import { connect } from "react-redux";
import { getCanFinalizeBooking } from "../../../core/selectors/bookingUISelectors";
import StyledSpan from "../../common/baseStyledComponents/StyledSpan";

const mapStateToProps = state => {
  const canFinalizeBooking = getCanFinalizeBooking(state);
  return {
    isHidden: canFinalizeBooking.value,
    children: canFinalizeBooking.description,
    marginLeft: "10px"
  };
};

export default connect(mapStateToProps)(StyledSpan);
