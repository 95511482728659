import checkInOutActionTypes from "../actionTypes/checkInOutActionTypes";

//* Customer
export const setPageName = ({ pageName }) => ({
  type: checkInOutActionTypes.SET_PAGE_NAME,
  pageName,
});

export const showCheckInOutModal = ({ modalType, modalProps }) => ({
  type: checkInOutActionTypes.SHOW_CHECK_IN_OUT_MODAL,
  modalType,
  modalProps,
});

export const hideCheckInOutModal = () => ({
  type: checkInOutActionTypes.HIDE_CHECK_IN_OUT_MODAL,
});

export const showCheckInOutButtonModal = ({ modalType }) => ({
  type: checkInOutActionTypes.SHOW_CHECK_IN_OUT_BUTTON_MODAL,
  modalType,
});

export const hideCheckInOutButtonModal = () => ({
  type: checkInOutActionTypes.HIDE_CHECK_IN_OUT_BUTTON_MODAL,
});

export const setPetServiceItem = ({ petServiceItem }) => ({
  type: checkInOutActionTypes.SET_PET_SERVICE_ITEM,
  petServiceItem,
});

export const setItinerary = ({ itinerary }) => ({
  type: checkInOutActionTypes.SET_ITINERARY,
  itinerary,
});

export const setCancelAppointmentReason = ({ reason }) => ({
  type: checkInOutActionTypes.SET_CANCEL_APPOINTMENT_REASON,
  reason,
});

export const clearCancelAppointmentReason = () => ({
  type: checkInOutActionTypes.CLEAR_CANCEL_APPOINTMENT_REASON,
});

export const setSpecialToRemove = ({ specialToRemove }) => ({
  type: checkInOutActionTypes.SET_SPECIAL_TO_REMOVE,
  specialToRemove,
});

export const clearSpecialToRemove = () => ({
  type: checkInOutActionTypes.CLEAR_SPECIAL_TO_REMOVE,
});

export const printInvoiceForMulti = ({ customerKey, itineraryId }) => ({
  type: checkInOutActionTypes.PRINT_INVOICE_FOR_MULTI,
  customerKey,
  itineraryId,
});

export const printInvoiceForMultiRequest = () => ({
  type: checkInOutActionTypes.PRINT_INVOICE_FOR_MULTI_REQUEST,
});

export const printInvoiceForMultiSuccess = () => ({
  type: checkInOutActionTypes.PRINT_INVOICE_FOR_MULTI_SUCCESS,
});

export const printInvoiceForMultiFailure = ({ error }) => ({
  type: checkInOutActionTypes.PRINT_INVOICE_FOR_MULTI_FAILURE,
  error,
});

export default {
  printInvoiceForMulti,
  printInvoiceForMultiRequest,
  printInvoiceForMultiFailure,
  printInvoiceForMultiSuccess,
};
