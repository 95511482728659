import { itinerariesPaymentOrigin } from "../../constants/itinerariesConstants";
import isPaidOrClosed from "./isPaidOrClosedUtil";

/**
 * Responsible for checking if a payment was made online, e.g mobile app or SBW(online website)
 *
 * @export isOnlinePayment
 * @param {String} paymentOrigin - Itinerary Payment Origin
 * @param { } itineraryStatus
 * @returns
 */
export default function isOnlinePayment(paymentOrigin, itineraryStatus) {
  const origin = paymentOrigin && paymentOrigin.toUpperCase();

  if (origin === itinerariesPaymentOrigin.WEB && isPaidOrClosed(itineraryStatus)) {
    return true;
  }

  if (origin === itinerariesPaymentOrigin.APP && isPaidOrClosed(itineraryStatus)) {
    return true;
  }

  return false;
}
