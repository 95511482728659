import addOnsActionTypes from "../actionTypes/addOnsActionTypes";

// LOAD ADDONS
export const loadAddOns = ({ petServiceId, fromDate }) => ({
  type: addOnsActionTypes.LOAD_ADDONS,
  petServiceId,
  fromDate,
});

export const loadAddOnsRequest = () => ({
  type: addOnsActionTypes.LOAD_ADDONS_REQUEST,
});

export const loadAddOnsSuccess = ({ addOns }) => ({
  type: addOnsActionTypes.LOAD_ADDONS_SUCCESS,
  payload: addOns,
});

export const loadAddOnsFailure = ({ error }) => ({
  type: addOnsActionTypes.LOAD_ADDONS_FAILURE,
  error,
});

// POST ADDONS
export const putAddOns = ({ customerId, itineraryId, engagementId, petServiceItemId, addons }) => ({
  type: addOnsActionTypes.PUT_ADDONS,
  customerId,
  itineraryId,
  engagementId,
  petServiceItemId,
  addons,
});

export const putAddOnsRequest = () => ({
  type: addOnsActionTypes.PUT_ADDONS_REQUEST,
});

export const putAddOnsSuccess = ({ itinerary }) => ({
  type: addOnsActionTypes.PUT_ADDONS_SUCCESS,
  payload: itinerary,
});

export const putAddOnsFailure = ({ error }) => ({
  type: addOnsActionTypes.PUT_ADDONS_FAILURE,
  error,
});

// PUT BUNDLED ADDONS
export const putAddOnsWithBundledOptions = ({
  customerKey,
  petServiceId,
  petServiceItemId,
  itineraryId,
  engagementId,
}) => ({
  type: addOnsActionTypes.PUT_ADDONS_WITH_BUNDLED_OPTIONS,
  customerKey,
  petServiceId,
  petServiceItemId,
  itineraryId,
  engagementId,
});

export const putAddOnsWithBundledOptionsRequest = () => ({
  type: addOnsActionTypes.PUT_ADDONS_WITH_BUNDLED_OPTIONS_REQUEST,
});

export const putAddOnsWithBundledOptionsSuccess = () => ({
  type: addOnsActionTypes.PUT_ADDONS_WITH_BUNDLED_OPTIONS_SUCCESS,
});

export const putAddOnsWithBundledOptionsFailure = ({ error }) => ({
  type: addOnsActionTypes.PUT_ADDONS_WITH_BUNDLED_OPTIONS_FAILURE,
  error,
});

export const openAddOnsFlyout = () => ({
  type: addOnsActionTypes.OPEN_ADDONS_FLYOUT,
});

export const closeAddOnsFlyout = () => ({
  type: addOnsActionTypes.CLOSE_ADDONS_FLYOUT,
});

export const loadEnhancedServicesSuccess = ({ enhancedServices }) => ({
  type: addOnsActionTypes.LOAD_ENHANCED_SERVICES_SUCCESS,
  payload: enhancedServices,
});

// EXPORTS
export default {
  loadAddOns,
  loadAddOnsRequest,
  loadAddOnsSuccess,
  loadAddOnsFailure,
  putAddOns,
  putAddOnsRequest,
  putAddOnsSuccess,
  putAddOnsFailure,
  putAddOnsWithBundledOptions,
  putAddOnsWithBundledOptionsRequest,
  putAddOnsWithBundledOptionsSuccess,
  putAddOnsWithBundledOptionsFailure,
  openAddOnsFlyout,
  closeAddOnsFlyout,
  loadEnhancedServicesSuccess,
};
