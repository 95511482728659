import { createSelector } from "reselect";
import { getState } from "core/selectors/commonSelector";
import { get } from "lodash/fp";
import isOnlinePayment from "core/utils/paymentUtils/isOnlinePaymentCheckUtil";
import { engagementTypes } from "web/setSystemType/constants/setSystemTypeConstants";
import {
  getFirstHotelEngagement,
  getHotelEngagementsArray,
  getStoreTimeZoneForHotelItinerary,
  selectIsAllPetsStatusCheckedOut,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { APPOINTMENT_STATUS } from "@/core/constants";
import { getCurrentPet, getStoreNumber, getIsSRCAgent } from "@/core/selectors/persistentSelectors";
import moment from "moment";
import momentTz from "moment-timezone";
import { itinerariesPaymentOrigin } from "@/core/constants/itinerariesConstants";
import { printInvoice } from "../printInvoice/printInvoiceConstants";

export const getHotelItinerary = createSelector([getState], state => {
  return state?.hotelItinerary?.itinerary;
});

export const getHotelItineraryPets = createSelector([getHotelItinerary], itinerary => {
  return itinerary?.pets;
});

export const getItineraryInvoice = createSelector([getHotelItinerary], itinerary => {
  return get(["invoice"], itinerary);
});

/**
 *  Selector to get isPaid value form the invoice on an itinerary
 *  @memberOf Selectors.HotelItinerary
 *  @function
 *  @name selectItineraryInvoiceIsPaid
 *  @param {Object} state - redux state
 *  @example selectItineraryInvoiceIsPaid(state)
 */
export const selectItineraryInvoiceIsPaid = createSelector(
  [getItineraryInvoice],
  invoice => !!invoice?.isPaid,
);

export const getHotelItineraryTotalPrice = createSelector([getHotelItinerary], itinerary => {
  return get(["totalPrice"], itinerary) || 0;
});

export const getHotelItineraryPaymentOrigin = createSelector([getHotelItinerary], itinerary => {
  return get(["invoice", "paymentOrigin"], itinerary);
});

/**
 *  Selector to check if invoice was paid at POS
 *  @memberOf Selectors.HotelItinerary
 *  @function
 *  @name getItineraryInvoiceIsPaidPOS
 *  @param {Object} state - redux state
 *  @example getItineraryInvoiceIsPaidPOS(state)
 */
export const getItineraryInvoiceIsPaidPOS = createSelector(
  [selectItineraryInvoiceIsPaid, getHotelItineraryPaymentOrigin],
  (isPaid, paymentOrigin) => {
    return !!isPaid && paymentOrigin === itinerariesPaymentOrigin.POS;
  },
);

export const getItineraryInvoiceIsPaidOnline = createSelector([getHotelItinerary], itinerary => {
  return isOnlinePayment(get(["invoice", "paymentOrigin"], itinerary), itinerary?.status);
});

/**
 *  Select Hotel Engagement Status by Itinerary ID & the current pet
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectHotelEngagementStatus
 *  @returns {string}
 *  @example
 *
 *  selectHotelEngagementStatus(state)
 */
export const selectHotelEngagementStatus = createSelector(
  [getCurrentPet, getHotelEngagementsArray],
  (petId, engagements) =>
    engagements?.find(engagement => engagement?.petId === petId)?.status ?? "",
);

/**
 *  Select Hotel Engagement Cancelled Status
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectHotelEngagementCancelStatus
 *  @returns {boolean}
 *  @example
 *
 *  selectHotelEngagementCancelStatus(state)
 */
export const selectHotelEngagementCancelStatus = createSelector(
  [selectHotelEngagementStatus],
  status => {
    return status === APPOINTMENT_STATUS.CANCELED;
  },
);

/**
 *  Select Hotel Engagement Checked Out Status
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectHotelEngagementCheckedOutStatus
 *  @returns {boolean}
 *  @example
 *
 *  selectHotelEngagementCheckedOutStatus(state)
 */
export const selectHotelEngagementCheckedOutStatus = createSelector(
  [selectHotelEngagementStatus],
  status => {
    return status === APPOINTMENT_STATUS.CHECKED_OUT;
  },
);

/**
 * Select Hotel Engagement has pending status
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectHotelEngagementIsPendingStatus
 * @returns {boolean}
 * @example selectHotelEngagementIsPendingStatus(state)
 */
export const selectHotelEngagementIsPendingStatus = createSelector(
  [selectHotelEngagementStatus],
  status => status === APPOINTMENT_STATUS.PENDING,
);

/**
 * Select whether the current pet's hotel itinerary is different from the current store
 * @memberOf Selectors.hotelItinerary
 * @function
 * @name selectHotelItineraryIsDifferentStore
 * @returns {boolean | number}
 * @example selectHotelItineraryIsDifferentStore(state)
 */
export const selectHotelItineraryIsDifferentStore = createSelector(
  [getHotelItinerary, getStoreNumber],
  (itinerary, currentStoreNumber) => {
    const isDifferent = itinerary?.storeNumber !== currentStoreNumber;
    return isDifferent && itinerary?.storeNumber; // return store number if different, else false
  },
);

/**
 * Select whether hotel reservation fields should be disabled due to the Itinerary being at a different store
 * @memberof Selectors.hotelItinerary
 * @function
 * @name selectIsReservationDisabledDueToDifferentStore
 * @returns {boolean}
 * @example selectIsReservationDisabledDueToDifferentStore(state)
 */
export const selectIsReservationDisabledDueToDifferentStore = createSelector(
  [getIsSRCAgent, selectHotelItineraryIsDifferentStore],
  (isSRCAgent, isDifferentStore) => {
    return !isSRCAgent && !!isDifferentStore;
  },
);

/**
 * Select whether hotel reservation fields should be disabled
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectIsReservationDisabled
 * @returns {boolean}
 * @example selectIsReservationDisabled(state)
 */
export const selectIsReservationDisabled = createSelector(
  [
    selectHotelEngagementCancelStatus,
    selectHotelEngagementCheckedOutStatus,
    selectHotelEngagementIsPendingStatus,
    selectIsReservationDisabledDueToDifferentStore,
  ],
  (isCanceled, isCheckedOut, isPending, isDifferentStore) => {
    return isCanceled || isCheckedOut || isPending || isDifferentStore;
  },
);

export const selectHotelItineraryHasSalon = createSelector(
  [getHotelItinerary],
  itinerary => !!itinerary?.metadata?.hasSalonAppointment,
);

/**
 * Select itinerary id from hotel itinerary error state
 * @memberOf Selectors.hotelItinerary
 * @function
 * @name selectItineraryIdFromError
 * @returns {number} itinerary id
 * @example selectItineraryIdFromError(state)
 */
export const selectItineraryIdFromError = createSelector([getState], state => {
  return state?.hotelItinerary?.error?.itineraryId;
});

/**
 * Selector to check if the current itinerary in state matches the rebooking itinerary
 * @memberOf Selectors.hotelItinerary
 * @function
 * @name selectIsRebookingItinerary
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String|Number} props.itineraryId
 * @returns boolean
 * @example selectIsRebookingItinerary(state, { itineraryId })
 */
export const selectIsRebookingItinerary = createSelector(
  [getHotelItinerary, (state, props) => props?.itineraryId],
  (itinerary, itineraryId) => {
    return !!itineraryId && itinerary?.itineraryId?.toString() === itineraryId?.toString();
  },
);

/**
 *  Selector to get the print invoice button label
 *  @memberOf Selectors.HotelItinerary
 *  @function
 *  @name selectInvoiceButtonLabel
 *  @param {Object} state - redux state
 *  @example selectInvoiceButtonLabel(state)
 */
export const selectInvoiceButtonLabel = createSelector(
  [
    getHotelItinerary,
    selectIsAllPetsStatusCheckedOut,
    getFirstHotelEngagement,
    getStoreTimeZoneForHotelItinerary,
    selectHotelEngagementCancelStatus,
  ],
  (
    { endDateTime } = {},
    isAllCheckedOut,
    { engagementType } = {},
    storeTimeZone,
    isCurrentPetCancelled,
  ) => {
    if (!endDateTime || !engagementType || isCurrentPetCancelled) return "";

    const today = momentTz.tz(moment(), storeTimeZone);
    const endDate = momentTz.tz(moment(endDateTime), storeTimeZone);
    const isOvernight = engagementType === engagementTypes.OVERNIGHT_BOARDING;
    const checksOutToday = endDate.isSame(today, "day");
    const isPastCheckOut = endDate.isBefore(today, "day");

    // If button should be hidden, then don't return a label
    const sameDayAndDoesntCheckOutToday = !isOvernight && !checksOutToday;
    const sameDayAndNotCheckedOut = !isOvernight && !isAllCheckedOut;
    const overnightAndPastCheckOut = isOvernight && isPastCheckOut;
    if (sameDayAndDoesntCheckOutToday || sameDayAndNotCheckedOut || overnightAndPastCheckOut)
      return "";

    const overnightAndNotAllCheckedOut = isOvernight && !isAllCheckedOut;
    if (overnightAndNotAllCheckedOut) return printInvoice.quoteInvoiceLabel;

    return printInvoice.invoiceLabel;
  },
);
