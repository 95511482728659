import React from "react";
import BulkPackageOfferingsExpirationContainer from "dux/bgm/bulkPackageOfferings/offerings/BulkPackageOfferingsExpirationContainer";
import BulkPackageOfferingsGroupRowCheckboxContainer from "../../../bgm/bulkPackageOfferings/offerings/BulkPackageOfferingsCheckboxContainer";
import Text from "../../genericLayoutComponents/TextComponent";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";

export default function BulkPackageOfferingsContentComponent({
  isHidden,
  componentID,
  petServiceId,
  items,
}) {
  if (isHidden) return null;

  return (
    <LayoutStack
      id={componentID}
      data-testid="test-bulk-package-offerings-content"
      space="scale-G1"
    >
      {items.map(({ productBundleConfigId, packageName, packagePrice }) => (
        <LayoutBox padding="scale-0" key={productBundleConfigId}>
          <LayoutStack>
            <LayoutCluster
              id={componentID}
              flexWrap="flex-noWrap"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <LayoutBox padding="scale-0">
                <LayoutCluster flexWrap="flex-noWrap">
                  <BulkPackageOfferingsGroupRowCheckboxContainer
                    petServiceId={petServiceId}
                    productBundleConfigId={productBundleConfigId}
                  />
                  <Text
                    text={packageName}
                    styleObj={{ marginRight: "20px", marginLeft: "15px" }}
                    styleType="priceList"
                  />
                </LayoutCluster>
              </LayoutBox>

              <Text text={`$${packagePrice?.toFixed(2)}`} styleType="priceList" />
            </LayoutCluster>

            <BulkPackageOfferingsExpirationContainer
              productBundleConfigId={productBundleConfigId}
            />
          </LayoutStack>
        </LayoutBox>
      ))}
    </LayoutStack>
  );
}
