import { getState } from "@/core/selectors/commonSelector";
import { createSelector } from "reselect";
import { storeSettingsNames } from "./storeSettingsConstants";

/**
 * Selector to get store settings from the Redux State
 * @memberOf Selectors.storeSettings
 * @name selectStoreSettings
 * @param {Object } state - redux state
 * @returns {Object}
 */
export const selectStoreSettings = createSelector([getState], state => state?.storeSettings ?? {});

/**
 * Selector to get the min stay to warn store setting value
 * @memberOf Selectors.storeSettings
 * @function
 * @name selectMinHotelStayToWarnStoreSetting
 * @param {Object } state - redux state
 */
export const selectMinHotelStayToWarnStoreSetting = createSelector(
  [selectStoreSettings],
  (storeSettings = {}) => {
    return Number(storeSettings[storeSettingsNames.MINIMUM_HOTEL_STAY_TO_WARN]?.value ?? 0);
  },
);

/**
 * Selector to get the min itinerary call wait time store setting value
 * Note: the unit for this settings value is seconds
 * @memberOf Selectors.storeSettings
 * @function
 * @name selectMinHotelItinGetWaitTimeStoreSetting
 * @param {Object } state - redux state
 */
export const selectMinHotelItinGetWaitTimeStoreSetting = createSelector(
  [selectStoreSettings],
  (storeSettings = {}) => {
    return Number(storeSettings[storeSettingsNames.MINIMUM_HOTEL_ITIN_GET_WAIT_TIME]?.value ?? 0);
  },
);
