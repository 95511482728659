/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const medicationFrequencyActionTypes = {
  SET_MEDICATION_FREQUENCY: "SET_MEDICATION_FREQUENCY",
  CLEAR_MEDICATION_FREQUENCY: "CLEAR_MEDICATION_FREQUENCY",
  SET_MEDICATION_FREQUENCY_DATES: "SET_MEDICATION_FREQUENCY_DATES",
  MED_FREQUENCY_TOUCHED: "MED_FREQUENCY_TOUCHED",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setMedicationFrequency = ({ petId, medicationId, frequency }) => ({
  type: medicationFrequencyActionTypes.SET_MEDICATION_FREQUENCY,
  petId,
  medicationId,
  frequency,
});

export const clearMedicationFrequency = ({ petId, medicationId }) => ({
  type: medicationFrequencyActionTypes.CLEAR_MEDICATION_FREQUENCY,
  petId,
  medicationId,
});

export const setMedicationFrequencyDates = ({ petId, medicationId, dates }) => ({
  type: medicationFrequencyActionTypes.SET_MEDICATION_FREQUENCY_DATES,
  petId,
  medicationId,
  dates,
});

export const medicationFrequencyTouched = ({ petId, medicationId }) => ({
  type: medicationFrequencyActionTypes.MED_FREQUENCY_TOUCHED,
  petId,
  medicationId,
});
