import { connect } from "react-redux";
import searchFieldWrapper from "../searchFieldWrapper/searchFieldWrapperComponent";
import { getSearchComponentName } from "../../../core/selectors/persistentSelectors";
import getIsSearchFieldMainV2WorkflowFeatureFlagHidden
  from "web/enableDisableWorkflowFeatureFlag/selectors/searchFieldMainV2/getIsSearchFieldMainV2WorkflowFeatureFlagHidden";
import { PrimarySearchFieldMain } from "dux/searchFieldMain/SearchFieldMain";
import SearchFieldHeaderContainer from "dux/searchField/SearchFieldHeaderContainer";
import { buildSearchFieldProducts, SearchFactorySalon } from "dux/searchFieldMain/searchFactory/SearchFactory";

const mapStateToProps = (state, ownProps) => {
  const isFeatureFlagHidden = getIsSearchFieldMainV2WorkflowFeatureFlagHidden(state);
  const headerSearch = buildSearchFieldProducts(new SearchFactorySalon());

  return {
    searchComponentName: getSearchComponentName(state, ownProps),

    // Factory will return the new or old customer search based on feature flag status
    searchField: headerSearch.customerSearch.getSearchField(isFeatureFlagHidden),
  };
};

const searchFieldWrapperContainer = connect(mapStateToProps)(searchFieldWrapper);

export default searchFieldWrapperContainer;
