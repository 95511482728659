//@ts-check

//React Redux
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Layout
import { Layout } from "@prism/psm-ui-components";

// Sparky
import { Heading, Tabs } from "@petsmart-ui/sparky";

// View components
import { StoreInfoWindowStoreWideNotes } from "dux/storeWideNotes/StoreWideNotes";
import { SRC_StoreInfoDayCampAvailability } from "dux/storeInfoDayCampAvailability/StoreInfoDayCampAvailability";
import { SRC_StoreInfoGoogleMap } from "dux/storeInfoGoogleMap/StoreInfoGoogleMap";
import { SRC_StoreInfoHoursAndServices } from "dux/storeInfoHoursAndServices/StoreInfoHoursAndServices";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import {
  selectStoreAddress,
  selectStoreLocalTime,
  selectStorePhoneNumber,
} from "dux/storeInfoHoursAndServices/storeInfoHoursAndServicesSelectors";
import moment from "moment-timezone";
import { StoreInfoListOfNearByStores } from "dux/listOfNearByStores/ListOfNearByStores";

export const StoreInfoWindow = props => {
  const { componentId, isHidden, storeInfo } = props;

  useEffect(() => {
    // some Effect();
  }, []);

  // NOTE: Temp CSS for Prepping and debug the layout, delete when you remove the className="psm-debug-layout" from the root component.
  const tempCssStoreAndInfo = { padding: ".5rem" };

  // COLUMNS
  // Place any styles here tha will need to span both column equally
  const columnStyles = {
    overflowY: "auto",
    height: "555px",
  };

  // Left Column
  const returnStoreInfo = () => {
    return (
      <Layout.Box style={{ ...columnStyles, minWidth: "fit-content", paddingRight: "1rem" }}>
        <Layout.Stack space="stack-space-12">
          {/*DAY CAMP AVAILABILITY*/}
          <Layout.Box>
            <SRC_StoreInfoDayCampAvailability />
          </Layout.Box>

          {/*MAP*/}
          <Layout.Cluster justify="center">
            <Layout.Box>
              <SRC_StoreInfoGoogleMap />
            </Layout.Box>
          </Layout.Cluster>

          {/*STORE INFO*/}
          <Layout.Box>
            <SRC_StoreInfoHoursAndServices />
          </Layout.Box>
        </Layout.Stack>
      </Layout.Box>
    );
  };

  // Right Column (Tabs Panel content)
  const returnNotes = () => {
    return (
      <Layout.Box style={{ ...columnStyles }}>
        {/*NOTES ACCORDION*/}
        <Layout.Box>
          <StoreInfoWindowStoreWideNotes />
        </Layout.Box>
      </Layout.Box>
    );
  };

  const returnEvents = () => {
    return (
      <Layout.Box style={{ ...columnStyles }}>
        {/*NOTES ACCORDION*/}
        <Layout.Box>
          The events will go here {/* Place notes accordion component/redux connect here */}
        </Layout.Box>
      </Layout.Box>
    );
  };

  const returnNearByStores = () => {
    return (
      <Layout.Box style={{ ...columnStyles }}>
        {/*NOTES ACCORDION*/}
        <Layout.Box>
          <StoreInfoListOfNearByStores />
        </Layout.Box>
      </Layout.Box>
    );
  };

  // Main body of component
  if (isHidden) {
    return null;
  }

  return (
    <Layout.Box
      id={componentId}
      style={{ padding: "1rem", height: "700px", width: "85vw", overflow: "hidden" }}
    >
      <Layout.Stack space="stack-space-2">
        {/*STORE INFO AND TIME*/}
        <Layout.Box>
          <Layout.Box style={tempCssStoreAndInfo}>
            <Heading tagName="h2" size="body-lg-bold">
              {storeInfo}
            </Heading>
          </Layout.Box>
        </Layout.Box>

        {/* TABS AND TAB PANEL CONTENT */}
        <Layout.Box style={{ overflow: "hidden" }}>
          <Tabs onChange={() => {}}>
            <Tabs.Panel title="Notes & Notifications">
              <Layout.Sidebar space="sidebar-space-2">
                {returnStoreInfo()}
                {returnNotes()}
              </Layout.Sidebar>
            </Tabs.Panel>
            {/*<Tabs.Panel title="Store Events">*/}
            {/*  <Layout.Sidebar space="sidebar-space-2">*/}
            {/*    { returnStoreInfo() }*/}
            {/*    { returnEvents() }*/}
            {/*  </Layout.Sidebar>*/}
            {/*</Tabs.Panel>*/}
            <Tabs.Panel title="Nearby Stores">
              <Layout.Sidebar space="sidebar-space-2">
                {returnStoreInfo()}
                {returnNearByStores()}
              </Layout.Sidebar>
            </Tabs.Panel>
          </Tabs>
        </Layout.Box>
      </Layout.Stack>
    </Layout.Box>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const SrcStoreInfoWindow = connect(state => {
  const storeNumber = getStoreNumber(state);
  const { streetLine1, city, stateProvinceAbbreviation, zipPostalCode } = selectStoreAddress(
    state,
    { storeNumber },
  );
  const phoneNumber = selectStorePhoneNumber(state, { storeNumber });
  const localTime = selectStoreLocalTime(state, { storeNumber });

  return {
    componentId: "SrcStoreInfoWindow",
    storeInfo: `Store #${storeNumber} | Local Time: ${localTime} | Store Address: ${streetLine1}, ${city} ${stateProvinceAbbreviation} ${zipPostalCode} | Phone: ${phoneNumber}`,
  };
})(StoreInfoWindow);
