import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import { omit } from "lodash/fp";
import {
  createPetFoodRequest,
  createPetFoodSuccess,
  createPetFoodFailure,
  createUpdateDeleteFoodActionTypes,
  deletePetFoodSuccess,
  deletePetFoodFailure,
  deletePetFoodRequest,
  updatePetFoodSuccess,
  updatePetFoodFailure,
  updatePetFoodRequest,
} from "./actions/createUpdateDeleteFoodActions";
import {
  postPetFood,
  putPetFood,
  deletePetFood,
} from "core/services/associateWebProfilePet/createUpdateDeleteFoodEndPoints";
import { NEW_FOOD_ID } from "../newFood/newFoodConstants";
import { getFoodByPet, getPetFoods } from "./foodsSelector";
import { updatePetSuccess } from "../../core/actionCreators/petsActionCreators";
import { setFoods } from "./actions/foodsActions";
import { getSourceId, getCurrentPet } from "../../core/selectors/persistentSelectors";
import normalizeArrayByProperty from "../../core/utils/normalizeUtils/normalizeArray";

/** ----------------------------------------------------------------------- **\
    CREATE PET FOOD SAGA
\** ----------------------------------------------------------------------- * */
/**
 * Saga to handle requests and responses for adding customer notes to the Web Service
 * @param { Number } customerKey - customerId of the current customer
 * @param { String } content - Note message
 */
function* onCreatePetFood({ food, petId, isFromBooking }) {
  try {
    const sourceId = yield select(getSourceId);
    yield put(createPetFoodRequest());

    const response = yield call(postPetFood, {
      petId,
      sourceId,
      data: food,
    });
    const { result } = response.data;
    const petFoodAdded = { [result.petFoodId]: result };
    const petFoods = yield select(getFoodByPet, { petId });
    const updatedPetFoods = omit([NEW_FOOD_ID], petFoods);

    const petProfileFoods = yield select(getPetFoods, { petId });
    const petProfileFoodAdded = { [petProfileFoods.length]: result };
    const addedPetProfileFood = { ...petProfileFoods, ...petProfileFoodAdded };

    const addedFoods = { ...updatedPetFoods, ...petFoodAdded }; // Removes old NEW_FOOD and adds the API response
    yield put(
      updatePetSuccess({
        pet: {
          petId: Number(petId),
          foods: Object.values(addedPetProfileFood),
        },
      }),
    );
    if (!isFromBooking) {
      yield put(
        setFoods({
          foods: addedFoods,
          petId,
        }),
      );
    }
    yield put(createPetFoodSuccess());
  } catch (error) {
    yield put(createPetFoodFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
    DELETE PET FOOD SAGA
\** ----------------------------------------------------------------------- * */
/**
 * Saga to handle requests and responses for adding customer notes to the Web Service
 * @param { Number } customerKey - customerId of the current customer
 * @param { String } content - Note message
 */
function* onDeletePetFood({ foodId, petId }) {
  try {
    const sourceId = yield select(getSourceId);
    yield put(deletePetFoodRequest());

    yield call(deletePetFood, {
      petId,
      foodId,
      sourceId,
    });
    const petFoods = yield select(getFoodByPet, { petId });
    const newFoods = omit([foodId], petFoods);
    yield put(
      updatePetSuccess({
        pet: {
          petId: Number(petId),
          foods: Object.values(newFoods),
        },
      }),
    );
    const currentPet = yield select(getCurrentPet);
    const newPetFoods = yield select(getPetFoods, { petId: currentPet });
    if (currentPet === petId) {
      yield put(
        setFoods({
          foods: normalizeArrayByProperty(newPetFoods, "petFoodId"),
          petId,
        }),
      );
    }
    yield put(deletePetFoodSuccess());
  } catch (error) {
    yield put(deletePetFoodFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
    UPDATE PET FOOD SAGA
\** ----------------------------------------------------------------------- * */
/**
 * Saga to handle requests and responses for adding customer notes to the Web Service
 * @param { Number } customerKey - customerId of the current customer
 * @param { String } content - Note message
 */
function* onUpdatePetFood({ food, petId, isFoodDetailsShowing, isFromBooking }) {
  try {
    const sourceId = yield select(getSourceId);

    yield put(updatePetFoodRequest());

    const response = yield call(putPetFood, {
      petId,
      sourceId,
      foodId: food.petFoodId,
      data: food,
    });
    const { result } = response.data;
    const petFoodChanged = result;
    const petFoods = yield select(getFoodByPet, { petId });
    const stateFood = { ...petFoodChanged, isFoodDetailsShowing }; // Add isFoodShowing to maintain opened food
    const addedFoods = {
      ...petFoods,
      [petFoodChanged.petFoodId]: stateFood,
    }; // Removes old NEW_FOOD and adds the API response
    yield put(
      updatePetSuccess({
        pet: {
          petId: Number(petId),
          foods: Object.values(addedFoods),
        },
      }),
    );
    const currentPet = yield select(getCurrentPet);
    const newPetFoods = yield select(getPetFoods, { petId: currentPet });

    if (currentPet === petId && !isFromBooking) {
      yield put(
        setFoods({
          foods: normalizeArrayByProperty(newPetFoods, "petFoodId"),
          petId,
        }),
      );
    }
    yield put(updatePetFoodSuccess());
  } catch (error) {
    yield put(updatePetFoodFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
    WATCH FUNCTIONS
\** ----------------------------------------------------------------------- * */
function* watchCreatePetFood() {
  yield takeEvery(createUpdateDeleteFoodActionTypes.CREATE_PET_FOOD, onCreatePetFood);
}

function* watchDeletePetFood() {
  yield takeEvery(createUpdateDeleteFoodActionTypes.DELETE_PET_FOOD, onDeletePetFood);
}

function* watchUpdatePetFood() {
  yield takeEvery(createUpdateDeleteFoodActionTypes.UPDATE_PET_FOOD, onUpdatePetFood);
}

/** ----------------------------------------------------------------------- **\
    EXPORT SAGAS
\** ----------------------------------------------------------------------- * */
export default function* createUpdateDeleteFoodSaga() {
  yield all([fork(watchCreatePetFood), fork(watchUpdatePetFood), fork(watchDeletePetFood)]);
}
