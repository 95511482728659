import { connect } from "react-redux";
import QuickView from "web/common/quickView/QuickView";
import {
  getQuickViewRebookCoordinates,
  getQuickViewRebookIsVisible,
} from "core/selectors/quickView/quickViewRebookSelectors";

const mapStateToProps = state => ({
  isVisible: getQuickViewRebookIsVisible(state),
  coordinates: getQuickViewRebookCoordinates(state),
});

export default connect(mapStateToProps)(QuickView);
