export default {
  SELECT_PET: "SELECT_PET",
  SET_BOOKING_ITINERARY_ID: "SET_BOOKING_ITINERARY_ID",
  SET_CURRENT_BOOKING_SERVICE_ITEM_ID: "SET_CURRENT_BOOKING_SERVICE_ITEM_ID",
  SELECT_HOVERED_TIME_SLOT: "SELECT_HOVERED_TIME_SLOT",
  SHOW_BOOKING_MODAL: "SHOW_BOOKING_MODAL",
  HIDE_BOOKING_MODAL: "HIDE_BOOKING_MODAL",
  SELECT_NEW_APPOINTMENT_TIME_SLOT: "SELECT_NEW_APPOINTMENT_TIME_SLOT",
  CLEAR_NEW_APPOINTMENT_TIME_SLOT: "CLEAR_NEW_APPOINTMENT_TIME_SLOT",
  BOOK_APPOINTMENT: "BOOK_APPOINTMENT",
  FINALIZE_APPOINTMENT: "FINALIZE_APPOINTMENT",
  LOAD_AVAILABLE_TIME_SLOTS: "LOAD_AVAILABLE_TIME_SLOTS",
  LOAD_AVAILABLE_TIME_SLOTS_REQUEST: "LOAD_AVAILABLE_TIME_SLOTS_REQUEST",
  LOAD_AVAILABLE_TIME_SLOTS_SUCCESS: "LOAD_AVAILABLE_TIME_SLOTS_SUCCESS",
  LOAD_AVAILABLE_TIME_SLOTS_FAILURE: "LOAD_AVAILABLE_TIME_SLOTS_FAILURE",
  LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT: "LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT",
  LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT_REQUEST:
    "LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT_REQUEST",
  LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT_SUCCESS:
    "LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT_SUCCESS",
  LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT_FAILURE:
    "LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT_FAILURE",
  SET_TIME_SLOT_FROM_DASHBOARD: "SET_TIME_SLOT_FROM_DASHBOARD",
  SET_TIME_SLOT_FROM_DASHBOARD_REQUEST: "SET_TIME_SLOT_FROM_DASHBOARD_REQUEST",
  SET_TIME_SLOT_FROM_DASHBOARD_SUCCESS: "SET_TIME_SLOT_FROM_DASHBOARD_SUCCESS",
  SET_TIME_SLOT_FROM_DASHBOARD_FAILURE: "SET_TIME_SLOT_FROM_DASHBOARD_FAILURE",

  SHOULD_PROCEED_WITH_BOOKING: "SHOULD_PROCEED_WITH_BOOKING",

  REFRESH_SESSION_CACHE: "REFRESH_SESSION_CACHE",
  REFRESH_SESSION_CACHE_REQUEST: "REFRESH_SESSION_CACHE_REQUEST",
  REFRESH_SESSION_CACHE_SUCCESS: "REFRESH_SESSION_CACHE_SUCCESS",
  REFRESH_SESSION_CACHE_FAILURE: "REFRESH_SESSION_CACHE_FAILURE",

  SET_ADDITIONAL_APPOINTMENT_BEFORE_BREAK: "SET_ADDITIONAL_APPOINTMENT_BEFORE_BREAK",
  SET_ADDITIONAL_APPOINTMENT_AFTER_BREAK: "SET_ADDITIONAL_APPOINTMENT_AFTER_BREAK",

  SET_PET_ID_TO_REMOVE_FROM_CART: "SET_PET_ID_TO_REMOVE_FROM_CART",
};
