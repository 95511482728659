// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pplt-layout__grid {
    --setGridGap: 20;
    --setGridMinimum: 20;

    /* ↓ Establish a grid context */
    display: grid;
    /* ↓ Set a gap between grid items  */
    grid-gap: var(--setGridGap);
    /* ↓ Set the minimum column width */

}

/* The @supports CSS at-rule lets you specify declarations that depend on a browser's support for one or more specific CSS features. */
@supports (width: min(var(--setGridMinimum), 100%)) {
.pplt-layout__grid {
    /* ↓ Enhance with the min() function
    into multiple columns */
    grid-template-columns: repeat(auto-fit, minmax(min(var(--setGridMinimum), 100%), 1fr));
} }
`, "",{"version":3,"sources":["webpack://./src/layout/grid/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,oBAAoB;;IAEpB,+BAA+B;IAC/B,aAAa;IACb,oCAAoC;IACpC,2BAA2B;IAC3B,mCAAmC;;AAEvC;;AAEA,sIAAsI;AACtI;AACA;IACI;2BACuB;IACvB,sFAAsF;AAC1F,EAAE","sourcesContent":[".pplt-layout__grid {\n    --setGridGap: 20;\n    --setGridMinimum: 20;\n\n    /* ↓ Establish a grid context */\n    display: grid;\n    /* ↓ Set a gap between grid items  */\n    grid-gap: var(--setGridGap);\n    /* ↓ Set the minimum column width */\n\n}\n\n/* The @supports CSS at-rule lets you specify declarations that depend on a browser's support for one or more specific CSS features. */\n@supports (width: min(var(--setGridMinimum), 100%)) {\n.pplt-layout__grid {\n    /* ↓ Enhance with the min() function\n    into multiple columns */\n    grid-template-columns: repeat(auto-fit, minmax(min(var(--setGridMinimum), 100%), 1fr));\n} }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
