import React from "react";
import CommonDeleteModal from "../../../../../../common/modals/shared/CommonDeleteModal";

export default ({ vetToDelete, isLoading, handleRemove, onClose }) => (
  <CommonDeleteModal
    heading="Remove vet"
    message="Are you sure you want to remove this vet?"
    buttonLabel="Remove vet"
    handleRemove={() => handleRemove(vetToDelete)}
    onClose={onClose}
    disabled={isLoading}
  />
);
