import { connect } from "react-redux";
import InputWithBlur from "../common/InputWithBlur";
import { setMedicationTypeAmount } from "./actions/medicationTypeAmountActions";
import { getMedicationTypeAmountById } from "./medicationTypeAmountSelectors";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../core/constants/validationErrors";
import { isValidString } from "@/core/utils/validationUtils/stringValidation";
import { getMedIsDiscontinuedById } from "../medication/medicationsSelector";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, medicationIndex, petId } = ownProps;
  const currentValue = getMedicationTypeAmountById(state, { medicationId, petId });
  const isValid = isValidString(currentValue);
  return {
    medicationId,
    petId,
    currentValue,
    componentId: "medicationDetails-medicationTypeAmount",
    title: `Medication ${medicationIndex} amount`,
    display: "grid",
    error: !isValid && REQUIRED_FIELD_ERROR_MESSAGE,
    maxLength: 70,
    disabled: getMedIsDiscontinuedById(state, { petId, medicationId })
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeMedicationTypeAmount: ({ value, medicationId, petId }) => {
    dispatch(setMedicationTypeAmount({ medicationTypeAmount: value, medicationId, petId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onBlur: value => {
    const { medicationId, petId } = propsFromState;
    propsFromDispatch.onChangeMedicationTypeAmount({ value, medicationId, petId });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InputWithBlur);
