import React from "react";
import { connect } from "react-redux";
import { getTrainingAttendeesByClassSession } from "../trainingClassSessions/selectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { ManageTrainingItineraryComponent } from "@/dux/manageTrainingClassPage/ManageTrainingItineraryComponent";

const TrainingAttendeeCardsComponent = ({ trainingAttendees }) => {
  return trainingAttendees.map(attendee => {
    return (
      <ManageTrainingItineraryComponent key={attendee.attendeeId} {...attendee} />
    );
  });
};

const mapStateToProps = (state) => {
  const storeNumber = getStoreNumber(state);
  return {
    trainingAttendees: getTrainingAttendeesByClassSession(state, { storeNumber }),
  };
};

export default connect(mapStateToProps)(TrainingAttendeeCardsComponent);
