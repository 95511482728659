import React from "react";
import { connect } from "react-redux";
import { getCustomer } from "core/selectors/entitiesSelector";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { history } from "dux/utils/browser/browserHistory";
import { hideBookingModal } from "core/actionCreators/bookingActionCreators";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { Heading, Button } from "@petsmart-ui/sparky";
import { routePaths } from "core/constants/routePaths";
import { HotelReservationPetCardList } from "dux/_components/petCard/AppointmentSetPetCardListComponent";

export const SetAppointmentsModal = props => {
  const { componentId, isHidden, header, customer } = props;
  const { handleProfileClick, handleDoneClick } = props;

  if (isHidden) return null;

  return (
    <LayoutBox componentId={componentId}>
      <LayoutStack space="scale-G2">
        <LayoutBox>
          <LayoutCluster style={{ justifyContent: "center" }}>
            <Heading id={`${componentId}__heading`} tagName="h4">
              {header}
            </Heading>
          </LayoutCluster>
        </LayoutBox>

        <LayoutBox id={`${componentId}__cardList`} style={{ overflowY: "auto" }}>
          <HotelReservationPetCardList />
        </LayoutBox>

        <LayoutBox id={`${componentId}__footer`}>
          <LayoutCluster style={{ justifyContent: "space-between", alignItems: "center" }}>
            <Button
              text={`${customer.firstName}'s profile`}
              variant="link"
              onClick={handleProfileClick}
            />

            <Button text="Done" variant="primary" onClick={handleDoneClick} />
          </LayoutCluster>
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const SetHotelAppointmentsModal = connect(
  state => {
    const customerKey = getCurrentCustomerKey(state);

    return {
      componentId: "SetHotelAppointmentsModal",
      header: "The reservation is set!",
      customer: getCustomer(state, { customerKey }),

      customerKey,
    };
  },

  dispatch => {
    return {
      profileClick: ({ customerKey }) => {
        history.push(`/pet-parent-profile/${customerKey}`);
        dispatch(hideBookingModal());
      },
      doneClick: () => {
        history.push(routePaths.DASHBOARD);
        dispatch(hideBookingModal());
      },
    };
  },

  (mapProps, dispatchProps) => {
    // Props for component
    const { componentId, header, customer } = mapProps;

    // props for handlers
    const { customerKey } = mapProps;

    const { profileClick, doneClick } = dispatchProps;

    return {
      // props passed to the view.
      componentId,
      header,
      customer,

      // actions to pass to view
      handleProfileClick: () => profileClick({ customerKey }),
      handleDoneClick: () => doneClick(),
    };
  },
)(SetAppointmentsModal);
