// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-pet-card {
  padding: 0 15px 15px;
}

.psm-pet-card__card-body {
  padding-top: 15px; 
}

.psm-pet-card__cluster > * {
  flex-wrap: nowrap;
}`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/composites/PetCard/styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".psm-pet-card {\n  padding: 0 15px 15px;\n}\n\n.psm-pet-card__card-body {\n  padding-top: 15px; \n}\n\n.psm-pet-card__cluster > * {\n  flex-wrap: nowrap;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
