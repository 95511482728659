// NOTE: this file is used ONLY for writing stories within storybook
import * as React from 'react';

interface StoryStateProviderProps<T> {
  /** children MUST be a function (state, setState) => ReactNode */
  children: (value: { state: T; setState: React.Dispatch<React.SetStateAction<T>> }) => React.ReactNode;
  /** key used in the context map */
  contextKey: string;
  /** the initial state for the story */
  value: T;
}

// context objects are singletons, so store all contexts here to avoid
// unnecessary rerenders within the demo pages
const contextMap = new Map<string, React.Context<any>>();

function ContextFactory(key: string, value: any): React.Context<any> {
  const existingContext = contextMap.get(key);
  if (!existingContext) {
    const context = React.createContext(value);
    contextMap.set(key, context);
    return context;
  }
  return existingContext;
}

// the purpose here is to encapsulate component state to make UI changes
// a bit easier to work with in the story files
export function StoryStateProvider<T>(props: StoryStateProviderProps<T>) {
  const { value } = props;
  const [state, setState] = React.useState(value);
  const Context = ContextFactory(props.contextKey, {
    state: value,
    setState,
  });

  return (
    <Context.Provider value={{ state, setState }}>
      <Context.Consumer>{props.children}</Context.Consumer>
    </Context.Provider>
  );
}

// remove the prop from the argstable when remove is default to false,
// when true the prop remains in the table but the controls are disabled.
export const removeArgType = (remove = false) => {
  return remove
    ? { table: { disable: true } }
    : {
        control: false,
      };
};

// the common "as" prop can be any HTML element, for documentation
// purposes we show a limited list
export const asPropArgTypes = (defaultValue: string) => {
  return {
    table: { defaultValue: { summary: defaultValue } },
    control: {
      type: 'radio',
      options: ['div', 'span', 'p'],
    },
  };
};

// helper to construct the dropdown menus in the Docs stories
export function selectArgTypes<T>(tokens: T[]) {
  return {
    control: {
      type: 'select',
      options: tokens,
    },
  };
}

// used to help show the source code in our stories
export function showSourceCode() {
  return {
    source: {
      type: 'code',
    },
  };
}

// construct a component for each prop in an array and use
// the prop as a label
export function propTester<T, C extends React.ReactElement>(
  allProps: T[],
  testProp: string,
  Component: C,
  children?: React.ReactNode
) {
  return allProps.map((prop) => {
    return (
      <div
        key={`${testProp}-${prop}`}
        style={{
          border: '1px dotted black',
          padding: '1rem',
          backgroundColor: 'rgba(0,0,0,0)',
          marginBottom: '1rem',
          marginTop: '1rem',
        }}
      >
        <div
          style={{
            color: '#9CA3AF',
            fontFamily: 'sans-serif',
            textTransform: 'uppercase',
            fontWeight: 'lighter',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            letterSpacing: '0.025em',
            marginBottom: '0.5rem',
          }}
        >
          {`${prop}`}
        </div>
        {React.cloneElement(Component, {
          [`${testProp}`]: prop,
          ...(children && { children }),
        })}
      </div>
    );
  });
}
