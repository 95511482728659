import { connect } from "react-redux";
import ClickableText from "../common/ClickableText";
import { color, font } from "../common/styles/theme";
import { clearUiStore } from "../../core/actionCreators/ui/uiActionCreators";
import { routePaths } from "../../core/constants/routePaths";
import { history } from "@/dux/utils/browser/browserHistory";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import { getSystemType } from "../setSystemType/selectors/setSystemTypeSelectors";
import { setSystemBookingFlowType } from "../setSystemType/actions/setSystemTypeActions";
import getIsHotelWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";

function checkIfVisible(state, flowName) {
  const ffEnabled = !getIsHotelWorkflowFeatureFlagHidden(state);

  const isCorrectFlow = getSystemType(state) === flowName;

  return ffEnabled && isCorrectFlow;
}

const mapStateToProps = (state, ownProps) => {
  const systemType = getSystemType(state);

  return {
    isVisible: checkIfVisible(state, systemName.HOTEL),
    children: "Dashboard",
    styleObj: {
      fontSize: "16px",
      color: color.victorStone,
      textDecorationLine: "underline",
      fontFamily: font.mainFamily,
    },

    systemType,
  };
};

const mapDispatchToProps = dispatch => ({
  onClick: () => {
    dispatch(clearUiStore());
    dispatch(setSystemBookingFlowType({ systemBookingFlow: systemName.HOTEL }));
    history.push(routePaths.DASHBOARD);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClickableText);
