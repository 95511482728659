import React from "react";
import styled from "styled-components";
import { device } from "web/common/styles/responsive/devices";

const StyledForm = styled.form`
  display: ${({ show = true }) => (show ? "grid" : "none")};
  grid-column-gap: 3.75rem;
  grid-row-gap: 1.875rem;
  ${props =>
    props.displayVertical &&
    `
        display: flex;
        flex-direction: column;
    `};

  // use repeat(auto-fill.... so lower devices may go to 2 col if rotated to landscape.
  @media ${device.mobileS} {
    grid-template-columns: repeat(1, 1fr));
  }

  //set specific column number for targeted devices.
  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }
  
  
  @media ${device.prismDesktop} {
    grid-template-columns: ${({ columns = 2 }) => `repeat(${columns}, 1fr)`};
  }
`;

export const FormContext = React.createContext();

class Form extends React.Component {
  render() {
    const {
      onFieldFocus,
      onFieldBlur,
      onSubmit,
      onSetFieldValue,
      disabled,
      fieldsToInclude,
    } = this.props;
    const formContext = {
      onFocus: onFieldFocus,
      onBlur: onFieldBlur,
      onSubmit,
      onSetFieldValue,
      disabled,
      fieldsToInclude,
    };
    return (
      <StyledForm {...this.props}>
        <FormContext.Provider value={formContext}>{this.props.children}</FormContext.Provider>
      </StyledForm>
    );
  }
}

export default Form;
