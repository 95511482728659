import { connect } from "react-redux";
import CommonButton from "../../../../common/commonButton";
import { createLoadingSelector } from "../../../../../core/selectors/utils";
import customersActionTypes from "../../../../../core/actionTypes/customersActionTypes";

const mapStateToProps = (state, { onClick, isValid, isAddAddressButtonShown }) => {
  const isAddAddressLoading = createLoadingSelector([customersActionTypes.CREATE_CUSTOMER_ADDRESS])(
    state
  );
  return {
    onClick,
    // Disable button if Formik validation is invalid or we are submitting an address
    disabled: !isValid || isAddAddressLoading,
    label: isAddAddressLoading ? "Creating..." : "Create",
    isHidden: !isAddAddressButtonShown
  };
};

export default connect(
  mapStateToProps,
  null
)(CommonButton);
