import React from "react";
import CommonModal from "../modals/commonModal";
import { modalTypes } from "core/constants/modalConstants";
import { HotelModalIconKey, SalonIconKey } from "dux/iconKey/iconKey";

const AppHeaderModal = props => (
  <CommonModal
    onClose={() => {
      props.onClose();
    }}
  >
    {selectModalContent(props)}
  </CommonModal>
);

const selectModalContent = props => {
  switch (props.modalType) {
    case modalTypes.ICON_SALON_KEY:
      return <SalonIconKey />
    case modalTypes.ICON_HOTEL_KEY:
      return <HotelModalIconKey />
    default:
      return null;
  }
};

export default AppHeaderModal;
