import React, { Component } from "react";
import styled from "styled-components";
import { Layout } from "@prism/psm-ui-components";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding }) => {
    return padding ? padding : "35px 1em 0 1em";
  }};
`;

export default class WeekNavigationBar extends Component {
  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.setWeekNumber();
  }

  render() {
    const { week, isHidden, diComp, compName, componentId, switcherSpace } = this.props;

    const DaySelection = diComp.daySelection[compName];
    const PrevWeek = diComp.prevWeek[compName];
    const NextWeek = diComp.nextWeek[compName];

    if (!isHidden) {
      return (
        <SectionWrapper id={componentId} padding={this.props.padding}>
          <PrevWeek />

          <Layout.Switcher
            id={`${componentId}__week`}
            space={switcherSpace}
            threshold="switcher-threshold-3"
          >
            {week.map(day => (
              // Since day will return a string with spaces, we are using replace to remove spaces from the string, just for a cleaner key
              <DaySelection key={`daySelection_${day}`.replace(/\s/g, "")} day={day} />
            ))}
          </Layout.Switcher>

          <NextWeek />
        </SectionWrapper>
      );
    }

    return null;
  }
}
