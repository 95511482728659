/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS =
  "GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS";
export const GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS_REQUEST =
  "GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS_REQUEST";
export const GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS_SUCCESS =
  "GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS_SUCCESS";

export const GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS_FAILURE =
  "GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS_FAILURE";

export const CANCEL_OVERBOOKING_EXCEPTION = "CANCEL_OVERBOOKING_EXCEPTION";
export const CANCEL_OVERBOOKING_EXCEPTION_REQUEST = "CANCEL_OVERBOOKING_EXCEPTION_REQUEST";
export const CANCEL_OVERBOOKING_EXCEPTION_SUCCESS = "CANCEL_OVERBOOKING_EXCEPTION_SUCCESS";
export const CANCEL_OVERBOOKING_EXCEPTION_FAILURE = "CANCEL_OVERBOOKING_EXCEPTION_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getOverBookingExceptionSuspensionDetails = payload => ({
  type: GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS,
  payload,
});

export const getOverBookingExceptionSuspensionDetailsRequest = payload => ({
  type: GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS_REQUEST,
  payload,
});

export const getOverBookingExceptionSuspensionDetailsSuccess = payload => ({
  type: GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS_SUCCESS,
  payload,
});

export const getOverBookingExceptionSuspensionDetailsFailure = payload => ({
  type: GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS_FAILURE,
  payload,
});

export const cancelOverbookingException = ({ capacityExceptionId, roomTypeBucketNumber }) => ({
  type: CANCEL_OVERBOOKING_EXCEPTION,
  capacityExceptionId,
  roomTypeBucketNumber,
});

export const cancelOverbookingExceptionRequest = () => ({
  type: CANCEL_OVERBOOKING_EXCEPTION_REQUEST,
});

export const cancelOverbookingExceptionSuccess = () => ({
  type: CANCEL_OVERBOOKING_EXCEPTION_SUCCESS,
});

export const cancelOverbookingExceptionFailure = error => ({
  type: CANCEL_OVERBOOKING_EXCEPTION_FAILURE,
  error,
});
