import { connect } from "react-redux";
import { getCurrentCustomerKey } from "../../core/selectors/persistent/customer/customerSelectors";
import getIsTrainingWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/training/getIsTrainingWorkflowFeatureFlagHidden";
import TempAppointmentColumn from "dux/_components/temp/tempAppointmentsColumn";
import AppointmentsComponent from "web/petParentProfile/appointments/AppointmentsComponent";
import { createLoadingSelector } from "core/selectors/utils";
import itinerariesActionTypes from "core/actionTypes/itinerariesActionTypes";
import petsActionTypes from "core/actionTypes/petsActionTypes";
import customersActionTypes from "core/actionTypes/customersActionTypes";

const mapStateToProps = (state, ownProps) => ({
  isHidden: getIsTrainingWorkflowFeatureFlagHidden(state),
  componentID: "appointmentsColumnTraining-AppointmentsComponent",
  isLoading: createLoadingSelector([
    itinerariesActionTypes.LOAD_ITINERARIES_BY_CUSTOMER,
    petsActionTypes.LOAD_PETS_BY_CUSTOMER,
    customersActionTypes.LOAD_CUSTOMER,
  ])(state),

  gridArea: "appointments",
  customerKey: getCurrentCustomerKey(state),
  disabled: !getCurrentCustomerKey(state),
  fromPetParent: true,
});

export default connect(mapStateToProps, null)(AppointmentsComponent);
