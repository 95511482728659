/**
 * Responsible for setting the alignment for text
 *
 * @export SetInputTextAlignment
 * @param {boolean} {
 *   centerText, isLeft, isRight, justifyText,
 * }
 * @returns
 */
export default function SetTextAlignment({ centerText, isLeft, isRight, justifyText }) {
  if (centerText) {
    return "center";
  }

  if (isLeft) {
    return "left";
  }

  if (isRight) {
    return "right";
  }

  if (justifyText) {
    return "justify";
  }

  return "inherit";
}
