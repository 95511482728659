import { hotelRoomCapacityActionTypes } from "./hotelRoomCapacityActions";

const initialState = {
  capacity: [],
};

const hotelRoomCapacityReducer = (state = initialState, action) => {
  switch (action.type) {
    case hotelRoomCapacityActionTypes.LOAD_HOTEL_ROOM_CAPACITY_SUCCESS:
      const response = action.hotelCapacity?.map(item => {
        return {
          ...item,
          physical: 1,
          overbooking: 0,
        };
      });
      return {
        ...state,
        capacity: response,
      };
    default:
      return state;
  }
};

export default hotelRoomCapacityReducer;
