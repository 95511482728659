import React from "react";
import { PetImage, PetImageWrapper } from "web/petCheckInOut/modals/checkInOutModalsLayout";

export default function PetImagesComponent({ isHidden, petPhotos }) {
  if (!isHidden) {
    return (
      <PetImageWrapper>
        {petPhotos.map(({ petId, petPhoto }) => (
          <PetImage key={petId} src={petPhoto} />
        ))}
      </PetImageWrapper>
    );
  }

  return null;
}
