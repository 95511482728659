import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Refreshes the session cache with the current version of the customer and pet
 * For System Services Booking API
 * - Refreshes session cache with current customer and pet data
 * @param {*} { customerId, petId }
 * @returns {*} AxiosPromise
 */
export function triggerSessionCacheRefresh({ customerId, petId }) {
  return bookingAPI.put(`${endpointVersionString()}/session/customers/${customerId}/pets/${petId}`);
}
