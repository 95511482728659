import { createSelector } from "reselect";
import { totalNumberOfOtherAddresses } from "../../../constants/petParentProfileConstants";
import getOtherAddresses from "./getOtherAddresses";

// Example of use:  getIsAddressLimitReached(state, { customerKey });
const getIsAddressLimitReached = createSelector(
  [getOtherAddresses],
  otherAddresses => otherAddresses.length >= totalNumberOfOtherAddresses
);

export default getIsAddressLimitReached;
