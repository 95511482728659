import { searchForFeatureActionTypes } from "./searchForFeatureActions";

const initialState = {
  searchText: ""
};

const searchForFeatureReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchForFeatureActionTypes.SEARCH_FOR_FEATURE:
      return {
        ...state,
        searchText: action.searchValue
      };
    default:
      return state;
  }
};

export default searchForFeatureReducer;
