import { filter, includes } from "lodash/fp";
import normalizeString from "../stringUtils/normalizeString";

/**
 * responsible for searching through a list of appointment objects and filtering them
 * by Pet Name, Room Number or Owner/Customer.
 * @param appointments - Collection of appointment object.
 * @param searchText - String of what to search for.
 * @returns Array of filtered appointments
 */
export default function filterAppointmentsByPetNameRoomOwner(appointments, searchText) {
  const filteredAppointments = filter(({ petName, roomNumber, customer }) => {
    const matchesSearchText = includes(normalizeString(searchText));
    const room = roomNumber ?? "unassigned";
    const isPetMatch = matchesSearchText(normalizeString(petName));
    const isRoomMatch = matchesSearchText(normalizeString(room));
    const isOwnerMatch = matchesSearchText(normalizeString(customer));

    return isPetMatch || isRoomMatch || isOwnerMatch;
  }, appointments);

  return filteredAppointments;
}
