import { put, takeEvery, call, all, select } from "redux-saga/effects";
import {
  GET_STORE_SETTINGS,
  getStoreSettingsRequest,
  getStoreSettingsSuccess,
  getStoreSettingsFailure,
} from "./storeSettingsActions";
import { fetchStoreSettings } from "@/core/services/systemServicesBooking/storeSettingsEndpoints";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import normalizeArrayByProperty from "@/core/utils/normalizeUtils/normalizeArray";

function* onGetStoreSettings() {
  try {
    const storeNumber = yield select(getStoreNumber);
    yield put(getStoreSettingsRequest());
    const response = yield call(fetchStoreSettings, storeNumber);
    const settings = normalizeArrayByProperty(response?.data?.result?.settings, "name");
    yield put(getStoreSettingsSuccess(settings));
  } catch (error) {
    yield put(getStoreSettingsFailure(error));
  }
}

function* watchOnGetStoreSettings() {
  yield takeEvery(GET_STORE_SETTINGS, onGetStoreSettings);
}

export default function* storeSettingsSaga() {
  yield all([watchOnGetStoreSettings()]);
}
