import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";

// Selectors
import { selectHotelPetsNotCanceled } from "../hotelEngagements/hotelEngagementSelectors";
import { getPetsByIds } from "@/core/selectors/entitiesSelector";
import { selectItineraryInvoiceIsPaid } from "../hotelItinerary/hotelItinerarySelectors";
import { createLoadingSelector } from "@/core/selectors/utils";

// Actions
import { hideCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import {
  GET_ITINERARY_HISTORY_LIST,
  getItineraryHistoryList,
} from "../itineraryHistoryList/itineraryHistoryListActions";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { Button, Heading, TextPassage } from "@petsmart-ui/sparky";
import { LayoutStack } from "@/layout/stack/Stack";
import { HotelCheckOutCompletePet } from "./checkOutCompleteModalPet";
import LoadingWrapper from "@/web/common/LoadingWrapper";

// Utils
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import joinWithLastDelimiter from "@/core/utils/stringManipulationUtils/joinWithLastDelimiter";

// Constants
import { routePaths } from "@/core/constants/routePaths";
import { checkOutComplete } from "./checkOutCompleteConstants";
import { engagementTypeIds } from "@/web/setSystemType/constants/setSystemTypeConstants";

/**
 * React view component for check out complete modal
 * @memberOf Views.Customer
 * @function
 * @name CheckOutComplete
 * @param {Object} props
 * @param {string} props.componentId
 * @param {Boolean} props.isHidden
 * @returns {JSX.Element|null}
 * @example const container = connect()(CheckOutComplete)
 */
export const CheckOutComplete = props => {
  const {
    componentId,
    isHidden,
    buttonLabel,
    headerText,
    onClick = () => {},
    pets,
    petComponent,
    invoiceMsg,
    isLoading,
    loadFutureCustomerAppointments = () => {},
  } = props;

  useEffect(() => {
    loadFutureCustomerAppointments();
  }, []);

  if (isHidden) return null;

  const Pet = petComponent;

  return (
    <LayoutBox id={componentId}>
      <LayoutStack style={{ alignItems: "center" }} space="scale-G1">
        <Heading tagName="h4">{headerText}</Heading>

        {invoiceMsg && <TextPassage>{invoiceMsg}</TextPassage>}

        <LoadingWrapper isLoading={isLoading}>
          <LayoutStack>
            {pets?.map(pet => (
              <Pet key={pet?.petId} pet={pet} />
            ))}
          </LayoutStack>
        </LoadingWrapper>

        <Button onClick={onClick} variant="primary" text={buttonLabel} />
      </LayoutStack>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for hotel check out complete modal
 * @see {@link Views.Customer.CheckOutAlerts}
 * @summary Located on the hotel check-out page
 * @memberOf Views.Customer
 * @function
 * @name HotelCheckOutComplete
 * @returns {JSX.Element|null}
 * @example <HotelCheckOutComplete />
 */
export const HotelCheckOutComplete = compose(
  withRouteProps,
  connect(
    state => {
      const hasPaid = selectItineraryInvoiceIsPaid(state);

      // Constants strs
      const { greatJob, done, singlePetLeft, multiPetLeft, invoiceMsg } = checkOutComplete;

      const petIds = selectHotelPetsNotCanceled(state);
      const isMultiPet = petIds?.length > 1;
      const pets = getPetsByIds(state, { petIds });
      const petNamesArray = pets?.map(pet => pet?.petName);
      const petNamesString = joinWithLastDelimiter(petNamesArray);
      const singlePetStr = `${petNamesArray[0]} ${singlePetLeft}`;
      const multiPetStr = `${petNamesString} ${multiPetLeft}`;

      return {
        componentId: "HotelCheckOutComplete",
        buttonLabel: done,
        headerText: `${greatJob} ${isMultiPet ? multiPetStr : singlePetStr}`,
        pets,
        invoiceMsg: !hasPaid && invoiceMsg,
        isLoading: createLoadingSelector([GET_ITINERARY_HISTORY_LIST])(state),
        petComponent: HotelCheckOutCompletePet,
      };
    },
    (dispatch, { router: { navigate, params } }) => {
      return {
        onClick: () => {
          dispatch(hideCheckInOutModal());
          navigate(routePaths.DASHBOARD);
        },
        loadFutureCustomerAppointments: () => {
          const customerKey = params?.customerKey;
          if (!customerKey) return;

          const productFamilyID = [
            engagementTypeIds.DAY_CAMP,
            engagementTypeIds.DAY_CARE,
            engagementTypeIds.OVERNIGHT_BOARDING,
          ].join(",");

          dispatch(getItineraryHistoryList({ customerKey, productFamilyID, from: moment() }));
        },
      };
    },
  ),
)(CheckOutComplete);
