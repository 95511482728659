import { connect } from "react-redux";
import * as refundPackageModalTypes from "@/dux/_constants/refundPackageModalTypes";
import { getIsRefundPackageConfirmationModalHidden } from "./refundPackageConfirmationModalSelectors";
import { displayRefundPackageConfirmationModal } from "./refundPackageConfirmationModalActions";

import RefundPackageModalContentComponent from "../_components/refundPackageModals/RefundPackageModalContentComponent";
import RefundPackageConfirmationContentContainer from "./RefundPackageConfirmationContentContainer";

const mapStateToProps = state => ({
  isHidden: getIsRefundPackageConfirmationModalHidden(state), // a selector that has the modal is hidden,
  compName: refundPackageModalTypes.REFUND_CONFIRMATION,
  diComp: {
    [refundPackageModalTypes.REFUND_CONFIRMATION]: RefundPackageConfirmationContentContainer,
  },
});

const mapDispatchToProps = dispatch => ({
  onModalClose: () => dispatch(displayRefundPackageConfirmationModal({ isHidden: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundPackageModalContentComponent);
