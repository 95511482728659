import { associateWebProfileAPI } from "./_axiosConfigAssociateWebProfile";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Add a new healthCondition for a Pet from the Web Associate Profile API - Add a new healthCondition for a Pet end point
 * @param {*} { customerKey, petId, conditionId, sourceId }
 * @returns
 */
export function postPetHealthCondition({ customerKey, petId, conditionId, sourceId }) {
  return associateWebProfileAPI.post(
    `${endpointVersionString()}/customers/${customerKey}/pets/${petId}/petConditions?sourceId=${sourceId}`,
    {
      sourceId,
      conditionId
    }
  );
}

/**
 * delete a healthCondition for a Pet from the Web Associate Profile API - Deletes a pet's healthCondition end point
 * @param {*} { customerKey, petId, petConditionId, sourceId }
 * @returns
 */
export function deletePetHealthCondition({ customerKey, petId, petConditionId, sourceId }) {
  return associateWebProfileAPI.delete(
    `${endpointVersionString()}/customers/${customerKey}/pets/${petId}/petConditions/${petConditionId}?sourceId=${sourceId}`
  );
}
