import { connect } from "react-redux";
import { isFTCOHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import { FTCO } from "@/web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";
import { getPackagesByType } from "../bulkPackageOfferings/bulkPackageOfferingsSelectors";
import { getCurrentPetServiceItem } from "@/core/selectors/checkInOutSelector";
import { getPetServiceItem } from "@/core/selectors/entitiesSelector";
import PackageOfferTable from "@/dux/_components/packageOffers/PackageOfferTable";

const mapStateToProps = state => {
  const currentPetServiceItemId = getCurrentPetServiceItem(state);
  const petServiceItem = getPetServiceItem(state, { petServiceItemId: currentPetServiceItemId });
  const petServiceId = petServiceItem?.petServiceId;

  return {
    componentID: "FTCOPackageList__PackageOfferTable",
    isHidden: isFTCOHidden(state),
    packageList: getPackagesByType({ state, type: FTCO, petServiceId }),
  };
};

export default connect(mapStateToProps)(PackageOfferTable);
