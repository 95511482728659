import { connect } from "react-redux";
import { setSelectedAddOn } from "./bulkPackageAddOnsActions";
import { getSelectedAddOn } from "../bulkPackageOfferingsSelectors";
import { getSelectedBulkPackageOfferingsPetServiceItem } from "../../../toggleArrow/toggleArrowSelectors";
import Checkbox from "../../../../web/common/Checkbox";

const mapStateToProps = (state, { itemId }) => {
  const petServiceId = getSelectedBulkPackageOfferingsPetServiceItem(state);
  const selectedAddOn = getSelectedAddOn(state);

  return {
    componentId: `bulk-package-addOn-checkbox-${petServiceId}-${itemId}`,
    checked: selectedAddOn[itemId],
    name: itemId,
    value: itemId,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchClick: id => dispatch(setSelectedAddOn(id)),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  onChange: () => propsFromDispatch.dispatchClick(propsFromState.value),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Checkbox);
