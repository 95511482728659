import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_HOTEL_ADDONS_BY_PET_SERVICE,
  getHotelAddOnsByPetServiceFailure,
  getHotelAddOnsByPetServiceRequest,
  getHotelAddOnsByPetServiceSuccess,
} from "dux/hotelAddons/hotelAddonsLIstActions";
import { getHotelAddOnsByPetService } from "core/services/systemServicesBooking/addOnEndPoints";

export function* onGetHotelAddOnsByPetService({ storeNumber, petServiceId, petId, checkInDate }) {
  try {
    yield put(getHotelAddOnsByPetServiceRequest());

    const results = yield call(getHotelAddOnsByPetService, {
      storeNumber,
      petservices: petServiceId,
      pets: petId,
      checkInDate,
    });

    const payload = results.data.result;
    const descriptors = results?.data?.metadata?.serviceDescriptors;

    yield put(getHotelAddOnsByPetServiceSuccess({ petId, addons: payload, descriptors }));
  } catch (error) {
    yield put(getHotelAddOnsByPetServiceFailure(error));
  }
}

function* watchOnGetHotelAddOnsByPetService() {
  yield takeEvery(GET_HOTEL_ADDONS_BY_PET_SERVICE, onGetHotelAddOnsByPetService);
}

export default function* hotelAddonsLIstSaga() {
  yield all([fork(watchOnGetHotelAddOnsByPetService)]);
}
