import React from "react";
import styled from "styled-components";
import Img from "./Img";

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  cursor: pointer;
`;

const ProfileImage = styled(Img)`
  border-radius: 50%;
  margin: 5px;
  height: 100px;
  width: 100px;
  box-shadow: 1px 1px 22px 3px #1e1e1e;
  ${props =>
    props.isSelected &&
    `
        box-shadow: 1px 1px 30px 3px #fff;
    `} transition: 0.75s box-shadow;
  &:hover {
    box-shadow: 1px 1px 30px 3px #fff;
  }
`;

const ProfileTitle = styled.div`
  margin: 10px;
  color: #fff;
`;

const ProfileImageButton = ({ onClick, image, name, isSelected }) => (
  <SectionWrapper onClick={onClick}>
    <ProfileImage src={image} isSelected={isSelected} />
    <ProfileTitle>{name}</ProfileTitle>
  </SectionWrapper>
);

export default ProfileImageButton;
