import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { cancellationsReasons, VPTClassStatuses } from "../trainingClassSessions/constants";
import { routePaths } from "@/core/constants/routePaths";
import {
  cancelTrainingClassItinerary,
  CANCEL_TRAINING_CLASS_ITINERARY,
} from "@/dux/trainingClassItineraries/trainingClassItinerariesActions";
import {
  toggleCancelTrainingAppointmentModal,
  toggleCancelTrainingAppointmentFlashMessage,
} from "./manageTrainingClassPageActions";
import { getIsCancelTrainingAppointmentModalOpen } from "./manageTrainingClassPageSelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getTrainingAttendeeById } from "@/dux/trainingClassSessions/selectors";
import { CancelAppointmentModal } from "@/dux/_components/cancelTrainingAppointmentModal/CancelTrainingAppointmentModal";
import { createLoadingSelector } from "@/core/selectors/utils";

export default withRouteProps(
  connect(
    (state, { attendeeId }) => {
      const storeNumber = getStoreNumber(state);
      const { customerId, itineraryId, engagementId } = getTrainingAttendeeById(state, {
        storeNumber,
        attendeeId,
      });
      return {
        customerId,
        itineraryId,
        engagementId,
        isHidden: !getIsCancelTrainingAppointmentModalOpen(state),
        isLoading: createLoadingSelector([CANCEL_TRAINING_CLASS_ITINERARY])(state),
      };
    },
    (dispatch) => ({
      dispatchCancelTrainingClassItinerary: ({
        customerKey,
        itineraryId,
        engagementId,
        onComplete,
      }) => {
        dispatch(
          cancelTrainingClassItinerary({
            customerKey,
            itineraryId,
            engagementId,
            onComplete,
            status: VPTClassStatuses.CANCELED,
            reason: cancellationsReasons.BY_OWNER,
          }),
        );
      },
      dispatchToggleCancelTrainingAppointmentModal: () => {
        dispatch(toggleCancelTrainingAppointmentModal());
      },
      toggleCancelTrainingAppointmentFlashMessage: () => {
        dispatch(toggleCancelTrainingAppointmentFlashMessage());
      },
    }),
    (propsFromState, propsFromDispatch, ownProps) => ({
      ...propsFromState,
      ...propsFromDispatch,
      ...ownProps,

      onSubmit: () => {
        const { customerId, itineraryId, engagementId } = propsFromState;
        const {
          dispatchCancelTrainingClassItinerary,
          dispatchToggleCancelTrainingAppointmentModal,
          toggleCancelTrainingAppointmentFlashMessage,
        } = propsFromDispatch;
        const { router: { navigate } } = ownProps;

        dispatchToggleCancelTrainingAppointmentModal();

        dispatchCancelTrainingClassItinerary({
          customerKey: customerId,
          itineraryId,
          engagementId,
          onComplete: () => {
            navigate(`${routePaths.PET_PARENT_PROFILE}/${customerId}`);
            toggleCancelTrainingAppointmentFlashMessage();
          },
        });
      },
      onClose: () => {
        const { dispatchToggleCancelTrainingAppointmentModal } = propsFromDispatch;
        dispatchToggleCancelTrainingAppointmentModal();
      },
    }),
  )(CancelAppointmentModal),
);
