/* (Context of the ServicesManagementToggle) - We need to know if there is an actual suspension to cancel and not just a filled out form, so we can't use local
state for reason or additional details, we need to pull those values from Props, that way if there is no scheduled
suspension, they can't just fill out the form and get a cancel, they have to have an actual suspension, that was
provided via props. */

import moment from "moment/moment";

/**
 * Determines if a pre-scheduled suspension can be canceled.
 *
 * @memberOf Views.ScheduledSuspensions
 * @function
 * @param {boolean} isFormContentVisible - Flag indicating if the form content is visible.
 * @param {string} reason - The reason for the scheduled suspension.
 * @param {string} additionalDetails - Additional details for the scheduled suspension.
 * @returns {boolean} - Flag indicating if the scheduled suspension can be canceled.
 */
export const ableToCancelScheduledSuspension = (
  isFormContentVisible,
  reason,
  additionalDetails,
) => {
  return !!(isFormContentVisible && reason && additionalDetails);
};

/**
 * Determines if a new suspension can be cancelled.
 *
 * A new suspension can be cancelled if both the form content is visible and there is a success message indicating that
 * a suspension was just scheduled. If the form content is not visible, the success message is disregarded.
 *
 * @memberOf Views.ScheduledSuspensions
 * @function
 * @param {boolean} isFormContentVisible - Indicates if the form content is visible.
 * @param {string} successMessage - The success message indicating that a suspension was just scheduled.
 * @returns {boolean} - Returns true if a new suspension can be cancelled, otherwise false.
 */
export const ableToCancelNewSuspension = (isFormContentVisible, successMessage) => {
  return !!(isFormContentVisible && successMessage);
};

/**
 * Checks if the user is able to cancel a suspension.
 * User is only able to cancel if it's a previously or newly scheduled suspension e.g. a simple filled out form will not work.
 *
 * @memberOf Views.ScheduledSuspensions
 * @function
 * @param cancelScheduledSuspension
 * @param cancelNewSuspension
 * @returns {boolean} - True if either the scheduled suspension or new suspension can be cancelled, false otherwise.
 */
export const ableToCancelASuspension = (cancelScheduledSuspension, cancelNewSuspension) => {
  return cancelScheduledSuspension || cancelNewSuspension;
};

/**
 * Helper function to find suspension based on date and suspensionTypeId.
 *
 * @memberOf helpers.ScheduledSuspensions
 * @function
 * @param {Array} scheduledSuspensions - list of scheduled suspensions
 * @param {String} selectedDate - selected date
 * @param {String} suspensionTypeId - Id of the suspension type
 * @returns {boolean} - True there is a scheduled suspension with a matching date and id.
 */
export const findSuspensionHelper = (scheduledSuspensions, selectedDate, suspensionTypeId) => {
  return scheduledSuspensions?.find(item => {
    // Inject Moment.isSame to verify that the suspension startDate and the Selected start date are the same regardless of string passed in
    const itemStartDate = moment(item.startDate);
    return itemStartDate.isSame(selectedDate) && item.suspensionTypeId === suspensionTypeId;
  });
};
