import React, { useEffect } from "react";
import { HotelCheckInPage, SalonCheckInPage } from "dux/checkInOut/CheckInOutPage";
import { connect } from "react-redux";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import customerActionCreator from "core/actionCreators/customer/customerActionCreator";
import { setItineraryId } from "core/actionCreators/ui/web/generalActionCreators";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const checkInOutPageComponents = {
  [systemName.SALON]: SalonCheckInPage,
  [systemName.HOTEL]: HotelCheckInPage,
};

export const CheckInOutPageShell = props => {
  const { pageComponent, isHidden, setCurrent } = props;

  useEffect(() => {
    if (!isHidden) setCurrent();
  }, []);

  if (isHidden) return null;

  const CheckInOutPageComp = pageComponent;
  return <CheckInOutPageComp {...props} />;
};

export const CheckInOut = withRouteProps(
  connect(
    state => {
      const bookingFlow = getSystemBookingFlow(state);
      const pageComponent = checkInOutPageComponents[bookingFlow];
      return { pageComponent, isHidden: !pageComponent };
    },
    (dispatch, ownProps) => {
      const { customerKey, itineraryId } = ownProps.router.params;

      return {
        setCurrent: () => {
          dispatch(
            customerActionCreator.setCurrentCustomerKey({ currentCustomerKey: customerKey }),
          );
          dispatch(setItineraryId({ itineraryId })); // note this is for state.ui.web.checkInOut.currentItinerary not state.ui.web.booking.itineraryId
        },
      };
    },
  )(CheckInOutPageShell),
);
