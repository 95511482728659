import React from "react";
import { connect } from "react-redux";
import { getCalendarCellData } from "@/dux/trainingClassSessions/selectors";
import { List, Text } from "@prism/psm-ui-components";

function CalendarCellContentList({ isToday, cellData = [] }) {
  const isCellContentAvailable = cellData.length;

  return (
    <List
      className="virtual-dog-training-calendar-cell-content-list"
      items={cellData}
      isHidden={!isCellContentAvailable}
    >
      {({ id, label }) => (
        <Text key={id} color={isToday ? "text-color-white" : "text-color-black"} bold={900}>
          {label}
        </Text>
      )}
    </List>
  );
}


const mapStateToProps = (state, { date, isToday }) => {
  return {
    isToday,
    cellData: getCalendarCellData(state, { date }),
  };
};

export default connect(mapStateToProps)(CalendarCellContentList);
