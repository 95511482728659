import { createSelector } from "reselect";
import getWorkflowsFeatureFlagState from "./_workflowsFeatureFlagState";
import getSearchFeatureText from "../../searchForFeature/selectors/getSearchFeatureText";
import valueMatchesSearchString from "../../../core/utils/stringManipulationUtils/valueMatchesSearchString";

const getWorkflowFeatureFlagsList = createSelector(
  [getWorkflowsFeatureFlagState, getSearchFeatureText],
  (workflowFeatureFlags, searchFeatureText) => {
    //
    // Wrap all feature flag objects in an array so the UI can easily map over them.
    const list = Object.values(workflowFeatureFlags);

    // Looking at the id value in each object in the list and and filtering out any items that do not
    // match the text that was searched.
    return list.filter(workflowFeatureFlag =>
      valueMatchesSearchString(workflowFeatureFlag.id, searchFeatureText),
    );
  },
);

export default getWorkflowFeatureFlagsList;
