import { connect } from "react-redux";
import { getCancelAppointmentModalType } from "../../core/selectors/modals/cancelAppointmentModalSelector";
import CancelAppointmentModal from "./CancelAppointmentModalComp";
import { hideCancelAppointmentModal } from "../../core/actionCreators/cancellationsActionCreators";

const mapStateToProps = state => ({
  cancelAppointmentModalType: getCancelAppointmentModalType(state),
});

const mapDispatchToProps = dispatch => ({
  handleClick: () => dispatch(hideCancelAppointmentModal()),
});

// const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
//     ...propsFromState,
//     ...propsFromDispatch,
//     ...ownProps,

//     keyName: ({  }) => propsFromDispatch.functionName({
//         keyName:	propsFromState.value,
//     }),
//     keyName: ({  }) => propsFromDispatch.functionName({
//         keyName:	propsFromState.value,
//     }),
// })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // mergeProps,
)(CancelAppointmentModal);
