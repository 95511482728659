import { put, call, all, takeLatest } from "redux-saga/effects";
import { getGeoCodingByPostalCode } from "@/core/services/googleGeoCoding/googleGeoCoding.api";
import {
  GET_SEARCH_ADDRESS_BY_ZIP,
  getSearchAddressByZipRequest,
  getSearchAddressByZipSuccess,
  getSearchAddressByZipFailure,
  setZipSearchCityOptions,
} from "./searchAddressByZipActions";
import { parseGoogleAddressResponse } from "./searchAddressByZipHelpers";

function* onGetSearchAddressByZip({ zipCode }) {
  try {
    yield put(getSearchAddressByZipRequest());
    const response = yield call(getGeoCodingByPostalCode, zipCode);
    const results = response?.results ?? [];

    const address = parseGoogleAddressResponse(results[0]?.address_components);
    yield put(getSearchAddressByZipSuccess(address));

    const cityOptions = results[0]?.postcode_localities;
    yield put(setZipSearchCityOptions(cityOptions));
  } catch (error) {
    yield put(getSearchAddressByZipFailure());
  }
}

function* watchOnGetSearchAddressByZip() {
  yield takeLatest(GET_SEARCH_ADDRESS_BY_ZIP, onGetSearchAddressByZip);
}

export default function* searchAddressByZipSaga() {
  yield all([watchOnGetSearchAddressByZip()]);
}
