/**
 * ROOM SUSPENSIONS TYPE ID - 120
 */
export const SUSPENSION_TYPE_ID = "120";

export const ROOM_SUSPENSION_STATUS = {
  scheduled: "Scheduled",
  canceled: "Canceled",
  completed: "Completed",
};
