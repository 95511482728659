import React from "react";
import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import Img from "@/web/common/Img";
import getUnknownPetImage from "@/core/utils/assetUtils/unknownPetImage";
import { selectPet } from "@/core/actionCreators/bookingActionCreators";
import { setPetId } from "@/core/actionCreators/ui/web/generalActionCreators";
import { setPetServiceItem } from "@/core/actionCreators/checkInOutActionCreator";
import { onSelectAppointment } from "@/core/actionCreators/ui/web/commonActionCreators";
import { getCurrentCustomerKey } from "../../core/selectors/persistent/customer/customerSelectors";
import { getPet, getPetBreedName } from "@/core/selectors/entitiesSelector";
import calculateAge from "@/core/utils/dateUtils/calculateAge";
import { formatPropsForList } from "../_utils/uiComponentUtils";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";

// Components
import { PetCard, Text, Icon } from "@prism/psm-ui-components";
import { HotelRebookButton } from "@/dux/appointmentRebook/AppointmentRebookButton";
import { HotelPetCardRebookLoginMsg } from "../appointmentRebook/AppointmentRebookLoginMsg";

// HOTEL ITINERARY HISTORY PET CARD CONTAINER ------------------------------------------------------------------------
export const HotelItineraryHistoryPetCard = compose(
  withRouteProps,
  connect(
    (state, ownProps) => {
      const { petId } = ownProps.reservation;
      const pet = getPet(state, { petId }) || {};

      return {
        customerKey: getCurrentCustomerKey(state),
        pet,
        petBreed: getPetBreedName(state, pet),
        currentStore: getStoreNumber(state),
      };
    },

    (dispatch, ownProps) => {
      const {
        reservation,
        getAppointmentURI,
        router: { navigate },
      } = ownProps;
      const { petId, status, itineraryId } = reservation;

      return {
        handlePetCardClick: ({ customerKey, appointmentNumber }) => {
          dispatch(selectPet(petId));
          dispatch(setPetId(petId));
          dispatch(setPetServiceItem({ petServiceItem: Number(appointmentNumber) }));
          dispatch(onSelectAppointment({ appointmentId: appointmentNumber }));
          navigate(`${getAppointmentURI(status)}/${customerKey}/${itineraryId}`);
        },
      };
    },

    (stateProps, dispatchProps, ownProps) => {
      const { customerKey, pet, petBreed, currentStore } = stateProps;
      const { handlePetCardClick } = dispatchProps;
      const { reservation } = ownProps;
      const {
        startDateTime,
        endDateTime,
        storeNumber,
        metadata,
        engagementIds,
        petServiceName,
        status,
        itineraryId,
      } = reservation;

      const isFromCurrentStore = currentStore === storeNumber;

      // Get pet data
      const { petName, birthDate, speciesId } = pet;
      const petAge = calculateAge(birthDate);

      // Format Dates
      const isOvernight = !moment(startDateTime).isSame(moment(endDateTime), "day");
      const formatedStart = moment(startDateTime).format("l LT");
      const formattedEnd = moment(endDateTime).format(isOvernight ? "l LT" : "LT");

      // Format Room Name
      const assignedRoom = metadata?.roomName ?? "N/A";

      // Get Appointment #
      const appointmentNumber = engagementIds.length ? engagementIds[0] : "";

      return {
        componentId: "HotelItineraryHistoryPetCard",
        petImage: <Img src={getUnknownPetImage({ speciesId })} />,
        topSectionRows: formatPropsForList([
          <>
            <Text bold size="text-size-lg">
              {petName || "Loading..."},&nbsp;
            </Text>
            <Text size="text-size-lg">
              {petAge}, {petBreed}
            </Text>
          </>,
          <Text>{petServiceName}</Text>,
          <Text>
            {formatedStart} to {formattedEnd}
          </Text>,
        ]),
        bottomSectionRows: formatPropsForList([
          <Text>Appointment #: {appointmentNumber}</Text>,
          <Text>Status: {status}</Text>,
          <Text>Store: {storeNumber}</Text>,
          <Text>Assigned Room: {assignedRoom}</Text>,
          <HotelRebookButton itineraryId={itineraryId} isHidden={!isFromCurrentStore} />,
          <HotelPetCardRebookLoginMsg storeNumber={storeNumber} />,
        ]),
        serviceIcon: (
          <Icon
            variant="psm-icon-ph"
            useColorForFill
            color="psm-icon-color-white"
            size="psm-icon-size-48"
            style={{ backgroundColor: "#1777f2" }}
          />
        ),
        onClick: event => {
          handlePetCardClick({ customerKey, appointmentNumber });
        },
      };
    },
  ),
)(PetCard);
