import { put, takeEvery, call, all, select } from "redux-saga/effects";
import { get } from "lodash/fp";
import {
  GET_ENGAGEMENT_SERVICE_DETAILS,
  getEngagementServiceDetailsRequest,
  getEngagementServiceDetailsSuccess,
  getEngagementServiceDetailsFailure,
} from "./digitalServiceCardActions";
import { getEngagementServiceDetails } from "@/core/services/systemServicesBooking/engagementServiceDetailsEndpoints";
import { getEngagement } from "@/core/selectors/entitiesSelector";
import { loadEngagement } from "@/core/actionCreators/engagementsActionCreators";

export function* onGetEngagementServiceDetails({ customerKey, itineraryId, engagementId }) {
  try {
    yield put(getEngagementServiceDetailsRequest());

    const { data } = yield call(getEngagementServiceDetails, {
      customerKey,
      itineraryId,
      engagementId,
    });
    const questions = get(["result", "questions"], data);
    const engagement = yield select(getEngagement, { engagementId });

    yield put(
      loadEngagement({
        engagement: {
          ...engagement,
          questions,
        },
      }),
    );

    yield put(getEngagementServiceDetailsSuccess());
  } catch (error) {
    yield put(getEngagementServiceDetailsFailure({ error }));
  }
}

function* watchLoadGetEngagementServiceDetails() {
  yield takeEvery([GET_ENGAGEMENT_SERVICE_DETAILS], onGetEngagementServiceDetails);
}

export default function* getEngagementServiceDetailsSaga() {
  yield all([watchLoadGetEngagementServiceDetails()]);
}
