import { history } from "@/dux/utils/browser/browserHistory";
import { routePaths } from "../../core/constants/routePaths";
import { NEW_MEDICATION_ID } from "../newMedication/newMedicationConstants";

export const shouldHideAddMedicationButton = (type, medicationId) => {
  if (medicationId !== NEW_MEDICATION_ID) {
    return true;
  } else if (history.location.pathname.includes("select-service") && type === routePaths.BOOKING) {
    return false;
  } else if (
    history.location.pathname.includes("pet-parent-profile") &&
    type === routePaths.PET_PARENT_PROFILE
  ) {
    return false;
  }
  return true;
};
