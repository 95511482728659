import { BundleHistoryDateListHeader } from "../bgm/bundleHistory/BundleHistoryDateListHeaderContainer";
import BundleRedeemedDatesListHeaderContainer from "../bgm/availableBundlesByPet/availableBundlesList/BundleRedeemedDatesListHeaderContainer";
import BundleReservedDatesListHeaderContainer from "../bgm/availableBundlesByPet/availableBundlesList/BundleReservedDatesListHeaderContainer";

export const headerComponentTypes = {
  REDEEMED_BUNDLES: "REDEEMED_BUNDLES",
  RESERVED_BUNDLES: "RESERVED_BUNDLES",
  BUNDLE_HISTORY: "BUNDLE_HISTORY",
};

export const headerComponents = {
  [headerComponentTypes.BUNDLE_HISTORY]: BundleHistoryDateListHeader,
  [headerComponentTypes.REDEEMED_BUNDLES]: BundleRedeemedDatesListHeaderContainer,
  [headerComponentTypes.RESERVED_BUNDLES]: BundleReservedDatesListHeaderContainer,
};
