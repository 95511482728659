import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { patchHotelReservationNotesEndpoint } from "@/core/services/systemServicesBooking/getCreateUpdateDeleteHotelItineraryEndpoints";
import {
  PATCH_HOTEL_ITINERARY_PET_NOTES,
  patchHotelItineraryPetNotesRequest,
  patchHotelItineraryPetNotesSuccess,
  patchHotelItineraryPetNotesFailure,
} from "./hotelItineraryPetNotesActions";
import { saveHotelItineraryResponse } from "../hotelItinerary/hotelItinerarySaga";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getCurrentItinerary } from "@/core/selectors/checkInOutSelector";

/**
 * Saga to update pet notes for an itinerary
 * @memberOf Sagas.hotel.itinerary
 * @function
 * @name onPatchHotelItineraryPetNotes
 * @param {string} petNotesData - request payload for updating pet notes
 */
function* onPatchHotelItineraryPetNotes({ petNotesData }) {
  try {
    yield put(patchHotelItineraryPetNotesRequest());

    const itineraryId = yield select(getCurrentItinerary);
    const customerId = yield select(getCurrentCustomerKey);

    const response = yield call(patchHotelReservationNotesEndpoint, {
      itineraryId,
      customerId,
      data: petNotesData,
    });

    // Parse and store hotel itinerary response
    yield call(saveHotelItineraryResponse, { responseData: response?.data?.result });

    yield put(patchHotelItineraryPetNotesSuccess());
  } catch (error) {
    yield put(patchHotelItineraryPetNotesFailure(error));
  }
}

function* watchOnPatchHotelItineraryPetNotes() {
  yield takeLatest(PATCH_HOTEL_ITINERARY_PET_NOTES, onPatchHotelItineraryPetNotes);
}

export default function* hotelItineraryPetNotesPatchSaga() {
  yield all([watchOnPatchHotelItineraryPetNotes()]);
}
