import { connect } from "react-redux";
import PetProfileFoodComponent from "./PetProfileFoodComponent";
import { getPetFoods, getFoodIds } from "./foodsSelector";
import { getCurrentPet } from "../../core/selectors/persistentSelectors";
import { setFoods } from "./actions/foodsActions";
import normalizeArrayByProperty from "../../core/utils/normalizeUtils/normalizeArray";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "../setSystemType/selectors/setSystemTypeSelectors";
import getIsHotelWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { createLoadingSelector } from "@/core/selectors/utils";
import { createUpdateDeleteFoodActionTypes } from "./actions/createUpdateDeleteFoodActions";
import { getFoodOptionsList } from "@/dux/foodOptionsList/foodOptionsListActions";
import selectIsDiscontinuedFoodsFeatureHidden from "../enableDisableWorkflowFeatureFlag/selectors/discontinuedFoods/getIsDiscontinuedFoodsWorkflowFeatureFlagHidden";

const mapStateToProps = state => {
  const petId = getCurrentPet(state);
  const foodIds = getFoodIds(state, { petId });
  return {
    petId,
    isHidden:
      getSystemBookingFlow(state) !== systemName.HOTEL ||
      getIsHotelWorkflowFeatureFlagHidden(state),
    foods: getPetFoods(state, { petId }),
    showBottomBorder: foodIds.length === 0,
    isLoading: createLoadingSelector([createUpdateDeleteFoodActionTypes.UPDATE_PET_FOOD])(state),
    isFoodFeatureHidden: selectIsDiscontinuedFoodsFeatureHidden(state),
  };
};

const mapDispatchToProps = dispatch => ({
  setFoods: (foods, petId) => {
    const normalizedFoods = normalizeArrayByProperty(foods, "petFoodId");
    dispatch(setFoods({ foods: normalizedFoods, petId }));
  },
  loadFoodOptions: () => dispatch(getFoodOptionsList()),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,
  loadFoodOptions: () => {
    const { isFoodFeatureHidden } = propsFromState;
    const { loadFoodOptions } = propsFromDispatch;
    if (!isFoodFeatureHidden) loadFoodOptions();
  },
  setPetFoods: foods => {
    const { petId } = propsFromState;
    propsFromDispatch.setFoods(foods, petId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PetProfileFoodComponent);
