import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { Button } from "@prism/psm-ui-components";
import { isBookingDisabled } from "dux/eligibility/helperUtils/checkIsBookingDisabled";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { routePaths } from "@/core/constants/routePaths";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);

  return {
    isHidden: getSystemBookingFlow(state) !== systemName.TRAINING,
    componentId: "bookAppointmentTraining-commonButton",
    disabled: isBookingDisabled(state, customerKey),
    variant: "prism-primary",
    children: "Schedule Class",
  };
};

const mapDispatchToProps = (_, { router: { navigate } }) => ({
  onClick: () => {
    navigate(routePaths.SCHEDULE_TRAINING);
  },
});

export default withRouteProps(connect(mapStateToProps, mapDispatchToProps)(Button));
