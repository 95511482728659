import { createSelector } from "reselect";

export const getHotelRoomCapacityState = state => {
  return state.hotelRoomCapacity;
};

export const getHotelRoomCapacityCapacityState = state => {
  return state.hotelRoomCapacity.capacity;
};

export const getHotelRoomCapacityByIndex = (state, index) => {
  return state.hotelRoomCapacity.capacity[index];
};

export const getHotelRoomCapacity = createSelector(
  [getHotelRoomCapacityState],
  HotelCapacity => HotelCapacity.capacity,
);
