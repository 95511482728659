// @ts-check

export const SET_RESERVATION_NOTES = "SET_RESERVATION_NOTES";

/**
 * @param {Object} obj
 * @param {String} obj.notes
 * @param {Number|String} obj.petId
 * @returns
 */
export const setPetReservationNotes = ({ notes, petId }) => ({
  type: SET_RESERVATION_NOTES,
  notes,
  petId,
});
