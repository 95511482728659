import moment from "moment";

/**
 * Returns a function that retrieves the pet ID associated with a given engagement ID.
 *
 * @memberOf Utils.Engagement
 * @function
 * @name getPetIdFromEngagementId
 * @param {Object} engagements - An object containing engagement IDs as keys and associated pet IDs as values.
 * @returns {Function} - A function that takes an engagement ID as a parameter and returns the associated pet ID, or undefined if the engagement ID is not found in the engagements object.
 *
 * @example
 *
 * const engagements = {
 *   "51137394": { petId: 000022671 },
 *   "51137395": { petId: 1000022405 }
 * };
 *
 * const getPetIdFunc = getPetIdFromEngagementId(engagements)(engagementId);
 * // or
 * const ids = engagementIds?.map(getPetIdFromEngagementId(engagements));
 *
 * getPetIdFunc("51137394"); // Returns 000022671
 * getPetIdFunc("51137395"); // Returns 1000022405
 */
export const getPetIdFromEngagementId = engagements => {
  return engagementId => {
    return engagements[engagementId]?.petId;
  };
};

/**
 * Returns a function that retrieves the pet ID and a engagementType associated with a given engagement ID.
 * NOTE: key for engagementType will be set as "serviceType" as required by the Get hotel booking eligibility by pet Ids api.
 *
 * @memberOf Utils.Engagement
 * @function
 * @name getPetIdAndEngagementTypeFromEngagementId
 * @param {Object} engagements - A Pets Hotel Engagement Object.
 * @returns {Function} - A function that takes an engagement ID as a parameter and returns the pet ID and a petServiceName associated with a given engagement ID, or undefined if the engagement ID is not found in the engagements object.
 *
 * @example
 *
 * const engagements = {
 *   '51137467': {
 *     serviceType: "Day Camp",
 *     petId: 1000022397,
 *   },
 *   '51137467': {
 *     serviceType: "Daycare",
 *     petId: 1000022396,
 *   }
 * }
 *
 * const getPetIdFunc = getPetIdAndEngagementTypeFromEngagementId(engagements)(51137467)
 * or
 * const getPetIdAndEngagementType = getPetIdAndEngagementTypeFromEngagementId(engagements);
 * const result1 = getPetIdAndPetService("51137467");
 */
export const getPetIdAndEngagementTypeFromEngagementId = engagements => {
  return engagementId => {
    return {
      serviceType: engagements[engagementId]?.engagementType,
      petId: engagements[engagementId]?.petId,
    };
  };
};

/**
 * Returns a function that retrieves all pet IDs associated with the given engagement IDs from a provided engagements object.
 *
 * @memberOf Utils.Engagement
 * @function
 * @name getAllPetIdsFromEngagements
 * @param {string[]} engagementIds - An array of engagement IDs to retrieve pet IDs for.
 * @returns {Function} - A function that takes an engagements object as a parameter and returns an array of associated pet IDs for the provided engagement IDs, or an empty array if none are found.
 *
 * @example
 *
 * const engagements = {
 *   "51137394": { petId: 000022671 },
 *   "51137395": { petId: 1000022405 }
 *   "51137396": { petId: 1000022405 }
 * };
 *
 * const engagementIds = ["51137394", "51137395", "51137396"];
 *
 * const getAllPetIds = getAllPetIdsFromEngagements(engagementIds);
 *
 * getAllPetIds(engagements); // Returns [000022671, 1000022405, 1000022405]
 */
export const getAllPetIdsFromEngagements = engagementIds => {
  return engagements => {
    return engagementIds?.map(getPetIdFromEngagementId(engagements));
  };
};

/**
 * Returns a function that retrieves all pet IDs and engagementTypes associated with the given engagement IDs from a provided engagements object.
 * NOTE: key for engagementType will be set as "serviceType" as required by the Get hotel booking eligibility by pet Ids api.
 *
 * @memberOf Utils.Engagement
 * @function
 * @name getAllPetIdAndEngagementTypesFromEngagementId
 * @param {string[]} engagementIds - An array of engagement IDs to retrieve pet IDs for.
 * @returns {Function} - A function that takes an engagements object as a parameter and returns an array of objects with  pet IDs and engagementTypes
 *
 * @example
 *
 * const engagements = {
 *     "51137467": {
 *       engagementType: "Day Camp",
 *       petId: 1000022397,
 *     },
 *     "51137468": {
 *       engagementType: "Daycare",
 *       petId: 1000022396,
 *     },
 *   };
 *
 * const engagementIds = ["51137467", "51137468"];
 * const result = getAllPetIdAndEngagementTypesFromEngagementId(engagementIds)(engagements);
 */
export const getAllPetIdAndEngagementTypesFromEngagementId = engagementIds => {
  return engagements => {
    return engagementIds?.map(getPetIdAndEngagementTypeFromEngagementId(engagements));
  };
};

/**
 * A Utility function that returns the latest time from a dictionary containing a key:value date. (e.g. endDatetimes: "2023-05-02T00:06:00.000Z")
 *
 * @memberOf Utils.Engagement
 * @function
 * @name getLatestEndDatetime
 * @param {Object} obj - The object containing objects with a key:value date. (e.g. {engagementID: {endDatetime:String}}
 * @param {string} keyName - The name of the property containing the end datetime (e.g. endDatetimes)
 * @returns {moment.Moment} The latest end datetime as a Moment.js object
 *
 * @example
 *
 * const engagements = {
 *   "51137394": { endDatetimes: "2023-05-02T00:06:00.000Z" },
 *   "51137395": { endDatetimes: "2023-06-02T00:06:00.000Z" }
 *   "51137396": { endDatetimes: "2023-07-02T00:06:00.000Z" }
 * };
 *
 * getLatestEndDatetime(engagements, "endDatetime");
 */
export const getLatestEndDatetime = (obj, keyName) => {
  // Extract an array of Moment.js objects
  const endDateTimes = Object.values(obj).map(item => moment(item[keyName]));

  // Find the maximum Moment.js object representing the latest end datetime
  const latestEndDatetime = moment.max(endDateTimes);

  // Return the latest end datetime
  return latestEndDatetime;
};


/**
 * A Utility function that determines if all pet service ids match given a list of engagments (state.hotelEngagements.engagements as an array)
 *
 * @memberOf Utils.Engagement
 * @function
 * @name allEngagementPetServiceIdsMatch
 * @param {Array} engagementsList - List of engagements for a given pet
 * @returns {boolean} true/false if all service ids match
 *
 * @example
 *
 * const engagementsList = [
 *   { petService: { petServiceId: 123 } },
 *   { petService: { petServiceId: 456 } },
 * ];
 *
 * allEngagementPetServiceIdsMatch(engagementsList) => false;
 */
export const allEngagementPetServiceIdsMatch = (engagementsList) => {
  const petServiceIds = engagementsList.map((engagement) => engagement?.petService?.petServiceId);
  return petServiceIds.every((serviceId, __, array) => serviceId === array[0]);
};
