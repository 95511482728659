import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { phoneSchema, emailSchema } from "../../../../core/constants/validationSchema";
import formatPhoneInputField from "../../../../core/utils/stringManipulationUtils/formatPhoneInputField";
import { color } from "../../../common/styles/theme";
import Form from "../../../common/Form";
import FormInput from "../../../common/FormInput";
import withSaveOnClickOutside from "../../../common/hoc/withSaveOnClickOutside";
import CloseButton from "../../../common/CloseButton";
import { font } from "@/web/common/styles/theme";

const petParentAgentValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: phoneSchema.required("Phone is required"),
  email: emailSchema,
  emergencyDecisionFlag: Yup.bool().required(),
  dropPickFlag: Yup.bool().required(),
  reservationFlag: Yup.bool().required(),
});

const FormSection = styled.div`
  ${props =>
    !props.firstListItem &&
    `
        padding-top: 2em;
    `}
`;

const GridColumn = styled.div`
    display: grid
    grid-template-columns: 'repeat(1, 1fr)';
    grid-column-gap: 3.75rem;
    grid-row-gap: 1.875rem;
    ${props =>
      props.marginTop &&
      `
        margin-top: ${props.marginTop}em;
    `}
`;

const CloseButtonContainer = styled.div`
  position: relative;
  float: right;
  display: flex;
  flex-direction: row;
`;

class PetParentAgentForm extends Component {
  render() {
    const {
      customerKey,
      petParentAgentFormData,
      onSubmit,
      openRemoveAgentModal,
      handleFocus,
      formikRef,
      clickOutsideRef,
      firstListItem,
      lastListItem,
    } = this.props;

    const componentId = "petParentAgentFrom"; // NOTE this should eventually come from the container or parent

    return (
      <FormSection id={componentId} firstListItem={firstListItem} lastListItem={lastListItem}>
        {petParentAgentFormData && petParentAgentFormData.agentId ? (
          <CloseButtonContainer>
            <CloseButton
              onClick={() => openRemoveAgentModal(petParentAgentFormData.agentId, customerKey)}
            />
          </CloseButtonContainer>
        ) : null}
        <Formik
          initialValues={petParentAgentFormData}
          onSubmit={values =>
            onSubmit({ formValues: values, initialValues: petParentAgentFormData })
          }
          ref={formikRef}
          validationSchema={petParentAgentValidationSchema}
          enableReinitialize
          render={({ values }) => (
            <Form innerRef={customerKey ? clickOutsideRef : null} onFieldFocus={handleFocus}>
              <GridColumn>
                <FormInput
                  id={`${componentId}__firstName`}
                  modifier="firstName"
                  label={`${values.firstName ? "" : "*"}First Name`}
                  type="text"
                  name="firstName"
                  style={{ fontFamily: font.sparkyFamily }}
                />
                <FormInput
                  id={`${componentId}__lastName`}
                  modifier="lastName"
                  label={`${values.lastName ? "" : "*"}Last Name`}
                  type="text"
                  name="lastName"
                  style={{ fontFamily: font.sparkyFamily }}
                />
                <FormInput
                  id={`${componentId}__phoneNumber`}
                  modifier="phoneNumber"
                  label={`${values.phoneNumber ? "" : "*"}Phone Number`}
                  type="text"
                  name="phoneNumber"
                  value={formatPhoneInputField(values.phoneNumber)}
                  style={{ fontFamily: font.sparkyFamily }}
                />
                <FormInput
                  id={`${componentId}__email`}
                  modifier="email"
                  label="Email"
                  type="text"
                  name="email"
                  style={{ fontFamily: font.sparkyFamily }}
                />
              </GridColumn>
              <GridColumn>
                <FormInput
                  id={`${componentId}__emergencyFlag`}
                  modifier="emergencyDecisionFlag"
                  label="Emergency decisions"
                  type="checkbox"
                  name="emergencyDecisionFlag"
                  style={{ minHeight: "auto", fontFamily: font.sparkyFamily }}
                />
                <FormInput
                  id={`${componentId}__dropPickFlag`}
                  modifier="dropPickFlag"
                  label="Drop off/pick up"
                  type="checkbox"
                  name="dropPickFlag"
                  style={{ minHeight: "auto", fontFamily: font.sparkyFamily }}
                />

                <FormInput
                  id={`${componentId}__reservationFlag`}
                  modifier="reservationFlag"
                  label="Make reservations"
                  type="checkbox"
                  name="reservationFlag"
                  style={{ minHeight: "auto", fontFamily: font.sparkyFamily }}
                />
              </GridColumn>
            </Form>
          )}
        />
      </FormSection>
    );
  }
}

export default withSaveOnClickOutside(PetParentAgentForm);
