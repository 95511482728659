import { combineReducers } from "redux";
import moment from "moment";

import bookingActionTypes from "../../../actionTypes/bookingActionTypes";
import { formatMoment } from "../../../utils/dateUtils/formatDateTime";

const newAppointmentTimeSlotReducer = (state = null, action) => {
  switch (action.type) {
    case bookingActionTypes.SELECT_NEW_APPOINTMENT_TIME_SLOT:
      return action.newTimeSlot;

    case bookingActionTypes.CLEAR_NEW_APPOINTMENT_TIME_SLOT:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  availableTimeSlots: (state = [], action) =>
    action.type === bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_SUCCESS ||
    action.type === bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT_SUCCESS
      ? action.availableTimeSlots.map(timeSlot => {
          if (!timeSlot.endDateTime) {
            return {
              ...timeSlot,
              endDateTime: formatMoment(
                moment(timeSlot.startDateTime).add(timeSlot.duration, "minutes"),
              ),
            };
          }

          return timeSlot;
        })
      : state,
  hoveredTimeSlot: (state = "", action) =>
    action.type === bookingActionTypes.SELECT_HOVERED_TIME_SLOT ? action.timeSlot : state,
  selectedPet: (state = null, action) =>
    action.type === bookingActionTypes.SELECT_PET ? action.petId : state,
  itineraryId: (state = null, action) =>
    action.type === bookingActionTypes.SET_BOOKING_ITINERARY_ID ? action.itineraryId : state,
  newAppointmentTimeSlot: newAppointmentTimeSlotReducer,
  additionalAppointmentBeforeBreak: (state = null, action) =>
    action.type === bookingActionTypes.SET_ADDITIONAL_APPOINTMENT_BEFORE_BREAK || state,
  additionalAppointmentAfterBreak: (state = null, action) =>
    action.type === bookingActionTypes.SET_ADDITIONAL_APPOINTMENT_AFTER_BREAK || state,
  petServiceItemId: (state = null, action) =>
    action.type === bookingActionTypes.SET_CURRENT_BOOKING_SERVICE_ITEM_ID
      ? action.petServiceItemId
      : state,
  petIdToRemoveFromCart: (state = null, action) =>
    action.type === bookingActionTypes.SET_PET_ID_TO_REMOVE_FROM_CART ? action.petId : state,
});
