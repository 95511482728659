import { createSelector } from "reselect";
import { getStoreInformation } from "core/selectors/entitiesSelector";

/**
 * Selects the Salon Store Location info and returns just the Latitude and Longitude in structure for google maps.
 */
export const selectStoreLatAndLong = createSelector(
  [getStoreInformation],
  ({ Latitude, Longitude }) => {
    return {
      lat: Latitude,
      lng: Longitude,
    };
  },
);
