/**
 * Helper function to dry up code and is responsible for determining the show or hide state of a modal
 *
 * @export getUpdatedModalState
 * @param {*} state - redux state
 * @param {*} initialState - initial state of the modal
 * @param {string} showActionType - Action type designated to show the modal
 * @param {string} hideActionType - Action type designated to hide the modal
 * @param {*} action
 * @returns
 */
export default function getUpdatedModalState(
  state,
  initialState,
  showActionType,
  hideActionType,
  action,
) {
  switch (action.type) {
    case showActionType:
      return {
        ...state,
        show: true,
        modalType: action.modalType,
        modalProps: action?.modalProps,
      };

    case hideActionType:
      return initialState;

    default:
      return state;
  }
}
