import { connect } from "react-redux";
import HotelBookingCartComponent from "./HotelBookingCartComponent";
import { getSystemBookingFlow } from "../setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import getHotelWorkflowFeatureFlag from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getHotelWorkflowFeatureFlag";
import * as pendingAppointmentActions from "../pendingAppointment/actions/pendingAppointmentActions";

const mapStateToProps = state => {
  const featureFlag = getHotelWorkflowFeatureFlag(state);
  return {
    isHidden: getSystemBookingFlow(state) !== systemName.HOTEL || featureFlag.isHidden,
    componentId: "cartWrapper-hotelBookingCart",
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearPendingAppointment: () => dispatch(pendingAppointmentActions.clearPendingAppointment()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelBookingCartComponent);
