import { createSelector } from "reselect";
import moment from "moment";
import { getPet, getAssociates } from "../../entitiesSelector";

export const selectGroomingNotes = createSelector([getPet, getAssociates], (pet, associates) => {
  if (pet) {
    if (pet.notes) {
      return pet.notes.groomingNotes
        .map(item => ({
          ...item,
          formatedTime: moment(item.timestamp).format("MMMM Do YYYY, h:mm a"),
          fullName: associates[item.associateId] && associates[item.associateId].associateName,
        }))
        .sort((a, b) => moment.utc(a.timestamp).diff(moment.utc(b.timestamp)));
    }
  }
  return [];
});
