import { createSelector } from "reselect";
import { getScheduledSuspensions } from "dux/scheduleSuspensionTable/scheduleSuspensionTableSelector";
import { getDateRange } from "core/utils/dateUtils/dateRanges";
import moment from "moment";
import { getStoreInformation } from "core/selectors/entitiesSelector";
import { serviceTypeIds } from "core/constants/serviceTypesConstants";
import { formatCalendarDateMoment } from "core/utils/dateUtils/formatDateTime";

/**
 * Selector to get store services for Doggie day camp
 * @memberOf Selectors.entities
 * @function
 * @name selectStoreDoggieDayCampService
 * @param {Object} state - redux state
 * @returns {Object}
 * @example selectStoreDoggieDayCampService(state, { storeNumber })
 */
export const selectStoreDoggieDayCampService = createSelector([getStoreInformation], storeInfo => {
  const dayCampService = storeInfo?.StoreServices.find(
    services => services.ServiceId === serviceTypeIds.DDC,
  );

  return dayCampService || {};
});

/**
 * Selector to get store services availability for Doggie day camp
 * @memberOf Selectors.entities
 * @function
 * @name selectIsDoggieDayCampServiceAvailable
 * @param {Object} state - redux state
 * @param {Object} storeNumber - store number
 * @returns {Boolean}
 * @example selectIsDoggieDayCampServiceAvailable(state, { storeNumber })
 */
export const selectIsDoggieDayCampServiceAvailable = createSelector(
  selectStoreDoggieDayCampService,
  dayCampService => {
    return Boolean(dayCampService?.IsActive);
  },
);

// selector Is there data, e.g. getScheduledSuspensions is empty
export const selectSuspensionsListEmptyCheck = createSelector(
  [getScheduledSuspensions],
  suspensionsList => suspensionsList.length === 0,
);

// selector to return seven days of data from today getScheduledSuspensions,
export const selectUpTo7DaysOfScheduledSuspensions = createSelector(
  [getScheduledSuspensions],
  suspensionsList => {
    // make a list of 7 days from today
    const range = getDateRange(moment(), moment().add(6, "days"), "days", "YYYY-MM-DD");

    // Filter out just all suspensions that are currently scheduled.
    const scheduledSuspensions = suspensionsList?.filter(item => item.status === "Scheduled");

    // Filter out any Scheduled suspensions that are within the above 7 Day date range.
    const scheduledSuspensionsWithinDateRange = scheduledSuspensions?.filter(item =>
      range?.includes(item.startDate),
    );

    return scheduledSuspensionsWithinDateRange;
  },
);

export const selectIsSuspensionScheduledInNext7Days = createSelector(
  [selectUpTo7DaysOfScheduledSuspensions, selectStoreDoggieDayCampService],
  (suspensionsList, dayCampServices) => {
    const range = getDateRange(moment(), moment().add(6, "days"), "days", "YYYY-MM-DD");
    // StoreServiceHoursForDateList: [
    //     {
    //       ForDate: '2024-02-12T00:00:00',
    //       DayOfWeek: 'Monday',
    //       IsClosed: false,
    //       OpenTime: '08:00:00',
    //       CloseTime: '21:00:00'
    //     },
    // ]
    const daysServicesNotAvailable = dayCampServices?.StoreServiceHoursForDateList?.filter(
      day => day.IsClosed,
    );

    return range.map(date => {
      const isSuspended = suspensionsList.some(item => item?.startDate === date);
      const isServiceUnavailable = daysServicesNotAvailable?.some(
        item => formatCalendarDateMoment(item?.ForDate) === date,
      );

      return {
        date,
        isSuspended: isSuspended ?? isServiceUnavailable,
        dowNumber: moment(date).format("D"),
        dowAbvr: moment(date).format("ddd"),
      };
    });
  },
);
