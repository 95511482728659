export default {
  LOAD_PET_SERVICE_ITEM: "LOAD_PET_SERVICE_ITEM",
  LOAD_PET_SERVICE_ITEMS: "LOAD_PET_SERVICE_ITEMS",

  DELETE_PET_SERVICE_ITEMS: "DELETE_PET_SERVICE_ITEMS",

  UPDATE_PET_SERVICE_ITEM: "UPDATE_PET_SERVICE_ITEM",
  UPDATE_PET_SERVICE_ITEM_REQUEST: "UPDATE_PET_SERVICE_ITEM_REQUEST",
  UPDATE_PET_SERVICE_ITEM_SUCCESS: "UPDATE_PET_SERVICE_ITEM_SUCCESS",
  UPDATE_PET_SERVICE_ITEM_FAILURE: "UPDATE_PET_SERVICE_ITEM_FAILURE"
};
