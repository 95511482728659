import React from "react";

/**
 * React Node for Up Arrow SVG
 *
 * @memberOf Views.SVG
 * @function
 * @name UpArrowSvg
 * @returns {JSX.Element}
 *
 * @example <UpArrowSvg />
 */
const UpArrowSvg = () => {
  return (
    <svg
      id="UpArrowSvg"
      data-name="UpArrowSvg"
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="12px"
      viewBox="0 0 17.03 13.55"
    >
      <polyline points="0 13.55 8.51 0 17.03 13.55" style={{ fill: "#575b5c" }} />
    </svg>
  );
};

export default UpArrowSvg;
