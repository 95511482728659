import { createSelector } from "reselect";
import getIsBGMWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getFTCOWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import { FTCO, BGM } from "@/web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";

export const getPackageOfferType = createSelector(
  [getIsBGMWorkflowFeatureFlagHidden, getFTCOWorkflowFeatureFlagHidden],
  (isBGMFeatureFlagHidden, isFTCOFeatureFlagHidden) => {
    const isBgmFeatureFlagEnabled = !isBGMFeatureFlagHidden;
    const isFtcoFeatureFlagEnabled = !isFTCOFeatureFlagHidden;

    let offerType = null;

    if (isFtcoFeatureFlagEnabled && isBgmFeatureFlagEnabled) {
      offerType = null;
    } else if (isFtcoFeatureFlagEnabled) {
      offerType = FTCO;
    } else if (isBgmFeatureFlagEnabled) {
      offerType = BGM;
    } else {
      offerType = null;
    }

    return offerType;
  },
);
