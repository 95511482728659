/* * ----------------------------------------------------------------------- * *\
  Actions around getting hotel Service Suspensions
\* * ----------------------------------------------------------------------- * */
export const GET_HOTEL_SERVICE_SUSPENSION = "GET_HOTEL_SERVICE_SUSPENSION";
export const getHotelServiceSuspension = ({ fromDate, toDate, page, size, includeHistory }) => ({
  type: GET_HOTEL_SERVICE_SUSPENSION,
  fromDate,
  toDate,
  page,
  size,
  includeHistory,
});

export const GET_HOTEL_SERVICE_SUSPENSION_REQUEST = "GET_HOTEL_SERVICE_SUSPENSION_REQUEST";
export const getHotelServiceSuspensionNameRequest = () => ({
  type: GET_HOTEL_SERVICE_SUSPENSION_REQUEST,
});

export const GET_HOTEL_SERVICE_SUSPENSION_SUCCESS = "GET_HOTEL_SERVICE_SUSPENSION_SUCCESS";
export const getHotelServiceSuspensionNameSuccess = suspensions => ({
  type: GET_HOTEL_SERVICE_SUSPENSION_SUCCESS,
  suspensions,
});

export const GET_HOTEL_SERVICE_SUSPENSION_FAILURE = "GET_HOTEL_SERVICE_SUSPENSION_FAILURE";
export const getHotelServiceSuspensionNameFailure = error => ({
  type: GET_HOTEL_SERVICE_SUSPENSION_FAILURE,
  error,
});

/* * ----------------------------------------------------------------------- * *\
  Actions around posting hotel Service Suspensions
\* * ----------------------------------------------------------------------- * */
export const POST_HOTEL_SERVICE_SUSPENSION = "POST_HOTEL_SERVICE_SUSPENSION";
export const POST_HOTEL_SERVICE_SUSPENSION_REQUEST = "POST_HOTEL_SERVICE_SUSPENSION_REQUEST";
export const POST_HOTEL_SERVICE_SUSPENSION_SUCCESS = "POST_HOTEL_SERVICE_SUSPENSION_SUCCESS";
export const POST_HOTEL_SERVICE_SUSPENSION_FAILURE = "POST_HOTEL_SERVICE_SUSPENSION_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const postHotelServiceSuspension = ({
  details,
  reasonValue,
  suspensionTypeId,
  reasonTypeName,
}) => ({
  type: POST_HOTEL_SERVICE_SUSPENSION,
  details,
  reasonValue,
  suspensionTypeId,
  reasonTypeName,
});

export const postHotelServiceSuspensionRequest = () => ({
  type: POST_HOTEL_SERVICE_SUSPENSION_REQUEST,
});

export const postHotelServiceSuspensionSuccess = suspensions => ({
  type: POST_HOTEL_SERVICE_SUSPENSION_SUCCESS,
  suspensions,
});

export const postHotelServiceSuspensionFailure = error => ({
  type: POST_HOTEL_SERVICE_SUSPENSION_FAILURE,
  error,
});

/* * ----------------------------------------------------------------------- * *\
  Actions around patching hotel Service Suspensions
\* * ----------------------------------------------------------------------- * */
export const PATCH_HOTEL_SERVICE_SUSPENSION = "PATCH_HOTEL_SERVICE_SUSPENSION";
export const PATCH_HOTEL_SERVICE_SUSPENSION_REQUEST = "PATCH_HOTEL_SERVICE_SUSPENSION_REQUEST";
export const PATCH_HOTEL_SERVICE_SUSPENSION_SUCCESS = "PATCH_HOTEL_SERVICE_SUSPENSION_SUCCESS";
export const PATCH_HOTEL_SERVICE_SUSPENSION_FAILURE = "PATCH_HOTEL_SERVICE_SUSPENSION_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const patchHotelServiceSuspension = ({
  startDate,
  endDate,
  details,
  reasonId,
  suspensionId,
  suspensionTypeId,
  isCanceled,
  reasonValue
}) => ({
  type: PATCH_HOTEL_SERVICE_SUSPENSION,
  startDate,
  endDate,
  details,
  reasonId,
  suspensionId,
  suspensionTypeId,
  isCanceled,
  reasonValue
});

export const patchHotelServiceSuspensionRequest = () => ({
  type: PATCH_HOTEL_SERVICE_SUSPENSION_REQUEST,
});

export const patchHotelServiceSuspensionSuccess = ({ suspensions }) => ({
  type: PATCH_HOTEL_SERVICE_SUSPENSION_SUCCESS,
  suspensions,
});

export const patchHotelServiceSuspensionFailure = error => ({
  type: PATCH_HOTEL_SERVICE_SUSPENSION_FAILURE,
  error,
});
