import { connect } from "react-redux";
import IndexTabs from "../common/tabs/indexTabs/IndexTabs";

const mapStateToProps = (state, ownProps) => ({
  componentID: "petParentProfileTabs-IndexTabs"
});

const mapDispatchToProps = dispatch => ({
  // keyName: ({ }) => dispatch(actionName({ })),
});

// const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
//   ...propsFromState,
//   ...propsFromDispatch,
//   ...ownProps,

//   keyName: ({ }) => propsFromDispatch.functionName({
//     keyName:	propsFromState.value,
//   }),
//   keyName: ({ }) => propsFromDispatch.functionName({
//     keyName:	propsFromState.value,
//   }),
// })

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // mergeProps,
)(IndexTabs);
