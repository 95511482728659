import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Text, FormFieldCheckbox, Layout } from "@prism/psm-ui-components";
import formatToPascalCase from "core/utils/stringManipulationUtils/pascalCaseUtil";
import { ClickOutside } from "core/utils/clickOutsideUtils/clickOutside";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getCustomer } from "core/selectors/entitiesSelector";
import { updateCustomerProfile } from "core/actionCreators/customersActionCreators";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "core/actionTypes/customersActionTypes";

export const VipInputCheckBox = props => {
  const { componentId, isHidden, inputLabel, updateVIPStatus, isVipInitValue, disabled } = props;

  // component State
  const [isVip, setIsVIp] = useState(isVipInitValue);

  // set local state from props on initial render
  useEffect(() => {
    setIsVIp(isVipInitValue);
  }, [isVipInitValue]);

  // Set local VIP state in prep to send to container
  const onCheckBoxChange = () => {
    setIsVIp(!isVip);
  };

  // Update vip status (send action) once a click outside has happened
  const onClickOutside = () => {
    updateVIPStatus({ isVip });
  };

  // Click Outside of the component
  const ref = useRef(null);
  ClickOutside(ref, onClickOutside);

  // Set Ids names to Pascal case
  const pascalInputLabel = formatToPascalCase(inputLabel, " ", false);

  if (isHidden) {
    return null;
  }

  return (
    <div id={componentId} ref={ref}>
      <Layout.Box id={componentId}>
        <Layout.Cluster justify="flex-start" space="cluster-space-2">
          <FormFieldCheckbox
            id={`${componentId}__CheckBox`}
            changed={onCheckBoxChange}
            checked={isVip}
            disabled={disabled}
          />
          <Text
            id={`${componentId}__${pascalInputLabel}Label`}
            size="text-size-lg"
            color={disabled ? "text-color-disabled" : "text-color-black"}
          >
            {inputLabel}
          </Text>
        </Layout.Cluster>
      </Layout.Box>
    </div>
  );
};

// HOTEL CONTAINER ---------------------------------------------------------------------------------
export const VIPInputHotelCheckBox = connect(
  state => {
    const customerKey = getCurrentCustomerKey(state);
    const customer = getCustomer(state, { customerKey });
    const bookingFlow = getSystemBookingFlow(state);
    const isLoading = createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state);

    return {
      isVipInitValue: !!customer?.prismCustomer?.isVip,
      // Temporarily hiding VIP label per PO request - SVCSART-30464
      isHidden: true || bookingFlow !== systemName.HOTEL,
      componentId: "VIPInputHotelCheckBox",
      inputLabel: "VIP Customer",
      customerKey,
      disabled: isLoading,
    };
  },

  dispatch => {
    return {
      dispatchUpdateVIPActions: ({ isVip, customerKey }) => {
        const formValues = {
          customerKey,
          isVip,
        };
        dispatch(updateCustomerProfile({ formValues, customerKey, isInHotel: true }));
      },
    };
  },
  (mapProps, dispatchProps) => {
    const { componentId, isHidden, inputLabel, isVipInitValue, customerKey, disabled } = mapProps;
    const { dispatchUpdateVIPActions } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,
      inputLabel,
      isVipInitValue,
      disabled,

      // actions to pass to view
      updateVIPStatus: ({ isVip }) => {
        dispatchUpdateVIPActions({ isVip, customerKey });
      },
    };
  },
)(VipInputCheckBox);
