import React from "react";
import styled from "styled-components";
import { envFlowBannerMessage } from "core/utils/notificationCenterUtils/envAndFlowTypeBannerUtils";
import { envFlowBannerUtil } from "core/utils/notificationCenterUtils/envFlowBannerUtil";
import { color } from "./styles/theme";
import setStyleObjToStyledCompReadyString from "../../core/utils/objectUtils/styleObjToStyledCompReadyString";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${color.errorRed};
  color: ${color.victorStone};
  width: 100%;
  font-weight: 700px;

  /* Add any additional CSS to this component */
  ${styleObj => (styleObj ? setStyleObjToStyledCompReadyString(styleObj) : "")};
`;

/**
 * React Component that displays a warning message of what environment the user is in.
 *
 * @export EnvWrapper
 * @returns {*} - JSX
 */
export default function EnvironmentWarning(props) {
  const { styleObj } = props;
  const { systemType, systemBookingFlow } = props;
  const appEnv = window.env.APP_ENV;

  return (
    <SectionWrapper styleObj={styleObj}>
      {envFlowBannerUtil({
        appEnv,
        systemType,
        systemBookingFlow,
        messageFn: envFlowBannerMessage,
      })}
    </SectionWrapper>
  );
}
