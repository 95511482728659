import { DashboardSalonContainer } from "dux/_components/dashboard/DashboardSalonComponent";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { DashboardHotelContainer } from "dux/_components/dashboard/DashboardHotelComponent";
import DashboardTrainingContainer from "dux/dashboard/DashboardTrainingContainer";

/**
 * Returns the appropriate dashboard based on the booking flow and permissions.
 *
 * @memberof Views.Dashboard
 * @function
 * @param {string} bookingFlow - The current booking flow.
 * @param {boolean} allowForHotel - Whether the user has permission to view the hotel dashboard.
 * @returns {Component} - The dashboard component.
 */
export const dashboardFactory = (bookingFlow, allowForHotel) => {

  if(bookingFlow === systemName.HOTEL && allowForHotel){
    return DashboardHotelContainer
  }

  if(bookingFlow === systemName.TRAINING){
    return DashboardTrainingContainer
  }

  return DashboardSalonContainer
}