// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-Calendar__dayLabel {
  border: 1px solid #dedede;
}

.psm-Calendar__dayLabel > * + * {
  border-left: 1px solid #dedede;
}

.psm-Calendar__dayGrid > * {
  border-left: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}

.psm-Calendar__dayGrid > *:nth-child(7n) {
  border-right: 1px solid #dedede;
}

.cover > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/composites/Calendar/styles.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;EAC9B,gCAAgC;AAClC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".psm-Calendar__dayLabel {\n  border: 1px solid #dedede;\n}\n\n.psm-Calendar__dayLabel > * + * {\n  border-left: 1px solid #dedede;\n}\n\n.psm-Calendar__dayGrid > * {\n  border-left: 1px solid #dedede;\n  border-bottom: 1px solid #dedede;\n}\n\n.psm-Calendar__dayGrid > *:nth-child(7n) {\n  border-right: 1px solid #dedede;\n}\n\n.cover > * {\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
