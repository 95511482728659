/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const eligibilityLoadClearActionTypes = {
  LOAD_ELIGIBILITY_RESULTS: "eligibility/LOAD_ELIGIBILITY_RESULTS",
  CLEAR_ELIGIBILITY_RESULTS: "eligibility/CLEAR_ELIGIBILITY_RESULTS",
};

export const loadEligibilityResults = results => ({
  type: eligibilityLoadClearActionTypes.LOAD_ELIGIBILITY_RESULTS,
  results,
});

export const clearEligibilityResults = () => ({
  type: eligibilityLoadClearActionTypes.CLEAR_ELIGIBILITY_RESULTS,
});
