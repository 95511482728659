import React from "react";
import TableCell from "./TableCell";

export default function TableHeader({ columns }) {
  return (
    <thead>
      <tr>
        {columns.map(column => (
          <th className="header" key={column.path || column.key}>
            {Array.isArray(column?.label) ? (
              column?.label.map((label, idx) => <TableCell key={idx} values={label} />)
            ) : (
              <TableCell values={column?.label} />
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
}
