import * as React from 'react';
import { psx } from '../../../utils/';
import { LayoutProps } from '../';
import { BOX_TOKENS } from './_tokens';

import './styles.css';

export interface BoxProps extends LayoutProps<React.ElementType> {
  /** A CSS padding value */
  padding?: keyof typeof BOX_TOKENS['PADDING'];
  /** A boolean or CSS border-width value */
  borderWidth?: boolean | keyof typeof BOX_TOKENS['BORDER_WIDTH'];
  /** A CSS border-style value */
  borderStyle?: keyof typeof BOX_TOKENS['BORDER_STYLE'];
  /** A CSS border-color value */
  borderColor?: keyof typeof BOX_TOKENS['BORDER_COLOR'];
  /** A CSS border-radius value */
  borderRadius?: keyof typeof BOX_TOKENS['BORDER_RADIUS'];
  /** A CSS background-color value */
  backgroundColor?: keyof typeof BOX_TOKENS['BACKGROUND_COLOR'];
  /** Completely removes the component from the DOM. Useful for feature flags or t hide or show
   * a component. */
  isHidden?: boolean;
}

/**
 * Just about every component or feature should be wrapped in a `<Box/>` component.
 *
 * This foundational piece is responsible for three primary style attributes:
 *
 * 1. **Padding**
 * 2. **Background color**
 * 3. **Border**: width, style, color and radius
 *
 * If all features are wrapped in this component, the layout will be more consistent,
 * it will enable themeing much easier and provide default classnames that help our
 * QA partners in their automated testing.
 *
 * [Source](https://every-layout.dev/layouts/box)
 */
export const Box: React.FunctionComponent<BoxProps> = ({
  padding = 'box-padding-0',
  backgroundColor = 'box-background-inherit',
  borderWidth = 'box-border-width-0',
  borderStyle = 'box-border-solid',
  borderColor = 'box-border-color-black-100',
  borderRadius = 'box-rounded-none',
  className,
  children,
  testid,
  as,
  isHidden = false,
  ...props
}) => {
  const id = 'psm-layout__box';
  const Component = as || 'div';
  const classNames = psx('psm', id, className);

  if (isHidden) {
    return null;
  }

  return (
    <Component
      className={classNames}
      data-testid={[id, testid].join('-')}
      data-padding={padding}
      data-background-color={backgroundColor}
      data-border-width={typeof borderWidth === 'boolean' ? `${borderWidth}` : borderWidth}
      data-border-style={borderStyle}
      data-border-color={borderColor}
      data-border-radius={borderRadius}
      {...props}
    >
      {children}
    </Component>
  );
};
