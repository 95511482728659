import React from "react";
import { connect } from "react-redux";
import { getStoreFromSalon } from "core/selectors/entitiesSelector";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import getStoreNameNumberAddress from "core/utils/storeUtils/getStoreNameNumberAddressUtil";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutBox } from "@/layout/box/Box";
import { Heading, TextPassage } from "@petsmart-ui/sparky";

const StoreNameNumberAddressComponent = ({
  componentId,
  heading = "Appointment summary",
  currentStore,
  isHidden,
}) => {
  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} style={{ paddingLeft: 0, paddingRight: 0 }}>
      <LayoutStack>
        <Heading tagName="h4" size="body-lg-bold">
          {heading}
        </Heading>
        <TextPassage size="lg">{getStoreNameNumberAddress(currentStore)}</TextPassage>
      </LayoutStack>
    </LayoutBox>
  );
};

export const StoreNameNumberAddress = connect(state => {
  const storeNumber = getStoreNumber(state);
  return {
    currentStore: getStoreFromSalon(state, { storeNumber }),
  };
})(StoreNameNumberAddressComponent);
