import moment from "moment";

/** @module startOfWeekInPresent */

/**
 * Gets the earliest date in the week of the given date which is not in the past
 *
 * @exports getStartOfWeekInPresent
 * @param {date} date - input date
 * @returns {object} - A Moments object representing the earliest date in the week.
 */
export default function getStartOfWeekInPresent(date) {
  const startOfWeek = moment(date).startOf("isoWeek");

  while (startOfWeek.isBefore(moment(), "day")) {
    startOfWeek.add(1, "days");
  }

  return startOfWeek;
}
