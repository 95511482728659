export const SET_ITINERARY_HITSORY_LIST_FILTERS = "SET_ITINERARY_HITSORY_LIST_FILTERS";
export const SET_ITINERARY_HISTORY_LIST_VISIBLE_FILTERS =
  "SET_ITINERARY_HISTORY_LIST_VISIBLE_FILTERS";
export const SET_ITINERARY_HITSORY_LIST_FILTERS_TO_INIT =
  "SET_ITINERARY_HITSORY_LIST_FILTERS_TO_INIT";

export const setItineraryHistoryListFilters = payload => ({
  type: SET_ITINERARY_HITSORY_LIST_FILTERS,
  payload,
});

export const setItineraryHistoryListVisibleFilters = payload => ({
  type: SET_ITINERARY_HISTORY_LIST_VISIBLE_FILTERS,
  payload,
});

export const setItineraryHistoryListFiltersToInitial = () => ({
  type: SET_ITINERARY_HITSORY_LIST_FILTERS_TO_INIT,
});
