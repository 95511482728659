import { all, put, takeEvery, fork, call, select } from "redux-saga/effects";
import { getOr } from "lodash/fp";
import {
  customerAlertsActionsTypes,
  updateHotelCustomerAlertsFailure,
} from "./customerAlertsActions";
import { updateCustomerProfilePartial } from "../../core/services/systemServicesBooking/profile/customerProfile";
import { updateCustomerSuccess } from "../../core/actionCreators/customersActionCreators";
import { getPrismCustomerProfile } from "../../web/petParentProfile/selectors/getPrismCustomerProfileSelector";
import { systemName } from "../../web/setSystemType/constants/setSystemTypeConstants";

// This saga is the new version for customer alerts using the new PETCH endpoint, currently hotel uses it but in the future Salon update will also use this saga

function* onUpdateCustomerAlerts({ alerts }) {
  try {
    const { customerKey } = alerts;
    const prismCustomerProfile = yield select(getPrismCustomerProfile, { customerKey });
    const hotelProfile = prismCustomerProfile.profiles.find(
      ({ profileType }) => profileType === systemName.DDC_HOTEL,
    );
    const response = yield call(updateCustomerProfilePartial, {
      customerKey,
      data: alerts.prismCustomer,
    });

    const updatedPrismCustomer = response.data.result.profiles.find(
      ({ profileType }) => profileType === systemName.DDC_HOTEL,
    );
    const updatedCheckOutAlert = getOr("", "checkOutAlert", updatedPrismCustomer);
    const updatedCheckInAlert = getOr("", "checkInAlert", updatedPrismCustomer);
    const updatedBookingAlert = getOr("", "bookingAlert", updatedPrismCustomer);

    yield put(
      updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          prismCustomer: {
            ...prismCustomerProfile,
            profiles: [
              {
                profileType: systemName.DDC_HOTEL,
                ...hotelProfile,
                checkOutAlert: updatedCheckOutAlert,
                checkInAlert: updatedCheckInAlert,
                bookingAlert: updatedBookingAlert,
              },
            ],
          },
        },
      }),
    );
  } catch (error) {
    updateHotelCustomerAlertsFailure({ error });
  }
}

function* watchUpdateCustomerAlertsSaga() {
  yield takeEvery(customerAlertsActionsTypes.UPDATE_HOTEL_CUSTOMER_ALERTS, onUpdateCustomerAlerts);
}

export default function* customerProfilesAlertsSaga() {
  yield all([fork(watchUpdateCustomerAlertsSaga)]);
}
