import React from "react";
import styled from "styled-components";
import moment from "moment";
import { color, font } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import { device } from "web/common/styles/responsive/devices";
import AppointmentReBookPetInfoContainer from "dux/appointmentRebook/AppointmentReBookPetInfoContainer";
import Img from "../../common/Img";
import getUnknownPetImage from "@/core/utils/assetUtils/unknownPetImage";
import calculateAge from "@/core/utils/dateUtils/calculateAge";
import getUSDateString from "@/core/utils/dateUtils/usDate";
import GroomerName from "../../upcoming_here/groomerName/groomerNameContainer";
import StarImage from "@/assets/icons/star.svg";
import HistoryImage from "@/assets/icons/history.svg";
import Icons from "../../upcoming_here/icons/iconsContainer";
import AppointmentInfo from "./appointmentInfoComponent";
import QuickViewRebookButtonEnterLeaveWrapperContainer from "dux/quickViews/QuickViewRebookButtonEnterLeaveWrapperContainer";
import QuickViewRebookTextEnterLeaveWrprContainer from "dux/quickViews/QuickViewRebookTextEnterLeaveWrprContainer";

const flexMargin = 0.5;

// Styles via styled-components
const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex: 1;
`;

const PetName = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.regular};
  color: ${color.tChalla};

  @media ${device.mobileS} {
    margin-bottom: ${flexMargin}rem;
  }
`;

const ServiceWrapper = styled.div`
  display: flex;

  @media ${device.mobileS} {
    margin-bottom: ${flexMargin}rem;
  }
`;

const Service = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.medium};
  color: ${color.tChalla};
`;

const Status = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.medium};
  font-weight: bold;
  margin-left: auto;
`;

const TimeRangeWrapper = styled.div`
  display: flex;
  width: 100%;

  @media ${device.mobileS} {
    flex-direction: column;
  }

  @media ${device.prismDesktop} {
    flex-direction: row;
  }
`;

const TimeRange = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.medium};
  color: ${color.tChalla};
  margin-right: 0.625rem;

  @media ${device.mobileS} {
    margin-bottom: ${flexMargin}rem;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5px;
`;

const Icon = styled.img`
  height: 1em;
  margin-left: 2px;
`;

const HistoryIcon = styled.img`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 1.1em;
`;

const PetImage = styled(Img)`
  width: 50px;
  height: 50px;
`;

const GroomerNameWrapper = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  text-align: right;
`;

const BoldPetName = styled.span`
  font-weight: bold;
  margin-right: 0.4rem;
`;

const RebookContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
class PetInfo extends React.Component {
  handleHistoryPopover = e => {
    e.stopPropagation();
    const { appointment, showModal } = this.props;

    if (!appointment) {
      return;
    }

    showModal({ appointment });
  };

  render() {
    const {
      petServiceItemId,
      pet,
      customer,
      appointment,
      breeds,
      showStatus,
      showDate,
      type,
      addOnsList,
      enhancedServiceName,
      style
    } = this.props;
    const age = pet ? calculateAge(pet.birthDate) : "Unknown Age";

    return (
      <SectionWrapper style={style}>
        <TopContainer>
          <PetImage src={getUnknownPetImage(pet)} />
          <InfoWrapper>
            <PetName>
              <BoldPetName>
                {pet.petName} {customer.lastName}
              </BoldPetName>
              {age}, {breeds[pet.breedId] ? breeds[pet.breedId].Description : "Unknown Breed"}
            </PetName>

            <ServiceWrapper>
              <Service>{appointment.petServiceName}</Service>
            </ServiceWrapper>

            {appointment.startDateTime && (
              <TimeRangeWrapper>
                <TimeRange>{`${moment(appointment.startDateTime).format("LT")} - ${moment(
                  appointment.endDateTime,
                ).format("LT")}`}</TimeRange>
                {showDate && <span>{getUSDateString(appointment.startDateTime)}</span>}
                {showStatus && <Status>{appointment.status}</Status>}
              </TimeRangeWrapper>
            )}
          </InfoWrapper>
        </TopContainer>
        <BottomContainer>
          <AppointmentInfo
            appointmentNumber={appointment.petServiceItemId}
            status={appointment.status}
            store={appointment.storeNumber}
            addOnsList={addOnsList}
            enhancedServiceName={enhancedServiceName}
          />
          <IconsWrapper>
            <Icons pet={pet} appointment={appointment} petServiceItemId={petServiceItemId} />
            <GroomerNameWrapper>
              <GroomerName petServiceItemId={petServiceItemId} />
              {appointment.lockToAssociate ? <Icon src={StarImage} /> : null}
            </GroomerNameWrapper>
          </IconsWrapper>
          <RebookContainer>
            <QuickViewRebookTextEnterLeaveWrprContainer appointment={appointment}>
              <AppointmentReBookPetInfoContainer appointment={appointment} type={type} />
            </QuickViewRebookTextEnterLeaveWrprContainer>
          </RebookContainer>
        </BottomContainer>
      </SectionWrapper>
    );
  }
}

export default PetInfo;
