export type DisplayCharacterCount = (maxLength: number, textToCount: string) => string

/**
 * Display a character count, e.g. 5/20 or 15/100 etc.
 * @memberOf Utils.Display
 * @function
 * @name displayCharacterCount
 * @param {number} maxLength - The number of characters that you want to show as the limit
 * @param {string} textToCount - the string we want to count the length of.
 * @returns String
 * @example
 * const characterCount = displayCharacterCount(20, "foo bar baz");
 */
export const displayCharacterCount: DisplayCharacterCount = (
  maxLength: number,
  textToCount: string = "",
): string => {
  return `${textToCount.length} / ${maxLength} `;
}