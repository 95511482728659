import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import qs from "querystringify";

import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { authorizeUser } from "../../../../core/actionCreators/authActionCreators";
import { getAuthenticatedWithQuickPin } from "../../../../core/selectors/authSelectors";
import { getSecureRedirectRoute } from "../../../../core/selectors/persistentSelectors";
import { routePaths } from "../../../../core/constants/routePaths";

import DelayedLoader from "../../DelayedLoader";
import AuthLoading from "../../AuthLoading";
import OktaAuthError from "./OktaAuthError";

class CheckPetSmartAuth extends Component {
  componentDidMount() {
    const { router, authorizeUser: setAuthorizeUser, secureRedirectRoute } = this.props;

    // We parse out the access token query string and id token fragment found in the /implicit/callback URL.
    const params = qs.parse(router.location.hash);
    const accessToken = params.access_token;
    const idToken = params.id_token;

    // The id and access token are required for authenticating a user session via the authorize user API
    if (accessToken && idToken) {
      // Authorize user endpoint is called
      setAuthorizeUser({ accessToken, idToken, pathname: secureRedirectRoute });
    }
  }

  render() {
    const {
      isAuthenticatedWithQuickPin,
      secureRedirectRoute,
      router: {
        location: { hash },
      },
    } = this.props;
    const routeToRedirect = secureRedirectRoute || routePaths.DASHBOARD;

    // If we cannot parse tokens from URL, show authentication error
    if (!hash) {
      return <OktaAuthError />;
    }

    if (isAuthenticatedWithQuickPin === null) {
      return (
        <DelayedLoader
          delay={250}
          backgroundColor="rgba(0, 0, 0, 0.8)"
          render={() => <AuthLoading />}
        />
      );
    }

    if (!isAuthenticatedWithQuickPin) {
      return <Navigate replace to={routePaths.LOGIN} />;
    }

    return <Navigate replace to={routeToRedirect} />;
  }
}

const mapStateToProps = state => ({
  isAuthenticatedWithQuickPin: getAuthenticatedWithQuickPin(state),
  secureRedirectRoute: getSecureRedirectRoute(state),
});

const mapDispatchToProps = dispatch => ({
  authorizeUser: ({ accessToken, idToken, pathname }) =>
    dispatch(authorizeUser({ accessToken, idToken, pathname })),
});

export default withRouteProps(connect(mapStateToProps, mapDispatchToProps)(CheckPetSmartAuth));
