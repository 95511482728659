import enumsActionTypes from "../actionTypes/enumsActionTypes";

export const loadEnum = () => ({
  type: enumsActionTypes.LOAD_ENUMS
});

export const loadEnumRequest = () => ({
  type: enumsActionTypes.LOAD_ENUMS_REQUEST
});

export const loadEnumSuccess = ({ payload }) => ({
  type: enumsActionTypes.LOAD_ENUMS_SUCCESS,
  payload
});

export const loadEnumFailure = ({ error }) => ({
  type: enumsActionTypes.LOAD_ENUMS_FAILURE,
  error
});

export default {
  loadEnum,
  loadEnumRequest,
  loadEnumSuccess,
  loadEnumFailure
};
