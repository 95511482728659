import * as React from 'react';
import './styles.css';

export interface FormFieldToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: false;
  changed?: (checked: boolean) => void;
  label?: string;
}

export class FormFieldToggleSwitch extends React.Component<FormFieldToggleProps> {

  render() {
    const { changed, checked, label, ...otherProps } = this.props;
    return (
      <>
        <div className="psm-toggle-switch__label">{label}</div>
        <label className="psm-toggle-switch">

          <input
            {...otherProps}
            className="psm-toggle-switch__input"
            type="checkbox"
            onChange={changed ? (e) => changed(e.target.checked) : undefined}
            checked={checked}
          />
          <span className="switch" />
        </label>
      </>
    );
  }
}