import { put, takeEvery, call, all, select } from "redux-saga/effects";
import {
  getHotelItinerary,
  putHotelItinerary,
} from "core/services/systemServicesBooking/getCreateUpdateDeleteHotelItineraryEndpoints";
import { setHotelEngagement } from "dux/hotelEngagements/hotelEngagementActions";
import { separateHotelItineraryResponse } from "dux/hotelItinerary/hotelItineraryUtils";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { onNeedsReview } from "dux/hotelCheckInOutPetNeedsReview/hotelCheckInOutPetNeedsReviewSaga";
import {
  GET_HOTEL_ITINERARY,
  getHotelItineraryRequest,
  getHotelItinerarySuccess,
  getHotelItineraryFailure,
  putHotelItineraryRequest,
  putHotelItineraryFailure,
  PUT_HOTEL_ITINERARY,
  putHotelItinerarySuccess,
} from "./hotelItineraryActions";
import { setHotelPricingSummary } from "../hotelPricingSummary/hotelPricingSummaryActions";
import { clearError } from "@/core/actionCreators/errorActionCreators";
import { showCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import { modalTypes as checkInOutModalTypes } from "@/core/constants/checkInOutConstants";
import { setPetReservationNotes } from "../hotelCheckInOutPetNotes/hotelCheckInOutPetNotesActions";
import { clearMedications } from "@/web/medication/actions/medicationsActions";
import { clearFoods } from "@/web/food/actions/foodsActions";

/* * ----------------------------------------------------------------------- * *\
  NOTE: this file should be reserved only for full GET, PUT and POST related Sagas
  Other sagas such as patches should be in their own files in their own dux directory
\* * ----------------------------------------------------------------------- * */

function* saveReservationNotes({ petKey, reservationNotes }) {
  yield put(setPetReservationNotes({ petId: petKey, notes: reservationNotes }));
}

function* savePricingSummary({ petKey, pricingSummary }) {
  yield put(setHotelPricingSummary({ petId: petKey, pricingSummary }));
}

/**
 * Helper to parse and store a hotel itinerary
 *
 * @memberOf Sagas.hotel.itinerary
 * @generator
 * @name saveHotelItineraryResponse
 * @param {Object} obj
 * @param {Object} obj.responseData - result from a hotel itinerary API call
 * @param {String} obj.petId - optional - pet ID for current pet
 * @returns {Generator<*, void, *>}
 * @example yield call(saveHotelItineraryResponse, { responseData: response.data.result, petId })
 * @todo refactor hotel itinerary sagas to use this helper instead of copying the same logic all over the place,
 *  basically any saga using getHotelItinerarySuccess that isn't onGetHotelItinerary
 */
export function* saveHotelItineraryResponse({ responseData, petId }) {
  // separate engagements from hotel itinerary
  const dataObj = separateHotelItineraryResponse(responseData);

  // Clear food and med state
  yield put(clearFoods());
  yield put(clearMedications());

  // send engagements to state
  yield put(setHotelEngagement(dataObj.engagements));

  // send hotel itinerary to state
  yield put(getHotelItinerarySuccess(dataObj.modifiedItinerary));

  // send reservation notes to state for all pets
  yield all(dataObj?.pets?.map(saveReservationNotes));

  // send pricing summaries to state
  yield all(dataObj?.pets?.map(savePricingSummary));

  // Save data for current pet
  if (petId) {
    yield call(onNeedsReview, { pets: dataObj.pets, petId });
  }
}

export function* onGetHotelItinerary(payload) {
  const { customerKey, itineraryId } = payload?.payload;
  try {
    yield put(getHotelItineraryRequest());

    const petId = yield select(getCurrentPet);

    // Make the API call
    const response = yield call(getHotelItinerary, {
      customerKey,
      itineraryId,
    });

    // Get the actual result data
    const responseData = response.data.result;
    yield call(saveHotelItineraryResponse, { responseData, petId });
  } catch (error) {
    yield put(getHotelItineraryFailure(error));
  }
}

/**
 * Generator function to call the putHotelItinerary service function to do a full PUT of an itinerary, it wll all call the saveHotelItineraryResponse and the itinerary to state.
 *
 * @memberOf Sagas.hotel.itinerary
 * @see saveHotelItineraryResponse
 * @generator
 * @name onPutHotelItinerary
 * @param {Object} itineraryObject - full itinerary object
 * @returns {Generator<*, void, *>}
 * @example
 *
 * // from another saga
 * yield call(onPutHotelItinerary, itineraryObject)
 */
export function* onPutHotelItinerary({ hotelItinerary }) {
  try {
    // dispatch action to let Redux know we about to make an itinerary Put call
    yield put(putHotelItineraryRequest());

    // get petId and Customer Id from state
    const petId = yield select(getCurrentPet);
    const customerKey = yield select(getCurrentCustomerKey);

    // make API PUT call
    const response = yield call(putHotelItinerary, {
      customerKey,
      data: hotelItinerary,
    });

    // Send API response to State
    const responseData = response.data.result;
    yield call(saveHotelItineraryResponse, { responseData, petId });

    yield put(putHotelItinerarySuccess({ payload: responseData }));
  } catch (error) {
    if (error?.response?.status === 409 && !!error?.response?.headers) {
      const itineraryId = error?.response?.headers["x-itinerary-id"];

      yield put(putHotelItineraryFailure({ itineraryId }));
      yield put(
        showCheckInOutModal({ modalType: checkInOutModalTypes.PRE_EXISTING_RESERVATION_MODAL }),
      );

      // Clear the error so that the generic error modal closes
      yield put(clearError(PUT_HOTEL_ITINERARY));
      return;
    }

    yield put(putHotelItineraryFailure(error));
  }
}

function* watchPutHotelItinerary() {
  yield takeEvery(PUT_HOTEL_ITINERARY, onPutHotelItinerary);
}

function* watchGetHotelItinerary() {
  yield takeEvery(GET_HOTEL_ITINERARY, onGetHotelItinerary);
}

export default function* hotelItinerarySaga() {
  yield all([watchGetHotelItinerary(), watchPutHotelItinerary()]);
}
