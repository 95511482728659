import { get } from "lodash/fp";
import { createSelector } from "reselect";
import { getFoodByPet } from "../food/foodsSelector";
import { selectFoodList } from "@/dux/foodOptionsList/foodOptionsListSelectors";

export const getFoodTypeByExternalId = createSelector(
  [getFoodByPet, selectFoodList, (state, props) => props],
  (foods, foodList, { foodId }) => {
    const chosenFood = get(foodId, foods);
    if (chosenFood && chosenFood.externalId) {
      const foundFood = foodList?.find(food => food.FoodId === chosenFood.externalId);
      return foundFood && foundFood.FoodType;
    }
    return chosenFood?.type;
  },
);
