import { connect } from "react-redux";
import SearchResultsWrapper from "./searchResultsWrapperComponent";
import { getShowResults } from "../../../core/selectors/searchSalonSRCSelector";
import getIsSearchFieldMainV2WorkflowFeatureFlagHidden
  from "web/enableDisableWorkflowFeatureFlag/selectors/searchFieldMainV2/getIsSearchFieldMainV2WorkflowFeatureFlagHidden";
import { buildSearchFieldProducts, SearchFactorySalon } from "dux/searchFieldMain/searchFactory/SearchFactory";

const mapStateToProps = (state, ownProps) => {
  const isFeatureFlagHidden = getIsSearchFieldMainV2WorkflowFeatureFlagHidden(state);
  const search = buildSearchFieldProducts(new SearchFactorySalon());

  return {
    // Factory will return the new or old customer search based on feature flag status
    searchField: search.customerSearch.getSearchField(isFeatureFlagHidden),

    hasResults: getShowResults(state)
  };
};

const SearchResultsWrapperContainer = connect(mapStateToProps)(SearchResultsWrapper);

export default SearchResultsWrapperContainer;
