import { createSelector } from "reselect";
import { get } from "lodash/fp";
import { getPetParentProfile } from "../../petParentProfileSelectors";

// Example of use:  getDisplayOtherAddresses(state);
const getDisplayOtherAddresses = createSelector([getPetParentProfile], profile =>
  get("displayOtherAddresses", profile)
);

export default getDisplayOtherAddresses;
