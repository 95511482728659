import React from "react";
import { Layout } from "@prism/psm-ui-components";
import AssociateProfileSubheading from "./AssociateProfileSubheading";
import AssociateProfileFormTraining from "./associateProfileForm/AssociateProfileFormTraining";
import AssociateProfileVirtualTrainerBanner from "./AssociateProfileVirtualTrainerBanner";
import { RemoveTrainerFromVirtualFlashMessage } from "../flashMessage/RemoveTrainerFromVirtualFlashMessage";

const AssociateProfileTraining = () => {
  return (
    <Layout.Box>
      <Layout.Box>
        <AssociateProfileVirtualTrainerBanner />
      </Layout.Box>
      <Layout.Box padding="box-padding-5">
        <AssociateProfileSubheading />
      </Layout.Box>
      <Layout.Box>
        <AssociateProfileFormTraining />
      </Layout.Box>
      <RemoveTrainerFromVirtualFlashMessage />
    </Layout.Box>
  );
};

export default AssociateProfileTraining;
