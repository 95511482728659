// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-legacy-static-input:focus {
  outline: none;
}

/*
  Hack to display HTML5 validation bubble for react select
  even though it is a hidden input field.
 */
.psm-hidden-input {
  position: relative;
  top: -14px;
  opacity: 0;
  width: 100%;
  height: 0;
}


`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/components/Form/Fields/Select/legacy-styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;;;EAGE;AACF;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,WAAW;EACX,SAAS;AACX","sourcesContent":[".psm-legacy-static-input:focus {\n  outline: none;\n}\n\n/*\n  Hack to display HTML5 validation bubble for react select\n  even though it is a hidden input field.\n */\n.psm-hidden-input {\n  position: relative;\n  top: -14px;\n  opacity: 0;\n  width: 100%;\n  height: 0;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
