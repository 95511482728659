export const PIN = "PIN";

export const authErrorMessages = {
  SAME_PIN_ERROR: "PIN cannot be same as previous PIN.",
  ELEVATED_PERMISSION_EXPIRED:
    "User's elevated permission has expired. Perform a full auth to access elevated endpoint.",
  ELEVATED_PERMISSION_EXPIRED_USER_FRIENDLY:
    "You do not currently have permission to perform this action. A full login to PRISM is required to gain access.",
  NON_ELEVATED: "Session token validation failed. Token is expired or invalid.",
  PIN_INVALID: "Input pin does not match stored pin",
  PIN_INVALID_USER_FRIENDLY: `${PIN} code is invalid`,
  ELEVATED_PERMISSIONS_REJECTED: "User does not have permission to access elevated endpoint.",
  ELEVATED_PERMISSIONS_REJECTED_USER_FRIENDLY: "You do not have permission to perform this action.",
  USER_HAS_NO_PIN: "User has no PIN",
  MISSING_TOKEN: "Authorization token missing.",
  ACCESS_TOKEN_NOT_IN_REPO: "Access token not in repo",
  SF_ACCESS_TOKEN_NOT_FOUND: "Error retrieving the access token from Salesforce",
  SF_ACCESS_TOKEN_NOT_FOUND_USER_FRIENDLY:
    "User not set up in SalesForce. Please contact the solution center for support."
};

export const pinValidationErrors = {
  REQUIRED: `${PIN} is a required field`,
  LENGTH: `${PIN} must be at least 6 digits`,
  NON_NUMERIC: `${PIN} must contain all numbers`,
  ALL_SAME_NUMBERS: `${PIN} must not contain all the same numbers`,
  SEQUENTIAL_NUMBERS: `${PIN} must not contain all sequential numbers`,
  PINS_DONT_MATCH: `${PIN}s don't match`,
  CONFIRM_PIN: `You must confirm your ${PIN}`
};
