import { connect } from "react-redux";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import dispatchTrainingAvailability from "@/dux/scheduleTrainingPage/scheduleTrainingPageUtils";
import { getTrainingClassSessionDetails } from "../trainingClassSessions/selectors";
import Calendar from "@/web/common/Calendar";
import { getCachedTrainingAvailabilityWeeks } from "../scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";

const mapStateToProps = (state) => {
  const selectedDate = getSelectedDate(state);
  const storeNumber = getStoreNumber(state);
  const { petServiceId } = getTrainingClassSessionDetails(state, { storeNumber });

  return {
    selectedDate,
    storeNumber,
    petServiceId,
    isHidden: false,
    calendarTitle: "Date",
    isWeekly: false,
    isOutsideRange: () => false,
    numberOfMonths: 1,
    cachedTrainingAvailabilityWeeks: getCachedTrainingAvailabilityWeeks(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSelectDate: ({ selectedDate, storeNumber, petServiceId, petId, cache }) => {
    dispatchTrainingAvailability({
      selectedDate,
      cache,
      storeNumber,
      petServiceId,
      petId,
      dispatch,
    });
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  selectDate: (selectedDate) => {
    const { petServiceId, storeNumber, cachedTrainingAvailabilityWeeks } = propsFromState;

    propsFromDispatch.dispatchSelectDate({
      selectedDate,
      storeNumber,
      petServiceId,
      cache: cachedTrainingAvailabilityWeeks,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Calendar);
