import React from "react";
import { connect } from "react-redux";
import { Layout, Text, Button } from "@prism/psm-ui-components";
import {
  getAssociatesBookedTrainingAppointments,
  hasBookedTrainingAppointments,
} from "./associateProfilePageSelectors";
import { setToggleRemoveVirtualTrainerModal } from "./associateProfilePageActions";
import { getAssociate } from "@/core/selectors/entitiesSelector";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

function SchedulingConflictModalContent({ children }) {
  return (
    <Layout.Box padding="box-padding-8">
      <Layout.Stack space="stack-space-8">
        <Text size="text-size-lg" align="center" bold>
          Cancellation Warning
        </Text>
        <Layout.Center maxWidth={650}>
          <Layout.Stack space="stack-space-4">
            <Text size="text-size-md" align="left">
              This trainer has enrolled classes. All future classes must be removed or assigned to
              another trainer before removing this trainer as a virtual trainer.
            </Text>
            {children}
          </Layout.Stack>
        </Layout.Center>
      </Layout.Stack>
    </Layout.Box>
  );
}

export function RemoveVirtualTrainerSchedulingConflictModalContent({
  isHidden,
  onClose,
  bookedAppointmentCards,
}) {
  if (isHidden) {
    return null;
  }
  return (
    <Layout.Box>
      <SchedulingConflictModalContent>
        <Layout.Stack space="stack-space-4">
          <Text size="text-size-md" bold>
            Assigned classes
          </Text>
          <Layout.Grid cellMinWidth="cell_min-width-72" cellSpacing="cell-space-2">
            {bookedAppointmentCards}
          </Layout.Grid>
          <Layout.Cluster space="cluster-space-4">
            <Button variant="prism-primary" onClick={onClose}>
              Done
            </Button>
          </Layout.Cluster>
        </Layout.Stack>
      </SchedulingConflictModalContent>
    </Layout.Box>
  );
}

const mapStateToProps = (state, { router }) => {
  const associateId = router?.params?.associateId;
  const associateDetails = getAssociate(state, { associateId });
  const associateName = associateDetails?.associateName;
  return {
    isHidden: !hasBookedTrainingAppointments(state, { associateId }),
    bookedAppointmentCards: getAssociatesBookedTrainingAppointments(state, {
      associateId,
      associateName,
    }),
  };
};
const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(setToggleRemoveVirtualTrainerModal());
  },
});

export default withRouteProps(
  connect(mapStateToProps, mapDispatchToProps)(RemoveVirtualTrainerSchedulingConflictModalContent),
);
