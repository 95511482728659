import axios from "axios";
import {
  setRequestHeaders,
  handleAuthorizationRejection,
  setResponseRefreshToken,
} from "../../utils/authHelper";
import { getDomainEnv } from "core/utils/envUtils.js";

/**
 * Axios Create configuration for the Pre Booking (Cart) api (/associate/web).
 *
 * @summary /associate/web -  not to be confused with the Web associate (/web/associate) routes.
 * @memberOf APIs.WebAssociate
 * @function
 * @name  cartAPI
 * @type {axios.AxiosInstance}
 *
 * @example
 * return cartAPI.post(url, params)
 * return cartAPI.get(url, params)
 * return cartAPI.put(url, params)
 */
export const cartAPI = axios.create({
  baseURL: `${window.env.BASE_URL}${window.env.ASSOCIATE_WEB_BFF_URI}`,
});

/**
 * Intercept the `x-refresh-session-token` value
 * - Note: This can be disabled by using the ENV flag below
 */
if (!window.env.DISABLE_API_AUTH_IN_DEV) {
  cartAPI.interceptors.request.use(setRequestHeaders, handleAuthorizationRejection);
  cartAPI.interceptors.response.use(setResponseRefreshToken, handleAuthorizationRejection);
}
