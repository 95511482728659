import { filter } from "lodash/fp";

import { createSelector } from "reselect";
import { NOTIFICATION_CENTER } from "dux/storeWideNotes/storeWideNotesConstants";

/**
 * Helper function to provide a list objects where the property displayLocation has a value of 'notificationCenter'
 * @param { Object } notificationsList - Array of objects that must contain displayLocations and a serviceLocationType properties
 * @return {[{displayLocations: [string, string],serviceLocationType: string}]}
 */
export const filterNotesDisplayLocationsByNotificationCenter = ({ notificationsList }) => {
  return filter(item => item.displayLocations.includes(NOTIFICATION_CENTER), notificationsList);
};

/**
 * Helper function that provides a list of ServiceLocationTypes, e.g. ["Training", "Salon", "Hotel"]
 * @param { Array }notesByNotificationCenter a list of objects that Contains a serviceLocationType : string  property
 * @return {string []}
 */
export const listServiceLocationTypes = ({ notesByNotificationCenter }) => {
  return [...new Set(notesByNotificationCenter.map(item => item.serviceLocationType))];
};

/**
 * Helper function that provides a list of Objects categorized by ServiceLocationType
 * @param {Object } - notesByNotificationCenter - A list of objects that Contains a serviceLocationType : string  property
 * @param { string } - locationType for example "Salon:
 * @return {[{serviceLocationType: string,},]}
 */
export const filterNotesByServiceLocationType = ({ notesByNotificationCenter, locationType }) => {
  return notesByNotificationCenter.filter(item => {
    return locationType === item.serviceLocationType && item;
  });
};

/**
 * Provides a single serviceLocationType object with all the ReichTextMessages concatenated into one reichTextMessage Prop under one serviceTypeObject
 * @param notesByServiceLocationType - List of Objects were each object may have the serviceLocationType value as another object in the list. The Objects must have a serviceLocationType: string and a richTextMessage: string properties
 * @return {{serviceLocationType: stirng, richTextMessage: string}}
 */
export const reduceNotesBySameServiceLocationType = ({ notesByServiceLocationType }) => {
  return notesByServiceLocationType.reduce(
    (preValue, currValue) => {
      if (preValue.serviceLocationType === currValue.serviceLocationType) {
        return {
          ...currValue,
          richTextMessage: preValue.richTextMessage + currValue.richTextMessage,
        };
      }

      return currValue;
    },
    [
      {
        serviceLocationType: "",
        richTextMessage: "",
      },
    ],
  );
};

// SELECTOR ----------------------------------------------------------------------------------------
export const selectStoreWideNotifications = state => state.storeWideNotes?.notifications;

export const selectStoreWideNotificationsList = state =>
  state.storeWideNotes?.notifications?.notificationsList;

export const selectStoreWideNotificationsLastAPICallDateTime = state =>
  state.storeWideNotes?.notifications?.lastAPICall;

export const selectStoreWideNotificationCenterNotifications = createSelector(
  [selectStoreWideNotificationsList],
  notificationsList => {
    const serviceLocationTypes =
      notificationsList &&
      listServiceLocationTypes({ notesByNotificationCenter: notificationsList });

    return serviceLocationTypes?.map(locationType => {
      const notesByServiceLocationType = filterNotesByServiceLocationType({
        notesByNotificationCenter: notificationsList,
        locationType,
      });

      return reduceNotesBySameServiceLocationType({ notesByServiceLocationType });
    });
  },
);
