import { put, takeEvery, call, all } from "redux-saga/effects";
import { updatePaymentStatus } from "../services/systemServicesBooking/paymentEndPoints";
import { showCheckInOutModal } from "../../core/actionCreators/checkInOutActionCreator";
import { modalTypes } from "../../core/constants/checkInOutConstants";
import voidTransactionActionCreators from "../actionCreators/voidTransactionActionCreator";
import voidTransactionActionTypes from "../actionTypes/voidTransactionActionTypes";
import { dispatchItinerariesDataAndReturnItineraries } from "./itinerariesSaga";

function* onVoidTransaction({ customerId, itineraryId, data }) {
  try {
    yield put(voidTransactionActionCreators.voidTransactionRequest());

    const response = yield call(updatePaymentStatus, { customerId, itineraryId, data });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response
    });
    const itinerary = normalizedItineraries[itineraryId];

    yield put(voidTransactionActionCreators.voidTransactionSuccess({ itinerary }));

    const modalType = modalTypes.VOID_COMPLETE;
    yield put(showCheckInOutModal({ modalType }));
  } catch (error) {
    yield put(voidTransactionActionCreators.voidTransactionFailure(error));
  }
}

function* watchVoidTransaction() {
  yield takeEvery(voidTransactionActionTypes.VOID_TRANSACTION, onVoidTransaction);
}

export default function* voidTransactionSaga() {
  yield all([watchVoidTransaction()]);
}
