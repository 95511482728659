import { createSelector } from "reselect";
import { getBreakByDateAndAssociate } from "../../core/selectors/entitiesSelector";
import getDashboardHourOptions from "../../core/utils/dateUtils/dashboardHourOptions";
import { DEFAULT_STAGGER } from "../../core/constants/dashboardContants";
import { getWeeklyGanttDate } from "./commonSelector";
import { getAssociateShiftTimeByDate } from "./schedulesSelectors";

export const getAppointment = state => state.ui.web.dashboard.appointment;

export const getDashboardHourOptionsByBreak = createSelector(
  [getBreakByDateAndAssociate, getAssociateShiftTimeByDate],
  (currentBreak, shift) => {
    const hourOptionsBeforeBreak = getDashboardHourOptions({
      startDate: shift && shift.shiftStartDateTime,
      endDate: currentBreak ? currentBreak.startDate : shift.shiftEndDateTime,
      stagger: DEFAULT_STAGGER
    });
    const hourOptionsAfterBreak = currentBreak
      ? getDashboardHourOptions({
          startDate: currentBreak.endDate,
          endDate: shift && shift.shiftEndDateTime,
          stagger: DEFAULT_STAGGER
        })
      : [];

    return { hourOptionsBeforeBreak, hourOptionsAfterBreak };
  }
);
