import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Retrieves a list of employee groups
 * from System Services Booking API
 * @returns {*} AxiosPromise
 */
export function fetchEmployeeGroups() {
  return bookingAPI.get(`${endpointVersionString()}/settings/employee-groups`);
}
