import React from "react";
import styled from "styled-components";
import { Formik } from "formik";
import Form from "../../../../../../common/Form";
import FormInput from "../../../../../../common/FormInput";
import ResultListContainer from "@/web/searchSalonSRC/resultList/resultListContainer";
import Header from "./SearchVetModalHeader";
import Footer from "./SearchVetModalFooter";

const ModalWrapper = styled.div`
  max-height: ${props => (props.showVetSearchResults ? "750" : "400")}px;
  ${props =>
    props.isLoading &&
    `
        opacity: 0.3;
    `};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${props =>
    props.showVetSearchResults &&
    `
        padding-bottom: 1em;
    `};
`;

const FormContainer = styled.div`
  padding: 2em;
`;

const AddVetModalComponent = ({
  submitForm,
  STATE_DROPDOWN_OPTIONS,
  showVetSearchResults,
  showModal,
  isLoading,
}) => (
  <Formik
    onSubmit={values => {
      submitForm({
        formValues: values,
      });
    }}
    enableReinitialize
    render={({ handleSubmit, values }) => (
      <ModalWrapper isLoading={isLoading} showVetSearchResults={showVetSearchResults}>
        <Content showVetSearchResults={showVetSearchResults}>
          <Header title="Search for a vet" />
          <FormContainer>
            <Form>
              <FormInput label="First name" name="firstName" />
              <FormInput label="Last name" name="lastName" />
              <FormInput label="Clinic name" name="clinicName" />
              <FormInput label="City" name="city" />
              <FormInput
                label="State/Province"
                type="select"
                options={STATE_DROPDOWN_OPTIONS}
                name="state"
                menuListMaxHeight={95}
              />
            </Form>
          </FormContainer>
          {showVetSearchResults && <ResultListContainer />}
        </Content>
        <Footer
          isLoading={isLoading}
          showVetSearchResults={showVetSearchResults}
          values={values}
          handleSubmit={handleSubmit}
          showModal={showModal}
        />
      </ModalWrapper>
    )}
  />
);

export default AddVetModalComponent;
