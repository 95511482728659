import { connect } from "react-redux";
import {
  getVoidModalTypeLIst,
  getCurrentCustomer,
  getCurrentItinerary,
} from "core/selectors/checkInOutSelector";
import {
  showCheckInOutModal,
  hideCheckInOutModal,
} from "core/actionCreators/checkInOutActionCreator";
import { voidTransaction } from "core/actionCreators/voidTransactionActionCreator";
import { getCheckInOutModalType } from "core/selectors/modals/checkInOutModalSelectors";
import { selectVoidTransactionReason } from "dux/voidTransactionReasons/voidTransactionResonsSelectors";
import VoidTransactionModalContent from "../../../_components/modals/checkOut/voidTransaction/VoidTransactionModalContent";

const mapStateToProps = (state, ownProps) => ({
  modalTypeList: getVoidModalTypeLIst(),
  modalType: getCheckInOutModalType(state, ownProps),
  title: "Void the transaction",
  buttonLabel: "Void Transaction",
  linkLabel: "Go Back",
  copy: "Before we void the transaction, we need to know the reason.",
  reason: selectVoidTransactionReason(state),
  customerId: getCurrentCustomer(state),
  itineraryId: getCurrentItinerary(state),
});

const mapDispatchToProps = dispatch => ({
  // The Footer component uses a shared button, so we must determine what
  // modal window we are on to determine what needs to happen when the button
  // is clicked.
  dispatchVoidTransactionFlow: ({ modalIndex, modalTypeName, customerId, itineraryId, reason }) => {
    const modalType = modalTypeName;

    if (modalIndex === 0) {
      dispatch(showCheckInOutModal({ modalType }));
    } else if (modalIndex === 1) {
      const data = {
        action: "Void",
        reason: reason.name,
      };

      dispatch(voidTransaction({ customerId, itineraryId, data }));
    } else {
      dispatch(hideCheckInOutModal());
    }
  },
  goBack: ({ modalIndex, modalTypeName }) => {
    const modalType = modalTypeName;

    if (modalIndex > 0) {
      dispatch(showCheckInOutModal({ modalType }));
    } else {
      dispatch(hideCheckInOutModal());
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  voidTransactionFlow: ({ modalIndex, modalTypeName }) =>
    propsFromDispatch.dispatchVoidTransactionFlow({
      modalIndex,
      modalTypeName,
      customerId: propsFromState.customerId,
      itineraryId: propsFromState.itineraryId,
      reason: propsFromState.reason,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(VoidTransactionModalContent);
