import React from "react";
import moment from "moment";
import styled from "styled-components";
import { Container as SearchCell } from "./associateCell";
import { color, scheduleGridColumns } from "../common/styles/theme";
import Input from "../common/Input";
import Cell from "./cell";

const ColumnTitles = props => {
  const { startWeekDate, searchValue, setSchedulesSearchValue, salonHoursRange } = props;

  const currentMoment = moment(startWeekDate);
  const endMoment = moment(startWeekDate).add(6, "days");
  const result = [
    <SearchCell key="search">
      <SearchTitle>Search</SearchTitle>
      <Input value={searchValue} onChange={e => setSchedulesSearchValue(e.target.value)} />
    </SearchCell>
  ];

  while (currentMoment <= endMoment) {
    const currentDate = currentMoment.format("YYYY-MM-DD");
    const selected = currentDate === moment().format("YYYY-MM-DD");
    result.push(
      <Cell key={currentDate}>{renderHeader(currentMoment, salonHoursRange, selected)} </Cell>
    );
    currentMoment.add(1, "day");
  }

  result.push(<Cell key="hours">Hours per week</Cell>);

  return <StyledColumnTitles>{result}</StyledColumnTitles>;
};

function renderHeader(currentMoment, salonHoursRange, selected) {
  const dayMonth = currentMoment.format("ddd, MMMM DD");
  const dayKey = currentMoment.format("YYYY-MM-DD");
  return (
    <React.Fragment>
      {selected ? <CurrentDate>{dayMonth}</CurrentDate> : dayMonth}
      <SalonHours isClosed={salonHoursRange[dayKey] === "Store Closed"}>
        {salonHoursRange[dayKey]}
      </SalonHours>
    </React.Fragment>
  );
}

const StyledColumnTitles = styled.div`
  display: grid;
  grid-template-columns: ${scheduleGridColumns};
  grid-auto-rows: 50px;
  border-top: 1px solid ${color.borderGrey};
  border-bottom: 1px solid ${color.borderGrey};
  text-align: center;
`;

const SearchTitle = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

const CurrentDate = styled.div`
  background-color: ${color.errorRed};
  color: white;
  border-radius: 2px;
  padding: 5px 10px;
`;

const SalonHours = styled.div`
  ${({ isClosed }) => isClosed && `color: ${color.errorRed}`};
`;

export default ColumnTitles;
