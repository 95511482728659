import axios from "axios";

/**
 * Axios Create configuration for the Web Public API (https://api.petsmart-qa.com).
 *
 * @summary https://api.petsmart-qa.com
 * @memberOf APIs.WebPublic
 * @function
 * @name  petsmartWebPublicAPI
 * @type {axios.AxiosInstance}
 *
 * @example
 * return petsmartWebPublicAPI.post(url, params)
 * return petsmartWebPublicAPI.get(url, params)
 * return petsmartWebPublicAPI.put(url, params)
 */
export const petsmartWebPublicAPI = axios.create({
  baseURL: `${window.env.ENUMS_BASE_URL}/v1`,
});

/**
 * Axios Create configuration for the Web Public API (https://api.petsmart-qa.com).
 *
 * @summary https://api.petsmart-qa.com
 * @memberOf APIs.WebPublic
 * @function
 * @name  petsmartWebPublicAPIV2
 * @type {axios.AxiosInstance}
 *
 * @example
 * return petsmartWebPublicAPI.post(url, params)
 * return petsmartWebPublicAPI.get(url, params)
 * return petsmartWebPublicAPI.put(url, params)
 */
export const petsmartWebPublicAPIV2 = axios.create({
  baseURL: `${window.env.ENUMS_BASE_URL}/v2`,
});
