export const modalTypes = {
  CHECK_OUT: "CHECK-OUT",
  CHECK_OUT_MULTI: "CHECK-OUT-MULTI",
  CHECK_IN: "CHECK-IN",
  CHECK_IN_MULTI: "CHECK-IN-MULTI",
  VOID_CONFIRMATION: "VOID_CONFIRMATION",
  VOID_REASON: "VOID_REASON",
  VOID_COMPLETE: "VOID_COMPLETE",
  CANCEL_APPOINTMENT: "CANCEL_APPOINTMENT",
  PRIMARY_SERVICE_PRICE_OVERRIDE: "PRIMARY_SERVICE_PRICE_OVERRIDE",
  HOTEL_FEEDING_PRICE_OVERRIDE: "HOTEL_FEEDING_PRICE_OVERRIDE",
  HOTEL_ADDON_PRICE_OVERRIDE: "HOTEL_ADDON_PRICE_OVERRIDE",
  HOTEL_PRIMARY_SERVICE_CHANGE: "HOTEL_PRIMARY_SERVICE_CHANGE",
  SHOW_HOTEL_CHECK_OUT_ALERTS: "SHOW_HOTEL_CHECK_OUT_ALERTS",
  SHOW_HOTEL_CHECK_OUT_ALERTS_ALL_PETS: "SHOW_HOTEL_CHECK_OUT_ALERTS_ALL_PETS",
  HOTEL_CHECK_OUT_COMPLETE: "HOTEL_CHECK_OUT_COMPLETE",
  HOTEL_VOID_TRANSACTION: "HOTEL_VOID_TRANSACTION",
  PRE_EXISTING_RESERVATION_MODAL: "PRE_EXISTING_RESERVATION_MODAL",
  FOOD_MED_NOT_AVAILABLE: "FOOD_MED_NOT_AVAILABLE",
};

export const flyoutTypes = {
  ADD_ONS: "ADD_ONS",
  SELECT_SERVICE: "SELECT_SERVICE",
};

export const statusChangeActions = {
  CHECK_IN_SINGLE: "statusChangeActions/CHECK_IN_SINGLE",
  CHECK_IN_MULTI: "statusChangeActions/CHECK_IN_MULTI",
  CHECK_OUT_SINGLE: "statusChangeActions/CHECK_OUT_SINGLE",
  CHECK_OUT_MULTI: "statusChangeActions/CHECK_OUT_MULTI",
};

export const pageNames = {
  CHECK_IN: "check-in",
  BOOK_AND_CHECK_IN: "Book and Check-In",
  CHECK_OUT: "check-out",
  FUTURE_APPOINTMENT: "future-appointment",
  PAST_APPOINTMENT: "past-appointment",
};

export const PRINT_INVOICE_REMINDER_MESSAGE =
  "Have all services/add-ons been updated on the appointment?\nCompleting this step will send the invoice to POS and\nno further changes can be made to the appointment.";

export const HANDS_ON_PET_REMINDER_MESSAGE =
  "Did you perform a Hands on Pet\nAssessment and recommend any additional\nservices or add-ons for the pet?\n";

export const HOP_NOTE_VERBIAGE = "Hands on Pet Assessment Notes:";
