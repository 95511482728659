/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS = "LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS";
export const LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS_REQUEST =
  "LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS_REQUEST";
export const LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS_SUCCESS =
  "LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS_SUCCESS";
export const LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS_FAILURE =
  "LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const loadHotelPriceAdjustmentReasons = payload => ({
  type: LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS,
  payload,
});

export const loadHotelPriceAdjustmentReasonsRequest = payload => ({
  type: LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS_REQUEST,
  payload,
});

export const loadHotelPriceAdjustmentReasonsSuccess = payload => ({
  type: LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS_SUCCESS,
  payload,
});

export const loadHotelPriceAdjustmentReasonsFailure = payload => ({
  type: LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS_FAILURE,
  payload,
});
