import modalInitState from "./_modalInitState";
import petProfileActionTypes from "../../actionTypes/petProfileActionTypes";
import getUpdatedModalState from "./helpers/getUpdatedModalState";

const initialState = modalInitState();

export default function petProfileModalsReducer(state = initialState, action) {
  return getUpdatedModalState(
    state,
    initialState,
    petProfileActionTypes.SHOW_PET_PROFILE_MODAL,
    petProfileActionTypes.HIDE_PET_PROFILE_MODAL,
    action
  );
}
