import React from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { TextPassage } from "@petsmart-ui/sparky";
import { CartPriceComponent } from "../cartPrice/cartPrice";

// Selectors
import { selectHotelCartPetFoodPricing } from "../servicesCartHotel/servicesCartHotelSelectors";
import { selectPetFoodPricing } from "../hotelPricingSummary/hotelPricingSummarySelectors";
import { getCheckInOutPetFoods } from "../_components/checkInOutFeeding/CheckInOutCartFoodSelectors";
import { selectIsReservationDisabled } from "../hotelItinerary/hotelItinerarySelectors";
import { hasManagerPrivileges } from "@/core/selectors/persistentSelectors";

// Action Creators
import { showCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";

// Utils
import { formatMoney } from "dux/_utils/moneyUtil";

// Constants
import { FEEDING_CHARGES_LABEL } from "./FeedingChargesConstants";
import { modalTypes } from "@/core/constants/checkInOutConstants";

export const FeedingCharges = ({
  componentId,
  isHidden,
  price,
  strikethroughPrice,
  label = FEEDING_CHARGES_LABEL,
  onClick,
  disabled,
}) => {
  if (isHidden) return null;

  return (
    <LayoutBox id={componentId}>
      <LayoutCluster>
        <TextPassage size="lg" style={{ marginRight: "auto" }}>
          <span>{label}</span>
        </TextPassage>

        <CartPriceComponent
          componentId={`${componentId}__price`}
          isClickable={!!onClick}
          price={price}
          disabled={disabled}
          onClick={onClick}
        />

        {strikethroughPrice && (
          <TextPassage size="lg" style={{ textDecoration: "line-through" }}>
            <span>{strikethroughPrice}</span>
          </TextPassage>
        )}
      </LayoutCluster>
    </LayoutBox>
  );
};

export const BookingTotalFeedingCharges = connect((state, { petId }) => {
  const pricing = selectHotelCartPetFoodPricing(petId)(state);

  return {
    componentId: "BookingTotalFeedingCharges",
    price: formatMoney(pricing?.finalPrice ?? 0),
  };
})(FeedingCharges);

export const CheckInOutTotalFeedingCharges = connect(
  (state, { petId }) => {
    const { finalPrice = 0, originalPrice = 0 } = selectPetFoodPricing(petId)(state);
    const hasFood = !!getCheckInOutPetFoods(state, { petId })?.length;
    const hasAdjustedPrice = finalPrice !== originalPrice;
    const isReservationDisabled = selectIsReservationDisabled(state);
    const isManager = hasManagerPrivileges(state);

    return {
      componentId: "CheckInOutTotalFeedingCharges",
      price: formatMoney(finalPrice),
      strikethroughPrice: hasAdjustedPrice && formatMoney(originalPrice),
      disabled: isReservationDisabled || !hasFood || !isManager,
      isManager,
    };
  },
  dispatch => ({
    openModal: () => {
      dispatch(showCheckInOutModal({ modalType: modalTypes.HOTEL_FEEDING_PRICE_OVERRIDE }));
    },
  }),
  (stateProps, dispatchProps) => {
    const { componentId, price, strikethroughPrice, disabled, isManager } = stateProps;
    const { openModal } = dispatchProps;

    return {
      componentId,
      price,
      strikethroughPrice,
      disabled,
      onClick: isManager && (() => openModal()),
    };
  },
)(FeedingCharges);
