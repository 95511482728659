import { connect } from "react-redux";
import { getBulkPackageOfferingHasExistingPackage } from "../bulkPackageOfferingsSelectors";
import ErrorText from "../../../../web/common/ErrorText";

const mapStateToProps = (state, { petServiceId }) => {
  return {
    isHidden: !getBulkPackageOfferingHasExistingPackage(state, { petServiceId }),
    componentID: "bulk-package-offerings-group-header-error-text",
    message: "Package already exists",
    styleObject: { marginTop: "0.5em" },
  };
};

export default connect(mapStateToProps, null)(ErrorText);
