import React from "react";
import { connect } from "react-redux";

// Actions
import { setWeeklyEditDayActivity } from "@/core/actionCreators/schedulesActionCreators";

// Components
import { Radio } from "@prism/psm-ui-components";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { TextPassage } from "@petsmart-ui/sparky";

// Constants
import { PET_TYPES, dayActivityTypes } from "@/core/constants/schedulesConstants";
import { weekOffs } from "@/core/constants";

// Utils
import { getCurrentPetType } from "@/core/utils/schedulesUtils/schedulesUtils";

/**
 *  React view component for the pet type radio buttons in the associate schedule table
 *  @memberOf Views.Associate
 *  @function
 *  @name PetTypeRadioButtonsComponent
 *  @param {Object} props - props passed into the view component
 *  @param {Object[]} props.workHours
 *  @param {String} props.dayOfWeek
 *  @param {Function} props.selectPetSupportedType
 *  @param {Boolean} props.isDayOutOfWeek
 *  @returns {JSX.Element}
 *  @example const component = connect()(PetTypeRadioButtonsComponent);
 */
const PetTypeRadioButtonsComponent = ({
  componentId,
  workHours,
  selectPetSupportedType,
  isDayOutOfWeek,
}) => {
  return (
    <LayoutStack id={componentId}>
      {workHours.map((partialRow, rowIndex) => {
        const { localId, type, petSupportedType } = partialRow;
        const petType = getCurrentPetType(petSupportedType);
        const isAbsent = type === dayActivityTypes.ABSENCE;
        const isHidden = !type || isAbsent || isDayOutOfWeek;

        if (isHidden) return null;

        return (
          <LayoutBox key={localId} padding="scale-0">
            <LayoutCluster flexWrap="flex-noWrap">
              {PET_TYPES.map(({ id, label }) => (
                <LayoutBox key={id} padding="scale-0">
                  <LayoutCluster space="scale-S2" flexWrap="flex-noWrap">
                    <Radio
                      id={`${localId}_${id}_${rowIndex}`}
                      name={`${localId}_${rowIndex}`}
                      value={id}
                      checked={petType === id}
                      onChange={event => {
                        selectPetSupportedType({
                          localId,
                          petSupportedType: event.target.value.split("_"),
                        });
                      }}
                    />
                    <TextPassage>{label}</TextPassage>
                  </LayoutCluster>
                </LayoutBox>
              ))}
            </LayoutCluster>
          </LayoutBox>
        );
      })}
    </LayoutStack>
  );
};

/**
 * Redux Connect function for the PetTypeRadioButtonsComponent
 * @see {@link Views.Associate.PetTypeRadioButtons}
 * @summary Located on the associate-scheduling page
 * @memberOf Views.Associate
 * @function
 * @name PetTypeRadioButtons
 * @param {Object} props
 * @param {String} props.dayOfWeek
 * @param {Object[]} props.workHours - array of day activities containing work hours
 * @returns {JSX.Element|null}
 * @example <PetTypeRadioButtons />
 */
export const PetTypeRadioButtons = connect(
  (state, { workHours, dayOfWeek }) => {
    return {
      componentId: `PetTypeRadioButtons_${dayOfWeek}`,
      workHours,
      dayOfWeek,
      isDayOutOfWeek: weekOffs.indexOf(dayOfWeek) !== -1,
    };
  },
  dispatch => ({
    selectPetSupportedType: ({ localId, petSupportedType }) =>
      dispatch(setWeeklyEditDayActivity({ localId, petSupportedType })),
  }),
)(PetTypeRadioButtonsComponent);
