import { connect } from "react-redux";
import {
  getPetVaccinationOptions,
  getShowVaccinationDropdown
} from "../../../../core/selectors/ui/vaccinations/vaccinationsSelectors";
import VaccinationDropdown from "./vaccinationDropdown";
import petVaccinationsActionCreators from "../../../../core/actionCreators/petVaccinationsActionCreators";

const mapStateToProps = (state, ownProps) => ({
  vaccinations: getPetVaccinationOptions(state, ownProps),
  isShowingDropdown: getShowVaccinationDropdown(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  newVaccination: values => {
    dispatch(
      petVaccinationsActionCreators.createPetVaccination({
        customerKey: ownProps.customerKey,
        petId: ownProps.petId,
        vaccinationId: values.vaccination
      })
    );
  },
  hideDropdown: () => dispatch(petVaccinationsActionCreators.hideVaccinationDropdown())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VaccinationDropdown);
