import React from "react";
import styled from "styled-components";
import { BulkPackageOfferingsCartDetailsPetImageWithLabel } from "@/dux/PetImage/PetImageWithLabel";
import BulkPackageOfferingsCartDetailsPetCardCancelButtonContainer from "../../../bgm/bulkPackageOfferings/cartDetails/BulkPackageOfferingsCartDetailsPetCardCancelButtonContainer";
import BulkPackageOfferingsCartDetailsPetIconsContainer from "../../../bgm/bulkPackageOfferings/cartDetails/BulkPackageOfferingsCartDetailsPetIconsContainer";

const PetCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BulkPackageOfferingsCartDetailsPetCardComponent = ({ isHidden, componentID }) => {
  if (!isHidden) {
    return (
      <PetCardWrapper componentID={componentID}>
        <BulkPackageOfferingsCartDetailsPetImageWithLabel>
          <BulkPackageOfferingsCartDetailsPetIconsContainer />
        </BulkPackageOfferingsCartDetailsPetImageWithLabel>
        <BulkPackageOfferingsCartDetailsPetCardCancelButtonContainer />
      </PetCardWrapper>
    );
  }

  return null;
};

export default BulkPackageOfferingsCartDetailsPetCardComponent;
