import { connect } from "react-redux";
import moment from "moment";

import { getSelectedAssociateWeekly } from "../../../core/selectors/commonSelector";
import GanttWeekDayComponent from "./GanttWeekDayComponent";
import { getAssociateShiftTimeByDate } from "../../../core/selectors/schedulesSelectors";

const mapStateToProps = (state, ownProps) => ({
  date: moment(ownProps.groupMember),
  shiftTime: getAssociateShiftTimeByDate(state, {
    associateId: getSelectedAssociateWeekly(state),
    selectedDate: ownProps.groupMember
  })
});

export default connect(mapStateToProps)(GanttWeekDayComponent);
