import CalculateEMFromPX from "core/utils/conversions/calculateEMFromPX";
import ValuesInPixels from "web/common/styles/valuesInPixels";

export const fontSizes = {
  small: `${CalculateEMFromPX(ValuesInPixels.small)}rem`, // '0.75rem',
  medium: `${CalculateEMFromPX(ValuesInPixels.base)}rem`, // '1rem',
  regular: `${CalculateEMFromPX(ValuesInPixels.regular)}rem`, // '1.125rem',
  big: `${CalculateEMFromPX(ValuesInPixels.big)}rem`, // '1.25rem',
  extraBig: `${CalculateEMFromPX(ValuesInPixels.extraBig)}rem`, // '1.5rem',
  large: `${CalculateEMFromPX(ValuesInPixels.large)}rem`, // '1.75rem',
  extraLarge: `${CalculateEMFromPX(ValuesInPixels.extraLarge)}rem`, // '2 rem',
  magnificent: `${CalculateEMFromPX(ValuesInPixels.magnificent)}rem`, // '2.25 rem',
  fantastic: `${CalculateEMFromPX(ValuesInPixels.fantastic)}rem`, // '2.375 rem',
  godlike: `${CalculateEMFromPX(ValuesInPixels.godlike)}rem`, // '2.625 rem',
};
