// @ts-check

import { SET_HOTEL_MANAGE_ROOM_SUSPENSION_DETAILS } from "./hotelManageRoomDetailsDetailsActions";

const initialState = {
  roomSuspensionDetails: null,
};

const hotelManageRoomDetailsDetailsReducer = (
  state = initialState,
  /** @type {{ type: string; payload: string; }} */ action,
) => {
  switch (action.type) {
    case SET_HOTEL_MANAGE_ROOM_SUSPENSION_DETAILS:
      return {
        ...state,
        roomSuspensionDetails: action.payload,
      };
    default:
      return state;
  }
};

export default hotelManageRoomDetailsDetailsReducer;
