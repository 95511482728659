import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Layout, Text } from "@prism/psm-ui-components";
import Checkbox from "web/common/Checkbox";
import {
  selectVoidTransactionsReasons,
  selectVoidTransactionReason,
} from "dux/voidTransactionReasons/voidTransactionResonsSelectors";
import { setVoidTransactionReason } from "dux/voidTransactionReasons/voidTransactionReasonsActions";
import { loadVoidTransactionsReasons } from "core/actionCreators/voidTransactionActionCreator";

/**
 * @param {Object} params - Destructured Params.
 * @param {String} params.componentId - Id to identify component for automated testing.
 * @param {Boolean} params.isHidden - Flag to hide or show component
 * @param {Array} params.reasonsOptions - list of options to select
 * @param {String} params.reason - Currently selected reason
 * @param {(String) => void} params.setReason - function to dispatch action to set reason.
 * @param {() => void} params.setReason - function to dispatch acton to get Void Transaction reasons.
 * @return {JSX.Element}
 */
export const VoidTransactionReasonsList = ({
  componentId,
  isHidden,
  reasonsOptions,
  reason,
  setReason,
  getVoidTransactionsReasons,
}) => {
  const [selectedReason, setSelectedReason] = useState(null);

  // On Mount Get Void Transaction reasons
  useEffect(() => {
    getVoidTransactionsReasons();
  }, []);

  // On Mount set selected reasons
  useEffect(() => {
    if (reason) {
      setSelectedReason(reasonsOptions.find(reasonOption => reasonOption.name === reason.name));
    }
  }, []);

  const onSelectionChange = reason => {
    setSelectedReason(reason);
    setReason({ selectedReason });
  };

  if (isHidden) {
    return null;
  }

  return (
    <Layout.Box id={componentId}>
      <Layout.Stack space="stack-space-8" justify="center">
        <Layout.Box>
          <Text id={`${componentId}__copy`}>
            Before we void the transaction, we need to know the reason.
          </Text>
        </Layout.Box>

        <Layout.Box>
          <Layout.Stack space="stack-space-6" justify="center">
            {reasonsOptions.map(option => (
              <Checkbox
                id={`${componentId}__selection--${option.name}`}
                key={option.name}
                label={option.value}
                labelPosition="right"
                onChange={() => onSelectionChange(option)}
                checked={selectedReason === option}
              />
            ))}
          </Layout.Stack>
        </Layout.Box>
      </Layout.Stack>
    </Layout.Box>
  );
};

// SALON REDUX CONNECT FUNCTIONS/CONTAINERS ----------------------------------------------------------------------------------------
export const SalonVoidTransactionReasonsList = connect(
  state => {
    // const someProp = someSelector(state);

    return {
      componentId: "SalonVoidTransactionReasonsList",
      reason: selectVoidTransactionReason(state),
      reasonsOptions: selectVoidTransactionsReasons(state),
    };
  },

  dispatch => {
    return {
      setReason: ({ selectedReason }) => {
        dispatch(setVoidTransactionReason(selectedReason));
      },
      getVoidTransactionsReasons: () => {
        dispatch(loadVoidTransactionsReasons());
      },
    };
  },
)(VoidTransactionReasonsList);
