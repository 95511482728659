// action creators for notification center
export const GET_NOTIF_FOR_ALERT_MESSAGE = "GET_NOTIF_FOR_ALERT_MESSAGE";
export const getNotifForAlert = ({ storeNumber, displayLocation, date }) => ({
  type: GET_NOTIF_FOR_ALERT_MESSAGE,
  storeNumber,
  displayLocation,
  date,
});

export const GET_NOTIF_FOR_ALERT_MESSAGE_REQUEST = "GET_NOTIF_FOR_ALERT_MESSAGE_REQUEST";
export const getNotifForAlertRequest = () => ({
  type: GET_NOTIF_FOR_ALERT_MESSAGE_REQUEST,
});

export const GET_NOTIF_FOR_ALERT_MESSAGE_SUCCESS = "GET_NOTIF_FOR_ALERT_MESSAGE_SUCCESS";
export const getNotifForAlertSuccess = ({ notifications }) => ({
  type: GET_NOTIF_FOR_ALERT_MESSAGE_SUCCESS,
  notifications,
});

export const GET_NOTIF_FOR_ALERT_MESSAGE_FAILURE = "GET_NOTIF_FOR_ALERT_MESSAGE_FAILURE";
export const getNotifForAlertFailure = ({ error }) => ({
  type: GET_NOTIF_FOR_ALERT_MESSAGE_FAILURE,
  error,
});
