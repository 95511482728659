export const BATCH_ACTION = "BATCH_ACTION";
export const RESET_ACTION = "RESET_ACTION";

export function withBatchReducer(reducer) {
  return function(state, action) {
    switch (action.type) {
      case RESET_ACTION:
        return {};
      case BATCH_ACTION:
        return action.actions.reduce(reducer, state);
      default:
        return reducer(state, action);
    }
  };
}

export function createBatchActions(...actions) {
  return {
    type: BATCH_ACTION,
    actions,
  };
}
