import moment from "moment";
import { connect } from "react-redux";
import { getSelectedDate } from "@/dux/selectedDate/selectedDateSelector";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { setWeekNumber } from "@/core/actionCreators/ui/web/commonActionCreators";
import { getWeekNumber } from "@/core/selectors/commonSelector";
import { getWeekFromDate } from "@/core/utils/dateUtils/getWeekFromDate";
import WeekNavigationBar from "@/web/weekNavigationBar/WeekNavigationBar";
import DaySelectionDashboardTrainingContainer from "@/dux/selectedDate/DaySelectionDashboardTrainingContainer";
import {
  PrevWeekRescheduleTraining,
  NextWeekRescheduleTraining,
} from "./ToggleWeekRescheduleTraining";

const mapStateToProps = (state) => {
  const selectedDate = getSelectedDate(state);
  const weekNumber = getWeekNumber(state) || moment(selectedDate).week();
  return {
    weekNumber,
    isHidden: false,
    componentId: "weeklyNavigationBarRescheduleTraining",
    switcherSpace: "switcher-space-1",
    week: getWeekFromDate({ date: selectedDate }),

    compName: systemName.TRAINING,
    padding: "0em",
    diComp: {
      daySelection: {
        [systemName.TRAINING]: DaySelectionDashboardTrainingContainer,
      },
      nextWeek: {
        [systemName.TRAINING]: NextWeekRescheduleTraining,
      },
      prevWeek: {
        [systemName.TRAINING]: PrevWeekRescheduleTraining,
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchWeekNumber: ({ weekNumber }) => {
    dispatch(setWeekNumber({ weekNumber }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  setWeekNumber: () => {
    const { weekNumber } = propsFromState;

    propsFromDispatch.dispatchWeekNumber({
      weekNumber,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(WeekNavigationBar);
