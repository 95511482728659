import { connect } from "react-redux";
import petVaccinationsActionCreators from "../../../../../core/actionCreators/petVaccinationsActionCreators";
import { getShowVaccinationDropdown } from "../../../../../core/selectors/ui/vaccinations/vaccinationsSelectors";
import AddButton from "../../../../common/buttons/addButton";

const mapStateToProps = state => {
  const isShowingDropdown = getShowVaccinationDropdown(state);
  return {
    isShowingDropdown,
    opacity: "1",
    label: "Add Vaccination",
    fontSize: "regular"
  };
};

const mapDispatchToProps = dispatch => ({
  hideDropdown: () => dispatch(petVaccinationsActionCreators.hideVaccinationDropdown()),
  showDropdown: () => dispatch(petVaccinationsActionCreators.showVaccinationDropdown())
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  buttonClickHandler: () => {
    propsFromState.isShowingDropdown
      ? propsFromDispatch.hideDropdown()
      : propsFromDispatch.showDropdown();
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AddButton);
