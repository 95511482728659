import { connect } from "react-redux";
import { getSelectedAssociateWeekly } from "../../../core/selectors/commonSelector";
import { createLoadingSelector } from "../../../core/selectors/utils";
import { selectAssociateWeekly } from "../../../core/actionCreators/ui/web/commonActionCreators";
import AssociateDropdownComponent from "../../common/associateDropdown/AssociateDropdownContainer";
import { associateGroupConstants } from "../../../core/constants/associatesConstants";
import {
  getAssociatesByGroup,
  getAssociatesOfCurrentStore
} from "../../../core/selectors/associatesSelectors";
import bookingActionTypes from "../../../core/actionTypes/bookingActionTypes";

const mapStateToProps = state => {
  const associates = getAssociatesOfCurrentStore(state);

  return {
    associates,
    selectedAssociate: getSelectedAssociateWeekly(state),
    disabled: createLoadingSelector([
      bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS,
      bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT
    ])(state),
    associatesByGroup: {
      [associateGroupConstants.ALL]: Object.values(associates),
      ...getAssociatesByGroup(state)
    }
  };
};

const mapDispatchToProps = dispatch => ({
  onSelectAssociate: associateId => dispatch(selectAssociateWeekly({ associateId }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociateDropdownComponent);
