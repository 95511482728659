import React from "react";

export const AlertCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="13" y1="7" x2="5" y2="15" />
      <line x1="5" y1="7" x2="13" y2="15" />
    </svg>
  );
};
