/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const toggleFeatureFlagWindowActionTypes = {
  CLOSE_FEATURE_FLAG_WINDOW: "CLOSE_FEATURE_FLAG_WINDOW",
  OPEN_FEATURE_FLAG_WINDOW: "OPEN_FEATURE_FLAG_WINDOW",
  POSITION_FEATURE_FLAG_WINDOW: "POSITION_FEATURE_FLAG_WINDOW",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const closeFeatureFlagWindow = ({ isHidden }) => ({
  type: toggleFeatureFlagWindowActionTypes.CLOSE_FEATURE_FLAG_WINDOW,
  isHidden,
});

export const openFeatureFlagWindow = ({ isHidden }) => ({
  type: toggleFeatureFlagWindowActionTypes.OPEN_FEATURE_FLAG_WINDOW,
  isHidden,
});
