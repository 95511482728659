import { getSalonPetEligibilityAfterChange } from "@/dux/eligibility/sagas/eligibilitySaga";
import {
  getHotelBookingEligibilityAfterChange,
  getHotelCheckInOutEligibilityAfterChange,
  getHotelProfileEligibilityAfterChange,
} from "@/dux/hotelEligibility/hotelEligibilitySaga";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import {
  isFromBooking,
  isFromCheckInOut,
  isFromPetProfile,
} from "../validationUtils/isFromValidation";

/**
 * Factory to determine which eligibility generator to call after pet updates
 * @memberOf Sagas.Eligibility
 * @function
 * @name afterPetChangeEligibilityFactory
 * @param {String} systemBookingFlow
 * @param {String} path - pathname from current url
 */
export const afterChangePetEligibilityFactory = (systemBookingFlow, path) => {
  const isHotel = systemBookingFlow === systemName.HOTEL;
  if (isHotel && isFromBooking(path)) {
    return getHotelBookingEligibilityAfterChange;
  }
  if (isHotel && isFromPetProfile(path)) {
    return getHotelProfileEligibilityAfterChange;
  }
  if (isHotel && isFromCheckInOut(path)) {
    return getHotelCheckInOutEligibilityAfterChange;
  }

  return getSalonPetEligibilityAfterChange;
};
