import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import MenuButton from "@/web/common/MenuButton";
import { getJobRole } from "@/core/selectors/persistentSelectors";
import { jobRoleConstants } from "@/core/constants/jobRoleConstants";
import { routePaths } from "@/core/constants/routePaths";
import getHotelWorkflowFeatureFlag from "../../enableDisableWorkflowFeatureFlag/selectors/hotel/getHotelWorkflowFeatureFlag";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import getIsHotelAdminWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/hotelAdmin/getIsHotelAdminWorkflowFeatureFlagHidden";
import { jobRoleAllowsForHotel } from "core/utils/jobRolsUtils/jobRoleBasedServiceVisibility";
import { selectCapabilities } from "dux/storeCapabilities/storeCapabilitiesSelectors";

const mapStateToProps = state => {
  const capabilities = selectCapabilities(state);
  const featureFlag = getHotelWorkflowFeatureFlag(state);
  const hotelFeatureFlagIsOn = featureFlag.isHidden;
  const hotelAdminFeatureFlag = getIsHotelAdminWorkflowFeatureFlagHidden(state);
  const associateJobCode = getJobRole(state);
  const systemBookingFlow = getSystemBookingFlow(state);

  return {
    title: "PetsHotel Admin",
    isHidden: !(
      jobRoleAllowsForHotel(associateJobCode, capabilities) &&
      !hotelAdminFeatureFlag &&
      associateJobCode === jobRoleConstants.MANAGER &&
      !hotelFeatureFlagIsOn &&
      systemBookingFlow === systemName.HOTEL
    ),
  };
};

const mapDispatchToProps = (_, { router: { navigate } }) => ({
  onClick: () => {
    navigate(routePaths.MANAGE_HOTEL_OVERBOOKING);
  },
});

const PetsHotelAdminHeaderHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuButton);

export default withRouteProps(PetsHotelAdminHeaderHeaderContainer);
