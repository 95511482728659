/* * ----------------------------------------------------------------------- * *\
  Action Creators  getReservationCartDetailsConfirmation
\* * ----------------------------------------------------------------------- * */
export const SHOW_RESERVATION_CART_DETAILS_CONFIRMATION =
  "SHOW_RESERVATION_CART_DETAILS_CONFIRMATION";
export const SET_PENDING_PRICE = "SET_PENDING_PRICE";
export const CLEAR_PENDING_PRICE = "CLEAR_PENDING_PRICE";
export const SET_PENDING_FOOD = "SET_PENDING_FOOD";
export const CLEAR_PENDING_FOOD = "CLEAR_PENDING_FOOD";
export const CLEAR_PENDING_FOOD_BY_ID = "CLEAR_PENDING_FOOD_BY_ID";
export const SET_PENDING_MED = "SET_PENDING_MED";
export const CLEAR_PENDING_MED = "CLEAR_PENDING_MED";
export const CLEAR_PENDING_MED_BY_ID = "CLEAR_PENDING_MED_BY_ID";
export const SET_RESERVATION_CART_SAVE_BUTTON_DISABLED =
  "SET_RESERVATION_CART_SAVE_BUTTON_DISABLED";
export const SAVE_RESERVATION_CART_DETAILS = "SAVE_RESERVATION_CART_DETAILS";
export const SAVE_RESERVATION_CART_DETAILS_REQUEST = "SAVE_RESERVATION_CART_DETAILS_REQUEST";
export const SAVE_RESERVATION_CART_DETAILS_SUCCESS = "SAVE_RESERVATION_CART_DETAILS_SUCCESS";
export const SAVE_RESERVATION_CART_DETAILS_FAILURE = "SAVE_RESERVATION_CART_DETAILS_FAILURE";
export const PATCH_RESERVATION_CART_DETAILS = "PATCH_RESERVATION_CART_DETAILS";
export const PATCH_RESERVATION_CART_DETAILS_REQUEST = "PATCH_RESERVATION_CART_DETAILS_REQUEST";
export const PATCH_RESERVATION_CART_DETAILS_SUCCESS = "PATCH_RESERVATION_CART_DETAILS_SUCCESS";
export const PATCH_RESERVATION_CART_DETAILS_FAILURE = "PATCH_RESERVATION_CART_DETAILS_FAILURE";
export const PATCH_HOTEL_ITINERARY_STATUS = "PATCH_HOTEL_ITINERARY_STATUS";
export const PATCH_HOTEL_ITINERARY_STATUS_REQUEST = "PATCH_HOTEL_ITINERARY_STATUS_REQUEST";
export const PATCH_HOTEL_ITINERARY_STATUS_SUCCESS = "PATCH_HOTEL_ITINERARY_STATUS_SUCCESS";
export const PATCH_HOTEL_ITINERARY_STATUS_FAILURE = "PATCH_HOTEL_ITINERARY_STATUS_FAILURE";
export const PATCH_HOTEL_ITINERARY_ROOM = "PATCH_HOTEL_ITINERARY_ROOM";
export const PATCH_HOTEL_ITINERARY_ROOM_REQUEST = "PATCH_HOTEL_ITINERARY_ROOM_REQUEST";
export const PATCH_HOTEL_ITINERARY_ROOM_SUCCESS = "PATCH_HOTEL_ITINERARY_ROOM_SUCCESS";
export const PATCH_HOTEL_ITINERARY_ROOM_FAILURE = "PATCH_HOTEL_ITINERARY_ROOM_FAILURE";
export const PATCH_HOTEL_ITINERARY_CHECK_IN_STATUS = "PATCH_HOTEL_ITINERARY_CHECK_IN_STATUS";
export const PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE = "PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE";
export const PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE_REQUEST =
  "PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE_REQUEST";
export const PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE_SUCCESS =
  "PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE_SUCCESS";
export const PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE_FAILURE =
  "PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators  resetPendingReservationCartDetailsConfirmationState

\* * ----------------------------------------------------------------------- * */
export const showReservationCartDetailsConfirmation = ({ isHidden }) => ({
  type: SHOW_RESERVATION_CART_DETAILS_CONFIRMATION,
  isHidden,
});

export const setPendingPrice = ({ payload, engagementId }) => ({
  type: SET_PENDING_PRICE,
  payload,
  engagementId,
});

export const clearPendingPrice = () => ({
  type: CLEAR_PENDING_PRICE,
});

export const setPendingFood = ({ foodData, foodId, petId }) => ({
  type: SET_PENDING_FOOD,
  foodData,
  foodId,
  petId,
});

export const clearPendingFood = () => ({
  type: CLEAR_PENDING_FOOD,
});

export const clearPendingFoodById = ({ foodId }) => ({
  type: CLEAR_PENDING_FOOD_BY_ID,
  foodId,
});

export const setPendingMed = ({ medData, medId, petId }) => ({
  type: SET_PENDING_MED,
  medData,
  medId,
  petId,
});

export const clearPendingMed = () => ({
  type: CLEAR_PENDING_MED,
});

export const clearPendingMedById = ({ medId }) => ({
  type: CLEAR_PENDING_MED_BY_ID,
  medId,
});

export const saveReservationCartDetails = ({ petId, hotelItineraryId, customerKey }) => ({
  type: SAVE_RESERVATION_CART_DETAILS,
  petId,
  hotelItineraryId,
  customerKey,
});

export const saveReservationCartDetailsConfirmationRequest = () => ({
  type: SAVE_RESERVATION_CART_DETAILS_REQUEST,
});

export const saveReservationCartDetailsConfirmationSuccess = () => ({
  type: SAVE_RESERVATION_CART_DETAILS_SUCCESS,
});

export const saveReservationCartDetailsConfirmationFailure = error => ({
  type: SAVE_RESERVATION_CART_DETAILS_FAILURE,
  error,
});

export const patchReservationCartDetails = ({ hotelItineraryId, customerKey }) => ({
  type: PATCH_RESERVATION_CART_DETAILS,
  hotelItineraryId,
  customerKey,
});

export const patchReservationCartDetailsConfirmationRequest = () => ({
  type: PATCH_RESERVATION_CART_DETAILS_REQUEST,
});

export const patchReservationCartDetailsConfirmationSuccess = () => ({
  type: PATCH_RESERVATION_CART_DETAILS_SUCCESS,
});

export const patchReservationCartDetailsConfirmationFailure = error => ({
  type: PATCH_RESERVATION_CART_DETAILS_FAILURE,
  error,
});

export const patchHotelItineraryCheckInStatus = ({ itineraryId, data, petId }) => ({
  type: PATCH_HOTEL_ITINERARY_CHECK_IN_STATUS,
  itineraryId,
  data,
  petId,
});

export const patchHotelItineraryStatus = ({ itineraryId, data }) => ({
  type: PATCH_HOTEL_ITINERARY_STATUS,
  itineraryId,
  data,
});

export const patchHotelItineraryStatusRequest = () => ({
  type: PATCH_HOTEL_ITINERARY_STATUS_REQUEST,
});

export const patchHotelItineraryStatusSuccess = () => ({
  type: PATCH_HOTEL_ITINERARY_STATUS_SUCCESS,
});

export const patchHotelItineraryStatusFailure = error => ({
  type: PATCH_HOTEL_ITINERARY_STATUS_FAILURE,
  error,
});

export const patchHotelItineraryRoom = ({ data }) => ({
  type: PATCH_HOTEL_ITINERARY_ROOM,
  data,
});

export const patchHotelItineraryRoomRequest = () => ({
  type: PATCH_HOTEL_ITINERARY_ROOM_REQUEST,
});

export const patchHotelItineraryRoomSuccess = () => ({
  type: PATCH_HOTEL_ITINERARY_ROOM_SUCCESS,
});

export const patchHotelItineraryRoomFailure = error => ({
  type: PATCH_HOTEL_ITINERARY_ROOM_FAILURE,
  error,
});

export const patchHotelItineraryPriceOverride = ({ itineraryId, customerId, data }) => ({
  type: PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE,
  itineraryId,
  customerId,
  data,
});

export const patchHotelItineraryPriceOverrideRequest = () => ({
  type: PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE_REQUEST,
});

export const patchHotelItineraryPriceOverrideSuccess = () => ({
  type: PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE_SUCCESS,
});

export const patchHotelItineraryPriceOverrideFailure = error => ({
  type: PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE_FAILURE,
  error,
});
