import { createSelector } from "reselect";
import { getPets } from "core/selectors/entitiesSelector";
import { isEmpty, mapValues, pickBy } from "lodash/fp";
import { getPetsEligibility } from "dux/eligibility/selectors/eligibilitySelectors";

/**
 * Find The Pet(s) Within eligibility State that can not book and return an Object of the pets that meet that criteria
 * @return {object} - Pets that can not book.
 */
export const getCanNotBookPets = createSelector(
  [getPetsEligibility, getPets],
  (petsEligibility, pets) =>
    mapValues(
      pet => pets[pet.petId], // Object of Pets based on the ID returned from the pickBy
      pickBy(pet => !pet.canBook, petsEligibility), // Object of pets that can not book
    ),
);

/**
 * Responsible for checking if Pet edibility has reasons, or if any pet can Book or can book is undefined, then all pets can book.
 * @return {boolean}
 */
export const getAllPetsCanNotBook = createSelector([getPetsEligibility], petsEligibility => {
  let allPetsCanNotBook = true;

  // If there are no reasons within pet eligibility, all pets can book
  if (isEmpty(petsEligibility)) {
    return false;
  }

  // If there are reasons within pet eligibility:
  // get Eligibility values and turn into an array to map over e.g [{etId: 1000001555, canBook: true, reasons: Array(3)}, {...}],
  // map through those and if pet can book, all pets can book,
  // eslint-disable-next-line array-callback-return
  Object.values(petsEligibility).map(pet => {
    // eslint-disable-next-line valid-typeof
    if (typeof pet.canBook === undefined || pet.canBook) {
      allPetsCanNotBook = false;
    }
  });

  return allPetsCanNotBook;
});
