import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get the itinerary with the specified ID.
 * For System Services Booking API
 * - Get itinerary
 * @param {*} { customerKey, itineraryId }
 * @returns {*} AxiosPromise - Object literal
 */
export function fetchItinerary({ customerKey, itineraryId }) {
  return bookingAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/itineraries/${itineraryId}`
  );
}

/**
 * Create an itinerary that contains one or more engagements and pet services as specified
 * by the body content of the request.
 * For System Services Booking API
 * - Create itinerary
 * @param {*} { customerKey, data }
 * @returns {*} AxiosPromise
 */
export function postItinerary({ customerKey, data }) {
  return bookingAPI.post(`${endpointVersionString()}/customers/${customerKey}/itineraries`, data);
}

/**
 * Book the itinerary reserving all resources for the pet services included.
 * For System Services Booking API
 * - Book (finalize) itinerary
 * @param {*} { customerKey, itineraryId }
 * @returns {*} AxiosPromise
 */
export function finalizeItinerary({ customerKey, itineraryId }) {
  return bookingAPI.post(
    `${endpointVersionString()}/customers/${customerKey}/itineraries/${itineraryId}`
  );
}

/**
 * Delete the itinerary and all contained engagements and pet services
 * For System Services Booking API
 * - Delete an itinerary
 * @param {*} { customerKey, itineraryId }
 * @returns {*} AxiosPromise
 */
export function deleteItinerary({ customerKey, itineraryId }) {
  return bookingAPI.delete(
    `${endpointVersionString()}/customers/${customerKey}/itineraries/${itineraryId}`
  );
}

/**
 * Update the status of all pet services in all engagements in this itinerary.
 * For System Services Booking API
 * - Update itinerary status
 * @param {*} { customerId, itineraryId, data }
 * @returns {*} AxiosPromise
 */
export function putItineraryStatus({ customerId, itineraryId, data }) {
  return bookingAPI.put(
    `${endpointVersionString()}/customers/${customerId}/itineraries/${itineraryId}/status`,
    data
  );
}

/**
 * Undo the last status update performed on all engagements in this itinerary.
 * For System Services Booking API
 * - Undo the last itinerary status update
 * @export
 * @param {*} { customerId, itineraryId }
 * @returns {*} AxiosPromise
 */
export function deleteLastItineraryStatusUpdate({ customerId, itineraryId }) {
  return bookingAPI.delete(
    `${endpointVersionString()}/customers/${customerId}/itineraries/${itineraryId}/status`
  );
}
