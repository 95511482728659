import React from "react";
import { connect } from "react-redux";
import { Form, Layout, Button  } from "@prism/psm-ui-components";
import { useForm } from "@/dux/utils/formUtils/useForm";
import {
  associateMaxCheckInOptions,
  associateMaxPerBlockOptions, preferredNameConstants,
} from "@/core/constants/associatesConstants";
import { createLoadingSelector } from "@/core/selectors/utils";
import associateActionTypes from "@/core/actionTypes/associateActionTypes";
import { getAssociateFormData } from "@/core/selectors/entitiesSelector";
import { hasManagerPrivileges } from "@/core/selectors/persistentSelectors";
import { getAssociatesModalType } from "@/core/selectors/modals/associatesModalSelectors";
import associateActionCreators from "@/core/actionCreators/associateActionCreator";
import { employeeGroupConstants } from "@/core/constants/associatesConstants";
import { routePaths } from "@/core/constants/routePaths";
import UnknownAssociateImage from "@/assets/images/associate-image.png";
import AssociatesModals from "@/web/associates/modals/associatesModalsContainer";
import AssociateProfileUpdateRestrictedMessage from "../AssociateProfileUpdateRestrictedMessage";
import { getEmployeeGroupsDropdownOptions } from "../associateProfilePageSelectors";
import { TextWithCharacterCountComponent } from "@/dux/_components/textWithCharacterCount/TextWithCharacterCountComponent";
import { toggleAssociateProfileModal } from "../associateProfilePageActions";
import AssociateProfileModal from "./AssociateProfileModal";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { cleanPreferredNameEventValue } from "dux/associateProfile/associateProfileForm/AssociateProfileformHelper";

const AssociateProfileFormSalon = ({
  associateFormData,
  onSubmit,
  isManager,
  employeeGroups,
  modalType,
  isLoading,
  redirectToAssociatesPage,
  textFieldStyles,
}) => {
  const { values, didFormFieldsUpdate, setFormInput, setDropdownOption } = useForm(
    associateFormData,
  );

  const {
    firstName,
    preferredName,
    lastName,
    associateGroup,
    phoneNumber,
    commisionPercentage,
    associateId,
    maxCheckIn,
    weightRestriction,
    maxPerBlock1,
    maxPerBlock2,
  } = values;

  const isSubmitButtonDisabled = isLoading || !isManager || !didFormFieldsUpdate;

  return (
    <Layout.Box padding="box-padding-5">
      <AssociateProfileModal />
      <Layout.Stack space="stack-space-6">
        <Layout.Box
          padding="box-padding-16"
          borderWidth="box-border-width"
          borderColor="box-border-color-gray-100"
        >
          <Layout.Sidebar sideWidth="40%" space="sidebar-space-8">
            <Layout.Stack space="stack-space-4">
              <img src={UnknownAssociateImage} style={{ minWidth: "370px", width: "100%" }} />
              <Layout.Center>
                <Layout.Box>
                  <Button variant="link" size="xl">
                    Change Photo
                  </Button>
                </Layout.Box>
              </Layout.Center>
            </Layout.Stack>
            <Layout.Stack space="stack-space-10">
              <Form.Field.Text
                id={`AssociateProfile__firstName--${associateId}`}
                disabled
                label="First Name"
                name="firstName"
                variant="material"
                style={textFieldStyles}
                value={firstName}

              />
              <TextWithCharacterCountComponent
                value={preferredName}
                maxLength={preferredNameConstants.limit}
              >
                <Form.Field.Text
                  id={`AssociateProfile__preferredName--${associateId}`}
                  label="Preferred Name"
                  name="preferredName"
                  variant="material"
                  style={textFieldStyles}
                  onChange={(e) => setFormInput(cleanPreferredNameEventValue(e))}
                  value={preferredName}
                  maxLength={preferredNameConstants.limit}
                  placeholder={preferredNameConstants.placeHolder}
                />
              </TextWithCharacterCountComponent>
              <Form.Field.Text
                id={`AssociateProfile__lastName--${associateId}`}
                disabled
                label="Last Name"
                name="lastName"
                variant="material"
                style={textFieldStyles}
                value={lastName}
              />
              <Form.Field.Select
                id={`AssociateProfile__associateGroup--${associateId}`}
                legacy
                label="Employee Group"
                name="associateGroup"
                variant="material"
                options={employeeGroups}
                onChange={setDropdownOption}
                value={associateGroup}
              />
              <Form.Field.Text
                id={`AssociateProfile__phoneNumber--${associateId}`}
                disabled
                label="Phone Number"
                variant="material"
                name="phoneNumber"
                style={textFieldStyles}
                value={phoneNumber}
              />
              {/* Disable updates to Groom Pay % by default */}
              <Form.Field.Text
                id={`AssociateProfile__commisionPercentage--${associateId}`}
                disabled
                label="Groom Pay %"
                name="commisionPercentage"
                variant="material"
                style={textFieldStyles}
                value={commisionPercentage}
              />
              <Form.Field.Text
                id={associateId}
                disabled
                label="Associate ID"
                name="associateId"
                variant="material"
                style={textFieldStyles}
                value={associateId}
              />
              <Form.Field.Select
                id={`AssociateProfile__maxCheckIn--${associateId}`}
                legacy
                label="Check In Max"
                name="maxCheckIn"
                options={associateMaxCheckInOptions}
                onChange={setDropdownOption}
                value={maxCheckIn}
              />
              <Form.Field.Text
                id={`AssociateProfile__weightRestriction--${associateId}`}
                label="Weight Restrictions"
                name="weightRestriction"
                variant="material"
                style={textFieldStyles}
                onChange={setFormInput}
                value={weightRestriction}
              />
              <Form.Field.Select
                id={`AssociateProfile__maxPerBlock1--${associateId}`}
                legacy
                label="Max Per Block #1"
                name="maxPerBlock1"
                variant="material"
                options={associateMaxPerBlockOptions}
                onChange={setDropdownOption}
                value={maxPerBlock1}
              />
              <Form.Field.Select
                id={`AssociateProfile__maxPerBlock2--${associateId}`}
                legacy
                label="Max Per Block #2"
                name="maxPerBlock2"
                variant="material"
                options={associateMaxPerBlockOptions}
                onChange={setDropdownOption}
                value={maxPerBlock2}
              />
            </Layout.Stack>
          </Layout.Sidebar>
        </Layout.Box>
        <Layout.Cluster space="cluster-space-2" justify="right">
          <Button variant="prism-primary-inverted" onClick={redirectToAssociatesPage}>
            Cancel
          </Button>
          <Button
            variant="prism-primary"
            type="submit"
            disabled={isSubmitButtonDisabled}
            onClick={() => onSubmit(values)}
          >
            Submit
          </Button>
        </Layout.Cluster>
        <Layout.Cluster justify="flex-end">
          <AssociateProfileUpdateRestrictedMessage />
        </Layout.Cluster>
      </Layout.Stack>
      {modalType && <AssociatesModals modalType={modalType} />}
    </Layout.Box>
  );
};

export default withRouteProps(
  connect(
    (state, { router }) => ({
      isLoading: createLoadingSelector([associateActionTypes.UPDATE_ASSOCIATE])(state),
      associateFormData: getAssociateFormData(state, { associateId: router.params.associateId }),
      employeeGroups: getEmployeeGroupsDropdownOptions(state) || employeeGroupConstants,
      isManager: hasManagerPrivileges(state),
      modalType: getAssociatesModalType(state),
      textFieldStyles: { width: "100%" },
    }),
    (dispatch, { router: { navigate } }) => ({
      putUpdateAssociate: ({ associateId, data }) => {
        dispatch(
          associateActionCreators.updateAssociate({
            associateId,
            data,
          }),
        );
      },
      redirectToAssociatesPage: () => {
        navigate(routePaths.ASSOCIATES);
      },
      toggleModal: () => {
        dispatch(toggleAssociateProfileModal());
      }
    }),
    (stateProps, dispatchProps, { router }) => ({
      ...stateProps,
      ...dispatchProps,

      onSubmit: (values) => {
        const associateId = router.params.associateId;
        const { putUpdateAssociate, toggleModal } = dispatchProps;

        if (associateId) {
          if (values.associateGroup !== stateProps.associateFormData.associateGroup) {
            toggleModal()
          }
          putUpdateAssociate({
            associateId,
            data: values,
          });
        }
      },
    }),
  )(AssociateProfileFormSalon),
);
