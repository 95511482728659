import { injectGlobal } from "styled-components";
import { device } from "web/common/styles/responsive/devices";
import { fontSizes } from "web/common/styles/responsive/fonts";

injectGlobal`

  html, body, #root{
    height: 100%;
  }
  
  html{
    color: black;
    
    @media ${device.mobileS} {
      font-size: 28px;
    }
    
    @media ${device.mobileL} {
      font-size: 20px;
    }
    
    @media ${device.mobileL} {
      font-size: 18px;
    }
    
    @media ${device.laptop} {
      font-size: 14px;
    }
    
    @media ${device.laptopL} {
      font-size: 16px;
    }
    
    // There were concerns around responsive affecting the UI layout a week before the BGM deployment, All media queries
    // that use the device object will have a media query to target Prism desktop to support BGM release.
    // This media query can be removed after 11/9/20
    @media ${device.prismDesktop} {
      font-size: 16px;
    }
  }

  #root {
    display: grid;
  }

  body {
    -webkit-print-color-adjust: exact;
    font-family: Lato;
    margin: 0;
  }
 
`;

export const color = {
  tChalla: "#000",
  kalEl: "#1777f2",
  victorStone: "#fff",
  silverSurfer: "#565a5c",
  storm: "#b6b6b6",
  profX: "#333",
  ororoMunroe: "#dedede",
  borderGrey: "#bec0c4",
  whiteGrey: "#f2f2f2",
  darkGrey: "#333333",
  lightGrey: "#6b6b6b",
  veryLightGrey: "#eaeaea",
  errorRed: "#e62722",
  grey: "#a3a3a3",
  blueBackground: "#dbe4f0",
  tealish: "#23c7a1",
  darkTealBlue: "#007db4",
  duskyBlue: "#465f89",
  deepSkyBlue: "#2684ff",
  tableRowBackground: "#f8f8f8",
  tableTotalsBackground: "#eaeef4",
  wolverine: "#f4c43a",
  halJordan: "#23c76a",
  statusBlue: "#1BDFB3",
  lightPink: "#ff000015",
};

export const font = {
  mainFamily: "Lato, sans-serif",
  secondaryFamily: "Montserrat, sans-serif",
  sparkyFamily: "euclid",
};

export const lineThickness = {
  sliver: "0.0625rem",
};

export const modalPadding = "45px 15px 30px 15px";

export const scheduleGridColumns = "2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.7fr";

export const dropDownUri =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+LUNEtwAAARxJREFUGJV1kbGuMVEURtc5hkaiEHoFpYfQaYRiROcl1EpReAPR0IhHmHdQzalIREVGNYlCzMnId4ubKfx/7q73yvfttY0k3u+3rLVUKhXDH5NlmUqlEkEQGPt6vTSfz1mv16Rpqn+XP5+PnHNaLBZEUUSe5wokkaYpx+MR7z3T6VSNRsMUwOl0YrVa8Xg86Ha7SMJWq1Uzm83odDpEUcR+vydJEmVZpjiOWS6XJEnCYDCg1+tRLpeNkUSe53LOsdlsuF6v9Pt92u02h8OB2+3GaDRiMpnQbDYNgJF+z/DeK45jttst5/MZay3ee8bjMWEYUlT+ggpDzjl2ux2Xy4UwDBkOh9Tr9S+rX1CReL/feT6ftFotarXaf2/4AWKcnQf3XZtzAAAAAElFTkSuQmCC";

export const downArrowUri =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+LUNEtwAAARxJREFUGJV1kbGuMVEURtc5hkaiEHoFpYfQaYRiROcl1EpReAPR0IhHmHdQzalIREVGNYlCzMnId4ubKfx/7q73yvfttY0k3u+3rLVUKhXDH5NlmUqlEkEQGPt6vTSfz1mv16Rpqn+XP5+PnHNaLBZEUUSe5wokkaYpx+MR7z3T6VSNRsMUwOl0YrVa8Xg86Ha7SMJWq1Uzm83odDpEUcR+vydJEmVZpjiOWS6XJEnCYDCg1+tRLpeNkUSe53LOsdlsuF6v9Pt92u02h8OB2+3GaDRiMpnQbDYNgJF+z/DeK45jttst5/MZay3ee8bjMWEYUlT+ggpDzjl2ux2Xy4UwDBkOh9Tr9S+rX1CReL/feT6ftFotarXaf2/4AWKcnQf3XZtzAAAAAElFTkSuQmCC";
export const upArrowUri =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+LUNEtwAAARlJREFUGJVdkCHPglAYhc/9cFdmMJGczU2TVqtFCpkmOueM/hSL/gCqg1v4IzZsbpAIOIMzMHblGNzHGKe+e86z9wiSaOf1ejFJEvT7fQwGA0gpRfPeaQPP55NRFEEphdFohPV6jel0ym63W4OiaXo8HlRKIQxDSClRVRXG4zE2mw1ms1lt/PsH8jzn5XKBUgrD4RCHwwGO4+B+v8P3fcRxDK31z0ASeZ7zfD5zuVxytVrxer2yKApmWcbT6UTbtrnf73m73UgSKMuSQRDQtm16nsc4jqm1Jsm68Hg8crFYcLfb8f1+syOEQK/Xw3w+h+u6mEwmMAyjftqyLLHdbimlRJqmEEL8htBa8/P5oLlQO2VZsqoqmKYpviE3nm3cNL8QAAAAAElFTkSuQmCC";

export const downArrowUriWhite =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+LUNEtwAAAOlJREFUGJV10buqwkAQgOF/L2yCpEiwSZFN6xMIvn8lJE9gbcBCEwjRXQUzVkr0cKaagflgLkpEmOdZlFIopRT/xLLHzvMsp9OJ+/2O916apvmCu91OQgj0fc80TWw2G9EA1lrW6zXH45HtditLEGPker2SZRlaawCUiBBCkHEcSZKEYRioqgpjDCEEbrcbzjn6vsd7j7VWaYA0TcmyjBgjRVHQdR2Hw+ELVFXFe3QNsN/v1Wq1+sA8zynLEuccl8sF7z1t23521e/kF4oI5/OZuq75PY4SkWWNiMjj8eD5fJIkCcaYP294AZPYfcB0Xb9sAAAAAElFTkSuQmCC";

export const upArrowUriWhite =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+LUNEtwAAAPpJREFUGJVdkNGqglAQRdfYJc4xkTQf6y8S6v8fVOobgtJXIfAQnIM69+FS2F2Pe/aeYbaoKv+Zpkm996xWK9brNSIiy/kPQFVVH6EsS73f7+R5jveeJEmI41ibphGA8/lMtNxQlqU+Hg+KokBEMMbgnOP1enE6nfTrEsDxeNS2bdntdoQQ6PseYwzb7RbnHCLyDoqoKuM4atu25HlOCIE4jrHWMk0TXdeRZRnee9I0xVorkarq7XYjyzJCCO8faJpGrterHA4Hns8n1lqGYWCeZ40A5nnGOcdms8EYQ13Xn7Yul4vs93v6vmccRwBEVdE/iKLoq9olS88v3nSDsB8cSmIAAAAASUVORK5CYII=";
