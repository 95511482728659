import { put } from "redux-saga/effects";
import mergeEnginesResult from "dux/eligibility/helperUtils/mergeResults";
import normalizeArrayByProperty from "core/utils/normalizeUtils/normalizeArray";

/**
 *
 * @param { Array }primaryEngineResult - List of objects e.g. {reasons: Array(1), canBook: true, petId: 1000002650}
 * @param { Array }secondaryEngineResult - List of object e.g. {petId: 1000001555, canBook: true, reasons: Array(1)}
 * @param { FN }successAction - Redux action to show success
 * @return {Generator<SimpleEffect<"PUT", PutEffectDescriptor<*>>, {}|{}, ?>}
 */
// eslint-disable-next-line consistent-return
export function* onNormalizeCombinedEngines({
  primaryEngineResult,
  secondaryEngineResult,
  successAction,
}) {
  const mergedResults = mergeEnginesResult({ primaryEngineResult, secondaryEngineResult });

  if (mergedResults) {
    yield put(successAction());
    return normalizeArrayByProperty(mergedResults, "petId");
  }

  yield put(successAction());
  // should we return an empty object?
}
