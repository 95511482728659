import { connect } from "react-redux";
import { createLoadingSelector } from "../../../../../core/selectors/utils";
import vetsActionTypes from "../../../../../core/actionTypes/vetsActionTypes";
import vetsActionCreators from "../../../../../core/actionCreators/vetsActionCreators";
import petProfileActionCreators from "../../../../../core/actionCreators/petProfileActionCreators";
import { modalTypes } from "../../../../../core/constants/petProfileConstants";
import { getCurrentCustomerKey } from "../../../../../core/selectors/persistent/customer/customerSelectors";
import { getCurrentPet } from "../../../../../core/selectors/persistentSelectors";
import AddButton from "../../../../common/buttons/addButton";

const mapStateToProps = (state, ownProps) => {
  const petId = getCurrentPet(state);
  const customerKey = getCurrentCustomerKey(state);
  return {
    customerKey,
    petId,
    isVisible: true,
    opacity: "1",
    label: "Add Vet",
    fontSize: "regular",
    disabled: createLoadingSelector([vetsActionTypes.DELETE_VET])(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addVet: (petId, customerKey) => {
    dispatch(
      vetsActionCreators.setCustomerAndPetKeys({
        customerKey,
        petId
      })
    );
    dispatch(petProfileActionCreators.showPetProfileModal(modalTypes.SEARCH_VET));
  }
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  buttonClickHandler: () => {
    const { customerKey, petId } = propsFromState;

    propsFromDispatch.addVet(petId, customerKey);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AddButton);
