import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  hotelRoomCapacityActionTypes,
  loadHotelRoomCapacityFailure,
  loadHotelRoomCapacitySuccess,
} from "dux/hotelRoomCapacity/hotelRoomCapacityActions";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";

export function* loadHotelCapacity({ hotelCapacity }) {
  try {
    // Is the selected date in dashboardSelectedDateList state?
    const selectedDate = yield select(getSelectedDate);

    // map through each one of the hotelCapacity from API and add an assigned date property.
    const capacityWithDate = hotelCapacity.map(capacityObj => {
      const clonedCapacity = { ...capacityObj };
      clonedCapacity.assignedDate = selectedDate;
      return clonedCapacity;
    });

    yield put(loadHotelRoomCapacitySuccess({ hotelCapacity: capacityWithDate }));

    // yes it was, so we do noting and move on to next saga!
  } catch (error) {
    yield put(loadHotelRoomCapacityFailure({ error }));
  }
}

function* watchHotelCapacity() {
  yield takeEvery(hotelRoomCapacityActionTypes.LOAD_HOTEL_ROOM_CAPACITY_REQUEST, loadHotelCapacity);
}

export default function* hotelCapacitySaga() {
  yield all([fork(watchHotelCapacity)]);
}
