import { connect } from "react-redux";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../../web/setSystemType/constants/setSystemTypeConstants";
import AlertModalComponent from "../_components/modals/AlertWithActionAndCancelModal";

const mapStateToProps = state => ({
  header: "Warning: Manual Booking",
  message:
    "You are about to book an unavailable service, are you sure you want to proceed with booking?",
  isVisible: true,
  label: "Continue",
  isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
  componentID: "bookingAlertsModal-manualBooking",
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose: () => ownProps.onClose && ownProps.onClose(),
  onButtonClick: () => ownProps.onShouldProceed && ownProps.onShouldProceed(true),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertModalComponent);
