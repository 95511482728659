import { put, call, all, takeEvery, select } from "redux-saga/effects";

// Selectors
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getCurrentItinerary } from "@/core/selectors/checkInOutSelector";

// Endpoints
import { patchPrimaryServiceChange } from "@/core/services/associateWebBff/hotelPrimaryServiceChangeEndpoint";

// Sagas
import { saveHotelItineraryResponse } from "@/dux/hotelItinerary/hotelItinerarySaga";

// Actions
import {
  PATCH_HOTEL_PRIMARY_SERVICE_CHANGE,
  patchHotelPrimaryServiceChangeRequest,
  patchHotelPrimaryServiceChangeSuccess,
  patchHotelPrimaryServiceChangeFailure,
} from "./hotelPrimaryServiceChangeActions";

function* onPatchHotelPrimaryService({ data, onComplete = () => {} }) {
  try {
    yield put(patchHotelPrimaryServiceChangeRequest());
    const customerId = yield select(getCurrentCustomerKey);
    const itineraryId = yield select(getCurrentItinerary);

    const response = yield call(patchPrimaryServiceChange, { customerId, data, itineraryId });

    // Parse and store hotel itinerary response
    yield call(saveHotelItineraryResponse, { responseData: response.data.result });
    onComplete();
    yield put(patchHotelPrimaryServiceChangeSuccess());
  } catch (error) {
    yield put(patchHotelPrimaryServiceChangeFailure(error));
  }
}
function* watchPatchHotelPrimaryService() {
  yield takeEvery(PATCH_HOTEL_PRIMARY_SERVICE_CHANGE, onPatchHotelPrimaryService);
}

export default function* primaryServiceChangeSaga() {
  yield all([watchPatchHotelPrimaryService()]);
}
