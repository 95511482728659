import React from "react";
import { LayoutBox } from "@/layout/box/Box";
import PetParentColumnHeader from "./petParentColumnHeader/PetParentColumnHeader";
import { color } from "../common/styles/theme";

const PetParentProfileColumn = ({ title, shadow, noRightBorder, disabled, children }) => (
  <LayoutBox
    padding="scale-0"
    style={{
      borderRight: noRightBorder ? "none" : `1px solid ${color.borderGrey}`,
      border: shadow ? "none" : "auto",
      boxShadow: shadow ? "0 0.625rem 2.125rem 0 rgba(13, 18, 27, 0.12)" : "none",
      opacity: disabled ? "0.3" : "auto",
    }}
  >
    <PetParentColumnHeader
      componentID={`petParentColumnHeader-${title.replace(/\s/gi, "-")}-Column`}
      noRightBorder={noRightBorder}
      title={title}
    />
    <LayoutBox padding="scale-G1">{children}</LayoutBox>
  </LayoutBox>
);

export default PetParentProfileColumn;
