import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

// Actions
import { loadCustomer } from "core/actionCreators/customersActionCreators";
import {
  GET_HOTEL_ITINERARY,
  PUT_HOTEL_ITINERARY,
  clearHotelItinerary,
  getHotelItinerary,
} from "dux/hotelItinerary/hotelItineraryActions";
import { setPetId } from "core/actionCreators/ui/web/generalActionCreators";
import { loadHotelPriceAdjustmentReasons } from "dux/hotelPriceAdjustmentReasons/hotelPriceAdjustmentActions";
import {
  PATCH_HOTEL_MANUAL_SPECIALS,
  REMOVE_HOTEL_MANUAL_SPECIALS,
} from "dux/applySpecial/applyManaulSpecialsHotelActions";
import { getFrequencyOptions } from "../frequencyOptions/frequencyOptionsActions";
import {
  PATCH_HOTEL_ITINERARY_ROOM,
  PATCH_HOTEL_ITINERARY_STATUS,
  PATCH_RESERVATION_CART_DETAILS,
  SAVE_RESERVATION_CART_DETAILS,
} from "../reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import { DELETE_CHECK_IN_MED } from "../_components/checkInOutMedications/checkInMedsActions";
import { DELETE_CHECK_IN_FEEDING } from "../_components/checkInOutFeeding/checkInFoodsActions";
import { GET_HOTEL_SERVICE_CARD } from "@/dux/hotelPrintServiceCardButton/hotelPrintServiceCardActions";
import { PATCH_HOTEL_ITINERARY_ADDONS } from "../hotelItineraryAddonPatch/hotelItineraryAddonPatchActions";
import { selectPet } from "@/core/actionCreators/bookingActionCreators";

// Selectors
import { getInitialHotelEngagementsPet } from "dux/hotelEngagements/hotelEngagementSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";

// Components
import CheckInOutHeaderContainer from "web/header/CheckInOutHeaderContainer";
import SharedCheckInOutWrapper from "web/petCheckInOut/sharedCheckInOutWrapperContainer";
import { HotelCheckOutLayout } from "@/dux/checkInOut/CheckOutLayout";
import { HotelCheckInLayout } from "@/dux/checkInOut/CheckInLayout";
import {
  HotelConfirmAllAppointment,
  HotelConfirmSingleAppointment,
} from "dux/hotelConfirmAppt/hotelConfirmApptButton";
import { HotelCheckInOutModals } from "web/petCheckInOut/modals/checkInOutModals";
import { HotelPrintServiceCardButton } from "dux/hotelPrintServiceCardButton/hotelPrintServiceCardButton";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { HotelPrintInvoiceButton } from "../printInvoice/printInvoiceButton";

// Utils
import getParamFromPath from "core/utils/matchUtils/getParmFromPath";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { getCheckInOutRoutePath } from "@/core/utils/validationUtils/isFromValidation";

// Constants & Assets
import HeaderImage from "assets/images/bg-header-image.png";
import { paramName } from "core/constants/urlParamConstants";
import { routePaths } from "@/core/constants/routePaths";
import { PUT_HOTEL_ITINERARY_ADDONS } from "../hotelItineraryAddonPut/hotelItineraryAddonPutActions";
import { PATCH_HOTEL_ITINERARY_PET_NOTES } from "../hotelItineraryPetNotes/hotelItineraryPetNotesActions";
import { PATCH_HOTEL_PRIMARY_SERVICE_CHANGE } from "../hotelPrimaryServiceChange/hotelPrimaryServiceChangeActions";
import { getHotelItinerary as selectHotelItinerary } from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { getStoreTimeZone } from "@/core/selectors/entitiesSelector";
import { loadSalonInfoWithHours } from "@/core/actionCreators/salonActionCreator";
import { GET_STORE_SETTINGS, getStoreSettings } from "../storeSettings/storeSettingsActions";

const hotelPageContent = {
  [routePaths.CHECK_IN]: HotelCheckInLayout,
  [routePaths.CHECK_OUT]: HotelCheckOutLayout,
};

export const CheckInOutPage = props => {
  const {
    componentId,
    makeAPICalls,
    setCurrentPetCall,
    itineraryPet,
    customerKey,
    itineraryId,
    isLoading,
    pageContent,
    clearPageData = () => {},
    storeNumber,
    getStoreData = () => {},
  } = props;

  useEffect(() => {
    itineraryId && makeAPICalls();
  }, [itineraryId]);

  useEffect(() => {
    if (storeNumber) getStoreData();
  }, [storeNumber]);

  useEffect(() => {
    return () => {
      if (typeof clearPageData === "function") clearPageData();
    };
  }, []);

  // If itineraryPet changes (itinerary changes, or is null on initial load) update currentPet
  useEffect(() => {
    setCurrentPetCall(itineraryPet);
  }, [itineraryPet]);

  const CheckInOutPageContent = pageContent;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LoadingWrapper isLoading={isLoading} fullScreenHeight={true} fullScreenWidth={true} />
      <LayoutStack space="scale-0">
        {/* HEADER ----------------------------------------------------------------------------------------------------*/}
        <LayoutBox id={`${componentId}__header`} padding="scale-0">
          <CheckInOutHeaderContainer backgroundImage={HeaderImage}>
            <HotelConfirmSingleAppointment />
            <HotelConfirmAllAppointment />
            <HotelPrintInvoiceButton />
            <HotelPrintServiceCardButton />
          </CheckInOutHeaderContainer>
        </LayoutBox>

        {/* PAGE CONTENT ----------------------------------------------------------------------------------------------*/}
        <CheckInOutPageContent customerKey={customerKey} setPetId={setCurrentPetCall} />
      </LayoutStack>

      <HotelCheckInOutModals />
    </LayoutBox>
  );
};

// HOTEL CHECKIN PAGE CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInPage = compose(
  withRouteProps,
  connect(
    (state, { router }) => {
      const customerKey = getParamFromPath(paramName.CUSTOMER_KEY);
      const itineraryId = getParamFromPath(paramName.ITINERARY_ID);
      const routePath = getCheckInOutRoutePath(router?.location?.pathname);
      const storeNumber = selectHotelItinerary(state)?.storeNumber;
      const timeZone = getStoreTimeZone(state, { storeNumber });

      return {
        componentId: "HotelCheckInPage",
        customerKey,
        itineraryId,
        itineraryPet: getInitialHotelEngagementsPet(state),
        pageContent: hotelPageContent[routePath],
        isLoading: createLoadingSelector([
          GET_HOTEL_ITINERARY,
          GET_HOTEL_SERVICE_CARD,
          SAVE_RESERVATION_CART_DETAILS,
          PATCH_RESERVATION_CART_DETAILS,
          PATCH_HOTEL_ITINERARY_ROOM,
          PATCH_HOTEL_ITINERARY_STATUS,
          PATCH_HOTEL_MANUAL_SPECIALS,
          REMOVE_HOTEL_MANUAL_SPECIALS,
          PATCH_HOTEL_ITINERARY_ADDONS,
          PUT_HOTEL_ITINERARY_ADDONS,
          DELETE_CHECK_IN_FEEDING,
          DELETE_CHECK_IN_MED,
          PUT_HOTEL_ITINERARY,
          PATCH_HOTEL_ITINERARY_PET_NOTES,
          PATCH_HOTEL_PRIMARY_SERVICE_CHANGE,
          GET_STORE_SETTINGS,
        ])(state),
        storeNumber,
        timeZone,
      };
    },
    dispatch => {
      return {
        dispatchSetCurrentPet: petId => {
          // petId in persistent state
          dispatch(setPetId(petId));

          // petId in state for profile components (vaccinations, etc.), some of those components
          // will throw errors if it is null/undefined, so make sure it exists first
          if (petId) dispatch(selectPet(petId));
        },
        dispatchMakeAPICalls: ({ customerKey, itineraryId }) => {
          // specific to Hotel
          dispatch(loadCustomer({ customerKey }));
          dispatch(getHotelItinerary({ customerKey, itineraryId }));
          dispatch(loadHotelPriceAdjustmentReasons({}));
          dispatch(getFrequencyOptions());
          dispatch(getStoreSettings());
        },
        clearPageData: () => {
          dispatch(clearHotelItinerary());
        },
        getStoreData: storeNumber => {
          dispatch(loadSalonInfoWithHours({ storeNumber }));
        },
      };
    },
    (mapProps, dispatchProps) => {
      const {
        customerKey,
        itineraryId,
        componentId,
        itineraryPet,
        isLoading,
        pageContent,
        storeNumber,
        timeZone,
      } = mapProps;
      const {
        dispatchMakeAPICalls,
        dispatchSetCurrentPet,
        clearPageData,
        getStoreData,
      } = dispatchProps;
      return {
        componentId,
        customerKey,
        itineraryPet,
        itineraryId,
        isLoading,
        pageContent,
        storeNumber,
        setCurrentPetCall: petId => dispatchSetCurrentPet(petId),
        makeAPICalls: () => dispatchMakeAPICalls({ customerKey, itineraryId }),
        getStoreData: () => {
          if (!timeZone && storeNumber) getStoreData(storeNumber);
        },
        clearPageData,
      };
    },
  ),
)(CheckInOutPage);

// SALON CHECKIN PAGE CONTAINER --------------------------------------------------------------------------------------------
export const SalonCheckInPage = connect(
  state => {
    return {
      componentId: "SalonCheckInPage",
    };
  },
  dispatch => {
    return {
      makeAPICalls: () => {
        // specific to Salon
      },
    };
  },
  // NOTE: we are currently pointing to the legacy(Salon) SharedCheckInOutWrapper, this will will be refactored into using the new CheckInOutPage
  // As we plan to bring Salon over from legacy code to this format.
)(SharedCheckInOutWrapper);
