import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import MenuButton from "@/web/common/MenuButton";
import { routePaths } from "../../../core/constants/routePaths";
import { getJobRole } from "@/core/selectors/persistentSelectors";
import { jobRoleConstants } from "@/core/constants/jobRoleConstants";
import getHotelWorkflowFeatureFlag from "../../enableDisableWorkflowFeatureFlag/selectors/hotel/getHotelWorkflowFeatureFlag";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = state => {
  const featureFlag = getHotelWorkflowFeatureFlag(state);
  const hotelFeatureFlagIsOn = featureFlag.isHidden;
  const associateJobCode = getJobRole(state);
  const systemBookingFlow = getSystemBookingFlow(state);

  return {
    className: "ServicesManager__menuButton",
    title: "Services Manager",
    isHidden: !(
      associateJobCode === jobRoleConstants.MANAGER &&
      !hotelFeatureFlagIsOn &&
      systemBookingFlow === systemName.HOTEL
    ),
  };
};

const mapDispatchToProps = (_, { router: { navigate } }) => ({
  onClick: () => {
    navigate(routePaths.SERVICE_MANAGEMENT);
  },
});

const ServiceManagerHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(MenuButton);

export default withRouteProps(ServiceManagerHeaderContainer);
