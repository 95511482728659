import React from "react";
import classnames from 'classnames';
import './Hr.css';

export interface HrProps {
  /**
   * Stylistic variations:
   * - **brand** yields a branded horizontal rule
   */
  variant?: 'brand';

  /**
   * Size variations:
   * - **lg** yields a thicker horizontal rule
   */
  size?: 'lg';
  /**
   * CSS class names that can be appended to the component.
   */
  className?: string;
}

/**
 * Primary UI component for user interaction
 */
const Hr = ({ className, size, variant, ...other }: HrProps) => {
  const componentClassName = classnames('prism-hr', className, {
    'prism-hr--lg': size === 'lg',
    'prism-hr--brand': variant === 'brand',
  });

  return <hr className={componentClassName} {...other} />;
};

export default Hr