import { connect } from "react-redux";
import { createLoadingSelector } from "../../../../core/selectors/utils";
import { toggleStandaloneAccordion } from "../../toggleAccordionActions";
import { isHiddenStandaloneList } from "./StandaloneServicesAccordionSelectors";
import servicesSelectionActionTypes from "../../../../core/actionTypes/servicesSelectionActionTypes";
import customersActionTypes from "../../../../core/actionTypes/customersActionTypes";
import addOnsActionTypes from "../../../../core/actionTypes/addOnsActionTypes";
import Accordion from "../../../../web/common/Accordion/AccordionComponent";

const loaderDependencies = [
  servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_PET,
  customersActionTypes.LOAD_CUSTOMER,
  addOnsActionTypes.LOAD_ADDONS,
];

const mapStateToProps = state => {
  const isLoading = createLoadingSelector(loaderDependencies)(state);
  const isOpen = isHiddenStandaloneList(state);

  return {
    componentID: "toggleAccordion-AccordionComponent",
    label: "Standalone Services",
    isLoading,
    isOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchHeaderToggle: isOpen => dispatch(toggleStandaloneAccordion(isOpen)),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  onClick: () => propsFromDispatch.dispatchHeaderToggle(propsFromState.isOpen),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Accordion);
