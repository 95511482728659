import React from "react";
import { connect } from "react-redux";
import { TextPassage } from "@petsmart-ui/sparky";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { getFoodIsDiscontinuedById } from "@/web/food/foodsSelector";
import { color } from "@/web/common/styles/theme";
import selectIsDiscontinuedFoodsFeatureHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/discontinuedFoods/getIsDiscontinuedFoodsWorkflowFeatureFlagHidden";
import selectIsDiscontinuedMedsFeatureHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/discontinuedMeds/getIsDiscontinuedMedsWorkflowFeatureFlagHidden";
import { getMedIsDiscontinuedById } from "@/web/medication/medicationsSelector";

export const DiscontinuedLabel = props => {
  const { componentId, isHidden, label } = props;
  if (isHidden) return null;

  return (
    <LayoutCluster id={componentId}>
      <TextPassage size="lg">{label}</TextPassage>
      <TextPassage size="lg" style={{ color: color.errorRed }}>
        Discontinued
      </TextPassage>
    </LayoutCluster>
  );
};

export const FoodsDiscontinuedLabel = connect((state, { petId, foodId, foodIndex }) => {
  const isDiscontinuedFoodFeatureHidden = selectIsDiscontinuedFoodsFeatureHidden(state);
  if (isDiscontinuedFoodFeatureHidden) return { isHidden: true };

  const isDiscontinued = getFoodIsDiscontinuedById(state, { petId, foodId });

  return {
    componentId: `FoodsDiscontinuedLabel_${foodIndex}`,
    isHidden: !isDiscontinued,
    label: `Food ${foodIndex}`,
  };
})(DiscontinuedLabel);

export const MedsDiscontinuedLabel = connect((state, { petId, medicationId, medicationIndex }) => {
  const isDiscontinuedMedFeatureHidden = selectIsDiscontinuedMedsFeatureHidden(state);
  if (isDiscontinuedMedFeatureHidden) return { isHidden: true };

  const isDiscontinued = getMedIsDiscontinuedById(state, { petId, medicationId });

  return {
    componentId: `MedsDiscontinuedLabel_${medicationIndex}`,
    isHidden: !isDiscontinued,
    label: `Medication ${medicationIndex}`,
  };
})(DiscontinuedLabel);
