import { GET_STORE_SETTINGS_SUCCESS } from "./storeSettingsActions";

const initialState = {};

const storeSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_SETTINGS_SUCCESS:
      return { ...action?.settings };
    default:
      return state;
  }
};

export default storeSettingsReducer;
