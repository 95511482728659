import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  LOAD_HOTEL_ROOM_TYPES,
  setHotelRoomTypesRequest,
  setHotelRoomTypesSuccess,
  setHotelRoomTypesFailure,
} from "@/dux/hotelRoomSelection/hotelRoomSelectionActions";

import { getStoreNumber } from "core/selectors/persistentSelectors";
import { getHotelRoomSuspensions } from "core/services/systemServicesBooking/hotelRoomMananagementEndPoints";

function* loadHotelRoomTypes() {
  try {
    yield put(setHotelRoomTypesRequest());
    const storeNumber = yield select(getStoreNumber);

    // room suspensions api
    const response = yield call(getHotelRoomSuspensions, {
      storeNumber,
    });

    yield put(setHotelRoomTypesSuccess(response.data.result));
  } catch (error) {
    yield put(setHotelRoomTypesFailure(error));
  }
}

function* watchRoomManagementActions() {
  yield takeEvery(LOAD_HOTEL_ROOM_TYPES, loadHotelRoomTypes);
}

export default function* hotelRoomSelectionSaga() {
  yield all([fork(watchRoomManagementActions)]);
}
