/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_DO_NOT_BOOK_SALON = "SET_DO_NOT_BOOK_SALON";
export const SET_DO_NOT_BOOK_HOTEL = "SET_DO_NOT_BOOK_HOTEL";
export const SET_DO_NOT_BOOK_ERROR = "SET_DO_NOT_BOOK_ERROR";


/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setDoNotBookSalon = ({ doNotBook, doNotBookReason }) => ({
  type: SET_DO_NOT_BOOK_SALON,
  doNotBookReason,
  doNotBook,
});

export const setDoNotBookHotel = ({ doNotBook, doNotBookReason, updateCustomerProfilePartial}) => ({
  type: SET_DO_NOT_BOOK_HOTEL,
  doNotBookReason,
  doNotBook,
  updateCustomerProfilePartial,
})

export const setDoNotBookError = ({ error }) => ({
  type: SET_DO_NOT_BOOK_ERROR,
  error
});