import petServiceItemsActionTypes from "../actionTypes/petServiceItemsActionTypes";

export const loadPetServiceItem = ({ petServiceItem }) => ({
  type: petServiceItemsActionTypes.LOAD_PET_SERVICE_ITEM,
  payload: petServiceItem
});

export const loadPetServiceItems = ({ petServiceItems }) => ({
  type: petServiceItemsActionTypes.LOAD_PET_SERVICE_ITEMS,
  payload: petServiceItems
});

export const deletePetServiceItems = ({ petServiceItems }) => ({
  type: petServiceItemsActionTypes.DELETE_PET_SERVICE_ITEMS,
  payload: petServiceItems
});

export const updatePetServiceItem = ({
  customerKey,
  itineraryId,
  engagementId,
  petServiceItemId,
  dispatchToCart,
  data,
}) => ({
  type: petServiceItemsActionTypes.UPDATE_PET_SERVICE_ITEM,
  customerKey,
  itineraryId,
  engagementId,
  petServiceItemId,
  dispatchToCart,
  data,
});

export const updatePetServiceItemRequest = () => ({
  type: petServiceItemsActionTypes.UPDATE_PET_SERVICE_ITEM_REQUEST
});

export const updatePetServiceItemSuccess = ({ itinerary }) => ({
  type: petServiceItemsActionTypes.UPDATE_PET_SERVICE_ITEM_SUCCESS,
  payload: itinerary
});

export const updatePetServiceItemFailure = ({ error }) => ({
  type: petServiceItemsActionTypes.UPDATE_PET_SERVICE_ITEM_FAILURE,
  error
});

export default {
  loadPetServiceItem,
  loadPetServiceItems,
  deletePetServiceItems,

  updatePetServiceItem,
  updatePetServiceItemRequest,
  updatePetServiceItemSuccess,
  updatePetServiceItemFailure
};
