import React from "react";
import { connect } from "react-redux";
import { Layout, Button, Text } from "@prism/psm-ui-components";
import { color } from "@/web/common/styles/theme";
import { getTrainingAttendeeById } from "../trainingClassSessions/selectors";
import { getPetFullName, getPet } from "@/core/selectors/entitiesSelector";
import getUnknownPetImage from "@/core/utils/assetUtils/unknownPetImage";
import { SelectCustomerTimeZoneRescheduleTrainingDropdown } from "./SelectCustomerTimeZoneRescheduleTrainingDropdown";
import ScheduleTrainingAppointmentDetails from "../scheduleTrainingAppointment/ScheduleTrainingAppointmentDetails";
import { resetSelectedTimeSlot } from "../scheduleTrainingAppointment/scheduleTrainingAppointmentActions";
import { getSelectedTimeSlot } from "../scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";
import { getRescheduleAttendeeId } from "./manageTrainingClassPageSelectors";
import { formatDayNameMonthDayYear } from "@/core/utils/dateUtils/formatDateTime";
import { getSelectedDate } from "../selectedDate/selectedDateSelector";

const RescheduleTrainingAppointmentSummaryComponent = ({
  componentId,
  classDateTime,
  classTitle,
  petImage,
  petName,
  onCancel,
  isTimeslotSelected,
}) => {
  const borderColor = isTimeslotSelected ? color.kalEl : color.ororoMunroe;
  return (
    <Layout.Stack id={componentId}>
      <SelectCustomerTimeZoneRescheduleTrainingDropdown />
      <Layout.Box
        id={componentId}
        padding="box-padding-5"
        style={{ border: `1px solid ${borderColor}` }}
      >
        <Layout.Stack space="stack-space-2">
          <Layout.Cluster childOverrides={{ justifyContent: "space-between", flexWrap: "nowrap" }}>
            <Layout.Box padding="box-padding-4">
              {/*Image and Name -------------------------------------------------------------*/}
              <Layout.Cluster space="cluster-space-5" childOverrides={{ flexWrap: "nowrap" }}>
                <img
                  id={`${componentId}__petImage`}
                  alt="petImage"
                  src={petImage}
                  style={{ width: "60px", height: "60px" }}
                />
                <Text bold id={`${componentId}__petName`}>
                  {petName}
                </Text>
              </Layout.Cluster>
            </Layout.Box>

            {/*Cancel button ----------------------------------------------------------------*/}
            <Button
              id={`${componentId}__cancelRescheduleTrainingAppointment`}
              isHidden={!isTimeslotSelected}
              onClick={onCancel}
              variant="link"
              style={{ fontFamily: "lato", color: color.errorRed }}
            >
              Cancel
            </Button>
          </Layout.Cluster>
          <Text id={`${componentId}__classTitle}`} size="text-size-lg">
            {classTitle}
          </Text>
          <Text size="text-size-lg">{classDateTime}</Text>
          <ScheduleTrainingAppointmentDetails />
        </Layout.Stack>
      </Layout.Box>
    </Layout.Stack>
  );
};

export const RescheduleTrainingAppointmentSummary = connect(
  (state) => {
    const attendeeId = getRescheduleAttendeeId(state);
    const { customerId: customerKey, petId } = getTrainingAttendeeById(state, { attendeeId });
    const pet = getPet(state, { petId });
    const petName = getPetFullName(state, { petId, customerKey });

    return {
      petName,
      classDateTime: formatDayNameMonthDayYear(getSelectedDate(state)),
      componentId: "RescheduleTrainingAppointmentSummary",
      classTitle: "Virtual Pet Training - Private Class",
      petImage: getUnknownPetImage(pet),
      isTimeslotSelected: getSelectedTimeSlot(state),
    };
  },
  (dispatch) => ({
    onCancel: () => {
      dispatch(resetSelectedTimeSlot());
    },
  }),
)(RescheduleTrainingAppointmentSummaryComponent);
