/**
 * Responsible for returning the getFeatureFlagsWindow Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getFeatureFlagWindowState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the featureFlagWindow object in the redux store
 *
 * @example createSelector([getFeatureFlagWindowState], featureFlagWindowState => featureFlagWindowState.position;
 */
export default function getFeatureFlagWindowState(state) {
  return state.featureFlagging.featureFlagWindow;
}
