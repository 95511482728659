import React from "react";
import { Layout, Text } from "@prism/psm-ui-components";

const SectionHeader = props => {
  const { title } = props;
  return (
    <Layout.Box>
      <Text
        align="left"
        bold="bold"
        color="text-color-inherit"
        fontFamily="text-family-montserrat"
        letterSpacing="text-tracking-normal"
        size="text-size-lg"
        as="h2"
      >
        {title}
      </Text>
    </Layout.Box>
  );
};

export default SectionHeader;
