import React from "react";
import styled from "styled-components";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
`;

export default function LightBulbIcon(props) {
  const { isHidden, componentID, handleClick, ispPersistent } = props;

  // Teh isPersistent will come in as a Boolean, however wee need it to be a string with a value of 1 or 0 to properly
  // display the opacity of the light icon to be lit.
  const opacityVal = ispPersistent ? "1" : "0";

  if (!isHidden) {
    return (
      <SectionWrapper id={componentID} onClick={handleClick}>
        <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
          <g>
            <title>background</title>
            <rect fill="none" id="canvas_background" height="27" width="27" y="-1" x="-1" />
          </g>
          <g>
            <title>Layer 1</title>
            <g stroke="null" id="svg_1">
              <g stroke="null" id="XMLID_30_">
                <g stroke="null" id="svg_2">
                  <rect
                    stroke="null"
                    id="svg_3"
                    fill="#546A79"
                    height="2.5625"
                    width="3.5875"
                    y="20.57188"
                    x="10.70625"
                  />
                  <polygon
                    stroke="null"
                    id="svg_4"
                    fill="#AFB6BB"
                    points="15.318747520446777,18.521876335144043 15.318747520446777,20.571873664855957 14.29375171661377,20.571873664855957 10.706247329711914,20.571873664855957 9.681252479553223,20.571873664855957 9.681252479553223,18.521876335144043 "
                  />
                  <rect
                    stroke="null"
                    id="svg_5"
                    fill="#E7ECED"
                    height="1.79375"
                    width="5.6375"
                    y="16.72813"
                    x="9.68125"
                  />
                  <path
                    stroke="null"
                    id="svg_6"
                    fill="#FFD248"
                    fillOpacity={opacityVal}
                    d="m19.675,8.01562c0,2.96215 -1.79375,5.50435 -4.35625,6.60095l0,2.11155l-5.6375,0l0,-2.11155c-2.5625,-1.09655 -4.35625,-3.6388 -4.35625,-6.60095c0,-3.96163 3.21312,-7.175 7.175,-7.175s7.175,3.21338 7.175,7.175z"
                  />
                  <path
                    stroke="null"
                    id="svg_7"
                    fill="#FECA47"
                    fillOpacity={opacityVal}
                    d="m17.36875,8.01562c0,2.68888 -2.17961,4.86875 -4.86875,4.86875c-2.68863,0 -4.86875,-2.17987 -4.86875,-4.86875s2.18012,-4.86875 4.86875,-4.86875c2.68914,0 4.86875,2.17987 4.86875,4.86875z"
                  />
                </g>
                <g stroke="null" id="svg_8">
                  <path
                    stroke="null"
                    id="svg_9"
                    fill="#231F20"
                    d="m12.5,0.32812c-4.23889,0 -7.6875,3.44861 -7.6875,7.6875c0,2.96866 1.69878,5.65277 4.35625,6.93095l0,5.6253c0,0.28305 0.22945,0.5125 0.5125,0.5125l0.5125,0l0,2.05c0,0.28305 0.22945,0.5125 0.5125,0.5125l1.28125,0l0,1.025l1.025,0l0,-1.025l1.28125,0c0.283,0 0.5125,-0.22945 0.5125,-0.5125l0,-2.05l0.5125,0c0.283,0 0.5125,-0.22945 0.5125,-0.5125l0,-5.6253c2.65747,-1.27812 4.35625,-3.96229 4.35625,-6.93095c0,-4.23889 -3.44861,-7.6875 -7.6875,-7.6875zm-2.30625,16.9125l4.6125,0l0,0.76875l-4.6125,0l0,-0.76875zm3.5875,5.38125l-2.5625,0l0,-1.5375l2.5625,0l0,1.5375zm1.025,-2.5625l-0.5125,0l-3.5875,0l-0.5125,0l0,-1.025l4.6125,0l0,1.025zm0.31083,-5.91399c-0.18855,0.08067 -0.31083,0.26604 -0.31083,0.47114l0,1.5991l-4.6125,0l0,-1.59905c0,-0.2051 -0.12233,-0.39047 -0.31083,-0.47114c-2.45754,-1.05165 -4.04542,-3.45774 -4.04542,-6.12981c0,-3.6737 2.98875,-6.6625 6.6625,-6.6625s6.6625,2.9888 6.6625,6.6625c0,2.67207 -1.58788,5.07816 -4.04542,6.12976z"
                  />
                  <path
                    stroke="null"
                    id="svg_10"
                    fill="#231F20"
                    d="m14.29375,7.75937l-2.90167,0l2.30697,-3.56531l-0.86054,-0.55683l-2.81875,4.35625c-0.10199,0.15765 -0.10968,0.35844 -0.01994,0.52331c0.08974,0.16492 0.26245,0.26758 0.45018,0.26758l3.03615,0l-1.70611,3.62553l0.92742,0.43645l2.05,-4.35625c0.07472,-0.15877 0.06299,-0.34471 -0.03101,-0.49282c-0.09399,-0.14816 -0.25722,-0.2379 -0.4327,-0.2379z"
                  />
                  <rect
                    stroke="null"
                    id="svg_11"
                    fill="#231F20"
                    height="1.025"
                    width="1.025"
                    y="7.75937"
                    x="7.375"
                  />
                  <rect
                    stroke="null"
                    id="svg_12"
                    fill="#231F20"
                    height="1.025"
                    width="1.025"
                    y="7.75937"
                    x="16.6"
                  />
                </g>
              </g>
            </g>
            <g id="svg_13" />
            <g id="svg_14" />
            <g id="svg_15" />
            <g id="svg_16" />
            <g id="svg_17" />
            <g id="svg_18" />
            <g id="svg_19" />
            <g id="svg_20" />
            <g id="svg_21" />
            <g id="svg_22" />
            <g id="svg_23" />
            <g id="svg_24" />
            <g id="svg_25" />
            <g id="svg_26" />
            <g id="svg_27" />
          </g>
        </svg>
      </SectionWrapper>
    );
  }

  return null;
}
