import React from "react";
import styled from "styled-components";
import { uniq, map } from "lodash/fp";
import AppointmentCard from "./AppointmentCardContainer";
import LoadingWrapper from "../../../LoadingWrapper";
import CommonButton from "../../../commonButton";
import { mapWithKey } from "../../../../../core/utils";
import AssociateSchedulingOverrideViolationContainer from "../../../../associateScheduling/AssociateSchedulingOverrideViolationContainer";
import AssociateSchedulingOverrideMessageContainer from "../../../../associateScheduling/AssociateSchedulingOverrideMessageContainer";
import AssociateSchedulingConfirmationContainer from "../../../../associateScheduling/AssociateSchedulingConfirmationContainer";

export default class RuleViolation extends React.Component {
  componentDidMount() {
    const { ruleViolations, loadPets } = this.props;
    const petIds = uniq(map(ruleViolation => ruleViolation.PetId, ruleViolations));

    loadPets({ petIds });
  }

  componentWillUnmount() {
    const { clearRuleViolations, hideModal } = this.props;

    clearRuleViolations();
    hideModal();
  }

  render() {
    const { ruleViolations, isLoading, onClose, message } = this.props;

    return (
      <LoadingWrapper isLoading={isLoading}>
        <Container>
          <Header>Schedule change conflict</Header>
          <AssociateSchedulingOverrideViolationContainer />
          <AssociateSchedulingOverrideMessageContainer />

          <PetCardsContainer>
            {mapWithKey(
              (ruleViolation, i) => (
                <AppointmentCard key={i} ruleViolation={ruleViolation} />
              ),
              ruleViolations
            )}
          </PetCardsContainer>

          <CancelButtonContainer>
            <CommonButton margin inverted label="Cancel" onClick={onClose} />
            <AssociateSchedulingConfirmationContainer />
          </CancelButtonContainer>
        </Container>
      </LoadingWrapper>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;
  max-width: 800px;
`;

const Header = styled.div`
  font-weight: bold;
  margin-bottom: 30px;
`;

const Message = styled.div`
  width: 70%;
  margin-bottom: 30px;
`;

const PetCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 40px;
  max-height: 250px;
  overflow: auto;
`;

const CancelButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
