import React from "react";

import { modalTypes } from "../../../core/constants/quickPin";

import CommonModal from "../../common/modals/commonModal";
import AuthorizationRequiredModal from "./authorizationRequired/AuthorizationRequiredModalContainer";
import LockUserModal from "./lockUser/LockUserModalContainer";
import StartShiftModalContainer from "./startShift/StartShiftModalContainer";

const QuickPinModals = props => (
  <CommonModal
    onClose={() => {
      props.onClose();
    }}
  >
    {selectModalContent(props)}
  </CommonModal>
);

function selectModalContent({ modalType }) {
  switch (modalType) {
    case modalTypes.AUTHORIZATION_REQUIRED:
      return <AuthorizationRequiredModal />;
    case modalTypes.LOCK_USER:
      return <LockUserModal />;
    case modalTypes.START_SHIFT:
      return <StartShiftModalContainer />;
    default:
      return null;
  }
}

export default QuickPinModals;
