import { connect } from "react-redux";
import {
  getEnhancedServicesByBundleConfigId, getSelectedEnhancedService,
} from "../bulkPackageOfferingsSelectors";
import { EnhancedServicesListComponent } from "@/dux/enhancedServicesList/EnhancedServicesList";
import { getIsEnhancedServicesBulkPackagesHidden } from "@/dux/enhancedServicesList/EnhancedServicesListSelectors";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import { setSelectedEnhancedService } from './bulkPackageEnhancedServiceActions'
import { getSelectedAddOnIds } from "@/dux/_selectors/addOnsSelectors";

const mapStateToProps = state => {
  const petId = getSelectedPet(state);
  const enhancedServices = getEnhancedServicesByBundleConfigId(state);

  return {
    isHidden: enhancedServices?.length === 0 || getIsEnhancedServicesBulkPackagesHidden(state),
    componentId: "bulk-package-offerings-enhanced-services",
    title: "Enhanced Services",
    addons: enhancedServices,
    petId,
    selectedEnhancedService: getSelectedEnhancedService(state),
    selectedEnhancedServiceID: getSelectedAddOnIds(state, { petId })
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSelectionChange: (enhancedService) => dispatch(setSelectedEnhancedService(enhancedService?.addOnId || null)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedServicesListComponent);
