import { get } from "lodash/fp";
import { history } from "@/dux/utils/browser/browserHistory";
import cookieExist from "../cookies/CheckForCookies";
import displayFeatureFlagIconConstants from "../../../web/displayFeatureFlagIcon/displayFeatureFlagIconConstants";
import { enableDisableWorkflowFeatureFlag } from "../../../web/enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagActions";
import * as workflowFeatureTypes from "../../../web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";

function dispatchWorkFlowFFActions(dispatch, checkedValue) {
  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.BGM,
      checked: checkedValue,
    }),
  );
  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.HOTEL,
      checked: checkedValue,
    }),
  );
  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.FTCO,
      checked: checkedValue,
    }),
  );
  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.MANUAL_APPLY,
      checked: checkedValue,
    }),
  );
  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.TRAINING,
      checked: checkedValue,
    }),
  );
  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.ENHANCED_SERVICES,
      checked: checkedValue,
    }),
  );
  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.ENHANCED_SERVICES_BULK_PACKAGES,
      checked: checkedValue,
    }),
  );

  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.CONTROLLED_OVERBOOK,
      checked: checkedValue,
    }),
  );

  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.STORE_EVENT_NOTIFICATIONS,
      checked: checkedValue,
    }),
  );

  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.SEARCH_FIELD_MAIN_V2,
      checked: checkedValue,
    }),
  );

  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.STAND_ALONE_BULK_PACKAGES,
      checked: checkedValue,
    }),
  );

  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.HOTEL_ADMIN,
      checked: checkedValue,
    }),
  );
  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.REPORTS,
      checked: checkedValue,
    }),
  );
  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.DISCONTINUED_FOODS,
      checked: checkedValue,
    }),
  );
  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.DISCONTINUED_MEDS,
      checked: checkedValue,
    }),
  );
  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.DDC_PACKAGES,
      checked: checkedValue,
    }),
  );
  dispatch(
    enableDisableWorkflowFeatureFlag({
      featureFlagId: workflowFeatureTypes.SALON_CART_MIGRATION,
      checked: checkedValue,
    }),
  );
}

export default function setAllFeatureFlags(dispatch) {
  const urlParams = get("location.search", history);
  const regExPersistTrue = new RegExp(
    displayFeatureFlagIconConstants.PERSIST_FEATURE_FLAG_PARAM,
    "g",
  );
  const regExPersistFalse = new RegExp(
    displayFeatureFlagIconConstants.REMOVE_PERSIST_FEATURE_FLAG_PARAM,
    "g",
  );
  const ffTrueIsinURL = !!urlParams.match(regExPersistTrue);
  const ffFalseIsinURL = !!urlParams.match(regExPersistFalse);
  const enableFF = ffTrueIsinURL || cookieExist(displayFeatureFlagIconConstants.PERSIST_COOKIE_KEY);

  if (!ffFalseIsinURL) {
    if (enableFF) {
      dispatchWorkFlowFFActions(dispatch, true);

      return true;
    }
  }
}
