import { connect } from "react-redux";

import { getSelectedPet } from "../../core/selectors/bookingUISelectors";

import { getEligibilityResults } from "dux/eligibility/selectors/eligibilitySelectors";
import {
  canNotBookDDCReasonCodes,
  canNotBookReasonCodes,
} from "dux/eligibility/constants/eligibilityConstants";
import { getSystemBookingFlow } from "../setSystemType/selectors/setSystemTypeSelectors";
import ServicesComponent from "../features/services/Services";
import { getCurrentCustomerKey } from "../../core/selectors/persistent/customer/customerSelectors";
import withInBookingOrModify from "../common/hoc/withInBookingOrModify";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = state => {
  const selectedPet = getSelectedPet(state);
  const systemBookingFlow = getSystemBookingFlow(state);
  const canNotBookReasons =
    systemBookingFlow === systemName.SALON ? canNotBookReasonCodes : canNotBookDDCReasonCodes;
  return {
    selectedPet,
    eligibility: getEligibilityResults(state),
    customerKey: getCurrentCustomerKey(state),
    canNotBookReasons,
    hotelKey: "pendingAppointmentHotelKey",
    salonKey: selectedPet,
    systemBookingFlow,
    componentID: "servicesComponent",
  };
};

export default withInBookingOrModify(connect(mapStateToProps)(ServicesComponent));
