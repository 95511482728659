import React from "react";
import { connect } from "react-redux";

import { Text } from "@prism/psm-ui-components";
import {
  selectHotelItineraryIsDifferentStore,
  selectIsReservationDisabledDueToDifferentStore,
} from "@/dux/hotelItinerary/hotelItinerarySelectors";

export const DifferentStoreMessageComponent = ({ isHidden, differentStoreNumber }) => {
  if (isHidden) return null;

  return (
    <Text bold color="text-color-red">
      This appointment is scheduled for store {differentStoreNumber}
    </Text>
  );
};

export const DifferentStoreMessage = connect(state => {
  // if the reservation is disabled due to a different store, we want to show the message
  const isHidden = !selectIsReservationDisabledDueToDifferentStore(state);
  const differentStoreNumber = selectHotelItineraryIsDifferentStore(state);

  return {
    isHidden,
    differentStoreNumber,
  };
})(DifferentStoreMessageComponent);
