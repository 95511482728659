import React from "react";
import styled from "styled-components";
import { color } from "./styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import NonEditable from "./NonEditable";

const StyledTextArea = styled.textarea`
    border: ${props => `0.0625rem solid ${props.error ? color.errorRed : color.borderGrey}`};
    font-size: ${fontSizes.regular};
    font-family: Lato;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    height: ${props => (props.height ? props.height : "auto")};
    padding-left: 2px;
    color: ${props => {
      if (props.error) {
        return color.errorRed;
      } else if (props.isLink) {
        return color.kalEl;
      }
      return color.darkGrey;
    }}
    ${props =>
      props.isLink &&
      `
        cursor: pointer;
    `}
    &:focus {
        outline: ${props => (props.error ? color.errorRed : color.kalEl)};
    }
    ${props =>
      props.isStatic &&
      `
        cursor: default;
        &:focus {
            color: inherit;
            border-bottom-color: ${color.borderGrey};
        }
    `}
`;

const InputContainer = styled.div`
  position: relative;
  margin-top: 10px;
`;

const TextArea = props =>
  props.nonEditable ? (
    <NonEditable {...props}>{props.value}</NonEditable>
  ) : (
    <InputContainer>
      <StyledTextArea {...props} />
    </InputContainer>
  );

export default TextArea;
