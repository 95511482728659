import React from "react";
import Select from "web/common/Select";

/** ----------------------------------------------------------------------- **\
    STYLES
\** ----------------------------------------------------------------------- * */
const customSelectStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: "white",
    width: "200px",
    borderRadius: 0,
    cursor: "context-menu"
  }),

  valueContainer: styles => ({
    ...styles,
    width: "30px",
    overflow: "hidden"
  }),

  option: (base, { isDisabled, isFocused, isSelected }) => ({
    ...base,
    backgroundColor: "#fff",
    color: isSelected ? "#007db4" : "#666",
    padding: 10
  }),

  singleValue: (base, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...base, opacity, transition };
  }
};

/** ----------------------------------------------------------------------- **\
    COMPONENT
\** ----------------------------------------------------------------------- * */
export default function SearchSelect(props) {
  return (
    <Select
      classNamePrefix="search-bar"
      value={props.value}
      defaultValue={props.defaultValue}
      label="Single select"
      styles={customSelectStyles}
      options={props.options}
      onChange={props.onSelectionChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    />
  );
}
