import React from "react";
import { connect } from "react-redux";
import { HotelItineraryHistoryPetCard } from "../itineraryHistoryListPetCard/itineraryHistoryListPetCard";
import { APPOINTMENT_STATUS } from "@/core/constants";
import { routePaths } from "@/core/constants/routePaths";

// Components
import { Layout, Text } from "@prism/psm-ui-components";

// MAIN COMPONENT ----------------------------------------------------------------------------------------------------
const ItineraryHistoryListSection = props => {
  const { componentID, title, reservations, getAppointmentURI, diComp, compName } = props;

  if (reservations && reservations.length) {
    const PetCard = diComp[compName];

    return (
      <Layout.Box id={componentID}>
        <Layout.Stack space="stack-space-4">
          <Layout.Box>
            <Text bold size="text-size-xl">
              {title}
            </Text>
          </Layout.Box>

          {reservations.map((res, index) => (
            <PetCard
              key={`${res.itineraryId}-${index}`}
              reservation={res}
              getAppointmentURI={getAppointmentURI}
            />
          ))}
        </Layout.Stack>
      </Layout.Box>
    );
  }

  return null;
};

// ITINERARY HISTORY CURRENT RESERVATIONS CONTAINER ------------------------------------------------------------------
export const ItineraryHistoryCurrentReservations = connect((state, { reservations }) => ({
  componentID: "ItineraryHistoryCurrentReservations",
  title: "Current Reservations",
  reservations,
  compName: "HOTEL",
  diComp: { HOTEL: HotelItineraryHistoryPetCard },
  getAppointmentURI: status =>
    status === APPOINTMENT_STATUS.CHECKED_IN ? routePaths.CHECK_OUT : routePaths.CHECK_IN,
}))(ItineraryHistoryListSection);

// ITINERARY HISTORY FUTURE RESERVATIONS CONTAINER -------------------------------------------------------------------
export const ItineraryHistoryFutureReservations = connect((state, { reservations }) => ({
  componentID: "ItineraryHistoryFutureReservations",
  title: "Future Reservations",
  reservations,
  compName: "HOTEL",
  diComp: { HOTEL: HotelItineraryHistoryPetCard },
  getAppointmentURI: () => routePaths.CHECK_IN,
}))(ItineraryHistoryListSection);

// ITINERARY HISTORY PAST RESERVATIONS CONTAINER ---------------------------------------------------------------------
export const ItineraryHistoryPastReservations = connect((state, { reservations }) => ({
  componentID: "ItineraryHistoryPastReservations",
  title: "Past Reservations",
  reservations,
  compName: "HOTEL",
  diComp: { HOTEL: HotelItineraryHistoryPetCard },
  getAppointmentURI: () => routePaths.CHECK_OUT,
}))(ItineraryHistoryListSection);
