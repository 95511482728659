import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import {
  TextPassage,
  Button,
  TextInput,
  SelectField,
  Heading,
  TextField,
} from "@petsmart-ui/sparky";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import {
  getPetHotelEngagements,
  selectAddonsByGroupingId,
  selectFeedingChargeAddons,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { getHotelPriceAdjustmentReasons } from "dux/hotelPriceAdjustmentReasons/hotelPriceAdjustmentSelectors";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutCenter } from "@/layout/center/Center";
import { LayoutGrid } from "@/layout/grid/Grid";
import { hideCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import { getHotelItinerary } from "dux/hotelItinerary/hotelItinerarySelectors";
import { getStoreTimeZone } from "core/selectors/entitiesSelector";
import LoadingWrapper from "web/common/LoadingWrapper";
import { createLoadingSelector } from "core/selectors/utils";
import { MAX_OVERRIDE_MODAL_DESCRIPTION_LENGTH } from "core/constants/modalConstants";
import { selectCurrentAddonGroupingId } from "./hotelPriceOverrideSelectors";
import {
  PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE,
  patchHotelItineraryPriceOverride,
} from "../reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import {
  formatEngagementServicesForPatch,
  formatAddonsForModal,
  formatServicesForModal,
  formatEngagementAddonsForPricePatch,
  validateModalFields,
  applyModalDataToPriceUpdates,
} from "./hotelPriceOverrideUtils";
import { formatMoney } from "../_utils/moneyUtil";

/**
 * React view component for overriding price by day in hotel check in
 * @summary hotel check-in page
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelPriceOverrideModalComponent
 * @param {Object} props - props passed into the view component
 * @param {string} props.componentId - component id
 * @param {string} props.title - Title to display at top of view
 * @param {Object[]} props.modalData - Array of objects with the following format
 *      { id, formattedDate, finalPrice, reasonCode, reasonDescription }
 * @param {boolean} props.isSaveDisabled - flag to disable the save button
 * @param {Object[]} props.hotelPriceAdjustmentReasons - array of options for the reason dropdown
 * @param {function} props.validateModalFields - returns error message
 * @returns {JSX.Element}
 * @example <HotelPriceOverrideModalComponent />
 */
export const HotelPriceOverrideModalComponent = props => {
  const {
    componentId,
    title,
    modalData,
    isSaveDisabled,
    hotelPriceAdjustmentReasons,
    defaultReason,
    isLoading,
  } = props;
  const { onClose, onSave } = props;

  const [updatedPriceInfo, updatePriceInfo] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [saveButtonErrorMessage, setsaveButtonErrorMessage] = useState("");

  const hasUpdatedPrice = id => updatedPriceInfo[id] && updatedPriceInfo[id]?.updatedPrice >= 0;

  // Reset the form state when the modal closes
  useEffect(() => {
    return () => {
      updatePriceInfo({});
    };
  }, []);

  // Close the modal when changes have been made and it is done loading
  useEffect(() => {
    const hasBeenUpdated = !!Object.keys(updatedPriceInfo)?.length;
    if (hasBeenUpdated && !isLoading) onClose();
  }, [isLoading]);

  const onChange = (id, updatedValue) => {
    setIsDirty(true);
    setsaveButtonErrorMessage("");
    updatePriceInfo({
      ...updatedPriceInfo,
      [id]: { ...updatedPriceInfo[id], ...updatedValue },
    });
  };

  const validateAndSave = () => {
    const updatedPricing = applyModalDataToPriceUpdates(updatedPriceInfo, modalData);
    const errorMessage = validateModalFields(updatedPricing);

    if (errorMessage) {
      setsaveButtonErrorMessage(errorMessage);
      return;
    }

    // assumes no validation errors, so go ahead and save
    onSave(updatedPricing);
  };

  return (
    <LayoutBox id={componentId}>
      <LoadingWrapper isLoading={isLoading}>
        <LayoutStack>
          {/* MODAL TITLE */}
          <LayoutCenter>
            <Heading tagName="h4">{title}</Heading>
          </LayoutCenter>

          <LayoutBox>
            <LayoutGrid
              style={{
                gridTemplateColumns: "2fr 1fr 70px 2fr 5fr",
                padding: "1rem 0",
              }}
            >
              {/* COLUMN TITLES */}
              <TextPassage size="lg">Date of Service</TextPassage>
              <TextPassage size="lg" align="center">
                Original
              </TextPassage>
              <TextPassage size="lg">Updated</TextPassage>
              <TextPassage size="lg">Reason</TextPassage>
              <TextPassage size="lg">Reason Description</TextPassage>

              {/* ENGAGEMENT DATE ROWS */}
              {modalData?.map(
                ({ id, formattedDate, finalPrice, reasonCode, reasonDescription }) => (
                  <React.Fragment key={id}>
                    <TextPassage size="lg" style={{ padding: ".8em 0" }}>
                      {formattedDate}
                    </TextPassage>
                    <TextPassage size="lg" align="center" style={{ padding: ".8em 0" }}>
                      {formatMoney(finalPrice)}
                    </TextPassage>
                    <TextField
                      id={`${componentId}__newValue--${id}`}
                      label="$"
                      floating="false"
                      name="newValue"
                      type="number"
                      min="0"
                      isError={false}
                      value={updatedPriceInfo[id]?.updatedPrice ?? ""}
                      onChange={({ target }) =>
                        onChange(id, {
                          updatedPrice: Math.abs(target.value),
                          reasonCode: defaultReason,
                          reasonDescription: "",
                        })
                      }
                    />
                    <SelectField
                      id={`${componentId}__reasonCodes--${id}`}
                      label=""
                      items={hotelPriceAdjustmentReasons}
                      value={updatedPriceInfo[id]?.reasonCode ?? reasonCode ?? defaultReason}
                      onChange={({ target }) => onChange(id, { reasonCode: target.value })}
                      disabled={!hasUpdatedPrice(id)}
                    />
                    <TextInput
                      id={`${componentId}__reasonDescription--${id}`}
                      type="text"
                      label=""
                      name="reasonDescription"
                      maxLength={MAX_OVERRIDE_MODAL_DESCRIPTION_LENGTH}
                      value={updatedPriceInfo[id]?.reasonDescription ?? reasonDescription}
                      onChange={({ target }) => onChange(id, { reasonDescription: target.value })}
                      disabled={!hasUpdatedPrice(id)}
                      placeholder="min 10 max 255 characters..."
                    />
                  </React.Fragment>
                ),
              )}
            </LayoutGrid>
          </LayoutBox>

          {/* BUTTONS */}
          <LayoutBox>
            {/* ERROR MESSAGE */}
            <TextPassage style={{ color: "red", textAlign: "end" }} capLinelength={false}>
              {saveButtonErrorMessage}
            </TextPassage>
            <LayoutCluster>
              {/* GO BACK BUTTON */}
              <LayoutBox style={{ marginLeft: "auto" }}>
                <Button variant="link" text="Go Back" onClick={onClose} />
              </LayoutBox>

              {/* SAVE BUTTON */}
              <LayoutBox>
                <Button
                  disabled={isSaveDisabled || !isDirty}
                  variant="primary"
                  text="Save"
                  onClick={() => validateAndSave()}
                />
              </LayoutBox>
            </LayoutCluster>
          </LayoutBox>
        </LayoutStack>
      </LoadingWrapper>
    </LayoutBox>
  );
};

export const reasonPlaceholder = { label: "Select...", value: "reason-placeholder" };

/**
 * Redux Connect function for the HotelPriceOverrideModalComponent
 * @see {@link HotelPriceOverrideModalComponent}
 * @summary Hotel check-in & check-out
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelPriceOverrideModal
 * @returns {JSX.Element|null}
 * @example <HotelPriceOverrideModal />
 */
export const HotelPriceOverrideModal = connect(
  state => {
    const customerId = getCurrentCustomerKey(state);
    const itinerary = getHotelItinerary(state);
    const itineraryId = itinerary?.itineraryId;
    const petId = getCurrentPet(state);
    const engagements = getPetHotelEngagements(state, { petId });
    const productName = engagements && engagements[0]?.engagementType;
    const hotelPriceAdjustmentReasons = getHotelPriceAdjustmentReasons(state);
    const isLoading = createLoadingSelector([PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE])(state);
    const timeZone = getStoreTimeZone(state, { storeNumber: itinerary?.storeNumber });
    const modalData = formatServicesForModal(engagements, timeZone);

    return {
      componentId: "HotelPriceOverrideModal",
      title: `Price Adjustment - ${productName}`,
      isSaveDisabled: false,
      modalData,
      hotelPriceAdjustmentReasons: [reasonPlaceholder, ...hotelPriceAdjustmentReasons],
      defaultReason: reasonPlaceholder.value,
      isLoading,
      validateModalFields,

      // For merge props
      petId,
      customerId,
      itineraryId,
      engagements,
    };
  },
  dispatch => {
    return {
      onClose: () => dispatch(hideCheckInOutModal()),
      priceOverridePatch: ({ itineraryId, customerId, data }) => {
        dispatch(patchHotelItineraryPriceOverride({ itineraryId, customerId, data }));
      },
    };
  },
  (stateProps, dispatchProps) => {
    const {
      componentId,
      title,
      isSaveDisabled,
      modalData,
      hotelPriceAdjustmentReasons,
      defaultReason,
      petId,
      itineraryId,
      customerId,
      engagements,
      isLoading,
    } = stateProps;
    const { onClose, priceOverridePatch } = dispatchProps;

    return {
      componentId,
      title,
      isSaveDisabled,
      modalData,
      hotelPriceAdjustmentReasons,
      defaultReason,
      isLoading,
      validateModalFields,
      onClose,
      onSave: updatedPriceInfo => {
        const updatedEngagements = formatEngagementServicesForPatch(engagements, updatedPriceInfo);
        const data = {
          itineraryId,
          pets: [{ petKey: petId, engagements: updatedEngagements }],
        };

        priceOverridePatch({ itineraryId, customerId, data });
      },
    };
  },
)(HotelPriceOverrideModalComponent);

/**
 * Redux Connect function for the HotelPriceOverrideModalComponent
 * @see {@link HotelPriceOverrideModalComponent}
 * @summary Hotel check-in & check-out
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name CheckInOutFeedingPriceOverrideModal
 * @returns {JSX.Element|null}
 * @example <CheckInOutFeedingPriceOverrideModal />
 */
export const CheckInOutFeedingPriceOverrideModal = connect(
  state => {
    const petId = getCurrentPet(state);
    const itinerary = getHotelItinerary(state);
    const addons = selectFeedingChargeAddons(state, { petId });
    const hotelPriceAdjustmentReasons = getHotelPriceAdjustmentReasons(state);
    const timeZone = getStoreTimeZone(state, { storeNumber: itinerary?.storeNumber });
    const isLoading = createLoadingSelector([PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE])(state);
    return {
      componentId: "CheckInOutFeedingPriceOverrideModal",
      title: `Price Adjustment`,
      addons,
      modalData: formatAddonsForModal(addons, timeZone),
      hotelPriceAdjustmentReasons: [reasonPlaceholder, ...hotelPriceAdjustmentReasons],
      defaultReason: reasonPlaceholder.value,
      itineraryId: itinerary?.itineraryId,
      petId,
      isLoading,
      validateModalFields,
      isSaveDisabled: isLoading,
      customerId: getCurrentCustomerKey(state),
    };
  },
  dispatch => {
    return {
      priceOverridePatch: ({ itineraryId, customerId, data }) => {
        dispatch(patchHotelItineraryPriceOverride({ itineraryId, customerId, data }));
      },
      closeModal: () => dispatch(hideCheckInOutModal()),
    };
  },
  (stateProps, dispatchProps) => {
    // Component props
    const {
      componentId,
      title,
      modalData,
      hotelPriceAdjustmentReasons,
      isLoading,
      isSaveDisabled,
      customerId,
      defaultReason,
    } = stateProps;
    // Merge props
    const { addons, itineraryId, petId } = stateProps;
    const { priceOverridePatch, closeModal } = dispatchProps;

    return {
      componentId,
      title,
      modalData,
      hotelPriceAdjustmentReasons,
      isLoading,
      validateModalFields,
      isSaveDisabled,
      defaultReason,
      onClose: () => closeModal(),
      onSave: updatedPriceInfo => {
        const engagements = formatEngagementAddonsForPricePatch({ addons, updatedPriceInfo });
        const data = {
          itineraryId,
          pets: [{ petKey: petId, engagements }],
        };
        priceOverridePatch({ itineraryId, customerId, data });
      },
    };
  },
)(HotelPriceOverrideModalComponent);

/**
 * Redux Connect function for the HotelPriceOverrideModalComponent
 * @see {@link HotelPriceOverrideModalComponent}
 * @summary Hotel check-in & check-out
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name CheckInOutAddonPriceOverrideModal
 * @returns {JSX.Element|null}
 * @example <CheckInOutAddonPriceOverrideModal />
 */
export const CheckInOutAddonPriceOverrideModal = connect(
  state => {
    const petId = getCurrentPet(state);
    const itinerary = getHotelItinerary(state);
    const groupingId = selectCurrentAddonGroupingId(state);
    const addons = selectAddonsByGroupingId(groupingId)(state, { petId });
    const productName = addons[0]?.addOnName;
    const hotelPriceAdjustmentReasons = getHotelPriceAdjustmentReasons(state);
    const timeZone = getStoreTimeZone(state, { storeNumber: itinerary?.storeNumber });
    const isLoading = createLoadingSelector([PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE])(state);

    return {
      componentId: "CheckInOutAddonPriceOverrideModal",
      title: `Price Adjustment - ${productName}`,
      addons,
      modalData: formatAddonsForModal(addons, timeZone),
      hotelPriceAdjustmentReasons: [reasonPlaceholder, ...hotelPriceAdjustmentReasons],
      defaultReason: reasonPlaceholder.value,
      petId,
      itineraryId: itinerary?.itineraryId,
      isLoading,
      validateModalFields,
      isSaveDisabled: isLoading,
      customerId: getCurrentCustomerKey(state),
    };
  },
  dispatch => {
    return {
      priceOverridePatch: ({ itineraryId, customerId, data }) => {
        dispatch(patchHotelItineraryPriceOverride({ itineraryId, customerId, data }));
      },
      closeModal: () => dispatch(hideCheckInOutModal()),
    };
  },
  (stateProps, dispatchProps) => {
    // Component props
    const {
      componentId,
      title,
      modalData,
      hotelPriceAdjustmentReasons,
      isLoading,
      isSaveDisabled,
      customerId,
      defaultReason,
    } = stateProps;
    // Merge props
    const { addons, itineraryId, petId } = stateProps;
    const { priceOverridePatch, closeModal } = dispatchProps;

    return {
      componentId,
      title,
      modalData,
      hotelPriceAdjustmentReasons,
      isLoading,
      validateModalFields,
      defaultReason,
      isSaveDisabled,
      onClose: () => closeModal(),
      onSave: updatedPriceInfo => {
        const engagements = formatEngagementAddonsForPricePatch({ addons, updatedPriceInfo });
        const data = {
          itineraryId,
          pets: [{ petKey: petId, engagements }],
        };

        priceOverridePatch({ itineraryId, customerId, data });
      },
    };
  },
)(HotelPriceOverrideModalComponent);
