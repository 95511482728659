// React Redux
import React, { useEffect } from "react";
import { connect } from "react-redux";

// Layout
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";

// Selectors
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getStoreNumber } from "core/selectors/persistentSelectors";

import { createLoadingSelector } from "core/selectors/utils";
// Components or Containers
import ScheduleTrainingColumnsContainer from "dux/scheduleTrainingPage/ScheduleTrainingColumnsContainer";
import TrainingHeaderContainer from "@/dux/_components/trainingHeader/TrainingHeaderContainer";

// Actions
import customerActionTypes from "core/actionTypes/customersActionTypes";
import {
  GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER, getAvailableBundlesForAllPetsByCustomer,
} from "dux/bgm/availableBundlesByPet/availableBundlesByPetActions";
import {
  GET_TRAINING_CLASS_AVAILABILITY,
  getTrainingClassAvailabilityIfActiveBundleIsAvailable,
} from "dux/trainingClassAvailability/trainingClassAvailabilityActions";
import {
  FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING,
  POST_TRAINING_CLASS_ITINERARY,
  PUT_TRAINING_CLASS_ITINERARY,
} from "dux/trainingClassItineraries/trainingClassItinerariesActions";
import { resetScheduleTrainingPage } from "dux/scheduleTrainingPage/scheduleTrainingPageActions";
import { loadCustomer } from "core/actionCreators/customersActionCreators";
import { getTrainingAssociates } from "dux/trainingAssociates/trainingAssociatesActions";

// Loading
import LoadingWrapper from "@/web/common/LoadingWrapper";


export const ScheduleTrainingPageComponent =  (props) => {

  // Handlers
  const { getCustomer, getAvailableBundlesForAllPetsByCustomer, loadTrainingAssociates, resetPage} = props;

  // View props
  const { isHidden, componentId, isLoading } = props;

  // Mount
  useEffect( () => {
    getCustomer();
    getAvailableBundlesForAllPetsByCustomer();
    loadTrainingAssociates();
  }, [] );

  // Unmount
  useEffect(() => {
    // unmount through closure
    return () => {
      resetPage();
    };
  },[]);

  if (!isHidden) {
    return (
      <LoadingWrapper displayContainer="block" isLoading={isLoading}>
        <LayoutBox id={componentId} padding="space-0">
          <LayoutStack>
            <TrainingHeaderContainer />
            <ScheduleTrainingColumnsContainer />
          </LayoutStack>
        </LayoutBox>
      </LoadingWrapper>
    );
  }

  return null;
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const ScheduleTrainingPage = connect(
  (state) => {
    const customerKey = getCurrentCustomerKey(state);

    return {
      customerKey,
      componentId: "ScheduleTrainingPage",
      isHidden: false,
      isLoading: createLoadingSelector([
        customerActionTypes.LOAD_CUSTOMER,
        GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER,
        GET_TRAINING_CLASS_AVAILABILITY,
        POST_TRAINING_CLASS_ITINERARY,
        PUT_TRAINING_CLASS_ITINERARY,
        FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING,
      ])(state),
      storeNumber: getStoreNumber(state),
    };
  },

  dispatch => {
    return {
      resetPageHandler: () => {
        console.log("page reset");
        dispatch(resetScheduleTrainingPage());
      } ,
      dispatchGetAvailableBundlesForAllPetsByCustomer: ({ customerKey, onComplete }) =>
        dispatch(getAvailableBundlesForAllPetsByCustomer({ customerKey, onComplete })),
      dispatchGetCustomer: ({ customerKey }) => dispatch(loadCustomer({ customerKey })),
      dispatchLoadTrainingAssociates: ({ storeNumber }) => {
        dispatch(getTrainingAssociates({ storeNumber, isLimited: true }));
      },
      dispatchGetTrainingClassAvailabilityIfActiveBundleIsAvailable: () => {
        dispatch(getTrainingClassAvailabilityIfActiveBundleIsAvailable());
      },
    };
  },

  (mapProps, dispatchProps) => {
    const { componentId, customerKey, storeNumber, isHidden } = mapProps;
    const {
      dispatchGetCustomer,
      dispatchGetAvailableBundlesForAllPetsByCustomer,
      dispatchGetTrainingClassAvailabilityIfActiveBundleIsAvailable,
      dispatchLoadTrainingAssociates,
      resetPageHandler
    } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,

      // actions to pass to view
      getCustomer: () => {
        dispatchGetCustomer({ customerKey });
      },
      getAvailableBundlesForAllPetsByCustomer: () => {
        dispatchGetAvailableBundlesForAllPetsByCustomer({
          customerKey,
          onComplete: () => {
            dispatchGetTrainingClassAvailabilityIfActiveBundleIsAvailable();
          },
        });
      },
      loadTrainingAssociates: () => {
        dispatchLoadTrainingAssociates({ storeNumber });
      },
      resetPage: () => {
        resetPageHandler();
      }
    };
  },
)(ScheduleTrainingPageComponent);
