import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCenter } from "@/layout/center/Center";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { Button, Heading, TextPassage } from "@petsmart-ui/sparky";
import { HotelVoidTransactionReasons } from "@/dux/voidTransactionReasons/VoidTransactionReasons";

// Actions
import { hideCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import {
  putHotelInvoiceVoidTransaction,
  PUT_HOTEL_INVOICE_VOID_TRANSACTION,
} from "@/dux/hotelItineraryPayment/hotelItineraryPaymentActions";

// Selectors
import {
  selectedOtherVoidReasonNotes,
  selectVoidTransactionReason,
} from "@/dux/voidTransactionReasons/voidTransactionResonsSelectors";

// Utils
import { createLoadingSelector } from "@/core/selectors/utils";
import LoadingWrapper from "@/web/common/LoadingWrapper";

// Constants
import { VOID_TRANSACTION_OTHER_REASON } from "@/dux/_components/invoice/invoiceConstants";
import { itinerariesPaymentOrigin } from "@/core/constants/itinerariesConstants";

/**
 * React view component for Void transaction
 * @memberOf Views.Store
 * @function
 * @name VoidTransaction
 * @param {Object} props
 * @param {String} props.backButtonLabel
 * @param {String} props.componentId
 * @param {String} props.headerText
 * @param {Boolean} props.isHidden
 * @param {Boolean} props.isLoading
 * @param {Boolean} props.isVoidTransactionBtnEnabled
 * @param {Function} props.onDismiss
 * @param {Function} props.onVoidTransaction
 * @param {String} props.proceedButtonLabel
 * @param {String} props.voidTransactionMessage
 * @returns {JSX.Element|null}
 * @example const container = connect()(VoidTransaction)
 */
export const VoidTransaction = props => {
  const {
    backButtonLabel,
    componentId,
    headerText,
    isHidden,
    isLoading,
    isVoidTransactionBtnEnabled,
    onDismiss,
    onVoidTransaction,
    proceedButtonLabel,
    voidTransactionMessage,
  } = props;

  if (isHidden) return null;

  const [isVoidOptionsStep, setIsVoidOptionsStep] = useState(false);
  const [isProceedDisabled, setIsProceedDisabled] = useState(false);

  useEffect(() => {
    if (isVoidOptionsStep) setIsProceedDisabled(!isVoidTransactionBtnEnabled);
  }, [isVoidTransactionBtnEnabled]);

  // Handlers
  const handleBackClick = () => {
    if (isVoidOptionsStep) {
      setIsVoidOptionsStep(false);
      setIsProceedDisabled(false);
    } else onDismiss();
  };
  const handleProceedClick = () => {
    if (!isVoidOptionsStep) {
      setIsVoidOptionsStep(true);
      setIsProceedDisabled(true);
    } else onVoidTransaction();
  };

  return (
    <LayoutBox id={componentId}>
      <LoadingWrapper isLoading={isLoading}>
        <LayoutStack space="scale-G2">
          <LayoutCenter>
            <Heading tagName="h4">{headerText}</Heading>
          </LayoutCenter>

          {isVoidOptionsStep ? (
            <HotelVoidTransactionReasons />
          ) : (
            <TextPassage align="center">{voidTransactionMessage}</TextPassage>
          )}

          <LayoutCluster style={{ justifyContent: "space-between" }}>
            <Button onClick={handleBackClick} variant="link" text={backButtonLabel} />
            <Button
              onClick={handleProceedClick}
              variant="primary"
              text={proceedButtonLabel}
              disabled={isProceedDisabled}
            />
          </LayoutCluster>
        </LayoutStack>
      </LoadingWrapper>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for hotel void transaction
 * @summary Located on the hotel check-out page
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelVoidTransaction
 * @returns {JSX.Element|null}
 * @example <HotelVoidTransaction />
 */
export const HotelVoidTransaction = connect(
  state => {
    const voidReason = selectVoidTransactionReason(state);
    const voidReasonNotes = selectedOtherVoidReasonNotes(state);
    const hasVoidReason = voidReason !== VOID_TRANSACTION_OTHER_REASON && voidReason !== "";
    const hasOtherReasonWithNotes =
      voidReason === VOID_TRANSACTION_OTHER_REASON && voidReasonNotes !== "";

    const isVoidTransactionBtnEnabled = hasVoidReason || hasOtherReasonWithNotes;
    const isLoading = createLoadingSelector([PUT_HOTEL_INVOICE_VOID_TRANSACTION])(state);

    return {
      componentId: "HotelVoidTransaction",
      isHidden: false,
      isLoading,
      isVoidTransactionBtnEnabled,
      headerText: "Void the transaction",
      backButtonLabel: "Go Back",
      proceedButtonLabel: "Void Transaction",
      voidTransactionMessage:
        "Are you sure you want to void this transaction? If there are multiple pets in the order, all items will be voided with this action.",
    };
  },
  dispatch => {
    return {
      onDismiss: () => {
        dispatch(hideCheckInOutModal());
      },
      onVoidTransaction: () => {
        // Void transaction API action
        dispatch(putHotelInvoiceVoidTransaction(itinerariesPaymentOrigin.POS));
      },
    };
  },
)(VoidTransaction);
