import { connect } from "react-redux";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import CommonButton from "web/common/commonButton";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { history } from "dux/utils/browser/browserHistory";
import getIsHotelWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { clearCartsForPets } from "dux/petCartCard/actions/setPetCartCardActions";
import { isBookAppointmentHotelDisabled } from "dux/eligibility/helperUtils/checkIsBookingDisabled";
import { BOOK_HOTEL_RESERVATION } from "@/dux/bookAppointment/PetsHotelReservationConstant";
const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);

  /* * ----------------------------------------------------------------------- * *\
    In order for the Book Hotel button to show a few things need to happen:
      The feature flag and the System type of Hotel both have to resolve as true
      to logically handle the conditional check through the logical operator &&
      as the view uses a negative or reversed isHidden property to show the
      button.

      So the result actually of the logical operation needs to return
      false e.g isHidden is false, but in order to do that we need to reverse
      the isHidden for the feature flag to true, then we can use a proper
      logical operator and to say:

      "hey, the feature flag needs to be visible and we are in the system type of HOTEl. If both
      are true, then lets show the button".

      However it needs to be false for the view's isHidden property to be reversed,
      so we have to revers the result of the logical check to false, just so the
      view can flip it back to true as in the view is NOT Hidden.
  \* * ----------------------------------------------------------------------- * */
  const is305Visible = !getIsHotelWorkflowFeatureFlagHidden(state);
  const isInPetsHotelSystemType = getSystemBookingFlow(state) === systemName.HOTEL;

  return {
    // Here we flip the result of the resolved logical operation to handle the reversed view logic.
    isHidden: !(is305Visible && isInPetsHotelSystemType),
    componentId: "bookAppointmentHotel-commonButton",
    label: BOOK_HOTEL_RESERVATION,
    disabled: isBookAppointmentHotelDisabled(state, customerKey),

    customerKey,
  };
};

const mapDispatchToProps = dispatch => ({
  redirect: ({ customerKey }) => {
    history.push(`/booking/${customerKey}/select-service`);
    dispatch(clearCartsForPets());
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () =>
    propsFromDispatch.redirect({
      customerKey: propsFromState.customerKey,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
