import getErrorMatches from "../../core/utils/validationUtils/errorMatches";
import withClearReducer from "../utils/withClearReducer";
import { CLEAR_SERVER_ERRORS } from "../actionTypes/errorActionTypes";

const errorReducer = (state = {}, action) => {
  const matches = getErrorMatches(action.type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestStatus] = matches;
  return {
    ...state,
    [requestName]: requestStatus === "FAILURE" ? action.error : null
  };
};

export default withClearReducer(errorReducer, CLEAR_SERVER_ERRORS);
