import React from "react";
import styled from "styled-components";
import Select from "./Select";
import { color } from "./styles/theme";
import ErrorText from "./ErrorText";

const StyledSelect = styled.div`
  border-bottom: ${props => {
    if (props.error) {
      return "0.0625rem solid red";
    }
    if (props.isFocused) {
      return "0.0625rem solid blue";
    }
    if (props.disabled) {
      return "0.0625rem solid grey";
    }
    return "0.0625rem solid #bec0c4";
  }};
`;

const Title = styled.div`
  color: ${props => {
    if (props.error) {
      return "red";
    }
    if (props.isFocused) {
      return color.kalEl;
    }
    if (props.disabled) {
      return color.grey;
    }
    return "black";
  }};
`;

const Wrapper = styled.div`
  display: ${props => props.display || "grid"};
  width: ${props => props.width || "initial"};
  padding: ${props => props.padding || "none"};
`;

/* * ----------------------------------------------------------------------- * *\
  JSX View Component
\* * ----------------------------------------------------------------------- * */

class SelectWithWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      isFocused: false,
    };
  }

  onBlur() {
    this.setState({ touched: true, isFocused: false });
  }

  onFocus() {
    this.setState({ isFocused: true });
  }

  render() {
    const {
      title,
      options,
      componentId,
      isHidden,
      value,
      defaultValue,
      onChange,
      error,
      width,
      display,
      padding,
      disabled,
    } = this.props;

    const { touched, isFocused } = this.state;
    const showError = this.props.showError || (touched && !value && error);
    return !isHidden ? (
      <Wrapper id={componentId} width={width} display={display} padding={padding}>
        <Title {...this.props} error={showError} isFocused={isFocused}>
          {title}
        </Title>
        <StyledSelect
          {...this.props}
          onFocus={() => this.onFocus()}
          onBlur={() => this.onBlur()}
          error={showError}
          isFocused={isFocused}
        >
          <Select
            defaultValue={defaultValue}
            options={options}
            value={options.find(option => option.value === value) || null}
            onChange={onChange}
            error={showError}
            disabled={disabled}
          />
        </StyledSelect>
        {showError && <ErrorText message={showError} />}
      </Wrapper>
    ) : null;
  }
}

export default SelectWithWrapper;
