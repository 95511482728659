import { connect } from "react-redux";
import { getIsBulkPackageOfferingSelected } from "dux/bgm/bulkPackageOfferings/bulkPackageOfferingsSelectors";
import BulkPackageOfferingsExpirationComponent from "dux/_components/bgm/bulkPackageOfferings/BulkPackageOfferingsExpirationComponent";

const mapStateToProps = (state, { productBundleConfigId }) => {
  return {
    isHidden: !getIsBulkPackageOfferingSelected(state, { productBundleConfigId }),
  };
};

export default connect(mapStateToProps)(BulkPackageOfferingsExpirationComponent);
