// @ts-check
/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const POST_OVER_BOOKING_NEW_EXCEPTION_DATE = "POST_OVER_BOOKING_NEW_EXCEPTION_DATE";
export const POST_OVER_BOOKING_NEW_EXCEPTION_DATE_REQUEST =
  "POST_OVER_BOOKING_NEW_EXCEPTION_DATE_REQUEST";
export const POST_OVER_BOOKING_NEW_EXCEPTION_DATE_SUCCESS =
  "POST_OVER_BOOKING_NEW_EXCEPTION_DATE_SUCCESS";
export const POST_OVER_BOOKING_NEW_EXCEPTION_DATE_FAILURE =
  "POST_OVER_BOOKING_NEW_EXCEPTION_DATE_FAILURE";
export const CLEAR_OVERBOOKING_EXCEPTION_FORM = "CLEAR_OVERBOOKING_EXCEPTION_FORM";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 *
 * @param {object} payload
 * @param {string} payload.startDate
 * @param {string} payload.endDate
 * @param {number} payload.roomTypeBucket
 * @param {string} payload.percent
 * @returns
 */
export const postOverBookingNewExceptionDate = ({
  startDate,
  endDate,
  roomTypeBucket,
  percent,
}) => ({
  type: POST_OVER_BOOKING_NEW_EXCEPTION_DATE,
  startDate,
  endDate,
  roomTypeBucket,
  percent,
});

export const postOverBookingNewExceptionDateRequest = () => ({
  type: POST_OVER_BOOKING_NEW_EXCEPTION_DATE_REQUEST,
});

/**
 *
 * @param {object} payload
 * @returns
 */
export const postOverBookingNewExceptionDateSuccess = payload => ({
  type: POST_OVER_BOOKING_NEW_EXCEPTION_DATE_SUCCESS,
  payload,
});

/**
 *
 * @param {object|null} payload
 * @returns
 */
export const postOverBookingNewExceptionDateFailure = payload => ({
  type: POST_OVER_BOOKING_NEW_EXCEPTION_DATE_FAILURE,
  payload,
});

/**
 * Action to clear all form values for hotel overbooking exceptions
 * @returns object
 */
export const clearOverbookingExceptionForm = () => ({
  type: CLEAR_OVERBOOKING_EXCEPTION_FORM,
});
