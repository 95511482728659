import { associateWebBFFAPI } from "./_axiosConfigAssociateWebBff";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * API to get hotel overbooking exceptions
 * @memberOf APIs.hotel.Overbooking
 * @param {string} storeNumber - store number eg: 133
 * @param {string} fromDate - from date - past 30 days
 * @param {string} toDate - to date - future 18 months
 * @param {number} size - size of data to request
 * @param {number} page - page size when pagination is accounted
 * @param {boolean} includeHistory - attribute to include history
 * @returns {*} AxiosPromise
 */
export function fetchHotelOverBookingExceptions({
  storeNumber,
  fromDate,
  toDate,
  size = 12,
  page = 0,
  includeHistory = true,
}) {
  return associateWebBFFAPI.get(
    `${endpointVersionString()}/hotel/stores/${storeNumber}/overbookingexceptions?fromdate=${fromDate}&todate=${toDate}&size=${size}&page=${page}&includehistory=${includeHistory}`,
  );
}

/**
 * API to get hotel overbooking exceptions
 * @memberOf APIs.hotel.Overbooking
 * @param {string} storeNumber - store number eg: 133
 * @param {string} exceptionId
 * @returns {*} AxiosPromise
 */
export function fetchHotelOverBookingExceptionById({ storeNumber, exceptionId }) {
  return associateWebBFFAPI.get(
    `${endpointVersionString()}/hotel/stores/${storeNumber}/overbookingexceptions/${exceptionId}`,
  );
}

/**
 * API to patch hotel overbooking exceptions
 * @memberOf APIs.hotel.Overbooking
 * @param {Object} arg
 * @param {string} arg.storeNumber - store number eg: 133
 * @param {string} arg.capacityExceptionId
 * @param {string|number} arg.roomTypeBucketNumber
 * @param {string} arg.startDate
 * @param {string} arg.endDate
 * @param {string|number} arg.overBookingPercentage
 * @param {Boolean?} arg.isCanceled
 * @returns {*} AxiosPromise
 */
export function patchHotelOverBookingExceptionById({
  storeNumber,
  capacityExceptionId,
  roomTypeBucketNumber,
  startDate,
  endDate,
  overBookingPercentage,
  isCanceled,
}) {
  return associateWebBFFAPI.patch(
    `${endpointVersionString()}/hotel/stores/${storeNumber}/overbookingexceptions/${capacityExceptionId}`,
    {
      roomTypeBucketNumber,
      startDate,
      endDate,
      overBookingPercentage,
      isCanceled,
    },
  );
}

/**
 * API to get hotel overbooking set up data
 * @memberOf APIs.hotel.Overbooking
 * @param {string} storeNumber - store number eg: 133
 * @param {string} date
 * @returns {*} AxiosPromise
 */
export function fetchHotelOverBookingSetUp({ storeNumber, date }) {
  return associateWebBFFAPI.get(
    `${endpointVersionString()}/hotel/stores/${storeNumber}/overbookingsetup`,
    { params: { date } },
  );
}

/**
 * API to POST hotel overbooking exceptions by roomTypeBucketNumber
 * @memberof APIs.hotel.Overbooking
 * @param {string} storeNumber - store number eg: 133
 * @returns {*} AxiosPromise
 */
export function postHotelOverBookingExceptionByRoomTypeBucket({
  storeNumber,
  roomTypeBucketNumber,
  startDate,
  endDate,
  overBookingPercentage,
}) {
  return associateWebBFFAPI.post(
    `${endpointVersionString()}/hotel/stores/${storeNumber}/overbookingexceptions`,
    {
      roomTypeBucketNumber,
      startDate,
      endDate,
      overBookingPercentage,
    },
  );
}
