// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-layout__frame {
  --width: 9; /* width */
  --height: 16; /* height */
  padding-bottom: calc(var(--width) / var(--height) * 100%);
  position: relative;
}

.psm-layout__frame > * {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.psm-layout__frame > img,
.psm-layout__frame > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/components/Layout/Frame/styles.css"],"names":[],"mappings":"AAAA;EACE,UAAU,EAAE,UAAU;EACtB,YAAY,EAAE,WAAW;EACzB,yDAAyD;EACzD,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".psm-layout__frame {\n  --width: 9; /* width */\n  --height: 16; /* height */\n  padding-bottom: calc(var(--width) / var(--height) * 100%);\n  position: relative;\n}\n\n.psm-layout__frame > * {\n  overflow: hidden;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.psm-layout__frame > img,\n.psm-layout__frame > video {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
