import { connect } from "react-redux";
import BulkPackagePurchaseModalComponent from "../../../_components/bgm/cartDetails/BulkPackagePurchaseModalComponent";

const mapStateToProps = () => {
  return {
    componentId: "bulk-package-purchase-modal",
    header: "Purchase complete!",
    body: "Does the customer want to book an appointment?",
  };
};

export default connect(mapStateToProps)(BulkPackagePurchaseModalComponent);
