//@ts-check
import React from "react";
import { connect } from "react-redux";

// Components
import CommonModal from "web/common/modals/commonModal";
import { HotelRoomSuspensionsHistoryTable } from "@/dux/appointmentHistory/appointmentHistoryComponent";
import { Layout } from "@prism/psm-ui-components";

// Actions
import { setHotelManageRoomDetailsSuspensionHistoryId } from "./hotelManageRoomDetailsSuspensionHistoryActions";

// Selectors
import { selectRoomSuspensionHistoryModalIsHidden } from "./hotelManageRoomDetailsSuspensionHistorySelectors";

/**
 *
 * @param {object} props
 * @param {string} props.componentId
 * @param {boolean} props.isHidden
 * @param {Function} props.onClose
 * @param {JSX} props.historyTable
 * @returns
 */
export const HotelManageRoomDetailsSuspensionHistory = props => {
  const { componentId, isHidden, onClose, historyTable } = props;

  const Table = historyTable;

  return (
    <CommonModal isHidden={isHidden} onClose={onClose}>
      <Layout.Box id={componentId}>
        <Table />
      </Layout.Box>
    </CommonModal>
  );
};

// HOTEL MANAGE ROOM SUSPENSIONS HISTORY MODAL CONTAINER ----------------------------------------------------------------------------------------
export const HotelManageRoomSuspensionsHistoryModal = connect(
  state => {
    return {
      componentId: "HotelManageRoomSuspensionsHistoryModal",
      isHidden: selectRoomSuspensionHistoryModalIsHidden(state),
      historyTable: HotelRoomSuspensionsHistoryTable,
    };
  },

  dispatch => {
    return {
      onClose: () => {
        dispatch(setHotelManageRoomDetailsSuspensionHistoryId({ suspensionId: null }));
      },
    };
  },
)(HotelManageRoomDetailsSuspensionHistory);
