/**
 * @typedef {Object} CancellationReason
 * @property {string} value - Value provided for the selection element
 * @property {string} name - Name provided for the selection element
 */

/**
 * @typedef  {CancellationReason[]} CancellationReasons
 */

/**
 * Selector to select/retrieve cancellation reasons from redux state.
 * @param {Object} state
 * @return {CancellationReasons}
 */
export const selectCancellationReasons = state => state.cancellationReasons;
