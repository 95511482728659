/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const setMedicationInstructionsActionTypes = {
  SET_MEDICATION_INSTRUCTIONS: "SET_MEDICATION_INSTRUCTIONS",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setMedicationInstructions = ({ medicationInstructions, medicationId, petId }) => ({
  type: setMedicationInstructionsActionTypes.SET_MEDICATION_INSTRUCTIONS,
  medicationInstructions,
  medicationId,
  petId,
});
