import React from "react";
import { getPetToDisplay } from "@/core/utils/petUtils";
import PetAlertsSalonContainer from "@/dux/petAlerts/PetAlertsSalonContainer";
import PetFavorabilitySalonContainer from "@/dux/petFavorability/PetFavorabilitySalonContainer";
import { CONFIRMATION_MODAL_TYPE } from "@/core/constants/modalConstants";
import { NEW_PET_ID } from "@/core/constants";
import PetAlertsHotelContainer from "@/dux/petAlerts/PetAlertsHotelContainer";
import PetProfileAvailableBundlesListContainer from "@/dux/bgm/availableBundlesByPet/availableBundlesList/PetProfileAvailableBundlesListContainer";
import PetFavorabilityHotelContainer from "@/dux/petFavorability/PetFavorabilityHotelContainer";
import PetFavorabilityTrainingContainer from "dux/petFavorability/PetFavorabilityTrainingContainer";
import PetProfileTabsContainer from "@/dux/petProfileTabs/PetProfileTabsContainer";
import { DDCPetDetailsContainer } from "dux/_components/ddcPetDetails/DDCPetDetailsComponent";
import PetParentProfileColumn from "../PetParentProfileColumn";
import PetForm from "./petForm/PetFormContainer";
import PetVetsTable from "./petVets/petVetsTable/PetVetsTableContainer";
import PetHealthConditions from "./petHealthConditions/PetHealthConditionsContainer";
import PetProfileModals from "./modals/PetModalsContainer";
import Section from "../../common/Section";
import Button from "../../common/commonButton";
import AddButtonContainer from "../../common/AddButtonContainer";
import SplitLineWithMessage from "../../common/SplitLineWithMessage";
import AddPetAddButtonContainer from "./buttons/AddPetAddButtonContainer";
import PetProfileMedicationContainer from "../../medication/PetProfileMedicationContainer";
import PetProfileFoodContainer from "../../food/PetProfileFoodContainer";
import { PET_NOTES_PER_SERVICE_TYPE } from "dux/notes/NotesDiConstants";
import { PetVaccinations } from "@/dux/vaccinations/PetVaccinations";
import { LayoutStack } from "@/layout/stack/Stack";

class PetsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.onAddPet = this.onAddPet.bind(this);
    this.onCancelAddPet = this.onCancelAddPet.bind(this);
    this.alertsFormRef = React.createRef();
    this.infoFormRef = React.createRef();
  }

  componentDidMount() {
    const { selectedPet, pets, selectPet, routedPet } = this.props;
    const petToDisplay = routedPet || getPetToDisplay(pets);

    if (pets.length && selectedPet) {
      selectPet(petToDisplay);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedPet, pets, selectPet, missingRequiredMessage } = this.props;
    const petToDisplay = getPetToDisplay(pets);

    if (
      (pets.length && !selectedPet) ||
      !Object.values(pets)
        .map((pet) => pet.petId)
        .includes(selectedPet)
    ) {
      if (selectedPet !== NEW_PET_ID) {
        selectPet(petToDisplay);
      }
    }
    if (
      missingRequiredMessage !== prevProps.missingRequiredMessage &&
      missingRequiredMessage.length > 0
    ) {
      this.getMissingFieldModal(missingRequiredMessage);
    }
  }

  onCancelAddPet() {
    const { setIsAddingPet } = this.props;
    const isAddingPet = false;
    setIsAddingPet(isAddingPet);
  }

  onAddPet() {
    const { selectPet, setIsAddingPet } = this.props;
    const isAddingPet = true;
    selectPet(NEW_PET_ID);
    setIsAddingPet(isAddingPet);
  }

  getMissingFieldModal(missingRequiredMessage) {
    const { showConfirmationModal } = this.props;
    const message =
      "Please review the pet profile and update any required fields needing information before continuing.";

    showConfirmationModal({
      modalType: CONFIRMATION_MODAL_TYPE.ALERT,
      header: "Pet Data Missing",
      content: SplitLineWithMessage({
        string: missingRequiredMessage,
        delimiter: "\n",
        additionalMessage: message,
      }),
      confirmText: "Close",
    });
  }

  isPetActive() {
    const { currentPet } = this.props;
    return currentPet && currentPet.isActive;
  }

  renderPetColumn() {
    const {
      isLoading,
      customerKey,
      disabled,
      selectedPet,
      selectPet,
      modalType,
      isCustomerActive,
      numberOfActivePets,
      showModal,
      isAddingPet,
      systemName,
    } = this.props;
    const pets = isAddingPet
      ? [...this.props.pets, { petId: NEW_PET_ID, petName: "New pet" }]
      : [...this.props.pets];

    if (pets.length === 0) {
      if (isLoading) {
        return null;
      }
      return (
        <Section title="No pets yet" centered>
          <Button
            onClick={this.onAddPet}
            disabled={disabled || !isCustomerActive}
            label="Add pet"
          />
        </Section>
      );
    }
    return (
      <Section disabled={!isCustomerActive} padding="0">
        {modalType && <PetProfileModals modalType={modalType} />}
        {pets.length > 1 && (
          <PetProfileTabsContainer
            selectedPet={selectedPet}
            customerKey={customerKey}
            pets={pets}
            onSelectPet={selectPet}
            onAddPet={numberOfActivePets >= 10 ? showModal : this.onAddPet}
            onCancelAddPet={this.onCancelAddPet}
          />
        )}
        {pets.length <= 1 && !isAddingPet && (
          <AddButtonContainer>
            <AddPetAddButtonContainer />
          </AddButtonContainer>
        )}
        <PetForm
          isAdding={isAddingPet}
          onAddPet={this.onAddPet}
          onCancelAddPet={this.onCancelAddPet}
          onSelectPet={selectPet}
          showAddPet={!pets.length}
          customerKey={customerKey}
          petId={selectedPet}
          isPetActive={!this.isPetActive()}
          systemName={systemName}
        />
        <Section disabled={!this.isPetActive()}>
          <PetProfileAvailableBundlesListContainer />

          <PetVaccinations />
          {/* Only one Pet Favorability Section will display at at time based on tab the customer is in  */}
          <PetFavorabilitySalonContainer disabled={selectedPet === NEW_PET_ID} />
          <PetFavorabilityHotelContainer disabled={selectedPet === NEW_PET_ID} />
          <PetFavorabilityTrainingContainer />

          <DDCPetDetailsContainer />

          <PetProfileFoodContainer />

          <PetProfileMedicationContainer />

          {/* Only one Pet Alerts Section will display at at time based on tab the customer is in  */}
          <PetAlertsSalonContainer disabled={selectedPet === NEW_PET_ID} />
          <PetAlertsHotelContainer disabled={selectedPet === NEW_PET_ID} />

          <Section title="Health Conditions" topLine>
            <PetHealthConditions customerKey={customerKey} petId={selectedPet} />
          </Section>

          {/* --- PET notes such as grooming notes or Pet hotel notes --- */}
          {PET_NOTES_PER_SERVICE_TYPE[systemName]}

          <PetVetsTable customerKey={customerKey} petId={selectedPet} />
        </Section>
      </Section>
    );
  }

  render() {
    const { isHidden, componentID, disabled, colTitle } = this.props;

    if (!isHidden) {
      return (
        <LayoutStack id={componentID}>
          <PetParentProfileColumn title={colTitle} disabled={disabled} noRightBorder>
            {this.renderPetColumn()}
          </PetParentProfileColumn>
        </LayoutStack>
      );
    }

    return null;
  }
}

export default PetsComponent;