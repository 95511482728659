import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { isEmpty } from "lodash/fp";
import { pickBy } from "lodash";
import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";
import { getStoreNumber } from "../selectors/persistentSelectors";
import { dispatchItinerariesDataAndReturnItineraries } from "./itinerariesSaga";
import { fetchAddOns, putAddOns } from "../services/systemServicesBooking/addOnEndPoints";
import {
  loadAddOnsFailure,
  loadAddOnsRequest,
  loadAddOnsSuccess,
  loadEnhancedServicesSuccess,
  putAddOnsFailure,
  putAddOnsRequest,
  putAddOnsSuccess,
  putAddOnsWithBundledOptionsFailure,
  putAddOnsWithBundledOptionsRequest,
  putAddOnsWithBundledOptionsSuccess,
} from "../actionCreators/addOnsActionCreator";
import { updateStoreServiceForPetSuccess } from "../actionCreators/servicesSelectionActionCreator";
import addOnsActionTypes from "../actionTypes/addOnsActionTypes";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { getBundleAddOnIdsByPetServiceId } from "dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import wrapAddonIdsToObjects from "dux/utils/addOns/wrapAddonIdsToObjects";
import { waitForRequests } from "core/sagas/utilsSaga";
import petServiceItemsActionTypes from "core/actionTypes/petServiceItemsActionTypes";

function getAddonsAndEnhancedServices(allAddOns) {
  const enhancedServices = pickBy(allAddOns, addOn => addOn.isEnhancedAddOn);
  const addOns = pickBy(allAddOns, addOn => !addOn.isEnhancedAddOn);

  return { enhancedServices, addOns };
}

function* onloadAddOns({ petServiceId, fromDate }) {
  try {
    const storeNumber = yield select(getStoreNumber);
    const customerKey = yield select(getCurrentCustomerKey);
    const petId = yield select(getSelectedPet);
    yield put(loadAddOnsRequest());
    const response = yield call(fetchAddOns, {
      storeNumber,
      petServiceId,
      fromDate,
      customerKey,
      petId,
    });
    const addOnsData = response.data?.result;
    yield put(
      updateStoreServiceForPetSuccess({
        petServiceId,
        addOnIds: addOnsData.map(addOn => addOn.addOnId),
      }),
    );
    const { enhancedServices, addOns } = getAddonsAndEnhancedServices(normalizeArrayByProperty(addOnsData, "addOnId"))
    yield put(loadEnhancedServicesSuccess({ enhancedServices })); // NOTE: has to occur before LOAD_ADDONS_SUCCESS due to waterfall of actions on addons load
    yield put(loadAddOnsSuccess({ addOns }));
  } catch (error) {
    yield put(loadAddOnsFailure({ error }));
  }
}

function* onPutAddOns({ customerId, itineraryId, engagementId, petServiceItemId, addons }) {
  try {
    yield put(putAddOnsRequest());
    const response = yield call(putAddOns, {
      customerId,
      itineraryId,
      engagementId,
      petServiceItemId,
      addons,
    });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });
    const itinerary = normalizedItineraries[itineraryId];

    yield put(putAddOnsSuccess({ itinerary }));
  } catch (error) {
    yield put(putAddOnsFailure({ error }));
  }
}

function* onPutAddOnsWithBundledOptions({
  customerKey,
  itineraryId,
  engagementId,
  petServiceItemId,
  petServiceId,
}) {
  try {
    yield put(putAddOnsWithBundledOptionsRequest());

    const petId = yield select(getSelectedPet);
    const bundledAddOns = yield select(getBundleAddOnIdsByPetServiceId, {
      petId,
      petServiceId,
      customerKey,
    });

    if (!isEmpty(bundledAddOns)) {
      // Based on convo with Anthony on 11/11/20, we want to wait for the update pet service item call
      // to completely finish before making a call to PUT add-ons. This is because the PUT pet service
      // item endpoint automatically removes add-ons when the pet service is switched (i.e. if the user moves
      // from Bath & Brush to Bath & Trim), and when a call is made to PUT add-ons it is also attempts to remove
      // add-ons from the previously selected pet service, leading to a race condition.
      yield waitForRequests([petServiceItemsActionTypes.UPDATE_PET_SERVICE_ITEM]);

      yield call(onPutAddOns, {
        itineraryId,
        engagementId,
        petServiceItemId,
        customerId: customerKey,
        addons: wrapAddonIdsToObjects(bundledAddOns),
      });
    }

    yield put(putAddOnsWithBundledOptionsSuccess());
  } catch (error) {
    yield put(putAddOnsWithBundledOptionsFailure({ error }));
  }
}

function* watchloadAddOns() {
  yield takeEvery(addOnsActionTypes.LOAD_ADDONS, onloadAddOns);
}

function* watchPutAddOns() {
  yield takeEvery(addOnsActionTypes.PUT_ADDONS, onPutAddOns);
}

function* watchPutAddOnsWithBundledOptions() {
  yield takeEvery(addOnsActionTypes.PUT_ADDONS_WITH_BUNDLED_OPTIONS, onPutAddOnsWithBundledOptions);
}

export default function* addOnsaga() {
  yield all([watchloadAddOns(), watchPutAddOns(), watchPutAddOnsWithBundledOptions()]);
}
