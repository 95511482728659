import { connect } from "react-redux";
import moment from "moment";
import { getSelectedDate } from "@/dux/selectedDate/selectedDateSelector";
import { setSelectDate } from "@/dux/selectedDate/selectedDateActions";
import DaySelection from "@/web/weekNavigationBar/DaySelection";
import uniformTimeFormat from "@/core/utils/dateUtils/uniformTimeFormat";

const mapStateToProps = (state, ownProps) => {
  const uniformDates = uniformTimeFormat([ownProps.day, getSelectedDate(state)], "YYYY-MM-DD");
  return {
    componentId: "trainingDaySelection",
    isSelected: moment(uniformDates.date_0).isSame(uniformDates.date_1),
    formatedDate: moment(ownProps.day).format("ddd DD"),
    isUpperCase: true,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchClickHandler: ({ selectedDate }) => {
    dispatch(setSelectDate(selectedDate));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  clickHandler: () =>
    propsFromDispatch.dispatchClickHandler({
      selectedDate: ownProps.day,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DaySelection);
