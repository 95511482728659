/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SELECT_TRAINING_SERVICE = "SELECT_TRAINING_SERVICE";
export const CLEAR_SELECTED_TRAINING_SERVICE = "CLEAR_SELECTED_TRAINING_SERVICE";

export const SHOW_PURCHASE_TRAINING_SERVICE_MESSAGE = "SHOW_PURCHASE_TRAINING_SERVICE_MESSAGE";
export const HIDE_PURCHASE_TRAINING_SERVICE_MESSAGE = "HIDE_PURCHASE_TRAINING_SERVICE_MESSAGE";

export const SHOW_TRAINING_DATE_SELECTION = "SHOW_TRAINING_DATE_SELECTION";
export const HIDE_TRAINING_DATE_SELECTION = "HIDE_TRAINING_DATE_SELECTION";

export const CLEAR_TRAINING_APPOINTMENT = "CLEAR_TRAINING_APPOINTMENT";

export const SET_TRAINING_ENGAGEMENT_NOTE = "SET_TRAINING_ENGAGEMENT_NOTE";
export const CLEAR_TRAINING_ENGAGEMENT_NOTE = "CLEAR_TRAINING_ENGAGEMENT_NOTE";

export const TOGGLE_TRAINING_APPOINTMENT_IS_SET_MODAL = "TOGGLE_TRAINING_APPOINTMENT_IS_SET_MODAL";

export const TOGGLE_CANCEL_TRAINING_PENDING_APPOINTMENT_MODAL =
  "TOGGLE_CANCEL_TRAINING_PENDING_APPOINTMENT_MODAL";

export const RESET_SCHEDULE_TRAINING_PAGE = "RESET_SCHEDULE_TRAINING_PAGE";
export const RESET_SCHEDULE_TRAINING_APPOINTMENT = "RESET_SCHEDULE_TRAINING_APPOINTMENT";

// setPreviouxsBookingTimeZone
/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const selectTrainingService = ({ petServiceId }) => ({
  type: SELECT_TRAINING_SERVICE,
  petServiceId,
});

export const clearSelectedTrainingService = () => ({
  type: CLEAR_SELECTED_TRAINING_SERVICE,
});

export const showPurchaseTrainingServiceMessage = () => ({
  type: SHOW_PURCHASE_TRAINING_SERVICE_MESSAGE,
});

export const hidePurchaseTrainingServiceMessage = () => ({
  type: HIDE_PURCHASE_TRAINING_SERVICE_MESSAGE,
});

export const showTrainingDateSelection = () => ({
  type: SHOW_TRAINING_DATE_SELECTION,
});

export const hideTrainingDateSelection = () => ({
  type: HIDE_TRAINING_DATE_SELECTION,
});

export const clearSelectedTimeSlot = () => ({
  type: CLEAR_SELECTED_TIME_SLOT,
});

export const clearTrainingAppointment = () => ({
  type: CLEAR_TRAINING_APPOINTMENT,
});

export const setTrainingEngagementNote = (trainingEngagementNote) => ({
  type: SET_TRAINING_ENGAGEMENT_NOTE,
  trainingEngagementNote,
});

export const clearTrainingEngagementNote = () => ({
  type: CLEAR_TRAINING_ENGAGEMENT_NOTE,
});

export const toggleTrainingAppointmentIsSetModal = () => ({
  type: TOGGLE_TRAINING_APPOINTMENT_IS_SET_MODAL,
});

export const toggleCancelTrainingPendingAppointmentModal = () => ({
  type: TOGGLE_CANCEL_TRAINING_PENDING_APPOINTMENT_MODAL,
});

export const resetScheduleTrainingPage = () => ({
  type: RESET_SCHEDULE_TRAINING_PAGE,
});

export const resetScheduleTrainingAppointment = () => ({
  type: RESET_SCHEDULE_TRAINING_APPOINTMENT,
});
