import { all, call, put, takeEvery } from "redux-saga/effects";

// Endpoints
import { getMedicationsListEndpoint } from "@/core/services/petsmartWebPublic/hotelEndPoints";

// Utils
import normalizeArrayByProperty from "@/core/utils/normalizeUtils/normalizeArray";

// Actions
import {
  GET_MEDICATIONS_LIST,
  getMedicationsListFailure,
  getMedicationsListRequest,
  getMedicationsListSuccess,
} from "./medicationActions";

/**
 * Fetch V2 medications
 *
 * @memberOf Sagas.hotel.medications
 * @generator
 * @name onGetMedicationsList
 * @returns {Generator<*, void, *>}
 * @example yield call(getMedicationsListEndpoint)
 */
function* onGetMedicationsList() {
  try {
    yield put(getMedicationsListRequest());
    const response = yield call(getMedicationsListEndpoint);
    const normalizedMedicationsList = normalizeArrayByProperty(response.data, "MedicationId");
    yield put(getMedicationsListSuccess(normalizedMedicationsList));
  } catch (error) {
    yield put(getMedicationsListFailure(error));
  }
}

function* watchOnGetMedicationsListSaga() {
  yield takeEvery(GET_MEDICATIONS_LIST, onGetMedicationsList);
}

export default function* medicationsListSaga() {
  yield all([watchOnGetMedicationsListSaga()]);
}
