import styled from "styled-components";
import Popover from "react-popover";

const StyledPopover = styled(Popover)`
  .Popover-left {
    width: 30%
  }
  .Popover-body {
    margin: -1px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 9px 35px 0 rgba(68, 68, 68, 0.41);
    border: solid 1px #dedede;
    justify-content: space-around;
    z-index: 1;
  }

  .Popover-tip {
    z-index: 1;

    .Popover-tipShape {
      fill: white;
      stroke: #dedede;
      stroke-dasharray: 0, 0, 20;
    }
  }
`;

export default StyledPopover;
