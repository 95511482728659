import React, { useState } from "react";

// Components
import StyledPopover from "@/web/common/StyledPopover";

// Icons
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

/**
 *  React view component for PrismFilterFlyout
 *  @memberOf Views.Associate
 *  @function
 *  @name PrismFilterFlyout
 *  @param {object} props
 *  @param {boolean} props.isHidden
 *  @param {boolean} props.isActive
 *  @param {JSX.Element} props.children
 *  @param {JSX.Element} props.flyoutContent
 *  @param {object} props.flyoutContentProps
 *  @param {Function} props.onHandleClickFn
 *  @returns {JSX.Element}
 *
 *  @example const component = connect()(PrismFilterFlyout);
 */
export const PrismFilterFlyout = props => {
  const {
    isHidden,
    isActive,
    children,
    flyoutContent: FlyoutContent,
    flyoutContentProps,
    onHandleClickFn = () => {},
  } = props;

  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  if (isHidden || !FlyoutContent) {
    return null;
  }

  return (
    <StyledPopover
      body={<FlyoutContent onClose={() => setIsFlyoutOpen(false)} {...flyoutContentProps} />}
      isOpen={isFlyoutOpen}
      preferPlace="below"
      onOuterAction={() => setIsFlyoutOpen(false)}
      tipSize={7}
      // Issue: SVCSART-33939 - Fix specific to profile popover
      // Bump z-index as sparky components have higher z-index
      style={{ zIndex: "101" }}
    >
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={event => {
          event.stopPropagation();
          setIsFlyoutOpen(true);
          onHandleClickFn();
        }}
      >
        {isActive ? (
          <FilterAltIcon style={{ cursor: "pointer", marginRight: "5px" }} fontSize="12px" />
        ) : (
          <FilterAltOutlinedIcon
            style={{ cursor: "pointer", marginRight: "5px" }}
            fontSize="12px"
          />
        )}
        {children}
      </div>
    </StyledPopover>
  );
};
