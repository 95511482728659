import React from "react";
import styled from "styled-components";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
`;

export default function FlagIcon(props) {
  const { isHidden, componentID, handleClick } = props;

  if (!isHidden) {
    return (
      <SectionWrapper id={componentID} onClick={handleClick}>
        <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
          {/* <!-- Created with Method Draw - http://github.com/duopixel/Method-Draw/ --> */}
          <g>
            <title>background</title>
            {/* <rect  id="canvas_background" height="27" width="27" y="-1" x="-1"/> */}
            <g
              display="none"
              overflow="visible"
              y="0"
              x="0"
              height="100%"
              width="100%"
              id="canvasGrid"
            >
              <rect
                fill="url(#gridpattern)"
                strokeWidth="0"
                y="0"
                x="0"
                height="100%"
                width="100%"
              />
            </g>
          </g>
          <g>
            <title>Layer 1</title>
            <path
              stroke="#000"
              id="svg_1"
              d="m1.177482,5.688245c-0.157322,-0.431464 -0.171277,-1.138177 -0.03181,-1.572087l0.46152,-1.432612c0.139448,-0.433907 0.759597,-0.851143 1.378897,-0.927076l4.253418,-0.520315c0.618852,-0.075932 1.632539,-0.073453 2.250517,0.005544l4.607306,0.587605c0.619292,0.078996 1.632522,0.087018 2.253989,0.018521l4.507954,-0.497507c-0.005664,0.015431 -0.015686,0.029028 -0.020495,0.044459l-3.085478,10.380628l-1.412024,0.172801c-0.619269,0.075942 -1.631638,0.072837 -2.250929,-0.005532l-4.605997,-0.588222c-0.617978,-0.078381 -1.63253,-0.0864 -2.253996,-0.017908l-4.94592,0.546271c-0.621467,0.06849 -1.035059,-0.232722 -0.921725,-0.670939l0.553884,-2.123281c0.114189,-0.437631 0.07891,-1.148062 -0.077552,-1.579527l-0.661559,-1.820823l0,-0.000001zm22.132317,-4.750829c0.618394,0.09257 1.013677,0.520308 0.883372,0.957314l-6.58413,22.149425l-2.333737,0l6.680879,-22.480877c0.132483,-0.438864 0.736949,-0.718463 1.353616,-0.625863l0.000001,0z"
              strokeWidth="0.5"
              fill="#1777f2"
            />
          </g>
        </svg>
      </SectionWrapper>
    );
  }

  return null;
}
