import { bookingAPI } from "core/services/systemServicesBooking/_axiosConfigSystemServicesBooking";

/**
 * Get the services the pet is qualified for.
 * For System Services Booking API
 * - Get pet services at a hotel for a pet
 * @param {*} { customerKey, hotelNumber, petId ...params }
 * @param {string} params.roomConfig - Base 64 encoding of a Pet Room Config JSON (example minified: {"roomPetConfigurations":[{"petIdsOfPetsInRoom":[123,456]}]})
 *  Note: SF asked for this JSON in this format in order to keep this call as a GET, if we wanted to send the JSON as is we would need a new PUT endpoint
 * @returns {*} AxiosPromise
 */
export function fetchPetServicesAtHotel({
  customerKey,
  petList,
  storeNumber,
  appType,
  isMultiDay,
  checkinDate,
  checkOutDate,
  roomConfig = null,
}) {
  let url = `v2/hotel/customers/${customerKey}/pets/${petList}/petservices?storeNumber=${storeNumber}&appType=${appType}&isMultiDay=${isMultiDay}&checkInDate=${checkinDate}`;
  if (checkOutDate) url = `${url}&checkOutDate=${checkOutDate}`;
  if (roomConfig) url = `${url}&petRoomConfig=${roomConfig}`;
  return bookingAPI.get(url);
}
