import { get } from "lodash/fp";
import filterValidAppointmentsByStore from "./filterValidAppointmentsForStore";

/**
 * Responsible for determining if an appointment should be shown in the weekly Gantt view.
 * @param {object} petServiceItem
 * @param {number} storeNumber
 * @param {number} selectedAssociateWeekly
 * @returns {boolean}
 */
export default function filterValidWeeklyAppointments({
  petServiceItem,
  storeNumber,
  selectedAssociateWeekly
}) {
  return (
    filterValidAppointmentsByStore(petServiceItem, storeNumber) &&
    get("associate", petServiceItem) === selectedAssociateWeekly
  );
}
