import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { EditableSection, Box, Button, ButtonGroup } from "@petsmart-ui/sparky";
import { Layout, Text, Form } from "@prism/psm-ui-components";
import {
  selectCommonHotelBookingStepData,
  selectHotelBookingPetList,
  selectHotelBookingRoomIdByPetId,
  selectHotelBookingRooms,
  selectIsAggressivePetRoomSharing,
  selectIsCatInRoomWithDog,
  selectPetsWithoutRoom,
  selectShouldRestartToEdit,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { getPetById } from "@/core/selectors/entitiesSelector";
import {
  setHotelBookingFlowRoom,
  removeHotelBookingFlowRoom,
  setHotelBookingFlowPetRoomNumber,
  setHotelBookingCurrentServiceSelectionRoom,
  setHotelBookingPendingChanges,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowActions";
import clamp from "lodash/clamp";
import isEmpty from "lodash/fp/isEmpty";
import mapKeys from "lodash/mapKeys";
import omit from "lodash/fp/omit";
import * as petServicesAtHotelActionTypes from "web/services/hotelServices/actions/petServicesAtHotel";
import { showBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { hotelBookingTypes } from "@/web/hotelAlerts/hotelBookingConstants";
import { PetSelectionCard } from "../hotelBookingPetSelection/HotelBookingPetSelection";
import {
  getHotelBookingRoomCountOptions,
  getHotelBookingRoomErrorMessage,
} from "./_helpers_/hotelBookingServiceSelectionHelpers";
import { commonHotelBookingStepActions } from "@/web/features/hotelBookingFlow/hotelBookingFlowUtils";
import { selectRebookingPetRooms } from "../hotelEngagements/hotelEngagementSelectors";
import { compose } from "redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { selectIsRebookingItinerary } from "../hotelItinerary/hotelItinerarySelectors";

const RoomSectionComponent = ({ roomId, pets, isSelected, onPetCardClick }) => {
  return (
    <Box>
      <Layout.Cluster justify="space-between">
        <Text>Room {roomId}</Text>
      </Layout.Cluster>

      <Layout.Cluster justify="space-between">
        {Object.values(pets)?.map(pet => (
          <PetSelectionCard
            key={pet?.petId}
            pet={pet}
            isSelected={isSelected(roomId.toString(), pet?.petId)}
            onClick={() => onPetCardClick(roomId, pet?.petId)}
          />
        ))}
      </Layout.Cluster>
    </Box>
  );
};

const HotelBookingRoomSelectionComponent = ({
  componentID,
  roomCountOptions,
  selectedPetIds,
  selectedPets,
  isSelected,
  onPetCardClick,
  cleanupPetSelections,
  roomHasPets,
  initialRoomCount,
  canNavigate,
  isActive,
  onClick,
  disabledContinue,
  continueBooking,
  errorMessage,
  isRebooking,
  rebookingRoomCount,
  setPetRoomsFromRebookingItinerary = () => {},
}) => {
  const [roomCount, setRoomCount] = useState(initialRoomCount);

  useEffect(() => {
    // If the original pet selections change then clean up the rooms state to only include selected pets
    cleanupPetSelections();

    // Also verify that the selected room count is still a valid option, if it is out of bounds then update it
    setRoomCount(
      clamp(roomCount, roomCountOptions[0], roomCountOptions[roomCountOptions.length - 1]),
    );
  }, [selectedPetIds]);

  useEffect(() => {
    // If rebooking, then add pets rooms from itinerary to booking state
    if (isRebooking) {
      setPetRoomsFromRebookingItinerary();
      setRoomCount(rebookingRoomCount);
    }
  }, [selectedPetIds, isRebooking]);

  const renderRoomSections = () => {
    const roomSections = [];
    for (let i = 1; i < roomCount + 1; i++) {
      roomSections.push(
        <RoomSectionComponent
          key={`roomSection-${i}`}
          roomId={i}
          pets={selectedPets}
          isSelected={isSelected}
          onPetCardClick={onPetCardClick}
          isButtonDisabled={!roomHasPets(i)}
        />,
      );
    }

    return roomSections;
  };

  return (
    <EditableSection
      buttonText={canNavigate && "Edit"}
      isActive={isActive}
      onClick={onClick}
      title="Select Rooms"
    >
      <Layout.Box id={componentID}>
        <Text>How many rooms?</Text>

        <Form.Field.Select
          id={`${componentID}__select`}
          legacy
          value={roomCount}
          onChange={({ value }) => setRoomCount(value)}
          options={roomCountOptions}
        />

        {roomCount && (
          <Layout.Stack space="stack-space-4" style={{ margin: "1rem 0" }}>
            {renderRoomSections()}
          </Layout.Stack>
        )}
      </Layout.Box>
      {!!errorMessage && <Text color="text-color-red">{errorMessage}</Text>}
      <ButtonGroup align="right">
        <Button
          variant="primary"
          onClick={() => continueBooking()}
          text="Next"
          disabled={disabledContinue}
        />
      </ButtonGroup>
    </EditableSection>
  );
};

export const HotelBookingRoomSelection = compose(
  withRouteProps,
  connect(
    (state, { stepIndex, router }) => {
      const selectedPetIds = selectHotelBookingPetList(state);
      const selectedPets = selectedPetIds?.map(petId => {
        const { speciesId, petName } = getPetById(state, petId);
        return { petId, speciesId, petName };
      });
      const roomCountOptions = getHotelBookingRoomCountOptions({
        min: 1,
        max: selectedPetIds?.length,
      });
      const hotelBookingRooms = selectHotelBookingRooms(state);
      const isCatRoomSharingWithDog = selectIsCatInRoomWithDog(state);
      const isAggressiveBreed = selectIsAggressivePetRoomSharing(state);
      const { isActive, canNavigate, openStep, furthestStep } = selectCommonHotelBookingStepData(
        stepIndex,
      )(state);
      const itineraryId = router?.params?.itineraryId;

      return {
        componentID: "hotelBookingFlow-roomSelection",
        roomCountOptions,
        selectedPetIds,
        selectedPets,
        disabledContinue:
          selectPetsWithoutRoom(state) || isCatRoomSharingWithDog || isAggressiveBreed,
        hotelBookingRooms,
        selectRoomFromPet: petId => selectHotelBookingRoomIdByPetId(petId)(state),
        canNavigate,
        isActive,
        openStep,
        furthestStep,
        errorMessage: getHotelBookingRoomErrorMessage({
          isCatRoomSharingWithDog,
          isAggressiveBreed,
        }),
        shouldRestartToEdit: selectShouldRestartToEdit(state),
        // Rebooking data
        isRebooking: selectIsRebookingItinerary(state, { itineraryId }),
        rebookingRooms: selectRebookingPetRooms(state),
      };
    },
    (dispatch, { stepIndex }) => {
      const { setStep, onContinue } = commonHotelBookingStepActions(dispatch);
      return {
        dispatchSetHotelBookingFlowRoom: ({ roomId, pets }) =>
          dispatch(setHotelBookingFlowRoom({ roomId, pets })),
        dispatchRemoveHotelBookingFlowRoom: ({ roomId }) =>
          dispatch(removeHotelBookingFlowRoom({ roomId })),
        dispatchSetHotelBookingFlowPetRoomNumber: ({ roomId, petId }) =>
          dispatch(setHotelBookingFlowPetRoomNumber({ roomId, petId })),
        dispatchSetHotelBookingCurrentServiceSelectionRoom: roomId =>
          dispatch(setHotelBookingCurrentServiceSelectionRoom({ roomId })),
        dispatchLoadPetServicesAtHotel: petId =>
          dispatch(petServicesAtHotelActionTypes.loadPetServicesAtHotelBooking(petId)),
        savePendingAndShowModal: ({ rooms }) => {
          const pendingChanges = { rooms, openStep: stepIndex, furthestStep: stepIndex };
          // Add pet objects to state based on room selections
          mapKeys(rooms, (petList, roomId) => {
            petList?.map(petId => (pendingChanges[petId] = { roomId }));
          });
          dispatch(setHotelBookingPendingChanges(pendingChanges));
          dispatch(showBookingModal(hotelBookingTypes.HOTEL_BOOKING_RESTART_MODAL));
        },
        setStep,
        onContinue,
      };
    },
    (mapProps, dispatchProps, { stepIndex }) => {
      const {
        componentID,
        roomCountOptions,
        selectedPetIds,
        selectedPets,
        hotelBookingRooms,
        selectRoomFromPet,
        canNavigate,
        isActive,
        openStep,
        furthestStep,
        disabledContinue,
        errorMessage,
        shouldRestartToEdit,
        isRebooking,
        rebookingRooms,
      } = mapProps;
      const {
        dispatchSetHotelBookingFlowRoom,
        dispatchRemoveHotelBookingFlowRoom,
        dispatchSetHotelBookingFlowPetRoomNumber,
        dispatchSetHotelBookingCurrentServiceSelectionRoom,
        dispatchLoadPetServicesAtHotel,
        savePendingAndShowModal,
        setStep,
        onContinue,
      } = dispatchProps;

      const initialRoomCount = isEmpty(hotelBookingRooms)
        ? roomCountOptions[0]
        : Object.keys(hotelBookingRooms).length;

      const isSelected = (roomId, petId) => roomId === selectRoomFromPet(petId);

      const onPetCardClick = (roomId, petId) => {
        const petCurrentRoom = selectRoomFromPet(petId);
        const removePetFromRoom = !!petCurrentRoom;
        const addPetToRoom = !petCurrentRoom || petCurrentRoom !== roomId.toString();
        let rooms = { ...hotelBookingRooms };

        if (removePetFromRoom) {
          // if removing the only pet in the room, instead just remove the room
          const removeRoom = rooms[petCurrentRoom]?.length <= 1;
          if (removeRoom) rooms = omit(petCurrentRoom, rooms);
          else
            rooms = {
              ...rooms,
              [petCurrentRoom]: rooms[petCurrentRoom]?.filter(pet => pet !== petId),
            };
        }

        if (addPetToRoom) {
          const petList = rooms[roomId] || [];
          rooms = { ...rooms, [roomId]: [...petList, petId] };
        }

        if (shouldRestartToEdit) {
          savePendingAndShowModal({ rooms });
          return;
        }

        // Dispatch the updated room data to the store
        if (removePetFromRoom) {
          dispatchSetHotelBookingFlowPetRoomNumber({ roomId: null, petId });
          if (rooms[petCurrentRoom]) {
            dispatchSetHotelBookingFlowRoom({
              roomId: petCurrentRoom,
              pets: rooms[petCurrentRoom],
            });
          } else dispatchRemoveHotelBookingFlowRoom({ roomId: petCurrentRoom });
        }
        if (addPetToRoom) {
          dispatchSetHotelBookingFlowPetRoomNumber({ roomId, petId });
          dispatchSetHotelBookingFlowRoom({
            roomId,
            pets: rooms[roomId],
          });
        }
      };

      const cleanupPetSelections = () => {
        let petsToBeRemoved = [];
        Object.keys(hotelBookingRooms).forEach(roomId => {
          const room = hotelBookingRooms[roomId];
          if (!room) return;
          // If the roomId is out of bounds now, then empty the room, otherwise only remove unselected pets
          if (!roomCountOptions.includes(parseInt(roomId))) petsToBeRemoved = room;
          else
            room.forEach(petId => {
              if (!selectedPetIds.includes(parseInt(petId))) petsToBeRemoved.push(petId);
            });

          if (petsToBeRemoved.length) {
            petsToBeRemoved.forEach(petId => {
              dispatchSetHotelBookingFlowPetRoomNumber({ roomId: null, petId });
            });

            const newPetList = room.filter(pet => !petsToBeRemoved.includes(pet));
            if (isEmpty(newPetList)) {
              // If the new petlist is empty then remove the room completely
              dispatchRemoveHotelBookingFlowRoom({ roomId });
            } else {
              // Otherwise remove only the pets that are no longer selected
              dispatchSetHotelBookingFlowRoom({
                roomId,
                pets: newPetList,
              });
            }
            petsToBeRemoved = [];
          }
        });
      };

      const roomHasPets = roomId =>
        roomId in hotelBookingRooms && hotelBookingRooms[roomId]?.length;
      const setDefaultRoomClick = () => dispatchSetHotelBookingCurrentServiceSelectionRoom(1);

      const continueBooking = () => {
        dispatchLoadPetServicesAtHotel(selectedPetIds[0]);
        setDefaultRoomClick();
        onContinue({ openStep, furthestStep });
      };

      return {
        // control what props get passed to the view
        componentID,
        roomCountOptions,
        selectedPetIds,
        selectedPets,
        initialRoomCount,
        canNavigate,
        isActive,
        onClick: () => setStep(stepIndex),
        isSelected,
        onPetCardClick,
        cleanupPetSelections,
        roomHasPets,
        disabledContinue,
        continueBooking,
        errorMessage,
        isRebooking,
        rebookingRoomCount: Object.keys(rebookingRooms)?.length,
        setPetRoomsFromRebookingItinerary: () => {
          if (!isRebooking) return;

          Object.entries(rebookingRooms).map(([roomIdStr, pets]) => {
            const roomId = Number(roomIdStr);
            dispatchSetHotelBookingFlowRoom({ roomId, pets });
            pets.forEach(petId => {
              dispatchSetHotelBookingFlowPetRoomNumber({ roomId, petId });
            });
          });
        },
      };
    },
  ),
)(HotelBookingRoomSelectionComponent);
