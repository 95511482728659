import { connect } from "react-redux";
import {
  getSystemType,
  getSystemBookingFlow,
} from "../setSystemType/selectors/setSystemTypeSelectors";
import EnvironmentWarning from "../common/EnvironmentWarning";

const mapStateToProps = state => ({
  styleObj: { position: "absolute", top: 0 },
  systemType: getSystemType(state),
  systemBookingFlow: getSystemBookingFlow(state),
});

export default connect(mapStateToProps)(EnvironmentWarning);
