import { connect } from "react-redux";
import bookingActionTypes from "@/core/actionTypes/bookingActionTypes";
import { selectPet } from "@/core/actionCreators/bookingActionCreators";
import { getActivePetsForTabsList } from "@/core/selectors/entitiesSelector";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import { getCartDetails } from "../../core/selectors/cartSelectors";
import PetsTabs from "@/web/petParentProfile/pets/petsTabs/PetsTabs";
import { resetScheduleTrainingPage } from "../scheduleTrainingPage/scheduleTrainingPageActions";
import { setPetId } from "@/core/actionCreators/ui/web/generalActionCreators";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { getTrainingClassAvailabilityIfActiveBundleIsAvailable } from "@/dux/trainingClassAvailability/trainingClassAvailabilityActions";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);

  return {
    style: { margin: "none" },
    tabsData: getActivePetsForTabsList(state, { customerKey }),
    selectedPet: getCurrentPet(state),
    customerKey: getCurrentCustomerKey(state),
    appointments: getCartDetails(state),
    isAvailabilityLoading: createLoadingSelector([
      bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS,
      bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT,
    ])(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onSelectPet: petId => {
    dispatch(setPetId(petId));
    dispatch(resetScheduleTrainingPage());
    dispatch(getTrainingClassAvailabilityIfActiveBundleIsAvailable());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PetsTabs);
