import React from "react";
import styled from "styled-components";

import OvalLoader from "../../assets/icons/oval-loader.svg";

const LoadingAuth = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.div`
  color: white;
  margin-bottom: 2em;
`;

export default () => (
  <LoadingAuth>
    <Heading>Authenticating...</Heading>
    <img alt="loader" src={OvalLoader} />
  </LoadingAuth>
);
