import { hotelCheckedOutActionTypes } from "./hotelCheckedOutActions";
import { hotelCheckedOutHeaderSearchActionTypes } from "../columnHeader/search/hotelCheckedOutHeaderSearchActions";
import { hotelCheckedOutFilterButtonActionTypes } from "../columnHeader/filterButtons/hotelCheckedOutFilterButtonActions";
import { hotelCheckedOutColumnToggleActionTypes } from "../columnHeader/columnSwitch/hotelCheckedOutColumnToggleActions";

const initialState = {
  appointments: [],
  searchText: "",
  filterBy: {
    overnight: true,
    dayCamp: true,
    dayCare: true,
    all: true,
  },
  isHidden: true,
};

const hotelCheckedOutReducer = (state = initialState, action) => {
  switch (action.type) {
    case hotelCheckedOutActionTypes.CLEAR_HOTEL_CHECKED_OUT:
      return initialState;
    case hotelCheckedOutActionTypes.LOAD_HOTEL_CHECKED_OUT_SUCCESS:
      return {
        ...state,
        appointments: action.checkedOut,
      };
    case hotelCheckedOutHeaderSearchActionTypes.SET_HOTEL_CHECKED_OUT_SEARCH:
      return {
        ...state,
        searchText: action.value,
      };
    case hotelCheckedOutHeaderSearchActionTypes.CLEAR_HOTEL_CHECKED_OUT_SEARCH:
      return {
        ...state,
        searchText: "",
      };
    case hotelCheckedOutFilterButtonActionTypes.FILTER_BY_OVERNIGHT_CHECKED_OUT:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          overnight: action.filterBtnStatus,
        },
      };
    case hotelCheckedOutFilterButtonActionTypes.FILTER_BY_DAY_CAMP_CHECKED_OUT:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          dayCamp: action.filterBtnStatus,
        },
      };
    case hotelCheckedOutFilterButtonActionTypes.FILTER_BY_DAY_CARE_CHECKED_OUT:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          dayCare: action.filterBtnStatus,
        },
      };
    case hotelCheckedOutFilterButtonActionTypes.FILTER_BY_All_CHECKED_OUT:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          all: action.filterBtnStatus,
        },
      };
    case hotelCheckedOutColumnToggleActionTypes.TOGGLE_HOTEL_CHECKED_OUT_COLUMN:
      return {
        ...state,
        isHidden: action.isHidden,
      };
    default:
      return state;
  }
};

export default hotelCheckedOutReducer;
