import { connect } from "react-redux";
import moment from "moment";
import { every } from "lodash/fp";
import { APPOINTMENT_STATUS } from "../../core/constants/index";
import { pageNames } from "../../core/constants/checkInOutConstants";
import ConfirmAppointmentButton from "../common/commonButton";
import { updateEngagementStatus } from "../../core/actionCreators/engagementsActionCreators";
import {
  checkIfButtonShouldShow,
  checkIfMultiButtonShouldShow
} from "../_helpers/generic/buttons/checkIfButtonsShouldshow";
import getStoreDictionary from "../_helpers/generic/buttons/getStoreDictionary";

const mapStateToProps = (state, ownProps) => {
  const dict = getStoreDictionary(state);

  return {
    isHidden:
      !checkIfButtonShouldShow({
        currentPage: dict.page,
        requiredPage: pageNames.FUTURE_APPOINTMENT,
        petServiceItems: dict.petServiceItems,
        currentPetServiceItem: dict.currentPetServiceItem
      }) && checkIfMultiButtonShouldShow(dict.petServiceItems),
    label: "Confirm for all",
    disabled: dict.isConfirmForAllDisabled || dict.isFromOtherStore,

    // Not actually a param we are passing to the ConfirmAppointmentButton
    // but rather passing it to mapDispatchToProps through mergeProps
    currentPetServiceItem: dict.currentPetServiceItem
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchUpdateItineraryStatus: ({ currentPetServiceItem }) => {
    const { customer, itinerary } = currentPetServiceItem;

    // NOTE: This is currently broken and will return a 500 when fired
    // as it is missing engagements. This functionality will be added
    // at a later date, however I am leaving this code as it should be
    // an easy fix to add the engagements to the action when the business
    // decides they want to implement this functionality, however is not
    // part of ticket SVCS-3154 so I was instructed to leave as is until
    // a later date.
    dispatch(
      updateEngagementStatus({
        reason: "",
        status: APPOINTMENT_STATUS.CONFIRMED,
        customerId: customer,
        itineraryId: itinerary
      })
    );
  }
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () =>
    propsFromDispatch.dispatchUpdateItineraryStatus({
      currentPetServiceItem: propsFromState.currentPetServiceItem
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ConfirmAppointmentButton);
