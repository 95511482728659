import { keyframes } from "styled-components";

/**
 *Responsible for animating a DOM element to slide down via CSS Keyframes
 *
 * @export slideDown
 * @returns styled-components keyframe object
 */
export default function slideDown() {
  return keyframes`
  0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
      opacity: 1;
        transform: translateY(0);
    }
`;
}
