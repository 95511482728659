import { connect } from "react-redux";
import ConfirmationModal from "./ConfirmationModal";
import { hideConfirmationModal } from "../../../../../core/actionCreators/confirmationModalActionCreators";

// TODO: create selectors for confirmation modal state
const mapStateToProps = state => ({
  modalType: state.modals.confirmationModal.modalType,
  header: state.modals.confirmationModal.header,
  content: state.modals.confirmationModal.content,
  confirmText: state.modals.confirmationModal.confirmText,
  cancelText: state.modals.confirmationModal.cancelText,
  confirm: state.modals.confirmationModal.confirm,
  cancel: state.modals.confirmationModal.cancel,
  closeBeforeConfirm: state.modals.confirmationModal.closeBeforeConfirm,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(hideConfirmationModal()),
});

const ConfirmationModalContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);

export default ConfirmationModalContainer;
