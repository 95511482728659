/**
 *  Verifies a pet object has a needs review property, if not, it will add the property and set it to false.
 *
 *  @memberOf Helpers.redux
 *  @function
 *  @name hasNeedsReviewProperty
 *  @param {Object } Pet - Pet object, e.g. {petKey: 1234, petName: "Foo", ...}
 *  @returns {*}
 *  @example
 *
 *  const petWithNeedsReview = hasNeedsReviewProperty(pet);
 */
export const hasNeedsReviewProperty = pet => {
  const clonedPet = { ...pet };
  const hasProperty = Object.prototype.hasOwnProperty.call(clonedPet, "needsReview");

  // if the pet already has the needsReview Property, just return the pet object.
  if (hasProperty) {
    return clonedPet;
  }

  // if the pet does not have the needsReview property, add it and set it to false by default.
  clonedPet.needsReview = false;

  // return pet object with a needs review property
  return clonedPet;
};

/**
 * Add the needs review property to a pet within an Itinerary object.
 *
 * @memberOf Helpers.redux
 * @function
 * @name addNeedsReviewToPetInItinerary
 * @param {Object} itineraryPayload - Full Itinerary Object
 * @param {Boolean} needsReview -  needs review value
 * @param {Number} petId - id that represents a pet
 * @return {*}
 */
export const addNeedsReviewToPetInItinerary = (itineraryPayload, needsReview, petId) => {
  // Clone itineraryPayload to no modify state of object passed in
  const itinerary = { ...itineraryPayload };

  itinerary.pets = itinerary.pets?.map(pet => {
    const hasNeedsReviewPet = hasNeedsReviewProperty(pet);

    if (hasNeedsReviewPet.petKey === petId) {
      hasNeedsReviewPet.needsReview = needsReview;
    }
    return hasNeedsReviewPet;
  });

  return itinerary;
};

/**
 * Get the needs review value from a "selected" pet from an array of Pets
 *
 * @memberOf Helpers.redux
 * @function
 * @name getNeedsReviewPropertyFromPets
 * @param {Array} Pets - Array of Pets
 * @param {number} petId - the Id of the selected pet
 * @return {boolean}
 *
 * @example
 *
 * const needsReview = getNeedsReviewPropertyFromPets(pets, petId);
 */
export const getNeedsReviewPropertyFromPets = (pets, petId) => {
  // find the selected pet
  const selectedPet = pets.find(pet => pet.petKey === petId) ?? {};

  // Make sure the selected pet has a needs review property and retrieve it.
  const { needsReview } = hasNeedsReviewProperty(selectedPet);

  // return out needs review
  return needsReview;
};
