import React, { useEffect } from "react";
import { connect } from "react-redux";
import { EditableSection, Button, ButtonGroup, SparkyTextWrapper } from "@petsmart-ui/sparky";
import { Layout } from "@prism/psm-ui-components";
import {
  selectCommonHotelBookingStepData,
  selectHotelBookingPetList,
  selectHotelBookingRooms,
  selectIsOvernight,
  selectPetsWithoutService,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { clearFoods, setFoods } from "@/web/food/actions/foodsActions";
import {
  getFoodByPetExcludingNewFoodID,
  getFoodsExcludingNewFoodID,
  selectPetFoodsForBooking,
  selectPetFoodsIsMissingDates,
  selectItineraryDiscontinuedFoods,
} from "@/web/food/foodsSelector";
import isEmpty from "lodash/isEmpty";
import normalizeArrayByProperty from "@/core/utils/normalizeUtils/normalizeArray";
import { commonHotelBookingStepActions } from "@/web/features/hotelBookingFlow/hotelBookingFlowUtils";
import { setHotelBookingFurthestStep } from "@/web/features/hotelBookingFlow/hotelBookingFlowActions";
import { foodCountLabel, noFoodLabel } from "./hotelBookingFoodSectionConstants";
import BookingFoodContainer from "../bookingFeeding/BookingFoodContainer";
import { HotelBookingRoomAndPetTabs } from "../hotelBookingRoomAndPetTabs/HotelBookingRoomAndPetTabs";
import { compose } from "redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { selectIsRebookingItinerary } from "../hotelItinerary/hotelItinerarySelectors";
import { getIconType } from "../hotelBookingRoomAndPetTabs/hotelBookingRoomAndPetTabsHelpers";
import { showBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { hotelBookingTypes } from "@/web/hotelAlerts/hotelBookingConstants";

const HotelBookingFoodSectionComponent = ({
  onContinue,
  isHidden,
  componentID,
  canNavigate,
  isActive,
  onClick,
  getRoomTabIconType,
  getPetTabIconType,
  pets,
  setPetFoods,
  clearFood,
  disabledContinue,
  isPrevStepIncomplete = false,
  goToPreviousStep = () => {},
  descriptionText = "",
  showDiscontinuedFoodsModal = () => {},
}) => {
  useEffect(() => {
    if (pets?.length) setPetFoods();
  }, [pets]);

  useEffect(() => {
    // Clear food state once we leave the booking page
    // If a reservation was booked any new food should be stored on the itinerary by this point
    return () => {
      clearFood();
    };
  }, []);

  useEffect(() => {
    // If we somehow got to this step even though the previous step
    // wasn't completed, then we want to return to the previous step
    if (isActive && isPrevStepIncomplete) goToPreviousStep();
  }, [isActive]);

  useEffect(() => {
    if (isActive) showDiscontinuedFoodsModal();
  }, [isActive]);

  if (isHidden) return null;

  const description = () => {
    if (isActive || !canNavigate) return;
    return <SparkyTextWrapper truncateOn={1}>{descriptionText}</SparkyTextWrapper>;
  };

  return (
    <EditableSection
      buttonText={canNavigate && "Edit"}
      isActive={isActive}
      onClick={onClick}
      title="Food Details"
      description={description()}
    >
      <Layout.Box id={componentID}>
        <HotelBookingRoomAndPetTabs
          componentID={componentID}
          getRoomTabIconType={getRoomTabIconType}
          getPetTabIconType={getPetTabIconType}
        >
          {petId => <BookingFoodContainer petId={petId} />}
        </HotelBookingRoomAndPetTabs>

        <ButtonGroup align="right">
          <Button
            variant="primary"
            onClick={() => {
              onContinue();
            }}
            text="Next"
            disabled={disabledContinue}
          />
        </ButtonGroup>
      </Layout.Box>
    </EditableSection>
  );
};

export const HotelBookingFoodSection = compose(
  withRouteProps,
  connect(
    (state, { stepIndex, router }) => {
      const petHasFood = petId => !isEmpty(getFoodByPetExcludingNewFoodID(state, { petId }));
      const rooms = selectHotelBookingRooms(state);
      const isFoodRequired = selectIsOvernight(state);
      const pets = selectHotelBookingPetList(state);
      const allPetsHaveFood = pets.every(petId => petHasFood(petId));
      const { isActive, canNavigate, openStep, furthestStep } = selectCommonHotelBookingStepData(
        stepIndex,
      )(state);
      const allPetFoods = getFoodsExcludingNewFoodID(state);
      const foodCount = Object.values(allPetFoods)?.reduce(
        (count, food) => count + Object.values(food)?.length,
        0,
      );
      const descriptionText = foodCount > 0 ? `${foodCount} ${foodCountLabel}` : noFoodLabel;

      // Rebooking data
      const itineraryId = router?.params?.itineraryId;
      const isRebooking = selectIsRebookingItinerary(state, { itineraryId });

      return {
        componentID: "hotelBookingFlow-foodSection",
        canNavigate,
        isActive,
        openStep,
        furthestStep,
        disabledContinue: isFoodRequired && !allPetsHaveFood,
        pets,
        descriptionText,
        getRoomTabIconType: roomId => {
          const showCheckIcon = rooms[roomId]?.every(petId => petHasFood(petId));
          const hasError = rooms[roomId]?.some(petId =>
            selectPetFoodsIsMissingDates(state, { petId }),
          );
          return getIconType(showCheckIcon, hasError);
        },
        getPetTabIconType: petId => {
          const showCheckIcon = petHasFood(petId);
          const hasError = selectPetFoodsIsMissingDates(state, { petId });
          return getIconType(showCheckIcon, hasError);
        },
        getInitFoods: petId => selectPetFoodsForBooking(state, { petId, isRebooking }),
        isPrevStepIncomplete: selectPetsWithoutService(state),
        discontinuedFoods: isRebooking && selectItineraryDiscontinuedFoods(state, { petIds: pets }),
      };
    },
    (dispatch, { stepIndex }) => {
      const { setStep, onContinue } = commonHotelBookingStepActions(dispatch);
      return {
        clearFood: () => dispatch(clearFoods()),
        setFoods: (foods, petId) => {
          const normalizedFoods = normalizeArrayByProperty(foods, "petFoodId");
          dispatch(setFoods({ foods: normalizedFoods, petId }));
        },
        setStep,
        onContinue,
        goToPreviousStep: () => {
          setStep(stepIndex - 1);
          dispatch(setHotelBookingFurthestStep(stepIndex - 1));
        },
        dispatchDiscontinuedFoodsModal: () =>
          dispatch(showBookingModal(hotelBookingTypes.REBOOKING_FOOD_NOT_AVAILABLE)),
      };
    },
    (stateProps, dispatchProps, { stepIndex }) => {
      const {
        componentID,
        canNavigate,
        isActive,
        openStep,
        furthestStep,
        disabledContinue,
        pets,
        descriptionText,
        getRoomTabIconType,
        getPetTabIconType,
        getInitFoods,
        isPrevStepIncomplete,
        discontinuedFoods,
      } = stateProps;
      const {
        clearFood,
        setFoods,
        setStep,
        onContinue,
        goToPreviousStep,
        dispatchDiscontinuedFoodsModal,
      } = dispatchProps;

      return {
        onContinue: () => onContinue({ openStep, furthestStep }),
        componentID,
        canNavigate,
        isActive,
        onClick: () => setStep(stepIndex),
        disabledContinue,
        pets,
        descriptionText,
        getRoomTabIconType,
        getPetTabIconType,
        clearFood,
        setPetFoods: () => {
          // Initialize with pet profile foods or if rebooking then init with itinerary foods
          pets.forEach(petId => {
            const foods = getInitFoods(petId);
            setFoods(foods, petId);
          });
        },
        goToPreviousStep,
        isPrevStepIncomplete,
        showDiscontinuedFoodsModal: () => {
          if (isEmpty(discontinuedFoods)) return;

          dispatchDiscontinuedFoodsModal();
        },
      };
    },
  ),
)(HotelBookingFoodSectionComponent);
