import petProfileActionTypes from "../actionTypes/petProfileActionTypes";

export const showPetProfileModal = modalType => ({
  type: petProfileActionTypes.SHOW_PET_PROFILE_MODAL,
  modalType
});

export const hidePetProfileModal = () => ({
  type: petProfileActionTypes.HIDE_PET_PROFILE_MODAL
});

export default {
  showPetProfileModal,
  hidePetProfileModal
};
