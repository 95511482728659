import formatBundleLabelFactory from "../../common/formatBundleLabelFactory";

function isSingular(count) {
  return count === 1 ? "Service" : "Services";
}

export function formatLabel(label, value = "-") {
  return `${label}: ${value}`;
}

export const formatBundleServiceLabel = formatBundleLabelFactory(formatLabel);

export function formatUnitsLabel(count, type) {
  if (count === 0) {
    return `No Services ${type}`;
  }

  return `${count} ${isSingular(count)} ${type}:`;
}

export function formatPackagesLabel(isPrefixed, count, type) {
  const prefix = isPrefixed ? "All " : "";
  return `${prefix}${count} ${isSingular(count)} ${type}`;
}
