import getHotelWorkflowFeatureFlag
  from "web/enableDisableWorkflowFeatureFlag/selectors/hotel/getHotelWorkflowFeatureFlag";
import getTrainingWorkflowFeatureFlag
  from "web/enableDisableWorkflowFeatureFlag/selectors/training/getTrainingWorkflowFeatureFlag";

/**
 * Helper function to determine, based on various FF status if a component should be hidden
 * @param globalState
 * @return {boolean}
 */
export const isAFeatureFlagsHiddenLogicHelper = (globalState) => {
  const hotelFF = getHotelWorkflowFeatureFlag(globalState).isHidden;
  const trainingFF = getTrainingWorkflowFeatureFlag(globalState).isHidden;

  // if hotel and training ff is hidden(e.g. off) hide component, so return true
  if(hotelFF && trainingFF){
    return true;
  }

  // if hotel or training ff is NOT hidden(e.g. On) Show component, so return false
  if(!hotelFF || !trainingFF){
    return false;
  }
}

