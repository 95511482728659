import { associateWebProfileAPI } from "./_axiosConfigAssociateWebProfile";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Add a vaccination to a pet from the Web Associate Profile API - Add pet's vaccination end point
 * @param {*} { customerKey, petId, sourceId, data }
 * @returns
 */
export function postPetVaccination({ customerKey, petId, sourceId, data }) {
  return associateWebProfileAPI.post(
    `${endpointVersionString()}/customers/${customerKey}/pets/${petId}/vaccinations?sourceId=${sourceId}`,
    data,
  );
}

/**
 * Delete a vaccination from a pet from the Web Associate Profile API - soft delete of a pet vaccination end point
 * @export
 * @param {*} { customerKey, petId, vaccinationId, sourceId }
 * @returns
 */
export function deletePetVaccination({ customerKey, petId, vaccinationId, sourceId }) {
  return associateWebProfileAPI.delete(
    `${endpointVersionString()}/customers/${customerKey}/pets/${petId}/vaccinations/${vaccinationId}?sourceId=${sourceId}`,
  );
}

/**
 * Update a vaccination record for a pet from the Web Associate Profile API - Update pets vaccination record for the give vaccinationId end point
 *
 * @export
 * @param {*} { customerKey, petId, vaccinationId, payload }
 * @returns
 */
export function putPetVaccination({ customerKey, petId, vaccinationId, payload }) {
  return associateWebProfileAPI.put(
    `${endpointVersionString()}/customers/${customerKey}/pets/${petId}/vaccinations/${vaccinationId}?sourceId=${
      payload.sourceId
    }`,
    payload,
  );
}
