import { isEmpty } from "lodash/fp";
import getFormFieldsChanged from "./getFormFieldsChanged";

/**
 * Checks if form fields have been updated
 *
 * @export getIsFormUpdated
 * @param { Object } previousFormValues - An object with form values.
 * @param { Object } currentFormValues - An object with form values.
 * @returns { Boolean } - returns true/false if form values have been updated.
 */

export default function getIsFormUpdated(previousFormValues, formValues) {
  const formFieldsChanged = getFormFieldsChanged(previousFormValues, formValues);
  return !isEmpty(formFieldsChanged);
}
