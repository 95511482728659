/**
 * Convert a delimited string to pascal case
 * @param {String} nonPascalString - string to convert to pascal case
 * @param {String} delimiter - delimiter such as a space or underscore to set where the pascal case should happen
 * @param {Boolean} keepSpace - Optional if you want a space or not. default is true
 * @returns {String}
 */
export default function formatToPascalCase(nonPascalString, delimiter, keepSpace = true) {
  const wordList = nonPascalString.split(delimiter);

  for (let i = 0; i < wordList.length; i++) {
    wordList[i] = wordList[i][0].toUpperCase() + wordList[i].substr(1);
  }

  return wordList.join(keepSpace ? " " : "");
}
