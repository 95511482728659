import React from "react";
import { connect } from "react-redux";

import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";

import HeaderImage from "@/assets/images/bg-header-image.png";

import AppHeader from "@/web/header/AppHeader";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { LayoutSwitcher } from "@/layout/switcher/Switcher";
import { DDCPackagesPetParentColumn } from "./DdcPackagesPetParentColumn";
import { DDCPackagesListColumn } from "./DdcPackagesListColumn";
import getIsDdcPackagesWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ddcPackages/getIsDdcPackagesWorkflowFeatureFlagHidden";
import { Navigate } from "react-router-dom";
import { routePaths } from "@/core/constants/routePaths";
import { DDCPackagesCartColumn } from "./DdcPackagesCartColumn";

/**
 * React view component for purchasing ddc packages
 * @memberOf Views.Purchase
 * @param {*} props
 * @returns {JSX.Element}
 *
 * @example const component = connect()(PurchaseDDCPackages);
 */
export const PurchaseDDCPackages = props => {
  const { componentId, Header, isHidden } = props;

  // If page is hidden then redirect to dashboard
  if (isHidden) return <Navigate to={routePaths.DASHBOARD} />;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack space="scale-0">
        {/* Header */}
        <LayoutBox padding="scale-0">
          <Header />
        </LayoutBox>

        <LayoutSwitcher space="scale-S10">
          <DDCPackagesPetParentColumn />
          <DDCPackagesListColumn />
          <DDCPackagesCartColumn />
        </LayoutSwitcher>
      </LayoutStack>
    </LayoutBox>
  );
};

export const PurchaseDDCPackagesHeader = () => (
  <AppHeader
    componentId="PurchaseDDCPackagesHeader"
    title="Purchase DDC Play & Save"
    backgroundImage={HeaderImage}
  />
);

/**
 * Redux Connect function for Purchase DDC Play & Save page
 * @memberOf Views.Purchase
 * @summary path: /purchase/:customerKey/ddc-packages
 * @function
 * @name PurchaseDDCPackagesPage
 * @example <PurchaseDDCPackagesPage />
 */
export const PurchaseDDCPackagesPage = withRouteProps(
  connect(state => {
    return {
      componentId: "PurchaseDDCPackagesPage",
      Header: PurchaseDDCPackagesHeader,
      isHidden: getIsDdcPackagesWorkflowFeatureFlagHidden(state),
    };
  })(PurchaseDDCPackages),
);
