import { hotelOvernightActionTypes } from "./hotelOvernightActions";
import { hotelOverNightHeaderSearchActionTypes } from "../columnHeader/search/hotelOverNightHeaderSearchActions";
import { hotelOvernightFilterButtonActionTypes } from "../columnHeader/filterButtons/hotelOvernightFilterButtonActions";

const initialState = {
  appointments: [],
  searchText: "",
  filterBy: {
    all: true,
    attentionNeeded: true,
    unassignedPet: true,
  },
};

const hotelOverNightReducer = (state = initialState, action) => {
  switch (action.type) {
    case hotelOvernightActionTypes.CLEAR_HOTEL_OVERNIGHTS:
      return initialState;
    case hotelOvernightActionTypes.LOAD_HOTEL_OVERNIGHT_SUCCESS:
      return {
        ...state,
        appointments: action.overnight,
      };
    case hotelOverNightHeaderSearchActionTypes.SET_HOTEL_OVER_NIGHT_SEARCH:
      return {
        ...state,
        searchText: action.value,
      };
    case hotelOverNightHeaderSearchActionTypes.CLEAR_HOTEL_OVER_NIGHT_SEARCH:
      return {
        ...state,
        searchText: "",
      };
    case hotelOvernightFilterButtonActionTypes.FILTER_BY_All_OVERNIGHT:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          all: action.filterBtnStatus,
        },
      };
    case hotelOvernightFilterButtonActionTypes.FILTER_BY_ATTENTION_NEEDED_OVERNIGHT:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          attentionNeeded: action.filterBtnStatus,
        },
      };
    case hotelOvernightFilterButtonActionTypes.FILTER_BY_UNASSIGNED_PET_OVERNIGHT:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          unassignedPet: action.filterBtnStatus,
        },
      };
    default:
      return state;
  }
};

export default hotelOverNightReducer;
