import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  ddcRoomCapacityActionTypes,
  loadDdcCapacityFailure,
  loadDdcRoomCapacitySuccess,
} from "dux/ddcRoomCapacity/ddcRoomCapacityActions";

export function* loadDdcCapacity({ ddcCapacity }) {
  try {
    yield put(loadDdcRoomCapacitySuccess({ ddcCapacity }));
  } catch (error) {
    yield put(loadDdcCapacityFailure({ error }));
  }
}

function* watchDdcCapacity() {
  yield takeEvery(ddcRoomCapacityActionTypes.LOAD_HOTEL_DDC_ROOM_CAPACITY_REQUEST, loadDdcCapacity);
}

export default function* ddcCapacitySaga() {
  yield all([fork(watchDdcCapacity)]);
}
