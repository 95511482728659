import React from 'react';
import './styles.css';

export interface FormFieldCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: false;
  changed?: (checked: boolean) => void;
  label?: string;
}

export class FormFieldCheckbox extends React.Component<FormFieldCheckboxProps> {
  render() {
    const { changed, checked, label, ...otherProps } = this.props;
    return (
      <label className="sym-checkbox-label">
        <input
          {...otherProps}
          type="checkbox"
          className="sym-checkbox"
          onChange={changed ? (e) => changed(e.target.checked) : () => {}}
          checked={checked}
        />
        <span className="sym-checkbox-control" />
      </label>
    );
  }
}
