import moment from "moment";

/**
 * Responsible for creating a new object of unified formatted dates from an array
 * of dates of varying formats.
 *
 * @export uniformTimeFormat
 * @param {Array} listOfDates - an array of dates, array elements can be an Supported ISO 8601 string|Moment|Number|Date
 * @param {String} [format='YYYY-MM-DDTHH:mm:ss'] Optional moment usable formatted date
 * @param {Boolean} returnAsString - Optional if you want to return an object of strings instead of moment objects
 * @returns
 */
export default function uniformTimeFormat(
  listOfDates,
  format = "YYYY-MM-DDTHH:mm:ss",
  returnAsString = false
) {
  const obj = {};

  for (let i = 0; i < listOfDates.length; i++) {
    if (returnAsString) {
      obj[`date_${[i]}`] = moment(listOfDates[i]).format(format);
    } else {
      obj[`date_${[i]}`] = moment(moment(listOfDates[i]).format(format));
    }
  }
  return obj;
}
