import defaultImage from "assets/images/header-image-main.jpg";




/**
 * Returns a holiday themed header image based on the date of the holiday.
 * @return {*} - png file
 */
export const setHotelHeaderBanner = () => {
  return defaultImage;
};
