import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import { omit } from "lodash/fp";
import { getSourceId, getCurrentPet } from "../../../core/selectors/persistentSelectors";
import {
  createUpdateDeleteMedicationActionTypes,
  createPetMedicationSuccess,
  createPetMedicationFailure,
  createPetMedicationRequest,
  updatePetMedicationRequest,
  updatePetMedicationSuccess,
  updatePetMedicationFailure,
  deletePetMedicationRequest,
  deletePetMedicationFailure,
  deletePetMedicationSuccess,
} from "../actions/createUpdateDeleteMedicationActions";
import {
  postPetMedication,
  putPetMedication,
  deletePetMedication,
} from "core/services/associateWebProfilePet/createUpdateDeleteMedicationEndPoints";
import { getMedicationsByPet, getPetMedications } from "../medicationsSelector";
import { updatePetSuccess } from "../../../core/actionCreators/petsActionCreators";
import { NEW_MEDICATION_ID } from "../../newMedication/newMedicationConstants";
import { setMedications } from "../actions/medicationsActions";
import normalizeArrayByProperty from "../../../core/utils/normalizeUtils/normalizeArray";

/** ----------------------------------------------------------------------- **\
    CREATE PET MEDICATION SAGA
\** ----------------------------------------------------------------------- * */
/**
 * Saga to handle requests and responses for adding customer notes to the Web Service
 * @param { Number } customerKey - customerId of the current customer
 * @param { String } content - Note message
 */
function* onCreatePetMedication({ medication, petId, isFromBooking }) {
  try {
    const sourceId = yield select(getSourceId);
    yield put(createPetMedicationRequest());

    const response = yield call(postPetMedication, {
      petId,
      sourceId,
      data: medication,
    });
    const { result } = response.data;
    const petMedicationAdded = { [result.petMedicationId]: result };
    const petMedications = yield select(getMedicationsByPet, { petId });
    const updatedPetMedications = omit([NEW_MEDICATION_ID], petMedications);

    const petProfileMeds = yield select(getPetMedications, { petId });
    const petProfileMedAdded = { [petProfileMeds.length]: result };
    const addedPetProfileMed = { ...petProfileMeds, ...petProfileMedAdded };

    const addedMeds = { ...updatedPetMedications, ...petMedicationAdded }; // Removes old NEW_MEDICATION and adds the API response
    yield put(
      updatePetSuccess({
        pet: {
          petId: Number(petId),
          medications: Object.values(addedPetProfileMed),
        },
      }),
    );
    if (!isFromBooking) {
      yield put(
        setMedications({
          medications: addedMeds,
          petId,
        }),
      );
    }
    yield put(createPetMedicationSuccess());
  } catch (error) {
    yield put(createPetMedicationFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
    UPDATE PET MEDICATION SAGA
\** ----------------------------------------------------------------------- * */
/**
 * Saga to handle requests and responses for adding customer notes to the Web Service
 * @param { Number } customerKey - customerId of the current customer
 * @param { String } content - Note message
 */
function* onUpdatePetMedication({ medication, petId, isMedicationDetailsShowing, isFromBooking }) {
  try {
    const sourceId = yield select(getSourceId);
    yield put(updatePetMedicationRequest());
    const response = yield call(putPetMedication, {
      petId,
      sourceId,
      medicationId: medication.petMedicationId,
      data: medication,
    });
    const { result } = response.data;
    const petMedicationChanged = result;
    const petMedications = yield select(getMedicationsByPet, { petId });
    const stateMedication = { ...petMedicationChanged, isMedicationDetailsShowing }; // Add isMedShowing to maintain opened med
    const addedMeds = {
      ...petMedications,
      [petMedicationChanged.petMedicationId]: stateMedication,
    }; // Removes old NEW_MEDICATION and adds the API response
    yield put(
      updatePetSuccess({
        pet: {
          petId: Number(petId),
          medications: Object.values(addedMeds),
        },
      }),
    );
    const currentPet = yield select(getCurrentPet);
    const newPetMeds = yield select(getPetMedications, { petId: currentPet });
    if (currentPet === petId && !isFromBooking) {
      yield put(
        setMedications({
          medications: normalizeArrayByProperty(newPetMeds, "petMedicationId"),
          petId,
        }),
      );
    }

    yield put(updatePetMedicationSuccess());
  } catch (error) {
    yield put(updatePetMedicationFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
    DELETE PET MEDICATION SAGA
\** ----------------------------------------------------------------------- * */
/**
 * Saga to handle requests and responses for adding customer notes to the Web Service
 * @param { Number } customerKey - customerId of the current customer
 * @param { String } content - Note message
 */
function* onDeletePetMedication({ medicationId, petId }) {
  try {
    const sourceId = yield select(getSourceId);
    yield put(deletePetMedicationRequest());

    yield call(deletePetMedication, {
      medicationId,
      petId,
      sourceId,
    });
    const petMedications = yield select(getMedicationsByPet, { petId });
    const newMeds = omit([medicationId], petMedications);
    yield put(
      updatePetSuccess({
        pet: {
          petId: Number(petId),
          medications: Object.values(newMeds),
        },
      }),
    );
    const currentPet = yield select(getCurrentPet);
    const newPetMeds = yield select(getPetMedications, { petId: currentPet });
    if (currentPet === petId) {
      yield put(
        setMedications({
          medications: normalizeArrayByProperty(newPetMeds, "petMedicationId"),
          petId,
        }),
      );
    }
    yield put(deletePetMedicationSuccess());
  } catch (error) {
    yield put(deletePetMedicationFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
    WATCH FUNCTIONS
\** ----------------------------------------------------------------------- * */
function* watchCreatePetMedication() {
  yield takeEvery(
    createUpdateDeleteMedicationActionTypes.CREATE_PET_MEDICATION,
    onCreatePetMedication,
  );
}

function* watchUpdatePetMedication() {
  yield takeEvery(
    createUpdateDeleteMedicationActionTypes.UPDATE_PET_MEDICATION,
    onUpdatePetMedication,
  );
}

function* watchDeletePetMedication() {
  yield takeEvery(
    createUpdateDeleteMedicationActionTypes.DELETE_PET_MEDICATION,
    onDeletePetMedication,
  );
}

/** ----------------------------------------------------------------------- **\
    EXPORT SAGAS
\** ----------------------------------------------------------------------- * */
export default function* createUpdateDeleteMedicationSaga() {
  yield all([
    fork(watchCreatePetMedication),
    fork(watchUpdatePetMedication),
    fork(watchDeletePetMedication),
  ]);
}
