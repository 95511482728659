/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelCheckedOutColumnToggleActionTypes = {
  TOGGLE_HOTEL_CHECKED_OUT_COLUMN: "TOGGLE_HOTEL_CHECKED_OUT_COLUMN",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const toggleHotelCheckedOutColumn = ({ isHidden }) => ({
  type: hotelCheckedOutColumnToggleActionTypes.TOGGLE_HOTEL_CHECKED_OUT_COLUMN,
  isHidden,
});
