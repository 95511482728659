/**
 *
 * @param {object} state
 * @param {object} [state.modals]
 * @param {object} [state.modals.hotelOverbookingExceptionsHistoryModal]
 * @param {boolean} [state.modals.hotelOverbookingExceptionsHistoryModal.isHidden]
 * @returns - Overbooking Exceptions history modal is hidden or not
 */
export const selectHotelOverbookingExceptionsHistoryModalIsHidden = state =>
  state.modals?.hotelOverbookingExceptionsHistoryModal?.isHidden;

/**
 *
 * @param {object} state
 * @param {object} [state.modals]
 * @param {object} [state.modals.hotelOverbookingExceptionsHistoryModal]
 * @param {(string | null)} [state.modals.hotelOverbookingExceptionsHistoryModal.suspensionId]
 * @returns - Exception ID for selected record in hotelOverbookingExceptionsHistoryModal
 */
export const selectHotelOverbookingExceptionsHistoryModalSuspensionId = state =>
  state.modals?.hotelOverbookingExceptionsHistoryModal?.exceptionId;
