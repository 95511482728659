import React from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { PrintFoodAndMedLabel } from "./FoodAndMedLabel";
import "./FoodAndMedLabel.css";
import { selectChunkedLabelData, selectLabelData } from "./foodAndMedLabelsSelectors";

/**
 *  React view component that will build a list of Food and/or med labels for print
 *
 *  @summary /reports/labels
 *  @memberOf Views.Store
 *  @function
 *  @name FoodAndMedLabels
 *  @param {Object } props - props passed into the view component
 *  @returns {JSX.Element}
 *  @todo - optional todo
 *  @example
 *
 *  <FoodAndMedLabels />
 */
export const FoodAndMedLabels = props => {
  // Short circuit view if it isHidden
  const { isHidden } = props;
  if (isHidden) return null;

  // value props
  const { componentId, labelsData } = props;

  return (
    <LayoutBox padding="scale-0" id={componentId}>
      {labelsData.map((labelData, index) => {
        return <PrintFoodAndMedLabel key={index} labelData={labelData} />;
      })}
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the PrintFoodAndMedLabels
 *
 * @see {@link FoodAndMedLabels}
 * @summary located at /reports/labels
 * @memberOf Views.Store
 * @function
 * @name  PrintFoodAndMedLabels
 * @param { Object } props
 * @returns {JSX.Element|null}
 * @todo - optional todo
 *
 * @example <PrintFoodAndMedLabels />
 */
export const PrintFoodAndMedLabels = connect(state => {
  const labelsData = selectChunkedLabelData(state);

  return {
    componentId: "PrintFoodAndMedLabels",
    labelsData,
  };
})(FoodAndMedLabels);
