import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { loadSalonInfoWithServicesForSrcStoreSearch } from "core/actionCreators/salonActionCreator";
import { commonDispatchActions, searchFieldProps } from "dux/searchField/searchFieldCommonProps";
import {
  PET_PARENT_PROFILE_SEARCH_OPTIONS,
  STORE_SEARCH_DEFAULT_VALUE,
} from "core/constants/dropdownOptions";
import getSelectedSearchOption from "core/utils/searchUtils/getSelectedSearchOption";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { displayStoreHoursError } from "@/dux/storeHoursError/storeHoursErrorActions";
import { setStoreNumber } from "@/core/actionCreators/ui/web/generalActionCreators";
import SearchFieldComponent from "dux/searchField/searchFieldComponent";
import { routePaths } from "@/core/constants/routePaths";
import { setItineraryId } from "@/core/actionCreators/bookingActionCreators";

function getCommonSearchFieldProps({ onRedirect }) {
  return [
    (state, ownProps) => {
      const commonProps = searchFieldProps(state, ownProps);
      const { searchFieldType, options } = commonProps;
      const validateSearchField = options.validateSearchField;
      return {
        ...commonProps,

        // add any new use case props here
        customerKey: getCurrentCustomerKey(state),
        defaultValue: STORE_SEARCH_DEFAULT_VALUE,
        defaultSearchFieldType: "store",
        searchOptions: PET_PARENT_PROFILE_SEARCH_OPTIONS,
        selectedSearchOption: getSelectedSearchOption(
          searchFieldType,
          PET_PARENT_PROFILE_SEARCH_OPTIONS,
        ),
        validateSearchField,
      };
    },
    (dispatch, { router: { navigate } }) => {
      const dispatchCommonActions = commonDispatchActions(dispatch);

      return {
        ...dispatchCommonActions,
        dispatchOnSearch: ({ searchValue, customerKey }) => {
          dispatch(
            loadSalonInfoWithServicesForSrcStoreSearch({
              storeNumber: searchValue,

              onSuccess: () => {
                dispatch(setStoreNumber({ storeNumber: searchValue }));
                onRedirect({ navigate, customerKey, dispatch });
              },
              onError: () => {
                dispatch(displayStoreHoursError());
              },
            }),
          );
        },
      };
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      onSearch: (_, searchValue) => {
        const { customerKey } = stateProps;
        const { dispatchOnSearch } = dispatchProps;

        dispatchOnSearch({ searchValue, customerKey });
      },
    }),
  ];
}

export const SearchFieldBookSalonAppointment = withRouteProps(
  connect(
    ...getCommonSearchFieldProps({
      onRedirect: ({ navigate, customerKey, dispatch }) => {
        // Clear itineraryId to ensure that multiple appointment
        // are not attached to the same itinerary
        dispatch(setItineraryId(null));
        navigate(`/booking/${customerKey}/select-service`);
      },
    }),
  )(SearchFieldComponent),
);

export const SearchFieldBookHotelAppointment = withRouteProps(
  connect(
    ...getCommonSearchFieldProps({
      onRedirect: ({ navigate, customerKey }) => {
        navigate(`/booking/${customerKey}/select-service`);
      },
    }),
  )(SearchFieldComponent),
);

export const SearchFieldBookTrainingAppointment = withRouteProps(
  connect(
    ...getCommonSearchFieldProps({
      onRedirect: ({ navigate }) => {
        navigate(routePaths.SCHEDULE_TRAINING);
      },
    }),
  )(SearchFieldComponent),
);
