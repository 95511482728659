// cc: QuickViewAppointment#6;Display UI;Show the Appointment quickView

import { connect } from "react-redux";
import {
  getAppointmentQuickViewIsVisible,
  getAppointmentQuickViewCoordinates,
} from "../../../core/selectors/quickView/appointmentQickViewSelectors";

import QuickView from "../../common/quickView/QuickView";

const mapStateToProps = state => ({
  isVisible: getAppointmentQuickViewIsVisible(state),
  coordinates: getAppointmentQuickViewCoordinates(state),
});

export default connect(mapStateToProps)(QuickView);
