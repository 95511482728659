import React from "react";
import { connect } from "react-redux";
import {
  HotelBookingCartAddons,
  HotelCheckInOutAddons,
} from "dux/hotelAddons/HotelAddonsLIst";
import CommonModal from "web/common/modals/commonModal";

export const HotelCartAddonModalComponent = ({
  componentId,
  modalContent,
  showingModal,
  setShowingModal,
}) => {
  return (
    <CommonModal
      isHidden={!showingModal}
      componentId={componentId}
      onClose={() => {
        setShowingModal(false);
      }}
    >
      {modalContent}
    </CommonModal>
  );
};

// HOTEL CHECK IN/OUT ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInOutCartAddonModal = connect(
  (state, props) => {
    return {
      componentId: "AddAddOn-HotelCart_Button",
      modalContent: (
        <HotelCheckInOutAddons onClose={() => props?.setShowingModal(false)} />
      ),
      showingModal: props?.showingModal,
      setShowingModal: props?.setShowingModal,
    };
  },

  dispatch => {
    return {};
  },

  (mapProps, dispatchProps) => {
    const { componentId, modalContent, setShowingModal, showingModal } = mapProps;

    return {
      // control what props get passed to the view
      componentId,
      modalContent,
      setShowingModal,
      showingModal,
    };
  },
)(HotelCartAddonModalComponent);

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingCartAddonModal = connect(
  (state, { petId, showingModal, setShowingModal }) => {
    return {
      componentId: "AddAddOn-HotelCart_Button",
      modalContent: <HotelBookingCartAddons petId={petId} onClose={() => setShowingModal(false)} />,
      showingModal,
      setShowingModal,
    };
  },
)(HotelCartAddonModalComponent);
