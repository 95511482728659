import React, { useEffect } from "react";
import { connect } from "react-redux";

import { GET_ITINERARY_HISTORY_LIST, getItineraryHistoryList } from "./itineraryHistoryListActions";
import petsActionTypes from "../../core/actionTypes/petsActionTypes";
import customersActionTypes from "../../core/actionTypes/customersActionTypes";
import { createLoadingSelector } from "../../core/selectors/utils";
import { getCurrentCustomerKey } from "../../core/selectors/persistent/customer/customerSelectors";
import getIsHotelWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { engagementTypeIds } from "web/setSystemType/constants/setSystemTypeConstants";
import PetParentProfileColumn from "../../web/petParentProfile/PetParentProfileColumn";
import LoadingWrapper from "../../web/common/LoadingWrapper";
import { GET_HOTEL_ROOM_TYPES } from "../hotelRoomTypes/hotelRoomTypesActions";
import { ItineraryHistoryListPagination } from "../itineraryHistoryListPagination/itineraryHistoryListPagination";
import { HotelItineraryHistoryFilterButtons } from "../itineraryHistoryListFilterButtons/itineraryHistoryListFilterButtons";
import { selectFilteredItineraryHistoryList } from "./itineraryHistoryListSelectors";
import { getCustomer } from "@/core/selectors/entitiesSelector";

// Components
import { Layout, Text } from "@prism/psm-ui-components";

// MAIN COMPONENT ----------------------------------------------------------------------------------------------------
const ItineraryHistoryList = props => {
  const {
    isHidden,
    componentID,
    isCustomerLoaded,
    isLoading,
    disabled,
    loadItineraryHistory,
    hasItineraryHistory,
    diComp,
    compName,
  } = props;

  const FilterButtons = diComp[compName];

  useEffect(() => {
    if (isCustomerLoaded) loadItineraryHistory();
  }, [isCustomerLoaded]);

  if (!isHidden) {
    return (
      <Layout.Box id={componentID}>
        <PetParentProfileColumn
          title="Appointment (all pets)"
          disabled={disabled}
          noRightBorder
          shadow
        >
          <Layout.Stack space="stack-space-4">
            {/* Filter Pills */}
            <FilterButtons />

            <LoadingWrapper isLoading={isLoading}>
              {hasItineraryHistory && !isLoading && (
                // Paginated List
                <ItineraryHistoryListPagination />
              )}
              {!hasItineraryHistory && !isLoading && (
                <Text bold="600" size="text-size-lg">
                  No future or past appointments
                </Text>
              )}
            </LoadingWrapper>
          </Layout.Stack>
        </PetParentProfileColumn>
      </Layout.Box>
    );
  }

  return null;
};

// HOTEL ITINERARY HISTORY CONTAINER ---------------------------------------------------------------------------------
export const HotelItineraryHistoryColumn = connect(
  (state, { customerKey }) => ({
    isHidden: getIsHotelWorkflowFeatureFlagHidden(state),
    componentID: "HotelItineraryHistoryColumn",
    isCustomerLoaded: !!getCustomer(state, { customerKey }),
    isLoading: createLoadingSelector([
      GET_ITINERARY_HISTORY_LIST,
      GET_HOTEL_ROOM_TYPES,
      petsActionTypes.LOAD_PETS_BY_CUSTOMER,
      customersActionTypes.LOAD_CUSTOMER,
    ])(state),
    // Using customer key from URL params instead of getCurrentCustomerKey(state) because this component
    // renders before the key is updated in the store. When using the key from state and switching between
    // different pet parent profiles the API would get called with the previous customer key.
    customerKey,
    disabled: !getCurrentCustomerKey(state),
    hasItineraryHistory: selectFilteredItineraryHistoryList(state)?.length > 0,
    diComp: { filterButtons: HotelItineraryHistoryFilterButtons },
    compName: "filterButtons",
  }),

  dispatch => {
    const productFamilyID = `${engagementTypeIds.DAY_CAMP},${engagementTypeIds.DAY_CARE},${engagementTypeIds.OVERNIGHT_BOARDING}`;

    return {
      dispatchGetItineraryHistoryList: customerKey => {
        dispatch(getItineraryHistoryList({ customerKey, productFamilyID }));
      },
    };
  },

  (stateProps, dispatchProps) => {
    const {
      isHidden,
      componentID,
      isCustomerLoaded,
      isLoading,
      disabled,
      customerKey,
      hasItineraryHistory,
      diComp,
      compName,
    } = stateProps;
    const { dispatchGetItineraryHistoryList } = dispatchProps;

    return {
      isHidden,
      componentID,
      isCustomerLoaded,
      isLoading,
      disabled,
      hasItineraryHistory,
      diComp,
      compName,
      loadItineraryHistory: () => {
        dispatchGetItineraryHistoryList(customerKey);
      },
    };
  },
)(ItineraryHistoryList);
