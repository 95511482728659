import { isEmpty } from "lodash";

export const getAllIncludedProductNumbers = (addonsArg = []) => {
  const addons = [...addonsArg];
  const enhancedAddons = addons.filter(({ isEnhancedAddOn }) => isEnhancedAddOn);
  if (isEmpty(enhancedAddons)) return [];

  const allIncludedProducts = enhancedAddons?.reduce(
    (includedProducts, { includes }) => [...includedProducts, ...includes?.products],
    [],
  );
  return allIncludedProducts?.map(({ productNumber }) => Number(productNumber));
};
