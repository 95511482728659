import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";
import {
  getPetInCartMissingFoodFrequency,
  getPetInCartMissingMedFrequency,
} from "./selectors/hotelBookingSelectors";
import ErrorText from "../../web/common/ErrorText";
import ExpiredIcon from "../../assets/icons/expired.svg";
import SplitLineWithMessage from "../../web/common/SplitLineWithMessage";
import { color } from "../../web/common/styles/theme";

const mapStateToProps = state => {
  const petsMissingMedFreq = getPetInCartMissingMedFrequency(state);
  const petsMissingFoodFreq = getPetInCartMissingFoodFrequency(state);
  const missingMedString = petsMissingMedFreq
    ? `Medication frequency is missing for ${petsMissingMedFreq}.\n`
    : "";
  const missingFoodString = petsMissingFoodFreq
    ? `Feeding frequency is missing for ${petsMissingFoodFreq}.\n`
    : "";
  return {
    isHidden: isEmpty(petsMissingMedFreq) && isEmpty(petsMissingFoodFreq),
    componentID: "hotelBooking-hotelBookingErrorMessage",
    message: SplitLineWithMessage({
      string: `${missingMedString} ${missingFoodString}Please review before booking.`,
      delimiter: "\n",
    }),
    icon: ExpiredIcon,
    styleObject: { color: color.errorRed, marginTop: "0.5em", display: "flex" },
  };
};

export default connect(mapStateToProps)(ErrorText);
