import dashboardActionTypes from "../../../actionTypes/ui/web/dashboardActionTypes";

export const showDashboardModal = modalType => ({
  type: dashboardActionTypes.SHOW_DASHBOARD_MODAL,
  modalType
});

export const hideDashboardModal = () => ({
  type: dashboardActionTypes.HIDE_DASHBOARD_MODAL
});

export const setNewAppointmentData = ({ associateId, startDateTime }) => ({
  type: dashboardActionTypes.SET_NEW_APPOINTMENT_DATA,
  payload: {
    associateId,
    startDateTime
  }
});

export const clearNewAppointmentData = () => ({
  type: dashboardActionTypes.CLEAR_NEW_APPOINTMENT_DATA
});

export default {
  hideDashboardModal,
  showDashboardModal,
  setNewAppointmentData,
  clearNewAppointmentData
};
