import React from "react";
import { connect } from "react-redux";
import { Text, Layout } from "@prism/psm-ui-components";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getHotelItineraryTotalPrice } from "dux/hotelItinerary/hotelItinerarySelectors";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import { GET_HOTEL_ITINERARY } from "dux/hotelItinerary/hotelItineraryActions";
import { selectCartHotelTotalPrice } from "dux/servicesCartHotel/servicesCartHotelSelectors";

const InvoiceGeneratedTotalPriceComponent = props => {
  const { isHidden, componentId, totalPrice, children } = props;

  if (!isHidden) {
    return (
      <Layout.Box padding="box-padding-4" id={componentId}>
        <Layout.Stack space="stack-space-4">
          <Layout.Cluster space="cluster-space-2" justify="space-between">
            <Text>Total Price:</Text>
            <Text>{totalPrice}</Text>
          </Layout.Cluster>
          {children}
        </Layout.Stack>
      </Layout.Box>
    );
  }

  return null;
};

export const HotelBookingInvoiceGeneratedTotalPrice = connect((state, ownProps) => {
  const appointmentPrice = selectCartHotelTotalPrice(state);

  return {
    componentId: "bookingHotel__HotelBookingInvoiceGeneratedTotalPriceComponent",
    isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
    checkBoxLabel: "Invoice Generated",
    totalPrice: `$${appointmentPrice && appointmentPrice?.toFixed(2)}`,
  };
})(InvoiceGeneratedTotalPriceComponent);

export const HotelCheckInOutInvoiceGeneratedTotalPrice = connect((state, ownProps) => {
  const totalPrice = getHotelItineraryTotalPrice(state);
  const isLoading = createLoadingSelector([
    customersActionTypes.LOAD_CUSTOMER,
    GET_HOTEL_ITINERARY,
  ])(state);
  return {
    componentId: "HotelCheckInOutInvoiceGeneratedTotalPrice",
    isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
    checkBoxLabel: "Invoice Generated",
    totalPrice: isLoading ? `loading Price` : `$${totalPrice && totalPrice?.toFixed(2)}`,
  };
})(InvoiceGeneratedTotalPriceComponent);
