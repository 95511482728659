import { connect } from "react-redux";
import { GET_BULK_PACKAGE_ADDONS } from "./bulkPackageAddOnsActions";
import { createLoadingSelector } from "../../../../core/selectors/utils";
import getIsBGMWorkflowFeatureFlagHidden from "../../../../web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import {
  getAddOnsByBundleConfigId,
  getSelectedPackageOffering,
} from "../bulkPackageOfferingsSelectors";
import getFTCOWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import BulkPackageOfferingsAddOnsComponent from "../../../_components/bgm/bulkPackageOfferings/BulkPackageOfferingsAddOnsComponent";

const mapStateToProps = state => {
  // showing/hiding the add-ons depends on feature flags, the selected package state and data loaded
  // the last check on the data occurs at the component level
  const isFeatureHidden =
    getIsBGMWorkflowFeatureFlagHidden(state) && getFTCOWorkflowFeatureFlagHidden(state);

  // Is BGM Package selected, NOT FTCO
  const selectedOffering = getSelectedPackageOffering(state);

  return {
    isHidden: !isFeatureHidden && !selectedOffering?.hasAddOns,
    isLoading: createLoadingSelector([GET_BULK_PACKAGE_ADDONS])(state),
    message: "",
    componentID: "bulk-package-offerings-add-ons",
    title: "Add-ons",
    items: getAddOnsByBundleConfigId(state),
  };
};

export default connect(mapStateToProps)(BulkPackageOfferingsAddOnsComponent);
