import { put, call, all, takeLatest } from "redux-saga/effects";
import { fetchFrequencies } from "@/core/services/associateWebBff/frequenciesEndpoint";
import {
  GET_FREQUENCY_OPTIONS,
  getFrequencyOptionsRequest,
  getFrequencyOptionsSuccess,
  getFrequencyOptionsFailure,
} from "./frequencyOptionsActions";

function* onGetFrequencyOptions() {
  try {
    yield put(getFrequencyOptionsRequest());
    const response = yield call(fetchFrequencies);
    const payload = response?.data?.result;

    yield put(getFrequencyOptionsSuccess(payload));
  } catch (error) {
    yield put(getFrequencyOptionsFailure(error));
  }
}

function* watchGetFrequencyOptions() {
  yield takeLatest(GET_FREQUENCY_OPTIONS, onGetFrequencyOptions);
}

export default function* frequencyOptionsSaga() {
  yield all([watchGetFrequencyOptions()]);
}
