import { history } from "@/dux/utils/browser/browserHistory";
/**
 * Responsible for taking a route and determining if that route is in the path name
 *
 * @exports isInRoute
 * @param {string} route
 * @returns {boolean}
 */
export default function isInRoute(route) {
  return history.location.pathname.includes(route);
}
