import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import momentTz from "moment-timezone";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { TextPassage } from "@petsmart-ui/sparky";
import { Form } from "@prism/psm-ui-components";
import LoadingWrapper from "@/web/common/LoadingWrapper";

// Actions
import { getHotelRoomAvailability } from "dux/hotelRoomAvailability/hotelRoomAvailabilityActions";
import { patchHotelItineraryRoom } from "@/dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";

// Selectors
import {
  getStoreTimeZoneForHotelItinerary,
  selectCurrentHotelEngagement,
  selectIsCheckInTodayOrPast,
  selectPetsWithEngagementsByHostPetId,
  selectAllPetServicesMatch,
  getHotelEngagementByEngagementId,
  selectIsCheckOutInPast,
} from "@/dux/hotelEngagements/hotelEngagementSelectors";
import { getCheckInOutPetRoom } from "@/dux/_components/checkInOutAppointmentSummary/checkInOutAppointmentSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { getIsSRCAgent } from "@/core/selectors/persistentSelectors";
import { getPetHotelEngagements } from "@/dux/hotelEngagements/hotelEngagementSelectors";
import {
  getRoomAvailabilityOptions,
  getRoomAvailabilityIsLoading,
} from "@/dux/hotelRoomAvailability/hotelRoomAvailabilitySelectors";
import {
  getHotelItinerary,
  selectIsReservationDisabled,
} from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { getStoreTimeZoneOffset } from "@/core/selectors/entitiesSelector";

// Utils
import { formatUTCDateWithOffset } from "@/core/utils/dateUtils/formatDateTime";

// Constants
import { COLORS } from "@/layout/colorsList";
import { roomMsgs } from "./roomSelectionConstants";
import { setSelectDate } from "../selectedDate/selectedDateActions";

/**
 * React view component for Room Selection
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name RoomSelection
 * @param {Object} props
 * @param {String} props.componentId
 * @param {String} props.errorMessage
 * @param {Function} props.handleOnChange
 * @param {Boolean} props.isDisabled
 * @param {Boolean} props.isHidden
 * @param {Function} props.loadData
 * @param {String} props.roomAvailabilityId
 * @param {Array} props.selectOptions
 * @param {String} props.selectValue
 * @param {String} props.title
 * @returns {JSX.Element|null}
 * @example const container = connect()(RoomSelection)
 */
export const RoomSelection = props => {
  const {
    componentId,
    roomMessage,
    isError,
    handleOnChange,
    isDisabled,
    isHidden,
    loadData,
    selectOptions,
    selectValue,
    title,
    isLoading,
  } = props;

  if (isHidden) return null;

  const handleLoadData = () => {
    if (isLoading || isDisabled) return;

    loadData();
  };

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LoadingWrapper isLoading={isLoading}>
        <LayoutStack>
          <LayoutCluster>
            <TextPassage size="lg">
              <span>{title}</span>
            </TextPassage>
            <Form.Field.Select
              id={`${componentId}__roomNumber`}
              isDisabled={isDisabled}
              value={selectValue}
              onChange={({ value }) => handleOnChange(value)}
              options={selectOptions}
              legacy
              onFocus={handleLoadData}
            />
          </LayoutCluster>
          {Boolean(roomMessage) && (
            <TextPassage style={{ color: isError ? COLORS.red : COLORS.black }}>
              <span>{roomMessage}</span>
            </TextPassage>
          )}
        </LayoutStack>
      </LoadingWrapper>
    </LayoutBox>
  );
};

/**
 * Helper to set the given dates time to 12am and format in UTC
 * @function
 * @name formatStartForAPI
 * @param {String} start - date
 * @param {Number} storeOffset - UTC offset for current store
 * @returns {string}
 */
export const formatStartForAPI = (start, storeOffset) => {
  // Get rooms available for current date from 12am
  const midnight = moment("12:00 AM", "HH:mm a");
  return formatUTCDateWithOffset(
    moment(start).set({
      hour: midnight.hour(),
      minute: midnight.minute(),
      second: midnight.second(),
    }),
    storeOffset,
  );
};

/**
 * Redux Connect function for hotel room selection
 * @summary Located on the hotel check-in, check-out pages
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelRoomSelection
 * @returns {JSX.Element|null}
 * @example <HotelRoomSelection />
 */
export const HotelRoomSelection = connect(
  (state, { roomTypeBucketId }) => {
    const petId = getCurrentPet(state);
    // Engagement for a particular day
    const selectedDate = getSelectedDate(state);
    const engagement = selectCurrentHotelEngagement(state, { petId, date: selectedDate });
    const engagementId = engagement?.engagementId;
    const currentRoomDetails = getCheckInOutPetRoom(state, { engagementId });
    const customerKey = getCurrentCustomerKey(state);
    const isSRCAgent = getIsSRCAgent(state);
    const isReservationDisabled = selectIsReservationDisabled(state);
    const isEngagementStartDateSameOrBeforeToday = selectIsCheckInTodayOrPast(state, { petId });
    const isCheckOutInPast = selectIsCheckOutInPast(state, { petId });
    const allEngagementServiceIdsMatch = selectAllPetServicesMatch(state, { petId });

    const storeTimeZone = getStoreTimeZoneForHotelItinerary(state);

    // Pet Engagements for current pet
    const petEngagements = getPetHotelEngagements(state, { petId });

    // For same day or overnight - at least one engagement is available
    // hostPetId value is either current petId or host pet - petId
    // hostPetId will be same on all engagements for a given pet
    const currentHostPetId = petEngagements[0]?.hostPetId;

    const currentPetToUpdate = [{ petId, engagements: petEngagements }];

    // Fetch other room sharing pet/pets and their engagements by host petId
    const roomSharingPetsToUpdate = selectPetsWithEngagementsByHostPetId({
      hostPetId: currentHostPetId,
      petId,
    })(state);

    // Aggregate pet/pets to update
    const petsToUpdate =
      roomSharingPetsToUpdate.length > 0
        ? [...currentPetToUpdate, ...roomSharingPetsToUpdate]
        : currentPetToUpdate;

    const selectValue = currentRoomDetails?.roomNumber || "";
    const isDisabled =
      isSRCAgent ||
      isReservationDisabled ||
      !isEngagementStartDateSameOrBeforeToday ||
      !allEngagementServiceIdsMatch ||
      isCheckOutInPast;

    // Date range for room selection is today until check out
    const roomRangeStart = momentTz.tz(undefined, storeTimeZone).format();
    const roomRangeEnd = petEngagements[petEngagements?.length - 1]?.endDatetime;

    const storeNumber = getHotelItinerary(state)?.storeNumber;

    return {
      componentId: "HotelRoomSelection",
      customerKey,
      isDisabled,
      isHidden: false,
      petId,
      petsToUpdate,
      selectOptions: getRoomAvailabilityOptions(
        roomTypeBucketId,
        roomRangeStart,
        roomRangeEnd,
      )(state),
      selectValue,
      storeTimeZone,
      title: "Room Number:",
      roomRangeStart,
      roomRangeEnd,
      storeOffset: getStoreTimeZoneOffset(state, { storeNumber }),
      isLoading: getRoomAvailabilityIsLoading(
        roomTypeBucketId,
        roomRangeStart,
        roomRangeEnd,
      )(state),
    };
  },
  (dispatch, { roomTypeBucketId }) => {
    return {
      getRoomData: ({ petId, customerKey, startDateTime, endDateTime, formatStartForAPI }) => {
        // Fetch Room Data based on roomTypeBucketId
        dispatch(
          getHotelRoomAvailability({
            petId,
            customerKey,
            roomTypeBucketId,
            startDateTime,
            endDateTime,
            formatStartForAPI,
          }),
        );
      },
      setHotelRoomNumber: ({ data }) => {
        dispatch(patchHotelItineraryRoom({ data }));
      },
      setSelectedDate: () => dispatch(setSelectDate()),
    };
  },
  (mapStateProps, mapDispatchProps, ownProps) => {
    const {
      componentId,
      customerKey,
      isDisabled,
      isHidden,
      petId,
      petsToUpdate,
      selectOptions,
      selectValue,
      storeTimeZone,
      title,
      roomRangeStart,
      roomRangeEnd,
      storeOffset,
      isLoading,
    } = mapStateProps;

    const { getRoomData, setHotelRoomNumber, setSelectedDate } = mapDispatchProps;

    const { roomTypeBucketId } = ownProps;

    return {
      // State Props
      componentId,
      isDisabled,
      isHidden,
      selectOptions,
      selectValue,
      title,
      isLoading,

      // Dispatch Props
      loadData: () => {
        getRoomData({
          petId,
          customerKey,
          startDateTime: roomRangeStart,
          endDateTime: roomRangeEnd,
          formatStartForAPI: start => formatStartForAPI(start, storeOffset),
        });
      },
      handleOnChange: value => {
        if (isDisabled) return;

        // Current or all pets to updates
        const pets = petsToUpdate.map(pet => {
          return {
            petKey: pet?.petId,
            engagements: pet?.engagements
              ?.map(({ engagementId, startDatetime, metadata }) => {
                const startDate = momentTz.tz(startDatetime, storeTimeZone);
                const isInPast = startDate.isBefore(roomRangeStart, "day");
                const isSameRoomType =
                  metadata?.room?.roomTypeBucketId?.toString() === roomTypeBucketId?.toString();
                const isInPastAndHasRoom = isInPast && !!metadata?.room?.roomNumber;

                if (isInPastAndHasRoom || !isSameRoomType) return {};

                return {
                  engagementId,
                  metadata: {
                    room: {
                      roomTypeBucketId,
                      roomNumber: value,
                      isUnassigned: false,
                    },
                  },
                };
              })
              .filter(engagement => !!engagement?.engagementId),
          };
        });

        setHotelRoomNumber({ data: { pets } });
        setSelectedDate();
      },
    };
  },
)(RoomSelection);

/**
 * Redux Connect function for hotel room selection in the primary service modal
 * @summary Located on the hotel check-in, check-out pages
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelPrimaryServiceModalRoomSelection
 * @returns {JSX.Element|null}
 * @example <HotelPrimaryServiceModalRoomSelection />
 */
export const HotelPrimaryServiceModalRoomSelection = connect(
  (state, { roomNumber, roomTypeBucketId, engagementId, isRoomValid, isUpdatingHostService }) => {
    const petId = getCurrentPet(state);
    const customerKey = getCurrentCustomerKey(state);

    const isSRCAgent = getIsSRCAgent(state);
    const isReservationDisabled = selectIsReservationDisabled(state);
    const isEngagementStartDateSameOrBeforeToday = selectIsCheckInTodayOrPast(state, { petId });

    const isDisabled =
      isSRCAgent ||
      isReservationDisabled ||
      !isEngagementStartDateSameOrBeforeToday ||
      isUpdatingHostService;

    const engagement = getHotelEngagementByEngagementId(state, { engagementId });
    const startDateTime = engagement?.startDatetime;
    const endDateTime = engagement?.endDatetime;
    const selectOptions = getRoomAvailabilityOptions(
      roomTypeBucketId,
      startDateTime,
      endDateTime,
    )(state);

    const storeNumber = getHotelItinerary(state)?.storeNumber;

    const invalidError = !isRoomValid && roomMsgs.INVALID;
    const isUpdatingHostServiceMsg = isUpdatingHostService && roomMsgs.UPDATING_HOST_SERVICE;

    return {
      componentId: "HotelPrimaryServiceModalRoomSelection",
      customerKey,
      isDisabled,
      petId,
      selectOptions,
      selectValue: roomNumber ?? null,
      title: "Room Number:",
      isLoading: getRoomAvailabilityIsLoading(roomTypeBucketId, startDateTime, endDateTime)(state),
      startDateTime,
      endDateTime,
      storeOffset: getStoreTimeZoneOffset(state, { storeNumber }),
      isError: !isUpdatingHostService && !isRoomValid,
      roomMessage: isUpdatingHostServiceMsg || invalidError,
    };
  },
  dispatch => {
    return {
      getRoomData: ({
        petId,
        customerKey,
        roomTypeBucketId,
        startDateTime,
        endDateTime,
        formatStartForAPI,
      }) => {
        // Fetch Room Data based on roomTypeBucketId
        dispatch(
          getHotelRoomAvailability({
            petId,
            customerKey,
            roomTypeBucketId,
            startDateTime,
            endDateTime,
            formatStartForAPI,
          }),
        );
      },
    };
  },
  (mapStateProps, mapDispatchProps, ownProps) => {
    const {
      componentId,
      isDisabled,
      isHidden,
      selectOptions,
      selectValue,
      title,
      petId,
      customerKey,
      isLoading,
      startDateTime,
      endDateTime,
      storeOffset,
      roomMessage,
      isError,
    } = mapStateProps;

    const { getRoomData } = mapDispatchProps;
    const { roomTypeBucketId, handleOnChange } = ownProps;

    return {
      // State Props
      componentId,
      isDisabled,
      isHidden,
      selectOptions,
      selectValue,
      title,
      handleOnChange,
      roomMessage,
      isError,
      isLoading,

      // Dispatch Props
      loadData: () => {
        getRoomData({
          petId,
          customerKey,
          roomTypeBucketId,
          startDateTime,
          endDateTime,
          formatStartForAPI: start => formatStartForAPI(start, storeOffset),
        });
      },
    };
  },
)(RoomSelection);
