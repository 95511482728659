/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_TRAINING_CLASS_AVAILABILITY = "GET_TRAINING_CLASS_AVAILABILITY";
export const GET_TRAINING_CLASS_AVAILABILITY_REQUEST = "GET_TRAINING_CLASS_AVAILABILITY_REQUEST";
export const GET_TRAINING_CLASS_AVAILABILITY_SUCCESS = "GET_TRAINING_CLASS_AVAILABILITY_SUCCESS";
export const GET_TRAINING_CLASS_AVAILABILITY_FAILURE = "GET_TRAINING_CLASS_AVAILABILITY_FAILURE";

export const GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE =
  "GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE";
export const GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE_REQUEST =
  "GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE_REQUEST";
export const GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE_SUCCESS =
  "GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE_SUCCESS";
export const GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE_FAILURE =
  "GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getTrainingClassAvailability = (payload) => ({
  type: GET_TRAINING_CLASS_AVAILABILITY,
  petServiceId: payload.petServiceId,
  fromDate: payload.fromDate,
  toDate: payload.toDate,
  storeNumber: payload.storeNumber,
  petId: payload.petId,
  onComplete: payload.onComplete,
});

export const getTrainingClassAvailabilityRequest = () => ({
  type: GET_TRAINING_CLASS_AVAILABILITY_REQUEST,
});

export const getTrainingClassAvailabilitySuccess = (payload) => ({
  type: GET_TRAINING_CLASS_AVAILABILITY_SUCCESS,
  payload,
});

export const getTrainingClassAvailabilityFailure = (error) => ({
  type: GET_TRAINING_CLASS_AVAILABILITY_FAILURE,
  error,
});

export const getTrainingClassAvailabilityIfActiveBundleIsAvailable = () => ({
  type: GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE,
});

export const getTrainingClassAvailabilityIfActiveBundleIsAvailableRequest = () => ({
  type: GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE_REQUEST,
});

export const getTrainingClassAvailabilityIfActiveBundleIsAvailableSuccess = () => ({
  type: GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE_SUCCESS,
});

export const getTrainingClassAvailabilityIfActiveBundleIsAvailableFailure = (error) => ({
  type: GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE_FAILURE,
  error,
});
