import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import momentTz from "moment-timezone";
import isEmpty from "lodash/isEmpty";

// Components
import { Button, Heading, TextPassage, SelectField } from "@petsmart-ui/sparky";
import { HotelCheckInOutServiceDayOptions } from "@/dux/primaryServiceDayOptions/PrimaryServiceDayOptions";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutSwitcher } from "@/layout/switcher/Switcher";
import { LayoutCluster } from "@/layout/culster/Cluster";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { HotelPrimaryServiceDropdown } from "@/dux/primaryServiceSelection/PrimaryServiceDropdown";

// Actions
import { LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING } from "@/web/services/hotelServices/actions/petServicesAtHotel";
import {
  PATCH_HOTEL_PRIMARY_SERVICE_CHANGE,
  patchHotelPrimaryServiceChange,
} from "@/dux/hotelPrimaryServiceChange/hotelPrimaryServiceChangeActions";
import { hideCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";

// Selectors
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import {
  getPetHotelEngagements,
  selectIsCheckInTodayOrPast,
  selectIsUpdatingHostService,
  selectPetHotelEngagementServices,
  selectPetsWithEngagementsByHostPetId,
} from "@/dux/hotelEngagements/hotelEngagementSelectors";
import {
  getHotelItinerary,
  selectIsReservationDisabled,
} from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { getStoreTimeZone } from "@/core/selectors/entitiesSelector";
import { getCurrentPet, getIsSRCAgent } from "@/core/selectors/persistentSelectors";
import { selectIsRoomValid } from "@/dux/hotelRoomAvailability/hotelRoomAvailabilitySelectors";

// Utils
import { createLoadingSelector } from "core/selectors/utils";
import { useForm } from "@/dux/utils/formUtils/useForm";
import {
  buildEngagementsForPrimaryServicePatch,
  getUpdatedEngagementIds,
} from "@/core/utils/hotelEngagementUtils/buildEngagementsForPrimaryServicePatch";
import { getEngagementFromArrayById } from "@/core/utils/hotelEngagementUtils/hotelEngagementUtils";

// Constants
import { COLORS } from "@/layout/colorsList";
import { ROOM_ASSIGNMENT_DAY_CHANGE, serviceFrequencyTypes } from "./changePrimaryServiceConstants";

const { TODAY, TODAY_FORWARD, ALL, MANUAL } = serviceFrequencyTypes;

/**
 *  Util to return engagementId's that should be updated based off of the given service frequency
 *  @memberOf Utils.Engagement
 *  @function
 *  @name engagementIdsFromApplyChangesTo
 *  @param {Object[]} engagementServices - pet engagement objects
 *  @param {string} applyChangesTo - the service frequency value
 *  @param {moment} today - a moment object with today's date
 *  @returns {string[]} list of engagementId's
 *  @example engagementIdsFromApplyChangesTo(engagementServices, applyChangesTo, today)
 */
export const engagementIdsFromApplyChangesTo = (engagementServices = [], applyChangesTo, today) => {
  const engagementIdsFn = ({ engagementId }) => engagementId;

  switch (applyChangesTo) {
    case ALL:
      return engagementServices.map(engagementIdsFn);
    case TODAY:
      return engagementServices
        .filter(({ date }) => moment(date).isSame(today, "day"))
        .map(engagementIdsFn);
    case TODAY_FORWARD:
      return engagementServices
        .filter(({ date }) => moment(date).isSameOrAfter(today, "day"))
        .map(engagementIdsFn);
    default:
      return [];
  }
};

/**
 *  Helper to get pending rooms for sharing pets for each days room being updated
 *  @memberOf helpers.hotel.itinerary
 *  @function
 *  @name getPendingRoomsForRoomSharingPets
 *  @param {Object} arg
 *  @param {String} arg.hostPetId
 *  @param {[]} arg.applyToEngagements
 *  @param {Object} arg.pendingRooms
 *  @param {Object[]} arg.petEngagements
 *  @param {Function} arg.getRoomSharingPetsToUpdate
 *  @returns {Object} updated pendingRooms
 */
export const getPendingRoomsForRoomSharingPets = ({
  hostPetId = "",
  applyToEngagements = [],
  pendingRooms = {},
  petEngagements = [],
  getRoomSharingPetsToUpdate = () => [],
  storeTimeZone = "UTC",
}) =>
  Object.entries(pendingRooms)?.reduce((pets, [key, room]) => {
    const currentPetEng = getEngagementFromArrayById(petEngagements, key);
    const isHostUpdating =
      !!hostPetId && !!applyToEngagements?.find(engId => engId === Number(key));
    const hostId = isHostUpdating ? hostPetId : currentPetEng?.hostPetId;
    const roomSharingPetsToUpdate = getRoomSharingPetsToUpdate(Number(hostId));

    const engStart = momentTz.tz(currentPetEng?.startDatetime, storeTimeZone);
    roomSharingPetsToUpdate?.map(pet => {
      const engForSameDay = pet?.engagements?.find(eng =>
        momentTz.tz(eng?.startDatetime, storeTimeZone).isSame(engStart, "day"),
      );

      if (!!engForSameDay)
        pets[pet?.petId] = {
          pendingRooms: { ...pets[pet?.petId]?.pendingRooms, [engForSameDay?.engagementId]: room },
          engagements: pet?.engagements,
        };
    });

    return pets;
  }, {});

/**
 *  Helper to check if save button should be enabled
 *  @memberOf helpers.hotel.itinerary
 *  @function
 *  @name isSaveDisabled
 *  @param {Object} arg
 *  @param {string} arg.petServiceId
 *  @param {string} arg.roomTypeBucketId
 *  @param {Array} arg.applyToEngagements
 *  @param {Object} arg.pendingRooms
 *  @param {Function} arg.checkIsRoomValid
 *  @param {Object[]} arg.petEngagements
 *  @param {Boolean} arg.areRoomsDisabled
 *  @returns {boolean}
 */
export const isSaveDisabled = ({
  roomTypeBucketId,
  applyToEngagements,
  pendingRooms,
  checkIsRoomValid,
  petEngagements,
  areRoomsDisabled,
}) => {
  const isServiceUpdated = !!roomTypeBucketId;
  const engsWithUpdatedService = isServiceUpdated ? applyToEngagements : [];

  // Days are checked for service to be updated but no service has been chosen yet
  if (!isServiceUpdated && applyToEngagements?.length) return true;

  // Get all engagementIds where room or service were updated in order to validate the room selections
  const updatedEngagements = getUpdatedEngagementIds({
    applyToEngagements: engsWithUpdatedService,
    pendingRooms,
  });

  // No changes, button is disabled
  if (updatedEngagements.length === 0) return true;

  const allRoomsValid = updatedEngagements?.every(engagementId => {
    const engagement = getEngagementFromArrayById(petEngagements, engagementId);
    const room = engagement?.metadata?.room;
    const isDayChecked = !!engsWithUpdatedService?.find(id => id === engagementId);
    const isBucketUpdated = isDayChecked && roomTypeBucketId !== room?.roomTypeBucketId;
    const roomNumber = pendingRooms[engagementId] ?? (isBucketUpdated ? null : room?.roomNumber);
    const noRoomSelected = roomNumber === null || roomNumber === undefined;

    if (areRoomsDisabled) return noRoomSelected;

    const isRoomUpdated = roomNumber !== room?.roomNumber;
    if (!isServiceUpdated && !isRoomUpdated) return false;

    const serviceChangedAndRoomCleared = isDayChecked && isServiceUpdated && noRoomSelected;

    return (
      serviceChangedAndRoomCleared ||
      checkIsRoomValid({
        roomTypeBucketId: isDayChecked ? roomTypeBucketId : room?.roomTypeBucketId,
        roomNumber: pendingRooms[engagementId] ?? room?.roomNumber,
        startDateTime: engagement?.startDatetime,
        endDateTime: engagement?.endDatetime,
      })
    );
  });

  return !allRoomsValid;
};
/**
 *
 * @param {Object} arg
 * @param {Object} arg.pendingRooms { enagementId: string, roomNumber: number }
 * @param {array} arg.petEngagements engagements[]
 * @param {Moment} arg.today Moment object for the current day
 * @param {string} arg.storeTimeZone current stores time zone
 * @returns {boolean}
 */
export const isPastRoomsUpdated = ({ pendingRooms, petEngagements, today, storeTimeZone }) => {
  const pendingEngagements = Object.keys(pendingRooms);
  const engagementsInThePast = pendingEngagements.filter(pendingEngagementId => {
    const petEngagement = petEngagements.find(
      ({ engagementId }) => engagementId === Number(pendingEngagementId),
    );

    const startDate = momentTz.tz(petEngagement?.startDatetime, storeTimeZone);
    return startDate.isBefore(today, "day");
  });
  return engagementsInThePast.length;
};

/**
 *  React view component that is used to update a primary service in check in flow
 *  @summary Used in CheckInOutAppointmentSummary and ServiceNameAndSelection
 *  @memberOf Views.Pet
 *  @function
 *  @name ChangePrimaryServiceComponent
 *  @param {Object} props
 *  @param {String} props.componentId
 *  @param {Array} props.daysForRoomAssignment
 *  @param {String} props.header
 *  @param {String} props.subHeader
 *  @param {Object} props.initialFormValues
 *  @param {Boolean} props.isLoading
 *  @param {Function} props.onClose
 *  @param {Function} props.onSave
 *  @param {String} props.saveLabel
 *  @param {Function} props.onApplyChangesToChange
 *  @returns {JSX.Element || null}
 *
 */
const ChangePrimaryServiceModal = ({
  componentId,
  daysForRoomAssignment,
  header,
  subHeader,
  initialFormValues,
  isLoading,
  onClose,
  onSave,
  onApplyChangesToChange,
  getSaveButtonLabel = () => "Save",
  isSaveButtonDisabled = () => false,
  isUpdatingPastRooms,
}) => {
  const { values, setFormValues } = useForm(initialFormValues);
  const [applyToEngagements, setApplyToEngagements] = useState([]);
  const [pendingRooms, setPendingRooms] = useState({});
  const [updatingPastRooms, setUpdatingPastRooms] = useState(false);
  const { applyChangesTo, petServiceId, hostPetId, roomTypeBucketId, roomType } = values;

  const isSaveBtnDisabled = useMemo(
    () => isSaveButtonDisabled({ roomTypeBucketId, applyToEngagements, pendingRooms }),
    [roomTypeBucketId, applyToEngagements, pendingRooms],
  );

  const isDayChecked = engagementId => applyToEngagements?.indexOf(engagementId) >= 0;
  const handleServiceCheckboxClick = (engagementId, checked) => {
    const engagements = applyToEngagements;
    const newApplyToEngagements = checked
      ? [...engagements, engagementId]
      : engagements.filter(id => id !== engagementId);

    setFormValues({ ...values, applyChangesTo: MANUAL });
    setApplyToEngagements(newApplyToEngagements);
  };

  const handleApplyChangesToChange = e => {
    const value = e?.target?.value;
    const isManual = value === MANUAL;

    setFormValues({ ...values, applyChangesTo: value });
    if (!isManual) setApplyToEngagements(onApplyChangesToChange(value));
  };

  const handleRoomChangeForEngagement = (engagementId, roomNumber) => {
    const newPendingRooms = { ...pendingRooms, [engagementId]: roomNumber };
    setUpdatingPastRooms(isUpdatingPastRooms(newPendingRooms));
    setPendingRooms(newPendingRooms);
  };

  useEffect(() => {
    // Reset form after API call finishes loading
    if (!isLoading) {
      setFormValues(initialFormValues);
      setApplyToEngagements([]);
      setPendingRooms({});
    }
  }, [isLoading]);

  const handleOnChange = (service = {}) => {
    setFormValues({
      ...values,
      petServiceId: service?.petServiceId,
      hostPetId: service?.hostPetId,
      roomTypeBucketId: service?.roomTypeBucketId,
      roomType: service?.roomTypeId,
    });
  };

  const handleOnSave = () => {
    onSave({
      petServiceId,
      hostPetId,
      roomTypeBucketId,
      roomType,
      applyToEngagements,
      pendingRooms,
    });
  };

  return (
    <LoadingWrapper isLoading={isLoading}>
      <LayoutBox id={componentId} padding="scale-0">
        <LayoutStack>
          <LayoutBox id={`${componentId}__title`}>
            <Heading size="body-lg-bold" tagName="h2">
              {header}
            </Heading>
            <TextPassage>{subHeader}</TextPassage>
          </LayoutBox>

          <LayoutSwitcher threshold="scale-0" space="scale-G2">
            <LayoutStack space="scale-G2" style={{ maxWidth: "45%" }}>
              <LayoutBox id={`${componentId}__primary--container`}>
                <HotelPrimaryServiceDropdown
                  componentId={`${componentId}__primary--select`}
                  label={
                    <Heading size="body-lg-bold" tagName="h3">
                      Primary Service
                    </Heading>
                  }
                  isLoading={false}
                  onChange={handleOnChange}
                  petServiceId={petServiceId}
                />
              </LayoutBox>

              <LayoutBox id={`${componentId}__days--container`}>
                <SelectField
                  id={`${componentId}__days--select`}
                  label={
                    <Heading size="body-lg-bold" tagName="h3">
                      Apply these changes to
                    </Heading>
                  }
                  items={daysForRoomAssignment}
                  name="applyChangesToSelection"
                  onChange={handleApplyChangesToChange}
                  value={applyChangesTo}
                />
              </LayoutBox>
            </LayoutStack>

            <HotelCheckInOutServiceDayOptions
              selectedService={petServiceId}
              pendingRooms={pendingRooms}
              onClick={handleServiceCheckboxClick}
              onRoomChange={handleRoomChangeForEngagement}
              isChecked={isDayChecked}
            />
          </LayoutSwitcher>

          <LayoutStack id={`${componentId}__saveCancelButtons`}>
            {!isSaveBtnDisabled && updatingPastRooms ? (
              <LayoutBox style={{ display: "flex", justifyContent: "flex-end", paddingBottom: 0 }}>
                <TextPassage style={{ color: COLORS.red, maxWidth: "20rem", textAlign: "right" }}>
                  Updating rooms on past dates, please ensure this is intentional before saving
                </TextPassage>
              </LayoutBox>
            ) : null}

            <LayoutCluster space="scale-0" style={{ justifyContent: "flex-end" }}>
              <LayoutBox>
                <Button variant="link" onClick={onClose} text="Go back" />
              </LayoutBox>
              <LayoutBox>
                <Button
                  variant="primary"
                  onClick={handleOnSave}
                  disabled={isSaveBtnDisabled}
                  text={getSaveButtonLabel(petServiceId)}
                />
              </LayoutBox>
            </LayoutCluster>
          </LayoutStack>
        </LayoutStack>
      </LayoutBox>
    </LoadingWrapper>
  );
};

export const ChangePrimaryServiceModalContainer = connect(
  state => {
    const petId = getCurrentPet(state);
    const customerKey = getCurrentCustomerKey(state);
    const initialFormValues = {
      applyChangesTo: "",
      petServiceId: "",
      roomTypeBucketId: "",
    };

    const storeNumber = getHotelItinerary(state)?.storeNumber;
    const storeTimeZone = getStoreTimeZone(state, { storeNumber });
    const engagementServices = selectPetHotelEngagementServices(state, { petId });
    const petEngagements = getPetHotelEngagements(state, { petId });

    const getRoomSharingPetsToUpdate = hostPetId =>
      selectPetsWithEngagementsByHostPetId({ hostPetId, petId })(state);

    const checkIsRoomValid = ({ roomTypeBucketId, roomNumber, startDateTime, endDateTime }) =>
      selectIsRoomValid({ roomTypeBucketId, roomNumber, startDateTime, endDateTime })(state);

    // Check if room dropdowns are disabled
    const isSRCAgent = getIsSRCAgent(state);
    const isReservationDisabled = selectIsReservationDisabled(state);
    const isEngagementStartDateSameOrBeforeToday = selectIsCheckInTodayOrPast(state, { petId });
    const areRoomsDisabled =
      isSRCAgent || isReservationDisabled || !isEngagementStartDateSameOrBeforeToday;

    const isUpdatingHostService = selectedService =>
      selectIsUpdatingHostService({ selectedService, petId })(state);

    const today = momentTz.tz(undefined, storeTimeZone);

    return {
      header: "Change Primary Service & Room",
      subHeader: "Changing Service will impact pricing",
      componentId: "ChangePrimaryServiceModalContainer",
      customerKey,
      petId,
      initialFormValues,
      isLoading: createLoadingSelector([
        LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING,
        PATCH_HOTEL_PRIMARY_SERVICE_CHANGE,
      ])(state),
      daysForRoomAssignment: ROOM_ASSIGNMENT_DAY_CHANGE,
      onApplyChangesToChange: applyChangesTo => {
        return engagementIdsFromApplyChangesTo(engagementServices, applyChangesTo, today);
      },
      isSaveButtonDisabled: args =>
        isSaveDisabled({ ...args, checkIsRoomValid, petEngagements, areRoomsDisabled }),
      isUpdatingPastRooms: pendingRooms => {
        return isPastRoomsUpdated({ pendingRooms, petEngagements, today, storeTimeZone });
      },
      petEngagements,
      getRoomSharingPetsToUpdate,
      storeTimeZone,
      isUpdatingHostService,
      getSaveButtonLabel: selectedService =>
        isUpdatingHostService(selectedService) ? "Save Primary Service" : "Save",
    };
  },
  dispatch => {
    return {
      changePrimaryService: ({ data, onComplete }) =>
        dispatch(patchHotelPrimaryServiceChange({ data, onComplete })),
      closeChangePrimaryServiceModal: () => dispatch(hideCheckInOutModal()),
    };
  },
  (stateProps, dispatchProps) => {
    const {
      petId,
      petEngagements,
      getRoomSharingPetsToUpdate,
      storeTimeZone,
      isUpdatingHostService,
    } = stateProps;
    const { changePrimaryService, closeChangePrimaryServiceModal } = dispatchProps;

    return {
      ...stateProps,
      onClose: () => closeChangePrimaryServiceModal(),
      onSave: ({
        petServiceId,
        hostPetId,
        roomTypeBucketId,
        roomType,
        applyToEngagements,
        pendingRooms,
      }) => {
        // Construct engagements for current pet
        const engagements = buildEngagementsForPrimaryServicePatch({
          petEngagements,
          petServiceId,
          hostPetId,
          roomTypeBucketId,
          roomType,
          applyToEngagements,
          pendingRooms,
        });

        // Get pending rooms for any pets that are sharing rooms & should also be updated
        const roomSharingPetsPendingRooms = getPendingRoomsForRoomSharingPets({
          hostPetId,
          applyToEngagements,
          pendingRooms,
          petEngagements,
          getRoomSharingPetsToUpdate,
          storeTimeZone,
        });

        // Construct & add engagements for any pets that are sharing the room
        const pets = [
          { petKey: petId, engagements },
          ...Object.entries(roomSharingPetsPendingRooms)
            ?.map(([roomSharingPetId, roomSharingPetData]) => ({
              petKey: Number(roomSharingPetId),
              engagements: buildEngagementsForPrimaryServicePatch({
                petEngagements: roomSharingPetData?.engagements,
                roomTypeBucketId,
                roomType,
                pendingRooms: roomSharingPetData?.pendingRooms,
              }),
            }))
            ?.filter(pet => !isEmpty(pet?.engagements)),
        ];

        changePrimaryService({
          data: { pets },
          onComplete: () => {
            if (!isUpdatingHostService(petServiceId)) closeChangePrimaryServiceModal();
          },
        });
      },
    };
  },
)(ChangePrimaryServiceModal);
