import { connect } from "react-redux";
import bookingActionCreators from "../../../core/actionCreators/bookingActionCreators";
import AvailableManualAppointmentComponent from "../../../web/common/availableManualAppointment/availableManualAppointmentComponent";
import { getNewAppointmentTimeSlot } from "../../../core/selectors/bookingUISelectors";

const mapStateToProps = state => ({
  newAppointmentTimeSlot: getNewAppointmentTimeSlot(state)
});

const mapDispatchToProps = dispatch => ({
  selectNewAppointmentTimeSlot: timeSlot => {
    dispatch(bookingActionCreators.selectNewAppointmentTimeSlot(timeSlot));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailableManualAppointmentComponent);
