import momentTz from "moment-timezone";
import { formatDayNameMonthDayYear } from "@/core/utils/dateUtils/formatDateTime";
import { MIN_OVERRIDE_MODAL_DESCRIPTION_LENGTH } from "core/constants/modalConstants";
import { reasonPlaceholder } from "./hotelPriceOverrideModal";

/**
 * Util to format petService price override data for the PATCH req
 * @memberOf Utils.Engagement
 * @function
 * @name formatEngagementServicesForPatch
 * @param {Object[]} engagements - itinerary engagements
 * @param {Object} updatedPriceInfo - object from modal onSave
 * @returns {Object} price override itinerary patch obj
 * @example formatEngagementServicesForPatch({ engagements, updatedPriceInfo })
 */
export const formatEngagementServicesForPatch = (engagements = [], updatedPriceInfo = {}) => {
  return Object.entries(updatedPriceInfo).map(
    ([id, { updatedPrice, reasonCode, reasonDescription } = {}]) => {
      const engagement =
        engagements.find(({ engagementId } = {}) => engagementId.toString() === id) ?? {};
      const { petServiceProductId, petServiceId, groupingId } = engagement?.petService ?? {};

      return {
        engagementId: id,
        petService: {
          petServiceProductId,
          petServiceId,
          groupingId,
          pricing: {
            finalPrice: Number(updatedPrice),
            adjustmentReason: reasonCode,
            adjustmentReasonComments: reasonDescription,
          },
        },
      };
    },
  );
};

/**
 * Util to format pertService objs from engagement for the price override modal
 * @memberOf Utils.Engagement
 * @function
 * @name formatServicesForModal
 * @param {Object[]} engagements - itinerary engagements
 * @param {string} timeZone - the time zone for the store on the itinerary, used to format the dates
 * @returns {Array} [{ id, formattedDate, reasonCode, reasonDescription }]
 * @example formatServicesForModal(engagements, storeTimeZone)
 */
export const formatServicesForModal = (engagements = [], timeZone) =>
  engagements.map(({ engagementId, startDatetime, petService }) => {
    const dateInStoreTz = momentTz.tz(startDatetime, timeZone);
    const formattedDate = formatDayNameMonthDayYear(dateInStoreTz);
    const finalPrice = petService?.pricing?.finalPrice;
    const enagementData = { id: engagementId, formattedDate, finalPrice };

    const reasonCode = petService?.pricing?.adjustmentReason;
    const reasonDescription = petService?.pricing?.adjustmentReasonComments;
    if (reasonCode) enagementData.reasonCode = reasonCode;
    if (reasonDescription) enagementData.reasonDescription = reasonDescription;

    return enagementData;
  });

/**
 * Util to format addon objs from engagement for the price override modal
 * @memberOf Utils.Engagement
 * @function
 * @name formatAddonsForModal
 * @param addons - addon array containing copies of the same addon where each addon also has engagementId & startDatetime
 *  ie. all feeding charges or addons with same groupingId
 * @param timeZone - the time zone for the store on the itinerary, used to format the dates
 * @returns {Array} [{ id, formattedDate, reason, description }]
 * @example formatAddonsForModal(addons, storeTimeZone)
 */
export const formatAddonsForModal = (addons = [], timeZone) =>
  addons.map(({ engagementId, startDatetime, pricing } = {}) => {
    const dateInStoreTz = momentTz.tz(startDatetime, timeZone);

    return {
      id: engagementId,
      formattedDate: formatDayNameMonthDayYear(dateInStoreTz),
      finalPrice: pricing?.finalPrice,
      reasonCode: pricing?.adjustmentReason,
      reasonDescription: pricing?.adjustmentReasonComments,
    };
  });

/**
 * Util to format addon price override data for the PATCH req
 * @memberOf Utils.Engagement
 * @function
 * @name formatEngagementAddonsForPricePatch
 * @param {Array} addons - addon array containing copies of the same addon where each addon also has engagementId & startDatetime
 *  ie. all feeding charges or addons with same groupingId
 * @param {Object} updatedPriceInfo - object
 * @returns {Object} - data for patch call
 * @example formatEngagementAddonsForPricePatch{(addons, updatedPriceInfo})
 */
export const formatEngagementAddonsForPricePatch = ({ addons = [], updatedPriceInfo = {} }) => {
  return Object.entries(updatedPriceInfo).map(
    ([id, { updatedPrice, reasonCode, reasonDescription } = {}]) => {
      const addon = addons?.find(({ engagementId } = {}) => engagementId.toString() === id) ?? {};
      const { groupingId, addOnProductNumber, orderProductNumber, pricing, quantity } = addon;

      return {
        engagementId: id,
        addOns: [
          {
            groupingId,
            addOnProductNumber,
            orderProductNumber,
            quantity,
            pricing: {
              finalPrice: updatedPrice ?? pricing?.finalPrice,
              adjustmentReason: reasonCode ?? pricing?.adjustmentReason,
              adjustmentReasonComments: reasonDescription ?? pricing?.adjustmentReasonComments,
            },
          },
        ],
      };
    },
  );
};

/**
 * Validator: any price override has to have a reason code
 * @memberOf Utils.Validations
 * @function
 * @name allLinesHaveAReason
 * @param {Object[]} priceOverrides - objects describing which engagement prices have been modified
 * @returns {Object} [{ isValid, errorMessage }]
 * @example allLinesHaveAReason(priceOverrides)
 */
export const allLinesHaveAReason = priceOverrides => {
  const errorMessage = "A reason is required for all price overrides";
  const allReasonsExist = priceOverrides.every(
    priceOverride =>
      priceOverride.reasonCode && priceOverride.reasonCode !== reasonPlaceholder.value,
  );
  return {
    isValid: allReasonsExist,
    errorMessage,
  };
};

/**
 * Validator: if a reason code is 'other' then make sure it also has a description
 * @memberOf Utils.Validations
 * @function
 * @name allOtherReasonsHaveADescription
 * @param {Object[]} priceOverrides - objects describing which engagement prices have been modified
 * @returns {Object} [{ isValid, errorMessage }]
 * @example allOtherReasonsHaveADescription(priceOverrides)
 */
export const allOtherReasonsHaveADescription = priceOverrides => {
  const errorMessage = "A reason description is required for all price overrides";
  const allDescriptionsExist = priceOverrides
    .filter(priceOverride => priceOverride.reasonCode === "203")
    .every(lineWithReason => lineWithReason.reasonDescription);
  return {
    isValid: allDescriptionsExist,
    errorMessage,
  };
};

/**
 * Validator: make sure any descriptions are over 10 characters
 * @memberOf Utils.Validations
 * @function
 * @name allDescriptionsAreOverMin
 * @param {Object[]} priceOverrides - objects describing which engagement prices have been modified
 * @param {Number} minLength - minimum number of characters required for a description
 * @returns {Object} [{ isValid, errorMessage }]
 * @example allDescriptionsAreOverMin(priceOverrides)
 */
export const allDescriptionsAreOverMin = (priceOverrides, minLength) => {
  const errorMessage = "Descriptions require a minimum of 10 characters";
  const allDescriptionsOverMin = priceOverrides
    .filter(priceOverride => priceOverride.reasonDescription)
    .every(lineWithDescription => lineWithDescription.reasonDescription.length >= minLength);
  return {
    isValid: allDescriptionsOverMin,
    errorMessage,
  };
};

/**
 * Validator: validate all the modal fields
 * @memberOf Utils.Validations
 * @function
 * @name validateModalFields
 * @param {Object} updatedPriceInfo - object describing which engagement prices have been modified
 * @returns {String} error message or empty string if there is no error
 * @example validateModalFields(updatedPriceInfo)
 */
export const validateModalFields = updatedPriceInfo => {
  const updatedLines = Object.values(updatedPriceInfo);
  const validations = [
    allLinesHaveAReason(updatedLines),
    allOtherReasonsHaveADescription(updatedLines),
    allDescriptionsAreOverMin(updatedLines, MIN_OVERRIDE_MODAL_DESCRIPTION_LENGTH),
  ];

  const hasError = validations.find(priceOverride => priceOverride.isValid === false);

  return hasError?.errorMessage || "";
};

/**
 * Util to add reasons from modal data into the updated price info if they don't already exist
 * @function
 * @name validateModalFields
 * @param {Object} updatedPriceInfo - object describing which engagement prices have been modified
 * @param {Object[]} modalData - array of objects containing original price override data for an engagement
 * @returns {String} obj matching structure of updatedPriceInfo
 * @example applyModalDataToPriceUpdates(updatedPriceInfo, modalData)
 */
export const applyModalDataToPriceUpdates = (updatedPriceInfo = {}, modalData = []) => {
  return Object.fromEntries(
    Object.entries(updatedPriceInfo)?.map(([key, { reasonCode, ...updatedItem } = {}]) => {
      const originalData = modalData?.find(item => item?.id?.toString() === key);

      return [key, { ...updatedItem, reasonCode: reasonCode ?? originalData?.reasonCode }];
    }),
  );
};
