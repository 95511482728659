import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { put, takeEvery, takeLatest, call, all, select } from "redux-saga/effects";
import {
  flatten,
  keyBy,
  values,
  filter,
  omit,
  reduce,
  forEach,
  get,
  some,
  map,
  find,
  set,
  isEmpty,
  compose,
} from "lodash/fp";
import { derivePetSupportedType, isWorkOrRelocationActivityType } from "core/utils/schedulesUtils";
import { getStoreNumber } from "../selectors/persistentSelectors";
import { forEachWithKey } from "../utils/general//loopsAndMaps";
import { formatMoment, formatCalendarDateMoment } from "../utils/dateUtils/formatDateTime";
import { weekDaysNames } from "../constants/index";
import { setRuleViolations } from "../actionCreators/ruleViolationsActionCreator";
import { modalTypes as commonModalTypes } from "../../core/constants/modalConstants";
import { setAbsences } from "../actionCreators/absencesActionCreator";
import { loadSalonHoursSuccess } from "../actionCreators/salonHoursActionCreators";
import { loadAssociatesSuccess } from "../actionCreators/associateActionCreator";
import { absenceReasons, dayActivityTypes } from "../constants/schedulesConstants";
import getMomentByDateAndTime from "../utils/dateUtils/byDateAndTime";
import { onloadAllRelocatedSalonHours } from "./salonHoursSaga";
import { getScheduleViolationOverride } from "../selectors/schedules/scheduleViolationOverride";
import {
  fetchTemplateSchedules,
  postTemplateSchedules,
  fetchLunchBreakTimes,
} from "../services/systemServicesBooking/schedulingEndPoints";
import {
  fetchAssociatesSchedule,
  fetchMySchedule,
  fetchSchedules,
  putSchedules,
} from "../services/systemServicesBooking/associatesEndPoints";
import schedulesActionTypes from "../actionTypes/schedulesActionTypes";
import {
  setSchedulesRequest,
  setSchedulesSuccess,
  showSchedulesModal,
  setSchedulesFailure,
  loadAssociatesScheduleRequest,
  loadAssociatesScheduleSuccess,
  loadAssociatesScheduleFailure,
  loadSchedulesRequest,
  setWeeklyEdit,
  loadSchedulesSuccess,
  loadSchedulesFailure,
  loadTemplateSchedulesRequest,
  clearSchedules,
  clearTemplateSchedules,
  loadTemplateSchedulesSuccess,
  setTemplateWeekId,
  setEffectiveStartDate,
  loadTemplateSchedulesFailure,
  setTemplateWeeklyEdit,
  getTemplateWeeklyEdit,
  setTemplateSchedulesRequest,
  setWeeklyEditChanged,
  setTemplateSchedulesSuccess,
  setTemplateSchedulesFailure,
  setDailyLunch,
  removeDailyLunch,
  resetDayActivityIds,
  loadLunchBreakTimesRequest,
  loadLunchBreakTimesSuccess,
  loadLunchBreakTimesFailure,
  setAllAssociatesWeekSchedule,
  loadMyScheduleRequest,
  loadMyScheduleSuccess,
  loadMyScheduleFailure,
  removeWeeklyEdit,
  setNewWeeklyEditSuccess,
} from "../actionCreators/schedulesActionCreators";
import {
  getTemplateDayActivities,
  getTemplateWeekId,
  getWeeklyEditActivity,
  getWorkActivitiesForDayOfWeek,
  getLunchBreakByStore,
} from "../selectors/schedulesSelectors";
import { normaliseResponseForPartialRelocation } from "../utils/normalizeUtils/normalizeDayActivities";
import { addSplitShiftActivityIndex } from "../utils/schedulesUtils/schedulesUtils";

const normalizeSchedule = schedule => keyBy("id", flatten(values(schedule.dailyActivity)));
const normalizeSchedules = schedules =>
  reduce(
    (currentResult, schedule) => ({
      ...currentResult,
      ...normalizeSchedule(schedule),
    }),
    {},
    schedules,
  );

const normalizeWeekActivitiesByDateAndAssociate = ({ schedules }) => {
  const result = {};

  forEach(schedule => {
    forEachWithKey((dayActivities, dayOfWeek) => {
      const formattedMoment = moment(schedule.effectiveStartDate)
        .isoWeekday(dayOfWeek)
        .format("YYYY-MM-DD");

      forEach(activity => {
        const associatesActivities = get(
          [formattedMoment, schedule.associateId.toString()],
          result,
        );

        if (!result[formattedMoment]) {
          result[formattedMoment] = {};
        }

        result[formattedMoment] = {
          ...result[formattedMoment],
          [schedule.associateId]: associatesActivities
            ? [...associatesActivities, activity.id]
            : [activity.id],
        };
      }, dayActivities);
    }, schedule.dailyActivity);
  }, schedules);

  return result;
};

const denormalizeDayActivities = ({ startWeekDate, dayActivities, omitLocalId = true }) => {
  const fixedDayActivities = values(dayActivities).map(dayActivity => {
    // Remove "Day Off" as server don't need to get them.
    if (
      dayActivity.type === dayActivityTypes.ABSENCE &&
      dayActivity.absenceReason === absenceReasons.DAY_OFF
    ) {
      return {};
    }

    return {
      ...dayActivity,
    };
  });

  const dailyActivity = {};
  weekDaysNames.forEach(dayOfWeek => {
    dailyActivity[dayOfWeek] =
      filter(dayActivity => dayActivity.dayOfWeek === dayOfWeek, fixedDayActivities) || [];
  });

  // modify hours for the shifts
  const modifyWorkingHours = {};
  Object.keys(dailyActivity).forEach(key => {
    let activity = dailyActivity[key];
    const hasSecondShift = activity.find(item => item?.localId?.includes("_temp"));
    if (hasSecondShift && !omitLocalId) {
      // get all the shifts
      const firstShift = activity.find(
        item => isWorkOrRelocationActivityType(item?.type) && !item?.localId?.includes("_temp"),
      );
      const secondShift = activity.find(
        item => isWorkOrRelocationActivityType(item?.type) && item?.localId?.includes("_temp"),
      );
      const lunchHours = activity.find(item => item.absenceReason === absenceReasons.LUNCH_BREAK);

      // change the start/end times for the respective shifts as follows
      if (firstShift && secondShift) {
        secondShift.startTime = lunchHours.endTime;
        secondShift.endTime = firstShift.endTime;
        firstShift.endTime = lunchHours.startTime;
        activity = [firstShift, lunchHours, secondShift];
      }
    }
    modifyWorkingHours[key] = omitLocalId
      ? activity.map(item => {
          return {
            ...omit(["localId", "hasChanged", "isPartialDayRelocation"], item),
            petSupportedType: derivePetSupportedType(item),
          };
        })
      : activity;
  });

  return {
    effectiveStartDate: startWeekDate,
    dailyActivity: modifyWorkingHours,
  };
};

const fillEmptyDays = dayActivities => {
  weekDaysNames.forEach(dayName => {
    if (!some({ dayOfWeek: dayName }, dayActivities)) {
      dayActivities.push({
        localId: uuidv4(),
        id: null,
        type: dayActivityTypes.ABSENCE,
        absenceReason: absenceReasons.DAY_OFF,
        startTime: "00:00:00",
        endTime: "00:00:00",
        dayOfWeek: dayName,
      });
    }
  });

  return dayActivities;
};

export const buildDay = day => ({
  ...day,
  localId: day.id,
  petSupportedType: derivePetSupportedType(day),
});

const normalizeADayActivities = schedules =>
  keyBy("localId", fillEmptyDays(map(buildDay, flatten(values(schedules.dailyActivity)))));

/**
 * Helper to normalize day activites and to flag partial day relocations and split shifts
 * @memberOf Utils.Schedules
 * @param {Object} data - api data from schedules call
 * @returns dayActivities array for state
 */
export const processDayActivitesForState = data => {
  return compose(
    normalizeADayActivities,
    normaliseResponseForPartialRelocation,
    addSplitShiftActivityIndex,
  )(data);
};

const normalizeTemplateDayActivities = schedules => ({
  week1: keyBy(
    "localId",
    fillEmptyDays(map(buildDay, flatten(values(schedules.dailyActivity["Week 1"])))),
  ),
  week2: keyBy(
    "localId",
    fillEmptyDays(map(buildDay, flatten(values(schedules.dailyActivity["Week 2"])))),
  ),
  effectiveStartDate: schedules.effectiveStartDate,
  hasFutureAppointments: schedules.hasFutureAppointments,
});

const denormalizeTemplateDayActivities = ({
  associateId,
  effectiveStartDate,
  templateDayActivities,
}) => ({
  effectiveStartDate,
  dailyActivity: {
    "week 1": denormalizeDayActivities({
      associateId,
      startWeekDate: effectiveStartDate,
      dayActivities: templateDayActivities.week1,
    }).dailyActivity,
    "week 2": denormalizeDayActivities({
      associateId,
      startWeekDate: effectiveStartDate,
      dayActivities: templateDayActivities.week2,
    }).dailyActivity,
  },
});

const getNormalizedAbsences = schedules => {
  const result = {};

  forEach(schedule => {
    forEachWithKey((dayActivities, dayOfWeek) => {
      const dateOfWeek = formatCalendarDateMoment(
        moment(schedule.effectiveStartDate).isoWeekday(dayOfWeek),
      );

      forEach(activity => {
        if (activity.type !== dayActivityTypes.ABSENCE) {
          return;
        }

        const startDateTime = getMomentByDateAndTime(dateOfWeek, activity.startTime);
        const endDateTime = getMomentByDateAndTime(dateOfWeek, activity.endTime);
        const absenceDuration = moment.duration(endDateTime.diff(startDateTime)).asMinutes();

        result[activity.id] = {
          ...omit("id", activity),
          absenceStartDateTime: formatMoment(startDateTime),
          absenceEndDateTime: formatMoment(endDateTime),
          associateId: schedule.associateId,
          absenceId: activity.id,
          absenceDuration,
        };
      }, dayActivities);
    }, schedule.dailyActivity);
  }, schedules);

  return result;
};

const getAssociatesWithAbsences = absences =>
  reduce(
    (result, absence) =>
      get([absence.associateId, "absences"], result)
        ? set(
            [Number(absence.associateId), "absences"],
            [...result[absence.associateId].absences, absence.absenceId],
            result,
          )
        : set([Number(absence.associateId), "absences"], [absence.absenceId], result),
    {},
    absences,
  );

const getSalonHoursWithAbsences = absences =>
  reduce(
    (result, absence) => {
      const absenceDate = moment(absence.absenceStartDateTime).format("YYYY-MM-DD");

      return get([absenceDate, "absences"], result)
        ? set(
            [absenceDate, "absences"],
            [...result[absenceDate].absences, absence.absenceId],
            result,
          )
        : set([absenceDate, "absences"], [absence.absenceId], result);
    },
    {},
    absences,
  );

const needRemoveLunch = dayActivities => {
  let existWorkOrRelocationActivity = false;
  let existLunchBreakActivity = false;

  forEach(activity => {
    if (activity.type === dayActivityTypes.WORK || activity.type === dayActivityTypes.RELOCATION) {
      existWorkOrRelocationActivity = true;
    } else if (activity.absenceReason === absenceReasons.LUNCH_BREAK) {
      existLunchBreakActivity = true;
    }
  }, dayActivities);

  return existLunchBreakActivity && !existWorkOrRelocationActivity;
};

function* onLoadAssociatesSchedule({ fromDate }) {
  try {
    const storeNumber = yield select(getStoreNumber);
    yield put(loadAssociatesScheduleRequest());
    const response = yield call(fetchAssociatesSchedule, { storeNumber, fromDate });
    const schedules = response.data;
    const activities = normalizeWeekActivitiesByDateAndAssociate({ schedules, fromDate });

    yield call(setWeekSchedulesAndAbsences, { activities, schedules });
    yield put(loadAssociatesScheduleSuccess({ schedules: normalizeSchedules(schedules) }));
  } catch (error) {
    yield put(loadAssociatesScheduleFailure({ error }));
  }
}

function* onLoadMySchedule({ fromDate }) {
  try {
    yield put(loadMyScheduleRequest());
    const { data } = yield call(fetchMySchedule, { fromDate });
    const activities = processDayActivitesForState(data);
    yield put(setWeeklyEdit({ dayActivities: activities }));
    yield put(loadMyScheduleSuccess({ dayActivities: data }));
  } catch (error) {
    yield put(loadMyScheduleFailure({ error }));
  }
}

function* onLoadSchedules({ associateId, fromDate }) {
  try {
    yield put(loadSchedulesRequest());
    const response = yield call(fetchSchedules, { associateId, fromDate });
    const dayActivities = processDayActivitesForState(response.data);
    // for editing mode
    yield put(setWeeklyEdit({ dayActivities }));

    yield put(loadSchedulesSuccess());
    yield call(onloadAllRelocatedSalonHours);
  } catch (error) {
    yield put(loadSchedulesFailure({ error }));
  }
}

function* onLoadTemplateSchedules({ associateId, fromDate }) {
  try {
    yield put(loadTemplateSchedulesRequest());
    const response = yield call(fetchTemplateSchedules, { associateId, fromDate });
    const { data } = response;
    const templateSchedules = normalizeTemplateDayActivities(data);
    yield put(clearSchedules());
    yield put(clearTemplateSchedules());
    yield put(loadTemplateSchedulesSuccess({ templateSchedules }));
    yield put(setTemplateWeekId("week1"));
    yield put(setEffectiveStartDate(templateSchedules.effectiveStartDate));
  } catch (error) {
    yield put(clearSchedules());
    yield put(clearTemplateSchedules());
    yield put(setTemplateWeekId(null));
    yield put(setEffectiveStartDate(null));
    yield put(loadTemplateSchedulesFailure({ error }));
  }
}

function* onSetTemplateWeekId({ weekId }) {
  yield put(clearSchedules());
  yield put(setTemplateWeeklyEdit(weekId));
}

function* onChangeTemplateWeekId({ weekId }) {
  const currentWeekId = yield select(getTemplateWeekId);
  yield put(getTemplateWeeklyEdit(currentWeekId));
  yield put(setTemplateWeekId(weekId));
}

function* onSetSchedules({ associateId, startWeekDate, dayActivities }) {
  try {
    // IsOverride should be set to false by default. in which case the call to update the schedule is made,
    // however when/if there is a conflict e.g. Associate as an appointment for that time frame, the putSchedules
    // API call will return status code 406 and thus we jump to the catch statement where a dialog is thrown to
    // the user.  This is where the user now has the option to set the isOverride to true, if this happens this
    // generator/saga function is called once more and if isOverride is now true, we skip the basic putSchedules call, but now call it
    // with the added ignoreViolations param and at this point the call should be successful.
    const isOverride = yield select(getScheduleViolationOverride);
    yield put(setSchedulesRequest());
    const data = denormalizeDayActivities({ associateId, startWeekDate, dayActivities });
    let response;

    if (!isOverride) {
      response = yield call(putSchedules, { associateId, data });
    } else {
      const ignoreViolations = true;
      response = yield call(putSchedules, { associateId, data, ignoreViolations });
    }

    const normalizedDayActivities = processDayActivitesForState(response.data);
    yield put(setSchedulesSuccess({ dayActivities: normalizedDayActivities }));
    yield call(onLoadAssociatesSchedule, { fromDate: startWeekDate });
  } catch (error) {
    const errorCode = get(["response", "status"], error);

    if (errorCode === 406) {
      const ruleViolations = get(["response", "data"], error);

      // where we fire the modal to override schedule conflict
      if (ruleViolations) {
        yield put(setRuleViolations(ruleViolations));
        yield put(showSchedulesModal(commonModalTypes.RULE_VIOLATION));
      }
    }

    yield put(setSchedulesFailure({ error }));
  }
}

function* onSetTemplateSchedules({ associateId, weekId, effectiveStartDate }) {
  try {
    yield put(setTemplateSchedulesRequest());
    yield put(getTemplateWeeklyEdit(weekId)); // use dayActivities ?
    const templateDayActivities = yield select(getTemplateDayActivities);

    const data = denormalizeTemplateDayActivities({
      associateId,
      weekId,
      effectiveStartDate,
      templateDayActivities,
    });
    yield call(postTemplateSchedules, { associateId, data });
    yield put(setWeeklyEditChanged(false));
    yield put(setTemplateSchedulesSuccess());
  } catch (error) {
    if (get(["response", "status"], error) === 406) {
      const ruleViolations = get(["response", "data"], error);

      if (ruleViolations) {
        yield put(setRuleViolations(ruleViolations));
        yield put(showSchedulesModal(commonModalTypes.RULE_VIOLATION));
      }
    }

    yield put(setTemplateSchedulesFailure({ error }));
  }
}

/*
If "Work" time is more than 5 hours, add "Lunch Break" for that day at the 4'th working hour
If "Work" time is les than 5 hours, remove "Lunch Break" for that day.
 */

function* onSetWeeklyEditDayActivity({ payload, type }) {
  // If the day is a split shift then short circuit as we don't want this logic to overwrite
  // the lunch hours, the action for this saga is also used for a reducer so we still need call it
  if (payload?.isSplitShift) return;

  let dayActivity, dayActivities;
  if (type === schedulesActionTypes.REMOVE_WEEKLY_EDIT) {
    dayActivities = yield select(getWorkActivitiesForDayOfWeek, {
      dayOfWeek: payload.dayOfWeek,
    });
    dayActivity = dayActivities.find(
      activity => activity.absenceReason !== absenceReasons.LUNCH_BREAK,
    );
  } else {
    dayActivity = yield select(getWeeklyEditActivity, { localId: payload.localId });
    dayActivities = yield select(getWorkActivitiesForDayOfWeek, {
      dayOfWeek: dayActivity.dayOfWeek,
    });
  }
  let storeNumber = dayActivity.storeId ? dayActivity.storeId : yield select(getStoreNumber);

  if (payload.storeId) {
    storeNumber = payload.storeId;
  }

  const isLunchDataFetched = !isEmpty(yield select(getLunchBreakByStore, { storeNumber }));

  if (!isLunchDataFetched) {
    yield call(onLoadLunchBreakTimes, { storeNumber });
  }
  let shouldEditLunchHours = true;
  const lunchHours = dayActivities.find(
    activity => activity.absenceReason === absenceReasons.LUNCH_BREAK,
  );
  const workHours = dayActivities.filter(
    activity => activity.absenceReason !== absenceReasons.LUNCH_BREAK,
  );

  if (
    (dayActivity.type === dayActivityTypes.WORK ||
      dayActivity.type === dayActivityTypes.RELOCATION) &&
    (payload.startTime !== null || payload.endTime !== null)
  ) {
    const lunchBreakTimes = yield select(getLunchBreakByStore, { storeNumber });
    const startTime = moment(payload.startTime || dayActivity.startTime, "HH:mm:ss");
    const endTime = moment(payload.endTime || dayActivity.endTime, "HH:mm:ss");
    const workTime = moment.duration(endTime.diff(startTime)).asMinutes();
    if (lunchHours) {
      const actualLunchStartTime = moment(lunchHours.startTime, "HH:mm:ss");
      const actualLunchEndTime = moment(lunchHours.endTime, "HH:mm:ss");
      if (
        workHours.length > 1 &&
        (!actualLunchStartTime.isBetween(startTime, endTime) ||
          !actualLunchEndTime.isBetween(startTime, endTime))
      ) {
        shouldEditLunchHours = false;
      }
    }
    const lunchStartTime = moment(startTime)
      .add(lunchBreakTimes.startOffset, "m")
      .format("HH:mm:ss");
    const lunchEndTime = moment(startTime)
      .add(lunchBreakTimes.startOffset, "m")
      .add(lunchBreakTimes.duration, "m")
      .format("HH:mm:ss");

    if (workTime >= lunchBreakTimes.minShiftLength && shouldEditLunchHours) {
      yield put(
        setDailyLunch({
          dayOfWeek: dayActivity.dayOfWeek,
          startTime: lunchStartTime,
          endTime: lunchEndTime,
        }),
      );
    } else if (
      shouldEditLunchHours &&
      find({ absenceReason: absenceReasons.LUNCH_BREAK }, dayActivities)
    ) {
      yield put(removeDailyLunch({ dayOfWeek: dayActivity.dayOfWeek }));
      const toBeRemovedShift = workHours.find(wh => wh.localId === dayActivity.localId);
      if (toBeRemovedShift && workHours.length > 1) {
        yield put(removeWeeklyEdit(toBeRemovedShift));
      }
    }
  } else if (payload.type === dayActivityTypes.ABSENCE) {
    const toBeRemovedShift = workHours.find(wh => wh.localId !== payload.localId);
    if (toBeRemovedShift && workHours.length > 1) {
      yield put(removeWeeklyEdit(toBeRemovedShift));
    }
  }

  // Remove "Lunch Break" if there is not "Work" or "Relocation" at all.
  if (needRemoveLunch(dayActivities)) {
    yield put(removeDailyLunch({ dayOfWeek: dayActivity.dayOfWeek }));
  }

  if (payload.resetIds) {
    yield put(resetDayActivityIds(payload.localId));
  }
}

function* onSetNewWeeklyEditDayActivity({ payload }) {
  const dayActivities = yield select(getWorkActivitiesForDayOfWeek, {
    dayOfWeek: payload.dayOfWeek,
  });

  const { dailyActivity } = denormalizeDayActivities({
    dayActivities: [...dayActivities, payload],
    omitLocalId: false,
  });
  const activities = keyBy("localId", dailyActivity[payload.dayOfWeek]);

  yield put(setNewWeeklyEditSuccess({ dayActivities: activities }));
}

function* onLoadLunchBreakTimes({ storeNumber }) {
  try {
    yield put(loadLunchBreakTimesRequest());
    const storeNumberForLunch = storeNumber || (yield select(getStoreNumber));
    const response = yield call(fetchLunchBreakTimes, { storeNumber: storeNumberForLunch });
    const lunchBreakTimes = response.data;
    yield put(loadLunchBreakTimesSuccess({ lunchBreakTimes, storeNumber: storeNumberForLunch }));
  } catch (error) {
    yield put(loadLunchBreakTimesFailure({ error }));
  }
}

function* setWeekSchedulesAndAbsences({ schedules, activities }) {
  const absences = getNormalizedAbsences(schedules);

  yield put(setAllAssociatesWeekSchedule({ activities }));
  yield put(setAbsences({ absences }));
  yield put(loadAssociatesSuccess({ associates: getAssociatesWithAbsences(absences) }));
  yield put(loadSalonHoursSuccess({ salonHours: getSalonHoursWithAbsences(absences) }));
}

/* ========================= */
/*         watchers          */
/* ========================= */
function* watchLoadAssociatesSchedule() {
  yield takeEvery(schedulesActionTypes.LOAD_ASSOCIATES_SCHEDULE, onLoadAssociatesSchedule);
}

function* watchLoadSchedules() {
  yield takeEvery(schedulesActionTypes.LOAD_SCHEDULES, onLoadSchedules);
}

function* watchMySchedule() {
  yield takeEvery(schedulesActionTypes.LOAD_MY_SCHEDULE, onLoadMySchedule);
}

function* watchSetSchedules() {
  yield takeEvery(schedulesActionTypes.SET_SCHEDULES, onSetSchedules);
}

function* watchLoadTemplateSchedules() {
  yield takeEvery(schedulesActionTypes.LOAD_TEMPLATE_SCHEDULES, onLoadTemplateSchedules);
}

function* watchSetTemplateSchedules() {
  yield takeLatest(schedulesActionTypes.SET_TEMPLATE_SCHEDULES, onSetTemplateSchedules);
}

function* watchSetTemplateWeekId() {
  yield takeEvery(schedulesActionTypes.SET_TEMPLATE_WEEK_ID, onSetTemplateWeekId);
}

function* watchChangeTemplateWeekId() {
  yield takeEvery(schedulesActionTypes.CHANGE_TEMPLATE_WEEK_ID, onChangeTemplateWeekId);
}

function* watchSetWeeklyEditDayActivity() {
  yield takeEvery(schedulesActionTypes.SET_WEEKLY_EDIT_DAY_ACTIVITY, onSetWeeklyEditDayActivity);
}

function* watchSetNewWeeklyEditDayActivity() {
  yield takeEvery(schedulesActionTypes.SET_NEW_WEEKLY_EDIT, onSetNewWeeklyEditDayActivity);
}

function* watchRemoveWeeklyEdit() {
  yield takeEvery(schedulesActionTypes.REMOVE_WEEKLY_EDIT, onSetWeeklyEditDayActivity);
}

function* watchLoadLunchBreakTimes() {
  yield takeEvery(schedulesActionTypes.LOAD_LUNCH_BREAK_TIMES, onLoadLunchBreakTimes);
}

export default function* schedulesSaga() {
  yield all([
    watchLoadAssociatesSchedule(),
    watchLoadSchedules(),
    watchSetSchedules(),
    watchMySchedule(),
    watchLoadTemplateSchedules(),
    watchSetTemplateSchedules(),
    watchSetTemplateWeekId(),
    watchChangeTemplateWeekId(),
    watchSetWeeklyEditDayActivity(),
    watchSetNewWeeklyEditDayActivity(),
    watchRemoveWeeklyEdit(),
    watchLoadLunchBreakTimes(),
  ]);
}
