/**
 * Converts two arrays into an object, where the values of the first array
 * become the keys, and the values of the second array become the values.
 *
 * @param {Array} keys - An array of strings representing the keys for the resulting object.
 * @param {Array} values - An array of values that will be mapped to the corresponding keys.
 * @returns {Object} - An object where the keys are taken from the `keys` array and the values are taken from the `values` array.
 */
export default function arraysToObject(keys, values) {
  // Use the `reduce` function to iterate over the `keys` array.
  return keys.reduce((acc, cur, idx) => {
    // For each key, add a new property to the accumulator object with the corresponding value.
    return { ...acc, [cur]: values[idx] };
  }, {}); // Start with an empty object as the initial accumulator value.
}
