import moment from "moment";

/** @module convertDateToTimestamp */

/**
 * Converts a human readable date to a Unix timestamp. I.E. 03.12.2014 => 1394582400000
 * An optional second parameter can be passed to specify the type of human readable date
 * I.E. '03.12.2014'
 *
 * @exports convertDateToTimestamp
 * @param {String} date - Date to transform
 * @param {String} format - Date format to transform
 * @returns {Number} Unix timestamp
 * @example
 * - convertDateToTimestamp('03.12.2014', '03.12.2014')
 */
export default function convertDateToTimestamp(date, format = "YYYY-MM-DD") {
  return moment(date, format)
    .utc()
    .valueOf();
}
