import { get, includes } from "lodash/fp";

import getCustomerByPetServiceItem from "./getCustomerByPetServiceItem";
import normalizeString from "../stringUtils/normalizeString";

export default function filterUpcomingAndHereBySearchText({
  customers,
  pets,
  associates,
  petServiceItemId,
  petServiceItems,
  searchText
}) {
  if (searchText) {
    const petServiceItem = get(petServiceItemId, petServiceItems);
    const associateId = get("associate", petServiceItem);
    const associateName = get([associateId, "associateName"], associates);
    const petId = get("pet", petServiceItem);
    const petFirstName = get([petId, "petName"], pets);
    const customer = getCustomerByPetServiceItem({ petServiceItem, customers, pets });
    const customerLastName = get("lastName", customer);
    const fullPetName = `${petFirstName} ${customerLastName}`;

    const matchesSearchText = includes(normalizeString(searchText));
    const isAssociateMatch = matchesSearchText(normalizeString(associateName));
    const isPetMatch = matchesSearchText(normalizeString(fullPetName));

    return isAssociateMatch || isPetMatch;
  }

  return true;
}
