import React from "react";
import { connect } from "react-redux";
import momentTz from "moment-timezone";

// Selectors
import { selectClosestFutureResForPet } from "../itineraryHistoryList/itineraryHistoryListSelectors";
import { getStoreTimeZone } from "@/core/selectors/entitiesSelector";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { TextPassage } from "@petsmart-ui/sparky";

// Utils
import getUnknownPetImage from "@/core/utils/assetUtils/unknownPetImage";
import { formatDayHourMinutes, formatMonthDay } from "@/core/utils/dateUtils/formatDateTime";

// Constants
import { checkOutComplete } from "./checkOutCompleteConstants";

/**
 * React view component for check out complete modal pet cards
 * @memberOf Views.Pet
 * @function
 * @name CheckOutCompletePet
 * @param {Object} props
 * @param {string} props.componentId
 * @param {Boolean} props.isHidden
 * @param {string} props.imgSrc
 * @param {string} props.petId
 * @param {string} props.petHasCheckedOut
 * @param {Boolean} props.hasFutureRes
 * @param {string} props.futureApptHeader
 * @param {string} props.futureApptDates
 * @param {string} props.futureApptTime
 * @param {string} props.futureApptService
 * @returns {JSX.Element|null}
 * @example const container = connect()(CheckOutCompletePet)
 */
export const CheckOutCompletePet = ({
  componentId,
  isHidden,
  imgSrc,
  petId,
  petHasCheckedOut,
  hasFutureRes,
  futureApptHeader,
  futureApptDates,
  futureApptTime,
  futureApptService,
}) => {
  if (isHidden) return null;

  return (
    <LayoutBox id={`${componentId}__petCard--${petId}`}>
      <LayoutCluster style={{ alignItems: "flex-start" }}>
        <img alt="petImage" src={imgSrc} />

        <TextPassage>
          <LayoutStack>
            <b>{petHasCheckedOut}</b>

            {hasFutureRes && (
              <LayoutBox padding="scale=0">
                <LayoutStack space="scale-0">
                  <b>{futureApptHeader}</b>
                  <span>{futureApptDates}</span>
                  <span>{futureApptTime}</span>
                  <span>{futureApptService}</span>
                </LayoutStack>
              </LayoutBox>
            )}
          </LayoutStack>
        </TextPassage>
      </LayoutCluster>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for pets in the hotel check out complete modal
 * @see {@link Views.Pet.CheckOutCompletePet}
 * @summary Located on the hotel check-out page
 * @memberOf Views.Pet
 * @function
 * @name HotelCheckOutCompletePet
 * @returns {JSX.Element|null}
 * @example <HotelCheckOutCompletePet />
 */
export const HotelCheckOutCompletePet = connect((state, { pet }) => {
  // Get future res if exists & convert datetimes to store timezone
  const futureRes = selectClosestFutureResForPet(pet?.petId)(state);
  const storeTimeZone = getStoreTimeZone(state, { storeNumber: futureRes?.storeNumber });
  const startDate = momentTz.tz(futureRes?.startDateTime, storeTimeZone);
  const endDate = momentTz.tz(futureRes?.endDateTime, storeTimeZone);

  // Constants strs
  const { hasCheckedOut, futureResMsg, checkInTime } = checkOutComplete;

  return {
    componentId: "HotelCheckOutCompletePet",
    imgSrc: getUnknownPetImage(pet),
    petId: pet?.petId,
    hasFutureRes: !!futureRes,
    petHasCheckedOut: `${pet?.petName}  ${hasCheckedOut}`,
    futureApptHeader: futureResMsg,
    futureApptDates: `${formatMonthDay(startDate)} - ${formatMonthDay(endDate)}`,
    futureApptTime: `${checkInTime} ${formatDayHourMinutes(startDate)}`,
    futureApptService: futureRes?.petServiceName,
  };
})(CheckOutCompletePet);
