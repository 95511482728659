import { connect } from "react-redux";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "../../core/actionTypes/customersActionTypes";
import PetParentComponent from "./petParent/PetParentComponent";
import { getCustomer, getIsCustomerActive } from "core/selectors/entitiesSelector";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);

  return {
    componentID: "petParentProfileSalonColumn-PetParentComponent",
    isLoading: createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state),
    isCustomerInStore: !!getCustomer(state, { customerKey }),
    isCustomerActive: getIsCustomerActive(state, { customerKey }),
    customerKey,
    colTitle: `${systemName.SALON} Pet parent profile details`,
    gridArea: "pet-parent-form",
    systemName: systemName.SALON,
  };
};

export default connect(
  mapStateToProps,
  null
)(PetParentComponent);
