import React from "react";
import { Layout } from "@prism/psm-ui-components";
import SearchFieldHeaderContainer from "dux/searchField/SearchFieldHeaderContainer";

export default function searchHeaderWrapper({ searchField }) {

  const SearchField = searchField;

  return (
    <Layout.Box>
      <SearchField />
    </Layout.Box>
  );
}
