import React from "react";
import styled from "styled-components";
import moment from "moment";

import GanttGroupTitle from "./GanttGroupTitle";
import GanttRow from "./GanttRowContainer";
import ganttConstants from "../../core/constants/ganttConstants";

import withWidthOnResize from "../common/hoc/withWidthOnResize";
import { StyledGanttRow } from "./GanttRowComponent";
import { color } from "../common/styles/theme";

const StyledGanttGroup = styled.div`
  display: flex;
  flex-direction: column;

  ${StyledGanttRow}:nth-child(even) {
    background-color: ${color.whiteGrey};
  }
`;

const NoAppointments = styled.div`
  padding-top: 10px;
  font-size: 16px;
  text-align: center;
`;

class GanttGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: moment().utcOffset(this.props.timeOffset),
      intervalId: null,
    };
    this.calculateX = this.calculateX.bind(this);
    this.xOffset = 3;
  }

  componentDidMount() {
    const intervalId = setInterval(() => {
      this.setState({ currentTime: moment().utcOffset(this.props.timeOffset) });
    }, 60000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  calculateX(startDateTime) {
    const { hours, currentWidth } = this.props;
    const startTime = moment(startDateTime);
    const itemHours = startTime.hours();
    const itemMinutes = startTime.minutes();
    return (
      (hours.findIndex(hour => hour.value === itemHours) * currentWidth) / hours.length +
      this.xOffset +
      ((itemMinutes / 60) * currentWidth) / hours.length
    );
  }

  render() {
    const {
      groupName,
      group,
      isWeekly,
      availableAppointments,
      GroupMemberComponent,
      widthRef,
      currentWidth,
      isFirstGroup,
      hours,
    } = this.props;
    const { currentTime } = this.state;

    return (
      <StyledGanttGroup>
        {group.length ? (
          <GanttGroupTitle
            isFirstGroup={isFirstGroup}
            currentWidth={currentWidth}
            groupName={groupName}
            isWeekly={isWeekly}
            currentTime={currentTime}
            calculateX={this.calculateX}
            groupCount={group.length}
            hours={hours}
          />
        ) : (
          <NoAppointments>Associate is not scheduled this week.</NoAppointments>
        )}
        {group.map((groupMember, index) => {
          const associateId = groupMember[0];
          const associateData = groupMember[1];
          return (
            <GanttRow
              key={associateId}
              innerRef={widthRef}
              groupMember={associateId}
              currentWidth={currentWidth}
              isWeekly={isWeekly}
              appointments={associateData.appointments}
              absences={associateData.absences}
              availableAppointments={availableAppointments[associateId] || []}
              GroupMemberComponent={GroupMemberComponent}
              hours={hours}
              height={ganttConstants.ROW_HEIGHT}
              appointmentHeight={ganttConstants.APPOINTMENT_HEIGHT}
              calculateX={this.calculateX}
              row={index}
            />
          );
        })}
      </StyledGanttGroup>
    );
  }
}

export default withWidthOnResize(GanttGroup);
