// @ts-nocheck
// these are legacy data/functions from prism and we're not going to check their types
const ValuesInPixels = {
  tiny: 12,
  small: 14,
  base: 16,
  regular: 18,
  big: 20,
  extraBig: 24,
  large: 28,
  extraLarge: 32,
  magnificent: 36,
  fantastic: 38,
  godlike: 42,
};

function CalculateEMFromPX(pxValue: number) {
  const emValue = pxValue / ValuesInPixels.base;

  return emValue;
}

export const fontSizes = {
  small: `${CalculateEMFromPX(ValuesInPixels.small)}rem`, // '0.75rem',
  medium: `${CalculateEMFromPX(ValuesInPixels.base)}rem`, // '1rem',
  regular: `${CalculateEMFromPX(ValuesInPixels.regular)}rem`, // '1.125rem',
  big: `${CalculateEMFromPX(ValuesInPixels.big)}rem`, // '1.25rem',
  extraBig: `${CalculateEMFromPX(ValuesInPixels.extraBig)}rem`, // '1.5rem',
  large: `${CalculateEMFromPX(ValuesInPixels.large)}rem`, // '1.75rem',
  extraLarge: `${CalculateEMFromPX(ValuesInPixels.extraLarge)}rem`, // '2 rem',
  magnificent: `${CalculateEMFromPX(ValuesInPixels.magnificent)}rem`, // '2.25 rem',
  fantastic: `${CalculateEMFromPX(ValuesInPixels.fantastic)}rem`, // '2.375 rem',
  godlike: `${CalculateEMFromPX(ValuesInPixels.godlike)}rem`, // '2.625 rem',
};

export const color = {
  tChalla: '#000',
  kalEl: '#1777f2',
  victorStone: '#fff',
  silverSurfer: '#565a5c',
  storm: '#b6b6b6',
  profX: '#333',
  ororoMunroe: '#dedede',
  borderGrey: '#bec0c4',
  whiteGrey: '#f2f2f2',
  darkGrey: '#333333',
  lightGrey: '#6b6b6b',
  veryLightGrey: '#eaeaea',
  errorRed: '#e62722',
  grey: '#a3a3a3',
  blueBackground: '#dbe4f0',
  tealish: '#23c7a1',
  darkTealBlue: '#007db4',
  duskyBlue: '#465f89',
  deepSkyBlue: '#2684ff',
  tableRowBackground: '#f8f8f8',
  tableTotalsBackground: '#eaeef4',
  wolverine: '#f4c43a',
};

const getControlStyles = (base, { isActive, isForm, backgroundColor }) => {
  const styles = {
    ...base,
    border: 'none',
    backgroundColor: backgroundColor || 'white',
    fontSize: fontSizes.regular,
    color: color.darkGrey,
  };
  // if (isForm) {
  return {
    ...styles,
    borderRadius: '0',
    isFocused: {
      outline: 'none',
      border: 'none',
      boxShadow: 'none',
    },
    borderBottom: isActive ? `1px solid ${color.deepSkyBlue}` : '1px solid #b5b5b5',
    boxShadow: isActive ? `0 1px 0 0 ${color.deepSkyBlue}` : 'inherit',
  };
  // }
  // return styles;
};

// this is the main function being used in the legacy component
export const buildCustomStyles = (props) => ({
  option: (styles, { data }) => {
    return {
      ...styles,
      color: data.isInactive || data.isDisabled ? `${color.lightGrey}` : `${color.darkGrey}`,
    };
  },
  control: (base) => getControlStyles(base, props),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: props.menuListMaxHeight ? `${props.menuListMaxHeight}px` : base.maxHeight,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    color: props.isDisabled ? `${color.lightGrey}` :'inherit'
  }),
  singleValue: (base) => ({
    ...base,
    opacity: props.isDisabled ? '50%' : 'inherit',
    color: props.error ? color.errorRed : props.isDisabled ? `${color.lightGrey}` :'inherit',
    top: '65%',
  }),
  placeholder: (base) => ({
    ...base,
    color: props.error ? color.errorRed : props.isDisabled ? `${color.lightGrey}` :'inherit',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    ':hover': props.isForm && props.isActive ? { color: `${color.deepSkyBlue}` } : { color: 'hsl(0, 0%, 60%)' },
    color: props.isForm && props.isActive ? `${color.deepSkyBlue}` : props.isDisabled ? `${color.lightGrey}` :'inherit',
  }),
});
