import React from "react";
import { connect } from "react-redux";
import EditSvgIcon from "@/dux/editSvgIcon/EditSvgIcon";
import { LayoutBox } from "@/layout/box/Box";
import { selectIsReservationDisabled } from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { color } from "@/web/common/styles/theme";

const HotelCheckInOutEditIcon = props => {
  const { componentId, fill, handleOnClick, styleProp } = props;

  return (
    <LayoutBox id={componentId} onClick={handleOnClick} style={styleProp} padding="scale-0">
      <EditSvgIcon fill={fill} />
    </LayoutBox>
  );
};

export const HotelCheckInOutEditIconContainer = connect((state, { onClick, isDisabled, style }) => {
  const isReservationDisabled = selectIsReservationDisabled(state);
  const componentId = "HotelCheckInOutEditIcon";

  if (isDisabled || isReservationDisabled)
    return {
      componentId,
      fill: color.grey,
      handleOnClick: () => {},
      styleProp: { ...{ cursor: "not-allowed" }, ...style },
    };

  return {
    componentId,
    handleOnClick: onClick,
    styleProp: { ...{ cursor: "pointer" }, ...style },
  };
})(HotelCheckInOutEditIcon);
