import { createSelector } from "reselect";
import getQuickViewState from "core/selectors/quickView/_qickViewState";
import { get } from "lodash/fp";

export const getPetAppointmentQuickViewCoordinates = createSelector(
  [getQuickViewState],
  quickViewState => ({
    xLoc: get(["petAppointmentQuickView", "xLoc"], quickViewState),
    yLoc: get(["petAppointmentQuickView", "yLoc"], quickViewState),
  }),
);

export const getPetAppointmentQuickViewIsVisible = createSelector([getQuickViewState], quickViewState =>
  get(["petAppointmentQuickView", "isVisible"], quickViewState),
);

