import { connect } from "react-redux";
import { createLoadingSelector } from "@/core/selectors/utils";
import {
  getFrequencyOptions,
  GET_FREQUENCY_OPTIONS,
} from "@/dux/frequencyOptions/frequencyOptionsActions";
import { eligibilityTypes } from "@/dux/eligibility/constants/eligibilityConstants";
import {
  getHotelEligibility,
  GET_HOTEL_ELIGIBILITY,
} from "@/dux/hotelEligibility/hotelEligibilityActions";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "../setSystemType/selectors/setSystemTypeSelectors";
import HotelBookingFlow from "../features/hotelBookingFlow/HotelBookingFlowContainer";
import getIsHotelWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import {
  getBookablePetsForTabsList,
  selectHotelBookingPetList,
} from "../features/hotelBookingFlow/hotelBookingFlowSelectors";
import { clearServicesCart } from "@/dux/servicesCart/servicesCartActions";
import { clearHotelBooking } from "../features/hotelBookingFlow/hotelBookingFlowActions";
import { clearCartsForPets } from "@/dux/petCartCard/actions/setPetCartCardActions";
import { compose } from "redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { getHotelItinerary } from "@/dux/hotelItinerary/hotelItineraryActions";
import { getFoodOptionsList } from "@/dux/foodOptionsList/foodOptionsListActions";
import selectIsDiscontinuedFoodsFeatureHidden from "../enableDisableWorkflowFeatureFlag/selectors/discontinuedFoods/getIsDiscontinuedFoodsWorkflowFeatureFlagHidden";
import { getMedicationsList } from "@/dux/medications/medicationActions";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  const pets = getBookablePetsForTabsList(state, { customerKey });

  return {
    isHidden:
      getIsHotelWorkflowFeatureFlagHidden(state) ||
      getSystemBookingFlow(state) !== systemName.HOTEL,
    componentID: "pendingAppointmentComponent",
    isLoadingFrequencies: createLoadingSelector([GET_FREQUENCY_OPTIONS])(state),
    isLoadingEligibility: createLoadingSelector([GET_HOTEL_ELIGIBILITY])(state),
    petIds: pets?.map(pet => pet?.petId),
    isMultiPet: selectHotelBookingPetList(state)?.length > 1,
    isFoodFeatureHidden: selectIsDiscontinuedFoodsFeatureHidden(state),
  };
};

const mapDispatchToProps = (dispatch, { router }) => ({
  getFrequencies: () => dispatch(getFrequencyOptions()),
  getEligibility: petIds =>
    dispatch(
      getHotelEligibility({
        eligibilityType: eligibilityTypes.BOOKING,
        payload: {
          pets: petIds,
        },
      }),
    ),
  resetBookingState: () => {
    dispatch(clearHotelBooking());
    dispatch(clearServicesCart());
    dispatch(clearCartsForPets());
  },
  loadRebookingItinerary: () => {
    const customerKey = router?.params?.customerKey;
    const itineraryId = router?.params?.itineraryId;
    if (itineraryId) {
      dispatch(getHotelItinerary({ customerKey, itineraryId }));
    }
  },
  loadFoodOptions: () => dispatch(getFoodOptionsList()),
  loadMedOptions: () => dispatch(getMedicationsList()),
});

const mergeProps = (stateProps, dispatchProps) => {
  const {
    componentID,
    isHidden,
    isLoadingFrequencies,
    isLoadingEligibility,
    petIds,
    isMultiPet,
    isFoodFeatureHidden,
  } = stateProps;
  const {
    getFrequencies,
    getEligibility,
    resetBookingState,
    loadRebookingItinerary,
    loadFoodOptions,
    loadMedOptions,
  } = dispatchProps;

  return {
    componentID,
    isHidden,
    isLoadingFrequencies,
    isLoadingEligibility,
    petIds,
    getFrequencies,
    getEligibility: () => {
      getEligibility(petIds);
    },
    isMultiPet,
    resetBookingState,
    loadRebookingItinerary,
    loadFoodAndMedOptions: () => {
      if (!isFoodFeatureHidden) loadFoodOptions();
      loadMedOptions();
    },
  };
};

export default compose(
  withRouteProps,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(HotelBookingFlow);
