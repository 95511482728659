import { connect } from "react-redux";
import { FlashMessage } from "@/dux//flashMessage/FlashMessage";
import { getIsCancelTrainingAppointmentFlashMessageOpen } from "@/dux/manageTrainingClassPage/manageTrainingClassPageSelectors";
import { alertThemesTypes } from "@/dux/alert/utils";
import { toggleCancelTrainingAppointmentFlashMessage } from "@/dux/manageTrainingClassPage/manageTrainingClassPageActions";

export const CancelTrainingAppointmentFlashMessage = connect(
  (state) => ({
    theme: alertThemesTypes.SUCCESS,
    display: getIsCancelTrainingAppointmentFlashMessageOpen(state),
    message: "Training appointment successfully canceled",
  }),
  (dispatch) => ({
    toggleCancelTrainingAppointmentFlashMessage: () => {
      dispatch(toggleCancelTrainingAppointmentFlashMessage());
    },
  }),
  (stateProps, dispatchProps) => {
    const { toggleCancelTrainingAppointmentFlashMessage } = dispatchProps;
    return {
      ...stateProps,
      onClose: () => {
        toggleCancelTrainingAppointmentFlashMessage();
      },
    };
  },
)(FlashMessage);
