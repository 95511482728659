import { connect } from "react-redux";
import QuickView from "web/common/quickView/QuickView";
import {
    getPetAppointmentQuickViewIsVisible,
    getPetAppointmentQuickViewCoordinates,
  } from "core/selectors/quickView/petAppointmentQuickViewSelectors";

const mapStateToProps = state => ({
  isVisible: getPetAppointmentQuickViewIsVisible(state),
  coordinates: getPetAppointmentQuickViewCoordinates(state),
  isPointer: false,
});

export default connect(mapStateToProps)(QuickView);
