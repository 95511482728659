//@ts-check
// Hotel Manage Room Details Selectors
import { createSelector } from "reselect";
import moment from "moment";

/**
 *
 * @param {object} state
 * @param {object} [state.hotelRoomManagementDetails]
 * @param {object} [state.hotelRoomManagementDetails.selectedRoomDetails]
 * @returns Selected Room Details for manage room page
 */
export const getHotelSelectedRoomDetails = state =>
  state?.hotelRoomManagementDetails?.selectedRoomDetails;

/**
 *
 * @param {object} state
 * @param {object} [state.hotelRoomManagementDetails]
 * @param {boolean} [state.hotelRoomManagementDetails.scheduleInactivation]
 * @returns
 */
export const selectHotelRoomManageSchuduleInactivation = state =>
  state?.hotelRoomManagementDetails?.scheduleInactivation;

/**
 *
 * @param {object} state
 * @param {object} [state.hotelRoomManagementDetails]
 * @param {boolean} [state.hotelRoomManagementDetails.roomNumber]
 * @returns
 */
export const selectHotelRoomNumber = state => state?.hotelRoomManagementDetails?.roomNumber;

export const selectHotelRoomSelectedRoomTypeBucketId = createSelector(
  [getHotelSelectedRoomDetails],
  (/** @type {object} */ selectedRoomDetails) => {
    const roomTypeBucketId = Object.keys(selectedRoomDetails)[0];
    return roomTypeBucketId || "";
  },
);

export const selectHotelRoomSuspensionsForSelectedRoom = createSelector(
  [getHotelSelectedRoomDetails],
  (/** @type {object} */ selectedRoomDetails) => {
    const room = Object.values(selectedRoomDetails)[0]?.rooms?.[0];
    return room?.suspensionDetails || [];
  },
);

/**
 *
 * @param {{suspensionId: string}} suspensionId
 * @returns
 */
export const selectHotelRoomSuspensionBySuspensionId = ({ suspensionId }) =>
  createSelector(
    [selectHotelRoomSuspensionsForSelectedRoom],
    selectedRoomSuspensionHistoryTableData => {
      return (
        selectedRoomSuspensionHistoryTableData?.find(
          (/** @type {{ suspensionId: string; }} */ suspension) =>
            suspension.suspensionId === suspensionId,
        ) || []
      );
    },
  );
