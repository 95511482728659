import React from "react";
import { Layout } from "@prism/psm-ui-components";
import { color } from "web/common/styles/theme";
import TrainingAppointmentCompleteConfirmationModal from "./TrainingAppointmentCompleteConfirmationModal";
import TrainingRedemptionStatus from "./TrainingRedemptionStatus";
import TrainingAppointmentCompleteCheckbox from "./TrainingAppointmentCompleteCheckbox";
import TrainingAttendeePetParentName from "./TrainingAttendeePetParentName";
import TrainingAttendeePetName from "./TrainingAttendeePetName";
import TrainingAttendeeGoals from "./TrainingAttendeeGoals";
import ResendMeetingLinkButton from "./ResendMeetingLinkButton";
import TrainingAttendeeClassNotes from "./TrainingAttendeeClassNotes";
import { TrainingAttendeeRescheduleButton } from "./TrainingAttendeeRescheduleButton";
import CancelTrainingAppointmentButton from "./CancelTrainingAppointmentButton";
import CancelTrainingAppointmentConfirmationModal from "./CancelTrainingAppointmentConfirmationModal";
import RescheduleTrainingAppointmentModal from "./RescheduleTrainingAppointmentModal";

export const ManageTrainingItineraryComponent = ({ attendeeId }) => {
  return (
    <Layout.Box
      borderWidth="box-border-width"
      borderColor="box-border-color-gray-100"
      style={{ width: "25rem" }}
    >
      <Layout.Stack>
        <Layout.Box
          padding="box-padding-4"
          style={{ borderBottom: `1px solid ${color.ororoMunroe}` }}
        >
          <Layout.Cluster justify="space-between">
            <TrainingAppointmentCompleteCheckbox attendeeId={attendeeId} />
            <TrainingRedemptionStatus attendeeId={attendeeId} />
          </Layout.Cluster>
        </Layout.Box>
        <Layout.Box padding="box-padding-4">
          <Layout.Stack space="stack-space-4">
            <Layout.Cluster justify="space-between">
              <TrainingAttendeePetParentName attendeeId={attendeeId} />
              <ResendMeetingLinkButton attendeeId={attendeeId} />
            </Layout.Cluster>
            <TrainingAttendeePetName attendeeId={attendeeId} />
            <TrainingAttendeeGoals attendeeId={attendeeId} />
            <TrainingAttendeeClassNotes attendeeId={attendeeId} />
            <Layout.Cluster justify="space-between">
              <CancelTrainingAppointmentButton attendeeId={attendeeId} />
              <TrainingAttendeeRescheduleButton attendeeId={attendeeId} />
            </Layout.Cluster>
            <TrainingAppointmentCompleteConfirmationModal attendeeId={attendeeId} />
            <CancelTrainingAppointmentConfirmationModal attendeeId={attendeeId} />
            <RescheduleTrainingAppointmentModal />
          </Layout.Stack>
        </Layout.Box>
      </Layout.Stack>
    </Layout.Box>
  );
};
