/* * ----------------------------------------------------------------------- * *\
  Full API for booking and managing service itineraries,
  which are the root containers for pet service appointments.
  This API is hosted on Salesforce and accessed through Azure APIM.

  See Confluence page for more details
    https://confluence.ssg.petsmart.com/display/PS/2.+Pet+Profile+V1

  See APIM for more details
    https://petm-apimanagement-qa.portal.azure-api.net/docs/services/system-services-booking-api-new-qa/operations/Add-Engagement?tags=&pattern=Pet+Profile&groupBy=
\* * ----------------------------------------------------------------------- * */

import { bookingAPI } from "../_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../../utils/envUtils";

/**
 * For System Services Booking API
 * - Get Pet Profile -
 * @param {*} { customerKey }
 * @param {*} { petKey }
 * @returns {*} AxiosPromise
 */
export function getPetProfile({ customerKey, petKey }) {
  return bookingAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/pets/${petKey}/profile`,
  );
}

/**
 * For System Services Booking API
 * - PUT Pet Profile -
 * @param {*} { customerKey }
 * @param {*} { petKey }
 * @param {*} { data }
 * @returns {*} AxiosPromise
 */
/**
 * - Update part of the customer profile
 * @param {*} { customerKey, data }
 * @returns {*} AxiosPromise
 */
export function updatePetProfileFull({ customerKey, petKey, data }) {
  return bookingAPI.put(
    `${endpointVersionString()}/customers/${customerKey}/pets/${petKey}/profile`,
    data,
  );
}

/**
 * - Update part of the pet profile
 * @param {*} { customerKey }
 * @param {*} { petKey }
 * @param {*} { data }
 * @returns {*} AxiosPromise
 */
export function updatePetProfilePartial({ customerKey, petKey, data }) {
  return bookingAPI.patch(
    `${endpointVersionString()}/customers/${customerKey}/pets/${petKey}/profile`,
    data,
  );
}
