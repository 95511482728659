// cc: QuickViewAppointment#3;Reduce;Reduce the QuickView and Update State

import { quickViewActionTypes } from "dux/quickViews/quickViewActions";
import quickViewInitState from "./_quickViewsInitState";

// Get the initial state for any quick view
const initialState = quickViewInitState();

/**
 * Responsible for reducing the state for the appointment QuickView of hte quick view
 * feature.
 *
 * @export petParentAppointmentQuickViewReducer
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns
 */
export default function petAppointmentQuickViewReducer(state = initialState, action) {
  switch (action.type) {
    case quickViewActionTypes.SHOW_APPOINTMENT_QUICKVIEW:
      return {
        ...state,
        isVisible: true,
        xLoc: action.xLoc,
        yLoc: action.yLoc,
        petServiceItemId: action.petServiceItemId,

      };
    case quickViewActionTypes.HIDE_APPOINTMENT_QUICKVIEW:
      return initialState;
    default:
      return state;
  }
}
