import {
  RESET_SELECTED_SUSPENSION_TYPE,
  SET_SELECTED_SUSPENSION_TYPE,
} from "dux/hotelSelectedSuspensionType/selectedSuspensionTypeActions";

/**
 * Represents the initial state of a suspension.
 *
 * @typedef {Object} initialState
 * @property {number | null} suspensionTypeId - The ID of the suspension type. Null if not selected.
 * @property {string} reasonTypeName - The name of the reason for suspension. Empty string if not selected.
 */
const initialState = {
  suspensionTypeId: null,
  reasonTypeName: "",
  suspensionId:"",
};
/**
 * Redux reducer function for managing the selected suspension type state.
 *
 * @param {Object} state - The current state of the selected suspension type.
 * @param {Object} action - The action object that contains information on how to modify the state.
 * @returns {Object} The updated state after applying the action.
 */
export const selectedSuspensionTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_SUSPENSION_TYPE:
      return {
        suspensionTypeId: action.suspensionType.suspensionTypeId,
        reasonTypeName: action.suspensionType.reasonTypeName,
        suspensionId: action.suspensionId,
      };
    case RESET_SELECTED_SUSPENSION_TYPE:
      return initialState;
    default:
      return state;
  }
};