import webUiReducer from "./web/webUiReducer";
import uiActionTypes from "../../actionTypes/ui/uiActionTypes";
import withClearReducer from "../../utils/withClearReducer";
import { combineReducers } from "redux";

const uiReducer = combineReducers({
  web: webUiReducer
});

export default withClearReducer(uiReducer, uiActionTypes.CLEAR_UI_STORE);
