import { connect } from "react-redux";
import { get } from "lodash/fp";
import {
  getCartDetails,
  getMaxLimitFormValues,
  getMaxPerBlockOptions,
  selectAdditionalBookingType,
} from "../../../core/selectors/cartSelectors";
import ReduceMaxLimitComponent from "./ReduceMaxLimitComponent";
import cartActionCreators from "../../../core/actionCreators/ui/web/cartActionCreators";
import { updatePetServiceItem } from "../../../core/actionCreators/petServiceItemsActionCreators";
import {
  getPet,
  getMaxPerBlockOrCheckReductionReasons
} from "../../../core/selectors/entitiesSelector";
import withInBookingOrModify from "../../../web/common/hoc/withInBookingOrModify";
import { getIsControlledOverbookHidden } from "@/dux/enhancedServicesList/EnhancedServicesListSelectors";

const mapStateToProps = (state, ownProps) => {
  const pet = getPet(state, ownProps);

  return {
    MAX_PER_BLOCK_OPTIONS: getMaxPerBlockOptions(state),
    appointments: getCartDetails(state),
    maxLimitFormValues: getMaxLimitFormValues(state, { petId: pet.petId }),
    reductionReasons: getMaxPerBlockOrCheckReductionReasons(state),
    controlOverbookEnabled: !getIsControlledOverbookHidden(state),
    additionalBookingType: selectAdditionalBookingType(state, {petId: pet.petId}),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { petId, inBookingOrModify } = ownProps;

  return {
    ...ownProps,
    setAdditionalBookingType: (additionalBookingType) => dispatch(cartActionCreators.setAdditionalBookingType({additionalBookingType, petId})),
    setMaxCheckInLimit: ({ maxCheckInCount }) =>
      dispatch(cartActionCreators.setMaxCheckInLimit({ maxCheckInCount, petId })),
    setMaxPerBlockLimit: ({ maxPerBlockCount }) =>
      dispatch(cartActionCreators.setMaxPerBlockLimit({ maxPerBlockCount, petId })),
    setMaxCheckInBool: ({ maxCheckInBool }) =>
      dispatch(cartActionCreators.setMaxCheckInBool({ maxCheckInBool, petId })),
    setMaxPerBlockBool: ({ maxPerBlockBool }) =>
      dispatch(cartActionCreators.setMaxPerBlockBool({ maxPerBlockBool, petId })),
    setMaxPerBlockOrCheckReductionReason: ({ maxPerBlockOrCheckReductionReason }) =>
      dispatch(
        cartActionCreators.setMaxPerBlockOrCheckReductionReason({
          petId,
          maxPerBlockOrCheckReductionReason
        })
      ),
    onUpdatePetServiceItem: ({ petServiceItem, data }) => {
      const isManualAppointment = inBookingOrModify
        ? get("maxPerBlockCount", data) === 0
          ? true
          : undefined
        : true;

      if (petServiceItem) {
        const serviceItem = {
          customerKey: petServiceItem.customer,
          itineraryId: petServiceItem.itinerary,
          engagementId: petServiceItem.engagement,
          petServiceItemId: petServiceItem.petServiceItemId,
          dispatchToCart: true,
          data: {
            ...data,
            isManualAppointment,
          },
        };

        dispatch(updatePetServiceItem(serviceItem));
      }
    },
  }
};

export default withInBookingOrModify(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ReduceMaxLimitComponent)
);
