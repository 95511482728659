import React from "react";
import { connect } from "react-redux";
import FoodBookingAddButtonContainer from "dux/bookingFeeding/FoodBookingAddButtonContainer";
import { selectIsReservationDisabled } from "@/dux/hotelItinerary/hotelItinerarySelectors";
import Section from "../common/Section";
import FoodAddButtonContainer from "./buttons/FoodAddButtonContainer";
import { getFoodIds } from "../food/foodsSelector";

const mapStateToProps = (state, ownProps) => {
  const { petId, locationType, topLine = true, title = "Food Details" } = ownProps;
  const foodIds = getFoodIds(state, { petId });
  const isReservationDisabled = selectIsReservationDisabled(state);

  return {
    petId,
    title,
    addButton: (
      <>
        <FoodAddButtonContainer petId={petId} buttonDisabled={isReservationDisabled} />
        <FoodBookingAddButtonContainer petId={petId} locationType={locationType} />
      </>
    ),
    topLine,
    padding: foodIds.length > 0 ? "2.5rem 0 0 0" : "2.5rem 0 3rem 0",
    titleMarginBottom: foodIds.length > 0 && "none",
    paddingTop: "10px",
  };
};

export default connect(mapStateToProps)(Section);
