import { connect } from "react-redux";
import { getPastAppointmentsByCustomer } from "../../../../../core/selectors/entitiesSelector";
import { getCurrentCustomerKey } from "../../../../../core/selectors/persistent/customer/customerSelectors";
import CommonAppointmentComponent from "../common/CommonAppointmentListComponent";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  return {
    type: "past",
    title: "Past Appointments",
    appointments: getPastAppointmentsByCustomer(state, { customerKey }),
    componentID: "PastAppointmentsList",
  };
};

export default connect(mapStateToProps)(CommonAppointmentComponent);
