import React from "react";
import styled from "styled-components";
import { fontSizes } from "web/common/styles/responsive/fonts";
import Checkbox from "../Checkbox";

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: ${fontSizes.regular};
  margin-top: ${props => setMargin(props)};
  margin-left: ${props => setMargin(props)};
  margin-right: ${props => setMargin(props)};
  margin-bottom: ${props => setMargin(props)};
`;

/** ----------------------------------------------------------------------- * */
// SET CSS CONFIGURATIONS(S)
function setMargin(props) {
  if (props.spaceLeft) {
    return "1em";
  }

  if (props.spaceRight) {
    return "1em";
  }

  if (props.spaceTop) {
    return "1em";
  }

  if (props.spaceBottom) {
    return "1em";
  }
  return "0";
}

export default function FlexWrappedCheckBox(props) {
  const { isHidden, disabled, checked, labelPosition, label, onHandleChange } = props;

  if (!isHidden) {
    return (
      <SectionWrapper {...props}>
        <Checkbox
          disabled={disabled}
          checked={checked}
          labelPosition={labelPosition}
          label={label}
          onChange={onHandleChange}
        />
      </SectionWrapper>
    );
  }

  return null;
}
