import { TOGGLE_SCHEDULE_SUSPENSION_CANCEL_MODAL } from "dux/scheduleSuspensionCancelModal/scheduleSuspensionCancelModalActions";

const initialState = { isHidden: true, suspensionId: null, startDate: "" };

const scheduleSuspensionCancelModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SCHEDULE_SUSPENSION_CANCEL_MODAL:
      return {
        ...state,
        isHidden: !state.isHidden,
        suspensionId: action.suspensionId,
        startDate: action.startDate,
      };
    default:
      return state;
  }
};

export default scheduleSuspensionCancelModalReducer;
