import salonsActionTypes from "../actionTypes/salonsActionTypes";

export const loadAllSalons = () => ({
  type: salonsActionTypes.LOAD_ALL_SALONS
});

export const loadAllSalonsRequest = () => ({
  type: salonsActionTypes.LOAD_ALL_SALONS_REQUEST
});

export const loadAllSalonsSuccess = ({ salons }) => ({
  type: salonsActionTypes.LOAD_ALL_SALONS_SUCCESS,
  payload: salons
});

export const loadAllSalonsFailure = ({ error }) => ({
  type: salonsActionTypes.LOAD_ALL_SALONS_FAILURE,
  error
});

export default {
  loadAllSalons,
  loadAllSalonsRequest,
  loadAllSalonsSuccess,
  loadAllSalonsFailure
};
