import { featureFlagPersistentCookieToggleActionTypes } from "dux/featureFlagPersistentCookie/featureFlagPersistentCookieToggleActions";
import getCookieValueOrNull from "core/utils/cookies/cookieValueOrNull";
import dispalyFeatureFlagIconConstants from "web/displayFeatureFlagIcon/displayFeatureFlagIconConstants";

const initialState = {
  isCookieSet: getCookieValueOrNull(dispalyFeatureFlagIconConstants.PERSIST_COOKIE_KEY),
};

const featureFlagPersistentCookieToggleReducer = (state = initialState, action) => {
  switch (action.type) {
    case featureFlagPersistentCookieToggleActionTypes.SET_PERSISTENT_COOKIE_ICON:
      return {
        ...state,
        isCookieSet: action.isCookieSet,
      };
    default:
      return state;
  }
};

export default featureFlagPersistentCookieToggleReducer;
