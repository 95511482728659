import React from "react";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutBox } from "@/layout/box/Box";

export default function SpeciesCounts(props) {
  const { isHidden, componentId, appointments } = props;

  if (!isHidden) {
    const dogText = appointments.dog === 1 ? " Dog" : " Dogs";
    const catText = appointments.cat === 1 ? " Cat" : " Cats";

    return (
      <LayoutBox id={componentId} padding="scale-0">
        <LayoutStack>
          <div id={`${componentId}__dog`}>{`${appointments.dog} ${dogText}`}</div>
          <div id={`${componentId}__cat`}>{`${appointments.cat} ${catText}`}</div>
        </LayoutStack>
      </LayoutBox>
    );
  }

  return null;
}
