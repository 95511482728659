export const GET_FOOD_OPTIONS_LIST = "GET_FOOD_OPTIONS_LIST";
export const GET_FOOD_OPTIONS_LIST_REQUEST = "GET_FOOD_OPTIONS_LIST_REQUEST";
export const GET_FOOD_OPTIONS_LIST_SUCCESS = "GET_FOOD_OPTIONS_LIST_SUCCESS";
export const GET_FOOD_OPTIONS_LIST_FAILURE = "GET_FOOD_OPTIONS_LIST_FAILURE";
export const SET_FOOD_OPTIONS_LIST = "SET_FOOD_OPTIONS_LIST";

export const getFoodOptionsList = () => ({
  type: GET_FOOD_OPTIONS_LIST,
});

export const getFoodOptionsListRequest = () => ({
  type: GET_FOOD_OPTIONS_LIST_REQUEST,
});

export const getFoodOptionsListSuccess = () => ({
  type: GET_FOOD_OPTIONS_LIST_SUCCESS,
});

export const getFoodOptionsListFailure = error => ({
  type: GET_FOOD_OPTIONS_LIST_FAILURE,
  error,
});

export const setFoodOptionsList = foodOptions => ({
  type: SET_FOOD_OPTIONS_LIST,
  foodOptions,
});
