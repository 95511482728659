import { endpointVersionString } from "../../utils/envUtils";
import { associateWebAPI } from "./_axiosConfigAssociateWeb";

/**
 * Retrieves the capabilities of a store from
 * the Associate Web BFF API.
 *
 * @memberof APIs.AssociateWeb
 *
 * @param {string} storeNumber - The store number for which to fetch capabilities.
 * @returns {AxiosPromise} - The axios promise that would resolve with the capabilities of the store.
 */
export function fetchStoreCapabilities(storeNumber) {
  return associateWebAPI.get(`${endpointVersionString()}/stores/${storeNumber}/capabilities`);
}