import { put, select, takeEvery, all, fork } from "redux-saga/effects";

import { modalTypes } from "../constants/modalConstants";
import ganttActionTypes from "../../core/actionTypes/ganttActionTypes";
import {
  getSelectedAppointment,
  getSelectedAssociateWeekly
} from "../../core/selectors/commonSelector";
import { showDashboardModal } from "../../core/actionCreators/ui/web/dashboardActionCreators";
import { showBookingModal } from "../../core/actionCreators/bookingActionCreators";
import { onAssociateClick } from "../../core/actionCreators/ui/web/commonActionCreators";

function* onAssociateRowClick({ fromDashboard, isWeekly, groupMember }) {
  const selectedAppointment = yield select(getSelectedAppointment);
  const associateId = isWeekly ? yield select(getSelectedAssociateWeekly) : groupMember;

  if (!selectedAppointment) {
    yield put(onAssociateClick({ associateId }));
    fromDashboard
      ? yield put(showDashboardModal(modalTypes.GANTT_ZOOM))
      : yield put(showBookingModal(modalTypes.GANTT_ZOOM));
  }
}

function* watchOnAssociateClick() {
  yield takeEvery(ganttActionTypes.ASSOCIATE_CLICK, onAssociateRowClick);
}

export default function* ganttSaga() {
  yield all([fork(watchOnAssociateClick)]);
}
