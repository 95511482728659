import React from "react";
import styled from "styled-components";
import CommonButton from "web/common/commonButton";
import VoidTransactionModalHeader from "../shared/VoidTransactionModalHeader";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 188px;
`;

export default function CompleteModalContent({ setDone }) {
  return (
    <SectionWrapper>
      <VoidTransactionModalHeader title="Transaction Voided" />
      <CommonButton label="Done" width="160px" height="49px" onClick={setDone} />
    </SectionWrapper>
  );
}
