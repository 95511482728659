import verifyPhoneNumber from "../validationUtils/phoneNumberValidation";
import formatPhoneNumberNANP from "./formatPhoneNumber";
import convertStringToNumbers from "./stringToNumberConversion";

/**
 * Format phone input value if passes validation
 * @param {String} phoneNumber - Phone number to format
 * @returns {String}
 */
export default function formatPhoneInputField(phoneNumber) {
  return verifyPhoneNumber(phoneNumber)
    ? formatPhoneNumberNANP(phoneNumber)
    : convertStringToNumbers(phoneNumber);
}
