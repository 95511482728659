import React, { useState } from "react";
import { connect } from "react-redux";
import { Tabs } from "@petsmart-ui/sparky";
import { Layout, Text } from "@prism/psm-ui-components";
import { selectHotelBookingRooms } from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import Img from "@/web/common/Img";
import isEmpty from "lodash/isEmpty";
import getPetName from "../PetName/selectors/getPetName";
import { tabIconType, tabIcons } from "./hotelBookingRoomAndPetTabsConstants";
import { isMultiPetRooms } from "@/web/features/hotelBookingFlow/hotelBookingFlowUtils";

const TitleWithIcon = ({ title, tabIcon }) => {
  const iconSrc = tabIcons[tabIcon];
  return (
    <Layout.Cluster>
      <Text>{title}</Text>
      {iconSrc && (
        <Img
          style={{ width: "20px", height: "20px", paddingLeft: "2px", alignSelf: "center" }}
          src={iconSrc}
        />
      )}
    </Layout.Cluster>
  );
};

const RoomTabs = ({ componentId, rooms, getTabIconType, children }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const roomIds = Object.keys(rooms);
  const roomsKey = roomIds?.map(roomId => `${roomId}${getTabIconType(roomId)}`).join("-");

  return roomIds?.length === 1 ? (
    children(roomIds[0])
  ) : (
    <>
      <Tabs key={`${componentId}-${roomsKey}`} activeIndex={tabIndex} onChange={setTabIndex}>
        {roomIds?.map(roomId => (
          <Tabs.Panel
            key={`roomTab-${roomId}`}
            title={<TitleWithIcon title={`Room ${roomId}`} tabIcon={getTabIconType(roomId)} />}
          />
        ))}
      </Tabs>

      {children(roomIds[tabIndex])}
    </>
  );
};

const PetTabs = ({
  componentId,
  pets,
  getPetName,
  getTabIconType,
  isServiceSelectedForHost,
  children,
  isMultiPet,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const petsKey = pets?.map(petId => `${petId}${getTabIconType(petId)}`).join("-");

  return !isMultiPet ? (
    children(pets[0])
  ) : (
    <>
      <Tabs key={`${componentId}-${petsKey}`} activeIndex={tabIndex} onChange={setTabIndex}>
        {pets?.map(petId => {
          return (
            <Tabs.Panel
              key={`petTab-${petId}`}
              title={<TitleWithIcon title={getPetName(petId)} tabIcon={getTabIconType(petId)} />}
            />
          );
        })}
      </Tabs>

      {tabIndex === 0 || isServiceSelectedForHost(pets[tabIndex]) ? (
        children(pets[tabIndex])
      ) : (
        <Text color="text-color-red">{`You must choose a host service first.`}</Text>
      )}
    </>
  );
};

/**
 *
 * @param {object} props
 * @param {string} props.componentID
 * @param {string} props.isHidden
 * @param {object} props.rooms expecting the following format: { roomId1: [petId1, petId2], roomId2: [petId3] ... }
 * @param {function} props.children expecting a function that will return a component specific to a given pet
 * @param {function} props.getPetName pet name selector
 * @param {function} props.getRoomTabIconType Optional prop to determine which icon to chow on room tab
 * @param {function} props.getPetTabIconType Optional prop to determine which icon to chow on pet tab
 * @returns
 */
const HotelBookingRoomAndPetTabsComponent = ({
  componentID,
  isHidden,
  rooms,
  children = () => {},
  getPetName,
  getRoomTabIconType = () => tabIconType.NONE,
  getPetTabIconType = () => tabIconType.NONE,
  isServiceSelectedForHost = () => true,
}) => {
  if (isHidden) return null;

  return (
    <Layout.Box id={componentID}>
      <RoomTabs rooms={rooms} getTabIconType={getRoomTabIconType} componentId={componentID}>
        {roomId => (
          <PetTabs
            key={`room${roomId}-pets`}
            pets={rooms[roomId]}
            getPetName={getPetName}
            getTabIconType={getPetTabIconType}
            isServiceSelectedForHost={isServiceSelectedForHost}
            componentId={componentID}
            isMultiPet={isMultiPetRooms(rooms)}
          >
            {children}
          </PetTabs>
        )}
      </RoomTabs>
    </Layout.Box>
  );
};

export const HotelBookingRoomAndPetTabs = connect(
  (
    state,
    {
      componentID,
      rooms = selectHotelBookingRooms(state),
      children,
      getRoomTabIconType,
      isServiceSelectedForHost,
      getPetTabIconType,
    },
  ) => ({
    componentID: `${componentID}_petTabs`,
    isHidden: isEmpty(rooms),
    rooms,
    children,
    getPetName: petId => getPetName(state, { petId }),
    isServiceSelectedForHost,
    getRoomTabIconType,
    getPetTabIconType,
  }),
)(HotelBookingRoomAndPetTabsComponent);
