import { connect } from "react-redux";

import AppointmentsComponent from "./appointments/AppointmentsComponent";
import itinerariesActionTypes from "../../core/actionTypes/itinerariesActionTypes";
import petsActionTypes from "../../core/actionTypes/petsActionTypes";
import customersActionTypes from "../../core/actionTypes/customersActionTypes";
import { createLoadingSelector } from "../../core/selectors/utils";
import { getCurrentCustomerKey } from "../../core/selectors/persistent/customer/customerSelectors";

const mapStateToProps = state => ({
  componentID: "appointmentsColumnSalon-AppointmentsComponent",
  isLoading: createLoadingSelector([
    itinerariesActionTypes.LOAD_ITINERARIES_BY_CUSTOMER,
    petsActionTypes.LOAD_PETS_BY_CUSTOMER,
    customersActionTypes.LOAD_CUSTOMER,
  ])(state),

  gridArea: "appointments",
  customerKey: getCurrentCustomerKey(state),
  disabled: !getCurrentCustomerKey(state),
  fromPetParent: true,
});

export default connect(mapStateToProps, null)(AppointmentsComponent);
