import { pick } from "lodash/fp";
import {
  getPageMountReasons,
  getActionClickedReasons,
} from "dux/eligibility/selectors/getReasonsSelectors";

function addPetReasonsToEligibility(selectedPets, eligibility) {
  let mergedEligibilityReasons = { ...eligibility };

  if (selectedPets && eligibility) {
    mergedEligibilityReasons = {
      ...mergedEligibilityReasons,
      pets: pick(selectedPets, eligibility.pets),
    };
  }

  return mergedEligibilityReasons;
}

/* eslint-disable import/prefer-default-export */
export function constructCustomerAndPetSalonEligibility(state, isWarnings, selectedPets) {
  const eligibility = isWarnings ? getActionClickedReasons(state) : getPageMountReasons(state);

  return addPetReasonsToEligibility(selectedPets, eligibility);
}

export function mergeCustomerAndPetHotelEligibilityReasons(state, selectedPets) {
  const eligibility = getActionClickedReasons(state);

  return addPetReasonsToEligibility(selectedPets, eligibility);
}
