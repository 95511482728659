import * as React from 'react';
import { psx } from '../../../utils/';

export interface ShiftByProps extends React.ComponentPropsWithoutRef<'div'> {
  /** Used to turn on a background color so that the ShiftBy is visible for debugging. */
  debug?: boolean;
  /** Move the element to the left or right. */
  x?: number;
  /** Move the element up or down. */
  y?: number;
  /** Completely removes the component from the DOM. Useful for feature flags or t hide or show
   * a component. */
  isHidden?: boolean;
}

/**
 * Reach for this component seldomly.
 *
 * It is useful in obtaining "pixel-perfect" designs, but ideally we are promoting
 * the use of our design system and the tokens chosen by our designers.
 */
export const ShiftBy: React.FunctionComponent<ShiftByProps> = ({
  x = 0,
  y = 0,
  debug = false,
  style = {},
  children,
  isHidden = false,
  ...props
}) => {
  if (isHidden) {
    return null;
  }

  return (
    <div
      className={psx('psm', 'psm-layout__shiftBy')}
      style={{ transform: `translate(${x}px, ${y}px)`, backgroundColor: debug ? 'hotpink' : 'inherit', ...style }}
      {...props}
    >
      {children}
    </div>
  );
};
