import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  loadHotelSpecialsManualRequest,
  LOAD_HOTEL_MANUAL_SPECIALS,
  loadHotelSpecialsManualFailure,
  loadHotelSpecialsManualSuccess,
  PATCH_HOTEL_MANUAL_SPECIALS,
  patchHotelManualSpecialsFailure,
  removeHotelSpecialsManualFailure,
  REMOVE_HOTEL_MANUAL_SPECIALS,
  LOAD_BOOKING_HOTEL_MANUAL_SPECIALS,
  loadBookingHotelSpecialsManualRequest,
  loadBookingHotelSpecialsManualSuccess,
  loadBookingHotelSpecialsManualFailure,
  patchHotelManualSpecialsRequest,
  patchHotelManualSpecialsSuccess,
  removeHotelSpecialsManualRequest,
  removeHotelSpecialsManualSuccess,
} from "dux/applySpecial/applyManaulSpecialsHotelActions";
import { fetchSpecials } from "core/services/systemServicesBooking/specialsEndPoints";
import { fetchHotelManualSpecialsByIdEndpoint } from "core/services/cart/hotelManualSpecialsEndpoints";
import { deleteHotelManualSpecialsEndpoint } from "@/core/services/systemServicesBooking/getCreateUpdateDeleteHotelItineraryEndpoints";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getCurrentItinerary } from "@/core/selectors/checkInOutSelector";
import { patchApplySpecialsEndpoint } from "core/services/associateWebBff/applySpecialsEndpoint";
import { saveHotelItineraryResponse } from "../hotelItinerary/hotelItinerarySaga";

function* onLoadManualSpecialsHotel({ itineraryId }) {
  try {
    yield put(loadHotelSpecialsManualRequest());

    const response = yield call(fetchSpecials, { itineraryId });
    const specials = response.data;

    // pull out ony what is needed for the specials state.
    const manualSpecials = specials.map(special => {
      return {
        type: special.type,
        specialId: special.specialId,
        name: special.name,
        code: special.code,
        isManual: special.isManual,
        appliesToSkus: special.appliesToSkus,
      };
    });

    yield put(loadHotelSpecialsManualSuccess({ manualSpecials }));
  } catch (error) {
    yield put(loadHotelSpecialsManualFailure(error));
  }
}

// TODO: this saga isn't being used, calls the same endpoint as onLoadManualSpecialsHotel, & updates
// same state. Only difference is this one doesn't filter the response, delete it & related actions?
// function* onLoadManualBookingSpecialsHotel({ itineraryId }) {
//   try {
//     yield put(loadBookingHotelSpecialsManualRequest());

//     const response = yield call(fetchHotelManualSpecialsByIdEndpoint, { itineraryId });
//     const { specials } = response.data.result;

//     yield put(loadBookingHotelSpecialsManualSuccess({ manualSpecials: specials }));
//   } catch (error) {
//     yield put(loadBookingHotelSpecialsManualFailure(error));
//   }
// }

function* onPatchHotelManualSpecials({ customerId, itineraryId, data }) {
  try {
    yield put(patchHotelManualSpecialsRequest());
    const response = yield call(patchApplySpecialsEndpoint, {
      customerId,
      itineraryId,
      data,
    });

    // Save itinerary in state
    yield call(saveHotelItineraryResponse, { responseData: response?.data?.result });

    yield put(patchHotelManualSpecialsSuccess());
  } catch (error) {
    yield put(patchHotelManualSpecialsFailure(error));
  }
}

function* onRemoveManualSpecialsHotel({ specialCode, petId }) {
  try {
    yield put(removeHotelSpecialsManualRequest());
    const customerId = yield select(getCurrentCustomerKey);
    const itineraryId = yield select(getCurrentItinerary);

    const response = yield call(deleteHotelManualSpecialsEndpoint, {
      itineraryId,
      customerId,
      specialCode,
      petId,
    });

    // Save itinerary in state
    yield call(saveHotelItineraryResponse, { responseData: response?.data?.result });

    yield put(removeHotelSpecialsManualSuccess());
  } catch (error) {
    yield put(removeHotelSpecialsManualFailure(error));
  }
}

function* watchLoadManualSpecialsHotel() {
  yield takeEvery(LOAD_HOTEL_MANUAL_SPECIALS, onLoadManualSpecialsHotel);
}

// function* watchLoadManualBookingSpecialsHotel() {
//   yield takeEvery(LOAD_BOOKING_HOTEL_MANUAL_SPECIALS, onLoadManualBookingSpecialsHotel);
// }

function* watchPatchHotelManualSpecials() {
  yield takeEvery(PATCH_HOTEL_MANUAL_SPECIALS, onPatchHotelManualSpecials);
}

function* watchRemoveManualSpecialsHotel() {
  yield takeEvery(REMOVE_HOTEL_MANUAL_SPECIALS, onRemoveManualSpecialsHotel);
}

export default function* applyManualSpecialsHotelSaga() {
  yield all([
    watchLoadManualSpecialsHotel(),
    // watchLoadManualBookingSpecialsHotel(),
    watchPatchHotelManualSpecials(),
    watchRemoveManualSpecialsHotel(),
  ]);
}
