import React from 'react';
import './styles.css';
import { pluck } from '../utils';
import { GLOBAL_TOKENS } from '../scaleToken';

// Sets the interface for the Box layout custom properties.
interface SwitcherProps<C extends React.ElementType> {

  /** A CSS padding value */
  space?: keyof typeof GLOBAL_TOKENS['SCALE'];

  /** value to control the switch between a horizontal and a vertical layout */
  threshold?: keyof typeof GLOBAL_TOKENS['SCALE'];

  highlight?: Number;

  limit?: Number;

  // Place styles on the component root vs intermediary div
  styleRoot?: any;
}

  // Include types that are not part of SwitcherProps, such as children,
type Props <C extends React.ElementType> = React.PropsWithChildren<SwitcherProps<C>> & React.ComponentPropsWithoutRef<C>;

/**
   * Switch directly from a horizontal (one row) to a vertical (one column)
   * @returns
   */
export const LayoutSwitcher = <C extends React.ElementType>({
  space = 'scale-S1',
  threshold = 'scale-G10',
  highlight = 0,
  limit = 0,
  children,
  styleRoot,
  style,
  ...props

}: Props<C>): React.ReactElement => {
  let classes: string = 'pplt-layout__switcher';

  if (highlight) {
    classes = classes.concat(' ', `pplt-layout__switcher--highlight-${highlight}`);
  };

  if (limit) {
    classes = classes.concat(' ', `pplt-layout__switcher--limit-${limit}`);
  };

  return (
    <div
      className={classes}
      style={styleRoot}
      {...props}
    >
      <div
          id="switcher_intermediary"
          style={{
            '--space': pluck(GLOBAL_TOKENS.SCALE, space),
            '--threshold': pluck(GLOBAL_TOKENS.SCALE, threshold),
            ...style,
          }}
      >
          {children}
      </div>
    </div>
  );
};
