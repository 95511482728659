import { createSelector } from "reselect";
import { get } from "lodash/fp";
import getPrimaryAddress from "./getPrimaryAddress";

/* * ----------------------------------------------------------------------- * *\
    Selector that returns normalized Values form the customers Primary Address

    Example of use:  getPrimaryAddressID(state, { customerKey });
\* * ----------------------------------------------------------------------- * */
const getPrimaryAddressID = createSelector([getPrimaryAddress], primaryAddress =>
  get("addressId", primaryAddress)
);

export default getPrimaryAddressID;
