import { MILLISECONDS_IN_MINUTE } from "@/dux/utils/dateUtils/dateTimeConstants";

/**
 * Responsible for taking a timestamp and returning a new timestamp with the specified offset added
 *
 * @export incrementTimestamp
 * @param {number} timestamp - Date time stamp
 * @param {number} offet - Integer value to increment
 * @returns {number} - Date time stamp
 */

export default function incrementTimestamp(timestamp, offset) {
  return timestamp && timestamp + offset * MILLISECONDS_IN_MINUTE;
}
