import { connect } from "react-redux";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import CommonButton from "web/common/commonButton";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { history } from "dux/utils/browser/browserHistory";
import getIsDdcPackagesWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ddcPackages/getIsDdcPackagesWorkflowFeatureFlagHidden";
import { getIsSRCAgent } from "@/core/selectors/persistentSelectors";
import { routePaths } from "@/core/constants/routePaths";
import { isBookAppointmentHotelDisabled } from "../eligibility/helperUtils/checkIsBookingDisabled";

export const PURCHASE_DDC_PACKAGES = "Purchase DDC Play & Save";

export const isButtonHidden = (isSRCAgent, isFeatureFlagHidden, isInPetsHotelSystemType) =>
  isSRCAgent || isFeatureFlagHidden || !isInPetsHotelSystemType;

export const DdcPackagesPurchaseButton = connect(
  state => {
    const customerKey = getCurrentCustomerKey(state);
    const isSRCAgent = getIsSRCAgent(state);
    const isFeatureFlagHidden = getIsDdcPackagesWorkflowFeatureFlagHidden(state);
    const isInPetsHotelSystemType = getSystemBookingFlow(state) === systemName.HOTEL;
    const isHidden = isButtonHidden(isSRCAgent, isFeatureFlagHidden, isInPetsHotelSystemType);

    return {
      isHidden,
      componentId: "ddcPurchasePackages-commonButton",
      label: PURCHASE_DDC_PACKAGES,
      customerKey,
      disabled: isBookAppointmentHotelDisabled(state, customerKey),
    };
  },
  () => ({
    redirect: ({ customerKey }) => {
      history.push(`${routePaths.PURCHASE}/${customerKey}${routePaths.DDC_PACKAGES}`);
    },
  }),
  (propsFromState, propsFromDispatch) => {
    const { customerKey } = propsFromState;
    const { redirect } = propsFromDispatch;

    return {
      ...propsFromState,
      onClick: () => redirect({ customerKey }),
    };
  },
)(CommonButton);
