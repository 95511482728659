import { connect } from "react-redux";
import AppHeader from "./AppHeader";
import { getJobRole } from "../../core/selectors/persistentSelectors";
import { headerConfigConstants } from "../../core/constants/headerConfigConstants";

// TODO: move to AppHeader.js so all containers are co-located with the component.
const mapStateToProps = (state, ownProps) => ({
  title: "Associate Profiles",
  config: headerConfigConstants.APPOINTMENT,
  jobRole: getJobRole(state, ownProps),
  backgroundImage: ownProps.backgroundImage
});

// const mapDispatchToProps = (dispatch) => ({
//     keyName: ({  }) => dispatch( actionName({  })),
// });

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(AppHeader);
