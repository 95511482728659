import React from "react";
import moment from "moment-timezone";
import { createSelector } from "reselect";
import { Text } from "@prism/psm-ui-components";
import { getStoreTimeZone, getAssociate, getPetFullName } from "@/core/selectors/entitiesSelector";
import { getProps } from "@/core/selectors/commonSelector";

export const getSelectedTrainingPetService = (state) =>
  state.scheduleTrainingPage.selectedTrainingPetService;

export const getIsPurchaseTrainingServiceMessageHidden = (state) =>
  state.scheduleTrainingPage.isPurchaseTrainingServiceMessageHidden;

export const getIsDateSelectionHidden = (state) => state.scheduleTrainingPage.isDateSelectionHidden;

export const getTrainingEngagementNote = (state) =>
  state.scheduleTrainingPage.trainingEngagementNote;

export const getTrainingAppointment = (state) => state.scheduleTrainingPage.appointment;

export const getIsTrainingAppointmentSetModalOpen = (state) =>
  state.scheduleTrainingPage.isTrainingAppointmentSetModalOpen;

/**
 * @export getTrainingAppointmentCardInfo
 * @param {number} customerKey - Unique identifier associated with a customer
 * @param {number} petId - Unique identifier for a pet
 * @param {number} storeNumber - Unique identifier for a store number
 * @param {number} associateId - Unique identifier for an associate
 * @param {string} startDateTime - Timestamp representing the appointment start time
 * @returns {Object[]} trainingAppointmentCardInfo - List of React elements to be displayed in card
 * @example getTrainingAppointmentCardInfo(state, { customerKey, petId, storeNumber, associateId, startDateTime })
 **/
export const getTrainingAppointmentCardInfo = createSelector(
  [getPetFullName, getAssociate, getStoreTimeZone, getProps],
  (petName, associate, storeTimeZone, { startDateTime }) => {
    const appointmentTime = moment.tz(startDateTime, storeTimeZone).format("ddd, MMM D h:mm A");
    const associateName = associate?.associateName;
    return [
      { id: 0, label: <Text bold>{petName}</Text> },
      { id: 1, label: <Text>Virtual Dog Training - Private Class</Text> },
      { id: 2, label: <Text>{appointmentTime}</Text> },
      {
        id: 3,
        label: (
          <Text align="right" bold>
            {associateName}
          </Text>
        ),
      },
    ];
  },
);

export const getIsCancelTrainingAppointmentModalOpen = (state) =>
  state.scheduleTrainingPage.isCancelTrainingAppointmentModalOpen;
