export const alertThemesTypes = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};

export const getAlertBackgroundColor = (theme) => {
  switch (theme) {
    case alertThemesTypes.SUCCESS:
      return "#c6f6d5";
    case alertThemesTypes.ERROR:
      return "#fed7d7";
    case alertThemesTypes.WARNING:
      return "#f4ebc8";
  }
};

export const getAlertIconColor = (theme) => {
  switch (theme) {
    case alertThemesTypes.SUCCESS:
      return "#1ebf50";
    case alertThemesTypes.ERROR:
      return "#e40505";
    case alertThemesTypes.WARNING:
      return "#b99a24";
  }
};

export const getAlertThemeStyles = (theme) => {
  return {
    background: getAlertBackgroundColor(theme),
    borderRadius: "5px",
    top: "2rem",
    zIndex: "999",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, .15)",
  };
};
