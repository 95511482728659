import { connect } from "react-redux";
import { getFoodInstructionsById } from "./foodInstructionsSelectors";
import { setFoodInstructions } from "./actions/foodInstructionsActions";
import TextAreaWithBlur from "../common/TextAreaWithBlur";
import { getFoodIsDiscontinuedById } from "../food/foodsSelector";

const mapStateToProps = (state, ownProps) => {
  const { foodId, foodIndex, petId } = ownProps;
  const currentValue = getFoodInstructionsById(state, { foodId, petId });
  return {
    foodId,
    petId,
    currentValue,
    componentId: "foodDetails-foodInstructions",
    height: "3.75em",
    title: `Food ${foodIndex} instructions`,
    maxLength: 250,
    disabled: getFoodIsDiscontinuedById(state, { petId, foodId }),
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeFoodType: ({ value, foodId, petId }) => {
    dispatch(setFoodInstructions({ foodInstructions: value, foodId, petId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onBlur: value => {
    const { foodId, petId } = propsFromState;
    propsFromDispatch.onChangeFoodType({ value, foodId, petId });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TextAreaWithBlur);
