import { connect } from "react-redux";
import { columnHeaders } from "core/constants/dashboardContants";
import HotelOvernightPetCardContainer from "dux/hotelOvernight/columnList/HotelOvernightPetCardContainer";
import ScrollablePetCardList from "../../_components/petCard/ScrollablePetCardList";
import { getHotelOvernightAppointments } from "./hotelOvernightSelector";

const mapStateToProps = state => ({
  componentID: "scrollableCardList__hotelOvernight",
  appointments: getHotelOvernightAppointments(state),
  isHidden: false,
  diComp: {
    [columnHeaders.OVERNIGHT]: HotelOvernightPetCardContainer,
  },
  compName: columnHeaders.OVERNIGHT,
});

// const mapDispatchToProps = dispatch => ({
//   // keyName: ({}) => dispatch(actionName({})),
// });

// const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
//   ...propsFromState,
//   ...propsFromDispatch,
//   ...ownProps,
//
//   keyName: ({}) => propsFromDispatch.functionName({
//     keyName: propsFromState.value,
//   }),
//   keyName: ({}) => propsFromDispatch.functionName({
//     keyName: propsFromState.value,
//   }),
// });

export default connect(
  mapStateToProps,
  // mapDispatchToProps,
  // mergeProps,
)(ScrollablePetCardList);
