import moment from "moment";

// Utils
import { replaceAllRawFormattedTime } from "@/core/utils/stringManipulationUtils/replaceMultiUnformattedTime";
import getUSDateString from "@/core/utils/dateUtils/usDate";
import getUSTimeStringByDate from "@/core/utils/dateUtils/USTimeByDate";

// Constants
import { REG_EX } from "@/core/constants";

/**
 *
 * @exports formatAndSortHistoryObject
 * @param {Array[]} history - standard history object to transform
 * @param {Array[]} associates - Associates
 * @param {boolean} isAsc - data will be reversed when true
 * @returns {Array[]} Formatted and sorted history object to match history modal
 */

export const formatAndSortHistoryObject = ({ history, associates, isAsc = false }) => {
  if (!history || !history.length) return [];

  const sortedData = history
    .map((historyItem, index) => {
      return {
        associate: {
          associateName: (associates && associates[historyItem.associateId]?.associateName) || "",
          associateId: historyItem.oktaId || historyItem.associateId,
        },
        date: getUSDateString(historyItem.timestamp),
        description: replaceAllRawFormattedTime(
          historyItem.description,
          REG_EX.UN_FORMATTED_TIME,
          "LLL",
        ),
        eventType: historyItem.eventType,
        index,
        time: getUSTimeStringByDate(historyItem.timestamp, true),
        timestamp: historyItem.timestamp,
      };
    })
    .sort((a, b) => moment(b.timestamp).diff(a.timestamp));

  return isAsc ? sortedData.reverse() : sortedData;
};
