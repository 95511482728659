import { connect } from "react-redux";
import { overrideScheduleViolation } from "../../core/actionCreators/ScheduleViolationsActionCreators";
import { scheduleConflictMessages } from "../../core/constants/associateSchedulingConstants";
import { getScheduleViolationOverride } from "../../core/selectors/schedules/scheduleViolationOverride";
import Checkbox from "../common/Checkbox";

const mapStateToProps = state => ({
  isHidden: false,
  label: "Override Conflict",
  checked: getScheduleViolationOverride(state)
});

const mapDispatchToProps = dispatch => ({
  handleChange: ({ isOverride }) => {
    let message = scheduleConflictMessages.DEFAULT_SCHEDULE_CONFLICT_MESSAGE;

    if (isOverride) {
      message = scheduleConflictMessages.OVERRIDE_SCHEDULE_CONFLICT_MESSAGE;
      dispatch(overrideScheduleViolation({ isOverride, message }));
    }
    dispatch(overrideScheduleViolation({ isOverride, message }));
  }
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onChange: () =>
    propsFromDispatch.handleChange({
      isOverride: !propsFromState.checked
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Checkbox);
