import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Retrieve a list of training classes from the web services booking service
 * @param {number} The current store number
 * @param {fromDate} The date to start fetching training classes
 * @param {toDate} The date to stop fetching training classes
 * @returns {*} AxiosPromise
 */
export function fetchTrainingClasses({ storeNumber, fromDate, toDate }) {
  return bookingAPI.get(
    `dashboard/${endpointVersionString()}/stores/${storeNumber}/training?fromDate=${fromDate}&toDate=${toDate}`,
  );
}

/**
 * Adds a list of training classes from the web services booking service
 * @param {number} The current store number
 * @param {associateId} The associate id of the training class
 * @param {primaryServiceId} The primary service id of the training class
 * @param {startDateTime} The start date time of the training class
 * @param {toDate} The end date time of the training class
 * @returns {*} AxiosPromise
 */
export function postTrainingClass({
  storeNumber,
  associateId,
  primaryServiceId,
  startDatetime,
  endDatetime,
}) {
  return bookingAPI.post(`dashboard/${endpointVersionString()}/stores/${storeNumber}/training`, {
    associateId,
    primaryServiceId,
    startDatetime,
    endDatetime,
  });
}

export function deleteTrainingClass({ storeNumber, classNumber }) {
  return bookingAPI.delete(
    `dashboard/${endpointVersionString()}/stores/${storeNumber}/training/classSession/${classNumber}`,
  );
}
