import { endpointVersionString } from "core/utils/envUtils";
import { bookingAPI } from "./_axiosConfigSystemServicesBooking";

/**
 * Get the available time slots for the specified pet service at the specified store.
 * From System Services Booking API
 * @param {*} { customerKey, storeNumber, petServiceId, ...params }
 * @returns {*} AxiosPromise - Array of Object
 */
export function fetchAvailableTimeSlots({ customerKey, storeNumber, petServiceId, ...params }) {
  return bookingAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/stores/${storeNumber}/petservices/${petServiceId}/availability`,
    {
      params,
    },
  );
}

/**
 * Get availability based on an existing engagement, commonly used for rescheduling an existing appointment.
 * From System Services Booking API
 * @param {*} { customerKey, storeNumber, itineraryId, engagementId, ...params}
 * @returns {*} AxiosPromise - Array of Object
 */
export function fetchAvailableTimeSlotsByEngagement({
  customerKey,
  storeNumber,
  itineraryId,
  engagementId,
  ...params
}) {
  return bookingAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/stores/${storeNumber}/itineraries/${itineraryId}/engagements/${engagementId}/availability`,
    {
      params,
    },
  );
}
