import { connect } from "react-redux";

import { getSelectedPet } from "../../core/selectors/bookingUISelectors";
import { getCurrentCustomerKey } from "../../core/selectors/persistent/customer/customerSelectors";
import PetProfileDetailsComponent from "../features/petProfile/PetProfileDetails";

const mapStateToProps = state => {
  return {
    selectedPet: getSelectedPet(state),
    customerKey: getCurrentCustomerKey(state),
  };
};

export default connect(mapStateToProps)(PetProfileDetailsComponent);
