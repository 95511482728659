import React from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";

// Sparky Components
import { TextPassage } from "@petsmart-ui/sparky";

// Selectors
import { getIsOnlineAccount } from "@/core/selectors/petParentProfileSelectors";

// Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";

// Utils
import getParamFromPath from "@/core/utils/matchUtils/getParmFromPath";

// Constants
import { paramName } from "@/core/constants/urlParamConstants";

// Theme
import { color } from "@/web/common/styles/theme";

const HotelOnlineAccount = props => {
  const { componentId, isHidden, isOnlineAccount, title } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} style={{ paddingLeft: 0 }}>
      <LayoutCluster>
        {isOnlineAccount ? (
          <CheckCircleIcon style={{ color: color.statusBlue }} />
        ) : (
          <DoNotDisturbAltOutlinedIcon style={{ color: color.grey }} />
        )}
        <TextPassage size="lg">{title}</TextPassage>
      </LayoutCluster>
    </LayoutBox>
  );
};

// Map state and own props to props
const mapStateToProps = state => {
  const customerKey = getParamFromPath(paramName.CUSTOMER_KEY);
  const isOnlineAccount = getIsOnlineAccount(state, { customerKey });

  return {
    componentId: "HotelOnlineAccount",
    isHidden: false,
    isOnlineAccount,
    title: "Online Account",
  };
};

// HotelOnlineAccountContainer
export const HotelOnlineAccountContainer = connect(mapStateToProps)(HotelOnlineAccount);
