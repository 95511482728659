import { connect } from "react-redux";
import IconsComponent from "web/upcoming_here/icons/iconsComponent";
import { getHotelCardIcons } from "dux/hotelPetCardIcon/hotelIconUtils";
import { isCat } from "@/core/utils/iconUtils/iconUtils";

const mapStateToProps = (state, ownProps) => {
  const { iconInfo } = ownProps;
  const {
    isNewCustomer,
    hasPSA,
    receiveMedications,
    isOnlineBooking,
    isSenior,
    isPuppy,
    invoicePaid,
    isVIP,
    isIndividual,
    hasNoBed,
    hasSalonAppointment,
    hasStatusAlert,
    hasHealthCondition,
    isMissingVaccinations,
    speciesId,
    hasVetFee,
    hasDoggieValet,
    isGroupReservation,
    needsReview,
  } = iconInfo;
  return {
    icons: getHotelCardIcons({
      isNew: isNewCustomer,
      hasPSA,
      receiveMedications,
      isOnlineBooking,
      invoicePaid,
      isVIP,
      isIndividual,
      hasNoBed,
      hasSalonAppointment,
      hasStatusAlert,
      hasHealthCondition,
      isMissingVaccinations,
      isPuppy,
      isSenior,
      isCat: isCat(speciesId),
      hasVetFee,
      hasDoggieValet,
      isGroupReservation,
      needsReview,
    }),
  };
};

export default connect(mapStateToProps)(IconsComponent);
