import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import { showBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { hotelBookingTypes } from "@/web/hotelAlerts/hotelBookingConstants";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import logByGroup from "core/utils/loggingUtils/logByGroup";
import { patchPreBookingStatus } from "core/services/associateWeb/hotelBookingEndpoints";
import { saveCartResponse } from "dux/servicesCart/servicesCartSagas";
import {
  hotelBookingActionTypes,
  patchHotelBookingStatusForCartRequest,
  patchHotelBookingStatusForCartSuccess,
  patchHotelBookingStatusForCartFailure,
} from "./hotelBookingActions";

function* onPatchHotelBookingStatusForCart({ servicesCartId }) {
  try {
    yield put(patchHotelBookingStatusForCartRequest());
    const customerKey = yield select(getCurrentCustomerKey);

    const response = yield call(patchPreBookingStatus, {
      servicesCartId,
      customerKey,
    });

    // status/book api gives back the cart response
    const cartResponse = response.data.result;

    // Normalize the cart response and send data to state.servicesCart and state.servicesCartHotel
    yield call(saveCartResponse, cartResponse);

    // Pop Modal
    yield put(showBookingModal(hotelBookingTypes.HOTEL_APPOINTMENT_SET));
    yield put(patchHotelBookingStatusForCartSuccess());
  } catch (error) {
    logByGroup("patch Hotel Booking Status For Cart Error", error);
    yield put(patchHotelBookingStatusForCartFailure(error));
  }
}

/** ----------------------------------------------------------------------- **\
 WATCH FUNCTIONS
 \** ----------------------------------------------------------------------- * */
function* watchBookHotel() {
  yield takeEvery(
    hotelBookingActionTypes.PATCH_HOTEL_BOOKING_STATUS_FOR_CART,
    onPatchHotelBookingStatusForCart,
  );
}

/** ----------------------------------------------------------------------- **\
 EXPORT SAGAS
 \** ----------------------------------------------------------------------- * */
export default function* hotelBookingSaga() {
  yield all([fork(watchBookHotel)]);
}
