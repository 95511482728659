import { put, takeEvery, call, all, select } from "redux-saga/effects";
import moment from "moment";
import { getCustomerAppointmentHistory } from "@/core/services/associateWeb/customerAppointmentHistoryEndpoints";
import { getPetsByCustomer } from "@/core/selectors/entitiesSelector";
import { formatMoment } from "../../core/utils/dateUtils/formatDateTime";
import {
  GET_ITINERARY_HISTORY_LIST,
  getItineraryHistoryListRequest,
  getItineraryHistoryListSuccess,
  getItineraryHistoryListFailure,
} from "./itineraryHistoryListActions";
import { itineraryHistoryListContants } from "../_constants/itineraryHistoryListConstants";

const { REQUEST_PAGE_SIZE } = itineraryHistoryListContants;

function* onGetItineraryHistoryList({ customerKey, storeServiceType, productFamilyID, page, from, to }) {
  try {
    yield put(getItineraryHistoryListRequest());

    const currentDate = moment();
    const fromDate = from ?? moment(currentDate).subtract(1, "years");
    const toDate = to ?? moment(currentDate).add(1, "years");

    // Make the API call
    const response = yield call(getCustomerAppointmentHistory, {
      customerKey,
      fromDate: formatMoment(fromDate),
      toDate: formatMoment(toDate),
      storeServiceType,
      productFamilyID,
      page,
      size: REQUEST_PAGE_SIZE,
    });

    // Filter out any invalid results before saving to state, (e.g. pet doesnt belong to current customer)
    const customerPets = yield select(getPetsByCustomer, { customerKey });
    const payload = response?.data?.results?.filter(result => {
      const validPet = customerPets.includes(result?.petId);
      return validPet && !!result?.startDateTime && !!result?.endDateTime;
    });

    yield put(getItineraryHistoryListSuccess(payload));
  } catch (error) {
    yield put(getItineraryHistoryListFailure(error));
  }
}

function* watchGetItineraryHistoryList() {
  yield takeEvery(GET_ITINERARY_HISTORY_LIST, onGetItineraryHistoryList);
}

export default function* itineraryHistoryListSaga() {
  yield all([watchGetItineraryHistoryList()]);
}
