import { omit } from "lodash/fp";
import {
  GET_HOTEL_ROOM_AVAILABILITY,
  GET_HOTEL_ROOM_AVAILABILITY_FAILURE,
  GET_HOTEL_ROOM_AVAILABILITY_SUCCESS,
} from "./hotelRoomAvailabilityActions";
import { roomAvailabilityKey } from "./hotelRoomAvailabilitySelectors";

const initialState = { loading: {} };

const hotelRoomAvailabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTEL_ROOM_AVAILABILITY: {
      const { roomTypeBucketId, startDateTime, endDateTime } = action?.payload ?? {};
      return {
        ...state,
        loading: {
          ...state.loading,
          [roomAvailabilityKey(roomTypeBucketId, startDateTime, endDateTime)]: true,
        },
      };
    }
    case GET_HOTEL_ROOM_AVAILABILITY_SUCCESS: {
      const { key, value } = action?.payload ?? {};
      return {
        ...state,
        [key]: { ...value },
        loading: omit([key], state?.loading),
      };
    }
    case GET_HOTEL_ROOM_AVAILABILITY_FAILURE:
      return {
        ...state,
        loading: omit([action?.payload?.key], state?.loading),
      };
    default:
      return state;
  }
};

export default hotelRoomAvailabilityReducer;
