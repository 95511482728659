import { connect } from "react-redux";
import { get } from "lodash/fp";

import CancelAppointment from "./cancelAppointment";
import {
  clearCancelAppointmentReason,
  setCancelAppointmentReason,
  hideCheckInOutModal,
} from "../../core/actionCreators/checkInOutActionCreator";
import { hideCancelAppointmentModal } from "../../core/actionCreators/cancellationsActionCreators";
import {
  getCancelAppointmentReason,
  getCurrentPetServiceItem,
} from "../../core/selectors/checkInOutSelector";
import {
  getPet,
  getPetServiceItem,
  getAreAllPetServiceItemsCancelled,
} from "../../core/selectors/entitiesSelector";
import { updateEngagementStatus } from "../../core/actionCreators/engagementsActionCreators";
import { createLoadingSelector } from "../../core/selectors/utils";
import engagementsActionTypes from "../../core/actionTypes/engagementsActionTypes";
import { APPOINTMENT_STATUS } from "../../core/constants";
import { getBookingPetServiceItem } from "../../core/selectors/bookingUISelectors";
import { hideBookingModal } from "../../core/actionCreators/bookingActionCreators";
import { clearCart } from "../../core/actionCreators/ui/web/cartActionCreators";
import { selectCancellationReasons } from "dux/cancellationreasons/cancellationReasonsSeletors";

const mapStateToProps = state => {
  const currentPetServiceItem = getCurrentPetServiceItem(state);
  const currentBookingPetServiceItem = getBookingPetServiceItem(state);
  const petServiceItem = currentPetServiceItem
    ? getPetServiceItem(state, { petServiceItemId: currentPetServiceItem })
    : getPetServiceItem(state, { petServiceItemId: currentBookingPetServiceItem });
  const itineraryId = get("itinerary", petServiceItem);
  const customer = get("customer", petServiceItem);

  return {
    cancelAppointmentReason: getCancelAppointmentReason(state),
    cancellationReasons: selectCancellationReasons(state),
    petServiceItem,
    itineraryId,
    customer,
    pet: petServiceItem && getPet(state, { petId: petServiceItem.pet }),
    isLoading: createLoadingSelector([engagementsActionTypes.UPDATE_ENGAGEMENT_STATUS])(state),
    areAllPetServiceItemsCancelled: getAreAllPetServiceItemsCancelled(state, { itineraryId }),
  };
};

const mapDispatchToProps = dispatch => ({
  clearCart: () => dispatch(clearCart()),
  hideModal: () => {
    dispatch(hideCheckInOutModal());
    dispatch(hideBookingModal());
    dispatch(hideCancelAppointmentModal());
  },
  onClose: () => {
    dispatch(hideCheckInOutModal());
    dispatch(hideBookingModal());
    dispatch(hideCancelAppointmentModal());
  },
  setCancelAppointmentReason: reason => dispatch(setCancelAppointmentReason({ reason })),
  clearCancelAppointmentReason: () => dispatch(clearCancelAppointmentReason()),
  cancelAppointment: ({ customerId, itineraryId, engagementId, reason }) =>
    dispatch(
      updateEngagementStatus({
        customerId,
        itineraryId,
        engagementId,
        reason,
        status: APPOINTMENT_STATUS.CANCELED,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelAppointment);
