/**
 * Truncate a string to a limited number of characters, Note this is a HOF and will require two partial args
 * @memberOf Utils.Strings
 * @function
 * @name truncateStringToLimit
 * @param {number} limit - The number of characters to you would like to limit the string to
 * @returns {(input: string) => string}
 * @example
 * const truncatedString = truncateStringToLimit(5)
 * const shortString = truncatedString("foo bar baz")
 */
export const truncateStringToLimit = limit => {
  return input => {
    return input.substring(0, limit);
  };
};
