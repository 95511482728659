import { connect } from "react-redux";
import indexTab from "../common/tabs/indexTabs/IndexTab";
import { getSystemBookingFlow } from "./selectors/setSystemTypeSelectors";
import { systemName } from "./constants/setSystemTypeConstants";
import { setSystemBookingFlowType } from "./actions/setSystemTypeActions";
import getHotelWorkflowFeatureFlag
  from "web/enableDisableWorkflowFeatureFlag/selectors/hotel/getHotelWorkflowFeatureFlag";

const mapStateToProps = state => ({
  isHidden: getHotelWorkflowFeatureFlag(state).isHidden,
  isActive: getSystemBookingFlow(state) === systemName.HOTEL,
  componentID: `setSystemType${systemName.HOTEL}-indexTab`,
  label: `Pets${systemName.HOTEL} Details `,
});

const mapDispatchToProps = dispatch => ({
  handleClick: () => dispatch(setSystemBookingFlowType({ systemBookingFlow: systemName.HOTEL })),
});

// const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
//   ...propsFromState,
//   ...propsFromDispatch,
//   ...ownProps,

//   keyName: ({ }) => propsFromDispatch.functionName({
//     keyName:	propsFromState.value,
//   }),
//   keyName: ({ }) => propsFromDispatch.functionName({
//     keyName:	propsFromState.value,
//   }),
// })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // mergeProps,
)(indexTab);
