export default {
  SET_HERE_OR_COMPLETED_FILTER_APPLIED: "SET_HERE_OR_COMPLETED_FILTER_APPLIED",
  SET_UPCOMING_SEARCH_TEXT: "SET_UPCOMING_SEARCH_TEXT",
  CLEAR_UPCOMING_SEARCH_TEXT: "CLEAR_UPCOMING_SEARCH_TEXT",
  SET_HERE_SEARCH_TEXT: "SET_HERE_SEARCH_TEXT",
  CLEAR_HERE_SEARCH_TEXT: "CLEAR_HERE_SEARCH_TEXT",
  SET_UPCOMING_BOARDED_GUEST_CHECKED: "SET_UPCOMING_BOARDED_GUEST_CHECKED",
  CLEAR_UPCOMING_BOARDED_GUEST_CHECKED: "CLEAR_UPCOMING_BOARDED_GUEST_CHECKED",
  SET_HERE_BOARDED_GUEST_CHECKED: "SET_HERE_BOARDED_GUEST_CHECKED",
  CLEAR_HERE_BOARDED_GUEST_CHECKED: "CLEAR_HERE_BOARDED_GUEST_CHECKED"
};
