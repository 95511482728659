import moment from "moment";
import { connect } from "react-redux";
import { getSelectedDate } from "@/dux/selectedDate/selectedDateSelector";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import { setWeekNumber } from "@/core/actionCreators/ui/web/commonActionCreators";
import { getWeekNumber } from "@/core/selectors/commonSelector";
import { getWeekFromDate } from "@/core/utils/dateUtils/getWeekFromDate";
import {
  getIsDateSelectionHidden,
  getSelectedTrainingPetService,
} from "@/dux/scheduleTrainingPage/scheduleTrainingPageSelectors";
import { getCachedTrainingAvailabilityWeeks } from "@/dux/scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import WeekNavigationBar from "@/web/weekNavigationBar/WeekNavigationBar";
import DaySelectionDashboardTrainingContainer from "@/dux/selectedDate/DaySelectionDashboardTrainingContainer";
import NextWeekTrainingCont from "@/web/weekNavigationBar/NextWeekTrainingCont";
import PrevWeekTrainingCont from "@/web/weekNavigationBar/PrevWeekTrainingCont";

const mapStateToProps = (state) => {
  const selectedDate = getSelectedDate(state);
  const weekNumber = getWeekNumber(state) || moment(selectedDate).week();
  const bookingFlow = getSystemBookingFlow(state);
  return {
    componentId: "trainingWeeklyNavigationBar",
    switcherSpace: "switcher-space-1",
    week: getWeekFromDate({ date: selectedDate }),
    isHidden: bookingFlow !== systemName.TRAINING || getIsDateSelectionHidden(state),
    weekNumber,
    selectedDate,
    petServiceId: getSelectedTrainingPetService(state),
    storeNumber: getStoreNumber(state),
    petId: getSelectedPet(state),
    cachedTrainingAvailabilityWeeks: getCachedTrainingAvailabilityWeeks(state),

    compName: systemName.TRAINING,
    diComp: {
      daySelection: {
        [systemName.TRAINING]: DaySelectionDashboardTrainingContainer,
      },
      nextWeek: {
        [systemName.TRAINING]: NextWeekTrainingCont,
      },
      prevWeek: {
        [systemName.TRAINING]: PrevWeekTrainingCont,
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchWeekNumber: ({ weekNumber }) => {
    dispatch(setWeekNumber({ weekNumber }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  setWeekNumber: () => {
    const { weekNumber } = propsFromState;

    propsFromDispatch.dispatchWeekNumber({
      weekNumber,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(WeekNavigationBar);
