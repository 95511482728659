import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import quickQuoteConstants from "core/constants/quickQuoteConstants";
import { formatCalendarDateMoment, formatMoment } from "core/utils/dateUtils/formatDateTime";
import Form from "web/common/Form";
import { color } from "web/common/styles/theme";
import FormInput from "web/common/FormInput";
import LoadingWrapper from "web/common/LoadingWrapper";
import Tab from "web/common/Tab";
import Tabs from "web/common/Tabs";
import { QuickQuoteStandaloneService } from "../../quickQuote/quickQuoteStandaloneService/QuickQuoteStandaloneService";
import { QuickQuotePetForm } from "../../quickQuote/quickQuotePetForm/QuickQuotePetFormContainer";
import Services from "../../quickQuote/quickQuoteService/ServicesContainer";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutSwitcher } from "@/layout/switcher/Switcher";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutGrid } from "@/layout/grid/Grid";
import { connect } from "react-redux";
import { getBreedsList } from "core/selectors/enumsSelectors";
import { getQuickQuotePet, getQuickQuoteServicesTab } from "core/selectors/quickQuoteUISelectors";
import { createLoadingSelector } from "core/selectors/utils";
import servicesSelectionActionTypes from "core/actionTypes/servicesSelectionActionTypes";
import { loadStoreServicesForQuickQuotePet } from "core/actionCreators/servicesSelectionActionCreator";
import { setServicesTab } from "core/actionCreators/quickQuoteActionCreators";
import { clearCart, selectPetService } from "core/actionCreators/ui/web/cartActionCreators";

const appointmentDateFormValidationSchema = Yup.object().shape({
  appointmentDate: Yup.date()
    .required()
    .min(formatCalendarDateMoment(moment()), "Must be Future Date"),
});

export const QuickQuoteService = (props) => {

  // *** PROPS ***
  const { componentId, isServicesLoading, petId, breeds, quickQuoteServicesTab,  quickQuotePet, loadServicesForQuickQuotePet, clearCart, setServicesTab } = props;
  const { speciesId,  breedId, birthDate } = !!quickQuotePet && quickQuotePet;

  // *** LOCAL VARS ***
  const selectedTab = quickQuoteServicesTab;
  const today = moment();

  // *** LOCAL STATE ***
  const [appointmentDate, setAppointmentDate] = useState(formatCalendarDateMoment(moment()));

  // *** COMPONENT FUNCTIONS ***
  const loadServices = () => {
    loadServicesForQuickQuotePet({
      ...quickQuotePet,
      fromDate: appointmentDate
    })
  };

  const handleBlur = (values, isValid) => {
    if (isValid && appointmentDate !== values.appointmentDate) {
      setAppointmentDate(values.appointmentDate)
    }
  };

  const changeTab = tabId => {
    clearCart();
    setServicesTab(tabId);
  };


  // *** LIFE CYCLE ***
  // Load Services if speciesId,  breedId, birthDate or the selected appointment date changes.
  useEffect(() => {
    // QuickQuotePet initially comes in as undefined, so we need to verify it has data before we load any services
    !!quickQuotePet && loadServices();
  },[appointmentDate, speciesId, breedId, birthDate]);

  return (
    <LayoutBox id={componentId} padding={"scale-0"} style={{ width: "100%"}}>

      {/*NOTE: both the switcher root and switcher intermediary need to be 100% for border to stretch full page*/}
      <LayoutSwitcher highlight={2} styleRoot={{ height: "100%" }} style={{ height: "100%"}}>

        {/*Pet Details*/}
        <LayoutBox id={`${componentId}__petDetails`} padding={"scale-G2"}>
          <QuickQuotePetForm petId={petId} breeds={breeds} />
        </LayoutBox>

        {/*Appointment Details*/}
        <LayoutBox id={`${componentId}__appointmentDetails`} padding={"scale-G2"} style={{ height: "100%", borderLeft: `1px solid ${color.borderGrey}`}}>
          <LayoutStack space={"scale-G1"}>

            {/*Tabs*/}
            <LayoutBox id={`${componentId}__tabs`}>
              <Tabs
                activeTab={selectedTab}
                onTabClick={id => changeTab(id)}
              >
                <Tab
                  componentId="QuickQuoteService-appointmentDetails"
                  selected={selectedTab === "appointmentDetails"}
                  title="Appointment Details"
                  tabId="appointmentDetails"
                />
                <Tab
                  componentId="QuickQuoteService-standaloneService"
                  selected={selectedTab === "standaloneService"}
                  title="Standalone Service"
                  tabId="standaloneService"
                />
              </Tabs>
            </LayoutBox>

            {/*Tab Content*/}
            <LayoutBox id={`${componentId}__tabsContent`}>
              {selectedTab === "appointmentDetails" && (
                <LayoutStack space={"scale-G2"}>
                  <Formik
                    initialValues={{ appointmentDate }}
                    validationSchema={appointmentDateFormValidationSchema}
                    render={({ values, isValid }) => (
                      <Form onBlur={handleBlur(values, isValid)}>
                        <FormInput
                          label="Choose appointment date"
                          labelDisclaimer="*If a date is not selected, any special pricing is not applied. Time of Day special pricing is never applied"
                          type="calendar"
                          modifier="appointmentDate"
                          name="appointmentDate"
                          displayFormat="MM/DD/YYYY"
                          isOutsideRange={day => day.isBefore(today, "day")}
                        />
                      </Form>
                    )}
                  />

                  <LoadingWrapper isLoading={isServicesLoading}>
                    <Services
                      petId={quickQuoteConstants.QUOTE_PET}
                      appointmentDate={appointmentDate}
                    />
                  </LoadingWrapper>
                </LayoutStack>
              )}

              {selectedTab === "standaloneService" && (
                <QuickQuoteStandaloneService petId={quickQuoteConstants.QUOTE_PET} />
              )}
            </LayoutBox>
          </LayoutStack>
        </LayoutBox>
      </LayoutSwitcher>
    </LayoutBox>
  );
}

// REDUX CONNECT FUNCTIONS ---------------------------------------------------------------------------------------------
export const SalonQuickQuote =  connect(
  state => {
    return{
      componentId: "salonQuickQuoteServiceForm",
      breeds: getBreedsList(state),
      quickQuotePet: getQuickQuotePet(state),
      quickQuoteServicesTab: getQuickQuoteServicesTab(state),
      isServicesLoading: createLoadingSelector([servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET,])(state),
    }
  },
  dispatch => {
    return {
      loadServicesForQuickQuotePet: ({ speciesId, breedId, birthDate, fromDate }) => {
        dispatch(
          loadStoreServicesForQuickQuotePet({
            customerKey: "QUOTE_CUSTOMER",
            speciesId,
            breedId,
            birthDate,
            fromDate: formatMoment(moment(fromDate)),
            petId: quickQuoteConstants.QUOTE_PET,
          }),
        );
      },
      setServicesTab: tabId => dispatch(setServicesTab(tabId)),
      clearCart: () => dispatch(clearCart()),
      selectPetService,
    }
  })(QuickQuoteService);
