import axios from "axios";
import {
  appendAuthenticatedSubscriptionKeyToHeaders,
  handleError,
} from "core/services/systemAssociateAuth/interceptors";

/**
 * Axios Create configuration for the Vets Lookup API.
 */

/**
 * Axios Create configuration for the Vets Lookup API (/vetslookup).
 *
 * @summary /vetslookup.
 * @memberOf APIs.VetsLookup
 * @function
 * @name  vetsLookupAPI
 * @type {axios.AxiosInstance}
 *
 * @example
 * return vetsLookupAPI.post(url, params)
 * return vetsLookupAPI.get(url, params)
 * return vetsLookupAPI.put(url, params)
 */
export const vetsLookupAPI = axios.create({
  baseURL: `${window.env.BASE_URL}${window.env.VETS_LOOKUP_URI}`,
});

/**
 * Intercept the `x-refresh-session-token` value
 * - Note: This can be disabled by using the ENV flag below
 */
if (!window.env.DISABLE_API_AUTH_IN_DEV) {
  vetsLookupAPI.interceptors.request.use(appendAuthenticatedSubscriptionKeyToHeaders, handleError);
}
