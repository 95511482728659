import axios from "axios";

/**
 * Axios Create configuration for the associate authentication api (/web/associate/auth).
 *
 * @summary /web/associate/auth
 * @memberOf APIs.Auth
 * @function
 * @name  authAPI
 * @type {axios.AxiosInstance}
 *
 * @example
 * return authAPI.post(url, params)
 * return authAPI.get(url, params)
 * return authAPI.put(url, params)
 */
export const authAPI = axios.create({
  baseURL: `${window.env.BASE_URL}${window.env.AUTH_URI}`,
  headers: {
    "Ocp-Apim-Subscription-Key": window.env.UNAUTHENTICATED_SUBSCRIPTION_KEY,
  },
});
