import { createSelector } from "reselect";
import getWorkflowsFeatureFlagState from "./_workflowsFeatureFlagState";

const getWorkflowFeatureFlagFactory = TYPE =>
  createSelector(
    [getWorkflowsFeatureFlagState],
    workflowFeatureFlags => workflowFeatureFlags[TYPE],
  );

export default getWorkflowFeatureFlagFactory;
