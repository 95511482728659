/**
 * Responsible for setting the CSS property for Pointer Event
 *
 * @export SetPointerEvent
 * @param {boolean} { disabled }
 * @returns {string}
 */
export default function SetPointerEvent({ disabled }) {
  if (disabled) {
    return "none";
  }

  return "auto";
}
