/**
 * Util to get the total number of engagements on an itinerary across all pets
 * @memberOf Utils.Engagement
 * @function
 * @name countEngagementsOnItinerary
 * @param {Object} itinerary
 * @returns {number}
 */
export const countEngagementsOnItinerary = itinerary => {
  const engagementsCount = itinerary?.pets?.reduce(
    (count, { engagements = [] }) => count + engagements?.length,
    0,
  );
  return engagementsCount ?? 0;
};
