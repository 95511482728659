import { connect } from "react-redux";
import { get } from "lodash/fp";
import { getMedicationIds, getMedicationById } from "../../../web/medication/medicationsSelector";
import {
  getMedicationIsShowingDetails,
  getMedicationIsShowingDetailsForType,
} from "../../../web/medicationList/medicationListSelectors";
import BookingMedicationFormComponent from "../../../web/common/BookingMedicationFormComponent";
import { isFromBooking } from "../../../core/utils/validationUtils/isFromValidation";
import { history } from "@/dux/utils/browser/browserHistory";
import {
  applyPreservedMedications,
  preserveMedications,
} from "@/web/medication/actions/medicationsActions";
import { medicationTypes } from "@/web/newMedication/newMedicationConstants";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, medicationIndex, petId, locationType } = ownProps;
  const isShowingDetails = getMedicationIsShowingDetailsForType(state, {
    medicationId,
    petId,
    locationType,
  });

  // This form is used in 2 places on the booking page, so if at least one is open isEditingMed should be true
  const typesShowing = getMedicationIsShowingDetails(state, { medicationId, petId });
  const isEditingMed =
    typesShowing.includes(medicationTypes.BOOKING) || typesShowing.includes(medicationTypes.CART);

  return {
    petId,
    medicationId,
    medicationIndex,
    isHidden: !isFromBooking(get(["location", "pathname"], history)) || !isShowingDetails,
    medicationIds: getMedicationIds(state, { petId }),
    isEditingMed,
    isMedPreserved: !!getMedicationById(state, { medicationId, petId })?.preserved,
  };
};

const mapDispatchToProps = (dispatch, { medicationId, petId }) => ({
  preserveOrApplyMedData: ({ isEditingMed, isMedPreserved }) => {
    // When showing the med form, store a copy of med to preserve in case changes need to be discarded
    if (isEditingMed && !isMedPreserved) dispatch(preserveMedications({ medicationId, petId }));

    // When closing, overwrite any changes with the preserved version if it exists
    if (!isEditingMed && isMedPreserved)
      dispatch(applyPreservedMedications({ medicationId, petId }));
  },
});

const mergeProps = (stateProps, dispatchProps) => {
  const {
    petId,
    medicationId,
    medicationIndex,
    isHidden,
    medicationIds,
    isEditingMed,
    isMedPreserved,
  } = stateProps;
  const { preserveOrApplyMedData } = dispatchProps;

  return {
    isHidden,
    medicationId,
    medicationIndex,
    medicationIds,
    petId,
    isEditingMed,
    preserveOrApplyMedData: () => preserveOrApplyMedData({ isEditingMed, isMedPreserved }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(BookingMedicationFormComponent);
