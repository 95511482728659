export const dogVaccinationTypes = {
  DISTEMPER: 2,
  PARVOVIRUS: 3,
  PARAINFLUENZA: 5,
  BORDATELLA: 6,
  RABIES: 9
};
export const catVaccinationTypes = {
  RABIES: 11,
  FVRCCP: 17
};
