/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_OVERBOOKING_EXCEPTION = "GET_OVERBOOKING_EXCEPTION";
export const GET_OVERBOOKING_EXCEPTION_REQUEST = "GET_OVERBOOKING_EXCEPTION_REQUEST";
export const GET_OVERBOOKING_EXCEPTION_SUCCESS = "GET_OVERBOOKING_EXCEPTION_SUCCESS";
export const GET_OVERBOOKING_EXCEPTION_FAILURE = "GET_OVERBOOKING_EXCEPTION_FAILURE";

export const PATCH_OVERBOOKING_EXCEPTION = "PATCH_OVERBOOKING_EXCEPTION";
export const PATCH_OVERBOOKING_EXCEPTION_REQUEST = "PATCH_OVERBOOKING_EXCEPTION_REQUEST";
export const PATCH_OVERBOOKING_EXCEPTION_SUCCESS = "PATCH_OVERBOOKING_EXCEPTION_SUCCESS";
export const PATCH_OVERBOOKING_EXCEPTION_FAILURE = "PATCH_OVERBOOKING_EXCEPTION_FAILURE";

export const SET_OVERBOOKING_EXCEPTION_PERCENT = "SET_OVERBOOKING_EXCEPTION_PERCENT";
export const SET_OVERBOOKING_EXCEPTION_START = "SET_OVERBOOKING_EXCEPTION_START";
export const SET_OVERBOOKING_EXCEPTION_END = "SET_OVERBOOKING_EXCEPTION_END";
export const SET_OVERBOOKING_EXCEPTION_ROOM_TYPE = "SET_OVERBOOKING_EXCEPTION_ROOM_TYPE";
export const SET_OVERBOOKING_EXCEPTION_ERROR = "SET_OVERBOOKING_EXCEPTION_ERROR";

export const SET_OVERBOOKING_EXCEPTION = "SET_OVERBOOKING_EXCEPTION";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

export const getOverbookingException = exceptionId => ({
  type: GET_OVERBOOKING_EXCEPTION,
  exceptionId,
});

export const getOverbookingExceptionRequest = () => ({
  type: GET_OVERBOOKING_EXCEPTION_REQUEST,
});

export const getOverbookingExceptionSuccess = exception => ({
  type: GET_OVERBOOKING_EXCEPTION_SUCCESS,
  exception,
});

export const getOverbookingExceptionFailure = error => ({
  type: GET_OVERBOOKING_EXCEPTION_FAILURE,
  error,
});

export const patchOverbookingException = ({
  capacityExceptionId,
  roomTypeBucketNumber,
  startDate,
  endDate,
  overBookingPercentage,
  isCanceled,
}) => ({
  type: PATCH_OVERBOOKING_EXCEPTION,

  capacityExceptionId,
  roomTypeBucketNumber,
  startDate,
  endDate,
  overBookingPercentage,
  isCanceled,
});

export const patchOverbookingExceptionRequest = () => ({
  type: PATCH_OVERBOOKING_EXCEPTION_REQUEST,
});

export const patchOverbookingExceptionSuccess = exception => ({
  type: PATCH_OVERBOOKING_EXCEPTION_SUCCESS,
  exception,
});

export const patchOverbookingExceptionFailure = error => ({
  type: PATCH_OVERBOOKING_EXCEPTION_FAILURE,
  error,
});

export const setOverbookingException = exception => ({
  type: SET_OVERBOOKING_EXCEPTION,
  exception,
});

export const setOverbookingExceptionPercent = percent => ({
  type: SET_OVERBOOKING_EXCEPTION_PERCENT,
  percent,
});

export const setOverbookingExceptionStart = date => ({
  type: SET_OVERBOOKING_EXCEPTION_START,
  date,
});

export const setOverbookingExceptionEnd = date => ({
  type: SET_OVERBOOKING_EXCEPTION_END,
  date,
});

export const setOverbookingExceptionRoomType = roomTypeBucketNumber => ({
  type: SET_OVERBOOKING_EXCEPTION_ROOM_TYPE,
  roomTypeBucketNumber,
});

export const setOverbookingExceptionError = error => ({
  type: SET_OVERBOOKING_EXCEPTION_ERROR,
  error,
});
