import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Section from "web/common/Section";
import Form from "../../common/Form";
import FormInput from "../../common/FormInput";

import withSaveOnClickOutside from "../../common/hoc/withSaveOnClickOutside";
import { Layout } from "@prism/psm-ui-components";

const petFormValidationSchema = Yup.object().shape({
  isBookingAlert: Yup.bool(),
  bookingAlertReason: Yup.string().when("isBookingAlert", (isBookingAlert, schema) => {
    return isBookingAlert ? schema.required("Required if booking alert is marked") : schema;
  }),
  isCheckInAlert: Yup.bool(),
  checkInAlertReason: Yup.string().when("isCheckInAlert", (isCheckInAlert, schema) => {
    return isCheckInAlert ? schema.required("Required if check in is marked") : schema;
  }),
  isCheckOutAlert: Yup.bool(),
  checkOutAlertReason: Yup.string().when("isCheckOutAlert", (isCheckOutAlert, schema) => {
    return isCheckOutAlert ? schema.required("Required if check out is marked") : schema;
  }),
  isServiceCardAlert: Yup.bool(),
  serviceCardAlertReason: Yup.string().when("isServiceCardAlert", (isServiceCardAlert, schema) => {
    return isServiceCardAlert
      ? schema.required("Required if service card alert is marked")
      : schema;
  }),
});

const AlertsFormComponent = ({
  alertsFormData,
  onSubmit,
  disabled,
  fieldsToInclude,
  handleFocus,
  formikRef,
  clickOutsideRef,
  showServiceCardAlert,
  isHidden,
  componentID,
  title,
}) => {
  const componentId = "alertsForm"; // NOTE this should eventually come from the container or parent

  if (!isHidden) {
    return (
      <Layout.Box id={componentID}>
        <Section title={title} topLine>
          <Formik
            initialValues={alertsFormData}
            onSubmit={onSubmit}
            ref={formikRef}
            validationSchema={petFormValidationSchema}
            enableReinitialize
            render={({ values }) => (
              <Form
                innerRef={clickOutsideRef}
                onSubmit={onSubmit}
                onFieldFocus={handleFocus}
                fieldsToInclude={fieldsToInclude}
                disabled={disabled}
              >
                <FormInput
                  id={componentId}
                  modifier="isBookingAlert"
                  label="Booking Alert"
                  type="checkbox"
                  name="isBookingAlert"
                />

                <FormInput
                  id={componentId}
                  modifier="bookingAlertReason"
                  label="Booking alert reason"
                  name="bookingAlertReason"
                  disabled={!values.isBookingAlert}
                />

                <FormInput
                  id={componentId}
                  modifier="isCheckInAlert"
                  label="Check in Alert"
                  type="checkbox"
                  name="isCheckInAlert"
                />

                <FormInput
                  id={componentId}
                  modifier="checkInAlertReason"
                  label="Check in alert reason"
                  name="checkInAlertReason"
                  disabled={!values.isCheckInAlert}
                />

                <FormInput
                  id={componentId}
                  modifier="isCheckOutAlert"
                  label="Check out Alert"
                  type="checkbox"
                  name="isCheckOutAlert"
                />

                <FormInput
                  id={componentId}
                  modifier="checkOutAlertReason"
                  label="Check out alert reason"
                  name="checkOutAlertReason"
                  disabled={!values.isCheckOutAlert}
                />
                {showServiceCardAlert && (
                  <>
                    <FormInput
                      id={componentId}
                      modifier="isServiceCardAlert"
                      label="Service card Alert"
                      type="checkbox"
                      name="isServiceCardAlert"
                    />

                    <FormInput
                      id={componentId}
                      modifier="serviceCardAlertReason"
                      label="Service card alert reason"
                      name="serviceCardAlertReason"
                      disabled={!values.isServiceCardAlert}
                    />
                  </>
                )}
              </Form>
            )}
          />
        </Section>
      </Layout.Box>
    );
  }

  return null;
};

export default withSaveOnClickOutside(AlertsFormComponent);
