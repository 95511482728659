import React from "react";
import styled from "styled-components";
import { color } from "../styles/theme";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${color.borderGrey};
  padding-bottom: 3em;
`;

export default function DateSelectionPreferencesWrapper(props) {
  const { isHidden, children } = props;

  if (!isHidden) {
    return <SectionWrapper>{children}</SectionWrapper>;
  }

  return null;
}
