import { put, takeLatest, call, all, select } from "redux-saga/effects";

import { getConstructedHotelItinerary } from "dux/hotelItinerary/hotelConstructedItinerarySelectors";
import {
  PUT_HOTEL_ITINERARY_ADDONS,
  putHotelItineraryAddonsRequest,
  putHotelItineraryAddonsSuccess,
  putHotelItineraryAddonsFailure,
} from "./hotelItineraryAddonPutActions";
import { onPutHotelItinerary } from "../hotelItinerary/hotelItinerarySaga";
import { updateItineraryWithNewPetAddons } from "./hotelItineraryAddonPutHelpers";

/**
 * Saga to update addons for an itinerary via PUT
 * @memberOf Sagas.hotel.itinerary
 * @generator
 * @name onPutHotelItineraryAddons
 * @param {string} petId
 * @param {{ engagementId: string, addOns: Object[] }[]} updatedEngagements - array of engagements containing updated arrays of addons
 */
function* onPutHotelItineraryAddons({ petId, updatedEngagements }) {
  try {
    yield put(putHotelItineraryAddonsRequest());

    // get the itinerary
    const itinerary = yield select(getConstructedHotelItinerary);

    // re-construct hotel Itinerary body, the api is a put only and requires the full body.
    const hotelItinerary = updateItineraryWithNewPetAddons(itinerary, petId, updatedEngagements);

    yield call(onPutHotelItinerary, { hotelItinerary });

    yield put(putHotelItineraryAddonsSuccess());
  } catch (error) {
    yield put(putHotelItineraryAddonsFailure(error));
  }
}

function* watchOnPutHotelItineraryAddons() {
  yield takeLatest(PUT_HOTEL_ITINERARY_ADDONS, onPutHotelItineraryAddons);
}

export default function* hotelItineraryAddonPutSaga() {
  yield all([watchOnPutHotelItineraryAddons()]);
}
