import { get } from "lodash/fp";
import { createSelector } from "reselect";
import { getPetSpeciesId } from "core/selectors/ui/vaccinations/vaccinationsSelectors";
import { getFoodByPet } from "../food/foodsSelector";
import { selectFoodList } from "@/dux/foodOptionsList/foodOptionsListSelectors";
import { getFoodTypeByExternalId } from "../foodType/foodTypeSelectors";

export const getFoodNameById = createSelector(
  [getFoodByPet, (state, props) => props],
  (foods, { foodId }) => get([foodId, "name"], foods),
);

export const getFoodNameByExternalId = createSelector(
  [getFoodByPet, selectFoodList, (state, props) => props],
  (foods, foodList, { foodId }) => {
    const chosenFood = get(foodId, foods);
    if (chosenFood && chosenFood.externalId) {
      const foundFood = foodList.find(food => food.FoodId === chosenFood.externalId);
      return foundFood && foundFood.FoodName;
    }
    return chosenFood?.name;
  },
);

export const getFoodNameExternalIdById = createSelector(
  [getFoodByPet, (state, props) => props],
  (foods, { foodId }) => get([foodId, "externalId"], foods),
);

export const getPetFoodOptions = createSelector(
  [selectFoodList, getPetSpeciesId, getFoodTypeByExternalId],
  (foods, petSpecies, foodType) => {
    const sortedFoods = foods
      .filter(food => food.IsActive && food.SpeciesId === petSpecies && food.FoodType === foodType)
      .sort((a, b) => (a.FoodName > b.FoodName) - (a.FoodName < b.FoodName));
    const foodOptions = sortedFoods.map(food => ({
      value: food.FoodId,
      label: food.FoodName,
    }));
    return foodOptions;
  },
);
