import { connect } from "react-redux";
import schedulesActionCreators from "../../../core/actionCreators/schedulesActionCreators";
import AddButton from "../../common/buttons/addButton";

const mapStateToProps = () => ({
  opacity: "1",
  label: "Reset Week",
  fontSize: "1em"
});

const mapDispatchToProps = dispatch => ({
  buttonClickHandler: weekId => dispatch(schedulesActionCreators.resetWeek(weekId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddButton);
