import { associateWebProfileAPI } from "./_axiosConfigAssociateWebProfile";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Add a new email to a customers Account from the Web Associate Profile API - Add new email to customers account end point
 * @param {*} { customerKey, sourceId, data }
 * @returns
 */
export function postEmail({ customerKey, sourceId, data }) {
  return associateWebProfileAPI.post(
    `v2/customers/${customerKey}/emails?sourceId=${sourceId}`,
    data
  );
}

export function updateEmailPreferences({ customerKey, isEmailOptedOut, sourceId }) {
  return associateWebProfileAPI.put(
    `${endpointVersionString()}/customers/${customerKey}/EmailOptedOutFlag/${isEmailOptedOut}?sourceId=${sourceId}`
  );
}

export function getUpdatedEmailPreferences({ customerKey }) {
  return associateWebProfileAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/isEmailOptedOut`
  );
}
