import { color, lineThickness } from "../theme";

/**
 * Responsible for setting the border properties such as color and thickness
 *
 * @param {*} error
 * @returns {string}
 */
export default function setTextInputBorder(error) {
  let borderColor = color.borderGrey;

  if (error) {
    borderColor = color.errorRed;
  }

  return `${lineThickness.sliver} solid ${borderColor}`;
}
