export default {
  AUTHORIZE_USER: "AUTHORIZE_USER",
  AUTHORIZE_USER_REQUEST: "AUTHORIZE_USER_REQUEST",
  AUTHORIZE_USER_SUCCESS: "AUTHORIZE_USER_SUCCESS",
  AUTHORIZE_USER_FAILURE: "AUTHORIZE_USER_FAILURE",

  AUTHORIZE_USER_BY_PIN: "AUTHORIZE_USER_BY_PIN",
  AUTHORIZE_USER_BY_PIN_REQUEST: "AUTHORIZE_USER_BY_PIN_REQUEST",
  AUTHORIZE_USER_BY_PIN_SUCCESS: "AUTHORIZE_USER_BY_PIN_SUCCESS",
  AUTHORIZE_USER_BY_PIN_FAILURE: "AUTHORIZE_USER_BY_PIN_FAILURE",

  FETCH_AUTHORIZED_USERS: "FETCH_AUTHORIZED_USERS",
  FETCH_AUTHORIZED_USERS_REQUEST: "FETCH_AUTHORIZED_USERS_REQUEST",
  FETCH_AUTHORIZED_USERS_SUCCESS: "FETCH_AUTHORIZED_USERS_SUCCESS",
  FETCH_AUTHORIZED_USERS_FAILURE: "FETCH_AUTHORIZED_USERS_FAILURE",

  SET_OR_UPDATE_USER_PIN: "SET_OR_UPDATE_USER_PIN",
  SET_OR_UPDATE_USER_PIN_REQUEST: "SET_OR_UPDATE_USER_PIN_REQUEST",
  SET_OR_UPDATE_USER_PIN_SUCCESS: "SET_OR_UPDATE_USER_PIN_SUCCESS",
  SET_OR_UPDATE_USER_PIN_FAILURE: "SET_OR_UPDATE_USER_PIN_FAILURE",

  LOGOUT_USER: "LOGOUT_USER",
  LOGOUT_USER_REQUEST: "LOGOUT_USER_REQUEST",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_FAILURE: "LOGOUT_USER_FAILURE",

  LOGOUT_STORE: "LOGOUT_STORE",
  LOGOUT_STORE_REQUEST: "LOGOUT_STORE_REQUEST",
  LOGOUT_STORE_SUCCESS: "LOGOUT_STORE_SUCCESS",
  LOGOUT_STORE_FAILURE: "LOGOUT_STORE_FAILURE",

  REFRESH_SESSION_TOKEN: "REFRESH_SESSION_TOKEN",
  REFRESH_SESSION_TOKEN_REQUEST: "REFRESH_SESSION_TOKEN_REQUEST",
  REFRESH_SESSION_TOKEN_SUCCESS: "REFRESH_SESSION_TOKEN_SUCCESS",
  REFRESH_SESSION_TOKEN_FAILURE: "REFRESH_SESSION_TOKEN_FAILURE",

  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SET_ID_TOKEN: "SET_ID_TOKEN",

  SET_SESSION_TOKEN: "SET_SESSION_TOKEN",
  CLEAR_SESSION_TOKEN: "CLEAR_SESSION_TOKEN",

  SET_USER_SESSION: "SET_USER_SESSION",
  CLEAR_USER_SESSION: "CLEAR_USER_SESSION",

  SESSION_TIMEOUT: "SESSION_TIMEOUT",

  SET_AUTHENTICATED_WITH_QUICK_PIN: "SET_AUTHENTICATED_WITH_QUICK_PIN",
  CLEAR_AUTHENTICATED_WITH_QUICK_PIN: "CLEAR_AUTHENTICATED_WITH_QUICK_PIN",

  SET_AUTHENTICATED_USERS: "SET_AUTHENTICATED_USERS",

  SET_SELECTED_USER: "SET_SELECTED_USER",
  CLEAR_SELECTED_USER: "CLEAR_SELECTED_USER",

  SET_SESSION_TIME_REMAINING: "SET_SESSION_TIME_REMAINING",

  SET_INVALID_PIN_ERROR: "SET_INVALID_PIN_ERROR",
  CLEAR_INVALID_PIN_ERROR: "CLEAR_INVALID_PIN_ERROR",

  SHOW_AUTH_MODAL: "SHOW_AUTH_MODAL",
  HIDE_AUTH_MODAL: "HIDE_AUTH_MODAL",

  // SET_SESSION_TOKEN_EXPIRED: "SET_SESSION_TOKEN_EXPIRED",
  // CLEAR_SESSION_TOKEN_EXPIRED: "CLEAR_SESSION_TOKEN_EXPIRED",

  SET_REDIRECT_TO_FORGOT_PIN: "SET_REDIRECT_TO_FORGOT_PIN",
  CLEAR_REDIRECT_TO_FORGOT_PIN: "CLEAR_REDIRECT_TO_FORGOT_PIN",

  SET_REDIRECT_TO_SET_PIN: "SET_REDIRECT_SET_PIN",
  CLEAR_REDIRECT_TO_SET_PIN: "CLEAR_REDIRECT_SET_PIN",

  CANCEL_REFRESH: "CANCEL_REFRESH",

  LOGIN_WITH_OKTA: "LOGIN_WITH_OKTA",
  SET_SECURE_REDIRECT_ROUTE: "SET_SECURE_REDIRECT_ROUTE",

  SET_AUTHENTICATED_SUBSCRIPTION_KEY: "SET_AUTHENTICATED_SUBSCRIPTION_KEY",
};
