import React from "react";
import { connect } from "react-redux";
import getUnknownPetImage from "core/utils/assetUtils/unknownPetImage";
import HotelDashboardCardIconContainer from "dux/hotelPetCardIcon/HotelDashboardCardIconContainer";

import { TextPassage } from "@petsmart-ui/sparky";

import { LayoutBox } from "@/layout/box/Box";
import { LayoutSideBar } from "@/layout/sideBar/SideBar";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { history } from "dux/utils/browser/browserHistory";
import { routePaths } from "core/constants/routePaths";
import { selectPet } from "core/actionCreators/bookingActionCreators";
import { setPetId } from "core/actionCreators/ui/web/generalActionCreators";
import { selectCurrentStoreTimeZone } from "core/selectors/persistentSelectors";
import { APPOINTMENT_STATUS } from "@/core/constants";
import { showHideHotelCapacityModal } from "dux/hotelRoomCapacityModal/hotelRoomCapacityModalActions";
import { getCardFields } from "dux/_components/petCard/HotelPetCardUtils";

export default function HotelPetCardComponent(props) {
  const { isHidden, componentID, cardInfo, iconInfo, onClick = () => {}, backgroundColor } = props;

  if (isHidden) {
    return null;
  }

  const {
    petName,
    petAge,
    petBreed,
    petWeight,
    apptStatus,
    roomNumber,
    checkInOutDateTime,
    petService,
    pet,
  } = cardInfo;

  return (
    <LayoutBox
      id={componentID}
      borderColor="lightGrey"
      borderWidth="scale-S5"
      onClick={() => onClick()}
      style={{ cursor: "pointer", backgroundColor }}
    >
      <LayoutSideBar>
        {/* IMAGE */}
        <LayoutBox padding="scale-0">
          <img alt="pets face" src={getUnknownPetImage(pet)} width="57px" height="57px" />
        </LayoutBox>

        {/* CONTENT */}
        <LayoutStack>
          <LayoutBox padding="scale-0">
            <LayoutStack space="scale-S5">
              <LayoutCluster space="scale-S5">
                {/* PET  AGE, BREED AND WEIGHT */}
                <TextPassage size="lg" style={{ fontWeight: "bold" }}>{`${petName}, `}</TextPassage>
                <TextPassage size="lg">{`${petAge}, `}</TextPassage>
                <TextPassage size="lg">{`${petBreed}, `}</TextPassage>
                <TextPassage size="lg">{petWeight}</TextPassage>
              </LayoutCluster>

              {/* SCHEDULED SERVICE, APPT. TIME AND ROOM NUMBER */}
              <TextPassage size="lg">{petService}</TextPassage>
              <TextPassage size="lg">{checkInOutDateTime}</TextPassage>
              <TextPassage size="lg">{`Room: ${roomNumber}`}</TextPassage>
            </LayoutStack>
          </LayoutBox>

          {/* ICONS */}
          <LayoutBox padding="scale-0">
            <LayoutCluster style={{ justifyContent: "space-between" }}>
              <HotelDashboardCardIconContainer iconInfo={iconInfo} />
              <TextPassage size="lg">{apptStatus}</TextPassage>
            </LayoutCluster>
          </LayoutBox>
        </LayoutStack>
      </LayoutSideBar>
    </LayoutBox>
  );
}

export const PetInfoCard = connect(
  (state, ownProps) => {
    const { appointment, roomTypeId } = ownProps;
    const storeTimeZone = selectCurrentStoreTimeZone(state);
    const cardFields = getCardFields(appointment, storeTimeZone);

    return {
      componentId: `PetCard_${roomTypeId}`,
      iconInfo: cardFields.iconInfo,
      cardInfo: cardFields.cardInfo,
    };
  },
  (dispatch, ownProps) => {
    const { appointment } = ownProps;
    const { petId, customerId, itineraryId, status } = appointment;
    const routePath =
      status === APPOINTMENT_STATUS.CHECKED_IN ? routePaths.CHECK_OUT : routePaths.CHECK_IN;

    return {
      onClick: () => {
        dispatch(showHideHotelCapacityModal({ isHidden: true }));
        dispatch(selectPet(petId));
        dispatch(setPetId(petId));

        history.push(`${routePath}/${customerId}/${itineraryId}`);
      },
    };
  },
)(HotelPetCardComponent);
