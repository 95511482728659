import { connect } from "react-redux";
import { formatUnitsLabel } from "dux/_components/bgm/availableBundlesByPet/availableBundlesList/_Utils";
import AvailableBundlesDateListHeaderComponent from "../../../_components/bgm/availableBundlesByPet/AvailableBundlesDateListHeaderComponent";
import { getBundleRedeemedUnits } from "../availableBundlesByPetSelectors";

const mapStateToProps = (state, { bundleNumber, idEnum }) => {
  const { customerId, petId } = idEnum;
  const count = getBundleRedeemedUnits(state, { bundleNumber, petId });
  return {
    customerId,
    componentId: "bundle_redeemed_dates_list-header",
    label: formatUnitsLabel(count, "Redeemed"),
  };
};

export default connect(mapStateToProps)(AvailableBundlesDateListHeaderComponent);
