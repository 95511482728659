/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const customerAlertsActionsTypes = {
  UPDATE_HOTEL_CUSTOMER_ALERTS: "UPDATE_HOTEL_CUSTOMER_ALERTS",
  UPDATE_HOTEL_CUSTOMER_ALERTS_FAILURE: "UPDATE_HOTEL_CUSTOMER_ALERTS_FAILURE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const updateHotelCustomerAlerts = ({ alerts }) => ({
  type: customerAlertsActionsTypes.UPDATE_HOTEL_CUSTOMER_ALERTS,
  alerts,
});

export const updateHotelCustomerAlertsFailure = ({ error }) => ({
  type: customerAlertsActionsTypes.UPDATE_HOTEL_CUSTOMER_ALERTS_FAILURE,
  error,
});
