import { frequencyConstants } from "dux/frequency/FrequencyConstants";
import { OTHER_FOOD_NAME } from "../newFoodConstants";
import { validateArrayItems } from "@/core/utils/validationUtils/validateArrayItems";

/**
 * Checks if a required pet food field is missing
 *
 * @export isRequiredPetFoodFieldMissing
 * @param { Object } values - An object with form values.
 * @returns { Boolean } - returns true/false if form data is not loaded.
 */

export default function isRequiredPetFoodFieldMissing(values) {
  if (!values.food) {
    return true;
  }
  const { isInBooking } = values;
  const { externalId, amount, timeOfDay, frequency, concreteSchedule, name } = values.food;
  let requiredFields = [externalId, amount, timeOfDay];

  if (!values.foodList) {
    return false;
  }

  const foundOtherFood = values.foodList.find(food => {
    return food.FoodId === externalId && food.FoodName === OTHER_FOOD_NAME;
  });
  if (foundOtherFood) {
    // If 'other' is selected for food, the otherName field is required.
    requiredFields = [...requiredFields, name];
  }

  if (isInBooking) {
    // If in the booking flow, the frequency field is required. If frequency is manual, manual dates is required.
    if (frequency === frequencyConstants.MANUAL) {
      requiredFields = [...requiredFields, frequency, concreteSchedule];
    } else {
      requiredFields = [...requiredFields, frequency];
    }
  }

  // If it has, check if any required field is missing and return t/f accordingly
  return !validateArrayItems(requiredFields);
}
