import { put, takeEvery, call, all } from "redux-saga/effects";
import { noop } from "lodash/fp";
import {
  getTrainingItineraryById,
  postTrainingItinerary,
  putTrainingItinerary,
  patchTrainingItinerary,
  putTrainingClassItineraryStatus,
  deleteTrainingClassItinerary,
} from "@/core/services/systemServicesBooking/trainingItinerariesEndpoints";
import {
  GET_TRAINING_CLASS_ITINERARY,
  getTrainingClassItineraryRequest,
  getTrainingClassItinerarySuccess,
  getTrainingClassItineraryFailure,
  POST_TRAINING_CLASS_ITINERARY,
  postTrainingClassItineraryRequest,
  postTrainingClassItinerarySuccess,
  postTrainingClassItineraryFailure,
  PUT_TRAINING_CLASS_ITINERARY,
  putTrainingClassItineraryRequest,
  putTrainingClassItinerarySuccess,
  putTrainingClassItineraryFailure,
  PATCH_TRAINING_CLASS_ITINERARY,
  patchTrainingClassItineraryRequest,
  patchTrainingClassItinerarySuccess,
  patchTrainingClassItineraryFailure,
  putTrainingClassItineraryStatusRequest,
  putTrainingClassItineraryStatusSuccess,
  putTrainingClassItineraryStatusFailure,
  PUT_TRAINING_CLASS_ITINERARY_STATUS,
  deleteTrainingClassItineraryRequest,
  deleteTrainingClassItinerarySuccess,
  deleteTrainingClassItineraryFailure,
  DELETE_TRAINING_CLASS_ITINERARY,
  finalizeTrainingClassItineraryBookingRequest,
  finalizeTrainingClassItineraryBookingSuccess,
  finalizeTrainingClassItineraryBookingFailure,
  markTrainingClassItineraryAsCompleteRequest,
  markTrainingClassItineraryAsCompleteSuccess,
  markTrainingClassItineraryAsCompleteFailure,
  FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING,
  MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE,
  cancelTrainingClassItineraryRequest,
  cancelTrainingClassItinerarySuccess,
  cancelTrainingClassItineraryFailure,
  CANCEL_TRAINING_CLASS_ITINERARY,
} from "@/dux/trainingClassItineraries/trainingClassItinerariesActions";
import { normalizeGetTrainingClassByItineraryResponse } from "@/dux/trainingClassSessions/_utils";
import { createBatchActions } from "@/core/utils/batchUtils";
import {
  setTrainingSessionAppointmentDates,
  setTrainingAttendees,
  getTrainingClassSessionsSuccess,
} from "@/dux/trainingClassSessions/actions";
import { loadPetsSuccess } from "@/core/actionCreators/petsActionCreators";
import { loadAssociatesSuccess } from "@/core/actionCreators/associateActionCreator";
import { loadCustomersSuccess } from "@/core/actionCreators/customersActionCreators";
import { loadStoreServices } from "@/core/actionCreators/servicesSelectionActionCreator";
import BGM_STATUS from "@/dux/bgm/availableBundlesByPet/availableBundlesByPetConstants";
import { VPTClassStatuses } from "../trainingClassSessions/constants";

export function* onGetTrainingClassItinerary({ customerKey, itineraryId, onComplete = noop }) {
  try {
    yield put(getTrainingClassItineraryRequest());
    const response = yield call(getTrainingItineraryById, {
      customerKey,
      itineraryId,
    });
    const result = response.data.result;

    const {
      trainingSessionAppointmentDates,
      trainingClassSessions,
      trainingAttendees,
      pets,
      associates,
      customers,
      petServices,
    } = normalizeGetTrainingClassByItineraryResponse(result);

    // We are currently batching actions so that we only trigger one render cycle when
    // updating Redux with our normalized data. This is to prevent triggering multiple render cycles,
    // which can lead to performance degradation.
    yield put(
      createBatchActions(
        setTrainingSessionAppointmentDates(trainingSessionAppointmentDates),
        setTrainingAttendees(trainingAttendees),
        loadPetsSuccess({ pets }),
        loadAssociatesSuccess({ associates }),
        loadCustomersSuccess({ customers }),
        loadStoreServices({ petServices }),
        getTrainingClassSessionsSuccess(trainingClassSessions),
      ),
    );

    yield put(getTrainingClassItinerarySuccess());

    onComplete(result);
  } catch (error) {
    yield put(getTrainingClassItineraryFailure(error));
  }
}

export function* onPostTrainingClassItinerary({
  petServiceId,
  customerKey,
  petId,
  classSessionId,
  startDateTime,
  associateId,
  duration,
  durationUnitOfMeasure,
  behaviors,
  notes,
  customerPhone,
  customerPhoneType,
  customerTimeZone,
}) {
  try {
    yield put(postTrainingClassItineraryRequest());
    const response = yield call(postTrainingItinerary, {
      petServiceId,
      customerKey,
      petId,
      classSessionId,
      startDateTime,
      associateId,
      duration,
      durationUnitOfMeasure,
      behaviors,
      notes,
      customerPhone,
      customerPhoneType,
      customerTimeZone,
    });
    const payload = response.data.result;

    yield put(postTrainingClassItinerarySuccess({ ...payload, classSessionId }));
  } catch (error) {
    yield put(postTrainingClassItineraryFailure(error));
  }
}

export function* onPutTrainingClassItinerary({
  petServiceId,
  customerKey,
  petId,
  itineraryId,
  classSessionId,
  startDateTime,
  associateId,
  duration,
  durationUnitOfMeasure,
  behaviors,
  notes,
  customerPhone,
  customerPhoneType,
  customerTimeZone,
  isReschedule,
  onComplete = noop,
}) {
  try {
    yield put(putTrainingClassItineraryRequest());
    const response = yield call(putTrainingItinerary, {
      petServiceId,
      customerKey,
      itineraryId,
      petId,
      classSessionId,
      startDateTime,
      associateId,
      duration,
      durationUnitOfMeasure,
      behaviors,
      notes,
      customerPhone,
      customerPhoneType,
      customerTimeZone,
      isReschedule,
    });
    const { result } = response.data;
    const payload = { ...result, classSessionId };

    yield put(putTrainingClassItinerarySuccess(payload));

    onComplete(payload);
  } catch (error) {
    yield put(putTrainingClassItineraryFailure(error));
  }
}

export function* onPatchTrainingClassItinerary({
  customerKey,
  itineraryId,
  petId,
  classSessionId,
  notes,
  onComplete = noop,
}) {
  try {
    yield put(patchTrainingClassItineraryRequest());
    yield call(patchTrainingItinerary, {
      customerKey,
      itineraryId,
      petId,
      classSessionId,
      notes,
    });

    yield put(patchTrainingClassItinerarySuccess());

    onComplete();
  } catch (error) {
    yield put(patchTrainingClassItineraryFailure(error));
  }
}

export function* onPutTrainingClassItineraryStatus({
  customerKey,
  itineraryId,
  engagementId,
  status,
  reason,
}) {
  try {
    yield put(putTrainingClassItineraryStatusRequest());
    const response = yield call(putTrainingClassItineraryStatus, {
      customerKey,
      itineraryId,
      engagementId,
      status,
      reason,
    });
    const { result } = response.data;

    yield put(putTrainingClassItineraryStatusSuccess());

    return result;
  } catch (error) {
    yield put(putTrainingClassItineraryStatusFailure(error));
  }
}

export function* onFinalizeTrainingClassItineraryBooking({
  customerKey,
  itineraryId,
  engagementId,
  status,
  reason,
  onComplete = noop,
}) {
  try {
    yield put(finalizeTrainingClassItineraryBookingRequest());

    const result = yield call(onPutTrainingClassItineraryStatus, {
      customerKey,
      itineraryId,
      engagementId,
      status,
      reason,
    });

    const { classSessionId, petId } = result;

    const classSessionPayload = {
      [classSessionId]: { status },
    };

    const attendeeId = `${classSessionId}-${customerKey}-${petId}`;
    const attendeePayload = {
      [attendeeId]: { redemptionStatus: BGM_STATUS.COMPLETE },
    };

    yield put(finalizeTrainingClassItineraryBookingSuccess());

    yield put(getTrainingClassSessionsSuccess(classSessionPayload));
    yield put(setTrainingAttendees(attendeePayload));

    onComplete(result);
  } catch (error) {
    yield put(finalizeTrainingClassItineraryBookingFailure(error));
  }
}

// Since the appointment moves from "Booked" to "Available"
// when canceled, and there is only one appointment per class,
// we redirect back to the main dashboard since there's no more data
// to display (and consequently no state updates in this saga, since
// the itineraries will be refreshed on the dashboard). In the future,
// we will just refresh the page if there's more than one appointment
// per class, and one of them is canceled.
export function* onCancelTrainingClassItinerary({
  customerKey,
  itineraryId,
  engagementId,
  status,
  reason,
  onComplete = noop,
}) {
  try {
    yield put(cancelTrainingClassItineraryRequest());

    const result = yield call(onPutTrainingClassItineraryStatus, {
      customerKey,
      itineraryId,
      engagementId,
      status,
      reason,
    });

    yield put(cancelTrainingClassItinerarySuccess());

    onComplete(result);
  } catch (error) {
    yield put(cancelTrainingClassItineraryFailure(error));
  }
}

export function* onMarkTrainingClassItineraryAsComplete({
  customerKey,
  itineraryId,
  engagementId,
  status,
  reason,
  onComplete = noop,
}) {
  try {
    yield put(markTrainingClassItineraryAsCompleteRequest());

    const result = yield call(onPutTrainingClassItineraryStatus, {
      customerKey,
      itineraryId,
      engagementId,
      status,
      reason,
    });

    const { classSessionId, petId } = result;
    const attendeeId = `${classSessionId}-${customerKey}-${petId}`;

    const trainingAttendeesPayload = {
      [attendeeId]: { redemptionStatus: BGM_STATUS.COMPLETE },
    };

    const trainingClassItineraryPayload = {
      [classSessionId]: { status: VPTClassStatuses.COMPLETED },
    };

    yield put(markTrainingClassItineraryAsCompleteSuccess());

    yield put(setTrainingAttendees(trainingAttendeesPayload));
    yield put(getTrainingClassSessionsSuccess(trainingClassItineraryPayload));

    onComplete({ ...trainingAttendeesPayload, ...trainingClassItineraryPayload });
  } catch (error) {
    yield put(markTrainingClassItineraryAsCompleteFailure(error));
  }
}

export function* onDeleteTrainingClassItinerary({ customerKey, itineraryId, onComplete = noop }) {
  try {
    yield put(deleteTrainingClassItineraryRequest());
    yield call(deleteTrainingClassItinerary, {
      customerKey,
      itineraryId,
    });

    yield put(deleteTrainingClassItinerarySuccess());

    onComplete();
  } catch (error) {
    yield put(deleteTrainingClassItineraryFailure(error));
  }
}

function* watchGetTrainingClassItinerary() {
  yield takeEvery(GET_TRAINING_CLASS_ITINERARY, onGetTrainingClassItinerary);
}

function* watchPostTrainingClassItinerary() {
  yield takeEvery(POST_TRAINING_CLASS_ITINERARY, onPostTrainingClassItinerary);
}

function* watchPutTrainingClassItinerary() {
  yield takeEvery(PUT_TRAINING_CLASS_ITINERARY, onPutTrainingClassItinerary);
}

function* watchPatchTrainingClassItinerary() {
  yield takeEvery(PATCH_TRAINING_CLASS_ITINERARY, onPatchTrainingClassItinerary);
}

function* watchPutTrainingClassItineraryStatus() {
  yield takeEvery(PUT_TRAINING_CLASS_ITINERARY_STATUS, onPutTrainingClassItineraryStatus);
}

function* watchFinalizeTrainingClassItineraryBooking() {
  yield takeEvery(
    FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING,
    onFinalizeTrainingClassItineraryBooking,
  );
}

function* watchMarkTrainingClassItineraryAsComplete() {
  yield takeEvery(
    MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE,
    onMarkTrainingClassItineraryAsComplete,
  );
}

function* watchCancelTrainingClassItinerary() {
  yield takeEvery(CANCEL_TRAINING_CLASS_ITINERARY, onCancelTrainingClassItinerary);
}

function* watchDeleteTrainingClassItinerary() {
  yield takeEvery(DELETE_TRAINING_CLASS_ITINERARY, onDeleteTrainingClassItinerary);
}

export default function* trainingClassAvailabilitySaga() {
  yield all([
    watchGetTrainingClassItinerary(),
    watchPostTrainingClassItinerary(),
    watchPutTrainingClassItinerary(),
    watchPatchTrainingClassItinerary(),
    watchPutTrainingClassItineraryStatus(),
    watchDeleteTrainingClassItinerary(),
    watchFinalizeTrainingClassItineraryBooking(),
    watchMarkTrainingClassItineraryAsComplete(),
    watchCancelTrainingClassItinerary(),
  ]);
}
