// @ts-check

/**
 * @param {object} state
 * @param {object} [state.hotelRoomManagementDetailsDetailsInput]
 * @param {string} [state.hotelRoomManagementDetailsDetailsInput.roomSuspensionDetails]
 * @returns {string | undefined} - Room Suspesion details for the row being currently modified
 */
export const selectHotelRoomSuspensionDetailsValue = state =>
  state?.hotelRoomManagementDetailsDetailsInput?.roomSuspensionDetails;
