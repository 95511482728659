import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { SET_DO_NOT_BOOK_SALON, setDoNotBookError } from "dux/doNotBook/doNotBookActions";
import { putCustomer } from "core/services/associateWeb/customerEndPoints";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getCustomer } from "core/selectors/entitiesSelector";
import { updateCustomerSuccess } from "core/actionCreators/customersActionCreators";

function* onUpdateDoNoBookSalon({ doNotBook, doNotBookReason }) {
  try {
    const sourceId = yield select(getStoreNumber);
    const customerKey = yield select(getCurrentCustomerKey);
    const customer = yield select(getCustomer, { customerKey });

    /*  the data requires a mim of: sourceId, firstName, lastName doNotBook and doNotBookReason for the
        putCustomer API to not return an error */
    const response = yield call(putCustomer, {
      customerKey,
      data: {
        firstName: customer.firstName,
        lastName: customer.lastName,
        doNotBook,
        doNotBookReason,
        sourceId,
      },
    });

    yield put(
      updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          doNotBook: response.data.doNotBook,
          doNotBookReason: response.data.doNotBookReason,
        }
      }));
  } catch (error) {
    yield put(setDoNotBookError({ error }));
  }
}

function* watchUpdateDoNotBook() {
  yield takeEvery(SET_DO_NOT_BOOK_SALON, onUpdateDoNoBookSalon);
}

export default function* updateDoNotBookSalonSaga() {
  yield all([fork(watchUpdateDoNotBook)]);
}