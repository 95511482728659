/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelDeparturesHeaderSearchActionTypes = {
  SET_HOTEL_DEPARTURES_SEARCH: "SET_HOTEL_DEPARTURES_SEARCH",
  CLEAR_HOTEL_DEPARTURES_SEARCH: "CLEAR_HOTEL_DEPARTURES_SEARCH",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setHotelDeparturesSearchText = ({ value }) => ({
  type: hotelDeparturesHeaderSearchActionTypes.SET_HOTEL_DEPARTURES_SEARCH,
  value,
});

export const clearHotelDeparturesSearchText = () => ({
  type: hotelDeparturesHeaderSearchActionTypes.CLEAR_HOTEL_DEPARTURES_SEARCH,
});
