import moment from "moment";
import { initMomentObj } from "./formatDateTime";

/** @module byDateAndTime */

/**
 * Returns a moments object from a date and time.
 *
 * @export getMomentByDateAndTime
 * @param {string} date
 * @param {string} time
 * @returns {object} - moments object
 */
export default function getMomentByDateAndTime(date, time) {
  return moment(`${date}T${time}`);
}

/**
 * Returns a moments object from a date and time
 * @export setTimeForDate
 * @param {string} date
 * @param {string} time
 * @param {string?} timeZone - time zone of the date param
 * @returns {object} - moment object
 */
export const setTimeForDate = (date, time, timeZone) =>
  initMomentObj(date, timeZone).set({
    hour: moment(time).hour(),
    minutes: moment(time).minute(),
    seconds: moment(time).seconds(),
  });
