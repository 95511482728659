import { createSelector } from "reselect";
import { get, words, clone, values, compose, map, compact, find, reduce } from "lodash/fp";
import moment from "moment";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { getPrismCustomerHotelProfile } from "web/petParentProfile/selectors/getPrismCustomerProfileSelector";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getCustomer, getPhonesByCustomer } from "./entitiesSelector";
import { newCustomerFieldTypes, customerPhoneTypes } from "../constants/customerProfileConstants";
import normalizeFormInput from "../utils/normalizeUtils/normalizeFormInput";
import formatPhoneNumberNANP from "../utils/stringManipulationUtils/formatPhoneNumber";
import { getProps } from "./commonSelector";
import { getIsTextOptedOut } from "../utils/customerProfile";
import { getUiWeb } from "./uiSelector";

export const getPetParentProfile = createSelector([getUiWeb], uiWeb => uiWeb?.petParentProfile);

export const getIsOnlineAccount = createSelector(
  [getCustomer],
  customer => customer && customer.loyaltys && customer.loyaltys.some(get("hasOnlineProfile")),
);

export const getIsLoyaltyCustomer = createSelector(
  [getCustomer],
  customer => customer && customer.loyaltys && customer.loyaltys.length,
);

export const getPetParentProfileEmailToDelete = createSelector(
  [getPetParentProfile],
  petParentProfile => petParentProfile?.emailAddressId,
);

export const getCustomerKeyForDeleteEmail = createSelector(
  [getPetParentProfile],
  petParentProfile => petParentProfile?.customerKey,
);

export const getSetFieldForNewProfile = createSelector([getPetParentProfile], petParentProfile => {
  switch (petParentProfile?.newCustomerFieldType) {
    case newCustomerFieldTypes.PHONE:
      return { mobilePhone: petParentProfile?.newCustomerFieldValue };
    case newCustomerFieldTypes.EMAIL:
      return { email: petParentProfile?.newCustomerFieldValue };
    case newCustomerFieldTypes.NAME:
      const [firstName, lastName = ""] = words(petParentProfile?.newCustomerFieldValue);
      return { firstName, lastName };
    default:
      return {};
  }
});

/**
 * Callback function to pass to sort method that sorts phones based on created date.
 * @param {Object} previous - Previous customer phone object
 * @param {Object} current - Current customer phone object
 * @returns {Array.<Object>} Array of sorted phone objects
 */
export const sortPhonesByCreatedDateDescending = (previous, current) =>
  moment.utc(current?.createdDate).diff(moment.utc(previous?.createdDate));

/**
 * Gets the first primary phone number found based on the phone numbers and phone type passed in.
 * @param {Array} phones - Array of customer phone numbers
 * @param {string} type - Phone number type (Mobile, Work or Home)
 * @returns {Object}
 */
export function getPrimaryPhoneByType({ phones, type }) {
  return (
    phones &&
    phones.sort(sortPhonesByCreatedDateDescending) &&
    phones.find(({ isActive, isPrimary, phoneType }) => isPrimary && isActive && phoneType === type)
  );
}

/**
 * Gets the first primary phone number found in the customer profile.
 * @param {Array} phones - Array of customer phone numbers
 * @returns {Object}
 */
export const getPrimaryPhoneByCustomer = createSelector([getPhonesByCustomer], phones => {
  if (phones) {
    // Need to clone phones since Javascript mutates array on sort,
    // which can lead to inconsistent results.
    const phonesCopy = clone(phones);
    return phonesCopy
      .sort(sortPhonesByCreatedDateDescending)
      .find(({ isActive, isPrimary }) => isPrimary && isActive);
  }
  return null;
});

/**
 * Gets the most recent active phone number based on the phone numbers and phone type passed in.
 * @param {Array} phones - Array of customer phone numbers
 * @param {string} type - Phone number type (Mobile, Work or Home)
 * @returns {Object}
 */
export function getActivePhoneByType({ phones, type }) {
  return (
    phones &&
    phones
      .sort(sortPhonesByCreatedDateDescending)
      .find(({ isActive, phoneType }) => isActive && phoneType === type)
  );
}

/**
 * Gets the phone number to display on the UI based on the phone number type passed in.
 * Current business logic is to display a primary phone number first, regardless of when
 * it was created. If there is no primary phone number, we will get the most recently
 * added phone number instead.
 * @param {Array} phones - Array of customer phone numbers
 * @param {string} type - Phone number type (Mobile, Work or Home)
 * @returns {Object}
 */
export function getPhoneToDisplay({ phones, type }) {
  const primaryPhone = getPrimaryPhoneByType({ phones: clone(phones), type });
  const activePhone = getActivePhoneByType({ phones: clone(phones), type });

  if (primaryPhone) {
    return primaryPhone;
  }

  return activePhone;
}

export const getPetParentProfileFormData = createSelector(
  [getCustomer, getSystemBookingFlow, (state, props) => state],
  (customer, systemType, state) => {
    const primaryEmail = customer && customer.emails && customer.emails.find(Boolean);
    const phones = get("phones", customer);
    const mobilePhone = getPhoneToDisplay({ phones, type: customerPhoneTypes.MOBILE });
    const homePhone = getPhoneToDisplay({ phones, type: customerPhoneTypes.HOME });
    const workPhone = getPhoneToDisplay({ phones, type: customerPhoneTypes.WORK });

    const customerHotelProfile = getPrismCustomerHotelProfile(state, {
      customerKey: customer?.customerKey,
    });
    const doNotBook =
      systemType === systemName.SALON
        ? normalizeFormInput(customer, "doNotBook")
        : customerHotelProfile?.doNotBook;
    const doNotBookReason =
      systemType === systemName.SALON
        ? normalizeFormInput(customer, "doNotBookReason")
        : customerHotelProfile?.doNotBookReason;
    return {
      firstName: normalizeFormInput(customer, "firstName"),
      lastName: normalizeFormInput(customer, "lastName"),
      mobilePhone: normalizeFormInput(mobilePhone, "phoneNumber"),
      homePhone: normalizeFormInput(homePhone, "phoneNumber"),
      workPhone: normalizeFormInput(workPhone, "phoneNumber"),
      email: normalizeFormInput(primaryEmail, "email"),
      isEmailDeclined: customer ? !!customer.isEmailDeclined : false,
      isEmailOptedOut: primaryEmail ? normalizeFormInput(customer, "isEmailOptedOut") : null,
      doNotBook,
      doNotBookReason: doNotBook ? doNotBookReason : "",
      isVip: customer?.prismCustomer?.isVip,
      psaOnFile: customer?.prismCustomer?.psaOnFile,
    };
  },
);

export const getContactPhonesByCustomerToDisplay = createSelector([getPhonesByCustomer], phones => {
  const getPhoneToDisplayByType = type => getPhoneToDisplay({ type, phones });
  return compose(compact, map(getPhoneToDisplayByType), values)(customerPhoneTypes);
});

export const getContactPhoneByCustomerToDisplay = createSelector(
  [getContactPhonesByCustomerToDisplay, getProps],
  (phones, { phoneId }) => find(phone => phone.phoneId === Number(phoneId), phones),
);

export const getFormattedContactPhoneByCustomerToDisplay = createSelector(
  [getContactPhoneByCustomerToDisplay],
  phone => {
    const formatContactPhoneNumber = ({ phoneType, phoneNumber }) =>
      `${phoneType} - ${formatPhoneNumberNANP(phoneNumber)}`;
    return phone && formatContactPhoneNumber(phone);
  },
);

export const getPhoneContactOptInOptOutSettingsFormValues = createSelector(
  [getContactPhonesByCustomerToDisplay],
  contactPhones =>
    reduce(
      (acc, phone) => ({ ...acc, [phone.phoneId]: !getIsTextOptedOut(phone) }),
      {},
    )(contactPhones),
);

export const getReceiveReminderCallsFormValues = createSelector([getCustomer], customer => ({
  receiveReminderCalls: get("reminderOptOutFlag", customer)
    ? !get("reminderOptOutFlag", customer)
    : true,
}));

export const getPetParentContactSettings = createSelector(
  [getReceiveReminderCallsFormValues, getPhoneContactOptInOptOutSettingsFormValues],
  (receiveReminderCallsFormValues, phoneContactSettingsFormValues) => ({
    ...receiveReminderCallsFormValues,
    ...phoneContactSettingsFormValues,
  }),
);
