import { createSelector } from "reselect";
import { filter, find, difference, isEmpty } from "lodash/fp";
import { getHotelRoomSelectionRoomTypes } from "dux/hotelRoomSelection/hotelRoomSelectionSelector";
import { getAppointmentsByRoomNumberNotNull } from "dux/hotelRoomCapacityModal/hotelRoomTypeColumns/hotelRoomTypeColumnsSelectors";
import { sortListAscendingOrder } from "core/utils/arrayUtils/sortByProperty";

/**
 * Returns a list of availableRooms e.g. no suspensions and rooms that are not currently assigned to an appointment
 *
 * @memberOf Selectors.Rooms
 * @function selectAvailableHotelRooms
 * @param {string} targetRoomTypeId - The id of the target room, e.g. "8", "10" etc.
 * @returns {Array} - An array of available room numbers.
 *
 * @example
 *
 * const rooms = selectAvailableHotelRooms("123")(state);
 */
export const selectAvailableHotelRooms = targetRoomTypeId =>
  createSelector(
    [getHotelRoomSelectionRoomTypes, getAppointmentsByRoomNumberNotNull],
    (roomSuspensions, roomsAttachedTOAppointments) => {
      // Map over all scheduled appointments to get a list of all currently occupied Rooms
      const occupiedRooms = roomsAttachedTOAppointments?.map(appointment => appointment.roomNumber);

      // find the suspension data for our roomTypeId
      const roomSuspension = find(
        suspension => suspension?.roomTypeId === targetRoomTypeId,
        roomSuspensions,
      );

      // Filter out any rooms that have a suspension, this way only a list of schedule rooms are provided
      const nonSuspensionRooms = filter(
        room => isEmpty(room.suspensionDetails),
        roomSuspension?.rooms,
      );

      // Remove the suspensionDetails from the nonSuspensionRooms list and just provide a list of room numbers
      const listOfRooms = nonSuspensionRooms?.map(room => room?.roomNumber);

      // return availableRooms e.g. no suspensions or rooms that are not currently assigned to an appointment
      const listOfAvailableRooms = difference(listOfRooms, occupiedRooms);

      return sortListAscendingOrder(listOfAvailableRooms);
    },
  );
