import { connect } from "react-redux";

import CommonButton from "web/common/commonButton";
import { getCurrentPetServiceItem, isFromAnotherStore } from "core/selectors/checkInOutSelector";
import {
  getAssociates,
  getPetByPetServiceItemId,
  getPetServiceItem,
  getServicesIdsByPet,
} from "core/selectors/entitiesSelector";
import { setRebookErrorMessages } from "core/actionCreators/ui/web/commonActionCreators";
import buildRebookErrorMessages from "core/utils/errors/rebookErrorMessage";
import { showCheckInOutModal } from "core/actionCreators/checkInOutActionCreator";
import { modalTypes } from "core/constants/modalConstants";
import { clearCart } from "core/actionCreators/ui/web/cartActionCreators";
import { createLoadingSelector } from "core/selectors/utils";
import petsActionTypes from "core/actionTypes/petsActionTypes";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import itinerariesActionTypes from "core/actionTypes/itinerariesActionTypes";
import { petIsActive } from "dux/eligibility/helperUtils/checkIsBookingDisabled";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { REBOOK } from "dux/appointmentRebook/_appointmentRebookConstants";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const mapStateToProps = state => {
  const customer = getCurrentCustomerKey(state);
  const currentPetServiceItemId = getCurrentPetServiceItem(state);
  const selectedPet =
    currentPetServiceItemId &&
    getPetByPetServiceItemId(state, {
      petServiceItemId: currentPetServiceItemId,
    });
  const currentPetServiceItem =
    (currentPetServiceItemId &&
      getPetServiceItem(state, { petServiceItemId: currentPetServiceItemId })) ||
    {};
  const { associate, petServiceId, status } = currentPetServiceItem;
  const isPetsLoading = createLoadingSelector([
    petsActionTypes.LOAD_PETS_BY_CUSTOMER,
    customersActionTypes.LOAD_CUSTOMER,
    itinerariesActionTypes.LOAD_ITINERARY,
  ])(state);
  const isFromOtherStore = isFromAnotherStore(state, {
    petServiceItemId: currentPetServiceItemId,
  });
  const associates = getAssociates(state);
  const petServiceIds = getServicesIdsByPet(state, { petId: selectedPet && selectedPet.petId });

  return {
    label: REBOOK,
    disabled: isPetsLoading || isFromOtherStore || !petIsActive(state, customer),

    // here for merge props
    customer,
    currentPetServiceItem,
    errorMessages: buildRebookErrorMessages({
      associate,
      associates,
      petServiceId,
      petServiceIds,
      status,
    }),
  };
};

const mapDispatchToProps = (dispatch, { router: { navigate } }) => ({
  rebookHandler: ({ errorMessages, currentPetServiceItem, customer }) => {
    if (!currentPetServiceItem) {
      return;
    }

    const {
      addOns,
      enhancedServices,
      associate,
      lockToAssociate,
      pet,
      petServiceId,
    } = currentPetServiceItem;

    if (errorMessages.length) {
      dispatch(setRebookErrorMessages({ errorMessages }));
      dispatch(showCheckInOutModal({ modalType: modalTypes.REBOOK_ERROR }));
    } else {
      dispatch(clearCart());
      navigate(`/booking/${customer}/select-service`, {
        state: {
          cart: {
            addOns,
            enhancedServices,
            pet,
            associate,
            petServiceId,
            lockToAssociate,
          },
        },
      });
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () =>
    propsFromDispatch.rebookHandler({
      errorMessages: propsFromState.errorMessages,
      currentPetServiceItem: propsFromState.currentPetServiceItem,
      customer: propsFromState.customer,
    }),
});

export default withRouteProps(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton),
);
