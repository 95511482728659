import { connect } from "react-redux";

// Assets
import HeaderImage from "../../assets/images/hotel-int-header-image.png";

// Components
import AppHeader from "web/header/AppHeader";

// Containers
export const HotelRoomManagementHeader = connect(() => {
  return {
    componentId: "HotelRoomManagementHeader",
    title: "Room Manager",
    backgroundImage: HeaderImage,
  };
})(AppHeader);
