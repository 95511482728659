import React from "react";
import { connect } from "react-redux";
import { Layout, Text, Button } from "@prism/psm-ui-components";
import { hasBookedTrainingAppointments } from "./associateProfilePageSelectors";
import {
  setToggleRemoveVirtualTrainerModal,
  toggleRemoveTrainerFromVirtualFlashMessage,
} from "./associateProfilePageActions";
import { getAssociate } from "@/core/selectors/entitiesSelector";
import associateActionCreator from "@/core/actionCreators/associateActionCreator";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

// confirmation modal content
function ConfirmationModalContent({ children }) {
  return (
    <Layout.Box>
      <Layout.Stack space="stack-space-8">
        <Text size="text-size-lg" align="center" bold>
          Remove trainer from VDT list?
        </Text>
        {children}
      </Layout.Stack>
    </Layout.Box>
  );
}

export function RemoveVirtualTrainerConfirmationModalContent({
  isHidden,
  isLoading,
  onClose,
  onSubmit,
  associateName,
}) {
  if (isHidden) {
    return null;
  }
  return (
    <Layout.Box>
      <ConfirmationModalContent>
        <Layout.Center maxWidth={500}>
          <Text align="center" size="text-size-md">
            Are you sure you want to remove {associateName} from the list of approved virtual
            trainers? In order to add them back to the approved VDT list, a Service Now request will
            need to be submitted.
          </Text>
        </Layout.Center>
        <Layout.Cluster space="cluster-space-4">
          <Button variant="prism-primary-no-outline" onClick={onClose}>
            Go back
          </Button>
          <Button variant="prism-primary" disabled={isLoading} onClick={onSubmit}>
            Remove trainer
          </Button>
        </Layout.Cluster>
      </ConfirmationModalContent>
    </Layout.Box>
  );
}

const mapStateToProps = (state, { router }) => {
  const associateId = router?.params?.associateId;
  const associateDetails = getAssociate(state, { associateId });
  const associateName = associateDetails?.associateName;
  return {
    associateId,
    associateName,
    isHidden: hasBookedTrainingAppointments(state, { associateId }),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(setToggleRemoveVirtualTrainerModal());
  },
  patchAssociate: ({ associateId, data, onComplete }) => {
    dispatch(
      associateActionCreator.patchAssociate({
        associateId,
        data,
        onComplete,
      }),
    );
  },
  toggleRemoveTrainerFromVirtualFlashMessage: () => {
    dispatch(toggleRemoveTrainerFromVirtualFlashMessage());
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,

  onSubmit: () => {
    const { associateId } = stateProps;
    const { patchAssociate, onClose, toggleRemoveTrainerFromVirtualFlashMessage } = dispatchProps;

    patchAssociate({
      associateId,
      data: { isVirtualTrainer: false },
      onComplete: () => {
        toggleRemoveTrainerFromVirtualFlashMessage();
      },
    });

    onClose();
  },
});

export default withRouteProps(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  )(RemoveVirtualTrainerConfirmationModalContent),
);
