import { connect } from "react-redux";
import {
  getBulkPackageServiceName,
  getBulkPackageOfferingHasExistingPackage,
} from "../bulkPackageOfferingsSelectors";
import { toggleBulkPackageOfferingsDropdown } from "../../../toggleArrow/toggleArrowActions";
import { getIsSelectedBulkPackageOfferingDropdownSelected } from "../../../toggleArrow/toggleArrowSelectors";
import BulkPackageOfferingsHeaderComponent from "../../../_components/bgm/bulkPackageOfferings/BulkPackageOfferingsHeaderComponent";

const mapStateToProps = (state, { petServiceId }) => {
  const isSelected = !getIsSelectedBulkPackageOfferingDropdownSelected(state, { petServiceId });

  return {
    componentID: `bulk-package-offerings-group-header-text-and-arrow-${petServiceId}`,
    isDisabled: getBulkPackageOfferingHasExistingPackage(state, { petServiceId }),
    headerText: getBulkPackageServiceName(state, { petServiceId }),
    direction: isSelected ? "down" : "up",
    isHidden: false,
    petServiceId,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleDropdown: petServiceId => dispatch(toggleBulkPackageOfferingsDropdown(petServiceId)),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,
  clickHandler: () => propsFromDispatch.toggleDropdown(propsFromState.petServiceId),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(BulkPackageOfferingsHeaderComponent);
