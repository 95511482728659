import { connect } from "react-redux";
import { getCommonServicesByPet } from "../../../core/selectors/entitiesSelector";
import commonServicesButtonGrid from "../../common/servicesForPet/commonServicesButtonGrid";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = (state, ownProps) => {
  return {
    services: getCommonServicesByPet(state, ownProps),
    isHidden: getSystemBookingFlow(state) !== systemName.SALON,
    componentID: "buttonGrid-salonServices",
    testid: "buttonGrid-salonServices",
  };
};

export default connect(mapStateToProps)(commonServicesButtonGrid);
