import { createSelector } from "reselect";
import moment from "moment";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getAssociates } from "../../entitiesSelector";
import getPrismPetState from "../../../../dux/prismPet/prismPetSelector";

export const selectHotelNotes = createSelector(
  [getPrismPetState, getAssociates],
  (prismPet, associates) => {
    if (prismPet && prismPet.profiles) {
      const hotelProfile = prismPet.profiles.find(
        profile => profile.profileType === systemName.DDC_HOTEL,
      );
      if (hotelProfile && hotelProfile.notes) {
        return hotelProfile.notes
          .map(item => ({
            ...item,
            formatedTime: moment(item.timestamp).format("MMMM Do YYYY, h:mm a"),
            fullName: associates[item.associateId] && associates[item.associateId].associateName,
          }))
          .sort((a, b) => moment.utc(a.timestamp).diff(moment.utc(b.timestamp)));
      }
    }
    return [];
  },
);
