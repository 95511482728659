/* NOTE:
    Use "set" over api verbs  "get, put, post patch" etc. if action does not invoke a saga and API call. otherwise use
    relevant api verb as a prefix

    see adr-002-Redux_Flow_Naming_Conventions.md for more info

    These notes can be deleted. as they are just a reminder when creating files
*/

/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_STORE_WIDE_NOTES = "GET_STORE_WIDE_NOTES";
export const GET_STORE_WIDE_NOTES_REQUEST = "GET_STORE_WIDE_NOTES_REQUEST";
export const GET_STORE_WIDE_NOTES_SUCCESS = "GET_STORE_WIDE_NOTES_SUCCESS";
export const GET_STORE_WIDE_NOTES_FAILURE = "GET_STORE_WIDE_NOTES_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getStoreWideNotes = ({ storeNumber }) => ({
  type: GET_STORE_WIDE_NOTES,
  storeNumber,
});

export const getStoreWideNotesRequest = payload => ({
  type: GET_STORE_WIDE_NOTES_REQUEST,
  payload,
});

export const getStoreWideNotesSuccess = ({ notifications }) => ({
  type: GET_STORE_WIDE_NOTES_SUCCESS,
  notifications,
});

export const getStoreWideNotesFailure = payload => ({
  type: GET_STORE_WIDE_NOTES_FAILURE,
  payload,
});
