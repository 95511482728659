import { connect } from "react-redux";
import SearchSelect from "./searchSelectComponent";
import { setSearchFieldType } from "core/actionCreators/searchSalonActionCreator";

/*
* This Container is simply being used as a pass through for the SearchSelect
* Component. It is primarily here if we need to interact with the Redux store
* or dispatch actions in the future.
*/
const mapStateToProps = (state, ownProps) => {
  return {
    value: ownProps.value.length ? ownProps.value : ownProps.defaultValue,
    defaultValue: ownProps.defaultValue,
    options: ownProps.options,
    onFocus: ownProps.onFocus,
    onBlur: ownProps.onBlur
  };
};

const mapDispatchToProps = dispatch => ({
  onSelectionChange: event => {
    const searchFieldType = event.value;
    dispatch(setSearchFieldType({ searchFieldType }));
  }
});

const SearchSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchSelect);

export default SearchSelectContainer;
