import React from "react";
import styled from "styled-components";
import { capitalize } from "lodash/fp";
import Img from "../../../web/common/Img";
import { color  } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import HourOption from "../../../web/common/hourOption";

export default function availableManualAppointmentComponent(props) {
  const {
    type,
    withHours,
    hourOptions,
    newAppointmentTimeSlot,
    selectNewAppointmentTimeSlot,
    isAppointmentSelected,
    onClick,
    isDashboard
  } = props;

  return (
    <Container onClick={onClick}>
      <SectionWrapper>
        <Image src={require(`../../../assets/icons/${type}-appointment.svg`)} />

        <InfoWrapper withHours={withHours}>
          <Title>{capitalize(type)} appointment</Title>

          {withHours && (
            <React.Fragment>
              {isDashboard ? null : <div>Select check-in time</div>}
              <HoursContainer>
                {hourOptions.map(hourOption => (
                  <HourOption
                    key={hourOption.startDateTime}
                    onClick={() => {
                      hourOption.enableHourAction ? selectNewAppointmentTimeSlot(hourOption) : null
                    }}
                    hourOption={hourOption}
                    selected={
                      newAppointmentTimeSlot &&
                      newAppointmentTimeSlot.startDateTime === hourOption.startDateTime &&
                      isAppointmentSelected
                    }
                  />
                ))}
              </HoursContainer>
            </React.Fragment>
          )}
        </InfoWrapper>
      </SectionWrapper>
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex: 1;
  padding: 15px;
  cursor: pointer;
  border: 1px solid ${color.ororoMunroe};
  flex-shrink: 0;

  &:hover {
    border: 1px solid ${color.kalEl};
  }
`;

const SectionWrapper = styled.section`
  display: flex;
  flex: 1;
`;

const Image = styled(Img)`
  width: 70px;
  height: 70px;
  border-radius: 3.6px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex: 1;
  justify-content: ${({ withHours }) => (withHours ? "space-between" : "center")};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: ${fontSizes.regular};
`;

const HoursContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
