import cartActionTypes from "../../../../core/actionTypes/ui/web/cartActionTypes";

export const selectPetService = ({ petServiceId, petId }) => ({
  type: cartActionTypes.SELECT_PET_SERVICE,
  petServiceId,
  petId
});

export const setAddons = ({ addons, petId }) => ({
  type: cartActionTypes.SET_ADDONS,
  addons,
  petId
});

export const removeAddOn = ({ addon, petId }) => ({
  type: cartActionTypes.REMOVE_ADDON,
  addon,
  petId
});

export const setStandalone = ({ petId }) => ({
  type: cartActionTypes.SET_STANDALONE,
  petId
});

export const setSpecials = ({ specials, petId }) => ({
  type: cartActionTypes.SET_SPECIALS,
  specials,
  petId
});

export const selectTimeSlot = ({ petId, timeSlot }) => ({
  type: cartActionTypes.SELECT_TIME_SLOT,
  petId,
  timeSlot
});

export const removeAppointmentFromCart = ({ petId }) => ({
  type: cartActionTypes.REMOVE_APPOINTMENT_FROM_CART,
  petId
});

export const cancelAppointment = ({ petId, customerKey }) => ({
  type: cartActionTypes.CANCEL_APPOINTMENT,
  petId,
  customerKey
});

export const clearCart = () => ({
  type: cartActionTypes.CLEAR_CART
});

export const initializeCart = ({ petServiceItemId }) => ({
  type: cartActionTypes.INITIALIZE_CART,
  petServiceItemId
});

export const setPetServiceItemId = ({ petServiceItemId, petId }) => ({
  type: cartActionTypes.SET_PET_SERVICE_ITEM_ID,
  petServiceItemId,
  petId
});

export const addSpecial = ({ petId, special }) => ({
  type: cartActionTypes.ADD_SPECIAL,
  petId,
  special
});

export const removeSpecial = ({ petId, special }) => ({
  type: cartActionTypes.REMOVE_SPECIAL,
  petId,
  special
});

export const setAdditionalBookingType = ({ petId, additionalBookingType }) => ({
  type: cartActionTypes.SET_ADDITIONAL_BOOKING_TYPE,
  petId,
  additionalBookingType,
});

export const setMaxCheckInLimit = ({ maxCheckInCount, petId }) => ({
  type: cartActionTypes.SET_MAX_CHECK_IN_LIMIT,
  maxCheckInCount,
  petId
});

export const setMaxPerBlockLimit = ({ maxPerBlockCount, petId }) => ({
  type: cartActionTypes.SET_MAX_PER_BLOCK_LIMIT,
  maxPerBlockCount,
  petId
});

export const setMaxCheckInBool = ({ maxCheckInBool, petId }) => ({
  type: cartActionTypes.SET_MAX_CHECK_IN_BOOL,
  maxCheckInBool,
  petId
});

export const setMaxPerBlockBool = ({ maxPerBlockBool, petId }) => ({
  type: cartActionTypes.SET_MAX_PER_BLOCK_BOOL,
  maxPerBlockBool,
  petId
});

export const setIsBoarded = ({ petId }) => ({
  type: cartActionTypes.SET_IS_BOARDED,
  petId
});

export const clearIsBoarded = ({ petId }) => ({
  type: cartActionTypes.CLEAR_IS_BOARDED,
  petId
});

export const initializeCartByItinerary = ({ itineraryId }) => ({
  type: cartActionTypes.INITIALIZE_CART_BY_ITINERARY,
  itineraryId
});

export const setLockToAssociate = ({ petId, lockToAssociate }) => ({
  type: cartActionTypes.SET_LOCK_TO_ASSOCIATE,
  petId,
  lockToAssociate
});

export const setAppointmentNote = ({ petId, appointmentNote }) => ({
  type: cartActionTypes.SET_APPOINTMENT_NOTE,
  petId,
  appointmentNote
});

export const setMaxPerBlockOrCheckReductionReason = ({
  petId,
  maxPerBlockOrCheckReductionReason
}) => ({
  type: cartActionTypes.SET_MAX_PER_BLOCK_REDUCTION_REASON,
  petId,
  maxPerBlockOrCheckReductionReason
});

export default {
  selectPetService,
  selectTimeSlot,
  removeAppointmentFromCart,
  setAddons,
  removeAddOn,
  setStandalone,
  clearCart,
  initializeCart,
  setPetServiceItemId,
  cancelAppointment,
  addSpecial,
  removeSpecial,
  setAdditionalBookingType,
  setMaxCheckInLimit,
  setMaxPerBlockLimit,
  setMaxCheckInBool,
  setMaxPerBlockBool,
  setIsBoarded,
  clearIsBoarded,
  initializeCartByItinerary,
  setLockToAssociate,
  setAppointmentNote,
  setMaxPerBlockOrCheckReductionReason
};
