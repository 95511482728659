import {
  SET_TOGGLE_TAKE_PHOTO_MODAL,
  SET_TOGGLE_REMOVE_VIRTUAL_TRAINER_MODAL,
  TOGGLE_REMOVE_TRAINER_FROM_VIRTUAL_FLASH_MESSAGE,
  TOGGLE_ASSOCIATE_PROFILE_MODAL,
} from "./associateProfilePageActions";

const initialState = {
  isTakePhotoModalOpen: false,
  isRemoveVirtualTrainerModalOpen: false,
  isRemoveTrainerFromVirtualFlashMessageOpen: false,
  isAssociateProfileModalOpen: false,
};

export default function associateProfilePageReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_ASSOCIATE_PROFILE_MODAL:
      return {
        ...state,
        isAssociateProfileModalOpen: !state.isAssociateProfileModalOpen,
      };
    case SET_TOGGLE_TAKE_PHOTO_MODAL:
      return {
        ...state,
        isTakePhotoModalOpen: !state.isTakePhotoModalOpen,
      };
    case SET_TOGGLE_REMOVE_VIRTUAL_TRAINER_MODAL:
      return {
        ...state,
        isRemoveVirtualTrainerModalOpen: !state.isRemoveVirtualTrainerModalOpen,
      };
    case TOGGLE_REMOVE_TRAINER_FROM_VIRTUAL_FLASH_MESSAGE:
      return {
        ...state,
        isRemoveTrainerFromVirtualFlashMessageOpen: !state.isRemoveTrainerFromVirtualFlashMessageOpen,
      };
    default:
      return state;
  }
}
