import { connect } from "react-redux";

import {
  setRedirectToForgotPin,
  logoutUser,
  hideAuthModal
} from "../../../../core/actionCreators/authActionCreators";
import AuthorizationRequiredModalComponent from "./AuthorizationRequiredModalComponent";

const mapDispatchToProps = dispatch => ({
  setRedirectToForgotPin: () => dispatch(setRedirectToForgotPin()),
  hideModal: () => dispatch(hideAuthModal()),
  logoutUser: ({ browserSessionOnly }) => dispatch(logoutUser({ browserSessionOnly }))
});

export default connect(
  null,
  mapDispatchToProps
)(AuthorizationRequiredModalComponent);
