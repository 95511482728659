// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WMWeVtTGU3TvMaD8oHZl:nth-child(odd) {
  background-color: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/web/associateScheduling/weeklyTable/weeklyRow/weeklyRow.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B","sourcesContent":[".weeklyRow:nth-child(odd) {\n  background-color: #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"weeklyRow": `WMWeVtTGU3TvMaD8oHZl`
};
export default ___CSS_LOADER_EXPORT___;
