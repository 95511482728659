import React from "react";

/**
 * React Node for History SVG
 *
 * @memberOf Views.SVG
 * @function
 * @name HistorySvg
 * @returns {JSX.Element}
 *
 * @example <HistorySvg />
 */
const HistorySvg = () => {
  return (
    <svg
      width="20px"
      height="17px"
      viewBox="0 0 20 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-59.000000, -431.000000)"
          fillRule="nonzero"
          stroke="#333333"
          strokeWidth="2"
        >
          <g id="history-icon-copy-4" transform="translate(60.000000, 432.000000)">
            <path
              d="M7.13944413,14.2068026 C8.15032453,14.7143115 9.2917992,15 10.5,15 C14.6421356,15 18,11.6421356 18,7.5 C18,3.35786438 14.6421356,0 10.5,0 C6.35786438,0 3,3.35786438 3,7.5 L3,10.5"
              id="Oval"
            ></path>
            <polyline
              id="Path-9"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="0 7.75 3 10.75 6 7.75"
            ></polyline>
            <polyline
              id="Path-8"
              strokeLinecap="round"
              points="10.5 4.5 10.5 8.42857143 12 10"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HistorySvg;
