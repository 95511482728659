import React from "react";
import { connect } from "react-redux";
import { Layout, Text } from "@prism/psm-ui-components";

import getAppointmentMetaDataByPetId from "core/selectors/pet/getAppointmentMetaDataByPetId";
import { getAppointmentPriceSalon } from "dux/appointmentPrice/appointmentPriceSelectors";
import comparePrices from "dux/utils/stringUtils/comparePrices";
import { selectPetPrimaryServicePricing } from "../hotelPricingSummary/hotelPricingSummarySelectors";
import {
  selectAddonByPetAndAddonId,
  selectAddonTotalPriceByGroupingId,
  selectAddonTotalOriginalPriceByGroupingId,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { selectHotelCartPetProductById } from "../servicesCartHotel/servicesCartHotelSelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { formatMoney } from "../_utils/moneyUtil";

export const PriceChangeComponent = ({ isHidden, price, componentId }) => {
  return (
    !isHidden && (
      <Layout.Box id={componentId} data-testid="price-change">
        <Text id={`${componentId}__price`} style={{ textDecoration: "line-through" }}>
          {formatMoney(price)}
        </Text>
      </Layout.Box>
    )
  );
};

// SALON CONTAINER ----------------------------------------------------------------------------------------
export const SalonPriceChange = connect((state, { petId }) => {
  const { dynamicPrice } = getAppointmentMetaDataByPetId(state, { petId });
  const originalPrice = dynamicPrice?.originalPrice;
  const appointmentPrice = getAppointmentPriceSalon(state, { petId });

  return {
    componentId: "SalonPriceChange",
    isHidden: !originalPrice || comparePrices(originalPrice, appointmentPrice),
    price: originalPrice?.toFixed(2),
  };
})(PriceChangeComponent);

// HOTEL CONTAINER ----------------------------------------------------------------------------------------
export const HotelPriceChange = connect(state => {
  const petId = getCurrentPet(state);
  const { finalPrice = 0, originalPrice = 0 } = selectPetPrimaryServicePricing(petId)(state);

  return {
    componentId: "HotelPriceChange",
    isHidden: comparePrices(originalPrice, finalPrice), // When a special is not present or no override is pending,
    price: originalPrice,
  };
})(PriceChangeComponent);

// HOTEL CHECK IN/OUT ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelAddonPriceChange = connect((state, { addonId, petId }) => {
  const groupingId = selectAddonByPetAndAddonId(addonId)(state, { petId })?.groupingId;
  const originalPrice = selectAddonTotalOriginalPriceByGroupingId(groupingId)(state, { petId });
  const finalPrice = selectAddonTotalPriceByGroupingId(groupingId)(state, { petId });

  return {
    componentId: "HotelAddonPriceChange",
    isHidden: comparePrices(originalPrice, finalPrice),
    price: originalPrice,
  };
})(PriceChangeComponent);

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingAddonPriceChange = connect((state, { petId, addonId }) => {
  const addon = selectHotelCartPetProductById({ petId, productId: addonId })(state);

  const originalPrice = addon?.pricing?.originalPrice;
  const finalPrice = addon?.pricing?.finalPrice;

  return {
    componentId: "HotelBookingAddonPriceChange",
    isHidden: comparePrices(originalPrice, finalPrice),
    price: originalPrice,
  };
})(PriceChangeComponent);
