/**
 * Constant representing the action type to set the selected suspension type.
 *
 * @type {string}
 */
export const SET_SELECTED_SUSPENSION_TYPE = "SET_SELECTED_SUSPENSION_TYPE";
/**
 * Constant Represents the constant variable for resetting the selected suspension type.
 *
 * @constant {string}
 * @description Use this constant variable to reset the selected suspension type.
 */
export const RESET_SELECTED_SUSPENSION_TYPE = "RESET_SELECTED_SUSPENSION_TYPE";


/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
/**
 * Sets the selected suspension type.
 *
 * @memberof Actions.ScheduledSuspensions
 * @function
 * @param {Object} options - The options object.
 * @param {Object} options.suspensionType - The selected suspension type.
 * @param {string} options.suspensionId - The selected suspension id.
 * @returns {Object} - The action object with type and suspensionType.
 */
export const setSelectedSuspensionType = ({ suspensionType, suspensionId }) => ({
  type: SET_SELECTED_SUSPENSION_TYPE,
  suspensionType,
  suspensionId,
});
/**
 * Resets the selected suspension type to initial State.
 *
 * @memberof Actions.ScheduledSuspensions
 * @function
 * @returns {Object} The action object with type as RESET_SELECTED_SUSPENSION_TYPE.
 */
export const reSetSelectedSuspensionType = () => ({
  type: RESET_SELECTED_SUSPENSION_TYPE,
});

