/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const newMedicationActionTypes = {
  SET_NEW_MEDICATION: "SET_NEW_MEDICATION",
  CLEAR_NEW_MEDICATION: "CLEAR_NEW_MEDICATION",
  SHOW_ADD_NEW_MEDICATION: "SHOW_ADD_NEW_MEDICATION",
  HIDE_ADD_NEW_MEDICATION: "HIDE_ADD_NEW_MEDICATION",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setNewMedication = ({ petId }) => ({
  type: newMedicationActionTypes.SET_NEW_MEDICATION,
  petId,
});

export const clearNewMedication = ({ petId }) => ({
  type: newMedicationActionTypes.CLEAR_NEW_MEDICATION,
  petId,
});

export const showAddNewMedication = ({ petId }) => ({
  type: newMedicationActionTypes.SHOW_ADD_NEW_MEDICATION,
  petId,
});

export const hideAddNewMedication = ({ petId }) => ({
  type: newMedicationActionTypes.HIDE_ADD_NEW_MEDICATION,
  petId,
});
