import React from "react";
import moment from "moment";
import styled, { css } from "styled-components";
import TimeRangePicker from "./TimeRangePicker";
import ClickableText from "../ClickableText";

import withClickOutside from "../hoc/withClickOutside";
import EditIcon from "../../../assets/icons/edit.svg";

const StyledTimeRange = styled.div`
  display: flex;
  align-items: flex-end;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;

const Icon = styled.img`
  margin: 0 8px;
  cursor: pointer;
  display: ${props => (props.show ? "auto" : "none")};
`;

class TimeRangePickerToggle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inEditMode: false,
      appointmentStartTime: null,
      appointmentEndTime: null,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleClickOutside() {
    if (this.state.inEditMode) {
      this.save();
    }
  }

  cancel() {
    const initialStartTime = this.props.startTime ? moment(this.props.startTime, "HH:mm") : null;
    const initialEndTime = this.props.endTime ? moment(this.props.endTime, "HH:mm") : null;

    this.setState({
      inEditMode: false,
      appointmentStartTime: initialStartTime,
      appointmentEndTime: initialEndTime,
    });
  }

  save() {
    const { onSave } = this.props;
    const { appointmentStartTime, appointmentEndTime } = this.state;

    if (onSave) {
      onSave({
        startTime: appointmentStartTime,
        endTime: appointmentEndTime,
      });
    }
    this.setState({ inEditMode: false });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.save();
    } else if (e.key === "Escape") {
      this.cancel();
    }
  }

  setNewTime(m, newTime) {
    const [hours, minutes] = newTime.split(":");
    return moment(m).set({ hours, minutes });
  }

  render() {
    const { clickOutsideRef, disabled, petServiceItem } = this.props;
    const { inEditMode, appointmentStartTime, appointmentEndTime } = this.state;
    const isValid =
      appointmentStartTime &&
      appointmentEndTime &&
      appointmentStartTime.isBefore(appointmentEndTime, "minutes");
    const startDateTime = petServiceItem.startDateTime || appointmentStartTime;
    const endDateTime = petServiceItem.endDateTime || appointmentEndTime;

    const initialStartTime = this.props.startTime ? moment(this.props.startTime, "HH:mm") : null;
    const initialEndTime = this.props.endTime ? moment(this.props.endTime, "HH:mm") : null;

    return (
      <div ref={clickOutsideRef}>
        <StyledTimeRange disabled={disabled} data-testid="time-range-picker-toggle">
          {inEditMode ? (
            <>
              <TimeRangePicker
                startTime={appointmentStartTime || initialStartTime}
                endTime={appointmentEndTime || initialEndTime}
                onChange={({ startTime, endTime }) => {
                  this.setState({
                    appointmentStartTime: startTime ? moment(startTime, "HH:mm") : null,
                    appointmentEndTime: endTime ? moment(endTime, "HH:mm") : null,
                  });
                }}
              />
              <ClickableText isVisible onClick={this.save} disabled={!isValid}>
                Save
              </ClickableText>
              <ClickableText isVisible onClick={this.cancel}>
                Cancel
              </ClickableText>
            </>
          ) : (
            <>
              <span>
                {startDateTime && endDateTime
                  ? `${moment(startDateTime).format("hh:mm A")} - ${moment(endDateTime).format(
                      "hh:mm A",
                    )}`
                  : "No times entered"}
              </span>
            </>
          )}
          <Icon
            src={EditIcon}
            onClick={() => !inEditMode && this.setState({ inEditMode: true })}
            show={!inEditMode}
          />
        </StyledTimeRange>
      </div>
    );
  }
}

export default withClickOutside(TimeRangePickerToggle);
