import { createSelector } from "reselect";
import { getState } from "core/selectors/commonSelector";

export const getBeddingTypes = createSelector([getState], state => {
  return state?.hotelBeddingTypes;
});

export const getBeddingTypeSelectorOptions = createSelector([getBeddingTypes], beddingTypes => {
  return beddingTypes
    ? Object.keys(beddingTypes).map(index => beddingTypes[index]?.beddingTypeName)
    : [];
});

export const getPetDefaultBeddingType = ({ beddingTypes, beddingType }) => {
  const beddingIndex = Object.keys(beddingTypes)?.find(
    index => beddingTypes[index]?.beddingTypeName === beddingType,
  );

  return beddingIndex ? beddingTypes[beddingIndex]?.beddingTypeId : "";
};

export const getPetBeddingName = ({ beddingTypes, petProfile }) => {
  const beddingIndex = Object.keys(beddingTypes)?.find(
    index => beddingTypes[index]?.beddingTypeId == petProfile?.beddingType,
  );

  return beddingIndex ? beddingTypes[beddingIndex]?.beddingTypeName : "";
};
