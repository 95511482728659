import { put, takeEvery, call, all, select } from "redux-saga/effects";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import {
  fetchHotelOverBookingExceptionById,
  patchHotelOverBookingExceptionById,
} from "@/core/services/associateWebBff/hotelOverbookingExceptions";
import { formatExceptionDates } from "@/core/utils/overbookingUtils/formatExceptionDates";
import {
  GET_OVERBOOKING_EXCEPTION,
  PATCH_OVERBOOKING_EXCEPTION,
  getOverbookingExceptionFailure,
  getOverbookingExceptionRequest,
  getOverbookingExceptionSuccess,
  patchOverbookingExceptionFailure,
  patchOverbookingExceptionRequest,
  patchOverbookingExceptionSuccess,
  setOverbookingException,
  setOverbookingExceptionError,
} from "./overBookingExceptionActions";

function* saveOverbookingExceptionResponse(exception = {}) {
  const { capacityExceptionId, roomTypeBucketNumber, overBookingPercentage } = exception;
  const { startDate, endDate } = formatExceptionDates(exception);
  yield put(
    setOverbookingException({
      capacityExceptionId,
      roomTypeBucketNumber,
      startDate,
      endDate,
      overBookingPercentage,
    }),
  );
}

/**
 * Generator function to fetch hotel overbooking exception by id
 * @memberOf Sagas.hotel.Overbooking
 * @generator
 * @name onGetOverBookingException
 * @returns {Generator<*, void, *>}
 */
function* onGetOverBookingException({ exceptionId }) {
  try {
    yield put(getOverbookingExceptionRequest());
    const storeNumber = yield select(getStoreNumber);
    const response = yield call(fetchHotelOverBookingExceptionById, {
      storeNumber,
      exceptionId,
    });

    yield call(saveOverbookingExceptionResponse, response?.data?.result);
    yield put(getOverbookingExceptionSuccess());
  } catch (error) {
    yield put(getOverbookingExceptionFailure(error));
  }
}

/**
 * Generator function to update/cancel an overbooking exception
 * @memberOf Sagas.hotel.Overbooking
 * @generator
 * @name onGetOverBookingException
 * @returns {Generator<*, void, *>}
 */
export function* onPatchOverBookingException({
  capacityExceptionId,
  roomTypeBucketNumber,
  startDate,
  endDate,
  overBookingPercentage,
  isCanceled,
}) {
  try {
    yield put(patchOverbookingExceptionRequest());
    const storeNumber = yield select(getStoreNumber);
    const response = yield call(patchHotelOverBookingExceptionById, {
      storeNumber,
      capacityExceptionId,
      roomTypeBucketNumber,
      startDate,
      endDate,
      overBookingPercentage,
      isCanceled,
    });

    yield call(saveOverbookingExceptionResponse, response?.data?.result);
    yield put(patchOverbookingExceptionSuccess());
  } catch (error) {
    yield put(setOverbookingExceptionError(error));
    yield put(patchOverbookingExceptionFailure());
  }
}

function* watchOnGetOverBookingException() {
  yield takeEvery(GET_OVERBOOKING_EXCEPTION, onGetOverBookingException);
}

function* watchOnPatchOverBookingException() {
  yield takeEvery(PATCH_OVERBOOKING_EXCEPTION, onPatchOverBookingException);
}

export default function* overBookingExceptionSaga() {
  yield all([watchOnGetOverBookingException(), watchOnPatchOverBookingException()]);
}
