import { connect } from "react-redux";
import AppointmentHistoryComponent from "./AppointmentHistoryComponent";

const mapStateToProps = () => {
  return {
    isHidden: false,
    componentID: "AppointmentHistory",
  };
};

export default connect(mapStateToProps)(AppointmentHistoryComponent);
