/* * ----------------------------------------------------------------------- * *\
  SERVICE_MANAGEMENT_TOGGLE_REASONS
\* * ----------------------------------------------------------------------- * */
export const GET_SERVICE_MANAGEMENT_TOGGLE_REASONS = "GET_SERVICE_MANAGEMENT_TOGGLE_REASONS";
export const getServiceManagementToggleReasons = ({ suspensionTypes }) => ({
  type: GET_SERVICE_MANAGEMENT_TOGGLE_REASONS,
  suspensionTypes,
});

export const GET_SERVICE_MANAGEMENT_TOGGLE_REASONS_REQUEST =
  "GET_SERVICE_MANAGEMENT_TOGGLE_REASONS_REQUEST";
export const getServiceManagementToggleReasonsRequest = () => ({
  type: GET_SERVICE_MANAGEMENT_TOGGLE_REASONS_REQUEST,
});

export const GET_SERVICE_MANAGEMENT_TOGGLE_REASONS_SUCCESS =
  "GET_SERVICE_MANAGEMENT_TOGGLE_REASONS_SUCCESS";
export const getServiceManagementToggleReasonsSuccess = ({ suspensionReasons }) => ({
  type: GET_SERVICE_MANAGEMENT_TOGGLE_REASONS_SUCCESS,
  suspensionReasons,
});

export const GET_SERVICE_MANAGEMENT_TOGGLE_REASONS_FAILURE =
  "GET_SERVICE_MANAGEMENT_TOGGLE_REASONS_FAILURE";
export const getServiceManagementToggleReasonsFailure = error => ({
  type: GET_SERVICE_MANAGEMENT_TOGGLE_REASONS_FAILURE,
  error,
});

export const POST_HOTEL_SERVICE_SUSPENSION_IS_UPDATED = "POST_HOTEL_SERVICE_SUSPENSION_IS_UPDATED";
export const postHotelServiceSuspensionIsUpdated = ({ isUpdated, reasonTypeName }) => ({
  type: POST_HOTEL_SERVICE_SUSPENSION_IS_UPDATED,
  isUpdated,
  reasonTypeName,
});

export const POST_HOTEL_BG_SERVICE_SUSPENSION_IS_UPDATED =
  "POST_HOTEL_BG_SERVICE_SUSPENSION_IS_UPDATED";
export const postHotelBGServiceSuspensionIsUpdated = idUpdated => ({
  type: POST_HOTEL_BG_SERVICE_SUSPENSION_IS_UPDATED,
  idUpdated,
});
