import { connect } from "react-redux";
import PetCartCardComponent from "../_components/petCard/PetCartCardComponent";
import { getPetBreedName, getPet } from "../../core/selectors/entitiesSelector";
import calculateAge from "../../core/utils/dateUtils/calculateAge";

const mapStateToProps = (state, ownProps) => {
  const { petId } = ownProps;
  const pet = getPet(state, { petId });
  return {
    pet,
    petId,
    petName: pet && pet.petName,
    age: pet && calculateAge(pet.birthDate),
    breedName: pet && getPetBreedName(state, { breedId: pet.breedId }),
    componentId: "petCard-hotelBookingCart",
  };
};

export default connect(mapStateToProps)(PetCartCardComponent);
