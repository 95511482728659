import { find } from "lodash/fp";

/** @module buildHourOption */

/**
 * Attempts to find a timestamp within a list of objects, get a price, currency code and a duration based on that
 * filtered timestamp and return an object with those properties.
 *
 * @export buildHourOption
 * @param {Object} obj - Destructuring parameters
 * @param {string} obj.dateTime
 * @param {Object} obj.service
 * @param {array} obj.availableTimeSlots
 * @returns {Object}
 */
export default function buildHourOption({ dateTime, service, availableTimeSlots }) {
  const timeSlot = find({ startDateTime: dateTime }, availableTimeSlots);
  const price = timeSlot ? timeSlot.price : service.price;
  const currencyCode = timeSlot ? timeSlot.currencyCode : service.currencyCode;
  const duration = timeSlot ? timeSlot.duration : service.duration;

  return {
    startDateTime: dateTime,
    available: !!timeSlot,
    price,
    currencyCode,
    duration
  };
}
