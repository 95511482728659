import React from "react";
import styled from "styled-components";
import VipBookingAlertMessageContainer from "../../../../../web/vipBookingAlertMessage/VipBookingAlertMessageContainer";
import GeneralTitle from "../../../../../web/common/GeneralTitlesAndMessages/GeneralTitle";
import VIPBookingAlertContinueButtonContainer from "../../../../../web/vipBookingAlertMessage/VIPBookingAlertContinueButtonContainer";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: 185px;
  align-items: center;
`;

const ContentCssWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 115px;
  width: 100%;
  margin-top: 50px;
  justify-content: space-between;
  align-items: center;
`;

const ButtonCssWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
`;

export default function VipMessageModal(props) {
  const { isHidden, componentID } = props;

  if (!isHidden) {
    return (
      <SectionWrapper id={componentID}>
        <GeneralTitle>Important to know</GeneralTitle>
        <ContentCssWrapper>
          <VipBookingAlertMessageContainer />
          <ButtonCssWrapper>
            <VIPBookingAlertContinueButtonContainer />
          </ButtonCssWrapper>
        </ContentCssWrapper>
      </SectionWrapper>
    );
  }

  return null;
}
