/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_SELECTED_REPORT = "GET_SELECTED_REPORT";
export const GET_SELECTED_REPORT_REQUEST = "GET_SELECTED_REPORT_REQUEST";
export const GET_SELECTED_REPORT_SUCCESS = "GET_SELECTED_REPORT_SUCCESS";
export const GET_SELECTED_REPORT_FAILURE = "GET_SELECTED_REPORT_FAILURE";

export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const SET_SELECTED_SORT_INFO = "SET_SELECTED_SORT_INFO";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 * Action to fetch selected report
 * @memberOf Actions.Reports
 * @function
 * @name getSelectedReport
 * @returns {object}
 * @example getSelectedReport()
 */
export const getSelectedReport = ({ reportType, selectedDate }) => ({
  type: GET_SELECTED_REPORT,
  reportType,
  selectedDate,
});

/**
 * Action to request selected report
 * @memberOf Actions.Reports
 * @function
 * @name getSelectedReportRequest
 * @returns {object}
 * @example getSelectedReportRequest()
 */
export const getSelectedReportRequest = () => ({
  type: GET_SELECTED_REPORT_REQUEST,
});

/**
 * Action to set selected report
 * @memberOf Actions.Reports
 * @function
 * @name getSelectedReportSuccess
 * @returns {object}
 * @example getSelectedReportSuccess()
 */
export const getSelectedReportSuccess = selectedReportData => ({
  type: GET_SELECTED_REPORT_SUCCESS,
  selectedReportData,
});

/**
 * Action to set error for selected report
 * @memberOf Actions.Reports
 * @function
 * @name getSelectedReportFailure
 * @returns {object}
 * @example getSelectedReportFailure()
 */
export const getSelectedReportFailure = error => ({
  type: GET_SELECTED_REPORT_FAILURE,
  error,
});

/**
 * Action to set selected filters
 * @memberOf Actions.Reports
 * @function
 * @param {Array} filters - { filterArrayIndex: 0 ,filterKey: 'petName', filterValues: ['Kho', 'Scooby']}
 * @name setSelectedFilters
 * @returns {object}
 * @example setSelectedFilters()
 */
export const setSelectedFilters = filters => ({
  type: SET_SELECTED_FILTERS,
  filters,
});

/**
 * Action to set sort order info
 * @memberOf Actions.Reports
 * @function
 * @param {Array} sortInfo - { sortOrder: 'asc', sortKey: 'propertyKey'}
 * @name setSelectedSortInfo
 * @returns {Object}
 * @example setSelectedSortInfo()
 */
export const setSelectedSortInfo = sortInfo => ({
  type: SET_SELECTED_SORT_INFO,
  sortInfo,
});
