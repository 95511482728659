import { connect } from "react-redux";
import BGM_STATUS from "../availableBundlesByPet/availableBundlesByPetConstants";
import { getBundleRedemptionsByStatus } from "../availableBundlesByPet/availableBundlesByPetSelectors";
import { headerComponentTypes } from "../../_constants";
import { getIsBulkHistoryRedemptionsDropdownSelected } from "../../toggleArrow/toggleArrowSelectors";
import { loadTrainingClassDetails } from "@/dux/manageTrainingClassPage/manageTrainingClassPageActions";
import { bundleDatesClickHandler } from "../availableBundlesUtils";
import BundleDatesListComponent from "../../_components/bgm/availableBundlesByPet/BundleDatesListComponent";

const mapStateToProps = (state, { bundleNumber, idEnum, offerType }) => {
  const { customerId, petId } = idEnum;
  return {
    customerId,
    bundleNumber,
    isListHidden: !getIsBulkHistoryRedemptionsDropdownSelected(state, { bundleNumber }),
    componentId: "bundle_history_redeemed_dates_list",
    dates: getBundleRedemptionsByStatus(state, {
      petId,
      bundleNumber,
      status: BGM_STATUS.COMPLETE,
    }),
    headerType: headerComponentTypes.BUNDLE_HISTORY,
    offerType: offerType,
    onClick: bundleDatesClickHandler,
  };
};

const mapDispatchToProps = dispatch => ({
  setCustomerKeyAndItineraryId: (customerKey, itineraryId) => {
    dispatch(loadTrainingClassDetails({ customerKey, itineraryId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BundleDatesListComponent);
