/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelHoursActionTypes = {
  LOAD_HOTEL_HOURS: "LOAD_HOTEL_HOURS",
  LOAD_HOTEL_HOURS_REQUEST: "LOAD_HOTEL_HOURS_REQUEST",
  LOAD_HOTEL_HOURS_SUCCESS: "LOAD_HOTEL_HOURS_SUCCESS",
  LOAD_HOTEL_HOURS_FAILURE: "LOAD_HOTEL_HOURS_FAILURE",
  SET_HOTEL_HOURS: "SET_HOTEL_HOURS",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const loadHotelHours = ({ date, storeNumber }) => ({
  type: hotelHoursActionTypes.LOAD_HOTEL_HOURS,
  date,
  storeNumber,
});

export const loadHotelHoursRequest = () => ({
  type: hotelHoursActionTypes.LOAD_HOTEL_HOURS_REQUEST,
});

export const loadHotelHoursSuccess = () => ({
  type: hotelHoursActionTypes.LOAD_HOTEL_HOURS_SUCCESS,
});

export const loadHotelHoursFailure = ({ error }) => ({
  type: hotelHoursActionTypes.LOAD_HOTEL_HOURS_FAILURE,
  error,
});

export const setHotelHours = ({ serviceId, hours }) => ({
  type: hotelHoursActionTypes.SET_HOTEL_HOURS,
  serviceId,
  hours,
});
