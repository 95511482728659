import React from "react";
import styled from "styled-components";
import AppointmentHistoryContainer from "./appointmentHistory/AppointmentHistoryContainer";

const StyledAppointmentsList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AppointmentsListComponent = ({ isHidden, componentID }) => {
  if (!isHidden) {
    return (
      <StyledAppointmentsList id={componentID}>
        <AppointmentHistoryContainer />
      </StyledAppointmentsList>
    );
  }

  return null;
};

export default AppointmentsListComponent;
