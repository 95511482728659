import { connect } from "react-redux";

/**
 * A Wrapper to handle a bug/feature in chrome. The mouseLeave and mouse out will not fire when
 * a button is disabled, so this container if or a component that wraps a button. This way we can still
 * get the mouseLeave and MouseOut events.
 */
import { getPet, getPetServiceItem } from "core/selectors/entitiesSelector";
import { hideRebookQuickView, showRebookQuickView } from "dux/quickViews/quickViewActions";
import QuickViewToolTipsEnterLeaveWrapper from "dux/_components/quickViewToolTips/QuickViewToolTipsEnterLeaveWrapper";

const mapStateToProps = (state, ownProps) => {
  const petId = ownProps.appointment.pet;
  const pet = getPet(state, { petId });
  const appointmentId = getPetServiceItem(state, ownProps.appointment);

  return {
    isVisible: true,
    componentID: `QuickViewRebookTextEnterLeave__ClickableText__${appointmentId}`,

    // not using petIsActive() as it does this by current customer to get current pet, We need the pet
    // by id in this case, so get it directly from the pet object in state.
    disabled: !pet.isActive,
  };
};

const mapDispatchToProps = dispatch => ({
  mouseEnterHandler: (e, { disabled }) => {
    if (disabled) {
      dispatch(
        showRebookQuickView({
          xLoc: e.clientX,
          yLoc: e.clientY,
        }),
      );
    }
  },
  handleMouseLeave: () => {
    dispatch(hideRebookQuickView());
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleMouseEnter: e =>
    propsFromDispatch.mouseEnterHandler(e, { disabled: propsFromState.disabled }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(QuickViewToolTipsEnterLeaveWrapper);
