import { GET_NOTIF_FOR_ALERT_MESSAGE_SUCCESS } from "./storeEventNotificationsActions";
import { emojiSize, petFactsSize } from "dux/notificationCenter/PetFacts";

const initialState = {
  randomNumber: Math.floor(Math.random() * (petFactsSize - 1)),
  randomNumberEmoji: Math.floor(Math.random() * (emojiSize - 1)),
  isCollapsed: true,
};

const storeEventNotificationsReducer = (state = initialState, { notifications, type }) => {
  switch (type) {
    case GET_NOTIF_FOR_ALERT_MESSAGE_SUCCESS:
      return {
        ...state,
        notifications,
      };
    default:
      return state;
  }
};

export default storeEventNotificationsReducer;
