import { connect } from "react-redux";
import Arrow from "web/common/Arrow";

const mapStateToProps = (state, props) => {
  const { isExpanded } = props;
  return {
    direction: isExpanded ? "down" : "up",
  };
};

export default connect(mapStateToProps)(Arrow);
