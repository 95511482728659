import { getFoodsExcludingNewFoodID } from "@/web/food/foodsSelector";
import { getMedsExcludingNewMedId } from "@/web/medication/medicationsSelector";
import { createSelector } from "reselect";
import getMomentByDateAndTime from "core/utils/dateUtils/byDateAndTime";
import moment from "moment";
import { isEmpty } from "lodash";
import { denormalizeHotelCart } from "../servicesCart/servicesCartUtils";
import { selectIncludedDescriptorsNotes } from "../hotelAddons/hotelAddonsLIstSelectors";
import { frequencyConstants } from "../frequency/FrequencyConstants";
import { getAllIncludedProductNumbers } from "@/core/utils/hotelEngagementUtils/getAllIncludedProductNumbers";

export const selectServicesCartHotel = state => state?.servicesCartHotel || {};

export const selectHotelCartDetails = createSelector(
  [selectServicesCartHotel],
  cart => cart?.cartDetails || {},
);

export const selectHotelCartStartTime = createSelector([selectHotelCartDetails], cartDetails =>
  getMomentByDateAndTime(
    moment(cartDetails?.startDate, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"),
    moment(cartDetails?.dropOffTime, "HH:mm:ss").format("HH:mm"),
  ),
);

export const selectHotelCartEndTime = createSelector([selectHotelCartDetails], cartDetails =>
  getMomentByDateAndTime(
    moment(cartDetails?.endDate, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"),
    moment(cartDetails?.pickUpTime, "HH:mm:ss").format("HH:mm"),
  ),
);

export const selectHotelItineraryIdFromCart = createSelector(
  [selectHotelCartDetails],
  cartDetails => {
    return cartDetails?.itineraryId || null;
  },
);
export const selectHotelCartPets = createSelector(
  [selectServicesCartHotel],
  cart => cart?.pets || {},
);

export const selectHotelCartPetById = petId =>
  createSelector([selectHotelCartPets], pets => pets[petId] || {});

export const selectHotelCartPetNotes = petId =>
  createSelector([selectHotelCartPetById(petId)], pet => pet?.notes || "");

export const selectHotelCartPetNeedsReview = petId =>
  createSelector([selectHotelCartPetById(petId)], ({ needsReview = false }) => needsReview);

export const selectHotelCartPetPricingSummary = petId =>
  createSelector([selectHotelCartPetById(petId)], pet => pet?.pricingSummary || {});

export const selectHotelCartPetAddonPricing = petId =>
  createSelector(
    [selectHotelCartPetPricingSummary(petId)],
    pricingSummary => pricingSummary?.addOnPricing || {},
  );

export const selectHotelCartPetFoodPricing = petId =>
  createSelector(
    [selectHotelCartPetPricingSummary(petId)],
    ({ feedingPricing }) => feedingPricing ?? {},
  );

export const selectHotelCartProducts = createSelector(
  [selectServicesCartHotel],
  cart => cart?.products || {},
);

export const selectHotelCartPetProducts = petId =>
  createSelector(
    [selectHotelCartProducts],
    products =>
      Object.values(products)?.filter(
        product => product?.petId?.toString() === petId?.toString(),
      ) || [],
  );

export const selectHotelCartPetPrimaryServiceProduct = petId =>
  createSelector(
    [selectHotelCartPetProducts(petId)],
    products => Object.values(products)?.find(product => product?.isPrimaryService) || {},
  );

export const selectHotelCartAddons = createSelector(
  [selectHotelCartProducts],
  products => Object.values(products)?.filter(product => !product?.isPrimaryService) || [],
);

export const selectHotelCartPetAddons = petId =>
  createSelector(
    [selectHotelCartProducts],
    products =>
      Object.values(products)?.filter(
        product => product?.petId?.toString() === petId.toString() && !product?.isPrimaryService,
      ) || [],
  );

export const selectHotelCartPetAddonsWithoutIncludedProducts = petId =>
  createSelector([selectHotelCartPetAddons(petId)], addons => {
    const allIncludedProducts = getAllIncludedProductNumbers(addons);
    return (
      addons?.filter(addon => {
        const isChildAddon =
          addon?.isAutoApplyAddOn && allIncludedProducts?.includes(addon?.productId);
        return !isChildAddon;
      }) || []
    );
  });

export const selectHotelCartPetAddonsWithoutAutoApply = petId =>
  createSelector(
    [selectHotelCartProducts],
    products =>
      Object.values(products)?.filter(
        product =>
          product?.petId === petId && !product?.isPrimaryService && !product?.isAutoApplyAddOn,
      ) || [],
  );

export const selectHotelCartPetProductById = ({ petId, productId }) =>
  createSelector(
    [selectHotelCartPetProducts(petId)],
    products => Object.values(products)?.find(product => product?.productId === productId) || null,
  );

/**
 * Helper to check if an addon has manual frequency and no dates
 * @memberOf Selectors.servicesCartHotel
 * @function
 * @name checkProductIsMissingDates
 * @param {Object} addon
 * @return Boolean
 * @example checkProductIsMissingDates(addon)
 */
export const checkProductIsMissingDates = ({ frequency, customFrequencyDates } = {}) => {
  return frequency === frequencyConstants.MANUAL && isEmpty(customFrequencyDates);
};

/**
 * Selector to check if an addon has manual frequency and no dates
 * @memberOf Selectors.servicesCartHotel
 * @function
 * @name selectCartProductIsMissingDates
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.productId
 * @return {(state: Object) => Boolean}
 * @example selectCartProductIsMissingDates({ petId, productId })(state)
 */
export const selectCartProductIsMissingDates = ({ petId, productId }) =>
  createSelector([selectHotelCartPetProductById({ petId, productId })], addon => {
    return !!addon && checkProductIsMissingDates(addon);
  });

/**
 * Selector to check if pet has any addons with manual frequency and no dates
 * @memberOf Selectors.servicesCartHotel
 * @function
 * @name selectCartPetProductsIsMissingDates
 * @param {String} petId
 * @return {(state: Object) => Boolean}
 * @example selectCartPetProductsIsMissingDates(petId)(state)
 */
export const selectCartPetProductsIsMissingDates = petId =>
  createSelector([selectHotelCartPetProducts(petId)], products => {
    return products?.some(addon => checkProductIsMissingDates(addon));
  });

export const selectHotelCartAndDenormalizeForPut = createSelector(
  [
    selectHotelCartDetails,
    selectHotelCartPets,
    selectHotelCartProducts,
    getFoodsExcludingNewFoodID,
    getMedsExcludingNewMedId,
  ],
  (cartDetails, pets, products, feedings, medications) =>
    denormalizeHotelCart({ cartDetails, pets, products, feedings, medications }),
);

export const selectProductSpecialsByPetId = ({ petId }) =>
  createSelector([selectHotelCartPetProducts(petId)], products => {
    let specials = [];
    const codes = [];

    // Get all specials from each pet product (addons and primary services)
    products?.map(product => {
      if (!isEmpty(product?.pricing?.specials)) {
        specials = specials.concat(product?.pricing?.specials);
      }
    });

    // Just get codes in order to map within flyout
    specials?.map(special => {
      if (special?.code) {
        codes.push(special?.code);
      }
    });
    return codes;
  });

export const selectCartHotelTotalPrice = createSelector(
  [selectHotelCartDetails],
  ({ totalPrice }) => totalPrice ?? 0,
);

/**
 * Selector to get all child addons of a geven enhanced addon, normalized by product number
 * @memberOf Selectors.ServicesCartHotel
 * @function
 * @name selectEnhancedAddonChildrenFromCart
 * @param { String } petId
 * @param { String } productId - product number of enhanced addon (productId from cart api responses)
 * @param { Object } state - Redux state
 * @return { { [productId: string]: Object } } - addons normalized by productId
 * @example selectEnhancedAddonChildrenFromCart({ petId, productId })(state)
 */
export const selectEnhancedAddonChildrenFromCart = ({ petId, productId }) =>
  createSelector(
    [selectHotelCartPetProductById({ petId, productId }), selectHotelCartPetAddons(petId)],
    (existingAddon, addons) => {
      if (!existingAddon?.isEnhancedAddOn) return {};

      // Note: productId/productNumber is not unique, an addon is a child of an enhanced addon
      // if its productId is in the included object AND if it is an auto applied addon
      const includedProducts = existingAddon?.includes?.products;
      const children = includedProducts?.map(({ productNumber }) => [
        productNumber,
        addons.find(
          addon =>
            addon?.isAutoApplyAddOn && addon?.productId.toString() === productNumber.toString(),
        ) ?? {},
      ]);
      return Object.fromEntries(children);
    },
  );

/**
 * Selector to get notes for an addon or child addons if the given addon is enhanced
 * @memberOf Selectors.ServicesCartHotel
 * @function
 * @name selectAddonNotesFromCart
 * @param { String } petId
 * @param { String } productId - product number of enhanced addon (productId from cart api responses)
 * @param { Object } state - Redux state
 * @return { { [productId: string]: { label: string, notes: string, id: string} } } - object containing addon notes, label for the notes input field, and productId
 * @example selectAddonNotesFromCart({ petId, productId })(state)
 */
export const selectAddonNotesFromCart = ({ petId, productId }) =>
  createSelector(
    [
      selectIncludedDescriptorsNotes(productId),
      selectHotelCartPetProductById({ petId, productId }),
      selectEnhancedAddonChildrenFromCart({ petId, productId }),
    ],
    (includedDescriptorNotes, addon, children) => {
      const isAppliedAndEnhanced = addon && addon.isEnhancedAddOn;
      const isNotAppliedAndEnhanced = !addon && includedDescriptorNotes.length;
      const isEnhanced = isAppliedAndEnhanced || isNotAppliedAndEnhanced;

      // Addon isn't enhanced
      if (!isEnhanced) return { [productId]: { id: productId, notes: addon?.notes } };

      // Addon isn't applied yet but is enhanced
      if (isNotAppliedAndEnhanced) return includedDescriptorNotes;

      // Addon is enhanced and applied
      const childNotes = {};
      const childArr = Object.entries(children) ?? [];
      childArr.forEach(([id, { productName, notes }]) => {
        childNotes[id] = { label: productName, notes, id };
      });

      return childNotes;
    },
  );
