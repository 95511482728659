import React from "react";
import { connect } from "react-redux";
import { Layout, Text, Button } from "@prism/psm-ui-components";
import {
  toggleCancelTrainingPendingAppointmentModal,
  resetScheduleTrainingPage,
} from "@/dux/scheduleTrainingPage/scheduleTrainingPageActions";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import {
  deleteTrainingClassItinerary,
  DELETE_TRAINING_CLASS_ITINERARY,
} from "@/dux/trainingClassItineraries/trainingClassItinerariesActions";
import { getTrainingClassAvailabilityIfActiveBundleIsAvailable } from "@/dux/trainingClassAvailability/trainingClassAvailabilityActions";
import { getAvailableBundlesForAllPetsByCustomer } from "@/dux/bgm/availableBundlesByPet/availableBundlesByPetActions";
import CommonModal from "@/web/common/modals/commonModal";
import {
  getIsCancelTrainingAppointmentModalOpen,
  getTrainingAppointment,
} from "@/dux/scheduleTrainingPage/scheduleTrainingPageSelectors";

export function CancelAppointmentModal({ isHidden, isLoading, onClose, onSubmit }) {
  if (isHidden) {
    return null;
  }
  return (
    <CommonModal isHidden={isHidden} onClose={onClose}>
      <Layout.Box padding="box-padding-8">
        <Layout.Stack space="stack-space-8">
          <Text size="text-size-lg" align="center" bold>
            Cancel Appointment
          </Text>
          <Text size="text-size-md" align="center">
            Are you sure you want to cancel this appointment?
          </Text>
          <Layout.Cluster space="cluster-space-4">
            <Button variant="prism-primary-no-outline" onClick={onClose}>
              Go back
            </Button>
            <Button variant="prism-primary" disabled={isLoading} onClick={onSubmit}>
              Cancel
            </Button>
          </Layout.Cluster>
        </Layout.Stack>
      </Layout.Box>
    </CommonModal>
  );
}

export default connect(
  (state) => {
    const { itineraryId } = getTrainingAppointment(state);
    return {
      itineraryId,
      isHidden: !getIsCancelTrainingAppointmentModalOpen(state),
      customerKey: getCurrentCustomerKey(state),
      isLoading: createLoadingSelector([DELETE_TRAINING_CLASS_ITINERARY])(state),
    };
  },
  (dispatch) => ({
    onClose: () => {
      dispatch(toggleCancelTrainingPendingAppointmentModal());
    },
    dispatchDeleteTrainingClassItinerary: (payload) => {
      dispatch(deleteTrainingClassItinerary(payload));
    },
    dispatchResetScheduleTrainingPage: () => {
      dispatch(resetScheduleTrainingPage());
    },
    dispatchGetAvailableBundlesForAllPetsByCustomer: ({ customerKey, onComplete }) =>
      dispatch(
        getAvailableBundlesForAllPetsByCustomer({
          customerKey,
          onComplete,
        }),
      ),
    dispatchGetTrainingClassAvailabilityIfActiveBundleIsAvailable: () =>
      dispatch(getTrainingClassAvailabilityIfActiveBundleIsAvailable()),
  }),
  (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,

    onSubmit: () => {
      const { customerKey, itineraryId } = propsFromState;
      const {
        dispatchDeleteTrainingClassItinerary,
        dispatchResetScheduleTrainingPage,
        dispatchGetAvailableBundlesForAllPetsByCustomer,
        dispatchGetTrainingClassAvailabilityIfActiveBundleIsAvailable,
      } = propsFromDispatch;

      dispatchDeleteTrainingClassItinerary({
        customerKey,
        itineraryId,
        onComplete: () => {
          dispatchResetScheduleTrainingPage();
          dispatchGetAvailableBundlesForAllPetsByCustomer({
            customerKey,
            onComplete: () => dispatchGetTrainingClassAvailabilityIfActiveBundleIsAvailable(),
          });
        },
      });
    },
  }),
)(CancelAppointmentModal);
