import { put, takeEvery, call, all } from "redux-saga/effects";
import salonsActionTypes from "../actionTypes/salonsActionTypes";
import salonsActionCreator from "../actionCreators/salonsActionCreator";
import { FetchAllSalons } from "../services/petsmartWebPublic/salonEndPoints";
import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";

function* onLoadAllSalons() {
  try {
    yield put(salonsActionCreator.loadAllSalonsRequest());
    const response = yield call(FetchAllSalons);
    const salons = response.data;
    yield put(
      salonsActionCreator.loadAllSalonsSuccess({
        salons: normalizeArrayByProperty(salons, "StoreNumber")
      })
    );
  } catch (error) {
    yield put(salonsActionCreator.loadAllSalonsFailure({ error }));
  }
}

function* watchLoadAllSalons() {
  yield takeEvery(salonsActionTypes.LOAD_ALL_SALONS, onLoadAllSalons);
}

export default function* salonsSaga() {
  yield all([watchLoadAllSalons()]);
}
