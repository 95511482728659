/**
 * Action types for hotel check out
 * @constant
 * @memberof actions.hotel.itinerary
 */
const checkOutActionTypes = {
  PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS: "PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS",
  PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS_REQUEST: "PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS_REQUEST",
  PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS_SUCCESS: "PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS_SUCCESS",
  PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS_FAILURE: "PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS_FAILURE",
};
export const {
  PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS,
  PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS_FAILURE,
} = checkOutActionTypes;

/**
 * Action creator for hotel patch to update pets to checked out
 * @memberof actions.hotel.itinerary
 * @param {Object} obj
 * @param {string} obj.itineraryId
 * @param {string[]} obj.petIds
 * @returns action obj
 */
export const patchHotelCheckOutPets = ({ itineraryId, petIds }) => ({
  type: PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS,
  itineraryId,
  petIds,
});

/**
 * Action creator for hotel check out loading
 * @memberof actions.hotel.itinerary
 * @function
 * @name patchHotelCheckOutPetsRequest
 * @returns action obj
 */
export const patchHotelCheckOutPetsRequest = () => ({
  type: checkOutActionTypes.PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS_REQUEST,
});

/**
 * Action creator for hotel check out loading
 * @memberof actions.hotel.itinerary
 * @function
 * @name patchHotelCheckOutPetsSuccess
 * @returns action obj
 */
export const patchHotelCheckOutPetsSuccess = () => ({
  type: checkOutActionTypes.PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS_SUCCESS,
});

/**
 * Action creator for hotel check out loading
 * @memberof actions.hotel.itinerary
 * @function
 * @name patchHotelCheckOutPetsFailure
 * @returns action obj
 */
export const patchHotelCheckOutPetsFailure = error => ({
  type: checkOutActionTypes.PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS_FAILURE,
  error,
});
