import { connect } from "react-redux";
import {
  clearSessionToken,
  hideAuthModal,
} from "../../../../core/actionCreators/authActionCreators";
import LockUserModalComponent from "./LockUserModalComponent";

const mapDispatchToProps = dispatch => ({
  lockUser: () => dispatch(clearSessionToken()),
  hideModal: () => dispatch(hideAuthModal()),
});

export default connect(null, mapDispatchToProps)(LockUserModalComponent);
