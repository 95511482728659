export const modalTypes = {
  APPOINTMENT_SET: "APPOINTMENT_SET",
  CANCEL_BOOKING_APPOINTMENT: "CANCEL_BOOKING_APPOINTMENT",
  BOOKING_REDIRECTION_MODAL: "BOOKING_REDIRECTION_MODAL",
  APPOINTMENT_MODIFIED: "APPOINTMENT_MODIFIED",
};

export const MAX_CHECK_IN_OPTIONS = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
];

export const MAX_PER_BLOCK_OPTIONS = [{ value: 1, label: "1" }];

export const MAX_PER_BLOCK_OPTIONS_FOR_MANAGER = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
];

export const ADDITIONAL_BOOKING_TYPE_NONE = "None";

export const ADDITIONAL_APPOINTMENT_OPTIONS = [
  {
    value: "isAdditionalAppointmentAny",
    label: "Overbook Any",
    id: "additional_appointment_any",
    type: "Any",
  },
  {
    value: "isAdditionalAppointmentControlled",
    label: "Overbook Controlled",
    id: "additional_appointment_controlled",
    type: "Controlled",
  },
  {
    value: "isAdditionalAppointmentNone",
    label: "No Overbook",
    id: "no_overbook",
    type: "None",
  },
];
