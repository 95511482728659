/* * ----------------------------------------------------------------------- * *\
  This component is a view component specificity designed to be a configurable diamond
  that can scale up.

  It takes the following configuration object as a cssConfig prop.
  {
    size: [Number],
    color: [String],
    animation: [boolean],
    zIndex: [number]
  };

  example of use:
    <ScalingDiamond cssConfig={ {size: 30, color: "blue", animation: true} } />
\* * ----------------------------------------------------------------------- * */

import React from "react";
import styled from "styled-components";
import { get } from "lodash/fp";
import Scale from "../../styles/Animation/scale/scale.ts";

const SectionWrapper = styled.section`
  transform: rotate(45deg);
  z-index: ${props => (get(["cssConfig", "zIndex"], props) ? props.cssConfig.zIndex : 0)};
`;

const InnerSection = styled.div`
  width: ${props => (props.cssConfig.size ? props.cssConfig.size : 10)}px;
  height: ${props => (props.cssConfig.size ? props.cssConfig.size : 10)}px;
  background-color: ${props => (props.cssConfig.color ? props.cssConfig.color : "black")};
  ${props => (props.cssConfig.animation ? `animation: ${Scale()} .5s ease;` : "")};
`;

export default function ScalingDiamond(props) {
  return (
    <SectionWrapper {...props}>
      <InnerSection {...props} />
    </SectionWrapper>
  );
}
