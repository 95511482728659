import { set } from "lodash/fp";
import searchSalonActionTypes from "core/actionTypes/searchSalonActionTypes";
import { checkForUndefined } from "core/utils/undefinedOrNullUtils/undefinedOrNullUtils";

const initialState = null;
/**
 * A Redux reducer for managing the search component name state.
 *
 * @memberof Reducer.persistent
 * @param {Object} state - The current state of the search component name.
 * @param {Object} action - The Redux action object.
 * @returns {Object} - The updated state of the search component name.
 */
export const searchComponentNameReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchSalonActionTypes.SET_SEARCH_COMPONENT_NAME:
      return checkForUndefined(action.searchComponentName, initialState);

    case searchSalonActionTypes.SET_PREVIOUS_SEARCH_COMPONENT_NAME:
      return action.prevSearchComponentName;

    default:
      return state;
  }
};