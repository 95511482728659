import modalInitState from "./_modalInitState";
import schedulesActionTypes from "../../actionTypes/schedulesActionTypes";
import getUpdatedModalState from "./helpers/getUpdatedModalState";

const initialState = modalInitState();

export default function schedulesModalsReducer(state = initialState, action) {
  return getUpdatedModalState(
    state,
    initialState,
    schedulesActionTypes.SHOW_SCHEDULES_MODAL,
    schedulesActionTypes.HIDE_SCHEDULES_MODAL,
    action
  );
}
