export const SET_USER_STATUS = "SET_USER_STATUS";

/**
 * Sets the users current status
 *
 * @memberof Actions.UserStatus
 * @function
 * @param {active | idle} status the current status of the user
 * @param {number} time the millisecond representation of when the status changed
 * @returns {Object} The action object with type as SET_USER_STATUS.
 */
export const setUserStatus = ({ status, time }) => ({
  type: SET_USER_STATUS,
  status,
  time,
});
