import React from "react";
import { Layout, Button, Text } from "@prism/psm-ui-components";
import { default as GraduationCapIcon } from "@/assets/icons/graduation-cap.svg";

export default function TrainingClassTypeButtonComponent({
  isAvailable,
  classTitle,
  purchaseClassCopy,
  reservedUnits,
  redeemedUnits,
  availableUnits,
  onClick,
  buttonStyles,
  remainingUnitsStyles,
}) {
  return (
    <Button variant="no-outline" style={buttonStyles} onClick={onClick}>
      <Layout.Center>
        <Layout.Box padding="box-padding-2">
          <Layout.Stack space="stack-space-4">
            <Layout.Center>
              <Layout.Stack>
                <Layout.Center>
                  <img style={{ width: "3rem", height: "3rem" }} src={GraduationCapIcon} />
                </Layout.Center>
                <Text bold size="text-size-xl">
                  {classTitle}
                </Text>
              </Layout.Stack>
            </Layout.Center>
            <Layout.Center>
              <Layout.Cluster space="cluster-space-4">
                <Layout.Box>
                  <Text bold>Reserved: </Text>
                  <Text>{reservedUnits}</Text>
                </Layout.Box>
                <Layout.Box>
                  <Text bold>Redeemed: </Text>
                  <Text>{redeemedUnits}</Text>
                </Layout.Box>
                <Layout.Box>
                  <Text style={remainingUnitsStyles} bold>
                    Remaining:{" "}
                  </Text>
                  <Text style={remainingUnitsStyles}>{availableUnits}</Text>
                </Layout.Box>
              </Layout.Cluster>
            </Layout.Center>
            <Text isHidden={isAvailable} size="text-size-lg">
              {purchaseClassCopy}
            </Text>
          </Layout.Stack>
        </Layout.Box>
      </Layout.Center>
    </Button>
  );
}
