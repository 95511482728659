// GET bulk package offerings ----------------------------------------------------------------------
export const GET_BULK_PACKAGE_OFFERINGS = "GET_BULK_PACKAGE_OFFERINGS";
export const GET_BULK_PACKAGE_OFFERINGS_REQUEST = "GET_BULK_PACKAGE_OFFERINGS_REQUEST";
export const GET_BULK_PACKAGE_OFFERINGS_SUCCESS = "GET_BULK_PACKAGE_OFFERINGS_SUCCESS";
export const GET_BULK_PACKAGE_OFFERINGS_FAILURE = "GET_BULK_PACKAGE_OFFERINGS_FAILURE";

export const getBulkPackageOfferings = ({ storeNumber, customerKey, petId, offerType }) => ({
  type: GET_BULK_PACKAGE_OFFERINGS,
  storeNumber,
  customerKey,
  petId,
  offerType,
});

export const getBulkPackageOfferingsRequest = () => ({
  type: GET_BULK_PACKAGE_OFFERINGS_REQUEST,
});

export const getBulkPackageOfferingsSuccess = () => ({
  type: GET_BULK_PACKAGE_OFFERINGS_SUCCESS,
});

export const getBulkPackageOfferingsFailure = ({ error }) => ({
  type: GET_BULK_PACKAGE_OFFERINGS_FAILURE,
  error,
});

// POST bulk package offerings ---------------------------------------------------------------------
export const POST_BULK_PACKAGE_OFFERINGS = "POST_BULK_PACKAGE_OFFERINGS";
export const POST_BULK_PACKAGE_OFFERINGS_REQUEST = "POST_BULK_PACKAGE_OFFERINGS_REQUEST";
export const POST_BULK_PACKAGE_OFFERINGS_SUCCESS = "POST_BULK_PACKAGE_OFFERINGS_SUCCESS";
export const POST_BULK_PACKAGE_OFFERINGS_FAILURE = "POST_BULK_PACKAGE_OFFERINGS_FAILURE";

export const purchaseBulkPackageOfferings = () => ({
  type: POST_BULK_PACKAGE_OFFERINGS,
});

export const postBulkPackageOfferingsRequest = () => ({
  type: POST_BULK_PACKAGE_OFFERINGS_REQUEST,
});

export const postBulkPackageOfferingsSuccess = () => ({
  type: POST_BULK_PACKAGE_OFFERINGS_SUCCESS,
});

export const postBulkPackageOfferingsFailure = ({ error }) => ({
  type: POST_BULK_PACKAGE_OFFERINGS_FAILURE,
  error,
});

// PUT Refund Package  ---------------------------------------------------------------------
export const PUT_REFUND_PACKAGE = "PUT_REFUND_PACKAGE";
export const PUT_REFUND_PACKAGE_REQUEST = "PUT_REFUND_PACKAGE_REQUEST";
export const PUT_REFUND_PACKAGE_SUCCESS = "PUT_REFUND_PACKAGE_SUCCESS";
export const PUT_REFUND_PACKAGE_FAILURE = "PUT_REFUND_PACKAGE_FAILURE";

export const refundPackage = ({ storeNumber, customerKey, petId, offerType, bundleConfigId }) => ({
  type: PUT_REFUND_PACKAGE,
  storeNumber,
  customerKey,
  petId,
  offerType,
  bundleConfigId,
});

export const refundPackageRequest = () => ({
  type: PUT_REFUND_PACKAGE_REQUEST,
});

export const refundPackageSuccess = () => ({
  type: PUT_REFUND_PACKAGE_SUCCESS,
});

export const refundPackageFailure = ({ error }) => ({
  type: PUT_REFUND_PACKAGE_FAILURE,
  error,
});

// Selected bulk package state ---------------------------------------------------------------------
export const SET_SELECTED_BULK_PACKAGE_OFFERING = "SET_SELECTED_BULK_PACKAGE_OFFERING";
export const CLEAR_SELECTED_BULK_PACKAGE_OFFERINGS = "CLEAR_SELECTED_BULK_PACKAGE_OFFERINGS";

export const setSelectedBulkPackageOffering = (petServiceId, productBundleConfigId) => ({
  type: SET_SELECTED_BULK_PACKAGE_OFFERING,
  productBundleConfigId,
  petServiceId,
});

export const clearSelectedBulkPackageOfferings = () => ({
  type: CLEAR_SELECTED_BULK_PACKAGE_OFFERINGS,
});
