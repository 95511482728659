import axios from "axios";
import {
  setRequestHeaders,
  handleAuthorizationRejection,
  setResponseRefreshToken,
} from "../../utils/authHelper";

/**
 * Axios Create configuration for the Web Services Engagement API (/web/services/engagement).
 *
 * @summary /web/services/engagement.
 * @memberOf APIs.WebServicesEngagement
 * @function
 * @name  webServicesEngagementAPI
 * @type {axios.AxiosInstance}
 *
 * @example
 * return webServicesEngagementAPI.post(url, params)
 * return webServicesEngagementAPI.get(url, params)
 * return webServicesEngagementAPI.put(url, params)
 */
export const webServicesEngagementAPI = axios.create({
  baseURL: `${window.env.BASE_URL}${window.env.WEB_SERVICES_ENGAGEMENT_URI}`,
});

/**
 * Intercept the `x-refresh-session-token` value
 * - Note: This can be disabled by using the ENV flag below
 */
if (!window.env.DISABLE_API_AUTH_IN_DEV) {
  webServicesEngagementAPI.interceptors.request.use(
    setRequestHeaders,
    handleAuthorizationRejection,
  );
  webServicesEngagementAPI.interceptors.response.use(
    setResponseRefreshToken,
    handleAuthorizationRejection,
  );
}
