//@ts-check
/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_HOTEL_MANAGE_ROOM_DETAILS_DATE_PICKER_START_DATE =
  "SET_HOTEL_MANAGE_ROOM_DETAILS_DATE_PICKER_START_DATE";
export const SET_HOTEL_MANAGE_ROOM_DETAILS_DATE_PICKER_END_DATE =
  "SET_HOTEL_MANAGE_ROOM_DETAILS_DATE_PICKER_END_DATE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
/**
 *
 * @param {string} payload
 * @returns {{type: string, payload: string}}
 */
export const setHotelManageRoomDetailsDatePickerStartDate = payload => ({
  type: SET_HOTEL_MANAGE_ROOM_DETAILS_DATE_PICKER_START_DATE,
  payload,
});

/**
 *
 * @param {string} payload
 * @returns {{type: string, payload: string}}
 */
export const setHotelManageRoomDetailsDatePickerEndDate = payload => ({
  type: SET_HOTEL_MANAGE_ROOM_DETAILS_DATE_PICKER_END_DATE,
  payload,
});
