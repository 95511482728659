import moment from "moment";
import { connect } from "react-redux";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { getWeekNumber } from "../../core/selectors/commonSelector";
import { setWeekNumber } from "../../core/actionCreators/ui/web/commonActionCreators";
import Arrow from "../common/Arrow";
import isDateOnXday from "../../core/utils/dateUtils/isDateOnISOday";
import loadItinerariesHelper from "../_helpers/itineraries/loadItinerariesHelper";

const mapStateToProps = state => ({
  componentID: "button_prev_week_navigation",
  weekNumber: getWeekNumber(state),
  direction: "left",
  currentDate: getSelectedDate(state),
});

const mapDispatchToProps = dispatch => ({
  dispatchClickHandler: ({ selectedDate, weekNumber }) => {
    dispatch(setSelectDate(selectedDate));

    // Verify the selected day is Sunday, if so we changed weeks so we need to
    // update itineraries and set the weekNumber in the Redux state.
    if (isDateOnXday(selectedDate, 7)) {
      // Abstract out dispatching load itineraries since it is used in multiple containers.
      loadItinerariesHelper(dispatch, selectedDate, false);

      dispatch(setWeekNumber({ weekNumber }));
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  clickHandler: () =>
    propsFromDispatch.dispatchClickHandler({
      selectedDate: moment(propsFromState.currentDate).add(-1, "days"),
      weekNumber: propsFromState.weekNumber - 1,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Arrow);
