import React from "react";
import { connect } from "react-redux";
import { getFoodIds } from "web/food/foodsSelector";
import {
  BookingFoodListItem,
  CheckInOutFoodListItem,
  PetProfileFoodListItem,
} from "@/dux/foodListItem/FoodListItem";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { selectCheckInOutFoodIds } from "@/dux/_components/checkInOutFeeding/CheckInOutCartFoodSelectors";

/**
 * Takes a list of items of foods and renders an each item with an expandable right button and name
 * FoodIndex increments by 1 to pass container number to fields that require it.
 * */
export const PetFoodList = props => {
  const {
    componentId,
    items,
    petId,
    locationType,
    setFeedingFormInvalid,
    invalidFeedingForms,
    foodListItem,
  } = props;

  const FoodListItem = foodListItem ?? (() => <></>);

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack>
        {items.map((foodId, index) => (
          <FoodListItem
            key={foodId}
            foodId={foodId}
            foodIndex={index + 1}
            petId={petId}
            locationType={locationType}
            setFeedingFormInvalid={setFeedingFormInvalid}
            invalidFeedingForms={invalidFeedingForms}
            hasBorder={index + 1 < items.length}
          />
        ))}
      </LayoutStack>
    </LayoutBox>
  );
};

export const CheckInOutPetFoodList = connect((state, { petId }) => {
  return {
    componentId: "CheckInOutPetFoodList",
    isCheckInOutFlow: true,
    items: selectCheckInOutFoodIds(state, { petId }),
    foodListItem: CheckInOutFoodListItem,
  };
})(PetFoodList);

export const BookingPetFoodList = connect((state, { petId }) => {
  return {
    componentId: "BookingPetFoodList",
    isCheckInOutFlow: true,
    items: getFoodIds(state, { petId }),
    foodListItem: BookingFoodListItem,
  };
})(PetFoodList);

export const PetProfilePetFoodList = connect((state, { petId }) => {
  return {
    componentId: "PetProfilePetFoodList",
    isCheckInOutFlow: true,
    items: getFoodIds(state, { petId }),
    foodListItem: PetProfileFoodListItem,
  };
})(PetFoodList);
