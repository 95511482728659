import { color } from "web/common/styles/theme";
import { setHotelBookingFurthestStep, setHotelBookingOpenStep } from "./hotelBookingFlowActions";
import flattenArray from "@/core/utils/arrayUtils/flattenArray";

export const hotelBookingFlowSparkyBoxStyle = ({ disabled, isSelected }) => {
  return {
    cursor: !disabled && "pointer",
    borderColor: isSelected && color.kalEl,
    color: isSelected && color.kalEl,
    opacity: disabled && 0.5,
  };
};

export const commonHotelBookingStepActions = dispatch => {
  return {
    // Sets the current step.
    setStep: step => dispatch(setHotelBookingOpenStep(step)),

    // Continues to a new step.
    onContinue: ({ openStep, furthestStep }) => {
      // If we're the furthest we've gotten in the flow, navigate to the next furthest step.
      // Else, return to the furthest step we've been to.
      if (openStep === furthestStep) {
        dispatch(setHotelBookingFurthestStep(furthestStep + 1));
        dispatch(setHotelBookingOpenStep(furthestStep + 1));
      } else dispatch(setHotelBookingOpenStep(furthestStep));
    },
  };
};

/**
 * Util to check if rooms obj contains multiple pets
 * @memberOf Utils.Booking
 * @function
 * @name isMultiPetRooms
 * @param {Object} rooms
 * @returns {Boolean}
 * @example isMultiPetRooms({ room1: [1], room2:[2] }) // -> returns true
 */
export const isMultiPetRooms = (rooms = {}) => {
  const pets = flattenArray(Object.values(rooms));
  return pets?.length > 1;
};
