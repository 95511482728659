import { put, takeEvery, call, all, select } from "redux-saga/effects";
import { has, get } from "lodash/fp";
import { getPetEligibilityAfterChange } from "dux/eligibility/actions/eligibilityPetActions";
import petHealthConditionsActionCreators from "../actionCreators/petHealthConditionsActionCreators";
import petsActionCreators from "../actionCreators/petsActionCreators";
import petHealthConditionsActionTypes from "../actionTypes/petHealthConditionsActionTypes";
import {
  postPetHealthCondition,
  deletePetHealthCondition,
} from "../services//associateWebProfile/petHealthEndPoints";
import {
  getPetConditionIdByConditionId,
  getPetHealthConditions,
  getPetHealthConditionsFormData,
} from "../selectors/petHealthConditionsSelectors";
import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";
import { getSourceId } from "../selectors/persistentSelectors";

function* onCreatePetHealthCondition({ customerKey, petId, ConditionId: conditionId }) {
  try {
    yield put(petHealthConditionsActionCreators.createPetHealthConditionRequest());
    const sourceId = yield select(getSourceId);
    const response = yield call(postPetHealthCondition, {
      customerKey,
      petId,
      conditionId,
      sourceId,
    });
    const petHealthConditionAdded = response.data;
    const petHealthConditions = yield select(getPetHealthConditions, { petId });
    yield put(
      petsActionCreators.updatePetSuccess({
        pet: {
          petId: Number(petId),
          petConditions: petHealthConditions.concat(petHealthConditionAdded),
        },
      }),
    );
    yield put(petHealthConditionsActionCreators.createPetHealthConditionSuccess());
  } catch (error) {
    yield put(petHealthConditionsActionCreators.createPetHealthConditionFailure({ error }));
  }
}

function* onDeletePetHealthCondition({
  customerKey,
  petId,
  ConditionId: conditionId,
  petConditionId,
}) {
  try {
    yield put(petHealthConditionsActionCreators.deletePetHealthConditionRequest());
    const sourceId = yield select(getSourceId);
    const petConditionId = yield select(getPetConditionIdByConditionId, { conditionId, petId });
    yield call(deletePetHealthCondition, { customerKey, petId, petConditionId, sourceId });
    const petHealthConditions = yield select(getPetHealthConditions, { petId });
    yield put(
      petsActionCreators.updatePetSuccess({
        pet: {
          petId: Number(petId),
          petConditions: petHealthConditions.filter(
            petHealthCondition => petHealthCondition.conditionId !== conditionId,
          ),
        },
      }),
    );
    yield put(petHealthConditionsActionCreators.deletePetHealthConditionSuccess());
    yield put(getPetEligibilityAfterChange({ petId, customerKey }));
  } catch (error) {
    yield put(petHealthConditionsActionCreators.deletePetHealthConditionFailure({ error }));
  }
}

function* onUpdatePetHealthConditions({ customerKey, petId, formValues }) {
  try {
    yield put(petHealthConditionsActionCreators.updatePetHealthConditionsRequest());
    const sourceId = yield select(getSourceId);
    const healthConditionsList = yield select(getPetHealthConditionsFormData, { petId });
    const petHealthConditions = yield select(getPetHealthConditions, { petId });
    const normalizedPetHealthConditions = normalizeArrayByProperty(
      petHealthConditions,
      "conditionId",
    );
    const petHealthConditionsToUpdate = healthConditionsList
      .filter(({ ConditionId }) => has(ConditionId, formValues))
      .map(({ ConditionId }) => {
        const shouldAddPetHealthCondition = get(ConditionId, formValues);
        const payload = { customerKey, petId, ConditionId: Number(ConditionId), sourceId };
        if (shouldAddPetHealthCondition) {
          return call(onCreatePetHealthCondition, payload);
        }
        return call(onDeletePetHealthCondition, {
          ...payload,
          petConditionId:
            normalizedPetHealthConditions[ConditionId] &&
            normalizedPetHealthConditions[ConditionId].petConditionId,
        });
      });

    yield all(petHealthConditionsToUpdate);
    yield put(petHealthConditionsActionCreators.updatePetHealthConditionsSuccess());
    yield put(getPetEligibilityAfterChange({ petId, customerKey }));
  } catch (error) {
    yield put(petHealthConditionsActionCreators.updatePetHealthConditionsFailure({ error }));
  }
}

function* watchCreatePetCondition() {
  yield takeEvery(
    petHealthConditionsActionTypes.CREATE_PET_HEALTH_CONDITION,
    onCreatePetHealthCondition,
  );
}

function* watchDeletePetCondition() {
  yield takeEvery(
    petHealthConditionsActionTypes.DELETE_PET_HEALTH_CONDITION,
    onDeletePetHealthCondition,
  );
}

function* watchUpdatePetHealthConditions() {
  yield takeEvery(
    petHealthConditionsActionTypes.UPDATE_PET_HEALTH_CONDITIONS,
    onUpdatePetHealthConditions,
  );
}

export default function* petHealthConditionsSaga() {
  yield all([
    watchCreatePetCondition(),
    watchDeletePetCondition(),
    watchUpdatePetHealthConditions(),
  ]);
}
