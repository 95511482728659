import React from "react";
import { Layout, Text } from "@prism/psm-ui-components";
import { FrequencySelection } from "dux/frequency/modal/FrequencySelection";
import FrequencyModalSaveButtonContainer from "../../../frequency/modal/FrequencyModalSaveButtonContainer";
import FrequencyModalCancelButtonContainer from "../../../frequency/modal/FrequencyModalCancelButtonContainer";
import MedicationFrequencyDatesContainer from "../../../frequency/modal/FrequencyDatesContainer";

export const SetFrequencyModalComponent = ({ componentId, header, onClose }) => {
  return (
    <Layout.Box id={componentId}>
      <Layout.Stack space="stack-space-5">
        <Layout.Box id={`${componentId}__title`}>
          <Text size="text-size-lg" bold>
            {header}{" "}
          </Text>
        </Layout.Box>

        <Layout.Box
          id={`${componentId}__frequencySelection`}
          borderWidth
          borderColor="box-border-color-gray-100"
          padding="box-padding-4"
        >
          <Layout.Switcher space="switcher-space-8">
            <FrequencySelection />
            <MedicationFrequencyDatesContainer />
          </Layout.Switcher>
        </Layout.Box>

        <Layout.Box id={`${componentId}__saveCancelButtons`}>
          <Layout.Cluster justify="space-between">
            <FrequencyModalCancelButtonContainer onClose={onClose} />
            <FrequencyModalSaveButtonContainer onClose={onClose} />
          </Layout.Cluster>
        </Layout.Box>
      </Layout.Stack>
    </Layout.Box>
  );
};
