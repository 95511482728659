import { petsmartWebPublicAPI } from "./_axiosConfigPetMWebPubic";

/**
 * Get A specific Salon Info with Hours of operation.
 * @param {*} storeNumber Destructured Property with a values as a Number
 */
export const FetchSalonInfoWithHours = ({ storeNumber }) => {
  return petsmartWebPublicAPI.get(`stores/${storeNumber}/hours`);
};

/**
 * Get Info on all Grooming Salons.
 */
export const FetchAllSalons = () => {
  return petsmartWebPublicAPI.get(`/stores/all`);
};

/**
 * Get A specific Salon Info with a list of services.
 * @param {*} storeNumber Destructured Property with a values as a Number
 */
export const FetchSalonInfoWithServices = ({ storeNumber }) => {
  return petsmartWebPublicAPI.get(`stores/${storeNumber}`);
};
