import * as petServicesAtHotelActionTypes from "../../services/hotelServices/actions/petServicesAtHotel";

const initialState = {};

const appointmentsInCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case petServicesAtHotelActionTypes.SAVE_HOTEL_SERVICES:
      return {
        ...state,
        petServicesAtHotel: action.petAvailableServicesData,
      };
    case petServicesAtHotelActionTypes.CLEAR_HOTEL_SERVICES:
      return {
        ...state,
        [action.petId]: {
          ...state[action.petId],
          petServicesAtHotel: [],
        },
      };
    default:
      return state;
  }
};

export default appointmentsInCartReducer;
