import { vetsLookupAPI } from "./_axiosConfigVets";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Vets Lookup API - Query the Vet/Clinic data
 * @param {*} data list of request parameters to pass to end point see in APIM for more info.
 */
export const searchVet = ({ data }) => {
  return vetsLookupAPI.get(`${endpointVersionString()}/lookup/query`, {
    params: {
      ...data,
      limit: 100
    }
  });
};
