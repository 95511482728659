import React, { useState } from "react";
import { connect } from "react-redux";
import { showBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { LayoutBox } from "@/layout/box/Box";
import { Button } from "@petsmart-ui/sparky";
import {
  clearPendingFrequency,
  frequencyActionTypes,
  setPendingFrequency,
  setPendingFrequencyAddonDates,
  setPendingId,
} from "../frequency/actions/frequencyActions";
import { frequencyConstants, frequencyTypes } from "../frequency/FrequencyConstants";
import { setFrequencyDates } from "../frequency/modal/FrequencySelection";
import { selectHotelCartPetProductById } from "../servicesCartHotel/servicesCartHotelSelectors";
import { SET_FREQUENCY_TEXT } from "./hotelAddonsSetFrequencyConstants";
import StyledPopover from "@/web/common/StyledPopover";
import { getPendingId } from "../frequency/frequencySelectors";
import {
  selectDateRangeForFrequency,
  selectAddonDefaultFrequency,
} from "../frequency/dateRangeForFrequencySelectors";
import { selectAddonByPetAndAddonId } from "../hotelEngagements/hotelEngagementSelectors";
import { showCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";

export const HotelAddonsSetFrequencyComponent = ({
  componentId,
  isHidden,
  disabled,
  openFrequencyModal = () => {},
}) => {
  const [showPopover, setShowPopover] = useState(false);
  if (isHidden) return null;

  return (
    <StyledPopover
      body={<LayoutBox>{SET_FREQUENCY_TEXT.POPOVER}</LayoutBox>}
      isOpen={showPopover}
      style={{ zIndex: 10000 }}
    >
      <LayoutBox
        id={componentId}
        padding="space-0"
        onMouseEnter={() => setShowPopover(disabled)}
        onMouseLeave={() => setShowPopover(false)}
      >
        <Button
          text={SET_FREQUENCY_TEXT.BUTTON}
          variant="link"
          disabled={!!disabled}
          onClick={() => openFrequencyModal()}
        />
      </LayoutBox>
    </StyledPopover>
  );
};

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingAddonsSetFrequency = connect(
  (state, { petId, addonId }) => {
    const addon = selectHotelCartPetProductById({ petId, productId: addonId })(state);
    const defaultFrequency = selectAddonDefaultFrequency(addonId)(state);

    return {
      componentId: "HotelBookingAddonsSetFrequency",
      frequency: addon?.frequency || defaultFrequency,
      frequencyDates: addon?.customFrequencyDates,
      pendingAppointmentDates: selectDateRangeForFrequency(state),
      disabled: !!addon?.isAutoApplyAddOn,
      pendingId: getPendingId(state),
    };
  },
  (dispatch, { addonId, petId }) => {
    return {
      clearFrequency: () => dispatch(clearPendingFrequency()),
      setFrequency: ({ frequency, dates }) => {
        dispatch(
          setPendingId({
            pendingId: addonId,
            petId,
            frequencyType: frequencyTypes.ADDON,
          }),
        );
        if (frequency) dispatch(setPendingFrequency({ frequency }));
        if (dates?.length) dispatch(setPendingFrequencyAddonDates({ dates }));
      },
      showFrequencyModal: () =>
        dispatch(showBookingModal(frequencyActionTypes.SHOW_FREQUENCY_MODAL)),
    };
  },
  (stateProps, dispatchProps, { addonId }) => {
    const { frequency, frequencyDates, pendingAppointmentDates, disabled, pendingId } = stateProps;
    const { clearFrequency, setFrequency, showFrequencyModal } = dispatchProps;

    const dates =
      frequency === frequencyConstants.MANUAL
        ? frequencyDates
        : setFrequencyDates(frequency, pendingAppointmentDates);

    return {
      disabled,
      openFrequencyModal: () => {
        if (!addonId) return;

        // Initialize the pending frequency if we haven't opened the frequency modal for this addon yet,
        if (addonId !== pendingId) {
          clearFrequency();
          setFrequency({ frequency, dates });
        }

        showFrequencyModal();
      },
    };
  },
)(HotelAddonsSetFrequencyComponent);

// HOTEL CHECK IN/OUT ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInOutAddonsSetFrequency = connect(
  (state, { petId, addonId }) => {
    const addon = selectAddonByPetAndAddonId(addonId)(state, { petId });
    const defaultFrequency = selectAddonDefaultFrequency(addonId)(state);

    return {
      componentId: "HotelCheckInOutAddonsSetFrequency",
      frequency: addon?.frequency || defaultFrequency,
      frequencyDates: addon?.customFrequencyDates ?? [],
      pendingAppointmentDates: selectDateRangeForFrequency(state),
      disabled: !!addon?.isAutoApplyAddOn,
      pendingId: getPendingId(state),
    };
  },
  (dispatch, { addonId, petId }) => {
    return {
      clearFrequency: () => dispatch(clearPendingFrequency()),
      setFrequency: ({ frequency, dates }) => {
        dispatch(
          setPendingId({
            pendingId: addonId,
            petId,
            frequencyType: frequencyTypes.ADDON,
          }),
        );
        if (frequency) dispatch(setPendingFrequency({ frequency }));
        if (dates?.length) dispatch(setPendingFrequencyAddonDates({ dates }));
      },
      showFrequencyModal: () =>
        dispatch(showCheckInOutModal({ modalType: frequencyActionTypes.SHOW_FREQUENCY_MODAL })),
    };
  },
  (stateProps, dispatchProps, { addonId }) => {
    const { frequency, frequencyDates, pendingAppointmentDates, disabled, pendingId } = stateProps;
    const { clearFrequency, setFrequency, showFrequencyModal } = dispatchProps;

    const dates =
      frequency === frequencyConstants.MANUAL
        ? frequencyDates
        : setFrequencyDates(frequency, pendingAppointmentDates);

    return {
      disabled,
      openFrequencyModal: () => {
        if (!addonId) return;

        // Initialize the pending frequency if we haven't opened the frequency modal for this addon yet,
        if (addonId !== pendingId) {
          clearFrequency();
          setFrequency({ frequency, dates });
        }

        showFrequencyModal();
      },
    };
  },
)(HotelAddonsSetFrequencyComponent);
