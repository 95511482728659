import { put, takeEvery, call, all, select } from "redux-saga/effects";
import { has, flatten, noop } from "lodash/fp";
import moment from "moment";

import petVaccinationsActionCreators from "../actionCreators/petVaccinationsActionCreators";
import petsActionCreators from "../actionCreators/petsActionCreators";
import petProfileActionCreators from "../actionCreators/petProfileActionCreators";
import petVaccinationsActionTypes from "../actionTypes/petVaccinationsActionTypes";
import {
  postPetVaccination,
  deletePetVaccination,
  putPetVaccination,
} from "../services/associateWebProfile/vaccinationEndPoints";
import { getVaccinationsList } from "../selectors/enumsSelectors";
import { getPetVaccinations } from "../selectors/ui/vaccinations/vaccinationsSelectors";
import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";
import { getSourceId } from "../selectors/persistentSelectors";
import { getPetEligibilityAfterChange } from "@/dux/eligibility/actions/eligibilityPetActions";

function* onCreatePetVaccination({
  customerKey,
  petId,
  vaccinationId,
  expirationDate,
  onComplete = noop,
}) {
  try {
    const sourceId = yield select(getSourceId);
    yield put(petVaccinationsActionCreators.createPetVaccinationRequest());
    const response = yield call(postPetVaccination, {
      customerKey,
      petId,
      sourceId,
      data: { sourceId: Number(sourceId), expirationDate, vaccinationId, verifiedFlag: true },
    });
    const petVaccinationAdded = response.data;
    const petVaccinations = yield select(getPetVaccinations, { petId });
    yield put(
      petsActionCreators.updatePetSuccess({
        pet: {
          petId: Number(petId),
          vaccinations: petVaccinations.concat(petVaccinationAdded),
        },
      }),
    );
    yield put(petVaccinationsActionCreators.createPetVaccinationSuccess());
    yield put(getPetEligibilityAfterChange({ petId, customerKey }));
    onComplete(petVaccinationAdded);
  } catch (error) {
    yield put(petVaccinationsActionCreators.createPetVaccinationFailure({ error }));
  }
}

function* onDeletePetVaccination({ customerKey, petId, vaccinationId }) {
  try {
    const sourceId = yield select(getSourceId);
    yield put(petVaccinationsActionCreators.deletePetVaccinationRequest());
    yield call(deletePetVaccination, {
      customerKey,
      petId,
      vaccinationId: vaccinationId.petVaccinationId,
      sourceId,
    });
    const petVaccinations = yield select(getPetVaccinations, { customerKey, petId });
    yield put(
      petsActionCreators.updatePetSuccess({
        pet: {
          petId: Number(petId),
          vaccinations: petVaccinations.filter(
            (petVaccination) => petVaccination.vaccinationId !== vaccinationId.vaccinationId,
          ),
        },
      }),
    );
    yield put(petVaccinationsActionCreators.deletePetVaccinationSuccess());
    yield put(getPetEligibilityAfterChange({ petId, customerKey }));
    yield put(petVaccinationsActionCreators.clearCustomerAndPetKeys());
    yield put(petVaccinationsActionCreators.clearVaccinationToDelete());
    yield put(petProfileActionCreators.hidePetProfileModal());
  } catch (error) {
    yield put(petVaccinationsActionCreators.deletePetVaccinationFailure({ error }));
  }
}

function* onUpdatePetVaccinations({ customerKey, petId, formValues, onComplete = noop }) {
  try {
    yield put(petVaccinationsActionCreators.updatePetVaccinationsRequest());
    const vaccinationsList = yield select(getVaccinationsList);
    const petVaccinationsList = yield select(getPetVaccinations, { petId });
    const petVaccinationsToUpdate = vaccinationsList
      .filter(({ Name }) => has(Name, formValues))
      .map(({ Name, VaccinationId }) => {
        const petVaccination = petVaccinationsList.find(
          (vaccination) => vaccination.vaccinationId === VaccinationId,
        );
        if (
          petVaccination &&
          formValues[Name] &&
          formValues[Name] !== moment(petVaccination.expirationDate).format("YYYY-MM-DD")
        ) {
          const payload = {
            expirationDate: formValues[Name],
            sourceId: petVaccination.updateSourceId,
            vaccinationId: petVaccination.vaccinationId,
            verifiedFlag: true,
          };

          return call(putPetVaccination, {
            customerKey,
            petId,
            vaccinationId: petVaccination.petVaccinationId,
            payload,
          });
        }
      });

    const response = yield all(petVaccinationsToUpdate);
    const filterResponse = response.filter(Boolean).map((vaccination) => vaccination.data);

    const vaccinations = normalizeArrayByProperty(flatten(filterResponse), "vaccinationId");

    yield put(
      petsActionCreators.updatePetSuccess({
        pet: {
          petId: Number(petId),
          vaccinations: petVaccinationsList.map((petVaccination) => {
            if (vaccinations[petVaccination.vaccinationId]) {
              return { ...petVaccination, ...vaccinations[petVaccination.vaccinationId] };
            }
            return petVaccination;
          }),
        },
      }),
    );
    yield put(petVaccinationsActionCreators.updatePetVaccinationsSuccess());
    onComplete();
    yield put(getPetEligibilityAfterChange({ petId, customerKey }));
  } catch (error) {
    yield put(petVaccinationsActionCreators.updatePetVaccinationsFailure({ error }));
  }
}

function* watchCreatePetVaccination() {
  yield takeEvery(petVaccinationsActionTypes.CREATE_PET_VACCINATION, onCreatePetVaccination);
}

function* watchDeletePetVaccination() {
  yield takeEvery(petVaccinationsActionTypes.DELETE_PET_VACCINATION, onDeletePetVaccination);
}

function* watchUpdatePetVaccinations() {
  yield takeEvery(petVaccinationsActionTypes.UPDATE_PET_VACCINATIONS, onUpdatePetVaccinations);
}

export default function* petVaccinationsSaga() {
  yield all([
    watchCreatePetVaccination(),
    watchDeletePetVaccination(),
    watchUpdatePetVaccinations(),
  ]);
}