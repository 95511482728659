/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const enableDisableWorkflowFeatureFlagActionTypes = {
  ENABLE_DISABLE_WORKFLOW_FEATURE_FLAGS: "ENABLE_DISABLE_WORKFLOW_FEATURE_FLAGS",
  ENABLE_DISABLE_WORKFLOW_FEATURE_FLAG: "ENABLE_DISABLE_WORKFLOW_FEATURE_FLAG",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const enableDisableWorkflowFeatureFlags = ({ featureFlagId, checked }) => ({
  type: enableDisableWorkflowFeatureFlagActionTypes.ENABLE_DISABLE_WORKFLOW_FEATURE_FLAGS,
  featureFlagId,
  checked,
});

export const enableDisableWorkflowFeatureFlag = ({ featureFlagId, checked }) => ({
  type: enableDisableWorkflowFeatureFlagActionTypes.ENABLE_DISABLE_WORKFLOW_FEATURE_FLAG,
  featureFlagId,
  checked,
});
