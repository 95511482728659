// @ts-check
import { createSelector } from "reselect";

/**
 * @typedef {Object} state
 * @property {object} hotelScheduleSuspensionsPagination
 */

/**
 * Selector to get the pagination for scheduled suspensions from the redux state
 * @param {state} state
 * @returns {object}
 */
export const selectHotelScheduleSuspensionsPagination = state =>
  state.hotelScheduleSuspensionsPagination;

/**
 * Selector to get the pagination count for scheduled suspensions from the redux state
 * @type {function}
 * @returns {number}
 */
export const selectHotelScheduleSuspensionsPaginationCount = createSelector(
  [selectHotelScheduleSuspensionsPagination],
  pagination => {
    return pagination?.count;
  },
);

/**
 * Selector to get the pagination page for scheduled suspensions from the redux state
 * @type {function}
 * @returns {number}
 */
export const selectHotelScheduleSuspensionsPaginationPage = createSelector(
  [selectHotelScheduleSuspensionsPagination],
  pagination => {
    return pagination?.page;
  },
);

/**
 * Selector to get the pagination total for scheduled suspensions from the redux state
 * @type {function}
 * @returns {number}
 */
export const selectHotelScheduleSuspensionsPaginationTotal = createSelector(
  [selectHotelScheduleSuspensionsPagination],
  pagination => {
    return pagination?.total;
  },
);

/**
 * Selector to get the pagination size for scheduled suspensions from the redux state
 * @type {function}
 * @returns {number}
 */
export const selectHotelScheduleSuspensionsPaginationSize = createSelector(
  [selectHotelScheduleSuspensionsPagination],
  pagination => {
    return pagination?.size;
  },
);
