import { put, call, all, select, takeLatest } from "redux-saga/effects";

// Actions
import {
  GET_SELECTED_REPORT,
  getSelectedReportRequest,
  getSelectedReportSuccess,
  getSelectedReportFailure,
} from "./reportsActions";

// Endpoints
import { getReportsByReportTypeEndpoint } from "@/core/services/associateWebBff/reportsEndpoints";

// Selectors
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
/**
 * Generator function to fetch selected report
 * @memberOf Sagas.Reports
 * @generator
 * @name onGetSelectedReport
 * @returns {Generator<*, void, *>}
 */
function* onGetSelectedReport({ reportType, selectedDate }) {
  try {
    const storeNumber = yield select(getStoreNumber);

    yield put(getSelectedReportRequest());

    const response = yield call(getReportsByReportTypeEndpoint, {
      reportType,
      storeNumber,
      selectedDate,
    });

    yield put(getSelectedReportSuccess(response?.data?.results));
  } catch (error) {
    yield put(getSelectedReportFailure(error));
  }
}

function* watchOnGetSelectedReport() {
  yield takeLatest(GET_SELECTED_REPORT, onGetSelectedReport);
}

export default function* reportsSaga() {
  yield all([watchOnGetSelectedReport()]);
}
