/**
 * Responsible for taking an Action Type and returning a RegExpExecArray
 * if that action type has any of the following sub-strings within the action type
 * string.
 * - REQUEST
 * - SUCCESS
 * - FAILURE
 *
 * @export getLoadingMatches
 * @param {string} actionType
 * @returns { RegExpExecArray }
 */
export default function getLoadingMatches(actionType) {
  return /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(actionType);
}
