import {
  applyFoodMedChangesToEngagement,
  sanitizeFoodForItineraryCall,
  sanitizeMedForItineraryCall,
} from "@/dux/hotelItinerary/hotelItineraryUtils";
import { updateCustomFrequencyDates } from "./updateCustomFrequencyDates";

/**
 * Util to update foods & meds for a pets engagements based on frequency and dates
 * @memberOf Utils.Engagement
 * @function
 * @name updatePetEngagementsFoodMeds
 * @param {Object} args
 * @param {Object[]} args.foods - foods from original engagements
 * @param {Object[]} args.meds - meds from original engagements
 * @param {string} args.timeZone
 * @returns {engagements => Object[]} function to update given engagements with foods and meds
 * @example updatePetEngagementsFoodMeds({ foods, meds, timeZone })(petEngagements)
 */
export const updatePetEngagementsFoodMeds = ({
  foods = [],
  meds = [],
  timeZone,
  foodExternalData = {},
  medExternalData = [],
}) => petEngagements => {
  const dates = petEngagements?.map(({ startDatetime }) => startDatetime);

  // Recalculate customFrequencyDates based on petEngagements dates
  const foodsWithCorrectDates = foods?.map(updateCustomFrequencyDates(dates));
  const medsWithCorrectDates = meds?.map(updateCustomFrequencyDates(dates));

  // Move the foods & meds to the correct engagements based on the updated dates
  return petEngagements?.map(engagement => {
    const { metadata, startDatetime, endDatetime } = engagement;
    return {
      ...engagement,
      metadata: {
        ...metadata,
        feedings: applyFoodMedChangesToEngagement({
          engagementFoodOrMeds: metadata?.feedings,
          startDatetime,
          endDatetime,
          foodsOrMeds: foodsWithCorrectDates,
          sanitizeData: sanitizeFoodForItineraryCall,
          timeZone,
          foodMedExternalData: foodExternalData,
        }),
        medications: applyFoodMedChangesToEngagement({
          engagementFoodOrMeds: metadata?.medications,
          startDatetime,
          endDatetime,
          foodsOrMeds: medsWithCorrectDates,
          sanitizeData: sanitizeMedForItineraryCall,
          timeZone,
          foodMedExternalData: medExternalData,
        }),
      },
    };
  });
};
