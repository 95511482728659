import React, { Component } from "react";
import styled from "styled-components";
import StyledPopover from "../../../common/StyledPopover";
import Select from "../../../common/Select";
import PetHealthConditionsPopup from "./PetHealthConditionsPopup";

const StyledSelect = styled.div`
  ${props => !props.isBooking && "width: 50%;"};
`;

class PetHealthConditionsComponent extends Component {
  constructor(props) {
    super(props);
    this.openFlyout = this.openFlyout.bind(this);
    this.closeFlyout = this.closeFlyout.bind(this);
    this.renderHealthConditionsFlyout = this.renderHealthConditionsFlyout.bind(this);
    this.healthConditionsRef = React.createRef();
    this.state = { isFlyoutOpen: false };
  }
  openFlyout() {
    this.setState({ isFlyoutOpen: true });
  }
  closeFlyout() {
    this.setState({ isFlyoutOpen: false });
  }
  renderHealthConditionsFlyout() {
    const {
      petHealthConditionsFormData,
      petHealthConditionsInitialValues,
      submitForm
    } = this.props;
    return (
      <PetHealthConditionsPopup
        petHealthConditionsFormData={petHealthConditionsFormData}
        petHealthConditionsInitialValues={petHealthConditionsInitialValues}
        closeFlyout={this.closeFlyout}
        submitForm={submitForm}
      />
    );
  }
  render() {
    const { isFlyoutOpen } = this.state;
    const { commaDelimitedPetHealthConditions, isBooking } = this.props;

    return (
      <StyledPopover
        isOpen={isFlyoutOpen}
        preferPlace="below"
        onOuterAction={this.closeFlyout}
        tipSize={7}
        target={this.healthConditionsRef}
        body={this.renderHealthConditionsFlyout()}
      >
        <StyledSelect isBooking>
          <Select
            inputValue={commaDelimitedPetHealthConditions}
            onDone={this.openFlyout}
            isStatic
            isForm
          />
        </StyledSelect>
      </StyledPopover>
    );
  }
}

export default PetHealthConditionsComponent;
