import modalInitState from "./_modalInitState";
import associateActionTypes from "../../actionTypes/associateActionTypes";
import getUpdatedModalState from "./helpers/getUpdatedModalState";

const initialState = modalInitState();

export default function associatesModalsReducer(state = initialState, action) {
  return getUpdatedModalState(
    state,
    initialState,
    associateActionTypes.SHOW_ASSOCIATES_MODAL,
    associateActionTypes.HIDE_ASSOCIATES_MODAL,
    action
  );
}
