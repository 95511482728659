import React, { Component } from "react";
import styled from "styled-components";
import { APriceListHeading } from "../../../../web/common/styles/abstractStyledComponents";
import { color, font } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import Button from "../../../../web/common/commonButton";
import CommonPriceList from "./commonPriceList";

// ABSTRACT CLASSES FOR THIS COMPONENT (DO NOT USE DIRECTLY, EXTEND)
const Container = styled.div`
  background-color: white;
`;

const ACopy = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.regular};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 25px;
  letter-spacing: normal;
  color: ${color.profX};
`;

// WRAPPER CLASSES
const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 685px;
  padding: 30px 30px;
  box-sizing: border-box;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AllPriceListsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 435px;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 30px;
  border-top: 1px solid ${color.ororoMunroe};
  min-height: 79px;
  box-sizing: border-box;
`;

// HEADING CLASSES
const SearchHeading = APriceListHeading.extend`
  margin-bottom: 16px;
`;
// OTHER
const CloseCTA = ACopy.extend`
  cursor: pointer;
  color: #1778f2;
  font-size: 16px;
`;

const Search = styled.div`
  margin-bottom: 30px;
`;

const SearchField = styled.input`
  width: 100%;
  height: 38px;
  font-size: ${fontSizes.regular};
`;

/**
 * List of two sections that contain prices and a selection checkbox. Designed to go in a flyOut or Modal
 * @param {Array} topList - List of Items for the top Price list
 * @param {Array} bottomList - List of Items for the bottom Price list
 * @param {String} idName - Each price listed item has an ID key value pair, this is the name of the Key
 * @param {String} mainHeading - Main heading for the list
 * @param {String} topHeading - Top Price list Heading
 * @param {String} bottomHeading - Bottom Price list Heading
 * @param {Function} onClose - Event handler to close a Window (done via action in container)
 * @param {Function} onDone - Event handler to close a Window and pass selected options (as ids) (done via action in container)
 */
export default class CommonSplitPriceList extends Component {
  constructor(props) {
    super(props);

    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.onDone = this.onSelectionChange.bind(this);

    this.state = {
      selectedOptions: [],
      searchFilter: "",
    };
  }

  componentDidMount() {
    const { initialSelectedOptions = [] } = this.props;
    this.setState({
      selectedOptions: initialSelectedOptions,
    });
  }

  onSelectionChange(addOnId) {
    const { selectedOptions } = this.state;
    const isSelected = selectedOptions.includes(addOnId);
    if (isSelected) {
      this.setState(prevState => ({
        selectedOptions: prevState.selectedOptions.filter(selectedId => selectedId !== addOnId),
      }));
    } else {
      this.setState(prevState => ({
        selectedOptions: [...prevState.selectedOptions, addOnId],
      }));
    }
  }

  render() {
    // Set Props
    const {
      idProperty,
      nameProperty,
      topList,
      bottomList,
      topHeading,
      bottomHeading,
      onClose,
      onDone,
      mainHeading,
      disabledOptions,
      isLoading,
    } = this.props;

    // Set set values to Filter
    const { selectedOptions, searchFilter } = this.state;
    return (
      <Container>
        <SectionWrapper>
          <SearchWrapper>
            <SearchHeading>{mainHeading}</SearchHeading>
            <Search>
              <SearchField
                value={searchFilter}
                onChange={e => this.setState({ searchFilter: e.target.value })}
              />
            </Search>
          </SearchWrapper>

          <AllPriceListsWrapper>
            <CommonPriceList
              priceList={topList}
              idProperty={idProperty}
              nameProperty={nameProperty}
              selectedOptions={selectedOptions}
              heading={topHeading}
              searchFilter={searchFilter}
              onSelectionChange={this.onSelectionChange}
              disabledOptions={disabledOptions}
            />

            <CommonPriceList
              priceList={bottomList}
              idProperty={idProperty}
              nameProperty={nameProperty}
              selectedOptions={selectedOptions}
              heading={bottomHeading}
              searchFilter={searchFilter}
              onSelectionChange={this.onSelectionChange}
              disabledOptions={disabledOptions}
              noBorder
            />
          </AllPriceListsWrapper>
        </SectionWrapper>

        <FooterWrapper>
          <CloseCTA onClick={onClose}>Close</CloseCTA>
          <Button
            label="Done"
            disabled={isLoading}
            onClick={() => {
              if (onDone) {
                onDone(this.state.selectedOptions);
              }
              if (onClose) {
                onClose();
              }
            }}
          />
        </FooterWrapper>
      </Container>
    );
  }
}
