import React, { Component } from "react";
import styled from "styled-components";

// TO DO - share code with loading wrapper
const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

const Loader = styled.div`
  width: ${({ fullScreenWidth }) => (fullScreenWidth ? "100vw" : "100%")};
  height: ${({ fullScreenHeight }) => (fullScreenHeight ? "100vh" : "100%")};
  position: ${({ fullScreenHeight, fullScreenWidth }) =>
    fullScreenHeight && fullScreenWidth ? "fixed" : "absolute"};
  z-index: 100;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? `${backgroundColor}` : "rgba(0, 0, 0, 0.1)"};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

class DelayedLoading extends Component {
  mounted = false;
  state = { isTimedOut: false };
  componentDidMount() {
    const opts = Object.assign({ delay: 200 }, this.props.delay);
    this.mounted = true;
    this.timeout = setTimeout(() => {
      if (this.mounted) {
        this.setState(() => ({ isTimedOut: true }));
      }
    }, opts.delay);
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  render() {
    return (
      <Container>
        {this.state.isTimedOut && <Loader {...this.props}>{this.props.render()}</Loader>}
      </Container>
    );
  }
}

export default DelayedLoading;
