import agentsActionTypes from "../../../actionTypes/agentsActionTypes";

const initialState = {
  isAddAgentFormShown: false,
  customerKey: "",
  agentId: ""
};

export default function agentsReducer(state = initialState, action) {
  switch (action.type) {
    case agentsActionTypes.SHOW_ADD_AGENT_FORM:
      return {
        ...state,
        isAddAgentFormShown: true
      };
    case agentsActionTypes.HIDE_ADD_AGENT_FORM:
      return {
        ...state,
        isAddAgentFormShown: false
      };
    case agentsActionTypes.SET_CUSTOMER_AND_AGENT_KEYS:
      return {
        ...state,
        customerKey: action.customerKey,
        agentId: action.agentId
      };
    case agentsActionTypes.CLEAR_CUSTOMER_AND_AGENT_KEYS:
      return {
        ...state,
        customerKey: "",
        agentId: null
      };
    default:
      return state;
  }
}
