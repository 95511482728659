/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const enableDisableFeatureActionTypes = {
  ENABLE_DISABLE_FEATURE_FLAGS: "ENABLE_DISABLE_FEATURE_FLAGS",
  ENABLE_DISABLE_FEATURE_FLAG: "ENABLE_DISABLE_FEATURE_FLAG",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const enableDisableFeatures = ({ featureFlagId, checked }) => ({
  type: enableDisableFeatureActionTypes.ENABLE_DISABLE_FEATURE_FLAGS,
  featureFlagId,
  checked,
});

export const enableDisableFeature = ({ featureFlagId, checked }) => ({
  type: enableDisableFeatureActionTypes.ENABLE_DISABLE_FEATURE_FLAG,
  featureFlagId,
  checked,
});
