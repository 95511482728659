import { createSelector } from "reselect";
import getItinerariesState from "./_itinerariesState";

const getItinerary = (state, itineraryId) => {
  const itineraries = getItinerariesState(state);
  const itinerary = itineraries[itineraryId];
  return itinerary;
};

/* NOTE Property will take two params, state and itineraryId
   both will be automatically passed to getItinerary property

    example of use: getItineraryByID(state, itineraryId);
*/
const getItineraryByID = createSelector([getItinerary], itinerary => {
  return itinerary;
});

export default getItineraryByID;
