import { connect } from "react-redux";
import moment from "moment";
import { getSelectedPackageOffering } from "dux/bgm/bulkPackageOfferings/bulkPackageOfferingsSelectors";
import { formatLabel } from "dux/_components/bgm/availableBundlesByPet/availableBundlesList/_Utils";
import { FTCO } from "web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";
import TextComponent from "dux/_components/genericLayoutComponents/TextComponent";
import { FREE_SERVICES_EXPIRE } from "dux/bgm/availableBundlesByPet/availableBundlesConstents";

const mapStateToProps = state => {
  const { freeUnitsExpirationDate, offerType } = getSelectedPackageOffering(state);
  const formattedFreeUnitsExpirationDate = moment(freeUnitsExpirationDate).format("MM/DD/YYYY");
  return {
    isHidden: offerType === FTCO,
    text: formatLabel(FREE_SERVICES_EXPIRE, formattedFreeUnitsExpirationDate),
    styleType: "div",
    styleObj: {
      marginBottom: "0.5em",
    },
  };
};

export default connect(mapStateToProps)(TextComponent);
