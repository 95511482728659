//@ts-check
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Layout, Text } from "@prism/psm-ui-components";
import { color } from "web/common/styles/theme";
import {
  selectHotelScheduleSuspensionsPaginationCount,
  selectHotelScheduleSuspensionsPaginationPage,
  selectHotelScheduleSuspensionsPaginationSize,
  selectHotelScheduleSuspensionsPaginationTotal,
} from "dux/scheduleSuspensionPagination/scheduleSuspensionPaginationSelectors";
import {
  selectOverbookingExceptionsPaginationCount,
  selectOverbookingExceptionsPaginationPage,
  selectOverbookingExceptionsPaginationSize,
  selectOverbookingExceptionsPaginationTotal,
} from "../overbookingExceptionsPagination/overbookingExceptionsPaginationSelectors";
import { getHotelServiceSuspension } from "dux/scheduleSuspensionPage/scheduleSuspensionActions";
import { getOverBookingExceptionSuspensionDetails } from "@/dux/overbookingExceptionTableDetails/overBookingExceptionTableDetailsActions";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import moment from "moment";
import {
  SuspensionDateRangeEnd,
  SuspensionDateRangeStart,
} from "core/constants/suspensionConstants";
import { EXCEPTION_TABLE_SIZE } from "../overbookingExceptionTableDetails/overBookingExceptionTableDetailsConstants";

/**
 * @callback setPageNumberCallback
 * @param {number} page
 */

/**
 * Pagination View Component
 * @param {Object} paginationProps - Destructed Props
 * @param {string} paginationProps.componentId - Id used for Automated testing
 * @param {boolean} paginationProps.isHidden - flag to hide or show component
 * @param {number} paginationProps.count - number of items currently displayed
 * @param {number} paginationProps.page - Current Page
 * @param {number} paginationProps.total - total number items available
 * @param {number} paginationProps.size - total size of items that can currently be displayed
 * @param {setPageNumberCallback} paginationProps.setPageNumber - handler function  to dispatch updated api call to get data for new page.
 * @returns {JSX.Element | null}
 */
export const Pagination = ({ componentId, isHidden, count, page, total, size, setPageNumber }) => {
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPage, setSelectedPage] = useState(page);

  // set number of pages based on total and size
  useEffect(() => {
    // if total and size are 0 we get NaN, so we return at min a 0
    const calcPages = total / size ? total / size : 0;

    setTotalPages(Math.ceil(calcPages));
  }, [total, size]);

  // set initial selected page from props
  useEffect(() => {
    setSelectedPage(page);
  }, [page]);

  if (isHidden) {
    return null;
  }

  const startPageSize = page * size + 1;
  const endPageSize = page * size + count;

  return (
    <Layout.Box id={componentId}>
      {/*Showing and number selection*/}
      <Layout.Cluster space="cluster-space-8">
        {/*Showing x of y*/}
        <Layout.Box>
          <Text bold id={`${componentId}__totalCount`}>
            Showing {startPageSize} - {endPageSize} of {total}
          </Text>
        </Layout.Box>

        {/*Number Selection*/}
        <Layout.Box>
          <Layout.Cluster space="cluster-space-4">
            {[...Array(totalPages)].map((item, index) => {
              const adjustedIndex = index + 1;
              const adjustedSelectedPage = selectedPage + 1;

              return (
                <Layout.Box
                  key={index}
                  onClick={() => setPageNumber({ page: index })}
                  style={{
                    backgroundColor:
                      adjustedSelectedPage === adjustedIndex ? `${color.kalEl}` : "initial",
                    borderRadius: "20px",
                    minWidth: "25px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <Text
                    id={`${componentId}__page--${adjustedIndex}`}
                    style={{
                      color:
                        adjustedSelectedPage === adjustedIndex ? color.ororoMunroe : color.tChalla,
                    }}
                  >
                    {adjustedIndex}
                  </Text>
                </Layout.Box>
              );
            })}
          </Layout.Cluster>
        </Layout.Box>
      </Layout.Cluster>
    </Layout.Box>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const ScheduledSuspensionPagination = connect(
  state => {
    const selectedDate = getSelectedDate(state);

    return {
      componentId: "ScheduledSuspensionPagination",
      isHidden: false,
      count: selectHotelScheduleSuspensionsPaginationCount(state),
      page: selectHotelScheduleSuspensionsPaginationPage(state),
      total: selectHotelScheduleSuspensionsPaginationTotal(state),
      size: selectHotelScheduleSuspensionsPaginationSize(state),

      // for merge props
      selectedDate,
    };
  },

  dispatch => {
    return {
      /**
       * property that will dispatch an action to set page number
       * @param {object} destructedParams
       * @param {number } destructedParams.page - Page number we want to set
       * @param {string } destructedParams.selectedDate - Currently selected date
       */
      dispatchSetPageNumber: ({ page, selectedDate }) => {
        // Set a date range for 6mo. of history and fire off acton to request all services suspensions 3 months prior, and 3 months out from the currently elected date.
        const startOfDateRange = moment(selectedDate)
          .subtract(SuspensionDateRangeStart, "months")
          .format("YYYY-MM-DD");
        const endOfDateRange = moment(selectedDate)
          .add(SuspensionDateRangeEnd, "months")
          .format("YYYY-MM-DD");
        dispatch(
          getHotelServiceSuspension({
            fromDate: startOfDateRange,
            toDate: endOfDateRange,
            page,
            size: 12,
            includeHistory: true,
          }),
        );
      },
    };
  },

  (mapProps, dispatchProps) => {
    const { componentId, isHidden, count, page, total, size, selectedDate } = mapProps;
    const { dispatchSetPageNumber } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,
      count,
      page,
      total,
      size,

      /**
       * property that will merge props from view and the pops from state to dispatch an action to set page number
       * @param {object} destructedParams
       * @param {number } destructedParams.page - Page number we want to set
       */
      setPageNumber: ({ page }) => dispatchSetPageNumber({ page, selectedDate }),
    };
  },
)(Pagination);

/**
 * Redux Connect function for the Overbooking exceptions pagination component
 * @summary located on /hotel-management/overbooking/exception-dates
 * @memberOf Views.Overbooking
 * @function
 * @name OverbookingExceptionsTablePagination
 * @returns {JSX.Element|null}
 * @example <OverbookingExceptionsTablePagination />
 */
export const OverbookingExceptionsTablePagination = connect(
  state => {
    return {
      componentId: "OverbookingExceptionsTablePagination",
      isHidden: false,
      count: selectOverbookingExceptionsPaginationCount(state),
      page: selectOverbookingExceptionsPaginationPage(state),
      total: selectOverbookingExceptionsPaginationTotal(state),
      size: selectOverbookingExceptionsPaginationSize(state),
    };
  },

  dispatch => {
    return {
      /**
       * property that will dispatch an action to set page number
       * @param {object} destructedParams
       * @param {number } destructedParams.page - Page number we want to set
       */
      setPageNumber: ({ page }) => {
        dispatch(
          getOverBookingExceptionSuspensionDetails({
            page,
            size: EXCEPTION_TABLE_SIZE,
          }),
        );
      },
    };
  },
)(Pagination);
