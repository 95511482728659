import { connect } from "react-redux";
import { getPetParentScreenEligibility } from "dux/eligibility/actions/eligibilityByScreenActions";
import { clearEligibilityResults } from "dux/eligibility/actions/eligibilityLoadClearActions";
import salonActionTypes from "@/core/actionTypes/salonActionTypes";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getHotelEligibility } from "dux/hotelEligibility/hotelEligibilityActions";
import { eligibilityTypes } from "dux/eligibility/constants/eligibilityConstants";
import PetParentProfileComponent from "../../common/Profiles/PetParentProfileComponent";
import { loadCustomerClearErrors } from "../../../core/actionCreators/customersActionCreators";
import { createErrorSelector, createLoadingSelector } from "../../../core/selectors/utils";
import customersActionTypes from "../../../core/actionTypes/customersActionTypes";
import servicesSelectionActionTypes from "../../../core/actionTypes/servicesSelectionActionTypes";
import petsActionTypes from "../../../core/actionTypes/petsActionTypes";
import { loadPetParentAPIs } from "../../../core/actionCreators/petParentProfileActionCreator";
import { getPetParentProfileModalType } from "../../../core/selectors/modals/petParentProfilModalSelectors";
import { setCurrentCustomerKey } from "../../../core/actionCreators/customer/customerActionCreator";
import { getCurrentCustomerKey } from "../../../core/selectors/persistent/customer/customerSelectors";
import petVaccinationsActionTypes from "@/core/actionTypes/petVaccinationsActionTypes";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const mapStateToProps = (state, ownProps) => {
  const {
    router: {
      params: { customerKey },
    },
  } = ownProps;
  return {
    componentID: "petParentProfile-petParentProfileComponent",
    currentCustomerKey: getCurrentCustomerKey(state),
    systemBookingFlow: getSystemBookingFlow(state),
    loadCustomerError: createErrorSelector([customersActionTypes.LOAD_CUSTOMER])(state),
    isLoading: createLoadingSelector([
      customersActionTypes.LOAD_CUSTOMER,
      customersActionTypes.UPDATE_CUSTOMER,
      petsActionTypes.UPDATE_PET,
      servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_PET,
      salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH,
      petVaccinationsActionTypes.CREATE_PET_VACCINATION,
      petVaccinationsActionTypes.UPDATE_PET_VACCINATIONS,
      petVaccinationsActionTypes.DELETE_PET_VACCINATION,
    ])(state),
    modalType: getPetParentProfileModalType(state),
    customerKey,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    router: {
      params: { customerKey },
    },
  } = ownProps;
  return {
    loadAPIs: () => dispatch(loadPetParentAPIs({ customerKey })),
    clearErrors: () => dispatch(loadCustomerClearErrors()),
    clearEligibilityResults: () => dispatch(clearEligibilityResults()),
    callEligibility: () => {
      const { systemBookingFlow } = ownProps;
      if (systemBookingFlow === systemName.HOTEL) {
        dispatch(getHotelEligibility({ eligibilityType: eligibilityTypes.PROFILE }));
      } else {
        dispatch(getPetParentScreenEligibility({ customerKey }));
      }
    },
    setCurrentCustomerKey: customerKey =>
      dispatch(setCurrentCustomerKey({ currentCustomerKey: customerKey })),
  };
};

export default withRouteProps(
  connect(mapStateToProps, mapDispatchToProps)(PetParentProfileComponent),
);
