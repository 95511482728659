import React from "react";
import { connect } from "react-redux";
import { has } from "lodash/fp";
import StarIconComponent from "./starIconComponent";
import { getCustomer } from "../../../core/selectors/entitiesSelector";
import { updateCustomerIsPreferredContact } from "../../../core/actionCreators/customersActionCreators";
import { updatePetServiceItem } from "../../../core/actionCreators/petServiceItemsActionCreators";
import { showConfirmationModal } from "../../../core/actionCreators/confirmationModalActionCreators";

const mapStateToProps = (state, ownProps) => ({
  customer: getCustomer(state, ownProps)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  buttonClickHandler: (value, customer) => {
    if (customer) {
      const formattedPhone = value.replace(/\D+/g, "");
      const customerPhone = customer.phones.find(phone => phone.phoneNumber === formattedPhone);

      if (customerPhone && !customerPhone.isPreferredContact) {
        dispatch(
          updateCustomerIsPreferredContact({
            customerKey: ownProps.customerKey,
            data: customerPhone
          })
        );
      }
    }

    if (value && has("lockToAssociate", value)) {
      const petServiceItemObj = {
        customerKey: value.customer,
        itineraryId: value.itinerary,
        engagementId: value.engagement,
        petServiceItemId: value.petServiceItemId,
        dispatchToCart: true
      };

      if (!value.lockToAssociate) {
        dispatch(
          updatePetServiceItem({
            ...petServiceItemObj,
            data: {
              associateId: value.associate,
              lockToAssociate: true
            }
          })
        );
      } else {
        dispatch(
          showConfirmationModal({
            header: "Preferred associate change",
            content: (
              <div>
                The associate selected for this appointment is the preferred associate.
                <br />
                Are you sure you want to change the associate?
              </div>
            ),
            confirmText: "Continue",
            confirm: () =>
              dispatch(
                updatePetServiceItem({
                  ...petServiceItemObj,
                  data: {
                    associateId: value.associate,
                    lockToAssociate: false
                  }
                })
              )
          })
        );
      }
    }
  }
});

const StarIconContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StarIconComponent);

export default StarIconContainer;
