import React from "react";
import { Rect } from "react-konva";

import { color } from "../../common/styles/theme";
import ganttConstants from "../../../core/constants/ganttConstants";

const pattern = document.createElement("canvas");
pattern.width = 5;
pattern.height = 7;
const pctx = pattern.getContext("2d");

pctx.fillStyle = color.grey;
pctx.fillRect(0, 0, 5, 1.2);

class GanttAbsence extends React.Component {
  render() {
    const { absence, width, x } = this.props;
    return (
      <Rect
        x={x}
        y={0}
        height={ganttConstants.ROW_HEIGHT}
        width={width}
        fillPatternImage={pattern}
        fillPatternRotation={45}
        fillPatternRepeat="repeat"
      />
    );
  }
}

export default GanttAbsence;
