export default {
  LOAD_CUSTOMERS: "LOAD_CUSTOMERS",
  LOAD_CUSTOMERS_REQUEST: "LOAD_CUSTOMERS_REQUEST",
  LOAD_CUSTOMERS_SUCCESS: "LOAD_CUSTOMERS_SUCCESS",
  LOAD_CUSTOMERS_FAILURE: "LOAD_CUSTOMERS_FAILURE",
  SHOW_SEARCH_RESULTS: "SHOW_SEARCH_RESULTS",
  SHOW_QUICK_VIEW: "SHOW_QUICK_VIEW",
  SET_SEARCH_FIELD: "SET_SEARCH_FIELD",
  CLEAR_SEARCH_FIELD_VALUE: "CLEAR_SEARCH_FIELD_VALUE",
  SET_SEARCH_FIELD_TYPE: "SET_SEARCH_FIELD_TYPE",
  SET_SEARCH_ERROR_MESSAGE: "SET_SEARCH_ERROR_MESSAGE",
  CLEAR_SEARCH_ERROR_MESSAGE: "CLEAR_SEARCH_ERROR_MESSAGE",
  OPEN_SEARCH_ERROR_FLYOUT: "OPEN_SEARCH_ERROR_FLYOUT",
  CLOSE_SEARCH_ERROR_FLYOUT: "CLOSE_SEARCH_ERROR_FLYOUT",
  SET_SEARCH_FIELD_ACTIVE: "SET_SEARCH_FIELD_ACTIVE",
  CLEAR_SEARCH_FIELD_ACTIVE: "CLEAR_SEARCH_FIELD_ACTIVE",
  SET_SEARCH_APPLIED: "SET_SEARCH_APPLIED",
  CLEAR_SEARCH_APPLIED: "CLEAR_SEARCH_APPLIED",
  SET_PREVIOUS_SEARCH_COMPONENT_NAME: "SET_PREVIOUS_SEARCH_COMPONENT_NAME",
  SET_SEARCH_COMPONENT_NAME: "SET_SEARCH_COMPONENT_NAME"
};
