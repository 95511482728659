import { COLORS } from './colorsList'
import { G_1, G_10, G_2, G_3, G_4, G_5, G_6, G_7, G_8, G_9, S_1, S_2, S_3, S_4, S_5, S_6, S_7, S_8, S_9, S_10 } from "./modularScale";

export const GLOBAL_TOKENS = {
  SCALE: {
    'scale-0': '0',
    'scale-G1': `${G_1}rem`,
    'scale-G2': `${G_2}rem`,
    'scale-G3': `${G_3}rem`,
    'scale-G4': `${G_4}rem`,
    'scale-G5': `${G_5}rem`,
    'scale-G6': `${G_6}rem`,
    'scale-G7': `${G_7}rem`,
    'scale-G8': `${G_8}rem`,
    'scale-G9': `${G_9}rem`,
    'scale-G10': `${G_10}rem`,

    'scale-S1': `${S_1}rem`,
    'scale-S2': `${S_2}rem`,
    'scale-S3': `${S_3}rem`,
    'scale-S4': `${S_4}rem`,
    'scale-S5': `${S_5}rem`,
    'scale-S6': `${S_6}rem`,
    'scale-S7': `${S_7}rem`,
    'scale-S8': `${S_8}rem`,
    'scale-S9': `${S_9}rem`,
    'scale-S10': `${S_10}rem`,
  },
  COLOR: {
    inherit: 'inherit',
    white: COLORS.white,
    black: COLORS.black,
    lightGrey: COLORS.lightGrey,
    mediumGrey: COLORS.mediumGrey,
    red: COLORS.red,
    azure: COLORS.azure,
    crayolaBlue: COLORS.crayolaBlue
  }

}
