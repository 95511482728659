import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Retrieve a list of available training classes from the web services booking service
 * @param {Number} petServiceId - The pet service id to fetch availability for
 * @param {String} fromDate - The date to start fetching available training classes
 * @param {String} toDate - The date to stop fetching available training classes
 * @param {Number} storeNumber - The store number to fetch availability for
 * @returns {*} AxiosPromise
 */
export function fetchTrainingAvailability({ petServiceId, fromDate, toDate, storeNumber, petId }) {
  return bookingAPI.get(
    `${endpointVersionString()}/training/petservices/${petServiceId}/availability`,
    { params: { fromDate, toDate, storeNumber, petId } },
  );
}
