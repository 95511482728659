import React from "react";
import CommonModal from "../../../common/modals/commonModal";
import RebookError from "../../../common/modals/content/rebookError/rebookErrorContainer";
import { modalTypes } from "../../../../core/constants/modalConstants";
import DeleteAgent from "../../petParent/petParentAgentForm/deleteAgent/DeleteAgentModalContainer";
import DeleteEmail from "../../petParent/petParentForm/deleteEmail/DeleteEmailModalContainer";
import { SalonAppointmentHistoryTable } from "dux/appointmentHistory/appointmentHistoryComponent";

const PetParentProfileModals = props => (
  <CommonModal
    onClose={() => {
      const isRebookErrorModal = props.modalType === modalTypes.REBOOK_ERROR;

      if (isRebookErrorModal) {
        props.clearRebookErrorMessages();
      }

      props.onClose();
    }}
  >
    {selectModalContent(props)}
  </CommonModal>
);

const selectModalContent = props => {
  switch (props.modalType) {
    case modalTypes.REBOOK_ERROR:
      return <RebookError />;
    case modalTypes.SALON_APPOINTMENT_HISTORY:
      return <SalonAppointmentHistoryTable />;
    case modalTypes.DELETE_AGENT:
      return <DeleteAgent />;
    case modalTypes.DELETE_EMAIL:
      return <DeleteEmail />;
    default:
      return null;
  }
};

export default PetParentProfileModals;
