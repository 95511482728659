import { put, takeEvery, call, all } from "redux-saga/effects";
import { getHotelBeddingTypes } from "dux/hotelBeddingTypes/hotelBeddingTypesEndpoints";
import {
  GET_HOTEL_BEDDING_TYPES,
  getHotelBeddingTypesRequest,
  getHotelBeddingTypesSuccess,
  getHotelBeddingTypesFailure,
} from "./hotelBeddingTypesActions";

function* onGetHotelBeddingTypes() {
  try {
    yield put(getHotelBeddingTypesRequest());
    const response = yield call(getHotelBeddingTypes);
    const payload = response?.data?.result;

    yield put(getHotelBeddingTypesSuccess(payload));
  } catch (error) {
    yield put(getHotelBeddingTypesFailure());
  }
}

function* watchGetHotelBeddingTypes() {
  yield takeEvery(GET_HOTEL_BEDDING_TYPES, onGetHotelBeddingTypes);
}

export default function* hotelBeddingTypesSaga() {
  yield all([watchGetHotelBeddingTypes()]);
}
