/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const refundPackageConfirmationModalActionTypes = {
  DISPLAY_REFUND_PACKAGE_CONFIRMATION_MODAL: "DISPLAY_REFUND_PACKAGE_CONFIRMATION_MODAL",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const displayRefundPackageConfirmationModal = ({ isHidden, petId, bundleNumber }) => ({
  type: refundPackageConfirmationModalActionTypes.DISPLAY_REFUND_PACKAGE_CONFIRMATION_MODAL,
  isHidden,
  petId,
  bundleNumber,
});
