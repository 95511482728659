import { toggleFeatureFlagWindowActionTypes } from "./toggleFeatureFlagWindowActions";
// import toggleFeatureFlagWindowConstants from "./toggleFeatureFlagWindowConstants";

const initialState = {
  isHidden: true,
  // position: toggleFeatureFlagWindowConstants.LEFT,
};

const toggleFeatureFlagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case toggleFeatureFlagWindowActionTypes.CLOSE_FEATURE_FLAG_WINDOW:
      return {
        ...state,
        isHidden: !action.isHidden,
      };
    case toggleFeatureFlagWindowActionTypes.OPEN_FEATURE_FLAG_WINDOW:
      return {
        ...state,
        isHidden: !action.isHidden,
      };
    default:
      return state;
  }
};

export default toggleFeatureFlagsReducer;
