import getIsBGMWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getFTCOWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import { BGM, FTCO } from "@/web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";
import { get, isEmpty } from "lodash/fp";
import { createSelector } from "reselect";
import {
  getBundleAddOnIdByPetServiceId,
  getBundleEnhacedServiceIdByPetServiceId,
  getBundleOfferTypeByPetServiceId,
  getIsActiveBundleAvailable,
} from "@/dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import { getAddOns, getServicesForPet } from "./entitiesSelector";
import { getAppointmentByPet, getCartDetails } from "./cartSelectors";

/**
 * Helper to calculate the price of addons including the enhanced service addon
 * @function
 * @name calculateAddonPrice
 * @param {Object} obj
 * @param {{ price: Number }[]} obj.addons - array of addon objects
 * @param {{ price?: Number, currentPrice?: Number }} props.enhancedService
 * @returns {Number} sum of prices
 * @example calculateAddonPrice({ addons, enhancedService })
 */
export const calculateAddonPrice = ({ addons = [], enhancedService = {} } = {}) => {
  const addonsPrice = addons?.reduce(
    (priceSum, addon) => (addon?.price ? priceSum + addon?.price : priceSum),
    0,
  );
  const enhancedServicePrice = enhancedService?.price ?? enhancedService?.currentPrice;

  return enhancedServicePrice ? addonsPrice + enhancedServicePrice : addonsPrice;
};

/**
 * Helper to calculate the pricing when redeeming a bundle, checks if addon is part of redemption or not
 * @function
 * @name calculateBundleAddonPricing
 * @param {Object} obj
 * @param {Number?} obj.addonsPrice - defaults to 0 if undefined
 * @param {{ addOnId: Number, price: Number }[]} obj.selectedAddons - array of addon objects
 * @param {Number} props.selectedBundleAddOnId - addOnId of addon included in bundle
 * @returns {Number} addon price without bundle addon
 * @example calculateBundleAddonPricing({ addonsPrice, selectedAddons, selectedBundleAddOnId })
 */
export const calculateBundleAddonPricing = ({
  addonsPrice = 0,
  selectedAddons,
  selectedBundleAddOnId,
} = {}) => {
  const selectedBundleAddOn = selectedAddons?.find(
    ({ addOnId } = {}) => addOnId === selectedBundleAddOnId,
  );
  const selectedBundleAddOnPrice = selectedBundleAddOn?.price ?? 0;

  // If an add-on is included as part of a bundle (BGM), then we want to subtract the total
  // price of the bundled add-on from the total price of all add-ons.
  return addonsPrice - selectedBundleAddOnPrice;
};

/**
 * Selector to check if BGM bundle pricing should be used
 * @memberOf Selectors.ui
 * @function
 * @name selectShowBGMPricing
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.petServiceId
 * @returns {Boolean} true if BGM bundle pricing should be used
 * @example selectShowBGMPricing(state, { petId, petServiceId })
 */
export const selectShowBGMPricing = createSelector(
  [getIsBGMWorkflowFeatureFlagHidden, getIsActiveBundleAvailable, getBundleOfferTypeByPetServiceId],
  (isBGMHidden, isBundleAvailable, offerType) => {
    return !isBGMHidden && offerType !== FTCO && isBundleAvailable;
  },
);

/**
 * Selector to check if FTCO bundle pricing should be used
 * @memberOf Selectors.ui
 * @function
 * @name selectShowFTCOPricing
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.petServiceId
 * @returns {Boolean} true if FTCO bundle pricing should be used
 * @example selectShowFTCOPricing(state, { petId, petServiceId })
 */
export const selectShowFTCOPricing = createSelector(
  [getFTCOWorkflowFeatureFlagHidden, getIsActiveBundleAvailable, getBundleOfferTypeByPetServiceId],
  (isFTCOHidden, isBundleAvailable, offerType) => {
    return !isFTCOHidden && offerType !== BGM && isBundleAvailable;
  },
);

/**
 * Selector to get salon booking total price for a given pet
 * @memberOf Selectors.ui
 * @function
 * @name selectSalonTotalPriceByPet
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.petServiceId
 * @returns {Number} price
 * @example selectSalonTotalPriceByPet(state, { petId, petServiceId })
 */
export const selectSalonTotalPriceByPet = createSelector(
  [
    getAppointmentByPet,
    getServicesForPet,
    getAddOns,
    getBundleAddOnIdByPetServiceId,
    getBundleEnhacedServiceIdByPetServiceId,
    selectShowBGMPricing,
    selectShowFTCOPricing,
  ],
  (
    appointment = {},
    services = {},
    addons = {},
    selectedBundleAddOnId,
    selectedBundleEnhancedServiceId,
    showDiscountedPriceBGM,
    showDiscountedPriceFTCO,
  ) => {
    const timeSlot = appointment?.timeSlot;
    if (timeSlot) {
      return timeSlot.price;
    }

    const selectedEnhancedService = appointment?.enhancedServices;
    const selectedService = services[appointment?.petService];
    const basePrice = selectedService?.price ?? 0;
    const selectedAddons = appointment?.addons?.map(addon => addons[addon?.addOnId]);
    const hasEnhancedService =
      selectedEnhancedService?.addOnId &&
      selectedEnhancedService?.addOnId !== selectedBundleEnhancedServiceId;
    const addonsPrice = calculateAddonPrice({
      addons: !isEmpty(addons) ? selectedAddons : [],
      enhancedService: hasEnhancedService ? selectedEnhancedService : {},
    });

    // If bundle applied and the appointment has not been scheduled
    // in SF, then do not include the base price (only addons will be
    // included in total price).
    if (showDiscountedPriceBGM || showDiscountedPriceFTCO) {
      return calculateBundleAddonPricing({ addonsPrice, selectedAddons, selectedBundleAddOnId });
    }

    return basePrice + addonsPrice;
  },
);

/**
 * Wrapper selector around {@link Selectors.ui.selectSalonTotalPriceByPet} to get a function that will return a price for a given pet
 * @memberOf Selectors.ui
 * @function
 * @name selectTotalPriceByPetFn
 * @param {Object} state - redux state
 * @returns {(props: Object) => Number} function to get total price per pet
 * @example selectTotalPriceByPetFn(state)
 */
const selectTotalPriceByPetFn = state => props => selectSalonTotalPriceByPet(state, props);

/**
 * Selector to get total price for all pets while booking a salon itinerary
 * @memberOf Selectors.ui
 * @function
 * @name selectSalonTotalPrice
 * @param {Object} state - redux state
 * @returns {Boolean} total price for a salon itinerary
 * @example selectSalonTotalPrice(state)
 */
export const selectSalonTotalPrice = createSelector(
  [getCartDetails, selectTotalPriceByPetFn],
  (appointments = {}, getTotalByPetFn) => {
    return Object.keys(appointments).reduce((total, petId) => {
      const petServiceId = appointments[petId]?.petService;
      return total + getTotalByPetFn({ petId, petServiceId });
    }, 0);
  },
);
