import React from "react";
import { connect } from "react-redux";
import { Text, Layout } from "@prism/psm-ui-components";
import TrainingAppointmentDetailsContainer from "../trainingAppointmentDetails/TrainingAppointmentDetailsContainer";
import LaunchVirtualClassButton from "@/dux/manageTrainingClassPage/LaunchVirtualClassButton";

function TrainingClassDetails({ title }) {
  return (
    <Layout.Stack space="stack-space-5">
      <Text bold="bold" align="center" size="text-size-2xl" letterSpacing="ext-tracking-normal">
        {title}
      </Text>
      <Layout.Box>
        <TrainingAppointmentDetailsContainer />
      </Layout.Box>
      <Layout.Center>
        <LaunchVirtualClassButton />
      </Layout.Center>
    </Layout.Stack>
  );
}

export default connect(() => ({
  title: "Virtual Pet Training - Private Class",
}))(TrainingClassDetails);
