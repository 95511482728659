import * as React from 'react';
import * as CSS from 'csstype';
import { psx } from '../../utils/clsx';
import { TEXT_TOKENS } from './_tokens';

import './styles.css';

export interface Props<C extends React.ElementType> {
  /** A CSS font-weight value  */
  bold?: boolean | CSS.Property.FontWeight;
  /** A CSS color value  */
  color?: keyof typeof TEXT_TOKENS['COLOR'];
  /** A CSS font-family value  */
  fontFamily?: keyof typeof TEXT_TOKENS['FAMILY'];
  /** A CSS font-size value  */
  size?: keyof typeof TEXT_TOKENS['SIZE'];
  /** A CSS letter-spacing value  */
  letterSpacing?: keyof typeof TEXT_TOKENS['SPACING'];
  /** A CSS text-align value  */
  align?: CSS.Property.TextAlign;
  /** Completely removes the component from the DOM. Useful for feature flags. */
  isHidden?: boolean;
  /** An override of the default HTML tag. Can also be another React component! */
  as?: C;
}

export type TextProps<C extends React.ElementType> = Props<C> & Omit<React.ComponentPropsWithRef<C>, keyof Props<C>>;

const Text = <C extends React.ElementType = 'span'>({
  bold = false,
  color = 'text-color-inherit',
  size = 'text-size-base',
  fontFamily = 'text-family-lato',
  letterSpacing = 'text-tracking-normal',
  align = 'left',
  isHidden = false,
  style = {},
  className,
  children,
  as,
  id,
}: TextProps<C>) => {
  if (isHidden) {
    return null;
  }

  // overwrite the as prop when we want to align the text
  // to any position other than the default "left" value
  const Component = align !== 'left' ? 'div' : as || 'span';
  const classNames = psx('psm', 'psm-text', className);
  return (
    <Component
      id={id}
      className={classNames}
      data-font-color={color}
      data-font-size={size}
      data-font-family={fontFamily}
      data-letter-spacing={letterSpacing}
      style={{
        ...(bold && { fontWeight: typeof bold === 'string' ? bold : 'bold' }),
        ...(align && { textAlign: align }),
        ...style,
      }}
    >
      {children}
    </Component>
  );
};

export default Text;
