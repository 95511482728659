import { connect } from "react-redux";
import SmallPillButton from "../../../_components/buttons/SmallPillButton";
import { getHotelCheckedOutFilterBy } from "./hotelCheckedOutPetServiceSelector";
import {
  filterByAllCheckedOut,
  filterByOverNightCheckedOut,
} from "./hotelCheckedOutFilterButtonActions";

const mapStateToProps = state => {
  const filterBy = getHotelCheckedOutFilterBy(state);

  return {
    isHidden: false,
    componentID: "SmallPillButton_HotelCheckedOutOverNightFilterButton",
    label: "Overnight",
    selected: filterBy.overnight,
    disabled: false,
    filterBtnStatus: filterBy.overnight,

    isAllOthersSelected: filterBy.dayCamp && filterBy.dayCare,
  };
};

const mapDispatchToProps = dispatch => ({
  handleFilterByOverNightCheckedOut: ({ filterBtnStatus, isAllOthersSelected }) => {
    if (filterBtnStatus) {
      // toggle off
      dispatch(filterByOverNightCheckedOut({ filterBtnStatus: !filterBtnStatus }));

      // toggle off all button as well
      dispatch(filterByAllCheckedOut({ filterBtnStatus: false }));
    } else {
      dispatch(filterByOverNightCheckedOut({ filterBtnStatus: !filterBtnStatus }));

      if (isAllOthersSelected) {
        dispatch(filterByAllCheckedOut({ filterBtnStatus: true }));
      }
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleClick: () =>
    propsFromDispatch.handleFilterByOverNightCheckedOut({
      filterBtnStatus: propsFromState.filterBtnStatus,
      isAllOthersSelected: propsFromState.isAllOthersSelected,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SmallPillButton);
