import React from "react";
import { Layout, Radio } from "@prism/psm-ui-components";

export const EnhancedServicesListItem = ({ itemName="enhancedServices", item, id, onClick, onChange, currentCheckedId, isDisabled }) => (
  <Layout.Cluster space="cluster-space-4" align="top" justify="left" style={{paddingRight: '20px', fontSize: "18px"}} childOverrides={{flexWrap: "nowrap"}}>
  <Radio disabled={isDisabled} id={id}  name={itemName} value={id} onClick={onClick} onChange={onChange} checked={currentCheckedId === id}/>
    <label style={{flexGrow: '1'}}>
      <div>{item.addOnName}</div>
      <div style={{marginTop: '2px', fontSize: '0.7em', textAlign: 'justify'}}>
        {item.description}
      </div>
    </label>
    <span className="enhancedServices__item--price">${item.price}</span>
  </Layout.Cluster>
);
