import { some } from "lodash/fp";
import {
  getCurrentItinerary,
  getCurrentPetServiceItem,
  isFromAnotherStore,
  getPage
} from "../../../../core/selectors/checkInOutSelector";
import {
  getPetServiceItemsByItinerary,
  getPetServiceItem
} from "../../../../core/selectors/entitiesSelector";
import { APPOINTMENT_STATUS } from "../../../../core/constants/index";

/**
 * Responsible for abstracting out and returning a set of properties that are used over multiple containers.
 *
 * @export getStoreDictionary
 * @param {*} state
 * @returns dictionary/object literal of properties from a set select of selectors.
 */
export default function getStoreDictionary(state) {
  const itineraryId = getCurrentItinerary(state);
  const currentPetServiceItemId = getCurrentPetServiceItem(state);
  const petServiceItems = getPetServiceItemsByItinerary(state, { itineraryId });
  const currentPetServiceItem =
    currentPetServiceItemId &&
    getPetServiceItem(state, { petServiceItemId: currentPetServiceItemId });

  return {
    petServiceItems,
    currentPetServiceItem,
    isConfirmForAllDisabled: some(
      item => item.status === APPOINTMENT_STATUS.CONFIRMED,
      petServiceItems
    ),
    isFromOtherStore: isFromAnotherStore(state, { petServiceItemId: currentPetServiceItemId }),
    isConfirmed: currentPetServiceItem.status === APPOINTMENT_STATUS.CONFIRMED,
    page: getPage(state),
    itineraryId,
    petId: currentPetServiceItem.petId,
    customerKey: currentPetServiceItem.customer
  };
}
