import moment from "moment";
import { routePaths } from "../../../core/constants/routePaths";
import { history } from "../browser/browserHistory";

export default function handleRedirectToPastOrFutureAppointment({
  customerId,
  itemDate,
  itineraryId,
}) {
  const isPastDate = moment(itemDate).isBefore(moment());
  if (isPastDate) {
    history.push(`${routePaths.PAST_APPOINTMENT}/${customerId}/${itineraryId}`);
  } else {
    history.push(`${routePaths.FUTURE_APPOINTMENT}/${customerId}/${itineraryId}`);
  }
}
