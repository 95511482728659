import { createSelector } from "reselect";
import { APPOINTMENT_STATUS } from "@/core/constants";
import moment from "moment";
import momentTz from "moment-timezone";
import isEmpty from "lodash/isEmpty";
import { itineraryHistoryListContants } from "../_constants/itineraryHistoryListConstants";
import { selectItineraryHistoryListActiveFilterValues } from "../itineraryHistoryListFilterButtons/itineraryHistoryListFilterButtonsSelectors";
import { selectCurrentStoreTimeZone } from "@/core/selectors/persistentSelectors";

const { PAGE_SIZE } = itineraryHistoryListContants;

// Helpers
export const checkIsReservationDone = res =>
  res.status === APPOINTMENT_STATUS.CANCELED || res.status === APPOINTMENT_STATUS.CHECKED_OUT;

const checkIsPreCheckIn = res =>
  res.status === APPOINTMENT_STATUS.PENDING ||
  res.status === APPOINTMENT_STATUS.BOOKED ||
  res.status === APPOINTMENT_STATUS.CONFIRMED;

const checkIsReservationInProgress = res =>
  res.status === APPOINTMENT_STATUS.CHECKED_IN ||
  res.status === APPOINTMENT_STATUS.SERVICE_COMPLETED;

export const checkIsCurrentReservation = timeZone => res => {
  const today = momentTz.tz(moment(), timeZone).format("YYYY-MM-DD");

  const hasInProgressStatus = checkIsReservationInProgress(res);

  const isPastCheckIn = moment(res.startDateTime, "YYYY-MM-DD").isBefore(today, "day");
  const isInPastAndHasntBeenCheckedIn = isPastCheckIn && checkIsPreCheckIn(res);

  const isToday = moment(res.startDateTime, "YYYY-MM-DD").isSame(today, "day");
  const isCheckInTodayAndNotDone = isToday && !checkIsReservationDone(res);

  return hasInProgressStatus || isInPastAndHasntBeenCheckedIn || isCheckInTodayAndNotDone;
};

// Selectors
export const selectItineraryHistory = state => state?.itineraryHistory;

export const selectItineraryHistoryList = createSelector(
  [selectItineraryHistory],
  itineraryHistory => {
    return itineraryHistory?.list;
  },
);

/**
 * Selector to get itinerary history list with all dates in current sore timezone
 * @memberOf Selectors.itineraryHistory
 * @function
 * @name selectItineraryHistoryListInStoreTime
 * @param {Object} state - redux state
 * @returns {Object[]} array of appointments
 * @example selectItineraryHistoryListInStoreTime(state)
 */
export const selectItineraryHistoryListInStoreTime = createSelector(
  [selectItineraryHistoryList, selectCurrentStoreTimeZone],
  (list, tz) => {
    return list?.map(({ startDateTime, endDateTime, ...res } = {}) => ({
      ...res,
      startDateTime: momentTz.tz(startDateTime, tz),
      endDateTime: momentTz.tz(endDateTime, tz),
    }));
  },
);

/**
 * Selector to get filtered itinerary history list
 * @memberOf Selectors.itineraryHistory
 * @function
 * @name selectFilteredItineraryHistoryList
 * @param {Object} state - redux state
 * @returns {Object[]} array of appointments
 * @example selectFilteredItineraryHistoryList(state)
 */
export const selectFilteredItineraryHistoryList = createSelector(
  [selectItineraryHistoryListInStoreTime, selectItineraryHistoryListActiveFilterValues],
  (list, activeFilters) =>
    isEmpty(list) || isEmpty(activeFilters)
      ? list
      : list.filter(res => activeFilters.includes(res.productFamilyId)),
);

export const selectItineraryHistoryListPageCount = createSelector(
  [selectFilteredItineraryHistoryList],
  list => Math.ceil(list.length / PAGE_SIZE),
);

/**
 * Selector to get list of reservations from itinerary history
 * where start day is today or status is checked in
 * @memberOf Selectors.itineraryHistory
 * @function
 * @name selectCurrentReservations
 * @param {Object} state - redux state
 * @returns {Object[]} array of appointments
 * @example selectCurrentReservations(state)
 */
export const selectCurrentReservations = createSelector(
  [selectFilteredItineraryHistoryList, selectCurrentStoreTimeZone],
  (itineraryHistoryList = [], storeTimeZone) => {
    const currentReservations = itineraryHistoryList.filter(
      checkIsCurrentReservation(storeTimeZone),
    );

    // Sort by check in date DESC
    const sortedCurrentReservations = currentReservations.sort((a, b) =>
      moment(b.startDateTime).diff(moment(a.startDateTime)),
    );

    return sortedCurrentReservations;
  },
);

/**
 * Selector to get list of reservations from itinerary history
 * where start day is in past and status is not checked in
 * @memberOf Selectors.itineraryHistory
 * @function
 * @name selectFutureReservations
 * @param {Object} state - redux state
 * @returns {Object[]} array of appointments
 * @example selectFutureReservations(state)
 */
export const selectFutureReservations = createSelector(
  [selectFilteredItineraryHistoryList, selectCurrentStoreTimeZone],
  (itineraryHistoryList = [], storeTimeZone) => {
    const today = momentTz.tz(moment(), storeTimeZone).format("YYYY-MM-DD");

    const futureReservations = itineraryHistoryList.filter(reservation => {
      const isInFuture = moment(reservation.startDateTime, "YYYY-MM-DD").isAfter(today, "day");
      const isReservationDone = checkIsReservationDone(reservation);
      const isReservationInProgress = checkIsReservationInProgress(reservation);

      return isInFuture && !isReservationDone && !isReservationInProgress;
    });

    // Sort by check in date ASC
    const sortedFutureReservations = futureReservations.sort((a, b) =>
      moment(a.startDateTime).diff(moment(b.startDateTime)),
    );

    return sortedFutureReservations;
  },
);

/**
 * Selector to get list of reservations from itinerary history
 * where end day is in past or status is cancelled or checked out and not checked in
 * @memberOf Selectors.itineraryHistory
 * @function
 * @name selectPastReservations
 * @param {Object} state - redux state
 * @returns {Object[]} array of appointments
 * @example selectPastReservations(state)
 */
export const selectPastReservations = createSelector(
  [selectFilteredItineraryHistoryList, selectCurrentStoreTimeZone],
  (itineraryHistoryList = []) => {
    const pastReservations = itineraryHistoryList.filter(checkIsReservationDone);

    // Sort by check out date DESC
    const sortedPastReservations = pastReservations.sort((a, b) =>
      moment(b.endDateTime).diff(moment(a.endDateTime)),
    );

    return sortedPastReservations;
  },
);

// Allow pageSize to be set/overridden for testing, when used in the app it should NOT be provided
export const selectReservationsForCurrentPage = ({ currentPage = 1, pageSize = PAGE_SIZE }) =>
  createSelector(
    [selectCurrentReservations, selectFutureReservations, selectPastReservations],
    (currentReservations, futureReservations, pastReservations) => {
      let cardsNeeded = pageSize;
      const start = pageSize * (currentPage - 1); // Expects page index to start at 1
      const pageReservations = {
        currentReservations: [],
        futureReservations: [],
        pastReservations: [],
      };

      // Add current reservations to page
      if (start < currentReservations.length) {
        pageReservations.currentReservations = currentReservations.slice(
          start,
          start + cardsNeeded,
        );
        cardsNeeded -= pageReservations.currentReservations.length;
      }

      // Add future reservations to page
      if (start < currentReservations.length + futureReservations.length && cardsNeeded > 0) {
        let futureStart = start - currentReservations.length;
        if (pageSize - cardsNeeded > 0) futureStart = 0;

        pageReservations.futureReservations = futureReservations.slice(
          futureStart,
          futureStart + cardsNeeded,
        );
        cardsNeeded -= pageReservations.futureReservations.length;
      }

      // Add past reservations to page
      if (cardsNeeded > 0) {
        let pastStart = start - currentReservations.length - futureReservations.length;
        if (pageSize - cardsNeeded > 0) pastStart = 0;

        pageReservations.pastReservations = pastReservations.slice(
          pastStart,
          pastStart + cardsNeeded,
        );
      }

      return pageReservations;
    },
  );

/**
 *  Selector to get nearest future reservation for a given pet
 *  @memberOf Selectors.itineraryHistory
 *  @function
 *  @name selectClosestFutureResForPet
 *  @param {string} petId
 *  @example selectClosestFutureResForPet(petId)(state)
 */
export const selectClosestFutureResForPet = petId =>
  createSelector([selectFutureReservations], futureReservations =>
    futureReservations?.find(res => res?.petId === petId),
  );
