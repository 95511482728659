import { connect } from "react-redux";
import { color } from "../../../../web/common/styles/theme";
import { clearSelectedBulkPackageOfferings } from "../bulkPackageOfferingsActions";
import ClickableText from "../../../../web/common/ClickableText";

const mapStateToProps = () => {
  return {
    isVisible: true,
    label: "Cancel",
    styleObj: { color: color.errorRed },
  };
};

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(clearSelectedBulkPackageOfferings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClickableText);
