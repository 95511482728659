import priceAdjustmentActionTypes from "../../core/actionTypes/priceAdjustmentActionTypes";

const initialState = [];

const priceAdjustmentReasonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case priceAdjustmentActionTypes.LOAD_PRICE_ADJUSTMENT_REASONS_SUCCESS:
      return action.priceAdjustmentReasons;

    default:
      return state;
  }
};

export default priceAdjustmentReasonsReducer;
