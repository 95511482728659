import React from "react";
import { Button, TextPassage } from "@petsmart-ui/sparky";
import { LayoutCluster } from "@/layout/culster/Cluster";
import CloseButton from "../CloseButton";

export default function RemoveButtonWithLabel(props) {
  const { label, buttonDisabled, isHidden, componentId, onClick } = props;

  return !isHidden ? (
    <Button
      id={componentId}
      variant="bare"
      size="lg"
      onClick={(...args) => {
        if (!buttonDisabled) onClick(...args);
      }}
      text={
        <LayoutCluster disabled={buttonDisabled}>
          <CloseButton />
          <TextPassage size="lg">{label || "Remove"}</TextPassage>
        </LayoutCluster>
      }
      disabled={buttonDisabled}
    />
  ) : null;
}
