/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const setFoodTimeOfDayActionTypes = {
  SET_FOOD_TIME_OF_DAY: "SET_FOOD_TIME_OF_DAY",
  FOOD_TIME_OF_DAY_TOUCHED: "FOOD_TIME_OF_DAY_TOUCHED",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setFoodTimeOfDay = ({ foodId, timeOfDay, petId }) => ({
  type: setFoodTimeOfDayActionTypes.SET_FOOD_TIME_OF_DAY,
  foodId,
  petId,
  timeOfDay,
});

export const setTimeOfDayTouched = ({ foodId, petId, timeOfDayTouched }) => ({
  type: setFoodTimeOfDayActionTypes.FOOD_TIME_OF_DAY_TOUCHED,
  foodId,
  petId,
  timeOfDayTouched,
});
