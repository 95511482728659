import { connect } from "react-redux";
import Section from "../../../../common/Section";
import getCustomerHasPrimaryAddress from "@/core/selectors/customer/address/getCustomerHasPrimaryAddress";

const mapStateToProps = (state, { children, customerKey }) => ({
  children,
  topLine: true,
  title: "Primary Address",
  isVisible: getCustomerHasPrimaryAddress(state, { customerKey }),
});

// const mapDispatchToProps = dispatch => ({
//   keyName: ({ }) => dispatch(actionName({ })),
// });

// const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
//   ...propsFromState,
//   ...propsFromDispatch,
//   ...ownProps,

//   keyName: ({ }) => propsFromDispatch.functionName({
//     keyName:	propsFromState.value,
//   }),
//   keyName: ({ }) => propsFromDispatch.functionName({
//     keyName:	propsFromState.value,
//   }),
// })

export default connect(
  mapStateToProps,
  // mapDispatchToProps,
  // mergeProps,
)(Section);
