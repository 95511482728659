import React from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { DatepickerRange } from "@petsmart-ui/sparky";

// Actions
import {
  setOverbookingExceptionEnd,
  setOverbookingExceptionStart,
} from "@/dux/overBookingException/overBookingExceptionActions";

// Selectors
import {
  selectOverBookingExceptionEnd,
  selectOverBookingExceptionStart,
} from "@/dux/overBookingException/overBookingExceptionSelectors";

// Utils
import { formatCalendarDateMoment } from "core/utils/dateUtils/formatDateTime";
import moment from "moment";

/**
 * Helper function to validate the overbooking date range
 * @param {moment|null} startDate
 * @param {moment|null} endDate
 * @param {boolean?} isRequired
 * @returns {string}
 */
export const validateDateRange = (startDate, endDate, isRequired) => {
  const hasValues =
    startDate !== null && startDate !== undefined && endDate !== null && endDate !== undefined;
  if (isRequired && !hasValues) return "Start and end date is required";

  const rangeDiff = moment(endDate).diff(startDate, "days");
  if (hasValues && rangeDiff > 30) return "Exception date range cannot exceed 30 days";

  return "";
};

/**
 *
 * @param {*} props
 * @returns
 */
export const ExceptionStartEndDates = props => {
  const { componentId, isOutsideRange, startDate, endDate, selectDates, error, disabled } = props;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <DatepickerRange
        isOutsideRange={isOutsideRange}
        onDatesChange={selectDates}
        startLabel="Start Date"
        endLabel="End Date"
        startDate={startDate}
        endDate={endDate}
        fieldNote={error}
        isError={!!error}
        disabled={disabled}
        minimumNights={0}
        startDatePlaceholderText=""
        endDatePlaceholderText=""
      />
    </LayoutBox>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const OverBookingExceptionDateRange = connect(
  (state, { isRequired }) => {
    const selectedStartDate = selectOverBookingExceptionStart(state);
    const selectedEndDate = selectOverBookingExceptionEnd(state);

    const minDate = moment().subtract(1, "days");
    const maxDate = moment().add(18, "months");
    const isOutsideRange = day => day.isSameOrBefore(minDate, "day") || day.isAfter(maxDate, "day");

    return {
      componentId: "OverBookingExceptionDateRange",
      isOutsideRange,
      selectedStartDate,
      selectedEndDate,
      error: validateDateRange(selectedStartDate, selectedEndDate, isRequired),
    };
  },
  dispatch => {
    return {
      selectDates: ({ formattedStartDate, formattedEndDate }) => {
        dispatch(setOverbookingExceptionStart(formattedStartDate));
        dispatch(setOverbookingExceptionEnd(formattedEndDate));
      },
    };
  },
  (stateProps, dispatchProps, { disabled }) => {
    const { selectedStartDate, selectedEndDate, ...propsFromState } = stateProps;
    const { selectDates } = dispatchProps;

    return {
      ...propsFromState,
      disabled,
      startDate:
        selectedStartDate && moment(selectedStartDate).isValid() ? moment(selectedStartDate) : null,
      endDate:
        selectedEndDate && moment(selectedEndDate).isValid() ? moment(selectedEndDate) : null,
      selectDates: (startDate, endDate) => {
        const startIsUpdated = moment(startDate).isMoment
          ? !startDate.isSame(selectedStartDate)
          : startDate !== selectedStartDate;
        const startIsBeforeEnd = moment(startDate).isSameOrBefore(endDate);

        selectDates({
          formattedStartDate:
            !startIsUpdated && !startIsBeforeEnd ? undefined : formatCalendarDateMoment(startDate),
          formattedEndDate:
            startIsUpdated && !startIsBeforeEnd ? undefined : formatCalendarDateMoment(endDate),
        });
      },
    };
  },
)(ExceptionStartEndDates);
