import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Text } from "@prism/psm-ui-components";
import { ScheduledSuspensionHeader } from "dux/scheduleSuspensionHeader/scheduleSuspensionHeaderContainer";
import { HotelScheduleSuspensionTable } from "dux/scheduleSuspensionTable/ScheduleSuspensionTable";
import {
  GET_HOTEL_SERVICE_SUSPENSION,
  getHotelServiceSuspension,
  PATCH_HOTEL_SERVICE_SUSPENSION,
  POST_HOTEL_SERVICE_SUSPENSION,
} from "dux/scheduleSuspensionPage/scheduleSuspensionActions";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { HotelScheduleSuspensionHistoryModal } from "dux/scheduleSuspensionHistoryModal/scheduleSuspensionHistoryModal";
import { HotelScheduleSuspensionCancelModal } from "dux/scheduleSuspensionCancelModal/scheduleSuspensionCancelModal";
import { HotelScheduleSuspensionFilterButtons } from "dux/scheduleSuspensionFilterButtons/ScheduleSuspensionFilterButtons";
import moment from "moment";
import { ScheduledSuspensionPagination } from "dux/pagination/Pagination";
import { createLoadingSelector } from "core/selectors/utils";
import LoadingWrapper from "web/common/LoadingWrapper";
import {
  SuspensionDateRangeEnd,
  SuspensionDateRangeStart,
} from "core/constants/suspensionConstants";
import { getServiceManagementToggleReasons } from "../servicesManagementToggle/servicesManagementToggleActions";
import { suspensionTypes } from "../servicesManagementToggle/serviceManagmentToggleConstants";

export const ScheduleSuspensionPage = props => {
  const { componentId, isHidden, onPageLoad, title, isLoading } = props;

  useEffect(() => {
    onPageLoad();
  }, []);

  if (isHidden) {
    return null;
  }

  const paddingValue = "2.5rem";
  const edgePadding = {
    paddingLeft: paddingValue,
    paddingRight: paddingValue,
  };

  return (
    <LoadingWrapper isLoading={isLoading} displayContainer="block">
      <Layout.Box id={componentId}>
        <Layout.Stack space="stack-space-12">
          {/* --- HEADER ---*/}
          <Layout.Box id={`${componentId}__header`}>
            <ScheduledSuspensionHeader />
          </Layout.Box>

          {/*TODO: remove className="psm-debug-layout" when page is complete*/}
          {/* --- PAGE BODY ---*/}
          <Layout.Box>
            <Layout.Stack space="stack-space-8">
              {/* --- TITLE, PILLS BUTTONS, PAGINATION --- */}
              <Layout.Box style={edgePadding}>
                <Layout.Stack space="stack-space-8">
                  {/* --- TITLE --- */}
                  <Layout.Box>
                    <Text size="text-size-2xl" bold>
                      {title}
                    </Text>
                  </Layout.Box>

                  {/* --- PILL BUTTONS AND PAGINATION --- */}
                  <Layout.Box>
                    <Layout.Cluster justify="space-between">
                      <Layout.Box>
                        <HotelScheduleSuspensionFilterButtons />
                      </Layout.Box>

                      <Layout.Box>
                        <ScheduledSuspensionPagination />
                      </Layout.Box>
                    </Layout.Cluster>
                  </Layout.Box>
                </Layout.Stack>
              </Layout.Box>

              {/* --- TABLE AND LAST PAGINATION --- */}
              <Layout.Box>
                <Layout.Stack space="stack-space-8">
                  {/* --- SUSPENSION TABLE --- */}
                  {/*TODO: height css props is temp, remove once table component is in place*/}
                  <Layout.Box>
                    <HotelScheduleSuspensionTable />
                  </Layout.Box>

                  {/* --- FINAL PAGINATION --- */}
                  <Layout.Box>
                    <Layout.Box style={edgePadding}>
                      <Layout.Cluster justify="right">
                        <ScheduledSuspensionPagination />
                      </Layout.Cluster>
                    </Layout.Box>
                  </Layout.Box>
                </Layout.Stack>
              </Layout.Box>
            </Layout.Stack>
          </Layout.Box>
        </Layout.Stack>

        {/* MODAL */}
        <HotelScheduleSuspensionHistoryModal />
        <HotelScheduleSuspensionCancelModal />
      </Layout.Box>
    </LoadingWrapper>
  );
};

// CONTAINER ----------------------------------------------------------------------------------------
export const DdcScheduleSuspensionPage = connect(
  state => {
    const selectedDate = getSelectedDate(state);
    const isLoading = createLoadingSelector([
      GET_HOTEL_SERVICE_SUSPENSION,
      POST_HOTEL_SERVICE_SUSPENSION,
      PATCH_HOTEL_SERVICE_SUSPENSION,
    ])(state);

    return {
      componentId: "DdcScheduleSuspensionPage",
      title: "Suspensions Schedule",
      isLoading,

      selectedDate,
    };
  },

  dispatch => {
    return {
      handleGetHotelServiceSuspension: ({ fromDate }) => {
        // Fetching one month past and 18 months future suspensions
        const startOfDateRange = moment(fromDate)
          .subtract(SuspensionDateRangeStart, "months")
          .format("YYYY-MM-DD");
        const endOfDateRange = moment(fromDate)
          .add(SuspensionDateRangeEnd, "months")
          .format("YYYY-MM-DD");
        dispatch(
          getHotelServiceSuspension({
            fromDate: startOfDateRange,
            toDate: endOfDateRange,
            page: 0,
            size: 12,
            includeHistory: true,
          }),
        );
      },
      handleGetSuspensionReasons: () => {
        dispatch(
          getServiceManagementToggleReasons({
            suspensionTypes: `${suspensionTypes.SERVICE},${suspensionTypes.CTAD}`,
          }),
        );
      },
    };
  },
  (mapProps, dispatchProps) => {
    const { componentId, title, selectedDate, isLoading } = mapProps;
    const { handleGetHotelServiceSuspension, handleGetSuspensionReasons } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      title,
      isLoading,

      // actions to pass to view
      onPageLoad: () => {
        handleGetHotelServiceSuspension({ fromDate: selectedDate });
        handleGetSuspensionReasons();
      },
    };
  },
)(ScheduleSuspensionPage);
