import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";
import getFormFieldsChanged from "../../../../core/utils/formUtils/getFormFieldsChanged";
import {
  getPetHealthConditionsFormData,
  getPetHealthConditionsInitialValues,
  getCommaDelimitedPetHealthConditions
} from "../../../../core/selectors/petHealthConditionsSelectors";
import petHealthConditionsActionCreators from "../../../../core/actionCreators/petHealthConditionsActionCreators";
import PetHealthConditionsComponent from "./PetHealthConditionsComponent";

const mapStateToProps = (state, ownProps) => ({
  petHealthConditionsFormData: getPetHealthConditionsFormData(state, ownProps),
  petHealthConditionsInitialValues: getPetHealthConditionsInitialValues(state, ownProps),
  commaDelimitedPetHealthConditions: getCommaDelimitedPetHealthConditions(state, ownProps)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitForm: ({ initialValues, formValues }) => {
    const formFieldsToSubmit = getFormFieldsChanged(initialValues, formValues);
    if (!isEmpty(formFieldsToSubmit)) {
      dispatch(
        petHealthConditionsActionCreators.updatePetHealthConditions({
          customerKey: ownProps.customerKey,
          petId: ownProps.petId,
          formValues: formFieldsToSubmit
        })
      );
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PetHealthConditionsComponent);
