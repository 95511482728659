/**
 * Groups an array of objects by a specified key.
 *
 * @param {Array} arr - The input array of objects to group.
 * @param {string} key - The key to group the objects by.
 * @returns {Object} - A dictionary object that groups the input array elements by the key.
 */
export default function groupBy(arr, key) {
  return arr.reduce((acc, cur) => {
    if (!acc[cur[key]]) {
      // If the key doesn't exist in the accumulator object, create a new array for it.
      acc[cur[key]] = [];
    }
    // Add the current object to the array associated with its key in the accumulator.
    acc[cur[key]].push(cur);
    return acc;
  }, {});
}
