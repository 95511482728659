/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_VOID_TRANSACTION_REASON = "SET_VOID_TRANSACTION_REASON";
export const SET_VOID_TRANSACTION_REASON_NOTES = "SET_VOID_TRANSACTION_REASON_NOTES";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setVoidTransactionReason = reason => ({
  type: SET_VOID_TRANSACTION_REASON,
  reason,
});

export const setVoidReasonNotes = voidReasonNotes => ({
  type: SET_VOID_TRANSACTION_REASON_NOTES,
  voidReasonNotes,
});
