import { errorMessages } from "dux/hotelBookingServiceSelection/__constants__/hotelBookingRoomSelectionConstants";

/**
 * A helper function to build an array of select options for room count
 * @param { number } Object.min - minumum value to be included
 * @param { number } Object.max - maximum value to be included
 * @return { array } The array of options
 */
export const getHotelBookingRoomCountOptions = ({ min, max }) =>
  Array.from({ length: max - min + 1 }, (option, index) => index + min);

export const getHotelBookingRoomErrorMessage = ({ isAggressiveBreed, isCatRoomSharingWithDog }) => {
  if (isAggressiveBreed) return errorMessages.AGGRESSIVE_BREED;
  if (isCatRoomSharingWithDog) return errorMessages.DOG_CAT_SHARING;
};
