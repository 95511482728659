import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { GET_AVAILABLE_BUNDLES_BY_PET } from "../availableBundlesByPetActions";
import getIsBGMWorkflowFeatureFlagHidden from "../../../../web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import { getFilteredActiveBundlesByPet } from "../availableBundlesByPetSelectors";
import { createLoadingSelector } from "../../../../core/selectors/utils";
import { AvailableBundlesListComponent } from "../../../_components/bgm/availableBundlesByPet/AvailableBundlesListComponent";
import { getCurrentCustomerKey } from "../../../../core/selectors/persistent/customer/customerSelectors";
import getFTCOWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";

const mapStateToProps = state => {
  const customerId = getCurrentCustomerKey(state);
  const petId = getSelectedPet(state);
  const bundles = getFilteredActiveBundlesByPet(state, { petId });
  const isFeatureFlagHidden =
    getIsBGMWorkflowFeatureFlagHidden(state) && getFTCOWorkflowFeatureFlagHidden(state);
  const hidden = isFeatureFlagHidden || isEmpty(bundles) || getSystemBookingFlow(state) == systemName.HOTEL;
  return {
    bundles,
    topLine: !hidden,
    isHidden: hidden,
    componentId: "PetProfileAvailableBundlesList_AvailableBundlesListComponent",
    idEnum: { customerId, petId },
    isLoading: createLoadingSelector([GET_AVAILABLE_BUNDLES_BY_PET])(state),
  };
};

export default connect(mapStateToProps)(AvailableBundlesListComponent);
