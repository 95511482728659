import { createSelector } from "reselect";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { getHotelOvernightAppointmentsByDate } from "dux/hotelOvernight/columnList/hotelOvernightSelector";
import { getHotelCheckedOutAppointmentsByDate } from "dux/hotelCheckedOut/columnList/hotelCheckedOutSelector";
import { getHotelDeparturesAppointmentsByDate } from "dux/hotelDepartures/columnList/hotelDeparturesSelector";
import { isEmpty } from "lodash/fp";
import filterAppointmentsByFilterType from "../../../core/utils/filterUtils/filterAppointmentsByFilterType";
import filterAppointmentBySelectedDate from "../../../core/utils/filterUtils/filterAppointmentBySelectedDate";
import filterAppointmentsByPetNameRoomOwner from "../../../core/utils/filterUtils/filterAppointmentsByPetNameRoomOwner";
import { sortByPropertyAsc } from "@/core/utils/arrayUtils/sortByProperty";

export const getHotelArrivalsState = state => {
  return state.hotelArrivals;
};

export const getHotelArrivalsAppointmentState = state => {
  return state.hotelArrivals.appointments;
};

export const getHotelArrivalsAppointments = createSelector(
  [getHotelArrivalsState],
  ({ appointments, searchText, filterBy }) => {
    const filteredBySearchText = filterAppointmentsByPetNameRoomOwner(appointments, searchText);
    const filteredByFilterType = filterAppointmentsByFilterType(filteredBySearchText, filterBy);
    const filteredAndSortedAppointments = sortByPropertyAsc(
      filteredByFilterType,
      "checkInDateTime",
    );

    return filteredAndSortedAppointments;
  },
);

export const getHotelArrivalsAppointmentsByDate = createSelector(
  [getHotelArrivalsState, (state, props) => props],
  (arrivals, { date }) => {
    return filterAppointmentBySelectedDate(arrivals.appointments, date);
  },
);

export const getHotelDashboardAppointmentByPetId = createSelector(
  [
    getHotelArrivalsAppointmentsByDate,
    getHotelOvernightAppointmentsByDate,
    getHotelCheckedOutAppointmentsByDate,
    getHotelDeparturesAppointmentsByDate,
    getCurrentPet,
  ],
  (arrivals, overnight, checkedOut, departures, petId) => {
    let foundAppointment = {};

    foundAppointment = arrivals.find(appt => appt.petId === petId);
    if (!isEmpty(foundAppointment)) {
      return foundAppointment;
    }

    foundAppointment = overnight.find(appt => appt.petId === petId);
    if (!isEmpty(foundAppointment)) {
      return foundAppointment;
    }

    foundAppointment = departures.find(appt => appt.petId === petId);
    if (!isEmpty(foundAppointment)) {
      return foundAppointment;
    }

    foundAppointment = checkedOut.find(appt => appt.petId === petId);
    if (!isEmpty(foundAppointment)) {
      return foundAppointment;
    }

    return foundAppointment;
  },
);
