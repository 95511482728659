import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Retrieves service card by itinerary. A service card is a PDF generated specifically
 * for the hotel itinerary. It provides in-store associates with details about the pet and
 * the services in a printable format.
 * For System Services Booking API
 * @param {*} { customerKey, hotelItineraryId, petId}
 * @returns {*} AxiosPromise
 */
export function fetchHotelServiceCard({ customerKey, hotelItineraryId, petId }) {
  return bookingAPI({
    method: "GET",
    url: `hotel/${endpointVersionString()}/customers/${customerKey}/itineraries/${hotelItineraryId}/servicecard`,
    params: { petId },
  });
}
