import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { associateWebAPI } from "./../../../../../associate-ui/src/core/services/associateWeb/_axiosConfigAssociateWeb";
import { endpointVersionString } from "core/utils/envUtils";

/**
 * Update the price of a pet service on any add-on
 * For System Services Booking API
 * - Update price of pet service/add-on
 * @param {*} { customerId, itineraryId, engagementId, petServiceItemId, data }
 * @returns {*} AxiosPromise
 */
export function updatePriceOfPetServiceOrAddon({
  customerId,
  itineraryId,
  engagementId,
  petServiceItemId,
  data,
}) {
  return bookingAPI.put(
    `${endpointVersionString()}/customers/${customerId}/itineraries/${itineraryId}/engagements/${engagementId}/petservices/${petServiceItemId}/prices`,
    data,
  );
}

/**
 * Get the add-ons available at the specified store for the specified pet service
 * For System Services Booking API
 * - Get pet service add-ons available at a store for a pet service.
 * @param {*} { storeNumber, petServiceId, fromDate, bundleConfigId }
 * @returns {*} AxiosPromise
 */
export function fetchAddOns({
  storeNumber,
  petServiceId,
  fromDate,
  productBundleConfigId,
  customerKey,
  petId,
}) {
  return associateWebAPI.get(
    `${endpointVersionString()}/stores/${storeNumber}/petservices/${petServiceId}/addons`,
    {
      params: {
        fromDate,
        productBundleConfigId,
        customerKey,
        petId,
      },
    },
  );
}

/**
 * Update the add-ons of the specified pet service item.
 * For System Services Booking API
 * - Change pet service add-ons
 * @param {*} { customerId, itineraryId, engagementId, petServiceItemId, addons }
 * @returns {*} AxiosPromise
 */
export function putAddOns({ customerId, itineraryId, engagementId, petServiceItemId, addons }) {
  return bookingAPI.put(
    `${endpointVersionString()}/customers/${customerId}/itineraries/${itineraryId}/engagements/${engagementId}/petservices/${petServiceItemId}/addons`,
    addons,
  );
}

/*  ********************************************************************************************************************
Hotel addons
********************************************************************************************************************* */

/**
 * API call to get Hotel addOns at a store filtered down by petservice and pet
 *
 * @param {Object} obj - destructed params.
 * @param {string} obj.storeNumber
 * @param {string} obj.petservices - Pet service Id
 * @param {string} obj.pets - Pet Id
 * @param {string} obj.checkInDate - Date Pet will be checked in
 * @return {AxiosPromise<any>}
 */
// Confluence Docs: https://confluence.ssg.petsmart.com/pages/viewpage.action?spaceKey=PART&title=19.+Get+Hotel+AddOns+by+Pet+Service
export function getHotelAddOnsByPetService({ storeNumber, petservices, pets, checkInDate }) {
  return bookingAPI.get(
    `hotel/products/v2/stores/${storeNumber}/petservices/${petservices}/pets/${pets}/addOns`,
    {
      params: {
        checkInDate,
      },
    },
  );
}
