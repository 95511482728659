import { connect } from "react-redux";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getCurrentPet, hasManagerPrivileges } from "core/selectors/persistentSelectors";
import { buildPetFavoribilityOptionsList } from "core/utils/petParentUtils";
import { getPetFavorabilityHotelFormData, isDisabledForNonManagement } from "./petFavorabilitySelectors";
import { updatePetFavorabilityHotel } from "./petFavorabilityHotelActions";
import PetFavorabilityComponent from "../_components/favorability/PetFavorabilityComponent";

const mapStateToProps = (state) => {
  const petId = getCurrentPet(state);
  const customerKey = getCurrentCustomerKey(state);
  const isManager = hasManagerPrivileges(state);
  const petFavorabilityFormData = getPetFavorabilityHotelFormData(state, { petId });
  const isDisabledForNonManager = isDisabledForNonManagement({ petFavorabilityFormData, isManager });
  const options = buildPetFavoribilityOptionsList(!isManager && !isDisabledForNonManager);

  return {
    componentID: "petFavorably-Hotel",
    title: "pet hotel favorability",
    label: "PetsHotel rating",
    isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
    petFavorabilityFormData,
    isDisabledForNonManagement: isDisabledForNonManager,
    options,
    petId,
    customerKey,
  };
};

const mapDispatchToProps = dispatch => ({
  handleOnSubmit: ({ formValues, petId, customerKey }) => {
    dispatch(
      updatePetFavorabilityHotel({
        customerKey,
        petId,
        formValues,
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSubmit: ({ formValues }) =>
    propsFromDispatch.handleOnSubmit({
      petId: propsFromState.petId,
      customerKey: propsFromState.customerKey,
      formValues,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PetFavorabilityComponent);
