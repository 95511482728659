import { connect } from "react-redux";

// Components
import { ToggleButtonListWithSelectAll } from "@/dux/filteringButtons/ToggleButtonListWithSelectAll";

// Actions
import { setOverBookingFilters } from "./overBookingFilterPillsActions";

// Container
export const OverBookingFilterPills = connect(
  state => {
    const filters = [
      {
        id: "scheduled",
        name: "Scheduled",
        isPressed: false,
        filterValue: "Scheduled",
      },
      {
        id: "canceled",
        name: "Canceled",
        isPressed: false,
        filterValue: "Canceled",
      },
      {
        id: "completed",
        name: "Completed",
        isPressed: false,
        filterValue: "Completed",
      },
    ];

    return {
      componentId: "OverBookingFilterPills",
      key: filters,
      data: filters,
    };
  },

  dispatch => {
    return {
      setGlobalState: data => {
        const filters = Object.values(data)?.filter(item => item.isPressed);
        dispatch(setOverBookingFilters(filters));
      },
    };
  },
)(ToggleButtonListWithSelectAll);
