import { connect } from "react-redux";
import SearchInput from "../../../../web/common/SearchInput";
import simplePhrases from "../../../_constants/simplePhrasesConstants";
import {
  clearHotelArrivalSearchText,
  setHotelArrivalSearchText,
} from "./hotelArrivalsHeaderSearchActions";
import { getArrivalsSearchText } from "./hotelArrivalsheaderSearchSelector";

const mapStateToProps = state => ({
  placeholder: simplePhrases.HOTEL_HEADER_SEARCH_BY,
  value: getArrivalsSearchText(state),
});

const mapDispatchToProps = dispatch => ({
  onChange: e => dispatch(setHotelArrivalSearchText({ value: e.target.value })),
  clearText: () => dispatch(clearHotelArrivalSearchText()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
