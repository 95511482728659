import React from "react";
import { Layout } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import { HotelCheckInOutInvoiceGeneratedTotalPrice } from "web/invoiceGeneratedTotalPrice/InvoiceGeneratedTotalPriceComponent";
import { CheckInOutPaidVoidTransaction } from "dux/_components/invoice/PaidVoidTransaction";
import { HotelCheckInOutInvoiceGeneratedCheckbox } from "web/invoiceGeneratedTotalPrice/InvoiceGeneratedCheckBox";
import { color } from "web/common/styles/theme";
/* NOTE: -------------------------------------------------------------------------------------------
 The intent and responsibility of this file is to just provide a dry solution for
 for the JSX that contains the total price and invoice info for hotel checkIn/Out
 This code is identical in the CheckInOutSinglePetCheckInLayout and CheckInOutMultiPetCheckInLayout
 files and thus this file is to abstract that out to reduce wet code and provide a update once, use
 multiple principle.

 I you find this layout differs between the CheckInOutSinglePetCheckInLayout and CheckInOutMultiPetCheckInLayout
 use a different solution, do not modify this file to handle conditional cases.

 This file should not contain any logic as it is specifically a wrapper for other components.
 -------------------------------------------------------------------------------------------------*/
export const TotalPriceLayoutWrapper = props => {
  const { componentId, isHidden } = props;

  if (isHidden) {
    return null;
  }

  return (
    <Layout.Stack
      space="stack-space-3"
      id={componentId}
      style={{ borderTop: `1px dashed ${color.borderGrey}`, paddingTop: "1em" }}
    >
      <HotelCheckInOutInvoiceGeneratedTotalPrice />
      <CheckInOutPaidVoidTransaction />
      <HotelCheckInOutInvoiceGeneratedCheckbox />
    </Layout.Stack>
  );
};

// CONTAINER ----------------------------------------------------------------------------------------
export const TotalPriceLayoutCheckInOutWrapper = connect(() => {
  return {
    componentId: "TotalPriceLayoutCheckInOutWrapper",
    isHidden: false,
  };
})(TotalPriceLayoutWrapper);
