import { put, takeEvery, call, all } from "redux-saga/effects";
import serviceStatusesActionTypes from "../actionTypes/serviceStatusesActionTypes";
import serviceStatusesActionCreator from "../actionCreators/serviceStatusesActionCreator";
import { fetchStatuses } from "../services/systemServicesBooking/statusEndPoints";

function* onloadStatuses() {
  try {
    yield put(serviceStatusesActionCreator.loadStatusesRequest());
    const response = yield call(fetchStatuses);
    const statuses = response.data;
    yield put(serviceStatusesActionCreator.loadStatusesSuccess({ statuses }));
  } catch (error) {
    yield put(serviceStatusesActionCreator.loadStatusesFailure({ error }));
  }
}

function* watchloadItineraries() {
  yield takeEvery(serviceStatusesActionTypes.LOAD_STATUSES, onloadStatuses);
}

export default function* serviceStatusesSaga() {
  yield all([watchloadItineraries()]);
}
