/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const DELETE_HOTEL_ADDON = "DELETE_HOTEL_ADDON";
export const deleteHotelAddon = ({ payload }) => ({
  type: DELETE_HOTEL_ADDON,
  payload,
});

export const DELETE_HOTEL_ADDON_REQUEST = "DELETE_HOTEL_ADDON_REQUEST";
export const deleteHotelAddonRequest = () => ({
  type: DELETE_HOTEL_ADDON_REQUEST,
});

export const DELETE_HOTEL_ADDON_SUCCESS = "DELETE_HOTEL_ADDON_SUCCESS";
export const deleteHotelAddonSuccess = ({ payload }) => ({
  type: DELETE_HOTEL_ADDON_SUCCESS,
  payload,
});

export const DELETE_HOTEL_ADDON_FAILURE = "DELETE_HOTEL_ADDON_FAILURE";
export const deleteHotelAddonFailure = ({ error }) => ({
  type: DELETE_HOTEL_ADDON_FAILURE,
  error,
});
