import { call, all } from "redux-saga/effects";
import { eligibilitySubjectTypes } from "dux/eligibility/constants/eligibilityConstants";
import { onGetReasonFlow } from "dux/eligibility/sagas/eligibilityPetCustomerReasonsFow";

/**
 * Responsible for getting the Secondary eligibility engine results for all pets
 * @param { Array } petIds
 * @param { string } eligibilityType - eligibility tpe, such as booking, check in, check out etc.
 * @return {Generator<CombinatorEffect<"ALL", unknown>, *, ?>}
 */
export function* onGetSecondaryEngineForAllPets({ petIds, eligibilityType }) {
  const secondaryEngineResult = yield all(
    petIds.map(petId => {
      return call(onGetReasonFlow, {
        id: Number(petId),
        eligibilityType,
        eligibilitySubjectType: eligibilitySubjectTypes.PET,
      });
    }),
  );

  return secondaryEngineResult;
}
