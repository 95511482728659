import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../core/constants/";
import buildHourOption from "./buildHourOption";

/** @module getHourOptions */

/**
 * @export getHourOptions
 * @param {Object} obj - Destructuring parameters
 * @param {(object|string)} obj.startDate - Moments Object or string
 * @param {(object|string)} obj.endDate - Moments Object or string
 * @param {number} obj.stagger
 * @param {object} obj.availableTimeSlots
 * @param {object} obj.service
 * @returns {array}
 */
export default function getHourOptions({
  startDate,
  endDate,
  stagger,
  availableTimeSlots,
  service
}) {
  let currentMoment = moment(startDate);
  const endMoment = moment(endDate);
  const options = [];

  while (currentMoment <= endMoment) {
    const formattedMoment = currentMoment.format(DATE_TIME_FORMAT);
    options.push(buildHourOption({ dateTime: formattedMoment, service, availableTimeSlots }));
    currentMoment = currentMoment.add(stagger, "hours");
  }

  return options;
}
