import { connect } from "react-redux";
import { getPetFullName, getPetPhoto } from "core/selectors/entitiesSelector";
import { getIsCurrentEngagementPriceGreaterThanZero } from "core/selectors/checkInOutSelector";
import { PAYMENT_MESSAGE, REDEEMED_MESSAGE } from "dux/modals/checkIn/constants/checkInConstants";
import CheckInModalContent from "dux/_components/modals/checkIn/single/checkInModalContent";

const mapStateToProps = (state, { customerKey, petId }) => {
  const petFullName = getPetFullName(state, { customerKey, petId });
  return {
    isHidden: false,
    headerText: `${petFullName} is Checked in\n`,
    // If the itinerary's current price is not greater than zero, then the bundle has not been fully redeemed
    // (it is either a BGM or FTCO bundle package that has additional add-ons, or is a regular appointment).
    // In this case, we want to display a message asking if the customer wants to pay rather than a message
    // asking if appointment has been redeemed.
    message: getIsCurrentEngagementPriceGreaterThanZero(state, { petId })
      ? PAYMENT_MESSAGE
      : REDEEMED_MESSAGE,
    petPhoto: getPetPhoto(state, { petId }),
  };
};

export default connect(mapStateToProps)(CheckInModalContent);
