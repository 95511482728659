/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const setMedicationTimeOfDayActionTypes = {
  SET_MEDICATION_TIME_OF_DAY: "SET_MEDICATION_TIME_OF_DAY",
  MED_TIME_OF_DAY_TOUCHED: "MED_TIME_OF_DAY_TOUCHED",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setMedicationTimeOfDay = ({ medicationId, timeOfDay, petId }) => ({
  type: setMedicationTimeOfDayActionTypes.SET_MEDICATION_TIME_OF_DAY,
  medicationId,
  timeOfDay,
  petId,
});

export const setTimeOfDayTouched = ({ medicationId, timeOfDayTouched, petId }) => ({
  type: setMedicationTimeOfDayActionTypes.MED_TIME_OF_DAY_TOUCHED,
  medicationId,
  timeOfDayTouched,
  petId,
});
