import { put, takeEvery, call, all, select } from "redux-saga/effects";
import { get, noop } from "lodash/fp";

import { getStoreNumber, getIsSRCAgent } from "../../core/selectors/persistentSelectors";
import associateActionTypes from "../actionTypes/associateActionTypes";
import associateActionCreator from "../actionCreators/associateActionCreator";
import { fetchEmployeeGroups } from "../services/systemServicesBooking/employeeGroupsEndPoints";
import {
  fetchAssociates,
  putAssociate,
  patchAssociate,
} from "../services/systemServicesBooking/associatesEndPoints";

import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";
import { setRuleViolations } from "../actionCreators/ruleViolationsActionCreator";
import { modalTypes as commonModalTypes } from "../constants/modalConstants";
import { getEmployeeGroupType } from "@/dux/associateProfile/associateProfileUtils";
import { getAssociate } from "../selectors/entitiesSelector";

function* onloadAssociates({ isLimited }) {
  const storeNumber = yield select(getStoreNumber);
  if (storeNumber) {
    try {
      yield put(associateActionCreator.loadAssociatesRequest());
      const isSRCAgent = yield select(getIsSRCAgent);
      const response = yield call(fetchAssociates, {
        storeNumber,
        isLimited: isLimited || isSRCAgent,
      });
      let associates = response.data;

      associates = associates.map((associate) => ({
        ...associate,
        employeeGroupType: getEmployeeGroupType(associate),
      }));

      yield put(
        associateActionCreator.loadAssociatesSuccess({
          associates: normalizeArrayByProperty(associates, "associateId"),
        }),
      );
    } catch (error) {
      yield put(associateActionCreator.loadAssociatesFailure({ error }));
    }
  } else {
    // console.log('store number is falsy so we will not try to load associates');
  }
}

function* onUpdateAssociate({ associateId, data }) {
  try {
    const result = {
      ...data,
      weightRestriction: parseFloat(data.weightRestriction),
    };
    yield put(associateActionCreator.updateAssociateRequest());
    const response = yield call(putAssociate, { associateId, data: result });
    const associate = response.data;
    yield put(associateActionCreator.updateAssociateSuccess({ associate }));
  } catch (error) {
    if (get(["response", "status"], error) === 406) {
      const ruleViolations = get(["response", "data"], error);

      if (ruleViolations) {
        yield put(setRuleViolations(ruleViolations));
        yield put(associateActionCreator.showAssociateModal(commonModalTypes.RULE_VIOLATION));
      }
    }

    yield put(associateActionCreator.updateAssociateFailure({ error }));
  }
}

function* onPatchAssociate({ associateId, data, onComplete = noop }) {
  try {
    // Remember to parse float for weight restriction
    yield put(associateActionCreator.patchAssociateRequest());

    const response = yield call(patchAssociate, { associateId, data });
    const { result } = response.data;

    const associate = yield select(getAssociate, { associateId });

    // Perform optimistic update of associate name. We need to set associate name
    // to preferred name to update full associate state since return value of patch
    // call only returns the updated fields and not the full associate payload.
    const preferredName = result?.preferredName || associate?.preferredName;
    const associateName = `${preferredName} ${associate?.lastName}`;

    yield put(
      associateActionCreator.loadAssociatesSuccess({
        associates: {
          [associateId]: {
            ...associate,
            ...result,
            associateName,
          },
        },
      }),
    );

    yield put(associateActionCreator.patchAssociateSuccess(associate));

    onComplete(associate);
  } catch (error) {
    if (get(["response", "status"], error) === 406) {
      const ruleViolations = get(["response", "data"], error);

      if (ruleViolations) {
        yield put(setRuleViolations(ruleViolations));
        yield put(associateActionCreator.showAssociateModal(commonModalTypes.RULE_VIOLATION));
      }
    }

    yield put(associateActionCreator.patchAssociateFailure({ error }));
  }
}

function* onloadEmployeeGroups() {
  try {
    yield put(associateActionCreator.loadEmployeeGroupsRequest());
    const { data } = yield call(fetchEmployeeGroups);
    yield put(
      associateActionCreator.loadEmployeeGroupsSuccess(normalizeArrayByProperty(data, "id")),
    );
  } catch (error) {
    yield put(associateActionCreator.loadEmployeeGroupsFailure({ error }));
  }
}

function* watchloadAssociates() {
  yield takeEvery(associateActionTypes.LOAD_ASSOCIATES, onloadAssociates);
}

function* watchUpdateAssociate() {
  yield takeEvery(associateActionTypes.UPDATE_ASSOCIATE, onUpdateAssociate);
}

function* watchPatchAssociate() {
  yield takeEvery(associateActionTypes.PATCH_ASSOCIATE, onPatchAssociate);
}

function* watchloadEmployeeGroups() {
  yield takeEvery(associateActionTypes.LOAD_EMPLOYEE_GROUPS, onloadEmployeeGroups);
}

export default function* associateSaga() {
  yield all([
    watchloadAssociates(),
    watchUpdateAssociate(),
    watchPatchAssociate(),
    watchloadEmployeeGroups(),
  ]);
}
