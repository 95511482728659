export default {
  SELECT_APPOINTMENT: "SELECT_APPOINTMENT",
  SELECT_ASSOCIATE: "SELECT_ASSOCIATE",
  SELECT_ASSOCIATE_WEEKLY: "SELECT_ASSOCIATE_WEEKLY",
  SET_WEEKLY_GANTT_DATE: "SET_WEEKLY_GANTT_DATE",
  CLEAR_WEEKLY_GANTT_DATE: "CLEAR_WEEKLY_GANTT_DATE",
  SELECT_APPOINTMENT_AND_ASSOCIATE: "SELECT_APPOINTMENT_AND_ASSOCIATE",
  ASSOCIATE_CLICK: "ASSOCIATE_CLICK",
  WEEK_NUMBER: "WEEK_NUMBER",
  RESET_SELECTED_DATE: "RESET_SELECTED_DATE",
  SELECT_TIME: "SELECT_TIME",
  SET_REBOOK_ERROR_MESSAGES: "SET_REBOOK_ERROR_MESSAGES",
  CLEAR_REBOOK_ERROR_MESSAGES: "CLEAR_REBOOK_ERROR_MESSAGES",
};
