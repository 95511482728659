import { associateWebBFFAPI } from "./_axiosConfigAssociateWebBff";
import { endpointVersionString } from "../../utils/envUtils";
import {
  CUSTOMER_ID_HEADER_KEY,
  PAYMENT_SOURCE_HEADER_KEY,
} from "@/core/constants/endpointConstants";

/**
 * API to get PDF for invoice or quote invoice for a given itinerary
 * @memberOf APIs.hotel.itinerary
 * @param {string} customerId - id of customer on itinerary
 * @param {string} itineraryId - id of itinerary
 * @returns {*} AxiosPromise
 */

export function fetchHotelInvoice({ customerId, itineraryId }) {
  return associateWebBFFAPI.get(
    `${endpointVersionString()}/hotel/itineraries/${itineraryId}/invoice`,
    { headers: { [CUSTOMER_ID_HEADER_KEY]: customerId } },
  );
}

/**
 * API to set itinerary invoice as paid, void, etc.
 * @memberOf APIs.hotel.itinerary
 * @param {string} customerId - id of customer on itinerary
 * @param {string} itineraryId - id of itinerary
 * @param {string} paymentOrigin
 * @param {object} data to pass in put call
 * @returns {*} AxiosPromise
 * @example putItineraryPayment({ customerId, itineraryId, paymentOrigin: "POS", data: { action: "paidInFull" } })
 */
export function putItineraryPayment({ customerId, itineraryId, paymentOrigin, data }) {
  return associateWebBFFAPI.put(
    `${endpointVersionString()}/hotel/itineraries/${itineraryId}/payment`,
    data,
    {
      headers: {
        [CUSTOMER_ID_HEADER_KEY]: customerId,
        [PAYMENT_SOURCE_HEADER_KEY]: paymentOrigin,
      },
    },
  );
}
