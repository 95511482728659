import ruleViolationsActionTypes from "../actionTypes/ruleViolationsActionTypes";

const initialState = [];

const ruleViolationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ruleViolationsActionTypes.SET_RULE_VIOLATIONS:
      return action.ruleViolations;

    case ruleViolationsActionTypes.CLEAR_RULE_VIOLATIONS:
      return initialState;

    default:
      return state;
  }
};

export default ruleViolationsReducer;
