import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";
import SectionHeader from "web/common/sectionHeader";
import { getPetById, getPetFullName } from "core/selectors/entitiesSelector";
import { getPetAlert } from "core/selectors/checkInOutSelector";
import { setPetId } from "core/actionCreators/ui/web/generalActionCreators";
import { HotelPetNameCheckInOutLink } from "web/petCheckInOut/petDetails/PetNameCheckInOutLink";

export const CheckInOutPetDetails = props => {
  const { componentId, isHidden, petId, title } = props;

  if (isHidden) return null;

  return (
    <Layout.Box id={componentId}>
      <SectionHeader title={title} />
      <HotelPetNameCheckInOutLink petId={petId} />
    </Layout.Box>
  );
};

// HOTEL CHECKIN PET PARENT DETAILS CONTAINER --------------------------------------------------------------------------
export const HotelCheckInOutPetDetails = connect((state, ownProps) => {
  const pet = getPetById(state, ownProps.petId);
  return {
    isHidden: false,
    componentId: "HotelCheckInOutPetDetails",
    title: "Pet Details",
    setPetId: petId => dispatch(setPetId(petId)),
    isPetActive: pet?.isActive,
    petFullName: getPetFullName(state, ownProps),
    petAlert: getPetAlert(state, ownProps),
  };
})(CheckInOutPetDetails);
