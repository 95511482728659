import searchActionTypes from "../actionTypes/searchActionTypes";
import salonActionTypes from "../actionTypes/salonActionTypes";

const initialState = { customers: [], salonInfoWithServices: [], searchResultsToDisplay: null };

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case searchActionTypes.LOAD_CUSTOMERS_SEARCH_RESULTS:
      return {
        ...state,
        customers: action.customers,
      };
    case salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES_SUCCESS:
      return {
        ...state,
        salonInfoWithServices: action.salon,
      };
    case searchActionTypes.SET_SEARCH_RESULTS_TO_DISPLAY:
      return {
        ...state,
        searchResultsToDisplay: action.searchResultsToDisplay,
      };
    default:
      return state;
  }
}
