import { call, put, select, all, takeEvery } from "redux-saga/effects";
import jobRoleActionTypes from "../../actionTypes/jobRoleActionTypes";
import { getOktaEmail, getStoreNumber, getSessionToken } from "../../selectors/persistentSelectors";
import { resetMockAccessLevel } from "../../services/systemAssociateAuth/mockAccessLevelEndPoints";
import {
  updateAccessLevelInState,
  mockTestRoleFailure
} from "../../actionCreators/jobRoleActionCreators";
import { refreshSessionToken } from "../../actionCreators/authActionCreators";

function* onResetTestRoles() {
  try {
    const username = yield select(getOktaEmail);

    const data = {
      storeNumber: yield select(getStoreNumber)
    };

    const xSessionToken = yield select(getSessionToken);

    const response = yield call(resetMockAccessLevel, { username, data, xSessionToken });

    yield put(updateAccessLevelInState({ mockAccessLevel: response.data }));

    yield put(refreshSessionToken());
  } catch (error) {
    yield put(mockTestRoleFailure({ error }));
  }
}

function* watchResetTestRoles() {
  yield takeEvery(jobRoleActionTypes.RESET_MOCK_JOB_ROLES, onResetTestRoles);
}

export default function* resetTestRoles() {
  yield all([watchResetTestRoles()]);
}
