import { associateWebAPI } from "./_axiosConfigAssociateWeb";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Updates a customer's existing account from the Web Associate API - Update email to customer's account end point
 * @param {*} { customerKey, emailAddressId, sourceId, data }
 * @returns
 */
export function putEmail({ customerKey, emailAddressId, sourceId, data }) {
  return associateWebAPI.put(
    `${endpointVersionString()}/customers/${customerKey}/emails/${emailAddressId}?sourceId=${sourceId}`,
    data
  );
}
