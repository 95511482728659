export default {
  LOAD_SALON_HOURS: "LOAD_SALON_HOURS",
  LOAD_SALON_HOURS_REQUEST: "LOAD_SALON_HOURS_REQUEST",
  LOAD_SALON_HOURS_SUCCESS: "LOAD_SALON_HOURS_SUCCESS",
  LOAD_SALON_HOURS_FAILURE: "LOAD_SALON_HOURS_FAILURE",
  LOAD_RELOCATED_SALON_HOURS: "LOAD_RELOCATED_SALON_HOURS",
  LOAD_RELOCATED_SALON_HOURS_REQUEST: "LOAD_RELOCATED_SALON_HOURS_REQUEST",
  LOAD_RELOCATED_SALON_HOURS_SUCCESS: "LOAD_RELOCATED_SALON_HOURS_SUCCESS",
  LOAD_RELOCATED_SALON_HOURS_FAILURE: "LOAD_RELOCATED_SALON_HOURS_FAILURE",
  LOAD_ALL_RELOCATED_SALON_HOURS: "LOAD_ALL_RELOCATED_SALON_HOURS",
  LOAD_ALL_RELOCATED_SALON_HOURS_REQUEST: "LOAD_ALL_RELOCATED_SALON_HOURS_REQUEST",
  LOAD_ALL_RELOCATED_SALON_HOURS_SUCCESS: "LOAD_ALL_RELOCATED_SALON_HOURS_SUCCESS",
  LOAD_ALL_RELOCATED_SALON_HOURS_FAILURE: "LOAD_ALL_RELOCATED_SALON_HOURS_FAILURE",
};
