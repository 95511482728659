import { createSelector } from "reselect";
import { isEmpty, get, getOr } from "lodash/fp";
import formatPhoneNumberNANP from "../utils/stringManipulationUtils/formatPhoneNumber";
import {
  getEmailsByCustomer,
  getPhonesByCustomer,
  getPetsNamesAndIdsByCustomer,
  getCustomerFullName,
} from "./entitiesSelector";
import verifyPhoneNumber from "../utils/validationUtils/phoneNumberValidation";

// All customers based on search criteria, A list of customers.
export const getCustomersSearchResults = state => state.search.customers;

// Store Info based on a search for store Info.
export const getStoreSearchResults = state => state.search.salonInfoWithServices;

// A Bool to determine if search results should be shown.
export const getShowResults = state => state.ui.web.search.showSearchResults;

export const getSalonInfoWithServicesSearchResults = state => state.search.storeInfo;

export const getSearchResultsToDisplay = state => state.search.searchResultsToDisplay;

function getCustomer(state, custID) {
  const { customers } = state.entities.customers;
  return customers.find(customer => (customer.customerKey = custID));
}

export const getShowQuickView = state => state?.ui?.web?.search?.showQuickView;

export const getSearchFieldType = state => state?.ui?.web?.search?.searchFieldType;

export const getSearchFieldValue = state => state?.ui?.web?.search?.searchField;

export const getSearchApplied = state => state?.ui?.web?.search?.searchApplied;

export const getCustQuickViewInfo = (state, custID) => {
  const customerInfo = [];
  const customer = getCustomer(state, custID);
  const primaryPhone = customer.phones.find(phone => phone.isPrimary);
  const primaryEmail = customer.emails.find(email => email.isPrimary);

  customerInfo.push(`${customer.firstName} ${customer.lastName}`);
  customerInfo.push(primaryEmail.email);
  customerInfo.push(primaryPhone.phoneNumber); // TODO: need to format number

  return customerInfo;
};

export const getCustAddressQuickViewInfo = (state, custID) => {
  const customerAddress = [];
  const customer = getCustomer(state, custID);

  try {
    const primaryAddress = customer.addresses.find(address => address.isPrimary);

    customerAddress.push(`${primaryAddress.streetLine1} ${primaryAddress.streetLine2}`);
    customerAddress.push(`${primaryAddress.city} ${primaryAddress.stateProvinceAbbreviation}`);
    customerAddress.push(primaryAddress.zipPostalCode);
  } catch (error) {
    customerAddress.push("No Address information");
    console.warn("Need to do new API call to get customer address info");
  }

  return customerAddress;
};

export const getFormattedStoreSearchResults = createSelector(
  [getStoreSearchResults],
  storeSearchResults => {
    /* data from state will be a single store wrapped in an array, In this case we do not need the
    array, only the single store object. */
    const storeSearchResult = storeSearchResults[0];

    // Place all values into an array so they can be neatly displayed in a list.
    const storeInfo = [];

    if (!isEmpty(storeSearchResult)) {
      storeInfo.push(`${storeSearchResult.StoreNumber} ${storeSearchResult.Name}`);
      storeInfo.push(storeSearchResult.StreetLine1);
      storeInfo.push(formatPhoneNumberNANP(storeSearchResult.PhoneNumber));

      // The storeSearchResult.StoreServices object is an array of objects that provide
      // properties such as name or if booking is allowed. We need to extract the name
      // from each object and all names need to be placed into a single string.
      let servicesString = "";
      if (storeSearchResult.StoreServices) {
        const servicesObjects = Object.values(storeSearchResult.StoreServices);
        const listOfServices = servicesObjects.map(item => item.ServiceName);
        servicesString = listOfServices.join(", ");
      }
      storeInfo.push(servicesString);
    }

    return storeInfo;
  },
);

export const getSearchResults = createSelector(
  [getCustomersSearchResults, getStoreSearchResults, getSearchFieldType],
  (customerSearchResults, storeSearchResults, searchFieldType) => {
    if (searchFieldType === "store" && !isEmpty(storeSearchResults)) {
      // The storeSearchResults.StoreServices object is an array of objects that provide
      // properties such as name or if booking is allowed. We need to extract the name
      // from each object and all names need to be placed into a single string.
      let servicesString = "";
      if (storeSearchResults.StoreServices) {
        const servicesObjects = Object.values(storeSearchResults.StoreServices);
        const listOfServices = servicesObjects.map(item => item.ServiceName);
        servicesString = listOfServices.join(", ");
      }

      // Place all values into an array so they can be neatly displayed in a list.
      const storeInfo = [];
      storeInfo.push(`${storeSearchResults.StoreNumber} ${storeSearchResults.Name}`);
      storeInfo.push(storeSearchResults.StreetLine1);
      storeInfo.push(formatPhoneNumberNANP(storeSearchResults.PhoneNumber));
      storeInfo.push(servicesString);

      return storeInfo;
    }
    return customerSearchResults;
  },
);

export const getPets = (state, custID) => {
  const customer = getCustomer(state, custID);
  return customer.pets;
};

const filterBySearchTerm = ({ arr = [], key, searchField }) =>
  arr.filter(get("isActive")).find(obj => obj[key] === searchField || obj.isPrimary);

export const getSearchInfoList = createSelector(
  [
    getCustomerFullName,
    getEmailsByCustomer,
    getPhonesByCustomer,
    getPetsNamesAndIdsByCustomer,
    getSearchFieldValue,
  ],
  (customerFullName, emails, phones, pets, searchField) => {
    const phone = filterBySearchTerm({ arr: phones, key: "phoneNumber", searchField });
    const email = filterBySearchTerm({ arr: emails, key: "email", searchField });

    const normalizedPhoneNumber =
      phone && verifyPhoneNumber(phone.phoneNumber) ? formatPhoneNumberNANP(phone.phoneNumber) : "";
    const normalizedEmail = getOr("", "email", email);
    const petNames = pets.length > 0 ? `Pets: ${pets.map(get("petName")).join(", ")}` : "";

    return [customerFullName, normalizedPhoneNumber, normalizedEmail, petNames];
  },
);

export const getNumberOfSearchResults = createSelector(
  [getSearchResults],
  searchResults => searchResults.length,
);
