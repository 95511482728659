import { associateWebAPI } from "core/services/associateWeb/_axiosConfigAssociateWeb";

/**
 * Removes an existing add-on from a hotel engagement.
 */
export function deleteHotelAddon({
  customerKey,
  itineraryId,
  petId,
  engagementId,
  orderProductNumber,
}) {
  return associateWebAPI.delete(
    `/v1/hotel/booking/customers/${customerKey}/itineraries/${itineraryId}/pets/${petId}/engagements/${engagementId}/addOns/${orderProductNumber}`,
  );
}
