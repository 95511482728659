import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { setHotelEngagement } from "dux/hotelEngagements/hotelEngagementActions";
import {
  DELETE_HOTEL_ADDON,
  deleteHotelAddonFailure,
  deleteHotelAddonRequest,
  deleteHotelAddonSuccess,
} from "dux/deleteHotelAddon/deleteHotelAddonActions";
import { deleteHotelAddon } from "dux/deleteHotelAddon/deleteHotelAddonEndpoint";
import { separateHotelItineraryResponse } from "dux/hotelItinerary/hotelItineraryUtils";
import { getHotelItinerarySuccess } from "dux/hotelItinerary/hotelItineraryActions";

function* onDeleteHotelAddon({ payload }) {
  try {
    yield put(deleteHotelAddonRequest());
    const { customerKey, itineraryId, petId, engagementId, orderProductNumber } = payload;
    const response = yield call(deleteHotelAddon, {
      customerKey,
      itineraryId,
      petId,
      engagementId,
      orderProductNumber,
    });

    // Get the actual result data
    const responseData = response?.data?.result;

    // separate engagements from hotel itinerary
    const dataObj = separateHotelItineraryResponse(responseData);

    // send engagements to state
    yield put(setHotelEngagement(dataObj.engagements));

    // send hotel itinerary to state
    yield put(getHotelItinerarySuccess(dataObj.modifiedItinerary));

    yield put(deleteHotelAddonSuccess({ payload: dataObj }));
  } catch (error) {
    yield put(deleteHotelAddonFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
 WATCH FUNCTIONS
 \** ----------------------------------------------------------------------- * */
function* watchDeleteHotelAddon() {
  yield takeEvery(DELETE_HOTEL_ADDON, onDeleteHotelAddon);
}

/** ----------------------------------------------------------------------- **\
 EXPORT SAGAS
 \** ----------------------------------------------------------------------- * */
export default function* deleteHotelAddonSaga() {
  yield all([fork(watchDeleteHotelAddon)]);
}
