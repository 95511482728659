import { put, takeEvery, call, all } from "redux-saga/effects";
import { forEach, omit } from "lodash/fp";
import { updatePriceOfPetServiceOrAddon } from "../services/systemServicesBooking/addOnEndPoints";
import { fetchPriceAdjustmentReasons } from "../services/systemServicesBooking/priceAdjustmentEndPoints";
import priceAdjustmentActionCreator from "../actionCreators/priceAdjustmentActionCreator";
import priceAdjustmentActionTypes from "../actionTypes/priceAdjustmentActionTypes";
import { dispatchItinerariesDataAndReturnItineraries } from "./itinerariesSaga";

function* onloadPriceAdjustmentReasons() {
  try {
    yield put(priceAdjustmentActionCreator.loadPriceAdjustmentReasonsRequest());
    const response = yield call(fetchPriceAdjustmentReasons, { appType: "Salon" });
    const priceAdjustmentReasons = response.data;
    yield put(
      priceAdjustmentActionCreator.loadPriceAdjustmentReasonsSuccess({
        priceAdjustmentReasons: normalizePriceAdjustmentReasons(priceAdjustmentReasons),
      }),
    );
  } catch (error) {
    yield put(priceAdjustmentActionCreator.loadPriceAdjustmentReasonsFailure({ error }));
  }
}

function* onUpdatePriceOfPetServiceOrAddon({
  customerId,
  itineraryId,
  engagementId,
  petServiceItemId,
  data,
}) {
  try {
    yield put(priceAdjustmentActionCreator.updatePriceOfPetServiceOrAddonRequest());
    const response = yield call(updatePriceOfPetServiceOrAddon, {
      customerId,
      itineraryId,
      engagementId,
      petServiceItemId,
      data,
    });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });
    const itinerary = normalizedItineraries[itineraryId];
    yield put(priceAdjustmentActionCreator.updatePriceOfPetServiceOrAddonSuccess({ itinerary }));
  } catch (error) {
    yield put(priceAdjustmentActionCreator.updatePriceOfPetServiceOrAddonFailure({ error }));
  }
}

function* watchloadPriceAdjustmentReasons() {
  yield takeEvery(
    priceAdjustmentActionTypes.LOAD_PRICE_ADJUSTMENT_REASONS,
    onloadPriceAdjustmentReasons,
  );
}

function* watchUpdatePriceOfPetServiceOrAddon() {
  yield takeEvery(
    priceAdjustmentActionTypes.UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON,
    onUpdatePriceOfPetServiceOrAddon,
  );
}

export default function* priceAdjustmentReasonsSaga() {
  yield all([watchloadPriceAdjustmentReasons(), watchUpdatePriceOfPetServiceOrAddon()]);
}

const normalizePriceAdjustmentReasons = reasons => {
  const result = [];

  forEach(reason => {
    result.push({
      ...omit("name", reason),
      label: reason.name,
    });
  }, reasons);

  return result;
};
