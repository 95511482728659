import { map, keyBy, flow, get, set, identity, filter, reject, omitBy, omit } from "lodash/fp";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { history } from "@/dux/utils/browser/browserHistory";
import schedulesActionTypes from "../actionTypes/schedulesActionTypes";
import { weekDaysNames } from "../constants";
import { mapWithKey } from "../utils";
import { absenceReasons, dayActivityTypes } from "../constants/schedulesConstants";
import ScheduleViolationActionTypes from "../actionTypes/ScheduleViolationActionTypes";
import { scheduleConflictMessages } from "../constants/associateSchedulingConstants";

const getPrevDay = dayOfWeek => weekDaysNames[weekDaysNames.indexOf(dayOfWeek) - 1];

const initialState = {
  allAssociates: {},
  startWeekDate: moment()
    .startOf("isoWeek")
    .format("YYYY-MM-DD"),
  allAssociatesWeeks: [],
  associateWeeks: [],
  weeklyEdit: {},
  weeklyEditChanged: false,
  lunchBreakTimes: {},
  searchValue: "",
  scheduleViolationOverride: {
    message: scheduleConflictMessages.DEFAULT_SCHEDULE_CONFLICT_MESSAGE,
    isOverride: false,
  },
};

const copyDayActivities = dayActivities =>
  flow([
    map(dayActivity => ({
      ...dayActivity,
      localId: uuidv4(),
      id: null,
      parentId: null,
    })),
    keyBy("localId"),
  ])(dayActivities);

const getEmptyDay = dayOfWeek => ({
  localId: uuidv4(),
  id: null,
  dayOfWeek,
  type: dayActivityTypes.ABSENCE,
  absenceReason: absenceReasons.DAY_OFF,
  parentId: null,
  startTime: null,
  endTime: null,
});

export default function schedulesReducer(state = initialState, action) {
  switch (action.type) {
    case schedulesActionTypes.SET_ALL_ASSOCIATES_WEEK_SCHEDULE:
      return {
        ...state,
        allAssociates: {
          ...state.allAssociates,
          ...action.activities,
        },
      };
    case schedulesActionTypes.CLEAR_ASSOCIATES_SCHEDULES:
      return {
        ...state,
        allAssociates: initialState.allAssociates,
      };

    case schedulesActionTypes.SET_ASSOCIATE_ID:
      return {
        ...state,
        associateId: action.associateId,
      };

    case schedulesActionTypes.SET_TEMPLATE_WEEK_ID:
      return {
        ...state,
        weekId: action.weekId,
      };

    case schedulesActionTypes.LOAD_TEMPLATE_SCHEDULES_SUCCESS:
      return {
        ...state,
        templateSchedules: action.templateSchedules,
      };

    case schedulesActionTypes.SET_TEMPLATE_WEEK_EDIT:
      return {
        ...state,
        weeklyEdit: get(["templateSchedules", action.weekId], state),
        weekId: action.weekId,
        weeklyEditChanged: false,
      };

    case schedulesActionTypes.GET_TEMPLATE_WEEK_EDIT:
      return {
        ...state,
        templateSchedules: set([action.weekId], get("weeklyEdit", state), state.templateSchedules),
      };

    case schedulesActionTypes.LOAD_SCHEDULES_SUCCESS:
      return state;

    case schedulesActionTypes.SET_SCHEDULES_SUCCESS:
      const weeklyEdit = mapWithKey((day, id) => set("localId", id, day), action.payload);

      return flow([
        set("weeklyEditChanged", null),
        set("weeklyEdit", keyBy("localId", weeklyEdit)),
      ])(state);

    case schedulesActionTypes.SET_WEEKLY_EDIT:
      return {
        ...state,
        weeklyEditChanged: null,
        weeklyEdit: action.dayActivities,
      };

    case schedulesActionTypes.SET_NEW_WEEKLY_EDIT_SUCCESS:
      return {
        ...state,
        weeklyEditChanged: null,
        weeklyEdit: {
          ...state.weeklyEdit,
          ...action.payload.dayActivities,
        },
      };

    case schedulesActionTypes.REMOVE_WEEKLY_EDIT:
      return {
        ...state,
        weeklyEditChanged: null,
        weeklyEdit: omit([action.payload.localId], state.weeklyEdit),
      };
    case schedulesActionTypes.CLEAR_SCHEDULES:
      return {
        ...state,
        weeklyEditChanged: null,
        weeklyEdit: null,
      };

    case schedulesActionTypes.GO_TO_ASSOCIATE_TEMPLATE:
      history.push(`/template-scheduling/${action.associateId}`);
      return state;

    case schedulesActionTypes.CLEAR_TEMPLATE_SCHEDULES:
      return {
        ...state,
        templateSchedules: null,
      };

    case schedulesActionTypes.SET_WEEKLY_EDIT_DAY_ACTIVITY:
      if ([absenceReasons.DAY_OFF].includes(action.payload.absenceReason)) {
        action.payload.startTime = "00:00:00";
        action.payload.endTime = "00:00:00";
      }

      return {
        ...state,
        weeklyEditChanged: true,
        weeklyEdit: flow([
          typeof action.payload.startTime !== "undefined"
            ? set([action.payload.localId, "startTime"], action.payload.startTime)
            : identity,
          typeof action.payload.endTime !== "undefined"
            ? set([action.payload.localId, "endTime"], action.payload.endTime)
            : identity,
          typeof action.payload.type !== "undefined"
            ? set([action.payload.localId, "type"], action.payload.type)
            : identity,
          typeof action.payload.storeId !== "undefined"
            ? set([action.payload.localId, "storeId"], action.payload.storeId)
            : identity,
          typeof action.payload.absenceReason !== "undefined"
            ? set([action.payload.localId, "absenceReason"], action.payload.absenceReason)
            : identity,
          typeof action.payload.petSupportedType !== "undefined"
            ? set([action.payload.localId, "petSupportedType"], action.payload.petSupportedType)
            : identity,
          typeof action.payload.hasChanged !== "undefined"
            ? set([action.payload.localId, "hasChanged"], action.payload.hasChanged)
            : identity,
          typeof action.payload.shiftIndex !== "undefined"
            ? set([action.payload.localId, "shiftIndex"], action.payload.shiftIndex)
            : identity,
        ])(state.weeklyEdit),
      };

    case schedulesActionTypes.COPY_PREVIOUS_DAY:
      const prevDayOfWeek = getPrevDay(action.dayOfWeek);
      const previousDay = filter({ dayOfWeek: prevDayOfWeek }, state.weeklyEdit);

      const currentDay = previousDay.map(partialDay => ({
        ...partialDay,
        localId: partialDay?.localId?.includes("_temp") ? `${uuidv4()}_temp` : uuidv4(),
        id: null,
        parentId: null,
        dayOfWeek: action.dayOfWeek,
      }));

      return {
        ...state,
        weeklyEditChanged: true,
        weeklyEdit: {
          ...keyBy("localId", reject({ dayOfWeek: action.dayOfWeek }, state.weeklyEdit)),
          ...keyBy("localId", currentDay),
        },
      };

    case schedulesActionTypes.RESET_DAY: {
      const newDay = getEmptyDay(action.dayOfWeek);
      return {
        ...state,
        weeklyEditChanged: true,
        weeklyEdit: {
          ...keyBy("localId", reject({ dayOfWeek: action.dayOfWeek }, state.weeklyEdit)),
          ...keyBy("localId", [newDay]),
        },
      };
    }

    case schedulesActionTypes.COPY_PREVIOUS_WEEK:
      const duplicatedWeek = copyDayActivities(state.templateSchedules.week1);
      return {
        ...state,
        weeklyEditChanged: true,
        templateSchedules: {
          ...state.templateSchedules,
          week2: { ...duplicatedWeek },
        },
        weeklyEdit: { ...duplicatedWeek },
      };

    case schedulesActionTypes.RESET_WEEK:
      const newWeekArray = weekDaysNames.map(dayOfWeek => getEmptyDay(dayOfWeek));

      return {
        ...state,
        weeklyEditChanged: true,
        weeklyEdit: { ...keyBy("localId", newWeekArray) },
      };

    case schedulesActionTypes.SET_START_WEEK_DATE:
      return {
        ...state,
        startWeekDate: action.startWeekDate,
      };

    case schedulesActionTypes.SET_EFFECTIVE_START_DATE:
      return {
        ...state,
        effectiveStartDate: action.effectiveStartDate,
      };

    case schedulesActionTypes.ADD_PARTIAL_DAY_RELOCATION:
      const newDay = {
        ...action.partialDayRelocation,
        localId: uuidv4(),
        type: dayActivityTypes.RELOCATION,
        absenceReason: null,
        id: null,
        parentId: null,
        isPartialDayRelocation: true,
      };

      return {
        ...state,
        weeklyEditChanged: true,
        weeklyEdit: set([newDay.localId], newDay, state.weeklyEdit),
      };

    case schedulesActionTypes.SET_DAILY_LUNCH: {
      const { dayOfWeek, startTime, endTime } = action;
      const newDay = {
        localId: uuidv4(),
        dayOfWeek,
        type: dayActivityTypes.ABSENCE,
        startTime,
        endTime,
        absenceReason: absenceReasons.LUNCH_BREAK,
        id: null,
        storeId: null,
        parentId: null,
      };

      return {
        ...state,
        weeklyEditChanged: true,
        weeklyEdit: flow([
          omitBy({
            dayOfWeek,
            absenceReason: absenceReasons.LUNCH_BREAK,
          }),
          set([newDay.localId], newDay),
        ])(state.weeklyEdit),
      };
    }

    case schedulesActionTypes.REMOVE_DAILY_LUNCH: {
      const { dayOfWeek } = action;

      return {
        ...state,
        weeklyEditChanged: true,
        weeklyEdit: omitBy(
          { dayOfWeek, absenceReason: absenceReasons.LUNCH_BREAK },
          state.weeklyEdit,
        ),
      };
    }

    case schedulesActionTypes.RESET_DAY_ACTIVITY_IDS: {
      // Take dayActivity, and clear it's id, and parentId

      const { dayActivityLocalId } = action;
      const dayActivity = state.weeklyEdit[dayActivityLocalId];

      return {
        ...state,
        weeklyEditChanged: true,
        weeklyEdit: set(
          dayActivityLocalId,
          {
            ...dayActivity,
            id: null,
            parentId: null,
          },
          state.weeklyEdit,
        ),
      };
    }

    case schedulesActionTypes.LOAD_LUNCH_BREAK_TIMES_SUCCESS: {
      return set(["lunchBreakTimes", action.storeNumber], action.lunchBreakTimes, state);
    }

    case schedulesActionTypes.SET_SCHEDULES_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.searchValue,
      };

    case schedulesActionTypes.SET_WEEKLY_EDIT_CHANGED:
      return {
        ...state,
        weeklyEditChanged: action.newWeeklyEditChanged,
      };

    case ScheduleViolationActionTypes.OVERRIDE_SCHEDULE_VIOLATION:
      return {
        ...state,
        scheduleViolationOverride: {
          isOverride: action.isOverride,
          message: action.message,
        },
      };

    default:
      return state;
  }
}
