import { connect } from "react-redux";
import moment from "moment";
import salonHoursActionTypes from "../../core/actionTypes/salonHoursActionTypes";
import { createLoadingSelector } from "../../core/selectors/utils";

import GanttComponent from "./GanttComponent";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { loadSalonHours } from "../../core/actionCreators/salonHoursActionCreators";
import { getSalonHours } from "../../core/selectors/salonHoursSelector";

import { loadAssociatesSchedule } from "../../core/actionCreators/schedulesActionCreators";
import schedulesActionTypes from "../../core/actionTypes/schedulesActionTypes";
import { getAllAssociatesSchedules } from "../../core/selectors/schedulesSelectors";
import { getWeekBeginEndDates } from "core/utils/salonHours";
import { formatCalendarDateMoment } from "core/utils/dateUtils/formatDateTime";

const mapStateToProps = state => {
  const selectedDate = getSelectedDate(state);

  return {
    isLoading: createLoadingSelector([
      salonHoursActionTypes.LOAD_SALON_HOURS,
      schedulesActionTypes.LOAD_ASSOCIATES_SCHEDULE,
    ])(state),
    selectedDate,
    salonHours: getSalonHours(state),
    allAssociateSchedules: getAllAssociatesSchedules(state),
    weekBeginEndDates: getWeekBeginEndDates(selectedDate),
  };
};

const mapDispatchToProps = dispatch => ({
  loadSalonHours: ({ beginDate, endDate }) => dispatch(loadSalonHours({ beginDate, endDate })),
  loadAssociatesSchedule: ({ fromDate }) => dispatch(loadAssociatesSchedule({ fromDate })),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  loadSalonHoursForWeek: () => {
    const { beginDate } = propsFromState.weekBeginEndDates;
    const { loadSalonHours } = propsFromDispatch;

    // Adding an additional day to the date range since the store locator API
    // returns store hours exclusive of the end date, i.e. if we specify range
    // 2021-02-08 to 2021-02-14, the hours on the 14th won't be returned.
    loadSalonHours({
      beginDate,
      endDate: formatCalendarDateMoment(
        moment(beginDate)
          .endOf("week")
          .add(1, "day"),
      ),
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(GanttComponent);
