import { get } from "lodash/fp";
import { createSelector } from "reselect";
import getJobRolesState from "./_jobRolesState";

export const getIsTestJobRolesVisible = createSelector([getJobRolesState], jobRolesState => {
  return get("isVisible", jobRolesState);
});

export const getTestJobRolesList = createSelector([getJobRolesState], jobRolesState => {
  const availableTestJobRoles = get("AvailableTestJobRoles", jobRolesState);
  const jobRoles = availableTestJobRoles
    ? availableTestJobRoles.map(item => item.accessLevel)
    : undefined;
  return jobRoles;
});
