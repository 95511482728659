import React from "react";
import { connect } from "react-redux";
import { ButtonGroup, Button, TextPassage, Heading } from "@petsmart-ui/sparky";
import { hideBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import {
  applyHotelBookingPendingChanges,
  clearHotelBookingPendingChanges,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowActions";
import {
  selectHotelBookingEndDate,
  selectHotelBookingPendingChanges,
  selectHotelBookingPetById,
  selectHotelBookingPetList,
  selectHotelBookingRooms,
  selectHotelBookingStartDate,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { restartBookingModalText } from "./bookingRestartModalConstants";
import { clearServicesCart } from "../servicesCart/servicesCartActions";
import { selectServicesCart } from "../servicesCart/servicesCartSelectors";
import { clearCartsForPets } from "../petCartCard/actions/setPetCartCardActions";

const { title, subtitle, cancel, confirm } = restartBookingModalText;

const BookingRestartModalComponent = ({ componentID, isHidden, hideModal, restartBooking }) => {
  if (isHidden) return null;

  return (
    <LayoutBox id={componentID} padding="scale-G1">
      <LayoutStack space="scale-G2">
        <LayoutStack space="scale-G1">
          <Heading size="title" tagName="h2">
            {title}
          </Heading>
          <TextPassage>{subtitle}</TextPassage>
        </LayoutStack>
        <ButtonGroup align="right">
          <Button text={cancel} onClick={hideModal} />
          <Button text={confirm} variant="primary" onClick={restartBooking} />
        </ButtonGroup>
      </LayoutStack>
    </LayoutBox>
  );
};

export const HotelBookingRestartModal = connect(
  state => ({
    componentID: "HotelBookingRestartModal",
    pendingChanges: selectHotelBookingPendingChanges(state),
    petList: selectHotelBookingPetList(state),
    startDate: selectHotelBookingStartDate(state),
    endDate: selectHotelBookingEndDate(state),
    rooms: selectHotelBookingRooms(state),
    getPet: petId => selectHotelBookingPetById(petId)(state),
    cartId: selectServicesCart(state)?.servicesCartId,
  }),
  dispatch => ({
    hideModal: () => dispatch(hideBookingModal()),
    clearPendingChanges: () => dispatch(clearHotelBookingPendingChanges()),
    applyPendingChanges: pending => dispatch(applyHotelBookingPendingChanges({ pending })),
    clearCartState: () => {
      dispatch(clearServicesCart());
      dispatch(clearCartsForPets());
    },
  }),
  (stateProps, dispatchProps) => {
    const {
      componentID,
      pendingChanges,
      petList,
      startDate,
      endDate,
      rooms,
      getPet,
      cartId,
    } = stateProps;
    const { hideModal, clearPendingChanges, applyPendingChanges, clearCartState } = dispatchProps;

    const restartBooking = () => {
      const newBookingFlowState = { ...pendingChanges };
      const hasPendingServiceChanges = petList.some(
        petId => petId in pendingChanges && !!pendingChanges[petId].petService,
      );

      // Preserve data from previous steps
      if (!pendingChanges?.petList) {
        newBookingFlowState.petList = petList;
      }
      if (!!pendingChanges?.rooms || hasPendingServiceChanges) {
        newBookingFlowState.startDate = startDate;
        newBookingFlowState.endDate = endDate;
      }
      if (hasPendingServiceChanges) {
        newBookingFlowState.rooms = rooms;
        petList.map(petId => {
          const pet = getPet(petId);
          const pendingPet = pendingChanges[petId] ?? {};
          newBookingFlowState[petId] = { ...pet, ...pendingPet };
        });
      }

      // Clear current state and start over
      applyPendingChanges(newBookingFlowState);
      if (cartId) clearCartState();
      hideModal();
    };

    return {
      componentID,
      hideModal: () => {
        hideModal();
        clearPendingChanges();
      },
      restartBooking,
    };
  },
)(BookingRestartModalComponent);
