import React from "react";
import styled from "styled-components";
import CloseButton from "../CloseButton";

const CloseButtonWrapper = styled.div`
  ${({ isRightAligned }) =>
    isRightAligned &&
    `
    display: flex;
    justify-content: flex-end;
  `};
`;

export default function DeleteAddressButtonComponent({
  isRightAligned = false,
  onClick,
  isHidden = false
}) {
  if (!isHidden) {
    return (
      <CloseButtonWrapper isRightAligned={isRightAligned}>
        <CloseButton onClick={onClick} />
      </CloseButtonWrapper>
    );
  }

  return null;
}
