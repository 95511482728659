import { all, put, takeEvery, fork, call, select } from "redux-saga/effects";
import { getOr } from "lodash/fp";
import { systemName } from "../../web/setSystemType/constants/setSystemTypeConstants";
import { petAlertsActionsTypes, updateHotelPetAlertsFailure } from "./petAlertsActions";
import { updatePetProfilePartial } from "../../core/services/systemServicesBooking/profile/petProfile";
import { updatePetSuccess } from "../../core/actionCreators/petsActionCreators";
import getPrismPetState from "../prismPet/prismPetSelector";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";

function* patchHotelPetAlerts({ alerts }) {
  try {
    const petId = yield select(getCurrentPet);
    const prismPet = yield select(getPrismPetState, { petId });
    const hotelProfile = prismPet.profiles.find(
      ({ profileType }) => profileType === systemName.DDC_HOTEL,
    );
    const response = yield call(updatePetProfilePartial, {
      customerKey: alerts.customerKey,
      petKey: alerts.petId,
      data: alerts.prismPet,
    });
    const updatedPrismPet = response.data.result.profiles.find(
      ({ profileType }) => profileType === systemName.DDC_HOTEL,
    );
    const updatedCheckOutAlert = getOr("", "checkOutAlert", updatedPrismPet);
    const updatedCheckInAlert = getOr("", "checkInAlert", updatedPrismPet);
    const updatedBookingAlert = getOr("", "bookingAlert", updatedPrismPet);
    const updatedServiceCardAlert = getOr("", "serviceCardAlert", updatedPrismPet);

    yield put(
      updatePetSuccess({
        pet: {
          petId: Number(petId),
          prismPet: {
            ...prismPet,
            profiles: [
              {
                profileType: systemName.DDC_HOTEL,
                ...hotelProfile,
                checkOutAlert: updatedCheckOutAlert,
                checkInAlert: updatedCheckInAlert,
                bookingAlert: updatedBookingAlert,
                serviceCardAlert: updatedServiceCardAlert,
              },
            ],
          },
        },
      }),
    );
  } catch (error) {
    updateHotelPetAlertsFailure({ error });
  }
}

function* watchPetAlertsSaga() {
  yield takeEvery(petAlertsActionsTypes.UPDATE_HOTEL_PET_ALERTS, patchHotelPetAlerts);
}

export default function* petAlertsSaga() {
  yield all([fork(watchPetAlertsSaga)]);
}
