import { put, call, all, select } from "redux-saga/effects";
import { getContacts } from "../../customerProfile";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getPhonesByCustomer, getEmailsByCustomer } from "@/core/selectors/entitiesSelector";
import { updateCustomerSuccess } from "@/core/actionCreators/customersActionCreators";
import {
  communicationTypeDescriptions,
  communicationTypes,
} from "@/core/constants/customerProfileConstants";
import updateCommunicationPreferences from "@/core/utils/customer/preferences/updateCommunicationPreferences";

export default function* setPreferences({ apiEndpoint, customerKey, data }) {
  const [customerPhones, customerEmails, sourceId] = yield all([
    yield select(getPhonesByCustomer, { customerKey }),
    yield select(getEmailsByCustomer, { customerKey }),
    yield select(getStoreNumber),
  ]);
  const response = yield call(apiEndpoint, {
    customerKey,
    sourceId,
    data,
  });
  const updatedCommunicationPreferences = response.data.communicationPreferences;
  const updatedContacts = getContacts(updatedCommunicationPreferences);

  yield put(
    updateCustomerSuccess({
      customer: {
        customerKey: Number(customerKey),
        phones: customerPhones.map(
          updateCommunicationPreferences({
            updatedContacts,
            communicationTypeDescription: communicationTypeDescriptions.TEXT,
            communicationTypeId: communicationTypes.TEXT,
            id: "phoneId",
          }),
        ),
        emails: customerEmails.map(
          updateCommunicationPreferences({
            updatedContacts,
            communicationTypeDescription: communicationTypeDescriptions.EMAIL,
            communicationTypeId: communicationTypes.EMAIL,
            id: "emailAddressId",
          }),
        ),
      },
    }),
  );
}
