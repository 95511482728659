import { connect } from "react-redux";
import { columnHeaders } from "core/constants/dashboardContants";
import HotelDeparturesPetCardContainer from "dux/hotelDepartures/columnList/HotelDeparturesPetCardContainer";
import ScrollablePetCardList from "../../_components/petCard/ScrollablePetCardList";
import { getHotelDeparturesAppointments } from "./hotelDeparturesSelector";
import { getCheckedOutColumnVisibility } from "@/dux/hotelCheckedOut/columnHeader/columnSwitch/hotelCheckedOutColumnToggleSelector";

const mapStateToProps = state => ({
  componentId: "scrollableCardList__hotelDepartures",
  appointments: getHotelDeparturesAppointments(state),
  isHidden: !getCheckedOutColumnVisibility(state),
  diComp: {
    [columnHeaders.DEPARTURES]: HotelDeparturesPetCardContainer,
  },
  compName: columnHeaders.DEPARTURES,
});

export default connect(mapStateToProps)(ScrollablePetCardList);
