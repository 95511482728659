import { createSelector } from "reselect";
import { getCustomer } from "@/core/selectors/entitiesSelector";

// This property can be pulled from multiple sources (i.e. GET Customer, GET Itinerary, etc)
export const getIsCustomerFTCOEligible = state => state.ftco.isCustomerFTCOEligible;

export const getFirstTimeCustomerInfo = createSelector(
  [getCustomer, getIsCustomerFTCOEligible],
  (customer, isFirstTimeCustomer) => {
    return {
      isFirstTimeCustomer,
      firstName: customer?.firstName,
    };
  },
);
