import React from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { withRouteProps } from "core/utils/routingUtils/withRouteProps";
import getIsBGMWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import {
  getAppointmentTimeSlot,
  getCurrentPetServiceId,
  getIsBundleApplied,
} from "core/selectors/cartSelectors";
import {
  getBundleOfferTypeByPetServiceId,
  getIsActiveBundleAvailable,
} from "dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import { isFromBooking } from "core/utils/validationUtils/isFromValidation";
import { get } from "lodash/fp";
import { BGM, FTCO } from "web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";
import getFTCOWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import getIsManualApplyWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/manualApply/getIsManualApplyWorkflowFeatureFlagHidden";
import ApplyBundleAvailableUnitsRemainingContainer from "../../../../bgm/availableBundlesByPet/applyAvailableBundle/ApplyBundleAvailableUnitsRemainingContainer";
import BundlePackageNameContainer from "../../../../bgm/availableBundlesByPet/applyAvailableBundle/BundlePackageNameContainer";
import {
  applyBundleComponents,
  applyBundleComponentTypes,
} from "../../../../_constants/applyBundleComponents";

/**
 * ApplyAvailableBundleComponent is a React component that displays an available bundle component
 *
 * @memberOf Views.BulkPackages
 * @function
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isHidden - Indicates whether the component should be hidden or not.
 * @param {string} props.componentId - The ID of the component.
 * @param {string} props.component - The name of the component to be rendered.
 * @returns {Object|null} - The JSX element representing the component, or null if isHidden is true.
 */
export const ApplyAvailableBundleComponent = props => {
  const { isHidden, componentId, component } = props;

  if (isHidden) {
    return null;
  }

  const Component = applyBundleComponents[component];

  return (
    <LayoutBox data-testid="ApplyAvailableBundle" id={componentId} padding="scale-0">
      <LayoutCluster style={{ justifyContent: "space-between" }}>
        <LayoutCluster>
          <BundlePackageNameContainer />
          <ApplyBundleAvailableUnitsRemainingContainer />
        </LayoutCluster>

        <Component />
      </LayoutCluster>
    </LayoutBox>
  );
};

// APPLY BGM AVAILABLE BUNDLE ---------------------->
export const ApplyBGMAvailableBundle = withRouteProps(
  connect((state, { router }) => {
    const isBGMFeatureFlagHidden = getIsBGMWorkflowFeatureFlagHidden(state);
    const petId = getSelectedPet(state);
    const petServiceId = getCurrentPetServiceId(state, { petId });
    const isBundleAvailable = getIsActiveBundleAvailable(state, { petId, petServiceId });
    const isTimeSlotAvailable = getAppointmentTimeSlot(state, { petId });
    const isBookingFlow = isFromBooking(get(["location", "pathname"], router));
    const offerType = getBundleOfferTypeByPetServiceId(state, { petId, petServiceId });

    return {
      // If we are not in the booking flow, there is no bundle available, a BGM
      // special has been applied to the cart, or an appointment has been created
      // (timeslot available), we want to hide the bundle discount.
      isHidden:
        isBGMFeatureFlagHidden ||
        offerType !== BGM ||
        !isBundleAvailable ||
        !isBookingFlow ||
        isTimeSlotAvailable,
      componentId: "ApplyBGMAvailableBundle",
      component: applyBundleComponentTypes.BUNDLE_DISCOUNT,
    };
  })(ApplyAvailableBundleComponent),
);
// APPLY BGM AVAILABLE BUNDLE <----------------------

// APPLY FTCO AVAILABLE BUNDLE ---------------------->
export const ApplyFTCOAvailableBundle = withRouteProps(
  connect((state, { router }) => {
    const isFTCOFeatureFlagHidden = getFTCOWorkflowFeatureFlagHidden(state);
    const petId = getSelectedPet(state);
    const petServiceId = getCurrentPetServiceId(state, { petId });
    const isBundleAvailable = getIsActiveBundleAvailable(state, { petId, petServiceId });
    const isTimeSlotAvailable = getAppointmentTimeSlot(state, { petId });
    const isBookingFlow = isFromBooking(get(["location", "pathname"], router));
    const offerType = getBundleOfferTypeByPetServiceId(state, { petId, petServiceId });

    return {
      // If we are not in the booking flow, there is no bundle available, a BGM
      // special has been applied to the cart, or an appointment has been created
      // (timeslot available), we want to hide the bundle discount.
      isHidden:
        isFTCOFeatureFlagHidden ||
        offerType !== FTCO ||
        !isBundleAvailable ||
        !isBookingFlow ||
        isTimeSlotAvailable,
      componentId: "ApplyFTCOAvailableBundle",
      component: applyBundleComponentTypes.BUNDLE_DISCOUNT,
    };
  })(ApplyAvailableBundleComponent),
);
// APPLY FTCO AVAILABLE BUNDLE <----------------------

// MANUAL APPLY BUNDLE ---------------------->
export const ManualApplyBundle = withRouteProps(
  connect((state, { router }) => {
    const isBGMFeatureFlagHidden = getIsBGMWorkflowFeatureFlagHidden(state);
    const isManualApplyFeatureFlagHidden = getIsManualApplyWorkflowFeatureFlagHidden(state);
    const petId = getSelectedPet(state);
    const petServiceId = getCurrentPetServiceId(state, { petId });
    const isBundleAvailable = getIsActiveBundleAvailable(state, { petId, petServiceId });
    const isBundleApplied = getIsBundleApplied(state, { petId });
    const isBookingFlow = isFromBooking(get(["location", "pathname"], router));

    return {
      // If we are in the booking flow, there is no bundle available or a bundle
      // has already been applied, we want to hide the manual apply functionality.
      isHidden:
        isBGMFeatureFlagHidden ||
        isManualApplyFeatureFlagHidden ||
        isBookingFlow ||
        !isBundleAvailable ||
        isBundleApplied,
      componentId: "ManualApplyBundle",
      value: "Apply",
      component: applyBundleComponentTypes.MANUAL_APPLY,
    };
  })(ApplyAvailableBundleComponent),
);
// MANUAL APPLY BUNDLE <----------------------
