import { connect } from "react-redux";
import {
  getVaccinationToDelete,
  getCustomerAndPetKeys,
} from "../../../../../../../core/selectors/ui/vaccinations/vaccinationsSelectors";
import petProfileActionCreators from "../../../../../../../core/actionCreators/petProfileActionCreators";
import vaccinationActionCreators from "../../../../../../../core/actionCreators/petVaccinationsActionCreators";
import vaccinationActionTypes from "../../../../../../../core/actionTypes/petVaccinationsActionTypes";
import { createLoadingSelector } from "../../../../../../../core/selectors/utils";
import DeleteVaccinationModalComponent from "./DeleteVaccinationModalComponent";

const mapStateToProps = (state, { onModalClose }) => ({
  vaccinationToDelete: getVaccinationToDelete(state),
  customerAndPetKeys: getCustomerAndPetKeys(state),
  isLoading: createLoadingSelector([vaccinationActionTypes.DELETE_PET_VACCINATION])(state),
  onModalClose
});

const mapDispatchToProps = dispatch => ({
  handleRemove(vaccinationToRemove, customerAndPetKeys) {
    dispatch(
      vaccinationActionCreators.deletePetVaccination({
        customerKey: customerAndPetKeys.customerKey,
        petId: customerAndPetKeys.petId,
        vaccinationId: vaccinationToRemove,
      }),
    );
  },
  closeModal() {
    dispatch(vaccinationActionCreators.clearCustomerAndPetKeys());
    dispatch(vaccinationActionCreators.clearVaccinationToDelete());
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClose: () => {
    const { closeModal } = propsFromDispatch;
    const { onModalClose } = propsFromState;

    closeModal();
    onModalClose();
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DeleteVaccinationModalComponent);
