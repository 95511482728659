import { createSelector } from "reselect";
import { selectServiceSuspensionReasons } from "dux/hotelSuspensionReasons/hotelSuspensionReasonsSelectors";
import { map } from "lodash/fp";

/**
 * Retrieves the selected suspension type for a hotel from the state.
 *
 * @param {Object} state - The state object containing the hotel information.
 * @returns {Object} - The selected suspension type, or undefined if not available.
 */
export const selectHotelSelectedSuspensionType = state => state?.hotelSelectedSuspensionType;

/**
 * A function expression that creates a selector for generating a list of suspension type names.
 *
 * @memberof Views.ScheduledSuspensions
 * @function
 * @param {Function} getServiceSuspensionReasons - The selector function to get the service suspension reasons.
 * @returns {Function} - The selector function that generates the list of suspension types.
 */
export const selectListOfSuspensionTypes = createSelector(
  [selectServiceSuspensionReasons],
  serviceSuspensionReasons => {
    return map(item => {
      return item?.reasonTypeName;
    }, serviceSuspensionReasons);
  },
);

/**
 * This function expression that creates a selector for listing suspension types from service suspension reasons.
 *
 * @memberof Views.ScheduledSuspensions
 * @function
 * @param {Function} getServiceSuspensionReasons - The function that retrieves the service suspension reasons.
 * @returns {Function} - The selector function that selects suspension types from service suspension reasons.
 */
export const selectSuspensionTypes = createSelector(
  [selectServiceSuspensionReasons],
  serviceSuspensionReasons => {
    return map(item => {
      return {
        suspensionTypeId: item?.suspensionTypeId,
        reasonTypeName: item?.reasonTypeName,
      };
    }, serviceSuspensionReasons);
  },
);



/**
 * Determines if a suspension is selected based on the selected suspension type and IDs (suspensionTypeId, suspensionId)
 *
 * @memberof Views.ScheduledSuspensions
 * @function selectIsSuspensionSelected
 * @param {Object} state - The current state object.
 * @param {Object} iDs - The suspension type and suspension IDs. ((suspensionTypeId, suspensionId)
 *
 * @returns {boolean} Returns true if a suspension is selected, false otherwise.
 * @example
 * // connect context
 * const isSelected = selectIsSuspensionSelected(state, { suspensionTypeId, suspensionId });
 */
export const selectIsSuspensionSelected = createSelector(
  [selectHotelSelectedSuspensionType, (state, iDs) => iDs],
  (selectedSuspension, iDs) => {
    /*
      NOTE: we use the Nullish coalescing operator because suspensionTypeId or suspensionId could
      come in undefined, but so can the properties of selectedSuspension, so this will give a false
      positive and thus by using the Nullish coalescing operator here we can set the right side to
      something other than undefined or an empty string thus the two sides will not falsely match.
     */

    return (
      selectedSuspension?.suspensionTypeId === (iDs?.suspensionTypeId ?? "0") ||
      selectedSuspension?.suspensionId === (iDs?.suspensionId ?? "0")
    );
  },
);
