import { createSelector } from "reselect";
import {
  getHotelItinerary,
  selectItineraryInvoiceIsPaid,
} from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { selectPetNeedsReview } from "@/dux/hotelCheckInOutPetNeedsReview/hotelCheckInOutPetNeedsReviewSelectors";
import { getCheckInOutPetMeds } from "@/dux/_components/checkInOutMedications/CheckInOutCartMedsSelectors";
import { getHotelEngagementsPets } from "@/dux/hotelEngagements/hotelEngagementSelectors";
import {
  getPrismCustomerHotelProfile,
  getPrismCustomerProfile,
} from "@/web/petParentProfile/selectors/getPrismCustomerProfileSelector";
import getPrismPetHotelProfile from "@/dux/petAlerts/selectors/PrismPetProfilesSelectors";
import { isCat, isPuppy, isSenior } from "@/core/utils/iconUtils/iconUtils";
import { getPet } from "@/core/selectors/entitiesSelector";
import { hotelPetRatings } from "@/dux/_constants/petRatingConstants";
import isEmpty from "lodash/isEmpty";
import { getVaccinationsList } from "@/core/selectors/enumsSelectors";
import { beddingTypes } from "@/dux/hotelBeddingTypes/hotelBeddingTypesConstants";
import { getIsMissingVaccination } from "./hotelIconUtils";
import { selectCheckInOutDateTimeCommonState } from "../hotelCheckInOutDateTimeCart/hotelCheckInOutSelectors";
import { formatCalendarDateMoment } from "@/core/utils/dateUtils/formatDateTime";

export const selectHotelProfilePetCardIconInfo = createSelector(
  [
    getPrismCustomerHotelProfile,
    getPrismPetHotelProfile,
    getPrismCustomerProfile,
    getPet,
    getVaccinationsList,
  ],
  (customerHotelProfile, petHotelProfile, prismCustomer, pet, vaccinationList) => {
    if (!prismCustomer || !pet) return {};

    const { psaOnFile, isVip } = prismCustomer;
    const { speciesId, prismPet: { isIndividual, beddingType } = {} } = pet;

    return {
      isNewCustomer: customerHotelProfile?.isNewCustomer ?? petHotelProfile?.isNewCustomer,
      hasPSA: psaOnFile,
      isSenior: isSenior(pet?.birthDate),
      isPuppy: isPuppy(pet),
      isCat: isCat(speciesId),
      isVIP: isVip,
      isIndividual,
      hasNoBed: beddingType === beddingTypes.NO_BEDDING,
      hasStatusAlert:
        petHotelProfile?.petRating === hotelPetRatings.CAUTION ||
        petHotelProfile?.petRating === hotelPetRatings.NOT_GOOD_FIT,
      hasHealthCondition: !isEmpty(pet?.healthConditions),
      isMissingVaccinations: getIsMissingVaccination(pet, vaccinationList),
      speciesId,
    };
  },
);

/**
 * Selector to check if a given pet's vaccinations will expire before their check out date
 * @param {Object} pet
 * @param {Boolean} vaccinationList
 * @param {string?} expiredByDate - optional, if not defined the todays date will be used
 * @returns boolean
 */
export const selectHotelItineraryPetIsMissingVaccinations = createSelector(
  [selectCheckInOutDateTimeCommonState, getPet, getVaccinationsList],
  ({ endDateTime, storeTimeZone }, pet, vaccinationList) => {
    const checkOutDate = formatCalendarDateMoment(endDateTime, storeTimeZone);
    return pet && getIsMissingVaccination(pet, vaccinationList, checkOutDate);
  },
);

export const selectHotelItineraryPetCardIconInfo = createSelector(
  [
    getHotelItinerary,
    selectPetNeedsReview,
    selectItineraryInvoiceIsPaid,
    getCheckInOutPetMeds,
    getHotelEngagementsPets,
    selectHotelItineraryPetIsMissingVaccinations,
  ],
  (itinerary, needsReview, invoicePaid, petMeds, petList, isMissingVaccinations) => {
    const { hasDoggieValet, hasSalonAppointment, hasVetFee, isNewCustomer } =
      itinerary?.metadata ?? {};

    return {
      isNewCustomer,
      receiveMedications: !!petMeds?.length,
      invoicePaid,
      hasSalonAppointment,
      hasVetFee,
      hasDoggieValet,
      isGroupReservation: petList?.length > 1,
      needsReview,
      isMissingVaccinations,
    };
  },
);
