/**
 *  Base values for any CSS type measurement, such as font size, line height etc.
 *  All px values should derive from here.
 */
const ValuesInPixels = {
  tiny: 12,
  small: 14,
  base: 16,
  regular: 18,
  big: 20,
  extraBig: 24,
  large: 28,
  extraLarge: 32,
  magnificent: 36,
  fantastic: 38,
  godlike: 42,
};

export default ValuesInPixels;
