import { combineReducers } from "redux";
import { get } from "lodash/fp";
import {
  ADD_GROOMING_NOTE_SUCCESS,
  ADD_SALON_CUSTOMER_NOTE_SUCCESS,
  UPDATE_APPOINTMENT_NOTE_SUCCESS,
} from "core/actionCreators/notesActionCreator";
import serviceStatusesReducer from "./serviceStatusesReducer";
import createEntitiesReducer from "../utils/createEntitiesReducer";

import petServiceItemsActionTypes from "../actionTypes/petServiceItemsActionTypes";
import engagementsActionTypes from "../actionTypes/engagementsActionTypes";
import itinerariesActionTypes from "../actionTypes/itinerariesActionTypes";
import petsActionTypes from "../actionTypes/petsActionTypes";
import associateActionTypes from "../actionTypes/associateActionTypes";
import customersActionTypes from "../actionTypes/customersActionTypes";
import addOnsActionTypes from "../actionTypes/addOnsActionTypes";
import servicesSelectionActionTypes from "../actionTypes/servicesSelectionActionTypes";
import absencesActionTypes from "../actionTypes/absencesActionTypes";
import specialsActionTypes from "../actionTypes/specialsActionTypes";
import salonActionTypes from "../actionTypes/salonActionTypes";
import absenceReasonsReducer from "./absenceReasonsReducer";
import priceAdjustmentReasonsReducer from "./priceAdjustmentReasonsReducer";
import priceAdjustmentActionTypes from "../actionTypes/priceAdjustmentActionTypes";
import schedulesActionTypes from "../actionTypes/schedulesActionTypes";
import salonsActionTypes from "../actionTypes/salonsActionTypes";
import salonHoursActionTypes from "../actionTypes/salonHoursActionTypes";
import voidTransactionActionTypes from "../actionTypes/voidTransactionActionTypes";
import maxPerBlockOrCheckReductionReasonsReducer from "./maxPerBlockOrCheckReductionReasonsReducer";

const entitiesReducer = combineReducers({
  customers: createEntitiesReducer({
    loadOneAction: [
      customersActionTypes.LOAD_CUSTOMER_SUCCESS,
      customersActionTypes.CREATE_CUSTOMER_SUCCESS,
    ],
    loadManyAction: customersActionTypes.LOAD_CUSTOMERS_SUCCESS,
    updateAction: [customersActionTypes.UPDATE_CUSTOMER_SUCCESS, ADD_SALON_CUSTOMER_NOTE_SUCCESS],
    idProperty: "customerKey",
  }),
  pets: createEntitiesReducer({
    loadOneAction: [petsActionTypes.LOAD_PET_SUCCESS, petsActionTypes.CREATE_PET_SUCCESS],
    loadManyAction: [
      petsActionTypes.LOAD_PETS_SUCCESS,
      petsActionTypes.LOAD_PETS_BY_CUSTOMER_SUCCESS,
    ],
    updateAction: [petsActionTypes.UPDATE_PET_SUCCESS, ADD_GROOMING_NOTE_SUCCESS],
    idProperty: "petId",
  }),

  petServiceItems: createEntitiesReducer({
    loadOneAction: petServiceItemsActionTypes.LOAD_PET_SERVICE_ITEM,
    loadManyAction: petServiceItemsActionTypes.LOAD_PET_SERVICE_ITEMS,
    deleteManyAction: petServiceItemsActionTypes.DELETE_PET_SERVICE_ITEMS,
    idProperty: "petServiceItemId",
    mergeStrategy: (oldPetServiceItem, newPetServiceItem) => {
      const merged = {
        ...oldPetServiceItem,
        ...newPetServiceItem,
      };

      const oldHistory = get("history", oldPetServiceItem);
      const newHistory = get("history", newPetServiceItem);

      if (!oldHistory) {
        return merged;
      }

      if (!newHistory || oldHistory.length > newHistory.length) {
        merged.history = [...oldHistory];
      }

      return merged;
    },
  }),

  engagements: createEntitiesReducer({
    loadOneAction: engagementsActionTypes.LOAD_ENGAGEMENT,
    loadManyAction: engagementsActionTypes.LOAD_ENGAGEMENTS,
    deleteManyAction: engagementsActionTypes.DELETE_ENGAGEMENTS,
    idProperty: "engagementId",
  }),
  itineraries: createEntitiesReducer({
    loadOneAction: [
      itinerariesActionTypes.LOAD_ITINERARY_SUCCESS,
      itinerariesActionTypes.CREATE_ITINERARY_SUCCESS,
      engagementsActionTypes.CREATE_ENGAGEMENT_SUCCESS,
      addOnsActionTypes.PUT_ADDONS_SUCCESS,
      specialsActionTypes.POST_SPECIALS_SUCCESS,
      specialsActionTypes.REMOVE_SPECIALS_SUCCESS,
      petServiceItemsActionTypes.UPDATE_PET_SERVICE_ITEM_SUCCESS,
      itinerariesActionTypes.FINALIZE_ITINERARY_SUCCESS,
      engagementsActionTypes.UPDATE_ENGAGEMENT_STATUS_SUCCESS,
      itinerariesActionTypes.UPDATE_ITINERARY_STATUS_SUCCESS,
      itinerariesActionTypes.UPDATE_ITINERARY_PAYMENT_STATUS_SUCCESS,
      priceAdjustmentActionTypes.UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON_SUCCESS,
      engagementsActionTypes.UNDO_LAST_ENGAGEMENT_STATUS_UPDATE_SUCCESS,
      itinerariesActionTypes.UNDO_LAST_ITINERARY_STATUS_UPDATE_SUCCESS,
      voidTransactionActionTypes.VOID_TRANSACTION_SUCCESS,
      UPDATE_APPOINTMENT_NOTE_SUCCESS,
    ],
    loadManyAction: [
      itinerariesActionTypes.LOAD_ITINERARIES_SUCCESS,
      itinerariesActionTypes.LOAD_ITINERARIES_BY_CUSTOMER_SUCCESS,
    ],
    deleteAction: itinerariesActionTypes.DELETE_ITINERARY_SUCCESS,
    idProperty: "itineraryId",
  }),

  associates: createEntitiesReducer({
    loadOneAction: associateActionTypes.UPDATE_ASSOCIATE_SUCCESS,
    loadManyAction: associateActionTypes.LOAD_ASSOCIATES_SUCCESS,
    deleteAll: associateActionTypes.CLEAR_ASSOCIATES,
    idProperty: "associateId",
  }),
  addOns: createEntitiesReducer({
    loadManyAction: addOnsActionTypes.LOAD_ADDONS_SUCCESS,
    idProperty: "addOnId",
  }),
  enhancedServices: createEntitiesReducer({
    loadManyAction: addOnsActionTypes.LOAD_ENHANCED_SERVICES_SUCCESS,
    idProperty: "addOnId",
  }),
  servicesForPet: createEntitiesReducer({
    updateAction: servicesSelectionActionTypes.UPDATe_STORE_SERVICE_FOR_PET_SUCCESS,
    loadManyAction: [
      servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_PET_SUCCESS,
      servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET_SUCCESS,
      servicesSelectionActionTypes.LOAD_STORE_SERVICES,
    ],

    idProperty: "petServiceId",
  }),
  absences: createEntitiesReducer({
    loadManyAction: absencesActionTypes.SET_ABSENCES,
    idProperty: "absenceId",
  }),
  salon: createEntitiesReducer({
    loadOneAction: salonActionTypes.LOAD_SALON_INFO_WITH_HOURS_SUCCESS,
    loadManyAction: salonActionTypes.LOAD_SALONS_INFO_WITH_HOURS_SUCCESS,
    idProperty: "StoreNumber",
  }),
  specials: createEntitiesReducer({
    loadManyAction: specialsActionTypes.LOAD_SPECIALS_SUCCESS,
    idProperty: "code",
  }),
  dayActivities: createEntitiesReducer({
    loadManyAction: [
      schedulesActionTypes.LOAD_ASSOCIATES_SCHEDULE_SUCCESS,
      schedulesActionTypes.SET_SCHEDULES_SUCCESS,
    ],
    idProperty: "id",
  }),
  salons: createEntitiesReducer({
    loadOneAction: salonHoursActionTypes.LOAD_RELOCATED_SALON_HOURS_SUCCESS,
    loadManyAction: salonsActionTypes.LOAD_ALL_SALONS_SUCCESS,
    idProperty: "StoreNumber",
  }),
  employeeGroups: createEntitiesReducer({
    loadManyAction: [associateActionTypes.LOAD_EMPLOYEE_GROUPS_SUCCESS],
    idProperty: "id",
  }),
  statuses: serviceStatusesReducer,
  // cancellationReasons: cancellationReasonsReducer,
  absenceReasons: absenceReasonsReducer,
  priceAdjustmentReasons: priceAdjustmentReasonsReducer,
  maxPerBlockOrCheckReductionReasons: maxPerBlockOrCheckReductionReasonsReducer,
});

export default entitiesReducer;
