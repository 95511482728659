import React from "react";
import FutureAppointmentsListBGMContainer from "../FutureAppointmentsList/FutureAppointmentsListBGMContainer";
import PastAppointmentsListBGMContainer from "../PastAppointmentsList/PastAppointmentsListBGMContainer";
import NoAppointmentsBGMContainer from "../common/NoAppointmentsBGMContainer";

const AppointmentHistory = ({ isHidden, componentID }) => {
  if (!isHidden) {
    return (
      <div id={componentID}>
        <FutureAppointmentsListBGMContainer />
        <PastAppointmentsListBGMContainer />
        <NoAppointmentsBGMContainer />
      </div>
    );
  }

  return null;
};

export default AppointmentHistory;
