import React from "react";
import { BundleHistoryCard } from "../../../bgm/bundleHistory/BundleHistoryCard";
import { LayoutStack } from "@/layout/stack/Stack";

const BundleHistoryComponent = ({ isHidden, componentID, bundleHistoryList }) => {
  return (
    !isHidden && (
      <LayoutStack id={componentID} space="scale-G1">
        {bundleHistoryList.map(({ bundleNumber }) => (
          <BundleHistoryCard key={bundleNumber} id={bundleNumber} />
        ))}
      </LayoutStack>
    )
  );
};

export default BundleHistoryComponent;
