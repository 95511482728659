import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get the available time slots for the specified pet service at the specified store.
 * From the System Services Booking API
 * - Get availability of pet service at a store
 * @param {*} { storeNumber, ...params }
 * @returns {*} AxiosPromise
 */
export function fetchQuickQuoteServicesAtStore({ storeNumber, ...params }) {
  return bookingAPI.get(`${endpointVersionString()}/stores/${storeNumber}/petservices`, {
    params
  });
}
