import salonHoursActionTypes from "../../core/actionTypes/salonHoursActionTypes";

const initialState = {};

const salonHoursReducer = (state = initialState, action) => {
  switch (action.type) {
    case salonHoursActionTypes.LOAD_SALON_HOURS_SUCCESS:
      const newState = { ...state };

      Object.keys(action.salonHours).forEach(date => {
        const oldAppointments =
          newState[date] && newState[date].petServiceItems ? newState[date].petServiceItems : [];
        const newAppointments =
          action.salonHours[date] && action.salonHours[date].petServiceItems
            ? action.salonHours[date].petServiceItems
            : [];
        newState[date] = {
          ...newState[date],
          ...action.salonHours[date],
          petServiceItems: Array.from(new Set([...oldAppointments, ...newAppointments])),
        };
      });

      return newState;

    default:
      return state;
  }
};

export default salonHoursReducer;
