import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  EditableSection,
  Button,
  ButtonGroup,
  Box,
  LoadingIndicator,
  SparkyTextWrapper,
} from "@petsmart-ui/sparky";
import { Layout, Text } from "@prism/psm-ui-components";
import getUnknownPetImage from "core/utils/assetUtils/unknownPetImage";
import {
  setHotelBookingFlowPets,
  setHotelBookingPendingChanges,
} from "web/features/hotelBookingFlow/hotelBookingFlowActions";
import { isEmpty, pull } from "lodash/fp";
import xor from "lodash/fp/xor";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getIsSRCAgent } from "core/selectors/persistentSelectors";
import {
  commonHotelBookingStepActions,
  hotelBookingFlowSparkyBoxStyle,
} from "web/features/hotelBookingFlow/hotelBookingFlowUtils";
import {
  selectCommonHotelBookingStepData,
  getBookablePetsForTabsList,
  selectHotelBookingPetList,
  selectShouldRestartToEdit,
} from "web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import StyledPopover from "@/web/common/StyledPopover";
import { petTooltipMessages } from "./hotelBookingPetSelectionConstants";
import { createLoadingSelector } from "@/core/selectors/utils";
import { GET_HOTEL_ELIGIBILITY } from "../hotelEligibility/hotelEligibilityActions";
import petsActionTypes from "@/core/actionTypes/petsActionTypes";
import { showBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { hotelBookingTypes } from "@/web/hotelAlerts/hotelBookingConstants";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { selectActiveHotelEngagementsPets } from "../hotelEngagements/hotelEngagementSelectors";
import { selectIsRebookingItinerary } from "../hotelItinerary/hotelItinerarySelectors";

export const PetSelectionCard = ({ pet, onClick, isSelected, disabled, tooltipMessage }) => {
  const [showTootlip, setShowTooltip] = useState(false);

  return (
    <StyledPopover
      body={
        <Layout.Box
          style={{
            padding: "10px",
          }}
        >
          <Text>{tooltipMessage}</Text>
        </Layout.Box>
      }
      isOpen={showTootlip}
    >
      <Box
        variant={disabled && "gray"}
        onClick={e => !disabled && onClick(e)}
        style={{
          ...hotelBookingFlowSparkyBoxStyle({ disabled, isSelected }),
          width: "48%",
          margin: "2% 0%",
        }}
        onMouseEnter={() => setShowTooltip(disabled && !!tooltipMessage)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <Layout.Cluster justify="left">
          <img
            style={{ width: "50px", height: "50px", marginRight: "20px" }}
            alt="Pet"
            src={getUnknownPetImage(pet)}
          />
          <Text size="text-size-lg" bold>
            {pet?.petName}
          </Text>
        </Layout.Cluster>
      </Box>
    </StyledPopover>
  );
};

const HotelBookingPetSelectionComponent = ({
  onContinue,
  isHidden,
  pets,
  setHotelBookingPets,
  componentID,
  canNavigate,
  isActive,
  onClick,
  isLoading,
  currentPetList = [],
  shouldRestartToEdit,
  descriptionText = "",
  isRebooking,
  itineraryPets,
  savePendingAndShowModal = () => {},
}) => {
  const [petList, setPetList] = useState(currentPetList);

  useEffect(() => {
    // If currentPetList was updated and petList no longer matches then update it
    if (!!xor(petList, currentPetList).length) setPetList(currentPetList);
  }, [currentPetList]);

  useEffect(() => {
    // If rebooking, then add pets from itinerary to booking state
    if (isRebooking && !!itineraryPets?.length && isActive) {
      setHotelBookingPets({ petList: itineraryPets });
      onContinue();
    }
  }, [itineraryPets]);

  const onPetCardClick = petId => {
    const newPetList = isSelected(petId) ? pull(petId, petList) : [...petList, petId];

    if (shouldRestartToEdit) {
      savePendingAndShowModal({ petList: newPetList });
      return;
    }

    setPetList(newPetList);
  };

  const isSelected = petId => {
    return petList.includes(petId);
  };

  const description = () => {
    if (isActive) return;
    return <SparkyTextWrapper truncateOn={1}>{descriptionText}</SparkyTextWrapper>;
  };

  if (isLoading || isEmpty(pets)) {
    return (
      <EditableSection
        buttonText={canNavigate && "Edit"}
        isActive={isActive}
        onClick={onClick}
        title="Pet Selection"
        description={description()}
      >
        <Layout.Cluster justify="center">
          <LoadingIndicator iconSize="md" />
        </Layout.Cluster>
      </EditableSection>
    );
  }

  if (!isHidden) {
    return (
      <EditableSection
        buttonText={canNavigate && "Edit"}
        isActive={isActive}
        onClick={onClick}
        title="Pet Selection"
        description={description()}
      >
        <Layout.Box id={componentID}>
          <Layout.Cluster justify="space-between">
            {isActive &&
              Object.values(pets)?.map(pet => (
                <PetSelectionCard
                  key={pet?.petId}
                  pet={pet}
                  disabled={pet?.disabled}
                  tooltipMessage={pet?.tooltipMessage}
                  isSelected={isSelected(pet?.petId)}
                  onClick={() => {
                    onPetCardClick(pet?.petId);
                  }}
                />
              ))}
          </Layout.Cluster>
          <ButtonGroup align="right">
            <Button
              variant="primary"
              disabled={petList.length === 0}
              onClick={() => {
                setHotelBookingPets({
                  petList,
                });
                onContinue();
              }}
              text="Next"
            />
          </ButtonGroup>
        </Layout.Box>
      </EditableSection>
    );
  }

  return null;
};

export const HotelBookingPetSelection = compose(
  withRouteProps,
  connect(
    (state, { stepIndex, router: { params } }) => {
      const customerKey = getCurrentCustomerKey(state);
      const pets = Object.values(getBookablePetsForTabsList(state, { customerKey }));
      const isSRCAgent = getIsSRCAgent(state);
      const { isActive, canNavigate, openStep, furthestStep } = selectCommonHotelBookingStepData(
        stepIndex,
      )(state);
      const currentPetList = selectHotelBookingPetList(state);
      const petNames = currentPetList?.map(
        petId => pets.find(pet => pet?.petId === petId)?.petName,
      );
      const itineraryId = params?.itineraryId;

      return {
        pets: pets.map(pet => {
          const doNotBookAndSRC = pet?.doNotBook && isSRCAgent;
          const petRatingMessage =
            doNotBookAndSRC && `${petTooltipMessages.ratingTooLow} ${pet?.petRatingReason}`;

          return {
            ...pet,
            disabled: doNotBookAndSRC,
            tooltipMessage: petRatingMessage,
          };
        }),
        isLoading: createLoadingSelector([GET_HOTEL_ELIGIBILITY, petsActionTypes.UPDATE_PET])(
          state,
        ),
        componentID: "hotelBookingFlow-petSelection",
        canNavigate,
        isActive,
        shouldRestartToEdit: selectShouldRestartToEdit(state),
        currentPetList,
        openStep,
        furthestStep,
        descriptionText: petNames.join(", "),
        // Rebooking data
        isRebooking: selectIsRebookingItinerary(state, { itineraryId }),
        itineraryPets: selectActiveHotelEngagementsPets(state),
      };
    },
    (dispatch, { stepIndex }) => {
      const { setStep, onContinue } = commonHotelBookingStepActions(dispatch);
      return {
        dispatchHotelBookingPets: ({ petList }) => {
          dispatch(setHotelBookingFlowPets({ pets: petList }));
        },
        savePendingAndShowModal: ({ petList }) => {
          dispatch(
            setHotelBookingPendingChanges({
              petList,
              openStep: stepIndex,
              furthestStep: stepIndex,
            }),
          );
          dispatch(showBookingModal(hotelBookingTypes.HOTEL_BOOKING_RESTART_MODAL));
        },
        setStep,
        onContinue,
      };
    },
    (mapProps, dispatchProps, { stepIndex }) => {
      const {
        pets,
        componentID,
        canNavigate,
        isActive,
        isLoading,
        shouldRestartToEdit,
        currentPetList,
        openStep,
        furthestStep,
        descriptionText,
        isRebooking,
        itineraryPets,
      } = mapProps;
      const {
        dispatchHotelBookingPets,
        savePendingAndShowModal,
        setStep,
        onContinue,
      } = dispatchProps;

      return {
        // control what props get passed to the view
        pets,
        onContinue: () => onContinue({ openStep, furthestStep }),
        setHotelBookingPets: petList => dispatchHotelBookingPets({ petList }),
        componentID,
        canNavigate,
        isActive,
        onClick: () => setStep(stepIndex),
        isLoading,
        currentPetList,
        shouldRestartToEdit,
        savePendingAndShowModal,
        descriptionText,
        isRebooking,
        itineraryPets,
      };
    },
  ),
)(HotelBookingPetSelectionComponent);
