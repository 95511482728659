import { hotelDeparturesActionTypes } from "./hotelDeparturesActions";
import { hotelDeparturesHeaderSearchActionTypes } from "../columnHeader/search/hotelDeparturesHeaderSearchActions";
import { hotelDeparturesFilterButtonActionTypes } from "../columnHeader/filterButtons/hotelDeparturesFilterButtonActions";

const initialState = {
  appointments: [],
  searchText: "",
  filterBy: {
    overnight: true,
    dayCamp: true,
    dayCare: true,
    all: true,
    attentionNeeded: true,
    unassignedPet: true,
  },
};

const hotelDeparturesReducer = (state = initialState, action) => {
  switch (action.type) {
    case hotelDeparturesActionTypes.CLEAR_HOTEL_DEPARTURES:
      return initialState;
    case hotelDeparturesActionTypes.LOAD_HOTEL_DEPARTURES_SUCCESS:
      return {
        ...state,
        appointments: action.departures,
      };
    case hotelDeparturesHeaderSearchActionTypes.SET_HOTEL_DEPARTURES_SEARCH:
      return {
        ...state,
        searchText: action.value,
      };
    case hotelDeparturesHeaderSearchActionTypes.CLEAR_HOTEL_DEPARTURES_SEARCH:
      return {
        ...state,
        searchText: "",
      };
    case hotelDeparturesFilterButtonActionTypes.FILTER_BY_OVERNIGHT_DEPARTURES:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          overnight: action.filterBtnStatus,
        },
      };
    case hotelDeparturesFilterButtonActionTypes.FILTER_BY_DAY_CAMP_DEPARTURES:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          dayCamp: action.filterBtnStatus,
        },
      };
    case hotelDeparturesFilterButtonActionTypes.FILTER_BY_DAY_CARE_DEPARTURES:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          dayCare: action.filterBtnStatus,
        },
      };
    case hotelDeparturesFilterButtonActionTypes.FILTER_BY_All_DEPARTURES:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          all: action.filterBtnStatus,
        },
      };
    case hotelDeparturesFilterButtonActionTypes.FILTER_BY_ATTENTION_NEEDED_DEPARTURES:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          attentionNeeded: action.filterBtnStatus,
        },
      };
    case hotelDeparturesFilterButtonActionTypes.FILTER_BY_UNASSIGNED_PET_DEPARTURES:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          unassignedPet: action.filterBtnStatus,
        },
      };
    default:
      return state;
  }
};

export default hotelDeparturesReducer;
