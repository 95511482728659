import { associateWebAPI } from "./_axiosConfigAssociateWeb";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get Customer's Past, Present, and Future Appointments
 *
 * @param {Object} args
 * @param {string} args.customerKey - The customer key to fetch appointment history for
 * @param {string} args.fromDate - UTC formatted from date
 * @param {string} args.toDate - UTC formatted to date
 * @param {number} args.page - The desired results page to get, zero based index
 * @param {string} args.size - Number of results to return per page
 * @param {string} args.sort - Sort order for Itinerary Effective Date
 * @param {string} args.storeServiceType - Comma separated list of store service types
 * @param {string} args.productFamilyID - Comma separated list of product family IDs
 * @returns {*} AxiosPromise
 */
export function getCustomerAppointmentHistory({
  customerKey,
  fromDate,
  toDate,
  page,
  size,
  sort,
  storeServiceType,
  productFamilyID,
}) {
  return associateWebAPI.get(
    `${endpointVersionString()}/profile/customers/${customerKey}/appointmentHistory`,
    {
      params: { fromDate, toDate, page, size, sort, storeServiceType, productFamilyID },
    },
  );
}
