import moment from "moment";
import { DATE_TIME_FORMAT } from "../../constants";

/** @module ISODateWithTime */

/**
 * Attempts to take a date, add hours to that date and return the formatted date.
 *
 * @export getISODateWithTime
 * @param {string} date ISO formatted date
 * @param {string} hours value to add to the date.
 * @returns {string} format date
 * @example
 * - getISODateWithTime ('2019-01-25T00:00:00', '08:00:00')
 */
export default function getISODateWithTime(date, hours) {
  return moment(date)
    .add(moment.duration(hours, "hours"))
    .format(DATE_TIME_FORMAT);
}
