import React from "react";
import styled from "styled-components";
import Table from "../../common/Table/Table";

export default function PrintSchedulesComponent({ print }) {
  return (
    <PrintSchedulesTable>
      <h1>Associate Scheduling</h1>
      <Table columns={print.columns} rows={print.rows} />
    </PrintSchedulesTable>
  );
}

const PrintSchedulesTable = styled.div`
  display: none;
  table,
  th,
  td {
    white-space: pre-line;
    border-collapse: collapse;
    border: 1px solid #636363;
    padding-right: 8px;
    padding-left: 8px;
  }

  /* header */
  th {
    font-weight: bold;
  }

  /* last column */
  td:last-child {
    font-weight: bold;
    width: 50px;
  }

  /* center every cell in the body except the first */
  td:not(:first-child) {
    text-align: center;
  }

  /* bold first element in each cell*/
  td {
    p:first-child {
      font-weight: bold;
    }
  }

  tr.group-header td {
    background-color: #bdbdbd;
    line-height: 40px;
    border: 0;
  }

  tr.group-subtotal {
    font-weight: bold;
  }

  tr.total-row {
    font-weight: bold;
  }

  @media print {
    font-size: 12px;
    display: initial;
    @page {
      size: landscape;
    }
  }
`;
