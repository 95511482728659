import { connect } from "react-redux";
import PaidVoidTransactionComponent from "./PaidVoidTransactionComponent";
import { getCurrentItinerary } from "core/selectors/checkInOutSelector";
import { getItinerary } from "core/selectors/entitiesSelector";

const mapStateToProps = state => {
  const itineraryId = getCurrentItinerary(state);
  const itinerary = getItinerary(state, { itineraryId });
  const itineraryStatus = itinerary && itinerary.status;

  return {
    componentId: "PaidVoidTransaction",
    itineraryStatus,
  };
};
export default connect(mapStateToProps)(PaidVoidTransactionComponent);
