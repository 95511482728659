import { put, takeEvery, call, all, select } from "redux-saga/effects";
// Services
import { fetchHotelOverBookingExceptions } from "@/core/services/associateWebBff/hotelOverbookingExceptions";

// Actions
import {
  GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS,
  getOverBookingExceptionSuspensionDetailsRequest,
  getOverBookingExceptionSuspensionDetailsSuccess,
  getOverBookingExceptionSuspensionDetailsFailure,
  CANCEL_OVERBOOKING_EXCEPTION,
  cancelOverbookingExceptionRequest,
  cancelOverbookingExceptionSuccess,
  cancelOverbookingExceptionFailure,
} from "./overBookingExceptionTableDetailsActions";
import { setOverbookingExceptionsPagination } from "@/dux/overbookingExceptionsPagination/overbookingExceptionsPaginationActions";
import { onPatchOverBookingException } from "../overBookingException/overBookingExceptionSaga";

// Selectors
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import {
  selectOverbookingExceptionsPaginationPage,
  selectOverbookingExceptionsPaginationSize,
} from "../overbookingExceptionsPagination/overbookingExceptionsPaginationSelectors";

// Helpers or Utils
import moment from "moment";
import { formatCalendarDateMoment } from "core/utils/dateUtils/formatDateTime";
import { formatExceptionDates } from "@/core/utils/overbookingUtils/formatExceptionDates";

/**
 * Generator function to fetch hotel overbooking exceptions
 *
 *  @memberOf Sagas.hotel.Overbooking
 *  @generator
 *  @name onGetOverBookingExceptionSuspensionDetails
 *  @returns {Generator<*, void, *>}
 */
function* onGetOverBookingExceptionSuspensionDetails({ payload }) {
  try {
    yield put(getOverBookingExceptionSuspensionDetailsRequest());

    const { page, size } = payload;

    // Request payload
    const storeNumber = yield select(getStoreNumber);
    const fromDate = formatCalendarDateMoment(moment().subtract(30, "days"));
    const toDate = formatCalendarDateMoment(moment().add(18, "months"));

    const response = yield call(fetchHotelOverBookingExceptions, {
      storeNumber,
      fromDate,
      toDate,
      size,
      page,
    });

    const exceptions = response?.data?.results?.map(formatExceptionDates);
    yield put(getOverBookingExceptionSuspensionDetailsSuccess(exceptions));

    yield put(
      setOverbookingExceptionsPagination({
        count: response.data.count,
        page: response.data.page,
        total: response.data.total,
        size: response.data.size,
      }),
    );
  } catch (error) {
    yield put(getOverBookingExceptionSuspensionDetailsFailure());
  }
}

function* onPatchOverBookingExceptionCancel({ capacityExceptionId, roomTypeBucketNumber }) {
  try {
    yield put(cancelOverbookingExceptionRequest());

    // Patch call to cancel exception
    yield call(onPatchOverBookingException, {
      capacityExceptionId,
      roomTypeBucketNumber,
      isCanceled: true,
    });

    // Get all exceptions for current page to update data in table
    const page = yield select(selectOverbookingExceptionsPaginationPage);
    const size = yield select(selectOverbookingExceptionsPaginationSize);
    yield call(onGetOverBookingExceptionSuspensionDetails, { payload: { page, size } });

    yield put(cancelOverbookingExceptionSuccess());
  } catch (error) {
    yield put(cancelOverbookingExceptionFailure());
  }
}

function* watchOnGetOverBookingExceptionSuspensionDetails() {
  yield takeEvery(
    GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS,
    onGetOverBookingExceptionSuspensionDetails,
  );
}
function* watchOnPatchOverBookingExceptionCancel() {
  yield takeEvery(CANCEL_OVERBOOKING_EXCEPTION, onPatchOverBookingExceptionCancel);
}

export default function* overBookingExceptionSuspensionDetailsSaga() {
  yield all([
    watchOnGetOverBookingExceptionSuspensionDetails(),
    watchOnPatchOverBookingExceptionCancel(),
  ]);
}
