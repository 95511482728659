import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Gets engagement service details associated with pre check-in.
 * For System Services Booking API
 * - Get engagement service details
 * @param {*} { customerKey, itineraryId, engagementId }
 * @returns {*} AxiosPromise
 */
export function getEngagementServiceDetails({ customerKey, itineraryId, engagementId }) {
  return bookingAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/itineraries/${itineraryId}/engagements/${engagementId}/servicecontract`,
  );
}
