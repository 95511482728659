import { frequencyActionTypes } from "dux/frequency/actions/frequencyActions";

const initialState = {};

const pendingFrequencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case frequencyActionTypes.SET_PENDING_ID:
      return {
        ...state,
        pendingId: action.pendingId,
        petId: action.petId,
        frequencyType: action.frequencyType,
      };
    case frequencyActionTypes.SET_PENDING_FREQUENCY_FOOD_DATES:
      return { ...state, foodDates: action.dates };
    case frequencyActionTypes.SET_PENDING_FREQUENCY_MEDICATION_DATES:
      return { ...state, medicationDates: action.dates };
    case frequencyActionTypes.SET_PENDING_FREQUENCY_ADDON_DATES:
      return { ...state, addonDates: action.dates };
    case frequencyActionTypes.SET_PENDING_FREQUENCY:
      return { ...state, frequency: action.frequency };
    case frequencyActionTypes.SET_CACHED_FREQUENCY:
      return { ...state, cachedFrequency: action.cachedFrequency };
    case frequencyActionTypes.SET_PENDING_FREQUENCY_IS_FROM_CART:
      return { ...state, isFromCart: action.isFromCart };
    case frequencyActionTypes.CLEAR_PENDING_FREQUENCY:
      return initialState;
    default:
      return state;
  }
};

export default pendingFrequencyReducer;
