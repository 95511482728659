import React from "react";
import {
  ModalCopy,
  ModalFooter,
  ModalHeader,
  SectionWrapper,
} from "web/petCheckInOut/modals/checkInOutModalsLayout";
import PayNowConfirmationButtonMultiAppointmentContainer from "dux/modals/confirmationModal/PayNowConfirmationButtonMultiAppointmentContainer";
import CheckInModalDoneContainer from "dux/modals/checkIn/single/CheckInModalDoneContainer";
import PetImagesContainer from "dux/petImages/PetImagesContainer";

function CheckInMultiModalContent({ isHidden, headerText, message }) {
  if (!isHidden) {
    return (
      <SectionWrapper>
        <ModalHeader>{headerText}</ModalHeader>

        <ModalCopy data-testid="CheckInModalMessage">{message}</ModalCopy>

        <PetImagesContainer />

        <ModalFooter>
          <PayNowConfirmationButtonMultiAppointmentContainer />
          <CheckInModalDoneContainer />
        </ModalFooter>
      </SectionWrapper>
    );
  }

  return null;
}

export default CheckInMultiModalContent;
