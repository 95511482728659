import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { Button } from "@prism/psm-ui-components";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getTrainingAttendeeById } from "@/dux/trainingClassSessions/selectors";
import { routePaths } from "@/core/constants/routePaths";

const mapStateToProps = (state, { attendeeId }) => {
  const storeNumber = getStoreNumber(state);
  const { customerId, petParentName } = getTrainingAttendeeById(state, {
    storeNumber,
    attendeeId,
  });
  return {
    customerId,
    variant: "link",
    size: "xl",
    style: { fontWeight: "bold" },
    children: petParentName,
  };
};

const mapDispatchToProps = (_, { router: { navigate } }) => ({
  redirectToPetParentProfile: (customerKey) => {
    navigate(`${routePaths.PET_PARENT_PROFILE}/${customerKey}`);
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => {
  const { variant, size, style, children, customerId } = propsFromState;
  return {
    variant,
    size,
    children,
    style,
    onClick: () => {
      const { redirectToPetParentProfile } = propsFromDispatch;
      redirectToPetParentProfile(customerId);
    },
  };
};

export default withRouteProps(connect(mapStateToProps, mapDispatchToProps, mergeProps)(Button));
