import { compose, get, getOr, omit } from "lodash/fp";
import { createSelector } from "reselect";
import { v4 as uuidv4 } from "uuid";
import getFoodState from "./_foodState";
import { getPet } from "../../core/selectors/entitiesSelector";
import { NEW_FOOD_ID } from "../newFood/newFoodConstants";
import { getCheckInOutPetFoods } from "@/dux/_components/checkInOutFeeding/CheckInOutCartFoodSelectors";
import { frequencyConstants } from "@/dux/frequency/FrequencyConstants";
import { medicationTypes } from "../newMedication/newMedicationConstants";
import { selectFoodList, selectFoodOptions } from "@/dux/foodOptionsList/foodOptionsListSelectors";
import isEmpty from "lodash/isEmpty";
import selectIsDiscontinuedFoodsFeatureHidden from "../enableDisableWorkflowFeatureFlag/selectors/discontinuedFoods/getIsDiscontinuedFoodsWorkflowFeatureFlagHidden";
import uniqBy from "lodash/fp/uniqBy";
import {
  getHotelEngagements,
  getHotelEngagementsPets,
} from "@/dux/hotelEngagements/hotelEngagementSelectors";
import { getProps } from "@/core/selectors/commonSelector";
import flattenArray from "@/core/utils/arrayUtils/flattenArray";
import { convertTimeOfDayToObj } from "@/core/utils/checkInOutUtils";

/**
 * Helper to get food from list by external id
 * @function
 * @name getExternalFoodById
 * @param {Object} food
 * @param {String} food.externalId
 * @param {Object[]} foodList
 * @returns {Object} food data with corresponding FoodId
 */
export const getExternalFoodById = ({ externalId, foodId } = {}, foodList) => {
  const id = externalId ?? foodId;
  return foodList?.find(({ FoodId }) => FoodId === id);
};

export const getFoods = createSelector([getFoodState], foodState => getOr({}, "foods", foodState));

export const getFoodByPet = createSelector(
  [getFoods, (state, props) => props],
  (foods, { petId }) => {
    return getOr({}, petId, foods);
  },
);

export const getFoodByPetExcludingNewFoodID = createSelector([getFoodByPet], foods => {
  return omit(NEW_FOOD_ID, foods);
});

export const getFoodsExcludingNewFoodID = createSelector([getFoods], foods =>
  Object.entries(foods)?.reduce(
    (nonNewFoods, [petId, petFoods]) => ({ ...nonNewFoods, [petId]: omit(NEW_FOOD_ID, petFoods) }),
    {},
  ),
);

export const getFoodById = createSelector(
  [getFoodByPet, (state, props) => props],
  (foods, { foodId }) => {
    return get(foodId, foods);
  },
);

export const getPetFoods = createSelector([getPet], pet => {
  return (pet && pet.foods) || [];
});

/**
 * Selector to get pet profile foods filtered by IsActive attr from food list
 * @memberOf Selectors.Entities
 * @function
 * @name selectActivePetProfileFoods
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Array} pet foods from profile where IsActive is true
 * @example selectActivePetProfileFoods(state, { petId })
 */
export const selectActivePetProfileFoods = createSelector(
  [getPetFoods, selectFoodList],
  (foods, foodList) => {
    return foods?.filter(food => getExternalFoodById(food, foodList)?.IsActive);
  },
);

export const getPetFoodById = createSelector(
  [getPetFoods, (state, props) => props],
  (foods, { foodId }) => {
    return foods.find(food => food.petFoodId && food.petFoodId.toString() === foodId);
  },
);

export const getFoodIds = createSelector([getFoodByPet], foods => {
  return foods
    ? Object.keys(foods)
        ?.filter(food => food !== NEW_FOOD_ID)
        .sort()
    : [];
});

export const getPetFoodIds = createSelector([getPetFoods], foods => {
  return foods?.map(food => food.petFoodId);
});

/**
 * Selector to get all pet foods from itinerary and format them for booking
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectPetFoodsForRebooking
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Array} pet foods
 * @example selectPetFoodsForRebooking(state, { petId })
 */
export const selectPetFoodsForRebooking = createSelector([getCheckInOutPetFoods], (foods = []) => {
  return foods?.map(food => {
    const isManual = food?.frequency === frequencyConstants.MANUAL;
    return {
      petFoodId: food?.recordId,
      externalId: food?.foodId,
      name: food?.feedingName,
      amount: food?.amount,
      frequency: food?.frequency,
      timeOfDay: food?.timeOfDay,
      specialInstructions: food?.splInstruction,
      startDate: food?.startDatetime,
      endDate: food?.endDatetime,
      locationTypes: isManual ? [medicationTypes.BOOKING] : [],
      frequencyTouched: isManual,
    };
  });
});

/**
 * Selector to get all pet foods from itinerary and format them for booking
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectActivePetFoodsForRebooking
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Array} pet foods
 * @example selectActivePetFoodsForRebooking(state, { petId })
 */
export const selectActivePetFoodsForRebooking = createSelector(
  [selectPetFoodsForRebooking, selectFoodList],
  (foods, foodList) => {
    return foods?.filter(food => getExternalFoodById(food, foodList)?.IsActive);
  },
);

/**
 * Selector to get initial foods for a pet in the hotel booking flow
 * @function
 * @name selectPetFoodsForBooking
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.isRebooking - if true then use foods from itinerary else use pet profile foods
 * @returns {Array} pet foods formatted for booking flow
 * @example selectPetFoodsForBooking(state, { petId, isRebooking })
 */
export const selectPetFoodsForBooking = createSelector(
  [
    selectActivePetProfileFoods,
    selectActivePetFoodsForRebooking,
    selectFoodList,
    (state, props) => props?.isRebooking,
  ],
  (petProfileFoods, itineraryFoods, foodList, isRebooking) => {
    const foods = isRebooking ? itineraryFoods : petProfileFoods;

    return foods?.map(petFood => ({
      ...petFood,
      petProfileFoodId: !isRebooking ? petFood?.petFoodId : undefined,
      saveToProfile: false,
      groupingId: uuidv4(),
      type: foodList?.find(food => food?.FoodId === petFood.externalId)?.FoodType,
      frequency: petFood?.frequency ?? frequencyConstants.DAILY,
    }));
  },
);

/**
 * Selector to format pet profile foods for check in out food form
 * @function
 * @name selectPetProfileFoodsForPostBooking
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Boolean}
 * @example selectPetProfileFoodsForPostBooking(state, { petId })
 */
export const selectPetProfileFoodsForPostBooking = createSelector(
  [selectActivePetProfileFoods, selectFoodOptions],
  (foods, foodOptions) => {
    return foods?.reduce((foodObj, food) => {
      const timeOfDayObj = convertTimeOfDayToObj(food?.timeOfDay);
      const externalFood = foodOptions[food?.externalId];
      const isOther = externalFood?.FoodName?.toLowerCase()?.includes("other");

      return {
        ...foodObj,
        [food?.petFoodId]: {
          petProfileFoodId: food?.petFoodId,
          externalId: food?.externalId,
          foodType: externalFood?.FoodType,
          foodName: isOther ? externalFood?.FoodName : food?.name,
          otherFoodName: isOther ? food?.name : "",
          foodAmount: food?.amount,
          foodInstructions: food?.specialInstructions,
          frequency: frequencyConstants.DAILY,
          ...timeOfDayObj,
        },
      };
    }, {});
  },
);

/**
 * Selector to check if any foods for a given pet are missing dates for manual frequency
 * @function
 * @name selectPetFoodsIsMissingDates
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Boolean}
 * @example selectPetFoodsIsMissingDates(state, { petId })
 */
export const selectPetFoodsIsMissingDates = createSelector(
  [getFoodByPetExcludingNewFoodID],
  (foods = {}) => {
    return !!Object.values(foods)?.some(
      ({ frequency, concreteSchedule }) =>
        frequency === frequencyConstants.MANUAL && isEmpty(concreteSchedule),
    );
  },
);

/**
 * Selector to get food from options by external id
 * @function
 * @name getFoodExternalDataById
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.foodId
 * @returns {Object}
 * @example getFoodExternalDataById(state, { petId, foodId })
 */
export const getFoodExternalDataById = createSelector(
  [getFoodById, selectFoodList],
  getExternalFoodById,
);

/**
 * Selector to check if given food is discontinued (IsActive is false)
 * Note: if the discontinued food feature flag is off then, this will return false
 * @function
 * @name getFoodIsDiscontinuedById
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.foodId
 * @returns {Boolean}
 * @example getFoodIsDiscontinuedById(state, { petId, foodId })
 */
export const getFoodIsDiscontinuedById = createSelector(
  [getFoodExternalDataById, selectIsDiscontinuedFoodsFeatureHidden],
  (food, isDiscontinuedFoodsFeatureOff) => {
    if (isDiscontinuedFoodsFeatureOff) return false;

    return food?.IsActive === false;
  },
);

export const selectCheckInOutDiscontinuedFoodsByPet = createSelector(
  [getCheckInOutPetFoods, selectFoodList],
  (foods, foodList) =>
    foods?.filter(food => getExternalFoodById(food, foodList)?.IsActive === false) ?? [],
);

export const selectEngagementAndFoodOptionsSlice = createSelector(
  [getHotelEngagements, selectFoodOptions],
  (engagements, foodOptions) => ({ hotelEngagements: { engagements }, foodOptions }),
);

export const formatFoodForNotAvailableModal = foods =>
  foods?.map(({ foodId, feedingName }) => ({ id: foodId, name: feedingName })) ?? [];

export const selectItineraryDiscontinuedFoods = createSelector(
  [
    selectEngagementAndFoodOptionsSlice,
    getHotelEngagementsPets,
    getProps,
    selectIsDiscontinuedFoodsFeatureHidden,
  ],
  (stateSlice, itineraryPets, { petIds }, isDiscontinuedFoodsFeatureOff) => {
    if (isDiscontinuedFoodsFeatureOff) return [];

    const petsToCheck = petIds ?? itineraryPets;
    const petFoods =
      petsToCheck?.map(petId => selectCheckInOutDiscontinuedFoodsByPet(stateSlice, { petId })) ??
      [];

    return compose(formatFoodForNotAvailableModal, uniqBy("foodId"), flattenArray)(petFoods);
  },
);
