import absenceReasonsActionTypes from "../../core/actionTypes/absenceReasonsActionTypes";

export const loadAbsenceReasons = () => ({
  type: absenceReasonsActionTypes.LOAD_ABSENCE_REASONS
});

export const loadAbsenceReasonsRequest = () => ({
  type: absenceReasonsActionTypes.LOAD_ABSENCE_REASONS_REQUEST
});

export const loadAbsenceReasonsSuccess = ({ absenceReasons }) => ({
  type: absenceReasonsActionTypes.LOAD_ABSENCE_REASONS_SUCCESS,
  payload: absenceReasons
});

export const loadAbsenceReasonsFailure = ({ error }) => ({
  type: absenceReasonsActionTypes.LOAD_ABSENCE_REASONS_FAILURE,
  error
});

export default {
  loadAbsenceReasons,
  loadAbsenceReasonsRequest,
  loadAbsenceReasonsSuccess,
  loadAbsenceReasonsFailure
};
