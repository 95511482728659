import { get } from "lodash/fp";
import { createSelector } from "reselect";
import getDiscontinuedFoodsWorkflowFeatureFlag from "./getDiscontinuedFoodsWorkflowFeatureFlag";

const selectIsDiscontinuedFoodsFeatureHidden = createSelector(
  [getDiscontinuedFoodsWorkflowFeatureFlag],
  featureFlag => get("isHidden", featureFlag),
);

export default selectIsDiscontinuedFoodsFeatureHidden;
