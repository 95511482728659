import BundleDiscountPriceContainer from "../bgm/availableBundlesByPet/applyAvailableBundle/BundleDiscountPriceContainer";
import ManualApplyBundleButtonContainer from "../bgm/availableBundlesByPet/applyAvailableBundle/ManualApplyBundleButtonContainer";

export const applyBundleComponentTypes = {
  MANUAL_APPLY: "MANUAL_APPLY",
  BUNDLE_DISCOUNT: "BUNDLE_DISCOUNT",
};

export const applyBundleComponents = {
  [applyBundleComponentTypes.MANUAL_APPLY]: ManualApplyBundleButtonContainer,
  [applyBundleComponentTypes.BUNDLE_DISCOUNT]: BundleDiscountPriceContainer,
};
