import { connect } from "react-redux";
import React from "react";
import { Button, Heading, TextPassage } from "@petsmart-ui/sparky";
import { LayoutStack } from "@/layout/stack/Stack";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import {
  getCustomerEmail,
  getCustomerFullName,
  getCustomerPhone,
} from "@/core/selectors/entitiesSelector";
import { LayoutBox } from "@/layout/box/Box";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/core/constants/routePaths";

/**
 * React view component for customer summary
 * @memberOf Views.Customer
 * @param {*} props
 * @returns {JSX.Element}
 */
export const PetParentCartSummaryComponent = props => {
  const {
    customerPhone,
    customerEmail,
    componentId,
    customerName,
    customerPath,
    padding = "scale-G1",
    isHidden,
  } = props;
  const navigate = useNavigate();
  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding={padding} style={{ paddingLeft: 0, paddingRight: 0 }}>
      <LayoutStack>
        <Heading tagName="h4" size="body-lg-bold">
          Pet Parent
        </Heading>

        <TextPassage size="lg">
          <LayoutStack space="scale-0">
            {customerName && (
              <LayoutBox padding="scale-0">
                <Button
                  text={customerName}
                  onClick={() => navigate(customerPath)}
                  size="lg"
                  style={{ fontWeight: "bold" }}
                  variant="link"
                />
              </LayoutBox>
            )}
            {customerPhone && <LayoutBox padding="scale-0">{customerPhone}</LayoutBox>}
            {customerEmail && <LayoutBox padding="scale-0">{customerEmail}</LayoutBox>}
          </LayoutStack>
        </TextPassage>
      </LayoutStack>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for customer summary
 * @memberOf Views.Customer
 * @function
 * @name PetParentCartSummary
 * @example <PetParentCartSummary />
 */
export const PetParentCartSummary = connect(state => {
  const customerKey = getCurrentCustomerKey(state);

  return {
    componentId: "PetParentCartSummary",
    customerName: getCustomerFullName(state, { customerKey }),
    customerPhone: getCustomerPhone(state, { customerKey }),
    customerEmail: getCustomerEmail(state, { customerKey }),
    customerPath: `${routePaths.PET_PARENT_PROFILE}/${customerKey}`,
  };
})(PetParentCartSummaryComponent);
