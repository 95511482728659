/**
 * Check if a cookie is set or if it is null.
 *
 * @export getCookieValueOrNull
 * @param { String } - the name of the cooking including the equal sign, e.g. persistFF=
 * @returns {Boolean || null } - the value the cooke has been set to.
 */
export default function getCookieValueOrNull(cookieName) {
  const cookieIsInDoc = !!document.cookie.match(new RegExp(cookieName));

  if (cookieIsInDoc) {
    const cookies = document.cookie;
    const cookie = cookies.match(new RegExp(`${cookieName}[a-z]+`));
    const valueStartIndex = cookieName.length + 1;
    const value = cookie[0].slice(valueStartIndex + -1);

    // The cookie will return a string so we implicitly set it to true or false ;
    return value === "true";
  }

  // if no cookie, then null
  return null;
}
