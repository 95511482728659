import { connect } from "react-redux";
import { hideCheckInOutModal } from "core/actionCreators/checkInOutActionCreator";
import { setVoidTransactionReason } from "dux/voidTransactionReasons/voidTransactionReasonsActions";
import CompleteModalContent from "../../../../_components/modals/checkOut/voidTransaction/complete/CompleteModalContent";

const mapDispatchToProps = dispatch => ({
  setDone: () => {
    dispatch(hideCheckInOutModal());
    dispatch(setVoidTransactionReason({ reason: "" }));
  },
});

export default connect(null, mapDispatchToProps)(CompleteModalContent);
