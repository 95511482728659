/**
 |--------------------------------------------------
 | * Takes an array of items and yields them one at a time in an infinate loop when next() is called
 *
 * @param {Array} keysToOmit An array of keys to remove from target array/object
 * @returns {Generator} A generator that will yield each item one at a time
 *
 *  @example const gen = toGenerator(['a', 'b']);
 *    gen.next().value; // a
 *    gen.next().value; // b
 *    gen.next().value; // a
 *
 |--------------------------------------------------
 */
export default function* toGenerator(list) {
  let index = 0;

  while (true) {
    yield list[index];
    index = list.length - 1 > index ? index + 1 : 0;
  }
}
