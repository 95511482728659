import React, { Component } from "react";
import { history } from "@/dux/utils/browser/browserHistory";
import { Formik } from "formik";
import styled from "styled-components";

import { font, color } from "../common/styles/theme";
import { SET_PIN_INITIAL_FORM_VALUES } from "../../core/constants/quickPin";
import Img from "../common/Img";
import CommonButton from "../common/commonButton";
import { LoadingSpinner } from "../common/LoadingSpinner";
import ProfileSVG from "../../assets/icons/profile.svg";
import { setPinValidationSchema } from "../../core/constants/validationSchema";
import { routePaths } from "../../core/constants/routePaths";

const StyledSubHeading = styled.h4`
  font-family: Lato;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: white;
`;

const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin: 0 0 2.5em 0;
  background-color: #505050;
`;

const ProfileImage = styled(Img)`
  border-radius: 50%;
  margin: 5px;
  height: 125px;
  width: 125px;
  box-shadow: 1px 1px 22px 3px #1e1e1e;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 10% 0 10%;
  padding-bottom: 0.5em;
`;

const InputBox = styled.input`
  height: 45px;
  width: 250px;
  box-sizing: border-box;
  border: 0;
  outline: none;
  font-family: ${font.mainFamily};
  font-size: 18px;
  color: white;
  padding: 0.5em 0.7em;
  background-color: rgba(250, 250, 250, 0.2);
  box-shadow: inset 0 1px 0 0 ${color.storm}, inset -1px 0 0 ${color.storm},
    ${props => props.lastChild && `inset 0 -1px 0 ${color.storm},`} inset 1px 0 0 ${color.storm};
  &:focus {
    box-shadow: inset 0 2px 0 0 ${color.victorStone}, inset -2px 0 0 ${color.victorStone},
      inset 0 -2px 0 ${color.victorStone}, inset 2px 0 0 ${color.victorStone};
  }
  ::placeholder {
    color: white;
  }
`;

const SubmitButton = styled(CommonButton)`
  font-size: 18px;
  border-radius: 0;
  &:hover {
    background-color: ${color.deepSkyBlue};
  }
  &:focus {
    outline: none;
  }
`;

const ErrorText = styled.div`
  color: #ff7f7f;
  text-align: center;
  margin-top: 1em;
  max-width: 250px;
`;

class SetPinComponent extends Component {
  componentDidMount() {
    const { isRedirectedToSetPin } = this.props;
    if (!isRedirectedToSetPin) {
      history.push(routePaths.LOGIN);
    }
  }

  componentWillUnmount() {
    const { clearInvalidPinError, clearRedirectToSetPin } = this.props;
    clearInvalidPinError();
    clearRedirectToSetPin();
  }

  render() {
    const { isLoading, setOrUpdateUserPin, invalidPinError } = this.props;
    return (
      <UsersContainer>
        <ProfileImage src={ProfileSVG} />
        <StyledSubHeading>Please choose your new PIN code</StyledSubHeading>
        <Formik
          initialValues={SET_PIN_INITIAL_FORM_VALUES}
          validationSchema={setPinValidationSchema}
          onSubmit={({ pin }) => setOrUpdateUserPin({ pin })}
          render={({
            handleSubmit,
            handleChange,
            values: { pin, confirmPin },
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <InputBox
                type="password"
                placeholder="Personal PIN Code"
                name="pin"
                maxLength="6"
                onChange={handleChange}
                value={pin}
                firstChild
              />
              <InputBox
                type="password"
                placeholder="Confirm PIN"
                name="confirmPin"
                maxLength="6"
                onChange={handleChange}
                value={confirmPin}
                lastChild
              />
              <SubmitButton type="Submit" label={isLoading ? <LoadingSpinner /> : "Submit"} />
              <ErrorText>
                {(touched.pin && errors.pin) ||
                  (touched.confirmPin && errors.confirmPin) ||
                  invalidPinError}
              </ErrorText>
            </Form>
          )}
        />
      </UsersContainer>
    );
  }
}

export default SetPinComponent;
