import axios from "axios";
import {
  setRequestHeaders,
  handleAuthorizationRejection,
  setResponseRefreshToken,
} from "../../utils/authHelper";
import { getDomainEnv } from "core/utils/envUtils.js";

/**
 * Axios Create configuration for the Associate Web API (/web/associate).
 *
 * @summary /web/associate.
 * @memberOf APIs.AssociateWeb
 * @function
 * @name  associateWebBFFAPI
 * @type {axios.AxiosInstance}
 *
 * @example
 * return associateWebBFFAPI.post(url, params)
 * return associateWebBFFAPI.get(url, params)
 * return associateWebBFFAPI.put(url, params)
 */
export const associateWebBFFAPI = axios.create({
  baseURL: `${window.env.BASE_URL}${window.env.ASSOCIATE_WEB_BFF_URI}`,
});

/**
 * Intercept the `x-refresh-session-token` value
 * - Note: This can be disabled by using the ENV flag below
 */
if (!window.env.DISABLE_API_AUTH_IN_DEV) {
  associateWebBFFAPI.interceptors.request.use(setRequestHeaders, handleAuthorizationRejection);
  associateWebBFFAPI.interceptors.response.use(
    setResponseRefreshToken,
    handleAuthorizationRejection,
  );
}
