import { createSelector } from "reselect";
import { getEnhancedServices, getService, getPetFormData } from "core/selectors/entitiesSelector";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { getAppointmentByPet } from "core/selectors/cartSelectors";
import { getState } from "core/selectors/commonSelector";
import getIsEnhancedServicesWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/enhancedServices/getIsEnhancedServicesWorkflowFeatureFlagHidden";
import {
  selectControlledOverbookStoreCapability,
  selectEnhancedServicesStoreCapability,
} from "@/core/selectors/schedulesSelectors";
import getIsEnhancedServicesBulkPackagesWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/enhancedServices/getIsEnhancedServicesBulkPackagesWorkflowFeatureFlagHidden";
import getIsControlledOverbookWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/enhancedServices/getIsControlledOverbookWorkflowFeatureFlagHidden";

export const selectEnhancedServicesByService = createSelector(
  [getEnhancedServices, getService],
  (enhancedServices, service) => {
    if (!service?.addOnIds) {
      return [];
    }

    return service.addOnIds.reduce((services, addOnId) => {
      const enhancedService = enhancedServices[addOnId];

      if (enhancedService) {
        services.push(enhancedService);
      }

      return services;
    }, []);
  },
);

export const hasAppointment = state => {
  const selectedPetId = getSelectedPet(state);
  return selectedPetId && getAppointmentByPet(state, { petId: selectedPetId });
};

export const selectEnhancedSalonServices = state =>
  state?.storeCapabilities?.capabilities?.find(item => item.enhancedSalonServices)
    ?.enhancedSalonServices;

export const selectEnhancedServicesCart = createSelector(
  [getState, getSelectedPet],
  (state, selectedPetId) => state.ui.web.cart[selectedPetId]?.enhancedServices,
);

export const selectEnhancedServicesCartId = state => selectEnhancedServicesCart(state)?.addOnId;

export const isCatSpeciesId = (state, ownProps) => getPetFormData(state, ownProps)?.speciesId === 2;

export const isEnhancedServicesHidden = (state, ownProps) =>
  !hasAppointment(state) ||
  getIsEnhancedServicesWorkflowFeatureFlagHidden(state) ||
  !selectEnhancedSalonServices(state) ||
  isCatSpeciesId(state, ownProps) ||
  ownProps.sortedAddons?.length === 0;

/* Selector to determine if enhanced service should be visible
 * @function
 * @name selectIsEnhancedServicesVisible
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Boolean}
 * @example selectIsEnhancedServicesVisible(state, { petId })
 */
export const selectIsEnhancedServicesVisible = createSelector(
  [isEnhancedServicesHidden, getAppointmentByPet],
  (enhancedServicesHidden, appointment) =>
    Boolean(!enhancedServicesHidden && appointment && !appointment.isStandalone),
);

export const getIsEnhancedServicesBulkPackagesHidden = state =>
  !selectEnhancedServicesStoreCapability(state) ||
  getIsEnhancedServicesBulkPackagesWorkflowFeatureFlagHidden(state);

export const getIsControlledOverbookHidden = state =>
  !selectControlledOverbookStoreCapability(state) ||
  getIsControlledOverbookWorkflowFeatureFlagHidden(state);
