// saga effects
import { put, takeEvery, call, all } from "redux-saga/effects";

// Api call(s)
import { getNotifications } from "core/services/associateWeb/notifications";

// lodash
import { filter } from "lodash/fp";

// constants
import { BANNER } from "dux/storeWideNotes/storeWideNotesConstants";

// Actions
import { getStoreWideNotes, getStoreWideNotesSuccess } from "dux/storeWideNotes/storeWideNotesActions";
import {
  GET_NOTIF_FOR_ALERT_MESSAGE,
  getNotifForAlertFailure,
  getNotifForAlertRequest,
  getNotifForAlertSuccess,
} from "./storeEventNotificationsActions";
import { filterStoreWideNotifications } from "dux/storeWideNotes/_helpers_/storeWideNotesHelpers";

function* onGetNotifications({ storeNumber, displayLocation, date }) {
  try {
    yield put(getNotifForAlertRequest());

    const response = yield call(getNotifications, { storeNumber, displayLocation, date });
    const result = response?.data?.result;

    // filter and return only those notifications that have displayLocations of type 'banner'
    const notifications = filter(item => item.displayLocations.includes(BANNER), result);

    yield put(getNotifForAlertSuccess({ notifications }));

    // Since Store Wide Notes also come in on this API call we filter out those results separately and push to its respective reducer
    const storeWideNotes = filterStoreWideNotifications(result);
    yield put(getStoreWideNotesSuccess({ notifications: storeWideNotes }));

  } catch (error) {
    yield put(getNotifForAlertFailure({ error }));
  }
}

function* watchOnGetNotifications() {
  yield takeEvery(GET_NOTIF_FOR_ALERT_MESSAGE, onGetNotifications);
}

export default function* notificationsSaga() {
  yield all([watchOnGetNotifications()]);
}
