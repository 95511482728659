import { createSelector } from "reselect";

export const getHotelReservationConfirmation = state => state.hotelReservationConfirmation;
export const getHasReservationConfirmationChanged = state =>
  state.hotelReservationConfirmation.isHidden;

export const getHasReservationConfirmationMessage = createSelector(
  [getHotelReservationConfirmation],
  hotelReservationConfirmation => hotelReservationConfirmation?.message,
);

export const getIsReservationSaveDisabled = createSelector(
  [getHotelReservationConfirmation],
  hotelReservationConfirmation => hotelReservationConfirmation?.isSaveDisabled,
);

/**
 *  Selector to get pending check in date time
 *  @memberOf Selectors.HotelReservationConfirmation
 *  @function
 *  @name getReservationConfirmationPendingCheckInTime
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {string} props.petId - optional
 *  @returns {string | moment}
 *  @example getReservationConfirmationPendingCheckInTime(state)
 * // OR provide petId to only return datetime if petId's match
 * getReservationConfirmationPendingCheckInTime(state, { petId })
 * @todo this pending check in time state is no longer being used, delete this and refactor whatever is using it
 */
export const getReservationConfirmationPendingCheckInTime = createSelector(
  [getHotelReservationConfirmation, (state, props) => props],
  ({ pendingDateTime } = {}, { petId } = {}) => {
    if (!petId) return pendingDateTime?.checkInTime;
    return petId === pendingDateTime?.petId && pendingDateTime?.checkInTime;
  },
);

export const selectHasCheckInOutDateUpdated = createSelector(
  [getHotelReservationConfirmation],
  hotelReservationConfirmation => !!hotelReservationConfirmation?.pendingDateTime,
);

export const selectHasRoomNumberUpdated = createSelector(
  [getHotelReservationConfirmation],
  hotelReservationConfirmation => !!hotelReservationConfirmation?.pendingRoom,
);

export const getReservationConfirmationPendingFoods = createSelector(
  [getHotelReservationConfirmation],
  hotelReservationConfirmation => {
    return hotelReservationConfirmation?.pendingFoods;
  },
);

export const getReservationConfirmationPendingPrice = createSelector(
  [getHotelReservationConfirmation],
  hotelReservationConfirmation => {
    return hotelReservationConfirmation?.pendingPrice;
  },
);

export const getReservationConfirmationPendingMeds = createSelector(
  [getHotelReservationConfirmation],
  hotelReservationConfirmation => {
    return hotelReservationConfirmation?.pendingMeds;
  },
);
