import { hotelArrivalsActionTypes } from "./hotelArivalsActions";
import { hotelArrivalsHeaderSearchActionTypes } from "../columnHeader/search/hotelArrivalsHeaderSearchActions";
import { hotelArrivalsFilterButtonActionTypes } from "../columnHeader/filterButtons/hotelArrivalsFilterButtonActions";

const initialState = {
  appointments: [],
  searchText: "",
  filterBy: {
    overnight: true,
    dayCamp: true,
    dayCare: true,
    all: true,
    attentionNeeded: true,
    unassignedPet: true,
  },
};

const hotelArrivalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case hotelArrivalsActionTypes.CLEAR_HOTEL_ARRIVALS:
      return initialState;
    case hotelArrivalsActionTypes.LOAD_HOTEL_ARRIVALS_SUCCESS:
      return {
        ...state,
        appointments: action.arrivals,
      };
    case hotelArrivalsHeaderSearchActionTypes.SET_HOTEL_ARRIVAL_SEARCH:
      return {
        ...state,
        searchText: action.value,
      };
    case hotelArrivalsHeaderSearchActionTypes.CLEAR_HOTEL_ARRIVAL_SEARCH:
      return {
        ...state,
        searchText: "",
      };
    case hotelArrivalsFilterButtonActionTypes.FILTER_BY_OVERNIGHT_ARRIVALS:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          overnight: action.filterBtnStatus,
        },
      };
    case hotelArrivalsFilterButtonActionTypes.FILTER_BY_DAY_CAMP_ARRIVALS:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          dayCamp: action.filterBtnStatus,
        },
      };
    case hotelArrivalsFilterButtonActionTypes.FILTER_BY_DAY_CARE_ARRIVALS:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          dayCare: action.filterBtnStatus,
        },
      };
    case hotelArrivalsFilterButtonActionTypes.FILTER_BY_All_ARRIVALS:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          all: action.filterBtnStatus,
        },
      };
    case hotelArrivalsFilterButtonActionTypes.FILTER_BY_ATTENTION_NEEDED_ARRIVALS:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          attentionNeeded: action.filterBtnStatus,
        },
      };
    case hotelArrivalsFilterButtonActionTypes.FILTER_BY_UNASSIGNED_PET_ARRIVALS:
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          unassignedPet: action.filterBtnStatus,
        },
      };
    default:
      return state;
  }
};

export default hotelArrivalsReducer;
