/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelOvernightActionTypes = {
  LOAD_HOTEL_OVERNIGHT_SUCCESS: "LOAD_HOTEL_OVERNIGHT_SUCCESS",
  LOAD_HOTEL_OVERNIGHT_REQUEST: "LOAD_HOTEL_OVERNIGHT_REQUEST",
  LOAD_HOTEL_OVERNIGHT_FAILURE: "LOAD_HOTEL_OVERNIGHT_FAILURE",
  CLEAR_HOTEL_OVERNIGHTS: "CLEAR_HOTEL_OVERNIGHTS",
};
/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const loadHotelOvernightSuccess = ({ overnight }) => ({
  type: hotelOvernightActionTypes.LOAD_HOTEL_OVERNIGHT_SUCCESS,
  overnight,
});

export const loadHotelOvernightRequest = ({ overnight }) => ({
  type: hotelOvernightActionTypes.LOAD_HOTEL_OVERNIGHT_REQUEST,
  overnight,
});

export const loadHotelOvernightFailure = ({ error }) => ({
  type: hotelOvernightActionTypes.LOAD_HOTEL_OVERNIGHT_FAILURE,
  error,
});

export const clearHotelOvernights = () => ({
  type: hotelOvernightActionTypes.CLEAR_HOTEL_OVERNIGHTS,
});
