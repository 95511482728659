export default {
  CREATE_AGENT: "CREATE_AGENT",
  CREATE_AGENT_REQUEST: "CREATE_AGENT_REQUEST",
  CREATE_AGENT_SUCCESS: "CREATE_AGENT_SUCCESS",
  CREATE_AGENT_FAILURE: "CREATE_AGENT_FAILURE",
  UPDATE_AGENT: "UPDATE_AGENT",
  UPDATE_AGENT_REQUEST: "UPDATE_AGENT_REQUEST",
  UPDATE_AGENT_SUCCESS: "UPDATE_AGENT_SUCCESS",
  UPDATE_AGENT_FAILURE: "UPDATE_AGENT_FAILURE",
  DELETE_AGENT: "DELETE_AGENT",
  DELETE_AGENT_REQUEST: "DELETE_AGENT_REQUEST",
  DELETE_AGENT_SUCCESS: "DELETE_AGENT_SUCCESS",
  DELETE_AGENT_FAILURE: "DELETE_AGENT_FAILURE",
  SHOW_ADD_AGENT_FORM: "SHOW_ADD_AGENT_FORM",
  HIDE_ADD_AGENT_FORM: "HIDE_ADD_AGENT_FORM",
  SET_CUSTOMER_AND_AGENT_KEYS: "SET_CUSTOMER_AND_AGENT_KEYS",
  CLEAR_CUSTOMER_AND_AGENT_KEYS: "CLEAR_CUSTOMER_AND_AGENT_KEYS"
};
