import moment, { Moment } from 'moment';
import { range } from './math';

export function getDayNumbersToDisplayForCurrentMonth(selectedDate: Moment) {
  const daysInMonth = moment(selectedDate).daysInMonth();
  const currentMonthRange = range(1, daysInMonth);
  const dayNumbersToDisplayForCurrentMonth = currentMonthRange.map((dayNumber: number) => {
    const startOfMonth = moment(selectedDate).startOf('month');
    return {
      dayNumber,
      date: startOfMonth.add(dayNumber - 1, 'days').format('YYYY-MM-DD'),
      isDisabled: false,
    };
  });

  return dayNumbersToDisplayForCurrentMonth;
}
