import appHeaderActionTypes from "../../actionTypes/ui/web/appHeaderActionTypes";
import modalInitState from "./_modalInitState";
import getUpdatedModalState from "./helpers/getUpdatedModalState";

const initialState = modalInitState();

export default function appHeaderModalsReducer(state = initialState, action) {
  return getUpdatedModalState(
    state,
    initialState,
    appHeaderActionTypes.SHOW_HEADER_MODAL,
    appHeaderActionTypes.HIDE_HEADER_MODAL,
    action
  );
}
