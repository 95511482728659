/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const eligibilityScreenActionTypes = {
  GET_BOOKING_SCREEN_ELIGIBILITY: "eligibility/GET_BOOKING_SCREEN_ELIGIBILITY",
  GET_BOOKING_SCREEN_ELIGIBILITY_FAILURE: "eligibility/GET_BOOKING_SCREEN_ELIGIBILITY_FAILURE",
  GET_PET_PARENT_SCREEN_ELIGIBILITY: "eligibility/GET_PET_PARENT_SCREEN_ELIGIBILITY",
  GET_CHECK_IN_SCREEN_ELIGIBILITY: "eligibility/GET_CHECK_IN_SCREEN_ELIGIBILITY",
  GET_CHECK_IN_SCREEN_ELIGIBILITY_REQUEST: "eligibility/GET_CHECK_IN_SCREEN_ELIGIBILITY_REQUEST",
  GET_CHECK_IN_SCREEN_ELIGIBILITY_SUCCESS: "eligibility/GET_CHECK_IN_SCREEN_ELIGIBILITY_SUCCESS",
  GET_CHECK_IN_SCREEN_ELIGIBILITY_FAILURE: "eligibility/GET_CHECK_IN_SCREEN_ELIGIBILITY_FAILURE",
  GET_CHECK_OUT_SCREEN_ELIGIBILITY: "eligibility/GET_CHECK_OUT_SCREEN_ELIGIBILITY",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getBookingScreenEligibility = ({ customerKey, itineraryId }) => ({
  type: eligibilityScreenActionTypes.GET_BOOKING_SCREEN_ELIGIBILITY,
  customerKey,
  itineraryId,
});

export const getBookingScreenEligibilityFailure = ({ error }) => ({
  type: eligibilityScreenActionTypes.GET_BOOKING_SCREEN_ELIGIBILITY_FAILURE,
  error,
});

export const getPetParentScreenEligibility = ({ customerKey, includePets }) => ({
  type: eligibilityScreenActionTypes.GET_PET_PARENT_SCREEN_ELIGIBILITY,
  customerKey,
  includePets,
});

export const getCheckInScreenEligibility = ({ customerKey, itineraryId }) => ({
  type: eligibilityScreenActionTypes.GET_CHECK_IN_SCREEN_ELIGIBILITY,
  customerKey,
  itineraryId,
});

export const getCheckInScreenEligibilityRequest = () => ({
  type: eligibilityScreenActionTypes.GET_CHECK_IN_SCREEN_ELIGIBILITY_REQUEST,
});

export const getCheckInScreenEligibilitySuccess = () => ({
  type: eligibilityScreenActionTypes.GET_CHECK_IN_SCREEN_ELIGIBILITY_SUCCESS,
});

export const getCheckInScreenEligibilityFailure = (error) => ({
  type: eligibilityScreenActionTypes.GET_CHECK_IN_SCREEN_ELIGIBILITY_FAILURE,
  error,
});

export const getCheckOutScreenEligibility = ({ customerKey, itineraryId }) => ({
  type: eligibilityScreenActionTypes.GET_CHECK_OUT_SCREEN_ELIGIBILITY,
  customerKey,
  itineraryId,
});
