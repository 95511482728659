import React from "react";
import styled from "styled-components";

const RootComponent = styled.div`
  display: flex;
`;

function getDirection(direction) {
  switch (direction) {
    case "down":
      return "";

    case "left":
      return "rotate(90)";

    case "right":
      return "rotate(270)";

    case "up":
      return "rotate(180)";

    default:
      return "";
  }
}

export default ({ color = "#000000", direction = "down", styleObject }) => (
  <RootComponent data-testid="SVGArrowViewComponent">
    <svg height="15" width="20" viewBox="0 0 20 20" style={{ ...styleObject }}>
      <polyline
        points="5,5 12.5,12.5 20,5"
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 3,
          transformBox: "fill-box",
          transformOrigin: "center",
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform={getDirection(direction)}
      />
    </svg>
  </RootComponent>
);
