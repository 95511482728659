import moment from "moment";
import { connect } from "react-redux";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { getSelectedAssociateWeekly } from "../../core/selectors/commonSelector";
import { setWeekNumber } from "../../core/actionCreators/ui/web/commonActionCreators";
import { clearUpcomingAndHereSearch } from "../../core/actionCreators/upcomingAndHereActionCreators";
import loadItinerariesHelper from "../_helpers/itineraries/loadItinerariesHelper";
import { GO_LIVE_DATE } from "../../core/constants/dashboardContants";
import Calendar from "../common/Calendar";

const mapStateToProps = (state) => {
  const selectedDate = getSelectedDate(state);

  return {
    isWeekly: !!getSelectedAssociateWeekly(state),
    isOutsideRange: (date) => date.isBefore(GO_LIVE_DATE),
    selectedDate,
    calendarTitle: "Schedule",
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectDate: (selectedDate) => {
    dispatch(setSelectDate(selectedDate));
    dispatch(clearUpcomingAndHereSearch());
  },

  setWeekNumber: (weekNumber) => {
    dispatch(setWeekNumber({ weekNumber }));
  },

  loadItinerariesBySelectedDate: (selectedDate) => {
    // Abstract out dispatching load itineraries since it is used in multiple containers.
    loadItinerariesHelper(dispatch, moment(selectedDate), false);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
