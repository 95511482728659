import moment from "moment";
import convertDateToTimestamp from "../../../core/utils/dateUtils/dateToTimestamp";

/**
 * Returns the diff between two date timestamps for comparison purposes
 *
 * @export getTimestampDiff
 * @param {object} - 1st date timestamp to be compared
 * @param {object} - 2nd date timestamp to be compared
 * @returns {number} - difference between two unix date timestamps (can be a positive or negative number)
 */

export default function getTimestampDiff(a, b) {
  const previousStartDateTime = convertDateToTimestamp(a);
  const currentStartDateTime = convertDateToTimestamp(b);

  return moment.utc(currentStartDateTime).diff(moment.utc(previousStartDateTime));
}
