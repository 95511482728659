import { get } from "lodash/fp";
import { createSelector } from "reselect";
import { getMedicationsByPet } from "../medication/medicationsSelector";
import { selectMedicationsList } from "@/dux/medications/medicationsSelectors";
import { OTHER_MEDICATION_NAME } from "../newMedication/newMedicationConstants";

export const getOtherMedicationNameById = createSelector(
  [getMedicationsByPet, selectMedicationsList, (state, props) => props],
  (medications, medicationList, { medicationId }) => {
    const chosenMedication = get(medicationId, medications);
    if (chosenMedication && chosenMedication.externalId) {
      const foundOtherMedication = medicationList?.find(medication => {
        return (
          medication.MedicationId === chosenMedication.externalId &&
          medication.Description === OTHER_MEDICATION_NAME
        );
      });
      if (foundOtherMedication) {
        return chosenMedication.name;
      }
    }
    return "";
  },
);
