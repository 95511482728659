import { CUSTOMER_ID_HEADER_KEY } from "@/core/constants/endpointConstants";
import { cartAPI } from "./_axiosConfigCart";

/**
 * Initial call to create a cart
 * @param {*} { cart }
 * @returns
 */
export function postCartEndpoint({ cart }) {
  return cartAPI.post(`v1/carts`, cart);
}

/**
 * PUT call that expects the full cart object
 * @param {*} { cartId, cart }
 * @returns
 */
export function putCartEndpoint({ cartId, cart }) {
  return cartAPI.put(`v1/carts/${cartId}`, cart);
}

/**
 * PUT call that expects a single LOB object from the cart, i.e. { hotel: { ... } }
 * @param {*} { cartId, lob, cart }
 * @returns
 */
export function putCartByLobEndpoint({ cartId, lob, cart }) {
  return cartAPI.put(`v1/carts/${cartId}/lob/${lob}`, cart);
}

/**
 * PUT call that expects a pets object for a specific LOB
 * @param {*} { cartId, lob, pets }
 * @returns
 */
export function putCartByLobAndPetsEndpoint({ cartId, lob, pets }) {
  return cartAPI.put(`v1/carts/${cartId}/lob/${lob}/pets`, pets);
}

/**
 * PATCH call that adds specials to all applicable products for all pets on reservation
 * @param {*} { cartId, specialsData, customerId }
 * @returns
 */
export function patchCartSpecialsEndpoint({ cartId, specialsData, customerId }) {
  return cartAPI.patch(`v1/carts/${cartId}/lob/hotel/specials`, specialsData, {
    headers: { [CUSTOMER_ID_HEADER_KEY]: customerId },
  });
}

/**
 * DELETE call that expects cartId, code, lob, customerKey, petId
 * @param {*} { cartId, specialCode, lob, customerKey, petId }
 * @returns
 */
export function deleteCartSpecialEndpoint({ cartId, specialCode, lob, customerKey, petId }) {
  return cartAPI.delete(
    `v1/carts/${cartId}/specials/${specialCode}?lob=${lob}&customerKey=${customerKey}&petIds=${petId}`,
  );
}

/**
 * GET cart by servicesCartId
 * @param {*} { cartId, customerId }
 * @returns
 */
export function getCartByIdEndpoint({ cartId, customerId }) {
  return cartAPI.get(`v1/carts/${cartId}?customerKey=${customerId}`);
}

/**
 * GET cart by itinerary id
 * @param {*} { itineraryId, customerId }
 * @returns
 */
export function getCartByItineraryIdEndpoint({ itineraryId, customerId }) {
  return cartAPI.get(`v1/carts/itineraries/${itineraryId}?customerKey=${customerId}`);
}

/**
 * Deletes a pending cart by cart id
 * @param {*} { cartId, customerId }
 * @returns
 */
export function deleteCartByIdEndpoint({ cartId, customerKey }) {
  return cartAPI.delete(`v1/carts/${cartId}/lob/hotel?customerKey=${customerKey}`);
}
