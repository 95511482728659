import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { setCurrentCustomerKey } from "../../../core/actionCreators/customer/customerActionCreator";
import {
  clearSearchApplied,
  clearSearchFieldValue,
  setSearchComponentName, showSearchResults,
} from "../../../core/actionCreators/searchSalonActionCreator";
import { searchConstants } from "../../../core/constants/searchConstants";
import { getCustomer, getIsCustomerActive } from "../../../core/selectors/entitiesSelector";
import {
  getSearchComponentName,
  getStoreNumber,
} from "../../../core/selectors/persistentSelectors";
import {
  getSearchFieldType,
  getSearchInfoList,
} from "../../../core/selectors/searchSalonSRCSelector";
import CustomerCard from "./customerCardComponent";
import { routePaths } from "@/core/constants/routePaths";

const mapStateToProps = (state, ownProps) => {
  const customer = getCustomer(state, ownProps);
  return {
    customer,
    isHidden: false,
    infoList: getSearchInfoList(state, ownProps),
    searchComponentName: getSearchComponentName(state, ownProps),
    searchFieldType: getSearchFieldType(state, ownProps),
    storeNumber: getStoreNumber(state),
    isCustomerActive: getIsCustomerActive(state, { customerKey: customer.customerKey }),
    componentID: `customer_search_result_${ownProps.idx}`,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchSearchComponentName: () => {
    dispatch(setSearchComponentName({ searchComponentName: searchConstants.PET_PARENT_PROFILE }));
  },
  setCurrentCustomerKey: currentCustomerKey => {
    dispatch(setCurrentCustomerKey({ currentCustomerKey }));
  },
  resetSearch: () => {
    /*
      Once a search has been conducted and a customer card is selected, we want to clear the search
      filed, and applied search and hide the ResultListComponent
     */
    dispatch(clearSearchFieldValue());
    dispatch(clearSearchApplied());
    dispatch(showSearchResults({ showSearchResults: false }));
  }

});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  onClick: () => {
    const { storeNumber, customer } = propsFromState;
    const { setCurrentCustomerKey, dispatchSearchComponentName, resetSearch } = propsFromDispatch;
    const {
      router: { navigate },
    } = ownProps;
    const customerKey = customer.customerKey;

    setCurrentCustomerKey(customerKey);

    if (!storeNumber) {
      dispatchSearchComponentName();
    }

    resetSearch();

    navigate(`${routePaths.PET_PARENT_PROFILE}/${customerKey}`);
  },
});

export default withRouteProps(connect(mapStateToProps, mapDispatchToProps, mergeProps)(CustomerCard));
