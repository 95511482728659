import React from "react";
import compareStrings from "@/core/utils/stringUtils/compareStrings";
import HealthQuestionsListItemComponent from "./HealthQuestionsListItemComponent";
import { LayoutBox } from "@/layout/box/Box";
import * as modularScale from "@/layout/modularScale";

export default function HealthQuestionListComponent({
  isHidden,
  componentID,
  questions,
  displayAlertIcon,
  answerToDisplayBold,
}) {
  return (
    !isHidden && (
      <LayoutBox id={componentID} style={{ overflowY: "scroll", maxHeight: "300px" }}>
        <ol
          style={{
            listStyleType: "none",
            margin: "0",
            counterReset: "orderedlist",
            padding: modularScale.S_1,
          }}
        >
          {questions.map(({ questionId, questionText, answer, answerNumber }) => {
            const isAnswerBold = compareStrings(answer, answerToDisplayBold);
            return (
              <HealthQuestionsListItemComponent
                key={questionId}
                answerNumber={answerNumber}
                questionId={questionId}
                questionText={questionText}
                answer={answer}
                hasAlertIcon={displayAlertIcon && isAnswerBold}
                isAnswerBold={isAnswerBold}
              />
            );
          })}
        </ol>
      </LayoutBox>
    )
  );
}
