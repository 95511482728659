import { createSelector } from "reselect";
import getAddressById from "./getAddressById";
import normalizeAddressValues from "../../../utils/customer/address/normalizeAddressValues";

/* * ----------------------------------------------------------------------- * *\
    Selector that returns normalized Values form the customers Primary Address

    Example of use:  getPrimaryAddressFormValues(state, { customerKey, addressId });
\* * ----------------------------------------------------------------------- * */
const getOtherAddressFormValues = createSelector([getAddressById], otherAddress =>
  normalizeAddressValues(otherAddress),
);

export default getOtherAddressFormValues;
