import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import { omit, pick, concat } from "lodash/fp";
import { history } from "@/dux/utils/browser/browserHistory";
import customersActionTypes from "../actionTypes/customersActionTypes";
import customersActionCreators from "../actionCreators/customersActionCreators";
import searchActionCreators from "../actionCreators/searchActionCreators";
import petsActionCreators from "../actionCreators/petsActionCreators";
import {
  getCustomer,
  getCustomerStatuses,
  getPhonesByCustomer,
  getEmailsByCustomer,
} from "../selectors/entitiesSelector";
import { getStoreNumber, getSourceId } from "../selectors/persistentSelectors";
import { fetchCustomers } from "../services/associateWebProfile/customersEndPoints";
import {
  fetchCustomer,
  putCustomer,
  postCustomer,
  postCustomerV2,
  fetchCustomerV2,
} from "../services/associateWeb/customerEndPoints";
import {
  postPersonStatus,
  deletePersonStatus,
} from "../services/associateWebProfile/personStatusEndPoints";
import normalizeCustomers from "../utils/normalizeCustomers";
import { getPhoneFields, getPhoneType, getContacts } from "../utils/customerProfile";
import {
  CUSTOMER_PHONE_FIELDS,
  ACTIVE_IN_PRISM_ID,
  customerEmailTypes,
  channelTypes,
  communicationTypes,
  UPDATE_CUSTOMER_REQUEST_FIELDS,
  CUSTOMER_PROFILE_FIELDS,
  communicationTypeDescriptions,
  customerPhoneTypes,
  UPDATE_CUSTOMER_HOTEL_REQUEST_FIELDS,
} from "../constants/customerProfileConstants";
import { chooseCreateOrUpdatePhone } from "./customers/shared/utils";
import { onCreateCustomerPreferences } from "./customers/customerPreferencesSaga";

import { waitForRequests } from "./utilsSaga";
import petsActionTypes from "../actionTypes/petsActionTypes";

import removeEmpty from "../utils/objectUtils/removeEmpty";
import customerActionCreator from "../actionCreators/customer/customerActionCreator";
import getIsHotelWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import { updatePrismCustomerProfile } from "@/web/updatePrismCustomerProfile/updatePrismCustomerProfile";
import { postEnrollInServices } from "../services/webServicesEngagement/enrollInServicesEndpoints";
import getPetServiceTypeIdByBookingFlow from "../utils/customer/status/getPetServiceTypeIdByBookingFlow";
import updateCommunicationPreferences from "../utils/customer/preferences/updateCommunicationPreferences";
import compareStrings from "../utils/stringUtils/compareStrings";
import { setCustomerFTCOEligible } from "@/dux/bgm/ftco/ftcoActions";
import { setEligiblePromotions } from "dux/eligiblePromotions/eligiblePromotionsActions";

//* Customer
export function* onLoadCustomer({ customerKey }) {
  // cc: loadItinerariesFromDB#8;loadCustomers;Load Customers
  try {
    yield put(customersActionCreators.loadCustomerRequest());
    yield waitForRequests([petsActionTypes.UPDATE_PET]);

    const response = yield call(fetchCustomerV2, { customerKey });
    const customer = response?.data?.result;
    const partialError = response?.data?.errors;
    const { customers: normalizedCustomers, pets } = normalizeCustomers(customer);
    yield put(petsActionCreators.loadPetsSuccess({ pets }));
    yield put(
      customersActionCreators.loadCustomerSuccess({ customer: normalizedCustomers[customerKey] }),
    );

    yield put(setCustomerFTCOEligible(customer.isNewServicesCustomer));
    yield put(setEligiblePromotions(customer.eligiblePromotions));

    // Need to return partial error to determine if customer is enrolled
    // in PRISM or not. Based on this enrollment status, we will determine which
    // SF endpoints we are able to call. If the "errors" array returned in the response
    // payload is not empty, we know that a customer is not yet enrolled in PRISM.
    return { customer, partialError };
  } catch (error) {
    yield put(
      customersActionCreators.loadCustomerFailure({
        error: { customerKey, ...error.response.data },
      }),
    );
  }
}

export function* onUpdateCustomer({ customerKey, data, isInHotel }) {
  try {
    yield put(customersActionCreators.updateCustomerRequest());
    const [sourceId, customerInfo] = yield all([
      yield select(getStoreNumber),
      yield select(getCustomer, { customerKey }),
    ]);

    let requestPayload;
    if (isInHotel) {
      requestPayload = pick(UPDATE_CUSTOMER_HOTEL_REQUEST_FIELDS, { ...customerInfo, ...data });
    } else {
      requestPayload = pick(UPDATE_CUSTOMER_REQUEST_FIELDS, { ...customerInfo, ...data });
    }

    const response = yield call(putCustomer, {
      customerKey,
      sourceId,
      data: {
        sourceId,
        ...requestPayload,
      },
    });
    const customer = response.data;
    const { customers: normalizedCustomers, pets } = normalizeCustomers(customer);
    yield put(petsActionCreators.loadPetsSuccess({ pets }));
    yield put(
      customersActionCreators.updateCustomerSuccess({ customer: normalizedCustomers[customerKey] }),
    );
    yield put(setCustomerFTCOEligible(customer.isNewServicesCustomer));
    yield put(setEligiblePromotions(customer.eligiblePromotions));
  } catch (error) {
    yield put(customersActionCreators.updateCustomerFailure({ error }));
  }
}

/* * ----------------------------------------------------------------------- * *\
  When a new customer is created A few things need to happen before the
  postCustomer call can be made.
   - Some form fields can not be sent in the body, so they must be removed
   - Once removed the body can be built as needed.
   - other fields like isVip will need to be updated after the postCustomer call
\* * ----------------------------------------------------------------------- * */
function* onCreateCustomer({ data }) {
  try {
    yield put(customersActionCreators.createCustomerRequest());
    const sourceId = yield select(getSourceId);

    const formValues = removeEmpty(data);

    // Values to remove so they are not sent with the body of the postCustomer call
    const formValuesToOmit = concat(CUSTOMER_PROFILE_FIELDS, CUSTOMER_PHONE_FIELDS);

    // Remove those values
    let createCustomerPayload = omit(formValuesToOmit, formValues);

    if (formValues.email) {
      createCustomerPayload = {
        ...createCustomerPayload,
        email: {
          email: formValues.email,
          isEmailVerified: true,
          isPrimary: true,
          emailType: customerEmailTypes.HOME,
        },
      };
    }

    const phoneFields = getPhoneFields(formValues);
    const [requiredPhone, ...optionalPhones] = phoneFields;
    const [requiredPhoneKey, requiredPhoneValue] = requiredPhone;

    const requestPayload = {
      sourceId,
      data: {
        ...createCustomerPayload,
        phone: {
          isPrimary: true,
          phoneNumber: requiredPhoneValue,
          phoneType: getPhoneType(requiredPhoneKey),
        },
        reminderOptOutFlag: true, // opt out users from reminder calls by default
        sourceId,
        statuses: [
          {
            sourceId,
            statusId: ACTIVE_IN_PRISM_ID,
          },
        ],
      },
    };

    const response = yield call(postCustomerV2, requestPayload);

    const customer = response?.data?.result;
    const customerKey = customer?.customerKey;

    const { customers: normalizedCustomers } = normalizeCustomers(customer);
    yield put(customerActionCreator.setCurrentCustomerKey({ currentCustomerKey: customerKey }));
    yield put(
      customersActionCreators.loadCustomerSuccess({ customer: normalizedCustomers[customerKey] }),
    );

    const createAPICalls = [];

    const shouldCreateOptionalPhones = optionalPhones.length;
    // If customer sets more than one phone, create them through the profile API
    if (shouldCreateOptionalPhones) {
      optionalPhones.forEach(([key, phoneNumberFromForm]) => {
        const createPhoneAPICall = call(chooseCreateOrUpdatePhone, {
          customerKey,
          phoneNumberFromForm,
          phoneType: getPhoneType(key),
        });
        createAPICalls.push(createPhoneAPICall);
      });
    }

    yield all(createAPICalls);

    /* * ----------------------------------------------------------------------- * *\
      Make the profile call and patch additional data such as isVip.

      Additional work will need to be done once additional profiles are added.
    \* * ----------------------------------------------------------------------- * */
    const isHotelFeatureFlagEnabled = yield select(
      state => !getIsHotelWorkflowFeatureFlagHidden(state),
    );
    const bookingFlow = yield select(getSystemBookingFlow);

    const shouldUpdatePrismCustomerProfile =
      isHotelFeatureFlagEnabled && bookingFlow === systemName.HOTEL;

    // update PRISM customer profile
    if (shouldUpdatePrismCustomerProfile) {
      yield call(updatePrismCustomerProfile, {
        customerKey,
        isVip: formValues.isVip,
        psaOnFile: formValues.psaOnFile,
      });
    }

    const [customerPhones, customerEmails] = yield all([
      yield select(getPhonesByCustomer, { customerKey }),
      yield select(getEmailsByCustomer, { customerKey }),
    ]);

    const customerPreferencesRequestPayload = [];

    if (customerPhones.length) {
      // Add contact preferences for phones. With Express Consent, mobile numbers
      // need to be automatically opted-in when created in the system. Also, now
      // that we are using POST customer v2 as part of Express Consent, contact
      // preferences are automatically created for the mobile phone number that is included
      // as part of the POST customer payload. To prevent an error from being returned
      // from the phone preferences API, we will exclude the phone number included in the create customer
      // payload (mobile phone #) from the create phone preferences request payload.
      const phoneContactPreferences = customerPhones
        .filter(phone => !compareStrings(phone.phoneType, customerPhoneTypes.MOBILE))
        .map(phone => {
          return {
            contactId: phone.phoneId,
            channels: [
              {
                channelId: channelTypes.SERVICES,
                isOptedOut: true,
                isPreferred: phone.phoneNumber === requiredPhoneValue,
              },
            ],
          };
        });
      customerPreferencesRequestPayload.push({
        communicationTypeId: communicationTypes.TEXT,
        contacts: phoneContactPreferences,
      });
    }

    // Add customer contact preferences for emails
    if (customerEmails.length) {
      const emailContactPreferences = customerEmails.map(email => ({
        contactId: email.emailAddressId,
        channels: [
          {
            channelId: channelTypes.MARKETING,
            isOptedOut: true,
            isPreferred: true,
          },
        ],
      }));
      customerPreferencesRequestPayload.push({
        communicationTypeId: communicationTypes.EMAIL,
        contacts: emailContactPreferences,
      });
    }

    yield call(onCreateCustomerPreferences, {
      sourceId,
      customerKey,
      data: customerPreferencesRequestPayload,
    });

    const updatedCustomer = yield select(getCustomer, { customerKey });
    yield put(customersActionCreators.createCustomerSuccess({ customer: updatedCustomer }));

    history.replace(`/pet-parent-profile/${customerKey}`);
  } catch (error) {
    yield put(customersActionCreators.createCustomerFailure({ error }));
  }
}

//* Customers
function* onloadCustomers(SearchType) {
  try {
    // Clear old search results
    yield put(searchActionCreators.loadCustomersSearchResults({ customers: [] }));

    // Showing in the redux logger we are making a the request
    yield put(customersActionCreators.loadCustomersRequest());

    let searchParams = SearchType.searchValue;

    if (SearchType.searchValue.email) {
      searchParams = {
        email: SearchType.searchValue.email.trim(),
      };
    }

    if (SearchType.searchValue.name) {
      const splitString = SearchType.searchValue.name.trim().split(" ");
      searchParams = {
        storeNumber: yield select(getStoreNumber),
        firstName: splitString[0],
        middleName: splitString.slice(1, -1).join("-"),
        lastName: splitString.slice(-1)[0],
      };
    }

    // Make the API call
    const response = yield call(fetchCustomers, searchParams);

    const customers = response.data ? response.data.customer : [];
    yield put(searchActionCreators.loadCustomersSearchResults({ customers }));
    const { customers: normalizedCustomers, pets } = normalizeCustomers(customers);
    yield put(petsActionCreators.loadPetsSuccess({ pets }));
    yield put(customersActionCreators.loadCustomersSuccess({ customers: normalizedCustomers }));
  } catch (error) {
    yield put(customersActionCreators.loadCustomersFailure({ error }));
  }
}

function* onSetCustomerActive({ customerKey }) {
  try {
    yield put(customersActionCreators.setCustomerActiveRequest());
    const sourceId = yield select(getSourceId);
    const bookingFlow = yield select(getSystemBookingFlow);

    const petServiceTypeId = getPetServiceTypeIdByBookingFlow(bookingFlow);

    const { data } = yield call(postEnrollInServices, {
      customerKey,
      sourceId,
      data: {
        sourceId,
        petServiceTypeId,
      },
    });
    const { updatedStatuses, updatedCommunicationPreferences } = data.result;

    const customerStatuses = yield select(getCustomerStatuses, { customerKey });
    const isSalonStatusMissing = customerStatuses.every(
      status => status.statusId !== ACTIVE_IN_PRISM_ID,
    );
    let statuses = [];

    // If no salon status is set due to customer migration issue OR only has loyalty status set, make sure that it is added
    if (isSalonStatusMissing) {
      statuses = statuses.concat(updatedStatuses);
    } else {
      statuses = customerStatuses.map(status =>
        status.statusId === ACTIVE_IN_PRISM_ID ? { ...status, isActive: true } : { ...status },
      );
    }

    const phones = yield select(getPhonesByCustomer, { customerKey });

    // Set updated status and phone communication preferences on the customer level
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          statuses,
          customerKey: Number(customerKey),

          // Update opt-in/opt-out options for text messaging based on results
          // returned from POST enroll in services API call
          phones: phones.map(
            updateCommunicationPreferences({
              updatedContacts: getContacts(updatedCommunicationPreferences),
              communicationTypeDescription: communicationTypeDescriptions.TEXT,
              communicationTypeId: communicationTypes.TEXT,
              id: "phoneId",
            }),
          ),
        },
      }),
    );

    yield put(customersActionCreators.setCustomerActiveSuccess());
  } catch (error) {
    yield put(customersActionCreators.setCustomerActiveFailure({ error }));
  }
}

function* onSetCustomerInactive({ customerKey }) {
  try {
    yield put(customersActionCreators.setCustomerInactiveRequest());
    const sourceId = yield select(getSourceId);
    yield call(deletePersonStatus, {
      customerKey,
      sourceId,
      data: {
        sourceId,
        statusId: ACTIVE_IN_PRISM_ID,
      },
    });
    const customerStatuses = yield select(getCustomerStatuses, { customerKey });
    const updatedStatuses = customerStatuses.map(status =>
      status.statusId === ACTIVE_IN_PRISM_ID ? { ...status, isActive: false } : { ...status },
    );
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          statuses: updatedStatuses,
        },
      }),
    );
    yield put(customersActionCreators.setCustomerInactiveSuccess());
  } catch (error) {
    yield put(customersActionCreators.setCustomerInactiveFailure({ error }));
  }
}

function* watchLoadCustomer() {
  yield takeEvery(customersActionTypes.LOAD_CUSTOMER, onLoadCustomer);
}

function* watchUpdateCustomer() {
  yield takeEvery(customersActionTypes.UPDATE_CUSTOMER, onUpdateCustomer);
}

function* watchCreateCustomer() {
  yield takeEvery(customersActionTypes.CREATE_CUSTOMER, onCreateCustomer);
}

function* watchloadCustomers() {
  yield takeEvery(customersActionTypes.LOAD_CUSTOMERS, onloadCustomers);
}

function* watchSetCustomerActive() {
  yield takeEvery(customersActionTypes.SET_CUSTOMER_ACTIVE, onSetCustomerActive);
}

function* watchSetCustomerInactive() {
  yield takeEvery(customersActionTypes.SET_CUSTOMER_INACTIVE, onSetCustomerInactive);
}

export default function* customersSaga() {
  yield all([
    fork(watchLoadCustomer),
    fork(watchUpdateCustomer),
    fork(watchloadCustomers),
    fork(watchCreateCustomer),
    fork(watchSetCustomerActive),
    fork(watchSetCustomerInactive),
  ]);
}
