import { connect } from "react-redux";
import { getFormattedPackageDetails } from "../availableBundlesByPetSelectors";
import BundlePackageDetailsComponent from "../../../_components/bgm/availableBundlesByPet/availableBundlesList/BundlePackageDetailsComponent";

const mapStateToProps = (state, { bundleNumber, idEnum }) => {
  const { customerId, petId } = idEnum;

  return {
    componentID: "bundlePackageDetails_BundlePackageDetailsComponent",
    petId,
    customerId,
    bundleNumber,
    componentId: "BundlePackageDetailsComponent",
    data: getFormattedPackageDetails(state, { petId, bundleNumber }),
  };
};

export default connect(mapStateToProps)(BundlePackageDetailsComponent);
