import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Retrieve a list of absence reasons from the System Services Booking API
 * - Retrieve a list of absence reasons
 * @returns {*} AxiosPromise
 */
export function fetchAbsenceReasons() {
  return bookingAPI.get(`${endpointVersionString()}/settings/absence-reasons`);
}
