import { put, call } from "redux-saga/effects";
import { customerEligibilityFlow } from "dux/eligibility/sagas/eligibilityCustomerFlowSaga";
import { eligibilitySubjectTypes } from "dux/eligibility/constants/eligibilityConstants";
import { onGetEligibiltyMultiplePets } from "dux/eligibility/sagas/getEligibiltyMultiplePetsSaga";
import { loadEligibilityResults } from "dux/eligibility/actions/eligibilityLoadClearActions";

/**
 * Responsible for combining customer eligibility and multiple Pets eligibility
 * @param{ string }customerKey
 * @param { string }eligibilityType
 * @param { string }itineraryId
 * @param { string }startDate
 * @param { boolean }includePets
 * @return {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{type: string, results: *}>>|SimpleEffect<"CALL", CallEffectDescriptor>, void, {}>}
 */
export function* onGetUnifiedEligibility({
  customerKey,
  eligibilityType,
  itineraryId,
  startDate,
  includePets = true,
}) {
  // Get customer eligibility from teh customer flow saga.
  const customerEligibityResults = yield call(customerEligibilityFlow, {
    customerKey,
    eligibilityType,
    eligibilitySubjectType: eligibilitySubjectTypes.CUSTOMER,
    startDate,
  });

  // If we need to include multiple pets, we grab them from the saga, else just return an empty object
  const multiplePetsEligibilityResults = includePets
    ? yield call(onGetEligibiltyMultiplePets, {
        customerKey,
        itineraryId,
        eligibilityType,
        startDate,
      })
    : {};

  // put the results of customer and multiple pet eligibility together and send off via an action.
  yield put(
    loadEligibilityResults({
      customer: customerEligibityResults,
      pets: multiplePetsEligibilityResults,
    }),
  );
}
