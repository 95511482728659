import voidTransactionActionTypes from "../../core/actionTypes/voidTransactionActionTypes";

export const voidTransaction = ({ customerId, itineraryId, data }) => ({
  type: voidTransactionActionTypes.VOID_TRANSACTION,
  customerId,
  itineraryId,
  data
});

export const voidTransactionRequest = () => ({
  type: voidTransactionActionTypes.VOID_TRANSACTION_REQUEST
});

export const voidTransactionSuccess = ({ itinerary }) => ({
  type: voidTransactionActionTypes.VOID_TRANSACTION_SUCCESS,
  payload: itinerary
});

export const voidTransactionFailure = ({ error }) => ({
  type: voidTransactionActionTypes.VOID_TRANSACTION_FAILURE,
  error
});

export const loadVoidTransactionsReasons = () => ({
  type: voidTransactionActionTypes.LOAD_VOID_TRANSACTIONS_REASONS
});

export const loadVoidTransactionsReasonsRequest = () => ({
  type: voidTransactionActionTypes.LOAD_VOID_TRANSACTIONS_REASONS_REQUEST
});

export const loadVoidTransactionsReasonsSuccess = ({ reasons }) => ({
  type: voidTransactionActionTypes.LOAD_VOID_TRANSACTIONS_REASONS_SUCCESS,
  payload: reasons
});

export const loadVoidTransactionsReasonsFailure = ({ error }) => ({
  type: voidTransactionActionTypes.LOAD_VOID_TRANSACTIONS_REASONS_FAILURE,
  error
});

export default {
  voidTransaction,
  voidTransactionRequest,
  voidTransactionFailure,
  voidTransactionSuccess,
  loadVoidTransactionsReasons,
  loadVoidTransactionsReasonsRequest,
  loadVoidTransactionsReasonsSuccess,
  loadVoidTransactionsReasonsFailure
};
