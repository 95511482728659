import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Checks the customer's eligibility to book pet services and returns an indication of whether or not the customer can book.
 * From the System Services Booking API
 * - Get customer's eligibility for booking
 * @param {*} { customerKey }
 * @returns {*} AxiosPromise
 */
export function fetchCustomerEligibility({ customerKey }) {
  return bookingAPI.get(`${endpointVersionString()}/customers/${customerKey}/eligibility`);
}

/**
 * Checks the customer's eligibility to book pet services for hotel and returns an indication of whether or not the customer can book.
 * From the System Services Booking API
 * - Get customer's eligibility for hotel booking
 * @param {*} { customerKey }
 * @returns {*} AxiosPromise
 */
export function fetchHotelCustomerEligibility({ customerKey }) {
  return Promise.resolve(mockPet.owners)
}