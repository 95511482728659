import { connect } from "react-redux";
import AddButton from "../../../common/buttons/addButton";
import addOnsActionCreator from "../../../../core/actionCreators/addOnsActionCreator";
import { getShouldShowAddons } from "../../../../core/selectors/ui/addons/addonSelector";

const mapStateToProps = state => {
  const showAddons = getShouldShowAddons(state);
  return {
    showAddons,
    opacity: "1",
    label: "Add standalone",
    fontSize: "regular"
  };
};

const mapDispatchToProps = dispatch => ({
  closeAddonsFlyout: () => dispatch(addOnsActionCreator.closeAddonsFlyout()),
  openAddonsFlyout: () => dispatch(addOnsActionCreator.openAddOnsFlyout())
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  buttonClickHandler: () => {
    propsFromState.showAddons
      ? propsFromDispatch.closeAddonsFlyout()
      : propsFromDispatch.openAddonsFlyout();
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AddButton);
