export const modalTypes = {
  SEARCH_VET: "SEARCH_VET",
  ADD_VET: "ADD_VET",
  DELETE_VET: "DELETE_VET",
  DELETE_VACCINATION: "DELETE_VACCINATION",
  ADD_PET_WARNING: "ADD_PET_WARNING"
};

export const favourabilityOptions = [
  { value: 1, label: "Caution" },
  { value: 2, label: "Neutral" },
  { value: 3, label: "Not a good fit" },
  { value: 4, label: "Preferred" }
];
