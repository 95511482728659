import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get a list of the itineraries scheduled at the specified store
 * for the specified date or date range.
 * For System Services Booking API
 * - Get itineraries scheduled at a store
 * @param {*} { storeNumber, ...params }
 * @returns {*} AxiosPromise
 */
export function fetchItineraries({ storeNumber, ...params }) {
  return bookingAPI.get(`${endpointVersionString()}/stores/${storeNumber}/itineraries`, {
    params
  });
}

/**
 * Search for customer itineraries at the specified store (or all stores) and for the specified pet (or all pets).
 * For System Services Booking API
 * - Find itineraries
 * @param {*} { customerKey, ...params }
 * @returns {*} AxiosPromise
 */
export function fetchItinerariesByCustomer({ customerKey, ...params }) {
  return bookingAPI.get(`${endpointVersionString()}/customers/${customerKey}/itineraries`, {
    params
  });
}
