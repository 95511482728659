import authActionTypes from "core/actionTypes/authActionTypes";
import { checkForUndefined } from "core/utils/undefinedOrNullUtils/undefinedOrNullUtils";

const initialState = "";
/**
 * A reducer function to handle authentication related actions, specifically
 * setting the authenticated subscription key. It takes the current state and
 * an action as arguments and returns the new state.
 *
 * @memberof Reducer.persistent
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @returns {Object} - The new state.
 */
export const authenticatedSubscriptionKeyReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.SET_AUTHENTICATED_SUBSCRIPTION_KEY:
      return checkForUndefined(action.authenticatedSubscriptionKey, initialState);

    default:
      return state;
  }
};