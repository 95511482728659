import React from "react";
import styled from "styled-components";
import { Formik } from "formik";
import Form from "../../../common/Form";
import FormInput from "../../../common/FormInput";
import Button from "../../../common/commonButton";

const PetHealthConditionsForm = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 1.25em 1.25em 0.75em 1.25em;
`;

const CheckboxWrapper = styled.div`
  overflow-y: auto;
  height: 300px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding: 1em 0 0 0;
`;

export default ({
  petHealthConditionsFormData,
  petHealthConditionsInitialValues,
  submitForm,
  closeFlyout
}) => (
  <Formik
    initialValues={petHealthConditionsInitialValues}
    onSubmit={values => {
      submitForm({
        initialValues: petHealthConditionsInitialValues,
        formValues: values
      });
      closeFlyout();
    }}
    enableReinitialize
    render={({ handleSubmit }) => (
      <PetHealthConditionsForm>
        <CheckboxWrapper>
          <Form displayVertical>
            {petHealthConditionsFormData.map(({ Description, ConditionId }) => (
              <FormInput label={Description} type="checkbox" name={ConditionId} key={ConditionId} />
            ))}
          </Form>
        </CheckboxWrapper>
        <ButtonWrapper>
          <Button label="Done" onClick={handleSubmit} />
        </ButtonWrapper>
      </PetHealthConditionsForm>
    )}
  />
);
