import { connect } from "react-redux";
import BulkPackageOfferingsCartDetailsPetCardComponent from "../../../_components/bgm/cartDetails/BulkPackageOfferingsCartDetailsPetCardComponent";

const mapStateToProps = () => {
  return {
    componentID: "bulk-package-offerings-cart-details-pet-card",
    isHidden: false,
  };
};

export default connect(mapStateToProps)(BulkPackageOfferingsCartDetailsPetCardComponent);
