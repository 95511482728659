import React from "react";
import CommonDeleteModal from "../../../../common/modals/shared/CommonDeleteModal";

export default ({ emailAddressId, customerKey, isLoading, handleRemove, onClose }) => (
  <CommonDeleteModal
    heading="Remove email"
    message="Are you sure you want to remove this email?"
    buttonLabel="Remove email"
    handleRemove={() => handleRemove({ customerKey, emailAddressId })}
    onClose={onClose}
    disabled={isLoading}
  />
);
