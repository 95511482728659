/** @module pinValidation */

/**
 * Verifies the set of numbers being entered are, or are not sequential
 *
 * @export isSequentialPin
 * @param {string} str
 * @returns{boolean}
 */
export function isSequentialPin(str) {
  return (
    str &&
    str.split("").every((curr, i, arr) => {
      const prev = arr[i - 1];

      if (i === 0) {
        return true;
      }

      if (curr - prev === 1) {
        return true;
      }
      return false;
    })
  );
}

/**
 *
 *
 * @export allCharactersSame
 * @param {*} str
 * @returns
 */
export function allCharactersSame(str) {
  return (
    str &&
    str.split("").every((current, index, arr) => {
      const start = arr[0];
      return current === start;
    })
  );
}
