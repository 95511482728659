/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
/**
 *  @constant
 *  type {String}
 */
export const PATCH_HOTEL_ITINERARY_PET_NOTES = "PATCH_HOTEL_ITINERARY_PET_NOTES";

/**
 *  @constant
 *  type {String}
 */
export const PATCH_HOTEL_ITINERARY_PET_NOTES_REQUEST = "PATCH_HOTEL_ITINERARY_PET_NOTES_REQUEST";

/**
 *  @constant
 *  type {String}
 */
export const PATCH_HOTEL_ITINERARY_PET_NOTES_SUCCESS = "PATCH_HOTEL_ITINERARY_PET_NOTES_SUCCESS";

/**
 *  @constant
 *  type {String}
 */
export const PATCH_HOTEL_ITINERARY_PET_NOTES_FAILURE = "PATCH_HOTEL_ITINERARY_PET_NOTES_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 * Action to patch pet notes to itinerary
 *
 * @memberOf Sagas.hotel.itinerary
 * @function
 * @name patchHotelItineraryPetNotes
 * @param {Object} petNotesData
 * @returns {Object}
 * @example patchHotelItineraryPetNotes(petNotesData)
 */
export const patchHotelItineraryPetNotes = petNotesData => ({
  type: PATCH_HOTEL_ITINERARY_PET_NOTES,
  petNotesData,
});

/**
 * Request action for itinerary reservation notes patch
 *
 * @memberOf Sagas.hotel.itinerary
 * @function
 * @name patchHotelItineraryPetNotesRequest
 * @returns {Object}
 * @example patchHotelItineraryPetNotesRequest()
 */
export const patchHotelItineraryPetNotesRequest = () => ({
  type: PATCH_HOTEL_ITINERARY_PET_NOTES_REQUEST,
});

/**
 * Success action for itinerary reservation notes patch
 *
 * @memberOf Sagas.hotel.itinerary
 * @function
 * @name patchHotelItineraryPetNotesSuccess
 * @returns {Object}
 * @example patchHotelItineraryPetNotesSuccess()
 */
export const patchHotelItineraryPetNotesSuccess = () => ({
  type: PATCH_HOTEL_ITINERARY_PET_NOTES_SUCCESS,
});

/**
 * Failure action for itinerary reservation notes patch
 *
 * @memberOf Sagas.hotel.itinerary
 * @function
 * @name patchHotelItineraryPetNotesFailure
 * @param {Object} error - error caught in try catch
 * @returns {Object}
 * @example patchHotelItineraryPetNotesFailure()
 */
export const patchHotelItineraryPetNotesFailure = error => ({
  type: PATCH_HOTEL_ITINERARY_PET_NOTES_FAILURE,
  error,
});
