import React from "react";
import { Layout } from "@prism/psm-ui-components";

import CustomerCardListContainer from "@/web/searchSalonSRC/customerCard/CustomerCardListContainer";
import StoreCardListContainer from "@/web/searchSalonSRC/storeCard/StoreCardListContainer";
import NumberOfResultsCustomerContainer from "@/web/searchSalonSRC/NumberOfResults/NumberOfResultsCustomerContainer";
import CreatePetParentButtonContainer from "@/web/searchSalonSRC/createPetParentButton/CreatePetParentButtonContainer";
import PetVetCardListContainer from "@/web/searchSalonSRC/petVetCard/PetVetCardListContainer";
import NumberOfResultsVetContainer from "@/web/searchSalonSRC/NumberOfResults/NumberOfResultsVetContainer";

function ResultListComponent({ isLoading, itemsToDisplay}) {

  const List = itemsToDisplay;
  if (isLoading) {
    return <p>Loading search results...</p>;
  }
  return (
    <Layout.Stack space="stack-space-4">
      <NumberOfResultsCustomerContainer />
      <NumberOfResultsVetContainer />
      <Layout.Box style={{ width: "100%", overflowY: "auto", maxHeight: "325px" }}>
        <List />
      </Layout.Box>
      <CreatePetParentButtonContainer />
    </Layout.Stack>
  );
}

export default ResultListComponent;
