// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prism-reports--selected-report-type-title {
  display: none;
}

.prism-reports--tableContent {
  zoom: 100%;
  padding: 0;
}

@media print {
  @page {
    /* !!! DO NOT !!!! add other properties to size, it will affect label printing */
    size: landscape;
    margin: 0;
  }

  /* All the items that need to be hidden when printing */
  .prism-reports--header,
  .prism-reports--sub-header,
  .prism-reports--table {
    display: none;
  }

  .prism-reports--selected-report-type-title {
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/dux/reports/reports.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,UAAU;AACZ;;AAEA;EACE;IACE,gFAAgF;IAChF,eAAe;IACf,SAAS;EACX;;EAEA,uDAAuD;EACvD;;;IAGE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":[".prism-reports--selected-report-type-title {\n  display: none;\n}\n\n.prism-reports--tableContent {\n  zoom: 100%;\n  padding: 0;\n}\n\n@media print {\n  @page {\n    /* !!! DO NOT !!!! add other properties to size, it will affect label printing */\n    size: landscape;\n    margin: 0;\n  }\n\n  /* All the items that need to be hidden when printing */\n  .prism-reports--header,\n  .prism-reports--sub-header,\n  .prism-reports--table {\n    display: none;\n  }\n\n  .prism-reports--selected-report-type-title {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
