import { filter } from "lodash/fp";
import { NOTIFICATION_CENTER } from "dux/storeWideNotes/storeWideNotesConstants";

/**
 * A helper function to determine if the API should fire.
 * @param {String | undefined } dateTime - UTC time
 * @param { Array } list
 * @param { Boolean } meetsTimeRequirement
 * @return {boolean}
 */
export const shouldFireApi = (dateTime, list, meetsTimeRequirement) => {
  if (dateTime === undefined || dateTime === false) {
    return true;
  }

  return list.length === 0 && meetsTimeRequirement;
};

export const filterStoreWideNotifications = results => {
  const filteredResults = filter(
    item => item.displayLocations.includes(NOTIFICATION_CENTER),
    results,
  );

  const notificationsList = filteredResults.map(
    ({ displayLocations, plainTextMessage, richTextMessage, serviceLocationType }) => {
      return {
        displayLocations,
        plainTextMessage,
        richTextMessage,
        serviceLocationType,
      };
    },
  );

  return { notificationsList, lastAPICall: new Date() };
};
