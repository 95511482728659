import React from "react";
import styled from "styled-components";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
`;

const Icon = styled.img`
  height: 13px;
  padding: 1px;
`;

export default function Icons(props) {
  // Component JSX
  const { icons } = props;
  return (
    <SectionWrapper>
      {icons.map(icon => {
        return <Icon key={icon} src={icon} />;
      })}
    </SectionWrapper>
  );
}
