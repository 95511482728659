const features = {
  SVCSART123: {
    id: "SVCSART-123",
    isHidden: true,
  },
  SVCSART371: {
    id: "SVCSART-371",
    isHidden: true,
  },
  SVCSART651: {
    id: "SVCSART-651",
    isHidden: true,
  },
  SVCSART728: {
    id: "SVCSART-728",
    isHidden: true,
  },
  SVCSART727: {
    id: "SVCSART-727",
    isHidden: true,
  },
  SVCSART428: {
    id: "SVCSART-428",
    isHidden: true,
  },
  SVCSART569: {
    id: "SVCSART-569",
    isHidden: true,
  },
  SVCSART1039: {
    id: "SVCSART-1039",
    isHidden: true,
  },
  SVCSART305: {
    id: "SVCSART-305",
    isHidden: true,
  },
  SVCSART790: {
    id: "SVCSART-790",
    isHidden: true,
  },
  SVCSART529: {
    id: "SVCSART-529",
    isHidden: true,
    requirements: ["SVCSART-790"],
  },
  SVCSART1018: {
    id: "SVCSART-1018",
    isHidden: true,
    requirements: ["SVCSART-790", "SVCSART-529"],
  },
  SVCSART1017: {
    id: "SVCSART-1017",
    isHidden: true,
    requirements: ["SVCSART-790", "SVCSART-529", "SVCSART-1018"],
  },
  SVCSART467: {
    id: "SVCSART-467",
    isHidden: true,
    requirements: ["SVCSART-569", "SVCSART-728"],
  },
  SVCSART468: {
    id: "SVCSART-468",
    isHidden: true,
  },
  SVCSART475: {
    id: "SVCSART-475",
    isHidden: true,
  },
  SVCSART333: {
    id: "SVCSART-333",
    isHidden: true,
    requirements: ["SVCSART-728", "SVCSART-569"],
  },
  SVCSART479: {
    id: "SVCSART-479",
    isHidden: true,
    requirements: ["SVCSART-475", "SVCSART-569", "SVCSART-728"],
  },
  SVCSART476: {
    id: "SVCSART-476",
    isHidden: true,
    requirements: ["SVCSART-475", "SVCSART-479"],
  },
  SVCSART469: {
    id: "SVCSART-469",
    isHidden: true,
    requirements: ["SVCSART-475", "SVCSART-479"],
  },
  SVCSART1195: {
    id: "SVCSART-1195",
    isHidden: true,
  },
  SVCSART474: {
    id: "SVCSART-474",
    isHidden: true,
    requirements: ["SVCSART-467"],
  },
  SVCSART560: {
    id: "SVCSART-560",
    isHidden: true,
    requirements: ["SVCSART-728", "SVCSART-569"],
  },
  SVCSART306: {
    id: "SVCSART-306",
    isHidden: true,
    requirements: ["SVCSART-728", "SVCSART-305"],
  },
  SVCSART478: {
    id: "SVCSART-478",
    isHidden: true,
    requirements: ["SVCSART-474"],
  },
  SVCSART470: {
    id: "SVCSART-470",
    isHidden: true,
    requirements: ["SVCSART-474", "SVCSART-478"],
  },
  SVCSART1627: {
    id: "SVCSART-1627",
    isHidden: true,
    requirements: [],
  },
  SVCSART477: {
    id: "SVCSART-477",
    isHidden: true,
    requirements: ["SVCSART-474", "SVCSART-478"],
  },
  SVCSART1434: {
    id: "SVCSART-1434",
    isHidden: true,
    requirements: [],
  },
};

export default features;
