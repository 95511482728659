/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const medicationListActionTypes = {
  SHOW_HIDE_MEDICATION_DETAILS: "SHOW_HIDE_MEDICATION_DETAILS",
  REMOVE_PET_MED_FROM_BOOKING: "REMOVE_PET_MED_FROM_BOOKING",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const showHideMedicationDetails = ({ medicationId, petId, locationTypes }) => ({
  type: medicationListActionTypes.SHOW_HIDE_MEDICATION_DETAILS,
  medicationId,
  petId,
  locationTypes,
});

export const removePetMedFromBooking = ({ medicationId, petId }) => ({
  type: medicationListActionTypes.REMOVE_PET_MED_FROM_BOOKING,
  medicationId,
  petId,
});
