/**
 * Saga to update addons for an itinerary via PUT
 * @memberOf helpers.hotel.itinerary
 * @function
 * @name updateItineraryWithNewPetAddons
 * @param {Object} itinerary
 * @param {string} petId
 * @param {Array} updatedEngagements
 * @return updateItineraryWithNewPetAddons
 */
export const updateItineraryWithNewPetAddons = (
  { pets, ...itinerary } = {},
  petId,
  updatedEngagements,
) => {
  const otherPets = pets?.filter(({ petKey }) => petKey !== petId);
  const pet = pets?.find(({ petKey }) => petKey === petId);

  if (!pet) return { ...itinerary, pets };

  const updatedPet = {
    ...pet,
    engagements: pet?.engagements?.map(engagement => {
      const updatedEngagement = updatedEngagements?.find(
        ({ engagementId }) => engagementId === engagement?.engagementId,
      );
      const addOns = updatedEngagement?.addOns ?? engagement?.addOns;
      return { ...engagement, addOns };
    }),
  };

  return { ...itinerary, pets: [...otherPets, updatedPet] };
};
