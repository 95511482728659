import { put, takeEvery, call, all } from "redux-saga/effects";
import { noop } from "lodash";
import {
  GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER,
  getAvailableBundlesForAllPetsByCustomerRequest,
  getAvailableBundlesForAllPetsByCustomerSuccess,
  getAvailableBundlesForAllPetsByCustomerFailure,
  GET_AVAILABLE_BUNDLES_BY_PET,
  getAvailableBundlesByPetRequest,
  getAvailableBundlesByPetSuccess,
  getAvailableBundlesByPetFailure,
} from "./availableBundlesByPetActions";
import { updatePetSuccess } from "../../../core/actionCreators/petsActionCreators";
import getAvailableBundlesByPet from "../../../core/services/systemServicesBooking/availableBundlesByPetEndpoints";
import getAvailableBundlesForAllPetsByCustomer from "../../../core/services/systemServicesBooking/availableBundlesForAllPetsByCustomerEndpoints";

function* onGetAvailableBundlesByPet({ petId }) {
  try {
    yield put(getAvailableBundlesByPetRequest());

    const {
      data: {
        result: { bundles },
      },
    } = yield call(getAvailableBundlesByPet, { petId });

    yield put(
      updatePetSuccess({
        pet: {
          petId: Number(petId),
          bundles,
        },
      }),
    );

    yield put(getAvailableBundlesByPetSuccess());
  } catch (error) {
    yield put(getAvailableBundlesByPetFailure({ error }));
  }
}

export function* onGetAvailableBundlesByCustomerForAllPets({ customerKey, onComplete = noop }) {
  try {
    yield put(getAvailableBundlesForAllPetsByCustomerRequest());

    const response = yield call(getAvailableBundlesForAllPetsByCustomer, { customerKey });
    const { pets } = response.data.result;

    yield all(
      pets.map(({ petId, bundles }) => {
        return put(
          updatePetSuccess({
            pet: {
              petId: Number(petId),
              bundles,
            },
          }),
        );
      }),
    );

    yield put(getAvailableBundlesForAllPetsByCustomerSuccess());
    onComplete(response);
  } catch (error) {
    yield put(getAvailableBundlesForAllPetsByCustomerFailure({ error }));
  }
}

function* watchLoadGetAvailableBundlesByPet() {
  yield takeEvery([GET_AVAILABLE_BUNDLES_BY_PET], onGetAvailableBundlesByPet);
}

function* watchLoadGetAvailableBundlesAllPetsByCustomer() {
  yield takeEvery(
    [GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER],
    onGetAvailableBundlesByCustomerForAllPets,
  );
}

export default function* getAvailableBundlesByPetSaga() {
  yield all([watchLoadGetAvailableBundlesByPet(), watchLoadGetAvailableBundlesAllPetsByCustomer()]);
}
