import {
  GET_HOTEL_SERVICE_SUSPENSION_SUCCESS,
  PATCH_HOTEL_SERVICE_SUSPENSION_SUCCESS, POST_HOTEL_SERVICE_SUSPENSION_SUCCESS,
} from "dux/scheduleSuspensionPage/scheduleSuspensionActions";
import {
  POST_HOTEL_BG_SERVICE_SUSPENSION_IS_UPDATED,
  POST_HOTEL_SERVICE_SUSPENSION_IS_UPDATED,
} from "dux/servicesManagementToggle/servicesManagementToggleActions";

const initialState = { suspensionsList: [] };

const hotelScheduleSuspensionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTEL_SERVICE_SUSPENSION_SUCCESS:
      return {
        ...state,
        suspensionsList: action.suspensions,
      };
    case POST_HOTEL_SERVICE_SUSPENSION_IS_UPDATED:
      return {
        ...state,
        [`is${action.reasonTypeName}Updated`]: action.isUpdated,
      };
    case POST_HOTEL_BG_SERVICE_SUSPENSION_IS_UPDATED:
      return {
        ...state,
        isBGUpdate: action.idUpdated,
      };
    case PATCH_HOTEL_SERVICE_SUSPENSION_SUCCESS:
      return {
        ...state,
        suspensionsList: action.suspensions,
      };
    case POST_HOTEL_SERVICE_SUSPENSION_SUCCESS:
      return {
        ...state,
        suspensionsList: action.suspensions,
      };
    default:
      return state;
  }
};

export default hotelScheduleSuspensionReducer;
