import { connect } from "react-redux";
import { getSelectedAddOnName, getSelectedAddOnPrice } from "../bulkPackageOfferingsSelectors";
import PricingLineItemComponent from "../../../_components/pricing/PricingLineItemComponent";

const mapStateToProps = state => {
  const price = getSelectedAddOnPrice(state);
  return {
    componentID: `bulk-package-offerings-cart-details-add-ons`,
    label: getSelectedAddOnName(state),
    price: price ? `$${price?.toFixed(2)}` : null,
    isHidden: false,
  };
};

export default connect(mapStateToProps, null)(PricingLineItemComponent);
