/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelCheckedOutFilterButtonActionTypes = {
  FILTER_BY_OVERNIGHT_CHECKED_OUT: "FILTER_BY_OVERNIGHT_CHECKED_OUT",
  FILTER_BY_DAY_CAMP_CHECKED_OUT: "FILTER_BY_DAY_CAMP_CHECKED_OUT",
  FILTER_BY_DAY_CARE_CHECKED_OUT: "FILTER_BY_DAY_CARE_CHECKED_OUT",
  FILTER_BY_All_CHECKED_OUT: "FILTER_BY_All_CHECKED_OUT",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const filterByOverNightCheckedOut = ({ filterBtnStatus }) => ({
  type: hotelCheckedOutFilterButtonActionTypes.FILTER_BY_OVERNIGHT_CHECKED_OUT,
  filterBtnStatus,
});

export const filterByDayCampCheckedOut = ({ filterBtnStatus }) => ({
  type: hotelCheckedOutFilterButtonActionTypes.FILTER_BY_DAY_CAMP_CHECKED_OUT,
  filterBtnStatus,
});

export const filterByDayCareCheckedOut = ({ filterBtnStatus }) => ({
  type: hotelCheckedOutFilterButtonActionTypes.FILTER_BY_DAY_CARE_CHECKED_OUT,
  filterBtnStatus,
});

export const filterByAllCheckedOut = ({ filterBtnStatus }) => ({
  type: hotelCheckedOutFilterButtonActionTypes.FILTER_BY_All_CHECKED_OUT,
  filterBtnStatus,
});
