/**
 * Responsible for taking a timestamp and formatting it to a short date time , i.e. 11:30 AM
 *
 * @export formatShortDateTime
 * @param {number} timestamp - Date time stamp
 * @returns {string} - Short date time
 */

export default function formatShortDateTime(timestamp) {
  const shortTimeFormatter = new Intl.DateTimeFormat("en", { timeStyle: "short" });

  return timestamp && shortTimeFormatter.format(timestamp);
}
