/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const setFoodTypeAmountActionTypes = {
  SET_FOOD_TYPE_AMOUNT: "SET_FOOD_TYPE_AMOUNT",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setFoodTypeAmount = ({ foodTypeAmount, foodId, petId }) => ({
  type: setFoodTypeAmountActionTypes.SET_FOOD_TYPE_AMOUNT,
  foodTypeAmount,
  foodId,
  petId,
});
