import { connect } from "react-redux";
// import { openFeatureFlagWindow } from "./toggleFeatureFlagWindowActions";
// import getFeatureFlagVisibleState from "./selectors/getFeatureFlagVisibleState";
// import FlagIcon from "../common/icons/FlagIcon";
// import getFeatureFlagIconVisibleState from "../displayFeatureFlagIcon/selectors/getFeatureFlagIconVisibleState";
import LightBulbIcon from "web/common/icons/LightBulbIcon";
import getFeatureFlagIconVisibleState from "web/displayFeatureFlagIcon/selectors/getFeatureFlagIconVisibleState";
import toggleCookie from "core/utils/cookies/toggleCookie";
import dispalyFeatureFlagIconConstants from "web/displayFeatureFlagIcon/displayFeatureFlagIconConstants";
import { setPersistentCookieIcon } from "dux/featureFlagPersistentCookie/featureFlagPersistentCookieToggleActions";
import { getPersistFFIconIsCookieSet } from "dux/featureFlagPersistentCookie/featureFlagPersistentCookieToggleSelectors";

const mapStateToProps = state => {
  return {
    componentID: "featureFlagPersistentCookieToggle-LightBulbIcon",
    ispPersistent: getPersistFFIconIsCookieSet(state),
    isHidden: getFeatureFlagIconVisibleState(state),
  };
};

const mapDispatchToProps = dispatch => ({
  handleClick: () => {
    const isCookieSet = toggleCookie(dispalyFeatureFlagIconConstants.PERSIST_COOKIE_KEY);
    dispatch(setPersistentCookieIcon({ isCookieSet }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LightBulbIcon);
