import React from 'react';
import './styles.css';
import { pluck } from '../utils';
import { GLOBAL_TOKENS } from '../scaleToken';

// Sets the interface for the Box layout custom properties.
interface SideBarProps<C extends React.ElementType> {

  /** value between the sidebar items */
  space?: keyof typeof GLOBAL_TOKENS['SCALE'];
}

// Include types that are not part of SideBarProps, such as children,
type Props <C extends React.ElementType> = React.PropsWithChildren<SideBarProps<C>> & React.ComponentPropsWithoutRef<C>;

export const LayoutSideBar = <C extends React.ElementType>({
  space = 'scale-S1',
  children,
  style,
  ...props

}: Props<C>): React.ReactElement => {
  return (
    <div
        className="pplt-layout__sidebar"
        {...props}
    >
      <div
          id="sideBar_intermediary"
          style={{
            '--setSideBarSpace': pluck(GLOBAL_TOKENS.SCALE, space),
            ...style
          }}
      >
        {children}
      </div>

    </div>
  );
};
