import { connect } from "react-redux";
import moment from "moment";
import { isEqual } from "lodash/fp";

import { getPetFormData, getPetCoOwners } from "../../../../core/selectors/entitiesSelector";
import {
  getBreedsOptions,
  getGendersOptions,
  getColorsOptions,
  getSpeciesOptions,
} from "../../../../core/selectors/enumsSelectors";
import PetFormComponent from "./PetFormComponent";
import transformObjectByMap from "../../../../core/utils/objectUtils/transformObjectByMap";
import filterObjectByProperties from "../../../../core/utils/objectUtils/filterObjectByProperties";
import getFormFieldsChanged from "../../../../core/utils/formUtils/getFormFieldsChanged";
import petsActionCreators, { updatePet } from "../../../../core/actionCreators/petsActionCreators";
import petsActionTypes from "../../../../core/actionTypes/petsActionTypes";
import { createLoadingSelector } from "../../../../core/selectors/utils";
import { NEW_PET_ID } from "../../../../core/constants";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { getPetParentScreenEligibility } from "@/dux/eligibility/actions/eligibilityByScreenActions";
import { getHotelEligibility } from "@/dux/hotelEligibility/hotelEligibilityActions";
import { eligibilityTypes } from "@/dux/eligibility/constants/eligibilityConstants";

const petFormValuesMap = {
  birthDate: birthDate => birthDate && moment(birthDate).toISOString(),
};

const mapStateToProps = (state, ownProps) => ({
  petFormData: getPetFormData(state, ownProps),
  breeds: getBreedsOptions(state, ownProps),
  genders: getGendersOptions(state),
  colors: getColorsOptions(state),
  species: getSpeciesOptions(state),
  isCreatingPet: createLoadingSelector([petsActionTypes.CREATE_PET])(state),
  petCoOwners: getPetCoOwners(state, ownProps),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: ({ initialValues, formValues }) => {
    if (isEqual(initialValues, formValues)) {
      return;
    }

    const { fieldsToInclude, customerKey } = ownProps;
    const data = getFormFieldsChanged(
      {},
      transformObjectByMap(
        fieldsToInclude ? filterObjectByProperties(formValues, fieldsToInclude) : formValues,
        petFormValuesMap,
      ),
    );
    if (ownProps.petId === NEW_PET_ID) {
      dispatch(
        petsActionCreators.createPet({
          customerKey,
          data,
          onDone: petId => {
            ownProps.onCancelAddPet();
            ownProps.onSelectPet(petId);

            // Call eligibility to sync SF and Pods data
            if (ownProps?.systemName === systemName.HOTEL) {
              dispatch(getHotelEligibility({ eligibilityType: eligibilityTypes.PROFILE }));
            } else {
              dispatch(getPetParentScreenEligibility({ customerKey, includePets: true }));
            }
          },
        }),
      );
    } else {
      const formFieldsChanged = !isEqual(initialValues, formValues);
      if (formFieldsChanged) {
        dispatch(
          petsActionCreators.updatePet({
            petId: ownProps.petId,
            customerKey: ownProps.customerKey,
            data,
          }),
        );
      }
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  PetFormComponent,
);

const CHECK_IN_OUT_PET_FIELDS = ["breedId", "isMixedBreed", "genderId", "isSpayedNeutered"];
export const CheckInOutPetForm = connect(
  (state, ownProps) => ({
    componentId: "CheckInOutPetForm",
    petFormData: getPetFormData(state, ownProps),
    breeds: getBreedsOptions(state, ownProps),
    genders: getGendersOptions(state),
    isLoading: createLoadingSelector([petsActionTypes.UPDATE_PET])(state),
  }),
  (dispatch, { customerKey, petId }) => {
    return {
      fieldsToInclude: CHECK_IN_OUT_PET_FIELDS,
      onSubmit: ({ initialValues, formValues }) => {
        if (isEqual(initialValues, formValues)) return;

        const data = getFormFieldsChanged(
          {},
          transformObjectByMap(
            filterObjectByProperties(formValues, CHECK_IN_OUT_PET_FIELDS),
            petFormValuesMap,
          ),
        );

        const formFieldsChanged = !isEqual(initialValues, formValues);
        if (!formFieldsChanged) return;

        dispatch(updatePet({ petId, customerKey, data }));
      },
    };
  },
  null,
  { forwardRef: true },
)(PetFormComponent);
