import React from "react";
import { connect } from "react-redux";

// Components
import { Heading } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";

// Selectors
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import PetImageComponent from "../_components/petImage/PetImageComponent";
import getPetName from "../PetName/selectors/getPetName";
import getUnknownPetImage from "./selectors/getUnknownPetImage";

/**
 * React view component for pet image, name, and icons
 * @memberOf Views.Salon
 * @function
 * @name PetImageWithLabel
 * @param {Object} props - props passed into the view component
 * @param {boolean} props.isHidden - description
 * @param {string} props.componentId
 * @param {string} props.src - src for image
 * @param {string} props.label - pet name
 * @param {JSX.Element} props.children - icons to display below pet pic and name
 * @returns {JSX.Element}
 * @example const container = connect(mapState, mapDispatch, mergeProps)(PetImageWithLabel)
 */
const PetImageWithLabel = ({ isHidden, componentId, src, label, children }) => {
  if (!isHidden) {
    return (
      <LayoutBox id={componentId} padding="scale-0">
        <LayoutCluster style={{ alignItems: "center" }}>
          <PetImageComponent src={src} />
          <LayoutStack space="scale-0">
            <Heading size="body-lg-bold" tagName="div">
              {label}
            </Heading>
            {children}
          </LayoutStack>
        </LayoutCluster>
      </LayoutBox>
    );
  }

  return null;
};

/**
 * Redux Connect function for the PetImageWithLabel
 * @see {@link Views.Salon.PetImageWithLabel}
 * @summary salon standalone select-service page
 * @memberOf Views.Salon
 * @function
 * @name  BulkPackageOfferingsCartDetailsPetImageWithLabel
 * @returns {JSX.Element|null}
 * @example <BulkPackageOfferingsCartDetailsPetImageWithLabel />
 */
export const BulkPackageOfferingsCartDetailsPetImageWithLabel = connect(state => {
  const petId = getSelectedPet(state);
  return {
    isHidden: false,
    componentId: "BulkPackageOfferingsCartDetailsPetImageWithLabel",
    src: getUnknownPetImage(state, { petId }),
    label: getPetName(state, { petId }),
  };
})(PetImageWithLabel);
