import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { getWeekNumber } from "core/selectors/commonSelector";
import moment from "moment";
import { getWeekFromDate } from "core/utils/dateUtils/getWeekFromDate";
import { setWeekNumber } from "core/actionCreators/ui/web/commonActionCreators";
import { connect } from "react-redux";
import WeekNavigationBar from "web/weekNavigationBar/WeekNavigationBar";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import DaySelectionDashBoardHotelContainer from "web/weekNavigationBar/DaySelectionDashBoardHotelContainer";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import getIsHotelWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import PrevWeekHotelContainer from "web/weekNavigationBar/PrevWeekHotelContainer";
import NextWeekHotelContainer from "web/weekNavigationBar/NextWeekHotelContainer";

const mapStateToProps = state => {
  const selectedDate = getSelectedDate(state);
  const weekNumber = getWeekNumber(state) || moment(selectedDate).week();
  const bookingFlow = getSystemBookingFlow(state);

  return {
    componentId: "hotelWeeklyNavigationBar",
    switcherSpace: "switcher-space-1",
    week: getWeekFromDate({ date: selectedDate }),
    isHidden: bookingFlow !== systemName.HOTEL || getIsHotelWorkflowFeatureFlagHidden(state),
    weekNumber,

    compName: systemName.HOTEL,
    diComp: {
      daySelection: {
        [systemName.HOTEL]: DaySelectionDashBoardHotelContainer,
      },
      nextWeek: {
        [systemName.HOTEL]: NextWeekHotelContainer,
      },
      prevWeek: {
        [systemName.HOTEL]: PrevWeekHotelContainer,
      },
    },
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchWeekNumber: ({ weekNumber }) => dispatch(setWeekNumber({ weekNumber })),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  setWeekNumber: () =>
    propsFromDispatch.dispatchWeekNumber({
      weekNumber: propsFromState.weekNumber,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(WeekNavigationBar);
