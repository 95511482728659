/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const frequencyActionTypes = {
  SET_PENDING_FREQUENCY_FOOD_DATES: "SET_PENDING_FREQUENCY_DATES",
  SET_PENDING_FREQUENCY_MEDICATION_DATES: "SET_PENDING_FREQUENCY_MEDICATION_DATES",
  SET_PENDING_FREQUENCY_ADDON_DATES: "SET_PENDING_FREQUENCY_ADDON_DATES",
  SET_PENDING_FREQUENCY: "SET_PENDING_FREQUENCY",
  SET_CACHED_FREQUENCY: "SET_CACHED_FREQUENCY",
  CLEAR_PENDING_FREQUENCY: "CLEAR_PENDING_FREQUENCY",
  SET_PENDING_ID: "SET_PENDING_ID",
  MED_FREQUENCY_TOUCHED: "MED_FREQUENCY_TOUCHED",
  SHOW_FREQUENCY_MODAL: "SHOW_FREQUENCY_MODAL",
  SET_PENDING_FREQUENCY_IS_FROM_CART: "SET_PENDING_FREQUENCY_IS_FROM_CART",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setPendingFrequencyFoodDates = ({ dates }) => ({
  type: frequencyActionTypes.SET_PENDING_FREQUENCY_FOOD_DATES,
  dates,
});
export const setPendingFrequencyMedicationDates = ({ dates }) => ({
  type: frequencyActionTypes.SET_PENDING_FREQUENCY_MEDICATION_DATES,
  dates,
});
export const setPendingFrequencyAddonDates = ({ dates }) => ({
  type: frequencyActionTypes.SET_PENDING_FREQUENCY_ADDON_DATES,
  dates,
});

export const setPendingId = ({ pendingId, petId, frequencyType }) => ({
  type: frequencyActionTypes.SET_PENDING_ID,
  pendingId,
  petId,
  frequencyType,
});

export const setPendingFrequency = ({ frequency }) => ({
  type: frequencyActionTypes.SET_PENDING_FREQUENCY,
  frequency,
});

export const setCachedFrequency = ({ cachedFrequency }) => ({
  type: frequencyActionTypes.SET_CACHED_FREQUENCY,
  cachedFrequency,
});

export const clearPendingFrequency = () => ({
  type: frequencyActionTypes.CLEAR_PENDING_FREQUENCY,
});

export const setPendingFrequencyIsFromCart = ({ isFromCart }) => ({
  type: frequencyActionTypes.SET_PENDING_FREQUENCY_IS_FROM_CART,
  isFromCart,
});
