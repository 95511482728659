import React from "react";
import PetCartCardContainer from "../../petCartCard/PetCartCardContainer";

export default function PetCartCardsMappingComponent({ isHidden, list }) {
  return !isHidden && list ? (
    <>
      {list.map(petId => {
        return <PetCartCardContainer key={petId} petId={petId} />;
      })}
    </>
  ) : null;
}
