import { connect } from "react-redux";
import SelectWithWrapper from "../common/SelectWithWrapper";
import { MAX_CHECK_IN_OPTIONS } from "../../core/constants/bookingConstants";
import { setFoodName } from "./actions/setFoodNameActions";
import {
  getPetFoodOptions,
  getFoodNameExternalIdById,
  getFoodNameByExternalId,
} from "./foodNameSelectors";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../core/constants/validationErrors";
import { OTHER_FOOD_NAME } from "../newFood/newFoodConstants";
import getIsHotelWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { getFoodIsDiscontinuedById } from "../food/foodsSelector";

const mapStateToProps = (state, ownProps) => {
  const { foodId, foodIndex, petId } = ownProps;
  const value = getFoodNameExternalIdById(state, { foodId, petId });
  const currentFoodName = getFoodNameByExternalId(state, { foodId, petId });
  const isDiscontinued = getFoodIsDiscontinuedById(state, { petId, foodId });
  const discontinuedOption = [{ value, label: currentFoodName }];
  const nameOptions = getIsHotelWorkflowFeatureFlagHidden(state)
    ? MAX_CHECK_IN_OPTIONS
    : getPetFoodOptions(state, { foodId, petId });

  return {
    foodId,
    petId,
    title: `Food ${foodIndex} name`,
    options: isDiscontinued ? discontinuedOption : nameOptions,
    isForm: true,
    componentId: "foodDetails-foodName",
    value,
    currentFoodName,
    error: REQUIRED_FIELD_ERROR_MESSAGE,
    disabled: isDiscontinued,
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeFoodType: ({ option, foodId, currentFoodName, petId }) => {
    if (currentFoodName === OTHER_FOOD_NAME && option?.label !== OTHER_FOOD_NAME) {
      dispatch(setFoodName({ name: option?.label, externalId: option.value, foodId, petId }));
    } else if (option?.label === OTHER_FOOD_NAME) {
      dispatch(
        setFoodName({
          name: "",
          externalId: option.value,
          foodId,
          petId,
        }),
      );
    } else {
      dispatch(
        setFoodName({
          name: option?.label,
          externalId: option.value,
          foodId,
          petId,
        }),
      );
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onChange: option => {
    const { foodId, petId, currentFoodName } = propsFromState;
    propsFromDispatch.onChangeFoodType({ option, foodId, currentFoodName, petId });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SelectWithWrapper);
