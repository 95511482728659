/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelArrivalsHeaderSearchActionTypes = {
  SET_HOTEL_ARRIVAL_SEARCH: "SET_HOTEL_ARRIVAL_SEARCH",
  CLEAR_HOTEL_ARRIVAL_SEARCH: "CLEAR_HOTEL_ARRIVAL_SEARCH",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setHotelArrivalSearchText = ({ value }) => ({
  type: hotelArrivalsHeaderSearchActionTypes.SET_HOTEL_ARRIVAL_SEARCH,
  value,
});

export const clearHotelArrivalSearchText = () => ({
  type: hotelArrivalsHeaderSearchActionTypes.CLEAR_HOTEL_ARRIVAL_SEARCH,
});
