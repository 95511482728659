import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";
import {
  getSearchResultsToDisplay,
  getFormattedStoreSearchResults,
} from "@/core/selectors/searchSalonSRCSelector";
import { searchConstants } from "@/core/constants/searchConstants";
import StoreCardListComponent from "./StoreCardListComponent";

const mapStateToProps = state => {
  const searchResults = getFormattedStoreSearchResults(state);
  return {
    // Fixes an bug (SVCSART-11273) where an invalid search number is searched and an empty box is displayed
    isHidden: getSearchResultsToDisplay(state) !== searchConstants.STORE || isEmpty(searchResults),
  };
};

export default connect(mapStateToProps)(StoreCardListComponent);
