import { GET_SERVICE_PRODUCT_FAMILIES_SUCCESS } from "./serviceProductFamiliesActions";

const initialState = [];

const serviceProductFamiliesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SERVICE_PRODUCT_FAMILIES_SUCCESS:
      return [...state, ...payload];
    default:
      return state;
  }
};

export default serviceProductFamiliesReducer;
