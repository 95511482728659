import { getOr, isEmpty } from "lodash/fp";
import { createSelector } from "reselect";
import { getFoodById, getFoodByPet } from "web/food/foodsSelector";
import { frequencyConstants } from "dux/frequency/FrequencyConstants";

export const getFoodFrequencyById = createSelector(
  [getFoodByPet, (state, props) => props],
  (foods, { foodId }) => getOr(null, [foodId, "frequency"], foods),
);

export const getFoodFrequencyDatesById = createSelector(
  [getFoodByPet, (state, props) => props],
  (foods, { foodId }) => getOr(null, [foodId, "concreteSchedule"], foods),
);

export const getFrequencyIsTouchedById = createSelector([getFoodById], food =>
  getOr(false, ["frequencyTouched"], food),
);

export const getIsFrequencyMissingField = createSelector(
  [getFoodFrequencyById, getFoodFrequencyDatesById],
  (frequency, dates) => {
    if (!frequency) {
      return true;
    }
    if (frequency === frequencyConstants.MANUAL) {
      return isEmpty(dates);
    }
    return false;
  },
);
