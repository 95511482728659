import { omit } from "lodash/fp";
import { setPetCartCardActionTypes } from "../actions/setPetCartCardActions";

const initialState = {};

const hotelBookingPetCartCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case setPetCartCardActionTypes.ADD_CART_FOR_PET:
      return {
        ...state,
        [action.petId]: {},
      };
    case setPetCartCardActionTypes.SHOW_HIDE_PET_CARD:
      return {
        ...state,
        [action.petId]: {
          ...state[action.petId],
          isPetCardShowing: action.isPetCardShowing,
        },
      };
    case setPetCartCardActionTypes.REMOVE_CART_FOR_PET:
      return omit([action.petId], state);
    case setPetCartCardActionTypes.CLEAR_CARTS_FOR_PETS:
      return {};
    default:
      return state;
  }
};

export default hotelBookingPetCartCardsReducer;
