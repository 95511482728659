export const LOAD_PET_SERVICES_AT_HOTEL_BOOKING = "LOAD_PET_SERVICES_AT_HOTEL_BOOKING";
export const LOAD_PET_SERVICES_AT_HOTEL_BOOKING_REQUEST =
  "LOAD_PET_SERVICES_AT_HOTEL_BOOKING_REQUEST";
export const LOAD_PET_SERVICES_AT_HOTEL_BOOKING_SUCCESS =
  "LOAD_PET_SERVICES_AT_HOTEL_BOOKING_SUCCESS";
export const LOAD_PET_SERVICES_AT_HOTEL_BOOKING_FAILURE =
  "LOAD_PET_SERVICES_AT_HOTEL_BOOKING_FAILURE";
export const SAVE_HOTEL_SERVICES = "SAVE_HOTEL_SERVICES";
export const CLEAR_HOTEL_SERVICES = "CLEAR_HOTEL_SERVICES";
export const LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING = "LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING";
export const LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING_REQUEST =
  "LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING_REQUEST";
export const LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING_SUCCESS =
  "LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING_SUCCESS";
export const LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING_FAILURE =
  "LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING_FAILURE";

export const loadPetServicesAtHotelBooking = petId => ({
  type: LOAD_PET_SERVICES_AT_HOTEL_BOOKING,
  petId,
});

export const loadPetServicesAtHotelBookingRequest = () => ({
  type: LOAD_PET_SERVICES_AT_HOTEL_BOOKING_REQUEST,
});

export const loadPetServicesAtHotelBookingSuccess = () => ({
  type: LOAD_PET_SERVICES_AT_HOTEL_BOOKING_SUCCESS,
});

export const loadPetServicesAtHotelBookingFailure = ({ error }) => ({
  type: LOAD_PET_SERVICES_AT_HOTEL_BOOKING_FAILURE,
  error,
});

export const saveHotelServices = ({ petAvailableServicesData }) => ({
  type: SAVE_HOTEL_SERVICES,
  petAvailableServicesData,
});

export const onClearHotelServices = ({ petId }) => ({
  type: CLEAR_HOTEL_SERVICES,
  petId,
});

export const loadPetServicesAtHotelPostBooking = petId => ({
  type: LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING,
  petId,
});

export const loadPetServicesAtHotelPostBookingRequest = () => ({
  type: LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING_REQUEST,
});

export const loadPetServicesAtHotelPostBookingSuccess = () => ({
  type: LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING_SUCCESS,
});

export const loadPetServicesAtHotelPostBookingFailure = ({ error }) => ({
  type: LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING_FAILURE,
  error,
});