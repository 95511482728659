import jobRoleActionTypes from "core/actionTypes/jobRoleActionTypes";
import authActionTypes from "core/actionTypes/authActionTypes";

const initialState = {};
/**
 * Reducer function to handle session-related actions in the Redux store.
 *
 *@memberof Reducer.persistent
 * @param {Object} state - The current state of the session.
 * @param {Object} action - The action object that contains a type and payload.
 *
 * @returns {Object} - The new state after applying the action.
 */
export const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
      };
    case authActionTypes.SET_ID_TOKEN:
      return {
        ...state,
        idToken: action.idToken,
      };
    case authActionTypes.SET_USER_SESSION:
      return {
        ...state,
        ...action.sessionData,
      };
    case authActionTypes.SET_SESSION_TOKEN:
      return {
        ...state,
        sessionToken: action.sessionToken,
      };
    case authActionTypes.REFRESH_SESSION_TOKEN:
      return {
        ...state,
      };
    case authActionTypes.CLEAR_SESSION_TOKEN:
      // eslint-disable-next-line no-case-declarations
      const { sessionToken, ...sessionProps } = state;
      return {
        ...sessionProps,
      };
    case authActionTypes.CLEAR_USER_SESSION:
      return {
        ...initialState,
      };
    case jobRoleActionTypes.UPDATE_ACCESS_LEVEL_IN_STATE:
      return {
        ...state,
        oktaUserInfoResponse: {
          ...state.oktaUserInfoResponse,
          accessLevel: action.mockAccessLevel.accessLevel,
          isAccessLevelMocked: action.mockAccessLevel.isAccessLevelMocked,
          canElevate: action.mockAccessLevel.canElevate,
          isPinRequired: action.mockAccessLevel.isPinRequired,
          sessionValidDuration: action.mockAccessLevel.sessionValidDuration,
        },
      };

    default:
      return state;
  }
};
