import { associateWebAPI } from "./_axiosConfigAssociateWeb";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Add note to customer from Web Associate API - Add Notes to Customer
 * @param {*} { customerKey, content }
 * @returns
 */
export function postCustomerNote({ customerKey, content }) {
  return associateWebAPI
    .post(`${endpointVersionString()}/customers/${customerKey}/notes`, { content })
    .then(response => response);
}

/**
 * Add note to pet. from Web Associate API - Add Pet Notes
 * @param {*} { customerKey, content, petId }
 * @returns
 */
export function postGroomingNote({ customerKey, content, petId }) {
  return associateWebAPI
    .post(`${endpointVersionString()}/customers/${customerKey}/pets/${petId}/notes`, {
      content,
      type: "grooming"
    })
    .then(response => response);
}
