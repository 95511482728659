import { jobRoleConstants } from "../../constants/jobRoleConstants";
import { searchConstants } from "../../constants/searchConstants";

/** @module jobRoleToSearchComponentName */

/**
 * Returns Search Type of Store or Customer based on Job role.
 *
 * @export mapJobRoleToSearchComponentName
 * @param {string} jobRole;
 * @returns {string}
 */
export default function mapJobRoleToSearchComponentName(jobRole) {
  return jobRole === jobRoleConstants.SRC ? searchConstants.STORE : searchConstants.CUSTOMER;
}
