//@ts-check

/**
 *
 * @param {object} state
 * @param {object} [state.hotelRoomManagementSuspensions]
 * @param {string} [state.hotelRoomManagementSuspensions.roomSuspensionReason]
 * @returns {string} - Selected Room Suspesion Reason
 */
export const selectHotelRoomSuspensionReason = state =>
  state?.hotelRoomManagementSuspensions?.roomSuspensionReason || "";
