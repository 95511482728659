// @ts-check
import {
  POST_OVER_BOOKING_NEW_EXCEPTION_DATE_SUCCESS,
  POST_OVER_BOOKING_NEW_EXCEPTION_DATE_FAILURE,
} from "./overBookingNewExceptionDateActions";

const initialState = {};

const overBookingNewExceptionDateReducer = (
  state = initialState,
  /** @type {{ type: string; payload: any; }} */ action,
) => {
  switch (action.type) {
    case POST_OVER_BOOKING_NEW_EXCEPTION_DATE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case POST_OVER_BOOKING_NEW_EXCEPTION_DATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default overBookingNewExceptionDateReducer;
