import { connect } from "react-redux";
import { getPendingFrequencyType } from "dux/frequency/frequencySelectors";
import { selectDateRangeForFrequency } from "../dateRangeForFrequencySelectors";
import React from "react";
import { FrequencyClearDates } from "dux/frequency/modal/FrequencyClearDates";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutBox } from "@/layout/box/Box";
import { FREQUENCY_CHECKBOXES } from "@/dux/_components/medication/frequency/FrequencyCheckboxMappingComponent";

export const FrequencyCheckboxMappingComponent = ({
  isHidden,
  list,
  title,
  componentId,
  frequencyType,
}) => {
  const FrequencyCheckbox = FREQUENCY_CHECKBOXES[frequencyType];

  return !isHidden && list ? (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack style={{ overflowY: "auto", maxHeight: "40vh" }} space="scale-0">
        <LayoutBox id={`${componentId}__title`}>
          <LayoutCluster style={{ justifyContent: "space-between" }}>
            <div>{title}</div>
            <FrequencyClearDates />
          </LayoutCluster>
        </LayoutBox>

        {list.map(date => (
          <FrequencyCheckbox key={date} date={date} />
        ))}
      </LayoutStack>
    </LayoutBox>
  ) : null;
};

const mapStateToProps = state => {
  const list = selectDateRangeForFrequency(state);
  return {
    title: "Dates",
    componentId: "medicationFrequency-medicationCheckboxDates",
    list,
    frequencyType: getPendingFrequencyType(state),
  };
};

export default connect(mapStateToProps)(FrequencyCheckboxMappingComponent);
