import { put, takeEvery, call, all, select } from "redux-saga/effects";
import { noop } from "lodash/fp";
import salonActionTypes from "../actionTypes/salonActionTypes";
import generalActionTypes from "../actionTypes/ui/web/generalActionTypes";
import salonActionCreator from "../actionCreators/salonActionCreator";
import { getStoreInformation, getSalonTimes } from "../../core/selectors/entitiesSelector";
import { getStoreNumber, getIsSRCAgent } from "../../core/selectors/persistentSelectors";
import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";
import {
  FetchSalonInfoWithHours,
  FetchSalonInfoWithServices,
} from "../services/petsmartWebPublic/salonEndPoints";
import { setStoreNumber } from "../actionCreators/ui/web/generalActionCreators";
import salonHoursActionCreators from "@/core/actionCreators/salonHoursActionCreators";
import { normalizeSalonHours } from "@/dux/utils/normalize/normalizeSalonHours";

/** ----------------------------------------------------------------------- **\
    When a search for a store is conducted this Saga will retrieve the store
    information, specificity , the store hours, send the info to populate the
    UI.
\** ----------------------------------------------------------------------- * */
function* onLoadSalonWithHours({ storeNumber }) {
  if (!storeNumber) {
    return;
  }

  try {
    yield put(salonActionCreator.loadSalonInfoWithHoursRequest());
    const response = yield call(FetchSalonInfoWithHours, { storeNumber });

    const salon = response.data;
    yield put(salonActionCreator.loadSalonInfoWithHoursSuccess({ salon }));

    return salon;
  } catch (error) {
    yield put(salonActionCreator.loadSalonInfoWithHoursFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
    When a search for a store is conducted this Saga will retrieve the store
    information, specificity , the store hours, send the info to populate the
    UI.
\** ----------------------------------------------------------------------- * */
function* onLoadEngagementSalon({ engagementStoreNumber }) {
  try {
    yield put(salonActionCreator.loadSalonInfoWithHoursRequest());
    const isSRCAgent = yield select(getIsSRCAgent);
    const storeNumber = yield select(getStoreNumber);
    let currentSalon = yield select(getStoreInformation, { storeNumber });

    const isEngagementStoreNumberUpdated = storeNumber && engagementStoreNumber !== storeNumber;

    // Only update current salon if one is missing OR the user is an SRC agent.
    // We want to grant SRC agents the capability to modify or cancel an appointment
    // for ALL stores, even if they originally logged in using a different store.
    const shouldUpdateCurrentSalon =
      !currentSalon || (isSRCAgent && isEngagementStoreNumberUpdated);

    if (shouldUpdateCurrentSalon) {
      const storeNumberToFetch = engagementStoreNumber || storeNumber;

      yield put(setStoreNumber({ storeNumber: storeNumberToFetch }));

      const { data } = yield call(FetchSalonInfoWithHours, { storeNumber: storeNumberToFetch });
      currentSalon = data;
    }

    if (isEngagementStoreNumberUpdated) {
      const response = yield call(FetchSalonInfoWithHours, { storeNumber: engagementStoreNumber });
      const newSalon = response.data;
      const salons = [newSalon, currentSalon];
      yield put(
        salonActionCreator.loadSalonsInfoWithHoursSuccess({
          salons: normalizeArrayByProperty(salons, "StoreNumber"),
        }),
      );
    } else {
      yield put(salonActionCreator.loadSalonInfoWithHoursSuccess({ salon: currentSalon }));
    }
  } catch (error) {
    yield put(salonActionCreator.loadSalonInfoWithHoursFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
    When a search for a store is conducted this Saga will retrieve the store
    information, specificity for Services offered at the store, and send the
    info to populate the UI and update the store number in the reducer.
\** ----------------------------------------------------------------------- * */
function* onLoadSalonWithServices({ storeNumber }) {
  try {
    yield put(salonActionCreator.loadSalonInfoWithServicesRequest());
    const response = yield call(FetchSalonInfoWithServices, { storeNumber });

    // need to wrap sing store onbject into an array ass the redux state requires an array
    const salon = [response.data];

    yield put(salonActionCreator.loadSalonInfoWithServicesSuccess({ salon }));
  } catch (error) {
    yield put(salonActionCreator.loadSalonInfoWithServicesFailure({ error }));
  }
}

function* onLoadSalonWithServicesForSrcStoreSearch({
  storeNumber,
  onSuccess = noop,
  onError = noop,
}) {
  try {
    yield put(salonActionCreator.loadSalonInfoWithServicesForSrcStoreSearchRequest());

    try {
      const response = yield call(FetchSalonInfoWithHours, { storeNumber });
      const salonHours = getSalonTimes(response.data);

      yield put(
        salonHoursActionCreators.loadSalonHoursSuccess({
          salonHours: normalizeSalonHours(salonHours),
        }),
      );

      onSuccess(response.data);
    } catch (error) {
      onError(error);
    }

    yield put(salonActionCreator.loadSalonInfoWithServicesForSrcStoreSearchSuccess());
  } catch (error) {
    yield put(salonActionCreator.loadSalonInfoWithServicesForSrcStoreSearchFailure({ error }));
  }
}

function* watchLoadSalonWithHours() {
  yield takeEvery(
    [salonActionTypes.LOAD_SALON_INFO_WITH_HOURS, generalActionTypes.SET_STORE_NUMBER],
    onLoadSalonWithHours,
  );
}

function* watchLoadEngagementSalon() {
  yield takeEvery(salonActionTypes.LOAD_ENGAGEMENT_SALON, onLoadEngagementSalon);
}

function* watchLoadSalonWithServices() {
  yield takeEvery(salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES, onLoadSalonWithServices);
}

function* watchLoadSalonWithServicesForSrcStoreSearch() {
  yield takeEvery(
    salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH,
    onLoadSalonWithServicesForSrcStoreSearch,
  );
}

export default function* salonSaga() {
  yield all([
    watchLoadSalonWithHours(),
    watchLoadEngagementSalon(),
    watchLoadSalonWithServices(),
    watchLoadSalonWithServicesForSrcStoreSearch(),
  ]);
}
