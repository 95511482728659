import { connect } from "react-redux";
import { isFTCOHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import { getFirstTimeCustomerInfo } from "./ftcoSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { FIRST_TIME_CUSTOMER_MESSAGE } from "./ftcoConstants";
import IconWithLabel from "@/dux/_components/icon/IconWithLabel";
import PreCheckedInIcon from "../../../assets/icons/pre-checked-in-appointment.svg";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  const { firstName } = getFirstTimeCustomerInfo(state, { customerKey });
  return {
    firstName,
    componentId: "ftco-indicator",
    isHidden: isFTCOHidden(state),
    src: PreCheckedInIcon,
    label: `${firstName} ${FIRST_TIME_CUSTOMER_MESSAGE}`,
    styleObj: {
      padding: "1em 0 0 0",
      justifyContent: "center",
    },
  };
};

export default connect(mapStateToProps)(IconWithLabel);
