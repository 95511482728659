import { connect } from "react-redux";
import { selectCartHotelTotalPrice } from "dux/servicesCartHotel/servicesCartHotelSelectors";
import TotalPriceHotelBookingComponent from "../_components/HotelCart/Booking/TotalPriceHotelBookingComponent";

const mapStateToProps = (state) => {
  const totalPrice = selectCartHotelTotalPrice(state);

  return {
    isHidden: false,
    label: "Total price for all pets",
    componentId: "hotelBooking-hotelTotalPriceLabel",
    totalPrice: `$${totalPrice.toFixed(2)}`,
  };
};

export default connect(mapStateToProps)(TotalPriceHotelBookingComponent);
