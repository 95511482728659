import { includes, get } from "lodash/fp";
import { APPOINTMENT_STATUS } from "../../constants/index";

/** @module rebookErrorMessage */

/**
 * returns an error message(s) if no associate number, no associate in a list of associates or no petServiceID in a list of petServiceIDs
 *
 * @export
 * @param {Object} obj - Destructuring parameters
 * @param {number} associate
 * @param {object} associates
 * @param {number} petServiceId
 * @param {array} petServiceIds
 * @returns
 */
export default function buildRebookErrorMessages({
  associate,
  associates,
  petServiceId,
  petServiceIds,
  status
}) {
  const errorMessages = [];

  // when there is not a previous associate and the appointment was not previously canceled, the user is just rebooking a
  // non-canceled (status other than canceled) previous appointment .
  if (associate === null && status !== APPOINTMENT_STATUS.CANCELED) {
    errorMessages.push(
      `Previously assigned associate is no longer available. User must select a new associate for this service.`
    );
  } else if (associate === null && status == APPOINTMENT_STATUS.CANCELED) {
    errorMessages.push(
      `Rebooking a cancelled appointment does not add the associate that was previously scheduled for this service`
    );
  } else if (!get(associate, associates)) {
    errorMessages.push(`Associate doesn't exist anymore.`);
  }

  if (!includes(petServiceId, petServiceIds)) {
    errorMessages.push(`Service doesn't exist anymore.`);
  }
  return errorMessages;
}
