import { connect } from "react-redux";

import { columnHeaders } from "../../../../core/constants/dashboardContants";
import ListFilterButtons from "../../../_components/buttons/ListFilterButtons";
import {
  HotelAllFilterBtnCompNames,
  HotelDayCampFilterBtnCompNames, HotelDayCareFilterBtnCompNames,
  HotelOverNightFilterBtnCompNames,
} from "../../../_constants/dynamicComponentNames";

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => ({
  isHidden: false,
  componentID: "ListFilterButtons__HotelCheckedOut",
  compName: columnHeaders.CHECKED_OUT,
  filters: [
    HotelOverNightFilterBtnCompNames,
    HotelDayCampFilterBtnCompNames,
    HotelDayCareFilterBtnCompNames,
    HotelAllFilterBtnCompNames,
  ],
});


// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = dispatch => ({
  // keyName: ({}) => dispatch(actionName({})),
});

// const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
//   ...propsFromState,
//   ...propsFromDispatch,
//   ...ownProps,
//
//   keyName: ({}) => propsFromDispatch.functionName({
//     keyName: propsFromState.value,
//   }),
//   keyName: ({}) => propsFromDispatch.functionName({
//     keyName: propsFromState.value,
//   }),
// });


export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // mergeProps,
)(ListFilterButtons);
