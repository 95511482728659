import itinerariesActionTypes from "../actionTypes/itinerariesActionTypes";

export const loadItinerariesByCustomer = ({ customerKey, fromDate, toDate, serviceTypes }) => ({
  type: itinerariesActionTypes.LOAD_ITINERARIES_BY_CUSTOMER,
  customerKey,
  fromDate,
  toDate,
  serviceTypes
});

export const loadItinerariesByCustomerRequest = () => ({
  type: itinerariesActionTypes.LOAD_ITINERARIES_BY_CUSTOMER_REQUEST
});

export const loadItinerariesByCustomerFailure = ({ error }) => ({
  type: itinerariesActionTypes.LOAD_ITINERARIES_BY_CUSTOMER_FAILURE,
  error
});

export const loadItinerariesByCustomerSuccess = ({ itineraries }) => ({
  type: itinerariesActionTypes.LOAD_ITINERARIES_BY_CUSTOMER_SUCCESS,
  payload: itineraries
});

export const loadItineraries = ({ fromDate, toDate, shouldLoadPets, shouldLoadCustomers }) => ({
  type: itinerariesActionTypes.LOAD_ITINERARIES,
  fromDate,
  toDate,
  shouldLoadPets,
  shouldLoadCustomers
});

// Duplicate action to separate loadings
export const loadItinerariesLiveUpdate = ({
  fromDate,
  toDate,
  shouldLoadPets,
  shouldLoadCustomers,
  serviceTypes
}) => ({
  type: itinerariesActionTypes.LOAD_ITINERARIES_LIVE_UPDATES,
  fromDate,
  toDate,
  shouldLoadPets,
  shouldLoadCustomers,
  serviceTypes,
});

export const loadItinerariesRequest = () => ({
  type: itinerariesActionTypes.LOAD_ITINERARIES_REQUEST
});

export const loadItinerariesSuccess = ({ itineraries }) => ({
  type: itinerariesActionTypes.LOAD_ITINERARIES_SUCCESS,
  payload: itineraries
});

export const loadItinerariesFailure = ({ error }) => ({
  type: itinerariesActionTypes.LOAD_ITINERARIES_FAILURE,
  error
});

export const createItinerary = ({ customerKey, data, dispatchToCart }) => ({
  type: itinerariesActionTypes.CREATE_ITINERARY,
  customerKey,
  data,
  dispatchToCart
});

export const createItineraryRequest = () => ({
  type: itinerariesActionTypes.CREATE_ITINERARY_REQUEST
});

export const createItinerarySuccess = ({ itinerary }) => ({
  type: itinerariesActionTypes.CREATE_ITINERARY_SUCCESS,
  payload: itinerary
});

export const createItineraryFailure = ({ error }) => ({
  type: itinerariesActionTypes.CREATE_ITINERARY_FAILURE,
  error
});

export const deleteItinerary = ({ customerKey, itineraryId }) => ({
  type: itinerariesActionTypes.DELETE_ITINERARY,
  customerKey,
  itineraryId
});

export const deleteItineraryRequest = () => ({
  type: itinerariesActionTypes.DELETE_ITINERARY_REQUEST
});

export const deleteItinerarySuccess = ({ itineraryID }) => ({
  type: itinerariesActionTypes.DELETE_ITINERARY_SUCCESS,
  payload: itineraryID
});

export const deleteItineraryFailure = ({ error }) => ({
  type: itinerariesActionTypes.DELETE_ITINERARY_FAILURE,
  error
});

export const finalizeItinerary = ({ customerKey, itineraryID }) => ({
  type: itinerariesActionTypes.FINALIZE_ITINERARY,
  customerKey,
  itineraryID
});

export const finalizeItineraryRequest = () => ({
  type: itinerariesActionTypes.FINALIZE_ITINERARY_REQUEST
});

export const finalizeItinerarySuccess = ({ itinerary }) => ({
  type: itinerariesActionTypes.FINALIZE_ITINERARY_SUCCESS,
  payload: itinerary
});

export const finalizeItineraryFailure = ({ error }) => ({
  type: itinerariesActionTypes.FINALIZE_ITINERARY_FAILURE,
  error
});

export const loadItinerary = ({
  customerKey,
  itineraryId,
  shouldLoadCustomers,
  shouldLoadPets
}) => ({
  type: itinerariesActionTypes.LOAD_ITINERARY,
  customerKey,
  itineraryId,
  shouldLoadCustomers,
  shouldLoadPets
});

export const loadItineraryRequest = () => ({
  type: itinerariesActionTypes.LOAD_ITINERARY_REQUEST
});

export const loadItinerarySuccess = ({ itinerary }) => ({
  type: itinerariesActionTypes.LOAD_ITINERARY_SUCCESS,
  payload: itinerary
});

export const loadItineraryFailure = ({ error }) => ({
  type: itinerariesActionTypes.LOAD_ITINERARY_FAILURE,
  error
});

export const loadServiceCard = ({ customerKey, itineraryId, engagementId }) => ({
  type: itinerariesActionTypes.LOAD_SERVICE_CARD,
  customerKey,
  itineraryId,
  engagementId
});

export const loadServiceCardRequest = () => ({
  type: itinerariesActionTypes.LOAD_SERVICE_CARD_REQUEST
});

export const loadServiceCardSuccess = () => ({
  type: itinerariesActionTypes.LOAD_SERVICE_CARD_SUCCESS
});

export const loadServiceCardFailure = ({ error }) => ({
  type: itinerariesActionTypes.LOAD_SERVICE_CARD_FAILURE,
  error
});

export const loadServiceCards = ({ customerKey, itineraryId, engagementId }) => ({
  type: itinerariesActionTypes.LOAD_SERVICE_CARDS,
  customerKey,
  itineraryId,
  engagementId
});

export const loadServiceCardsRequest = () => ({
  type: itinerariesActionTypes.LOAD_SERVICE_CARDS_REQUEST
});

export const loadServiceCardsSuccess = () => ({
  type: itinerariesActionTypes.LOAD_SERVICE_CARDS_SUCCESS
});

export const loadServiceCardsFailure = ({ error }) => ({
  type: itinerariesActionTypes.LOAD_SERVICE_CARDS_FAILURE,
  error
});

export const loadInvoice = ({ customerKey, itineraryId, petId }) => ({
  type: itinerariesActionTypes.LOAD_INVOICE,
  customerKey,
  itineraryId,
  petId
});

export const loadInvoiceRequest = () => ({
  type: itinerariesActionTypes.LOAD_INVOICE_REQUEST
});

export const loadInvoiceSuccess = () => ({
  type: itinerariesActionTypes.LOAD_INVOICE_SUCCESS
});

export const loadInvoiceFailure = ({ error }) => ({
  type: itinerariesActionTypes.LOAD_INVOICE_FAILURE,
  error
});

export const updateItineraryStatus = ({ pageName, customerId, itineraryId, reason, status }) => ({
  type: itinerariesActionTypes.UPDATE_ITINERARY_STATUS,
  customerId,
  itineraryId,
  reason,
  status,
  pageName
});

export const updateItineraryStatusRequest = () => ({
  type: itinerariesActionTypes.UPDATE_ITINERARY_STATUS_REQUEST
});

export const updateItineraryStatusSuccess = ({ itinerary }) => ({
  type: itinerariesActionTypes.UPDATE_ITINERARY_STATUS_SUCCESS,
  payload: itinerary
});

export const updateItineraryStatusFailure = ({ error }) => ({
  type: itinerariesActionTypes.UPDATE_ITINERARY_STATUS_FAILURE,
  error
});

export const updateItineraryPaymentStatus = ({ customerId, itineraryId, data }) => ({
  type: itinerariesActionTypes.UPDATE_ITINERARY_PAYMENT_STATUS,
  customerId,
  itineraryId,
  data
});

export const updateItineraryPaymentStatusRequest = () => ({
  type: itinerariesActionTypes.UPDATE_ITINERARY_PAYMENT_STATUS_REQUEST
});

export const updateItineraryPaymentStatusSuccess = ({ itinerary }) => ({
  type: itinerariesActionTypes.UPDATE_ITINERARY_PAYMENT_STATUS_SUCCESS,
  payload: itinerary
});

export const updateItineraryPaymentStatusFailure = error => ({
  type: itinerariesActionTypes.UPDATE_ITINERARY_PAYMENT_STATUS_FAILURE,
  error
});

export const createItineraryFromExistingItinerary = ({ customerKey, itineraryId }) => ({
  type: itinerariesActionTypes.CREATE_ITINERARY_FROM_EXISTING_ITINERARY,
  customerKey,
  itineraryId
});

export const undoLastItineraryStatusUpdate = ({ customerId, itineraryId }) => ({
  type: itinerariesActionTypes.UNDO_LAST_ITINERARY_STATUS_UPDATE,
  customerId,
  itineraryId
});

export const undoLastItineraryStatusUpdateRequest = () => ({
  type: itinerariesActionTypes.UNDO_LAST_ITINERARY_STATUS_UPDATE_REQUEST
});

export const undoLastItineraryStatusUpdateSuccess = ({ itinerary }) => ({
  type: itinerariesActionTypes.UNDO_LAST_ITINERARY_STATUS_UPDATE_SUCCESS,
  payload: itinerary
});

export const undoLastItineraryStatusUpdateFailure = ({ error }) => ({
  type: itinerariesActionTypes.UNDO_LAST_ITINERARY_STATUS_UPDATE_FAILURE,
  payload: error
});

export default {
  loadItineraries,
  loadItinerariesRequest,
  loadItinerariesSuccess,
  loadItinerariesFailure,
  loadItinerariesLiveUpdate,
  loadItinerariesByCustomer,
  loadItinerariesByCustomerRequest,
  loadItinerariesByCustomerFailure,
  loadItinerariesByCustomerSuccess,
  loadItinerary,
  loadItineraryRequest,
  loadItineraryFailure,
  loadItinerarySuccess,
  createItinerary,
  createItineraryRequest,
  createItineraryFailure,
  createItinerarySuccess,
  finalizeItinerary,
  finalizeItineraryRequest,
  finalizeItineraryFailure,
  finalizeItinerarySuccess,
  deleteItinerary,
  deleteItineraryRequest,
  deleteItineraryFailure,
  deleteItinerarySuccess,
  loadServiceCard,
  loadServiceCardFailure,
  loadServiceCardRequest,
  loadServiceCardSuccess,
  loadServiceCards,
  loadServiceCardsFailure,
  loadServiceCardsRequest,
  loadServiceCardsSuccess,
  loadInvoice,
  loadInvoiceRequest,
  loadInvoiceSuccess,
  loadInvoiceFailure,
  updateItineraryStatus,
  updateItineraryStatusRequest,
  updateItineraryStatusSuccess,
  updateItineraryStatusFailure,
  updateItineraryPaymentStatus,
  updateItineraryPaymentStatusRequest,
  updateItineraryPaymentStatusSuccess,
  updateItineraryPaymentStatusFailure,
  createItineraryFromExistingItinerary,
  undoLastItineraryStatusUpdate,
  undoLastItineraryStatusUpdateRequest,
  undoLastItineraryStatusUpdateSuccess,
  undoLastItineraryStatusUpdateFailure
};
