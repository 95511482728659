import { SET_USER_STATUS } from "dux/userStatus/userStatusActions";

/**
 * Represents the initial state of the user's status
 *
 * @typedef {Object} initialState
 * @property {active | idle} status - The current users status type
 * @property {number} time - The millisecond representation of when the current status changed
 */
const initialState = {
  status: "active",
  time: Date.now(),
};

/**
 * Redux reducer function for managing the users status state.
 *
 * @param {Object} state - The current state of the user's status.
 * @param {Object} action - The action object that contains information on how to modify the state.
 * @returns {Object} The updated state after applying the action.
 */
export const userStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_STATUS:
      return { ...state, ...{ status: action.status, time: action.time } };
    default:
      return state;
  }
};
