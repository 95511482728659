/**
 * Remove any numbers from a string
 * @memberOf Utils.Strings
 * @function
 * @name removeNumbers
 * @param {string} input - string to remove any characters from
 * @returns string
 * @example
 * const truncatedString = removeNumbers(I Like5 all th3 Numb3r5)
 */
export const removeNumbers = input => {
  return input?.replace(/[0-9]/g, "");
};
