import React from "react";
import BulkPackageOfferingsContent from "../../../bgm/bulkPackageOfferings/offerings/BulkPackageOfferingsContentContainer";
import BulkPackageOfferingsHeaderContainer from "../../../bgm/bulkPackageOfferings/offerings/BulkPackageOfferingsHeaderContainer";
import BulkPackageOfferingsHeaderErrorMessageContainer from "../../../bgm/bulkPackageOfferings/offerings/BulkPackageOfferingsHeaderErrorMessageContainer";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";

export default function BulkPackageOfferingsComponent({
  isHidden,
  componentID,
  testID,
  bulkPackageOfferings,
}) {
  if (!isHidden) {
    return (
      <LayoutStack data-testid="BulkPackageOfferingsComponent" id={componentID} space="scale-G1">
        {bulkPackageOfferings.map(({ petServiceId }) => (
          <LayoutBox key={petServiceId} data-testid={testID} padding="scale-0">
            <LayoutStack>
              <BulkPackageOfferingsHeaderContainer petServiceId={petServiceId} />
              <BulkPackageOfferingsHeaderErrorMessageContainer petServiceId={petServiceId} />
            </LayoutStack>
            <BulkPackageOfferingsContent petServiceId={petServiceId} />
          </LayoutBox>
        ))}
      </LayoutStack>
    );
  }

  return null;
}
