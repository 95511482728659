import React, { Component } from "react";
import { get, isNil, some } from "lodash/fp";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import styled from "styled-components";
import { HealthQuestionsContainer } from "@/dux/_components/digitalServiceCard/HealthQuestionsComponent";
import SplitLineWithMessage from "../../common/SplitLineWithMessage";
import Button from "../../common/commonButton";
import { APPOINTMENT_STATUS } from "../../../core/constants";
import {
  statusChangeActions,
  HANDS_ON_PET_REMINDER_MESSAGE,
  pageNames,
  HOP_NOTE_VERBIAGE,
} from "core/constants/checkInOutConstants";
import { SalonAppointmentNotes } from "dux/notes/appointmentNotes/AppointmentNotesComponent";
import { modalTypes } from "core/constants/modalConstants";
import CheckInOutButtonModal from "../modals/checkInOutButtonModalsContainer";


const AppointmentNoteRow = styled.div`
  margin: 1em auto;
  max-width: 300px;
`;

class CheckInOutButtonComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionClicked: null,
    };
  }

  // Gets message for HOP Note
  getAppointmentNoteMessage() {
    const { petId, router, itineraryId, petServiceItemId } = this.props;
    const customerId = get(["params", "customerKey"], router);
    return (
      <>
        <AppointmentNoteRow>
          {SplitLineWithMessage({
            string: HANDS_ON_PET_REMINDER_MESSAGE,
            delimiter: "\n",
          })}
          <SalonAppointmentNotes
            isHOPNote
            petId={petId}
            customerId={customerId}
            itineraryId={itineraryId}
            petServiceItemId={petServiceItemId}
          />
        </AppointmentNoteRow>
        <HealthQuestionsContainer petId={petId} engagementId={petServiceItemId} />
        <br />
      </>
    );
  }

  // Dispatches check-in single or multi depending on the action passed to it.
  getActionForModal(actionClicked) {
    const {
      checkInMulti,
      checkInSingle,
      pageName,
      router,
      itineraryId,
      petServiceItemId,
    } = this.props;
    const customerId = get(["params", "customerKey"], router);

    switch (actionClicked) {
      case statusChangeActions.CHECK_IN_MULTI:
        return checkInMulti({
          status: APPOINTMENT_STATUS.CHECKED_IN,
          customerId,
          itineraryId,
          engagementId: petServiceItemId,
        });
      case statusChangeActions.CHECK_IN_SINGLE:
        return checkInSingle({
          reason: "",
          status: APPOINTMENT_STATUS.CHECKED_IN,
          customerId,
          itineraryId,
          engagementId: petServiceItemId,
        });
      default:
        return () => {};
    }
  }

  shouldAppointmentNoteUpdate = (appointmentNote) => {
    const { petEngagementNoteFormData } = this.props;

    const currentAppointmentNote = get("appointmentNotes", petEngagementNoteFormData);
    const addedHOPNote = appointmentNote && HOP_NOTE_VERBIAGE.trim() !== appointmentNote.trim();

    return appointmentNote !== currentAppointmentNote && !isNil(appointmentNote) && addedHOPNote;
  };

  createCartFooterAction(actionClicked) {
    const { eligibilityWarnings, showEligibilityWarningsModal } = this.props;
    this.setState({ actionClicked });

    if (eligibilityWarnings) {
      showEligibilityWarningsModal(modalTypes.ELIGIBILITY_WARNINGS);
    } else {
      this.getActionForModal(actionClicked);
    }
  }

  saveHandsOnPetReminder(confirmActionType) {
    const {
      updateAppointmentNote,
      appointmentNote,
      router,
      itineraryId,
      petServiceItemId,
    } = this.props;
    const customerId = get(["params", "customerKey"], router);

    if (this.shouldAppointmentNoteUpdate(appointmentNote)) {
      updateAppointmentNote({
        appointmentNote,
        customerId,
        itineraryId,
        petServiceItemId,
      });
    }

    this.createCartFooterAction(confirmActionType);
  }

  // Gets the action type (multi/single) and creates a confirmation modal accordingly.
  getCheckInAction = (confirmActionType) => {
    const { showConfirmationModal, getCheckInScreenEligibility, isFromStandaloneFlow } = this.props;

    showConfirmationModal({
      header: "Check-In Reminder",
      content: this.getAppointmentNoteMessage(),
      confirmText: "Continue",
      confirm: () => {
        if (isFromStandaloneFlow) {
          getCheckInScreenEligibility();
        }
        this.saveHandsOnPetReminder(confirmActionType);
      },
      cancelText: "Add services",
    });
  };

  // Checks if check-in button should be disabled
  getCheckInDisabled() {
    const { petServiceItem, isFromAnotherStore, isSRCAgent, isEligibilityLoading } = this.props;
    return (
      [APPOINTMENT_STATUS.CHECKED_IN, APPOINTMENT_STATUS.CANCELED].includes(
        get("status", petServiceItem),
      ) ||
      isFromAnotherStore ||
      isSRCAgent ||
      isEligibilityLoading
    );
  }

  // Checks if check-in multi button should be disabled
  getCheckInForAllDisabled() {
    const {
      petServiceItems,
      isFromAnotherStore,
      isOnePetServiceItemCancelled,
      doServiceItemsMatch,
      isSRCAgent,
      isEligibilityLoading,
    } = this.props;
    return (
      some({ status: APPOINTMENT_STATUS.CHECKED_IN }, petServiceItems) ||
      isFromAnotherStore ||
      isOnePetServiceItemCancelled ||
      !doServiceItemsMatch ||
      isEligibilityLoading ||
      isSRCAgent
    );
  }

  render() {
    const {
      router,
      petId,
      itineraryId,
      itinerary,
      ModalType,
      label,
      disabled,
    } = this.props;
    const customerKey = get(["params", "customerKey"], router);
    const pets = get(["pets"], itinerary) || petId;
    const checkInDisabled = disabled || this.getCheckInDisabled();

    return (
      <>
        {label === pageNames.CHECK_IN && pets && pets.length > 1 && (
          <Button
            onClick={() => this.getCheckInAction(statusChangeActions.CHECK_IN_MULTI)}
            label={`${label} for all`}
            disabled={this.getCheckInForAllDisabled()}
            width="174px"
            height="49px"
            inverted
          />
        )}
        <Button
          onClick={() => this.getCheckInAction(statusChangeActions.CHECK_IN_SINGLE)}
          label={label}
          disabled={checkInDisabled}
          width="174px"
          height="49px"
        />
        {ModalType ? (
          <CheckInOutButtonModal
            itineraryId={itineraryId}
            petId={petId}
            customerKey={customerKey}
            onProceed={() => {
              this.getActionForModal(this.state.actionClicked);
            }}
            modalType={ModalType}
            selectedPets={
              this.state.actionClicked === statusChangeActions.CHECK_IN_SINGLE ? petId : pets
            }
          />
        ) : null}
      </>
    );
  }
}

export default withRouteProps(CheckInOutButtonComponent);
