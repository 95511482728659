import { connect } from "react-redux";
import { isFTCOHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import { history } from "@/dux/utils/browser/browserHistory";
import { hideCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { clearCart } from "@/core/actionCreators/ui/web/cartActionCreators";
import CommonButton from "@/web/common/commonButton";
import { ELIGIBLE_PROMOTIONS_FTCO_ACCEPT_BUTTON_LABEL } from "dux/eligiblePromotions/eligiblePromotionConstants";

const mapStateToProps = state => {
  return {
    componentId: "ftco-accept__commonButton",
    isHidden: isFTCOHidden(state),
    label: ELIGIBLE_PROMOTIONS_FTCO_ACCEPT_BUTTON_LABEL,
    customerKey: getCurrentCustomerKey(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onAcceptHandler: customerKey => {
    history.push(`/standalone/${customerKey}/select-service`);
    dispatch(hideCheckInOutModal());
    dispatch(clearCart());
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  onClick: () => propsFromDispatch.onAcceptHandler(propsFromState.customerKey),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
