import moment from "moment";

/**
 * Responsible for taking a Date and determining if that date is within the
 * current ISO Week  e.g. is the date between Monday - Sunday of this week.
 *
 * @export checkIfSelectedDateIsInCurrentWeek
 * @param {moment} selectedDate
 * @returns {boolean}
 */
export default function isWithinCurrentWeek(selectedDate) {
  const currentDate = moment();

  const startOfIsoWeek = moment(currentDate).startOf("isoWeek");
  const endOfIsoWeek = moment(currentDate).endOf("isoWeek");
  const isSameWeek = moment(selectedDate).isBetween(startOfIsoWeek, endOfIsoWeek);

  return isSameWeek;
}
