import React from "react";
import styled from "styled-components";
import { font } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import fadeIn from "./styles/Animation/fade/fadeIn";

/* * ----------------------------------------------------------------------- * *\
  styled Components
\* * ----------------------------------------------------------------------- * */
const StyledSectionTitle = styled.div`
  font: ${font.secondaryFamily};
  font-weight: 600;
  font-size: ${props => fontSizes[props.fontSize]};
  margin-bottom: ${props => (props.titleMarginBottom ? props.titleMarginBottom : "1.25rem")};
  animation: ${fadeIn()} 1s ease;
`;

/* * ----------------------------------------------------------------------- * *\
  JSX View Component
\* * ----------------------------------------------------------------------- * */
const SectionTitle = ({ title, fontSize = "regular", className, titleMarginBottom }) => (
  <StyledSectionTitle
    titleMarginBottom={titleMarginBottom}
    fontSize={fontSize}
    className={className}
  >
    {title}
  </StyledSectionTitle>
);

export default SectionTitle;
