import { bookingAPI } from "@/core/services/systemServicesBooking/_axiosConfigSystemServicesBooking";

/**
 * For Hotel Room Availability API
 * - Get Room Availability for pet -
 * @returns {*} AxiosPromise
 */

export function getHotelRoomAvailability({
  customerKey,
  petId,
  roomTypeBucketId,
  storeNumber,
  startDateTime,
  endDateTime,
}) {
  return bookingAPI.get(
    `/hotel/v1/customers/${customerKey}/pets/${petId}/stores/${storeNumber}/roomAvailability`,
    { params: { roomTypeBucketId, startDateTime, endDateTime } },
  );
}
