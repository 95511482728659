import uniformTimeFormat from "core/utils/dateUtils/uniformTimeFormat";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import moment from "moment";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { connect } from "react-redux";
import DaySelection from "web/weekNavigationBar/DaySelection";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { loadHotelDailyDashboard } from "dux/dashboard/hotel/hotelDashboardActions";

const mapStateToProps = (state, ownProps) => {
  const uniformDates = uniformTimeFormat([ownProps.day, getSelectedDate(state)], "YYYY-MM-DD");
  return {
    componentId: "hotelDaySelection",
    isSelected: moment(uniformDates.date_0).isSame(uniformDates.date_1),
    formatedDate: moment(ownProps.day).format("ddd DD"),
    isUpperCase: true,
    storeId: getStoreNumber(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchClickHandler: ({ selectedDate, storeId }) => {
    dispatch(setSelectDate(selectedDate));
    dispatch(loadHotelDailyDashboard({ storeId, selectedDate }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  clickHandler: () => {
    const momentDate = moment(propsFromState?.formattedDate);
    propsFromDispatch.dispatchClickHandler({
      selectedDate: ownProps.day,
      storeId: propsFromState.storeId,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DaySelection);
