import { Layout } from "@prism/psm-ui-components";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getIsPetCardShowing } from "dux/petCartCard/selectors/petCartCardSelectors";
import { medicationTypes } from "web/newMedication/newMedicationConstants";
import { CheckInOutPetMedList } from "@/web/medicationList/MedList";
import MedicationSectionContainer from "web/newMedication/MedicationSectionContainer";
import { CheckInOutNewMed } from "dux/_components/checkInOutMedications/CheckInOutMedsForm";
import { selectMedicationsList } from "@/dux/medications/medicationsSelectors";
import { getMedicationsList } from "@/dux/medications/medicationActions";
import isEmpty from "lodash/isEmpty";

export const CheckInOutMedComponent = props => {
  const { isHidden, petId, locationType, componentId, loadMedicationsList } = props;

  const [invalidMedForms, setMedFormInvalid] = useState([]);

  useEffect(() => {
    if (typeof loadMedicationsList === "function") loadMedicationsList();
  }, []);

  if (!isHidden) {
    return (
      <Layout.Stack id={componentId} space="stack-space-7">
        <MedicationSectionContainer petId={petId} locationType={locationType}>
          <CheckInOutNewMed
            setMedFormInvalid={setMedFormInvalid}
            invalidMedForms={invalidMedForms}
          />
          <CheckInOutPetMedList
            petId={petId}
            locationType={locationType}
            setMedFormInvalid={setMedFormInvalid}
            invalidMedForms={invalidMedForms}
          />
        </MedicationSectionContainer>
      </Layout.Stack>
    );
  }
  return null;
};

export const CheckInOutMeds = connect(
  (state, { petId }) => {
    const systemFlow = getSystemBookingFlow(state);
    const medicationList = selectMedicationsList(state);
    return {
      componentId: `checkInOut_meds`,
      petId,
      isHidden: systemFlow !== systemName.HOTEL || !getIsPetCardShowing(state, { petId }),
      medicationList,
      hasPadding: "1rem 1rem 0 1rem",
      locationType: medicationTypes.CART,
    };
  },
  dispatch => ({
    fetchMedicationsList: () => dispatch(getMedicationsList()),
  }),
  (stateProps, dispatchProps) => {
    const { componentId, petId, isHidden, medicationList, hasPadding, locationType } = stateProps;

    const { clearMeds, fetchMedicationsList } = dispatchProps;

    return {
      componentId,
      petId,
      isHidden,
      hasPadding,
      locationType,
      clearMeds,
      loadMedicationsList: () => {
        if (isHidden) return false;
        if (isEmpty(medicationList)) fetchMedicationsList();
      },
    };
  },
)(CheckInOutMedComponent);
