export const modalTypes = {
  PARTIAL_DAY_RELOCATION: "PARTIAL_DAY_RELOCATION",
  UNSAVE_CHANGES_MODAL_DATE_CHANGE: "UNSAVE_CHANGES_MODAL_DATE_CHANGE",
  UNSAVE_CHANGES_MODAL_ASSOCIATE_CHANGE: "UNSAVE_CHANGES_MODAL_ASSOCIATE_CHANGE",
  UNSAVE_CHANGES_MODAL_GO_TO_TEMPLATE: "UNSAVE_CHANGES_MODAL_GO_TO_TEMPLATE",
  UNSAVE_CHANGES_MODAL_GO_TO_TEMPLATE_WEEK: "UNSAVE_CHANGES_MODAL_GO_TO_TEMPLATE_WEEK",
  UNSAVE_CHANGES_MODAL_URL_CHANGE: "UNSAVE_CHANGES_MODAL_URL_CHANGE",
  TEMPLATE_DATE_MUST_BE_CHANGED: "TEMPLATE_DATE_MUST_BE_CHANGED",
  NOTIFICATION: "NOTIFICATION",
  RELOCATION_ON_SPLIT_SHIFT_NOTIFICATION: "RELOCATION_ON_SPLIT_SHIFT_NOTIFICATION",
};

export const dayTypes = [
  { value: "Work", label: "Workday" },
  { value: "Relocation", label: "Relocation" },
  { value: "Day Off", label: "Day Off" }
];

export const timeRangeErrors = {
  OUTSIDE_STORE_HOURS: "Selected work hours are outside of store operating hours",
  START_EARLIER_THAN_END: "Start time must be earlier than end time"
};

export const weekIds = {
  week2: "week2"
};

export const businessHours = [
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12PM" },
  { value: 13, label: "1" },
  { value: 14, label: "2" },
  { value: 15, label: "3" },
  { value: 16, label: "4" },
  { value: 17, label: "5" },
  { value: 18, label: "6" },
  { value: 19, label: "7" },
  { value: 20, label: "8" },
  { value: 21, label: "9" }
];

export const scheduleConflictMessages = {
  DEFAULT_SCHEDULE_CONFLICT_MESSAGE:
    "The associate you are attempting to make changes to has appointments scheduled that conflict with this change. Appointments must be adjusted before any changes can be saved",
  OVERRIDE_SCHEDULE_CONFLICT_MESSAGE:
    "Are you sure you want to override the schedule conflicts? Any appointments not modified prior to schedule changes will not be changed automatically."
};
