import React from "react";
import styled from "styled-components";

const StaticInputContainer = styled.div`
  &:focus {
    outline: none;
  }
  margin-bottom: 5px;
`;

const StaticInput = ({ children, value, cx, ...props }) => {
  return <StaticInputContainer {...props}>{value}</StaticInputContainer>;
};

export default StaticInput;
