import {
  SET_SERVICES_CART,
  POST_CART_FAILURE,
  PUT_CART_FAILURE,
  GET_CART_BY_ID_FAILURE,
  GET_CART_BY_ITINERARY_ID_FAILURE,
  CLEAR_SERVICES_CART,
} from "./servicesCartActions";

const servicesCartReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SERVICES_CART:
      return {
        ...state,
        ...action?.cart,
      };
    case POST_CART_FAILURE:
    case PUT_CART_FAILURE:
    case GET_CART_BY_ID_FAILURE:
    case GET_CART_BY_ITINERARY_ID_FAILURE:
      return {
        ...state,
        error: { ...action?.error },
      };
    case CLEAR_SERVICES_CART:
      return {};
    default:
      return state;
  }
};

export default servicesCartReducer;
