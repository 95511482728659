import { APPOINTMENT_STATUS } from "../../constants";

/** @module isAppointmentBookedOrConfirmed */

/**
 * Returns a bool t/f is an appointment is booked or confirmed
 *
 * @export getMomentByDateAndTime
 * @param {string} - appointment status
 * @returns {boolean} - is the appointment booked or confirmed
 */
export default function isAppointmentBookedOrConfirmed(status) {
  return status === APPOINTMENT_STATUS.BOOKED || status === APPOINTMENT_STATUS.CONFIRMED;
}
