/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const medicationSaveToProfileActionTypes = {
  SET_MEDICATION_SAVE_TO_PROFILE: "SET_MEDICATION_SAVE_TO_PROFILE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setMedicationSaveToProfile = ({ saveToProfile, medicationId, petId }) => ({
  type: medicationSaveToProfileActionTypes.SET_MEDICATION_SAVE_TO_PROFILE,
  saveToProfile,
  medicationId,
  petId,
});
