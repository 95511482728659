import * as React from 'react';
import { psx } from '../../utils/clsx';
import {
  isFormElement,
  getDeltaValueFromFormElement,
  checkFormElementValidity,
  getLastDeltaFromFormElement,
} from './utils';
import { InputHandler, SubmitHandler, ElementDeltaMap, LastDelta } from './types';
import { FormControlledContext } from './form-context';

export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  /**
   * A callback helper for form validation; "false" will consider the form invalid.
   * To lookup values in form, use the "elements" property on the form object.
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/elements
   */
  onCheckValidity?: (form: HTMLFormElement) => boolean;

  /**
   * Setting this value to "true" will use browser validation
   */
  useHTMLValidation?: boolean;

  /**
   * A callback that also provides the deltas from the current form
   * delta names are determined by the "name" attribute defined on each FormField.
   */
  onInput?: InputHandler;

  /**
   * A callback that also provides the deltas from the current form
   * delta names are determined by the "name" attribute defined on each FormField.
   */
  onSubmit?: SubmitHandler;

  /**
   * When isControlled is true, when the underlying for structure changes,
   * it will force fields to unmount/mount if the value changed.
   *
   * When "false", form fields do not change if the form structure changes.
   */
  isControlled?: boolean;
}

export interface FormState {
  deltas: ElementDeltaMap;
}

export class Form extends React.Component<FormProps, FormState> {
  static Field: any;

  constructor(props: FormProps) {
    super(props);
    this.state = {
      deltas: {},
    };
  }

  handleCheckValidity = (form: HTMLFormElement) => {
    if (this.props.onCheckValidity) {
      return this.props.onCheckValidity(form);
    }
    // use default form validity check
    return form.checkValidity();
  };

  handleSubmit: SubmitHandler = (e) => {
    e.preventDefault();
    const isValid = this.handleCheckValidity(e.currentTarget);

    if (!isValid) {
      return;
    }

    if (this.props.onSubmit) {
      return this.props.onSubmit(e, this.state.deltas);
    }
  };

  handleInput: React.FormEventHandler<HTMLFormElement> = (e) => {
    // determine deltas
    const { nativeEvent, target } = e;
    const isValid = checkFormElementValidity(target);

    if (!isValid) {
      return;
    }

    // if custom event was used, lookup what the value is based on metadata
    if (nativeEvent instanceof CustomEvent) {
      const { inputName, inputValue } = nativeEvent.detail;
      const element = e.currentTarget.elements.namedItem(inputName);
      if (!element || !isFormElement(element)) {
        return;
      }
      const newDeltas = {
        ...this.state.deltas,
        [inputName]: inputValue,
      };
      const lastDelta: LastDelta = { key: inputName, value: inputValue };
      if (this.props.onInput && lastDelta) {
        this.props.onInput(e, lastDelta, newDeltas);
      }
      return this.setState({ deltas: newDeltas });
    }

    const targetDeltaValue = getDeltaValueFromFormElement(target);
    if (targetDeltaValue) {
      const newDeltas = {
        ...this.state.deltas,
        ...targetDeltaValue,
      };
      const lastDelta = getLastDeltaFromFormElement(target);
      if (this.props.onInput && lastDelta) {
        this.props.onInput(e, lastDelta, newDeltas);
      }
      return this.setState({ deltas: newDeltas });
    }
  };

  render() {
    const {
      children,
      className = '',
      onCheckValidity,
      isControlled = false,
      useHTMLValidation = false,
      onInput,
      onSubmit,
      ...rest
    } = this.props;

    const classNames = psx('psm', 'psm-form', className);

    return (
      <FormControlledContext.Provider value={isControlled}>
        <form
          className={classNames}
          onInput={this.handleInput}
          onSubmit={this.handleSubmit}
          noValidate={!useHTMLValidation}
          {...rest}
        >
          {children}
        </form>
      </FormControlledContext.Provider>
    );
  }
}
