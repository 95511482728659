import { get, isEmpty } from "lodash/fp";
import { createSelector } from "reselect";
import { getMedicationsByPet, getMedicationById } from "../medication/medicationsSelector";

export const getMedicationTimeOfDayById = createSelector(
  [getMedicationsByPet, (state, props) => props],
  (medications, { medicationId }) => get([medicationId, "timeOfDay"], medications) || [],
);

export const getMedicationTimeOfDayTouched = createSelector([getMedicationById], medication => {
  return get("timeOfDayTouched", medication) || false;
});

export const getIsMedicationTimeOfDayEmpty = createSelector([getMedicationById], medication => {
  const timeOfDay = get("timeOfDay", medication);
  return isEmpty(timeOfDay);
});
