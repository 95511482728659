import { getState } from "@/core/selectors/commonSelector";
import { createSelector } from "reselect";

export const selectMedicationOptions = createSelector(
  [getState],
  state => state?.medicationList ?? {},
);

/**
 *  Selector to get medications list
 *
 *  @memberOf Selectors.Enums
 *  @function
 *  @param {Object} - state
 *  @name selectMedicationsList
 *  @returns {Array} - medication list
 *  @example
 *
 *  selectMedicationsList(state)
 */
export const selectMedicationsList = createSelector([selectMedicationOptions], medOptions =>
  Object.values(medOptions),
);
