import moment from "moment";

/**
 * Util to sort hotel engagements ascending by start date
 * @memberOf Utils.Engagement
 * @function
 * @name sortHotelEngagements
 * @param {Object[]} petEngagements - all engagements for a pet from a hotel itinerary
 * @returns {Object[]} - sorted engagements
 * @example sortHotelEngagements(petEngagements)
 */
export const sortHotelEngagements = (petEngagements = []) =>
  petEngagements?.sort((a, b) => {
    if (a.startDatetime && b.startDatetime)
      return moment(a.startDatetime).diff(moment(b.startDatetime));

    // If for some reason the engagements don't have startDatetimes then leave the sorting as is
    return 0;
  });
