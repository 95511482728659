import React, { Component } from "react";
import styled from "styled-components";
import Img from "../common/Img";

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const ButtonText = styled.div`
  color: #000;
  font-family: Montserrat;
  font-size: 18;
  font-weight: 600;
`;

const ButtonTextPadding = styled.div`
  color: #000;
  font-family: Montserrat;
  font-size: 18;
  font-weight: 600;
  margin-bottom: 10px;
`;

const ButtonIcon = styled(Img)`
    padding: 5px;
    padding-right: 5px;
    width: 30px
    height: 30px;
`;

const MenuButton = ({ icon, title, onClick, componentID = "menuButton", isHidden = false }) => {
  if (!isHidden) {
    return (
      <ButtonWrapper id={componentID} onClick={onClick}>
        {icon && <ButtonIcon src={icon} />}
        {icon ? <ButtonText>{title}</ButtonText> : <ButtonTextPadding>{title}</ButtonTextPadding>}
      </ButtonWrapper>
    );
  }
  return null;
};

export default MenuButton;
