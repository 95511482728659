import { CLEAR_OVERBOOKING_EXCEPTION_FORM } from "../overBookingNewExceptionDate/overBookingNewExceptionDateActions";
import {
  SET_OVERBOOKING_EXCEPTION,
  SET_OVERBOOKING_EXCEPTION_END,
  SET_OVERBOOKING_EXCEPTION_ERROR,
  SET_OVERBOOKING_EXCEPTION_PERCENT,
  SET_OVERBOOKING_EXCEPTION_ROOM_TYPE,
  SET_OVERBOOKING_EXCEPTION_START,
} from "./overBookingExceptionActions";

const initialState = {};

export const overBookingExceptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OVERBOOKING_EXCEPTION:
      return action?.exception;
    case SET_OVERBOOKING_EXCEPTION_PERCENT:
      return { ...state, overBookingPercentage: action?.percent };
    case SET_OVERBOOKING_EXCEPTION_START:
      return { ...state, startDate: action?.date };
    case SET_OVERBOOKING_EXCEPTION_END:
      return { ...state, endDate: action?.date };
    case SET_OVERBOOKING_EXCEPTION_ROOM_TYPE:
      return { ...state, roomTypeBucketNumber: action?.roomTypeBucketNumber };
    case SET_OVERBOOKING_EXCEPTION_ERROR:
      return { ...state, error: action?.error };
    case CLEAR_OVERBOOKING_EXCEPTION_FORM:
      return initialState;
    default:
      return state;
  }
};
