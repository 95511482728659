import { put, takeLatest, call, all, select, fork } from "redux-saga/effects";
import moment from "moment";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { formatMoment } from "core/utils/dateUtils/formatDateTime";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import {
  selectHotelBookingEndDate,
  selectHotelBookingStartDate,
  selectHotelBookingRooms,
  selectHotelBookingPetList,
} from "web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { fetchPetServicesAtHotel } from "../services/petServicesHotelEndPoints";
import * as petServicesAtHotelActions from "../actions/petServicesAtHotel";
import {
  getHotelEngagementsPets,
  selectHotelItineraryRoomConfig,
} from "@/dux/hotelEngagements/hotelEngagementSelectors";
import { getHotelItinerary } from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { setHotelBookingHostPets } from "web/features/hotelBookingFlow/hotelBookingFlowActions";
import isEqual from "lodash/isEqual";
import unionWith from "lodash/unionWith";

function* onLoadPetServicesAtHotelBooking({ petId }) {
  try {
    yield put(petServicesAtHotelActions.loadPetServicesAtHotelBookingRequest());
    const customerKey = yield select(getCurrentCustomerKey);
    const storeNumber = yield select(getStoreNumber);

    const rooms = yield select(selectHotelBookingRooms);

    const roomConfigs = {
      roomPetConfigurations: Object.values(rooms).map(room => {
        return { petIdsOfPetsInRoom: room };
      }),
    };

    const bookingPets = yield select(selectHotelBookingPetList);
    const petList = petId || bookingPets[0];

    const appType = yield select(getSystemBookingFlow);
    const checkinDate = yield select(selectHotelBookingStartDate);
    const checkOutDate = yield select(selectHotelBookingEndDate);
    const momentCheckInDate = moment(checkinDate);
    const momentCheckOutDate = moment(checkOutDate);
    const response = yield call(fetchPetServicesAtHotel, {
      customerKey,
      petList,
      storeNumber,
      appType,
      isMultiDay: !momentCheckInDate.isSame(moment(checkOutDate), "day"),
      checkinDate: formatMoment(momentCheckInDate),
      checkOutDate: formatMoment(momentCheckOutDate),
      roomConfig: bookingPets.length > 1 && btoa(JSON.stringify(roomConfigs)),
    });
    const petAvailableServicesData = response?.data?.result || [];
    const hostPetIdSet = new Set(petAvailableServicesData.map(({ hostPetId }) => hostPetId)); // dedupe host ids
    const hostPets = [...hostPetIdSet];

    yield put(setHotelBookingHostPets({ hostPets }));
    yield put(petServicesAtHotelActions.saveHotelServices({ petAvailableServicesData }));
    yield put(petServicesAtHotelActions.loadPetServicesAtHotelBookingSuccess());
  } catch (error) {
    yield put(petServicesAtHotelActions.loadPetServicesAtHotelBookingFailure({ error }));
  }
}

function* onLoadPetServicesAtHotelPostBooking({ petId }) {
  try {
    yield put(petServicesAtHotelActions.loadPetServicesAtHotelPostBookingRequest());
    const customerKey = yield select(getCurrentCustomerKey);
    const appType = yield select(getSystemBookingFlow);

    const roomConfig = yield select(selectHotelItineraryRoomConfig);
    const pets = yield select(getHotelEngagementsPets);
    const petList = petId || pets[0];

    const itinerary = yield select(getHotelItinerary);
    const momentCheckInDate = moment(itinerary?.startDateTime);
    const momentCheckOutDate = moment(itinerary?.endDateTime);

    const endpointArgs = {
      customerKey,
      petList,
      storeNumber: itinerary?.storeNumber,
      appType,
      isMultiDay: !momentCheckInDate.isSame(momentCheckOutDate, "day"),
      checkinDate: formatMoment(momentCheckInDate),
      checkOutDate: formatMoment(momentCheckOutDate),
    };

    const response = yield call(fetchPetServicesAtHotel, {
      ...endpointArgs,
      roomConfig: pets.length > 1 && btoa(JSON.stringify(roomConfig.fromItinerary)),
    });

    let petAvailableServicesData = response?.data?.result || [];

    // The get pet services call will never return host pet services for a guest pet, even if
    // we send a pet as both a guest and a host it will only send guest services. So if there
    // are guest pets, do a second API call as if they are hosts so that users can make them
    // hosts if needed
    if (roomConfig?.guestsAsHosts) {
      const guestsAsHostsResponse = yield call(fetchPetServicesAtHotel, {
        ...endpointArgs,
        petList: roomConfig.guestPetList,
        roomConfig: pets.length > 1 && btoa(JSON.stringify(roomConfig.guestsAsHosts)),
      });

      petAvailableServicesData = unionWith(
        petAvailableServicesData,
        guestsAsHostsResponse?.data?.result,
        isEqual,
      );
    }

    yield put(petServicesAtHotelActions.saveHotelServices({ petAvailableServicesData }));
    yield put(petServicesAtHotelActions.loadPetServicesAtHotelPostBookingSuccess());
  } catch (error) {
    yield put(petServicesAtHotelActions.loadPetServicesAtHotelPostBookingFailure({ error }));
  }
}

function* watchOnLoadPetServicesAtHotelBooking() {
  yield takeLatest(
    [petServicesAtHotelActions.LOAD_PET_SERVICES_AT_HOTEL_BOOKING],
    onLoadPetServicesAtHotelBooking,
  );
}

function* watchOnLoadPetServicesAtHotelPostBooking() {
  yield takeLatest(
    [petServicesAtHotelActions.LOAD_PET_SERVICES_AT_HOTEL_POSTBOOKING],
    onLoadPetServicesAtHotelPostBooking,
  );
}

export default function* hotelServicesSaga() {
  yield all([
    fork(watchOnLoadPetServicesAtHotelBooking),
    fork(watchOnLoadPetServicesAtHotelPostBooking),
  ]);
}
