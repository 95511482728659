import { connect } from "react-redux";
import { getSelectedPet } from "../../../core/selectors/bookingUISelectors";
import { getAppointmentByPet } from "../../../core/selectors/cartSelectors";
import { getStandaloneServiceByPet } from "../../../core/selectors/entitiesSelector";
import { getShouldShowAddons } from "../../../core/selectors/ui/addons/addonSelector";
import { closeAddOnsFlyout } from "../../../core/actionCreators/addOnsActionCreator";
import StandaloneServiceComponent from "./StandaloneServiceComponent";

const mapStateToProps = (state, ownProps) => {
  const appointment = getAppointmentByPet(state, ownProps);
  return {
    isHidden: true,
    componentID: "standaloneService-StandaloneServiceComponent",
    selectedPetService: appointment && appointment.petService,
    standaloneService: getStandaloneServiceByPet(state, ownProps),
    showAddons: getShouldShowAddons(state),
    selectedPet: getSelectedPet(state),
    appointment,
  };
};

const mapDispatchToProps = dispatch => ({
  closeAddonsFlyout: () => dispatch(closeAddOnsFlyout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneServiceComponent);
