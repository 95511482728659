import { put, takeEvery, call, all } from "redux-saga/effects";
import { fetchServiceProductFamilies } from "@/core/services/associateWeb/serviceProductFamiliesEndpoint";
import {
  GET_SERVICE_PRODUCT_FAMILIES,
  getServiceProductFamiliesRequest,
  getServiceProductFamiliesSuccess,
  getServiceProductFamiliesFailure,
} from "./serviceProductFamiliesActions";

function* onGetServiceProductFamilies() {
  try {
    yield put(getServiceProductFamiliesRequest());
    const response = yield call(fetchServiceProductFamilies);
    const payload = response?.data?.result;

    yield put(getServiceProductFamiliesSuccess({ payload }));
  } catch (error) {
    yield put(getServiceProductFamiliesFailure({ error }));
  }
}

function* watchGetServiceProductFamilies() {
  yield takeEvery(GET_SERVICE_PRODUCT_FAMILIES, onGetServiceProductFamilies);
}

export default function* serviceProductFamiliesSaga() {
  yield all([watchGetServiceProductFamilies()]);
}
