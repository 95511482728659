import { connect } from "react-redux";
import { getIsPreCheckedInByEngagementId } from "dux/digitalServiceCard/digitalServiceCardSelectors";
import { getPetServiceItem } from "core/selectors/entitiesSelector";
import IconWithLabel from "dux/_components/icon/IconWithLabel";
import PreCheckedInIcon from "../../assets/icons/pre-checked-in-appointment.svg";

const mapStateToProps = (state, ownProps) => {
  const appointment = getPetServiceItem(state, { petServiceItemId: ownProps.petServiceItemId });
  const isPreCheckedIn = getIsPreCheckedInByEngagementId(state, {
    engagementId: appointment.engagement,
  });

  return {
    componentId: "pre-checked-in-icon",
    src: PreCheckedInIcon,
    label: "Pre checked-in",
    isHidden: !isPreCheckedIn,
  };
};

export default connect(mapStateToProps)(IconWithLabel);
