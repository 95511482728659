import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { SET_DO_NOT_BOOK_HOTEL, setDoNotBookError } from "dux/doNotBook/doNotBookActions";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { updateCustomerProfilePartial } from "core/services/systemServicesBooking/profile/customerProfile";
import { updateCustomerSuccess } from "core/actionCreators/customersActionCreators";

function* onUpdateDoNoBookHotel({ doNotBook, doNotBookReason }) {
  try {
    const customerKey = yield select(getCurrentCustomerKey);

    const response = yield call(updateCustomerProfilePartial, {
      customerKey,
      data: {
        profiles: [
          {
            profileType: "DDC/Hotel",
            doNotBook,
            doNotBookReason,
          },
        ],
      },
    });

    yield put(
      updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          prismCustomer: {
            profiles: response.data?.result?.profiles,
            psaOnFile: response.data?.result?.psaOnFile,
            isVIP: response.data?.result?.isVIP,
          },
        },
      }),
    );
  } catch (error) {
    yield put(setDoNotBookError({ error }));
  }
}

function* watchUpdateDoNotBook() {
  yield takeEvery(SET_DO_NOT_BOOK_HOTEL, onUpdateDoNoBookHotel);
}

export default function* updateDoNotBookHotelSaga() {
  yield all([fork(watchUpdateDoNotBook)]);
}
