/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const TOGGLE_CUSTOMER_PROFILE_INCOMPLETE_MODAL = "TOGGLE_CUSTOMER_PROFILE_INCOMPLETE_MODAL ";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const toggleCustomerProfileIncompleteModal = () => ({
  type: TOGGLE_CUSTOMER_PROFILE_INCOMPLETE_MODAL,
});
