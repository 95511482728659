import React from "react";

class Img extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      hasError: false
    };

    this.onLoadImage = this.onLoadImage.bind(this);
    this.onErrorImage = this.onErrorImage.bind(this);
  }

  onLoadImage() {
    this.setState({ isLoaded: true });
  }

  onErrorImage() {
    this.setState({ hasError: true });
  }

  componentDidMount() {
    this.img = new Image();

    this.img.addEventListener("load", this.onLoadImage, false);
    this.img.addEventListener("error", this.onErrorImage, false);

    this.img.src = this.props.src;
  }

  componentWillUnmount() {
    this.img.removeEventListener("load", this.onLoadImage, false);
    this.img.removeEventListener("error", this.onErrorImage, false);
  }

  render() {
    const { src, ...props } = this.props;
    return this.state.isLoaded || !this.state.hasError ? (
      <img alt={src} src={src} {...props} />
    ) : (
      <img alt="" src="" {...props} />
    );
  }
}

export default Img;
