import { connect } from "react-redux";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import {
  getFilteredByBreakData,
  selectCatGroomingStoreCapability,
} from "core/selectors/schedulesSelectors";
import GanttZoomContent from "./ganttZoomContent";
import {
  getAppointmentsByAssociateAndDate,
  getAssociate,
  getGroomingSkills,
} from "../../../../../core/selectors/entitiesSelector";
import { getAppointmentByPet } from "../../../../../core/selectors/cartSelectors";
import {
  getAssociateId,
  getSelectedAssociateWeekly,
  getWeeklyGanttDate,
} from "../../../../../core/selectors/commonSelector";
import {
  getHourOptionsByBreak,
  getNewAppointmentTimeSlot,
  getSelectedPet,
  getShowAdditionalAppointmentBeforeBreak,
  getShowAdditionalAppointmentAfterBreak,
} from "../../../../../core/selectors/bookingUISelectors";
import {
  clearNewAppointmentTimeSlot,
  hideBookingModal,
  showBookingModal,
  bookAppointment,
} from "../../../../../core/actionCreators/bookingActionCreators";
import { getDashboardHourOptionsByBreak } from "../../../../../core/selectors/dashboardUiSelectors";
import {
  setNewAppointmentData,
  hideDashboardModal,
  showDashboardModal,
  clearNewAppointmentData,
} from "../../../../../core/actionCreators/ui/web/dashboardActionCreators";

import { clearWeeklyGanttDate } from "../../../../../core/actionCreators/ui/web/commonActionCreators";
import { showConfirmationModal } from "../../../../../core/actionCreators/confirmationModalActionCreators";
import { isFromDashboard } from "../../../../../core/utils/validationUtils/isFromValidation";

const mapStateToProps = (state, ownProps) => {
  const {
    router: { location },
  } = ownProps;
  const petId = getSelectedPet(state);
  const isWeeklyView = !!getSelectedAssociateWeekly(state);
  const cartAppointment = getAppointmentByPet(state, { petId });
  const associateId = getAssociateId(state);
  const selectedDate = isWeeklyView ? getWeeklyGanttDate(state) : getSelectedDate(state);
  const appointments = getAppointmentsByAssociateAndDate(state, { associateId, selectedDate })[
    associateId
  ];

  return {
    appointments,
    associate: getAssociate(state, { associateId }),
    hasStoreGroomingCapability: selectCatGroomingStoreCapability(state),
    hasGroomingSkills: getGroomingSkills(state, { associateId, petId }),
    selectedDate,
    petId,
    selectedPetService: cartAppointment && cartAppointment.petService,
    filteredByBreakData: getFilteredByBreakData(state, {
      appointments,
      selectedDate,
      associateId,
    }),
    hourOptionsByBreak: isFromDashboard(location.pathname)
      ? getDashboardHourOptionsByBreak(state, { selectedDate, associateId })
      : getHourOptionsByBreak(state, {
          selectedDate,
          associateId,
          appointments,
          petId,
        }),
    newAppointmentTimeSlot: getNewAppointmentTimeSlot(state),
    isFromDashboard: isFromDashboard(location.pathname),
    additionalAppointmentBeforeBreak: getShowAdditionalAppointmentBeforeBreak(state),
    additionalAppointmentAfterBreak: getShowAdditionalAppointmentAfterBreak(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    router: {
      location,
      params: { customerKey },
    },
  } = ownProps;
  return {
    clearNewAppointmentTimeSlot: () => dispatch(clearNewAppointmentTimeSlot()),
    setNewAppointmentData: ({ startDateTime, associateId }) =>
      dispatch(setNewAppointmentData({ startDateTime, associateId })),
    bookAppointment: ({ selectedPetService, timeSlot, isManualAppointment }) => {
      dispatch(
        bookAppointment({
          timeSlot,
          customerKey,
          selectedPetService,
          isManualAppointment,
        }),
      );
    },
    hideModal: () =>
      isFromDashboard(location.pathname)
        ? dispatch(hideDashboardModal())
        : dispatch(hideBookingModal()),
    showDashboardModal: modalType => dispatch(showDashboardModal(modalType)),
    showBookingModal: modalType => dispatch(showBookingModal(modalType)),
    clearWeeklyGanttDate: () => dispatch(clearWeeklyGanttDate()),
    showConfirmationModal: args => dispatch(showConfirmationModal(args)),
    clearNewAppointmentData: () => dispatch(clearNewAppointmentData()),
  };
};

export default withRouteProps(connect(mapStateToProps, mapDispatchToProps)(GanttZoomContent));
