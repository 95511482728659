/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_TRAINING_ASSOCIATES = "GET_TRAINING_ASSOCIATES";
export const GET_TRAINING_ASSOCIATES_REQUEST = "GET_TRAINING_ASSOCIATES_REQUEST";
export const GET_TRAINING_ASSOCIATES_SUCCESS = "GET_TRAINING_ASSOCIATES_SUCCESS";
export const GET_TRAINING_ASSOCIATES_FAILURE = "GET_TRAINING_ASSOCIATES_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getTrainingAssociates = ({ storeNumber, isLimited }) => ({
  type: GET_TRAINING_ASSOCIATES,
  storeNumber,
  isLimited,
});

export const getTrainingAssociatesRequest = (payload) => ({
  type: GET_TRAINING_ASSOCIATES_REQUEST,
  payload,
});

export const getTrainingAssociatesSuccess = (payload) => ({
  type: GET_TRAINING_ASSOCIATES_SUCCESS,
  payload,
});

export const getTrainingAssociatesFailure = (payload) => ({
  type: GET_TRAINING_ASSOCIATES_FAILURE,
  payload,
});
