import moment from "moment";

export const getVaccinationExpirationDateColor = (isExpired) =>
  isExpired ? "text-color-red" : "text-color-black";

export const getFormattedSelectedDate = (selectedDate) => {
  return moment(selectedDate).isMoment
    ? selectedDate.format("YYYY-MM-DD")
    : moment(selectedDate).format("YYYY-MM-DD");
};

export const getIsOutsideRange = (day, currentDate) => day.isBefore(currentDate, "day");
