export const GET_BULK_PACKAGE_ENHANCED_SERVICES = "GET_BULK_PACKAGE_ENHANCED_SERVICES";
export const GET_BULK_PACKAGE_ENHANCED_SERVICES_REQUEST = "GET_BULK_PACKAGE_ENHANCED_SERVICES_REQUEST";
export const GET_BULK_PACKAGE_ENHANCED_SERVICES_SUCCESS = "GET_BULK_PACKAGE_ENHANCED_SERVICES_SUCCESS";
export const GET_BULK_PACKAGE_ENHANCED_SERVICES_FAILURE = "GET_BULK_PACKAGE_ENHANCED_SERVICES_FAILURE";
export const SET_BULK_ENHANCED_SERVICE_LIST = "SET_BULK_ENHANCED_SERVICE_LIST";
export const SET_SELECTED_ENHANCED_SERVICE = "SET_SELECTED_ENHANCED_SERVICE";

export const getBulkPackageEnhancedServices = (petServiceId, bundleConfigId) => ({
  type: GET_BULK_PACKAGE_ENHANCED_SERVICES,
  petServiceId,
  bundleConfigId,
});

export const getBulkPackageEnhancedServicesRequest = () => ({
  type: GET_BULK_PACKAGE_ENHANCED_SERVICES_REQUEST,
});

export const getBulkPackageEnhancedServicesSuccess = () => ({
  type: GET_BULK_PACKAGE_ENHANCED_SERVICES_SUCCESS,
});

export const getBulkPackageEnhancedServicesFailure = ({ error }) => ({
  type: GET_BULK_PACKAGE_ENHANCED_SERVICES_FAILURE,
  error,
});

export const setSelectedBulkPackageEnhancedServices = ({ petId, productBundleConfigId, enhancedServices }) => {
  return {
    type: SET_BULK_ENHANCED_SERVICE_LIST,
    petId,
    productBundleConfigId,
    enhancedServices,
  };
};

export const setSelectedEnhancedService = enhancedServiceId => ({
  type: SET_SELECTED_ENHANCED_SERVICE,
  enhancedServiceId,
});
