/**
 * @constant
 * type {String}
 */
export const SET_HOTEL_PRICING_SUMMARY = "SET_HOTEL_PRICING_SUMMARY";

/**
 * Action to set pet pricing summary in state
 * @memberOf actions.hotel.itinerary
 * @function
 * @name setHotelPricingSummary
 * @param {Object} payloadObj
 * @param {[type]} payloadObj.petId
 * @param {[type]} payloadObj.pricingSummary
 * @returns {Object}
 * @example dispatch(setHotelPricingSummary({ petId, pricingSummary }))
 */
export const setHotelPricingSummary = ({ petId, pricingSummary }) => ({
  type: SET_HOTEL_PRICING_SUMMARY,
  petId,
  pricingSummary,
});
