import React from "react";
import styled from "styled-components";
import SalonServicesGridContainer from "../services/salonServices/SalonServicesGridContainer";

const Container = styled.div`
  padding: 20px;
  width: 450px;
`;

export default function ServiceSelectionFlyout({ petId, petServiceItem, onClose }) {
  return (
    <Container>
      <SalonServicesGridContainer
        petId={petId}
        petServiceItem={petServiceItem}
        onClose={onClose}
        isFlyout
      />
    </Container>
  );
}
