import dashboardActionTypes from "../../../actionTypes/ui/web/dashboardActionTypes";

const initialState = {
  modalType: "",
  appointment: {}
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardActionTypes.SET_NEW_APPOINTMENT_DATA: {
      return {
        ...state,
        appointment: {
          ...state.appointment,
          ...action.payload
        }
      };
    }

    case dashboardActionTypes.CLEAR_NEW_APPOINTMENT_DATA: {
      return {
        ...state,
        appointment: {}
      };
    }

    default:
      return state;
  }
};

export default dashboardReducer;
