// Action Types
import {
  GET_AVAILABLE_REPORTS_SUCCESS,
  GET_AVAILABLE_REPORTS_FAILURE,
  SET_SELECTED_REPORT_TYPE,
} from "./reportSelectionActions";

/**
 * @name reportSelectionReducer
 * @memberof Reducer.Reports
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
const reportSelectionReducer = (
  state = { availableReports: [], selectedReportType: "", error: null },
  action,
) => {
  switch (action.type) {
    case GET_AVAILABLE_REPORTS_SUCCESS:
      return {
        ...state,
        availableReports: action.availableReports,
      };
    case GET_AVAILABLE_REPORTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case SET_SELECTED_REPORT_TYPE:
      return {
        ...state,
        selectedReportType: action.selectedReportType,
      };
    default:
      return state;
  }
};

export default reportSelectionReducer;
