import React from "react";
import CommonModal from "web/common/modals/commonModal";
import { modalTypes as commonModalTypes } from "../../../core/constants/modalConstants";
import { modalTypes as bookingModalTypes } from "../../../core/constants/bookingConstants";
import { modalTypes as checkInOutModalTypes } from "../../../core/constants/checkInOutConstants";
import AlertsModal from "../../common/modals/content/eligibilityModals/AlertsModalContainer";
import CheckInModalContent from "../../../dux/modals/checkIn/single/CheckInModalContentContainer";
import CheckinMultiModalContent from "dux/modals/checkIn/multi/checkInMultiModalContainerr";
import AppointmentSet from "../../booking/modals/content/AppointmentSetContainer";

const CheckInOutButtonModals = props => (
  <CommonModal onClose={() => props.onClose()}>{selectModalContent(props)}</CommonModal>
);

const selectModalContent = props => {
  switch (props.modalType) {
    case checkInOutModalTypes.CHECK_IN:
      return <CheckInModalContent {...props} />;
    case checkInOutModalTypes.CHECK_IN_MULTI:
      return <CheckinMultiModalContent {...props} />;
    case bookingModalTypes.APPOINTMENT_SET:
      return <AppointmentSet customerKey={props.customerKey} isCheckingIn {...props} />;
    case commonModalTypes.ELIGIBILITY_WARNINGS:
      return <AlertsModal {...props} isWarnings />;
    default:
      return null;
  }
};

export default CheckInOutButtonModals;
