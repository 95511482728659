import { get } from "lodash/fp";
import moment from "moment";
import filterValidAppointmentsByStore from "./filterValidAppointmentsForStore";

/**
 * Responsible for determining if an appointment should be shown in the daily Gantt view.
 * @param {object} petServiceItem
 * @param {number} storeNumber
 * @param {string} selectedDate
 * @returns {boolean}
 */
export default function filterValidDailyAppointments({
  petServiceItem,
  storeNumber,
  selectedDate
}) {
  return (
    filterValidAppointmentsByStore(petServiceItem, storeNumber) &&
    moment(get("startDateTime", petServiceItem)).isSame(selectedDate, "day")
  );
}
