import { GET_TRAINING_CLASS_AVAILABILITY_SUCCESS } from "./trainingClassAvailabilityActions";
import { RESET_SCHEDULE_TRAINING_PAGE } from "@/dux/scheduleTrainingPage/scheduleTrainingPageActions";
import normalizeArrayByProperty from "@/core/utils/normalizeUtils/normalizeArray";

const initialState = {};

const trainingClassAvailabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRAINING_CLASS_AVAILABILITY_SUCCESS:
      const availableTrainingClasses = action.payload.classes || [];
      const {
        petServiceId,
        serviceDurationTime,
        serviceDurationTimeUnit,
        behaviors,
      } = action.payload;

      const availableTimeSlots = [];

      availableTrainingClasses.forEach(trainingClass => {
        const startDatetime = trainingClass.startDatetime;
        const endDatetime = trainingClass.endDatetime;

        const availableTimeSlot = { startDatetime, endDatetime };

        const trainingClassSessions = trainingClass.classSessions;

        trainingClassSessions.forEach(({ classSessionId, associateId }) => {
          availableTimeSlots.push({
            ...availableTimeSlot,
            petServiceId,
            classSessionId,
            serviceDurationTime,
            serviceDurationTimeUnit,
            associateId,
            behaviors,
          });
        });
      });

      const normalizedAvailableTrainingClasses = normalizeArrayByProperty(
        availableTimeSlots,
        "classSessionId",
      );

      return { ...state, ...normalizedAvailableTrainingClasses };
    case RESET_SCHEDULE_TRAINING_PAGE:
      return initialState;
    default:
      return state;
  }
};

export default trainingClassAvailabilityReducer;
