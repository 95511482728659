/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const quickViewActionTypes = {
  SHOW_QUICKVIEW: "SHOW_QUICKVIEW",
  HIDE_QUICKVIEW: "HIDE_QUICKVIEW",
  SHOW_APPOINTMENT_QUICKVIEW: "SHOW_APPOINTMENT_QUICKVIEW",
  HIDE_APPOINTMENT_QUICKVIEW: "HIDE_APPOINTMENT_QUICKVIEW",
  SHOW_REBOOK_QUICKVIEW: "SHOW_REBOOK_QUICKVIEW",
  HIDE_REBOOK_QUICKVIEW: "HIDE_REBOOK_QUICKVIEW",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const showQuickView = ({ xLoc, yLoc, petServiceItemId }) => ({
  type: quickViewActionTypes.SHOW_QUICKVIEW,
  xLoc,
  yLoc,
  petServiceItemId
});

export const showAppointmentQuickView = ({ xLoc, yLoc, petServiceItemId }) => ({
  type: quickViewActionTypes.SHOW_APPOINTMENT_QUICKVIEW,
  xLoc,
  yLoc,
  petServiceItemId
});

export const showRebookQuickView = ({ xLoc, yLoc }) => ({
  type: quickViewActionTypes.SHOW_REBOOK_QUICKVIEW,
  xLoc,
  yLoc,
});

export const hideQuickView = () => ({
  type: quickViewActionTypes.HIDE_QUICKVIEW,
});

export const hideAppointmentQuickView = () => ({
  type: quickViewActionTypes.HIDE_APPOINTMENT_QUICKVIEW,
});

export const hideRebookQuickView = () => ({
  type: quickViewActionTypes.HIDE_REBOOK_QUICKVIEW,
});
