import { connect } from "react-redux";
import { ToggleButtonListWithSelectAll } from "dux/filteringButtons/ToggleButtonListWithSelectAll";
import { selectScheduleSuspensionsTableFilterPills } from "dux/scheduleSuspensionTable/scheduleSuspensionTableSelector";
import { setSuspensionScheduleFilterButtonData } from "dux/scheduleSuspensionFilterButtons/scheduleSuspensionFilterButtonsActions";

export const HotelScheduleSuspensionFilterButtons = connect(
  state => {
    const data = selectScheduleSuspensionsTableFilterPills(state);

    return {
      componentId: "HotelScheduleSuspensionFilterButtons",
      key: data, // force a component did update via the key field
      data,
    };
  },

  dispatch => {
    return {
      setGlobalState: data => {
        dispatch(setSuspensionScheduleFilterButtonData({ data }));
      },
    };
  },
)(ToggleButtonListWithSelectAll);
