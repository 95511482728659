import { IS_NOT_NUMBER_REGEX } from "../../constants/regex.js";

/** @module hasNonNumericCharacters */

/**
 * Returns if a string has a non numeric character within it or not
 *
 * @export hasNonNumericCharacters
 * @param {string} str
 * @returns {boolean}
 */
export default function hasNonNumericCharacters(str) {
  return IS_NOT_NUMBER_REGEX.test(str);
}
