/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
/**
 *  @constant
 *  type {String}
 */
export const PATCH_HOTEL_ITINERARY_ADDONS = "PATCH_HOTEL_ITINERARY_ADDONS";

/**
 *  @constant
 *  type {String}
 */
export const PATCH_HOTEL_ITINERARY_ADDONS_REQUEST = "PATCH_HOTEL_ITINERARY_ADDONS_REQUEST";

/**
 *  @constant
 *  type {String}
 */
export const PATCH_HOTEL_ITINERARY_ADDONS_SUCCESS = "PATCH_HOTEL_ITINERARY_ADDONS_SUCCESS";

/**
 *  @constant
 *  type {String}
 */
export const PATCH_HOTEL_ITINERARY_ADDONS_FAILURE = "PATCH_HOTEL_ITINERARY_ADDONS_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 * Action to patch addons to itinerary
 *
 * @memberOf Sagas.hotel.itinerary
 * @function
 * @name patchHotelItineraryAddons
 * @param {Object} payloadObj
 * @param {string} payloadObj.itineraryId
 * @param {string} payloadObj.customerId
 * @param {Object} payloadObj.data
 * @returns {Object}
 * @example patchHotelItineraryAddons({ itineraryId, customerId, data })
 */
export const patchHotelItineraryAddons = ({ itineraryId, customerId, data }) => ({
  type: PATCH_HOTEL_ITINERARY_ADDONS,
  itineraryId,
  customerId,
  data,
});

/**
 * Request action for itinerary addon patch
 *
 * @memberOf Sagas.hotel.itinerary
 * @function
 * @name patchHotelItineraryAddonsRequest
 * @returns {Object}
 * @example patchHotelItineraryAddonsRequest()
 */
export const patchHotelItineraryAddonsRequest = () => ({
  type: PATCH_HOTEL_ITINERARY_ADDONS_REQUEST,
});

/**
 * Success action for itinerary addon patch
 *
 * @memberOf Sagas.hotel.itinerary
 * @function
 * @name patchHotelItineraryAddonsSuccess
 * @returns {Object}
 * @example patchHotelItineraryAddonsSuccess()
 */
export const patchHotelItineraryAddonsSuccess = () => ({
  type: PATCH_HOTEL_ITINERARY_ADDONS_SUCCESS,
});

/**
 * Failure action for itinerary addon patch
 *
 * @memberOf Sagas.hotel.itinerary
 * @function
 * @name patchHotelItineraryAddonsFailure
 * @param {Object} error - error caught in try catch
 * @returns {Object}
 * @example patchHotelItineraryAddonsFailure()
 */
export const patchHotelItineraryAddonsFailure = error => ({
  type: PATCH_HOTEL_ITINERARY_ADDONS_FAILURE,
  error,
});
