import { put } from "redux-saga/effects";
import { waitFor, waitForRequests } from "core/sagas/utilsSaga";
import petsActionTypes from "core/actionTypes/petsActionTypes";
import customersActionTypes from "core/actionTypes/customersActionTypes";

/**
 * Responsible for getting PET Ids for all pets
 * @param { string }selectorKey - type of selector we a calling, eg, customerKey or itineraryId
 * @param { string }selectorId - selector param value, such as the actual id for customer or itinerary
 * @param { FN }selectorToWaitFor - A Redux selector - will be yielded until state for selector has updated.
 * @param { FN }requestAction - Redux action used to display a a request has been made in dev tools.
 * @return {Generator<Generator<Generator<SimpleEffect<"SELECT", SelectEffectDescriptor>|SimpleEffect<"TAKE", TakeEffectDescriptor>, *, ?>, void, ?>|Generator<SimpleEffect<"SELECT", SelectEffectDescriptor>|SimpleEffect<"TAKE", TakeEffectDescriptor>, *, ?>, *, ?>}
 */
export function* onGetPetIdsForAllPets({
  selectorKey,
  selectorId,
  selectorToWaitFor,
  requestAction,
}) {
  yield put(requestAction());

  // Wait for updatePet and updateCustomer API calls to complete finish before proceeding
  yield waitForRequests([petsActionTypes.UPDATE_PET, customersActionTypes.UPDATE_CUSTOMER]);

  const selectorResults = yield waitFor(selectorToWaitFor, { [selectorKey]: selectorId });
  const petIds = selectorResults.pets;

  return petIds;
}
