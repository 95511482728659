import React from "react";
import { createSelector } from "reselect";
import { isEmpty } from "lodash/fp";
import { getProps, getState } from "core/selectors/commonSelector";
import { getAssociate, getCustomerFullName, getCustomers } from "@/core/selectors/entitiesSelector";
import formatPhoneNumberNANP from "@/core/utils/stringManipulationUtils/formatPhoneNumber";
import { Text, Card } from "@prism/psm-ui-components";
import { formatDayMonthDateWithStartEndTime } from "@/core/utils/dateUtils/formatStartEndTime";
import {
  getTrainingClassSessions,
  getTrainingAttendees,
} from "@/dux/trainingClassSessions/selectors";
import getPetName from "@/dux/PetName/selectors/getPetName";

export const getAssociateProfilePage = (state) => state.associateProfilePage;

export const getIsAssociateProfileModalOpen = state =>
state.associateProfilePage.isAssociateProfileModalOpen;

export const getEmployeeGroups = (state) => state.entities.employeeGroups;

export const getEmployeeGroupsDropdownOptions = createSelector(
  [getEmployeeGroups],
  (employeeGroups) => Object.values(employeeGroups).map((group) => group.employeeGroupName),
);

// Usage getAssociateGroup(state, { associateId })
export const getAssociateGroup = createSelector(
  [getAssociate, getEmployeeGroups],
  (associate, employeeGroups) => {
    const associateGroupId = associate?.associateGroupId;
    return employeeGroups[associateGroupId];
  },
);

// Usage getAssociateGroupType(state, { associateId })
export const getAssociateGroupType = createSelector([getAssociateGroup], (group) => group?.type);

// Usage getIsAssociateVirtualTrainer(state, { associateId })
export const getIsAssociateVirtualTrainer = createSelector(
  [getAssociate],
  (associate) => associate?.isVirtualTrainer,
);

export const getIsTakePhotoModalOpen = createSelector(
  [getAssociateProfilePage],
  (associateProfilePage) => associateProfilePage.isTakePhotoModalOpen,
);

export const getIsRemoveVirtualTrainerModalOpen = createSelector(
  [getAssociateProfilePage],
  (associateProfilePage) => associateProfilePage.isRemoveVirtualTrainerModalOpen,
);

export const getIsRemoveTrainerFromVirtualFlashMessageOpen = createSelector(
  [getAssociateProfilePage],
  (associateProfilePage) => associateProfilePage.isRemoveTrainerFromVirtualFlashMessageOpen,
);

export const getTrainingClassSessionsByAssociate = createSelector(
  [getTrainingClassSessions, getProps],
  (classSessions, { associateId }) => {
    return Object.values(classSessions).filter((session) => {
      return session.associateId == associateId;
    });
  },
);

export const hasBookedTrainingAppointments = createSelector(
  [getTrainingClassSessionsByAssociate],
  (classSessions) => !isEmpty(classSessions),
);

const BorderShape = (
  <div
    style={{
      flex: "0.03",
      borderRadius: "5px",
      minHeight: "100px",
      maxWidth: "0.5%",
      minWidth: "2%",
      backgroundColor: "#f5a623",
    }}
  />
);

export const getAssociatesBookedTrainingAppointments = createSelector(
  [getTrainingClassSessionsByAssociate, getTrainingAttendees, getCustomers, getProps, getState],
  (classSessions, trainingAttendees, customers, { associateName }, state) => {
    return classSessions.map((session) => {
      const attendeeId = session.attendees[0];
      const attendee = trainingAttendees[attendeeId];

      const customerKey = attendee?.customerId;
      const customerName = getCustomerFullName(state, { customerKey });
      const customerPhoneNumber = formatPhoneNumberNANP(
        `${customers[customerKey]?.phones[0]?.phoneNumber}`,
      );
      const customerDetails = `${customerName} ${customerPhoneNumber}`;
      const timeHeader = formatDayMonthDateWithStartEndTime(
        session.startDateTime,
        session.endDateTime,
      );
      const petName = getPetName(state, { petId: attendee?.petId });
      return (
        <Card
          key={session.startDateTime + session.endDateTime}
          rows={[
            {
              id: 1,
              label: <Text bold>{timeHeader}</Text>,
            },
            { id: 2, label: <Text bold>{session.className}</Text> },
            {
              id: 3,
              label: <Text>{customerDetails}</Text>,
            },
            {
              id: 3,
              label: <Text>{petName}</Text>,
            },
            { id: 4, label: <Text align="right">{associateName}</Text> },
          ]}
          style={{
            height: "100%",
          }}
          children={BorderShape}
        />
      );
    });
  },
);
