import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCenter } from "@/layout/center/Center";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { Checkbox, TextPassage, Textarea } from "@petsmart-ui/sparky";

// Actions
import { loadVoidTransactionsReasons } from "@/core/actionCreators/voidTransactionActionCreator";
import voidTransactionActionTypes from "@/core/actionTypes/voidTransactionActionTypes";
import {
  setVoidReasonNotes,
  setVoidTransactionReason,
} from "@/dux/voidTransactionReasons/voidTransactionReasonsActions";

// Selectors
import {
  selectedOtherVoidReasonNotes,
  selectVoidTransactionReason,
  selectVoidTransactionsReasons,
} from "@/dux/voidTransactionReasons/voidTransactionResonsSelectors";

// Utils
import { createLoadingSelector } from "@/core/selectors/utils";
import LoadingWrapper from "@/web/common/LoadingWrapper";

// Constants
import { VOID_TRANSACTION_OTHER_REASON } from "@/dux/_components/invoice/invoiceConstants";

/**
 * React view component for Void transaction Reasons
 * @memberOf Views.Store
 * @function
 * @name VoidTransactionReasons
 * @param {Object} props
 * @param {String} props.componentId
 * @param {Boolean} props.isHidden
 * @param {Boolean} props.isLoading
 * @param {String} props.headerText
 * @param {Array} props.reasonsOptions
 * @param {String} props.selectedVoidReasonNotes
 * @param {String} props.selectedVoidReason
 * @param {Function} props.getVoidTransactionsReasons
 * @param {Function} props.setOtherVoidReasonNotes
 * @param {Function} props.setSelectedVoidReason
 * @returns {JSX.Element|null}
 * @example const container = connect()(VoidTransactionReasons)
 */
export const VoidTransactionReasons = props => {
  const {
    componentId,
    getVoidTransactionsReasons,
    headerText,
    isHidden,
    isLoading,
    reasonsOptions,
    selectedVoidReasonNotes,
    selectedVoidReason,
    setOtherVoidReasonNotes,
    setSelectedVoidReason,
  } = props;

  useEffect(() => {
    // Component on mount
    getVoidTransactionsReasons();

    return () => {
      // Component on unmount
      setSelectedVoidReason("");
      setOtherVoidReasonNotes("");
    };
  }, []);

  const [reasonNotes, setReasonNotes] = useState(selectedVoidReasonNotes);

  // Check if void reason is other
  const isOtherOptionSelected = selectedVoidReason === VOID_TRANSACTION_OTHER_REASON;

  // Handlers
  const handleOnCheckBoxSelection = option => {
    setSelectedVoidReason(option.value);
    if (!isOtherOptionSelected) {
      setOtherVoidReasonNotes("");
      setReasonNotes("");
    }
  };
  const handleOnBlurOtherTextArea = () => {
    setOtherVoidReasonNotes(reasonNotes);
  };

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId}>
      <LoadingWrapper isLoading={isLoading}>
        <LayoutStack>
          <LayoutCenter>
            <TextPassage align="center">{headerText}</TextPassage>
          </LayoutCenter>
          <LayoutStack>
            <LayoutCenter>
              {reasonsOptions.map(option => (
                <LayoutCluster key={option.name}>
                  <Checkbox
                    id={`${componentId}__selection--${option.name}`}
                    name={option.name}
                    onChange={() => handleOnCheckBoxSelection(option)}
                    checked={selectedVoidReason === option.value}
                  />
                  <TextPassage>
                    <span>{option.value}</span>
                  </TextPassage>
                </LayoutCluster>
              ))}
            </LayoutCenter>
            <LayoutCenter>
              {isOtherOptionSelected && (
                <Textarea
                  id={`${componentId}__otherReason`}
                  maxLength="255"
                  value={reasonNotes}
                  rows="3"
                  onBlur={handleOnBlurOtherTextArea}
                  onChange={event => setReasonNotes(event.target.value)}
                />
              )}
            </LayoutCenter>
          </LayoutStack>
        </LayoutStack>
      </LoadingWrapper>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for hotel void transaction reasons
 * @summary Located on the hotel check-out page
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelVoidTransactionReasons
 * @returns {JSX.Element|null}
 * @example <HotelVoidTransactionReasons />
 */
export const HotelVoidTransactionReasons = connect(
  state => {
    const isLoading = createLoadingSelector([
      voidTransactionActionTypes.LOAD_VOID_TRANSACTIONS_REASONS,
    ])(state);

    return {
      componentId: "HotelVoidTransactionReasons",
      isHidden: false,
      isLoading,
      headerText: "Before we void the transaction, we need to know the reason.",
      reasonsOptions: selectVoidTransactionsReasons(state),
      selectedVoidReasonNotes: selectedOtherVoidReasonNotes(state),
      selectedVoidReason: selectVoidTransactionReason(state),
    };
  },
  dispatch => {
    return {
      getVoidTransactionsReasons: () => {
        dispatch(loadVoidTransactionsReasons());
      },
      setOtherVoidReasonNotes: otherVoidReasonNotes => {
        dispatch(setVoidReasonNotes(otherVoidReasonNotes));
      },
      setSelectedVoidReason: voidReason => {
        dispatch(setVoidTransactionReason(voidReason));
      },
    };
  },
  (stateProps, dispatchProps) => {
    const {
      componentId,
      headerText,
      isHidden,
      isLoading,
      reasonsOptions,
      selectedVoidReasonNotes,
      selectedVoidReason,
    } = stateProps;
    const {
      getVoidTransactionsReasons,
      setOtherVoidReasonNotes,
      setSelectedVoidReason,
    } = dispatchProps;

    return {
      // State props
      componentId,
      isHidden,
      isLoading,
      headerText,
      reasonsOptions,
      selectedVoidReasonNotes,
      selectedVoidReason,

      // Dispatch props
      getVoidTransactionsReasons: () => {
        if (!reasonsOptions.length) getVoidTransactionsReasons();
      },
      setOtherVoidReasonNotes,
      setSelectedVoidReason,
    };
  },
)(VoidTransactionReasons);
