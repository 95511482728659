import moment from "moment";
import { formatCalendarDateMoment } from "@/core/utils/dateUtils/formatDateTime";
import { schema, normalize, denormalize } from "normalizr";
import {
  FOOD_PRODUCT_ID,
  MED_PRODUCT_ID,
  HOTEL_ID,
  SALON_ID,
  TRAINING_ID,
} from "./servicesCartConstants";

/**
 * get the id for an item in the cart api req/res model
 * @memberOf Utils.ServicesCart
 * @function
 * @name getCartItemId
 * @param {object} args
 * @param {string} args.petId
 * @param {Object} args.item - cart product obj (primary service, addon, food, or med)
 * @returns string - id to use when referencing the item in redux store
 * @example getCartItemId({ petId, item })
 */
export const getCartItemId = ({ petId, item } = {}) => {
  const { groupingId, productId } = item || {};
  const useGroupingId = !productId || productId === FOOD_PRODUCT_ID || productId === MED_PRODUCT_ID;
  const id = useGroupingId ? groupingId : productId;

  if (!petId || !id) return "";
  return `${petId}-${id}`;
};

const createPetEntity = key =>
  new schema.Entity(
    key,
    {},
    {
      idAttribute: (value, parent, key) => getCartItemId({ petId: parent.petId, item: value }),
      processStrategy: (value, parent, key) => ({
        ...value,
        petId: parent.petId,
        customFrequencyDates: value?.customFrequencyDates?.map(date =>
          formatCalendarDateMoment(moment(date).utc()),
        ),
      }),
    },
  );

const productsEntity = createPetEntity("products");
const feedingsEntity = createPetEntity("feedings");
const medicationsEntity = createPetEntity("medications");

const hotelPets = new schema.Entity(
  "pets",
  {
    products: [productsEntity],
    feedings: [feedingsEntity],
    medications: [medicationsEntity],
  },
  {
    idAttribute: "petId",
  },
);

const hotelCart = new schema.Entity(
  "hotelCart",
  {
    pets: [hotelPets],
  },
  {
    idAttribute: () => HOTEL_ID,
  },
);

const salonCart = new schema.Entity(
  "salonCart",
  {},
  {
    idAttribute: () => SALON_ID,
  },
);

const trainingCart = new schema.Entity(
  "trainingCart",
  {},
  {
    idAttribute: () => TRAINING_ID,
  },
);

/**
 * @param {Object} feedings - the normalized feedings object from cart responses
 * @returns an object that matches the shape of state.food.foods
 */
export const formatFeedingForState = feedings =>
  Object.entries(feedings).reduce((formattedFoods, [foodKey, food]) => {
    // Pull out any attrs that need to be renamed
    const {
      petId,
      foodName,
      foodId,
      foodType,
      customFrequencyDates,
      splInstruction,
      ...rest
    } = food;

    return {
      ...formattedFoods,
      [petId]: {
        ...formattedFoods[petId],
        [foodKey]: {
          ...rest,
          petFoodId: foodKey,
          name: foodName,
          type: foodType,
          externalId: foodId,
          concreteSchedule: customFrequencyDates,
          specialInstructions: splInstruction,
        },
      },
    };
  }, {});

/**
 * @param {Object} foods - Expects an object containing food objects for a specific pet
 * @returns array of foods for Cart call
 */
export const formatPetFoodsForCartRequest = foods =>
  Object.values(foods).map(({ preserved, ...foodState }) => {
    // If food form is being edited but hasn't been saved, then use the preserved version and not the pending changes
    const food = preserved ? { ...foodState, ...preserved } : foodState;

    const {
      name,
      type,
      externalId,
      concreteSchedule,
      specialInstructions,
      frequency,
      timeOfDay,
      amount,
      groupingId,
    } = food;
    const customFrequencyDates = concreteSchedule?.map(date => formatCalendarDateMoment(date));

    return {
      frequency,
      timeOfDay,
      amount,
      groupingId,
      productId: FOOD_PRODUCT_ID,
      customFrequencyDates: customFrequencyDates || [],
      foodId: externalId,
      foodName: name,
      foodType: type,
      splInstruction: specialInstructions,
    };
  }, []);

/**
 *
 * @param {Object} foods Expects state.food.foods which is in the following shape:
 *                  { [petId]: { [foodId]: {} } }
 * @returns the object reshaped for a Cart PUT request
 */
export const formatFoodsForCartPutRequest = foods =>
  Object.entries(foods).reduce((formattedFoods, [petId, petFoods]) => {
    const petFoodsArray = formatPetFoodsForCartRequest(petFoods);
    const formattedPetFoods = petFoodsArray.reduce(
      (obj, food) => ({ ...obj, [getCartItemId({ petId, item: food })]: { ...food, petId } }),
      {},
    );

    return { ...formattedFoods, ...formattedPetFoods };
  }, {});

/**
 * @param {Object} medications - the normalized medications object from cart responses
 * @returns an object that matches the shape of state.medication.medications
 */
export const formatMedicationsForState = medications =>
  Object.entries(medications).reduce((formattedMeds, [medKey, med]) => {
    // Pull out any attrs that need to be renamed
    const {
      petId,
      medicationName,
      medicationId,
      customFrequencyDates,
      splInstruction,
      dose,
      ...rest
    } = med;

    return {
      ...formattedMeds,
      [petId]: {
        ...formattedMeds[petId],
        [medKey]: {
          ...rest,
          petMedicationId: medKey,
          name: medicationName,
          externalId: medicationId,
          concreteSchedule: customFrequencyDates,
          specialInstructions: splInstruction,
          amount: dose,
        },
      },
    };
  }, {});

/**
 * @param {Object} meds - Expects an object containing med objects for a specific pet
 * @returns array of meds for Cart call
 */
export const formatPetMedsForCartRequest = meds =>
  Object.values(meds).map(med => {
    const {
      name,
      externalId,
      concreteSchedule,
      specialInstructions,
      frequency,
      timeOfDay,
      amount,
      groupingId,
      pricing,
    } = med;
    const customFrequencyDates = concreteSchedule?.map(date => formatCalendarDateMoment(date));

    return {
      productId: MED_PRODUCT_ID,
      customFrequencyDates: customFrequencyDates || [],
      medicationId: externalId,
      medicationName: name,
      splInstruction: specialInstructions,
      frequency,
      timeOfDay,
      dose: amount,
      groupingId,
      pricing,
    };
  }, []);

/**
 *
 * @param {Object} meds Expects state.medication.medications which is in the following shape:
 *                  { [petId]: { [medId]: {} } }
 * @returns the object reshaped for a Cart PUT request
 */
export const formatMedsForCartPutRequest = meds =>
  Object.entries(meds).reduce((formattedMeds, [petId, petMeds]) => {
    const petMedsArray = formatPetMedsForCartRequest(petMeds);
    const formattedPetMeds = petMedsArray.reduce(
      (obj, med) => ({ ...obj, [getCartItemId({ petId, item: med })]: { ...med, petId } }),
      {},
    );

    return { ...formattedMeds, ...formattedPetMeds };
  }, {});

const normalizeHotelCart = hotel => {
  if (hotel === null) return {};

  const { entities } = normalize(hotel, hotelCart);

  const { pets, ...cartDetails } = entities?.hotelCart[HOTEL_ID] || {};
  const filteredPets = entities?.pets || {};
  Object.keys(filteredPets).map(petId => {
    // Filter out the unnecessary arrays of id's
    const { feedings, medications, products, ...rest } = filteredPets[petId];
    filteredPets[petId] = rest;
  });

  return {
    cartDetails: cartDetails || {},
    pets: filteredPets || {},
    products: entities?.products || {},
    feedings: formatFeedingForState(entities?.feedings || {}),
    medications: formatMedicationsForState(entities?.medications || {}),
  };
};

export const getIdArrayForPets = (petId, obj) =>
  Object.keys(obj).filter(id => obj[id]?.petId?.toString() === petId);

export const getObjsWithoutPetId = obj =>
  Object.entries(obj).reduce(
    (newObj, [key, { petId, ...rest }]) => ({ ...newObj, [key]: rest }),
    {},
  );

export const denormalizeHotelCart = ({
  cartDetails = {},
  pets = {},
  products = {},
  feedings = {},
  medications = {},
}) => {
  // Reformat the foods & meds objs to match the normalized versions
  const formattedFeedings = formatFoodsForCartPutRequest(feedings);
  const formattedMeds = formatMedsForCartPutRequest(medications);

  // Add back some attributes that were removed in normalizeHotelCart()
  const entities = {
    hotelCart: {
      [HOTEL_ID]: {
        ...cartDetails,
        pets: Object.keys(pets),
      },
    },
    pets: Object.keys(pets).reduce(
      (petsObj, petId) => ({
        ...petsObj,
        [petId]: {
          ...pets[petId],
          products: getIdArrayForPets(petId, products),
          feedings: getIdArrayForPets(petId, formattedFeedings),
          medications: getIdArrayForPets(petId, formattedMeds),
        },
      }),
      {},
    ),
    products: getObjsWithoutPetId(products),
    feedings: getObjsWithoutPetId(formattedFeedings),
    medications: getObjsWithoutPetId(formattedMeds),
  };

  return denormalize(HOTEL_ID, hotelCart, entities);
};

const normalizeSalonCart = salon => {
  if (salon === null) return {};

  const { entities } = normalize(salon, salonCart);

  return {
    ...entities?.salonCart[SALON_ID],
  };
};

const normalizeTrainingCart = training => {
  if (training === null) return {};

  const { entities } = normalize(training, trainingCart);
  return {
    ...entities?.trainingCart[TRAINING_ID],
  };
};

export const normalizeServicesCart = cartResponse => {
  if (!cartResponse) {
    throw new Error("Cart API response must be supplied");
  }

  const { hotel = {}, salon = {}, training = {}, ...servicesCart } = cartResponse;

  return {
    servicesCart,
    hotelCart: normalizeHotelCart(hotel),
    salonCart: normalizeSalonCart(salon),
    trainingCart: normalizeTrainingCart(training),
  };
};

/**
 * Used to merge the food or med from cart responses (newObjs) into the existing food or med state (oldObjs)
 * @param {Object} oldObjs - an object that is either the foods state or the medications state
 * @param {Object} newObjs - an object of the same shape as oldObs and containing the same type of objs (food or med)
 * @returns an object
 */
export const mergeFoodObjectsOrMedObjects = (oldObjs, newObjs) =>
  Object.entries(newObjs ?? {}).reduce(
    (petObj, [petId, foodsOrMeds]) => ({
      ...petObj,
      [petId]: Object.entries(foodsOrMeds).reduce((obj, [key, foodOrMed]) => {
        const existingFoodOrMed =
          (oldObjs[petId] &&
            Object.values(oldObjs[petId] ?? {}).find(
              oldObj => getCartItemId({ petId, item: oldObj }) === key,
            )) ||
          {};
        return {
          ...obj,
          [key]: {
            ...existingFoodOrMed,
            ...foodOrMed,
          },
        };
      }, {}),
    }),
    {},
  );
