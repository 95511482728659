import { displayFeatureFlagIconActionTypes } from "./displayFeatureFlagIconActions";

const initialState = {
  isHidden: true
};

const displayFeatureFlagIconReducer = (state = initialState, action) => {
  switch (action.type) {
    case displayFeatureFlagIconActionTypes.DISPLAY_FEATURE_FLAG_ICON:
      return {
        ...state,
        isHidden: action.isHidden
      };
    default:
      return state;
  }
};

export default displayFeatureFlagIconReducer;
