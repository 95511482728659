import React from "react";
import styled from "styled-components";
import CommonButton from "../../../../../../common/commonButton";
import isFormEmpty from "../../../../../../../core/utils/formUtils/isFormEmpty";
import { modalTypes } from "../../../../../../../core/constants/petProfileConstants";

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding: 1em 0 0 0;
  ${props =>
    props.showVetSearchResults &&
    `
        margin-bottom: 1em;
    `};
`;

const Button = styled(CommonButton)`
  padding: 0.75em 3em;
  margin-bottom: 1em;
  ${props =>
    props.margin &&
    `
    margin-right: 1em;
`};
`;

export default ({ showVetSearchResults, handleSubmit, values, showModal, isLoading }) => (
  <Footer showVetSearchResults={showVetSearchResults}>
    {showVetSearchResults &&
      !isLoading && (
        <Button
          margin
          className="resultList__button"
          onClick={() => showModal(modalTypes.ADD_VET)}
          label="Add new vet"
        />
      )}
    <Button label="Search" onClick={handleSubmit} disabled={isFormEmpty(values)} />
  </Footer>
);
