import { engagementTypeIds } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { reservationNeedsReview } from "@/dux/hotelPetCardIcon/hotelIconUtils";

const { DAY_CAMP, DAY_CARE, OVERNIGHT_BOARDING } = engagementTypeIds;

export default function filterAppointmentsByFilterType(appointments, filterBy) {
  if (filterBy?.all) return appointments;

  return appointments.filter(
    ({
      hasPSA,
      isMissingVaccinations,
      needsReview,
      receiveMedications,
      productFamilyId,
      isUnassigned,
      hasSalonAppointment,
    }) => {
      if (filterBy?.overnight && productFamilyId === OVERNIGHT_BOARDING.toString()) return true;
      if (filterBy?.dayCamp && productFamilyId === DAY_CAMP.toString()) return true;
      if (filterBy?.dayCare && productFamilyId === DAY_CARE.toString()) return true;
      if (
        filterBy?.attentionNeeded &&
        reservationNeedsReview({
          hasPSA,
          isMissingVaccinations,
          needsReview,
          receiveMedications,
          hasSalonAppointment,
        })
      )
        return true;
      if (filterBy?.unassignedPet && isUnassigned) return true;

      return false;
    },
  );
}
