import { connect } from "react-redux";
import { getDDCArrivals } from "dux/ddcRoomCapacity/ddcRoomCapacitySelector";
import { columnHeaders } from "core/constants/dashboardContants";
import HotelArrivalsPetCardContainer from "dux/hotelArrivals/columnList/HotelArrivalsPetCardContainer";
import ScrollablePetCardList from "dux/_components/petCard/ScrollablePetCardList";

const mapStateToProps = state => ({
  isHidden: false,
  componentId: "ScrollablePetCardList__DDCRoomCapacityArrivalAppointments",
  appointments: getDDCArrivals(state),
  diComp: {
    [columnHeaders.ARRIVALS]: HotelArrivalsPetCardContainer,
  },
  compName: columnHeaders.ARRIVALS,
});

export default connect(mapStateToProps)(ScrollablePetCardList);
