/**
 * Responsible for returning the workflows Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getWorkflowsState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the featureFlags object in the redux store
 *
 * @example createSelector([getWorkflowsState], workflowState => workflowState.BGM;
 */
export default function getWorkflowsFeatureFlagState(state) {
  return state?.persistent?.workflowFeatureFlags ?? {};
}
