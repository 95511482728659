import normalizeString from "./normalizeString";

/**
 * Compares two strings for equality, ignoring case sensitivity.
 * This function is useful for comparing strings in scenarios where the case of the characters should not affect the comparison outcome, such as environments or other identifiers that are case-insensitive.
 * @todo remove default export
 * @todo refactor name to caseInsensitiveCompare for more declarative on what is being compared
 * @todo refactor to be a pure function e.g. change body to return str1.toLowerCase() === str2.toLowerCase();
 * @memberof Utils.Strings
 * @param {String} str1 The first string to compare
 * @param {String} str2 The second string to compare
 * @returns {Boolean} Returns `true` if the strings are equal regardless of their case, otherwise `false`.
 *
 * @example
 * // returns true
 * compareStrings('hello', 'HELLO');
 *
 * @example
 * // returns false
 * compareStrings('hello', 'world');
 * */

export default (a, b) => normalizeString(a) === normalizeString(b);
