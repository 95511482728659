import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

// Selectors
import { getCurrentPet } from "core/selectors/persistentSelectors";
import {
  getHotelEngagementsPets,
  selectIsLastToCheckOut,
  selectIsCheckoutToday,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { getHotelItinerary } from "../hotelItinerary/hotelItinerarySelectors";

// Actions
import { showHotelCheckOutReminder } from "../checkOutReminder/checkOutReminderActions";
import { hideCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import { patchHotelCheckOutPets } from "../checkOut/checkOutActions";

// Components
import {
  HotelCheckOutPetParentAlert,
  HotelCheckOutSinglePetAlert,
} from "@/web/hotelAlerts/HotelAlertComponent";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCenter } from "@/layout/center/Center";
import { Button, Heading } from "@petsmart-ui/sparky";
import { LayoutStack } from "@/layout/stack/Stack";
import { HotelCheckoutDayAlert } from "dux/checkoutDayAlert/checkoutDayAlert";

// Constants
import { checkOutReminder } from "../checkOutReminder/checkOutReminderConstants";

/**
 * React view component for check out customer and pet alerts
 * @memberOf Views.Customer
 * @function
 * @name CheckOutAlerts
 * @param {Object} props
 * @param {string} props.componentId
 * @param {Boolean} props.isHidden
 * @param {string} props.buttonLabel
 * @param {string} props.header
 * @param {Function} props.onProceed
 * @param {JSX.Element} props.checkoutDayAlert
 * @param {JSX.Element} props.customerAlerts
 * @param {JSX.Element} props.petAlerts
 * @returns {JSX.Element|null}
 * @example const container = connect()(CheckOutAlerts)
 */
export const CheckOutAlerts = props => {
  const {
    componentId,
    isHidden,
    buttonLabel,
    header,
    onProceed = () => {},
    checkoutDayAlert,
    customerAlerts,
    petAlerts,
  } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId}>
      <LayoutStack>
        <LayoutCenter>
          <Heading id={`${componentId}__heading`} tagName="h4">
            {header}
          </Heading>
        </LayoutCenter>
        {checkoutDayAlert}
        {customerAlerts}
        {petAlerts}
        <LayoutCenter>
          <Button
            id={`${componentId}__button`}
            onClick={onProceed}
            variant="primary"
            text={buttonLabel}
          />
        </LayoutCenter>
      </LayoutStack>
    </LayoutBox>
  );
};

/**
 * Redux Connect function to get shared props for hotel check out alerts modals
 * @see {@link Views.Customer.HotelCheckOutAlerts} & {@link Views.Customer.HotelCheckOutAllPetsAlerts}
 * @summary Located on the hotel check-out page
 * @memberOf Views.Customer
 * @function
 * @name HotelCheckOutAlertsCommonProps
 * @returns {() => JSX.Element|null}
 * @example const container = compose(HotelCheckOutAlertsCommonProps, connect())(component);
 */
const HotelCheckOutAlertsCommonProps = connect(
  state => {
    return { itineraryId: getHotelItinerary(state)?.itineraryId, header: "Check Out Alerts!" };
  },
  dispatch => {
    const closeModals = () => dispatch(hideCheckInOutModal());

    const closeModalsAndCheckOut = ({ petIds, itineraryId }) => {
      closeModals();
      dispatch(patchHotelCheckOutPets({ petIds, itineraryId }));
    };

    const showCheckOutReminder = ({ petIds, itineraryId }) => {
      dispatch(
        showHotelCheckOutReminder({
          confirm: () => closeModalsAndCheckOut({ petIds, itineraryId }),
          cancel: closeModals,
        }),
      );
    };

    return { closeModalsAndCheckOut, showCheckOutReminder };
  },
  ({ itineraryId, header }, { closeModalsAndCheckOut, showCheckOutReminder }) => {
    return {
      header,
      closeModalsAndCheckOut: petIds => closeModalsAndCheckOut({ petIds, itineraryId }),
      showCheckOutReminder: petIds => showCheckOutReminder({ petIds, itineraryId }),
    };
  },
);

/**
 * Redux Connect function for hotel check out alerts for current pet
 * @see {@link Views.Customer.CheckOutAlerts}
 * @summary Located on the hotel check-out page
 * @memberOf Views.Customer
 * @function
 * @name HotelCheckOutAlerts
 * @returns {JSX.Element|null}
 * @example <HotelCheckOutAlerts />
 */
export const HotelCheckOutAlerts = compose(
  HotelCheckOutAlertsCommonProps,
  connect((state, ownProps) => {
    // shared props
    const { closeModalsAndCheckOut, showCheckOutReminder } = ownProps;

    const petId = getCurrentPet(state);
    const isLastToCheckOut = selectIsLastToCheckOut([petId])(state);
    const isCheckoutToday = selectIsCheckoutToday(state, { petId });

    const checkoutDayAlert = isCheckoutToday ? null : <HotelCheckoutDayAlert />;
    const customerAlerts = <HotelCheckOutPetParentAlert />;
    const petAlerts = <HotelCheckOutSinglePetAlert petId={petId} />;

    return {
      componentId: "HotelCheckOutAlerts",
      buttonLabel: checkOutReminder.proceed,
      checkoutDayAlert,
      customerAlerts,
      petAlerts,
      onProceed: () => {
        const petIds = [petId];

        // If this pet isn't the last (or only) on itinerary to check out, then don't show reminder confirmation
        if (!isLastToCheckOut) {
          closeModalsAndCheckOut(petIds);
          return;
        }

        showCheckOutReminder(petIds);
      },
    };
  }),
)(CheckOutAlerts);

/**
 * Redux Connect function for hotel check out alerts for all pets
 * @see {@link Views.Customer.CheckOutAlerts}
 * @summary Located on the hotel check-out page
 * @memberOf Views.Customer
 * @function
 * @name HotelCheckOutAllPetsAlerts
 * @returns {JSX.Element|null}
 * @example <HotelCheckOutAllPetsAlerts />
 */
export const HotelCheckOutAllPetsAlerts = compose(
  HotelCheckOutAlertsCommonProps,
  connect((state, ownProps) => {
    // shared props
    const { showCheckOutReminder } = ownProps;

    const petIds = getHotelEngagementsPets(state);
    const allCheckingOutToday = petIds.every(petId => selectIsCheckoutToday(state, { petId }));

    const checkoutDayAlert = allCheckingOutToday ? null : <HotelCheckoutDayAlert />;
    const customerAlerts = <HotelCheckOutPetParentAlert />;
    const petAlerts = petIds.map(petId => (
      <HotelCheckOutSinglePetAlert key={petId} petId={petId} />
    ));

    return {
      componentId: "HotelCheckOutAllPetsAlerts",
      buttonLabel: checkOutReminder.proceed,
      checkoutDayAlert,
      customerAlerts,
      petAlerts,
      onProceed: () => showCheckOutReminder(petIds),
    };
  }),
)(CheckOutAlerts);
