import { connect } from "react-redux";
import ClickableText from "web/common/ClickableText";
import { color } from "web/common/styles/theme";
import { rebookClick } from "core/actionCreators/petParentProfileActionCreator";
import { getPet, getPetServiceItem } from "core/selectors/entitiesSelector";
import { REBOOK } from "dux/appointmentRebook/_appointmentRebookConstants";
import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { selectPet } from "core/actionCreators/bookingActionCreators";

const mapStateToProps = (state, ownProps) => {
  const petId = ownProps.appointment.pet;
  const pet = getPet(state, { petId });
  const { engagementType } = ownProps.appointment

  return {
    componentID: `AppointmentRebookPastAppt__ClickableText${ownProps.appointment.petServiceItemId}`,
    isVisible: ownProps.type === "past" && engagementType !== engagementTypes.TRAINING,
    label: REBOOK,
    styleObj: { color: color.kalEl },

    // not using petIsActive() as it does this by current customer to get current pet, We need the pet
    // by id in this case, so get it directly from the pet object in state.
    disabled: !pet.isActive,

    // for merge props
    appointment: getPetServiceItem(state, ownProps.appointment),
    petId,
  };
};

const mapDispatchToProps = dispatch => ({
  rebookHandler: (e, { appointment, petId }) => {
    e.stopPropagation();

    if (!appointment) {
      return;
    }

    dispatch(selectPet(petId));
    dispatch(rebookClick({ appointment }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: e =>
    propsFromDispatch.rebookHandler(e, {
      appointment: propsFromState.appointment,
      petId: propsFromState.petId,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ClickableText);
