import { unset } from "lodash/fp";
import pick from "lodash/pick";
import { v4 as uuidv4 } from 'uuid';
import { foodFrequencyActionTypes } from "dux/bookingFeeding/setFoodFrequency/actions/foodFrequencyActions";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { addFoodBookingActionTypes } from "dux/bookingFeeding/addNewFoodBookingActions";
import { foodSaveToProfileActionTypes } from "dux/bookingFeeding/bookingSaveFoodToProfile/foodSaveToProfileActions";
import { getFoodSaveToProfileById } from "@/dux/bookingFeeding/bookingSaveFoodToProfile/foodSaveToProfileSelectors";
import { newFoodActionTypes } from "../../newFood/actions/newFoodActions";
import { setFoodTypeActionTypes } from "../../foodType/actions/setFoodTypeActions";
import { setFoodNameActionTypes } from "../../foodName/actions/setFoodNameActions";
import { setFoodTypeAmountActionTypes } from "../../foodTypeAmount/actions/foodTypeAmountActions";
import { setFoodTimeOfDayActionTypes } from "../../foodTimeOfDay/actions/foodTimeOfDayActions";
import { setFoodInstructionsActionTypes } from "../../foodInstructions/actions/foodInstructionsActions";
import { foodListActionTypes } from "../../foodList/actions/foodListActions";
import { foodsActionTypes } from "../actions/foodsActions";
import { NEW_FOOD_ID } from "../../newFood/newFoodConstants";

const initialState = {};

const foodsReducer = (state = initialState, action) => {
  const petId = action.petId || getCurrentPet(state); // Either pass a specific petId to update or use the current pet.
  // Sets Save to Profile in the booking flow to unchecked if a pet's info is updated.
  const saveToProfile = getFoodSaveToProfileById(state, {
    foodId: action.foodId,
    petId,
  });
  switch (action.type) {
    case foodsActionTypes.SET_FOODS:
      return { ...state, [petId]: { ...action.foods } };
    case newFoodActionTypes.SET_NEW_FOOD:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          NEW_FOOD: {
            groupingId: uuidv4(),
            petFoodId: NEW_FOOD_ID,
            saveToProfile: false,
          },
        },
      };
    case foodsActionTypes.CLEAR_FOODS:
      return initialState;
    case newFoodActionTypes.CLEAR_NEW_FOOD:
      return unset(`${petId}.${NEW_FOOD_ID}`, state);
    case setFoodTypeActionTypes.SET_FOOD_TYPE:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...state[petId][action.foodId],
            type: action.foodType,
          },
        },
      };
    case setFoodNameActionTypes.SET_FOOD_NAME:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...state[petId][action.foodId],
            name: action.name,
            externalId: action.externalId,
            saveToProfile,
          },
        },
      };
    case addFoodBookingActionTypes.ADD_NEW_BOOKING_FOOD:
      return {
        ...state,
        [petId]: {
          [action.foodId]: {
            ...state[petId].NEW_FOOD,
            petFoodId: action.foodId,
            locationTypes: [],
            isFoodDetailsShowing: false,
          },
          ...state[petId],
        },
      };
    case setFoodTypeAmountActionTypes.SET_FOOD_TYPE_AMOUNT:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...state[petId][action.foodId],
            amount: action.foodTypeAmount,
            saveToProfile,
          },
        },
      };
    case setFoodTimeOfDayActionTypes.SET_FOOD_TIME_OF_DAY:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...state[petId][action.foodId],
            timeOfDay: action.timeOfDay,
            saveToProfile,
          },
        },
      };
    case setFoodInstructionsActionTypes.SET_FOOD_INSTRUCTIONS:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...state[petId][action.foodId],
            specialInstructions: action.foodInstructions,
            saveToProfile,
          },
        },
      };
    case foodListActionTypes.SHOW_HIDE_FOOD_DETAILS:
      return {
        ...state,
        [petId]: {
          ...state?.[petId],
          [action.foodId]: {
            ...state?.[petId]?.[action.foodId],
            locationTypes: action.locationTypes,
          },
        },
      };
    case setFoodTimeOfDayActionTypes.FOOD_TIME_OF_DAY_TOUCHED:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...state[petId][action.foodId],
            timeOfDayTouched: action.timeOfDayTouched,
          },
        },
      };
    case foodSaveToProfileActionTypes.SET_FOOD_SAVE_TO_PROFILE:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...state[petId][action.foodId],
            saveToProfile: action.saveToProfile,
          },
        },
      };
    case foodFrequencyActionTypes.SET_FOOD_FREQUENCY:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...state[petId][action.foodId],
            frequency: action.frequency,
          },
        },
      };
    case foodFrequencyActionTypes.SET_FOOD_FREQUENCY_DATES:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...state[petId][action.foodId],
            concreteSchedule: action.dates,
          },
        },
      };
    case foodFrequencyActionTypes.CLEAR_FOOD_FREQUENCY:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...state[petId][action.foodId],
            frequency: null,
            concreteSchedule: null,
          },
        },
      };
    case foodFrequencyActionTypes.FOOD_FREQUENCY_TOUCHED:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...state[petId][action.foodId],
            frequencyTouched: true,
          },
        },
      };
    case foodListActionTypes.REMOVE_PET_FOOD_FROM_BOOKING:
      return unset(`${petId}.${action.foodId}`, state);
    case foodsActionTypes.PRESERVE_FOOD: {
      const food = state[petId][action.foodId];
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...food,
            preserved: pick(food, [
              "externalId",
              "type",
              "name",
              "amount",
              "timeOfDay",
              "specialInstructions",
              "frequency",
              "concreteSchedule",
              "saveToProfile",
            ]),
          },
        },
      };
    }
    case foodsActionTypes.APPLY_PRESERVED_FOOD: {
      const { preserved, ...food } = state[petId][action.foodId];
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.foodId]: {
            ...food,
            ...preserved,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default foodsReducer;
