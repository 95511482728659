import React from "react";
import { Layout } from "@prism/psm-ui-components";
import AssociateProfileSubheading from "./AssociateProfileSubheading";
import AssociateProfileFormSalon from "@/dux/associateProfile/associateProfileForm/AssociateProfileFormSalon";

const AssociateProfileSalon = () => {
  return (
    <Layout.Box>
      <Layout.Box padding="box-padding-5">
        <AssociateProfileSubheading />
      </Layout.Box>
      <Layout.Box>
        <AssociateProfileFormSalon />
      </Layout.Box>
    </Layout.Box>
  );
};

export default AssociateProfileSalon;
