/**
 * A helper function that checks if arg1 is undefined and returns either the arg1 or arg2.
 * If both arg1 or arg2 is undefined, it returns a null value.
 *
 * Note: A reducer should never return undefined. It is too easy to do this if an arg1 sends in a value of
 * undefined, this is just is a simple check, that if the value is undefined, it sets it to the initial
 * state.
 *
 * @memberof Utils.CurrentCustomerKey
 * @function
 * @param {any} arg1 - The arg1 to check.
 * @param {any} arg2 - The initial state to return if the arg1 is undefined.
 * @returns {any} - The arg1 or the initial state.
 */
export const checkForUndefined = (arg1, arg2) => {
  if (arg1 === undefined && arg2 === undefined) {
    return null;
  }
  return arg1 === undefined ? arg2 : arg1;
};
