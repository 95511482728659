import { SET_HOTEL_ROOM_MANAGEMENT_SEARCH } from "./hotelRoomManagementSearchActions";

const initialState = {
  searchTerm: "",
};

const hotelRoomManagementSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTEL_ROOM_MANAGEMENT_SEARCH:
      return {
        ...state,
        searchTerm: action.payload,
      };
    default:
      return state;
  }
};

export default hotelRoomManagementSearchReducer;
