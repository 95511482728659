import { connect } from "react-redux";
import MenuButton from "../common/MenuButton";
import { history } from "@/dux/utils/browser/browserHistory";
import { setSystemBookingFlowType } from "../setSystemType/actions/setSystemTypeActions";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import { routePaths } from "../../core/constants/routePaths";
import getIsTrainingWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/training/getIsTrainingWorkflowFeatureFlagHidden";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";

const mapStateToProps = (state) => {
  const isTrainingFeatureFlagHidden = getIsTrainingWorkflowFeatureFlagHidden(state);
  const storeNumber = getStoreNumber(state);
  return {
    className: "ProfileButton__menuButton--salonDashboard",
    componentID: "ProfileButton-salonDashboard",
    // When we release the VDT platform, we want to distinguish between "salon" and "training"
    // options. We will do this by migrating the salon dashboard menu item copy from "Main Dashboard"
    // to "Salon Dashboard". When we remove the VDT feature flag, we will have to remember
    // to strip this logic out and default to "Salon Dashboard".
    title: isTrainingFeatureFlagHidden ? "Main Dashboard" : "Salon Dashboard",
    // We want to hide the salon dashboard link in the SRC flow if there's no store number
    isHidden: !storeNumber,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClick: () => {
    dispatch(setSystemBookingFlowType({ systemBookingFlow: systemName.SALON }));
    history.push(routePaths.DASHBOARD);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);
