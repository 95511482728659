import React from "react";
import styled from "styled-components";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { noop } from "lodash/fp";
import Tabs from "../../../common/Tabs";
import Tab from "../../../common/Tab";
import AddButtonContainer from "../../../common/AddButtonContainer";
import { NEW_PET_ID } from "@/core/constants";
import { history } from "@/dux/utils/browser/browserHistory";
import { isFromSelectTimeSlot } from "@/core/utils/validationUtils/isFromValidation";
import AddPetAddButtonContainer from "../buttons/AddPetAddButtonContainer";

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${props => (props.withPadding ? "0.625rem" : "auto")};
`;

const PetsTabs = props => {
  const {
    componentId = "PetTabs",
    isHidden,
    router: { location },
    appointments,
    selectedPet,
    onCancelAddPet,
    isPetAdding,
    onSelectPet,
    className,
    petNames,
    isAvailabilityLoading,
    tabsData,
    style,
    onTabChange = noop,
  } = props;

  if (isHidden) return null;

  const isInProfile = location.pathname.includes("pet-parent-profile");

  return (
    <TabsWrapper className={className} style={style}>
      <Tabs
        componentId={`${componentId}__wrapper`}
        activeTab={selectedPet}
        onTabClick={id => {
          if (id !== NEW_PET_ID && isPetAdding) {
            onCancelAddPet();
          }
          onSelectPet(id);
          onTabChange();
        }}
      >
        {tabsData.map((item, index) => {
          const title = item.petName || petNames[item.pet];
          const id = item.petId || item.petServiceItemId;

          const selected =
            !isPetAdding &&
            (location.pathname.endsWith("select-service") ||
              location.pathname.endsWith("select-time-slot")) &&
            appointments[item.petId] != undefined;
          const petServiceItemId =
            appointments && appointments[item.petId] && appointments[item.petId].petServiceItemId;
          const selectedTime =
            location.pathname.endsWith("select-time-slot") && typeof petServiceItemId === "number";

          // If we are on the pet parent profile, check the "isActive" property on the pet profile. If we're on the appointment
          // view page (past appt, check-in/out, etc.), then check the "isPetActive" flag on the pet service item.
          // TODO: Refactor PetsTabs component to decouple this logic from the component.
          const isActive = id !== NEW_PET_ID && !item.status ? item.isActive : item.isPetActive;
          const disabled =
            isFromSelectTimeSlot(history.location.pathname.toString()) && isAvailabilityLoading;
          return (
            <Tab
              componentId={`${componentId}__Tab-${index}`}
              selected={selectedTime || selected}
              onAddPet={isPetAdding}
              key={id}
              title={title}
              tabId={id}
              isPetActive={!isActive}
              disabled={disabled}
            />
          );
        })}
      </Tabs>

      {!isPetAdding && isInProfile && (
        <AddButtonContainer>
          <AddPetAddButtonContainer />
        </AddButtonContainer>
      )}
    </TabsWrapper>
  );
};

export default withRouteProps(PetsTabs);
