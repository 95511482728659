import { connect } from "react-redux";
import { getMedicationInstructionsById } from "./medicationInstructionsSelectors";
import { setMedicationInstructions } from "./actions/medicationInstructionsActions";
import TextAreaWithBlur from "../common/TextAreaWithBlur";
import { getMedIsDiscontinuedById } from "../medication/medicationsSelector";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, medicationIndex, petId } = ownProps;
  const currentValue = getMedicationInstructionsById(state, { medicationId, petId });
  return {
    medicationId,
    petId,
    currentValue,
    componentId: "medicationDetails-medicationInstructions",
    height: "3.75em",
    title: `Medication ${medicationIndex} instructions`,
    maxLength: 250,
    disabled: getMedIsDiscontinuedById(state, { petId, medicationId }),
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeMedicationInstructions: ({ value, medicationId, petId }) => {
    dispatch(setMedicationInstructions({ medicationInstructions: value, medicationId, petId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onBlur: value => {
    const { medicationId, petId } = propsFromState;
    propsFromDispatch.onChangeMedicationInstructions({ value, medicationId, petId });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TextAreaWithBlur);
