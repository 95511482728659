// cc: QuickViewAppointment#7;Display UI;Show the Appointment pet Card

import { connect } from "react-redux";
import { getPetServiceItem } from "../../../core/selectors/entitiesSelector";
import { getQuickViewAppointmentId } from "../../../core/selectors/quickView/appointmentQickViewSelectors";
import PetCardComponent from "../../upcoming_here/petCard/PetCardComponent";

const mapStateToProps = state => {
  const appointmentID = getQuickViewAppointmentId(state);
  const petServiceItem = getPetServiceItem(state, { petServiceItemId: appointmentID });

  return {
    isSelectedAppointment: false,
    associateId: petServiceItem.associate,
    customerKey: petServiceItem.customer,
    itineraryId: petServiceItem.itinerary,
    status: petServiceItem.status,
    showStatus: true,
    hideBorder: true,
    shouldShowCustomerPhone: true,
    appointmentID
  };
};

export default connect(mapStateToProps)(PetCardComponent);
