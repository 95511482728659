export const modalTypes = {
  GANTT_ZOOM: "GANTT_ZOOM",
  REBOOK_ERROR: "REBOOK_ERROR",
  ICON_SALON_KEY: "ICON_SALON_KEY",
  ICON_HOTEL_KEY: "ICON_HOTEL_KEY",
  SALON_APPOINTMENT_HISTORY: "APPOINTMENT_HISTORY",
  HOTEL_APPOINTMENT_HISTORY: "HOTEL_APPOINTMENT_HISTORY",
  REMOVE_SPECIAL: "REMOVE_SPECIAL",
  ALERTS: "ALERTS",
  ELIGIBILITY_WARNINGS: "ELIGIBILITY_WARNINGS",
  ADD_VACCINATION: "ADD_VACCINATION",
  DELETE_AGENT: "DELETE_AGENT",
  DELETE_EMAIL: "DELETE_EMAIL",
  RULE_VIOLATION: "RULE_VIOLATION",
  CANCEL_APPOINTMENT: "CANCEL_APPOINTMENT",
  IS_VIP: "IS_VIP",
  PURCHASE_BULK_PACKAGE: "PURCHASE_BULK_PACKAGE",
};

export const CONFIRMATION_MODAL_TYPE = {
  CONFIRM: "CONFIRM",
  ALERT: "ALERT",
};

export const MIN_OVERRIDE_MODAL_DESCRIPTION_LENGTH = 10;
export const MAX_OVERRIDE_MODAL_DESCRIPTION_LENGTH = 255;
