import axios from "axios";
import {
  setRequestHeaders,
  handleAuthorizationRejection,
  setResponseRefreshToken,
} from "../../utils/authHelper";

/**
 * Axios Create configuration for the Associate Web Profile API (/web/associate/profile).
 *
 * @summary /web/associate/profile.
 * @memberOf APIs.AssociateWebProfile
 * @function
 * @name  associateWebProfileAPI
 * @type {axios.AxiosInstance}
 *
 * @example
 * return associateWebProfileAPI.post(url, params)
 * return associateWebProfileAPI.get(url, params)
 * return associateWebProfileAPI.put(url, params)
 */
export const associateWebProfileAPI = axios.create({
  baseURL: `${window.env.BASE_URL}${window.env.ASSOCIATE_WEB_PROFILE_URI}`,
});

/**
 * Intercept the `x-refresh-session-token` value
 * - Note: This can be disabled by using the ENV flag below
 */
if (!window.env.DISABLE_API_AUTH_IN_DEV) {
  associateWebProfileAPI.interceptors.request.use(setRequestHeaders, handleAuthorizationRejection);
  associateWebProfileAPI.interceptors.response.use(
    setResponseRefreshToken,
    handleAuthorizationRejection,
  );
}
