import convertStringToNumbers from "../stringManipulationUtils/stringToNumberConversion";

const removeNANPFormat = ({ searchFieldType, searchFieldValue }) => {
  switch (searchFieldType) {
    case "phoneNumber":
      return { searchFieldValue: convertStringToNumbers(searchFieldValue) };

    // TODO: remove case email and name, not necessary
    case "email":
      return { searchFieldValue };
    case "name":
      return { searchFieldValue };
    default:
      return { searchFieldValue };
  }
};

export default removeNANPFormat;
