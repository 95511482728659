/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const  SHOW_HIDE_HOTEL_CAPACITY_MODAL = "SHOW_HIDE_HOTEL_CAPACITY_MODAL";
export const SHOW_HIDE_HOTEL_CAPACITY_DRAWER ="SHOW_HIDE_HOTEL_CAPACITY_DRAWER";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const showHideHotelCapacityModal = ({ isHidden }) => ({
  type: SHOW_HIDE_HOTEL_CAPACITY_MODAL,
  isHidden,
});
export const showHotelCapacityDrawer = ({ isDrawerOpen }) => ({
  type: SHOW_HIDE_HOTEL_CAPACITY_DRAWER,
  isDrawerOpen,
});
