export const calculateZoomLevel = eleWidth => {
  // Determine the available length of the print page
  // Assuming A4 paper in landscape is (297mm long) and 96 DPI, with 10mm margins on each side
  const mmToPixels = 96 / 25.4; // Convert mm to pixels
  const pageWidth = 297 * mmToPixels; // Convert page width from mm to pixels
  const margin = 10 * mmToPixels; // Convert margin from mm to pixels
  const availableWidth = pageWidth - 2 * margin; // Subtract margins from both sides

  // Calculate the zoom level (this is technically the scale ratio)
  const zoom = availableWidth / eleWidth;

  // Round to 2 decimal places because it looks better
  return Math.round(zoom * 100) / 100;
};

export const applyZoomStyles = (ele, zoom) => {
  /* eslint-disable no-param-reassign */
  ele.style.transformOrigin = "top left";
  ele.style.zoom = `${zoom}`;
};

export const printWithZoomByElementWidth = elementSelector => {
  // Determine the actual width of the element
  const ele = document.querySelector(elementSelector);

  // If the element is not found, print as is
  if (!ele) {
    window.print();
    return;
  }

  // Get the zoom level
  const zoom = calculateZoomLevel(ele.offsetWidth);

  applyZoomStyles(ele, zoom);

  window.print(); // Ship it!

  applyZoomStyles(ele, 1);
};
