import React from "react";
import styled from "styled-components";

const PetImg = styled.img`
  width: 57px;
  height: 57px;
`;

export default function PetImageComponent({ src }) {
  return <PetImg src={src} />;
}
