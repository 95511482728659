import { SET_HOTEL_ENGAGEMENT } from "dux/hotelEngagements/hotelEngagementActions";
import { CLEAR_HOTEL_ITINERARY } from "../hotelItinerary/hotelItineraryActions";

const initialState = {};

const hotelEngagementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTEL_ENGAGEMENT:
      return {
        ...state,
        engagements: action.engagements,
      };
    case CLEAR_HOTEL_ITINERARY:
      return initialState;
    default:
      return state;
  }
};

export default hotelEngagementsReducer;
