import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

// Components
import { PrismTable, PrismTableData } from "@/dux/_components/prismTable/PrismTable";
import { PrismTableHeaderRowWithSortAndFilter } from "@/dux/_components/prismTable/PrismTableHeaderRow";

// Actions
import { setSelectedSortInfo } from "@/dux/reports/reportsActions";

// Selectors
import {
  selectSelectedReportDataWithFilters,
  selectSelectedFilters,
} from "@/dux/reports/reportsSelectors";
import { selectSelectedReportType } from "@/dux/reportSelection/reportSelectionSelectors";

// Helpers
import {
  formatBooleansToValues,
  formatDateTimeValues,
  formatTableHeadersFromArray,
} from "@/dux/_components/prismTable/prismTableHelpers";

/**
 * Redux Connect function for the Reports Table
 * @summary Located on the /reports page
 * @memberOf Views.Associate
 * @function
 * @name ReportTable
 * @example <ReportTable />
 */
export const ReportTable = connect(
  state => {
    const reportData = selectSelectedReportDataWithFilters(state);
    const formattedTableHeaders = formatTableHeadersFromArray(reportData);
    const selectedFilters = selectSelectedFilters(state);
    const selectedReportType = selectSelectedReportType(state);

    const isFeedingOrMedReports =
      selectedReportType.toLowerCase().includes("feeding") ||
      selectedReportType.toLowerCase().includes("medications");

    const initialSortColumnIndex =
      isFeedingOrMedReports && reportData.length > 0
        ? Object.keys(reportData[0]).indexOf("roomNumber")
        : 0;

    return {
      componentId: "ReportsTable",
      isHidden: !reportData,
      initialSortColumnIndex,
      reportData: reportData,
      formattedTableHeaders,
      selectedFilters,
    };
  },
  dispatch => {
    return {
      onSortFn: ({ sortInfo }) => {
        dispatch(setSelectedSortInfo(sortInfo));
      },
    };
  },
  (
    {
      componentId,
      isHidden,
      initialSortColumnIndex,
      reportData,
      formattedTableHeaders,
      selectedFilters,
    },
    { onSortFn },
  ) => {
    return {
      componentId,
      isHidden,
      hasNoData: !reportData.length,
      tableHeaders: (
        <PrismTableHeaderRowWithSortAndFilter
          isHidden={!reportData.length}
          initialSortColumnIndex={initialSortColumnIndex}
          headers={formattedTableHeaders}
          onHandleSortFn={sortInfo => onSortFn({ sortInfo })}
          activeFilters={selectedFilters}
        />
      ),
      tableData: reportData.length && (
        <PrismTableData
          tableData={reportData}
          isHidden={!reportData.length}
          formatCellFn={compose(formatBooleansToValues, formatDateTimeValues)}
        />
      ),
    };
  },
)(PrismTable);
