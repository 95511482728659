/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_TRAINING_CLASS_ITINERARY = "GET_TRAINING_CLASS_ITINERARY";
export const GET_TRAINING_CLASS_ITINERARY_REQUEST = "GET_TRAINING_CLASS_ITINERARY_REQUEST";
export const GET_TRAINING_CLASS_ITINERARY_SUCCESS = "GET_TRAINING_CLASS_ITINERARY_SUCCESS";
export const GET_TRAINING_CLASS_ITINERARY_FAILURE = "GET_TRAINING_CLASS_ITINERARY_FAILURE";

export const POST_TRAINING_CLASS_ITINERARY = "POST_TRAINING_CLASS_ITINERARY";
export const POST_TRAINING_CLASS_ITINERARY_REQUEST = "POST_TRAINING_CLASS_ITINERARY_REQUEST";
export const POST_TRAINING_CLASS_ITINERARY_SUCCESS = "POST_TRAINING_CLASS_ITINERARY_SUCCESS";
export const POST_TRAINING_CLASS_ITINERARY_FAILURE = "POST_TRAINING_CLASS_ITINERARY_FAILURE";

export const PUT_TRAINING_CLASS_ITINERARY = "PUT_TRAINING_CLASS_ITINERARY";
export const PUT_TRAINING_CLASS_ITINERARY_REQUEST = "PUT_TRAINING_CLASS_ITINERARY_REQUEST";
export const PUT_TRAINING_CLASS_ITINERARY_SUCCESS = "PUT_TRAINING_CLASS_ITINERARY_SUCCESS";
export const PUT_TRAINING_CLASS_ITINERARY_FAILURE = "PUT_TRAINING_CLASS_ITINERARY_FAILURE";

export const PATCH_TRAINING_CLASS_ITINERARY = "PATCH_TRAINING_CLASS_ITINERARY";
export const PATCH_TRAINING_CLASS_ITINERARY_REQUEST = "PATCH_TRAINING_CLASS_ITINERARY_REQUEST";
export const PATCH_TRAINING_CLASS_ITINERARY_SUCCESS = "PATCH_TRAINING_CLASS_ITINERARY_SUCCESS";
export const PATCH_TRAINING_CLASS_ITINERARY_FAILURE = "PATCH_TRAINING_CLASS_ITINERARY_FAILURE";

export const PUT_TRAINING_CLASS_ITINERARY_STATUS = "PUT_TRAINING_CLASS_ITINERARY_STATUS";
export const PUT_TRAINING_CLASS_ITINERARY_STATUS_REQUEST =
  "PUT_TRAINING_CLASS_ITINERARY_STATUS_REQUEST";
export const PUT_TRAINING_CLASS_ITINERARY_STATUS_SUCCESS =
  "PUT_TRAINING_CLASS_ITINERARY_STATUS_SUCCESS";
export const PUT_TRAINING_CLASS_ITINERARY_STATUS_FAILURE =
  "PUT_TRAINING_CLASS_ITINERARY_STATUS_FAILURE";

export const FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING =
  "FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING";
export const FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING_REQUEST =
  "FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING_REQUEST";
export const FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING_SUCCESS =
  "FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING_SUCCESS";
export const FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING_FAILURE =
  "FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING_FAILURE";

export const MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE =
  "MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE";
export const MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE_REQUEST =
  "MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE_REQUEST";
export const MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE_SUCCESS =
  "MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE_SUCCESS";
export const MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE_FAILURE =
  "MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE_FAILURE";

export const CANCEL_TRAINING_CLASS_ITINERARY = "CANCEL_TRAINING_CLASS_ITINERARY";
export const CANCEL_TRAINING_CLASS_ITINERARY_REQUEST = "CANCEL_TRAINING_CLASS_ITINERARY_REQUEST";
export const CANCEL_TRAINING_CLASS_ITINERARY_SUCCESS = "CANCEL_TRAINING_CLASS_ITINERARY_SUCCESS";
export const CANCEL_TRAINING_CLASS_ITINERARY_FAILURE = "CANCEL_TRAINING_CLASS_ITINERARY_FAILURE";

export const DELETE_TRAINING_CLASS_ITINERARY = "DELETE_TRAINING_CLASS_ITINERARY";
export const DELETE_TRAINING_CLASS_ITINERARY_REQUEST = "DELETE_TRAINING_CLASS_ITINERARY_REQUEST";
export const DELETE_TRAINING_CLASS_ITINERARY_SUCCESS = "DELETE_TRAINING_CLASS_ITINERARY_SUCCESS";
export const DELETE_TRAINING_CLASS_ITINERARY_FAILURE = "DELETE_TRAINING_CLASS_ITINERARY_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

export const getTrainingClassItinerary = ({ customerKey, itineraryId, onComplete }) => ({
  type: GET_TRAINING_CLASS_ITINERARY,
  customerKey,
  itineraryId,
  onComplete,
});

export const getTrainingClassItineraryRequest = () => ({
  type: GET_TRAINING_CLASS_ITINERARY_REQUEST,
});

export const getTrainingClassItinerarySuccess = (payload) => ({
  type: GET_TRAINING_CLASS_ITINERARY_SUCCESS,
  payload,
});

export const getTrainingClassItineraryFailure = (error) => ({
  type: GET_TRAINING_CLASS_ITINERARY_FAILURE,
  error,
});

export const postTrainingClassItinerary = (payload) => ({
  type: POST_TRAINING_CLASS_ITINERARY,
  petServiceId: payload.petServiceId,
  customerKey: payload.customerKey,
  petId: payload.petId,
  classSessionId: payload.classSessionId,
  startDateTime: payload.startDateTime,
  associateId: payload.associateId,
  duration: payload.duration,
  durationUnitOfMeasure: payload.durationUnitOfMeasure,
  behaviors: payload.behaviors,
  notes: payload.notes,
  customerPhone: payload.customerPhone,
  customerPhoneType: payload.customerPhoneType,
  customerTimeZone: payload.customerTimeZone,
});

export const postTrainingClassItineraryRequest = () => ({
  type: POST_TRAINING_CLASS_ITINERARY_REQUEST,
});

export const postTrainingClassItinerarySuccess = (payload) => ({
  type: POST_TRAINING_CLASS_ITINERARY_SUCCESS,
  payload,
});

export const postTrainingClassItineraryFailure = (error) => ({
  type: POST_TRAINING_CLASS_ITINERARY_FAILURE,
  error,
});

export const putTrainingClassItinerary = (payload) => ({
  type: PUT_TRAINING_CLASS_ITINERARY,
  petServiceId: payload.petServiceId,
  customerKey: payload.customerKey,
  itineraryId: payload.itineraryId,
  petId: payload.petId,
  classSessionId: payload.classSessionId,
  startDateTime: payload.startDateTime,
  associateId: payload.associateId,
  duration: payload.duration,
  durationUnitOfMeasure: payload.durationUnitOfMeasure,
  behaviors: payload.behaviors,
  notes: payload.notes,
  customerPhone: payload.customerPhone,
  customerPhoneType: payload.customerPhoneType,
  customerTimeZone: payload.customerTimeZone,
  onComplete: payload.onComplete,
  isReschedule: payload.isReschedule,
});

export const putTrainingClassItineraryRequest = () => ({
  type: PUT_TRAINING_CLASS_ITINERARY_REQUEST,
});

export const putTrainingClassItinerarySuccess = (payload) => ({
  type: PUT_TRAINING_CLASS_ITINERARY_SUCCESS,
  payload,
});

export const putTrainingClassItineraryFailure = (error) => ({
  type: PUT_TRAINING_CLASS_ITINERARY_FAILURE,
  error,
});

export const patchTrainingClassItinerary = (payload) => ({
  type: PATCH_TRAINING_CLASS_ITINERARY,
  customerKey: payload.customerKey,
  itineraryId: payload.itineraryId,
  petId: payload.petId,
  classSessionId: payload.classSessionId,
  notes: payload.notes,
  onComplete: payload.onComplete,
});

export const patchTrainingClassItineraryRequest = () => ({
  type: PATCH_TRAINING_CLASS_ITINERARY_REQUEST,
});

export const patchTrainingClassItinerarySuccess = () => ({
  type: PATCH_TRAINING_CLASS_ITINERARY_SUCCESS,
});

export const patchTrainingClassItineraryFailure = (error) => ({
  type: PATCH_TRAINING_CLASS_ITINERARY_FAILURE,
  error,
});

export const putTrainingClassItineraryStatus = (payload) => ({
  type: PUT_TRAINING_CLASS_ITINERARY_STATUS,
  customerKey: payload.customerKey,
  itineraryId: payload.itineraryId,
  engagementId: payload.engagementId,
  status: payload.status,
  reason: payload.reason,
});

export const putTrainingClassItineraryStatusRequest = () => ({
  type: PUT_TRAINING_CLASS_ITINERARY_STATUS_REQUEST,
});

export const putTrainingClassItineraryStatusSuccess = (payload) => ({
  type: PUT_TRAINING_CLASS_ITINERARY_STATUS_SUCCESS,
  payload,
});

export const putTrainingClassItineraryStatusFailure = (error) => ({
  type: PUT_TRAINING_CLASS_ITINERARY_STATUS_FAILURE,
  error,
});

export const finalizeTrainingClassItineraryBooking = (payload) => ({
  type: FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING,
  customerKey: payload.customerKey,
  itineraryId: payload.itineraryId,
  engagementId: payload.engagementId,
  status: payload.status,
  reason: payload.reason,
  onComplete: payload.onComplete,
});

export const finalizeTrainingClassItineraryBookingRequest = () => ({
  type: FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING_REQUEST,
});

export const finalizeTrainingClassItineraryBookingSuccess = () => ({
  type: FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING_SUCCESS,
});

export const finalizeTrainingClassItineraryBookingFailure = (error) => ({
  type: FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING_FAILURE,
  error,
});

export const markTrainingClassItineraryAsComplete = (payload) => ({
  type: MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE,
  customerKey: payload.customerKey,
  itineraryId: payload.itineraryId,
  engagementId: payload.engagementId,
  status: payload.status,
  reason: payload.reason,
  onComplete: payload.onComplete,
});

export const markTrainingClassItineraryAsCompleteRequest = () => ({
  type: MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE_REQUEST,
});

export const markTrainingClassItineraryAsCompleteSuccess = () => ({
  type: MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE_SUCCESS,
});

export const markTrainingClassItineraryAsCompleteFailure = (error) => ({
  type: MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE_FAILURE,
  error,
});

export const cancelTrainingClassItinerary = (payload) => ({
  type: CANCEL_TRAINING_CLASS_ITINERARY,
  customerKey: payload.customerKey,
  itineraryId: payload.itineraryId,
  engagementId: payload.engagementId,
  status: payload.status,
  reason: payload.reason,
  onComplete: payload.onComplete,
});

export const cancelTrainingClassItineraryRequest = () => ({
  type: CANCEL_TRAINING_CLASS_ITINERARY_REQUEST,
});

export const cancelTrainingClassItinerarySuccess = () => ({
  type: CANCEL_TRAINING_CLASS_ITINERARY_SUCCESS,
});

export const cancelTrainingClassItineraryFailure = (error) => ({
  type: CANCEL_TRAINING_CLASS_ITINERARY_FAILURE,
  error,
});

export const deleteTrainingClassItinerary = (payload) => ({
  type: DELETE_TRAINING_CLASS_ITINERARY,
  customerKey: payload.customerKey,
  itineraryId: payload.itineraryId,
  onComplete: payload.onComplete,
});

export const deleteTrainingClassItineraryRequest = () => ({
  type: DELETE_TRAINING_CLASS_ITINERARY_REQUEST,
});

export const deleteTrainingClassItinerarySuccess = (payload) => ({
  type: DELETE_TRAINING_CLASS_ITINERARY_SUCCESS,
  payload,
});

export const deleteTrainingClassItineraryFailure = (error) => ({
  type: DELETE_TRAINING_CLASS_ITINERARY_FAILURE,
  error,
});
