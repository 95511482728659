import momentTz from "moment-timezone";
import { APPOINTMENT_STATUS } from "@/core/constants";

export const buildCheckInOutMessage = (
  { status, checkInDateTime, checkOutDateTime },
  storeTimeZone,
) => {
  const isCheckedInStatus = status === APPOINTMENT_STATUS.CHECKED_IN;
  const { statusText, date } = isCheckedInStatus
    ? { statusText: "Out", date: checkOutDateTime }
    : { statusText: "In", date: checkInDateTime };
  const formattedDate = momentTz.tz(date, storeTimeZone).format("llll");

  return `Check ${statusText}: ${formattedDate}`;
};

export const getCardFields = (appointment, storeTimeZone) => {
  const iconInfo = {
    isNewCustomer: appointment?.isNewCustomer,
    hasPSA: appointment?.hasPSA,
    receiveMedications: appointment?.receiveMedications,
    isOnlineBooking: appointment?.isOnlineBooking,
    isSenior: appointment?.isSenior,
    isPuppy: appointment?.isPuppy,
    invoicePaid: appointment?.invoicePaid,
    isVIP: appointment?.isVIP,
    isIndividual: appointment?.isIndividual,
    hasNoBed: appointment?.hasNoBed,
    hasSalonAppointment: appointment?.hasSalonAppointment,
    hasStatusAlert: appointment?.hasStatusAlert,
    hasHealthCondition: appointment?.hasHealthCondition,
    isMissingVaccinations: appointment?.isMissingVaccinations,
    speciesId: appointment?.petSpeciesId,
    isGroupReservation: appointment?.isGroupReservation,
    needsReview: appointment?.needsReview,
  };

  const cardInfo = {
    petName: `${appointment?.petName} ${appointment?.customerLastName}`,
    petAge: appointment?.petAge,
    petBreed: appointment?.petBreed || appointment?.petBreedDescription,
    petWeight: appointment?.petWeight,
    petService: appointment?.petService,
    apptStatus: appointment?.status,
    roomNumber: appointment?.roomNumber || "Unassigned",
    checkInOutDateTime: buildCheckInOutMessage(appointment, storeTimeZone),
    pet: { speciesId: appointment?.petSpeciesId },
  };

  return {
    cardInfo,
    iconInfo,
  };
};
