import { connect } from "react-redux";
import HotelCapacityRowContainer from "./HotelCapacityRowContainer";
import { getHotelRoomCapacity } from "./hotelRoomCapacitySelector";
import CapacityTable from "../_components/roomCapacity/CapacityTable";
import HotelCapacityTableHeaderContainer from "./HotelCapacityTableHeaderContainer";
import { showHideHotelCapacityModal } from "dux/hotelRoomCapacityModal/hotelRoomCapacityModalActions";

const mapStateToProps = state => ({
  isHidden: false,
  componentID: "CapacityTable__Hotel",
  capacity: getHotelRoomCapacity(state),
  diComp: {
    header: {
      hotel: HotelCapacityTableHeaderContainer,
    },
    row: {
      hotel: HotelCapacityRowContainer,
    },
  },
  compName: "hotel",
});

const mapDispatchToProps = dispatch => ({
  dispatchShowModal: ({}) => {
    dispatch(showHideHotelCapacityModal({ isHidden: false }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onShowModal: () => {
    propsFromDispatch.dispatchShowModal({});
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CapacityTable);
