import { connect } from "react-redux";
import getIsBGMWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getFTCOWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import AppointmentsListBGMComponent from "./AppointmentsListBGMComponent";

const mapStateToProps = state => {
  const isBGMFeatureFlagHidden = getIsBGMWorkflowFeatureFlagHidden(state);
  const isFTCOFeatureFlagHidden = getFTCOWorkflowFeatureFlagHidden(state);
  return {
    isHidden: isBGMFeatureFlagHidden && isFTCOFeatureFlagHidden,
    componentID: "AppointmentsList",
  };
};

export default connect(mapStateToProps)(AppointmentsListBGMComponent);
