/**
 * A Simple Selector that returns a single Date from the redux store.
 * @param {*} state
 * @return {string} Selected Date e.g. "2018-10-17"
 */
export const getSelectedTime = state => state.ui.web.common.selectedTime;
export const getWeeklyGanttDate = state => state.ui.web.common.weeklyGanttDate;

/**
 * A Simple Selector that returns the week number from the redux store.
 * @param {*} state
 * @return {Number}
 */
export const getWeekNumber = state => state.ui.web.common.weekNumber;

export const getProps = (state, props = {}) => props;

/**
 * A Simple Selector that returns a appointment ID from state.
 * @param {*} state
 * @return {string} appointmentID
 */
export const getSelectedAppointment = state => state.ui.web.common.selectedAppointment;
export const getSelectedAssociate = state => state.ui.web.common.selectedAssociate;
export const getSelectedAssociateWeekly = state => state.ui.web.common.selectedAssociateWeekly;

export const getAssociateId = state => state.ui.web.common.associateId;
export const getRebookErrorMessages = state => state.ui.web.common.rebookErrorMessages;

export const getState = state => state;
