import { createSelector } from "reselect";

import { getStoreNumber } from "core/selectors/persistentSelectors";

export const selectNearByStores = state => state.listOfNearByStores.nearByStores;
export const selectCacheStoreNumber = state => state.listOfNearByStores.cacheStoreNumber;

export const selectIsStoreNumberCached = createSelector(
  [getStoreNumber, selectCacheStoreNumber],
  (storeNumber, cacheStoreNumber) => {
    return storeNumber === cacheStoreNumber;

  },
);
