import { combineReducers } from "redux";
import newMedicationReducer from "../../newMedication/newMedicationReducer";
import medicationsReducer from "./medicationsReducer";

const medicationReducer = combineReducers({
  newMedication: newMedicationReducer,
  medications: medicationsReducer,
});

export default medicationReducer;
