import React from "react";
import CommonModal from "../../../web/common/modals/commonModal";
import { PartialDayRelocation } from "./content/PartialDayRelocationComponent";
import { modalTypes } from "../../../core/constants/associateSchedulingConstants";
import {
  Notification,
  TemplateDateMustBeChanged,
  UnsaveChanges,
} from "./content/AssociateSchedulingModal";
import { modalTypes as commonModalTypes } from "../../../core/constants/modalConstants";
import RuleViolation from "../../common/modals/content/ruleViolation/ruleViolationContainer";
import { RELOCATION_ON_SPLIT_SHIFT } from "@/core/constants/schedulesConstants";

const AssociateSchedulingModals = props => (
  <CommonModal
    onClose={() => {
      props.onClose();
    }}
  >
    {selectModalContent(props)}
  </CommonModal>
);

const selectModalContent = props => {
  switch (props.modalType) {
    case modalTypes.PARTIAL_DAY_RELOCATION:
      return <PartialDayRelocation />;

    case modalTypes.UNSAVE_CHANGES_MODAL_DATE_CHANGE:
      return (
        <UnsaveChanges
          onContinue={() => {
            props.setStartWeekDate();
            props.onClose();
          }}
          {...props}
        />
      );

    case modalTypes.UNSAVE_CHANGES_MODAL_GO_TO_TEMPLATE:
      return (
        <UnsaveChanges
          onContinue={() => {
            props.goToAssociateTemplate();
            props.onClose();
          }}
          {...props}
        />
      );

    case modalTypes.UNSAVE_CHANGES_MODAL_GO_TO_TEMPLATE_WEEK:
      return (
        <UnsaveChanges
          onContinue={() => {
            props.setTemplateWeeklyEdit();
            props.onClose();
          }}
          {...props}
        />
      );

    case modalTypes.UNSAVE_CHANGES_MODAL_ASSOCIATE_CHANGE:
      return (
        <UnsaveChanges
          onContinue={() => {
            props.setAssociateId();
            props.onClose();
          }}
          {...props}
        />
      );

    case modalTypes.UNSAVE_CHANGES_MODAL_URL_CHANGE:
      return <UnsaveChanges {...props} />;

    case modalTypes.TEMPLATE_DATE_MUST_BE_CHANGED:
      return <TemplateDateMustBeChanged />;

    case modalTypes.NOTIFICATION:
      return <Notification {...props} />;

    case modalTypes.RELOCATION_ON_SPLIT_SHIFT_NOTIFICATION:
      return <Notification {...props} notificationMessage={RELOCATION_ON_SPLIT_SHIFT} />;

    case commonModalTypes.RULE_VIOLATION:
      return <RuleViolation onClose={props.onClose} />;

    default:
      return null;
  }
};

export default AssociateSchedulingModals;
