import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Add an engagement to an itinerary.
 * For System Services Booking API
 * - Add engagement
 * @param {*} { customerKey, itineraryId, data }
 * @returns {*} AxiosPromise
 */
export function postEngagement({ customerKey, itineraryId, data }) {
  return bookingAPI.post(
    `${endpointVersionString()}/customers/${customerKey}/itineraries/${itineraryId}/engagements`,
    data
  );
}

/**
 * Delete the engagement and all contained pet services
 * For System Services Booking API
 * - Delete an engagement
 * @param {*} { customerKey, itineraryId, engagementId }
 * @returns {*} AxiosPromise
 */
export function deleteEngagement({ customerKey, itineraryId, engagementId }) {
  return bookingAPI.delete(
    `${endpointVersionString()}/customers/${customerKey}/itineraries/${itineraryId}/engagements/${engagementId}`
  );
}

/**
 * Update the status of all pet services in an engagement
 * For System Services Booking API
 * - Update engagement status
 * @param {*} { customerId, itineraryId, engagementId, data }
 * @returns {*} AxiosPromise
 */
export function putEngagementStatus({ customerId, itineraryId, engagementId, data }) {
  return bookingAPI.put(
    `${endpointVersionString()}/customers/${customerId}/itineraries/${itineraryId}/engagements/${engagementId}/status`,
    data
  );
}

/**
 * Undo the last status update performed on all pet services in this engagement.
 * For System Services Booking API
 * - Undo the last engagement status update
 * @param {*} { customerId, itineraryId, engagementId }
 * @returns AxiosPromise
 */
export function deleteLastEngagementStatusUpdate({ customerId, itineraryId, engagementId }) {
  return bookingAPI.delete(
    `${endpointVersionString()}/customers/${customerId}/itineraries/${itineraryId}/engagements/${engagementId}/status`
  );
}
