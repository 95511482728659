import { connect } from "react-redux";
import { color } from "web/common/styles/theme";
import ClickableText from "web/common/ClickableText";

const mapStateToProps = () => ({
  isVisible: true,
  componentID: "manual-apply-bundle__ClickableText",
  label: "Apply",
  styleObj: {
    color: color.kalEl,
  },
});

const mapDispatchToProps = () => ({
  onClick: () => {
    // TO DO: Implement logic when manual apply is available
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClickableText);
