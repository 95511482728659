import moment from "moment";

/** @module calculateAge */

/**
 * Attempts to get the difference between the current date and the birthDate
 * and returns that difference as a formatted string in Years, months and days.
 * If an age is less than 1 year, the year is omitted.
 * e.g. '1Y 2M 10d'
 *
 * @export calculateAge
 * @param {string} birthDate - birth date as a string, e.g. '1990-01-13'
 * @returns {string} - Formatted difference in years, months, and days.
 */
export default function calculateAge(birthDate) {
  const birthDateObj = moment(birthDate);
  const currentDate = moment();

  const yearDiff = currentDate.diff(birthDateObj, "years");
  const monthDiff = currentDate.diff(birthDateObj.add(yearDiff, "years"), "months");
  const dayDiff = currentDate.diff(birthDateObj.add(monthDiff, "months"), "days");

  if (yearDiff < 1) {
    return `${monthDiff}M ${dayDiff}d`;
  }
  return `${yearDiff}Y ${monthDiff}M ${dayDiff}d`;
}
