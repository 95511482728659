import moment from "moment";

/** @module usDate */

/**
 * Formats a moments date object to a string
 *
 * @export getUSDateString
 * @param {*} date - Moments date object or a string representing the date
 * @returns {string} formatted date string
 * @example
 * - getUSDateString(appointment.startDateTime)
 */
export default function getUSDateString(date) {
  const formatDate = moment(date).format("MM/DD/YYYY");
  return formatDate;
}
