import {
  SET_SELECTED_ADDON,
  SET_SELECTED_BULK_PACKAGE_ADDONS,
  CLEAR_SELECTED_BULK_PACKAGE_ADDON,
} from "./bulkPackageAddOnsActions";

const initialState = { selectedAddOn: {} };

const bulkPackageAddOnsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ADDON: {
      // remove the item from state when the value is false, to avoid a stale state
      // and needing a cleanup action: REMOVE_SELECTED_ADDON
      const value = !state.selectedAddOn[action.addOnId];
      const selectedAddOn = value ? { [action.addOnId]: value } : {};
      return { ...state, selectedAddOn };
    }
    case SET_SELECTED_BULK_PACKAGE_ADDONS:
      // petServiceId + bundleConfigId is a composite key because multiple pets can
      // have the same service
      return {
        ...state,
        [`${action.petId}-${action.productBundleConfigId}`]: action.addOns,
      };
    case CLEAR_SELECTED_BULK_PACKAGE_ADDON:
      return {
        ...state,
        selectedAddOn: {},
      };
    default:
      return state;
  }
};

export default bulkPackageAddOnsReducer;
