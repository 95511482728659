import React from "react";
import styled from "styled-components";
import CommonLoadingSpinner from "./CommonLoadingSpinner";

const LoadingWrapper = ({
  children,
  isLoading,
  fullScreenHeight = false,
  fullScreenWidth = false,
  display = "flex",
  displayContainer = "flex",
  background = true,
  showSpinner = true,
}) => (
  <Container display={displayContainer}>
    {isLoading && (
      <Loader
        fullScreenHeight={fullScreenHeight}
        fullScreenWidth={fullScreenWidth}
        background={background}
        display={display}
      >
        <CommonLoadingSpinner showSpinner={showSpinner} />
      </Loader>
    )}
    {children}
  </Container>
);

const Container = styled.div`
  position: relative;
  display: ${({ display }) => display || "flex"};
  flex: 1;
`;

const Loader = styled.div`
  width: ${({ fullScreenWidth }) => (fullScreenWidth ? "100vw" : "100%")};
  height: ${({ fullScreenHeight }) => (fullScreenHeight ? "100vh" : "100%")};
  position: ${({ fullScreenHeight, fullScreenWidth }) =>
    fullScreenHeight && fullScreenWidth ? "fixed" : "absolute"};
  z-index: 100;
  background-color: ${({ background }) => (background ? "rgba(0, 0, 0, 0.1)" : "transparent")};
  display: ${({ display }) => display || "flex"};
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

export default LoadingWrapper;
