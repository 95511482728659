/**
 * Responsible for taking a URL path and returning the 1st section of that path
 *
 * @exports getPageFromURLPath
 * @param {string} urlPath
 * @returns {string}
 */
export default function getPageFromURLPath(urlPath) {
  return urlPath.match(/[^\/]+/)[0];
}
