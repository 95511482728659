import React from "react";
import styled from "styled-components";
import BulkPackageOfferingsAccordionHeaderContainer from "./BulkPackageOfferingsAccordionHeaderContainer";
import BulkPackageOfferingsContainer from "../../../bgm/bulkPackageOfferings/offerings/BulkPackageOfferingsContainer";
import BulkPackageOfferingsNoneAvailableMessageContainer from "@/dux/bgm/bulkPackageOfferings/offerings/BulkPackageOfferingsNoneAvailableMessageContainer";

const Container = styled.div`
  margin-bottom: 2em;
`;

const BulkPackageOfferingsAccordionComponent = ({ isHidden }) => {
  if (!isHidden) {
    return (
      <Container>
        <BulkPackageOfferingsAccordionHeaderContainer>
          <BulkPackageOfferingsContainer />
          <BulkPackageOfferingsNoneAvailableMessageContainer />
        </BulkPackageOfferingsAccordionHeaderContainer>
      </Container>
    );
  }

  return null;
};

export default BulkPackageOfferingsAccordionComponent;
