/**
 * Responsible for taking an Action Type and returning a RegExpExecArray
 * if that action type has any of the following sub-strings within the action type
 * string.
 * - REQUEST
 * - FAILURE
 * - CLEAR_ERRORS
 *
 * @export getLoadingMatches
 * @param {string} actionType
 * @returns { RegExpExecArray }
 */

export default function getErrorMatches(actionType) {
  return /(.*)_(REQUEST|FAILURE|CLEAR_ERRORS)/.exec(actionType);
}
