import moment from "moment";

/**
 * Responsible for returning a formatted Day of the week based on the ISO Weekday number passed in.
 *
 * @export getISOWeekDayName
 * @param {Number} weekDayNumber - Number that represents the ISO days of the week, e.g. 1 for Monday, 7 for Sunday
 * @returns Day of the week
 */
export default function getISOWeekDayName(weekDayNumber) {
  return moment()
    .isoWeekday(weekDayNumber)
    .format("dddd");
}
