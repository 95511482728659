import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { Button } from "@prism/psm-ui-components";
import { setFieldForNewProfile } from "@/core/actionCreators/petParentProfileActionCreator";
import { setCurrentCustomerKey } from "@/core/actionCreators/customer/customerActionCreator";
import { routePaths } from "@/core/constants/routePaths";
import {
  getSearchFieldType,
  getSearchFieldValue,
  getSearchResultsToDisplay,
} from "@/core/selectors/searchSalonSRCSelector";
import { searchConstants } from "@/core/constants/searchConstants";

const mapStateToProps = state => {
  return {
    isHidden: getSearchResultsToDisplay(state) !== searchConstants.CUSTOMER,
    children: "Create new pet parent",
    variant: "prism-primary",
    searchFieldType: getSearchFieldType(state),
    searchFieldValue: getSearchFieldValue(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetFieldForNewProfile: ({ searchFieldType, searchFieldValue }) => {
      dispatch(setFieldForNewProfile({ searchFieldType, searchFieldValue }));
    },
    dispatchSetCurrentCustomerKey: customerKey => {
      dispatch(setCurrentCustomerKey(customerKey));
    },
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () => {
    const { searchFieldType, searchFieldValue } = propsFromState;
    const { dispatchSetFieldForNewProfile, dispatchSetCurrentCustomerKey } = propsFromDispatch;
    const {
      router: { navigate },
    } = ownProps;

    dispatchSetFieldForNewProfile({ searchFieldType, searchFieldValue });
    dispatchSetCurrentCustomerKey("");
    navigate(routePaths.PET_PARENT_PROFILE);
  },
});

export default withRouteProps(connect(mapStateToProps, mapDispatchToProps, mergeProps)(Button));
