import { get, unset } from "lodash/fp";
import pick from "lodash/pick";
import { v4 as uuidv4 } from "uuid";
import { history } from "@/dux/utils/browser/browserHistory";
import { newMedicationActionTypes } from "../../newMedication/actions/newMedicationActions";
import { setMedicationNameActionTypes } from "../../medicationName/actions/setMedicationNameActions";
import { setMedicationTypeAmountActionTypes } from "../../medicationTypeAmount/actions/medicationTypeAmountActions";
import { setMedicationTimeOfDayActionTypes } from "../../medicationTimeOfDay/actions/medicationTimeOfDayActions";
import { setMedicationInstructionsActionTypes } from "../../medicationInstructions/actions/medicationInstructionsActions";
import { medicationsActionTypes } from "../actions/medicationsActions";
import { medicationListActionTypes } from "../../medicationList/actions/medicationListActions";
import { NEW_MEDICATION_ID } from "../../newMedication/newMedicationConstants";
import { addMedicationBookingActionTypes } from "../../../dux/bookingMedication/addMedBooking/actions/addNewMedicationBookingActions";
import { medicationSaveToProfileActionTypes } from "../../../dux/bookingMedication/bookingSaveMedicationToProfile/medicationSaveToProfileActions";
import { isFromBooking } from "../../../core/utils/validationUtils/isFromValidation";
import { getMedicationSaveToProfileById } from "../../../dux/bookingMedication/bookingSaveMedicationToProfile/medicationSaveToProfileSelectors";
import { medicationFrequencyActionTypes } from "../../../dux/bookingPage/setMedFrequency/medicationFrequencyActions";
import { getCurrentPet } from "../../../core/selectors/persistentSelectors";

const initialState = {};

const medicationsReducer = (state = initialState, action) => {
  const petId = action.petId || getCurrentPet(state); // Either pass a specific petId to update or use the current pet.
  // Sets Save to Profile in the booking flow to unchecked if a pet's info is updated.
  let saveToProfile = getMedicationSaveToProfileById(state, {
    medicationId: action.medicationId,
    petId,
  });
  const updateMedOptions = [
    setMedicationNameActionTypes.SET_MEDICATION_NAME,
    setMedicationTypeAmountActionTypes.SET_MEDICATION_TYPE_AMOUNT,
    setMedicationTimeOfDayActionTypes.SET_MEDICATION_TIME_OF_DAY,
    setMedicationInstructionsActionTypes.SET_MEDICATION_INSTRUCTIONS,
  ];
  if (
    isFromBooking(get(["location", "pathname"], history)) &&
    updateMedOptions.find(option => option === action.type)
  ) {
    saveToProfile = false;
  }

  switch (action.type) {
    case medicationsActionTypes.SET_MEDICATIONS:
      return { ...state, [petId]: { ...action.medications } };
    case newMedicationActionTypes.SET_NEW_MEDICATION:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          NEW_MEDICATION: {
            groupingId: uuidv4(),
            petMedicationId: NEW_MEDICATION_ID,
            saveToProfile: false,
          },
        },
      };
    case addMedicationBookingActionTypes.ADD_NEW_BOOKING_MEDICATION:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...state[petId].NEW_MEDICATION,
            locationTypes: [],
            petMedicationId: action.medicationId,
          },
        },
      };
    case medicationsActionTypes.CLEAR_MEDICATIONS:
      return initialState;
    case newMedicationActionTypes.CLEAR_NEW_MEDICATION:
      return unset(`${petId}.${NEW_MEDICATION_ID}`, state);
    case setMedicationNameActionTypes.SET_MEDICATION_NAME:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...state[petId][action.medicationId],
            name: action.name,
            externalId: action.externalId,
          },
        },
      };
    case setMedicationTypeAmountActionTypes.SET_MEDICATION_TYPE_AMOUNT:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...state[petId][action.medicationId],
            amount: action.medicationTypeAmount,
            saveToProfile,
          },
        },
      };
    case setMedicationTimeOfDayActionTypes.SET_MEDICATION_TIME_OF_DAY:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...state[petId][action.medicationId],
            timeOfDay: action.timeOfDay,
            saveToProfile,
          },
        },
      };
    case setMedicationInstructionsActionTypes.SET_MEDICATION_INSTRUCTIONS:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...state[petId][action.medicationId],
            specialInstructions: action.medicationInstructions,
            saveToProfile,
          },
        },
      };
    case medicationListActionTypes.SHOW_HIDE_MEDICATION_DETAILS:
      return {
        ...state,
        [petId]: {
          ...state?.[petId],
          [action?.medicationId]: {
            ...state?.[petId]?.[action?.medicationId],
            locationTypes: action?.locationTypes,
          },
        },
      };
    case setMedicationTimeOfDayActionTypes.MED_TIME_OF_DAY_TOUCHED:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...state[petId][action.medicationId],
            timeOfDayTouched: action.timeOfDayTouched,
          },
        },
      };
    case medicationFrequencyActionTypes.MED_FREQUENCY_TOUCHED:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...state[petId][action.medicationId],
            frequencyTouched: true,
          },
        },
      };
    case medicationSaveToProfileActionTypes.SET_MEDICATION_SAVE_TO_PROFILE:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...state[petId][action.medicationId],
            saveToProfile: action.saveToProfile,
          },
        },
      };
    case medicationFrequencyActionTypes.SET_MEDICATION_FREQUENCY:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...state[petId][action.medicationId],
            frequency: action.frequency,
          },
        },
      };
    case medicationFrequencyActionTypes.SET_MEDICATION_FREQUENCY_DATES:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...state[petId][action.medicationId],
            concreteSchedule: action.dates,
          },
        },
      };
    case medicationFrequencyActionTypes.CLEAR_MEDICATION_FREQUENCY:
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...state[petId][action.medicationId],
            frequency: null,
            concreteSchedule: null,
          },
        },
      };
    case medicationListActionTypes.REMOVE_PET_MED_FROM_BOOKING:
      return unset(`${petId}.${action.medicationId}`, state);
    case medicationsActionTypes.PRESERVE_MEDICATIONS: {
      const med = state[petId][action.medicationId];
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...med,
            preserved: pick(med, [
              "externalId",
              "name",
              "amount",
              "timeOfDay",
              "specialInstructions",
              "frequency",
              "concreteSchedule",
              "saveToProfile",
            ]),
          },
        },
      };
    }
    case medicationsActionTypes.APPLY_PRESERVED_MEDICATIONS: {
      const { preserved, ...med } = state[petId][action.medicationId];
      return {
        ...state,
        [petId]: {
          ...state[petId],
          [action.medicationId]: {
            ...med,
            ...preserved,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default medicationsReducer;
