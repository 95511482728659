/**
 * Responsible for returning the jobRole Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getJobRolesState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the jobRoles object in the redux store
 *
 * @example createSelector([getJobRolesState], jobRolesState => jobRolesState.isVisible;
 */
export default function getJobRolesState(state) {
  return state.persistent.testJobRoles;
}
