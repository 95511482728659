import { connect } from "react-redux";
import { uniq } from "lodash/fp";
import { getAddonsByService } from "core/selectors/entitiesSelector";
import { putAddOns } from "core/actionCreators/addOnsActionCreator";
import { setAddons } from "core/actionCreators/ui/web/cartActionCreators";
import { createLoadingSelector } from "@/core/selectors/utils";
import addOnsActionTypes from "core/actionTypes/addOnsActionTypes";
import {
  getBundleAddOnIdsByPetServiceId,
  getIsActiveBundleAvailable,
} from "dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { getCurrentPetServiceId, getSelectedEnhancedServices } from "core/selectors/cartSelectors";
import unwrapAddonObjectsToIds from "dux/utils/addOns/unwrapAddonObjectsToIds";
import wrapAddonIdsToObjects from "dux/utils/addOns/wrapAddonIdsToObjects";
import CommonSplitPriceList from "../common/modalInsets/selectablePriceLists/commonSplitPriceList";

const mapStateToProps = (state, ownProps) => {
  const petId = getSelectedPet(state);
  const petServiceId = getCurrentPetServiceId(state, { petId });
  const addOns = getAddonsByService(state, ownProps);
  const bundleAddOns = getBundleAddOnIdsByPetServiceId(state, { petId, petServiceId });
  const isActiveBundle = getIsActiveBundleAvailable(state, { petId, petServiceId });
  const selectedAddOns = ownProps.selectedAddons.concat(isActiveBundle ? bundleAddOns : []);
  const selectedEnhancedServices = getSelectedEnhancedServices(state, { petId });

  return {
    initialSelectedOptions: unwrapAddonObjectsToIds(selectedAddOns),
    idProperty: "addOnId",
    nameProperty: "addOnName",
    mainHeading: ownProps.mainHeading || "Add-ons",
    topHeading: "Most Popular",
    bottomHeading: ownProps.bottomHeading || "All Add-ons",
    topList: addOns.mostPopular || [],
    bottomList: addOns.mainAddOns || [],
    disabledOptions: selectedAddOns || [],
    selectedEnhancedServices,
    isLoading: createLoadingSelector([addOnsActionTypes.PUT_ADDONS])(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleOnSelectionChange: addons => {
    const { petServiceItem, petId } = ownProps;

    if (!petServiceItem) {
      dispatch(setAddons({ addons: wrapAddonIdsToObjects(addons), petId }));
      return;
    }

    const { customer, engagement, itinerary, petServiceItemId } = petServiceItem;

    dispatch(
      putAddOns({
        customerId: customer,
        itineraryId: itinerary,
        engagementId: engagement,
        petServiceItemId,
        addons: wrapAddonIdsToObjects(addons),
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onDone: selectedOptions => {
    const { handleOnSelectionChange } = propsFromDispatch;
    const { selectedEnhancedServices } = propsFromState;

    if (selectedEnhancedServices?.addOnId) {
      selectedOptions.push(selectedEnhancedServices.addOnId);
    }

    // Remove duplicate add-ons in list of selected options
    // This will prevent pre-selected add-ons included with a bundle
    // to be added twice
    handleOnSelectionChange(uniq(selectedOptions));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonSplitPriceList);
