import { get } from "lodash/fp";
import { createSelector } from "reselect";
import getDiscontinuedMedsWorkflowFeatureFlag from "./getDiscontinuedMedsWorkflowFeatureFlag";

const selectIsDiscontinuedMedsFeatureHidden = createSelector(
  [getDiscontinuedMedsWorkflowFeatureFlag],
  featureFlag => get("isHidden", featureFlag),
);

export default selectIsDiscontinuedMedsFeatureHidden;
