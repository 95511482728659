import React from "react";
import styled from "styled-components";

import CommonButton from "../../../common/commonButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 1em;
`;

const Heading = styled.h3`
  margin-bottom: 1em;
`;

const Text = styled.div`
  margin-bottom: 3em;
  max-width: 350px;
  font-size: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 0 0 1em 0;
`;

const AuthorizationRequiredModal = ({ setRedirectToForgotPin, logoutUser, hideModal }) => (
  <Container>
    <Heading>Authorization required</Heading>
    <Text>
      A full authorization is required to change your pin. Click the button below to login to your
      account.
    </Text>
    <ButtonContainer>
      <CommonButton
        width="200px"
        label="Login"
        onClick={() => {
          setRedirectToForgotPin();
          logoutUser({ browserSessionOnly: true });
          hideModal();
        }}
      />
    </ButtonContainer>
  </Container>
);

export default AuthorizationRequiredModal;
