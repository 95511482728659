/**
 * Util to determine if confirm all button should be hidden on hotel check in out pages
 * @param {Oject} obj
 * @param {Number[]} obj.bookedOrConfirmedPets - array of petIds for pets who are confirmed or booked
 * @param {Number[]} obj.petIds - array of all petIds
 * @param {Number} obj.currentPetId
 * @returns boolean - true if button should be hidden
 */
export const isConfirmAllHidden = ({ bookedOrConfirmedPets = [], petIds = [], currentPetId }) => {
  const currentPetIsBookedOrConfirmed = bookedOrConfirmedPets?.some(
    petId => petId === currentPetId,
  );
  const allPetsPastConfirmed = bookedOrConfirmedPets?.length === 0;
  return allPetsPastConfirmed || petIds?.length <= 1 || !currentPetIsBookedOrConfirmed;
};
