import { omit } from "lodash/fp";
import {
  SET_RESERVATION_CART_SAVE_BUTTON_DISABLED,
  SHOW_RESERVATION_CART_DETAILS_CONFIRMATION,
  SET_PENDING_FOOD,
  CLEAR_PENDING_FOOD,
  CLEAR_PENDING_FOOD_BY_ID,
  SET_PENDING_MED,
  CLEAR_PENDING_MED_BY_ID,
  CLEAR_PENDING_MED,
  PATCH_RESERVATION_CART_DETAILS_SUCCESS,
  SET_PENDING_PRICE,
  CLEAR_PENDING_PRICE,
} from "./reservationCartDetailsConfirmationActions";

const initState = {
  isHidden: true,
};

const reservationCartDetailsConfirmationReducer = (state = initState, action) => {
  switch (action.type) {
    case SHOW_RESERVATION_CART_DETAILS_CONFIRMATION:
      return {
        ...state,
        isHidden: action.isHidden,
      };

    case SET_RESERVATION_CART_SAVE_BUTTON_DISABLED:
      return {
        ...state,
        isSaveDisabled: action.isSaveDisabled,
      };

    case PATCH_RESERVATION_CART_DETAILS_SUCCESS:
      // Clear pending cart on patch
      return {
        ...initState,
      };

    case SET_PENDING_FOOD:
      return {
        ...state,
        pendingFoods: {
          ...state.pendingFoods,
          [action.foodId]: {
            ...action.foodData,
          },
        },
      };

    case SET_PENDING_PRICE:
      return {
        ...state,
        pendingPrice: {
          price: action.payload?.price,
          reason: action.payload?.reason,
          engagementId: action.engagementId,
        },
      };

    case CLEAR_PENDING_PRICE:
      return {
        ...state,
        pendingPrice: null,
      };

    case CLEAR_PENDING_FOOD_BY_ID:
      return {
        ...state,
        pendingFoods: {
          ...omit([action.foodId], state.pendingFoods),
        },
      };

    case CLEAR_PENDING_FOOD:
      return {
        ...state,
        pendingFoods: null,
      };

    case SET_PENDING_MED:
      return {
        ...state,
        pendingMeds: {
          ...state.pendingMeds,
          [action.medId]: {
            ...action.medData,
          },
        },
      };

    case CLEAR_PENDING_MED_BY_ID:
      return {
        ...state,
        pendingMeds: {
          ...omit([action.medId], state.pendingMeds),
        },
      };

    case CLEAR_PENDING_MED:
      return {
        ...state,
        pendingMeds: null,
      };

    default:
      return state;
  }
};

export default reservationCartDetailsConfirmationReducer;
