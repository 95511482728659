import { petsmartWebPublicAPI } from "../../core/services/petsmartWebPublic/_axiosConfigPetMWebPubic";

/**
 * PetsMart/enums API - Fetch store hours from the PetSmart Web Public API
 * See https://api.petsmart.com/swagger/ui/index for more info
 * @param {Object} storeNumber and Dates
 */
export const fetchHotelHours = ({ storeNumber, beginDate, endDate }) => {
  return petsmartWebPublicAPI.get(
    `stores/${storeNumber}/hours?model.beginDate=${beginDate}&model.endDate=${endDate}`,
  );
};
