/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_ITINERARY_HISTORY_LIST = "GET_ITINERARY_HISTORY_LIST";
export const GET_ITINERARY_HISTORY_LIST_REQUEST = "GET_ITINERARY_HISTORY_LIST_REQUEST";
export const GET_ITINERARY_HISTORY_LIST_SUCCESS = "GET_ITINERARY_HISTORY_LIST_SUCCESS";
export const GET_ITINERARY_HISTORY_LIST_FAILURE = "GET_ITINERARY_HISTORY_LIST_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getItineraryHistoryList = ({
  customerKey,
  storeServiceType,
  productFamilyID,
  page,
  from,
  to,
}) => ({
  type: GET_ITINERARY_HISTORY_LIST,
  customerKey,
  storeServiceType,
  productFamilyID,
  page,
  from,
  to,
});

export const getItineraryHistoryListRequest = payload => ({
  type: GET_ITINERARY_HISTORY_LIST_REQUEST,
  payload,
});

export const getItineraryHistoryListSuccess = payload => ({
  type: GET_ITINERARY_HISTORY_LIST_SUCCESS,
  payload,
});

export const getItineraryHistoryListFailure = error => ({
  type: GET_ITINERARY_HISTORY_LIST_FAILURE,
  error,
});
