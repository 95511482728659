/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */

export const hotelBookingActionTypes = {
  PATCH_HOTEL_BOOKING_STATUS_FOR_CART: "PATCH_HOTEL_BOOKING_STATUS_FOR_CART",
  PATCH_HOTEL_BOOKING_STATUS_FOR_CART_REQUEST: "PATCH_HOTEL_BOOKING_STATUS_FOR_CART_REQUEST",
  PATCH_HOTEL_BOOKING_STATUS_FOR_CART_SUCCESS: "PATCH_HOTEL_BOOKING_STATUS_FOR_CART_SUCCESS",
  PATCH_HOTEL_BOOKING_STATUS_FOR_CART_FAILURE: "PATCH_HOTEL_BOOKING_STATUS_FOR_CART_FAILURE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const patchHotelBookingStatusForCart = ({ servicesCartId }) => ({
  type: hotelBookingActionTypes.PATCH_HOTEL_BOOKING_STATUS_FOR_CART,
  servicesCartId,
});

export const patchHotelBookingStatusForCartRequest = () => ({
  type: hotelBookingActionTypes.PATCH_HOTEL_BOOKING_STATUS_FOR_CART_REQUEST,
});

export const patchHotelBookingStatusForCartSuccess = () => ({
  type: hotelBookingActionTypes.PATCH_HOTEL_BOOKING_STATUS_FOR_CART_SUCCESS,
});

export const patchHotelBookingStatusForCartFailure = error => ({
  type: hotelBookingActionTypes.PATCH_HOTEL_BOOKING_STATUS_FOR_CART_FAILURE,
  error,
});
