/**
 *  Helper to convert suspension type id's to labels
 *
 *  @memberOf helpers.hotel.scheduleServices
 *  @function
 *  @name convertSuspensionTypeIdToLabel
 *  @param {number} suspensionTypeId
 *  @param {object} reasons
 *  @returns {string}
 */
export const convertSuspensionTypeIdToLabel = (suspensionTypeId, reasons) => {
  if (!suspensionTypeId || !reasons) return "";

  return reasons[suspensionTypeId]?.reasonTypeName;
};
