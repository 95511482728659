/** ----------------------------------------------------------------------- **\
    CUSTOMER NOTES
\** ----------------------------------------------------------------------- * */

/** --- Salon Customer Notes --- * */
export const ADD_SALON_CUSTOMER_NOTE = "ADD_SALON_CUSTOMER_NOTE";
export const addSalonCustomerNote = ({ customerKey, content }) => ({
  type: ADD_SALON_CUSTOMER_NOTE,
  customerKey,
  content,
});

export const ADD_SALON_CUSTOMER_NOTE_REQUEST = "ADD_SALON_CUSTOMER_NOTE_REQUEST";
export const addSalonCustomerNotesRequest = () => ({
  type: ADD_SALON_CUSTOMER_NOTE_REQUEST,
});

export const ADD_SALON_CUSTOMER_NOTE_SUCCESS = "ADD_SALON_CUSTOMER_NOTE_SUCCESS";
export const addSalonCustomerNotesSuccess = ({ customer }) => ({
  type: ADD_SALON_CUSTOMER_NOTE_SUCCESS,
  payload: customer,
});

/**
 * Action to for a failure to add a note to the WS.
 * @param { String } error - error message
 * @returns { Object } type and customerKey
 */
export const ADD_SALON_CUSTOMER_NOTE_FAILURE = "ADD_SALON_CUSTOMER_NOTE_FAILURE";
export const addSalonCustomerNotesFailure = ({ error }) => ({
  type: ADD_SALON_CUSTOMER_NOTE_FAILURE,
  error,
});

/** --- Hotel Customer Notes --- * */
export const ADD_HOTEL_CUSTOMER_NOTE = "ADD_HOTEL_CUSTOMER_NOTE";
export const addHotelCustomerNote = ({ content, customerKey }) => ({
  type: ADD_HOTEL_CUSTOMER_NOTE,
  content,
  customerKey,
});

export const ADD_HOTEL_CUSTOMER_NOTE_REQUEST = "ADD_HOTEL_CUSTOMER_NOTE_REQUEST";
export const addHotelCustomerNoteRequest = () => ({
  type: ADD_HOTEL_CUSTOMER_NOTE_REQUEST,
});

export const ADD_HOTEL_CUSTOMER_NOTE_SUCCESS = "ADD_HOTEL_CUSTOMER_NOTE_SUCCESS";
export const addHotelCustomerNoteSuccess = () => ({
  type: ADD_HOTEL_CUSTOMER_NOTE_SUCCESS,
});

export const ADD_HOTEL_CUSTOMER_NOTE_FAILURE = "ADD_HOTEL_CUSTOMER_NOTE_FAILURE";
export const addHotelCustomerNoteFailure = ({ error }) => ({
  type: ADD_HOTEL_CUSTOMER_NOTE_FAILURE,
  error,
});

/** ----------------------------------------------------------------------- **\
    SALON GROOMING NOTES
\** ----------------------------------------------------------------------- * */

export const ADD_GROOMING_NOTE = "ADD_GROOMING_NOTE";
export const addGroomingNote = ({ customerKey, petId, content }) => ({
  type: ADD_GROOMING_NOTE,
  customerKey,
  petId,
  content,
});

export const ADD_GROOMING_NOTE_REQUEST = "ADD_GROOMING_NOTE_REQUEST";
export const addGroomingNotesRequest = () => ({
  type: ADD_GROOMING_NOTE_REQUEST,
});

export const ADD_GROOMING_NOTE_SUCCESS = "ADD_GROOMING_NOTE_SUCCESS";
export const addGroomingNotesSuccess = ({ pets }) => ({
  type: ADD_GROOMING_NOTE_SUCCESS,
  payload: pets,
});

export const ADD_GROOMING_NOTE_FAILURE = "ADD_GROOMING_NOTE_FAILURE";
export const addGroomingNotesFailure = ({ error }) => ({
  type: ADD_GROOMING_NOTE_FAILURE,
  error,
});

/** ----------------------------------------------------------------------- **\
 HOTEL PET NOTES
 \** ----------------------------------------------------------------------- * */

export const ADD_HOTEL_PET_NOTE = "ADD_HOTEL_PET_NOTE";
export const addHotelPetNotes = ({ customerKey, petId, content }) => ({
  type: ADD_HOTEL_PET_NOTE,
  customerKey,
  petId,
  content,
});

export const ADD_HOTEL_PET_NOTE_REQUEST = "ADD_HOTEL_PET_NOTE_REQUEST";
export const addHotelPetNotesRequest = () => ({
  type: ADD_HOTEL_PET_NOTE_REQUEST,
});

export const ADD_HOTEL_PET_NOTE_SUCCESS = "ADD_HOTEL_PET_NOTE_SUCCESS";
export const addHotelPetNotesSuccess = ({ pets }) => ({
  type: ADD_HOTEL_PET_NOTE_SUCCESS,
  payload: pets,
});

export const ADD_HOTEL_PET_NOTE_FAILURE = "ADD_HOTEL_PET_NOTE_FAILURE";
export const addHotelPetNotesFailure = ({ error }) => ({
  type: ADD_HOTEL_PET_NOTE_FAILURE,
  error,
});

/** ----------------------------------------------------------------------- **\
    APPOINTMENT NOTES
\** ----------------------------------------------------------------------- * */
export const UPDATE_APPOINTMENT_NOTE = "UPDATE_APPOINTMENT_NOTE";
export const updateAppointmentNote = ({ data, customerId, itineraryId, petServiceItemId }) => ({
  type: UPDATE_APPOINTMENT_NOTE,
  data,
  customerId,
  itineraryId,
  petServiceItemId,
});

export const UPDATE_APPOINTMENT_NOTE_REQUEST = "UPDATE_APPOINTMENT_NOTE_REQUEST";
export const updateAppointmentNotesRequest = () => ({
  type: UPDATE_APPOINTMENT_NOTE_REQUEST,
});

export const UPDATE_APPOINTMENT_NOTE_SUCCESS = "UPDATE_APPOINTMENT_NOTE_SUCCESS";
export const updateAppointmentNotesSuccess = ({ itinerary }) => ({
  type: UPDATE_APPOINTMENT_NOTE_SUCCESS,
  payload: itinerary,
});

export const UPDATE_APPOINTMENT_NOTE_FAILURE = "UPDATE_APPOINTMENT_NOTE_FAILURE";
export const updateAppointmentNotesFailure = ({ error }) => ({
  type: UPDATE_APPOINTMENT_NOTE_FAILURE,
  error,
});
