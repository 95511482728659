import React from "react";
import styled from "styled-components";
import BulkPackageAddOnsCartDetailsContainer from "../../../bgm/bulkPackageOfferings/cartDetails/BulkPackageAddOnsCartDetailsContainer";
import BulkPackageOfferingsCartDetailsPackagePriceContainer from "../../../bgm/bulkPackageOfferings/cartDetails/BulkPackageOfferingsCartDetailsPackagePriceContainer";
import BulkPackageEnhancedServiceCartDetailsContainer from "../../../bgm/bulkPackageOfferings/cartDetails/BulkPackageEnhancedServicesCartDetailsContainer";

const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PricingHeading = styled.div`
  margin-bottom: 1.5rem;
`;

const PricingLineItems = styled.div`
  margin-left: 1em;
`;

const PricingLineItemWrapper = styled.div`
  ${({ marginBottom }) =>
    marginBottom &&
    `
    margin-bottom: 0.5em;
  `}
`;

const BulkPackageOfferingsCartDetailsPricingComponent = ({ isHidden, componentID }) => {
  if (!isHidden) {
    return (
      <PricingWrapper id={componentID}>
        <PricingHeading>Packages</PricingHeading>
        <PricingLineItems>
          <PricingLineItemWrapper marginBottom>
            <BulkPackageOfferingsCartDetailsPackagePriceContainer />
          </PricingLineItemWrapper>
          <PricingLineItemWrapper marginBottom>
            <BulkPackageEnhancedServiceCartDetailsContainer />
          </PricingLineItemWrapper>
          <PricingLineItemWrapper>
            <BulkPackageAddOnsCartDetailsContainer />
          </PricingLineItemWrapper>
        </PricingLineItems>
      </PricingWrapper>
    );
  }

  return null;
};

export default BulkPackageOfferingsCartDetailsPricingComponent;
