import { useEffect } from "react";

export function useTimeoutHandler({ timeout, handler, dependencies = [] }) {
  useEffect(() => {
    const id = window.setTimeout(() => {
      handler();
    }, timeout);

    return () => {
      clearTimeout(id);
    };
  }, dependencies);
}
