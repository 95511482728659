import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Section from "../../../../common/Section";
import { displayOtherPetParentAddresses } from "../../../../../core/actionCreators/petParentProfileActionCreator";
import getDisplayOtherAddresses from "../../../../../core/selectors/customer/address/getDisplayOtherAddresses";
import getOtherAddresses from "../../../../../core/selectors/customer/address/getOtherAddresses";

const mapStateToProps = (state, { children, customerKey }) => {
  const displayOtherAddresses = getDisplayOtherAddresses(state);
  const hasOtherAddresses = isEmpty(getOtherAddresses(state, { customerKey }));

  return {
    children,
    // here for merge props
    displayOtherAddresses,
    title: displayOtherAddresses ? "Other Addresses" : "",
    // NOTE: If there are no addresses, do no show button, else display label based on if the addresses are visible in the UI
    button:
      hasOtherAddresses || displayOtherAddresses ? "Show Less Addresses" : "Show More Addresses",
    isVisible: !hasOtherAddresses
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchDisplayAddresses: ({ isVisible }) =>
    dispatch(displayOtherPetParentAddresses({ isVisible }))
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  buttonClickHandler: () =>
    propsFromDispatch.dispatchDisplayAddresses({
      isVisible: !propsFromState.displayOtherAddresses
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Section);
