import { abbrs, timeZoneIdentifiers } from "./timeZoneCustomerConstants";
import moment from "moment-timezone";

/**
 *  Helper to get timezones
 *
 *  @memberOf helpers.associate
 *  @function
 *  @name getTimeZones
 *  @returns {Array} - time zone list 
 *  @example
 *
 *  getTimeZones()
 */
export function getTimeZones() {
  const tzList = [];

  for (const key in timeZoneIdentifiers) {
    tzList.push(key);
  }

  return tzList;
}

/**
 *  Helper to get time zone identifier by time zone
 *
 *  @memberOf helpers.associate
 *  @function
 *  @name getTimeZoneIdentifierByTimezone
 *  @param {String} timeZone - given timezone
 *  @returns {Object} - moment object
 *  @example
 *
 *  getTimeZoneIdentifierByTimezone(timeZone)
 */
export const getTimeZoneIdentifierByTimezone = timeZone => {
  moment.fn.zoneName = function() {
    const abbr = this.zoneAbbr();
    return abbrs[abbr] || abbr;
  };

  return moment.tz(timeZone).format("zz");
};
