import React from "react";
import PetParentForm from "./petParentForm/PetParentFormContainer";
import PetParentFormHeader from "./petParentFormHeader/PetParentFormHeaderContainer";
import PetParentProfileColumn from "../PetParentProfileColumn";
import PetParentAlertsForm from "./petParentAlerts/PetParentAlertsContainer";
import { PetParentContactSettings } from "@/dux/petParentContactSettings/PetParentContactSettings";
import { Hr } from "@petsmart-ui/sparky";
import PetParentAgentFormSectionSalon from "./petParentAgentFormWrapper/PetParentAgentFormWrapperContainer";
import PetParentAgentFormSectionHotel from "./petParentAgentFormWrapper/PetParentAgentFormHotelWrapperContainer";
import PetParentAgentFormSectionTraining from "./petParentAgentFormWrapper/PetParentAgentFormTrainingWrapperContainer";
import Section from "../../common/Section";
import PrimaryPetParentAddressContainer from "./petParentForm/AddressInput/PrimaryPetParentAddressContainer";
import PrimaryAddressSectionContainer from "./petParentForm/AddressInput/PrimaryAddressSectionContainer";
import OtherAddressesSectionContainer from "./petParentForm/AddressInput/OtherAddressesSectionContainer";
import OtherPetParentAddressListContainer from "./petParentForm/OtherPetParentAddressListContainer";
import AddAddressSectionContainer from "./petParentForm/AddressInput/AddAddressSectionContainer";
import AddPetParentAddressFormContainer from "./petParentForm/AddressInput/AddPetParentAddressFormContainer";
import PetParentAlertsHotelContainer from "./petParentAlerts/PetParentAlertsHotelContainer";
import PetParentAlertsTrainingContainer from "web/petParentProfile/petParent/petParentAlerts/PetParentAlertsTrainingContainer";
import {
  DoNotBookInputHotelContainer,
  DoNotBookInputSalonContainer,
} from "dux/doNotBook/DoNotBookInput";
import { PSAInputHotelCheckBox } from "dux/psa/psaInput";
import { VIPInputHotelCheckBox } from "dux/vip/VIPInputCheckbox";
import { CUSTOMER_NOTES_PER_SERVICE_TYPE } from "dux/notes/NotesDiConstants";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";

export default function PetParentComponent(props) {
  const { isHidden, componentID, customerKey, isCustomerActive, colTitle, systemName } = props;

  if (!isHidden) {
    return (
      <LayoutBox id={componentID} padding="scale-0">
        <PetParentProfileColumn title={colTitle}>
          <Section padding="0">
            {customerKey && <PetParentFormHeader customerKey={customerKey} />}
            <PetParentForm customerKey={customerKey} />

            <LayoutStack>
              {/* will show do not book checkbox for either hotel or salon based on flow user is in*/}
              {/*TODO: convert to key name lookup, see CUSTOMER_NOTES_COMPONENT_TYPES for reference*/}
              <DoNotBookInputSalonContainer />
              <DoNotBookInputHotelContainer />

              {/*TODO: convert to key name lookup, see CUSTOMER_NOTES_COMPONENT_TYPES for reference*/}
              <VIPInputHotelCheckBox />
              <PSAInputHotelCheckBox />
            </LayoutStack>
          </Section>

          {customerKey && (
            <Section disabled={!isCustomerActive}>
              <Hr style={{ width: "100%", margin: "15px 0" }} />
              <PetParentContactSettings customerKey={customerKey} />
              <Hr style={{ width: "100%", margin: "15px 0" }} />

              <Section>{CUSTOMER_NOTES_PER_SERVICE_TYPE[systemName]}</Section>

              {/*TODO: convert to key name lookup, see CUSTOMER_NOTES_COMPONENT_TYPES for reference*/}
              {/* Only one PetParentAlertsForm will display at at time based on tab the customer is in  */}
              <PetParentAlertsForm customerKey={customerKey} disabled={!customerKey} />
              <PetParentAlertsHotelContainer customerKey={customerKey} disabled={!customerKey} />
              <PetParentAlertsTrainingContainer customerKey={customerKey} disabled={!customerKey} />

              {/*TODO: convert to key name lookup, see CUSTOMER_NOTES_COMPONENT_TYPES for reference*/}
              {/* Only one PetParentAgentFormSection will display at at time based on tab the customer is in  */}
              <PetParentAgentFormSectionSalon customerKey={customerKey} />
              <PetParentAgentFormSectionHotel customerKey={customerKey} />
              <PetParentAgentFormSectionTraining customerKey={customerKey} />

              <PrimaryAddressSectionContainer customerKey={customerKey}>
                {/* NOTE, Take out reference of customer key and get directly from PrimaryPetParentAddressContainer once in persistent state, no longer prop drill this */}
                <PrimaryPetParentAddressContainer customerKey={customerKey} />
              </PrimaryAddressSectionContainer>

              {/* NOTE, Take out reference of customer key and get directly from OtherPetParentAddressListContainer and OtherAddressesSectionContainer once in persistent state, no longer prop drill this */}
              <OtherAddressesSectionContainer customerKey={customerKey}>
                <OtherPetParentAddressListContainer customerKey={customerKey} />
              </OtherAddressesSectionContainer>

              {/* NOTE, Take out reference of customer key and get directly from AddPetParentAddressFormContainer and AddAddressesSectionContainer once in persistent state, no longer prop drill this */}
              <AddAddressSectionContainer customerKey={customerKey}>
                <AddPetParentAddressFormContainer customerKey={customerKey} />
              </AddAddressSectionContainer>
            </Section>
          )}
        </PetParentProfileColumn>
      </LayoutBox>
    );
  }

  return null;
}
