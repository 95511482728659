import { itinerariesPaymentStatuses } from "../../constants/itinerariesConstants";
import { jobRoleConstants } from "../../constants/jobRoleConstants";

const isPaidFactory = itineraryStatus => {
  switch (itineraryStatus) {
    case itinerariesPaymentStatuses.PAID:
      return true;
    case itinerariesPaymentStatuses.CLOSED:
      return true;
    case itinerariesPaymentStatuses.VOIDED:
      return true;
    case itinerariesPaymentStatuses.OPEN:
      return true;
    default:
      return false;
  }
};

/**
 * Responsible for determining if a check related to payments should be disabled.
 *
 * @export isPaidDisabled
 * @param {*} itinerary itinerary object
 * @param {*} jobRole - Access level
 * @returns
 */
export default function isPaidDisabled(itinerary, jobRole) {
  const isDisable = isPaidFactory(itinerary?.status);

  return jobRole === jobRoleConstants.ASSOCIATE || isDisable;
}
