import React from "react";
import styled from "styled-components";
import ConfirmAllAppointments_FA_Container from "./confirmAllAppointments_FA_Container";
import ConfirmSingleAppointment_CICO_Container from "./ConfirmSingleAppointment_CICO_Container";
import ConfirmSingleAppointment_FA_Container from "./ConfirmSingleAppointment_Container";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
`;

/**
 * Responsible for aggregating the confirm Appointment Button Containers into a single location..
 * This way all button containers will be loaded in a single location and can be
 * re-used with a single place to update.
 *
 * @export ConfirmButtonsAggregate
 * @param {*} props - none
 * @returns JSX
 */
export default function ConfirmButtonsAggregate(props) {
  return (
    <SectionWrapper>
      {/*
                Un-comment when confirm all appointments is requested
                via a Jira ticket through the business, see my comments in this
                container component under dispatchUpdateItineraryStatus for more info

                <ConfirmAllAppointments_FA_Container />
            */}

      {/* Confirm button for the Check In Check Out page. */}
      <ConfirmSingleAppointment_CICO_Container />

      {/* Confirm button for the Future appointment Page */}
      <ConfirmSingleAppointment_FA_Container />
    </SectionWrapper>
  );
}
