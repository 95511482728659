import {
  GET_HOTEL_ADDONS_BY_PET_SERVICE_SUCCESS,
  SET_HOTEL_ADDONS_LIST,
} from "./hotelAddonsLIstActions";

const initialState = {
  searchValue: "",
  hotelAddons: {},
  serviceDescriptors: {},
};

const hotelAddonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTEL_ADDONS_LIST:
      return {
        ...state,
        searchValue: action.searchValue,
      };
    case GET_HOTEL_ADDONS_BY_PET_SERVICE_SUCCESS:
      return {
        ...state,
        latestPet: action?.petId,
        hotelAddons: { ...state?.hotelAddons, [action?.petId]: action.addons },
        serviceDescriptors: { ...state?.serviceDescriptors, [action?.petId]: action?.descriptors },
      };
    default:
      return state;
  }
};

export default hotelAddonsReducer;
