import React from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { TextPassage } from "@petsmart-ui/sparky";
import SplitLineWithMessage from "@/web/common/SplitLineWithMessage";

// Constants
import { checkoutDayAlert } from "./checkoutDayAlertConstants";

/**
 * React view component for hotel early check out warning
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name CheckoutDayAlert
 * @param {Object} props
 * @param {string} props.componentId
 * @param {Boolean} props.isHidden
 * @param {JSX|string} props.mainReminder
 * @returns {JSX.Element}
 * @example const container = connect()(CheckoutDayAlert)
 */
export const CheckoutDayAlert = props => {
  const { componentId, mainReminder } = props;

  return (
    <LayoutBox id={componentId}>
      <TextPassage>
        <span style={{ color: "red" }}>{mainReminder}</span>
      </TextPassage>
    </LayoutBox>
  );
};

/**
 * Redux Connect function displaying checkout day alert
 * @see {@link Views.Hotel.Itinerary.CheckOutReminder}
 * @summary Located on the hotel check-out page
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelCheckoutDayAlert
 * @returns {JSX.Element|null}
 * @example <HotelCheckoutDayAlert />
 */
export const HotelCheckoutDayAlert = connect(() => {
  const delimiter = "\n";

  return {
    componentId: "HotelCheckoutDayAlert",
    mainReminder: SplitLineWithMessage({ string: checkoutDayAlert.mainMsg, delimiter }),
  };
})(CheckoutDayAlert);
