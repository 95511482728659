import { createSelector } from "reselect";
import { getOr } from "lodash/fp";
import { getPet } from "../../core/selectors/entitiesSelector";
import normalizeFormInput from "../../core/utils/normalizeUtils/normalizeFormInput";
import { favourabilityOptions } from "../../core/constants/petProfileConstants";
import getPrismPetState from "../prismPet/prismPetSelector";
import { systemName } from "../../web/setSystemType/constants/setSystemTypeConstants";

/* * ----------------------------------------------------------------------- * *\
  Salon Favorability
\* * ----------------------------------------------------------------------- * */
export const getPetFavorability = createSelector([getPet], pet => (pet && pet.petRatings) || []);

export const getPetFavorabilityFormData = createSelector([getPetFavorability], petFavorability => {
  return {
    ratingReason: normalizeFormInput(petFavorability[0], "ratingReason"),
    ratingTypeId: normalizeFormInput(petFavorability[0], "ratingId"),
  };
});

/* * ----------------------------------------------------------------------- * *\
  Hotel Favorability
\* * ----------------------------------------------------------------------- * */
export const getPetFavorabilityHotel = createSelector([getPrismPetState], prismPet => {
  if (prismPet.profiles) {
    const hotelProfile = prismPet.profiles.find(profile => {
      return profile.profileType === systemName.DDC_HOTEL;
    });

    // petRating will come in as a string, but needs to be converted to a number to be used as an ID.
    const ratingOption = favourabilityOptions.find(option => {
      return hotelProfile ? hotelProfile.petRating === option?.label : 0;
    });

    return [
      {
        ratingReason: getOr("", "petRatingReason", hotelProfile),
        ratingId: getOr(0, "value", ratingOption),
      },
    ];
  }

  return [];
});

export const getPetFavorabilityHotelFormData = createSelector(
  [getPetFavorabilityHotel],
  petFavorability => {
    return {
      ratingReason: normalizeFormInput(petFavorability[0], "ratingReason"),
      ratingTypeId: normalizeFormInput(petFavorability[0], "ratingId"),
    };
  },
);

export const isDisabledForNonManagement = ({ petFavorabilityFormData, isManager }) => {
  const isDisabledForNonManagement = petFavorabilityFormData?.ratingTypeId === 3 && !isManager;
  return isDisabledForNonManagement
};
