import { put, takeEvery, call, all, select } from "redux-saga/effects";
import agentsActionCreators from "../actionCreators/agentsActionCreators";
import customersActionCreators from "../actionCreators/customersActionCreators";
import agentsActionTypes from "../actionTypes/agentsActionTypes";
import { postAgent, putAgent, deleteAgent } from "../services/associateWebProfile/agentEndPoints";
import { getPetParentAgents } from "../selectors/agentsSelectors";
import { getSourceId } from "../selectors/persistentSelectors";
import petParentProfileActionCreators from "../actionCreators/petParentProfileActionCreator";

function* onCreateAgent({ customerKey, data }) {
  try {
    yield put(agentsActionCreators.createAgentRequest());
    const sourceId = yield select(getSourceId);
    const response = yield call(postAgent, { customerKey, sourceId, data: { ...data, sourceId } });
    const agent = response.data;
    const customerAgents = yield select(getPetParentAgents, { customerKey });
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          agents: customerAgents.concat(agent)
        }
      })
    );
    yield put(agentsActionCreators.createAgentSuccess());
    yield put(agentsActionCreators.hideAddAgentForm());
  } catch (error) {
    yield put(agentsActionCreators.createAgentFailure({ error }));
  }
}

function* onUpdateAgent({ customerKey, agentId, data }) {
  try {
    yield put(agentsActionCreators.updateAgentRequest());
    const sourceId = yield select(getSourceId);
    const response = yield call(putAgent, {
      customerKey,
      sourceId,
      agentId,
      data: { ...data, sourceId }
    });
    const updatedAgent = response.data;
    const customerAgents = yield select(getPetParentAgents, { customerKey });
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          agents: customerAgents.map(agent => {
            if (agent.agentId === updatedAgent.agentId) {
              return { ...agent, ...updatedAgent };
            }
            return agent;
          })
        }
      })
    );
    yield put(agentsActionCreators.updateAgentSuccess());
  } catch (error) {
    yield put(agentsActionCreators.updateAgentFailure({ error }));
  }
}

function* onDeleteAgent({ customerKey, agentId }) {
  try {
    yield put(agentsActionCreators.deleteAgentRequest());
    const sourceId = yield select(getSourceId);
    yield call(deleteAgent, {
      customerKey,
      agentId,
      sourceId
    });
    const customerAgents = yield select(getPetParentAgents, { customerKey });
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey,
          agents: customerAgents.filter(agent => agent.agentId !== agentId)
        }
      })
    );
    yield put(agentsActionCreators.deleteAgentSuccess());
    yield put(agentsActionCreators.clearCustomerAndAgentKeys());
    yield put(petParentProfileActionCreators.hidePetParentProfileModal());
  } catch (error) {
    yield put(agentsActionCreators.updateAgentFailure({ error }));
  }
}

function* watchCreateAgent() {
  yield takeEvery(agentsActionTypes.CREATE_AGENT, onCreateAgent);
}

function* watchUpdateAgent() {
  yield takeEvery(agentsActionTypes.UPDATE_AGENT, onUpdateAgent);
}

function* watchDeleteAgent() {
  yield takeEvery(agentsActionTypes.DELETE_AGENT, onDeleteAgent);
}

export default function* agentsSaga() {
  yield all([watchCreateAgent(), watchUpdateAgent(), watchDeleteAgent()]);
}
