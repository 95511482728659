import React from "react";
import WeeklyNavigationBarHotelContainer from "web/weekNavigationBar/WeeklyNavigationBarHotelContainer";
import DateSelectionPreferencesWrapper from "./DateSelectionPreferencesWrapper";
import DateAndAssociateSelectionWrapper from "./DateAndAssociateSelectionWrapper";
import TodayButtonCont, { HotelTodayButton } from "../../weekNavigationBar/TodayButtonCont";
import WeeklyNavigationBarSalonContainer from "../../weekNavigationBar/WeeklyNavigationBarSalonContainer";
import StoreClosedWarningContainer from "../storeClosedWarning/StoreClosedWarningContainer";
import MultiPetBookingWarningMessage from "@/dux/bookingSalon/MultiPetBookingErrorMessage";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import { connect } from "react-redux";
import { LayoutStack } from "@/layout/stack/Stack";

const todayButtons = {
  [systemName.SALON]: TodayButtonCont,
  [systemName.HOTEL]: HotelTodayButton,
};

const weeklyNavBars = {
  [systemName.SALON]: WeeklyNavigationBarSalonContainer,
  [systemName.HOTEL]: WeeklyNavigationBarHotelContainer,
};

export function DateSelectionWrapper(props) {
  const { componentId, isHidden, children, diComp } = props;
  if (isHidden) return null;

  const TodayButton = diComp.todayButton ?? "div";
  const WeeklyNavBar = diComp.weeklyNavBar ?? "div";

  return (
    <LayoutStack
      id={componentId}
      style={{
        padding: "2em 1em 0 1em",
        alignItems: "stretch",
        justifyContent: "space-between",
      }}
    >
      <DateSelectionPreferencesWrapper>
        <DateAndAssociateSelectionWrapper>{children}</DateAndAssociateSelectionWrapper>
        <TodayButton />
      </DateSelectionPreferencesWrapper>

      {/* What WeeklyNavigationBar shows is based on what system flow the user is in */}
      <WeeklyNavBar />

      <StoreClosedWarningContainer />
      <MultiPetBookingWarningMessage />
    </LayoutStack>
  );
}

export default connect(state => {
  const bookingFlow = getSystemBookingFlow(state);
  return {
    componentId: "DateSelectionWrapper",
    diComp: {
      todayButton: todayButtons[bookingFlow],
      weeklyNavBar: weeklyNavBars[bookingFlow],
    },
  };
})(DateSelectionWrapper);
