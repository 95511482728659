/**
 * Responsible for returning the hotelhours Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export hotelhoursState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the hotelhours object in the redux store
 *
 * @example createSelector([hotelhoursState], hotelhoursState => hotelhoursState.2020-01-28;
 */
export default function getHotelHoursState(state) {
  return state?.hotelHours;
}
