/*  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  sections are currently set to commented out as of this time because training does not need to display
  an agent form.
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

import { connect } from "react-redux";
import {
  getPetParentAgents,
  getIsAddAgentFormShown,
  getIsAddAgentButtonShown
} from "../../../../core/selectors/agentsSelectors";
import agentsActionCreators from "../../../../core/actionCreators/agentsActionCreators";
import PetParentAgentFormWrapperComponent from "./PetParentAgentFormWrapperComponent";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = (state, ownProps) => ({
  isHidden: true, // getSystemBookingFlow(state) !== systemName.TRAINING,
  componentId: "PetParent__AgentForm--Training",
  // petParentAgents: getPetParentAgents(state, ownProps),
  // isAddAgentFormShown: getIsAddAgentFormShown(state),
  // isAddAgentButtonShown: getIsAddAgentButtonShown(state, ownProps)
});

// const mapDispatchToProps = dispatch => ({
//   showAddAgentForm: () => dispatch(agentsActionCreators.showAddAgentForm())
// });

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(PetParentAgentFormWrapperComponent);
