/**
 * HOW TO USE:
 * Wrap the component to want to register a click outside of, with this component
 *      export default withClickOutside(MyComponent);
 *
 * The component you are wrapping must also have be a class and have a handleClickOutside() function
 * definition:
 *      handleClickOutside() {
 *         console.log('clicked out side MyComponent');
 *      }
 * You also need to also have a clickOutsideRef property within your class, best to palace it
 * in the render method
 *      const { clickOutsideRef } = this.props;
 *
 * Lastly wrap your component(s) in a clickedOutsideRef div
 *      <div ref={clickOutsideRef}>
 *          ...
 *      </div>
 *
 * Can add class "no-click-outside" to and components outside that shouldn't trigger outside click
 * Or pass a different class name to ignore when wrapping a component
 */

import React from "react";

export const clickOutsideIgnoredClass = "no-click-outside";

export default function withClickOutside(WrappedComponent, ignoreClass = clickOutsideIgnoredClass) {
  return class extends React.Component {
    wrappedComponentRef = React.createRef();

    clickOutsideRef = React.createRef();

    componentDidMount() {
      document.addEventListener("mousedown", this.handleClick, false);
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClick, false);
    }

    /**
     * Determines if target element is located outside of the "clickOutsideRef" in the DOM.
     * @param {object} e - The React synthetic click event object.
     * @returns {Boolean} - Returns a boolean value indicating if the target element is outside of the click outside ref.
     */
    isTargetElementOutsideOfRef = e => {
      return this.clickOutsideRef.current && !this.clickOutsideRef.current.contains(e.target);
    };

    /**
     * Determines if target element has a role of "option", which indicates that the element is a dropdown item.
     * @param {object} e - The React synthetic click event object.
     * @returns {Boolean} - Returns a boolean value indicating if the target element is a dropdown item.
     */
    isDropdownOption = e => e.target.getAttribute("role") === "option";

    /**
     * Determines if target element has a className of "commonModal", which indicates that the element is our common modal.
     * @param {object} e - The React synthetic click event object.
     * @returns {Boolean} - Returns a boolean value indicating if the target element is our common modal.
     */
    isCommonModal = e => {
      const {
        target: { className = "" },
      } = e;

      // react-select had an svg icon that was resulting in errors because svg's are an object: { baseVal: "", animVal: ""}
      if (className instanceof SVGAnimatedString) {
        return className.baseVal.includes("commonModal");
      }

      return className.includes("commonModal");
    };

    /**
     * Determines if target element has a parent with class name matching ignoreClass prop
     * @param {object} e - The React synthetic click event object.
     * @returns {Boolean}
     */
    isComponentToIgnore = e => {
      return !!e?.target?.closest(`.${ignoreClass}`);
    };

    /**
     * Since React-Select dropdown options are located outside of the typical DOM flow,
     * we want to disable our handle click outside functionality if the component we are clicking on
     * is a dropdown option. Otherwise, clicking a dropdown option will falsely fire a click outside
     * event when it is clicked. We also want to disable the handle click outside functionality if the
     * target element is the common modal, which is outside of the root React entry point.
     * @param {object} e - The React synthetic click event object.
     * @returns {Boolean} - Returns a boolean value indicating if we should fire off the handle click outside event.
     */
    shouldFireClickOutsideEvent = e => {
      const { handleClickOutside } = this.wrappedComponentRef.current;
      return (
        handleClickOutside &&
        this.isTargetElementOutsideOfRef(e) &&
        !this.isDropdownOption(e) &&
        !this.isCommonModal(e) &&
        !this.isComponentToIgnore(e)
      );
    };

    handleClick = e => {
      const { handleClickOutside } = this.wrappedComponentRef.current;
      if (this.shouldFireClickOutsideEvent(e)) {
        handleClickOutside(e);
      }
    };

    render() {
      const { wrappedComponentRef, clickOutsideRef, props } = this;
      return (
        <WrappedComponent {...props} clickOutsideRef={clickOutsideRef} ref={wrappedComponentRef} />
      );
    }
  };
}
