import styled from "styled-components";
import { device } from "web/common/styles/responsive/devices";

const AddButtonContainer = styled.span`
  
  // @media ${device.prismDesktop} {
  //   flex-basis: 15%;
  // }
  
  // There were concerns around responsive affecting the UI layout a week before the BGM deployment, All media queries
  // that use the device object will have a media query to target Prism desktop to support BGM release.
  // This media query can be removed after 11/9/20
  @media ${device.prismDesktop} {
    margin-left: auto;
  }
`;

export default AddButtonContainer;
