import vetsActionTypes from "../../../actionTypes/vetsActionTypes";

const initialState = {
  showVetSearchResults: false,
  customerKey: null,
  petId: null,
  searchResults: [],
  searchParams: {},
  vetToDelete: null
};

export default function vetsReducer(state = initialState, action) {
  switch (action.type) {
    case vetsActionTypes.SHOW_VET_SEARCH_RESULTS:
      return {
        ...state,
        showVetSearchResults: true
      };
    case vetsActionTypes.HIDE_VET_SEARCH_RESULTS:
      return {
        ...state,
        showVetSearchResults: false
      };
    case vetsActionTypes.LOAD_VET_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        searchResults: action.searchResults,
        searchParams: action.searchParams
      };
    case vetsActionTypes.SET_CUSTOMER_AND_PET_KEYS:
      return {
        ...state,
        customerKey: action.customerKey,
        petId: action.petId
      };
    case vetsActionTypes.SET_VET_TO_DELETE:
      return {
        ...state,
        vetToDelete: action.vetToDelete
      };
    case vetsActionTypes.CLEAR_VET_TO_DELETE:
      return {
        ...state,
        vetToDelete: initialState.vetToDelete
      };
    case vetsActionTypes.CLEAR_DATA_REQUIRED_FOR_VET_SEARCH:
      return initialState;
    default:
      return state;
  }
}
