import React from "react";
import styled from "styled-components";
import MedicationMorningCheckboxContainer from "./MedicationMorningCheckboxContainer";
import MedicationAfternoonCheckboxContainer from "./MedicationAfternoonCheckboxContainer";
import MedicationEveningCheckboxContainer from "./MedicationEveningCheckboxContainer";
import ErrorText from "../common/ErrorText";

const Wrapper = styled.div`
  display: grid;
  grid-column: 1 / 3;
  color: ${props => props.error && "red"};
`;

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-rows: 1;
  grid-template-columns: repeat(3, 1fr);
`;

const Title = styled.div`
  color: ${props => props.error && "red"};
`;

export default function MedicationTimeWrapper({ isHidden, medicationId, petId, title, error }) {
  return !isHidden ? (
    <Wrapper error={error}>
      <Title>{title}</Title>
      <CheckboxContainer>
        <MedicationMorningCheckboxContainer medicationId={medicationId} petId={petId} />
        <MedicationAfternoonCheckboxContainer medicationId={medicationId} petId={petId} />
        <MedicationEveningCheckboxContainer medicationId={medicationId} petId={petId} />
      </CheckboxContainer>
      {error && <ErrorText message={error} />}
    </Wrapper>
  ) : null;
}
