import { createSelector } from "reselect";
import getModalsState from "./modalsState";

export const getCheckInOutModalType = createSelector(
  [getModalsState],
  modals => modals.checkInOutModal.modalType,
);

export const getCheckInOutModalProps = createSelector(
  [getModalsState],
  modals => modals?.checkInOutModal?.modalProps ?? {},
);
