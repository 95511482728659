/**
 * Responsible for setting Opacity
 *
 * @export SetOpacity
 * @param {boolean} { disabled }
 * @returns {number}
 */
export default function SetOpacity({ disabled }) {
  if (disabled) {
    return 0.5;
  }

  return 1;
}
