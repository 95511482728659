import React from "react";
import styled, { css } from "styled-components";

const commonListProps = css`
  ${({ paddingBottom }) =>
    paddingBottom &&
    `
    padding-bottom: ${paddingBottom};
  `};
  ${({ margin }) =>
    margin &&
    `
  margin: ${margin};
`};
`;

const OrderedList = styled.ol`
  ${commonListProps};
`;

const UnorderedList = styled.ul`
  ${commonListProps};
`;

/**
 * Takes a list of strings and renders an ordered or unordered list to the DOM.
 * @param {Array} items An array of string values.
 * @param {String} type The type of list to show (ordered/unordered). Default = unordered..
 * @returns {JSX Element} JSX element to render to DOM.
 * */

export default ({ items = [], type = "unordered", isHidden = false, ...props }) => {
  const ListWrapper = type === "ordered" ? OrderedList : UnorderedList;
  return !isHidden ? (
    <ListWrapper {...props}>
      {items.map(item => (
        <li key={item}>{item}</li>
      ))}
    </ListWrapper>
  ) : null;
};
