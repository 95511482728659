import { connect } from "react-redux";
import DatesRangeComponent from "./datesRangeComponent";
import { getStartWeekDate } from "../../core/selectors/schedulesSelectors";
import { setStartWeekDate } from "../../core/actionCreators/schedulesActionCreators";

const mapStateToProps = state => ({
  startWeekDate: getStartWeekDate(state)
});

const mapDispatchToProps = dispatch => ({
  setStartWeekDate: date => dispatch(setStartWeekDate(date))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatesRangeComponent);
