import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";
import { getBulkPackageOfferingsServiceTypesByPet } from "../bulkPackageOfferingsSelectors";
import BulkPackageOfferingsComponent from "../../../_components/bgm/bulkPackageOfferings/BulkPackageOfferingsComponent";

const mapStateToProps = state => {
  const bulkPackageOfferings = getBulkPackageOfferingsServiceTypesByPet(state);
  return {
    bulkPackageOfferings,
    isHidden: isEmpty(bulkPackageOfferings),
    componentID: "bulk-package-offerings-accordion-content",
    testID: "test-bulk-package-offerings-accordion-content",
  };
};

export default connect(mapStateToProps)(BulkPackageOfferingsComponent);
