import { connect } from "react-redux";
import { Text } from "@prism/psm-ui-components";
import { getFormattedBundleAddOnUnitPrice } from "@/dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";

const mapStateToProps = (state, { bundleNumber, idEnum }) => {
  const { petId } = idEnum;
  const addOnUnitPrice = getFormattedBundleAddOnUnitPrice(state, { petId, bundleNumber });
  return {
    // SVCSART-9866: We want to display the add-on unit price for BGM packages only.
    // We are defaulting to an empty string here for FTCO packages in order to keep
    // a placeholder slot in our grid.
    children: addOnUnitPrice,
  };
};

export default connect(mapStateToProps)(Text);
