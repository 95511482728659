import React from "react";
import { Layout } from "@prism/psm-ui-components";

export default function CapacityTable(props) {
  const { isHidden, componentID, capacity, diComp, compName, onShowModal } = props;

  if (!isHidden) {
    const Header = diComp.header[compName];
    const Row = diComp.row[compName];

    return (
      <Layout.Box id={componentID} onClick={onShowModal}>
        <Layout.Box>
          <Header />
        </Layout.Box>

        <Layout.Stack>
          {capacity.map((item, index) => {
            const key = `${componentID}_row-${index}`;

            return <Row key={key} index={index} />;
          })}
        </Layout.Stack>
      </Layout.Box>
    );
  }

  return null;
}
