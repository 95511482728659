import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { getConstructedHotelItinerary } from "dux/hotelItinerary/hotelConstructedItinerarySelectors";
import { updateItineraryWithPendingChanges } from "@/dux/hotelItinerary/hotelItineraryUtils";
import {
  DELETE_CHECK_IN_MED,
  deleteCheckInMedFailure,
  deleteCheckInMedRequest,
  deleteCheckInMedSuccess,
} from "dux/_components/checkInOutMedications/checkInMedsActions";
import { onPutHotelItinerary } from "@/dux/hotelItinerary/hotelItinerarySaga";
import { selectCurrentStoreTimeZone } from "@/core/selectors/persistentSelectors";
import { selectPetMedsWithoutMedToBeRemoved } from "./CheckInOutCartMedsSelectors";

function* onDeleteCheckInMed({ petId, medId }) {
  try {
    yield put(deleteCheckInMedRequest());

    // get the itinerary
    const itinerary = yield select(getConstructedHotelItinerary);

    const meds = yield select(selectPetMedsWithoutMedToBeRemoved(medId), { petId });
    const timeZone = yield select(selectCurrentStoreTimeZone);

    // re-construct hotel Itinerary body, the api is a put only and requires the full body.
    const itineraryPayload = updateItineraryWithPendingChanges({
      itinerary,
      currentPetId: petId,
      meds,
      timeZone,
    });

    yield call(onPutHotelItinerary, { hotelItinerary: itineraryPayload });

    yield put(deleteCheckInMedSuccess());
  } catch (error) {
    yield put(deleteCheckInMedFailure(error));
  }
}

/** ----------------------------------------------------------------------- **\
 WATCH FUNCTIONS
 \** ----------------------------------------------------------------------- * */
function* watchDeleteCheckInMed() {
  yield takeEvery(DELETE_CHECK_IN_MED, onDeleteCheckInMed);
}

/** ----------------------------------------------------------------------- **\
 EXPORT SAGAS
 \** ----------------------------------------------------------------------- * */
export default function* deleteCheckInMedSaga() {
  yield all([fork(watchDeleteCheckInMed)]);
}
