import { connect } from "react-redux";
import indexTab from "../common/tabs/indexTabs/IndexTab";
import { systemName } from "./constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "./selectors/setSystemTypeSelectors";
import { setSystemBookingFlowType } from "./actions/setSystemTypeActions";
import getTrainingWorkflowFeatureFlag
  from "web/enableDisableWorkflowFeatureFlag/selectors/training/getTrainingWorkflowFeatureFlag";

const mapStateToProps = state => ({
  isHidden: getTrainingWorkflowFeatureFlag(state).isHidden,
  isActive: getSystemBookingFlow(state) === systemName.TRAINING,
  componentID: `setSystemType${systemName.TRAINING}-indexTab`,
  label: `${systemName.TRAINING} Details `,
});

const mapDispatchToProps = dispatch => ({
  handleClick: () => dispatch(setSystemBookingFlowType({ systemBookingFlow: systemName.TRAINING })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(indexTab);
