import { put, takeLatest, call, all, select } from "redux-saga/effects";

// Services
import { getHotelRoomSuspensionsByRoomNumber } from "core/services/systemServicesBooking/hotelRoomMananagementEndPoints";

// Selectors
import { getStoreNumber } from "core/selectors/persistentSelectors";

// Actions
import { getServiceManagementToggleReasons } from "@/dux/servicesManagementToggle/servicesManagementToggleActions";
import {
  GET_HOTEL_MANAGE_ROOM_DETAILS,
  getHotelManageRoomDetailsRequest,
  getHotelManageRoomDetailsSuccess,
  getHotelManageRoomDetailsFailure,
} from "./hotelManageRoomDetailsActions";

// Constants
import { suspensionTypes } from "../servicesManagementToggle/serviceManagmentToggleConstants";

function* onGetHotelManageRoomDetails({ payload }) {
  const { fromDate, toDate, roomNumber } = payload;
  try {
    yield put(getHotelManageRoomDetailsRequest());
    const storeNumber = yield select(getStoreNumber);

    // room suspensions api by room number
    const response = yield call(getHotelRoomSuspensionsByRoomNumber, {
      storeNumber,
      roomNumber,
      fromDate,
      toDate,
    });

    // fetch room suspensions reasons
    yield put(getServiceManagementToggleReasons({ suspensionTypes: suspensionTypes.ROOM }));

    yield put(getHotelManageRoomDetailsSuccess(response.data.result));
  } catch (error) {
    yield put(getHotelManageRoomDetailsFailure());
  }
}

function* watchOnGetHotelManageRoomDetails() {
  yield takeLatest(GET_HOTEL_MANAGE_ROOM_DETAILS, onGetHotelManageRoomDetails);
}

export default function* hotelManageRoomDetailsSaga() {
  yield all([watchOnGetHotelManageRoomDetails()]);
}
