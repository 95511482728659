import { connect } from "react-redux";
import { removeAddOn } from "../../../core/actionCreators/ui/web/cartActionCreators";
import AddonRow from "./AddonRow";

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeAddOn: () => dispatch(removeAddOn({ addon: ownProps.addon.addOnId, petId: ownProps.petId }))
});

export default connect(
  null,
  mapDispatchToProps
)(AddonRow);
