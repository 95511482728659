import React from "react";
import { connect } from "react-redux";
import { Layout, Form, Text } from "@prism/psm-ui-components";
import { toggleCompleteTrainingAppointmentModal } from "./manageTrainingClassPageActions";
import BGM_STATUS from "../bgm/availableBundlesByPet/availableBundlesByPetConstants";
import { getIsSRCAgent, getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getTrainingAttendeeById } from "@/dux/trainingClassSessions/selectors";

const TrainingAppointmentCompleteCheckbox = ({ onChange, isChecked, isSRCAgent }) => {
  return (
    <Layout.Cluster space="cluster-space-2">
      <Form.Field.Checkbox
        changed={onChange}
        checked={isChecked}
        disabled={isChecked || isSRCAgent}
      />
      <Text color={isSRCAgent ? "text-color-disabled" : "text-color-inherit"}>Complete</Text>
    </Layout.Cluster>
  );
};

export default connect(
  (state, { attendeeId }) => {
    const storeNumber = getStoreNumber(state);
    const { redemptionStatus } = getTrainingAttendeeById(state, {
      storeNumber,
      attendeeId,
    });
    return {
      isChecked: redemptionStatus === BGM_STATUS.COMPLETE,
      isSRCAgent: getIsSRCAgent(state),
    };
  },
  dispatch => ({
    onChange: () => {
      dispatch(toggleCompleteTrainingAppointmentModal());
    },
  }),
)(TrainingAppointmentCompleteCheckbox);
