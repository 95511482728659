import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import customersActionTypes from "../../actionTypes/customersActionTypes";
import * as customersActionCreators from "../../actionCreators/customersActionCreators";
import { patchCustomerAlerts } from "../../services/associateWebProfile/customersEndPoints";
import { getSourceId } from "../../selectors/persistentSelectors";

// This saga is the old version for customer alerts using the old PETCH endpoint, in the future Salon update will use the new customer alerts saga under the dux folder

export function* onUpdateCustomerAlerts({ customerKey, data }) {
  try {
    yield put(customersActionCreators.updateCustomerAlertsRequest());
    const sourceId = yield select(getSourceId);
    const response = yield call(patchCustomerAlerts, {
      customerKey,
      sourceId,
      data,
    });
    const customerAlerts = response.data;
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          bookingAlert: customerAlerts.bookingAlert,
          checkInAlert: customerAlerts.checkInAlert,
          checkOutAlert: customerAlerts.checkOutAlert,
        },
      }),
    );
    yield put(customersActionCreators.updateCustomerAlertsSuccess());
  } catch (error) {
    yield put(customersActionCreators.updateCustomerAlertsFailure({ error }));
  }
}

function* watchUpdateCustomerAlerts() {
  yield takeEvery(customersActionTypes.UPDATE_CUSTOMER_ALERTS, onUpdateCustomerAlerts);
}

export default function* customerAlertsSaga() {
  yield all([fork(watchUpdateCustomerAlerts)]);
}
