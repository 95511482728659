import { associateWebBFFAPI } from "./_axiosConfigAssociateWebBff";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * API to provide Frequency options to the SOE for the following Hotel engagement entities:
 *    Add-Ons
 *    Medications
 *    Feedings
 * @returns {*} AxiosPromise
 */
export function fetchFrequencies() {
  return associateWebBFFAPI.get(`${endpointVersionString()}/hotel/frequencies`);
}
