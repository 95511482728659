import React from "react";
import UnderlinedTabs from "../../../../common/tabs/underlinedTabs/UnderlinedTabs";
import AppointmentsListTab from "./AppointmentsListTab";
import OtherServicesListTab from "./OtherServicesListTab";

const ServiceHistoryTabsComponent = ({ componentID, isHidden }) => {
  if (!isHidden) {
    return (
      <UnderlinedTabs id={componentID}>
        <AppointmentsListTab />
        <OtherServicesListTab />
      </UnderlinedTabs>
    );
  }

  return null;
};

export default ServiceHistoryTabsComponent;
