import { connect } from "react-redux";
import {
  getIsCustomerActive,
  getCustomerFullName
} from "../../../../core/selectors/entitiesSelector";
import customersActionCreators from "../../../../core/actionCreators/customersActionCreators";
import FormHeaderComponent from "../../FormHeaderComponent";

const mapStateToProps = (state, ownProps) => ({
  fullName: getCustomerFullName(state, ownProps),
  isActive: getIsCustomerActive(state, ownProps)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSetIsActive: isActive => {
    if (isActive) {
      dispatch(
        customersActionCreators.setCustomerActive({
          customerKey: ownProps.customerKey
        })
      );
    } else {
      dispatch(
        customersActionCreators.setCustomerInactive({
          customerKey: ownProps.customerKey
        })
      );
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormHeaderComponent);
