import VipBookingAlertMessageContainer from "web/vipBookingAlertMessage/VipBookingAlertMessageContainer";
import { connect } from "react-redux";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import {
  HotelCheckInPetParentAlert,
  HotelCheckInSinglePetAlert,
} from "web/hotelAlerts/HotelAlertComponent";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import React from "react";

import { TextPassage, Button, Heading } from "@petsmart-ui/sparky";
import { getCurrentItinerary } from "core/selectors/checkInOutSelector";
import { ALERTS_FOR_THIS_APPOINTMENT } from "dux/eligibility/constants/eligibilityConstants";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutCenter } from "@/layout/center/Center";
import { patchHotelItineraryCheckInStatus } from "dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import { getChangeStatusPayload } from "dux/reservationCartDetailsConfirmation/reservationCartDetailsUtils";
import { itinerariesStatusList } from "dux/_constants";
import { selectHotelPetsToCheckIn } from "@/dux/hotelEngagements/hotelEngagementSelectors";

export const HotelCheckInOutAlertsModalComponent = props => {
  // Props
  const { componentId, header, message, alertMessages, isContinueDisabled } = props;

  // Handlers
  const { onClose, onContinue } = props;

  return (
    <LayoutBox id={componentId}>
      <LayoutStack>
        {/*HEADER AND NOTICE MESSAGE*/}
        <LayoutBox>
          <LayoutStack space={"scale-S1"}>
            <LayoutCenter>
              <Heading id={`${componentId}__heading`} tagName="h4">
                {header}
              </Heading>
            </LayoutCenter>

            <LayoutCenter>
              <TextPassage size="lg">{message}</TextPassage>
            </LayoutCenter>
          </LayoutStack>
        </LayoutBox>

        {/*VIP CONTENT*/}
        <LayoutBox>
          <VipBookingAlertMessageContainer />
        </LayoutBox>

        {/*ALERT CONTENT*/}
        <LayoutBox>{alertMessages}</LayoutBox>

        {/*BUTTONS*/}
        <LayoutBox>
          <LayoutCluster style={{ justifyContent: "space-between" }}>
            {/*GO BACK BUTTON*/}
            <LayoutBox>
              <Button variant="link" text="Go Back" onClick={onClose} />
            </LayoutBox>

            {/*CONTINUE BUTTON*/}
            <LayoutBox>
              <Button
                disabled={isContinueDisabled}
                variant="primary"
                text="Continue"
                onClick={onContinue}
              />
            </LayoutBox>
          </LayoutCluster>
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

export const HotelCheckInSinglePetAlertsModal = connect(
  (state, { onClose }) => {
    const petId = getCurrentPet(state);
    const customerKey = getCurrentCustomerKey(state);

    return {
      componentId: "HotelCheckInSinglePetAlertsModal",
      isContinueDisabled: false,
      header: "Important to know",
      message: ALERTS_FOR_THIS_APPOINTMENT,
      onClose,

      // for mapProps
      petId,
      customerKey,
      itineraryId: getCurrentItinerary(state),

      // TODO: move this out of this connect fn into its own component
      alertMessages: (
        <div>
          {/*Customer Alerts*/}
          <HotelCheckInPetParentAlert />

          {/*Pet Alerts*/}
          <HotelCheckInSinglePetAlert petId={petId} />
        </div>
      ),
    };
  },
  dispatch => {
    return {
      checkInPet: ({ petId, itineraryId }) => {
        dispatch(
          patchHotelItineraryCheckInStatus({
            itineraryId,
            data: getChangeStatusPayload({
              petIds: [petId],
              status: itinerariesStatusList.CHECKED_IN,
            }),
            petId,
          }),
        );
      },
    };
  },
  (mapProps, dispatchProps) => {
    const {
      componentId,
      isContinueDisabled,
      header,
      message,
      onClose,
      alertMessages,
      petId,
      itineraryId,
    } = mapProps;
    const { checkInPet } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isContinueDisabled,
      header,
      message,
      onClose,
      alertMessages,

      // actions to pass to view
      onContinue: () => {
        // continue booking pet
        checkInPet({ petId, itineraryId });

        // close modal
        onClose();
      },
    };
  },
)(HotelCheckInOutAlertsModalComponent);

export const HotelCheckInMultiPetAlertsModal = connect(
  (state, { onClose }) => {
    const allPetIds = selectHotelPetsToCheckIn(state);
    const customerKey = getCurrentCustomerKey(state);

    return {
      componentId: "HotelCheckInMultiPetAlertsModal",
      isContinueDisabled: false,
      header: "Important to know",
      message: ALERTS_FOR_THIS_APPOINTMENT,
      onClose,

      // for mapProps
      allPetIds,
      customerKey,
      itineraryId: getCurrentItinerary(state),

      // TODO: move this out of this connect fn into its own component
      alertMessages: (
        <div>
          <HotelCheckInPetParentAlert />
          {allPetIds.map(petId => (
            <HotelCheckInSinglePetAlert key={petId} petId={petId} />
          ))}
        </div>
      ),
    };
  },
  dispatch => {
    return {
      checkInPet: ({ allPetIds, itineraryId }) => {
        dispatch(
          patchHotelItineraryCheckInStatus({
            itineraryId,
            data: getChangeStatusPayload({
              petIds: allPetIds,
              status: itinerariesStatusList.CHECKED_IN,
            }),
          }),
        );
      },
    };
  },
  (mapProps, dispatchProps) => {
    const {
      componentId,
      isContinueDisabled,
      header,
      message,
      onClose,
      alertMessages,
      allPetIds,
      itineraryId,
    } = mapProps;
    const { checkInPet } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isContinueDisabled,
      header,
      message,
      onClose,
      alertMessages,

      // actions to pass to view
      onContinue: () => {
        // continue booking pet
        checkInPet({ allPetIds, itineraryId });

        // close modal
        onClose();
      },
    };
  },
)(HotelCheckInOutAlertsModalComponent);
