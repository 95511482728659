import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

// Selectors
import { getCurrentPet, getIsSRCAgent } from "core/selectors/persistentSelectors";
import { createLoadingSelector } from "core/selectors/utils";
import {
  getHotelEngagementsPets,
  selectHotelPetsToCheckOut,
} from "@/dux/hotelEngagements/hotelEngagementSelectors";
import {
  selectIsPetStatusCheckedIn,
  selectIsLastToCheckOut,
  selectIsCheckoutToday,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import {
  getHotelItinerary,
  selectIsReservationDisabled,
} from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { getPrismCustomerHotelProfile } from "@/web/petParentProfile/selectors/getPrismCustomerProfileSelector";
import getPrismPetHotelProfile from "@/dux/petAlerts/selectors/PrismPetProfilesSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";

// Actions
import customersActionTypes from "core/actionTypes/customersActionTypes";
import { GET_HOTEL_ITINERARY } from "dux/hotelItinerary/hotelItineraryActions";
import {
  showCheckInOutModal,
  hideCheckInOutModal,
} from "@/core/actionCreators/checkInOutActionCreator";
import { showHotelCheckOutReminder } from "@/dux/checkOutReminder/checkOutReminderActions";
import { patchHotelCheckOutPets } from "@/dux/checkOut/checkOutActions";
import {
  showConfirmationModal,
  hideConfirmationModal,
} from "core/actionCreators/confirmationModalActionCreators";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { Button } from "@petsmart-ui/sparky";
import { HotelCheckoutDayAlert } from "dux/checkoutDayAlert/checkoutDayAlert";

// Utils
import { isMultiIndexList } from "core/utils/arrayUtils/indexManipulation";

// Constants
import { modalTypes } from "@/core/constants/checkInOutConstants";
import { checkoutDayAlert } from "dux/checkoutDayAlert/checkoutDayAlertConstants";

/**
 * React view component for hotel check out buttons
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name CheckOutPetsHotelButton
 * @param {Object} props
 * @param {string} props.componentId
 * @param {string} props.buttonLabel
 * @param {Boolean} props.isDisabled
 * @param {Boolean} props.isHidden
 * @param {Function} props.onClick
 * @returns {JSX.Element}
 * @example const container = connect()(CheckOutPetsHotelButton)
 */
export const CheckOutPetsHotelButton = props => {
  const { componentId, buttonLabel, isDisabled, isHidden, onClick = () => {} } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <Button variant="primary" onClick={onClick} disabled={isDisabled} text={buttonLabel} />
    </LayoutBox>
  );
};

/**
 * Redux Connect function for common props from state used in both check out button containers
 * @see {@link Views.Hotel.Itinerary.CheckOutSinglePetHotelButton} {@link Views.Hotel.Itinerary.CheckOutAllPetsHotelButton}
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelCheckOutButtonProps
 * @example const container = compose(HotelCheckOutButtonProps, connect())(component)
 */
const HotelCheckOutButtonProps = connect(
  state => {
    return {
      isSRCAgent: getIsSRCAgent(state),
      isItineraryLoading: createLoadingSelector([
        customersActionTypes.LOAD_CUSTOMER,
        GET_HOTEL_ITINERARY,
      ])(state),
      itineraryId: getHotelItinerary(state)?.itineraryId,
    };
  },
  dispatch => {
    const proceedToCheckOut = ({ petIds, itineraryId }) => {
      dispatch(patchHotelCheckOutPets({ petIds, itineraryId }));
    };

    const showCheckoutDayAlert = ({ petIds, itineraryId }) => {
      dispatch(hideConfirmationModal());
      // we don't want the second modal to pop immediately, delay so the continuity feels better
      setTimeout(() => {
        dispatch(
          showConfirmationModal({
            header: checkoutDayAlert.header,
            confirmText: checkoutDayAlert.proceed,
            cancelText: checkoutDayAlert.cancel,
            content: <HotelCheckoutDayAlert />,
            confirm: () => proceedToCheckOut({ petIds, itineraryId }),
            closeBeforeConfirm: true,
            cancel: () => dispatch(hideCheckInOutModal()),
          }),
        );
      }, 250);
    };

    const shouldShowCheckoutAlert = ({ petIds, itineraryId, isCheckoutToday }) =>
      isCheckoutToday
        ? proceedToCheckOut({ petIds, itineraryId })
        : showCheckoutDayAlert({ petIds, itineraryId });

    const showCheckOutReminder = ({ petIds, itineraryId, isCheckoutToday }) => {
      dispatch(
        showHotelCheckOutReminder({
          confirm: () => shouldShowCheckoutAlert({ petIds, itineraryId, isCheckoutToday }),
          cancel: () => dispatch(hideCheckInOutModal()),
        }),
      );
    };

    return {
      shouldShowCheckoutAlert,
      showCheckOutReminder,
    };
  },
);

/**
 * Redux Connect function for single pet check out button
 * @see {@link Views.Hotel.Itinerary.CheckOutPetsHotelButton}
 * @summary Located on the hotel check-out page
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name CheckOutSinglePetHotelButton
 * @returns {JSX.Element|null}
 * @example <CheckOutSinglePetHotelButton />
 */
export const CheckOutSinglePetHotelButton = compose(
  HotelCheckOutButtonProps,
  connect(
    (state, { isItineraryLoading, isSRCAgent }) => {
      const petId = getCurrentPet(state);
      const isCheckedIn = selectIsPetStatusCheckedIn(state, { petId });
      const isLastToCheckOut = selectIsLastToCheckOut([petId])(state);
      const customerKey = getCurrentCustomerKey(state);
      const selectedPrismHotelPetParent = getPrismCustomerHotelProfile(state, { customerKey });
      const selectedPrismHotelPet = getPrismPetHotelProfile(state, { petId });
      const isCheckoutToday = selectIsCheckoutToday(state, { petId });

      const petParentCheckOutAlert = selectedPrismHotelPetParent?.checkOutAlert;
      const petCheckOutAlert = selectedPrismHotelPet?.checkOutAlert;
      const hasCheckOutAlerts = petParentCheckOutAlert || petCheckOutAlert;

      return {
        componentId: "CheckOutSinglePetHotelButton",
        buttonLabel: isItineraryLoading ? "Loading..." : "Check-out",
        isDisabled: isItineraryLoading,
        isHidden: isSRCAgent || !isCheckedIn,
        isLastToCheckOut,
        hasCheckOutAlerts,
        petId,
        isCheckoutToday,
      };
    },
    dispatch => ({
      showCheckOutAlerts: () =>
        dispatch(showCheckInOutModal({ modalType: modalTypes.SHOW_HOTEL_CHECK_OUT_ALERTS })),
    }),
    (stateProps, dispatchProps, commonProps) => {
      const {
        buttonLabel,
        componentId,
        hasCheckOutAlerts,
        isDisabled,
        isHidden,
        isLastToCheckOut,
        petId,
        isCheckoutToday,
      } = stateProps;
      const { showCheckOutAlerts } = dispatchProps;
      const { itineraryId, shouldShowCheckoutAlert, showCheckOutReminder } = commonProps;

      const onClickHandler = isLastToCheckOut
        ? () => showCheckOutReminder({ petIds: [petId], itineraryId, isCheckoutToday })
        : () => shouldShowCheckoutAlert({ petIds: [petId], itineraryId, isCheckoutToday });
      const onClick = hasCheckOutAlerts ? showCheckOutAlerts : onClickHandler;

      return {
        componentId,
        buttonLabel,
        isDisabled,
        isHidden,
        onClick,
      };
    },
  ),
)(CheckOutPetsHotelButton);

/**
 * Redux Connect function for multi pet check out button
 * @see {@link Views.Hotel.Itinerary.CheckOutPetsHotelButton}
 * @summary Located on the hotel check-out page
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name CheckOutAllPetsHotelButton
 * @returns {JSX.Element|null}
 * @example <CheckOutAllPetsHotelButton />
 */
export const CheckOutAllPetsHotelButton = compose(
  HotelCheckOutButtonProps,
  connect(
    (state, { isItineraryLoading, isSRCAgent }) => {
      const allPetIds = getHotelEngagementsPets(state);
      const isMultiPet = isMultiIndexList(allPetIds);
      const isReservationDisabled = selectIsReservationDisabled(state);
      const petsToCheckOut = selectHotelPetsToCheckOut(state);
      const multiplePetsLeftToCheckOut = petsToCheckOut?.length > 1;

      const customerKey = getCurrentCustomerKey(state);
      const selectedPrismHotelPetParent = getPrismCustomerHotelProfile(state, { customerKey });
      const selectedPrismHotelPets = petsToCheckOut.map(petId =>
        getPrismPetHotelProfile(state, { petId }),
      );
      const allCheckingOutToday = petsToCheckOut.every(petId =>
        selectIsCheckoutToday(state, { petId }),
      );

      const petParentCheckOutAlert = selectedPrismHotelPetParent?.checkOutAlert;
      const petsCheckOutAlerts = selectedPrismHotelPets?.find(prismPet => prismPet?.checkOutAlert);
      const hasCheckOutAlerts = petParentCheckOutAlert || petsCheckOutAlerts;

      return {
        petsToCheckOut,
        componentId: "CheckOutAllPetsHotelButton",
        buttonLabel: isItineraryLoading ? "Loading..." : "Check-out all pets",
        isDisabled: isItineraryLoading,
        isHidden: !isMultiPet || isSRCAgent || !multiplePetsLeftToCheckOut || isReservationDisabled,
        hasCheckOutAlerts,
        isCheckoutToday: allCheckingOutToday,
      };
    },
    dispatch => ({
      showCheckOutAlerts: () =>
        dispatch(
          showCheckInOutModal({ modalType: modalTypes.SHOW_HOTEL_CHECK_OUT_ALERTS_ALL_PETS }),
        ),
    }),
    (stateProps, dispatchProps, commonProps) => {
      const {
        petsToCheckOut,
        buttonLabel,
        componentId,
        hasCheckOutAlerts,
        isDisabled,
        isHidden,
        isCheckoutToday,
      } = stateProps;
      const { showCheckOutAlerts } = dispatchProps;
      const { itineraryId, showCheckOutReminder } = commonProps;

      const onClickHandler = () =>
        showCheckOutReminder({ petIds: petsToCheckOut, itineraryId, isCheckoutToday });
      const onClick = hasCheckOutAlerts ? showCheckOutAlerts : onClickHandler;

      return {
        componentId,
        buttonLabel,
        isDisabled,
        isHidden,
        onClick,
      };
    },
  ),
)(CheckOutPetsHotelButton);
