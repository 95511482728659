/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const foodFrequencyActionTypes = {
  SET_FOOD_FREQUENCY: "SET_FOOD_FREQUENCY",
  CLEAR_FOOD_FREQUENCY: "CLEAR_FOOD_FREQUENCY",
  SET_FOOD_FREQUENCY_DATES: "SET_FOOD_FREQUENCY_DATES",
  FOOD_FREQUENCY_TOUCHED: "FOOD_FREQUENCY_TOUCHED",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setFoodFrequency = ({ petId, foodId, frequency }) => ({
  type: foodFrequencyActionTypes.SET_FOOD_FREQUENCY,
  petId,
  foodId,
  frequency,
});

export const clearFoodFrequency = ({ petId, foodId }) => ({
  type: foodFrequencyActionTypes.CLEAR_FOOD_FREQUENCY,
  petId,
  foodId,
});

export const setFoodFrequencyDates = ({ petId, foodId, dates }) => ({
  type: foodFrequencyActionTypes.SET_FOOD_FREQUENCY_DATES,
  petId,
  foodId,
  dates,
});

export const foodFrequencyTouched = ({ petId, foodId }) => ({
  type: foodFrequencyActionTypes.FOOD_FREQUENCY_TOUCHED,
  petId,
  foodId,
});
