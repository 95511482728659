import { omitBy } from "lodash/fp";

/**
 * A Utils function to do a shallow comparison of two objects and return the different properties between them
 *
 * @param {object} obj1 - Object Literal - Base object
 * @param {object} obj2 - Object Literal - Object to compare with
 * @returns {Object}
 */
export default function shallowDiff(obj1, obj2) {
  return omitBy((value, key) => obj1[key] === value)(obj2);
}
