import ganttActionTypes from "../actionTypes/ganttActionTypes";

export const associateClick = ({ isWeekly, groupMember, fromDashboard }) => ({
  type: ganttActionTypes.ASSOCIATE_CLICK,
  isWeekly,
  groupMember,
  fromDashboard
});

export default {
  associateClick
};
