import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

// Components
import { LayoutBox } from "@/layout/box/Box";
import AssociateDropdown from "../common/associateDropdown/AssociateDropdownContainer";
import AssociateSchedulingHeaderComponent from "../header/AssociateSchedulingHeaderContainer";
import AssociateSchedulingModals from "./modals/associateSchedulingModalsContainer";
import Button from "../common/commonButton";
import DatesRange from "../schedules/datesRangeContainer";
import FlexRow from "@/web/common/FlexRow";
import HeaderImage from "../../assets/images/bg-header-image.png";
import LoadingWrapper from "../common/LoadingWrapper";
import { AssociateSchedulingWeeklyTable } from "web/associateScheduling/weeklyTable/WeeklyTableComponent";

// Actions
import schedulesActionTypes from "../../core/actionTypes/schedulesActionTypes";
import {
  setStartWeekDate,
  setAssociateId,
  loadSchedules,
  clearSchedules,
  setSchedules,
  showSchedulesModal,
} from "../../core/actionCreators/schedulesActionCreators";
import { loadAbsenceReasons } from "../../core/actionCreators/absenceReasonsActionCreator";
import { loadAllSalons } from "../../core/actionCreators/salonsActionCreator";
import { loadAssociates } from "../../core/actionCreators/associateActionCreator";
import { loadSalonHours } from "../../core/actionCreators/salonHoursActionCreators";

// Selectors
import {
  getAssociateId,
  getStartWeekDate,
  getWeeklyEditActivities,
  getAllTimeRangesError,
  getWeeklyEditChanged,
  selectIsRelocationValid,
} from "../../core/selectors/schedulesSelectors";
import { getAssociatesByGroup } from "../../core/selectors/associatesSelectors";
import { getSalonHours } from "../../core/selectors/salonHoursSelector";
import { getSchedulesModalType } from "../../core/selectors/modals/schedulesModalSelectors";
import { getStoreNumber } from "../../core/selectors/persistentSelectors";

// Utils
import { isEmpty, get } from "lodash/fp";
import { history } from "@/dux/utils/browser/browserHistory";
import { formatCalendarDateMoment } from "@/core/utils/dateUtils/formatDateTime";
import { checkWeeklyErrors } from "./utils";
import { createLoadingSelector } from "../../core/selectors/utils";

// Constants
import { color } from "../common/styles/theme";
import { modalTypes } from "../../core/constants/associateSchedulingConstants";
import { pageNames } from "../../core/constants/checkInOutConstants";

/**
 *  React view component that allows associate to schedule
 *
 *  @summary Located on the "/associate-scheduling/:associateId" path in App.js.
 *  @memberOf Views.Associate
 *  @function
 *  @name AssociateSchedulingComponent
 *  @param { Object } props - props passed into the view component
 *  @param { String } props.associateId
 *  @param { String } props.componentId
 *  @param { Function } props.clearSchedules
 *  @param { Boolean } props.isLoading
 *  @param { Function } props.loadAbsenceReasons
 *  @param { Function } props.loadAllSalons
 *  @param { Function } props.loadAssociates
 *  @param { Function } props.loadSalonHours
 *  @param { Function } props.loadSchedules
 *  @param { Object } props.modalType
 *  @param { Object } props.router
 *  @param { Function } props.setAssociateId
 *  @param { Function } props.setSchedules
 *  @param { Function } props.showModal
 *  @param { String } props.startWeekDate
 *  @param { Object } props.storeHours
 *  @param { String } props.timeRangesError
 *  @param { Array } props.weeklyEditActivities
 *  @param { Boolean } props.weeklyEditChanged
 *  @param { Boolean } props.isRelocationValid
 *  @returns {JSX.Element}
 *  @example
 *
 *  <AssociateSchedulingComponent />
 */
const AssociateSchedulingComponent = props => {
  const {
    associateId,
    componentId,
    clearSchedules,
    isLoading,
    loadAbsenceReasons,
    loadAllSalons,
    loadAssociates,
    loadSalonHours,
    loadSchedules,
    modalType,
    router,
    setAssociateId,
    setSchedules,
    setStartWeekDate,
    showModal,
    startWeekDate,
    storeHours,
    timeRangesError,
    weeklyEditActivities,
    weeklyEditChanged,
    isRelocationValid,
  } = props;

  // Local state
  const [newStartWeekDate, setNewStartWeekDate] = useState("");
  const [newAssociateId, setNewAssociateId] = useState("");
  const [notificationMessage, setNotifcationMessage] = useState("");
  const unblock = useRef();

  // Call back handler
  const handleHistoryCallBack = ({ location }) => {
    const mainRoute = location.pathname.split("/")[1];

    if (
      mainRoute === "associate-scheduling" ||
      mainRoute === "template-scheduling" ||
      mainRoute === pageNames.FUTURE_APPOINTMENT
    ) {
      return true;
    }

    showModal(modalTypes.UNSAVE_CHANGES_MODAL_URL_CHANGE);
    return false;
  };

  // Component on mount
  useEffect(() => {
    let associateId = parseInt(router.params.associateId);

    loadAssociates({ isLimited: false });
    loadAllSalons();
    loadAbsenceReasons();

    if (!get(startWeekDate, storeHours)) {
      loadSalonHours({
        beginDate: startWeekDate,
        endDate: formatCalendarDateMoment(moment(startWeekDate).add(7, "days")),
      });
    }

    if (!isNaN(associateId)) {
      setAssociateId(associateId);
    }

    setStartWeekDate(formatCalendarDateMoment(moment(startWeekDate).startOf("week")));

    if (associateId && startWeekDate) {
      loadSchedules({ associateId, fromDate: startWeekDate });
    }
  }, []);

  // Component update
  useEffect(() => {
    if (!get(startWeekDate, storeHours)) {
      loadSalonHours({
        beginDate: startWeekDate,
        endDate: formatCalendarDateMoment(moment(startWeekDate).add(7, "days")),
      });
    }

    if (associateId && startWeekDate) {
      loadSchedules({ associateId, fromDate: startWeekDate });
    } else {
      clearSchedules();
    }
  }, [associateId, startWeekDate]);

  // Component update
  useEffect(() => {
    if (weeklyEditChanged) unblock.current = history.block(handleHistoryCallBack);
    if (!weeklyEditChanged && typeof unblock.current === "function") unblock.current();
  }, [weeklyEditChanged]);

  // Component unmount
  useEffect(() => {
    return () => typeof unblock.current === "function" && unblock.current();
  }, []);

  // Event handlers
  const handleChangeToTemplate = () => {
    if (weeklyEditChanged) {
      showModal(modalTypes.UNSAVE_CHANGES_MODAL_GO_TO_TEMPLATE);
      return;
    }
    history.push(`/template-scheduling/${associateId}`);
  };

  const handleDateChange = date => {
    setNewStartWeekDate(date);
    if (weeklyEditChanged) {
      showModal(modalTypes.UNSAVE_CHANGES_MODAL_DATE_CHANGE);
      return;
    }
    setStartWeekDate(date);
  };

  const handleAssociateChange = newChangedAssociateId => {
    setNewAssociateId(newChangedAssociateId);
    if (weeklyEditChanged) {
      showModal(modalTypes.UNSAVE_CHANGES_MODAL_ASSOCIATE_CHANGE);
      return;
    }
    setAssociateId(newChangedAssociateId);
    history.push(`/associate-scheduling/${newChangedAssociateId}`);
  };

  const handleSave = () => {
    const validationError = checkWeeklyErrors(weeklyEditActivities);

    if (validationError) {
      setNotifcationMessage(validationError);
      showModal(modalTypes.NOTIFICATION);
      return;
    }

    setSchedules({ associateId, startWeekDate, dayActivities: weeklyEditActivities });
  };

  const handleCancel = () => {
    if (associateId && startWeekDate) {
      loadSchedules({ associateId, fromDate: startWeekDate });
    }

    if (unblock.current) {
      unblock.current();
    }

    history.push("/schedules");
  };

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <AssociateSchedulingHeaderComponent backgroundImage={HeaderImage} />
      <LayoutBox style={{ padding: "40px" }}>
        <LayoutBox style={{ paddingBottom: "20px" }}>
          <LayoutBox style={{ fontSize: "18px", fontWeight: "bold" }} padding="scale-0">
            Associate
          </LayoutBox>
          <AssociateDropdown
            selectedAssociate={associateId}
            onSelectAssociate={newAssociateId => handleAssociateChange(newAssociateId)}
            includeAllSuitable={false}
          />
        </LayoutBox>
        <FlexRow style={{ justifyContent: "space-between" }}>
          <LayoutBox style={{ fontSize: "18px", fontWeight: "bold" }}>Weekly Schedule</LayoutBox>
          <DatesRange onChange={date => handleDateChange(date)} />
          <div>
            {associateId && (
              <span
                style={{
                  cursor: "pointer",
                  color: color.kalEl,
                }}
                onClick={() => {
                  handleChangeToTemplate();
                }}
              >
                Change Regular Schedule &nbsp;
                <img src={require("../../assets/icons/edit.svg")} />
              </span>
            )}
          </div>
        </FlexRow>

        {isEmpty(weeklyEditActivities) && (
          <LoadingWrapper isLoading={isLoading}>
            <LayoutBox style={{ minHeight: "500px" }} padding="scale-0" />
          </LoadingWrapper>
        )}

        {!isEmpty(weeklyEditActivities) && (
          <LayoutBox style={{ paddingBottom: "20px", paddingTop: "20px" }}>
            <AssociateSchedulingWeeklyTable
              associateId={associateId}
              startWeekDate={startWeekDate}
              readOnly={false}
            />
          </LayoutBox>
        )}

        {!isEmpty(weeklyEditActivities) && (
          <FlexRow style={{ justifyContent: "flex-end" }}>
            {timeRangesError && (
              <LayoutBox style={{ color: "red", alignSelf: "center" }} padding="scale-0">
                {timeRangesError}
              </LayoutBox>
            )}
            <Button
              width="100px"
              label="Cancel"
              onClick={() => handleCancel()}
              inverted
              style={{ marginLeft: "20px" }}
            />
            <Button
              disabled={timeRangesError || !weeklyEditChanged || isLoading || !isRelocationValid}
              width="100px"
              label="Save"
              onClick={() => handleSave()}
              style={{ marginLeft: "20px" }}
            />
          </FlexRow>
        )}
      </LayoutBox>

      {modalType && (
        <AssociateSchedulingModals
          modalType={modalType}
          newStartWeekDate={newStartWeekDate}
          newAssociateId={newAssociateId}
          associateId={associateId}
          notificationMessage={notificationMessage}
        />
      )}
    </LayoutBox>
  );
};

// Containers
const mapStateToProps = state => {
  const storeNumber = getStoreNumber(state);
  return {
    associateId: getAssociateId(state),
    componentId: "AssociateSchedulingContainer",
    startWeekDate: getStartWeekDate(state),
    weeklyEditActivities: getWeeklyEditActivities(state),
    weeklyEditChanged: getWeeklyEditChanged(state),
    isRelocationValid: selectIsRelocationValid(state),
    associatesByGroup: getAssociatesByGroup(state),
    modalType: getSchedulesModalType(state),
    timeRangesError: getAllTimeRangesError(state, { storeNumber, omitTemp: true }),
    salonHours: getSalonHours(state),
    isLoading: createLoadingSelector([
      schedulesActionTypes.LOAD_SCHEDULES,
      schedulesActionTypes.SET_SCHEDULES,
      schedulesActionTypes.LOAD_TEMPLATE_SCHEDULES,
      schedulesActionTypes.SET_TEMPLATE_SCHEDULES,
      schedulesActionTypes.LOAD_LUNCH_BREAK_TIMES,
    ])(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadAbsenceReasons: () => dispatch(loadAbsenceReasons()),
    loadAssociates: ({ isLimited }) => dispatch(loadAssociates({ isLimited })),
    loadAllSalons: () => dispatch(loadAllSalons()),
    setStartWeekDate: date => dispatch(setStartWeekDate(date)),
    setAssociateId: associateId => dispatch(setAssociateId(associateId)),
    loadSchedules: ({ associateId, fromDate }) =>
      dispatch(loadSchedules({ associateId, fromDate })),
    clearSchedules: () => dispatch(clearSchedules()),
    setSchedules: ({ associateId, startWeekDate, dayActivities }) =>
      dispatch(setSchedules({ associateId, startWeekDate, dayActivities })),
    showModal: modalType => dispatch(showSchedulesModal(modalType)),
    loadSalonHours: ({ beginDate, endDate }) => dispatch(loadSalonHours({ beginDate, endDate })),
  };
};

const AssociateSchedulingContainer = compose(
  withRouteProps,
  connect(mapStateToProps, mapDispatchToProps),
)(AssociateSchedulingComponent);

export default AssociateSchedulingContainer;
