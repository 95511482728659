import React from "react";
import LoadingWrapper from "../../common/LoadingWrapper";
import BookingPriceList from "./BookingAddonsPriceListContainer";

export default function BookingAddonsComponent(props) {
  return (
    <LoadingWrapper isLoading={props.isLoading}>
      {!props.isLoading && <BookingPriceList />}
    </LoadingWrapper>
  );
}
