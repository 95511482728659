import React from "react";
import { showConfirmationModal } from "@/core/actionCreators/confirmationModalActionCreators";
import { HotelCheckOutReminder } from "./checkOutReminder";
import { checkOutReminder } from "./checkOutReminderConstants";

/**
 * Wrapper action creator for hotel reminder confirmation modal
 * @memberof actions.hotel.itinerary
 * @param {Object} obj
 * @param {Function} obj.confirm - function to run on click of proceed button
 * @returns action
 */
export const showHotelCheckOutReminder = ({ confirm, cancel }) =>
  showConfirmationModal({
    header: checkOutReminder.header,
    confirmText: checkOutReminder.proceed,
    cancelText: checkOutReminder.cancel,
    content: <HotelCheckOutReminder />,
    confirm,
    cancel,
    closeBeforeConfirm: true,
  });
