import cloneDeep from "lodash/cloneDeep";
import { engagementTypeIds } from "@/web/setSystemType/constants/setSystemTypeConstants";
import {
  SET_ITINERARY_HITSORY_LIST_FILTERS,
  SET_ITINERARY_HISTORY_LIST_VISIBLE_FILTERS,
  SET_ITINERARY_HITSORY_LIST_FILTERS_TO_INIT,
} from "./itineraryHistoryListFilterButtonsActions";

const initialState = Object.keys(engagementTypeIds).reduce((obj, id) => {
  return {
    ...obj,
    [id]: {
      id,
      isPressed: false,
      filterValue: engagementTypeIds[id],
      isVisible: false,
    },
  };
}, {});

const itineraryHistoryFilterButtonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITINERARY_HITSORY_LIST_FILTERS:
      return { ...state, ...action.payload };
    case SET_ITINERARY_HISTORY_LIST_VISIBLE_FILTERS: {
      const newState = cloneDeep(state);
      Object.keys(newState).forEach(
        key => (newState[key].isVisible = action.payload.includes(newState[key].filterValue)),
      );
      return newState;
    }
    case SET_ITINERARY_HITSORY_LIST_FILTERS_TO_INIT:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default itineraryHistoryFilterButtonsReducer;
