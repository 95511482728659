// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pplt-layout__sidebar {
    /* ↓ Suppresses scrolling sometimes caused
    by the overflow */
    overflow: hidden;
}

.pplt-layout__sidebar > * {
/* ↓ The setSideBarSpace value; the first point on the modular scale */
--setSideBarSpace: 0;

/* ↓ Establish the flex context and allow wrapping */
display: flex;
flex-wrap: wrap;

/* ↓ Negate the margin to follow
(\`* -1\` makes the value negative) */
margin: calc(var(--setSideBarSpace) / 2 * -1);
}

.pplt-layout__sidebar > * > * {
/* ↓ Half margins meet to make the whole setSideBarSpace value together */
margin: calc(var(--setSideBarSpace) / 2);

/* ↓ Allow growing (needed for the vertical configuration) */
flex-grow: 1;
}

.pplt-layout__sidebar > * > :last-child {
/* ↓ Grow from nothing */
flex-basis: 0;

/* ↓ Ensure all the available setSideBarSpace is taken */
flex-grow: 999;

/* ↓ The value at which the configuration changes
between horizontal and vertical */
min-width: calc(50% - var(--setSideBarSpace));
}`, "",{"version":3,"sources":["webpack://./src/layout/sideBar/styles.css"],"names":[],"mappings":"AAAA;IACI;qBACiB;IACjB,gBAAgB;AACpB;;AAEA;AACA,sEAAsE;AACtE,oBAAoB;;AAEpB,oDAAoD;AACpD,aAAa;AACb,eAAe;;AAEf;mCACmC;AACnC,6CAA6C;AAC7C;;AAEA;AACA,yEAAyE;AACzE,wCAAwC;;AAExC,4DAA4D;AAC5D,YAAY;AACZ;;AAEA;AACA,wBAAwB;AACxB,aAAa;;AAEb,wDAAwD;AACxD,cAAc;;AAEd;iCACiC;AACjC,6CAA6C;AAC7C","sourcesContent":[".pplt-layout__sidebar {\n    /* ↓ Suppresses scrolling sometimes caused\n    by the overflow */\n    overflow: hidden;\n}\n\n.pplt-layout__sidebar > * {\n/* ↓ The setSideBarSpace value; the first point on the modular scale */\n--setSideBarSpace: 0;\n\n/* ↓ Establish the flex context and allow wrapping */\ndisplay: flex;\nflex-wrap: wrap;\n\n/* ↓ Negate the margin to follow\n(`* -1` makes the value negative) */\nmargin: calc(var(--setSideBarSpace) / 2 * -1);\n}\n\n.pplt-layout__sidebar > * > * {\n/* ↓ Half margins meet to make the whole setSideBarSpace value together */\nmargin: calc(var(--setSideBarSpace) / 2);\n\n/* ↓ Allow growing (needed for the vertical configuration) */\nflex-grow: 1;\n}\n\n.pplt-layout__sidebar > * > :last-child {\n/* ↓ Grow from nothing */\nflex-basis: 0;\n\n/* ↓ Ensure all the available setSideBarSpace is taken */\nflex-grow: 999;\n\n/* ↓ The value at which the configuration changes\nbetween horizontal and vertical */\nmin-width: calc(50% - var(--setSideBarSpace));\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
