import { createSelector } from "reselect";
import {
  getHotelEngagementsArray,
  selectAppliedAddonsFromEngagementsByPet,
  selectCurrentHotelPetService,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { getHotelPetSpecials } from "dux/specials/specialsSelectors";
import flattenArray from "@/core/utils/arrayUtils/flattenArray";
import isEmpty from "lodash/fp/isEmpty";
import {
  selectHotelCartPetAddons,
  selectHotelCartPetPrimaryServiceProduct,
} from "../servicesCartHotel/servicesCartHotelSelectors";

/**
 * Selector to get all manual specials from /specials/manual GET call
 * @memberOf Selectors.HotelManualSpecials
 * @function
 * @name selectHotelManualSpecials
 * @param {Object} state
 * @returns array of manual specials
 * @example selectHotelManualSpecials(state)
 */
export const selectHotelManualSpecials = state => state.hotelManualSpecials ?? [];

/**
 * Selector to get all codes for manual specials currently applied to products on the hotel itinerary
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name selectSelectedOptionsFromHotelEngagements
 * @param {Object} state
 * @param {Object} props
 * @param {string} props.petId
 * @returns an array of special codes
 * @example selectSelectedOptionsFromHotelEngagements(state, { petId })
 */
export const selectSelectedOptionsFromHotelEngagements = createSelector(
  [getHotelPetSpecials, selectAppliedAddonsFromEngagementsByPet],
  (serviceSpecials, addons) => {
    const serviceSpecialCodes = serviceSpecials
      .filter(({ isManual }) => isManual)
      .map(({ code }) => code);

    const addonSpecialCodes = addons.map(({ pricing } = {}) =>
      pricing?.specials?.filter(({ isManual }) => isManual)?.map(({ code } = {}) => code),
    );

    return flattenArray([...serviceSpecialCodes, ...addonSpecialCodes]);
  },
);

/**
 * Helper function to check if a manual special can be applied to a particular sku
 * @memberOf Utils.ManualSpecials
 * @function
 * @name checkIfManualSpecialApplies
 * @param {string} sku - the sku that we are looking for in appliesTo
 * @param {Object} appliesToObj
 * @param {Boolean} appliesToObj.appliesToAll - if true then there is no need to check appliesTo
 * @param {string[]} appliesToObj.appliesTo - an array of product numbers that this special can be applied to
 * @returns a boolean, if true then the given special can be applied to the given product
 * @example
 * const sku = addOn.pricing.sku // or petService.pricing.sku
 * const appliesObj = manualSpecial.appliesToSkus
 * checkIfManualSpecialApplies(sku, appliesObj);
 */
export const checkIfManualSpecialApplies = (sku, appliesToObj) => {
  const { appliesToAll = false, appliesTo = [] } = appliesToObj ?? {};
  const appliesToCurrent = appliesTo?.some(val => val?.toString() === sku?.toString());
  return appliesToAll || appliesToCurrent;
};

/**
 * Helper function to get a function that will return an array of manual specials that apply to a given product
 * @memberOf Utils.ManualSpecials
 * @function
 * @name getManualSpecialsFromCodes
 * @param {string} productNumber - the product number that we are looking for in appliesTo
 * @param {Object} obj
 * @param {Boolean} obj.appliesToAll - if true then there is no need to check appliesTo
 * @param {string[]} obj.appliesTo - an array of product numbers that this special can be applied to
 * @returns function to get array of specials for a given product sku
 * @example
 * // Where specialsToAdd is an array of special codes to be added and
 * // manualSpecials is the hotelManualSpecials in state
 * const getProductSpecials = getManualSpecialsFromCodes(specialsToAdd, manualSpecials);
 * // Use getProductSpecials to get new specials for each product
 * const specials = getProductSpecials(sku)
 */
export const getManualSpecialsFromCodes = (specialsToAdd = [], manualSpecials = []) => {
  return sku =>
    specialsToAdd.reduce((specials, code) => {
      const { appliesToSkus, ...manualSpecial } = manualSpecials.find(s => s.code === code) ?? {};
      const applies = checkIfManualSpecialApplies(sku, appliesToSkus);

      return applies ? [...specials, manualSpecial] : specials;
    }, []);
};

/**
 * Helper function to get the petService for the manual special patch req
 * @memberOf Utils.Engagement
 * @function
 * @name getPetServiceForSpecialsPatch
 * @param {object} petService - petService from an engagement
 * @param {function} getProductSpecials - a function that returns an array of specials for a given petService
 * @returns function to get array of specials for a given product
 * @example getPetServiceForSpecialsPatch(petService, getProductSpecials);
 */
export const getPetServiceForSpecialsPatch = (petService = {}, getProductSpecials) => {
  const { petServiceId, groupingId, quantity, pricing } = petService;
  const specials = getProductSpecials(pricing?.sku);
  return { petServiceId, groupingId, quantity, pricing: { specials } };
};

/**
 * Helper function to get the addOns for the manual special patch req
 * @memberOf Utils.Engagement
 * @function
 * @name getAddonsForSpecialsPatch
 * @param {object[]} addOns - array of addons from an engagement
 * @param {function} getProductSpecials - a function that returns an array of specials for a given addon sku
 * @returns array of addons for an engagement with new specials applied
 * @example getAddonsForSpecialsPatch(addOns, getProductSpecials);
 */
export const getAddonsForSpecialsPatch = (addOns = [], getProductSpecials) =>
  addOns?.map(({ addOnProductNumber, orderProductNumber, groupingId, quantity, pricing } = {}) => ({
    addOnProductNumber,
    orderProductNumber,
    groupingId,
    quantity,
    pricing: { specials: getProductSpecials(pricing?.sku) },
  }));

/**
 * Helper to filter out auto applied specials
 * @memberOf Utils.Engagement
 * @function
 * @name filterManualSpecials
 * @param {object} obj - petService or addon from an engagement
 * @param {object} obj.pricing - pricing obj from the service or addon from an engagement
 * @returns array of manual specials
 * @example filterManualSpecials(addon) // or filterManualSpecials(petService)
 */
export const filterManualSpecials = ({ pricing } = {}) =>
  pricing?.specials?.filter(special => special.isManual) ?? [];

/**
 * Helper to get the petService for a pet whose specials aren't changing
 * but who have specials to include in the manual specials patch
 * @memberOf Utils.Engagement
 * @function
 * @name formatPetServiceForUneditedPet
 * @param {object} petService
 * @returns a petService with only the attributes needed for the specials patch call or empty obj if no manual specials
 * @example formatPetServiceForUneditedPet(petService);
 */
export const formatPetServiceForUneditedPet = (petService = {}) => {
  const { petServiceId, groupingId, quantity, pricing } = petService;
  const specials = filterManualSpecials({ pricing });
  return specials.length ? { petServiceId, groupingId, quantity, pricing: { specials } } : {};
};

/**
 * Helper to format an addon for a pet whose specials aren't changing
 * but who have specials to include in the manual specials patch
 * @memberOf Utils.Engagement
 * @function
 * @name formatAddonForUneditedPet
 * @param {object} addon
 * @returns an addon with only the attributes needed for the specials patch call or empty obj if no manual specials
 * @example formatAddonForUneditedPet(addon);
 */
export const formatAddonForUneditedPet = (addon = {}) => {
  const { addOnProductNumber, orderProductNumber, groupingId, quantity, pricing } = addon;
  const specials = filterManualSpecials({ pricing });
  const newAddon = { addOnProductNumber, orderProductNumber, groupingId, quantity };
  return specials.length ? { ...newAddon, pricing: { specials } } : {};
};

/**
 * Helper to get the pets whose specials aren't changing but who have specials to include in the manual specials patch
 * @memberOf Utils.Engagement
 * @function
 * @name formatEngagementForUneditedPet
 * @param {object} engagement - an engagement
 * @returns an engagement with only the attributes needed for the specials patch call or empty obj if no manual specials
 * @example formatEngagementForUneditedPet(engagement);
 */
export const formatEngagementForUneditedPet = (engagement = {}) => {
  const { engagementId, addOns = [], petService = {} } = engagement;
  const newService = formatPetServiceForUneditedPet(petService);
  const newAddOns = addOns.reduce((arr, addon) => {
    const newAddon = formatAddonForUneditedPet(addon);
    return isEmpty(newAddon) ? arr : [...arr, newAddon];
  }, []);

  const hasServiceSpecials = !isEmpty(newService);
  const hasAddonSpecials = !!newAddOns.length;
  const hasSpecials = hasServiceSpecials || hasAddonSpecials;

  const newEngagement = { engagementId };
  if (hasServiceSpecials) newEngagement.petService = newService;
  if (hasAddonSpecials) newEngagement.addOns = newAddOns;

  return hasSpecials ? newEngagement : {};
};

/**
 * Filter the manual specials based on if they are applicable for the given products
 * @memberOf Utils.ManualSpecials
 * @function
 * @name filterSpecialsByProducts
 * @param {object[]} manualSpecials - array of manual specials
 * @param {string[]} skus- array product skus
 * @returns array of manual specials
 * @example filterSpecialsByProducts(manualSpecials, skus)
 */
export const filterSpecialsByProducts = (manualSpecials = [], skus = []) =>
  manualSpecials.filter(({ appliesToSkus } = {}) =>
    skus?.some(sku => !!checkIfManualSpecialApplies(sku, appliesToSkus)),
  );

/**
 * Selector to get applicable manual specials from /specials/manual GET call based on services cart state
 * @memberOf Selectors.HotelManualSpecials
 * @function
 * @name selectApplicableHotelManualSpecialsFromCart
 * @param {string} petId
 * @returns array of manual specials
 * @example selectApplicableHotelManualSpecialsFromCart(petId)(state)
 */
export const selectApplicableHotelManualSpecialsFromCart = petId =>
  createSelector(
    [
      selectHotelManualSpecials,
      selectHotelCartPetPrimaryServiceProduct(petId),
      selectHotelCartPetAddons(petId),
    ],
    (manualSpecials, petService, addons) => {
      const petServiceSku = petService?.sku;
      const addOnSkus = addons?.map(({ sku }) => sku) ?? [];
      return filterSpecialsByProducts(manualSpecials, [petServiceSku, ...addOnSkus]);
    },
  );

/**
 * Selector to get applicable manual specials from /specials/manual GET call based on itinerary state
 * @memberOf Selectors.HotelManualSpecials
 * @function
 * @name selectApplicableHotelManualSpecialsFromItinerary
 * @param {string} petId
 * @returns array of manual specials
 * @example selectApplicableHotelManualSpecialsFromItinerary(state, { petId })
 */
export const selectApplicableHotelManualSpecialsFromItinerary = createSelector(
  [
    selectHotelManualSpecials,
    selectCurrentHotelPetService,
    selectAppliedAddonsFromEngagementsByPet,
  ],
  (manualSpecials, petService, addons) => {
    const petServiceSku = petService?.pricing?.sku;
    const addOnSkus = addons?.map(({ pricing }) => pricing?.sku) ?? [];
    return filterSpecialsByProducts(manualSpecials, [petServiceSku, ...addOnSkus]);
  },
);
