/*
  Container for the Confirm button in the Check In/Out Page.
*/

import { connect } from "react-redux";
import moment from "moment";
import { APPOINTMENT_STATUS } from "../../core/constants/index";
import { pageNames } from "../../core/constants/checkInOutConstants";
import ConfirmAppointmentButton from "../common/commonButton";
import { updateEngagementStatus } from "../../core/actionCreators/engagementsActionCreators";
import getStoreDictionary from "../_helpers/generic/buttons/getStoreDictionary";
import { checkIfButtonShouldShow } from "../_helpers/generic/buttons/checkIfButtonsShouldshow";

const mapStateToProps = (state, ownProps) => {
  const dict = getStoreDictionary(state);

  return {
    isHidden: !checkIfButtonShouldShow({
      currentPage: dict.page,
      requiredPage: pageNames.CHECK_IN,
      petServiceItems: dict.petServiceItems,
      currentPetServiceItem: dict.currentPetServiceItem
    }),
    label: dict.isConfirmed ? "Confirmed" : "Confirm",
    disabled: dict.isConfirmed || dict.isFromOtherStore,

    // Not actually a param we are passing to the ConfirmAppointmentButton
    // but rather passing it to mapDispatchToProps through mergeProps
    currentPetServiceItem: dict.currentPetServiceItem
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchUpdateEngagementStatus: ({ currentPetServiceItem }) => {
    const { customer, engagement, itinerary } = currentPetServiceItem;

    dispatch(
      updateEngagementStatus({
        status: APPOINTMENT_STATUS.CONFIRMED,
        customerId: customer,
        itineraryId: itinerary,
        engagementId: engagement
      })
    );
  }
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () =>
    propsFromDispatch.dispatchUpdateEngagementStatus({
      currentPetServiceItem: propsFromState.currentPetServiceItem
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ConfirmAppointmentButton);
