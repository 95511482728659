import { connect } from "react-redux";

import {
  getAssociatesByGroup,
  getAssociatesOfCurrentStore
} from "../../../core/selectors/associatesSelectors";
import AssociateDropdownComponent from "./AssociateDropdownComponent";
import { associateGroupConstants } from "../../../core/constants/associatesConstants";

const mapStateToProps = state => {
  const associates = getAssociatesOfCurrentStore(state);
  return {
    associates,
    associatesByGroup: {
      [associateGroupConstants.ALL]: Object.values(associates),
      ...getAssociatesByGroup(state)
    }
  };
};

export default connect(mapStateToProps)(AssociateDropdownComponent);
