import React from "react";
import { connect } from "react-redux";
import {
  selectHotelBookingEndDate,
  selectHotelBookingStartDate,
} from "web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import moment from "moment";
import { Text, Layout } from "@prism/psm-ui-components";

const BookingCartTimeComponent = ({ isHidden, title, date, time }) => {
  if (isHidden) return;

  return (
    <Layout.Cluster justify="space-between" style={{ padding: "0 1.5em .5em" }}>
      <Layout.Cluster>
        <Text>{title}</Text>
        <Text style={{ paddingLeft: ".5em" }}>{date}</Text>
      </Layout.Cluster>
      <Text align="right">{time}</Text>
    </Layout.Cluster>
  );
};

export const HotelBookingCartCheckOutTime = connect(
  state => {
    const endDate = selectHotelBookingEndDate(state);

    return {
      date: moment(endDate).format("dddd, MMMM D, YYYY"),
      time: moment(endDate).format("h:mm A"),
      title: "Check-Out: ",
    };
  },
  dispatch => {
    return {};
  },
  (mapProps, dispatchProps) => {
    const { date, time, title } = mapProps;

    return {
      // control what props get passed to the view
      date,
      time,
      title,
    };
  },
)(BookingCartTimeComponent);

export const HotelBookingCartCheckInTime = connect(
  state => {
    const startDate = selectHotelBookingStartDate(state);

    return {
      date: moment(startDate).format("dddd, MMMM D, YYYY"),
      time: moment(startDate).format("h:mm A"),
      title: "Check-In: ",
    };
  },
  dispatch => {
    return {};
  },
  (mapProps, dispatchProps) => {
    const { date, time, title } = mapProps;

    return {
      // control what props get passed to the view
      date,
      time,
      title,
    };
  },
)(BookingCartTimeComponent);
