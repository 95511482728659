/**
 * Responsible for setting the Cursor style
 *
 * @export SetCursor
 * @param {boolean} { isLink, isStatic }
 * @returns
 */
export default function SetCursor({ isLink, isStatic }) {
  if (isLink) {
    return "pointer";
  }

  if (isStatic) {
    return "default";
  }

  return "inherit";
}
