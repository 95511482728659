import { connect } from "react-redux";
import { getActiveBundleAvailableUnits } from "../availableBundlesByPetSelectors";
import Text from "../../../_components/genericLayoutComponents/TextComponent";
import { getCurrentPetServiceId } from "../../../../core/selectors/cartSelectors";
import { getSelectedPet } from "../../../../core/selectors/bookingUISelectors";

const mapStateToProps = state => {
  const petId = getSelectedPet(state);
  const petServiceId = getCurrentPetServiceId(state, { petId });
  const units = getActiveBundleAvailableUnits(state, { petId, petServiceId });
  return {
    componentID: "bundle-available-units-remaining",
    text: `${units} Remaining`,
    styleType: "div",
    isHidden: false,
  };
};

export default connect(mapStateToProps)(Text);
