import { connect } from "react-redux";
import moment from "moment";
import { getSelectedPackageOffering } from "dux/bgm/bulkPackageOfferings/bulkPackageOfferingsSelectors";
import { formatLabel } from "dux/_components/bgm/availableBundlesByPet/availableBundlesList/_Utils";
import TextComponent from "dux/_components/genericLayoutComponents/TextComponent";

const mapStateToProps = state => {
  const { baseUnitsExpirationDate } = getSelectedPackageOffering(state);
  const formattedBaseUnitsExpirationDate = moment(baseUnitsExpirationDate).format("MM/DD/YYYY");
  return {
    isHidden: false,
    text: formatLabel("Base Services Expire", formattedBaseUnitsExpirationDate),
    styleType: "div",
    styleObj: {
      marginBottom: "0.5em",
    },
  };
};

export default connect(mapStateToProps)(TextComponent);
