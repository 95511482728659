import React, { useEffect } from "react";
import { connect } from "react-redux";

// Containers
import { HotelRoomManagementHeader } from "@/dux/hotelRoomManagementHeader/hoteRoomManagementHeader";
import { HotelManageRoomDetailsTable } from "@/dux/hotelManageRoomDetailsTable/hotelManageRoomDetailsTable";

// Components
import { Button, Layout, Text } from "@prism/psm-ui-components";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { HotelManageRoomSuspensionsHistoryModal } from "dux/hotelManageRoomDetailsSuspensionHistory/HotelManageRoomDetailsSuspensionHistory";

// Actions
import {
  getHotelManageRoomDetails,
  setHotelManageRoomNumber,
  setHotelManageRoomScheduleInactivation,
  GET_HOTEL_MANAGE_ROOM_DETAILS,
} from "./hotelManageRoomDetailsActions";
import { setHotelRoomSuspensionError } from "@/dux/hotelManageRoomDetailsTable/hotelManageRoomDetailsTableActions";

// Selectors
import {
  getHotelSelectedRoomDetails,
  selectHotelRoomManageSchuduleInactivation,
} from "./hotelManageRoomDetailsSelectors";

// Utils
import moment from "moment";
import { createLoadingSelector } from "@/core/selectors/utils";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

export const HotelManageRoomDetails = props => {
  const {
    componentId,
    clearScheduleSuspensionError,
    getRoomDetails,
    isHidden,
    isLoading,
    isNewScheduleInactivation,
    roomNumber,
    roomTypeSelected,
    setScheduleInactivation,
  } = props;

  useEffect(() => {
    getRoomDetails();
    // clean up any unsaved inactivation on flow update
    return () => setScheduleInactivation(false);
  }, []);

  const handleSchueduleInactivation = () => {
    setScheduleInactivation(true);
    clearScheduleSuspensionError();
  };

  return (
    <Layout.Box id={componentId}>
      <Layout.Stack space="stack-space-12">
        <HotelRoomManagementHeader />
        <LoadingWrapper displayContainer="block" isLoading={isLoading}>
          <Layout.Box
            padding="box-padding-6"
            borderWidth
            isHidden={isHidden}
            style={{ margin: "20px" }}
          >
            <Layout.Stack space="stack-space-4">
              <Layout.Cluster justify="space-between">
                <Text size="text-size-2xl">
                  {roomTypeSelected} {roomNumber}
                </Text>
                <Button
                  variant="prism-primary"
                  onClick={handleSchueduleInactivation}
                  disabled={isNewScheduleInactivation}
                >
                  <Text>Schedule Inactivation</Text>
                </Button>
              </Layout.Cluster>
              <HotelManageRoomDetailsTable />
            </Layout.Stack>
          </Layout.Box>
        </LoadingWrapper>
        {/* Modals */}
        <HotelManageRoomSuspensionsHistoryModal />
      </Layout.Stack>
    </Layout.Box>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const HotelManageRoomDetailsPage = withRouteProps(
  connect(
    (state, { router }) => {
      const roomNumber = router.params.room;
      const roomDetails = getHotelSelectedRoomDetails(state);
      const roomTypeSelected = roomDetails && Object.values(roomDetails)[0]?.roomTypeBucketName;
      const isNewScheduleInactivation = selectHotelRoomManageSchuduleInactivation(state);

      return {
        componentId: "HotelManageRoomDetailsPage",
        isLoading: createLoadingSelector([GET_HOTEL_MANAGE_ROOM_DETAILS])(state),
        isHidden: !roomDetails,
        isNewScheduleInactivation,
        roomNumber,
        roomTypeSelected,
      };
    },

    dispatch => {
      return {
        clearScheduleSuspensionError: () => dispatch(setHotelRoomSuspensionError([])),
        getRoomDetails: ({ roomNumber, fromDate, toDate }) => {
          dispatch(getHotelManageRoomDetails({ roomNumber, fromDate, toDate }));
          dispatch(setHotelManageRoomNumber(roomNumber));
        },
        setScheduleInactivation: (/** @type {boolean} */ inactivation) =>
          dispatch(setHotelManageRoomScheduleInactivation(inactivation)),
      };
    },
    (mapProps, dispatchProps) => {
      const {
        componentId,
        isHidden,
        isLoading,
        isNewScheduleInactivation,
        roomNumber,
        roomTypeSelected,
      } = mapProps;
      const {
        clearScheduleSuspensionError,
        getRoomDetails,
        setScheduleInactivation,
      } = dispatchProps;

      const fromDate = moment().format("YYYY-MM-DD");
      const toDate = moment(fromDate)
        .add(30, "days")
        .format("YYYY-MM-DD");

      return {
        componentId,
        clearScheduleSuspensionError,
        getRoomDetails: () => getRoomDetails({ roomNumber, fromDate, toDate }),
        isHidden,
        isLoading,
        isNewScheduleInactivation,
        roomNumber,
        roomTypeSelected,
        setScheduleInactivation,
      };
    },
  )(HotelManageRoomDetails),
);
