import { authorizeTokensAPI } from "core/services/systemAssociateAuth/_axiosConfigAuthorizeTokens";
import { endpointVersionString } from "core/utils/envUtils";

/**
 * Authorize a user
 * From the System Associate Auth API
 * - Authorize a user
 * @param {*} { accessToken, storeNumber }
 * @returns AxiosPromise
 */
export function authorizeUser({ accessToken, storeNumber }) {
  return authorizeTokensAPI.post(`${endpointVersionString()}/tokens/authorize`, {
    accessToken,
    storeNumber,
  });
}
