import React from "react";
import { Layout } from "@prism/psm-ui-components";

export const MockFlyoutContent = props => {
  const { componentId } = props;

  return (
    <Layout.Box padding="box-padding-5" style={{ height: "200px" }} id={componentId}>
      This is Mock FlyoutContent and will be replaced at a later date.
    </Layout.Box>
  );
};
