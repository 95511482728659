import React from "react";
import styled from "styled-components";

import TextComponent from "@/dux/_components/genericLayoutComponents/TextComponent";
import CommonButton from "@/web/common/commonButton";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 10px auto 0 auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonSpaceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 45%;
  margin-top: 1em;
`;

export default function RefundPackageErrorModalComponent({
  isHidden,
  componentID,
  headerText,
  messageText,
  onModalClose,
  onSubmit,
  cancelButtonLabel,
  confirmButtonLabel,
  isRefundPackageLoading,
}) {
  if (!isHidden) {
    return (
      <SectionWrapper id={componentID}>
        <TextContainer>
          <TextComponent
            text={headerText}
            styleObj={{ fontWeight: "bold", textAlign: "center", marginBottom: "1em" }}
          />
          <TextComponent
            type="div"
            text={messageText}
            styleObj={{ textAlign: "center", marginBottom: "1em" }}
          />
          <ButtonContainer>
            <ButtonSpaceWrapper>
              <CommonButton inverted label={cancelButtonLabel} onClick={onModalClose} />
              <CommonButton
                disabled={isRefundPackageLoading}
                label={confirmButtonLabel}
                onClick={onSubmit}
              />
            </ButtonSpaceWrapper>
          </ButtonContainer>
        </TextContainer>
      </SectionWrapper>
    );
  }

  return null;
}
