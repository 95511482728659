import { put, takeEvery, call, all, select } from "redux-saga/effects";
import { getStoreNumber } from "../../core/selectors/persistentSelectors";
import { fetchPetServicesAtStore } from "../services/systemServicesBooking/petServicesEndPoints";
import { fetchQuickQuoteServicesAtStore } from "../services/systemServicesBooking/quotesEndPoints";
import {
  setSelectedService,
  loadStoreServicesForPetRequest,
  loadStoreServicesForPetSuccess,
  loadStoreServicesForPetFailure,
  loadStoreServicesForQuickQuotePetRequest,
  loadStoreServicesForQuickQuotePetFailure,
  loadStoreServicesForQuickQuotePetSuccess,
} from "../actionCreators/servicesSelectionActionCreator";

import { loadPetSuccess } from "../actionCreators/petsActionCreators";
import servicesSelectionActionTypes from "../actionTypes/servicesSelectionActionTypes";

import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";
import {
  selectPetService,
  setStandalone,
  clearCart,
} from "../../core/actionCreators/ui/web/cartActionCreators";
import { loadAddOns } from "../../core/actionCreators/addOnsActionCreator";

import { waitForRequests } from "./utilsSaga";
import petsActionTypes from "../actionTypes/petsActionTypes";
import { getPetById } from "@/core/selectors/entitiesSelector";

function* onloadPetServicesAtStore({ customerKey, petId, isStandalone, fromDate }) {
  try {
    yield put(loadStoreServicesForPetRequest());
    const storeNumber = yield select(getStoreNumber);
    yield waitForRequests([petsActionTypes.UPDATE_PET]);
    const response = yield call(fetchPetServicesAtStore, {
      customerKey,
      storeNumber,
      petId: Number(petId),
      fromDate,
    });
    const petServiceDatas = response.data;
    const pet = yield select(getPetById, petId);
    yield put(
      loadPetSuccess({
        pet: {
          petId,
          ...pet,
          petServiceIds: petServiceDatas.map(petService => petService.petServiceId),
        },
      }),
    );

    if (isStandalone) {
      const standaloneService = petServiceDatas.find(service => service.isStandalone);

      if (standaloneService) {
        const { petServiceId } = standaloneService;
        yield put(setSelectedService({ petServiceId }));
        yield put(selectPetService({ petServiceId, petId }));
        yield put(setStandalone({ petId }));
        yield put(loadAddOns({ petServiceId }));
      }
    }
    yield put(
      loadStoreServicesForPetSuccess(normalizeArrayByProperty(petServiceDatas, "petServiceId")),
    );
  } catch (error) {
    yield put(loadStoreServicesForPetFailure({ error }));
  }
}

function* onloadQuickQuoteServicesAtStore({
  speciesId,
  breedId,
  birthDate,
  fromDate,
  isStandalone,
  petId,
}) {
  try {
    const storeNumber = yield select(getStoreNumber);
    yield put(loadStoreServicesForQuickQuotePetRequest());
    const response = yield call(fetchQuickQuoteServicesAtStore, {
      storeNumber,
      speciesId,
      breedId,
      dateOfBirth: birthDate,
      fromDate,
    });
    const storeServiceDatas = response.data;
    const pet = yield select(getPetById, petId);
    yield put(
      loadPetSuccess({
        pet: {
          petId,
          ...pet,
          petServiceIds: storeServiceDatas.map(petService => petService.petServiceId),
        },
      }),
    );
    yield put(
      loadStoreServicesForQuickQuotePetSuccess(
        normalizeArrayByProperty(storeServiceDatas, "petServiceId"),
      ),
    );
    yield put(clearCart());

    if (isStandalone) {
      const standaloneService = storeServiceDatas.find(service => service.isStandalone);

      if (standaloneService) {
        const { petServiceId } = standaloneService;
        yield put(setSelectedService({ petServiceId }));
        yield put(selectPetService({ petServiceId, petId }));
        yield put(setStandalone({ petId }));
        yield put(loadAddOns({ petServiceId }));
      }
    }
  } catch (error) {
    yield put(loadStoreServicesForQuickQuotePetFailure({ error }));
  }
}

function* watchloadPetServicesAtStore() {
  yield takeEvery(
    servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_PET,
    onloadPetServicesAtStore,
  );
}

function* watchloadQuickQuoteServicesAtStore() {
  yield takeEvery(
    servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET,
    onloadQuickQuoteServicesAtStore,
  );
}

export default function* serviceSelectionSaga() {
  yield all([watchloadPetServicesAtStore(), watchloadQuickQuoteServicesAtStore()]);
}
