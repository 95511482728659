import { connect } from "react-redux";
import TimeRangePickerToggle from "./TimeRangePickerToggle";
import { getPetServiceItem } from "../../../core/selectors/entitiesSelector";

const mapStateToProps = (state, ownProps) => {
  return {
    appointment: getPetServiceItem(state, {
      petServiceItemId: ownProps.petServiceItem.petServiceItemId
    })
  };
};

export default connect(mapStateToProps)(TimeRangePickerToggle);
