import { getState } from "@/core/selectors/commonSelector";
import { createSelector } from "reselect";

/**
 * Selector to get food options object
 * @memberOf Selectors.foodOptions
 * @function
 * @name selectFoodOptions
 * @param {Object} state - redux state
 * @returns {Object}
 * @example selectFoodOptions(state)
 */
export const selectFoodOptions = createSelector([getState], state => state?.foodOptions ?? {});

/**
 * Selector to get food options as array
 * @memberOf Selectors.foodOptions
 * @function
 * @name selectFoodList
 * @param {Object} state - redux state
 * @returns {Object[]}
 * @example selectFoodList(state)
 */
export const selectFoodList = createSelector(
  [selectFoodOptions],
  foodOptions => Object.values(foodOptions) ?? [],
);
