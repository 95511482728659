import customersActionTypes from "../actionTypes/customersActionTypes";

//* Customer
export const loadCustomer = ({ customerKey }) => ({
  type: customersActionTypes.LOAD_CUSTOMER,
  customerKey,
});

export const loadCustomerClearErrors = () => {
  return {
    type: customersActionTypes.LOAD_CUSTOMER_CLEAR_ERRORS,
  };
};

export const loadCustomerRequest = () => ({
  type: customersActionTypes.LOAD_CUSTOMER_REQUEST,
});

export const loadCustomerSuccess = ({ customer }) => ({
  type: customersActionTypes.LOAD_CUSTOMER_SUCCESS,
  payload: customer,
});

export const loadCustomerFailure = ({ error }) => ({
  type: customersActionTypes.LOAD_CUSTOMER_FAILURE,
  error,
});

export const updateCustomer = ({ customerKey, data }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER,
  customerKey,
  data,
});

export const updateAndLoadCustomer = ({ data, customerKey }) => ({
  type: customersActionTypes.UPDATE_AND_LOAD_CUSTOMER,
  data,
  customerKey,
});

export const updateCustomerRequest = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_REQUEST,
});

export const updateCustomerSuccess = ({ customer }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_SUCCESS,
  payload: customer,
});

export const updateCustomerFailure = ({ error }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_FAILURE,
  error,
});

export const createCustomer = ({ data }) => ({
  type: customersActionTypes.CREATE_CUSTOMER,
  data,
});

export const createCustomerRequest = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_REQUEST,
});

export const createCustomerSuccess = ({ customer }) => ({
  type: customersActionTypes.CREATE_CUSTOMER_SUCCESS,
  payload: customer,
});

export const createCustomerFailure = ({ error }) => ({
  type: customersActionTypes.CREATE_CUSTOMER_FAILURE,
  error,
});

export const createCustomerPhone = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_PHONE,
});

export const createCustomerPhoneRequest = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_PHONE_REQUEST,
});

export const createCustomerPhoneSuccess = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_PHONE_SUCCESS,
});

export const createCustomerPhoneError = ({ error }) => ({
  type: customersActionTypes.CREATE_CUSTOMER_PHONE_FAILURE,
  error,
});

export const updateCustomerPhone = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_PHONE,
});

export const updateCustomerPhoneRequest = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_PHONE_REQUEST,
});

export const updateCustomerPhoneSuccess = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_PHONE_SUCCESS,
});

export const updateCustomerPhoneError = ({ error }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_PHONE_FAILURE,
  error,
});

export const createCustomerEmail = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_EMAIL,
});

export const createCustomerEmailRequest = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_EMAIL_REQUEST,
});

export const createCustomerEmailSuccess = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_EMAIL_SUCCESS,
});

export const createCustomerEmailError = ({ error }) => ({
  type: customersActionTypes.CREATE_CUSTOMER_EMAIL_FAILURE,
  error,
});

export const deleteCustomerEmail = ({ customerKey, emailAddressId }) => ({
  type: customersActionTypes.DELETE_CUSTOMER_EMAIL,
  customerKey,
  emailAddressId,
});

export const deleteCustomerEmailRequest = () => ({
  type: customersActionTypes.DELETE_CUSTOMER_EMAIL_REQUEST,
});

export const deleteCustomerEmailSuccess = () => ({
  type: customersActionTypes.DELETE_CUSTOMER_EMAIL_SUCCESS,
});

export const deleteCustomerEmailError = ({ error }) => ({
  type: customersActionTypes.DELETE_CUSTOMER_EMAIL_FAILURE,
  error,
});

export const updateCustomerEmail = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_EMAIL,
});

export const updateCustomerEmailRequest = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_EMAIL_REQUEST,
});

export const updateCustomerEmailSuccess = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_EMAIL_SUCCESS,
});

export const updateCustomerEmailError = ({ error }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_EMAIL_FAILURE,
  error,
});

export const updateCustomerEmailOptedOut = ({ customerKey, isEmailOptedOut }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_EMAIL_OPTED_OUT,
  customerKey,
  isEmailOptedOut,
});

export const updateCustomerEmailOptedOutRequest = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_EMAIL_OPTED_OUT_REQUEST,
});

export const updateCustomerEmailOptedOutSuccess = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_EMAIL_OPTED_OUT_SUCCESS,
});

export const updateCustomerEmailOptedOutError = ({ error }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_EMAIL_OPTED_OUT_FAILURE,
  error,
});

export const createCustomerAddress = ({ customerKey, data }) => ({
  type: customersActionTypes.CREATE_CUSTOMER_ADDRESS,
  customerKey,
  data,
});

export const createCustomerAddressRequest = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_ADDRESS_REQUEST,
});

export const createCustomerAddressSuccess = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_ADDRESS_SUCCESS,
});

export const createCustomerAddressError = ({ error }) => ({
  type: customersActionTypes.CREATE_CUSTOMER_ADDRESS_FAILURE,
  error,
});

export const updateCustomerAddress = ({ customerKey, addressId, data }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_ADDRESS,
  customerKey,
  addressId,
  data,
});

export const updateCustomerAddressRequest = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_ADDRESS_REQUEST,
});

export const updateCustomerAddressSuccess = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_ADDRESS_SUCCESS,
});

export const updateCustomerAddressError = ({ error }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_ADDRESS_FAILURE,
  error,
});

export const deleteCustomerAddress = ({ customerKey, addressId }) => ({
  type: customersActionTypes.DELETE_CUSTOMER_ADDRESS,
  customerKey,
  addressId,
});

export const deleteCustomerAddressRequest = () => ({
  type: customersActionTypes.DELETE_CUSTOMER_ADDRESS_REQUEST,
});

export const deleteCustomerAddressSuccess = () => ({
  type: customersActionTypes.DELETE_CUSTOMER_ADDRESS_SUCCESS,
});

export const deleteCustomerAddressError = ({ error }) => ({
  type: customersActionTypes.DELETE_CUSTOMER_ADDRESS_FAILURE,
  error,
});

export const updateCustomerProfile = ({ customerKey, formValues, isFormFieldHidden }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_PROFILE,
  customerKey,
  formValues,
  isFormFieldHidden,
});

export const updateCustomerIsPreferredContact = ({ customerKey, data }) => ({
  type: customersActionTypes.UPDATE_IS_PREFERRED_CONTACT,
  customerKey,
  data,
});

export const updateCustomerProfileRequest = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_PROFILE_REQUEST,
});

export const updateCustomerProfileSuccess = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_PROFILE_SUCCESS,
});

export const updateCustomerProfileError = ({ error }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_PROFILE_FAILURE,
  error,
});

export const updateCustomerContactSettings = ({ customerKey, data }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_CONTACT_SETTINGS,
  customerKey,
  data,
});

export const updateCustomerContactSettingsRequest = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_CONTACT_SETTINGS_REQUEST,
});

export const updateCustomerContactSettingsSuccess = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_CONTACT_SETTINGS_SUCCESS,
});

export const updateCustomerContactSettingsFailure = ({ error }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_CONTACT_SETTINGS_FAILURE,
  error,
});

//* Customers
export const loadCustomers = searchValue => ({
  type: customersActionTypes.LOAD_CUSTOMERS,
  searchValue,
});

export const loadCustomersRequest = () => ({
  type: customersActionTypes.LOAD_CUSTOMERS_REQUEST,
});

export const loadCustomersSuccess = ({ customers = {} }) => ({
  type: customersActionTypes.LOAD_CUSTOMERS_SUCCESS,
  payload: customers,
});

export const loadCustomersFailure = ({ error }) => ({
  type: customersActionTypes.LOAD_CUSTOMERS_FAILURE,
  error,
});

export const setCustomerActive = ({ customerKey }) => ({
  type: customersActionTypes.SET_CUSTOMER_ACTIVE,
  customerKey,
});

export const setCustomerActiveRequest = () => ({
  type: customersActionTypes.SET_CUSTOMER_ACTIVE_REQUEST,
});

export const setCustomerActiveSuccess = () => ({
  type: customersActionTypes.SET_CUSTOMER_ACTIVE_SUCCESS,
});

export const setCustomerActiveFailure = () => ({
  type: customersActionTypes.SET_CUSTOMER_ACTIVE_FAILURE,
});

export const setCustomerInactive = ({ customerKey }) => ({
  type: customersActionTypes.SET_CUSTOMER_INACTIVE,
  customerKey,
});

export const setCustomerInactiveRequest = () => ({
  type: customersActionTypes.SET_CUSTOMER_INACTIVE_REQUEST,
});

export const setCustomerInactiveSuccess = () => ({
  type: customersActionTypes.SET_CUSTOMER_INACTIVE_SUCCESS,
});

export const setCustomerInactiveFailure = () => ({
  type: customersActionTypes.SET_CUSTOMER_INACTIVE_FAILURE,
});

export const createCustomerPreferences = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_PREFERENCES,
});
export const createCustomerPreferencesRequest = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_PREFERENCES_REQUEST,
});

export const createCustomerPreferencesSuccess = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_PREFERENCES_SUCCESS,
});

export const createCustomerPreferencesFailure = () => ({
  type: customersActionTypes.CREATE_CUSTOMER_PREFERENCES_FAILURE,
});

export const updateCustomerPreferences = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_PREFERENCES,
});

export const updateCustomerPreferencesRequest = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_PREFERENCES_REQUEST,
});

export const updateCustomerPreferencesSuccess = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_PREFERENCES_SUCCESS,
});

export const updateCustomerPreferencesFailure = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_PREFERENCES_FAILURE,
});

export const updateCustomerAlerts = ({ customerKey, data }) => ({
  type: customersActionTypes.UPDATE_CUSTOMER_ALERTS,
  customerKey,
  data,
});

export const updateCustomerAlertsRequest = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_ALERTS_REQUEST,
});

export const updateCustomerAlertsSuccess = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_ALERTS_SUCCESS,
});

export const updateCustomerAlertsFailure = () => ({
  type: customersActionTypes.UPDATE_CUSTOMER_ALERTS_FAILURE,
});

export default {
  loadCustomer,
  loadCustomerRequest,
  loadCustomerSuccess,
  loadCustomerFailure,
  loadCustomerClearErrors,
  updateCustomer,
  updateCustomerRequest,
  updateCustomerSuccess,
  updateCustomerFailure,
  updateAndLoadCustomer,
  loadCustomers,
  loadCustomersRequest,
  loadCustomersSuccess,
  loadCustomersFailure,
  createCustomer,
  createCustomerRequest,
  createCustomerFailure,
  createCustomerSuccess,
  createCustomerPhone,
  createCustomerPhoneRequest,
  createCustomerPhoneSuccess,
  createCustomerPhoneError,
  updateCustomerPhone,
  updateCustomerPhoneRequest,
  updateCustomerPhoneSuccess,
  updateCustomerPhoneError,
  createCustomerEmail,
  createCustomerEmailRequest,
  createCustomerEmailSuccess,
  createCustomerEmailError,
  updateCustomerEmail,
  updateCustomerEmailRequest,
  updateCustomerEmailSuccess,
  updateCustomerEmailError,
  deleteCustomerEmail,
  deleteCustomerEmailRequest,
  deleteCustomerEmailSuccess,
  deleteCustomerEmailError,
  updateCustomerEmailOptedOut,
  updateCustomerEmailOptedOutRequest,
  updateCustomerEmailOptedOutSuccess,
  updateCustomerEmailOptedOutError,
  createCustomerAddress,
  createCustomerAddressRequest,
  createCustomerAddressSuccess,
  createCustomerAddressError,
  updateCustomerAddress,
  updateCustomerAddressRequest,
  updateCustomerAddressSuccess,
  updateCustomerAddressError,
  deleteCustomerAddress,
  deleteCustomerAddressRequest,
  deleteCustomerAddressSuccess,
  deleteCustomerAddressError,
  updateCustomerProfile,
  updateCustomerProfileRequest,
  updateCustomerProfileSuccess,
  updateCustomerProfileError,
  updateCustomerContactSettings,
  updateCustomerContactSettingsRequest,
  updateCustomerContactSettingsSuccess,
  updateCustomerContactSettingsFailure,
  updateCustomerIsPreferredContact,
  setCustomerActive,
  setCustomerActiveRequest,
  setCustomerActiveSuccess,
  setCustomerActiveFailure,
  setCustomerInactive,
  setCustomerInactiveRequest,
  setCustomerInactiveSuccess,
  setCustomerInactiveFailure,
  createCustomerPreferences,
  createCustomerPreferencesRequest,
  createCustomerPreferencesSuccess,
  createCustomerPreferencesFailure,
  updateCustomerPreferences,
  updateCustomerPreferencesRequest,
  updateCustomerPreferencesSuccess,
  updateCustomerPreferencesFailure,
  updateCustomerAlerts,
  updateCustomerAlertsRequest,
  updateCustomerAlertsSuccess,
  updateCustomerAlertsFailure,
};
