// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-qty-modifier__input {
  font-size: 1rem;
  height: 2.5em;
  width: 2.5em;
  text-align: center;
  margin: 1em;
  border: 1px solid darkGrey;
}
`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/composites/QuantityModifier/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".psm-qty-modifier__input {\n  font-size: 1rem;\n  height: 2.5em;\n  width: 2.5em;\n  text-align: center;\n  margin: 1em;\n  border: 1px solid darkGrey;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
