import { frequencyConstants } from "@/dux/frequency/FrequencyConstants";
import { setFrequencyDates } from "@/dux/frequency/modal/FrequencySelection";

/**
 * If frequancy is manual then return the passed in dates otherwise calculate a new dates array
 * @param {String} frequency - the frequency value
 * @param {Array} dates - an array of date strings to be used if frequency is set to manual
 * @param {Array} appointmentDates - An array of date strings, should contain every day from check in to check out
 * @returns an array of date strings
 */
export const getFrequencyDates = (frequency, dates, appointmentDates) =>
  frequency === frequencyConstants.MANUAL ? dates : setFrequencyDates(frequency, appointmentDates);

/**
 * Determines which frequency and dates to use based on if pending values are present and what the frequency value is
 * @param {String} param.frequency - the frequency value
 * @param {Array} param.frequencyDates - An array of date strings, should contain every day from check in to check out
 * @param {String} param.pendingFrequency - the pending frequency value
 * @param {Array} param.pendingDates - An array of date strings, to use instead of frequencyDates if pendingFrequency was provided
 * @param {Array} param.appointmentDates - An array of date strings, should contain every day from check in to check out
 * @param {String} param.defaultFrequency - Optional param to specify which frequency should be default
 * @returns An object containing the new frequency and dates
 */
export const getFrequencyAndDates = ({
  frequency,
  frequencyDates,
  pendingFrequency,
  pendingDates,
  appointmentDates,
  defaultFrequency = frequencyConstants.MANUAL,
} = {}) => {
  const newFrequency = pendingFrequency || frequency || defaultFrequency;
  const dates = pendingFrequency ? pendingDates : frequencyDates;
  const newDates = getFrequencyDates(newFrequency, dates, appointmentDates);

  return {
    frequency: newFrequency,
    dates: newDates ?? [],
  };
};
