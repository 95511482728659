import { selectedDateActions } from "dux/selectedDate/selectedDateActions";
import commonActionTypes from "../../../actionTypes/ui/web/commonActionTypes";

const initialState = {
  selectedAppointment: "",
  selectedAssociate: "",
  selectedAssociateWeekly: "",
  associateId: "",
  associateJobCode: "",
  selectedTime: "",
  rebookErrorMessages: [],
  weeklyGanttDate: "",
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonActionTypes.SELECT_APPOINTMENT:
      return {
        ...state,
        selectedAppointment: action.appointmentId,
      };

    case commonActionTypes.SELECT_ASSOCIATE:
      return {
        ...state,
        selectedAssociate: action.associateId,
      };

    case commonActionTypes.SELECT_ASSOCIATE_WEEKLY:
      return {
        ...state,
        selectedAssociateWeekly: action.associateId,
      };

    case commonActionTypes.ASSOCIATE_CLICK:
      return {
        ...state,
        associateId: Number(action.associateId),
      };

    case commonActionTypes.SELECT_TIME:
      return {
        ...state,
        selectedTime: action.selectedTime,
      };
    case commonActionTypes.WEEK_NUMBER:
      return {
        ...state,
        weekNumber: action.weekNumber,
      };

    case commonActionTypes.SET_REBOOK_ERROR_MESSAGES:
      return {
        ...state,
        rebookErrorMessages: action.errorMessages,
      };

    case commonActionTypes.CLEAR_REBOOK_ERROR_MESSAGES:
      return {
        ...state,
        rebookErrorMessages: [],
      };

    case commonActionTypes.SET_WEEKLY_GANTT_DATE:
      return {
        ...state,
        weeklyGanttDate: action.date,
      };

    case commonActionTypes.CLEAR_WEEKLY_GANTT_DATE:
      return {
        ...state,
        weeklyGanttDate: "",
      };

    default:
      return state;
  }
};

export default commonReducer;
