import bookingActionTypes from "../../actionTypes/bookingActionTypes";
import modalInitState from "./_modalInitState";
import getUpdatedModalState from "./helpers/getUpdatedModalState";

const initialState = modalInitState();

/**
 * Reducer for Booking Modals
 *
 * @export bookingModalsReducer
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {string}
 */
export default function bookingModalsReducer(state = initialState, action) {
  return getUpdatedModalState(
    state,
    initialState,
    bookingActionTypes.SHOW_BOOKING_MODAL,
    bookingActionTypes.HIDE_BOOKING_MODAL,
    action
  );
}
