import React from "react";
import { LayoutBox } from "@/layout/box/Box";
import { Button, Heading, TextPassage } from "@petsmart-ui/sparky";
import { connect } from "react-redux";
import { hideBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { LayoutStack } from "@/layout/stack/Stack";
import {
  ADDONS_UNAVAILABLE,
  FOODS_UNAVAILABLE,
  MEDS_UNAVAILABLE,
  SUGGEST_SIMILAR,
} from "./rebookingItemsNotAvailableConstants";
import { selectRebookingNotAvailableAddons } from "../hotelEngagements/hotelEngagementSelectors";
import { selectItineraryDiscontinuedFoods } from "@/web/food/foodsSelector";
import { selectItineraryDiscontinuedMeds } from "@/web/medication/medicationsSelector";
import { selectHotelBookingPetList } from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { hideCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";

export const RebookingItemsNotAvailable = props => {
  const { componentId, isHidden, onClick, unavailableItems } = props;

  if (isHidden) return null;
  return (
    <LayoutBox id={componentId}>
      <LayoutStack style={{ alignItems: "center" }}>
        {unavailableItems?.map(({ header, items }) => {
          if (!items.length) return null;
          return (
            <LayoutBox key={header}>
              <LayoutStack style={{ alignItems: "center" }}>
                <Heading tagName="h3" size="headline">
                  {header}
                </Heading>

                {items?.map(({ id, name }) => (
                  <Heading tagName="span" size="body-lg-bold" key={id}>
                    {name}
                  </Heading>
                ))}
              </LayoutStack>
            </LayoutBox>
          );
        })}

        <LayoutBox>
          <TextPassage size="lg">{SUGGEST_SIMILAR}</TextPassage>
        </LayoutBox>

        <LayoutBox>
          <Button text="Okay" variant="primary" onClick={onClick} />
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

export const HotelRebookAddonNotAvailable = connect(
  state => {
    const addons = selectRebookingNotAvailableAddons(state);
    return {
      componentId: "HotelRebookAddonNotAvailable",
      unavailableItems: [
        {
          items: addons?.map(addon => ({ id: addon?.addOnProductNumber, name: addon?.addOnName })),
          header: ADDONS_UNAVAILABLE,
        },
      ],
    };
  },
  dispatch => {
    return {
      onClick: () => dispatch(hideBookingModal()),
    };
  },
)(RebookingItemsNotAvailable);

export const HotelRebookFoodsNotAvailable = connect(
  state => {
    const petIds = selectHotelBookingPetList(state);

    return {
      componentId: "HotelRebookFoodsNotAvailable",
      unavailableItems: [
        { items: selectItineraryDiscontinuedFoods(state, { petIds }), header: FOODS_UNAVAILABLE },
      ],
    };
  },
  dispatch => {
    return {
      onClick: () => dispatch(hideBookingModal()),
    };
  },
)(RebookingItemsNotAvailable);

export const HotelCheckInFoodsMedsNotAvailable = connect(
  (state, { modalProps: { petIds } = {} }) => {
    return {
      componentId: "HotelCheckInFoodsMedsNotAvailable",
      unavailableItems: [
        { items: selectItineraryDiscontinuedFoods(state, { petIds }), header: FOODS_UNAVAILABLE },
        { items: selectItineraryDiscontinuedMeds(state, { petIds }), header: MEDS_UNAVAILABLE },
      ],
    };
  },
  dispatch => {
    return {
      onClick: () => dispatch(hideCheckInOutModal()),
    };
  },
)(RebookingItemsNotAvailable);

export const HotelRebookMedsNotAvailable = connect(
  state => {
    const petIds = selectHotelBookingPetList(state);

    return {
      componentId: "HotelRebookMedsNotAvailable",
      unavailableItems: [
        { items: selectItineraryDiscontinuedMeds(state, { petIds }), header: MEDS_UNAVAILABLE },
      ],
    };
  },
  dispatch => {
    return {
      onClick: () => dispatch(hideBookingModal()),
    };
  },
)(RebookingItemsNotAvailable);
