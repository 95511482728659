import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import HistoryImage from "assets/icons/history.svg";
import getAppointmentMetaDataBySelectedPet from "core/selectors/pet/getAppointmentMetaDataBySelectedPet";
import { onSelectAppointment } from "core/actionCreators/ui/web/commonActionCreators";
import { showCheckInOutModal } from "core/actionCreators/checkInOutActionCreator";
import { modalTypes } from "core/constants/modalConstants";
import { getHotelItinerary } from "dux/hotelItinerary/hotelItinerarySelectors";

const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const HistoryIcon = styled.img`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 1.1em;
  cursor: pointer;
  margin-left: 5px;
`;

const TextInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;
`;

export const AppointmentInfo = ({ appointmentId, handleHistoryPopover, componentId, label }) => {
  return (
    <InfoLine id={componentId}>
      <TextInfo id={`${componentId}__textInfo`}>
        {`${label} # ${appointmentId}`}
        <HistoryIcon
          id={`${componentId}__icon--history`}
          src={HistoryImage}
          onClick={handleHistoryPopover}
        />
      </TextInfo>
    </InfoLine>
  );
};

// SALON APPOINTMENT INFO CONTAINER ----------------------------------------------------------------------------------------
export const SalonAppointmentInfo = connect(
  state => {
    const { petServiceItemId } = getAppointmentMetaDataBySelectedPet(state);

    return {
      componentId: "SalonAppointmentInfo",
      label: "Appointment",
      appointmentId: petServiceItemId,
    };
  },

  dispatch => {
    return {
      dispatchOnShowAppointmentHistory: ({ petServiceItemId }) => {
        dispatch(onSelectAppointment({ appointmentId: petServiceItemId }));
        dispatch(showCheckInOutModal({ modalType: modalTypes.SALON_APPOINTMENT_HISTORY }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const { componentId, label, appointmentId } = mapProps;
    const { dispatchOnShowAppointmentHistory } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      label,
      appointmentId,

      // actions to pass to view
      handleHistoryPopover: e => {
        e.stopPropagation();

        if (appointmentId) {
          dispatchOnShowAppointmentHistory({ petServiceItemId: appointmentId });
        }
      },
    };
  },
)(AppointmentInfo);

// HOTEL APPOINTMENT INFO CONTAINER ----------------------------------------------------------------------------------------
export const HotelAppointmentInfo = connect(
  state => {
    const itinerary = getHotelItinerary(state);

    return {
      componentId: "HotelAppointmentInfo",
      label: "Reservation",
      appointmentId: itinerary?.itineraryId,
    };
  },

  dispatch => {
    return {
      dispatchOnShowAppointmentHistory: ({ petServiceItemId }) => {
        // dispatch(onSelectAppointment({ appointmentId: petServiceItemId }));
        dispatch(showCheckInOutModal({ modalType: modalTypes.HOTEL_APPOINTMENT_HISTORY }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const { componentId, label, appointmentId } = mapProps;
    const { dispatchOnShowAppointmentHistory } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      label,
      appointmentId,

      // actions to pass to view
      handleHistoryPopover: e => {
        e.stopPropagation();

        if (appointmentId) {
          dispatchOnShowAppointmentHistory({ itineraryId: appointmentId });
        }
      },
    };
  },
)(AppointmentInfo);
