import { connect } from "react-redux";
import { getSearchResultsToDisplay } from "@/core/selectors/searchSalonSRCSelector";
import { searchConstants } from "@/core/constants/searchConstants";
import { getVetSearchResultsList } from "@/core/selectors/vetsSelectors";
import PetVetCardListComponent from "@/web/searchSalonSRC/petVetCard/PetVetCardListComponent";

const mapStateToProps = state => ({
  isHidden: getSearchResultsToDisplay(state) !== searchConstants.VET,
  searchResults: getVetSearchResultsList(state),
});

export default connect(mapStateToProps)(PetVetCardListComponent);
