/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
/**
 * @memberOf actions.hotel.itinerary
 * @constant
 * type {String}
 */
export const GET_HOTEL_INVOICE = "GET_HOTEL_INVOICE";

/**
 * @memberOf actions.hotel.itinerary
 * @constant
 * type {String}
 */
export const GET_HOTEL_INVOICE_REQUEST = "GET_HOTEL_INVOICE_REQUEST";

/**
 * @memberOf actions.hotel.itinerary
 * @constant
 * type {String}
 */
export const GET_HOTEL_INVOICE_SUCCESS = "GET_HOTEL_INVOICE_SUCCESS";

/**
 * @memberOf actions.hotel.itinerary
 * @constant
 * type {String}
 */
export const GET_HOTEL_INVOICE_FAILURE = "GET_HOTEL_INVOICE_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 * Get hotel invoice action
 * @memberOf actions.hotel.itinerary
 * @function
 * @name getHotelInvoice
 * @returns {Object}
 */
export const getHotelInvoice = () => ({
  type: GET_HOTEL_INVOICE,
});

/**
 * Hotel invoice request action
 * @memberOf actions.hotel.itinerary
 * @function
 * @name getHotelInvoiceRequest
 * @returns {Object}
 */
export const getHotelInvoiceRequest = () => ({
  type: GET_HOTEL_INVOICE_REQUEST,
});

/**
 * Hotel invoice success action
 * @memberOf actions.hotel.itinerary
 * @function
 * @name getHotelInvoiceSuccess
 * @returns {Object}
 */
export const getHotelInvoiceSuccess = () => ({
  type: GET_HOTEL_INVOICE_SUCCESS,
});

/**
 * Hotel invoice failure action
 * @memberOf actions.hotel.itinerary
 * @function
 * @name getHotelInvoiceFailure
 * @param {Object} error
 * @returns {Object}
 * @example getHotelInvoiceFailure(error)
 */
export const getHotelInvoiceFailure = error => ({
  type: GET_HOTEL_INVOICE_FAILURE,
  error,
});
