import { connect } from "react-redux";
import { getBundleName } from "../availableBundlesByPetSelectors";
import { getSelectedPet } from "../../../../core/selectors/bookingUISelectors";
import { getCurrentPetServiceId } from "../../../../core/selectors/cartSelectors";
import Text from "../../../_components/genericLayoutComponents/TextComponent";

const mapStateToProps = state => {
  const petId = getSelectedPet(state);
  const petServiceId = getCurrentPetServiceId(state, { petId });
  return {
    isHidden: false,
    componentID: "bundle-package-name",
    text: getBundleName(state, { petId, petServiceId }),
    styleObj: { marginRight: "1em", fontWeight: "bold" },
    styleType: "div",
  };
};

export default connect(mapStateToProps)(Text);
