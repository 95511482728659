import moment from "moment";
import getDateRangeFromTo from "dux/utils/dates/getDateRange";
import { getTrainingClassSessions } from "dux/trainingClassSessions/actions";

export default function calcAndDispatchRange(
  dispatch,
  { MomentObj, cachedMonthsList, storeNumber },
) {
  const currentMonth = moment(MomentObj).format("MM YY");

  // clone so we dont' try to modify the TrainingClassCachedMonths state
  const cachedMonths = [...cachedMonthsList];

  // moment().month() uses 0 based index, so add one to get current month.
  if (!cachedMonths.includes(currentMonth)) {
    const { fromDate, toDate } = getDateRangeFromTo(MomentObj, currentMonth);

    // add the the month # into a list of months (cache api calls).
    cachedMonths.push(currentMonth);
    dispatch(getTrainingClassSessions({ storeNumber, fromDate, toDate, cachedMonths }));
  }
}
