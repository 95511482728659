import { connect } from "react-redux";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "../setSystemType/selectors/setSystemTypeSelectors";
import BookingMedicationComponent from "../medication/BookingMedicationComponent";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { medicationTypes } from "../newMedication/newMedicationConstants";
import { selectMedicationsList } from "@/dux/medications/medicationsSelectors";
import { getMedicationsList } from "@/dux/medications/medicationActions";
import isEmpty from "lodash/isEmpty";

const mapStateToProps = (state, { petId }) => {
  const systemFlow = getSystemBookingFlow(state);
  const medicationList = selectMedicationsList(state);
  return {
    petId: petId || getCurrentPet(state),
    isHidden: systemFlow !== systemName.HOTEL,
    locationType: medicationTypes.BOOKING,
    medicationList,
    topLine: false,
    title: "",
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMedicationsList: () => dispatch(getMedicationsList()),
});

const mergeProps = (propsFromState, propsFromDispatch) => {
  const { petId, isHidden, locationType, topLine, title, medicationList } = propsFromState;
  const { fetchMedicationsList } = propsFromDispatch;

  return {
    petId,
    isHidden,
    locationType,
    topLine,
    title,
    loadMedicationsList: () => {
      if (isHidden) return false;
      if (isEmpty(medicationList)) fetchMedicationsList();
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BookingMedicationComponent);
