import { connect } from "react-redux";
import { getVetSearchParams } from "@/core/selectors/vetsSelectors";
import vetsActionCreators from "@/core/actionCreators/vetsActionCreators";
import petProfileActionCreators from "@/core/actionCreators/petProfileActionCreators";
import { modalTypes } from "@/core/constants/petProfileConstants";
import PetVetCard from "@/web/petParentProfile/pets/petVets/petVetsSearchResults/PetVetCard";

const mapStateToProps = state => ({
  isHidden: false,
  searchParams: getVetSearchParams(state),
});

const mapDispatchToProps = dispatch => ({
  navigate: () => dispatch(petProfileActionCreators.showPetProfileModal(modalTypes.ADD_VET)),
  createVet: data => {
    dispatch(
      vetsActionCreators.createVet({
        formValues: data,
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PetVetCard);
