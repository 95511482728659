// @ts-check
// Hotel Manage Room Details Table Selectors

/**
 *
 * @param {object} state
 * @param {object} [state.hotelRoomMangementTable]
 * @param {Object[]} [state.hotelRoomMangementTable.error]
 * @param {string} [state.hotelRoomMangementTable.error.message]
 * @returns Errors for any new or modify room suspensions records
 */
export const selectHotelRoomSuspensionError = (state) =>
  state?.hotelRoomMangementTable?.error?.[0]?.message;
