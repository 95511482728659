import React from "react";
import { Layout } from "@prism/psm-ui-components";
import QuickViewWrapperContainer from "../quickViewWrapper/quickViewWrapperContainer";
import ResultListContainer from "../resultList/resultListContainer";
import SearchFieldCustomerContainer from "dux/searchField/SearchFieldCustomerContainer";

const Results = () => (
  <Layout.Box style={{width: "100%"}} className="searchResultsWrapper__resultsCol">
    <ResultListContainer className="searchResultsWrapper__resultListContainer" />
  </Layout.Box>
);

export default function SearchResultsWrapper({ searchField, hasResults }) {

  const SearchField = searchField

  return (
    <Layout.Stack space="stack-space-4" style={{ width: "850px", margin: "3em auto 0 auto" }}>
      <Layout.Box style={{width: "100%"}} className="searchResultsWrapper__searchFieldPlusWrapper">
        <SearchField />
      </Layout.Box>
      {hasResults ? <Results className="searchResultsWrapper__results" /> : null}
      <Layout.Box className="searchResultsWrapper__quickViewCol">
        <QuickViewWrapperContainer className="searchResultsWrapper__quickViewWrapperContainer" />
      </Layout.Box>
    </Layout.Stack>
  );
}
