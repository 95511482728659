import { connect } from "react-redux";
import HeaderImage from "../../assets/images/hotel-int-header-image.png";
import AppHeader from "web/header/AppHeader";

// Hotel Service Overbooking Management header container ----------------------------------------------------------------------------------------
export const PetsHotelAdminHeader = connect(state => {

  return {
    componentId: "PetsHotelAdminHeader",
    title: "Pets Hotel Admin",
    backgroundImage: HeaderImage,
  };
})(AppHeader);
