import React from "react";
import { LayoutCluster } from "@/layout/culster/Cluster";

export default function AvailableBundlesDateListHeaderComponent({ label, componentID }) {
  return (
    <LayoutCluster
      data-testid="AvailableBundlesDateListHeaderComponent"
      id={componentID}
      space="scale-0"
      style={{ justifyContent: "space-between" }}
    >
      {label}
    </LayoutCluster>
  );
}
