import { connect } from "react-redux";
import { setPetServiceItem } from "core/actionCreators/checkInOutActionCreator";
import { hideDashboardModal } from "core/actionCreators/ui/web/dashboardActionCreators";
import { setCurrentCustomerKey } from "core/actionCreators/customer/customerActionCreator";
import { getSelectedAppointment, getSelectedAssociate } from "core/selectors/commonSelector";
import { getPetServiceItem } from "core/selectors/entitiesSelector";
import {
  onSelectAssociate,
  onSelectAppointment,
} from "core/actionCreators/ui/web/commonActionCreators";
import { selectPet } from "core/actionCreators/bookingActionCreators";
import PetCardComponent from "./PetCardComponent";

const mapStateToProps = (state, ownProps) => {
  const petServiceItem = getPetServiceItem(state, { petServiceItemId: ownProps.appointmentID });
  const selectedAppointment = getSelectedAppointment(state);
  const selectedAssociate = getSelectedAssociate(state);
  return {
    isSelectedAppointment: selectedAppointment
      ? ownProps.appointmentID === selectedAppointment
      : petServiceItem.associate === selectedAssociate,
    associateId: petServiceItem.associate,
    customerKey: petServiceItem.customer,
    itineraryId: petServiceItem.itinerary,
    status: petServiceItem.status,
    selectedPet: petServiceItem.pet,
    componentId: `${ownProps.index}_PetCard__${petServiceItem.pet}`,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchOnSelectAssociate: ({ associateId }) => {
    dispatch(onSelectAssociate({ associateId }));
  },
  dispatchOnSelectAppointment: ({ appointmentId }) => {
    dispatch(onSelectAppointment({ appointmentId }));
  },

  setPetServiceItem: petServiceItem => dispatch(setPetServiceItem({ petServiceItem })),
  setCurrentCustomerKey: currentCustomerKey =>
    dispatch(setCurrentCustomerKey({ currentCustomerKey })),
  hideModal: () => dispatch(hideDashboardModal()),
  selectPet: petId => dispatch(selectPet(petId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PetCardComponent);
