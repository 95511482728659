import axios from "axios";
import {
  handleError,
  saveAuthenticatedSubscriptionKeyToPersistentState,
} from "core/services/systemAssociateAuth/interceptors";

/**
 * Axios Create configuration for authorize tokens API
 * Even though this is the same configuration as _axiosConfigAuth.js,
 * we are creating a new instance of Axios in order to add a response
 * interceptor to set the Authenticated Subscription Key (ASK) ONLY for the tokens/authorize endpoint
 * since that is where it is returned.
 */
export const authorizeTokensAPI = axios.create({
  baseURL: `${window.env.BASE_URL}${window.env.AUTH_URI}`,
  headers: {
    "Ocp-Apim-Subscription-Key": window.env.UNAUTHENTICATED_SUBSCRIPTION_KEY,
  },
});

authorizeTokensAPI.interceptors.response.use(
  saveAuthenticatedSubscriptionKeyToPersistentState,
  handleError,
);
