import { createSelector } from "reselect";
import { has, find, flow, get } from "lodash/fp";
import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";
import { getPet } from "./entitiesSelector";
import { getPetHealthConditionsList } from "./enumsSelectors";

/**
 * Selector to get Pet Health Conditions
 *
 * @memberOf Entities
 * @type {OutputSelector<[((function(*, *): *)|*)], unknown, (...args: SelectorResultArray<[((function(*, *): *)|*)]>) => {clearCache: () => void}, GetParamsFromSelectors<[((function(*, *): *)|*)]>> & {clearCache: () => void}}
 */
export const getPetHealthConditions = createSelector(
  [getPet],
  pet => (pet && pet.petConditions) || [],
);

export const getPetHealthConditionsFormData = createSelector(
  [getPetHealthConditionsList, getPetHealthConditions],
  (conditionsList, petHealthConditions) => {
    return (
      petHealthConditions &&
      conditionsList
        .filter(condition => condition.IsActive)
        .map(({ Description, ConditionId }) => ({
          Description,
          ConditionId: ConditionId.toString(),
        }))
    );
  },
);

export const getPetHealthConditionsInitialValues = createSelector(
  [getPetHealthConditionsList, getPetHealthConditions],
  (conditionsList, petHealthConditions) => {
    const normalizedPetHealthConditions = normalizeArrayByProperty(
      petHealthConditions,
      "conditionId",
    );
    return (
      petHealthConditions &&
      conditionsList
        .filter(condition => condition.IsActive)
        .reduce(
          (acc, { ConditionId }) => ({
            ...acc,
            [ConditionId]: has(ConditionId, normalizedPetHealthConditions),
          }),
          {},
        )
    );
  },
);

export const getCommaDelimitedPetHealthConditions = createSelector(
  [getPetHealthConditionsList, getPetHealthConditions],
  (conditionsList, petHealthConditions) => {
    const normalizedPetHealthConditions = normalizeArrayByProperty(
      petHealthConditions,
      "conditionId",
    );
    return (
      petHealthConditions &&
      conditionsList
        .filter(condition => condition.IsActive)
        .reduce((acc, { Description, ConditionId }) => {
          const petHasCondition = has(ConditionId, normalizedPetHealthConditions);
          return petHasCondition ? `${acc && `${acc}, `}${Description}` : acc;
        }, "")
    );
  },
);

/**
 * The condition object contains both "conditionId" and "petConditionId"...
 * The delete health condition API requires the send the "petConditionId"
 * where as the post health condition API requires to send the "conditionId".
 */
export const getPetConditionIdByConditionId = createSelector(
  [getPetHealthConditions, (_, props) => props],
  (petHealthConditions, { conditionId }) => {
    return flow([find({ conditionId }), get("petConditionId")])(petHealthConditions);
  },
);
