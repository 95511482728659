import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { get } from "lodash/fp";
import BookCheckInButtonContainer from "../petCheckInOut/buttons/bookCheckInButtonContainer";
import CheckInButtonContainer from "../petCheckInOut/buttons/checkInButtonContainer";
import { pageNames } from "../../core/constants/checkInOutConstants";
import Button from "../common/commonButton";

const CartFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  & > *:not(:first-child) {
    margin-left: 0.3125rem;
  }
`;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.3125rem;
`;

const PriceAndActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const CartFooterComponent = ({
  totalPrice,
  footerActions = [],
  multiplePets,
  pet,
  petHasExpiredVaccination,
  isServiceFound,
  isSRCAgent,
}) => {
  const location = useLocation();
  const pathname = get("pathname", location);

  return (
    <CartFooter>
      <PriceAndActions>
        {totalPrice !== undefined && (
          <Price>
            <div>Total price {multiplePets && "for all pets"}</div>
            <div>${totalPrice && totalPrice.toFixed(2)}</div>
          </Price>
        )}
        <Actions>
          {footerActions.map((action, index) => {
            return (
              !action.hide && (
                <Button
                  componentId={`button_cart_footer_${action?.label.toLowerCase()}`}
                  key={index}
                  label={action?.label}
                  disabled={
                    // Need to disable schedule button if no pet service item exists
                    action.disabled || (action?.label === "Schedule" && isServiceFound === false)
                  }
                  onClick={action.action}
                  inverted={action.inverted}
                  width="174px"
                  height="49px"
                />
              )
            );
          })}
          {
            pathname &&
            pathname.includes("standalone") &&
            pathname.includes("select-time-slot") &&
            !isSRCAgent &&
            (
              <BookCheckInButtonContainer label={pageNames.BOOK_AND_CHECK_IN} />
            )
          }
          {pathname && pathname.includes("check-in") && (
            <CheckInButtonContainer label={pageNames.CHECK_IN} />
          )}
        </Actions>
      </PriceAndActions>

      {petHasExpiredVaccination && <div>Note, {pet.petName} has expired vaccinations</div>}
    </CartFooter>
  );
};

export default CartFooterComponent;
