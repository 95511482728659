import dispalyFeatureFlagIconConstants from "web/displayFeatureFlagIcon/displayFeatureFlagIconConstants";

/**
 * Sets the value of the Persistent feature flag cookie
 *
 * @export setPersistFeatureFlagCookie
 * @param {string } - url Params
 * @returns {Boolean} - the value the cooke has been set to.
 */
export default function setPersistFeatureFlagCookie(urlParams) {
  if (urlParams.includes(dispalyFeatureFlagIconConstants.PERSIST_FEATURE_FLAG_PARAM)) {
    document.cookie = dispalyFeatureFlagIconConstants.PERSIST_FEATURE_FLAG_PARAM;
    return true;
  }

  if (urlParams.includes(dispalyFeatureFlagIconConstants.REMOVE_PERSIST_FEATURE_FLAG_PARAM)) {
    if (document.cookie.split(";").filter(item => item.trim().startsWith("persistFF=")).length) {
      document.cookie = dispalyFeatureFlagIconConstants.REMOVE_PERSIST_FEATURE_FLAG_PARAM;
    }
  }

  return false;
}
