import * as React from 'react';
import { psx } from '../../../utils/';

export interface SpacerProps extends React.ComponentPropsWithoutRef<'span'> {
  /** The pixel size of your Spacer component. */
  size: number;
  /** Changes the orientation of this component for vertical and horizontal spacing. */
  axis?: 'vertical' | 'horizontal';
  /** Display types that may impact how the spacer behaves. */
  display?: 'block' | 'inline-block';
  /** Used to turn on a background color so that the spacer is visible for debugging. */
  debug?: boolean;
  /** Completely removes the component from the DOM. Useful for feature flags or t hide or show
   * a component. */
  isHidden?: boolean;
}

/**
 * This component should also rarely be used. The primary use-case for the spacer component is in constructing
 * primatives like buttons with icons.
 *
 * General layout should be handled with components using explicit spacing layout tokens such as the `<Box/>`
 * and the `<Stack/>`.
 *
 * [Source](https://www.joshwcomeau.com/react/modern-spacer-gif/)
 */
export const Spacer: React.FunctionComponent<SpacerProps> = ({
  display = 'inline-block',
  axis = 'horizontal',
  size = 24,
  debug,
  isHidden = false,
  ...props
}) => {
  const width = axis === 'vertical' ? 1 : size;
  const height = axis === 'horizontal' ? 1 : size;
  const classNames = psx('psm', 'psm-layout__spacer');

  if (isHidden) {
    return null;
  }

  return (
    <span
      className={classNames}
      style={{
        backgroundColor: debug ? 'hotpink' : 'none',
        minWidth: `${width}px`,
        minHeight: `${height}px`,
        height: `${height}px`,
        width: `${width}px`,
        display,
      }}
      {...props}
    />
  );
};
