import { createSelector } from "reselect";
import { get } from "lodash/fp";
import { getPetParentProfile } from "../../petParentProfileSelectors";

// Example of use:  getDisplayAddPetParentAddressForm(state);
const getDisplayAddPetParentAddressForm = createSelector([getPetParentProfile], profile =>
  get("displayAddPetParentAddressForm", profile)
);

export default getDisplayAddPetParentAddressForm;
