import { connect } from "react-redux";
import SmallPillButton from "../../../_components/buttons/SmallPillButton";
import { getHotelOvernightFilterBy } from "./hotelOvernightPetServiceSelectors";
import {
  filterByAllOvernight,
  filterByAttentionNeededOvernight,
} from "./hotelOvernightFilterButtonActions";

const mapStateToProps = state => {
  const filterBy = getHotelOvernightFilterBy(state);

  return {
    isHidden: false,
    componentID: "HotelOvernightAttentionNeededBtn",
    label: "Attention Needed",
    selected: filterBy.attentionNeeded,
    disabled: false,
    filterBtnStatus: filterBy.attentionNeeded,
    isAllOthersSelected: filterBy.unassignedPet,
  };
};

const mapDispatchToProps = dispatch => ({
  handleFilterByAttentionNeededOvernight: ({ filterBtnStatus, isAllOthersSelected }) => {
    if (filterBtnStatus) {
      // toggle to off
      dispatch(filterByAttentionNeededOvernight({ filterBtnStatus: !filterBtnStatus }));

      // set all other buttons to false;
      dispatch(filterByAllOvernight({ filterBtnStatus: false }));
    } else {
      // toggle to on
      dispatch(filterByAttentionNeededOvernight({ filterBtnStatus: !filterBtnStatus }));

      if (isAllOthersSelected) {
        // set all other buttons to true;
        dispatch(filterByAllOvernight({ filterBtnStatus: true }));
      }
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleClick: () =>
    propsFromDispatch.handleFilterByAttentionNeededOvernight({
      filterBtnStatus: propsFromState.filterBtnStatus,
      isAllOthersSelected: propsFromState.isAllOthersSelected,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SmallPillButton);
