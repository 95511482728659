import { put, takeEvery, call, all, select, takeLeading } from "redux-saga/effects";
import moment from "moment";

import { getPetEligibilityRequest } from "dux/eligibility/actions/eligibilityPetActions";
import { getStoreNumber } from "../selectors/persistentSelectors";
import { getSelectedPet, getSelectedItinerary } from "../selectors/bookingUISelectors";
import {
  getPetServiceItem,
  getItinerary,
  getEngagement,
  getServicesForPet,
} from "../selectors/entitiesSelector";
import itinerariesActionCreators from "../actionCreators/itinerariesActionCreators";
import { createEngagement } from "../actionCreators/engagementsActionCreators";
import { updatePetServiceItem } from "../actionCreators/petServiceItemsActionCreators";
import bookingActionCreators, {
  showBookingModal,
  bookAppointment,
} from "../actionCreators/bookingActionCreators";
import bookingActionTypes from "../actionTypes/bookingActionTypes";
import { fetchPetEligibility } from "../services/systemServicesBooking/petEligibilityEndPoints";
import {
  fetchAvailableTimeSlots,
  fetchAvailableTimeSlotsByEngagement,
} from "../services/systemServicesBooking/availibilityEndPoints";
import { onFinalizeItinerary } from "./itinerariesSaga";
import { modalTypes, ADDITIONAL_BOOKING_TYPE_NONE } from "../constants/bookingConstants";

import { createErrorSelector } from "../selectors/utils";
import itinerariesActionTypes from "../actionTypes/itinerariesActionTypes";
import { clearNewAppointmentData } from "../actionCreators/ui/web/dashboardActionCreators";
import {
  getSelectedAddOns,
  getSelectedEnhancedServices,
  getSelectedPetServiceItem,
  getMaxCountInfo,
  getIsBoarded,
  getAppointmentNote,
  getLockToAssociate,
  getMaxPerBlockOrCheckReductionReason,
  selectAdditionalBookingType,
} from "../selectors/cartSelectors";
import { getEligibilityResults } from "dux/eligibility/selectors/eligibilitySelectors";
import { loadEligibilityResults } from "dux/eligibility/actions/eligibilityLoadClearActions";
import { formatMoment } from "../utils/dateUtils/formatDateTime";
import { showCheckInOutButtonModal } from "../actionCreators/checkInOutActionCreator";
import petEligibilitySaga from "dux/eligibility/sagas/petEligibilitySaga";

// SF expects a value of 'None' in order to "unset" the additionalBookingType property.
//  However, other calls like 'availability' want a value of NULL.  So we're converting from None to NULL for those calls but leaving the state as None
export const sanitizeAdditionalBookingTypeData = (value) => (value !== ADDITIONAL_BOOKING_TYPE_NONE ? value : null);

function buildPetServiceItemData({
  timeSlot,
  selectedPetService,
  notes,
  addOns,
  maxCountInfo,
  lockToAssociate,
  isManualAppointment,
  additionalBookingType,
  maxPerBlockOrCheckReductionReason,
}) {
  const { maxCheckInCount, maxCheckInBool, maxPerBlockCount, maxPerBlockBool } = maxCountInfo;
  return {
    petServiceId: selectedPetService,
    startDateTime: timeSlot.startDateTime,
    associateId: timeSlot.associateId,
    duration: timeSlot.duration,
    notes,
    addOns,
    lockToAssociate,
    maxCheckInCount:
      maxCheckInCount !== 0 && (!maxCheckInCount || !maxCheckInBool)
        ? undefined
        : Number(maxCheckInCount),
    maxPerBlockCount:
      maxPerBlockCount !== 0 && (!maxPerBlockCount || !maxPerBlockBool)
        ? undefined
        : Number(maxPerBlockCount),
    isManualAppointment: isManualAppointment || maxPerBlockCount === 0,
    additionalBookingType: sanitizeAdditionalBookingTypeData(additionalBookingType),
    maxPerBlockOrCheckReductionReason,
  };
}

function buildEngagementData({
  timeSlot,
  petId,
  storeNumber,
  selectedPetService,
  addOns,
  maxCountInfo,
  isBoarded,
  lockToAssociate,
  notes,
  isManualAppointment,
  additionalBookingType,
  maxPerBlockOrCheckReductionReason,
}) {
  return {
    petId,
    storeNumber,
    isBoarded,
    petServices: [
      buildPetServiceItemData({
        timeSlot,
        selectedPetService,
        notes,
        addOns,
        maxCountInfo,
        lockToAssociate,
        isManualAppointment,
        additionalBookingType,
        maxPerBlockOrCheckReductionReason,
      }),
    ],
  };
}

// Used for draft appointment in modify appointment
function* onCreateAppointmentFromExistingItinerary({ customerKey, itineraryId }) {
  const itinerary = yield select(getItinerary, { itineraryId });
  const engagementsData = yield itinerary.engagements.map(engagementId =>
    call(buildEngagementDataFromExistingEngagement, { engagementId }),
  );
  yield put(
    itinerariesActionCreators.createItinerary({
      dispatchToCart: true,
      customerKey,
      data: engagementsData,
    }),
  );
}

function* buildEngagementDataFromExistingEngagement({ engagementId }) {
  const engagement = yield select(getEngagement, { engagementId });
  return engagement
    ? {
        petId: engagement.pet,
        storeNumber: engagement.storeNumber,
        isBoarded: engagement.isBoarded,
        petServices: yield engagement.petServiceItems.map(petServiceItemId =>
          call(buildPetServiceItemDataFromExistingPetServiceItem, { petServiceItemId }),
        ),
      }
    : {};
}

function* buildPetServiceItemDataFromExistingPetServiceItem({ petServiceItemId }) {
  const petServiceItem = yield select(getPetServiceItem, { petServiceItemId });
  return petServiceItem
    ? {
        petServiceId: petServiceItem.petServiceId,
        startDateTime: petServiceItem.startDateTime,
        duration: petServiceItem.duration,
        associateId: petServiceItem.associate,
        maxCheckInCount: petServiceItem.maxCheckInCount,
        maxPerBlockCount: petServiceItem.maxPerBlockCount,
        isManualAppointment: petServiceItem.isManualAppointment,
        additionalBookingType: petServiceItem.additionalBookingType,
        maxPerBlockOrCheckReductionReason: petServiceItem.maxPerBlockOrCheckReductionReason,
        addOns: petServiceItem.addOns.map(addon => ({
          addonId: addon.addonId,
          associateId: petServiceItem.associate,
        })),
      }
    : {};
}

function* onBookAppointment({ timeSlot, selectedPetService, customerKey, isManualAppointment }) {
  const storeNumber = yield select(getStoreNumber);
  const selectedPet = yield select(getSelectedPet);
  const selectedItinerary = yield select(getSelectedItinerary);
  const selectedAddOns = yield select(getSelectedAddOns, { petId: selectedPet });
  const selectedEnhancedServices = yield select(getSelectedEnhancedServices, { petId: selectedPet });
  const selectedPetServiceItem = yield select(getSelectedPetServiceItem, { petId: selectedPet });
  const isBoarded = yield select(getIsBoarded, { petId: selectedPet });
  const lockToAssociate = yield select(getLockToAssociate, { petId: selectedPet });
  const appointmentNote = yield select(getAppointmentNote, { petId: selectedPet });
  const maxCountInfo = yield select(getMaxCountInfo, { petId: selectedPet });
  const additionalBookingType = yield select(selectAdditionalBookingType, { petId: selectedPet });
  const maxPerBlockOrCheckReductionReason = yield select(getMaxPerBlockOrCheckReductionReason, {
    petId: selectedPet,
  });
  yield put(getPetEligibilityRequest());
  const newEligibilityResponse = yield call(fetchPetEligibility, {
    customerKey,
    petId: selectedPet,
    startDate: moment(timeSlot.startDateTime).format("YYYY-MM-DD"),
  });
  const eligibilityResults = yield select(getEligibilityResults);
  const shouldProceed = yield call(petEligibilitySaga, {
    newEligibilityResponse,
    eligibilityResults,
  });

  if (shouldProceed) {
    const { associateId } = timeSlot;
    const addOns = selectedAddOns.map(addOn => ({ ...addOn, associateId }));

    if (selectedEnhancedServices?.addOnId) {
      addOns.push({ addOnId: selectedEnhancedServices.addOnId, associateId });
    }

    const engagementData = buildEngagementData({
      timeSlot,
      selectedPetService,
      storeNumber,
      petId: selectedPet,
      addOns,
      maxCountInfo,
      isBoarded,
      lockToAssociate,
      notes: appointmentNote,
      isManualAppointment,
      additionalBookingType,
      maxPerBlockOrCheckReductionReason,
    });

    if (selectedPetServiceItem) {
      const { engagement: engagementId } = yield select(getPetServiceItem, {
        petServiceItemId: selectedPetServiceItem,
      });
      const petServiceItemData = buildPetServiceItemData({
        timeSlot,
        selectedPetService,
        maxCountInfo,
        isManualAppointment,
        additionalBookingType,
        maxPerBlockOrCheckReductionReason,
      });
      yield put(
        updatePetServiceItem({
          customerKey,
          itineraryId: selectedItinerary,
          engagementId,
          petServiceItemId: selectedPetServiceItem,
          data: petServiceItemData,
          dispatchToCart: true,
        }),
      );
    } else if (selectedItinerary) {
      yield put(
        createEngagement({
          dispatchToCart: true,
          customerKey,
          itineraryId: selectedItinerary,
          data: engagementData,
        }),
      );
    } else {
      yield put(
        itinerariesActionCreators.createItinerary({
          dispatchToCart: true,
          customerKey,
          data: [engagementData],
        }),
      );
    }
  } else {
    yield put(loadEligibilityResults(eligibilityResults));
  }

  yield put(clearNewAppointmentData());
}

function* onFinalizeAppointment({ customerKey, shouldCheckIn }) {
  const selectedItinerary = yield select(getSelectedItinerary);
  yield* onFinalizeItinerary({ customerKey, itineraryId: selectedItinerary, shouldCheckIn });
  const error = yield select(createErrorSelector([itinerariesActionTypes.FINALIZE_ITINERARY]));
  if (error) {
    alert("Error in finalizing itinerary");
  } else {
    shouldCheckIn
      ? yield put(showCheckInOutButtonModal({ modalType: modalTypes.APPOINTMENT_SET }))
      : yield put(showBookingModal(modalTypes.APPOINTMENT_SET));
  }
}

function* onFinalizeModifyAppointment({ draftAppointmentId, originalItineraryId }) {
  // Waiting for new WS to copy draft appointment into original appointment
  yield put(showBookingModal(modalTypes.APPOINTMENT_MODIFIED));
}

function* transformDateRange(apiCall, args) {
  const currentDate = moment();
  const { fromDate, toDate } = args;
  const isFromDateInThePast = moment(fromDate).isBefore(currentDate, "day");
  const isToDateInThePast = toDate ? moment(toDate).isBefore(currentDate, "day") : null;

  let response;

  if (toDate ? !isToDateInThePast : !isFromDateInThePast) {
    response = yield call(apiCall, {
      ...args,
      fromDate: isFromDateInThePast ? formatMoment(currentDate) : fromDate,
    });
  } else {
    response = { data: [] };
  }

  return response;
}

function* onLoadAvailableTimeSlots({ customerKey, fromDate, toDate, associateId, petServiceId }) {
  try {
    const storeNumber = yield select(getStoreNumber);
    const selectedPet = yield select(getSelectedPet);
    const maxCountInfo = yield select(getMaxCountInfo, { petId: selectedPet });
    const additionalBookingType = yield select(selectAdditionalBookingType, { petId: selectedPet });

    const { maxCheckInCount, maxCheckInBool, maxPerBlockCount, maxPerBlockBool } = maxCountInfo;

    yield put(bookingActionCreators.loadAvailableTimeSlotsRequest());

    const response = yield call(transformDateRange, fetchAvailableTimeSlots, {
      customerKey,
      fromDate,
      toDate,
      associateId,
      petServiceId,
      storeNumber,
      petId: selectedPet,
      additionalBookingType: sanitizeAdditionalBookingTypeData(additionalBookingType),
      maxCheckInCount:
        maxCheckInCount !== 0 && (!maxCheckInCount || !maxCheckInBool)
          ? undefined
          : Number(maxCheckInCount),
      maxPerBlockCount:
        maxPerBlockCount !== 0 && (!maxPerBlockCount || !maxPerBlockBool)
          ? undefined
          : Number(maxPerBlockCount),
    });

    const availableTimeSlots = response.data;
    yield put(bookingActionCreators.loadAvailableTimeSlotsSuccess({ availableTimeSlots }));
  } catch (error) {
    yield put(bookingActionCreators.loadAvailableTimeSlotsFailure({ error }));
  }
}

function* onLoadAvailableTimeSlotsByEngagement({
  customerKey,
  itineraryId,
  engagementId,
  fromDate,
  toDate,
  associateId,
}) {
  try {
    const storeNumber = yield select(getStoreNumber);
    const selectedPet = yield select(getSelectedPet);

    yield put(bookingActionCreators.loadAvailableTimeSlotsByEngagementRequest());
    const response = yield call(transformDateRange, fetchAvailableTimeSlotsByEngagement, {
      customerKey,
      itineraryId,
      engagementId,
      fromDate,
      toDate,
      associateId,
      storeNumber,
      petId: selectedPet,
    });
    const availableTimeSlots = response.data;
    yield put(
      bookingActionCreators.loadAvailableTimeSlotsByEngagementSuccess({ availableTimeSlots }),
    );
  } catch (error) {
    yield put(bookingActionCreators.loadAvailableTimeSlotsByEngagementFailure({ error }));
  }
}

function* onSetTimeSlotFromDashboard({ customerKey, fromDate, petId, associateId, petServiceId }) {
  try {
    yield put(bookingActionCreators.setTimeSlotFromDashboardRequest());
    const petServices = yield select(getServicesForPet);
    yield put(bookingActionCreators.setTimeSlotFromDashboardSuccess());

    const petService = petServices[petServiceId];

    if (petService) {
      const { currencyCode, duration, price } = petService;
      const timeSlot = {
        startDateTime: fromDate,
        currencyCode,
        duration,
        associateId,
        price,
      };

      yield put(bookAppointment({ timeSlot, customerKey, selectedPetService: petServiceId }));
    }
  } catch (error) {
    yield put(bookingActionCreators.setTimeSlotFromDashboardFailure({ error }));
  }
}

function* watchLoadAvailableTimeSlots() {
  yield takeLeading(bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS, onLoadAvailableTimeSlots);
}

function* watchSetTimeSlotFromDashboard() {
  yield takeEvery(bookingActionTypes.SET_TIME_SLOT_FROM_DASHBOARD, onSetTimeSlotFromDashboard);
}

function* watchBookAppointment() {
  yield takeEvery(bookingActionTypes.BOOK_APPOINTMENT, onBookAppointment);
}

function* watchFinalizeAppointment() {
  yield takeEvery(bookingActionTypes.FINALIZE_APPOINTMENT, onFinalizeAppointment);
}

function* watchLoadAvailableTimeSlotsByEngagement() {
  yield takeEvery(
    bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT,
    onLoadAvailableTimeSlotsByEngagement,
  );
}

function* watchCreateAppointmentFromExistingItinerary() {
  yield takeEvery(
    itinerariesActionTypes.CREATE_ITINERARY_FROM_EXISTING_ITINERARY,
    onCreateAppointmentFromExistingItinerary,
  );
}

export default function* bookingSaga() {
  yield all([
    watchLoadAvailableTimeSlots(),
    watchBookAppointment(),
    watchFinalizeAppointment(),
    watchSetTimeSlotFromDashboard(),
    watchLoadAvailableTimeSlotsByEngagement(),
    watchCreateAppointmentFromExistingItinerary(),
  ]);
}
