import { refundPackageConfirmationModalActionTypes } from "./refundPackageConfirmationModalActions";

const initialState = {
  isHidden: true,
  petId: null,
  bundleNumber: null,
};

const refundPackageConfirmationModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case refundPackageConfirmationModalActionTypes.DISPLAY_REFUND_PACKAGE_CONFIRMATION_MODAL:
      return {
        ...state,
        isHidden: action.isHidden,
        petId: action.petId,
        bundleNumber: action.bundleNumber,
      };
    default:
      return state;
  }
};

export default refundPackageConfirmationModalReducer;
