import moment from "moment";

/**
 * @param {date|moment} start The start date
 * @param {date|moment} end The end date
 * @param {string} type The range type. eg: 'days', 'hours' etc
 * @param {string} type the way you would like a date formatted,eg: 'YYYY-MM-DD', if left off will return a moment object.
 */
export const getDateRange = (startDate, endDate, type, format = undefined) => {
  const fromDate = moment(startDate);
  const toDate = moment(endDate);
  const diff = toDate.diff(fromDate, type);
  const range = [];
  for (let i = 0; i <= diff; i++) {
    if (format) {
      range.push(
        moment(startDate)
          .add(i, type)
          .format(format),
      );
    } else {
      range.push(moment(startDate).add(i, type));
    }
  }
  return range;
};
