import React from "react";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { isEmpty, isNil, isNumber } from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import { fontSizes } from "web/common/styles/responsive/fonts";
import { LayoutBox } from "@/layout/box/Box";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../../../core/constants/validationErrors";
import { SPAYED_NEUTERED_OPTIONS } from "../../../../core/constants/dropdownOptions";
import { NEW_PET_ID } from "../../../../core/constants";
import {
  NUMBERS_ONLY_REGEX,
  WHITESPACE_REGEX,
  DECIMAL_REGEX,
} from "../../../../core/constants/regex";
import createRangedOptions from "../../../../core/utils/rangeUtils/createRangedOptions";
import calculateAge from "../../../../core/utils/dateUtils/calculateAge";
import Form from "../../../common/Form";
import isRequiredPetFieldMissing from "../../../../core/utils/formUtils/isRequiredPetFieldMissing";
import FormInput from "../../../common/FormInput";
import Button from "../../../common/commonButton";
import PetFormHeader from "../petFormHeader/PetFormHeaderContainer";
import withSaveOnClickOutside from "../../../common/hoc/withSaveOnClickOutside";
import PetHealthConditions from "../petHealthConditions/PetHealthConditionsContainer";

const petFormValidationSchema = Yup.object().shape({
  petName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  breedId: Yup.number().required(REQUIRED_FIELD_ERROR_MESSAGE),
  speciesId: Yup.number().required(REQUIRED_FIELD_ERROR_MESSAGE),
  birthDate: Yup.date().required(REQUIRED_FIELD_ERROR_MESSAGE),
  genderId: Yup.number().required(REQUIRED_FIELD_ERROR_MESSAGE),
  isSpayedNeutered: Yup.bool().required(REQUIRED_FIELD_ERROR_MESSAGE),
  weight: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .test(
      "has a decimal",
      "Weight cannot contain a decimal",
      value => DECIMAL_REGEX.test(value) === false,
    )
    .test("is a number", "Weight must be a number", value => NUMBERS_ONLY_REGEX.test(value))
    .test(
      "has whitespace",
      "Weight must be a number",
      value => WHITESPACE_REGEX.test(value) === false,
    ),
  isMixedBreed: Yup.bool(),
  markings: Yup.string(),
});

const filterBreedsBySpeciesId = ({ breeds, speciesId }) => {
  return speciesId ? breeds.filter(breed => breed.speciesId == speciesId) : [];
};

// TODO: Convert to a generic utils function
/**
 * Responsible for taking an object and sorting it by label in ascending order.
 *
 * @param {object} itemToAlphabetize
 * @return {object} object alphabetized by label
 */
const alphabetize = itemToAlphabetize => {
  itemToAlphabetize.sort((a, b) => {
    // convert to lower case to better compare
    const itemA = a?.label.toLowerCase();
    const itemB = b?.label.toLowerCase();

    // sort string ascending
    // returning either -1, 1, or 0 accordingly to sort, exactly the formula
    // used inexplicably by the sort() method without any function passed in.
    if (itemA < itemB) {
      return -1;
    }
    if (itemA > itemB) {
      return 1;
    }

    // default return value (no sorting)
    return 0;
  });

  return itemToAlphabetize;
};

class PetFormComponent extends React.Component {
  componentDidUpdate(prevProps) {
    const { isCreatingPet, petFormData, petId, isPetActive, formikRef } = this.props;

    // Checks if pet is missing data only if pet has just loaded, has an id, is active, and is not being created.
    if (
      prevProps.petId !== petId &&
      !isCreatingPet &&
      isRequiredPetFieldMissing(petFormData) &&
      petId !== NEW_PET_ID &&
      !isPetActive
    ) {
      formikRef.current.submitForm();
    }
  }

  shouldShowErrorOnLoad = value => {
    const { isCreatingPet, petId } = this.props;

    // Return truthy if the field is missing and is required, is not a new pet, and a new pet is not being created.
    return (
      !isCreatingPet && petId !== NEW_PET_ID && (isNumber(value) ? isNil(value) : isEmpty(value))
    );
  };

  render() {
    const {
      componentId = "PetForm",
      petFormData,
      breeds = [],
      colors = [],
      genders = [],
      species = [],
      onSubmit,
      petId,
      customerKey,
      show,
      isAdding,
      showAddPet,
      isCreatingPet,
      onAddPet,
      fieldsToInclude,
      handleFocus,
      formikRef,
      clickOutsideRef,
      petCoOwners = [],
      router: { navigate },
      isBooking,
      isPetActive,
      isLoading,
    } = this.props;
    const isNewPet = petId === NEW_PET_ID;
    const today = moment();

    return (
      <Formik
        initialValues={petFormData}
        onSubmit={values =>
          onSubmit({
            formValues: { ...values, weight: +values.weight },
            initialValues: petFormData,
          })
        }
        ref={formikRef}
        validationSchema={petFormValidationSchema}
        enableReinitialize
        render={({ values, isValid, setFieldValue, errors }) => (
          <LoadingWrapper isLoading={isLoading} displayContainer="block">
            <Form
              id="PetFormComp"
              columns={3}
              innerRef={!isNewPet ? clickOutsideRef : null}
              onFieldFocus={handleFocus}
              show={show}
              fieldsToInclude={fieldsToInclude}
              disabled={isCreatingPet || (petId !== NEW_PET_ID && isPetActive)}
            >
              <PetFormHeader
                onSubmit={onSubmit}
                values={values}
                errors={errors}
                name="petName"
                isAdding={isAdding}
                showAddPet={showAddPet}
                onAddPet={onAddPet}
                petName={values.petName}
                onNameChange={name => setFieldValue("petName", name)}
                show={show}
                petId={petId}
                customerKey={customerKey}
                isPet
                showErrorOnLoad={this.shouldShowErrorOnLoad(values.petName)}
                isHidden={fieldsToInclude && !fieldsToInclude.includes("petName")}
              />
              <FormInput
                id={componentId}
                modifier="species"
                label="*Species"
                name="speciesId"
                type="select"
                options={species}
                nonEditable={!isNewPet}
                onChange={() => (values.breedId = "")}
                error={errors.speciesId}
                showErrorOnLoad={this.shouldShowErrorOnLoad(values.speciesId)}
              />
              <FormInput
                id={componentId}
                modifier="breed"
                label="*Breed"
                name="breedId"
                type="select"
                options={alphabetize(
                  filterBreedsBySpeciesId({ breeds, speciesId: values.speciesId }),
                )}
                error={errors.breedId}
                showErrorOnLoad={this.shouldShowErrorOnLoad(values.breedId)}
              />
              <FormInput
                id={componentId}
                modifier="isMixedBreed"
                label="Mix"
                type="checkbox"
                name="isMixedBreed"
              />
              <FormInput
                id={componentId}
                modifier="gender"
                label="*Sex"
                name="genderId"
                type="select"
                options={genders}
                error={errors.genderId}
                showErrorOnLoad={this.shouldShowErrorOnLoad(values.genderId)}
              />
              <FormInput
                id={componentId}
                modifier="birthDate"
                label="*Date of birth"
                type="calendar"
                name="birthDate"
                numberOfMonths={1}
                displayFormat="MM/DD/YYYY"
                isOutsideRange={day => day.isAfter(today, "day")}
                yearsOptions={createRangedOptions(today.year() - 30, today.year())}
                error={errors.birthDate}
                showErrorOnLoad={this.shouldShowErrorOnLoad(values.birthDate)}
              />
              <FormInput
                id={componentId}
                modifier="age"
                label="Age"
                type="text"
                name="age"
                value={values.birthDate ? calculateAge(values.birthDate) : ""}
                nonEditable
              />
              <FormInput
                id={componentId}
                modifier="color"
                label="Color"
                name="colorId"
                type="select"
                options={colors}
                error={errors.colorId}
                showErrorOnLoad={this.shouldShowErrorOnLoad(values.colorId)}
              />
              <FormInput
                id={componentId}
                type="text"
                modifier="weight"
                label="*Weight (Lb)"
                name="weight"
                error={errors.weight}
                showErrorOnLoad={this.shouldShowErrorOnLoad(values.weight)}
              />
              <FormInput
                id={componentId}
                modifier="isSpayedNeutered"
                label="Spayed / Neutered"
                type="select"
                name="isSpayedNeutered"
                options={SPAYED_NEUTERED_OPTIONS}
              />
              <FormInput
                id={componentId}
                modifier="markings"
                label="Markings"
                type="text"
                name="markings"
              />
              {isBooking ? (
                <LayoutBox>
                  <div style={{ fontSize: fontSizes.regular }}>Health Conditions</div>
                  <PetHealthConditions isBooking customerKey={customerKey} petId={petId} />
                </LayoutBox>
              ) : null}
              {petCoOwners.length > 0 &&
                petCoOwners.map(({ customerKey, firstName, lastName }) => (
                  <FormInput
                    id={componentId}
                    modifier={`coOwner-${customerKey}`}
                    label="Co-owner"
                    type="text"
                    name={`coOwner-${customerKey}`}
                    key={customerKey}
                    value={`${firstName} ${lastName}`}
                    isLink
                    onClick={() => navigate(`/pet-parent-profile/${customerKey}`)}
                    disabled
                  />
                ))}
              {isNewPet && (
                <Button
                  type="button"
                  onClick={() => onSubmit({ formValues: values, initialValues: petFormData })}
                  disabled={!isValid || isCreatingPet}
                  label={isCreatingPet ? "Creating.." : "Create"}
                />
              )}
            </Form>
          </LoadingWrapper>
        )}
      />
    );
  }
}

export default withSaveOnClickOutside(withRouteProps(PetFormComponent));
