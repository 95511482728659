/**
 * Constant for the check out reminder
 * @memberOf Constants.CheckOut
 */
export const checkOutReminder = {
  header: "Check-Out Reminder",
  proceed: "Proceed Anyway",
  cancel: "Cancel",
  mainMsg:
    "Have all services/add-ons been updated on the appointment?\nCompleting this step will send the invoice to POS and\nno further changes can be made to the appointment.",
  overnightMsg: "Please ensure an extended care fee has been added to any\napplicable reservation.",
  salonMsg: "Please print Salon Invoice.",
};
