export const NEW_MEDICATION_ID = "NEW_MEDICATION";

export const MEDICATION_MAX_COUNT = 3;

export const OTHER_MEDICATION_NAME = "Other";

export const medicationFields = {
  LOCATION_TYPES: "locationTypes",
};

export const medicationTypes = {
  CART: "CART",
  BOOKING: "BOOKING",
  PROFILE: "PROFILE",
};
