import React from "react";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { TextPassage } from "@petsmart-ui/sparky";

// Helpers
import { formatBooleansToValues } from "./prismTableHelpers";

// Styles
import "./prismTable.css";

/**
 *  React view component for PrismTableHeader
 *  @memberOf Views.Associate
 *  @function
 *  @name PrismTableHeader
 *  @param {object} props
 *  @param {boolean} props.isHidden
 *  @param {Array} props.tableHeaders
 *  @returns {JSX.Element}
 *
 *  @example const component = connect()(PrismTableHeader);
 */
export const PrismTableHeader = props => {
  const { isHidden, tableHeaders = [] } = props;

  if (isHidden) {
    return null;
  }

  return (
    <>
      {tableHeaders.map((tableHeader, tableHeaderIndex) => {
        return (
          <th key={tableHeaderIndex} className="prism-table-header__cell">
            {tableHeader}
          </th>
        );
      })}
    </>
  );
};

/**
 *  React view component for PrismTableData
 *  @memberOf Views.Associate
 *  @function
 *  @name PrismTableData
 *  @param {object} props
 *  @param {boolean} props.isHidden
 *  @param {Array} props.tableData
 *  @param {Function} props.formatCellFn
 *  @returns {JSX.Element}
 *
 *  @example const component = connect()(PrismTableData);
 */
export const PrismTableData = props => {
  const { isHidden, tableData = [], formatCellFn = formatBooleansToValues } = props;

  if (isHidden) {
    return null;
  }

  return (
    <>
      {tableData.map((tableRow, tableRowIndex) => {
        return (
          <tr key={tableRowIndex} className="prism-table-body__row">
            {Object.values(tableRow).map((tableCell, tableCellIndex) => {
              return (
                <td key={tableCellIndex} className="prism-table-body__cell">
                  <TextPassage>{formatCellFn(tableCell)}</TextPassage>
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

/**
 *  React view component for PrismTable
 *  @memberOf Views.Associate
 *  @function
 *  @name Table
 *  @param {object} props
 *  @param {string} props.componentId
 *  @param {boolean} props.isHidden
 *  @param {boolean} props.hasNoData
 *  @param {string} props.noDataLabel
 *  @param {JSX.Element} props.tableHeaders
 *  @param {JSX.Element} props.tableData
 *  @returns {JSX.Element}
 *
 *  @example const component = connect()(PrismTable);
 */
export const PrismTable = props => {
  const {
    componentId,
    isHidden,
    hasNoData = false,
    noDataLabel = "No Records Found",
    tableHeaders,
    tableData,
  } = props;

  if (isHidden) {
    return null;
  }

  if (hasNoData) {
    return (
      <TextPassage align="center">
        <h6>{noDataLabel}</h6>
      </TextPassage>
    );
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <table id={`${componentId}__table`} className="prism-table">
        <thead>
          <tr className="prism-table-header__row">{tableHeaders}</tr>
        </thead>
        <tbody>{tableData}</tbody>
      </table>
    </LayoutBox>
  );
};
