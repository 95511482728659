import React from "react";
import { connect } from "react-redux";

// Components
import CommonModal from "web/common/modals/commonModal";
import { LayoutBox } from "@/layout/box/Box";
import { HotelOverbookingExceptionsHistoryTable } from "@/dux/appointmentHistory/appointmentHistoryComponent";

// Actions
import { setHotelOverbookingExceptionsHistoryId } from "./hotelOverbookingExceptionsHistoryActions";

// Selectors
import { selectHotelOverbookingExceptionsHistoryModalIsHidden } from "./hotelOverbookingExceptionsHistorySelectors";

/**
 *
 * @param {object} props
 * @param {string} props.componentId
 * @param {boolean} props.isHidden
 * @param {Function} props.onClose
 * @param {JSX} props.historyTable
 * @returns
 */
export const HotelOverbookingExceptionsHistory = props => {
  const { componentId, isHidden, onClose, historyTable } = props;

  const Table = historyTable;

  return (
    <CommonModal isHidden={isHidden} onClose={onClose}>
      <LayoutBox id={componentId}>
        <Table />
      </LayoutBox>
    </CommonModal>
  );
};

// HOTEL MANAGE ROOM SUSPENSIONS HISTORY MODAL CONTAINER
export const HotelOverbookingExceptionsHistoryModal = connect(
  state => {
    return {
      componentId: "HotelManageRoomSuspensionsHistoryModal",
      isHidden: selectHotelOverbookingExceptionsHistoryModalIsHidden(state),
      historyTable: HotelOverbookingExceptionsHistoryTable,
    };
  },

  dispatch => {
    return {
      onClose: () => {
        dispatch(setHotelOverbookingExceptionsHistoryId({ exceptionId: null }));
      },
    };
  },
)(HotelOverbookingExceptionsHistory);
