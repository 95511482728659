import React from "react";
import styled from "styled-components";

const SectionWrapper = styled.span`
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "initial")};
`;

export default function StyledSpan(props) {
  const { isHidden, children } = props;
  if (!isHidden) {
    return <SectionWrapper {...props}>{children}</SectionWrapper>;
  }

  return null;
}
