// LIBS
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SelectField, TextField } from "@petsmart-ui/sparky";

// Layout
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";

// Constants
import {
  ASSOCIATE_ID_VALUE_TYPE,
  ASSOCIATE_NAME_VALUE_TYPE,
  ASSOCIATE_SEARCH_OPTIONS,
  CUSTOMER_SEARCH_OPTIONS,
  PHONE_NUMBER_VALUE_TYPE,
  SRC_SEARCH_OPTIONS,
  STORE_NUMBER_VALUE_TYPE,
} from "core/constants/dropdownOptions";

// Selectors
import { getSearchFieldType, getSearchFieldValue } from "core/selectors/searchSalonSRCSelector";
import getFormattedCustomerSearchField from "core/utils/searchUtils/getFormattedCustomerSearchField";
import validateCustomerSearchField from "core/utils/searchUtils/validateCustomerSearchField";

// Factory
import { searchTypeHandlerFactory } from "dux/searchFieldMain/searchTypeFactory/searchTypeHandlerFactory";
import { Wrapper } from "@googlemaps/react-wrapper";

export const SearchFieldMain = props => {
  // props from props mapStateToProps
  const {
    componentId,
    isHidden,
    searchOptions,
    inputWidth = "260px",
    label = "Search",
    defaultSearchFieldType,
    searchFieldType,
    searchFieldValue,
    validateSearchField,
  } = props;

  // props from  mapDispatchToProps
  const { searchTypeFactory } = props;

  // Local state
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /* MOUNT
    When components mounts set (in redux state) the default search value,
    type, e.g. phoneNumber, store, associate ID, etc. depending on the search fields the user
    is currently accessing
   */
  useEffect(() => {
    const factory = searchTypeFactory(defaultSearchFieldType);
    factory.onSetDefaultSearchFieldType(defaultSearchFieldType);
  }, []);

  /* UN-MOUNT
    When the Component Un-Mounts we need to clear the global state for the searchFieldValue this is
    done because when the user leaves the page and comes back the search field will be empty
 */
  useEffect(() => {
    return () => {
      const factory = searchTypeFactory(defaultSearchFieldType);
      factory.onFieldUnMount();
    };
  }, []);

  // Component Methods
  const onSearchFieldChange = e => {
    const factory = searchTypeFactory(searchFieldType);
    factory.onFieldUpdate({ searchFieldType, searchFieldValue: e.target.value });
  };

  const handleSearch = () => {
    const srhFieldType = searchFieldType || defaultSearchFieldType;
    const factory = searchTypeFactory(srhFieldType);
    factory.onSearch({ searchFieldType: srhFieldType, searchFieldValue });
  };

  const handleSearchTypeChange = e => {
    const searchFieldType = e.target.value;
    const factory = searchTypeFactory(searchFieldType);
    factory.onSelectionChange({ searchFieldType });
  };

  const checkForErrors = () => {
    const { validationError, message } = validateSearchField({ searchFieldType, searchFieldValue });

    // set local state props
    setErrorMessage(message);
    setHasError(validationError);

    // if there are no errors we can continue to execute the search
    if (!validationError) {
      handleSearch();
    }
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      checkForErrors();
    }
  };

  if (isHidden) {
    return null;
  }

  // Component markup
  return (
    <LayoutBox id={componentId} padding="scale-0">
      <Wrapper apiKey={window.env.GOOGLE_MAP_KEY}>
        <LayoutCluster space="scale-0" style={{ justify: "flex-start" }}>
          {/*Selection Dropdown*/}
          <LayoutBox padding="scale-0" style={{ minWidth: "200px" }}>
            <SelectField
              id={`${componentId}__select`}
              items={searchOptions}
              label={label}
              onChange={e => handleSearchTypeChange(e)}
              defaultValue={defaultSearchFieldType}
            />
          </LayoutBox>

          {/*Search Field*/}
          <LayoutBox padding="scale-0" style={{ minWidth: inputWidth, flexGrow: "4" }}>
            <TextField
              id={`${componentId}__searchField`}
              hideLabel={!hasError} // hide/show label above text based on if there is an error or not
              isError={hasError} // will height field red if there is an error
              label={errorMessage} // label (section above text) will act as the error message
              value={searchFieldValue}
              onChange={e => onSearchFieldChange(e)}
              type="search"
              onKeyDown={e => handleKeyPress(e)}
            />
          </LayoutBox>
        </LayoutCluster>
      </Wrapper>
    </LayoutBox>
  );
};

/*
  Primary Search Field Redux Container/Connect
  Handles props and actions for the primary search that will show is locations such as the
  dashboard and search page
 */
export const PrimarySearchFieldMain = connect(
  state => {
    const searchFieldType = getSearchFieldType(state);
    const searchFieldValue = getSearchFieldValue(state);
    const formattedSearchFieldValue = getFormattedCustomerSearchField({
      searchFieldType,
      searchFieldValue,
    });

    return {
      componentId: "PrimarySearchFieldMain",
      inputWidth: "500px",
      searchOptions: CUSTOMER_SEARCH_OPTIONS,
      label: "Search for customer by",
      defaultSearchFieldType: searchFieldValue ? searchFieldType : PHONE_NUMBER_VALUE_TYPE,
      validateSearchField: validateCustomerSearchField,

      searchFieldType,
      searchFieldValue: formattedSearchFieldValue, // need for phone number format
    };
  },

  dispatch => {
    return {
      // The search type factory will provide the correct handlers for the view per dropdown use case
      searchTypeFactory: searchFieldType => searchTypeHandlerFactory({ searchFieldType, dispatch }),
    };
  },
)(SearchFieldMain);

/*
  SRC Search Field Redux Container/Connect
  Handles props and actions for the search that will show in locations such as the SRC dashboard
 */
export const SRCSearchFieldMain = connect(
  state => {
    const searchFieldType = getSearchFieldType(state);
    const searchFieldValue = getSearchFieldValue(state);
    const formattedSearchFieldValue = getFormattedCustomerSearchField({
      searchFieldType,
      searchFieldValue,
    });

    return {
      componentId: "SRCSearchFieldMain",
      inputWidth: "580px",
      defaultSearchFieldType: STORE_NUMBER_VALUE_TYPE,
      searchOptions: SRC_SEARCH_OPTIONS,
      label: "Search by",
      validateSearchField: validateCustomerSearchField,
      searchFieldType,
      searchFieldValue: formattedSearchFieldValue, // need for phone number format
    };
  },

  dispatch => {
    return {
      // The search type factory will provide the correct handlers for the view per dropdown use case
      searchTypeFactory: searchFieldType => searchTypeHandlerFactory({ searchFieldType, dispatch }),
    };
  },
)(SearchFieldMain);

/*
  Associate Profile Search Field Redux Container/Connect
  Handles props and actions for the search that will show in on the associates page
 */
export const AssociateSearchFieldMain = connect(
  state => {
    const searchFieldType = getSearchFieldType(state);
    const searchFieldValue = getSearchFieldValue(state);

    return {
      componentId: "AssociateSearchFieldMain",
      inputWidth: "490px",
      searchOptions: ASSOCIATE_SEARCH_OPTIONS,
      label: "Search profiles by",

      searchFieldType, // this could come in as an any string including an empty string, see comments below.
      searchFieldValue,

      /* The Associate Search can only be of two types, ASSOCIATE_NAME_VALUE_TYPE or ASSOCIATE_ID_VALUE_TYPE,
        When the component loads for Associate Search The defaultSearchFieldType should be by ID, But could come in as
        other types if navigated to from a different page.

        So we check if it is not ASSOCIATE_NAME_VALUE_TYPE (other types including an empty string could come in) and if
        not, default to ID, if the dropdown value is changed to Name, and the user
        leaves the page and comes back, the searchFieldType will ASSOCIATE_NAME_VALUE_TYPE, and thus we want to use
        that searchFieldType instead, so it will be set to ASSOCIATE_NAME_VALUE_TYPE.
       */
      defaultSearchFieldType:
        searchFieldType !== ASSOCIATE_NAME_VALUE_TYPE ? ASSOCIATE_ID_VALUE_TYPE : searchFieldType,
      /*
        can only be id or phone, if anything other than those two set to blank string.

       */
    };
  },

  dispatch => {
    return {
      // The search type factory will provide the correct handlers for the view per dropdown use case
      searchTypeFactory: searchFieldType => searchTypeHandlerFactory({ searchFieldType, dispatch }),
    };
  },
)(SearchFieldMain);

/*
  SRC Booking Search Field Redux Container/Connect
  Handles props and actions for the search that will show in on the pet-parent-profile page when book Salon button is clicked.
 */
export const BookingSearchFieldMainSalon = connect(
  state => {
    const searchFieldType = getSearchFieldType(state);
    const searchFieldValue = getSearchFieldValue(state);
    const formattedSearchFieldValue = getFormattedCustomerSearchField({
      searchFieldType,
      searchFieldValue,
    });

    return {
      componentId: "BookingSearchFieldMainSalon",
      inputWidth: "270px",
      defaultSearchFieldType: STORE_NUMBER_VALUE_TYPE,
      searchOptions: SRC_SEARCH_OPTIONS,
      label: "Search by",
      validateSearchField: validateCustomerSearchField,
      searchFieldType,
      searchFieldValue: formattedSearchFieldValue, // need for phone number format
    };
  },

  dispatch => {
    return {
      // The search type factory will provide the correct handlers for the view per dropdown use case
      searchTypeFactory: searchFieldType => {
        return searchTypeHandlerFactory({ searchFieldType, dispatch });
      },
    };
  },
)(SearchFieldMain);

export const BookingSearchFieldMainHotel = connect(
  state => {
    const searchFieldType = getSearchFieldType(state);
    const searchFieldValue = getSearchFieldValue(state);
    const formattedSearchFieldValue = getFormattedCustomerSearchField({
      searchFieldType,
      searchFieldValue,
    });

    return {
      componentId: "BookingSearchFieldMainHotel",
      inputWidth: "270px",
      defaultSearchFieldType: STORE_NUMBER_VALUE_TYPE,
      searchOptions: SRC_SEARCH_OPTIONS,
      label: "Search by",
      validateSearchField: validateCustomerSearchField,
      searchFieldType,
      searchFieldValue: formattedSearchFieldValue, // need for phone number format
    };
  },

  dispatch => {
    return {
      // The search type factory will provide the correct handlers for the view per dropdown use case
      searchTypeFactory: searchFieldType => {
        return searchTypeHandlerFactory({ searchFieldType, dispatch });
      },
    };
  },
)(SearchFieldMain);

export const BookingSearchFieldMainTraining = connect(
  state => {
    const searchFieldType = getSearchFieldType(state);
    const searchFieldValue = getSearchFieldValue(state);
    const formattedSearchFieldValue = getFormattedCustomerSearchField({
      searchFieldType,
      searchFieldValue,
    });

    return {
      componentId: "BookingSearchFieldMainTraining",
      inputWidth: "270px",
      defaultSearchFieldType: STORE_NUMBER_VALUE_TYPE,
      searchOptions: SRC_SEARCH_OPTIONS,
      label: "Search by",
      validateSearchField: validateCustomerSearchField,
      searchFieldType,
      searchFieldValue: formattedSearchFieldValue, // need for phone number format
    };
  },

  dispatch => {
    return {
      // The search type factory will provide the correct handlers for the view per dropdown use case
      searchTypeFactory: searchFieldType => {
        return searchTypeHandlerFactory({ searchFieldType, dispatch });
      },
    };
  },
)(SearchFieldMain);
