import { connect } from "react-redux";
import { loadCustomers } from "core/actionCreators/customersActionCreators";
import {
  showSearchResults,
  clearSearchFieldValue,
  setSearchApplied,
  clearSearchApplied,
} from "core/actionCreators/searchSalonActionCreator";
import { commonDispatchActions, searchFieldProps } from "dux/searchField/searchFieldCommonProps";
import redirectOnCustomerSearch from "core/utils/searchUtils/redirectOnCustomerSearch";
import getFormattedCustomerSearchField from "core/utils/searchUtils/getFormattedCustomerSearchField";
import { setSearchResultsToDisplay } from "@/core/actionCreators/searchActionCreators";
import { searchConstants } from "@/core/constants/searchConstants";
import SearchFieldComponent from "dux/searchField/searchFieldComponent";

const mapStateToProps = (state, ownProps) => {
  const commonProps = searchFieldProps(state, ownProps);
  const { searchFieldType, searchFieldValue, options } = commonProps;

  return {
    ...commonProps,

    // add any new use case props here
    ...options,
    searchFieldValue: getFormattedCustomerSearchField({ searchFieldType, searchFieldValue }),
    redirectOnSearch: redirectOnCustomerSearch,
    styles: {flexGrow: "4"}
  };
};

const mapDispatchToProps = dispatch => {
  const dispatchCommonActions = commonDispatchActions(dispatch);

  return {
    ...dispatchCommonActions,
    clearSearchApplied: () => {
      dispatch(clearSearchFieldValue());
      dispatch(clearSearchApplied());
    },
    onSearch: (searchType, searchValue) => {
      dispatch(setSearchApplied());
      dispatch(loadCustomers({ [searchType]: searchValue }));
      dispatch(showSearchResults({ showSearchResults: true }));
      dispatch(setSearchResultsToDisplay({ searchResultsToDisplay: searchConstants.CUSTOMER }));
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

const SearchFieldHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(SearchFieldComponent);

export default SearchFieldHeaderContainer;
