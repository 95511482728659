import {
  SET_HOTEL_ROOM_TYPES_SUCCESS,
  SET_HOTEL_ROOM_TYPE_SELECTION,
} from "@/dux/hotelRoomSelection/hotelRoomSelectionActions";
import { CLEAR_OVERBOOKING_EXCEPTION_FORM } from "../overBookingNewExceptionDate/overBookingNewExceptionDateActions";

const initialState = {
  roomTypeSelected: null,
  roomTypes: [],
};

export const hotelRoomSelectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTEL_ROOM_TYPES_SUCCESS:
      return {
        ...state,
        roomTypes: action.payload,
      };
    case SET_HOTEL_ROOM_TYPE_SELECTION:
      return {
        ...state,
        roomTypeSelected: action.payload,
      };
    case CLEAR_OVERBOOKING_EXCEPTION_FORM:
      return {
        ...state,
        roomTypeSelected: initialState.roomTypeSelected,
      };
    default:
      return state;
  }
};
