import React from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { Datepicker } from "@petsmart-ui/sparky";

// Actions
import { setSelectedReportDate } from "./reportDateSelectionActions";

// Selectors
import { selectSelectedReportDate } from "./reportDateSelectionSelectors";
import {
  selectAvailableReportsList,
  selectSelectedReportType,
} from "@/dux/reportSelection/reportSelectionSelectors";

// Utils
import moment from "moment";

/**
 *  React view component for report date selection
 *  @memberOf Views.Associate
 *  @function
 *  @name ReportDateSelection
 *  @param {object} props
 *  @param {string} props.componentId
 *  @param {string} props.isHidden
 *  @param {Function} props.isOutsideRange
 *  @param {string} props.label
 *  @param {Function} props.onDateChange
 *  @param {string} props.selectedDate
 *  @returns {JSX.Element}
 *
 *  @example const component = connect()(ReportDateSelection);
 */
export const ReportDateSelection = props => {
  const { componentId, isHidden, isOutsideRange, label, onDateChange, selectedDate } = props;

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <Datepicker
        label={label}
        isOutsideRange={isOutsideRange}
        onDateChange={date => onDateChange(date)}
        date={selectedDate}
      />
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the ReportDateSelection
 * @summary Located on the /reports page
 * @memberOf Views.Associate
 * @function
 * @name ReportDateSelectionContainer
 * @example <ReportDateSelectionContainer />
 */
export const ReportDateSelectionContainer = connect(
  state => {
    const today = moment();
    const availableReports = selectAvailableReportsList(state);
    const selectedReportType = selectSelectedReportType(state);

    const relevantDaysForSelectedReport = availableReports.filter(
      report => report.value === selectedReportType,
    );
    const reportSelectionStartDate = relevantDaysForSelectedReport[0]?.relevantDays?.startDate;
    const reportSelectionEndDate = relevantDaysForSelectedReport[0]?.relevantDays?.endDate;

    const isOutsideRange = date => {
      // Today, and tomorrow dates are available for pets in facility
      // Yesterday, today, and tomorrow are available for all reports
      return (
        date.isBefore(reportSelectionStartDate, "day") ||
        date.isAfter(reportSelectionEndDate, "day")
      );
    };

    return {
      componentId: "ReportDateSelectionContainer",
      isHidden: false,
      isOutsideRange,
      label: "Select Date",
      selectedDate: selectSelectedReportDate(state) || today,
    };
  },
  dispatch => ({
    onDateChange: date => dispatch(setSelectedReportDate(date)),
  }),
)(ReportDateSelection);
