import { createSelector } from "reselect";
import { getHotelItinerary } from "../hotelItinerary/hotelItinerarySelectors";

/**
 *  Selector to get hotel reservation notes state
 *  @memberOf Selectors.hotelPetReservationNotes
 *  @function
 *  @name selectHotelReservationNotes
 *  @param {Object} state - redux state
 *  @returns {Object} hotel reservation notes
 *  @example selectHotelReservationNotes(state)
 */
export const selectHotelReservationNotes = state => state?.hotelPetReservationNotes ?? {};

/**
 *  Selector to get hotel reservation notes state for a given pet
 *  @memberOf Selectors.hotelPetReservationNotes
 *  @function
 *  @name selectHotelReservationNotesByPet
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {Number} props.petId
 *  @returns {String} hotel reservation notes
 *  @example selectHotelReservationNotesByPet(state, { petId })
 */
export const selectHotelReservationNotesByPet = createSelector(
  [selectHotelReservationNotes, (state, props) => props?.petId],
  (notes, petId) => {
    return notes[petId] || "";
  },
);

/**
 *  Selector to get hotel reservation notes state for a given pet when rebooking
 *  @memberOf Selectors.hotelPetReservationNotes
 *  @function
 *  @name selectPetNotesForRebooking
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {Number} props.petId
 *  @returns {Object} hotel reservation notes with rebooking prefix
 *  @example selectPetNotesForRebooking(state, { petId })
 */
export const selectPetNotesForRebooking = createSelector(
  [selectHotelReservationNotesByPet, getHotelItinerary],
  (notes = "", { itineraryId } = {}) => {
    const newPrefix = `Copy of Reservation #${itineraryId}`;
    const rebookNoteRegex = new RegExp(/^Copy of Reservation #\d*\s*/g);
    const noteWithoutPrefix = notes?.replace(rebookNoteRegex, "")?.trim();

    return noteWithoutPrefix ? `${newPrefix}\n${noteWithoutPrefix}` : newPrefix;
  },
);
