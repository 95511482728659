import { LOAD_TRAINING_CLASS_DETAILS } from "dux/manageTrainingClassPage/manageTrainingClassPageActions";
import { checkForUndefined } from "core/utils/undefinedOrNullUtils/undefinedOrNullUtils";

const initialState = {
  customerKey: null,
  itineraryId: null,
};

/**
 * Reducer function for managing the state of the manage training class page.
 *
 * @memberof Reducer.persistent
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @returns {Object} - The new state.
 */
export const manageTrainingClassPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRAINING_CLASS_DETAILS:
      return {
        ...state,
        customerKey: checkForUndefined(action.payload.customerKey, initialState.customerKey),
        itineraryId: checkForUndefined(action.payload.itineraryId, initialState.itineraryId),
      };

    default:
      return state;
  }
};
