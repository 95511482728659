import moment from "moment";
import { weekDaysNames } from "../constants";
import { getWeekFromDate } from "../utils/dateUtils/getWeekFromDate";
import militaryToStandardTime from "../utils/dateUtils/militaryToStandardTime";
import groupBy from "../utils/arrayUtils/groupBy";

// --------------------------------------------------------------------------------------
// We first construct the <Table /> header columns:
// - Path names correspond to properties defined in the activity helper functions (formatDayOff).
// - Changes to the order of this array will be reflected in the view.
// - Labels can be arrays, representing stacked content within a cell.
const COLUMNS = [
  { label: "", path: "label" },
  { label: "Type of Day", path: "type" },
  { label: ["Work", "Start Time"], path: "workStart" },
  { label: ["Work", "End Time"], path: "workEnd" },
  { label: ["Lunch", "Start Time"], path: "lunchStart" },
  { label: ["Lunch", "End Time"], path: "lunchEnd" }
];

// --------------------------------------------------------------------------------------
// This selector explicitly defines the format of the data being consumed by a <Table /> component.
// The output is an array of objects where each property corresponds to a column. Each object is a
// row in the table body.
export function getMySchedule(state) {
  const weeklyActivity = Object.values(state.schedules.weeklyEdit);
  if (!weeklyActivity.length) return { columns: [], rows: [] };

  const labels = createDateLabels(state);
  const hours = getWeeklySalonHoursByRange(state);
  const allFormattedData = weeklyActivity.reduce((acc, cur) => {
    if (cur.absenceReason === "Lunch Break") return [...acc, formatLunchBreak(cur, labels, hours)];
    if (cur.absenceReason === "Day Off") return [...acc, formatDayOff(cur, labels, hours)];
    if (cur.type === "Relocation") return [...acc, formatRelocation(cur, labels, hours)];
    return [...acc, formatOther(cur, labels, hours)];
  }, []);

  // Merge activities by day - notably lunch and work should appear on the same row.
  const groupByDay = groupBy(allFormattedData, "dayOfWeek");
  const groupsByDayArray = Object.values(groupByDay);
  const groupsByDayMerge = groupsByDayArray.map(day => Object.assign(...day));

  // Sort the rows from Monday (top) to Sunday (bottom). The order of this array will be reflected in the view.
  const rows = weekDaysNames.map(day =>
    groupsByDayMerge.find(activity => activity.dayOfWeek === day)
  );
  return { columns: COLUMNS, rows };
}

// Used to load store hours and identify when they are open/closed.
export function getWeekStartEndDates(state, range = 6) {
  const { startWeekDate } = state.schedules;
  return {
    fromDate: startWeekDate,
    toDate: moment(startWeekDate)
      .add(range, "days")
      .format("YYYY-MM-DD")
  };
}

// --------------------------------------------------------------------------------------

// Returns an array of salon hours for each day of the week
function getWeeklySalonHoursByRange(state) {
  const { salonHours } = state;
  const { startWeekDate } = state.schedules;
  const dateArray = getWeekFromDate({ date: startWeekDate });
  const datesFormatted = dateArray.map(date => moment(date).format("YYYY-MM-DD"));
  const output = datesFormatted.map(date => ({
    ...salonHours[date],
    date
  }));

  return output;
}

// This function creates a look up object containing labels. Each key is associated with a day of the week:
// { Thursday: "Thursday, November 26" }
function createDateLabels(state) {
  const { startWeekDate } = state.schedules;
  const dateArray = getWeekFromDate({ date: startWeekDate });
  const dateLabels = dateArray.map(date => {
    const m = moment(date);
    const weekDay = m.format("dddd");
    return {
      [weekDay]: `${weekDay}, ${m.format("MMMM")} ${m.format("DD")}`
    };
  });

  return Object.assign(...dateLabels);
}

function isClosedByDay(hours, dayOfWeek) {
  const hoursByDay = hours.find(day => day.DayOfWeek === dayOfWeek);
  return hoursByDay && hoursByDay.IsClosed === true ? "Store Closed" : null;
}

// --------------------------------------------------------------------------------------
// These are local functions used to format various activity types. Each property on the
// returned object will correspond to a column "path".
function formatLunchBreak(activity, labels, hours) {
  const { startTime, endTime, dayOfWeek } = activity;
  const isClosed = isClosedByDay(hours, dayOfWeek);

  return {
    type: [isClosed, "Workday"],
    lunchStart: militaryToStandardTime(startTime),
    lunchEnd: militaryToStandardTime(endTime),
    label: labels[dayOfWeek],
    dayOfWeek
  };
}

function formatDayOff(activity, labels, hours) {
  const { absenceReason, dayOfWeek } = activity;
  const isClosed = isClosedByDay(hours, dayOfWeek);

  // the or condition below makes sure that only "store closed" appears on a closed date,
  // instead of "store closed" and "day off"
  return {
    type: [isClosed || absenceReason],
    label: labels[dayOfWeek],
    workStart: "",
    workEnd: "",
    dayOfWeek
  };
}

function formatRelocation(activity, labels, hours) {
  const { type, storeId, startTime, endTime, dayOfWeek } = activity;

  const isClosed = isClosedByDay(hours, dayOfWeek);

  return {
    type: [isClosed, type, `Store #${storeId}`],
    workStart: militaryToStandardTime(startTime),
    workEnd: militaryToStandardTime(endTime),
    label: labels[dayOfWeek],
    dayOfWeek
  };
}

function formatOther(activity, labels, hours) {
  const { type, startTime, endTime, dayOfWeek } = activity;

  const dayType = type === "Work" ? "Workday" : type;
  const isClosed = isClosedByDay(hours, dayOfWeek);

  return {
    type: [isClosed, dayType],
    workStart: militaryToStandardTime(startTime),
    workEnd: militaryToStandardTime(endTime),
    label: labels[dayOfWeek],
    dayOfWeek
  };
}
