import { connect } from "react-redux";
import { getSelectedTrainingPetService } from "@/dux/scheduleTrainingPage/scheduleTrainingPageSelectors";
import { getBundleByBundleNumber } from "@/dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { color } from "@/web/common/styles/theme";
import {
  VDT_PRIVATE_CLASS_TITLE,
  PURCHASE_ANOTHER_CLASS_COPY,
} from "@/dux/scheduleTrainingPage/scheduleTrainingPageContants";
import { selectTrainingService } from "@/dux/scheduleTrainingPage/scheduleTrainingPageActions";
import TrainingClassTypeButtonComponent from "@/dux/_components/scheduleTrainingPage/TrainingClassTypeButtonComponent";

const mapStateToProps = (state, { bundleNumber }) => {
  const petId = getCurrentPet(state);
  const { petServiceId, reservedUnits, redeemedUnits, availableUnits } = getBundleByBundleNumber(
    state,
    {
      petId,
      bundleNumber,
    },
  );
  const selectedTrainingPetService = getSelectedTrainingPetService(state);
  const isActive = petServiceId === selectedTrainingPetService;
  const isAvailable = availableUnits > 0;

  return {
    petServiceId,
    reservedUnits,
    redeemedUnits,
    availableUnits,
    isActive,
    isAvailable,
    componentID: "TrainingClassTypeButton",

    // Hard-coded for MMP, but will be pulled from petServiceDisplayName field from the GET bundles API post-MMP
    classTitle: VDT_PRIVATE_CLASS_TITLE,
    purchaseClassCopy: PURCHASE_ANOTHER_CLASS_COPY,
    buttonStyles: {
      pointerEvents: isAvailable ? "auto" : "none",
      border: isActive ? `2px solid ${color.kalEl}` : `2px solid ${color.ororoMunroe}`,
    },
    remainingUnitsStyles: !isAvailable ? { color: color.errorRed } : {},
  };
};

const mapDispatchToProps = dispatch => ({
  selectTrainingService: ({ petServiceId }) => dispatch(selectTrainingService({ petServiceId })),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  // We only have one service right now, but want to set up this click handler
  // to update the training service for when we have more than one service in the future.
  onClick: () => {
    propsFromDispatch.selectTrainingService({
      petServiceId: propsFromState.petServiceId,
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(TrainingClassTypeButtonComponent);
