import { connect } from "react-redux";
import * as refundPackageModalTypes from "@/dux/_constants/refundPackageModalTypes";
import { getIsRefundPackageErrorModalHidden } from "./refundPackageErrorModalSelectors";
import { displayRefundPackageErrorModal } from "./refundPackageErrorModalActions";
import RefundPackageModalContentComponent from "../_components/refundPackageModals/RefundPackageModalContentComponent";
import RefundPackageErrorContentContainer from "./RefundPackageErrorContentContainer";

const mapStateToProps = (state) => ({
  isHidden: getIsRefundPackageErrorModalHidden(state), // a selector that has the modal is hidden,
  compName: refundPackageModalTypes.REFUND_PACKAGE_ERROR,
  diComp: { [refundPackageModalTypes.REFUND_PACKAGE_ERROR]: RefundPackageErrorContentContainer },
});

const mapDispatchToProps = (dispatch) => ({
  onModalClose: () => dispatch(displayRefundPackageErrorModal({ isHidden: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundPackageModalContentComponent);
