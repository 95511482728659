// @ts-check
import React from "react";
import { connect } from "react-redux";

// Components
import { TextField } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";

// Actions
import { setOverbookingExceptionPercent } from "@/dux/overBookingException/overBookingExceptionActions";

// Selectors
import { selectRoomCountBySelectedBucketId } from "../hotelRoomSelection/hotelRoomSelectionSelector";
import { selectOverBookingPercentage } from "@/dux/overBookingException/overBookingExceptionSelectors";

/**
 * Helper function to validate the overbooking percent
 * @param {string?} percent
 * @param {boolean?} isRequired
 * @returns {string}
 */
export const validatePercent = (percent, isRequired) => {
  const hasValue = percent !== null && percent !== undefined && percent !== "";
  if (isRequired && !hasValue) return "Overbooking percentage is required";
  if (hasValue && isNaN(+percent)) return "Overbooking has to be numerical value";
  if (hasValue && (+percent < 1 || +percent > 100))
    return "Overbooking has to be between 1 and 100";
  return "";
};

/**
 *
 * @param {object} props
 * @param {string} props.componentId
 * @param {string} props.percent
 * @param {boolean} props.isHidden
 * @param {Function} props.handleOnChange
 * @param {string} props.addedRoomCount
 * @param {string} props.error
 * @returns
 */
export const EditNewCapacity = props => {
  const { componentId, percent, isHidden, handleOnChange, addedRoomCount, error } = props;

  if (isHidden) return null;

  /** @type {import("react").ChangeEventHandler<HTMLInputElement>} */
  const onChange = event => {
    handleOnChange(event?.target?.value);
  };

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <TextField
        label="Overbooking (1 - 100%)"
        value={percent}
        onChange={onChange}
        fieldNote={error ? error : addedRoomCount}
        isError={!!error}
      />
    </LayoutBox>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const OverBookingExceptionNewCapacity = connect(
  (state, /** @type {{ isRequired: boolean}}*/ { isRequired }) => {
    const roomCount = selectRoomCountBySelectedBucketId(state);
    const percent = selectOverBookingPercentage(state);
    const count = Math.floor(roomCount * percent * 0.01);

    return {
      componentId: "OverBookingExceptionNewCapacity",
      percent,
      addedRoomCount: !!percent ? `Added rooms: ${count}` : " ",
      error: validatePercent(percent, isRequired),
    };
  },
  dispatch => {
    return {
      handleOnChange: (/** @type {string} */ value) =>
        dispatch(setOverbookingExceptionPercent(value)),
    };
  },
)(EditNewCapacity);
