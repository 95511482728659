import { get, head, compose } from "lodash/fp";

export const getPetToDisplay = pets =>
  compose(
    get("petId"),
    head
  )(pets);

export const getIsPrevPetCurrent = (pets, selectedPet) => {
  return pets.find(pet => {
    if (pet.petId === selectedPet) return true;
  });
};

export const getPetVaccinationId = values => {
  const selectedVaccination = values.vaccinations.find(
    vaccination =>
      vaccination.Name === values.currentVaccinationName && vaccination.SpeciesId === values.species
  );
  const deleteVaccination =
    selectedVaccination &&
    values.petVaccinations.find(
      vaccination =>
        vaccination.vaccinationId === selectedVaccination.VaccinationId && vaccination.activeFlag
    );

  return (
    deleteVaccination && {
      petVaccinationId: deleteVaccination.petVaccinationId,
      vaccinationId: deleteVaccination.vaccinationId
    }
  );
};
