/**
 * Responsible for returning the featureFlag Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getFeatureFlagsState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the featureFlags object in the redux store
 *
 * @example createSelector([getFeatureFlagsState], featureFlagState => featureFlagState.SVCSART123;
 */
export default function getFeatureFlagsState(state) {
  return state.featureFlagging.featureFlags;
}
