import { connect } from "react-redux";
import { getCurrentPetServiceId } from "core/selectors/cartSelectors";
import { color } from "web/common/styles/theme";
import { getPrePaidBundleAddOnPriceByPetServiceId } from "dux/_selectors/addOnsSelectors";
import TextComponent from "dux/_components/genericLayoutComponents/TextComponent";

const mapStateToProps = (state, { petId }) => {
  const petServiceId = getCurrentPetServiceId(state, { petId });
  const price = getPrePaidBundleAddOnPriceByPetServiceId(state, { petId, petServiceId });
  return {
    isHidden: false,
    styleType: "div",
    text: `-$${price}`,
    styleObj: { color: color.lightGrey },
  };
};

export default connect(mapStateToProps)(TextComponent);
