import { GET_LIST_OF_NEAR_BY_STORES_SUCCESS, SET_CACHED_STORE_NUMBER } from "./listOfNearByStoresActions";

const initialState = {
  nearByStores: [],
  cacheStoreNumber: null,
};

const listOfNearByStoresReducer = (
  state = initialState,
  { type, nearByStores, cacheStoreNumber },
) => {
  switch (type) {
    case GET_LIST_OF_NEAR_BY_STORES_SUCCESS:
      return {
        ...state,
        nearByStores,
      };
    case SET_CACHED_STORE_NUMBER:
      return {
        ...state,
        cacheStoreNumber,
      };
    default:
      return state;
  }
};

export default listOfNearByStoresReducer;
