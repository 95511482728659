import { createSelector } from "reselect";

export const selectFrequencies = state => state?.frequencyOptions || [];

export const selectFrequencyNameById = frequencyId =>
  createSelector(
    [selectFrequencies],
    frequencies =>
      frequencies.find(frequency => frequency.frequencyId === frequencyId)?.frequencyName || "",
  );

export const selectFrequencyOptions = createSelector([selectFrequencies], frequencies =>
  frequencies.map(({ frequencyId, frequencyName }) => ({
    value: frequencyId,
    label: frequencyName,
  })),
);
