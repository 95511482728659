import { connect } from "react-redux";
import DashboardModals from "@/web/dashboard/modals/dashboardModals";
import { hideDashboardModal } from "@/core/actionCreators/ui/web/dashboardActionCreators";

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(hideDashboardModal());
  },
});

export default connect(null, mapDispatchToProps)(DashboardModals);
