import { call } from "redux-saga/effects";
import {
  eligibilitySubjectTypes,
  eligibilityTypes,
  reasonCodes,
} from "dux/eligibility/constants/eligibilityConstants";
import { waitFor } from "core/sagas/utilsSaga";
import { getCustomer, getPet } from "core/selectors/entitiesSelector";

/**
 * Responsible for adding reasons object to reasons array, populate reasons object with reasonCode, description and isSecondary properties
 * @param { string } eligibilityType - check in, check out, or booking
 * @param { object } alerts
 * @return {[]}
 */
function buildReasons(eligibilityType, alerts) {
  const reasons = [];

  // eslint-disable-next-line default-case
  switch (eligibilityType) {
    case eligibilityTypes.CHECK_OUT:
      if (alerts.checkOutAlert) {
        reasons.push({
          reasonCode: reasonCodes.CheckOutAlert,
          description: alerts.checkOutAlert,
          isSecondary: true,
        });
      }
      break;
    case eligibilityTypes.CHECK_IN:
      if (alerts.checkInAlert) {
        reasons.push({
          reasonCode: reasonCodes.CheckInAlert,
          description: alerts.checkInAlert,
          isSecondary: true,
        });
      }
      break;
    case eligibilityTypes.BOOKING:
      if (alerts.bookingAlert) {
        reasons.push({
          reasonCode: reasonCodes.BookingAlert,
          description: alerts.bookingAlert,
          isSecondary: true,
        });
      }
      break;
  }

  return reasons;
}

/**
 * Responsible for building an object of pet checkin, booking and checkout alerts.
 * @param { string } id - the PetId
 * @return {Generator<SimpleEffect<"SELECT", SelectEffectDescriptor>|SimpleEffect<"TAKE", TakeEffectDescriptor>, *, ?>}
 */
function* getPetAlerts(id) {
  const pet = yield waitFor(getPet, { petId: id });

  return {
    checkOutAlert: pet.groomingCheckOutAlert,
    checkInAlert: pet.groomingCheckInAlert,
    bookingAlert: pet.groomingBookingAlert,
  };
}

/**
 * Responsible for building an object of Customer checkin, booking and checkout alerts.
 * @param { string } id - the customerKey
 * @return {Generator<SimpleEffect<"SELECT", SelectEffectDescriptor>|SimpleEffect<"TAKE", TakeEffectDescriptor>, *, ?>}
 */
function* getCustomerAlerts(id) {
  const customer = yield waitFor(getCustomer, { customerKey: id });

  return {
    checkOutAlert: customer.checkOutAlert,
    checkInAlert: customer.checkInAlert,
    bookingAlert: customer.bookingAlert,
  };
}

/**
 * Responsible for building alert reasons based on what the customer or pet flow.
 * @param { string } id - customerKey or PetId
 * @param { string } eligibilitySubjectType - flow type, customer or pet, e.g. eligibilitySubjectTypes.CUSTOMER
 * @param { string } - check in, check out, or booking
 * @return {Generator<SimpleEffect<"CALL", CallEffectDescriptor>, {customerKey: *, reasons: *[], canBook: boolean}|{petId: *, reasons: *[], canBook: boolean}, ?>}
 */
export function* onGetReasonFlow({ id, eligibilitySubjectType, eligibilityType }) {
  // eslint-disable-next-line no-useless-catch
  try {
    if (eligibilitySubjectType === eligibilitySubjectTypes.CUSTOMER) {
      // Customer flow
      const alerts = yield call(getCustomerAlerts, id);
      return {
        customerKey: id,
        canBook: true,
        reasons: buildReasons(eligibilityType, alerts),
      };
    }

    // Pet Flow
    const alerts = yield call(getPetAlerts, id);
    return {
      petId: id,
      canBook: true,
      reasons: buildReasons(eligibilityType, alerts),
    };
  } catch (error) {
    throw error;
  }
}
