import { endpointVersionString } from "core/utils/envUtils";
import { bookingAPI } from "./_axiosConfigSystemServicesBooking";

/**
 * GET API for the Hotel Room Suspensions
 * @param { number } obj.storeNumber - Number of the current store
 * @returns { AxiosPromise<any> }
 */
export function getHotelRoomSuspensions({ storeNumber }) {
  return bookingAPI.get(`hotel/${endpointVersionString()}/stores/${storeNumber}/roomsuspensions`);
}

/**
 * GET API for the Hotel Room Suspensions by room number
 * @param { number } obj.storeNumber - Number of the current store
 * @param { string } obj.roomNumber - Room Number
 * @param { string } obj.fromDate - fromDate in UTC
 * @param { string } obj.toDate - toDate in UTC
 * @returns { AxiosPromise<any> }
 */
export function getHotelRoomSuspensionsByRoomNumber({ storeNumber, roomNumber, toDate, fromDate }) {
  return bookingAPI.get(
    `hotel/${endpointVersionString()}/stores/${storeNumber}/roomsuspensions?roomNumber=${roomNumber}&fromDate=${fromDate}&toDate=${toDate}`,
  );
}

/**
 * POST API for the Hotel Room Suspensions
 * @param { number } obj.storeNumber - Number of the current store
 * @param { object } obj.data - payload
 * @returns { AxiosPromise<any> }
 */
export function postHotelRoomSuspension({ storeNumber, data }) {
  return bookingAPI.post(
    `hotel/${endpointVersionString()}/stores/${storeNumber}/roomsuspensions`,
    data,
  );
}

/**
 * PATCH API for the Hotel Room Suspensions
 * @param { number } obj.storeNumber - Number of the current store
 * @param { object } obj.data - payload
 * @param { string } obj.suspensionId - suspenionId is required to update a record
 * @returns { AxiosPromise<any> }
 */
export function patchHotelRoomSuspension({ storeNumber, data, suspensionId }) {
  return bookingAPI.patch(
    `hotel/${endpointVersionString()}/stores/${storeNumber}/roomsuspensions/${suspensionId}`,
    data,
  );
}
