import moment from "moment";
import { connect } from "react-redux";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { setWeekNumber } from "../../core/actionCreators/ui/web/commonActionCreators";
import CommonButton from "../common/commonButton";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { loadHotelDailyDashboard } from "@/dux/dashboard/hotel/hotelDashboardActions";
import { formatCalendarDateMoment } from "@/core/utils/dateUtils/formatDateTime";

const mapStateToProps = () => ({
  label: "Today",
  disabled: false,
});

const mapDispatchToProps = dispatch => ({
  onClick: () => {
    dispatch(setSelectDate({ selectedDate: moment() }));

    dispatch(setWeekNumber({ weekNumber: moment().week() }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonButton);

export const HotelTodayButton = connect(
  state => ({
    componentId: "HotelTodayButton",
    label: "Today",
    disabled: false,
    storeId: getStoreNumber(state),
  }),
  dispatch => ({
    onClick: storeId => {
      const selectedDate = moment();
      dispatch(setSelectDate({ selectedDate }));
      dispatch(setWeekNumber({ weekNumber: selectedDate.week() }));
      dispatch(
        loadHotelDailyDashboard({ storeId, selectedDate: formatCalendarDateMoment(selectedDate) }),
      );
    },
  }),
  (stateProps, dispatchProps) => {
    const { componentId, label, disabled, storeId } = stateProps;
    const { onClick } = dispatchProps;

    return { componentId, label, disabled, onClick: () => onClick(storeId) };
  },
)(CommonButton);
