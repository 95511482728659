import React from "react";
import { Icon, Layout, Text } from "@prism/psm-ui-components";

export function AddButton({ disabled, text }) {
  return (
    <Layout.Cluster>
      <Layout.ShiftBy y={2}>
        <Icon
          variant="hero-icon-plus-circle-sm"
          color="psm-icon-color-white"
          size="psm-icon-size-32"
          style={{ fill: disabled ? "#1010104D" : "#1777f2" }}
        />
      </Layout.ShiftBy>
      <Layout.Spacer size={5} axis="horizontal" />
      <Text size="text-size-lg" bold>
        {text}
      </Text>
    </Layout.Cluster>
  );
}

export default AddButton;
