import { put, takeEvery, call, all, select } from "redux-saga/effects";
import vetsActionCreators from "../actionCreators/vetsActionCreators";
import vetsActionTypes from "../actionTypes/vetsActionTypes";
import petsActionCreators from "../actionCreators/petsActionCreators";
import petProfileActionCreators from "../actionCreators/petProfileActionCreators";
import { postVet, deleteVet } from "../services/associateWebProfile/vetEndPoints";
import { getPetVets, getCustomerAndPetKeys } from "../selectors/vetsSelectors";
import { getSourceId } from "../selectors/persistentSelectors";
import { searchVet } from "../services/vets/vetEndPoints";

function* onCreateVet({ data }) {
  try {
    yield put(vetsActionCreators.createVetRequest());
    const { customerKey, petId } = yield select(getCustomerAndPetKeys);
    const sourceId = yield select(getSourceId);
    const response = yield call(postVet, {
      customerKey,
      sourceId,
      petId,
      data: { ...data, sourceId }
    });
    const vet = response.data;
    const petVets = yield select(getPetVets, { customerKey, petId });
    yield put(
      petsActionCreators.updatePetSuccess({
        pet: {
          petId: Number(petId),
          vetClinic: petVets.concat(vet)
        }
      })
    );
    yield put(vetsActionCreators.createVetSuccess());
    yield put(vetsActionCreators.hideVetSearchResults());
    yield put(petProfileActionCreators.hidePetProfileModal());
  } catch (error) {
    yield put(vetsActionCreators.createVetFailure({ error }));
  }
}

function* onDeleteVet({ clinicId }) {
  try {
    yield put(vetsActionCreators.deleteVetRequest());
    const sourceId = yield select(getSourceId);
    const { customerKey, petId } = yield select(getCustomerAndPetKeys);
    yield call(deleteVet, { customerKey, petId, sourceId, clinicId });
    const petVets = yield select(getPetVets, { customerKey, petId });
    yield put(
      petsActionCreators.updatePetSuccess({
        pet: {
          petId: Number(petId),
          vetClinic: petVets.filter(petVet => petVet.vetClinicId !== clinicId)
        }
      })
    );
    yield put(vetsActionCreators.deleteVetSuccess());
    yield put(vetsActionCreators.clearCustomerAndPetKeys());
    yield put(vetsActionCreators.clearVetToDelete());
    yield put(petProfileActionCreators.hidePetProfileModal());
  } catch (error) {
    yield put(vetsActionCreators.deleteVetFailure({ error }));
  }
}

function* onSearchVet({ data }) {
  try {
    yield put(vetsActionCreators.loadVetsSearchResultsRequest());
    const response = yield call(searchVet, { data });
    const searchResults = response.data.records;
    yield put(
      vetsActionCreators.loadVetsSearchResultsSuccess({ searchResults, searchParams: data })
    );
  } catch (error) {
    yield put(vetsActionCreators.loadVetsSearchResultsFailure({ error }));
  }
}

function* watchCreateVet() {
  yield takeEvery(vetsActionTypes.CREATE_VET, onCreateVet);
}

function* watchDeleteVet() {
  yield takeEvery(vetsActionTypes.DELETE_VET, onDeleteVet);
}

function* watchSearchVet() {
  yield takeEvery(vetsActionTypes.LOAD_VET_SEARCH_RESULTS, onSearchVet);
}

export default function* vetsSaga() {
  yield all([watchCreateVet(), watchDeleteVet(), watchSearchVet()]);
}
