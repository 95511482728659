/**
 * Responsible for returning a customer Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getCustomerState
 * @param {object} state
 * @param {Number} customerKey
 * @returns{object} - Reference to the customer (via customer key) object in the redux store
 *
 * @example createSelector([getCustomerState], customerState => customer.firstName;
 */
export default function getCustomerState(state, props) {
  return state.entities.customers[props.customerKey];
}
