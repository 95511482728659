/**
 * Responsible for taking an HTML element and returning the computed line height as a number.
 *
 * @export getComputedLineHeightAsNumber
 * @param {*} element HTML element
 * @returns {Number}
 */
export default function getComputedLineHeightAsNumber(element) {
  // Get all the computed styles from the element
  const compStyles = window.getComputedStyle(element);

  // Get just computed line height
  const compLineHeight = compStyles.getPropertyValue("line-height");

  // Slicing off the px from the string and then returning the computed line height as a base 10 number
  return parseInt(compLineHeight.slice(0, -2), 10);
}
