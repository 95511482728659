import React from "react";
import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";
import {
  selectPetSelectedService,
  selectHotelBookingRoomsHostFirst,
  selectIsServiceSelectedForHostByPetId,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { setHotelBookingCurrentServiceSelectionRoom } from "@/web/features/hotelBookingFlow/hotelBookingFlowActions";
import { HotelBookingRoomAndPetTabs } from "../hotelBookingRoomAndPetTabs/HotelBookingRoomAndPetTabs";
import { HotelMultiPetBookingServiceOptions } from "./HotelBookingServiceOptions";
import { getIconType } from "../hotelBookingRoomAndPetTabs/hotelBookingRoomAndPetTabsHelpers";

const HotelBookingRoomServiceSelectionComponent = ({
  componentID,
  rooms,
  getRoomTabIconType,
  getPetTabIconType,
  isServiceSelectedForHost,
  stepIndex,
}) => {
  return (
    <Layout.Box id={componentID}>
      <HotelBookingRoomAndPetTabs
        componentID={componentID}
        rooms={rooms}
        getRoomTabIconType={getRoomTabIconType}
        getPetTabIconType={getPetTabIconType}
        isServiceSelectedForHost={isServiceSelectedForHost}
      >
        {petId => <HotelMultiPetBookingServiceOptions stepIndex={stepIndex} petId={petId} />}
      </HotelBookingRoomAndPetTabs>
    </Layout.Box>
  );
};

export const HotelBookingRoomServiceSelection = connect(
  (state, { stepIndex }) => {
    const hotelBookingRooms = selectHotelBookingRoomsHostFirst(state);

    return {
      componentID: "hotelBookingFlow-roomServiceSelection",
      rooms: hotelBookingRooms,
      isServiceSelectedForHost: petId => selectIsServiceSelectedForHostByPetId(state, { petId }),
      getRoomTabIconType: roomId => {
        const showCheckIcon = hotelBookingRooms[roomId]?.every(
          petId => !!selectPetSelectedService(petId)(state),
        );
        return getIconType(showCheckIcon);
      },
      getPetTabIconType: petId => {
        const showCheckIcon = !!selectPetSelectedService(petId)(state);
        return getIconType(showCheckIcon);
      },
      stepIndex,
    };
  },
  dispatch => {
    return {
      dispatchSetHotelBookingCurrentServiceSelectionRoom: roomId =>
        dispatch(setHotelBookingCurrentServiceSelectionRoom({ roomId })),
    };
  },
  (mapProps, dispatchProps) => {
    const {
      componentID,
      rooms,
      isServiceSelectedForHost,
      stepIndex,
      getRoomTabIconType,
      getPetTabIconType,
    } = mapProps;
    const { dispatchSetHotelBookingCurrentServiceSelectionRoom } = dispatchProps;

    return {
      // control what props get passed to the view
      componentID,
      rooms,
      stepIndex,
      onBackClick: () => dispatchSetHotelBookingCurrentServiceSelectionRoom(),
      isServiceSelectedForHost,
      getRoomTabIconType,
      getPetTabIconType,
    };
  },
)(HotelBookingRoomServiceSelectionComponent);
