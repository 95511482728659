import React from "react";
import styled from "styled-components";
import { fontSizes } from "web/common/styles/responsive/fonts";
import BulkPackagePurchaseProfileButtonContainer from "../../../bgm/bulkPackageOfferings/cartDetails/BulkPackagePurchaseProfileButtonContainer";
import BulkPackagePurchaseToDashboardButtonContainer from "../../../bgm/bulkPackageOfferings/cartDetails/BulkPackagePurchaseToDashboardButtonContainer";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px 20px;
  opacity: ${props => (props.disabled ? "0.3" : "1")};
`;

const Header = styled.div`
  font-size: ${fontSizes.regular};
  font-weight: bold;
  text-align: center;
`;

const FrequencyModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 28rem;
  height: 1rem;
  padding: 10px;
  margin: 25px 0px;
  align-self: center;
  font-size: ${fontSizes.regular};
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BulkPackagePurchaseModalComponent = ({ componentId, header, body }) => {
  return (
    <>
      <Header>{header}</Header>
      <Container id={componentId}>
        <FrequencyModalWrapper>{body}</FrequencyModalWrapper>
        <ButtonsContainer>
          <BulkPackagePurchaseProfileButtonContainer />
          <BulkPackagePurchaseToDashboardButtonContainer />
        </ButtonsContainer>
      </Container>
    </>
  );
};

export default BulkPackagePurchaseModalComponent;
