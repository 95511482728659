import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ToggleButtonListWithSelectAll } from "dux/filteringButtons/ToggleButtonListWithSelectAll";
import {
  setItineraryHistoryListFilters,
  setItineraryHistoryListVisibleFilters,
  setItineraryHistoryListFiltersToInitial,
} from "./itineraryHistoryListFilterButtonsActions";
import { selectItineraryHistoryListVisibleFilters } from "./itineraryHistoryListFilterButtonsSelectors";
import { engagementTypeIds } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { createLoadingSelector } from "../../core/selectors/utils";
import { GET_SERVICE_PRODUCT_FAMILIES } from "../serviceProductFamilies/serviceProductFamiliesActions";

const { DAY_CAMP, DAY_CARE, OVERNIGHT_BOARDING } = engagementTypeIds;

// MAIN COMPONENT ------------------------------------------------------------------------------------------
const ItineraryHistoryListFilterButtons = ({
  isHidden,
  setVisibleFilters,
  componentID,
  data,
  setGlobalState,
  resetSelectedFilters,
}) => {
  useEffect(() => {
    setVisibleFilters();

    return () => {
      // Clear all filter selections on unmount
      resetSelectedFilters();
    }
  }, []);

  const noVisibleFilters = !data.some(filter => filter.isVisible);
  const filterNamesMissing = !data.some(filter => !!filter.name);
  if (isHidden || noVisibleFilters || filterNamesMissing) return null;

  return (
    <ToggleButtonListWithSelectAll
      componentID={componentID}
      data={data}
      setGlobalState={setGlobalState}
    />
  );
};

// HOTEL ITINERARY HISTORY FILTER BUTTONS ------------------------------------------------------------------
export const HotelItineraryHistoryFilterButtons = connect(
  (state) => ({
    componentID: "itineraryHistoryList__filterButtons--hotel",
    data: selectItineraryHistoryListVisibleFilters(state),
    isHidden: createLoadingSelector([
      GET_SERVICE_PRODUCT_FAMILIES,
    ])(state),
  }),
  dispatch => ({
    setGlobalState: filters => dispatch(setItineraryHistoryListFilters(filters)),
    setVisibleFilters: () =>
      dispatch(setItineraryHistoryListVisibleFilters([DAY_CAMP, DAY_CARE, OVERNIGHT_BOARDING])),
    resetSelectedFilters: () => dispatch(setItineraryHistoryListFiltersToInitial())
  }),
)(ItineraryHistoryListFilterButtons);
