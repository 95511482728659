import { reduce, set } from "lodash/fp";
import moment from "moment";
import getISODateWithTime from "@/core/utils/dateUtils/ISODateWithTime";

export const normalizeSalonHours = salonHours =>
  reduce(
    (result, salonHour) =>
      set(
        moment(salonHour.ForDate).format("YYYY-MM-DD"),
        {
          ...salonHour,
          OpenDateTime: getISODateWithTime(salonHour.ForDate, salonHour.OpenTime),
          CloseDateTime: getISODateWithTime(salonHour.ForDate, salonHour.CloseTime),
        },
        result,
      ),
    {},
    salonHours,
  );
