import {
  LOAD_BOOKING_HOTEL_MANUAL_SPECIALS_SUCCESS,
  LOAD_HOTEL_MANUAL_SPECIALS_SUCCESS,
} from "dux/applySpecial/applyManaulSpecialsHotelActions";

const initialState = [];

export default function applyManualSpecialsHotelReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_HOTEL_MANUAL_SPECIALS_SUCCESS:
      return action.manualSpecials;
    case LOAD_BOOKING_HOTEL_MANUAL_SPECIALS_SUCCESS:
      return action.manualSpecials;
    default:
      return state;
  }
}
