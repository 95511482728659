/**
 * Helper to get a specific address component by type from the google maps response
 * @memberOf helpers.searchAddressByZip
 * @function
 * @name parseGoogleAddressResponse
 * @param {Object} addressComponents - address_components from google response
 * @param {String} type
 * @returns {Object}
 */
export const findAddressComponentByType = (addressComponents, type) =>
  addressComponents?.find(({ types }) => types?.includes(type));

/**
 * Helper to parse the response from the google maps api into an address obj
 * @memberOf helpers.searchAddressByZip
 * @function
 * @name parseGoogleAddressResponse
 * @param {Object} addressComponents - address_components from google response
 *  https://developers.google.com/maps/documentation/geocoding/requests-geocoding#GeocodingResponses
 * @returns {city: string, state: string, country: string}
 */
export const parseGoogleAddressResponse = (addressComponents = []) => {
  const cityObj = findAddressComponentByType(addressComponents, "locality");
  const stateObj = findAddressComponentByType(addressComponents, "administrative_area_level_1");
  const countryObj = findAddressComponentByType(addressComponents, "country");

  return {
    city: cityObj?.short_name ?? "",
    state: stateObj?.short_name ?? "",
    country: countryObj?.short_name ?? "",
  };
};
