import { connect } from "react-redux";
import getAddOnMetaData from "dux/_selectors/getAddOnMetaData";
import PriceComponent from "dux/_components/pricing/PriceComponent";

const mapStateToProps = (state, { addOnId, petId, isHidden }) => {
  const { isAddOnsPriceChange, timeSlot, originalPrice, currentAddOn } = getAddOnMetaData(state, {
    addOnId,
    petId,
  });
  return {
    isHidden: !isAddOnsPriceChange || isHidden,
    componentID: "AddOnFullPrice",
    type: "price-change",
    price: timeSlot
      ? originalPrice?.toFixed(2)
      : // TODO: Opportunity for refactor/investigation. The currentAddOn property's price
        // is null when an add-on is selected from the "rebook" flow (which is derived from
        // the appointment selector in the onRebookClick saga. In this case, we have to
        // default back to the original price so that the price isn't blank in the cart
        // prior to selecting a timeslot.
        currentAddOn?.price?.toFixed(2) || originalPrice?.toFixed(2),
  };
};

export default connect(mapStateToProps)(PriceComponent);
