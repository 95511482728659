import * as actionTypes from "../actionTypes/confirmationModalActionTypes";
import { CONFIRMATION_MODAL_TYPE } from "../constants/modalConstants";
import modalInitState from "./modals/_modalInitState";

const initialState = modalInitState({
  modalType: CONFIRMATION_MODAL_TYPE.CONFIRM,
  header: "",
  content: "",
  confirmText: "OK",
  cancelText: "Cancel",
  confirm: null,
  cancel: null,
  onClose: null,
});

const confirmationModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        show: true,
        ...action.payload,
      };

    case actionTypes.HIDE_CONFIRMATION_MODAL:
      return initialState;

    default:
      return state;
  }
};

export default confirmationModalReducer;
