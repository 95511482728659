import { createSelector } from "reselect";
import { getHotelOvernightAppointments } from "../../columnList/hotelOvernightSelector";
import getSpeciesCount from "../../../hotelArrivals/columnHeader/speciesCount/getSpeciesCount";

export const getHotelOverNightSpeciesCount = createSelector(
  [getHotelOvernightAppointments],
  appointments => {
    return getSpeciesCount(appointments);
  },
);
