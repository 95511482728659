import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { LayoutBox } from "@/layout/box/Box";
import { Button } from "@petsmart-ui/sparky";
import React from "react";
import { connect } from "react-redux";

// Actions
import { clearHotelItinerary } from "dux/hotelItinerary/hotelItineraryActions";

// Selectors
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { REBOOK, REBOOK_RESERVATION } from "./_appointmentRebookConstants";
import { getHotelItinerary } from "../hotelItinerary/hotelItinerarySelectors";

/**
 * React view component for rebook button
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name AppointmentRebookButton
 * @param {Object} props - props passed into the view component
 * @param {String} props.componentId
 * @param {Boolean} props.isHidden
 * @param {String} props.text
 * @param {Function} props.onClick
 * @returns {JSX.Element|null}
 * @example <AppointmentRebookButton />
 */
export const AppointmentRebookButton = props => {
  const { componentId, isHidden, text = REBOOK, onClick, size } = props;
  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} style={{ paddingLeft: 0 }}>
      <Button text={text} variant="link" size={size} onClick={onClick} />
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the hotel AppointmentRebookButton
 * @see {@link Views.Hotel.Itinerary.AppointmentRebookButton}
 * @summary Located on the pet parent profile
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name  HotelRebookButton
 * @param {Object} props
 * @param {String} props.itineraryId
 * @returns {JSX.Element|null}
 * @example <HotelRebookButton />
 */
export const HotelRebookButton = withRouteProps(
  connect((state, { itineraryId, router: { navigate } }) => {
    const customerKey = getCurrentCustomerKey(state);

    return {
      componentId: `HotelRebookButton__${itineraryId}`,
      size: "lg",
      onClick: e => {
        e.stopPropagation(); // don't trigger click on parent components (pet card)
        navigate(`/booking/${customerKey}/select-service/${itineraryId}`);
      },
    };
  })(AppointmentRebookButton),
);

/**
 * Redux Connect function for the hotel check in/out page rebook button
 * @see {@link Views.Hotel.Itinerary.AppointmentRebookButton}
 * @summary Located on the hotel check in & out pages
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name  HotelCheckInOutRebookButton
 * @returns {JSX.Element|null}
 * @example <HotelCheckInOutRebookButton />
 */
export const HotelCheckInOutRebookButton = withRouteProps(
  connect(
    state => {
      const customerKey = getCurrentCustomerKey(state);
      const { itineraryId, storeNumber } = getHotelItinerary(state) ?? {};

      // Make sure store number of itinerary matches logged in store
      const currentStore = getStoreNumber(state);
      const isCurrentStore = currentStore === storeNumber;

      return {
        componentId: `HotelCheckInOutRebookButton__${itineraryId}`,
        customerKey,
        itineraryId,
        isHidden: !isCurrentStore,
        text: REBOOK_RESERVATION,
      };
    },
    (dispatch, { router: { navigate } }) => {
      return {
        clearPageData: (customerKey, itineraryId) => {
          dispatch(clearHotelItinerary());

          navigate(`/booking/${customerKey}/select-service/${itineraryId}`);
        },
      };
    },
    (propsFromState, propsFromDispatch) => {
      const { componentId, customerKey, itineraryId, isHidden, text } = propsFromState;

      const { clearPageData } = propsFromDispatch;

      return {
        componentId,
        isHidden,
        text,
        onClick: e => {
          e.stopPropagation(); // don't trigger click on parent components (pet card)
          clearPageData(customerKey, itineraryId);
        },
      };
    },
  )(AppointmentRebookButton),
);
