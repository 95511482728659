import moment from "moment";
import { createSelector } from "reselect";
import {
  getEngagement,
  getPet,
  getPetBreedName,
  getPetServiceItem,
  getAssociate,
} from "@/core/selectors/entitiesSelector";
import calculateAge from "@/core/utils/dateUtils/calculateAge";
import { getState } from "@/core/selectors/commonSelector";
import getUSDateString from "@/core/utils/dateUtils/usDate";

export const getRefundConflictAppointmentPetCardInfo = createSelector(
  [getEngagement, getPet, getState],
  (appointment, pet, state) => {
    const petServiceItem = getPetServiceItem(state, {
      petServiceItemId: appointment?.engagementId,
    });
    const associate = getAssociate(state, { associateId: petServiceItem?.associate });
    const startDateTime = petServiceItem?.startDateTime;
    const endDateTime = petServiceItem?.endDateTime;

    return {
      pet,
      petService: petServiceItem?.petServiceName,
      petName: pet?.petName,
      petAge: calculateAge(pet?.birthDate),
      petBreed: getPetBreedName(state, { breedId: pet?.breedId }),
      timeRange: `${moment(startDateTime).format("LT")} - ${moment(endDateTime).format("LT")}`,
      date: getUSDateString(startDateTime),
      associateName: associate?.associateName,
    };
  },
);
