import { Layout, Text } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import React from "react";
import { getPendingAppointmentPetServiceAtHotelByPet } from "web/pendingAppointment/selectors/pendingAppointmentSelectors";
import { formatMoney } from "dux/_utils/moneyUtil";
import { selectHotelCartPetPrimaryServiceProduct } from "dux/servicesCartHotel/servicesCartHotelSelectors";

export const ServiceTypePrice = ({ componentId, serviceTypeName, serviceTypePrice }) => {
  return (
    <Layout.Cluster id={componentId} justify="space-between" style={{ padding: "0 1.5em .5em" }}>
      <Text id={`${componentId}__serviceTypeName`}>{serviceTypeName}</Text>
      <Text id={`${componentId}__serviceTypePrice`}>{serviceTypePrice}</Text>
    </Layout.Cluster>
  );
};

export const HotelBookingCartServiceTypePrice = connect((state, ownProps) => {
  const { petId } = ownProps;
  const service = getPendingAppointmentPetServiceAtHotelByPet(petId)(state);
  const cartServiceInformation = selectHotelCartPetPrimaryServiceProduct(petId)(state);

  const pricing = cartServiceInformation?.pricing || {};

  const { quantity, finalPrice, pricePerUnit } = pricing;

  const priceText =
    quantity === 1
      ? formatMoney(finalPrice)
      : `${formatMoney(pricePerUnit)} /night x ${quantity} = ${formatMoney(finalPrice)}`;

  return {
    componentId: "hotelBooking_HotelBookingCartServiceTypePrice",
    serviceTypeName: service?.associateDisplayName,
    serviceTypePrice: priceText,
  };
})(ServiceTypePrice);
