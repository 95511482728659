import { compose } from "lodash/fp";
import { stripEmojis } from "core/utils/stringUtils/unicodeUtils";
import { truncateStringToLimit } from "core/utils/stringUtils/truncateStringUtil";
import { removeNumbers } from "core/utils/stringUtils/numbersInStringsUtils";

/**
 * Cleans a string (e.g. will remove all numbers, emojis and limit the strong to a specified number of characters.
 * @memberOf Utils.Strings
 * @function
 * @name truncateStringToLimit
 * @param {string} input - the string you want cleaned
 * @param {number} limit - The number of characters to you would like to limit the string to
 * @returns string
 * @example
 * const truncatedString = truncateStringToLimit(5)
 * const shortString = truncatedString("foo bar baz")
 */
export const getCleanedField = (input, limit) => {
  // Order of composition: Applies functions from right to left. So, it will first truncateStringToLimit(limit), then removeNumbers and finally stripEmojis from the input string.
  const cleaned = compose(stripEmojis, removeNumbers, truncateStringToLimit(limit));

  return cleaned(input);
};

/**
 * Cleans the whitespace in a string
 * @memberOf Utils.Strings
 * @function
 * @name minimizeWhitespace
 * @param {string} str - the string you want cleaned
 * @returns string
 * @example minimizeWhitespace("     ") // returns " "
 */
export const minimizeWhitespace = str => str?.replace(/\s+/g, " ");
