import { createSelector } from "reselect";
import { selectSelectedReportDate } from "../reportDateSelection/reportDateSelectionSelectors";
import { selectSelectedReportDataWithFilters } from "../reports/reportsSelectors";
import { filterAndListObjectByKeys } from "@/core/utils/objectUtils/filterObjects";
import { abbreviateDelimitedString } from "@/core/utils/stringManipulationUtils/abbreviateString";
import { isEmpty } from "lodash/fp";
import moment from "moment";
import momentTz from "moment-timezone";
import { selectCurrentStoreTimeZone } from "@/core/selectors/persistentSelectors";
import { chunk } from "lodash/fp";

/**
 * Selector to get report data, and return report data but with a modified and abbreviated time of day
 * @memberOf Selectors.Reports
 * @function
 * @name selectReportDataWithAbbreviatedTimeOfDay
 * @param {Object} state
 * @returns {Array} selected report with abbreviated time of day
 * @example const reportData = selectReportDataWithAbbreviatedTimeOfDay(state);
 */
export const selectReportDataWithAbbreviatedTimeOfDay = createSelector(
  [selectSelectedReportDataWithFilters],
  reportData => {
    return reportData.map(item => {
      if ((item?.timeOfDay?.match(/,/g) || []).length > 1) {
        return {
          ...item,
          timeOfDay: abbreviateDelimitedString(item.timeOfDay, ",", 3),
        };
      }
      return item;
    });
  },
);

/**
 * Selector to get report data, and return report data but with a formated date
 * @memberOf Selectors.Reports
 * @function
 * @name selectReportDataWithAbbreviatedTimeOfDay
 * @param {Object} state
 * @returns {Array} selected report with a formated date
 * @example const reportData = selectReportDataWithAbbreviatedTimeOfDay(state);
 */
export const selectReportDataWithAddedDate = createSelector(
  [selectReportDataWithAbbreviatedTimeOfDay, selectSelectedReportDate, selectCurrentStoreTimeZone],
  (reportData, reportDate, storeTimeZone) => {
    return reportData.map(item => {
      return {
        ...item,
        date: isEmpty(reportDate)
          ? momentTz.tz(moment(), storeTimeZone).format("MM/DD/YYYY")
          : momentTz.tz(reportDate, storeTimeZone).format("MM/DD/YYYY"),
      };
    });
  },
);

/**
 * Selector to get Label data
 * @memberOf Selectors.Reports
 * @function
 * @name selectLabelData
 * @param {Object} state
 * @returns {Array} selected report as an array of objects
 * @example const labelData = selectLabelData(state);
 */
export const selectLabelData = createSelector([selectReportDataWithAddedDate], reportData => {
  return reportData.map(item => {
    const isFood = Object.keys(item).includes("foodName");
    const name = isFood ? "foodName" : "medicationName";

    /*
        NOTE: the order of the key names are important to how the labels will display
    */
    return filterAndListObjectByKeys(
      [
        "petName",
        "breed",
        "roomNumber",
        "color",
        "date",
        "amount",
        "timeOfDay",
        name,
        "instructions",
      ],
      item,
    );
  });
});

/**
 * Selector to get Label data in a chucked format e.g. [ [ {"petName": "Kloe"}, {"breed": "Jack Russell Terrier - Rough"} ]...[ {"instructions": null} ] ]
 * @memberOf Selectors.Reports
 * @function
 * @name selectChunkedLabelData
 * @param {Object} state
 * @returns {Array} selected report as an array of chunked objects
 * @example const labelData = selectChunkedLabelData(state);
 */
export const selectChunkedLabelData = createSelector([selectLabelData], labelData => {
  return labelData.map(items => {
    const splitIndex = 2;

    const firstItems = items?.slice(0, items.length - splitIndex);

    const lastItems = items?.slice(items.length - splitIndex);

    const pairedItems1 = chunk(2, firstItems);
    const pairedItems2 = chunk(1, lastItems);

    const newList = [...pairedItems1, ...pairedItems2];

    return newList;
  });
});
