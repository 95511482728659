import { connect } from "react-redux";
import { getPetsByIds, getPetsByItinerary } from "core/selectors/entitiesSelector";
import { getCurrentItinerary } from "core/selectors/checkInOutSelector";
import getUnknownPetImage from "core/utils/assetUtils/unknownPetImage";
import PetImagesComponent from "dux/_components/petImages/PetImagesComponent";

const mapStateToProps = state => {
  const itineraryId = getCurrentItinerary(state);
  const petIds = getPetsByItinerary(state, { itineraryId });
  const pets = getPetsByIds(state, { petIds });

  return {
    isHidden: false,
    petPhotos: pets.map(pet => ({ petId: pet.petId, petPhoto: getUnknownPetImage(pet) })),
  };
};

export default connect(mapStateToProps)(PetImagesComponent);
