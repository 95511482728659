import { connect } from "react-redux";
import SpeciesCounts from "../../../_components/SpeciesCount/SpeciesCounts";
import { getHotelDeparturesSpeciesCount } from "./HotelDeparturesSpeciesCountSelector";

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => {
  const departures = getHotelDeparturesSpeciesCount(state);
  return {
    isHidden: false,
    componentId: "SpeciesCounts__HotelOverNight",
    appointments: { dog: departures.dogCount, cat: departures.catCount },
  };
};

// const mapDispatchToProps = dispatch => ({
//   keyName: ({}) => dispatch(actionName({})),
// });

// const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
//   ...propsFromState,
//   ...propsFromDispatch,
//   ...ownProps,
//
//   keyName: ({}) => propsFromDispatch.functionName({
//     keyName: propsFromState.value,
//   }),
//   keyName: ({}) => propsFromDispatch.functionName({
//     keyName: propsFromState.value,
//   }),
// });

export default connect(
  mapStateToProps,
  // mapDispatchToProps,
  // mergeProps,
)(SpeciesCounts);
