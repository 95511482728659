import { put, takeEvery, call, all, select } from "redux-saga/effects";
import { updatePetSuccess } from "../../core/actionCreators/petsActionCreators";
import {
  petFavorabilitySalonActionTypes,
  createPetFavorabilityFailure,
  createPetFavorabilityRequest,
  createPetFavorabilitySuccess,
  updatePetFavorabilityFailure,
  updatePetFavorabilityRequest,
  updatePetFavorabilitySuccess,
} from "./petFavorabilitySalonActions";
import {
  postPetFavorability,
  putPetFavorability,
} from "../../core/services/associateWebProfile/petFavorabilityEndPoints";
import { getPetFavorability } from "./petFavorabilitySelectors";
import { getSourceId } from "../../core/selectors/persistentSelectors";
import { getPetEligibilityAfterChange } from "../eligibility/actions/eligibilityPetActions";

function* onCreatePetFavorability({ customerKey, petId, formValues }) {
  try {
    yield put(createPetFavorabilityRequest());
    const sourceId = yield select(getSourceId);

    const data = {
      RatingId: formValues.ratingTypeId,
      ratingReason: formValues.ratingReason,
      sourceId,
    };

    const response = yield call(postPetFavorability, { customerKey, petId, data });
    const PetFavorability = response.data;

    yield put(
      updatePetSuccess({
        pet: {
          petId: Number(petId),
          petRatings: PetFavorability,
        },
      }),
    );
    yield put(createPetFavorabilitySuccess());
    yield put(getPetEligibilityAfterChange({ petId, customerKey }));
  } catch (error) {
    yield put(createPetFavorabilityFailure({ error }));
  }
}

function* onUpdatePetFavorability({ customerKey, petId, formValues }) {
  try {
    yield put(updatePetFavorabilityRequest());
    const PetFavorability = yield select(getPetFavorability, { petId });

    if (PetFavorability) {
      const data = {
        RatingId: formValues.ratingTypeId,
        ratingReason: formValues.ratingReason ? formValues.ratingReason : "",
        sourceId: PetFavorability[0].updateSourceId
          ? PetFavorability[0].updateSourceId
          : PetFavorability[0].sourceId,
      };

      const response = yield call(putPetFavorability, {
        customerKey,
        petId,
        ratingId: PetFavorability[0].petRatingId
          ? PetFavorability[0].petRatingId
          : PetFavorability[0].PetratingId,
        data,
      });
      const UpdatedPetFavorability = response.data;

      yield put(
        updatePetSuccess({
          pet: {
            petId: Number(petId),
            petRatings: UpdatedPetFavorability,
          },
        }),
      );

      yield put(updatePetFavorabilitySuccess());
      yield put(getPetEligibilityAfterChange({ petId, customerKey }));
    }
  } catch (error) {
    yield put(updatePetFavorabilityFailure({ error }));
  }
}

function* watchCreatePetCondition() {
  yield takeEvery(petFavorabilitySalonActionTypes.CREATE_PET_FAVORABILITY, onCreatePetFavorability);
}

function* watchUpdatePetFavorability() {
  yield takeEvery(petFavorabilitySalonActionTypes.UPDATE_PET_FAVORABILITY, onUpdatePetFavorability);
}

export default function* PetFavorabilitySaga() {
  yield all([watchCreatePetCondition(), watchUpdatePetFavorability()]);
}
