import { createSelector } from "reselect";
import { getHotelDeparturesAppointments } from "../../columnList/hotelDeparturesSelector";
import getSpeciesCount from "../../../hotelArrivals/columnHeader/speciesCount/getSpeciesCount";

// eslint-disable-next-line import/prefer-default-export
export const getHotelDeparturesSpeciesCount = createSelector(
  [getHotelDeparturesAppointments],
  appointments => {
    return getSpeciesCount(appointments);
  },
);
