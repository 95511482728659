import React from "react";
import styled from "styled-components";

/**
 * Responsible for Providing clickable text with fully parameterized CSS for maximum flexibility.
 *
 * @export ClickableText
 * @param {Object} obj - Destructuring parameters
 * @param { Object } obj.children - Generally a JSX object but can be any object
 * @param { Object } obj.styleObj - Object Literal of css, e.g. styleObj: { fontSize: '16px', color: color.victorStone, } etc.
 * @param { Boolean } obj.disabled - if text is disabled.
 * @param { Function } obj.onClick - handler function
 * @returns - JSX
 */
export default function ClickableText({
  children,
  styleObj,
  disabled,
  onClick,
  isVisible,
  label,
  componentID,
}) {
  if (isVisible) {
    return (
      <Container
        data-testid="ClickableText"
        id={componentID}
        styleObj={styleObj}
        disabled={disabled}
        onClick={!disabled ? onClick : e => e.stopPropagation()}
      >
        {label || children}
      </Container>
    );
  }

  return null;
}

/*
  -- STYLES --
  NOTE: To extend just add your Property here with the following pattern:
    styleObj && styleObj.[camelCaseProperty] || [defaultProperty]

  This way if you need to add a property for your specific use case, you do not need to
  touch each instance of the ClickableText object.

  Also note the defaultProperty you set will be for all objects using the ClickableText
  so a good solution may be 'inherit'
*/
const Container = styled.div`
  font-size: ${({ styleObj }) => (styleObj && styleObj.fontSize) || "inherit"};

  color: ${({ styleObj, disabled }) =>
    disabled ? "rgba(0, 0, 0, 0.5)" : (styleObj && styleObj.color) || "black"};

  font-weight: ${({ styleObj }) => (styleObj && styleObj.fontWeight) || "inherit"};

  text-decoration-line: ${({ styleObj }) => (styleObj && styleObj.textDecorationLine) || "inherit"};

  font-family: ${({ styleObj }) => (styleObj && styleObj.fontFamily) || "inherit"};

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  padding: ${({ styleObj }) => (styleObj && styleObj.padding) || "5px"};

  user-select: none;

  pointer-events: auto;

  &:hover {
    opacity: 0.5;
  }

  &:last-child {
    padding-right: ${({ styleObj }) => styleObj && !styleObj.padding && "0"};
  }
`;
