export const hotelBookingTypes = {
  HOTEL_BOOKING_ALERT_MODAL: "HOTEL_BOOKING_ALERT_MODAL",
  HOTEL_MANUAL_BOOKING_CONFIRMATION_MODAL: "HOTEL_MANUAL_BOOKING_CONFIRMATION_MODAL",
  HOTEL_APPOINTMENT_SET: "HOTEL_APPOINTMENT_SET",
  HOTEL_BOOKING_RESTART_MODAL: "HOTEL_BOOKING_RESTART_MODAL",
  PRE_EXISTING_RESERVATION_MODAL: "PRE_EXISTING_RESERVATION_MODAL",
  REBOOKING_ADDON_NOT_AVAILABLE: "REBOOKING_ADDON_NOT_AVAILABLE",
  REBOOKING_FOOD_NOT_AVAILABLE: "REBOOKING_FOOD_NOT_AVAILABLE",
  REBOOKING_MED_NOT_AVAILABLE: "REBOOKING_MED_NOT_AVAILABLE",
};
