import { endpointVersionString } from "../../utils/envUtils";
import { associateWebPetProfileAPI } from "./_axiosConfigAssociateWebPetProfile";

/**
 * Add a medication to a pet from the Web Associate Profile API - Add pet's medication end point
 * @param {*} { petId, sourceId, data }
 * @returns
 */
export function postPetMedication({ petId, sourceId, data }) {
  return associateWebPetProfileAPI.post(
    `${endpointVersionString()}/pets/${petId}/medication`,
    data,
  );
}

/**
 * Add a medication to a pet from the Web Associate Profile API - Add pet's medication end point
 * @param {*} { petId, sourceId, data }
 * @returns
 */
export function putPetMedication({ petId, sourceId, medicationId, data }) {
  return associateWebPetProfileAPI.put(
    `${endpointVersionString()}/pets/${petId}/medication/${medicationId}`,
    data,
  );
}

/**
 * Delete a medication to a pet from the Web Associate Profile API - Add pet's medication end point
 * @param {*} { customerKey, petId, sourceId, data }
 * @returns
 */
export function deletePetMedication({ medicationId, petId, sourceId }) {
  return associateWebPetProfileAPI.delete(
    `${endpointVersionString()}/pets/${petId}/medication/${medicationId}`,
  );
}
