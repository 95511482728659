import authActionTypes from "../actionTypes/authActionTypes";

/** @description Authorize a User
 * @summary Authorizes a user with only a store number and session token
 */

export const authorizeUser = ({ accessToken, idToken, pathname }) => ({
  type: authActionTypes.AUTHORIZE_USER,
  accessToken,
  idToken,
  pathname,
});

export const authorizeUserRequest = () => ({
  type: authActionTypes.AUTHORIZE_USER_REQUEST,
});

export const authorizeUserSuccess = () => ({
  type: authActionTypes.AUTHORIZE_USER_SUCCESS,
});

export const authorizeUserFailure = ({ error }) => ({
  type: authActionTypes.AUTHORIZE_USER_FAILURE,
  error,
});

/**
 * @description Authorize User by PIN
 * @summary Authorizes a given user by providing their store number and PIN
 */

export const authorizeUserByPin = ({ pin }) => ({
  type: authActionTypes.AUTHORIZE_USER_BY_PIN,
  pin,
});

export const authorizeUserByPinRequest = () => ({
  type: authActionTypes.AUTHORIZE_USER_BY_PIN_REQUEST,
});

export const authorizeUserByPinSuccess = () => ({
  type: authActionTypes.AUTHORIZE_USER_BY_PIN_SUCCESS,
});

export const authorizeUserByPinFailure = ({ error }) => ({
  type: authActionTypes.AUTHORIZE_USER_BY_PIN_FAILURE,
  error,
});

/**
 * @description Fetch Authorized Users
 * @summary Fetch all users who have been authorized today
 */

export const fetchAuthorizedUsers = () => ({
  type: authActionTypes.FETCH_AUTHORIZED_USERS,
});

export const fetchAuthorizedUsersRequest = () => ({
  type: authActionTypes.FETCH_AUTHORIZED_USERS_REQUEST,
});

export const fetchAuthorizedUsersSuccess = () => ({
  type: authActionTypes.FETCH_AUTHORIZED_USERS_SUCCESS,
});

export const fetchAuthorizedUsersFailure = ({ error }) => ({
  type: authActionTypes.FETCH_AUTHORIZED_USERS_FAILURE,
  error,
});

/** @description Set or Update User PIN
 * @summary Sets or updates the PIN value for a given user
 */

export const setOrUpdateUserPin = ({ accessToken, pin }) => ({
  type: authActionTypes.SET_OR_UPDATE_USER_PIN,
  accessToken,
  pin,
});

export const setOrUpdateUserPinRequest = () => ({
  type: authActionTypes.SET_OR_UPDATE_USER_PIN_REQUEST,
});

export const setOrUpdateUserPinSuccess = () => ({
  type: authActionTypes.SET_OR_UPDATE_USER_PIN_SUCCESS,
});

export const setOrUpdateUserPinFailure = ({ error }) => ({
  type: authActionTypes.SET_OR_UPDATE_USER_PIN_FAILURE,
  error,
});

/** @description Logout Users
 * @summary Logs a user out and revokes their Okta access token
 */

export const logoutUser = ({ browserSessionOnly = true }) => ({
  type: authActionTypes.LOGOUT_USER,
  browserSessionOnly,
});

export const logoutUserRequest = () => ({
  type: authActionTypes.LOGOUT_USER_REQUEST,
});

export const logoutUserSuccess = () => ({
  type: authActionTypes.LOGOUT_USER_SUCCESS,
});

export const logoutUserFailure = ({ error }) => ({
  type: authActionTypes.LOGOUT_USER_FAILURE,
  error,
});

/** @description Logout Store
 * @summary Logs ALL users out and revokes their Okta access token
 */

export const logoutStore = () => ({
  type: authActionTypes.LOGOUT_STORE,
});

export const logoutStoreRequest = () => ({
  type: authActionTypes.LOGOUT_STORE_REQUEST,
});

export const logoutStoreSuccess = () => ({
  type: authActionTypes.LOGOUT_STORE_SUCCESS,
});

export const logoutStoreFailure = ({ error }) => ({
  type: authActionTypes.LOGOUT_STORE_FAILURE,
  error,
});

/** @description Refresh session token
 * @summary Refereshes a user session token for a given user
 */

export const refreshSessionToken = (duration) => ({
  type: authActionTypes.REFRESH_SESSION_TOKEN,
  duration,
});

export const refreshSessionTokenRequest = () => ({
  type: authActionTypes.REFRESH_SESSION_TOKEN_REQUEST,
});

export const refreshSessionTokenSuccess = () => ({
  type: authActionTypes.REFRESH_SESSION_TOKEN_SUCCESS,
});

export const refreshSessionTokenFailure = ({ error }) => ({
  type: authActionTypes.REFRESH_SESSION_TOKEN_FAILURE,
  error,
});

/** @description Session Data Utils
 * @summary Sets & remove information related to session data such as tokens and profile info
 */

export const setAccessToken = ({ accessToken }) => ({
  type: authActionTypes.SET_ACCESS_TOKEN,
  accessToken,
});

export const setIdToken = ({ idToken }) => ({
  type: authActionTypes.SET_ID_TOKEN,
  idToken,
});

export const setSessionToken = ({ sessionToken, duration }) => ({
  type: authActionTypes.SET_SESSION_TOKEN,
  sessionToken,
  duration,
});

export const clearSessionToken = () => ({
  type: authActionTypes.CLEAR_SESSION_TOKEN,
});

export const setUserSession = sessionData => ({
  type: authActionTypes.SET_USER_SESSION,
  sessionData,
});

export const clearUserSession = () => ({
  type: authActionTypes.CLEAR_USER_SESSION,
});

export const setAuthenticatedWithQuickPin = () => ({
  type: authActionTypes.SET_AUTHENTICATED_WITH_QUICK_PIN,
});

export const clearAuthenticatedWithQuickPin = () => ({
  type: authActionTypes.CLEAR_AUTHENTICATED_WITH_QUICK_PIN,
});

export const setAuthenticatedUsers = ({ users }) => ({
  type: authActionTypes.SET_AUTHENTICATED_USERS,
  users,
});

export const setSelectedUser = ({ name, username }) => ({
  type: authActionTypes.SET_SELECTED_USER,
  name,
  username,
});

export const clearSelectedUser = () => ({
  type: authActionTypes.CLEAR_SELECTED_USER,
});

export const setSessionTimeRemaining = ({ milliseconds }) => ({
  type: authActionTypes.SET_SESSION_TIME_REMAINING,
  milliseconds,
});

export const sessionTimeout = () => ({ type: authActionTypes.SESSION_TIMEOUT });

export const setInvalidPinError = ({ invalidPinError }) => ({
  type: authActionTypes.SET_INVALID_PIN_ERROR,
  invalidPinError,
});

export const clearInvalidPinError = () => ({
  type: authActionTypes.CLEAR_INVALID_PIN_ERROR,
});

export const showAuthModal = ({ modalType }) => ({
  type: authActionTypes.SHOW_AUTH_MODAL,
  modalType,
});

export const hideAuthModal = () => ({
  type: authActionTypes.HIDE_AUTH_MODAL,
});

export const setRedirectToForgotPin = () => ({
  type: authActionTypes.SET_REDIRECT_TO_FORGOT_PIN,
});

export const clearRedirectToForgotPin = () => ({
  type: authActionTypes.CLEAR_REDIRECT_TO_FORGOT_PIN,
});

export const setRedirectToSetPin = () => ({
  type: authActionTypes.SET_REDIRECT_TO_SET_PIN,
});

export const clearRedirectToSetPin = () => ({
  type: authActionTypes.CLEAR_REDIRECT_TO_SET_PIN,
});

export const cancelRefresh = () => ({
  type: authActionTypes.CANCEL_REFRESH,
});

export const loginWithOkta = ({ route, storeNumber, systemType }) => ({
  type: authActionTypes.LOGIN_WITH_OKTA,
  route,
  storeNumber,
  systemType,
});

export const setSecureRedirectRoute = ({ route }) => ({
  type: authActionTypes.SET_SECURE_REDIRECT_ROUTE,
  secureRedirectRoute: route,
});

export const setAuthenticatedSubscriptionKey = authenticatedSubscriptionKey => ({
  type: authActionTypes.SET_AUTHENTICATED_SUBSCRIPTION_KEY,
  authenticatedSubscriptionKey,
});

export default {
  authorizeUser,
  authorizeUserRequest,
  authorizeUserSuccess,
  authorizeUserFailure,
  authorizeUserByPin,
  authorizeUserByPinRequest,
  authorizeUserByPinSuccess,
  authorizeUserByPinFailure,
  fetchAuthorizedUsers,
  fetchAuthorizedUsersRequest,
  fetchAuthorizedUsersSuccess,
  fetchAuthorizedUsersFailure,
  setOrUpdateUserPin,
  setOrUpdateUserPinRequest,
  setOrUpdateUserPinSuccess,
  setOrUpdateUserPinFailure,
  logoutUser,
  logoutUserRequest,
  logoutUserSuccess,
  logoutUserFailure,
  logoutStore,
  logoutStoreRequest,
  logoutStoreSuccess,
  logoutStoreFailure,
  refreshSessionToken,
  refreshSessionTokenRequest,
  refreshSessionTokenSuccess,
  refreshSessionTokenFailure,
  setAccessToken,
  setIdToken,
  setSessionToken,
  clearSessionToken,
  setUserSession,
  clearUserSession,
  setAuthenticatedWithQuickPin,
  clearAuthenticatedWithQuickPin,
  setAuthenticatedUsers,
  setSelectedUser,
  clearSelectedUser,
  setSessionTimeRemaining,
  sessionTimeout,
  setInvalidPinError,
  clearInvalidPinError,
  showAuthModal,
  hideAuthModal,
  setRedirectToForgotPin,
  clearRedirectToForgotPin,
  setRedirectToSetPin,
  clearRedirectToSetPin,
  cancelRefresh,
  loginWithOkta,
  setSecureRedirectRoute,
  setAuthenticatedSubscriptionKey,
};
