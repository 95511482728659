import React from "react";
import styled from "styled-components";
import { isEmpty, filter, get } from "lodash/fp";
import { color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import { APPOINTMENT_DETAILS_TYPES } from "../../core/constants";
import LoadingWrapper from "../common/LoadingWrapper";
import BulkPackageOfferingsCartDetailsContainer from "../../dux/bgm/bulkPackageOfferings/cartDetails/BulkPackageOfferingsCartDetailsContainer";
import CartFooter from "./CartFooterContainer";
import FirstTimeCustomerIndicatorAppointmentViewContainer from "@/dux/bgm/ftco/FirstTimeCustomerIndicatorAppointmentViewContainer";
import EligiblePromotionsBGMAppointmentViewContainer from "dux/eligiblePromotions/EligiblePromotionsBGMAppointmentViewContainer";
import EligiblePromotionsFTCOAppointmentViewContainer from "dux/eligiblePromotions/EligiblePromotionsFTCOAppointmentViewContainer";
import { PetParentCartSummary } from "@/dux/petParentCartSummary/PetParentCartSummary";

const StyledCart = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;

  > *:not(:last-child) {
    border-bottom: 1px solid ${color.borderGrey};
  }
`;

const StyledAppointmentsDetails = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 2rem 0;
  overflow: auto;
`;

const SingleAppointmentContainer = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 1rem 0;
`;

const AppointmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AppointmentTitle = styled.h3`
  padding: 0 ${({ multiplePets }) => (multiplePets ? 0 : 1.5)}rem;
  display: flex;
  flex-direction: column;
`;

const AppointmentHeader = styled.div`
  padding-bottom: 1em;
`;

const AddressWrapper = styled.div`
  font-weight: normal;
  font-size: ${fontSizes.regular};
`;

class CartComponent extends React.Component {
  componentDidMount() {
    const { initializeCart } = this.props;
    initializeCart();
  }

  componentWillUnmount() {
    const { clearCart } = this.props;

    clearCart();
  }

  render() {
    const {
      customerKey,
      appointments,
      footerActions,
      servicesForPet,
      multiplePets,
      isQuickQuote,
      appointmentDetailsType,
      selectedPet,
      isLoading,
      storeData,
      petId,
      isHidden,
      diComp,
    } = this.props;

    const petIds = Object.keys(appointments) || [];
    const selectedPetString = selectedPet && selectedPet.toString();
    const sortedPetIds = get(selectedPetString, appointments)
      ? [selectedPetString, ...filter(id => id !== selectedPetString, petIds)]
      : petIds;

    const CartDetailsContainer = diComp;

    return !isHidden ? (
      <LoadingWrapper isLoading={isLoading}>
        <CartWrapper>
          <StyledCart>
            {multiplePets && customerKey && <PetParentCartSummary />}
            {!isEmpty(servicesForPet) &&
              appointmentDetailsType !== APPOINTMENT_DETAILS_TYPES.FUTURE_APPOINTMENT &&
              appointmentDetailsType !== APPOINTMENT_DETAILS_TYPES.PAST_APPOINTMENT && (
                <CartFooter
                  footerActions={footerActions}
                  multiplePets={multiplePets}
                  petId={petId}
                  selectedPet={selectedPet}
                  itineraryId={get("router.params.itineraryId", this.props)}
                />
              )}

            {/*  PACKAGE OFFERING INDICATORS */}
            <FirstTimeCustomerIndicatorAppointmentViewContainer />
            <EligiblePromotionsFTCOAppointmentViewContainer />
            <EligiblePromotionsBGMAppointmentViewContainer />

            <AppointmentsWrapper>
              <AppointmentTitle multiplePets={multiplePets}>
                <AppointmentHeader>
                  Appointment {multiplePets ? "summary" : "Details"}
                </AppointmentHeader>
                {!appointmentDetailsType && (
                  <>
                    <AddressWrapper>
                      {storeData && `${storeData.storeName} #${storeData.storeNumber}`}
                    </AddressWrapper>
                    <AddressWrapper>{storeData && storeData.storeAddress}</AddressWrapper>
                  </>
                )}
              </AppointmentTitle>

              <BulkPackageOfferingsCartDetailsContainer />

              {multiplePets ? (
                <StyledAppointmentsDetails>
                  {sortedPetIds.map(id => (
                    <CartDetailsContainer
                      key={id}
                      petId={id}
                      multiplePets={multiplePets}
                      isQuickQuote={isQuickQuote}
                    />
                  ))}
                </StyledAppointmentsDetails>
              ) : (
                <SingleAppointmentContainer>
                  {appointments[petId] && (
                    <CartDetailsContainer
                      key={petId}
                      petId={petId}
                      appointmentDetailsType={appointmentDetailsType}
                    />
                  )}
                </SingleAppointmentContainer>
              )}
            </AppointmentsWrapper>
          </StyledCart>
        </CartWrapper>
      </LoadingWrapper>
    ) : null;
  }
}

export default CartComponent;
