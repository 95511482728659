/* * ----------------------------------------------------------------------- * *\
  Actions Types
\* * ----------------------------------------------------------------------- * */
export const GET_MEDICATIONS_LIST = "GET_MEDICATIONS_LIST";
export const GET_MEDICATIONS_LIST_REQUEST = "GET_MEDICATIONS_LIST_REQUEST";
export const GET_MEDICATIONS_LIST_SUCCESS = "GET_MEDICATIONS_LIST_SUCCESS";
export const GET_MEDICATIONS_LIST_FAILURE = "GET_MEDICATIONS_LIST_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getMedicationsList = () => ({
  type: GET_MEDICATIONS_LIST,
});

export const getMedicationsListRequest = payload => ({
  type: GET_MEDICATIONS_LIST_REQUEST,
  payload,
});

export const getMedicationsListSuccess = payload => ({
  type: GET_MEDICATIONS_LIST_SUCCESS,
  payload,
});

export const getMedicationsListFailure = payload => ({
  type: GET_MEDICATIONS_LIST_FAILURE,
  payload,
});
