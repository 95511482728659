// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pplt-layout__cluster {
    /* ↓ Set the setClusterSpace as a variable */
    --setClusterSpace: 0;
    --setClusterWrap: wrap;
  }
  .pplt-layout__cluster > * {
    /* ↓ Establish the flex context and allow wrapping */
    display: flex;
    flex-wrap: var(--setClusterWrap);

    /* ↓ Horizontal alignment */
    justify-content: flex-start;

    /* ↓ Vertical alignment */
    align-items: center;

    /* ↓ Negate the margin to follow
    (\`* -1\` makes the value negative) */
    margin: calc(var(--setClusterSpace) / 2 * -1);
  }
  .pplt-layout__cluster > * > * {
    /* ↓ Half margins meet to make the whole setClusterSpace value together */
    margin: calc(var(--setClusterSpace) / 2);
  }`, "",{"version":3,"sources":["webpack://./src/layout/culster/styles.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,oBAAoB;IACpB,sBAAsB;EACxB;EACA;IACE,oDAAoD;IACpD,aAAa;IACb,gCAAgC;;IAEhC,2BAA2B;IAC3B,2BAA2B;;IAE3B,yBAAyB;IACzB,mBAAmB;;IAEnB;uCACmC;IACnC,6CAA6C;EAC/C;EACA;IACE,yEAAyE;IACzE,wCAAwC;EAC1C","sourcesContent":[".pplt-layout__cluster {\n    /* ↓ Set the setClusterSpace as a variable */\n    --setClusterSpace: 0;\n    --setClusterWrap: wrap;\n  }\n  .pplt-layout__cluster > * {\n    /* ↓ Establish the flex context and allow wrapping */\n    display: flex;\n    flex-wrap: var(--setClusterWrap);\n\n    /* ↓ Horizontal alignment */\n    justify-content: flex-start;\n\n    /* ↓ Vertical alignment */\n    align-items: center;\n\n    /* ↓ Negate the margin to follow\n    (`* -1` makes the value negative) */\n    margin: calc(var(--setClusterSpace) / 2 * -1);\n  }\n  .pplt-layout__cluster > * > * {\n    /* ↓ Half margins meet to make the whole setClusterSpace value together */\n    margin: calc(var(--setClusterSpace) / 2);\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
