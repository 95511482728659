/** @module momentByValue */

/**
 * Responsible for breaking the call by reference
 * @param {moment} momentObj
 * @returns {moment} Cloned moments object
 */
export default function getMomentByValue(momentObj) {
  const momentClone = momentObj.clone();

  return momentClone;
}
