/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_HOTEL_BEDDING_TYPES = "GET_HOTEL_BEDDING_TYPES";
export const GET_HOTEL_BEDDING_TYPES_REQUEST = "GET_HOTEL_BEDDING_TYPES_REQUEST";
export const GET_HOTEL_BEDDING_TYPES_SUCCESS = "GET_HOTEL_BEDDING_TYPES_SUCCESS";
export const GET_HOTEL_BEDDING_TYPES_FAILURE = "GET_HOTEL_BEDDING_TYPES_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getHotelBeddingTypes = payload => ({
  type: GET_HOTEL_BEDDING_TYPES,
  payload,
});

export const getHotelBeddingTypesRequest = payload => ({
  type: GET_HOTEL_BEDDING_TYPES_REQUEST,
  payload,
});

export const getHotelBeddingTypesSuccess = payload => ({
  type: GET_HOTEL_BEDDING_TYPES_SUCCESS,
  payload,
});

export const getHotelBeddingTypesFailure = payload => ({
  type: GET_HOTEL_BEDDING_TYPES_FAILURE,
  payload,
});
