/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const featureFlagPersistentCookieToggleActionTypes = {
  SET_PERSISTENT_COOKIE_ICON: "SET_PERSISTENT_COOKIE_ICON",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setPersistentCookieIcon = ({ isCookieSet }) => ({
  type: featureFlagPersistentCookieToggleActionTypes.SET_PERSISTENT_COOKIE_ICON,
  isCookieSet,
});
