export const VPT_PRIVATE_CLASS_SERVICE_ID = 10008977;

export const VPTClassStatuses = {
  AVAILABLE: "Available",
  PENDING: "Pending",
  BOOKED: "Booked",
  // Note: "Checked-Out" is passed to PUT training class status, whereas
  // "Completed" is returned as the class status.
  CHECKED_OUT: "Checked-Out",
  COMPLETED: "Completed",
  CANCELED: "Canceled",
};

export const VPTClassLabels = {
  PRIVATE: "Pr Avail",
};

export const cancellationsReasons = {
  BY_OWNER: "By Owner",
};
