import { mockAccessLevelAPI } from "core/services/systemAssociateAuth/_axiosConfigMockAuthAccessLevels";
import { endpointVersionString } from "../../utils/envUtils";

export function getAvailableMockAssociateAccessLevels({ clientSessionToken }) {
  return mockAccessLevelAPI.get(`${endpointVersionString()}/access/levels`, {
    headers: { "x-client-session-token": clientSessionToken },
  });
}

export function mockDifferentAccessLevel({ username, data, xSessionToken }) {
  return mockAccessLevelAPI.put(
    `${endpointVersionString()}/access/levels/users/${username}`,
    data,
    {
      headers: { "x-session-token": xSessionToken },
    },
  );
}

export function resetMockAccessLevel({ username, data, xSessionToken }) {
  return mockAccessLevelAPI.post(
    `${endpointVersionString()}/access/levels/users/${username}/reset`,
    data,
    {
      headers: { "x-session-token": xSessionToken },
    },
  );
}
