// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pplt-layout__center {
    --setCenterPadding: 0;

    /* ↓ Remove padding from the width calculation */
    box-sizing: content-box;

    /* ↓ The maximum width is the maximum measure */
    max-width: 60ch;

    /* ↓ Only affect horizontal margins */
    margin-left: auto;
    margin-right: auto;

    /* ↓ Apply the minimum horizontal space */
    padding-left: var(--setCenterPadding);
    padding-right: var(--setCenterPadding);
  }`, "",{"version":3,"sources":["webpack://./src/layout/center/styles.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;;IAErB,gDAAgD;IAChD,uBAAuB;;IAEvB,+CAA+C;IAC/C,eAAe;;IAEf,qCAAqC;IACrC,iBAAiB;IACjB,kBAAkB;;IAElB,yCAAyC;IACzC,qCAAqC;IACrC,sCAAsC;EACxC","sourcesContent":[".pplt-layout__center {\n    --setCenterPadding: 0;\n\n    /* ↓ Remove padding from the width calculation */\n    box-sizing: content-box;\n\n    /* ↓ The maximum width is the maximum measure */\n    max-width: 60ch;\n\n    /* ↓ Only affect horizontal margins */\n    margin-left: auto;\n    margin-right: auto;\n\n    /* ↓ Apply the minimum horizontal space */\n    padding-left: var(--setCenterPadding);\n    padding-right: var(--setCenterPadding);\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
