import { authErrorMessages } from "../../../core/constants/authConstants";
import compareStrings from "../stringUtils/compareStrings";

/**
 * Takes an error message returned from the server,
 * matches it against a list of specified error messages
 *  and determines if we should show the server error header.
 * @param {String} serverErrorMessage The error message returned from the server.
 * @returns {Boolean} Determines if we should show the header in the server error modal.
 * */

export default serverErrorMessage =>
  ![authErrorMessages.ELEVATED_PERMISSION_EXPIRED_USER_FRIENDLY].some(message =>
    compareStrings(serverErrorMessage, `Message: ${message}`)
  );
