import { createSelector } from "reselect";
import { getPreviousBookingTimeZone } from "@/dux/scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";

// Helpers
import { getTimeZoneIdentifierByTimezone } from "./timeZoneCustomerHelpers";

/**
 *  Selector to get customers time zone
 *
 *  @memberOf Selectors.Enums
 *  @function
 *  @param {Object} - state
 *  @name getCustomersTimeZone
 *  @returns {Array} - time zone list
 *  @example
 *
 *  getCustomersTimeZone(state)
 */
export const getCustomersTimeZone = createSelector(
  [getPreviousBookingTimeZone],
  previousBookingTimeZone => {
    return getTimeZoneIdentifierByTimezone(previousBookingTimeZone);
  },
);
