import { FormElement, FormElementDefaultValue, FormElementValue, FieldValueProps, LastDelta } from './types';

// for some reason, RadioNodeList is needed for form element checking
type TargetOrElement = Element | RadioNodeList | EventTarget | null;

// based on MDN documentation
// the following are considered form elements
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/elements
export function isFormElement(el?: TargetOrElement): el is FormElement {
  if (el instanceof HTMLInputElement) {
    return true;
  }
  if (el instanceof HTMLSelectElement) {
    return true;
  }
  if (el instanceof HTMLTextAreaElement) {
    return true;
  }
  if (el instanceof HTMLObjectElement) {
    return true;
  }
  return false;
}

export function getDeltaValueFromFormElement(
  el?: TargetOrElement
): { [name: string]: number | string | boolean } | undefined {
  if (el instanceof HTMLInputElement) {
    // if name cannot be determined
    if (!el.name) {
      return;
    }
    return {
      [el.name]: el.type === 'checkbox' ? el.checked : el.value,
    };
  }

  if (el instanceof HTMLSelectElement) {
    // if name cannot be determined
    if (!el.name) {
      return;
    }
    return {
      // for selected value "el.value"
      // we're using index
      [el.name]: el.selectedIndex,
    };
  }

  return;
}

export function getLastDeltaFromFormElement(el?: TargetOrElement): LastDelta | undefined {
  if (el instanceof HTMLInputElement) {
    // if name cannot be determined
    if (!el.name) {
      return;
    }
    return {
      key: el.name,
      value: el.type === 'checkbox' ? el.checked : el.value,
    };
  }

  if (el instanceof HTMLSelectElement) {
    // if name cannot be determined
    if (!el.name) {
      return;
    }
    return {
      key: el.name,
      // for selected value "el.value"
      // we're using index
      value: el.selectedIndex,
    };
  }

  return;
}

export function checkFormElementValidity(el: TargetOrElement) {
  if (isFormElement(el)) {
    return el.validity.valid;
  }

  return true;
}

export function createFormInputEvent(e: React.MouseEvent<any>, inputName: string) {
  const form = e.currentTarget?.form;
  if (form instanceof HTMLFormElement) {
    const evt = new CustomEvent('input', {
      bubbles: true,
      cancelable: true,
      detail: {
        inputName,
      },
      composed: true,
    });
    form.dispatchEvent(evt);
  }
}

export function createFormInputEventFromElement(
  e: FormElement | null | undefined,
  inputName: string,
  inputValue: string | boolean
) {
  if (!e) {
    return;
  }

  const form = e?.form;
  if (form instanceof HTMLFormElement) {
    const evt = new CustomEvent('input', {
      bubbles: true,
      cancelable: true,
      detail: {
        inputName,
        inputValue,
      },
      composed: true,
    });
    form.dispatchEvent(evt);
  }
}
