import { newMedicationActionTypes } from "./actions/newMedicationActions";


const initialState = false;

const newMedicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case newMedicationActionTypes.SHOW_ADD_NEW_MEDICATION:
      return true;
    case newMedicationActionTypes.HIDE_ADD_NEW_MEDICATION:
      return initialState;
    default:
      return state;
  }
};

export default newMedicationReducer;
