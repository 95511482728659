import { connect } from "react-redux";
import { Button, Layout } from "@prism/psm-ui-components";
import { color } from "@/web/common/styles/theme";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import petVaccinationsActionCreators from "@/core/actionCreators/petVaccinationsActionCreators";
import { getVaccinationIdsByName } from "@/core/selectors/ui/vaccinations/vaccinationsSelectors";
import React, { useState } from "react";
import DeleteVaccinationModal from "dux/vaccinations/DeleteVaccinationModal";

export default function ButtonWithModal(props) {
  const { onClick } = props;
  const [isModalShowing, setIsModalShowing] = useState(false);

  return (
    <>
      <DeleteVaccinationModal
        isHidden={!isModalShowing}
        onModalClose={() => setIsModalShowing(false)}
      />
      <Button
        isHidden={false}
        variant="link"
        style={{ color: color.errorRed }}
        onClick={() => {
          onClick();
          setIsModalShowing(!isModalShowing);
        }}
      >
        Delete
      </Button>
    </>
  );
}

export const DeleteVaccinationButton = connect(
  (state, { name }) => {
    const selectedPet = getSelectedPet(state);
    return {
      selectedPet,
      customerKey: getCurrentCustomerKey(state),
      vaccinationIds: getVaccinationIdsByName(state, { name, petId: selectedPet }),
    };
  },

  dispatch => ({
    openRemoveVaccinationModal: ({ customerKey, petId, vaccinationId, petVaccinationId }) => {
      dispatch(
        petVaccinationsActionCreators.setCustomerAndPetKeys({
          customerKey,
          petId,
        }),
      );
      dispatch(
        petVaccinationsActionCreators.setVaccinationToDelete({ vaccinationId, petVaccinationId }),
      );
    },
  }),
  (stateProps, dispatchProps) => {
    const { customerKey, selectedPet, vaccinationIds, variant, style, children } = stateProps;
    const { vaccinationId, petVaccinationId } = vaccinationIds;
    const { openRemoveVaccinationModal } = dispatchProps;
    return {
      variant,
      style,
      children,
      onClick: () =>
        openRemoveVaccinationModal({
          petId: selectedPet,
          customerKey,
          vaccinationId,
          petVaccinationId,
        }),
    };
  },
)(ButtonWithModal);
