import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { fetchAddOns } from "../../../../core/services/systemServicesBooking/addOnEndPoints";
import { getStoreNumber } from "../../../../core/selectors/persistentSelectors";
import { getSelectedPetDetails } from "../../../../core/selectors/bookingUISelectors";
import {
  getSelectedProductBundleConfigId,
  hasRequestedEnhancedServicesByBundleConfigId,
} from "../bulkPackageOfferingsSelectors";
import {
  getBulkPackageEnhancedServicesRequest,
  getBulkPackageEnhancedServicesSuccess,
  getBulkPackageEnhancedServicesFailure,
  setSelectedBulkPackageEnhancedServices,
  GET_BULK_PACKAGE_ENHANCED_SERVICES,
} from "./bulkPackageEnhancedServiceActions";

function* onGetBulkPackageEnhancedServices({ petServiceId }) {
  const hasRequested = yield select(hasRequestedEnhancedServicesByBundleConfigId);
  if (hasRequested) return;

  // do not request data when there is no bundle selected
  const productBundleConfigId = yield select(getSelectedProductBundleConfigId);
  if (!productBundleConfigId) return;

  try {
    yield put(getBulkPackageEnhancedServicesRequest());
    const storeNumber = yield select(getStoreNumber);
    const pet = yield select(getSelectedPetDetails);

    const response = yield call(fetchAddOns, {
      storeNumber,
      petServiceId,
      productBundleConfigId,
    });

    const enhancedServices = response?.data?.result?.filter(item => item.isEnhancedAddOn)

    yield put(setSelectedBulkPackageEnhancedServices({ petId: pet.petId, productBundleConfigId, enhancedServices }));
    yield put(getBulkPackageEnhancedServicesSuccess());
  } catch (error) {
    yield put(getBulkPackageEnhancedServicesFailure({ error }));
  }
}

function* watchLoadBulkPackageEnhancedServices() {
  yield takeLatest([GET_BULK_PACKAGE_ENHANCED_SERVICES], onGetBulkPackageEnhancedServices);
}

export default function* bulkPackageEnhancedServicesSaga() {
  yield all([watchLoadBulkPackageEnhancedServices()]);
}
