export default {
  SELECT_PET_SERVICE: "SELECT_PET_SERVICE",
  REMOVE_APPOINTMENT_FROM_CART: "REMOVE_APPOINTMENT_FROM_CART",
  SET_PET_SERVICE_ITEM_ID: "SET_PET_SERVICE_ITEM_ID",
  SELECT_TIME_SLOT: "SELECT_TIME_SLOT",
  SET_ADDONS: "SET_ADDONS",
  ADD_ADDON: "ADD_ADDON",
  REMOVE_ADDON: "REMOVE_ADDON",
  SET_STANDALONE: "SET_STANDALONE",
  SET_SPECIALS: "SET_SPECIALS",
  ADD_SPECIAL: "ADD_SPECIAL",
  REMOVE_SPECIAL: "REMOVE_SPECIAL",
  CLEAR_CART: "CLEAR_CART",
  INITIALIZE_CART: "INITIALIZE_CART",
  CANCEL_APPOINTMENT: "CANCEL_APPOINTMENT",
  SET_ADDITIONAL_BOOKING_TYPE: "SET_ADDITIONAL_BOOKING_TYPE",
  SET_MAX_CHECK_IN_LIMIT: "SET_MAX_CHECK_IN_LIMIT",
  SET_MAX_PER_BLOCK_LIMIT: "SET_MAX_PER_BLOCK_LIMIT",
  SET_MAX_CHECK_IN_BOOL: "SET_MAX_CHECK_IN_BOOL",
  SET_MAX_PER_BLOCK_BOOL: "SET_MAX_PER_BLOCK_BOOL",
  SET_IS_BOARDED: "SET_IS_BOARDED",
  CLEAR_IS_BOARDED: "CLEAR_IS_BOARDED",
  INITIALIZE_CART_BY_ITINERARY: "INITIALIZE_CART_BY_ITINERARY",
  SET_LOCK_TO_ASSOCIATE: "SET_LOCK_TO_ASSOCIATE",
  SET_APPOINTMENT_NOTE: "SET_APPOINTMENT_NOTE",
  SET_MAX_PER_BLOCK_REDUCTION_REASON: "SET_MAX_PER_BLOCK_REDUCTION_REASON"
};
