import { associateWebBFFAPI } from "./_axiosConfigAssociateWebBff";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * API to patch primary service change
 * @param { String } customerId
 * @param { String } itineraryId
 * @param { Object } data
 * {
 *     "pets": [
 *         {
 *             "petKey": petId,
 *             "engagements": [
 *                 {
 *                     "engagementId": 749237,
 *                     "petService": {
 *                         "petServiceId": "10002723",
 *                          "groupingId": null,
 *                         "petServiceProductId":null
 *                     },
 *                     "metadata": {
 *                             "room": {
 *                             "roomTypeBucketId": 16643,
 *                             "roomType": null,
 *                             "roomNumber": null,
 *                             "isUnassigned": false
 *                             }
 *                     }
 *                 }
 *             ]
 *         }
 *     ]
 * }
 * @returns {*} AxiosPromise
 */
export function patchPrimaryServiceChange({ customerId, data, itineraryId }) {
  return associateWebBFFAPI.patch(
    `${endpointVersionString()}/hotel/customers/${customerId}/itineraries/${itineraryId}/primaryservice`,
    data,
  );
}
