import { get, isArray } from "lodash/fp";
import { createSelector } from "reselect";
import { medicationFields } from "web/newMedication/newMedicationConstants";
import { getFoodById } from "web/food/foodsSelector";

export const getFoodIsShowingDetails = createSelector([getFoodById], food => {
  return get(medicationFields.LOCATION_TYPES, food) || [];
});

export const getFoodIsShowingDetailsForType = createSelector(
  [getFoodIsShowingDetails, (state, props) => props],
  (showingTypes, { locationType }) => {
    return isArray(showingTypes) ? showingTypes.find(type => type === locationType) : false;
  },
);
