import * as React from 'react';
import { psx } from '../../utils/clsx';
import { Layout, BoxProps } from '../../components/Layout';
import Button from '../../components/Button';
import SvgIcon from '../../components/Icon';

export interface PrevNextSelectorProps extends BoxProps {
  /** Select the next time/date/word... object etc. */
  selectNext?: () => void;
  /** Select the previous time/date/word... object etc. */
  selectPrev?: () => void;
  /** Spacer  */
  space?: number;
}

const PrevNextSelector: React.FunctionComponent<PrevNextSelectorProps> = ({
  isHidden = false,
  space = 100,
  selectNext,
  selectPrev,
  className,
  children,
  testid,
  ...props
}) => {
  const classNames = psx('psm', 'psm-PrevNextSelector', className);

  return (
    <Layout.Cluster className={classNames} {...props}>
      <Button variant="no-outline" onClick={selectPrev}>
        <SvgIcon variant="hero-icon-left" size="psm-icon-size-32">
          <Layout.Spacer size={space} axis="horizontal" />
        </SvgIcon>
      </Button>
      {children}
      <Button variant="no-outline" onClick={selectNext} dir="rtl">
        <SvgIcon variant="hero-icon-right" size="psm-icon-size-32">
          <Layout.Spacer size={space} axis="horizontal" />
        </SvgIcon>
      </Button>
    </Layout.Cluster>
  );
};

export default PrevNextSelector;
