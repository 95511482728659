import { CLEAR_HOTEL_ITINERARY } from "../hotelItinerary/hotelItineraryActions";
import { SET_HOTEL_PRICING_SUMMARY } from "./hotelPricingSummaryActions";

const initialState = {};

const hotelPricingSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTEL_PRICING_SUMMARY:
      return {
        ...state,
        [action.petId]: action.pricingSummary,
      };
    case CLEAR_HOTEL_ITINERARY:
      return initialState;
    default:
      return state;
  }
};

export default hotelPricingSummaryReducer;
