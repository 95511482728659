import { NEW_FOOD_ID } from "web/newFood/newFoodConstants";
import { history } from "@/dux/utils/browser/browserHistory";
import { routePaths } from "../../core/constants/routePaths";

export const shouldHideAddFoodButton = (type, foodId) => {
  if (foodId !== NEW_FOOD_ID) {
    return true;
  }
  if (history.location.pathname.includes("select-service") && type === routePaths.BOOKING) {
    return false;
  }
  if (
    history.location.pathname.includes("pet-parent-profile") &&
    type === routePaths.PET_PARENT_PROFILE
  ) {
    return false;
  }
  return true;
};
