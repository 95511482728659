import React from "react";
import { TextPassage } from "@petsmart-ui/sparky";
import { LayoutBox } from "../../layout/box/Box";
import { LayoutStack } from "../../layout/stack/Stack";
import { LayoutCluster } from "../../layout/culster/Cluster";
import { connect } from "react-redux";
import { selectAvailableHotelRooms } from "dux/hotelAvailableRoomCard/hotelAvailableRoomCardSelector";
import { generateRandomString } from "core/utils/authHelper";
import IsManualImage from "assets/icons/manual-appointment.svg";

export const HotelAvailableRoomCard = props => {
  const { componentId, rooms } = props;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack space="scale-S3">
        {rooms?.map(room => (
          <LayoutBox
            key={generateRandomString(6)}
            padding="scale-G1"
            borderWidth="scale-S6"
            borderColor="mediumGrey"
          >
            <LayoutCluster space="scale-S1">
              <LayoutBox padding="scale-0">
                <img alt="icon" src={IsManualImage} />
              </LayoutBox>

              <TextPassage size="lg">Available Room {room}</TextPassage>
            </LayoutCluster>
          </LayoutBox>
        ))}
      </LayoutStack>
    </LayoutBox>
  );
};

export const RoomCard = connect((state, props) => {
  const { roomTypeId } = props;
  const rooms = selectAvailableHotelRooms(roomTypeId)(state);

  return {
    componentId: `roomCard_${roomTypeId}`,
    rooms,
  };
})(HotelAvailableRoomCard);
