import React, { useEffect, useRef, useState } from "react";
import { LayoutBox } from "@/layout/box/Box";
import { Button } from "@petsmart-ui/sparky";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { isEmpty } from "lodash";
import { color } from "../styles/theme";

const isFormTouched = (touched = {}) => Object.values(touched)?.some(val => val === true);

const SaveAndCancelWrapper = ({ isFocused, hasError, children, handleCancel, handleSave }) => {
  const focusColor = hasError ? color.errorRed : color.kalEl;
  const focusedStyle = isFocused && {
    margin: "0 -1rem",
    padding: "1rem",
    borderWidth: "1px",
    borderRadius: "5px",
    borderColor: focusColor,
    boxShadow: `0 0 1rem 0 ${focusColor}`,
  };
  return (
    <LayoutBox padding="scale-0" style={{ transition: "all 0.1s", ...focusedStyle }}>
      <LayoutStack>
        {children}

        {isFocused && (
          <LayoutBox>
            <LayoutCluster style={{ justifyContent: "space-around" }}>
              <Button text="Cancel" onClick={handleCancel} />
              <Button text="Save" variant="primary" onClick={handleSave} />
            </LayoutCluster>
          </LayoutBox>
        )}
      </LayoutStack>
    </LayoutBox>
  );
};

/**
 * HOC to add focus styles and save and cancel buttons to a form
 * @param {JSX.Element} WrappedComponent
 * @returns {JSX.Element}
 * @example @see {@link FormWithSaveAndCancel}
 */
export const withSaveAndCancel = WrappedComponent => {
  return props => {
    const formRef = useRef();

    const [isFocused, setIsFocused] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [touched, setTouched] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const resetState = () => {
      setIsFocused(false);
      setHasError(false);
      setTouched(false);
    };

    useEffect(() => {
      if (!isFocused && touched) setIsFocused(true);
    }, [touched]);

    const handleSave = () => {
      if (!isFocused || hasError) return;
      formRef.current.submitForm();
      resetState();
    };

    const handleCancel = () => {
      if (!isFocused) return;
      formRef.current.resetForm();
      resetState();
    };

    return (
      <SaveAndCancelWrapper
        isFocused={isFocused}
        hasError={hasError}
        handleCancel={handleCancel}
        handleSave={handleSave}
      >
        <WrappedComponent
          {...props}
          formRef={formRef}
          isFocused={isFocused}
          handleFocus={handleFocus}
          handleErrors={errors => setHasError(!isEmpty(errors))}
          handleTouched={fieldsTouched => setTouched(isFormTouched(fieldsTouched))}
        />
      </SaveAndCancelWrapper>
    );
  };
};

/**
 * HOC to add focus styles and save and cancel buttons to a form that is using <Formik />
 * @param {JSX.Element} WrappedComponent
 * @returns {JSX.Element}
 * @example
 * const component = withSaveAndCancelFormik(({ formRef, ...props }) => {
 *    return <Formik ref={formRef} />;
 * });
 */
export const withSaveAndCancelFormik = WrappedComponent => {
  return props => {
    const [isFocused, setIsFocused] = useState(false);
    const formRef = useRef();

    const touched = formRef?.current?.state?.touched === true;
    const hasError = formRef?.current?.state?.touched && !isEmpty(formRef?.current?.state?.errors);

    useEffect(() => {
      if (!isFocused && touched) setIsFocused(true);
    }, [touched]);

    const handleFocus = () => setIsFocused(true);

    const handleSave = () => {
      if (!isFocused || hasError) return;
      formRef.current.submitForm();
      setIsFocused(false);
    };

    const handleCancel = () => {
      if (!isFocused) return;
      formRef.current.resetForm();
      setIsFocused(false);
    };

    return (
      <SaveAndCancelWrapper
        isFocused={isFocused}
        hasError={hasError}
        handleCancel={handleCancel}
        handleSave={handleSave}
      >
        <WrappedComponent
          {...props}
          isFocused={isFocused}
          handleFocus={handleFocus}
          formRef={formRef}
        />
      </SaveAndCancelWrapper>
    );
  };
};
