import React from "react";
import StandaloneHeader from "./StandaloneServicesAccordionHeaderContainer";
import StandaloneContent from "./StandaloneServicesAccordionContentContainer";

export default function StandaloneServicesAccordionComponent({ isHidden }) {
  return !isHidden ? (
    <StandaloneHeader>
      <StandaloneContent />
    </StandaloneHeader>
  ) : null;
}
