import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { getBulkPackageOfferingsServiceTypesByPet } from "../bulkPackageOfferingsSelectors";
import GenericTitle from "@/dux/_components/genericLayoutComponents/GenericTitle";
import { createLoadingSelector } from "@/core/selectors/utils";
import { GET_BULK_PACKAGE_OFFERINGS } from "../bulkPackageOfferingsActions";
import customersActionTypes from "@/core/actionTypes/customersActionTypes";

const mapStateToProps = state => {
  const bulkPackageOfferings = getBulkPackageOfferingsServiceTypesByPet(state);
  const isLoading = createLoadingSelector([
    GET_BULK_PACKAGE_OFFERINGS,
    customersActionTypes.LOAD_CUSTOMER,
  ])(state);
  return {
    bulkPackageOfferings,
    isHidden: !isEmpty(bulkPackageOfferings) || isLoading,
    componentID: "bulk-package-offerings-accordion-content",
    title: "No package offerings available",
    styleObj: {
      display: "flex",
      justifyContent: "center",
    },
  };
};

export default connect(mapStateToProps)(GenericTitle);
