import { SET_PET_NEEDS_REVIEW } from "./hotelCheckInOutPetNeedsReviewActions";

const initialState = {};

const hotelCheckInOutPetNeedsReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PET_NEEDS_REVIEW:
      return {
        ...state,
        [action.petId]: {
          ...state[action.petId],
          needsReview: action.needsReview,
        },
      };
    default:
      return state;
  }
};

export default hotelCheckInOutPetNeedsReviewReducer;
