/**
 * Responsible for returning the petParentProfile Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getPetParentProfileState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the Addons object in the redux store
 *
 * @example createSelector([petParentProfile], addonsState => petParentProfile.emailAddressId;
 */
export default function getPetParentProfileState(state) {
  return state.ui.web.petParentProfile;
}
