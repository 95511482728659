import React, { useEffect } from "react";
import { fontSizes } from "web/common/styles/responsive/fonts";
import MedicationNameContainer from "../medicationName/MedicationNameContainer";
import AddNewBookingMedicationButtonContainer from "../../dux/bookingMedication/addMedBooking/AddNewBookingMedicationButtonContainer";
import CancelNewMedicationButtonContainer from "../addMedication/buttons/CancelNewMedicationButtonContainer";
import OtherMedicationNameContainer from "../otherMedicationName/OtherMedicationNameContainer";
import MedicationTypeAmountContainer from "../medicationTypeAmount/MedicationTypeAmountContainer";
import MedicationInstructionsContainer from "../medicationInstructions/MedicationInstructionsContainer";
import MedicationTimeOfDayContainer from "../medicationTimeOfDay/MedicationTimeOfDayContainer";
import MedicationSaveToProfileCheckboxContainer from "../../dux/bookingMedication/bookingSaveMedicationToProfile/MedicationSaveToProfileCheckboxContainer";
import { isNewMedication } from "../medication/medicationUtils";
import MedicationFrequencyErrorMessageContainer from "dux/frequency/MedicationFrequencyErrorMessageContainer";
import MedicationIsFromProfileCheckboxContainer from "../../dux/bookingMedication/bookingIsFromProfile/MedicationIsFromProfileCheckboxContainer";
import SetFrequencyMedicationButtonContainer from "dux/bookingPage/setMedFrequency/SetFrequencyMedicationButtonContainer";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutGrid } from "@/layout/grid/Grid";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { UpdateBookingMedButtonContainer } from "@/dux/bookingFeeding/UpdateBookingFoodButtonContainer";

const BookingMedicationFormComponent = ({
  isHidden,
  medicationId,
  medicationIndex,
  medicationIds,
  petId,
  locationType,
  isEditingMed = false,
  preserveOrApplyMedData = () => {},
}) => {
  useEffect(() => {
    if (!isNewMedication(medicationId)) preserveOrApplyMedData();
  }, [isEditingMed]);

  const newMedicationIndex = isNewMedication(medicationId)
    ? medicationIds.length + 1
    : medicationIndex;

  return !isHidden ? (
    <LayoutBox padding="scale-0" style={{ fontSize: fontSizes.regular }}>
      <LayoutGrid
        space="scale-G2"
        minimum="scale-G5"
        style={{
          gridRowGap: "1.875rem",
          gridTemplateRows: "repeat(3, 1fr)",
          padding: "1rem 0",
        }}
      >
        <MedicationNameContainer
          medicationIndex={newMedicationIndex}
          medicationId={medicationId}
          petId={petId}
        />
        <OtherMedicationNameContainer medicationId={medicationId} petId={petId} />
        <MedicationTypeAmountContainer
          medicationId={medicationId}
          medicationIndex={newMedicationIndex}
          petId={petId}
        />
        <MedicationTimeOfDayContainer
          medicationId={medicationId}
          medicationIndex={newMedicationIndex}
          petId={petId}
        />
      </LayoutGrid>

      <LayoutBox padding="scale-0">
        <MedicationInstructionsContainer
          medicationId={medicationId}
          medicationIndex={newMedicationIndex}
          petId={petId}
        />
      </LayoutBox>

      <MedicationSaveToProfileCheckboxContainer medicationId={medicationId} petId={petId} />
      <MedicationIsFromProfileCheckboxContainer medicationId={medicationId} petId={petId} />

      <LayoutCluster justify="space-between" style={{ padding: "15px 0px" }}>
        <SetFrequencyMedicationButtonContainer medicationId={medicationId} petId={petId} />
        <LayoutBox style={{ marginLeft: "auto" }}>
          <LayoutCluster>
            <UpdateBookingMedButtonContainer
              medicationId={medicationId}
              petId={petId}
              locationType={locationType}
            />
            <AddNewBookingMedicationButtonContainer
              medicationId={medicationId}
              petId={petId}
              locationType={locationType}
            />
            <CancelNewMedicationButtonContainer medicationId={medicationId} petId={petId} />
          </LayoutCluster>
        </LayoutBox>
      </LayoutCluster>
      <MedicationFrequencyErrorMessageContainer medicationId={medicationId} petId={petId} />
    </LayoutBox>
  ) : null;
};

export default BookingMedicationFormComponent;
