import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Calendar from '../common/Calendar';
import Arrow from '../common/Arrow';
import ClickableText from '../common/ClickableText';

export default class DatesRangeComponent extends React.Component {
  onSelectDate = selectedDate => {
    const { setStartWeekDate } = this.props;
    const selectedDateMoment = moment(selectedDate).isMoment ? selectedDate : moment(selectedDate);

    setStartWeekDate(selectedDateMoment.startOf('isoWeek').format('YYYY-MM-DD'));
  };

  onArrowClick = direction => {
    const { startWeekDate, setStartWeekDate, onChange } = this.props;
    const newDateMoment =
      direction === 'left' ? moment(startWeekDate).subtract(7, 'days') : moment(startWeekDate).add(7, 'days');

    if (onChange) {
      onChange(newDateMoment.format('YYYY-MM-DD'));
      return;
    }
    setStartWeekDate(newDateMoment.format('YYYY-MM-DD'));
  };

  onCurrentWeekClick = () => {
    const { setStartWeekDate, onChange } = this.props;
    const dateToChange = moment()
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    if (onChange) {
      onChange(dateToChange);
      return;
    }
    setStartWeekDate(dateToChange);
  };

  render() {
    const { startWeekDate } = this.props;
    const isCurrentWeek =
      moment()
        .startOf('isoWeek')
        .format('YYYY-MM-DD') === startWeekDate;

    return (
      <Container>
        {!isCurrentWeek && <CurrentWeek isVisible onClick={this.onCurrentWeekClick}>Current week</CurrentWeek>}

        <Arrow direction="left" clickHandler={() => this.onArrowClick('left')} />

        <Calendar isWeekly labelFontSize={'14px'} selectedDate={startWeekDate} selectDate={this.onSelectDate}>
          <Dates>{`${moment(startWeekDate).format('MMMM DD')} - ${moment(startWeekDate)
            .add(6, 'days')
            .format('MMMM DD')}`}</Dates>
        </Calendar>

        <Arrow direction="right" clickHandler={() => this.onArrowClick('right')} />
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

const Dates = styled.div`
  margin: 0 10px;
  cursor: pointer;
  font-weight: bold;
`;

const CurrentWeek = styled(ClickableText)`
  position: absolute;
  left: -150px;
`;
