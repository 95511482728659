import { compose } from "redux";

/**
 * Util to get all addons from a pets engagements with the startDatetime's from the engagement they came from
 * @memberOf Utils.Engagement
 * @function
 * @name getAddonsFromPetEngagements
 * @param {Object} petEngagements - engagements for a pet from a hotel itinerary
 * @returns {Object[]} array of all addons from a pets engagements
 * @example getAddonsFromPetEngagements(petEngagements)
 */
export const getAddonsFromPetEngagements = (petEngagements = []) =>
  petEngagements?.reduce((arr, { startDatetime, addOns = [] } = {}) => {
    const addonsWithDate = addOns.map(addon => ({ ...addon, startDatetime }));
    return [...arr, ...addonsWithDate];
  }, []);

/**
 * Util to dedupe addons from a pets engagements and calculate custom frequency dates
 * @memberOf Utils.Engagement
 * @function
 * @name dedupeAddonsFromEngagements
 * @param {Object} addons - array of all addons from a pets engagements
 * @param {string => string} formatDateFn - optional function to format the custom frequency date
 * @returns {Object[]} deduped array of addons
 * @example dedupeAddonsFromEngagements(addons)
 */
export const dedupeAddonsFromEngagements = (
  addonsFromEngagements = [],
  formatDateFn = date => date,
) =>
  Object.values(
    addonsFromEngagements?.reduce((deduped, { startDatetime, ...addon }) => {
      // Use groupingId to remove duplicates
      const id = addon?.groupingId;
      const dates = deduped[id]?.customFrequencyDates ?? [];
      const date = formatDateFn(startDatetime);
      const addonWithDate = { ...addon, customFrequencyDates: [date] };

      // Short circuit if there are no duplicates
      if (addonsFromEngagements.find(({ groupingId }) => id === groupingId)?.length === 1) {
        return { ...deduped, [id]: addonWithDate };
      }

      const addonObj = deduped[id]
        ? { ...deduped[id], customFrequencyDates: [...dates, date] }
        : addonWithDate;

      return { ...deduped, [id]: addonObj };
    }, {}),
  );

/**
 * Util to get all addons from pet engagements and dedupe and calculate custom frequency dates
 * @memberOf Utils.Engagement
 * @function
 * @name getDedupedAddonsFromPetEngagements
 * @param {Object} petEngagements - array of all addons from a pets engagements
 * @returns {Object[]} deduped array of addons
 * @example getDedupedAddonsFromPetEngagements(petEngagements)
 */
export const getDedupedAddonsFromPetEngagements = compose(
  dedupeAddonsFromEngagements,
  getAddonsFromPetEngagements,
);
