import React from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { TextPassage } from "@petsmart-ui/sparky";
import { getHotelEngagementsPets } from "../hotelEngagements/hotelEngagementSelectors";
import { TOTAL_PRICE } from "./hotelCheckInOutTotalPriceConstants";
import { getHotelItineraryTotalPrice } from "../hotelItinerary/hotelItinerarySelectors";
import { formatMoney } from "../_utils/moneyUtil";

/**
 * React view component that displays a total price with a label
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelCheckInOutTotalPriceComponent
 * @param {Object } props - props passed into the view component
 * @param {string} props.componentId
 * @param {boolean} props.isHidden
 * @param {string} props.label
 * @param {string} props.price
 * @returns {JSX.Element|null}
 * @example const container = connect(mapState, mapDispatch, mergeProps)(HotelCheckInOutTotalPriceComponent)
 */
export const HotelCheckInOutTotalPriceComponent = ({ componentId, isHidden, label, price }) => {
  if (isHidden) return null;

  return (
    <LayoutBox padding="scale-0" id={componentId}>
      <TextPassage size="lg">{label}</TextPassage>
      <TextPassage size="lg">{price}</TextPassage>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the HotelCheckInOutTotalPriceComponent
 * @see {@link Views.Hotel.Itinerary.HotelCheckInOutTotalPriceComponent}
 * @summary hotel check-in & check-out pages
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelCheckInOutTotalPrice
 * @returns {JSX.Element|null}
 * @example <HotelCheckInOutTotalPrice />
 */
export const HotelCheckInOutTotalPrice = connect(state => {
  const isMultiPet = getHotelEngagementsPets(state)?.length > 1;
  const price = getHotelItineraryTotalPrice(state);

  return {
    componentId: "HotelCheckInOutTotalPrice",
    label: isMultiPet ? TOTAL_PRICE.multiPetLabel : TOTAL_PRICE.singlePetLabel,
    price: formatMoney(price),
  };
})(HotelCheckInOutTotalPriceComponent);
