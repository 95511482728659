import React from "react";
import { connect } from "react-redux";
import {
  ItineraryHistoryCurrentReservations,
  ItineraryHistoryFutureReservations,
  ItineraryHistoryPastReservations,
} from "../itineraryHistoryListSection/itineraryHistoryListSection";
import {
  selectReservationsForCurrentPage,
  selectItineraryHistoryListPageCount,
} from "../itineraryHistoryList/itineraryHistoryListSelectors";
import { selectItineraryHistoryListActiveFilterValues } from "../itineraryHistoryListFilterButtons/itineraryHistoryListFilterButtonsSelectors";

// Components
import { PaginatedStack } from "@prism/psm-ui-components";

// ITINERARY HISTORY LIST PAGINATION ------------------------------------------------------------------
export const ItineraryHistoryListPagination = connect(
  state => {
    return {
      componentId: "ItineraryHistoryListPagination",
      total: selectItineraryHistoryListPageCount(state),
      space: "stack-space-8",
      style: {
        width: "100%",
      },
      // When the filters are changed, reset the pagination
      key: selectItineraryHistoryListActiveFilterValues(state).join("-"),
      children: currentPage => {
        const pageReservations = selectReservationsForCurrentPage({ currentPage })(state);
        const { currentReservations, futureReservations, pastReservations } = pageReservations;

        return (
          <>
            <ItineraryHistoryCurrentReservations reservations={currentReservations} />
            <ItineraryHistoryFutureReservations reservations={futureReservations} />
            <ItineraryHistoryPastReservations reservations={pastReservations} />
          </>
        );
      },
    };
  },
  // If no mapDispatchToProps fn is passed, then dispatch gets passed as a prop,
  // if the component being wrapped uses prop spreading this could cause errors.
  // To prevent this we pass a function returning an empty obj
  () => ({}),
)(PaginatedStack);
