import UnknownCatImage from "../../assets/images/unknown-cat.png";
import UnknownDogImage from "../../assets/images/unknown-dog.png";

/**
 * Responsible for taking a pet object and determining if an image for
 * an Cat or Dog should be returned.
 *
 * @param {object} pet
 * @returns Image
 */
export default function getUnknownPetImage(pet) {
  if (pet) {
    if (pet.speciesId === 2) {
      return UnknownCatImage;
    }

    return UnknownDogImage;
  }
}
