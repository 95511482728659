import React from "react";

import {
  ModalCopy,
  ModalFooter,
  ModalHeader,
  PetImage,
  PetImageWrapper,
  SectionWrapper,
} from "web/petCheckInOut/modals/checkInOutModalsLayout";
import { PackageOfferDetails } from "@/dux/bgm/ftco/PackageOfferDetails";
import CheckInOutButtonContainer from "web/petCheckInOut/modals/CheckInOutButtonContainer";

function CheckOutModalContent({ petPhoto, petsPaid, isHidden, message, headerText }) {
  if (!isHidden) {
    return (
      <SectionWrapper data-testid="CheckOutModalContent">
        <ModalHeader>{headerText}</ModalHeader>
        {!petsPaid && <ModalCopy data-testid="CheckOutModalContent__Message">{message}</ModalCopy>}

        <PetImageWrapper>
          <PetImage src={petPhoto} />
        </PetImageWrapper>
        <ModalFooter>
          <PackageOfferDetails />
          <CheckInOutButtonContainer />
        </ModalFooter>
      </SectionWrapper>
    );
  }
  return null;
}

export default CheckOutModalContent;
