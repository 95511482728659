import React from "react";

const EditSvgIcon = (props) => {
  const { fill = "#000" } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13">
      <g fill={fill} fillRule="nonzero">
        <path d="M.904 8.768l6.364-6.364 2.828 2.828-6.364 6.364zM.047 11.712c-.184.643.185 1.026.846.842L3 11.957.647 9.6l-.6 2.112zM11.684 2L10.491.805A1.067 1.067 0 0 0 9 .804L8 1.807 10.684 4.5l1-1.003a1.044 1.044 0 0 0 0-1.496z" />
      </g>
    </svg>
  );
};

export default EditSvgIcon;
