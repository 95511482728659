import React from "react";
import { Layout } from "@prism/psm-ui-components";
import TrainingClassTypeButtonContainer from "@/dux/scheduleTrainingPage/TrainingClassTypeButtonContainer";
import PurchaseTrainingServiceMessageContainer from "@/dux/scheduleTrainingPage/PurchaseTrainingServiceMessageContainer";

export default function TrainingClassTypeListComponent({ trainingBundles }) {
  return (
    <Layout.Stack>
      <PurchaseTrainingServiceMessageContainer />
      {trainingBundles.map(({ bundleNumber }) => (
        <TrainingClassTypeButtonContainer key={bundleNumber} bundleNumber={bundleNumber} />
      ))}
    </Layout.Stack>
  );
}
