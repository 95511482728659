import serviceStatusesActionTypes from "../actionTypes/serviceStatusesActionTypes";

const initState = {
  0: {
    statusID: 0,
    name: " ",
    value: " "
  }
};

/*
  Transforms raw api data to a
  more suitable structure for the store
*/
const transformStatusesData = statuses => {
  const newStatuses = {};

  statuses.statuses.map((status, index) => {
    return (newStatuses[index] = {
      statusID: index,
      name: status.name,
      value: status.value
    });
  });

  return newStatuses;
};

/*
  Reducer
*/
const serviceStatusesReducer = (state = initState, action) => {
  switch (action.type) {
    case serviceStatusesActionTypes.LOAD_STATUSES_SUCCESS:
      const statuses = action;
      return transformStatusesData(statuses);

    default:
      return state;
  }
};

export default serviceStatusesReducer;
