import salonActionTypes from "../actionTypes/salonActionTypes";

/**
 * Action Creator to used to load salon info, specificity for store hours at the salon
 *
 * Redux Path: salonHours
 *
 * @example setStoreNumber({ storeNumber: 133 })
 *
 * @param {Number} storeNumber
 */
export const loadSalonInfoWithHours = ({ storeNumber }) => ({
  type: salonActionTypes.LOAD_SALON_INFO_WITH_HOURS,
  storeNumber,
});

export const loadSalonInfoWithHoursRequest = () => ({
  type: salonActionTypes.LOAD_SALON_INFO_WITH_HOURS_REQUEST,
});

export const loadSalonInfoWithHoursSuccess = ({ salon }) => ({
  type: salonActionTypes.LOAD_SALON_INFO_WITH_HOURS_SUCCESS,
  payload: salon,
});

export const loadSalonInfoWithHoursFailure = ({ error }) => ({
  type: salonActionTypes.LOAD_SALON_INFO_WITH_HOURS_FAILURE,
  error,
});

/**
 * Action Creator to used to load salon info, specificity for services offered at the salon
 *
 * Redux Path: search.salonInfoWithServices
 *
 * @example setStoreNumber({ storeNumber: 133 })
 *
 * @param {Number} storeNumber
 */
export const loadSalonInfoWithServices = ({ storeNumber }) => ({
  type: salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES,
  storeNumber,
});

export const loadSalonInfoWithServicesRequest = () => ({
  type: salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES_REQUEST,
});

export const loadSalonInfoWithServicesSuccess = ({ salon }) => ({
  type: salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES_SUCCESS,
  salon,
});

export const loadSalonInfoWithServicesFailure = ({ error }) => ({
  type: salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES_FAILURE,
  error,
});

export const loadSalonInfoWithServicesForSrcStoreSearch = ({
  storeNumber,
  onSuccess,
  onError,
}) => ({
  type: salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH,
  storeNumber,
  onSuccess,
  onError,
});

export const loadSalonInfoWithServicesForSrcStoreSearchRequest = () => ({
  type: salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH_REQUEST,
});

export const loadSalonInfoWithServicesForSrcStoreSearchSuccess = () => ({
  type: salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH_SUCCESS,
});

export const loadSalonInfoWithServicesForSrcStoreSearchFailure = ({ error }) => ({
  type: salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH_FAILURE,
  error,
});
/**
 * Action Creator to used to load new salon info for an engagement
 *
 * Redux Path: entities.salon
 *
 * @param {Number} storeNumber
 */
export const loadEngagementSalon = ({ engagementStoreNumber }) => ({
  type: salonActionTypes.LOAD_ENGAGEMENT_SALON,
  engagementStoreNumber,
});

export const loadSalonsInfoWithHoursSuccess = ({ salons }) => ({
  type: salonActionTypes.LOAD_SALONS_INFO_WITH_HOURS_SUCCESS,
  payload: salons,
});

export default {
  loadSalonInfoWithHours,
  loadSalonInfoWithHoursRequest,
  loadSalonInfoWithHoursSuccess,
  loadSalonsInfoWithHoursSuccess,
  loadSalonInfoWithHoursFailure,

  loadSalonInfoWithServices,
  loadSalonInfoWithServicesRequest,
  loadSalonInfoWithServicesSuccess,
  loadSalonInfoWithServicesFailure,

  loadSalonInfoWithServicesForSrcStoreSearch,
  loadSalonInfoWithServicesForSrcStoreSearchRequest,
  loadSalonInfoWithServicesForSrcStoreSearchSuccess,
  loadSalonInfoWithServicesForSrcStoreSearchFailure,
};
