import { put, takeEvery, call, all } from "redux-saga/effects";
import { noop } from "lodash/fp";
import {
  postResendMeetingLinkFailure,
  postResendMeetingLinkRequest,
  postResendMeetingLinkSuccess,
  POST_RESEND_MEETING_LINK,
} from "./resendMeetingLinkActions";
import { fetchResendMeetingLink } from "@/core/services/systemServicesBooking/resendMeetingLinkEndPoint";

export function* onResendMeetingLink({ engagementId, onComplete = noop }) {
  try {
    yield put(postResendMeetingLinkRequest());
    yield call(fetchResendMeetingLink, {
      engagementId,
    });

    yield put(postResendMeetingLinkSuccess({ engagementId }));
    onComplete();
  } catch (error) {
    yield put(postResendMeetingLinkFailure());
  }
}

function* watchResendMeetingLink() {
  yield takeEvery(POST_RESEND_MEETING_LINK, onResendMeetingLink);
}

export default function* resendMeetingLinkSaga() {
  yield all([watchResendMeetingLink()]);
}
