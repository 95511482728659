export const GET_SEARCH_ADDRESS_BY_ZIP = "GET_SEARCH_ADDRESS_BY_ZIP";
export const GET_SEARCH_ADDRESS_BY_ZIP_REQUEST = "GET_SEARCH_ADDRESS_BY_ZIP_REQUEST";
export const GET_SEARCH_ADDRESS_BY_ZIP_SUCCESS = "GET_SEARCH_ADDRESS_BY_ZIP_SUCCESS";
export const GET_SEARCH_ADDRESS_BY_ZIP_FAILURE = "GET_SEARCH_ADDRESS_BY_ZIP_FAILURE";
export const SET_ZIP_SEARCH_FORM_TO_UPDATE = "SET_ZIP_SEARCH_FORM_TO_UPDATE";
export const SET_ZIP_SEARCH_CITY_OPTIONS = "SET_ZIP_SEARCH_CITY_OPTIONS";

/**
 * Action to get address from google maps api by zipcode
 * @memberOf Actions.searchAddressByZip
 * @function
 * @name getSearchAddressByZip
 * @param {String|Number} zipCode
 * @returns {Object}
 */
export const getSearchAddressByZip = zipCode => ({
  type: GET_SEARCH_ADDRESS_BY_ZIP,
  zipCode,
});

/**
 * Action to init loading state for searchAddressByZip
 * @memberOf Actions.searchAddressByZip
 * @function
 * @name getSearchAddressByZipRequest
 * @returns {Object}
 */
export const getSearchAddressByZipRequest = () => ({
  type: GET_SEARCH_ADDRESS_BY_ZIP_REQUEST,
});

/**
 * Action to stop loading state for searchAddressByZip as success and save address to state
 * @memberOf Actions.searchAddressByZip
 * @function
 * @name getSearchAddressByZipSuccess
 * @returns {Object}
 */
export const getSearchAddressByZipSuccess = address => ({
  type: GET_SEARCH_ADDRESS_BY_ZIP_SUCCESS,
  address,
});

/**
 * Action to stop loading state for searchAddressByZip as failure
 * @memberOf Actions.searchAddressByZip
 * @function
 * @name getSearchAddressByZipFailure
 * @returns {Object}
 */
export const getSearchAddressByZipFailure = error => ({
  type: GET_SEARCH_ADDRESS_BY_ZIP_FAILURE,
  error,
});

/**
 * Action to set form id of form to update with zip search results
 * @memberOf Actions.searchAddressByZip
 * @function
 * @name setZipSearchFormToUpdate
 * @param {String|Number} zipCode
 * @returns {Object}
 */
export const setZipSearchFormToUpdate = formId => ({
  type: SET_ZIP_SEARCH_FORM_TO_UPDATE,
  formId,
});

/**
 * Action to set array of city options
 * @memberOf Actions.searchAddressByZip
 * @function
 * @name setZipSearchCityOptions
 * @param {String[]} cityOptions
 * @returns {Object}
 */
export const setZipSearchCityOptions = cityOptions => ({
  type: SET_ZIP_SEARCH_CITY_OPTIONS,
  cityOptions,
});
