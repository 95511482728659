import React, { useState } from "react";
import { connect } from "react-redux";
import AddButton from "web/common/buttons/addButton";
import {
  HotelBookingCartAddonModal,
  HotelCheckInOutCartAddonModal,
} from "dux/hotelCartAddons/HotelCartAddonModal";
import { selectIsReservationDisabled } from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { LayoutBox } from "@/layout/box/Box";

export const HotelCartAddonAddButtonComponent = ({
  componentId,
  petId,
  diComp,
  isAddButtonDisabled = false,
}) => {
  const [showingModal, setShowingModal] = useState(false);

  const AddonModal = diComp?.modal;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <AddButton
        buttonDisabled={isAddButtonDisabled}
        fontSize="regular"
        opacity="1"
        label="Add/Edit/Delete Add-On"
        buttonClickHandler={() => setShowingModal(!showingModal)}
      />
      <AddonModal petId={petId} setShowingModal={setShowingModal} showingModal={showingModal} />
    </LayoutBox>
  );
};

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingCartAddonAddButton = connect((state, { petId }) => {
  return {
    componentId: "AddAddOn-HotelCart_Button",
    petId,
    diComp: {
      modal: HotelBookingCartAddonModal,
    },
  };
})(HotelCartAddonAddButtonComponent);

// HOTEL CHECK IN/OUT ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInOutCartAddonAddButton = connect(state => {
  const isReservationDisabled = selectIsReservationDisabled(state);

  return {
    componentId: "AddAddOn-HotelCart_Button--checkinout",
    diComp: {
      modal: HotelCheckInOutCartAddonModal,
    },
    isAddButtonDisabled: isReservationDisabled,
  };
})(HotelCartAddonAddButtonComponent);
