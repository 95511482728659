import { combineReducers } from "redux";
import { GET_ITINERARY_HISTORY_LIST_SUCCESS } from "./itineraryHistoryListActions";
import itineraryHistoryFilterButtonsReducer from "../itineraryHistoryListFilterButtons/itineraryHistoryListFilterButtonsReducer";

const initialState = [];

const itineraryHistoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ITINERARY_HISTORY_LIST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  list: itineraryHistoryListReducer,
  filters: itineraryHistoryFilterButtonsReducer,
});
