export const GET_HOTEL_SERVICE_MANAGEMENT = "GET_HOTEL_SERVICE_MANAGEMENT";
export const getHotelServiceManagement = ({ fromDate, toDate }) => ({
  type: GET_HOTEL_SERVICE_MANAGEMENT,
  fromDate,
  toDate,
});

export const GET_HOTEL_SERVICE_MANAGEMENT_REQUEST = "GET_HOTEL_SERVICE_MANAGEMENT_REQUEST";
export const getHotelServiceManagementRequest = () => ({
  type: GET_HOTEL_SERVICE_MANAGEMENT_REQUEST,
});

export const GET_HOTEL_SERVICE_MANAGEMENT_SUCCESS = "GET_HOTEL_SERVICE_MANAGEMENT_SUCCESS";
export const getHotelServiceManagementSuccess = payload => ({
  type: GET_HOTEL_SERVICE_MANAGEMENT_SUCCESS,
  payload,
});

export const GET_HOTEL_SERVICE_MANAGEMENT_FAILURE = "GET_HOTEL_SERVICE_MANAGEMENT_FAILURE";
export const getHotelServiceManagementFailure = error => ({
  type: GET_HOTEL_SERVICE_MANAGEMENT_FAILURE,
  error,
});
