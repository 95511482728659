import { connect } from "react-redux";
import BookingFoodComponent from "dux/_components/feeding/booking/BookingFoodComponent";
import { getPetFoods } from "web/food/foodsSelector";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { medicationTypes } from "web/newMedication/newMedicationConstants";
import { getIsPetCardShowing } from "dux/petCartCard/selectors/petCartCardSelectors";

const mapStateToProps = (state, ownProps) => {
  const { petId } = ownProps;
  const systemFlow = getSystemBookingFlow(state);
  return {
    petId,
    isHidden: systemFlow !== systemName.HOTEL || !getIsPetCardShowing(state, { petId }),
    food: getPetFoods(state, { petId }),
    hasPadding: "1rem 1rem 0 1rem",
    locationType: medicationTypes.CART,
    showTotalPrice: true,
  };
};

export default connect(mapStateToProps)(BookingFoodComponent);
