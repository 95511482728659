export const SET_HOTEL_ENGAGEMENT = "SET_HOTEL_ENGAGEMENT";
export const SET_HOTEL_ENGAGEMENT_REQUEST = "SET_HOTEL_ENGAGEMENT_REQUEST";
export const SET_HOTEL_ENGAGEMENT_SUCCESS = "SET_HOTEL_ENGAGEMENT_SUCCESS";
export const SET_HOTEL_ENGAGEMENT_FAILURE = "SET_HOTEL_ENGAGEMENT_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setHotelEngagement = engagements => ({
  type: SET_HOTEL_ENGAGEMENT,
  engagements,
});

export const setHotelEngagementRequest = () => ({
  type: SET_HOTEL_ENGAGEMENT_REQUEST,
});

export const setHotelEngagementSuccess = () => ({
  type: SET_HOTEL_ENGAGEMENT_SUCCESS,
});

export const setHotelEngagementFailure = error => ({
  type: SET_HOTEL_ENGAGEMENT_FAILURE,
  error,
});
