/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_HOTEL_ROOM_TYPES = "GET_HOTEL_ROOM_TYPES";
export const GET_HOTEL_ROOM_TYPES_REQUEST = "GET_HOTEL_ROOM_TYPES_REQUEST";
export const GET_HOTEL_ROOM_TYPES_SUCCESS = "GET_HOTEL_ROOM_TYPES_SUCCESS";
export const GET_HOTEL_ROOM_TYPES_FAILURE = "GET_HOTEL_ROOM_TYPES_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getHotelRoomTypes = payload => ({
  type: GET_HOTEL_ROOM_TYPES,
  payload,
});

export const getHotelRoomTypesRequest = payload => ({
  type: GET_HOTEL_ROOM_TYPES_REQUEST,
  payload,
});

export const getHotelRoomTypesSuccess = payload => ({
  type: GET_HOTEL_ROOM_TYPES_SUCCESS,
  payload,
});

export const getHotelRoomTypesFailure = payload => ({
  type: GET_HOTEL_ROOM_TYPES_FAILURE,
  payload,
});
