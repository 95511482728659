import React from "react";
import styled from "styled-components";
import FoodMorningCheckboxContainer from "./FoodMorningCheckboxContainer";
import FoodAfternoonCheckboxContainer from "./FoodAfternoonCheckboxContainer";
import FoodEveningCheckboxContainer from "./FoodEveningCheckboxContainer";
import ErrorText from "../common/ErrorText";

const Wrapper = styled.div`
  display: grid;
  grid-column: 2 / 4;
  color: ${props => props.error && "red"};
`;

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-rows: 1;
  grid-template-columns: repeat(3, 1fr);
`;

const Title = styled.div``;

export default function FoodTimeWrapper({ isHidden, foodId, title, error, petId }) {
  return !isHidden ? (
    <Wrapper error={error}>
      <Title>{title}</Title>
      <CheckboxContainer>
        <FoodMorningCheckboxContainer foodId={foodId} petId={petId} />
        <FoodAfternoonCheckboxContainer foodId={foodId} petId={petId} />
        <FoodEveningCheckboxContainer foodId={foodId} petId={petId} />
      </CheckboxContainer>
      {error && <ErrorText message={error} />}
    </Wrapper>
  ) : null;
}
