/**
 * Utility for case insensitive searches
 * @param {String} baseValue - Base string to compare search value against
 * @param {String} searchValue - Search value passed
 * @returns {Boolean}
 */
export default function valueMatchesSearchString(baseValue, searchValue) {
  return (
    baseValue &&
    baseValue
      .trim()
      .toLowerCase()
      .includes(searchValue.trim().toLowerCase())
  );
}
