import { connect } from "react-redux";
import { getBulkPackageOfferingsTotalPrice } from "../bulkPackageOfferingsSelectors";
import { getSelectedPet } from "../../../../core/selectors/bookingUISelectors";
import PricingLineItemComponent from "../../../_components/pricing/PricingLineItemComponent";

const mapStateToProps = state => {
  const petId = getSelectedPet(state);
  const price = getBulkPackageOfferingsTotalPrice(state, { petId });
  return {
    componentID: `bulk-package-offerings-cart-details-pricing-total`,
    price: price ? `$${price?.toFixed(2)}` : null,
    label: "Total Price",
    isHidden: false,
  };
};

export default connect(mapStateToProps)(PricingLineItemComponent);
