import { connect } from "react-redux";
import PetMedicationComponent from "./PetProfileMedicationComponent";
import { getPetMedications, getMedicationIds } from "./medicationsSelector";
import { getCurrentPet } from "../../core/selectors/persistentSelectors";
import { setMedications } from "./actions/medicationsActions";
import normalizeArrayByProperty from "../../core/utils/normalizeUtils/normalizeArray";
import { getSystemBookingFlow } from "../setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import getIsHotelWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { selectMedicationsList } from "@/dux/medications/medicationsSelectors";
import { getMedicationsList } from "@/dux/medications/medicationActions";
import isEmpty from "lodash/isEmpty";
import { createLoadingSelector } from "@/core/selectors/utils";
import { createUpdateDeleteMedicationActionTypes } from "./actions/createUpdateDeleteMedicationActions";

const mapStateToProps = state => {
  const petId = getCurrentPet(state);
  const medicationIds = getMedicationIds(state, { petId });
  const systemBookingFlow = getSystemBookingFlow(state);
  const medicationList = selectMedicationsList(state);
  return {
    petId,
    medicationList,
    isHidden: systemBookingFlow !== systemName.HOTEL || getIsHotelWorkflowFeatureFlagHidden(state),
    medications: getPetMedications(state, { petId }),
    showBottomBorder: medicationIds.length === 0,
    isLoading: createLoadingSelector([createUpdateDeleteMedicationActionTypes.UPDATE_PET_MEDICATION])(state),
  };
};

const mapDispatchToProps = dispatch => ({
  setPetMeds: ({ medications, petId }) => {
    const normalizedMedications = normalizeArrayByProperty(medications, "petMedicationId");
    dispatch(setMedications({ medications: normalizedMedications, petId }));
  },
  fetchMedicationsList: () => dispatch(getMedicationsList()),
});

const mergeProps = (propsFromState, propsFromDispatch) => {
  const { petId, medicationList, isHidden, medications, showBottomBorder, isLoading } = propsFromState;
  const { fetchMedicationsList, setPetMeds } = propsFromDispatch;

  return {
    petId,
    isHidden,
    medications,
    showBottomBorder,
    isLoading,
    setPetMedications: ({ medications }) => {
      setPetMeds({ medications, petId });
    },
    loadMedicationsList: () => {
      if (isHidden) return false;
      if (isEmpty(medicationList)) fetchMedicationsList();
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PetMedicationComponent);
