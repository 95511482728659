import React from "react";
import styled from "styled-components";
import { color } from "../../../web/common/styles/theme";
import setStyleObjToStyledCompReadyString from "../../../core/utils/objectUtils/styleObjToStyledCompReadyString";

// SET CSS CONFIGURATIONS(S)
function isSelected(selected) {
  if (selected) {
    return `
      background-color: ${color.kalEl};
      color: ${color.victorStone};
    `;
  }
  return `
      background-color: none;
      color: ${color.tChalla};
    `;
}

export default function SmallPillButton(props) {
  const { isHidden, componentID, label, disabled, handleClick } = props;

  if (!isHidden) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <RootComponent
        data-testid={componentID}
        id={componentID}
        disabled={disabled}
        onClick={handleClick}
        {...props}
      >
        {label}
      </RootComponent>
    );
  }

  return null;
}

const RootComponent = styled.button`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  border-radius: 1rem;
  border: 1px solid ${color.ororoMunroe}
  padding: .2rem .5rem .2rem .5rem;
  margin: .5rem .1rem .5rem .1rem;
  cursor: pointer;
  font-size: .6rem;


  /* preset Configurations */
  ${props => isSelected(props.selected)};

  /* Add any additional UseCase CSS or override css to this component */
  ${props => (props.styleObj ? setStyleObjToStyledCompReadyString(props.styleObj) : "")};
`;
