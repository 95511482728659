import * as React from 'react';
import { psx } from '../../utils/clsx';
import { Layout, BoxProps } from '../../components/Layout';
import List, { IdObj } from '../../components/List';
import Hover from '../../components/Hover';

import './styles.css';

export interface PetCardProps<T> extends BoxProps {
  /** An element to display the unknown pet image */
  petImage: React.ReactNode;

  /** An array of data containing the text or components in the "label" propety */
  topSectionRows: T[];

  /** An array of data containing the text or components in the "label" propety */
  bottomSectionRows?: T[];

  /** An optional element containing an icon to display as a right sidebar */
  serviceIcon?: React.ReactNode;
}

export interface petCardIdObj extends IdObj {}

export const PetCard = <T extends petCardIdObj>({
  componentId,
  petImage,
  topSectionRows,
  bottomSectionRows,
  serviceIcon,
  isHidden = false,
  className,
  children,
  isDisabled,
  ...props
}: PetCardProps<T>) => {
  const classNames = psx('psm', 'psm-pet-card', className);

  if (isHidden) return null;

  return (
    <Hover style={{ border: '1px solid #dedede' }} hoverStyle={isDisabled ? {} : { border: '1px solid #1777f2' }}>
      <Layout.Box id={componentId} className={classNames} {...props} style={{ cursor: !isDisabled && 'pointer' }}>
        <Layout.Sidebar side="right" sideWidth="44px" space="sidebar-space-4">
          <Layout.Stack className="psm-pet-card__card-body" space="stack-space-2">
            <Layout.Cluster
              align="flex-start"
              justify="flex-start"
              space="cluster-space-2.5"
              className="psm-pet-card__cluster"
            >
              {/* Pet Image */}
              <Layout.Box style={{ width: '50px', height: '50px', display: 'flex' }}>{petImage}</Layout.Box>

              {/* Top Section */}
              <Layout.Stack>
                <List items={topSectionRows} as={Layout.Box}>
                  {(row) => <>{row.label}</>}
                </List>
              </Layout.Stack>
            </Layout.Cluster>

            {/* Bottom Section */}
            {bottomSectionRows && (
              <Layout.Stack>
                <List items={bottomSectionRows} as={Layout.Box}>
                  {(row) => <>{row.label}</>}
                </List>
              </Layout.Stack>
            )}
          </Layout.Stack>

          {/* Sidebar */}
          {serviceIcon}
        </Layout.Sidebar>
      </Layout.Box>
    </Hover>
  );
};

export default PetCard;
