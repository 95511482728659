// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-icon {
  --setIconSize: ;
  --setIconVerticalAlign: ;
  display: inline-block;
  vertical-align: var(--setIconVerticalAlign);
  height: var(--setIconSize);
  width: var(--setIconSize);
}

/*  */
`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/components/Icon/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,wBAAwB;EACxB,qBAAqB;EACrB,2CAA2C;EAC3C,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA,KAAK","sourcesContent":[".psm-icon {\n  --setIconSize: ;\n  --setIconVerticalAlign: ;\n  display: inline-block;\n  vertical-align: var(--setIconVerticalAlign);\n  height: var(--setIconSize);\n  width: var(--setIconSize);\n}\n\n/*  */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
