import React from "react";
import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";
import { LayoutStack } from "@/layout/stack/Stack";
import { HotelPetParentContactLink } from "@/web/petCheckInOut/petParentDetails/petParentContactInfo/PetParentContactInfoComponent";
import { HotelOnlineAccountContainer } from "@/dux/hotelOnlineAccount/HotelOnlineAccount";
import { PSAInputHotelCheckBox } from "@/dux/psa/psaInput";
import { Heading, Hr } from "@petsmart-ui/sparky";

export const CheckInOutPetParentDetails = props => {
  const { componentId, isHidden, title } = props;

  if (isHidden) return null;

  return (
    <Layout.Box id={componentId}>
      <LayoutStack>
        <Heading tagName="h2" size="title">
          {title}
        </Heading>
        <Hr style={{ margin: "15px 0" }} />
        <HotelPetParentContactLink />
        <HotelOnlineAccountContainer />
        <PSAInputHotelCheckBox />
        <Hr style={{ margin: "15px 0" }} />
      </LayoutStack>
    </Layout.Box>
  );
};

// HOTEL CHECKIN PET PARENT DETAILS CONTAINER --------------------------------------------------------------------------
export const HotelCheckInOutPetParentDetails = connect((state, ownProps) => {
  return {
    isHidden: false,
    componentId: "HotelCheckInOutPetParentDetails",
    title: "PetsHotel Pet Parent Details",
  };
})(CheckInOutPetParentDetails);
