import { APPOINTMENT_STATUS } from "core/constants";

/**
 *
 * @param {object} engagement - Hotel engagement
 * @returns {boolean}
 */
export const isDateTimeEditable = (engagement, isCheckOutTime = false) => {
  const status = engagement?.status;

  switch (status) {
    case APPOINTMENT_STATUS.BOOKED:
    case APPOINTMENT_STATUS.CONFIRMED:
      return true;
    case APPOINTMENT_STATUS.CHECKED_IN:
      return isCheckOutTime;
    case APPOINTMENT_STATUS.CHECKED_OUT:
    default:
      return false;
  }
};
