import React from "react";
import { connect } from "react-redux";
import { TextPassage } from "@petsmart-ui/sparky";
import { MedsDiscontinuedLabel } from "@/dux/discontinuedLabel/DiscontinuedLabel";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutBox } from "@/layout/box/Box";
import { color } from "@/web/common/styles/theme";
import PetProfileMedicationsRightArrowContainer from "@/web/medicationList/PetProfileMedicationsRightArrowContainer";
import { CheckInOutMedList } from "@/dux/_components/checkInOutMedications/CheckInOutMedsForm";
import BookingMedicationsDeleteButtonContainer from "@/web/medicationList/BookingMedicationsDeleteButtonContainer";
import BookingMedicationFormContainer from "@/dux/bookingMedication/bookingMedicationForm/BookingMedicationFormContainer";
import PetProfileMedicationFormContainer from "@/web/petProfileMedicationForm/PetProfileMedicationFormContainer";
import PetProfileMedicationsDeleteButtonContainer from "@/web/medicationList/PetProfileMedicationsDeleteButtonContainer";
import { getMedIsDiscontinuedById } from "@/web/medication/medicationsSelector";

const DefaultLabel = ({ medicationIndex }) => (
  <TextPassage size="lg">{`Medication ${medicationIndex}`}</TextPassage>
);

export const MedListItem = props => {
  const {
    componentId,
    isHidden,
    medicationId,
    medicationIndex,
    petId,
    locationType,
    setMedFormInvalid,
    invalidMedForms,
    diComp,
    hasBorder,
  } = props;

  if (isHidden) return null;

  const LabelComponent = diComp?.label || DefaultLabel;
  const FormComponent = diComp?.form || (() => <></>);

  return (
    <LayoutBox
      id={componentId}
      padding="scale-G1"
      style={{
        paddingRight: 0,
        paddingLeft: 0,
        ...(hasBorder && { borderBottom: `1px solid ${color.veryLightGrey}` }),
      }}
    >
      <LayoutStack>
        <LayoutBox padding="scale-0">
          <LayoutCluster style={{ justifyContent: "space-between" }}>
            <LayoutBox padding="scale-0">
              <LabelComponent
                medicationId={medicationId}
                medicationIndex={medicationIndex}
                petId={petId}
                locationType={locationType}
              />
            </LayoutBox>

            <LayoutBox padding="scale-0">
              <PetProfileMedicationsRightArrowContainer
                medicationId={medicationId}
                petId={petId}
                locationType={locationType}
              />
            </LayoutBox>
          </LayoutCluster>
        </LayoutBox>

        <LayoutBox padding="scale-0">
          <FormComponent
            medicationIndex={medicationIndex}
            medicationId={medicationId}
            petId={petId}
            locationType={locationType}
            setMedFormInvalid={setMedFormInvalid}
            invalidMedForms={invalidMedForms}
          />
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

export const CheckInOutMedListItem = connect((state, { petId, medicationId, medicationIndex }) => {
  const isDiscontinued = getMedIsDiscontinuedById(state, { petId, medicationId });
  return {
    componentId: `CheckInOutMedListItem-${medicationIndex}`,
    diComp: {
      label: isDiscontinued && MedsDiscontinuedLabel,
      form: CheckInOutMedList,
    },
  };
})(MedListItem);

export const BookingMedListItem = connect((state, { medicationIndex }) => {
  return {
    componentId: `BookingMedListItem-${medicationIndex}`,
    diComp: {
      label: BookingMedicationsDeleteButtonContainer,
      form: BookingMedicationFormContainer,
    },
  };
})(MedListItem);

export const PetProfileMedListItem = connect((state, { petId, medicationId, medicationIndex }) => {
  const isDiscontinued = getMedIsDiscontinuedById(state, { petId, medicationId });
  return {
    componentId: `PetProfileMedListItem-${medicationIndex}`,
    diComp: {
      label: isDiscontinued ? MedsDiscontinuedLabel : PetProfileMedicationsDeleteButtonContainer,
      form: PetProfileMedicationFormContainer,
    },
  };
})(MedListItem);
