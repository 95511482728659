import React from "react";
import styled from "styled-components";
import { AFontRegular } from "../../../../../../common/styles/abstractStyledComponents";
import { fontSizes } from "web/common/styles/responsive/fonts";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em 2em 0 2em;
`;

const Header = AFontRegular.extend`
  font-size: ${fontSizes.regular};
  font-weight: bold;
  margin: 0 auto 0.5em;
`;

const Message = styled.div`
  margin: 30px auto;
  text-align: center;
  max-width: 240px;
`;

export default () => (
  <Content>
    <Header>Warning</Header>
    <Message>The customer has 10 active pets and cannot add anymore.</Message>
  </Content>
);
