import { connect } from "react-redux";
import SmallPillButton from "../../../_components/buttons/SmallPillButton";
import { getHotelDeparturesFilterBy } from "./hotelDeparturesPetServiceSelector";
import {
  filterByAllDepartures,
  filterByDayCareDepartures,
} from "./hotelDeparturesFilterButtonActions";

const mapStateToProps = state => {
  const filterBy = getHotelDeparturesFilterBy(state);

  return {
    isHidden: false,
    componentID: "SmallPillButton_HotelDeparturesDayCareFilterButton",
    label: "Day Care",
    selected: filterBy.dayCare,
    disabled: false,
    filterBtnStatus: filterBy.dayCare,
    isAllOthersSelected:
      filterBy.overnight && filterBy.dayCamp && filterBy.attentionNeeded && filterBy.unassignedPet,
  };
};

const mapDispatchToProps = dispatch => ({
  handleFilterByOverNightDepartures: ({ filterBtnStatus, isAllOthersSelected }) => {
    if (filterBtnStatus) {
      // toggle button off
      dispatch(filterByDayCareDepartures({ filterBtnStatus: !filterBtnStatus }));

      // toggle off all button as well
      dispatch(filterByAllDepartures({ filterBtnStatus: false }));
    } else {
      dispatch(filterByDayCareDepartures({ filterBtnStatus: !filterBtnStatus }));

      if (isAllOthersSelected) {
        dispatch(filterByAllDepartures({ filterBtnStatus: true }));
      }
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleClick: () =>
    propsFromDispatch.handleFilterByOverNightDepartures({
      filterBtnStatus: propsFromState.filterBtnStatus,
      isAllOthersSelected: propsFromState.isAllOthersSelected,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SmallPillButton);
