import { connect } from "react-redux";
import { hideAuthModal } from "../../../core/actionCreators/authActionCreators";
import QuickPinModalsComponent from "./QuickPinModalsComponent";

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(hideAuthModal())
});

export default connect(
  null,
  mapDispatchToProps
)(QuickPinModalsComponent);
