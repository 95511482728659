import React, { useState } from "react";
import { LayoutBox } from "@/layout/box/Box";
import { Button } from "@petsmart-ui/sparky";
import { connect } from "react-redux";
import { getCurrentPet, getIsSRCAgent } from "core/selectors/persistentSelectors";
import { getCurrentItinerary } from "core/selectors/checkInOutSelector";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import { GET_HOTEL_ITINERARY } from "dux/hotelItinerary/hotelItineraryActions";
import {
  GET_HOTEL_ELIGIBILITY,
  getHotelEligibility,
  POST_HOTEL_ELIGIBILITY,
  postHotelEligibility,
} from "dux/hotelEligibility/hotelEligibilityActions";
import {
  getShouldShowHotelCheckInAlertsForMultiPets,
  getShouldShowHotelCheckInEligibilityForModal,
} from "dux/hotelEligibility/hotelEligibilitySelectors";
import CommonModal from "web/common/modals/commonModal";
import {
  HotelCheckInMultiPetAlertsModal,
  HotelCheckInSinglePetAlertsModal,
} from "web/hotelAlerts/hotelCheckInOutAlertsModal";
import { itinerariesStatusList } from "dux/_constants";
import {
  getHotelItinerary,
  selectIsReservationDisabled,
} from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { isMultiIndexList } from "core/utils/arrayUtils/indexManipulation";
import {
  selectPetIdsAndEngagementTypesForEligibility,
  getHotelEngagementsPets,
  selectHotelPetsToCheckIn,
  selectAllPetsHaveRoomAssigned,
  selectIsPetStatusCheckedIn,
  selectLastHotelEngagementDate,
  selectCurrentHotelEngagement,
  selectIsCheckInTodayOrPast,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { eligibilityTypes } from "dux/eligibility/constants/eligibilityConstants";
import { getCheckInOutPetRoom } from "@/dux/_components/checkInOutAppointmentSummary/checkInOutAppointmentSelectors";
import { isRoomAssignedEmptyForOvernightService } from "@/core/utils/checkInOutUtils";
import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";

export const CheckInPetsHotelButton = props => {
  const {
    componentId,
    onGetEligibility,
    buttonLabel,
    isDisabled,
    isHidden,
    petCustomerHasMissingEligibilityRequirement,
    alertsModalType,
  } = props;

  const [canShowModal, setCanShowModal] = useState(false);

  // Dependency injected Redux Container for single or multi pet eligibility alert modal
  const HotelCheckInOutPetAlertsModal = alertsModalType;

  if (isHidden) return null;

  return (
    <div>
      <LayoutBox id={componentId} padding="scale-0">
        <Button
          variant="primary"
          onClick={() => {
            setCanShowModal(true);
            onGetEligibility();
          }}
          disabled={isDisabled}
          text={buttonLabel}
        />
      </LayoutBox>
      {petCustomerHasMissingEligibilityRequirement && !isDisabled && canShowModal && (
        <CommonModal
          id={`${componentId}__commonModal--eligibility`}
          onClose={() => {
            setCanShowModal(false);
          }}
        >
          <HotelCheckInOutPetAlertsModal
            id={`${componentId}__eligibilityModalContent`}
            onClose={() => {
              setCanShowModal(false);
            }}
          />
        </CommonModal>
      )}
    </div>
  );
};

// SINGLE PET CONTAINER ----------------------------------------------------------------------------------------
export const CheckInSinglePetHotelButton = connect(
  state => {
    // general vars
    const petId = getCurrentPet(state);
    const hotelItineraryId = getCurrentItinerary(state);
    const customerKey = getCurrentCustomerKey(state);
    const isCheckedIn = selectIsPetStatusCheckedIn(state, { petId });
    const isFutureCheckIn = !selectIsCheckInTodayOrPast(state, { petId });

    // Loading
    const isLoading = createLoadingSelector([
      customersActionTypes.LOAD_CUSTOMER,
      GET_HOTEL_ITINERARY,
      GET_HOTEL_ELIGIBILITY,
    ])(state);

    // Room Assignment
    const isReservationDisabled = selectIsReservationDisabled(state);
    const engagement = selectCurrentHotelEngagement(state, { petId });
    const engagementId = engagement?.engagementId;
    const currentRoomDetails = getCheckInOutPetRoom(state, { engagementId });
    const isRoomAssignedEmpty = isRoomAssignedEmptyForOvernightService(
      currentRoomDetails?.roomNumber,
      currentRoomDetails?.isUnassigned,
      engagement?.engagementType,
    );

    // Modal Check
    const petCustomerHasMissingEligibilityRequirement = getShouldShowHotelCheckInEligibilityForModal(
      state,
      { customerKey, petId },
    );

    return {
      componentId: "CheckInSinglePetHotelButton",
      buttonLabel: isLoading ? "Loading..." : "Check-in",
      isDisabled:
        isLoading || isRoomAssignedEmpty || getIsSRCAgent(state) || isCheckedIn || isFutureCheckIn,
      isHidden: isReservationDisabled,
      petCustomerHasMissingEligibilityRequirement,
      alertsModalType: HotelCheckInSinglePetAlertsModal,

      // for merge props
      petId,
      hotelItineraryId,
      customerKey,
    };
  },

  dispatch => {
    return {
      getEligibility: ({ petId, hotelItineraryId, customerKey }) => {
        dispatch(
          getHotelEligibility({
            payload: {
              petId,
              hotelItineraryId,
              customerKey,
              isChangingStatus: true,
              status: itinerariesStatusList.CHECKED_IN,
            },
          }),
        );
      },
    };
  },
  (mapProps, dispatchProps) => {
    const {
      buttonLabel,
      isDisabled,
      isHidden,
      componentId,
      petId,
      hotelItineraryId,
      customerKey,
      petCustomerHasMissingEligibilityRequirement,
      alertsModalType,
    } = mapProps;
    const { dispatchCheckInSinglePetAction, checkInPet, getEligibility } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      buttonLabel,
      isDisabled,
      isHidden,
      petCustomerHasMissingEligibilityRequirement,
      alertsModalType,

      // actions to pass to view
      dispatchCheckInSinglePetAction,
      onGetEligibility: () => getEligibility({ petId, hotelItineraryId, customerKey }),
    };
  },
)(CheckInPetsHotelButton);

// MULTI PET CONTAINER ----------------------------------------------------------------------------------------
export const CheckInAllPetSHotelButton = connect(
  state => {
    // General vars
    const petId = getCurrentPet(state);
    const customerKey = getCurrentCustomerKey(state);
    const isReservationDisabled = selectIsReservationDisabled(state);
    const petsToCheckIn = selectHotelPetsToCheckIn(state);
    const multiplePetsLeftToCheckIn = petsToCheckIn?.length > 1;
    const isFutureCheckIn = !selectIsCheckInTodayOrPast(state, { petId });

    // Loading
    const isLoading = createLoadingSelector([
      customersActionTypes.LOAD_CUSTOMER,
      GET_HOTEL_ITINERARY,
      POST_HOTEL_ELIGIBILITY,
    ])(state);

    // Room Assignment
    const allPetsHaveRooms = selectAllPetsHaveRoomAssigned(state, { petIds: petsToCheckIn });
    const engagement = selectCurrentHotelEngagement(state, { petId });
    const isOvernight = engagement?.engagementType === engagementTypes.OVERNIGHT_BOARDING;
    const hasNeededRooms = isOvernight ? allPetsHaveRooms : true;

    // Multi pet check
    const allPetIds = getHotelEngagementsPets(state);
    const isMultiPet = isMultiIndexList(allPetIds);

    const lastHotelEngagementDate = selectLastHotelEngagementDate(state);
    const petsAndServiceTypes = selectPetIdsAndEngagementTypesForEligibility(state, {
      petIds: petsToCheckIn,
    });

    // Modal check
    const petCustomerHasMissingEligibilityRequirement = getShouldShowHotelCheckInAlertsForMultiPets(
      state,
      customerKey,
      allPetIds,
    );

    return {
      componentId: "CheckInAllPetsHotelButton",
      buttonLabel: isLoading ? "Loading..." : "Check-in all pets",
      isDisabled:
        isLoading ||
        getIsSRCAgent(state) ||
        !multiplePetsLeftToCheckIn ||
        hasNeededRooms === false ||
        isFutureCheckIn,
      isHidden: !isMultiPet || isReservationDisabled,
      petCustomerHasMissingEligibilityRequirement,
      alertsModalType: HotelCheckInMultiPetAlertsModal,

      // for merge props
      lastHotelEngagementDate,
      petsAndServiceTypes,
      itineraryId: getHotelItinerary(state)?.itineraryId,
    };
  },

  dispatch => {
    return {
      dispatchGetHotelEligibility: ({ itineraryId, petsAndServiceTypes, checkOutDateTime }) => {
        dispatch(
          postHotelEligibility({
            eligibilityType: eligibilityTypes.CHECK_IN,
            payload: {
              petsAndServiceTypes,
              checkOutDateTime,
              hotelItineraryId: itineraryId,
              status: itinerariesStatusList.CHECKED_IN,
              isChangingStatus: true,
            },
          }),
        );
      },
    };
  },
  (mapProps, dispatchProps) => {
    const {
      buttonLabel,
      componentId,
      isDisabled,
      isHidden,
      lastHotelEngagementDate,
      petsAndServiceTypes,
      alertsModalType,
      petCustomerHasMissingEligibilityRequirement,
      itineraryId,
    } = mapProps;
    const { dispatchGetHotelEligibility } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      buttonLabel,
      isDisabled,
      isHidden,
      alertsModalType,
      petCustomerHasMissingEligibilityRequirement,

      // actions to pass to view
      onGetEligibility: () =>
        dispatchGetHotelEligibility({
          itineraryId,
          petsAndServiceTypes,
          checkOutDateTime: lastHotelEngagementDate,
        }),
    };
  },
)(CheckInPetsHotelButton);
