import { get, clone } from "lodash/fp";
import { createSelector } from "reselect";
import formatPhoneNumberNANP from "@/core/utils/stringManipulationUtils/formatPhoneNumber";
import { sortPhonesByCreatedDateDescending } from "@/core/selectors/petParentProfileSelectors";
import getPersistentState from "./../_persistentState";

export const getCustomers = state => {
  return state.entities.customers;
};

export const getCurrentCustomerKey = createSelector([getPersistentState], persistentState => {
  return get("currentCustomerKey", persistentState);
});

export const getCurrentCustomer = createSelector(
  [getCustomers, getCurrentCustomerKey],
  (customers, customerKey) => {
    return customers[customerKey];
  },
);

export const getCurrentCustomerFullName = createSelector([getCurrentCustomer], customer => {
  return `${customer?.firstName || ""} ${customer?.lastName || ""}`;
});

export const getCurrentCustomerFirstName = createSelector([getCurrentCustomer], customer => {
  return `${customer?.firstName || ""}`;
});

export const getCurrentCustomerPrimaryPhone = createSelector([getCurrentCustomer], customer => {
  if (!customer) return;

  const { phones } = customer;
  const primaryPhone = clone(phones)
    .sort(sortPhonesByCreatedDateDescending)
    .find(({ isActive, isPrimary }) => isPrimary && isActive);

  return primaryPhone;
});

export const getCurrentCustomerPrimaryPhoneNumber = createSelector(
  [getCurrentCustomerPrimaryPhone],
  primaryPhone => {
    return formatPhoneNumberNANP(primaryPhone?.phoneNumber);
  },
);

export const getCurrentCustomerPrimaryPhoneType = createSelector(
  [getCurrentCustomerPrimaryPhone],
  primaryPhone => {
    return primaryPhone?.phoneType;
  },
);
