//@ts-check
import React from "react";
import { connect } from "react-redux";
import { Form, Layout } from "@prism/psm-ui-components";
import styled from "styled-components";

// Selectors
import { getServiceSuspensionRoomReasons } from "@/dux/servicesManagementToggle/servicesManagementToggleSelectors";

// Styles
// Override to fix an issue where we are getting native select dropdown
const Select = styled(Form.Field.Select)`
  .psm-hidden-input {
    top: 0;
  }
`;

/**
 * Room Suspensions Reason Drop Down Component
 * @param {object} props - props object
 * @param {string} props.componentId - Component Id
 * @param {Function} props.handleChange - Handle selection
 * @param {boolean} props.isHidden - isHidden to control dom rendering
 * @param {object} props.roomSuspensionReasons - room suspension reasons
 * @param {string} props.roomSuspensionSelected - inital or selected suspension reason
 * @returns {(JSX.Element|null)}
 */
export const RoomSuspensionsReasonDropDown = props => {
  const {
    componentId,
    handleChange,
    isHidden,
    roomSuspensionReasons,
    roomSuspensionSelected,
  } = props;

  if (isHidden) {
    return null;
  }

  return (
    <Layout.Box id={componentId} style={{ height: "35px" }}>
      <Select
        id={`${componentId}__select`}
        legacy
        value={roomSuspensionSelected}
        onChange={(/** @type {object} */ event) => handleChange(event)}
        options={roomSuspensionReasons}
      />
    </Layout.Box>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const HotelManageRoomDetailsReasons = connect(
  // @ts-ignore
  (state, { initialValue, handleChange }) => {
    const reasons = getServiceSuspensionRoomReasons(state);
    const roomSuspensionSelected = initialValue;

    const roomSuspensionReasons = reasons.map(
      (/** @type {{ reasonName: string; }} */ reason) => reason.reasonName,
    );

    return {
      componentId: "HotelManageRoomDetailsReasons",
      handleChange,
      isHidden: false,
      roomSuspensionReasons,
      roomSuspensionSelected,
    };
  },
)(RoomSuspensionsReasonDropDown);
