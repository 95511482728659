import { createSelector } from "reselect";
import { get, find } from "lodash/fp";
import { getCustomer } from "../../../core/selectors/entitiesSelector";
import { systemName } from "../../setSystemType/constants/setSystemTypeConstants";

/* * ----------------------------------------------------------------------- * *\
  Get the Prism Customer property that holds the customer profiles for
  HOTEL, Training etc.
\* * ----------------------------------------------------------------------- * */
export const getPrismCustomerProfile = createSelector(
  [getCustomer],
  customer => customer && customer.prismCustomer,
);

/* * ----------------------------------------------------------------------- * *\
  Get the hotel profile from the Prism Customer property.
\* * ----------------------------------------------------------------------- * */
export const getPrismCustomerHotelProfile = createSelector(
  [getPrismCustomerProfile],
  customerProfile => {
    const customerProfiles = get("profiles", customerProfile);
    const customerHotelProfile = find({ profileType: systemName.DDC_HOTEL }, customerProfiles);
    return customerProfiles && customerHotelProfile;
  },
);
