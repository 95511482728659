import React from "react";
import styled from "styled-components";
import CloseSVG from "../../assets/icons/close.svg";

const CloseButton = styled.img`
  height: 1.25em;
  min-width: 1.25em;
  display: block;
  stroke: #fff;
  cursor: pointer;
`;

export default ({ onClick }) => <CloseButton src={CloseSVG} onClick={onClick} />;
