import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";

// utils ---------------------------------------------------------------------------------------------------------------
export const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));

export function getButtons() {
  return [
    { id: "grooming", name: "Grooming", isPressed: false, filterValue: engagementTypes.GROOMING },
    {
      id: "training",
      name: "Training",
      isPressed: false,
      filterValue: engagementTypes.TRAINING,
    },
  ];
}

// actions -------------------------------------------------------------------------------------------------------------
export const SET_FILTERING_BY_ENGAGEMENT_TYPE = "SET_FILTERING_BY_ENGAGEMENT_TYPE";
export const SET_FILTERING_BY_TRAINER = "SET_FILTERING_BY_TRAINER";

export const setFilteringByEngagementType = payload => ({
  type: SET_FILTERING_BY_ENGAGEMENT_TYPE,
  payload,
});

export const setFilteringByTrainer = payload => ({
  type: SET_FILTERING_BY_TRAINER,
  payload,
});

// reducers ------------------------------------------------------------------------------------------------------------
const initialState = arrayToObject(getButtons(), "id");

export const filterAppointmentsByEngagementType = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERING_BY_ENGAGEMENT_TYPE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const TrainingClassFilterButtonData = (state = [], action) => {
  switch (action.type) {
    case SET_FILTERING_BY_TRAINER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
