import axios from "axios";
import {
  setRequestHeaders,
  handleAuthorizationRejection,
  setResponseRefreshToken,
} from "../../utils/authHelper";

/**
 * Axios Create configuration for the Associate Web Profile Pet API (/services/pet/a/).
 *
 * @summary /services/pet/a/.
 * @memberOf APIs.AssociateWebProfilePet
 * @function
 * @name  associateWebPetProfileAPI
 * @type {axios.AxiosInstance}
 *
 * @example
 * return associateWebPetProfileAPI.post(url, params)
 * return associateWebPetProfileAPI.get(url, params)
 * return associateWebPetProfileAPI.put(url, params)
 */
export const associateWebPetProfileAPI = axios.create({
  baseURL: `${window.env.BASE_URL}${window.env.ASSOCIATE_WEB_PET_PROFILE_URI}`,
  headers: {
    "X-PETM-SOURCEID": 133, // API WILL REMOVE THIS FROM HEADER. TODO: Need to update to a query param after the change occurs.
  },
});

/**
 * Intercept the `x-refresh-session-token` value
 * - Note: This can be disabled by using the ENV flag below
 */
if (!window.env.DISABLE_API_AUTH_IN_DEV) {
  associateWebPetProfileAPI.interceptors.request.use(
    setRequestHeaders,
    handleAuthorizationRejection,
  );
  associateWebPetProfileAPI.interceptors.response.use(
    setResponseRefreshToken,
    handleAuthorizationRejection,
  );
}
