import { jobRoleConstants } from "./jobRoleConstants";

/**
 * Dictionary of Regular expressions [NOTE: all regular expressions should be placed/moved here]
 * @typedef {object} Constant.Regex
 * @memberOf Constant
 * @property {RegExp} UN_FORMATTED_TIME - Regular expression pattern for matching un-formatted time string in format "yyyy-mm-dd hh:mm:ss".
 * @property {RegExp} OFFSET_DATE_TIME - Regular expression pattern for matching a date time string that has already had the offset applied: "yyyy-mm-ddthh:mm:ss+h:ss".
 */
export const REG_EX = Object.freeze({
  UN_FORMATTED_TIME: /\d{4}-\d{2}-\d{2}[" "]\d{2}:\d{2}:\d{2}/gi,
  OFFSET_DATE_TIME: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}$/i,
});

export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";

export const APPOINTMENT_DETAILS_TYPES = {
  PAST_APPOINTMENT: "PAST_APPOINTMENT",
  FUTURE_APPOINTMENT: "FUTURE_APPOINTMENT",
  CHECK_IN: "CHECK_IN",
  CHECK_OUT: "CHECK_OUT",
};

/**
 * Values that represent the Status of an Itinerary or status of an Engagement on an Itinerary.
 *
 * @global
 * @type {{CONFIRMED: string, BOOKED: string, SERVICE_COMPLETED: string, CANCELED: string, CHECKED_OUT: string, PENDING: string, OPEN: string, CHECKED_IN: string}}
 * @example
 * {
 *   CHECKED_IN: "Checked-In",
 *   CHECKED_OUT: "Checked-Out",
 *   SERVICE_COMPLETED: "Service Completed",
 *   BOOKED: "Booked",
 *   CONFIRMED: "Confirmed",
 *   CANCELED: "Canceled",
 *   PENDING: "Pending"
 * }
 *
 * @todo this Object is the same as itinerariesStatusList, we can consolidate that object into this one
 * @see itinerariesStatusList
 */
export const APPOINTMENT_STATUS = {
  CHECKED_IN: "Checked-In",
  CHECKED_OUT: "Checked-Out",
  SERVICE_COMPLETED: "Service Completed",
  BOOKED: "Booked",
  CONFIRMED: "Confirmed",
  CANCELED: "Canceled",
  PENDING: "Pending",
};

export const weekDaysKeys = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
export const weekDaysNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const weekOffs = ["Saturday", "Sunday"];
export const STORE_NUMBER_BY_JOB_ROLE = {
  [jobRoleConstants.SRC]: 727368,
};

export const NEW_PET_ID = "NEW_PET";

export const SERVICE_UNAVAILABLE = "Service unavailable";
