import { connect } from "react-redux";

import { getSelectedUser, getInvalidPinError } from "../../core/selectors/authSelectors";
import { getLoggedInUser } from "../../core/selectors/persistentSelectors";
import { createLoadingSelector } from "../../core/selectors/utils";
import {
  authorizeUserByPin,
  clearInvalidPinError,
  clearSelectedUser,
  showAuthModal
} from "../../core/actionCreators/authActionCreators";
import authActionTypes from "../../core/actionTypes/authActionTypes";
import { modalTypes } from "../../core/constants/quickPin";

import EnterPinComponent from "./EnterPinComponent";

const mapStateToProps = state => ({
  selectedUser: getSelectedUser(state),
  loggedInUser: getLoggedInUser(state),
  invalidPinError: getInvalidPinError(state),
  isLoading: createLoadingSelector([authActionTypes.AUTHORIZE_USER_BY_PIN])(state)
});

const mapDispatchToProps = dispatch => ({
  authorizeUserByPin: ({ pin }) => dispatch(authorizeUserByPin({ pin })),
  clearInvalidPinError: () => dispatch(clearInvalidPinError()),
  clearSelectedUser: () => dispatch(clearSelectedUser()),
  showModal: () => dispatch(showAuthModal({ modalType: modalTypes.AUTHORIZATION_REQUIRED }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterPinComponent);
