export default {
  LOAD_ENGAGEMENT: "LOAD_ENGAGEMENT",
  LOAD_ENGAGEMENTS: "LOAD_ENGAGEMENTS",

  CREATE_ENGAGEMENT: "CREATE_ENGAGEMENT",
  CREATE_ENGAGEMENT_REQUEST: "CREATE_ENGAGEMENT_REQUEST",
  CREATE_ENGAGEMENT_SUCCESS: "CREATE_ENGAGEMENT_SUCCESS",
  CREATE_ENGAGEMENT_FAILURE: "CREATE_ENGAGEMENT_FAILURE",

  DELETE_ENGAGEMENT: "DELETE_ENGAGEMENT",
  DELETE_ENGAGEMENT_REQUEST: "DELETE_ENGAGEMENT_REQUEST",
  DELETE_ENGAGEMENT_SUCCESS: "DELETE_ENGAGEMENT_SUCCESS",
  DELETE_ENGAGEMENT_FAILURE: "DELETE_ENGAGEMENT_FAILURE",

  DELETE_ENGAGEMENTS: "DELETE_ENGAGEMENTS",

  UPDATE_ENGAGEMENT_STATUS: "UPDATE_ENGAGEMENT_STATUS",
  UPDATE_ENGAGEMENT_STATUS_REQUEST: "UPDATE_ENGAGEMENT_STATUS_REQUEST",
  UPDATE_ENGAGEMENT_STATUS_SUCCESS: "UPDATE_ENGAGEMENT_STATUS_SUCCESS",
  UPDATE_ENGAGEMENT_STATUS_FAILURE: "UPDATE_ENGAGEMENT_STATUS_FAILURE",

  UNDO_LAST_ENGAGEMENT_STATUS_UPDATE: "UNDO_LAST_ENGAGEMENT_STATUS_UPDATE",
  UNDO_LAST_ENGAGEMENT_STATUS_UPDATE_REQUEST: "UNDO_LAST_ENGAGEMENT_STATUS_UPDATE_REQUEST",
  UNDO_LAST_ENGAGEMENT_STATUS_UPDATE_SUCCESS: "UNDO_LAST_ENGAGEMENT_STATUS_UPDATE_SUCCESS",
  UNDO_LAST_ENGAGEMENT_STATUS_UPDATE_FAILURE: "UNDO_LAST_ENGAGEMENT_STATUS_UPDATE_FAILURE"
};
