import React from "react";
import { connect } from "react-redux";
import { ButtonGroup, Button, TextPassage } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { routePaths } from "@/core/constants/routePaths";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { hideBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { PRE_EXISTING_RES_TEXT } from "./bookingPreExistingReservationModalConstants";
import { selectItineraryIdFromPostError } from "../servicesCart/servicesCartSelectors";
import { hideCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import { selectItineraryIdFromError } from "../hotelItinerary/hotelItinerarySelectors";

const BookingPreExistingReservationModalComponent = ({
  componentId,
  isHidden,
  onViewResClick = () => {},
  onBackToProfileClick = () => {},
}) => {
  if (isHidden) return null;

  return (
    <LayoutBox id={componentId}>
      <LayoutStack space="scale-G1">
        <TextPassage>{PRE_EXISTING_RES_TEXT.CONTENT}</TextPassage>

        <ButtonGroup style={{ justifyContent: "space-between" }}>
          <Button
            text={PRE_EXISTING_RES_TEXT.VIEW_RES}
            variant="primary"
            onClick={onViewResClick}
          />
          <Button text={PRE_EXISTING_RES_TEXT.BACK_TO_PROFILE} onClick={onBackToProfileClick} />
        </ButtonGroup>
      </LayoutStack>
    </LayoutBox>
  );
};

export const HotelBookingPreExistingReservationModal = withRouteProps(
  connect(
    state => {
      return {
        componentId: "HotelBookingPreExistingReservationModal",
        itineraryId: selectItineraryIdFromPostError(state),
      };
    },
    dispatch => ({
      closeModal: () => dispatch(hideBookingModal()),
    }),
    (stateProps, dispatchProps, { router: { navigate, params } }) => {
      const { componentId, itineraryId } = stateProps;
      const { closeModal } = dispatchProps;
      const customerKey = params?.customerKey;

      return {
        componentId,
        onViewResClick: () => {
          closeModal();
          navigate(`${routePaths.CHECK_IN}/${customerKey}/${itineraryId}`);
        },
        onBackToProfileClick: () => {
          closeModal();
          navigate(`${routePaths.PET_PARENT_PROFILE}/${customerKey}`);
        },
      };
    },
  )(BookingPreExistingReservationModalComponent),
);

export const HotelCheckInOutPreExistingReservationModal = withRouteProps(
  connect(
    state => {
      return {
        componentId: "HotelCheckInOutPreExistingReservationModal",
        itineraryId: selectItineraryIdFromError(state),
      };
    },
    dispatch => ({
      closeModal: () => dispatch(hideCheckInOutModal()),
    }),
    (stateProps, dispatchProps, { router: { navigate, params } }) => {
      const { componentId, itineraryId } = stateProps;
      const { closeModal } = dispatchProps;
      const customerKey = params?.customerKey;

      return {
        componentId,
        onViewResClick: () => {
          closeModal();
          navigate(`${routePaths.CHECK_IN}/${customerKey}/${itineraryId}`);
        },
        onBackToProfileClick: () => {
          closeModal();
          navigate(`${routePaths.PET_PARENT_PROFILE}/${customerKey}`);
        },
      };
    },
  )(BookingPreExistingReservationModalComponent),
);
