import { connect } from "react-redux";
import { get } from "lodash/fp";
import { history } from "@/dux/utils/browser/browserHistory";
import RemoveButtonWithLabel from "../common/buttons/RemoveButtonWithLabel";
import { showConfirmationModal } from "../../core/actionCreators/confirmationModalActionCreators";
import { deletePetMedication } from "../medication/actions/createUpdateDeleteMedicationActions";
import getIsHotelWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { isFromPetProfile } from "../../core/utils/validationUtils/isFromValidation";

const mapStateToProps = (state, ownProps) => {
  const { medicationIndex, medicationId, petId } = ownProps;
  return {
    petId,
    isHidden: !isFromPetProfile(get(["location", "pathname"], history)),
    medicationIndex,
    medicationId,
    label: `Medication ${medicationIndex}`,
    canDeleteFromFeatureFlag: !getIsHotelWorkflowFeatureFlagHidden(state),
  };
};

const mapDispatchToProps = dispatch => ({
  deletePetMed: (medicationId, petId) => dispatch(deletePetMedication({ medicationId, petId })),
  showConfirmationModal: args => dispatch(showConfirmationModal(args)),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { medicationId, medicationIndex, canDeleteFromFeatureFlag, petId } = propsFromState;
    if (canDeleteFromFeatureFlag) {
      propsFromDispatch.showConfirmationModal({
        header: `Remove medication ${medicationIndex}`,
        content: "Are you sure want to remove this medication?",
        confirmText: `Remove medication ${medicationIndex}`,
        confirm: () => propsFromDispatch.deletePetMed(medicationId, petId),
        cancelText: "Go back",
      });
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RemoveButtonWithLabel);
