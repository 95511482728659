import React from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ styleObj }) =>
    css`
      ${{ ...styleObj }};
    `};
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

// TODO: Refactor to use only DI components or children?

export default function IconWithLabel(props) {
  const { componentId, styleObj, label, isHidden, src, diComp } = props;
  const Component = diComp;

  if (isHidden) return null;

  return (
    <Wrapper id={componentId} data-testid={componentId} styleObj={styleObj}>
      <Icon src={src} />
      <div>{diComp ? <Component /> : label}</div>
    </Wrapper>
  );
}
