import { connect } from "react-redux";
import indexTab from "../common/tabs/indexTabs/IndexTab";
import { systemName } from "./constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "./selectors/setSystemTypeSelectors";
import { setSystemBookingFlowType } from "./actions/setSystemTypeActions";

const mapStateToProps = state => ({
  isActive: getSystemBookingFlow(state) === systemName.SALON,
  componentID: `setSystemType${systemName.SALON}-indexTab`,
  label: `${systemName.SALON} Details `,
});

const mapDispatchToProps = dispatch => ({
  handleClick: () => dispatch(setSystemBookingFlowType({ systemBookingFlow: systemName.SALON })),
});

// const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
//   ...propsFromState,
//   ...propsFromDispatch,
//   ...ownProps,

//   keyName: ({ }) => propsFromDispatch.functionName({
//     keyName:	propsFromState.value,
//   }),
//   keyName: ({ }) => propsFromDispatch.functionName({
//     keyName:	propsFromState.value,
//   }),
// })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // mergeProps,
)(indexTab);
