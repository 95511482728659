import { connect } from "react-redux";
import {
  getTrainingClassCustomerKey,
  getTrainingClassItineraryId,
} from "@/dux/manageTrainingClassPage/manageTrainingClassPageSelectors";
import {
  getTrainingClassItinerary,
  DELETE_TRAINING_CLASS_ITINERARY,
  GET_TRAINING_CLASS_ITINERARY,
  MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE,
  PUT_TRAINING_CLASS_ITINERARY,
  PATCH_TRAINING_CLASS_ITINERARY,
  CANCEL_TRAINING_CLASS_ITINERARY,
} from "@/dux/trainingClassItineraries/trainingClassItinerariesActions";
import ManageTrainingClassComponent from "./ManageTrainingClassComponent";
import { createLoadingSelector } from "@/core/selectors/utils";
import { POST_RESEND_MEETING_LINK } from "../_components/resendMeetingLink/resendMeetingLinkActions";
import { resetRescheduleClassSessionId } from "./manageTrainingClassPageActions";
import { resetTrainingClassSessions } from "../trainingClassSessions/actions";

const mapStateToProps = state => {
  return {
    title: "Virtual Pet Training - Private Class",
    isLoading: createLoadingSelector([
      GET_TRAINING_CLASS_ITINERARY,
      PUT_TRAINING_CLASS_ITINERARY,
      PATCH_TRAINING_CLASS_ITINERARY,
      MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE,
      DELETE_TRAINING_CLASS_ITINERARY,
      POST_RESEND_MEETING_LINK,
      CANCEL_TRAINING_CLASS_ITINERARY,
    ])(state),
    customerKey: getTrainingClassCustomerKey(state),
    itineraryId: getTrainingClassItineraryId(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchGetTrainingItinerary: ({ customerKey, itineraryId }) => {
      dispatch(getTrainingClassItinerary({ customerKey, itineraryId }));
    },
    resetRescheduleClassSessionId: () => {
      dispatch(resetRescheduleClassSessionId());
    },
    resetTrainingClassSessions: () => {
      dispatch(resetTrainingClassSessions());
    },
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  getTrainingItinerary: () => {
    const { customerKey, itineraryId } = propsFromState;
    const { dispatchGetTrainingItinerary } = propsFromDispatch;

    dispatchGetTrainingItinerary({ customerKey, itineraryId });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ManageTrainingClassComponent);
