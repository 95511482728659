import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Layout, SearchableList, Text, Button } from "@prism/psm-ui-components";

import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { getSelectedPetServiceItem, getSelectedAddOns, getCurrentPetServiceId } from 'core/selectors/cartSelectors';
import { getPetServiceItem } from 'core/selectors/entitiesSelector';
import fuzzyFilter from "core/utils/arrayUtils/fuzzyFilter";
import { selectEnhancedServicesByService, selectEnhancedServicesCartId, isEnhancedServicesHidden, getSelectedEnhancedServiceId } from "./EnhancedServicesListSelectors";
import { setEnhancedServicesAddon } from "./EnhancedServicesListActions";
import { putAddOns } from "core/actionCreators/addOnsActionCreator";
import addOnsActionTypes from "core/actionTypes/addOnsActionTypes";
import { createLoadingSelector } from "core/selectors/utils";
import LoadingWrapper from "web/common/LoadingWrapper";

import { EnhancedServicesListItem } from "./EnhancedServicesListItem"
import { selectPrePaidBundleEnhancedServicePrice, getSelectedAddOnIds } from "../_selectors/addOnsSelectors";

// VIEW COMPONENT ----------------------------------------------------------------------------------
export const EnhancedServicesListComponent = ({
  isHidden,
  componentId,
  title,
  addons,
  petId,
  onSelectionChange,
  selectedEnhancedService,
  itemName,
  selectedEnhancedServiceID,
  isPrePaidBundleEnhancedServiceSelected,
  isLoading,
}) => {
  const [enhancedServicesList, setEnhancedServicesList] = useState([]);

  useEffect(() => {
    setEnhancedServicesList(addons);
  }, [addons])

  useEffect(() => {
    const isEnhacedSelected = enhancedServicesList.find(item => selectedEnhancedServiceID.includes(item.addOnId))
    if (isEnhacedSelected) {
      onSelectionChange(isEnhacedSelected, petId);
    }
  }, [selectedEnhancedServiceID, enhancedServicesList])

  const onSearchChange = (petId, { target: { value } }) => {
    setEnhancedServicesList(fuzzyFilter(addons, 'addOnName', value));
    if (selectedEnhancedService) {
      // when we search we want to deselect anything we had previously selected
      onServiceSelection(null, petId, true);
    }
  };

  const onServiceSelection = (addOn, petId, isChecked) => {
    onSelectionChange(isChecked ? null : addOn, petId);
  }

  return !isHidden &&
  (
    <LoadingWrapper isLoading={isLoading}>
      <Layout.Stack>
        <Layout.Cluster justify="space-between" style={{
          marginTop: '2.5rem',
          marginBottom: '2.5rem',
        }}>
          <Text id={`${componentId}__title`} bold size="text-size-lg">
            {title}
          </Text>
          <Button disabled={Boolean(isPrePaidBundleEnhancedServiceSelected)} style={{ padding: 0, height: 'auto', width: 'auto', fontWeight: 'normal', cursor: Boolean(isPrePaidBundleEnhancedServiceSelected) ? 'not-allowed' : 'pointer' }} onClick={() => onServiceSelection(null, petId)} variant="prism-primary-no-outline" id={`${componentId}__clear`}>
            Clear
          </Button>
        </Layout.Cluster>
        <SearchableList id={componentId} placeholder="Search" onChange={onSearchChange.bind(null, petId)}>
          <Layout.Box id={`${componentId}__list`} style={{ paddingTop: "2em" }}>
            <Layout.Stack space="stack-space-6">
              {enhancedServicesList.map(addon =>
                <EnhancedServicesListItem
                  item={addon}
                  id={addon.addOnId}
                  key={addon.addOnId.toString()}
                  currentCheckedId={selectedEnhancedService}
                  onClick={onServiceSelection.bind(null, addon, petId)}
                  itemName={itemName}
                  isDisabled={isLoading || Boolean(isPrePaidBundleEnhancedServiceSelected)}
                />)
              }
            </Layout.Stack>
          </Layout.Box>
        </SearchableList>
      </Layout.Stack >
    </LoadingWrapper>
  );
}

// CONTAINERS --------------------------------------------------------------------------------------
export const BookingEnhancedServicesList = connect(
  (state) => {
    const petId = getSelectedPet(state);
    const petServiceItemId = getSelectedPetServiceItem(state, {petId});
    const petServiceItem = getPetServiceItem(state, {petServiceItemId});
    const selectedAddOns = getSelectedAddOns(state, {petId});
    const enhancedServicesCartId = selectEnhancedServicesCartId(state);
    const petServiceId = getCurrentPetServiceId(state, { petId });
    const addons = selectEnhancedServicesByService(state, { petId, petServiceId });
    const sortedAddons = addons.sort((a, b) => a.addOnSortOrder - b.addOnSortOrder);
    const isPrePaidBundleEnhancedServiceSelected = selectPrePaidBundleEnhancedServicePrice(state, { petId, petServiceId });
    const isLoading = createLoadingSelector([addOnsActionTypes.PUT_ADDONS])(state);

    return {
      isHidden: isEnhancedServicesHidden(state, { petId, sortedAddons }),
      componentId: "BookingEnhancedServicesList",
      title: 'Enhanced Services',
      addons: sortedAddons,
      petId,
      selectedEnhancedService: enhancedServicesCartId ? enhancedServicesCartId : petServiceItem?.enhancedServices?.addOnId,
      petServiceItem,
      selectedAddOns,
      selectedEnhancedServiceID: getSelectedAddOnIds(state, { petId }),
      isPrePaidBundleEnhancedServiceSelected,
      isLoading,
    }
  },

  (dispatch) => ({
    handleChange: (addOn, petId) => {
      dispatch(setEnhancedServicesAddon(addOn, petId));
    },

    handleChangeWithServiceItem: (petServiceItem, selectedAddOns, addOnId, petId) => {
      const { customer, engagement, itinerary, petServiceItemId } = petServiceItem;
      const addons = selectedAddOns.map( ({addOnId}) => ({addOnId}));

      addOnId && addons.push({addOnId});

      dispatch(
        putAddOns({
          customerId: customer,
          itineraryId: itinerary,
          engagementId: engagement,
          petServiceItemId,
          addons,
        }),
      );

    }
  }),

  (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,

    onSelectionChange: (addOn, petId) => {
      const { handleChange, handleChangeWithServiceItem } = propsFromDispatch;
      const { petServiceItem, selectedAddOns } = propsFromState;

      handleChange(addOn, petId);

      petServiceItem &&
        handleChangeWithServiceItem(petServiceItem, selectedAddOns, addOn?.addOnId, petId);
    },
  }),
)(EnhancedServicesListComponent);
