import { createSelector } from "reselect";
import { get, some } from "lodash/fp";
import getAddressesByCustomer from "./getAddressesByCustomer";

// Example of use:  getCustomerHasPrimaryAddress(state, { customerKey });
const getCustomerHasPrimaryAddress = createSelector([getAddressesByCustomer], addresses =>
  some(get("isPrimary"), addresses)
);

export default getCustomerHasPrimaryAddress;
