import React from "react";
import styled from "styled-components";
import { medicationTypes } from "web/newMedication/newMedicationConstants";
import { fontSizes } from "web/common/styles/responsive/fonts";
import FoodNameContainer from "../foodName/FoodNameContainer";
import AddNewFoodButtonContainer from "../addFood/buttons/AddNewFoodButtonContainer";
import CancelNewFoodButtonContainer from "../addFood/buttons/CancelNewFoodButtonContainer";
import OtherFoodNameContainer from "../otherFoodName/OtherFoodNameContainer";
import FoodTypeAmountContainer from "../foodTypeAmount/FoodTypeAmountContainer";
import FoodInstructionsContainer from "../foodInstructions/FoodInstructionsContainer";
import FoodTimeOfDayContainer from "../foodTimeOfDay/FoodTimeOfDayContainer";
import { isNewFood } from "../food/foodUtils";
import FoodTypeContainer from "../foodType/FoodTypeContainer";
import withClickOutside from "./hoc/withClickOutside";

const FoodInstructionsWrapper = styled.div`
  font-size: ${fontSizes.regular};
`;

const Grid = styled.div`
  display: grid;
  grid-row-gap: 1.875rem;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 3.75rem;
  font-size: ${fontSizes.regular};
  padding: 1rem 0;
`;

const NewFoodButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 10px;
`;

class PetProfileFeedingFormComponent extends React.Component {
  handleClickOutside = this.handleClickOutside.bind(this);

  handleClickOutside(e) {
    const { onSubmit } = this.props;
    onSubmit && onSubmit();
  }

  render() {
    const {
      isHidden,
      foodId,
      petId,
      foodIndex,
      foodIds,
      clickOutsideRef,
      locationType,
    } = this.props;
    const newFoodIndex = isNewFood(foodId) ? foodIds.length + 1 : foodIndex;
    return !isHidden ? (
      <div ref={clickOutsideRef} handleClickOutside={this.handleClickOutside}>
        <Grid>
          <FoodTypeContainer foodIndex={newFoodIndex} foodId={foodId} petId={petId} />
          <FoodNameContainer foodIndex={newFoodIndex} foodId={foodId} petId={petId} />
          <OtherFoodNameContainer foodId={foodId} petId={petId} />
          <FoodTypeAmountContainer foodId={foodId} foodIndex={newFoodIndex} petId={petId} />
          <FoodTimeOfDayContainer foodId={foodId} foodIndex={newFoodIndex} petId={petId} />
        </Grid>
        <FoodInstructionsWrapper>
          <FoodInstructionsContainer foodId={foodId} foodIndex={newFoodIndex} petId={petId} />
        </FoodInstructionsWrapper>
        <NewFoodButtonsContainer>
          <AddNewFoodButtonContainer foodId={foodId} petId={petId} />
          <CancelNewFoodButtonContainer foodId={foodId} petId={petId} />
        </NewFoodButtonsContainer>
      </div>
    ) : null;
  }
}

export default withClickOutside(PetProfileFeedingFormComponent);
