import { put, takeEvery, takeLatest, call, all, fork, select, delay } from "redux-saga/effects";
import { isEmpty } from "lodash/fp";

import { history } from "dux/utils/browser/browserHistory";
import { selectUserStatus } from "dux/userStatus/userStatusSelectors";
import authActionTypes from "../actionTypes/authActionTypes";
import authActionCreators from "../actionCreators/authActionCreators";
import {
  buildOAuthURL,
  buildDefaultOptions,
  setCookie,
  setOktaTokenStorageCookie,
  deleteCookie,
  deleteLocalStorageItem,
} from "../utils/authHelper";
import { calculateRefreshTokenDuration } from "dux/userStatus/userStatusUtils";
import { getApiError } from "../selectors/utils";
import { authErrorMessages } from "../constants/authConstants";
import { getSelectedUser } from "../selectors/authSelectors";
import { routePaths } from "../constants/routePaths";
import { getCurrentLocation } from "../selectors/navigationSelectors";
import { setSearchComponentName } from "../actionCreators/searchSalonActionCreator";
import {
  getStoreNumber,
  getAccessToken,
  getIdToken,
  getLoggedInUser,
  getClientSessionToken,
  getSessionToken,
  getJobRole,
  getSourceId,
  getIsUserPinRequired,
} from "../selectors/persistentSelectors";
import {
  authorizeUserByPin,
  fetchAuthorizedUsers,
  setOrUpdateUserPin,
  logoutUser,
  logoutStore,
  refreshSessionToken,
} from "../services/systemAssociateAuth/authEndPoints";
import { authorizeUser } from "core/services/systemAssociateAuth/authorizeTokensEndpoints";
import { logoutFromOkta } from "../services/oktaAuth/oktaAuthEndpoints";
import mapJobRoleToSearchComponentName from "../utils/jobRolsUtils/jobRoleToSearchComponentName";
import { jobRoleConstants } from "../constants/jobRoleConstants";
import retrieveTestJobRoles from "./jobRoles/testJobRolesSaga";
import { getSessionTimeRemaining } from "core/selectors/authSelectors";

import { setStoreNumber } from "../actionCreators/ui/web/generalActionCreators";
import {
  setSystemBookingFlowType,
  setSystemType,
} from "../../web/setSystemType/actions/setSystemTypeActions";

/* eslint-disable */
function* onAuthorizeUser({ accessToken, idToken, pathname: pathFromOkta, isSettingPin }) {
  try {
    // make a request action
    yield put(authActionCreators.authorizeUserRequest());

    // Place accessToken into redux state
    yield put(authActionCreators.setAccessToken({ accessToken }));

    // Place idToken into redux state
    yield put(authActionCreators.setIdToken({ idToken }));

    const storeNumber = yield select(getStoreNumber);

    // call the /tokens/authorize
    const { data: sessionData } = yield call(authorizeUser, { storeNumber, accessToken });

    // place session data into redux state and Refresh Session Token
    yield put(authActionCreators.setUserSession(sessionData));

    // hanlde job role
    const jobRole = yield select(getJobRole);

    yield put(
      setSearchComponentName({ searchComponentName: mapJobRoleToSearchComponentName(jobRole) }),
    );

    yield put(authActionCreators.setAuthenticatedWithQuickPin());
    yield put(authActionCreators.authorizeUserSuccess());

    yield call(setOktaTokenStorageCookie, { accessToken, idToken });
    yield call(retrieveTestJobRoles, { clientSessionToken: sessionData.clientSessionToken });

    // Prevent authorize user redirect until set pin API call is complete.
    // Since set user by pin requires a call to authorize first, we want set user by pin
    // to handle its own redirect so that we don't go to the dashboard too early without authorizing
    // the quick pin user first.
    if (!isSettingPin) {
      yield handleRedirect(pathFromOkta);
    }
  } catch (error) {
    yield call(handleAuthorizationError, error);
  }
}

function* handleAuthorizationError(error) {
  const { status } = error.response;
  switch (status) {
    // Something is wrong with the use profile (they are not set up in salesforce, etc.)
    case 401:
      yield call(history.push, routePaths.LOGIN);
      yield put(authActionCreators.authorizeUserFailure({ error }));

      break;
    // the associate has not yet set a pin code and will be redirected to set a pin.
    case 403:
      yield put(authActionCreators.setRedirectToSetPin());
      yield call(history.push, routePaths.SET_PIN);
      yield put(authActionCreators.authorizeUserSuccess());
      break;
    default:
      yield put(authActionCreators.authorizeUserFailure({ error }));
  }
  yield put(authActionCreators.clearSelectedUser());
}

function* onAuthorizeUserByPin({ pin }) {
  try {
    yield put(authActionCreators.authorizeUserByPinRequest());
    const [selectedUser, loggedInUser, savedLocation] = yield all([
      yield select(getSelectedUser),
      yield select(getLoggedInUser),
      yield select(getCurrentLocation),
    ]);
    const storeNumber = yield select(getSourceId);
    const { data: sessionData } = yield call(authorizeUserByPin, {
      storeNumber,
      Username: !isEmpty(selectedUser) ? selectedUser.username : loggedInUser.username,
      pin,
    });
    yield put(authActionCreators.setUserSession(sessionData));
    const jobRole = yield select(getJobRole);
    yield put(
      setSearchComponentName({ searchComponentName: mapJobRoleToSearchComponentName(jobRole) }),
    );
    yield put(authActionCreators.setAuthenticatedWithQuickPin());
    yield put(authActionCreators.clearRedirectToSetPin());
    yield put(authActionCreators.authorizeUserByPinSuccess());

    yield handleRedirect(savedLocation);
  } catch (error) {
    const { status } = error.response;

    if (status === 401) {
      const apiError = getApiError(error);

      if (apiError.includes(authErrorMessages.PIN_INVALID)) {
        // User entered incorrect pin code
        yield put(
          authActionCreators.setInvalidPinError({
            invalidPinError: authErrorMessages.PIN_INVALID_USER_FRIENDLY,
          }),
        );
        yield put(authActionCreators.authorizeUserByPinSuccess());
      } else {
        // User is not authenticated with Okta
        yield put(authActionCreators.authorizeUserByPinSuccess());
        yield call(history.push, routePaths.LOGIN);
      }
    } else if (status === 403) {
      //User has not yet set a pin
      yield put(authActionCreators.authorizeUserByPinSuccess());
      yield put(authActionCreators.setRedirectToSetPin());
      yield call(history.push, routePaths.SET_PIN);
    } else {
      // All other cases handled by UI validation
      yield put(authActionCreators.authorizeUserByPinFailure({ error }));
    }
  }
}

// Handles Redirect when the user is authenticated into the system successfully
function* handleRedirect(savedLocation) {
  const jobRole = yield select(getJobRole);
  const routeToSRC = jobRole === jobRoleConstants.SRC;

  if (routeToSRC) {
    yield call(history.push, routePaths.SRC_DASHBOARD);
  } else {
    const redirectRoute = savedLocation ? savedLocation : routePaths.DASHBOARD;
    yield call(history.push, redirectRoute);
  }
}

function* onSetOrUpdateUserPin({ pin }) {
  try {
    yield put(authActionCreators.setOrUpdateUserPinRequest());
    const accessToken = yield select(getAccessToken);
    const idToken = yield select(getIdToken);
    yield call(setOrUpdateUserPin, { accessToken, pin });
    yield put(authActionCreators.setOrUpdateUserPinSuccess());
    yield call(onAuthorizeUser, { accessToken, idToken, isSettingPin: true });
    yield call(onAuthorizeUserByPin, { pin });
  } catch (error) {
    const apiError = getApiError(error);

    if (apiError.includes(authErrorMessages.SAME_PIN_ERROR)) {
      yield put(authActionCreators.setOrUpdateUserPinSuccess());
      yield put(
        authActionCreators.setInvalidPinError({
          invalidPinError: authErrorMessages.SAME_PIN_ERROR,
        }),
      );
    } else {
      yield call(history.push, routePaths.LOGIN);
      yield put(authActionCreators.setOrUpdateUserPinFailure({ error }));
    }
  }
}

function* onFetchAuthorizedUsers() {
  try {
    yield put(authActionCreators.fetchAuthorizedUsersRequest());
    const [storeNumber, clientSessionToken] = yield all([
      yield select(getStoreNumber),
      yield select(getClientSessionToken),
    ]);
    const response = yield call(fetchAuthorizedUsers, { storeNumber, clientSessionToken });
    const users = response.data;
    yield put(authActionCreators.setAuthenticatedUsers({ users }));
    yield put(authActionCreators.fetchAuthorizedUsersSuccess());
  } catch (error) {
    yield put(authActionCreators.fetchAuthorizedUsersFailure({ error }));
  }
}

function* onLogoutUser({ browserSessionOnly }) {
  try {
    yield put(authActionCreators.logoutUserRequest());
    if (!browserSessionOnly) {
      const [storeNumber, { username }, clientSessionToken] = yield all([
        yield select(getStoreNumber),
        yield select(getLoggedInUser),
        yield select(getClientSessionToken),
      ]);
      yield call(logoutUser, { storeNumber, username, clientSessionToken });
    }
    yield call(history.push, routePaths.LOGIN);
    yield call(logoutIfUserIsAuthenticated);
    yield call(clearQuickPinSession);
  } catch (error) {
    yield put(authActionCreators.logoutUserFailure({ error }));
  } finally {
    yield call(logoutIfUserIsAuthenticated);
  }
}

function* onLogoutStore() {
  try {
    yield put(authActionCreators.logoutStoreRequest());
    const [storeNumber, sessionToken] = yield all([
      yield select(getStoreNumber),
      yield select(getSessionToken),
    ]);
    yield call(logoutStore, { storeNumber, sessionToken });
    yield call(history.push, routePaths.LOGIN);
    yield call(logoutIfUserIsAuthenticated);
    yield call(clearQuickPinSession);
  } catch (error) {
    yield put(authActionCreators.logoutStoreFailure({ error }));
  } finally {
    yield call(logoutIfUserIsAuthenticated);
  }
}

function* onRefreshSessionToken({ duration }) {
  try {
    yield put(authActionCreators.refreshSessionTokenRequest());
    const sessionToken = yield select(getSessionToken);
    const sessionDurationSec = (duration / 1000).toFixed(0); // converts milliseconds => seconds
    const response = yield call(refreshSessionToken, {
      sessionToken,
      duration: sessionDurationSec,
    });
    yield put(
      authActionCreators.setSessionToken({ sessionToken: response.data.sessionToken, duration }),
    );
    yield put(authActionCreators.refreshSessionTokenSuccess());
  } catch (error) {
    yield put(authActionCreators.refreshSessionTokenFailure({ error }));
  }
}

export function* onRefreshSession(action) {
  if (action.type === authActionTypes.CANCEL_REFRESH) return;
  const timeoutByRole = yield call(getTimeoutByRole);
  const timeout = action.duration || timeoutByRole;
  yield put(authActionCreators.setSessionTimeRemaining({ milliseconds: timeout }));
  while (true) {
    const delayTime = timeout - window.env.USER_STATUS_TIMEOUT; // subtract the user status timeout to account for api timing and remove any timing crossover with the status
    yield delay(delayTime);
    const { status, time } = yield select(selectUserStatus);
    const sessionDuration = yield select(getSessionTimeRemaining);

    const newSessionDuration = calculateRefreshTokenDuration(status, window.env, {
      lastInteraction: time,
      currentTime: Date.now(),
      sessionDuration,
    });

    newSessionDuration > 0
      ? yield put(authActionCreators.refreshSessionToken(newSessionDuration))
      : yield put(authActionCreators.sessionTimeout());
  }
}

export function* onSessionTimeout() {
  const isPinRequired = yield select(getIsUserPinRequired);
  yield delay(window.env.USER_STATUS_TIMEOUT); // delay a tad more to give the user their full session
  if (!isPinRequired) {
    yield call(onLogoutUser, { browserSessionOnly: true });
  } else {
    yield put(authActionCreators.clearAuthenticatedWithQuickPin());
    yield put(authActionCreators.clearSessionToken());
    yield call(history.push, routePaths.SELECT_USER);
  }
}

// We need to make sure the user is still logged out of Okta even
// if a call to logout via the PetSmart Auth API fails.  This prevents
// the user from getting "stuck" in an authorized state.
function* logoutIfUserIsAuthenticated() {
  const accessToken = yield select(getAccessToken);
  if (accessToken) {
    try {
      yield call(logoutFromOkta);
    } catch (error) {
      // If Okta auth logout fails, manually clear token storage
      yield call(clearQuickPinSession);
      yield call(history.push, routePaths.LOGIN);
    }
    yield call(clearQuickPinSession);
    yield call(history.push, routePaths.LOGIN);
  }
  yield call(clearQuickPinSession);
  yield call(history.push, routePaths.ROOT);
}

function* clearQuickPinSession() {
  yield put(authActionCreators.clearAuthenticatedWithQuickPin());
  yield put(authActionCreators.clearUserSession());
  yield put(authActionCreators.clearSelectedUser());
  yield put(authActionCreators.logoutStoreSuccess());
  yield put(authActionCreators.cancelRefresh());
  deleteCookie("okta-oauth-redirect-params");
  deleteCookie("okta-oauth-nonce");
  deleteCookie("okta-oauth-state");
  deleteLocalStorageItem("okta-token-storage");
}

function* getTimeoutByRole() {
  const isPinRequired = yield select(getIsUserPinRequired);
  if (!isPinRequired) {
    return window.env.SRC_SESSION_TOKEN_TIMEOUT;
  }
  return window.env.SESSION_TOKEN_TIMEOUT;
}

function* onLoginWithOkta({ route, storeNumber, systemType }) {
  try {
    yield put(setStoreNumber({ storeNumber }));
    yield put(setSystemType({ systemType }));
    yield put(setSystemBookingFlowType({ systemBookingFlow: systemType }));

    yield put(authActionCreators.setSecureRedirectRoute({ route }));
    const opts = buildDefaultOptions();
    const url = buildOAuthURL(opts);

    const params = JSON.stringify({
      responseType: opts.responseType,
      state: opts.state,
      nonce: opts.nonce,
      scopes: opts.scopes,
      urls: opts.urls,
    });

    setCookie("okta-oauth-redirect-params", params);
    setCookie("okta-oauth-nonce", opts.nonce);
    setCookie("okta-oauth-state", opts.state);

    yield call(redirectToOkta, url);
  } catch (error) {
    yield call(history.push, routePaths.LOGIN);
  }
}

function* redirectToOkta(url) {
  yield window.location.assign(url);
}

/**
 * Redux Saga Watchers
 */

function* watchAuthorizeUser() {
  yield takeEvery(authActionTypes.AUTHORIZE_USER, onAuthorizeUser);
}

function* watchAuthorizeUserByPin() {
  yield takeEvery(authActionTypes.AUTHORIZE_USER_BY_PIN, onAuthorizeUserByPin);
}

function* watchFetchAuthorizedUsers() {
  yield takeEvery(authActionTypes.FETCH_AUTHORIZED_USERS, onFetchAuthorizedUsers);
}

function* watchSetOrUpdateUserPin() {
  yield takeEvery(authActionTypes.SET_OR_UPDATE_USER_PIN, onSetOrUpdateUserPin);
}

function* watchLogoutUser() {
  yield takeEvery(authActionTypes.LOGOUT_USER, onLogoutUser);
}

function* watchLogoutStore() {
  yield takeEvery(authActionTypes.LOGOUT_STORE, onLogoutStore);
}

function* watchRefreshSessionToken() {
  yield takeEvery(authActionTypes.REFRESH_SESSION_TOKEN, onRefreshSessionToken);
}

function* watchRefreshSession() {
  yield takeLatest(
    [
      authActionTypes.SET_USER_SESSION,
      authActionTypes.SET_SESSION_TOKEN,
      authActionTypes.CANCEL_REFRESH,
    ],
    onRefreshSession,
  );
}

function* watchSessionTimeout() {
  yield takeEvery(authActionTypes.SESSION_TIMEOUT, onSessionTimeout);
}

function* watchLoginWithOkta() {
  yield takeLatest(authActionTypes.LOGIN_WITH_OKTA, onLoginWithOkta);
}

export default function* authSaga() {
  yield all([
    fork(watchAuthorizeUser),
    fork(watchAuthorizeUserByPin),
    fork(watchFetchAuthorizedUsers),
    fork(watchSetOrUpdateUserPin),
    fork(watchLogoutUser),
    fork(watchRefreshSessionToken),
    fork(watchSessionTimeout),
    fork(watchRefreshSession),
    fork(watchLogoutStore),
    fork(watchLoginWithOkta),
  ]);
}
