import moment from "moment";

/**
 * Finds the range of occurrences of a given match pattern in a given input string.
 *
 * @memberOf Utils.StringManipulation
 * @param {object} params - The options object.
 * @param {string} params.input - The input string to search.
 * @param {RegExp} params.matchPattern - The regular expression pattern to match.
 *
 * @returns {Array} - An array of objects representing the range of occurrences.
 *
 * @example
 * const indices = getRangeOfOccurrences({ input: "Scheduled End changed from 2023-09-25 22:30:00 to 2023-09-25 23:30:00, REG_EX.UN_FORMATTED_TIME });
 * // returns [{ start: 27, end: 46 },{ start: 50, end: 69 }]
 */
export const getRangeOfOccurrences = ({ input, matchPattern }) => {
  let result;
  const indices = [];

  // eslint-disable-next-line no-cond-assign
  while ((result = matchPattern.exec(input))) {
    const range = {
      start: result.index,
      end: matchPattern.lastIndex,
    };
    indices.push(range);
  }

  return indices;
};

/**
 * Returns a list of occurrences of substrings within the given input string based on the provided ranges.
 *
 * @memberOf Utils.StringManipulation
 * @param {object} params - The parameters for retrieving occurrences.
 * @param {string} params.input - The input string to search within.
 * @param {Array} params.listOfRanges - The list of range objects specifying the start and end indices of substrings to extract.
 * @returns {Array} - The list of extracted substrings.
 *
 * @example
 * const Occurrences = getListOfOccurrences = ({ input: "Scheduled End changed from 2023-09-25 22:30:00 to 2023-09-25 23:30:00", listOfRanges: [{ start: 27, end: 46 },{ start: 50, end: 69 }] })
 * // returns ["2023-09-25 22:30:00", "2023-09-25 23:30:00"];
 */
export const getListOfOccurrences = ({ input, listOfRanges }) => {
  return listOfRanges?.map(range => {
    return input?.substring(range.start, range.end);
  });
};

/**
 * Replaces all occurrences of a specified pattern in a string with replacement patterns.
 *
 * @memberOf Utils.StringManipulation
 * @param {object} params - The parameters for updating the occurrences.
 * @param {string} params.input - The input string.
 * @param {string[]} params.patternToBeReplaced - An array of strings/patterns to be replaced.
 * @param {string[]} params.replacementPattern - An array of replacement strings/patterns.
 * @returns {string} - The updated string with all occurrences replaced.
 *
 * @example
 * const input = "Scheduled End changed from 2023-09-25 20:30:00 to 2023-09-25 21:30:00";
 * const patternToBeReplaced = ["2023-09-25 20:30:00", "2023-09-25 21:30:00"];
 * const replacementPattern = ["September 25, 2023 8:30 PM", "September 25, 2023 9:30 PM"];
 * updateAllOccurrences({ input, patternToBeReplaced, replacementPattern })
 * // returns "Scheduled End changed from September 25, 2023 8:30 PM to September 25, 2023 9:30 PM"
 */
export const updateAllOccurrences = ({ input, patternToBeReplaced, replacementPattern }) => {
  return patternToBeReplaced.reduce(
    (accumulator, currentValue, i) => accumulator.replace(currentValue, replacementPattern[i]),
    input,
  );
};

/**
 * Replaces all occurrences of a match pattern in a string with formatted time.
 *
 * @param {string} input - The input string.
 * @param {string | RegExp} matchPattern - The pattern to find and replace in the input string.
 * @param {string} timeFormat - The (moment) format to format the time value.
 * @returns {string} - The modified string with replaced patterns.
 *
 * @example
 * const input = "Scheduled End changed from 2023-09-25 20:30:00 to 2023-09-25 21:30:00";
 * description: replaceAllRawFormattedTime(input, REG_EX.UN_FORMATTED_TIME, "LLL"),
 * // returns "Scheduled End changed from September 25, 2023 8:30 PM to September 25, 2023 9:30 PM"
 */
export const replaceAllRawFormattedTime = (input, matchPattern, timeFormat) => {
  const indices = getRangeOfOccurrences({ input, matchPattern });
  const patternToBeReplaced = getListOfOccurrences({ input, listOfRanges: indices });
  const replacementPattern = patternToBeReplaced.map(item => moment(item).format(timeFormat));

  return updateAllOccurrences({ input, patternToBeReplaced, replacementPattern });
};
