/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const createUpdateDeleteMedicationActionTypes = {
  CREATE_PET_MEDICATION: "CREATE_PET_MEDICATION",
  CREATE_PET_MEDICATION_REQUEST: "CREATE_PET_MEDICATION_REQUEST",
  CREATE_PET_MEDICATION_SUCCESS: "CREATE_PET_MEDICATION_SUCCESS",
  CREATE_PET_MEDICATION_FAILURE: "CREATE_PET_MEDICATION_FAILURE",

  UPDATE_PET_MEDICATION: "UPDATE_PET_MEDICATION",
  UPDATE_PET_MEDICATION_REQUEST: "UPDATE_PET_MEDICATION_REQUEST",
  UPDATE_PET_MEDICATION_SUCCESS: "UPDATE_PET_MEDICATION_SUCCESS",
  UPDATE_PET_MEDICATION_FAILURE: "UPDATE_PET_MEDICATION_FAILURE",

  DELETE_PET_MEDICATION: "DELETE_PET_MEDICATION",
  DELETE_PET_MEDICATION_REQUEST: "DELETE_PET_MEDICATION_REQUEST",
  DELETE_PET_MEDICATION_SUCCESS: "DELETE_PET_MEDICATION_SUCCESS",
  DELETE_PET_MEDICATION_FAILURE: "DELETE_PET_MEDICATION_FAILURE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const createPetMedication = ({ medication, petId, isFromBooking = false }) => ({
  type: createUpdateDeleteMedicationActionTypes.CREATE_PET_MEDICATION,
  medication,
  petId,
  isFromBooking,
});

export const createPetMedicationRequest = () => ({
  type: createUpdateDeleteMedicationActionTypes.CREATE_PET_MEDICATION_REQUEST,
});

export const createPetMedicationSuccess = () => ({
  type: createUpdateDeleteMedicationActionTypes.CREATE_PET_MEDICATION_SUCCESS,
});

export const createPetMedicationFailure = ({ error }) => ({
  type: createUpdateDeleteMedicationActionTypes.CREATE_PET_MEDICATION_FAILURE,
  error,
});

export const updatePetMedication = ({
  medication,
  petId,
  isMedicationDetailsShowing,
  isFromBooking = false,
}) => ({
  type: createUpdateDeleteMedicationActionTypes.UPDATE_PET_MEDICATION,
  medication,
  petId,
  isMedicationDetailsShowing,
  isFromBooking,
});

export const updatePetMedicationRequest = () => ({
  type: createUpdateDeleteMedicationActionTypes.UPDATE_PET_MEDICATION_REQUEST,
});

export const updatePetMedicationSuccess = () => ({
  type: createUpdateDeleteMedicationActionTypes.UPDATE_PET_MEDICATION_SUCCESS,
});

export const updatePetMedicationFailure = ({ error }) => ({
  type: createUpdateDeleteMedicationActionTypes.UPDATE_PET_MEDICATION_FAILURE,
  error,
});

export const deletePetMedication = ({ medicationId, petId }) => ({
  type: createUpdateDeleteMedicationActionTypes.DELETE_PET_MEDICATION,
  medicationId,
  petId,
});

export const deletePetMedicationRequest = () => ({
  type: createUpdateDeleteMedicationActionTypes.DELETE_PET_MEDICATION_REQUEST,
});

export const deletePetMedicationSuccess = () => ({
  type: createUpdateDeleteMedicationActionTypes.DELETE_PET_MEDICATION_SUCCESS,
});

export const deletePetMedicationFailure = ({ error }) => ({
  type: createUpdateDeleteMedicationActionTypes.DELETE_PET_MEDICATION_FAILURE,
  error,
});
