import axios from "axios";

/**
 * Axios Create configuration for the Okta authentication api.
 *
 * @summary
 * @memberOf APIs.Auth
 * @function
 * @name  oktaAuthAPI
 * @type {axios.AxiosInstance}
 *
 * @example
 * return oktaAuthAPI.post(url, params)
 * return oktaAuthAPI.get(url, params)
 * return oktaAuthAPI.put(url, params)
 */
export const oktaAuthAPI = axios.create({
  baseURL: `${window.env.OKTA_ISSUER}`,
  withCredentials: true,
});
