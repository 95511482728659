import { connect } from "react-redux";
import { getPetMedications } from "../medication/medicationsSelector";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "../setSystemType/selectors/setSystemTypeSelectors";
import BookingMedicationComponent from "../medication/BookingMedicationComponent";
import { medicationTypes } from "../newMedication/newMedicationConstants";
import { getIsPetCardShowing } from "dux/petCartCard/selectors/petCartCardSelectors";
import { selectMedicationsList } from "@/dux/medications/medicationsSelectors";
import { getMedicationsList } from "@/dux/medications/medicationActions";
import isEmpty from "lodash/isEmpty";

const mapStateToProps = (state, ownProps) => {
  const { petId } = ownProps;
  const medicationList = selectMedicationsList(state);
  const systemFlow = getSystemBookingFlow(state);
  return {
    petId,
    isHidden: systemFlow !== systemName.HOTEL || !getIsPetCardShowing(state, { petId }),
    medications: getPetMedications(state, { petId }),
    medicationList,
    hasPadding: "0 1rem",
    locationType: medicationTypes.CART,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMedicationsList: () => dispatch(getMedicationsList()),
});

const mergeProps = (propsFromState, propsFromDispatch) => {
  const { petId, isHidden, medications, hasPadding, locationType, medicationList } = propsFromState;
  const { fetchMedicationsList } = propsFromDispatch;

  return {
    petId,
    isHidden,
    medications,
    hasPadding,
    locationType,
    loadMedicationsList: () => {
      if (isHidden) return false;
      if (isEmpty(medicationList)) fetchMedicationsList();
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BookingMedicationComponent);
