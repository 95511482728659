import moment, { Moment } from 'moment';
import { range } from './math';
import { getPreviousMonthDayNumberToStartOn } from './getPreviousMonthDayNumberToStartOn';

export function getDayNumbersToDisplayForPreviousMonth(selectedDate: Moment) {
  const startOfCurrentMonth = Number(moment(selectedDate).startOf('month').format('d'));
  const previousMonthDayNumberToStartOn = getPreviousMonthDayNumberToStartOn(selectedDate);

  const previousMonthRange = range(previousMonthDayNumberToStartOn, startOfCurrentMonth);

  const dayNumbersToDisplayForPreviousMonth = previousMonthRange.map((dayNumber: number) => {
    const previousMonthStart = moment(selectedDate).subtract(1, 'month').startOf('month');
    return {
      dayNumber,
      date: previousMonthStart.add(dayNumber - 1, 'days').format('YYYY-MM-DD'),
      isDisabled: true,
    };
  });

  return dayNumbersToDisplayForPreviousMonth;
}
