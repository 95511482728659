/**
 * Timezone constants - Schedule training
 * @memberOf Constants.training
 * @returns {Object}
 * Map long names to the standard abbreviations moment timezone uses, this way we can output
 * the long form name when we use format(zz) with moment timezone.
 * see https://momentjs.com/timezone/docs/#/using-timezones/formatting/
 **/
export const abbrs = {
  EST: "Eastern Time (EST/EDT)",
  EDT: "Eastern Time (EST/EDT)",
  CST: "Central Time (CST/CDT)",
  CDT: "Central Time (CST/CDT)",
  MDT: "Mountain Time (MST/MDT)",
  MST: "Mountain Time (MST/MDT)",
  PST: "Pacific Time (PST/PDT)",
  PDT: "Pacific Time (PST/PDT)",
  HST: "Hawaiian Time (HST)",
  AKST: "Alaskan Time (AKST/AKDT)",
  AKDT: "Alaskan Time (AKST/AKDT)",
  AST: "Puerto Rico Time (AST)",
  NST: "Newfoundland Time (NST/NDT)",
  NDT: "Newfoundland Time (NST/NDT)",
};

/**
 * Timezone Identifiers constants - Schedule training
 * @memberOf Constants.training
 * @returns {Object}
 **/
export const timeZoneIdentifiers = {
  [abbrs.EST]: "America/New_York",
  [abbrs.CST]: "America/Chicago",
  [abbrs.MST]: "America/Denver",
  [abbrs.PST]: "America/Los_Angeles",
  [abbrs.AKST]: "America/Anchorage",
  [abbrs.AST]: "America/Puerto_Rico",
  [abbrs.NST]: "America/St_Johns",
  [abbrs.HST]: "Pacific/Honolulu",
  "Phoenix (MST)": "America/Phoenix",
};
