/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const showHideRequiresWorkflowFeatureFlagActionTypes = {
  SHOW_REQUIRE_WORKFLOW_FEATURE_FLAG: "SHOW_REQUIRE_WORKFLOW_FEATURE_FLAG",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const showHideRequireWorkflowFeatureFlag = ({ featureFlagId, isHidden }) => ({
  type: showHideRequiresWorkflowFeatureFlagActionTypes.SHOW_REQUIRE_WORKFLOW_FEATURE_FLAG,
  featureFlagId,
  isHidden,
});
