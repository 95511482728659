import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getFirstTimeCustomerInfo } from "dux/bgm/ftco/ftcoSelectors";
import { isBGMEligibilityHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import { isFromQuickQuote } from "core/utils/validationUtils/isFromValidation";
import IconWithLabel from "dux/_components/icon/IconWithLabel";
import PreCheckedInIcon from "assets/icons/pre-checked-in-appointment.svg";
import EligiblePromotionsBGMTextContainer from "dux/eligiblePromotions/EligiblePromotionsBGMTextContainer";

const mapStateToProps = (state, { router }) => {
  const customerKey = getCurrentCustomerKey(state);
  const { firstName } = getFirstTimeCustomerInfo(state, { customerKey });
  return {
    firstName,
    componentId: "eligiblePromotionsBGMContainer__IconWithLabel",
    isHidden: isBGMEligibilityHidden(state) || isFromQuickQuote(router.location.pathname),
    src: PreCheckedInIcon,
    diComp: EligiblePromotionsBGMTextContainer,
    styleObj: {
      padding: "0.5em",
      justifyContent: "center",
    },
  };
};

export default withRouteProps(connect(mapStateToProps)(IconWithLabel));
