import React from "react";
import { connect } from "react-redux";
import { Button } from "@prism/psm-ui-components";
import {
  getIsTrainingClassCompleted,
  getTrainingClassSessionDetails,
} from "../trainingClassSessions/selectors";
import { getIsSRCAgent, getStoreNumber } from "@/core/selectors/persistentSelectors";

const LaunchVirtualClassButton = ({ meetingLink, ...buttonProps }) => {
  const handleOnClick = () => {
    window.open(meetingLink);
  };
  return <Button {...buttonProps} onClick={handleOnClick} />;
};
export default connect(state => {
  const storeNumber = getStoreNumber(state);
  const isTrainingClassCompleted = getIsTrainingClassCompleted(state, { storeNumber });
  const { meetingLink } = getTrainingClassSessionDetails(state, { storeNumber }) || {};
  return {
    variant: "prism-primary",
    size: "xs",
    title: "Virtual Pet Training - Private Class",
    children: "Launch Virtual Class",
    disabled: isTrainingClassCompleted || getIsSRCAgent(state),
    style: { pointerEvents: isTrainingClassCompleted ? "none" : "pointer" },
    meetingLink,
  };
})(LaunchVirtualClassButton);
