import React from 'react';
import './styles.css';
import { pluck } from '../utils';
import { GLOBAL_TOKENS } from '../scaleToken';

// Sets the interface for the Box layout custom properties.
interface GridProps<C extends React.ElementType> {
  /** Set the minimum column width */
  minimum?: keyof typeof GLOBAL_TOKENS['SCALE'];

  /** Set the space/gap between grid items */
  space?: keyof typeof GLOBAL_TOKENS['SCALE'];

}

// Include types that are not part of GridProps, such as children,
type Props <C extends React.ElementType> = React.PropsWithChildren<GridProps<C>> & React.ComponentPropsWithoutRef<C>;

export const LayoutGrid = <C extends React.ElementType>({
  minimum = 'scale-G1',
  space = 'scale-G1',
  children,
  style,
  ...props

}: Props<C>): React.ReactElement => {
  return (
    <div
      className="pplt-layout__grid"
      style={{
        '--setGridMinimum': pluck(GLOBAL_TOKENS.SCALE, minimum),
        '--setGridGap': pluck(GLOBAL_TOKENS.SCALE, space),
        ...style,
      }}

      {...props}
    >
      {children}
    </div>
  );
};
