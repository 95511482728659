import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";
import {
  GET_LIST_OF_NEAR_BY_STORES,
  GET_LIST_OF_NEAR_BY_STORES_SUCCESS,
  getListOfNearByStores,
  setCachedStoreNumber,
} from "dux/listOfNearByStores/listOfNearByStoresActions";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { selectStoreLatAndLong } from "dux/storeInfoGoogleMap/storeInfoGoogleMapSelectors";
import {
  selectIsStoreNumberCached,
  selectNearByStores,
} from "dux/listOfNearByStores/listOfNearByStoresSelectors";
import { Heading, Icon } from "@petsmart-ui/sparky";
import formatPhoneNumberNANP from "core/utils/stringManipulationUtils/formatPhoneNumber";
import { createLoadingSelector } from "core/selectors/utils";
import LoadingWrapper from "web/common/LoadingWrapper";
import { getStoreData } from "core/selectors/entitiesSelector";
import { Closest_StoreInfoGoogleMap } from "dux/storeInfoGoogleMap/StoreInfoGoogleMap";
import { getStoreSearchResults } from "core/selectors/searchSalonSRCSelector";

export const ListOfNearByStores = props => {
  const {
    componentId,
    isHidden,
    listNearByStores,
    nearByStores,
    storeNumber,
    isLoading,
    currentStoreInfo,
    headingLabel,
    omit1stStore,
  } = props;

  useEffect(() => {
    listNearByStores && listNearByStores();
  }, []);

  const SubListing = ({ iconName, copy, id }) => {
    return (
      <Layout.Box id={id}>
        <Layout.Cluster space="cluster-space-4" justify="flex-start">
          <Icon name={iconName} size="md" />
          {copy}
        </Layout.Cluster>
      </Layout.Box>
    );
  };

  const ServiceNames = ({ iconName, copy, id }) => {
    return (
      <Layout.Box id={id} style={{ maxWidth: "480px" }}>
        <Layout.Cluster justify="flex-start" childOverrides={{ flexWrap: "noWrap" }}>
          <Layout.Box>
            <Icon name={iconName} size="md" />
          </Layout.Box>

          <Layout.Box style={{ paddingLeft: ".5rem" }}>{copy}</Layout.Box>
        </Layout.Cluster>
      </Layout.Box>
    );
  };

  if (isHidden) return null;
  if (isLoading) return <LoadingWrapper isLoading={isLoading} />;

  return (
    <Layout.Box id={componentId}>
      <Layout.Stack space="stack-space-8">
        <Layout.Box>
          <Heading tagName="h2" size="body-lg-bold">
            {headingLabel}
          </Heading>
        </Layout.Box>

        <Layout.Box>
          <Layout.Stack space="stack-space-8">
            {nearByStores.map((store, index) => {
              // In some cases we want to omit the 1st store as it may be the store we are searching on, so we don't need it listed
              if (omit1stStore) {
                // The 1st index will be the currently selected store, we want to omit that from the rendered list
                if (index === 0) {
                  return;
                }
              }

              // render all other stores
              return (
                <Layout.Box
                  key={store.address}
                  borderWidth
                  borderColor="box-border-color-gray-100"
                  padding="box-padding-4"
                >
                  <Layout.Cluster justify="space-between" style={{ overflow: "visible" }}>
                    {/*LIST OF STORES*/}
                    <Layout.Box>
                      <Layout.Stack space="stack-space-8">
                        {/*TITLE*/}
                        <Layout.Box>
                          <Heading tagName="h2" size="headline">
                            {`Store: ${store?.storeNumber}`}
                          </Heading>
                        </Layout.Box>

                        {/*STORE DATA*/}
                        <Layout.Stack space="stack-space-4">
                          {/*PHONE*/}
                          <SubListing
                            id={`${componentId}__Phone`}
                            copy={formatPhoneNumberNANP(store.phoneNumber)}
                            iconName="bell"
                          />

                          {/*ADDRESS*/}
                          <SubListing
                            id={`${componentId}__Address`}
                            copy={store.address}
                            iconName="pin-outline"
                          />

                          {/*DISTANCE*/}
                          <SubListing
                            id={`${componentId}__Distance`}
                            copy={`${store.distance} ${store.unitOfDistance} away ${
                              currentStoreInfo
                                ? `from: ${currentStoreInfo.storeName} ${storeNumber}`
                                : "from search location"
                            }
                                              `}
                            iconName="car"
                          />

                          {/*SERVICE NAMES*/}
                          <Layout.Box style={{ maxWidth: "480px" }}>
                            <ServiceNames
                              id={`${componentId}__AvailableServices`}
                              copy={store.availableServices}
                              iconName="calendar-outline"
                            />
                          </Layout.Box>
                        </Layout.Stack>
                      </Layout.Stack>
                    </Layout.Box>

                    {/*Store Map*/}
                    <Layout.Box>
                      <Closest_StoreInfoGoogleMap lat={store?.latitude} lng={store?.longitude} />
                    </Layout.Box>
                  </Layout.Cluster>
                </Layout.Box>
              );
            })}
          </Layout.Stack>
        </Layout.Box>
      </Layout.Stack>
    </Layout.Box>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
/*
  Populate a list of stores from nearby stores listed at the top of the PRISM app window
 */
export const StoreInfoListOfNearByStores = connect(
  state => {
    const storeNumber = getStoreNumber(state);
    const currentStoreInfo = getStoreData(state, { storeNumber });
    const isLoading = createLoadingSelector([
      GET_LIST_OF_NEAR_BY_STORES,
      GET_LIST_OF_NEAR_BY_STORES_SUCCESS,
    ])(state);

    // Lat and Long are based on the currently selected store.
    const { lat, lng } = selectStoreLatAndLong(state, { storeNumber });

    const nearByStores = selectNearByStores(state);

    const isStoreNumberCached = selectIsStoreNumberCached(state);

    return {
      componentId: "StoreInfoListOfNearByStores",
      headingLabel: "Closest store(s) within a 75 mile radius",
      isHidden: false,
      nearByStores,
      storeNumber,
      isLoading,
      currentStoreInfo,

      // for mergeProps
      lat,
      lng,
      isStoreNumberCached,
      omit1stStore: true,
    };
  },

  dispatch => {
    return {
      dispatchGetListOfNearByStores: ({ lat, lng, isStoreNumberCached, storeNumber }) => {
        // Only make an api call if we do not have a cachedStoreNumber, if we do not make the api
        // call and set a cachedStoreNumber
        if (!isStoreNumberCached) {
          // Lat and Long are based on the currently selected store.
          dispatch(getListOfNearByStores({ latitude: lat, longitude: lng }));

          dispatch(setCachedStoreNumber({ cacheStoreNumber: storeNumber }));
        }
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      isHidden,
      lat,
      lng,
      isStoreNumberCached,
      nearByStores,
      storeNumber,
      isLoading,
      currentStoreInfo,
      headingLabel,
      omit1stStore,
    } = mapProps;
    const { dispatchGetListOfNearByStores } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,
      nearByStores,
      storeNumber,
      isLoading,
      currentStoreInfo,
      headingLabel,
      omit1stStore,

      // actions to pass to view
      listNearByStores: () =>
        dispatchGetListOfNearByStores({ lat, lng, isStoreNumberCached, storeNumber }),
    };
  },
)(ListOfNearByStores);

/*
  Populate a list of stores from search results by location such as zip code, address, city state etc.
 */
export const ListOfNearByLocation = connect(state => {
  const nearByStores = getStoreSearchResults(state);

  return {
    componentId: "ListOfNearByLocation",
    headingLabel: "Closest store(s) from search location",
    isHidden: false,
    nearByStores,
    isLoading: false,
  };
})(ListOfNearByStores);
