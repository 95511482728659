/*
 *
 * @export groupBy
 * @param {Array[Object]} arr - Array of objects to group
 * @param {String} property - Property to group array by
 * @returns {Object} groupedItems - An object containing a key value pair of string identifier and array of values
 * @example groupBy([ ...arrayValuesHere ], "petServiceId");
 */
// {
// '10000005': [
//   {
//     purchaseDatetime: '2021-05-25T22:36:04.000Z',
//     petServiceId: 10000005,
//     availableUnits: 0,
//     reservedUnits: 0
//   },
//   {
//     purchaseDatetime: '2021-04-23T22:36:04.000Z',
//     petServiceId: 10000005,
//     availableUnits: 0,
//     reservedUnits: 8
//   },
//   {
//     purchaseDatetime: '2021-03-05T22:36:04.000Z',
//     petServiceId: 10000005,
//     availableUnits: 0,
//     reservedUnits: 0
//   }
// ],
// }

export default function groupBy(arr, property) {
  return arr.reduce((acc, current) => {
    const value = acc[current[property]] || [];
    acc[current[property]] = value.concat(current);
    return acc;
  }, {});
}
