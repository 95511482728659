import { connect } from "react-redux";
import moment from "moment";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import GenericTwoColModalContent from "dux/_components/genericLayoutComponents/GenericTwoColModalContent";

import DDCRoomCapacityModalArrivalsColContainer from "dux/ddcRoomCapacityModal/DDCRoomCapacityModalArrivalsColContainer";
import DDCRoomCapacityModalDeparturesColContainer from "dux/ddcRoomCapacityModal/DDCRoomCapacityModalDeparturesColContainer";

const mapStateToProps = state => ({
  isHidden: false,
  componentID: "TwoColumnModalContent__DDCRoomCapacity",
  title: `Boarded Day Camp Guest Scheduled for ${moment(getSelectedDate(state)).format(
    "dddd, MMMM D, YYYY ",
  )} `,
  compName: "DDC",
  diComp: {
    ColOne: {
      title: "Booked",
      DDC: DDCRoomCapacityModalArrivalsColContainer,
    },
    ColTwo: {
      title: "Checked In",
      DDC: DDCRoomCapacityModalDeparturesColContainer,
    },
  },
});

export default connect(mapStateToProps)(GenericTwoColModalContent);
