import associateActionTypes from "../actionTypes/associateActionTypes";

/**
 * Action Creator to begin the process of loading associates into the redux store
 *
 * Redux Path: entities.associates
 *
 * @example loadAssociates()
 */
export const loadAssociates = ({ isLimited = null }) => ({
  type: associateActionTypes.LOAD_ASSOCIATES,
  isLimited,
});

export const loadAssociatesRequest = () => ({
  type: associateActionTypes.LOAD_ASSOCIATES_REQUEST,
});

export const loadAssociatesSuccess = ({ associates }) => ({
  type: associateActionTypes.LOAD_ASSOCIATES_SUCCESS,
  payload: associates,
});

export const loadAssociatesFailure = ({ error }) => ({
  type: associateActionTypes.LOAD_ASSOCIATES_FAILURE,
  error,
});

/**
 * Action Creator to begin the process of updating an associate into the redux store
 *
 * Redux Path: entities.associates
 *
 * @example updateAssociate()
 */
export const updateAssociate = ({ associateId, customerKey, data }) => ({
  type: associateActionTypes.UPDATE_ASSOCIATE,
  associateId,
  data,
});

export const updateAssociateRequest = () => ({
  type: associateActionTypes.UPDATE_ASSOCIATE_REQUEST,
});

export const updateAssociateSuccess = ({ associate }) => ({
  type: associateActionTypes.UPDATE_ASSOCIATE_SUCCESS,
  payload: associate,
});

export const updateAssociateFailure = ({ error }) => ({
  type: associateActionTypes.UPDATE_ASSOCIATE_FAILURE,
  error,
});

export const patchAssociate = ({ associateId, data, onComplete }) => ({
  type: associateActionTypes.PATCH_ASSOCIATE,
  associateId,
  data,
  onComplete,
});

export const patchAssociateRequest = () => ({
  type: associateActionTypes.PATCH_ASSOCIATE_REQUEST,
});

export const patchAssociateSuccess = ({ associate }) => ({
  type: associateActionTypes.PATCH_ASSOCIATE_SUCCESS,
  payload: associate,
});

export const patchAssociateFailure = ({ error }) => ({
  type: associateActionTypes.PATCH_ASSOCIATE_FAILURE,
  error,
});

/**
 * Action Creator to begin the process of getting employee groups
 *
 *
 * @example getEmployeeGroups()
 */
export const loadEmployeeGroups = () => ({
  type: associateActionTypes.LOAD_EMPLOYEE_GROUPS,
});

export const loadEmployeeGroupsRequest = () => ({
  type: associateActionTypes.LOAD_EMPLOYEE_GROUPS_REQUEST,
});

export const loadEmployeeGroupsSuccess = (payload) => ({
  type: associateActionTypes.LOAD_EMPLOYEE_GROUPS_SUCCESS,
  payload,
});

export const loadEmployeeGroupsFailure = ({ error }) => ({
  type: associateActionTypes.LOAD_EMPLOYEE_GROUPS_FAILURE,
  error,
});

/**
 * Action Creator to used to clear 1 to all associates from the redux store. Pass the
 * optional param as an array of numbers to clear from 1 to many to all associates.
 * You can also pass no param and use in conjunction with the deleteAll in the entities
 * reducer to remove all associates from the entities state.
 *
 * Redux Path: entities.associates
 *
 * @example clearAssociates([13, 14, 15]);
 *
 * @param {Array.<Number>} associateIds
 */
export const clearAssociates = (associateIds) => ({
  type: associateActionTypes.CLEAR_ASSOCIATES,
  payload: associateIds,
});

export const setTabFilter = ({ tabFilter }) => ({
  type: associateActionTypes.SET_TAB_FILTER,
  tabFilter,
});

export const clearTabFilter = () => ({
  type: associateActionTypes.CLEAR_TAB_FILTER,
});

export const showAssociateModal = (modalType) => ({
  type: associateActionTypes.SHOW_ASSOCIATES_MODAL,
  modalType,
});

export const hideAssociateModal = () => ({
  type: associateActionTypes.HIDE_ASSOCIATES_MODAL,
});

export default {
  loadAssociates,
  loadAssociatesRequest,
  loadAssociatesSuccess,
  loadAssociatesFailure,
  updateAssociate,
  updateAssociateRequest,
  updateAssociateSuccess,
  updateAssociateFailure,
  patchAssociate,
  patchAssociateRequest,
  patchAssociateSuccess,
  patchAssociateFailure,
  loadEmployeeGroups,
  loadEmployeeGroupsRequest,
  loadEmployeeGroupsSuccess,
  loadEmployeeGroupsFailure,
  setTabFilter,
  clearTabFilter,
  clearAssociates,
  showAssociateModal,
  hideAssociateModal,
};
