import SearchFieldHeaderContainer from "dux/searchField/SearchFieldHeaderContainer";
import {
  AssociateSearchFieldMain,
  BookingSearchFieldMainHotel,
  BookingSearchFieldMainSalon,
  BookingSearchFieldMainTraining,
  PrimarySearchFieldMain,
  SRCSearchFieldMain,
} from "dux/searchFieldMain/SearchFieldMain";
import { NamedExoticComponent } from "react";
import SearchFieldSrcContainer from "dux/searchField/SearchFieldSrcContainer";
import SearchFieldAssociateContainer from "dux/searchField/SearchFieldAssociateContainer";
import {
  SearchFieldBookHotelAppointment,
  SearchFieldBookSalonAppointment, SearchFieldBookTrainingAppointment,
} from "dux/searchField/SearchFieldBookAppointmentContainer";
import { SearchStoreNumTrainingContent } from "dux/searchStoreNumAndBooking/SearchStoreNumAndBookingContent";

/**
 * Several families of search field products.
 * Most of the product families return the same result currently. The purpose of this factory is to
 * provide "future-proof" way of adding use case diversity based on variations (salon words different from hotel) within each family.
 */

/*
  SRC Search Products
  * Note:
  *   All SRC search products currently use the same search field, any of them can currently
  *   be used interchangeably, if Hotel, Training etc. need something different update the
  *   getSearchField method in the correct product to return the specific search field needed.
 */
export interface ISRCSearch {
  getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent;
}

export class SRCSearchSalon implements ISRCSearch {
  public getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent {
    // If the feature flag is of, how original search else show the new SRCSearchFieldMain
    return isFeatureFlagHidden ? SearchFieldSrcContainer : SRCSearchFieldMain;
  }
}

export class SRCSearchHotel implements ISRCSearch {
  public getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent {
    // If the feature flag is of, how original search else show the new SRCSearchFieldMain
    return isFeatureFlagHidden ? SearchFieldSrcContainer : SRCSearchFieldMain;
  }
}

export class SRCSearchTraining implements ISRCSearch {
  public getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent {
    // If the feature flag is of, how original search else show the new SRCSearchFieldMain
    return isFeatureFlagHidden ? SearchFieldSrcContainer : SRCSearchFieldMain;
  }
}

/*
  Customer Search Products
  * Note:
  *   All customer search products currently use the same search field, any of them can currently
  *   be used interchangeably, if Hotel, Training etc. need something different update the
  *   getSearchField method in the correct product to return the specific search field needed.
 */
export interface ICustomerSearch {
  getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent;
}

export class CustomerSearchSalon implements ICustomerSearch {
  public getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent {
    // If the feature flag is of, how original search else show the new PrimarySearchFieldMain
    return isFeatureFlagHidden ? SearchFieldHeaderContainer : PrimarySearchFieldMain;
  }
}

export class CustomerSearchHotel implements ICustomerSearch {
  public getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent {
    // If the feature flag is of, how original search else show the new PrimarySearchFieldMain
    return isFeatureFlagHidden ? SearchFieldHeaderContainer : PrimarySearchFieldMain;
  }
}

export class CustomerSearchTraining implements ICustomerSearch {
  public getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent {
    // If the feature flag is of, how original search else show the new PrimarySearchFieldMain
    return isFeatureFlagHidden ? SearchFieldHeaderContainer : PrimarySearchFieldMain;
  }
}

/*
  Booking Search Products
 */
export interface IBookingSearch {
  getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent;
}

export class BookingSearchSalon implements IBookingSearch {
  public getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent {
    // If the feature flag is of, how original search else show the new BookingSearchFieldMainSalon
    return isFeatureFlagHidden ? SearchFieldBookSalonAppointment : BookingSearchFieldMainSalon;
  }
}

export class BookingSearchHotel implements IBookingSearch {
  public getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent {
    // If the feature flag is of, how original search else show the new BookingSearchFieldMainHotel
    return isFeatureFlagHidden ? SearchFieldBookHotelAppointment : BookingSearchFieldMainHotel;
  }
}

export class BookingSearchTraining implements IBookingSearch {

  public getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent {
    // If the feature flag is of, how original search else show the new BookingSearchFieldMainTraining
    return isFeatureFlagHidden ? SearchFieldBookTrainingAppointment : BookingSearchFieldMainTraining;
  }
}

/*
  *Associate Search Products
    * Note:
  *   All Associate search products currently use the same search field, any of them can currently
  *   be used interchangeably, if Hotel, Training etc. need something different update the
  *   getSearchField method in the correct product to return the specific search field component needed.
*/
export interface IAssociateSearch {
  getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent;
}

export class AssociateSearchSalon implements IAssociateSearch {

  public getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent {
    return isFeatureFlagHidden ? SearchFieldAssociateContainer : AssociateSearchFieldMain;
  }
}

export class AssociateSearchHotel implements IAssociateSearch {

  public getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent {
    return isFeatureFlagHidden ? SearchFieldAssociateContainer : AssociateSearchFieldMain;
  }
}

export class AssociateSearchTraining implements IAssociateSearch {

  public getSearchField(isFeatureFlagHidden: boolean): NamedExoticComponent {
    return isFeatureFlagHidden ? SearchFieldAssociateContainer : AssociateSearchFieldMain;
  }
}
