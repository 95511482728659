import { filter } from "lodash/fp";
import moment from "moment";

/**
 * Filters a Collection of appointments by `checkInDateTime` given a specific date
 * @param appointments - Collection of appointment objects.
 * @param selectedDate - date to filter by
 * @returns Array of filtered appointments
 */
export default function filterAppointmentBySelectedDate(appointments, selectedDate) {
  return filter(({ checkInDateTime }) => {
    const appointmentTime = moment.utc(checkInDateTime).format("YYYY-MM-DD");
    return moment(appointmentTime).isSame(selectedDate, "day");
  }, appointments);
}
