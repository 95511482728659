export default {
  VOID_TRANSACTION: "VOID_TRANSACTION",
  VOID_TRANSACTION_REQUEST: "VOID_TRANSACTION_REQUEST",
  VOID_TRANSACTION_SUCCESS: "VOID_TRANSACTION_SUCCESS",
  VOID_TRANSACTION_FAILURE: "VOID_TRANSACTION_FAILURE",

  LOAD_VOID_TRANSACTIONS_REASONS: "LOAD_VOID_TRANSACTIONS_REASONS",
  LOAD_VOID_TRANSACTIONS_REASONS_REQUEST: "LOAD_VOID_TRANSACTIONS_REASONS_REQUEST",
  LOAD_VOID_TRANSACTIONS_REASONS_SUCCESS: "LOAD_VOID_TRANSACTIONS_REASONS_SUCCESS",
  LOAD_VOID_TRANSACTIONS_REASONS_FAILURE: "LOAD_VOID_TRANSACTIONS_REASONS_FAILURE"
};
