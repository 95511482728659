import { put, takeEvery, call, all } from "redux-saga/effects";
import { noop } from "lodash/fp";
import {
  PUT_UPLOAD_TRAINER_PHOTO,
  putUploadTrainerPhotoRequest,
  putUploadTrainerPhotoSuccess,
  putUploadTrainerPhotoFailure,
  LOAD_TRAINER_BOOKED_APPOINTMENTS,
  loadTrainerBookedAppointmentsRequest,
  loadTrainerBookedAppointmentsSuccess,
  loadTrainerBookedAppointmentsFailure,
} from "./associateProfilePageActions";
import { uploadTrainerPhoto } from "@/core/services/associateWeb/photoEndpoints";
import { convertDataUrlToPNG } from "@/dux/utils/imageUtils";
import { loadAssociatesSuccess } from "@/core/actionCreators/associateActionCreator";
import { fetchBookedTrainingAppointments } from "@/core/services/associateWeb/bookedAppointmentsTrainingEndpoints";
import { normalizeBookedTrainingAppointments } from "../trainingClassSessions/_utils";
import { createBatchActions } from "@/core/utils/batchUtils";
import {
  setTrainingSessionAppointmentDates,
  setTrainingAttendees,
  getTrainingClassSessionsSuccess,
} from "../trainingClassSessions/actions";
import { loadPetsSuccess } from "@/core/actionCreators/petsActionCreators";
import { loadCustomersSuccess } from "@/core/actionCreators/customersActionCreators";
import { loadStoreServices } from "@/core/actionCreators/servicesSelectionActionCreator";

export function* onPutUploadTrainerPhoto({ storeNumber, associateId, file, onComplete = noop }) {
  try {
    yield put(putUploadTrainerPhotoRequest());
    const parsedFile = yield call(convertDataUrlToPNG, file, "trainerPhoto.png");

    const formData = new FormData();
    formData.append("file", parsedFile);

    const { data } = yield call(uploadTrainerPhoto, {
      storeNumber,
      associateId,
      file: formData,
    });

    yield put(putUploadTrainerPhotoSuccess(data));
    yield put(
      loadAssociatesSuccess({
        associates: { [associateId]: { associatePhotoUrl: data.PhotoUrl } },
      }),
    );
    onComplete(data);
  } catch (error) {
    yield put(putUploadTrainerPhotoFailure(error));
  }
}

function* watchPutUploadTrainerPhoto() {
  yield takeEvery(PUT_UPLOAD_TRAINER_PHOTO, onPutUploadTrainerPhoto);
}

export function* onloadTrainerBookedAppointments({ associateId, fromDate }) {
  try {
    yield put(loadTrainerBookedAppointmentsRequest());

    const { data } = yield call(fetchBookedTrainingAppointments, {
      associateId,
      fromDate,
    });

    const {
      trainingSessionAppointmentDates,
      trainingAttendees,
      pets,
      customers,
      petServices,
      trainingClassSessions,
    } = normalizeBookedTrainingAppointments(data, associateId);

    // We are excluding normalizing out the associate details since this API doesn't
    // return associates (since we already retrieve associate details from the GET
    // Associates call on the trainer profile page).
    yield put(
      createBatchActions(
        setTrainingSessionAppointmentDates(trainingSessionAppointmentDates),
        setTrainingAttendees(trainingAttendees),
        loadPetsSuccess({ pets }),
        loadCustomersSuccess({ customers }),
        loadStoreServices({ petServices }),
        getTrainingClassSessionsSuccess(trainingClassSessions),
      ),
    );
    yield put(loadTrainerBookedAppointmentsSuccess());
  } catch (error) {
    yield put(loadTrainerBookedAppointmentsFailure(error));
  }
}

function* watchloadTrainerBookedAppointments() {
  yield takeEvery(LOAD_TRAINER_BOOKED_APPOINTMENTS, onloadTrainerBookedAppointments);
}

export default function* associateProfileSaga() {
  yield all([watchPutUploadTrainerPhoto(), watchloadTrainerBookedAppointments()]);
}
