import React from "react";
import { Layout } from "@prism/psm-ui-components";
import CustomerCardContainer from "@/web/searchSalonSRC/customerCard/customerCardContainer";

export default function CustomerCardListComponent({ isHidden, searchResults }) {
  if (isHidden) {
    return null;
  }
  return (
    <>
      {searchResults.map((customer, index) => (
        <Layout.Box
          key={customer}
          padding="box-padding-4"
          borderWidth="box-border-width"
          borderColor="box-border-color-gray-100"
          style={{ marginBottom: "1em" }}
        >
          <CustomerCardContainer
            className="resultList__customerCardContainer"
            customerKey={customer}
            petID={123}
            idx={index}
          />
        </Layout.Box>
      ))}
    </>
  );
}
