//@ts-check
import { SET_HOTEL_MANAGE_ROOM_DETAILS_SUSPENSION_HISTORY_ID } from "./hotelManageRoomDetailsSuspensionHistoryActions";

const initialState = { isHidden: true, suspensionId: null };

/**
 *
 * @param {object} state
 * @param {boolean} state.isHidden
 * @param {object} action
 * @param {string} action.type
 * @param {string} action.suspensionId
 * @returns hotel manage room suspension history reducer
 */
const hotelManageRoomDetailsSuspensionHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTEL_MANAGE_ROOM_DETAILS_SUSPENSION_HISTORY_ID:
      return {
        ...state,
        isHidden: !state.isHidden,
        suspensionId: action.suspensionId,
      };
    default:
      return state;
  }
};

export default hotelManageRoomDetailsSuspensionHistoryReducer;
