import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

// Components
import DropdownPanelComponent from "@/dux/_components/dropdownPanel/DropdownPanelComponent";
import AlertPanel from "@/dux/_components/alertPanel/AlertPanel";
import HealthQuestionsListComponent from "@/dux/_components/digitalServiceCard/HealthQuestionsListComponent";

// Theme
import { color } from "@/web/common/styles/theme";

// Selectors
import {
  getPreCheckInQuestions,
  getPreCheckInQuestionsAnsweredYes,
  getIsPreCheckedInByEngagementId,
  getPreCheckInQuestionsAnsweredNo,
  getAreAllPreCheckInQuestionsAnsweredNo,
  getHasPreCheckInQuestionsAnsweredYes,
  getHasPreCheckInQuestionsAnsweredNo,
} from "@/dux/digitalServiceCard/digitalServiceCardSelectors";

// Styles
const Header = styled.div`
  font-weight: bold;
  margin-bottom: 1em;
`;

const QuestionContainer = styled.div`
  border: 1px solid ${color.veryLightGrey};
  width: 500px;
`;

const Divider = styled.div`
  width: 90%;
  height: 1px;
  position: relative;
  margin-left: 5%;
  margin-right: 5%;
  border-bottom: 1px solid ${color.veryLightGrey};
`;

// HealthQuestionsComponent
const HealthQuestionsComponent = (props) => {
  const {
    allPreCheckInQuestionsAnswered,
    allQuestionsAnsweredNo,
    componentID,
    hasPreCheckInQuestionsAnsweredYes,
    headerText,
    isHidden,
    preCheckInQuestionsAnsweredYes,
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  if (isHidden) {
    return null;
  }

  return (
    <div id={componentID}>
      <Header>{headerText}</Header>
      <QuestionContainer>
        {hasPreCheckInQuestionsAnsweredYes && (
          <>
            <AlertPanel text="The pet parent answered YES to the following questions:" />
            <HealthQuestionsListComponent
              questions={preCheckInQuestionsAnsweredYes}
              answerToDisplayBold="Yes"
            />
            <Divider />
          </>
        )}
        {allQuestionsAnsweredNo && (
          <AlertPanel text="The pet parent answered NO to all questions:" />
        )}
        <DropdownPanelComponent
          onClick={() => setIsMenuOpen((value) => !value)}
          text="View All Questions"
          isMenuOpen={isMenuOpen}
        />
        {isMenuOpen && (
          <HealthQuestionsListComponent
            questions={allPreCheckInQuestionsAnswered}
            answerToDisplayBold="Yes"
            displayAlertIcon
          />
        )}
      </QuestionContainer>
    </div>
  );
};

// HealthQuestionsContainer
export const HealthQuestionsContainer = connect((state, { petId, engagementId }) => {
  const isPreCheckedIn = getIsPreCheckedInByEngagementId(state, { engagementId });

  return {
    componentID: "health-questionnaire-answers",
    headerText: "Health Questionnaire Answers",
    isHidden: !isPreCheckedIn,
    allPreCheckInQuestionsAnswered: getPreCheckInQuestions(state, { petId }),
    preCheckInQuestionsAnsweredYes: getPreCheckInQuestionsAnsweredYes(state, { petId }),
    preCheckInQuestionsAnsweredNo: getPreCheckInQuestionsAnsweredNo(state, { petId }),
    hasPreCheckInQuestionsAnsweredYes: getHasPreCheckInQuestionsAnsweredYes(state, { petId }),
    hasPreCheckInQuestionsAnsweredNo: getHasPreCheckInQuestionsAnsweredNo(state, { petId }),
    allQuestionsAnsweredNo: getAreAllPreCheckInQuestionsAnsweredNo(state, { petId }),
  };
})(HealthQuestionsComponent);
