import React from "react";
import { Layout, Button, Text } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import { createLoadingSelector } from "@/core/selectors/utils";
import associateActionTypes from "@/core/actionTypes/associateActionTypes";
import { routePaths } from "@/core/constants/routePaths";
import { parseAssociateCopy } from "./associateProfileUtils";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const AssociateProfileSubHeading = ({ associateProfileCopy, associateScheduleCopy, onClick }) => (
  <Layout.Cluster justify="space-between">
    <Text size="lg" bold>
      {associateProfileCopy}
    </Text>
    <Button size="lg" variant="link" onClick={onClick}>
      {associateScheduleCopy}
    </Button>
  </Layout.Cluster>
);

export default withRouteProps(
  connect(
    (state, { router }) => {
      const associateId = router?.params?.associateId;
      const isLoading = createLoadingSelector([
        associateActionTypes.LOAD_EMPLOYEE_GROUPS,
        associateActionTypes.LOAD_ASSOCIATES,
        associateActionTypes.UPDATE_ASSOCIATE,
      ])(state);

      return {
        componentId: "",
        associateId,
        associateProfileCopy: parseAssociateCopy({
          state,
          associateId,
          isLoading,
          text: "Profile",
        }),
        associateScheduleCopy: parseAssociateCopy({
          state,
          associateId,
          isLoading,
          text: "Schedule",
        }),
      };
    },
    null,
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      onClick: () => {
        const { associateId } = stateProps;
        const { router: { navigate } } = ownProps;
        navigate(`${routePaths.ASSOCIATE_SCHEDULING}/${associateId}`);
      },
    }),
  )(AssociateProfileSubHeading),
);
