import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";
import { GET_AVAILABLE_BUNDLES_BY_PET } from "../availableBundlesByPetActions";
import {
  getCurrentPetServiceItem,
  getCurrentCustomer,
} from "../../../../core/selectors/checkInOutSelector";
import { getFilteredActiveBundlesByPet } from "../availableBundlesByPetSelectors";
import { createLoadingSelector } from "../../../../core/selectors/utils";
import { getPetByPetServiceItemId } from "../../../../core/selectors/entitiesSelector";
import { AvailableBundlesListComponent } from "../../../_components/bgm/availableBundlesByPet/AvailableBundlesListComponent";
import getIsBGMWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getFTCOWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = state => {
  const petServiceItemId = getCurrentPetServiceItem(state);
  const customerId = getCurrentCustomer(state);

  const pet = petServiceItemId && getPetByPetServiceItemId(state, { petServiceItemId });
  const bundles = pet && getFilteredActiveBundlesByPet(state, { petId: pet.petId });
  const isLoading = createLoadingSelector([GET_AVAILABLE_BUNDLES_BY_PET])(state);
  const isFeatureFlagHidden =
    getIsBGMWorkflowFeatureFlagHidden(state) && getFTCOWorkflowFeatureFlagHidden(state);

  return {
    isLoading,
    isHidden: isFeatureFlagHidden || isEmpty(bundles) || getSystemBookingFlow(state) == systemName.HOTEL,
    bundles,
    componentId: "bundle_transactions",
    idEnum: { customerId, petServiceItemId, petId: pet && pet.petId },
  };
};

export default connect(mapStateToProps)(AvailableBundlesListComponent);
