import { createSelector } from "reselect";

export const getHotelCapacityModalState = state => state.modals.hotelRoomCapacityModal;

export const getHotelModalIsHidden = createSelector(
  [getHotelCapacityModalState],
  capacityModal => capacityModal.isHidden,
);

export const getHotelDrawerIsOpen = createSelector(
  [getHotelCapacityModalState],
  capacityModal => capacityModal.isDrawerOpen,
);
