import React from "react";
import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";
import {
  SearchStoreNumSalonStyledPopover,
  SearchStoreNumHotelStyledPopover,
  SearchStoreNumTrainingStyledPopover,
} from "@/dux/searchStoreNumAndBooking/SearchStoreNumStyledPopover";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";

function BookAppointmentButtonsWithStoreSearchFlyoutComponent({ isHidden, systemBookingFlow }) {
  return (
    <Layout.Box isHidden={isHidden}>
      {
        {
          [systemName.SALON]: <SearchStoreNumSalonStyledPopover />,
          [systemName.HOTEL]: <SearchStoreNumHotelStyledPopover />,
          [systemName.TRAINING]: <SearchStoreNumTrainingStyledPopover />,
        }[systemBookingFlow]
      }
    </Layout.Box>
  );
}

export const BookAppointmentButtonsWithStoreSearchFlyout = connect((state) => ({
  isHidden: getStoreNumber(state),
  systemBookingFlow: getSystemBookingFlow(state),
}))(BookAppointmentButtonsWithStoreSearchFlyoutComponent);
