import { connect } from "react-redux";
import DDCRoomCapacityModalButtonContainer from "dux/ddcRoomCapacityModal/DDCRoomCapacityModalButtonContainer";
import CapacityHeader from "../_components/roomCapacity/CapacityHeader";

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => ({
  isHidden: false,
  instanceName: "DDC",
  componentID: "CapacityHeader__DDC",
  title: "DDC and Play Capacity",
  hasButton: true,
  diComp: { DDC: DDCRoomCapacityModalButtonContainer },
});

export default connect(mapStateToProps)(CapacityHeader);
