import { newFoodActionTypes } from "./actions/newFoodActions";

const initialState = false;

const newFoodReducer = (state = initialState, action) => {
  switch (action.type) {
    case newFoodActionTypes.SHOW_ADD_NEW_FOOD:
      return true;
    case newFoodActionTypes.HIDE_ADD_NEW_FOOD:
      return initialState;
    default:
      return state;
  }
};

export default newFoodReducer;
