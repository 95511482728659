/* * ----------------------------------------------------------------------- * *\
  GET action
\* * ----------------------------------------------------------------------- * */
export const GET_HOTEL_ITINERARY = "GET_HOTEL_ITINERARY";
export const GET_HOTEL_ITINERARY_REQUEST = "GET_HOTEL_ITINERARY_REQUEST";
export const GET_HOTEL_ITINERARY_SUCCESS = "GET_HOTEL_ITINERARY_SUCCESS";
export const GET_HOTEL_ITINERARY_FAILURE = "GET_HOTEL_ITINERARY_FAILURE";
export const CLEAR_HOTEL_ITINERARY = "CLEAR_HOTEL_ITINERARY";
export const SET_HOTEL_ITINERARY_INVOICE = "SET_HOTEL_ITINERARY_INVOICE";
export const SET_HOTEL_ITINERARY_STATUS = "SET_HOTEL_ITINERARY_STATUS";

export const getHotelItinerary = payload => ({
  type: GET_HOTEL_ITINERARY,
  payload,
});

export const getHotelItineraryRequest = payload => ({
  type: GET_HOTEL_ITINERARY_REQUEST,
  payload,
});

export const getHotelItinerarySuccess = payload => ({
  type: GET_HOTEL_ITINERARY_SUCCESS,
  payload,
});

export const getHotelItineraryFailure = error => ({
  type: GET_HOTEL_ITINERARY_FAILURE,
  error,
});

export const clearHotelItinerary = () => ({
  type: CLEAR_HOTEL_ITINERARY,
});

export const setHotelItineraryInvoice = invoice => ({
  type: SET_HOTEL_ITINERARY_INVOICE,
  invoice,
});

export const setHotelItineraryStatus = ({ status }) => ({
  type: SET_HOTEL_ITINERARY_STATUS,
  status,
});

/* * ----------------------------------------------------------------------- * *\
  PUT action
\* * ----------------------------------------------------------------------- * */
export const PUT_HOTEL_ITINERARY = "PUT_HOTEL_ITINERARY";
export const PUT_HOTEL_ITINERARY_REQUEST = "PUT_HOTEL_ITINERARY_REQUEST";
export const PUT_HOTEL_ITINERARY_FAILURE = "PUT_HOTEL_ITINERARY_FAILURE";
export const PUT_HOTEL_ITINERARY_SUCCESS = "PUT_HOTEL_ITINERARY_SUCCESS";

/**
 * Redux Action to make a hotel itinerary full put call.
 *
 * @memberOf Actions.hotel.itinerary
 * @see onPutHotelItinerary
 * @param {object } itineraryObject - full itinerary object
 * @return {{itineraryObject: Object, type: string}}
 *
 * @example
 * // saga
 * yield call(putHotelItinerary, itineraryObject);
 *
 * // mapDispatchToProps
 * dispatch({putHotelItinerary(itineraryObject)),
 *
 */
export const putHotelItinerary = ({ hotelItinerary }) => ({
  type: PUT_HOTEL_ITINERARY,
  hotelItinerary,
});

export const putHotelItineraryRequest = () => ({
  type: PUT_HOTEL_ITINERARY_REQUEST,
});

export const putHotelItineraryFailure = error => ({
  type: PUT_HOTEL_ITINERARY_FAILURE,
  error,
});

export const putHotelItinerarySuccess = ({ payload }) => ({
  type: PUT_HOTEL_ITINERARY_SUCCESS,
  payload,
});
