import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import find from "lodash/fp/find";
import moment from "moment";
import { Formik } from "formik";
import Form from "web/common/Form";
import FormInput from "web/common/FormInput";
import { QUICK_QUOTE_AGE_OPTIONS } from "core/constants/dropdownOptions";
import withSaveOnClickOutside from "web/common/hoc/withSaveOnClickOutside";
import { selectPet } from "core/actionCreators/bookingActionCreators";
import { createPetSuccess } from "core/actionCreators/petsActionCreators";
import { loadCustomerSuccess } from "core/actionCreators/customersActionCreators";
import { getBreedsOptions, getSpeciesOptions } from "core/selectors/enumsSelectors";
import transformObjectByMap from "core/utils/objectUtils/transformObjectByMap";
import filterObjectByProperties from "core/utils/objectUtils/filterObjectByProperties";
import quickQuoteConstants from "core/constants/quickQuoteConstants";
import { Heading } from "@petsmart-ui/sparky";
import { font } from "@/web/common/styles/theme";
import { LayoutBox } from "@/layout/box/Box";
import {
  NAN_FIELD_ERROR_MESSAGE,
  REQUIRED_FIELD_ERROR_MESSAGE,
} from "@/core/constants/validationErrors";
import verifyNumber from "@/core/utils/validationUtils/numberValidation";

const validatePetForm = ({ breedId, speciesId } = {}) => {
  const errors = {};

  // validate breedId
  if (!verifyNumber(breedId)) errors.breedId = `breedId ${NAN_FIELD_ERROR_MESSAGE}`;
  if (!breedId) errors.breedId = REQUIRED_FIELD_ERROR_MESSAGE;

  // validate speciesId
  if (!verifyNumber(speciesId)) errors.speciesId = `speciesId ${NAN_FIELD_ERROR_MESSAGE}`;
  if (!speciesId) errors.speciesId = REQUIRED_FIELD_ERROR_MESSAGE;

  return errors;
};

const QuickQuotePetFormComponent = ({
  componentId,
  breeds,
  species,
  onSubmit,
  show,
  handleFocus,
  formikRef,
  clickOutsideRef,
}) => {
  const showAgeSelect = (values, setFieldValue) => {
    const { speciesId } = values;
    const selectedSpeciesAge = values[speciesId];
    const options = QUICK_QUOTE_AGE_OPTIONS[speciesId];

    // Set a default value, even when FormInput will not let us
    if (!selectedSpeciesAge) setFieldValue(speciesId, "adult");
    return (
      <FormInput
        id={componentId}
        label="Age"
        name={`${speciesId}`}
        modifier="age"
        type="select"
        options={options}
      />
    );
  };

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <Heading
        tagName="h3"
        id={`${componentId}__petTitle`}
        // Override sparky styles to match removed styled component and rest of quick quote page
        style={{ fontFamily: font.mainFamily, fontSize: "1.17em", margin: "revert" }}
      >
        Pet Details
      </Heading>
      <Formik
        id={`${componentId}__formik`}
        initialValues={{ speciesId: "" }}
        onSubmit={onSubmit}
        ref={formikRef}
        validate={validatePetForm}
        enableReinitialize
        render={({ values, setFieldValue }) => (
          <Form
            id={`${componentId}__form`}
            columns={2}
            innerRef={clickOutsideRef}
            onFieldFocus={handleFocus}
            show={show}
          >
            <FormInput
              id={componentId}
              label="*Species"
              name="speciesId"
              type="select"
              modifier="species"
              options={species}
              onChange={() => setFieldValue("breedId", "")}
            />
            <FormInput
              id={componentId}
              label="*Breed"
              name="breedId"
              type="select"
              modifier="breed"
              options={
                values.speciesId ? breeds.filter(breed => breed.speciesId === values.speciesId) : []
              }
            />
            {values.speciesId && showAgeSelect(values, setFieldValue)}
          </Form>
        )}
      />
    </LayoutBox>
  );
};

const petFormValuesMap = {
  birthDate: birthDate => moment(birthDate).toISOString(),
};

export const QuickQuotePetForm = compose(
  withSaveOnClickOutside,
  connect(
    (state, ownProps) => ({
      breeds: getBreedsOptions(state, ownProps),
      species: getSpeciesOptions(state),
      componentId: "quickQuotePetForm",
    }),
    (dispatch, ownProps) => ({
      onSubmit: values => {
        const { fieldsToInclude } = ownProps;
        const { breedId, speciesId } = values;

        // We can infer the age and birthDate from the selected age: "adult", "puppy" or "kitten".
        // Age has default values. Adults are 2 years old, and a puppy or kitten is 4 months old.
        // Birthday is calculated from the click event.
        const findAgeBySpecies = values[speciesId];
        const age = findAgeBySpecies === "adult" ? "2 Yrs 0 M" : "0 Yrs 4 M";
        const birthDate =
          findAgeBySpecies === "adult"
            ? moment().subtract(2, "years")
            : moment().subtract(4, "months");

        const combineValues = {
          speciesId,
          breedId,
          age,
          birthDate: birthDate.format("YYYY-MM-DD"),
        };

        const data = transformObjectByMap(
          fieldsToInclude
            ? filterObjectByProperties(combineValues, fieldsToInclude)
            : combineValues,
          petFormValuesMap,
        );

        const breedName = find({ BreedId: data.breedId }, ownProps.breeds).Description;

        dispatch(
          createPetSuccess({
            pet: {
              petId: quickQuoteConstants.QUOTE_PET,
              ...data,
              breedName,
            },
          }),
        );

        dispatch(
          loadCustomerSuccess({
            customer: {
              customerKey: "QUOTE_CUSTOMER",
              firstName: "Guest",
              lastName: "Customer",
              pets: [quickQuoteConstants.QUOTE_PET],
            },
          }),
        );

        dispatch(selectPet(quickQuoteConstants.QUOTE_PET));
      },
    }),
    null,
    { forwardRef: true },
  ),
)(QuickQuotePetFormComponent);
