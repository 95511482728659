import { LayoutBox } from "@/layout/box/Box";
import React from "react";
import { connect } from "react-redux";
import { getIsSRCAgent } from "@/core/selectors/persistentSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import { Button } from "@petsmart-ui/sparky";
import { isFromCheckOut } from "@/core/utils/validationUtils/isFromValidation";
import { GET_HOTEL_ITINERARY } from "../hotelItinerary/hotelItineraryActions";
import { selectInvoiceButtonLabel } from "../hotelItinerary/hotelItinerarySelectors";
import { history } from "../utils/browser/browserHistory";
import { GET_HOTEL_INVOICE, getHotelInvoice } from "../hotelInvoice/hotelInvoiceActions";

/**
 * React view component for print invoice button
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name PrintInvoiceButton
 * @param {Object} props
 * @param {string} props.componentId
 * @param {Boolean} props.isHidden
 * @param {string} props.label
 * @param {Function} props.onClick
 * @param {Boolean} props.disabled
 * @param {Boolean} props.isLoading
 * @returns {JSX.Element|null}
 * @example const container = connect()(PrintInvoiceButton)
 */
export const PrintInvoiceButton = ({
  componentId,
  isHidden,
  label,
  onClick,
  disabled,
  isLoading,
}) => {
  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <Button
        text={label}
        onClick={onClick}
        disabled={disabled}
        loading={isLoading}
        variant="primary"
      />
    </LayoutBox>
  );
};
/**
 * Redux Connect function for hotel print invoice button
 * @see {@link Views.Hotel.Itinerary.PrintInvoiceButton}
 * @summary Located on the hotel check-out page
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelPrintInvoiceButton
 * @returns {JSX.Element|null}
 * @example <HotelPrintInvoiceButton />
 */
export const HotelPrintInvoiceButton = connect(
  state => {
    const isCheckOutPage = isFromCheckOut(history?.location?.pathname);
    const isSrc = getIsSRCAgent(state);
    const label = selectInvoiceButtonLabel(state);
    const isItineraryLoading = createLoadingSelector([GET_HOTEL_ITINERARY])(state);
    const isLoading = createLoadingSelector([GET_HOTEL_INVOICE])(state);

    return {
      componentId: "HotelPrintInvoiceButton",
      isHidden: !isCheckOutPage || isSrc || !label || isItineraryLoading,
      label,
      disabled: isLoading,
      isLoading,
    };
  },
  dispatch => {
    return {
      onClick: () => dispatch(getHotelInvoice()),
    };
  },
)(PrintInvoiceButton);
