import React from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { Heading } from "@petsmart-ui/sparky";
import { LayoutStack } from "@/layout/stack/Stack";
import { SearchableList } from "@prism/psm-ui-components";

/**
 * React view component for middle column on DDC packages page
 * @memberOf Views.Purchase
 * @param {*} props
 * @returns {JSX.Element}
 */
export const DDCPackagesListColumnComponent = props => {
  const { componentId } = props;

  return (
    <LayoutBox id={componentId} padding="scale-G1">
      <LayoutStack>
        <LayoutBox padding="scale-0">
          <Heading tagName="h2" size="title">
            Purchase DDC Play & Save
          </Heading>
        </LayoutBox>

        <LayoutBox>
          <SearchableList id={componentId} placeholder="Search">
            {/* TODO: replace with DDC Packages searchable list */}
          </SearchableList>
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for middle column on DDC packages page
 * @memberOf Views.Purchase
 * @summary path: /purchase/:customerKey/ddc-packages
 * @function
 * @name DDCPackagesListColumn
 * @example <DDCPackagesListColumn />
 */
export const DDCPackagesListColumn = connect(state => {
  return {
    componentId: "DDCPackagesListColumn",
  };
})(DDCPackagesListColumnComponent);
