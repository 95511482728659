import { bookingAPI } from "core/services/systemServicesBooking/_axiosConfigSystemServicesBooking";

/**
 * For Hotel Room Types API
 * - Get Room Types By Species -
 * @returns {*} AxiosPromise
 */

export function getHotelRoomTypes() {
  return bookingAPI.get(`/hotel/v1/roomTypes`);
}