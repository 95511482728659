import React, { useEffect } from "react";
import { connect } from "react-redux";
import { EditableSection, Button, ButtonGroup, SparkyTextWrapper } from "@petsmart-ui/sparky";
import { Layout } from "@prism/psm-ui-components";
import {
  selectCommonHotelBookingStepData,
  selectHotelBookingPetList,
  selectHotelBookingRooms,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import BookingMedicationContainer from "@/web/bookingAppointmentMedication/BookingMedicationContainer";
import {
  getMedsByPetExcludingNewMedID,
  getMedsExcludingNewMedId,
  selectItineraryDiscontinuedMeds,
  selectPetMedsForBooking,
  selectPetMedsIsMissingDates,
} from "@/web/medication/medicationsSelector";
import { clearMedications, setMedications } from "@/web/medication/actions/medicationsActions";
import normalizeArrayByProperty from "@/core/utils/normalizeUtils/normalizeArray";
import { isEmpty } from "lodash";
import { commonHotelBookingStepActions } from "@/web/features/hotelBookingFlow/hotelBookingFlowUtils";
import { medCountLabel, noMedsLabel } from "./hotelBookingMedsSectionConstants";
import { HotelBookingRoomAndPetTabs } from "../hotelBookingRoomAndPetTabs/HotelBookingRoomAndPetTabs";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { compose } from "redux";
import { selectIsRebookingItinerary } from "../hotelItinerary/hotelItinerarySelectors";
import { getIconType } from "../hotelBookingRoomAndPetTabs/hotelBookingRoomAndPetTabsHelpers";
import { showBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { hotelBookingTypes } from "@/web/hotelAlerts/hotelBookingConstants";

const HotelBookingMedsSectionComponent = ({
  onContinue,
  isHidden,
  componentID,
  canNavigate,
  isActive,
  onClick,
  getRoomTabIconType,
  getPetTabIconType,
  clearMeds,
  pets,
  setPetMeds,
  descriptionText = "",
  showDiscontinuedMedsModal,
}) => {
  useEffect(() => {
    if (pets?.length) setPetMeds();
  }, [pets]);

  useEffect(() => {
    // Clear medications state once we leave the booking page
    // If a reservation was booked any new meds should be stored on the itinerary by this point
    return () => {
      clearMeds();
    };
  }, []);

  useEffect(() => {
    if (isActive) showDiscontinuedMedsModal();
  }, [isActive]);

  if (isHidden) return null;

  const description = () => {
    if (isActive || !canNavigate) return;
    return <SparkyTextWrapper truncateOn={1}>{descriptionText}</SparkyTextWrapper>;
  };

  return (
    <EditableSection
      buttonText={canNavigate && "Edit"}
      isActive={isActive}
      onClick={onClick}
      title="Medication Details"
      description={description()}
    >
      <Layout.Box id={componentID}>
        <HotelBookingRoomAndPetTabs
          componentID={componentID}
          getRoomTabIconType={getRoomTabIconType}
          getPetTabIconType={getPetTabIconType}
        >
          {petId => <BookingMedicationContainer petId={petId} />}
        </HotelBookingRoomAndPetTabs>

        <ButtonGroup align="right">
          <Button
            variant="primary"
            onClick={() => {
              onContinue();
            }}
            text="Next"
          />
        </ButtonGroup>
      </Layout.Box>
    </EditableSection>
  );
};

export const HotelBookingMedsSection = compose(
  withRouteProps,
  connect(
    (state, { stepIndex, router }) => {
      const pets = selectHotelBookingPetList(state);
      const petHasMeds = petId => !isEmpty(getMedsByPetExcludingNewMedID(state, { petId }));
      const rooms = selectHotelBookingRooms(state);
      const { isActive, canNavigate, openStep, furthestStep } = selectCommonHotelBookingStepData(
        stepIndex,
      )(state);

      const allPetMeds = getMedsExcludingNewMedId(state);
      const medCount = Object.values(allPetMeds)?.reduce(
        (count, med) => count + Object.values(med)?.length,
        0,
      );
      const descriptionText = medCount > 0 ? `${medCount} ${medCountLabel}` : noMedsLabel;

      // Rebooking data
      const itineraryId = router?.params?.itineraryId;
      const isRebooking = selectIsRebookingItinerary(state, { itineraryId });

      return {
        componentID: "hotelBookingFlow-medsSection",
        canNavigate,
        isActive,
        openStep,
        furthestStep,
        descriptionText,
        pets,
        getInitMeds: petId => selectPetMedsForBooking(state, { petId, isRebooking }),
        getRoomTabIconType: roomId => {
          const showCheckIcon = rooms[roomId]?.every(petId => petHasMeds(petId));
          const hasError = rooms[roomId]?.some(petId =>
            selectPetMedsIsMissingDates(state, { petId }),
          );
          return getIconType(showCheckIcon, hasError);
        },
        getPetTabIconType: petId => {
          const showCheckIcon = petHasMeds(petId);
          const hasError = selectPetMedsIsMissingDates(state, { petId });
          return getIconType(showCheckIcon, hasError);
        },
        isRebooking,
        discontinuedMeds: isRebooking && selectItineraryDiscontinuedMeds(state, { petIds: pets }),
      };
    },
    dispatch => {
      const { setStep, onContinue } = commonHotelBookingStepActions(dispatch);
      return {
        clearMeds: () => dispatch(clearMedications()),
        setMeds: (medications, petId) => {
          const normalizedMedications = normalizeArrayByProperty(medications, "petMedicationId");
          dispatch(setMedications({ medications: normalizedMedications, petId }));
        },
        setStep,
        onContinue,
        dispatchDiscontinuedMedsModal: () =>
          dispatch(showBookingModal(hotelBookingTypes.REBOOKING_MED_NOT_AVAILABLE)),
      };
    },
    (stateProps, dispatchProps, { stepIndex }) => {
      const {
        componentID,
        canNavigate,
        isActive,
        openStep,
        furthestStep,
        pets,
        descriptionText,
        getRoomTabIconType,
        getPetTabIconType,
        getInitMeds,
        discontinuedMeds,
      } = stateProps;
      const {
        clearMeds,
        setMeds,
        setStep,
        onContinue,
        dispatchDiscontinuedMedsModal,
      } = dispatchProps;

      return {
        onContinue: () => onContinue({ openStep, furthestStep }),
        componentID,
        canNavigate,
        isActive,
        onClick: () => setStep(stepIndex),
        pets,
        descriptionText,
        getRoomTabIconType,
        getPetTabIconType,
        clearMeds,
        setPetMeds: () => {
          // init with meds saved on pet profile or from itinerary if rebooking
          pets.forEach(petId => {
            const medications = getInitMeds(petId);
            setMeds(medications, petId);
          });
        },
        showDiscontinuedMedsModal: () => {
          if (isEmpty(discontinuedMeds)) return;

          dispatchDiscontinuedMedsModal();
        },
      };
    },
  ),
)(HotelBookingMedsSectionComponent);
