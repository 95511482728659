import { connect } from "react-redux";
import { isBookingDisabled } from "dux/eligibility/helperUtils/checkIsBookingDisabled";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import CommonButton from "web/common/commonButton";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { history } from "dux/utils/browser/browserHistory";
import { setItineraryId } from "@/core/actionCreators/bookingActionCreators";

const mapStateToProps = (state) => {
  const customerKey = getCurrentCustomerKey(state);

  return {
    isHidden: getSystemBookingFlow(state) !== systemName.SALON,
    componentId: "bookAppointmentSalon-commonButton",
    label: "Book Salon Appointment",
    disabled: isBookingDisabled(state, customerKey),

    // prop here for mergeProps pass through to dispatch
    customerKey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  redirect: ({ customerKey }) => {
    // Clear itineraryId to ensure that multiple appointment
    // are not attached to the same itinerary
    dispatch(setItineraryId(null));
    history.push(`/booking/${customerKey}/select-service`);
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () =>
    propsFromDispatch.redirect({
      customerKey: propsFromState.customerKey,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
