import { get } from "lodash/fp";
import { createSelector } from "reselect";
import { getFoodByPet } from "../food/foodsSelector";
import { OTHER_FOOD_NAME } from "../newFood/newFoodConstants";
import { selectFoodList } from "@/dux/foodOptionsList/foodOptionsListSelectors";

export const getOtherFoodNameById = createSelector(
  [getFoodByPet, selectFoodList, (state, props) => props],
  (foods, foodList, { foodId }) => {
    const chosenFood = get(foodId, foods);
    if (chosenFood && chosenFood.externalId) {
      const foundOtherFood = foodList.find(food => {
        return food.FoodId === chosenFood.externalId && food.FoodName === OTHER_FOOD_NAME;
      });
      if (foundOtherFood) {
        return chosenFood.name;
      }
    }
    return "";
  },
);
