import { set } from "lodash/fp";
import commonActionTypes from "core/actionTypes/ui/web/commonActionTypes";
import { checkForUndefined } from "core/utils/undefinedOrNullUtils/undefinedOrNullUtils";

const initialState = null;
/**
 * Description: Persistent Reducer function for job code.
 *
 * @memberof Reducer.persistent
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @returns {Object} - The updated state.
 */
export const associateJobCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case checkForUndefined(commonActionTypes.ASSOCIATE_JOB_CODE, initialState):
      return action.associateJobCode;

    default:
      return state;
  }
};
