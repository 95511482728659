import {
  GET_HOTEL_MANAGE_ROOM_DETAILS_SUCCESS,
  SET_HOTEL_MANAGE_ROOM_SCHEDULE_INACTIVATION,
  SET_HOTEL_MANAGE_ROOM_NUMBER
} from "./hotelManageRoomDetailsActions";

const initialState = {};

const hotelManageRoomDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTEL_MANAGE_ROOM_DETAILS_SUCCESS:
      return {
        ...state,
        selectedRoomDetails: action.payload,
      };
    case SET_HOTEL_MANAGE_ROOM_SCHEDULE_INACTIVATION:
      return {
        ...state,
        scheduleInactivation: action.payload,
      };
    case SET_HOTEL_MANAGE_ROOM_NUMBER:
      return {
        ...state,
        roomNumber: action.payload,
      };
    default:
      return state;
  }
};

export default hotelManageRoomDetailsReducer;
