import React from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Heading, Modal } from "@petsmart-ui/sparky";
import { hideOverbookingModal } from "../overbookingModals/overbookingModalsActions";
import { cancelOverbookingException } from "../overbookingExceptionTableDetails/overBookingExceptionTableDetailsActions";
import { selectOverbookingModalData } from "../overbookingModals/overbookingModalsSelectors";
import { getMoDayYearDate } from "@/core/utils/dateUtils/formatDateTime";

/**
 * React view component for overbooking modals
 * @summary /hotel-management/overbooking page
 * @memberOf Views.Overbooking
 * @function
 * @name OverbookingModalComponent
 * @param {Object} props - props passed into the view component
 * @param {String} props.componentId
 * @param {Boolean} props.isHidden
 * @param {String} props.header
 * @param {String|JSX.Element} props.body
 * @param {String} props.confirmLabel
 * @param {Function} props.onConfirm
 * @param {String} props.cancelLabel
 * @param {Function} props.onClose
 * @returns {JSX.Element|null}
 * @example const Component = connect()(OverbookingModalComponent)
 */
const OverbookingModalComponent = ({
  componentId,
  isHidden,
  header,
  body,
  confirmLabel,
  onConfirm,
  cancelLabel,
  onClose,
}) => {
  if (isHidden) return null;

  return (
    <Modal id={componentId} dismissible isActive onClose={onClose}>
      <Modal.Header id={componentId}>
        <Heading size="title" tagName="h2">
          {header}
        </Heading>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <ButtonGroup align="right">
          <Button text={cancelLabel} onClick={onClose} />
          <Button variant="primary" text={confirmLabel} onClick={onConfirm} />
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

/**
 * Connect function for hotel overbooking cancel exception modal
 * @see {@link Views.Overbooking.OverbookingModalComponent}
 * @summary /hotel-management/overbooking page
 * @memberOf Views.Overbooking
 * @function
 * @name OverbookingCancelExceptionModal
 * @returns {JSX.Element|null}
 * @example <OverbookingCancelExceptionModal />
 */
export const OverbookingCancelExceptionModal = connect(
  state => {
    const exceptionData = selectOverbookingModalData(state);
    const startDate = getMoDayYearDate(exceptionData?.startDate);
    const endDate = getMoDayYearDate(exceptionData?.endDate);
    const roomTypeName = exceptionData?.roomTypeBucketName ?? "";

    return {
      componentId: "HotelCheckInOutModals",
      header: "Cancel Exception",
      body: `Are you sure you want to cancel the exception for ${roomTypeName} ${startDate} - ${endDate}?`,
      confirmLabel: "Cancel exception",
      cancelLabel: "Keep exception",
      exceptionData,
    };
  },

  dispatch => {
    const onClose = () => dispatch(hideOverbookingModal());
    return {
      onClose,
      onConfirm: exception => {
        dispatch(cancelOverbookingException(exception));
        onClose();
      },
    };
  },
  (stateProps, dispatchProps) => {
    const { componentId, header, body, confirmLabel, cancelLabel, exceptionData } = stateProps;
    const { onClose, onConfirm } = dispatchProps;

    return {
      componentId,
      header,
      body,
      confirmLabel,
      cancelLabel,
      onClose,
      onConfirm: () => onConfirm(exceptionData),
    };
  },
)(OverbookingModalComponent);
