import { connect } from "react-redux";
import { get } from "lodash/fp";
import {
  setSpecialToRemove,
  showCheckInOutModal,
} from "../../core/actionCreators/checkInOutActionCreator";
import { showBookingModal } from "../../core/actionCreators/bookingActionCreators";
import { isFromBooking } from "../../core/utils/validationUtils/isFromValidation";
import SpecialsComponent from "./SpecialsComponent";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSpecialToRemove: special => dispatch(setSpecialToRemove({ specialToRemove: special })),
  showModal: modalType => {
    if (isFromBooking(get("router.location.pathname", ownProps))) {
      dispatch(showBookingModal(modalType));
    } else {
      dispatch(showCheckInOutModal({ modalType }));
    }
  },
});

export default withRouteProps(connect(null, mapDispatchToProps)(SpecialsComponent));
