import {
  SELECT_TRAINING_SERVICE,
  CLEAR_SELECTED_TRAINING_SERVICE,
  SHOW_PURCHASE_TRAINING_SERVICE_MESSAGE,
  HIDE_PURCHASE_TRAINING_SERVICE_MESSAGE,
  SHOW_TRAINING_DATE_SELECTION,
  HIDE_TRAINING_DATE_SELECTION,
  CLEAR_TRAINING_APPOINTMENT,
  SET_TRAINING_ENGAGEMENT_NOTE,
  CLEAR_TRAINING_ENGAGEMENT_NOTE,
  TOGGLE_TRAINING_APPOINTMENT_IS_SET_MODAL,
  TOGGLE_CANCEL_TRAINING_PENDING_APPOINTMENT_MODAL,
  RESET_SCHEDULE_TRAINING_PAGE,
} from "@/dux/scheduleTrainingPage/scheduleTrainingPageActions";
import {
  POST_TRAINING_CLASS_ITINERARY_SUCCESS,
  PUT_TRAINING_CLASS_ITINERARY_SUCCESS,
} from "@/dux/trainingClassItineraries/trainingClassItinerariesActions";

const initialState = {
  selectedTrainingPetService: null,
  isPurchaseTrainingServiceMessageHidden: true,
  isDateSelectionHidden: true,
  cachedTrainingAvailabilityWeeks: [],
  selectedTimeSlot: null,
  appointment: {},
  trainingEngagementNote: "",
  isTrainingAppointmentSetModalOpen: false,
  isCancelTrainingAppointmentModalOpen: false,
};

const trainingSchedulePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_TRAINING_SERVICE:
      return { ...state, selectedTrainingPetService: action.petServiceId };
    case CLEAR_SELECTED_TRAINING_SERVICE:
      return { ...state, selectedTrainingPetService: initialState.selectedTrainingPetService };
    case SHOW_PURCHASE_TRAINING_SERVICE_MESSAGE:
      return { ...state, isPurchaseTrainingServiceMessageHidden: false };
    case HIDE_PURCHASE_TRAINING_SERVICE_MESSAGE:
      return {
        ...state,
        isPurchaseTrainingServiceMessageHidden: initialState.isPurchaseTrainingServiceMessageHidden,
      };
    case SHOW_TRAINING_DATE_SELECTION:
      return { ...state, isDateSelectionHidden: false };
    case HIDE_TRAINING_DATE_SELECTION:
      return {
        ...state,
        isDateSelectionHidden: true,
      };
    case POST_TRAINING_CLASS_ITINERARY_SUCCESS:
    case PUT_TRAINING_CLASS_ITINERARY_SUCCESS:
      return {
        ...state,
        appointment: { ...action.payload },
      };
    case CLEAR_TRAINING_APPOINTMENT:
      return {
        ...state,
        appointment: initialState.appointment,
      };
    case SET_TRAINING_ENGAGEMENT_NOTE:
      return {
        ...state,
        trainingEngagementNote: action.trainingEngagementNote,
      };
    case CLEAR_TRAINING_ENGAGEMENT_NOTE:
      return {
        ...state,
        trainingEngagementNote: initialState.trainingEngagementNote,
      };
    case TOGGLE_TRAINING_APPOINTMENT_IS_SET_MODAL:
      return {
        ...state,
        isTrainingAppointmentSetModalOpen: !state.isTrainingAppointmentSetModalOpen,
      };
    case TOGGLE_CANCEL_TRAINING_PENDING_APPOINTMENT_MODAL:
      return {
        ...state,
        isCancelTrainingAppointmentModalOpen: !state.isCancelTrainingAppointmentModalOpen,
      };
    case RESET_SCHEDULE_TRAINING_PAGE:
      return initialState;

    default:
      return state;
  }
};

export default trainingSchedulePageReducer;
