import React from "react";
import { get, getOr } from "lodash/fp";
import styled from "styled-components";
import CloseButton from "../../common/CloseButton";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 10px;
  border: 1px solid silver;
  border-bottom: 0px solid silver;

  &:last-child {
    border-bottom: 1px solid silver;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;

const AddonName = styled.div`
  flex: 1;
`;

const CloseContainer = styled.div`
  padding-left: 5px;
`;

const AddonRow = props => {
  const { addon, removeAddOn } = props;

  return (
    <Container>
      <AddonName>{get("addOnName", addon)}</AddonName>
      <div>${getOr(0, "price", addon).toFixed(2)}</div>
      <CloseContainer>
        <CloseButton title="Delete Addon" onClick={removeAddOn} />
      </CloseContainer>
    </Container>
  );
};

export default AddonRow;
