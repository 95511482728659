// @ts-check
/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_HOTEL_MANAGE_ROOM_DETAILS = "GET_HOTEL_MANAGE_ROOM_DETAILS";
export const GET_HOTEL_MANAGE_ROOM_DETAILS_REQUEST = "GET_HOTEL_MANAGE_ROOM_DETAILS_REQUEST";
export const GET_HOTEL_MANAGE_ROOM_DETAILS_SUCCESS = "GET_HOTEL_MANAGE_ROOM_DETAILS_SUCCESS";
export const GET_HOTEL_MANAGE_ROOM_DETAILS_FAILURE = "GET_HOTEL_MANAGE_ROOM_DETAILS_FAILURE";
export const SET_HOTEL_MANAGE_ROOM_SCHEDULE_INACTIVATION =
  "SET_HOTEL_MANAGE_ROOM_SCHEDULE_INACTIVATION";
export const SET_HOTEL_MANAGE_ROOM_NUMBER = "SET_HOTEL_MANAGE_ROOM_NUMBER";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
/**
 *
 * @param {object} payload
 * @returns
 */
export const getHotelManageRoomDetails = (payload) => ({
  type: GET_HOTEL_MANAGE_ROOM_DETAILS,
  payload,
});

export const getHotelManageRoomDetailsRequest = () => ({
  type: GET_HOTEL_MANAGE_ROOM_DETAILS_REQUEST,
});

/**
 *
 * @param {object} payload
 * @returns
 */
export const getHotelManageRoomDetailsSuccess = (payload) => ({
  type: GET_HOTEL_MANAGE_ROOM_DETAILS_SUCCESS,
  payload,
});

/**
 *
 * @param {object} payload
 * @returns
 */
export const getHotelManageRoomDetailsFailure = (payload) => ({
  type: GET_HOTEL_MANAGE_ROOM_DETAILS_FAILURE,
  payload,
});

/**
 *
 * @param {boolean} payload
 * @returns
 */
export const setHotelManageRoomScheduleInactivation = (payload) => ({
  type: SET_HOTEL_MANAGE_ROOM_SCHEDULE_INACTIVATION,
  payload,
});

/**
 *
 * @param {string} payload
 * @returns
 */
export const setHotelManageRoomNumber = (payload) => ({
  type: SET_HOTEL_MANAGE_ROOM_NUMBER,
  payload,
});
