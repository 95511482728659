import { connect } from "react-redux"
import { history } from "dux/utils/browser/browserHistory";
import { routePaths } from "core/constants/routePaths";
import { Button } from "@prism/psm-ui-components";
import { getJobRole } from "core/selectors/persistentSelectors";
import { jobRoleConstants } from "core/constants/jobRoleConstants";

// Button to Route to Schedule Suspension page  -----------------------------------------------------------------------
export const ScheduleSuspensionButton = connect(
  (state) => {
    const jobRole = getJobRole(state);

    return {
      componentId: "ScheduleSuspensionButton",
      variant: "prism-primary",
      children: "Scheduled Suspensions",
      disabled: jobRole != jobRoleConstants.MANAGER
    };
  },

  () => {
    return {
      onClick: ({}) => {
        history.push(routePaths.SCHEDULE_SUSPENSION);
      },
    };
  },
)(Button);