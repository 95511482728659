import * as React from 'react';
import { psx } from '../../utils/clsx';

interface Props<T, C> {
  children: (item: T) => React.ReactNode;
  /** Appends a dividers className that can be used to style separators between the list items. */
  dividers?: boolean;
  /** An array of objects where each object **must** contain an id property. */
  items: T[];
  isHidden?: boolean;
  /** An override of the default HTML tag to cast your component to be any HTML element or React component. */
  as?: C;
}

export type ListProps<T, C extends React.ElementType> = Props<T, C> &
  Omit<React.ComponentPropsWithRef<C>, keyof Props<T, C>>;

export interface IdObj {
  id: string | number;
  label?: string | React.ReactNode;
}

/**
 * The list component standardizes the practice of iterating over an array.
 *
 * While no styles are applied, the intent is to enforce that a id key is present on the children elements
 * because [keys help React identify which items have changed, are added, or are removed.](https://reactjs.org/docs/lists-and-keys.html#keys).
 *
 * ```jsx
 * <List items={data}>
 *  {(item) => <div>{item.id}</div>}
 * </List>
 * ```
 *
 */

const List = <T extends IdObj, C extends React.ElementType = 'div'>({
  className = '',
  dividers = false,
  isHidden = false,
  items = [],
  children,
  as,
  ...props
}: ListProps<T, C>) => {
  if (isHidden) {
    return null;
  }

  const classNames = psx('psm', 'psm-list-item', className, { dividers });
  const Component = as || 'div';

  return (
    <>
      {items.map((item) => (
        <Component key={item.id} className={classNames} {...props}>
          {children(item)}
        </Component>
      ))}
    </>
  );
};

export default List;
