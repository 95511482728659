/** @module getStoreNameNumberAddress */

import SplitLineWithMessage from "../../../web/common/SplitLineWithMessage";

/**
 * Takes a store and returns store name, number as a string
 *
 * @export getStoreNameNumber
 * @param currentStore - current store object
 * @returns {string} - Store name number and address
 */
export default function getStoreNameNumberAddress(currentStore) {
  if (!currentStore) {
    return "";
  }

  const {
    Name,
    StoreNumber,
    StreetLine1,
    StreetLine2,
    City,
    StateProvinceAbbreviation,
    ZipPostalCode,
  } = currentStore;

  const address = `${StreetLine1} ${StreetLine2 ||
    ""}  ${City}, ${StateProvinceAbbreviation} ${ZipPostalCode}`;
  return SplitLineWithMessage({
    string: `${Name} #${StoreNumber}\n ${address}`,
    delimiter: "\n",
  });
}
