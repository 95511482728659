import { connect } from "react-redux";
import { getFoodTimeOfDayById } from "./foodTimeOfDaySelectors";
import { setFoodTimeOfDay, setTimeOfDayTouched } from "./actions/foodTimeOfDayActions";
import { timesOfDay } from "../../core/constants/timeOfDayConstants";
import Checkbox from "../common/Checkbox";
import { getFoodIsDiscontinuedById } from "../food/foodsSelector";

const mapStateToProps = (state, ownProps) => {
  const { foodId, petId } = ownProps;
  const foodTimes = getFoodTimeOfDayById(state, { foodId, petId });
  return {
    foodId,
    petId,
    foodTimes,
    componentId: "foodDetails-timeOfDayAfternoon",
    label: timesOfDay.AFTERNOON,
    labelPosition: "right",
    checked: foodTimes.includes(timesOfDay.AFTERNOON),
    disabled: getFoodIsDiscontinuedById(state, { petId, foodId }),
  };
};

const mapDispatchToProps = dispatch => ({
  setTimeOfDay: ({ foodId, timeOfDay, petId }) =>
    dispatch(setFoodTimeOfDay({ foodId, timeOfDay, petId })),
  setTimeOfDayTouched: (foodId, petId) =>
    dispatch(setTimeOfDayTouched({ foodId, petId, timeOfDayTouched: true })),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onChange: () => {
    const { foodId, foodTimes, checked, petId } = propsFromState;
    if (!checked) {
      const timeOfDay = [...foodTimes, timesOfDay.AFTERNOON];
      propsFromDispatch.setTimeOfDay({ foodId, timeOfDay, petId });
    } else {
      const timeOfDay = foodTimes.filter(time => time !== timesOfDay.AFTERNOON);
      propsFromDispatch.setTimeOfDay({ foodId, timeOfDay, petId });
    }
  },
  onBlur: () => {
    const { foodId, petId } = propsFromState;
    propsFromDispatch.setTimeOfDayTouched(foodId, petId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Checkbox);
