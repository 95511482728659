import { connect } from "react-redux";
import OnOffLabelWrapper from "dux/_components/roomCapacity/OnOffLabelWrapper";

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => ({
  isHidden: false,
  componentID: "OnOffLabelWrapper__DdcCapacityTableHeader",
});

export default connect(mapStateToProps)(OnOffLabelWrapper);
