/**
 * Responsible for returning the Vaccinations Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getVaccinationsState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the Vaccinations object in the redux store
 *
 * @example createSelector([getVaccinationsState], vaccinationsState => vaccinationsState.showVaccinationDropdown);
 */
export default function getVaccinationsState(state) {
  return state.ui.web.vaccinations;
}
