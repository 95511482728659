import React, { useEffect, useState } from "react";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { Button, Checkbox, TextPassage, Hr } from "@petsmart-ui/sparky";

/**
 *  React view component for filter flyout
 *  @memberOf Views.Associate
 *  @function
 *  @name FilterFlyout
 *  @param {object} props
 *  @param {string} props.componentId
 *  @param {string} props.isHidden
 *  @param {Array} props.initialSelectedOptions
 *  @param {Array} props.options
 *  @param {Function} props.onHandleClick
 *  @param {string} props.submitLabel
 *  @param {string} props.title
 *  @returns {JSX.Element}
 *
 *  @example const component = connect()(FilterFlyout);
 */
export const FilterFlyout = props => {
  const {
    componentId,
    isHidden,
    initialSelectedOptions = [],
    options = [],
    onHandleClick,
    submitLabel = "Apply",
    title = "Filter By:",
  } = props;

  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions);
  const isSelectedAll = selectedOptions.length ? options.length === selectedOptions.length : false;
  const [isAllOptionsSelected, setIsAllOptionsSelected] = useState(isSelectedAll);

  useEffect(() => {
    if (selectedOptions.length && options.length === selectedOptions.length) {
      setIsAllOptionsSelected(true);
    }
    if (selectedOptions.length && options.length !== selectedOptions.length) {
      setIsAllOptionsSelected(false);
    }
  }, [selectedOptions]);

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} style={{ width: "250px" }}>
      <TextPassage>
        <h6>{title}</h6>
      </TextPassage>
      <Hr style={{ margin: "1rem 0" }} />
      <LayoutBox padding="scale-0" style={{ marginBottom: "10px" }}>
        <LayoutCluster>
          <Checkbox
            checked={isAllOptionsSelected}
            indeterminate={
              selectedOptions.length ? options.length !== selectedOptions.length : false
            }
            name="selectAll"
            onChange={isChecked => {
              setIsAllOptionsSelected(isChecked);
              if (isChecked) {
                setSelectedOptions(options);
              } else {
                setSelectedOptions([]);
              }
            }}
          />
          <TextPassage style={{ fontWeight: "bold" }}>Select/Unselect All</TextPassage>
        </LayoutCluster>
      </LayoutBox>
      <LayoutCluster style={{ alignItems: "start", flexDirection: "column", marginLeft: "20px" }}>
        {options.map(({ value, label }) => (
          <LayoutBox padding="scale-0" key={value}>
            <LayoutCluster>
              <Checkbox
                checked={selectedOptions?.map(item => item?.value).includes(value)}
                name="checkbox"
                onChange={isChecked => {
                  const options = isChecked
                    ? [...selectedOptions, { value, label }]
                    : selectedOptions.filter(item => item?.value !== value);
                  setSelectedOptions(options);
                }}
              />
              <TextPassage> {label ?? "Blank"}</TextPassage>
            </LayoutCluster>
          </LayoutBox>
        ))}
      </LayoutCluster>
      <LayoutCluster style={{ justifyContent: "flex-end" }}>
        <LayoutBox>
          <Button
            variant="primary"
            text={submitLabel}
            onClick={event => {
              event.stopPropagation();
              onHandleClick(selectedOptions);
            }}
          />
        </LayoutBox>
      </LayoutCluster>
    </LayoutBox>
  );
};
