import React from 'react';
import { Form, Layout, Text } from "@prism/psm-ui-components";
import { omit } from 'lodash';
import { connect } from 'react-redux';
import { displayCharacterCount } from "core/utils/displayUtils/characterCountDisplayUtil";

const CharacterCountComponent = props => {
    return (
        <Layout.Box id ={props.componentId}>
            {props.children}
            <Layout.Stack space="stack-space-1">
                <Layout.Cluster justify="flex-end">
                    <Text id={`${props.componentId}_characterCount`}>{props.characterCount}</Text>
                </Layout.Cluster>
            </Layout.Stack>
        </Layout.Box> 
    )
}

const mapStateToProps = (state, { value: textToCount, maxLength }) => {
    const characterCount = displayCharacterCount(maxLength, textToCount);
    return {
        componentId: 'TextWithCharacterCountComponent',
        characterCount
    }
}

export const TextWithCharacterCountComponent = connect(mapStateToProps)(CharacterCountComponent);