import { connect } from "react-redux";
import { compose } from "redux";

// Components
import { FilterFlyout } from "@/dux/_components/filterFlyout/FilterFlyout";

// Actions
import { setSelectedFilters } from "@/dux/reports/reportsActions";

// Selectors
import {
  selectSelectedReportDataWithFilters,
  selectSelectedFilters,
} from "@/dux/reports/reportsSelectors";

// Utils
import { map, uniqBy } from "lodash";

// Helpers
import {
  formatBooleansToValues,
  formatDateTimeValues,
  mergeCustomFiltersForTable,
} from "@/dux/_components/prismTable/prismTableHelpers";

// Constants
import { timesOfDay } from "@/core/constants/timeOfDayConstants";

/**
 * Redux Connect function for the ReportFilterFlyout
 * @summary Located on the /reports page
 * @memberOf Views.Associate
 * @function
 * @name ReportFilterFlyout
 * @example <ReportFilterFlyout />
 */
export const ReportFilterFlyout = connect(
  (state, { filterKey }) => {
    const reportData = selectSelectedReportDataWithFilters(state);
    const selectedFilters = selectSelectedFilters(state);
    const selectedFilterValues =
      selectedFilters.find(item => item.filterKey === filterKey)?.filterValues || [];
    let filterOptions = [];
    const timeOfDayDataValues = [...new Set(reportData?.map(item => item?.timeOfDay))].join(",");

    if (filterKey.includes("timeOfDay")) {
      const filterValues =
        selectedFilterValues.length > 0
          ? selectedFilterValues
          : [
              {
                label: timesOfDay.MORNING,
                value: timesOfDay.MORNING,
              },
              {
                label: timesOfDay.AFTERNOON,
                value: timesOfDay.AFTERNOON,
              },
              {
                label: timesOfDay.EVENING,
                value: timesOfDay.EVENING,
              },
            ];

      filterOptions = filterValues.filter(({ value }) =>
        timeOfDayDataValues?.toLowerCase()?.includes(value.toLowerCase()),
      );
    } else {
      filterOptions = uniqBy(
        map(reportData, item => {
          return {
            label: compose(formatBooleansToValues, formatDateTimeValues)(item[filterKey]),
            value: item[filterKey],
          };
        }),
        "value",
      );
    }

    return {
      componentId: "ReportFilterFlyout",
      isHidden: false,
      initialSelectedOptions: selectedFilterValues,
      options: filterOptions,
      selectedFilters,
      submitLabel: "Apply",
      title: "Filter By:",
    };
  },
  (dispatch, { onClose }) => {
    return {
      onApplyFilters: filters => {
        dispatch(setSelectedFilters(filters));
        onClose();
      },
    };
  },
  (propsFromState, propsFromDispatch, { filterKey, filterArrayIndex }) => {
    const {
      componentId,
      isHidden,
      initialSelectedOptions,
      options,
      selectedFilters,
      submitLabel,
      title,
    } = propsFromState;

    const { onApplyFilters } = propsFromDispatch;

    return {
      componentId,
      isHidden,
      initialSelectedOptions,
      options,
      submitLabel,
      title,
      onHandleClick: filtersOpted => {
        let filters = mergeCustomFiltersForTable(
          selectedFilters,
          filtersOpted,
          filterKey,
          filterArrayIndex,
        );

        onApplyFilters(filters);
      },
    };
  },
)(FilterFlyout);
