import { connect } from "react-redux";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getFirstTimeCustomerInfo } from "dux/bgm/ftco/ftcoSelectors";
import { ELIGIBLE_PROMOTIONS_BGM_MESSAGE } from "dux/eligiblePromotions/eligiblePromotionConstants";
import { isBGMEligibilityHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import IconWithLabel from "dux/_components/icon/IconWithLabel";
import PreCheckedInIcon from "assets/icons/pre-checked-in-appointment.svg";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  const { firstName } = getFirstTimeCustomerInfo(state, { customerKey });

  return {
    firstName,
    componentId: "eligiblePromotionsBGMContainer__IconWithLabel",
    isHidden: isBGMEligibilityHidden(state),
    src: PreCheckedInIcon,
    label: `${firstName} ${ELIGIBLE_PROMOTIONS_BGM_MESSAGE}`,
    styleObj: {
      padding: "1em 0 0 0",
      justifyContent: "center",
    },
  };
};

export default connect(mapStateToProps)(IconWithLabel);
