/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const POST_RESEND_MEETING_LINK = "POST_RESEND_MEETING_LINK";
export const POST_RESEND_MEETING_LINK_REQUEST = "POST_RESEND_MEETING_LINK_REQUEST";
export const POST_RESEND_MEETING_LINK_SUCCESS = "POST_RESEND_MEETING_LINK_SUCCESS";
export const POST_RESEND_MEETING_LINK_FAILURE = "POST_RESEND_MEETING_LINK_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const postResendMeetingLink = (payload) => ({
  type: POST_RESEND_MEETING_LINK,
  engagementId: payload.engagementId,
  onComplete: payload.onComplete,
});

export const postResendMeetingLinkRequest = () => ({
  type: POST_RESEND_MEETING_LINK_REQUEST,
});

export const postResendMeetingLinkSuccess = (payload) => ({
  type: POST_RESEND_MEETING_LINK_SUCCESS,
  payload,
});

export const postResendMeetingLinkFailure = (error) => ({
  type: POST_RESEND_MEETING_LINK_FAILURE,
  error,
});
