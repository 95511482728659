import { range } from "lodash/fp";

/**
 * Creates an array of objects for <select> options which are solely running indices.
 * e.g: [{label: 1, value: 1}, {label: 2, value: 2}, ...]
 * @param {number} startIndex The number value to start from
 * @param {number} endIndex The number value to end with
 * @returns {Array<{label, value}>}
 */
export default function createRangedOptions(startIndex, endIndex) {
  return range(startIndex, endIndex + 1).map(value => ({ label: value, value }));
}
