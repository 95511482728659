// ACTION TYPES --------------------------------------------------------------------------------------------------------

/** @type {string} */
export const GET_CANCELLATION_REASONS = "GET_CANCELLATION_REASONS";

/** @type {string} */
export const GET_CANCELLATION_REASONS_REQUEST = "GET_CANCELLATION_REASONS_REQUEST";

/** @type {string} */
export const GET_CANCELLATION_REASONS_SUCCESS = "GET_CANCELLATION_REASONS_SUCCESS";

/** @type {string} */
export const GET_CANCELLATION_REASONS_FAILURE = "GET_CANCELLATION_REASONS_FAILURE";

// ACTIONS -------------------------------------------------------------------------------------------------------------
/**
 * Action to Kick off process to make an api all to get cancellationReasons
 * @returns {{type: string}}
 */
export const getCancellationReasons = () => ({
  type: GET_CANCELLATION_REASONS,
});

/**
 * Action to used to specify an API request will be made, this action is generally here to help loading screen/spinner etc.
 * @returns {{type: string}}
 */
export const getCancellationReasonsRequest = () => ({
  type: GET_CANCELLATION_REASONS_REQUEST,
});

/**
 * @typedef {Object} CancellationReason
 * @property {string} value - Value provided for the selection element ff
 * @property {string} name - Name provided for the selection element
 */

/**
 * @typedef  {CancellationReason[]} CancellationReasons
 */

/**
 *
 * @param {CancellationReasons } cancellationReasons
 * @returns {{type: string, cancellationReasons: CancellationReasons, }}
 */
export const getCancellationReasonsSuccess = ({ cancellationReasons }) => ({
  type: GET_CANCELLATION_REASONS_SUCCESS,
  cancellationReasons,
});

/**
 *
 * @param { string }error
 * @returns {{type: string, error: string}}
 */
export const getCancellationReasonsFailure = ({ error }) => ({
  type: GET_CANCELLATION_REASONS_FAILURE,
  error,
});
