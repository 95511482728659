import React from "react";
import styled from "styled-components";
import { get } from "lodash/fp";
import Checkbox from "../common/Checkbox";
import CommonButton from "../common/commonButton";
import Img from "../common/Img";
import LoadingWrapper from "../common/LoadingWrapper";
import { APPOINTMENT_STATUS } from "../../core/constants";
import { pageNames } from "../../core/constants/checkInOutConstants";
import getUnknownPetImage from "../../core/utils/assetUtils/unknownPetImage";
import Login from "web/login/loginComponent";
import AppointmentRebookCancelApptContainer from "dux/appointmentRebook/AppointmentRebookCancelApptContainer";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

class CancelAppointment extends React.Component {
  handleReasonChange = reason => {
    const { setCancelAppointmentReason } = this.props;
    setCancelAppointmentReason(reason);
  };

  handleDone = isCanceled => {
    const { hideModal, cancelAppointment, petServiceItem, cancelAppointmentReason } = this.props;

    if (!petServiceItem) {
      return;
    }

    const { customer, itinerary, engagement } = petServiceItem;

    if (isCanceled) {
      hideModal();
    } else {
      cancelAppointment({
        customerId: customer,
        itineraryId: itinerary,
        engagementId: engagement,
        reason: cancelAppointmentReason,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { areAllPetServiceItemsCancelled, itineraryId, customer, router: { location, navigate } } = this.props;
    if (
      get(["petServiceItem", "status"], prevProps) !== APPOINTMENT_STATUS.CANCELED &&
      get(["petServiceItem", "status"], this.props) === APPOINTMENT_STATUS.CANCELED &&
      areAllPetServiceItemsCancelled &&
      !location.pathname.includes("modify-appointment")
    ) {
      navigate(`/${pageNames.PAST_APPOINTMENT}/${customer}/${itineraryId}`);
    }
  }

  componentWillUnmount() {
    this.props.clearCancelAppointmentReason();
  }

  render() {
    const {
      cancelAppointmentReason,
      pet,
      isLoading,
      petServiceItem,
      cancellationReasons,
    } = this.props;

    const isCanceled = petServiceItem.status === APPOINTMENT_STATUS.CANCELED;
    const title = isCanceled ? "Appointment canceled" : "Appointment cancellation";
    const message = isCanceled
      ? `We canceled the appointment for ${pet && pet.petName}`
      : "Before we cancel the appointment, we want to know why";

    return (
      <LoadingWrapper isLoading={isLoading}>
        <Container>
          <Header>
            <Title>{title}</Title>
            {message}
          </Header>

          <ReasonsContainer>
            {isCanceled ? (
              <PetImage src={getUnknownPetImage(pet)} />
            ) : (
              <Reasons>
                {cancellationReasons.map(reason => (
                  <Reason key={reason.value}>
                    <Checkbox
                      label={reason.value}
                      labelPosition="right"
                      onChange={() => this.handleReasonChange(reason.value)}
                      checked={reason.value === cancelAppointmentReason}
                      boldSelection
                    />
                  </Reason>
                ))}
              </Reasons>
            )}
          </ReasonsContainer>

          <ButtonContainer isCanceled={isCanceled}>
            <CommonButton
              label="Done"
              onClick={() => this.handleDone(isCanceled)}
              disabled={!cancelAppointmentReason && !isCanceled}
            />

            <AppointmentRebookCancelApptContainer />
          </ButtonContainer>
          {!cancelAppointmentReason && !isCanceled && (
            <p>Please supply a reason for cancellation</p>
          )}
        </Container>
      </LoadingWrapper>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ReasonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
`;

const Reasons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Reason = styled.div`
  margin-top: 10px;
  width: 250px;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.isCanceled ? "1fr" : "1fr 1fr")};
  column-gap: 20px;
`;

const PetImage = styled(Img)`
  width: 70px;
  height: 70px;
`;

export default withRouteProps(CancelAppointment);
