import * as Sentry from "@sentry/react";

/** Creates a Redux Enhancer for omitting sensitive data or actions
 *  from Redux before processing with Sentry.
 *
 *  See https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/ for documentation.
 */
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Used to remove sensitve information from state.
  stateTransformer: state => {
    if (state.persistent.accessToken) {
      return null;
    }
    if (state.persistent.refreshToken) {
      return null;
    }
    if (state.persistent.authenticatedSubscriptionKey) {
      return null;
    }
    if (state.persistent.session) {
      return null;
    }
  },
  // Used to remove sensitve information from actions.
  actionTransformer: action => {
    if (action.type === "SET_SESSION_TOKEN") {
      return {
        ...action,
        sessionToken: null,
      };
    }
    if (action.type === "SET_AUTHENTICATED_SUBSCRIPTION_KEY") {
      return {
        ...action,
        authenticatedSubscriptionKey: null,
      };
    }
    if (action.type === "SET_ACCESS_TOKEN") {
      return {
        ...action,
        accessToken: null,
      };
    }
    if (action.type === "SET_ID_TOKEN") {
      return {
        ...action,
        sessionData: null,
      };
    }
  },

  configureScopeWithState: (scope, state) => {
    scope.setTag("store", state.persistent?.currentStore?.toString() );
    scope.setTag("accessLevel", state.persistent?.session?.oktaUserInfoResponse?.accessLevel);
  },
});

export default sentryReduxEnhancer;
