/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const DELETE_CHECK_IN_MED = "DELETE_CHECK_IN_MED";
export const DELETE_CHECK_IN_MED_REQUEST = "DELETE_CHECK_IN_MED_REQUEST";
export const DELETE_CHECK_IN_MED_SUCCESS = "DELETE_CHECK_IN_MED_SUCCESS";
export const DELETE_CHECK_IN_MED_FAILURE = "DELETE_CHECK_IN_MED_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const deleteCheckInMed = ({ petId, medId }) => ({
  type: DELETE_CHECK_IN_MED,
  petId,
  medId,
});

export const deleteCheckInMedRequest = () => ({
  type: DELETE_CHECK_IN_MED_REQUEST,
});

export const deleteCheckInMedSuccess = () => ({
  type: DELETE_CHECK_IN_MED_SUCCESS,
});

export const deleteCheckInMedFailure = error => ({
  type: DELETE_CHECK_IN_MED_FAILURE,
  error,
});
