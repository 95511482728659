/**
 * Room Management Actions
 */
export const LOAD_HOTEL_ROOM_TYPES = "LOAD_HOTEL_ROOM_TYPES";
export const SET_HOTEL_ROOM_SELECTED_DATE = "SET_HOTEL_ROOM_SELECTED_DATE";
export const SET_HOTEL_ROOM_TYPES = "SET_HOTEL_ROOM_TYPES";
export const SET_HOTEL_ROOM_TYPES_REQUEST = "SET_HOTEL_ROOM_TYPES_REQUEST";
export const SET_HOTEL_ROOM_TYPES_FAILURE = "SET_HOTEL_ROOM_TYPES_FAILURE";
export const SET_HOTEL_ROOM_TYPES_SUCCESS = "SET_HOTEL_ROOM_TYPES_SUCCESS";
export const SET_HOTEL_ROOM_TYPE_SELECTION = "SET_HOTEL_ROOM_TYPE_SELECTION";

export const loadHotelRoomTypes = payload => ({
  type: LOAD_HOTEL_ROOM_TYPES,
  payload,
});

export const setHotelRoomTypesRequest = payload => ({
  type: SET_HOTEL_ROOM_TYPES_REQUEST,
  payload,
});

export const setHotelRoomTypesSuccess = payload => ({
  type: SET_HOTEL_ROOM_TYPES_SUCCESS,
  payload,
});

export const setHotelRoomTypesFailure = error => ({
  type: SET_HOTEL_ROOM_TYPES_FAILURE,
  error,
});

export const setHotelRoomDateSelection = payload => ({
  type: SET_HOTEL_ROOM_SELECTED_DATE,
  payload,
});

export const setHotelRoomTypeSelection = payload => ({
  type: SET_HOTEL_ROOM_TYPE_SELECTION,
  payload,
});
