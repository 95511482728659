import moment from "moment";
import { find, forEach } from "lodash/fp";
import { dayActivityTypes } from "../../constants/schedulesConstants";
import getMomentByDateAndTime from "./byDateAndTime";

/**
 * Returns the difference between work duration and absence duration
 *
 * @export
 * @param {object} obj - Destructuring parameters
 * @param {array} dayActivities
 * @param {string} date
 * @returns {number}
 */
export default function getWorkTimeDuration({ dayActivities, date }) {
  let workActivity = find({ type: dayActivityTypes.WORK }, dayActivities);

  if (!workActivity) {
    return 0;
  }

  const workActivities = dayActivities.filter(item => item.type === dayActivityTypes.WORK);

  if (workActivities?.length > 1) {
    workActivity = {...workActivity, endTime: workActivities[1].endTime};
  }

  let absencesDuration = 0;
  const workStartMoment = getMomentByDateAndTime(date, workActivity.startTime);
  const workEndMoment = getMomentByDateAndTime(date, workActivity.endTime);
  const workDuration = moment.duration(workEndMoment.diff(workStartMoment)).asHours();

  forEach(dayActivity => {
    if (dayActivity.type !== dayActivityTypes.WORK) {
      const absenceStartMoment = getMomentByDateAndTime(date, dayActivity.startTime);
      const absenceEndMoment = getMomentByDateAndTime(date, dayActivity.endTime);

      if (absenceStartMoment >= workStartMoment && absenceEndMoment <= workEndMoment) {
        absencesDuration += moment.duration(absenceEndMoment.diff(absenceStartMoment)).asHours();
      }
    }
  }, dayActivities);

  return workDuration - absencesDuration;
}
