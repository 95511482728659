import { connect } from "react-redux";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { getAssociate } from "../../../core/selectors/entitiesSelector";
import { getSelectedAssociate } from "../../../core/selectors/commonSelector";
import GanttAssociateInfoComponent from "./GanttAssociateInfoComponent";
import { getAssociateShiftTimeByDate } from "../../../core/selectors/schedulesSelectors";

const mapStateToProps = (state, ownProps) => {
  const selectedAssociate = getSelectedAssociate(state);

  return {
    associate: getAssociate(state, { associateId: ownProps.groupMember }),
    selectedAssociate,
    isSelectedAssociate: ownProps.groupMember === selectedAssociate,
    shiftTime: getAssociateShiftTimeByDate(state, {
      associateId: ownProps.groupMember,
      selectedDate: getSelectedDate(state),
    }),
  };
};

export default connect(mapStateToProps)(GanttAssociateInfoComponent);
