import { connect } from "react-redux";
import {
  getSelectedBulkPackageOfferingPrice,
  getFormattedBulkPackageOfferingName,
} from "../bulkPackageOfferingsSelectors";
import PricingLineItemComponent from "../../../_components/pricing/PricingLineItemComponent";

const mapStateToProps = state => {
  const price = getSelectedBulkPackageOfferingPrice(state);
  return {
    componentID: `bulk-package-offerings-cart-details-pricing`,
    label: getFormattedBulkPackageOfferingName(state),
    price: price ? `$${price?.toFixed(2)}` : null,
    isHidden: false,
  };
};

export default connect(mapStateToProps, null)(PricingLineItemComponent);
