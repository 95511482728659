export default {
  SET_PAGE_NAME: "SET_PAGE_NAME",
  SHOW_CHECK_IN_OUT_MODAL: "SHOW_CHECK_IN_OUT_MODAL",
  HIDE_CHECK_IN_OUT_MODAL: "HIDE_CHECK_IN_OUT_MODAL",
  SHOW_CHECK_IN_OUT_BUTTON_MODAL: "SHOW_CHECK_IN_OUT_BUTTON_MODAL",
  HIDE_CHECK_IN_OUT_BUTTON_MODAL: "HIDE_CHECK_IN_OUT_BUTTON_MODAL",
  SET_PET_SERVICE_ITEM: "SET_PET_SERVICE_ITEM",
  SET_ITINERARY: "SET_ITINERARY",
  SET_CANCEL_APPOINTMENT_REASON: "SET_CANCEL_APPOINTMENT_REASON",
  CLEAR_CANCEL_APPOINTMENT_REASON: "CLEAR_CANCEL_APPOINTMENT_REASON",
  SET_SPECIAL_TO_REMOVE: "SET_SPECIAL_TO_REMOVE",
  CLEAR_SPECIAL_TO_REMOVE: "CLEAR_SPECIAL_TO_REMOVE",
  PRINT_INVOICE_FOR_MULTI: "PRINT_INVOICE_FOR_MULTI",
  PRINT_INVOICE_FOR_MULTI_REQUEST: "PRINT_INVOICE_FOR_MULTI_REQUEST",
  PRINT_INVOICE_FOR_MULTI_SUCCESS: "PRINT_INVOICE_FOR_MULTI_SUCCESS",
  PRINT_INVOICE_FOR_MULTI_FAILURE: "PRINT_INVOICE_FOR_MULTI_FAILURE"
};
