import React from "react";
import { connect } from "react-redux";
import { color } from "@/web/common/styles/theme";
import { TextPassage } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { getIsSRCAgent, getStoreNumber } from "@/core/selectors/persistentSelectors";
import { LOGIN_TO_REBOOK } from "./_appointmentRebookConstants";
import { getHotelItinerary } from "../hotelItinerary/hotelItinerarySelectors";

/**
 * React view component for the hotel check out page rebook login message
 * @summary Located on the hotel check out page
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name  AppointmentRebookLoginMsg
 * @param {Object} props - props passed into the view component
 * @param {String} props.componentId
 * @param {Boolean} props.isHidden
 * @param {Object} props.style
 * @returns {JSX.Element|null}
 * @example <AppointmentRebookLoginMsg />
 */
export const AppointmentRebookLoginMsg = props => {
  const { componentId, isHidden, style } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} style={{ color: color.grey, ...style }}>
      <TextPassage size="lg">{LOGIN_TO_REBOOK}</TextPassage>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the hotel pet parent profile pet card rebook login message
 * @see {@link Views.Hotel.Itinerary.AppointmentRebookLoginMsg}
 * @summary Located on the hotel pet parent profile
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelPetCardRebookLoginMsg
 * @param {Object} props - props passed into the view component
 * @param {Number} props.storeNumber - store number of reservation
 * @returns {JSX.Element|null}
 * @example <HotelPetCardRebookLoginMsg />
 */
export const HotelPetCardRebookLoginMsg = connect((state, { storeNumber }) => {
  const isSRC = getIsSRCAgent(state);
  const currentStore = getStoreNumber(state);
  const isCurrentStore = currentStore === storeNumber;
  const showMessage = isSRC && !isCurrentStore;

  return {
    componentId: `HotelPetCardRebookLoginMsg`,
    isHidden: !showMessage,
    style: { paddingLeft: 0 },
  };
})(AppointmentRebookLoginMsg);

/**
 * Redux Connect function for the hotel check out page rebook login message
 * @see {@link Views.Hotel.Itinerary.AppointmentRebookLoginMsg}
 * @summary Located on the hotel check out page
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelCheckInOutRebookLoginMsg
 * @returns {JSX.Element|null}
 * @example <HotelCheckInOutRebookLoginMsg />
 */
export const HotelCheckInOutRebookLoginMsg = connect(state => {
  const isSRC = getIsSRCAgent(state);
  const { storeNumber } = getHotelItinerary(state) ?? {};
  const currentStore = getStoreNumber(state);
  const isCurrentStore = currentStore === storeNumber;
  const showMessage = isSRC && !isCurrentStore;

  return {
    componentId: `HotelCheckInOutRebookLoginMsg`,
    isHidden: !showMessage,
    style: { textAlign: "right" },
  };
})(AppointmentRebookLoginMsg);
