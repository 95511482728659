import { connect } from "react-redux";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { getIsDateSelectionHidden } from "../scheduleTrainingPage/scheduleTrainingPageSelectors";
import { getCachedTrainingAvailabilityWeeks } from "@/dux/scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";
import { getSelectedTrainingPetService } from "@/dux/scheduleTrainingPage/scheduleTrainingPageSelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import dispatchTrainingAvailability from "@/dux/scheduleTrainingPage/scheduleTrainingPageUtils";
import Calendar from "@/web/common/Calendar";

const mapStateToProps = (state) => {
  const selectedDate = getSelectedDate(state);

  return {
    selectedDate,
    isHidden: getIsDateSelectionHidden(state),
    calendarTitle: "Preference",
    isWeekly: false,
    isOutsideRange: () => false,
    petServiceId: getSelectedTrainingPetService(state),
    storeNumber: getStoreNumber(state),
    petId: getSelectedPet(state),
    cachedTrainingAvailabilityWeeks: getCachedTrainingAvailabilityWeeks(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSelectDate: ({ selectedDate, storeNumber, petServiceId, petId, cache }) => {
    dispatchTrainingAvailability({
      selectedDate,
      cache,
      storeNumber,
      petServiceId,
      petId,
      dispatch,
    });
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  selectDate: (selectedDate) => {
    const { petServiceId, storeNumber, petId, cachedTrainingAvailabilityWeeks } = propsFromState;

    propsFromDispatch.dispatchSelectDate({
      selectedDate,
      storeNumber,
      petServiceId,
      petId,
      cache: cachedTrainingAvailabilityWeeks,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Calendar);
