import { createSelector } from "reselect";
import { get } from "lodash/fp";
import { getCustomer } from "../../entitiesSelector";
import { getPrimaryPhoneByCustomer } from "../../petParentProfileSelectors";
import normalizeAddressValues from "../../../utils/customer/address/normalizeAddressValues";

// Example of use:  getDefaultPropsForAddAddressForm(state, { customerKey });
const getDefaultPropsForAddAddressForm = createSelector(
  [getCustomer, getPrimaryPhoneByCustomer],
  (customer, primaryPhone) =>
    normalizeAddressValues({
      firstName: get("firstName", customer),
      lastName: get("lastName", customer),
      phoneNumber: get("phoneNumber", primaryPhone)
    })
);

export default getDefaultPropsForAddAddressForm;
