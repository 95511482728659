import { connect } from "react-redux";
import { getSpeciesCountsByAppointmentType } from "../../../core/selectors/entitiesSelector";
import SalonTitleCard from "../../../dux/_components/titleCard/SalonTitleCard";

const mapStateToProps = state => ({
  speciesCounts: getSpeciesCountsByAppointmentType(state),
});

const TitleCardContainer = connect(mapStateToProps)(SalonTitleCard);

export default TitleCardContainer;
