import { range } from "lodash/fp";

export const associateGroupConstants = {
  ALL: "all",
  STYLIST: "Stylist",
  BATHER: "Bather",
  OTHER: "Other",
};

export const associateMaxCheckInOptions = range(1, 5);
export const associateMaxPerBlockOptions = range(1, 15);

export const employeeGroupConstants = [
  "Professional PetStylist - Dog & Cat",
  "Professional Bather",
];

/**
 * Constants props for the associate preferred Name
 * @memberOf Constants.associates
 * @returns {{limit: number, placeHolder: string}}
 * @example
 * const maxLength = preferredNameConstants.limit
 */
export const preferredNameConstants = {
  limit: 20,
  placeHolder: "First name and last name initial only",
};