import React from "react";

// Components
import { TextPassage } from "@petsmart-ui/sparky";

/**
 *  React view component for PrismTableHeaderCell
 *  @memberOf Views.Associate
 *  @function
 *  @name PrismTableHeaderCell
 *  @param {object} props
 *  @param {boolean} props.isHidden
 *  @param {JSX.Element} props.children
 *  @param {object} props.styles
 *  @returns {JSX.Element}
 *
 *  @example const component = connect()(PrismTableHeaderCell);
 */
export const PrismTableHeaderCell = props => {
  const { isHidden, children, styles } = props;

  if (isHidden) {
    return null;
  }

  return <TextPassage style={{ fontWeight: "bold", ...styles }}>{children}</TextPassage>;
};
