import React from "react";
import { connect } from "react-redux";
import { TextPassage } from "@petsmart-ui/sparky";
import { getDayMonthAndDate } from "core/utils/dateUtils/dayOfTheWeekUtils";
import { LayoutBox } from "@/layout/box/Box";
import { weekDaysNames } from "../../../core/constants";

export const DayOfWeekPassage = props => {
  // Short circuit view if it isHidden
  const { isHidden } = props;
  if (isHidden) return null;

  // value props
  const { componentId, dayOfWeekText } = props;

  return (
    <LayoutBox id={componentId}>
      <TextPassage size="lg">{dayOfWeekText}</TextPassage>
    </LayoutBox>
  );
};

export const DayOfWeekText = connect((state, ownProps) => {
  const { dayOfWeek, startWeekDate, isTemplateMode } = ownProps;

  const dayOfWeekText = getDayMonthAndDate(dayOfWeek, startWeekDate, weekDaysNames, isTemplateMode);

  return {
    componentId: "DayOfWeekText",
    dayOfWeekText,
  };
})(DayOfWeekPassage);
