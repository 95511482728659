import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Layout, Button } from "@prism/psm-ui-components";
import { useForm } from "../utils/formUtils/useForm";
import { getFormattedSelectedDate } from "./petVaccinationUtils";
import { color } from "@/web/common/styles/theme";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import { getVaccinationIdsByName } from "@/core/selectors/ui/vaccinations/vaccinationsSelectors";
import { updatePetVaccinations } from "@/core/actionCreators/petVaccinationsActionCreators";
import EditIcon from "@/assets/icons/edit.svg";
import Calendar from "@/web/common/Calendar";
import { createLoadingSelector } from "@/core/selectors/utils";
import petVaccinationsActionTypes from "@/core/actionTypes/petVaccinationsActionTypes";

function EditPetVaccinationComponent({ onSave, isLoading }) {
  const [isEditing, setIsEditing] = useState(false);
  const { values, didFormFieldsUpdate, setFormValue } = useForm();

  const currentDate = moment().format("YYYY-MM-DD");
  const { vaccinationDate = currentDate } = values;

  const toggleIsEditing = () => setIsEditing(!isEditing);

  return (
    <Layout.Cluster
      align="justify"
      space="cluster-space-4"
      style={{ overflow: "visible", alignItems: "center" }}
    >
      {isEditing ? (
        <>
          <Calendar
            hasIcon
            numberOfMonths={1}
            displayFormat="MM/DD/YYYY"
            selectedDate={vaccinationDate}
            selectDate={(selectedDate) => {
              const formattedValue = getFormattedSelectedDate(selectedDate);
              setFormValue("vaccinationDate", formattedValue);
            }}
            isOutsideRange={(day) => day.isBefore(currentDate, "day")}
          />
          <Button
            disabled={isLoading || !didFormFieldsUpdate}
            variant="link"
            onClick={() => onSave({ vaccinationDate, onComplete: toggleIsEditing })}
          >
            Save
          </Button>
          <Button variant="link" style={{ color: color.darkGrey }} onClick={toggleIsEditing}>
            Cancel
          </Button>
        </>
      ) : (
        <img src={EditIcon} style={{ cursor: "pointer" }} onClick={toggleIsEditing} />
      )}
    </Layout.Cluster>
  );
}

export const EditPetVaccination = connect(
  (state, { name }) => {
    const selectedPet = getSelectedPet(state);
    return {
      selectedPet,
      isLoading: createLoadingSelector([petVaccinationsActionTypes.UPDATE_PET_VACCINATIONS])(state),
      customerKey: getCurrentCustomerKey(state),
      vaccinationIds: getVaccinationIdsByName(state, { name, petId: selectedPet }),
    };
  },

  (dispatch) => ({
    updatePetVaccination: (payload) => {
      dispatch(updatePetVaccinations(payload));
    },
  }),
  (stateProps, dispatchProps, { name }) => {
    const { customerKey, selectedPet, isLoading } = stateProps;
    const { updatePetVaccination } = dispatchProps;
    return {
      isLoading,
      onSave: ({ vaccinationDate, onComplete }) => {
        updatePetVaccination({
          customerKey,
          petId: selectedPet,
          formValues: {
            [name]: vaccinationDate,
          },
          onComplete,
        });
      },
    };
  },
)(EditPetVaccinationComponent);
