import React, { Fragment } from "react";
import ReactDOM from "react-dom";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";

function featureFlagUIWrapper(props) {
  const { isHidden, workflowFeatureFlags, diComp } = props;
  const app = document.getElementById("root");

  if (isHidden) return null;

  return ReactDOM.createPortal(
    <LayoutBox
      style={{
        width: "100%",
        padding: "1.5rem",
        backgroundColor: "rgba(255, 255, 255, .15)",
        backdropFilter: "blur(5px)",
        position: "fixed",
        bottom: 0,
        boxShadow: "4px -3px 5px 0 rgb(0, 0, 0, 0.25)",
      }}
    >
      <LayoutCluster space="scale-S2" style={{ justifyContent: "space-between" }}>
        {workflowFeatureFlags.map(workflowFeatureFlag => {
          const FeatureFlag = diComp[workflowFeatureFlag.id];

          return (
            <Fragment key={workflowFeatureFlag.id}>
              {FeatureFlag ? <FeatureFlag id={workflowFeatureFlag.id} /> : <></>}
            </Fragment>
          );
        })}
      </LayoutCluster>
    </LayoutBox>,
    app,
  );
}

export default featureFlagUIWrapper;
