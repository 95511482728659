import React from "react";
import styled from "styled-components";

import {
  GroupMemberTitle,
  GroupMemberDetails
} from "../ganttCommonComponents/groupMemberComponents";

const StyledGanttWeekDayInfoComponent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.4;
  min-height: 80px;
  padding-left: 20px;
  justify-content: center;
`;

const GanttWeekDayComponent = props => {
  const { date, shiftTime } = props;
  return (
    shiftTime && (
      <StyledGanttWeekDayInfoComponent>
        <GroupMemberTitle>{date.format("dddd, MMMM DD")}</GroupMemberTitle>
        {shiftTime &&
          shiftTime.shiftStartDateTime &&
          shiftTime.shiftEndDateTime && (
            <GroupMemberDetails>
              {shiftTime.shiftStartDateTime.format("LT")} -{" "}
              {shiftTime.shiftEndDateTime.format("LT")}
            </GroupMemberDetails>
          )}
      </StyledGanttWeekDayInfoComponent>
    )
  );
};

export default GanttWeekDayComponent;
