import * as React from "react";
import { WebcamProps } from "./types";

interface ScreenshotDimensions {
  width: number;
  height: number;
}

interface WebcamState {
  hasUserMedia: boolean;
  src?: string;
}

/**
 * Performs a check to determine if device can access the WebRTC API
 * @returns {boolean} Boolean if the `mediaDevices` or `getUserMedia` API's can be found.
 *
 * @note This must be declared outside of the webcam class definition to allow checking
 * for supported API functions.
 *
 */
function hasGetUserMedia() {
  return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
}

/**
 * React module for handling the detection and connection of connected camera inputs using the WebRTC protocols.
 *
 * The module renders a single camera I/O stream to the canvas and provides functions to
 * capture an image and encode it as a Base64 string.
 *
 * @usage       import Webcam from "./webcam"
 *              <Webcam ref={myRef} audio={false} screenshotFormat="image/png" selectedDeviceId={123456} />
 *
 * @help        https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API
 *              https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Taking_still_photos
 *
 * @extends React.Component
 *
 */
export default class Webcam extends React.Component<WebcamProps, WebcamState> {
  static defaultProps = {
    audio: false,
    forceScreenshotSourceSize: false,
    imageSmoothing: true,
    mirrored: false,
    onUserMedia: () => undefined,
    onUserMediaError: () => undefined,
    screenshotFormat: "image/webp",
    screenshotQuality: 0.92,
  };

  private errorMessage = {
    PERMISSION_DENIED: "[WEBRTC-ERROR-01]: Permission denied to access camera.",
    NOT_SUPPORTED: "[WEBRTC-ERROR-02]: Device or browser does not support accessing the camera.",
    NO_DEVICES_FOUND: "[WEBRTC-ERROR-03]: No cameras available on this device.",
  };

  private canvas: HTMLCanvasElement | null = null;
  private ctx: CanvasRenderingContext2D | null = null;
  private unmounted = false;

  stream: MediaStream | null;
  video: HTMLVideoElement | null;

  /** Lifecyle methods **/
  constructor(props: WebcamProps) {
    super(props);
    this.state = {
      hasUserMedia: false,
    };
  }

  componentDidMount() {
    const { state, props } = this;

    if (!hasGetUserMedia()) {
      props.onUserMediaError(this.errorMessage.NOT_SUPPORTED);
      return;
    }

    if (!state.hasUserMedia) {
      this.requestUserMedia();
    }
  }

  componentDidUpdate(nextProps: WebcamProps) {
    const { props } = this;

    if (!hasGetUserMedia()) {
      props.onUserMediaError(this.errorMessage.NOT_SUPPORTED);
      return;
    }

    const selectedDeviceIdChanged = nextProps.selectedDeviceId !== props.selectedDeviceId;
    const minScreenshotWidthChanged = nextProps.minScreenshotWidth !== props.minScreenshotWidth;
    const minScreenshotHeightChanged = nextProps.minScreenshotHeight !== props.minScreenshotHeight;

    if (selectedDeviceIdChanged || minScreenshotWidthChanged || minScreenshotHeightChanged) {
      this.canvas = null;
      this.ctx = null;
    }

    if (selectedDeviceIdChanged) {
      this.stopAndCleanup();
      this.requestUserMedia();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.stopAndCleanup();
  }

  /** Private functions **/

  /**
  * Stops the current media stream.
   *
   * @static
   * @param {MediaStream | null} stream - The media stream to be stopped.
   */
  private static stopMediaStream(stream: MediaStream | null) {
    if (stream) {
      if (stream.getVideoTracks && stream.getAudioTracks) {
        stream.getVideoTracks().map(track => {
          stream.removeTrack(track);
          track.stop();
        });
        stream.getAudioTracks().map(track => {
          stream.removeTrack(track);
          track.stop();
        });
      } else {
        ((stream as unknown) as MediaStreamTrack).stop();
      }
    }
  }

  /**
   * Stops the current media stream and cleans up the references.
   *
   */
  private stopAndCleanup() {
    const { state } = this;

    if (state.hasUserMedia) {
      Webcam.stopMediaStream(this.stream);

      if (state.src) {
        window.URL.revokeObjectURL(state.src);
      }
    }
  }

  /**
   * Access the current capture (screenshot) of the webcam.
   *
   * @param {ScreenshotDimensions} [screenshotDimensions] - Specifies the width and height of the image.
   * @returns {string} Base64 Encoded image content in the specified quality.
   */
  getScreenshot(screenshotDimensions?: ScreenshotDimensions) {
    const { state, props } = this;

    if (!state.hasUserMedia) return null;

    const canvas = this.getCanvas(screenshotDimensions);
    return canvas && canvas.toDataURL(props.screenshotFormat, props.screenshotQuality);
  }

  /**
   * Return the canvas element for the current capture.
   *
   * @param {ScreenshotDimensions} [screenshotDimensions] - The width and height of the canvas image.
   * @returns {HTMLCanvasElement} The canvas element for the current capture session.
   */
  getCanvas(screenshotDimensions?: ScreenshotDimensions) {
    const { state, props } = this;

    if (!this.video) {
      return null;
    }

    if (!state.hasUserMedia || !this.video.videoHeight) return null;

    if (!this.ctx) {
      let canvasWidth = this.video.videoWidth;
      let canvasHeight = this.video.videoHeight;
      if (!this.props.forceScreenshotSourceSize) {
        const aspectRatio = canvasWidth / canvasHeight;

        canvasWidth = props.minScreenshotWidth || this.video.clientWidth;
        canvasHeight = canvasWidth / aspectRatio;

        if (props.minScreenshotHeight && canvasHeight < props.minScreenshotHeight) {
          canvasHeight = props.minScreenshotHeight;
          canvasWidth = canvasHeight * aspectRatio;
        }
      }

      this.canvas = document.createElement("canvas");
      this.canvas.width = screenshotDimensions?.width || canvasWidth;
      this.canvas.height = screenshotDimensions?.height || canvasHeight;
      this.ctx = this.canvas.getContext("2d");
    }

    const { ctx, canvas } = this;

    if (ctx && canvas) {
      // Mirror the screenshot
      if (props.mirrored) {
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);
      }

      ctx.imageSmoothingEnabled = props.imageSmoothing;
      ctx.drawImage(
        this.video,
        0,
        0,
        screenshotDimensions?.width || canvas.width,
        screenshotDimensions?.height || canvas.height,
      );

      // Invert mirroring
      if (props.mirrored) {
        ctx.scale(-1, 1);
        ctx.translate(-canvas.width, 0);
      }
    }

    return canvas;
  }



  /**
   * Requests access to the camera on the device and selects the default device to use.
   *
   */
  private requestUserMedia() {
    const { props } = this;

    const sourceSelected = (
      selectedDeviceId: string | undefined,
    ) => {
      const defaultVideoConstraints = { facingMode: { exact: "environment" } };
      const constraints: MediaStreamConstraints = {
        video: selectedDeviceId ? { deviceId: selectedDeviceId } : defaultVideoConstraints,
      }

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          if (this.unmounted) {
            Webcam.stopMediaStream(stream);
          } else {
            this.handleUserMedia(null, stream);
          }
        })
        .catch(e => {
          this.handleUserMedia(e);
        });
    };

    if ("mediaDevices" in navigator) {
      sourceSelected(props.selectedDeviceId);
    } else {
      // @ts-ignore: deprecated api
      MediaStreamTrack.getSources(sources => {
        const videoSource: MediaStreamTrack = sources.find( (source: MediaStreamTrack) => source.kind === "video");

        sourceSelected(videoSource.id);
      });
    }
  }

  /**
   * Handles the detection and connection of the WebRTC MediaStream API
   *
   * @param {any} err - The error, if any occured.
   * @param {MediaStream} [stream] - The MediaStream object.
   */
  private handleUserMedia(err: any, stream?: MediaStream) {
    const { props } = this;

    if (err || !stream) {
      this.setState({ hasUserMedia: false });
      props.onUserMediaError(err);
      return;
    }

    this.stream = stream;

    try {
      if (this.video) {
        this.video.srcObject = stream;
      }
      this.setState({ hasUserMedia: true });
    } catch (error) {
      this.setState({
        hasUserMedia: true,
        src: window.URL.createObjectURL(stream),
      });
    }

    props.onUserMedia(stream);
  }

  /**
   * Renders the Webcam component with props.
   *
   */
  render() {
    const { state, props } = this;

    const {
      audio,
      forceScreenshotSourceSize,
      onUserMedia,
      onUserMediaError,
      screenshotFormat,
      screenshotQuality,
      minScreenshotWidth,
      minScreenshotHeight,
      imageSmoothing,
      mirrored,
      style = {},
      ...rest
    } = props;

    const videoStyle = mirrored
      ? { ...style, transform: `${style.transform || ""} scaleX(-1)` }
      : style;

    return (
      <video
        autoPlay
        src={state.src}
        muted={!audio}
        playsInline
        ref={ref => {
          this.video = ref;
        }}
        style={videoStyle}
        {...rest}
      />
    );
  }
}
