import moment from "moment";

/**
 * Responsible for formating the time from military (11:00:00) to standard (11:00 AM).
 *
 * @exports militaryToStandardTime
 * @returns {String}
 */
export default function militaryToStandardTime(time) {
  return moment(time, "HH:mm:ss").format("h:mm A");
}
