import { createSelector } from "reselect";
import { get, some } from "lodash/fp";
import { getPetServiceItem } from "../entitiesSelector";
import { INVOICE_GENERATED_STATUS } from "../../constants/petServiceItemsConstants";

export const getAddOnsForPetServiceItem = createSelector([getPetServiceItem], petServiceItem =>
  get("addOns", petServiceItem),
);

export const getEnhancedServicesForPetServiceItem = createSelector(
  [getPetServiceItem],
  (petServiceItem) => get("enhancedServices", petServiceItem),
);

export const getAddOnsList = createSelector([getAddOnsForPetServiceItem], (addOns = []) =>
  addOns.map(get("addOnName")),
);

export const getEnhancedServiceName = createSelector(
  [getEnhancedServicesForPetServiceItem],
  (enhancedService) => get("addOnName", enhancedService),
);

export const getHasInvoiceBeenGenerated = createSelector([getPetServiceItem], petServiceItem => {
  const petServiceItemHistory = get("history", petServiceItem);
  const hasInvoiceBeenGenerated = some(
    historyItem => get("eventType", historyItem) === INVOICE_GENERATED_STATUS,
    petServiceItemHistory,
  );

  return hasInvoiceBeenGenerated;
});
