import { connect } from "react-redux";
import BulkPackageOfferingsCartDetailsPricingComponent from "../../../_components/bgm/cartDetails/BulkPackageOfferingsCartDetailsPricingComponent";

const mapStateToProps = () => {
  return {
    componentID: `bulk-package-offerings-cart-details-pricing`,
    isHidden: false,
  };
};

export default connect(mapStateToProps)(BulkPackageOfferingsCartDetailsPricingComponent);
