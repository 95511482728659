import React from "react";
import styled from "styled-components";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export default function FlexRow(props) {
  const { isHidden, children } = props;

  if (!isHidden) {
    return <SectionWrapper {...props}>{children}</SectionWrapper>;
  }

  return null;
}
