import * as actionTypes from "../actionTypes/confirmationModalActionTypes";

export const showConfirmationModal = payload => ({
  type: actionTypes.SHOW_CONFIRMATION_MODAL,
  payload,
});

export const hideConfirmationModal = () => ({
  type: actionTypes.HIDE_CONFIRMATION_MODAL,
});
