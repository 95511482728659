import React, { useState } from "react";

// Components
import { PrismTableHeader } from "@/dux/_components/prismTable/PrismTable";
import { PrismTableHeaderCell } from "@/dux/_components/prismTable/PrismTableHeaderCell";
import { PrismFilterFlyout } from "@/dux/_components/prismFilterFlyout/PrismFilterFlyout";
import { PrismSort } from "@/dux/_components/prismSort/PrismSort";
import { ReportFilterFlyout } from "@/dux/reportTable/reportTableFilterFlyout";

// Utils
import camelCase from "lodash/fp/camelCase";

/**
 *  React view component for PrismTableHeaderRowWithSort
 *  @memberOf Views.Associate
 *  @function
 *  @name PrismTableHeaderRowWithSort
 *  @param {object} props
 *  @param {boolean} props.isHidden
 *  @param {Array} props.headers
 *  @param {Function} props.onHandleSortFn
 *  @param {number} props.initialSortIndex
 *  @returns {JSX.Element}
 *
 *  @example const component = connect()(PrismTableHeaderRowWithSort);
 */

export const PrismTableHeaderRowWithSort = props => {
  const { isHidden, headers = [], onHandleSortFn, initialSortIndex = 0 } = props;

  const [sortColumn, setSortColumn] = useState(initialSortIndex);

  if (isHidden) {
    return null;
  }

  const reportTableHeaders = headers.map((item, index) => {
    return (
      <PrismSort
        className="prism-table-header__sort-cell"
        isActive={sortColumn === index}
        key={index}
        onHandleSortFn={({ sortOrder }) => {
          setSortColumn(index);
          onHandleSortFn({
            sortCell: item,
            sortIndex: index,
            sortKey: camelCase(item),
            sortOrder,
          });
        }}
      >
        <PrismTableHeaderCell>{item}</PrismTableHeaderCell>
      </PrismSort>
    );
  });

  return <PrismTableHeader tableHeaders={reportTableHeaders} />;
};

/**
 *  React view component for PrismTableHeaderRowWithSortAndFilter
 *  @memberOf Views.Associate
 *  @function
 *  @name PrismTableHeaderRowWithSortAndFilter
 *  @param {object} props
 *  @param {boolean} props.isHidden
 *  @param {Array} props.headers
 *  @param {Function} props.onHandleSortFn
 *  @param {number} props.initialSortColumnIndex
 *  @param {Array} props.activeFilters
 *  @returns {JSX.Element}
 *
 *  @example const component = connect()(PrismTableHeaderRowWithSortAndFilter);
 */

export const PrismTableHeaderRowWithSortAndFilter = props => {
  const {
    isHidden,
    headers = [],
    onHandleSortFn,
    initialSortColumnIndex = 0,
    activeFilters = [],
  } = props;

  const [activeSortColumn, setActiveSortColumn] = useState(initialSortColumnIndex);

  if (isHidden) {
    return null;
  }

  const reportTableHeaders = headers.map((item, index) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }} key={index}>
        <PrismFilterFlyout
          isActive={activeFilters.find(item => item.filterArrayIndex === index)}
          flyoutContent={ReportFilterFlyout}
          flyoutContentProps={{ filterKey: camelCase(item), filterArrayIndex: index }}
        >
          <PrismTableHeaderCell>{item}</PrismTableHeaderCell>
        </PrismFilterFlyout>
        <PrismSort
          className="prism-table-header__sort-cell"
          isActive={activeSortColumn === index}
          onHandleSortFn={({ sortOrder }) => {
            setActiveSortColumn(index);
            onHandleSortFn({
              sortCell: item,
              sortIndex: index,
              sortKey: camelCase(item),
              sortOrder,
            });
          }}
        />
      </div>
    );
  });

  return <PrismTableHeader tableHeaders={reportTableHeaders} />;
};
