import { connect } from "react-redux";
import { getMedicationIds } from "../medication/medicationsSelector";
import { medicationTypes, NEW_MEDICATION_ID } from "./newMedicationConstants";
import { getCurrentPet } from "../../core/selectors/persistentSelectors";
import PetProfileMedicationFormComponent from "../common/PetProfileMedicationFormComponent";
import { getMedicationIsShowingDetailsForType } from "../medicationList/medicationListSelectors";

const mapStateToProps = state => {
  const petId = getCurrentPet(state);
  const medicationId = NEW_MEDICATION_ID;
  const locationType = medicationTypes.PROFILE;
  return {
    medicationId,
    petId,
    medicationIds: getMedicationIds(state, { petId }),
    isShowingDetails: getMedicationIsShowingDetailsForType(state, {
      medicationId,
      petId,
      locationType,
    }),
    isHidden: !getMedicationIsShowingDetailsForType(state, { medicationId, petId, locationType }),
  };
};

export default connect(mapStateToProps)(PetProfileMedicationFormComponent);
