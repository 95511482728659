import React from "react";
import { connect } from "react-redux";
import { Button } from "@petsmart-ui/sparky";
import { patchHotelBookingStatusForCart } from "dux/bookHotel/hotelBookingActions";
import { LayoutBox } from "@/layout/box/Box";
import { selectServicesCart } from "dux/servicesCart/servicesCartSelectors";
import { getSaveToProfileFoods, getSaveToProfileMeds } from "./selectors/hotelBookingSelectors";
import {
  createPetMedication,
  updatePetMedication,
} from "@/web/medication/actions/createUpdateDeleteMedicationActions";
import { createPetFood, updatePetFood } from "@/web/food/actions/createUpdateDeleteFoodActions";
import { formatFood } from "@/web/food/foodUtils";
import { formatMedication } from "@/web/medication/medicationUtils";

export const BookButton = props => {
  const { componentId, isHidden, label, variant, disabled } = props;
  const { onClick } = props;

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId}>
      <Button text={label} variant={variant} disabled={disabled} size="lg" onClick={onClick} />
    </LayoutBox>
  );
};

export const HotelBookButton = connect(
  state => {
    const servicesCartId = selectServicesCart(state)?.servicesCartId;

    return {
      componentId: "HotelBookButton",
      label: "Book",
      variant: "primary",
      disabled: !servicesCartId, // if there is a cart id, show button
      isHidden: false,

      servicesCartId,
      saveToProfileMeds: getSaveToProfileMeds(state),
      saveToProfileFoods: getSaveToProfileFoods(state),
    };
  },

  dispatch => {
    const createNewPetMed = ({ medication, petId }) =>
      dispatch(createPetMedication({ medication, petId, isFromBooking: true }));
    const updateExistingPetMed = ({ medication, petId }) =>
      dispatch(updatePetMedication({ medication, petId, isFromBooking: true }));
    const createNewPetFood = ({ food, petId }) =>
      dispatch(createPetFood({ food, petId, isFromBooking: true }));
    const updateExistingPetFood = ({ food, petId }) =>
      dispatch(updatePetFood({ food, petId, isFromBooking: true }));

    return {
      setBookingStatus: ({ servicesCartId }) => {
        dispatch(patchHotelBookingStatusForCart({ servicesCartId }));
      },
      setPetFoods: foods => {
        Object.values(foods).map(food => {
          if (!food) return null;

          const setFoodFn = food?.isNew ? createNewPetFood : updateExistingPetFood;
          return setFoodFn({ food: formatFood(food), petId: food.petId });
        });
      },
      setPetMeds: meds => {
        Object.values(meds).map(med => {
          if (!med) return null;

          const setMedFn = med?.isNew ? createNewPetMed : updateExistingPetMed;
          return setMedFn({ medication: formatMedication(med), petId: med.petId });
        });
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      label,
      variant,
      disabled,
      isHidden,
      servicesCartId,
      saveToProfileMeds,
      saveToProfileFoods,
    } = mapProps;
    const { setBookingStatus, setPetFoods, setPetMeds } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      label,
      variant,
      disabled,
      isHidden,

      // actions to pass to view
      onClick: () => {
        setBookingStatus({ servicesCartId });
        setPetFoods(saveToProfileFoods);
        setPetMeds(saveToProfileMeds);
      },
    };
  },
)(BookButton);
