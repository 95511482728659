import React from "react";
import styled from "styled-components";
import TextArea from "./TextArea";
import { color } from "./styles/theme";

const Container = styled.div`
  display: ${props => (props.display ? props.display : "auto")};
  color: ${props => {
    if (props.error) {
      return "red";
    } else if (props.isFocused) {
      return color.kalEl;
    } else if (props.disabled) {
      return color.grey;
    }
    return "black";
  }};
`;

class TextAreaWithBlur extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      isFocused: false,
    };
  }

  onBlur(value) {
    const { onBlur, currentValue } = this.props;
    if (currentValue !== value) {
      onBlur(value);
    }
    this.setState({ touched: true, isFocused: false });
  }

  onChange(value) {
    const { onBlur } = this.props;
    onBlur(value);
  }

  onFocus() {
    this.setState({ isFocused: true });
  }

  render() {
    const { title, display, isHidden, componentId, error, maxLength, currentValue } = this.props;
    const { touched, isFocused } = this.state;
    const showError = touched && !currentValue && error;
    if (!isHidden) {
      return (
        <Container error={showError} isFocused={isFocused} display={display} id={componentId}>
          {title}
          <TextArea
            {...this.props}
            onBlur={e => this.onBlur(e.target.value)}
            value={currentValue}
            onChange={e => this.onChange(e.target.value)}
            error={showError}
            maxLength={maxLength}
          />
          {error}
        </Container>
      );
    }
    return null;
  }
}

export default TextAreaWithBlur;
