export default {
  LOAD_STORE_SERVICES_FOR_PET: "LOAD_STORE_SERVICES_FOR_PET",
  LOAD_STORE_SERVICES_FOR_PET_REQUEST: "LOAD_STORE_SERVICES_FOR_PET_REQUEST",
  LOAD_STORE_SERVICES_FOR_PET_SUCCESS: "LOAD_STORE_SERVICES_FOR_PET_SUCCESS",
  LOAD_STORE_SERVICES_FOR_PET_FAILURE: "LOAD_STORE_SERVICES_FOR_PET_FAILURE",

  LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET: "LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET",
  LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET_REQUEST:
    "LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET_REQUEST",
  LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET_SUCCESS:
    "LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET_SUCCESS",
  LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET_FAILURE:
    "LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET_FAILURE",

  UPDATe_STORE_SERVICE_FOR_PET_SUCCESS: "UPDATE_STORE_SERVICE_FOR_PET_SUCCESS",

  SET_SELECTED_SERVICE: "SET_SELECTED_SERVICE",

  LOAD_STORE_SERVICES: "LOAD_STORE_SERVICES",
};
