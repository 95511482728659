import { connect } from "react-redux";
import { getRedirectionReason } from "dux/eligibility/selectors/customerCanBookSelectors";
import { history } from "@/dux/utils/browser/browserHistory";

import BookingRedirectionModalComponent from "./BookingRedirectionModalComponent";

const mapStateToProps = state => {
  return {
    redirectionReason: getRedirectionReason(state),
  };
};

const mapDispatchToProps = () => ({
  onRedirection: () => history.push("/dashboard"),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingRedirectionModalComponent);
