// @ts-check

/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_ENHANCED_SERVICE = "SET_ENHANCED_SERVICE";
export const storeCapabilitiesActionTypes = {
  GET_STORE_CAPABILITIES: "store/GET_STORE_CAPABILITIES",
  GET_STORE_CAPABILITIES_REQUEST: "store/GET_STORE_CAPABILITIES_REQUEST",
  GET_STORE_CAPABILITIES_FAILURE: "store/GET_STORE_CAPABILITIES_FAILURE",
  GET_STORE_CAPABILITIES_SUCCESS: "store/GET_STORE_CAPABILITIES_SUCCESS",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 * Action to update the enhanced service addon in the cart
 * @param {string} addOnId - the enhanced service addon id that was selected
 * @param {string} petId - the current pet id
 * @return {{type: string, addOn: string, petId: string}}
 */
export const setEnhancedServicesAddon = (addOn, petId) => ({
  type: SET_ENHANCED_SERVICE,
  addOn,
  petId,
});

export const getStoreCapabilities = ({ storeNumber = '' }) => ({
  type: storeCapabilitiesActionTypes.GET_STORE_CAPABILITIES,
  storeNumber,
});

export const getStoreCapabilitiesRequest = () => ({
  type: storeCapabilitiesActionTypes.GET_STORE_CAPABILITIES_REQUEST,
});

export const getStoreCapabilitiesFailure = ({ error = {} }) => ({
  type: storeCapabilitiesActionTypes.GET_STORE_CAPABILITIES_FAILURE,
  error,
});

export const getStoreCapabilitiesSuccess = ({ result = {} }) => ({
  type: storeCapabilitiesActionTypes.GET_STORE_CAPABILITIES_SUCCESS,
  result,
});
