import { connect } from "react-redux";
import AlertsFormComponent from "web/petParentProfile/alerts/AlertsFormComponent";
import getPrismPetHotelProfile from "./selectors/PrismPetProfilesSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { updateHotelPetAlerts } from "./petAlertsActions";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import parseAlertData from "core/utils/entitiesUtils/parseAlertData";
import { getCurrentPet } from "core/selectors/persistentSelectors";

const mapStateToProps = state => {
  const petId = getCurrentPet(state);
  const customerKey = getCurrentCustomerKey(state);
  const selectedPet = getSelectedPet(state);
  const parsedAlertData = parseAlertData(getPrismPetHotelProfile(state, { petId }));

  return {
    componentID: "alertForm-HotelPetAlerts",
    title: "Pet Alerts",
    isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
    alertsFormData: parsedAlertData,
    showServiceCardAlert: true,

    // here for mergeProps
    customerKey,
    selectedPet,
  };
};

const mapDispatchToProps = dispatch => ({
  handleUpdateHotelPetAlerts: ({ customerKey, selectedPet, values }) => {
    dispatch(
      updateHotelPetAlerts({
        alerts: {
          customerKey,
          petId: selectedPet,
          prismPet: {
            profiles: [
              {
                bookingAlert: values.isBookingAlert ? values.bookingAlertReason : "",
                checkInAlert: values.isCheckInAlert ? values.checkInAlertReason : "",
                checkOutAlert: values.isCheckOutAlert ? values.checkOutAlertReason : "",
                serviceCardAlert: values.isServiceCardAlert ? values.serviceCardAlertReason : "",
                profileType: systemName.DDC_HOTEL,
              },
            ],
          },
        },
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSubmit: values =>
    propsFromDispatch.handleUpdateHotelPetAlerts({
      customerKey: propsFromState.customerKey,
      selectedPet: propsFromState.selectedPet,
      values,
    }),
  keyName: () =>
    propsFromDispatch.functionName({
      keyName: propsFromState.value,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AlertsFormComponent);
