// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Prism Table Styles */
.prism-table {
  border-collapse: collapse;
  width: 100%;
}

.prism-table-header__row,
.prism-table-body__row {
  border-bottom: 1px solid gray;
  text-align: left;
}

.prism-table-header__cell,
.prism-table-body__cell {
  padding: 10px;
}

.prism-table-header__cell {
  cursor: pointer;
}

.prism-table-body__row:nth-child(even) {
  background-color: #eaeaea;
}

/* Prism Table Sorting Styles */
.prism-table-header__cell .prism-table-header__sort-cell svg.MuiSvgIcon-root.active--false {
  color: gray;
  visibility: hidden;
}
.prism-table-header__cell .prism-table-header__sort-cell svg.MuiSvgIcon-root.active--true {
  color: inherit;
  visibility: visible;
}

.prism-table-header__cell:hover .prism-table-header__sort-cell svg.MuiSvgIcon-root {
  visibility: visible;
}

.prism-table-header__sort-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/dux/_components/prismTable/prismTable.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;;EAEE,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,+BAA+B;AAC/B;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":["/* Prism Table Styles */\n.prism-table {\n  border-collapse: collapse;\n  width: 100%;\n}\n\n.prism-table-header__row,\n.prism-table-body__row {\n  border-bottom: 1px solid gray;\n  text-align: left;\n}\n\n.prism-table-header__cell,\n.prism-table-body__cell {\n  padding: 10px;\n}\n\n.prism-table-header__cell {\n  cursor: pointer;\n}\n\n.prism-table-body__row:nth-child(even) {\n  background-color: #eaeaea;\n}\n\n/* Prism Table Sorting Styles */\n.prism-table-header__cell .prism-table-header__sort-cell svg.MuiSvgIcon-root.active--false {\n  color: gray;\n  visibility: hidden;\n}\n.prism-table-header__cell .prism-table-header__sort-cell svg.MuiSvgIcon-root.active--true {\n  color: inherit;\n  visibility: visible;\n}\n\n.prism-table-header__cell:hover .prism-table-header__sort-cell svg.MuiSvgIcon-root {\n  visibility: visible;\n}\n\n.prism-table-header__sort-cell {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
