export const CLUSTER_TOKENS = {
  SPACE: {
    'cluster-space-0': '0px',
    'cluster-space-px': '1px',
    'cluster-space-0.5': '0.125rem',
    'cluster-space-1': '0.25rem',
    'cluster-space-1.5': '0.375rem',
    'cluster-space-2': '0.5rem',
    'cluster-space-2.5': '0.625rem',
    'cluster-space-3': '0.75rem',
    'cluster-space-3.5': '0.875rem',
    'cluster-space-4': '1rem',
    'cluster-space-5': '1.25rem',
    'cluster-space-6': '1.5rem',
    'cluster-space-7': '1.75rem',
    'cluster-space-8': '2rem',
    'cluster-space-9': '2.25rem',
    'cluster-space-10': '2.5rem',
    'cluster-space-11': '2.75rem',
    'cluster-space-12': '3rem',
    'cluster-space-14': '3.5rem',
    'cluster-space-16': '4rem',
    'cluster-space-20': '5rem',
    'cluster-space-24': '6rem',
    'cluster-space-28': '7rem',
    'cluster-space-32': '8rem',
    'cluster-space-36': '9rem',
    'cluster-space-40': '10rem',
    'cluster-space-44': '11rem',
    'cluster-space-48': '12rem',
    'cluster-space-52': '13rem',
    'cluster-space-56': '14rem',
    'cluster-space-60': '15rem',
    'cluster-space-64': '16rem',
    'cluster-space-72': '18rem',
    'cluster-space-80': '20rem',
    'cluster-space-96': '24rem',
  },
};
