/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const setMedicationNameActionTypes = {
  SET_MEDICATION_NAME: "SET_MEDICATION_NAME",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setMedicationName = ({ name, externalId, medicationId, petId }) => ({
  type: setMedicationNameActionTypes.SET_MEDICATION_NAME,
  name,
  externalId,
  medicationId,
  petId,
});
