import { getOr, isEmpty } from "lodash/fp";
import { createSelector } from "reselect";
import { getMedicationById, getMedicationsByPet } from "web/medication/medicationsSelector";
import { frequencyConstants } from "dux/frequency/FrequencyConstants";

export const getMedicationFrequencyById = createSelector(
  [getMedicationsByPet, (state, props) => props],
  (medications, { medicationId }) => getOr(null, [medicationId, "frequency"], medications),
);

export const getMedicationFrequencyDatesById = createSelector(
  [getMedicationsByPet, (state, props) => props],
  (medications, { medicationId }) => getOr(null, [medicationId, "concreteSchedule"], medications),
);

export const getFrequencyIsTouchedById = createSelector([getMedicationById], medication =>
  getOr(false, ["frequencyTouched"], medication),
);

export const getIsFrequencyMissingField = createSelector(
  [getMedicationFrequencyById, getMedicationFrequencyDatesById],
  (frequency, dates) => {
    if (!frequency) {
      return true;
    }
    if (frequency === frequencyConstants.MANUAL) {
      return isEmpty(dates);
    }
    return false;
  },
);
