import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Text } from "@prism/psm-ui-components";

import { getStoreNumber } from "core/selectors/persistentSelectors";
import { hideStoreHoursError } from "@/dux/storeHoursError/storeHoursErrorActions";

import LoadingWrapper from "@/web/common/LoadingWrapper";
import StoreHoursErrorMessageContainer from "@/web/searchSalonSRC/storeHoursErrorMesssage/StoreHoursErrorMessageContainer";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import { searchStoreNumHeaderMessageConstants } from "./searchStoreNumConstants";
import getIsSearchFieldMainV2WorkflowFeatureFlagHidden
  from "web/enableDisableWorkflowFeatureFlag/selectors/searchFieldMainV2/getIsSearchFieldMainV2WorkflowFeatureFlagHidden";
import {
  buildSearchFieldProducts,
  SearchFactoryHotel,
  SearchFactorySalon, SearchFactoryTraining,
} from "dux/searchFieldMain/searchFactory/SearchFactory";
import ResultListContainer from "web/searchSalonSRC/resultList/resultListContainer";
import { getShowResults } from "core/selectors/searchSalonSRCSelector";
import { showSearchResults } from "core/actionCreators/searchSalonActionCreator";

/*
  REACT/VIEW COMPONENT
 */
const  SearchStoreNumStyledPopoverContentComponent = (props) => {

  // props from mapStateToPros
  const {hideStoreHoursError, isHidden, componentID, searchHeader, isLoading, SearchFieldDIComp, hasResults,} = props;

  // props from mapDispatchToProps
  const { resetShowSearchResults } = props;

  if (isHidden) {
    return null;
  }

  useEffect(() => {
    return () => {
      hideStoreHoursError();
      
      /* 
        Need to hide (set showSearchResults in global state to false) search results on initial
        render of the component. This is done so use cases when the user is in src mode has searched for a zip code for
        example but then switches from the salon tab to the hotel tab and clicks the Book reservation button, the previous
        search results do not show.
       */
      resetShowSearchResults();
    };
  }, []);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Layout.Box id={componentID}>
        <Layout.Stack space="stack-space-2" style={{ padding: "1rem", maxWidth: "500px" }}>
          <Text bold size="text-size-xl" fontFamily="text-family-montserrat">
            {searchHeader}
          </Text>
          <SearchFieldDIComp />
          {hasResults ? <ResultListContainer /> : null}
          <StoreHoursErrorMessageContainer />
        </Layout.Stack>
      </Layout.Box>
    </LoadingWrapper>
  );
}

/*
  COMMON PROPS AMONG ALL THE MAP STATE TO PROPS IN EACH CONNECT FUNCTION
 */
const getCommonProps = (state) => {
  return {
    storeNumber: getStoreNumber(state),
    systemBookingFlow: getSystemBookingFlow(state),
    hideStoreHoursError,
  };
};

/*
  REDUX CONNECT FUNCTION FOR SALON TO SHOW SEARCH RESULTS FOR BOOKING IN SRC
 */
export const SearchStoreNumSalonContent = connect(
  (state) => {
    const isFeatureFlagHidden = getIsSearchFieldMainV2WorkflowFeatureFlagHidden(state);
    const search = buildSearchFieldProducts(new SearchFactorySalon());

    return {
      ...getCommonProps(state),
      componentID: "searchStoreNumAndBookingContent_DropDownContent--Salon",
      searchHeader: searchStoreNumHeaderMessageConstants.SALON,
      SearchFieldDIComp: search.bookingSearch.getSearchField(isFeatureFlagHidden),
      hasResults: getShowResults(state),
    };
  },
  (dispatch) => {
    return {
      resetShowSearchResults: () => {
        dispatch(showSearchResults({ showSearchResults: false }));
      }
    }
  }
)(SearchStoreNumStyledPopoverContentComponent);

/*
  REDUX CONNECT FUNCTION FOR HOTEL TO SHOW SEARCH RESULTS FOR BOOKING IN SRC
 */
export const SearchStoreNumHotelContent = connect(
  (state) => {
    const isFeatureFlagHidden = getIsSearchFieldMainV2WorkflowFeatureFlagHidden(state);
    const search = buildSearchFieldProducts(new SearchFactoryHotel());

    return {
      ...getCommonProps(state),
      componentID: "searchStoreNumAndBookingContent_DropDownContent--Hotel",
      searchHeader: searchStoreNumHeaderMessageConstants.HOTEL,
      SearchFieldDIComp: search.bookingSearch.getSearchField(isFeatureFlagHidden),
      hasResults: getShowResults(state),
    };
  },
  (dispatch) => {
    return {
      resetShowSearchResults: () => {
        dispatch(showSearchResults({ showSearchResults: false }));
      }
    }
  },
)(SearchStoreNumStyledPopoverContentComponent);

/*
  REDUX CONNECT FUNCTION FOR TRAINING TO SHOW SEARCH RESULTS FOR BOOKING IN SRC
 */
export const SearchStoreNumTrainingContent = connect(
  (state) => {
    const isFeatureFlagHidden = getIsSearchFieldMainV2WorkflowFeatureFlagHidden(state);
    const search = buildSearchFieldProducts(new SearchFactoryTraining());

    return {
      ...getCommonProps(state),
      componentID: "searchStoreNumAndBookingContent_DropDownContent--Training",
      searchHeader: searchStoreNumHeaderMessageConstants.TRAINING,
      SearchFieldDIComp: search.bookingSearch.getSearchField(isFeatureFlagHidden),
      hasResults: getShowResults(state),
    };
  },
  (dispatch) => {
    return {
      resetShowSearchResults: () => {
        dispatch(showSearchResults({ showSearchResults: false }));
      }
    }
  },
)(SearchStoreNumStyledPopoverContentComponent);

