/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const ddcRoomCapacityActionTypes = {
  LOAD_DDC_ROOM_CAPACITY_SUCCESS: "LOAD_DDC_ROOM_CAPACITY_SUCCESS",
  LOAD_HOTEL_DDC_ROOM_CAPACITY_REQUEST: "LOAD_HOTEL_DDC_ROOM_CAPACITY_REQUEST",
  LOAD_HOTEL_DDC_ROOM_CAPACITY_FAILURE: "LOAD_HOTEL_DDC_ROOM_CAPACITY_FAILURE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const loadDdcRoomCapacitySuccess = ({ ddcCapacity }) => ({
  type: ddcRoomCapacityActionTypes.LOAD_DDC_ROOM_CAPACITY_SUCCESS,
  ddcCapacity,
});

export const loadDdcCapacityRequest = ({ ddcCapacity }) => ({
  type: ddcRoomCapacityActionTypes.LOAD_HOTEL_DDC_ROOM_CAPACITY_REQUEST,
  ddcCapacity,
});

export const loadDdcCapacityFailure = ({ error }) => ({
  type: ddcRoomCapacityActionTypes.LOAD_HOTEL_DDC_ROOM_CAPACITY_FAILURE,
  error,
});
