import { connect } from "react-redux";
import SmallPillButton from "../../../_components/buttons/SmallPillButton";
import { getHotelCheckedOutFilterBy } from "./hotelCheckedOutPetServiceSelector";
import {
  filterByAllCheckedOut,
  filterByDayCampCheckedOut,
  filterByDayCareCheckedOut,
  filterByOverNightCheckedOut,
} from "./hotelCheckedOutFilterButtonActions";

const mapStateToProps = state => {
  const filterBy = getHotelCheckedOutFilterBy(state);

  return {
    isHidden: false,
    componentID: "SmallPillButton_HotelCheckedOutAllFilterButton",
    label: "All",
    selected: filterBy.all,
    disabled: false,
    filterBtnStatus: filterBy.all,
  };
};

const mapDispatchToProps = dispatch => ({
  handleFilterByOverNightCheckedOut: ({ filterBtnStatus }) => {
    if (filterBtnStatus) {
      // toggle to off
      dispatch(filterByAllCheckedOut({ filterBtnStatus: !filterBtnStatus }));

      // set all other buttons to false;
      dispatch(filterByOverNightCheckedOut({ filterBtnStatus: false }));
      dispatch(filterByDayCampCheckedOut({ filterBtnStatus: false }));
      dispatch(filterByDayCareCheckedOut({ filterBtnStatus: false }));
    } else {
      // toggle to on
      dispatch(filterByAllCheckedOut({ filterBtnStatus: !filterBtnStatus }));

      // set all other buttons to true;
      dispatch(filterByOverNightCheckedOut({ filterBtnStatus: true }));
      dispatch(filterByDayCampCheckedOut({ filterBtnStatus: true }));
      dispatch(filterByDayCareCheckedOut({ filterBtnStatus: true }));
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleClick: () =>
    propsFromDispatch.handleFilterByOverNightCheckedOut({
      filterBtnStatus: propsFromState.filterBtnStatus,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SmallPillButton);
