import { connect } from "react-redux";

import { getAccessToken } from "../../core/selectors/persistentSelectors";
import { getInvalidPinError, getRedirectToSetPin } from "../../core/selectors/authSelectors";
import { createLoadingSelector } from "../../core/selectors/utils";
import {
  clearInvalidPinError,
  fetchAuthorizedUsers,
  setOrUpdateUserPin,
  clearRedirectToSetPin,
} from "../../core/actionCreators/authActionCreators";
import authActionTypes from "../../core/actionTypes/authActionTypes";
import SetPinComponent from "./SetPinComponent";

const mapStateToProps = state => ({
  accessToken: getAccessToken(state),
  isLoading: createLoadingSelector([
    authActionTypes.AUTHORIZE_USER,
    authActionTypes.AUTHORIZE_USER_BY_PIN,
    authActionTypes.SET_OR_UPDATE_USER_PIN,
  ])(state),
  isRedirectedToSetPin: getRedirectToSetPin(state),
  invalidPinError: getInvalidPinError(state),
});

const mapDispatchToProps = dispatch => ({
  fetchAuthorizedUsers: () => dispatch(fetchAuthorizedUsers()),
  setOrUpdateUserPin: ({ pin }) => dispatch(setOrUpdateUserPin({ pin })),
  clearInvalidPinError: () => dispatch(clearInvalidPinError()),
  clearRedirectToSetPin: () => dispatch(clearRedirectToSetPin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPinComponent);
