import React, { useEffect } from "react";
import { connect } from "react-redux";
import { EditableSection, Button, ButtonGroup, LoadingIndicator } from "@petsmart-ui/sparky";
import { Layout, Text } from "@prism/psm-ui-components";
import { HotelBookingAlert } from "@/web/hotelAlerts/HotelAlertComponent";
import {
  selectCommonHotelBookingStepData,
  selectHotelBookingPetList,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import { getShouldShowHotelBookingAlertsForMultiPets } from "../hotelEligibility/hotelEligibilitySelectors";
import {
  GET_HOTEL_ELIGIBILITY,
  POST_HOTEL_ELIGIBILITY,
} from "../hotelEligibility/hotelEligibilityActions";
import { commonHotelBookingStepActions } from "@/web/features/hotelBookingFlow/hotelBookingFlowUtils";

const HotelBookingAlertsComponent = ({
  componentID,
  onContinue,
  isHidden,
  canNavigate,
  canShowNoAlerts,
  isEditable,
  isActive,
  onClick,
  selectedPetIds,
  hasAlerts,
  isLoading,
}) => {
  useEffect(() => {
    // If there aren't any alerts then skip past this section and continue on
    if (!hasAlerts && isActive && !isLoading) onContinue();
  }, [isActive, hasAlerts, isLoading]);

  const description = () =>
    canShowNoAlerts && <Text color="text-color-red">No customer or pet alerts at this time.</Text>;

  if (isLoading) {
    return (
      <EditableSection
        buttonText={canNavigate && "View"}
        isActive={isActive}
        onClick={onClick}
        title="Alerts"
      >
        <Layout.Cluster justify="center">
          <LoadingIndicator iconSize="md" />
        </Layout.Cluster>
      </EditableSection>
    );
  }

  return (
    <EditableSection
      buttonText={isEditable && "View"}
      isActive={isActive}
      onClick={onClick}
      title="Alerts"
      description={description()}
    >
      <Layout.Box id={componentID} isHidden={isHidden}>
        {/* Pet Parent Alerts */}
        <HotelBookingAlert />

        {/* Pet Alerts */}
        {selectedPetIds.map(petId => (
          <HotelBookingAlert isPet petId={petId} key={petId} />
        ))}

        <Layout.Box style={{ marginTop: "2rem" }}>
          <ButtonGroup align="right">
            <Button variant="primary" onClick={() => onContinue()} text="Acknowledge" />
          </ButtonGroup>
        </Layout.Box>
      </Layout.Box>
    </EditableSection>
  );
};

export const HotelBookingAlerts = connect(
  (state, { stepIndex }) => {
    const customerKey = getCurrentCustomerKey(state);
    const selectedPetIds = selectHotelBookingPetList(state);
    const hasAlerts = getShouldShowHotelBookingAlertsForMultiPets(
      state,
      customerKey,
      selectedPetIds,
    );

    const isLoading = createLoadingSelector([GET_HOTEL_ELIGIBILITY, POST_HOTEL_ELIGIBILITY])(state);
    const { isActive, canNavigate, openStep, furthestStep } = selectCommonHotelBookingStepData(
      stepIndex,
    )(state);

    return {
      componentID: "hotelBookingFlow-alerts",
      canNavigate,
      canShowNoAlerts: !hasAlerts && canNavigate,
      isEditable: hasAlerts && canNavigate,
      isActive,
      selectedPetIds,
      hasAlerts,
      isLoading,
      openStep,
      furthestStep,
    };
  },
  commonHotelBookingStepActions, // returns setStep & onContinue
  (stateProps, dispatchProps, { stepIndex }) => {
    const {
      componentID,
      canNavigate,
      canShowNoAlerts,
      isEditable,
      isActive,
      selectedPetIds,
      hasAlerts,
      isLoading,
      openStep,
      furthestStep,
    } = stateProps;
    const { setStep, onContinue } = dispatchProps;

    return {
      componentID,
      canNavigate,
      canShowNoAlerts,
      isEditable,
      isActive,
      selectedPetIds,
      hasAlerts,
      isLoading,
      onClick: () => setStep(stepIndex),
      onContinue: () => onContinue({ openStep, furthestStep }),
    };
  },
)(HotelBookingAlertsComponent);
