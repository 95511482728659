import React from "react";
import styled from "styled-components";
import List from "../../common/List";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
`;

const Info = styled.div`
  padding: 1px;
`;

export default function AppointmentInfo(props) {
  // Component JSX
  const { appointmentNumber, status, store, addOnsList = [], enhancedServiceName = null } = props;
  return (
    <SectionWrapper>
      <Info>Appointment #: {appointmentNumber}</Info>
      <Info>Status: {status}</Info>
      <Info>Store: {store}</Info>
      {enhancedServiceName && (
        <>
          <Info>Enhanced Service:</Info>
          {/* using a list despite there only being a single string to get the styles for free to match the addons */}
          <List paddingBottom=".75em" items={[enhancedServiceName]} />
        </>
      )}
      {addOnsList.length > 0 && (
        <>
          <Info>Add-Ons:</Info>
          <List paddingBottom=".75em" items={addOnsList} />
        </>
      )}
    </SectionWrapper>
  );
}
