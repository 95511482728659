import { createSelector } from "reselect";
import { getHotelCheckedOutAppointments } from "../../columnList/hotelCheckedOutSelector";
import getSpeciesCount from "../../../hotelArrivals/columnHeader/speciesCount/getSpeciesCount";

export const getHotelCheckedOutSpeciesCount = createSelector(
  [getHotelCheckedOutAppointments],
  appointments => {
    return getSpeciesCount(appointments);
  },
);
