import { connect } from "react-redux";
import { FlashMessage } from "@/dux//flashMessage/FlashMessage";
import { getIsRescheduleTrainingAppointmentFlashMessageOpen } from "@/dux/manageTrainingClassPage/manageTrainingClassPageSelectors";
import { alertThemesTypes } from "@/dux/alert/utils";
import { toggleRescheduleTrainingAppointmentFlashMessage } from "../manageTrainingClassPage/manageTrainingClassPageActions";

export const RescheduleTrainingAppointmentFlashMessage = connect(
  (state) => ({
    theme: alertThemesTypes.SUCCESS,
    display: getIsRescheduleTrainingAppointmentFlashMessageOpen(state),
    message: "Training appointment successfully rescheduled",
  }),
  (dispatch) => ({
    toggleRescheduleTrainingAppointmentFlashMessage: () => {
      dispatch(toggleRescheduleTrainingAppointmentFlashMessage());
    },
  }),
  (stateProps, dispatchProps) => {
    const { toggleRescheduleTrainingAppointmentFlashMessage } = dispatchProps;
    return {
      ...stateProps,
      onClose: () => {
        toggleRescheduleTrainingAppointmentFlashMessage();
      },
    };
  },
)(FlashMessage);
