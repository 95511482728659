import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

// Actions
import {
  removeWeeklyEdit,
  setNewWeeklyEdit,
  setWeeklyEditDayActivity,
} from "@/core/actionCreators/schedulesActionCreators";

// Components
import { Form } from "@prism/psm-ui-components";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { TextPassage } from "@petsmart-ui/sparky";

// Constants
import { dayActivityTypes, petSupportedTypes } from "@/core/constants/schedulesConstants";
import { weekDaysNames } from "@/core/constants";

// Selectors
import { getLunchBreakByStore } from "@/core/selectors/schedulesSelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";

// Utils
import { createSplitShift } from "@/core/utils/schedulesUtils/schedulesUtils";

/**
 *  React view component for the split shift checkboxes in the associate schedule table
 *  @memberOf Views.Associate
 *  @function
 *  @name SplitShiftCheckboxComponent
 *  @param {Object} props - props passed into the view component
 *  @param {String} props.componentId
 *  @param {Boolean} props.isHidden
 *  @param {Boolean} props.isRelocationOrAbsence
 *  @param {Boolean} props.hasMultipleWorkHours
 *  @param {Function} props.onChange
 *  @param {Function} props.clearSplitShift
 *  @returns {JSX.Element}
 *  @example const component = connect()(SplitShiftCheckboxComponent)
 */
const SplitShiftCheckboxComponent = ({
  componentId,
  isHidden,
  isRelocationOrAbsence,
  hasMultipleWorkHours,
  onChange,
  clearSplitShift,
}) => {
  useEffect(() => {
    // If we have multiple work hours but shouldn't then remove the 2nd shift
    if (isRelocationOrAbsence && hasMultipleWorkHours) {
      clearSplitShift();
    }
  }, [isRelocationOrAbsence, hasMultipleWorkHours]);

  if (isHidden) return null;

  return (
    <LayoutCluster id={componentId}>
      <Form.Field.Checkbox checked={hasMultipleWorkHours} changed={onChange} />
      <TextPassage>Split into 2 shifts</TextPassage>
    </LayoutCluster>
  );
};

/**
 * Redux Connect function for the SplitShiftCheckboxComponent
 * @see {@link Views.Associate.SplitShiftCheckboxComponent}
 * @summary Located on the associate-scheduling page
 * @memberOf Views.Associate
 * @function
 * @name SplitShiftCheckbox
 * @param {Object} props
 * @param {String} props.dayOfWeek
 * @param {Object[]} props.workHours - array of day activities containing work hours
 * @param {Object[]} props.lunchHours - array of day activities containing lunch break
 * @returns {JSX.Element|null}
 * @example <SplitShiftCheckbox />
 */
export const SplitShiftCheckbox = connect(
  (state, { dayOfWeek, workHours, lunchHours }) => {
    const storeNumber = getStoreNumber(state);
    const lunchBreakTimes = getLunchBreakByStore(state, { storeNumber });
    const type = workHours[0]?.type;
    const isRelocationOrAbsence =
      type === dayActivityTypes.ABSENCE || type === dayActivityTypes.RELOCATION;
    const hasMultipleWorkHours = workHours?.length > 1;
    const isWeekday = weekDaysNames.indexOf(dayOfWeek) < 5;

    return {
      componentId: `SplitShiftCheckbox_${dayOfWeek}`,
      isHidden: isRelocationOrAbsence || !lunchHours || !isWeekday,
      minShiftTime: lunchBreakTimes ? Math.floor(lunchBreakTimes.minShiftLength / 60) : 0, //converting minShiftTime to hours
      isRelocationOrAbsence,
      hasMultipleWorkHours,
    };
  },
  dispatch => ({
    setDayActivityIndex: ({ localId, shiftIndex, isSplitShift }) =>
      dispatch(setWeeklyEditDayActivity({ localId, shiftIndex, isSplitShift })),
    dispatchSetNewWeeklyEdit: (minShiftTime, { localId, endTime, storeId, type, dayOfWeek }) => {
      const momentNewStartTime = moment(endTime, "HH:mm:ss");
      const extendedEndTime = momentNewStartTime
        .hour(momentNewStartTime.hour() + minShiftTime)
        .format("HH:mm:ss"); //adding minShiftTime to the new shift

      dispatch(
        setNewWeeklyEdit({
          localId,
          petSupportedType: [petSupportedTypes.DOG],
          startTime: endTime,
          endTime: extendedEndTime, // adding 2 hours for the new shift created, which can be changed.
          type: type === dayActivityTypes.RELOCATION ? type : dayActivityTypes.WORK,
          storeId,
          dayOfWeek,
        }),
      );
    },
    dispatchRemoveWeeklyEdit: ({ localId, dayOfWeek }) =>
      dispatch(removeWeeklyEdit({ localId, dayOfWeek })),
  }),
  (stateProps, dispatchProps, { workHours }) => {
    const {
      componentId,
      isHidden,
      minShiftTime,
      isRelocationOrAbsence,
      hasMultipleWorkHours,
    } = stateProps;
    const {
      setDayActivityIndex,
      dispatchSetNewWeeklyEdit,
      dispatchRemoveWeeklyEdit,
    } = dispatchProps;

    const clearSplitShift = () => {
      const [first, second] = workHours;
      setDayActivityIndex({ localId: first?.localId, isSplitShift: null, shiftIndex: null });
      dispatchRemoveWeeklyEdit(second);
    };

    return {
      componentId,
      isHidden,
      isRelocationOrAbsence,
      hasMultipleWorkHours,
      clearSplitShift,
      onChange: checked => {
        const [firstShift] = workHours;
        if (checked) {
          const [updatedFirstShift, newSecondShift] = createSplitShift(firstShift);
          setDayActivityIndex(updatedFirstShift);
          dispatchSetNewWeeklyEdit(minShiftTime, newSecondShift);
        } else {
          clearSplitShift();
        }
      },
    };
  },
)(SplitShiftCheckboxComponent);
