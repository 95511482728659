import {
  GET_HOTEL_ELIGIBILITY_SUCCESS,
  POST_HOTEL_ELIGIBILITY_SUCCESS,
} from "dux/hotelEligibility/hotelEligibilityActions";
import uiActionTypes from "core/actionTypes/ui/uiActionTypes";

const initialState = {};

const hotelEligibilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTEL_ELIGIBILITY_SUCCESS:
    case POST_HOTEL_ELIGIBILITY_SUCCESS:
      return {
        ...action.payload,
      };
    case uiActionTypes.CLEAR_UI_STORE:
      return initialState;
    default:
      return state;
  }
};

export default hotelEligibilityReducer;
