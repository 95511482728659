import { connect } from "react-redux";
import { createLoadingSelector } from "../../../core/selectors/utils";
import addOnsActionTypes from "../../../core/actionTypes/addOnsActionTypes";
import BookingAddonsComponent from "./BookingAddonsComponent";

const mapStateToProps = state => ({
  isLoading: createLoadingSelector([addOnsActionTypes.LOAD_ADDONS])(state)
});

export default connect(mapStateToProps)(BookingAddonsComponent);
