import { createSelector } from "reselect";
import filterAppointmentBySelectedDate from "../../../core/utils/filterUtils/filterAppointmentBySelectedDate";
import filterAppointmentsByPetNameRoomOwner from "../../../core/utils/filterUtils/filterAppointmentsByPetNameRoomOwner";
import filterAppointmentsByFilterType from "../../../core/utils/filterUtils/filterAppointmentsByFilterType";
import { sortByPropertyAsc } from "@/core/utils/arrayUtils/sortByProperty";

export const getHotelDeparturesState = state => {
  return state.hotelDepartures;
};

export const getHotelDeparturesAppointmentsState = state => {
  return state.hotelDepartures.appointments;
};

export const getHotelDeparturesAppointments = createSelector(
  [getHotelDeparturesState],
  ({ appointments, searchText, filterBy }) => {
    const filteredBySearchText = filterAppointmentsByPetNameRoomOwner(appointments, searchText);
    const filteredByFilterType = filterAppointmentsByFilterType(filteredBySearchText, filterBy);
    const filteredAndSortedAppointments = sortByPropertyAsc(
      filteredByFilterType,
      "checkOutDateTime",
    );

    return filteredAndSortedAppointments;
  },
);

export const getHotelDeparturesAppointmentsByDate = createSelector(
  [getHotelDeparturesState, (state, props) => props],
  (departures, { date }) => {
    return filterAppointmentBySelectedDate(departures.appointments, date);
  },
);
