import React from 'react';
import './styles.css';
import { pluck } from '../utils';
import { GLOBAL_TOKENS } from '../scaleToken';

// Sets the interface for the Box layout custom properties.
interface BoxProps<C extends React.ElementType> {

  /** A CSS padding value */
  padding?: keyof typeof GLOBAL_TOKENS['SCALE'];

  /** A boolean or CSS border-width value */
  borderWidth?: keyof typeof GLOBAL_TOKENS['SCALE'];

  /** A CSS border-color value */
  borderColor?: keyof typeof GLOBAL_TOKENS['COLOR'];

  /** A CSS border-radius value */
  borderRadius?: keyof typeof GLOBAL_TOKENS['SCALE'];

  /** A CSS background-color value */
  backgroundColor?: keyof typeof GLOBAL_TOKENS['COLOR'];
}

// Include types that are not part of BoxProps, such as children,
type Props <C extends React.ElementType> = React.PropsWithChildren<BoxProps<C>> & React.ComponentPropsWithoutRef<C>;

export const LayoutBox = <C extends React.ElementType>({
  padding = 'scale-S1',
  backgroundColor = 'inherit',
  borderWidth = 'scale-0',
  borderColor = 'black',
  borderRadius = 'scale-0',
  children,
  style,
  ...props

}: Props<C>): React.ReactElement => {
  return (
    <div
      className="pplt-layout__box"
      style={{
        '--setBoxPadding': pluck(GLOBAL_TOKENS.SCALE, padding),
        '--setBorderWidth': pluck(GLOBAL_TOKENS.SCALE, borderWidth),
        '--setBorderColor': pluck(GLOBAL_TOKENS.COLOR, borderColor),
        '--setBorderRadius': pluck(GLOBAL_TOKENS.SCALE, borderRadius),
        '--setBackgroundColor': pluck(GLOBAL_TOKENS.COLOR, backgroundColor),
        ...style,
      }}

    {...props}
    >
        {children}
    </div>
  );
};
