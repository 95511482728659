/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
/**
 * @memberOf actions.hotel.itinerary
 * @constant
 * type {String}
 */
export const PUT_HOTEL_INVOICE_PAID = "PUT_HOTEL_INVOICE_PAID";

/**
 * @memberOf actions.hotel.itinerary
 * @constant
 * type {String}
 */
export const PUT_HOTEL_INVOICE_PAID_REQUEST = "PUT_HOTEL_INVOICE_PAID_REQUEST";

/**
 * @memberOf actions.hotel.itinerary
 * @constant
 * type {String}
 */
export const PUT_HOTEL_INVOICE_PAID_SUCCESS = "PUT_HOTEL_INVOICE_PAID_SUCCESS";

/**
 * @memberOf actions.hotel.itinerary
 * @constant
 * type {String}
 */
export const PUT_HOTEL_INVOICE_PAID_FAILURE = "PUT_HOTEL_INVOICE_PAID_FAILURE";

/**
 * @memberOf actions.hotel.itinerary
 * @constant
 * type {String}
 */
export const PUT_HOTEL_INVOICE_VOID_TRANSACTION = "PUT_HOTEL_INVOICE_VOID_TRANSACTION";

/**
 * @memberOf actions.hotel.itinerary
 * @constant
 * type {String}
 */
export const PUT_HOTEL_INVOICE_VOID_TRANSACTION_REQUEST =
  "PUT_HOTEL_INVOICE_VOID_TRANSACTION_REQUEST";

/**
 * @memberOf actions.hotel.itinerary
 * @constant
 * type {String}
 */
export const PUT_HOTEL_INVOICE_VOID_TRANSACTION_SUCCESS =
  "PUT_HOTEL_INVOICE_VOID_TRANSACTION_SUCCESS";

/**
 * @memberOf actions.hotel.itinerary
 * @constant
 * type {String}
 */
export const PUT_HOTEL_INVOICE_VOID_TRANSACTION_FAILURE =
  "PUT_HOTEL_INVOICE_VOID_TRANSACTION_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 * Put hotel payment action to set invoice as paid
 * @memberOf actions.hotel.itinerary
 * @function
 * @name putHotelInvoicePaid
 * @returns {Object}
 */
export const putHotelInvoicePaid = paymentOrigin => ({
  type: PUT_HOTEL_INVOICE_PAID,
  paymentOrigin,
});

/**
 * Hotel payment request action
 * @memberOf actions.hotel.itinerary
 * @function
 * @name putHotelInvoicePaidRequest
 * @returns {Object}
 */
export const putHotelInvoicePaidRequest = () => ({
  type: PUT_HOTEL_INVOICE_PAID_REQUEST,
});

/**
 * Hotel payment success action
 * @memberOf actions.hotel.itinerary
 * @function
 * @name putHotelInvoicePaidSuccess
 * @returns {Object}
 */
export const putHotelInvoicePaidSuccess = () => ({
  type: PUT_HOTEL_INVOICE_PAID_SUCCESS,
});

/**
 * Hotel payment failure action
 * @memberOf actions.hotel.itinerary
 * @function
 * @name putHotelInvoicePaidFailure
 * @param {Object} error
 * @returns {Object}
 * @example putHotelInvoicePaidFailure(error)
 */
export const putHotelInvoicePaidFailure = error => ({
  type: PUT_HOTEL_INVOICE_PAID_FAILURE,
  error,
});

/**
 * Put hotel payment action to set invoice as void transaction
 * @memberOf actions.hotel.itinerary
 * @function
 * @name putHotelInvoiceVoidTransaction
 * @returns {Object}
 */
export const putHotelInvoiceVoidTransaction = paymentOrigin => ({
  type: PUT_HOTEL_INVOICE_VOID_TRANSACTION,
  paymentOrigin,
});

/**
 * Hotel payment request action
 * @memberOf actions.hotel.itinerary
 * @function
 * @name putHotelInvoiceVoidTransactionRequest
 * @returns {Object}
 */
export const putHotelInvoiceVoidTransactionRequest = () => ({
  type: PUT_HOTEL_INVOICE_VOID_TRANSACTION_REQUEST,
});

/**
 * Hotel payment success action
 * @memberOf actions.hotel.itinerary
 * @function
 * @name putHotelInvoiceVoidTransactionSuccess
 * @returns {Object}
 */
export const putHotelInvoiceVoidTransactionSuccess = () => ({
  type: PUT_HOTEL_INVOICE_PAID_SUCCESS,
});

/**
 * Hotel payment failure action
 * @memberOf actions.hotel.itinerary
 * @function
 * @name putHotelInvoiceVoidTransactionFailure
 * @param {Object} error
 * @returns {Object}
 * @example putHotelInvoiceVoidTransactionFailure(error)
 */
export const putHotelInvoiceVoidTransactionFailure = error => ({
  type: PUT_HOTEL_INVOICE_VOID_TRANSACTION_FAILURE,
  error,
});
