import React from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { TextPassage } from "@petsmart-ui/sparky";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { columnHeaders } from "core/constants/dashboardContants";
import { createLoadingSelector } from "core/selectors/utils";
import { hotelDashBoardActionTypes } from "dux/dashboard/hotel/hotelDashboardActions";
import HotelArrivalsSpeciesCountContainer from "dux/hotelArrivals/columnHeader/speciesCount/HotelArrivalsSpeciesCountContainer";
import HotelArrivalsHeaderSearchContainer from "dux/hotelArrivals/columnHeader/search/HotelArrivalsHeaderSearchContainer";
import HotelArrivalsListFiltersContainer from "dux/hotelArrivals/columnHeader/filterButtons/HotelArrivalsListFiltersContainer";
import GenericTitle from "dux/_components/genericLayoutComponents/GenericTitle";
import HotelCheckedOutSpeciesCountContainer from "dux/hotelCheckedOut/columnHeader/speciesCount/HotelCheckedOutSpeciesCountContainer";
import HotelCheckedOutHeaderSearchContainer from "dux/hotelCheckedOut/columnHeader/search/HotelCheckedOutHeaderSearchContainer";
import HotelCheckedOutListFiltersContainer from "dux/hotelCheckedOut/columnHeader/filterButtons/HotelCheckedOutListFiltersContainer";
import HotelDeparturesSpeciesCountContainer from "dux/hotelDepartures/columnHeader/speciesCount/HotelDeparturesSpeciesCountContainer";
import HotelDeparturesHeaderSearchContainer from "dux/hotelDepartures/columnHeader/search/HotelDeparturesHeaderSearchContainer";
import HotelDeparturesListFiltersContainer from "dux/hotelDepartures/columnHeader/filterButtons/HotelDeparturesListFiltersContainer";
import HotelOverNightSpeciesCountContainer from "dux/hotelOvernight/columnHeader/speciesCount/HotelOverNightSpeciesCountContainer";
import HotelOverNightHeaderSearchContainer from "dux/hotelOvernight/columnHeader/search/HotelOverNightHeaderSearchContainer";
import HotelOverNightListFiltersContainer from "dux/hotelOvernight/columnHeader/filterButtons/HotelOverNightListFiltersContainer";
import {
  HotelCheckOutToggle,
  HotelDeparturesToggle,
} from "dux/_components/styledPopovers/FlyoutToggleWithTitle";
import { getCheckedOutColumnVisibility } from "@/dux/hotelCheckedOut/columnHeader/columnSwitch/hotelCheckedOutColumnToggleSelector";

/**
 * NOTE this react component was originally
 * packages/associate-ui/src/dux/_components/titleCard/HotelTitleCard.js
 * and was deprecated and deleted as of ticket SVCSART-22841
 */
export const TitleCard = props => {
  const { isHidden } = props;
  if (isHidden) return null;

  const { componentId, title, diComp, compName, isLoading } = props;

  const SpeciesCount = diComp.speciesCount[compName];
  const Search = diComp.search[compName];
  const FilterBtns = diComp.filterBtns[compName];
  const Title = diComp.title[compName];

  return (
    <LayoutBox
      id={componentId}
      padding="scale-S1"
      borderWidth="scale-S6"
      borderColor="lightGrey"
      style={{ minHeight: "146px" }}
    >
      <LayoutStack space="scale-S1">
        {/* TITLE */}
        <LayoutBox padding="scale-0">
          <LayoutCluster>
            <LayoutBox padding="scale-0">
              <Title title={title} />
            </LayoutBox>

            <LayoutBox padding="scale-0">
              {isLoading && (
                <LayoutCluster style={{ justifyContent: "space-between" }}>
                  <TextPassage>
                    <p>Loading...</p>
                  </TextPassage>
                </LayoutCluster>
              )}
            </LayoutBox>
          </LayoutCluster>
        </LayoutBox>

        {/* SPECIES COUNT */}
        <LayoutBox padding="scale-0">
          <SpeciesCount />
        </LayoutBox>

        {/* SEARCH AND FILTER */}
        <LayoutBox padding="scale-0">
          <LayoutStack space="scale-S1">
            <LayoutBox padding="scale-0">
              <Search />
            </LayoutBox>

            <LayoutBox padding="scale-0" style={{ minHeight: "42px" }}>
              <FilterBtns />
            </LayoutBox>
          </LayoutStack>
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

/**
 * NOTE this redux container was originally
 * packages/associate-ui/src/dux/hotelArrivals/columnHeader/HotelArrivalsHeaderContainer.js
 * and was deprecated and deleted as of ticket SVCSART-22841
 */
export const HotelArrivalsHeader = connect(state => {
  return {
    componentId: "HotelArrivals",
    isHidden: false,
    componentID: "HotelTitleCard__HotelArrivalsHeader",
    isLoading: createLoadingSelector([hotelDashBoardActionTypes.LOAD_PETS_HOTEL_DAILY_DASHBOARD])(
      state,
    ),
    diComp: {
      speciesCount: {
        [columnHeaders.ARRIVALS]: HotelArrivalsSpeciesCountContainer,
      },
      search: {
        [columnHeaders.ARRIVALS]: HotelArrivalsHeaderSearchContainer,
      },
      filterBtns: {
        [columnHeaders.ARRIVALS]: HotelArrivalsListFiltersContainer,
      },
      title: {
        [columnHeaders.ARRIVALS]: GenericTitle,
      },
    },
    compName: columnHeaders.ARRIVALS,
    title: "Arrivals",
  };
})(TitleCard);

/**
 * NOTE this redux container was originally
 * packages/associate-ui/src/dux/hotelCheckedOut/columnHeader/HotelCheckedOutHeaderContainer.js
 * and was deprecated and deleted as of ticket SVCSART-22841
 */
export const HotelCheckedOutHeader = connect(state => {
  return {
    componentId: "HotelCheckedOutHeader",
    isHidden: getCheckedOutColumnVisibility(state),
    isLoading: createLoadingSelector([hotelDashBoardActionTypes.LOAD_PETS_HOTEL_DAILY_DASHBOARD])(
      state,
    ),
    diComp: {
      speciesCount: {
        [columnHeaders.CHECKED_OUT]: HotelCheckedOutSpeciesCountContainer,
      },
      search: {
        [columnHeaders.CHECKED_OUT]: HotelCheckedOutHeaderSearchContainer,
      },
      filterBtns: {
        [columnHeaders.CHECKED_OUT]: HotelCheckedOutListFiltersContainer,
      },
      title: {
        [columnHeaders.CHECKED_OUT]: HotelCheckOutToggle,
      },
    },
    compName: columnHeaders.CHECKED_OUT,
    title: "Checked Out",
  };
})(TitleCard);

/**
 * NOTE this redux container was originally
 * packages/associate-ui/src/dux/hotelDepartures/columnHeader/HotelDeparturesHeaderContainer.js
 * and was deprecated and deleted as of ticket SVCSART-22841
 */
export const HotelDeparturesHeader = connect(state => {
  return {
    componentId: "HotelDeparturesHeader",
    isHidden: !getCheckedOutColumnVisibility(state),
    isLoading: createLoadingSelector([hotelDashBoardActionTypes.LOAD_PETS_HOTEL_DAILY_DASHBOARD])(
      state,
    ),
    diComp: {
      speciesCount: {
        [columnHeaders.DEPARTURES]: HotelDeparturesSpeciesCountContainer,
      },
      search: {
        [columnHeaders.DEPARTURES]: HotelDeparturesHeaderSearchContainer,
      },
      filterBtns: {
        [columnHeaders.DEPARTURES]: HotelDeparturesListFiltersContainer,
      },
      title: {
        [columnHeaders.DEPARTURES]: HotelDeparturesToggle,
      },
    },
    compName: columnHeaders.DEPARTURES,
    title: "Departures",
  };
})(TitleCard);

/**
 * NOTE this redux container was originally
 * packages/associate-ui/src/dux/hotelOvernight/columnHeader/HotelOvernightHeaderContainer.js
 * and was deprecated and deleted as of ticket SVCSART-22841
 */
export const HotelOvernightHeader = connect(state => {
  return {
    componentId: "HotelOvernightHeader",
    isHidden: false,
    isLoading: createLoadingSelector([hotelDashBoardActionTypes.LOAD_PETS_HOTEL_DAILY_DASHBOARD])(
      state,
    ),
    diComp: {
      speciesCount: {
        [columnHeaders.OVERNIGHT]: HotelOverNightSpeciesCountContainer,
      },
      search: {
        [columnHeaders.OVERNIGHT]: HotelOverNightHeaderSearchContainer,
      },
      filterBtns: {
        [columnHeaders.OVERNIGHT]: HotelOverNightListFiltersContainer,
      },
      title: {
        [columnHeaders.OVERNIGHT]: GenericTitle,
      },
    },
    compName: columnHeaders.OVERNIGHT,
    title: "Over Night",
  };
})(TitleCard);
