import React from "react";
import { connect } from "react-redux";
import { showHeaderModal } from "../../../core/actionCreators/ui/web/appHeaderActionCreators";
import { modalTypes } from "../../../core/constants/modalConstants";
import { getModalType } from "../../../core/selectors/modals/appheaderModalSelectors";
import { setSearchComponentName } from "../../../core/actionCreators/searchSalonActionCreator";
import {
  logoutUser,
  logoutStore,
  clearSessionToken,
} from "../../../core/actionCreators/authActionCreators";
import {
  getAssociateNumber,
  getJobRole,
  hasManagerPrivileges,
  getUsername,
  getStoreNumber,
  getOktaEmail,
  getDisplayName,
} from "../../../core/selectors/persistentSelectors";
import ProfileButtonComponent from "./profileButtonComponent";
import getHotelWorkflowFeatureFlag from "../../enableDisableWorkflowFeatureFlag/selectors/hotel/getHotelWorkflowFeatureFlag";
import { showConfirmationModal } from "../../../core/actionCreators/confirmationModalActionCreators";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import version from "../../../../../../package.json";

const mapStateToProps = state => {
  const featureFlag = getHotelWorkflowFeatureFlag(state);
  return {
    username: getUsername(state),
    displayName: getDisplayName(state),
    oktaEmail: getOktaEmail(state),
    storeNumber: getStoreNumber(state),
    profileImage: "https://via.placeholder.com/32x32",
    modalType: getModalType(state),
    associateJobCode: getJobRole(state),
    isManager: hasManagerPrivileges(state),
    currentAssociateId: getAssociateNumber(state),
    isSVCS727Enabled: !featureFlag.isHidden,
    systemType: getSystemBookingFlow(state),
    versionNumber: version,
    hotelFeatureFlagIsOn: featureFlag.isHidden,
  };
};

const mapDispatchToProps = dispatch => ({
  onProfile: () => {},
  onLogout: () => {
    dispatch(logoutUser({ browserSessionOnly: false }));
  },
  exitPrism: () => {
    dispatch(logoutStore());
  },
  lockUser: () => dispatch(clearSessionToken()),
  showHotelIconModal: () => {
    dispatch(showHeaderModal(modalTypes.ICON_HOTEL_KEY));
  },
  showSalonIconModal: () => {
    dispatch(showHeaderModal(modalTypes.ICON_SALON_KEY));
  },
  showConfirmationModal: args => dispatch(showConfirmationModal(args)),
  setSearchComponentName: searchComponentName =>
    dispatch(setSearchComponentName({ searchComponentName })),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  showModals: () => {
    const { systemType } = propsFromState;
    if (systemType === systemName.HOTEL) {
      propsFromDispatch.showHotelIconModal();
    } else {
      propsFromDispatch.showSalonIconModal();
    }
  },

  exitPrism: () => {
    propsFromDispatch.showConfirmationModal({
      header: "Exit PRISM",
      content: (
        <>
          Are you sure?
          <br />
          This will log all users out of PRISM.
        </>
      ),
      confirmText: `Exit PRISM`,
      confirm: () => propsFromDispatch.exitPrism(),
      cancelText: "Cancel",
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProfileButtonComponent);
