import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import { isEqual } from "lodash/fp";
import { getPetsByCustomer, getPetById } from "../selectors/entitiesSelector";
import petsActionTypes from "../actionTypes/petsActionTypes";
import * as petsActionCreators from "../actionCreators/petsActionCreators";
import * as customersActionCreators from "../actionCreators/customersActionCreators";
import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";
import { getSourceId } from "../selectors/persistentSelectors";
import {
  fetchPet,
  fetchPetsByCustomer,
  postPet,
  putPet,
} from "../services/associateWeb/petEndPoints";
import { deletePet, fetchPetsByIds } from "../services/associateWebProfile/petsEndPoints";
import { getSystemBookingFlow } from "../../web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../../web/setSystemType/constants/setSystemTypeConstants";
import * as eligibilityActionCreators from "dux/eligibility/actions/eligibilityLoadClearActions";
import { getPetParentScreenEligibility } from "@/dux/eligibility/actions/eligibilityByScreenActions";
import { getPetEligibilityAfterChange } from "@/dux/eligibility/actions/eligibilityPetActions";

function* onLoadPet({ petId, customerKey }) {
  try {
    yield put(petsActionCreators.loadPetRequest());
    const response = yield call(fetchPet, { petId, customerKey });
    const pet = response.data;
    yield put(petsActionCreators.loadPetSuccess({ pet }));
  } catch (error) {
    yield put(petsActionCreators.loadPetFailure({ error }));
  }
}

function* onLoadPets({ petIds, previousNotProcessedPets = [] }) {
  try {
    yield put(petsActionCreators.loadPetsRequest());
    const response = yield call(fetchPetsByIds, { petIds });
    const pets = response.data && response.data.length > 0 && response.data[0].Pets;
    const notProcessedPets =
      response.data && response.data.length > 0 && response.data[0].notProcessed;

    // If the API limit is exceeded
    if (
      notProcessedPets &&
      notProcessedPets.length > 0 &&
      !isEqual(notProcessedPets, previousNotProcessedPets)
    ) {
      yield call(onLoadPets, {
        petIds: notProcessedPets,
        previousNotProcessedPets: notProcessedPets,
      });
    }

    yield put(
      petsActionCreators.loadPetsSuccess({ pets: normalizeArrayByProperty(pets, "petId") }),
    );
  } catch (error) {
    yield put(petsActionCreators.loadPetsFailure({ error }));
  }
}

function* onLoadPetsByCustomer({ customerKey }) {
  try {
    yield put(petsActionCreators.loadPetsByCustomerRequest());
    const response = yield call(fetchPetsByCustomer, { customerKey });
    const pets = response.data;
    yield put(
      petsActionCreators.loadPetsByCustomerSuccess({
        pets: normalizeArrayByProperty(pets, "petId"),
      }),
    );
  } catch (error) {
    yield put(petsActionCreators.loadPetsByCustomerFailure({ error }));
  }
}

/**
 *  Saga to update pet details
 *
 *  @memberOf Sagas.Pets
 *  @generator
 *  @name onUpdatePet
 *  @param { Number } petId - pet id for building the request url
 *  @param { String } customerKey - customer key for building the request url
 *  @param { Object } data - request payload for updating the pet details
 *
 *  @todo the eligibility endpoint is called after a successful update as a temporary workaround
 *    which tells SF to pull the latest information from PODs.
 *    Reach out to .NET/SF and see if this has been fixed: after 7/22/23
 */
function* onUpdatePet({ petId, customerKey, data }) {
  try {
    yield put(petsActionCreators.updatePetRequest());
    const sourceId = yield select(getSourceId);
    const { petName } = yield select(getPetById, petId);
    const response = yield call(putPet, {
      petId,
      customerKey,
      data: { petName, ...data, sourceId },
    });
    const pet = response.data;
    yield put(petsActionCreators.updatePetSuccess({ pet }));

    // Call eligibility to sync SF and Pods data
    const bookingFlowType = yield select(getSystemBookingFlow);
    if (bookingFlowType === systemName.HOTEL) {
      yield put(eligibilityActionCreators.clearEligibilityResults());
      yield put(getPetEligibilityAfterChange({ petId, customerKey }));
    } else {
      yield put(getPetParentScreenEligibility({ customerKey, includePets: true }));
    }
  } catch (error) {
    yield put(petsActionCreators.updatePetFailure({ error }));
  }
}

function* onCreatePet({ customerKey, data, onDone }) {
  try {
    yield put(petsActionCreators.createPetRequest());
    const sourceId = yield select(getSourceId);
    const response = yield call(postPet, { customerKey, data: { ...data, sourceId } });
    const pet = response.data;
    yield put(petsActionCreators.createPetSuccess({ pet }));
    const customerPets = yield select(getPetsByCustomer, { customerKey });
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey,
          pets: [...customerPets, pet.petId],
        },
      }),
    );
    yield onDone && onDone(pet.petId);
  } catch (error) {
    yield put(petsActionCreators.createPetFailure({ error }));
  }
}

function* onSetPetInactive({ petId, customerKey }) {
  try {
    yield put(petsActionCreators.setPetInactiveRequest());
    const sourceId = yield select(getSourceId);
    yield call(deletePet, {
      petId,
      customerKey,
      sourceId,
    });
    yield put(
      petsActionCreators.updatePetSuccess({
        pet: {
          petId: Number(petId),
          isActive: false,
        },
      }),
    );
    yield put(petsActionCreators.setPetInactiveSuccess());
  } catch (error) {
    yield put(petsActionCreators.setPetInactiveFailure({ error }));
  }
}

function* onSetPetActive({ petId, customerKey }) {
  try {
    yield put(petsActionCreators.setPetActiveRequest());
    const sourceId = yield select(getSourceId);
    const { petName } = yield select(getPetById, petId);
    yield call(putPet, {
      petId,
      customerKey,
      sourceId,
      data: {
        petName,
        sourceId,
      },
    });
    yield put(
      petsActionCreators.updatePetSuccess({
        pet: {
          petId: Number(petId),
          isActive: true,
        },
      }),
    );
    yield put(petsActionCreators.setPetActiveSuccess());
  } catch (error) {
    yield put(petsActionCreators.setPetActiveFailure({ error }));
  }
}

function* watchLoadPetsByCustomer() {
  yield takeEvery(petsActionTypes.LOAD_PETS_BY_CUSTOMER, onLoadPetsByCustomer);
}

function* watchLoadPet() {
  yield takeEvery(petsActionTypes.LOAD_PET, onLoadPet);
}

function* watchUpdatePet() {
  yield takeEvery(petsActionTypes.UPDATE_PET, onUpdatePet);
}

function* watchCreatePet() {
  yield takeEvery(petsActionTypes.CREATE_PET, onCreatePet);
}

function* watchSetPetInactive() {
  yield takeEvery(petsActionTypes.SET_PET_INACTIVE, onSetPetInactive);
}

function* watchSetPetActive() {
  yield takeEvery(petsActionTypes.SET_PET_ACTIVE, onSetPetActive);
}

function* watchLoadPetsByIds() {
  yield takeEvery(petsActionTypes.LOAD_PETS, onLoadPets);
}

export default function* petsSaga() {
  yield all([
    fork(watchLoadPetsByCustomer),
    fork(watchUpdatePet),
    fork(watchLoadPet),
    fork(watchCreatePet),
    fork(watchSetPetInactive),
    fork(watchSetPetActive),
    fork(watchLoadPetsByIds),
  ]);
}
