import moment from "moment";
import { getDayNumbersToDisplayInCalendarGrid } from "@prism/psm-ui-components/";
import { formatMoment } from "core/utils/dateUtils/formatDateTime";

/**
 * Get Moment from/to dates for a given month
 *
 * @exports getDateRangeFromTo
 * @param {object} moment - current selected date as a moment object
 * @param {string} currentMonth - current selected month in the format 'MM YY'
 * @returns {object} - object containing Moment dates fromDate and toDate
 */
export default function getDateRangeFromTo(MomentObj, currentMonth) {
  const month = moment(MomentObj).month(currentMonth);
  const range = getDayNumbersToDisplayInCalendarGrid(month);
  const fromDate = formatMoment(moment(range[0].id));
  const toTime = { hours: 23, minutes: 59, seconds: 59 }; // from time defaults to 00:00:00 so we don't need to set it
  const toDate = formatMoment(moment(range[range.length - 1].id).set(toTime));

  return {
    fromDate,
    toDate,
  };
}
