import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

/**
 * Retrieves the "prismPet" profiles information for a given petId from the provided pets object.
 *
 * @memberOf Utils.Pets
 * @function
 * @name getPrismPetProfiles
 * @param {Object} pets - The object containing pet information.
 * @returns {Function} A curried function that accepts a petId and returns an object containing the petId and its profile information.
 * @example
 *
 * //get a single pet profiles
 * const profile = getPrismPetProfiles(pets)(petId)
 *
 * // Map over multiple pets (e.g. const listOfPetIds = [1000022397, 1000022396])
 * const curriedMap = mapFn => array => array.map(mapFn);
 * const mapGetProfile = curriedMap(getPrismPetProfiles(pets))
 * const profiles = mapGetProfile(listOfPetIds);
 */
export const getPrismPetProfiles = pets => petId => {
  const profiles = pets[petId]?.prismPet?.profiles;
  return {
    petId,
    profiles,
  };
};

// TODO, refactor code to use this function
const getDdcHotelProfiles = (systemName) => (PrismPetProfiles) => {
  const hotelProfile = PrismPetProfiles?.profiles?.find(
    ({ profileType }) => profileType === systemName,
  );
  return hotelProfile;
};
