import { TOGGLE_SCHEDULE_SUSPENSION_HISTORY_MODAL } from "dux/scheduleSuspensionHistoryModal/scheduleSuspensionHistoryModalActions";

const initialState = { isHidden: true, suspensionId: null };

const scheduleSuspensionHistoryModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SCHEDULE_SUSPENSION_HISTORY_MODAL:
      return {
        ...state,
        isHidden: !state.isHidden,
        suspensionId: action.suspensionId,
      };
    default:
      return state;
  }
};

export default scheduleSuspensionHistoryModalReducer;
