/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_LIST_OF_NEAR_BY_STORES = "GET_LIST_OF_NEAR_BY_STORES";
export const GET_LIST_OF_NEAR_BY_STORES_REQUEST = "GET_LIST_OF_NEAR_BY_STORES_REQUEST";
export const GET_LIST_OF_NEAR_BY_STORES_SUCCESS = "GET_LIST_OF_NEAR_BY_STORES_SUCCESS";
export const GET_LIST_OF_NEAR_BY_STORES_FAILURE = "GET_LIST_OF_NEAR_BY_STORES_FAILURE";

export const SET_CACHED_STORE_NUMBER = "SET_CACHED_STORE_NUMBER";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getListOfNearByStores = ({ latitude, longitude }) => ({
  type: GET_LIST_OF_NEAR_BY_STORES,
  latitude,
  longitude,
});

export const getListOfNearByStoresRequest = payload => ({
  type: GET_LIST_OF_NEAR_BY_STORES_REQUEST,
  payload,
});

export const getListOfNearByStoresSuccess = ({ nearByStores }) => ({
  type: GET_LIST_OF_NEAR_BY_STORES_SUCCESS,
  nearByStores,
});

export const getListOfNearByStoresFailure = payload => ({
  type: GET_LIST_OF_NEAR_BY_STORES_FAILURE,
  payload,
});


// CACHED STORE NUMBER
export const setCachedStoreNumber = ({ cacheStoreNumber }) => ({
  type: SET_CACHED_STORE_NUMBER,
  cacheStoreNumber,
})