import { GET_SERVICE_MANAGEMENT_TOGGLE_REASONS_SUCCESS } from "dux/servicesManagementToggle/servicesManagementToggleActions";

const initialState = {};

export const serviceManagementToggleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICE_MANAGEMENT_TOGGLE_REASONS_SUCCESS:
      return {
        ...action.suspensionReasons,
      };
    default:
      return state;
  }
};
