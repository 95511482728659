/**
 * Dynamically generates and returns environment-specific banner messages through a callback function.
 * The function evaluates the application environment (`appEnv`) and, based on its value, it constructs
 * an appropriate message that includes the environment name, system type, and booking flow. The message
 * is generated using a provided callback function (`messageFn`).
 *
 * @function envFlowBannerUtil
 * @memberof Utils.Banner
 * @param {Object} params - The parameters object.
 * @param {string} params.appEnv - The application environment, which dictates the type of message generated. Supported environments are case-insensitive "DEV", "QA", and "STAGE".
 * @param {string} params.systemType - Describes the system type (e.g., "Salon", "Hotel") to be included in the banner message.
 * @param {string} params.systemBookingFlow - Specifies the booking flow (e.g., "Salon", "Hotel") to be mentioned in the banner message.
 * @param {function} params.messageFn - A callback function that is used to construct the message. This function is expected to accept an object with `environment`, `systemType`, and `systemBookingFlow` properties and return a string.
 * @returns {?string} The generated banner message string based on the provided environment or `null` if the environment does not match any of the predefined cases.
 */
export const envFlowBannerUtil = ({ appEnv, systemType, systemBookingFlow, messageFn }) => {
  const environment = RegExp(appEnv, "i");

  if (environment.test("DEV")) {
    return messageFn({ environment: "Development", systemType, systemBookingFlow });
  }

  if (environment.test("QA")) {
    return messageFn({ environment: "Quality Assurance (QA)", systemType, systemBookingFlow });
  }

  if (environment.test("STAGE")) {
    return messageFn({ environment: "Training", systemType, systemBookingFlow });
  }

  return null;
};
