/**
 * Abbreviates a string based on the given delimiter and number of characters.
 *
 * @memberof Utils.Strings
 * @function abbreviateDelimitedString
 * @param {string} str - The input string to be abbreviated.
 * @param {string} delimiter - The delimiter used to split the string.
 * @param {number} num - The number of characters for the abbreviation.
 * @returns {string} - A string of abbreviated words separated by a comma.
 *
 * @example
 *
 * abbreviateDelimitedString("Monday; Tuesday; Wednesday", ";", 3);
 * // Returns "Mon, Tue, Wed"
 */
export const abbreviateDelimitedString = (str, delimiter, num) => {
  return str
    .split(delimiter)
    .map(word => word.trim().substring(0, num))
    .join(", ");
};
