import React from "react";
import { connect } from "react-redux";
import { get } from "lodash/fp";

// Components / redux connect fns
import BulkPackageAddOnAppliedPriceContainer from "dux/bulkPackageAddOnAppliedPrice/BulkPackageAddOnAppliedPriceContainer";
import BulkPackageAddOnAppliedLabelContainer from "dux/bulkPackageAddOnAppliedLabel/BulkPackageAddOnAppliedLabelContainer";

// Every Layout
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";

// HOC
import { withRouteProps } from "core/utils/routingUtils/withRouteProps";

// Selectors
import { getAppointmentTimeSlot, getCurrentPetServiceId } from "core/selectors/cartSelectors";
import {
  getBundleOfferTypeByPetServiceId,
  getIsActiveBundleAvailable,
} from "dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import getIsBGMWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getAddOnMetaData from "dux/_selectors/getAddOnMetaData";

// Utils
import { isFromBooking } from "core/utils/validationUtils/isFromValidation";

// Constants
import { BGM } from "web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";

/**
 * Renders the component for bulk package add-ons applied.
 *
 * @memberOf Views.Salon.QuickQuote
 * @function
 * @name BulkPackageAddOnsAppliedComponent
 * @param {Object} props - The input properties of the component.
 * @param {boolean} props.isHidden - Indicates whether the component should be hidden.
 * @param {string} props.componentId - The unique identifier for the component.
 * @param {string} props.petId - The identifier for the pet.
 * @returns {JSX.Element|null} - The rendered component or null if isHidden is true.
 *
 * @example <BulkPackageAddOnsAppliedComponent />
 */
export const BulkPackageAddOnsAppliedComponent = ({ isHidden, componentId, petId }) => {
  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutCluster style={{ justifyContent: "space-between" }}>
        <BulkPackageAddOnAppliedLabelContainer />
        <BulkPackageAddOnAppliedPriceContainer petId={petId} />
      </LayoutCluster>
    </LayoutBox>
  );
};

//  CONTAINER ---------------------------------------------------------------------------------------- >
/**
 * AppliedBulkPackageAddOns is a Higher Order Component that enhances the BulkPackageAddOnsAppliedComponent.
 * It connects the state and route props to provide the necessary data to the component.
 *
 * @param {object} state - The current state of the application.
 * @param {object} router - The router object.
 * @param {string} petId - The ID of the pet.
 * @param {string} addOnId - The ID of the add-on.
 * @returns {object} - The enhanced props object for the BulkPackageAddOnsAppliedComponent
 */
export const AppliedBulkPackageAddOns = withRouteProps(
  connect((state, { router, petId, addOnId }) => {
    const petServiceId = getCurrentPetServiceId(state, { petId });
    const isBundleAvailable = getIsActiveBundleAvailable(state, { petId, petServiceId });
    const offerType = getBundleOfferTypeByPetServiceId(state, { petId, petServiceId });
    const isBookingFlow = isFromBooking(get(["location", "pathname"], router));
    const isTimeSlotAvailable = getAppointmentTimeSlot(state, { petId });

    // Applied add-ons only apply to BGM for now
    const isBGMHidden = getIsBGMWorkflowFeatureFlagHidden(state);

    const { selectedBundleAddOnId } = getAddOnMetaData(state, { petId, addOnId });
    const isBundledAddOn = addOnId === selectedBundleAddOnId;

    return {
      componentId: "AppliedBulkPackageAddOns",
      isHidden:
        isBGMHidden ||
        offerType !== BGM ||
        !isBundleAvailable ||
        !isBookingFlow ||
        isTimeSlotAvailable ||
        !isBundledAddOn,
    };
  })(BulkPackageAddOnsAppliedComponent),
);
// <----------------------------------------------------------------------------------------
