import React, { Fragment } from "react";
import styled from "styled-components";
import CommonModal from "../common/modals/commonModal";
import CancelAppointmentContainer from "./cancelAppointmentContainer";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
`;

export default function CancelAppointmentModal(props) {
  const { cancelAppointmentModalType, handleClick } = props;

  return (
    <Fragment>
      {cancelAppointmentModalType ? (
        <SectionWrapper>
          <CommonModal onClose={handleClick}>
            <CancelAppointmentContainer />
          </CommonModal>
        </SectionWrapper>
      ) : null}
    </Fragment>
  );
}
