import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  GET_SEARCH_ADDRESS_BY_ZIP,
  getSearchAddressByZip,
  setZipSearchFormToUpdate,
} from "@/dux/searchAddressByZip/searchAddressByZipActions";
import {
  selectSearchAddressByZipAddress,
  selectZipSearchFormToUpdate,
  selectZipSearchCityOptions,
} from "@/dux/searchAddressByZip/searchAddressByZipSelectors";
import isValidPostalCode from "@/core/utils/validationUtils/postalCodeValidation";
import isEmpty from "lodash/isEmpty";
import { createLoadingSelector } from "@/core/selectors/utils";
import { getCountriesOptions } from "@/core/selectors/enumsSelectors";
import FormInput from "@/web/common/FormInput";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Heading, RadioField } from "@petsmart-ui/sparky";
import { RadioFieldItem } from "@petsmart-ui/sparky/dist/components/RadioField/RadioField";
import StyledPopover from "@/web/common/StyledPopover";
import { LayoutBox } from "@/layout/box/Box";
import { clickOutsideIgnoredClass } from "@/web/common/hoc/withClickOutside";

const CityOptions = ({ cityOptions, value, onChange }) => {
  return (
    <LayoutBox>
      <RadioField
        label={
          <Heading tagName="h4" size="body-lg-bold">
            This zip code contains the following cities:
          </Heading>
        }
        onChange={onChange}
        selectedValue={value}
      >
        {cityOptions?.map(city => (
          <RadioFieldItem text={city} value={city} key={city} />
        ))}
      </RadioField>
    </LayoutBox>
  );
};

const ZipCodeField = ({
  componentId,
  isHidden,
  zipSearchResults,
  updateAddressFormWithResults = () => {},
  value,
  input: InputField,
  cityOptions,
  onCityChange,
  ...props
}) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  useEffect(() => {
    updateAddressFormWithResults();
  }, [zipSearchResults]);

  useEffect(() => {
    if (cityOptions) setIsFlyoutOpen(cityOptions?.length > 1);
  }, [cityOptions]);

  if (isHidden) return null;

  return (
    <Wrapper apiKey={window.env.GOOGLE_MAP_KEY}>
      <StyledPopover
        body={
          <CityOptions
            value={zipSearchResults?.city}
            cityOptions={cityOptions}
            onChange={city => {
              onCityChange(city);
              setIsFlyoutOpen(false);
            }}
          />
        }
        isOpen={isFlyoutOpen}
        preferPlace="below"
        onOuterAction={() => setIsFlyoutOpen(false)}
        className={clickOutsideIgnoredClass}
      >
        <InputField id={componentId} {...props} />
      </StyledPopover>
    </Wrapper>
  );
};

export const PetParentZipCodeInput = connect(
  (state, { componentId }) => {
    return {
      componentId: `PetParentZipCodeInput__${componentId}`,
      zipSearchResults: selectSearchAddressByZipAddress(state),
      formToUpdate: selectZipSearchFormToUpdate(state),
      isLoading: createLoadingSelector([GET_SEARCH_ADDRESS_BY_ZIP])(state),
      countries: getCountriesOptions(state) ?? [{}], // empty obj to use default validation from isValidPostalCode
      input: FormInput,
      cityOptions: selectZipSearchCityOptions(state),
    };
  },
  (dispatch, { formId }) => {
    return {
      onZipSearch: zipCode => {
        dispatch(getSearchAddressByZip(zipCode));
        dispatch(setZipSearchFormToUpdate(formId));
      },
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    const {
      componentId,
      zipSearchResults,
      formToUpdate,
      isLoading,
      countries,
      input,
      cityOptions,
    } = stateProps;
    const { onZipSearch } = dispatchProps;
    const { setFieldValue, formId } = ownProps;

    return {
      ...ownProps,
      componentId,
      zipSearchResults,
      cityOptions,
      input,
      onChange: e => {
        const zipCode = e?.target?.value;

        const isValid = countries.some(({ value }) => isValidPostalCode(zipCode, value));
        if (isLoading || !isValid) return;

        onZipSearch(zipCode);
      },
      updateAddressFormWithResults: () => {
        const shouldUpdateForm = formToUpdate === formId;
        if (!shouldUpdateForm || isEmpty(zipSearchResults)) return;

        const { city, state, country } = zipSearchResults;
        if (city) setFieldValue("city", city);
        if (state) setFieldValue("stateProvinceAbbreviation", state);
        if (country) setFieldValue("countryAbbreviation", country);
      },
      onCityChange: city => setFieldValue("city", city),
    };
  },
)(ZipCodeField);
