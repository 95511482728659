// @ts-check

import {
  GET_SEARCH_ADDRESS_BY_ZIP_SUCCESS,
  SET_ZIP_SEARCH_FORM_TO_UPDATE,
  SET_ZIP_SEARCH_CITY_OPTIONS,
} from "./searchAddressByZipActions";

const initialState = {
  address: {},
  formToUpdate: null,
};

/**
 * Redux Reducer to add address from google maps search to the Redux State
 * @memberOf Reducer.searchAddressByZip
 * @function
 * @name searchAddressByZipReducer
 * @param {Object } state - Redux state
 * @param {{ type: string, address?: Object, formId?: string, cityOptions?: string[] }} action - Redux Action
 * @returns {{}}
 */
const searchAddressByZipReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_SEARCH_ADDRESS_BY_ZIP_SUCCESS:
      return {
        ...state,
        address: action?.address,
      };
    case SET_ZIP_SEARCH_FORM_TO_UPDATE:
      return {
        ...state,
        formToUpdate: action?.formId,
      };
    case SET_ZIP_SEARCH_CITY_OPTIONS:
      return {
        ...state,
        cityOptions: action?.cityOptions,
      };
    default:
      return state;
  }
};

export default searchAddressByZipReducer;
