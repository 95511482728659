import { createSelector } from "reselect";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { filter, forEach, get, isEmpty, set, groupBy, omit, merge } from "lodash/fp";
import getIsCatGroomingWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/catGrooming/getIsCatGroomingWorkflowFeatureFlagHidden";
import {
  filterRelocationWeeklyEditActivites,
  allRelocationsHaveStoreIds,
  isRelocatingToNonPrimaryStore,
} from "core/utils/schedulesUtils";
import { getAssociatesOfCurrentStore } from "./associatesSelectors";
import { getProps, getState } from "./commonSelector";
import { forEachWithKey } from "../utils/general/loopsAndMaps";
import { createDeepEqualSelector } from "./utils";
import { absenceReasons, dayActivityTypes } from "../constants/schedulesConstants";
import getMomentByDateAndTime from "../utils/dateUtils/byDateAndTime";
import getWorkTimeDuration from "../utils/dateUtils/workTimeDuration";
import { formatCalendarDateMoment } from "../utils/dateUtils/formatDateTime";
import { getSalonHours } from "./salonHoursSelector";
import { getTimeRangeError } from "../../web/associateScheduling/utils";
import { getStoreNumber } from "./persistentSelectors";
import valueMatchesSearchString from "../utils/stringManipulationUtils/valueMatchesSearchString";
import {
  getAbsencesByDateAndAssociate,
  getAppointmentsByAssociateAndDate,
  getAssociate,
  getAssociates,
  getBreakByDateAndAssociate,
  getDayActivities,
  getPetServiceItem,
  getPetServiceItems,
  getStateSliceForAvailableAppointments,
  getSalons,
} from "./entitiesSelector";
import { weekDaysNames } from "../constants";
import { selectCapabilities } from "@/dux/storeCapabilities/storeCapabilitiesSelectors";

export const selectSchedules = createSelector([getState], state => state?.schedules ?? {});

export const getAssociateId = createSelector(
  [selectSchedules],
  schedules => schedules?.associateId,
);
export const getTemplateWeekId = createSelector([selectSchedules], schedules => schedules?.weekId);
export const getWeeklyEditActivities = createSelector(
  [selectSchedules],
  schedules => schedules?.weeklyEdit,
);
export const getWeeklyEditActivity = createSelector(
  [getWeeklyEditActivities, getProps],
  (weeklyEdit, { localId } = {}) => weeklyEdit?.[localId],
);
export const getWeeklyEditChanged = createSelector(
  [selectSchedules],
  schedules => schedules?.weeklyEditChanged,
);
export const getTemplateDayActivities = createSelector(
  [selectSchedules],
  schedules => schedules?.templateSchedules,
);
export const getStartWeekDate = createSelector(
  [selectSchedules],
  schedules => schedules?.startWeekDate,
);
export const getEffectiveStartDate = createSelector([selectSchedules], schedules => {
  const format = "YYYY-MM-DD";
  const today = moment();
  const effectiveStartDate = moment(schedules?.effectiveStartDate, format);
  const followingMonday = today
    .startOf("isoWeek")
    .add(1, "week")
    .format(format);
  return today.isAfter(effectiveStartDate) ? followingMonday : effectiveStartDate.format(format);
});

export const selectTemplateSchedules = createSelector(
  [selectSchedules],
  schedules => schedules?.templateSchedules,
);
export const getTemplateEffectiveStartDate = createSelector(
  [selectTemplateSchedules],
  templateSchedules => templateSchedules?.effectiveStartDate,
);
export const getTemplateHasFutureAppointments = createSelector(
  [selectTemplateSchedules],
  templateSchedules => templateSchedules?.hasFutureAppointments,
);
export const getAllAssociatesSchedules = createSelector(
  [selectSchedules],
  schedules => schedules?.allAssociates,
);
export const getLunchBreakTimes = createSelector(
  [selectSchedules],
  schedules => schedules?.lunchBreakTimes,
);
export const getSchedulesSearchValue = createSelector(
  [selectSchedules],
  schedules => schedules?.searchValue,
);

export const getLunchBreakByStore = createSelector(
  [getLunchBreakTimes, getProps],
  (lunchBreakTimes, { storeNumber }) => lunchBreakTimes?.[storeNumber],
);

export const getStateSliceForSchedules = createSelector(
  [getDayActivities, getAssociates, selectSchedules],
  (dayActivities, associates, schedules) => ({
    entities: { dayActivities, associates },
    schedules: omit("searchValue", schedules),
  }),
);

export const getWorkActivitiesForDayOfWeek = createSelector(
  [getWeeklyEditActivities, getProps],
  (dayActivities, { dayOfWeek }) => {
    return filter({ dayOfWeek }, dayActivities);
  },
);

export const getDayActivitiesAndSchedulesObject = createSelector(
  [getDayActivities, getAllAssociatesSchedules],
  (dayActivities, allAssociates) => ({
    entities: { dayActivities },
    schedules: { allAssociates },
  }),
);

export const getSelectedAssociate = createSelector(
  [getAssociateId, getAssociates],
  (associateId, associates) => associates?.[associateId],
);

export const getAssociateDayActivities = createSelector(
  [getDayActivities, getAllAssociatesSchedules, getProps],
  (dayActivities, schedules, { associateId, selectedDate }) => {
    if (isEmpty(dayActivities)) {
      return [];
    }

    const result = [];
    const dayAssociateActivitiesIds = get([selectedDate, associateId], schedules);

    forEach(activityId => {
      dayActivities[activityId] && result.push(dayActivities[activityId]);
    }, dayAssociateActivitiesIds);

    return result;
  },
);

export const getAssociateWeekSchedule = createSelector(
  [getStateSliceForSchedules, getProps],
  (state, { associateId, startWeekDate }) => {
    const currentMoment = moment(startWeekDate);
    const endMoment = moment(startWeekDate).add(6, "days");
    const result = { activities: {} };
    let hoursPerWeek = 0;

    while (currentMoment <= endMoment) {
      const formattedMoment = currentMoment.format("YYYY-MM-DD");
      const dayActivities = getAssociateDayActivities(state, {
        associateId,
        selectedDate: formattedMoment,
      });
      result.activities[formattedMoment] = dayActivities;
      hoursPerWeek += getWorkTimeDuration({ dayActivities, date: formattedMoment });
      currentMoment.add(1, "day");
    }

    return set("hoursPerWeek", hoursPerWeek, result);
  },
);

export const getFilteredAssociates = createSelector(
  [getAssociatesOfCurrentStore, getSchedulesSearchValue],
  (associates = {}, searchValue) => {
    const filteredAssociates = Object.keys(associates).filter(associateId => {
      const associateName = get([associateId, "associateName"], associates);

      return valueMatchesSearchString(associateName, searchValue);
    });

    return filteredAssociates;
  },
);

export const getAssociatesWeekSchedule = createDeepEqualSelector(
  [getStateSliceForSchedules, getFilteredAssociates, getStartWeekDate],
  (state, filteredAssociates, startWeekDate) => {
    const result = {};

    forEach(associateId => {
      result[associateId] = getAssociateWeekSchedule(state, { associateId, startWeekDate });
    }, filteredAssociates);

    return result;
  },
);

export const getFilteredAssociatesByGroup = createSelector(
  [getAssociatesOfCurrentStore, getFilteredAssociates],
  (associates, filteredAssociates) => {
    return groupBy(associateId => associates[associateId].associateGroup, filteredAssociates);
  },
);

export const getWeekWorkDurationTotals = createSelector(
  [getAssociatesWeekSchedule, getProps],
  (schedules, { filteredSchedules }) => {
    const result = {};
    let weekHoursTotal = 0;

    forEachWithKey((schedule, associateId) => {
      if (!filteredSchedules[associateId]) {
        return;
      }
      weekHoursTotal += schedule.hoursPerWeek;

      forEachWithKey((dayActivities, date) => {
        const workDuration = getWorkTimeDuration({ dayActivities, date });
        result[date] = result[date] ? result[date] + workDuration : workDuration;
      }, schedule.activities);
    }, schedules);

    return set("weekHoursTotal", weekHoursTotal, result);
  },
);

export const isAllAssociatesWeekScheduleFetched = createSelector(
  [getStartWeekDate, getAllAssociatesSchedules],
  (startWeekDate, associatesWeekActivityIds = {}) => {
    const currentMoment = moment(startWeekDate);
    const endMoment = moment(startWeekDate).add(6, "days");

    while (currentMoment <= endMoment) {
      const formattedMoment = currentMoment.format("YYYY-MM-DD");
      if (!associatesWeekActivityIds[formattedMoment]) {
        return false;
      }

      currentMoment.add(1, "day");
    }

    return true;
  },
);

/*
  There may be more than one work shift (split schedules) so
    we need to find the earliest time and the latest time
    and return those as a shift object
*/
export const determineStartAndEndShiftTimes = (selectedDate, workActivities) =>
  workActivities.reduce((shiftTimes, workActivity) => {
    const workActivityStart = getMomentByDateAndTime(selectedDate, workActivity.startTime);
    const workActivityEnd = getMomentByDateAndTime(selectedDate, workActivity.endTime);

    // if no values currently exist, then we want the incoming
    shiftTimes.shiftStartDateTime ??= workActivityStart;
    shiftTimes.shiftEndDateTime ??= workActivityEnd;

    // decide if the incoming time or the current time is earlier/later
    const isCurrentBeforeIncoming = shiftTimes.shiftStartDateTime.isBefore(workActivityStart);
    const isCurrentAfterIncoming = shiftTimes.shiftEndDateTime.isAfter(workActivityEnd);

    // assign based on if the current value is earlier/later
    shiftTimes.shiftStartDateTime = isCurrentBeforeIncoming
      ? shiftTimes.shiftStartDateTime
      : workActivityStart;
    shiftTimes.shiftEndDateTime = isCurrentAfterIncoming
      ? shiftTimes.shiftEndDateTime
      : workActivityEnd;

    return shiftTimes;
  }, {});

export const getAssociateShiftTimeByDate = createSelector(
  [getAssociateDayActivities, getProps],
  (dayActivities, { selectedDate }) => {
    if (isEmpty(dayActivities)) {
      return null;
    }

    const workActivities = dayActivities.filter(
      dayActivity =>
        dayActivity.type === dayActivityTypes.WORK ||
        dayActivity.type === dayActivityTypes.RELOCATION,
    );

    if (!workActivities.length) {
      return null;
    }

    return determineStartAndEndShiftTimes(selectedDate, workActivities);
  },
);

// TODO: break into smaller pieces & test them
export const getFilteredByBreakData = createSelector(
  [getPetServiceItems, getBreakByDateAndAssociate, getAssociateShiftTimeByDate, getProps],
  (petServiceItems, currentBreak, shiftTimes, { appointments = [] }) => {
    const startBreak = currentBreak
      ? currentBreak.startDate
      : shiftTimes && shiftTimes.shiftEndDateTime;
    const endBreak = currentBreak && currentBreak.endDate;
    let extraMaxLimitBeforeBreak = 0;
    let extraMaxLimitAfterBreak = 0;

    const appointmentsBeforeBreak = appointments.filter(appointment => {
      const petServiceItem = petServiceItems?.[appointment];
      const petServiceItemStartTime = moment(petServiceItem?.startDateTime);

      if (!petServiceItem) {
        return;
      }

      if (petServiceItemStartTime < moment(startBreak)) {
        if (petServiceItem.isStandalone || petServiceItem.maxPerBlockCount === 0) {
          extraMaxLimitBeforeBreak -= 1;
        }

        if (petServiceItem.maxPerBlockCount > 1) {
          extraMaxLimitBeforeBreak += petServiceItem.maxPerBlockCount - 1;
        }
      }
      return petServiceItemStartTime < moment(startBreak);
    });

    const appointmentsAfterBreak = !currentBreak
      ? []
      : appointments.filter(appointment => {
          const petServiceItem = petServiceItems?.[appointment];
          const petServiceItemStartTime = moment(petServiceItem?.startDateTime);

          if (!petServiceItem) {
            return;
          }

          if (petServiceItemStartTime >= moment(startBreak)) {
            if (petServiceItem.isStandalone || petServiceItem.maxPerBlockCount === 0) {
              extraMaxLimitAfterBreak -= 1;
            }

            if (petServiceItem.maxPerBlockCount > 1) {
              extraMaxLimitAfterBreak += petServiceItem.maxPerBlockCount - 1;
            }
          }

          return petServiceItemStartTime >= moment(startBreak);
        });

    const startBeforeBreak = shiftTimes && shiftTimes.shiftStartDateTime;
    const endAfterBreak = shiftTimes && shiftTimes.shiftEndDateTime;

    return {
      appointmentsBeforeBreak,
      appointmentsAfterBreak,
      startBeforeBreak,
      endAfterBreak,
      startBreak,
      endBreak,
      extraMaxLimitBeforeBreak,
      extraMaxLimitAfterBreak,
    };
  },
);

// TODO: break into smaller pieces & test them
export const getDashboardAvailableAppointmentsForAssociate = createDeepEqualSelector(
  [
    getAppointmentsByAssociateAndDate,
    getAssociate,
    getStateSliceForAvailableAppointments,
    getDayActivitiesAndSchedulesObject,
    getProps,
  ],
  (appointments, associate, slicedState, dayActivitiesAndSchedules, props) => {
    if (!associate) {
      return [];
    }

    const { associateId, maxPerBlock1, maxPerBlock2 } = associate;
    const shiftObject = getAssociateShiftTimeByDate(dayActivitiesAndSchedules, {
      ...props,
      associateId,
    });

    if (isEmpty(shiftObject)) {
      return [];
    }
    const { shiftStartDateTime, shiftEndDateTime } = shiftObject;
    const currentBreak = getBreakByDateAndAssociate(slicedState, { ...props, associateId });
    const {
      appointmentsBeforeBreak,
      appointmentsAfterBreak,
      extraMaxLimitBeforeBreak,
      extraMaxLimitAfterBreak,
    } = getFilteredByBreakData(merge(slicedState, dayActivitiesAndSchedules), {
      ...props,
      appointments: appointments[associateId],
      associateId,
    });
    let availableBeforeBreak =
      maxPerBlock1 - appointmentsBeforeBreak.length - extraMaxLimitBeforeBreak;
    availableBeforeBreak = availableBeforeBreak > 0 ? availableBeforeBreak : 0;
    let availableAfterBreak =
      maxPerBlock2 - appointmentsAfterBreak.length - extraMaxLimitAfterBreak;
    availableAfterBreak = availableAfterBreak > 0 ? availableAfterBreak : 0;

    const beforeBreakArray = Array(availableBeforeBreak).fill({
      startDateTime: moment(shiftStartDateTime),
      endDateTime: moment(currentBreak ? currentBreak.startDate : shiftEndDateTime),
      duration: moment(currentBreak ? currentBreak.startDate : shiftEndDateTime).diff(
        moment(shiftStartDateTime),
        "minutes",
      ),
    });

    const afterBreakArray = currentBreak
      ? Array(availableAfterBreak).fill({
          startDateTime: moment(currentBreak.endDate),
          endDateTime: moment(shiftEndDateTime),
          duration: moment(shiftEndDateTime).diff(moment(currentBreak.endDate), "minutes"),
        })
      : [];

    return [...beforeBreakArray, ...afterBreakArray];
  },
);

export const getDashboardAvailableAppointmentsByDateAndAssociate = createDeepEqualSelector(
  [
    getAssociates,
    getStateSliceForAvailableAppointments,
    getDayActivitiesAndSchedulesObject,
    getProps,
  ],
  (associates = {}, slicedState, dayActivitiesAndSchedules, props) => {
    const result = {};
    Object.keys(associates).forEach(associateId => {
      result[associateId] = getDashboardAvailableAppointmentsForAssociate(
        merge(slicedState, dayActivitiesAndSchedules),
        { ...props, associateId },
      );
    });

    return result;
  },
);

export const getAppointmentsAbsencesAndShiftByAssociateAndDate = createDeepEqualSelector(
  [
    getAppointmentsByAssociateAndDate,
    getAssociates,
    getPetServiceItems,
    getStateSliceForAvailableAppointments,
    getDayActivitiesAndSchedulesObject,
    getProps,
  ],
  (
    appointments,
    associates,
    petServiceItems,
    slicedState,
    dayActivitiesAndSchedules,
    { selectedDate },
  ) => {
    const result = {};
    Object.keys(associates).forEach(associateId => {
      associateId = Number(associateId);

      result[associateId] = {
        appointments: appointments[associateId]
          ? appointments[associateId].map(petServiceItemId =>
              getPetServiceItem({ entities: { petServiceItems } }, { petServiceItemId }),
            )
          : [],
        absences: getAbsencesByDateAndAssociate(slicedState, { associateId }) || [],
        shift: getAssociateShiftTimeByDate(dayActivitiesAndSchedules, {
          associateId,
          selectedDate,
        }),
      };
    });

    return result;
  },
);

export function getSalonHoursFromState({ storeId, storeNumber, salonHours, salons, weekDayDate }) {
  const daySalonHours = get(weekDayDate, salonHours);
  const isRelocatedStore = storeId && storeId !== storeNumber;

  if (isRelocatedStore) {
    const relocatedDaySalonHours = get([storeId, "salonHours", weekDayDate], salons);

    return relocatedDaySalonHours;
  }

  return daySalonHours;
}

export const getAllTimeRangesError = createSelector(
  [getWeeklyEditActivities, getSalonHours, getStartWeekDate, getStoreNumber, getSalons, getProps],
  (weeklyEditActivities, salonHours, startWeekDate, storeNumber, salons, props) => {
    if (!weeklyEditActivities) {
      return;
    }

    if (isEmpty(salonHours)) {
      return "The store is closed";
    }

    let activities = Object.values(weeklyEditActivities);
    let error;

    if (props?.omitTemp) {
      activities = activities.filter(item => !item?.localId?.includes("_temp"));
    }

    for (let i = 0; i < activities.length; i++) {
      const { dayOfWeek, startTime, endTime, absenceReason, type, storeId } = activities[i];

      const activityDate = formatCalendarDateMoment(moment(startWeekDate).isoWeekday(dayOfWeek));

      const daySalonHours = getSalonHoursFromState({
        storeId,
        storeNumber,
        salonHours,
        salons,
        weekDayDate: activityDate,
      });

      if (absenceReason && absenceReason === absenceReasons.DAY_OFF) {
        continue;
      }

      error = getTimeRangeError({ startTime, endTime, type, daySalonHours });

      if (error) {
        break;
      }
    }

    return error;
  },
);

export const getDaySalonHours = createSelector(
  [getStoreNumber, getSalonHours, getSalons, getProps],
  (storeNumber, salonHours, salons, { weekDayDate, weeklyEditActivity: { storeId } = {} }) =>
    getSalonHoursFromState({
      storeId,
      storeNumber,
      salonHours,
      salons,
      weekDayDate,
    }),
);

/**
 * Retrieves the selectCatGroomingStoreCapability from the provided store capabilities.
 * If available, it returns the catGrooming capability from the storeCapabilities array.
 *
 * @todo Move to storeCapabilitiesSelectors.js
 * @memberof Selectors.Capabilities
 * @function
 * @param {Object} state - The state object containing storeCapabilities.
 * @returns {boolean|undefined} - The catGrooming capability if found, otherwise undefined.
 */
export const selectCatGroomingStoreCapability = createSelector(
  [selectCapabilities],
  capabilities => capabilities?.find(item => item?.catGrooming)?.catGrooming,
);

/**
 * Retrieve the enhanced salon services from the state's store capabilities.
 * If available, it returns the enhancedSalonServices capability from the storeCapabilities array.
 *
 * @todo Move to storeCapabilitiesSelectors.js
 * @memberof Selectors.Capabilities
 * @function
 * @param {Object} state - The state object containing storeCapabilities.
 * @returns {Object|undefined} - The enhanced salon services or undefined if not found.
 */
export const selectEnhancedServicesStoreCapability = createSelector(
  [selectCapabilities],
  capabilities => capabilities?.find(item => item.enhancedSalonServices)?.enhancedSalonServices,
);
/**
 * Retrieve the controlled Overbooking from the state's store capabilities.
 * If available, it returns the controlledOverbooking capability from the storeCapabilities array.
 *
 * @todo Move to storeCapabilitiesSelectors.js
 * @memberof Selectors.Capabilities
 * @function
 * @param {Object} state - The state object containing storeCapabilities.
 * @returns {Object|undefined} - The enhanced salon services or undefined if not found.
 */
export const selectControlledOverbookStoreCapability = createSelector(
  [selectCapabilities],
  capabilities => capabilities?.find(item => item.controlledOverbooking)?.controlledOverbooking,
);

export const isAssociateWithoutCatSkills = createSelector(
  [getAssociate, getProps],
  (associate, { associateId }) => {
    if (!associateId) {
      return true;
    }

    return associate?.skills?.grooming?.length
      ? associate.skills.grooming.indexOf("Cat") === -1
      : true;
  },
);

export const getIsCatGroomingHidden = createSelector(
  [
    selectCatGroomingStoreCapability,
    getIsCatGroomingWorkflowFeatureFlagHidden,
    isAssociateWithoutCatSkills,
  ],
  (hasCatGroomingCapability, isCatGroomingFeatureFlagHidden, associateHasNoCatSkills) =>
    !hasCatGroomingCapability || isCatGroomingFeatureFlagHidden || associateHasNoCatSkills,
);

export const isNotLunchBreakHours = partial => partial.absenceReason !== absenceReasons.LUNCH_BREAK;

export const sortByStartTime = (w1, w2) =>
  moment(w1.startTime, "HH:mm:ss").isBefore(moment(w2.startTime, "HH:mm:ss")) ? -1 : 1;

/**
 * Helper to sort an array of activities by shiftIndex
 * @memberOf Utils.Schedules
 * @param {Object} a - activity
 * @param {Object} b - activity
 * @returns number
 */
export const sortByShiftIndex = (a, b) => a?.shiftIndex - b?.shiftIndex;

/**
 * Helper to sort an array of activities by shiftIndex if it is present, otherwise by startTime
 * @memberOf Utils.Schedules
 * @param {Object} a - activity
 * @param {Object} b - activity
 * @returns number
 */
export const sortDayActivities = (a, b) => {
  const useShiftIndex = [a, b]?.find(({ shiftIndex }) => shiftIndex >= 0);
  return useShiftIndex ? sortByShiftIndex(a, b) : sortByStartTime(a, b);
};

/**
 * Helper to get day activities for work hours and sort them
 * @memberOf Utils.Schedules
 * @param {Object[]} activities - array of day activities
 * @returns sorted array of day activities that are work hours
 */
export const filterWorkHours = activities =>
  activities.filter(isNotLunchBreakHours).sort(sortDayActivities);

export const getDefaultRow = dayOfWeek => [{ dayOfWeek, id: uuidv4() }];

/**
 * Helper to Return work hours which are not Lunch.
 * If none exist, return the default row so the row will be rendered even if it's empty
 * @memberOf Utils.Schedules
 * @param {Object[]} rowData - array of day activities
 * @param {String} dayOfWeek - array of day activities
 * @returns array of day activities
 */
export const getWorkHours = (rowData, dayOfWeek) => {
  const workHours = filterWorkHours(rowData);
  return workHours.length ? workHours : getDefaultRow(dayOfWeek);
};

/**
 * Helper to format a split shift into one day activity with correct start and end times
 * @memberOf Utils.Schedules
 * @param {Object[]} workHours - array of day activities for first and second shift
 * @returns array of one day activity that is the first shift with the endtime of the second shift
 */
export const getWorkingHour = workHours => {
  // for existing saved split shift if it has two hours
  const hour = workHours[0];
  // we have to get first entry only and get the end time from the last entry
  // if value was changed then get the first shift endtime
  const endTime = workHours[1].endTime;
  return [{ ...hour, endTime }];
};

/**
 * Helper to return work hours as one day activity, if the day is a split shift then
 * the first shift will be returned with the endTime of the second shift.
 * @memberOf Utils.Schedules
 * @param {Object[]} rowData - array of day activities
 * @param {String} dayOfWeek - array of day activities
 * @returns array of day activities
 */
export const getWorkingHoursWithTemp = (rowData, dayOfWeek) => {
  let workHours = getWorkHours(rowData, dayOfWeek);
  if (workHours.length > 1) {
    // this is for saved split shifts
    return getWorkingHour(workHours);
  }
  return workHours;
};

export const isPartialDayRelocation = item => item.isPartialDayRelocation;

export const getWeeklyRowData = weeklyEditActivities =>
  weekDaysNames.map(day => {
    const rowData = filter({ dayOfWeek: day }, weeklyEditActivities);
    const relocation = rowData.filter(item => isPartialDayRelocation(item));
    const restWorkHours = rowData
      .filter(item => !isPartialDayRelocation(item))
      .sort(sortDayActivities);

    return {
      relocation,
      restWorkHours,
      day,
    };
  });

/**
 * Selector to check if relocations in weekly edit activities are all valid
 *
 * @memberOf Selectors.Schedules
 * @function
 * @name selectIsRelocationValid
 * @param { Object } state - Redux state
 * @return {boolean} - false if ANY relocations are using the associates primary store or a store is left blank
 * @example selectIsRelocationValid(state)
 */
export const selectIsRelocationValid = createSelector(
  [getWeeklyEditActivities, getSelectedAssociate],
  (weeklyEditActivities, associate) => {
    if (!weeklyEditActivities) {
      return;
    }

    const relocations = filterRelocationWeeklyEditActivites(weeklyEditActivities);

    const isNonPrimaryStoreRelocation = isRelocatingToNonPrimaryStore(relocations, associate);
    const allRelocationsHaveAStoreId = allRelocationsHaveStoreIds(relocations);

    return isNonPrimaryStoreRelocation && allRelocationsHaveAStoreId;
  },
);
