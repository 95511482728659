export const ROOM = "Room";
export const BOARDED_GUEST_DDC = "Boarded Guest DDC";
export const DDC = "DDC";

/**
 * @namespace Constants.Suspensions
 */
export const suspensionTypes = {
  ROOM: "ROOM",
  SERVICE: "SERVICE",
  CTAD: "CTAD",
};

export const CTAD_ID = "160";
