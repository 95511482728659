import { put, takeEvery, call, all, select, fork } from "redux-saga/effects";
import moment from "moment";
import { getItinerary } from "core/selectors/entitiesSelector";
import { eligibilityTypes } from "dux/eligibility/constants/eligibilityConstants";
import { waitFor } from "core/sagas/utilsSaga";
import { getCurrentItinerary } from "core/selectors/checkInOutSelector";
import {
  eligibilityPetActionTypes,
  getPetEligibility,
} from "dux/eligibility/actions/eligibilityPetActions";
import {
  eligibilityScreenActionTypes,
  getBookingScreenEligibilityFailure,
  getCheckInScreenEligibilityRequest,
  getCheckInScreenEligibilitySuccess,
  getCheckInScreenEligibilityFailure,
} from "dux/eligibility/actions/eligibilityByScreenActions";
import { onGetUnifiedEligibility } from "dux/eligibility/sagas/unifiedeligibilitySaga";
import { history } from "@/dux/utils/browser/browserHistory";
import { selectItineraryBySystemType } from "dux/pendingAppointment/selectors/itinerarySelectors";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import { pageNames } from "@/core/constants/checkInOutConstants";
import { afterChangePetEligibilityFactory } from "@/core/utils/eligibilityUtils/eligibilityUtils";

function* onGetBookingScreenEligibility({ customerKey, itineraryId }) {
  try {
    yield call(onGetUnifiedEligibility, {
      customerKey,
      itineraryId,
      eligibilityType: eligibilityTypes.BOOKING,
    });
  } catch (error) {
    yield put(getBookingScreenEligibilityFailure({ error }));
  }
}

function* onGetPetParentScreenEligibility({ customerKey, includePets = false }) {
  yield call(onGetUnifiedEligibility, {
    customerKey,
    eligibilityType: eligibilityTypes.PROFILE,
    includePets,
  });
}

function* onGetCheckOutScreenEligibility({ customerKey, itineraryId }) {
  const itinerary = yield waitFor(getItinerary, { itineraryId });
  const startDate =
    itinerary && itinerary.startDateTime && moment(itinerary.startDateTime).format("YYYY-MM-DD");
  yield call(onGetUnifiedEligibility, {
    customerKey,
    itineraryId,
    startDate,
    eligibilityType: eligibilityTypes.CHECK_OUT,
  });
}

function* onGetCheckInScreenEligibility({ customerKey, itineraryId }) {
  try {
    yield put(getCheckInScreenEligibilityRequest());

    const itinerary = yield waitFor(getItinerary, { itineraryId });
    const startDate =
      itinerary && itinerary.startDateTime && moment(itinerary.startDateTime).format("YYYY-MM-DD");

    yield call(onGetUnifiedEligibility, {
      customerKey,
      itineraryId,
      startDate,
      eligibilityType: eligibilityTypes.CHECK_IN,
    });

    yield put(getCheckInScreenEligibilitySuccess());
  } catch (error) {
    yield put(getCheckInScreenEligibilityFailure(error));
  }
}

/**
 *  Saga to call eligibility after an update to the pet
 *
 *  @memberOf Sagas.Eligibility
 *  @generator
 *  @name getSalonPetEligibilityAfterChange
 *  @param { String } customerKey - customer key for building the request url
 *  @param { Number } petId - pet id for building the request url
 *
 */
export function* getSalonPetEligibilityAfterChange({ customerKey, petId }) {
  const inCheckIn = history?.location?.pathname?.includes(pageNames.CHECK_IN);

  let startDate;
  if (inCheckIn) {
    const itineraryId = yield select(getCurrentItinerary);
    const itinerary = yield select(selectItineraryBySystemType, { itineraryId });
    startDate =
      itinerary && itinerary.startDateTime && moment(itinerary.startDateTime).format("YYYY-MM-DD");
  } else {
    startDate = moment().format("YYYY-MM-DD");
  }

  yield put(
    getPetEligibility({
      customerKey,
      eligibilityType: inCheckIn ? eligibilityTypes.CHECK_IN : eligibilityTypes.BOOKING,
      petId,
      startDate,
    }),
  );
}

/**
 *  Saga to determine which eligibility call to make after an update to the pet
 *
 *  @memberOf Sagas.Eligibility
 *  @generator
 *  @name onGetPetEligibilityAfterChange
 *  @param { String } customerKey - customer key for building the request url
 *  @param { Number } petId - pet id for building the request url
 *
 */
function* onGetPetEligibilityAfterChange({ customerKey, petId }) {
  const systemBookingFlow = yield select(getSystemBookingFlow);
  const path = history?.location?.pathname;
  const eligibilityAfterChangeGenerator = afterChangePetEligibilityFactory(systemBookingFlow, path);

  if (!eligibilityAfterChangeGenerator) return;

  yield call(eligibilityAfterChangeGenerator, { customerKey, petId });
}

function* watchGetBookingScreenEligibility() {
  yield takeEvery(
    eligibilityScreenActionTypes.GET_BOOKING_SCREEN_ELIGIBILITY,
    onGetBookingScreenEligibility,
  );
}

function* watchGetPetParentScreenEligibility() {
  yield takeEvery(
    eligibilityScreenActionTypes.GET_PET_PARENT_SCREEN_ELIGIBILITY,
    onGetPetParentScreenEligibility,
  );
}

function* watchGetCheckInScreenEligibility() {
  yield takeEvery(
    eligibilityScreenActionTypes.GET_CHECK_IN_SCREEN_ELIGIBILITY,
    onGetCheckInScreenEligibility,
  );
}

function* watchGetCheckOutScreenEligibility() {
  yield takeEvery(
    eligibilityScreenActionTypes.GET_CHECK_OUT_SCREEN_ELIGIBILITY,
    onGetCheckOutScreenEligibility,
  );
}

function* watchGetPetEligibilityAfterChange() {
  yield takeEvery(
    eligibilityPetActionTypes.GET_PET_ELIGIBILITY_AFTER_CHANGE,
    onGetPetEligibilityAfterChange,
  );
}

export default function* eligibilitySaga() {
  yield all([
    fork(watchGetPetParentScreenEligibility),
    fork(watchGetCheckInScreenEligibility),
    fork(watchGetCheckOutScreenEligibility),
    fork(watchGetBookingScreenEligibility),
    fork(watchGetPetEligibilityAfterChange),
  ]);
}
