import React, { useState } from "react";
import { Button, Layout } from "@prism/psm-ui-components";
import { arrayToObject } from "./dux";
import { ToggleManager } from "./ToggleManager";


export const ToggleButtonListWithSelectAll = ({componentId, setGlobalState, isHidden = false, data = [], showAll = true}) => {
  if (isHidden) return null;

  const [list, setList] = useState(data);

  const handleClick = (name, toggleAll) => {
    const updateList = list.map(item =>
      item.name === name ? { ...item, isPressed: !item.isPressed } : item,
    );

    // "turn on" the check-all switch when the local list is updated
    if (updateList.every(item => item.isPressed)) {
      toggleAll();
    }

    // "turn off" the check-all switch
    if (list.every(item => item.isPressed)) {
      toggleAll();
    }

    // coupling our view to our global state manager
    // the list of buttons require an array in local state, but converts to object when placing in global state
    // if you need to use global state to drive local state, convert it back to an array in your selector or other controller layer
    if (setGlobalState) {
      setGlobalState(arrayToObject(updateList, "id"));
    }

    setList(updateList);
  };

  const handleChangeAll = (value) => {
      let updateList = list.map(item => ({ ...item, isPressed: !value }));

      // coupling our view to our global state manager
      if (setGlobalState) {
        setGlobalState(arrayToObject(updateList, "id"));
      }

    setList(updateList);
  };


  return (
    <ToggleManager>
      {({ on: toggleAllState, toggle: toggleAll, getTogglerProps }) => (
        <Layout.Box id={componentId}>
          <Layout.Cluster space="cluster-space-3" justify="start">
            {list.map(item => {
              return (
                <Button
                  key={item.name}
                  variant="toggle-pill"
                  aria-pressed={item.isPressed}
                  onClick={() => {
                    handleClick(item.name, toggleAll);
                  }}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  {item.name}
                </Button>
              );
            })}
            {showAll && (
              <Button
                variant="toggle-pill"
                {...getTogglerProps({
                  onClick: () => {
                    toggleAll();
                    handleChangeAll(toggleAllState);
                  },
                })}
                style={{
                  cursor: 'pointer'
                }}
              >
                All
              </Button>
            )}
          </Layout.Cluster>
        </Layout.Box>
      )}
    </ToggleManager>
  );
}
