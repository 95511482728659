import React from "react";
import { LayoutCluster } from "@/layout/culster/Cluster";
import {
  HotelSameDayOnOffLabel,
  HotelStayAndPlayGroupOnOffLabel,
  HotelStayAndPlayIndivOnOffLabel,
} from "@/dux/ddcRoomCapacity/HotelServiceOnOffLabels";

export default function OnOffLabelWrapper(props) {
  const { isHidden, componentID } = props;

  if (!isHidden) {
    return (
      <LayoutCluster id={componentID}>
        <HotelSameDayOnOffLabel />
        <HotelStayAndPlayGroupOnOffLabel />
        <HotelStayAndPlayIndivOnOffLabel />
      </LayoutCluster>
    );
  }

  return null;
}
