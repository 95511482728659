import moment from "moment";
import { isUndefined, isEmpty } from "lodash/fp";
import { isExpiredByDate } from "core/utils/dateUtils/isExpired";
import { color } from "web/common/styles/theme";
import ReviewReservation from "assets/icons/review-reservation.svg";
import AgeImage from "../../assets/icons/age.svg";
import CatImage from "../../assets/icons/black-cat.svg";
import VaccinationImage from "../../assets/icons/vaccination.svg";
import MedicationImage from "../../assets/icons/health.svg";
import HealthImage from "../../assets/icons/medications-Icon.svg";
import PSAImage from "../../assets/icons/PSA-icon.svg";
import NewPetImage from "../../assets/icons/new-pet.svg";
import VIPImage from "../../assets/icons/VIP-customer-icon.svg";
import PaidImage from "../../assets/icons/paid.svg";
import PuppyImage from "../../assets/icons/puppy.svg";
import NoBedImage from "../../assets/icons/no-bed-icon.svg";
import IndividualImage from "../../assets/icons/flag-alert.svg";
import DigitalBooking from "../../assets/icons/digital-booking.svg";
import NotificationImage from "../../assets/icons/notificaiton.svg";
import VetFeeImage from "../../assets/icons/vet-fee.svg";
import DoggieValetImage from "../../assets/icons/doggie-valet-icon.svg";
import LinkedImage from "../../assets/icons/linked.svg";
import SalonAppointmentImage from "../../assets/icons/salon-appt-icon-key.svg";

export const getIsSenior = birthDate => moment().diff(moment(birthDate), "years") >= 10;

export const getIsPuppy = pet => {
  const birthday = moment(pet?.birthDate);
  return moment().diff(birthday, "days") <= 168 && pet?.speciesId === 1;
};

/**
 * Util to check if a given pet is missing any vaccinations based on active vaccinations from enums
 * @param {Object} pet
 * @param {Boolean} vaccinationList
 * @param {string?} expiredByDate - optional, if not defined the todays date will be used
 * @returns boolean
 */
export const getIsMissingVaccination = (pet, vaccinationList, expiredByDate) => {
  if (!pet) {
    return true;
  }

  const vaccinations = pet?.vaccinations ?? [];
  const requiredVaccinations = vaccinationList?.filter(
    ({ SpeciesId, IsActive } = {}) => SpeciesId === pet?.speciesId && IsActive !== false,
  );

  const isMissingVaccinations = requiredVaccinations?.some(reqVaccination => {
    return !vaccinations?.find(
      vaccination =>
        vaccination.vaccinationId === reqVaccination.VaccinationId &&
        vaccination.activeFlag &&
        vaccination.expirationDate &&
        !isExpiredByDate(vaccination.expirationDate, expiredByDate),
    );
  });

  return !!isMissingVaccinations;
};

/**
 * Helper to determine if a hotel appointment needs to be reviewed
 * @param {Object} obj
 * @param {Boolean} obj.hasPSA
 * @param {Boolean} obj.isMissingVaccinations
 * @param {Boolean} obj.needsReview
 * @param {Boolean} obj.receiveMedications
 * @param {Boolean} obj.hasSalonAppointment
 * @returns boolean
 */
export const reservationNeedsReview = ({
  hasPSA,
  isMissingVaccinations,
  needsReview,
  receiveMedications,
  hasSalonAppointment,
} = {}) =>
  !hasPSA || isMissingVaccinations || needsReview || receiveMedications || hasSalonAppointment;

/**
 * Helper to determine if a hotel pet card needs review color
 * @param {Object} appointment
 * @returns {String}
 */
export const reservationNeedsReviewBgColor = (appointment = {}) => {
  if (isEmpty(appointment)) return color.victorStone;

  return reservationNeedsReview(appointment) ? color.lightPink : color.victorStone;
};

export const getHotelCardIcons = values => {
  const {
    pet,
    vaccinationList,
    isCat,
    hasHealthCondition,
    hasSalonAppointment,
    hasStatusAlert,
    hasPSA,
    receiveMedications,
    isVIP,
    isPuppy,
    isSenior,
    isNew,
    isIndividual,
    isMissingVaccinations,
    invoicePaid,
    isOnlineBooking,
    hasNoBed,
    hasVetFee,
    hasDoggieValet,
    isGroupReservation,
    needsReview,
  } = values || {};
  const icons = [];

  if (hasHealthCondition) {
    icons.push(HealthImage);
  }

  if (hasSalonAppointment) {
    icons.push(SalonAppointmentImage);
  }

  if (hasStatusAlert) {
    icons.push(NotificationImage);
  }
  if (
    isUndefined(isMissingVaccinations)
      ? getIsMissingVaccination(pet, vaccinationList)
      : isMissingVaccinations
  ) {
    icons.push(VaccinationImage);
  }

  if (receiveMedications) {
    icons.push(MedicationImage);
  }

  if (isCat) {
    icons.push(CatImage);
  }

  if (!hasPSA) {
    icons.push(PSAImage);
  }

  if (isVIP) {
    icons.push(VIPImage);
  }

  if (isUndefined(isPuppy) ? getIsPuppy(pet) : isPuppy) {
    icons.push(PuppyImage);
  }

  if (invoicePaid) {
    icons.push(PaidImage);
  }

  if (isNew) {
    icons.push(NewPetImage);
  }

  if (hasNoBed) {
    icons.push(NoBedImage);
  }

  if (isOnlineBooking) {
    icons.push(DigitalBooking);
  }

  if (isIndividual) {
    icons.push(IndividualImage);
  }

  if (hasDoggieValet) {
    icons.push(DoggieValetImage);
  }

  if (hasVetFee) {
    icons.push(VetFeeImage);
  }

  if (isUndefined(isSenior) ? getIsSenior(pet?.birthDate) : isSenior) {
    icons.push(AgeImage);
  }

  if (isGroupReservation) {
    icons.push(LinkedImage);
  }

  if (needsReview) {
    icons.push(ReviewReservation);
  }

  return icons;
};
