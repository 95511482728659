import { connect } from "react-redux";
import AppointmentCardComponent from "./AppointmentCardComponent";
import { getAssociate, getPet } from "../../../../../core/selectors/entitiesSelector";
import { getBreeds } from "../../../../../core/selectors/enumsSelectors";
import { get } from "lodash/fp";

const mapStateToProps = (state, ownProps) => ({
  pet: getPet(state, { petId: get("ruleViolation.PetId", ownProps) }),
  breeds: getBreeds(state),
  associate: getAssociate(state, { associateId: get("ruleViolation.associateId", ownProps) })
});

export default connect(mapStateToProps)(AppointmentCardComponent);
