import React from "react";
import { connect } from "react-redux";

// Components
import { PetsHotelOverBookingDashboard } from "@/dux/petsHotelOverBookingDashboard/PetsHotelOverBookingDashboard";
import {
  OverBookingModifyExceptionDate,
  OverBookingNewExceptionDate,
} from "@/dux/overBookingNewExceptionDate/OverBookingNewExceptionDate";
import { LayoutBox } from "@/layout/box/Box";
import { Route, Routes } from "react-router-dom";
import { routePathPartials } from "@/core/constants/routePaths";
import { OverbookingModals } from "../overbookingModals/OverbookingModals";

export const HotelOverBooking = props => {
  const { componentId } = props;

  return (
    <LayoutBox id={componentId}>
      <Routes>
        <Route path="/*" element={<PetsHotelOverBookingDashboard />} />
        <Route
          path={routePathPartials.HOTEL_OVERBOOKING_NEW_EXCEPTION}
          element={<OverBookingNewExceptionDate />}
        />
        <Route
          path={`${routePathPartials.HOTEL_OVERBOOKING_MODFIY_EXCEPTION}/:exceptionId`}
          element={<OverBookingModifyExceptionDate />}
        />
      </Routes>

      <OverbookingModals />
    </LayoutBox>
  );
};

export const PetsHotelOverBooking = connect(state => {
  return {
    componentId: "PetsHotelOverBooking",
  };
})(HotelOverBooking);
