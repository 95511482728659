export const FREE_SERVICES_EXPIRE = "Free Services Expire"; // Was "Free Units Expire"
export const BASE_SERVICES_EXPIRE = "Base Services Expire"; // Was "Base Unit Expire"
export const FREE_SERVICES = "free services"; // Was "free units"
export const BASE_SERVICES = "base services"; // Was "base units"

// Using constant here instead of workflow feature type constants
// since feature flags can be removed over time.
export const TRAINING = "Training";
export const FTCO = "FTCO";
export const BGM = "BGM";
