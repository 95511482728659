import React from "react";
import styled from "styled-components";

import CommonButton from "../../../common/commonButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 1em;
`;

const Text = styled.div`
  margin: 3em 0;
  max-width: 350px;
  font-size: 16px;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 0 0 1em 0;
`;

const StartShiftModal = ({ logoutUser, hideModal }) => (
  <Container>
    <Text>Are you sure you want to log out and start a new shift?</Text>
    <ButtonContainer>
      <CommonButton
        width="200px"
        label="Start Shift"
        onClick={() => {
          logoutUser({ browserSessionOnly: true });
          hideModal();
        }}
      />
    </ButtonContainer>
  </Container>
);

export default StartShiftModal;
