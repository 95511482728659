import React from "react";
import { connect } from "react-redux";
import { Layout, Text } from "@prism/psm-ui-components";
import { getPetVaccinationsFormData } from "@/core/selectors/ui/vaccinations/vaccinationsSelectors";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import { EditPetVaccination } from "./EditPetVaccinationForm";
import { getVaccinationExpirationDateColor } from "./petVaccinationUtils";
import { DeleteVaccinationButton } from "./DeleteVaccinationButton";

function PetVaccinationsListComponent({ petVaccinations }) {
  if (!petVaccinations.length) {
    return <Text size="text-size-lg">No vaccinations on record</Text>;
  }

  return (
    <Layout.Stack space="stack-space-4">
      {petVaccinations.map(({ name, expirationDate, isExpired }) => (
        <Layout.Cluster
          justify="left"
          space="cluster-space-4"
          key={name}
          style={{ overflow: "visible", alignItems: "center" }}
        >
          <Text bold>{name}</Text>
          <Text color={getVaccinationExpirationDateColor(isExpired)}>Exp: {expirationDate}</Text>
          <EditPetVaccination name={name} />
          <DeleteVaccinationButton name={name} />
        </Layout.Cluster>
      ))}
    </Layout.Stack>
  );
}

export const PetVaccinationsList = connect((state) => ({
  isHidden: false,
  petVaccinations: getPetVaccinationsFormData(state, { petId: getSelectedPet(state) }),
}))(PetVaccinationsListComponent);
