import { STORE_REGEX } from "../../constants/regex";

/** @module storeNumberValidation */

/**
 * Verify if a string is a valid store number
 * @exports verifyStoreNumber
 * @param {string} storeStirng - store number
 * @return A boolean if the string is a valid store number or not
 */
export default function verifyStoreNumber(storeStirng) {
  const regXExpression = STORE_REGEX;
  return regXExpression.test(storeStirng);
}
