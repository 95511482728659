import { createSelector } from "reselect";
import { getPet } from "core/selectors/entitiesSelector";

// In the petCardComponent there was a perpetual re-render as the code pet || {} was utilized in the container, each time
// the redux connect function was invoked (each re-render), it would make this check. If there was no pet data returned,
// the redux connect function would return a new empty object, because of this, the component just kept re-rendering as
// the use effect was checking for a change in the object, it would get a new object each time, thus a new render.
// this was causing an unlimited number of XHR calls on the hotel dashboard api.
// I created this selector to solve this issue as we can take advantage of reselect's memoization.
export const getPetInfoOrMemoizedObject = createSelector([getPet], pet => {
  return pet || {};
});
