import { get } from "lodash/fp";
import { createSelector } from "reselect";
import getPersistentState from "../../../core/selectors/persistent/_persistentState";
import getFeatureFlagsState from "../../enableDisableFeature/selectors/_featureFlagsState";

export const getSystemType = createSelector([getPersistentState], persistentState =>
  get("systemType", persistentState),
);

export const getSystemBookingFlow = createSelector([getPersistentState], persistentState =>
  get("systemBookingFlow", persistentState),
);

/* * ----------------------------------------------------------------------- * *\
  Get Feature Flag States
\* * ----------------------------------------------------------------------- * */
export const getLoginSystemFeatureFlag = createSelector(
  [getFeatureFlagsState],
  featureFlags => featureFlags.SVCSART371,
);

export const getTabsSystemFeatureFlag = createSelector(
  [getFeatureFlagsState],
  featureFlags => featureFlags.SVCSART728,
);
