/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */

export const LOAD_TRAINING_CLASS_DETAILS = "LOAD_TRAINING_CLASS_DETAILS";
export const TOGGLE_COMPLETE_TRAINING_APPOINTMENT_MODAL =
  "TOGGLE_COMPLETE_TRAINING_APPOINTMENT_MODAL";
export const TOGGLE_CANCEL_TRAINING_APPOINTMENT_MODAL = "TOGGLE_CANCEL_TRAINING_APPOINTMENT_MODAL";
export const TOGGLE_RESCHEDULE_TRAINING_APPOINTMENT_MODAL =
  "TOGGLE_RESCHEDULE_TRAINING_APPOINTMENT_MODAL";
export const SET_RESCHEDULE_ATTENDEE_ID = "SET_RESCHEDULE_ATTENDEE_ID";
export const TOGGLE_CANCEL_TRAINING_APPOINTMENT_FLASH_MESSAGE =
  "TOGGLE_CANCEL_TRAINING_APPOINTMENT_FLASH_MESSAGE";
export const TOGGLE_RESCHEDULE_TRAINING_APPOINTMENT_FLASH_MESSAGE =
  "TOGGLE_RESCHEDULE_TRAINING_APPOINTMENT_FLASH_MESSAGE";
export const TOGGLE_RESEND_MEETING_LINK_FLASH_MESSAGE = "TOGGLE_RESEND_MEETING_LINK_FLASH_MESSAGE";
export const RESET_MANAGE_TRAINING_CLASS_PAGE = "RESET_MANAGE_TRAINING_CLASS_PAGE";
export const SET_RESCHEDULE_CLASS_SESSION_ID = "SET_RESCHEDULE_CLASS_SESSION_ID";
export const RESET_RESCHEDULE_CLASS_SESSION_ID = "RESET_RESCHEDULE_CLASS_SESSION_ID";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

export const loadTrainingClassDetails = (payload) => {
  return {
    type: LOAD_TRAINING_CLASS_DETAILS,
    payload,
  };
};

export const toggleCompleteTrainingAppointmentModal = () => {
  return {
    type: TOGGLE_COMPLETE_TRAINING_APPOINTMENT_MODAL,
  };
};

export const toggleCancelTrainingAppointmentModal = () => {
  return {
    type: TOGGLE_CANCEL_TRAINING_APPOINTMENT_MODAL,
  };
};

export const toggleRescheduleTrainingAppointmentModal = () => {
  return {
    type: TOGGLE_RESCHEDULE_TRAINING_APPOINTMENT_MODAL,
  };
};

export const setRescheduleAttendeeId = (attendeeId) => {
  return {
    type: SET_RESCHEDULE_ATTENDEE_ID,
    attendeeId,
  };
};

export const toggleCancelTrainingAppointmentFlashMessage = () => {
  return {
    type: TOGGLE_CANCEL_TRAINING_APPOINTMENT_FLASH_MESSAGE,
  };
};

export const toggleRescheduleTrainingAppointmentFlashMessage = () => {
  return {
    type: TOGGLE_RESCHEDULE_TRAINING_APPOINTMENT_FLASH_MESSAGE,
  };
};

export const toggleResendMeetingLinkFlashMessage = () => {
  return {
    type: TOGGLE_RESEND_MEETING_LINK_FLASH_MESSAGE,
  };
};

export const resetManageTrainingClassPage = () => ({
  type: RESET_MANAGE_TRAINING_CLASS_PAGE,
});

export const setRescheduleClassSessionId = (classSessionId) => ({
  type: SET_RESCHEDULE_CLASS_SESSION_ID,
  classSessionId,
});

export const resetRescheduleClassSessionId = (classSessionId) => ({
  type: RESET_RESCHEDULE_CLASS_SESSION_ID,
  classSessionId,
});
