import React from "react";
import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";

import { getStoreNumber } from "@/core/selectors/persistentSelectors";

import BookAppointmentSalonContainer from "./BookAppointmentSalonContainer";
import BookAppointmentHotelContainer from "./BookAppointmentHotelContainer";
import BookAppointmentTrainingContainer from "./BookAppointmentTrainingContainer";

/** ----------------------------------------------------------------------- **\
 COMPONENT - Show a book appointment button that redirects to the booking
  page, or a drop down to enter a store number if no store number
  is in the Redux-Store or Local Storage.
\** ----------------------------------------------------------------------- * */
function BookAppointmentButtonComponent({ isHidden, componentId }) {
  if (!isHidden) {
    return (
      <Layout.Box data-testid={componentId} id={componentId}>
        <BookAppointmentSalonContainer />
        <BookAppointmentHotelContainer />
        <BookAppointmentTrainingContainer />
      </Layout.Box>
    );
  }

  return null;
}

export const BookAppointmentButtons = connect((state) => ({
  isHidden: !getStoreNumber(state),
  componentId: "appointmentBookingButtons",
}))(BookAppointmentButtonComponent);
