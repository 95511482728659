export const serviceFrequencyTypes = {
  TODAY: "Today Only",
  TODAY_FORWARD: "Today & Moving Forward",
  ALL: "All Days",
  MANUAL: "Manual",
};

export const ROOM_ASSIGNMENT_DAY_CHANGE = [
  { label: serviceFrequencyTypes.TODAY_FORWARD, value: serviceFrequencyTypes.TODAY_FORWARD },
  { label: serviceFrequencyTypes.TODAY, value: serviceFrequencyTypes.TODAY },
  { label: serviceFrequencyTypes.ALL, value: serviceFrequencyTypes.ALL },
  { label: serviceFrequencyTypes.MANUAL, value: serviceFrequencyTypes.MANUAL },
];
