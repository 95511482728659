import React from "react";
import styled from "styled-components";
import StyledPopover from "../../../web/common/StyledPopover";
import AddOnsFlyout from "../../../web/cart/AddonsFlyoutContainer";
import StandaloneAddons from "./../standaloneAddons/StandaloneAddonsContainer";
import StandaloneAddButtonContainer from "./buttons/StandaloneAddButtonContainer";

const Wrapper = styled.div`
  flex: 1;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default class StandaloneServiceComponent extends React.Component {
  renderAddonsFlyout() {
    const { appointment, selectedPet, petServiceItem, closeAddonsFlyout } = this.props;
    if (!appointment) {
      return <div />;
    }
    return (
      <AddOnsFlyout
        selectedAddons={appointment.addons}
        mainHeading="Standalones"
        bottomHeading="All standalones"
        petId={selectedPet}
        onClose={() => closeAddonsFlyout()}
        petServiceId={appointment.petService}
        petServiceItem={petServiceItem}
      />
    );
  }

  render() {
    const {
      isHidden,
      componentID,
      selectedPetService,
      selectedPet,
      showAddons,
      closeAddonsFlyout
    } = this.props;

    return !isHidden ? (
      <Wrapper id={componentID}>
        <HeaderWrapper>
          <div>Stand alone services</div>

          {selectedPetService ? (
            <StyledPopover
              body={this.renderAddonsFlyout()}
              isOpen={showAddons}
              place="left"
              onOuterAction={() => closeAddonsFlyout()}
              tipSize={7}
            >
              <StandaloneAddButtonContainer />
            </StyledPopover>
          ) : (
            <div>None</div>
          )}
        </HeaderWrapper>
        {selectedPet && selectedPetService && <StandaloneAddons petId={selectedPet} />}
      </Wrapper>
    ) : null;
  }
}
