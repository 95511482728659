import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { WeeklyRow } from "./weeklyRow/WeeklyRowContainer";
import LoadingWrapper from "../../common/LoadingWrapper";
import { weekIds } from "../../../core/constants/associateSchedulingConstants";
import WeeklyTableAddButtonContainer from "../buttons/WeeklyTableAddButtonContainer";
import CopyPreviousWeekAddButtonContainer from "../buttons/CopyPreviousWeekAddButtonContainer";
import PartialDayRelocationAddButtonContainer from "../buttons/PartialDayRelocationAddButtonContainer";
import { LayoutBox } from "@/layout/box/Box";
import { Heading, TextPassage } from "@petsmart-ui/sparky";
import { WeeklyTableAssociateCell } from "web/schedules/associateCell";
import { LayoutCluster } from "@/layout/culster/Cluster";
import {
  getIsCatGroomingHidden,
  getWeeklyEditActivities,
  getWeeklyRowData,
} from "core/selectors/schedulesSelectors";
import { createLoadingSelector } from "core/selectors/utils";
import schedulesActionTypes from "core/actionTypes/schedulesActionTypes";
import { getSalonHours } from "core/selectors/salonHoursSelector";
import { loadSchedules } from "core/actionCreators/schedulesActionCreators";

/**
 * React view component of a table that displays info such as day fo the week, type of workday/time off, pet type support, work hours and lunch.
 *
 * @summary Located on the associate-scheduling page.
 * @memberOf Views.Associate
 * @function
 * @name  WeeklyTableComponent
 * @param { Object } props
 * @param { String } props.componentId - Block level Id for the component
 * @param { Number } props.associateId - ID that represents an associate
 * @param { Object } props.weeklyEditActivities - ?? Needs Description ??
 * @param { Boolean } props.isTemplateMode - To determine if the user is editing the associates schedule template
 * @param { Number } props.weekId - ID that represents the Week
 * @param { Boolean } props.isLoading - If the component is in a loading state
 * @param { Boolean } props.readOnly - ?? Needs description ??
 * @param { Boolean } props.isCatGroomingHidden - Determine if cat grooming is visible
 * @param { Object } props.weeklyRowData - Table Data
 * @param { Boolean } props.isHidden - determines if the component is visible
 * @returns {JSX.Element|null}
 *
 * @example < WeeklyTableComponent />
 */
export const WeeklyTableComponent = props => {
  // Short circuit view if it isHidden
  const { isHidden } = props;
  if (isHidden) return null;

  // value props
  const {
    componentId,
    associateId,
    weeklyEditActivities,
    isTemplateMode,
    weekId,
    isLoading,
    readOnly,
    isCatGroomingHidden,
    weeklyRowData,
  } = props;

  // Central variable for all <td> tags.
  const tableCellStyle = {
    padding: "10px 20px",
  };

  return (
    <LayoutBox id={componentId} borderWidth="scale-S10" padding="scale-0" borderColor="lightGrey">
      <LoadingWrapper display="block" displayContainer="block" isLoading={isLoading}>
        {!isEmpty(weeklyEditActivities) && (
          // TABLE
          <table id={`${componentId}__table`} style={{ borderSpacing: "0px" }}>
            {/*TABLE HEAD*/}
            <thead id={`${componentId}__tableHead`}>
              <tr>
                <td rowSpan="2" style={tableCellStyle}>
                  <WeeklyTableAssociateCell
                    associateId={associateId}
                    style={{ padding: "0px", border: "0px" }}
                  />
                </td>
                <td>
                  <Heading tagName="h4" size="headline" style={{ textAlign: "center" }}>
                    {"Type of Day"}
                  </Heading>
                </td>
                {!isCatGroomingHidden && (
                  <td style={tableCellStyle}>
                    <Heading tagName="h4" size="headline" style={{ textAlign: "center" }}>
                      {"Pet type supported"}
                    </Heading>
                  </td>
                )}
                <td style={tableCellStyle}>
                  <Heading tagName="h4" size="headline" style={{ textAlign: "center" }}>
                    {"Work hours"}
                  </Heading>
                </td>
                <td style={tableCellStyle}>
                  <Heading tagName="h4" size="headline" style={{ textAlign: "center" }}>
                    {"Lunch"}
                  </Heading>
                </td>
                <td rowSpan="2" style={{ ...tableCellStyle, verticalAlign: "bottom" }}>
                  {!readOnly && (
                    <React.Fragment>
                      {isTemplateMode && (
                        <React.Fragment>
                          <WeeklyTableAddButtonContainer />
                          {weekId === weekIds.week2 && <CopyPreviousWeekAddButtonContainer />}
                        </React.Fragment>
                      )}
                      <PartialDayRelocationAddButtonContainer />
                    </React.Fragment>
                  )}
                </td>
              </tr>

              <tr>
                <td />
                {!isCatGroomingHidden && <td />}
                <td style={tableCellStyle}>
                  <LayoutBox padding="scale-0">
                    <LayoutCluster space="scale-G3">
                      <TextPassage size="lg">Start Time:</TextPassage>
                      <TextPassage size="lg">End Time</TextPassage>
                    </LayoutCluster>
                  </LayoutBox>
                </td>
                <td style={tableCellStyle}>
                  <LayoutBox padding="scale-0">
                    <LayoutCluster space="scale-G3">
                      <TextPassage size="lg">Start Time:</TextPassage>
                      <TextPassage size="lg">End Time</TextPassage>
                    </LayoutCluster>
                  </LayoutBox>
                </td>
              </tr>
            </thead>

            {/*TABLE BODY*/}
            <tbody>
              {weeklyRowData.map(({ relocation, restWorkHours, day }) => {
                return (
                  <React.Fragment key={day}>
                    <WeeklyRow
                      dayOfWeek={day}
                      associateId={associateId}
                      rowData={restWorkHours}
                      isTemplateMode={isTemplateMode}
                      readOnly={readOnly}
                    />
                    {relocation?.length ? (
                      <WeeklyRow
                        dayOfWeek={""}
                        associateId={associateId}
                        rowData={relocation}
                        isTemplateMode={isTemplateMode}
                        readOnly={readOnly}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        )}
      </LoadingWrapper>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the WeeklyTableComponent
 *
 * @see {@link WeeklyTableComponent}
 * @summary Located on the associate-scheduling page.
 * @memberOf Views.Associate
 * @function
 * @name AssociateSchedulingWeeklyTable
 * @param { Object } props
 * @param { Type } props.associateId - Id that represents an associate
 * @returns {JSX.Element|null}
 *
 * @example <AssociateSchedulingWeeklyTable />
 */
export const AssociateSchedulingWeeklyTable = connect(
  (state, { associateId }) => {
    const weeklyEditActivities = getWeeklyEditActivities(state);

    return {
      componentId: "AssociateSchedulingWeeklyTable",
      isLoading: createLoadingSelector([
        schedulesActionTypes.LOAD_SCHEDULES,
        schedulesActionTypes.SET_SCHEDULES,
        schedulesActionTypes.LOAD_TEMPLATE_SCHEDULES,
        schedulesActionTypes.SET_TEMPLATE_SCHEDULES,
        schedulesActionTypes.LOAD_LUNCH_BREAK_TIMES,
      ])(state),
      weeklyEditActivities,
      salonHours: getSalonHours(state),
      isCatGroomingHidden: getIsCatGroomingHidden(state, { associateId }),
      weeklyRowData: getWeeklyRowData(weeklyEditActivities),
    };
  },

  dispatch => {
    return {
      loadSchedules: ({ associateId, fromDate }) => {
        dispatch(loadSchedules({ associateId, fromDate }));
      },
    };
  },
)(WeeklyTableComponent);
