import modalInitState from "./_modalInitState";
import dashboardActionTypes from "../../actionTypes/ui/web/dashboardActionTypes";
import getUpdatedModalState from "./helpers/getUpdatedModalState";

const initialState = modalInitState();

export default function dashboardModalsReducer(state = initialState, action) {
  return getUpdatedModalState(
    state,
    initialState,
    dashboardActionTypes.SHOW_DASHBOARD_MODAL,
    dashboardActionTypes.HIDE_DASHBOARD_MODAL,
    action
  );
}
