import React from "react";
import styled from "styled-components";
import NotificationImage from "@/assets/icons/notificaiton.svg";
import TextComponent from "@/dux/_components/genericLayoutComponents/TextComponent";

const ListItem = styled.li`
  display: table;
  counter-increment: orderedlist;
  padding: 0.3em 0 0.3em 1.4em;
  line-height: 1.5;
  text-align: left;
  ${({ hasAlertIcon }) =>
    hasAlertIcon &&
    `
      background-image: url(${NotificationImage});
      background-position: 0 9px;
      background-repeat: no-repeat;
      background-size: 1em;
  `}
  &:before {
    content: ${({ answerNumber }) => `"${answerNumber}."`};
    display: table-cell;
    width: 1.75em;
  }
`;

export default function HealthQuestionsListItemComponent({
  answerNumber,
  questionId,
  questionText,
  answer,
  hasAlertIcon,
  isAnswerBold,
}) {
  return (
    <ListItem key={questionId} hasAlertIcon={hasAlertIcon} answerNumber={answerNumber}>
      <TextComponent styleType="span" styleObj={{ marginRight: "0.5em" }} text={questionText} />
      <TextComponent
        styleType="span"
        styleObj={{
          fontWeight: isAnswerBold ? "bold" : "normal",
        }}
        text={answer}
      />
    </ListItem>
  );
}
