import * as React from 'react';
import * as CSS from 'csstype';
import { LayoutProps } from '../';
import { psx, pluck } from '../../../utils/';
import { CLUSTER_TOKENS } from './_tokens';

import './styles.css';

export interface ClusterProps extends LayoutProps<React.ElementType> {
  /** A CSS margin value. The minimum space between the clustered child elements. */
  space?: keyof typeof CLUSTER_TOKENS['SPACE'];
  /** A CSS justify-content value */
  justify?: CSS.Property.JustifyContent;
  /** A CSS align-items value */
  align?: CSS.Property.AlignItems;
  /** Completely removes the component from the DOM. Useful for feature flags or t hide or show
   * a component. */
  isHidden?: boolean;
}

/**
 * Use the `<Cluster/>` when dealing with groups of elements of an indeterminate size/shape,
 * and you want to distribute them in fluid way.
 *
 * Cluster components suit any groups of elements that differ in length and are liable to wrap.
 * Buttons that appear together at the end of forms are ideal candidates, as well as lists of tags,
 * keywords, or other meta information. Use the Cluster to align any groups of horizontally laid out
 * elements to the left or right, or in the center.
 *
 * [Source](https://every-layout.dev/layouts/cluster/)
 */
export const Cluster: React.FunctionComponent<ClusterProps> = ({
  space = 'cluster-space-0',
  justify = 'center',
  align = 'center',
  style = {},
  childOverrides = {},
  className,
  children,
  testid,
  as,
  isHidden = false,
  ...props
}) => {
  const id = 'psm-layout__cluster';
  const Component = as || 'div';
  const classNames = psx('psm', id, className);

  if (isHidden) {
    return null;
  }

  return (
    <Component
      className={classNames}
      data-testid={[id, testid].join('-')}
      style={{
        '--setClusterSpace': pluck(CLUSTER_TOKENS.SPACE, space),
        '--setClusterJustify': justify,
        '--setClusterAlign': align,
        ...style,
      }}
      {...props}
    >
      <div style={{ ...childOverrides }}>{children}</div>
    </Component>
  );
};
