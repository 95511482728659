// @ts-check

/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_HOTEL_MANAGE_ROOM_SUSPENSION_DETAILS = "SET_HOTEL_MANAGE_ROOM_SUSPENSION_DETAILS";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setHotelManageRoomSuspensionDetails = (/** @type {string} */ payload) => ({
  type: SET_HOTEL_MANAGE_ROOM_SUSPENSION_DETAILS,
  payload,
});
