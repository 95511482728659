import { connect } from "react-redux";
import PetParentAddress from "@/web/common/Address/PetParentAddress";
import { getCountriesOptions, getStatesOptions } from "@/core/selectors/enumsSelectors";
import getDisplayAddPetParentAddressForm from "@/core/selectors/customer/address/getDisplayAddPetParentAddressForm";
import customersActionCreators from "@/core/actionCreators/customersActionCreators";
import getDefaultPropsForAddAddressForm from "@/core/selectors/customer/address/getDefaultPropsForAddAddressForm";
import { createLoadingSelector } from "@/core/selectors/utils";
import customersActionTypes from "@/core/actionTypes/customersActionTypes";
import { GET_SEARCH_ADDRESS_BY_ZIP } from "@/dux/searchAddressByZip/searchAddressByZipActions";

const mapStateToProps = (state, { customerKey }) => {
  return {
    componentId: "AddPetParentAddressFormContainer",
    customerKey,
    address: getDefaultPropsForAddAddressForm(state, { customerKey }),
    isHidden: !getDisplayAddPetParentAddressForm(state, { customerKey }),
    countryOptions: getCountriesOptions(state),
    stateOptions: getStatesOptions(state),
    isLoading: createLoadingSelector([customersActionTypes.CREATE_CUSTOMER_ADDRESS])(state),
    isZipSearchLoading: createLoadingSelector([GET_SEARCH_ADDRESS_BY_ZIP])(state),

    // For Form Input
    isDisabled: false,
    isAddAddressButtonShown: true,
  };
};

const mapDispatchToProps = (dispatch, { customerKey }) => ({
  onSubmit: values => {
    dispatch(
      customersActionCreators.createCustomerAddress({
        customerKey,
        data: values,
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PetParentAddress);
