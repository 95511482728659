export default {
  CREATE_PET_VACCINATION: "CREATE_PET_VACCINATION",
  CREATE_PET_VACCINATION_REQUEST: "CREATE_PET_VACCINATION_REQUEST",
  CREATE_PET_VACCINATION_SUCCESS: "CREATE_PET_VACCINATION_SUCCESS",
  CREATE_PET_VACCINATION_FAILURE: "CREATE_PET_VACCINATION_FAILURE",
  DELETE_PET_VACCINATION: "DELETE_PET_VACCINATION",
  DELETE_PET_VACCINATION_REQUEST: "DELETE_PET_VACCINATION_REQUEST",
  DELETE_PET_VACCINATION_SUCCESS: "DELETE_PET_VACCINATION_SUCCESS",
  DELETE_PET_VACCINATION_FAILURE: "DELETE_PET_VACCINATION_FAILURE",
  UPDATE_PET_VACCINATIONS: "UPDATE_PET_VACCINATIONS",
  UPDATE_PET_VACCINATIONS_REQUEST: "UPDATE_PET_VACCINATIONS_REQUEST",
  UPDATE_PET_VACCINATIONS_SUCCESS: "UPDATE_PET_VACCINATIONS_SUCCESS",
  UPDATE_PET_VACCINATIONS_FAILURE: "UPDATE_PET_VACCINATIONS_FAILURE",
  SET_CUSTOMER_AND_PET_KEYS: "SET_CUSTOMER_AND_PET_KEYS",
  SET_VACCINATION_TO_DELETE: "SET_VACCINATION_TO_DELETE",
  CLEAR_CUSTOMER_AND_PET_KEYS: "CLEAR_CUSTOMER_AND_PET_KEYS",
  CLEAR_VACCINATION_TO_DELETE: "CLEAR_VACCINATION_TO_DELETE",
  SHOW_DROPDOWN: "SHOW_DROPDOWN",
  HIDE_DROPDOWN: "HIDE_DROPDOWN",
  TOGGLE_ADD_VACCINATION_FORM: "TOGGLE_ADD_VACCINATION_FORM",
};
