import { createSelector } from "reselect";
import moment from "moment";
import normalizeArrayByProperty from "../../../utils/normalizeUtils/normalizeArray";
import isExpired from "../../../utils/dateUtils/isExpired";
import { getPet, getItinerary } from "../../entitiesSelector";
import { getVaccinationsList } from "../../enumsSelectors";
import getVaccinationsState from "./_vaccinationsState";
import { getProps } from "../../commonSelector";

export const getPetVaccinations = createSelector([getPet], pet => (pet && pet.vaccinations) || []);

export const getPetSpeciesId = createSelector([getPet], pet => pet && pet.speciesId);

export const getRequiredPetVaccinationsByBreed = createSelector(
  [getVaccinationsList, getPetSpeciesId],
  (vaccinationsList, petSpeciesId) =>
    vaccinationsList.filter(({ SpeciesId }) => SpeciesId === petSpeciesId),
);

export const getRequiredPetVaccinationsList = createSelector(
  [getRequiredPetVaccinationsByBreed, getPetVaccinations],
  (requiredVaccinations, petVaccinations) => {
    const sortedVaccinations = requiredVaccinations.sort(
      (a, b) => (a.Name > b.Name) - (a.Name < b.Name),
    );
    const formFields = sortedVaccinations.reduce((accumulator, requiredVaccination) => {
      // Filter by active flag and sort by latest expiration date
      const petVaccination = petVaccinations
        .filter(vaccination => vaccination.activeFlag)
        .sort((a, b) => new Date(b.expirationDate) - new Date(a.expirationDate))
        .find(vaccination => vaccination.vaccinationId === requiredVaccination.VaccinationId);

      if (petVaccination) {
        if (petVaccination.expirationDate) {
          return {
            ...accumulator,
            // We need to explicitly set to work with UTC time since the moment constructor
            // translates to local time, causing  midnight of the current day i.e. 2023-11-30T00:00:00+00:00
            // to return the previous day (11/29/2023) instead. Details can be found in the following stack
            // overflow article: https://stackoverflow.com/questions/19648688/moment-js-returning-wrong-date
            [requiredVaccination.Name]: moment
              .utc(petVaccination.expirationDate)
              .format("YYYY-MM-DD"),
          };
        }

        return { ...accumulator, [requiredVaccination.Name]: "" };
      }
      return { ...accumulator };
    }, {});

    return formFields;
  },
);

export const getPetVaccinationsFormData = createSelector(
  [getRequiredPetVaccinationsList],
  vaccinations => {
    return Object.keys(vaccinations).map(key => ({
      name: key,
      expirationDate: vaccinations[key],
      isExpired: isExpired(vaccinations[key]),
    }));
  },
);

export const getPetVaccinationOptions = createSelector(
  [getRequiredPetVaccinationsByBreed, getPetVaccinations],
  (requiredVaccinations, petVaccinations) => {
    const sortedVaccinations = requiredVaccinations.sort(
      (a, b) => (a.Name > b.Name) - (a.Name < b.Name),
    );
    const vaccinationOptions = sortedVaccinations
      .filter(
        o1 => !petVaccinations.find(o2 => o1.VaccinationId === o2.vaccinationId && o2.activeFlag),
      )
      .map(vaccination => ({ value: vaccination.VaccinationId, label: vaccination.Name }));

    return vaccinationOptions;
  },
);

export const getPetHasExpiredVaccination = createSelector(
  [getPetVaccinationsFormData],
  formFields =>
    Object.values(formFields)
      .filter(Boolean)
      .some(formField => isExpired(formField)),
);

export const getVaccinationToDelete = createSelector(
  [getVaccinationsState],
  vaccinations => vaccinations.vaccinationToDelete,
);

export const getCustomerAndPetKeys = createSelector([getVaccinationsState], vaccinations => ({
  customerKey: vaccinations.customerKey,
  petId: vaccinations.petId,
}));

export const getShowVaccinationDropdown = createSelector(
  [getVaccinationsState],
  vaccinations => vaccinations.showDropdown,
);

export const getVaccinationVerification = (state, itineraryId) => {
  const itinerary = getItinerary(state, itineraryId);
  const petList = itinerary && itinerary.pets;

  const verificationList =
    petList &&
    petList.map(pet => {
      const currentPet = getPet(state, { petId: pet });
      const vaccination =
        currentPet &&
        currentPet.vaccinations.find(vacc => {
          if ((vacc.vaccinationId === 9 || vacc.vaccinationId === 11) && vacc.activeFlag) {
            return vacc;
          }
        });

      return vaccination
        ? { expirationDate: vaccination.expirationDate, pet: currentPet.petId }
        : { vaccination: false, pet: currentPet.petId };
    });

  if (verificationList) {
    return normalizeArrayByProperty(verificationList, "pet");
  }
};

export const getIsAddVaccinationFormOpen = createSelector(
  [getVaccinationsState],
  vaccinations => vaccinations.isAddVaccinationFormOpen,
);

export const getVaccinationIdsByName = createSelector(
  [getVaccinationsList, getPetVaccinations, getPetSpeciesId, getProps],
  (vaccinationsList, petVaccinations, speciesId, { name }) => {
    // UI displays latest expiration date vaccine
    // Sorting in reverse order to make latest vaccine to end of the list
    // normalizeArrayByProperty - will pick up the last record
    const sortedPetVaccinations = petVaccinations
      .filter(vaccination => vaccination.expirationDate && vaccination.activeFlag)
      .sort((a, b) => new Date(a.expirationDate) - new Date(b.expirationDate));

    const normalizedPetVaccinations = normalizeArrayByProperty(
      sortedPetVaccinations,
      "vaccinationId",
    );
    const currentVaccination = vaccinationsList.find(
      vaccination => vaccination.Name === name && vaccination.SpeciesId === speciesId,
    );

    const vaccinationId = currentVaccination?.VaccinationId;
    const petVaccinationId =
      normalizedPetVaccinations[vaccinationId]?.petVaccinationId ||
      petVaccinations.find(vaccination => vaccination.vaccinationId === vaccinationId)
        ?.petVaccinationId;
    return {
      vaccinationId,
      petVaccinationId,
    };
  },
);
