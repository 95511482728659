import * as React from 'react';
import { LayoutProps } from '../';
import { pluck, psx } from '../../../utils/';
import { GRID_TOKENS } from './_tokens';

import './styles.css';

export interface GridProps extends LayoutProps<React.ElementType> {
  /** A CSS grid-gap value. The space between the grid cells. */
  cellMinWidth?: keyof typeof GRID_TOKENS['CELL_MIN_WIDTH'];
  /* A CSS grid-template-columns value, specifically the min of the minmax function.
   * The min width the cell can be before it wraps.
   */
  cellSpacing?: keyof typeof GRID_TOKENS['CELL_SPACING'];
  /** Completely removes the component from the DOM. Useful for feature flags or t hide or show
   * a component. */
  isHidden?: boolean;
}

export const Grid: React.FunctionComponent<GridProps> = ({
  cellMinWidth = 'cell_min-width-60',
  cellSpacing = 'cell-space-0',
  style = {},
  className,
  children,
  testid,
  as,
  isHidden = false,
  ...props
}) => {
  const id = 'psm-layout__grid';
  const Component = as || 'div';
  const classNames = psx('psm', id, className);

  if (isHidden) {
    return null;
  }

  return (
    <Component
      className={classNames}
      data-testid={[id, testid].join('-')}
      style={{
        '--gridCellSpace': pluck(GRID_TOKENS.CELL_SPACING, cellSpacing),
        '--gridCellMinWidth': pluck(GRID_TOKENS.CELL_MIN_WIDTH, cellMinWidth),
        ...style,
      }}
      {...props}
    >
      {children}
    </Component>
  );
};
