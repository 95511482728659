import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getCurrentItinerary } from "@/core/selectors/checkInOutSelector";
import { fetchHotelInvoice } from "@/core/services/associateWebBff/hotelInvoiceEndpoints";
import {
  GET_HOTEL_INVOICE,
  getHotelInvoiceRequest,
  getHotelInvoiceSuccess,
  getHotelInvoiceFailure,
} from "./hotelInvoiceActions";
import { setHotelItineraryInvoice } from "../hotelItinerary/hotelItineraryActions";

/**
 * Saga to get hotel invoice and open in new window
 * @memberOf Sagas.hotel.itinerary
 * @generator
 * @name onGetHotelInvoice
 * @returns {Generator<*, void, *>}
 */
function* onGetHotelInvoice() {
  try {
    yield put(getHotelInvoiceRequest());

    const customerId = yield select(getCurrentCustomerKey);
    const itineraryId = yield select(getCurrentItinerary);

    const response = yield call(fetchHotelInvoice, { customerId, itineraryId });

    // This response is not a full itinerary so just update the itinerary state with the generated invoiceId
    const invoiceId = response?.data?.result?.invoiceId;
    yield put(setHotelItineraryInvoice({ invoiceId }));

    const url = response?.data?.result?.documentUrl;
    window.open(url);

    yield put(getHotelInvoiceSuccess());
  } catch (error) {
    yield put(getHotelInvoiceFailure(error));
  }
}

function* watchOnGetHotelInvoice() {
  yield takeLatest(GET_HOTEL_INVOICE, onGetHotelInvoice);
}

export default function* hotelInvoiceSaga() {
  yield all([watchOnGetHotelInvoice()]);
}
