import moment from "moment";
import { createSelector } from "reselect";
import { getCustomer, getAssociates } from "core/selectors/entitiesSelector";
import getNotesState from "core/selectors/ui/notes/_notesState";

export const selectCustomerNotes = createSelector(
  [getCustomer, getAssociates],
  (customer, associates) => {
    if (customer?.notes) {
      return customer?.notes
        .map(item => ({
          ...item,
          formatedTime: moment(item.timestamp).format("MMMM Do YYYY, h:mm a"),
          fullName: associates[item.associateId] && associates[item.associateId].associateName,
        }))
        .sort((a, b) => moment.utc(a.timestamp).diff(moment.utc(b.timestamp)));
    }

    return [];
  },
);

export const getShowNewCustomerNote = createSelector(
  [getNotesState],
  notes => notes && notes.showNewCustomerNote,
);
