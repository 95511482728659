import moment from "moment";

export const getSelectedDate = state => state?.selectedDate;

export const getFormattedSelectedDate = (state) => {
  const selectedDate = getSelectedDate(state);
  const isToday = moment(0, "HH").diff(selectedDate, "days") === 0;
  return moment(selectedDate).format(`[${isToday ? "Today, " : ""}] MMMM Do YYYY`);
}

