/**
 |--------------------------------------------------
 | * Takes an array of keys to omit from an object/array and the target object/array to remove
 | * keys/values from. We are writing our own implementation of omit , since Lodash's version
 | * omit will be removed in version 5.
 * @param {Array} keysToOmit An array of keys to remove from target array/object
 * @param {Array|Object} obj An array/object to remove keys/values from
 * @returns {String} A new object/array with keys/values omitted.
 |--------------------------------------------------
 */

export const omit = (keysToOmit, obj) => {
  if (Array.isArray(obj)) {
    return obj.filter(value => !keysToOmit.includes(value));
  }

  return Object.entries(obj)
    .filter(([key]) => !keysToOmit.includes(key))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};
