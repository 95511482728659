import { set } from "lodash/fp";
import { systemActionTypes } from "web/setSystemType/actions/setSystemTypeActions";
import { checkForUndefined } from "core/utils/undefinedOrNullUtils/undefinedOrNullUtils";

const initialState = null;
/**
 * Reducer function for managing the system type state.
 *
 * @memberof Reducer.persistent
 * @param {Object} state - The current state of the system type.
 * @param {Object} action - The action object passed to the reducer.
 * @returns {Object} - The new state object based on the action type.
 */
export const systemTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case systemActionTypes.SET_SYSTEM_TYPE:
      return checkForUndefined(action.systemType, initialState);

    default:
      return state;
  }
};
