import React from "react";
import { connect } from "react-redux";

// Components
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { OverBookingFilterPills } from "@/dux/overBookingFilterPills/OverBookingFilterPills";
import { OverbookingExceptionsTablePagination } from "@/dux/pagination/Pagination";

// Containers
import { OverBookingRoomTypeComboBox } from "@/dux/hotelRoomTypeSelection/hotelRoomTypeSelection";

// Actions
import { SET_HOTEL_ROOM_TYPES } from "@/dux/hotelRoomSelection/hotelRoomSelectionActions";

// Utils
import { createLoadingSelector } from "@/core/selectors/utils";
import { OverBookingExceptionTableDetails } from "../overbookingExceptionTableDetails/OverBookingExceptionTableDetails";

export const OverBookingException = props => {
  const { componentId, isHidden, isLoading } = props;

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack>
        <LayoutCluster style={{ justifyContent: "space-between" }}>
          <LoadingWrapper isLoading={isLoading}>
            <OverBookingRoomTypeComboBox />
          </LoadingWrapper>
          <OverBookingFilterPills />
        </LayoutCluster>

        <LayoutCluster style={{ justifyContent: "right" }}>
          <OverbookingExceptionsTablePagination />
        </LayoutCluster>
        <LayoutBox padding="scale-0">
          <OverBookingExceptionTableDetails />
        </LayoutBox>
        <LayoutCluster style={{ justifyContent: "right" }}>
          <OverbookingExceptionsTablePagination />
        </LayoutCluster>
      </LayoutStack>
    </LayoutBox>
  );
};

export const OverBookingExceptionDates = connect(state => {
  return {
    componentId: "OverBookingExceptionDates",
    isHidden: false,
    isLoading: createLoadingSelector([SET_HOTEL_ROOM_TYPES])(state),
  };
})(OverBookingException);
