/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const medicationsActionTypes = {
  SET_MEDICATIONS: "SET_MEDICATIONS",
  CLEAR_MEDICATIONS: "CLEAR_MEDICATIONS",
  PRESERVE_MEDICATIONS: "PRESERVE_MEDICATIONS",
  APPLY_PRESERVED_MEDICATIONS: "APPLY_PRESERVED_MEDICATIONS",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setMedications = ({ medications, petId }) => ({
  type: medicationsActionTypes.SET_MEDICATIONS,
  medications,
  petId,
});

export const clearMedications = () => ({
  type: medicationsActionTypes.CLEAR_MEDICATIONS,
});

export const preserveMedications = ({ medicationId, petId }) => ({
  type: medicationsActionTypes.PRESERVE_MEDICATIONS,
  medicationId,
  petId,
});

export const applyPreservedMedications = ({ medicationId, petId }) => ({
  type: medicationsActionTypes.APPLY_PRESERVED_MEDICATIONS,
  medicationId,
  petId,
});
