import { connect } from "react-redux";
import { getPetFullName, getPetPhoto } from "core/selectors/entitiesSelector";
import {
  getIsCurrentEngagementPriceGreaterThanZero,
  getPetsHavePaid,
} from "core/selectors/checkInOutSelector";
import { PAYMENT_MESSAGE, REDEEMED_MESSAGE } from "dux/modals/checkIn/constants/checkOutConstants";
import CheckOutModalContent from "../../../_components/modals/checkOut/single/CheckOutModalContent";

const mapStateToProps = (state, { customerKey, petId }) => {
  const petFullName = getPetFullName(state, { customerKey, petId });

  return {
    headerText: `Great Job! ${petFullName} has left the Salon`,
    isHidden: false,
    message: getIsCurrentEngagementPriceGreaterThanZero(state, { petId })
      ? PAYMENT_MESSAGE
      : REDEEMED_MESSAGE,
    petPhoto: getPetPhoto(state, { petId }),
    petsPaid: getPetsHavePaid(state),
  };
};

export default connect(mapStateToProps)(CheckOutModalContent);
