/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const setPetCartCardActionTypes = {
  ADD_CART_FOR_PET: "ADD_CART_FOR_PET",
  REMOVE_CART_FOR_PET: "REMOVE_CART_FOR_PET",
  CLEAR_CARTS_FOR_PETS: "CLEAR_CARTS_FOR_PETS",
  SHOW_HIDE_PET_CARD: "SHOW_HIDE_PET_CARD",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const addCartForPet = ({ petId }) => ({
  type: setPetCartCardActionTypes.ADD_CART_FOR_PET,
  petId,
});

export const removeCartForPet = ({ petId }) => ({
  type: setPetCartCardActionTypes.REMOVE_CART_FOR_PET,
  petId,
});

export const clearCartsForPets = () => ({
  type: setPetCartCardActionTypes.CLEAR_CARTS_FOR_PETS,
});

export const showHidePetCard = ({ petId, isPetCardShowing }) => ({
  type: setPetCartCardActionTypes.SHOW_HIDE_PET_CARD,
  petId,
  isPetCardShowing,
});
