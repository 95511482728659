import { associateWebBFFAPI } from "./_axiosConfigAssociateWebBff";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Endpoint for hotel dashboard appointments
 * @function getHotelDashboardAppointments
 * @param {string} fromDateTime
 * @param {string}  toDateTime
 * @param {string} storeNumber
 * @returns {*} AxiosPromise
 */
export function getHotelDashboardAppointments({ fromDateTime, toDateTime, storeNumber }) {
  return associateWebBFFAPI.get(
    `${endpointVersionString()}/hotel/stores/${storeNumber}/appointments?fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
  );
}
