import { associateWebProfileAPI } from "./_axiosConfigAssociateWebProfile";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Set a rating for a pet from the Web Associate Profile API -  Give a Rating for the pet end point
 * @param {*} { customerKey, petId, data }
 * @returns
 */
export function postPetFavorability({ customerKey, petId, data }) {
  return associateWebProfileAPI.post(
    `/${endpointVersionString()}/customers/${customerKey}/pets/${petId}/ratings?sourceId=${
      data.sourceId
    }`,
    data
  );
}

/**
 * Update a rating for a pet from the Web Associate Profile API -  Update Rating end point
 * @param {*} { customerKey, petId, ratingId, data }
 * @returns
 */
export function putPetFavorability({ customerKey, petId, ratingId, data }) {
  return associateWebProfileAPI.put(
    `/${endpointVersionString()}/customers/${customerKey}/pets/${petId}/ratings/${ratingId}?sourceId=${
      data.sourceId
    }`,
    data
  );
}
