import { getState } from "@/core/selectors/commonSelector";
import { createSelector } from "reselect";

// Helpers
import { sortTableDataByKeyAndSort } from "@/dux/_components/prismTable/prismTableHelpers";

// Utils
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import lowerCase from "lodash/lowerCase";
import intersection from "lodash/intersection";

/**
 * Selector to get selected report data
 * @memberOf Selectors.Reports
 * @function
 * @name selectSelectedReportData
 * @param {Object} state
 * @returns {Array} selected report data
 * @example selectSelectedReportData(state)
 */
export const selectSelectedReportData = createSelector([getState], state => {
  return state?.reports?.selectedReportData || [];
});

/**
 * Selector to get selected filters
 * @memberOf Selectors.Reports
 * @function
 * @name selectSelectedFilters
 * @param {Object} state
 * @returns {Array} selected filters
 * @example selectSelectedFilters(state)
 */
export const selectSelectedFilters = createSelector([getState], state => {
  return state?.reports?.selectedFilters || [];
});

/**
 * Selector to get selected sort info
 * @memberOf Selectors.Reports
 * @function
 * @name selectSelectedSortInfo
 * @param {Object} state
 * @returns {Object} selected sort info
 * @example selectSelectedSortInfo(state)
 */
export const selectSelectedSortInfo = createSelector([getState], state => {
  return state?.reports?.selectedSortInfo || {};
});

/**
 * Selector to get selected report data with sorted info
 * @memberOf Selectors.Reports
 * @function
 * @name selectSelectedReportDataWithSortInfo
 * @param {Object} state
 * @returns {Array} selected report data
 * @example selectSelectedReportDataWithSortInfo(state)
 */
export const selectSelectedReportDataWithSortInfo = createSelector(
  [selectSelectedReportData, selectSelectedSortInfo],
  (selectedReportData, selectedSortInfo) => {
    if (isEmpty(selectedSortInfo)) return selectedReportData || [];

    return sortTableDataByKeyAndSort({ data: selectedReportData, sortInfo: selectedSortInfo });
  },
);

/**
 * Selector to get selected report data with filtered columns
 * @memberOf Selectors.Reports
 * @function
 * @name selectSelectedReportDataWithFilters
 * @param {Object} state
 * @returns {Array} selected report data
 * @example selectSelectedReportDataWithFilters(state)
 */
export const selectSelectedReportDataWithFilters = createSelector(
  [selectSelectedReportDataWithSortInfo, selectSelectedFilters],
  (selectedReportData, selectedFilters) => {
    if (!selectedFilters.length) return selectedReportData || [];

    let filteredRecords = [];

    selectedFilters.forEach(filter => {
      const data = filteredRecords.length ? filteredRecords : selectedReportData;

      filteredRecords = data.filter(item => {
        const filterSet = filter.filterValues?.map(item => lowerCase(item?.value));

        if (filter.filterKey === "timeOfDay" && item[filter.filterKey]) {
          return (
            intersection(
              filterSet,
              item[filter.filterKey]?.split(",").map(item => lowerCase(item)),
            ).length > 0
          );
        }

        return includes(filterSet, lowerCase(item[filter.filterKey]));
      });
    });

    return filteredRecords;
  },
);
