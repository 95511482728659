import { put } from "redux-saga/effects";
import { setPetNeedsReview } from "dux/hotelCheckInOutPetNeedsReview/hotelCheckInOutPetNeedsReviewActions";
import { getNeedsReviewPropertyFromPets } from "dux/hotelCheckInOutPetNeedsReview/hotelCheckInOutPetNeedsReviewHelpers";

/**
 * Generator fn to place hotel pet needs review into state
 *
 *  @memberOf Sagas.hotel.itinerary
 *  @generator
 *  @name onNeedsReview
 *  @param {Object} payloadObj
 *  @param {Object} payloadObj.pets - Pet Object
 *  @param {number} payloadObj.petId - Pet Id
 *  @returns {Generator<*, void, *>}
 *  @example
 *
 *  yield call(onNeedsReview, { pets: dataObj.pets, petId });
 */
export function* onNeedsReview({ pets, petId }) {
  try {
    const needsReview = getNeedsReviewPropertyFromPets(pets, petId);

    yield put(setPetNeedsReview({ needsReview, petId }));
  } catch (error) {
    // add error action if api is added to saga
  }
}
