/**
 * Hotel Primary Service Change Actions
 */

export const PATCH_HOTEL_PRIMARY_SERVICE_CHANGE = "PATCH_HOTEL_PRIMARY_SERVICE_CHANGE";
export const PATCH_HOTEL_PRIMARY_SERVICE_CHANGE_REQUEST =
  "PATCH_HOTEL_PRIMARY_SERVICE_CHANGE_REQUEST";
export const PATCH_HOTEL_PRIMARY_SERVICE_CHANGE_SUCCESS =
  "PATCH_HOTEL_PRIMARY_SERVICE_CHANGE_SUCCESS";
export const PATCH_HOTEL_PRIMARY_SERVICE_CHANGE_FAILURE =
  "PATCH_HOTEL_PRIMARY_SERVICE_CHANGE_FAILURE";

export const patchHotelPrimaryServiceChange = ({ data, onComplete }) => ({
  type: PATCH_HOTEL_PRIMARY_SERVICE_CHANGE,
  data,
  onComplete,
});

export const patchHotelPrimaryServiceChangeRequest = () => ({
  type: PATCH_HOTEL_PRIMARY_SERVICE_CHANGE_REQUEST,
});

export const patchHotelPrimaryServiceChangeSuccess = () => ({
  type: PATCH_HOTEL_PRIMARY_SERVICE_CHANGE_SUCCESS,
});

export const patchHotelPrimaryServiceChangeFailure = error => ({
  type: PATCH_HOTEL_PRIMARY_SERVICE_CHANGE_FAILURE,
  error,
});
