import React, { useEffect } from "react";
import SetFrequencyFoodButtonContainer from "dux/bookingFeeding/setFoodFrequency/SetFrequencyFoodButtonContainer";
import AddNewBookingFoodButtonContainer from "dux/bookingFeeding/AddNewBookingFoodButtonContainer";
import { UpdateBookingFoodButtonContainer } from "dux/bookingFeeding/UpdateBookingFoodButtonContainer";
import FoodFrequencyErrorMessageContainer from "dux/frequency/FoodFrequencyErrorMessageContainer";
import FoodSaveToProfileCheckboxContainer from "dux/bookingFeeding/bookingSaveFoodToProfile/FoodSaveToProfileCheckboxContainer";
import FoodIsFromProfileCheckboxContainer from "dux/bookingFeeding/bookingIsFromProfile/FoodIsFromProfileCheckboxContainer";
import { fontSizes } from "web/common/styles/responsive/fonts";
import FoodTypeContainer from "../../../../web/foodType/FoodTypeContainer";
import FoodNameContainer from "../../../../web/foodName/FoodNameContainer";
import OtherFoodNameContainer from "../../../../web/otherFoodName/OtherFoodNameContainer";
import FoodTypeAmountContainer from "../../../../web/foodTypeAmount/FoodTypeAmountContainer";
import FoodTimeOfDayContainer from "../../../../web/foodTimeOfDay/FoodTimeOfDayContainer";
import FoodInstructionsContainer from "../../../../web/foodInstructions/FoodInstructionsContainer";
import CancelNewFoodButtonContainer from "../../../../web/addFood/buttons/CancelNewFoodButtonContainer";
import { isNewFood } from "../../../../web/food/foodUtils";
import { Layout } from "@prism/psm-ui-components";

const BookingFoodFormComponent = ({
  isHidden,
  foodId,
  foodIndex,
  foodIds,
  petId,
  locationType,
  isEditingFood = false,
  preserveOrApplyFoodData = () => {},
}) => {
  useEffect(() => {
    if (!isNewFood(foodId)) preserveOrApplyFoodData();
  }, [isEditingFood]);

  const newFoodIndex = isNewFood(foodId) ? foodIds.length + 1 : foodIndex;
  return !isHidden ? (
    <Layout.Box style={{ fontSize: fontSizes.regular }}>
      <Layout.Grid
        style={{
          gridTemplateColumns: "repeat(2, 1fr)",
          columnGap: "3.75rem",
          rowGap: "1.875rem",
          padding: "1.5rem 0",
        }}
      >
        <FoodTypeContainer foodIndex={newFoodIndex} foodId={foodId} petId={petId} />
        <FoodNameContainer foodIndex={newFoodIndex} foodId={foodId} petId={petId} />
        <OtherFoodNameContainer foodId={foodId} petId={petId} />
        <FoodTypeAmountContainer foodId={foodId} foodIndex={newFoodIndex} petId={petId} />
      </Layout.Grid>

      <Layout.Box>
        <FoodTimeOfDayContainer foodId={foodId} foodIndex={newFoodIndex} petId={petId} />
      </Layout.Box>

      <Layout.Box style={{ paddingTop: "1.5rem" }}>
        <FoodInstructionsContainer foodId={foodId} foodIndex={newFoodIndex} petId={petId} />
      </Layout.Box>

      <FoodSaveToProfileCheckboxContainer foodId={foodId} petId={petId} />
      <FoodIsFromProfileCheckboxContainer foodId={foodId} petId={petId} />

      <Layout.Cluster justify="space-between" style={{ padding: "15px 0px" }}>
        <SetFrequencyFoodButtonContainer foodId={foodId} petId={petId} />
        <Layout.Cluster style={{ marginLeft: "auto" }}>
          <UpdateBookingFoodButtonContainer
            foodId={foodId}
            petId={petId}
            locationType={locationType}
          />
          <AddNewBookingFoodButtonContainer
            foodId={foodId}
            petId={petId}
            locationType={locationType}
          />
          <CancelNewFoodButtonContainer foodId={foodId} petId={petId} locationType={locationType} />
        </Layout.Cluster>
      </Layout.Cluster>
      <FoodFrequencyErrorMessageContainer foodId={foodId} petId={petId} />
    </Layout.Box>
  ) : null;
};

export default BookingFoodFormComponent;
