import { put, call, select } from "redux-saga/effects";
import { getAvailableMockAssociateAccessLevels } from "../../services/systemAssociateAuth/mockAccessLevelEndPoints";
import { updateTestJobRoles } from "../../actionCreators/jobRoleActionCreators";
import { isCurrentEnvironment } from "../../utils/envUtils";
import { getIsAdmin } from "../../selectors/persistentSelectors";

/**
 * Generator/Saga function responsible for updating/displaying the job roles
 *
 * @param {*} { clientSessionToken }
 */
function* testJobRolesSaga({ clientSessionToken }) {
  const accessLevels = yield call(getAvailableMockAssociateAccessLevels, { clientSessionToken });
  const jobRoles = accessLevels.data;

  // dispatch action to display/Update the job roles
  yield put(updateTestJobRoles({ jobRoles }));
}

/**
 * Generator/Saga function responsible for determining the ability to change job roles
 *
 * @export retrieveTestJobRoles
 * @param {*} { clientSessionToken }
 */
export default function* retrieveTestJobRoles({ clientSessionToken }) {
  const isAdmin = yield select(getIsAdmin);

  // Anyone with Dev or QA access should have the ability to switch roles so
  // we do a negative check for prod or stage. IF not in Prod or stage, let them
  // change roles.
  if (!isCurrentEnvironment("prod") || !isCurrentEnvironment("stage")) {
    yield call(testJobRolesSaga, { clientSessionToken });
  }

  // IF in Prod/Stage Limited Admin/Full admin should have the ability to switch roles
  // in the PRISM UI (SRC/Leader/Associate) to validate issues. So if we have determined
  // the user is in PROD or Stage, we just verify they are an admin.
  if (isAdmin) {
    yield call(testJobRolesSaga, { clientSessionToken });
  }
}
