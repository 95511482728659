import { connect } from "react-redux";
import moment from "moment";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { eligibilityPetActionTypes } from "dux/eligibility/actions/eligibilityPetActions";
import { createLoadingSelector } from "../../../core/selectors/utils";
import {
  getAssociates,
  getStandaloneServiceByPet,
  getStoreTimeZoneOffset,
} from "../../../core/selectors/entitiesSelector";

import {
  getSelectedPet,
  getSelectedItinerary,
  getAvailableTimeSlotsByAssociate,
  getAvailableTimeSlotsWeekly,
  getAvailableTimeSlots,
  getTimeGroupsByDate,
  getCanFinalizeBooking,
  getIsDateChangingDisabled,
  getDateOfFirstPetServiceItem,
  getAvailableStandaloneAssociates,
} from "../../../core/selectors/bookingUISelectors";
import { getCartDetails, getAppointmentByPet } from "../../../core/selectors/cartSelectors";
import {
  selectPet,
  setItineraryId,
  loadAvailableTimeSlots,
  loadAvailableTimeSlotsByEngagement,
  setTimeSlotFromDashboard,
  bookAppointment,
} from "../../../core/actionCreators/bookingActionCreators";
import {
  getSelectedAssociate,
  getSelectedAssociateWeekly,
} from "../../../core/selectors/commonSelector";

import associateActionTypes from "../../../core/actionTypes/associateActionTypes";
import bookingActionTypes from "../../../core/actionTypes/bookingActionTypes";

import BookingTimeSlotComponent from "./BookingTimeSlotComponent";
import getStartOfWeekInPresent from "../../../core/utils/dateUtils/startOfWeekInPresent";
import {
  formatMoment,
  formatCalendarDateMoment,
} from "../../../core/utils/dateUtils/formatDateTime";
import {
  selectAssociateWeekly,
  setSelectTime,
} from "../../../core/actionCreators/ui/web/commonActionCreators";
import {
  createItineraryFromExistingItinerary,
  deleteItinerary,
} from "../../../core/actionCreators/itinerariesActionCreators";
import engagementsActionTypes from "../../../core/actionTypes/engagementsActionTypes";
import itinerariesActionTypes from "../../../core/actionTypes/itinerariesActionTypes";
import { getAppointment } from "../../../core/selectors/dashboardUiSelectors";
import petServiceItemsActionTypes from "../../../core/actionTypes/petServiceItemsActionTypes";
import { showConfirmationModal } from "../../../core/actionCreators/confirmationModalActionCreators";
import loadItinerariesHelper from "../../_helpers/itineraries/loadItinerariesHelper";
import { getStoreNumber } from "../../../core/selectors/persistentSelectors";

const mapStateToProps = (state, ownProps) => {
  const selectedPet = getSelectedPet(state);
  const isModify = ownProps.router.location.pathname.includes("modify-appointment");
  const storeNumber = getStoreNumber(state);

  return {
    standaloneService: getStandaloneServiceByPet(state, { petId: selectedPet }),
    isLoading: createLoadingSelector([
      associateActionTypes.LOAD_ASSOCIATES,
      engagementsActionTypes.CREATE_ENGAGEMENT,
      itinerariesActionTypes.CREATE_ITINERARY,
      petServiceItemsActionTypes.UPDATE_PET_SERVICE_ITEM,
      eligibilityPetActionTypes.GET_PET_ELIGIBILITY,
    ])(state),
    isDeletingItinerary: createLoadingSelector([itinerariesActionTypes.DELETE_ITINERARY]),
    isAvailableTimeSlotsLoading: createLoadingSelector([
      bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS,
      bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT,
    ])(state),
    associates: getAssociates(state),
    selectedDate: getSelectedDate(state),
    availableTimeSlots: getAvailableTimeSlots(state),
    availableStandaloneAssociates: getAvailableStandaloneAssociates(state),
    availableTimeSlotsByAssociate: getAvailableTimeSlotsByAssociate(state, { petId: selectedPet }),
    availableTimeSlotsWeekly: getAvailableTimeSlotsWeekly(state),
    timeSlotGroups: getTimeGroupsByDate(state),
    appointment: getAppointmentByPet(state, { petId: selectedPet }),
    appointments: getCartDetails(state),
    selectedAssociateWeekly: getSelectedAssociateWeekly(state),
    selectedPet,
    selectedItinerary: getSelectedItinerary(state),
    selectedAssociate: getSelectedAssociate(state),
    dashboardAppointment: getAppointment(state),
    isDateChangingDisabled: getIsDateChangingDisabled(state),
    dateOfFirstPetServiceItem: getDateOfFirstPetServiceItem(state),
    canFinalizeBooking: getCanFinalizeBooking(state),
    isModify,
    timeOffset: getStoreTimeZoneOffset(state, { storeNumber }),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectDate: selectedDate => {
    dispatch(setSelectDate(formatCalendarDateMoment(selectedDate)));
  },
  selectTime: selectedTime => {
    dispatch(setSelectTime(selectedTime));
  },
  loadItinerariesHandler: (selectedDate = moment()) => {
    // Abstract out dispatching load itineraries since it is used in multiple containers.
    loadItinerariesHelper(dispatch, selectedDate, false);
  },

  loadAvailableTimeSlots: ({ fromDate, petId, petServiceId, associateId }) => {
    dispatch(
      loadAvailableTimeSlots({
        customerKey: ownProps.customerKey,
        fromDate,
        petId,
        petServiceId,
        associateId,
      }),
    );
  },
  loadAvailableTimeSlotsByEngagement: ({ fromDate, associateId, engagementId, itineraryId }) => {
    dispatch(
      loadAvailableTimeSlotsByEngagement({
        customerKey: ownProps.customerKey,
        engagementId,
        itineraryId,
        fromDate,
        associateId,
      }),
    );
  },
  loadWeeklyAvailableTimeSlots: ({
    selectedDate,
    petId,
    petServiceId,
    selectedAssociateWeekly,
  }) => {
    dispatch(
      loadAvailableTimeSlots({
        customerKey: ownProps.customerKey,
        fromDate: formatMoment(getStartOfWeekInPresent(selectedDate)),
        toDate: formatMoment(moment(selectedDate).endOf("isoWeek")),
        associateId: selectedAssociateWeekly,
        petId,
        petServiceId,
      }),
    );
  },
  loadWeeklyAvailableTimeSlotsByEngagement: ({
    selectedDate,
    petId,
    itineraryId,
    engagementId,
    selectedAssociateWeekly,
  }) => {
    dispatch(
      loadAvailableTimeSlotsByEngagement({
        customerKey: ownProps.customerKey,
        fromDate: formatMoment(getStartOfWeekInPresent(selectedDate)),
        toDate: formatMoment(moment(selectedDate).endOf("isoWeek")),
        associateId: selectedAssociateWeekly,
        petId,
        itineraryId,
        engagementId,
      }),
    );
  },
  setTimeSlotFromDashboard: ({ fromDate, petId, associateId, petServiceId }) => {
    dispatch(
      setTimeSlotFromDashboard({
        customerKey: ownProps.customerKey,
        fromDate,
        petId,
        associateId,
        petServiceId,
      }),
    );
  },
  onSelectPet: petId => dispatch(selectPet(petId)),
  onSelectAssociate: associateId => dispatch(selectAssociateWeekly({ associateId })),
  onBookAssociate: ({ timeSlot, selectedPetService }) => {
    dispatch(
      bookAppointment({
        timeSlot,
        customerKey: ownProps.customerKey,
        selectedPetService,
      }),
    );
  },
  onAutoBook: ({ timeSlot, selectedPetService }) => {
    dispatch(
      bookAppointment({
        timeSlot,
        customerKey: ownProps.customerKey,
        selectedPetService,
      }),
    );
  },
  onSetItineraryId: itineraryId => dispatch(setItineraryId(itineraryId)),
  onCreateItineraryFromExistingItinerary: () => {
    const {
      router: {
        params: { customerKey, itineraryId },
      },
    } = ownProps;
    dispatch(createItineraryFromExistingItinerary({ itineraryId, customerKey }));
  },
  onDeleteItinerary: itineraryId => {
    dispatch(deleteItinerary({ itineraryId }));
  },
  showConfirmationModal: args => dispatch(showConfirmationModal(args)),
});

export default withRouteProps(connect(mapStateToProps, mapDispatchToProps)(BookingTimeSlotComponent));
