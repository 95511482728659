import React from "react";
import styled from "styled-components";
import CardList from "../../upcoming_here/cardList/CardListContainer";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1021px;
`;

export default function PetCard({
  upcomingAppointments,
  hereAppointments,
  setHereOrCompletedFilterApplied,
  hereOrCompletedFilterApplied
}) {
  return (
    <SectionWrapper>
      <CardList appointments={upcomingAppointments} title="Upcoming" />
      <CardList
        appointments={hereAppointments}
        title={hereOrCompletedFilterApplied}
        setHereOrCompletedFilterApplied={setHereOrCompletedFilterApplied}
        hereOrCompletedFilterApplied={hereOrCompletedFilterApplied}
        isFilterableList
      />
    </SectionWrapper>
  );
}
