import { CLEAR_HOTEL_ITINERARY } from "../hotelItinerary/hotelItineraryActions";
import { SET_RESERVATION_NOTES } from "./hotelCheckInOutPetNotesActions";

const initialState = {};

const hotelCheckInOutPetNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESERVATION_NOTES:
      return {
        ...state,
        [action?.petId]: action?.notes,
      };
    case CLEAR_HOTEL_ITINERARY:
      return initialState;
    default:
      return state;
  }
};

export default hotelCheckInOutPetNotesReducer;
