/**
 * Responsible for Taking an eligibility object and adding a "isSecondary" property to each object within the reasons array.
 * @param {object} primaryEngineResult - eligibility object, example: {reasons: Array(2), canBook: true, petId: 1000002654}
 * @return {object}
 */
const addFlagToPrimaryResult = primaryEngineResult => {
  return {
    ...primaryEngineResult,
    reasons: primaryEngineResult.reasons.map(reason => ({
      ...reason,
      isSecondary: false,
    })),
  };
};

// NOTE: fires on PPP : on when book appt button is clicked: On check in
/**
 * ?????
 * @param {object} primaryEngineResult - eligibility object, example: {reasons: Array(2), canBook: true, petId: 1000002654}
 * @param {object} secondaryEngineResult - eligibility object, example: {reasons: Array(0), canBook: true, petId: 1000002654}
 * @return {{reasons: *[], canBook: boolean}|*}
 */
// eslint-disable-next-line consistent-return
export default function mergeEnginesResult({ primaryEngineResult, secondaryEngineResult }) {
  // console.group('mergeEnginesResult')
  // console.log('primaryEngineResult ', primaryEngineResult);
  // console.log('secondaryEngineResult ', secondaryEngineResult);

  // if there is no primary or secondary engine result....
  if (!primaryEngineResult && !secondaryEngineResult) {
    // console.log('are no primary and secondary Eng results, so we do noting');
  } else if (!primaryEngineResult) {
    // console.log('There is no primary Eng result so rturn secondary eng result ', secondaryEngineResult);
    return secondaryEngineResult;
  } else if (!secondaryEngineResult) {
    // console.log('no secondar eng result so check if primary eng result is an array ', Array.isArray(primaryEngineResult));
    // console.log('if so map rought priamry and return ', primaryEngineResult.map(result => addFlagToPrimaryResult(result)));
    // console.log('if not, just return ', addFlagToPrimaryResult(primaryEngineResult));
    return Array.isArray(primaryEngineResult)
      ? primaryEngineResult.map(result => addFlagToPrimaryResult(result))
      : addFlagToPrimaryResult(primaryEngineResult);
  } else if (Array.isArray(primaryEngineResult) || Array.isArray(secondaryEngineResult)) {
    // console.log("primary or secondary is an array, check if they are not.");
    if (!Array.isArray(primaryEngineResult) || !Array.isArray(secondaryEngineResult)) {
      // console.log("primary or secondary is NOT an array, Throw error");
      throw new Error("Can not merge array with an object");
    }

    // console.log('map through primary engine result and...');
    const mappedResult = primaryEngineResult.map(primaryResult => {
      const secondaryResult = secondaryEngineResult.find(
        elem => elem.petId === primaryResult.petId,
      );
      if (!secondaryResult) {
        return primaryResult;
      }
      return {
        petId: primaryResult.petId,
        canBook: primaryResult.canBook && secondaryResult.canBook,
        reasons: [...primaryResult.reasons, ...secondaryResult.reasons],
      };
    });

    // console.log('return result', foo);
    return mappedResult;
  } else {
    const mergedRessons = {
      canBook: primaryEngineResult.canBook && secondaryEngineResult.canBook,
      reasons: [...primaryEngineResult.reasons, ...secondaryEngineResult.reasons],
    };

    // console.log('no checks were completed so return ', mergedRessons;);
    return mergedRessons;
  }
}
