/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelOverNightHeaderSearchActionTypes = {
  SET_HOTEL_OVER_NIGHT_SEARCH: "SET_HOTEL_OVER_NIGHT_SEARCH",
  CLEAR_HOTEL_OVER_NIGHT_SEARCH: "CLEAR_HOTEL_OVER_NIGHT_SEARCH",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setHotelOverNightSearchText = ({ value }) => ({
  type: hotelOverNightHeaderSearchActionTypes.SET_HOTEL_OVER_NIGHT_SEARCH,
  value,
});

export const clearHotelOverNightSearchText = () => ({
  type: hotelOverNightHeaderSearchActionTypes.CLEAR_HOTEL_OVER_NIGHT_SEARCH,
});
