import normalizeFormInput from "../../normalizeUtils/normalizeFormInput";

/**
 * Responsible for taking an address and normalizing out select values.
 *
 *
 * @param {Object} address
 * @returns
 */
export default function normalizeAddressValues(address) {
  // Add additional value pairs as needed
  return {
    firstName: normalizeFormInput(address, "firstName"),
    lastName: normalizeFormInput(address, "lastName"),
    streetLine1: normalizeFormInput(address, "streetLine1"),
    streetLine2: normalizeFormInput(address, "streetLine2"),
    city: normalizeFormInput(address, "city"),
    stateProvinceAbbreviation: normalizeFormInput(address, "stateProvinceAbbreviation"),
    countryAbbreviation: normalizeFormInput(address, "countryAbbreviation"),
    zipPostalCode: normalizeFormInput(address, "zipPostalCode"),
    phoneNumber: normalizeFormInput(address, "phoneNumber")
  };
}
