import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { color } from "../../common/styles/theme";
import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { pageNames } from "@/core/constants/checkInOutConstants";
import { Layout } from "@prism/psm-ui-components";
import { ReactComponent as SalonIcon } from "@/assets/icons/dog-fsg-white.svg";
import { ReactComponent as TrainingIcon } from "@/assets/icons/graduation-cap-white.svg";
import { routePaths } from "@/core/constants/routePaths";
import PetInfoContainer from "../profilePetInfo/petInfoContainer";

const SectionWrapper = styled.section`
  display: flex;
  gap: 1rem;
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  cursor: ${props => (props.isDisabled ? "no-drop" : "pointer")};
  border: ${props => `1px solid ${props.isSelectedAppointment ? color.kalEl : color.ororoMunroe}`};
  &:hover {
    border: ${props =>
      props.isDisabled ? `1px solid ${color.ororoMunroe}` : `1px solid ${color.kalEl}`};
  }
`;

function ProfilePetCardEngagementTypeIcon({ engagementType = "" }) {
  return (
    <Layout.Box>
      {(() => {
        switch (engagementType) {
          case engagementTypes.GROOMING:
            return <SalonIcon style={{ backgroundColor: "#1777f2" }} />;
          case engagementTypes.TRAINING:
            return <TrainingIcon style={{ backgroundColor: "#1777f2" }} />;
          default:
            return null;
        }
      })()}
    </Layout.Box>
  );
}

function PetCard(props) {
  const {
    appointmentID,
    associateId,
    customerKey,
    itineraryId,
    engagement,
    isEngagementTypeIconHidden,
    dispatchOnSelectAssociate,
    dispatchOnSelectAppointment,
    setPetServiceItem,
    showDate,
    showStatus,
    type,
    fromDashboard,
    fromPetParent,
    isSelectedAppointment,
    showErrorModal,
    selectPet,
    selectedPet,
    setCustomerKeyAndItineraryId,
    isDisabled,
    dispatchShowQuickView,
    dispatchHideQuickView,
  } = props;

  const navigate = useNavigate();

  return (
    <SectionWrapper
      isSelectedAppointment={isSelectedAppointment}
      isDisabled={isDisabled}
      onClick={event => {
        if (isDisabled) {
          event.preventDefault();
          return false;
        }
        selectPet(selectedPet);

        if (fromDashboard) {
          dispatchOnSelectAssociate({ associateId: "" });

          // NOTE, the dispatchOnSelectAppointment was removed in the upcoming and here petCard to fix a rebook error
          // as the appointment ID was being lost when the rebook button and/or link was being
          // clicked, this reference may need to be removed as well.
          dispatchOnSelectAppointment({ appointmentId: "" });

          setPetServiceItem(Number(appointmentID));

          navigate(
            `/${
              type === "Here" ? pageNames.CHECK_OUT : pageNames.CHECK_IN
            }/${customerKey}/${itineraryId}`,
          );
        } else if (fromPetParent) {
          setPetServiceItem(Number(appointmentID));
          dispatchOnSelectAppointment({ appointmentId: appointmentID });
          if (engagement.engagementType === engagementTypes.TRAINING) {
            setCustomerKeyAndItineraryId(customerKey, itineraryId);
            navigate(routePaths.MANAGE_TRAINING_CLASS);
          } else {
            navigate(`/${type}-appointment/${customerKey}/${itineraryId}`);
          }
        }
      }}
      onMouseEnter={e => {
        if (fromDashboard) {
          dispatchOnSelectAssociate({ associateId });
          dispatchOnSelectAppointment({ appointmentId: appointmentID });
        } else if (appointmentID && isDisabled) {
          const xLoc = e.clientX;
          const yLoc = e.clientY;
          dispatchShowQuickView({ appointmentID, xLoc, yLoc });
        }
      }}
      onMouseLeave={() => {
        if (fromDashboard) {
          dispatchOnSelectAssociate({ associateId: "" });
          dispatchOnSelectAppointment({ appointmentId: "" });
        } else if (appointmentID && isDisabled) {
          dispatchHideQuickView();
        }
      }}
    >
      <PetInfoContainer
        petServiceItemId={appointmentID}
        showDate={showDate}
        showStatus={showStatus}
        type={type}
        showErrorModal={showErrorModal}
        style={{ paddingTop: "15px" }}
      />
      {isEngagementTypeIconHidden ? (
        <ProfilePetCardEngagementTypeIcon engagementType={engagement?.engagementType} />
      ) : null}
    </SectionWrapper>
  );
}

export default PetCard;
