import { schema, normalize } from "normalizr";
import { set, map, get, forEach } from "lodash/fp";
import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";

const allPets = new Set();
const allCustomers = new Set();
let dynamicPrices = {
  petServiceItems: {},
  addOns: {},
};
let specials = [];

const associate = new schema.Entity(
  "associates",
  {},
  {
    idAttribute: "associateId",
    mergeStrategy: (prev, current) => ({
      ...current,
      petServiceItems: prev.petServiceItems.concat(current.petServiceItems),
    }),
    processStrategy: (value, parent, key) => ({
      ...value,
      petServiceItems: [parent.petServiceItemId],
    }),
  },
);

const petServiceItem = new schema.Entity(
  "petServiceItems",
  {
    associate,
  },
  {
    idAttribute: "petServiceItemId",
    processStrategy: (value, parent) => {
      let addOns = [];
      let enhancedServices = {};

      if (value.dynamicPrice) {
        const dynamicPriceWithSpecials = set(
          "specials",
          map(special => special.code, value.dynamicPrice.specials),
          value.dynamicPrice,
        );
        dynamicPrices = set(
          ["petServiceItems", value.petServiceItemId.toString()],
          dynamicPriceWithSpecials,
          dynamicPrices,
        );

        if (value.dynamicPrice.specials) {
          specials = [...specials, ...value.dynamicPrice.specials];
        }
      }

      if (value.addOns?.length) {
        addOns = value.addOns.filter((addon) => !addon.isEnhancedAddOn);
        enhancedServices = value.addOns.find((addon) => addon.isEnhancedAddOn);
      }

      return {
        ...value,
        addOns,
        enhancedServices,
        itinerary: parent.itinerary,
        engagement: parent.engagementId,
        pet: parent.pet,
        customer: parent.customer,
        storeNumber: parent.storeNumber,
        itineraryStatus: parent.itineraryStatus,
        engagementType: parent.engagementType,
      };
    },
  },
);

const engagement = new schema.Entity(
  "engagements",
  {
    petServiceItems: [petServiceItem],
  },
  {
    idAttribute: "engagementId",
    processStrategy: (value, parent, key) => ({
      ...value,
      itinerary: parent.itinerary,
      pet: parent.petId,
      customer: parent.customer,
      itineraryStatus: parent.itineraryStatus,
    }),
  },
);

const pet = new schema.Entity(
  "pets",
  {
    engagements: [engagement],
  },
  {
    idAttribute: "petId",
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        itinerary: parent.itineraryId,
        customer: parent.customerId,
        itineraryStatus: parent.status,
      };
    },
  },
);

const itinerary = new schema.Entity(
  "itineraries",
  {
    pets: [pet],
  },
  {
    idAttribute: "itineraryId",
    processStrategy: (value, parent, key) => {
      allCustomers.add(value.customerId);
      const engagements = new Set();
      const petServiceItems = new Set();
      const appliedSpecials = new Set();

      value.pets.forEach(pet => {
        allPets.add(pet.petId);
        pet.engagements.forEach(engagement => {
          engagements.add(engagement.engagementId);
          engagement.petServiceItems.forEach(petServiceItem => {
            petServiceItems.add(petServiceItem.petServiceItemId);

            const petServiceItemSpecials = get(["dynamicPrice", "specials"], petServiceItem);
            forEach(special => {
              appliedSpecials.add(special.code);
            }, petServiceItemSpecials);

            if (petServiceItem.addOns && petServiceItem.addOns.length) {
              forEach(addOn => {
                const addOnSpecials = get(["dynamicPrice", "specials"], addOn);
                forEach(special => {
                  appliedSpecials.add(special.code);
                }, addOnSpecials);
              }, petServiceItem.addOns);
            }
          });
        });
      });

      return {
        ...value,
        itineraryStatus: value.status,
        engagements: Array.from(engagements),
        petServiceItems: Array.from(petServiceItems),
        appliedSpecials: Array.from(appliedSpecials),
      };
    },
  },
);

const itinerariesList = [itinerary];

export default function normalizeItineraries(itinerariesResponse) {
  if (!itinerariesResponse) {
    throw new Error("Itineraries must be supplied");
  }
  const { entities } = normalize(
    itinerariesResponse,
    Array.isArray(itinerariesResponse) ? itinerariesList : itinerary,
  );

  return {
    itineraries: entities.itineraries || {},
    engagements: entities.engagements || {},
    petServiceItems: entities.petServiceItems || {},
    associates: entities.associates || {},
    pets: Array.from(allPets) || [],
    customers: Array.from(allCustomers || []),
    dynamicPrices,
    specials: normalizeArrayByProperty(specials, "code"),
  };
}
