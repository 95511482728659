/**
Filters a source object based on an array of keys and outputs an array of objects.
Each object contains a single key-value pair from the source object. The order will
preserved based on keys array

@memberOf Utils.Object
@function
@param {string[]} keys - Array of strings representing the keys to filter.
@param {Object} source - The source object to filter.
@returns {Object[]} An array of objects with only the keys that matched.

@example
const keys = ["petName", "breed", "roomNumber"];
const sourceObject = {
customerName: "John Doe",
breed: "Goldendoodle",
gender: "Male",
roomNumber: 101,
petName: "Duke",
};

const filteredArray = filterAndListObjectByKeys(keys, sourceObject);
console.log(filteredArray);
// Output: [{ petName: "Duke" }, { breed: "Goldendoodle" }, { roomNumber: 101 }]
*/
export const filterAndListObjectByKeys = (keys, source) => {
  return keys.reduce((filteredArray, key) => {
    if (key in source) {
      filteredArray.push({ [key]: source[key] });
    }
    return filteredArray;
  }, []);
};
