import React from "react";
import { connect } from "react-redux";
import getUnknownPetImage from "core/utils/assetUtils/unknownPetImage";
import HotelBookingCardIconContainer from "dux/hotelPetCardIcon/HotelBookingCardIconContainer";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { TextPassage } from "@petsmart-ui/sparky";
import { LayoutStack } from "@/layout/stack/Stack";
import { selectHotelCartPetPrimaryServiceProduct } from "dux/servicesCartHotel/servicesCartHotelSelectors";
import { getCustomer, getPet, getPetBreedName } from "core/selectors/entitiesSelector";
import calculateAge from "core/utils/dateUtils/calculateAge";
import { getPetMissingVaccinations } from "dux/eligibility/selectors/eligibilityVaccinationsSelectors";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import moment from "moment/moment";

export const ReservationPetCard = props => {
  const { isHidden } = props;

  if (isHidden) {
    return null;
  }

  const {
    componentId,
    primaryService,
    customer,
    petId,
    petName,
    petAge,
    pet,
    missingVaccinations,
    petBreed,
    checkInDateTime,
    checkOutDateTime,
    dropOffTime,
    pickUpTime,
  } = props;

  return (
    <LayoutBox id={componentId} borderColor="mediumGrey" borderWidth="scale-S6">
      <LayoutCluster>
        {/* ICON */}
        <LayoutBox id={`${componentId}__petIcon`} padding="scale-0">
          <img alt="petImage" src={getUnknownPetImage(pet)} />
        </LayoutBox>

        {/* PET INFO */}
        <LayoutBox id={`${componentId}__petInfo`} padding="scale-0">
          <LayoutStack space="scale-0">
            <LayoutBox padding="scale-0">
              {/* PET INFO */}
              <TextPassage>
                <span>{`${petName} ${customer.lastName}, `}</span>
                <span>{`${petAge} `}</span>
                <span>{`${petBreed} `}</span>
              </TextPassage>
            </LayoutBox>

            {/* PRIMARY SERVICE */}
            <LayoutBox padding="scale-0">
              <TextPassage>
                <span>{primaryService}</span>
              </TextPassage>
            </LayoutBox>

            {/* CHECK IN DATE */}
            <LayoutBox padding="scale-0">
              <TextPassage>
                <span>{checkInDateTime}</span>
              </TextPassage>
            </LayoutBox>

            {/* CHECK OUT TIME */}
            <LayoutBox padding="scale-0">
              <TextPassage>{checkOutDateTime}</TextPassage>
            </LayoutBox>

            {/* MISSING VACCINATIONS */}
            <LayoutBox padding="scale-0">
              {missingVaccinations.map(missingVaccination => (
                <LayoutCluster space="scale-S1">
                  <LayoutBox padding="scale-0">
                    {`Missing vaccination: ${missingVaccination.description}`}
                  </LayoutBox>
                </LayoutCluster>
              ))}
            </LayoutBox>

            {/* ICONS */}
            <LayoutBox padding="scale-0">
              <HotelBookingCardIconContainer petId={petId} />
            </LayoutBox>
          </LayoutStack>
        </LayoutBox>
      </LayoutCluster>
    </LayoutBox>
  );
};

/**
 * NOTE this redux container was originally
 * packages/associate-ui/src/dux/appointmentSet/AppointmentSetPetCardContainer.js
 * and was deprecated and deleted as of ticket SVCSART-23856
 */
export const HotelReservationPetCard = connect((state, { pet }) => {
  const { petId, petName, startDate, endDate, dropOffTime, pickUpTime } = pet;
  const cartServiceInformation = selectHotelCartPetPrimaryServiceProduct(petId)(state);

  // pet age and breed is not included in the cart response (where we get pet id and petName start date, end dates etc. from)
  // We use the pet id from the cart to get the full pet data from state.entities.pets, this will provide age and breed id etc.
  const petData = getPet(state, { petId });
  const petAge = petData && calculateAge(petData.birthDate);
  const petBreed = petData && getPetBreedName(state, { breedId: petData.breedId });
  const missingVaccinations = getPetMissingVaccinations(state, { petKey: petId });
  const customerKey = getCurrentCustomerKey(state);

  return {
    componentId: "HotelReservationPetCard",
    customer: getCustomer(state, { customerKey }),
    petId,
    pet: petData,
    petName,
    petAge,
    petBreed,
    primaryService: cartServiceInformation.productName,
    checkInDateTime: `Check In Date & Time: ${moment.utc(startDate)
      .set({
        hour: moment(dropOffTime, "HH:mm:ss").hour(),
        minutes: moment(dropOffTime, "HH:mm:ss").minute(),
      })
      .format("llll")}`,
    checkOutDateTime: `Check Out Date & Time: ${moment.utc(endDate)
      .set({
        hour: moment(pickUpTime, "HH:mm:ss").hour(),
        minutes: moment(pickUpTime, "HH:mm:ss").minute(),
      })
      .format("llll")}`,
    dropOffTime,
    pickUpTime,
    missingVaccinations,
  };
})(ReservationPetCard);
