import { all, call, put, takeEvery } from "redux-saga/effects";
import { fetchPriceAdjustmentReasons } from "core/services/systemServicesBooking/priceAdjustmentEndPoints";
import { forEach, omit } from "lodash/fp";
import {
  LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS,
  loadHotelPriceAdjustmentReasonsFailure,
  loadHotelPriceAdjustmentReasonsRequest,
  loadHotelPriceAdjustmentReasonsSuccess,
} from "dux/hotelPriceAdjustmentReasons/hotelPriceAdjustmentActions";

const normalizePriceAdjustmentReasons = reasons => {
  const result = [];

  forEach(reason => {
    result.push({
      ...omit("name", reason),
      label: reason.name,
    });
  }, reasons);

  return result;
};

function* onloadHotelPriceAdjustmentReasons() {
  try {
    yield put(loadHotelPriceAdjustmentReasonsRequest());
    const response = yield call(fetchPriceAdjustmentReasons, { appType: "Hotel" });
    const priceAdjustmentReasons = response.data;
    yield put(
      loadHotelPriceAdjustmentReasonsSuccess({
        priceAdjustmentReasons: normalizePriceAdjustmentReasons(priceAdjustmentReasons),
      }),
    );
  } catch (error) {
    yield put(loadHotelPriceAdjustmentReasonsFailure({ error }));
  }
}

function* watchloadPriceAdjustmentReasons() {
  yield takeEvery(LOAD_HOTEL_PRICE_ADJUSTMENT_REASONS, onloadHotelPriceAdjustmentReasons);
}

export default function* priceAdjustmentReasonsSaga() {
  yield all([watchloadPriceAdjustmentReasons()]);
}
