import React from "react";
import { connect } from "react-redux";
import { Layout, Text, Button } from "@prism/psm-ui-components";
import CommonModal from "@/web/common/modals/commonModal";
import { getIsAssociateProfileModalOpen } from "../associateProfilePageSelectors";
import { toggleAssociateProfileModal } from "../associateProfilePageActions";

function AssociateProfileModal({ isHidden, onClose }) {
  if (isHidden) {
    return null;
  }
  return (
    <CommonModal isHidden={isHidden} onClose={onClose}>
      <Layout.Box padding="box-padding-8" style={{ height: "100%" }}>
        <Layout.Stack space="stack-space-8">
          <Text size="text-size" align="center">
            After changing the employee group of an associate from dog and cat skills to dog only, you must go to the Associate's weekly and regular schedule and click Save to sync changes in the schedule.
          </Text>
          <Layout.Cluster space="cluster-space-4">
            <Button variant="prism-primary" onClick={onClose}>
              Close
            </Button>
          </Layout.Cluster>
        </Layout.Stack>
      </Layout.Box>
    </CommonModal>
  );
}

export default connect(
  state => {
    return {
      isHidden: !getIsAssociateProfileModalOpen(state),
    };
  },
  dispatch => ({
    onClose: () => {
      dispatch(toggleAssociateProfileModal());
    },
  }),
)(AssociateProfileModal);
