import { getSelectedAssociateWeekly } from "core/selectors/commonSelector";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { GO_LIVE_DATE } from "core/constants/dashboardContants";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { setWeekNumber } from "core/actionCreators/ui/web/commonActionCreators";
import { clearUpcomingAndHereSearch } from "core/actionCreators/upcomingAndHereActionCreators";
import moment from "moment";
import { connect } from "react-redux";
import Calendar from "web/common/Calendar";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { loadHotelDailyDashboard } from "dux/dashboard/hotel/hotelDashboardActions";

const mapStateToProps = state => {
  const selectedDate = getSelectedDate(state);

  return {
    isWeekly: !!getSelectedAssociateWeekly(state),
    isOutsideRange: date => date.isBefore(GO_LIVE_DATE),
    selectedDate,
    calendarTitle: "Schedule",
    storeId: getStoreNumber(state),
  };
};

const mapDispatchToProps = dispatch => ({
  handleSelectDate: ({ selectedDate, storeId }) => {
    dispatch(setSelectDate(selectedDate));
    dispatch(clearUpcomingAndHereSearch());
    dispatch(loadHotelDailyDashboard({ storeId, selectedDate }));
  },

  handleSetWeekNumber: ({ weekNumber }) => {
    dispatch(setWeekNumber({ weekNumber }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  selectDate: selectedDate => {
    propsFromDispatch.handleSelectDate({
      selectedDate: moment(selectedDate).format("YYYY-MM-DD"),
      storeId: propsFromState.storeId,
    });
  },
  setWeekNumber: ({ weekNumber }) => propsFromDispatch.handleSetWeekNumber({ weekNumber }),
  loadItinerariesBySelectedDate: () => {},
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Calendar);
