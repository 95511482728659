import React, { useState } from "react";

// Components
import { Icon } from "@prism/psm-ui-components";
import StyledPopover from "@/web/common/StyledPopover";
import { NavLink } from "react-router-dom";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { Heading, TextPassage } from "@petsmart-ui/sparky";
import fadeIn from "@/web/common/styles/Animation/fade/fadeIn";

/**
 * Hotel admin navbar item component
 * @summary located on /hotel-management page
 * @memberOf Views.HotelAdmin
 * @function
 * @name HotelAdmin
 * @param {Object} props - props passed into the view component
 * @param {Boolean} props.navBarIsOpen
 * @param {JSX.Element} props.icon
 * @param {String} props.title
 * @param {String} props.tooltipLabel
 * @returns {JSX.Element}
 */
const NavItem = ({ navBarIsOpen, icon, title, tooltipLabel }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return navBarIsOpen ? (
    <LayoutCluster flexWrap="flex-noWrap" style={{ alignItems: "center" }}>
      <LayoutBox>{icon}</LayoutBox>
      <Heading size="body-lg-bold" tagName="span" style={{ animation: `${fadeIn()} 1s ease` }}>
        {title}
      </Heading>
    </LayoutCluster>
  ) : (
    <StyledPopover
      body={
        <LayoutBox style={{ minWidth: "100px" }}>
          <TextPassage>{tooltipLabel}</TextPassage>
        </LayoutBox>
      }
      isOpen={showTooltip}
      place="right"
    >
      <LayoutBox
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {icon}
      </LayoutBox>
    </StyledPopover>
  );
};

/**
 * Hotel admin navbar component
 * @summary located on /hotel-management page
 * @memberOf Views.HotelAdmin
 * @function
 * @name HotelAdmin
 * @param {Object} props - props passed into the view component
 * @param {Object[]} props.list
 * @returns {JSX.Element}
 * @example <PetsHotelAdminNavBar />
 */
export const PetsHotelAdminNavBar = ({ list }) => {
  const [navBarIsOpen, setNavBarVisibility] = useState(true);

  return (
    <LayoutBox
      style={{
        gridArea: "NavBar",
        borderRight: "1px solid #131313",
        height: "100%",
        width: navBarIsOpen ? "200px" : "80px",
        transitionDuration: "400ms",
      }}
    >
      <LayoutStack>
        {list.map(item => (
          <NavLink
            to={`${item?.path}`}
            key={item?.path}
            style={({ isActive }) => ({
              color: isActive ? "#206ef6" : "#000",
              textDecoration: "none",
            })}
          >
            <NavItem
              navBarIsOpen={navBarIsOpen}
              icon={item?.icon}
              title={item?.title}
              tooltipLabel={item?.title}
            />
          </NavLink>
        ))}

        {/* Collapse button */}
        <LayoutBox
          onClick={() => setNavBarVisibility(prev => !prev)}
          padding="scale-0"
          style={{ position: "fixed", bottom: "15px", cursor: "pointer" }}
        >
          <NavItem
            navBarIsOpen={navBarIsOpen}
            icon={
              <LayoutBox style={{ backgroundColor: "#eee", borderRadius: "4px" }}>
                <Icon
                  variant={navBarIsOpen ? "hero-icon-left" : "hero-icon-right"}
                  size="psm-icon-size-24"
                />
              </LayoutBox>
            }
            title="Collapse"
            tooltipLabel="Expand Menu"
          />
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};
