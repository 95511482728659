import petHealthConditionsActionTypes from "../actionTypes/petHealthConditionsActionTypes";

export const createPetHealthCondition = ({ customerKey, petId, ConditionId }) => ({
  type: petHealthConditionsActionTypes.CREATE_PET_HEALTH_CONDITION,
  customerKey,
  petId,
  ConditionId
});

export const createPetHealthConditionRequest = () => ({
  type: petHealthConditionsActionTypes.CREATE_PET_HEALTH_CONDITION_REQUEST
});

export const createPetHealthConditionSuccess = () => ({
  type: petHealthConditionsActionTypes.CREATE_PET_HEALTH_CONDITION_SUCCESS
});

export const createPetHealthConditionFailure = ({ error }) => ({
  type: petHealthConditionsActionTypes.CREATE_PET_HEALTH_CONDITION_FAILURE,
  error
});

export const deletePetHealthCondition = ({ customerKey, petId, ConditionId }) => ({
  type: petHealthConditionsActionTypes.CREATE_PET_HEALTH_CONDITION,
  customerKey,
  petId,
  ConditionId
});

export const deletePetHealthConditionRequest = () => ({
  type: petHealthConditionsActionTypes.DELETE_PET_HEALTH_CONDITION_REQUEST
});

export const deletePetHealthConditionSuccess = () => ({
  type: petHealthConditionsActionTypes.DELETE_PET_HEALTH_CONDITION_SUCCESS
});

export const deletePetHealthConditionFailure = ({ error }) => ({
  type: petHealthConditionsActionTypes.DELETE_PET_HEALTH_CONDITION_FAILURE,
  error
});

export const updatePetHealthConditions = ({ customerKey, petId, formValues }) => ({
  type: petHealthConditionsActionTypes.UPDATE_PET_HEALTH_CONDITIONS,
  customerKey,
  petId,
  formValues
});

export const updatePetHealthConditionsRequest = () => ({
  type: petHealthConditionsActionTypes.UPDATE_PET_HEALTH_CONDITIONS_REQUEST
});

export const updatePetHealthConditionsSuccess = () => ({
  type: petHealthConditionsActionTypes.UPDATE_PET_HEALTH_CONDITIONS_SUCCESS
});

export const updatePetHealthConditionsFailure = ({ error }) => ({
  type: petHealthConditionsActionTypes.UPDATE_PET_HEALTH_CONDITIONS_FAILURE,
  error
});

export default {
  createPetHealthCondition,
  createPetHealthConditionRequest,
  createPetHealthConditionSuccess,
  createPetHealthConditionFailure,
  deletePetHealthCondition,
  deletePetHealthConditionRequest,
  deletePetHealthConditionSuccess,
  deletePetHealthConditionFailure,
  updatePetHealthConditions,
  updatePetHealthConditionsRequest,
  updatePetHealthConditionsSuccess,
  updatePetHealthConditionsFailure
};
