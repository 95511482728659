/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelDeparturesFilterButtonActionTypes = {
  FILTER_BY_OVERNIGHT_DEPARTURES: "FILTER_BY_OVERNIGHT_DEPARTURES",
  FILTER_BY_DAY_CAMP_DEPARTURES: "FILTER_BY_DAY_CAMP_DEPARTURES",
  FILTER_BY_DAY_CARE_DEPARTURES: "FILTER_BY_DAY_CARE_DEPARTURES",
  FILTER_BY_All_DEPARTURES: "FILTER_BY_All_DEPARTURES",
  FILTER_BY_ATTENTION_NEEDED_DEPARTURES: "FILTER_BY_ATTENTION_NEEDED_DEPARTURES",
  FILTER_BY_UNASSIGNED_PET_DEPARTURES: "FILTER_BY_UNASSIGNED_PET_DEPARTURES",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const filterByOverNightDepartures = ({ filterBtnStatus }) => ({
  type: hotelDeparturesFilterButtonActionTypes.FILTER_BY_OVERNIGHT_DEPARTURES,
  filterBtnStatus,
});

export const filterByDayCampDepartures = ({ filterBtnStatus }) => ({
  type: hotelDeparturesFilterButtonActionTypes.FILTER_BY_DAY_CAMP_DEPARTURES,
  filterBtnStatus,
});

export const filterByDayCareDepartures = ({ filterBtnStatus }) => ({
  type: hotelDeparturesFilterButtonActionTypes.FILTER_BY_DAY_CARE_DEPARTURES,
  filterBtnStatus,
});

export const filterByAllDepartures = ({ filterBtnStatus }) => ({
  type: hotelDeparturesFilterButtonActionTypes.FILTER_BY_All_DEPARTURES,
  filterBtnStatus,
});

export const filterByAttentionNeededDepartures = ({ filterBtnStatus }) => ({
  type: hotelDeparturesFilterButtonActionTypes.FILTER_BY_ATTENTION_NEEDED_DEPARTURES,
  filterBtnStatus,
});

export const filterByUnassignedPetDepartures = ({ filterBtnStatus }) => ({
  type: hotelDeparturesFilterButtonActionTypes.FILTER_BY_UNASSIGNED_PET_DEPARTURES,
  filterBtnStatus,
});
