import moment from "moment";
import { connect } from "react-redux";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { createLoadingSelector } from "../../../core/selectors/utils";
import bookingActionTypes from "../../../core/actionTypes/bookingActionTypes";
import { formatCalendarDateMoment } from "../../../core/utils/dateUtils/formatDateTime";
import { getSelectedAssociateWeekly } from "../../../core/selectors/commonSelector";
import { setWeekNumber } from "../../../core/actionCreators/ui/web/commonActionCreators";
import loadItinerariesHelper from "../../_helpers/itineraries/loadItinerariesHelper";
import Calendar from "../../common/Calendar";

const mapStateToProps = (state) => ({
  isWeekly: !!getSelectedAssociateWeekly(state),
  calendarTitle: "Preferences",
  selectedDate: getSelectedDate(state),
  disabled: createLoadingSelector([
    bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS,
    bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT,
  ])(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectDate: (selectedDate) => {
    dispatch(setSelectDate(formatCalendarDateMoment(moment(selectedDate))));
  },

  setWeekNumber: (weekNumber) => {
    dispatch(setWeekNumber({ weekNumber }));
  },

  loadItinerariesBySelectedDate: (selectedDate = moment()) => {
    // Abstract out dispatching load itineraries since it is used in multiple containers.
    loadItinerariesHelper(dispatch, selectedDate, false);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
