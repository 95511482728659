import { createSelector } from "reselect";
import getPrimaryAddress from "./getPrimaryAddress";
import normalizeAddressValues from "../../../utils/customer/address/normalizeAddressValues";

/* * ----------------------------------------------------------------------- * *\
    Selector that returns normalized Values form the customers Primary Address

    Example of use:  getPrimaryAddressFormValues(state, { customerKey });
\* * ----------------------------------------------------------------------- * */
const getPrimaryAddressFormValues = createSelector([getPrimaryAddress], primaryAddress =>
  normalizeAddressValues(primaryAddress)
);

export default getPrimaryAddressFormValues;
