import { connect } from "react-redux";
import PetParentAddress from "@/web/common/Address/PetParentAddress";
import { getCountriesOptions, getStatesOptions } from "@/core/selectors/enumsSelectors";
import getOtherAddressFormValues from "@/core/selectors/customer/address/getOtherAddressFormValues";
import customersActionCreators from "@/core/actionCreators/customersActionCreators";
import getIsFormUpdated from "@/core/utils/formUtils/getIsFormUpdated";
import getIsAddressEditable from "@/core/selectors/customer/address/getIsAddressEditable";
import customersActionTypes from "@/core/actionTypes/customersActionTypes";
import { createLoadingSelector } from "@/core/selectors/utils";
import { GET_SEARCH_ADDRESS_BY_ZIP } from "@/dux/searchAddressByZip/searchAddressByZipActions";

const mapStateToProps = (state, { customerKey, addressId }) => {
  return {
    componentId: "OtherPetParentAddressContainer",
    customerKey,
    addressId,
    address: getOtherAddressFormValues(state, { customerKey, addressId }),
    countryOptions: getCountriesOptions(state),
    stateOptions: getStatesOptions(state),
    isLoading: createLoadingSelector([customersActionTypes.UPDATE_CUSTOMER_ADDRESS])(state),
    isZipSearchLoading: createLoadingSelector([GET_SEARCH_ADDRESS_BY_ZIP])(state),

    // For Form Input
    isDisabled: !getIsAddressEditable(state, { customerKey, addressId }),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchOnSubmit: ({ data, customerKey, addressId }) => {
    dispatch(
      customersActionCreators.updateCustomerAddress({
        data,
        customerKey,
        addressId,
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSubmit: formValues => {
    const previousFormValues = propsFromState.address;
    const isFormUpdated = getIsFormUpdated(previousFormValues, formValues);

    if (isFormUpdated) {
      propsFromDispatch.dispatchOnSubmit({
        data: formValues,
        customerKey: propsFromState.customerKey,
        addressId: propsFromState.addressId,
      });
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PetParentAddress);
