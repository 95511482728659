import { endpointVersionString } from "../../utils/envUtils";
import { associateWebPetProfileAPI } from "./_axiosConfigAssociateWebPetProfile";

/**
 * Add a food to a pet from the Web Associate Profile API - Add pet's food end point
 * @param {*} { customerKey, petId, sourceId, data }
 * @returns
 */
export function postPetFood({ petId, data }) {
  return associateWebPetProfileAPI.post(`${endpointVersionString()}/pets/${petId}/food`, data);
}

/**
 * Delete a food to a pet from the Web Associate Profile API - Add pet's food end point
 * @param {*} { customerKey, petId, sourceId, data }
 * @returns
 */
export function deletePetFood({ foodId, petId }) {
  return associateWebPetProfileAPI.delete(
    `${endpointVersionString()}/pets/${petId}/food/${foodId}`,
  );
}

/**
 * Add a food to a pet from the Web Associate Profile API - Add pet's food end point
 * @param {*} { petId, sourceId, data }
 * @returns
 */
export function putPetFood({ petId, sourceId, foodId, data }) {
  return associateWebPetProfileAPI.put(
    `${endpointVersionString()}/pets/${petId}/food/${foodId}`,
    data,
  );
}
