import { combineReducers } from "redux";
import bulkPackageAddOnsReducer from "./addOns/bulkPackageAddOnsReducer";
import bulkPackageEnhancedServicesReducer from './enhancedServices/bulkPackageEnhancedServiceReducer';
import {
  SET_SELECTED_BULK_PACKAGE_OFFERING,
  CLEAR_SELECTED_BULK_PACKAGE_OFFERINGS,
} from "./bulkPackageOfferingsActions";

const initialState = {};

const bulkPackageOfferingsSelectedReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_BULK_PACKAGE_OFFERING: {
      const { petServiceId, productBundleConfigId } = action;
      const value = !state[productBundleConfigId];
      return value ? { [productBundleConfigId]: value, petServiceId } : {};
    }
    case CLEAR_SELECTED_BULK_PACKAGE_OFFERINGS:
      return initialState;
    default:
      return state;
  }
};

const bulkPackageOfferingsReducer = combineReducers({
  selectedOffering: bulkPackageOfferingsSelectedReducer,
  addOns: bulkPackageAddOnsReducer,
  enhancedServices: bulkPackageEnhancedServicesReducer
});

export default bulkPackageOfferingsReducer;
