import React from "react";
import { connect } from "react-redux";
import { getCurrentPet, hasManagerPrivileges } from "@/core/selectors/persistentSelectors";
import { LayoutBox } from "@/layout/box/Box";
import { TextPassage, Button } from "@petsmart-ui/sparky";
import {
  selectAddonTotalPriceByGroupingId,
  selectCurrentHotelEngagement,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { selectPetPrimaryServicePricing } from "../hotelPricingSummary/hotelPricingSummarySelectors";
import { showCheckInOutModal } from "core/actionCreators/checkInOutActionCreator";
import { modalTypes } from "core/constants/checkInOutConstants";
import { selectAddonByPetAndAddonId } from "../hotelEngagements/hotelEngagementSelectors";
import isEmpty from "lodash/isEmpty";
import { selectIsReservationDisabled } from "../hotelItinerary/hotelItinerarySelectors";
import { setCurrentAddonGroupingId } from "../hotelPriceOverride/hotelPriceOverrideActions";
import { formatMoney } from "dux/_utils/moneyUtil";

export const CartPriceComponent = ({
  componentId,
  isHidden,
  isClickable,
  price,
  disabled,
  onClick = () => {},
}) => {
  if (isHidden) return null;

  if (!isClickable)
    return (
      <LayoutBox id={componentId} padding="scale-0">
        <TextPassage size="lg">
          <span>{price}</span>
        </TextPassage>
      </LayoutBox>
    );

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <Button size="lg" variant="link" text={price} onClick={onClick} disabled={disabled} />
    </LayoutBox>
  );
};

export const HotelCartAddonPrice = connect(
  (state, { petId, addonId }) => {
    const addon = selectAddonByPetAndAddonId(addonId)(state, { petId });
    const isReservationDisabled = selectIsReservationDisabled(state);

    return {
      componentId: "HotelCartAddonPrice",
      isClickable: hasManagerPrivileges(state),
      groupingId: addon?.groupingId ?? "",
      price: formatMoney(selectAddonTotalPriceByGroupingId(addon?.groupingId)(state, { petId })),
      disabled: !addon?.groupingId || isReservationDisabled || addon?.isAutoApplyAddOn,
    };
  },
  dispatch => {
    return {
      openModal: groupingId => {
        if (!groupingId) return;
        dispatch(setCurrentAddonGroupingId(groupingId));
        dispatch(showCheckInOutModal({ modalType: modalTypes.HOTEL_ADDON_PRICE_OVERRIDE }));
      },
    };
  },
  (stateProps, dispatchProps) => {
    const { componentId, isClickable, price, groupingId, disabled } = stateProps;
    const { openModal } = dispatchProps;

    return {
      componentId,
      isClickable,
      price,
      disabled,
      onClick: () => openModal(groupingId),
    };
  },
)(CartPriceComponent);

export const HotelCartPrimaryServicePrice = connect(
  state => {
    const petId = getCurrentPet(state);
    const engagement = selectCurrentHotelEngagement(state, { petId });
    const finalPrice = formatMoney(selectPetPrimaryServicePricing(petId)(state)?.finalPrice ?? 0);
    const isReservationDisabled = selectIsReservationDisabled(state);

    return {
      componentId: "HotelCartPrimaryServicePrice",
      isClickable: hasManagerPrivileges(state),
      price: finalPrice,
      disabled: isEmpty(engagement) || isReservationDisabled,
    };
  },
  dispatch => {
    return {
      onClick: () =>
        dispatch(showCheckInOutModal({ modalType: modalTypes.PRIMARY_SERVICE_PRICE_OVERRIDE })),
    };
  },
)(CartPriceComponent);
