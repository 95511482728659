import searchActionTypes from "../actionTypes/searchActionTypes";

export const loadCustomersSearchResults = ({ customers }) => ({
  type: searchActionTypes.LOAD_CUSTOMERS_SEARCH_RESULTS,
  customers: customers.map(customer => customer.customerKey),
});

export const setSearchResultsToDisplay = ({ searchResultsToDisplay }) => ({
  type: searchActionTypes.SET_SEARCH_RESULTS_TO_DISPLAY,
  searchResultsToDisplay,
});

export default {
  loadCustomersSearchResults,
};
