import React from "react";
import NewBookingFoodFormContainer from "dux/bookingFeeding/NewBookingFoodFormContainer";
import FoodSectionContainer from "web/newFood/FoodSectionContainer";
import { BookingPetFoodList } from "@/web/foodList/FoodList";
import { Layout } from "@prism/psm-ui-components";
import { BookingTotalFeedingCharges } from "@/dux/feedingCharges/FeedingCharges";

const BookingFoodComponent = ({
  isHidden,
  petId,
  hasPadding,
  locationType,
  topLine,
  title,
  showTotalPrice = false,
}) => {
  if (isHidden) return null;

  const containerStyles = {
    padding: hasPadding || "inherit",
  };

  return (
    <Layout.Box style={containerStyles}>
      <FoodSectionContainer
        petId={petId}
        locationType={locationType}
        topLine={topLine}
        title={title}
      >
        <NewBookingFoodFormContainer petId={petId} locationType={locationType} />
        <BookingPetFoodList petId={petId} locationType={locationType} />
        {showTotalPrice && <BookingTotalFeedingCharges petId={petId} />}
      </FoodSectionContainer>
    </Layout.Box>
  );
};

export default BookingFoodComponent;
