/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_ELIGIBLE_PROMOTIONS = "SET_ELIGIBLE_PROMOTIONS";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setEligiblePromotions = eligiblePromotions => ({
  type: SET_ELIGIBLE_PROMOTIONS,
  eligiblePromotions,
});
