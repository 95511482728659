import React from "react";
import { color } from "@/web/common/styles/theme";
import SVGArrow from "@/web/common/SVGArrow";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutBox } from "@/layout/box/Box";
import { Heading } from "@petsmart-ui/sparky";

export default function BulkPackageOfferingsHeaderComponent({
  isHidden,
  isDisabled,
  componentID,
  headerText,
  clickHandler,
  direction,
}) {
  if (!isHidden) {
    return (
      <LayoutBox
        id={componentID}
        onClick={!isDisabled ? clickHandler : undefined}
        data-testid="BulkPackageOfferingsHeaderComponent"
      >
        <LayoutCluster
          space="scale-G1"
          flexWrap="flex-noWrap"
          style={{ cursor: isDisabled ? "default" : "pointer" }}
        >
          <Heading
            size="headline"
            tagName="h3"
            style={isDisabled ? { color: color.lightGrey } : {}}
            data-testid="test-header-text"
          >
            {headerText}
          </Heading>

          {!isDisabled && <SVGArrow color={color.kalEl} direction={direction} />}
        </LayoutCluster>
      </LayoutBox>
    );
  }
  return null;
}
