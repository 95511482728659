import { connect } from "react-redux";
import { Text } from "@prism/psm-ui-components";
import { getIsPurchaseTrainingServiceMessageHidden } from "./scheduleTrainingPageSelectors";
import { PURCHASE_CLASS_COPY } from "@/dux/scheduleTrainingPage/scheduleTrainingPageContants";

const mapStateToProps = state => ({
  isHidden: getIsPurchaseTrainingServiceMessageHidden(state),
  children: PURCHASE_CLASS_COPY,
  size: "text-size-lg",
});

export default connect(mapStateToProps)(Text);
