import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { getWeekNumber } from "core/selectors/commonSelector";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { setWeekNumber } from "core/actionCreators/ui/web/commonActionCreators";
import isDateOnXday from "core/utils/dateUtils/isDateOnISOday";
import moment from "moment";
import { connect } from "react-redux";
import Arrow from "web/common/Arrow";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { loadHotelDailyDashboard } from "dux/dashboard/hotel/hotelDashboardActions";

const mapStateToProps = state => ({
  componentID: "button_next_week_navigation",
  weekNumber: getWeekNumber(state),
  currentDate: getSelectedDate(state),
  storeId: getStoreNumber(state),
  direction: "right",
});

const mapDispatchToProps = dispatch => ({
  dispatchClickHandler: ({ selectedDate, weekNumber, storeId }) => {
    dispatch(setSelectDate(selectedDate));

    // Verify the selected day is Monday, if so we changed weeks so we need to set the weekNumber in the Redux state.
    if (isDateOnXday(selectedDate, 1)) {
      dispatch(setWeekNumber({ weekNumber }));
    }

    dispatch(loadHotelDailyDashboard({ storeId, selectedDate }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  clickHandler: () =>
    propsFromDispatch.dispatchClickHandler({
      selectedDate: moment(propsFromState.currentDate).add(1, "days"),
      weekNumber: propsFromState.weekNumber + 1,
      storeId: propsFromState.storeId,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Arrow);
