import React from "react";
import CommonModal from "../../../web/common/modals/commonModal";
import { modalTypes as commonModalTypes } from "../../../core/constants/modalConstants";
import RuleViolation from "../../common/modals/content/ruleViolation/ruleViolationContainer";

const AssociatesModals = props => (
  <CommonModal
    onClose={() => {
      props.onClose();
    }}
  >
    {selectModalContent(props)}
  </CommonModal>
);

const selectModalContent = props => {
  switch (props.modalType) {
    case commonModalTypes.RULE_VIOLATION:
      return <RuleViolation onClose={props.onClose} />;

    default:
  }
};

export default AssociatesModals;
