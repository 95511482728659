import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form, Layout } from "@prism/psm-ui-components";
import { getTrainingAttendeeById } from "../trainingClassSessions/selectors";
import { timeZoneIdentifiers } from "@/dux/timeZoneCustomer/timeZoneCustomerConstants";
import { updateCustomerTimeZone } from "@/dux/timeZoneCustomer/timeZoneCustomerActions";
import {
  getTimeZones,
  getTimeZoneIdentifierByTimezone,
} from "@/dux/timeZoneCustomer/timeZoneCustomerHelpers";
import { getRescheduleAttendeeId } from "./manageTrainingClassPageSelectors";
import { useForm } from "../utils/formUtils/useForm";

export const SelectCustomerTimeZoneRescheduleTrainingDropdownComponent = ({
  componentId,
  timeZoneDropdownOptions,
  setCustomerTimeZone,
  customerTimeZoneIdentifier,
}) => {
  const { values, setDropdownOption } = useForm({ customerTimeZoneIdentifier });
  useEffect(() => {
    setCustomerTimeZone(customerTimeZoneIdentifier);
  }, []);
  return (
    <Layout.Box id={componentId} padding="box-padding-5" style={{ paddingTop: "0" }}>
      <Form.Field.Select
        legacy
        name="customerTimeZoneIdentifier"
        label="Timezone"
        options={timeZoneDropdownOptions}
        value={values.customerTimeZoneIdentifier}
        onChange={(option, name) => {
          setDropdownOption(option, name);
          setCustomerTimeZone(option.value);
        }}
      />
    </Layout.Box>
  );
};

export const SelectCustomerTimeZoneRescheduleTrainingDropdown = connect(
  state => {
    const attendeeId = getRescheduleAttendeeId(state);
    const { customerTimeZone } = getTrainingAttendeeById(state, { attendeeId });
    return {
      customerTimeZoneIdentifier: getTimeZoneIdentifierByTimezone(customerTimeZone),
      componentId: "SelectCustomerTimeZoneRescheduleTrainingDropdown",
      timeZoneDropdownOptions: getTimeZones(),
    };
  },
  dispatch => ({
    setCustomerTimeZone: timeZone => {
      dispatch(updateCustomerTimeZone({ timeZone: timeZoneIdentifiers[timeZone] }));
    },
  }),
)(SelectCustomerTimeZoneRescheduleTrainingDropdownComponent);
