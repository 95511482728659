import { connect } from "react-redux";
import { getMedicationTimeOfDayById } from "./medicationTimeOfDaySelectors";
import { setMedicationTimeOfDay, setTimeOfDayTouched } from "./actions/medicationTimeOfDayActions";
import { timesOfDay } from "../../core/constants/timeOfDayConstants";
import Checkbox from "../common/Checkbox";
import { getMedIsDiscontinuedById } from "../medication/medicationsSelector";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, petId } = ownProps;
  const medicationTimes = getMedicationTimeOfDayById(state, { medicationId, petId });
  return {
    medicationId,
    petId,
    medicationTimes,
    componentId: "medicationDetails-timeOfDayMorning",
    label: timesOfDay.MORNING,
    labelPosition: "right",
    checked: medicationTimes.includes(timesOfDay.MORNING),
    disabled: getMedIsDiscontinuedById(state, { petId, medicationId }),
  };
};

const mapDispatchToProps = dispatch => ({
  setTimeOfDay: ({ medicationId, timeOfDay, petId }) =>
    dispatch(setMedicationTimeOfDay({ medicationId, timeOfDay, petId })),
  setTimeOfDayTouched: (medicationId, petId) =>
    dispatch(setTimeOfDayTouched({ medicationId, timeOfDayTouched: true, petId })),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onChange: () => {
    const { medicationId, medicationTimes, checked, petId } = propsFromState;
    if (!checked) {
      const timeOfDay = [...medicationTimes, timesOfDay.MORNING];
      propsFromDispatch.setTimeOfDay({ medicationId, timeOfDay, petId });
    } else {
      const timeOfDay = medicationTimes.filter(time => time !== timesOfDay.MORNING);
      propsFromDispatch.setTimeOfDay({ medicationId, timeOfDay, petId });
    }
  },
  onBlur: () => {
    const { medicationId, petId } = propsFromState;
    propsFromDispatch.setTimeOfDayTouched(medicationId, petId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Checkbox);
