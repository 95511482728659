import { createSelector } from "reselect";
import { getHotelRoomSelectionRoomTypes } from "@/dux/hotelRoomSelection/hotelRoomSelectionSelector";
import { uniqWith, isEqual } from "lodash";

export const getHotelRoomManagementFilters = createSelector(
  getHotelRoomSelectionRoomTypes,
  hotelRoomTypes => {
    let statusFilters = [];

    for (let roomId in hotelRoomTypes) {
      hotelRoomTypes[roomId].rooms.map(room => {
        const roomStatus = room.suspensionDetails[0]?.status;
        if (roomStatus) {
          const filter = {
            id: roomStatus?.toLowerCase(),
            name: roomStatus,
            isPressed: false,
            filterValue: roomStatus,
          };
          statusFilters.push(filter);
        }
      });
    }

    const filters = [
      {
        id: "active",
        name: "Active",
        isPressed: false,
        filterValue: "Active",
      },
      {
        id: "inactive",
        name: "In Active",
        isPressed: false,
        filterValue: "In Active",
      },
    ];

    return uniqWith([...statusFilters,...filters], isEqual);
  },
);
