import { connect } from "react-redux";
import { setWeeklyEditDayActivity, showSchedulesModal } from "../../../../core/actionCreators/schedulesActionCreators";
import {
  getSalons,
  getAbsenceReasons,
  getDayTypesAndAbsenceReasons
} from "../../../../core/selectors/entitiesSelector";
import DayTypeComponent from "./DayTypeComponent";
import { loadRelocatedSalonHours } from "../../../../core/actionCreators/salonHoursActionCreators";
import { getStartWeekDate } from "../../../../core/selectors/schedulesSelectors";
import { dayActivityTypes } from "@/core/constants/schedulesConstants";
import { modalTypes } from "@/core/constants/associateSchedulingConstants";

const mapStateToProps = (state, ownProps) => {
  const getDayTypes = getDayTypesAndAbsenceReasons(state);
  const filterDayTypes = getDayTypes.filter(option => option.value === "Relocation");
  const dayTypesAndAbsenceReasons = ownProps.isSalonClosed ? filterDayTypes : getDayTypes;
  return {
    salons: getSalons(state),
    absenceReasons: getAbsenceReasons(state),
    selectedDate: getStartWeekDate(state),
    dayTypesAndAbsenceReasons
  };
};

const mapDispatchToProps = dispatch => ({
  setWeeklyEditDayActivity: ({ isSplitShift, localId, type, absenceReason, petSupportedType, storeId, resetIds }) => {
    dispatch(
      setWeeklyEditDayActivity({
        localId,
        type,
        absenceReason,
        petSupportedType,
        storeId,
        resetIds,
      }),
    );
    if (type === dayActivityTypes.RELOCATION && isSplitShift) {
      dispatch(showSchedulesModal(modalTypes.RELOCATION_ON_SPLIT_SHIFT_NOTIFICATION));
    }
  },
  loadRelocatedSalonHours: ({ storeNumber, beginDate, endDate }) =>
    dispatch(loadRelocatedSalonHours({ storeNumber, beginDate, endDate })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DayTypeComponent);
