import React from "react";
import { connect } from "react-redux";
import { Layout, Text, Button } from "@prism/psm-ui-components";
import { VPTClassStatuses } from "@/dux/trainingClassSessions/constants";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getTrainingAttendeeById } from "@/dux/trainingClassSessions/selectors";
import { getIsCompleteTrainingAppointmentModalOpen } from "@/dux/manageTrainingClassPage/manageTrainingClassPageSelectors";
import {
  MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE,
  markTrainingClassItineraryAsComplete,
} from "@/dux/trainingClassItineraries/trainingClassItinerariesActions";
import { createLoadingSelector } from "@/core/selectors/utils";
import { toggleCompleteTrainingAppointmentModal } from "@/dux/manageTrainingClassPage/manageTrainingClassPageActions";
import CommonModal from "@/web/common/modals/commonModal";

function TrainingAppointmentCompleteConfirmationModal({
  isHidden,
  onClose,
  onComplete,
  isDisabled,
}) {
  if (isHidden) {
    return null;
  }

  return (
    <CommonModal isHidden={isHidden} onClose={onClose}>
      <Layout.Box padding="box-padding-8">
        <Layout.Stack space="stack-space-8">
          <Text size="text-size-lg" align="center" bold>
            Mark class as complete
          </Text>
          <Text size="text-size-md" align="center">
            Are you sure you want to mark this class as complete?
          </Text>
          <Layout.Cluster space="cluster-space-4">
            <Button variant="prism-primary-no-outline" onClick={onClose}>
              Go back
            </Button>
            <Button variant="prism-primary" disabled={isDisabled} onClick={onComplete}>
              Complete
            </Button>
          </Layout.Cluster>
        </Layout.Stack>
      </Layout.Box>
    </CommonModal>
  );
}

export default connect(
  (state, { attendeeId }) => {
    const storeNumber = getStoreNumber(state);
    const { customerId: customerKey, itineraryId, engagementId } = getTrainingAttendeeById(state, {
      storeNumber,
      attendeeId,
    });

    return {
      customerKey,
      itineraryId,
      engagementId,
      isHidden: !getIsCompleteTrainingAppointmentModalOpen(state),
      isDisabled: createLoadingSelector([MARK_TRAINING_CLASS_ITINERARY_AS_COMPLETE])(state),
    };
  },

  dispatch => {
    return {
      dispatchMarkTrainingClassItineraryAsComplete: ({
        customerKey,
        itineraryId,
        engagementId,
        status,
        onComplete,
      }) => {
        dispatch(
          markTrainingClassItineraryAsComplete({
            customerKey,
            itineraryId,
            engagementId,
            status,
            onComplete,
            reason: null,
          }),
        );
      },
      dispatchToggleCompleteTrainingAppointmentModal: () =>
        dispatch(toggleCompleteTrainingAppointmentModal()),
    };
  },

  (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,

    onClose: () => {
      const { dispatchToggleCompleteTrainingAppointmentModal } = propsFromDispatch;

      dispatchToggleCompleteTrainingAppointmentModal();
    },

    onComplete: () => {
      const { customerKey, itineraryId, engagementId } = propsFromState;
      const {
        dispatchMarkTrainingClassItineraryAsComplete,
        dispatchToggleCompleteTrainingAppointmentModal,
      } = propsFromDispatch;

      dispatchMarkTrainingClassItineraryAsComplete({
        customerKey,
        itineraryId,
        engagementId,
        status: VPTClassStatuses.CHECKED_OUT,
        onComplete: () => {
          dispatchToggleCompleteTrainingAppointmentModal();
        },
      });
    },
  }),
)(TrainingAppointmentCompleteConfirmationModal);
