import React from "react";
import { Layout } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import { Button } from "@prism/psm-ui-components";
import { frequencyConstants, frequencyTypes } from "dux/frequency/FrequencyConstants";
import { getPendingFrequency, getPendingFrequencyType } from "dux/frequency/frequencySelectors";
import {
  setPendingFrequencyAddonDates,
  setPendingFrequencyFoodDates,
  setPendingFrequencyMedicationDates,
} from "dux/frequency/actions/frequencyActions";

export const FrequencyClearDatesView = props => {
  const { componentId, frequencyType } = props;

  return <Layout.Box id={componentId}>{FREQUENCY_CLEAR_DATES_TYPES[frequencyType]}</Layout.Box>;
};

// FREQUENCY CLEAR DATES CONTAINER ----------------------------------------------------------------------------------------
export const FrequencyClearDates = connect(state => {
  return {
    componentId: "FrequencyClearDates",
    frequencyType: getPendingFrequencyType(state),
  };
})(FrequencyClearDatesView);

// FREQUENCY CLEAR DATES FOOD CONTAINER ----------------------------------------------------------------------------------------
export const FrequencyClearFoodDates = connect(
  state => {
    const frequency = getPendingFrequency(state);

    return {
      componentId: "FrequencyClearFoodDates",
      variant: "link",
      children: "Clear",
      isHidden: frequency !== frequencyConstants.MANUAL,
    };
  },

  dispatch => {
    return {
      onClick: ({}) => {
        dispatch(setPendingFrequencyFoodDates({ dates: [] }));
      },
    };
  },
)(Button);

// FREQUENCY CLEAR DATES MEDS CONTAINER ----------------------------------------------------------------------------------------
export const FrequencyClearMedicationDates = connect(
  state => {
    const frequency = getPendingFrequency(state);

    return {
      componentId: "FrequencyClearMedicationDates",
      variant: "link",
      children: "Clear",
      isHidden: frequency !== frequencyConstants.MANUAL,
    };
  },

  dispatch => {
    return {
      onClick: ({}) => {
        dispatch(setPendingFrequencyMedicationDates({ dates: [] }));
      },
    };
  },
)(Button);

// FREQUENCY CLEAR DATES ADDON CONTAINER ----------------------------------------------------------------------------------------
export const FrequencyClearAddonDates = connect(
  state => {
    const frequency = getPendingFrequency(state);

    return {
      componentId: "FrequencyClearAddonDates",
      variant: "link",
      children: "Clear",
      isHidden: frequency !== frequencyConstants.MANUAL,
    };
  },

  dispatch => {
    return {
      onClick: ({}) => {
        dispatch(setPendingFrequencyAddonDates({ dates: [] }));
      },
    };
  },
)(Button);

export const FREQUENCY_CLEAR_DATES_TYPES = {
  [frequencyTypes.FOOD]: <FrequencyClearFoodDates />,
  [frequencyTypes.MEDICATION]: <FrequencyClearMedicationDates />,
  [frequencyTypes.ADDON]: <FrequencyClearAddonDates />,
};
