import React from "react";
import { Layout } from "@prism/psm-ui-components";
import StoreCardContainer from "@/web/searchSalonSRC/storeCard/StoreCardContainer";

// The store card list only contains one store search result for now, but this component
// sets us up in the future in case we want to return more than one search result.
export default function StoreCardListComponent({ isHidden }) {
  if (isHidden) {
    return null;
  }
  return (
    <Layout.Box
      padding="box-padding-4"
      borderWidth="box-border-width"
      borderColor="box-border-color-gray-100"
      style={{ marginBottom: "1em" }}
    >
      <StoreCardContainer />
    </Layout.Box>
  );
}
