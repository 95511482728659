import { takeEvery, call, all, select, put } from "redux-saga/effects";
import {
  enableDisableFeatureActionTypes,
  enableDisableFeature,
} from "./EnableDisableFeatureActions";
import getNormalizedFeatureFlagName from "./utils/getNormalizedFeatureFlagName";
import getFeatureFlagsState from "./selectors/_featureFlagsState";

function* onEnableDisableFeatureFlags({ featureFlagId, checked, processedRequirements = [] }) {
  try {
    const featureFlags = yield select(getFeatureFlagsState);
    const { requirements } = featureFlags[getNormalizedFeatureFlagName(featureFlagId)];

    yield put(enableDisableFeature({ featureFlagId, checked }));

    if (requirements) {
      const isRequirementNotYetProcessed = id => !processedRequirements.includes(id);
      yield all(
        requirements.filter(isRequirementNotYetProcessed).map(id => {
          return call(onEnableDisableFeatureFlags, {
            featureFlagId: id,
            checked,
            processedRequirements: processedRequirements.concat(requirements),
          });
        }),
      );
    }
  } catch (error) {}
}

function* watchEnableDisableFeatureFlags() {
  yield takeEvery(
    enableDisableFeatureActionTypes.ENABLE_DISABLE_FEATURE_FLAGS,
    onEnableDisableFeatureFlags,
  );
}

export default function* enableDisableFeaturesSaga() {
  yield all([watchEnableDisableFeatureFlags()]);
}
