import moment from "moment";
import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import { cloneDeep } from "lodash/fp";
import { selectCustomerNotes } from "dux/notes/customerNotesSelector";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import {
  addSalonCustomerNotesFailure,
  addSalonCustomerNotesSuccess,
  addGroomingNotesSuccess,
  addGroomingNotesFailure,
  updateAppointmentNotesRequest,
  updateAppointmentNotesSuccess,
  updateAppointmentNotesFailure,
  addSalonCustomerNotesRequest,
  addGroomingNotesRequest,
  addHotelPetNotesRequest,
  addHotelPetNotesSuccess,
  addHotelPetNotesFailure,
  ADD_HOTEL_PET_NOTE,
  ADD_SALON_CUSTOMER_NOTE,
  ADD_GROOMING_NOTE,
  UPDATE_APPOINTMENT_NOTE,
} from "../actionCreators/notesActionCreator";
import { updateServiceNote } from "../services/systemServicesBooking/petServiceNoteEndPonts";
import { postCustomerNote, postGroomingNote } from "../services/associateWeb/notesEndPoints";
import { selectGroomingNotes } from "../selectors/ui/notes/groomingNotesentitiesSelector";
import { getAssociateNumber, getLoggedInUser } from "../selectors/persistentSelectors";
import { dispatchItinerariesDataAndReturnItineraries } from "./itinerariesSaga";
import { updatePetProfilePartial } from "../services/systemServicesBooking/profile/petProfile";
import { selectHotelNotes } from "../selectors/ui/notes/hotelNotesEntitiesSelector";
import { updatePetSuccess } from "../actionCreators/petsActionCreators";
import getPrismPetState from "../../dux/prismPet/prismPetSelector";

/** ----------------------------------------------------------------------- **\
    ADD CUSTOMER NOTE SAGA
\** ----------------------------------------------------------------------- * */
/**
 * Saga to handle requests and responses for adding customer notes to the Web Service
 * @param { Number } customerKey - customerId of the current customer
 * @param { String } content - Note message
 */
function* addCustomerNote({ customerKey, content }) {
  try {
    yield put(addSalonCustomerNotesRequest());
    // Send the new note to the Web Service
    yield call(postCustomerNote, { customerKey, content });

    // Once the User has "added" a note, the Web Service is updated, but we still need to update
    // the UI to show the user that the the note has been added. So here we are getting the list of
    // notes that are already in the customer.notes redux store, creating a notes object so we can push it back
    // to the customer.notes object so the UI will refresh and show the new note.
    const associateId = yield select(getAssociateNumber);
    const loggedInUser = yield select(getLoggedInUser);

    const customerNotesList = yield select(selectCustomerNotes, { customerKey });

    customerNotesList.push({
      timestamp: moment().format(),
      content,
      associateId,
      associateDisplayName: loggedInUser && loggedInUser.displayName,
      associateInitials: loggedInUser ? loggedInUser.initials : "PA",
    });
    yield put(
      addSalonCustomerNotesSuccess({
        customer: {
          customerKey: Number(customerKey),
          notes: customerNotesList,
        },
      }),
    );
  } catch (error) {
    yield put(addSalonCustomerNotesFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
    ADD GROOMING NOTE SAGA
\** ----------------------------------------------------------------------- * */
/**
 * Saga to handle requests and responses for adding grooming notes to the Web Service
 * @param { Number } customerKey - customerId of the current customer
 * @param { String } content - Note message
 * @param petId
 */
function* addGroomingNote({ customerKey, content, petId }) {
  try {
    yield put(addGroomingNotesRequest());
    yield call(postGroomingNote, { customerKey, content, petId });

    // Once the User has "added" a note, the Web Service is updated, but we still need to update
    // the UI to show the user that the the note has been added. So here we are getting the list of
    // notes that are already in the pets.notes.groomingNotes redux store, creating a notes object so we can push it back
    // to the pets.notes.groomingNotes object so the UI will refresh and show the new note.
    const associateId = yield select(getAssociateNumber);
    const loggedInUser = yield select(getLoggedInUser);

    const groomingNotesList = yield select(selectGroomingNotes, { petId });

    groomingNotesList.push({
      timestamp: moment().format(),
      content,
      associateId,
      associateDisplayName: loggedInUser && loggedInUser.displayName,
      associateInitials: loggedInUser ? loggedInUser.initials : "PA",
    });
    yield put(
      addGroomingNotesSuccess({
        pets: {
          petId: Number(petId),
          notes: {
            groomingNotes: groomingNotesList,
          },
        },
      }),
    );
  } catch (error) {
    yield put(addGroomingNotesFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
 ADD HOTEL NOTE SAGA
 \** ----------------------------------------------------------------------- * */
/**
 * Saga to handle requests and responses for adding hotel notes to the Web Service
 * @param { Number } customerKey - customerId of the current customer
 * @param { String } content - Note message
 * @param petId
 */
function* addHotelNote({ customerKey, content, petId }) {
  try {
    yield put(addHotelPetNotesRequest());
    const associateId = yield select(getAssociateNumber);
    const loggedInUser = yield select(getLoggedInUser);
    const timestamp = moment().format();

    const data = {
      customerKey,
      petKey: petId,
      profiles: [
        {
          profileType: systemName.DDC_HOTEL,
          notes: [
            {
              content,
              timestamp,
              associateId,
              associateDisplayName: loggedInUser && loggedInUser.displayName,
              associateInitials: loggedInUser ? loggedInUser.initials : "PA",
            },
          ],
        },
      ],
    };

    yield call(updatePetProfilePartial, { customerKey, data, petKey: petId });

    // Once the User has "added" a note, the Web Service is updated, but we still need to update
    // the UI to show the user that the the note has been added.;

    const hotelNotesList = yield select(selectHotelNotes, { petId });
    const hotelNotesDeepClone = cloneDeep(hotelNotesList);
    const prismPet = yield select(getPrismPetState, { petId });
    const prismPetClone = cloneDeep(prismPet);

    /* * ----------------------------------------------------------------------- * *\
      State may not have gotten a profile's property from the initial pet api call, and
      so we do a quick check and if not there, we add it with a profileType of DDC/Hotel
    \* * ----------------------------------------------------------------------- * */
    if (!prismPetClone.profiles) {
      prismPetClone.profiles = [{ profileType: systemName.DDC_HOTEL }];
    }

    /* * ----------------------------------------------------------------------- * *\
      If the profile's property was found, we just search for the DDC/Hotel profile type and
      move forward.
    \* * ----------------------------------------------------------------------- * */
    const profile = prismPetClone.profiles.find(
      ({ profileType }) => profileType === systemName.DDC_HOTEL,
    );

    hotelNotesDeepClone.push({
      timestamp,
      content,
      associateId,
      associateDisplayName: loggedInUser && loggedInUser.displayName,
      associateInitials: loggedInUser ? loggedInUser.initials : "PA",
    });

    profile.notes = hotelNotesDeepClone;

    yield put(
      updatePetSuccess({
        pet: {
          petId: Number(petId),
          prismPet: prismPetClone,
        },
      }),
    );

    yield put(addHotelPetNotesSuccess({ prismPet }));
  } catch (error) {
    yield put(addHotelPetNotesFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
    APPOINTMENT NOTE SAGA
\** ----------------------------------------------------------------------- * */
/**
 * Saga to handle requests and responses for adding grooming notes to the Web Service
 * @param { Number } customerKey - customerId of the current customer
 * @param { String } content - Note message
 * @param petId
 */
function* updateAppointmentNote({ data, customerId, itineraryId, petServiceItemId }) {
  try {
    yield put(updateAppointmentNotesRequest());
    const response = yield call(updateServiceNote, {
      data,
      customerId,
      itineraryId,
      petServiceItemId,
    });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });

    yield put(updateAppointmentNotesSuccess({ itinerary: normalizedItineraries[itineraryId] }));
  } catch (error) {
    yield put(updateAppointmentNotesFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
    WATCH FUNCTIONS
\** ----------------------------------------------------------------------- * */
function* watchAddCustomerNotes() {
  yield takeEvery(ADD_SALON_CUSTOMER_NOTE, addCustomerNote);
}

function* watchAddGroomingNote() {
  yield takeEvery(ADD_GROOMING_NOTE, addGroomingNote);
}

function* watchUpdateAppointmentNote() {
  yield takeEvery(UPDATE_APPOINTMENT_NOTE, updateAppointmentNote);
}

function* watchAddHotelNote() {
  yield takeEvery(ADD_HOTEL_PET_NOTE, addHotelNote);
}

/** ----------------------------------------------------------------------- **\
    EXPORT SAGAS
\** ----------------------------------------------------------------------- * */
export default function* notesSaga() {
  yield all([
    fork(watchAddCustomerNotes),
    fork(watchAddGroomingNote),
    fork(watchUpdateAppointmentNote),
    fork(watchAddHotelNote),
  ]);
}
