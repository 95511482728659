import { NamedExoticComponent } from "react";

import StoreCardListContainer from "web/searchSalonSRC/storeCard/StoreCardListContainer";
import CustomerCardListContainer from "web/searchSalonSRC/customerCard/CustomerCardListContainer";
import { searchConstants } from "core/constants/searchConstants";
import PetVetCardListContainer from "web/searchSalonSRC/petVetCard/PetVetCardListContainer";
import { ListOfNearByLocation } from "dux/listOfNearByStores/ListOfNearByStores";

/*
  FACTORY CREATORS
  Each will return a product based on a single fn definition
*/
abstract class AProductFactory {
  public abstract productFactory(): ListProduct;

  public createProduct(): NamedExoticComponent {
    const product = this.productFactory();
    return product.getResultList();
  }
}

class StoreCardListProductFactory extends AProductFactory {
  public productFactory(): ListProduct {
    return new StoreCardListProduct();
  }
}

class CustomerCardListProductFactory extends AProductFactory {
  public productFactory(): ListProduct {
    return new CustomerCardListProduct();
  }
}

class ListOfStoresByLocationProductFactory extends AProductFactory {
  public productFactory(): ListProduct {
    return new ListOfStoresByLocationProduct();
  }
}

class VetCardListProductProductFactory extends AProductFactory {
  public productFactory(): ListProduct {
    return new VetCardListProduct();
  }
}

/*
  PRODUCTS
  Each will return a redux connect object or a React component
*/
interface ListProduct {
  getResultList(): NamedExoticComponent;
}

class StoreCardListProduct implements ListProduct {
  public getResultList(): NamedExoticComponent {
    return StoreCardListContainer;
  }
}

class CustomerCardListProduct implements ListProduct {
  public getResultList(): NamedExoticComponent {
    return CustomerCardListContainer;
  }
}

class VetCardListProduct implements ListProduct {
  public getResultList(): NamedExoticComponent {
    return PetVetCardListContainer;
  }
}

class ListOfStoresByLocationProduct implements ListProduct {
  public getResultList(): NamedExoticComponent {
    return ListOfNearByLocation;
  }
}

/*
  Factory the client code will use.
 */
export const resultListFactory = (resultsToDisplay: string) => {
  if (resultsToDisplay === searchConstants.STORE) {
    const factory = new StoreCardListProductFactory();
    return factory.createProduct();
  }

  if (resultsToDisplay === searchConstants.VET) {
    const factory = new VetCardListProductProductFactory();
    return factory.createProduct();
  }

  if (resultsToDisplay === searchConstants.STORES_BY_LOCATION) {
    const factory = new ListOfStoresByLocationProductFactory();
    return factory.createProduct();
  }

  /*
    Return Customer by default
   */
  const factory = new CustomerCardListProductFactory();
  return factory.createProduct();
};
