import React, { useEffect } from "react";
import { color } from "@/web/common/styles/theme";
import { Layout, Button, Text } from "@prism/psm-ui-components";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import CalendarCardList from "../cards/TrainingCardList";
import AddButtonChildren from "../buttons/AddButtonChildren";
import TrainingCalendar from "../calendars/TrainingCalendar";
import TrainingCalendarCellContent from "../calendars/TrainingCalendarCellContent";
import SelectedDatePrevNextContainer from "@/dux/trainingClassSessions/TrainingSelectedDatePrevNextContainer";
import TrainingAddClassForm from "@/dux/trainingClassSessions/TrainingAddClassForm";
import DashboardHeaderTrainingContainer from "@/dux/dashboardHeader/DashboardHeaderTrainingContainer";

// Dashboard Layout Components -----------------------------------------------------------------------------------------
function UpperContent({ children, ...props }) {
  return <Layout.Box {...props}>{children}</Layout.Box>;
}

function LowerContent({ children, ...props }) {
  return (
    <Layout.Box padding="box-padding-6" {...props}>
      {children}
    </Layout.Box>
  );
}

const VirtualDogTrainingDashBoard = ({ upperContent, lowerContent, ...props }) => {
  return (
    <Layout.Box {...props}>
      {upperContent}
      {lowerContent}
    </Layout.Box>
  );
};

export default function TrainingDashboard({
  isHidden,
  loadTrainingClassSessions,
  loadTrainingAssociates,
  loadTrainingPetServices,
  resetTrainingClassSessions,
  isTrainingClassFormHidden,
  toggleTrainingClassForm,
  componentId,
  isLoading,
  isSRCAgent,
  cardListDate,
}) {
  useEffect(() => {
    if (!isHidden) {
      loadTrainingClassSessions();
      loadTrainingAssociates();
      loadTrainingPetServices();
    }
    return () => {
      // Need to reset training class sessions until we have a consistent/reliable class session id as an identifier to "select" the correct class session from a list of training classes
      resetTrainingClassSessions();
    };
  }, [isHidden]);

  return (
    <LoadingWrapper displayContainer="block" isLoading={isLoading}>
      <Layout.Box className="psm-training-dashboard" testid={componentId} isHidden={isHidden}>
        <VirtualDogTrainingDashBoard
          className="psm-training-dashboard__component"
          upperContent={
            <UpperContent>
              <DashboardHeaderTrainingContainer />
            </UpperContent>
          }
          lowerContent={
            <LowerContent className="psm-training-dashboard__lower-content">
              <Layout.Sidebar side="right" sideWidth="30%">
                <Layout.Box
                  className="psm-training-dashboard__sidebar-panel-one"
                  borderWidth
                  borderColor="box-border-color-gray-100"
                  padding="box-padding-8"
                  style={{ borderRight: "none", minWidth: "55rem" }} // place the minWidth here so the sidebar will respond correctly and the calendar will not just infinitely squish
                >
                  <TrainingCalendar className="psm-training-dashboard__calendar">
                    <TrainingCalendarCellContent />
                  </TrainingCalendar>
                </Layout.Box>
                <Layout.Box
                  className="psm-training-dashboard__sidebar-panel-two"
                  borderWidth
                  borderColor="box-border-color-gray-100"
                  style={{ paddingRight: "0px", paddingLeft: "0px" }}
                >
                  <Layout.Cluster
                    justify="flex-end"
                    style={{
                      paddingTop: "1.5rem",
                      paddingBottom: "1.5rem",
                      paddingRight: "1.5rem",
                    }}
                  >
                    <Button
                      disabled={isSRCAgent}
                      size="lg"
                      variant="ghost"
                      onClick={toggleTrainingClassForm}
                    >
                      <AddButtonChildren disabled={isSRCAgent} text="Add Class" />
                    </Button>
                  </Layout.Cluster>
                  <SelectedDatePrevNextContainer
                    className="psm-training-dashboard__prev-next-selector"
                    style={{
                      borderTop: `2px solid ${color.ororoMunroe}`,
                      borderBottom: `2px solid ${color.ororoMunroe}`,
                      paddingTop: "1rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    <Text size="text-size-xl" bold>
                      {cardListDate}
                    </Text>
                  </SelectedDatePrevNextContainer>
                  {isTrainingClassFormHidden ? <CalendarCardList /> : <TrainingAddClassForm />}
                </Layout.Box>
              </Layout.Sidebar>
            </LowerContent>
          }
        />
      </Layout.Box>
    </LoadingWrapper>
  );
}
