import { connect } from "react-redux";
import { medicationTypes } from "web/newMedication/newMedicationConstants";
import BookingFoodComponent from "../_components/feeding/booking/BookingFoodComponent";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { getFoodIds } from "web/food/foodsSelector";
import { getCurrentPet } from "core/selectors/persistentSelectors";

const mapStateToProps = (state, { petId }) => {
  const foodIds = getFoodIds(state, { petId });
  return {
    petId: petId || getCurrentPet(state),
    isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
    showBottomBorder: foodIds.length === 0,
    locationType: medicationTypes.BOOKING,
    topLine: false,
    title: "",
  };
};

export default connect(mapStateToProps)(BookingFoodComponent);
