import { get, isEmpty } from "lodash/fp";
import { createSelector } from "reselect";
import { getFoodByPet, getFoodById } from "../food/foodsSelector";

export const getFoodTimeOfDayById = createSelector(
  [getFoodByPet, (state, props) => props],
  (foods, { foodId }) => get([foodId, "timeOfDay"], foods) || [],
);

export const getFoodTimeOfDayTouched = createSelector([getFoodById], food => {
  return get("timeOfDayTouched", food) || false;
});

export const getIsFoodTimeOfDayEmpty = createSelector([getFoodById], food => {
  const timeOfDay = get("timeOfDay", food);
  return isEmpty(timeOfDay);
});
