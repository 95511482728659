import React from "react";
import styled from "styled-components";
import { color } from "../common/styles/theme";
import PetCartCardsMappingContainer from "../../dux/petCartCard/PetCartCardsMappingContainer";
import { StoreNameNumberAddress } from "dux/storeNameNumberAddress/StoreNameNumberAddress";
import { HotelBookButtonTotalPriceComponent } from "dux/_components/HotelCart/Booking/HotelBookButtonTotalPriceComponent";
import { PetParentCartSummary } from "@/dux/petParentCartSummary/PetParentCartSummary";

const CartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;

  > *:not(:last-child) {
    border-bottom: 1px solid ${color.borderGrey};
  }
`;

class HotelBookingCartComponent extends React.Component {
  render() {
    const { isHidden } = this.props;
    return !isHidden ? (
      <CartWrapper>
        <PetParentCartSummary />
        <HotelBookButtonTotalPriceComponent componentId="HotelBookAndTotalPrice" />
        <StoreNameNumberAddress />
        <PetCartCardsMappingContainer />
      </CartWrapper>
    ) : null;
  }
}

export default HotelBookingCartComponent;
