import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  hotelCheckedOutActionTypes,
  loadHotelCheckedOutFailure,
  loadHotelCheckedOutSuccess,
} from "dux/hotelCheckedOut/columnList/hotelCheckedOutActions";

export function* loadHotelCheckedOut({ checkedOut }) {
  try {
    yield put(loadHotelCheckedOutSuccess({ checkedOut }));
  } catch (error) {
    yield put(loadHotelCheckedOutFailure({ error }));
  }
}

function* watchHotelCheckedOut() {
  yield takeEvery(hotelCheckedOutActionTypes.LOAD_HOTEL_CHECKED_OUT_REQUEST, loadHotelCheckedOut);
}

export default function* hotelCheckedOutSaga() {
  yield all([fork(watchHotelCheckedOut)]);
}
