import React from "react";
import styled from "styled-components";
import { color, font } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import moment from "moment/moment";
import { get } from "lodash/fp";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import calculateAge from "@/core/utils/dateUtils/calculateAge";
import getUnknownPetImage from "@/core/utils/assetUtils/unknownPetImage";
import { pageNames } from "@/core/constants/checkInOutConstants";
import getUSDateString from "@/core/utils/dateUtils/usDate";

class AppointmentCardComponent extends React.Component {
  handleAppointmentClick = () => {
    const {
      ruleViolation,
      router: { navigate },
    } = this.props;

    if (!ruleViolation) {
      return;
    }
    const { CustomerId, itineraryId } = ruleViolation;

    navigate(`/${pageNames.FUTURE_APPOINTMENT}/${CustomerId}/${itineraryId}`);
  };

  render() {
    const { ruleViolation, pet, breeds, associate } = this.props;

    if (!ruleViolation || !pet) {
      return null;
    }

    const { petName, birthDate, owners } = pet;
    const { startDateTime, endDateTime, primaryServiceName } = ruleViolation;
    const age = pet ? calculateAge(birthDate) : "Unknown Age";

    return (
      <Container onClick={this.handleAppointmentClick}>
        <PetImg src={getUnknownPetImage(pet)} />

        <InfoWrapper>
          <PetInfo>
            <BoldPetName>
              {petName} {get(["0", "lastName"], owners)}
            </BoldPetName>
            {age}, {breeds[pet.breedId] ? breeds[pet.breedId].Description : "Unknown Breed"}
          </PetInfo>

          <div>{primaryServiceName}</div>

          {startDateTime && (
            <TimeRangeWrapper>
              <TimeRange>{`${moment(startDateTime).format("LT")} - ${moment(endDateTime).format(
                "LT",
              )}`}</TimeRange>
              {getUSDateString(startDateTime)}
            </TimeRangeWrapper>
          )}

          <div>{associate.associateName}</div>
        </InfoWrapper>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid ${color.borderGrey};
  padding: 5px 0;
  flex-shrink: 0;
  cursor: pointer;

  &:first-child {
    border-top: 2px solid ${color.borderGrey};
  }
`;

const PetImg = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 20px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex: 1;
`;

const PetInfo = styled.div`
  display: flex;
  flex-direction: row;
`;

const BoldPetName = styled.span`
  font-weight: bold;
  margin-right: 0.4rem;
`;

const TimeRangeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TimeRange = styled.div`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.medium};
  color: ${color.tChalla};
  margin-right: 0.625rem;
`;

export default withRouteProps(AppointmentCardComponent);
