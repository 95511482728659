import { connect } from "react-redux";
import SearchFieldComponent from "dux/searchField/searchFieldComponent";
import {
  showSearchResults,
  clearSearchFieldValue,
  setSearchApplied,
  clearSearchApplied,
} from "core/actionCreators/searchSalonActionCreator";
import { clearTabFilter } from "core/actionCreators/associateActionCreator";
import { commonDispatchActions, searchFieldProps } from "dux/searchField/searchFieldCommonProps";
import { ASSOCIATE_SEARCH_DEFAULT_VALUE, ASSOCIATE_SEARCH_OPTIONS } from "core/constants/dropdownOptions";
import validateAssociateSearchField from "core/utils/searchUtils/validateAssociateSearchField";
import getSelectedSearchOption from "core/utils/searchUtils/getSelectedSearchOption";

const mapStateToProps = (state, ownProps) => {
  const commonProps = searchFieldProps(state, ownProps);
  const { searchFieldType} = commonProps;
  return {
    ...commonProps,

    // add any new use case props here
    defaultValue: ASSOCIATE_SEARCH_DEFAULT_VALUE,
    defaultSearchFieldType: "associateName",
    searchOptions: ASSOCIATE_SEARCH_OPTIONS,
    validateSearchField: validateAssociateSearchField, // fn reference, invocation happens in searchFieldComponent
    selectedSearchOption: getSelectedSearchOption({ searchFieldType }, ASSOCIATE_SEARCH_OPTIONS),
    isLiveSearch: true,
    styles: {flexGrow: "4"}
  }
};

const mapDispatchToProps = dispatch => {
  const dispatchCommonActions  = commonDispatchActions(dispatch);

  return {
    ...dispatchCommonActions,
    clearSearchApplied: () => {
      dispatch(clearSearchFieldValue());
      dispatch(clearTabFilter());
      dispatch(clearSearchApplied());
    },
    onSearch:  () => {
      dispatch(setSearchApplied());
      dispatch(
        showSearchResults({
          showSearchResults: true
        })
      );
    }
  }
};


const SearchFieldAssociateContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchFieldComponent);

export default SearchFieldAssociateContainer;
