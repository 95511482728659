import { useState, useEffect, useRef } from "react";
import getIsFormUpdated from "@/core/utils/formUtils/getIsFormUpdated";

export function useForm(initialValues = {}, transformInput = val => val) {
  const initialValuesRef = useRef(initialValues);
  const [values, setFormValues] = useState(initialValuesRef.current);
  const [touched, setTouched] = useState({});

  const setFormValue = (name, value) => {
    setTouched(prev => ({ ...prev, [name]: true }));
    return setFormValues(prev => ({ ...prev, [name]: value }));
  };

  const setFormInput = e => setFormValue(e.target.name, transformInput(e.target.value));
  const setTextAreaInput = (value, name) => setFormValue(name, value);
  const setDropdownOption = (option, name) => setFormValue(name, option.value);
  const didFormFieldsUpdate = getIsFormUpdated(initialValuesRef.current, values);

  useEffect(() => {
    const didInitialValuesUpdate = getIsFormUpdated(initialValuesRef.current, initialValues);

    if (didInitialValuesUpdate) {
      initialValuesRef.current = initialValues;
      setFormValues(initialValues);
      setTouched({});
    }
  }, [initialValues]);

  return {
    initialValues,
    values,
    didFormFieldsUpdate,
    touched,
    setFormInput,
    setFormValues: newValues => {
      setFormValues(newValues);
      setTouched({});
    },
    setTextAreaInput,
    setDropdownOption,
    setFormValue,
  };
}
