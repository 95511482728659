import priceAdjustmentActionTypes from "../../core/actionTypes/priceAdjustmentActionTypes";
import cartActionTypes from "../../core/actionTypes/ui/web/cartActionTypes";

export const loadPriceAdjustmentReasons = () => ({
  type: priceAdjustmentActionTypes.LOAD_PRICE_ADJUSTMENT_REASONS
});

export const loadPriceAdjustmentReasonsRequest = () => ({
  type: priceAdjustmentActionTypes.LOAD_PRICE_ADJUSTMENT_REASONS_REQUEST
});

export const loadPriceAdjustmentReasonsSuccess = ({ priceAdjustmentReasons }) => ({
  type: priceAdjustmentActionTypes.LOAD_PRICE_ADJUSTMENT_REASONS_SUCCESS,
  priceAdjustmentReasons
});

export const loadPriceAdjustmentReasonsFailure = ({ error }) => ({
  type: priceAdjustmentActionTypes.LOAD_PRICE_ADJUSTMENT_REASONS_FAILURE,
  error
});

export const updatePriceOfPetServiceOrAddon = ({
  customerId,
  itineraryId,
  engagementId,
  petServiceItemId,
  data
}) => ({
  type: priceAdjustmentActionTypes.UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON,
  customerId,
  itineraryId,
  engagementId,
  petServiceItemId,
  data
});

export const updatePriceOfPetServiceOrAddonRequest = () => ({
  type: priceAdjustmentActionTypes.UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON_REQUEST
});

export const updatePriceOfPetServiceOrAddonSuccess = ({ itinerary }) => ({
  type: priceAdjustmentActionTypes.UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON_SUCCESS,
  payload: itinerary
});

export const updatePriceOfPetServiceOrAddonFailure = ({ error }) => ({
  type: priceAdjustmentActionTypes.UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON_FAILURE,
  error
});

export default {
  loadPriceAdjustmentReasons,
  loadPriceAdjustmentReasonsRequest,
  loadPriceAdjustmentReasonsSuccess,
  loadPriceAdjustmentReasonsFailure,
  updatePriceOfPetServiceOrAddon,
  updatePriceOfPetServiceOrAddonRequest,
  updatePriceOfPetServiceOrAddonSuccess,
  updatePriceOfPetServiceOrAddonFailure
};
