import { createSelector } from "reselect";
import { get, pick } from "lodash/fp";
import { reasonCodes } from "dux/eligibility/constants/eligibilityConstants";
import { getPetsEligibility } from "dux/eligibility/selectors/eligibilitySelectors";

export const getPetMissingVaccinations = createSelector(
  [getPetsEligibility, (state, props) => props],
  (petsEligibility, { petKey }) => {
    const petEligibility = pick(petKey, petsEligibility) || {};
    const reasons = get("reasons", petEligibility[petKey]);
    return reasons
      ? reasons.filter(reason => reason.reasonCode === reasonCodes.MissingVaccinations)
      : [];
  },
);
