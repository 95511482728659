import { get } from "lodash/fp";
import { createSelector } from "reselect";
import { getFoodById, getFoodByPet, getPetFoodIds } from "../../../web/food/foodsSelector";

export const getFoodSaveToProfileById = createSelector(
  [getFoodByPet, (state, props) => props],
  (foods, { foodId }) => {
    return get([foodId, "saveToProfile"], foods) || false;
  },
);

/**
 *  Selector to get id from food that corresponds to petFoodId on the pet profile foods
 *  @memberOf Selectors.foods
 *  @function
 *  @name getFoodProfileIdById
 *  @param {Object} state
 *  @param {Object} props
 *  @param {string} props.petId
 *  @param {string} props.foodId
 *  @example getFoodProfileIdById(state, { foodId, petId })
 */
export const getFoodProfileIdById = createSelector(
  [getFoodById],
  food => food?.petProfileFoodId ?? food?.petFoodId,
);

/**
 *  Selector to determine if food is from pet profile
 *  @memberOf Selectors.foods
 *  @function
 *  @name getIsFoodOnPetById
 *  @param {Object} state
 *  @param {Object} props
 *  @param {string} props.petId
 *  @param {string} props.foodId
 *  @example getIsFoodOnPetById(state, { foodId, petId })
 */
export const getIsFoodOnPetById = createSelector(
  [getPetFoodIds, getFoodProfileIdById],
  (foodIds, petProfileFoodId) => {
    return !!foodIds.find(id => id === petProfileFoodId);
  },
);

/**
 *  Selector to count the number of foods to be saved on a pet profile
 *  @memberOf Selectors.foods
 *  @function
 *  @name getFoodSaveToProfileAmount
 *  @param {Object} state
 *  @param {Object} props
 *  @param {string} props.petId
 *  @example getFoodSaveToProfileAmount(state, { petId })
 */
export const getFoodSaveToProfileAmount = createSelector(
  [getFoodByPet, getPetFoodIds],
  (foods, petFoods) => {
    const numberOfNewBookingFoods = Object.values(foods)?.filter(
      food => food.saveToProfile && !petFoods.find(petFood => petFood === food.petProfileFoodId),
    ).length;

    const numberOfProfileFoods = petFoods.length;

    // The amount of foods saving to profile equal the amount already from the profile plus whatever new booking foods have 'Save to Profile' checked.
    return foods ? numberOfProfileFoods + numberOfNewBookingFoods : numberOfProfileFoods;
  },
);
