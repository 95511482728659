import { connect } from "react-redux";

import {
  setRedirectToForgotPin,
  hideAuthModal,
  logoutUser
} from "../../../../core/actionCreators/authActionCreators";
import StartShiftModalComponent from "./StartShiftModalComponent";

const mapDispatchToProps = dispatch => ({
  setRedirectToForgotPin: () => dispatch(setRedirectToForgotPin()),
  hideModal: () => dispatch(hideAuthModal()),
  logoutUser: ({ browserSessionOnly }) => dispatch(logoutUser({ browserSessionOnly }))
});

export default connect(
  null,
  mapDispatchToProps
)(StartShiftModalComponent);
