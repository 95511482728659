import React from "react";
import { Layout, Text } from "@prism/psm-ui-components";
import { color } from "web/common/styles/theme";

export default function OnOffLabel(props) {
  const { isHidden, componentID, label, status, isLoading } = props;

  if (!isHidden) {
    return (
      <Layout.Cluster space="cluster-space-3" style={{ marginBottom: "1rem" }} id={componentID}>
        <Layout.Box>
          <Text bold>{label}:</Text>
        </Layout.Box>
        <Text bold color={status ? "text-color-green" : "text-color-red"}>
          {
            isLoading ? "Loading" :
            status ? "On" : "Off"
          }
        </Text>
      </Layout.Cluster>
    );
  }

  return null;
}
