import React from "react";
import {
  ModalCopy,
  ModalFooter,
  ModalHeader,
  PetImage,
  PetImageWrapper,
  SectionWrapper,
} from "web/petCheckInOut/modals/checkInOutModalsLayout";
import CheckInModalDoneContainer from "dux/modals/checkIn/single/CheckInModalDoneContainer";
import PayNowConfirmationButtonSingleAppointmentContainer from "dux/modals/confirmationModal/PayNowConfirmationButtonSingleAppointmentContainer";

function CheckInModalContent(props) {
  const { headerText, petPhoto, message, isHidden } = props;

  return (
    !isHidden && (
      <SectionWrapper data-testid="CheckInModalContent">
        <ModalHeader>{headerText}</ModalHeader>

        <ModalCopy data-testid="CheckInModalMessage">{message}</ModalCopy>

        <PetImageWrapper>
          <PetImage src={petPhoto} />
        </PetImageWrapper>

        <ModalFooter>
          <PayNowConfirmationButtonSingleAppointmentContainer />
          <CheckInModalDoneContainer />
        </ModalFooter>
      </SectionWrapper>
    )
  );
}

export default CheckInModalContent;
