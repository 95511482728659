import { connect } from "react-redux";
import { getPetParentAgentFormData } from "core/selectors/agentsSelectors";
import agentsActionCreators from "core/actionCreators/agentsActionCreators";
import PetParentAgentFormComponent from "./PetParentAgentFormComponent";
import { modalTypes } from "core/constants/customerProfileConstants";
import petParentProfileActionCreator from "core/actionCreators/petParentProfileActionCreator";

const mapStateToProps = (state, ownProps) => {
  const petParentAgentFormData = getPetParentAgentFormData(state, ownProps);
  return {
    petParentAgentFormData,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit({ formValues }) {
    if (ownProps.agentId) {
      if (Object.keys(formValues).length) {
        dispatch(
          agentsActionCreators.updateAgent({
            customerKey: ownProps.customerKey,
            agentId: ownProps.agentId,
            formValues
          })
        );
      }
    } else {
      dispatch(
        agentsActionCreators.createAgent({
          customerKey: ownProps.customerKey,
          formValues
        })
      );
    }
  },
  openRemoveAgentModal: (agentId, customerKey) => {
    if (agentId && customerKey) {
      dispatch(
        agentsActionCreators.setCustomerAndAgentKeys({
          customerKey,
          agentId
        })
      );
      dispatch(petParentProfileActionCreator.showPetParentProfileModal(modalTypes.DELETE_AGENT));
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PetParentAgentFormComponent);
