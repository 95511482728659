import React from "react";
import styled from "styled-components";
import {
  AFontRegular,
  APriceListHeading,
  APriceListCopy,
} from "../../../web/common/styles/abstractStyledComponents";

const Styles = {
  regular: styled(AFontRegular)`
    text-align: ${({ styleObj }) => styleObj && styleObj.textAlign};
    margin-bottom: ${({ styleObj }) => styleObj && styleObj.marginBottom};
    font-weight: ${({ styleObj }) => styleObj && styleObj.fontWeight};
  `,
  priceListHeading: styled(APriceListHeading)``,
  priceList: styled(APriceListCopy)`
    margin-right: ${({ styleObj }) => styleObj && styleObj.marginRight};
    margin-left: ${({ styleObj }) => styleObj && styleObj.marginLeft};
  `,
  div: styled.div`
    margin-right: ${({ styleObj }) => styleObj && styleObj.marginRight};
    margin-left: ${({ styleObj }) => styleObj && styleObj.marginLeft};
    margin-bottom: ${({ styleObj }) => styleObj && styleObj.marginBottom};
    color: ${({ styleObj }) => styleObj && styleObj.color};
    font-weight: ${({ styleObj }) => styleObj && styleObj.fontWeight};
    text-align: ${({ styleObj }) => styleObj && styleObj.textAlign};
    font-size: ${({ styleObj }) => styleObj && styleObj.fontSize};
  `,
  span: styled.span`
    margin-right: ${({ styleObj }) => styleObj && styleObj.marginRight};
    margin-left: ${({ styleObj }) => styleObj && styleObj.marginLeft};
    color: ${({ styleObj }) => styleObj && styleObj.color};
    font-weight: ${({ styleObj }) => styleObj && styleObj.fontWeight};
  `,
  h4: styled.h4``,
};

const View = ({ isHidden, componentID, styleObj, styleType = "regular", text }) => {
  if (!styleType) {
    return null;
  }

  if (!isHidden) {
    const Component = Styles[styleType];
    return (
      <Component
        id={componentID}
        data-testid="Test-TextComponent"
        style={styleObj}
        styleObj={styleObj}
      >
        {text}
      </Component>
    );
  }

  return null;
};

export default View;
