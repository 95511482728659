import { select, take } from "redux-saga/effects";
import { createLoadingSelector } from "../selectors/utils";

export function* waitFor(selector, args) {
  let result = yield select(selector, args);
  if (result) {
    return result;
  }

  while (true) {
    yield take("*");
    result = yield select(selector, args);
    if (result) {
      return result;
    }
  }
}

export function* waitForValue(selector, args, value) {
  let result = yield select(selector, args);
  if (result === value) {
    return result;
  }

  while (true) {
    yield take("*");
    result = yield select(selector, args);
    if (result === value) {
      return result;
    }
  }
}

export function* waitForRequests(actionTypes) {
  yield waitForValue(createLoadingSelector(actionTypes), {}, false);
}
