import { createSelector } from "reselect";

export const selectServiceProductFamilies = state => state?.serviceProductFamilies;

export const selectServiceProductFamilyById = serviceFamilyId =>
  createSelector([selectServiceProductFamilies], serviceProductFamilies => {
    return serviceProductFamilies.find(
      service => service.productFamilyID === serviceFamilyId.toString(),
    )?.productFamilyName;
  });
