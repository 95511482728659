import { createSelector } from "reselect";
import { getStoreFromSalon } from "core/selectors/entitiesSelector";

export const getStoreNameAndNumber = createSelector([getStoreFromSalon], store => {
  const name = store?.Name;
  const number = store?.StoreNumber;
  return `${name} #${number}`;
});

export const getStoreAddress = createSelector([getStoreFromSalon], store => {
  const street1 = store?.StreetLine1;
  const street2 = store?.StreetLine2;
  const city = store?.City;
  const state = store?.StateProvinceAbbreviation;
  const zipCode = store?.ZipPostalCode;

  if (street2) {
    return `${street1} ${street2} ${city}, ${state} ${zipCode}`;
  }

  return `${street1} ${city}, ${state} ${zipCode}`;
});
