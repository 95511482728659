import * as React from 'react';
import moment, { Moment } from 'moment';
import { DAYS_OF_WEEK, getIsTargetDate, getDayNumbersToDisplayInCalendarGrid } from './utils';
import PrevNextSelector from '../PrevNextSelector';
import { Layout, BoxProps } from '../../components/Layout';
import List from '../../components/List';
import Text from '../../components/Text';
import SvgIcon from '../../components/Icon';
import Button from '../../components/Button';
import './styles.css';

export interface CalendarProps extends BoxProps {
  /**
   *  Custom callback to fire off when the previous
   *  month is toggled in the calendar. This callback
   *  can be used to dispatch Redux actions, etc.
   */
  onSelectPreviousMonth?: (MomentObj: Moment) => void;
  /**
   *  Custom callback to fire off when the next month
   *  is toggled in the calendar. This callback
   *  can be used to dispatch Redux actions, etc.
   */
  onSelectNextMonth?: (MomentObj: Moment) => void;
  /**
   *  Custom callback to fire off when a new date
   *  is selected in the calendar. This callback
   *  can be used to dispatch Redux actions, etc.
   */
  onSelectDate?: (selectedDate: Moment) => void;
  /**
   *  Custom date in the format '2021-01-14' to use as the
   *  selected date. When passed in, the selected dat will
   *  become a 'controlled' prop in the calendar.
   */
  selectedDate?: string;
}

interface CalendarState {
  internalDate: Moment;
  selectedDate: Moment;
}

export default class Calendar extends React.Component<CalendarProps, CalendarState> {
  //1. When the dashboard loads, get training class results ranging from the 1st Sunday in the calendar to the last Saturday in the Calendar  in "DashboardTrainingContainer
  // * we currently load by 1st of the month to last of the month, this needs to be updated to encompass the visible dates in the calendar
  // * need to add a list of months that have been loaded into state e.g. possibly using js Map Object - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
  // *
  // 2. when onSelectNextMonth is invoked -
  // *

  // ideas:
  // in the initial call (dashboard loads) in the n the DashboardTrainingContainer, in the dispatch LoadTrainingClassSessions function, get the current month number, and pass it into the getTrainingClassSessions action
  // in the triningClassSessionsSaga, when we make a call to the api, save the month number in an array or object in state, this way we can keep track of the months we have called.
  // when the button to go to next month or prev month is clicked (above calendar) before we dispatch the getTrainingClassSessions action, check if the month # is in the list, if so don't dispatch.
  // when the next day or pev day is selected (above class form) do a check to see if the current month number is in the list, if not don't dispatch getTrainingClassSessions action.
  // when the days is selected in the calendar, (in container) do a check to see if the current month number is in the list, if not don't dispatch getTrainingClassSessions action.

  // state is holding moment object representing the month, (day is based on selected date)

  // questions:
  // are we loading training class results now?
  // YES
  // where are are all the places we are dispatching getTrainingClassSessions?
  //

  state = { internalDate: moment(), selectedDate: moment() };

  onSelectPreviousMonth = () => {
    const { onSelectPreviousMonth } = this.props;
    const prevMonth = moment(this.state.internalDate).subtract(1, 'months');
    this.setState(
      () => ({
        internalDate: prevMonth,
      }),
      () => {
        if (onSelectPreviousMonth) {
          onSelectPreviousMonth(prevMonth);
        }
      }
    );
  };

  onSelectNextMonth = () => {
    const { onSelectNextMonth } = this.props;
    const nextMonth = moment(this.state.internalDate).add(1, 'months');
    this.setState(
      () => ({
        internalDate: nextMonth,
      }),
      () => {
        if (onSelectNextMonth) {
          onSelectNextMonth(nextMonth);
        }
      }
    );
  };

  onSelectDate = (date: Moment) => {
    const { onSelectDate } = this.props;
    this.setState(
      () => ({ selectedDate: date }),
      () => {
        if (onSelectDate) {
          onSelectDate(this.state.selectedDate);
        }
      }
    );
  };

  getSelectedDate = () => {
    return !!this.props.selectedDate ? this.props.selectedDate : this.state.selectedDate;
  };

  getCalendarProps = (internalDate: Moment) => {
    const date = moment(internalDate);
    const month = date.format('MMMM');
    const year = date.format('YYYY');

    return {
      internalDate,
      date,
      month,
      year,
      selectedDate: moment(this.getSelectedDate()),
      onSelectPreviousMonth: this.onSelectPreviousMonth,
      onSelectNextMonth: this.onSelectNextMonth,
      onSelectDate: this.onSelectDate,
      dayNumbersToDisplayInCalendarGrid: getDayNumbersToDisplayInCalendarGrid(internalDate),
    };
  };

  getCalendarCellFontColor(isToday: boolean, isDisabled: boolean) {
    if (isToday) {
      return 'text-color-white';
    }
    if (isDisabled) {
      return 'text-color-disabled';
    }
    return 'text-color-black';
  }

  render() {
    const gridTemplateColumns = 'repeat(7, minmax(0ch, 1fr))';
    const { onSelectDate, month, year, selectedDate, dayNumbersToDisplayInCalendarGrid } = this.getCalendarProps(
      this.state.internalDate
    );

    const numberOfWeeks = dayNumbersToDisplayInCalendarGrid.length / 7;

    return (
      <Layout.Box className="psm-Calendar" {...this.props}>
        <Layout.Stack space="stack-space-4">
          <Layout.Stack space="stack-space-8">
            <Layout.Box
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                justifyItems: 'center',
                alignItems: 'center',
                width: '60ch',
                margin: '0 auto',
              }}
            >
              <Button variant="no-outline" onClick={this.onSelectPreviousMonth}>
                <SvgIcon variant="hero-icon-left" size="psm-icon-size-32" />
              </Button>
              <Text size="text-size-2xl" bold>{`${month} ${year}`}</Text>
              <Button variant="no-outline" onClick={this.onSelectNextMonth} dir="rtl">
                <SvgIcon variant="hero-icon-right" size="psm-icon-size-32" />
              </Button>
            </Layout.Box>
            <Layout.Box
              className="psm-Calendar__dayLabel"
              backgroundColor="box-background-blue"
              padding="box-padding-4"
            >
              <Layout.Grid style={{ gridTemplateColumns }}>
                <List items={DAYS_OF_WEEK} as={Layout.Box} style={{ textAlign: 'center' }}>
                  {(day) => (
                    <Text size="text-size-2xl" color="text-color-white" align="center" bold>
                      {day.label}
                    </Text>
                  )}
                </List>
              </Layout.Grid>
            </Layout.Box>
          </Layout.Stack>
          <Layout.Grid
            className="psm-Calendar__dayGrid"
            style={{
              gridTemplateRows: `repeat(${numberOfWeeks}, minmax(20ch, auto))`,
              gridTemplateColumns,
              '--setStackSpace': '0rem',
            }}
          >
            <List items={dayNumbersToDisplayInCalendarGrid} style={{ cursor: 'pointer' }}>
              {(item) => {
                const isToday = getIsTargetDate(selectedDate, item.date);
                return (
                  <Layout.Box
                    backgroundColor={isToday ? 'box-background-blue' : 'box-background-white'}
                    borderColor="box-border-color-gray-100"
                    padding="box-padding-4"
                    style={{ height: '100%' }}
                    onClick={() => {
                      if (onSelectDate) {
                        onSelectDate(moment(item.date));
                      }
                    }}
                  >
                    <Layout.Stack space="stack-space-7">
                      <Text
                        size="text-size-2xl"
                        color={this.getCalendarCellFontColor(isToday, item.isDisabled)}
                        align="right"
                      >
                        {item.dayNumber}
                      </Text>
                      <Layout.Cluster>
                        <Layout.Stack space="stack-space-2">
                          {React.Children.map(this.props.children, (child) => {
                            if (React.isValidElement(child)) {
                              // @ts-ignore
                              const childProps: Object = child?.props ?? {};
                              const cloneProps = {
                                selectedDate,
                                isToday,
                                date: item.date,
                                key: item.date,
                                ...childProps,
                              };
                              return React.cloneElement(child, cloneProps);
                            } else {
                              return;
                            }
                          })}
                        </Layout.Stack>
                      </Layout.Cluster>
                    </Layout.Stack>
                  </Layout.Box>
                );
              }}
            </List>
          </Layout.Grid>
        </Layout.Stack>
      </Layout.Box>
    );
  }
}
