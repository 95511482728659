import moment from "moment";

/**
 * Responsible for formating the date with Short Day Name, Month, Day, e.g. Wed, April 1
 *
 * @exports formatShortDayNameMonthDay
 * @returns {String}
 */
export default function formatShortDayNameMonthDay(selectedDate) {
  return moment(selectedDate).format("ddd, MMMM D");
}
