import { all } from "redux-saga/effects";
import customersSaga from "./customersSaga";
import petsSaga from "./petsSaga";
import itinerariesSaga from "./itinerariesSaga";
import engagementsSaga from "./engagementsSaga";
import petServiceItemsSaga from "./petServiceItemsSaga";
import associateSaga from "./associateSaga";
import serviceStatusesSaga from "./serviceStatusesSaga";
import addOnsSaga from "./addOnsSaga";
import voidTransactionSaga from "./voidTransactionSaga";
import serviceSelectionSaga from "./servicesSelectionSaga";
import absenceReasonsSaga from "./absenceReasonsSaga";
import salonSaga from "./salonSaga";
import specialsSaga from "./specialsSaga";
import salonsSaga from "./salonsSaga";

export default function* rootSaga() {
  yield all([
    customersSaga(),
    petsSaga(),
    itinerariesSaga(),
    engagementsSaga(),
    petServiceItemsSaga(),
    associateSaga(),
    serviceStatusesSaga(),
    addOnsSaga(),
    absenceReasonsSaga(),
    voidTransactionSaga(),
    serviceSelectionSaga(),
    specialsSaga(),
    salonSaga(),
    salonsSaga(),
  ]);
}
