import React from "react";

/**
 * React Node for Down Arrow SVG
 *
 * @memberOf Views.SVG
 * @function
 * @name DownArrowSvg
 * @returns {JSX.Element}
 *
 * @example <DownArrowSvg />
 */
const DownArrowSvg = () => {
  return (
    <svg
      id="DownArrowSvg"
      data-name="DownArrowSvg"
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="12px"
      viewBox="0 0 17.03 13.55"
    >
      <polyline points="17.03 0 8.51 13.55 0 0" style={{ fill: "#575b5c" }} />
    </svg>
  );
};

export default DownArrowSvg;
