import { connect } from "react-redux";

import {
  setUpcomingBoardedGuestChecked,
  clearUpcomingBoardedGuestChecked
} from "../../core/actionCreators/upcomingAndHereActionCreators";
import { getUpcomingBoardedGuestChecked } from "../../core/selectors/upcomingAndHereSelectors";
import CheckboxWithLabel from "../common/CheckboxWithLabel";

const mapStateToProps = state => ({
  checked: getUpcomingBoardedGuestChecked(state),
  label: "boardedGuest"
});

const mapDispatchToProps = dispatch => ({
  setHandler: () => dispatch(setUpcomingBoardedGuestChecked()),
  clearHandler: () => dispatch(clearUpcomingBoardedGuestChecked())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckboxWithLabel);
