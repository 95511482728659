import styled from "styled-components";
import React from "react";
import { fontSizes } from "web/common/styles/responsive/fonts";

const StyledError = styled.div`
  display: ${({ styleObject }) =>
    styleObject && styleObject.display ? styleObject.display : "inline-block"};
  color: ${({ styleObject }) => (styleObject && styleObject.color ? styleObject.color : "red")};
  font-size: ${fontSizes.small};
  ${({ styleObject }) =>
    `
    margin-top: ${styleObject && styleObject.marginTop ? styleObject.marginTop : "initial"};
  `}
`;

const Icon = styled.div`
  vertical-align: top;
  padding-right: 2px;
`;

export default function ErrorText({ isHidden, componentID, styleObject, message, icon }) {
  if (!isHidden) {
    return (
      <StyledError id={componentID} data-testid="test-error-text" styleObject={styleObject}>
        {icon && (
          <Icon>
            <img src={icon} alt="Warning" />
          </Icon>
        )}
        {message}
      </StyledError>
    );
  }
  return null;
}
