import React, { Component } from "react";
import Section from "../../../common/Section";
import PetParentAgentForm from "../petParentAgentForm/PetParentAgentFormContainer";

class PetParentAgentFormComponent extends Component {
  render() {
    const {
      isHidden,
      petParentAgents,
      customerKey,
      showAddAgentForm,
      isAddAgentFormShown,
      isAddAgentButtonShown,
      componentId,
    } = this.props;

    if (isHidden) {
      return null;
    }

    return (
      <Section
        componentId={componentId}
        title="Agent information"
        button="Add agent"
        opacity="1"
        buttonClickHandler={showAddAgentForm}
        buttonDisabled={!isAddAgentButtonShown}
      >
        {(petParentAgents.length === 0 || isAddAgentFormShown) && (
          <PetParentAgentForm customerKey={customerKey} firstListItem />
        )}
        {petParentAgents
          .filter(agent => agent.isActive || agent.activeFlag)
          .map((agent, key) => (
            <PetParentAgentForm
              customerKey={customerKey}
              agentId={agent.agentId}
              firstListItem={key === 0 && !isAddAgentFormShown}
              lastListItem={key === petParentAgents.length - 1}
              key={agent.agentId}
            />
          ))}
      </Section>
    );
  }
}

export default PetParentAgentFormComponent;
