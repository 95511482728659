import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get the available pet service bundles by pet ID.
 * From the System Services Booking API
 * - Get bundles by Pet ID
 * @param {*} { petId }
 * @returns {*} AxiosPromise
 */

export default function getAvailableBundlesByPet({ petId }) {
  return bookingAPI.get(`${endpointVersionString()}/products/pets/${petId}/bundles`);
}
