// Utils
import startCase from "lodash/fp/startCase";
import keys from "lodash/fp/keys";
import uniq from "lodash/uniq";
import intersectionWith from "lodash/intersectionWith";
import isEqual from "lodash/isEqual";
import { sortByPropertyAsc, sortByPropertyDesc } from "@/core/utils/arrayUtils/sortByProperty";
import moment from "moment";
import { REG_EX } from "@/core/constants";

/**
 * Generates default table headers dynamically based on table data
 * @memberof helpers.associate
 * @function
 * @param {Array} data - table data
 * @returns {Array} - table headers
 */
export const formatTableHeadersFromArray = data => {
  if (!data || !data.length) return [];

  return keys(data[0]).map(propertyKey => startCase(propertyKey));
};

/**
 * Sort data based on property key and ASC or DESC order
 * @memberof helpers.associate
 * @function
 * @param {Array} data - data
 * @param {object} sortInfo - { sortOrder: 'asc', sortKey: 'propertyKey'}
 * @returns {Array} - sorted data
 */
export const sortTableDataByKeyAndSort = ({ data, sortInfo }) => {
  if (!sortInfo || !data || !data.length) return [];

  const { sortOrder, sortKey } = sortInfo;

  if (sortOrder === "asc") {
    const ascData = sortByPropertyAsc(data, sortKey);

    return [...ascData];
  } else if (sortOrder === "desc") {
    const descData = sortByPropertyDesc(data, sortKey);

    return [...descData];
  } else {
    return data;
  }
};

/**
 * Helper to format a boolean data value to a "Yes" or "No" values
 * @memberof helpers.associate
 * @function formatBooleansToValues
 * @param {boolean | any} data - data
 * @returns {string} - "Yes" for true, "No" for false or default if not boolean
 */
export const formatBooleansToValues = data => {
  if (typeof data !== "boolean") return data;

  if (data === true) {
    return "Yes";
  }

  if (data === false) {
    return "No";
  }
};

/**
 * Helper to format a date value in proper format as mm/dd/yyyy hh:mm AM/PM
 * @memberof helpers.associate
 * @function formatDateTimeValues
 * @param {string | any} data - data
 * @returns {string | any} - mm/dd/yyyy hh:mm AM/PM or data
 */
export const formatDateTimeValues = data => {
  if (REG_EX.OFFSET_DATE_TIME.test(data)) {
    return moment.parseZone(data).format("MM/DD/YY LT");
  }

  return data;
};

/**
 * Helper to merge filters for tables
 * @memberof helpers.associate
 * @function mergeCustomFiltersForTable
 * @param {Array} newFilterValues
 * @param {String} newFilterKey
 * @param {Number} newFilterArrayIndex
 */
export const mergeCustomFiltersForTable = (
  currentFilters = [],
  newFilterValues = [],
  newFilterKey = "",
  newFilterArrayIndex = 0,
) => {
  const hasNoCurrentFilters = !currentFilters.length;
  const hasNoNewFilterValues = !newFilterValues.length;

  // Case 1: No new filters are selected and  no current filters from store or initial filters
  if (hasNoCurrentFilters && hasNoNewFilterValues) return [];

  // Case 2: Has current filters but removed old selections for a particular key
  if (hasNoNewFilterValues)
    return currentFilters.filter(currentFilter => currentFilter.filterKey !== newFilterKey);

  // Case 3: Has no current filters but has new filters
  if (hasNoCurrentFilters) {
    return [
      {
        filterArrayIndex: newFilterArrayIndex,
        filterKey: newFilterKey,
        filterValues: newFilterValues,
      },
    ];
  }

  // Case 4: Has current filters but not updating an existing filter
  const hasExistingFilter = currentFilters.find(filter => filter?.filterKey === newFilterKey);

  // Push new filter if doesn't update an existing filter
  if (!hasExistingFilter) {
    return [
      ...currentFilters,
      ...[
        {
          filterArrayIndex: newFilterArrayIndex,
          filterKey: newFilterKey,
          filterValues: newFilterValues,
        },
      ],
    ];
  }

  // Case 5: Has current filters and updating an existing filter
  const mergedFilters = currentFilters.map(filter => {
    if (filter?.filterKey === newFilterKey) {
      const intersectionValues = intersectionWith(filter.filterValues, newFilterValues, isEqual);
      return {
        ...filter,
        filterValues:
          intersectionValues.length > 0
            ? intersectionValues
            : uniq([...filter.filterValues, ...newFilterValues]),
      };
    }

    return filter;
  });

  return mergedFilters;
};
