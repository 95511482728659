import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Text, Form } from "@prism/psm-ui-components";
import ResizableTextarea from "web/common/formInputs/ResizableTextarea";
import formatToPascalCase from "core/utils/stringManipulationUtils/pascalCaseUtil";
import { ClickOutside } from "core/utils/clickOutsideUtils/clickOutside";
import { getCustomer } from "core/selectors/entitiesSelector";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getStoreNumber, hasManagerPrivileges } from "core/selectors/persistentSelectors";
import { setDoNotBookHotel, setDoNotBookSalon } from "dux/doNotBook/doNotBookActions";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { getPrismCustomerHotelProfile } from "web/petParentProfile/selectors/getPrismCustomerProfileSelector";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "core/actionTypes/customersActionTypes";

export const DoNotBookInput = props => {
  // Component Props.
  const {
    componentId,
    isHidden,
    inputLabel,
    textAreaLabel,
    textAreaInitValue,
    updateDoNotBook,
    isLoading,
    isManager,
  } = props;

  // component State
  const [textAreaValue, setTextAreaValue] = useState(textAreaInitValue);
  const [textAreaError, setTextAreaError] = useState(false);
  const [textLabelColor, setTextLabelColor] = useState("text-color-disabled");
  const [isDisabled, setDisabled] = useState(!textAreaInitValue);

  useEffect(() => {
    setTextAreaValue(textAreaInitValue);
    setDisabled(!textAreaInitValue);
  }, [textAreaInitValue]);

  useEffect(() => {
    setTextLabelColor(textAreaInitValue && isManager ? "text-color-black" : "text-color-disabled");
  }, [textAreaInitValue, isManager]);

  // Enable and disable "do not book reason" field based on if checkbox is clicked or not.
  const onCheckBoxChange = () => {
    setDisabled(!isDisabled);
    isDisabled ? setTextLabelColor("text-color-black") : setTextLabelColor("text-color-disabled");
  };

  // Reset TextArea back to black on text input
  const onTextAreaChange = e => {
    setTextAreaValue(e.target.value);
    setTextLabelColor("text-color-black");
    setTextAreaError(false);
  };

  // A middleware function between the click outside and the container dispatch handler.
  const handleRequired = () => {
    const noText = textAreaValue === null || textAreaValue === undefined || textAreaValue === "";

    if (isDisabled) {
      // Clear text area, then Send empty string and false doNotbook to server
      setTextAreaValue("");
      updateDoNotBook({ doNotBookReason: "", doNotBook: !isDisabled });
      return;
    }

    if (!isDisabled && noText) {
      // Set text area to red and make no server call
      setTextAreaError(true);
      setTextLabelColor("text-color-red");
    } else {
      // send do not book info to server
      updateDoNotBook({ doNotBookReason: textAreaValue, doNotBook: !isDisabled });
    }
  };

  // Click Outside of the component
  const ref = useRef(null);
  ClickOutside(ref, handleRequired);

  // Set Ids names to Pascal case
  const pascalInputLabel = formatToPascalCase(inputLabel, " ", false);
  const pascalTextAreaLabel = formatToPascalCase(textAreaLabel, " ", false);

  if (isHidden) {
    return null;
  }

  return (
    <div id={componentId} ref={ref}>
      <Layout.Grid cellMinWidth="cell_min-width-1" cellSpacing="cell-space-14">
        <Layout.Cluster justify="flex-start" space="cluster-space-2">
          <Form.Field.Checkbox
            disabled={!isManager}
            id={`${componentId}__CheckBox`}
            changed={onCheckBoxChange}
            checked={!isDisabled}
          />
          <Text
            id={`${componentId}__${pascalInputLabel}Label`}
            size="text-size-lg"
            color={isLoading || !isManager ? "text-color-disabled" : "text-color-black"}
          >
            {inputLabel}
          </Text>
        </Layout.Cluster>

        <Layout.Stack>
          <Text
            id={`${componentId}__${pascalTextAreaLabel}Label`}
            size="text-size-lg"
            color={textLabelColor}
          >
            {textAreaLabel}
          </Text>

          <ResizableTextarea
            id={`${componentId}__textArea`}
            name="doNotBookReason"
            disabled={isDisabled || !isManager}
            value={textAreaValue}
            onChange={e => onTextAreaChange(e)}
            error={textAreaError}
          />
        </Layout.Stack>
      </Layout.Grid>
    </div>
  );
};

// SALON CONTAINER ----------------------------------------------------------------------------------------
export const DoNotBookInputSalonContainer = connect(
  state => {
    const customerKey = getCurrentCustomerKey(state);
    const customer = getCustomer(state, { customerKey });
    const bookingFlow = getSystemBookingFlow(state);
    const isLoading = createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state);

    return {
      isHidden: bookingFlow !== systemName.SALON || !customerKey,
      componentId: "doNotBookInputSalon",
      inputLabel: "Do not Book Salon",
      textAreaLabel: "Do Not Book Reason",
      storeNumber: getStoreNumber(state),
      textAreaInitValue: customer?.doNotBookReason ?? "",
      isLoading,
      isManager: hasManagerPrivileges(state),
    };
  },

  dispatch => {
    return {
      dispatchHandler: ({ doNotBookReason, doNotBook }) => {
        dispatch(setDoNotBookSalon({ doNotBookReason, doNotBook }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      inputLabel,
      textAreaLabel,
      textAreaInitValue,
      isHidden,
      isLoading,
      isManager,
    } = mapProps;
    const { dispatchHandler } = dispatchProps;

    return {
      //Props to pass to view
      componentId,
      inputLabel,
      textAreaLabel,
      textAreaInitValue,
      isHidden,
      isLoading,
      isManager,

      // actions to pass to view
      updateDoNotBook: ({ doNotBookReason, doNotBook }) =>
        dispatchHandler({ doNotBookReason, doNotBook }),
    };
  },
)(DoNotBookInput);

// HOTEL CONTAINER ----------------------------------------------------------------------------------------
export const DoNotBookInputHotelContainer = connect(
  state => {
    const customerKey = getCurrentCustomerKey(state);
    const bookingFlow = getSystemBookingFlow(state);
    const hotelProfile = getPrismCustomerHotelProfile(state, { customerKey });
    const isLoading = createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state);

    return {
      isHidden: bookingFlow !== systemName.HOTEL || !customerKey,
      componentId: "doNotBookInputHotel",
      inputLabel: "Do not Book Hotel",
      textAreaLabel: "Do Not Book Reason",
      textAreaInitValue: hotelProfile?.doNotBookReason ?? "",
      isLoading,
      isManager: hasManagerPrivileges(state),
    };
  },

  dispatch => {
    return {
      dispatchHandler: ({ doNotBookReason, doNotBook }) => {
        dispatch(setDoNotBookHotel({ doNotBookReason, doNotBook }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      inputLabel,
      textAreaLabel,
      textAreaInitValue,
      isHidden,
      isLoading,
      isManager,
    } = mapProps;
    const { dispatchHandler } = dispatchProps;

    return {
      //Props to pass to view
      componentId,
      inputLabel,
      textAreaLabel,
      textAreaInitValue,
      isHidden,
      isLoading,
      isManager,

      // actions to pass to view
      updateDoNotBook: ({ doNotBookReason, doNotBook }) =>
        dispatchHandler({ doNotBookReason, doNotBook }),
    };
  },
)(DoNotBookInput);
