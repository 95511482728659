import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get a list of the values available for max per block/check in reasons.
 * These are required to allow an associate to select the reason
 * for a manual change of an appointment's max per block/check in value.
 * From System Services Booking API
 * - Get list of reasons for cancellation
 * @returns {*} AxiosPromise
 */
export function fetchMaxPerBlockOrCheckReductionReasons() {
  return bookingAPI.get(`${endpointVersionString()}/settings/maxreductionreasons`);
}
