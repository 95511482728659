import { petsmartWebPublicAPI } from "core/services/petsmartWebPublic/_axiosConfigPetMWebPubic";

/**
 * Search for PetSmart stores within a 75 mile radius from a specified latitude/longitude point.
 * Filter to only those stores that have all of the services specified by "MustHaveServices", if a list is supplied.
 *  * @param {Object} obj - coordinates.
 *  * @param {string} obj.latitude - The latitude to use as the center point for the store search.
 *  * @param {string} obj.longitude - The longitude to use as the center point for the store search.
 * @returns {AxiosPromise<any>}
 */
export const fetchNearbyStores = ({ latitude, longitude }) => {
  return petsmartWebPublicAPI.get(
    `search/stores?model.latitude=${latitude}&model.longitude=${longitude}`,
  );
};
