import { connect } from "react-redux";
import { Button } from "@prism/psm-ui-components";
import { color } from "@/web/common/styles/theme";
import { toggleCancelTrainingAppointmentModal } from "./manageTrainingClassPageActions";
import { getIsTrainingAppointmentCompleted } from "@/dux/trainingClassSessions/selectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";

export default connect(
  (state, { attendeeId }) => {
    const storeNumber = getStoreNumber(state);
    return {
      variant: "link",
      style: { fontFamily: "lato", color: color.errorRed },
      children: "Cancel",
      disabled: getIsTrainingAppointmentCompleted(state, { attendeeId, storeNumber }),
    };
  },
  dispatch => ({
    onClick: () => {
      dispatch(toggleCancelTrainingAppointmentModal());
    },
  }),
)(Button);
