import React from "react";
import styled from "styled-components";
import { fontSizes } from "web/common/styles/responsive/fonts";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  font-size: ${fontSizes.regular};
  font-weight: bold;
  text-align: center;
`;

export default function GeneralTitle(props) {
  const { isHidden, componentID, children } = props;

  if (!isHidden) {
    return <SectionWrapper id={componentID}>{children}</SectionWrapper>;
  }

  return null;
}
