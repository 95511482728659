import React from 'react';
import classnames from 'classnames';

import './Radio.css';

export interface RadioProps {
  /**
   * HTML id of the helper text used to describe the component
   */
  ariaDescribedBy?: string;
  /**
   * Toggles whether or not the checkbox is checked or unchecked
   */
  checked?: boolean;
  /**
   * CSS class names that can be appended to the component.
   */
  className?: string;
  /**
   * Disables the field and prevents editing the contents
   */
  disabled?: boolean;
  /**
   * HTML id for the component
   */
  id?: string;
  /**
   * HTML name attribute for the radio button
   */
  name?: string;
  /**
   * Function that fires when field value has changed
   */
  onChange?: React.ChangeEventHandler;
    /**
   * Function that fires when field is clicked
   * This is intended to be used when the need to 'toggle' a radio button exists
   */
  onClick?: React.MouseEventHandler;
  /**
   * Toggles the form control's interactivity. When `readOnly` is set to `true`, the form control is not interactive
   */
  readOnly?: boolean;
  /**
   * Tabindex passed down from RadioField.Item to add or remove focus capability
   */
  tabIndex?: number;
  /**
   * A string representing the value of the radio button
   */
  value?: string | number;
}

/**
 * Primary UI component for user interaction
 */
const Radio = ({
  ariaDescribedBy,
  id,
  name,
  value,
  className,
  checked,
  disabled,
  readOnly,
  onChange = () => {},
  onClick = () => {},
  tabIndex,
  ...other
}: RadioProps) => {
  const componentClassName = classnames('psm-radio', className);

  return (
    <div className={componentClassName} {...other}>
      <input
        id={id}
        type="radio"
        name={name}
        value={value}
        tabIndex={tabIndex}
        className="psm-radio__input"
        checked={checked}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        onClick={onClick.bind(null, checked)}
        aria-describedby={ariaDescribedBy}
      />
      <span className="psm-radio__custom-radio" />
    </div>
  );
};

export default Radio;
