import { getStoreInformation } from "@/core/selectors/entitiesSelector";
import { createSelector } from "reselect";
import {
  selectHotelBookingEndDate,
  selectHotelBookingPetList,
  selectHotelBookingStartDate,
  selectPetSelectedService,
} from "web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import moment from "moment";
import { getState } from "core/selectors/commonSelector";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { frequencyConstants } from "dux/frequency/FrequencyConstants";
import { getFoodByPetExcludingNewFoodID } from "@/web/food/foodsSelector";
import { getMedsByPetExcludingNewMedID } from "@/web/medication/medicationsSelector";
import { selectHostPetIdByPetSelectedService } from "@/web/pendingAppointment/selectors/pendingAppointmentSelectors";
import { selectHotelCartAndDenormalizeForPut } from "../servicesCartHotel/servicesCartHotelSelectors";
import { formatPetFoodsForCartRequest, formatPetMedsForCartRequest } from "./servicesCartUtils";
import { selectPetNeedsReviewByPetId } from "../hotelCheckInOutPetNeedsReview/hotelCheckInOutPetNeedsReviewSelectors";
import { selectRebookingAddons } from "../hotelEngagements/hotelEngagementSelectors";
import { selectPetNotesForRebooking } from "../hotelCheckInOutPetNotes/hotelCheckInOutPetNotesSelectors";

export const selectServicesCart = state => state?.servicesCart || {};

export const selectServicesCartWithoutError = createSelector(
  [selectServicesCart],
  ({ error, ...servicesCart }) => servicesCart,
);

// @param storeNumber
export const selectStoreDetailsForCartRequest = createSelector(
  [getState, getStoreNumber],
  (state, storeNumber) => {
    const storeInfo = getStoreInformation(state, { storeNumber });
    return {
      storeNumber,
      rawOffset: storeInfo?.TimeZone?.RawOffset,
      timeZoneName: storeInfo?.TimeZone?.TimeZoneId,
    };
  },
);

export const selectProductDetailsForCartRequest = (petId, isRebooking) =>
  createSelector(
    [
      selectPetSelectedService(petId),
      state => selectRebookingAddons(state, { petId, isRebooking }),
    ],
    (selectedService, addons) => {
      return [
        {
          productId: selectedService,
          isPrimaryService: true,
          frequency: frequencyConstants.DAILY,
          customFrequencyDates: [],
        },
        ...addons,
      ];
    },
  );

// The hotel booking food section adds the pet profile foods to the foods state and sets the default freqencies,
// So for the cart POST we just need to grab the pets foods from the food state not from entities
export const selectPetProfileFoodsForCartRequest = createSelector(
  [getFoodByPetExcludingNewFoodID],
  foods => formatPetFoodsForCartRequest(foods),
);

// The hotel booking meds section adds the pet profile meds to the meds state and sets the default freqencies,
// So for the cart POST we just need to grab the pets meds from the med state not from entities
export const selectPetProfileMedsForCartRequest = createSelector(
  [getMedsByPetExcludingNewMedID],
  meds => formatPetMedsForCartRequest(meds),
);

// TODO: refactor to not call selectors within selectors
export const selectPetDetailsForCartRequest = createSelector(
  [
    selectHotelBookingPetList,
    selectHotelBookingStartDate,
    selectHotelBookingEndDate,
    getState,
    (state, props) => props?.isRebooking,
  ],
  (pets, startDate, endDate, state, isRebooking) => {
    return pets?.map(pet => ({
      petId: pet,
      hostPetId: selectHostPetIdByPetSelectedService(pet)(state),
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      dropOffTime: moment(startDate).format("HH:mm:ss.sss"),
      pickUpTime: moment(endDate).format("HH:mm:ss.sss"),
      notes: isRebooking ? selectPetNotesForRebooking(state, { petId: pet }) : "",
      products: selectProductDetailsForCartRequest(pet, isRebooking)(state),
      feedings: selectPetProfileFoodsForCartRequest(state, { petId: pet }),
      medications: selectPetProfileMedsForCartRequest(state, { petId: pet }),
      needsReview: isRebooking ? selectPetNeedsReviewByPetId(state, { petId: pet }) : false,
    }));
  },
);

export const selectHotelServiceCartPostObject = createSelector(
  [
    selectHotelBookingStartDate,
    selectHotelBookingEndDate,
    selectPetDetailsForCartRequest,
    selectStoreDetailsForCartRequest,
  ],
  (startDate, endDate, pets, storeDetails) => {
    return {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      dropOffTime: moment(startDate).format("HH:mm:ss.sss"),
      pickUpTime: moment(endDate).format("HH:mm:ss.sss"),
      storeDetails,
      pets,
    };
  },
);

export const selectCartPostObject = createSelector(
  [getCurrentCustomerKey, selectHotelServiceCartPostObject],
  (customerKey, hotelCart) => {
    return {
      customerKey,
      hotel: hotelCart,
      salon: null,
      training: {},
    };
  },
);

export const selectCartPutObject = createSelector(
  [selectServicesCartWithoutError, selectHotelCartAndDenormalizeForPut],
  (cart, hotelCart) => {
    return {
      ...cart,
      hotel: hotelCart,
    };
  },
);

export const selectItineraryIdFromPostError = createSelector(
  [selectServicesCart],
  cart => cart?.error?.itineraryId,
);
