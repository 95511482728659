import { put, takeEvery, call, all, select } from "redux-saga/effects";
import petServiceItemsActionTypes from "../actionTypes/petServiceItemsActionTypes";
import petServiceItemsActionCreators from "../actionCreators/petServiceItemsActionCreators";

import { putPetServiceItem } from "../services/systemServicesBooking/petServiceItemEndPoints";
import { initializeCartByItinerary } from "../actionCreators/ui/web/cartActionCreators";
import { dispatchItinerariesDataAndReturnItineraries } from "./itinerariesSaga";

function* onUpdatePetServiceItem({
  customerKey,
  itineraryId,
  engagementId,
  petServiceItemId,
  data,
  dispatchToCart,
}) {
  try {
    yield put(petServiceItemsActionCreators.updatePetServiceItemRequest());
    const response = yield call(putPetServiceItem, {
      customerKey,
      itineraryId,
      engagementId,
      petServiceItemId,
      data,
    });

    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });
    const itinerary = normalizedItineraries[itineraryId];

    yield put(petServiceItemsActionCreators.updatePetServiceItemSuccess({ itinerary }));
    if (dispatchToCart) {
      yield put(initializeCartByItinerary({ itineraryId }));
    }
  } catch (error) {
    yield put(petServiceItemsActionCreators.updatePetServiceItemFailure({ error }));
  }
}

function* watchUpdatePetServiceItem() {
  yield takeEvery(petServiceItemsActionTypes.UPDATE_PET_SERVICE_ITEM, onUpdatePetServiceItem);
}

export default function* petServiceItemsSaga() {
  yield all([watchUpdatePetServiceItem()]);
}
