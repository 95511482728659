import { cartAPI } from "@/core/services/cart/_axiosConfigCart";
import { bookingAPI } from "core/services/systemServicesBooking/_axiosConfigSystemServicesBooking";

/**
 * Checks the specified pet's eligibility to be booked for hotel specified pet service in specific dates and
 * returns an indication of whether or not the pet can be booked for this hotel service.
 * From System Services Booking API
 * - Get pet's eligibility for booking hotel service in specific dates
 * @param {*} { customerKey, petId, ...params }
 * @returns {*} AxiosPromise
 */
function fetchPetEligibilityForHotelForSelectedService({
  isBooking,
  customerKey,
  petIds,
  toDate,
  petServiceType,
}) {
  const url = `/v2/customers/${customerKey}/eligibility?isBooking=${isBooking}&petServiceType=${petServiceType}&toDate=${toDate}&petIds=${petIds}`;
  return bookingAPI.get(url);
}

/**
 * - Get pet's eligibility for booking hotel service in specific dates.
 * Checks the specified pet's eligibility to be booked for hotel specified pet service in specific dates and
 * returns an indication of whether the pet can be booked for this hotel service.
 *
 * NOTE: the serviceType key required for payload is the same value from the engagementType from the hotel Itinerary engagements.
 *
 * @summary [API Documentation]{@link https://curator-dev.ssg.petsmart.com/apis/psvc-prism-ui-bff-rest#tag/Prism/operation/Prism.GetAllHotelServicesBookingEligibility}
 * @memberOf APIs.WebAssociate
 * @function
 * @name postPetEligibilityForHotelForSelectedService
 * @param {Object} params
 * @param {Boolean} params.isBooking - if used during booking or post booking (e.g. check in or out)
 * @param {Number} params.customerKey - key or id that represents a customer
 * @param {Array} params.petsAndServiceTypes - Array of dictionaries e.g. [{ serviceType: "Day Camp", petId: 1000022397 }]
 * @param {String} params.toDate - String represents the to or end date of an engagement (e.g "2023-05-10T17:06:00")
 * @returns {*} AxiosPromise
 */
export const postPetEligibilityForHotelForSelectedService = ({
  isBooking,
  customerKey,
  petsAndServiceTypes = [],
  toDate,
}) => {
  const url = `/v1/hotel/customers/${customerKey}/eligibility?isBooking=${isBooking}&toDate=${toDate}`;
  return cartAPI.post(url, petsAndServiceTypes);
};

export default fetchPetEligibilityForHotelForSelectedService;
