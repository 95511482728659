import { getSearchApplied, getSearchFieldType, getSearchFieldValue } from "core/selectors/searchSalonSRCSelector";
import { getPrevSearchComponentName, getSearchComponentName } from "core/selectors/persistentSelectors";
import {
  clearSearchErrorMessage,
  clearSearchFieldActive,
  clearSearchFieldValue,
  closeSearchErrorFlyout,
  openSearchErrorFlyout,
  setPrevSearchComponentName,
  setSearchErrorMessage,
  setSearchField,
  setSearchFieldActive,
  setSearchFieldType,
} from "core/actionCreators/searchSalonActionCreator";
import { CUSTOMER_SEARCH_DEFAULT_VALUE, CUSTOMER_SEARCH_OPTIONS } from "core/constants/dropdownOptions";
import validateCustomerSearchField from "core/utils/searchUtils/validateCustomerSearchField";
import getSelectedSearchOption from "core/utils/searchUtils/getSelectedSearchOption";
import removeNANPFormat from "core/utils/searchUtils/removeNANPFormat";


/**
 * Common props for all the redux containers dependent on the main Search view.
 * @param state
 * @param ownProps
 * @return {{searchComponentName: *, searchApplied, searchFieldType, searchErrorFlyoutOpen, searchFieldActive: (boolean|*), prevSearchComponentName, searchErrorMessage, searchFieldValue}}
 */
export const searchFieldProps = (state, ownProps) => {
  const searchFieldType = getSearchFieldType(state);
  const options = {
    defaultValue: CUSTOMER_SEARCH_DEFAULT_VALUE,
    defaultSearchFieldType: "phoneNumber",
    searchOptions: CUSTOMER_SEARCH_OPTIONS,
    validateSearchField: validateCustomerSearchField,
    selectedSearchOption: getSelectedSearchOption({ searchFieldType }, CUSTOMER_SEARCH_OPTIONS)
  };

  return {

    searchFieldValue: getSearchFieldValue(state),
    searchFieldActive: state.ui.web.search.searchFieldActive,
    searchErrorMessage: state.ui.web.search.searchErrorMessage,
    searchErrorFlyoutOpen: state.ui.web.search.searchErrorFlyoutOpen,
    searchApplied: getSearchApplied(state),
    prevSearchComponentName: getPrevSearchComponentName(state),
    searchComponentName: getSearchComponentName(state, ownProps),
    searchFieldType,

    options,
  }
}

/**
 * Common dispatch actions for all the redux containers dependent on the main Search view.
 * @param dispatch
 * @return {{onFieldUpdate: onFieldUpdate, clearSearchErrorMessage: clearSearchErrorMessage, setSearchFieldType: setSearchFieldType, setSearchFieldActive: setSearchFieldActive, clearSearchFieldValue: clearSearchFieldValue, setSearchErrorMessage: setSearchErrorMessage, closeSearchErrorFlyout: closeSearchErrorFlyout, openSearchErrorFlyout: openSearchErrorFlyout, setPrevSearchComponentName: setPrevSearchComponentName, clearSearchFieldActive: clearSearchFieldActive}}
 */
export const commonDispatchActions = (dispatch) => {
  return {
    onFieldUpdate: ({ searchFieldValue,  searchFieldType}) => {
      const FormatSearchFieldValue = removeNANPFormat({ searchFieldType, searchFieldValue })
      dispatch(setSearchField({ searchFieldValue: FormatSearchFieldValue.searchFieldValue })); // when search field is typed in (each letter)
    },
    clearSearchFieldValue: () => {
      dispatch(clearSearchFieldValue());
    },
    setSearchFieldType: searchFieldType => {
      dispatch(setSearchFieldType({ searchFieldType })); // on page load
    },
    setSearchErrorMessage: message => {
      dispatch(setSearchErrorMessage(message)); // search error e.g. no characters was entered
    },
    clearSearchErrorMessage: () => {
      dispatch(clearSearchErrorMessage()); // on search (if not error)?
    },
    setSearchFieldActive: () => {
      dispatch(setSearchFieldActive()); // on click of the search field or dropdown
    },
    clearSearchFieldActive: () => {
      dispatch(clearSearchFieldActive()); // on search
    },
    openSearchErrorFlyout: () => {
      dispatch(openSearchErrorFlyout()); // search error e.g. no characters was entered
    },
    closeSearchErrorFlyout: () => {
      dispatch(closeSearchErrorFlyout()); // on search or on outside click
    },
    setPrevSearchComponentName: prevSearchComponentName => {
      dispatch(setPrevSearchComponentName({ prevSearchComponentName })); // on page load
    },
  }
}

