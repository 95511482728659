import { createSelector } from "reselect";
import { get } from "lodash/fp";
import getCustomerState from "../_customerState";

// Example of use:  getAddressesByCustomerID(state, { customerKey });
const getAddressesByCustomerID = createSelector([getCustomerState], customer =>
  get("addresses", customer)
);

export default getAddressesByCustomerID;
