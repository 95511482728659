/**
 * Hotel Service Card Actions
 */

export const GET_HOTEL_SERVICE_CARD = "GET_HOTEL_SERVICE_CARD";
export const GET_HOTEL_SERVICE_CARD_REQUEST = "GET_HOTEL_SERVICE_CARD_REQUEST";
export const GET_HOTEL_SERVICE_CARD_SUCCESS = "GET_HOTEL_SERVICE_CARD_SUCCESS";
export const GET_HOTEL_SERVICE_CARD_FAILURE = "GET_HOTEL_SERVICE_CARD_FAILURE";

export const getHotelServiceCard = ({ petId }) => ({
  type: GET_HOTEL_SERVICE_CARD,
  petId,
});

export const getHotelServiceCardRequest = () => ({
  type: GET_HOTEL_SERVICE_CARD_REQUEST,
});

export const getHotelServiceCardSuccess = () => ({
  type: GET_HOTEL_SERVICE_CARD_SUCCESS,
});

export const getHotelServiceCardFailure = ({ error }) => ({
  type: GET_HOTEL_SERVICE_CARD_FAILURE,
  error,
});
