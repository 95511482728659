/** @module objectToArray */

/**
 * A Utils function to convert set of object keys value pairs to an array
 *
 * @exports objToArray
 * @param {object} obj - Object literal to convert to an array
 * @returns {Array}
 */
export default function objToArray(obj) {
  const keyList = Object.keys(obj);
  const arr = keyList.map(key => {
    return obj[key];
  });

  return arr;
}
