import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Button, Text } from "@prism/psm-ui-components";
import { getIsTakePhotoModalOpen } from "./associateProfilePageSelectors";
import {
  setToggleTakePhotoModal,
  putUploadTrainerPhoto,
  PUT_UPLOAD_TRAINER_PHOTO,
} from "./associateProfilePageActions";
import { createLoadingSelector } from "@/core/selectors/utils";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import Webcam from "@/dux/utils/cameraUtils/webcam";
import toGenerator from "@/dux/utils/arrayUtils/toGenerator";
import CommonModal from "@/web/common/modals/commonModal";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import CameraFlipIcon from '@/assets/icons/camera-flip.svg';
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const TakeAssociatePhotoModal = ({ isHidden, componentId, isLoading, closeModal, onSubmit }) => {
  const webcamRef = useRef(null);

  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState({});
  const [screenshot, setScreenshot] = useState();

  useEffect( () => {
    discoverVideoDevices();
  }, []);

  const discoverVideoDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const devicesGenerator = toGenerator( devices.filter( ({kind}) => kind === 'videoinput') );
    setVideoDevices(devicesGenerator);
    setSelectedVideoDevice(devicesGenerator.next().value); // start with the first camera in the list
  }

  const toggleNextVideoDevice = () => {
    setSelectedVideoDevice(videoDevices.next().value);
  }

  const capturePhoto = () => {
    const screenshot = webcamRef?.current?.getScreenshot();
    setScreenshot(screenshot);
  };

  const resetPhoto = () => {
    setScreenshot(null);
  };

  const onClose = () => {
    resetPhoto();
    closeModal();
  };

  const savePhoto = () => onSubmit({ screenshot, onClose });

  const submitButtonCopy = screenshot ? "Save Photo" : "Take Photo";
  const submitButtonHandler = screenshot ? savePhoto : capturePhoto;

  return (
    <LoadingWrapper isLoading={isLoading}>
      <CommonModal isHidden={isHidden} onClose={onClose}>
        <Layout.Box id={componentId} padding="box-padding-8">
          <Layout.Stack space="stack-space-4">
            <Text size="text-size-lg" align="center" bold>
              Upload Photo
            </Text>
            <Layout.Box
              style={{
                display: "grid",
                placeItems: "center",
                height: "370px",
                width: "370px",
                margin: "auto",
              }}
            >
              {screenshot ? (
                <img src={screenshot} style={{ width: "100%", height: "auto" }} />
              ) : (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/png"
                  selectedDeviceId={selectedVideoDevice?.deviceId}
                  style={{ width: "100%", height: "auto" }}
                />
              )}
            </Layout.Box>
            <Layout.Cluster space="cluster-space-4">
              <Button variant="prism-primary-no-outline" onClick={onClose}>
                Cancel
              </Button>
              {screenshot && (
                <Button variant="prism-primary-no-outline" onClick={resetPhoto}>
                  Retake Photo
                </Button>
              )}
              <Button variant="prism-primary" disabled={isLoading} onClick={submitButtonHandler}>
                {submitButtonCopy}
              </Button>
              <img src={CameraFlipIcon} style={{ width: "25px", height: "25px" }} onClick={toggleNextVideoDevice} />
            </Layout.Cluster>
          </Layout.Stack>
        </Layout.Box>
      </CommonModal>
    </LoadingWrapper>
  );
};

export default withRouteProps(
  connect(
    (state) => ({
      componentId: "TakeAssociatePhotoModal",
      isHidden: !getIsTakePhotoModalOpen(state),
      storeNumber: getStoreNumber(state),
      isLoading: createLoadingSelector([PUT_UPLOAD_TRAINER_PHOTO])(state),
    }),
    (dispatch) => ({
      closeModal: () => {
        dispatch(setToggleTakePhotoModal());
      },
      dispatchPutUploadTrainerPhoto: ({ storeNumber, associateId, file, onComplete }) => {
        dispatch(putUploadTrainerPhoto({ storeNumber, associateId, file, onComplete }));
      },
    }),
    (stateProps, dispatchProps, { router }) => ({
      ...stateProps,
      ...dispatchProps,

      onSubmit: ({ screenshot, onClose }) => {
        const { storeNumber } = stateProps;
        const { dispatchPutUploadTrainerPhoto } = dispatchProps;
        const associateId = router.params.associateId;

        dispatchPutUploadTrainerPhoto({
          storeNumber,
          associateId,
          file: screenshot,
          onComplete: () => {
            onClose();
          },
        });
      },
    }),
  )(TakeAssociatePhotoModal),
);
