import { connect } from "react-redux";
import { get, isEqual } from "lodash/fp";
import { medicationTypes } from "web/newMedication/newMedicationConstants";
import PetProfileFeedingFormComponent from "../common/PetProfileFeedingFormComponent";
import {
  getFoodIds,
  getFoodById,
  getPetFoodById,
  getFoodIsDiscontinuedById,
} from "../food/foodsSelector";
import { getCurrentPet } from "../../core/selectors/persistentSelectors";
import { getFoodIsShowingDetailsForType } from "../foodList/foodListSelectors";
import { updatePetFood } from "../food/actions/createUpdateDeleteFoodActions";
import { formatFood } from "../food/foodUtils";
import isRequiredPetFoodFieldMissing from "../newFood/utils/isRequiredPetFoodFieldMissing";
import { selectFoodList } from "@/dux/foodOptionsList/foodOptionsListSelectors";
import getIsHotelWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { isFromBooking } from "../../core/utils/validationUtils/isFromValidation";
import { history } from "@/dux/utils/browser/browserHistory";

const mapStateToProps = (state, ownProps) => {
  const { foodId, foodIndex } = ownProps;
  const petId = getCurrentPet(state);
  const isShowingDetails = getFoodIsShowingDetailsForType(state, {
    foodId,
    petId,
    locationType: medicationTypes.PROFILE,
  });
  return {
    petId,
    foodId,
    foodIndex,
    isShowingDetails,
    isHidden: isFromBooking(get(["location", "pathname"], history)) || !isShowingDetails,
    foodIds: getFoodIds(state, { petId }),
    food: getFoodById(state, { foodId, petId }),
    foodList: selectFoodList(state),
    petFood: getPetFoodById(state, { petId, foodId }),
    canUpdateFromFeatureFlag: !getIsHotelWorkflowFeatureFlagHidden(state),
    isDisabled: getFoodIsDiscontinuedById(state, { petId, foodId }),
  };
};

const mapDispatchToProps = dispatch => ({
  updatePetFoods: (food, petId, isFoodDetailsShowing) =>
    dispatch(updatePetFood({ food, petId, isFoodDetailsShowing })),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSubmit: () => {
    const { food, petFood, petId, canUpdateFromFeatureFlag, foodList, isDisabled } = propsFromState;
    if (isDisabled) return;

    const foodToSend = formatFood(food);
    const formattedPetFood = formatFood(petFood);

    // If food in state has changed and nothing required is missing, update.
    if (
      canUpdateFromFeatureFlag &&
      !isEqual(formattedPetFood, foodToSend) &&
      !isRequiredPetFoodFieldMissing({ food: foodToSend, foodList })
    ) {
      propsFromDispatch.updatePetFoods(foodToSend, petId, food.isFoodDetailsShowing);
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(PetProfileFeedingFormComponent);
