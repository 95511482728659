import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { compose } from "redux";
import AppHeader from "./AppHeader";
import { headerConfigConstants } from "@/core/constants/headerConfigConstants";
import { getJobRole } from "@/core/selectors/persistentSelectors";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";

// TODO: move to AppHeader.js so all containers are co-located with the component.

/**
 * Redux Connect function for the salon & hotel booking app header
 * @summary Located on the booking pages
 * @memberOf Views.Booking
 * @function
 * @name  BookingHeader
 * @param {Object} props
 * @param {String} props.backgroundImage - image url
 * @returns {JSX.Element|null}
 * @example <BookingHeader backgroundImage="" />
 */
export const BookingHeader = compose(
  withRouteProps,
  connect((state, ownProps) => {
    const { backgroundImage, router } = ownProps;
    const isHotel = getSystemBookingFlow(state) === systemName.HOTEL;
    const isHotelRebooking = isHotel && !!router?.params?.itineraryId;

    return {
      title: isHotelRebooking ? "Rebook appointment" : "Book appointment",
      config: headerConfigConstants.APPOINTMENT,
      jobRole: getJobRole(state, ownProps),
      backgroundImage,
    };
  }),
)(AppHeader);
