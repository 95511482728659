import moment from "moment";
import { DATE_TIME_FORMAT } from "../../constants";

/** @module dashboardHourOptions */

/**
 * Attempts to take the startDate, and endDate, add an hour via the stagger value then
 * formats Start Date and time
 *
 * @export getDashboardHourOptions
 * @param {Object} obj - Destructuring parameters
 * @param {(object|string)} obj.startDate - Moments object or a string for the start date
 * @param {(object|string)} obj.endDate - Moments object or a string for the end date
 * @param {number} obj.stagger
 * @returns
 */
export default function getDashboardHourOptions({ startDate, endDate, stagger }) {
  let currentMoment = moment(startDate).isMoment ? startDate : moment(startDate);
  const endMoment = moment(endDate).isMoment ? startDate : moment(endDate);
  const options = [];

  while (currentMoment < endMoment) {
    const formattedMoment = currentMoment.format(DATE_TIME_FORMAT);

    options.push({
      startDateTime: formattedMoment,
      available: true
    });

    currentMoment = currentMoment.add(stagger, "hours");
  }

  return options;
}
