import { createSelector } from "reselect";

/**
 *  Selector to get hotel pricing summaries
 * @memberOf Selectors.hotelPricingSummary
 * @function
 * @name selectHotelPricingSummary
 * @param {Object} state - redux state
 * @returns {Object}
 * @example selectHotelPricingSummary(state)
 */
export const selectHotelPricingSummary = state => state?.hotelPricingSummary ?? {};

/**
 * Selector to get pricing summary for given pet
 * @memberOf Selectors.hotelPricingSummary
 * @function
 * @name selectPetPricingSummary
 * @param {string} petId
 * @returns {(state: Object) => Object} selector
 * @example selectPetPricingSummary(petId)(state)
 */
export const selectPetPricingSummary = petId =>
  createSelector(
    [selectHotelPricingSummary],
    hotelPricingSummary => hotelPricingSummary[petId] ?? {},
  );

/**
 * Selector to get primary service pricing for a given pet
 * @memberOf Selectors.hotelPricingSummary
 * @function
 * @name selectPetPrimaryServicePricing
 * @param {string} petId
 * @returns {(state: Object) => Object} selector
 * @example selectPetPrimaryServicePricing(petId)(state)
 */
export const selectPetPrimaryServicePricing = petId =>
  createSelector(
    [selectPetPricingSummary(petId)],
    petPricing => petPricing?.primaryServicePricing ?? {},
  );

/**
 * Selector to get addon pricing for a given pet
 * @memberOf Selectors.hotelPricingSummary
 * @function
 * @name selectPetAddonPricing
 * @param {string} petId
 * @returns {(state: Object) => Object} selector
 * @example selectPetAddonPricing(petId)(state)
 */
export const selectPetAddonPricing = petId =>
  createSelector([selectPetPricingSummary(petId)], petPricing => petPricing?.addOnPricing ?? {});

/**
 * Selector to get food pricing for a given pet
 * @memberOf Selectors.hotelPricingSummary
 * @function
 * @name selectPetFoodPricing
 * @param {string} petId
 * @returns {(state: Object) => Object} selector
 * @example selectPetFoodPricing(petId)(state)
 */
export const selectPetFoodPricing = petId =>
  createSelector([selectPetPricingSummary(petId)], petPricing => petPricing?.feedingPricing ?? {});
