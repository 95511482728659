import petsActionTypes from "../actionTypes/petsActionTypes";

export const loadPet = ({ petId, customerKey }) => ({
  type: petsActionTypes.LOAD_PET,
  petId,
  customerKey,
});

export const loadPetRequest = () => ({
  type: petsActionTypes.LOAD_PET_REQUEST,
});

export const loadPetSuccess = ({ pet }) => ({
  type: petsActionTypes.LOAD_PET_SUCCESS,
  payload: pet,
});

export const loadPetFailure = ({ error }) => ({
  type: petsActionTypes.LOAD_PET_FAILURE,
  error,
});

export const loadPetsByCustomer = ({ customerKey }) => ({
  type: petsActionTypes.LOAD_PETS_BY_CUSTOMER,
  customerKey,
});

export const loadPetsByCustomerRequest = () => ({
  type: petsActionTypes.LOAD_PETS_BY_CUSTOMER_REQUEST,
});

export const loadPetsByCustomerFailure = ({ error }) => ({
  type: petsActionTypes.LOAD_PETS_BY_CUSTOMER_FAILURE,
});

export const loadPetsByCustomerSuccess = ({ pets }) => ({
  type: petsActionTypes.LOAD_PETS_BY_CUSTOMER_SUCCESS,
  payload: pets,
});

export const loadPets = ({ petIds }) => ({
  type: petsActionTypes.LOAD_PETS,
  petIds,
});

export const loadPetsRequest = () => ({
  type: petsActionTypes.LOAD_PETS_REQUEST,
});

export const loadPetsSuccess = ({ pets }) => ({
  type: petsActionTypes.LOAD_PETS_SUCCESS,
  payload: pets,
});

export const loadPetsFailure = ({ error }) => ({
  type: petsActionTypes.LOAD_PETS_FAILURE,
  error,
});

export const updatePet = ({ petId, customerKey, data }) => ({
  type: petsActionTypes.UPDATE_PET,
  petId,
  customerKey,
  data,
});

export const updatePetRequest = () => ({
  type: petsActionTypes.UPDATE_PET_REQUEST,
});

export const updatePetSuccess = ({ pet }) => ({
  type: petsActionTypes.UPDATE_PET_SUCCESS,
  payload: pet,
});

export const updatePetFailure = ({ error }) => ({
  type: petsActionTypes.UPDATE_PET_FAILURE,
  error,
});

export const createPet = ({ customerKey, data, onDone }) => ({
  type: petsActionTypes.CREATE_PET,
  customerKey,
  data,
  onDone,
});

export const createPetRequest = () => ({
  type: petsActionTypes.CREATE_PET_REQUEST,
});

export const createPetSuccess = ({ pet }) => ({
  type: petsActionTypes.CREATE_PET_SUCCESS,
  payload: pet,
});

export const createPetFailure = ({ error }) => ({
  type: petsActionTypes.CREATE_PET_FAILURE,
  error,
});

export const setPetInactive = ({ petId, customerKey }) => ({
  type: petsActionTypes.SET_PET_INACTIVE,
  petId,
  customerKey,
});

export const setPetInactiveRequest = () => ({
  type: petsActionTypes.SET_PET_INACTIVE_REQUEST,
});

export const setPetInactiveSuccess = () => ({
  type: petsActionTypes.SET_PET_INACTIVE_SUCCESS,
});

export const setPetInactiveFailure = ({ error }) => ({
  type: petsActionTypes.SET_PET_INACTIVE_FAILURE,
  error,
});

export const setPetActive = ({ petId, customerKey }) => ({
  type: petsActionTypes.SET_PET_ACTIVE,
  petId,
  customerKey,
});

export const setPetActiveRequest = () => ({
  type: petsActionTypes.SET_PET_ACTIVE_REQUEST,
});

export const setPetActiveSuccess = () => ({
  type: petsActionTypes.SET_PET_ACTIVE_SUCCESS,
});

export const setPetActiveFailure = ({ error }) => ({
  type: petsActionTypes.SET_PET_ACTIVE_FAILURE,
  error,
});

export default {
  loadPetsByCustomer,
  loadPetsByCustomerRequest,
  loadPetsByCustomerFailure,
  loadPetsByCustomerSuccess,
  loadPetsRequest,
  loadPetsSuccess,
  loadPetsFailure,
  updatePet,
  updatePetRequest,
  updatePetSuccess,
  updatePetFailure,
  loadPet,
  loadPetRequest,
  loadPetSuccess,
  loadPetFailure,
  createPet,
  createPetRequest,
  createPetSuccess,
  createPetFailure,
  setPetInactive,
  setPetInactiveRequest,
  setPetInactiveSuccess,
  setPetInactiveFailure,
  setPetActive,
  setPetActiveRequest,
  setPetActiveSuccess,
  setPetActiveFailure,
};
