import addOnsActionTypes from "../../actionTypes/addOnsActionTypes";

export default function addonReducer(state = { showAddonFlyout: false }, action) {
  switch (action.type) {
    case addOnsActionTypes.OPEN_ADDONS_FLYOUT:
      return {
        ...state,
        showAddonFlyout: true
      };
    case addOnsActionTypes.CLOSE_ADDONS_FLYOUT:
      return {
        ...state,
        showAddonFlyout: false
      };
    default:
      return state;
  }
}
