// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pplt-layout__stack {
    --setStackSpace: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .pplt-layout__stack > * {
    /* ↓ Any extant vertical margins are removed */
    margin-top: 0;
    margin-bottom: 0;
  }
  .pplt-layout__stack > * + * {
    /* ↓ Top margin is only applied to successive elements */
    margin-top: var(--setStackSpace);
  }`, "",{"version":3,"sources":["webpack://./src/layout/stack/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,2BAA2B;EAC7B;EACA;IACE,8CAA8C;IAC9C,aAAa;IACb,gBAAgB;EAClB;EACA;IACE,wDAAwD;IACxD,gCAAgC;EAClC","sourcesContent":[".pplt-layout__stack {\n    --setStackSpace: 0;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n  }\n  .pplt-layout__stack > * {\n    /* ↓ Any extant vertical margins are removed */\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n  .pplt-layout__stack > * + * {\n    /* ↓ Top margin is only applied to successive elements */\n    margin-top: var(--setStackSpace);\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
