import axios from "axios";
import {
  setRequestHeaders,
  handleAuthorizationRejection,
  setResponseRefreshToken,
} from "../../utils/authHelper";

/**
 * Axios Create configuration for the Associate Web API (/web/associate).
 *
 * @summary /web/associate.
 * @memberOf APIs.AssociateWeb
 * @function
 * @name  associateWebAPI
 * @type {axios.AxiosInstance}
 *
 * @example
 * return associateWebAPI.post(url, params)
 * return associateWebAPI.get(url, params)
 * return associateWebAPI.put(url, params)
 */
export const associateWebAPI = axios.create({
  baseURL: `${window.env.BASE_URL}${window.env.ASSOCIATE_WEB_URI}`,
});

/**
 * Intercept the `x-refresh-session-token` value
 * - Note: This can be disabled by using the ENV flag below
 */
if (!window.env.DISABLE_API_AUTH_IN_DEV) {
  associateWebAPI.interceptors.request.use(setRequestHeaders, handleAuthorizationRejection);
  associateWebAPI.interceptors.response.use(setResponseRefreshToken, handleAuthorizationRejection);
}
