import { createSelector } from "reselect";

export const getManageTrainingClassPageState = (state) => state.manageTrainingClassPage;
export const getManageTrainingClassPagePersistentState = (state) =>
  state.persistent.manageTrainingClassPage;

export const getTrainingClassCustomerKey = createSelector(
  [getManageTrainingClassPagePersistentState],
  (manageTrainingClassPagePersistentState) => {
    return manageTrainingClassPagePersistentState.customerKey;
  },
);

export const getTrainingClassItineraryId = createSelector(
  [getManageTrainingClassPagePersistentState],
  (manageTrainingClassPagePersistentState) => {
    return manageTrainingClassPagePersistentState.itineraryId;
  },
);

export const getIsCompleteTrainingAppointmentModalOpen = createSelector(
  [getManageTrainingClassPageState],
  (manageTrainingClassPage) => {
    return manageTrainingClassPage.isCompleteTrainingAppointmentModalOpen;
  },
);

export const getIsCancelTrainingAppointmentModalOpen = createSelector(
  [getManageTrainingClassPageState],
  (manageTrainingClassPage) => {
    return manageTrainingClassPage.isCancelTrainingAppointmentModalOpen;
  },
);

export const getIsRescheduleTrainingAppointmentModalOpen = createSelector(
  [getManageTrainingClassPageState],
  (manageTrainingClassPage) => {
    return manageTrainingClassPage.isRescheduleTrainingAppointmentModalOpen;
  },
);

export const getRescheduleAttendeeId = createSelector(
  [getManageTrainingClassPageState],
  (manageTrainingClassPage) => {
    return manageTrainingClassPage.attendeeId;
  },
);

export const getCachedRescheduleTrainingAvailabilityWeeks = createSelector(
  [getManageTrainingClassPageState],
  (manageTrainingClassPage) => manageTrainingClassPage.cachedRescheduleTrainingAvailabilityWeeks,
);

export const getIsCancelTrainingAppointmentFlashMessageOpen = (state) =>
  state.manageTrainingClassPage.isCancelTrainingAppointmentFlashMessageOpen;

export const getIsRescheduleTrainingAppointmentFlashMessageOpen = (state) =>
  state.manageTrainingClassPage.isRescheduleTrainingAppointmentFlashMessageOpen;

export const getIsResendMeetingLinkFlashMessageOpen = (state) =>
  state.manageTrainingClassPage.isResendMeetingLinkFlashMessageOpen;

export const getRescheduleClassSessionId = (state) =>
  state.manageTrainingClassPage.rescheduleClassSessionId;
