import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

// Components
import { LayoutGrid } from "@/layout/grid/Grid";
import { LayoutStack } from "@/layout/stack/Stack";
import { Box, Checkbox, TextPassage } from "@petsmart-ui/sparky";
import { HotelPrimaryServiceModalRoomSelection } from "@/dux/roomSelection/RoomSelection";

// Selectors
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import {
  getHotelEngagementByEngagementId,
  selectPetHotelEngagementServices,
  selectIsUpdatingHostService,
} from "@/dux/hotelEngagements/hotelEngagementSelectors";
import { selectHotelPetServiceById } from "@/web/pendingAppointment/selectors/pendingAppointmentSelectors";
import { selectIsRoomValid } from "@/dux/hotelRoomAvailability/hotelRoomAvailabilitySelectors";

// Utils
import { formatMoney } from "../_utils/moneyUtil";

/**
 * React view component that is used to update a primary service in check in flow
 * @summary Used in ChangePrimaryServiceComponent
 * @memberOf Views.Pet
 * @function
 * @name ServiceDateOptions
 * @param {Object} props
 * @param {String} props.componentId
 * @param {Boolean} props.isHidden
 * @param {Array} props.engagementServices
 * @param {Function} props.onClick
 * @param {Function} props.onRoomChange
 * @returns {JSX.Element || null}
 */
export const PrimaryServiceDayOptions = ({
  componentId,
  isHidden,
  engagementServices,
  isUpdatingHostService,
  onClick = () => {},
  onRoomChange = () => {},
}) => {
  if (isHidden) return null;

  return (
    <LayoutStack id={componentId} style={{ overflowY: "auto", maxHeight: "40vh" }}>
      {engagementServices?.map(
        ({
          engagementId,
          name,
          price,
          formattedDate,
          roomNumber,
          roomTypeBucketId,
          isRoomValid,
          isDayChecked,
        }) => (
          <Box key={engagementId}>
            <LayoutGrid style={{ gridTemplateColumns: "1fr 7fr 3fr" }}>
              <Checkbox
                onChange={checked => onClick(engagementId, checked)}
                checked={isDayChecked}
              />

              <TextPassage style={{ minWidth: "210px" }}>
                <LayoutStack>
                  <span style={{ fontWeight: "bold" }}>{formattedDate}</span>
                  <span>{name}</span>
                </LayoutStack>
              </TextPassage>

              <TextPassage>
                <LayoutStack>
                  <span style={{ textAlign: "right", marginTop: "auto" }}>{price}</span>
                  <span style={{ textAlign: "right" }}>/per night</span>
                </LayoutStack>
              </TextPassage>
            </LayoutGrid>

            <HotelPrimaryServiceModalRoomSelection
              engagementId={engagementId}
              roomTypeBucketId={roomTypeBucketId}
              roomNumber={roomNumber}
              handleOnChange={room => onRoomChange(engagementId, room)}
              isRoomValid={isRoomValid}
              isUpdatingHostService={isUpdatingHostService}
            />
          </Box>
        ),
      )}
    </LayoutStack>
  );
};

/**
 * Helper to update service object with pending changes to display in UI
 * @function
 * @name ServiceDateOptions
 * @param {Object} obj
 * @param {Function} obj.isChecked
 * @param {Object} props.pendingService
 * @param {Object} props.pendingRooms
 * @param {Boolean} props.isHostPet
 * @param {Function} props.checkIsRoomValid
 * @returns {Function}
 */
export const updateServiceWithPending = ({
  isChecked,
  pendingService,
  pendingRooms,
  checkIsRoomValid = () => {},
}) => service => {
  const { engagementId, name, price, roomTypeBucketId, roomNumber, currencyCode } = service;
  const isDayChecked = isChecked(engagementId);
  const usePending = isDayChecked && !!pendingService;
  const isBucketIdUpdated =
    usePending && pendingService?.roomTypeBucketId?.toString() !== roomTypeBucketId?.toString();
  const isRoomUpdated = isBucketIdUpdated || !!pendingRooms[engagementId];
  const isRoomValid = isRoomUpdated
    ? checkIsRoomValid({
        roomTypeBucketId: isDayChecked ? pendingService?.roomTypeBucketId : roomTypeBucketId,
        roomNumber: pendingRooms[engagementId] ?? roomNumber,
        engagementId,
      })
    : true;

  return {
    ...service,
    name: usePending ? pendingService?.associateDisplayName : name,
    price: formatMoney(usePending ? pendingService?.price : price),
    roomTypeBucketId: usePending ? pendingService?.roomTypeBucketId : roomTypeBucketId,
    roomNumber: isBucketIdUpdated
      ? pendingRooms[engagementId]
      : pendingRooms[engagementId] ?? roomNumber,
    isRoomValid,
    isDayChecked,
  };
};

/**
 * Redux Connect function for the primary service options in the modal
 * @see {@link Views.Pet.PrimaryServiceDayOptions}
 * @summary Located on the hotel check-in & check-out pages
 * @memberOf Views.Pet
 * @function
 * @name HotelCheckInOutServiceDayOptions
 * @param {Object} props
 * @param {String} props.selectedService petServiceId of selected service
 * @param {Function} props.isChecked
 * @param {Object} props.pendingRooms
 * @returns {JSX.Element|null}
 * @example <HotelCheckInOutServiceDayOptions  />
 */
export const HotelCheckInOutServiceDayOptions = connect(
  (state, { selectedService, isChecked, pendingRooms }) => {
    const petId = getCurrentPet(state);
    const engagementServices = selectPetHotelEngagementServices(state, { petId });
    const pendingService = selectHotelPetServiceById({ selectedService, petId })(state);

    const checkIsRoomValid = ({ roomTypeBucketId, roomNumber, engagementId }) => {
      const engagement = getHotelEngagementByEngagementId(state, { engagementId });
      return selectIsRoomValid({
        roomTypeBucketId,
        roomNumber,
        startDateTime: engagement?.startDatetime,
        endDateTime: engagement?.endDatetime,
      })(state);
    };

    return {
      componentId: "HotelCheckInOutServiceDateOptions",
      isHidden: isEmpty(engagementServices),
      engagementServices: engagementServices?.map(
        updateServiceWithPending({
          isChecked,
          pendingService,
          pendingRooms,
          checkIsRoomValid,
        }),
      ),
      isUpdatingHostService: selectIsUpdatingHostService({ selectedService, petId })(state),
    };
  },
)(PrimaryServiceDayOptions);
