import { getEngagementFromArrayById } from "./hotelEngagementUtils";

/**
 * Helper to combine engagementIds from applyToEngagements array & pendingRooms object
 * @memberOf Utils.Engagement
 * @function
 * @name getUpdatedEngagementIds
 * @param {Number[]} arg.applyToEngagements
 * @param {{ [EngagementId: Number]: String }} arg.pendingRooms
 * @returns {Number[]} - array of engagementIds
 * @example getUpdatedEngagementIds({ applyToEngagements, pendingRooms })
 */
export const getUpdatedEngagementIds = ({ applyToEngagements = [], pendingRooms = {} } = {}) =>
  [...applyToEngagements, ...Object.keys(pendingRooms)].reduce(
    (deduped, id) => (deduped.indexOf(Number(id)) >= 0 ? deduped : [...deduped, Number(id)]),
    [],
  );

/**
 * Util to construct a payload for the primary service patch with primary service & patch updates for a given pet
 * @memberOf Utils.Engagement
 * @function
 * @name buildEngagementsForPrimaryServicePatch
 * @param {Object} args
 * @param {string} args.petEngagements - array of engagements from itinerary for a pet
 * @param {string} args.petServiceId - pending new petServiceId
 * @param {string} args.hostPetId - pending new hostPetId
 * @param {string} args.roomTypeBucketId - pending new roomTypeBucketId
 * @param {string} args.roomType - pending new roomType
 * @param {Number[]} args.applyToEngagements - array of engagementId's to update primary service for
 * @param {{ [engagementId: Number]: roomNumber: String }} args.pendingRooms - pending new rooms
 * @example buildEngagementsForPrimaryServicePatch({
 *   petEngagements, petServiceId, hostPetId, roomTypeBucketId, roomType, applyToEngagements, pendingRooms,
 * })
 */
export const buildEngagementsForPrimaryServicePatch = ({
  petEngagements = [],
  petServiceId = "",
  hostPetId = "",
  roomTypeBucketId = "",
  roomType = "",
  applyToEngagements = [],
  pendingRooms = {},
}) => {
  const engagementsToUpdate = getUpdatedEngagementIds({ applyToEngagements, pendingRooms });

  return engagementsToUpdate.map(engagementId => {
    const original = getEngagementFromArrayById(petEngagements, engagementId);
    const originalService = original?.petService;
    const originalRoom = original?.metadata?.room;

    const isUpdatingService = applyToEngagements.indexOf(engagementId) >= 0;
    const isUpdatingRoomType =
      isUpdatingService &&
      !!roomTypeBucketId &&
      roomTypeBucketId?.toString() !== originalRoom?.roomTypeBucketId?.toString();
    const isUpdatingRoom = !!pendingRooms[engagementId];
    const keepOriginalRoom = !isUpdatingRoom && !isUpdatingRoomType;
    const pendingRoomNumber = pendingRooms[engagementId] ?? null;

    return {
      engagementId,
      ...(isUpdatingService && { hostPetId }),
      petService: {
        petServiceId: isUpdatingService ? petServiceId : originalService?.petServiceId,
        groupingId: isUpdatingService ? null : originalService?.groupingId,
        petServiceProductId: isUpdatingService ? null : originalService?.petServiceProductId,
      },
      metadata: {
        room: {
          roomTypeBucketId: isUpdatingRoomType ? roomTypeBucketId : originalRoom?.roomTypeBucketId,
          roomType: isUpdatingRoomType ? roomType : originalRoom?.roomType,
          roomNumber: keepOriginalRoom ? originalRoom?.roomNumber : pendingRoomNumber,
          // If we need to clear the room number then we don't want to set isUnassigned to true, SF has
          // asked us to send isUnassigned: false so that they don't delete the Assigned Resource
          // Note: isUnassigned will come back as true after making a call with this payload
          isUnassigned: false,
        },
      },
    };
  });
};
