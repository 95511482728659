import React from "react";
import { Layout } from "@prism/psm-ui-components";
import SalonServicesGridButtonContainer from "dux/salonServicesGridButton/SalonServicesGridButtonContainer";

export default function CommonServicesButtonGrid(props) {
  const { services, isHidden, componentID, testid, systemBookingFlow, onServiceClick } = props;

  if (isHidden) {
    return null;
  }

  return (
    <Layout.Cluster testid={testid} id={componentID} space="cluster-space-4">
      {services.map(({ petServiceId, imageUrl, name, price }, index) => {
        return (
          <SalonServicesGridButtonContainer
            key={`${petServiceId}-${index}`}
            imageUrl={imageUrl}
            name={name}
            price={price}
            petServiceId={petServiceId}
            onServiceClick={onServiceClick}
            {...props}
          />
        );
      })}
    </Layout.Cluster>
  );
}
