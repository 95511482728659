import {
  IAssociateSearch,
  IBookingSearch,
  ICustomerSearch,
  ISRCSearch,
  AssociateSearchHotel,
  AssociateSearchSalon, AssociateSearchTraining,
  BookingSearchHotel,
  BookingSearchSalon,
  BookingSearchTraining,
  CustomerSearchHotel,
  CustomerSearchSalon,
  CustomerSearchTraining,
  SRCSearchHotel,
  SRCSearchSalon,
  SRCSearchTraining,
} from "dux/searchFieldMain/searchFactory/SearchFactoryProducts";

/**
 * The ISearchProducts interface declares a set of methods that return different search field products
 * These products are considered a family (e.g. a family of search fields) and are
 * related by a high-level theme or concept e.g. as set of search fields that belong to the hotel family.
 *
 * This Interface acts as the Abstract Factory
 */
export interface ISearchProducts {
  createSrcSearch(): ISRCSearch;

  createCustomerSearch(): ICustomerSearch;

  createBookingSearch(): IBookingSearch;

  createAssociateSearch(): IAssociateSearch;
}

// CONCRETE FACTORIES-------------------------------------------------------------------------------
/**
 * Provides the requested Salon Search Field.
 * Acts as a concrete Factory that corresponds to the Salon Search field product variant
 */
export class SearchFactorySalon implements ISearchProducts {
  public createSrcSearch(): ISRCSearch {
    return new SRCSearchSalon();
  }

  public createCustomerSearch(): ICustomerSearch {
    return new CustomerSearchSalon();
  }

  public createBookingSearch(): IBookingSearch {
    return new BookingSearchSalon();
  }

  public createAssociateSearch(): IAssociateSearch {
    return new AssociateSearchSalon();
  }
}

/**
 * Provides the requested Hotel Search Field.
 * Acts as a concrete Factory that corresponds to the Hotel Search field product variant
 */
export class SearchFactoryHotel implements ISearchProducts {
  public createSrcSearch(): ISRCSearch {
    return new SRCSearchHotel();
  }

  public createCustomerSearch(): ICustomerSearch {
    return new CustomerSearchHotel();
  }

  public createBookingSearch(): IBookingSearch {
    return new BookingSearchHotel();
  }

  public createAssociateSearch(): IAssociateSearch {
    return new AssociateSearchHotel();
  }
}

/**
 * Provides the requested Training Search Field.
 * Acts as a concrete Factory that corresponds to the Training Search field product variant
 */
export class SearchFactoryTraining implements ISearchProducts {
  public createSrcSearch(): ISRCSearch {
    return new SRCSearchTraining()
  }

  public createCustomerSearch(): ICustomerSearch {
    return new CustomerSearchTraining();
  }

  public createBookingSearch(): IBookingSearch {
    return new BookingSearchTraining();
  }

  public createAssociateSearch(): IAssociateSearch {
    return new AssociateSearchTraining();
  }
}


/**
 * Provides a family of search fields based on type of factory passed in.
 * @example
 * const salonSearch = buildSearchFieldProducts(new SearchFactorySalon());
 * const salonSRCSearch = salonSearch.srcSearch.getSearchField(state)
 * const salonCustomerSearch = salonSearch.customerSearch.getSearchField(state)
 *
 * const hotelSearch = buildSearchFieldProducts(new SearchFactoryHotel());
 * const hotelSrcSearch hotelSearch.srcSearch.getSearchField(state)
 * const hotelAssociateSearch = hotelSearch.associateSearch.getSearchField(state)
 * @param factory - The concrete factory for each family of search field products.
 */
export const buildSearchFieldProducts = (factory: ISearchProducts) => {
  return {
    srcSearch: factory.createSrcSearch(),
    customerSearch: factory.createCustomerSearch(),
    bookingSearch : factory.createBookingSearch(),
    associateSearch : factory.createAssociateSearch(),
  };
};
