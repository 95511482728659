import modalInitState from "./_modalInitState";
import authActionTypes from "../../actionTypes/authActionTypes";
import getUpdatedModalState from "./helpers/getUpdatedModalState";

const initialState = modalInitState();

export default function authModalsReducer(state = initialState, action) {
  return getUpdatedModalState(
    state,
    initialState,
    authActionTypes.SHOW_AUTH_MODAL,
    authActionTypes.HIDE_AUTH_MODAL,
    action
  );
}
