import { connect } from "react-redux";
import {
  getPetByPetServiceItemId,
  getPetServiceItem,
  getCustomerByPetServiceItemId,
} from "core/selectors/entitiesSelector";
import { getBreeds } from "core/selectors/enumsSelectors";
import { rebookClick } from "core/actionCreators/petParentProfileActionCreator";
import PetInfoComponent from "./petInfoComponent";

const mapStateToProps = (state, ownProps) => {
  const appointment = getPetServiceItem(state, ownProps);
  return {
    componentId: `petInfo__${appointment.pet}`,
    customer: getCustomerByPetServiceItemId(state, ownProps) || {},
    pet: getPetByPetServiceItemId(state, ownProps) || {},
    breeds: getBreeds(state),
    appointment,
  };
};

const mapDispatchToProps = dispatch => ({
  rebookClick: ({ appointment }) => dispatch(rebookClick({ appointment })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PetInfoComponent);
