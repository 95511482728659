import { connect } from "react-redux";
import MenuButton from "../common/MenuButton";
import { history } from "@/dux/utils/browser/browserHistory";
import getHotelWorkflowFeatureFlag from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getHotelWorkflowFeatureFlag";
import { setSystemBookingFlowType } from "../setSystemType/actions/setSystemTypeActions";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import { routePaths } from "../../core/constants/routePaths";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";

const mapStateToProps = (state) => {
  const featureFlag = getHotelWorkflowFeatureFlag(state);
  const storeNumber = getStoreNumber(state);
  return {
    className: "ProfileButton__menuButton--hotelDashboard",
    componentID: "ProfileButton-hotelDashboard",
    title: "Hotel Dashboard",
    // We want to hide the hotel dashboard link in the SRC flow if there's no store number
    isHidden: !storeNumber || featureFlag.isHidden,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClick: () => {
    dispatch(setSystemBookingFlowType({ systemBookingFlow: systemName.HOTEL }));
    history.push(routePaths.DASHBOARD);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);
