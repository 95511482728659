/**
 * Action type that adds pagination for overbooking exceptions into the redux state
 * @type {string}
 */
export const SET_OVERBOOKING_EXCEPTION_PAGINATION = "SET_OVERBOOKING_EXCEPTION_PAGINATION";

/**
 * Action that adds pagination for overbooking exceptions into the redux state
 * @param {object} pagination - Destructed Props
 * @param {number} pagination.count - Number of items currently displayed
 * @param {number} pagination.page - Current Page
 * @param {number} pagination.total - Total number items available
 * @param {number} pagination.size - Total size of items that can currently be displayed
 * @returns {{total: number, size: number, count: number, page: number, type: string}}
 */
export const setOverbookingExceptionsPagination = ({ count, page, total, size }) => ({
  type: SET_OVERBOOKING_EXCEPTION_PAGINATION,
  count,
  page,
  total,
  size,
});
