import React from "react";
import styled from "styled-components";

import { color } from "web/common/styles/theme";
import Section from "../../common/Section";
import PetForm from "../../petParentProfile/pets/petForm/PetFormContainer";
import { PetVaccinations } from "@/dux/vaccinations/PetVaccinations";
import PetFavorabilitySalonContainer from "../../../dux/petFavorability/PetFavorabilitySalonContainer";
import PetProfileAvailableBundlesListContainer from "dux/bgm/availableBundlesByPet/availableBundlesList/PetProfileAvailableBundlesListContainer";
import { SalonGroomingNotesV2 } from "dux/notes/Notes";

const PetFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2.8125rem;
  border-right: 1px solid ${color.borderGrey};
`;

export default function PetProfileDetails(props) {
  const { customerKey, selectedPet, isHidden, componentID } = props;

  if (!isHidden) {
    return (
      <PetFormContainer componentID={componentID}>
        <Section paddingTop="0">
          <PetForm customerKey={customerKey} petId={selectedPet} isBooking />
        </Section>

        {/* This component has a section within it, so it odes not have to explicitly be wrapped here by a Section */}
        <PetProfileAvailableBundlesListContainer />

        <PetVaccinations />

        <Section title="Favorability" topLine petId={selectedPet} customerKey={customerKey}>
          <PetFavorabilitySalonContainer topLine customerKey={customerKey} petId={selectedPet} />
        </Section>

        <Section topLine petId={selectedPet} customerKey={customerKey}>
          <SalonGroomingNotesV2 />
        </Section>
      </PetFormContainer>
    );
  }

  return null;
}
