// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-layout__sidebar {
  --setSidebarWidth: ;
  --setSidebarSpace: ;
  --setSidebarContentMinWidth: ;
}

.psm-layout__sidebar > * {
  display: flex;
  flex-wrap: wrap;
  gap: var(--setSidebarSpace);
}

.psm-layout__sidebar > * > * {
  margin: calc(var(--setSidebarSpace) / 2);
  flex-basis: var(--setSidebarWidth);
  flex-grow: 1;
}

.psm-layout__sidebar > * > :last-child {
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(var(--setSidebarContentMinWidth) - var(--setSidebarSpace));
}

.psm-layout__sidebar-right {
  --setSidebarWidth: ;
  --setSidebarSpace: ;
  --setSidebarContentMinWidth: ;
}

.psm-layout__sidebar-right > * {
  display: flex;
  flex-wrap: wrap;
  gap: var(--setSidebarSpace);
}

.psm-layout__sidebar-right > * > :first-child {
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(var(--setSidebarContentMinWidth) - var(--setSidebarSpace));
}

.psm-layout__sidebar-right > * > :last-child {
  margin: calc(var(--setSidbarSpace) / 2);
  flex-basis: var(--setSidebarWidth);
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/components/Layout/Sidebar/styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,wCAAwC;EACxC,kCAAkC;EAClC,YAAY;AACd;;AAEA;EACE,aAAa;EACb,cAAc;EACd,0EAA0E;AAC5E;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,cAAc;EACd,0EAA0E;AAC5E;;AAEA;EACE,uCAAuC;EACvC,kCAAkC;EAClC,YAAY;AACd","sourcesContent":[".psm-layout__sidebar {\n  --setSidebarWidth: ;\n  --setSidebarSpace: ;\n  --setSidebarContentMinWidth: ;\n}\n\n.psm-layout__sidebar > * {\n  display: flex;\n  flex-wrap: wrap;\n  gap: var(--setSidebarSpace);\n}\n\n.psm-layout__sidebar > * > * {\n  margin: calc(var(--setSidebarSpace) / 2);\n  flex-basis: var(--setSidebarWidth);\n  flex-grow: 1;\n}\n\n.psm-layout__sidebar > * > :last-child {\n  flex-basis: 0;\n  flex-grow: 999;\n  min-width: calc(var(--setSidebarContentMinWidth) - var(--setSidebarSpace));\n}\n\n.psm-layout__sidebar-right {\n  --setSidebarWidth: ;\n  --setSidebarSpace: ;\n  --setSidebarContentMinWidth: ;\n}\n\n.psm-layout__sidebar-right > * {\n  display: flex;\n  flex-wrap: wrap;\n  gap: var(--setSidebarSpace);\n}\n\n.psm-layout__sidebar-right > * > :first-child {\n  flex-basis: 0;\n  flex-grow: 999;\n  min-width: calc(var(--setSidebarContentMinWidth) - var(--setSidebarSpace));\n}\n\n.psm-layout__sidebar-right > * > :last-child {\n  margin: calc(var(--setSidbarSpace) / 2);\n  flex-basis: var(--setSidebarWidth);\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
