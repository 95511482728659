import moment from "moment";
import { connect } from "react-redux";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { getWeekNumber } from "../../core/selectors/commonSelector";
import { setWeekNumber } from "../../core/actionCreators/ui/web/commonActionCreators";
import { getCachedTrainingAvailabilityWeeks } from "@/dux/scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import dispatchTrainingAvailability from "@/dux/scheduleTrainingPage/scheduleTrainingPageUtils";
import { getRescheduleAttendeeId } from "./manageTrainingClassPageSelectors";
import {
  getTrainingAttendeeById,
  getTrainingClassSessionDetails,
} from "../trainingClassSessions/selectors";
import Arrow from "@/web/common/Arrow";
import { decrementBy, incrementBy } from "../utils/mathUtils";

function getCommonToggleWeekStateProps(state) {
  const storeNumber = getStoreNumber(state);
  const attendeeId = getRescheduleAttendeeId(state);
  const { petId } = getTrainingAttendeeById(state, { attendeeId });
  const { petServiceId } = getTrainingClassSessionDetails(state, { storeNumber });
  return {
    petId,
    petServiceId,
    weekNumber: getWeekNumber(state),
    selectedDate: getSelectedDate(state),
    storeNumber: getStoreNumber(state),
    cachedTrainingAvailabilityWeeks: getCachedTrainingAvailabilityWeeks(state),
    direction: "right",
    componentID: "button_next_week_navigation",
  };
}

function getCommonToggleWeekDispatchProps(dispatch) {
  return {
    dispatchClickHandler: ({
      selectedDate,
      weekNumber,
      storeNumber,
      petServiceId,
      petId,
      cache,
    }) => {
      dispatch(setSelectDate(selectedDate));
      dispatch(setWeekNumber({ weekNumber }));
      dispatchTrainingAvailability({
        selectedDate,
        cache,
        storeNumber,
        petServiceId,
        petId,
        dispatch,
      });
    },
  };
}

function buildMergeProps({ addOrSubtractMoment, modifyWeekFn }) {
  return (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,

    clickHandler: () => {
      const {
        selectedDate,
        petServiceId,
        storeNumber,
        petId,
        cachedTrainingAvailabilityWeeks,
        weekNumber,
      } = propsFromState;

      propsFromDispatch.dispatchClickHandler({
        selectedDate: moment(selectedDate)[addOrSubtractMoment](1, "days"),
        weekNumber: modifyWeekFn(weekNumber, 1),
        storeNumber,
        petServiceId,
        petId,
        cache: cachedTrainingAvailabilityWeeks,
      });
    },
  });
}

export const PrevWeekRescheduleTraining = connect(
  (state) => ({
    ...getCommonToggleWeekStateProps(state),
    componentID: "button_prev_week_navigation",
    direction: "left",
  }),
  getCommonToggleWeekDispatchProps,

  buildMergeProps({ addOrSubtractMoment: "subtract", modifyWeekFn: decrementBy }),
)(Arrow);

export const NextWeekRescheduleTraining = connect(
  (state) => ({
    ...getCommonToggleWeekStateProps(state),
    componentID: "button_next_week_navigation",
    direction: "right",
  }),
  getCommonToggleWeekDispatchProps,
  buildMergeProps({ addOrSubtractMoment: "add", modifyWeekFn: incrementBy }),
)(Arrow);
