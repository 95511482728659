/* * ----------------------------------------------------------------------- * *\
   Action Types
\* * ----------------------------------------------------------------------- * */

export const GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER =
  "GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER";
export const GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER_REQUEST =
  "GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER_REQUEST";
export const GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER_SUCCESS =
  "GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER_SUCCESS";
export const GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER_FAILURE =
  "GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER_FAILURE";

export const GET_AVAILABLE_BUNDLES_BY_PET = "GET_AVAILABLE_BUNDLES_BY_PET";
export const GET_AVAILABLE_BUNDLES_BY_PET_REQUEST = "GET_AVAILABLE_BUNDLES_BY_PET_REQUEST";
export const GET_AVAILABLE_BUNDLES_BY_PET_SUCCESS = "GET_AVAILABLE_BUNDLES_BY_PET_SUCCESS";
export const GET_AVAILABLE_BUNDLES_BY_PET_FAILURE = "GET_AVAILABLE_BUNDLES_BY_PET_FAILURE";

/* * ----------------------------------------------------------------------- * *\
   Action Creators
\* * ----------------------------------------------------------------------- * */

export const getAvailableBundlesForAllPetsByCustomer = ({ customerKey, onComplete }) => ({
  type: GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER,
  customerKey,
  onComplete,
});

export const getAvailableBundlesForAllPetsByCustomerRequest = () => ({
  type: GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER_REQUEST,
});

export const getAvailableBundlesForAllPetsByCustomerSuccess = () => ({
  type: GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER_SUCCESS,
});

export const getAvailableBundlesForAllPetsByCustomerFailure = ({ error }) => ({
  type: GET_AVAILABLE_BUNDLES_FOR_ALL_PETS_BY_CUSTOMER_FAILURE,
  error,
});

export const getAvailableBundlesByPet = ({ petId }) => ({
  type: GET_AVAILABLE_BUNDLES_BY_PET,
  petId,
});

export const getAvailableBundlesByPetRequest = () => ({
  type: GET_AVAILABLE_BUNDLES_BY_PET_REQUEST,
});

export const getAvailableBundlesByPetSuccess = () => ({
  type: GET_AVAILABLE_BUNDLES_BY_PET_SUCCESS,
});

export const getAvailableBundlesByPetFailure = ({ error }) => ({
  type: GET_AVAILABLE_BUNDLES_BY_PET_FAILURE,
  error,
});
