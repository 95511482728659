/**
 * Filters an array of objects by a given property and search string.
 *
 * @param {Array} list - The array to filter.
 * @param {string} itemProperty - The name of the property to filter by.
 * @param {string} [searchString=""] - The search string to filter for (case-insensitive).
 * @returns {Array} - A filtered array.
 */
export default function fuzzyFilter(list, itemProperty, searchString = "") {
  // Use the `filter` method of the input array to filter out objects that don't match the search criteria.
  return list.filter(
    item => item[itemProperty]?.toLowerCase().indexOf(searchString.toLowerCase()) > -1,
  );
}
