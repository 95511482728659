import { connect } from "react-redux";
import { getPetCardList } from "./selectors/petCartCardSelectors";
import PetCartCardsMappingComponent from "../_components/petCard/PetCartCardsMappingComponent";

const mapStateToProps = state => {
  return {
    list: getPetCardList(state),
  };
};

export default connect(mapStateToProps)(PetCartCardsMappingComponent);
