/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const setFoodTypeActionTypes = {
  SET_FOOD_TYPE: "SET_FOOD_TYPE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setFoodType = ({ foodType, foodId, petId }) => ({
  type: setFoodTypeActionTypes.SET_FOOD_TYPE,
  foodType,
  foodId,
  petId,
});
