import { connect } from "react-redux";
import { getIcons } from "core/utils/iconUtils/iconUtils";
import { getItineraries, getEngagements } from "../../../core/selectors/entitiesSelector";
import IconsComponent from "./iconsComponent";

const mapStateToProps = (state, ownProps) => {
  return {
    icons: getIcons(
      ownProps.pet,
      ownProps.appointment,
      { itineraries: getItineraries(state) },
      { engagements: getEngagements(state) },
    ),
  };
};

const IconsContainer = connect(mapStateToProps)(IconsComponent);

export default IconsContainer;
