import React from "react";
import { LayoutBox } from "@/layout/box/Box";
import { Button } from "@petsmart-ui/sparky";
import { connect } from "react-redux";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import { GET_HOTEL_ITINERARY } from "dux/hotelItinerary/hotelItineraryActions";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { getCurrentItinerary } from "core/selectors/checkInOutSelector";
import { itinerariesStatusList } from "dux/_constants";
import {
  PATCH_HOTEL_ITINERARY_STATUS,
  patchHotelItineraryStatus,
} from "dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import { getChangeStatusPayload } from "dux/reservationCartDetailsConfirmation/reservationCartDetailsUtils";
import {
  getFirstHotelEngagementStatusByPet,
  getHotelEngagementsPets,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { APPOINTMENT_STATUS } from "core/constants";
import { isConfirmAllHidden } from "dux/hotelConfirmAppt/utils/hotelConfirmApptUtils";

export const HotelConfirmApptButton = props => {
  const { componentId, isHidden, label, isDisabled, onConfirmPet } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <Button variant="primary" disabled={isDisabled} onClick={onConfirmPet} text={label} />
    </LayoutBox>
  );
};

// appointment
// CONTAINER ----------------------------------------------------------------------------------------
export const HotelConfirmSingleAppointment = connect(
  state => {
    const petId = getCurrentPet(state);
    const hotelItineraryId = getCurrentItinerary(state);
    const isLoading = createLoadingSelector([
      customersActionTypes.LOAD_CUSTOMER,
      GET_HOTEL_ITINERARY,
      PATCH_HOTEL_ITINERARY_STATUS,
    ])(state);

    const status = getFirstHotelEngagementStatusByPet(state, { petId });

    // Get if status is booked
    const isEngagementBooked = status === APPOINTMENT_STATUS.BOOKED;

    // Get if status is confirmed
    const isEngagementConfirmed = status === APPOINTMENT_STATUS.CONFIRMED;

    return {
      componentId: "HotelConfirmSingleAppointment",
      label: isEngagementConfirmed ? "Confirmed" : "Confirm",
      isDisabled: isLoading || isEngagementConfirmed,
      isHidden: !isEngagementConfirmed && !isEngagementBooked,
      petId,
      hotelItineraryId,
    };
  },

  dispatch => {
    return {
      confirmPet: ({ petId, hotelItineraryId }) => {
        const data = getChangeStatusPayload({
          petIds: [petId],
          shouldUndo: false,
          status: itinerariesStatusList.CONFIRMED,
        });
        dispatch(
          patchHotelItineraryStatus({
            itineraryId: hotelItineraryId,
            data,
          }),
        );
      },
    };
  },
  (mapProps, dispatchProps) => {
    const { componentId, label, isHidden, isDisabled, petId, hotelItineraryId } = mapProps;
    const { confirmPet } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      label,
      isHidden,
      isDisabled,

      // actions to pass to view
      onConfirmPet: () => confirmPet({ petId, hotelItineraryId }),
    };
  },
)(HotelConfirmApptButton);

export const HotelConfirmAllAppointment = connect(
  state => {
    const hotelItineraryId = getCurrentItinerary(state);
    const currentPetId = getCurrentPet(state);
    const petIds = getHotelEngagementsPets(state);
    const isLoading = createLoadingSelector([
      customersActionTypes.LOAD_CUSTOMER,
      GET_HOTEL_ITINERARY,
      PATCH_HOTEL_ITINERARY_STATUS,
    ])(state);

    // Get any pets with confirmed status
    const petsWithConfirmStatuses = petIds?.filter(pet => {
      const status = getFirstHotelEngagementStatusByPet(state, { petId: pet });
      return status === APPOINTMENT_STATUS.CONFIRMED;
    });

    // Get any pets with booked status
    const petsWithBookedStatuses = petIds?.filter(pet => {
      const status = getFirstHotelEngagementStatusByPet(state, { petId: pet });
      return status === APPOINTMENT_STATUS.BOOKED;
    });

    // Check if all pets where confirmed btn is not hidden are confirmed
    const bookedOrConfirmedPets = [...petsWithBookedStatuses, ...petsWithConfirmStatuses];
    const areAllConfirmed = petsWithConfirmStatuses?.length === bookedOrConfirmedPets?.length;
    const hasMultipleToBeConfirmed = petsWithBookedStatuses?.length > 1;

    return {
      componentId: "HotelConfirmSingleAppointment",
      label: areAllConfirmed ? "Confirmed for all" : "Confirm all",
      isDisabled: isLoading || !hasMultipleToBeConfirmed,
      isHidden: isConfirmAllHidden({ bookedOrConfirmedPets, petIds, currentPetId }),
      petIds: petsWithBookedStatuses, // Only pets with 'booked' status can be confirmed
      hotelItineraryId,
    };
  },

  dispatch => {
    return {
      confirmPet: ({ petIds, hotelItineraryId }) => {
        const data = getChangeStatusPayload({
          petIds,
          shouldUndo: false,
          status: itinerariesStatusList.CONFIRMED,
        });
        dispatch(
          patchHotelItineraryStatus({
            itineraryId: hotelItineraryId,
            data,
          }),
        );
      },
    };
  },
  (mapProps, dispatchProps) => {
    const { componentId, label, isHidden, isDisabled, petIds, hotelItineraryId } = mapProps;
    const { confirmPet } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      label,
      isHidden,
      isDisabled,

      // actions to pass to view
      onConfirmPet: () => confirmPet({ petIds, hotelItineraryId }),
    };
  },
)(HotelConfirmApptButton);
