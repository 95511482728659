export const CUSTOMER_INFO_FIELDS = ["firstName", "lastName", "isEmailDeclined"];

export const CUSTOMER_PROFILE_FIELDS = ["isVip", "psaOnFile"];

export const CUSTOMER_DO_NOT_BOOK_FIELDS = ["doNotBook", "doNotBookReason"];

export const CUSTOMER_EMAIL_FIELDS = ["email"];

export const CUSTOMER_PHONE_FIELDS = ["mobilePhone", "homePhone", "workPhone"];

export const CUSTOMER_ADDRESS_FIELDS = [
  "streetLine1",
  "streetLine2",
  "city",
  "stateProvinceAbbreviation",
  "countryAbbreviation",
  "zipPostalCode",
];

export const ACTIVE_IN_PRISM_ID = 2;

export const UPDATE_PHONE_DUPLICATE_PHONE_SERVER_ERROR =
  "The input values that were provided are already associated with the phone number.";

export const CREATE_PHONE_DUPLICATE_SERVER_ERROR = "This phone number already exists.";

export const EMAIL_DUPLICATE_SERVER_ERROR = "Duplicate Email";

export const DUPLICATE_PHONE_USER_FRIENDLY_ERROR_MESSAGE = "This phone number already exists.";

export const DUPLICATE_EMAIL_USER_FRIENDLY_ERROR_MESSAGE = "This email address already exists.";

export const DEFAULT_SERVER_ERROR_MESSAGE = "An unexpected error occurred. Please try again.";

export const customerPhoneTypes = {
  MOBILE: "Mobile",
  HOME: "Home",
  WORK: "Work",
};

export const customerAddressTypes = {
  HOME: "Home",
  BILLING: "Billing",
  OTHER: "Other",
};

export const customerEmailTypes = {
  HOME: "Home",
  WORK: "Work",
};

export const modalTypes = {
  DELETE_AGENT: "DELETE_AGENT",
};

export const channelTypes = {
  LOYALTY: 1,
  SERVICES: 2,
  MARKETING: 3,
};

export const communicationTypes = {
  EMAIL: 1,
  TEXT: 2,
  PUSH: 3,
};

export const newCustomerFieldTypes = {
  PHONE: "phoneNumber",
  EMAIL: "email",
  NAME: "name",
};

export const communicationTypeDescriptions = {
  EMAIL: "EMAIL",
  TEXT: "TEXT",
};

export const EMAIL_OPT_OUT_OPTIONS = [
  { value: false, label: "Opted In" },
  { value: true, label: "Opted Out" },
];

export const UPDATE_CUSTOMER_REQUEST_FIELDS = [
  "genderId",
  "firstName",
  "lastName",
  "salutation",
  "middleName",
  "suffix",
  "businessName",
  "mailingName",
  "birthDate",
  "hasNoEmail",
  "hasNoPet",
  "doNotBook",
  "doNotBookReason",
  "isMobileAppUser",
  "isEmailDeclined",
  "reminderOptOutFlag",
  "sourceId",
];

export const UPDATE_CUSTOMER_HOTEL_REQUEST_FIELDS = [
  "genderId",
  "firstName",
  "lastName",
  "salutation",
  "middleName",
  "suffix",
  "businessName",
  "mailingName",
  "birthDate",
  "hasNoEmail",
  "hasNoPet",
  "isMobileAppUser",
  "isEmailDeclined",
  "reminderOptOutFlag",
  "sourceId",
];
