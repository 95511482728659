export const SET_TOGGLE_TAKE_PHOTO_MODAL = "SET_TOGGLE_TAKE_PHOTO_MODAL";

export const PUT_UPLOAD_TRAINER_PHOTO = "PUT_UPLOAD_TRAINER_PHOTO";
export const PUT_UPLOAD_TRAINER_PHOTO_REQUEST = "PUT_UPLOAD_TRAINER_PHOTO_REQUEST";
export const PUT_UPLOAD_TRAINER_PHOTO_SUCCESS = "PUT_UPLOAD_TRAINER_PHOTO_SUCCESS";
export const PUT_UPLOAD_TRAINER_PHOTO_FAILURE = "PUT_UPLOAD_TRAINER_PHOTO_FAILURE ";

export const SET_TOGGLE_REMOVE_VIRTUAL_TRAINER_MODAL = "SET_TOGGLE_REMOVE_VIRTUAL_TRAINER_MODAL";
export const LOAD_TRAINER_BOOKED_APPOINTMENTS = "LOAD_TRAINER_BOOKED_APPOINTMENTS";
export const LOAD_TRAINER_BOOKED_APPOINTMENTS_REQUEST = "LOAD_TRAINER_BOOKED_APPOINTMENTS_REQUEST";
export const LOAD_TRAINER_BOOKED_APPOINTMENTS_SUCCESS = "LOAD_TRAINER_BOOKED_APPOINTMENTS_SUCCESS";
export const LOAD_TRAINER_BOOKED_APPOINTMENTS_FAILURE = "LOAD_TRAINER_BOOKED_APPOINTMENTS_FAILURE";
export const TOGGLE_REMOVE_TRAINER_FROM_VIRTUAL_FLASH_MESSAGE =
  "TOGGLE_REMOVE_TRAINER_FROM_VIRTUAL_FLASH_MESSAGE";

export const TOGGLE_ASSOCIATE_PROFILE_MODAL = "TOGGLE_ASSOCIATE_PROFILE_MODAL";
export const setToggleTakePhotoModal = (payload) => ({
  type: SET_TOGGLE_TAKE_PHOTO_MODAL,
  payload,
});

export const putUploadTrainerPhoto = ({ storeNumber, associateId, file, onComplete }) => ({
  type: PUT_UPLOAD_TRAINER_PHOTO,
  storeNumber,
  associateId,
  file,
  onComplete,
});

export const putUploadTrainerPhotoRequest = () => ({
  type: PUT_UPLOAD_TRAINER_PHOTO_REQUEST,
});

export const putUploadTrainerPhotoSuccess = (payload) => ({
  type: PUT_UPLOAD_TRAINER_PHOTO_SUCCESS,
  payload,
});

export const putUploadTrainerPhotoFailure = (error) => ({
  type: PUT_UPLOAD_TRAINER_PHOTO_FAILURE,
  error,
});

export const setToggleRemoveVirtualTrainerModal = (payload) => ({
  type: SET_TOGGLE_REMOVE_VIRTUAL_TRAINER_MODAL,
  payload,
});

/**
 * Action Creator to begin the process of getting booked appointments
 */
export const loadTrainerBookedAppointments = ({ associateId, fromDate }) => {
  return {
    type: LOAD_TRAINER_BOOKED_APPOINTMENTS,
    associateId,
    fromDate,
  };
};

export const loadTrainerBookedAppointmentsRequest = () => ({
  type: LOAD_TRAINER_BOOKED_APPOINTMENTS_REQUEST,
});

export const loadTrainerBookedAppointmentsSuccess = () => ({
  type: LOAD_TRAINER_BOOKED_APPOINTMENTS_SUCCESS,
});

export const loadTrainerBookedAppointmentsFailure = ({ error }) => ({
  type: LOAD_TRAINER_BOOKED_APPOINTMENTS_FAILURE,
  error,
});

export const toggleRemoveTrainerFromVirtualFlashMessage = () => {
  return {
    type: TOGGLE_REMOVE_TRAINER_FROM_VIRTUAL_FLASH_MESSAGE,
  };
};

export const toggleAssociateProfileModal = () => ({
  type: TOGGLE_ASSOCIATE_PROFILE_MODAL,
});
