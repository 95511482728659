import React from "react";
import styled from "styled-components";
import { downArrowUri } from "./styles/theme";

const SectionWrapper = styled.section`
  cursor: pointer;
  font-size: 16px;
  min-width: 15px;
  width: 15px;
  height: 15px;
  background: url(${downArrowUri}) no-repeat center;
  background-size: contain;
  transform: ${({ direction }) => {
    switch (direction) {
      case "left":
        return "rotate(90deg)";

      case "right":
        return "rotate(-90deg)";

      case "up":
        return "rotate(-180deg)";

      default:
        return "initial";
    }
  }};
`;

/**
 * Responsible for displaying a simple navigation arrow.
 *
 * @export Arrow
 * @param {Object} obj - Destructured parameters
 * @param {String} obj.direction
 * @param {Function} obj.clickHandler
 * @returns JSX
 */
export default function Arrow({ direction, clickHandler, componentID, isHidden }) {
  return !isHidden ? (
    <SectionWrapper id={componentID} direction={direction} onClick={clickHandler} />
  ) : null;
}
