import React from "react";
import styled from "styled-components";

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UnderlinedTabs = ({ isHidden, componentID, children }) => {
  if (!isHidden) {
    return (
      <SectionWrapper id={componentID} data-testid={componentID}>
        {children}
      </SectionWrapper>
    );
  }

  return null;
};

export default UnderlinedTabs;
