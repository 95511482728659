import { createSelector } from "reselect";
import { NEW_FOOD_ID } from "web/newFood/newFoodConstants";
import { getFoodByPet, getFoodIds } from "../food/foodsSelector";

export const getFoodCount = createSelector([getFoodByPet], foods => {
  return Object.keys(foods).length;
});

export const getNewFoodId = createSelector([getFoodIds], foods => {
  const lastFood = foods[foods.length - 1]?.split("_");
  const lastFoodIndex = lastFood ? lastFood[lastFood?.length - 1] : 0;
  return `${NEW_FOOD_ID}_${Number(lastFoodIndex) + 1}`;
});
