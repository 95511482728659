import { associateWebBFFAPI } from "./_axiosConfigAssociateWebBff";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Endpoint for hotel dashboard capacity
 * @function getHotelDashboardCapacity
 * @param {string} requestedDate
 * @param {string} storeNumber
 * @returns {*} AxiosPromise
 */
export function getHotelDashboardCapacity({ requestedDate, storeNumber }) {
  return associateWebBFFAPI.get(
    `${endpointVersionString()}/hotel/stores/${storeNumber}/capacity?requestedDate=${requestedDate}`,
  );
}
