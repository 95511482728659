import { createSelector } from "reselect";
import { getAppointmentByPet } from "../../../../core/selectors/cartSelectors";
import { getServicesForPet, getAddOns } from "../../../../core/selectors/entitiesSelector";

// Standalone services accordion visibility ---------------------------------------------
export const isHiddenStandaloneList = state => state.accordionViews.showStandaloneList;

// Add-On and Standalone services are returned by this selector -------------------------
// Responsible for returning addOnId's for the filterable services list
export const getaddOnStandaloneByService = createSelector(
  [getAppointmentByPet, getServicesForPet, getAddOns],
  (appointment, services, items) => {
    const empty = [[], []];

    // ensure that appointments, services and add-on/standalones are all loaded
    if (!appointment) return empty;
    if (Object.values(services).length === 0) return empty;
    if (Object.values(items).length === 0) return empty;

    const serviceById = services[appointment.petService];
    const idArray = serviceById?.addOnIds;
    if (!idArray) return empty;

    const limit = 5;
    const popular = idArray.slice(0, limit).map(id => items[id]);
    const theRest = idArray.slice(limit, idArray.length).map(id => items[id]);

    return [popular.filter(Boolean), theRest.filter(Boolean)];
  },
);
