export const itinerariesPaymentStatuses = {
  OPEN: "PSVC_Open",
  IN_PROGRESS: "PSVC_In_Progress",
  PARTIAL_PAID: "PSVC_Partial_Paid",
  PAID: "PSVC_Paid",
  CLOSED: "PSVC_Closed",
  VOIDED: "PSVC_Voided",
  CANCELED: "PSVC_Canceled",
};

export const itinerariesPaymentOrigin = {
  WEB: "WEB",
  APP: "APP",
  POS: "POS",
};

export const FETCH_PDF_DELAY = 5000;
