import { createSelector } from "reselect";
import { getProps } from "../../commonSelector";
import getIsPrimaryAddress from "./getIsPrimaryAddress";
import { getIsOnlineAccount } from "../../petParentProfileSelectors";

// Example of use:  getIsDeleteAddressButtonHidden(state, { customerKey, addressId });
const getIsDeleteAddressButtonHidden = createSelector(
  [getProps, getIsPrimaryAddress, getIsOnlineAccount],
  ({ addressId }, isPrimaryAddress, isOnlineAccount) => {
    // Do not show delete button if: 1) Is add address form (no address ID), 2) Is primary address 3) Is online account
    return !addressId || isPrimaryAddress || isOnlineAccount;
  }
);

export default getIsDeleteAddressButtonHidden;
