import { createSelector } from "reselect";

/**
 * Selector to get overbooking new exception state
 * @memberOf Selectors.overBookingNewExceptionDate
 * @function
 * @name selectOverbookingNewException
 * @param {Object} state
 * @returns {Object}
 * @example selectOverbookingNewException(state)
 */
export const selectOverbookingNewException = state => {
  return state?.overBookingNewExceptionDate;
};

/**
 * Selector to get error from overbooking new exception state
 * @memberOf Selectors.overBookingNewExceptionDate
 * @function
 * @name selectOverbookingNewException
 * @param {Object} state
 * @returns {Object}
 * @example selectOverbookingNewExceptionError(state)
 */
export const selectOverbookingNewExceptionError = createSelector(
  [selectOverbookingNewException],
  ({ error } = {}) => error,
);
