import React from "react";
import { connect } from "react-redux";
import { Radio, Label } from "@petsmart-ui/sparky";
import {
  getItineraryInvoice,
  getItineraryInvoiceIsPaidOnline,
  getItineraryInvoiceIsPaidPOS,
} from "dux/hotelItinerary/hotelItinerarySelectors";
import { hasManagerPrivileges, getJobRole } from "core/selectors/persistentSelectors";
import { putHotelInvoicePaid } from "@/dux/hotelItineraryPayment/hotelItineraryPaymentActions";
import { itinerariesPaymentOrigin } from "@/core/constants/itinerariesConstants";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutBox } from "@/layout/box/Box";
import getItineraryByID from "core/selectors/entities/Itineraries/getItineraryByIDSelector";
import { getCurrentItinerary, getCurrentCustomer } from "core/selectors/checkInOutSelector";
import isPaidDisabled from "core/utils/paymentUtils/isPaidDisabledUtil";
import isPosPayment from "core/utils/paymentUtils/isPOSPaymentCheckUtil";
import isOnlinePayment from "core/utils/paymentUtils/isOnlinePaymentCheckUtil";
import { updateItineraryPaymentStatus } from "core/actionCreators/itinerariesActionCreators";

const onlineLabel = "Online";
const inStoreLabel = "In Store";

const InvoicePaidRadio = props => {
  const { isHidden, componentId, checked, label, disabled, onClick } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId}>
      <LayoutCluster space="scale-S2">
        <Radio checked={checked} onClick={onClick} disabled={disabled} />
        <Label text={label} style={{ opacity: disabled ? "0.5" : "1", margin: 0 }} bold={false} />
      </LayoutCluster>
    </LayoutBox>
  );
};

export const HotelInvoicePaidInStore = connect(
  state => {
    const isPaidPOS = getItineraryInvoiceIsPaidPOS(state);
    const isManager = hasManagerPrivileges(state);
    const invoiceGenerated = getItineraryInvoice(state)?.invoiceId;

    return {
      componentId: "HotelInvoicePaidInStore",
      label: inStoreLabel,
      checked: isPaidPOS,
      disabled: !isManager || !invoiceGenerated || isPaidPOS,
    };
  },
  dispatch => {
    return {
      onClick: () => dispatch(putHotelInvoicePaid(itinerariesPaymentOrigin.POS)),
    };
  },
)(InvoicePaidRadio);

export const HotelInvoicePaidOnline = connect(state => {
  return {
    disabled: true,
    label: onlineLabel,
    componentId: "HotelInvoicePaidOnline",
    checked: getItineraryInvoiceIsPaidOnline(state),
  };
})(InvoicePaidRadio);

export const SalonInvoicePaidInStore = connect(
  state => {
    const itineraryId = getCurrentItinerary(state);
    const itinerary = getItineraryByID(state, itineraryId);

    return {
      componentId: "SalonInvoicePaidInStore",
      checked:
        !!itinerary &&
        itinerary.paymentOrigin &&
        isPosPayment(itinerary.paymentOrigin, itinerary.status),
      disabled: isPaidDisabled(itinerary, getJobRole(state)),
      label: inStoreLabel,

      // For mergeProps
      customerId: getCurrentCustomer(state),
      itineraryId,
    };
  },
  dispatch => {
    return {
      dispatchItineraryPaymentStatus: ({ customerId, itineraryId, data }) => {
        dispatch(updateItineraryPaymentStatus({ customerId, itineraryId, data }));
      },
    };
  },
  (stateProps, dispatchProps) => {
    const { componentId, checked, disabled, label, customerId, itineraryId } = stateProps;
    const { dispatchItineraryPaymentStatus } = dispatchProps;
    return {
      componentId,
      checked,
      disabled,
      label,
      onClick: () =>
        dispatchItineraryPaymentStatus({ customerId, itineraryId, data: { action: "PaidInFull" } }),
    };
  },
)(InvoicePaidRadio);

export const SalonInvoicePaidOnline = connect(state => {
  const itineraryId = getCurrentItinerary(state);
  const itinerary = getItineraryByID(state, itineraryId);

  return {
    componentId: "SalonInvoicePaidOnline",
    checked:
      !!itinerary &&
      itinerary.paymentOrigin &&
      isOnlinePayment(itinerary.paymentOrigin, itinerary.status),
    label: onlineLabel,
    /*  The ability for Prism Pay and SF to accept a flag/param for Payment from the UI to override paymentOrigin
        is not currently available. This will be a later ticket, so when the checkbox needs to be enabled, the logic
        behind that is in the code below, uncomment and replace the current disabled value.
    */
    // disabled: isPaidDisabled(itinerary, getJobRole(state)),
    disabled: true,
  };
})(InvoicePaidRadio);
