/**
 * Constructs and returns a banner message indicating the current environment, system type, and booking flow.
 *
 * @function envFlowBannerMessage
 * @memberOf Utils.Banner
 * @param {Object} options - The function parameters wrapped in an object.
 * @param {string} options.environment - The current environment (e.g., 'development', 'production').
 * @param {string} options.systemType - The type of system (e.g., 'Hotel', 'Salon').
 * @param {string} options.systemBookingFlow - The current booking flow (e.g., 'Hotel', 'Salon').
 * @returns {string} A message indicating the current environment, system type, and booking flow.
 */
export const envFlowBannerMessage = ({ environment, systemType, systemBookingFlow }) => {
  return `You are in the ${environment} environment, within the ${systemType} System Type and ${systemBookingFlow} Booking Flow`;
};
