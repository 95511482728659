// Action Types
import { SET_SELECTED_REPORT_DATE } from "./reportDateSelectionActions";

/**
 * @name reportDateSelectionReducer
 * @memberof Reducer.Reports
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
const reportDateSelectionReducer = (state = { selectedReportDate: "" }, action) => {
  switch (action.type) {
    case SET_SELECTED_REPORT_DATE:
      return {
        ...state,
        selectedReportDate: action.selectedReportDate,
      };

    default:
      return state;
  }
};

export default reportDateSelectionReducer;
