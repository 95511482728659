/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const petFavorabilitySalonActionTypes = {
  CREATE_PET_FAVORABILITY: "CREATE_PET_FAVORABILITY",
  CREATE_PET_FAVORABILITY_REQUEST: "CREATE_PET_FAVORABILITY_REQUEST",
  CREATE_PET_FAVORABILITY_SUCCESS: "CREATE_PET_FAVORABILITY_SUCCESS",
  CREATE_PET_FAVORABILITY_FAILURE: "CREATE_PET_FAVORABILITY_FAILURE",
  DELETE_PET_FAVORABILITY: "DELETE_PET_FAVORABILITY",
  DELETE_PET_FAVORABILITY_REQUEST: "DELETE_PET_FAVORABILITY_REQUEST",
  DELETE_PET_FAVORABILITY_SUCCESS: "DELETE_PET_FAVORABILITY_SUCCESS",
  DELETE_PET_FAVORABILITY_FAILURE: "DELETE_PET_FAVORABILITY_FAILURE",
  UPDATE_PET_FAVORABILITY: "UPDATE_PET_FAVORABILITY",
  UPDATE_PET_FAVORABILITY_REQUEST: "UPDATE_PET_FAVORABILITY_REQUEST",
  UPDATE_PET_FAVORABILITY_SUCCESS: "UPDATE_PET_FAVORABILITY_SUCCESS",
  UPDATE_PET_FAVORABILITY_FAILURE: "UPDATE_PET_FAVORABILITY_FAILURE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const createPetFavorability = ({ customerKey, petId, formValues }) => ({
  type: petFavorabilitySalonActionTypes.CREATE_PET_FAVORABILITY,
  customerKey,
  petId,
  formValues,
});

export const createPetFavorabilityRequest = () => ({
  type: petFavorabilitySalonActionTypes.CREATE_PET_FAVORABILITY_REQUEST,
});

export const createPetFavorabilitySuccess = () => ({
  type: petFavorabilitySalonActionTypes.CREATE_PET_FAVORABILITY_SUCCESS,
});

export const createPetFavorabilityFailure = ({ error }) => ({
  type: petFavorabilitySalonActionTypes.CREATE_PET_FAVORABILITY_FAILURE,
  error,
});

export const updatePetFavorability = ({ customerKey, petId, formValues }) => ({
  type: petFavorabilitySalonActionTypes.UPDATE_PET_FAVORABILITY,
  customerKey,
  petId,
  formValues,
});

export const updatePetFavorabilityRequest = () => ({
  type: petFavorabilitySalonActionTypes.UPDATE_PET_FAVORABILITY_REQUEST,
});

export const updatePetFavorabilitySuccess = () => ({
  type: petFavorabilitySalonActionTypes.UPDATE_PET_FAVORABILITY_SUCCESS,
});

export const updatePetFavorabilityFailure = ({ error }) => ({
  type: petFavorabilitySalonActionTypes.UPDATE_PET_FAVORABILITY_FAILURE,
  error,
});
