import { filter } from "lodash/fp";

// TODO: move to utils directory

/**
 * Calculates and returns the count of dogs and cats appointments.
 *
 * @memberOf Utils.Pets
 * @function
 * @name getSpeciesCount
 * @param {Array} appointments - The list of appointments to be counted.
 * @return {Object} An object containing the count of dogs and cats appointments.
 *
 * @example
 *   const appointments = [{petSpeciesId: 1, name: 'fluffy'}, {petSpeciesId: 2, name: 'milo'}]
 *   getSpeciesCount(appointments)
 *   // returns { dogCount: 1, catCount: 1 }
 *
 */
export default function getSpeciesCount(appointments) {
  const dogs = filter(["petSpeciesId", 1], appointments);
  const cats = filter(["petSpeciesId", 2], appointments);

  return {
    dogCount: dogs.length,
    catCount: cats.length,
  };
}
