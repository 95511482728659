import { flatMap, reduce } from "lodash/fp";

/**
 * Give a collection of Foods or Meds, (see state.foods or state.medications), this function will return list of all food or medication history for all pets on an engagement.
 *
 *  @memberOf Utils.Pets
 *  @function
 *  @name reduceFoodOrMedHistory
 * @param {Array<Array<string>>} collection - The collection of food or med history objects normalized by PetId.
 *
 * @returns {Array<Object>} - Array of history objects.
 * @example
 * const medHistory = reduceFoodOrMedHistory(hotelMeds);
 * const foodHistory = reduceFoodOrMedHistory(hotelFood.foods);
 */
export const reduceFoodOrMedHistory = collection => {
  return reduce(
    (result, recordIdList) => {
      const flatRecordList = flatMap(recordId => {
        return recordId?.history;
      }, recordIdList);

      return [...result, ...flatRecordList];
    },
    [],
    collection,
  );
}