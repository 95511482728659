import moment from "moment";
import { every, get } from "lodash/fp";
import { APPOINTMENT_STATUS } from "../../../../core/constants";
import { buttonTypes } from "../../../../core/constants/buttonConstants";

const isInvoiceButtonOrStartAfterNow = function getIsInvoiceButtonOrStartAfterNow(
  isStartAfterNow,
  isPrintInvoiceButton
) {
  if (isStartAfterNow || isPrintInvoiceButton) {
    return true;
  }

  return false;
};

const isPageCorrect = function getIsPageCorrect(
  isCorrectPage,
  petServiceItems,
  currentPetServiceItem
) {
  if (isCorrectPage && petServiceItems && currentPetServiceItem) {
    return true;
  }

  return false;
};

/**
 * Responsible for determining if the button should show
 * @export checkIfButtonShouldShow
 * @param {string} pageName - Page the application is currently on
 * @param {sting} requiredPage - Page the application should be on to show the button
 * @param {sting} buttonType - Type of button being shown or hidden
 * @param {object} petServiceItems - service items
 * @param {object} currentPetServiceItem - current service Items
 * @returns {boolean}
 */

export function checkIfButtonShouldShow({
  petServiceItems,
  currentPage,
  requiredPage,
  currentPetServiceItem,
  buttonType
}) {
  const isStartAfterNow = every(
    item => moment(item.startDateTime).isAfter(moment()),
    petServiceItems
  );
  const isCorrectPage = currentPage == requiredPage;
  const isPrintInvoiceButton = buttonType === buttonTypes.PRINT_INVOICE;
  const isStatusCheckedOut =
    get("status", currentPetServiceItem) === APPOINTMENT_STATUS.CHECKED_OUT;

  if (isPrintInvoiceButton && isStatusCheckedOut) {
    return true;
  }

  if (
    isInvoiceButtonOrStartAfterNow(isStartAfterNow, isPrintInvoiceButton) &&
    isPageCorrect(isCorrectPage, petServiceItems, currentPetServiceItem)
  ) {
    return true;
  }

  return false;
}

/**
 * Responsible for determining if the button for confirm multiple pets should show
 *
 * @export checkIfMultiButtonShouldShow
 * @param {*} petServiceItems
 * @returns
 */
export function checkIfMultiButtonShouldShow(petServiceItems) {
  const isMoreThanOneServiceItems = petServiceItems.length > 1;

  if (isMoreThanOneServiceItems) {
    return true;
  }
  return false;
}
