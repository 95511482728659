import * as React from 'react';
import { psx } from '../../utils';
import { STACK_TOKENS } from '../../components/Layout/Stack/_tokens';
import { BoxProps, Layout } from '../../components/Layout';
import Button from '../../components/Button';
import SvgIcon from '../../components/Icon';
import Text from '../../components/Text';

export interface InfoBoxProps extends BoxProps {
  onClose?: () => void;
  header?: string;
  headerSpace?: keyof typeof STACK_TOKENS['SPACE'];
}

const InfoBox: React.FunctionComponent<InfoBoxProps> = ({
  header,
  headerSpace = 'stack-space-0',
  onClose,
  className,
  children,
  ...rest
}) => {
  const classNames = psx('psm', 'info-box', className);
  const justifyContent = header ? 'space-between' : 'flex-end';

  return (
    <Layout.Box className={classNames} {...rest}>
      <Layout.Stack space={headerSpace}>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent }}>
          {header && (
            <Text size="text-size-lg" bold>
              {header}
            </Text>
          )}
          <Layout.ShiftBy y={4}>
            <Button className="info-box-icon-button" variant="no-outline" onClick={onClose} style={{ padding: '0px' }}>
              <SvgIcon variant="hero-icon-x" size="psm-icon-size-24" />
            </Button>
          </Layout.ShiftBy>
        </div>
        <Layout.Box className="info-box-content">{children}</Layout.Box>
      </Layout.Stack>
    </Layout.Box>
  );
};

export default InfoBox;
