import { set } from "lodash/fp";
import searchSalonActionTypes from "core/actionTypes/searchSalonActionTypes";
import { checkForUndefined } from "core/utils/undefinedOrNullUtils/undefinedOrNullUtils";

const initialState = null;
/**
 * Reducer function for previous search component name.
 *
 * @memberof Reducer.persistent
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @returns {Object} - The new state.
 */
export const prevSearchComponentNameReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchSalonActionTypes.SET_PREVIOUS_SEARCH_COMPONENT_NAME:
      return checkForUndefined(action.prevSearchComponentName, initialState);

    default:
      return state;
  }
};
