import { createSelector } from "reselect";
import { getHotelCheckedOutState } from "../../columnList/hotelCheckedOutSelector";
import { CHECKED_OUT_OR_DEPARTURES_FILTER_OPTIONS } from "../../../../core/constants/dropdownOptions";

export const getCheckedOutColumnVisibility = createSelector(
  [getHotelCheckedOutState],
  checkedOutState => checkedOutState.isHidden,
);

export const getCheckedOutSelectedOption = createSelector(
  [getCheckedOutColumnVisibility],
  isHidden => {
    if (!isHidden) {
      // "Checked Out"
      return CHECKED_OUT_OR_DEPARTURES_FILTER_OPTIONS[0];
    }
    // "Departures"
    return CHECKED_OUT_OR_DEPARTURES_FILTER_OPTIONS[1];
  },
);
