import React, { useEffect } from "react";
import { connect } from "react-redux";
import BackGroundImage from "./../../assets/images/searchSRCBackground.jpg";
import SearchSRCHeaderContainer from "../header/SearchSRCHeaderContainer";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCenter } from "@/layout/center/Center";
import { Heading } from "@petsmart-ui/sparky";
import getIsSearchFieldMainV2WorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/searchFieldMainV2/getIsSearchFieldMainV2WorkflowFeatureFlagHidden";
import {
  buildSearchFieldProducts,
  SearchFactorySalon,
} from "dux/searchFieldMain/searchFactory/SearchFactory";
import { setSearchComponentName } from "core/actionCreators/searchSalonActionCreator";
import { searchConstants } from "core/constants/searchConstants";
import { resetStoreNumber } from "core/actionCreators/ui/web/generalActionCreators";
import { getJobRole } from "core/selectors/persistentSelectors";
import { selectCapabilities } from "dux/storeCapabilities/storeCapabilitiesSelectors";
import { getSystemType } from "web/setSystemType/selectors/setSystemTypeSelectors";
import {
  setSystemBookingFlowType,
  setSystemType,
} from "web/setSystemType/actions/setSystemTypeActions";
import { getStoreCapabilities } from "dux/enhancedServicesList/EnhancedServicesListActions";
import { enableDisableWorkflowFeatureFlag } from "web/enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagActions";
import { setSystemByCapabilities } from "dux/_components/dashboard/dashboardHelpers";
import { useCapabilitiesCheck } from "dux/_components/dashboard/DashboardComponent";

export const SearchSRCComponent = props => {
  // properties
  const { searchField, componentId, capabilities, jobRole } = props;

  // handler fns
  const { searchComponentName, rstStoreNumber, setSystemTypeAndFlow, storeCapabilities } = props;

  // on Mount/componentDidMount
  useEffect(() => {
    searchComponentName();
    rstStoreNumber();
    storeCapabilities();
  }, []);

  useCapabilitiesCheck({ setSystemTypeAndFlow, capabilities, jobRole });

  const SearchField = searchField;

  return (
    <LayoutBox
      id={componentId}
      padding="scale-0"
      style={{
        backgroundImage: `url(${BackGroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {/* NOTE: use the stack space to move search up or down on the page. */}
      <LayoutStack space={"scale-G6"}>
        {/* HEADER */}
        <LayoutBox padding="scale-0">
          <SearchSRCHeaderContainer className="searchSRCComponent__appHeader" />
        </LayoutBox>

        {/* BODY */}
        <LayoutCenter padding="scale-0" style={{ maxWidth: "fit-content" }}>
          <LayoutBox>
            <LayoutStack space="scale-G3">
              <LayoutCenter>
                <Heading
                  className="searchSRCComponent__heading"
                  tagName="h3"
                  style={{ color: "white" }}
                >
                  Find stores & pet parents
                </Heading>
              </LayoutCenter>

              <SearchField />
            </LayoutStack>
          </LayoutBox>
        </LayoutCenter>
      </LayoutStack>
    </LayoutBox>
  );
};

// CONTAINER ----------------------------------------------------------------------------------------
export const SrcDashBoard = connect(
  state => {
    const isFeatureFlagHidden = getIsSearchFieldMainV2WorkflowFeatureFlagHidden(state);
    const search = buildSearchFieldProducts(new SearchFactorySalon());

    return {
      // Factory will return the new or old customer search based on feature flag status
      searchField: search.srcSearch.getSearchField(isFeatureFlagHidden),
      componentId: "SrcDashBoard",
      jobRole: getJobRole(state),
      capabilities: selectCapabilities(state),
      systemType: getSystemType(state),
    };
  },

  dispatch => {
    return {
      dispatchSetSearchComponentName: () =>
        dispatch(setSearchComponentName({ searchComponentName: searchConstants.STORE })),
      dispatchResetStoreNumber: () => dispatch(resetStoreNumber()),
      dispatchSetSystemType: ({ systemType }) => dispatch(setSystemType({ systemType })),
      dispatchSetSystemBookingFlowType: ({ systemBookingFlow }) =>
        dispatch(setSystemBookingFlowType({ systemBookingFlow })),

      // NOTE we default to using store 133 for capabilities for src since a store is required for the capabilities api.
      dispatchStoreCapabilities: () => dispatch(getStoreCapabilities({ storeNumber: "133" })),

      dispatchEnableDisableWorkflowFeatureFlags: ({ featureFlagId, checked }) => {
        dispatch(enableDisableWorkflowFeatureFlag({ featureFlagId, checked }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const { searchField, componentId, jobRole, capabilities, systemType } = mapProps;
    const {
      dispatchSetSearchComponentName,
      dispatchResetStoreNumber,
      dispatchSetSystemType,
      dispatchSetSystemBookingFlowType,
      dispatchStoreCapabilities,
      dispatchEnableDisableWorkflowFeatureFlags,
    } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      searchField,
      capabilities,

      // actions to pass to view
      searchComponentName: () => dispatchSetSearchComponentName(),
      rstStoreNumber: () => dispatchResetStoreNumber(),
      storeCapabilities: () => dispatchStoreCapabilities(),
      setSystemTypeAndFlow: () => {
        setSystemByCapabilities({
          jobRole,
          capabilities,
          systemType,
          message:
            "Hotel is not available based on your current job role and store service capabilities, you will placed into the Salon flow For SRC",
          dispatchSetSystemType,
          dispatchSetSystemBookingFlowType,
          dispatchEnableDisableWorkflowFeatureFlags,
        });
      },
    };
  },
)(SearchSRCComponent);
