import { createSelector } from "reselect";
import isEmpty from "lodash/isEmpty";
import { selectServiceProductFamilyById } from "../serviceProductFamilies/serviceProductFamiliesSelectors";

export const selectItineraryHistoryListVisibleFilters = (state = {}) => {
  const filters = state?.itineraryHistory?.filters;
  if (isEmpty(filters)) return [];

  return Object.values(filters)
    .filter(filter => filter.isVisible)
    .map(filter => ({
      ...filter,
      name: filter?.name || selectServiceProductFamilyById(filter.filterValue)(state),
    }));
};

export const selectItineraryHistoryListActiveFilterValues = createSelector(
  [selectItineraryHistoryListVisibleFilters],
  filters => filters.filter(filter => filter.isPressed).map(filter => filter.filterValue),
);
