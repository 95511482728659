import React from "react";
import styled from "styled-components";
import { isEmpty } from "lodash/fp";
import { color, font } from "../../common/styles/theme";
import InitialsCircle from "../../common/InitialsCircle";
import StoreImage from "../../../assets/images/storeImage.png";
import CustomerImage from "../../../assets/images/customerImage.png";

// Styles via styled-components
const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 60px;
  margin-right: 18px;
`;

const CustomerInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CustomerInfo = styled.div`
  font-family: ${font.mainFamily};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${color.tChalla};
`;

function getProfileImage(customer, isCustomerActive) {
  if (customer && isCustomerActive) {
    return <UserImage className="customerCard__userImage" src={CustomerImage} />;
  } else if (customer && !isCustomerActive) {
    return (
      <InitialsCircle
        className="customerCard__userImage"
        displayName={`${customer.firstName} ${customer.lastName}`}
        margin="0 1.2rem 0 0"
      />
    );
  }

  return <UserImage className="customerCard__userImage" src={StoreImage} />;
}

const CustomerCard = ({ isHidden, infoList, customer, isCustomerActive, componentID, onClick }) => {
  if (isHidden) {
    return null;
  }
  return (
    !isEmpty(infoList) && (
      <SectionWrapper id={componentID} className="customerCard" onClick={onClick}>
        {getProfileImage(customer, isCustomerActive)}
        <CustomerInfoWrapper>
          {infoList.map((infoItem, index) => (
            <CustomerInfo className="CustomerCard__customerInfo" key={`custInfoItem_${index}`}>
              {infoItem}
            </CustomerInfo>
          ))}
        </CustomerInfoWrapper>
      </SectionWrapper>
    )
  );
};

export default CustomerCard;
