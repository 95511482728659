import { connect } from "react-redux";

import { history } from "@/dux/utils/browser/browserHistory";

import { associateClick } from "../../core/actionCreators/ganttActionCreators";
import { showDashboardModal } from "../../core/actionCreators/ui/web/dashboardActionCreators";
import { showBookingModal } from "../../core/actionCreators/bookingActionCreators";
import GanttRowComponent from "./GanttRowComponent";
import { getSelectedAssociateWeekly } from "../../core/selectors/commonSelector";
import commonActionCreators from "../../core/actionCreators/ui/web/commonActionCreators";
import { isFromStandalone } from "../../core/utils/validationUtils/isFromValidation";
import { getStoreTimeZoneOffset } from "../../core/selectors/entitiesSelector";
import { getStoreNumber } from "../../core/selectors/persistentSelectors";

import {
  getAppointmentDispatchProps,
  getAppointmentStateProps,
} from "./ganttAppointment/GanttAppointmentContainer";

const mapStateToProps = state => {
  const storeNumber = getStoreNumber(state);

  return {
    isWeeklyView: !!getSelectedAssociateWeekly(state),
    disabled: isFromStandalone(history.location.pathname),
    timeOffset: getStoreTimeZoneOffset(state, { storeNumber }),

    // props for GanttAppointmentComponent
    getAppointmentStateProps: (appointment, associateId) =>
      getAppointmentStateProps(state, { appointment, associateId }),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelectAssociate: associateId => {
    dispatch(commonActionCreators.onSelectAssociate({ associateId }));
  },
  onRowClick: ({ fromDashboard, isWeeklyView }) => {
    if (isFromStandalone(history.location.pathname)) {
      return;
    }

    dispatch(
      associateClick({
        isWeekly: ownProps.isWeekly,
        groupMember: ownProps.groupMember,
        fromDashboard,
      }),
    );

    if (isWeeklyView) {
      dispatch(commonActionCreators.setWeeklyGanttDate({ date: ownProps.groupMember }));
    }
  },
  showModal: (modalType, fromDashboard) => {
    fromDashboard ? dispatch(showDashboardModal(modalType)) : dispatch(showBookingModal(modalType));
  },

  // props for GanttAppointmentComponent
  getAppointmentDispatchProps: (appointment, router) =>
    getAppointmentDispatchProps(dispatch, { appointment, router }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GanttRowComponent);
