import React from "react";

// Components
import { LayoutCluster } from "@/layout/culster/Cluster";

/**
 * React view component of a hotel filters pills on Dashboard
 *
 * @summary Located on the Hotel Dashboard
 * @memberOf Views.Associate
 * @function
 * @name  ListFilterButtons
 * @param { Object } props
 * @param { String } props.componentID - Block level Id for the component
 * @param { String } props.compName - eg: HotelArrivals, HotelDepartures, HotelOvernight
 * @param { Array } props.filters - List of filters to render
 * @param { Boolean } props.isHidden - determines if the component is visible
 * @returns {JSX.Element|null}
 *
 * @example < ListFilterButtons />
 */
export default function ListFilterButtons(props) {
  const { isHidden, componentID, compName, filters } = props;

  if (isHidden) return null;

  return (
    <LayoutCluster id={componentID} space="scale-S3">
      {filters.map((filter, index) => {
        const FilterBtn = filter[compName];
        const key = `${componentID}_Filterbtn-${index}`;

        return <FilterBtn key={key} style={{ minWidth: "80px" }} />;
      })}
    </LayoutCluster>
  );
}
