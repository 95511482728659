import { createSelector } from "reselect";
import { getItinerary } from "core/selectors/entitiesSelector";
import { getSystemType } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { getHotelItinerary } from "dux/hotelItinerary/hotelItinerarySelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

/**
 *  Selector to get the itinerary based on the current system (Salon, Hotel..)
 *
 *  @memberOf Selectors.itinerarySelectors
 *  @function
 *  @name selectItineraryBySystemType
 *  @param { Object } state - redux state
 *  @param { Object } itineraryId - itinerary id string
 *  @returns { Object } itinerary
 *  @example
 *
 *  const itineraryId = getCurrentItinerary(state);
 *  selectItineraryBySystemType(state, { itineraryId });
 */
export const selectItineraryBySystemType = createSelector(
  [getSystemType, getItinerary, getHotelItinerary],
  (systemType, salonItinerary, hotelItinerary) => {
    switch (systemType) {
      case systemName.HOTEL:
        return hotelItinerary;
      case systemName.SALON:
        return salonItinerary;
      default:
        return {};
    }
  },
);
