import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";
import StyledPopover from "web/common/StyledPopover";
import AddOnsFlyout from "web/cart/AddonsFlyoutContainer";
import StandaloneAddons from "web/booking/standaloneAddons/StandaloneAddonsContainer";
import moment from "moment";
import AddButton from "web/common/buttons/addButton";
import { LayoutBox } from "@/layout/box/Box";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { getAppointmentByPet } from "core/selectors/cartSelectors";
import { getStandaloneServiceByPet } from "core/selectors/entitiesSelector";
import { loadStoreServicesForQuickQuotePet } from "core/actionCreators/servicesSelectionActionCreator";
import { formatMoment } from "core/utils/dateUtils/formatDateTime";
import quickQuoteConstants from "core/constants/quickQuoteConstants";
import { getQuickQuotePet } from "core/selectors/quickQuoteUISelectors";
import { clearCart, selectPetService } from "core/actionCreators/ui/web/cartActionCreators";
import { selectPet } from "core/actionCreators/bookingActionCreators";

export const renderAddonsFlyout = ({ appointment, selectedPetId }, onClose) => {
  if (!appointment) {
    return null;
  }

  return (
    <AddOnsFlyout
      selectedAddons={appointment.addons}
      mainHeading="Standalones"
      bottomHeading="All standalones"
      petId={selectedPetId}
      onClose={() => onClose(false)}
      petServiceId={appointment.petService}
    />
  );
};

/**
 * React view component to Show addons popup on the Quick Quote page
 * @summary Quick Quote page popup
 * @memberOf Views.Salon.QuickQuote
 * @function
 * @name QuickQuoteStandaloneServiceComponent
 * @param {Object} props - props passed into the view component
 * @param {Object} props.quickQuotePet - tracking of quick quote page user selections
 * @param {Object} props.standaloneService - stand alone service object
 * @param {string} props.selectedPetId - the selected pet id from the appointment
 * @param {string} props.selectedPetServiceId - the selected pet service id from the appointment
 * @param {Object} props.appointment - the complete appointment
 * @param {Function} props.loadStandaloneService - dispatch actions to load the correct stand alone services
 * @returns {JSX.Element}
 * @example <QuickQuoteStandaloneService />
 */

export const QuickQuoteStandaloneServiceComponent = ({
  quickQuotePet = {},
  standaloneService = {},
  selectedPetId,
  selectedPetServiceId,
  appointment,
  loadStandaloneService,
}) => {
  const [isAddonPopoverOpen, setIsAddonPopoverOpen] = useState(false);

  useEffect(() => {
    if (quickQuotePet?.speciesId || quickQuotePet?.breedId || quickQuotePet?.birthDate) {
      loadStandaloneService();
    }
  }, [quickQuotePet?.speciesId, quickQuotePet.breedId, quickQuotePet.birthDate]);

  useEffect(() => {
    if (standaloneService?.petServiceId) {
      loadStandaloneService();
    }
  }, [standaloneService.petServiceId]);

  return (
    <LayoutBox style={{ flex: 1 }}>
      <LayoutBox style={{ display: "flex", justifyContent: "space-between" }}>
        <div>Stand alone services</div>

        {standaloneService && !isEmpty(standaloneService) && appointment ? (
          <StyledPopover
            body={renderAddonsFlyout({ appointment, selectedPetId }, setIsAddonPopoverOpen)}
            isOpen={isAddonPopoverOpen}
            place="left"
            onOuterAction={() => setIsAddonPopoverOpen(false)}
            tipSize={7}
          >
            <AddButton
              buttonClickHandler={() => setIsAddonPopoverOpen(true)}
              opacity="1"
              label="Add standalone"
              fontSize="regular"
            />
          </StyledPopover>
        ) : (
          <div>None</div>
        )}
      </LayoutBox>
      {selectedPetId && selectedPetServiceId && <StandaloneAddons petId={selectedPetId} />}
    </LayoutBox>
  );
};

/**
 * CONTAINER
 */

const mapStateToProps = (state, ownProps) => {
  const appointment = getAppointmentByPet(state, ownProps);

  return {
    quickQuotePet: getQuickQuotePet(state),
    selectedPetId: getSelectedPet(state),
    appointment,
    standaloneService: getStandaloneServiceByPet(state, ownProps),
    selectedPetServiceId: appointment && appointment.petService,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadServicesForQuickQuotePet: ({ speciesId, breedId, birthDate, fromDate, isStandalone }) =>
      dispatch(
        loadStoreServicesForQuickQuotePet({
          customerKey: "QUOTE_CUSTOMER",
          speciesId,
          breedId,
          birthDate,
          fromDate: formatMoment(moment(fromDate)),
          isStandalone,
          petId: quickQuoteConstants.QUOTE_PET,
        }),
      ),
    dispatchClearCart: () => dispatch(clearCart()),
    dispatchSelectPetService: ({ petServiceId, petId }) =>
      dispatch(selectPetService({ petServiceId, petId })),
    dispatchSelectPet: petId => dispatch(selectPet(petId)),
  };
};

const mergeProps = (propsFromState, propsFromDispatch) => {
  const { quickQuotePet, selectedPetId, appointment, standaloneService, selectedPetServiceId } = propsFromState;
  return {
    quickQuotePet,
    selectedPetId,
    appointment,
    standaloneService,
    selectedPetServiceId,
    loadStandaloneService: () => {
      const {
        loadServicesForQuickQuotePet,
        dispatchSelectPetService,
        dispatchClearCart,
        dispatchSelectPet,
      } = propsFromDispatch;

      if (quickQuotePet && standaloneService?.petServiceId) {
        dispatchClearCart();
        loadServicesForQuickQuotePet({
          ...quickQuotePet,
          isStandalone: true,
          fromDate: moment().format("YYYY-MM-DD"),
        });
        dispatchSelectPetService({
          petServiceId: standaloneService.petServiceId,
          petId: quickQuotePet.petId,
        });
        dispatchSelectPet(quickQuotePet.petId);
      }
    },
  }
};

export const QuickQuoteStandaloneService = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(QuickQuoteStandaloneServiceComponent);
