import { getState } from "@/core/selectors/commonSelector";
import { createSelector } from "reselect";

/**
 * Selector to get available reports
 * @memberOf Selectors.Reports
 * @function
 * @name selectAvailableReportsList
 * @param {Object} state
 * @returns {Array<{label: string; value; string; endpoint; string, relevantDays; object}>}
 * @example selectAvailableReportsList(state)
 */
export const selectAvailableReportsList = createSelector([getState], state => {
  const availableReports = state?.reportSelection?.availableReports || [];

  return availableReports?.map(report => {
    return {
      label: report.displayName,
      value: report.displayName,
      endpoint: report.endpointName,
      relevantDays: report.relevantDays,
    };
  });
});

/**
 * Selector to get selected report type
 * @memberOf Selectors.Reports
 * @function
 * @name selectSelectedReportType
 * @param {Object} state
 * @returns {string} selected report type
 * @example selectSelectedReportType(state)
 */
export const selectSelectedReportType = createSelector([getState], state => {
  return state?.reportSelection?.selectedReportType || "";
});
