import { connect } from "react-redux";
import { getHereOrCompletedFilterApplied } from "../../../core/selectors/upcomingAndHereSelectors";
import { setHereOrCompletedFilterApplied } from "../../../core/actionCreators/upcomingAndHereActionCreators";
import {
  getHereAppointmentsForSalon,
  getUpcomingAppointmentsForSalon,
} from "../../../core/selectors/entitiesSelector";
import CardListWrapperComponent from "./cardListWrapperComponent";

const mapStateToProps = state => {
  return {
    upcomingAppointments: getUpcomingAppointmentsForSalon(state),
    hereAppointments: getHereAppointmentsForSalon(state),
    hereOrCompletedFilterApplied: getHereOrCompletedFilterApplied(state),
  };
};

const mapDispatchToProps = dispatch => ({
  setHereOrCompletedFilterApplied: filterApplied => {
    dispatch(setHereOrCompletedFilterApplied({ hereOrCompletedFilterApplied: filterApplied }));
  },
});

const cardListWrapperContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CardListWrapperComponent);

export default cardListWrapperContainer;
