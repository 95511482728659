import { GET_HOTEL_BEDDING_TYPES_SUCCESS } from "./hotelBeddingTypesActions";

const initialState = {};

const hotelBeddingTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTEL_BEDDING_TYPES_SUCCESS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default hotelBeddingTypesReducer;
