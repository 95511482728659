import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";
import getFormFieldsChanged from "../../core/utils/formUtils/getFormFieldsChanged";
import PetFavorabilityComponent from "../_components/favorability/PetFavorabilityComponent";
import { getPetFavorabilityFormData, isDisabledForNonManagement } from "./petFavorabilitySelectors";
import { createPetFavorability, updatePetFavorability } from "./petFavorabilitySalonActions";
import { getSystemBookingFlow } from "../../web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../../web/setSystemType/constants/setSystemTypeConstants";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { hasManagerPrivileges } from "@/core/selectors/persistentSelectors";
import { buildPetFavoribilityOptionsList } from "core/utils/petParentUtils";

const mapStateToProps = (state) => {
  const petId = getSelectedPet(state);
  const customerKey = getCurrentCustomerKey(state);
  const isManager = hasManagerPrivileges(state);
  const petFavorabilityFormData = getPetFavorabilityFormData(state, { petId });
  const isDisabledForNonManager = isDisabledForNonManagement({ petFavorabilityFormData, isManager });
  const options = buildPetFavoribilityOptionsList(!isManager && !isDisabledForNonManager);

  return {
    componentID: "petFavorably-Salon",
    title: "pet favorability",
    label: "Grooming rating",
    isHidden: getSystemBookingFlow(state) !== systemName.SALON,
    petFavorabilityFormData,
    isManager,
    isDisabledForNonManagement: isDisabledForNonManager,
    options,
    petId,
    customerKey,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleOnSubmit: ({ formValues, initialValues, petId, customerKey }) => {
    const formFieldsToSubmit = getFormFieldsChanged(initialValues, formValues);
    const isNew = Object.keys(initialValues).filter(value => initialValues[value]);

    if (!isNew.length && !isEmpty(formFieldsToSubmit)) {
      dispatch(
        createPetFavorability({
          customerKey: customerKey,
          petId: petId,
          formValues: formFieldsToSubmit,
        }),
      );
    } else if (!isEmpty(formValues)) {
      dispatch(
        updatePetFavorability({
          customerKey: customerKey,
          petId: petId,
          formValues,
        }),
      );
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSubmit: ({ formValues, initialValues }) =>
    propsFromDispatch.handleOnSubmit({
      petId: propsFromState.petId,
      customerKey: propsFromState.customerKey,
      formValues,
      initialValues,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PetFavorabilityComponent);
