import { associateWebBFFAPI } from "./_axiosConfigAssociateWebBff";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * API to get available reports
 * @memberOf APIs.Reports
 * @function getAvailableReportsEndpoint
 * @returns {*} AxiosPromise
 */
export function getAvailableReportsEndpoint() {
  return associateWebBFFAPI.get(
    `${endpointVersionString()}/hotel/operational-dashboards/availablereports`,
  );
}

/**
 * API to get reports by report type
 * @memberOf APIs.Reports
 * @param {string} - reportType
 * @param {string} - storeNumber
 * @param {string} - selectedDate
 * @function getReportsByReportTypeEndpoint
 * @returns {*} AxiosPromise
 */
export function getReportsByReportTypeEndpoint({ reportType, storeNumber, selectedDate }) {
  return associateWebBFFAPI.get(
    `${endpointVersionString()}/hotel/stores/${storeNumber}/operational-dashboards/${reportType}?date=${selectedDate}`,
  );
}
