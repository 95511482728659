import React from "react";
import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { Text, Layout } from "@prism/psm-ui-components";
import { getIsMultiPetBookingWarningToBeDisplayed } from "@/core/selectors/bookingUISelectors";
import { multiPetBookingWarningMessage } from "@/dux/_constants/warningMessageConstants";
import {
  isFromSelectTimeSlot,
  isFromModifyAppointment,
} from "@/core/utils/validationUtils/isFromValidation";

export function MultiPetBookingErrorMessage({ isHidden, textContent }) {
  return (
    <Layout.Box isHidden={isHidden} padding="box-padding-4">
      <Text size="text-size-lg" color="text-color-red" align="center">
        {textContent}
      </Text>
    </Layout.Box>
  );
}

const mapStateToProps = (state, { router }) => {
  const pathname = router?.location?.pathname;
  const isRouteSelectTimeSlotOrModifyAppointment =
    isFromSelectTimeSlot(pathname) || isFromModifyAppointment(pathname);
  return {
    isHidden:
      !isRouteSelectTimeSlotOrModifyAppointment || !getIsMultiPetBookingWarningToBeDisplayed(state),
    textContent: multiPetBookingWarningMessage,
  };
};

export default withRouteProps(connect(mapStateToProps)(MultiPetBookingErrorMessage));
