import React from "react";
import { Button } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";
import {
  getCurrentCustomerKey,
  getCurrentCustomerPrimaryPhoneType,
  getCurrentCustomerPrimaryPhoneNumber,
} from "@/core/selectors/persistent/customer/customerSelectors";
import {
  getTrainingAppointment,
  getSelectedTrainingPetService,
  getTrainingEngagementNote,
} from "@/dux/scheduleTrainingPage/scheduleTrainingPageSelectors";
import {
  getSelectedTimeSlot,
  getCustomerTimeZone,
} from "@/dux/scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import {
  POST_TRAINING_CLASS_ITINERARY,
  PUT_TRAINING_CLASS_ITINERARY,
  putTrainingClassItinerary,
  finalizeTrainingClassItineraryBooking,
  FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING,
} from "@/dux/trainingClassItineraries/trainingClassItinerariesActions";
import { getTrainingClassAvailabilityByClassSessionId } from "@/dux/trainingClassAvailability/trainingClassAvailabilitySelectors";
import { getStoreNumber, getCurrentPet } from "@/core/selectors/persistentSelectors";
import { toggleTrainingAppointmentIsSetModal } from "@/dux/scheduleTrainingPage/scheduleTrainingPageActions";
import ScheduleTrainingAppointmentSetModal from "@/dux/_components/scheduleTraining/ScheduleTrainingAppointmentSetModal";
import { itinerariesStatusList } from "@/dux/_constants";
import { isBookingDisabled } from "@/dux/eligibility/helperUtils/checkIsBookingDisabled";

function ScheduleTrainingButtonWithModal({ isDisabled, onClick }) {
  return (
    <>
      <ScheduleTrainingAppointmentSetModal />
      <Button variant="prism-primary" disabled={isDisabled} onClick={onClick}>
        Schedule Class
      </Button>
    </>
  );
}

export default connect(
  (state) => {
    const isSelectedTimeSlot = getSelectedTimeSlot(state);
    const trainingAppointment = getTrainingAppointment(state);
    const storeNumber = getStoreNumber(state);
    const isLoading = createLoadingSelector([
      POST_TRAINING_CLASS_ITINERARY,
      PUT_TRAINING_CLASS_ITINERARY,
      FINALIZE_TRAINING_CLASS_ITINERARY_BOOKING,
    ])(state);

    const petServiceId = getSelectedTrainingPetService(state);
    const { classSessionId, itineraryId, engagementId } = trainingAppointment;
    const timeSlot = getTrainingClassAvailabilityByClassSessionId(state, { classSessionId }) || {};
    const {
      startDatetime,
      associateId,
      serviceDurationTime,
      serviceDurationTimeUnit,
      behaviors,
    } = timeSlot;
    const notes = getTrainingEngagementNote(state);
    const customerKey = getCurrentCustomerKey(state);
    const customerPhone = getCurrentCustomerPrimaryPhoneNumber(state);
    const customerPhoneType = getCurrentCustomerPrimaryPhoneType(state);
    const customerTimeZone = getCustomerTimeZone(state, { storeNumber });

    return {
      petServiceId,
      classSessionId,
      itineraryId,
      engagementId,
      associateId,
      behaviors,
      notes,
      customerKey,
      customerPhone,
      customerPhoneType,
      trainingAppointment,
      customerTimeZone,
      startDateTime: startDatetime,
      duration: serviceDurationTime,
      durationUnitOfMeasure: serviceDurationTimeUnit,
      isDisabled:
        isLoading ||
        !isSelectedTimeSlot ||
        isEmpty(trainingAppointment) ||
        isBookingDisabled(state, customerKey),
      petId: getCurrentPet(state),
    };
  },
  (dispatch) => ({
    dispatchPutTrainingItinerary: (payload) => {
      dispatch(putTrainingClassItinerary(payload));
    },
    dispatchFinalizeTrainingClassItineraryBooking: (payload) => {
      dispatch(finalizeTrainingClassItineraryBooking(payload));
    },
    dispatchToggleTrainingAppointmentIsSetModal: () =>
      dispatch(toggleTrainingAppointmentIsSetModal()),
  }),
  (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,

    onClick: () => {
      const {
        petServiceId,
        customerKey,
        petId,
        classSessionId,
        startDateTime,
        associateId,
        duration,
        durationUnitOfMeasure,
        behaviors,
        notes,
        customerPhone,
        customerPhoneType,
        customerTimeZone,
        itineraryId,
        engagementId,
      } = propsFromState;
      const {
        dispatchPutTrainingItinerary,
        dispatchFinalizeTrainingClassItineraryBooking,
        dispatchToggleTrainingAppointmentIsSetModal,
      } = propsFromDispatch;

      dispatchPutTrainingItinerary({
        petServiceId,
        customerKey,
        itineraryId,
        petId,
        classSessionId,
        startDateTime,
        associateId,
        duration,
        durationUnitOfMeasure,
        behaviors: [], // setting behavior goals currently isn't supported through Prism SVCSART-16071
        notes,
        customerPhone,
        customerPhoneType,
        customerTimeZone,
        onComplete: () => {
          dispatchFinalizeTrainingClassItineraryBooking({
            customerKey,
            itineraryId,
            engagementId,
            status: itinerariesStatusList.BOOKED,
            reason: null,
            onComplete: () => dispatchToggleTrainingAppointmentIsSetModal(),
          });
        },
      });
    },
  }),
)(ScheduleTrainingButtonWithModal);
