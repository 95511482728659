import { SET_OVER_BOOKING_FILTERS } from "./overBookingFilterPillsActions";

const initialState = {
  overBookingFilters: [],
};

export const overBookingFilterPillsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OVER_BOOKING_FILTERS:
      return {
        ...state,
        overBookingFilters: action.payload,
      };
    default:
      return state;
  }
};
