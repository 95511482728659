import {
  chromeDefaultBreakPoints,
  customBreakPoints,
} from "web/common/styles/responsive/breakPoints";

/**
 * Select the Device to target in a media in a media query
 * @type {{galaxyTabProLandscape: string, tablet: string, desktopL: string, galaxyTabProPortrait: string, desktop: string, laptopL: string, mobileS: string, mobileM: string, laptop: string, mobileL: string, prismDesktop: string}}
 */
export const device = {
  mobileS: `(min-width: ${chromeDefaultBreakPoints.mobileS})`,
  mobileM: `(min-width: ${chromeDefaultBreakPoints.mobileM})`,
  mobileL: `(min-width: ${chromeDefaultBreakPoints.mobileL})`,
  tablet: `(min-width: ${chromeDefaultBreakPoints.tablet})`,
  laptop: `(min-width: ${chromeDefaultBreakPoints.laptop})`,
  laptopL: `(min-width: ${chromeDefaultBreakPoints.laptopL})`,
  desktop: `(min-width: ${chromeDefaultBreakPoints.desktop})`,
  desktopL: `(min-width: ${chromeDefaultBreakPoints.desktop})`,

  // NOTE: some device targets may use a min-width and max-height to target very specific
  // resolutions in "PC Browser" dev tools. This was the way I used to target different devices
  // In Chrome Dev tools with the same width resolution, eg. both are 1920px without having the physical device.
  prismDesktop: `
    (min-width: ${customBreakPoints.desktopWidth}) and (max-height: ${customBreakPoints.desktopHeight})
  `,
  galaxyTabProLandscape: `
    (min-width: ${customBreakPoints.galaxyTabProWidth}) and (max-height: ${customBreakPoints.galaxyTabProHeight})
  `,
  galaxyTabProPortrait: `
    (min-width: ${customBreakPoints.galaxyTabProHeight}) and (max-height: ${customBreakPoints.galaxyTabProWidth})
  `,
};
