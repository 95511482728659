import { get } from "lodash/fp";
import { createSelector } from "reselect";
import getMedicationState from "../../medication/_medicationState";

export const getIsAddingNewMedication = createSelector([getMedicationState], medicationState =>
  get("newMedication", medicationState),
);

export const getIsNotAddingNewMedication = createSelector(
  [getMedicationState],
  medicationState => !get("newMedication", medicationState),
);
