import flattenArray from "core/utils/arrayUtils/flattenArray";

/**
 * Determines if an array is a multi-dimensional list (i.e. an array of arrays), if so it flattens the
 * array and returns a boolean if there is more than one index in the array.
 *
 * @param {any[]} array - The array to check.
 * @returns {boolean} - Returns true if the array length is greater than 1, false otherwise.
 *
 * @example
 *
 * const arr1 = [1, 2, 3, 4, 5];
 * const arr2 = [[1, 2], [3, 4], [5]];
 * const arr3 = [1, 2, 5, 4, 5, 6];
 * const arr4 = [6];
 *
 * isMultiIndexList(arr1); // Returns false
 * isMultiIndexList(arr2); // Returns true
 * isMultiIndexList(arr3); // Returns true
 * isMultiIndexList(arr4); // Returns false
 */
export const isMultiIndexList = array => {
  // Use flattenArray to make sure the array is flat and does not contain any duplicate values.
  const flatList = flattenArray(array);

  // return a bool if the array is greater than 1
  return flatList.length > 1;
};