import { connect } from "react-redux";
import PetModalsComponent from "./PetModalsComponent";
import vetsActionCreators from "../../../../core/actionCreators/vetsActionCreators";
import petProfileActionCreators from "../../../../core/actionCreators/petProfileActionCreators";

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(petProfileActionCreators.hidePetProfileModal()),
  hideVetSearchResults: () => dispatch(vetsActionCreators.hideVetSearchResults()),
  clearCustomerAndPetKeys: () => dispatch(vetsActionCreators.clearCustomerAndPetKeys()),
  clearVetToDelete: () => dispatch(vetsActionCreators.clearVetToDelete())
});

export default connect(
  null,
  mapDispatchToProps
)(PetModalsComponent);
