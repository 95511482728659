/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelOvernightFilterButtonActionTypes = {
  FILTER_BY_All_OVERNIGHT: "FILTER_BY_All_OVERNIGHT",
  FILTER_BY_ATTENTION_NEEDED_OVERNIGHT: "FILTER_BY_ATTENTION_NEEDED_OVERNIGHT",
  FILTER_BY_UNASSIGNED_PET_OVERNIGHT: "FILTER_BY_UNASSIGNED_PET_OVERNIGHT",
};

/* * ----------------------------------------------------------------------- * *\
    Action Creators
  \* * ----------------------------------------------------------------------- * */

export const filterByAllOvernight = ({ filterBtnStatus }) => ({
  type: hotelOvernightFilterButtonActionTypes.FILTER_BY_All_OVERNIGHT,
  filterBtnStatus,
});

export const filterByAttentionNeededOvernight = ({ filterBtnStatus }) => ({
  type: hotelOvernightFilterButtonActionTypes.FILTER_BY_ATTENTION_NEEDED_OVERNIGHT,
  filterBtnStatus,
});

export const filterByUnassignedPetOvernight = ({ filterBtnStatus }) => ({
  type: hotelOvernightFilterButtonActionTypes.FILTER_BY_UNASSIGNED_PET_OVERNIGHT,
  filterBtnStatus,
});
