import React from "react";
import { connect } from "react-redux";

// Components
import { Layout } from "@prism/psm-ui-components";
import LoadingWrapper from "@/web/common/LoadingWrapper";

// Containers
import { HotelRoomManagementHeader } from "@/dux/hotelRoomManagementHeader/hoteRoomManagementHeader";
import { HotelRoomTypeSelection } from "@/dux/hotelRoomTypeSelection/hotelRoomTypeSelection";

// Actions
import { SET_HOTEL_ROOM_TYPES } from "@/dux/hotelRoomSelection/hotelRoomSelectionActions";

// Utils
import { createLoadingSelector } from "@/core/selectors/utils";

export const RoomSelectionPage = props => {
  const { componentId, isHidden, isLoading } = props;

  if (isHidden) {
    return null;
  }

  return (
    <Layout.Box id={componentId}>
      <Layout.Stack space="stack-space-12">
        <HotelRoomManagementHeader />
        <Layout.Center style={{ width: "25%" }}>
          <LoadingWrapper displayContainer="block" isLoading={isLoading}>
            <HotelRoomTypeSelection />
          </LoadingWrapper>
        </Layout.Center>
      </Layout.Stack>
    </Layout.Box>
  );
};

export const HotelRoomSelectionPage = connect(state => {
  return {
    componentId: "HotelRoomSelectionPage",
    isHidden: false,
    isLoading: createLoadingSelector([SET_HOTEL_ROOM_TYPES])(state),
  };
})(RoomSelectionPage);
