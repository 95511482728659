import { createSelector } from "reselect";

/**
 * @typedef {Object} state
 * @property {object} overbookingExceptionsPagination
 */

/**
 *  Selector to get the pagination for overbooking exceptions from the redux state
 *  @memberOf Selectors.overbookingExceptions
 *  @function
 *  @name selectOverbookingExceptionsPagination
 *  @param {Object} state - redux state
 *  @returns {Object}
 *  @example selectOverbookingExceptionsPagination(state)
 */
export const selectOverbookingExceptionsPagination = state => state.overbookingExceptionsPagination;

/**
 *  Selector to get the pagination count for overbooking exceptions from the redux state
 *  @memberOf Selectors.overbookingExceptions
 *  @function
 *  @name selectOverbookingExceptionsPaginationCount
 *  @param {Object} state - redux state
 *  @returns {string | number}
 *  @example selectOverbookingExceptionsPaginationCount(state)
 */
export const selectOverbookingExceptionsPaginationCount = createSelector(
  [selectOverbookingExceptionsPagination],
  pagination => {
    return pagination?.count;
  },
);

/**
 *  Selector to get the pagination page for overbooking exceptions from the redux state
 *  @memberOf Selectors.overbookingExceptions
 *  @function
 *  @name selectOverbookingExceptionsPaginationPage
 *  @param {Object} state - redux state
 *  @returns {string | number}
 *  @example selectOverbookingExceptionsPaginationPage(state)
 */
export const selectOverbookingExceptionsPaginationPage = createSelector(
  [selectOverbookingExceptionsPagination],
  pagination => {
    return pagination?.page;
  },
);

/**
 *  Selector to get the pagination total for overbooking exceptions from the redux state
 *  @memberOf Selectors.overbookingExceptions
 *  @function
 *  @name selectOverbookingExceptionsPaginationTotal
 *  @param {Object} state - redux state
 *  @returns {string | number}
 *  @example selectOverbookingExceptionsPaginationTotal(state)
 */
export const selectOverbookingExceptionsPaginationTotal = createSelector(
  [selectOverbookingExceptionsPagination],
  pagination => {
    return pagination?.total;
  },
);

/**
 *  Selector to get the pagination size for overbooking exceptions from the redux state
 *  @memberOf Selectors.overbookingExceptions
 *  @function
 *  @name selectOverbookingExceptionsPaginationSize
 *  @param {Object} state - redux state
 *  @returns {string | number}
 *  @example selectOverbookingExceptionsPaginationSize(state)
 */
export const selectOverbookingExceptionsPaginationSize = createSelector(
  [selectOverbookingExceptionsPagination],
  pagination => {
    return pagination?.size;
  },
);
