/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const petFavorabilityHotelActionTypes = {
  CREATE_PET_FAVORABILITY_HOTEL: "CREATE_PET_FAVORABILITY_HOTEL",
  CREATE_PET_FAVORABILITY_HOTEL_REQUEST: "CREATE_PET_FAVORABILITY_HOTEL_REQUEST",
  CREATE_PET_FAVORABILITY_HOTEL_SUCCESS: "CREATE_PET_FAVORABILITY_HOTEL_SUCCESS",
  CREATE_PET_FAVORABILITY_HOTEL_FAILURE: "CREATE_PET_FAVORABILITY_HOTEL_FAILURE",
  DELETE_PET_FAVORABILITY_HOTEL: "DELETE_PET_FAVORABILITY_HOTEL",
  DELETE_PET_FAVORABILITY_HOTEL_REQUEST: "DELETE_PET_FAVORABILITY_HOTEL_REQUEST",
  DELETE_PET_FAVORABILITY_HOTEL_SUCCESS: "DELETE_PET_FAVORABILITY_HOTEL_SUCCESS",
  DELETE_PET_FAVORABILITY_HOTEL_FAILURE: "DELETE_PET_FAVORABILITY_HOTEL_FAILURE",
  UPDATE_PET_FAVORABILITY_HOTEL: "UPDATE_PET_FAVORABILITY_HOTEL",
  UPDATE_PET_FAVORABILITY_HOTEL_REQUEST: "UPDATE_PET_FAVORABILITY_HOTEL_REQUEST",
  UPDATE_PET_FAVORABILITY_HOTEL_SUCCESS: "UPDATE_PET_FAVORABILITY_HOTEL_SUCCESS",
  UPDATE_PET_FAVORABILITY_HOTEL_FAILURE: "UPDATE_PET_FAVORABILITY_HOTEL_FAILURE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const createPetFavorabilityHotel = ({ customerKey, petId, formValues }) => ({
  type: petFavorabilityHotelActionTypes.CREATE_PET_FAVORABILITY_HOTEL,
  customerKey,
  petId,
  formValues,
});

export const createPetFavorabilityHotelRequest = () => ({
  type: petFavorabilityHotelActionTypes.CREATE_PET_FAVORABILITY_HOTEL_REQUEST,
});

export const createPetFavorabilityHotelSuccess = () => ({
  type: petFavorabilityHotelActionTypes.CREATE_PET_FAVORABILITY_HOTEL_SUCCESS,
});

export const createPetFavorabilityHotelFailure = ({ error }) => ({
  type: petFavorabilityHotelActionTypes.CREATE_PET_FAVORABILITY_HOTEL_FAILURE,
  error,
});

export const updatePetFavorabilityHotel = ({ customerKey, petId, formValues }) => ({
  type: petFavorabilityHotelActionTypes.UPDATE_PET_FAVORABILITY_HOTEL,
  customerKey,
  petId,
  formValues,
});

export const updatePetFavorabilityHotelRequest = () => ({
  type: petFavorabilityHotelActionTypes.UPDATE_PET_FAVORABILITY_HOTEL_REQUEST,
});

export const updatePetFavorabilityHotelSuccess = () => ({
  type: petFavorabilityHotelActionTypes.UPDATE_PET_FAVORABILITY_HOTEL_SUCCESS,
});

export const updatePetFavorabilityHotelFailure = ({ error }) => ({
  type: petFavorabilityHotelActionTypes.UPDATE_PET_FAVORABILITY_HOTEL_FAILURE,
  error,
});

export default {
  createPetFavorabilityHotel,
  createPetFavorabilityHotelRequest,
  createPetFavorabilityHotelSuccess,
  createPetFavorabilityHotelFailure,
  updatePetFavorabilityHotel,
  updatePetFavorabilityHotelRequest,
  updatePetFavorabilityHotelSuccess,
  updatePetFavorabilityHotelFailure,
};
