import axios from "axios";
import {
  setRequestHeaders,
  handleAuthorizationRejection,
  setResponseRefreshToken,
} from "../../utils/authHelper";

/**
 * Axios Create configuration for the System services Booking API (/web/services/booking).
 *
 * @summary /web/services/booking.
 * @memberOf APIs.WebServicesBooking
 * @function
 * @name  bookingAPI
 * @type {axios.AxiosInstance}
 *
 * @example
 * return bookingAPI.post(url, params)
 * return bookingAPI.get(url, params)
 * return bookingAPI.put(url, params)
 */
export const bookingAPI = axios.create({
  baseURL: `${window.env.BASE_URL}${window.env.WEB_BOOKING_URI}`,
});

/**
 * Intercept the `x-refresh-session-token` value
 * - Note: This can be disabled by using the ENV flag below
 */
if (!window.env.DISABLE_API_AUTH_IN_DEV) {
  bookingAPI.interceptors.request.use(setRequestHeaders, handleAuthorizationRejection);
  bookingAPI.interceptors.response.use(setResponseRefreshToken, handleAuthorizationRejection);
}
