import { createSelector } from "reselect";
import { getStoreTimeZone } from "@/core/selectors/entitiesSelector";

export const getPreviousBookingTimeZone = (state) =>
  state.scheduleTrainingAppointment.previousBookingTimeZone;

export const getCachedTrainingAvailabilityWeeks = (state) =>
  state.scheduleTrainingAppointment.cachedTrainingAvailabilityWeeks;

export const getSelectedTimeSlot = (state) => state.scheduleTrainingAppointment.selectedTimeSlot;

export const getSelectedCustomerTimeZone = (state) =>
  state.scheduleTrainingAppointment.selectedCustomerTimeZone;

export const getCustomerTimeZone = createSelector(
  [getSelectedCustomerTimeZone, getStoreTimeZone, getPreviousBookingTimeZone],
  (customerTimeZone, storeTimeZone, previousBookingTimeZone) => {
    const timeZone = customerTimeZone || previousBookingTimeZone || storeTimeZone;
    return timeZone;
  },
);
