import React from "react";
import styled from "styled-components";
import { color } from "../styles/theme";
import LoadingWrapper from "../LoadingWrapper";

const Heading = styled.h4`
  cursor: pointer;
  background-color: ${props => (props.open ? color.deepSkyBlue : color.borderGrey)};
  color: ${props => (props.open ? color.victorStone : color.tChalla)};
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  text-align: center;
  margin-bottom: 0;
  padding: 15px;
`;

const Content = styled.div`
  transition: all 0.3s;
  padding: ${props => (props.open ? "30px" : "0 30px")};
  max-height: ${props => (props.open ? "100%" : "0")};
  opacity: ${props => (props.open ? "1" : "0")};
  border: 1px solid ${color.borderGrey};
  border-top: none;
  overflow: hidden;
`;

const InnerContent = styled.div`
  min-height: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function AccordionComponent({
  isOpen,
  componentID,
  isHidden,
  isLoading,
  onClick,
  label,
  children
}) {
  return !isHidden ? (
    <Wrapper id={componentID}>
      <Heading onClick={() => onClick()} open={isOpen}>
        {label}
      </Heading>
      <Content open={isOpen}>
        <LoadingWrapper isLoading={isLoading} showSpinner={false} background={false}>
          <InnerContent>{!isLoading && children}</InnerContent>
        </LoadingWrapper>
      </Content>
    </Wrapper>
  ) : null;
}
