export const getChangeStatusPayload = ({
  petIds = [],
  status = "",
  shouldUndo = false,
  cancellationReason = "",
}) => {
  const pets = petIds?.map(petId => {
    return {
      petKey: petId,
      setEngagementsStatuses: {
        revertToPreviousStatus: shouldUndo,
        status,
        cancellationReason,
      },
    };
  });

  return { pets };
};
