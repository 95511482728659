import { connect } from "react-redux";
import { getCustomerFullName } from "../../core/selectors/entitiesSelector";
import AppHeader from "./AppHeader";
import { headerConfigConstants } from "../../core/constants/headerConfigConstants";
import { getJobRole } from "../../core/selectors/persistentSelectors";

// TODO: move to AppHeader.js so all containers are co-located with the component.
const mapStateToProps = (state, ownProps) => ({
  title: ownProps.isCustomerLoading
    ? "Loading.."
    : `${getCustomerFullName(state, ownProps) || "New pet parent"}'s profile`,
  config: headerConfigConstants.APPOINTMENT,
  jobRole: getJobRole(state, ownProps)
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeader);
