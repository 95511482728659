import "react-dates/initialize";
import React, { Component } from "react";
import { CalendarDay, SingleDatePicker } from "react-dates";
import moment from "moment";
import styled from "styled-components";
import "react-dates/lib/css/_datepicker.css";

import {
  DatePickerWrapper,
  StyledCalendar,
  RightSideArrow,
  MonthYearDropdowns,
  StyledSelect,
  LabelWrapper,
  TitleContainer,
  Title,
} from "./styles/calendarStyle";

import ExpiredImage from "../../assets/icons/expired.svg";
import createRangedOptions from "../../core/utils/rangeUtils/createRangedOptions";
import {
  formatCalendarDateMoment,
  formatDayNameMonthDayYear,
} from "../../core/utils/dateUtils/formatDateTime";
import isExpired from "../../core/utils/dateUtils/isExpired";
import isWithinCurrentWeek from "../../core/utils/dateUtils/withinIsoWeek";

const Icon = styled.img`
  vertical-align: bottom;
  margin-left: 0.5em;
`;

const CalendarDropDown = styled.div``;

const currYear = moment().year();
const YEARS_OPTIONS = createRangedOptions(currYear, currYear + 10);

const MONTHS_OPTIONS = moment.months().map((label, value) => ({ label, value }));

export default class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      textValue: props.selectedDate,
      yearsOptions: props.yearsOptions,
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedDate } = this.props;

    if (selectedDate !== prevProps.selectedDate) {
      this.selectedDate = moment(selectedDate);

      this.onTextChange(this.selectedDate);
    }
  }

  setCalendarEnvironment = () => {
    moment.updateLocale("en", {
      week: {
        dow: 1,
      },
    });
  };

  getMonthLabel = monthIndex => MONTHS_OPTIONS.find(month => month.value === monthIndex)?.label;

  // TODO: Refactor non view specific logic out of the view layer to where it belongs, like a container for example
  onDatesChange = selectedDate => {
    const { isOutsideRange, setWeekNumber, loadItinerariesBySelectedDate } = this.props;

    // If range matters for this calendar, check if selected date is in range.
    const isInRange = isOutsideRange && selectedDate ? !isOutsideRange(selectedDate) : true;

    // TODO: shouldLoadItinerariesBySelectedDate logic to determine if loadItinerariesBySelectedDate should fire should be removed from the view and determined elsewhere picking a date my not relate to an itinerary
    const shouldLoadItinerariesBySelectedDate =
      selectedDate && !isWithinCurrentWeek(selectedDate) && loadItinerariesBySelectedDate;
    const shouldSetSelectedDate = selectedDate && moment(selectedDate) && isInRange;

    // We need to load a new batch of itineraries when a new date is clicked, however we only
    // want it to launch if not within the current week as the itineraries have already loaded.
    // We also do not want to reload itinerarires if if there is no loadItinerariesBySelectedDate
    // handler fucntion referenced.
    if (shouldLoadItinerariesBySelectedDate) {
      loadItinerariesBySelectedDate(selectedDate);
    }

    if (shouldSetSelectedDate) {
      this.selectedDate = selectedDate;

      if (setWeekNumber) {
        setWeekNumber(moment(selectedDate).week());
      }
    }
  };

  onClose = () => {
    const { selectDate, selectedDate, closeCalendar } = this.props;

    this.setState({ isVisible: false, textValue: this.selectedDate || selectedDate });

    if (
      this.selectedDate &&
      this.selectedDate.isValid() &&
      formatCalendarDateMoment(this.selectedDate) !== selectedDate
    ) {
      selectDate(this.selectedDate);
    } else {
      closeCalendar && closeCalendar();
    }
  };

  isDayBlocked = day => {
    if (day != null) {
      const date = moment(day._d).format("DD MMM, YYYY");
      const sdate = null;

      if (sdate === date) {
        return true;
      }
    }

    return undefined;
  };

  onTextChange = value => {
    this.setState({ textValue: value });
  };

  defaultIsOutsideRange = day => day.isBefore(moment().subtract(8, "days"));

  componentDidMount = () => {
    this.setCalendarEnvironment();
  };

  onCalendarOpen = e => {
    // eslint-disable-next-line react/destructuring-assignment
    this.setState({ isVisible: !this.state.isVisible });
    e.preventDefault();
  };

  captionRenderer = ({ month, onMonthSelect, onYearSelect }) => {
    const weekdays = moment.weekdaysMin(true);
    return (
      <div>
        <div className="DayPicker_weekHeader">
          <ul className="DayPicker_weekHeader_ul">
            {weekdays.map(day => (
              <li key={day} className="DayPicker_weekHeader_li">
                <small>{day}</small>
              </li>
            ))}
          </ul>
        </div>
        {this.renderMonthElement({ month, onMonthSelect, onYearSelect })}
      </div>
    );
  };

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    const yearsOptions = this.state.yearsOptions || YEARS_OPTIONS;
    return (
      <MonthYearDropdowns>
        <StyledSelect
          menuListMaxHeight={200}
          value={{ value: month.month(), label: this.getMonthLabel(month.month()) }}
          isSearchable={false}
          options={MONTHS_OPTIONS}
          onChange={e => {
            onMonthSelect(month, e.value);
          }}
        />
        <StyledSelect
          menuListMaxHeight={200}
          defaultValue={yearsOptions.find(({ value }) => month.year() === value) || null}
          value={yearsOptions.find(({ value }) => month.year() === value) || null}
          options={yearsOptions}
          onChange={e => {
            onYearSelect(month, e.value);
          }}
        />
      </MonthYearDropdowns>
    );
  };

  renderChildrenOrLabel = () => {
    const { isVisible } = this.state;
    const {
      children,
      selectedDate,
      displayFormat,
      labelFontSize,
      labelFontWeight,
      hasIcon,
      name,
    } = this.props;

    if (isVisible) {
      return undefined;
    }

    return (
      children || (
        <LabelWrapper
          labelFontSize={labelFontSize}
          labelFontWeight={labelFontWeight}
          error={hasIcon && isExpired(selectedDate)}
        >
          <RightSideArrow tabIndex="0" name={name}>
            {displayFormat
              ? moment(selectedDate).format(displayFormat)
              : formatDayNameMonthDayYear(selectedDate)}
          </RightSideArrow>
          {hasIcon && isExpired(selectedDate) && <Icon src={ExpiredImage} />}
        </LabelWrapper>
      )
    );
  };

  render() {
    const {
      margin,
      disabled,
      className,
      numberOfMonths = 2,
      selectedDate,
      calendarTitle,
      openDirection = "down",
      isOutsideRange,
      isHidden,
    } = this.props;
    const { isVisible, textValue } = this.state;
    const sdate = null;

    if (isHidden) {
      return null;
    }

    // We need an escape hatch in order to be able to opt-out of disabling dates.
    // For example, some services not need any restriction on which date you select.
    // Therefore, we don't want to automatically default to defaultIsOutsideRange if
    // the isOutsideRange function returns false. Using a ternary operator here rather than
    // an automatic default gives us the flexibility to opt-in/out of the default behavior.
    const patchedIsOutsideRange = date => {
      return isOutsideRange ? isOutsideRange(date) : this.defaultIsOutsideRange(date);
    };

    return (
      <StyledCalendar margin={margin} disabled={disabled} className={className}>
        <TitleContainer>
          {calendarTitle && <Title>{calendarTitle}</Title>}

          <CalendarDropDown onClick={e => this.onCalendarOpen(e)}>
            {this.renderChildrenOrLabel()}
          </CalendarDropDown>
        </TitleContainer>

        <DatePickerWrapper
          onChange={e => {
            this.onTextChange(e.target.value);
          }}
          hideInput={!isVisible}
        >
          <SingleDatePicker
            small
            openDirection={openDirection}
            anchorDirection="right"
            key={this.day}
            date={!textValue ? moment(new Date()) : selectedDate && moment(selectedDate)}
            displayFormat="MM/DD/YYYY"
            focused={isVisible}
            // ref={ component => (this.Calendar = component) } // ??? What is this line actually supposed to be doing - removed it as it violates esLint and does not seem to actually do anything  ???
            isOutsideRange={patchedIsOutsideRange}
            onDateChange={this.onDatesChange}
            onFocusChange={() => {}}
            onClose={this.onClose}
            numberOfMonths={numberOfMonths}
            isDayBlocked={this.isDayBlocked}
            hideKeyboardShortcutsPanel
            renderMonthElement={this.captionRenderer}
            horizontalMonthPadding={60}
            renderCalendarDay={props => this.editCalendarDay(props, sdate)}
          />
        </DatePickerWrapper>
      </StyledCalendar>
    );
  }

  //* ****************  THIS IS THE CUSTOM CODE TO MAKE THE CALENDAR NEW LOOK AND FEEL */
  cheeckSingle = props => {
    const { selectedDate } = this.props;
    const { day, modifiers } = props;

    if (day != null) {
      const date = formatCalendarDateMoment(moment(day._d));
      if (date === selectedDate) {
        modifiers && modifiers.delete("selected");
        modifiers && modifiers.delete("highlighted-calendar");
        modifiers && modifiers.add("highlighted-calendar");
        return <CalendarDay {...props} modifiers={modifiers} />;
      }
      modifiers && modifiers.delete("highlighted-calendar");
      modifiers && modifiers.delete("selected");
      return <CalendarDay {...props} />;
    }
    modifiers && modifiers.delete("selected");

    return <CalendarDay {...props} />;
  };

  cheeckWeekly = props => {
    const { selectedDate } = this.props;
    const { day, modifiers } = props;
    const date = formatCalendarDateMoment(moment(day._d));

    if (day._d != null) {
      if (
        moment(day._d).isBefore(
          moment(selectedDate)
            .clone()
            .endOf("week"),
        ) &&
        moment(day._d).isAfter(
          moment(selectedDate)
            .clone()
            .startOf("week"),
        ) &&
        date !== selectedDate
      ) {
        return this.returnDay(props);
      } else if (
        formatCalendarDateMoment(moment(day._d)) === formatCalendarDateMoment(moment(selectedDate))
      ) {
        modifiers && modifiers.delete("highlighted-calendar");
        modifiers && modifiers.add("highlighted-calendar");
        return <CalendarDay {...props} modifiers={modifiers} />;
      }
      modifiers && modifiers.delete("highlighted-calendar");
      modifiers && modifiers.delete("selected");
      return <CalendarDay {...props} modifiers={modifiers} />;
    }
  };

  editCalendarDay = (props, sdate) => {
    const { selectedDate } = this.props;
    const { day } = props;

    if (day != null && selectedDate != null && this.props.isWeekly === true) {
      return this.cheeckWeekly(props, sdate);
    }

    return this.cheeckSingle(props, sdate);
  };

  returnDay = props => {
    const { modifiers } = props;
    modifiers && modifiers.delete("highlighted-calendar");
    modifiers && modifiers.delete("selected");
    modifiers && modifiers.add("selected");
    return <CalendarDay {...props} modifiers={modifiers} />;
  };

  //* ****************  END OF CUSTOM STYLE THIS IS ALL THE CUSTOM UI TO MAKE THE CALENDAR LOOK ACCORDING TO TICKET */
}
