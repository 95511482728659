// https://developers.google.com/maps/documentation/javascript/geocoding

let geocoder: google.maps.Geocoder;
export const initGoogleGeocode = () => {
  if (!geocoder) geocoder = new google.maps.Geocoder();
};

export const googleGeocode = async (geocodeRequest: google.maps.GeocoderRequest) => {
  initGoogleGeocode();
  return geocoder.geocode(geocodeRequest, (results: any, status: String) => {
    return { results, status };
  });
};

export const getGeoCodingByAddress = async (address: string) => {
  // the address will be brought in delimited by spaces, however those space need to be url-escaped to %20 for Google's api
  const escapedAddress = address.replace(" ", "%20");

  return googleGeocode({ address: escapedAddress });
};

export const getGeoCodingByPostalCode = async (zipCode: string) => {
  return googleGeocode({ componentRestrictions: { postalCode: zipCode } });
};

export const getGeoCodingByCityAndState = async (cityState: string) => {
  // the city state will be brought in delimited by spaces or comma or both, however those space/commas need to be url-escaped to %20 for Google's api
  const escapedCityState = cityState.replace(/,|,\s|\s/, "%20");

  return googleGeocode({ componentRestrictions: { locality: escapedCityState } });
};
