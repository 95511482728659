import { connect } from "react-redux";
import SmallPillButton from "../../../_components/buttons/SmallPillButton";
import { filterByAllArrivals, filterByDayCampArrivals } from "./hotelArrivalsFilterButtonActions";
import { getHotelArrivalsFilterBy } from "./hotelArrivalsPetServiceSelector";

const mapStateToProps = state => {
  const filterBy = getHotelArrivalsFilterBy(state);

  return {
    isHidden: false,
    componentID: "SmallPillButton_HotelArrivalsDayCampFilterButton",
    label: "Day Camp",
    selected: filterBy.dayCamp,
    disabled: false,
    filterBtnStatus: filterBy.dayCamp,
    isAllOthersSelected:
      filterBy.overnight && filterBy.dayCare && filterBy.attentionNeeded && filterBy.unassignedPet,
  };
};

const mapDispatchToProps = dispatch => ({
  handleFilterByDayCampArrivals: ({ filterBtnStatus, isAllOthersSelected }) => {
    if (filterBtnStatus) {
      // toggle off
      dispatch(filterByDayCampArrivals({ filterBtnStatus: !filterBtnStatus }));

      // toggle off all button as well
      dispatch(filterByAllArrivals({ filterBtnStatus: false }));
    } else {
      dispatch(filterByDayCampArrivals({ filterBtnStatus: !filterBtnStatus }));

      if (isAllOthersSelected) {
        dispatch(filterByAllArrivals({ filterBtnStatus: true }));
      }
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleClick: () =>
    propsFromDispatch.handleFilterByDayCampArrivals({
      filterBtnStatus: propsFromState.filterBtnStatus,
      isAllOthersSelected: propsFromState.isAllOthersSelected,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SmallPillButton);
