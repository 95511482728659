/**
 * Responsible for abstracting out the default state for every modal. However additional state can be added via
 * the initState parameter
 *
 * @export modalInitState
 * @param {*} [initState={}] - optional param to add additional state to the modal object.
 * @returns {*} - state
 * @example modalInitState({foo: 'bar"})
 */
export default function modalInitState(initState = {}) {
  return {
    ...initState,
    show: false,
    modalType: initState.modalType || "",
    priority: 0
  };
}
