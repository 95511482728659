import { connect } from "react-redux";
import { columnHeaders } from "core/constants/dashboardContants";
import HotelCheckedOutPetCardContainer from "dux/hotelCheckedOut/columnList/HotelCheckedOutPetCardContainer";
import ScrollablePetCardList from "../../_components/petCard/ScrollablePetCardList";
import { getHotelCheckedOutAppointments } from "./hotelCheckedOutSelector";
import { getCheckedOutColumnVisibility } from "../columnHeader/columnSwitch/hotelCheckedOutColumnToggleSelector";

const mapStateToProps = state => ({
  componentId: "scrollableCardList__hotelCheckedOut",
  appointments: getHotelCheckedOutAppointments(state),
  isHidden: getCheckedOutColumnVisibility(state),
  diComp: {
    [columnHeaders.CHECKED_OUT]: HotelCheckedOutPetCardContainer,
  },
  compName: columnHeaders.CHECKED_OUT,
});

export default connect(mapStateToProps)(ScrollablePetCardList);
