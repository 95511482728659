import {
  TOGGLE_COMPLETE_TRAINING_APPOINTMENT_MODAL,
  TOGGLE_CANCEL_TRAINING_APPOINTMENT_MODAL,
  TOGGLE_RESCHEDULE_TRAINING_APPOINTMENT_MODAL,
  SET_RESCHEDULE_ATTENDEE_ID,
  TOGGLE_CANCEL_TRAINING_APPOINTMENT_FLASH_MESSAGE,
  TOGGLE_RESCHEDULE_TRAINING_APPOINTMENT_FLASH_MESSAGE,
  TOGGLE_RESEND_MEETING_LINK_FLASH_MESSAGE,
  RESET_MANAGE_TRAINING_CLASS_PAGE,
  SET_RESCHEDULE_CLASS_SESSION_ID,
  RESET_RESCHEDULE_CLASS_SESSION_ID,
} from "./manageTrainingClassPageActions";

const initialState = {
  isCompleteTrainingAppointmentModalOpen: false,
  isCancelTrainingAppointmentModalOpen: false,
  isRescheduleTrainingAppointmentModalOpen: false,
  isCancelTrainingAppointmentFlashMessageOpen: false,
  isRescheduleTrainingAppointmentFlashMessageOpen: false,
  isResendMeetingLinkFlashMessageOpen: false,
  attendeeId: null,
  rescheduleClassSessionId: null,
};

export default function manageTrainingClassPageReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_COMPLETE_TRAINING_APPOINTMENT_MODAL:
      return {
        ...state,
        isCompleteTrainingAppointmentModalOpen: !state.isCompleteTrainingAppointmentModalOpen,
      };
    case TOGGLE_CANCEL_TRAINING_APPOINTMENT_MODAL:
      return {
        ...state,
        isCancelTrainingAppointmentModalOpen: !state.isCancelTrainingAppointmentModalOpen,
      };
    case TOGGLE_RESCHEDULE_TRAINING_APPOINTMENT_MODAL:
      return {
        ...state,
        isRescheduleTrainingAppointmentModalOpen: !state.isRescheduleTrainingAppointmentModalOpen,
      };
    case SET_RESCHEDULE_ATTENDEE_ID:
      return {
        ...state,
        attendeeId: action.attendeeId,
      };
    case TOGGLE_CANCEL_TRAINING_APPOINTMENT_FLASH_MESSAGE:
      return {
        ...state,
        isCancelTrainingAppointmentFlashMessageOpen: !state.isCancelTrainingAppointmentFlashMessageOpen,
      };
    case TOGGLE_RESCHEDULE_TRAINING_APPOINTMENT_FLASH_MESSAGE:
      return {
        ...state,
        isRescheduleTrainingAppointmentFlashMessageOpen: !state.isRescheduleTrainingAppointmentFlashMessageOpen,
      };
    case TOGGLE_RESEND_MEETING_LINK_FLASH_MESSAGE:
      return {
        ...state,
        isResendMeetingLinkFlashMessageOpen: !state.isResendMeetingLinkFlashMessageOpen,
      };
    case SET_RESCHEDULE_CLASS_SESSION_ID:
      return {
        ...state,
        rescheduleClassSessionId: action.classSessionId,
      };

    case RESET_RESCHEDULE_CLASS_SESSION_ID:
      return {
        ...state,
        rescheduleClassSessionId: initialState.rescheduleClassSessionId,
      };

    case RESET_MANAGE_TRAINING_CLASS_PAGE:
      return initialState;

    default:
      return state;
  }
}
