import { connect } from "react-redux";
import { getStatesOptions } from "../../../../../../../core/selectors/enumsSelectors";
import { getShowVetSearchResults } from "../../../../../../../core/selectors/vetsSelectors";
import vetsActionCreators from "../../../../../../../core/actionCreators/vetsActionCreators";
import vetsActionTypes from "../../../../../../../core/actionTypes/vetsActionTypes";
import petProfileActionCreators from "../../../../../../../core/actionCreators/petProfileActionCreators";
import SearchVetModalComponent from "./SearchVetModalComponent";
import { createLoadingSelector } from "../../../../../../../core/selectors/utils";
import removeEmpty from "../../../../../../../core/utils/objectUtils/removeEmpty";
import { setSearchResultsToDisplay } from "@/core/actionCreators/searchActionCreators";
import { searchConstants } from "@/core/constants/searchConstants";

const mapStateToProps = state => ({
  STATE_DROPDOWN_OPTIONS: getStatesOptions(state),
  showVetSearchResults: getShowVetSearchResults(state),
  isLoading: createLoadingSelector([
    vetsActionTypes.CREATE_VET,
    vetsActionTypes.LOAD_VET_SEARCH_RESULTS,
  ])(state),
});

const mapDispatchToProps = dispatch => ({
  showModal: modalType => dispatch(petProfileActionCreators.showPetProfileModal(modalType)),
  submitForm: ({ formValues }) => {
    const formFieldsToSubmit = removeEmpty(formValues);
    dispatch(vetsActionCreators.showVetSearchResults());
    dispatch(
      vetsActionCreators.loadVetsSearchResults({
        formValues: formFieldsToSubmit,
      }),
    );
    dispatch(setSearchResultsToDisplay({ searchResultsToDisplay: searchConstants.VET }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchVetModalComponent);
