import React from "react";
import styled from "styled-components";

const Label = styled.span`
  font-weight: bold;
`;

export default function EligiblePromotionsTextComponentBGM({
  isHidden,
  componentID,
  label,
  firstName,
  reason,
}) {
  if (isHidden) {
    return null;
  }
  return (
    <div id={componentID}>
      <Label>{label}</Label> {`${firstName} ${reason}`}
    </div>
  );
}
