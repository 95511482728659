import { connect } from "react-redux";
import { history } from "@/dux/utils/browser/browserHistory";
import { getFirstTimeCustomerInfo } from "@/dux/bgm/ftco/ftcoSelectors";
import { hideCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import getFTCOWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import CommonButton from "@/web/common/commonButton";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  const { isFirstTimeCustomer } = getFirstTimeCustomerInfo(state, { customerKey });
  return {
    isHidden: getFTCOWorkflowFeatureFlagHidden(state) === false && isFirstTimeCustomer === true,
    componentId: "checkInOutMultiPet__commonButton",
    label: "Done",
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClick: () => {
      history.push("/dashboard");
      dispatch(hideCheckInOutModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonButton);
