import verifyStoreNumber from "../validationUtils/storeNumberValidation";
import verifyName from "../validationUtils/nameValidation";
import verifyEmail from "../validationUtils/emailValidation";
import verifyPhoneNumber from "../validationUtils/phoneNumberValidation";
import convertStringToNumbers from "../stringManipulationUtils/stringToNumberConversion";

/**
 * Validates the Search Field to verify type, e.g. email, phone, name or store. If the search
 * criteria is not valid, it will return an error.
 *
 * @param {*} { searchFieldType, searchFieldValue }
 * @returns error object
 */
function validateCustomerSearchField({ searchFieldType, searchFieldValue }) {
  // Verify an actual character was entered in the search field
  if (searchFieldValue) {
    // Check Phone Number Type
    if (
      searchFieldType === "phoneNumber" &&
      !verifyPhoneNumber(convertStringToNumbers(searchFieldValue))
    ) {
      // if more than 11 digits
      if (searchFieldValue.match(/\d{11,}/)) {
        return validationErrorMessage("Phone number search is not valid, too many characters.");
      }

      // If there is less than 10 digits
      if (searchFieldValue.match(/\d{0,9}/)) {
        return validationErrorMessage(
          "Partial search is not valid. Please enter full search criteria to get results",
        );
      }
    }

    // Check Email Type
    if (searchFieldType === "email" && !verifyEmail(searchFieldValue?.trim())) {
      return validationErrorMessage("Email address search is not valid.");
    }

    // Check Name Type
    if (searchFieldType == "name" && !verifyName(searchFieldValue)) {
      return validationErrorMessage("You must enter a name, it must be at least one character");
    }

    // Check Store Type
    if (searchFieldType == "store" && !verifyStoreNumber(searchFieldValue)) {
      return validationErrorMessage(
        "You must enter a Store Number, it must be at least one number and up to 4",
      );
    }
  } else {
    return validationErrorMessage("No character was entered");
  }
  return { validationError: false };
}

/**
 * Validation error message
 *
 * @param {string} errorMessage
 * @returns error object
 */
function validationErrorMessage(errorMessage) {
  return {
    validationError: true,
    message: errorMessage,
  };
}

export default validateCustomerSearchField;
