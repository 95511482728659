import { connect } from "react-redux";
import { loginWithOkta } from "../../core/actionCreators/authActionCreators";
import { routePaths } from "../../core/constants/routePaths";
import { getRedirectToForgotPin } from "../../core/selectors/persistentSelectors";
import { systemName } from "./constants/setSystemTypeConstants";
import CommonButton from "../common/commonButton";

const mapStateToProps = state => {
  const redirectToForgotPin = getRedirectToForgotPin(state);
  return {
    redirectToForgotPin,
    componentID: "commonButton-loginHotelButton",
    width: "15ch",
    label: `Login ${systemName.HOTEL}`,

    isHidden: false,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchLogin: values => {
    const redirectRoute = values.redirectToForgotPin ? routePaths.FORGOT_PIN : routePaths.DASHBOARD;
    dispatch(
      loginWithOkta({
        route: redirectRoute,
        storeNumber: values.storeNumber,
        systemType: systemName.HOTEL,
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () => {
    propsFromDispatch.dispatchLogin({
      storeNumber: ownProps.storeNumber,
      redirectToForgotPin: propsFromState.redirectToForgotPin,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
