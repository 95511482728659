import React from "react";
import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";
import { BookAppointmentButtons } from "@/dux/bookAppointment/BookAppointmentButtons";
import { BookAppointmentButtonsWithStoreSearchFlyout } from "./BookAppointmentButtonsWithStoreSearchFlyout";

/** ----------------------------------------------------------------------- **\
 COMPONENT - Show a book appointment button that redirects to the booking
  page, or a drop down to enter a store number if no store number
  is in the Redux-Store or Local Storage.
\** ----------------------------------------------------------------------- * */
function BookAppointmentButtonsWrapperComponent({ isHidden, componentId }) {
  if (!isHidden) {
    return (
      <Layout.Box data-testid={componentId} id={componentId}>
        <BookAppointmentButtons />
        <BookAppointmentButtonsWithStoreSearchFlyout />
      </Layout.Box>
    );
  }

  return null;
}

export const BookAppointmentButtonsWrapper = connect(() => ({
  isHidden: false,
  componentId: "BookAppointmentButtonComponent",
}))(BookAppointmentButtonsWrapperComponent);
