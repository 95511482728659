import { connect } from "react-redux";

import { columnHeaders } from "../../../../core/constants/dashboardContants";
import ListFilterButtons from "../../../_components/buttons/ListFilterButtons";
import {
  HotelAllFilterBtnCompNames,
  HotelAttentionNeededFilterBtnCompNames,
  HotelUnassignedPetFilterBtnCompNames,
} from "../../../_constants/dynamicComponentNames";

const mapStateToProps = () => ({
  isHidden: false,
  componentID: "ListFilterButtons__HotelOverNight",
  compName: columnHeaders.OVERNIGHT,
  filters: [
    HotelAllFilterBtnCompNames,
    HotelAttentionNeededFilterBtnCompNames,
    HotelUnassignedPetFilterBtnCompNames,
  ],
});

export default connect(mapStateToProps)(ListFilterButtons);
