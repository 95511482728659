import { put, call, select } from "redux-saga/effects";
import { updateCustomerProfilePartial } from "core/services/systemServicesBooking/profile/customerProfile";
import { getPrismCustomerProfile } from "../petParentProfile/selectors/getPrismCustomerProfileSelector";
import {
  updateCustomerSuccess,
  loadCustomerFailure,
} from "core/actionCreators/customersActionCreators";
import removeEmpty from "@/core/utils/objectUtils/removeEmpty";

// eslint-disable-next-line import/prefer-default-export
export function* updatePrismCustomerProfile({ customerKey, isVip, psaOnFile }) {
  try {
    const prismCustomerProfile = yield select(getPrismCustomerProfile, { customerKey });

    const data = removeEmpty({
      customerKey,
      isVip,
      psaOnFile,
    });

    yield call(updateCustomerProfilePartial, { customerKey, data });

    yield put(
      updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          prismCustomer: {
            ...prismCustomerProfile,
            ...data,
          },
        },
      }),
    );

  } catch (error) {
    yield put(loadCustomerFailure);
  }
}