import { createSelector } from "reselect";
import { getCustomer } from "./entitiesSelector";
import { getUiWeb } from "./uiSelector";
import normalizeFormInput from "../utils/normalizeUtils/normalizeFormInput";

export const getPetParentAgents = createSelector(
  [getCustomer],
  customer => (customer && customer.agents) || []
);

export const getPetParentAgent = createSelector(
  [getPetParentAgents, (_, props) => props],
  (agents, props) =>{
    const agent = agents.find(agent => agent.agentId === props.agentId);
    return agent;
  }
);

export const getPetParentAgentFormData = createSelector([getPetParentAgent], agent => ({
  firstName: normalizeFormInput(agent, "firstName"),
  lastName: normalizeFormInput(agent, "lastName"),
  phoneNumber: normalizeFormInput(agent, "phoneNumber"),
  email: normalizeFormInput(agent, "email"),
  emergencyDecisionFlag: normalizeFormInput(agent, "emergencyDecisionFlag", false),
  dropPickFlag: normalizeFormInput(agent, "dropPickFlag", false),
  reservationFlag: normalizeFormInput(agent, "reservationFlag", false),
  agentId: normalizeFormInput(agent, "agentId")
}));

export const getIsAddAgentFormShown = createSelector(
  [getUiWeb],
  uiWeb => uiWeb.agents.isAddAgentFormShown
);

export const getAgentToDelete = createSelector([getUiWeb], uiWeb => uiWeb.agents.agentId);

export const getCustomerToDelete = createSelector([getUiWeb], uiWeb => uiWeb.agents.customerKey);

export const getIsAddAgentButtonShown = createSelector(
  [getPetParentAgents, getIsAddAgentFormShown],
  (agents, isAddAgentFormShown) => !isAddAgentFormShown && !!agents.length
);
