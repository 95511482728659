import React, { useEffect } from "react";
import { Heading } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutCenter } from "@/layout/center/Center";
import { connect } from "react-redux";
import { HotelRoomTypeColumn } from "dux/hotelRoomCapacityModal/hotelRoomTypeColumns/hotelRoomTypeColumn";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import moment from "moment";
import { loadHotelRoomTypes } from "dux/hotelRoomSelection/hotelRoomSelectionActions";
import { getHotelRoomTypes } from "dux/hotelRoomTypes/hotelRoomTypesActions";
import { selectCombinedRoomTypes } from "dux/hotelRoomTypes/hotelRoomTypesSelectors";

export const RoomCapacityDetails = props => {
  // Properties
  const { componentId, isHidden, mainTitle, secondaryTitle, roomTypes } = props;

  // Handlers
  const { dispatchGetHotelRoomTypes, loadRoomSuspensions } = props;

  useEffect(() => {
    dispatchGetHotelRoomTypes();
    loadRoomSuspensions();
  }, []);

  if (isHidden) {
    return null;
  }

  return (
    <LayoutStack id={componentId} space={"scale-G1"}>
      {/* --- TITLE COPY ------------------------------------------------------------------------*/}
      <LayoutBox>
        <LayoutStack>
          <LayoutCenter>
            <Heading id={`${componentId}__mainTitle`} size="title" tagName={"h2"}>
              {mainTitle}
            </Heading>
          </LayoutCenter>

          <LayoutCenter>
            <Heading id={`${componentId}__secondaryTitle`} size="headline" tagName={"h2"}>
              {secondaryTitle}
            </Heading>
          </LayoutCenter>
        </LayoutStack>
      </LayoutBox>

      {/* --- ROOM COLUMNS ----------------------------------------------------------------------*/}
      <LayoutBox style={{ overflowX: "auto", overflowY: "hidden" }} padding={"scale-0"}>
        <LayoutCluster style={{ alignItems: "flex-start", flexWrap: "nowrap" }}>
          {roomTypes.map(roomType => (
            <HotelRoomTypeColumn roomType={roomType} key={roomType.roomName} />
          ))}
        </LayoutCluster>
      </LayoutBox>
    </LayoutStack>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const HotelRoomCapacityDetails = connect(
  state => {
    const date = moment(getSelectedDate(state)).format(`dddd, MMMM Do, YYYY`);
    const roomTypes = selectCombinedRoomTypes(state);

    return {
      componentId: "HotelRoomCapacityDetails",
      mainTitle: "PetsHotel Room Usage",
      secondaryTitle: `Schedule for ${date}`,
      roomTypes,
    };
  },
  dispatch => {
    return {
      dispatchGetHotelRoomTypes: () => {
        dispatch(getHotelRoomTypes());
      },
      loadRoomSuspensions: () => {
        // this action calls the suspensions endpoint
        dispatch(loadHotelRoomTypes());
      },
    };
  },
)(RoomCapacityDetails);
