import { jobRoleConstants } from "core/constants/jobRoleConstants";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import compareStrings from "core/utils/stringUtils/compareStrings";

/**
 * Determines whether the hotel can be shown based on the given job role and capabilities.
 * @memberof helpers.jobRoles
 * @function
 * @param {string} jobRole - The job role of the user e.g. associate, src, manager.
 * @param {Array} capabilities - The capabilities of the app.
 * @return {boolean} - True if the hotel can be shown, false otherwise.
 */
export const jobRoleAllowsForHotel = (jobRole, capabilities) => {
  if (compareStrings(jobRole, jobRoleConstants.SRC)) {
    return !!capabilities?.find(item => item.hotelSRC);
  }

  if (compareStrings(jobRole, jobRoleConstants.MANAGER)) {
    return !!capabilities?.find(item => item.hotelManager);
  }

  if (compareStrings(jobRole, jobRoleConstants.ASSOCIATE)) {
    return !!capabilities?.find(item => item.hotelAssociate);
  }

  return false;
};

/**
 * Determines if the system type and flow should be set to salon.
 * @memberof helpers.jobRoles
 * @function
 * @param {Object} options - The options for setting system type and flow.
 * @param {string} options.systemType - The current system type.
 * @param {boolean} options.allowForHotel - Indicates if hotel is allowed.
 * @returns {boolean} - Indicates if the system type is changed to salon.
 */
export const setSystemAndFlowtoSalon = ({ systemType, allowForHotel }) => {
  return systemType === systemName.HOTEL && !allowForHotel;
};
