import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import DashboardTrainingMenuButtonContainer from "@/web/switchDashboardFromProfile/DashboardTrainingMenuButtonContainer";
import { searchConstants } from "../../../core/constants/searchConstants";
import { isCurrentEnvironment } from "../../../core/utils/envUtils";
import Img from "../../common/Img";
import MenuButton from "../../common/MenuButton";
import AppHeaderModal from "../../common/appHeader/appHeaderModalContainer";
import StyledPopover from "../../common/StyledPopover";
import { jobRoleConstants } from "../../../core/constants/jobRoleConstants";
import ScheduleImage from "../../../assets/icons/menu-icons-schedule.svg";
import LogoutImage from "../../../assets/icons/menu-icons-key-logout.svg";
import IconKeyImage from "../../../assets/icons/menu-icons-key.svg";
import LockImage from "../../../assets/icons/lock.svg";
import InitialsCircle from "../../common/InitialsCircle";
import { routePaths } from "../../../core/constants/routePaths";
import ProfileDropDownJobRoleIndicatorContainer from "./ProfileDropDownJobRoleIndicatorContainer";
import ChangeTestProfileContainer from "./ChangeTestProfileContainer";
import DashboardHotelMenuButtonContainer from "../../switchDashboardFromProfile/DashboardHotelMenuButtonContainer";
import DashboardSalonMenuButtonContainer from "../../switchDashboardFromProfile/DashboardSalonMenuButtonContainer";
import FoodMedAddOnActivityReports from "@/dux/foodMedAddOnActivityReports/FoodMedAddOnActivityReports";
import ServiceManagerHeaderContainer from "./ServiceManagerHeaderContainer";
import RoomManagerHeaderContainer from "./RoomManagerHeaderContainer";
import PetsHotelAdminHeaderContainer from "./PetsHotelAdminHeaderContainer";
import ReportsProfileBtn from "./ReportsProfileBtn";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

const SectionContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-width: 200px;
  min-height: 50px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.23);
  border: solid transparent;
  backdrop-filter: blur(3.4px);
  box-sizing: border-box;
  justify-content: space-between;
`;

const ProfileWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  font-family: Lato;
`;

const ProfileNameTitle = styled.div`
  margin: 0 10px 0 10px;
  color: #fff;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  user-select: none;
`;

const SectionWrapper = styled.section`
  width: 92%;
  margin-left: 7px;
  border-bottom: 1px solid #bec0c4;
  border-top: 1px solid #bec0c4;
  padding-top: 10px;
`;

const ProfileSectionWrapper = styled.section`
  width: 92%;
  margin-left: 7px;
  margin-top: 5px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  min-width: 250px;
  min-height: 100px;
  align-items: flex-start;
`;

const Divider = styled.hr`
  color: #979797;
  opacity: 0.3;
`;

const MenuProfileWrapper = ProfileWrapper.extend`
  margin-top: 10px;
  cursor: default;
`;

const MenuProfileTitle = ProfileNameTitle.extend`
  color: black;
`;

const LockUser = styled(Img)`
  height: 20px;
  width: 20px;
  padding-right: 5px;
`;

const VersionTitle = styled.div`
  margin: auto;
  font-size: 11px;
  color: #979797;
`;

class ProfileButton extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);
    this.onIconToggleClick = this.onIconToggleClick.bind(this);
    this.handleClickEnv = this.handleClickEnv.bind(this);
    this.redirectToAssociatesPage = this.redirectToAssociatesPage.bind(this);
    this.redirectToSRCDashBoard = this.redirectToSRCDashBoard.bind(this);
    this.redirectToSwitchUser = this.redirectToSwitchUser.bind(this);
    this.onLockUser = this.onLockUser.bind(this);
    this.inputRef = React.createRef();
  }

  isMenuActive() {
    return this.state.showMenu;
  }

  hideMenu() {
    this.setState({
      showMenu: false,
    });
  }

  showMenu() {
    this.setState({
      showMenu: true,
    });
  }

  onToggleClick(event) {
    event.preventDefault();
    this.props.onProfile();
    if (this.isMenuActive()) {
      this.hideMenu();
    } else {
      this.showMenu();
    }
  }

  onIconToggleClick() {
    const { showModals } = this.props;
    showModals();
    this.hideMenu();
  }

  handleClickOutside() {
    this.hideMenu();
  }

  handleClickEnv() {
    const {
      router: { navigate },
    } = this.props;
    navigate("/env_details");
  }

  redirectToAssociatesPage() {
    const {
      setSearchComponentName,
      router: { navigate },
    } = this.props;
    setSearchComponentName(searchConstants.ASSOCIATE);
    navigate("/associates");
  }

  redirectToSRCDashBoard() {
    const {
      setSearchComponentName,
      router: { navigate },
    } = this.props;
    setSearchComponentName(searchConstants.STORE);
    navigate("/searchSRC");
  }

  renderAssociatesProfilesMenuItem = () => {
    const { associateJobCode, currentAssociateId } = this.props;

    if (associateJobCode === jobRoleConstants.MANAGER) {
      return (
        <MenuButton
          className="ProfileButton__menuButton"
          title="Associates Profiles"
          onClick={this.redirectToAssociatesPage}
        />
      );
    }
    if (associateJobCode === jobRoleConstants.ASSOCIATE && currentAssociateId) {
      const {
        router: { navigate },
      } = this.props;
      return (
        <MenuButton
          className="ProfileButton__menuButton"
          icon="https://via.placeholder.com/15x15"
          title="My Profile"
          onClick={() => navigate(`/associate/${currentAssociateId}`)}
        />
      );
    }

    return null;
  };

  redirectToSwitchUser() {
    const {
      router: { navigate },
    } = this.props;
    navigate("/quick-pin/select-user");
  }

  onLockUser(e) {
    const {
      lockUser,
      router: { navigate, location },
      oktaEmail,
      storeNumber,
    } = this.props;

    e.stopPropagation();

    lockUser();
    navigate(routePaths.SELECT_USER);
  }

  renderDropDownContainer = ({
    username,
    onLogout,
    associateJobCode,
    router: { navigate },
    exitPrism,
    currentAssociateId,
    isSVCS727Enabled,
    versionNumber,
    hotelFeatureFlagIsOn,
    displayName,
    systemBookingFlow,
  }) => {
    const systemIsSalonOrTraining =
      systemBookingFlow === systemName.SALON || systemBookingFlow === systemName.TRAINING;
    const version = versionNumber?.version ?? "";

    return (
      <DropdownContainer className="ProfileButton">
        <MenuProfileWrapper className="ProfileButton__menuProfileWrapper">
          <InitialsCircle
            className="ProfileButton__menuProfileImage"
            displayName={displayName}
            margin="5px"
          />
          <MenuProfileTitle className="ProfileButton__menuProfileTitle">
            {displayName}
          </MenuProfileTitle>
        </MenuProfileWrapper>

        <Divider />
        <ProfileDropDownJobRoleIndicatorContainer />
        <ChangeTestProfileContainer />
        <Divider />

        <MenuButton
          className="ProfileButton__menuButton"
          icon={IconKeyImage}
          title="Icons Key"
          onClick={this.onIconToggleClick}
        />

        {associateJobCode === jobRoleConstants.MANAGER && systemIsSalonOrTraining && (
          <MenuButton
            className="ProfileButton__menuButton"
            icon={ScheduleImage}
            title="Modify / Create Schedules"
            onClick={() => navigate("/schedules")}
          />
        )}

        {associateJobCode === jobRoleConstants.ASSOCIATE && systemIsSalonOrTraining && (
          <MenuButton
            className="ProfileButton__menuButton"
            icon={ScheduleImage}
            title="My Schedule"
            onClick={() => navigate(`/my-schedule/${currentAssociateId}`)}
          />
        )}

        <ProfileSectionWrapper>
          {systemIsSalonOrTraining && this.renderAssociatesProfilesMenuItem()}
          <FoodMedAddOnActivityReports />
          <ReportsProfileBtn />
          <ServiceManagerHeaderContainer />
          <PetsHotelAdminHeaderContainer />
          <RoomManagerHeaderContainer />
        </ProfileSectionWrapper>

        <SectionWrapper>
          {associateJobCode === jobRoleConstants.SRC && (
            <MenuButton
              className="ProfileButton__menuButton--src"
              title="SRC Dashboard"
              onClick={this.redirectToSRCDashBoard}
            />
          )}

          <DashboardSalonMenuButtonContainer />
          <DashboardTrainingMenuButtonContainer />
          <DashboardHotelMenuButtonContainer />
        </SectionWrapper>
        <Divider />

        <MenuButton
          className="ProfileButton__menuButton"
          icon="https://via.placeholder.com/15x15"
          title="Exit PRISM"
          onClick={exitPrism}
        />

        <MenuButton
          className="ProfileButton__menuButton"
          icon="https://via.placeholder.com/15x15"
          title="Switch User"
          onClick={this.redirectToSwitchUser}
        />

        <MenuButton
          className="ProfileButton__menuButton"
          icon={LogoutImage}
          title="Logout"
          onClick={onLogout}
        />

        {!isCurrentEnvironment("prod") ? (
          <MenuButton
            className="ProfileButton__menuButton"
            icon="https://via.placeholder.com/15x15"
            title="Environment Details"
            onClick={this.handleClickEnv}
          />
        ) : null}
        <Divider />
        <VersionTitle>{`v${version}`}</VersionTitle>
      </DropdownContainer>
    );
  };

  render() {
    const { modalType } = this.props;
    return (
      <StyledPopover
        className="ProfileButton__styledPopover"
        // Issue: SVCSART-33919 - Fix specific to profile popover
        // Bump z-index as sparky components have higher z-index
        style={{ zIndex: "101" }}
        body={this.renderDropDownContainer(this.props)}
        isOpen={this.state.showMenu}
        place="below"
        onOuterAction={() => this.handleClickOutside()}
        tipSize={7}
        target={this.inputRef}
      >
        <>
          <SectionContainer className="ProfileButton__sectionContainer">
            <ProfileWrapper className="ProfileButton__profileWrapper" onClick={this.onToggleClick}>
              <InitialsCircle
                className="ProfileButton__profileImage"
                displayName={this.props.displayName}
                margin="5px"
              />
              <ProfileNameTitle className="ProfileButton__profileNameTitle">
                {this.props.displayName}
              </ProfileNameTitle>
              <LockUser src={LockImage} onClick={this.onLockUser} />
            </ProfileWrapper>
          </SectionContainer>

          {modalType && <AppHeaderModal modalType={modalType} />}
        </>
      </StyledPopover>
    );
  }
}

const mapStateToProps = state => ({
  systemBookingFlow: getSystemBookingFlow(state),
});

export default withRouteProps(connect(mapStateToProps)(ProfileButton));
