import * as React from 'react';
import { psx } from '../../../../utils/clsx';
import FormField, { FormFieldInputProps, DEFAULT_FORM_FIELD_VARIANT } from '..';
import { getErrorMessage } from '../error-messages';

export interface FormFieldTimeProps extends FormFieldInputProps, React.ComponentPropsWithoutRef<'input'> {}

export interface FormFieldTimeState {
  errorMessage: string;
}

export class FormFieldTime extends React.Component<FormFieldTimeProps, FormFieldTimeState> {
  constructor(props: FormFieldTimeProps) {
    super(props);
    this.state = {
      errorMessage: '',
    };
  }

  handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ errorMessage: getErrorMessage(e.target, 'time') });
    if (this.props.onInput) {
      this.props.onInput(e);
    }
  };

  render() {
    const {
      children,
      className,
      label,
      labelClassName,
      onInput,
      defaultValue,
      value,
      variant = DEFAULT_FORM_FIELD_VARIANT,
      ...rest
    } = this.props;

    const classNames = psx(`form-input-time-${variant}`, className);

    return (
      <FormField className={labelClassName} label={label} error={this.state.errorMessage} variant={variant}>
        <input {...rest} onInput={this.handleInput} className={classNames} type="time" />
      </FormField>
    );
  }
}
