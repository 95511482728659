// React and Redux
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Layout
import { Layout } from "@prism/psm-ui-components";

// Selectors
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { getStoreData } from "core/selectors/entitiesSelector";

// Popover
import StyledPopover from "web/common/StyledPopover";

// Container/Component(s)
import { SrcStoreInfoWindow } from "dux/storeInfoWindow/StoreInfoWindow";

// Sparky
import { Button } from "@petsmart-ui/sparky";
import { createLoadingSelector } from "core/selectors/utils";
import salonActionTypes from "core/actionTypes/salonActionTypes";
import {
  GET_STORE_WIDE_NOTES,
  GET_STORE_WIDE_NOTES_SUCCESS,
} from "dux/storeWideNotes/storeWideNotesActions";

export const StoreInfoBanner = props => {
  const { componentId, isHidden, message, storeNumber, DropDownContent } = props;

  const [isMenuVisible, showMenu] = useState(false);

  useEffect(() => {
    // some Effect();
  }, []);

  const onToggleDropDown = event => {
    event.preventDefault();
    if (isMenuVisible) {
      showMenu(false);
    } else {
      showMenu(true);
    }
  };

  if (isHidden) {
    return null;
  }

  return (
    <Layout.Box
      id={componentId}
      style={{
        backgroundColor: "rgba(0, 0, 0, .23)",
        backdropFilter: "blur(3.4px)",
        border: "solid transparent",
        position: "absolute",
        width: "100%",
      }}
    >
      <Layout.Cluster justify="center">
        {/*NOTE: StyledPopover is here as future Dev work, will add popover functionality, */}
        <StyledPopover
          body={<DropDownContent />}
          isOpen={isMenuVisible}
          place="below"
          tipSize={7}
          onOuterAction={() => showMenu(false)}
          radius={"25px"}
          style={{ zIndex: 1 }}
        >
          {storeNumber && (
            <Button
              style={{ color: "white" }}
              variant="link"
              onClick={onToggleDropDown}
              text={message}
            />
          )}
        </StyledPopover>
      </Layout.Cluster>
    </Layout.Box>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const SRC_StoreInfoBanner = connect(state => {
  // store info
  const storeNumber = getStoreNumber(state);
  const storeInfo = getStoreData(state, { storeNumber });

  const isLoading = createLoadingSelector([
    salonActionTypes.LOAD_SALON_INFO_WITH_HOURS,
    GET_STORE_WIDE_NOTES,
  ])(state);

  // component will be hidden until notes and hours apis are loaded
  const isHidden = isLoading;

  return {
    componentId: "SRC_StoreInfoBanner",
    isHidden,
    message: storeNumber
      ? `${storeInfo.storeName} ${storeInfo.storeNumber}`
      : "No store is available, please search for a store",
    DropDownContent: SrcStoreInfoWindow,
    storeNumber,
  };
})(StoreInfoBanner);
