import { createSelector } from "reselect";
import { flatMap } from "lodash/fp";
import { getHotelItinerary } from "dux/hotelItinerary/hotelItinerarySelectors";
import { getHotelEngagements } from "dux/hotelEngagements/hotelEngagementSelectors";
import { selectHotelFoodHistory } from "web/food/_foodState";
import { selectHotelMedHistory } from "web/medication/medicationsSelector";
import isEqual from "lodash/isEqual";
import unionWith from "lodash/unionWith";

/**
 * Given the hotel Itinerary, Returns the hotel itinerary history.
 *
 * @memberOf Selectors.HotelHistory
 * @function
 * @name selectHotelItineraryHistory
 * @param {Function} getHotelItinerary - The selector function to get the hotel itinerary.
 * @returns {Array} - The array representing the history of the hotel itinerary.
 */
const selectHotelItineraryHistory = createSelector(
  [getHotelItinerary],
  hotelItinerary => hotelItinerary?.history ?? [],
);

/**
 * Given the hotel Engagements, Returns the history for all engagements.
 *
 * @memberOf Selectors.HotelHistory
 * @function
 * @name selectHotelItineraryHistory
 * @param {Function} getHotelItinerary - The selector function to get the hotel Engagements.
 * @returns {Array} - The array representing the history of the hotel Engagements.
 */
const selectHotelEngagementHistory = createSelector([getHotelEngagements], engagements => {
  return flatMap(engagement => {
    return engagement?.history;
  }, engagements);
});

/**
 * Returns the rolled up hotel history by combining `hotelItineraryHistory`,
 * `hotelEngagementHistory`, `hotelFoodHistory`, and `medHistory` into a single array.
 *
 * @memberOf Selectors.HotelHistory
 * @function
 * @name selectRolledUpHotelHistory
 * @param {Array} hotelItineraryHistory - The hotel itinerary history.
 * @param {Array} hotelEngagementHistory - The hotel engagement history.
 * @param {Array} hotelFoodHistory - The hotel food history.
 * @param {Array} medHistory - The medical history.
 * @returns {Array} The rolled up hotel history.
 */
export const selectRolledUpHotelHistory = createSelector(
  [
    selectHotelItineraryHistory,
    selectHotelEngagementHistory,
    selectHotelFoodHistory,
    selectHotelMedHistory,
  ],
  (hotelItineraryHistory, hotelEngagementHistory, hotelFoodHistory, medHistory) => {
    const historyArrays = [
      ...hotelItineraryHistory,
      ...hotelEngagementHistory,
      ...hotelFoodHistory,
      ...medHistory,
    ].map(item => [item]);
    return unionWith(...historyArrays, isEqual);
  },
);
