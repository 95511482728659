/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const displayFeatureFlagIconActionTypes = {
  DISPLAY_FEATURE_FLAG_ICON: "DISPLAY_FEATURE_FLAG_ICON"
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const displayFeatureFlagIcon = ({ isHidden }) => ({
  type: displayFeatureFlagIconActionTypes.DISPLAY_FEATURE_FLAG_ICON,
  isHidden
});
