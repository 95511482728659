import moment from "moment/moment";

/**
 * Returns the date of the given day of the week.
 *
 * @param {string} dayOfWeek - The day of the week (e.g. "Monday", "Tuesday", etc.)
 * @param {Date} startWeekDate - The start date of the week
 * @param {String[]} weekDays - list of days ["Monday", "Tuesday", ...]
 * @returns {Date|null} - The date of the given day of the week, or null if startWeekDate is not provided (e.g. Sun Apr 30 2023 00:00:00 GMT-0700 (Mountain Standard Time) )
 */
export const getDateOfDay = (dayOfWeek, startWeekDate, weekDays) => {
  if (!startWeekDate) {
    return null;
  }

  const offset = weekDays.indexOf(dayOfWeek);
  const dateOfDay = moment(startWeekDate).add(offset, "d");

  return dateOfDay.toDate();
};

/**
 * Returns the text representation of the given day of the week.
 *
 * @param {string} dayOfWeek - The day of the week (e.g. "Monday", "Tuesday", etc.)
 * @param {Date|null} dateOfDay - The date of the given day of the week, or null if startWeekDate is not provided
 * @returns {string} - The text representation of the given day of the week (e.g. Monday, April 24)
 */
export const getDayOfWeekText = (dayOfWeek, dateOfDay) => {
  if (!dateOfDay) {
    return dayOfWeek;
  }

  return `${dayOfWeek}, ${moment(dateOfDay).format("MMMM")} ${moment(dateOfDay).format("DD")}`;
};

/**
 * Returns the text representation of the given day of the week. (e.g. Monday, April 24)
 *
 * @param {string} dayOfWeek - The day of the week (e.g. "Monday", "Tuesday", etc.)
 * @param {Date} startWeekDate - The start date of the week
 * @param {String[]} weekDays - list of days ["Monday", "Tuesday", ...]
 * @param {Boolean} isTemplateMode - (optional)
 * @returns {string} - The text representation of the given day of the week
 */
export const getDayMonthAndDate = (dayOfWeek, startWeekDate, weekDays, isTemplateMode = false) => {
  const dateOfDay = getDateOfDay(dayOfWeek, startWeekDate, weekDays);
  const dayOfWeekText = getDayOfWeekText(dayOfWeek, dateOfDay);

  return isTemplateMode ? dayOfWeek : dayOfWeekText;
};
