import React from "react";
import styled from "styled-components";
import { Stage, Layer, Text, Line } from "react-konva";
import { color } from "../common/styles/theme";
import { LayoutGrid } from "@/layout/grid/Grid";
import ganttConstants from "@/core/constants/ganttConstants";

const StyledGanttGroupTitle = styled.div`
  height: 40px;
  margin-top: ${props => (props.isFirstGroup ? "0.5rem" : "auto")};
`;

const StyledTitle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const GanttGroupTitle = props => {
  const {
    groupName,
    groupCount,
    isWeekly,
    isFirstGroup,
    currentWidth,
    calculateX,
    currentTime,
    hours,
  } = props;
  const currentTimeX = calculateX(currentTime);

  return (
    <StyledGanttGroupTitle isFirstGroup={isFirstGroup}>
      <LayoutGrid space="scale-0" minimum="scale-0" style={ganttConstants.ROW_GRID_TEMPLATE}>
        <StyledTitle>{`${!isWeekly ? `${groupCount} ` : ""}${groupName}`}</StyledTitle>
        <Stage width={currentWidth} height={40}>
          <Layer>
            <Line
              points={[currentTimeX, isFirstGroup ? 15 : 0, currentTimeX, 40]}
              stroke={color.errorRed}
              strokeWidth={1}
            />
            {isFirstGroup && (
              <React.Fragment>
                <Text
                  text={currentTime.format("h:mm")}
                  x={currentTimeX - 12}
                  y={0}
                  fill={color.errorRed}
                  fontFamily="Lato"
                />
                {hours.map((hour, index) => (
                  <Text
                    key={index}
                    text={hour?.label}
                    x={index * (currentWidth / hours.length)}
                    y={20}
                    fontFamily="Lato"
                  />
                ))}
              </React.Fragment>
            )}
          </Layer>
        </Stage>
      </LayoutGrid>
    </StyledGanttGroupTitle>
  );
};

export default GanttGroupTitle;
