// Action Types
import {
  GET_SELECTED_REPORT_SUCCESS,
  GET_SELECTED_REPORT_FAILURE,
  SET_SELECTED_FILTERS,
  SET_SELECTED_SORT_INFO,
} from "./reportsActions";

/**
 * @name reportsReducer
 * @memberof Reducer.Reports
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
const reportsReducer = (
  state = { selectedReportData: [], selectedFilters: [], selectedSortInfo: {}, error: null },
  action,
) => {
  switch (action.type) {
    case GET_SELECTED_REPORT_SUCCESS:
      return {
        ...state,
        selectedReportData: action.selectedReportData,
      };
    case GET_SELECTED_REPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: action.filters,
      };
    case SET_SELECTED_SORT_INFO:
      return {
        ...state,
        selectedSortInfo: action.sortInfo,
      };
    default:
      return state;
  }
};

export default reportsReducer;
