import { PHONE_NUMBER_REGEX } from "../../constants/regex";

/** @module formatPhoneNumberNANP */

/**
 * Takes a string of numbers and formats it to a North American Number Plan from  Bell Systems.
 * A seven digit subscriber number with 3 digit area code, e.g. (xxx) xxx-xxxx
 *
 * @export formatPhoneNumberNANP
 * @param {string} phoneString
 * @returns {string} Formatted Bell System number with 3 digit area code
 */
export default function formatPhoneNumberNANP(phoneString) {
  // Return an empty string if no phone was given
  if (!phoneString) {
    return "";
  }

  // Remove all non digit characters
  const numbersOnly = `${phoneString}`.replace(/\D/g, "");

  // Break out Number sets into an array of groups
  const group = numbersOnly.match(PHONE_NUMBER_REGEX);

  // return newly formatted number e.g (xxx) xxx-xxxx
  return !group ? null : `(${group[1]}) ${group[2]}-${group[3]}`;
}
