import { connect } from "react-redux";
import { getPetVetsToDisplay } from "../../../../../core/selectors/vetsSelectors";
import { modalTypes } from "../../../../../core/constants/petProfileConstants";
import vetsActionCreators from "../../../../../core/actionCreators/vetsActionCreators";
import vetsActionTypes from "../../../../../core/actionTypes/vetsActionTypes";
import petProfileActionCreators from "../../../../../core/actionCreators/petProfileActionCreators";
import { createLoadingSelector } from "../../../../../core/selectors/utils";
import PetVetsTableComponent from "./PetVetsTableComponent";

const mapStateToProps = (state, ownProps) => ({
  petVets: getPetVetsToDisplay(state, ownProps),
  isLoading: createLoadingSelector([vetsActionTypes.DELETE_VET])(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  showModal: () => {
    dispatch(
      vetsActionCreators.setCustomerAndPetKeys({
        customerKey: ownProps.customerKey,
        petId: ownProps.petId,
      }),
    );
    dispatch(petProfileActionCreators.showPetProfileModal(modalTypes.SEARCH_VET));
  },
  openRemoveVetModal: veterinarianId => {
    dispatch(
      vetsActionCreators.setCustomerAndPetKeys({
        customerKey: ownProps.customerKey,
        petId: ownProps.petId,
      }),
    );
    dispatch(vetsActionCreators.setVetToDelete(veterinarianId));
    dispatch(petProfileActionCreators.showPetProfileModal(modalTypes.DELETE_VET));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PetVetsTableComponent);
