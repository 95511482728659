import { oktaAuthAPI } from "./_axiosConfigOktaAuth";

/**
 * Refresh a token
 * System Associate Auth API
 * - Refresh a token
 * @param {*} { sessionToken: SessionToken }
 * @returns AxiosPromise
 */
export function logoutFromOkta() {
  return oktaAuthAPI.delete("/api/v1/sessions/me");
}
