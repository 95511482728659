/**
 * Responsible for returning the quickView Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getSchedulesState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the schedules object in the redux store
 *
 * @example createSelector([getSchedulesState], schedulesState => schedulesState.allAssociates);
 */
export default function getSchedulesState(state) {
  return state.schedules;
}
