import { connect } from "react-redux";
import { get } from "lodash/fp";
import CommonErrorMessage from "../common/errorAndMessages/CommonErrorMessage";
import { getCurrentCustomerKey } from "../../core/selectors/persistent/customer/customerSelectors";
import { getCustomer } from "../../core/selectors/entitiesSelector";
import getIsHotelWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  const customer = getCustomer(state, { customerKey });
  return {
    // feature flagging must be on and customer must be a vip for the component to show.
    isHidden: customer?.prismCustomer?.isVip ? getIsHotelWorkflowFeatureFlagHidden(state) : true,
    message: `${customer?.firstName} ${customer?.lastName} is flagged as a VIP`,
    highlight: true,
    isError: true,
  };
};

// const mapDispatchToProps = dispatch => ({
//   keyName: ({ }) => dispatch(actionName({ })),
// });

// const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
//   ...propsFromState,
//   ...propsFromDispatch,
//   ...ownProps,

//   keyName: ({ }) => propsFromDispatch.functionName({
//     keyName:	propsFromState.value,
//   }),
//   keyName: ({ }) => propsFromDispatch.functionName({
//     keyName:	propsFromState.value,
//   }),
// })

export default connect(
  mapStateToProps,
  // mapDispatchToProps,
  // mergeProps,
)(CommonErrorMessage);
