/* * ----------------------------------------------------------------------- * *\
  Full API for booking and managing service itineraries,
  which are the root containers for pet service appointments.
  This API is hosted on Salesforce and accessed through Azure APIM.

  See Confluence page for more details
    https://confluence.ssg.petsmart.com/pages/viewpage.action?spaceKey=PS&title=1.+Customer+Profile+V1

  See APIM for more details
    https://petm-apimanagement-qa.portal.azure-api.net/docs/services/system-services-booking-api-new-qa/operations/update-customer-profile-partial?&pattern=Update%20Customer
\* * ----------------------------------------------------------------------- * */

import { bookingAPI } from "../_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../../utils/envUtils";

/**
 * For System Services Booking API
 * - Get Customer Profile - Same information also returns in the fetchCustomer() for associate Web service call
 * @param {*} { customerKey }
 * @returns {*} AxiosPromise
 */
export function getCustomerProfile({ customerKey }) {
  return bookingAPI.get(`${endpointVersionString()}/customers/${customerKey}/profile`);
}

/**
 * - Update part of the customer profile
 * @param {*} { customerKey, data }
 * @returns {*} AxiosPromise
 */
export function updateCustomerProfileFull({ customerKey, data }) {
  return bookingAPI.put(`${endpointVersionString()}/customers/${customerKey}/profile`, data);
}

/**
 * - Update part of the customer profile
 * @param {*} { customerKey, data }
 * @returns {*} AxiosPromise
 */
export function updateCustomerProfilePartial({ customerKey, data }) {
  return bookingAPI.patch(`${endpointVersionString()}/customers/${customerKey}/profile`, data);
}
