import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  GET_HOTEL_SERVICE_SUSPENSION,
  getHotelServiceSuspensionNameFailure,
  getHotelServiceSuspensionNameRequest,
  getHotelServiceSuspensionNameSuccess,
  PATCH_HOTEL_SERVICE_SUSPENSION,
  patchHotelServiceSuspensionFailure,
  patchHotelServiceSuspensionRequest,
  patchHotelServiceSuspensionSuccess,
  POST_HOTEL_SERVICE_SUSPENSION,
  postHotelServiceSuspensionFailure,
  postHotelServiceSuspensionRequest,
  postHotelServiceSuspensionSuccess,
} from "dux/scheduleSuspensionPage/scheduleSuspensionActions";
import { postHotelServiceSuspensionIsUpdated } from "dux/servicesManagementToggle/servicesManagementToggleActions";
import { selectServiceSuspensionReasons } from "dux/hotelSuspensionReasons/hotelSuspensionReasonsSelectors";
import { find } from "lodash/fp";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import {
  getHotelServiceSuspension,
  patchHotelServiceSuspension,
  postHotelServiceSuspension,
} from "core/services/systemServicesBooking/serviceSuspensions";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { setScheduleSuspensionPaginationSuccess } from "dux/scheduleSuspensionPagination/scheduleSuspensionPaginationActions";
import { getScheduledSuspensions } from "dux/scheduleSuspensionTable/scheduleSuspensionTableSelector";

// POST SUSPENSION SCHEDULE
/**
 * Saga that handles the request to post hotel service suspensions.
 *
 * @memberof Sagas.ScheduledSuspensions
 * @generator
 * @function
 * @name onPostHotelScheduleSuspensions
 * @param {Object} options - The options object.
 * @param {string} options.details - The details of the suspension - what shows in the "Additional Details input field".
 * @param {string} options.reasonValue - The value of the suspension reason - value from rom the Reason dropdown, e.g. Facility Issue, Staffing, Store Closure, etc.
 * @param {string} options.suspensionTypeId - The ID of the suspension type - the Id the belongs to teh Select Service dropdown.
 * @param {string} options.reasonTypeName - The name of the suspension type
 * @return {void}
 */
function* onPostHotelScheduleSuspensions({
  details,
  reasonValue,
  suspensionTypeId,
  reasonTypeName,
}) {
  try {
    yield put(postHotelServiceSuspensionRequest());

    const storeNumber = yield select(getStoreNumber);

    // get reason data from state and transform into data needed for api call
    const reasons = yield select(selectServiceSuspensionReasons);

    // const suspensionTypes = yield select(selectSuspensionTypes);

    const reasonObj = find(item => item.suspensionTypeId === suspensionTypeId, reasons);
    const reason = find(item => item.reasonName === reasonValue, reasonObj.reasons);

    const selectedDate = yield select(getSelectedDate);

    // call post api
    const response = yield call(postHotelServiceSuspension, {
      storeNumber,
      data: {
        startDate: selectedDate,
        endDate: selectedDate,
        suspensionTypeId,
        reasonId: reason.reasonId,
        details,
      },
    });

    const suspension = response.data.result;

    // The suspensionList state requires an array, but the response for a single suspension is an object, so we just
    // coerce to an array as the argument to the action.
    yield put(postHotelServiceSuspensionSuccess([suspension]));

    yield put(
      postHotelServiceSuspensionIsUpdated({
        isUpdated: true,
        reasonTypeName: reasonTypeName.replace(/\s/g, ""),
      }),
    );
  } catch (error) {
    yield put(postHotelServiceSuspensionFailure(error));
  }
}

// GET SUSPENSION SCHEDULE
function* onGetHotelScheduleSuspensions({ fromDate, toDate, page, size, includeHistory }) {
  try {
    yield put(getHotelServiceSuspensionNameRequest());

    const storeNumber = yield select(getStoreNumber);

    // Call the api to get scheduledSuspension data.
    const response = yield call(getHotelServiceSuspension, {
      storeNumber,
      fromDate,
      toDate,
      page,
      size,
      includeHistory,
    });

    yield put(getHotelServiceSuspensionNameSuccess(response?.data?.results));

    yield put(
      setScheduleSuspensionPaginationSuccess({
        count: response.data.count,
        page: response.data.page,
        total: response.data.total,
        size: response.data.size,
      }),
    );
  } catch (error) {
    yield put(getHotelServiceSuspensionNameFailure({ error }));
  }
}

// PATCH SUSPENSION SCHEDULE
/**
 * SuspensionPatchData data types
 * @typedef {Object} SuspensionPatchData
 * @property {string} startDate - Start Date of the suspension range
 * @property {string} endDate - End Date of the suspension range
 * @property {string} details - Details as to why the suspension scheduled
 * @property {string} reasonId - id of the reason value
 * @property {string} suspensionTypeId - id of the suspension type, e.g DDC
 * @property {boolean} isCanceled - to cancel a suspension
 */

/**
 * Saga generator to patch scheduledSuspensions
 * @param {Object} params - destructed
 * @param {SuspensionPatchData} params.SuspensionPatchData - Data passed from the acton to be used for the API call
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{type: string, error: *}>>|SimpleEffect<"PUT", PutEffectDescriptor<{suspensions: *, type: string}>>|SimpleEffect<"SELECT", SelectEffectDescriptor>|SimpleEffect<"CALL", CallEffectDescriptor<(function({storeNumber: number, suspensionId: number, data: Data}): AxiosPromise<*>)|* extends ((...args: any[]) => SagaIterator<infer RT>) ? RT : ((function({storeNumber: number, suspensionId: number, data: Data}): AxiosPromise<*>)|* extends ((...args: any[]) => Promise<infer RT>) ? RT : ((function({storeNumber: number, suspensionId: number, data: Data}): AxiosPromise<*>)|* extends ((...args: any[]) => infer RT) ? RT : never))>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
function* onPatchHotelScheduleSuspensions({
  startDate,
  endDate,
  details,
  reasonId,
  suspensionTypeId,
  isCanceled,
  suspensionId,
  reasonValue,
}) {
  try {
    yield put(patchHotelServiceSuspensionRequest());
    let reasonIdByReasonValue;
    const storeNumber = yield select(getStoreNumber);
    if (reasonValue) {
      // get reason data from state and transform into data needed for api call
      const reasons = yield select(selectServiceSuspensionReasons);
      const suspensionObj = reasons[suspensionTypeId];
      reasonIdByReasonValue =
        suspensionObj &&
        suspensionObj.reasons.find(itm => itm.reasonName === reasonValue)?.reasonId;
    }

    // call patch api
    const response = yield call(patchHotelServiceSuspension, {
      storeNumber,
      suspensionId,
      data: {
        startDate,
        endDate,
        details,
        reasonId: reasonId || reasonIdByReasonValue,
        suspensionTypeId,
        isCanceled,
      },
    });

    const responseData = response.data.result;

    const suspensionsInState = yield select(getScheduledSuspensions);

    /* The response data will most likely come back with an empty array for history, but the suspensions
    that we have in state will have history, we don't want to blow that out so we need to map/iterate
    over the all the suspensions in state, if we find a matching suspensionId, we update it with the
    response data and just overwrite the history from the response data with the history that was already
    in state. */
    const suspensions = suspensionsInState.map(item => {
      if (item.suspensionId === responseData.suspensionId) {
        return {
          ...responseData,
          history: item.history,
        };
      }

      return item;
    });

    yield put(patchHotelServiceSuspensionSuccess({ suspensions }));
  } catch (error) {
    yield put(patchHotelServiceSuspensionFailure(error));
  }
}

function* watchHotelScheduleSuspension() {
  yield takeEvery(GET_HOTEL_SERVICE_SUSPENSION, onGetHotelScheduleSuspensions);
}

function* watchPostHotelScheduleSuspension() {
  yield takeEvery(POST_HOTEL_SERVICE_SUSPENSION, onPostHotelScheduleSuspensions);
}

function* watchPatchHotelScheduleSuspension() {
  yield takeEvery(PATCH_HOTEL_SERVICE_SUSPENSION, onPatchHotelScheduleSuspensions);
}

export default function* hotelScheduleSuspensionSaga() {
  yield all([
    fork(watchHotelScheduleSuspension),
    fork(watchPostHotelScheduleSuspension),
    fork(watchPatchHotelScheduleSuspension),
  ]);
}
