import { LayoutBox } from "@/layout/box/Box";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  HotelCheckInDateTime,
  HotelCheckOutDateTime,
} from "@/dux/hotelCheckInOutDateTimeCart/hotelCheckInOutDateTimeWrapper";
import { selectCheckInOutDateTimeCommonState } from "./hotelCheckInOutSelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { getHotelItinerary } from "../hotelItinerary/hotelItinerarySelectors";
import { HotelActualCheckInDateTime } from "../hotelActualCheckInDateTime/HotelActualCheckInDateTime";

export const HotelCheckInOutDateTimeWrapperComponent = props => {
  const { componentId, isHidden, petId, startDateTime, endDateTime } = props;

  const [pendingStart, setPendingStart] = useState(moment(startDateTime));
  const [pendingEnd, setPendingEnd] = useState(moment(endDateTime));

  // If pet or dates change then reset local state
  useEffect(() => {
    setPendingStart(moment(startDateTime));
  }, [petId, startDateTime]);
  
  useEffect(() => {
    setPendingEnd(moment(endDateTime));
  }, [petId, endDateTime]);

  if (!isHidden) {
    return (
      <LayoutBox id={componentId} padding="scale-0">
        <HotelCheckInDateTime
          dateTime={pendingStart}
          setDateTime={setPendingStart}
          pendingEnd={pendingEnd}
        />
        <HotelActualCheckInDateTime />
        <HotelCheckOutDateTime
          dateTime={pendingEnd}
          setDateTime={setPendingEnd}
          pendingStart={pendingStart}
        />
      </LayoutBox>
    );
  }

  return null;
};

export const HotelCheckInOutDateTimeWrapper = connect(state => {
  const petId = getCurrentPet(state);
  const storeNumber = getHotelItinerary(state)?.storeNumber;
  const { startDateTime, endDateTime } = selectCheckInOutDateTimeCommonState(state, {
    petId,
    storeNumber,
  });

  return {
    componentId: "HotelCheckInOutDateTimeWrapper",
    petId,
    startDateTime,
    endDateTime,
  };
})(HotelCheckInOutDateTimeWrapperComponent);
