import { connect } from "react-redux";
import { getPrismCustomerHotelProfile } from "../../selectors/getPrismCustomerProfileSelector";
import AlertsFormComponent from "../../alerts/AlertsFormComponent";
import { updateHotelCustomerAlerts } from "../../../../dux/customerAlerts/customerAlertsActions";
import { getSystemBookingFlow } from "../../../setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../../../setSystemType/constants/setSystemTypeConstants";
import { getCurrentCustomerKey } from "../../../../core/selectors/persistent/customer/customerSelectors";
import parseAlertData from "../../../../core/utils/entitiesUtils/parseAlertData";

const mapStateToProps = (state, ownProps) => {
  const parsedAlertData = parseAlertData(getPrismCustomerHotelProfile(state, ownProps));

  return {
    title: "Pet Parent Alerts",
    customerKey: getCurrentCustomerKey(state),
    alertsFormData: parsedAlertData,
    componentID: "alertForm-HotelCustomerAlerts",
    isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
  };
};

const mapDispatchToProps = dispatch => ({
  handleUpdateHotelCustomerAlerts: ({ customerKey, values }) => {
    dispatch(
      updateHotelCustomerAlerts({
        alerts: {
          customerKey,
          prismCustomer: {
            profiles: [
              {
                bookingAlert: values.isBookingAlert ? values.bookingAlertReason : "",
                checkInAlert: values.isCheckInAlert ? values.checkInAlertReason : "",
                checkOutAlert: values.isCheckOutAlert ? values.checkOutAlertReason : "",
                profileType: systemName.DDC_HOTEL,
              },
            ],
          },
        },
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSubmit: values =>
    propsFromDispatch.handleUpdateHotelCustomerAlerts({
      customerKey: propsFromState.customerKey,
      values,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AlertsFormComponent);
