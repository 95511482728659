import { connect } from "react-redux";
import { getMedicationCount } from "../../medicationCount/medicationCountSelector";
import {
  createPetMedication,
  createUpdateDeleteMedicationActionTypes,
} from "../../medication/actions/createUpdateDeleteMedicationActions";
import { getMedicationById } from "../../medication/medicationsSelector";
import isRequiredPetMedicationFieldMissing from "../../newMedication/utils/isRequiredPetMedicationFieldMissing";
import { createLoadingSelector } from "../../../core/selectors/utils";
import { formatMedication } from "../../medication/medicationUtils";
import { shouldHideAddMedicationButton } from "../addMedicationUtils";
import { routePaths } from "../../../core/constants/routePaths";
import { selectMedicationsList } from "@/dux/medications/medicationsSelectors";
import CommonButton from "../../common/commonButton";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, petId } = ownProps;
  const medicationCount = getMedicationCount(state, { petId });
  const medication = getMedicationById(state, { medicationId, petId });
  const medicationList = selectMedicationsList(state);
  const isLoading = createLoadingSelector([
    createUpdateDeleteMedicationActionTypes.CREATE_PET_MEDICATION,
  ])(state);
  return {
    petId,
    medicationId,
    medicationCount,
    medication,
    medicationList,
    isHidden: shouldHideAddMedicationButton(routePaths.PET_PARENT_PROFILE, medicationId),
    componentId: "medicationDetails-addNewMedicationButton",
    label: isLoading ? "Adding..." : "Add",
    width: "8.5em",
    disabled: isRequiredPetMedicationFieldMissing({ medication, medicationList }) || isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  createPetMed: (medication, petId) => dispatch(createPetMedication({ medication, petId })),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { medication, petId } = propsFromState;
    const medToSend = formatMedication(medication);
    propsFromDispatch.createPetMed(medToSend, petId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
