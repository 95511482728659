import React, { useEffect, useState } from "react";
import { noop } from "lodash/fp";
import { Layout } from "@prism/psm-ui-components";
import { Alert } from "@/dux/alert/Alert";
import { useTimeoutHandler } from "@/dux/utils/browser/useTimeout";

export const FlashMessage = ({
  componentId,
  display,
  message,
  theme,
  timeout = 7000,
  onOpen = noop,
  onClose = noop,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onCloseHandler = () => {
    if (display) {
      setIsOpen(false);
      onClose();
    }
  };

  useTimeoutHandler({
    timeout,
    handler: onCloseHandler,
    dependencies: [display],
  });

  useEffect(() => {
    if (display) {
      setIsOpen(true);
      onOpen();
    }
  }, [display]);

  return (
    <Layout.Box
      style={{
        display: "grid",
        placeItems: "center",
      }}
    >
      <Alert
        id={componentId}
        isHidden={!isOpen}
        message={message}
        theme={theme}
        timeout={timeout}
        style={{
          position: "fixed",
          maxWidth: "min(60ch, 60vw)",
        }}
        onClose={onCloseHandler}
      />
    </Layout.Box>
  );
};
