import React from "react";
import styled from "styled-components";
import { fontSizes } from "web/common/styles/responsive/fonts";
import PetCard from "../../../../../web/upcoming_here/petCard/PetCardContainer";
import AvailableManualAppointment from "../../../../../web/common/availableManualAppointment/availableManualAppointmentContainer";
import getUSTimeStringByDate from "../../../../../core/utils/dateUtils/USTimeByDate";
import CommonButton from "../../../../../web/common/commonButton";
import { modalTypes as dashboardModalTypes } from "../../../../../core/constants/dashboardContants";
import { isFromDashboard } from "../../../../../core/utils/validationUtils/isFromValidation";
import { formatDayNameMonthDayYear } from "../../../../../core/utils/dateUtils/formatDateTime";
import { routePaths } from "../../../../../core/constants/routePaths";
import MaxBlockOneAddButtonContainer from "./buttons/MaxBlockOneAddButtonContainer";
import MaxBlockTwoAddButtonContainer from "./buttons/MaxBlockTwoAddButtonContainer";

class GanttZoomContent extends React.Component {
  state = {
    selectedAvailableAppointmentId: null
  };

  componentWillUnmount() {
    this.props.clearNewAppointmentTimeSlot();
    this.props.clearWeeklyGanttDate();
  }

  isDashboard = () => {
    const pathname = this.props?.router?.location?.pathname;
    return pathname === routePaths.DASHBOARD;
  };

  renderAvailableManualAppointments = ({ type, withHours, hourOptions, amount, beforeBreak }) => {
    const { selectedAvailableAppointmentId } = this.state;
    const { hasGroomingSkills, hasStoreGroomingCapability } = this.props;
    const appointments = [];
    const breakString = beforeBreak ? "beforeBreak" : "afterBreak";

    for (let i = 0; i < amount; i++) {
      appointments.push(
        <AvailableManualAppointment
          key={i}
          isAppointmentSelected={selectedAvailableAppointmentId === `${breakString}-${i}`}
          type={type}
          withHours={withHours}
          hourOptions={hourOptions.map((option) => {
            return {...option, enableHourAction: !hasStoreGroomingCapability || (option.available || hasGroomingSkills) };
          })}
          onClick={() => this.setState({ selectedAvailableAppointmentId: `${breakString}-${i}` })}
          isDashboard={this.isDashboard()}
        />
      );
    }

    return appointments;
  };

  handleSelectClick = () => {
    const {
      setNewAppointmentData,
      bookAppointment,
      router: { location },
      selectedPetService,
      associate,
      newAppointmentTimeSlot,
      hideModal,
      showDashboardModal
    } = this.props;

    if (!newAppointmentTimeSlot) {
      return;
    }

    const { startDateTime, price, currencyCode, duration, available } = newAppointmentTimeSlot;
    const fromDashboard = isFromDashboard(location.pathname);

    const timeSlot = {
      startDateTime,
      price,
      currencyCode,
      duration,
      associateId: associate.associateId
    };

    if (fromDashboard) {
      setNewAppointmentData({
        startDateTime,
        associateId: associate.associateId
      });

      showDashboardModal(dashboardModalTypes.MANUAL_BOOKING_WARNING);
    } else if (!available) {
      this.props.showConfirmationModal({
        header: "Rule Violation",
        content: (
          <div>
            This appointment violates one or more of the following booking rules:
            <br />
            Check-in max, Max-per block, Available service duration.
          </div>
        ),
        confirmText: "Select anyway",
        confirm: () => {
          bookAppointment({ timeSlot, selectedPetService, isManualAppointment: true });
          // This hides the GanttZoomContent modal, not to confuse with the confirmation modal
          hideModal();
        }
      });
    } else {
      bookAppointment({ timeSlot, selectedPetService, isManualAppointment: false });
      hideModal();
    }
  };

  render() {
    const {
      associate,
      selectedDate,
      filteredByBreakData,
      hourOptionsByBreak,
      additionalAppointmentBeforeBreak,
      additionalAppointmentAfterBreak,
      isFromDashboard,
      newAppointmentTimeSlot
    } = this.props;

    const withAMPM = true;

    const {
      appointmentsBeforeBreak,
      appointmentsAfterBreak,
      startBeforeBreak,
      endAfterBreak,
      startBreak,
      endBreak,
      extraMaxLimitBeforeBreak,
      extraMaxLimitAfterBreak
    } = filteredByBreakData;

    const { hourOptionsBeforeBreak, hourOptionsAfterBreak } = hourOptionsByBreak;
    const noBreak = !endBreak;

    return (
      <Container>
        <Header>
          <div>{associate.associateName}</div>
          <div>Schedule for {formatDayNameMonthDayYear(selectedDate)}</div>
        </Header>

        <TableHeader>
          <ColumnHeader>
            <TimePeriod>
              {`${getUSTimeStringByDate(startBeforeBreak, withAMPM)} -
                                ${getUSTimeStringByDate(startBreak, withAMPM)}`}
            </TimePeriod>
            <MaxBlockOneAddButtonContainer />
          </ColumnHeader>

          {!noBreak && (
            <ColumnHeader>
              <TimePeriod>
                {`${getUSTimeStringByDate(endBreak, withAMPM)} -
                                ${getUSTimeStringByDate(endAfterBreak, withAMPM)}`}
              </TimePeriod>
              <MaxBlockTwoAddButtonContainer />
            </ColumnHeader>
          )}
        </TableHeader>

        <Appointments>
          <BeforeBreak>
            {additionalAppointmentBeforeBreak &&
              this.renderAvailableManualAppointments({
                type: "manual",
                withHours: true,
                hourOptions: hourOptionsBeforeBreak,
                amount: 1,
                beforeBreak: true
              })}
            {appointmentsBeforeBreak.map(appointment => (
              <PetCard
                key={appointment}
                appointmentID={appointment}
                showStatus
                showDate
                fromDashboard={isFromDashboard}
                fromGantt
              />
            ))}
            {this.renderAvailableManualAppointments({
              type: "available",
              withHours: !this.isDashboard(),
              hourOptions: hourOptionsBeforeBreak,
              amount:
                associate.maxPerBlock1 - appointmentsBeforeBreak.length - extraMaxLimitBeforeBreak,
              beforeBreak: true
            })}
          </BeforeBreak>

          {!noBreak && (
            <AfterBreak>
              {additionalAppointmentAfterBreak &&
                this.renderAvailableManualAppointments({
                  type: "manual",
                  withHours: true,
                  hourOptions: hourOptionsAfterBreak,
                  amount: 1
                })}
              {appointmentsAfterBreak.map(appointment => (
                <PetCard
                  key={appointment}
                  appointmentID={appointment}
                  showStatus
                  showDate
                  fromDashboard={isFromDashboard}
                  fromGantt
                />
              ))}
              {this.renderAvailableManualAppointments({
                type: "available",
                withHours: !this.isDashboard(),
                hourOptions: hourOptionsAfterBreak,
                amount:
                  associate.maxPerBlock2 - appointmentsAfterBreak.length - extraMaxLimitAfterBreak
              })}
            </AfterBreak>
          )}
        </Appointments>

        {!isFromDashboard && (
          <ButtonContainer>
            <CommonButton disabled={!newAppointmentTimeSlot} label="Select" onClick={this.handleSelectClick} />
          </ButtonContainer>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;
  min-width: 800px;
  height: 500px;
`;

const Header = styled.div`
  font-size: ${fontSizes.regular};
  font-weight: bold;
  text-align: center;
`;

const Appointments = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
`;

const BeforeBreak = styled.div`
  flex: 1;
  flex-shrink: 0;
`;

const AfterBreak = styled.div`
  flex: 1;
  flex-shrink: 0;
`;

const ColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  flex: 1;
`;

const TableHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 10px 10px 10px;
`;

const TimePeriod = styled.div`
  font-size: ${fontSizes.regular};
  font-weight: bold;
  padding: 0 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0;
`;

export default GanttZoomContent;
