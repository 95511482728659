import { connect } from "react-redux";
import CommonButton from "web/common/commonButton";
import { history } from "@/dux/utils/browser/browserHistory";

const mapStateToProps = () => ({
  label: "Quick Quote",
});

const mapDispatchToProps = () => ({
  onClick: () => {
    history.push("/quick-quote");
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonButton);
