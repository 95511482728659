// cc: loadItinerariesFromDB#1;Dispatch Actions;loadItineraries -Day & loadItinerariesLiveUpdate - week

import getMomentByValue from "../../../core/utils/dateUtils/momentByValue";
import {
  loadItineraries,
  loadItinerariesLiveUpdate
} from "../../../core/actionCreators/itinerariesActionCreators";
import { formatMoment } from "../../../core/utils/dateUtils/formatDateTime";

/**
 * Load Itineraries for the Week, The UI is not initial dependent on this so we can load
 * Itineraries for the week so they are available when the suer changes days.
 *
 * @private
 * @param {function} dispatch - Redux Dispatch function
 * @param {*} dateObj - ISO 8601 formatted string or a Moment object
 */
const dispatchWeek = function DispatchItinerariesForWeek(dispatch, dateObj, serviceTypes) {
  // Load Itineraries for the Week, The UI is not initial dependent on this so we can load
  // Itineraries for the week so they are available when the suer changes days.
  dispatch(
    loadItinerariesLiveUpdate({
      fromDate: formatMoment(dateObj.startOf("isoWeek")),
      toDate: formatMoment(dateObj.endOf("isoWeek")),
      shouldLoadPets: true,
      serviceTypes,
    })
  );
};

/**
 * Private Load Itineraries for the day, this is done to get a smaller payload quicker
 * @private
 * @param {function} dispatch - Redux Dispatch function
 * @param {*} dateObj - ISO 8601 formatted string or a Moment object
 */
const dispatchDay = function dispatchItinerariesDay(dispatch, dateObj, serviceTypes) {
  // Load Itineraries for the day, this is done to get a smaller payload quicker
  dispatch(
    loadItineraries({
      fromDate: formatMoment(dateObj.startOf("day")),
      toDate: formatMoment(dateObj.endOf("day")),
      shouldLoadPets: true,
      serviceTypes,
    })
  );
};

/**
 * Helper function for dispatching Load Itineraries for the day and Week.
 *
 * @export loadItinerariesHelper
 * @public
 * @param {function} dispatch - Redux Dispatch function
 * @param {*} selectedDate - ISO 8601 formatted string or a Moment object
 * @param {boolean=} forBoth - Optional if itineraries should fire both both the day and week or just the week.
 */
export default function loadItinerariesHelper(dispatch, selectedDate, isBorBoth = true, serviceTypes) {
  // convert call by reference to call by value so we do not update the state object e.g. ui.web.common.selectedDate
  const selectedDateByValue = getMomentByValue(selectedDate);

  if (isBorBoth) {
    dispatchDay(dispatch, selectedDateByValue, serviceTypes);
    dispatchWeek(dispatch, selectedDateByValue, serviceTypes);
  } else {
    dispatchWeek(dispatch, selectedDateByValue, serviceTypes);
  }
}
