import React from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup } from "@petsmart-ui/sparky";
import {
  PATCH_HOTEL_ITINERARY_STATUS,
  patchHotelItineraryStatus,
} from "dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import { getCurrentItinerary } from "core/selectors/checkInOutSelector";
import { getChangeStatusPayload } from "dux/reservationCartDetailsConfirmation/reservationCartDetailsUtils";
import {
  getFirstHotelEngagementStatusByPet,
  getHotelEngagementsPets,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { getCurrentPet, getIsSRCAgent } from "@/core/selectors/persistentSelectors";
import { showConfirmationModal } from "core/actionCreators/confirmationModalActionCreators";
import { createLoadingSelector } from "core/selectors/utils";
import { APPOINTMENT_STATUS } from "core/constants";

export const UndoButton = ({ onClick, disabled, label, isHidden }) => {
  if (isHidden) return null;

  return (
    <ButtonGroup align="right">
      <Button variant="link" disabled={disabled} onClick={() => onClick()} text={label} />
    </ButtonGroup>
  );
};

export const HotelCheckInOutUndoSingleStatus = connect(
  (state, { petId }) => {
    const itineraryId = getCurrentItinerary(state);
    const isLoading = createLoadingSelector([PATCH_HOTEL_ITINERARY_STATUS])(state);

    const appointmentStatus = getFirstHotelEngagementStatusByPet(state, { petId });
    const isSRCAgent = getIsSRCAgent(state);

    const isEngagementCancelled = appointmentStatus === APPOINTMENT_STATUS.CANCELED;
    const isEngagementBooked = appointmentStatus === APPOINTMENT_STATUS.BOOKED;
    const isPending = appointmentStatus === APPOINTMENT_STATUS.PENDING;

    return {
      label: "Undo",
      isHidden: isSRCAgent || isEngagementCancelled || isEngagementBooked || isPending,
      itineraryId,
      disabled: isLoading,
      patchStatusPayload: getChangeStatusPayload({ petIds: [petId], shouldUndo: true }),
    };
  },

  dispatch => {
    return {
      dispatchPatchItineraryStatus: ({ itineraryId, data }) => {
        dispatch(
          showConfirmationModal({
            header: "Are you sure?",
            content: `Are you sure you want to undo?`,
            confirmText: "Yes",
            confirm: () =>
              dispatch(
                patchHotelItineraryStatus({
                  itineraryId,
                  data,
                }),
              ),
            closeBeforeConfirm: true,
          }),
        );
      },
    };
  },
  (mapProps, dispatchProps) => {
    const { itineraryId, patchStatusPayload, disabled, isHidden, label } = mapProps;
    const { dispatchPatchItineraryStatus } = dispatchProps;
    return {
      // control what props get passed to the view
      // actions to pass to view
      label,
      disabled,
      isHidden,
      onClick: () => {
        const data = patchStatusPayload;
        dispatchPatchItineraryStatus({
          itineraryId,
          data,
          isMulti: false,
        });
      },
    };
  },
)(UndoButton);

export const HotelCheckInOutUndoMultiStatus = connect(
  state => {
    const itineraryId = getCurrentItinerary(state);
    const petIds = getHotelEngagementsPets(state);
    const isLoading = createLoadingSelector([PATCH_HOTEL_ITINERARY_STATUS])(state);
    const isSRCAgent = getIsSRCAgent(state);

    // Pets who don't have cancelled, booked, or pending statuses
    const petsWithUndoStatuses = petIds?.filter(pet => {
      const status = getFirstHotelEngagementStatusByPet(state, { petId: pet });
      return (
        status !== APPOINTMENT_STATUS.CANCELED &&
        status !== APPOINTMENT_STATUS.BOOKED &&
        status !== APPOINTMENT_STATUS.PENDING
      );
    });

    // Hide if current pet is cancelled
    const currentPet = getCurrentPet(state);
    const appointmentStatus = getFirstHotelEngagementStatusByPet(state, { petId: currentPet });
    const isCurrentPetCancelled = appointmentStatus === APPOINTMENT_STATUS.CANCELED;

    return {
      label: "Undo for all",
      isHidden:
        isSRCAgent ||
        petIds?.length <= 1 ||
        isCurrentPetCancelled ||
        petsWithUndoStatuses?.length === 0, // Hide if all pets can't be undone
      itineraryId,
      disabled: isLoading,
      patchStatusPayload: getChangeStatusPayload({
        petIds: petsWithUndoStatuses, // Only send pets with a status that can be undone
        shouldUndo: true,
      }),
    };
  },

  dispatch => {
    return {
      dispatchPatchItineraryStatus: ({ itineraryId, data }) => {
        dispatch(
          showConfirmationModal({
            header: "Are you sure?",
            content: `Are you sure you want to undo for all?`,
            confirmText: "Yes",
            confirm: () =>
              dispatch(
                patchHotelItineraryStatus({
                  itineraryId,
                  data,
                }),
              ),
            closeBeforeConfirm: true,
          }),
        );
      },
    };
  },
  (mapProps, dispatchProps) => {
    const { itineraryId, patchStatusPayload, disabled, label, isHidden } = mapProps;
    const { dispatchPatchItineraryStatus } = dispatchProps;
    return {
      // control what props get passed to the view
      // actions to pass to view
      label,
      disabled,
      isHidden,
      onClick: () => {
        const data = patchStatusPayload;
        dispatchPatchItineraryStatus({
          itineraryId,
          data,
          isMulti: true,
        });
      },
    };
  },
)(UndoButton);
