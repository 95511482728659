import { isEmpty, values } from "lodash/fp";

/**
 * Checks if a form is empty
 *
 * @export isFormEmpty
 * @param { Object } values - An object with form values.
 * @returns { Boolean } - returns true/false if form values are empty.
 */

export default function isFormEmpty(values) {
  return isEmpty(values) || Object.values(values).every(value => !value);
}
