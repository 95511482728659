import React from "react";
import CommonDeleteModal from "../../../../../../common/modals/shared/CommonDeleteModal";

export default ({ vaccinationToDelete, customerAndPetKeys, isLoading, handleRemove, onClose }) => (
  <CommonDeleteModal
    heading="Remove vaccination"
    message="Are you sure you want to remove this vaccination?"
    buttonLabel="Remove vaccination"
    handleRemove={() => handleRemove(vaccinationToDelete, customerAndPetKeys)}
    onClose={onClose}
    disabled={isLoading}
  />
);
