import { associateWebAPI } from "./_axiosConfigAssociateWeb";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Adds a phone number to a customer from the Web Associate API - Adds a phone number to a customer end point.
 * @param {*} { customerKey, sourceId, data }
 * @returns
 */
export function postPhoneNumber({ customerKey, sourceId, data }) {
  return associateWebAPI.post(
    `${endpointVersionString()}/customers/${customerKey}/phones?sourceId=${sourceId}`,
    data
  );
}
