import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { getActionClickedReasons } from "dux/eligibility/selectors/getReasonsSelectors";
import { eligibilityPetActionTypes } from "dux/eligibility/actions/eligibilityPetActions";
import { showCheckInOutButtonModal } from "../../../core/actionCreators/checkInOutActionCreator";
import { showConfirmationModal } from "../../../core/actionCreators/confirmationModalActionCreators";
import { updateAppointmentNote } from "../../../core/actionCreators/notesActionCreator";
import withEligibility from "../../common/hoc/withEligibility";
import CheckInButtonComponent from "./checkInButtonComponent";
import {
  getCanFinalizeBooking,
  getSelectedItinerary,
  getSelectedPet,
} from "../../../core/selectors/bookingUISelectors";
import { getPetEngagementNoteFormDataWithHOPNotes } from "../../../core/selectors/entities/engagementNotesEntitesSelector";
import {
  getAppointmentNote,
  getSelectedPetServiceItem,
} from "../../../core/selectors/cartSelectors";
import { getItinerary } from "../../../core/selectors/entitiesSelector";
import bookingActionCreators from "../../../core/actionCreators/bookingActionCreators";
import { getCheckInOutButtonModalType } from "../../../core/selectors/modals/checkInOutButtonModalSelectors";
import { modalTypes } from "../../../core/constants/modalConstants";
import { createLoadingSelector } from "../../../core/selectors/utils";
import itinerariesActionTypes from "../../../core/actionTypes/itinerariesActionTypes";
import { getIsStandaloneNotBookable } from "dux/_selectors/standaloneSelectors";
import { getCheckInScreenEligibility } from "@/dux/eligibility/actions/eligibilityByScreenActions";
import { isFromStandalone } from "@/core/utils/validationUtils/isFromValidation";

const mapStateToProps = (state, { router: { location } }) => {
  const petId = getSelectedPet(state);
  const itineraryId = getSelectedItinerary(state);
  const canFinalizeBooking = getCanFinalizeBooking(state);
  const isLoading = createLoadingSelector([
    itinerariesActionTypes.FINALIZE_ITINERARY,
    eligibilityPetActionTypes.GET_PET_ELIGIBILITY,
  ])(state);
  const isStandaloneNotBookable = getIsStandaloneNotBookable(state, { petId, location });
  return {
    petId,
    itineraryId,
    disabled: !canFinalizeBooking.value || isLoading || isStandaloneNotBookable,
    ModalType: getCheckInOutButtonModalType(state),
    itinerary: getItinerary(state, { itineraryId }),
    petServiceItemId: getSelectedPetServiceItem(state, { petId }),
    petEngagementNoteFormData: getPetEngagementNoteFormDataWithHOPNotes(state, {
      isHOPNote: true,
    }),
    appointmentNote: getAppointmentNote(state, {
      petId,
    }),
    eligibilityWarnings: getActionClickedReasons(state),
    isFromStandaloneFlow: isFromStandalone(location.pathname),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAppointmentNote: ({ appointmentNote, customerId, itineraryId, petServiceItemId }) => {
      dispatch(
        updateAppointmentNote({
          data: { note: appointmentNote },
          customerId,
          itineraryId,
          petServiceItemId,
        }),
      );
    },
    checkInSingle: ({ customerId }) => {
      dispatch(
        bookingActionCreators.finalizeAppointment({ customerKey: customerId, shouldCheckIn: true }),
      );
    },
    showConfirmationModal: args => dispatch(showConfirmationModal(args)),
    showEligibilityWarningsModal: () => {
      dispatch(showCheckInOutButtonModal({ modalType: modalTypes.ELIGIBILITY_WARNINGS }));
    },
    dispatchGetCheckInScreenEligibility: ({ customerKey, itineraryId }) => {
      dispatch(getCheckInScreenEligibility({ customerKey, itineraryId }));
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getCheckInScreenEligibility: () => {
      const { router } = ownProps;
      const { customerKey } = router.params;
      const { itineraryId } = stateProps;
      const { dispatchGetCheckInScreenEligibility } = dispatchProps;

      dispatchGetCheckInScreenEligibility({ customerKey, itineraryId });
    },
  };
};

export default withRouteProps(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(withEligibility(CheckInButtonComponent)),
);
