import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";

import SchedulesComponent from "./schedulesComponent";
import {
  getAssociatesWeekSchedule,
  getStartWeekDate,
  isAllAssociatesWeekScheduleFetched,
  getFilteredAssociatesByGroup
} from "../../core/selectors/schedulesSelectors";
import { createLoadingSelector } from "../../core/selectors/utils";
import { getDayActivities } from "../../core/selectors/entitiesSelector";
import { getAssociatesOfCurrentStore } from "../../core/selectors/associatesSelectors";
import {
  loadAssociatesSchedule,
  setSchedulesSearchValue
} from "../../core/actionCreators/schedulesActionCreators";
import schedulesActionTypes from "../../core/actionTypes/schedulesActionTypes";
import { loadAssociates } from "../../core/actionCreators/associateActionCreator";
import { getSalonHours } from "../../core/selectors/salonHoursSelector";
import { loadSalonHours } from "../../core/actionCreators/salonHoursActionCreators";

const mapStateToProps = state => {
  const associates = getAssociatesOfCurrentStore(state);
  const startWeekDate = getStartWeekDate(state);

  return {
    startWeekDate,
    isLoading: createLoadingSelector([schedulesActionTypes.LOAD_ASSOCIATES_SCHEDULE])(state),
    schedules: !isEmpty(associates) && getAssociatesWeekSchedule(state),
    dayActivities: getDayActivities(state),
    isAllAssociatesWeekScheduleFetched: isAllAssociatesWeekScheduleFetched(state),
    filteredAssociatesByGroup: getFilteredAssociatesByGroup(state),
    salonHours: getSalonHours(state)
  };
};

const mapDispatchToProps = dispatch => ({
  loadAssociatesSchedule: ({ fromDate }) => dispatch(loadAssociatesSchedule({ fromDate })),
  setSchedulesSearchValue: searchValue => dispatch(setSchedulesSearchValue({ searchValue })),
  loadAssociates: ({ isLimited }) => dispatch(loadAssociates({ isLimited })),
  loadSalonHours: ({ beginDate, endDate }) => dispatch(loadSalonHours({ beginDate, endDate }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedulesComponent);
