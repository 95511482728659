import { connect } from "react-redux";
import { getCustomer, getPetsByIds, getPetsByItinerary } from "core/selectors/entitiesSelector";
import joinWithLastDelimiter from "core/utils/stringManipulationUtils/joinWithLastDelimiter";
import {
  getCurrentItinerary,
  getIsCurrentItineraryPriceGreaterThanZero,
} from "core/selectors/checkInOutSelector";
import { PAYMENT_MESSAGE, REDEEMED_MESSAGE } from "dux/modals/checkIn/constants/checkInConstants";
import PayNowConfirmationButtonMultiAppointmentContainer from "dux/modals/confirmationModal/PayNowConfirmationButtonMultiAppointmentContainer";
import CheckInMultiModalContent from "../../../_components/modals/checkIn/multi/checkinMultiModalContent";

const mapStateToProps = (state, { customerKey }) => {
  const itineraryId = getCurrentItinerary(state);

  const petIds = getPetsByItinerary(state, { itineraryId });
  const pets = getPetsByIds(state, { petIds });
  const petNamesArray = pets.map(pet => pet.petName);
  const petNamesString = joinWithLastDelimiter(petNamesArray);
  const customer = getCustomer(state, { customerKey });

  return {
    isHidden: false,
    headerText: `Great Job! ${petNamesString} ${customer.lastName} are now checked in`,

    // If the itinerary's current price is not greater than zero, then the bundle has not been fully redeemed
    // (it is either a BGM or FTCO bundle package that has additional add-ons, or is a regular appointment).
    // In this case, we want to display a message asking if the customer wants to pay rather than a message
    // asking if appointment has been redeemed.
    message: getIsCurrentItineraryPriceGreaterThanZero(state, { itineraryId })
      ? PAYMENT_MESSAGE
      : REDEEMED_MESSAGE,
    diComp: PayNowConfirmationButtonMultiAppointmentContainer,
  };
};

export default connect(mapStateToProps)(CheckInMultiModalContent);
