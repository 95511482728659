import modalInitState from "@/core/reducers/modals/_modalInitState";
import getUpdatedModalState from "@/core/reducers/modals/helpers/getUpdatedModalState";
import { HIDE_OVERBOOKING_MODAL, SHOW_OVERBOOKING_MODAL } from "./overbookingModalsActions";

const initialState = modalInitState();

/**
 * Redux Reducer to add overbooking modals to the Redux State
 * @memberOf Reducer.Overbooking
 * @function
 * @name overbookingModalsReducer
 * @param {Object} state - Redux state
 * @param {Object} action - Redux Action
 * @returns {{}}
 */
export const overbookingModalsReducer = (state = initialState, action) => {
  return getUpdatedModalState(
    state,
    initialState,
    SHOW_OVERBOOKING_MODAL,
    HIDE_OVERBOOKING_MODAL,
    action,
  );
};
