import React from "react";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import AssociateProfileSalon from "./AssociateProfileSalon";
import AssociateProfileTraining from "./AssociateProfileTraining";

export const PROFILE_COMPONENT_TYPES = {
  [systemName.SALON]: <AssociateProfileSalon />,
  [systemName.TRAINING]: <AssociateProfileTraining />,
};

export const EMPLOYEE_GROUP_TYPES = {
  TRAINER: "Trainer",
  STYLIST: "Stylist",
};
