// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-layout__switcher {
  --setSwitcherSpace: ;
  --setSwitcherThreshold: ;
  --setSidebarSpace: ;
}

.psm-layout__switcher > * {
  /* ↓ Establish the flex context and allow wrapping */
  display: flex;
  flex-wrap: wrap;
  /* ↓ Negate the margin to follow
  (\`* -1\` makes the value negative) */
  margin: calc((var(--setSwitcherSpace) / 2) * -1);
}
.psm-layout__switcher > * > * {
  /* ↓ Allow growing, to take up available space */
  flex-grow: 1;
  /* ↓ Create the container-based breakpoint
  (returns a negative or (large) positive value */
  flex-basis: calc((var(--setSwitcherThreshold) - (100% - var(--setSwitcherSpace))) * 999);
  /* ↓ Half margins meet to make the whole space value together */
  margin: calc(var(--setSwitcherSpace) / 2);
}
`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/components/Layout/Switcher/styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,oDAAoD;EACpD,aAAa;EACb,eAAe;EACf;qCACmC;EACnC,gDAAgD;AAClD;AACA;EACE,gDAAgD;EAChD,YAAY;EACZ;iDAC+C;EAC/C,wFAAwF;EACxF,+DAA+D;EAC/D,yCAAyC;AAC3C","sourcesContent":[".psm-layout__switcher {\n  --setSwitcherSpace: ;\n  --setSwitcherThreshold: ;\n  --setSidebarSpace: ;\n}\n\n.psm-layout__switcher > * {\n  /* ↓ Establish the flex context and allow wrapping */\n  display: flex;\n  flex-wrap: wrap;\n  /* ↓ Negate the margin to follow\n  (`* -1` makes the value negative) */\n  margin: calc((var(--setSwitcherSpace) / 2) * -1);\n}\n.psm-layout__switcher > * > * {\n  /* ↓ Allow growing, to take up available space */\n  flex-grow: 1;\n  /* ↓ Create the container-based breakpoint\n  (returns a negative or (large) positive value */\n  flex-basis: calc((var(--setSwitcherThreshold) - (100% - var(--setSwitcherSpace))) * 999);\n  /* ↓ Half margins meet to make the whole space value together */\n  margin: calc(var(--setSwitcherSpace) / 2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
