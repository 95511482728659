import moment from "moment";
import { formatMoment } from "../utils/dateUtils/formatDateTime";
import { serviceTypeIds } from "core/constants/serviceTypesConstants";

export const getWeekBeginEndDates = selectedDate => ({
  beginDate: moment(selectedDate)
    .startOf("isoWeek")
    .format("YYYY-MM-DD"),
  endDate: moment(selectedDate)
    .endOf("isoWeek")
    .format("YYYY-MM-DD")
});

export const getDefaultSalonHoursObject = date => ({
  ForDate: date,
  DayOfWeek: moment(date).format("dddd"),
  IsClosed: false,
  OpenTime: "07:00:00",
  CloseTime: "21:00:00"
});

export const getPastSalonHours = ({ beginDate, endDate }) => {
  const beginDateMoment = moment(beginDate);
  const endDateMoment = moment(endDate);

  let pastSalonHours = [];
  // Create salonHoursObject for each day in the past
  while (beginDateMoment.diff(moment(), "days") < 0 && beginDateMoment.diff(endDateMoment) < 0) {
    pastSalonHours = [...pastSalonHours, getDefaultSalonHoursObject(formatMoment(beginDateMoment))];
    beginDateMoment.add(1, "days");
  }

  return pastSalonHours;
};

export const mergeSalonHoursWithPastSalonHours = ({ services, pastSalonHours }) =>
  services.map(service => {
    // Merge past store hours with store hours returned from API if grooming service type
    const hoursToMerge = service.ServiceId === serviceTypeIds.GROOMING ? pastSalonHours : [];
    return {
      ...service,
      StoreServiceHoursForDateList: [...hoursToMerge, ...service.StoreServiceHoursForDateList]
    };
  });
