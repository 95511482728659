// Pluck ---------------------------------------------------------------------------------------------------------------
export function pluck<T, K extends keyof T>(obj: T, property: K): T[K] {
  return obj[property];
}

// Rename key ----------------------------------------------------------------------------------------------------------
export const renameKey = <OldKey extends keyof T, NewKey extends string, T extends Record<string, unknown>>(
  oldKey: OldKey,
  newKey: NewKey extends keyof T ? never : NewKey,
  userObject: T
): Record<NewKey, T[OldKey]> & Omit<T, OldKey> => {
  const { [oldKey]: value, ...common } = userObject;

  return {
    ...common,
    ...({ [newKey]: value } as Record<NewKey, T[OldKey]>),
  };
};

// Call all ------------------------------------------------------------------------------------------------------------
interface CallBack<Params extends any[]> {
  (...args: Params): void;
}

export const callAll = <Params extends any[]>(...fns: Array<CallBack<Params> | undefined>) => (...args: Params) =>
  fns.forEach((fn) => typeof fn === 'function' && fn(...args));

// String utils --------------------------------------------------------------------------------------------------------
export function toCamelCase(sentence = '') {
  return sentence.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (camelCaseMatch, i) {
    if (+camelCaseMatch === 0) return '';
    return i === 0 ? camelCaseMatch.toLowerCase() : camelCaseMatch.toUpperCase();
  });
}
