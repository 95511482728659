import { CUSTOMER_ID_HEADER_KEY } from "@/core/constants/endpointConstants";
import { associateWebBFFAPI } from "./_axiosConfigAssociateWebBff";
import { endpointVersionString } from "../../utils/envUtils";

/**
 *  PATCH API to add manual specials to an itinerary - using BFF axios config
 *
 *  @memberOf APIs.hotel.itinerary
 *  @function
 *  @name patchApplySpecialsEndpoint
 *  @param {Object} obj
 *  @param {Number} obj.itineraryId
 *  @param {Number} obj.customerId
 *  @param {Object} obj.data
 *  @returns {Promise}
 *  @example
 *
 *  const response = yield call(patchApplySpecialsEndpoint, {
 *       itineraryId,
 *       customerId,
 *       data,
 *     });
 */
export function patchApplySpecialsEndpoint({ itineraryId, data, customerId }) {
  return associateWebBFFAPI.patch(
    `${endpointVersionString()}/hotel/itineraries/${itineraryId}/specials`,
    data,
    {
      headers: { [CUSTOMER_ID_HEADER_KEY]: customerId },
    },
  );
}
