import { createSelector } from "reselect";
import moment from "moment";
import momentTz from "moment-timezone";
import { getStoreTimeZone } from "@/core/selectors/entitiesSelector";
import {
  getFirstHotelEngagementByPet,
  getLastDayEngagementByPetId,
} from "../hotelEngagements/hotelEngagementSelectors";

/**
 *  Selector to get common data used for check in out datetime components
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectCheckInOutDateTimeCommonState
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {string} props.petId
 *  @param {string} props.storeNumber - the storeNumber on the current hotel itinerary
 *  @returns {Object} contains the first and last engagement,
 *    and the check in out datetimes formatted in the stores time zone
 *  @example selectCheckInOutDateTimeCommonState(state, { petId, storeNumber })
 */
export const selectCheckInOutDateTimeCommonState = createSelector(
  [getFirstHotelEngagementByPet, getLastDayEngagementByPetId, getStoreTimeZone],
  (firstEngagement, lastEngagement, storeTimeZone) => {
    const endDateTime = momentTz.tz(lastEngagement?.endDatetime, storeTimeZone);
    const startDateTime = momentTz.tz(firstEngagement?.startDatetime, storeTimeZone);

    return { firstEngagement, lastEngagement, startDateTime, endDateTime, storeTimeZone };
  },
);
