import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import TimeRangePicker from "web/common/TimeRange/TimeRangePicker";
import { absenceReasons, dayActivityTypes } from "core/constants/schedulesConstants";
import { getDaySalonHours } from "core/selectors/schedulesSelectors";
import { setWeeklyEditDayActivity } from "core/actionCreators/schedulesActionCreators";
import { LayoutBox } from "@/layout/box/Box";
import { buildShiftDayActivities, findChangedActivity } from "core/utils/schedulesUtils";
import { getTimeRangeError } from "../../utils";

/**
 * Represents a component for selecting and displaying a time range.
 *
 * @summary Located on the my-schedule/:associateId page.
 * @memberOf Views.Associate
 * @function
 * @name  TimeRangeComponent
 * @typedef {Object} TimeRangeComponentProps
 * @property {boolean} isHidden - Indicates whether the component is hidden or not.
 * @property {boolean} isValid - Indicates whether the selected time range is valid.
 * @property {boolean} readOnly - Indicates whether the component is in read-only mode.
 * @property {string} componentId - The unique identifier for the component.
 * @property {string} initStartTime - The initial start time when the component loads.
 * @property {string} initEndTime - The initial end time when the component loads.
 * @property {function} handleTimeChange - Event handler for time change events.
 * @returns {JSX.Element|null} The JSX element representing the TimeRangeComponent.
 */
export const TimeRangeComponent = props => {
  const { isHidden, isValid, readOnly, componentId } = props;

  // initial start and end times when the component loads
  const { initStartTime, initEndTime } = props;

  // Event Handlers
  const { handleTimeChange } = props;

  if (isHidden) {
    return null;
  }

  if (readOnly) {
    return (
      <div>
        <span>{initStartTime}</span> - <span>{initEndTime}</span>
      </div>
    );
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <TimeRangePicker
        backgroundColor="inherit"
        startTime={initStartTime}
        endTime={initEndTime}
        onChange={e => handleTimeChange(e)}
        isValid={isValid}
      />
    </LayoutBox>
  );
};

// CONTAINER ----->
export const AssociateWorkHoursTimeRange = connect(
  (state, { partialRow, weekDayDate }) => {
    const initStartTime = moment(partialRow?.startTime, "HH:mm").format("HH:mm:ss");
    const initEndTime = moment(partialRow?.endTime, "HH:mm").format("HH:mm:ss");

    const daySalonHours = getDaySalonHours(state, { weeklyEditActivity: partialRow, weekDayDate });

    const isValid = !getTimeRangeError({
      startTime: initStartTime,
      endTime: initEndTime,
      type: partialRow?.type,
      daySalonHours,
    });

    return {
      componentId: "AssociateWorkHoursTimeRange",
      isHidden:
        partialRow?.absenceReason === absenceReasons.DAY_OFF || partialRow?.type === undefined,
      initStartTime,
      initEndTime,
      isValid,
    };
  },

  (dispatch, { dayActivities }) => {
    return {
      dispatchWeeklyEditDayActivity: newActivityTimes => {
        const activities = buildShiftDayActivities(dayActivities, newActivityTimes);
        // fire an action for each activity: morning block, lunch, afternoon block
        activities.forEach(({ localId, startTime, endTime, isSplitShift }) =>
          dispatch(
            setWeeklyEditDayActivity({ localId, startTime, endTime, hasChanged: true, isSplitShift }),
          ),
        );
      },
    };
  },

  (mapProps, dispatchProps, ownProps) => {
    const { isHidden, isValid, readOnly, componentId, initStartTime, initEndTime } = mapProps;
    const { dispatchWeeklyEditDayActivity } = dispatchProps;
    const { partialRow, dayActivities } = ownProps;
    return {
      // control what props get passed to the view
      isHidden,
      isValid,
      readOnly,
      componentId,
      initStartTime,
      initEndTime,

      // actions to pass to view
      handleTimeChange: e => {
        const { startTime, endTime } = e;

        const formattedStartTime = moment(startTime, "HH:mm").format("HH:mm:ss");
        const formattedEndTime = moment(endTime, "HH:mm").format("HH:mm:ss");

        if (partialRow?.type !== dayActivityTypes.WORK) {
          dispatchWeeklyEditDayActivity({
            localId: partialRow?.localId,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
          });
        } else {
          const isEndTimeChanged = partialRow?.endTime !== formattedEndTime;
          const changedActivity = findChangedActivity(dayActivities, isEndTimeChanged, {
            startTime: formattedStartTime,
            endTime: formattedEndTime,
          });

          dispatchWeeklyEditDayActivity({
            localId: changedActivity.localId,
            startTime: changedActivity.startTime,
            endTime: changedActivity.endTime,
          });
        }
      },
    };
  },
)(TimeRangeComponent);
// <---------
