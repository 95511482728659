import { connect } from "react-redux";
import { Text } from "@prism/psm-ui-components";
import { getIsStoreHoursErrorDisplayed } from "@/dux/storeHoursError/storeHoursErrorSelectors";
import { NO_STORE_NUMBER_FOUND_ERROR_MESSAGE } from "@/dux/storeHoursError/storeHoursErrorConstants";

const mapStateToProps = state => ({
  isHidden: !getIsStoreHoursErrorDisplayed(state),
  children: NO_STORE_NUMBER_FOUND_ERROR_MESSAGE,
});

export default connect(mapStateToProps)(Text);
