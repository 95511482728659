import { createSelector } from "reselect";
import { findIndex, filter, map, uniq } from "lodash/fp";

import { getCurrentCustomer } from "core/selectors/persistent/customer/customerSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getHotelEngagements } from "dux/hotelEngagements/hotelEngagementSelectors";
import { getPets } from "core/selectors/entitiesSelector";
import { getPetCart } from "dux/petCartCard/selectors/petCartCardSelectors";
import { sortHotelEngagements } from "@/core/utils/hotelEngagementUtils/sortHotelEngagements";
import { selectHotelReservationNotes } from "@/dux/hotelCheckInOutPetNotes/hotelCheckInOutPetNotesSelectors";
import { getHotelItinerary } from "./hotelItinerarySelectors";

// Provides a complete itinerary body rebuilt from State.
export const getConstructedHotelItinerary = createSelector(
  [
    getHotelItinerary,
    getCurrentCustomer,
    getHotelEngagements,
    getPets,
    getPetCart,
    selectHotelReservationNotes,
  ],
  (itinerary, fullCustomer, hotelEngagements, fullPets, petCart, reservationNotes) => {
    /**
     * builds a stripped down customer from entities.customer to only what hotel itinerary needs
     * @param customer
     * @returns {{customerKey: *, customerPhone: *, isNewCustomer: *, customerName: string}}
     */
    const buildCustomer = customer => {
      // We need to extract the hotel profile info from the full entities customer,
      // since profiles is an array, we need the index number to grab the correct profile
      const hotelProfileIndex = findIndex(profile => {
        return profile.profileType === systemName.DDC_HOTEL;
      }, customer?.prismCustomer?.profiles);

      // We need to extract customer's primary phone number from the full entities customer,
      // since phones is an array, we need the index number to grab the correct phone number
      const primaryPhoneIndex = findIndex(phone => {
        return phone.isPrimary === true;
      }, customer?.phones);

      // get full customer from Entities in state and pull out only what is needed for hotel itinerary
      return {
        customerKey: customer?.customerKey,
        customerName: `${customer?.firstName} ${customer?.lastName}`,
        isNewCustomer: customer?.prismCustomer?.profiles[hotelProfileIndex]?.isNewCustomer,
        customerPhone: customer?.phones[primaryPhoneIndex]?.phoneNumber,
      };
    };

    /**
     * Builds out pets with all associated engagements
     * @param engagements
     * @param pets
     * @returns {*}
     */
    const buildPets = (engagements, pets) => {
      // Since each pet in the hotel itinerary can have multiple engagements, and we have all the engagements in state
      // we need to make a list of all pets that are in the engagements, so we can map(iterate) over the pet ids
      // and build our pets with associated engagements.
      const petKeyList = uniq(
        map(engagement => {
          return engagement.petId;
        }, engagements),
      );

      // return pets with engagements
      return petKeyList.map(petId => {
        return {
          petKey: petId,
          petName: pets[petId]?.petName,
          reservationNotes: petCart[petId]?.reservationNotes || reservationNotes?.[petId] || "",
          needsReview: pets[petId]?.needsReview || false,
          // history is not needed in API calls, so filter it out
          engagements: sortHotelEngagements(
            filter(engagement => {
              return engagement.petId === petId;
            }, engagements).map(({ history, ...engagement }) => engagement),
          ),
        };
      });
    };

    // return full hotel itinerary body
    return {
      ...itinerary,
      customer: buildCustomer(fullCustomer),
      pets: buildPets(hotelEngagements, fullPets),
    };
  },
);
