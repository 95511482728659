import React, { useEffect } from "react";
import { withSaveAndCancel } from "@/web/common/hoc/withSaveAndCancel";

/**
 * Wrapper component that populates the ref & state of withSaveAndCancel for a form
 * @param {Object} props
 * @param {Function} props.submitForm
 * @param {Function} props.resetForm
 * @param {Object} props.touched - obj where keys are field names and values are booleans
 * @param {Object} props.errors - obj where keys are field names and values are strings
 * @param {JSX.Element} props.children
 * @param {React.Ref} props.formRef
 * @param {Function} props.handleErrors
 * @param {Function} props.handleTouched
 * @returns {JSX.Element}
 */
export const FormWithSaveAndCancel = withSaveAndCancel(props => {
  const { submitForm, resetForm, touched, values, errors, children } = props;

  // Props from withSaveAndCancel
  const { formRef, handleErrors, handleTouched } = props;

  useEffect(() => {
    formRef.current = { submitForm, resetForm };
  }, [values, submitForm, resetForm]);

  useEffect(() => {
    handleErrors(errors);
  }, [errors]);

  useEffect(() => {
    handleTouched(touched);
  }, [touched]);

  return <>{children}</>;
});
