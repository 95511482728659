import authActionTypes from "../actionTypes/authActionTypes";

const initialState = {
  authenticatedWithQuickPin: null,
  authorizedUsers: [],
  selectedUser: {},
  sessionTimeRemaining: window.env.SESSION_TOKEN_TIMEOUT,
  invalidPinError: null,
  showForgotPinModal: false,
  redirectToSetPin: false,
  modalType: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.SET_AUTHENTICATED_WITH_QUICK_PIN:
      return { ...state, authenticatedWithQuickPin: true };
    case authActionTypes.CLEAR_AUTHENTICATED_WITH_QUICK_PIN:
      return { ...state, authenticatedWithQuickPin: false };
    case authActionTypes.SET_AUTHENTICATED_USERS:
      return { ...state, authorizedUsers: action.users };
    case authActionTypes.SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          name: action.name,
          username: action.username,
        },
      };
    case authActionTypes.CLEAR_SELECTED_USER:
      return { ...state, selectedUser: initialState.selectedUser };
    case authActionTypes.SET_SESSION_TIME_REMAINING:
      return { ...state, sessionTimeRemaining: action.milliseconds };
    case authActionTypes.SET_INVALID_PIN_ERROR:
      return { ...state, invalidPinError: action.invalidPinError };
    case authActionTypes.CLEAR_INVALID_PIN_ERROR:
      return { ...state, invalidPinError: initialState.invalidPinError };
    case authActionTypes.SET_REDIRECT_TO_SET_PIN:
      return { ...state, redirectToSetPin: true };
    case authActionTypes.CLEAR_REDIRECT_TO_SET_PIN:
      return { ...state, redirectToSetPin: initialState.showSetPinModal };
    default:
      return state;
  }
};

export default authReducer;
