import isEqual from "lodash/isEqual";

import { checkFrequenciesMatch } from "@/dux/frequency/frequencySelectors";

/**
 * Determines if the single day hotel checkin page addon modal save button should be disabled
 * @param {number} quantity
 * @param {boolean} isLoading
 * @param {object} existingAddon
 * @returns boolean
 * @example
 * isSingleDaySaveDisabled({
      quantity,
      isLoading,
      existingAddon,
    })
 */
export const isSingleDaySaveDisabled = ({ quantity, isLoading, existingAddon }) =>
  isLoading || (!existingAddon?.addOnProductNumber && quantity === 0);

/**
 * Helper to check if save button in addon modal should be disabled
 * for overnight reservations, for booking & checkin flows
 *
 * @memberOf Utils.Addons
 * @function
 * @name isOvernightSaveDisabled
 * @param {Object} args
 * @param {Object} args.existingAddon - an addon object from cart or itinerary with frequency and customFrequencyDates
 * @param {boolean} args.hasPendingFrequency - true if pendingFrequencyId matches the current addon being updated
 * @param {Object} args.initialInstructions - notes or comment from existing addon or empty string
 * @param {Object} args.instructions - user entered notes
 * @param {string} args.frequency - pending frequency
 * @param {string[]} args.customFrequencyDates - pending frequency dates
 * @param {number} args.quantity - existing addon quantity or pending dates length
 * @returns {Boolean}
 * @example
 * isOvernightSaveDisabled({
 *    existingAddon,
 *    hasPendingFrequency,
 *    initialInstructions,
 *    instructions,
 *    frequency,
 *    customFrequencyDates,
 *    quantity
 * })
 */
export const isOvernightSaveDisabled = ({
  existingAddon,
  hasPendingFrequency,
  initialInstructions,
  instructions,
  frequency,
  customFrequencyDates,
  quantity,
}) => {
  const hasNoFrequency = !existingAddon ? !hasPendingFrequency : quantity === 0;
  if (hasNoFrequency) return true;

  const instructionsUnedited = isEqual(instructions, initialInstructions);
  const frequencyUnedited = checkFrequenciesMatch({
    frequencyA: frequency,
    datesA: customFrequencyDates,
    frequencyB: existingAddon?.frequency,
    datesB: existingAddon?.customFrequencyDates ?? [],
  });

  return !!existingAddon && instructionsUnedited && frequencyUnedited;
};
