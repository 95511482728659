import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD";

/**
 * Util to format the dates on an overbooking exception obj
 * @param {Object} exception - overbooking exception
 * @returns overbooking exception with dates formatted "YYYY-MM-DD"
 */
export const formatExceptionDates = exception => {
  const startDate = moment(exception?.startDate, DATE_FORMAT).format(DATE_FORMAT);
  const endDate = moment(exception?.endDate, DATE_FORMAT).format(DATE_FORMAT);
  return { ...exception, startDate, endDate };
};
