import React from "react";
import { LayoutStack } from "@/layout/stack/Stack";
import {
  DoggyDayCampRoomCapacity,
  HotelRoomCapacity,
} from "dux/_components/roomCapacity/RoomCapacity";

export default function RoomCapacityWrapper(props) {
  const { isHidden, componentID } = props;

  if (isHidden) return null;
  return (
    <LayoutStack id={componentID}>
      <HotelRoomCapacity />
      <DoggyDayCampRoomCapacity />
    </LayoutStack>
  );
}
