import moment from "moment";

/**
 * A check if more than the minimum number of minutes has passed.
 * @param {String | Date } startTime - Start time to check against
 * @param {String | Date } checkedTime - Time you want to check if passed X minutes
 * @param { Number } numMinPassed - Number of Minutes to check
 * @return {boolean}
 */
export function hasMinPassed(startTime, checkedTime, numMinPassed) {
  const startTimePlusXMin = moment(startTime).add(numMinPassed, "minutes");

  const isGreaterThanXMinPassed = moment(checkedTime).isAfter(startTimePlusXMin);
  return isGreaterThanXMinPassed;
}
