import React from "react";
import SetFrequencyMedicationModalContainer from "dux/frequency/modal/SetFrequencyModalContainer";
import { frequencyActionTypes } from "dux/frequency/actions/frequencyActions";
import { modalTypes as commonModalTypes } from "core/constants/modalConstants";
import { modalTypes } from "core/constants/bookingConstants";
import { history } from "@/dux/utils/browser/browserHistory";
import { HotelCheckInSinglePetAlertsModal } from "web/hotelAlerts/hotelCheckInOutAlertsModal";
import CommonModal from "../../common/modals/commonModal";
import ModalWithoutClose from "../../common/modals/ModalWithoutClose";
import GanttZoomContent from "../../common/modals/content/gantZoom/ganttZoomContainer";
import AppointmentSet from "./content/AppointmentSetContainer";
import BookingRedirectionModal from "./content/BookingRedirectionModalContainer";
import RemoveSpecial from "../../common/modals/content/removeSpecial/removeSpecialContainer";
import CancelBookingAppointment from "./content/cancelBookingAppointment/cancelBookingAppointmentContainer";
import AlertsModal from "../../common/modals/content/eligibilityModals/AlertsModalContainer";
import CancelAppointment from "../../cancelAppointment/cancelAppointmentContainer";
import { hotelBookingTypes } from "../../hotelAlerts/hotelBookingConstants";
import ManualBookingAlertModalContainer from "../../../dux/bookingModals/ManualBookingModalContainer";
import VIPBookingAlertMessageModalContainer from "../../vipBookingAlertMessage/VIPBookingAlertMessageModalContainer";
import BulkPackagePurchaseModalContainer from "../../../dux/bgm/bulkPackageOfferings/cartDetails/BulkPackagePurchaseModalContainer";
import { HotelBookingRestartModal } from "@/dux/bookingRestartModal/BookingRestartModal";
import { HotelBookingPreExistingReservationModal } from "@/dux/bookingPreExistingReservationModal/bookingPreExistingReservationModal";
import { SetHotelAppointmentsModal } from "dux/_components/modals/AppointmentSetModalComponent";
import { routePaths } from "@/core/constants/routePaths";
import {
  HotelRebookAddonNotAvailable,
  HotelRebookFoodsNotAvailable,
  HotelRebookMedsNotAvailable,
} from "@/dux/rebookingItemsNotAvailable/RebookingItemsNotAvailable";

const bookingOnClose = props => {
  switch (props.modalType) {
    case modalTypes.BOOKING_REDIRECTION_MODAL:
      return () => history.push("/dashboard");
    case modalTypes.APPOINTMENT_SET:
      return () => history.push("/dashboard");
    case commonModalTypes.ELIGIBILITY_WARNINGS &&
      (history.location.pathname.includes("time-slot") ||
        history.location.pathname.includes("modify-appointment")):
      return () => {
        props.onClose();
        props.onShouldProceed(false);
      };
    case frequencyActionTypes.SHOW_FREQUENCY_MODAL:
      return () => {
        props.onClose();
        props.clearFrequency();
      };
    case hotelBookingTypes.HOTEL_APPOINTMENT_SET:
      return () => {
        props.onClose();
        history.push(routePaths.DASHBOARD);
      };
    default:
      return () => props.onClose();
  }
};

const selectModalContent = props => {
  switch (props.modalType) {
    case commonModalTypes.GANTT_ZOOM:
      return <GanttZoomContent {...props} />;

    case modalTypes.APPOINTMENT_SET:
      return <AppointmentSet customerKey={props.customerKey} {...props} />;

    case hotelBookingTypes.HOTEL_APPOINTMENT_SET:
      return <SetHotelAppointmentsModal {...props} />;

    case modalTypes.APPOINTMENT_MODIFIED:
      return <AppointmentSet customerKey={props.customerKey} isModified {...props} />;

    case commonModalTypes.REMOVE_SPECIAL:
      return <RemoveSpecial {...props} />;

    case modalTypes.CANCEL_BOOKING_APPOINTMENT:
      return <CancelBookingAppointment {...props} />;

    case commonModalTypes.CANCEL_APPOINTMENT:
      return <CancelAppointment />;

    case commonModalTypes.ALERTS:
      return <AlertsModal {...props} />;

    case commonModalTypes.IS_VIP:
      return <VIPBookingAlertMessageModalContainer {...props} />;

    case hotelBookingTypes.HOTEL_BOOKING_ALERT_MODAL:
      return <HotelCheckInSinglePetAlertsModal {...props} />;

    case hotelBookingTypes.HOTEL_MANUAL_BOOKING_CONFIRMATION_MODAL:
      return <ManualBookingAlertModalContainer {...props} />;

    case commonModalTypes.ELIGIBILITY_WARNINGS:
      return <AlertsModal {...props} isWarnings />;

    case modalTypes.BOOKING_REDIRECTION_MODAL:
      return <BookingRedirectionModal {...props} />;

    case frequencyActionTypes.SHOW_FREQUENCY_MODAL:
      return <SetFrequencyMedicationModalContainer />;

    case commonModalTypes.PURCHASE_BULK_PACKAGE:
      return <BulkPackagePurchaseModalContainer />;

    case hotelBookingTypes.HOTEL_BOOKING_RESTART_MODAL:
      return <HotelBookingRestartModal {...props} />;

    case hotelBookingTypes.PRE_EXISTING_RESERVATION_MODAL:
      return <HotelBookingPreExistingReservationModal {...props} />;

    case hotelBookingTypes.REBOOKING_ADDON_NOT_AVAILABLE:
      return <HotelRebookAddonNotAvailable {...props} />;

    case hotelBookingTypes.REBOOKING_FOOD_NOT_AVAILABLE:
      return <HotelRebookFoodsNotAvailable {...props} />;

    case hotelBookingTypes.REBOOKING_MED_NOT_AVAILABLE:
      return <HotelRebookMedsNotAvailable {...props} />;

    default:
      return null;
  }
};

const BookingModals = props => {
  const { modalType } = props;

  if (modalType === commonModalTypes.PURCHASE_BULK_PACKAGE) {
    return (
      <ModalWithoutClose componentId="purchase_bulk_package_modal">
        {selectModalContent(props)}
      </ModalWithoutClose>
    );
  }

  return <CommonModal onClose={bookingOnClose(props)}>{selectModalContent(props)}</CommonModal>;
};

export default BookingModals;
