import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import CommonButton from "web/common/commonButton";
import { hideCheckInOutModal } from "core/actionCreators/checkInOutActionCreator";

const mapStateToProps = () => {
  return {
    isHidden: false,
    label: "Done",
  };
};

const mapDispatchToProps = (dispatch, { router: { location, navigate } }) => {
  return {
    onClick: () => {
      if (location.pathName !== "/dashboard") {
        navigate("/dashboard");
      }
      dispatch(hideCheckInOutModal());
    },
  };
};

export default withRouteProps(connect(mapStateToProps, mapDispatchToProps)(CommonButton));
