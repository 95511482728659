import { connect } from "react-redux";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { HotelPetProfileDetails } from "web/features/petProfile/HotelPetProfileDetails";
import PetProfileDetails from "web/petProfileDetails/PetProfileDetailsContainer";
import React from "react";
import BookingServiceComponent from "./BookingServiceComponent";
import { getSelectedPet } from "../../../core/selectors/bookingUISelectors";

const mapStateToProps = (state, ownProps) => ({
  selectedPet: getSelectedPet(state),
  isStandalone: ownProps.isStandalone,
  profileDetails:
    getSystemBookingFlow(state) === systemName.HOTEL ? (
      <HotelPetProfileDetails />
    ) : (
      <PetProfileDetails />
    ),
});

export default connect(mapStateToProps)(BookingServiceComponent);
