export default {
  CREATE_PET_HEALTH_CONDITION: "CREATE_PET_HEALTH_CONDITION",
  CREATE_PET_HEALTH_CONDITION_REQUEST: "CREATE_PET_HEALTH_CONDITION_REQUEST",
  CREATE_PET_HEALTH_CONDITION_SUCCESS: "CREATE_PET_HEALTH_CONDITION_SUCCESS",
  CREATE_PET_HEALTH_CONDITION_FAILURE: "CREATE_PET_HEALTH_CONDITION_FAILURE",
  DELETE_PET_HEALTH_CONDITION: "DELETE_PET_HEALTH_CONDITION",
  DELETE_PET_HEALTH_CONDITION_REQUEST: "DELETE_PET_HEALTH_CONDITION_REQUEST",
  DELETE_PET_HEALTH_CONDITION_SUCCESS: "DELETE_PET_HEALTH_CONDITION_SUCCESS",
  DELETE_PET_HEALTH_CONDITION_FAILURE: "DELETE_PET_HEALTH_CONDITION_FAILURE",
  UPDATE_PET_HEALTH_CONDITIONS: "UPDATE_PET_HEALTH_CONDITIONS",
  UPDATE_PET_HEALTH_CONDITIONS_REQUEST: "UPDATE_PET_HEALTH_CONDITIONS_REQUEST",
  UPDATE_PET_HEALTH_CONDITIONS_SUCCESS: "UPDATE_PET_HEALTH_CONDITIONS_SUCCESS",
  UPDATE_PET_HEALTH_CONDITIONS_FAILURE: "UPDATE_PET_HEALTH_CONDITIONS_FAILURE"
};
