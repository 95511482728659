import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Text } from "@prism/psm-ui-components";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { selectHotelCartPetNotes } from "@/dux/servicesCartHotel/servicesCartHotelSelectors";
import { putCart } from "@/dux/servicesCart/servicesCartActions";
import { setHotelCartPetNotes } from "@/dux/servicesCartHotel/servicesCartHotelActions";
import { selectIsReservationDisabled } from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { patchHotelItineraryPetNotes } from "@/dux/hotelItineraryPetNotes/hotelItineraryPetNotesActions";
import { LayoutStack } from "@/layout/stack/Stack";
import { selectHotelReservationNotesByPet } from "@/dux/hotelCheckInOutPetNotes/hotelCheckInOutPetNotesSelectors";

const BookingCartReservationNotesComponent = props => {
  const { isHidden, componentId, disabled, setNotes, reservationNotes, padding } = props;

  const [noteText, setNoteText] = useState("");

  useEffect(() => {
    setNoteText(reservationNotes || "");
  }, [reservationNotes]);

  const handleChange = () => {
    if (noteText === reservationNotes) return;
    
    setNotes({ notes: noteText });
  };

  if (isHidden) return null;

  return (
    <LayoutStack id={componentId} style={{ padding }}>
      <Text id={`${componentId}__label`} bold size="text-size-lg">
        Reservation Notes
      </Text>

      <Form.Field.TextArea
        disabled={disabled}
        rows={4}
        maxLength={255}
        style={{ padding: "1em 1em", fontSize: "1.15rem", fontFamily: "Lato-Bold, Lato" }}
        onInput={value => setNoteText(value)}
        onBlur={() => handleChange()}
        value={noteText}
      />
    </LayoutStack>
  );
};

// HOTEL BOOKING CART CONTAINER ----------------------------------------------------------------------------------------
export const BookingCartReservationNotesContainer = connect(
  (state, { petId }) => {
    return {
      componentId: "bookingHotel__ReservationNotesComponent",
      isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
      reservationNotes: selectHotelCartPetNotes(petId)(state),
      padding: "1em",
    };
  },
  (dispatch, { petId }) => {
    return {
      setNotes: ({ notes }) => {
        // Update the cart data with the new pet notes
        dispatch(setHotelCartPetNotes(petId, notes));

        // Dispatch a PUT to the Cart API
        dispatch(putCart());
      },
    };
  },
)(BookingCartReservationNotesComponent);

// HOTEL CHECK-IN CART CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInCartReservationNotes = connect(
  state => {
    const petId = getCurrentPet(state);

    // Current reservation - whats is stored in state.cart...reservationNotes.
    const reservationNotes = selectHotelReservationNotesByPet(state, { petId });

    const isReservationDisabled = selectIsReservationDisabled(state);

    return {
      componentId: "CheckInCartReservationNotes",
      disabled: isReservationDisabled,
      reservationNotes,
      padding: "1em 0",
      // for merge props
      petId,
    };
  },
  dispatch => {
    return {
      callSetReservationNotes: ({ notes, petId }) => {
        dispatch(
          patchHotelItineraryPetNotes({ pets: [{ petKey: petId, reservationNotes: notes }] }),
        );
      },
    };
  },
  (propsFromState, propsFromDispatch) => {
    const { componentId, disabled, reservationNotes, petId } = propsFromState;
    const { callSetReservationNotes } = propsFromDispatch;
    return {
      componentId,
      disabled,
      reservationNotes,
      setNotes: ({ notes }) => {
        callSetReservationNotes({ notes, petId });
      },
    };
  },
)(BookingCartReservationNotesComponent);
