import React from "react";
import moment from "moment";
import styled from "styled-components";

import Input from "./../Input";

const StyledTimePicker = styled.div`
  display: flex;
  width: 11.25rem;
  align-items: flex-end;
  justify-content: center;
`;

class TimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      time: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(newTime) {
    const { onChange } = this.props;
    const m = moment(newTime, "HH:mm");
    const time = this.props.time ? moment(this.props.time, "HH:mm") : null;

    if (m.isValid()) {
      onChange &&
        onChange(
          `${moment(time)
            .set("hours", m.hour())
            .format("HH")}:${m.format("mm")}`,
        );
    } else {
      this.setState({
        time,
      });
    }
  }

  render() {
    const { backgroundColor, error } = this.props;
    const time = this.props.time ? moment(this.props.time, "HH:mm") : null;

    return (
      <StyledTimePicker>
        <Input
          error={error}
          type="time"
          value={time ? time.format("HH:mm") : this.state.time}
          onChange={e => this.handleChange(e.target.value)}
          backgroundColor={backgroundColor}
        />
      </StyledTimePicker>
    );
  }
}

export default TimePicker;
