import { connect } from "react-redux";
import MedicationTimeWrapper from "./MedicationTimeWrapper";
import { getMedicationIsShowingDetails } from "../medicationList/medicationListSelectors";
import {
  getMedicationTimeOfDayTouched,
  getIsMedicationTimeOfDayEmpty,
} from "./medicationTimeOfDaySelectors";
import { getPetMedications } from "../medication/medicationsSelector";
import { getCurrentPet } from "../../core/selectors/persistentSelectors";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../core/constants/validationErrors";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, medicationIndex, petId } = ownProps;
  return {
    medicationId,
    petId,
    title: `Medication ${medicationIndex} time of day`,
    medications: getPetMedications(state, { petId }),
    error:
      getMedicationTimeOfDayTouched(state, { medicationId, petId }) &&
      getIsMedicationTimeOfDayEmpty(state, { medicationId, petId }) &&
      REQUIRED_FIELD_ERROR_MESSAGE,
  };
};

export default connect(mapStateToProps)(MedicationTimeWrapper);
