import * as React from 'react';
import { LayoutProps } from '../';
import { psx } from '../../../utils';
import './styles.css';

export interface FrameProps extends LayoutProps<React.ElementType> {
  /** Width value to use when calculating asepect ratio */
  width?: number;
  /** Height value to use when calculating asepect ratio */
  height?: number;
  /** Completely removes the component from the DOM. Useful for feature flags or t hide or show
   * a component. */
  isHidden?: boolean;
}

export const Frame: React.FunctionComponent<FrameProps> = ({
  children,
  className,
  as,
  testid,
  width = 16,
  height = 9,
  isHidden = false,
  ...props
}) => {
  const id = 'psm-layout__frame';
  const Component = as || 'div';
  const classNames = psx('psm', id, className);

  if (isHidden) {
    return null;
  }

  return (
    <Component
      style={{ '--width': width, '--height': height }}
      className={classNames}
      data-testid={[id, testid].join('-')}
      {...props}
    >
      {children}
    </Component>
  );
};
