import { endpointVersionString } from "core/utils/envUtils";
import { associateWebBFFAPI } from "@/core/services/associateWebBff/_axiosConfigAssociateWebBff";

// Add typing via JS Doc
/**
 * @typedef {Object} Data
 * @property {string} startDate
 * @property {string} endDate
 * @property {string} details
 * @property {string} reasonId
 * @property {string} suspensionTypeId
 * @property {boolean=} isCanceled
 */

/**
 * POST API for the Hotel Service Suspensions
 * @param {Object} obj - destructed
 * @param {number } obj.storeNumber - number of the current store
 * @param {Data} obj.data - Data passed as the body of the API call
 * @returns {AxiosPromise<any>}
 */
export function postHotelServiceSuspension({ storeNumber, data }) {
  return associateWebBFFAPI.post(`v1/hotel/stores/${storeNumber}/servicesuspensions`, data);
}

/**
 * PATCH API for the Hotel Service Suspensions
 * @param {Object} obj - destructed
 * @param {number } obj.storeNumber - Number of the current store
 * @param {number} obj.suspensionId - Id to identify an individual suspension
 * @param {Data} obj.data - Data passed as the body of the API call
 * @returns {AxiosPromise<any>}
 */
export function patchHotelServiceSuspension({ storeNumber, suspensionId, data }) {
  return associateWebBFFAPI.patch(
    `v1/hotel/stores/${storeNumber}/servicesuspensions/${suspensionId}`,
    data,
  );
}

/**
 * GET API for the Hotel Service Suspensions
 * @param {Object} obj - destructed
 * @param {number } obj.storeNumber - Number of the current store
 * @param {string} obj.fromDate - Date of we want to get list of suspensions from
 * @param {string} obj.toDate - Date of we want to get list of suspensions to
 * @param {string} obj.page - pagination page number
 * @param {string} obj.size - size of each page (number of records returned per page)
 * @param {boolean} obj.includeHistory - to include full history of suspension
 * @returns {AxiosPromise<any>}
 */
export function getHotelServiceSuspension({
  storeNumber,
  fromDate,
  toDate,
  page,
  size,
  includeHistory,
}) {
  return associateWebBFFAPI.get(`v1/hotel/stores/${storeNumber}/servicesuspensions`, {
    params: { fromdate: fromDate, todate: toDate, page, size, includehistory: includeHistory },
  });
}
