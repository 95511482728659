// DDC Service Management header container ----------------------------------------------------------------------------------------
import { connect } from "react-redux";
import { headerConfigConstants } from "core/constants/headerConfigConstants";
import HeaderImage from "assets/images/hotel-int-header-image.png";
import AppHeader from "web/header/AppHeader";

export const ScheduledSuspensionHeader = connect(state => {
  // const someProp = someSelector(state);

  return {
    componentId: "ScheduledSuspensionHeader",
    title: "Suspensions",
    config: headerConfigConstants.APPOINTMENT,
    // jobRole: getJobRole(state, ownProps),
    backgroundImage: HeaderImage,
  };
})(AppHeader);