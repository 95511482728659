import petParentProfileActionTypes from "../../core/actionTypes/ui/petParentProfileActionTypes";

export const showPetParentProfileModal = modalType => ({
  type: petParentProfileActionTypes.SHOW_PET_PARENT_PROFILE_MODAL,
  modalType
});

export const hidePetParentProfileModal = () => ({
  type: petParentProfileActionTypes.HIDE_PET_PARENT_PROFILE_MODAL
});

export const rebookClick = ({ appointment }) => ({
  type: petParentProfileActionTypes.REBOOK_CLICK,
  appointment
});

export const historyClick = ({ appointment }) => ({
  type: petParentProfileActionTypes.HISTORY_CLICK,
  appointment
});

export const historyClickFailure = ({ error }) => ({
  type: petParentProfileActionTypes.HISTORY_CLICK_FAILURE,
  error
});

export const loadPetParentAPIs = ({ customerKey }) => ({
  type: petParentProfileActionTypes.LOAD_PET_PARENT_APIS,
  customerKey
});

export const setCustomerAndEmailKeys = ({ customerKey, emailAddressId }) => ({
  type: petParentProfileActionTypes.SET_CUSTOMER_AND_EMAIL_KEYS,
  customerKey,
  emailAddressId
});

export const clearCustomerAndEmailKeys = () => ({
  type: petParentProfileActionTypes.CLEAR_CUSTOMER_AND_EMAIL_KEYS
});

export const setFieldForNewProfile = ({ searchFieldType, searchFieldValue }) => ({
  type: petParentProfileActionTypes.SET_FIELD_FOR_NEW_PROFILE,
  searchFieldType,
  searchFieldValue
});

export const clearFieldForNewProfile = () => ({
  type: petParentProfileActionTypes.CLEAR_FIELD_FOR_NEW_PROFILE
});

export const displayOtherPetParentAddresses = ({ isVisible }) => ({
  type: petParentProfileActionTypes.DISPLAY_OTHER_PET_PARENT_ADDRESSES,
  isVisible
});

export const displayAddPetParentAddressForm = ({ isVisible }) => ({
  type: petParentProfileActionTypes.DISPLAY_ADD_PET_PARENT_ADDRESS_FORM,
  isVisible
});

export const setIsAddingPet = ({ isAddingPet }) => ({
  type: petParentProfileActionTypes.SET_IS_ADDING_PET,
  isAddingPet
});

export default {
  hidePetParentProfileModal,
  showPetParentProfileModal,
  rebookClick,
  historyClick,
  historyClickFailure,
  loadPetParentAPIs,
  setCustomerAndEmailKeys,
  clearCustomerAndEmailKeys,
  setFieldForNewProfile,
  clearFieldForNewProfile,
  displayOtherPetParentAddresses,
  displayAddPetParentAddressForm,
  setIsAddingPet
};
