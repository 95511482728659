import { connect } from "react-redux";
import TextComponent from "@/dux/_components/genericLayoutComponents/TextComponent";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getIsBundleHistoryItemRefunded } from "./bundleHistorySelectors";

const mapStateToProps = (state, { bundleNumber }) => {
  const customerKey = getCurrentCustomerKey(state);
  const isRefunded = getIsBundleHistoryItemRefunded(state, { customerKey, id: bundleNumber });
  return {
    isHidden: !isRefunded,
    text: "Package has been refunded",
    styleType: "div",
  };
};

export default connect(mapStateToProps)(TextComponent);
