import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get the service card for the engagement. A service card is a PDF generated specifically
 * for the engagement. It provides in-store associates with details about the pet and
 * the services in a printable format.
 * For System Services Booking API
 * - Get engagement service card
 * @param {*} { customerKey, itineraryId, engagementId }
 * @returns {*} AxiosPromise
 */
export function fetchServiceCard({ customerKey, itineraryId, engagementId }) {
  return bookingAPI({
    method: "GET",
    url: `${endpointVersionString()}/customers/${customerKey}/itineraries/${itineraryId}/engagements/${engagementId}/servicecard`
  });
}

/**
 * Retrieves multiple service cards by itinerary. A service card is a PDF generated specifically
 * for the engagement. It provides in-store associates with details about the pet and
 * the services in a printable format.
 * For System Services Booking API
 * - Retrieves multiple service cards by itinerary
 * @param {*} { customerKey, itineraryId }
 * @returns {*} AxiosPromise
 */
export function fetchServiceCards({ customerKey, itineraryId }) {
  return bookingAPI({
    method: "GET",
    url: `${endpointVersionString()}/customers/${customerKey}/itineraries/${itineraryId}/servicecards`
  });
}
