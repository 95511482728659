
import { GET_STORE_WIDE_NOTES_SUCCESS } from "./storeWideNotesActions";

const initialState = {};

const storeWideNotesReducer = (state = initialState, {lastAPICall, notifications, type}) => {
  switch (type) {
    case GET_STORE_WIDE_NOTES_SUCCESS:
      return {
        ...state,
        lastAPICall,
        notifications,
      };
    default:
      return state;
  }
};

export default storeWideNotesReducer;
