/*
  Container for the Print Invoice button in the CICO Page.
*/
import { connect } from "react-redux";
import { pageNames, PRINT_INVOICE_REMINDER_MESSAGE } from "core/constants/checkInOutConstants";
import getStoreDictionary from "web/_helpers/generic/buttons/getStoreDictionary";
import { checkIfButtonShouldShow } from "web/_helpers/generic/buttons/checkIfButtonsShouldshow";
import {
  getCurrentItinerary,
  getIsCurrentItineraryPriceZero,
  isFromAnotherStore,
} from "core/selectors/checkInOutSelector";
import { buttonTypes } from "core/constants/buttonConstants";
import { showConfirmationModal } from "core/actionCreators/confirmationModalActionCreators";
import { loadInvoice } from "core/actionCreators/itinerariesActionCreators";
import { createLoadingSelector } from "core/selectors/utils";
import petsActionTypes from "core/actionTypes/petsActionTypes";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import itinerariesActionTypes from "core/actionTypes/itinerariesActionTypes";
import SplitLineWithMessage from "web/common/SplitLineWithMessage";
import CommonButton from "web/common/commonButton";

const mapStateToProps = state => {
  const itineraryId = getCurrentItinerary(state);
  const dict = getStoreDictionary(state);
  const isPetsLoading = createLoadingSelector([
    petsActionTypes.LOAD_PETS_BY_CUSTOMER,
    customersActionTypes.LOAD_CUSTOMER,
    itinerariesActionTypes.LOAD_ITINERARY,
  ])(state);

  return {
    componentId: "printInvoice-commonButton",
    isFromAnotherStore: isFromAnotherStore(state, {
      petServiceItemId: dict.currentPetServiceItemId,
    }),
    isHidden: !checkIfButtonShouldShow({
      currentPage: dict.page,
      requiredPage: pageNames.CHECK_OUT,
      petServiceItems: dict.petServiceItems,
      currentPetServiceItem: dict.currentPetServiceItem,
      buttonType: buttonTypes.PRINT_INVOICE,
    }),

    label: "Print Invoice",
    disabled:
      dict.isFromOtherStore ||
      isPetsLoading ||
      getIsCurrentItineraryPriceZero(state, { itineraryId }),
    currentPetServiceItem: dict.currentPetServiceItem,
    customerKey: dict.customerKey,
    itineraryId: dict.itineraryId,
    petId: dict.petId,
  };
};

const mapDispatchToProps = dispatch => ({
  handleClick: ({ customerKey, itineraryId, petId }) => {
    dispatch(
      showConfirmationModal({
        header: "Print Invoice Reminder",
        content: SplitLineWithMessage({ string: PRINT_INVOICE_REMINDER_MESSAGE, delimiter: "\n" }),
        confirmText: "Proceed Anyway",
        confirm: () => dispatch(loadInvoice({ customerKey, itineraryId, petId })),
        cancelText: "Cancel",
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () =>
    propsFromDispatch.handleClick({
      customerKey: propsFromState.customerKey,
      itineraryId: propsFromState.itineraryId,
      petId: propsFromState.petId,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
