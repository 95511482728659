import { put, takeEvery, call, all, select } from "redux-saga/effects";
import absenceReasonsActionTypes from "../../core/actionTypes/absenceReasonsActionTypes";
import absenceReasonsActionCreator from "../../core/actionCreators/absenceReasonsActionCreator";
import { reduce, set, mapValues, get } from "lodash/fp";
import { fetchAbsenceReasons } from "../services/systemServicesBooking/absencesEndPoints";

function* onloadAbsenceReasons() {
  try {
    yield put(absenceReasonsActionCreator.loadAbsenceReasonsRequest());
    const response = yield call(fetchAbsenceReasons);
    const absenceReasons = response.data;
    yield put(absenceReasonsActionCreator.loadAbsenceReasonsSuccess({ absenceReasons }));
  } catch (error) {
    yield put(absenceReasonsActionCreator.loadAbsenceReasonsFailure({ error }));
  }
}

function* watchloadAbsenceReasons() {
  yield takeEvery(absenceReasonsActionTypes.LOAD_ABSENCE_REASONS, onloadAbsenceReasons);
}

export default function* absenceReasonsSaga() {
  yield all([watchloadAbsenceReasons()]);
}
