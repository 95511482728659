import { createSelector } from "reselect";
import { getUiWeb } from "./uiSelector";

// Todo Simplify this back to a simple selector
/**
 * A Re-Select Selector that returns a string from the redux store describing what filter to apply, "here or Completed"
 * @param {*} state
 * @return {string} Filter Value e.g.  "Here"
 */
export const getHereOrCompletedFilterApplied = createSelector([getUiWeb], uiWeb => {
  return uiWeb.upcomingAndHere.hereOrCompletedFilterApplied;
});

export const getUpcomingSearchText = createSelector([getUiWeb], uiWeb => {
  return uiWeb.upcomingAndHere.upcomingSearchText;
});

export const getHereSearchText = createSelector([getUiWeb], uiWeb => {
  return uiWeb.upcomingAndHere.hereSearchText;
});

export const getUpcomingBoardedGuestChecked = createSelector([getUiWeb], uiWeb => {
  return uiWeb.upcomingAndHere.upcomingBoardedGuestChecked;
});

export const getHereBoardedGuestChecked = createSelector([getUiWeb], uiWeb => {
  return uiWeb.upcomingAndHere.hereBoardedGuestChecked;
});
