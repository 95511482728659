import { routePaths } from "@/core/constants/routePaths";
import handleRedirectToPastOrFutureAppointment from "../utils/redirect/handleRedirectToPastOrFutureAppointment";
import { TRAINING } from "./availableBundlesByPet/availableBundlesConstents";
import { history } from "../utils/browser/browserHistory";

export const bundleDatesClickHandler = (
  itineraryId,
  itemDate,
  customerId,
  offerType,
  setCustomerKeyAndItineraryId,
) => {
  if (offerType === TRAINING) {
    setCustomerKeyAndItineraryId(customerId, itineraryId);
    history.push(routePaths.MANAGE_TRAINING_CLASS);
  } else {
    handleRedirectToPastOrFutureAppointment({
      customerId: customerId,
      itemDate: itemDate,
      itineraryId: itineraryId,
    });
  }
};
