import moment from "moment";
import { isEmpty } from "lodash/fp";
import { DATE_TIME_FORMAT } from "core/constants";
import AgeImage from "../../assets/icons/age.svg";
import BoardedGuestImage from "../../assets/icons/boarded-guest.svg";
import CatImage from "../../assets/icons/cat.svg";
import ExpressImage from "../../assets/icons/express.svg";
import HealthImage from "../../assets/icons/health.svg";
import LinkedImage from "../../assets/icons/linked.svg";
import MaxLimitImage from "../../assets/icons/max-limit.svg";
import NewPetImage from "../../assets/icons/new-pet.svg";
import NotesImage from "../../assets/icons/notes.svg";
import NotificationImage from "../../assets/icons/notificaiton.svg";
import PaidImage from "../../assets/icons/paid.svg";
import PuppyImage from "../../assets/icons/puppy.svg";
import VaccinationImage from "../../assets/icons/vaccination.svg";
import DigitalBooking from "../../assets/icons/digital-booking.svg";
import { creationChannels } from "../../constants/appointmentConstants";
import IsManual from "../../assets/icons/is-manual.svg";
import isExpired from "../dateUtils/isExpired";
import { speciesTypes } from "../../constants/speciesContants";
import { dogVaccinationTypes, catVaccinationTypes } from "../../constants/vaccinationsConstants";

export const isSenior = birthDate => moment().diff(moment(birthDate), "years") >= 10;

export const isPuppy = pet => {
  const birthday = moment(pet.birthDate);
  return moment().diff(birthday, "days") <= 168 && pet.speciesId === 1;
};

export const isCat = species => species === 2;

export const alerts = pet => pet.petRatings && pet.petRatings.find(rating => rating.ratingId === 3);

export const healthConditions = healthConditionsData => !isEmpty(healthConditionsData);

export const isDogRabiesVaccination = (speciesId, vaccinationId) =>
  speciesId === speciesTypes.DOG && vaccinationId === dogVaccinationTypes.RABIES;

export const isCatRabiesVaccination = (speciesId, vaccinationId) =>
  speciesId === speciesTypes.CAT && vaccinationId === catVaccinationTypes.RABIES;

const isRabiesVaccination = (speciesId, vaccine) => {
  return (
    isCatRabiesVaccination(speciesId, vaccine.vaccinationId) ||
    isDogRabiesVaccination(speciesId, vaccine.vaccinationId)
  );
};

const isValidRabiesVaccine = (vaccine, speciesId) => {
  return (
    vaccine.activeFlag &&
    isRabiesVaccination(speciesId, vaccine) &&
    !isExpired(vaccine.expirationDate, DATE_TIME_FORMAT)
  );
};

export const isRabiesMissingOrExpired = (speciesId, vaccinations) => {
  if (vaccinations?.length !== 0) {
    const validRabiesVaccine = vaccinations?.find(vaccine =>
      isValidRabiesVaccine(vaccine, speciesId),
    );

    return !validRabiesVaccine;
  }

  return true;
};

export const isGroupItinerary = (itinerary, itineraries) => {
  const petsByItinerary = itineraries[itinerary] && itineraries[itinerary].pets;
  return !!(petsByItinerary && petsByItinerary.length > 1);
};

export const isBoarded = (appointment, engagements) =>
  appointment &&
  engagements &&
  engagements.engagements[appointment.engagement] &&
  engagements.engagements[appointment.engagement].isBoarded;

export const isDigital = (itinerary, itineraries) => {
  if (itineraries[itinerary]) {
    return itineraries[itinerary].creationChannel === creationChannels.ONLINE;
  }
  return false;
};

export const isNew = (appointment, engagements) =>
  appointment &&
  engagements &&
  engagements.engagements[appointment.engagement] &&
  engagements.engagements[appointment.engagement].isNewCustomer;

export const getIcons = (pet, appointment, itineraries, engagements) => {
  const icons = [];

  if (pet) {
    if (isCat(pet.speciesId)) icons.push(CatImage);

    if (alerts(pet)) icons.push(NotificationImage);

    if (healthConditions(pet.petConditions)) icons.push(HealthImage);

    if (pet.vaccinations && isRabiesMissingOrExpired(pet.speciesId, pet.vaccinations)) {
      icons.push(VaccinationImage);
    }

    if (isPuppy(pet)) icons.push(PuppyImage);
  }

  if (appointment) {
    if (appointment.isExpress) icons.push(ExpressImage);

    if (itineraries && isGroupItinerary(appointment.itinerary, itineraries.itineraries)) {
      icons.push(LinkedImage);
    }

    if (appointment.isPaid) icons.push(PaidImage);

    if (appointment.notes) {
      icons.push(NotesImage);
    }
    // TODO: change to dynamic amount when notes are implemented.

    if (appointment.maxCheckInCount > 1 || appointment.maxPerBlockCount > 1) {
      icons.push(MaxLimitImage);
    }
  }

  if (isBoarded(appointment, engagements)) icons.push(BoardedGuestImage);

  if (isNew(appointment, engagements)) icons.push(NewPetImage);

  if (appointment && itineraries && isDigital(appointment.itinerary, itineraries.itineraries)) {
    icons.push(DigitalBooking);
  }

  if (pet && isSenior(pet.birthDate)) icons.push(AgeImage);

  if (appointment && appointment.isManualAppointment) {
    icons.push(IsManual);
  }

  return icons;
};
