import React, { Children } from "react";
import { Layout } from "../../components/Layout";
import TitledBox from "../TitledBox/TitledBox";
import Hr from "../../components/HR/Hr";
import TextInput, { TextInputProps } from "../../components/TextInput/TextInput";
import './searchableList.css';

export interface SearchableListProps extends TextInputProps{
  /** DOM Selector id **/
  id: string,

  /** Controls the visibility of the component */
  isHidden?: boolean,

  /** Title of the component **/
  title: string,

  /** Show or hide dividers between lists **/
  isDivided?: boolean,

  /** Variants encompass all of the designed intent and the accompanying component states, constrained applies a scroll bar and max height, full has no such constraints */
  variant?: 'constrained' | 'full'
}


const SearchableList: React.FunctionComponent<SearchableListProps> = (
  { id,
    isHidden,
    children,
    title,
    isDivided,
    variant,
    className,
    ...other}) => {
  const arrayChildren = Children.toArray(children);

  const hasVariant = !!variant // if empty returns false;
  const listAreaVariant = `searchableList__lists--${hasVariant ? variant : "constrained"}`;

  if(isHidden){
    return null;
  }

  return (
    <TitledBox id={id} title={title} isSection={false}>
      {/*Search*/}
      <Layout.Box>
        <TextInput id={`${id}__searchField`} {...other} />
      </Layout.Box>

      {/*List*/}
      <Layout.Box id={`${id}__lists`} className={listAreaVariant}>
        {Children.map(arrayChildren, (child, index) => {
          const isLast:boolean = index === arrayChildren.length - 1;

          return (
            <>
              {child}

              {isDivided && !isLast ? <Hr /> : null}
            </>
          )
        })}
      </Layout.Box>
    </TitledBox>
  );
};

export default SearchableList