import { createSelector } from "reselect";
import { get } from "lodash/fp";
import { getProps, getState } from "core/selectors/commonSelector";
import { getCartDetails } from "core/selectors/cartSelectors";
import { isFromStandalone } from "core/utils/validationUtils/isFromValidation";
import { getPetServiceItem } from "core/selectors/entitiesSelector";

export const getIsStandaloneNotBookable = createSelector(
  [getCartDetails, getState, getProps],
  (cart = {}, state, { location }) => {
    const isStandaloneRoute = isFromStandalone(location?.pathname);
    const servicesInCart = Object.values(cart).map(get("petServiceItemId"));

    const areAllRequiredStandalonesInCart = servicesInCart.every(petServiceItemId => {
      const petServiceItem = getPetServiceItem(state, { petServiceItemId });
      return !!petServiceItem?.addOns?.length;
    });

    return isStandaloneRoute && !areAllRequiredStandalonesInCart;
  },
);
