import { refundPackageErrorModalActionTypes } from "./refundPackageErrorModalActions";

const initialState = {
  isHidden: true,
  petId: null,
  bundleNumber: null,
};

const refundPackageErrorModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case refundPackageErrorModalActionTypes.DISPLAY_REFUND_PACKAGE_ERROR_MODAL:
      return {
        ...state,
        isHidden: action.isHidden,
        petId: action.petId,
        bundleNumber: action.bundleNumber,
      };
    default:
      return state;
  }
};

export default refundPackageErrorModalReducer;
