import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { serviceTypeTraining } from "dux/trainingAssociates/trainingAssociatesConstants";
import { serviceTypeHotel } from "dux/dashboard/hotelDashboardConstants";

// ID references for the different booking flows -----------------------------------------------------------------------
export const bookingFlowServiceTypeIds = {
  GROOMING: 1,
  HOTEL: 2,
  TRAINING: 3,
  DAYCAMP: 4,
};

export const bookingFlowToPetServiceIdMap = new Map([
  [systemName.SALON, bookingFlowServiceTypeIds.GROOMING],
  [systemName.HOTEL, bookingFlowServiceTypeIds.HOTEL],
  [systemName.TRAINING, bookingFlowServiceTypeIds.TRAINING],
]);

// Service IDs ---------------------------------------------------------------------------------------------------------
export const serviceTypeIds = {
  GROOMING: 3,
  HOTEL: 4,
  DDC: 5,
};

// serviceTypes for Engagement and Itineraries.  -----------------------------------------------------------------------
export const serviceTypes = {
  SALON: "Salon", // TODO: abstract out to a serviceTypeSalon in Dux
  HOTEL: serviceTypeHotel,
  TRAINING: serviceTypeTraining,
};

export const AllServiceTypes = `${serviceTypes.SALON},${serviceTypeTraining}`;
