import { createSelector } from "reselect";
import getFilteredReasons from "dux/eligibility/helperUtils/filteredeligibilityReasons";
import {
  actionClickedDDCReasonCodes,
  actionClickedReasonCodes,
  pageMountReasonCodes,
} from "dux/eligibility/constants/eligibilityConstants";
import { getEligibilityResults } from "dux/eligibility/selectors/eligibilitySelectors";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

/**
 * Check if a reason within eligibility matches a page moount reason code, if so return an object of filtered reasons for pet and customer
 * @return {object} - Customer & Pet eligibility filtered reasons values
 */
export const getPageMountReasons = createSelector([getEligibilityResults], eligibility =>
  getFilteredReasons(eligibility, reason =>
    pageMountReasonCodes.includes(reason.reasonCode.trim()),
  ),
);

/**
 * responsible for returning Salon or DDC specific action clicked reason codes.
 * @return {object} - Customer & Pet eligibility filtered reasons values
 */
export const getActionClickedReasons = createSelector([getEligibilityResults], eligibility =>
  getFilteredReasons(eligibility, reason => {
    if (getSystemBookingFlow === systemName.SALON) {
      return actionClickedReasonCodes.includes(reason.reasonCode.trim());
    }
    if (getSystemBookingFlow === systemName.HOTEL) {
      return actionClickedDDCReasonCodes.includes(reason.reasonCode.trim());
    }
    return [];
  }),
);
