/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
// Retrieve specials
export const LOAD_HOTEL_MANUAL_SPECIALS = "LOAD_HOTEL_MANUAL_SPECIALS";
export const LOAD_HOTEL_MANUAL_SPECIALS_REQUEST = "LOAD_HOTEL_MANUAL_SPECIALS_REQUEST";
export const LOAD_HOTEL_MANUAL_SPECIALS_SUCCESS = "LOAD_HOTEL_MANUAL_SPECIALS_SUCCESS";
export const LOAD_HOTEL_SPECIALS_MANUAL_FAILURE = "LOAD_HOTEL_SPECIALS_MANUAL_FAILURE";

// Retrieve booking specials
export const LOAD_BOOKING_HOTEL_MANUAL_SPECIALS = "LOAD_BOOKING_HOTEL_MANUAL_SPECIALS";
export const LOAD_BOOKING_HOTEL_MANUAL_SPECIALS_REQUEST =
  "LOAD_BOOKING_HOTEL_MANUAL_SPECIALS_REQUEST";
export const LOAD_BOOKING_HOTEL_MANUAL_SPECIALS_SUCCESS =
  "LOAD_BOOKING_HOTEL_MANUAL_SPECIALS_SUCCESS";
export const LOAD_BOOKING_HOTEL_MANUAL_SPECIALS_FAILURE =
  "LOAD_BOOKING_HOTEL_MANUAL_SPECIALS_FAILURE";

// PATCH Specials
export const PATCH_HOTEL_MANUAL_SPECIALS = "PATCH_HOTEL_MANUAL_SPECIALS";
export const PATCH_HOTEL_MANUAL_SPECIALS_REQUEST = "PATCH_HOTEL_MANUAL_SPECIALS_REQUEST";
export const PATCH_HOTEL_MANUAL_SPECIALS_SUCCESS = "PATCH_HOTEL_MANUAL_SPECIALS_SUCCESS";
export const PATCH_HOTEL_MANUAL_SPECIALS_FAILURE = "PATCH_HOTEL_MANUAL_SPECIALS_FAILURE";

// Remove Specials
export const REMOVE_HOTEL_MANUAL_SPECIALS = "REMOVE_HOTEL_MANUAL_SPECIALS";
export const REMOVE_HOTEL_MANUAL_SPECIALS_REQUEST = "REMOVE_HOTEL_MANUAL_SPECIALS_REQUEST";
export const REMOVE_HOTEL_MANUAL_SPECIALS_SUCCESS = "REMOVE_HOTEL_MANUAL_SPECIALS_SUCCESS";
export const REMOVE_HOTEL_MANUAL_SPECIALS_FAILURE = "REMOVE_HOTEL_MANUAL_SPECIALS_FAILURE";
/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
// Retrieve specials
export const loadHotelSpecialsManual = ({ itineraryId }) => ({
  type: LOAD_HOTEL_MANUAL_SPECIALS,
  itineraryId,
});

export const loadHotelSpecialsManualRequest = () => ({
  type: LOAD_HOTEL_MANUAL_SPECIALS_REQUEST,
});

export const loadHotelSpecialsManualSuccess = ({ manualSpecials }) => ({
  type: LOAD_HOTEL_MANUAL_SPECIALS_SUCCESS,
  manualSpecials,
});

export const loadHotelSpecialsManualFailure = error => ({
  type: LOAD_HOTEL_SPECIALS_MANUAL_FAILURE,
  error,
});

// ---

// Retrieve booking specials
export const loadBookingHotelSpecialsManual = ({ itineraryId }) => ({
  type: LOAD_BOOKING_HOTEL_MANUAL_SPECIALS,
  itineraryId,
});

export const loadBookingHotelSpecialsManualRequest = () => ({
  type: LOAD_BOOKING_HOTEL_MANUAL_SPECIALS_REQUEST,
});

export const loadBookingHotelSpecialsManualSuccess = ({ manualSpecials }) => ({
  type: LOAD_BOOKING_HOTEL_MANUAL_SPECIALS_SUCCESS,
  manualSpecials,
});

export const loadBookingHotelSpecialsManualFailure = error => ({
  type: LOAD_BOOKING_HOTEL_MANUAL_SPECIALS_FAILURE,
  error,
});

// ---

// PATCH Specials
export const patchHotelManualSpecials = ({ itineraryId, customerId, data }) => ({
  type: PATCH_HOTEL_MANUAL_SPECIALS,
  itineraryId,
  customerId,
  data,
});

export const patchHotelManualSpecialsRequest = () => ({
  type: PATCH_HOTEL_MANUAL_SPECIALS_REQUEST,
});

export const patchHotelManualSpecialsSuccess = () => ({
  type: PATCH_HOTEL_MANUAL_SPECIALS_SUCCESS,
});

export const patchHotelManualSpecialsFailure = error => ({
  type: PATCH_HOTEL_MANUAL_SPECIALS_FAILURE,
  error,
});
// ---

// Remove
export const removeHotelSpecialsManual = ({ specialCode, petId }) => ({
  type: REMOVE_HOTEL_MANUAL_SPECIALS,
  specialCode,
  petId,
});

export const removeHotelSpecialsManualRequest = () => ({
  type: REMOVE_HOTEL_MANUAL_SPECIALS_REQUEST,
});

export const removeHotelSpecialsManualSuccess = () => ({
  type: REMOVE_HOTEL_MANUAL_SPECIALS_SUCCESS,
});

export const removeHotelSpecialsManualFailure = error => ({
  type: REMOVE_HOTEL_MANUAL_SPECIALS_FAILURE,
  error,
});
