import moment from "moment";

/**
 * A wrapper function for  moment().diff(), and is responsible for taking a moments object and
 * returning the difference between the passed Moment object and this functions invoked time/date
 *
 * @param {(object|string|number|date|array} endDate - moments object
 * @param {object}
 */
export default function isOverdue(endDate) {
  return moment().diff(endDate, "minutes") > 0;
}
