import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { EditableSection, Button, ButtonGroup, SparkyTextWrapper } from "@petsmart-ui/sparky";
import { Layout } from "@prism/psm-ui-components";
import {
  selectHotelBookingCanNavigate,
  selectHotelBookingEndDate,
  selectHotelBookingFurthestStep,
  selectHotelBookingIsActive,
  selectHotelBookingPetList,
  selectPetsWithoutService,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { HotelSinglePetBookingServiceOptions } from "dux/hotelBookingServiceSelection/HotelBookingServiceOptions";
import { HotelBookingRoomServiceSelection } from "dux/hotelBookingServiceSelection/HotelBookingRoomServiceSelection";
import {
  setHotelBookingCurrentServiceSelectionRoom,
  setHotelBookingOpenStep,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowActions";
import { HotelBookingServiceSectionAlerts } from "dux/hotelBookingAlerts/HotelBookingSelectServiceAlerts";
import { postHotelEligibility } from "dux/hotelEligibility/hotelEligibilityActions";
import { selectPetServiceTypesFromAppointmentServicesForPets } from "web/pendingAppointment/selectors/pendingAppointmentSelectors";
import { eligibilityTypes } from "dux/eligibility/constants/eligibilityConstants";
import { loadPetServicesAtHotelBooking } from "@/web/services/hotelServices/actions/petServicesAtHotel";

const HotelBookingServiceSelectionComponent = ({
  componentID,
  canNavigate,
  isActive,
  onClick,
  diComp,
  disabledContinue,
  clearCurrentServiceSelectionRoom,
  stepIndex,
  loadHotelEligibility,
  furthestStep,
  descriptionText = "",
  getPetServices = () => {},
}) => {
  // Know if we need to show alerts or not.
  const [continueClicked, setContinueClicked] = useState(false);

  useEffect(() => {
    // When navigating back to this section make sure the
    // service selection for a specific room is not open
    // and alerts are not showing
    if (isActive) {
      clearCurrentServiceSelectionRoom();
      setContinueClicked(false);
    }

    // Call pet services if the step is active and if we haven't continued past selecting services before.
    if (isActive && furthestStep <= stepIndex) getPetServices();
  }, [isActive]);

  const ServiceSelectionComponent = diComp(continueClicked);

  const description = () => {
    if (isActive || !canNavigate) return;
    return <SparkyTextWrapper truncateOn={1}>{descriptionText}</SparkyTextWrapper>;
  };

  return (
    <EditableSection
      buttonText={canNavigate && "Edit"}
      isActive={isActive}
      onClick={onClick}
      title="Select a Service"
      description={description()}
    >
      <Layout.Box id={componentID}>
        <ServiceSelectionComponent stepIndex={stepIndex} />
        {!continueClicked && (
          <ButtonGroup align="right">
            <Button
              variant="primary"
              onClick={() => {
                loadHotelEligibility();
                setContinueClicked(true);
              }}
              text="Next"
              disabled={disabledContinue}
            />
          </ButtonGroup>
        )}
      </Layout.Box>
    </EditableSection>
  );
};

export const HotelBookingServiceSelection = connect(
  (state, { stepIndex }) => {
    const selectedPetIds = selectHotelBookingPetList(state);
    const isMultiPet = selectedPetIds?.length > 1;

    // Determine whether to show multi pet or single pet component
    const ServiceComponent = isMultiPet
      ? HotelBookingRoomServiceSelection
      : HotelSinglePetBookingServiceOptions;

    return {
      componentID: "hotelBookingFlow-serviceSelection",
      canNavigate: selectHotelBookingCanNavigate(stepIndex)(state),
      isActive: selectHotelBookingIsActive(stepIndex)(state),
      disabledContinue: selectPetsWithoutService(state),
      petsAndServiceTypes: selectPetServiceTypesFromAppointmentServicesForPets({
        state,
        petList: selectedPetIds,
      }),
      diComp: continueClicked =>
        continueClicked ? HotelBookingServiceSectionAlerts : ServiceComponent,
      stepIndex,
      furthestStep: selectHotelBookingFurthestStep(state),
      checkOutDateTime: selectHotelBookingEndDate(state),
    };
  },
  dispatch => {
    return {
      dispatchSetHotelBookingCurrentServiceSelectionRoom: roomId =>
        dispatch(setHotelBookingCurrentServiceSelectionRoom({ roomId })),
      dispatchGetHotelEligibility: ({ petsAndServiceTypes, checkOutDateTime }) => {
        dispatch(
          postHotelEligibility({
            eligibilityType: eligibilityTypes.BOOKING,
            payload: { petsAndServiceTypes, checkOutDateTime },
          }),
        );
      },
      setStep: step => dispatch(setHotelBookingOpenStep(step)),
      dispatchLoadPetServicesAtHotel: petId => dispatch(loadPetServicesAtHotelBooking(petId)),
    };
  },
  (mapProps, dispatchProps) => {
    const {
      componentID,
      canNavigate,
      isActive,
      diComp,
      disabledContinue,
      stepIndex,
      petsAndServiceTypes,
      furthestStep,
      checkOutDateTime,
    } = mapProps;

    const {
      dispatchSetHotelBookingCurrentServiceSelectionRoom,
      dispatchGetHotelEligibility,
      setStep,
      dispatchLoadPetServicesAtHotel,
    } = dispatchProps;

    return {
      // control what props get passed to the view
      componentID,
      canNavigate,
      isActive,
      onClick: () => setStep(stepIndex),
      diComp,
      disabledContinue,
      stepIndex,
      descriptionText: petsAndServiceTypes.map(({ serviceType }) => serviceType).join(", "),
      furthestStep,
      clearCurrentServiceSelectionRoom: () => dispatchSetHotelBookingCurrentServiceSelectionRoom(),
      loadHotelEligibility: () =>
        dispatchGetHotelEligibility({ petsAndServiceTypes, checkOutDateTime }),
      getPetServices: () => dispatchLoadPetServicesAtHotel(),
    };
  },
)(HotelBookingServiceSelectionComponent);
