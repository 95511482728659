import { quickViewActionTypes } from "dux/quickViews/quickViewActions";
import quickViewInitState from "./_quickViewsInitState";

// Get the initial state for any quick view
const initialState = quickViewInitState();

/**
 * Responsible for reducing the state for the Rebook QuickView of hte quick view
 * feature.
 *
 * @export quickViewRebookReducer
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns
 */
export default function quickViewRebookReducer(state = initialState, action) {
  switch (action.type) {
    case quickViewActionTypes.SHOW_REBOOK_QUICKVIEW:
      return {
        ...state,
        isVisible: true,
        xLoc: action.xLoc,
        yLoc: action.yLoc,
      };
    case quickViewActionTypes.HIDE_REBOOK_QUICKVIEW:
      return initialState;
    default:
      return state;
  }
}
