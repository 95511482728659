import { connect } from "react-redux";
import { color } from "web/common/styles/theme";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getFirstTimeCustomerInfo } from "dux/bgm/ftco/ftcoSelectors";
import { ELIGIBLE_PROMOTIONS_FTCO_MESSAGE } from "dux/eligiblePromotions/eligiblePromotionConstants";
import { isFTCOEligibilityHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import PreCheckedInIcon from "assets/icons/pre-checked-in-appointment.svg";
import IconWithLabel from "dux/_components/icon/IconWithLabel";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  const { firstName } = getFirstTimeCustomerInfo(state, { customerKey });
  const isHidden = !customerKey || isFTCOEligibilityHidden(state);
  return {
    isHidden,
    firstName,
    componentId: "eligiblePromotionsFTCOContainer__IconWithLabel",
    src: PreCheckedInIcon,
    label: `${firstName} ${ELIGIBLE_PROMOTIONS_FTCO_MESSAGE}`,
    styleObj: {
      padding: "1em",
      justifyContent: "center",
      borderBottom: `1px solid ${color.borderGrey}`,
    },
  };
};

export default connect(mapStateToProps)(IconWithLabel);
