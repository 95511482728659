/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const UPDATE_CUSTOMER_TIME_ZONE = "UPDATE_CUSTOMER_TIME_ZONE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const updateCustomerTimeZone = ({ timeZone }) => ({
  type: UPDATE_CUSTOMER_TIME_ZONE,
  timeZone,
});
