import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get a list of manual specials, which are specials that can be applied by an associate.
 * These are required to allow an associate to select the special to be applied to a pet service.
 * From the System Services Booking API
 * - Get list of current manual specials
 * @param {*} { itineraryId }
 * @returns {*} AxiosPromise
 */
export function fetchSpecials({ itineraryId }) {
  return bookingAPI.get(`${endpointVersionString()}/specials/manual`, {
    params: { itineraryId }
  });
}

/**
 * Apply the specified specials to all applicable pet service items in the itinerary.
 * Total costs will be updated and reflected in the returned itinerary.
 * From the System Services Booking API
 * - Apply specials
 * @param {*} { customerId, itineraryId, specials }
 * @returns {*} AxiosPromise
 */
export function postSpecials({ customerId, itineraryId, specials }) {
  return bookingAPI.post(
    `${endpointVersionString()}/customers/${customerId}/itineraries/${itineraryId}/specials`,
    specials
  );
}

/**
 * Remove the specified specials from all applicable pet service items in the itinerary.
 * Total costs will be updated and reflected in the returned itinerary.
 * From System Services Booking API
 * - Remove specials
 * @param {*} { customerId, itineraryId, specials }
 * @returns {*} AxiosPromise
 */
export function removeSpecials({ customerId, itineraryId, specials }) {
  return bookingAPI.delete(
    `${endpointVersionString()}/customers/${customerId}/itineraries/${itineraryId}/specials`,
    {
      data: specials
    }
  );
}
