import { getJobRole } from "@/core/selectors/persistentSelectors";
import AppHeader from "@/web/header/AppHeader";
import { connect } from "react-redux";
import { headerConfigConstants } from "@/core/constants/headerConfigConstants";
import HeaderImage from "@/assets/images/bg-header-image.png";

// TODO: move to AppHeader.js so all containers are co-located with the component.
const mapStateToProps = (state, ownProps) => ({
  title: "Class Details",
  config: headerConfigConstants.APPOINTMENT,
  jobRole: getJobRole(state, ownProps),
  backgroundImage: HeaderImage,
});

export default connect(mapStateToProps)(AppHeader);
