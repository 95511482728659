/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelDashBoardActionTypes = {
  LOAD_PETS_HOTEL_DAILY_DASHBOARD: "LOAD_PETS_HOTEL_DAILY_DASHBOARD",
  LOAD_PETS_HOTEL_DAILY_DASHBOARD_REQUEST: "LOAD_PETS_HOTEL_DAILY_DASHBOARD_REQUEST",
  LOAD_PETS_HOTEL_DAILY_DASHBOARD_FAILURE: "LOAD_PETS_HOTEL_DAILY_DASHBOARD_FAILURE",
  LOAD_PETS_HOTEL_DAILY_DASHBOARD_SUCCESS: "LOAD_PETS_HOTEL_DAILY_DASHBOARD_SUCCESS",
  SET_PETS_HOTEL_DAILY_DASHBOARD_TIME_STAMP: "SET_PETS_HOTEL_DAILY_DASHBOARD_TIME_STAMP",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const loadHotelDailyDashboard = ({ storeId, selectedDate }) => ({
  type: hotelDashBoardActionTypes.LOAD_PETS_HOTEL_DAILY_DASHBOARD,
  storeId,
  selectedDate,
});

export const loadHotelDailyDashboardRequest = () => ({
  type: hotelDashBoardActionTypes.LOAD_PETS_HOTEL_DAILY_DASHBOARD_REQUEST,
});

export const loadHotelDailyDashboardFailure = ({ error }) => ({
  type: hotelDashBoardActionTypes.LOAD_PETS_HOTEL_DAILY_DASHBOARD_FAILURE,
  error,
});

export const loadHotelDailyDashboardSuccess = () => ({
  type: hotelDashBoardActionTypes.LOAD_PETS_HOTEL_DAILY_DASHBOARD_SUCCESS,
});
