import React, { useState } from "react";
import { connect } from "react-redux";
import UnknownDogImage from "@/assets/images/unknown-dog.png";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { deleteTrainingClassSession } from "@/dux/trainingClassSessions/actions";
import { createLoadingSelector } from "@/core/selectors/utils";
import { DELETE_TRAINING_CLASS_SESSION } from "@/dux/trainingClassSessions/actions";
import { Card, Layout, Text, Button, InfoBox } from "@prism/psm-ui-components";
import CommonModal from "@/web/common/modals/commonModal";

// cannot cancel modal -------------------------------------------------------------------------------------------------
function AttendeeCard({ rows = [] }) {
  return (
    <Card rows={rows} contentMinWidth={20} borderWidth>
      <img
        alt="dog profile photo"
        src={UnknownDogImage}
        style={{ boxSizing: "border-box", objectFit: "cover", flex: "0 0 auto" }}
      />
    </Card>
  );
}

// modal content
function CannotCancelModalContent({ children }) {
  return (
    <Layout.Box padding="box-padding-8">
      <Layout.Stack space="stack-space-8">
        <Text size="text-size-lg" align="center" bold>
          Cancellation Warning
        </Text>
        <Text size="text-size-md" style={{ width: "60ch" }}>
          This class has an enrolled student. You must move the customer to another date/time or
          cancel their class.
        </Text>
        {children}
      </Layout.Stack>
    </Layout.Box>
  );
}

// cancellation modal content ------------------------------------------------------------------------------------------

// button
const CancelButtonContainer = connect(
  state => ({
    disabled: createLoadingSelector([DELETE_TRAINING_CLASS_SESSION])(state),
    variant: "prism-primary",
    storeNumber: getStoreNumber(state),
  }),
  { handleClick: deleteTrainingClassSession },
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    onClick: () =>
      dispatchProps.handleClick({
        classNumber: ownProps["data-classnumber"],
        storeNumber: stateProps.storeNumber,
      }),
  }),
)(Button);

// modal content
function CancellationModalContent({ children }) {
  return (
    <Layout.Box padding="box-padding-8">
      <Layout.Stack space="stack-space-8">
        <Text size="text-size-lg" align="center" bold>
          Cancel Class
        </Text>
        <Text size="text-size-md" align="center">
          Are you sure you want to cancel this class?
        </Text>
        {children}
      </Layout.Stack>
    </Layout.Box>
  );
}

function DeleteClassInfoBox({ isSRCAgent, header, onClose, children }) {
  if (isSRCAgent) {
    return (
      <Layout.Box>
        <Text size="text-size-lg" bold>
          {header}
        </Text>
        {children}
      </Layout.Box>
    );
  } else {
    return (
      <InfoBox header={header} onClose={onClose}>
        {children}
      </InfoBox>
    );
  }
}

// Modal ---------------------------------------------------------------------------------------------------------------
export default function TrainingDeleteClassInfoBoxWithModal({
  header,
  hasAttendees,
  classNumber,
  attendeeCardRows,
  isSRCAgent,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClose = e => {
    e.stopPropagation();
    setIsModalOpen(!isModalOpen);
  };

  return (
    <DeleteClassInfoBox header={header} onClose={handleClose} isSRCAgent={isSRCAgent}>
      <CommonModal isHidden={!isModalOpen} onClose={handleClose}>
        {hasAttendees ? (
          <CannotCancelModalContent>
            <Layout.Stack space="stack-space-4">
              <Text size="text-size-md" bold>
                Student enrolled
              </Text>
              <AttendeeCard rows={attendeeCardRows} />
            </Layout.Stack>
            <Layout.Center>
              <Button variant="prism-primary" onClick={handleClose}>
                Done
              </Button>
            </Layout.Center>
          </CannotCancelModalContent>
        ) : (
          <CancellationModalContent>
            <Layout.Cluster space="cluster-space-4">
              <Button variant="prism-primary-no-outline" onClick={handleClose}>
                Go back
              </Button>
              <CancelButtonContainer data-classnumber={classNumber}>
                Cancel Class
              </CancelButtonContainer>
            </Layout.Cluster>
          </CancellationModalContent>
        )}
      </CommonModal>
    </DeleteClassInfoBox>
  );
}
