import { GET_FREQUENCY_OPTIONS_SUCCESS } from "./frequencyOptionsActions";

const initialState = [];

const frequencyOptionsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_FREQUENCY_OPTIONS_SUCCESS:
      return payload;
    default:
      return state;
  }
};

export default frequencyOptionsReducer;
