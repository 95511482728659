import { connect } from "react-redux";
import { getSelectedPet } from "../../../core/selectors/bookingUISelectors";
import { getAppointmentByPet } from "../../../core/selectors/cartSelectors";
import { getAddonsWithDynamicPrice } from "../../../core/selectors/dynamicPricesSelector";
import { removeAddOn } from "../../../core/actionCreators/ui/web/cartActionCreators";
import StandaloneAddonsComponent from "./StandaloneAddonsComponent";

const mapStateToProps = (state, ownProps) => {
  return {
    appointment: getAppointmentByPet(state, ownProps),
    addons: getAddonsWithDynamicPrice(state, { petId: ownProps.petId })
  };
};

export default connect(mapStateToProps)(StandaloneAddonsComponent);
