import convertDateToTimestamp from "./dateToTimestamp";
import getTodayTimestamp from "./todayTimestamp";

/**
 * Determine if the date passed is expired based on the current timestamp
 * @param {String} date - Date to transform
 * @param {String} format - Date format to transform
 * @returns {Boolean}
 */
export default function isExpired(date, format = "YYYY-MM-DD") {
  const expirationDate = convertDateToTimestamp(date, format);
  const currentTimestamp = getTodayTimestamp();
  return expirationDate < currentTimestamp;
}

/**
 * Determine if the date passed is expired based on the dateToCheck
 * @param {String} date - Date to transform
 * @param {String?} dateToCheck - date to check for expiry, if undefined then will default to today
 * @returns {Boolean}
 */
export const isExpiredByDate = (expiryDate, dateToCheck) => {
  const currentTimestamp = getTodayTimestamp();
  const expiryTimestamp = expiryDate ? convertDateToTimestamp(expiryDate) : currentTimestamp;
  const dateTimestamp = dateToCheck ? convertDateToTimestamp(dateToCheck) : currentTimestamp;

  return expiryTimestamp < dateTimestamp;
};
