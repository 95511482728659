import { createSelector } from "reselect";
import { getUiWeb } from "./uiSelector";
import { getPet } from "./entitiesSelector";
import normalizeFormInput from "../utils/normalizeUtils/normalizeFormInput";

export const getPetVets = createSelector(
  [getPet],
  pet => (pet && pet.vetClinic && pet.vetClinic.filter(clinic => clinic.activeFlag)) || []
);

export const getPetVetsToDisplay = createSelector([getPetVets], vets =>
  vets.map(vet => {
    const vetPhone =
      vet && vet.phones && vet.phones.find(phone => phone.isActive && phone.isPreferredContact);
    return {
      ...vet,
      phoneNumber: normalizeFormInput(vetPhone, "phoneNumber", "N/A"),
      veterinarianName: normalizeFormInput(vet, "veterinarianName", "N/A"),
      clinicName: normalizeFormInput(vet, "clinicName", "N/A")
    };
  })
);

export const getPetVet = createSelector([getPetVets, (_, props) => props], (vets, props) =>
  vets.find(vet => vet.veterinarianId === props.veterinarianId)
);

export const getPetVetsFormData = createSelector([getPetVet], pet => ({
  veterinarianFullName: normalizeFormInput(pet, "veterinarianFullName"),
  phoneNumber: normalizeFormInput(pet, "phoneNumber"),
  clinicName: normalizeFormInput(pet, "clinicName")
}));

export const getShowVetSearchResults = createSelector(
  [getUiWeb],
  uiWeb => uiWeb.vets.showVetSearchResults
);

export const getVetSearchResultsList = createSelector([getUiWeb], uiWeb =>
  uiWeb.vets.searchResults.map(
    ({
      veterinarian: { fullName, vetId },
      clinicId,
      clinic,
      phone,
      address,
      city,
      zip,
      state
    }) => ({
      clinicId,
      vetId,
      phone,
      address,
      city,
      zip,
      state,
      data: {
        fullName,
        clinic,
        state
      }
    })
  )
);

export const getVetSearchParams = createSelector([getUiWeb], uiWeb =>
  Object.values(uiWeb.vets.searchParams)
);

export const getNumberOfListingsForVetSearch = createSelector(
  [getVetSearchResultsList],
  resultsList => resultsList.length
);

export const getVetToDelete = createSelector([getUiWeb], uiWeb => uiWeb.vets.vetToDelete);

export const getCustomerAndPetKeys = createSelector([getUiWeb], uiWeb => ({
  customerKey: uiWeb.vets.customerKey,
  petId: uiWeb.vets.petId
}));
