import React from "react";
import { connect } from "react-redux";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getBundleHistoryItemPetId, getBundleHistoryItemOfferType } from "./bundleHistorySelectors";
import { BundleHistoryCardDetails } from "./BundleHistoryCardDetails";
import ScissorsSVG from "assets/icons/scissors.svg";
import BundleHistoryRedeemedDatesListContainer from "dux/bgm/bundleHistory/BundleHistoryRedeemedDatesListContainer";
import { PetCard } from "@prism/psm-ui-components";
import { formatPropsForList } from "@/dux/_utils/uiComponentUtils";
import { LayoutBox } from "@/layout/box/Box";

const ServiceImage = () => {
  return (
    <LayoutBox
      style={{
        width: "50px",
        height: "50px",
        background: "#dbe4f1 0% 0% no-repeat padding-box",
        borderRadius: "3px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={ScissorsSVG} style={{ width: "27px", height: "27px" }} />
    </LayoutBox>
  );
};

/**
 * React view component for bundle history cards
 * @summary Other Purchases column of pet parent profile
 * @memberOf Views.Customer
 * @function
 * @name BundleHistoryCardComponent
 * @param {Object} props - props passed into the view component
 * @param {String} props.componentId
 * @param {Boolean} props.isHidden
 * @param {String} props.bundleNumber
 * @param {Object} props.idEnum - { customerId, petId }
 * @param {String} props.offerType
 * @returns {JSX.Element}
 */
const BundleHistoryCardComponent = ({ componentId, isHidden, bundleNumber, idEnum, offerType }) => {
  if (isHidden) return null;

  return (
    <PetCard
      componentId={componentId}
      petImage={<ServiceImage />}
      topSectionRows={formatPropsForList([<BundleHistoryCardDetails id={bundleNumber} />])}
      bottomSectionRows={formatPropsForList([
        <LayoutBox>
          <BundleHistoryRedeemedDatesListContainer
            bundleNumber={bundleNumber}
            idEnum={idEnum}
            offerType={offerType}
          />
        </LayoutBox>,
      ])}
      isDisabled
    />
  );
};

/**
 * Redux Connect function for the BundleHistoryCardComponent
 * @see {@link Views.Customer.BundleHistoryCardComponent}
 * @memberOf Views.Customer
 * @function
 * @name BundleHistoryCard
 * @param {Object} props - props passed into the view component
 * @param {String} props.id
 * @returns {JSX.Element}
 * @example <BundleHistoryCard />
 */
export const BundleHistoryCard = connect((state, { id }) => {
  const customerKey = getCurrentCustomerKey(state);
  const petId = getBundleHistoryItemPetId(state, { customerKey, id });
  return {
    componentId: "BundleHistoryCard",
    bundleNumber: id,
    idEnum: { customerId: customerKey, petId },
    offerType: getBundleHistoryItemOfferType(state, { customerKey, id }),
  };
})(BundleHistoryCardComponent);
