import { overbookingModalTypes } from "./overbookingModalConstants";

export const SHOW_OVERBOOKING_MODAL = "SHOW_OVERBOOKING_MODAL";
export const HIDE_OVERBOOKING_MODAL = "HIDE_OVERBOOKING_MODAL";

/**
 * Action to show an overbooking modal
 * @memberOf Actions.Overbooking
 * @function
 * @name showOverbookingModal
 * @param {Object} modalType
 * @returns {Object}
 * @example showOverbookingModal("MODAL_NAME")
 */
export const showOverbookingModal = modalType => ({
  type: SHOW_OVERBOOKING_MODAL,
  modalType,
});

/**
 * Action to show the cancel exception confirmation modal
 * @memberOf Actions.Overbooking
 * @function
 * @name showOverbookingCancelExceptionModal
 * @param {Object} obj
 * @param {Object} obj.data - the exception to be cancelled
 * @returns {Object}
 * @example showOverbookingCancelExceptionModal({ data })
 */
export const showOverbookingCancelExceptionModal = ({ data }) =>
  showOverbookingModal({
    name: overbookingModalTypes.CANCEL_EXCEPTION,
    data,
  });

/**
 * Action to hide all overbooking modals
 * @memberOf Actions.Overbooking
 * @function
 * @name showOverbookingModal
 * @returns {Object}
 * @example showOverbookingModal()
 */
export const hideOverbookingModal = () => ({
  type: HIDE_OVERBOOKING_MODAL,
});
