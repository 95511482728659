/**
 * Responsible for detecting if any part of a DOM Element is outside the Browser Viewport.
 *
 * @export isOutsideViewPort
 * @param {*} domElement - Dome Element to check if outside browser viewport.
 * @returns Object of key value pair of boolean values
 */
export default function isOutsideViewPort(domElement) {
  const eleBoundingBox = domElement.getBoundingClientRect();
  const boolValues = {};

  boolValues.top = eleBoundingBox.top < 0;

  boolValues.left = eleBoundingBox.left < 0;

  boolValues.bottom = eleBoundingBox.bottom > window.innerHeight;

  boolValues.right = eleBoundingBox.right > window.innerWidth;

  boolValues.any = boolValues.top || boolValues.left || boolValues.bottom || boolValues.right;

  return boolValues;
}
