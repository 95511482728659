import React from "react";
import { connect } from "react-redux";
import { Layout, Text } from "@prism/psm-ui-components";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getTrainingAttendeeById } from "@/dux/trainingClassSessions/selectors";

const TrainingAttendeeGoals = ({ goals }) => (
  <Layout.Box>
    <Layout.Box>
      <Text>Goals:</Text>
    </Layout.Box>
    <Layout.Box>
      <ul style={{ margin: 0, paddingInlineStart: "20px" }}>
        {goals.map(goal => (
          <li key={goal}>{goal}</li>
        ))}
      </ul>
    </Layout.Box>
  </Layout.Box>
);

export default connect((state, { attendeeId }) => {
  const storeNumber = getStoreNumber(state);
  const { goals } = getTrainingAttendeeById(state, { storeNumber, attendeeId });
  return {
    isHidden: false,
    goals,
  };
})(TrainingAttendeeGoals);
