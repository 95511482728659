import moment from "moment";
import { connect } from "react-redux";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { getWeekNumber } from "../../core/selectors/commonSelector";
import { setWeekNumber } from "../../core/actionCreators/ui/web/commonActionCreators";
import { getSelectedTrainingPetService } from "@/dux/scheduleTrainingPage/scheduleTrainingPageSelectors";
import { getCachedTrainingAvailabilityWeeks } from "@/dux/scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import dispatchTrainingAvailability from "@/dux/scheduleTrainingPage/scheduleTrainingPageUtils";
import Arrow from "../common/Arrow";

const mapStateToProps = (state) => ({
  componentID: "button_next_week_navigation",
  weekNumber: getWeekNumber(state),
  selectedDate: getSelectedDate(state),
  petServiceId: getSelectedTrainingPetService(state),
  storeNumber: getStoreNumber(state),
  petId: getSelectedPet(state),
  cachedTrainingAvailabilityWeeks: getCachedTrainingAvailabilityWeeks(state),
  direction: "right",
});

const mapDispatchToProps = (dispatch) => ({
  dispatchClickHandler: ({ selectedDate, weekNumber, storeNumber, petServiceId, petId, cache }) => {
    dispatch(setSelectDate(selectedDate));
    dispatch(setWeekNumber({ weekNumber }));
    dispatchTrainingAvailability({
      selectedDate,
      cache,
      storeNumber,
      petServiceId,
      petId,
      dispatch,
    });
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  clickHandler: () => {
    const {
      selectedDate,
      petServiceId,
      storeNumber,
      petId,
      cachedTrainingAvailabilityWeeks,
      weekNumber,
    } = propsFromState;

    propsFromDispatch.dispatchClickHandler({
      selectedDate: moment(selectedDate).add(1, "days"),
      weekNumber: weekNumber + 1,
      storeNumber,
      petServiceId,
      petId,
      cache: cachedTrainingAvailabilityWeeks,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Arrow);
