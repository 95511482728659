import { set } from "lodash/fp";
import generalActionTypes from "core/actionTypes/ui/web/generalActionTypes";
import { checkForUndefined } from "core/utils/undefinedOrNullUtils/undefinedOrNullUtils";

const initialState = null;
/**
 * Reducer function for managing the current pet state.
 *
 * @memberof Reducer.persistent
 * @param {object} state - The current state.
 * @param {object} action - The action object containing type and data.
 * @returns {object} - The new state after applying the action.
 */
export const currentPetReducer = (state = initialState, action) => {
  switch (action.type) {
    case generalActionTypes.SET_PET_ID:
      return checkForUndefined(action.petId, initialState);

    default:
      return state;
  }
};