import searchSalonActionTypes from "../../../actionTypes/searchSalonActionTypes";

const initialState = {
  showSearchResults: false,
  showQickView: false,
  searchField: "",
  searchFieldType: "",
  searchErrorMessage: "",
  searchInputDOMRect: null,
  searchErrorFlyoutOpen: false,
  searchActive: false,
  searchApplied: false
};

const searchSalonReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchSalonActionTypes.SHOW_SEARCH_RESULTS:
      return {
        ...state,
        showSearchResults: action.showSearchResults
      };

    case searchSalonActionTypes.SHOW_QUICK_VIEW:
      return {
        ...state,
        showQuickView: action.showQuickView,
        custID: action.custID
      };

    case searchSalonActionTypes.SET_SEARCH_FIELD:
      return {
        ...state,
        searchField: action.searchFieldValue
      };

    case searchSalonActionTypes.CLEAR_SEARCH_FIELD_VALUE:
      return {
        ...state,
        searchField: initialState.searchField
      };

    case searchSalonActionTypes.SET_SEARCH_FIELD_TYPE:
      return {
        ...state,
        searchFieldType: action.searchFieldType
      };

    case searchSalonActionTypes.SET_SEARCH_ERROR_MESSAGE:
      return {
        ...state,
        searchErrorMessage: action.message
      };

    case searchSalonActionTypes.CLEAR_SEARCH_ERROR_MESSAGE:
      return {
        ...state,
        searchErrorMessage: initialState.searchErrorMessage
      };

    case searchSalonActionTypes.OPEN_SEARCH_ERROR_FLYOUT:
      return {
        ...state,
        searchErrorFlyoutOpen: true
      };

    case searchSalonActionTypes.CLOSE_SEARCH_ERROR_FLYOUT:
      return {
        ...state,
        searchErrorFlyoutOpen: false
      };

    case searchSalonActionTypes.SET_SEARCH_FIELD_ACTIVE:
      return {
        ...state,
        searchFieldActive: true
      };

    case searchSalonActionTypes.CLEAR_SEARCH_FIELD_ACTIVE:
      return {
        ...state,
        searchFieldActive: false
      };

    case searchSalonActionTypes.SET_SEARCH_APPLIED:
      return {
        ...state,
        searchApplied: true
      };

    case searchSalonActionTypes.CLEAR_SEARCH_APPLIED:
      return {
        ...state,
        searchApplied: false
      };

    default:
      return state;
  }
};

export default searchSalonReducer;
