import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { getSelectedAppointment } from "core/selectors/commonSelector";
import { getPetServiceItem, getAssociates } from "core/selectors/entitiesSelector";
import { creationChannels } from "core/constants/appointmentConstants";
import { color } from "web/common/styles/theme";
import GridTable from "web/common/GridTable/GridTable";
import { selectRolledUpHotelHistory } from "dux/appointmentHistory/appointmentHistorySelector";
import { getScheduledSuspensions } from "dux/scheduleSuspensionTable/scheduleSuspensionTableSelector";
import { getScheduledSuspensionHistoryModalSuspensionId } from "dux/scheduleSuspensionHistoryModal/scheduleSuspensionHistoryModalSelector";
import { selectRoomSuspensionHistoryModalSuspensionId } from "@/dux/hotelManageRoomDetailsSuspensionHistory/hotelManageRoomDetailsSuspensionHistorySelectors";
import { selectHotelRoomSuspensionBySuspensionId } from "@/dux/hotelManageRoomDetails/hotelManageRoomDetailsSelectors";
import { selectOverbookingExceptionsHistoryDataById } from "@/dux/overbookingExceptionTableDetails/overBookingExceptionTableDetailsSelectors";
import { Heading } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCenter } from "@/layout/center/Center";
import { formatAndSortHistoryObject } from "@/core/utils/historyModalUtils/formatAndSortHistoryObject";

export const AppointmentHistory = props => {
  const { componentId, heading, sortFn, sortArgs } = props;
  const history = sortFn(sortArgs);

  const [sortedHistory, setSortedHistory] = useState(history);

  // Set initial state from props - will update history from props if the prop is updated, overwriting local state.
  useEffect(() => {
    setSortedHistory(history);
  }, [sortArgs]);

  const sortHistory = field => {
    const newHistory = [...sortedHistory].sort((a, b) => {
      if (a[field] < b[field]) {
        return -1;
      }
      if (a[field] > b[field]) {
        return 1;
      }
      return 0;
    });

    if (!isEqual(newHistory, sortedHistory)) {
      setSortedHistory(newHistory);
    } else {
      const reverseHistory = [...newHistory].reverse();

      setSortedHistory(reverseHistory);
    }
  };

  const gridConfig = {
    gridOptions: {
      fixedHeader: true,
      sortable: true,
      sortHandler: field => {
        sortHistory(field);
      },
      headerStyling: {
        color: color.lightGrey,
        fontFamily: "Montserrat",
        fontWeight: "normal",
        fontSize: "13px",
        boxShadow: "0 1px 20px -10px rgba(0,0,0,0.5)",
      },
      columnsStyling: {
        fontFamily: "Lato",
        fontSize: "13px",
      },
    },
    columnDefs: [
      {
        title: "Date",
        field: "date",
        width: "12.5%",
      },
      {
        title: "Time",
        field: "time",
        width: "12.5%",
      },
      {
        title: "Activity Log",
        field: "eventType",
      },
      {
        title: "Description",
        field: "description",
      },
      {
        title: "User",
        field: "associate",
        cellRenderer: row =>
          row.associate && (
            <div>
              {row.associate.associateName && (
                <div>
                  {row.associate.associateName}
                  <br />
                </div>
              )}
              <div>
                {row.channel === creationChannels.ONLINE
                  ? "Online"
                  : `Associate #: ${row.associate.associateId || ""}`}
              </div>
            </div>
          ),
      },
    ],
  };

  if (!sortedHistory) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack space="scale-G1" style={{ maxWidth: "87vw", overflow: "hidden" }}>
        <LayoutCenter id={`${componentId}__header`}>
          <Heading size="title" tagName="h2">
            {heading}
          </Heading>
        </LayoutCenter>
        <LayoutBox id={`${componentId}__grid`} padding="scale-0" style={{ overflow: "auto" }}>
          <GridTable config={gridConfig} rowsData={sortedHistory} />
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

//  SALON APPOINTMENT HISTORY CONTAINER ----------------------------------------------------------------------------------------
export const SalonAppointmentHistoryTable = connect(state => {
  const petServiceItemId = getSelectedAppointment(state);
  const salonAppointmentHistory = getPetServiceItem(state, { petServiceItemId })?.history;
  const associates = getAssociates(state);

  return {
    componentId: "SalonAppointmentHistory",
    sortFn: formatAndSortHistoryObject,
    sortArgs: {
      history: salonAppointmentHistory,
      associates,
      isAsc: true,
    },
    heading: "Appointment History",
  };
})(AppointmentHistory);

//  HOTEL APPOINTMENT HISTORY CONTAINER ----------------------------------------------------------------------------------------
export const HotelAppointmentHistoryTable = connect(state => {
  const appointmentHistory = selectRolledUpHotelHistory(state);
  const associates = getAssociates(state);

  return {
    componentId: "HotelAppointmentHistoryTable",
    sortFn: formatAndSortHistoryObject,
    sortArgs: {
      history: appointmentHistory,
      associates,
    },
    heading: "Hotel Appointment History",
  };
})(AppointmentHistory);

//  HOTEL SUSPENSIONS SCHEDULE HISTORY ----------------------------------------------------------------------------------------
export const HotelScheduleSuspensionsHistoryTable = connect(state => {
  const suspensionId = getScheduledSuspensionHistoryModalSuspensionId(state);
  const suspensionHistories = getScheduledSuspensions(state);
  const suspensionHistory =
    suspensionHistories.find(item => item.suspensionId === suspensionId).history || [];
  const associates = getAssociates(state);

  return {
    componentId: "HotelScheduleSuspensionsHistoryTable",
    sortFn: formatAndSortHistoryObject,
    sortArgs: {
      history: suspensionHistory,
      associates,
    },
    heading: "Suspension History",
  };
})(AppointmentHistory);

// HOTEL MANAGE ROOM SUSPENSIONS HISTORY CONTAINER ----------------------------------------------------------------------------------------
export const HotelRoomSuspensionsHistoryTable = connect(state => {
  const suspensionId = selectRoomSuspensionHistoryModalSuspensionId(state);
  const suspensionDetails = selectHotelRoomSuspensionBySuspensionId({ suspensionId })(state);
  const suspensionHistory = suspensionDetails?.history;
  const associates = getAssociates(state);

  return {
    componentId: "HotelRoomSuspensionsHistoryTable",
    sortFn: formatAndSortHistoryObject,
    sortArgs: {
      history: suspensionHistory,
      associates,
    },
    heading: "Suspensions History",
  };
})(AppointmentHistory);

// HOTEL OVERBOOKING EXCEPTIONS HISTORY ----------------------------------------------------------------------------------------
export const HotelOverbookingExceptionsHistoryTable = connect(state => {
  const history = selectOverbookingExceptionsHistoryDataById(state);
  const associates = getAssociates(state);

  return {
    componentId: "HotelOverbookingExceptionsTable",
    sortFn: formatAndSortHistoryObject,
    sortArgs: {
      history,
      associates,
    },
    heading: "Exceptions History",
  };
})(AppointmentHistory);
