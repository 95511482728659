import { createBrowserHistory } from "history";
import { routePaths } from "../../constants/routePaths";
import getPageFromURLPath from "./pageFromURLPath";
import { paramName } from "../../constants/urlParamConstants";

/**
 * A factory responsible for taking a string name for property within a url you want and will return
 * the value of that property. If you use the urlParamConstants, this will provide a constant list
 * of correct params this factory will take.
 *
 * @export getParamFromPath
 * @param {string} param - The name of the param you want, e.g. 'CUSTOMER_KEY' (can get from the urlParamConstants.js)
 * @returns (string) - Param value
 */
export default function getParamFromPath(param) {
  const history = createBrowserHistory();
  const page = `/${getPageFromURLPath(history.location.pathname)}`;

  switch (page) {
    case routePaths.PET_PARENT_PROFILE:
      if (param == paramName.CUSTOMER_KEY) {
        const splitString = history.location.pathname.split("/");
        return splitString[2];
      }

      break;
    case routePaths.CHECK_IN:
    case routePaths.CHECK_OUT:
      if (param == paramName.ITINERARY_ID) {
        const splitString = history.location.pathname.split("/");
        return splitString[3];
      } else if (param == paramName.CUSTOMER_KEY) {
        const splitString = history.location.pathname.split("/");
        return splitString[2];
      }

    default:
      break;
  }
}
