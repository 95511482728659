import { connect } from "react-redux";
import {
  getCheckInScreenEligibility,
  getCheckOutScreenEligibility,
} from "dux/eligibility/actions/eligibilityByScreenActions";
import {
  getActionClickedReasons,
  getPageMountReasons,
} from "dux/eligibility/selectors/getReasonsSelectors";
import { eligibilityPetActionTypes } from "dux/eligibility/actions/eligibilityPetActions";
import { eligibilityPetsByItineraryActionTypes } from "dux/eligibility/actions/eligibilityPetsByItineraryActions";
import { eligibilityCustomerActionTypes } from "dux/eligibility/actions/eligibilityCustomerActions";
import getIsBGMWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getFTCOWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import {
  getAssociates,
  getDoServiceItemsMatch,
  getIsOnePetServiceItemCancelled,
  getPetByPetServiceItemId,
  getPetServiceItem,
  getPetServiceItemsByItinerary,
  getServicesIdsByPet,
} from "../../core/selectors/entitiesSelector";
import {
  getCurrentCustomer,
  getCurrentItinerary,
  getCurrentPetServiceItem,
  getNumberOfPetsInItinerary,
  getPageName,
  getPetNamesFromCurrentItinerary,
  getPetsHavePaid,
  isFromAnotherStore,
} from "../../core/selectors/checkInOutSelector";
import { getStoreNumber } from "../../core/selectors/persistentSelectors";
import { createLoadingSelector } from "../../core/selectors/utils";
import { getPetHasExpiredVaccination } from "../../core/selectors/ui/vaccinations/vaccinationsSelectors";
import {
  loadInvoice,
  loadItinerary,
  loadServiceCard,
  loadServiceCards,
  updateItineraryStatus,
} from "../../core/actionCreators/itinerariesActionCreators";
import { setCustomerId, setPetId } from "../../core/actionCreators/ui/web/generalActionCreators";
import { loadStoreServicesForPet } from "../../core/actionCreators/servicesSelectionActionCreator";
import petsActionTypes from "../../core/actionTypes/petsActionTypes";
import customersActionTypes from "../../core/actionTypes/customersActionTypes";
import {
  printInvoiceForMulti,
  setItinerary,
  setPageName,
  setPetServiceItem,
  showCheckInOutModal,
} from "../../core/actionCreators/checkInOutActionCreator";
import itinerariesActionTypes from "../../core/actionTypes/itinerariesActionTypes";
import { updateEngagementStatus } from "../../core/actionCreators/engagementsActionCreators";
import { setRebookErrorMessages } from "../../core/actionCreators/ui/web/commonActionCreators";
import { modalTypes } from "../../core/constants/modalConstants";
import { clearCart } from "../../core/actionCreators/ui/web/cartActionCreators";
import { loadEngagementSalon } from "../../core/actionCreators/salonActionCreator";
import withEligibility from "../common/hoc/withEligibility";
import getPageFromURLPath from "../../core/utils/matchUtils/pageFromURLPath";
import { APPOINTMENT_STATUS } from "../../core/constants";
import bookingActionTypes from "../../core/actionTypes/bookingActionTypes";
import { selectPet } from "../../core/actionCreators/bookingActionCreators";
import { getSelectedPet } from "../../core/selectors/bookingUISelectors";
import { pageNames } from "../../core/constants/checkInOutConstants";
import { showConfirmationModal } from "../../core/actionCreators/confirmationModalActionCreators";
import { getCheckInOutModalType } from "../../core/selectors/modals/checkInOutModalSelectors";
import { updateAppointmentNote } from "../../core/actionCreators/notesActionCreator";
import { getAppointmentNote } from "../../core/selectors/cartSelectors";
import { getCurrentCustomerKey } from "../../core/selectors/persistent/customer/customerSelectors";
import { getAvailableBundlesForAllPetsByCustomer } from "../../dux/bgm/availableBundlesByPet/availableBundlesByPetActions";
import SharedCheckInOutWrapper from "./sharedCheckInOutWrapperComponent";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const mapStateToProps = (state, ownProps) => {
  const {
    router: {
      params: { itineraryId },
    },
  } = ownProps;
  const currentPetServiceItemId = getCurrentPetServiceItem(state);
  const selectedPet =
    currentPetServiceItemId &&
    getPetByPetServiceItemId(state, {
      petServiceItemId: currentPetServiceItemId,
    });
  const numberOfPets = getNumberOfPetsInItinerary(state, ownProps);
  const customerKey = getCurrentCustomerKey(state);
  const isBGMFeatureFlagEnabled = !getIsBGMWorkflowFeatureFlagHidden(state);
  const isFTCOFeatureFlagEnabled = !getFTCOWorkflowFeatureFlagHidden(state);
  const isBGMOrFTCOEnabled = isBGMFeatureFlagEnabled || isFTCOFeatureFlagEnabled;

  return {
    customerKey,
    isBGMOrFTCOEnabled,
    petId: getSelectedPet(state, ownProps),
    ModalType: getCheckInOutModalType(state),
    petNames: getPetNamesFromCurrentItinerary(state),
    isPageLoading: createLoadingSelector([
      itinerariesActionTypes.LOAD_INVOICE,
      bookingActionTypes.REFRESH_SESSION_CACHE,
      customersActionTypes.UPDATE_CUSTOMER_CONTACT_SETTINGS,
    ])(state),
    isPetsLoading: createLoadingSelector([
      petsActionTypes.LOAD_PETS_BY_CUSTOMER,
      customersActionTypes.LOAD_CUSTOMER,
      itinerariesActionTypes.LOAD_ITINERARY,
    ])(state),
    isEligibilityLoading: createLoadingSelector([
      eligibilityPetActionTypes.GET_PET_ELIGIBILITY,
      eligibilityPetsByItineraryActionTypes.GET_PETS_ELIGIBILITY_BY_ITINERARY,
      eligibilityCustomerActionTypes.GET_CUSTOMER_ELIGIBILITY,
    ])(state),
    eligibilityAlerts: getPageMountReasons(state),
    eligibilityWarnings: getActionClickedReasons(state),
    petServiceItems: getPetServiceItemsByItinerary(state, { itineraryId }),
    currentPetServiceItemId,
    currentPetServiceItem:
      currentPetServiceItemId &&
      getPetServiceItem(state, { petServiceItemId: currentPetServiceItemId }),
    selectedPet,
    petHasExpiredVaccination: getPetHasExpiredVaccination(state, {
      petId: selectedPet && selectedPet.petId,
    }),
    pageName: getPageName(state),
    numberOfPets,
    isMultiplePets: numberOfPets && numberOfPets > 1,
    customerId: getCurrentCustomer(state),
    itineraryId: getCurrentItinerary(state),
    engagementId: getCurrentPetServiceItem(state),
    associates: getAssociates(state),
    petServiceIds: getServicesIdsByPet(state, {
      petId: selectedPet && selectedPet.petId,
    }),
    petsPaid: getPetsHavePaid(state),
    currentStore: getStoreNumber(state),
    isFromAnotherStore: isFromAnotherStore(state, {
      petServiceItemId: currentPetServiceItemId,
    }),
    isOnePetServiceItemCancelled: getIsOnePetServiceItemCancelled(state, {
      itineraryId,
    }),
    doServiceItemsMatch: getDoServiceItemsMatch(state, { itineraryId }),
    appointmentNote: getAppointmentNote(state, {
      petId: selectedPet && selectedPet.petId,
    }),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    router: {
      location,
      params: { customerKey, itineraryId },
    },
  } = ownProps;
  const pageName = getPageFromURLPath(location?.pathname);

  return {
    loadItinerary: ({ customerKey, itineraryId }) =>
      dispatch(loadItinerary({ customerKey, itineraryId, shouldLoadPets: true })),
    setCustomerID: customerKey => {
      const customerId = customerKey;
      dispatch(setCustomerId({ customerId }));
    },

    setPetId: petId => dispatch(setPetId(petId)),
    selectPet: petId => dispatch(selectPet(petId)),

    dispatchGetAvailableBundlesForAllPetsByCustomer: ({ customerKey }) =>
      dispatch(getAvailableBundlesForAllPetsByCustomer({ customerKey })),

    loadSalon: engagementStoreNumber => dispatch(loadEngagementSalon({ engagementStoreNumber })),

    loadServicesForPet: ({ customerKey, storeNumber, petId }) =>
      dispatch(loadStoreServicesForPet({ customerKey, storeNumber, petId })),

    dispatchPrintServiceCard: ({ customerKey, itineraryId, engagementId }) =>
      dispatch(loadServiceCard({ customerKey, itineraryId, engagementId })),

    dispatchPrintServiceCards: ({ customerKey, itineraryId, engagementId }) =>
      dispatch(loadServiceCards({ customerKey, itineraryId, engagementId })),

    dispatchPrintInvoice: ({ customerKey, itineraryId, petId }) =>
      dispatch(loadInvoice({ customerKey, itineraryId, petId })),

    setPetServiceItem: petServiceItem => dispatch(setPetServiceItem({ petServiceItem })),

    checkInOutMulti: ({ pageName, reason, status, customerId, itineraryId, petsPaid }) => {
      if (status === APPOINTMENT_STATUS.CHECKED_IN && !petsPaid) {
        dispatch(printInvoiceForMulti({ customerKey: customerId, itineraryId }));
      } else if (status === APPOINTMENT_STATUS.CHECKED_OUT && !petsPaid) {
        dispatch(loadInvoice({ customerKey: customerId, itineraryId }));
      }
      dispatch(
        updateItineraryStatus({
          pageName,
          reason,
          status,
          customerId,
          itineraryId,
        }),
      );
    },

    checkInOutSingle: ({
      pageName,
      reason,
      status,
      customerId,
      itineraryId,
      engagementId,
      petsPaid,
    }) => {
      if (status === APPOINTMENT_STATUS.CHECKED_IN && !petsPaid) {
        dispatch(
          loadServiceCard({
            customerKey: customerId,
            itineraryId,
            engagementId,
          }),
        );
      } else if (status === APPOINTMENT_STATUS.CHECKED_OUT && !petsPaid) {
        dispatch(loadInvoice({ customerKey: customerId, itineraryId }));
      }
      dispatch(
        updateEngagementStatus({
          pageName,
          reason,
          status,
          customerId,
          itineraryId,
          engagementId,
        }),
      );
    },

    setItinerary: itinerary => dispatch(setItinerary({ itinerary })),

    setPage: ({ pageName }) => dispatch(setPageName({ pageName })),

    setRebookErrorMessages: ({ errorMessages }) =>
      dispatch(setRebookErrorMessages({ errorMessages })),
    showModal: modalType => dispatch(showCheckInOutModal({ modalType })),
    showAlertsModal: () => dispatch(showCheckInOutModal({ modalType: modalTypes.ALERTS })),
    showConfirmationModal: args => dispatch(showConfirmationModal(args)),
    showEligibilityWarningsModal: () =>
      dispatch(showCheckInOutModal({ modalType: modalTypes.ELIGIBILITY_WARNINGS })),
    clearCart: () => dispatch(clearCart()),
    updateAppointmentNote: ({ appointmentNote, customerId, itineraryId, petServiceItemId }) => {
      dispatch(
        updateAppointmentNote({
          data: { note: appointmentNote },
          customerId,
          itineraryId,
          petServiceItemId,
        }),
      );
    },
    onCallEligibility: () => {
      switch (pageName) {
        case pageNames.CHECK_IN:
          dispatch(getCheckInScreenEligibility({ customerKey, itineraryId }));
          break;
        case pageNames.CHECK_OUT:
          dispatch(getCheckOutScreenEligibility({ customerKey, itineraryId }));
          break;
        default:
      }
    },
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  updateCheckInOutMultiStatus: ({ reason, status, pageName }) =>
    propsFromDispatch.checkInOutMulti({
      customerId: propsFromState.customerKey,
      itineraryId: propsFromState.itineraryId,
      pageName,
      reason,
      status,
      petsPaid: propsFromState.petsPaid,
    }),
  updateCheckInOutSingleStatus: ({ reason, status, pageName }) =>
    propsFromDispatch.checkInOutSingle({
      customerId: propsFromState.customerKey,
      itineraryId: propsFromState.itineraryId,
      engagementId: propsFromState.engagementId,
      pageName,
      reason,
      status,
      petsPaid: propsFromState.petsPaid,
    }),
  printInvoice: () =>
    propsFromDispatch.dispatchPrintInvoice({
      customerKey: propsFromState.customerKey,
      itineraryId: propsFromState.itineraryId,
      petId: propsFromState.selectedPet.petId.toString(),
    }),
  printServiceCard: () =>
    propsFromDispatch.dispatchPrintServiceCard({
      customerKey: propsFromState.customerKey,
      itineraryId: propsFromState.itineraryId,
      engagementId: propsFromState.engagementId,
    }),
  printServiceCards: () =>
    propsFromDispatch.dispatchPrintServiceCards({
      customerKey: propsFromState.customerKey,
      itineraryId: propsFromState.itineraryId,
      engagementId: propsFromState.engagementId,
    }),
  getAvailableBundlesForAllPetsByCustomer: () =>
    propsFromDispatch.dispatchGetAvailableBundlesForAllPetsByCustomer({
      customerKey: propsFromState.customerKey,
    }),
});

export default withRouteProps(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  )(withEligibility(SharedCheckInOutWrapper)),
);
