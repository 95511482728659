import React from "react";
import styled from "styled-components";
import AddonRow from "../../common/addonRow/AddonRowContainer";

const AddOnsWrapper = styled.div`
  margin-top: 20px;
`;

export default class StandaloneAddonsComponent extends React.Component {
  render() {
    const { appointment, addons, removeAddOn, petId } = this.props;

    if (!appointment) {
      return null;
    }

    return (
      <AddOnsWrapper>
        {addons.map(addon => {
          return (
            <AddonRow key={addon.addOnId} addon={addon} removeAddOn={removeAddOn} petId={petId} />
          );
        })}
      </AddOnsWrapper>
    );
  }
}
