import { associateWebAPI } from "./_axiosConfigAssociateWeb";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Uploads a trainer photo to blob storage and saves the URL to SF.
 * The photo uploaded will be returned in the GET Training Profile API call.
 *
 * @param {Object} options - Options to be passed to the web service
 * @param {Number} options.storeNumber - Store number where trainer photo is being
 * uploaded
 * @param {String} options.associateId - Associate id number associated with photo
 * @returns {*} AxiosPromise
 */
export function uploadTrainerPhoto({ storeNumber, associateId, file }) {
  return associateWebAPI.put(
    `${endpointVersionString()}/stores/${storeNumber}/associates/${associateId}/photos/training`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
}
