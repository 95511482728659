//@ts-check
/**
 *
 * @param {object} state
 * @param {object} [state.modals]
 * @param {object} [state.modals.hotelRoomSuspensionsHistoryModal]
 * @param {boolean} [state.modals.hotelRoomSuspensionsHistoryModal.isHidden]
 * @returns - Suspensions history modal is hidden or not
 */
export const selectRoomSuspensionHistoryModalIsHidden = state =>
  state.modals?.hotelRoomSuspensionsHistoryModal?.isHidden;

/**
 *
 * @param {object} state
 * @param {object} [state.modals]
 * @param {object} [state.modals.hotelRoomSuspensionsHistoryModal]
 * @param {(string | null)} [state.modals.hotelRoomSuspensionsHistoryModal.suspensionId]
 * @returns - Suspension ID for selected record in room suspensions table
 */
export const selectRoomSuspensionHistoryModalSuspensionId = state =>
  state.modals?.hotelRoomSuspensionsHistoryModal?.suspensionId;
