import { put, select, take } from "redux-saga/effects";
import { isEqual, get, flow, filter } from "lodash/fp";
import { getEligibilityResults } from "dux/eligibility/selectors/eligibilitySelectors";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { loadEligibilityResults } from "dux/eligibility/actions/eligibilityLoadClearActions";
import { showBookingModal } from "core/actionCreators/bookingActionCreators";
import bookingActionTypes from "core/actionTypes/bookingActionTypes";
import { modalTypes as commonModalTypes } from "core/constants/modalConstants";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getPetEligibilitySuccess } from "dux/eligibility/actions/eligibilityPetActions";

export default function* petEligibilitySaga({ newEligibilityResponse, eligibilityResults }) {
  const selectedPet = yield select(getSelectedPet);
  const petEligibility = get(["pets", selectedPet], eligibilityResults);
  const eligibility = yield select(getEligibilityResults);

  const secondaryReasons = flow([get("reasons"), filter({ isSecondary: true })])(petEligibility);
  const newEligibility = {
    ...newEligibilityResponse.data,
    petId: selectedPet,
    reasons: [...newEligibilityResponse.data.reasons, ...secondaryReasons],
  };

  yield put(getPetEligibilitySuccess({ petId: selectedPet, result: newEligibility }));

  let shouldProceed;

  if (!isEqual(newEligibility, petEligibility)) {
    yield select(getSystemBookingFlow) === systemName.SALON
      ? yield put(
          loadEligibilityResults({
            ...eligibilityResults,
            pets: {
              ...eligibilityResults.pets,
              [selectedPet]: newEligibility,
            },
          }),
        )
      : yield put(
          loadEligibilityResults({
            ...eligibility,
            pets: {
              ...eligibilityResults.pets,
              [selectedPet]: newEligibility,
            },
          }),
        );

    yield put(showBookingModal(commonModalTypes.ELIGIBILITY_WARNINGS));
    const shouldProceedAction = yield take(bookingActionTypes.SHOULD_PROCEED_WITH_BOOKING);
    shouldProceed = shouldProceedAction.shouldProceed;
  } else {
    shouldProceed = true;
  }
  return shouldProceed;
}
