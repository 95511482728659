import React from "react";
import { LayoutBox } from "@/layout/box/Box";
import { TextareaField } from "@petsmart-ui/sparky";

export const HotelAddonInstructionsInput = ({
  componentId,
  instructions,
  setInstructions,
  label = "Instructions (Optional)",
  charLimit = 255,
  disabled,
}) => {
  const charCount = instructions?.length ?? 0;
  return (
    <LayoutBox id={componentId} padding="scale-0">
      <TextareaField
        label={label}
        maxLength={charLimit}
        onChange={({ target }) => setInstructions(target?.value)}
        value={instructions ?? ""}
        rows={4}
        fieldNote={`${charLimit - charCount} characters remaining`}
        disabled={disabled}
      />
    </LayoutBox>
  );
};
