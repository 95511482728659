import { associateWebAPI } from "./_axiosConfigAssociateWeb";
import { endpointVersionString } from "core/utils/envUtils";

/**
 * Get Customer Details from the Web Associate API- Get Customer Details - Version 1
 * @param {*} { customerKey }
 * @returns
 */
export function fetchCustomer({ customerKey }) {
  return associateWebAPI.get(
    `${endpointVersionString()}/customers/${customerKey}?includeInactivePets=true`,
  );
}

/**
 * Get Customer Details from the Web Associate API- Get Customer Details - Version 2
 * @param {*} { customerKey }
 * @returns
 */
export function fetchCustomerV2({ customerKey }) {
  return associateWebAPI.get(`v2/customers/${customerKey}?includeInactivePets=true`);
}


/**
 * Update Customer Details from the Web Associate API - Update Customer Details
 * @param {*} { customerKey, data }
 * @returns
 */
export function putCustomer({ customerKey, data }) {
  return associateWebAPI.put(`${endpointVersionString()}/customers/${customerKey}`, data);
  // NOTE: APIM ink https://petm-apimanagement-qa.portal.azure-api.net/docs/services/web-associate-exp-api-qa/operations/Update-Customer-Details?
}

/**
 * Create a Customer from the Web associate API - Create a Customer
 * @param {*} { data }
 * @returns
 */
export function postCustomer({ data }) {
  return associateWebAPI.post(`${endpointVersionString()}/customers`, data);
}

/**
 * Create a Customer from the Web associate API - Create a Customer - Version 2
 * @param {*} { data }
 * @returns
 */
export function postCustomerV2({ data }) {
  return associateWebAPI.post(`v2/customers`, data);
}
