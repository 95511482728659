import { createSelector } from "reselect";
import { getHotelEngagementByEngagementId } from "dux/hotelEngagements/hotelEngagementSelectors";
import { get } from "lodash/fp";

/**
 *  Selector to get the room object for a given engagement
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name getCheckInOutPetRoom
 *  @param {Object} state - redux state
 *  @returns {string} the petServiceName
 *  @example
 *
 *  getCheckInOutPetRoom(state, { engagementId })
 */
export const getCheckInOutPetRoom = createSelector(
  [getHotelEngagementByEngagementId],
  engagement => {
    return get(["metadata", "room"], engagement) || {};
  },
);
