import { fork, put, takeEvery, all } from "redux-saga/effects";
import {
  hotelArrivalsActionTypes,
  loadHotelArrivalsFailure,
  loadHotelArrivalsSuccess,
} from "./hotelArivalsActions";

export function* loadHotelArrivals({ arrivals }) {
  try {
    yield put(loadHotelArrivalsSuccess({ arrivals }));
  } catch (error) {
    yield put(loadHotelArrivalsFailure({ error }));
  }
}

function* watchHotelArrivals() {
  yield takeEvery(hotelArrivalsActionTypes.LOAD_HOTEL_ARRIVALS_REQUEST, loadHotelArrivals);
}

export default function* hotelArrivalsSaga() {
  yield all([fork(watchHotelArrivals)]);
}
