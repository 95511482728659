import maxPerBlockOrCheckReductionReasonsActionTypes from "../actionTypes/maxPerBlockOrCheckReductionReasonsActionTypes";

export const loadMaxPerBlockOrCheckReductionReasons = () => ({
  type: maxPerBlockOrCheckReductionReasonsActionTypes.LOAD_MAX_PER_BLOCK_REDUCTION_REASONS
});

export const loadMaxPerBlockOrCheckReductionReasonsRequest = () => ({
  type: maxPerBlockOrCheckReductionReasonsActionTypes.LOAD_MAX_PER_BLOCK_REDUCTION_REASONS_REQUEST
});

export const loadMaxPerBlockOrCheckReductionReasonsSuccess = ({
  maxPerBlockOrCheckReductionReasons
}) => ({
  type: maxPerBlockOrCheckReductionReasonsActionTypes.LOAD_MAX_PER_BLOCK_REDUCTION_REASONS_SUCCESS,
  payload: maxPerBlockOrCheckReductionReasons
});

export const loadMaxPerBlockOrCheckReductionReasonsFailure = ({ error }) => ({
  type: maxPerBlockOrCheckReductionReasonsActionTypes.LOAD_MAX_PER_BLOCK_REDUCTION_REASONS_FAILURE,
  error
});

export default {
  loadMaxPerBlockOrCheckReductionReasons,
  loadMaxPerBlockOrCheckReductionReasonsRequest,
  loadMaxPerBlockOrCheckReductionReasonsSuccess,
  loadMaxPerBlockOrCheckReductionReasonsFailure
};
