import checkInOutActionTypes from "../../../actionTypes/checkInOutActionTypes";
import flyOutActionTypes from "../../../actionTypes/flyOutActionTypes";
import servicesSelectionActionTypes from "../../../actionTypes/servicesSelectionActionTypes";
import generalActionTypes from "../../../actionTypes/ui/web/generalActionTypes";

const checkInOutReducer = (state = {}, action) => {
  switch (action.type) {
    // Set .ui.web.checkInOut values
    case checkInOutActionTypes.SET_PAGE_NAME:
      return {
        ...state,
        pageName: action.pageName
      };
    case generalActionTypes.SET_CUSTOMER_ID:
      return {
        ...state,
        currentCustomer: action.customerId
      };
    case generalActionTypes.SET_ITINERARY_ID:
      return {
        ...state,
        currentItinerary: action.itineraryId
      };
    case servicesSelectionActionTypes.SET_SELECTED_SERVICE:
      return {
        ...state,
        selectedService: action.petServiceId
      };

    // Show Hide flyout
    case flyOutActionTypes.OPEN_FLYOUT:
      return {
        ...state,
        selectionDOMRect: action.selectionDOMRect,
        flyoutType: action.flyoutType
      };
    case flyOutActionTypes.CLOSE_FLYOUT:
      return {
        ...state,
        flyoutType: action.flyoutType
      };
    case checkInOutActionTypes.SET_PET_SERVICE_ITEM:
      return {
        ...state,
        currentPetServiceItem: action.petServiceItem
      };
    case checkInOutActionTypes.SET_ITINERARY:
      return {
        ...state,
        currentItinerary: action.itinerary
      };

    case checkInOutActionTypes.SET_CANCEL_APPOINTMENT_REASON:
      return {
        ...state,
        cancelAppointmentReason: action.reason
      };

    case checkInOutActionTypes.CLEAR_CANCEL_APPOINTMENT_REASON:
      return {
        ...state,
        cancelAppointmentReason: ""
      };

    case checkInOutActionTypes.SET_SPECIAL_TO_REMOVE:
      return {
        ...state,
        specialToRemove: action.specialToRemove
      };

    case checkInOutActionTypes.CLEAR_SPECIAL_TO_REMOVE:
      return {
        ...state,
        specialToRemove: ""
      };

    default:
      return state;
  }
};

export default checkInOutReducer;
