import { connect } from "react-redux";

// Components
import SmallPillButton from "@/dux/_components/buttons/SmallPillButton";

// Selectors
import { getHotelArrivalsFilterBy } from "./hotelArrivalsPetServiceSelector";

// Actions
import {
  filterByAllArrivals,
  filterByAttentionNeededArrivals,
} from "./hotelArrivalsFilterButtonActions";

/**
 * Redux Connect function for the Hotel Attention Needed filter pill
 * @summary located on /dashboard
 * @memberOf Views.Dashboard
 * @function
 * @name HotelArrivalsAttentionNeededFilterBtn
 * @returns {JSX.Element|null}
 * @example <HotelArrivalsAttentionNeededFilterBtn />
 */
export default connect(
  state => {
    const filterBy = getHotelArrivalsFilterBy(state);

    return {
      isHidden: false,
      componentID: "HotelAttentionNeededFilterButton",
      label: "Attention Needed",
      selected: filterBy.attentionNeeded,
      disabled: false,
      filterBtnStatus: filterBy.attentionNeeded,
      isAllOthersSelected:
        filterBy.dayCamp && filterBy.dayCare && filterBy.overnight && filterBy.unassignedPet,
    };
  },
  dispatch => ({
    handleFilterByAttentionNeeded: ({ filterBtnStatus, isAllOthersSelected }) => {
      if (filterBtnStatus) {
        // toggle to off
        dispatch(filterByAttentionNeededArrivals({ filterBtnStatus: !filterBtnStatus }));

        // set all button to false;
        dispatch(filterByAllArrivals({ filterBtnStatus: false }));
      } else {
        // toggle to on
        dispatch(filterByAttentionNeededArrivals({ filterBtnStatus: !filterBtnStatus }));

        if (isAllOthersSelected) {
          // set all button to true;
          dispatch(filterByAllArrivals({ filterBtnStatus: true }));
        }
      }
    },
  }),
  (propsFromState, { handleFilterByAttentionNeeded }, ownProps) => ({
    ...propsFromState,
    ...ownProps,

    handleClick: () =>
      handleFilterByAttentionNeeded({
        filterBtnStatus: propsFromState.filterBtnStatus,
        isAllOthersSelected: propsFromState.isAllOthersSelected,
      }),
  }),
)(SmallPillButton);
