import React from "react";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

export default function withInBookingOrModify(WrappedComponent) {
  return withRouteProps(props => {
    const path = props?.router?.location?.pathname;
    const inBookingOrModify = path.includes("/booking/") || path.includes("/modify-appointment/");
    return <WrappedComponent inBookingOrModify={inBookingOrModify} {...props} />;
  });
}
