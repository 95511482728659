import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Actions
import { updateCustomerTimeZone } from "@/dux/timeZoneCustomer/timeZoneCustomerActions";

// Selectors
import { getCustomersTimeZone } from "@/dux/timeZoneCustomer/timeZoneCustomerSelectors";

// Components
import { color } from "web/common/styles/theme";
import { Form } from "@prism/psm-ui-components";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { PetParentCartSummary } from "@/dux/petParentCartSummary/PetParentCartSummary";

// Helpers
import { getTimeZones } from "@/dux/timeZoneCustomer/timeZoneCustomerHelpers";

// Constants
import { timeZoneIdentifiers } from "@/dux/timeZoneCustomer/timeZoneCustomerConstants";

/**
 * React view component to Show customers header, Customer's Name, phone and select Timezone
 * @summary Schedule Training page
 * @memberOf Views.Customer
 * @function
 * @name TimeZoneCustomer
 * @param {Object} props - props passed into the view component
 * @param {string} props.componentId - component id
 * @param {string} props.customersTimeZone - customers time zone
 * @param {Function} props.dispatchOnChange - update time zone
 * @param {boolean} props.isHidden - is hidden to show or hide component
 * @returns {JSX.Element}
 * @example <TimeZoneCustomer />
 */
export const TimeZoneCustomer = props => {
  const { componentId, customersTimeZone, dispatchOnChange, isHidden } = props;

  const [timeZone, setTimeZone] = useState("");

  // Component on mount
  useEffect(() => {
    setTimeZone(customersTimeZone);
  }, []);

  // Component did update
  useEffect(() => {
    setTimeZone(customersTimeZone);
  }, [customersTimeZone]);

  // Handlers
  const handleOnChange = value => {
    setTimeZone(value);
    dispatchOnChange({ timeZone: value });
  };

  if (isHidden) return null;

  return (
    <LayoutBox
      id={componentId}
      className="psm-debug-layout--None"
      style={{ borderBottom: `1px solid ${color.borderGrey}` }}
    >
      <LayoutStack>
        <PetParentCartSummary padding="scale-0" />
        <Form.Field.Select
          value={timeZone}
          onChange={({ value }) => handleOnChange(value)}
          legacy={true}
          options={getTimeZones()}
        />
      </LayoutStack>
    </LayoutBox>
  );
};

/*  Use Case Container to handle the View Component to Show customers header, Customer's Name, phone
    and select Timezone on the Schedule Training page for Pet Training.
  ------------------------------------------------------------------------------------------------------------------ */
export const TimeZoneCustomerVPTContainer = connect(
  state => {
    return {
      componentId: "TimeZoneCustomerVPTContainer",
      customersTimeZone: getCustomersTimeZone(state),
    };
  },
  dispatch => {
    return {
      dispatchOnChange: ({ timeZone }) => {
        dispatch(updateCustomerTimeZone({ timeZone: timeZoneIdentifiers[timeZone] }));
      },
    };
  },
)(TimeZoneCustomer);
