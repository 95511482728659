import { connect } from "react-redux";
import { fontSizes } from "web/common/styles/responsive/fonts";
import Checkbox from "../../../web/common/Checkbox";
import {
  getMedicationSaveToProfileById,
  getIsMedicationOnPetById,
  getMedicationSaveToProfileAmount,
} from "./medicationSaveToProfileSelectors";
import { setMedicationSaveToProfile } from "./medicationSaveToProfileActions";
import { MEDICATION_MAX_COUNT } from "../../../web/newMedication/newMedicationConstants";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, petId } = ownProps;
  const checked = getMedicationSaveToProfileById(state, { medicationId, petId });
  return {
    medicationId,
    petId,
    checked,
    componentId: "medicationDetails-saveToProfile",
    label: "Save to profile",
    labelPosition: "right",
    paddingTop: "10px",
    fontSize: fontSizes.regular,
    disabled: getIsMedicationOnPetById(state, { medicationId, petId })
      ? false
      : getMedicationSaveToProfileAmount(state, { petId, medicationId }) >= MEDICATION_MAX_COUNT &&
        !checked,
  };
};

const mapDispatchToProps = dispatch => ({
  setSaveToProfile: (saveToProfile, medicationId, petId) =>
    dispatch(setMedicationSaveToProfile({ saveToProfile, medicationId, petId })),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onChange: () => {
    const { medicationId, checked, petId } = propsFromState;
    propsFromDispatch.setSaveToProfile(!checked, medicationId, petId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Checkbox);
