import { connect } from "react-redux";
import React from "react";
import { Formik } from "formik";
import Form from "web/common/Form";
import FormInput from "web/common/FormInput";
import withSaveOnClickOutside from "web/common/hoc/withSaveOnClickOutside";
import { getPetEngagementNoteFormDataWithHOPNotes } from "core/selectors/entities/engagementNotesEntitesSelector";
import { updateAppointmentNote } from "core/actionCreators/notesActionCreator";
import { setAppointmentNote } from "core/actionCreators/ui/web/cartActionCreators";
import { LayoutBox } from "@/layout/box/Box";

/**
 * React view component that is used in any "cart" flow for salon or Training
 * @summary Used in CartDetailsComponent
 * @memberOf Views.Customer
 * @function
 * @name AppointmentNotesComponent
 * @param {Object} props
 * @param {String} props.componentId
 * @param {String} props.label
 * @param {RefObject} props.clickOutsideRef
 * @param {RefObject} props.formikRef
 * @param {Object} props.fieldsToInclude
 * @param {Object} props.petEngagementNoteFormData
 * @param {Function} props.onSubmit
 * @param {Function} props.handleFocus
 * @return {JSX.Element}
 * @returns {JSX.Element || null}
 */
export const AppointmentNotesComponent = props => {
  // component ID
  const { componentId } = props;

  // Properties
  const { petEngagementNoteFormData, fieldsToInclude, formikRef, clickOutsideRef, label } = props;

  // Handler Fn
  const { onSubmit, handleFocus } = props;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <Formik
        initialValues={petEngagementNoteFormData}
        onSubmit={values => onSubmit(values)}
        ref={formikRef}
        enableReinitialize
        render={() => {
          return (
            <Form
              columns={1}
              innerRef={clickOutsideRef}
              onSubmit={onSubmit}
              onFieldFocus={handleFocus}
              fieldsToInclude={fieldsToInclude}
            >
              <FormInput
                id={componentId}
                modifier="appointmentNotes"
                isNote
                label={label}
                name="appointmentNotes"
                type="textarea"
              />
            </Form>
          );
        }}
      />
    </LayoutBox>
  );
};

// REDUX CONTAINERS ----------------------------------------------------------------------------------------
export const SalonAppointmentNotes = connect(
  (state, ownProps) => {
    return {
      componentId: "SalonAppointmentNotes",
      petEngagementNoteFormData: getPetEngagementNoteFormDataWithHOPNotes(state, ownProps),
    };
  },

  (dispatch, ownProps) => {
    return {
      onSubmit: formValues => {
        const { itineraryId, petId, customerId, petServiceItemId, isHOPNote } = ownProps;
        const { appointmentNotes } = formValues;

        if (itineraryId && !isHOPNote) {
          dispatch(
            updateAppointmentNote({
              data: { note: appointmentNotes },
              customerId,
              itineraryId,
              petServiceItemId,
            }),
          );
        } else if (appointmentNotes) {
          dispatch(
            setAppointmentNote({
              petId,
              appointmentNote: appointmentNotes,
            }),
          );
        }
      },
    };
  },
)(withSaveOnClickOutside(AppointmentNotesComponent));
