export default {
  LOAD_SALON_INFO_WITH_HOURS: "LOAD_SALON_INFO_WITH_HOURS",
  LOAD_SALON_INFO_WITH_HOURS_REQUEST: "LOAD_SALON_INFO_WITH_HOURS_REQUEST",
  LOAD_SALON_INFO_WITH_HOURS_SUCCESS: "LOAD_SALON_INFO_WITH_HOURS_SUCCESS",
  LOAD_SALON_INFO_WITH_HOURS_FAILURE: "LOAD_SALON_INFO_WITH_HOURS_FAILURE",

  LOAD_SALON_INFO_WITH_SERVICES: "LOAD_SALON_INFO_WITH_SERVICES",
  LOAD_SALON_INFO_WITH_SERVICES_REQUEST: "LOAD_SALON_INFO_WITH_SERVICES_REQUEST",
  LOAD_SALON_INFO_WITH_SERVICES_SUCCESS: "LOAD_SALON_INFO_WITH_SERVICES_SUCCESS",
  LOAD_SALON_INFO_WITH_SERVICES_FAILURE: "LOAD_SALON_INFO_WITH_SERVICES_FAILURE",

  LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH:
    "LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH",
  LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH_REQUEST:
    "LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH_REQUEST",
  LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH_SUCCESS:
    "LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH_SUCCESS",
  LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH_FAILURE:
    "LOAD_SALON_INFO_WITH_SERVICES_FOR_SRC_STORE_SEARCH_FAILURE",

  LOAD_ENGAGEMENT_SALON: "LOAD_ENGAGEMENT_SALON",
  LOAD_SALONS_INFO_WITH_HOURS_SUCCESS: "LOAD_SALONS_INFO_WITH_HOURS_SUCCESS",
};
