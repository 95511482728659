import { connect } from "react-redux";
import AppHeader from "./AppHeader";
import { headerConfigConstants } from "../../core/constants/headerConfigConstants";

// TODO: move to AppHeader.js so all containers are co-located with the component.
const mapStateToProps = (state, ownProps) => ({
  title: "Associate Scheduling",
  config: headerConfigConstants.APPOINTMENT,
  backgroundImage: ownProps.backgroundImage
});

export default connect(mapStateToProps)(AppHeader);
