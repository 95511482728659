import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { fetchAddOns } from "../../../../core/services/systemServicesBooking/addOnEndPoints";
import { getStoreNumber } from "../../../../core/selectors/persistentSelectors";
import { getSelectedPetDetails } from "../../../../core/selectors/bookingUISelectors";
import {
  getSelectedProductBundleConfigId,
  hasRequestedAddOnsByBundleConfigId,
} from "../bulkPackageOfferingsSelectors";
import {
  GET_BULK_PACKAGE_ADDONS,
  getBulkPackageAddOnsRequest,
  getBulkPackageAddOnsSuccess,
  getBulkPackageAddOnsFailure,
  setSelectedBulkPackageAddOns,
} from "./bulkPackageAddOnsActions";

function* onGetBulkPackageAddOns({ petServiceId }) {
  const hasRequested = yield select(hasRequestedAddOnsByBundleConfigId);
  if (hasRequested) return;

  // do not request data when there is no bundle selected
  const productBundleConfigId = yield select(getSelectedProductBundleConfigId);
  if (!productBundleConfigId) return;

  try {
    yield put(getBulkPackageAddOnsRequest());
    const storeNumber = yield select(getStoreNumber);
    const pet = yield select(getSelectedPetDetails);

    const response = yield call(fetchAddOns, {
      storeNumber,
      petServiceId,
      productBundleConfigId,
    });

    const addOns = response?.data?.result

    yield put(setSelectedBulkPackageAddOns({ petId: pet.petId, productBundleConfigId, addOns }));
    yield put(getBulkPackageAddOnsSuccess());
  } catch (error) {
    yield put(getBulkPackageAddOnsFailure({ error }));
  }
}

function* watchLoadBulkPackageAddOns() {
  yield takeLatest([GET_BULK_PACKAGE_ADDONS], onGetBulkPackageAddOns);
}

export default function* bulkPackageAddOnsSaga() {
  yield all([watchLoadBulkPackageAddOns()]);
}
