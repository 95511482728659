import React from "react";
import { connect } from "react-redux";
import CheckinMultiModalContent from "dux/modals/checkIn/multi/checkInMultiModalContainerr";
import ConfirmationModalContentCopy from "dux/_components/modals/checkOut/voidTransaction/confirmation/ConfirmationModalContentCopy";
import { hideCheckInOutModal } from "core/actionCreators/checkInOutActionCreator";
import { clearRebookErrorMessages } from "core/actionCreators/ui/web/commonActionCreators";
import {
  getCheckInOutModalProps,
  getCheckInOutModalType,
} from "core/selectors/modals/checkInOutModalSelectors";
import {
  HotelAppointmentHistoryTable,
  SalonAppointmentHistoryTable,
} from "dux/appointmentHistory/appointmentHistoryComponent";
import CommonModal from "../../common/modals/commonModal";
import CheckOutModalContent from "../../../dux/modals/checkOut/single/CheckOutModalContentContainerr";
import CheckOutMuliContent from "../../../dux/modals/checkOut/multi/checkOutMultiModalContentContainer";
import CheckInModalContent from "../../../dux/modals/checkIn/single/CheckInModalContentContainer";

import VoidTransactionModalContentContainer from "../../../dux/modals/checkOut/voidTransaction/VoidTransactionModalContentContainer";
import CompleteModalContentContainer from "../../../dux/modals/checkOut/voidTransaction/complete/CompleteModalContentContainer";
import { modalTypes as checkInOutModalTypes } from "../../../core/constants/checkInOutConstants";
import { modalTypes as commonModalTypes } from "../../../core/constants/modalConstants";

import RebookError from "../../common/modals/content/rebookError/rebookErrorContainer";
import RemoveSpecial from "../../common/modals/content/removeSpecial/removeSpecialContainer";
import AlertsModal from "../../common/modals/content/eligibilityModals/AlertsModalContainer";
import { SalonVoidTransactionReasonsList } from "dux/voidTransactionReasons/VoidTransactionReasonsList";
import {
  HotelPriceOverrideModal,
  CheckInOutFeedingPriceOverrideModal,
  CheckInOutAddonPriceOverrideModal,
} from "@/dux/hotelPriceOverride/hotelPriceOverrideModal";
import { setCurrentAddonGroupingId } from "@/dux/hotelPriceOverride/hotelPriceOverrideActions";
import {
  clearPendingFrequency,
  frequencyActionTypes,
} from "@/dux/frequency/actions/frequencyActions";
import SetFrequencyModalContainer from "dux/frequency/modal/SetFrequencyModalContainer";
import { ChangePrimaryServiceModalContainer } from "@/dux/changePrimaryServiceModal/ChangePrimaryServiceModal";
import {
  HotelCheckOutAlerts,
  HotelCheckOutAllPetsAlerts,
} from "@/dux/checkOutAlertsModal/checkOutAlertsModal";
import { HotelCheckOutComplete } from "@/dux/checkOutCompleteModal/checkOutCompleteModal";
import { HotelVoidTransaction } from "@/dux/voidTransaction/VoidTransaction";
import { HotelCheckInOutPreExistingReservationModal } from "@/dux/bookingPreExistingReservationModal/bookingPreExistingReservationModal";
import { HotelCheckInFoodsMedsNotAvailable } from "@/dux/rebookingItemsNotAvailable/RebookingItemsNotAvailable";

function selectModalContent(props) {
  switch (props.contentType) {
    case checkInOutModalTypes.CHECK_OUT:
      return <CheckOutModalContent {...props} />;
    case checkInOutModalTypes.CHECK_OUT_MULTI:
      return <CheckOutMuliContent {...props} />;
    case checkInOutModalTypes.CHECK_IN:
      return <CheckInModalContent {...props} />;
    case checkInOutModalTypes.CHECK_IN_MULTI:
      return <CheckinMultiModalContent {...props} />;
    case checkInOutModalTypes.VOID_CONFIRMATION:
      return (
        <VoidTransactionModalContentContainer>
          <ConfirmationModalContentCopy />
        </VoidTransactionModalContentContainer>
      );
    case checkInOutModalTypes.VOID_REASON:
      return (
        <VoidTransactionModalContentContainer>
          <SalonVoidTransactionReasonsList />
        </VoidTransactionModalContentContainer>
      );
    case checkInOutModalTypes.VOID_COMPLETE:
      return <CompleteModalContentContainer />;

    case commonModalTypes.REBOOK_ERROR:
      return <RebookError />;

    case commonModalTypes.SALON_APPOINTMENT_HISTORY:
      return <SalonAppointmentHistoryTable />;

    case commonModalTypes.HOTEL_APPOINTMENT_HISTORY:
      return <HotelAppointmentHistoryTable />;

    case checkInOutModalTypes.CANCEL_APPOINTMENT:
      return <CancelAppointment />;

    case commonModalTypes.REMOVE_SPECIAL:
      return <RemoveSpecial />;

    case commonModalTypes.ALERTS:
      return <AlertsModal {...props} />;
    case commonModalTypes.ELIGIBILITY_WARNINGS:
      return <AlertsModal {...props} isWarnings />;

    case checkInOutModalTypes.PRIMARY_SERVICE_PRICE_OVERRIDE:
      return <HotelPriceOverrideModal {...props} />;

    case checkInOutModalTypes.HOTEL_FEEDING_PRICE_OVERRIDE:
      return <CheckInOutFeedingPriceOverrideModal {...props} />;

    case checkInOutModalTypes.HOTEL_ADDON_PRICE_OVERRIDE:
      return <CheckInOutAddonPriceOverrideModal {...props} />;
    case checkInOutModalTypes.HOTEL_PRIMARY_SERVICE_CHANGE:
      return <ChangePrimaryServiceModalContainer {...props} />;

    case frequencyActionTypes.SHOW_FREQUENCY_MODAL:
      return <SetFrequencyModalContainer {...props} />;

    case checkInOutModalTypes.SHOW_HOTEL_CHECK_OUT_ALERTS:
      return <HotelCheckOutAlerts {...props} />;

    case checkInOutModalTypes.SHOW_HOTEL_CHECK_OUT_ALERTS_ALL_PETS:
      return <HotelCheckOutAllPetsAlerts {...props} />;

    case checkInOutModalTypes.HOTEL_CHECK_OUT_COMPLETE:
      return <HotelCheckOutComplete {...props} />;

    case checkInOutModalTypes.HOTEL_VOID_TRANSACTION:
      return <HotelVoidTransaction {...props} />;

    case checkInOutModalTypes.PRE_EXISTING_RESERVATION_MODAL:
      return <HotelCheckInOutPreExistingReservationModal {...props} />;

    case checkInOutModalTypes.FOOD_MED_NOT_AVAILABLE:
      return <HotelCheckInFoodsMedsNotAvailable {...props} />;

    default:
      return null;
  }
}

export const CheckInOutModals = props => {
  const { componentId, isHidden } = props;

  if (isHidden) {
    return null;
  }

  return (
    <CommonModal
      id={componentId}
      onClose={() => {
        switch (props.contentType) {
          case commonModalTypes.REBOOK_ERROR:
            props.clearRebookErrorMessages();
            break;

          case checkInOutModalTypes.CANCEL_APPOINTMENT:
            break;
          case checkInOutModalTypes.HOTEL_ADDON_PRICE_OVERRIDE:
            if (typeof props?.clearCurrentAddonGroupingId === "function")
              props.clearCurrentAddonGroupingId();
            break;
          case checkInOutModalTypes.SHOW_FREQUENCY_MODAL:
            if (typeof props?.clearFrequency === "function") props.clearFrequency();
            break;
          case checkInOutModalTypes.VOID_CONFIRMATION:
            props.onClose(null);
          default:
        }

        props.onClose(null);
      }}
    >
      {selectModalContent(props)}
    </CommonModal>
  );
};

// SALON CHECK IN OUT MODALS CONTAINER ----------------------------------------------------------------------------------------
export const SalonCheckInOutModals = connect(
  (state, ownProps) => {
    return {
      componentId: "SalonCheckInOutModals",
      contentType: ownProps.contentType, // TODO, get content type from selector instead of own props like Hotel and use for isHidden
    };
  },

  dispatch => {
    return {
      onClose: () => dispatch(hideCheckInOutModal()),
      clearRebookErrorMessages: () => dispatch(clearRebookErrorMessages()),
    };
  },
)(CheckInOutModals);

// HOTEL CHECK IN OUT MODALS CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInOutModals = connect(
  state => {
    const contentType = getCheckInOutModalType(state);
    const modalProps = getCheckInOutModalProps(state);

    return {
      componentId: "HotelCheckInOutModals",
      contentType,
      isHidden: !contentType,
      modalProps,
    };
  },

  dispatch => {
    return {
      onClose: () => dispatch(hideCheckInOutModal()),
      clearRebookErrorMessages: () => dispatch(clearRebookErrorMessages()),
      clearCurrentAddonGroupingId: () => dispatch(setCurrentAddonGroupingId("")),
      clearFrequency: () => dispatch(clearPendingFrequency()),
    };
  },
)(CheckInOutModals);
