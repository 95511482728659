import React from "react";
import styled from "styled-components";
import FlyoutToggleOption from "./FlyoutToggleOption";
import { color } from "../../../web/common/styles/theme";

const edge = `5px solid ${color.kalEl}`;

const RootComponent = styled.section`
  display: flex;
  flex-direction: column;

  border-left: ${edge};
  border-right: ${edge};

  padding: 0.5em 1em;
  min-width: 100px;
  min-height: 115px;
`;

export default function FlyoutToggleOptions(props) {
  const { isHidden, componentID, options, selectionHandler, selectedOption } = props;

  if (!isHidden) {
    return (
      <RootComponent id={componentID}>
        {options.map(option => (
          <FlyoutToggleOption
            key={option}
            option={option}
            selectionHandler={selectionHandler}
            selectedOption={selectedOption}
          />
        ))}
      </RootComponent>
    );
  }

  return null;
}
