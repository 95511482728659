export const SET_TRAINING_ATTENDEES = "SET_TRAINING_ATTENDEES";
export const GET_TRAINING_CLASS_SESSIONS = "GET_TRAINING_CLASS_SESSIONS";
export const GET_TRAINING_CLASS_SESSIONS_REQUEST = "GET_TRAINING_CLASS_SESSIONS_REQUEST";
export const GET_TRAINING_CLASS_SESSIONS_SUCCESS = "GET_TRAINING_CLASS_SESSIONS_SUCCESS";
export const GET_TRAINING_CLASS_SESSIONS_FAILURE = "GET_TRAINING_CLASS_SESSIONS_FAILURE";
export const POST_TRAINING_CLASS_SESSION = "POST_TRAINING_CLASS_SESSION";
export const POST_TRAINING_CLASS_SESSION_REQUEST = "POST_TRAINING_CLASS_SESSION_REQUEST";
export const POST_TRAINING_CLASS_SESSION_SUCCESS = "POST_TRAINING_CLASS_SESSION_SUCCESS";
export const POST_TRAINING_CLASS_SESSION_FAILURE = "POST_TRAINING_CLASS_SESSION_FAILURE";
export const DELETE_TRAINING_CLASS_SESSION = "DELETE_TRAINING_CLASS_SESSION";
export const DELETE_TRAINING_CLASS_SESSION_REQUEST = "DELETE_TRAINING_CLASS_SESSION_REQUEST";
export const DELETE_TRAINING_CLASS_SESSION_SUCCESS = "DELETE_TRAINING_CLASS_SESSION_SUCCESS";
export const DELETE_TRAINING_CLASS_SESSION_FAILURE = "DELETE_TRAINING_CLASS_SESSION_FAILURE";
export const SET_TRAINING_SESSION_APPOINTMENT_DATES = "SET_TRAINING_SESSION_APPOINTMENT_DATES";
export const TOGGLE_TRAINING_CLASS_FORM = "TOGGLE_TRAINING_CLASS_FORM";
export const SET_TRAINING_CLASS_CACHED_MONTH = "SET_TRAINING_CLASS_CACHED_MONTH";
export const RESET_TRAINING_CLASS_SESSIONS = "RESET_TRAINING_CLASS_SESSIONS";

export const setTrainingClassCachedMonth = ({ cachedMonths }) => ({
  type: SET_TRAINING_CLASS_CACHED_MONTH,
  cachedMonths,
});

export const setTrainingSessionAppointmentDates = (payload) => ({
  type: SET_TRAINING_SESSION_APPOINTMENT_DATES,
  payload,
});

export const setTrainingAttendees = (payload) => ({
  type: SET_TRAINING_ATTENDEES,
  payload,
});

export const toggleTrainingClassForm = () => ({
  type: TOGGLE_TRAINING_CLASS_FORM,
});

// GET -----------------------------------------------------------------------------------------------------------------
export const getTrainingClassSessions = ({ storeNumber, cachedMonths, fromDate, toDate }) => ({
  type: GET_TRAINING_CLASS_SESSIONS,
  cachedMonths,
  storeNumber,
  fromDate,
  toDate,
});

export const getTrainingClassSessionsRequest = () => ({
  type: GET_TRAINING_CLASS_SESSIONS_REQUEST,
});

export const getTrainingClassSessionsSuccess = (payload) => ({
  type: GET_TRAINING_CLASS_SESSIONS_SUCCESS,
  payload,
});

export const getTrainingClassSessionsFailure = (error) => ({
  type: GET_TRAINING_CLASS_SESSIONS_FAILURE,
  error,
});

// POST ----------------------------------------------------------------------------------------------------------------
export const postTrainingClassSession = (payload) => ({
  type: POST_TRAINING_CLASS_SESSION,
  ...payload,
});

export const postTrainingClassSessionRequest = () => ({
  type: POST_TRAINING_CLASS_SESSION_REQUEST,
});

export const postTrainingClassSessionSuccess = (payload) => ({
  type: POST_TRAINING_CLASS_SESSION_SUCCESS,
  payload,
});

export const postTrainingClassSessionFailure = (error) => ({
  type: POST_TRAINING_CLASS_SESSION_FAILURE,
  error,
});

//  DELETE -------------------------------------------------------------------------------------------------------------
export const deleteTrainingClassSession = (payload) => ({
  type: DELETE_TRAINING_CLASS_SESSION,
  ...payload,
});

export const deleteTrainingClassSessionRequest = () => ({
  type: DELETE_TRAINING_CLASS_SESSION_REQUEST,
});

export const deleteTrainingClassSessionSuccess = (payload) => ({
  type: DELETE_TRAINING_CLASS_SESSION_SUCCESS,
  payload,
});

export const deleteTrainingClassSessionFailure = (error) => ({
  type: DELETE_TRAINING_CLASS_SESSION_FAILURE,
  error,
});

export const resetTrainingClassSessions = () => ({
  type: RESET_TRAINING_CLASS_SESSIONS,
});
