import { APPOINTMENT_DETAILS_TYPES, APPOINTMENT_STATUS } from "core/constants";
import { pageNames } from "core/constants/checkInOutConstants";

export const getIsAppointmentDetailsTypeCheckedOut = appointmentDetailsType =>
  appointmentDetailsType === APPOINTMENT_DETAILS_TYPES.CHECK_OUT;

export const getIsStatusConfirmed = status => status === APPOINTMENT_STATUS.CONFIRMED;

export const getAppointmentDetailsType = pageName => {
  switch (pageName) {
    case pageNames.CHECK_IN:
      return APPOINTMENT_DETAILS_TYPES.CHECK_IN;

    case pageNames.CHECK_OUT:
      return APPOINTMENT_DETAILS_TYPES.CHECK_OUT;

    case pageNames.FUTURE_APPOINTMENT:
      return APPOINTMENT_DETAILS_TYPES.FUTURE_APPOINTMENT;

    case pageNames.PAST_APPOINTMENT:
      return APPOINTMENT_DETAILS_TYPES.PAST_APPOINTMENT;

    default:
      return APPOINTMENT_DETAILS_TYPES.CHECK_IN;
  }
};
