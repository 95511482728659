import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Gets a calendar template for an associate.
 * For System Services Booking API
 * @param {Number} { associateId }
 * @returns {*} AxiosPromise - Object literal
 */
export function fetchTemplateSchedules({ associateId }) {
  return bookingAPI.get(`/${endpointVersionString()}/associates/${associateId}/calendartemplate`);
}

/**
 * Creates or updates a calendar template for an associate.
 * For System Services Booking API
 * @param {*} { associateId, data }
 * @returns {*} AxiosPromise
 */
export function postTemplateSchedules({ associateId, data }) {
  return bookingAPI.post(
    `/${endpointVersionString()}/associates/${associateId}/calendartemplate`,
    data
  );
}

/**
 * Get the lunch breaks available at the specified store.
 * For System Services Booking API
 * - Get lunch breaks at a store
 * @returns {*} AxiosPromise
 */
export function fetchLunchBreakTimes({ storeNumber }) {
  return bookingAPI.get(`/${endpointVersionString()}/stores/${storeNumber}/lunch-break`);
}
