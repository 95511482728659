import React from "react";
import { connect } from "react-redux";
import momentTz from "moment-timezone";
import { LayoutBox } from "@/layout/box/Box";
import {
  getFirstHotelEngagementByPet,
  getStoreTimeZoneForHotelItinerary,
} from "@/dux/hotelEngagements/hotelEngagementSelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { CALENDAR_FORMAT } from "@/core/constants/dateConstants";
import { LayoutGrid } from "@/layout/grid/Grid";
import { APPOINTMENT_STATUS } from "@/core/constants";

/**
 * React view component for hotel itinerary actual check in date & time
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelActualCheckInDateTimeComponent
 * @param {Object} props - props passed into the view component
 * @param {Boolean} props.isHidden
 * @param {String} props.componentId
 * @param {String} props.date - formatted date string
 * @param {String} props.time - formatted time string
 * @returns {JSX.Element}
 */
export const HotelActualCheckInDateTimeComponent = props => {
  const { isHidden, componentId, date, time } = props;

  if (isHidden) return null;

  return (
    <LayoutGrid
      style={{ gridTemplateColumns: ".5fr 1.5fr 1fr .15fr", width: "100%" }}
      id={componentId}
      space="scale-0"
    >
      <LayoutBox padding="scale-0">Actual Check-in:</LayoutBox>
      <LayoutBox
        padding="scale-0"
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {date}
      </LayoutBox>
      <LayoutBox
        padding="scale-0"
        style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
      >
        {time}
      </LayoutBox>
    </LayoutGrid>
  );
};

/**
 * Redux Connect function for the HotelActualCheckInDateTimeComponent
 * @see {@link Views.Hotel.Itinerary.HotelActualCheckInDateTimeComponent}
 * @summary used on hotel check-in & check-out pages
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelActualCheckInDateTime
 * @returns {JSX.Element|null}
 * @example <HotelActualCheckInDateTime />
 */
export const HotelActualCheckInDateTime = connect(state => {
  const petId = getCurrentPet(state);
  const timeZone = getStoreTimeZoneForHotelItinerary(state);
  const firstEngagement = getFirstHotelEngagementByPet(state, { petId });
  const actualCheckIn = momentTz.tz(firstEngagement?.checkInTime, timeZone);
  const hasBeenCheckedIn =
    firstEngagement?.status === APPOINTMENT_STATUS.CHECKED_IN ||
    firstEngagement?.status === APPOINTMENT_STATUS.CHECKED_OUT;

  return {
    componentId: "HotelActualCheckInDateTime",
    isHidden: !hasBeenCheckedIn,
    date: actualCheckIn?.calendar(null, CALENDAR_FORMAT),
    time: actualCheckIn?.format("LT"),
  };
})(HotelActualCheckInDateTimeComponent);
