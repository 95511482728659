import { store } from "core/store";
import { setAuthenticatedSubscriptionKey } from "core/actionCreators/authActionCreators";

export const saveAuthenticatedSubscriptionKeyToPersistentState = config => {
  const { subscriptionKey = "" } = config.data;

  if (subscriptionKey) {
    store.dispatch(setAuthenticatedSubscriptionKey(subscriptionKey));
  }

  return config;
};

export const appendAuthenticatedSubscriptionKeyToHeaders = config => {
  const { persistent } = store.getState();
  const { authenticatedSubscriptionKey } = persistent;

  // Send the Authenticated Subscription Key (ASK) with each API request
  config.headers["Ocp-Apim-Subscription-Key"] = authenticatedSubscriptionKey;

  return config;
};

export const appendSessionTokenToHeaders = config => {
  const { persistent } = store.getState();
  const { sessionToken } = persistent.session;

  if (sessionToken) {
    const bearerToken = sessionToken ? `Bearer ${sessionToken}` : "";

    config.headers.Authorization = bearerToken;
  }

  return config;
};

export const handleError = error => Promise.reject(error);
