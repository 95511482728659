import React, { useEffect } from "react";
import { connect } from "react-redux";
import { EditableSection, SparkyTextWrapper } from "@petsmart-ui/sparky";
import {
  selectCommonHotelBookingStepData,
  selectHotelBookingPetList,
  selectHotelBookingRooms,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { HotelBookingAddons } from "dux/hotelAddons/HotelAddonsLIst";
import {
  selectCartPetProductsIsMissingDates,
  selectHotelCartAddons,
  selectHotelCartPetAddonsWithoutAutoApply,
} from "dux/servicesCartHotel/servicesCartHotelSelectors";
import { isEmpty } from "lodash/fp";
import { HotelBookingRoomAndPetTabs } from "../hotelBookingRoomAndPetTabs/HotelBookingRoomAndPetTabs";
import { LayoutBox } from "@/layout/box/Box";
import { addonCountLabel, noAddonLabel } from "./hotelBookingAddonSectionConstants";
import { commonHotelBookingStepActions } from "@/web/features/hotelBookingFlow/hotelBookingFlowUtils";
import { showBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { hotelBookingTypes } from "@/web/hotelAlerts/hotelBookingConstants";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { compose } from "redux";
import { selectIsRebookingItinerary } from "../hotelItinerary/hotelItinerarySelectors";
import { selectRebookingNotAvailableAddons } from "../hotelEngagements/hotelEngagementSelectors";
import { getIconType } from "../hotelBookingRoomAndPetTabs/hotelBookingRoomAndPetTabsHelpers";

const HotelBookingAddonsSectionComponent = ({
  isHidden,
  componentID,
  canNavigate,
  isActive,
  onClick,
  getRoomTabIconType,
  getPetTabIconType,
  descriptionText = "",
  showAddonModal = () => {},
}) => {
  if (isHidden) return null;

  useEffect(() => {
    if (isActive) showAddonModal();
  }, [isActive]);

  const description = () => {
    if (isActive || !canNavigate) return;
    return <SparkyTextWrapper truncateOn={1}>{descriptionText}</SparkyTextWrapper>;
  };

  return (
    <EditableSection
      buttonText={canNavigate && "Edit"}
      isActive={isActive}
      onClick={onClick}
      title="Select & Apply Add-ons"
      description={description()}
    >
      <LayoutBox id={componentID}>
        <HotelBookingRoomAndPetTabs
          componentID={componentID}
          getRoomTabIconType={getRoomTabIconType}
          getPetTabIconType={getPetTabIconType}
        >
          {petId => <HotelBookingAddons petId={petId} isActive={isActive} />}
        </HotelBookingRoomAndPetTabs>
      </LayoutBox>
    </EditableSection>
  );
};

export const HotelBookingAddonSection = compose(
  withRouteProps,
  connect(
    (state, { stepIndex, router }) => {
      const rooms = selectHotelBookingRooms(state);
      const { isActive, canNavigate } = selectCommonHotelBookingStepData(stepIndex)(state);
      const addonCount = selectHotelCartAddons(state)?.length;
      const descriptionText = addonCount > 0 ? `${addonCount} ${addonCountLabel}` : noAddonLabel;

      // Rebooking data
      const itineraryId = router?.params?.itineraryId;
      const isRebooking = selectIsRebookingItinerary(state, { itineraryId });

      return {
        componentID: "hotelBookingFlow-addonSection",
        canNavigate,
        isActive,
        pets: selectHotelBookingPetList(state),
        isHidden: false,
        descriptionText,
        getRoomTabIconType: roomId => {
          const showCheckIcon = rooms[roomId]?.every(
            petId => !isEmpty(selectHotelCartPetAddonsWithoutAutoApply(petId)(state)),
          );
          const hasError = rooms[roomId]?.some(petId =>
            selectCartPetProductsIsMissingDates(petId)(state),
          );
          return getIconType(showCheckIcon, hasError);
        },
        getPetTabIconType: petId => {
          const showCheckIcon = !isEmpty(selectHotelCartPetAddonsWithoutAutoApply(petId)(state));
          const hasError = selectCartPetProductsIsMissingDates(petId)(state);
          return getIconType(showCheckIcon, hasError);
        },
        stepIndex,
        notAvailableAddons: isRebooking && selectRebookingNotAvailableAddons(state),
      };
    },
    dispatch => {
      return {
        ...commonHotelBookingStepActions(dispatch),
        showNotAvailableAddonModal: () =>
          dispatch(showBookingModal(hotelBookingTypes.REBOOKING_ADDON_NOT_AVAILABLE)),
      };
    },
    (stateProps, dispatchProps) => {
      const {
        componentID,
        canNavigate,
        isActive,
        pets,
        isHidden,
        stepIndex,
        descriptionText,
        getRoomTabIconType,
        getPetTabIconType,
        notAvailableAddons,
      } = stateProps;
      const { setStep, showNotAvailableAddonModal } = dispatchProps;

      return {
        componentID,
        canNavigate,
        isActive,
        pets,
        isHidden,
        descriptionText,
        getRoomTabIconType,
        getPetTabIconType,
        onClick: () => {
          setStep(stepIndex);
        },
        showAddonModal: () => {
          if (isEmpty(notAvailableAddons)) return;

          showNotAvailableAddonModal();
        },
      };
    },
  ),
)(HotelBookingAddonsSectionComponent);
