import { connect } from "react-redux";
import HotelRoomCapacityModalButtonContainer from "dux/hotelRoomCapacityModal/HotelRoomCapacityModalButtonContainer";
import CapacityHeader from "../_components/roomCapacity/CapacityHeader";

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => ({
  isHidden: false,
  instanceName: "Hotel",
  componentID: "CapacityHeader__hotel",
  title: "PetsHotel Room Capacity",
  hasButton: true,
  diComp: { Hotel: HotelRoomCapacityModalButtonContainer },
});

// const mapDispatchToProps = dispatch => ({
//   keyName: ({}) => dispatch(actionName({})),
// });
//
//
// const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
//   ...propsFromState,
//   ...propsFromDispatch,
//   ...ownProps,
//
//   keyName: ({}) => propsFromDispatch.functionName({
//     keyName: propsFromState.value,
//   }),
//   keyName: ({}) => propsFromDispatch.functionName({
//     keyName: propsFromState.value,
//   }),
// });

export default connect(
  mapStateToProps,
  // mapDispatchToProps,
  // mergeProps,
)(CapacityHeader);
