// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
}

.psm * {
  box-sizing: border-box;
}

div .psm-debug * > *   {
  border-style: dotted;
  border-color: red ;
  border-width: 1px;
}

div .psm-debug-layout * > *   {
  border-style: dotted;
  border-color: #d3d3d3 ;
  border-width: 1px;
}

div .psm-debug-layout--None * > * {
  border: none;
}
`, "",{"version":3,"sources":["webpack://./../psm-ui-components/index.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".psm {\n  -webkit-box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  box-sizing: border-box;\n  font-size: 100%;\n}\n\n.psm * {\n  box-sizing: border-box;\n}\n\ndiv .psm-debug * > *   {\n  border-style: dotted;\n  border-color: red ;\n  border-width: 1px;\n}\n\ndiv .psm-debug-layout * > *   {\n  border-style: dotted;\n  border-color: #d3d3d3 ;\n  border-width: 1px;\n}\n\ndiv .psm-debug-layout--None * > * {\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
