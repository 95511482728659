/* * ----------------------------------------------------------------------- * *\
   Action Types
\* * ----------------------------------------------------------------------- * */

export const GET_ENGAGEMENT_SERVICE_DETAILS = "GET_ENGAGEMENT_SERVICE_DETAILS";
export const GET_ENGAGEMENT_SERVICE_DETAILS_REQUEST = "GET_ENGAGEMENT_SERVICE_DETAILS_REQUEST";
export const GET_ENGAGEMENT_SERVICE_DETAILS_SUCCESS = "GET_ENGAGEMENT_SERVICE_DETAILS_SUCCESS";
export const GET_ENGAGEMENT_SERVICE_DETAILS_FAILURE = "GET_ENGAGEMENT_SERVICE_DETAILS_FAILURE";

/* * ----------------------------------------------------------------------- * *\
   Action Creators
\* * ----------------------------------------------------------------------- * */

export const getEngagementServiceDetails = ({ customerKey, itineraryId, engagementId }) => ({
  type: GET_ENGAGEMENT_SERVICE_DETAILS,
  customerKey,
  itineraryId,
  engagementId,
});

export const getEngagementServiceDetailsRequest = () => ({
  type: GET_ENGAGEMENT_SERVICE_DETAILS_REQUEST,
});

export const getEngagementServiceDetailsSuccess = () => ({
  type: GET_ENGAGEMENT_SERVICE_DETAILS_SUCCESS,
});

export const getEngagementServiceDetailsFailure = ({ error }) => ({
  type: GET_ENGAGEMENT_SERVICE_DETAILS_FAILURE,
  error,
});
