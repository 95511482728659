/** @module stringOrArray */

/**
 * Checks if a value is in an array or if two strings are equal
 * @exports matchStringOrArray
 * @param {(array|string)} stringOrArray - Array of stings or a single sting
 * @param {string} query - A single string
 * @returns {boolean} If the query is included in the array or if the stringOrArray is equal to the query
 */
export default function matchStringOrArray(stringOrArray, query) {
  if (Array.isArray(stringOrArray)) {
    return stringOrArray.includes(query);
  }
  return query === stringOrArray;
}
