import { associateWebAPI } from "core/services/associateWeb/_axiosConfigAssociateWeb";
import { endpointVersionString } from "core/utils/envUtils";
import axios from "axios";
import { cartAPI } from "core/services/cart/_axiosConfigCart";

/**
 * Update status via "Cart" for pre-booking
 * @returns
 */
export function patchPreBookingStatus({ servicesCartId, customerKey }) {
  return cartAPI.patch(
    `${endpointVersionString()}/carts/${servicesCartId}/book?customerKey=${customerKey}`,
  );
}
