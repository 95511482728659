export default {
  CREATE_VET: "CREATE_VET",
  CREATE_VET_REQUEST: "CREATE_VET_REQUEST",
  CREATE_VET_SUCCESS: "CREATE_VET_SUCCESS",
  CREATE_VET_FAILURE: "CREATE_VET_FAILURE",
  DELETE_VET: "DELETE_VET",
  DELETE_VET_REQUEST: "DELETE_VET_REQUEST",
  DELETE_VET_SUCCESS: "DELETE_VET_SUCCESS",
  DELETE_VET_FAILURE: "DELETE_VET_FAILURE",
  LOAD_VET_SEARCH_RESULTS: "LOAD_VET_SEARCH_RESULTS",
  LOAD_VET_SEARCH_RESULTS_REQUEST: "LOAD_VET_SEARCH_RESULTS_REQUEST",
  LOAD_VET_SEARCH_RESULTS_SUCCESS: "LOAD_VET_SEARCH_RESULTS_SUCCESS",
  LOAD_VET_SEARCH_RESULTS_FAILURE: "LOAD_VET_SEARCH_RESULTS_FAILURE",
  SET_CUSTOMER_AND_PET_KEYS: "SET_CUSTOMER_AND_PET_KEYS",
  CLEAR_CUSTOMER_AND_PET_KEYS: "CLEAR_CUSTOMER_AND_PET_KEYS",
  SET_VET_TO_DELETE: "SET_VET_TO_DELETE",
  CLEAR_VET_TO_DELETE: "CLEAR_VET_TO_DELETE",
  SHOW_VET_SEARCH_RESULTS: "SHOW_VET_SEARCH_RESULTS",
  HIDE_VET_SEARCH_RESULTS: "HIDE_VET_SEARCH_RESULTS"
};
