/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const boardingDetailsHotelActionTypes = {
  UPDATE_BOARDING_DETAILS_HOTEL: "UPDATE_BOARDING_DETAILS_HOTEL",
  UPDATE_BOARDING_DETAILS_HOTEL_REQUEST: "UPDATE_BOARDING_DETAILS_HOTEL_REQUEST",
  UPDATE_BOARDING_DETAILS_HOTEL_SUCCESS: "UPDATE_BOARDING_DETAILS_HOTEL_SUCCESS",
  UPDATE_BOARDING_DETAILS_HOTEL_FAILURE: "UPDATE_BOARDING_DETAILS_HOTEL_FAILURE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const updateBoardingDetailsHotel = ({ customerKey, petId, formValues }) => ({
  type: boardingDetailsHotelActionTypes.UPDATE_BOARDING_DETAILS_HOTEL,
  customerKey,
  petId,
  formValues,
});

export const updateBoardingDetailsHotelRequest = () => ({
  type: boardingDetailsHotelActionTypes.UPDATE_BOARDING_DETAILS_HOTEL_REQUEST,
});

export const updateBoardingDetailsHotelSuccess = () => ({
  type: boardingDetailsHotelActionTypes.UPDATE_BOARDING_DETAILS_HOTEL_SUCCESS,
});

export const updateBoardingDetailsHotelFailure = ({ error }) => ({
  type: boardingDetailsHotelActionTypes.UPDATE_BOARDING_DETAILS_HOTEL_FAILURE,
  error,
});

export default {
  updateBoardingDetailsHotel,
  updateBoardingDetailsHotelRequest,
  updateBoardingDetailsHotelSuccess,
  updateBoardingDetailsHotelFailure,
};
