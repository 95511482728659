import { createSelector } from "reselect";
import { getOr } from "lodash/fp";
import { getEngagements } from "@/core/selectors/entitiesSelector";
import { getProps } from "@/core/selectors/commonSelector";
import { getAppointmentByPet } from "@/core/selectors/cartSelectors";
import compareStrings from "@/core/utils/stringUtils/compareStrings";
import { getDoesListHaveEntriesSelectorFactory } from "../common/selectors";

export const getPreCheckInQuestions = createSelector([getAppointmentByPet], appointment => {
  const questions = getOr([], "questions", appointment);

  // We are manually adding the answerNumber in so that we can display
  // an ordered list of health questions answered "yes" in their original order.
  // For example, if the question was listed third, we would want to display that
  // in the format "3. {answer}" in the ordered list.
  return questions.map((question, index) => ({ ...question, answerNumber: index + 1 }));
});

export const getPreCheckInQuestionsAnsweredFactory = TYPE =>
  createSelector([getPreCheckInQuestions], questions =>
    questions.filter(({ answer }) => compareStrings(answer, TYPE)),
  );

export const getAreAllQuestionsSameAnswerFactory = TYPE =>
  createSelector([getPreCheckInQuestions], questions =>
    questions.every(({ answer }) => compareStrings(answer, TYPE)),
  );

export const getPreCheckInQuestionsAnsweredYes = getPreCheckInQuestionsAnsweredFactory("Yes");

export const getPreCheckInQuestionsAnsweredNo = getPreCheckInQuestionsAnsweredFactory("No");

export const getAreAllPreCheckInQuestionsAnsweredNo = getAreAllQuestionsSameAnswerFactory("No");

export const getAreAllPreCheckInQuestionsAnsweredYes = getAreAllQuestionsSameAnswerFactory("Yes");

export const getHasPreCheckInQuestionsAnsweredYes = getDoesListHaveEntriesSelectorFactory(
  getPreCheckInQuestionsAnsweredYes,
);
export const getHasPreCheckInQuestionsAnsweredNo = getDoesListHaveEntriesSelectorFactory(
  getPreCheckInQuestionsAnsweredYes,
);

export const getIsPreCheckedInByEngagementId = createSelector(
  [getEngagements, getProps],
  (engagments, { engagementId }) => {
    return engagments[engagementId]?.completedPreCheckIn;
  },
);
