import { connect } from "react-redux";
import { enableDisableWorkflowFeatureFlags } from "../enableDisableWorkflowFeatureFlagActions";
import getWorkflowById from "../selectors/getWorkflowFeatureFlagById";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import { getBulkPackageOfferings } from "@/dux/bgm/bulkPackageOfferings/bulkPackageOfferingsActions";
import { getAvailableBundlesForAllPetsByCustomer } from "@/dux/bgm/availableBundlesByPet/availableBundlesByPetActions";
import { BGM } from "../workflowFeatureTypes";
import Checkbox from "../../common/Checkbox";

const mapStateToProps = (state, { id }) => {
  const workflow = getWorkflowById(state, { id });
  const workflowId = workflow.id;

  return {
    labelPosition: "left",
    checked: !workflow.isHidden,
    name: workflowId,
    value: workflowId,
    label: workflowId,

    // These values are specific to merge props.
    // We are using these properties to determine if
    // various endpoints should be called
    // when the BGM feature flag is enabled.
    storeNumber: getStoreNumber(state),
    customerKey: getCurrentCustomerKey(state),
    petId: getSelectedPet(state),
    offerType: BGM,
  };
};

const mapDispatchToProps = dispatch => ({
  // This function is specific to merge props.
  // We are using this property to conditionally fire off
  // various endpoints when the BGM
  // feature flag is enabled.
  handleCheckBoxChange: ({
    featureFlagId,
    checked,
    offerType,
    storeNumber,
    customerKey,
    petId,
  }) => {
    dispatch(enableDisableWorkflowFeatureFlags({ featureFlagId, checked }));
    if (checked && storeNumber && customerKey && petId) {
      dispatch(getBulkPackageOfferings({ storeNumber, customerKey, petId, offerType }));
    }
    if (checked && customerKey) {
      dispatch(getAvailableBundlesForAllPetsByCustomer({ customerKey }));
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onChange: () => {
    const { storeNumber, customerKey, petId } = propsFromState;

    propsFromDispatch.handleCheckBoxChange({
      featureFlagId: propsFromState.value,
      checked: !propsFromState.checked,
      offerType: propsFromState.offerType,
      storeNumber,
      customerKey,
      petId,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Checkbox);
