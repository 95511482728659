/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const newFoodActionTypes = {
  SET_NEW_FOOD: "SET_NEW_FOOD",
  CLEAR_NEW_FOOD: "CLEAR_NEW_FOOD",
  SHOW_ADD_NEW_FOOD: "SHOW_ADD_NEW_FOOD",
  HIDE_ADD_NEW_FOOD: "HIDE_ADD_NEW_FOOD",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setNewFood = ({ petId }) => ({
  type: newFoodActionTypes.SET_NEW_FOOD,
  petId,
});

export const clearNewFood = ({ petId }) => ({
  type: newFoodActionTypes.CLEAR_NEW_FOOD,
  petId,
});

export const showAddNewFood = ({ petId }) => ({
  type: newFoodActionTypes.SHOW_ADD_NEW_FOOD,
  petId,
});

export const hideAddNewFood = ({ petId }) => ({
  type: newFoodActionTypes.HIDE_ADD_NEW_FOOD,
  petId,
});
