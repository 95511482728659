/**
 * Formats money based on the given currency code
 * @param {number} money - the money to be formatted
 * @param {string} currencyCode - The currency code of the given money, default is USD
 * @param {boolean} hideDecimals - if true format without decimals $xx, if false format with decimals $xx.xx
 * @returns the money formatted as a string
 */
export const formatMoney = (money, currencyCode = "USD", hideDecimals = false) => {
  const options = {
    style: "currency",
    currency: currencyCode,
  };

  if (hideDecimals) options.maximumFractionDigits = 0;

  return new Intl.NumberFormat("en-US", options).format(money);
};
