import React from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Heading, Tabs } from "@petsmart-ui/sparky";
import findIndex from "lodash/findIndex";
import clamp from "lodash/clamp";

// Components
import { NewExceptionButton } from "@/dux/newException/NewException";
import { OverBookingSetupTable } from "@/dux/overBookingSetupTable/OverBookingSetupTable";
import { OverBookingExceptionDates } from "@/dux/overBookingExceptionDates/OverBookingExceptionDates";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";

// Helpers
import { routePathPartials } from "@/core/constants/routePaths";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

/**
 * Helper function to determine index of current active tab based on page url
 * @function
 * @name getCurrentRouteIndex
 * @param {Object[]} tabs
 * @param {string} currentPath
 * @returns Number
 */
export const getCurrentRouteIndex = (tabs, currentPath) => {
  const index = findIndex(tabs, ({ route }) => currentPath?.includes(route));
  return clamp(index, 0, tabs?.length - 1);
};

/**
 * React view component for the hotel overbooking page
 * @memberOf Views.HotelAdmin
 * @function
 * @name HotelOverBookingDashboard
 * @param {Object} props - props passed into the view component
 * @param {String} props.componentId
 * @param {String} props.title
 * @param {Object[]} props.tabs
 * @param {Object} props.router - from withRoutProps HOC
 * @returns {JSX.Element}
 * @example <HotelOverBookingDashboard />
 */
export const HotelOverBookingDashboard = props => {
  const { componentId, title, tabs, router } = props;

  const activeIndex = getCurrentRouteIndex(tabs, router?.location?.pathname);
  const handleTabChange = (tabIndex = 0) => {
    const newTab = tabs.at(tabIndex) ?? tabs[0];
    router.navigate(newTab.route);
  };

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack space="scale-G1">
        {/* --- HEADER ---*/}
        <LayoutCluster style={{ justifyContent: "space-between", alignItems: "start" }}>
          <LayoutBox padding="scale-0">
            <Heading tagName="h3" size="headline">
              {title}
            </Heading>
          </LayoutBox>

          {tabs[activeIndex]?.headerButton}
        </LayoutCluster>

        {/* --- TABS ---*/}
        <LayoutBox padding="scale-0">
          <Tabs activeIndex={activeIndex} onChange={handleTabChange}>
            {tabs?.map(({ route, label }) => (
              <Tabs.Panel key={route} title={label}></Tabs.Panel>
            ))}
          </Tabs>
        </LayoutBox>

        {/* --- ROUTES ---*/}
        <LayoutBox padding="scale-0">
          <Routes>
            {tabs?.map(({ path, component }) => (
              <Route key={path} path={path} element={component} />
            ))}
          </Routes>
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the hotel overbooking page component
 * @see {@link Views.HotelAdmin.HotelOverBookingDashboard}
 * @summary located on /hotel-management/overbooking page
 * @memberOf Views.HotelAdmin
 * @function
 * @name PetsHotelOverBookingDashboard
 * @returns {JSX.Element|null}
 * @example <PetsHotelOverBookingDashboard />
 */
export const PetsHotelOverBookingDashboard = withRouteProps(
  connect(() => {
    return {
      componentId: "PetsHotelOverBookingDashboard",
      title: "Overbooking",
      tabs: [
        {
          path: `${routePathPartials.HOTEL_OVERBOOKING_SETUP}?/*`,
          route: routePathPartials.HOTEL_OVERBOOKING_SETUP,
          label: "Set Up",
          component: <OverBookingSetupTable />,
        },
        {
          path: `${routePathPartials.HOTEL_OVERBOOKING_EXCEPTION_DATES}/*`,
          route: routePathPartials.HOTEL_OVERBOOKING_EXCEPTION_DATES,
          label: "Exceptions",
          component: <OverBookingExceptionDates />,
          headerButton: <NewExceptionButton />,
        },
      ],
    };
  })(HotelOverBookingDashboard),
);
