import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { persistor } from "@/core/store";

import FeatureFlagPersistentCookieToggleContainer from "dux/featureFlagPersistentCookie/FeatureFlagPersistentCookieToggleContainer";
import LoginSystemTrainingContainer from "web/setSystemType/LoginSystemTrainingContainer";
import { device } from "web/common/styles/responsive/devices";
import { color, font } from "../common/styles/theme";
import Button from "../common/commonButton";
import petBackgroundImg from "../../assets/images/login_background.png";
import EnvironmentWarning from "../common/EnvironmentWarning";
import FeatureFlagIconWrapper from "../common/featureFlagging/FeatureFlagIconWrapper";
import OpenFeatureFlagWindowContainer from "../toggleFeatureFlagWindow/OpenFeatureFlagWindowContainer";
import LoginSystemHotelContainer from "../setSystemType/LoginSystemHotelContainer";
import LoginSystemSalonContainer from "../setSystemType/LoginSystemSalonContainer";
import OldLoginContainer from "../setSystemType/OldLoginContainer";
import EnvironmentNotificationLoginContainer from "../environmentNotificaion/EnvironmentNotificationLoginContainer";
import { Layout, Text, Form } from "@prism/psm-ui-components";
import { stripEmojis } from "core/utils/stringUtils/unicodeUtils";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
  /* padding-bottom: 0.5em; */

  @media ${device.mobileS} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 70%;
  }

  @media ${device.prismDesktop} {
    width: 50%;
  }
`;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeNumber: props.storeNumberValue,
      isAuthenticated: false,
    };
  }

  componentDidMount() {
    persistor.purge();

    const { accessToken } = this.props;
    if (accessToken) {
      this.setState({ isAuthenticated: true });
    }
  }

  handleLogin = () => {
    const { dispatchOnLogin, redirectToForgotPin } = this.props;
    dispatchOnLogin({
      storeNumber: this.state.storeNumber,
      redirectToForgotPin,
    });
  };

  handleKeyPress = e => {
    const { isSVCS371Enabled } = this.props;
    if (e.key === "Enter" && !isSVCS371Enabled) {
      this.handleLogin();
    }
  };

  render() {
    const { logoutUser } = this.props;
    const { storeNumber, isAuthenticated } = this.state;
    return (
      <Layout.Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          backgroundImage: `url(${petBackgroundImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <EnvironmentNotificationLoginContainer />
        <Layout.Center maxWidth="70%">
          <Layout.Stack space="stack-space-12">
            {isAuthenticated ? (
              <>
                <Text as="h3" align="center" color="text-color-white" size="text-size-3xl" bold>
                  Hi there! <br />
                  It looks like another user is already logged in.
                </Text>

                <InputWrapper>
                  <Button label="Start Shift" onClick={logoutUser} />
                </InputWrapper>
              </>
            ) : (
              <>
                <Text as="h3" align="center" color="text-color-white" size="text-size-3xl" bold>
                  Hi there! <br />
                  Today is a great day for pets!
                </Text>

                {/* Store Number, TextFiled and Buttons -------------------------------------------------------------------------*/}
                <Layout.Box>
                  <Layout.Stack space="stack-space-8">
                    <Text align="center" color="text-color-white" size="text-size-lg">
                      Store Number
                    </Text>

                    <Layout.Center>
                      <Form.Field.Text
                        style={{
                          color: "#b6b6b6",
                          width: "100%",
                          height: "38px",
                          fontSize: "1.3rem",
                          padding: "9px 12px 9px 12px",
                          boxSizing: "border-box",
                          outline: "none",
                          border: "none",
                        }}
                        variant="FormFieldVariant"
                        value={storeNumber}
                        onChange={e => this.setState({ storeNumber: e.target.value })}
                        onKeyPress={this.handleKeyPress}
                      />
                    </Layout.Center>
                    <Layout.Switcher space="switcher-space-8">
                      <OldLoginContainer storeNumber={storeNumber} />
                      <LoginSystemSalonContainer storeNumber={storeNumber} />
                      <LoginSystemHotelContainer storeNumber={storeNumber} />
                      <LoginSystemTrainingContainer storeNumber={storeNumber} />
                    </Layout.Switcher>
                  </Layout.Stack>
                </Layout.Box>
              </>
            )}
          </Layout.Stack>
        </Layout.Center>

        <FeatureFlagIconWrapper componentID="login-FeatureFlagIconWrapper">
          <OpenFeatureFlagWindowContainer />
          <FeatureFlagPersistentCookieToggleContainer />
        </FeatureFlagIconWrapper>
      </Layout.Box>
    );
  }
}

export default Login;
