import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import { hasManagerPrivileges } from "@/core/selectors/persistentSelectors";
import { connect } from "react-redux";
import { buildPetFavoribilityOptionsList } from "core/utils/petParentUtils";
import PetFavorabilityComponent from "../_components/favorability/PetFavorabilityComponent";
import { getPetFavorabilityFormData, isDisabledForNonManagement } from "./petFavorabilitySelectors";

const mapStateToProps = (state) => {
  const petId = getSelectedPet(state);
  const isManager = hasManagerPrivileges(state);
  const petFavorabilityFormData = getPetFavorabilityFormData(state, { petId });
  const isDisabledForNonManager = isDisabledForNonManagement({ petFavorabilityFormData, isManager });
  const options = buildPetFavoribilityOptionsList(!isManager && !isDisabledForNonManager);

  return {
    componentID: "petFavorably-Training",
    title: "pet training favorability",
    label: "Training rating",
    isHidden: true, // getSystemBookingFlow(state) !== systemName.TRAINING,,
    isManager,
    isDisabledForNonManagement: isDisabledForNonManager,
    options,
  };
};

export default connect(mapStateToProps)(PetFavorabilityComponent);
