import { omit } from "dux/utils/arrayUtils/omit";

/**
 * Values that represent the Status of an Itinerary or status of an Engagement on an Itinerary.
 *
 * @global
 * @type {{CONFIRMED: string, BOOKED: string, SERVICE_COMPLETED: string, CANCELED: string, CHECKED_OUT: string, PENDING: string, OPEN: string, CHECKED_IN: string}}
 * @example
 * {
 *   OPEN: "Open",
 *   PENDING: "Pending",
 *   BOOKED: "Booked",
 *   CHECKED_IN: "Checked-In",
 *   CONFIRMED: "Confirmed",
 *   SERVICE_COMPLETED: "Service Completed",
 *   CHECKED_OUT: "Checked-Out",
 *   CANCELED: "Canceled",
 * }
 *
 * @todo this Object is the same as APPOINTMENT_STATUS, we can consolidate this object into that one
 * @see APPOINTMENT_STATUS
 */
export const itinerariesStatusList = {
  OPEN: "Open",
  PENDING: "Pending",
  BOOKED: "Booked",
  CHECKED_IN: "Checked-In",
  CONFIRMED: "Confirmed",
  SERVICE_COMPLETED: "Service Completed",
  CHECKED_OUT: "Checked-Out",
  CANCELED: "Canceled",
};

/**
 * Values that represent the Status of an Itinerary or status of an Engagement on an Itinerary as an Array.
 *
 * @global
 * @todo replace actual strings with references to itinerariesStatusList values via Object.values, see issue {@link https://github.ssg.petsmart.com/Stores-and-Services-Engineering/petm-services-prism-ui-react/issues/1732}
 * @todo refactor to fn name such as getItinerariesStatusList() and create a test
 * @type {string[]}
 */
export const ITINERARIES_STATUSES = [
  "Open",
  "Pending",
  "Booked",
  "Checked-In",
  "Confirmed",
  "Service Completed",
  "Checked-Out",
  "Canceled",
];

/**
 * Values that represent the Status of an Itinerary or status of an Engagement by store
 *
 * @global
 * @todo refactor to fn name such as getItinerariesStoreStatus() and create a test
 * @type {string[]}
 */
export const ITINERARIES_BY_STORE_STATUSES = omit(
  ["Open", "Pending", "Canceled"],
  ITINERARIES_STATUSES,
);


