import { createSelector } from "reselect";
import { get, getOr } from "lodash/fp";
import {
  selectMultiPetSelectedServices,
  selectPetSelectedService,
} from "web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { getIsSRCAgent } from "@/core/selectors/persistentSelectors";
import { formatMoney } from "@/dux/_utils/moneyUtil";

export const getPendingAppointment = state => state?.pendingAppointment;

export const getAppointmentsFromPendingAppointment = createSelector(
  [getPendingAppointment],
  pendingAppointment => get("appointments", pendingAppointment) || {},
);

export const getPendingAppointmentServicesByPet = ({ petId }) =>
  createSelector([getAppointmentsFromPendingAppointment], appointment =>
    getOr([], ["petServicesAtHotel"], appointment).filter(
      service => service?.petId?.toString() === petId?.toString(),
    ),
  );

export const getPrimaryServicesNameAndPriceByPet = ({ petId }) =>
  createSelector(
    [getPendingAppointmentServicesByPet({ petId }), getIsSRCAgent],
    (services, isSRCAgent) => {
      if (!services.length) return [];

      return services.map(service => {
        const price = formatMoney(service?.price);
        const notAvail = service?.isAvailable ? "" : " (NOT AVAILABLE)";
        const disabled = isSRCAgent && !service?.isAvailable;
        return {
          disabled,
          label: `${service?.associateDisplayName} - ${price}/ per night${notAvail}`,
          value: service?.petServiceId,
          service,
        };
      });
    },
  );

/**
 * Select pet service by Id from pendingAppointment.petServicesAtHotel (results from get pet services call)
 * @memberOf Selectors.pendingAppointment
 * @function
 * @name selectHotelPetServiceById
 * @param {object} obj
 * @param {String} obj.selectedService - petServiceId
 * @param {String} obj.petId
 * @returns {(state: Object) => Object} selector
 * @example selectHotelPetServiceById({ selectedService, petId })(state)
 */
export const selectHotelPetServiceById = ({ selectedService, petId }) =>
  createSelector([getPendingAppointmentServicesByPet({ petId })], services =>
    services?.find(({ petServiceId }) => petServiceId === selectedService),
  );

/**
 * Select petServiceType by service Id from pendingAppointment.petServicesAtHotel (results from get pet services call)
 * @memberOf Selectors.pendingAppointment
 * @function
 * @name selectPetServiceTypeFromAppointmentServicesByPet
 * @param {object} obj
 * @param {String} obj.selectedService - petServiceId
 * @param {String} obj.petId
 * @param {String} obj.defaultServiceType - optional param to specify what serviceType to use if no selected service
 * @returns {(state: Object) => Object} selector
 * @example selectPetServiceTypeFromAppointmentServicesByPet({ selectedService, petId })(state)
 */
export const selectPetServiceTypeFromAppointmentServicesByPet = ({
  selectedService,
  petId,
  defaultServiceType = "",
}) =>
  createSelector(
    [selectHotelPetServiceById({ selectedService, petId })],
    service => service?.petServiceType || defaultServiceType,
  );

// TODO: refactor to not call selector within combiner
export const selectPetServiceTypesFromAppointmentServicesForPets = ({
  state,
  petList,
  defaultServiceType,
}) => {
  const services = selectMultiPetSelectedServices({ state, petList });
  return Object.values(services)?.map(({ petId, selectedService }) => {
    return {
      petId,
      serviceType: selectPetServiceTypeFromAppointmentServicesByPet({
        selectedService,
        petId,
        defaultServiceType,
      })(state),
    };
  });
};

export const getPendingAppointmentPetServiceAtHotelByPet = petId =>
  createSelector(
    [getPendingAppointmentServicesByPet({ petId }), selectPetSelectedService(petId)],
    (appointments, selectedService) =>
      appointments?.find(({ petServiceId }) => petServiceId === selectedService),
  );

export const selectHostPetIdByPetSelectedService = petId =>
  createSelector(
    [getPendingAppointmentPetServiceAtHotelByPet(petId)],
    ({ hostPetId } = {}) => hostPetId ?? petId,
  );
