import React from "react";
import styled from "styled-components";
import { color } from "web/common/styles/theme";
import { getSign } from "@/web/cart/_utils";
import { modalTypes } from "../../core/constants/modalConstants";
import { DetailsRow } from "./CartDetailsComponent";
import CloseButton from "../common/CloseButton";

const SpecialAmount = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  color: ${color.lightGrey};
`;

const SpecialNameGroup = styled.div``;

const Special = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-weight: bold;
  margin-bottom: 5px;
`;

export default function SpecialsComponent({
  isEditable,
  showModal,
  dynamicPrice,
  setSpecialToRemove,
}) {
  const specials = dynamicPrice?.specials;

  if (!specials) {
    return null;
  }

  const handleRemoveSpecial = special => {
    setSpecialToRemove(special);
    showModal(modalTypes.REMOVE_SPECIAL);
  };

  return (
    <Container>
      {specials.map(special => {
        return (
          <DetailsRow key={special.code}>
            <SpecialNameGroup>
              <Special>
                {special.name}
                {isEditable && special.isManual && (
                  <CloseButton onClick={() => handleRemoveSpecial(special.code)} />
                )}
              </Special>
            </SpecialNameGroup>
            <SpecialAmount>
              {getSign(special.type)}
              {Math.abs(special.amount) && Math.abs(special.amount).toFixed(2)}
            </SpecialAmount>
          </DetailsRow>
        );
      })}
    </Container>
  );
}
