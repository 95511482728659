import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import { get, isEmpty } from "lodash/fp";
import customersActionTypes from "../../actionTypes/customersActionTypes";
import customersActionCreators from "../../actionCreators/customersActionCreators";
import { getPhonesByCustomer } from "../../selectors/entitiesSelector";
import { getSourceId } from "../../selectors/persistentSelectors";
import {
  onUpdateCustomerPreferences,
  onCreateCustomerPreferences
} from "./customerPreferencesSaga";
import { getIsTextOptedOut } from "../../utils/customerProfile";
import { channelTypes, communicationTypes } from "../../constants/customerProfileConstants";
import { postPhoneNumber } from "../../services/associateWeb/createPhoneEndPoints";
import { putPhoneNumber } from "../../services/associateWeb/updatePhoneEndPoints";

export function* onCreateCustomerPhone({ customerKey, data }) {
  try {
    yield put(customersActionCreators.createCustomerPhoneRequest());
    const sourceId = yield select(getSourceId);
    const response = yield call(postPhoneNumber, {
      customerKey,
      sourceId,
      data: { ...data, sourceId }
    });
    const phoneToAdd = response.data; // change back to single object
    const customerPhones = yield select(getPhonesByCustomer, { customerKey });
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          phones: customerPhones.concat(phoneToAdd)
        }
      })
    );
    yield put(customersActionCreators.createCustomerPhoneSuccess());
  } catch (error) {
    yield put(customersActionCreators.createCustomerPhoneError({ error }));
  }
}

export function* onUpdateCustomerPhone({ customerKey, phoneId, data }) {
  try {
    yield put(customersActionCreators.updateCustomerPhoneRequest());
    const sourceId = yield select(getSourceId);
    const response = yield call(putPhoneNumber, {
      customerKey,
      sourceId,
      phoneId,
      data: { ...data, sourceId }
    });
    const updatedPhone = response.data;
    const customerPhones = yield select(getPhonesByCustomer, { customerKey });
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          phones: customerPhones.map(phone => {
            if (phone.phoneId === updatedPhone.phoneId) {
              return { ...phone, ...updatedPhone };
            }
            return phone;
          })
        }
      })
    );
    yield put(customersActionCreators.updateCustomerPhoneSuccess());
  } catch (error) {
    yield put(customersActionCreators.updateCustomerPhoneError({ error }));
  }
}

function* onUpdateIsPreferredContact({ customerKey, data }) {
  try {
    yield put(customersActionCreators.updateCustomerPhoneRequest());
    const [sourceId, customerPhones] = yield all([
      yield select(getSourceId),
      yield select(getPhonesByCustomer, { customerKey })
    ]);

    const { phoneId: preferredPhoneId } = data;

    const phonePreferencesToCreate = [];
    const phonePreferencesToUpdate = [];

    customerPhones.forEach(phone => {
      const shouldUpdatePhonePreference = !isEmpty(get("communicationPreferences", phone));
      const phonePreferences = {
        contactId: phone.phoneId,
        channels: [
          {
            channelId: channelTypes.SERVICES,
            isOptedOut: getIsTextOptedOut(phone),
            isPreferred: phone.phoneId === preferredPhoneId
          }
        ]
      };

      if (shouldUpdatePhonePreference) {
        phonePreferencesToUpdate.push({
          communicationTypeId: communicationTypes.TEXT,
          contacts: [phonePreferences]
        });
      } else {
        phonePreferencesToCreate.push({
          communicationTypeId: communicationTypes.TEXT,
          contacts: [phonePreferences]
        });
      }
    });

    yield all([
      phonePreferencesToCreate.length
        ? yield call(onCreateCustomerPreferences, {
            customerKey,
            sourceId,
            data: phonePreferencesToCreate
          })
        : [],
      phonePreferencesToUpdate.length
        ? yield call(onUpdateCustomerPreferences, {
            customerKey,
            sourceId,
            data: phonePreferencesToUpdate
          })
        : []
    ]);
    yield put(customersActionCreators.updateCustomerPhoneSuccess());
  } catch (error) {
    yield put(customersActionCreators.updateCustomerPhoneError({ error }));
  }
}

function* watchCreateCustomerPhone() {
  yield takeEvery(customersActionTypes.CREATE_CUSTOMER_PHONE, onCreateCustomerPhone);
}

function* watchUpdateCustomerPhone() {
  yield takeEvery(customersActionTypes.UPDATE_CUSTOMER_PHONE, onUpdateCustomerPhone);
}

function* watchUpdateIsPreferredContact() {
  yield takeEvery(customersActionTypes.UPDATE_IS_PREFERRED_CONTACT, onUpdateIsPreferredContact);
}

export default function* customerPhoneSaga() {
  yield all([
    fork(watchCreateCustomerPhone),
    fork(watchUpdateCustomerPhone),
    fork(watchUpdateIsPreferredContact)
  ]);
}
