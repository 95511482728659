import React from "react";
import { Formik } from "formik";
import styled from "styled-components";
import Form from "../../../web/common/Form";
import FormInput from "../../../web/common/FormInput";
import { Text, Radio } from "@prism/psm-ui-components";
import { LayoutCluster } from "@/layout/culster/Cluster";
import {
  ADDITIONAL_APPOINTMENT_OPTIONS,
  MAX_CHECK_IN_OPTIONS,
  ADDITIONAL_BOOKING_TYPE_NONE,
} from "../../../core/constants/bookingConstants";

const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const Column = styled.div`
  padding: 15px 15px 0 0;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

class ReduceMaxLimitForm extends React.Component {
  onChangeCheckInFlag = value => {
    const {
      currentAppointment,
      onUpdatePetServiceItem,
      setMaxCheckInLimit,
      setMaxCheckInBool,
      maxLimitFormValues,
      setMaxPerBlockOrCheckReductionReason,
      setAdditionalBookingType,
      additionalBookingType,
    } = this.props;
    const shouldResetReason = !value && !maxLimitFormValues.reduceByBlockFlag;
    setMaxCheckInBool({ maxCheckInBool: value });
    const maxValue = value ? 2 : 1;

    // if this checkbox is selected, then deselect the bookingType radio button.
    const shouldRemoveBookingType = !!value && additionalBookingType;

    if (currentAppointment) {
      onUpdatePetServiceItem({
        petServiceItem: currentAppointment,
        data: {
          maxCheckInCount: maxValue,
          ...(shouldResetReason && { maxPerBlockOrCheckReductionReason: "" }),
          ...(shouldRemoveBookingType && { additionalBookingType: ADDITIONAL_BOOKING_TYPE_NONE }),
        },
      });
    } else {
      setMaxCheckInLimit({ maxCheckInCount: maxValue });
      if (shouldResetReason) {
        setMaxPerBlockOrCheckReductionReason({ maxPerBlockOrCheckReductionReason: "" });
      }
      if (shouldRemoveBookingType) {
        setAdditionalBookingType(null);
      }
    }
  };

  onChangeMaxPerBlockFlag = value => {
    const {
      currentAppointment,
      onUpdatePetServiceItem,
      setMaxPerBlockLimit,
      setMaxPerBlockBool,
      maxLimitFormValues,
      setMaxPerBlockOrCheckReductionReason,
      setAdditionalBookingType,
      additionalBookingType,
    } = this.props;
    const shouldResetReason = !value && !maxLimitFormValues.reduceCheckInFlag;
    setMaxPerBlockBool({ maxPerBlockBool: value });
    const maxValue = value ? 2 : 1;

    // if this checkbox is selected, then deselect the bookingType radio button.
    const shouldRemoveBookingType = !!value && additionalBookingType;

    if (currentAppointment) {
      onUpdatePetServiceItem({
        petServiceItem: currentAppointment,
        data: {
          maxPerBlockCount: maxValue,
          ...(shouldResetReason && { maxPerBlockOrCheckReductionReason: "" }),
          ...(shouldRemoveBookingType && { additionalBookingType: ADDITIONAL_BOOKING_TYPE_NONE }),
        },
      });
    } else {
      setMaxPerBlockLimit({ maxPerBlockCount: maxValue });
      if (shouldResetReason) {
        setMaxPerBlockOrCheckReductionReason({ maxPerBlockOrCheckReductionReason: "" });
      }
      if (shouldRemoveBookingType) {
        setAdditionalBookingType(null);
      }
    }
  };

  onChangeMaxCheckInCount = value => {
    const { currentAppointment, onUpdatePetServiceItem, setMaxCheckInLimit } = this.props;
    if (currentAppointment) {
      onUpdatePetServiceItem({
        petServiceItem: currentAppointment,
        data: { maxCheckInCount: value + 1 },
      });
    } else {
      setMaxCheckInLimit({ maxCheckInCount: value + 1 });
    }
  };

  onChangeMaxPerBlockCount = value => {
    const { currentAppointment, onUpdatePetServiceItem, setMaxPerBlockLimit } = this.props;
    if (currentAppointment) {
      onUpdatePetServiceItem({
        petServiceItem: currentAppointment,
        data: { maxPerBlockCount: value + 1 },
      });
    } else {
      setMaxPerBlockLimit({ maxPerBlockCount: value + 1 });
    }
  };

  onClickAdditionalBookingType = value => {
    const { setAdditionalBookingType, additionalBookingType } = this.props;

    // this allows the user to 'deselect' the radio button
    const newValue = additionalBookingType !== value ? value : ADDITIONAL_BOOKING_TYPE_NONE;
    const isNone = newValue === ADDITIONAL_BOOKING_TYPE_NONE;
    const newCount = isNone ? 1 : 0;
    const shouldResetReason = !isNone;

    // sets the booking type on the state
    setAdditionalBookingType(newValue);

    // sends the booking type to the services
    this.setManualBookingTypes(newValue, newCount, shouldResetReason);
  };

  onChangeManualOverbookFlag = value => {
    const newCount = value ? 0 : 1;
    const shouldResetReason = !!value;

    this.setManualBookingTypes(value, newCount, shouldResetReason);
  };

  setManualBookingTypes = (value, newCount, shouldResetReason) => {
    const {
      currentAppointment,
      onUpdatePetServiceItem,
      setMaxPerBlockLimit,
      setMaxCheckInLimit,
      setMaxCheckInBool,
      setMaxPerBlockBool,
      setMaxPerBlockOrCheckReductionReason,
      setAdditionalBookingType,
      controlOverbookEnabled,
    } = this.props;

    if (currentAppointment) {
      onUpdatePetServiceItem({
        petServiceItem: currentAppointment,
        data: {
          maxPerBlockCount: newCount,
          maxCheckInCount: newCount,
          ...(controlOverbookEnabled && { additionalBookingType: value }),
          ...(shouldResetReason && { maxPerBlockOrCheckReductionReason: "" }),
        },
      });
    } else {
      setMaxCheckInLimit({ maxCheckInCount: newCount });
      setMaxPerBlockLimit({ maxPerBlockCount: newCount });
      setMaxPerBlockBool({ maxPerBlockBool: false });
      setMaxCheckInBool({ maxCheckInBool: false });
      if (shouldResetReason) {
        setMaxPerBlockOrCheckReductionReason({ maxPerBlockOrCheckReductionReason: "" });
      }
    }
  };

  onChangeReductionReason = value => {
    const {
      currentAppointment,
      onUpdatePetServiceItem,
      setMaxPerBlockOrCheckReductionReason,
    } = this.props;
    if (currentAppointment) {
      onUpdatePetServiceItem({
        petServiceItem: currentAppointment,
        data: { maxPerBlockOrCheckReductionReason: value },
      });
    } else {
      setMaxPerBlockOrCheckReductionReason({ maxPerBlockOrCheckReductionReason: value });
    }
  };

  render() {
    const {
      disabled,
      fieldsToInclude,
      maxLimitFormValues,
      onSubmit,
      MAX_PER_BLOCK_OPTIONS,
      reductionReasons,
      controlOverbookEnabled,
      additionalBookingType,
    } = this.props;

    const componentId = "reduceMaxLimit"; // NOTE this should eventually come from the container or parent

    return (
      <Formik
        enableReinitialize
        initialValues={maxLimitFormValues}
        render={() => (
          <StyledForm id={componentId} fieldsToInclude={fieldsToInclude} disabled={disabled}>
            <Row>
              <Column>
                <FormInput
                  id={componentId}
                  modifier="reductionReason"
                  type="select"
                  label={"To reduce limits, please enter a reason:"}
                  name={"reductionReason"}
                  key={"reductionReason"}
                  options={reductionReasons.map(reason => ({
                    value: reason.value,
                    label: reason.name,
                  }))}
                  onChange={this.onChangeReductionReason}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <FormInput
                  id={componentId}
                  modifier="reduceCheckInFlag"
                  label="Reduce max check-in limit"
                  type="checkbox"
                  name="reduceCheckInFlag"
                  onChange={this.onChangeCheckInFlag}
                  disabled={!maxLimitFormValues.reductionReason}
                />
                {maxLimitFormValues.reduceCheckInFlag && (
                  <FormInput
                    id={componentId}
                    modifier="maxCheckInCount"
                    type="select"
                    label={"Reduce by"}
                    name={"maxCheckInCount"}
                    key={"maxCheckInCount"}
                    options={MAX_CHECK_IN_OPTIONS}
                    onChange={this.onChangeMaxCheckInCount}
                  />
                )}
              </Column>

              <Column>
                <FormInput
                  id={componentId}
                  modifier="reduceByBlockFlag"
                  label="Reduce max per block limit"
                  type="checkbox"
                  name="reduceByBlockFlag"
                  onChange={this.onChangeMaxPerBlockFlag}
                  disabled={!maxLimitFormValues.reductionReason}
                />

                {maxLimitFormValues.reduceByBlockFlag && (
                  <FormInput
                    id={componentId}
                    modifier="maxPerBlockCount"
                    type="select"
                    label={"Reduce by"}
                    name={"maxPerBlockCount"}
                    key={"maxPerBlockCount"}
                    options={MAX_PER_BLOCK_OPTIONS}
                    onChange={this.onChangeMaxPerBlockCount}
                  />
                )}
              </Column>
            </Row>
            <Row>
              {controlOverbookEnabled ? (
                <React.Fragment>
                  {ADDITIONAL_APPOINTMENT_OPTIONS.map((item, colIndex) => (
                    <LayoutCluster
                      key={item.id}
                      space="cluster-space-2"
                      justify="between"
                      style={{ paddingRight: 10, paddingTop: "10px" }}
                    >
                      <Radio
                        id={`${componentId}_${item.id}_${colIndex}`}
                        name={"AdditionalBookingTypes"}
                        value={item.value}
                        checked={
                          additionalBookingType
                            ? additionalBookingType === item.type
                            : ADDITIONAL_BOOKING_TYPE_NONE === item.type
                        }
                        style={{ display: "inline-block", marginRight: 5 }}
                        onClick={() => this.onClickAdditionalBookingType(item.type)}
                      />
                      <Text>{item?.label}</Text>
                    </LayoutCluster>
                  ))}
                </React.Fragment>
              ) : (
                <FormInput
                  id={componentId}
                  modifier={"isManualOverbook"}
                  label={"Manual overbook"}
                  type="checkbox"
                  name={"isManualOverbook"}
                  onChange={this.onChangeManualOverbookFlag}
                />
              )}
            </Row>
          </StyledForm>
        )}
      />
    );
  }
}

export default ReduceMaxLimitForm;
