export function normalizeHotelEngagements(itinerariesResponse) {
  const engagements = {};
  const { customerKey } = itinerariesResponse.customer;
  const { itineraryId } = itinerariesResponse;

  itinerariesResponse.pets.forEach(pet => {
    const petId = pet.petKey;

    pet.engagements.forEach(engagement => {
      engagements[engagement.engagementId] = {
        ...engagement,
        petId,
        customerKey,
        itineraryId,
      };
    });
  });

  return engagements;
}
