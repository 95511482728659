import { connect } from "react-redux";
import ClickableText from "../../common/ClickableText";
import { getIsAccessLevelMocked } from "../../../core/selectors/persistentSelectors";
import { resetMockJobRoles } from "../../../core/actionCreators/jobRoleActionCreators";

const mapStateToProps = state => ({
  children: "Reset",
  isVisible: getIsAccessLevelMocked(state)
});

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(resetMockJobRoles())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClickableText);
