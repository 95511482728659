import moment from "moment";

export function formatStartEndTime(start, end) {
  const startDateTime = moment(start);
  const endDateTime = moment(end);
  return `${startDateTime.format("hh:mm")} - ${endDateTime.format("hh:mm A")}`;
}

export function formatShortDayNameMonthDayWithTime(selectedDate) {
  return moment(selectedDate).format("ddd, MMMM D hh:mm A");
}

export function formatDayMonthDateWithStartEndTime(start, end) {
  const startDateTime = moment(start);
  const endDateTime = moment(end);
  return `${startDateTime.format("dddd, MMMM D hh:mm")} - ${endDateTime.format("hh:mm")}`;
}
