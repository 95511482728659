export const SET_HOTEL_CART_DETAILS = "SET_HOTEL_CART_DETAILS";
export const setHotelCartDetails = cartDetails => ({
  type: SET_HOTEL_CART_DETAILS,
  cartDetails,
});

export const SET_HOTEL_CART_PETS = "SET_HOTEL_CART_PETS";
export const setHotelCartPets = pets => ({
  type: SET_HOTEL_CART_PETS,
  pets,
});

export const SET_HOTEL_CART_TIMES = "SET_HOTEL_CART_TIMES";
export const setHotelCartTimes = times => ({
  type: SET_HOTEL_CART_TIMES,
  times,
});

export const SET_HOTEL_CART_PET_NOTES = "SET_HOTEL_CART_PET_NOTES";
export const setHotelCartPetNotes = (petId, notes) => ({
  type: SET_HOTEL_CART_PET_NOTES,
  petId,
  notes,
});

export const SET_HOTEL_CART_PRODUCTS = "SET_HOTEL_CART_PRODUCTS";
export const setHotelCartProducts = products => ({
  type: SET_HOTEL_CART_PRODUCTS,
  products,
});

export const SET_HOTEL_CART_PRODUCT = "SET_HOTEL_CART_PRODUCT";
export const setHotelCartProduct = ({ product, cartItemId }) => ({
  type: SET_HOTEL_CART_PRODUCT,
  product,
  cartItemId,
});

export const REMOVE_HOTEL_CART_PRODUCTS = "REMOVE_HOTEL_CART_PRODUCTS";
export const removeHotelCartProducts = cartItemIds => ({
  type: REMOVE_HOTEL_CART_PRODUCTS,
  cartItemIds,
});

export const SET_HOTEL_CART_PRODUCT_FREQUENCY = "SET_HOTEL_CART_PRODUCT_FREQUENCY";
export const setHotelCartProductFrequency = ({ cartItemId, frequency, customFrequencyDates }) => ({
  type: SET_HOTEL_CART_PRODUCT_FREQUENCY,
  cartItemId,
  frequency,
  customFrequencyDates,
});

export const TOGGLE_HOTEL_CART_PET_NEEDS_REVIEW = "TOGGLE_HOTEL_CART_PET_NEEDS_REVIEW";
export const toggleNeedsReview = petId => ({
  type: TOGGLE_HOTEL_CART_PET_NEEDS_REVIEW,
  petId,
});
