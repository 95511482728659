import { connect } from "react-redux";
import getAddOnMetaData from "dux/_selectors/getAddOnMetaData";
import PriceComponent from "dux/_components/pricing/PriceComponent";
import { isNumber } from "lodash/fp";

function getFinalPrice({ timeSlot, isBundledAddOn, isBundleAvailable, originalPrice, finalPrice }) {
  // If we're in the booking flow and a timeslot has been selected
  if (timeSlot) {
    return finalPrice?.toFixed(2);
  }

  const isDiscountedAddOnAvailable = isBundledAddOn && isBundleAvailable;

  // If there there's an active bundle available for the pet service and
  // there's an add-on associated with that bundle, then display 0.00 since
  // the add-on is already included in the bundle.
  if (isDiscountedAddOnAvailable) {
    return "0.00";
  }

  // If there's is an add-on associated with the bundle, but there's no active bundle
  // available, then we want to display the original price
  if (isBundledAddOn && !isBundleAvailable && isNumber(originalPrice)) {
    return originalPrice.toFixed(2);
  }

  // Return the final price in all other scenarios
  return finalPrice?.toFixed(2);
}

const mapStateToProps = (state, { addOnId, petId }) => {
  const {
    isPriceClickable,
    timeSlot,
    finalPrice,
    isBundledAddOn,
    isBundleAvailable,
    originalPrice,
  } = getAddOnMetaData(state, {
    addOnId,
    petId,
  });
  return {
    isPriceClickable,
    componentID: "AddOnFinalPrice",
    type: "add-on",
    price: getFinalPrice({
      timeSlot,
      isBundledAddOn,
      isBundleAvailable,
      originalPrice,
      finalPrice,
    }),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () => {
    const { isPriceClickable } = propsFromState;
    const { onClick } = ownProps;
    if (isPriceClickable && onClick) {
      onClick();
    }
  },
});

export default connect(mapStateToProps, null, mergeProps)(PriceComponent);