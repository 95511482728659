import { associateWebProfileAPI } from "./_axiosConfigAssociateWebProfile";
import { associateWebAPI } from "../associateWeb/_axiosConfigAssociateWeb";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Adds a new address to an existing customer from the Web Associate Profile API - Adds a new address to an existing customer end point
 * @param {*} { customerKey, sourceId, data }
 * @returns
 */
export function postAddress({ customerKey, data }) {
  return associateWebAPI.post(
    `${endpointVersionString()}/customers/${customerKey}/addresses`,
    data
  );
}

/**
 * Updates a customer's address from the Web Associate Profile API - Updates a customer's address end point
 * @param {*} { customerKey, addressId, sourceId, data }
 * @returns
 */
export function putAddress({ customerKey, addressId, sourceId, data }) {
  return associateWebProfileAPI.put(
    `v2/customers/${customerKey}/addresses/${addressId}?sourceId=${sourceId}`,
    data
  );
}
/**
 * Deletes a customer's address from the Web Associate Profile API - Delete a customer's address end point
 * @param {*} { customerKey, addressId, sourceId }
 * @returns
 */
export function deleteAddress({ customerKey, addressId, sourceId }) {
  return associateWebProfileAPI.delete(
    `v2/customers/${customerKey}/addresses/${addressId}?sourceId=${sourceId}`
  );
}
