import { connect } from "react-redux";
import {
  getTotalPriceForAllPetServiceItems,
  getIsServiceFound,
} from "../../core/selectors/cartSelectors";
import { selectSalonTotalPrice } from "core/selectors/cartPriceSelectors";
import { getPet } from "../../core/selectors/entitiesSelector";
import { getPetHasExpiredVaccination } from "../../core/selectors/ui/vaccinations/vaccinationsSelectors";
import { getIsSRCAgent } from "../../core/selectors/persistentSelectors";
import {
  getBulkPackageOfferingsTotalPrice,
  getIsAnyBulkPackageOfferingSelected,
} from "../../dux/bgm/bulkPackageOfferings/bulkPackageOfferingsSelectors";
import getIsBGMWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getFTCOWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import CartFooterComponent from "./CartFooterComponent";
import { getEnhancedServicesTotalWithDynamicPrice } from "@/core/selectors/dynamicPricesSelector";

const mapStateToProps = (state, ownProps) => {
  const isAnyBulkPackageOfferingSelected = getIsAnyBulkPackageOfferingSelected(state);
  const isBGMWorkflowFeatureFlagHidden = getIsBGMWorkflowFeatureFlagHidden(state);
  const isFTCOWorkflowFeatureFlagHidden = getFTCOWorkflowFeatureFlagHidden(state);
  const enhancedServicesPrice = getEnhancedServicesTotalWithDynamicPrice(state, {
    petId: ownProps.selectedPet,
  });

  let totalPrice = getTotalPriceForAllPetServiceItems(state, ownProps);

  if (ownProps.multiplePets) {
    totalPrice = selectSalonTotalPrice(state);
  }

  if (
    isAnyBulkPackageOfferingSelected &&
    (!isBGMWorkflowFeatureFlagHidden || !isFTCOWorkflowFeatureFlagHidden)
  ) {
    totalPrice = getBulkPackageOfferingsTotalPrice(state);
  }

  return {
    totalPrice,
    pet: getPet(state, ownProps),
    petHasExpiredVaccination: getPetHasExpiredVaccination(state, ownProps),
    isServiceFound: getIsServiceFound(state, { petId: ownProps.selectedPet }),
    isSRCAgent: getIsSRCAgent(state),
  };
};

export default connect(mapStateToProps)(CartFooterComponent);
