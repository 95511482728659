import formatPhoneNumberNANP from "../stringManipulationUtils/formatPhoneNumber";
import verifyPhoneNumber from "../validationUtils/phoneNumberValidation";

const getFormattedCustomerSearchField = ({ searchFieldType, searchFieldValue }) => {
  if (searchFieldType === "phoneNumber" && verifyPhoneNumber(searchFieldValue)) {
    return formatPhoneNumberNANP(searchFieldValue);
  }
  return searchFieldValue;
};

export default getFormattedCustomerSearchField;
