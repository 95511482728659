import React from "react";
import styled from "styled-components";
import { startCase } from "lodash/fp";
import setStyleObjToStyledCompReadyString from "../../core/utils/objectUtils/styleObjToStyledCompReadyString";

const CheckboxContainer = styled.div`
  text-align: right;

  /* Add any additional CSS to this component */
  ${styleObj => (styleObj ? setStyleObjToStyledCompReadyString(styleObj) : "")};
`;

const Label = styled.label`
  font-size: 0.8em;
  margin-right: 0.25em;
`;

export default function CheckboxWithLabel({ checked, label, setHandler, clearHandler, styleObj }) {
  return (
    <CheckboxContainer styleObj={styleObj}>
      <Label for={label}>{startCase(label)}</Label>
      <input
        id={label}
        type="checkbox"
        checked={checked}
        onChange={() => (checked ? clearHandler() : setHandler())}
      />
    </CheckboxContainer>
  );
}
