import moment from "moment";
import { put, takeEvery, call, all, select } from "redux-saga/effects";
import { isEmpty, noop } from "lodash";
import {
  GET_TRAINING_CLASS_AVAILABILITY,
  GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE,
  getTrainingClassAvailabilityRequest,
  getTrainingClassAvailabilitySuccess,
  getTrainingClassAvailabilityFailure,
  getTrainingClassAvailabilityIfActiveBundleIsAvailableRequest,
  getTrainingClassAvailabilityIfActiveBundleIsAvailableSuccess,
  getTrainingClassAvailabilityIfActiveBundleIsAvailableFailure,
} from "./trainingClassAvailabilityActions";
import { fetchTrainingAvailability } from "@/core/services/systemServicesBooking/trainingAvailabilityEndpoints";
import {
  showPurchaseTrainingServiceMessage,
  showTrainingDateSelection,
  selectTrainingService,
} from "dux/scheduleTrainingPage/scheduleTrainingPageActions";
import {
  setCachedTrainingAvailabilityWeek,
  setPreviousBookingTimeZone,
} from "@/dux/scheduleTrainingAppointment/scheduleTrainingAppointmentActions";
import { getAvailableTrainingBundlesForServiceSelection } from "@/dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import { getStoreNumber, getCurrentPet } from "@/core/selectors/persistentSelectors";
import { getSelectedDate } from "@/dux/selectedDate/selectedDateSelector";
import { getAssociatesFilterButtonData } from "dux/trainingAssociates/trainingAssociatesSelectors";
import { setFilteringByTrainer } from "dux/filteringButtons/dux";

function* onGetTrainingClassAvailability({
  petServiceId,
  fromDate,
  toDate,
  storeNumber,
  petId,
  onComplete = noop,
}) {
  try {
    yield put(getTrainingClassAvailabilityRequest());
    const response = yield call(fetchTrainingAvailability, {
      petServiceId,
      fromDate,
      toDate,
      storeNumber,
      petId,
    });
    const payload = response.data.result;
    const { previousBookingTimeZone } = payload;

    yield put(getTrainingClassAvailabilitySuccess(payload));

    // ToDo: Remove hardcoding these properties int he  getTrainingClassAvailability
    // saga, as this saga is shared across
    yield put(setPreviousBookingTimeZone(previousBookingTimeZone));

    // Once all availability is loaded we can construct the data needed (and update global state) to display the
    // training Associate filter buttons to filter out class sessions.
    const trainingClassFilterButtonData = yield select(getAssociatesFilterButtonData);
    yield put(setFilteringByTrainer(trainingClassFilterButtonData));
    onComplete(payload);
  } catch (error) {
    yield put(getTrainingClassAvailabilityFailure(error));
  }
}

// This handler automatically selects the first item in the list of pet training services displayed
// once the GET available bundles for all pets by customer is complete.
function* onGetTrainingClassAvailabilityIfActiveBundleIsAvailable() {
  try {
    yield put(getTrainingClassAvailabilityIfActiveBundleIsAvailableRequest());
    const petId = yield select(getCurrentPet);
    const availableBundles = yield select(getAvailableTrainingBundlesForServiceSelection, {
      petId,
    });

    if (isEmpty(availableBundles)) {
      yield put(showPurchaseTrainingServiceMessage());
      return;
    }

    const activeBundle = availableBundles.find((bundle) => bundle.availableUnits > 0);

    if (activeBundle) {
      const storeNumber = yield select(getStoreNumber);
      const selectedDate = yield select(getSelectedDate);

      const petServiceId = activeBundle.petServiceId;
      const fromDate = moment(selectedDate).startOf("isoWeek").format();
      const toDate = moment(selectedDate).endOf("isoWeek").format();

      yield put(showTrainingDateSelection());
      yield put(selectTrainingService({ petServiceId }));

      yield call(onGetTrainingClassAvailability, {
        petServiceId,
        petId,
        fromDate,
        toDate,
        storeNumber,
      });

      const week = moment(selectedDate).week();
      const year = moment(selectedDate).year();
      const cacheEntry = `${week}-${year}`;

      yield put(setCachedTrainingAvailabilityWeek(cacheEntry));
    }

    yield put(getTrainingClassAvailabilityIfActiveBundleIsAvailableSuccess());
  } catch (error) {
    yield put(getTrainingClassAvailabilityIfActiveBundleIsAvailableFailure(error));
  }
}

function* watchGetTrainingClassAvailability() {
  yield takeEvery(GET_TRAINING_CLASS_AVAILABILITY, onGetTrainingClassAvailability);
}

function* watchGetTrainingClassAvailabilityIfActiveBundleIsAvailable() {
  yield takeEvery(
    GET_TRAINING_CLASS_AVAILABILITY_IF_ACTIVE_BUNDLE_IS_AVAILABLE,
    onGetTrainingClassAvailabilityIfActiveBundleIsAvailable,
  );
}

export default function* trainingClassAvailabilitySaga() {
  yield all([
    watchGetTrainingClassAvailability(),
    watchGetTrainingClassAvailabilityIfActiveBundleIsAvailable(),
  ]);
}
