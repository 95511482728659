//@ts-check
import moment from "moment";

import {
  SET_HOTEL_MANAGE_ROOM_DETAILS_DATE_PICKER_START_DATE,
  SET_HOTEL_MANAGE_ROOM_DETAILS_DATE_PICKER_END_DATE,
} from "./hotelManageRoomDetailsDatePickersActions";

const initialState = {
  startDate: moment().format("YYYY-MM-DD"),
  endDate: moment().add(1, "days").format("YYYY-MM-DD"),
};

/**
 *
 * @param {object} state
 * @param {{type: string, payload: string}} action
 * @returns {object} - State
 */
const hotelManageRoomDetailsDatePickersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTEL_MANAGE_ROOM_DETAILS_DATE_PICKER_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case SET_HOTEL_MANAGE_ROOM_DETAILS_DATE_PICKER_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };
    default:
      return state;
  }
};

export default hotelManageRoomDetailsDatePickersReducer;
