import React from "react";
import { modalTypes } from "../../../../core/constants/petProfileConstants";
import CommonModal from "../../../common/modals/commonModal";
import SearchVetContainer from "./content/vets/searchVet/SearchVetModalContainer";
import AddVetComponent from "./content/vets/addVet/AddVetModalComponent";
import DeleteVet from "./content/vets/deleteVet/DeleteVetModalContainer";
import DeleteVaccination from "./content/vaccinations/deleteVaccination/DeleteVaccinationModalContainer";
import AddPetWarning from "./content/pets/addPet/AddPetWarningComponent";

const PetModals = ({
  modalType,
  onClose,
  hideVetSearchResults,
  clearCustomerAndPetKeys,
  clearVetToDelete
}) => (
  <CommonModal
    onClose={() => {
      const isSearchVetModal = modalType === modalTypes.SEARCH_VET;
      const isAddVetModal = modalType === modalTypes.ADD_VET;
      const isDeleteVetModal = modalType === modalTypes.DELETE_VET;

      if (isSearchVetModal || isAddVetModal) {
        hideVetSearchResults();
      }

      if (isDeleteVetModal) {
        clearCustomerAndPetKeys();
        clearVetToDelete();
      }

      onClose();
    }}
  >
    {selectModalContent({ modalType })}
  </CommonModal>
);

function selectModalContent({ modalType }) {
  switch (modalType) {
    case modalTypes.SEARCH_VET:
      return <SearchVetContainer />;
    case modalTypes.ADD_VET:
      return <AddVetComponent />;
    case modalTypes.DELETE_VET:
      return <DeleteVet />;
    case modalTypes.DELETE_VACCINATION:
      return <DeleteVaccination />;
    case modalTypes.ADD_PET_WARNING:
      return <AddPetWarning />;
    default:
      return null;
  }
}

export default PetModals;
