import { connect } from "react-redux";
import { openFeatureFlagWindow } from "./toggleFeatureFlagWindowActions";
import getFeatureFlagVisibleState from "./selectors/getFeatureFlagVisibleState";
import FlagIcon from "../common/icons/FlagIcon";
import getFeatureFlagIconVisibleState from "../displayFeatureFlagIcon/selectors/getFeatureFlagIconVisibleState";

const mapStateToProps = state => {
  return {
    componentID: "openFeatureFlagWindow-FlagIcon",
    isHidden: getFeatureFlagIconVisibleState(state),

    // Pass through for merge props - not used in FlagIcon Component
    windowVisibleState: getFeatureFlagVisibleState(state)
  };
};

const mapDispatchToProps = dispatch => ({
  handleDispatchOpenFeatureFlagWindow: ({ isHidden }) => {
    dispatch(openFeatureFlagWindow({ isHidden }));
  }
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleClick: () =>
    propsFromDispatch.handleDispatchOpenFeatureFlagWindow({
      isHidden: propsFromState.windowVisibleState
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FlagIcon);
