import { connect } from "react-redux";
import {
  getAgentToDelete,
  getCustomerToDelete
} from "../../../../../core/selectors/agentsSelectors";
import petParentProfileActionCreators from "../../../../../core/actionCreators/petParentProfileActionCreator";
import agentsActionCreators from "../../../../../core/actionCreators/agentsActionCreators";
import agentsActionTypes from "../../../../../core/actionTypes/agentsActionTypes";
import { createLoadingSelector } from "../../../../../core/selectors/utils";
import DeleteAgentModalComponent from "./DeleteAgentModalComponent";

const mapStateToProps = state => ({
  agentToDelete: getAgentToDelete(state),
  customerToDelete: getCustomerToDelete(state),
  isLoading: createLoadingSelector([agentsActionTypes.DELETE_AGENT])(state)
});

const mapDispatchToProps = dispatch => ({
  handleRemove(agentToRemove, customerToRemove) {
    dispatch(
      agentsActionCreators.deleteAgent({
        customerKey: customerToRemove,
        agentId: agentToRemove
      })
    );
  },
  onClose() {
    dispatch(agentsActionCreators.clearCustomerAndAgentKeys());
    dispatch(petParentProfileActionCreators.hidePetParentProfileModal());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAgentModalComponent);
