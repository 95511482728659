import { FormElement } from '../types';
import { isFormElement } from '../utils';

export type FormElementType = 'color' | 'date' | 'email' | 'integer' | 'numeric' | 'range' | 'text' | 'time';

// error messages used for form inputs
// will return a message based on the form input type provided
export function getErrorMessage(element: EventTarget | FormElement, type: FormElementType): string {
  if (!isFormElement(element) || element.validity.valid) {
    return '';
  }
  switch (type) {
    case 'color': {
      return getColorErrorMessage(element);
    }
    case 'date': {
      return getDateErrorMessage(element);
    }
    case 'email': {
      return getEmailErrorMessage(element);
    }
    case 'integer': {
      return getIntegerErrorMessage(element);
    }
    case 'numeric': {
      return getNumericErrorMessage(element);
    }
    case 'range': {
      return getRangeErrorMessage(element);
    }
    case 'text': {
      return getTextErrorMessage(element);
    }
    case 'time': {
      return getTextErrorMessage(element); // need to add validation!
    }
    default: {
      return getDefaultErrorMessage(element);
    }
  }
}

function getDefaultErrorMessage(element: FormElement) {
  const { labels, name, validity } = element;
  const label = labels?.item(0).textContent || name;

  // if name exists, then use that for the error message
  const prefixedMessage = label ? `The value for "${label}"` : `The value provided`;
  if (validity.valueMissing) {
    return `${prefixedMessage} cannot be undefined`;
  }
  return `${prefixedMessage} cannot be undefined`;
}

function getColorErrorMessage(element: FormElement) {
  const { labels, name } = element;
  const label = labels?.item(0).textContent || name;
  // if name exists, then use that for the error message
  const prefixedMessage = label ? `The value for "${label}"` : `The value provided`;
  return `${prefixedMessage} must use a valid color`;
}

function getDateErrorMessage(element: FormElement) {
  const { labels, name, validity } = element;
  const label = labels?.item(0).querySelector('.sym-form-label')?.textContent || name;
  // if name exists, then use that for the error message
  const prefixedMessage = label ? `The value for "${label}"` : `The value provided`;
  if (validity.valueMissing) {
    return `${prefixedMessage} must use a valid date`;
  }
  return `${prefixedMessage} must use a valid date`;
}

function getEmailErrorMessage(element: FormElement) {
  const { labels, name, validity } = element;
  const label = labels?.item(0).textContent || name;
  // if name exists, then use that for the error message
  const prefixedMessage = label ? `The value for "${label}"` : `The value provided`;
  if (validity.typeMismatch) {
    return `${prefixedMessage} is not a valid email`;
  }
  if (validity.valueMissing) {
    return `${prefixedMessage} must use a valid email`;
  }
  return `${prefixedMessage} must use a valid email`;
}

function getIntegerErrorMessage(element: FormElement) {
  const { labels, name, validity } = element;
  const label = labels?.item(0).textContent || name;
  let max: string = '';
  let min: string = '';

  if (element instanceof HTMLInputElement) {
    max = element.max;
    min = element.min;
  }
  // if name exists, then use that for the error message
  const prefixedMessage = label ? `The value for "${label}"` : `The value provided`;

  if (validity.badInput) {
    return `${prefixedMessage} is not a valid number`;
  }
  if (validity.rangeOverflow) {
    return max
      ? `${prefixedMessage} is greater than the maximum value of ${max}`
      : `${prefixedMessage} is greater than the maximum value`;
  }
  if (validity.rangeUnderflow) {
    return min
      ? `${prefixedMessage} is less than the minimum value of ${min}`
      : `${prefixedMessage} is less than the minimum value`;
  }
  if (validity.typeMismatch) {
    return `${prefixedMessage} is not a valid number`;
  }
  if (validity.valueMissing) {
    return `${prefixedMessage} must use a valid number`;
  }
  return `${prefixedMessage} is not a valid number`;
}

function getNumericErrorMessage(element: FormElement) {
  const { labels, name, validity } = element;
  const label = labels?.item(0).textContent || name;
  let max: string = '';
  let min: string = '';

  if (element instanceof HTMLInputElement) {
    max = element.max;
    min = element.min;
  }
  // if name exists, then use that for the error message
  const prefixedMessage = label ? `The value for "${label}"` : `The value provided`;

  if (validity.badInput) {
    return `${prefixedMessage} is not a valid number`;
  }
  if (validity.rangeOverflow) {
    return max
      ? `${prefixedMessage} is greater than the maximum value of ${max}`
      : `${prefixedMessage} is greater than the maximum value`;
  }
  if (validity.rangeUnderflow) {
    return min
      ? `${prefixedMessage} is less than the minimum value of ${min}`
      : `${prefixedMessage} is less than the minimum value`;
  }
  if (validity.typeMismatch) {
    return `${prefixedMessage} is not a valid number`;
  }
  if (validity.valueMissing) {
    return `${prefixedMessage} must use a valid number`;
  }
  return `${prefixedMessage} is not a valid number`;
}

function getRangeErrorMessage(element: FormElement) {
  const { labels, name, validity } = element;
  const label = labels?.item(0).textContent || name;
  let max: string = '';
  let min: string = '';

  if (element instanceof HTMLInputElement) {
    max = element.max;
    min = element.min;
  }
  // if name exists, then use that for the error message
  const prefixedMessage = label ? `The value for "${label}"` : `The value provided`;

  if (validity.rangeOverflow) {
    return max
      ? `${prefixedMessage} is greater than the maximum value of ${max}`
      : `${prefixedMessage} is greater than the maximum value`;
  }
  if (validity.rangeUnderflow) {
    return min
      ? `${prefixedMessage} is less than the minimum value of ${min}`
      : `${prefixedMessage} is less than the minimum value`;
  }
  if (validity.typeMismatch) {
    return `${prefixedMessage} is not a valid number`;
  }
  if (validity.valueMissing) {
    return `${prefixedMessage} must use a valid number`;
  }
  return `${prefixedMessage} is not a valid number`;
}

function getTextErrorMessage(element: FormElement) {
  const { labels, name, validity } = element;
  const label = labels?.item(0).textContent || name;
  // if name exists, then use that for the error message
  if (validity.valueMissing) {
    return 'This is a required field';
  }
  return `${label} is not valid`;
}

export function getSelectErrorMessage(element: FormElement) {
  if (!element.value) {
    return 'This is a required field';
  }

  return null;
}
