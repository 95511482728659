import { connect } from "react-redux";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getCurrentPetServiceItem, isFromAnotherStore } from "core/selectors/checkInOutSelector";
import { createLoadingSelector } from "core/selectors/utils";
import petsActionTypes from "core/actionTypes/petsActionTypes";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import itinerariesActionTypes from "core/actionTypes/itinerariesActionTypes";
import { hideRebookQuickView, showRebookQuickView } from "dux/quickViews/quickViewActions";
import QuickViewToolTipsEnterLeaveWrapper from "dux/_components/quickViewToolTips/QuickViewToolTipsEnterLeaveWrapper";
import { petIsActive } from "dux/eligibility/helperUtils/checkIsBookingDisabled";

/**
 * A Wrapper to handle a bug/feature in chrome. The mouseLeave and mousse out will not fire when
 * a button is disabled, so this container if or a component that wraps a button. This way we can still
 * get the mouseLeave and MouseOut events.
 */

const mapStateToProps = state => {
  const customer = getCurrentCustomerKey(state);
  const currentPetServiceItemId = getCurrentPetServiceItem(state);
  const isPetsLoading = createLoadingSelector([
    petsActionTypes.LOAD_PETS_BY_CUSTOMER,
    customersActionTypes.LOAD_CUSTOMER,
    itinerariesActionTypes.LOAD_ITINERARY,
  ])(state);
  const isFromOtherStore = isFromAnotherStore(state, {
    petServiceItemId: currentPetServiceItemId,
  });

  return {
    isHidden: false,
    componentID: "QuickViewRebookButtonEnterLeave__toolTipWrapper",
    disabled: isPetsLoading || isFromOtherStore || !petIsActive(state, customer),
  };
};

const mapDispatchToProps = dispatch => ({
  mouseEnterHandler: (e, { disabled }) => {
    if (disabled) {
      dispatch(
        showRebookQuickView({
          xLoc: e.clientX,
          yLoc: e.clientY,
        }),
      );
    }
  },
  handleMouseLeave: () => {
    dispatch(hideRebookQuickView());
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleMouseEnter: e =>
    propsFromDispatch.mouseEnterHandler(e, { disabled: propsFromState.disabled }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(QuickViewToolTipsEnterLeaveWrapper);
