import { put, takeEvery, all, call, select } from "redux-saga/effects";
import {
  POST_OVER_BOOKING_NEW_EXCEPTION_DATE,
  clearOverbookingExceptionForm,
  postOverBookingNewExceptionDateFailure,
  postOverBookingNewExceptionDateRequest,
  postOverBookingNewExceptionDateSuccess,
} from "./overBookingNewExceptionDateActions";
import { selectOverbookingNewExceptionError } from "./overBookingNewExceptionDateSelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { postHotelOverBookingExceptionByRoomTypeBucket } from "@/core/services/associateWebBff/hotelOverbookingExceptions";

// Navigation utils
import { history } from "dux/utils/browser/browserHistory";
import { routePaths } from "@/core/constants/routePaths";

function* onPostOverBookingNewExceptionDate({ startDate, endDate, roomTypeBucket, percent }) {
  try {
    yield put(postOverBookingNewExceptionDateRequest());
    const storeNumber = yield select(getStoreNumber);
    const response = yield call(postHotelOverBookingExceptionByRoomTypeBucket, {
      storeNumber,
      roomTypeBucketNumber: roomTypeBucket,
      startDate,
      endDate,
      overBookingPercentage: percent,
    });

    yield put(postOverBookingNewExceptionDateSuccess(response?.data?.result));

    history.push(routePaths.MANAGE_OVERBOOKING_EXCEPTION_DATES);
  } catch ({ response }) {
    const errorMessage = response?.data?.message;
    yield put(postOverBookingNewExceptionDateFailure([{ errorMessage }]));
  }
}

function* watchOnPostOverBookingNewExceptionDate() {
  yield takeEvery(POST_OVER_BOOKING_NEW_EXCEPTION_DATE, onPostOverBookingNewExceptionDate);
}

export default function* overBookingNewExceptionDateSaga() {
  yield all([watchOnPostOverBookingNewExceptionDate()]);
}
