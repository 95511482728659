import { connect } from "react-redux";
import {
  getDDCSameDayStatus,
  selectStayAndPlayGroupStatus,
  selectStayAndPlayIndivStatus,
} from "dux/ddcRoomCapacity/ddcRoomCapacitySelector";
import OnOffLabel from "dux/_components/roomCapacity/OnOffLabel";
import { createLoadingSelector } from "core/selectors/utils";
import { hotelDashBoardActionTypes } from "dux/dashboard/hotel/hotelDashboardActions";

const { LOAD_PETS_HOTEL_DAILY_DASHBOARD } = hotelDashBoardActionTypes;

/**
 * Redux Connect function for the OnOffLabel for hotel same day service
 * @summary displayed on hotel dashboard
 * @memberOf Views.Store
 * @function
 * @name HotelSameDayOnOffLabel
 * @returns {JSX.Element|null}
 * @example <HotelSameDayOnOffLabel />
 */
export const HotelSameDayOnOffLabel = connect(state => ({
  componentID: "HotelSameDayOnOffLabel",
  isLoading: createLoadingSelector([LOAD_PETS_HOTEL_DAILY_DASHBOARD])(state),
  label: "Same Day",
  status: getDDCSameDayStatus(state),
}))(OnOffLabel);

/**
 * Redux Connect function for the OnOffLabel for hotel stay & play group service
 * @summary displayed on hotel dashboard
 * @memberOf Views.Store
 * @function
 * @name HotelStayAndPlayGroupOnOffLabel
 * @returns {JSX.Element|null}
 * @example <HotelStayAndPlayGroupOnOffLabel />
 */
export const HotelStayAndPlayGroupOnOffLabel = connect(state => ({
  componentID: "HotelStayAndPlayGroupOnOffLabel",
  isLoading: createLoadingSelector([LOAD_PETS_HOTEL_DAILY_DASHBOARD])(state),
  label: "Stay & Play Group",
  status: selectStayAndPlayGroupStatus(state),
}))(OnOffLabel);

/**
 * Redux Connect function for the OnOffLabel for hotel stay and play individual service
 * @summary displayed on hotel dashboard
 * @memberOf Views.Store
 * @function
 * @name HotelStayAndPlayIndivOnOffLabel
 * @returns {JSX.Element|null}
 * @example <HotelStayAndPlayIndivOnOffLabel />
 */
export const HotelStayAndPlayIndivOnOffLabel = connect(state => ({
  componentID: "HotelStayAndPlayIndivOnOffLabel",
  isLoading: createLoadingSelector([LOAD_PETS_HOTEL_DAILY_DASHBOARD])(state),
  label: "Stay & Play Indiv",
  status: selectStayAndPlayIndivStatus(state),
}))(OnOffLabel);
