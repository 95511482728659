import { call, put, select, all, takeEvery } from "redux-saga/effects";
import jobRoleActionTypes from "../../actionTypes/jobRoleActionTypes";
import { mockDifferentAccessLevel } from "../../services/systemAssociateAuth/mockAccessLevelEndPoints";
import { getStoreNumber, getOktaEmail, getSessionToken } from "../../selectors/persistentSelectors";
import {
  updateAccessLevelInState,
  mockTestRoleFailure
} from "../../actionCreators/jobRoleActionCreators";
import { refreshSessionToken } from "../../actionCreators/authActionCreators";

function* onMockDifferentJobRole({ jobRole }) {
  try {
    const username = yield select(getOktaEmail);

    const data = {
      accessLevel: jobRole,
      storeNumber: yield select(getStoreNumber)
    };

    const xSessionToken = yield select(getSessionToken);

    const response = yield call(mockDifferentAccessLevel, { username, data, xSessionToken });

    yield put(updateAccessLevelInState({ mockAccessLevel: response.data }));

    yield put(refreshSessionToken());
  } catch (error) {
    yield put(mockTestRoleFailure({ error }));
  }
}

function* watchMockTestRole() {
  yield takeEvery(jobRoleActionTypes.MOCK_DIFFERENT_JOB_ROLE, onMockDifferentJobRole);
}

export default function* mockTestRole() {
  yield all([watchMockTestRole()]);
}
