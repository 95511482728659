import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { isFromQuickQuote } from "core/utils/validationUtils/isFromValidation";
import { isFTCOHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import { getFirstTimeCustomerInfo } from "./ftcoSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { FIRST_TIME_CUSTOMER_MESSAGE } from "./ftcoConstants";
import IconWithLabel from "@/dux/_components/icon/IconWithLabel";
import PreCheckedInIcon from "../../../assets/icons/pre-checked-in-appointment.svg";

const mapStateToProps = (state, { router }) => {
  const customerKey = getCurrentCustomerKey(state);
  const { firstName } = getFirstTimeCustomerInfo(state, { customerKey });

  return {
    firstName,
    componentId: "ftco-indicator",
    isHidden: isFTCOHidden(state) || isFromQuickQuote(router.location.pathname),
    src: PreCheckedInIcon,
    label: `${firstName} ${FIRST_TIME_CUSTOMER_MESSAGE}`,
    styleObj: {
      padding: "0.5em",
      justifyContent: "center",
    },
  };
};

export default withRouteProps(connect(mapStateToProps)(IconWithLabel));
