import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 6em 4em;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ErrorMessage = styled.div`
  font-size: 1.5em;
  ${({ marginBottom }) =>
    marginBottom &&
    `
    margin-bottom: ${marginBottom};
  `};
`;

const OktaAuthError = () => (
  <Container>
    <ErrorMessage marginBottom="1em">
      Okta Authentication Error: Cannot parse tokens from URL.
    </ErrorMessage>
    <ErrorMessage>Please contact the solution center for assistance.</ErrorMessage>
  </Container>
);

export default OktaAuthError;
