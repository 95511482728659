import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";
import IconsComponent from "web/upcoming_here/icons/iconsComponent";
import { getHotelCardIcons } from "dux/hotelPetCardIcon/hotelIconUtils";
import { getMedicationIds } from "web/medication/medicationsSelector";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { selectHotelProfilePetCardIconInfo } from "./hotelPetCardIconSelectors";

const mapStateToProps = (state, { petId }) => {
  const receiveMedications = !isEmpty(getMedicationIds(state, { petId }));
  const customerKey = getCurrentCustomerKey(state);
  const profileIconInfo = selectHotelProfilePetCardIconInfo(state, { petId, customerKey });
  return {
    icons: getHotelCardIcons({ receiveMedications, ...profileIconInfo }),
  };
};

export default connect(mapStateToProps)(IconsComponent);
