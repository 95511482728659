import ruleViolationsActionTypes from "../actionTypes/ruleViolationsActionTypes";

export const setRuleViolations = ruleViolations => ({
  type: ruleViolationsActionTypes.SET_RULE_VIOLATIONS,
  ruleViolations
});

export const clearRuleViolations = () => ({
  type: ruleViolationsActionTypes.CLEAR_RULE_VIOLATIONS
});
