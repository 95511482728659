// cc: QuickViewAppointment#1;init State;Set initial state for the quickView Reducer

/**
 * Responsible for abstracting out the default state for every quickView. However additional state can be added via
 * the initState parameter
 *
 * @export quickViewInitState
 * @param {*} [initState={}] - optional param to add additional state to the modal object.
 * @returns {*} - state
 * @example quickViewInitState({foo: "bar"})
 */
export default function quickViewInitState(initState = {}) {
  return {
    ...initState,
    isVisible: false,
    quickViewType: initState.quickViewType || "",
    priority: 0,
  };
}
