import { connect } from "react-redux";
import {
  getPetParentProfileEmailToDelete,
  getCustomerKeyForDeleteEmail
} from "../../../../../core/selectors/petParentProfileSelectors";
import petParentProfileActionCreator from "../../../../../core/actionCreators/petParentProfileActionCreator";
import customersActionCreators from "../../../../../core/actionCreators/customersActionCreators";
import { createLoadingSelector } from "../../../../../core/selectors/utils";
import DeleteAgentModalComponent from "./DeleteEmailModalComponent";
import customersActionTypes from "../../../../../core/actionTypes/customersActionTypes";

const mapStateToProps = state => ({
  emailAddressId: getPetParentProfileEmailToDelete(state),
  customerKey: getCustomerKeyForDeleteEmail(state),
  isLoading: createLoadingSelector([customersActionTypes.DELETE_CUSTOMER_EMAIL])(state)
});

const mapDispatchToProps = dispatch => ({
  handleRemove({ customerKey, emailAddressId }) {
    dispatch(
      customersActionCreators.deleteCustomerEmail({
        customerKey,
        emailAddressId
      })
    );
  },
  onClose() {
    dispatch(petParentProfileActionCreator.hidePetParentProfileModal());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAgentModalComponent);
