import servicesSelectionActionTypes from "../actionTypes/servicesSelectionActionTypes";

export const loadStoreServicesForPet = ({ customerKey, petId, isStandalone, fromDate }) => ({
  type: servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_PET,
  customerKey,
  petId,
  isStandalone,
  fromDate,
});

export const loadStoreServicesForPetRequest = () => ({
  type: servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_PET_REQUEST,
});

export const loadStoreServicesForPetSuccess = petServiceData => ({
  type: servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_PET_SUCCESS,
  payload: petServiceData,
});

export const loadStoreServicesForPetFailure = ({ error }) => ({
  type: servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_PET_FAILURE,
  error,
});

export const loadStoreServicesForQuickQuotePet = ({
  speciesId,
  breedId,
  birthDate,
  fromDate,
  isStandalone,
  petId,
}) => ({
  type: servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET,
  speciesId,
  breedId,
  birthDate,
  fromDate,
  isStandalone,
  petId,
});

export const loadStoreServicesForQuickQuotePetRequest = () => ({
  type: servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET_REQUEST,
});

export const loadStoreServicesForQuickQuotePetSuccess = petServiceData => ({
  type: servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET_SUCCESS,
  payload: petServiceData,
});

export const loadStoreServicesForQuickQuotePetFailure = ({ error }) => ({
  type: servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_QUICK_QUOTE_PET_FAILURE,
  error,
});

export const updateStoreServiceForPetSuccess = serviceItem => ({
  type: servicesSelectionActionTypes.UPDATe_STORE_SERVICE_FOR_PET_SUCCESS,
  payload: serviceItem,
});

export const setSelectedService = ({ petServiceId }) => ({
  type: servicesSelectionActionTypes.SET_SELECTED_SERVICE,
  petServiceId,
});

export const loadStoreServices = ({ petServices }) => ({
  type: servicesSelectionActionTypes.LOAD_STORE_SERVICES,
  payload: petServices,
});

// EXPORTS
export default {
  loadStoreServicesForPet,
  loadStoreServicesForPetRequest,
  loadStoreServicesForPetSuccess,
  loadStoreServicesForPetFailure,
  loadStoreServicesForQuickQuotePet,
  loadStoreServicesForQuickQuotePetRequest,
  loadStoreServicesForQuickQuotePetSuccess,
  loadStoreServicesForQuickQuotePetFailure,
  updateStoreServiceForPetSuccess,
  setSelectedService,
  loadStoreServices,
};
