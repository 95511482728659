import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get the associates working at a specified store who perform pet services.
 * From the System Services Booking API
 * - Get associates working at a store
 * @param {*} { storeNumber }
 * @returns {*} AxiosPromise
 */
export function fetchAssociates({ storeNumber, isLimited, params = {} }) {
  return bookingAPI.get(
    `${endpointVersionString()}/stores/${storeNumber}/associates${isLimited ? "/limited" : ""}`,
    { params: { ...params } },
  );
}

/**
 * Retrieves a list of associate stores for the specified store. from System Services Booking API
 * - Get associate schedules for a store
 * @param {*} { storeNumber, fromDate }
 * @returns {*} AxiosPromise
 */
export function fetchAssociatesSchedule({ storeNumber, fromDate }) {
  const url = `${endpointVersionString()}/stores/${storeNumber}/associatesschedule?fromDate=${fromDate}`;
  return bookingAPI.get(url);
}

/**
 * Retrieves an object containing the associate schedule from the System Services Booking API
 * @param {*} { storeNumber, fromDate }
 * @returns {*} AxiosPromise
 */
export function fetchMySchedule({ fromDate }) {
  const url = `${endpointVersionString()}/users/me/schedule?fromDate=${fromDate}`;
  return bookingAPI.get(url);
}

/**
 * Gets a calendar template for an associate from the System Services BookingAPI
 * - Gets a calendar template for an associate.
 * @param {*} { associateId, fromDate }
 * @returns {*} AxiosPromise
 */
export function fetchSchedules({ associateId, fromDate }) {
  return bookingAPI.get(
    `/${endpointVersionString()}/associates/${associateId}/calendars?fromDate=${fromDate}`,
  );
}

/**
 * Updates a collection of associate settings from System Services Booking API
 * - Update a collection of settings specific to the associate
 * @param {*} { associateId, data }
 * @returns {*} AxiosPromise
 */
export function putAssociate({ associateId, data }) {
  return bookingAPI.put(`${endpointVersionString()}/associates/${associateId}/settings`, data);
}

/**
 * Updates a partial collection of associate settings from System Services Booking API
 * - Update a collection of settings specific to the associate
 * @param {*} { associateId, data }
 * @returns {*} AxiosPromise
 */
export function patchAssociate({ associateId, data }) {
  return bookingAPI.patch(
    `${endpointVersionString()}/training/associates/${associateId}/settings`,
    data,
  );
}

/**
 * Gets a calendar template for an associate from System Services Booking API
 * - Update a calendar for an associate
 * @param {*} { associateId, data }
 * @returns {*} AxiosPromise
 */
export function putSchedules({ associateId, data, ignoreViolations }) {
  return bookingAPI.put(
    `${endpointVersionString()}/associates/${associateId}/calendars${
      ignoreViolations ? `?ignoreViolations=${ignoreViolations}` : ""
    }`,
    data,
  );
}
