import React from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { Button } from "@petsmart-ui/sparky";
import schedulesActionCreators from "core/actionCreators/schedulesActionCreators";

/**
 *  React view component to allow user to copy previous day settings.
 *
 *  @summary Located on the associate-scheduling page.
 *  @memberOf Views.Associate
 *  @function
 *  @name associateScheduleDayCTA
 *  @param {Object } props - props passed into the view component
 *  @param { Boolean } props.isHidden - A flag to render markup.
 *  @param { String } props.componentId - Block/Root level Id for the component
 *  @param { Function } props.handleDispatch - Event handler for button onClick
 *  @returns {JSX.Element}
 *  @example
 *
 *  <associateScheduleDayCTA />
 */
export const associateScheduleDayCTA = props => {
  // Short circuit view if it isHidden
  const { isHidden } = props;
  if (isHidden) return null;

  // value props
  const { componentId, label } = props;

  // fn props
  const { handleDispatch } = props;

  return (
    <LayoutBox padding="scale-0" id={componentId}>
      <Button variant="link" text={label} onClick={() => handleDispatch()} />
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the associateScheduleDayCTA
 *
 * @see {@link associateScheduleDayCTA}
 * @summary Located on the associate-scheduling page..
 * @memberOf Views.Associate
 * @function
 * @name  CopyPreviousDayForAssociate
 * @param { Object } props
 * @param { Object } props.state - Redux State
 * @param { String } props.dayOfWeek - Day of the week. e.g. Monday
 * @returns {JSX.Element|null}
 *
 * @example <CopyPreviousDayForAssociate />
 */
export const CopyPreviousDayForAssociate = connect(
  (state, { dayOfWeek }) => {

    return {
      componentId: "CopyPreviousDayForAssociate",
      label: "Copy Previous Day",
      isHidden: dayOfWeek === "Monday",
    };
  },

  (dispatch, { dayOfWeek }) => {
    return {
      handleDispatch: () => {
        dispatch(schedulesActionCreators.copyPreviousDay(dayOfWeek));
      },
    };
  },
)(associateScheduleDayCTA);

/**
 * Redux Connect function for Resting an associates day Day
 *
 * @see {@link associateScheduleDayCTA}
 * @summary Located on the associate-scheduling page..
 * @memberOf Views.Associate
 * @function
 * @name  ResetDayForAssociate
 * @param { Object } props
 * @param { Object } props.state - Redux State
 * @returns {JSX.Element|null}
 *
 * @example <ResetDayForAssociate />
 */
export const ResetDayForAssociate = connect(
  (state) => {

    return {
      componentId: "ResetDayForAssociate",
      label: "Reset Day",
      isHidden: false,
    };
  },

  (dispatch, { dayOfWeek }) => {
    return {
      handleDispatch: () => {
        dispatch(schedulesActionCreators.resetDay(dayOfWeek));
      },
    };
  },
)(associateScheduleDayCTA);
