import React from "react";
import { HotelReservationPetCard } from "dux/_components/petCard/AppointmentSetPetCardComponent";
import { LayoutBox } from "@/layout/box/Box";
import { connect } from "react-redux";
import { selectHotelCartPets } from "dux/servicesCartHotel/servicesCartHotelSelectors";
import { LayoutStack } from "@/layout/stack/Stack";

export const ReservationPetCardList = props => {
  const { isHidden } = props;

  if (isHidden) {
    return null;
  }

  const { componentID, pets } = props;

  return (
    <LayoutBox id={componentID} padding="scale-0" style={{ overflow: "auto" }}>
      <LayoutStack>
        {pets.map(pet => (
          <React.Fragment key={pet.petId}>
            <HotelReservationPetCard pet={pet} />
          </React.Fragment>
        ))}
      </LayoutStack>

    </LayoutBox>
  );
};

/**
 * NOTE this redux container was originally
 * packages/associate-ui/src/dux/appointmentSet/AppointmentSetPetCardListContainer.js
 * and was deprecated and deleted as of ticket SVCSART-23856
 */
export const HotelReservationPetCardList = connect(state => {
  return {
    componentId: "HotelReservationPetCardList",

    // Convert dictionary of pets to an array as the PetCardList needs an array.
    pets: Object.values(selectHotelCartPets(state)),
  };
})(ReservationPetCardList);
