import { put, takeEvery, call, all, select } from "redux-saga/effects";
import normalizeArrayByProperty from "@/core/utils/normalizeUtils/normalizeArray";
import { fetchAssociates } from "@/core/services/systemServicesBooking/associatesEndPoints";
import { loadAssociatesSuccess } from "@/core/actionCreators/associateActionCreator";
import { serviceTypeTraining } from "./trainingAssociatesConstants";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import {
  GET_TRAINING_ASSOCIATES,
  getTrainingAssociatesRequest,
  getTrainingAssociatesSuccess,
  getTrainingAssociatesFailure,
} from "./trainingAssociatesActions";
import { getEmployeeGroupType } from "../associateProfile/associateProfileUtils";

export function* onGetTrainingAssociates({ storeNumber, isLimited }) {
  try {
    yield put(getTrainingAssociatesRequest());
    const response = yield call(fetchAssociates, {
      storeNumber,
      isLimited,
      params: {
        serviceType: serviceTypeTraining,
      },
    });

    const associates = response.data.map((associate) => ({
      ...associate,
      employeeGroupType: getEmployeeGroupType(associate),
    }));
    const normalizedAssociates = normalizeArrayByProperty(associates, "associateId");
    const associateIds = Object.keys(normalizedAssociates);

    yield put(loadAssociatesSuccess({ associates: normalizedAssociates }));

    yield put(getTrainingAssociatesSuccess(associateIds));
  } catch (error) {
    yield put(getTrainingAssociatesFailure());
  }
}

function* watchGetTrainingAssociates() {
  yield takeEvery(GET_TRAINING_ASSOCIATES, onGetTrainingAssociates);
}

export default function* trainingAssociatesSaga() {
  yield all([watchGetTrainingAssociates()]);
}
