import React from "react";
import styled from "styled-components";
import { get } from "lodash/fp";
import AddOnFullPriceContainer from "dux/addOnFullPrice/AddOnFullPriceContainer";
import AddOnOriginalPriceContainer from "dux/addOnOriginalPrice/AddOnOriginalPriceContainer";
import AddOnFinalPriceContainer from "dux/addOnFinalPrice/AddOnFinalPriceContainer";
import { SalonPriceAdjustmentFlyout } from "web/cart/PriceAdjustmentFlyoutComponent";
import { AppliedBulkPackageAddOns } from "dux/_components/bgm/BulkPackageAddOnsAppliedComponent";
import StyledPopover from "../common/StyledPopover";
import Specials from "./SpecialsContainer";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
`;

class Addon extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPriceAdjustment: false };
  }

  render() {
    const { addon, petId, customerId, itineraryId, timeSlot, componentId, isHidden } = this.props;
    const { showPriceAdjustment } = this.state;
    const nameProperty = addon.name ? "name" : "addOnName";
    return (
      <LayoutBox id={componentId} padding="scale-0">
        <LayoutStack space="scale-S5">
          <DetailsRow>
            <div id={`${componentId}__name`}>{get(nameProperty, addon)}</div>

            <StyledPopover
              body={
                <SalonPriceAdjustmentFlyout
                  petId={petId}
                  addOnId={addon.addOnId}
                  onClose={() => this.setState({ showPriceAdjustment: false })}
                />
              }
              isOpen={showPriceAdjustment}
              preferPlace="above"
              onOuterAction={() => this.setState({ showPriceAdjustment: false })}
              tipSize={7}
            >
              <DetailsRow id={`${componentId}__price`}>
                <AddOnFinalPriceContainer
                  addOnId={addon.addOnId}
                  petId={petId}
                  onClick={() => this.setState({ showPriceAdjustment: true })}
                />
                <AddOnOriginalPriceContainer addOnId={addon.addOnId} petId={petId} isHidden={isHidden}/>
                <AddOnFullPriceContainer addOnId={addon.addOnId} petId={petId} />
              </DetailsRow>
            </StyledPopover>
          </DetailsRow>

          <AppliedBulkPackageAddOns addOnId={addon.addOnId} petId={petId} />

          {/* NOTE: We need to check if a timeslot exists to prevent the BGM special from appearing
              on the select service page in the rebooking flow. We shouldn't show the BGM applied special
              until after a timeslot is selected and the appointment is created.
            */}
          {timeSlot && addon.dynamicPrice && (
            <Specials
              isEditable
              dynamicPrice={addon.dynamicPrice}
              petId={petId}
              customerId={customerId}
              itineraryId={itineraryId}
            />
          )}
        </LayoutStack>
      </LayoutBox>
    );
  }
}

export default Addon;
