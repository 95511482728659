import React from "react";
import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";
import { getScheduledSuspensionHistoryModalIsHidden } from "dux/scheduleSuspensionHistoryModal/scheduleSuspensionHistoryModalSelector";
import CommonModal from "web/common/modals/commonModal";
import { toggleScheduleSuspensionHistoryModal } from "dux/scheduleSuspensionHistoryModal/scheduleSuspensionHistoryModalActions";
import { HotelScheduleSuspensionsHistoryTable } from "dux/appointmentHistory/appointmentHistoryComponent";

export const ScheduleSuspensionHistoryModal = props => {
  const { componentId, isHidden, onClose, historyTable } = props;

  const Table = historyTable;

  return (
    <CommonModal isHidden={isHidden} onClose={onClose}>
      <Layout.Box id={componentId}>
        <Table />
      </Layout.Box>
    </CommonModal>
  );
};

// CONTAINER NAME CONTAINER ----------------------------------------------------------------------------------------
export const HotelScheduleSuspensionHistoryModal = connect(
  state => {
    // const suspensionId = getScheduledSuspensionHistoryModalSuspensionId;

    return {
      componentId: "HotelScheduleSuspensionHistoryModal",
      isHidden: getScheduledSuspensionHistoryModalIsHidden(state),
      historyTable: HotelScheduleSuspensionsHistoryTable,
    };
  },

  dispatch => {
    return {
      onClose: () => {
        // Setting the suspension Id to null on modal close so no lingering id is in state.
        dispatch(toggleScheduleSuspensionHistoryModal({ suspensionId: null }));
      },
    };
  },
)(ScheduleSuspensionHistoryModal);
