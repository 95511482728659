import { connect } from "react-redux";
import { isBGMEligibilityHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import { BGM } from "@/web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";
import { getPackagesByType } from "@/dux/bgm/bulkPackageOfferings/bulkPackageOfferingsSelectors";
import { getCurrentPetServiceItem } from "@/core/selectors/checkInOutSelector";
import { getPetServiceItem } from "@/core/selectors/entitiesSelector";
import PackageOfferTable from "@/dux/_components/packageOffers/PackageOfferTable";

const mapStateToProps = state => {
  const currentPetServiceItemId = getCurrentPetServiceItem(state);
  const petServiceItem = getPetServiceItem(state, { petServiceItemId: currentPetServiceItemId });
  const petServiceId = petServiceItem?.petServiceId;

  const packageList = getPackagesByType({ state, type: BGM, petServiceId });

  return {
    componentID: "eligiblePromotionsBGMPackageList__packageOfferTable",
    isHidden: isBGMEligibilityHidden(state),
    numberOfColumns: packageList[0]?.packageListValues.length,
    packageList,
  };
};

export default connect(mapStateToProps)(PackageOfferTable);
