import { associateWebProfileAPI } from "./_axiosConfigAssociateWebProfile";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Post customer status from the Web Associate Profile API -  Post Customer Status end point
 * @param {*} { customerKey, sourceId, data }
 * @returns
 */
export function postPersonStatus({ customerKey, sourceId, data }) {
  return associateWebProfileAPI.post(
    `/${endpointVersionString()}/customers/${customerKey}/personstatus?sourceId=${sourceId}`,
    data
  );
}

/**
 * Delete customer status from the Web Associate Profile API - ???
 * @param {*} { customerKey, sourceId, data }
 * @returns
 */
export function deletePersonStatus({ customerKey, sourceId, data }) {
  return associateWebProfileAPI.delete(
    `/${endpointVersionString()}/customers/${customerKey}/personstatus?sourceId=${sourceId}`,
    { data },
    { headers: { "Content-Type": "application/json" } }
  );
}
