import { combineReducers } from "redux";
import featureFlagPersistentCookieToggleReducer from "dux/featureFlagPersistentCookie/featureFlagPersistentCookieToggleReducer";
import enableDisableFeatureFlagsReducer from "../../../web/enableDisableFeature/EnableDisableFeatureFlagReducer";
import toggleFeatureFlagsReducer from "../../../web/toggleFeatureFlagWindow/toggleFeatureFlagWindowReducer";
import displayFeatureFlagIconReducer from "../../../web/displayFeatureFlagIcon/displayFeatureFlagIconReducer";
import searchForFeatureReducer from "../../../web/searchForFeature/searchForFeatureReducer";

const featureFlaggingReducer = combineReducers({
  featureFlags: enableDisableFeatureFlagsReducer,
  featureFlagWindow: toggleFeatureFlagsReducer,
  featureFlagIcon: displayFeatureFlagIconReducer,
  featureFlagPersistIcon: featureFlagPersistentCookieToggleReducer,
  featureSearch: searchForFeatureReducer,
});

export default featureFlaggingReducer;
