export default {
  SET_START_WEEK_DATE: "SET_START_WEEK_DATE",
  SET_EFFECTIVE_START_DATE: "SET_EFFECTIVE_START_DATE",
  SET_TEMPLATE_WEEK_ID: "SET_TEMPLATE_WEEK_ID",
  CHANGE_TEMPLATE_WEEK_ID: "CHANGE_TEMPLATE_WEEK_ID",
  SET_ALL_ASSOCIATES_WEEK_SCHEDULE: "SET_ALL_ASSOCIATES_WEEK_SCHEDULE",

  CLEAR_SCHEDULES: "CLEAR_SCHEDULES",
  LOAD_SCHEDULES: "LOAD_SCHEDULES",
  LOAD_SCHEDULES_REQUEST: "LOAD_SCHEDULES_REQUEST",
  LOAD_SCHEDULES_SUCCESS: "LOAD_SCHEDULES_SUCCESS",
  LOAD_SCHEDULES_FAILURE: "LOAD_SCHEDULES_FAILURE",

  CLEAR_TEMPLATE_SCHEDULES: "CLEAR_TEMPLATE_SCHEDULES",
  LOAD_TEMPLATE_SCHEDULES: "LOAD_TEMPLATE_SCHEDULES",
  LOAD_TEMPLATE_SCHEDULES_REQUEST: "LOAD_TEMPLATE_SCHEDULES_REQUEST",
  LOAD_TEMPLATE_SCHEDULES_SUCCESS: "LOAD_TEMPLATE_SCHEDULES_SUCCESS",
  LOAD_TEMPLATE_SCHEDULES_FAILURE: "LOAD_TEMPLATE_SCHEDULES_FAILURE",

  CLEAR_ASSOCIATES_SCHEDULES: "CLEAR_ASSOCIATES_SCHEDULES",
  LOAD_ASSOCIATES_SCHEDULE: "LOAD_ASSOCIATES_SCHEDULE",
  LOAD_ASSOCIATES_SCHEDULE_REQUEST: "LOAD_ASSOCIATES_SCHEDULE_REQUEST",
  LOAD_ASSOCIATES_SCHEDULE_SUCCESS: "LOAD_ASSOCIATES_SCHEDULE_SUCCESS",
  LOAD_ASSOCIATES_SCHEDULE_FAILURE: "LOAD_ASSOCIATES_SCHEDULE_FAILURE",

  LOAD_MY_SCHEDULE: "LOAD_MY_SCHEDULE",
  LOAD_MY_SCHEDULE_REQUEST: "LOAD_MY_SCHEDULE_REQUEST",
  LOAD_MY_SCHEDULE_SUCCESS: "LOAD_MY_SCHEDULE_SUCCESS",
  LOAD_MY_SCHEDULE_FAILURE: "LOAD_MY_SCHEDULE_FAILURE",

  SET_SCHEDULES: "SET_SCHEDULES",
  SET_SCHEDULES_REQUEST: "SET_SCHEDULES_REQUEST",
  SET_SCHEDULES_SUCCESS: "SET_SCHEDULES_SUCCESS",
  SET_SCHEDULES_FAILURE: "SET_SCHEDULES_FAILURE",
  SET_TEMPLATE_SCHEDULES: "SET_TEMPLATE_SCHEDULES",
  SET_TEMPLATE_SCHEDULES_REQUEST: "SET_TEMPLATE_SCHEDULES_REQUEST",
  SET_TEMPLATE_SCHEDULES_SUCCESS: "SET_TEMPLATE_SCHEDULES_SUCCESS",
  SET_TEMPLATE_SCHEDULES_FAILURE: "SET_TEMPLATE_SCHEDULES_FAILURE",
  SHOW_SCHEDULES_MODAL: "SHOW_SCHEDULES_MODAL",
  HIDE_SCHEDULES_MODAL: "HIDE_SCHEDULES_MODAL",
  SET_ASSOCIATE_ID: "SET_ASSOCIATE_ID",
  SET_WEEKLY_EDIT: "SET_WEEKLY_EDIT",
  SET_NEW_WEEKLY_EDIT: "SET_NEW_WEEKLY_EDIT",
  SET_NEW_WEEKLY_EDIT_SUCCESS: "SET_NEW_WEEKLY_EDIT_SUCCESS",
  REMOVE_WEEKLY_EDIT: "REMOVE_WEEKLY_EDIT",
  SET_TEMPLATE_WEEK_EDIT: "SET_TEMPLATE_WEEK_EDIT",
  GET_TEMPLATE_WEEK_EDIT: "GET_TEMPLATE_WEEK_EDIT",
  SET_WEEKLY_EDIT_DAY_ACTIVITY: "SET_WEEKLY_EDIT_DAY_ACTIVITY",
  COPY_PREVIOUS_DAY: "COPY_PREVIOUS_DAY",
  COPY_PREVIOUS_WEEK: "COPY_PREVIOUS_WEEK",
  RESET_DAY: "RESET_DAY",
  RESET_WEEK: "RESET_WEEK",
  ADD_PARTIAL_DAY_RELOCATION: "ADD_PARTIAL_DAY_RELOCATION",
  GO_TO_ASSOCIATE_TEMPLATE: "GO_TO_ASSOCIATE_TEMPLATE",
  REMOVE_DAILY_LUNCH: "REMOVE_DAILY_LUNCH",
  SET_DAILY_LUNCH: "SET_DAILY_LUNCH",
  RESET_DAY_ACTIVITY_IDS: "RESET_DAY_ACTIVITY_IDS",

  LOAD_LUNCH_BREAK_TIMES: "LOAD_LUNCH_BREAK_TIMES",
  LOAD_LUNCH_BREAK_TIMES_REQUEST: "LOAD_LUNCH_BREAK_TIMES_REQUEST",
  LOAD_LUNCH_BREAK_TIMES_SUCCESS: "LOAD_LUNCH_BREAK_TIMES_SUCCESS",
  LOAD_LUNCH_BREAK_TIMES_FAILURE: "LOAD_LUNCH_BREAK_TIMES_FAILURE",

  SET_SCHEDULES_SEARCH_VALUE: "SET_SCHEDULES_SEARCH_VALUE",
  SET_WEEKLY_EDIT_CHANGED: "SET_WEEKLY_EDIT_CHANGED"
};
