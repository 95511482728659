import { connect } from "react-redux";
import { getSystemBookingFlow } from "../../../setSystemType/selectors/setSystemTypeSelectors";
import {
  processCreateCustomerError,
  processUpdateCustomerError,
  getCustomerPreferredPhoneByType,
  getIsCustomerActive,
  getCustomerPrimaryEmailId,
} from "../../../../core/selectors/entitiesSelector";
import filterObjectByProperties from "../../../../core/utils/objectUtils/filterObjectByProperties";
import customersActionCreators, {
  createCustomer,
  updateCustomerProfile,
} from "../../../../core/actionCreators/customersActionCreators";
import petParentProfileActionCreator from "../../../../core/actionCreators/petParentProfileActionCreator";
import { clearError } from "../../../../core/actionCreators/errorActionCreators";
import customersActionTypes from "../../../../core/actionTypes/customersActionTypes";
import { createLoadingSelector } from "../../../../core/selectors/utils";
import { getStatesOptions, getCountriesOptions } from "../../../../core/selectors/enumsSelectors";
import {
  getIsOnlineAccount,
  getIsLoyaltyCustomer,
  getSetFieldForNewProfile,
  getPetParentProfileFormData,
} from "../../../../core/selectors/petParentProfileSelectors";
import { modalTypes } from "../../../../core/constants/modalConstants";
import PetParentFormComponent from "./PetParentFormComponent";
import { getPetMissingRequired } from "../../../../core/selectors/entities/getPetMissingRequiredSelector";
import { systemName } from "../../../setSystemType/constants/setSystemTypeConstants";
import { isAFeatureFlagsHiddenLogicHelper } from "web/petParentProfile/petParentProfileHelpers";

const mapStateToProps = (state, ownProps) => ({
  customerKey: ownProps.customerKey,
  fieldsToInclude: ownProps.fieldsToInclude,
  petParentFormData: ownProps.customerKey
    ? getPetParentProfileFormData(state, ownProps)
    : getSetFieldForNewProfile(state, ownProps),
  customerPreferredPhoneType: getCustomerPreferredPhoneByType(state, ownProps),
  createCustomerErrors: processCreateCustomerError(state),
  updateCustomerErrors: processUpdateCustomerError(state),
  isCreate: !ownProps.customerKey,
  isCreatingCustomer: createLoadingSelector([
    customersActionTypes.CREATE_CUSTOMER,
    customersActionTypes.CREATE_CUSTOMER_PHONE,
    customersActionTypes.CREATE_CUSTOMER_EMAIL,
  ])(state),
  isUpdatingCustomer: createLoadingSelector([customersActionTypes.UPDATE_CUSTOMER_PROFILE])(state),
  STATE_DROPDOWN_OPTIONS: getStatesOptions(state),
  COUNTRY_DROPDOWN_OPTIONS: getCountriesOptions(state),
  isCustomerActive: getIsCustomerActive(state, ownProps),
  isOnlineAccount: getIsOnlineAccount(state, ownProps),
  isLoyaltyCustomer: getIsLoyaltyCustomer(state, ownProps),
  emailAddressId: getCustomerPrimaryEmailId(state, ownProps),
  missingRequiredMessage: getPetMissingRequired(state, ownProps),

  // For any form field that needs to show or hide based on what tab the customer is in.
  //  1. hidden when on Salon Tab
  //  2. Hidden when on Training Tab
  //  3. hidden based on hidden ff logic.
  isFormFieldHidden:
    getSystemBookingFlow(state) === systemName.SALON ||
    getSystemBookingFlow(state) === systemName.TRAINING ||
    isAFeatureFlagsHiddenLogicHelper(state),
  isDoNotBookHidden: getSystemBookingFlow(state) === systemName.TRAINING,
});

const mapDispatchToProps = dispatch => ({
  // Temporarily save just the first and last name. Will change when nested objects endpoints are available
  updateCreateCustomer: values => {
    // UPDATE
    if (values?.customerKey) {
      dispatch(
        updateCustomerProfile({
          customerKey: values?.customerKey,
          formValues: values,
          isInHotel: !values?.isFormFieldHidden,
        }),
      );
      // CREATE
    } else {
      dispatch(
        createCustomer({
          data: values?.fieldsToInclude
            ? filterObjectByProperties(values, values?.fieldsToInclude)
            : values,
        }),
      );
    }
  },

  clearCreateCustomerErrors: () => {
    dispatch(clearError(customersActionTypes.CREATE_CUSTOMER));
  },

  clearUpdateCustomerErrors: () => {
    dispatch(clearError(customersActionTypes.UPDATE_CUSTOMER_PROFILE));
  },

  showDeleteEmailModal: ({ customerKey, emailAddressId }) => {
    dispatch(
      petParentProfileActionCreator.setCustomerAndEmailKeys({
        customerKey,
        emailAddressId,
      }),
    );
    dispatch(petParentProfileActionCreator.showPetParentProfileModal(modalTypes.DELETE_EMAIL));
  },

  clearFieldForNewProfiles: () => {
    dispatch(petParentProfileActionCreator.clearFieldForNewProfile());
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSubmit: values => {
    const { isFormFieldHidden, customerKey, fieldsToInclude } = propsFromState;
    propsFromDispatch.updateCreateCustomer({
      ...values,
      isFormFieldHidden,
      customerKey,
      fieldsToInclude,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PetParentFormComponent);
