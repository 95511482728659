import { connect } from "react-redux";
import { getPetParentAlert } from "../../../core/selectors/checkInOutSelector";
import { getCustomerFullName, getCustomerPhone } from "../../../core/selectors/entitiesSelector";
import { createLoadingSelector } from "../../../core/selectors/utils";
import PetParentDetails from "./petParentDetailsComponent";
import customersActionCreators from "../../../core/actionCreators/customersActionCreators";
import customersActionTypes from "../../../core/actionTypes/customersActionTypes";
import { getCurrentCustomerKey } from "../../../core/selectors/persistent/customer/customerSelectors";

const mapStateToProps = (state, ownProps) => {
  return {
    customerKey: getCurrentCustomerKey(state),
    customerName: getCustomerFullName(state, ownProps),
    customerPhone: getCustomerPhone(state, ownProps),
    petParentAlert: getPetParentAlert(state, ownProps),
    isLoading: createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state)
  };
};

const mapDispatchToProps = dispatch => ({
  loadCustomer: ({ customerKey }) => dispatch(customersActionCreators.loadCustomer({ customerKey }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PetParentDetails);
