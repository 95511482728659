import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { FormFieldCheckbox, Layout } from "@prism/psm-ui-components";
import formatToPascalCase from "core/utils/stringManipulationUtils/pascalCaseUtil";
import { ClickOutside } from "core/utils/clickOutsideUtils/clickOutside";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getCustomer } from "core/selectors/entitiesSelector";
import { updateCustomerProfile } from "core/actionCreators/customersActionCreators";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import { TextPassage } from "@petsmart-ui/sparky";

export const PSAInputCheckBox = props => {
  const { componentId, isHidden, inputLabel, updatePsaStatus, isPsaInitValue, disabled } = props;

  // component State
  const [psaOnFile, setPSAOnFile] = useState(isPsaInitValue);

  // set local state from props on initial render
  useEffect(() => {
    setPSAOnFile(isPsaInitValue);
  }, [isPsaInitValue]);

  // Set local PSA state in prep to send to container
  const onCheckBoxChange = () => {
    setPSAOnFile(!psaOnFile);
  };

  // Update vip status (send action) once a click outside has happened
  const onClickOutside = () => {
    updatePsaStatus({ psaOnFile });
  };

  // Click Outside of the component
  const ref = useRef(null);
  ClickOutside(ref, onClickOutside);

  // Set Ids names to Pascal case
  const pascalInputLabel = formatToPascalCase(inputLabel, " ", false);

  if (isHidden) {
    return null;
  }

  return (
    <div id={componentId} ref={ref}>
      <Layout.Box id={componentId}>
        <Layout.Cluster justify="flex-start" space="cluster-space-2">
          <FormFieldCheckbox
            id={`${componentId}__CheckBox`}
            changed={onCheckBoxChange}
            checked={psaOnFile}
            disabled={disabled}
          />
          <TextPassage
            id={`${componentId}__${pascalInputLabel}Label`}
            size="lg"
            style={{
              color: disabled && "rgba(0, 0, 0, 0.5)",
              cursor: disabled && "not-allowed",
            }}
          >
            {inputLabel}
          </TextPassage>
        </Layout.Cluster>
      </Layout.Box>
    </div>
  );
};

// HOTEL CONTAINER ---------------------------------------------------------------------------------
export const PSAInputHotelCheckBox = connect(
  state => {
    const customerKey = getCurrentCustomerKey(state);
    const customer = getCustomer(state, { customerKey });
    const bookingFlow = getSystemBookingFlow(state);
    const isLoading = createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state);

    return {
      isPsaInitValue: !!customer?.prismCustomer?.psaOnFile,
      isHidden: bookingFlow !== systemName.HOTEL || !customerKey,
      componentId: "PsaInputHotelCheckBox",
      inputLabel: "PSA on File",
      customerKey,
      disabled: isLoading,
    };
  },

  dispatch => {
    return {
      dispatchUpdatePsaActions: ({ psaOnFile, customerKey }) => {
        const formValues = {
          customerKey,
          psaOnFile,
        };
        dispatch(updateCustomerProfile({ formValues, customerKey, isInHotel: true }));
      },
    };
  },
  (mapProps, dispatchProps) => {
    const { componentId, isHidden, inputLabel, isPsaInitValue, customerKey, disabled } = mapProps;
    const { dispatchUpdatePsaActions } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,
      inputLabel,
      isPsaInitValue,
      disabled,

      // actions to pass to view
      updatePsaStatus: ({ psaOnFile }) => {
        dispatchUpdatePsaActions({ psaOnFile, customerKey });
      },
    };
  },
)(PSAInputCheckBox);
