import React from "react";
import styled from "styled-components";
import PetParentProfileColumn from "../PetParentProfileColumn";
import AppointmentsListContainer from "./appointmentsList/AppointmentsListContainer";
import AppointmentsListBGMContainer from "./appointmentsList/AppointmentsListBGMContainer";
import Section from "../../common/Section";
import LoadingWrapper from "../../common/LoadingWrapper";
import ToggleFilterByEngagementTypeButtons from "@/dux/filteringButtons/ToggleEngagementTypeButtonsContainer";

const RootComponent = styled.section`
  display: flex;
  flex-direction: column;
  flex: 0.8;
`;

export default function FAppointmentsComponentN(props) {
  const { isHidden, componentID, isLoading, disabled } = props;

  if (!isHidden) {
    return (
      <RootComponent id={componentID}>
        <PetParentProfileColumn
          title="Appointment (all pets)"
          disabled={disabled}
          noRightBorder
          shadow
        >
          <ToggleFilterByEngagementTypeButtons />
          <Section padding="0" paddingTop="1rem">
            <LoadingWrapper isLoading={isLoading}>
              <AppointmentsListContainer />
              <AppointmentsListBGMContainer />
            </LoadingWrapper>
          </Section>
        </PetParentProfileColumn>
      </RootComponent>
    );
  }

  return null;
}
