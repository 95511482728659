import { connect } from "react-redux";
import { REFUND_PACKAGE_PET_CARD } from "@/dux/_constants/refundPackageConstants";
import {
  getRefundPetIdForErrorModal,
  getRefundBundleNumberForErrorModal,
} from "../refundPackageErrorModal/refundPackageErrorModalSelectors";
import BGM_STATUS from "../bgm/availableBundlesByPet/availableBundlesByPetConstants";
import { getBundleRedemptionsByStatus } from "../bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import RefundPackagePetCardContainer from "@/dux/refundPackagePetCard/RefundPackagePetCardContainer";
import ScrollablePetCardList from "@/dux/_components/petCard/ScrollablePetCardList";

const mapStateToProps = state => {
  const petId = getRefundPetIdForErrorModal(state);
  const bundleNumber = getRefundBundleNumberForErrorModal(state);

  const redemptions = getBundleRedemptionsByStatus(state, {
    petId,
    bundleNumber,
    status: BGM_STATUS.RESERVED,
  });

  return {
    isHidden: false,
    componentId: "ScrollablePetCardList__DDCRoomCapacityArrivalAppointments",
    appointments: redemptions,
    diComp: {
      [REFUND_PACKAGE_PET_CARD]: RefundPackagePetCardContainer,
    },
    compName: REFUND_PACKAGE_PET_CARD,
  };
};

export default connect(mapStateToProps)(ScrollablePetCardList);
