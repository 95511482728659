import * as React from 'react';
import { LayoutProps } from '../';
import { psx, pluck } from '../../../utils/';
import { STACK_TOKENS } from './_tokens';

import './styles.css';

export interface StackProps extends LayoutProps<React.ElementType> {
  /** Evenly distributes the space between each child element in the Stack. */
  space?: keyof typeof STACK_TOKENS['SPACE'];
  /** Changes the orientation of this component for a horizontal layout*/
  flip?: boolean;
  /** Completely removes the component from the DOM. Useful for feature flags or t hide or show
   * a component. */
  isHidden?: boolean;
}

/**
 * The stack is useful for vertical and horizontal alignment, epsecially
 * when a uniform space between each element is the required design.
 *
 * [Source](https://every-layout.dev/layouts/stack)
 */
export const Stack: React.FunctionComponent<StackProps> = ({
  space = 'stack-space-0',
  flip = false,
  style = {},
  className,
  children,
  testid,
  as,
  isHidden = false,
  ...props
}) => {
  const id = 'psm-layout__stack';
  const Component = as || 'div';
  const classNames = psx('psm', id, { [`stack-flip`]: flip }, className);

  if (isHidden) {
    return null;
  }

  return (
    <Component
      className={classNames}
      data-testid={[id, testid].join('-')}
      style={{
        '--setStackSpace': pluck(STACK_TOKENS['SPACE'], space),
        ...style,
      }}
      {...props}
    >
      {children}
    </Component>
  );
};
