import styled from "styled-components";
import { color } from "../common/styles/theme";

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${color.borderGrey};
  padding: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};

  &:first-child {
    border-left: 1px solid ${color.borderGrey};
  }
`;
