import absencesActionTypes from "../../core/actionTypes/absencesActionTypes";

export const setAbsences = ({ absences }) => ({
  type: absencesActionTypes.SET_ABSENCES,
  payload: absences
});

export default {
  setAbsences
};
