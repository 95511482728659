import { put, takeEvery, all, select, call } from "redux-saga/effects";
import moment from "moment";
import { history } from "@/dux/utils/browser/browserHistory";
import { formatMoment } from "../utils/dateUtils/formatDateTime";

import cartActionTypes from "../../core/actionTypes/ui/web/cartActionTypes";
import { getPetServiceItem, getItinerary } from "../../core/selectors/entitiesSelector";
import {
  setPetServiceItemId,
  removeAppointmentFromCart,
} from "../../core/actionCreators/ui/web/cartActionCreators";
import { setItineraryId } from "../../core/actionCreators/bookingActionCreators";
import { onDeleteItinerary } from "../../core/sagas/itinerariesSaga";
import { onDeleteEngagement } from "../../core/sagas/engagementsSaga";
import { getAppointmentByPet } from "../selectors/cartSelectors";
import { getStoreFromSalon } from "../selectors/entitiesSelector";
import { loadSalonInfoWithHours } from "../actionCreators/salonActionCreator";
import { createBatchActions } from "../utils/batchUtils";
import { isFromBooking } from "../utils/validationUtils/isFromValidation";

function* onCancelAppointment({ petId, customerKey }) {
  petId = Number(petId);
  customerKey = Number(customerKey);
  const inBooking = isFromBooking(history.location.pathname);

  const appointment = yield select(getAppointmentByPet, { petId });
  yield put(removeAppointmentFromCart({ petId }));

  if (appointment.petServiceItemId) {
    const petServiceItem = yield select(getPetServiceItem, {
      petServiceItemId: appointment.petServiceItemId,
    });
    const itinerary = yield select(getItinerary, { itineraryId: petServiceItem.itinerary });
    const engagementId = petServiceItem.engagement;

    // Delete the entire itinerary if it's the only engagement left
    if (itinerary.engagements.length === 1 && itinerary.engagements[0] === engagementId) {
      yield call(onDeleteItinerary, { itineraryId: petServiceItem.itinerary, customerKey });
      if (inBooking) {
        yield put(setItineraryId(null));
      }
      // If not, delete just the engagement
    } else {
      yield call(onDeleteEngagement, {
        itineraryId: petServiceItem.itinerary,
        engagementId: petServiceItem.engagement,
        customerKey,
      });
    }
  }
}

function* onCartInitializeByItinerary({ itineraryId }) {
  const inBooking = isFromBooking(history.location.pathname);
  const itinerary = yield select(getItinerary, { itineraryId });

  if (!itinerary) {
    return;
  }

  if (inBooking) {
    yield put(setItineraryId(itineraryId));
  }

  yield all(
    itinerary.petServiceItems.map(petServiceItemId => call(onCartInitialize, { petServiceItemId })),
  );
}

function* onCartInitialize({ petServiceItemId }) {
  const petServiceItem = yield select(getPetServiceItem, { petServiceItemId });

  const { pet } = petServiceItem;
  yield put(
    setPetServiceItemId({
      petId: pet,
      petServiceItemId,
    }),
  );
}

function* watchCartInitialize() {
  yield takeEvery(cartActionTypes.INITIALIZE_CART, onCartInitialize);
}

function* watchCancelAppointment() {
  yield takeEvery(cartActionTypes.CANCEL_APPOINTMENT, onCancelAppointment);
}

function* watchCartInitializeByItinerary() {
  yield takeEvery(cartActionTypes.INITIALIZE_CART_BY_ITINERARY, onCartInitializeByItinerary);
}

export default function* cartSaga() {
  yield all([watchCartInitialize(), watchCancelAppointment(), watchCartInitializeByItinerary()]);
}
