/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const setFoodNameActionTypes = {
  SET_FOOD_NAME: "SET_FOOD_NAME",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setFoodName = ({ name, externalId, foodId, petId }) => ({
  type: setFoodNameActionTypes.SET_FOOD_NAME,
  name,
  externalId,
  foodId,
  petId,
});
