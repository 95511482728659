import { createSelector } from "reselect";
import { getState } from "core/selectors/commonSelector";

export const getHotelPriceAdjustmentReasons = createSelector([getState], state => {
  return state?.hotelPriceAdjustmentReasons?.priceAdjustmentReasons;
});

export const getHotelPriceAdjustmentReasonsValues = createSelector(
  [getHotelPriceAdjustmentReasons],
  priceAdjustmentReasons => {
    return priceAdjustmentReasons?.map(reason => reason.value);
  },
);

export const getHotelPriceAdjustmentReasonsLabels = createSelector(
  [getHotelPriceAdjustmentReasons],
  priceAdjustmentReasons => {
    return priceAdjustmentReasons?.map(reason => reason?.label);
  },
);
