import { connect } from "react-redux";
import SmallPillButton from "../../../_components/buttons/SmallPillButton";
import { getHotelArrivalsFilterBy } from "./hotelArrivalsPetServiceSelector";
import {
  filterByAllArrivals,
  filterByAttentionNeededArrivals,
  filterByDayCampArrivals,
  filterByDayCareArrivals,
  filterByOverNightArrivals,
  filterByUnassignedPetArrivals,
} from "./hotelArrivalsFilterButtonActions";

const mapStateToProps = state => {
  const filterBy = getHotelArrivalsFilterBy(state);

  return {
    isHidden: false,
    componentID: "SmallPillButton_HotelArrivalsAllFilterButton",
    label: "All",
    selected: filterBy.all,
    disabled: false,
    filterBtnStatus: filterBy.all,
  };
};

const mapDispatchToProps = dispatch => ({
  handleFilterByOverNightArrivals: ({ filterBtnStatus }) => {
    if (filterBtnStatus) {
      // toggle to off
      dispatch(filterByAllArrivals({ filterBtnStatus: !filterBtnStatus }));

      // set all other buttons to false;
      dispatch(filterByAttentionNeededArrivals({ filterBtnStatus: false }));
      dispatch(filterByOverNightArrivals({ filterBtnStatus: false }));
      dispatch(filterByDayCampArrivals({ filterBtnStatus: false }));
      dispatch(filterByDayCareArrivals({ filterBtnStatus: false }));
      dispatch(filterByUnassignedPetArrivals({ filterBtnStatus: false }));
    } else {
      // toggle to on
      dispatch(filterByAllArrivals({ filterBtnStatus: !filterBtnStatus }));

      // set all other buttons to true;
      dispatch(filterByAttentionNeededArrivals({ filterBtnStatus: true }));
      dispatch(filterByOverNightArrivals({ filterBtnStatus: true }));
      dispatch(filterByDayCampArrivals({ filterBtnStatus: true }));
      dispatch(filterByDayCareArrivals({ filterBtnStatus: true }));
      dispatch(filterByUnassignedPetArrivals({ filterBtnStatus: true }));
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleClick: () =>
    propsFromDispatch.handleFilterByOverNightArrivals({
      filterBtnStatus: propsFromState.filterBtnStatus,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SmallPillButton);
