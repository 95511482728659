import generalActionTypes from "../../../actionTypes/ui/web/generalActionTypes";

// SET IDs
export const setCustomerId = ({ customerId }) => ({
  type: generalActionTypes.SET_CUSTOMER_ID,
  customerId
});

/**
 * Action Creator to used to set the current store number in the redux store.
 *
 * Redux Path: persistent.currentStore
 *
 * @example setStoreNumber({ storeNumber: 133 })
 *
 * @param {string | number} storeNumber
 */
export const setStoreNumber = ({ storeNumber }) => ({
  type: generalActionTypes.SET_STORE_NUMBER,
  storeNumber: Number(storeNumber)
});

export const resetStoreNumber = () => ({
  type: generalActionTypes.RESET_STORE_NUMBER
});

export const setPetId = petId => ({
  type: generalActionTypes.SET_PET_ID,
  petId
});

export const setItineraryId = ({ itineraryId }) => ({
  type: generalActionTypes.SET_ITINERARY_ID,
  itineraryId
});
