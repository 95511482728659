import React, { useEffect } from "react";
import { connect } from "react-redux";

// Components
import { Layout } from "@prism/psm-ui-components";
import { OverBookingSetupTableRow } from "./OverBookingSetupTableRow";
import LoadingWrapper from "@/web/common/LoadingWrapper";

// Actions
import {
  getOverBookingSetupData,
  SET_OVERBOOKING_SETUP_DATA,
} from "./overBookingSetupTableActions";

// Selectors
import { selectOverBookingSetupData } from "./overBookingSetupTableSelectors";

// Utils
import { createLoadingSelector } from "@/core/selectors/utils";

// Constants
import { setUpTableColumns } from "./overBookingSetupTableConstants";

// Setup Table Component
export const SetupTable = props => {
  const {
    componentId,
    fetchOverBookingSetupData,
    isHidden,
    isLoading,
    setUpTableColumns,
    setUpTableData,
  } = props;

  useEffect(() => {
    // fetch setup table data on load
    fetchOverBookingSetupData();
  }, []);

  const style = {
    padding: "10px",
    width: "200px",
  };

  if (isHidden) return null;
  if (isLoading) return <LoadingWrapper isLoading={isLoading} />;

  return (
    <Layout.Box id={componentId}>
      <table
        id={`${componentId}__table`}
        style={{
          borderCollapse: "collapse",
          marginTop: "20px",
          width: "100%",
        }}
      >
        <thead>
          <tr
            style={{
              borderBottom: "1px solid gray",
              textAlign: "left",
            }}
          >
            {setUpTableColumns?.map(columnName => (
              <th style={style} key={columnName}>
                {columnName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {setUpTableData?.map((room, index) => (
            <OverBookingSetupTableRow room={room} key={index} />
          ))}
        </tbody>
      </table>
    </Layout.Box>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const OverBookingSetupTable = connect(
  state => {
    const setUpTableData = selectOverBookingSetupData(state);
    const isLoading = createLoadingSelector([SET_OVERBOOKING_SETUP_DATA])(state);

    return {
      componentId: "OverBookingSetupTable",
      isHidden: false,
      isLoading,
      setUpTableColumns,
      setUpTableData,
    };
  },

  dispatch => {
    return {
      fetchOverBookingSetupData: () => dispatch(getOverBookingSetupData()),
    };
  },
)(SetupTable);
