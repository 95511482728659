import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Checks the specified pet's eligibility to be booked for pet services and
 * returns an indication of whether or not the pet can be booked.
 * From System Services Booking API
 * - Get pet's eligibility for booking or check-in
 * @param {*} { customerKey, petId, ...params }
 * @returns {*} AxiosPromise
 */
export function fetchPetEligibility({ customerKey, petId, ...params }) {
  return bookingAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/eligibility/pets/${petId}`,
    {
      params,
    },
  );
}

/**
 * Get eligibility for all pets belonging to a customer
 * From System Services Booking API
 * -  Get eligibility for all pets belonging to a customer
 * @param {*} { customerKey, petIds, ...params }
 * @returns {*} AxiosPromise
 */
export function fetchPetsEligibilityByCustomer({ customerKey, petIds, ...params }) {
  return bookingAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/eligibility/pets${
      petIds ? `?petIds=${petIds}` : ""
    }`,
    {
      params,
    },
  );
}

/**
 * Get the eligibility status for all pets belonging to an itinerary
 * From System Services Booking API
 * @param {*} { customerKey, itineraryId, ...params }
 * @returns {*} AxiosPromise
 */
export function fetchPetsEligibilityByItinerary({ customerKey, itineraryId, ...params }) {
  return bookingAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/itineraries/${itineraryId}/pets/eligibility`,
    {
      params,
    },
  );
}
