import { connect } from "react-redux";
import { getPastAppointmentsByCustomer } from "../../../../../core/selectors/entitiesSelector";
import { getCurrentCustomerKey } from "../../../../../core/selectors/persistent/customer/customerSelectors";
import CommonAppointmentComponent from "../common/CommonAppointmentListComponent";

// NOTE: This container is identical to the regular
// appointments list container for now, but we want
// the flexibility to change the appointment list display
// behavior when BGM is implemented.

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);

  return {
    type: "past",
    title: "Past Appointments",
    appointments: getPastAppointmentsByCustomer(state, { customerKey }),
    componentID: "PastAppointmentsList",
  };
};

export default connect(mapStateToProps)(CommonAppointmentComponent);
