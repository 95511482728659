import { associateWebAPI } from "./_axiosConfigAssociateWeb";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * API call to get PetSmart Service Product Families with their related IDs
 * @returns {*} AxiosPromise
 */
export function fetchServiceProductFamilies() {
  return associateWebAPI.get(`${endpointVersionString()}/config/products/families`);
}
