import React, { useEffect, useState } from "react";
import { find, isUndefined } from "lodash/fp";
import { connect } from "react-redux";
import { getPetServiceItem, getPriceAdjustmentReasonValues } from "core/selectors/entitiesSelector";
import { getSelectedPetServiceItem } from "core/selectors/cartSelectors";
import { updatePriceOfPetServiceOrAddon } from "core/actionCreators/priceAdjustmentActionCreator";
import { Layout, Text, Form } from "@prism/psm-ui-components";
import { useForm } from "dux/utils/formUtils/useForm";
import { getFirstHotelEngagement } from "dux/hotelEngagements/hotelEngagementSelectors";
import {
  setPendingPrice,
  showReservationCartDetailsConfirmation,
} from "dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import { VALID_PRICE_REGEX } from "core/constants/regex";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "core/constants/validationErrors";
import {
  getHotelPriceAdjustmentReasons,
  getHotelPriceAdjustmentReasonsLabels,
} from "dux/hotelPriceAdjustmentReasons/hotelPriceAdjustmentSelectors";
import CommonButton from "../common/commonButton";

const PriceAdjustmentFlyoutComponent = props => {
  const { reasons, petServiceItem, componentId, updatePrice } = props;

  if (!petServiceItem) {
    return null;
  }

  const { values, setFormInput, setDropdownOption } = useForm();
  const [priceError, setPriceError] = useState(null);

  const { price, reason } = values;

  const onSubmit = () => {
    const { onClose } = props;
    updatePrice(values);
    onClose();
  };

  useEffect(() => {
    if (price === "") {
      setPriceError(REQUIRED_FIELD_ERROR_MESSAGE);
    } else if (price === undefined) {
      setPriceError(null);
    } else if (!VALID_PRICE_REGEX.test(Number(price))) {
      setPriceError("Invalid price");
    } else {
      setPriceError(null);
    }
  }, [price]);

  return (
    <Layout.Box padding="box-padding-8">
      <Text bold>Price Adjustment</Text>
      <Layout.Spacer size="60" />
      <Layout.Stack>
        <Form.Field.Text
          id={`${componentId}__newPrice`}
          type="number"
          label="New price"
          modifier="price"
          name="price"
          variant="material"
          labelStyle={{ fontWeight: "normal" }}
          onChange={setFormInput}
        />
        {priceError && (
          <Text id={`${componentId}__requiredField--newPrice`} color="text-color-red">
            {priceError}
          </Text>
        )}
      </Layout.Stack>
      <Layout.Spacer size="60" />
      <Form.Field.Select
        id={`${componentId}__newPrice`}
        labelStyle={{ fontWeight: "normal" }}
        legacy
        label="Reason"
        type="select"
        modifier="price"
        name="reason"
        options={reasons}
        onChange={setDropdownOption}
      />

      <Layout.Box>
        <CommonButton
          onClick={onSubmit}
          label="Override"
          disabled={priceError || isUndefined(price) || isUndefined(reason) || reason === ""}
        />
      </Layout.Box>
    </Layout.Box>
  );
};

export const SalonPriceAdjustmentFlyout = connect(
  (state, ownProps) => {
    const petServiceItemId = getSelectedPetServiceItem(state, { petId: ownProps.petId });

    return {
      addOnId: ownProps.addOnId,
      componentId: "priceAdjustmentFlyout__salon",
      reasons: getPriceAdjustmentReasonValues(state),
      petServiceItem: petServiceItemId && getPetServiceItem(state, { petServiceItemId }),
    };
  },
  dispatch => {
    return {
      dispatchUpdatePrices: ({
        customerId,
        itineraryId,
        engagementId,
        petServiceItemId,
        values,
        addon,
      }) => {
        const data = addon
          ? {
              reason: values.reason,
              addOnChanges: [
                {
                  addOnItemId: addon.addOnItemId,
                  price: values.price,
                },
              ],
            }
          : values;

        dispatch(
          updatePriceOfPetServiceOrAddon({
            customerId,
            itineraryId,
            engagementId,
            petServiceItemId,
            data,
          }),
        );
      },
    };
  },
  (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,
    updatePrice: values => {
      const { petServiceItem, addOnId } = propsFromState;
      const { dispatchUpdatePrices } = propsFromDispatch;
      const { customer, itinerary, engagement, petServiceItemId, addOns } = petServiceItem;
      const addon = addOnId && find({ addOnId }, addOns);

      dispatchUpdatePrices({
        customerId: customer,
        itineraryId: itinerary,
        engagementId: engagement,
        petServiceItemId,
        values,
        addon,
      });
    },
  }),
)(PriceAdjustmentFlyoutComponent);

export const HotelPriceAdjustmentFlyout = connect(
  (state, ownProps) => {
    return {
      componentId: "priceAdjustmentFlyout__hotel",
      reasons: getHotelPriceAdjustmentReasonsLabels(state),
      petServiceItem: getFirstHotelEngagement(state),
      priceAdjustmentReasons: getHotelPriceAdjustmentReasons(state),
    };
  },
  dispatch => {
    return {
      dispatchUpdatePrices: ({ engagementId, data }) => {
        dispatch(showReservationCartDetailsConfirmation({ isHidden: false }));
        dispatch(
          setPendingPrice({
            engagementId,
            payload: data,
          }),
        );
      },
    };
  },
  (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,
    updatePrice: values => {
      const { petServiceItem, priceAdjustmentReasons } = propsFromState;
      const { dispatchUpdatePrices } = propsFromDispatch;
      const { engagement } = petServiceItem;

      const reasonValue = priceAdjustmentReasons?.find(
        adjustmentReason => adjustmentReason?.value === values?.reason,
      )?.value;

      dispatchUpdatePrices({
        engagementId: engagement,
        data: { ...values, value: reasonValue },
      });
    },
  }),
)(PriceAdjustmentFlyoutComponent);
