import {
  jobRoleAllowsForHotel,
  setSystemAndFlowtoSalon,
} from "core/utils/jobRolsUtils/jobRoleBasedServiceVisibility";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import * as workflowFeatureTypes from "web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";

/**
 * Sets the system type and system booking flow type.
 *
 * @param {Object} setSystem - An object containing dispatchSetSystemType and dispatchSetSystemBookingFlowType functions.
 * @param {Function} setSystem.dispatchSetSystemType - A function to set the system type.
 * @param {Function} setSystem.dispatchSetSystemBookingFlowType - A function to set the system booking flow type.
 */
const setSystem = ({ dispatchSetSystemType, dispatchSetSystemBookingFlowType }) => {
  dispatchSetSystemType({ systemType: systemName.SALON });
  dispatchSetSystemBookingFlowType({ systemBookingFlow: systemName.SALON });
};

/**
 * Toggles the enable/disable status of the hotel and search field main v2 feature flags.
 *
 * @param {Object} obj - The object containing the function parameters.
 * @param {Function} obj.dispatchEnableDisableWorkflowFeatureFlags - The function to dispatch enable/disable action for feature flags.
 * @param {boolean} obj.checked - The flag to indicate if the feature flags should be enabled or disabled.
 */
const turnOnOffHotel = ({ dispatchEnableDisableWorkflowFeatureFlags, checked }) => {
  dispatchEnableDisableWorkflowFeatureFlags({
    featureFlagId: workflowFeatureTypes.HOTEL,
    checked,
  });
  dispatchEnableDisableWorkflowFeatureFlags({
    featureFlagId: workflowFeatureTypes.SEARCH_FIELD_MAIN_V2,
    checked,
  });
};

/**
 * Helper that Sets the system type and booking flow based on the user's job role capabilities.
 *
 * @function
 * @param {object} props - The input properties.
 * @param {string} props.jobRole - The user's job role.
 * @param {string[]} props.capabilities - The user's capabilities.
 * @param {string} props.systemType - The current system type.
 * @param {string} props.message - The message to display in an alert.
 * @param {function} props.dispatchSetSystemType - Function to dispatch system type.
 * @param {function} props.dispatchSetSystemBookingFlowType - Function to dispatch system booking flow.
 * @param {function} props.dispatchEnableDisableWorkflowFeatureFlags - Function to dispatch workflow feature flags.
 */
export const setSystemByCapabilities = props => {
  const { jobRole, capabilities, systemType, message } = props;

  const {
    dispatchSetSystemType,
    dispatchSetSystemBookingFlowType,
    dispatchEnableDisableWorkflowFeatureFlags,
  } = props;

  // If the user Logs into Hotel with improper job role capabilities, the system type and booking flow
  // needs to be set to Salon
  const allowForHotel = jobRoleAllowsForHotel(jobRole, capabilities);
  const isSystemAndFlowToSalon = setSystemAndFlowtoSalon({ systemType, allowForHotel });

  if (isSystemAndFlowToSalon) {
    // Turn off Hotel and set system state to Salon for those who do not have access to see hotel.
    setSystem({ dispatchSetSystemType, dispatchSetSystemBookingFlowType });
    turnOnOffHotel({ dispatchEnableDisableWorkflowFeatureFlags, checked: false });

    // eslint-disable-next-line no-alert
    alert(message);
  }

  // if the user logs into Prism without proper job roll capabilities (not-allowForHotel) turn off Hotel
  if (!allowForHotel) {
    // turn off Hotel
    turnOnOffHotel({ dispatchEnableDisableWorkflowFeatureFlags, checked: false });
  }

  // if the user logs into Prism with proper job roll capabilities (allowForHotel) turn on Hotel,
  if (allowForHotel) {
    // turn off Hotel
    turnOnOffHotel({ dispatchEnableDisableWorkflowFeatureFlags, checked: true });
  }
};
