import { connect } from "react-redux";
import { get } from "lodash/fp";
import Checkbox from "../../../web/common/Checkbox";
import { isFromBooking } from "../../../core/utils/validationUtils/isFromValidation";
import { history } from "@/dux/utils/browser/browserHistory";
import { fontSizes } from "web/common/styles/responsive/fonts";
import { getIsFoodOnPetById } from "../bookingSaveFoodToProfile/foodSaveToProfileSelectors";

const mapStateToProps = (state, ownProps) => {
  const { foodId, petId } = ownProps;
  return {
    foodId,
    checked: getIsFoodOnPetById(state, { foodId, petId }),
    componentId: "foodDetails-isFromProfile",
    isHidden: !isFromBooking(get(["location", "pathname"], history)),
    label: "Pet profile food",
    labelPosition: "right",
    paddingTop: "10px",
    fontSize: fontSizes.regular,
    disabled: true,
  };
};

export default connect(mapStateToProps)(Checkbox);
