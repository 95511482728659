import React from "react";
import styled, { css } from "styled-components";
import { color } from "../../styles/theme";

/* * ----------------------------------------------------------------------- * *\
  Styled Components -CSS
\* * ----------------------------------------------------------------------- * */
const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  border-bottom: none;
  padding: 10px 8px 10px 8px;
  cursor: pointer;
  transition: transform 0.1s;
  ${(cssConfig) => isActiveCSSConfig(cssConfig)};
`;

const IndexTabLabel = styled.div`
  ${(cssConfig) => isActiveCSSConfig(cssConfig)} margin-right: 10px;
`;

const IndexTabIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

/* * ----------------------------------------------------------------------- * *\
  CSS Config logic
\* * ----------------------------------------------------------------------- * */
function isActiveCSSConfig(cssConfig) {
  if (cssConfig.isActive) {
    return `
    background-color: ${color.victorStone};
    transform: scale(1.1);
    color: ${color.kalEl}
    `;
  }

  return `
    background-color: ${color.silverSurfer};
    color: ${color.victorStone};
    transform: scale(.9) translate(0, 4px);
  `;
}

/* * ----------------------------------------------------------------------- * *\
  JSX Component
\* * ----------------------------------------------------------------------- * */
export default function indexTab(props) {
  const {
    isHidden,
    componentID,
    isActive,
    handleClick,
    label,
    style,
    renderIcon = (f) => f,
  } = props;

  if (!isHidden) {
    return (
      <SectionWrapper isActive={isActive} id={componentID} onClick={handleClick} style={style}>
        <IndexTabIconContainer>
          {/* Utilizing the Render Props pattern here to display different Icons and their active/inactive state */}
          {renderIcon(isActive)}
        </IndexTabIconContainer>

        <IndexTabLabel isActive={isActive}>{label}</IndexTabLabel>
      </SectionWrapper>
    );
  }

  return null;
}
