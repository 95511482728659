import React from "react";
import SplitLine from "./SplitLine";

/**
|--------------------------------------------------
| * Takes a message and adds line breaks and a missing data message
 * @param {String} string A string containing a message that needs to be broken down.
  * @param {String} delimiter A string containing a delimiter to break lines on. 
 * @returns {div} A div with correctly formatted lines and additional message.
|--------------------------------------------------
*/

export default function SplitLineWithMessage(values) {
  const { string, delimiter, additionalMessage } = values;

  return (
    <div>
      {string && string.split(delimiter).map(line => SplitLine(line))}
      {additionalMessage && <span>{additionalMessage}</span>}
    </div>
  );
}
