/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const systemActionTypes = {
  SET_SYSTEM_TYPE: "SET_SYSTEM_TYPE",
  SET_SYSTEM_BOOKING_FLOW: "SET_SYSTEM_BOOKING_FLOW",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setSystemType = ({ systemType }) => ({
  type: systemActionTypes.SET_SYSTEM_TYPE,
  systemType,
});

export const setSystemBookingFlowType = ({ systemBookingFlow }) => ({
  type: systemActionTypes.SET_SYSTEM_BOOKING_FLOW,
  systemBookingFlow,
});
