import workflow from "./enableDisableWorkflowFeatureFlagConstants";
import { enableDisableWorkflowFeatureFlagActionTypes } from "./enableDisableWorkflowFeatureFlagActions";
import { showHideRequiresWorkflowFeatureFlagActionTypes } from "./showHideRequiresWorkflowFeatureFlagActions";
import getNormalizedFeatureFlagName from "./utils/getNormalizedFeatureFlagName";
import { PURGE } from "redux-persist";

const initialState = workflow;

const enableDisableWorkflowFeatureFlagReducer = (state = initialState, action) => {
  switch (action.type) {
    case enableDisableWorkflowFeatureFlagActionTypes.ENABLE_DISABLE_WORKFLOW_FEATURE_FLAG:
      return {
        ...state,
        [getNormalizedFeatureFlagName(action.featureFlagId)]: {
          ...state[getNormalizedFeatureFlagName(action.featureFlagId)],
          isHidden: !action.checked,
        },
      };
    case showHideRequiresWorkflowFeatureFlagActionTypes.SHOW_REQUIRE_WORKFLOW_FEATURE_FLAG:
      return {
        ...state,
        [getNormalizedFeatureFlagName(action.featureFlagId)]: {
          ...state[getNormalizedFeatureFlagName(action.featureFlagId)],
          showRequires: !action.isHidden,
        },
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default enableDisableWorkflowFeatureFlagReducer;
