import { connect } from "react-redux";
import SmallPillButton from "../../../_components/buttons/SmallPillButton";
import { getHotelOvernightFilterBy } from "./hotelOvernightPetServiceSelectors";

import {
  filterByAllOvernight,
  filterByAttentionNeededOvernight,
  filterByUnassignedPetOvernight,
} from "./hotelOvernightFilterButtonActions";

const mapStateToProps = state => {
  const filterBy = getHotelOvernightFilterBy(state);

  return {
    isHidden: false,
    componentID: "SmallPillButton_HotelOverNightAllFilterButton",
    label: "All",
    selected: filterBy.all,
    disabled: false,
    filterBtnStatus: filterBy.all,
  };
};

const mapDispatchToProps = dispatch => ({
  handleFilterByAllOvernight: ({ filterBtnStatus }) => {
    if (filterBtnStatus) {
      // toggle to off
      dispatch(filterByAllOvernight({ filterBtnStatus: !filterBtnStatus }));

      // set all other buttons to false;
      dispatch(filterByAttentionNeededOvernight({ filterBtnStatus: false }));
      dispatch(filterByUnassignedPetOvernight({ filterBtnStatus: false }));
    } else {
      // toggle to on
      dispatch(filterByAllOvernight({ filterBtnStatus: !filterBtnStatus }));

      // set all other buttons to true;
      dispatch(filterByAttentionNeededOvernight({ filterBtnStatus: true }));
      dispatch(filterByUnassignedPetOvernight({ filterBtnStatus: true }));
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleClick: () =>
    propsFromDispatch.handleFilterByAllOvernight({
      filterBtnStatus: propsFromState.filterBtnStatus,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SmallPillButton);
