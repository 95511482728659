/**
 * Responsible for returning the Persistent Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getPersistentState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the Persistent object in the redux store
 *
 * @example createSelector([getPersistentState], persistentState => persistentState.persistentKey;
 */
export default function getPersistentState(state) {
  return state?.persistent;
}
