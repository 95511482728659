import React from "react";
import { Heading } from "@petsmart-ui/sparky";
import { LayoutBox } from "../../../layout/box/Box";
import { LayoutStack } from "../../../layout/stack/Stack";
import { LayoutCenter } from "../../../layout/center/Center";
import { connect } from "react-redux";
import { PetInfoCard } from "dux/_components/petCard/HotelPetCardComponent";
import { getAppointmentsByRoomTypeId } from "dux/hotelRoomCapacityModal/hotelRoomTypeColumns/hotelRoomTypeColumnsSelectors";
import { hasNoAppointmentsOrRooms } from "dux/hotelRoomCapacityModal/hotelRoomTypeColumns/hotelRoomTypeColumnUtils";
import { RoomCard } from "dux/hotelAvailableRoomCard/HotelAvailableRoomCard";
import { selectAvailableHotelRooms } from "dux/hotelAvailableRoomCard/hotelAvailableRoomCardSelector";

export const HotelRoomTypeColumnComp = props => {
  const { componentId, isHidden, colTitle, appointments, roomTypeId } = props;

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding={"scale-0"}>
      <LayoutStack>
        <LayoutCenter>
          <Heading size="body-lg-bold" tagName="h2">
            {colTitle}
          </Heading>
        </LayoutCenter>

        <LayoutBox padding={"scale-0"}>
          <LayoutStack space={"scale-S3"}>
            {appointments.map(appointment => (
              <PetInfoCard
                appointment={appointment}
                roomTypeId={roomTypeId}
                key={`${appointment.itineraryId}__${appointment.petId}`}
              />
            ))}
            <RoomCard roomTypeId={roomTypeId} />
          </LayoutStack>
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

export const HotelRoomTypeColumn = connect((state, props) => {
  const { roomTypeId, roomName } = props.roomType;
  const compName = `${roomName}_${roomTypeId}`;

  const appointments = getAppointmentsByRoomTypeId(roomTypeId)(state);
  const roomNumbers = selectAvailableHotelRooms(roomTypeId)(state);

  return {
    componentId: compName,
    colTitle: roomName,
    appointments,
    roomTypeId,
    isHidden: hasNoAppointmentsOrRooms(appointments, roomNumbers),
  };
})(HotelRoomTypeColumnComp);
