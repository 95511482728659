import React from "react";
import CommonModal from "@/web/common/modals/commonModal";
import GanttZoomContent from "@/web/common/modals/content/gantZoom/ganttZoomContainer";
import { modalTypes as commonModalTypes } from "@/core/constants/modalConstants";
import { modalTypes } from "@/core/constants/dashboardContants";
import ManualBookingWarning from "./content/manualBookingWarningContainer";

const DashboardModals = props => (
  <CommonModal
    onClose={() => {
      props.onClose();
    }}
  >
    {selectModalContent(props)}
  </CommonModal>
);

const selectModalContent = props => {
  switch (props.modalType) {
    case commonModalTypes.GANTT_ZOOM:
      return <GanttZoomContent />;

    case modalTypes.MANUAL_BOOKING_WARNING:
      return <ManualBookingWarning onClose={props.onClose} />;

    default:
      return null;
  }
};

export default DashboardModals;
