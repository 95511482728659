import { connect } from "react-redux";
import petsActionTypes from "../../../core/actionTypes/petsActionTypes";
import itinerariesActionTypes from "../../../core/actionTypes/itinerariesActionTypes";
import { getHereOrCompletedFilterApplied } from "../../../core/selectors/upcomingAndHereSelectors";
import { setHereOrCompletedFilterApplied } from "../../../core/actionCreators/upcomingAndHereActionCreators";
import {
  getHereAppointments,
  getUpcomingAppointments
} from "../../../core/selectors/entitiesSelector";
import { createLoadingSelector } from "../../../core/selectors/utils";
import CardList from "./CardList";

const mapStateToProps = state => ({
  isLoading: createLoadingSelector([
    itinerariesActionTypes.LOAD_ITINERARIES,
    petsActionTypes.LOAD_PETS
  ])(state),
  upcomingAppointments: getUpcomingAppointments(state),
  hereAppointments: getHereAppointments(state),
  hereOrCompletedFilterApplied: getHereOrCompletedFilterApplied(state)
});

const mapDispatchToProps = dispatch => ({
  setHereOrCompletedFilterApplied: filterApplied => {
    dispatch(setHereOrCompletedFilterApplied({ hereOrCompletedFilterApplied: filterApplied }));
  }
});

const CardListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CardList);

export default CardListContainer;
