import { connect } from "react-redux";
import isInRoute from "../../../../../core/utils/routeUtils/inRoute";
import { routePaths } from "../../../../../core/constants/routePaths";
import {
  getRebookErrorMessages,
  getSelectedAppointment
} from "../../../../../core/selectors/commonSelector";
import RebookErrorComponent from "../../../../../web/common/modals/content/rebookError/rebookErrorComponent";
import { clearCart } from "../../../../../core/actionCreators/ui/web/cartActionCreators";
import { clearRebookErrorMessages } from "../../../../../core/actionCreators/ui/web/commonActionCreators";
import { hidePetParentProfileModal } from "../../../../../core/actionCreators/petParentProfileActionCreator";
import { getPetServiceItem } from "../../../../../core/selectors/entitiesSelector";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const mapStateToProps = state => {
  const petServiceItemId = getSelectedAppointment(state);

  return {
    errorMessages: getRebookErrorMessages(state),
    appointment: getPetServiceItem(state, { petServiceItemId })
  };
};

const mapDispatchToProps = dispatch => ({
  clearCart: () => dispatch(clearCart()),
  onCancel: () => {
    dispatch(clearRebookErrorMessages());
    dispatch(hidePetParentProfileModal());
  }
});

export default withRouteProps(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RebookErrorComponent)
);
