import { connect } from "react-redux";
import { Text } from "@prism/psm-ui-components";
import {
  getNumberOfSearchResults,
  getSearchResultsToDisplay,
} from "@/core/selectors/searchSalonSRCSelector";
import { searchConstants } from "@/core/constants/searchConstants";
import pluralizeString from "@/dux/utils/stringUtils/pluralizeString";

const mapStateToProps = state => {
  const numberOfSearchResults = getNumberOfSearchResults(state);
  return {
    isHidden: getSearchResultsToDisplay(state) !== searchConstants.CUSTOMER,
    children: `${numberOfSearchResults} ${pluralizeString("result", numberOfSearchResults)}`,
    fontFamily: "text-family-montserrat",
  };
};

export default connect(mapStateToProps)(Text);
