import React from "react";
import styled from "styled-components";
import moment from "moment";
import { fontSizes } from "web/common/styles/responsive/fonts";
import Input from "./../Input";

const StyledTimeRange = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: ${fontSizes.regular};
`;

const Hyphen = styled.div`
  padding: 0 0.625rem;
  font-size: ${fontSizes.regular};
  ${props =>
    props.disabled &&
    `
        opacity: 0.5;
        * {
            pointer-events: none;
        }
    `};
`;

const TimeRangePicker = props => {
  const { startTime, endTime, onChange, disabled, hideEndTime } = props;
  const isStartTimeBeforeEndTime =
    hideEndTime || moment(startTime, "HH:mm").isBefore(moment(endTime, "HH:mm"));

  const isValid = props.isValid === undefined ? isStartTimeBeforeEndTime : props.isValid;

  return (
    <StyledTimeRange>
      <Input
        type="time"
        backgroundColor="transparent"
        error={!isValid}
        disabled={disabled}
        value={moment(startTime, "HH:mm").format("HH:mm")}
        onChange={e => {
          const newStartTime = e.target.value;
          if (onChange) {
            onChange({
              startTime: newStartTime,
              endTime,
            });
          }
        }}
      />

      {!hideEndTime && (
        <React.Fragment>
          <Hyphen disabled={disabled}> - </Hyphen>
          <Input
            type="time"
            backgroundColor="transparent"
            error={!isValid}
            disabled={disabled}
            value={moment(endTime, "HH:mm").format("HH:mm")}
            onChange={e => {
              const newEndTime = e.target.value;
              if (onChange) {
                onChange({
                  endTime: newEndTime,
                  startTime,
                });
              }
            }}
          />
        </React.Fragment>
      )}
    </StyledTimeRange>
  );
};

export default TimeRangePicker;
