import { checkFrequencyHasDay } from "@/core/utils/frequencyUtils/checkFrequencyHasDay";
import { normalizeHotelEngagements } from "dux/hotelEngagements/hotelEngagementNormalize";
import { find, findIndex, isEmpty } from "lodash/fp";

import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

/**
 * Remove Separates out engagements and removes un-needed fields from hotel Itinerary
 * @param responseData - hotel itinerary response result
 * @returns {{modifiedItinerary: Pick<*, Exclude<keyof *, "pets"|"engagements"|"customer">>, engagements: {}}}
 */
export function separateHotelItineraryResponse(responseData) {
  // get a normalized engagements from itinerary
  const normalizedEngagements = normalizeHotelEngagements(responseData);

  // remove engagements, pets, and customer from itinerary and place in new object as to not mutate the original responseData
  const { customer, pets, engagements, ...modifiedItinerary } = responseData;

  // return out the separated objects.
  return {
    engagements: normalizedEngagements,
    modifiedItinerary,
    pets,
    customer,
  };
}

/**
 * Returns the reservation notes for a particular pet.
 * @param pets
 * @param petId
 * @returns {{petKey, reservationNotes: *}}
 */
export function getReservationNotesByPetId(pets, petId) {
  const reqPet = find(pet => pet.petKey === petId, pets);

  return {
    petId: reqPet?.petKey,
    reservationNotes: reqPet?.reservationNotes,
  };
}

/**
 * Rebuilds the body of the hotel itinerary for put to SF as no patch is currently available.
 * NOTE: !!!! This function is depreciated and should use the getConstructedHotelItinerary selector instead.!!!
 * @param { Object } hotelItinerary
 * @param { Array } engagements
 * @param { Object } pets
 * @param currentPetId
 * @param { Object } customer
 * @param reservationNotes
 * @param checkOutTimeWithEngagement
 * @param foods
 * @returns {*&{constructedPetsObj, customer: {customerKey: *, customerPhone: *, isNewCustomer: *, customerName: string}}}
 */
export function buildHotelItineraryBody({
  hotelItinerary,
  engagements,
  pets,
  currentPetId,
  customer,
  reservationNotes = "",
  checkInTime = null,
  checkOutTime = null,
  engagementId = null,
  roomNumberWithEngagement = null,
  foods = null,
  meds = null,
}) {
  /* ====== CUSTOMER ====== */
  // get the index for the hotel profile
  const hotelProfileIndex = findIndex(profile => {
    return profile.profileType === systemName.DDC_HOTEL;
  }, customer?.prismCustomer?.profiles);

  // get the index for for the primary phone
  const primaryPhoneIndex = findIndex(phone => {
    return phone.isPrimary === true;
  }, customer?.phones);

  /* ====== PET ====== */
  // make a lis tof all pet keys from engagements.
  const petKeyList = engagements.map(engagement => engagement.petId);
  /* ====== RE-CONSTRUCTED HOTEL ITINERARY ====== */
  return {
    ...hotelItinerary,
    customer: {
      customerKey: customer?.customerKey,
      customerName: `${customer?.firstName} ${customer?.lastName}`,
      isNewCustomer: customer?.prismCustomer?.profiles[hotelProfileIndex]?.isNewCustomer,
      customerPhone: customer?.phones[primaryPhoneIndex].phoneNumber,
    },
    pets: petKeyList.map(petId => {
      // get the index for for the hotelProfileKey
      // const petHotelProfileIndex = findIndex(profile => {
      //   return profile.profileType === systemName.HOTEL;
      // }, pets[petId]?.prismPet.profiles);
      return {
        petKey: petId,
        petName: pets[petId].petName,
        reservationNotes: currentPetId === petId ? reservationNotes : "",
        engagements: engagements
          .filter(e => e?.petId === petId)
          .map(engagement => {
            return {
              ...engagement,
              startDatetime:
                engagementId === engagement.engagementId ? checkInTime : engagement.startDatetime,
              endDatetime:
                engagementId === engagement.engagementId ? checkOutTime : engagement.endDatetime,
              metadata: {
                ...engagement?.metadata,
                room: {
                  ...engagement?.metadata?.room,
                  roomNumber:
                    roomNumberWithEngagement?.engagementId === engagement.engagementId
                      ? roomNumberWithEngagement?.roomNumber
                      : engagement?.metadata?.room?.roomNumber,
                },
                feedings: foods
                  ? foods.filter(food => food?.engagementId === engagement?.engagementId)
                  : engagement?.metadata?.feedings,
                medications: meds
                  ? meds.filter(med => med?.engagementId === engagement?.engagementId)
                  : engagement?.metadata?.medications,
              },
            };
          }),
      };
    }),
  };
}

/**
 * Determines which foods or meds need to be on the current engagement
 * @param { Object } engagementFoodOrMeds - feedings or medications array from the engagement being updated
 * @param { String } startDatetime - startDateTime from the from the engagement being updated
 * @param { String } endDatetime - endDatetime from the from the engagement being updated
 * @param { Array } foodsOrMeds - the foods or meds from state that may have been updated
 * @param { Function } sanitizeData - the Id of the engagement to apply the pending times to
 * @param {string} timeZone
 * @param {Object} foodMedExternalData - food or med objs containing IsActive flag
 * @returns the array of foods or meds for a speciifc engagement
 */
export const applyFoodMedChangesToEngagement = ({
  engagementFoodOrMeds = [],
  startDatetime,
  endDatetime,
  foodsOrMeds,
  sanitizeData = args => args,
  timeZone,
  foodMedExternalData = {},
}) => {
  if (!foodsOrMeds) return engagementFoodOrMeds;
  const objs = {};

  engagementFoodOrMeds.map((record = {}) => {
    const groupingId = record?.groupingId;
    const foodOrMed = foodsOrMeds.find(obj => obj?.groupingId === groupingId) ?? {};
    const isDateInFrequency = checkFrequencyHasDay(
      foodOrMed?.customFrequencyDates,
      startDatetime,
      timeZone,
    );
    // Set isDeleted = true if the record isn't in foodsOrMeds or if the frequency doesn't include startDateTime
    const isDeleted = isEmpty(foodOrMed) || !isDateInFrequency;
    const { recordId, history, ...restOfRecord } = record;

    objs[groupingId] = sanitizeData({
      ...restOfRecord,
      ...foodOrMed,
      // Attributes we don't want foodOrMed to override
      startDatetime,
      endDatetime,
      recordId,
      history,
      isDeleted,
    });
  });

  // Check for any foods/meds that are not on the engagement but are in the foodsOrMeds array
  const groupingIds = Object.keys(objs);
  const missingObjs =
    foodsOrMeds.filter(({ groupingId }) => !groupingIds.includes(groupingId)) ?? [];

  missingObjs.map(obj => {
    const groupingId = obj?.groupingId;

    // If food/med is discontinued don't include it if it isn't already on the eng
    const externalId = obj?.foodId ?? obj?.medicationId;
    const isDiscontinued = foodMedExternalData?.[externalId]?.IsActive === false;
    if (isDiscontinued) return;

    const isDateInFrequency = checkFrequencyHasDay(
      obj?.customFrequencyDates,
      startDatetime,
      timeZone,
    );
    if (isDateInFrequency) {
      objs[groupingId] = sanitizeData({
        ...obj,
        startDatetime,
        endDatetime,
        isDeleted: false,
        // Attributes we don't want to copy when adding a new record
        recordId: undefined,
        history: undefined,
      });
    }
  });

  return Object.values(objs);
};

// Remove attributes from food obj that the itinerary api doesnt need
export const sanitizeFoodForItineraryCall = food => ({
  recordId: food?.recordId,
  groupingId: food?.groupingId,
  foodId: food?.foodId,
  feedingName: food?.feedingName,
  startDatetime: food?.startDatetime,
  endDatetime: food?.endDatetime,
  timeOfDay: food?.timeOfDay,
  splInstruction: food?.splInstruction,
  notes: food?.notes,
  measure: food?.measure,
  frequency: food?.frequency,
  amount: food?.amount,
  isDeleted: food?.isDeleted,
});

// Remove attributes from med obj that the itinerary api doesnt need
export const sanitizeMedForItineraryCall = med => ({
  recordId: med?.recordId,
  groupingId: med?.groupingId,
  medicationId: med?.medicationId,
  medicationName: med?.medicationName,
  startDatetime: med?.startDatetime,
  endDatetime: med?.endDatetime,
  timeOfDay: med?.timeOfDay,
  splInstruction: med?.splInstruction,
  notes: med?.notes,
  measure: med?.measure,
  frequency: med?.frequency,
  amount: med?.amount,
  isDeleted: med?.isDeleted,
});

/**
 * Updates the body of the hotel itinerary with pending food, med, & notes changes
 * @param { Object } itinerary - should be the full itinerary obj
 * @param { String } pendingReservationNotes
 * @param { String } checkInOutTimeWithEngagement.engagementId - the Id of the engagement to apply the pending times to
 * @param { Array } foods - all of the foods (pending & unchanged from itinerary) for the current pet
 * @param { Array } meds - all of the meds (pending & unchanged from itinerary) for the current pet
 * @param { Number } currentPetId - the petId of the pet that the pending changes apply to
 * @param {string} timeZone
 * @returns the full itinerary object but with the pending changes applied
 */
export const updateItineraryWithPendingChanges = ({
  itinerary,
  pendingReservationNotes,
  foods,
  meds,
  currentPetId,
  timeZone,
}) => {
  return {
    ...itinerary,
    pets: itinerary?.pets?.map(pet => {
      const engagements = pet?.engagements ?? [];
      if (currentPetId !== pet?.petKey) return pet;

      return {
        ...pet,
        reservationNotes: pendingReservationNotes ?? pet?.reservationNotes,
        engagements: engagements.map(engagement => {
          return {
            ...engagement,
            metadata: {
              ...engagement?.metadata,
              room: {
                ...engagement?.metadata?.room,
              },
              feedings: applyFoodMedChangesToEngagement({
                engagementFoodOrMeds: engagement?.metadata?.feedings,
                startDatetime: engagement?.startDatetime,
                endDatetime: engagement?.endDatetime,
                foodsOrMeds: foods,
                sanitizeData: sanitizeFoodForItineraryCall,
                timeZone,
              }),
              medications: applyFoodMedChangesToEngagement({
                engagementFoodOrMeds: engagement?.metadata?.medications,
                startDatetime: engagement?.startDatetime,
                endDatetime: engagement?.endDatetime,
                foodsOrMeds: meds,
                sanitizeData: sanitizeMedForItineraryCall,
                timeZone,
              }),
            },
          };
        }),
      };
    }),
  };
};
