import moment from "moment";
import normalizeArrayByProperty from "@/core/utils/normalizeUtils/normalizeArray";
import { VPTClassStatuses } from "@/dux/trainingClassSessions/constants";

function getNormalizedClassSessionData(classSession) {
  const attendeeDetails = classSession?.attendeeDetails || [];

  return {
    normalizedAssociate: classSession?.associate,
    normalizedTrainingClassSession: {
      attendees: attendeeDetails.map(
        (attendee) => `${classSession.classSessionId}-${attendee.customerId}-${attendee.petId}`,
      ),
      associateId: classSession?.associate?.associateId,
      startDateTime: classSession.startDatetime,
      endDateTime: classSession.endDatetime,
      petServiceId: classSession.primaryServiceId,
      classSessionId: classSession.classSessionId,
      meetingLink: classSession.meetingLink,
      className: classSession.className,
      status: classSession.status,
    },
    normalizedPetServices: {
      petServiceId: classSession.primaryServiceId,
      name: classSession.className,
    },
  };
}

function getNormalizedAttendeeData(attendee, { ...args }) {
  const { classSessionId } = args;
  return {
    normalizedTrainingAttendees: {
      attendeeId: `${classSessionId}-${attendee.customerId}-${attendee.petId}`,
      engagementId: attendee.engagementId,
      itineraryId: attendee.itineraryId,
      initialContactMade: attendee.initialContactMade,
      additionalCustomerCommentary: attendee.additionalCustomerCommentary,
      goals: attendee.goals || [],
      petId: attendee.petId,
      customerId: attendee.customerId,
      redemptionStatus: attendee.redemptionStatus,
      customerTimeZone: attendee.customerTimeZone,
    },
    normalizedPets: {
      petId: attendee.petId,
      petName: attendee.petName,
    },
    normalizedCustomers: {
      customerId: attendee.customerId,
      firstName: attendee.customerFirstName,
      lastName: attendee.customerLastName,
      phones: [{ phoneNumber: attendee.customerPhone, isPrimary: true }],
    },
  };
}

export const normalizeGetTrainingClassesResponse = (response) => {
  const trainingSessionAppointmentDates = [];
  const associates = [];
  const trainingClassSessions = [];
  const trainingAttendees = [];
  const pets = [];
  const customers = [];
  const petServices = [];

  response.forEach((entry) => {
    const { day: appointmentDate, classSessions } = entry;
    const classSessionIds = classSessions.map((classSession) => classSession.classSessionId);
    const trainingSessionAppointmentDate = {
      appointmentDate,
      classSessionIds,
    };

    classSessions.forEach((classSession) => {
      const {
        normalizedAssociate,
        normalizedTrainingClassSession,
        normalizedPetServices,
      } = getNormalizedClassSessionData(classSession);

      associates.push(normalizedAssociate);
      trainingClassSessions.push(normalizedTrainingClassSession);
      petServices.push(normalizedPetServices);

      const attendeeDetails = classSession.attendeeDetails || [];

      attendeeDetails.forEach((attendee) => {
        const {
          normalizedTrainingAttendees,
          normalizedPets,
          normalizedCustomers,
        } = getNormalizedAttendeeData(attendee, { classSessionId: classSession.classSessionId });

        trainingAttendees.push(normalizedTrainingAttendees);
        pets.push(normalizedPets);
        customers.push(normalizedCustomers);
      });
    });

    trainingSessionAppointmentDates.push(trainingSessionAppointmentDate);
  });

  return {
    trainingSessionAppointmentDates: normalizeArrayByProperty(
      trainingSessionAppointmentDates,
      "appointmentDate",
    ),
    trainingClassSessions: normalizeArrayByProperty(trainingClassSessions, "classSessionId"),
    trainingAttendees: normalizeArrayByProperty(trainingAttendees, "attendeeId"),
    associates: normalizeArrayByProperty(associates, "associateId"),
    pets: normalizeArrayByProperty(pets, "petId"),
    customers: normalizeArrayByProperty(customers, "customerId"),
    petServices: normalizeArrayByProperty(petServices, "petServiceId"),
  };
};

export const normalizeGetTrainingClassByItineraryResponse = (result) => {
  const appointmentDate = moment(result.startDatetime).format("YYYY-MM-DD");
  const classSessionIds = [result.classSessionId];

  const trainingSessionAppointmentDates = [];
  const associates = [];
  const trainingClassSessions = [];
  const trainingAttendees = [];
  const pets = [];
  const customers = [];
  const petServices = [];

  const trainingSessionAppointmentDate = {
    appointmentDate,
    classSessionIds,
  };

  const {
    normalizedAssociate,
    normalizedTrainingClassSession,
    normalizedPetServices,
  } = getNormalizedClassSessionData(result);

  const attendeeDetails = result.attendeeDetails || [];

  attendeeDetails.forEach((attendee) => {
    const {
      normalizedTrainingAttendees,
      normalizedPets,
      normalizedCustomers,
    } = getNormalizedAttendeeData(attendee, { classSessionId: result.classSessionId });

    trainingAttendees.push(normalizedTrainingAttendees);
    pets.push(normalizedPets);
    customers.push(normalizedCustomers);
  });

  associates.push(normalizedAssociate);
  trainingClassSessions.push(normalizedTrainingClassSession);
  petServices.push(normalizedPetServices);
  trainingSessionAppointmentDates.push(trainingSessionAppointmentDate);

  return {
    trainingSessionAppointmentDates: normalizeArrayByProperty(
      trainingSessionAppointmentDates,
      "appointmentDate",
    ),
    trainingClassSessions: normalizeArrayByProperty(trainingClassSessions, "classSessionId"),
    trainingAttendees: normalizeArrayByProperty(trainingAttendees, "attendeeId"),
    associates: normalizeArrayByProperty(associates, "associateId"),
    pets: normalizeArrayByProperty(pets, "petId"),
    customers: normalizeArrayByProperty(customers, "customerId"),
    petServices: normalizeArrayByProperty(petServices, "petServiceId"),
  };
};

export const normalizeBookedTrainingAppointments = (response, associateId) => {
  const trainingSessionAppointmentDates = [];
  const trainingClassSessions = [];
  const trainingAttendees = [];
  const pets = [];
  const customers = [];
  const petServices = [];

  response.result.classSessions.forEach((classSession) => {
    const { classSessionId, startDatetime } = classSession;
    const trainingSessionAppointmentDate = {
      appointmentDate: moment(startDatetime).format("YYYY-MM-DD"),
      classSessionIds: [classSessionId],
    };

    trainingSessionAppointmentDates.push(trainingSessionAppointmentDate);

    const { normalizedTrainingClassSession, normalizedPetServices } = getNormalizedClassSessionData(
      classSession,
    );
    normalizedTrainingClassSession.associateId = Number(associateId);

    const attendeeDetails = classSession.attendeeDetails || [];

    attendeeDetails.forEach((attendee) => {
      const {
        normalizedTrainingAttendees,
        normalizedPets,
        normalizedCustomers,
      } = getNormalizedAttendeeData(attendee, { classSessionId: classSession.classSessionId });

      trainingAttendees.push(normalizedTrainingAttendees);
      pets.push(normalizedPets);
      customers.push(normalizedCustomers);
    });

    trainingClassSessions.push(normalizedTrainingClassSession);
    petServices.push(normalizedPetServices);
  });

  const normalizedTrainingSessionAppointmentDates = trainingSessionAppointmentDates.reduce(
    (acc, current) => {
      const { appointmentDate, classSessionIds } = current;
      const previouslySavedAppointmentDate = acc[appointmentDate];

      if (!!previouslySavedAppointmentDate) {
        return {
          ...acc,
          [appointmentDate]: {
            appointmentDate,
            classSessionIds: previouslySavedAppointmentDate.classSessionIds.concat(classSessionIds),
          },
        };
      }

      return {
        ...acc,
        [appointmentDate]: {
          appointmentDate,
          classSessionIds,
        },
      };
    },
    [],
  );

  return {
    trainingSessionAppointmentDates: normalizedTrainingSessionAppointmentDates,
    trainingAttendees: normalizeArrayByProperty(trainingAttendees, "attendeeId"),
    trainingClassSessions: normalizeArrayByProperty(trainingClassSessions, "classSessionId"),
    pets: normalizeArrayByProperty(pets, "petId"),
    customers: normalizeArrayByProperty(customers, "customerId"),
    petServices: normalizeArrayByProperty(petServices, "petServiceId"),
  };
};

export function isTrainingClassScheduled(status) {
  return status === VPTClassStatuses.BOOKED || status === VPTClassStatuses.COMPLETED;
}
