import { connect } from "react-redux";
import { isFromPetProfile } from "core/utils/validationUtils/isFromValidation";
import { get } from "lodash/fp";
import { history } from "@/dux/utils/browser/browserHistory";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import RemoveButtonWithLabel from "../common/buttons/RemoveButtonWithLabel";
import { showConfirmationModal } from "../../core/actionCreators/confirmationModalActionCreators";
import { deletePetFood } from "../food/actions/createUpdateDeleteFoodActions";
import { getFoodIsDiscontinuedById } from "../food/foodsSelector";

const mapStateToProps = (state, ownProps) => {
  const { foodIndex, foodId } = ownProps;
  const petId = getCurrentPet(state);
  const isDiscontinued = getFoodIsDiscontinuedById(state, { petId, foodId });
  return {
    foodIndex,
    petId,
    foodId,
    isHidden: isDiscontinued || !isFromPetProfile(get(["location", "pathname"], history)),
    label: `Food ${foodIndex}`,
  };
};

const mapDispatchToProps = dispatch => ({
  deletePetFood: (foodId, petId) => dispatch(deletePetFood({ foodId, petId })),
  showConfirmationModal: args => dispatch(showConfirmationModal(args)),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { foodId, foodIndex, petId } = propsFromState;
    propsFromDispatch.showConfirmationModal({
      header: `Remove food ${foodIndex}`,
      content: "Are you sure want to remove this food?",
      confirmText: `Remove food ${foodIndex}`,
      confirm: () => propsFromDispatch.deletePetFood(foodId, petId),
      cancelText: "Go back",
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RemoveButtonWithLabel);
