import * as React from 'react';
import * as Components from './';

export interface LayoutComposition {
  Box: React.FunctionComponent<Components.BoxProps>;
  Center: React.FunctionComponent<Components.CenterProps>;
  Cluster: React.FunctionComponent<Components.ClusterProps>;
  Grid: React.FunctionComponent<Components.GridProps>;
  ShiftBy: React.FunctionComponent<Components.ShiftByProps>;
  Sidebar: React.FunctionComponent<Components.SidebarProps>;
  Spacer: React.FunctionComponent<Components.SpacerProps>;
  Stack: React.FunctionComponent<Components.StackProps>;
  Switcher: React.FunctionComponent<Components.SwitcherProps>;
  Frame: React.FunctionComponent<Components.FrameProps>;
}

export interface LayoutBaseProps<C extends React.ElementType = 'div'> {
  /** An override of the default HTML tag to cast your component to be any HTML element or React component. */
  as?: C;
  /** Completely removes the component from the DOM. Useful for feature flags or t hide or show
   * a component. */
  // isHidden?: boolean;
  /** For automated QA testing and internal integration testing (data-testid). */
  testid?: string;
}

export type LayoutProps<C extends React.ElementType> = LayoutBaseProps<C> &
  Omit<React.ComponentPropsWithRef<C>, keyof LayoutBaseProps<C>>;

export const LayoutBase = <C extends React.ElementType = 'div'>({
  // isHidden = false,
  testid = '',
  style = {},
  className,
  children,
  as,
}: LayoutProps<C> & LayoutComposition) => {
  // NOTE: commented for now as this was hiddent oeach sub component, but we will remove from sub components at a later date
  // if (isHidden) {
  //   return null;
  // }

  return React.cloneElement(children, { as, style, testid, className });
};

LayoutBase.Box = Components.Box;
LayoutBase.Center = Components.Center;
LayoutBase.Cluster = Components.Cluster;
LayoutBase.ShiftBy = Components.ShiftBy;
LayoutBase.Sidebar = Components.Sidebar;
LayoutBase.Spacer = Components.Spacer;
LayoutBase.Stack = Components.Stack;
LayoutBase.Grid = Components.Grid;
LayoutBase.Switcher = Components.Switcher;
LayoutBase.Frame = Components.Frame;

export { LayoutBase as Layout };
