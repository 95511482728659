import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  boardingDetailsHotelActionTypes,
  updateBoardingDetailsHotelFailure,
  updateBoardingDetailsHotelRequest,
  updateBoardingDetailsHotelSuccess,
} from "./boardingDetailsHotelActions";
import { updatePetProfilePartial } from "../../core/services/systemServicesBooking/profile/petProfile";
import { updatePetSuccess } from "../../core/actionCreators/petsActionCreators";
import { getPetEligibilityAfterChange } from "../eligibility/actions/eligibilityPetActions";

function* onUpdateBoardingDetailsHotel({ customerKey, petId, formValues }) {
  try {
    yield put(updateBoardingDetailsHotelRequest());

    const response = yield call(updatePetProfilePartial, {
      customerKey,
      petKey: petId,
      data: formValues,
    });

    const updatedPrismPet = response.data.result;

    yield put(
      updatePetSuccess({
        pet: {
          petId: Number(petId),
          prismPet: updatedPrismPet,
        },
      }),
    );

    yield put(updateBoardingDetailsHotelSuccess());

    yield put(getPetEligibilityAfterChange({ petId, customerKey }));
  } catch (error) {
    yield put(updateBoardingDetailsHotelFailure({ error }));
  }
}

function* watchUpdateBoardingDetailsHotel() {
  yield takeEvery(
    boardingDetailsHotelActionTypes.UPDATE_BOARDING_DETAILS_HOTEL,
    onUpdateBoardingDetailsHotel,
  );
}

export default function* updateBoardingDetailsHotelSaga() {
  yield all([watchUpdateBoardingDetailsHotel()]);
}
