//@ts-check

/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_HOTEL_MANAGE_ROOM_DETAILS_SUSPENSION_HISTORY_ID =
  "SET_HOTEL_MANAGE_ROOM_DETAILS_SUSPENSION_HISTORY_ID";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 *
 * @param {{suspensionId: string | null}} suspensionId
 * @returns Action creator for room suspensions history
 */
export const setHotelManageRoomDetailsSuspensionHistoryId = ({ suspensionId }) => ({
  type: SET_HOTEL_MANAGE_ROOM_DETAILS_SUSPENSION_HISTORY_ID,
  suspensionId,
});
