// cc: QuickViewAppointment#5;Selectors;Select Items from State

import { get } from "lodash/fp";
import { createSelector } from "reselect";
import getQuickViewState from "./_qickViewState";

export const getAppointmentQuickViewCoordinates = createSelector(
  [getQuickViewState],
  quickViewState => ({
    xLoc: get(["appointmentQuickView", "xLoc"], quickViewState),
    yLoc: get(["appointmentQuickView", "yLoc"], quickViewState),
  }),
);

export const getAppointmentQuickViewIsVisible = createSelector(
  [getQuickViewState],
  quickViewState => get(["appointmentQuickView", "isVisible"], quickViewState),
);

export const getQuickViewAppointmentId = createSelector([getQuickViewState], quickViewState =>
  get(["appointmentQuickView", "petServiceItemId"], quickViewState),
);
