import React from 'react';
import './styles.css';
import { pluck } from '../utils';
import { GLOBAL_TOKENS } from '../scaleToken';
import { CLUSTER_TOKENS } from './clusterTokens';

// Sets the interface for the Box layout custom properties.
interface ClusterProps<C extends React.ElementType> {

  /** A CSS padding value */
  space?: keyof typeof GLOBAL_TOKENS['SCALE'];

  flexWrap?: keyof typeof CLUSTER_TOKENS['FLEX'];

  // Place styles on the component root vs intermediary div
  styleRoot?: any;

  style?: any;
}

  // Include types that are not part of ClusterProps, such as children,
type Props <C extends React.ElementType> = React.PropsWithChildren<ClusterProps<C>> & React.ComponentPropsWithoutRef<C>;

export const LayoutCluster = <C extends React.ElementType>({
  space = 'scale-S1',
  flexWrap = 'flex-wrap',
  children,
  styleRoot,
  style,
  ...props

}: Props<C>): React.ReactElement => {
  return (
    <div
        className="pplt-layout__cluster"
        style={styleRoot}
        {...props}
    >
        <div
            id="cluster_intermediary"
            style={{
              '--setClusterSpace': pluck(GLOBAL_TOKENS.SCALE, space),
              '--setClusterWrap': pluck(CLUSTER_TOKENS.FLEX, flexWrap),
              ...style,
            }}
        >
            {children}
        </div>

    </div>
  );
};
