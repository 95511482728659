import { createSelector } from "reselect";
import { flatten, isEmpty, isEqual } from "lodash/fp";
import { frequencyConstants } from "dux/frequency/FrequencyConstants";
import {
  getMedicationFrequencyById,
  getMedicationFrequencyDatesById,
} from "dux/bookingPage/setMedFrequency/medicationFrequencySelectors";
import { getFoodByPet, getFoods, getPetFoodIds, getPetFoods } from "web/food/foodsSelector";
import {
  getFoodFrequencyById,
  getFoodFrequencyDatesById,
} from "dux/bookingFeeding/setFoodFrequency/foodFrequencySelectors";
import { formatFood } from "web/food/foodUtils";
import isRequiredPetFoodFieldMissing from "web/newFood/utils/isRequiredPetFoodFieldMissing";
import { getAppointmentsFromPendingAppointment } from "web/pendingAppointment/selectors/pendingAppointmentSelectors";
import { getPetCardList } from "@/dux/petCartCard/selectors/petCartCardSelectors";
import {
  getMedications,
  getMedicationsByPet,
  getPetMedicationIds,
  getPetMedications,
} from "@/web/medication/medicationsSelector";
import getPetName from "@/dux/PetName/selectors/getPetName";
import { formatMedication } from "@/web/medication/medicationUtils";
import isRequiredPetMedicationFieldMissing from "@/web/newMedication/utils/isRequiredPetMedicationFieldMissing";
import { selectFoodList } from "@/dux/foodOptionsList/foodOptionsListSelectors";
import { selectMedicationsList } from "@/dux/medications/medicationsSelectors";

// Map through each pet's med that is in cart and determine which pets have medications with missing frequencies.
export const getPetInCartMissingMedFrequency = createSelector(
  [getPetCardList, getAppointmentsFromPendingAppointment, state => state],
  (petsInCart, pendingAppointments, state) => {
    return petsInCart
      .map(pet => {
        return Object.keys(pendingAppointments).find(apptPet => {
          if (apptPet === pet) {
            const meds = getMedicationsByPet(state, { petId: pet });
            return (
              meds &&
              Object.keys(meds).find(medicationId => {
                const frequency = getMedicationFrequencyById(state, { medicationId, petId: pet });
                if (!frequency) {
                  return pet;
                }
                if (frequency === frequencyConstants.MANUAL) {
                  return (
                    isEmpty(getMedicationFrequencyDatesById(state, { medicationId, petId: pet })) &&
                    pet
                  );
                }
                return false;
              })
            );
          }
          return false;
        });
      })
      .filter(pet => pet !== false && pet !== undefined)
      .map(pet => {
        return getPetName(state, { petId: pet });
      })
      .join(", ");
  },
);

// Map through each pet's food that is in cart and determine which pets have foods with missing frequencies.
export const getPetInCartMissingFoodFrequency = createSelector(
  [getPetCardList, getAppointmentsFromPendingAppointment, state => state],
  (petsInCart, pendingAppointments, state) => {
    return petsInCart
      .map(pet => {
        return Object.keys(pendingAppointments).find(apptPet => {
          if (apptPet === pet) {
            const foods = getFoodByPet(state, { petId: pet });
            return (
              foods &&
              Object.keys(foods).find(foodId => {
                const frequency = getFoodFrequencyById(state, { foodId, petId: pet });
                if (!frequency) {
                  return pet;
                }
                if (frequency === frequencyConstants.MANUAL) {
                  return isEmpty(getFoodFrequencyDatesById(state, { foodId, petId: pet })) && pet;
                }
                return false;
              })
            );
          }
          return false;
        });
      })
      .filter(pet => pet !== false && pet !== undefined)
      .map(pet => {
        return getPetName(state, { petId: pet });
      })
      .join(", ");
  },
);

// For pets in the cart, this maps through each medication and determines if it needs to be added or updated to the pet's profile.
export const getSaveToProfileMeds = createSelector(
  [getPetCardList, selectMedicationsList, getMedications, state => state],
  (petCarts, medicationList, medications, state) => {
    const saveToProfileMeds = petCarts.map(petId => {
      const meds = medications[petId];
      // If pet has no medication, return.
      if (isEmpty(meds)) {
        return null;
      }
      return Object.values(meds)
        .map(med => {
          const petMedications = getPetMedications(state, { petId });
          const petMedicationsList = getPetMedicationIds(state, { petId });
          const petMedication = Object.values(petMedications).find(
            petMed => petMed.groupingId === med.groupingId,
          );
          // Format to determine if there are differences between the current profile med and the one an associate is trying to save to the profile.
          const medToSend = formatMedication(med);
          const formattedPetMed = formatMedication(petMedication);
          // If med in state hasn't changed, isn't checked save to profile, or something required is missing, return null.
          if (
            !med.saveToProfile ||
            isEqual(formattedPetMed, medToSend) ||
            isRequiredPetMedicationFieldMissing({ medication: medToSend, medicationList, petId })
          ) {
            return null;
          }
          // Return formatted medication with pet's id to know which pet is med is for and if the medication is new or needs updating.
          return {
            ...medToSend,
            petId,
            isNew: !petMedicationsList.find(
              petMed =>
                petMed === medToSend.petMedicationId || petMed === medToSend.petProfileMedId,
            ),
          };
        })
        .filter(med => med !== null);
    });
    // Flatten so that it's not by pet.
    return flatten(saveToProfileMeds);
  },
);

// For pets in the cart, this maps through each food and determines if it needs to be added or updated to the pet's profile.
export const getSaveToProfileFoods = createSelector(
  [getPetCardList, selectFoodList, getFoods, state => state],
  (petCarts, foodList, foods, state) => {
    const saveToProfileFoods = petCarts.map(petId => {
      const feedings = foods[petId];
      // If pet has no food, return.
      if (isEmpty(feedings)) {
        return null;
      }
      return Object.values(feedings)
        .map(food => {
          const petFoods = getPetFoods(state, { petId });
          const petFoodsList = getPetFoodIds(state, { petId });
          const petFood = Object.values(petFoods).find(
            petFeeding => petFeeding.groupingId === food.groupingId,
          );
          // Format to determine if there are differences between the current profile food, and the one an associate is trying to save to the profile.
          const foodToSend = formatFood(food);
          const formattedPetFood = formatFood(petFood);
          // If food in state hasn't changed, isn't checked save to profile, or something required is missing, return null.
          if (
            !food.saveToProfile ||
            isEqual(formattedPetFood, foodToSend) ||
            isRequiredPetFoodFieldMissing({ food: foodToSend, foodList, petId })
          ) {
            return null;
          }
          // Return formatted food with pet's id to know which pet is food is for and if the food is new or needs updating.
          return {
            ...foodToSend,
            petId,
            isNew: !petFoodsList.find(
              petFeeding =>
                petFeeding === foodToSend.petFoodId || petFeeding === foodToSend.petProfileFoodId,
            ),
          };
        })
        .filter(food => food !== null);
    });
    // Flatten so that it's not by pet.
    return flatten(saveToProfileFoods);
  },
);
