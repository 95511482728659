import React, { Component } from "react";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import styled from "styled-components";
import { Button, Text } from "@prism/psm-ui-components";
import { color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import MainSectionTitle from "../../common/SectionTitle";
import Section from "../../common/Section";
import PetHealthConditions from "../../petParentProfile/pets/petHealthConditions/PetHealthConditionsContainer";
import PetFavorabilitySalonContainer from "../../../dux/petFavorability/PetFavorabilitySalonContainer";
import { SectionWrapper, SectionTitle } from "../petParentDetails/petParentDetailsComponent";
import CheckInOutAvailableBundlesListContainer from "../../../dux/bgm/availableBundlesByPet/availableBundlesList/CheckInOutAvailableBundlesListContainer";
import { SalonGroomingNotesV2 } from "dux/notes/Notes";
import { PetVaccinations } from "@/dux/vaccinations/PetVaccinations";

const ComponentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 713px;
  padding: 2.8125rem 2.1875rem;
  border-right: 1px solid ${color.borderGrey};
  flex: 1;
`;

class PetDetails extends Component {
  componentDidMount() {
    const { petId, customerKey, loadPet, setPetId } = this.props;
    loadPet({ petId, customerKey });
    setPetId(petId);
  }

  handlePetNameClick = () => {
    const {
      router: { navigate },
      customerKey,
      setPetId,
      petId,
    } = this.props;
    setPetId(petId);
    navigate(`/pet-parent-profile/${customerKey}`);
  };

  render() {
    const { isPetActive, petFullName, petId, customerKey } = this.props;

    return (
      <ComponentWrapper>
        <MainSectionTitle title="Pet Details" fontSize="extraBig" />
        <SectionWrapper>
          <Button
            style={{
              fontSize: fontSizes.big,
              padding: 0,
              color: isPetActive ? color.kalEl : color.lightGrey,
            }}
            variant="no-outline"
            onClick={this.handlePetNameClick}
          >
            <Text
              color="text-color-blue"
              align="left"
              fontFamily="text-family-montserrat"
              letterSpacing="text-tracking-normal"
              size="text-size-lg"
            >
              {petFullName}
            </Text>
          </Button>
        </SectionWrapper>
        <Section>
          <PetFavorabilitySalonContainer topLine customerKey={customerKey} petId={petId} />
        </Section>
        <SectionWrapper>
          <SectionTitle>Health conditions</SectionTitle>
          <PetHealthConditions customerKey={customerKey} petId={petId} />
        </SectionWrapper>
        <SectionWrapper>
          <PetVaccinations />
        </SectionWrapper>
        <Section>
          <SalonGroomingNotesV2 />
        </Section>
        <CheckInOutAvailableBundlesListContainer />
      </ComponentWrapper>
    );
  }
}

export default withRouteProps(PetDetails);
