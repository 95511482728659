import { isEmpty } from "lodash/fp";
import {
  getCustomer,
  getPetsNamesAndIdsAndActiveByCustomer,
  getIsCustomerActive,
  getNumberOfActivePets,
} from "core/selectors/entitiesSelector";
import { getPetMissingRequired } from "core/selectors/entities/getPetMissingRequiredSelector";
import { getCustomerCanBook } from "dux/eligibility/selectors/customerCanBookSelectors";
import { getSelectedPet } from "core/selectors/bookingUISelectors";

/**
 * Determines if a customer has been loaded in state
 * @param state
 * @param customerKey
 * @return {boolean}
 */
export const customerIsLoadedInState = (state, customerKey) => {
  const customer = getCustomer(state, { customerKey });
  return !isEmpty(customer);
};

/**
 * Determines a customer has pets
 * @param state
 * @param customerKey
 * @return {boolean}
 */
export const customerHasPets = (state, customerKey) => {
  const customer = getCustomer(state, { customerKey });
  return !!customer?.pets?.length;
};

/**
 * Determines is a customer can book
 * @param state
 * @return {boolean}
 */
export const customerCanBook = state => {
  const canBook = getCustomerCanBook(state);
  return !!canBook;
};

/**
 * Determines if all pets are inactive
 * @param state
 * @param customerKey
 * @return {boolean}
 */
export const allPetsInActive = (state, customerKey) => {
  const pets = getNumberOfActivePets(state, { customerKey });
  return pets === 0;
};

/**
 * Determines if a pet is active
 * @param state
 * @param customerKey
 * @return {boolean}
 */
export const petIsActive = (state, customerKey) => {
  const pets = getPetsNamesAndIdsAndActiveByCustomer(state, { customerKey });
  const selectedPet = getSelectedPet(state);

  const activePet = pets.find(pet => {
    return pet?.petId === selectedPet && pet.isActive;
  });
  return !!activePet;
};

/**
 * Determines if a customer is active
 * @param state
 * @param customerKey
 * @return {boolean}
 */
export const customerIsActive = (state, customerKey) => {
  const isCustomerActive = getIsCustomerActive(state, { customerKey });
  return !!isCustomerActive;
};

/**
 * Determines if required pet fields are missing such as birthDate, speciesId, breedId, genderId, weight
 * @param state
 * @param customerKey
 * @return {boolean}
 */
export const requiredPetFieldsAreMissing = (state, customerKey) => {
  const isRequiredPetFieldMissing = getPetMissingRequired(state, { customerKey });
  return !!isRequiredPetFieldMissing;
};

/**
 * A Helper function to dry up code that was used in multiple places. It is responsible for
 * taking state and a customer key and returning if the booking button(s) should be disabled.
 *
 * @export isBookingDisabled
 * @param {*} state
 * @param {string} customerKey
 * @returns
 */
export function isBookingDisabled(state, customerKey) {
  return (
    !customerIsLoadedInState(state, customerKey) ||
    !customerHasPets(state, customerKey) ||
    !customerCanBook(state) ||
    !petIsActive(state, customerKey) ||
    !customerIsActive(state, customerKey) ||
    requiredPetFieldsAreMissing(state, customerKey)
  );
}

/**
 * A Helper function is responsible for taking state and a customer key
 * and returning if the  book appointment hotel button should be disabled.
 *
 * @export isBookAppointmentHotelDisabled
 * @param {*} state
 * @param {string} customerKey
 * @returns
 */
export function isBookAppointmentHotelDisabled(state, customerKey) {
  return (
    !customerHasPets(state, customerKey) ||
    allPetsInActive(state, customerKey) ||
    !customerIsActive(state, customerKey)
  );
}
