import React from "react";
import styled from "styled-components";
import { color } from "web/common/styles/theme";
import Img from "@/web/common/Img";
import NotificationImage from "@/assets/icons/notificaiton.svg";
import TextComponent from "@/dux/_components/genericLayoutComponents/TextComponent";

const AlertIcon = styled(Img)``;

const Header = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em;
  border-bottom: 1px solid ${color.veryLightGrey};
`;

export default function AlertPanel({ isHidden, componentID, text }) {
  return (
    !isHidden && (
      <div id={componentID} data-testid="AlertPanel">
        <Header>
          <AlertIcon data-testid="AlertPanel__icon" src={NotificationImage} />
          <TextComponent
            data-testid="AlertPanel__text"
            styleType="span"
            text={`${text}`}
            styleObj={{ marginLeft: "0.5em" }}
          />
        </Header>
      </div>
    )
  );
}
