import { connect } from "react-redux";
import { getPetServiceItemsByItineraryWithPetActiveStatus } from "@/core/selectors/entitiesSelector";
import PetsTabs from "@/web/petParentProfile/pets/petsTabs/PetsTabs";

const mapStateToProps = (state, { itineraryId }) => {
  return {
    tabsData: getPetServiceItemsByItineraryWithPetActiveStatus(state, { itineraryId }),
    style: { marginBottom: "0.625rem" },
  };
};

export default connect(mapStateToProps)(PetsTabs);
