import { connect } from "react-redux";

// Route and Route Paths
import { routePaths } from "@/core/constants/routePaths";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

// Components
import MenuButton from "@/web/common/MenuButton";

// Selectors
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";

// Constants
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";

// Feature Flag
import getReportsWorkflowFeatureFlag from "@/web/enableDisableWorkflowFeatureFlag/selectors/reports/getReportsWorkflowFeatureFlag";

const mapStateToProps = state => {
  const isReportsFeatureFlagOn = getReportsWorkflowFeatureFlag(state)?.isHidden;
  const systemBookingFlow = getSystemBookingFlow(state);

  return {
    componentID: "ReportsProfileBtn",
    title: "Reports",
    isHidden: !(!isReportsFeatureFlagOn && systemBookingFlow === systemName.HOTEL),
  };
};

const mapDispatchToProps = (_, { router: { navigate } }) => ({
  onClick: () => {
    navigate(routePaths.REPORTS);
  },
});

export default withRouteProps(connect(mapStateToProps, mapDispatchToProps)(MenuButton));
