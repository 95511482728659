// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-salon-services-grid-button {
    cursor: pointer;
    width: 18.5rem;
}

.psm-salon-services-grid-button:hover {
    color: #1777f2;
    border-color: #1777f2;
}
`, "",{"version":3,"sources":["webpack://./src/dux/_components/salonServicesGridButtonComponent/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".psm-salon-services-grid-button {\n    cursor: pointer;\n    width: 18.5rem;\n}\n\n.psm-salon-services-grid-button:hover {\n    color: #1777f2;\n    border-color: #1777f2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
