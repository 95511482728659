export default {
  LOAD_PRICE_ADJUSTMENT_REASONS: "LOAD_PRICE_ADJUSTMENT_REASONS",
  LOAD_PRICE_ADJUSTMENT_REASONS_REQUEST: "LOAD_PRICE_ADJUSTMENT_REASONS_REQUEST",
  LOAD_PRICE_ADJUSTMENT_REASONS_SUCCESS: "LOAD_PRICE_ADJUSTMENT_REASONS_SUCCESS",
  LOAD_PRICE_ADJUSTMENT_REASONS_FAILURE: "LOAD_PRICE_ADJUSTMENT_REASONS_FAILURE",
  UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON: "UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON",
  UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON_REQUEST: "UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON_REQUEST",
  UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON_SUCCESS: "UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON_SUCCESS",
  UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON_FAILURE: "UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON_FAILURE"
};
