import { put, takeEvery, call, all, select } from "redux-saga/effects";
import { getHotelRoomAvailability } from "dux/hotelRoomAvailability/hotelRoomAvailabilityEndpoints";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import {
  GET_HOTEL_ROOM_AVAILABILITY,
  getHotelRoomAvailabilityRequest,
  getHotelRoomAvailabilitySuccess,
  getHotelRoomAvailabilityFailure,
} from "./hotelRoomAvailabilityActions";
import { roomAvailabilityKey } from "./hotelRoomAvailabilitySelectors";

function* onGetHotelRoomAvailability({ payload }) {
  const { customerKey, petId, roomTypeBucketId, startDateTime, endDateTime } = payload;
  const key = roomAvailabilityKey(roomTypeBucketId, startDateTime, endDateTime);

  try {
    yield put(getHotelRoomAvailabilityRequest());

    const { formatStartForAPI = start => start, formatEndForAPI = end => end } = payload;

    const storeNumber = yield select(getStoreNumber);

    const response = yield call(getHotelRoomAvailability, {
      customerKey,
      petId,
      roomTypeBucketId,
      storeNumber,
      startDateTime: formatStartForAPI(startDateTime),
      endDateTime: formatEndForAPI(endDateTime),
    });
    const result = response?.data?.result;

    yield put(getHotelRoomAvailabilitySuccess({ key, value: result }));
  } catch (error) {
    yield put(getHotelRoomAvailabilityFailure({ key }));
  }
}

function* watchGetHotelRoomAvailability() {
  yield takeEvery(GET_HOTEL_ROOM_AVAILABILITY, onGetHotelRoomAvailability);
}

export default function* hotelRoomAvailabilitySaga() {
  yield all([watchGetHotelRoomAvailability()]);
}
