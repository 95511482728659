export const frequencyConstants = {
  DAILY: "Everyday",
  EOD1: "EOD1",
  EOD2: "EOD2",
  FIRST_DAY: "FirstDay",
  LAST_DAY: "LastDay",
  MANUAL: "Manual",
};

export const frequencyTypes = {
  FOOD: "Food",
  MEDICATION: "Medication",
  ADDON: "Addon",
};

export const frequencyErrors = {
  MANUAL_MISSING_DATES: "Please update manual frequency",
  REQUIRED: "Frequency is missing a required field.",
};
