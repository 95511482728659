import React from "react";

/**
 * A Wrapper to handle a bug/feature in chrome. The mouseLeave and mousse out will not fire when
 * a button is disabled, so wrap a button in this component and handle those events.
 */
export default function QuickViewToolTipsEnterLeaveWrapper(props) {
  const { isHidden, componentID, children, handleMouseEnter, handleMouseLeave } = props;
  if (!isHidden) {
    return (
      <div id={componentID} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
    );
  }

  return null;
}
