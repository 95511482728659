/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_HOTEL_ROOM_MANAGEMENT_SEARCH = "SET_HOTEL_ROOM_MANAGEMENT_SEARCH";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setHotelRoomManagementSearch = payload => ({
  type: SET_HOTEL_ROOM_MANAGEMENT_SEARCH,
  payload,
});