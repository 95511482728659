import agentsActionTypes from "../actionTypes/agentsActionTypes";

export const createAgent = ({ customerKey, formValues }) => ({
  type: agentsActionTypes.CREATE_AGENT,
  customerKey,
  data: formValues
});

export const createAgentRequest = () => ({
  type: agentsActionTypes.CREATE_AGENT_REQUEST
});

export const createAgentSuccess = () => ({
  type: agentsActionTypes.CREATE_AGENT_SUCCESS
});

export const createAgentFailure = ({ error }) => ({
  type: agentsActionTypes.CREATE_AGENT_FAILURE,
  error
});

export const updateAgent = ({ customerKey, agentId, formValues }) => ({
  type: agentsActionTypes.UPDATE_AGENT,
  customerKey,
  agentId,
  data: formValues
});

export const updateAgentRequest = () => ({
  type: agentsActionTypes.UPDATE_AGENT_REQUEST
});

export const updateAgentSuccess = () => ({
  type: agentsActionTypes.UPDATE_AGENT_SUCCESS
});

export const updateAgentFailure = ({ error }) => ({
  type: agentsActionTypes.UPDATE_AGENT_FAILURE,
  error
});

export const deleteAgent = ({ customerKey, agentId }) => ({
  type: agentsActionTypes.DELETE_AGENT,
  customerKey,
  agentId
});

export const deleteAgentRequest = () => ({
  type: agentsActionTypes.DELETE_AGENT_REQUEST
});

export const deleteAgentSuccess = () => ({
  type: agentsActionTypes.DELETE_AGENT_SUCCESS
});

export const deleteAgentFailure = ({ error }) => ({
  type: agentsActionTypes.DELETE_AGENT_FAILURE,
  error
});

export const showAddAgentForm = () => ({
  type: agentsActionTypes.SHOW_ADD_AGENT_FORM
});

export const hideAddAgentForm = () => ({
  type: agentsActionTypes.HIDE_ADD_AGENT_FORM
});

export const setCustomerAndAgentKeys = ({ customerKey, agentId }) => ({
  type: agentsActionTypes.SET_CUSTOMER_AND_AGENT_KEYS,
  customerKey,
  agentId
});

export const clearCustomerAndAgentKeys = () => ({
  type: agentsActionTypes.CLEAR_CUSTOMER_AND_AGENT_KEYS
});

export default {
  createAgent,
  createAgentRequest,
  createAgentSuccess,
  createAgentFailure,
  updateAgent,
  updateAgentRequest,
  updateAgentSuccess,
  updateAgentFailure,
  deleteAgent,
  deleteAgentRequest,
  deleteAgentSuccess,
  deleteAgentFailure,
  showAddAgentForm,
  hideAddAgentForm,
  setCustomerAndAgentKeys,
  clearCustomerAndAgentKeys
};
