import React from "react";
import styled from "styled-components";
import moment from "moment";
import { isEmpty } from "lodash/fp";

import LoadingWrapper from "../../web/common/LoadingWrapper";
import GanttBody from "./ganttBody/GanttBodyContainer";
import { getWeekBeginEndDates } from "../../core/utils/salonHours";
import { formatCalendarDateMoment } from "../../core/utils/dateUtils/formatDateTime";
import AppointmentQuickViewContainer from "./ganttAppointment/AppointmentQuickViewContainer";
import AppointmentPetCardQuickViewContainer from "./ganttAppointment/AppointmentPetCardQickViewContainer";

const StyledGantt = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default class GanttComponent extends React.Component {
  componentDidMount() {
    this.loadSchedulesData();
  }

  componentDidUpdate(prevProps) {
    const { selectedDate } = this.props;
    if (prevProps.selectedDate !== selectedDate) {
      this.checkAndUpdateSchedulesData();
    }
  }

  checkAndUpdateSchedulesData = () => {
    const { selectedDate, salonHours, allAssociateSchedules } = this.props;

    if (!salonHours[selectedDate] || !salonHours[selectedDate].ForDate) {
      this.loadSchedulesData();
    } else if (!allAssociateSchedules || !allAssociateSchedules[selectedDate]) {
      this.loadSchedulesData();
    }
  };

  loadSchedulesData = () => {
    const { selectedDate, loadSalonHoursForWeek, loadAssociatesSchedule } = this.props;
    const { beginDate } = getWeekBeginEndDates(selectedDate);

    loadSalonHoursForWeek();
    loadAssociatesSchedule({
      fromDate: formatCalendarDateMoment(moment(beginDate).startOf("isoWeek")),
    });
  };

  render() {
    const {
      isLoading,
      isWeekly,
      selectedDate,
      salonHours,
      availableAppointments = {},
    } = this.props;

    return (
      <StyledGantt>
        <LoadingWrapper isLoading={isLoading || isEmpty(salonHours)} fullScreenHeight>
          <GanttBody
            availableAppointments={availableAppointments}
            selectedDate={selectedDate}
            isWeekly={isWeekly}
          />

          <AppointmentQuickViewContainer>
            <AppointmentPetCardQuickViewContainer />
          </AppointmentQuickViewContainer>
        </LoadingWrapper>
      </StyledGantt>
    );
  }
}
