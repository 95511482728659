import { hotelHoursActionTypes } from "./hotelHoursActions";

const initialState = {};

const hotelHoursReducer = (state = initialState, action) => {
  switch (action.type) {
    case hotelHoursActionTypes.SET_HOTEL_HOURS:
      return {
        ...state,
        [action?.serviceId]: {
          ...state?.[action?.serviceId],
          ...action.hours,
        },
      };
    default:
      return state;
  }
};

export default hotelHoursReducer;
