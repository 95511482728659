import { connect } from "react-redux";
import { get } from "lodash/fp";
import { getHasInvoiceBeenGenerated } from "../../../../core/selectors/entities/petServiceItemsSelectors";
import { getAppointmentByPet } from "../../../../core/selectors/cartSelectors";
import FlexWrappedCheckBox from "../../../common/formInputs/FlexWrappedCheckBox";
import { getCurrentPet } from "../../../../core/selectors/persistentSelectors";

const mapStateToProps = (state, ownProps) => {
  const appointment = getAppointmentByPet(state, { petId: getCurrentPet(state) });
  const petServiceItemId = get("petServiceItemId", appointment);

  return {
    checked: getHasInvoiceBeenGenerated(state, { petServiceItemId }),
    disabled: true,
    labelPosition: "right",
    label: "Invoice generated"
  };
};

export default connect(mapStateToProps)(FlexWrappedCheckBox);
