// @flow
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import "typeface-lato";
import "typeface-montserrat";

import { store, persistor } from "./core/store";
import { loadEnum } from "./core/actionCreators/enumsActionCreators";
import { isCurrentEnvironment } from "./core/utils/envUtils";
import setAllFeatureFlags from "./core/utils/featureFlagging/toggleAllFeatureFlags";

import App from "./web/App";

/** Initialize and configure Sentry
 *
 * @description As early as possible, we set up and configure
 * the Sentry SDK to start sampling and logging crashes to the
 * application. This can be enabled or disabled with the
 * `SENTRY_ENABLED` environment variable at build time.
 */
Sentry.init({
  dsn: `${window.env.SENTRY_DSN}`,
  environment: `${window.env.APP_ENV}`,
  debug: window.env.SENTRY_ENABLE_DEBUG,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0,
  normalizeDepth: 10,
});

/**
 * The Store is our single source of truth and holds the state tree of our
  application. The redux library provides a store object for us that
  maintains the integrity of our state. We are passing in our Reducer and our
  initial state. By passing in the initial state it provides the store with
  state data and by passing in the Reducer object, the store will provide
  the state and action objects to the reducer function.
 */

// Load enums into store (breeds, species, etc)
store.dispatch(loadEnum());

/* * ----------------------------------------------------------------------- * *\
  With Feature flagging, the user may want, or need a feature flag to be enabled
  when a pages loads/App is initialized, this can be done via a cookie. If the cookie is set
  we will set the state to show all feature flags as on. This will allows a sudo
  persistent state for the feature flags, however not actually have it in persistent
  state as that is not desirable.
\* * ----------------------------------------------------------------------- * */
setAllFeatureFlags(store.dispatch);

// Load associates
// Load mock pets
// store.dispatch(loadPetsByCustomer({ customerKey: 13315 }));
// store.dispatch(loadPetSuccess({pet: mockPet}));

// Change the title to match the appropriate environment
document.title = !isCurrentEnvironment("prod") ? `PRISM - ${window.env.APP_ENV}` : "PRISM";

// Mock Service Worker is an API mocking library that allows you to write mocks
// Reference: https://mswjs.io/
async function enableMocking() {
  if (!(isCurrentEnvironment("DEV") && window.env.MSW_MOCKING === "true")) return;

  const { worker } = await import("./mocks/browser");

  return worker.start({
    onUnhandledRequest: "warn",
  });
}

enableMocking().then(() => {
  /*** 
    By Wrapping the main presentation component, e.g. the <APP /> component, in
    a Provider component, this allows the access to the Store, the Store.dispatch
    function within our container components via the connect() method.
  */
  const root = createRoot(document.getElementById("root"));
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
  );
});
