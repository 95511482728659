import upcomingAndHereActionTypes from "../actionTypes/upcomingAndHereActionTypes";
import { createBatchActions } from "../utils/batchUtils";

export const openFlyout = ({ selectionDOMRect, flyoutType }) => ({
  type: upcomingAndHereActionTypes.OPEN_HERE_OR_COMPLETED_FLYOUT,
  selectionDOMRect,
  flyoutType
});

export const closeFlyout = ({ flyoutType }) => ({
  type: upcomingAndHereActionTypes.CLOSE_HERE_OR_COMPLETED_FLYOUT,
  flyoutType
});

export const setHereOrCompletedFilterApplied = ({ hereOrCompletedFilterApplied }) => ({
  type: upcomingAndHereActionTypes.SET_HERE_OR_COMPLETED_FILTER_APPLIED,
  hereOrCompletedFilterApplied
});

export const setUpcomingSearchText = value => ({
  type: upcomingAndHereActionTypes.SET_UPCOMING_SEARCH_TEXT,
  value
});

export const clearUpcomingSearchText = () => ({
  type: upcomingAndHereActionTypes.CLEAR_UPCOMING_SEARCH_TEXT
});

export const setHereSearchText = value => ({
  type: upcomingAndHereActionTypes.SET_HERE_SEARCH_TEXT,
  value
});

export const clearHereSearchText = () => ({
  type: upcomingAndHereActionTypes.CLEAR_HERE_SEARCH_TEXT
});

export const setUpcomingBoardedGuestChecked = () => ({
  type: upcomingAndHereActionTypes.SET_UPCOMING_BOARDED_GUEST_CHECKED
});

export const clearUpcomingBoardedGuestChecked = () => ({
  type: upcomingAndHereActionTypes.CLEAR_UPCOMING_BOARDED_GUEST_CHECKED
});

export const setHereBoardedGuestChecked = () => ({
  type: upcomingAndHereActionTypes.SET_HERE_BOARDED_GUEST_CHECKED
});

export const clearHereBoardedGuestChecked = () => ({
  type: upcomingAndHereActionTypes.CLEAR_HERE_BOARDED_GUEST_CHECKED
});

export const clearUpcomingAndHereSearch = () =>
  createBatchActions(
    ...[
      clearUpcomingSearchText(),
      clearHereSearchText(),
      clearUpcomingBoardedGuestChecked(),
      clearHereBoardedGuestChecked()
    ]
  );

export default {
  openFlyout,
  closeFlyout,
  setHereOrCompletedFilterApplied,
  setUpcomingSearchText,
  clearUpcomingSearchText,
  setHereSearchText,
  clearHereSearchText,
  setUpcomingBoardedGuestChecked,
  clearUpcomingBoardedGuestChecked,
  setHereBoardedGuestChecked,
  clearHereBoardedGuestChecked,
  clearUpcomingAndHereSearch
};
