/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const POST_CART = "POST_CART";
export const POST_CART_REQUEST = "POST_CART_REQUEST";
export const POST_CART_SUCCESS = "POST_CART_SUCCESS";
export const POST_CART_FAILURE = "POST_CART_FAILURE";
export const REBOOKING_POST_CART = "REBOOKING_POST_CART";

export const PUT_CART = "PUT_CART";
export const PUT_CART_REQUEST = "PUT_CART_REQUEST";
export const PUT_CART_SUCCESS = "PUT_CART_SUCCESS";
export const PUT_CART_FAILURE = "PUT_CART_FAILURE";

export const PATCH_CART_SPECIALS = "PATCH_CART_SPECIALS";
export const PATCH_CART_SPECIALS_REQUEST = "PATCH_CART_SPECIALS_REQUEST";
export const PATCH_CART_SPECIALS_SUCCESS = "PATCH_CART_SPECIALS_SUCCESS";
export const PATCH_CART_SPECIALS_FAILURE = "PATCH_CART_SPECIALS_FAILURE";

export const DELETE_CART_SPECIAL = "DELETE_CART_SPECIAL";
export const DELETE_CART_SPECIAL_REQUEST = "DELETE_CART_SPECIAL_REQUEST";
export const DELETE_CART_SPECIAL_SUCCESS = "DELETE_CART_SPECIAL_SUCCESS";
export const DELETE_CART_SPECIAL_FAILURE = "DELETE_CART_SPECIAL_FAILURE";

export const GET_CART_BY_ID = "GET_CART_BY_ID";
export const GET_CART_BY_ID_REQUEST = "GET_CART_BY_ID_REQUEST";
export const GET_CART_BY_ID_SUCCESS = "GET_CART_BY_ID_SUCCESS";
export const GET_CART_BY_ID_FAILURE = "GET_CART_BY_ID_FAILURE";

export const GET_CART_BY_ITINERARY_ID = "GET_CART_BY_ITINERARY_ID";
export const GET_CART_BY_ITINERARY_ID_REQUEST = "GET_CART_BY_ITINERARY_ID_REQUEST";
export const GET_CART_BY_ITINERARY_ID_SUCCESS = "GET_CART_BY_ITINERARY_ID_SUCCESS";
export const GET_CART_BY_ITINERARY_ID_FAILURE = "GET_CART_BY_ITINERARY_ID_FAILURE";

export const DELETE_CART = "DELETE_CART";
export const DELETE_CART_REQUEST = "DELETE_CART_REQUEST";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_FAILURE = "DELETE_CART_FAILURE";

export const SET_SERVICES_CART = "SET_SERVICES_CART";
export const CLEAR_SERVICES_CART = "CLEAR_SERVICES_CART";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const postCart = cart => ({
  type: POST_CART,
  cart,
});

export const postCartRequest = () => ({
  type: POST_CART_REQUEST,
});

export const postCartSuccess = () => ({
  type: POST_CART_SUCCESS,
});

export const postCartFailure = error => ({
  type: POST_CART_FAILURE,
  error,
});

export const rebookingPostCart = () => ({
  type: REBOOKING_POST_CART,
});

export const putCart = cart => ({
  type: PUT_CART,
  cart,
});

export const putCartRequest = () => ({
  type: PUT_CART_REQUEST,
});

export const putCartSuccess = () => ({
  type: PUT_CART_SUCCESS,
});

export const putCartFailure = error => ({
  type: PUT_CART_FAILURE,
  error,
});

export const patchCartSpecials = ({ setManualSpecials }) => ({
  type: PATCH_CART_SPECIALS,
  setManualSpecials,
});

export const patchCartSpecialsRequest = () => ({
  type: PATCH_CART_SPECIALS_REQUEST,
});

export const patchCartSpecialsSuccess = () => ({
  type: PATCH_CART_SPECIALS_SUCCESS,
});

export const patchCartSpecialsFailure = error => ({
  type: PATCH_CART_SPECIALS_FAILURE,
  error,
});

export const deleteCartSpecial = ({ specialCode, petId }) => ({
  type: DELETE_CART_SPECIAL,
  specialCode,
  petId,
});

export const deleteCartSpecialRequest = () => ({
  type: DELETE_CART_SPECIAL_REQUEST,
});

export const deleteCartSpecialSuccess = () => ({
  type: DELETE_CART_SPECIAL_SUCCESS,
});

export const deleteCartSpecialFailure = error => ({
  type: DELETE_CART_SPECIAL_FAILURE,
  error,
});

export const getCartById = cartId => ({
  type: GET_CART_BY_ID,
  cartId,
});

export const getCartByIdRequest = () => ({
  type: GET_CART_BY_ID_REQUEST,
});

export const getCartByIdSuccess = () => ({
  type: GET_CART_BY_ID_SUCCESS,
});

export const getCartByIdFailure = error => ({
  type: GET_CART_BY_ID_FAILURE,
  error,
});

export const getCartByItineraryId = itineraryId => ({
  type: GET_CART_BY_ITINERARY_ID,
  itineraryId,
});

export const getCartByItineraryIdRequest = () => ({
  type: GET_CART_BY_ITINERARY_ID_REQUEST,
});

export const getCartByItineraryIdSuccess = () => ({
  type: GET_CART_BY_ITINERARY_ID_SUCCESS,
});

export const getCartByItineraryIdFailure = error => ({
  type: GET_CART_BY_ITINERARY_ID_FAILURE,
  error,
});

export const setServicesCart = cart => ({
  type: SET_SERVICES_CART,
  cart,
});

export const clearServicesCart = () => ({ type: CLEAR_SERVICES_CART });

export const deleteCart = ({ cartId, customerKey, clearBookingState }) => ({
  type: DELETE_CART,
  cartId,
  customerKey,
  clearBookingState,
});

export const deleteCartRequest = () => ({
  type: DELETE_CART_REQUEST,
});

export const deleteCartSuccess = () => ({
  type: DELETE_CART_SUCCESS,
});

export const deleteCartFailure = error => ({
  type: DELETE_CART_FAILURE,
  error,
});
