import { pickBy } from "lodash/fp";
import { SET_ENHANCED_SERVICE } from "@/dux/enhancedServicesList/EnhancedServicesListActions";
import cartActionTypes from "@/core/actionTypes/ui/web/cartActionTypes";
import withClearReducer from "@/core/utils/withClearReducer";

const initialState = {
  petId: "",
  petService: "",
  petServiceItemId: "",
  addons: [],
  specials: [],
  timeSlot: "",
  isBoarded: false,
  additionalBookingType: null,
  maxCheckInBool: false,
  maxPerBlockBool: false,
  maxCheckInCount: 1,
  maxPerBlockCount: 1,
  lockToAssociate: false,
  maxPerBlockOrCheckReductionReason: null,
  enhancedServices: null,
};

const petsAppointmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case cartActionTypes.SELECT_PET_SERVICE:
      return {
        ...state,
        petService: action.petServiceId,
      };
    case cartActionTypes.SELECT_TIME_SLOT:
      return {
        ...state,
        timeSlot: action.timeSlot,
      };
    case cartActionTypes.SET_PET_SERVICE_ITEM_ID:
      return {
        petServiceItemId: action.petServiceItemId,
      };
    case cartActionTypes.SET_ADDONS:
      return {
        ...state,
        addons: action.addons,
      };
    case SET_ENHANCED_SERVICE:
      return {
        ...state,
        enhancedServices: action.addOn,
      };
    case cartActionTypes.ADD_ADDON:
      return {
        ...state,
        addons: [...state.addons, action.addon],
      };
    case cartActionTypes.REMOVE_ADDON:
      return {
        ...state,
        addons: state.addons.filter(addon => addon.addOnId !== action.addon),
      };
    case cartActionTypes.SET_STANDALONE:
      return {
        ...state,
        isStandalone: true,
      };
    case cartActionTypes.SET_SPECIALS:
      return {
        ...state,
        specials: action.specials,
      };
    case cartActionTypes.ADD_SPECIAL:
      return {
        ...state,
        specials: [...state.specials, action.special],
      };
    case cartActionTypes.REMOVE_SPECIAL:
      return {
        ...state,
        specials: state.specials.filter(special => special !== action.special),
      };
    case cartActionTypes.SET_ADDITIONAL_BOOKING_TYPE:
      return {
        ...state,
        additionalBookingType: action.additionalBookingType,
      };
    case cartActionTypes.SET_MAX_CHECK_IN_LIMIT:
      return {
        ...state,
        maxCheckInCount: action.maxCheckInCount,
      };
    case cartActionTypes.SET_MAX_PER_BLOCK_LIMIT:
      return {
        ...state,
        maxPerBlockCount: action.maxPerBlockCount,
      };
    case cartActionTypes.SET_MAX_CHECK_IN_BOOL:
      return {
        ...state,
        maxCheckInBool: action.maxCheckInBool,
      };
    case cartActionTypes.SET_MAX_PER_BLOCK_BOOL:
      return {
        ...state,
        maxPerBlockBool: action.maxPerBlockBool,
      };
    case cartActionTypes.SET_IS_BOARDED:
      return {
        ...state,
        isBoarded: true,
      };
    case cartActionTypes.CLEAR_IS_BOARDED:
      return {
        ...state,
        isBoarded: initialState.isBoarded,
      };
    case cartActionTypes.SET_LOCK_TO_ASSOCIATE:
      return {
        ...state,
        lockToAssociate: action.lockToAssociate,
      };
    case cartActionTypes.SET_APPOINTMENT_NOTE:
      return {
        ...state,
        appointmentNote: action.appointmentNote,
      };
    case cartActionTypes.SET_MAX_PER_BLOCK_REDUCTION_REASON:
      return {
        ...state,
        maxPerBlockOrCheckReductionReason: action.maxPerBlockOrCheckReductionReason,
      };
    default:
      return state;
  }
};

const cartReducer = (state = {}, action) => {
  const petId = Number(action.petId);
  switch (action.type) {
    case cartActionTypes.ADD_ADDON:
    case cartActionTypes.ADD_SPECIAL:
    case cartActionTypes.REMOVE_ADDON:
    case cartActionTypes.REMOVE_SPECIAL:
    case cartActionTypes.SELECT_PET_SERVICE:
    case cartActionTypes.SELECT_TIME_SLOT:
    case cartActionTypes.SET_SPECIALS:
    case cartActionTypes.SET_ADDONS:
    case SET_ENHANCED_SERVICE:
    case cartActionTypes.SET_PET_SERVICE_ITEM_ID:
    case cartActionTypes.SET_ADDITIONAL_BOOKING_TYPE:
    case cartActionTypes.SET_MAX_CHECK_IN_LIMIT:
    case cartActionTypes.SET_MAX_PER_BLOCK_LIMIT:
    case cartActionTypes.SET_MAX_CHECK_IN_BOOL:
    case cartActionTypes.SET_MAX_PER_BLOCK_BOOL:
    case cartActionTypes.SET_STANDALONE:
    case cartActionTypes.SET_IS_BOARDED:
    case cartActionTypes.CLEAR_IS_BOARDED:
    case cartActionTypes.SET_LOCK_TO_ASSOCIATE:
    case cartActionTypes.SET_APPOINTMENT_NOTE:
    case cartActionTypes.SET_MAX_PER_BLOCK_REDUCTION_REASON:
      return {
        ...state,
        [petId]: {
          ...petsAppointmentsReducer(state[petId] ? { ...state[petId] } : undefined, action),
          petId,
        },
      };
    case cartActionTypes.REMOVE_APPOINTMENT_FROM_CART:
      return pickBy((value, petId) => Number(petId) !== action.petId, state);
    default:
      return state;
  }
};

export default withClearReducer(cartReducer, cartActionTypes.CLEAR_CART);
