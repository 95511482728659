import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { getCustomer, getItinerary } from "../../../../core/selectors/entitiesSelector";
import { getSelectedItinerary } from "../../../../core/selectors/bookingUISelectors";
import AppointmentSetComponent from "./AppointmentSetComponent";
import { getVaccinationVerification } from "../../../../core/selectors/ui/vaccinations/vaccinationsSelectors";
import { routePaths } from "../../../../core/constants/routePaths";
import { hideBookingModal } from "../../../../core/actionCreators/bookingActionCreators";
import { loadInvoice } from "../../../../core/actionCreators/itinerariesActionCreators";
import { showConfirmationModal } from "../../../../core/actionCreators/confirmationModalActionCreators";
import { hideCheckInOutButtonModal } from "../../../../core/actionCreators/checkInOutActionCreator";
import { setSystemBookingFlowType } from "../../../setSystemType/actions/setSystemTypeActions";
import { getSystemType } from "../../../setSystemType/selectors/setSystemTypeSelectors";

const mapStateToProps = (state, ownProps) => {
  const {
    isModified,
    router: {
      params: { itineraryId },
    },
  } = ownProps;
  const selectedItinerary = getSelectedItinerary(state);
  const itinerary = getItinerary(state, {
    itineraryId: isModified ? itineraryId : selectedItinerary,
  });

  return {
    itinerary,
    customer: getCustomer(state, ownProps) || {},
    petServiceItemsIds: itinerary && itinerary.petServiceItems ? itinerary.petServiceItems : [],
    systemType: getSystemType(state),
    verificationMessage: getVaccinationVerification(state, {
      itineraryId: isModified ? itineraryId : selectedItinerary,
    }),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    router: { navigate, params },
  } = ownProps;
  return {
    profileClick: () => {
      navigate(`/pet-parent-profile/${params.customerKey}`);
      dispatch(hideBookingModal());
    },
    doneClick: systemType => {
      dispatch(hideBookingModal());
      dispatch(hideCheckInOutButtonModal());
      dispatch(setSystemBookingFlowType({ systemBookingFlow: systemType }));
      navigate(routePaths.DASHBOARD);
    },
    onPayNow: () => {
      const { customerKey, itineraryId } = ownProps;
      dispatch(loadInvoice({ customerKey, itineraryId }));
    },
    showConfirmationModal: args => dispatch(showConfirmationModal(args)),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleProfileClick: () => {
    propsFromDispatch.profileClick();
  },

  handleDoneClick: () => {
    const { systemType } = propsFromState;
    propsFromDispatch.doneClick(systemType);
  },
});

export default withRouteProps(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(AppointmentSetComponent),
);
