import isEmpty from "lodash/isEmpty";
import { buildAddonForAPICall, buildAddonForPatchCall } from "./buildAddonForAPICall";
import { newAddonFromExisting } from "./newAddonFromExisting";
import { checkFrequencyHasDay } from "../frequencyUtils/checkFrequencyHasDay";

/**
 * Helper to format addons in engagements for itinerary calls (used for overnight and single day reservations)
 * @memberOf Utils.Engagement
 * @function
 * @name checkIfShouldModify
 * @param {{ addOnProductNumber: string, isAutoApplyAddOn: boolean }} existingAddon
 * @param {string} addonToEditId - product number of addon to edit
 * @param {string[]} childProductNumbers - array of productNumbers for addons included on the addonToEdit
 * @returns {boolean} - true if the given addon should be modified, false otherwise
 * @example checkIfShouldModify(existingAddon, addonToEditId, childProductNumbers)
 */
export const checkIfShouldModify = (addonToCheck = {}, addonToEditId, childProductNumbers) => {
  const { addOnProductNumber, isAutoApplyAddOn } = addonToCheck;
  const isEditAddon = addonToEditId === addOnProductNumber;
  const isEditChild =
    isAutoApplyAddOn &&
    childProductNumbers?.some(num => num.toString() === addOnProductNumber.toString());
  return isEditAddon || isEditChild;
};

/**
 * Util to format addons in engagements for itinerary calls (used for overnight and single day reservations)
 * @memberOf Utils.Engagement
 * @function
 * @name buildEngagementsWithUpdatedAddons
 * @param {Object} args
 * @param {Object[]} args.petEngagements - all engagements for given pet from itinerary
 * @param {Object} args.petAddons - for a PUT payload this should be all addons for current pet,
 *        for a PATCH it should contain at least the addon to edit and it's children if any
 * @param {string} args.addonToEditId - product number of addon to modify with given instructions & frequency
 * @param {{ [addonId: string]: { notes: string }}} args.instructions - comment to add to addon
 * @param {string} args.frequency - frequency to add to addon
 * @param {string[]} args.dates - dates for which engagements the addon should be included on
 * @param {function} args.buildAddonFn - function to update individual addon objects
 * @param {string} args.timeZone
 * @returns {Object[]} - engagements data for patch call
 * @example const engagements = buildEngagementsWithUpdatedAddons({
          petEngagements,
          petAddons,
          addonToEditId,
          instructions,
          frequency,
          dates,
          buildAddonFn,
          timeZone
        });
 */
export const buildEngagementsWithUpdatedAddons = ({
  petEngagements = [],
  petAddons = [],
  addonToEditId,
  instructions = {},
  frequency,
  dates,
  buildAddonFn = buildAddonForAPICall,
  timeZone,
}) => {
  const addonToEdit = petAddons?.find(
    ({ addOnProductNumber }) => addOnProductNumber === addonToEditId,
  );
  const childProdNums = addonToEdit?.includes?.products?.map(({ productNumber }) => productNumber);

  return petEngagements
    .map(({ engagementId, addOns = [], startDatetime } = {}) => ({
      engagementId,
      addOns: petAddons
        .map(existingAddon => {
          const groupingId = existingAddon?.groupingId;
          const shouldModify = checkIfShouldModify(existingAddon, addonToEditId, childProdNums);
          const isDateInFrequency = checkFrequencyHasDay(dates, startDatetime, timeZone);

          // Short circuit if this engagement isn't in the addons frequency dates
          if (shouldModify && !isDateInFrequency) return {};

          const matchingAddonFromEngagement = addOns?.find(
            addon => addon?.groupingId === groupingId,
          );

          // If this addon isn't one we are modifying then return as is from engagement
          if (!shouldModify) return matchingAddonFromEngagement ?? {};

          // If there isn't an addon in this engagement with the same groupingId then use existing to build a new one
          const isNewAddon = isEmpty(matchingAddonFromEngagement);

          // Short circuit if addon is auto applied and not on this engagement, SF will auto apply onto correct engagements
          if (isNewAddon && existingAddon?.isAutoApplyAddOn) return {};

          const newAddon = newAddonFromExisting(existingAddon);
          const addonToReturn = isNewAddon ? newAddon : matchingAddonFromEngagement;

          // If addon is auto applied then don't update it's frequency, only update notes
          const frequencyToReturn = addonToReturn?.isAutoApplyAddOn ? undefined : frequency;

          return buildAddonFn({ addon: addonToReturn, instructions, frequency: frequencyToReturn });
        })
        .filter(addon => !isEmpty(addon)),
    }))
    .filter(engagement => !isEmpty(engagement));
};

/**
 * Util to format addons in engagements for itinerary PUT calls
 * @memberOf Utils.Engagement
 * @function
 * @name buildEngagementAddonUpdatesForPut
 * @param {Object} args - see {@link Utils.Engagement.buildEngagementsWithUpdatedAddons} for more details
 * @returns {Object[]} - engagements data for PUT call
 * @example const engagements = buildEngagementAddonUpdatesForPut({
          petEngagements,
          petAddons,
          addonToEditId,
          instructions,
          frequency,
          dates,
        });
 */
export const buildEngagementAddonUpdatesForPut = args =>
  buildEngagementsWithUpdatedAddons({ ...args, buildAddonFn: buildAddonForAPICall });

/**
 * Util to format addons in engagements for itinerary PATCH calls
 * @memberOf Utils.Engagement
 * @function
 * @name buildEngagementAddonUpdatesForPatch
 * @param {Object} args - see {@link Utils.Engagement.buildEngagementsWithUpdatedAddons} for more details
 * @returns {Object[]} - engagements data for PATCH call
 * @example const engagements = buildEngagementAddonUpdatesForPatch({
          petEngagements,
          petAddons,
          addonToEditId,
          instructions,
          frequency,
          dates,
        });
 */
export const buildEngagementAddonUpdatesForPatch = args =>
  buildEngagementsWithUpdatedAddons({ ...args, buildAddonFn: buildAddonForPatchCall })?.filter(
    engagement => !isEmpty(engagement?.addOns),
  );
