import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { getSelectedPet } from "../../../core/selectors/bookingUISelectors";
import { getAppointmentByPet } from "../../../core/selectors/cartSelectors";
import { getAvailableBundlesForAllPetsByCustomer } from "../../../dux/bgm/availableBundlesByPet/availableBundlesByPetActions";
import { createLoadingSelector } from "../../../core/selectors/utils";
import servicesSelectionActionTypes from "../../../core/actionTypes/servicesSelectionActionTypes";
import SalonServicesComponent from "../../features/services/SalonServices";
import { getCurrentCustomerKey } from "../../../core/selectors/persistent/customer/customerSelectors";
import {
  isFromBooking,
  isFromStandalone,
} from "../../../core/utils/validationUtils/isFromValidation";
import { getSystemBookingFlow } from "../../setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../../setSystemType/constants/setSystemTypeConstants";
import getIsBGMWorkflowFeatureFlagHidden from "../../enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import {
  getBulkPackageOfferings,
  clearSelectedBulkPackageOfferings,
} from "../../../dux/bgm/bulkPackageOfferings/bulkPackageOfferingsActions";
import { loadStoreServicesForPet } from "../../../core/actionCreators/servicesSelectionActionCreator";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import getFTCOWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import { getPackageOfferType } from "@/dux/bgm/packageSelectors";

const mapStateToProps = (state, ownProps) => {
  const selectedPet = getSelectedPet(state);
  const petId = ownProps.petId || selectedPet;
  const appointment = selectedPet && getAppointmentByPet(state, { petId });
  const pathname = ownProps?.router?.location?.pathname;
  return {
    selectedPet,
    appointment,
    customerKey: getCurrentCustomerKey(state),
    isBGMFeatureWorkflowEnabled: !getIsBGMWorkflowFeatureFlagHidden(state),
    isFTCOFeatureWorkflowEnabled: !getFTCOWorkflowFeatureFlagHidden(state),
    isServicesLoading: createLoadingSelector([
      servicesSelectionActionTypes.LOAD_STORE_SERVICES_FOR_PET,
    ])(state),
    selectedPetService: appointment && appointment.petService,
    componentID: "salonServices-component",
    isHidden: getSystemBookingFlow(state) !== systemName.SALON,
    isBookingFlow: isFromBooking(pathname),
    isStandaloneFlow: isFromStandalone(pathname),

    // Need to use these props to pass to get packages endpoint
    storeNumber: getStoreNumber(state),
    petId: getSelectedPet(state),
    offerType: getPackageOfferType(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchGetAvailableBundlesForAllPetsByCustomer: ({ customerKey }) =>
    dispatch(getAvailableBundlesForAllPetsByCustomer({ customerKey })),
  handleGetPackageOfferings: ({ storeNumber, customerKey, petId, offerType }) =>
    dispatch(getBulkPackageOfferings({ storeNumber, customerKey, petId, offerType })),
  loadServicesForPet: ({ customerKey, petId, isStandalone }) => {
    if (petId) dispatch(loadStoreServicesForPet({ customerKey, petId, isStandalone }));
  },
  clearSelectedBulkPackageOfferings: () => dispatch(clearSelectedBulkPackageOfferings()),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  getAvailableBundlesForAllPetsByCustomer: () =>
    propsFromDispatch.dispatchGetAvailableBundlesForAllPetsByCustomer({
      customerKey: propsFromState.customerKey,
    }),

  getPackageOfferings: () =>
    propsFromDispatch.handleGetPackageOfferings({
      storeNumber: propsFromState.storeNumber,
      customerKey: propsFromState.customerKey,
      petId: propsFromState.petId,
      offerType: propsFromState.offerType,
    }),
});

export default withRouteProps(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(SalonServicesComponent),
);
