import { connect } from "react-redux";
import { createLoadingSelector } from "../../../core/selectors/utils";
import customersActionTypes from "../../../core/actionTypes/customersActionTypes";
import salonActionTypes from "@/core/actionTypes/salonActionTypes";
import vetsActionTypes from "@/core/actionTypes/vetsActionTypes";
import ResultListComponent from "./resultListComponent";
import { resultListFactory } from "web/searchSalonSRC/resultList/resultListFactory/resultListFactory";
import { getSearchResultsToDisplay } from "core/selectors/searchSalonSRCSelector";

const mapStateToProps = state => {
  const resultsToDisplay = getSearchResultsToDisplay(state);

  return {
    isLoading: createLoadingSelector([
      customersActionTypes.LOAD_CUSTOMERS,
      salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES,
      vetsActionTypes.LOAD_VET_SEARCH_RESULTS,
      salonActionTypes.LOAD_SALON_INFO_WITH_SERVICES_SUCCESS,
    ])(state),

    itemsToDisplay: resultListFactory(resultsToDisplay),
  };
};

export default connect(mapStateToProps)(ResultListComponent);
