//@ts-check
import React, { useState } from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { startCase } from "lodash/fp";
import { getCleanedField } from "@/core/utils/stringUtils/stringModificationUtils";
import { abbreviateDelimitedString } from "@/core/utils/stringManipulationUtils/abbreviateString";

/**
 *  React view component that dynamically builds a print label for Food or medications
 *
 *  @summary location where the view component is primarily displayed, e.g. Located on the associate-scheduling page.
 *  @memberOf Views.Store
 *  @function
 *  @name FoodAndMedLabel
 *  @param {Object } props - props passed into the view component
 *  @returns {JSX.Element}
 *  @todo - optional todo
 *  @example
 *
 *  <FoodAndMedLabel />
 */
export const FoodAndMedLabel = props => {
  // Short circuit view if it isHidden
  const { isHidden } = props;
  if (isHidden) return null;

  // value props
  const { componentId, labelData } = props;

  const numOfRows = labelData.length;

  return (
    <LayoutBox padding="scale-0" id={componentId} className="prism-food-and-med-label">
      <table>
        <tbody>
          {labelData.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {row.map((rowItem, colIndex) => {
                  /*
                    Get the kay name for each row item
                  */
                  const keys = Object.keys(rowItem);
                  const keyName = keys[0];

                  /*
                    Target the 1st to last cell
                  */
                  const isTargetRow = rowIndex > numOfRows - 4;
                  const is1stCol = colIndex === 0;
                  const isTargetCell = isTargetRow && is1stCol;

                  /* 
                    Note for colSpan, we check if its the last few cells, if so we need to 
                    set the colSpan to 2, if not all others have to be set to 1, if not colSpan
                    will not work. 
                  */
                  return (
                    <td
                      colSpan={isTargetCell ? 2 : 1}
                      className={`prism-food-and-med-label__cell ${
                        colIndex === 0 ? "prism-food-and-med-label__cell--short" : ""
                      }`}
                      key={colIndex}
                    >
                      <span className="prism-food-and-med-label__key">{`${startCase(
                        keyName,
                      )}: `}</span>
                      <span>{`${rowItem[keyName] ?? ""} `}</span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the PrintFoodAndMedLabel
 *
 * @see {@link PrintFoodAndMedLabel}
 * @summary located at the /reports/label
 * @memberOf Views.Store
 * @function
 * @name  PrintFoodAndMedLabel
 * @param { Object } props
 * @param { Type } props.labelData - data for a single label.
 * @returns {JSX.Element|null}
 * @todo - optional todo
 *
 * @example <PrintFoodAndMedLabel labelData={labelData} />
 */
export const PrintFoodAndMedLabel = connect((state, { labelData }) => {
  return {
    componentId: "PrintFoodAndMedLabel",
    labelData,
  };
})(FoodAndMedLabel);
