import { connect } from "react-redux";
import AssociatesModals from "./associatesModals";
import { hideAssociateModal } from "../../../core/actionCreators/associateActionCreator";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(hideAssociateModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssociatesModals);
