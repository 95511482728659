import { get } from "lodash/fp";
import { APPOINTMENT_STATUS } from "../../constants";
import { VOIDED_STATUS } from "../../constants/schedulesConstants";

/**
 * Responsible for determining if an appointment should be shown for a particular store.
 * @param {object} petServiceItem
 * @param {number} storeNumber
 * @returns {boolean}
 */
export default function filterValidAppointmentsForStore(petServiceItem, storeNumber) {
  return (
    get("storeNumber", petServiceItem) === storeNumber &&
    get("status", petServiceItem) !== APPOINTMENT_STATUS.CANCELED &&
    get("itineraryStatus", petServiceItem) !== VOIDED_STATUS
  );
}
