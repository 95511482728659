import matchStringOrArray from "../utils/matchUtils/stringOrArray";
import { omit } from "lodash/fp";

function createEntitiesReducer({
  updateAction,
  updateManyAction,
  loadOneAction,
  deleteAction,
  deleteManyAction,
  deleteAll,
  loadManyAction,
  transform = obj => ({ ...obj }),
  idProperty = "id",
  mergeStrategy = (oldObj, newObj) => ({ ...oldObj, ...newObj })
}) {
  function transformWithLastFetch(obj) {
    return {
      ...transform(obj),
      lastFetch: Date.now()
    };
  }
  return (state = {}, action) => {
    const { type, payload } = action;
    if (matchStringOrArray(updateAction, type) || matchStringOrArray(loadOneAction, type)) {
      return {
        ...state,
        // Custom merging strategy through mergeStrategy prop - defaults to shallow merging
        [payload[idProperty]]: mergeStrategy(
          state[payload[idProperty]],
          transformWithLastFetch(payload),
        ),
      };
    } else if (matchStringOrArray(updateManyAction, type)) {
      return mergeStrategy(state, payload);
    } else if (matchStringOrArray(loadManyAction, type)) {
      const newState = { ...state };
      Object.keys(payload).forEach(key => {
        // Custom merging strategy through mergeStrategy prop - defaults to shallow merging
        newState[key] = mergeStrategy(newState[key], transformWithLastFetch(payload[key]));
      });
      return newState;
    } else if (matchStringOrArray(deleteAction, type)) {
      return omit([payload], state);
    } else if (matchStringOrArray(deleteManyAction, type)) {
      // remove or omit a single or list of items based on Id number
      return omit(payload, state);
    } else if (matchStringOrArray(deleteAll, type)) {
      // will remove all items and just return a simple empty object
      // effectively clearing the entity
      return {};
    }
    return state;
  };
}

export default createEntitiesReducer;
