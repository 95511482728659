import React from "react";
import { connect } from "react-redux";
import { GenericStyledPopOver } from "dux/_components/selectServiceButton/GenericStyledPopOver";
import { Button } from "@prism/psm-ui-components";
import { LayoutBox } from "@/layout/box/Box";
import getAppointmentMetaDataByPetId from "core/selectors/pet/getAppointmentMetaDataByPetId";
import { getCurrentItinerary } from "core/selectors/checkInOutSelector";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getAppliedSpecials } from "core/selectors/entitiesSelector";
import { getCurrentPet, getIsSRCAgent } from "core/selectors/persistentSelectors";
import { difference, isEmpty } from "lodash/fp";
import { getAvailableSpecialsByPetServiceItem } from "core/selectors/dynamicPricesSelector";
import { getCartSpecialsByPet } from "core/selectors/cartSelectors";
import { color } from "web/common/styles/theme";
import { postSpecials, removeSpecials } from "core/actionCreators/specialsActionCreator";
import { MockFlyoutContent } from "dux/_components/selectServiceButton/mockFlyoutContent";
import {
  HotelBookingApplySpecialsList,
  HotelCheckInApplySpecials,
} from "dux/applySpecial/ApplySpecialsList";
import { createLoadingSelector } from "core/selectors/utils";
import { GET_HOTEL_ITINERARY } from "dux/hotelItinerary/hotelItineraryActions";
import {
  LOAD_HOTEL_MANUAL_SPECIALS,
} from "dux/applySpecial/applyManaulSpecialsHotelActions";
import { selectIsReservationDisabled } from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { DELETE_CART_SPECIAL, PATCH_CART_SPECIALS } from "dux/servicesCart/servicesCartActions";

const textCTA = ({ disabled, onClick, label }) => {
  return (
    <Button disabled={disabled} size="xl" variant="link" onClick={onClick}>
      {label || "Apply Specials"}
    </Button>
  );
};

export const ApplySpecialCTA = props => {
  const { isHidden, componentId, FlyoutContent, place, buttonLabel, flyoutProps, disabled } = props;

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <GenericStyledPopOver
        disabled={disabled}
        ctaLabel={buttonLabel}
        place={place}
        FlyoutContent={FlyoutContent}
        CTA={textCTA}
        flyoutProps={flyoutProps}
      />
    </LayoutBox>
  );
};

// SALON CONTAINER ----------------------------------------------------------------------------------------
// Note: this is not being used, SalonApplySpeicalsList is
export const SalonApplySpecial = connect(
  state => {
    const petId = getCurrentPet(state);
    const {
      petServiceItem,
      petServiceItemId,
      isEditable,
      appointment,
    } = getAppointmentMetaDataByPetId(state, {
      petId,
    });
    const itineraryId = getCurrentItinerary(state);
    const customerKey = getCurrentCustomerKey(state);
    const appliedSpecials = petServiceItem && getAppliedSpecials(state, { itineraryId });
    const isSRCAgent = getIsSRCAgent(state);

    return {
      componentId: "SalonApplySpecial",
      itineraryId,
      appliedSpecials,
      isHidden: isEmpty(petServiceItem),
      customerId: customerKey,
      disabled: !isEditable || isSRCAgent,
      options: getAvailableSpecialsByPetServiceItem(state, { petServiceItemId }),
      selectedOptions: appointment && getCartSpecialsByPet(state, { petId }),
      text: "Apply Special",
      styleObj: { color: color.kalEl },
      place: "left",
      FlyoutContent: MockFlyoutContent, // the ApplySpecialsList component needs to be added back in  via DI in the ApplySpecialCTA
      /*
        what was refactored out of the ApplySpecialCTA:
         <ApplySpecialsList
            idProperty="code"
            options={options}
            selectedOptions={selectedOptions}
            closeFlyout={specials => {
              this.setState({ showSpecials: false });
              closeFlyout(specials);
            }}
          />
       */
    };
  },

  dispatch => {
    return {
      dispatchPostSpecials: ({ customerId, itineraryId, specials }) =>
        dispatch(postSpecials({ customerId, itineraryId, specials })),
      dispatchRemoveSpecials: ({ customerId, itineraryId, specials }) =>
        dispatch(removeSpecials({ customerId, itineraryId, specials })),
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      itineraryId,
      customerId,
      appliedSpecials,
      FlyoutContent,
      place,
      isHidden,
    } = mapProps;
    const { dispatchPostSpecials, dispatchRemoveSpecials } = dispatchProps;

    return {
      // control what props get passed to the view
      componentId,
      FlyoutContent,
      place,
      isHidden,

      // actions to pass to view
      closeFlyout: specials => {
        const specialsToAdd = difference(specials, appliedSpecials);
        const specialsToRemove = difference(appliedSpecials, specials);

        if (specialsToAdd.length) {
          dispatchPostSpecials({
            itineraryId,
            customerId,
            specials: specialsToAdd,
          });
        }

        if (specialsToRemove.length) {
          dispatchRemoveSpecials({
            itineraryId,
            customerId,
            specials: specialsToRemove,
          });
        }
      },
    };
  },
)(ApplySpecialCTA);

// HOTEL CHECK IN CONTAINER ----------------------------------------------------------------------------------------
export const HotelApplySpecial = connect(state => {
  const isReservationDisabled = selectIsReservationDisabled(state);
  const isLoading = createLoadingSelector([GET_HOTEL_ITINERARY, LOAD_HOTEL_MANUAL_SPECIALS])(state);
  return {
    componentId: "HotelApplySpecial",
    disabled: isReservationDisabled || isLoading,
    place: "left",
    FlyoutContent: HotelCheckInApplySpecials,
  };
})(ApplySpecialCTA);

export const HotelBookingApplySpecial = connect((state, { petId }) => {
  return {
    componentId: "HotelApplySpecial_Booking",
    label: "Apply Specials",
    place: "right",
    FlyoutContent: HotelBookingApplySpecialsList,
    flyoutProps: { petId },
    disabled: createLoadingSelector([DELETE_CART_SPECIAL, PATCH_CART_SPECIALS])(state),
  };
})(ApplySpecialCTA);
