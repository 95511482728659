import { isNil } from "lodash/fp";

/** @module normalizeFormInput */

/**
 * A Utils function to normalize form input.  If value is null or undefined, default to empty string.
 * @exports normalizeFormInput
 * @param {object} formData - The form form values object to get form field from
 * @param {string} path - The path (object property) to parse
 * @param {string} defaultValue - Default value to set if path is null or undefined. Empty string if argument is not provided.
 * @returns {Object}
 */
export default function normalizeFormInput(formData, path, defaultValue = "") {
  return isNil(formData && formData[path]) ? defaultValue : formData[path];
}
