//@ts-check
export const EXCEPTION_STATUS = {
  scheduled: "Scheduled",
  canceled: "Canceled",
  completed: "Completed",
};

export const EXCEPTION_TABLE_SIZE = 12;

export const exceptionTableHeaderColumns = [
  "Status",
  "Room Type Bucket",
  "Start Date",
  "End Date",
  "Overbooking %",
  "Actions",
];

export const exceptionTableHeaderColumnKeys = [
  "status",
  "roomTypeBucketName",
  "startDate",
  "endDate",
  "overBookingPercentage",
];
