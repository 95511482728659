import React from "react";
import Section from "web/common/Section";
import LoadingWrapper from "web/common/LoadingWrapper";
import { LayoutBox } from "@/layout/box/Box";
import BundlePackageDetails from "../../../bgm/availableBundlesByPet/availableBundlesList/BundlePackageDetailsContainer";

export const AvailableBundlesListComponent = ({
  isHidden,
  componentId,
  isLoading,
  idEnum,
  bundles = [],
  topLine,
  padding,
}) => {
  return !isHidden ? (
    <Section topLine={topLine} padding={padding} title="Bulk Packages" componentId={componentId}>
      {bundles.map(({ bundleNumber }) => {
        return (
          <LayoutBox key={bundleNumber} padding="scale-0" style={{ marginBottom: "2.5rem" }}>
            <LoadingWrapper isLoading={isLoading} displayContainer="block">
              <LayoutBox
                borderColor="mediumGrey"
                style={{
                  padding: "1rem",
                  borderWidth: `1px`,
                  borderRadius: "3px",
                }}
              >
                <BundlePackageDetails bundleNumber={bundleNumber} idEnum={idEnum} />
              </LayoutBox>
            </LoadingWrapper>
          </LayoutBox>
        );
      })}
    </Section>
  ) : null;
}
