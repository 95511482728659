import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Update a pet service schedule.
 * From the System Services Booking API
 * - Update a pet service schedule
 * @param {*} { customerKey, itineraryId, engagementId, petServiceItemId, data }
 * @returns {*} AxiosPromise
 */
export function putPetServiceItem({
  customerKey,
  itineraryId,
  engagementId,
  petServiceItemId,
  data
}) {
  return bookingAPI.put(
    `${endpointVersionString()}/customers/${customerKey}/itineraries/${itineraryId}/engagements/${engagementId}/petservices/${petServiceItemId}/schedule`,
    data
  );
}
