import moment from "moment";

import { petsmartWebPublicAPI } from "./_axiosConfigPetMWebPubic";
import { formatMoment } from "../../utils/dateUtils/formatDateTime";
import { getPastSalonHours, mergeSalonHoursWithPastSalonHours } from "../../utils/salonHours";

/**
 * PetsMart/enums API - Fetch store hours from the PetSmart Web Public API
 * See https://api.petsmart.com/swagger/ui/index for more info
 * @param {Object} storeNumber and Dates
 */
export const fetchSalonHours = ({ storeNumber, beginDate, endDate }) => {
  const beginDateMoment = formatMoment(moment(beginDate));
  const endDateMoment = formatMoment(moment(endDate));

  // Temporary patch fixing fetching past date issue
  const pastSalonHours = getPastSalonHours({ beginDate, endDate });

  // Combine the response with the mock
  return petsmartWebPublicAPI
    .get(
      `stores/${storeNumber}/hours?model.beginDate=${beginDateMoment}&model.endDate=${endDateMoment}`,
    )
    .then(response => {
      const { data } = response;
      return {
        ...response,
        data: {
          ...data,
          StoreServices: mergeSalonHoursWithPastSalonHours({
            services: data.StoreServices,
            pastSalonHours,
          }),
        },
      };
    });
};
