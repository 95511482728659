import { TextWithEllipsis } from "@/dux/_components/textWithEllipsis/TextWithEllipsis";
import styled from "styled-components";
import { fontSizes } from "web/common/styles/responsive/fonts";

export const GroupMemberTitle = styled(TextWithEllipsis)`
  font-weight: bold;
  font-size: ${fontSizes.regular};
`;

export const GroupMemberDetails = styled.div`
  font-weight: normal;
  font-size: ${fontSizes.medium};
`;
