// React and Redux
import React from "react";
import { connect } from "react-redux";

// layout
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";

// Selectors
import {
  selectAllStoreServiceNamesAsComaDelString,
  selectAllStoreServicesHoursAsListsOfStrings,
  selectStoreAddress,
  selectStoreHours,
  selectStorePhoneNumber,
} from "dux/storeInfoHoursAndServices/storeInfoHoursAndServicesSelectors";
import { getStoreNumber } from "core/selectors/persistentSelectors";

// Sparky
import { Heading, Icon } from "@petsmart-ui/sparky";

// Theme
import { color } from "web/common/styles/theme";

export const StoreInfoHoursAndServices = props => {
  const {
    componentId,
    isHidden,
    storeAddress,
    phoneNumber,
    storeHours,
    servicesHours,
    storeServiceNames,
  } = props;

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack space="scale-G1">
        {/*ADDRESS*/}
        <LayoutBox padding="scale-0">
          <LayoutCluster space="scale-S4" style={{ justifyContent: "flex-start" }}>
            <Icon name="pin-outline" size="md" />

            {storeAddress}
          </LayoutCluster>
        </LayoutBox>

        {/*PHONE*/}
        <LayoutBox padding="scale-0">
          <LayoutCluster space="scale-S4" style={{ justifyContent: "flex-start" }}>
            <Icon name="bell" size="md" />
            {phoneNumber}
          </LayoutCluster>
        </LayoutBox>

        {/*SERVICE NAMES*/}
        <LayoutBox padding="scale-0" style={{ maxWidth: "280px" }}>
          <LayoutCluster
            space="scale-S4"
            style={{ justifyContent: "flex-start", flexWrap: "nowrap" }}
          >
            <LayoutBox padding="scale-0">
              <Icon name="calendar-outline" size="md" />
            </LayoutBox>

            <LayoutBox padding="scale-0" style={{ paddingLeft: ".5rem" }}>
              {storeServiceNames}
            </LayoutBox>
          </LayoutCluster>
        </LayoutBox>

        {/*STORE HOURS*/}
        <LayoutBox padding="scale-0">
          <LayoutStack space="scale-G1">
            <LayoutCluster space="scale-S4" style={{ justifyContent: "start" }}>
              <Icon name="clock" size="md" />
              <Heading tagName="h2" size="body-lg-bold">
                Store Hours
              </Heading>
            </LayoutCluster>

            <LayoutBox padding="scale-0">
              <LayoutStack>
                {storeHours.map((storeHour, index) => {
                  return (
                    <LayoutBox padding="scale-0" key={index}>
                      {storeHour.times}
                    </LayoutBox>
                  );
                })}
              </LayoutStack>
            </LayoutBox>
          </LayoutStack>
        </LayoutBox>

        {/*STORE SERVICES*/}
        <LayoutBox padding="scale-0">
          <LayoutStack space="scale-G1">
            {servicesHours.map((serviceHours, index) => {
              return (
                <LayoutBox padding="scale-0" key={index}>
                  {/*Heading*/}
                  <LayoutStack>
                    <LayoutBox padding="scale-0">
                      <LayoutStack>
                        <LayoutBox padding="scale-0">
                          <div
                            style={{ width: "100%", borderTop: `1px solid ${color.borderGrey}` }}
                          />
                        </LayoutBox>

                        <LayoutBox padding="scale-0">
                          <Heading
                            tagName="h2"
                            size="body-lg-bold"
                          >{`${serviceHours.serviceName} Hours`}</Heading>
                        </LayoutBox>
                      </LayoutStack>
                    </LayoutBox>

                    {/*HOURS*/}
                    <LayoutBox padding="scale-0">
                      <LayoutStack>
                        {serviceHours.hours.map((hour, index) => {
                          return (
                            <LayoutBox
                              key={index}
                              padding="scale-0"
                              style={{ color: hour.isClosed ? color.errorRed : "" }}
                            >
                              {hour.times}
                            </LayoutBox>
                          );
                        })}
                      </LayoutStack>
                    </LayoutBox>
                  </LayoutStack>
                </LayoutBox>
              );
            })}
          </LayoutStack>
        </LayoutBox>
      </LayoutStack>
    </LayoutBox>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const SRC_StoreInfoHoursAndServices = connect(state => {
  const storeNumber = getStoreNumber(state);
  const { streetLine1, city, stateProvinceAbbreviation, zipPostalCode } = selectStoreAddress(
    state,
    { storeNumber },
  );
  const phoneNumber = selectStorePhoneNumber(state, { storeNumber });
  const storeHours = selectStoreHours(state, { storeNumber });
  const servicesHours = selectAllStoreServicesHoursAsListsOfStrings(state, { storeNumber });
  const storeServiceNames = selectAllStoreServiceNamesAsComaDelString(state, { storeNumber });

  return {
    componentId: "SRC_StoreInfoHoursAndServices",
    storeAddress: `${streetLine1} ${city}  ${stateProvinceAbbreviation} ${zipPostalCode}`,
    phoneNumber,
    storeHours,
    servicesHours,
    storeServiceNames,
  };
})(StoreInfoHoursAndServices);
