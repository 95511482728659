import { createSelector } from "reselect";
import { getHotelArrivalsAppointments } from "../../columnList/hotelArivalsSelector";
import getSpeciesCount from "./getSpeciesCount";

export const getHotelArrivalsSpeciesCount = createSelector(
  [getHotelArrivalsAppointments],
  appointments => {
    return getSpeciesCount(appointments);
  },
);
