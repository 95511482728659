import { connect } from "react-redux";
import AddButton from "../../../common/buttons/addButton";
import petParentProfileActionCreator from "../../../../core/actionCreators/petParentProfileActionCreator";
import { NEW_PET_ID } from "../../../../core/constants";
import { selectPet } from "../../../../core/actionCreators/bookingActionCreators";
import { setPetId } from "../../../../core/actionCreators/ui/web/generalActionCreators";

const mapStateToProps = state => ({
  opacity: "1",
  label: "Add Pet",
  fontSize: "regular"
});

const mapDispatchToProps = dispatch => ({
  buttonClickHandler: () => {
    dispatch(selectPet(NEW_PET_ID));
    dispatch(setPetId(NEW_PET_ID));
    dispatch(petParentProfileActionCreator.setIsAddingPet({ isAddingPet: true }));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddButton);
