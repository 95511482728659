import React from "react";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { HotelBookButton } from "../../../bookHotel/HotelBookButton";
import HotelBookingTotalPriceContainer from "../../../bookHotel/HotelBookingTotalPriceContainer";
import BookErrorMessageContainer from "../../../bookHotel/BookErrorMessageContainer";

/**
 * Represents a component that displays the total price of a hotel booking button.
 *
 * @memberof Views.Booking
 * @function
 *
 * @param {Object} props - The props object.
 * @param {string} props.componentId - The unique id of the component.
 *
 * @returns {JSX.Element} The rendered HotelBookButtonTotalPriceComponent.
 */
export const HotelBookButtonTotalPriceComponent = ({ componentId }) => {
  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack space="scale-S10">
        <LayoutCluster style={{ justifyContent: "space-between" }}>
          <HotelBookingTotalPriceContainer />
          <HotelBookButton />
        </LayoutCluster>

        <BookErrorMessageContainer />
      </LayoutStack>
    </LayoutBox>
  );
};
