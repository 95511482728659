import upcomingAndHereActionTypes from "../../../actionTypes/upcomingAndHereActionTypes";

const initialState = {
  hereOrCompletedFilterApplied: "Here",
  upcomingSearchText: "",
  hereSearchText: "",
  upcomingBoardedGuestChecked: false,
  hereBoardedGuestChecked: false
};

const upcomingAndHereReducer = (state = initialState, action) => {
  switch (action.type) {
    case upcomingAndHereActionTypes.SET_HERE_OR_COMPLETED_FILTER_APPLIED:
      return {
        ...state,
        hereOrCompletedFilterApplied: action.hereOrCompletedFilterApplied
      };
    case upcomingAndHereActionTypes.SET_UPCOMING_SEARCH_TEXT:
      return {
        ...state,
        upcomingSearchText: action.value
      };
    case upcomingAndHereActionTypes.CLEAR_UPCOMING_SEARCH_TEXT:
      return {
        ...state,
        upcomingSearchText: initialState.upcomingSearchText
      };
    case upcomingAndHereActionTypes.SET_HERE_SEARCH_TEXT:
      return {
        ...state,
        hereSearchText: action.value
      };
    case upcomingAndHereActionTypes.CLEAR_HERE_SEARCH_TEXT:
      return {
        ...state,
        hereSearchText: initialState.hereSearchText
      };
    case upcomingAndHereActionTypes.SET_UPCOMING_BOARDED_GUEST_CHECKED:
      return {
        ...state,
        upcomingBoardedGuestChecked: true
      };
    case upcomingAndHereActionTypes.CLEAR_UPCOMING_BOARDED_GUEST_CHECKED:
      return {
        ...state,
        upcomingBoardedGuestChecked: initialState.upcomingBoardedGuestChecked
      };
    case upcomingAndHereActionTypes.SET_HERE_BOARDED_GUEST_CHECKED:
      return {
        ...state,
        hereBoardedGuestChecked: true
      };
    case upcomingAndHereActionTypes.CLEAR_HERE_BOARDED_GUEST_CHECKED:
      return {
        ...state,
        hereBoardedGuestChecked: initialState.hereBoardedGuestChecked
      };

    default:
      return state;
  }
};

export default upcomingAndHereReducer;
