import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import {
  getSearchFieldType,
  getFormattedStoreSearchResults,
} from "../../../core/selectors/searchSalonSRCSelector";
import {
  getSearchComponentName,
  getStoreNumber,
} from "../../../core/selectors/persistentSelectors";
import { routePaths } from "@/core/constants/routePaths";
import CustomerCard from "../customerCard/customerCardComponent";

const mapStateToProps = (state, ownProps) => {
  return {
    infoList: getFormattedStoreSearchResults(state),
    customerImage: "https://via.placeholder.com/40x40",
    searchComponentName: getSearchComponentName(state, ownProps),
    searchFieldType: getSearchFieldType(state, ownProps),
    storeNumber: getStoreNumber(state),
  };
};

const mapDispatchToProps = (_, { router: { navigate } }) => ({
  onClick: () => {
    navigate(routePaths.DASHBOARD);
  },
});

export default withRouteProps(connect(mapStateToProps, mapDispatchToProps)(CustomerCard));
