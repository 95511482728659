import { eligibilityTypes } from "dux/eligibility/constants/eligibilityConstants";

/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */

export const eligibilityCustomerActionTypes = {
  GET_CUSTOMER_ELIGIBILITY: "eligibilty/GET_CUSTOMER_ELIGIBILITY",
  GET_CUSTOMER_ELIGIBILITY_REQUEST: "eligibilty/GET_CUSTOMER_ELIGIBILITY_REQUEST",
  GET_CUSTOMER_ELIGIBILITY_FAILURE: "eligibilty/GET_CUSTOMER_ELIGIBILITY_FAILURE",
  GET_CUSTOMER_ELIGIBILITY_SUCCESS: "eligibilty/GET_CUSTOMER_ELIGIBILITY_SUCCESS",

  LOAD_CUSTOMER_ELIGIBILITY_SUCCESS: "LOAD_CUSTOMER_ELIGIBILITY_SUCCESS",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getCustomerEligibility = ({
  customerKey,
  eligibilityType = eligibilityTypes.BOOKING,
}) => ({
  type: eligibilityCustomerActionTypes.GET_CUSTOMER_ELIGIBILITY,
  customerKey,
  eligibilityType,
});

export const getCustomerEligibilityRequest = () => ({
  type: eligibilityCustomerActionTypes.GET_CUSTOMER_ELIGIBILITY_REQUEST,
});

export const getCustomerEligibilityFailure = ({ error }) => ({
  type: eligibilityCustomerActionTypes.GET_CUSTOMER_ELIGIBILITY_FAILURE,
  error,
});

export const getCustomerEligibilitySuccess = () => ({
  type: eligibilityCustomerActionTypes.GET_CUSTOMER_ELIGIBILITY_SUCCESS,
});

export const loadCustomerEligibilitySuccess = result => ({
  type: eligibilityCustomerActionTypes.LOAD_CUSTOMER_ELIGIBILITY_SUCCESS,
  result,
});
