import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

export function fetchVoidTransactionsReasons() {
  return bookingAPI.get(`${endpointVersionString()}/settings/voidreasons`);
}

/**
 * Update the current price and/or payment status of the itinerary.
 * From System Services Booking API
 * - Update payment status
 * @param {*} { customerId, itineraryId, data }
 * @returns {*} AxiosPromise
 */
export function putVoidTransaction({ customerId, itineraryId, data }) {
  return bookingAPI.put(`customers/${customerId}/itineraries/${itineraryId}/payment`, data);

  // console.log('\nSimulated void transaction: ,', { data });
  // delay(500).then((){
  //     return 'not  real response';
  // });
}
