import incrementTimestamp from "@/dux/utils/dateUtils/incrementTimestamp";
import { MINUTES_IN_DAY } from "@/dux/utils/dateUtils/dateTimeConstants";

/**
 * Responsible for fetching all timeslots in a 24 hour period in increments specified by
 * an "offset" value provided.
 *
 * @export getTimeSlots
 * @param {number} offet - Integer value to increment
 * @returns {string} - Short date time
 */
export default function getTimeSlots(offset = 30) {
  const midnight = new Date().setHours(0, 0, 0, 0);
  const numberOfSteps = MINUTES_IN_DAY / offset - 1;

  return Array(numberOfSteps)
    .fill()
    .reduce(
      (acc, curr) => {
        const [prevTimeSlot] = acc.slice(-1);
        const nextTimeSlot = incrementTimestamp(prevTimeSlot, offset);

        return [...acc, nextTimeSlot];
      },
      [midnight],
    );
}
