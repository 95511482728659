export default {
  LOAD_PET: "LOAD_PET",
  LOAD_PET_REQUEST: "LOAD_PET_REQUEST",
  LOAD_PET_SUCCESS: "LOAD_PET_SUCCESS",
  LOAD_PET_FAILURE: "LOAD_PET_FAILURE",

  LOAD_PETS: "LOAD_PETS",
  LOAD_PETS_REQUEST: "LOAD_PETS_REQUEST",
  LOAD_PETS_SUCCESS: "LOAD_PETS_SUCCESS",
  LOAD_PETS_FAILURE: "LOAD_PETS_FAILURE",

  LOAD_PETS_BY_CUSTOMER: "LOAD_PETS_BY_CUSTOMER",
  LOAD_PETS_BY_CUSTOMER_REQUEST: "LOAD_PETS_BY_CUSTOMER_REQUEST",
  LOAD_PETS_BY_CUSTOMER_SUCCESS: "LOAD_PETS_BY_CUSTOMER_SUCCESS",
  LOAD_PETS_BY_CUSTOMER_FAILURE: "LOAD_PETS_BY_CUSTOMER_FAILURE",

  UPDATE_PET: "UPDATE_PET",
  UPDATE_PET_REQUEST: "UPDATE_PET_REQUEST",
  UPDATE_PET_SUCCESS: "UPDATE_PET_SUCCESS",
  UPDATE_PET_FAILURE: "UPDATE_PET_FAILURE",

  CREATE_PET: "CREATE_PET",
  CREATE_PET_REQUEST: "CREATE_PET_REQUEST",
  CREATE_PET_SUCCESS: "CREATE_PET_SUCCESS",
  CREATE_PET_FAILURE: "CREATE_PET_FAILURE",

  SET_PET_INACTIVE: "SET_PET_INACTIVE",
  SET_PET_INACTIVE_REQUEST: "SET_PET_INACTIVE_REQUEST",
  SET_PET_INACTIVE_SUCCESS: "SET_PET_INACTIVE_SUCCESS",
  SET_PET_INACTIVE_FAILURE: "SET_PET_INACTIVE_FAILURE",

  SET_PET_ACTIVE: "SET_PET_ACTIVE",
  SET_PET_ACTIVE_REQUEST: "SET_PET_ACTIVE_REQUEST",
  SET_PET_ACTIVE_SUCCESS: "SET_PET_ACTIVE_SUCCESS",
  SET_PET_ACTIVE_FAILURE: "SET_PET_ACTIVE_FAILURE"
};
