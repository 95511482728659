import { associateWebBFFAPI } from "../associateWebBff/_axiosConfigAssociateWebBff";

/**
 * Gets a collection of suspensionReason by reason type.
 * From System Services Booking Api - Get list of service status items
 * @returns {*} AxiosPromise
 */
export function getSuspensionReasons({ storeNumber, suspensionTypes }) {
  return associateWebBFFAPI.get("v1/hotel/suspensionreasons", {
    params: { storeNumber, suspensionTypes },
  });
}
