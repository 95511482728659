import { connect } from "react-redux";
import { selectFoodList } from "@/dux/foodOptionsList/foodOptionsListSelectors";
import { getFoodById } from "web/food/foodsSelector";
import { createLoadingSelector } from "core/selectors/utils";
import CommonButton from "web/common/commonButton";
import { setTimeOfDayTouched } from "web/foodTimeOfDay/actions/foodTimeOfDayActions";
import { clearNewFood } from "web/newFood/actions/newFoodActions";
import {
  addFoodBookingActionTypes,
  addNewBookingFood,
} from "dux/bookingFeeding/addNewFoodBookingActions";
import isRequiredPetFoodFieldMissing from "web/newFood/utils/isRequiredPetFoodFieldMissing";
import { NEW_FOOD_ID } from "web/newFood/newFoodConstants";
import { getFoodIsShowingDetailsForType } from "web/foodList/foodListSelectors";
import { getNewFoodId } from "web/foodCount/foodCountSelector";
import { putCart } from "../servicesCart/servicesCartActions";

const mapStateToProps = (state, ownProps) => {
  const { foodId, petId, locationType } = ownProps;
  const foodList = selectFoodList(state);
  const food = getFoodById(state, { foodId, petId });
  const isLoading = createLoadingSelector([addFoodBookingActionTypes.ADD_NEW_BOOKING_FOOD])(state);
  return {
    foodId,
    petId,
    foodList,
    newFoodId: getNewFoodId(state, { petId }),
    food,
    isHidden:
      foodId !== NEW_FOOD_ID
        ? true
        : !getFoodIsShowingDetailsForType(state, { foodId, petId, locationType }),
    componentId: "foodDetails-addNewFoodButton",
    label: isLoading ? "Adding..." : "Add",
    width: "8.5em",
    disabled:
      isRequiredPetFoodFieldMissing({
        food,
        foodList,
        isInBooking: true,
        petId,
      }) || isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  addPetFoodToBooking: (foodId, petId) => {
    dispatch(addNewBookingFood({ foodId, petId }));
    dispatch(setTimeOfDayTouched({ foodId, petId, timeOfDayTouched: false }));
    dispatch(clearNewFood({ petId }));
    dispatch(putCart());
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { newFoodId, petId } = propsFromState;
    propsFromDispatch.addPetFoodToBooking(newFoodId, petId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
