import { connect } from "react-redux";
import CommonButton from "../common/commonButton";
import { getScheduleViolationOverride } from "../../core/selectors/schedules/scheduleViolationOverride";
import {
  setSchedules,
  hideSchedulesModal
} from "../../core/actionCreators/schedulesActionCreators";
import {
  getAssociateId,
  getStartWeekDate,
  getWeeklyEditActivities
} from "../../core/selectors/schedulesSelectors";

const mapStateToProps = (state, ownProps) => ({
  associateId: getAssociateId(state),
  startWeekDate: getStartWeekDate(state),
  weeklyEditActivities: getWeeklyEditActivities(state),
  disabled: !getScheduleViolationOverride(state),
  label: "Confirm",
  margin: true
});

const mapDispatchToProps = dispatch => ({
  handleClick: ({ associateId, startWeekDate, dayActivities }) => {
    dispatch(setSchedules({ associateId, startWeekDate, dayActivities }));
    dispatch(hideSchedulesModal());
  }
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () =>
    propsFromDispatch.handleClick({
      associateId: propsFromState.associateId,
      startWeekDate: propsFromState.startWeekDate,
      dayActivities: propsFromState.weeklyEditActivities
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CommonButton);
