import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Components
import { Form, Layout, Text } from "@prism/psm-ui-components";

// Actions
import {
  loadHotelRoomTypes,
  setHotelRoomTypeSelection,
} from "@/dux/hotelRoomSelection/hotelRoomSelectionActions";

// Selectors
import {
  getHotelRoomSelectedRoomType,
  getHotelRoomTypes,
} from "@/dux/hotelRoomSelection/hotelRoomSelectionSelector";

// Contants
import { routePaths } from "@/core/constants/routePaths";

// Helpers
import { history } from "dux/utils/browser/browserHistory";

// Component
export const RoomTypeSelection = props => {
  const {
    componentId,
    handleRoomSelection,
    roomSelected,
    roomSelectionTitle,
    roomTypes,
    getHotelRoomTypes,
    disabled,
  } = props;

  useEffect(() => {
    // fetch room types
    // checking for the lenght because we always have 'view all' room type.
    if (roomTypes.length <= 1) {
      getHotelRoomTypes();
    }
  }, [getHotelRoomTypes]);

  return (
    <Layout.Box>
      <Text>{roomSelectionTitle}</Text>
      <Form.Field.Select
        id={`${componentId}__select`}
        legacy
        value={roomSelected}
        onChange={event => handleRoomSelection(event)}
        options={roomTypes}
        isDisabled={disabled}
      />
    </Layout.Box>
  );
};

// Component
export const HotelRoomTypeSelection = () => {
  return (
    <Layout.Box
      id="HotelRoomTypeSelection"
      borderColor="box-border-color-gray-100"
      borderWidth
      padding="box-padding-6"
    >
      <Layout.Stack space="stack-space-6">
        <Text align="center" bold size="text-size-2xl">
          Room Manager
        </Text>
        <HotelRoomTypeComboBox />
      </Layout.Stack>
    </Layout.Box>
  );
};

// Container
export const HotelRoomTypeComboBox = connect(
  state => {
    return {
      componentId: "HotelRoomTypeComboBox",
      roomSelected: getHotelRoomSelectedRoomType(state),
      roomSelectionTitle: "Room Type",
      roomTypes: getHotelRoomTypes(state),
    };
  },
  dispatch => {
    return {
      handleRoomSelection: ({ value }) => {
        dispatch(setHotelRoomTypeSelection(value));
        history.push(routePaths.ROOM_MANAGEMENT);
      },
      getHotelRoomTypes: () => dispatch(loadHotelRoomTypes()),
    };
  },
)(RoomTypeSelection);

export const OverBookingRoomTypeComboBox = connect(
  state => {
    return {
      componentId: "OverBookingComboBox",
      roomSelected: getHotelRoomSelectedRoomType(state) || 'View All',
      roomSelectionTitle: "Room Type",
      roomTypes: getHotelRoomTypes(state),
    };
  },
  dispatch => {
    return {
      handleRoomSelection: ({ value }) => {
        dispatch(setHotelRoomTypeSelection(value));
      },
      getHotelRoomTypes: () => dispatch(loadHotelRoomTypes()),
    };
  },
)(RoomTypeSelection);
