import styled from "styled-components";
import LinkText from "../_components/styles/LinkText";

export const ListItem = styled(LinkText)`
  padding: 0.3em 0;
`;

export const listComponentTypes = {
  LIST_ITEM: "LIST_ITEM",
};

export const listComponents = {
  [listComponentTypes.LIST_ITEM]: ListItem,
};
