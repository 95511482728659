import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { APPOINTMENT_STATUS } from "@/core/constants";
import { showCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import { modalTypes } from "@/core/constants/checkInOutConstants";
import { onPatchHotelItineraryStatus } from "../reservationCartDetailsConfirmation/reservationCartDetailsConfirmationSagas";
import { getChangeStatusPayload } from "../reservationCartDetailsConfirmation/reservationCartDetailsUtils";
import {
  PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS,
  patchHotelCheckOutPetsFailure,
  patchHotelCheckOutPetsRequest,
  patchHotelCheckOutPetsSuccess,
} from "./checkOutActions";
import { selectIsLastToCheckOut } from "../hotelEngagements/hotelEngagementSelectors";
import { getHotelInvoice } from "../hotelInvoice/hotelInvoiceActions";
import { selectItineraryInvoiceIsPaid } from "../hotelItinerary/hotelItinerarySelectors";

/**
 * Saga to update hotel itinerary pet status to checked out
 * @memberOf Sagas.hotel.itinerary
 * @function
 * @name onPatchHotelItineraryCheckOutStatus
 * @param {Object} obj
 * @param {string} obj.itineraryId
 * @param {string[]} obj.petIds
 */
function* onPatchHotelItineraryCheckOutStatus({ itineraryId, petIds }) {
  try {
    yield put(patchHotelCheckOutPetsRequest());

    const isLastToCheckOut = yield select(selectIsLastToCheckOut(petIds));

    const data = yield call(getChangeStatusPayload, {
      petIds,
      status: APPOINTMENT_STATUS.CHECKED_OUT,
    });

    // Update status to checked out
    const error = yield call(onPatchHotelItineraryStatus, { itineraryId, data });

    yield put(patchHotelCheckOutPetsSuccess());

    // If all pets are now checked out, show check out complete modal & print invoice
    if (!error && isLastToCheckOut) {
      yield put(showCheckInOutModal({ modalType: modalTypes.HOTEL_CHECK_OUT_COMPLETE }));

      const isInvoicePaid = yield select(selectItineraryInvoiceIsPaid);
      if (!isInvoicePaid) yield put(getHotelInvoice());
    }
  } catch (error) {
    yield put(patchHotelCheckOutPetsFailure(error));
  }
}

function* watchOnPatchHotelItineraryCheckOutStatus() {
  yield takeLatest(PATCH_HOTEL_ITINERARY_CHECK_OUT_STATUS, onPatchHotelItineraryCheckOutStatus);
}

export default function* hotelItineraryCheckOutStatusSaga() {
  yield all([watchOnPatchHotelItineraryCheckOutStatus()]);
}
