import { put, call, takeEvery, all } from "redux-saga/effects";
import { fetchVoidTransactionsReasons } from "core/services/systemServicesBooking/voidTransactionsEndPoints";
import voidTransactionActionCreators from "core/actionCreators/voidTransactionActionCreator";
import voidTransactionActionTypes from "core/actionTypes/voidTransactionActionTypes";

function* onloadVoidTransactionsReasons() {
  try {
    yield put(voidTransactionActionCreators.loadVoidTransactionsReasonsRequest());
    const response = yield call(fetchVoidTransactionsReasons);
    const reasons = response.data;
    yield put(voidTransactionActionCreators.loadVoidTransactionsReasonsSuccess({ reasons }));
  } catch (error) {
    yield put(voidTransactionActionCreators.loadVoidTransactionsReasonsFailure({ error }));
  }
}

function* watchloadVoidTransactionsReasons() {
  yield takeEvery(
    voidTransactionActionTypes.LOAD_VOID_TRANSACTIONS_REASONS,
    onloadVoidTransactionsReasons
  );
}

export default function* voidTransactionReasonsSaga() {
  yield all([watchloadVoidTransactionsReasons()]);
}
