import React, { useEffect } from "react";
import { connect } from "react-redux";

// Actions
import { loadSalonHours } from "@/core/actionCreators/salonHoursActionCreators";
import { loadMySchedule } from "@/core/actionCreators/schedulesActionCreators";
import { loadAssociates } from "@/core/actionCreators/associateActionCreator";
import schedulesActionTypes from "@/core/actionTypes/schedulesActionTypes";

// Selectors
import { getMySchedule, getWeekStartEndDates } from "@/core/selectors/myScheduleSelectors";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { MayScheduleAssociateCell } from "web/schedules/associateCell";
import HeaderImage from "../../assets/images/bg-header-image.png";
import MyScheduleHeaderComponent from "../header/MyScheduleHeaderContainer";
import DateRangeSelect from "../schedules/datesRangeContainer";
import LoadingWrapper from "../common/LoadingWrapper";
import Table from "../common/Table/Table";

// Utils
import { createLoadingSelector } from "@/core/selectors/utils";

// Styled Components - @todo - remove styled components
import styled from "styled-components";

const TableWrapper = styled.div`
  min-height: 500px;
  table {
    width: 100%;
    border: 1px solid #dddddd;
  }
  table,
  th,
  td {
    white-space: pre-line;
    border-collapse: collapse;
    border: 1px solid #dddddd;
    padding-right: 20px;
    padding-left: 20px;
  }
  /* header */
  th {
    font-weight: bold;
  }
  /* first column */
  td:first-child {
    font-weight: bold;
  }
  /* center every cell in the body except the first */
  td:not(:first-child) {
    text-align: center;
  }
  tbody tr:nth-child(odd) {
    background-color: #f0f0f0;
  }
  tr {
    line-height: 20px;
  }
`;

/**
 * React view component of a table that displays my schedule for an associate
 *
 * @summary Located on the my-schedule/:associateId page.
 * @memberOf Views.Associate
 * @function
 * @name  MyScheduleComponent
 * @param { Object } props
 * @param { String } props.componentId - Block level Id for the component
 * @param { Number } props.dates - dates object - from and to dates
 * @param { Function } props.loadMySchedule - dispatch function to load schedule for an associate
 * @param { Function } props.loadStoreHours - dispatch function to load store hours
 * @param { Function } props.loadAssociates - dispatch function to load associates
 * @param { Boolean } props.isLoading - If the component is in a loading state
 * @param { Object } props.tableData - Table Data
 * @param { Boolean } props.isHidden - determines if the component is visible
 * @returns {JSX.Element|null}
 *
 * @example <MyScheduleComponent />
 */
const MyScheduleComponent = props => {
  const {
    componentId,
    dates,
    loadMySchedule,
    loadStoreHours,
    loadAssociates,
    isLoading,
    tableData,
    isHidden,
  } = props;
  const { fromDate, toDate } = dates;
  const { columns } = tableData;

  if (isHidden) return null;

  // Replace the first cell in the table header with the <AssociateCell />
  const remainingColumns = columns.slice(1, columns.length);
  const firstColumnHeader = {
    label: <MayScheduleAssociateCell />,
    path: columns[0] && columns[0].path,
  };

  const newColumns = [firstColumnHeader, ...remainingColumns];

  useEffect(() => {
    loadStoreHours({ beginDate: fromDate, endDate: toDate });
    loadAssociates({ isLimited: true });
    loadMySchedule({ fromDate });
  }, [fromDate]);

  return (
    <LayoutBox componentId={componentId} padding="scale-0">
      <MyScheduleHeaderComponent backgroundImage={HeaderImage} />
      <LayoutBox componentId={componentId} padding="scale-G2">
        <LayoutStack space="scale-G2">
          <LayoutCluster style={{ justifyContent: "center" }}>
            <DateRangeSelect />
          </LayoutCluster>
          <LoadingWrapper isLoading={isLoading}>
            <TableWrapper>
              {tableData.rows.length ? <Table columns={newColumns} rows={tableData.rows} /> : null}
            </TableWrapper>
          </LoadingWrapper>
        </LayoutStack>
      </LayoutBox>
    </LayoutBox>
  );
};

const mapStateToProps = state => ({
  dates: getWeekStartEndDates(state),
  tableData: getMySchedule(state),
  isLoading: createLoadingSelector([schedulesActionTypes.LOAD_MY_SCHEDULE])(state),
});

const mapDispatchToProps = dispatch => ({
  loadMySchedule: ({ fromDate }) => dispatch(loadMySchedule({ fromDate })),
  loadAssociates: ({ isLimited }) => dispatch(loadAssociates({ isLimited })),
  loadStoreHours: ({ beginDate, endDate }) => dispatch(loadSalonHours({ beginDate, endDate })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyScheduleComponent);