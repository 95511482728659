import React from "react";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { connect } from "react-redux";
import { TextPassage, Heading } from "@petsmart-ui/sparky";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getPrismCustomerHotelProfile } from "web/petParentProfile/selectors/getPrismCustomerProfileSelector";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { getCustomer } from "core/selectors/entitiesSelector";
import isEmpty from "lodash/fp/isEmpty";

export const DoNotBookInfoLabel = props => {
  const { alerts, componentId, isHidden, title } = props;

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack>
        <Heading size="headline" tagName="h2">
          {title}
        </Heading>
        {alerts?.map((alert, index) => {
          return (
            <LayoutStack key={alert.id} style={{ marginBottom: "1rem" }}>
              <TextPassage
                size="lg"
                style={{ fontWeight: "bold" }}
                id={`${componentId}__title__${index}`}
              >
                {alert.title}
              </TextPassage>
              <TextPassage size="lg" id={`${componentId}__message__${index}`}>
                {alert.message || "-"}
              </TextPassage>
            </LayoutStack>
          );
        })}
      </LayoutStack>
    </LayoutBox>
  );
};

// HOTEL CONTAINER ----------------------------------------------------------------------------------------
export const DoNotBookHotelCheckInInfoLabel = connect(state => {
  const customerKey = getCurrentCustomerKey(state);
  const hotelProfile = getPrismCustomerHotelProfile(state, { customerKey });
  const bookingFlow = getSystemBookingFlow(state);

  const checkInAlert = !isEmpty(hotelProfile?.checkInAlert)
    ? hotelProfile?.checkInAlert
    : "No Pet Parent Check-in Alert";
  const checkOutAlert = !isEmpty(hotelProfile?.checkOutAlert)
    ? hotelProfile?.checkOutAlert
    : "No Pet Parent Check-out Alert";

  const alerts = [
    {
      id: 1,
      title: "Check in Alert",
      message: checkInAlert,
    },
    {
      id: 2,
      title: "Check out Alert",
      message: checkOutAlert,
    },
  ];

  return {
    alerts,
    isHidden: bookingFlow !== systemName.HOTEL,
    componentId: "DoNotBookHotelCheckInInfoLabel",
    title: "Pet Parent Alerts",
  };
})(DoNotBookInfoLabel);

// SALON CONTAINER ----------------------------------------------------------------------------------------
export const DoNotBookSalonCheckInInfoLabel = connect(state => {
  const customerKey = getCurrentCustomerKey(state);
  const customer = getCustomer(state, { customerKey });
  const doNotBookReason = customer?.doNotBookReason;
  const noReason =
    doNotBookReason === null || doNotBookReason === undefined || doNotBookReason === "";
  const bookingFlow = getSystemBookingFlow(state);

  const doNotBookAlerts = [
    {
      message: noReason ? "No Pet Parent Check-in Alert" : "Pet Parent has a Check-in Alert",
    },
  ];

  return {
    alerts: doNotBookAlerts,
    isHidden: bookingFlow !== systemName.SALON,
    componentId: "DoNotBookSalonCheckInInfoLabel",
    title: "Pet Parent Alert",
  };
})(DoNotBookInfoLabel);
