import { petsmartWebPublicAPI } from "./_axiosConfigPetMWebPubic";
import { enumNames } from "../../constants/enumsConstants";

/**
 * PetsMart/enums API - List of enum type
 * See https://api.petsmart.com/swagger/ui/index for more info
 * @param {String} enumName Enum Name to determine what endpoint to return.
 */
export const fetchEnumByName = ({ enumName }) => {
  switch (enumName) {
    case enumNames.VACCINATIONS:
      return petsmartWebPublicAPI.get("/pets/vaccinations");
    case enumNames.STATES:
      return petsmartWebPublicAPI.get("/stateprovinces");
    case enumNames.COUNTRIES:
      return petsmartWebPublicAPI.get("/countries");
    default:
      return Promise.resolve();
  }
};

export const fetchEnums = () => {
  return petsmartWebPublicAPI.get("/pets/config");
};
