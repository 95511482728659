import {
  SET_BULK_PACKAGE_OFFERINGS_DROPDOWN,
  SET_BULK_HISTORY_REDEMPTIONS_DROPDOWN,
} from "./toggleArrowActions";
import { CLEAR_SELECTED_BULK_PACKAGE_OFFERINGS } from "../bgm/bulkPackageOfferings/bulkPackageOfferingsActions";

const initialState = {
  selectedBulkPackageOfferingsDropdowns: {},
  selectedBulkHistoryRedemptionsDropdowns: {},
};

const toggleArrowReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BULK_PACKAGE_OFFERINGS_DROPDOWN:
      return {
        selectedBulkPackageOfferingsDropdowns: {
          [action.payload]: !state.selectedBulkPackageOfferingsDropdowns[action.payload],
        },
      };
    case SET_BULK_HISTORY_REDEMPTIONS_DROPDOWN:
      return {
        ...state,
        selectedBulkHistoryRedemptionsDropdowns: {
          ...state.selectedBulkHistoryRedemptionsDropdowns,
          [action.payload]: !state.selectedBulkHistoryRedemptionsDropdowns[action.payload],
        },
      };
    case CLEAR_SELECTED_BULK_PACKAGE_OFFERINGS:
      return {
        ...state,
        selectedBulkPackageOfferingsDropdowns: initialState.selectedBulkPackageOfferingsDropdowns,
      };
    default:
      return state;
  }
};

export default toggleArrowReducer;
