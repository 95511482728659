import React, { useEffect } from "react";
import isEqual from "lodash/isEqual";
import { connect } from "react-redux";
import QuickQuoteSalonButtonContainer from "dux/quickQuote/QuickQuoteSalonButtonContainer";
import { NotificationsFactory } from "dux/notificationCenter/StoreEventNotifications";
import { getJobRole } from "core/selectors/persistentSelectors";
import { selectCapabilities } from "dux/storeCapabilities/storeCapabilitiesSelectors";
import { jobRoleAllowsForHotel } from "core/utils/jobRolsUtils/jobRoleBasedServiceVisibility";
import {
  getSystemBookingFlow,
  getSystemType,
} from "web/setSystemType/selectors/setSystemTypeSelectors";
import { dashboardFactory } from "dux/dashboard/dashboardPageHelpers";
import {
  setSystemBookingFlowType,
  setSystemType,
} from "web/setSystemType/actions/setSystemTypeActions";
import DashboardHeaderSalonContainer from "../../dashboardHeader/DashboardHeaderSalonContainer";
import DashboardHeaderHotelContainer from "../../dashboardHeader/DashboardHeaderHotelContainer";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { enableDisableWorkflowFeatureFlag } from "web/enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagActions";
import { setSystemByCapabilities } from "dux/_components/dashboard/dashboardHelpers";

/**
 * Custom Hook to abstract out the logic that is shared between the main dashboard and the src dashboard (SearchSRCComponent).
 *
 * @memberof Views.Dashboard
 * @function
 * @param {object} props - The properties passed to the Dashboard Component.
 * @param {string} props.capabilities - capabilities/permissions provided to the user.
 * @param {function} props.jobRole - User's Job roll, e.g. Manager, associate or SRC.
 * @param {array} props.capabilities - The capabilities array used to determine the system type and flow.
 * @param {function} props.setSystemTypeAndFlow - The handler function for setting the system type and flow.
 */
export const useCapabilitiesCheck = ({ setSystemTypeAndFlow, capabilities, jobRole }) => {
  // Need to know if the system and booking flow  global state need to change if capabilities array is updated
  useEffect(() => {
    /* The capabilities api could possibly return an empty array, this can cause an issue with how the  dashboard renders.
      By setting initialState to [{}], we force a change to the state regardless if the capabilities comes back empty or
      with actual data, thus calling this use effect. */
    if (!isEqual(capabilities, [{}])) {
      setSystemTypeAndFlow();
    }
  }, [capabilities, jobRole]);
};

/**
 * Represents a Dashboard Page that displays a system/service dashboard.
 *
 * @memberof Views.Dashboard
 * @function
 * @param {object} props - The properties passed to the Dashboard Component.
 * @param {boolean} props.isHidden - Indicates whether the Dashboard Component is hidden or not.
 * @param {string} props.componentID - The ID of the Dashboard Component.
 * @param {function} props.DashboardComp - The Dashboard Component to render e.g. Salon, Hotel, Training.
 * @param {array} props.capabilities - The capabilities array used to determine the system type and flow.
 * @param {function} props.setSystemTypeAndFlow - The handler function for setting the system type and flow.
 *
 * @returns {JSX.Element|null} The rendered Dashboard Component or null if it is hidden.
 */
export const DashboardComponent = props => {
  // properties
  const { isHidden, componentID, DashboardComp, capabilities, jobRole } = props;

  // Handler fns
  const { setSystemTypeAndFlow } = props;

  useCapabilitiesCheck({ setSystemTypeAndFlow, capabilities, jobRole });

  if (!isHidden) {
    return (
      <LayoutBox padding="scale-0" id={componentID}>
        <LayoutStack space="scale-0">
          <LayoutBox padding="scale-0">
            {/* Headers */}
            <DashboardHeaderSalonContainer>
              <QuickQuoteSalonButtonContainer />
            </DashboardHeaderSalonContainer>
            <DashboardHeaderHotelContainer />
          </LayoutBox>

          {/*NOTIFICATIONS BANNER*/}
          <NotificationsFactory />

          {/* Page Content */}
          <DashboardComp />
        </LayoutStack>
      </LayoutBox>
    );
  }

  return null;
};

export const DashBoardPage = connect(
  state => {
    const jobRole = getJobRole(state);
    const capabilities = selectCapabilities(state);
    const allowForHotel = jobRoleAllowsForHotel(jobRole, capabilities);
    const bookingFlow = getSystemBookingFlow(state);
    const systemType = getSystemType(state);

    return {
      componentID: "dashboardPage",
      isHidden: false,
      DashboardComp: dashboardFactory(bookingFlow, allowForHotel),

      allowForHotel,
      capabilities,
      jobRole,
      systemType,
    };
  },

  dispatch => {
    return {
      dispatchSetSystemType: ({ systemType }) => dispatch(setSystemType({ systemType })),
      dispatchSetSystemBookingFlowType: ({ systemBookingFlow }) =>
        dispatch(setSystemBookingFlowType({ systemBookingFlow })),
      dispatchEnableDisableWorkflowFeatureFlags: ({ featureFlagId, checked }) => {
        dispatch(enableDisableWorkflowFeatureFlag({ featureFlagId, checked }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const { componentID, isHidden, DashboardComp, capabilities, jobRole, systemType } = mapProps;
    const {
      dispatchSetSystemType,
      dispatchSetSystemBookingFlowType,
      dispatchEnableDisableWorkflowFeatureFlags,
    } = dispatchProps;

    return {
      // mapped props to view
      componentID,
      isHidden,
      DashboardComp,
      capabilities,
      jobRole,

      // Mapped Handlers to View
      setSystemTypeAndFlow: () => {
        setSystemByCapabilities({
          jobRole,
          capabilities,
          systemType,
          message:
            "Hotel is not available based on your current job role and store service capabilities, you will re-directed to Salon",
          dispatchSetSystemType,
          dispatchSetSystemBookingFlowType,
          dispatchEnableDisableWorkflowFeatureFlags,
        });
      },
    };
  },
)(DashboardComponent);
