import moment from "moment";

/** @module USTimeByDate */

/**
 * Formats time based on the moments date object.
 *
 * @export getUSTimeStringByDate
 * @param {*} date - Moments object
 * @param {boolean} withAMPM - if its am or pm
 * @returns {string} - formatted time
 * @example getUSTimeStringByDate(moment(day.OpenTime, 'HH:mm:ss'), true)
 */
export default function getUSTimeStringByDate(date, withAMPM) {
  return moment(date).format(`hh:mm${withAMPM ? " A" : ""}`);
}
