import { Form as FormBase, FormProps } from './form';
import { FormFieldText } from './Fields/Text';
import { FormFieldTime } from './Fields/Time';
import { FormFieldSelect } from './Fields/Select';
import { FormFieldTextArea } from "./Fields/TextArea";
import { FormFieldCheckbox } from "./Fields/Checkbox";
import { FormFieldToggleSwitch } from "./Fields/ToggleSwitch";

export * from './types';

export interface FormFunctionComponent extends React.ComponentClass<FormProps> {
  Field: {
    Text: typeof FormFieldText;
    Time: typeof FormFieldTime;
    Select: typeof FormFieldSelect;
    TextArea: typeof FormFieldTextArea;
    Checkbox: typeof FormFieldCheckbox;
    ToggleSwitch: typeof FormFieldToggleSwitch;
  };
}

const Form: Partial<FormFunctionComponent> = FormBase;

Form.Field = {
  Text: FormFieldText,
  Time: FormFieldTime,
  Select: FormFieldSelect,
  TextArea: FormFieldTextArea,
  Checkbox: FormFieldCheckbox,
  ToggleSwitch: FormFieldToggleSwitch,
};

export default Form as FormFunctionComponent;
