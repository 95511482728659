import React from "react";
import styled from "styled-components";
import Section from "../../../../common/Section";
import GridTable from "../../../../common/GridTable/GridTable";
import CloseButton from "../../../../common/CloseButton";
import formatPhoneNumberNANP from "../../../../../core/utils/stringManipulationUtils/formatPhoneNumber";
import VetAddButtonContainer from "../buttons/VetAddButtonContainer";

const VetNameWrapper = styled.div`
  display: flex;
`;

const CloseButtonWrapper = styled.div`
  margin-right: 1em;
`;

const VetName = styled.div``;

const buildGridConfig = opts => ({
  gridOptions: {
    rowKeyField: "vetClinicId",
    fixedHeader: false,
    headerStyling: { fontSize: "1em" }
  },
  columnDefs: [
    {
      title: "Veterinarian Name",
      field: "veterinarianName",
      cellRenderer: ({ vetClinicId, veterinarianName }) => (
        <VetNameWrapper>
          <CloseButtonWrapper>
            <CloseButton onClick={() => opts.openRemoveVetModal(vetClinicId)}>
              {vetClinicId}
            </CloseButton>
          </CloseButtonWrapper>
          <VetName>{veterinarianName}</VetName>
        </VetNameWrapper>
      )
    },
    {
      title: "Phone Number",
      field: "phoneNumber",
      cellRenderer: ({ phoneNumber }) => <div>{formatPhoneNumberNANP(phoneNumber)}</div>
    },
    {
      title: "Clinic Name",
      field: "clinicName"
    }
  ]
});

const vetAddButton = <VetAddButtonContainer />;

const PetVetsTableComponent = ({ petVets, isLoading, openRemoveVetModal }) => (
  <Section title="Vet information" topLine addButton={vetAddButton} disabled={isLoading}>
    <GridTable
      config={buildGridConfig({
        openRemoveVetModal: vetClinicId => openRemoveVetModal(vetClinicId)
      })}
      rowsData={petVets}
    />
  </Section>
);

export default PetVetsTableComponent;
