import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import sentryReduxEnhancer from "../utils/sentry/SentryReduxEnhancer";
import { withBatchReducer } from "../utils/batchUtils";

import rootReducer from "../../core/reducers/rootReducer";
import rootSaga from "../../core/sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

if (window.env.NODE_ENV === "development") {
  const freeze = require("redux-freeze");
  middlewares.push(freeze);
}

const configureStore = () => {
  const store = createStore(
    withBatchReducer(rootReducer),
    composeWithDevTools(applyMiddleware(...middlewares), sentryReduxEnhancer),
  );
  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
