import { takeEvery, all, select, put } from "redux-saga/effects";
import {
  enableDisableWorkflowFeatureFlag,
  enableDisableWorkflowFeatureFlagActionTypes,
} from "./enableDisableWorkflowFeatureFlagActions";
import { enableDisableFeatures } from "../enableDisableFeature/EnableDisableFeatureActions";
import getNormalizedFeatureFlagName from "./utils/getNormalizedFeatureFlagName";
import getWorkflowsState from "./selectors/_workflowsFeatureFlagState";

function* onEnableDisableWorkflowFeatureFlag({ featureFlagId, checked }) {
  try {
    const workflowFeatureFlags = yield select(getWorkflowsState);
    const { requirements = [] } = workflowFeatureFlags[featureFlagId];

    yield put(enableDisableWorkflowFeatureFlag({ featureFlagId, checked }));

    yield all(
      requirements.map(requirement => {
        return put(
          enableDisableFeatures({
            featureFlagId: getNormalizedFeatureFlagName(requirement),
            checked,
          }),
        );
      }),
    );
  } catch (error) {}
}

function* watchEnableDisableWorkflowFeatureFlags() {
  yield takeEvery(
    enableDisableWorkflowFeatureFlagActionTypes.ENABLE_DISABLE_WORKFLOW_FEATURE_FLAGS,
    onEnableDisableWorkflowFeatureFlag,
  );
}

export default function* enableDisableWorkflowFeatureFlagSaga() {
  yield all([watchEnableDisableWorkflowFeatureFlags()]);
}
