/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const addFoodBookingActionTypes = {
  ADD_NEW_BOOKING_FOOD: "ADD_NEW_BOOKING_FOOD",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const addNewBookingFood = ({ foodId, petId }) => ({
  type: addFoodBookingActionTypes.ADD_NEW_BOOKING_FOOD,
  foodId,
  petId,
});
