/**
 * Helper function that takes data returned from teh store locator and reduces the amount of fields
 * @param results
 * @return {*}
 */
export const reduceStoreInfoHelper = storeData => {
  return storeData.map(item => {
    const {
      StoreNumber,
      PhoneNumber,
      StreetLine1,
      City,
      StateProvinceAbbreviation,
      ZipPostalCode,
      StoreServices,
      Latitude,
      Longitude,
    } = item?.Store;

    const { DistanceToStoreFromOrigin, UnitOfDistance } = item;

    // get a list of stores, and convert to a comma delimited string.
    const availableServices = StoreServices?.map(service => service.ServiceName)
      .toString()
      .replaceAll(",", ", ");

    return {
      storeNumber: StoreNumber,
      phoneNumber: PhoneNumber,
      address: `${StreetLine1}, ${City} ${StateProvinceAbbreviation} ${ZipPostalCode}`,
      distance: DistanceToStoreFromOrigin.toFixed(2),
      unitOfDistance: UnitOfDistance,
      availableServices,
      latitude: Latitude,
      longitude: Longitude,
    };
  });
}