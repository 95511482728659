import { connect } from "react-redux";
import {
  getCurrentItinerary,
  getIsCurrentItineraryPriceGreaterThanZero,
  getPetsHavePaid,
} from "core/selectors/checkInOutSelector";
import { getCustomer, getPetsByIds, getPetsByItinerary } from "core/selectors/entitiesSelector";
import joinWithLastDelimiter from "core/utils/stringManipulationUtils/joinWithLastDelimiter";
import { PAYMENT_MESSAGE, REDEEMED_MESSAGE } from "dux/modals/checkIn/constants/checkOutConstants";
import CheckOutMultiModalContent from "../../../_components/modals/checkOut/multi/checkOutMultiModalContent";

const mapStateToProps = (state, { customerKey }) => {
  const itineraryId = getCurrentItinerary(state);

  const petIds = getPetsByItinerary(state, { itineraryId });
  const pets = getPetsByIds(state, { petIds });
  const petNamesArray = pets.map(pet => pet.petName);
  const petNamesString = joinWithLastDelimiter(petNamesArray);
  const customer = getCustomer(state, { customerKey });

  return {
    isHidden: false,
    headerText: `Great Job! ${petNamesString} ${customer.lastName} have left the Salon`,
    message: getIsCurrentItineraryPriceGreaterThanZero(state, { itineraryId })
      ? PAYMENT_MESSAGE
      : REDEEMED_MESSAGE,
    petsPaid: getPetsHavePaid(state),
  };
};

export default connect(mapStateToProps)(CheckOutMultiModalContent);
