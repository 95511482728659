import { connect } from "react-redux";
import { getHotelDrawerIsOpen } from "../hotelRoomCapacityModal/hotelRoomCapacitySelector";
import CapacityRoomRowTwoCol from "../_components/roomCapacity/CapacityRoomRowThreeCol";

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => ({
  isHidden: false,
  componentID: "CapacityRoomRow__hotelTableHeader",
  room: "Room",
  used: "% Used",
  availableRooms: "# of Available Rooms",
  physical: "# Physical Rooms",
  overbooking: "# Overbooking Rooms",
  isBold: true,
  isDrawerOpen: getHotelDrawerIsOpen(state),
});

// const mapDispatchToProps = dispatch => ({
//   keyName: ({}) => dispatch(actionName({})),
// });
//
// const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
//   ...propsFromState,
//   ...propsFromDispatch,
//   ...ownProps,
//
//   keyName: ({}) => propsFromDispatch.functionName({
//     keyName: propsFromState.value,
//   }),
//   keyName: ({}) => propsFromDispatch.functionName({
//     keyName: propsFromState.value,
//   }),
// });

export default connect(
  mapStateToProps,
  // mapDispatchToProps,
  // mergeProps,
)(CapacityRoomRowTwoCol);
