import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get the pet services available for the specified pet at the specified store.
 * For System Services Booking API
 * - Get pet services available at a store for a pet
 * @param {*} { customerKey, storeNumber, petId, ...params }
 * @returns {*} AxiosPromise
 */
export function fetchPetServicesAtStore({ customerKey, storeNumber, petId, ...params }) {
  return bookingAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/stores/${storeNumber}/petservices/pets/${petId}`,
    {
      params,
    },
  );
}

/**
 * Get the pet services available for pet training at the specified store.
 * For System Services Booking API
 * - Get pet training services available at a store
 * @param {*} { jstoreNumber }
 * @returns {*} AxiosPromise
 */
export function fetchTrainingPetServicesForStore({ storeNumber, params }) {
  return bookingAPI.get(`/v1/products/stores/${storeNumber}/petservices`, {
    params,
  });
}
