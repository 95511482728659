import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Sends Meeting Link to all the attendees
 * @param {Number} engagementId - The unique identifier of the appointment
 * @returns {*} AxiosPromise
 */
export function fetchResendMeetingLink({ engagementId }) {
  return bookingAPI.post(
    `${endpointVersionString()}/training/action/engagements/${engagementId}`,
    { type: "resendmeetinglink" },
  );
}
