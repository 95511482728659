// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchableList__lists--constrained{
    overflow-y: auto;
    max-height: 435px;
}

.searchableList__lists--full{
    overflow-y: inherit;
    min-height: inherit;
}`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/organisms/searchableList/searchableList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".searchableList__lists--constrained{\n    overflow-y: auto;\n    max-height: 435px;\n}\n\n.searchableList__lists--full{\n    overflow-y: inherit;\n    min-height: inherit;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
