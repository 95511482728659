import { isEqual } from "lodash";

/**
 * Compare frequency and dates and return true if there are any differences
 * @param {String} oldFrequency - The original frequency
 * @param {Array} oldDates - and array of date strings
 * @param {String} newFreqency - The pending frequency
 * @param {Array} newDates - and array of date strings
 * @returns Boolean
 */
export const checkIfFrequencyUpdated = (oldFrequency, oldDates, newFreqency, newDates) => {
  const didFrequencyUpdate = !!newFreqency && newFreqency !== oldFrequency;
  const didFrequencyDatesUpdate = newDates && !isEqual(newDates.sort(), oldDates.sort());

  return didFrequencyUpdate || didFrequencyDatesUpdate;
};
