// @ts-check

/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_HOTEL_ADDONS_LIST = "SET_HOTEL_ADDONS_LIST";

export const GET_HOTEL_ADDONS_BY_PET_SERVICE = "GET_HOTEL_ADDONS_BY_PET_SERVICE";
export const GET_HOTEL_ADDONS_BY_PET_SERVICE_REQUEST = "GET_HOTEL_ADDONS_BY_PET_SERVICE_REQUEST";
export const GET_HOTEL_ADDONS_BY_PET_SERVICE_SUCCESS = "GET_HOTEL_ADDONS_BY_PET_SERVICE_SUCCESS";
export const GET_HOTEL_ADDONS_BY_PET_SERVICE_FAILURE = "GET_HOTEL_ADDONS_BY_PET_SERVICE_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 * Action to update the get hotel addons list search value in  global state
 * @param {Object} obj - destructed params.
 * @param {string} obj.searchValue
 * @return {{type: string, searchValue: string}}
 */
export const getHotelAddonsList = ({ searchValue }) => ({
  type: SET_HOTEL_ADDONS_LIST,
  searchValue,
});

// API related actions
/**
 * Action to get Hotel AddOns by Pet Service from a web service.
 * @param {Object} obj - destructed params.
 * @param {string} obj.storeNumber
 * @param {string} obj.petServiceId
 * @param {string} obj.petId
 * @param {string} obj.checkInDate
 * @return {{ storeNumber: string, petServiceId: string, petId: string, type: string, checkInDate: string }}
 */
export const getHotelAddOnsByPetService = ({ storeNumber, petServiceId, petId, checkInDate }) => ({
  type: GET_HOTEL_ADDONS_BY_PET_SERVICE,
  storeNumber,
  petServiceId,
  petId,
  checkInDate,
});

export const getHotelAddOnsByPetServiceRequest = () => ({
  type: GET_HOTEL_ADDONS_BY_PET_SERVICE_REQUEST,
});

/**
 * Action, returns the resultes of the payload and loads them in global state.
 * @param {{petId: String|Number, addons: [], descriptors: []}} payload
 * @return {{petId: String|Number, addons: [], descriptors: [], type: string}}
 */
export const getHotelAddOnsByPetServiceSuccess = ({ petId, addons, descriptors }) => ({
  type: GET_HOTEL_ADDONS_BY_PET_SERVICE_SUCCESS,
  petId,
  addons,
  descriptors,
});

export const getHotelAddOnsByPetServiceFailure = error => ({
  type: GET_HOTEL_ADDONS_BY_PET_SERVICE_FAILURE,
  error,
});
