import { createSelector } from "reselect";
import { getCurrentPetServiceItem } from "../../../core/selectors/checkInOutSelector";
import { getPetServiceItems } from "../../../core/selectors/entitiesSelector";
import { getProps } from "../commonSelector";
import { HOP_NOTE_VERBIAGE } from "../../constants/checkInOutConstants";
import { get } from "lodash/fp";
import { getSelectedPetServiceItem } from "../cartSelectors";

export const getPetEngagementNoteFormData = createSelector(
  [getPetServiceItems, getCurrentPetServiceItem, getSelectedPetServiceItem],
  (petServiceItems, currentServiceItem, cartSelectedPetServiceItem) => {
    const petServiceItem = currentServiceItem || cartSelectedPetServiceItem;
    return (
      petServiceItems[petServiceItem] && {
        appointmentNotes: petServiceItems[petServiceItem].notes
      }
    );
  }
);

// Gets pet engagement note and appends HOP note verbiage if applicable
export const getPetEngagementNoteFormDataWithHOPNotes = createSelector(
  [getPetEngagementNoteFormData, getProps],
  (petEngagementNoteFormData, { isHOPNote }) => {
    const appointmentNotes = get("appointmentNotes", petEngagementNoteFormData);
    const existingHOPNote = appointmentNotes && appointmentNotes.includes(HOP_NOTE_VERBIAGE);

    if (isHOPNote && petEngagementNoteFormData && !existingHOPNote) {
      const hopAppointmentNotes = `${HOP_NOTE_VERBIAGE} \n${appointmentNotes}`;
      const editedPetEngagementNoteFormData = {
        ...petEngagementNoteFormData,
        appointmentNotes: hopAppointmentNotes
      };
      return editedPetEngagementNoteFormData;
    }
    return petEngagementNoteFormData;
  }
);
