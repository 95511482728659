import { put, takeLatest, call, all, fork, select } from "redux-saga/effects";
import moment from "moment";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import {
  loadHotelHoursRequest,
  loadHotelHoursSuccess,
  loadHotelHoursFailure,
  hotelHoursActionTypes,
  setHotelHours,
} from "./hotelHoursActions";
import { formatCalendarDateMoment } from "@/core/utils/dateUtils/formatDateTime";
import { fetchHotelHours } from "./hotelHoursEndpoints";
import { serviceTypeIds } from "@/core/constants/serviceTypesConstants";
import { getDdcStoreServiceHours, getPetsHotelStoreServiceHours } from "./hotelHoursUtils";

function* onloadHotelHours({ date, storeNumber }) {
  try {
    const loggedInStoreNumber = yield select(getStoreNumber);
    yield put(loadHotelHoursRequest());
    const endDate = formatCalendarDateMoment(moment(date).add(7, "days"));
    const response = yield call(fetchHotelHours, {
      storeNumber: storeNumber ?? loggedInStoreNumber,
      beginDate: date,
      endDate,
    });

    const data = response?.data ?? {};

    const hotelHours = getPetsHotelStoreServiceHours(data, date);
    yield put(setHotelHours({ serviceId: serviceTypeIds.HOTEL, hours: hotelHours }));

    const ddcHours = getDdcStoreServiceHours(data, date);
    yield put(setHotelHours({ serviceId: serviceTypeIds.DDC, hours: ddcHours }));

    yield put(loadHotelHoursSuccess());
  } catch (error) {
    yield put(loadHotelHoursFailure({ error }));
  }
}

function* watchLoadHotelHours() {
  yield takeLatest(hotelHoursActionTypes.LOAD_HOTEL_HOURS, onloadHotelHours);
}

export default function* hotelHoursSaga() {
  yield all([fork(watchLoadHotelHours)]);
}
