/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION = "GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION";
export const GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION_REQUEST = "GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION_REQUEST";
export const GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION_SUCCESS = "GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION_SUCCESS";
export const GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION_FAILURE = "GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getSearchFieldMainStoresByLocation = ({ searchFieldType, searchFieldValue }) => ({
  type: GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION,
  searchFieldType,
  searchFieldValue,
});

export const getSearchFieldMainStoresByLocationRequest = () => ({
  type: GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION_REQUEST,
});

export const getSearchFieldMainStoresByLocationSuccess = payload => ({
  type: GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION_SUCCESS,
  payload,
});

export const getSearchFieldMainStoresByLocationFailure = error => ({
  type: GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION_FAILURE,
  error,
});
