import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  ADD_HOTEL_CUSTOMER_NOTE,
  addHotelCustomerNoteFailure,
  addHotelCustomerNoteRequest,
} from "core/actionCreators/notesActionCreator";
import { getHotelCustomerNotes } from "dux/notes/HotelCustomerNotesSelector";
import moment from "moment";
import { getAssociateNumber, getLoggedInUser } from "core/selectors/persistentSelectors";
import { updateCustomerProfilePartial } from "core/services/systemServicesBooking/profile/customerProfile";
import { getPrismCustomerProfile } from "web/petParentProfile/selectors/getPrismCustomerProfileSelector";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { updateCustomerSuccess } from "core/actionCreators/customersActionCreators";

function* onAddHotelCustomerNote({ content, customerKey }) {
  try {
    yield put(addHotelCustomerNoteRequest());

    const prismCustomerProfile = yield select(getPrismCustomerProfile, { customerKey });
    const hotelCustomerNotes = yield select(getHotelCustomerNotes, { customerKey });
    const associateId = yield select(getAssociateNumber);
    const loggedInUser = yield select(getLoggedInUser);

    const notes = [
      ...hotelCustomerNotes,
      {
        timestamp: moment()
          .utc()
          .format(),
        content,
        associateId,
        associateDisplayName: loggedInUser?.displayName,
        associateInitials: loggedInUser?.initials,
      },
    ];

    const profiles = prismCustomerProfile?.profiles;
    let data = {};

    if (profiles.length > 0) {
      data = {
        ...prismCustomerProfile,
        profiles: profiles.map(profile => {
          const clonedProfile = { ...profile };
          if (profile?.profileType === systemName.DDC_HOTEL) {
            clonedProfile.notes = notes;
          }
          return clonedProfile;
        }),
      };
    }

    yield call(updateCustomerProfilePartial, { customerKey, data });

    yield put(
      updateCustomerSuccess({
        customer: {
          customerKey,
          prismCustomer: {
            ...data,
          },
        },
      }),
    );
  } catch (error) {
    yield put(addHotelCustomerNoteFailure({ error }));
  }
}

function* watchOnAddHotelCustomerNote() {
  yield takeEvery(ADD_HOTEL_CUSTOMER_NOTE, onAddHotelCustomerNote);
}

export default function* hotelCustomerNoteSaga() {
  yield all([fork(watchOnAddHotelCustomerNote)]);
}
