import { connect } from "react-redux";
import { getRefundConflictAppointmentPetCardInfo } from "../refundConflictAppointmentsList/refundConflictAppointmentsListSelectors";
import { getRefundPetIdForErrorModal } from "../refundPackageErrorModal/refundPackageErrorModalSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { displayRefundPackageErrorModal } from "../refundPackageErrorModal/refundPackageErrorModalActions";
import handleRedirectToPastOrFutureAppointment from "../utils/redirect/handleRedirectToPastOrFutureAppointment";
import BasePetCardComponent from "../_components/cards/BasePetCardComponent";

const mapStateToProps = (state, { appointment }) => {
  const { engagementId, itineraryId, appointmentDate } = appointment;
  const petId = getRefundPetIdForErrorModal(state);
  const customerId = getCurrentCustomerKey(state);
  return {
    itineraryId,
    customerId,
    itemDate: appointmentDate,
    cardInfo: getRefundConflictAppointmentPetCardInfo(state, { engagementId, petId }),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchOnClick: ({ itineraryId, customerId, itemDate }) => {
    handleRedirectToPastOrFutureAppointment({
      itineraryId,
      customerId,
      itemDate,
    });
    dispatch(displayRefundPackageErrorModal({ isHidden: true }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () =>
    propsFromDispatch.dispatchOnClick({
      itineraryId: propsFromState.itineraryId,
      customerId: propsFromState.customerId,
      itemDate: propsFromState.itemDate,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BasePetCardComponent);
