export const SET_HOTEL_BOOKING_FLOW_PETS = "SET_HOTEL_BOOKING_FLOW_PETS";
export const setHotelBookingFlowPets = ({ pets }) => ({
  type: SET_HOTEL_BOOKING_FLOW_PETS,
  pets,
});

export const SET_HOTEL_BOOKING_FLOW_DATE_TIME = "SET_HOTEL_BOOKING_FLOW_DATE_TIME";
export const setHotelBookingFlowDateTime = ({ startDate, endDate }) => ({
  type: SET_HOTEL_BOOKING_FLOW_DATE_TIME,
  startDate,
  endDate,
});

export const SET_HOTEL_BOOKING_FLOW_ROOM = "SET_HOTEL_BOOKING_FLOW_ROOM";
export const setHotelBookingFlowRoom = ({ roomId, pets }) => ({
  type: SET_HOTEL_BOOKING_FLOW_ROOM,
  roomId,
  pets,
});

export const REMOVE_HOTEL_BOOKING_FLOW_ROOM = "REMOVE_HOTEL_BOOKING_FLOW_ROOM";
export const removeHotelBookingFlowRoom = ({ roomId }) => ({
  type: REMOVE_HOTEL_BOOKING_FLOW_ROOM,
  roomId,
});

export const SET_HOTEL_BOOKING_FLOW_PET_ROOM_NUMBER = "SET_HOTEL_BOOKING_FLOW_PET_ROOM_NUMBER";
export const setHotelBookingFlowPetRoomNumber = ({ roomId, petId }) => ({
  type: SET_HOTEL_BOOKING_FLOW_PET_ROOM_NUMBER,
  roomId,
  petId,
});

export const DELETE_HOTEL_BOOKING_FLOW_PET_SERVICE = "DELETE_HOTEL_BOOKING_FLOW_PET_SERVICE";
export const deleteHotelBookingFlowPetService = ({ petId }) => ({
  type: DELETE_HOTEL_BOOKING_FLOW_PET_SERVICE,
  petId,
});

export const SET_HOTEL_BOOKING_FLOW_PET_SERVICE = "SET_HOTEL_BOOKING_FLOW_PET_SERVICE";
export const setHotelBookingFlowPetService = ({ petId, petServiceId, roomTypeBucketId }) => ({
  type: SET_HOTEL_BOOKING_FLOW_PET_SERVICE,
  petId,
  petServiceId,
  roomTypeBucketId,
});

export const SET_HOTEL_BOOKING_CURRENT_SERVICE_SELECTION_ROOM =
  "SET_HOTEL_BOOKING_CURRENT_SERVICE_SELECTION_ROOM";
export const setHotelBookingCurrentServiceSelectionRoom = ({ roomId }) => ({
  type: SET_HOTEL_BOOKING_CURRENT_SERVICE_SELECTION_ROOM,
  roomId,
});

export const SET_HOTEL_BOOKING_PENDING_CHANGES = "SET_HOTEL_BOOKING_PENDING_CHANGES";
export const setHotelBookingPendingChanges = pending => ({
  type: SET_HOTEL_BOOKING_PENDING_CHANGES,
  pending,
});

export const CLEAR_HOTEL_BOOKING_PENDING_CHANGES = "CLEAR_HOTEL_BOOKING_PENDING_CHANGES";
export const clearHotelBookingPendingChanges = () => ({
  type: CLEAR_HOTEL_BOOKING_PENDING_CHANGES,
});

export const APPLY_HOTEL_BOOKING_PENDING_CHANGES = "APPLY_HOTEL_BOOKING_PENDING_CHANGES";
export const applyHotelBookingPendingChanges = ({ pending }) => ({
  type: APPLY_HOTEL_BOOKING_PENDING_CHANGES,
  pending,
});

export const SET_HOTEL_BOOKING_OPEN_STEP = "SET_HOTEL_BOOKING_OPEN_STEP";
export const setHotelBookingOpenStep = step => ({
  type: SET_HOTEL_BOOKING_OPEN_STEP,
  step,
});

export const SET_HOTEL_BOOKING_FURTHEST_STEP = "SET_HOTEL_BOOKING_FURTHEST_STEP";
export const setHotelBookingFurthestStep = step => ({
  type: SET_HOTEL_BOOKING_FURTHEST_STEP,
  step,
});

export const CLEAR_HOTEL_BOOKING = "CLEAR_HOTEL_BOOKING";
export const clearHotelBooking = () => ({
  type: CLEAR_HOTEL_BOOKING,
});

export const SAVE_HOTEL_BOOKING_HOST_PETS = "SAVE_HOTEL_BOOKING_HOST_PETS";
export const setHotelBookingHostPets = ({ hostPets }) => ({
  type: SAVE_HOTEL_BOOKING_HOST_PETS,
  hostPets,
});
