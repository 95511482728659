export const SET_PIN_INITIAL_FORM_VALUES = {
  pin: "",
  confirmPin: ""
};

export const modalTypes = {
  AUTHORIZATION_REQUIRED: "AUTHORIZATION_REQUIRED",
  LOCK_USER: "LOCK_USER",
  START_SHIFT: "START_SHIFT"
};

export default {
  SET_PIN_INITIAL_FORM_VALUES,
  modalTypes
};
