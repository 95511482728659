import { createSelector } from "reselect";
import { getHotelArrivalsAppointmentState } from "dux/hotelArrivals/columnList/hotelArivalsSelector";
import { getHotelOvernightAppointmentsState } from "dux/hotelOvernight/columnList/hotelOvernightSelector";
import { getHotelDeparturesAppointmentsState } from "dux/hotelDepartures/columnList/hotelDeparturesSelector";

/**
 * Returns all scheduled hotel appointments (Arrivals, Overnight, Departures) On the Hotel Dashboard.
 *
 * @memberOf Selectors.HotelEngagements
 * @function getAllScheduledHotelAppointments
 * @returns {Array} - An array containing all scheduled hotel appointments.
 *
 * @example
 *
 * getAllScheduledHotelAppointments(state);
 */
export const getAllScheduledHotelAppointments = createSelector(
  [
    getHotelArrivalsAppointmentState,
    getHotelOvernightAppointmentsState,
    getHotelDeparturesAppointmentsState,
  ],
  (arrivals, overNight, departures) => {
    return [...arrivals, ...overNight, ...departures];
  },
);

/**
 * Returns all scheduled hotel appointments that have a roomNumber
 *
 * @memberOf Selectors.HotelEngagements
 * @function getAppointmentsByRoomNumberNotNull
 * @returns {Array} - An array containing all scheduled hotel appointments with a roomNumber assigned
 *
 * @example
 *
 * getAppointmentsByRoomNumberNotNull(state);
 */
export const getAppointmentsByRoomNumberNotNull = createSelector(
  [getAllScheduledHotelAppointments],
  appointments => {
    return appointments.filter(appointment => appointment.roomNumber !== null);
  },
);

/**
 * Returns all scheduled hotel appointments that have a room matching the given roomTypeId.
 *
 * @memberOf Selectors.HotelEngagements
 * @function getAppointmentsByRoomTypeId
 * @param {string} roomTypeId - the id of the room we want to select for
 * @returns {function} - A selector function that returns an array containing all scheduled hotel appointments with the given roomTypeId
 *
 * @example
 *
 * getAppointmentsByRoomTypeId("123")(state);
 */
export const getAppointmentsByRoomTypeId = roomTypeId =>
  createSelector([getAppointmentsByRoomNumberNotNull], appointments => {
    return appointments.filter(app => app.roomTypeId === roomTypeId);
  });
