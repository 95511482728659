/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
/**
 *  @constant
 *  type {String}
 */
export const PUT_HOTEL_ITINERARY_ADDONS = "PUT_HOTEL_ITINERARY_ADDONS";

/**
 *  @constant
 *  type {String}
 */
export const PUT_HOTEL_ITINERARY_ADDONS_REQUEST = "PUT_HOTEL_ITINERARY_ADDONS_REQUEST";

/**
 *  @constant
 *  type {String}
 */
export const PUT_HOTEL_ITINERARY_ADDONS_SUCCESS = "PUT_HOTEL_ITINERARY_ADDONS_SUCCESS";

/**
 *  @constant
 *  type {String}
 */
export const PUT_HOTEL_ITINERARY_ADDONS_FAILURE = "PUT_HOTEL_ITINERARY_ADDONS_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 * Action to put addons to itinerary
 *
 * @memberOf actions.hotel.itinerary
 * @function
 * @name putHotelItineraryAddons
 * @param {Object} payloadObj
 * @param {string} payloadObj.petId
 * @param {{ engagementId: string, addOns: Object[] }[]} payloadObj.updatedEngagements
 *  - array of engagements containing updated arrays of addons
 * @returns {Object}
 * @example putHotelItineraryAddons({ petId, updatedEngagements })
 */
export const putHotelItineraryAddons = ({ petId, updatedEngagements }) => ({
  type: PUT_HOTEL_ITINERARY_ADDONS,
  petId,
  updatedEngagements,
});

/**
 * Request action for itinerary addon put
 *
 * @memberOf actions.hotel.itinerary
 * @function
 * @name putHotelItineraryAddonsRequest
 * @returns {Object}
 * @example putHotelItineraryAddonsRequest()
 */
export const putHotelItineraryAddonsRequest = () => ({
  type: PUT_HOTEL_ITINERARY_ADDONS_REQUEST,
});

/**
 * Success action for itinerary addon put
 *
 * @memberOf actions.hotel.itinerary
 * @function
 * @name putHotelItineraryAddonsSuccess
 * @returns {Object}
 * @example putHotelItineraryAddonsSuccess()
 */
export const putHotelItineraryAddonsSuccess = () => ({
  type: PUT_HOTEL_ITINERARY_ADDONS_SUCCESS,
});

/**
 * Failure action for itinerary addon put
 *
 * @memberOf actions.hotel.itinerary
 * @function
 * @name putHotelItineraryAddonsFailure
 * @param {Object} error - error caught in try catch
 * @returns {Object}
 * @example putHotelItineraryAddonsFailure()
 */
export const putHotelItineraryAddonsFailure = error => ({
  type: PUT_HOTEL_ITINERARY_ADDONS_FAILURE,
  error,
});
