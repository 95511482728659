import React from "react";
import { connect } from "react-redux";

// Selectors
import { getPets } from "@/core/selectors/entitiesSelector";
import { getHotelEngagementsPets } from "@/dux/hotelEngagements/hotelEngagementSelectors";
import { getModalType } from "../../core/selectors/modals/petProfileModalSelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";

// Components
import { Tabs, Hr } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutSwitcher } from "@/layout/switcher/Switcher";
import { LayoutStack } from "@/layout/stack/Stack";
import { Text } from "@prism/psm-ui-components";
import { HotelCheckInOutPetParentDetails } from "dux/checkInOut/CheckInoutPetParentDetails";
import { HotelCheckInOutPetDetails } from "dux/checkInOut/CheckInoutPetDetails";
import PetParentAgentFormHotelWrapperContainer from "web/petParentProfile/petParent/petParentAgentFormWrapper/PetParentAgentFormHotelWrapperContainer";
import PetVetsTable from "web/petParentProfile/pets/petVets/petVetsTable/PetVetsTableContainer";
import { PetParentAlertLayoutCheckInWrapper } from "dux/checkInOut/layoutComponentWrappers/petParentDetails/petParentAlertsLayoutWrapperView";
import {
  CheckOutAllPetsHotelButton,
  CheckOutSinglePetHotelButton,
} from "dux/checkOutPetsHotelButton/checkOutPetsHotelButton";
import HotelPetCardContainer from "dux/_components/checkInOutCart/appointmentDetails/PetCardComponent";
import { TotalPriceLayoutCheckInOutWrapper } from "dux/checkInOut/layoutComponentWrappers/totalPrice/totalPriceLayoutWraperView";
import { HotelCheckInCartReservationNotes } from "web/bookingCartReservationNotes/BookingCartReservationNotesComponent";
import PetFavorabilityHotelContainer from "dux/petFavorability/PetFavorabilityHotelContainer";
import { DDCPetDetailsContainer } from "dux/_components/ddcPetDetails/DDCPetDetailsComponent";
import { HotelCheckInOutDateTimeWrapper } from "dux/hotelCheckInOutDateTimeCart/hotelCheckInOutTimeDateCartWrapperComponent";
import { CheckInOutFoods } from "dux/_components/checkInOutFeeding/CheckInOutFoods";
import { HotelAppointmentInfo } from "dux/appointmentInfo/AppointmentInfoComponent";
import { CheckInOutMeds } from "dux/_components/checkInOutMedications/CheckInOutMeds";
import CheckInOutAppointmentSummary from "dux/_components/checkInOutAppointmentSummary/CheckInOutApppointmentSummary";
import { AppointmentSummaryPriceLayoutCheckInWrapper } from "dux/checkInOut/layoutComponentWrappers/appointmentSummaryPrice/appointmentSummarylPriceLayoutWraperView";
import { HotelCustomerNotesV2, HotelPetNotesV2 } from "dux/notes/Notes";
import { HotelCancelSingleAppointment } from "dux/hotelCancelAppt/hotelCancelApptButton";
import { PetVaccinations } from "@/dux/vaccinations/PetVaccinations";
import { HotelCheckInOutCartAddonsSection } from "dux/hotelCartAddons/HotelCartAddonsList";
import { PetParentContactSettings } from "@/dux/petParentContactSettings/PetParentContactSettings";
import PetModalsContainer from "@/web/petParentProfile/pets/modals/PetModalsContainer";
import { HotelCheckInOutPetNeedsReviewContainer } from "dux/hotelCheckInOutPetNeedsReview/hotelCheckInOutPetNeedsReview";
import { HotelCheckInOutAppointmentStatusLabel } from "dux/_components/checkInOutCart/AppointmentStatusLabel";
import {
  HotelCheckInOutUndoMultiStatus,
  HotelCheckInOutUndoSingleStatus,
} from "dux/_components/checkInOutCart/UndoButton";
import { CheckInOutSalonInvoiceMsg } from "../checkInOutSalonInvoiceMsg/CheckInOutSalonInvoiceMsg";
import { HotelCheckInOutTotalPrice } from "../hotelCheckInOutTotalPrice/HotelCheckInOutTotalPrice";
import { HotelCheckInOutRebookButton } from "../appointmentRebook/AppointmentRebookButton";
import { HotelCheckInOutRebookLoginMsg } from "../appointmentRebook/AppointmentRebookLoginMsg";
import { CheckInOutPetForm } from "@/web/petParentProfile/pets/petForm/PetFormContainer";
import { DifferentStoreMessage } from "./DifferentStoreMessage";

// Utils
import fadeIn from "web/common/styles/Animation/fade/fadeIn";

// Constants
import { color } from "web/common/styles/theme";
import * as modularScale from "@/layout/modularScale";

export const CheckOutLayoutComponent = props => {
  const {
    allPets,
    componentId,
    customerKey,
    currentPetId,
    hotelEngagementsPets,
    modalType,
    setPetId,
  } = props;

  const petIndex = hotelEngagementsPets?.indexOf(currentPetId);
  const activeTab = petIndex === -1 ? 0 : petIndex;

  const handleActiveTabChange = index => {
    const petId = hotelEngagementsPets[index];
    setPetId(petId);
  };

  return (
    <LayoutBox
      id={`${componentId}__content`}
      style={{ animation: `${fadeIn()} 2s ease` }}
      padding="scale-0"
    >
      <LayoutSwitcher threshold="scale-G9">
        {/* LEFT COLUMN -------------------------------------------------------------------------------------------*/}
        <LayoutBox
          padding="scale-G1"
          style={{
            flexGrow: modularScale.S_3,
            boxShadow: "0 0.625rem 2.125rem 0 rgb(13 18 27 / 12%)",
          }}
        >
          <LayoutStack>
            <HotelCheckInOutPetParentDetails />

            {/* DO NOT BOOK, VIP, PSA ------------------------------------------------------------*/}
            <PetParentAlertLayoutCheckInWrapper />

            {/* CUSTOMER CONTACT SETTINGS  -----------------------------------------------------------------*/}
            <Hr style={{ width: "100%", margin: "15px 0" }} />
            <PetParentContactSettings customerKey={customerKey} />
            <Hr style={{ width: "100%", margin: "15px 0" }} />

            {/* Replace with component */}
            <PetParentAgentFormHotelWrapperContainer />

            {/* CUSTOMER NOTES  -----------------------------------------------------------------*/}
            <HotelCustomerNotesV2 />
          </LayoutStack>
        </LayoutBox>

        <LayoutBox padding="scale-0">
          <LayoutStack space="scale-0">
            {/* PET LIST ------------------------------------------------------------------------------------------*/}
            <LayoutBox padding="scale-0">
              <Tabs
                key={Object.keys(allPets)}
                activeIndex={activeTab}
                onChange={handleActiveTabChange}
              >
                {hotelEngagementsPets?.map(petId => {
                  const petName = allPets[petId]?.petName;
                  return <Tabs.Panel title={petName} key={petId}></Tabs.Panel>;
                })}
              </Tabs>
            </LayoutBox>

            <LayoutSwitcher threshold="scale-G8">
              {/* CENTER COLUMN ------------------------------------------------------------------------------------------*/}
              <LayoutBox
                id={`${componentId}__column02`}
                padding="scale-G1"
                style={{
                  borderRight: `1px solid ${color.borderGrey}`,
                }}
              >
                <LayoutStack space="scale-G1">
                  <HotelCheckInOutPetDetails customerKey={customerKey} petId={currentPetId} />

                  {/* PET FORM CONTAINER  -----------------------------------------------------------------*/}
                  <CheckInOutPetForm customerKey={customerKey} petId={currentPetId} />

                  {/* DDC PET DETAILS CONTAINER  -----------------------------------------------------------------*/}
                  <DDCPetDetailsContainer />

                  {/* PET FAVORABILITY  -----------------------------------------------------------------*/}
                  <PetFavorabilityHotelContainer disabled={false} topLine={false} />

                  {/* PET VACCINATIONS  -----------------------------------------------------------------*/}
                  <PetVaccinations />

                  {/* PET NOTES  -----------------------------------------------------------------*/}
                  <HotelPetNotesV2 />
                  {/* Replace with component */}

                  <PetVetsTable customerKey={customerKey} petId={currentPetId} />

                  {/* <PetModalsContainer */}
                  {modalType && <PetModalsContainer modalType={modalType} />}
                </LayoutStack>
              </LayoutBox>

              {/* RIGHT COLUMN -------------------------------------------------------------------------------------------*/}
              <LayoutBox id={`${componentId}__column03`} padding="scale-G1">
                <LayoutStack space="scale-G1">
                  {/* TOTAL PRICE AND CHECK OUT BUTTON --------------------------------------------------*/}
                  <LayoutBox
                    padding="scale-0"
                    style={{ borderBottom: `1px solid ${color.borderGrey}`, padding: "2rem 0" }}
                  >
                    <LayoutCluster style={{ justifyContent: "space-between" }}>
                      <HotelCheckInOutTotalPrice />
                      <CheckInOutSalonInvoiceMsg />
                      <LayoutCluster style={{ justifyContent: "space-between" }}>
                        <CheckOutSinglePetHotelButton />
                        <CheckOutAllPetsHotelButton />
                      </LayoutCluster>
                    </LayoutCluster>
                  </LayoutBox>

                  <LayoutStack space="scale-G2">
                    <Text bold size="text-size-lg" align="left">
                      Appointment Details
                    </Text>

                    <DifferentStoreMessage />

                    <HotelAppointmentInfo />

                    <HotelPetCardContainer petId={currentPetId} />
                  </LayoutStack>

                  {/* CHECK IN STATUS LABEL AND UNDO --------------------------------------------------*/}
                  <LayoutCluster style={{ justifyContent: "space-between" }}>
                    <HotelCheckInOutAppointmentStatusLabel petId={currentPetId} />
                    <LayoutCluster>
                      <HotelCheckInOutUndoSingleStatus petId={currentPetId} />
                      <HotelCheckInOutUndoMultiStatus />
                    </LayoutCluster>
                  </LayoutCluster>

                  <LayoutBox padding="scale-0">
                    <LayoutStack space="scale-G2">
                      {/* CHECK IN DATE AND TIME --------------------------------------------------*/}
                      <HotelCheckInOutDateTimeWrapper />

                      {/* REVIEW RESERVATION CHECKBOX ------------------------------------------------- */}
                      <HotelCheckInOutPetNeedsReviewContainer />

                      {/* CHECK IN APPOINTMENT SUMMARY --------------------------------------------------*/}
                      <CheckInOutAppointmentSummary />
                    </LayoutStack>
                  </LayoutBox>

                  {/* SUMMARY PRICE DETAILS --------------------------------------------------*/}
                  <AppointmentSummaryPriceLayoutCheckInWrapper />

                  {/* CHECK IN FOODS --------------------------------------------------*/}
                  <CheckInOutFoods petId={currentPetId} />

                  {/* CHECK IN MEDICATIONS --------------------------------------------------*/}
                  <CheckInOutMeds petId={currentPetId} />

                  {/* CHECK IN ADD-ONS --------------------------------------------------*/}
                  <HotelCheckInOutCartAddonsSection />

                  <HotelCheckInCartReservationNotes />

                  <LayoutCluster style={{ justifyContent: "flex-end", margin: 0 }}>
                    <HotelCancelSingleAppointment />
                  </LayoutCluster>

                  <LayoutCluster style={{ justifyContent: "flex-end", margin: 0 }}>
                    <HotelCheckInOutRebookButton />
                  </LayoutCluster>

                  <HotelCheckInOutRebookLoginMsg />

                  {/* Total Price, Paid Invoice and Invoice Generated  */}
                  <TotalPriceLayoutCheckInOutWrapper />
                </LayoutStack>
              </LayoutBox>
            </LayoutSwitcher>
          </LayoutStack>
        </LayoutBox>
      </LayoutSwitcher>
    </LayoutBox>
  );
};

// HOTEL CHECK OUT LAYOUT CONTAINER --------------------------------------------------------------------------------------------

export const HotelCheckOutLayout = connect(state => {
  const hotelEngagementsPets = getHotelEngagementsPets(state);
  const allPets = getPets(state);
  const modalType = getModalType(state);
  const currentPetId = getCurrentPet(state);

  return {
    allPets,
    componentId: "HotelCheckOutLayout",
    hotelEngagementsPets,
    modalType,
    currentPetId,
  };
})(CheckOutLayoutComponent);

// we plan to bring Salon over from legacy code to this format. plan Place Salon SalonCheckOutLayout container here.
