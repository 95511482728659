// @ts-check
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

// Actions
import { setHotelManageRoomSuspensionDetails } from "./hotelManageRoomDetailsDetailsActions";

// Components
import { Form, Text, Layout } from "@prism/psm-ui-components";

// Constants
import { REQUIRED_FIELD_ERROR_MESSAGE } from "@/core/constants/validationErrors";

/**
 * Room Suspensions Details Input Component
 * @param {object} props - props object
 * @param {string} props.componentId - Component Id
 * @param {Function} props.handleChange - handle change fn
 * @param {boolean} props.isHidden - isHidden to control dom rendering
 * @param {string} props.initialDetails - inital suspension details
 * @returns {(JSX.Element|null)}
 */
export const HotelManageRoomDetailsDetails = props => {
  // @ts-ignore
  const { componentId, handleChange, isHidden, initialDetails } = props;

  const [pendingDetails, setPendingDetails] = useState(initialDetails);
  const [error, setError] = useState("");

  const min = 10;
  const max = 255;
  const detailsSchema = Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .min(min, `${min} chars min`)
    .max(max);

  const handleOnChange = (/** @type {React.ChangeEvent<HTMLInputElement>} */ e) => {
    if (e.target.value.length > max) return;

    setPendingDetails(e.target.value);
  };

  // @ts-ignore
  const handleBlur = (/** @type {React.FocusEvent<HTMLInputElement>} */ e) => {
    detailsSchema
      .validate(pendingDetails)
      .then(details => {
        setError("");
        handleChange(details);
      })
      .catch(err => {
        setError(err.message);
        handleChange("");
      });
  };

  if (isHidden) return null;

  return (
    <Layout.Box id={componentId}>
      <Form.Field.Text
        placeholder={`Enter Details (${min} chars min)`}
        onChange={handleOnChange}
        onBlur={handleBlur}
        value={pendingDetails}
        variant="material"
        style={{ width: "100%" }}
      ></Form.Field.Text>

      {error && <Text color="text-color-red">{error}</Text>}
    </Layout.Box>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const HotelManageRoomDetailsDetailsInput = connect(
  // @ts-ignore
  (_, ownProps) => {
    // @ts-ignore
    const { initialDetails, handleChange } = ownProps;
    return {
      componentId: "HotelManageRoomDetailsDetailsInput",
      handleChange,
      isHidden: false,
      initialDetails,
    };
  },
)(HotelManageRoomDetailsDetails);
