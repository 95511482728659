import React from "react";
import styled from "styled-components";

import TextComponent from "@/dux/_components/genericLayoutComponents/TextComponent";
import RefundConflictAppointmentsListContainer from "@/dux/refundConflictAppointmentsList/RefundConflictAppointmentsListContainer";
import CommonButton from "@/web/common/commonButton";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 10px auto 20px auto;
`;

export default function RefundPackageErrorModalComponent(props) {
  const { isHidden, componentID, headerText, messageText, onModalClose } = props;

  if (!isHidden) {
    return (
      <SectionWrapper id={componentID}>
        <TextContainer>
          <TextComponent
            text={headerText}
            styleObj={{ fontWeight: "bold", textAlign: "center", marginBottom: "1em" }}
          />
          <TextComponent
            type="div"
            text={messageText}
            styleObj={{ textAlign: "center", marginBottom: "1em" }}
          />
          <RefundConflictAppointmentsListContainer maxHeight="500px" />
        </TextContainer>
        <CommonButton label="OK" onClick={onModalClose} />
      </SectionWrapper>
    );
  }

  return null;
}
