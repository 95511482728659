import { createSelector } from "reselect";
import { getAssociate } from "@/core/selectors/entitiesSelector";
import { getState } from "@/core/selectors/commonSelector";

export const getTrainingAssociates = (state) => state.trainingAssociates;
export const getTrainingClassFilterButtonData = (state) => state.trainingClassFilterButtonData;

export const getTrainingAssociatesData = createSelector(
  [getTrainingAssociates, getState],
  (associateIds, state) => {
    const associates = associateIds.map((associateId) => {
      return getAssociate(state, { associateId });
    });

    return associates;
  },
);

// Provides the default data for trainer filter buttons, but also provides the updated state with a
// small translation rom an object to an array as the ToggleButtonListWithSelectAll requires an array.
export const getAssociatesFilterButtonData = createSelector(
  [getTrainingAssociates, getState, getTrainingClassFilterButtonData],
  (associateIds, state, buttonDataList) => {
    if (buttonDataList.length === 0) {
      const associates = associateIds.map((associateId) => {
        const associate = getAssociate(state, { associateId });
        const name = associate.associateName;
        const associateObj = {
          id: associate.associateId,
          name: name,
          isPressed: false,
          filterValue: name,
        };
        return associateObj;
      });

      return associates;
    }

    return Object.values(buttonDataList);
  },
);

export const getTrainingAssociatesDropdownOptions = createSelector(
  [getTrainingAssociatesData],
  (associates) => {
    return associates
      .filter((associate) => associate?.isVirtualTrainer)
      .map((associate) => associate?.associateName);
  },
);
