import { createSelector } from "reselect";
import getFeatureFlagsState from "./_featureFlagsState";
import getSearchFeatureText from "../../searchForFeature/selectors/getSearchFeatureText";
import valueMatchesSearchString from "../../../core/utils/stringManipulationUtils/valueMatchesSearchString";

const getFeatureFlagsList = createSelector(
  [getFeatureFlagsState, getSearchFeatureText],
  (featureFlags, searchFeatureText) => {
    //
    // Wrap all feature flag objects in an array so the UI can easily map over them.
    const list = Object.values(featureFlags);

    // Looking at the id value in each object in the list and and filtering out any items that do not
    // match the text that was searched.
    return list.filter(feature => valueMatchesSearchString(feature.id, searchFeatureText));
  },
);

export default getFeatureFlagsList;
