import { connect } from "react-redux";
import {
  getCustomersSearchResults,
  getSearchResultsToDisplay,
} from "@/core/selectors/searchSalonSRCSelector";
import { searchConstants } from "@/core/constants/searchConstants";
import CustomerCardListComponent from "@/web/searchSalonSRC/customerCard/CustomerCardListComponent";

const mapStateToProps = state => ({
  isHidden: getSearchResultsToDisplay(state) !== searchConstants.CUSTOMER,
  searchResults: getCustomersSearchResults(state),
});

export default connect(mapStateToProps)(CustomerCardListComponent);
