import { connect } from "react-redux";
import CapacityRoomRowTwoCol from "dux/_components/roomCapacity/CapacityRoomRowTwoCol";
import { getDdcRoomCapacityByIndex } from "dux/ddcRoomCapacity/ddcRoomCapacitySelector";

const mapStateToProps = (state, { index }) => {
  const capacity = getDdcRoomCapacityByIndex(index)(state);

  return {
    isHidden: false,
    componentID: "CapacityRoomRow__DDC",
    description: capacity?.description,
    value: capacity?.value,
  };
};

export default connect(mapStateToProps)(CapacityRoomRowTwoCol);
