import { connect } from "react-redux";
import PetParentProfileModals from "../../../petParentProfile/petParentProfile/modals/petParentProfileModals";
import { hidePetParentProfileModal } from "../../../../core/actionCreators/petParentProfileActionCreator";
import { clearRebookErrorMessages } from "../../../../core/actionCreators/ui/web/commonActionCreators";
import vetsActionCreators from "../../../../core/actionCreators/vetsActionCreators";

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(hidePetParentProfileModal()),
  clearRebookErrorMessages: () => dispatch(clearRebookErrorMessages())
});

export default connect(
  null,
  mapDispatchToProps
)(PetParentProfileModals);
