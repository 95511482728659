import { createSelector } from "reselect";
import { getAssociates } from "./entitiesSelector";
import { getAssociatesWeekSchedule } from "./schedulesSelectors";
import getDifferenceInDecimalHours from "../utils/dateUtils/getDifferenceInDecimalHours";
import formatShortDayNameMonthDay from "../utils/dateUtils/formatShortDayNameMonthDay";
import militaryToStandardTime from "../utils/dateUtils/militaryToStandardTime";
import arraysToObject from "../utils/arrayUtils/arraysToObject";
import { sortByPropertyAsc } from "../utils/arrayUtils/sortByProperty";
import flattenArray from "../utils/arrayUtils/flattenArray";
import groupBy from "../utils/arrayUtils/groupBy";

// Constants --------------------------------------------------------------------------------------
const NO_ACTIVITIES = "-----";
const ROW_TYPES = {
  total: "total-row",
  associate: "associate",
  groupHeader: "group-header",
  groupSubtotal: "group-subtotal",
};

// rowlabel is the first column
// totalColumn is the last column
const COLUMN_PATHS = {
  rowLabel: "rowLabel",
  totalColumn: "totalColumn",
};

const hoursPerWeekColumn = {
  label: "Hours per week",
  path: COLUMN_PATHS.totalColumn,
};
const rowLabelColumn = { label: "", path: COLUMN_PATHS.rowLabel };

// Days are the columns of the table. Reordering them will be reflected in the view.
const DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const emptyDaysObject = DAYS.reduce((acc, cur) => ({ ...acc, [cur]: "" }), {});

// Functions --------------------------------------------------------------------------------------
/**
 * Responsible for formatting a YYYY-MM-DD date into an object with two properties:
 * label is an array, where each value will be displayed on a new line: ["Mon,", "August", "25"]
 * path is the first value of the label array, without the comma: "Mon"
 */
function formatHeaderDates(date) {
  const formatDate = formatShortDayNameMonthDay(date);
  const monthWithDay = formatDate.substring(5, formatDate.length);
  const label = [formatDate.substring(0, 4), ...monthWithDay.split(" ")];
  const path = formatDate.split(",")[0];
  return { label, path };
}

/**
 * Responsible for returning a positive number for "Work" hours only.
 * And negative numbers for anything else, like an "Absence"
 */
function activityTimeTotals(start, end, type) {
  const diff = getDifferenceInDecimalHours(start, end);
  return type === "Work" ? diff : -diff;
}

/**
 * Responsible for returning an array of accumulated subtotals. No activity is returned as 0.
 */
function activitySumsByDay(activities) {
  const sumSubtotals = (acc, cur) => acc.subtotal + cur.subtotal;
  return activities.map(day => {
    const includesActivity = !day.includes(NO_ACTIVITIES);
    if (includesActivity) {
      return day.length > 1 ? day.reduce(sumSubtotals) : day[0].subtotal;
    }
    return 0;
  });
}

/**
 * Responsible for returning any activity, work or absence, with two additional properties:
 * Formatted time ranges is an array: ["11:00 AM-", "12:00:00 PM"]
 * Subtotal is a positive or negative number depending on the type of activity
 */
function getFormattedSchedules(day) {
  return day.map(activity => {
    const { type, startTime, endTime } = activity;
    const start = militaryToStandardTime(startTime);
    const end = militaryToStandardTime(endTime);

    const formatTimeRanges = { formattedRange: [`${start}-`, end] };
    const subtotal = {
      subtotal: activityTimeTotals(start, end, type),
    };

    return { ...activity, ...formatTimeRanges, ...subtotal };
  });
}

/**
 * Responsible for returning activities with additional data: subtotal and formatted ranges
 */
function formatWeeklySchedules(week) {
  return week.map((day, idx) =>
    day.length ? { [DAYS[idx]]: getFormattedSchedules(day) } : { [DAYS[idx]]: NO_ACTIVITIES },
  );
}

/**
 * Format numbers all numbers to a decimal precision, with the exception of "zero"
 */
function toFixedIgnoreZero(number, digits) {
  return number === 0 ? number : number.toFixed(digits);
}

// Selector ---------------------------------------------------------------------------------------
// Responsible for returning an object containing two proerties: columns and rows.
// Columns is an array of objects: [{label: "Monday", path: "mon"}, {label: "Tuesday", path: "tue"}]
// Rows is also an array of objects, and each key corresponds to a column "path": [{mon: 1, tue: 2}, {mon 3, tue: 4}]

const selectors = [getAssociates, getAssociatesWeekSchedule];

const getPrintSelector = createSelector(selectors, (associates, schedules) => {
  const associateIds = Object.keys(associates);
  const associateSchedules = associateIds.map(id => ({
    ...associates[id],
    ...schedules[id],
  }));

  const associatesWithActivity = associateSchedules.filter(associate => associate.activities);
  const associatesArray = flattenArray(
    Object.values(groupBy(associatesWithActivity, "associateGroup")),
  );

  const associatesSortedByName = sortByPropertyAsc(associatesArray, "firstName");
  const associatesWithGroups = flattenArray(associatesSortedByName).map(associate => ({
    ...associate,
    ...{ associateGroup: associate.associateGroup || "" },
  }));

  // Table Header ---------------------------------------------------------------------------------
  const activityDates = associatesWithGroups.map(associate => Object.keys(associate.activities));
  const activityDatesFlatten = flattenArray(activityDates);
  const scheduleColumns = activityDatesFlatten.map(date => formatHeaderDates(date));

  // Sorting keeps the order of the day columns aligned with the DAYS constant
  const scheduleColomnsSorted = scheduleColumns.sort(
    (a, b) => DAYS.indexOf(a.path) - DAYS.indexOf(b.path),
  );

  // Reordering this array will be reflected in the view
  const allColumns = [rowLabelColumn, ...scheduleColomnsSorted, hoursPerWeekColumn];

  // Table Rows -----------------------------------------------------------------------------------
  const allActivity = associatesWithGroups.map(associate => Object.values(associate.activities));
  const allActivityFormatted = allActivity.map(week => formatWeeklySchedules(week));

  const activityValues = allActivityFormatted.map(schedule => Object.values(schedule));
  const activityValuesFlat = activityValues.map(schedule => Object.assign({}, ...schedule));
  const activitiesWithAssociateGroups = associatesWithGroups.map((associate, idx) => ({
    ...associate,
    ...activityValuesFlat[idx],
  }));

  const associateRows = activitiesWithAssociateGroups.map(associate => {
    const { associateName, associateGroup } = associate;
    const { Mon, Tue, Wed, Thu, Fri, Sat, Sun } = associate;
    const activities = [Mon, Tue, Wed, Thu, Fri, Sat, Sun];

    // first column
    const rowLabel = [associateName, associateGroup];

    // summary statistics: sum hrs by group and total sum hrs
    const subtotalValues = activitySumsByDay(activities);
    const subtotals = arraysToObject(DAYS, subtotalValues);
    const totalColumn = subtotalValues.reduce((acc, cur) => acc + cur, 0);

    // formatted ranges are the hrs displayed on each day
    const getFormattedRanges = activities.map(value => {
      if (Array.isArray(value)) {
        return value.map(activity => activity.formattedRange);
      }
      return value;
    });

    const dailyActivities = arraysToObject(DAYS, getFormattedRanges);

    return {
      type: ROW_TYPES.associate,
      ...{ rowLabel },
      ...dailyActivities,
      associateGroup,
      totalColumn: toFixedIgnoreZero(totalColumn, 2),
      subtotals,
    };
  });

  // Get the associate groups for computing and formatting the header, subtotal and total rows
  const associateGroups = activitiesWithAssociateGroups.map(associate => associate.associateGroup);
  const associateGroupsNoDuplicates = [...new Set(associateGroups)];

  // Group Headers Rows ---------------------------------------------------------------------------
  // Example: "9 Professional PetStylist - Dog & Cat"
  const associateGroupCounts = associateGroups.reduce((acc, cur) => {
    acc[cur] = 1 + acc[cur] || 1;
    return acc;
  }, {});

  const groupHeaders = associateGroupsNoDuplicates.map(group => {
    const count = associateGroupCounts[group];
    const rowLabel = { rowLabel: `${count} ${group || "Null"}` };
    return {
      ...emptyDaysObject,
      ...rowLabel,
      totalColumn: "",
      associateGroup: group,
      type: ROW_TYPES.groupHeader,
    };
  });

  // Group Subtotals Rows -------------------------------------------------------------------------
  // Subtotal of work hours, by associate group.
  const associateGroupSubtotals = groupBy(associateRows, "associateGroup");
  const groupSubtotalsByDay = Object.values(associateGroupSubtotals).map(associatesByGroup => {
    const subtotalsByGroup = associatesByGroup.map(associate => associate.subtotals);
    const subtotalsReducer = DAYS.map(day =>
      subtotalsByGroup.reduce((acc, cur) => acc + cur[day], 0),
    );
    const subtotalsRounded = subtotalsReducer.map(value => toFixedIgnoreZero(value, 2));
    return arraysToObject(DAYS, subtotalsRounded);
  });

  const groupSubtotalRows = associateGroupsNoDuplicates.map((group, idx) => {
    const rowLabel = { rowLabel: `Subtotals for ${group || "Null"}` };
    const subtotals = groupSubtotalsByDay[idx];
    const total = Object.values(subtotals).reduce((acc, cur) => acc + parseInt(cur, 10), 0);
    return {
      ...subtotals,
      ...rowLabel,
      totalColumn: toFixedIgnoreZero(total, 2),
      associateGroup: group,
      type: ROW_TYPES.groupSubtotal,
    };
  });

  // Daily Totals Row ------------------------------------------------------------------------------
  // This is the sum of all subtotal work-hours and the last row in the table.
  const subtotalValues = Object.values(groupSubtotalsByDay);
  const subtotalSum = DAYS.map(day =>
    subtotalValues.reduce((acc, cur) => acc + parseInt(cur[day], 10), 0),
  );
  const subtotals = arraysToObject(
    DAYS,
    subtotalSum.map(value => toFixedIgnoreZero(value, 2)),
  );
  const total = Object.values(subtotals).reduce((acc, cur) => acc + parseInt(cur, 10), 0);

  const totalSumRow = {
    ...subtotals,
    totalColumn: toFixedIgnoreZero(total, 2),
    rowLabel: "Totals",
    type: ROW_TYPES.total,
  };

  // Combine Rows  --------------------------------------------------------------------------------
  // Changes to the order of the combineRows array will be reflected in the view.
  // More rows can be inserted here, too.
  const combineRows = [...groupHeaders, ...associateRows, ...groupSubtotalRows];
  const groupRows = groupBy(combineRows, "associateGroup");
  const groupFlat = Object.keys(groupRows).map(key => [...groupRows[key]]);
  const tableRows = groupFlat.reduce((acc, cur) => acc.concat(cur), []);

  // The totalSumRow is the sum of each column and it is the last row in the table
  const rowsWithTotal = [...tableRows, totalSumRow];

  return { columns: allColumns, rows: rowsWithTotal };
});

export { getPrintSelector as default };
