import React from "react";
import styled from "styled-components";
import { AFontRegular } from "../../common/styles/abstractStyledComponents";
import { dropDownUri } from "../../common/styles/theme";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  opacity: ${props => (props.isEnabled ? "initial" : 0.3)};
`;

export const SelectionCopy = AFontRegular.extend`
  cursor: pointer;
  padding: 10px 0;
  pointer-events: ${props => (props.isEnabled ? "initial" : "none")};
  opacity: ${props => (props.isEnabled ? "auto" : "0.5")};

  ${"" /* This is meant to not stretch the element beyond its content, to prevent unwanted clicking */} display: inline-block;
  align-self: baseline;

  &:after {
    content: "";
    padding-left: 20px;
    background-image: url(${dropDownUri});
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export default function CustomFlyOutSelection(props) {
  const { currentOption, onSelection } = props;

  return (
    <SectionWrapper {...props}>
      {props.isDisabled}
      <SelectionCopy
        onClick={e => {
          onSelection({ e, ...props });
        }}
        {...props}
      >
        {currentOption}
      </SelectionCopy>
    </SectionWrapper>
  );
}
