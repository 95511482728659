import React from "react";
import styled from "styled-components";

const SectionWrapper = styled.span`
  display: flex;
  justify-content: space-between;
`;

export default function PricingLineItemComponent({ isHidden, componentID, label, price }) {
  if (!isHidden) {
    return (
      <SectionWrapper id={componentID} data-testid="pricing-line-item">
        <div>{label}</div>
        <div>{price}</div>
      </SectionWrapper>
    );
  }

  return null;
}
