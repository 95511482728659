import { bookingAPI } from "core/services/systemServicesBooking/_axiosConfigSystemServicesBooking";

/**
 * For Hotel Bedding Types API
 * - Get Bedding Types By Species -
 * @returns {*} AxiosPromise
 */

export function getHotelBeddingTypes() {
  return bookingAPI.get(`/hotel/v1/beddingTypes`);
}
