import React from "react";
import { connect } from "react-redux";

// Selectors
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { selectCurrentHotelEngagement } from "dux/hotelEngagements/hotelEngagementSelectors";
import { selectHotelItineraryHasSalon } from "../hotelItinerary/hotelItinerarySelectors";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { TextPassage } from "@petsmart-ui/sparky";
import SplitLineWithMessage from "@/web/common/SplitLineWithMessage";

// Constants
import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { checkOutReminder } from "./checkOutReminderConstants";

/**
 * React view component for hotel check out reminder
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name CheckOutReminder
 * @param {Object} props
 * @param {string} props.componentId
 * @param {Boolean} props.isHidden
 * @param {JSX|string} props.mainReminder
 * @param {JSX|string} props.overnightReminder
 * @param {JSX|string} props.salonReminder
 * @returns {JSX.Element}
 * @example const container = connect()(CheckOutReminder)
 */
export const CheckOutReminder = props => {
  const { componentId, isHidden, mainReminder, overnightReminder, salonReminder } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId}>
      <TextPassage>
        <LayoutStack>
          <span>{mainReminder}</span>
          <span>{overnightReminder}</span>
          <span>{salonReminder}</span>
        </LayoutStack>
      </TextPassage>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for single pet check out button
 * @see {@link Views.Hotel.Itinerary.CheckOutReminder}
 * @summary Located on the hotel check-out page
 * @memberOf Views.Hotel.Itinerary
 * @function
 * @name HotelCheckOutReminder
 * @returns {JSX.Element|null}
 * @example <HotelCheckOutReminder />
 */
export const HotelCheckOutReminder = connect(state => {
  const delimiter = "\n";

  // Check if overnight
  const petId = getCurrentPet(state);
  const engagementType = selectCurrentHotelEngagement(state, { petId })?.engagementType;
  const isOvernight = engagementType === engagementTypes.OVERNIGHT_BOARDING;

  // Check has salon
  const hasSalonAppt = selectHotelItineraryHasSalon(state);

  return {
    componentId: "HotelCheckOutReminder",
    mainReminder: SplitLineWithMessage({ string: checkOutReminder.mainMsg, delimiter }),
    overnightReminder:
      isOvernight && SplitLineWithMessage({ string: checkOutReminder.overnightMsg, delimiter }),
    salonReminder: hasSalonAppt && checkOutReminder.salonMsg,
  };
})(CheckOutReminder);
