import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { currentStoreReducer } from "dux/persistent/currentStoreReducer";
import { currentCustomerKeyReducer } from "dux/persistent/currentCustomerKeyReducer";
import { currentPetReducer } from "dux/persistent/currentPetReducer";
import { systemTypeReducer } from "dux/persistent/systemTypeReducer";
import { systemBookingFlowReducer } from "dux/persistent/systemBookingFlowReducer";
import { associateJobCodeReducer } from "dux/persistent/associateJobCodeReducer";
import { searchComponentNameReducer } from "dux/persistent/searchComponentNameReducer";
import { sessionReducer } from "dux/persistent/sessionReducer";
import { redirectToForgotPinReducer } from "dux/persistent/redirectToForgotPinReducer";
import { authenticatedSubscriptionKeyReducer } from "dux/persistent/authenticatedSubscriptionKeyReducer";
import { secureRedirectRouteReducer } from "dux/persistent/secureRedirectRouteReducer";
import { prevSearchComponentNameReducer } from "dux/persistent/prevSearchComponentNameReducer";
import { testJobRolesReducer } from "dux/persistent/testJobRolesReducer";
import { manageTrainingClassPageReducer } from "dux/persistent/manageTrainingClassPageReducer";
import enableDisableWorkflowFeatureFlagReducer
  from "web/enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagReducer";

const persistentReducers = combineReducers({
  currentStore: currentStoreReducer,
  currentPet: currentPetReducer,
  currentCustomerKey: currentCustomerKeyReducer,
  systemType: systemTypeReducer,
  systemBookingFlow: systemBookingFlowReducer,
  associateJobCode: associateJobCodeReducer,
  searchComponentName: searchComponentNameReducer,
  prevSearchComponentName: prevSearchComponentNameReducer,
  session: sessionReducer,
  redirectToForgotPin: redirectToForgotPinReducer,
  secureRedirectRoute: secureRedirectRouteReducer,
  testJobRoles: testJobRolesReducer,
  authenticatedSubscriptionKey: authenticatedSubscriptionKeyReducer,
  manageTrainingClassPage: manageTrainingClassPageReducer,
  workflowFeatureFlags: enableDisableWorkflowFeatureFlagReducer,
});

const persistConfig = {
  key: "persistent",
  storage,
  whitelist: [
    "currentStore",
    "currentPet",
    "currentCustomerKey",
    "associateJobCode",
    "searchComponentName",
    "prevSearchComponentName",
    "session",
    "redirectToForgotPin",
    "secureRedirectRoute",
    "testJobRoles",
    "systemType",
    "systemBookingFlow",
    "authenticatedSubscriptionKey",
    "manageTrainingClassPage",
    "workflowFeatureFlags",
  ],
};

export default persistReducer(persistConfig, persistentReducers);
