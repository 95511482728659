/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_CUSTOMER_FTCO_ELIGIBLE = "SET_CUSTOMER_FTCO_ELIGIBLE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setCustomerFTCOEligible = payload => {
  return {
    type: SET_CUSTOMER_FTCO_ELIGIBLE,
    payload,
  };
};
