import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";
/**
 * Fetches a training itinerary from the web services booking service based upon the customer key and itinerary ID passed in.
 * @param {Number} customerKey - The customer key to fetch a training appointment for
 * @param {String} itineraryId - The itinerary id of the training class appointment to fetch
 * @returns {*} AxiosPromise
 */
export function getTrainingItineraryById({ customerKey, itineraryId }) {
  return bookingAPI.get(
    `${endpointVersionString()}/training/customers/${customerKey}/itineraries/${itineraryId}`,
  );
}

/**
 * Creates a training appointment from the web services booking service. Moves an appointment to "Pending" status.
 * @param {Number} petServiceId - The pet service id to fetch availability for
 * @param {Number} customerKey - The customer key to schedule a training class for
 * @param {Number} classSessionId - The id of the class session you would like to book an appointment for
 * @param {String} startDateTime - The start time of the appointment to schedule
 * @param {Number} associateId - The id of the training associate who is running the class
 * @param {Number} duration - The length of the pet training class session
 * @param {String} durationUnitOfMeasure - The unit of measure for the class duration (i.e. "Minutes", "Hours")
 * @param {String[]} behaviors - The unit of measure for the class duration (i.e. "Minutes", "Hours")
 * @param {String} notes - Notes for the training appointment
 * @param {String} customerPhone - Customer phone number
 * @param {String} customerPhoneType - Customer phone type (i.e. Work, Home, etc.)
 * @returns {*} AxiosPromise
 */
export function postTrainingItinerary({
  petServiceId,
  customerKey,
  petId,
  classSessionId,
  startDateTime,
  associateId,
  duration,
  durationUnitOfMeasure,
  behaviors,
  notes,
  customerPhone,
  customerPhoneType,
  customerTimeZone,
}) {
  return bookingAPI.post(
    `${endpointVersionString()}/training/customers/${customerKey}/itineraries`,
    [
      {
        petId,
        petServices: [
          {
            petServiceId,
            classSessionId,
            startDateTime,
            associateId,
            duration,
            durationUnitOfMeasure,
            behaviors,
            notes,
            customerPhone,
            customerPhoneType,
          },
        ],
        customerTimeZone,
      },
    ],
  );
}

/**
 * Update training appointment details from the web services booking service
 * @param {Number} petServiceId - The pet service id to fetch availability for
 * @param {Number} customerKey - The customer key to schedule a training class for
 * @param {Number} classSessionId - The id of the class session you would like to book an appointment for
 * @param {String} startDateTime - The start time of the appointment to schedule
 * @param {Number} associateId - The id of the training associate who is running the class
 * @param {Number} duration - The length of the pet training class session
 * @param {String} durationUnitOfMeasure - The unit of measure for the class duration (i.e. "Minutes", "Hours")
 * @param {String[]} behaviors - The unit of measure for the class duration (i.e. "Minutes", "Hours")
 * @param {String} notes - Notes for the training appointment
 * @param {String} customerPhone - Customer phone number
 * @param {String} customerPhoneType - Customer phone type (i.e. Work, Home, etc.)
 * @returns {*} AxiosPromise
 */
export function putTrainingItinerary({
  petId,
  petServiceId,
  customerKey,
  itineraryId,
  classSessionId,
  startDateTime,
  associateId,
  duration,
  durationUnitOfMeasure,
  behaviors,
  notes,
  customerPhone,
  customerPhoneType,
  customerTimeZone,
  isReschedule,
}) {
  return bookingAPI({
    method: "PUT",
    data: [
      {
        petId,
        petServices: [
          {
            petServiceId,
            classSessionId,
            startDateTime,
            associateId,
            duration,
            durationUnitOfMeasure,
            behaviors,
            notes,
            customerPhone,
            customerPhoneType,
          },
        ],
        customerTimeZone,
      },
    ],
    url: `${endpointVersionString()}/training/customers/${customerKey}/itineraries/${itineraryId}`,
    params: { isReschedule },
  });
}

/**
 * Update training appointment details from the web services booking service
 * @param {Number} customerKey - The customer id of the class session you would like to modify
 * @param {Number} itineraryId - The itinerary id of the class session you would like to modify
 * @param {Number} petId - The pet id of the class session you would like to modify
 * @param {Number} classSessionId - The id of the class session you would like to modify
 * @param {String} notes - Notes for the training appointment
 * @returns {*} AxiosPromise
 */
export function patchTrainingItinerary({ customerKey, itineraryId, petId, classSessionId, notes }) {
  return bookingAPI.patch(
    `${endpointVersionString()}/training/customers/${customerKey}/itineraries/${itineraryId}`,
    [
      {
        petId,
        petServices: [
          {
            classSessionId,
            notes,
          },
        ],
      },
    ],
  );
}

/**
 * Finalize training appointment details from the web services booking service. Moves an appointment into a different status.
 * @param {Number} customerKey - The customer key to schedule a training class for
 * @param {Number} itineraryId - The unique identifier of the itinerary where the appointment is found
 * @param {Number} engagementId - The unique identifier of the appointment
 * @param {String} status - The unique identifier of the appointment
 * @param {STring} reason - The unique identifier of the appointment
 * @returns {*} AxiosPromise
 */
export function putTrainingClassItineraryStatus({
  customerKey,
  itineraryId,
  engagementId,
  status,
  reason,
}) {
  return bookingAPI({
    url: `${endpointVersionString()}/training/customers/${customerKey}/itineraries/${itineraryId}/engagements/${engagementId}/status`,
    method: "PUT",
    headers: { "X-PETM-SOE-SOURCE": "Associate" },
    data: { status, reason },
  });
}

/**
 * Delete training itinerary endpoint from the web services booking service. Deletes a training class appointment.
 * @param {Number} customerKey - The customer key to schedule a training class for
 * @param {Number} itineraryId - The unique identifier of the itinerary where the appointment is found
 * @returns {*} AxiosPromise
 */
export function deleteTrainingClassItinerary({ customerKey, itineraryId }) {
  return bookingAPI.delete(
    `${endpointVersionString()}/training/customers/${customerKey}/itineraries/${itineraryId}`,
  );
}
