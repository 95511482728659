// React and Redux
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GET_STORE_WIDE_NOTES, getStoreWideNotes } from "dux/storeWideNotes/storeWideNotesActions";

// Layout
import { Layout } from "@prism/psm-ui-components";

// Selectors
import { getStoreNumber } from "core/selectors/persistentSelectors";
import {
  selectStoreWideNotificationCenterNotifications,
  selectStoreWideNotificationsLastAPICallDateTime,
} from "dux/storeWideNotes/storeWideNotesSelectors";

// Utils
import { hasMinPassed } from "core/utils/dateUtils/timeHasPassedUtils";
import { createLoadingSelector } from "core/selectors/utils";

// Helpers
import { shouldFireApi } from "dux/storeWideNotes/_helpers_/storeWideNotesHelpers";
import LoadingWrapper from "web/common/LoadingWrapper";

// Sparky
import { Accordion, Heading } from "@petsmart-ui/sparky";
import { generateRandomString } from "core/utils/authHelper";

// Component Root
export const StoreWideNotes = props => {
  const { componentId, isHidden, dispatchApis, notifications, isLoading } = props;

  useEffect(() => {
    dispatchApis();
  }, []);

  if (isLoading) return <LoadingWrapper isLoading={isLoading} />;

  return (
    <Layout.Box id={componentId} style={{ width: "100%" }}>
      <Layout.Stack>
        <Accordion>
          {notifications?.map(item => {
            return (
              <Accordion.Panel
                key={generateRandomString(4)}
                header={
                  <Heading size="body-lg-bold" tagName={"h2"}>
                    {`${item.serviceLocationType} notes`}
                  </Heading>
                }
              >
                <Layout.Box>
                  {/* Using dangerouslySetInnerHTML can inadvertently expose the app to cross-site Scripting,
                         however since our app is not public this is a minimal risk*/}
                  <div dangerouslySetInnerHTML={{ __html: item.richTextMessage }} />
                </Layout.Box>
              </Accordion.Panel>
            );
          })}
        </Accordion>
      </Layout.Stack>
    </Layout.Box>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const StoreInfoWindowStoreWideNotes = connect(
  state => {
    const storeNumber = getStoreNumber(state);
    const notifications = selectStoreWideNotificationCenterNotifications(state);
    const lastAPICallDateTime = selectStoreWideNotificationsLastAPICallDateTime(state);

    return {
      componentId: "StoreInfoWindowStoreWideNotes",
      storeNumber,
      notifications,
      lastAPICallDateTime,

      isLoading: createLoadingSelector([GET_STORE_WIDE_NOTES])(state),
    };
  },

  dispatch => {
    return {
      dispatchNotification: ({ storeNumber }) => {
        dispatch(getStoreWideNotes({ storeNumber }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const { componentId, storeNumber, notifications, lastAPICallDateTime, isLoading } = mapProps;
    const { dispatchNotification } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      notifications,
      isLoading,

      // actions to pass to view
      dispatchApis: () => {
        if (
          shouldFireApi(
            lastAPICallDateTime,
            notifications,
            hasMinPassed(lastAPICallDateTime, new Date(), 10),
          )
        ) {
          dispatchNotification({ storeNumber });
        }
      },
    };
  },
)(StoreWideNotes);
