export const getTypeFromStatus = status => {
  switch (status) {
    case "Booked":
      return "Upcoming";
    case "Confirmed":
      return "Upcoming";
    case "Checked-In":
      return "Here";
    case "Checked-Out":
      return "Here";
    case "Service Completed":
      return "Here";
    default:
      return null;
  }
};
