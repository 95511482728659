import React, { Component } from "react";
import styled, { css } from "styled-components";
import { map, get } from "lodash/fp";
import { mapWithKey } from "../../../core/utils";
import { color as theme } from "../styles/theme";
import sortImage from "../../../assets/icons/sort.svg";

/* Helper functions
============================= */
const setGridTemplateColumns = columns =>
  columns.map(col => (get("width", col) ? col.width : "1fr")).join(" ");

class GridTable extends Component {
  headerRenderer = () => {
    const { columnDefs, gridOptions } = this.props.config;
    const { headerStyling = {}, sortable, sortHandler } = gridOptions;

    return (
      <Header fixedHeader={gridOptions.fixedHeader} styling={headerStyling}>
        <Row columns={columnDefs}>
          {map(column => {
            const additionalProps = sortable ? { onClick: () => sortHandler(column.field) } : {};

            return (
              <Column key={column.field} sortable={sortable} {...additionalProps}>
                {column.title}
                {sortable && <Icon src={sortImage} />}
              </Column>
            );
          }, columnDefs)}
        </Row>
      </Header>
    );
  };

  rowsRenderer = () => {
    const { config, rowsData } = this.props;
    const { columnDefs, gridOptions } = config;

    return mapWithKey((row, key) => {
      const additionalProps = gridOptions.rowClick
        ? {
            onClick: () => gridOptions.rowClick(row),
            clickable: true
          }
        : {};

      const uniqueKey = get([gridOptions.rowKeyField], row) || key;

      return (
        <Row key={uniqueKey} columns={columnDefs} {...additionalProps}>
          {this.columnsRenderer(row)}
        </Row>
      );
    }, rowsData);
  };

  columnsRenderer = row => {
    const { columnDefs } = this.props.config;

    return map(column => {
      let value = row[column.field];

      if (column.valueGetter) {
        value = column.valueGetter(row, column);
      } else if (column.cellRenderer) {
        value = column.cellRenderer(row, column);
      }

      return (
        <Column key={column.field} width={column.width} styling={column.styling}>
          {value}
        </Column>
      );
    }, columnDefs);
  };

  render() {
    const { gridOptions } = this.props.config;

    return (
      <Container fixedHeader={gridOptions.fixedHeader}>
        {this.headerRenderer()}
        <RowsContainer styling={gridOptions.columnsStyling}>{this.rowsRenderer()}</RowsContainer>
      </Container>
    );
  }
}

const Row = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-items: center;
  grid-template-columns: ${({ columns }) => setGridTemplateColumns(columns)};
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `};
`;

const Column = styled.div`
  padding: 1rem;
  box-sizing: border-box;

  ${({ sortable }) =>
    sortable &&
    css`
      display: flex;
      align-items: center;
    `};

  ${({ styling }) =>
    css`
      ${{ ...styling }};
    `};
`;

const Header = styled.header`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${theme.duskyBlue};

  ${Column} {
    padding: 2rem 1rem;
  }

  ${({ fixedHeader }) =>
    fixedHeader &&
    css`
      position: sticky;
      top: 0;
      background-color: #fff;
    `};

  ${({ styling }) =>
    css`
      ${{ ...styling }};
    `};
`;

const Container = styled.div`
  display: grid;
  ${({ fixedHeader }) =>
    fixedHeader &&
    css`
      position: relative;
    `};
`;

const RowsContainer = styled.div`
  ${({ styling }) =>
    css`
      ${{ ...styling }};
    `};

  ${Row}:nth-child(2n) {
    background-color: #f2f2f2;
  }
`;

const Icon = styled.img``;

export default GridTable;
