import { createSelector } from "reselect";
import { find } from "lodash/fp";
import getAddressesByCustomer from "./getAddressesByCustomer";
import { getProps } from "../../commonSelector";

// Example of use:  getAddressById(state, { customerKey, addressId });
const getAddressById = createSelector(
  [getAddressesByCustomer, getProps],
  (addresses, { addressId }) => find(address => address.addressId === addressId, addresses),
);

export default getAddressById;
