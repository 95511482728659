import * as React from 'react';
import { psx } from '../../../../utils/clsx';
import FormField, { FormFieldInputProps, DEFAULT_FORM_FIELD_VARIANT } from '..';
import { getErrorMessage } from '../error-messages';

import '../styles.css';

export interface FormFieldTextProps extends FormFieldInputProps, React.ComponentPropsWithoutRef<'input'> {}

export interface FormFieldTextState {
  errorMessage: string;
}

export class FormFieldText extends React.Component<FormFieldTextProps, FormFieldTextState> {
  constructor(props: FormFieldTextProps) {
    super(props);
    this.state = {
      errorMessage: '',
    };
  }

  handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    if (this.props.onInput) {
      this.props.onInput(e);
    }
    this.setState({ errorMessage: getErrorMessage(e.target, 'text') });
  };

  handleInvalid = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ errorMessage: getErrorMessage(e.target, 'text') });
  };

  render() {
    const {
      children,
      className,
      label,
      labelClassName,
      onInput,
      defaultValue,
      value,
      autoComplete = 'off',
      placeholder,
      variant = DEFAULT_FORM_FIELD_VARIANT,
      labelStyle = {},
      style = {},
      ...rest
    } = this.props;

    const classNames = psx('form-input-text', `form-input-text-${variant}`, className);
    const placeholderElement = placeholder || ' ';

    return (
      <FormField
        className={labelClassName}
        label={label}
        labelStyle={labelStyle}
        error={this.state.errorMessage}
        variant={variant}
      >
        <input
          {...rest}
          style={style}
          value={value}
          id={label}
          onInput={this.handleInput}
          className={classNames}
          type="text"
          autoComplete={autoComplete}
          placeholder={placeholderElement}
          onInvalid={this.handleInvalid}
        />
      </FormField>
    );
  }
}
