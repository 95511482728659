import { keyframes } from 'styled-components';

export default function scale(multiplier: number = 1) {
  return keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(${1 * multiplier});
  }
  `;
}
