import { createSelector } from "reselect";
import fuzzyFilter from "core/utils/arrayUtils/fuzzyFilter";
import { selectIsOvernight } from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { OVERNIGHT_TITLE, SINGLE_DAY_TITLE } from "./hotelAddonsListConstants";

/**
 * Selector to get hotel addon list state
 * @memberOf Selectors.HotelAddonsList
 * @function
 * @name selectHotelAddonsListState
 * @returns {Object}
 * @example selectHotelAddonsListState(state)
 */
export const selectHotelAddonsListState = state => state?.hotelAddonsList;

/**
 * Selector to get hotel addon search value
 * @memberOf Selectors.HotelAddonsList
 * @function
 * @name selectHotelAddonsListSearchValue
 * @returns {String}
 * @example selectHotelAddonsListSearchValue(state)
 */
export const selectHotelAddonsListSearchValue = createSelector(
  [selectHotelAddonsListState],
  hotelAddonsList => hotelAddonsList?.searchValue,
);

/**
 * Selector to get hotel addon list object
 * @memberOf Selectors.HotelAddonsList
 * @function
 * @name selectHotelAddonsListAllAddons
 * @returns {Object}
 * @example selectHotelAddonsListAllAddons(state)
 */
export const selectHotelAddonsListAllAddons = createSelector(
  [selectHotelAddonsListState],
  hotelAddonsList => hotelAddonsList?.hotelAddons ?? {},
);

/**
 * Selector to get hotel addon service descriptors object
 * @memberOf Selectors.HotelAddonsList
 * @function
 * @name selectHotelAddonsListAllDescriptors
 * @returns {Object}
 * @example selectHotelAddonsListAllDescriptors(state)
 */
export const selectHotelAddonsListAllDescriptors = createSelector(
  [selectHotelAddonsListState],
  hotelAddonsList => hotelAddonsList?.serviceDescriptors ?? {},
);

/**
 * Selector to get petId for last pet that get addon call was made for
 * @memberOf Selectors.HotelAddonsList
 * @function
 * @name selectHotelAddonsLatestPet
 * @returns {String|Number|Undefined}
 * @example selectHotelAddonsLatestPet(state)
 */
export const selectHotelAddonsLatestPet = createSelector(
  [selectHotelAddonsListState],
  hotelAddonsList => hotelAddonsList?.latestPet,
);

/**
 * Selector to get hotel addons list for latest pet
 * @memberOf Selectors.HotelAddonsList
 * @function
 * @name selectHotelAddonsListAddons
 * @returns {Array}
 * @example selectHotelAddonsListAddons(state)
 */
export const selectHotelAddonsListAddons = createSelector(
  [selectHotelAddonsListAllAddons, selectHotelAddonsLatestPet],
  (hotelAddons = {}, petId) => {
    return hotelAddons[petId] ?? [];
  },
);

/**
 * Selector to get hotel addons list for a given pet
 * @memberOf Selectors.HotelAddonsList
 * @function
 * @name selectHotelAddonsListAddonsByPet
 * @returns {Array}
 * @example selectHotelAddonsListAddonsByPet(state, { petId })
 */
export const selectHotelAddonsListAddonsByPet = createSelector(
  [selectHotelAddonsListAllAddons, (state, props) => props],
  (hotelAddons = {}, { petId } = {}) => {
    return hotelAddons[petId] ?? [];
  },
);

/**
 * Selector to get hotel addons service descriptors list for latest pet
 * @memberOf Selectors.HotelAddonsList
 * @function
 * @name selectHotelAddonsListDescriptors
 * @returns {Array}
 * @example selectHotelAddonsListDescriptors(state)
 */
export const selectHotelAddonsListDescriptors = createSelector(
  [selectHotelAddonsListAllDescriptors, selectHotelAddonsLatestPet],
  (serviceDescriptors, petId) => {
    return serviceDescriptors[petId] ?? [];
  },
);

/**
 * Selector that gets the HotelAddOnsByPetService from state and breaks them into 3 arrays, 1st enhanced, 2nd top 5, the 3rd the rest of the addons.
 * @type {OutputSelector<unknown, {mainAddOns: *, mostPopular: *, enhanced: *}, (res: ([]|*)) => {mainAddOns: *, mostPopular: *, enhanced: *}>}
 */
export const selectHotelAddOnsByPetService = createSelector(
  [selectHotelAddonsListAddons, selectHotelAddonsListSearchValue],
  (addons = [], searchValue) => {
    if (!addons.length)
      return {
        mostPopular: [],
        mainAddOns: [],
        enhanced: [],
      };

    // Seperate enhanced addons from the rest
    const enhanced = addons?.filter(addon => addon?.isEnhanced);
    const notEnhanced = addons?.filter(addon => !addon?.isEnhanced);

    // get the 1st 5 in the List of non-enhanced
    const mostPopular = notEnhanced?.slice(0, 5);

    // All remaining addons
    const mainAddOns = notEnhanced?.slice(5);

    return {
      mostPopular: fuzzyFilter(mostPopular, "addOnName", searchValue),
      mainAddOns: fuzzyFilter(mainAddOns, "addOnName", searchValue),
      enhanced: fuzzyFilter(enhanced, "addOnName", searchValue),
    };
  },
);

/**
 * Selector that gets HotelAddOns from state and then returns a specific addon which matches the addon id passed to it.
 * @type {function}
 */
export const selectHotelAddOnById = ({ addonId }) =>
  createSelector(
    [selectHotelAddonsListAddons],

    addons => {
      return addons?.find(addon => addon.addOnId === addonId);
    },
  );

export const selectHotelBookingAddonListTitle = createSelector([selectIsOvernight], isOvernight =>
  isOvernight ? OVERNIGHT_TITLE : SINGLE_DAY_TITLE,
);

/**
 * Selector to get all included descriptors for an enhanced addon
 * @memberOf Selectors.HotelAddonsList
 * @function
 * @name selectEnhancedAddonIncludedDescriptors
 * @param {string} addonId
 * @returns {Function} Selector
 * @example selectEnhancedAddonIncludedDescriptors(addonId)(state)
 */
export const selectEnhancedAddonIncludedDescriptors = addonId =>
  createSelector(
    [selectHotelAddOnById({ addonId }), selectHotelAddonsListDescriptors],
    (parent, serviceDescriptors) => {
      const parentIncludes = parent?.includes?.serviceDescriptors ?? [];
      return parentIncludes.map(({ id }) => serviceDescriptors?.find(sd => sd.id === id));
    },
  );

/**
 * Selector to get all included descriptors for an enhanced addon and format for instructions input
 * @memberOf Selectors.HotelAddonsList
 * @function
 * @name selectIncludedDescriptorsNotes
 * @param {string} addonId
 * @returns {Function} Selector
 * @example selectIncludedDescriptorsNotes(addonId)(state)
 */
export const selectIncludedDescriptorsNotes = addonId =>
  createSelector([selectEnhancedAddonIncludedDescriptors(addonId)], includedDescriptors => {
    return includedDescriptors.map(({ displayName, name }) => ({
      id: undefined, // TODO: service descriptors don't have product numbers so where can we get this?
      label: displayName ?? name,
      notes: "",
    }));
  });

/**
 * Selector to get an array of strings representing the names of all children for a given enhanced addon
 * @memberOf Selectors.HotelAddonsList
 * @function
 * @name selectEnhancedAddonChildNames
 * @param {string} addonId
 * @returns {() => string[]} Selector
 * @example selectEnhancedAddonChildNames(addonId)(state)
 */
export const selectEnhancedAddonChildNames = addonId =>
  createSelector([selectEnhancedAddonIncludedDescriptors(addonId)], includedDescriptors =>
    includedDescriptors?.map(({ displayName, name }) => displayName ?? name),
  );
