import {
  eligibilitySubjectTypes,
  eligibilityTypes,
} from "dux/eligibility/constants/eligibilityConstants";

/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const eligibilityPetActionTypes = {
  GET_PET_ELIGIBILITY: "eligibilty/GET_PET_ELIGIBILITY",
  GET_PET_ELIGIBILITY_REQUEST: "eligibilty/GET_PET_ELIGIBILITY_REQUEST",
  GET_PET_ELIGIBILITY_FAILURE: "eligibilty/GET_PET_ELIGIBILITY_FAILURE",
  GET_PET_ELIGIBILITY_SUCCESS: "eligibilty/GET_PET_ELIGIBILITY_SUCCESS",

  GET_PET_ELIGIBILITY_AFTER_CHANGE: "eligibility/GET_PET_ELIGIBILITY_AFTER_CHANGE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getPetEligibility = ({
  petId,
  customerKey,
  startDate,
  eligibilityType = eligibilityTypes.BOOKING,
}) => ({
  type: eligibilityPetActionTypes.GET_PET_ELIGIBILITY,
  petId,
  customerKey,
  startDate,
  eligibilityType,
  eligibilitySubjectType: eligibilitySubjectTypes.PET,
});

export const getPetEligibilityRequest = () => ({
  type: eligibilityPetActionTypes.GET_PET_ELIGIBILITY_REQUEST,
});

export const getPetEligibilityFailure = ({ error }) => ({
  type: eligibilityPetActionTypes.GET_PET_ELIGIBILITY_FAILURE,
  error,
});

export const getPetEligibilitySuccess = ({ result, petId }) => ({
  type: eligibilityPetActionTypes.GET_PET_ELIGIBILITY_SUCCESS,
  result,
  petId,
});

export const getPetEligibilityAfterChange = ({ petId, customerKey }) => ({
  type: eligibilityPetActionTypes.GET_PET_ELIGIBILITY_AFTER_CHANGE,
  customerKey,
  petId,
});
