import { connect } from "react-redux";

import {
  getSelectedUser,
  getAuthorizedUsers,
  getInvalidPinError
} from "../../core/selectors/authSelectors";
import {
  setOrUpdateUserPin,
  clearInvalidPinError,
  clearRedirectToForgotPin
} from "../../core/actionCreators/authActionCreators";
import { getRedirectToForgotPin } from "../../core/selectors/persistentSelectors";
import authActionTypes from "../../core/actionTypes/authActionTypes";
import { createLoadingSelector } from "../../core/selectors/utils";
import ForgotPinComponent from "./ForgotPinComponent";

const mapStateToProps = state => ({
  authedUsers: getAuthorizedUsers(state),
  selectedUser: getSelectedUser(state),
  invalidPinError: getInvalidPinError(state),
  redirectToForgotPin: getRedirectToForgotPin(state),
  isLoading: createLoadingSelector([
    authActionTypes.AUTHORIZE_USER,
    authActionTypes.AUTHORIZE_USER_BY_PIN,
    authActionTypes.SET_OR_UPDATE_USER_PIN
  ])(state)
});

const mapDispatchToProps = dispatch => ({
  setOrUpdateUserPin: ({ pin }) => dispatch(setOrUpdateUserPin({ pin })),
  clearInvalidPinError: () => dispatch(clearInvalidPinError()),
  clearRedirectToForgotPin: () => dispatch(clearRedirectToForgotPin())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPinComponent);
