import { getDDCDepartures } from "dux/ddcRoomCapacity/ddcRoomCapacitySelector";
import { columnHeaders } from "core/constants/dashboardContants";
import { connect } from "react-redux";
import ScrollablePetCardList from "dux/_components/petCard/ScrollablePetCardList";
import HotelDeparturesPetCardContainer from "dux/hotelDepartures/columnList/HotelDeparturesPetCardContainer";

const mapStateToProps = state => ({
  isHidden: false,
  componentId: "ScrollablePetCardList__DDCRoomCapacityDeparturesAppointments",
  appointments: getDDCDepartures(state),
  diComp: {
    [columnHeaders.DEPARTURES]: HotelDeparturesPetCardContainer,
  },
  compName: columnHeaders.DEPARTURES,
});

export default connect(mapStateToProps)(ScrollablePetCardList);
