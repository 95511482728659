import { itinerariesPaymentStatuses } from "../../constants/itinerariesConstants";

/**
 * Responsible for determining if a invoice is paid or closed.
 *
 * @export isPaidOrClosed
 * @param {*} itineraryStatus
 * @returns
 */
export default function isPaidOrClosed(itineraryStatus) {
  const isPaid = itineraryStatus === itinerariesPaymentStatuses.PAID;
  const isClosed = itineraryStatus === itinerariesPaymentStatuses.CLOSED;

  return isPaid || isClosed;
}
