export const PHONE_NUMBER_VALUE_TYPE = "phoneNumber";
export const STORE_NUMBER_VALUE_TYPE = "store";
export const ASSOCIATE_ID_VALUE_TYPE = "associateId";
export const ASSOCIATE_NAME_VALUE_TYPE = "associateName";

export const CUSTOMER_SEARCH_OPTIONS = [
  { value: PHONE_NUMBER_VALUE_TYPE, label: "Customer Phone" },
  { value: "email", label: "Customer Email" },
  { value: "name", label: "Customer Name" },
];

export const CUSTOMER_SEARCH_DEFAULT_VALUE = [
  {
    value: PHONE_NUMBER_VALUE_TYPE,
    label: "Customer Phone",
    color: "#a19f9f",
  },
];

export const ASSOCIATE_SEARCH_OPTIONS = [
  { value: "associateName", label: "Name" },
  { value: ASSOCIATE_ID_VALUE_TYPE, label: "Associate ID" },
];

export const ASSOCIATE_SEARCH_DEFAULT_VALUE = [
  {
    value: ASSOCIATE_ID_VALUE_TYPE,
    label: "Associate ID",
    color: "#a19f9f",
  },
];

export const HERE_OR_COMPLETED_FILTER_OPTIONS = ["Here", "Completed"];
export const CHECKED_OUT_OR_DEPARTURES_FILTER_OPTIONS = ["Checked Out", "Departures"];

export const SPAYED_NEUTERED_OPTIONS = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const STORE_OPTION = [{ value: STORE_NUMBER_VALUE_TYPE, label: "Store" }];

export const STORE_SEARCH_OPTIONS = [...STORE_OPTION, ...CUSTOMER_SEARCH_OPTIONS];

export const LOCAL_SEARCH_OPTIONS = [
  {
    value: "zipCode",
    label: "Zip Code",
  },
  {
    value: "cityState",
    label: "City-State",
  },
  {
    value: "address",
    label: "Address",
  },
];

// Used in the original searchFieldComponent search, the new version will use the SRC_SEARCH_OPTIONS below. (once the new version goes live, this can be removed.)
export const BOOKING_SEARCH_OPTIONS = [...STORE_OPTION, ...LOCAL_SEARCH_OPTIONS];

export const SRC_SEARCH_OPTIONS = [
  ...STORE_OPTION,
  ...LOCAL_SEARCH_OPTIONS,
  ...CUSTOMER_SEARCH_OPTIONS,
];

export const PET_PARENT_PROFILE_SEARCH_OPTIONS = [{ value: STORE_NUMBER_VALUE_TYPE, label: "Store" }];

export const STORE_SEARCH_DEFAULT_VALUE = [
  {
    value: STORE_NUMBER_VALUE_TYPE,
    label: "Store",
    color: "#a19f9f",
  },
];

export const QUICK_QUOTE_AGE_OPTIONS = {
  // this object's keys are the speciesId: { 1: "dog", 2: "cat" }
  1: [
    { value: "adult", label: "Adult" },
    { value: "puppy", label: "Puppy" },
  ],
  2: [
    { value: "adult", label: "Adult" },
    { value: "kitten", label: "Kitten" },
  ],
};
