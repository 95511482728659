import { SET_HOTEL_ROOM_FILTERS } from "@/dux/hotelRoomManagementFilterPills/hotelRoomManagementFilterPillsActions";

const initialState = {
  roomFilters: [],
};

export const hotelRoomManagementFilterPillsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTEL_ROOM_FILTERS:
      return {
        ...state,
        roomFilters: action.payload,
      };
    default:
      return state;
  }
};
