import { connect } from "react-redux";
import ClickableText from "web/common/ClickableText";
import { color } from "web/common/styles/theme";
import { showConfirmationModal } from "core/actionCreators/confirmationModalActionCreators";
import SplitLineWithMessage from "web/common/SplitLineWithMessage";
import { PRINT_INVOICE_REMINDER_MESSAGE } from "core/constants/checkInOutConstants";
import { loadInvoice } from "core/actionCreators/itinerariesActionCreators";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import {
  getCurrentItinerary,
  getIsCurrentEngagementPriceGreaterThanZero,
} from "core/selectors/checkInOutSelector";
import { getSelectedPet } from "core/selectors/bookingUISelectors";

const mapStateToProps = state => {
  const itineraryId = getCurrentItinerary(state);
  const petId = getSelectedPet(state);
  return {
    itineraryId,
    customerKey: getCurrentCustomerKey(state),
    isVisible: getIsCurrentEngagementPriceGreaterThanZero(state, { petId }),
    styleObj: { color: color.kalEl },
    label: "Pay Now",
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchOnClick: ({ customerKey, itineraryId }) => {
    // This should be removed from here as it is just being passed to the next Modal
    // it belongs in that modals container, needs a further refactor.
    const onPayNow = () => {
      dispatch(loadInvoice({ customerKey, itineraryId }));
    };

    dispatch(
      showConfirmationModal({
        header: "Pay Now Reminder",
        content: SplitLineWithMessage({ string: PRINT_INVOICE_REMINDER_MESSAGE, delimiter: "\n" }),
        confirmText: "Proceed Anyway",
        confirm: () => onPayNow(),
        cancelText: "Cancel",
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () =>
    propsFromDispatch.dispatchOnClick({
      itineraryId: propsFromState.itineraryId,
      customerKey: propsFromState.customerKey,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ClickableText);
