/* * ----------------------------------------------------------------------- * *\
  This component is a view component specificity designed to handle quick views
  of information or tools tips etc. that is displayed via children components

  example of use:
  <AppointmentQuickViewContainer >
    <SomeChildComponent />
  </AppointmentQuickViewContainer >
\* * ----------------------------------------------------------------------- * */

import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Layout } from "@prism/psm-ui-components";
import { color } from "../styles/theme";
import isOutsideViewPort from "../../../core/utils/domElementUtils/isOutsideViewPort";
import calculateDomElementNewPosition from "../../../core/utils/domElementUtils/calculateDomElementNewPosition";
import fadeIn from "../styles/Animation/fade/fadeIn.ts";
import ScalingDiamond from "../bitsAndPieces/AnimatedComponents/ScalingDiamond";

const edge = `5px solid ${color.kalEl}`;
const pointerSize = 20;
const halfPointerSize = pointerSize / 2;

const Pointer = styled.div`
  z-index: 10;
  position: absolute;

  /* Resets center of pointer to 0,0 of parent container after rotate. */
  left: ${halfPointerSize}px;
  top: ${-halfPointerSize}px;
  display:${props => props.isPointer ? 'block' :'none'};
`;

const ChildContainer = styled.div`
  z-index: 20;
  background-color: ${color.victorStone};
`;

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  position: fixed;
  box-sizing: border-box;
  top: ${props => props.coordinates.yLoc}px;
  left: ${props => props.coordinates.xLoc}px;
  background-color: ${color.victorStone};
  border-left: ${edge};
  border-right: ${edge};
  border-bottom: 1px solid ${color.borderGrey};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: ${fadeIn()} 0.5s ease;
`;

export default class QuickView extends Component {
  componentDidUpdate() {
    if (this.secWrapperDomRef) {
      // We need to determine if the Wrapper is outside the screen before we calculate its new position.
      // This way we can pass the "isOutside" ref to the calculatePointerPos function so it will know if
      // the wrapper was outside the screen before we recalculate its position.
      const isOutside = isOutsideViewPort(this.secWrapperDomRef);

      // Recalculate both the wrapper and pointer
      this.calculateWrapperPos();
      this.calculatePointerPos(isOutside);
    }
  }

  // Calculates and sets the new position of the SectionWrapper component if it is outside the browser view
  calculateWrapperPos = () => {
    const wrapperNewPosition = calculateDomElementNewPosition(
      this.secWrapperDomRef,
      pointerSize,
      pointerSize,
    );
    this.secWrapperDomRef.style.top = `${wrapperNewPosition.y}px`;
    this.secWrapperDomRef.style.left = `${wrapperNewPosition.x}px`;
  };

  // Calculates and set the new position of the Pinter component based on if hte SectionWrapper component is outside the
  // browser view
  calculatePointerPos = isOutside => {
    if (isOutside.bottom && isOutside.right) {
      this.secPointerDomRef.style.left = "initial";
      this.secPointerDomRef.style.top = "initial";
      this.secPointerDomRef.style.bottom = `${-halfPointerSize}px`;
      this.secPointerDomRef.style.right = `${halfPointerSize}px`;
    }

    if (isOutside.bottom) {
      this.secPointerDomRef.style.top = "initial";
      this.secPointerDomRef.style.left = `${halfPointerSize}px`;
      this.secPointerDomRef.style.bottom = `${-halfPointerSize}px`;
    }

    if (isOutside.right) {
      this.secPointerDomRef.style.left = "initial";
      this.secPointerDomRef.style.right = `${halfPointerSize}px`;
    }

    if (!isOutside.any) {
      this.secPointerDomRef.style.left = `${halfPointerSize}px`;
    }
  };

  render() {
    const { isVisible, children} = this.props;
    const diamondCSSConfig = { size: pointerSize, color: color.kalEl, animation: true };

    if (isVisible) {
      // NOTE use of createPortal is for the QuickView to always appear at the end of the DOM tree so it is always on the top layer.
      return ReactDOM.createPortal(
        // Note: we use interRef because we need access to the Actual DOM element and not the styled Component Wrapper we would get ith just ref
        <SectionWrapper
          {...this.props}
          innerRef={comp => {
            this.secWrapperDomRef = comp;
          }}
        >
          <Pointer
           {...this.props}
            innerRef={comp => {
              this.secPointerDomRef = comp;
            }}
          >
            <ScalingDiamond cssConfig={diamondCSSConfig} />
          </Pointer>
          <ChildContainer>
            <Layout.Box>{children}</Layout.Box>
          </ChildContainer>
        </SectionWrapper>,
        document.body,
      );
    }

    return null;
  }
}
