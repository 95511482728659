import React from "react";
import styled from "styled-components";

import InitialsCircle from "../../../InitialsCircle";
import Img from "../../../Img";
import { color } from "../../../styles/theme";

import NotificationImage from "../../../../../assets/icons/notificaiton.svg";
import { messagePrefixes } from "dux/eligibility/constants/eligibilityConstants";

const StyledAlert = styled.div`
  flex: 1;
  padding: 1rem 0;
  border-bottom: ${props => (props.withBorder ? `1px solid ${color.borderGrey}` : "auto")};
`;

const AlertHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledImage = styled(Img)`
  margin-right: 1.65rem;
`;

const Icon = styled(Img)`
  padding-right: 5px;
  height: 1em;
`;

const Reason = styled.div`
  margin-bottom: 0.3125rem;
`;

const AlertComponent = props => {
  const { subjectName, isPet, photo, reasons, isWarnings, withBorder } = props;
  return (
    <StyledAlert withBorder={withBorder}>
      <AlertHeader>
        {isPet
          ? photo && <StyledImage width={40} height={40} src={photo} />
          : subjectName && <InitialsCircle displayName={subjectName} margin="0 1.65rem 0 0" />}
        <Icon src={NotificationImage} />
        <h4>{`${subjectName}'s ${isWarnings ? "Warnings" : "Alerts"}`}</h4>
      </AlertHeader>
      {reasons.map(reason => (
        <Reason key={reason.reasonCode}>
          <b>{`${messagePrefixes[reason.reasonCode] || "Alert"}`}: </b>
          {reason.description}
        </Reason>
      ))}
    </StyledAlert>
  );
};

export default AlertComponent;
