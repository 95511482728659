import { put, takeEvery, call, all, select } from "redux-saga/effects";
import checkInOutActionTypes from "../actionTypes/checkInOutActionTypes";
import checkInOutActionCreator from "../actionCreators/checkInOutActionCreator";
import { getItinerary } from "../selectors/entitiesSelector";
import { loadServiceCards } from "../../core/actionCreators/itinerariesActionCreators";

function* onPrintInvoiceForMulti({ customerKey, itineraryId }) {
  try {
    const itinerary = yield select(getItinerary, { itineraryId });
    yield put(checkInOutActionCreator.printInvoiceForMultiRequest());
    const engagementId = itinerary.engagements.length && itinerary.engagements[0];
    // const serviceCardsToLoad = itinerary.engagements.map(engagementId => {
    // return put(loadServiceCard({ customerKey, itineraryId, engagementId }));
    // })
    if (engagementId) {
      yield put(loadServiceCards({ customerKey, itineraryId, engagementId }));
    }

    yield put(checkInOutActionCreator.printInvoiceForMultiSuccess());
  } catch (error) {
    yield put(checkInOutActionCreator.printInvoiceForMultiFailure({ error }));
  }
}

function* watchPrintInvoiceForMulti() {
  yield takeEvery(checkInOutActionTypes.PRINT_INVOICE_FOR_MULTI, onPrintInvoiceForMulti);
}

export default function* checkInOutSaga() {
  yield all([watchPrintInvoiceForMulti()]);
}
