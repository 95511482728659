import { endpointVersionString } from "../../utils/envUtils";
import { bookingAPI } from "../systemServicesBooking/_axiosConfigSystemServicesBooking";
/**
 * Get Associates booked appointments from the Web Associate API- Get Booked Appointment
 * @param {*} { associateId, fromDate }
 * @returns
 */
export function fetchBookedTrainingAppointments({ associateId, fromDate }) {
  return bookingAPI.get(
    `${endpointVersionString()}/training/profile/associates/${associateId}/classes?fromDate=${fromDate}&status=Booked`,
  );
}
