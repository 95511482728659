import { all, call, put, takeEvery, takeLeading, select } from "redux-saga/effects";
import { normalizeGetTrainingClassesResponse } from "@/dux/trainingClassSessions/_utils";
import {
  setTrainingAttendees,
  setTrainingSessionAppointmentDates,
  setTrainingClassCachedMonth,
} from "./actions";
import { loadPetsSuccess } from "@/core/actionCreators/petsActionCreators";
import { loadAssociatesSuccess } from "@/core/actionCreators/associateActionCreator";
import { loadCustomersSuccess } from "@/core/actionCreators/customersActionCreators";
import { loadStoreServices } from "@/core/actionCreators/servicesSelectionActionCreator";
import { createBatchActions } from "@/core/utils/batchUtils";
import {
  deleteTrainingClass,
  fetchTrainingClasses,
  postTrainingClass,
} from "@/core/services/systemServicesBooking/trainingClassesEndpoints";
import {
  GET_TRAINING_CLASS_SESSIONS,
  POST_TRAINING_CLASS_SESSION,
  DELETE_TRAINING_CLASS_SESSION,
  getTrainingClassSessionsFailure,
  getTrainingClassSessionsRequest,
  getTrainingClassSessionsSuccess,
  postTrainingClassSessionRequest,
  postTrainingClassSessionSuccess,
  postTrainingClassSessionFailure,
  deleteTrainingClassSessionRequest,
  deleteTrainingClassSessionSuccess,
  deleteTrainingClassSessionFailure,
  toggleTrainingClassForm,
} from "./actions";

// GET -----------------------------------------------------------------------------------------------------------------
export function* onGetTrainingClassSessions({ fromDate, toDate, storeNumber, cachedMonths }) {
  try {
    yield put(getTrainingClassSessionsRequest());

    const response = yield call(fetchTrainingClasses, { storeNumber, fromDate, toDate });
    const { result } = response.data;

    const {
      trainingSessionAppointmentDates,
      trainingClassSessions,
      trainingAttendees,
      pets,
      associates,
      customers,
      petServices,
    } = normalizeGetTrainingClassesResponse(result);

    // We are currently batching actions so that we only trigger one render cycle when
    // updating Redux with our normalized data. This is to prevent triggering multiple render cycles,
    // which can lead to performance degradation.
    yield put(
      createBatchActions(
        setTrainingSessionAppointmentDates(trainingSessionAppointmentDates),
        setTrainingAttendees(trainingAttendees),
        loadPetsSuccess({ pets }),
        loadAssociatesSuccess({ associates }),
        loadCustomersSuccess({ customers }),
        loadStoreServices({ petServices }),
        getTrainingClassSessionsSuccess(trainingClassSessions),
        setTrainingClassCachedMonth({ cachedMonths }),
      ),
    );
  } catch (error) {
    yield put(getTrainingClassSessionsFailure(error));
  }
}

function* watchGetTrainingClassSessions() {
  yield takeEvery(GET_TRAINING_CLASS_SESSIONS, onGetTrainingClassSessions);
}

// POST ----------------------------------------------------------------------------------------------------------------
export function* onPostTrainingClassSession({
  storeNumber,
  associateId,
  primaryServiceId,
  startDatetime,
  endDatetime,
  fromDate,
  toDate,
  cachedMonths,
}) {
  try {
    yield put(postTrainingClassSessionRequest());

    yield call(postTrainingClass, {
      storeNumber,
      associateId,
      primaryServiceId,
      startDatetime,
      endDatetime,
    });

    yield call(onGetTrainingClassSessions, { storeNumber, fromDate, toDate, cachedMonths });
    yield put(toggleTrainingClassForm());

    yield put(postTrainingClassSessionSuccess());
  } catch (error) {
    yield put(postTrainingClassSessionFailure(error));
  }
}

function* watchPostTrainingClassSession() {
  yield takeLeading(POST_TRAINING_CLASS_SESSION, onPostTrainingClassSession);
}

// DELETE --------------------------------------------------------------------------------------------------------------
export function* onDeleteTrainingClassSession({ classNumber, storeNumber }) {
  try {
    yield put(deleteTrainingClassSessionRequest());
    yield call(deleteTrainingClass, { storeNumber, classNumber });

    yield put(deleteTrainingClassSessionSuccess({ classNumber }));
  } catch (error) {
    yield put(deleteTrainingClassSessionFailure(error));
  }
}

function* watchDeleteTrainingClassSession() {
  yield takeLeading(DELETE_TRAINING_CLASS_SESSION, onDeleteTrainingClassSession);
}

// Pub/Sub -------------------------------------------------------------------------------------------------------------
export default function* trainingClassSessionsSaga() {
  yield all([
    watchGetTrainingClassSessions(),
    watchPostTrainingClassSession(),
    watchDeleteTrainingClassSession(),
  ]);
}
