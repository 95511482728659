import { createSelector } from "reselect";

// External Selectors
import { getStoreInformation } from "core/selectors/entitiesSelector";

// Helper functions
import formatPhoneNumberNANP from "core/utils/stringManipulationUtils/formatPhoneNumber";
import normalizeArrayByProperty from "core/utils/normalizeUtils/normalizeArray";
import getUSTimeStringByDate from "core/utils/dateUtils/USTimeByDate";

// Moment
import moment from "moment";

// Constants
import { weekDaysNames, SERVICE_UNAVAILABLE } from "core/constants";
import { getLocalTime } from "core/utils/localTime/localTime";

// HELPER FUNCTION(s) ----------------------------------------------------------------------------------------
/**
 * Helper function to take store hours in the format the store api provides and returns out an array of strings (MON 09:00 AM - 09:00 PM) in the order of Monday - Sunday
 * @param storeHours - object of store hours as the store located provides.
 * @return {string[]}
 */
export const formatStoreHours = ({ storeHours }) => {
  // get the store info array into a collection normalized by weekday names. e.g weekday names are the key to each object.
  const normalizedHours = normalizeArrayByProperty(
    storeHours?.map(day => ({
      dayOfWeek: day ? day?.DayOfWeek : "",
      openTime: day ? getUSTimeStringByDate(moment(day?.OpenTime, "HH:mm:ss"), true) : "",
      closeTime: day ? getUSTimeStringByDate(moment(day?.CloseTime, "HH:mm:ss"), true) : "",
      isClosed: day?.IsClosed,
    })),
    "dayOfWeek",
  );

  return weekDaysNames.map(day => {
    const abrDay = normalizedHours[day]?.dayOfWeek.slice(0, 3).toUpperCase();
    const isClosed = normalizedHours[day].isClosed;

    if (isClosed) {
      return { isClosed, times: `${abrDay}: ${SERVICE_UNAVAILABLE}` };
    }

    return {
      isClosed: false,
      times: `${abrDay}: ${normalizedHours[day]?.openTime} - ${normalizedHours[day]?.closeTime}`,
    };
  });
};

// SELECTORS(s) ----------------------------------------------------------------------------------------
/**
 * Selector to select store address from all store info located in the state.entities.salon object.
 * Will need store number as a 2nd prop e.g. selectStoreAddress(state, { storeNumber });
 */
export const selectStoreAddress = createSelector(
  [getStoreInformation],
  ({ StreetLine1, City, StateProvinceName, StateProvinceAbbreviation, ZipPostalCode }) => {
    return {
      streetLine1: StreetLine1,
      city: City,
      stateProvinceName: StateProvinceName,
      stateProvinceAbbreviation: StateProvinceAbbreviation,
      zipPostalCode: ZipPostalCode,
    };
  },
);

/**
 * Selector to select store phone number from all store info located in the state.entities.salon object.
 * Will need store number as a 2nd prop e.g. selectStorePhoneNumber(state, { storeNumber });
 */
export const selectStorePhoneNumber = createSelector([getStoreInformation], ({ PhoneNumber }) => {
  return formatPhoneNumberNANP(PhoneNumber);
});

/**
 * Selector to select store hours from all store info located in the state.entities.salon object. and return as a array of strings, e.g. "MON 09:00 AM - 09:00 PM"
 * Will need store number as a 2nd prop e.g. selectStoreHours(state, { storeNumber });
 */
export const selectStoreHours = createSelector(
  [getStoreInformation],
  ({ StoreHours }) => {
    return StoreHours && formatStoreHours({ storeHours: StoreHours });
  },
);

/**
 * Selector to select all store Services hours from all store info located in the state.entities.salon object.
 * Will need store number as a 2nd prop e.g. selectAllStoreServicesHours(state, { storeNumber });
 */
export const selectAllStoreServicesHours = createSelector(
  [getStoreInformation],
  ({ StoreServices }) => {
    return (
      StoreServices &&
      StoreServices.map(item => {
        return { [item.Name]: item.StoreServiceHoursForDateList };
      })
    );
  },
);

/**
 * Selector to select all store Services Names from all store info located in the state.entities.salon object.
 * Will need store number as a 2nd prop e.g. selectAllStoreServiceNames(state, { storeNumber });
 */
export const selectAllStoreServiceNames = createSelector(
  [getStoreInformation],
  ({ StoreServices }) => {
    return StoreServices?.map(item => item?.Name);
  },
);

/**
 * Selector to select all store Services Names as a coma delimited string from all store info located in the state.entities.salon object.
 * Will need store number as a 2nd prop e.g. selectAllStoreServiceNamesAsComaDelString(state, { storeNumber });
 */
export const selectAllStoreServiceNamesAsComaDelString = createSelector(
  [selectAllStoreServiceNames],
  storeServiceNames => {
    return storeServiceNames.toString().replaceAll(",", ", ");
  },
);

/**
 * Selector to select store service hours from all store info located in the state.entities.salon object. and return an multi dimensional array of strings.
 * Will need store number as a 2nd prop e.g. selectAllStoreServicesHoursAsListsOfStrings(state, { storeNumber });
 */
export const selectAllStoreServicesHoursAsListsOfStrings = createSelector(
  [selectAllStoreServicesHours],
  serviceHours => {
    return (
      serviceHours &&
      serviceHours.map(item => {
        const serviceName = Object.keys(item).join();

        return {
          serviceName,
          hours: formatStoreHours({ storeHours: item[serviceName] }),
        };
      })
    );
  },
);

/**
 * Selector to select store current time zone ID, e.g "America/Phoenix" from all store info located in the state.entities.salon object.
 * Will need store number as a 2nd prop e.g. selectStoreCurrentTimeZoneId(state, { storeNumber });
 */
export const selectStoreLocalTime = createSelector([getStoreInformation], ({ TimeZone }) =>
  getLocalTime(TimeZone.TimeZoneId),
);
