export const endpointVersionString = () => {
  switch (window.env.APP_ENV) {
    case "local":
      return "v1";
    case "DEV":
      return "v1";
    case "QA":
      return "v1";
    case "PROD":
      return "v1";
    default:
      return "v1";
  }
};

/**
 * Determines whether the current environment name matches the provided environment name.
 *
 * @memberOf Utils.Environment
 * @function
 * @name isCurrentEnvironment
 * @param {string} environmentName - The environment name to compare against.
 * @returns {boolean} - True if the current environment name matches the provided environment name, false otherwise.
 */
export const isCurrentEnvironment = environmentName => {
  return window?.env?.APP_ENV?.toLowerCase() === environmentName?.toLowerCase();
};

export const getDomainEnv = () => {
  switch (window.env.APP_ENV) {
    case "DEV":
      return "test";
    case "QA":
      return "qa";
    case "STAGE":
      return "stage";
    default:
      return "";
  }
}