import { SET_HOTEL_OVERBOOKING_EXCEPTIONS_HISTORY_ID } from "./hotelOverbookingExceptionsHistoryActions";

const initialState = { isHidden: true, exceptionId: null };

/**
 *
 * @param {object} state
 * @param {boolean} state.isHidden
 * @param {object} action
 * @param {string} action.type
 * @param {string} action.exceptionId
 * @returns hotel overbooking exceptions suspensions history reducer
 */
const hotelOverbookingExceptionsHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTEL_OVERBOOKING_EXCEPTIONS_HISTORY_ID:
      return {
        ...state,
        isHidden: !state.isHidden,
        exceptionId: action.exceptionId,
      };
    default:
      return state;
  }
};

export default hotelOverbookingExceptionsHistoryReducer;