import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get the available bulk package offerings for a pet
 * From the System Services Booking API
 * - Get Bulk Package Offerings
 * @param {*} { storeNumber, customerKey, petId }
 * @returns {*} AxiosPromise
 */

export function getBulkPackageOfferings({ storeNumber, customerKey, petId, offerType }) {
  return bookingAPI.get(
    `${endpointVersionString()}/products/customers/${customerKey}/pets/${petId}/packageOfferings?storeNumber=${storeNumber}${
      offerType ? `&offerType=${offerType}` : ""
    }`,
  );
}

/**
 * Post the selected bulk package offering + add-on for a pet
 * From the System Services Booking API
 * The request body has the following form:
  {
    "productBundleConfigId": 12345,
    "primaryServiceId": 6789,
    "addOns": [
      2775
    ]
  }
 * @param {*} { customerKey, petId, data }
 * @returns {*} AxiosPromise
 */

export function postBulkPackageOfferings({ storeNumber, customerKey, petId, data }) {
  return bookingAPI.post(
    `${endpointVersionString()}/products/customers/${customerKey}/pets/${petId}/packageOfferings?storeNumber=${storeNumber}`,
    data,
  );
}

/**
 * Refunds a purchased package offering based on the bundle
 * configuration id provided.
 * From the System Services Booking API
 * The request body has the following form:\
 * @param {*} { storeNumber, customerKey, bundleConfigId, data }
 * @returns {*} AxiosPromise
 */

export function putRefundPackage({ storeNumber, customerKey, bundleConfigId }) {
  return bookingAPI.put(
    `${endpointVersionString()}/products/customers/${customerKey}/bundles/${bundleConfigId}/refundPackage?storeNumber=${storeNumber}`,
  );
}
