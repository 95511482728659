import modalInitState from "./_modalInitState";
import petParentProfileActionTypes from "../../actionTypes/ui/petParentProfileActionTypes";

const initialState = modalInitState({ errorMessages: [] });

export default function petParentProfileModalsReducer(state = initialState, action) {
  switch (action.type) {
    case petParentProfileActionTypes.SHOW_PET_PARENT_PROFILE_MODAL:
      return {
        ...state,
        show: true,
        modalType: action.modalType,
        errorMessages: action.errorMessages
      };

    case petParentProfileActionTypes.HIDE_PET_PARENT_PROFILE_MODAL:
      return initialState;

    default:
      return state;
  }
}
