export function range(startAt = 0, size: number) {
  // https://stackoverflow.com/a/38213213/1499626
  return Array(size)
    .fill(0)
    .map((_, i) => i + startAt);
}

export function isSingleDigit(num: number) {
  return num > 0 && num < 10;
}
