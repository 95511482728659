/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const foodsActionTypes = {
  SET_FOODS: "SET_FOODS",
  CLEAR_FOODS: "CLEAR_FOODS",
  PRESERVE_FOOD: "PRESERVE_FOOD",
  APPLY_PRESERVED_FOOD: "APPLY_PRESERVED_FOOD",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setFoods = ({ petId, foods }) => ({
  type: foodsActionTypes.SET_FOODS,
  foods,
  petId,
});

export const clearFoods = () => ({
  type: foodsActionTypes.CLEAR_FOODS,
});

export const preserveFood = ({ foodId, petId }) => ({
  type: foodsActionTypes.PRESERVE_FOOD,
  foodId,
  petId,
});

export const applyPreservedFood = ({ foodId, petId }) => ({
  type: foodsActionTypes.APPLY_PRESERVED_FOOD,
  foodId,
  petId,
});
