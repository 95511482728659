import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "../setSystemType/selectors/setSystemTypeSelectors";

const mapStateToProps = state => ({
  isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
  id: "petParentProfileHotelContent-GenericContentWrapper",
  as: "section",
});

// If no mapDispatchToProps fn is passed, then dispatch gets passed as a prop,
// if the component being wrapped uses prop spreading this could cause errors.
// To prevent this we pass a function returning an empty obj
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Layout.Stack);
