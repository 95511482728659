import { associateWebProfileAPI } from "./_axiosConfigAssociateWebProfile";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Adds a new agent from the Web Associate Profile API - Add a new agent to PODS
 * @param {*} { customerKey, sourceId, data }
 * @returns
 */
export function postAgent({ customerKey, sourceId, data }) {
  return associateWebProfileAPI.post(
    `${endpointVersionString()}/customers/${customerKey}/agents/?sourceId=${sourceId}`,
    data
  );
}

/**
 * Update an agent's record from the Web Associate Profile API - Update Agent's record
 * @param {*} { customerKey, agentId, sourceId, data }
 * @returns
 */
export function putAgent({ customerKey, agentId, sourceId, data }) {
  return associateWebProfileAPI.put(
    `${endpointVersionString()}/customers/${customerKey}/agents/${agentId}?sourceId=${sourceId}`,
    data
  );
}

/**
 * Deletes an agent's record from the Web Associate Profile API - Deletes a agent
 * @param {*} { customerKey, agentId, sourceId }
 * @returns
 */
export function deleteAgent({ customerKey, agentId, sourceId }) {
  return associateWebProfileAPI.delete(
    `${endpointVersionString()}/customers/${customerKey}/agents/${agentId}?sourceId=${sourceId}`,
    {
      data: { sourceId }
    }
  );
}
