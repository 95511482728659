import { createSelector } from "reselect";

// import selectors
import { getHotelRoomSelectedRoomType } from "@/dux/hotelRoomSelection/hotelRoomSelectionSelector";
import { getOverBookingExceptionDatesFilters } from "@/dux/overBookingFilterPills/overBookingFilterPillsSelectors";
import { selectHotelOverbookingExceptionsHistoryModalSuspensionId } from "@/dux/hotelOverbookingExceptionsHistory/hotelOverbookingExceptionsHistorySelectors";

// Utils
import moment from "moment";

// Helpers
const hasAllFilters = filters => !filters.length || filters.length === 3;

export const selectHotelOverbookingExceptionDetails = state => {
  return state.overBookingExceptionSuspension.suspensionDetails;
};

export const getFilteredExceptions = createSelector(
  [selectHotelOverbookingExceptionDetails, getOverBookingExceptionDatesFilters],
  (exceptions, selectedFilters) => {
    // If none or all filters are selected
    if (hasAllFilters(selectedFilters)) return exceptions;

    let filteredExceptions = [];
    selectedFilters.forEach(filter => {
      filteredExceptions = [
        ...filteredExceptions,
        ...exceptions.filter(exception => exception.status === filter.filterValue),
      ];
    });

    return filteredExceptions;
  },
);

export const getSuspensionDetails = createSelector(
  [getFilteredExceptions, getHotelRoomSelectedRoomType],
  (filteredExceptions, roomTypeSelected) => {
    if (roomTypeSelected === "View All" || roomTypeSelected === null) {
      return filteredExceptions;
    }

    return filteredExceptions.filter(exception =>
      roomTypeSelected.includes(exception.roomTypeBucketName),
    );
  },
);

/**
 *  Selector to get modified history array for overbooking exceptions for specific exception Id
 *  @memberOf Selectors.overbookingExceptions
 *  @function
 *  @name selectOverbookingExceptionsHistoryDataById
 *  @param {Object} state - redux state
 *  @returns {Array[]} - History Object for a selected exception
 *  @example selectOverbookingExceptionsHistoryDataById(state)
 */
export const selectOverbookingExceptionsHistoryDataById = createSelector(
  [getSuspensionDetails, selectHotelOverbookingExceptionsHistoryModalSuspensionId],
  (exceptionsHistoryData, selectedExceptionId) => {
    if (!exceptionsHistoryData.length && selectedExceptionId) return [];

    return (
      exceptionsHistoryData.find(item => item.capacityExceptionId === selectedExceptionId)
        ?.history || []
    );
  },
);
