export const SET_PREV_BOOKING_TIME_ZONE = "SET_PREV_BOOKING_TIME_ZONE";
export const SET_CACHED_TRAINING_AVAILABILITY_WEEK = "SET_CACHED_TRAINING_AVAILABILITY_WEEK";
export const SET_SELECTED_TIME_SLOT = "SET_SELECTED_TIME_SLOT";
export const RESET_SELECTED_TIME_SLOT = "RESET_SELECTED_TIME_SLOT";

export const setPreviousBookingTimeZone = (previousBookingTimeZone) => ({
  type: SET_PREV_BOOKING_TIME_ZONE,
  previousBookingTimeZone,
});

export const setCachedTrainingAvailabilityWeek = (week) => ({
  type: SET_CACHED_TRAINING_AVAILABILITY_WEEK,
  week,
});

export const setSelectedTimeSlot = (classSessionId) => ({
  type: SET_SELECTED_TIME_SLOT,
  classSessionId,
});

export const resetSelectedTimeSlot = () => ({
  type: RESET_SELECTED_TIME_SLOT,
});
