/**
 * Gives us different labels and select option control for 
 * Restricted and follow up medications
 *
 * @memberOf Utils.Pets
 * @function
 * @name isRestrictedOrFollowUpMed
 * @param { Object } medication
 * {
 *   "MedicationId": 38,
 *   "Description": "Acepromazine",
 *   "IsActive": true,
 *   "IsAccepted": false,
 *   "IsFollowUp": false
 * }

 * @returns {Object} { label, isDisabled } 
 */
export const isRestrictedOrFollowUpMed = medication => {
  if (!medication) return { label: "", isDisabled: false };

  if (medication?.IsAccepted === false && medication?.IsFollowUp === false) {
    return {
      label: `${medication?.Description} (Restricted)`,
      isDisabled: true,
    };
  }
  if (medication?.IsFollowUp === true) {
    return {
      label: `${medication?.Description} (Follow Up)`,
      isDisabled: false,
    };
  }
  return {
    label: medication.Description,
    isDisabled: false,
  };
};
