import { combineReducers } from "redux";
import searchSalonUiReducer from "./searchSalonUiReducer";
import dashboardReducer from "./dashboardReducer";
import checkInOutReducer from "./checkInOutReducer";
import bookingReducer from "./bookingReducer";
import quickQuoteReducer from "./quickQuoteReducer";
import commonReducer from "../../../../core/reducers/ui/web/commonReducer";
import upcomingAndHereReducer from "./upcomingAndHereReducer";
import cartReducer from "./cartReducer";
import agentsReducer from "./agentsReducer";
import associatesReducer from "./associatesReducer";
import vetsReducer from "./vetsReducer";
import vaccinationsReducer from "./vaccinationsReducer";
import petParentProfileReducer from "../../../../core/reducers/ui/web/petParentProfileReducer";
import addonReducer from "../../booking/addonReducer";
import appointmentsListReducer from "../../../../web/petParentProfile/appointments/appointmentsList/appointmentsListReducer";
import bulkPackageOfferingsReducer from "../../../../dux/bgm/bulkPackageOfferings/bulkPackageOfferingsReducer";
import toggleArrowReducer from "../../../../dux/toggleArrow/toggleArrowReducer";

const webReducer = combineReducers({
  search: searchSalonUiReducer,
  dashboard: dashboardReducer,
  checkInOut: checkInOutReducer,
  booking: bookingReducer,
  quickQuote: quickQuoteReducer,
  cart: cartReducer,
  common: commonReducer,
  upcomingAndHere: upcomingAndHereReducer,
  agents: agentsReducer,
  petParentProfile: petParentProfileReducer,
  associates: associatesReducer,
  vets: vetsReducer,
  vaccinations: vaccinationsReducer,
  addons: addonReducer,
  appointmentsList: appointmentsListReducer,
  bulkPackageOfferings: bulkPackageOfferingsReducer,
  arrowViews: toggleArrowReducer,
});

export default webReducer;
