/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const CANCEL_HOTEL_PET = "CANCEL_HOTEL_PET";
export const CANCEL_HOTEL_PET_REQUEST = "CANCEL_HOTEL_PET_REQUEST";
export const CANCEL_HOTEL_PET_SUCCESS = "CANCEL_HOTEL_PET_SUCCESS";
export const CANCEL_HOTEL_PET_FAILURE = "CANCEL_HOTEL_PET_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const cancelHotelPet = ({ petId, status, hotelItineraryId, customerKey, reason }) => ({
  type: CANCEL_HOTEL_PET,
  petId,
  status,
  hotelItineraryId,
  customerKey,
  reason,
});

export const cancelHotelPetRequest = () => ({
  type: CANCEL_HOTEL_PET_REQUEST,
});

export const cancelHotelPetSuccess = () => ({
  type: CANCEL_HOTEL_PET_SUCCESS,
});

export const cancelHotelPetFailure = error => ({
  type: CANCEL_HOTEL_PET_FAILURE,
  error,
});
