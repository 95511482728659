import React, { useEffect, useState } from "react";

// MUI Icons
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

/**
 *  React view component for PrismSort
 *  @memberOf Views.Associate
 *  @function
 *  @name PrismSort
 *  @param {object} props
 *  @param {boolean} props.isHidden
 *  @param {boolean} props.isActive
 *  @param {JSX.Element} props.children
 *  @param {Function} props.onHandleSortFn
 *  @returns {JSX.Element}
 *
 *  @example const component = connect()(PrismSort);
 */
export const PrismSort = props => {
  const { isHidden, isActive, children, onHandleSortFn, ...restProps } = props;

  const [isAsc, setIsAsc] = useState(true);

  const isSortOrderActive = isActive && isAsc;

  useEffect(() => {
    // initial sorting
    if (isActive) handleToggleSort();
  }, [isActive]);

  if (isHidden) {
    return null;
  }

  // Handlers
  const handleToggleSort = () => {
    setIsAsc(sortOrder => !sortOrder);

    const sortOrder = isAsc === true ? "asc" : "desc";

    onHandleSortFn({
      sortOrder,
    });
  };

  return (
    <div onClick={handleToggleSort} {...restProps}>
      {children}
      {isSortOrderActive ? (
        <ArrowDownwardIcon className={`active--${isActive}`} fontSize="sm" />
      ) : (
        <ArrowUpwardIcon className={`active--${isActive}`} fontSize="sm" />
      )}
    </div>
  );
};
