import React from "react";
import styled from "styled-components";
import { Text, Layout } from "@prism/psm-ui-components";

import {
  ALERTS_FOR_THIS_APPOINTMENT,
  ELIGIBILITY_WARNING_FOR_CHOSEN_DATE,
  ELIGIBILITY_WARNINGS_FOR_THIS_APPT,
} from "dux/eligibility/constants/eligibilityConstants";
import Alert from "./AlertContainer";

import CommonButton from "../../../commonButton";
import VipBookingAlertMessageContainer from "../../../../vipBookingAlertMessage/VipBookingAlertMessageContainer";
import GeneralTitle from "../../../GeneralTitlesAndMessages/GeneralTitle";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Message = styled.div`
  margin: 20px auto;
  text-align: center;
  max-width: 240px;
`;

const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
`;

const Alerts = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  padding: 0 6rem;
  max-height: 25rem;
  overflow: auto;
`;

export default class AlertsModalComponent extends React.Component {
  render() {
    const {
      onClose = (f) => f,
      onProceed = (f) => f,
      eligibility,
      isWarnings,
      inTimeSlot,
      isHidden,
      isLoading,
    } = this.props;

    if (isHidden) {
      onProceed();
      onClose();
    } else {
      const { customer, pets } = eligibility;

      if (isLoading) {
        return (
          <Layout.Center>
            <Text size="text-size-lg" align="center">
              Checking Pet Eligibility...
            </Text>
          </Layout.Center>
        );
      }

      return (
        <Container>
          <GeneralTitle componentID="GeneralTitle_AlertsModal">
            {isWarnings ? "Eligibility warnings" : "Important to know"}
          </GeneralTitle>

          <Message>
            {isWarnings
              ? inTimeSlot
                ? ELIGIBILITY_WARNING_FOR_CHOSEN_DATE
                : ELIGIBILITY_WARNINGS_FOR_THIS_APPT
              : ALERTS_FOR_THIS_APPOINTMENT}
          </Message>

          <VipBookingAlertMessageContainer />

          <Alerts>
            {customer.reasons.length > 0 && (
              <Alert
                customerKey={customer.customerKey}
                reasons={customer.reasons}
                isWarnings={isWarnings}
                withBorder
              />
            )}
            {Object.values(pets).map((pet) => {
              if (!pet.reasons.length) {
                return null;
              }

              return (
                <Alert
                  key={pet.petId}
                  petId={pet.petId}
                  reasons={pet.reasons}
                  isPet
                  isWarnings={isWarnings}
                />
              );
            })}
          </Alerts>

          <ButtonsContainer>
            {isWarnings ? (
              <CommonButton
                componentId="button_alert_modal_proceed_anyway"
                label="Proceed anyway"
                onClick={() => {
                  onProceed();
                  onClose();
                }}
              />
            ) : (
              <CommonButton componentId="button_alert_modal_next" label="Next" onClick={onClose} />
            )}
          </ButtonsContainer>
        </Container>
      );
    }

    return null;
  }
}
