import { setFrequencyDates } from "@/dux/frequency/modal/FrequencySelection";

/**
 * Helper to recalculate customFrequencyDates for foods/meds/addons based on a given date range
 * @memberOf Utils.Engagement
 * @function
 * @name updateCustomFrequencyDates
 * @param {string[]} dateRange - array of date strings
 * @returns {Object => Object} - function used to map over foods/meds/addons
 * @example foods.map(updateCustomFrequencyDates(dateRange))
 */
export const updateCustomFrequencyDates = dateRange => object => {
  const { startDatetime, endDatetime, ...filteredObject } = object ?? {};

  return {
    ...filteredObject,
    customFrequencyDates: setFrequencyDates(
      object?.frequency,
      dateRange,
      object?.customFrequencyDates,
    ),
  };
};
