import { connect } from "react-redux";
import ControlledRadioList from "../../common/formInputs/ControlledRadioList";
import {
  getIsTestJobRolesVisible,
  getTestJobRolesList
} from "../../../core/selectors/jobRoles/jobRolesSelectors";
import {
  showTestJobRoles,
  mockDifferentJobRole
} from "../../../core/actionCreators/jobRoleActionCreators";
import { getIsAccessLevelMocked, getJobRole } from "../../../core/selectors/persistentSelectors";

const mapStateToProps = state => ({
  radioList: getTestJobRolesList(state),
  isVisible: getIsTestJobRolesVisible(state),
  clear: !getIsAccessLevelMocked(state),
  radioValue: getJobRole(state)
});

const mapDispatchToProps = dispatch => ({
  onHandleChange: jobRole => {
    // hide radio list
    dispatch(showTestJobRoles({ isVisible: false }));

    // mock job role
    dispatch(mockDifferentJobRole({ jobRole }));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlledRadioList);
