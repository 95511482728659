import moment, { Moment } from 'moment';
import { FIVE_WEEK_GRID_NUMBER_OF_DAYS, FIVE_WEEK_GRID_THRESHOLD, SIX_WEEK_GRID_NUMBER_OF_DAYS } from './constants';
import { range } from './math';

interface DatesToDisplay {
  dayNumber: number;
  date: string;
  isDisabled: boolean;
}

interface Props {
  dayNumbersToDisplayForPreviousMonth: DatesToDisplay[];
  dayNumbersToDisplayForCurrentMonth: DatesToDisplay[];
  selectedDate: Moment;
}

export function getDayNumbersToDisplayForNextMonth({
  dayNumbersToDisplayForPreviousMonth,
  dayNumbersToDisplayForCurrentMonth,
  selectedDate,
}: Props) {
  const pastMonthAndCurrentMonthDaysDisplayed =
    dayNumbersToDisplayForPreviousMonth.length + dayNumbersToDisplayForCurrentMonth.length;

  const numberOfDaysToDisplay =
    pastMonthAndCurrentMonthDaysDisplayed > FIVE_WEEK_GRID_THRESHOLD
      ? SIX_WEEK_GRID_NUMBER_OF_DAYS
      : FIVE_WEEK_GRID_NUMBER_OF_DAYS;

  const amountOfDaysToDisplayForNextMonth = Math.abs(numberOfDaysToDisplay - pastMonthAndCurrentMonthDaysDisplayed);

  const nextMonthRange = range(1, amountOfDaysToDisplayForNextMonth);

  const dayNumbersToDisplayForNextMonth = nextMonthRange.map((dayNumber: number) => {
    const startOfMonth = moment(selectedDate).add(1, 'month').startOf('month');
    return {
      dayNumber,
      date: startOfMonth.add(dayNumber - 1, 'days').format('YYYY-MM-DD'),
      isDisabled: true,
    };
  });

  return dayNumbersToDisplayForNextMonth;
}
