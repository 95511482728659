import React from "react";
import { connect } from "react-redux";
import { Layout, Button, Form } from "@prism/psm-ui-components";
import { createLoadingSelector } from "@/core/selectors/utils";
import associateActionTypes from "@/core/actionTypes/associateActionTypes";
import { getAssociateFormData, getAssociate } from "@/core/selectors/entitiesSelector";
import { hasManagerPrivileges, getStoreNumber } from "@/core/selectors/persistentSelectors";
import associateActionCreators from "@/core/actionCreators/associateActionCreator";
import { getAssociatesModalType } from "@/core/selectors/modals/associatesModalSelectors";
import { employeeGroupConstants, preferredNameConstants } from "@/core/constants/associatesConstants";
import { routePaths } from "@/core/constants/routePaths";
import { getEmployeeGroupsDropdownOptions } from "../associateProfilePageSelectors";
import { setToggleTakePhotoModal } from "../associateProfilePageActions";
import AssociatesModals from "@/web/associates/modals/associatesModalsContainer";
import AssociateProfileUpdateRestrictedMessage from "../AssociateProfileUpdateRestrictedMessage";
import AssociateProfileRemoveVirtualTrainerButton from "../AssociateProfileRemoveVirtualTrainerButton";
import { useForm } from "@/dux/utils/formUtils/useForm";
import { getTrainingAssociates } from "@/dux/trainingAssociates/trainingAssociatesActions";
import { TextWithCharacterCountComponent } from "@/dux/_components/textWithCharacterCount/TextWithCharacterCountComponent";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { cleanPreferredNameEventValue } from "dux/associateProfile/associateProfileForm/AssociateProfileformHelper";

const AssociateProfileFormTraining = ({
  associatePhotoUrl,
  onSubmit,
  modalType,
  associateFormData,
  employeeGroups,
  isLoading,
  isManager,
  redirectToAssociatesPage,
  setToggleTakePhotoModal,
  textFieldStyles,
}) => {
  const {
    values,
    didFormFieldsUpdate,
    setFormInput,
    setDropdownOption,
    setTextAreaInput,
  } = useForm(associateFormData);
  const {
    firstName,
    preferredName,
    lastName,
    associateGroup,
    phoneNumber,
    commisionPercentage,
    associateId,
    associateBio,
  } = values;

  const isSubmitButtonDisabled = isLoading || !isManager || !didFormFieldsUpdate;

  return (
    <Layout.Box padding="box-padding-5">
      <Layout.Stack space="stack-space-6">
        <Layout.Box
          padding="box-padding-16"
          borderWidth="box-border-width"
          borderColor="box-border-color-gray-100"
        >
          <Layout.Sidebar sideWidth="40%" space="sidebar-space-8">
            <Layout.Stack space="stack-space-4">
              <img src={associatePhotoUrl} style={{ minWidth: "370px", width: "100%" }} />
              <Layout.Center>
                <Layout.Box>
                  <Button variant="link" size="xl" onClick={setToggleTakePhotoModal}>
                    Change Photo
                  </Button>
                </Layout.Box>
              </Layout.Center>
            </Layout.Stack>
            <Layout.Stack space="stack-space-10">
              <Form.Field.Text
                id={`AssociateProfile__firstName--${associateId}`}
                disabled
                label="First Name"
                name="firstName"
                variant="material"
                style={textFieldStyles}
                value={firstName}
              />
              <TextWithCharacterCountComponent
                value={preferredName}
                maxLength={preferredNameConstants.limit}
              >
                <Form.Field.Text
                  id={`AssociateProfile__preferredName--${associateId}`}
                 label="Preferred Name"
                 name="preferredName"
                 variant="material"
                 style={textFieldStyles}
                 onChange={(e) => setFormInput(cleanPreferredNameEventValue(e))}
                 value={preferredName}
                 maxLength={preferredNameConstants.limit}
                 placeholder={preferredNameConstants.placeHolder}
               />
              </TextWithCharacterCountComponent>
              <Form.Field.Text
                id={`AssociateProfile__lastName--${associateId}`}
                disabled
                label="Last Name"
                name="lastName"
                variant="material"
                style={textFieldStyles}
                value={lastName}
              />
              <Form.Field.Select
                id={`AssociateProfile__associateGroup--${associateId}`}
                legacy
                label="Employee Group"
                name="associateGroup"
                variant="material"
                options={employeeGroups}
                onChange={setDropdownOption}
                value={associateGroup}
              />
              <Form.Field.Text
                id={`AssociateProfile__phoneNumber--${associateId}`}
                disabled
                label="Phone Number"
                variant="material"
                name="phoneNumber"
                style={textFieldStyles}
                value={phoneNumber}
              />
              <Form.Field.Text
                id={`AssociateProfile__commisionPercentage--${associateId}`}
                disabled
                label="Trainer Pay %"
                name="commisionPercentage"
                variant="material"
                style={textFieldStyles}
                value={commisionPercentage}
              />
              <Form.Field.Text
                id={associateId}
                disabled
                label="Associate ID"
                name="associateId"
                variant="material"
                style={textFieldStyles}
                value={associateId}
              />
              <TextWithCharacterCountComponent
                value={associateBio}
                maxLength={205}
              >
                <Form.Field.TextArea
                  id={`AssociateProfile__associateBio--${associateId}`}
                  name="associateBio"
                  onInput={setTextAreaInput}
                  label="Trainer bio"
                  rows={4}
                  maxLength={205}
                  value={associateBio}
                />
              </TextWithCharacterCountComponent>
            </Layout.Stack>
          </Layout.Sidebar>
        </Layout.Box>
        <Layout.Cluster justify="space-between">
          <Layout.Box>
            <AssociateProfileRemoveVirtualTrainerButton />
          </Layout.Box>
          <Layout.Cluster space="cluster-space-2" justify="right">
            <Button variant="prism-primary-inverted" onClick={redirectToAssociatesPage}>
              Cancel
            </Button>
            <Button
              variant="prism-primary"
              type="submit"
              disabled={isSubmitButtonDisabled}
              onClick={() => onSubmit(values)}
            >
              Save
            </Button>
          </Layout.Cluster>
        </Layout.Cluster>
        <Layout.Cluster justify="flex-end">
          <AssociateProfileUpdateRestrictedMessage />
        </Layout.Cluster>
      </Layout.Stack>
      {modalType && <AssociatesModals modalType={modalType} />}
    </Layout.Box>
  );
};

export default withRouteProps(
  connect(
    (state, { router }) => {
      const { associatePhotoUrl } = getAssociate(state, { associateId: router.params.associateId });
      return {
        isLoading: createLoadingSelector([associateActionTypes.PATCH_ASSOCIATE])(state),
        associatePhotoUrl,
        associateFormData: getAssociateFormData(state, { associateId: router.params.associateId }),
        employeeGroups: getEmployeeGroupsDropdownOptions(state) || employeeGroupConstants,
        isManager: hasManagerPrivileges(state),
        modalType: getAssociatesModalType(state),
        textFieldStyles: { width: "100%" },
        storeNumber: getStoreNumber(state),
      };
    },
    (dispatch, { router: { navigate } }) => ({
      getTrainingAssociates: (payload) => {
        dispatch(getTrainingAssociates(payload));
      },
      patchAssociate: (payload) => {
        dispatch(associateActionCreators.patchAssociate(payload));
      },
      redirectToAssociatesPage: () => {
        navigate(routePaths.ASSOCIATES);
      },
      setToggleTakePhotoModal: () => {
        dispatch(setToggleTakePhotoModal());
      },
    }),
    (stateProps, dispatchProps, { router }) => ({
      ...stateProps,
      ...dispatchProps,

      onSubmit: (values) => {
        const { preferredName, associateBio, associateGroup } = values;
        const associateId = router.params.associateId;
        const data = {
          preferredName,
          associateBio,
          associateGroup,
        };
        const { storeNumber } = stateProps;
        const { patchAssociate, getTrainingAssociates } = dispatchProps;
        if (associateId) {
          patchAssociate({
            associateId,
            data,
            onComplete: () => {
              getTrainingAssociates({ storeNumber, associateId, isLimited: false });
            },
          });
        }
      },
    }),
  )(AssociateProfileFormTraining),
);
