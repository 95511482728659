import { connect } from "react-redux";
import { Button } from "@prism/psm-ui-components";
import { getIsTrainingAppointmentCompleted } from "@/dux/trainingClassSessions/selectors";
import {
  toggleRescheduleTrainingAppointmentModal,
  setRescheduleAttendeeId,
} from "./manageTrainingClassPageActions";

export const TrainingAttendeeRescheduleButton = connect(
  (state, { attendeeId }) => ({
    attendeeId,
    id: "TrainingAttendeeRescheduleButton",
    variant: "link",
    children: "Reschedule",
    disabled: getIsTrainingAppointmentCompleted(state, { attendeeId }),
  }),
  (dispatch) => ({
    toggleRescheduleTrainingAppointmentModal: () => {
      dispatch(toggleRescheduleTrainingAppointmentModal());
    },
    setRescheduleAttendeeId: (attendeeId) => {
      dispatch(setRescheduleAttendeeId(attendeeId));
    },
  }),
  (stateProps, dispatchProps) => {
    const { attendeeId, classSessionId, ...rest } = stateProps;
    const { toggleRescheduleTrainingAppointmentModal, setRescheduleAttendeeId } = dispatchProps;
    return {
      ...rest,
      onClick: () => {
        toggleRescheduleTrainingAppointmentModal();
        setRescheduleAttendeeId(attendeeId);
      },
    };
  },
)(Button);
