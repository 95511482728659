import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getFirstTimeCustomerInfo } from "dux/bgm/ftco/ftcoSelectors";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import {
  getWillShowEligiblePromotionsBGM,
  getWillShowEligiblePromotionsFTCO,
} from "dux/eligiblePromotions/eligiblePromotionsSelectors";

export const isFTCOEligibilityHidden = state => {
  const customerKey = getCurrentCustomerKey(state);
  const { isFirstTimeCustomer } = getFirstTimeCustomerInfo(state, { customerKey });
  const isLoading = createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state);

  const isEligibleForFTCOPromotions = getWillShowEligiblePromotionsFTCO(state);

  return isFirstTimeCustomer || isLoading || !isEligibleForFTCOPromotions;
};

export const isBGMEligibilityHidden = state => {
  const customerKey = getCurrentCustomerKey(state);
  const { isFirstTimeCustomer } = getFirstTimeCustomerInfo(state, { customerKey });
  const isLoading = createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state);
  const isEligibleForBGMPromotions = getWillShowEligiblePromotionsBGM(state);

  return isFirstTimeCustomer || isLoading || !isEligibleForBGMPromotions;
};

export const isFTCOHidden = state => {
  const customerKey = getCurrentCustomerKey(state);
  const { isFirstTimeCustomer } = getFirstTimeCustomerInfo(state, { customerKey });
  const isLoading = createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state);

  return isFirstTimeCustomer === false || isLoading;
};
