/**
 * The Default breakpoints found in Chrome Dev tools
 * @type {{tablet: string, desktop: string, laptopL: string, mobileS: string, mobileM: string, laptop: string, mobileL: string}}
 */
export const chromeDefaultBreakPoints = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

/**
 * Custom Break points for custom devices.
 * @type {{galaxyTabProHeight: string, desktopWidth: string, galaxyTabProWidth: string, desktopHeight: string}}
 */
export const customBreakPoints = {
  desktopWidth: "1920px",
  desktopHeight: "1080px",
  galaxyTabProWidth: "1920px",
  galaxyTabProHeight: "1200px",
};
