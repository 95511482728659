import React from "react";
import { connect } from "react-redux";
import { Layout, Text } from "@prism/psm-ui-components";
import { getAvailableTrainingTimeSlotsForSelectedDate } from "@/dux/trainingClassAvailability/trainingClassAvailabilitySelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getSelectedTimeSlot } from "@/dux/scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";

// VIEW COMPONENT ----------------------------------------------------------------------------------
export function ScheduleTrainingAppointmentDetailsComponent(props) {
  const { isHidden, componentId, timeSlot } = props;

  if (!isHidden) {
    return (
      <Layout.Box id={componentId}>
        <Layout.Stack space="stack-space-2">
          <Layout.Box>
            <Layout.Cluster space="cluster-space-2" justify="flex-start">
              <Text size="text-size-lg" bold>
                {timeSlot?.customerTimeSlot}
              </Text>
              <Text size="text-size-lg">{timeSlot?.customerLabel}</Text>
            </Layout.Cluster>
          </Layout.Box>

          <Layout.Box>
            <Layout.Cluster space="cluster-space-2" justify="flex-start">
              <Text size="text-size-lg">{timeSlot?.storeTimeSlot}</Text>
              <Text size="text-size-lg">{timeSlot?.storeLabel}</Text>
            </Layout.Cluster>
          </Layout.Box>

          <Text size="text-size-lg">{timeSlot?.associateName}</Text>
        </Layout.Stack>
      </Layout.Box>
    );
  }

  return null;
}

// CONTAINERS --------------------------------------------------------------------------------------
export default connect(state => {
  const storeNumber = getStoreNumber(state);
  const selectedTimeSlot = getSelectedTimeSlot(state);
  const availableTimeSlots = getAvailableTrainingTimeSlotsForSelectedDate(state, { storeNumber });
  const timeSlot = availableTimeSlots.find(slot => slot.classSessionId === selectedTimeSlot);
  return {
    isHidden: false,
    componentId: "ScheduleTrainingAppointmentDetails",
    timeSlot,
  };
})(ScheduleTrainingAppointmentDetailsComponent);
