import appHeaderActionTypes from "../../../actionTypes/ui/web/appHeaderActionTypes";

export const showHeaderModal = modalType => ({
  type: appHeaderActionTypes.SHOW_HEADER_MODAL,
  modalType
});

export const hideHeaderModal = () => ({
  type: appHeaderActionTypes.HIDE_HEADER_MODAL
});

export default {
  showHeaderModal,
  hideHeaderModal
};
