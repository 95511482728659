import moment from "moment";
import { selectedDateActions } from "@/dux/selectedDate/selectedDateActions";
import { set } from "lodash/fp";

const initialState = moment().format("YYYY-MM-DD");

const selectedDateReducer = (state = initialState, action) => {
  switch (action.type) {
    case selectedDateActions.RESET_SELECTED_DATE:
      return set("selectedDate", initialState.selectedDate, state);

    case selectedDateActions.SELECT_DATE:
      return action.selectedDate;
    default:
      return state;
  }
};

export default selectedDateReducer;
