import React from "react";
import styled from "styled-components";
import { color, font } from "../styles/theme";
import { jobRoleConstants } from "../../../core/constants/jobRoleConstants";
import BreadCrumbsSalonContainer from "../../dashboardBreadCrumbs/BreadCrumbsSalonContainer";
import BreadCrumbsHotelContainer from "../../dashboardBreadCrumbs/BreadCrumbsHotelContainer";
import GenericContentWrapper from "../../../dux/_components/genericLayoutComponents/GenericContentWrapper";
import BreadCrumbsTrainingContainer from "@/web/dashboardBreadCrumbs/BreadCrumbsTrainingContainer";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  font-family: ${font.secondaryFamily};
  font-size: 25px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${color.ororoMunroe};
`;

const isSRCAgent = jobRole => {
  if (jobRole !== jobRoleConstants.SRC) {
    return (
      <GenericContentWrapper>
        <BreadCrumbsSalonContainer />
        <BreadCrumbsHotelContainer />
        <BreadCrumbsTrainingContainer />
      </GenericContentWrapper>
    );
  }
};

export default function BreadCrumbs(props) {
  const { title, jobRole } = props;
  return (
    <SectionWrapper>
      <Title className="breadcrumbs__title"> {title} </Title>

      {isSRCAgent(jobRole)}
    </SectionWrapper>
  );
}
