import { connect } from "react-redux";
import { getActionClickedReasons } from "dux/eligibility/selectors/getReasonsSelectors";
import {
  loadServiceCards,
  loadInvoice,
  loadServiceCard,
  loadItinerary,
  updateItineraryStatus,
} from "../../../core/actionCreators/itinerariesActionCreators";
import {
  printInvoiceForMulti,
  showCheckInOutButtonModal,
} from "../../../core/actionCreators/checkInOutActionCreator";
import { updateEngagementStatus } from "../../../core/actionCreators/engagementsActionCreators";
import {
  getPetsHavePaid,
  getCurrentPetServiceItem,
  getCurrentItinerary,
  isFromAnotherStore,
} from "../../../core/selectors/checkInOutSelector";
import { showConfirmationModal } from "../../../core/actionCreators/confirmationModalActionCreators";
import { updateAppointmentNote } from "../../../core/actionCreators/notesActionCreator";
import withEligibility from "../../common/hoc/withEligibility";
import CheckInButtonComponent from "./checkInButtonComponent";
import { getSelectedPet } from "../../../core/selectors/bookingUISelectors";
import { getPetEngagementNoteFormDataWithHOPNotes } from "../../../core/selectors/entities/engagementNotesEntitesSelector";
import { getAppointmentNote } from "../../../core/selectors/cartSelectors";
import {
  getPetByPetServiceItemId,
  getItinerary,
  getIsOnePetServiceItemCancelled,
  getDoServiceItemsMatch,
  getPetServiceItem,
  getPetServiceItemsByItinerary,
} from "../../../core/selectors/entitiesSelector";
import { getCheckInOutButtonModalType } from "../../../core/selectors/modals/checkInOutButtonModalSelectors";
import { getIsSRCAgent } from "../../../core/selectors/persistentSelectors";
import { createLoadingSelector } from "../../../core/selectors/utils";
import { eligibilityPetActionTypes } from "dux/eligibility/actions/eligibilityPetActions";
import { eligibilityPetsByItineraryActionTypes } from "dux/eligibility/actions/eligibilityPetsByItineraryActions";
import { eligibilityCustomerActionTypes } from "dux/eligibility/actions/eligibilityCustomerActions";

const mapStateToProps = (state, ownProps) => {
  const petServiceItemId = getCurrentPetServiceItem(state);
  const selectedPet = petServiceItemId
    ? getPetByPetServiceItemId(state, {
        petServiceItemId,
      })
    : getSelectedPet(state);
  const itineraryId = getCurrentItinerary(state);

  return {
    itineraryId,
    petServiceItemId,
    petServiceItem: getPetServiceItem(state, { petServiceItemId }),
    petsPaid: getPetsHavePaid(state),
    petId: getSelectedPet(state, ownProps),
    petEngagementNoteFormData: getPetEngagementNoteFormDataWithHOPNotes(state, {
      isHOPNote: true,
    }),
    appointmentNote: getAppointmentNote(state, {
      petId: selectedPet && selectedPet.petId,
    }),
    itinerary: getItinerary(state, { itineraryId }),
    eligibilityWarnings: getActionClickedReasons(state),
    ModalType: getCheckInOutButtonModalType(state),
    isSRCAgent: getIsSRCAgent(state),
    isFromAnotherStore: isFromAnotherStore(state, { petServiceItemId }),
    isOnePetServiceItemCancelled: getIsOnePetServiceItemCancelled(state, { itineraryId }),
    doServiceItemsMatch: getDoServiceItemsMatch(state, { itineraryId }),
    petServiceItems: getPetServiceItemsByItinerary(state, { itineraryId }),
    isEligibilityLoading: createLoadingSelector([
      eligibilityPetActionTypes.GET_PET_ELIGIBILITY,
      eligibilityPetsByItineraryActionTypes.GET_PETS_ELIGIBILITY_BY_ITINERARY,
      eligibilityCustomerActionTypes.GET_CUSTOMER_ELIGIBILITY,
    ])(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateAppointmentNote: ({ appointmentNote, customerId, itineraryId, petServiceItemId }) => {
      dispatch(
        updateAppointmentNote({
          data: { note: appointmentNote },
          customerId,
          itineraryId,
          petServiceItemId,
        }),
      );
    },
    checkInMulti: ({
      pageName = "check-in-multi",
      reason,
      status,
      customerId,
      itineraryId,
      engagementId,
    }) => {
      dispatch(
        updateItineraryStatus({
          pageName,
          reason,
          status,
          customerId,
          itineraryId,
        }),
      );
      dispatch(
        loadServiceCards({
          customerKey: customerId,
          itineraryId,
          engagementId,
        }),
      );
    },
    checkInSingle: ({
      isBooking,
      status = "Checked-in",
      customerId,
      itineraryId,
      engagementId,
      petsPaid = false,
    }) => {
      dispatch(
        updateEngagementStatus({
          pageName: ownProps?.label,
          status,
          customerId,
          itineraryId,
          engagementId,
        }),
      );
      dispatch(
        loadServiceCard({
          customerKey: customerId,
          itineraryId,
          engagementId,
        }),
      );
    },
    showConfirmationModal: args => dispatch(showConfirmationModal(args)),
    showEligibilityWarningsModal: modalType => dispatch(showCheckInOutButtonModal({ modalType })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withEligibility(CheckInButtonComponent));
