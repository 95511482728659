import { connect } from "react-redux";
import { getIcons } from "core/utils/iconUtils/iconUtils";
import { getSelectedPet } from "../../../../core/selectors/bookingUISelectors";
import Icons from "../../../../web/upcoming_here/icons/iconsComponent";
import { getPet } from "../../../../core/selectors/entitiesSelector";

const mapStateToProps = state => {
  const petId = getSelectedPet(state);
  const pet = getPet(state, { petId });
  return {
    icons: getIcons(pet),
  };
};

export default connect(mapStateToProps)(Icons);
