import { associateWebProfileAPI } from "./_axiosConfigAssociateWebProfile";
import { associateWebAPI } from "../associateWeb/_axiosConfigAssociateWeb";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Fetch a list of customers from the Web Associate Profile API - Find an existing customer end point.
 * @param {*} params - request parameters
 * @returns {Array} Requested customer objects
 */
export function fetchCustomers(params) {
  return associateWebProfileAPI.get("v2/customers", {
    params
  });
}

/**
 * Add new preferences for a customer from the Web Associate Profile API - Add a new preferences for customer end point.
 * @param {*} { customerKey, sourceId, data }
 * @returns
 */
export function postCustomerPreferences({ customerKey, sourceId, data }) {
  return associateWebProfileAPI.post(
    `/${endpointVersionString()}/customers/${customerKey}/preferences?sourceId=${sourceId}`,
    data
  );
}

/**
 * A a new owner to an existing Pet from the Web Associate Profile API - Add an owner (customer) to an existing pet end pont
 * @param {*} { petId, customerKey, sourceId, data }
 * @returns
 */
export function addOwnerToPet({ petId, customerKey, sourceId, data }) {
  return associateWebProfileAPI.post(
    `${endpointVersionString()}/customers/${customerKey}/pets/${petId}/owners?sourceId=${sourceId}`,
    data
  );
}

/**
 * Update preferences for customer from the Web Associate Profile API - Update preferences for customer end point.
 * @param {*} { customerKey, sourceId, data }
 * @returns
 */
export function putCustomerPreferences({ customerKey, sourceId, data }) {
  return associateWebProfileAPI.put(
    `/${endpointVersionString()}/customers/${customerKey}/preferences?sourceId=${sourceId}`,
    data
  );
}

/**
 * Update alerts for customer from the Web Associate Profile API - Update alerts for customer end point.
 * @param {*} { customerKey, sourceId, data }
 * @returns
 */
export function patchCustomerAlerts({ customerKey, sourceId, data }) {
  return associateWebAPI.patch(
    `/${endpointVersionString()}/customers/${customerKey}/alerts?sourceId=${sourceId}`,
    data
  );
}
