import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import customersActionTypes from "../../actionTypes/customersActionTypes";
import customersActionCreators from "../../actionCreators/customersActionCreators";
import petParentProfileActionCreator from "../../actionCreators/petParentProfileActionCreator";
import { getEmailsByCustomer } from "../../selectors/entitiesSelector";
import { getSourceId } from "../../selectors/persistentSelectors";
import {
  updateEmailPreferences,
  getUpdatedEmailPreferences
} from "../../services/associateWebProfile/createEmailEndPoints";
import { postEmail } from "../../services/associateWebProfile/createEmailEndPoints";
import { putEmail } from "../../services/associateWeb/updateEmailEndPoints";
import { deleteEmail } from "../../services/associateWeb/deleteEmailEndPoints";

export function* onCreateCustomerEmail({ customerKey, data }) {
  try {
    yield put(customersActionCreators.createCustomerEmailRequest());
    const sourceId = yield select(getSourceId);
    const response = yield call(postEmail, {
      customerKey,
      sourceId,
      data: { ...data, sourceId }
    });
    const emailToAdd = response.data;
    const customerEmails = yield select(getEmailsByCustomer, {
      customerKey
    });
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          emails: customerEmails.concat(emailToAdd)
        }
      })
    );
    yield put(customersActionCreators.createCustomerEmailSuccess());
  } catch (error) {
    yield put(customersActionCreators.createCustomerEmailError({ error }));
  }
}

export function* onUpdateCustomerEmail({ customerKey, emailAddressId, data }) {
  try {
    yield put(customersActionCreators.updateCustomerEmailRequest());
    const sourceId = yield select(getSourceId);
    const response = yield call(putEmail, {
      customerKey,
      sourceId,
      emailAddressId,
      data: { ...data, sourceId }
    });
    const updatedEmail = response.data;
    const customerEmails = yield select(getEmailsByCustomer, {
      customerKey
    });
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          emails: customerEmails.map(email => {
            if (email.emailAddressId === updatedEmail.emailAddressId) {
              return { ...email, ...updatedEmail };
            }
            return email;
          })
        }
      })
    );
    yield put(customersActionCreators.updateCustomerEmailSuccess());
  } catch (error) {
    yield put(customersActionCreators.updateCustomerEmailError({ error }));
  }
}

export function* onDeleteCustomerEmail({ customerKey, emailAddressId }) {
  try {
    yield put(customersActionCreators.deleteCustomerEmailRequest());
    const sourceId = yield select(getSourceId);

    yield call(deleteEmail, {
      customerKey,
      sourceId,
      emailAddressId,
      data: { sourceId }
    });

    const customerEmails = yield select(getEmailsByCustomer, {
      customerKey
    });
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          emails: customerEmails.filter(email => email.emailAddressId !== emailAddressId)
        }
      })
    );
    yield put(petParentProfileActionCreator.clearCustomerAndEmailKeys());
    yield put(petParentProfileActionCreator.hidePetParentProfileModal());
    yield put(customersActionCreators.deleteCustomerEmailSuccess());
  } catch (error) {
    yield put(customersActionCreators.deleteCustomerEmailError({ error }));
  }
}

export function* onUpdateCustomerEmailOptedOut({ customerKey, isEmailOptedOut }) {
  try {
    yield put(customersActionCreators.updateCustomerEmailOptedOutRequest());
    const sourceId = yield select(getSourceId);
    yield call(updateEmailPreferences, {
      customerKey,
      isEmailOptedOut,
      sourceId
    });

    const response = yield call(getUpdatedEmailPreferences, { customerKey });
    const isEmailOptedOutChange = response.data;

    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          isEmailOptedOut: isEmailOptedOutChange
        }
      })
    );
    yield put(customersActionCreators.updateCustomerEmailOptedOutSuccess());
  } catch (error) {
    yield put(customersActionCreators.updateCustomerEmailOptedOutError({ error }));
  }
}

function* watchCreateCustomerEmail() {
  yield takeEvery(customersActionTypes.CREATE_CUSTOMER_EMAIL, onCreateCustomerEmail);
}

function* watchUpdateCustomerEmail() {
  yield takeEvery(customersActionTypes.UPDATE_CUSTOMER_EMAIL, onUpdateCustomerEmail);
}

function* watchDeleteCustomerEmail() {
  yield takeEvery(customersActionTypes.DELETE_CUSTOMER_EMAIL, onDeleteCustomerEmail);
}

export function* watchUpdateCustomerEmailOptedOut() {
  yield takeEvery(
    customersActionTypes.UPDATE_CUSTOMER_EMAIL_OPTED_OUT,
    onUpdateCustomerEmailOptedOut
  );
}

export default function* customerEmailSaga() {
  yield all([
    fork(watchCreateCustomerEmail),
    fork(watchUpdateCustomerEmail),
    fork(watchDeleteCustomerEmail)
  ]);
}
