import React from "react";
import { getCleanedField } from "core/utils/stringUtils/stringModificationUtils";
import { preferredNameConstants } from "core/constants/associatesConstants";

/*
  The reason for this helper function is because the setFormInput()  requires an event, so we need to wrap the
  getCleanedField(e?.target?.value, preferredNameConstants?.limit) in a fn that will return an event vs a string.
 */

/**
 * A helper to specifically Cleans the string for the associate Preferred name (e.g. will remove all numbers, emojis and limit the strong to a specified number of characters.
 * @memberOf helpers.associate
 * @function
 * @name cleanPreferredNameEventValue
 * @param {object} e - synthetic event
 * @returns {object} e - synthetic event
 * @example
 * cleanPreferredNameEventValue(e)
 */
export const cleanPreferredNameEventValue = (
  e: React.ChangeEvent<HTMLInputElement>,
): React.ChangeEvent<HTMLInputElement> => {
  const clonedEvent: React.ChangeEvent<HTMLInputElement> = { ...e };

  clonedEvent.target.value = getCleanedField(e?.target?.value, preferredNameConstants?.limit);

  return clonedEvent;
};
