import React from "react";
import CommonModal from "web/common/modals/commonModal";
import { Layout } from "@prism/psm-ui-components";

export default function CapacityModals(props) {
  const { isHidden, componentID, onModalClose, compName, diComp } = props;

  if (isHidden) {
    return null;
  }

  /* Since the capacity modals can be for hotel or DDC etc. the content of the modal is dependency
  injected */
  const ModalContent = diComp[compName];

  return (
    <Layout.Box id={componentID} style={{ maxHeight: "400px" }}>
      <CommonModal onClose={onModalClose}>
        <ModalContent />
      </CommonModal>
    </Layout.Box>
  );
}
