import { connect } from "react-redux";
import { getFutureAppointmentsByCustomer } from "../../../../../core/selectors/entitiesSelector";
import { getCurrentCustomerKey } from "../../../../../core/selectors/persistent/customer/customerSelectors";
import CommonAppointmentComponent from "../common/CommonAppointmentListComponent";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  return {
    type: "future",
    title: "Future Appointments",
    appointments: getFutureAppointmentsByCustomer(state, { customerKey }),
    componentID: "FutureAppointmentsList",
  };
};

export default connect(mapStateToProps)(CommonAppointmentComponent);
