import { createSelector } from "reselect";
import { getUiWeb } from "./uiSelector";
import { modalTypes, pageNames } from "../../core/constants/checkInOutConstants";
import { APPOINTMENT_STATUS } from "../constants";
import { getStoreNumber } from "./persistentSelectors";
import objToArray from "../utils/objectUtils/objectToArray";
import {
  getCustomer,
  getPet,
  getAddOns,
  getServicesForPet,
  getPetServiceItem,
  getPetServiceItems,
  getPets,
  getItineraries,
} from "../../core/selectors/entitiesSelector";
import { getAppointmentByPet, getCurrentItineraryPrice } from "core/selectors/cartSelectors";
import { itinerariesStatusList } from "@/dux/_constants";

export const getSelectedService = state => state?.ui?.web?.checkInOut?.selectedService;
export const getCurrentPetServiceItem = state => state?.ui?.web?.checkInOut?.currentPetServiceItem;
export const getCurrentItinerary = state => state?.ui?.web?.checkInOut?.currentItinerary;
export const getCurrentCustomer = state => state?.ui?.web?.checkInOut?.currentCustomer;
export const getPage = state => state?.ui?.web?.checkInOut?.pageName;
export const getSpecialToRemove = state => state?.ui?.web?.checkInOut?.specialToRemove;
export const getCancelAppointmentReason = state =>
  state?.ui?.web?.checkInOut?.cancelAppointmentReason;

// TODO refactor all uiWeb.checkInOut.pageName with getPageName

export const getPetParentAlert = createSelector([getCustomer, getUiWeb], (customer, uiWeb) => {
  if (customer) {
    if (uiWeb?.checkInOut?.pageName == pageNames.CHECK_IN) {
      return customer?.checkInAlert ? customer.checkInAlert : "No Pet Parent Check-In Alert";
    } else if (uiWeb?.checkInOut?.pageName == pageNames.CHECK_OUT) {
      return customer?.checkOutAlert ? customer?.checkOutAlert : "No Pet Parent Check-Out Alert";
    }
    return "No Pet parent Alert";
  }
});

export const getPetAlert = createSelector([getPet, getUiWeb], (pet, uiWeb) => {
  if (pet) {
    if (uiWeb?.checkInOut?.pageName == pageNames.CHECK_IN) {
      return pet?.groomingCheckInAlert || "No Pet Check-In Alert";
    } else if (uiWeb?.checkInOut?.pageName == pageNames.CHECK_OUT) {
      return pet?.groomingCheckOutAlert || "No Pet Check-Out Alert";
    }
    return "No Pet Alert";
  }
});

// TODO: duplicate of getPage
export const getPageName = createSelector([getUiWeb], uiWeb => {
  if (uiWeb?.checkInOut?.pageName) {
    return uiWeb.checkInOut.pageName;
  }
});

export const getVoidModalTypeLIst = () => {
  return [modalTypes.VOID_CONFIRMATION, modalTypes.VOID_REASON, modalTypes.VOID_COMPLETE];
};

export const getFyOutLocationX = createSelector([getUiWeb], uiWeb => {
  if (uiWeb?.checkInOut?.selectionDOMRect) {
    const dif = window.innerWidth - uiWeb?.checkInOut?.selectionDOMRect?.x;
    return dif + 10;
  }
  return 0;
});

export const getFyOutLocationY = createSelector([getUiWeb], uiWeb => {
  if (uiWeb?.checkInOut?.selectionDOMRect) {
    const elmPosition = uiWeb?.checkInOut?.selectionDOMRect?.y;
    const dif = elmPosition / 2;
    return dif;
  }
  return 0;
});

export const getServicesForPetLocY = createSelector([getUiWeb], uiWeb => {
  if (uiWeb?.checkInOut?.selectionDOMRect) {
    const rect = uiWeb?.checkInOut?.selectionDOMRect;
    const elmPosition = rect?.y + rect?.height;
    const dif = elmPosition;
    return dif;
  }
  return 0;
});

export const getServicesForPetLocX = createSelector([getUiWeb], uiWeb => {
  if (uiWeb?.checkInOut?.selectionDOMRect) {
    const elmPosition = uiWeb?.checkInOut?.selectionDOMRect?.x;
    return elmPosition;
  }
  return 0;
});

export const getAddOnsBySelectedService = createSelector(
  [getSelectedService, getAddOns, getServicesForPet],
  (selectedService, addOns = {}, servicesForPet = {}) => {
    const mostPopular = [];
    const mainAddOns = [];
    const addOnsList = servicesForPet[selectedService]
      ? servicesForPet[selectedService]?.addOnIds
      : [];
    const addOnsBySelectedService = {};

    for (const key of addOnsList) {
      addOnsBySelectedService[key] = addOns[key];
    }

    Object.entries(addOnsBySelectedService).forEach(([key, value]) => {
      if (value?.sortOrder < 5) {
        mostPopular.push(value);
      } else {
        mainAddOns.push(value);
      }
    });

    return {
      mostPopular,
      mainAddOns,
    };
  },
);

export const getServicesForPetList = createSelector([getServicesForPet], services => {
  if (services) {
    return objToArray(services);
  }
});

export const getPetNamesFromCurrentItinerary = createSelector(
  [getPets, getItineraries, getCurrentItinerary],
  (pets = {}, itineraries = {}, itineraryId) =>
    itineraries[itineraryId] && itineraries[itineraryId]?.pets
      ? itineraries[itineraryId]?.pets?.reduce(
          (result, petId) => ({
            ...result,
            [petId]: pets[petId] && pets[petId]?.petName,
          }),
          {},
        )
      : {},
);

export const getPetsHavePaid = createSelector(
  [getPetServiceItems, getItineraries, getCurrentItinerary],
  (petServiceItems, itineraries, currentItinerary) => {
    return !(
      currentItinerary &&
      itineraries[currentItinerary] &&
      itineraries[currentItinerary].petServiceItems.find(petServiceItem => {
        return !petServiceItems[petServiceItem].isPaid;
      })
    );
  },
);

export const getNumberOfPetsInItinerary = createSelector(
  [getItineraries, getCurrentItinerary],
  (itineraries = {}, itineraryId) =>
    itineraries[itineraryId] && itineraries[itineraryId]?.pets?.length,
);

// If true - disables all the buttons on cartDetails component, including 'undo', 'cancel' and header buttons
export const isFromAnotherStore = createSelector(
  [getStoreNumber, getPetServiceItem],
  (storeNumber, petServiceItem) => {
    if (!petServiceItem) {
      return false;
    }

    return petServiceItem?.storeNumber !== storeNumber;
  },
);

// If true - disables all the buttons on cartDetails component, except 'undo', 'cancel' and header buttons
export const isCartDetailsEditable = createSelector(
  [getPageName, isFromAnotherStore, getPetServiceItem],
  (pageName, fromAnotherStore, petServiceItem) => {
    // New appointment, no pet service item yet
    if (!petServiceItem) {
      return true;
    }

    const appointmentStatus = petServiceItem?.status;
    const isCheckedOut = appointmentStatus === itinerariesStatusList.CHECKED_OUT;

    if (
      !appointmentStatus ||
      isCheckedOut ||
      pageName === pageNames.PAST_APPOINTMENT ||
      fromAnotherStore
    ) {
      return false;
    }

    return petServiceItem?.status !== APPOINTMENT_STATUS.CANCELED;
  },
);

export const getIsCurrentItineraryPriceGreaterThanZero = createSelector(
  getCurrentItineraryPrice,
  price => price > 0,
);

export const getIsCurrentItineraryPriceZero = createSelector(
  getCurrentItineraryPrice,
  price => price === 0,
);

export const getIsCurrentEngagementPriceGreaterThanZero = createSelector(
  [getAppointmentByPet],
  appointment => appointment?.currentPrice > 0,
);
