/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */

export const eligibilityPetByCustomerActionTypes = {
  GET_PETS_ELIGIBILITY_BY_CUSTOMER_REQUEST: "eligibilty/GET_PETS_ELIGIBILITY_BY_CUSTOMER_REQUEST",
  GET_PETS_ELIGIBILITY_BY_CUSTOMER_FAILURE: "eligibilty/GET_PETS_ELIGIBILITY_BY_CUSTOMER_FAILURE",
  GET_PETS_ELIGIBILITY_BY_CUSTOMER_SUCCESS: "eligibilty/GET_PETS_ELIGIBILITY_BY_CUSTOMER_SUCCESS",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getPetsEligibilityByCustomerRequest = () => ({
  type: eligibilityPetByCustomerActionTypes.GET_PETS_ELIGIBILITY_BY_CUSTOMER_REQUEST,
});

export const getPetsEligibilityByCustomerFailure = ({ error }) => ({
  type: eligibilityPetByCustomerActionTypes.GET_PETS_ELIGIBILITY_BY_CUSTOMER_FAILURE,
  error,
});

export const getPetsEligibilityByCustomerSuccess = () => ({
  type: eligibilityPetByCustomerActionTypes.GET_PETS_ELIGIBILITY_BY_CUSTOMER_SUCCESS,
});
