import moment from "moment";

/**
 *  Helper to adjust selected time for booking with different timezones
 *
 *  @memberOf Utils.Booking
 *  @function
 *  @name adjustSelectedTimeWithOffset
 *  @param {String} selectedDate - associate selected date
 *  @param {String} startTime - associate selected time
 *  @param {Numger} timeOffset - offset based off store location and timezone
 *  @returns {Moment} - Moment time
 *  @example
 *
 *  adjustSelectedTimeWithOffset(selectedDate, standaloneStartTime, timeOffset)
 */
export const adjustSelectedTimeWithOffset = (selectedDate, startTime, timeOffset = 0) => {
  if (!selectedDate || !startTime) return null;

  return moment(`${selectedDate} ${startTime}`).utcOffset(timeOffset, true);
};
