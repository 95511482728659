import { createSelector } from "reselect";
import getIsAddressLimitReached from "./getIsAddressLimitReached";

// Example of use:  getIsAddAddressVisible(state, { customerKey });
const getIsAddAddressVisible = createSelector(
  [getIsAddressLimitReached],
  isAddressLimitReached => !isAddressLimitReached,
);

export default getIsAddAddressVisible;
