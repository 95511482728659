import { connect } from "react-redux";
import { history } from "@/dux/utils/browser/browserHistory";
import { hideBookingModal } from "../../../../core/actionCreators/bookingActionCreators";
import CommonButton from "../../../../web/common/commonButton";

const mapStateToProps = () => {
  return {
    label: "Done",
    width: "8.5em",
  };
};

const mapDispatchToProps = dispatch => ({
  redirect: () => history.push(`/dashboard`),
  closeModal: () => dispatch(hideBookingModal()),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    propsFromDispatch.redirect();
    propsFromDispatch.closeModal();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
