import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_HOTEL_SERVICE_MANAGEMENT,
  getHotelServiceManagementFailure,
  getHotelServiceManagementRequest,
  getHotelServiceManagementSuccess,
} from "dux/serviceManagementPage/serviceManagementPageActions";
import { getServiceManagementToggleReasons } from "dux/servicesManagementToggle/servicesManagementToggleActions";
import { getHotelServiceSuspension } from "dux/scheduleSuspensionPage/scheduleSuspensionActions";
import { suspensionTypes } from "../servicesManagementToggle/serviceManagmentToggleConstants";

function* onGetHotelServiceManagement({ fromDate, toDate }) {
  try {
    yield put(getHotelServiceManagementRequest());

    yield put(getServiceManagementToggleReasons({ suspensionTypes: suspensionTypes.SERVICE }));

    // once reasons have loaded, we are safe to call to get suspension for selected day
    // NOTE: page, size and include history are hard coded here as we want 1 day, both services suspended, without history
    yield put(
      getHotelServiceSuspension({
        fromDate,
        toDate,
        page: 0,
        size: 10,
        includeHistory: false,
      }),
    );

    yield put(getHotelServiceManagementSuccess());
  } catch (error) {
    yield put(getHotelServiceManagementFailure(error));
  }
}

function* watchHotelServiceManagement() {
  yield takeEvery(GET_HOTEL_SERVICE_MANAGEMENT, onGetHotelServiceManagement);
}

export default function* HotelServiceManagementSaga() {
  yield all([fork(watchHotelServiceManagement)]);
}
