import { connect } from "react-redux";

import {
  setHereSearchText,
  clearHereSearchText
} from "../../core/actionCreators/upcomingAndHereActionCreators";
import { getHereSearchText } from "../../core/selectors/upcomingAndHereSelectors";
import SearchInput from "../common/SearchInput";

const mapStateToProps = state => ({
  placeholder: "Search by associate or pet",
  value: getHereSearchText(state)
});

const mapDispatchToProps = dispatch => ({
  onChange: e => dispatch(setHereSearchText(e.target.value)),
  clearText: () => dispatch(clearHereSearchText())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchInput);
