import associateActionTypes from "../../../actionTypes/associateActionTypes";
import { associateGroupConstants } from "../../../constants/associatesConstants";

const initialState = {
  tabFilter: associateGroupConstants.ALL,
  employeeGroups: "",
  modalType: "",
};

export default function agentsReducer(state = initialState, action) {
  switch (action.type) {
    case associateActionTypes.SET_TAB_FILTER:
      return {
        ...state,
        tabFilter: action.tabFilter,
      };

    case associateActionTypes.CLEAR_TAB_FILTER:
      return {
        ...state,
        tabFilter: initialState.tabFilter,
      };

    default:
      return state;
  }
}
