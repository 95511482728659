import { connect } from "react-redux";
import GroomerNameComponent from "./groomerNameComponent";

import { getAssociateByPetServiceId } from "../../../core/selectors/entitiesSelector";

/** ----------------------------------------------------------------------- **\
  mapStateToProps will be called whenever the state is updated and will
  return an object as props to the child presentation component.
\** ----------------------------------------------------------------------- * */
const mapStateToProps = (state, ownProps) => {
  const groomer = getAssociateByPetServiceId(state, ownProps);
  return {
    componentId: `groomer__${groomer?.associateId}`,
    associate: groomer
  };
};

/** ----------------------------------------------------------------------- **\
  The connect() method connects our container component to the Store, it
  will transform the current Redux store state into the props we want to pass
  to a presentation component we are wrapping.

  It does this when we pass in both the mapStateToProps and mapDispatchToProps
  functions as parameters.

  A mergePros can also be used as a 3rd parameter, however we do not need it
  in this particular case.
\** ----------------------------------------------------------------------- * */
const GroomerNameContainer = connect(mapStateToProps)(GroomerNameComponent);

export default GroomerNameContainer;
