import { getOr, get, isEmpty, isEqual } from "lodash/fp";
import { createSelector } from "reselect";
import { frequencyConstants, frequencyTypes } from "dux/frequency/FrequencyConstants";
import { selectFrequencyOptions } from "../frequencyOptions/frequencyOptionsSelectors";
import { selectHotelCartPetProductById } from "../servicesCartHotel/servicesCartHotelSelectors";
import { getCheckForSameDay, selectDateRangeForFrequency } from "./dateRangeForFrequencySelectors";

export const getGeneralPendingFrequency = state => {
  return state.pendingFrequency;
};

export const getPendingFrequency = createSelector(
  [getGeneralPendingFrequency],
  pendingFrequency => {
    return get(["frequency"], pendingFrequency);
  },
);

export const getCachedFrequency = createSelector([getGeneralPendingFrequency], pendingFrequency => {
  return get(["cachedFrequency"], pendingFrequency);
});

export const getPendingFrequencyFoodDates = createSelector(
  [getGeneralPendingFrequency],
  pendingFrequency => getOr([], ["foodDates"], pendingFrequency),
);

export const getPendingFrequencyMedicationDates = createSelector(
  [getGeneralPendingFrequency],
  pendingFrequency => getOr([], ["medicationDates"], pendingFrequency),
);

export const getPendingFrequencyAddonDates = createSelector(
  [getGeneralPendingFrequency],
  pendingFrequency => getOr([], ["addonDates"], pendingFrequency),
);

export const getPendingId = createSelector([getGeneralPendingFrequency], pendingFrequency =>
  get(["pendingId"], pendingFrequency),
);

export const getPendingPetId = createSelector([getGeneralPendingFrequency], pendingFrequency =>
  get(["petId"], pendingFrequency),
);

export const getPendingFrequencyType = createSelector(
  [getGeneralPendingFrequency],
  pendingFrequency => get(["frequencyType"], pendingFrequency),
);

export const getPendingFrequencyDates = createSelector(
  [
    getPendingFrequencyType,
    getPendingFrequencyFoodDates,
    getPendingFrequencyMedicationDates,
    getPendingFrequencyAddonDates,
  ],
  (
    pendingFrequencyType,
    pendingFrequencyFoodDates,
    pendingFrequencyMedicationDates,
    pendingFrequencyAddonDates,
  ) => {
    if (pendingFrequencyType === frequencyTypes.FOOD) {
      return pendingFrequencyFoodDates;
    }
    if (pendingFrequencyType === frequencyTypes.MEDICATION) {
      return pendingFrequencyMedicationDates;
    }
    if (pendingFrequencyType === frequencyTypes.ADDON) {
      return pendingFrequencyAddonDates;
    }
    return [];
  },
);

export const getIsPendingFrequencyMissingField = createSelector(
  [getPendingFrequency, getPendingFrequencyDates, getCheckForSameDay],
  (pendingFrequency, pendingFrequencyDates, isSameDay) => {
    if (isSameDay) {
      return false;
    }

    if (!pendingFrequency) {
      return true;
    }
    if (pendingFrequency === frequencyConstants.MANUAL) {
      return isEmpty(pendingFrequencyDates);
    }
    return false;
  },
);

export const getPendingMedicationFrequencyIsManual = createSelector(
  [getPendingFrequency],
  frequency => frequency === frequencyConstants.MANUAL,
);

// Find if date on pending frequency modal should be checked or not.
export const getIsPendingFrequencyDateChecked = createSelector(
  [
    getCheckForSameDay,
    selectDateRangeForFrequency,
    getPendingFrequencyDates,
    (state, props) => props,
  ],
  (isSameDay, appointmentDates, pendingFreqDates, { date }) => {
    // If it's a single day appointment, e.g. DDC, just return true to auto check the
    // box in the modal
    if (isSameDay) {
      return true;
    }

    // If it is not DDC and a multi day appointment e.g. HOTEL, we determine what date(s) are applicable
    // and then we force a bool value from the date. this will auto check the required dates.
    const matchedDate = pendingFreqDates?.find(pfd => pfd === date);
    const checkDate = appointmentDates.find(apd => apd === matchedDate);
    return !!checkDate;
  },
);

export const getSameDayFilteredOptions = createSelector(
  [getCheckForSameDay, selectFrequencyOptions],
  (isSameDay, options) => {
    const defaultOption = options.filter(option => option.value === frequencyConstants.DAILY);

    return isSameDay ? defaultOption : options;
  },
);

export const selectPendingFrequencyIsFromCart = createSelector(
  [getGeneralPendingFrequency],
  pendingFrequency => pendingFrequency?.isFromCart,
);

export const checkFrequenciesMatch = ({ frequencyA, datesA, frequencyB, datesB }) => {
  const freqsMatch = frequencyA === frequencyB;
  const isManual = frequencyA === frequencyConstants.MANUAL;
  const datesMatch = isManual ? isEqual(datesA.sort(), datesB.sort()) : true;

  return freqsMatch && datesMatch;
};

export const selectAddonFrequencyDisableSave = createSelector(
  [
    getPendingPetId,
    getPendingId,
    getPendingFrequencyType,
    getPendingFrequency,
    getPendingFrequencyAddonDates,
    state => state,
  ],
  (petId, pendingId, type, frequency, dates = [], state) => {
    if (type !== frequencyTypes.ADDON) return false;

    const addon = selectHotelCartPetProductById({ petId, productId: pendingId })(state);

    return checkFrequenciesMatch({
      frequencyA: frequency,
      datesA: dates,
      frequencyB: addon?.frequency,
      datesB: addon?.customFrequencyDates ?? [],
    });
  },
);

export const selectIsFrequencySaveDisabled = createSelector(
  [getIsPendingFrequencyMissingField, selectAddonFrequencyDisableSave],
  (isMissingFields, disableForAddon) => isMissingFields || disableForAddon,
);
