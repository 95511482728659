import React from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";

// Sparky Components
import { TextPassage } from "@petsmart-ui/sparky";

// Selectors
import { getFormattedContactPhoneByCustomerToDisplay } from "@/core/selectors/petParentProfileSelectors";

const HotelPetParentPhone = props => {
  const { componentId, isHidden, phoneNumber } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} style={{ paddingLeft: 0 }}>
      <TextPassage size="lg">{phoneNumber}</TextPassage>
    </LayoutBox>
  );
};

// Map state and own props to props
const mapStateToProps = (state, { customerKey, phoneId }) => {
  const phoneNumber = getFormattedContactPhoneByCustomerToDisplay(state, { customerKey, phoneId });

  return {
    componentId: "HotelPetParentPhone",
    isHidden: false,
    phoneNumber,
  };
};

// HotelPetParentPhoneContainer
export const HotelPetParentPhoneContainer = connect(mapStateToProps)(HotelPetParentPhone);
