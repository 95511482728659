import React from "react";
import { connect } from "react-redux";
import { HotelBooking, SalonBooking } from "@/dux/booking/Booking";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { LayoutBox } from "@/layout/box/Box";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";

const bookingPageContent = {
  [systemName.SALON]: SalonBooking,
  [systemName.HOTEL]: HotelBooking,
};

/**
 * A wrapper component that displays the hotel or salon booking flows
 * @memberof Views.Booking
 * @function
 * @param {Object} props
 * @param {Boolean} props.isHidden,
 * @param {String} props.componentId
 * @param {JSX.Element} props.BookingComp
 * @param {Boolean} props.isStandalone
 * @returns {JSX.Element|null}
 */
const BookingPageWrapper = props => {
  const { isHidden, componentId, BookingComp } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <BookingComp {...props} />
    </LayoutBox>
  );
};

/**
 * Redux Connect function for booking page wrapper that determines which page to show (salon vs. hotel)
 * @see {@link Views.Booking.BookingPageWrapper}
 * @summary Located on the salon & hotel booking pages
 * @memberOf Views.Booking
 * @function
 * @param {Object} props
 * @param {Boolean?} props.isStandalone
 * @returns {JSX.Element|null}
 * @example <BookingPage />
 */
export const BookingPage = connect(state => {
  const bookingFlow = getSystemBookingFlow(state);

  return {
    componentId: "BookingPage",
    BookingComp: bookingPageContent[bookingFlow],
  };
})(BookingPageWrapper);
