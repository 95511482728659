import { GET_MEDICATIONS_LIST_SUCCESS } from "./medicationActions";

// Initial State
const initialState = {};

// Medications Reducer
const medicationsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEDICATIONS_LIST_SUCCESS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default medicationsListReducer;
