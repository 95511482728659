export default {
  LOAD_ASSOCIATES: "LOAD_ASSOCIATES",
  LOAD_ASSOCIATES_REQUEST: "LOAD_ASSOCIATES_REQUEST",
  LOAD_ASSOCIATES_SUCCESS: "LOAD_ASSOCIATES_SUCCESS",
  LOAD_ASSOCIATES_FAILURE: "LOAD_ASSOCIATES_FAILURE",
  CLEAR_ASSOCIATES: "CLEAR_ASSOCIATES",
  SET_TAB_FILTER: "SET_TAB_FILTER",
  CLEAR_TAB_FILTER: "CLEAR_TAB_FILTER",

  UPDATE_ASSOCIATE: "UPDATE_ASSOCIATE",
  UPDATE_ASSOCIATE_REQUEST: "UPDATE_ASSOCIATE_REQUEST",
  UPDATE_ASSOCIATE_SUCCESS: "UPDATE_ASSOCIATE_SUCCESS",
  UPDATE_ASSOCIATE_FAILURE: "UPDATE_ASSOCIATE_FAILURE",

  PATCH_ASSOCIATE: "PATCH_ASSOCIATE",
  PATCH_ASSOCIATE_REQUEST: "PATCH_ASSOCIATE_REQUEST",
  PATCH_ASSOCIATE_SUCCESS: "PATCH_ASSOCIATE_SUCCESS",
  PATCH_ASSOCIATE_FAILURE: "PATCH_ASSOCIATE_FAILURE",

  LOAD_EMPLOYEE_GROUPS: "LOAD_EMPLOYEE_GROUPS",
  LOAD_EMPLOYEE_GROUPS_REQUEST: "LOAD_EMPLOYEE_GROUPS_REQUEST",
  LOAD_EMPLOYEE_GROUPS_SUCCESS: "LOAD_EMPLOYEE_GROUPS_SUCCESS",
  LOAD_EMPLOYEE_GROUPS_FAILURE: "LOAD_EMPLOYEE_GROUPS_FAILURE",

  SHOW_ASSOCIATES_MODAL: "SHOW_ASSOCIATES_MODAL",
  HIDE_ASSOCIATES_MODAL: "HIDE_ASSOCIATES_MODAL",
};
