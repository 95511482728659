import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Text, Form, FormFieldToggleSwitch } from "@prism/psm-ui-components";
import { Heading } from "@petsmart-ui/sparky";
import IconWithLabel from "dux/_components/icon/IconWithLabel";

import PreCheckedInIcon from "../../assets/icons/pre-checked-in-appointment.svg";
import {
  getIsScheduledSuspensionIsUpdated,
  selectServiceSuspensionDetails,
  selectServiceSuspensionReason,
  selectScheduledService,
  selectReasonBySuspensionTypeId,
  selectServiceSuspensionSuspensionId,
  selectServiceSuspensionSuspensionStartDate,
  selectServiceSuspensionStatus,
} from "dux/servicesManagementToggle/servicesManagementToggleSelectors";
import {
  postHotelServiceSuspension,
  patchHotelServiceSuspension,
} from "dux/scheduleSuspensionPage/scheduleSuspensionActions";
import { postHotelServiceSuspensionIsUpdated } from "dux/servicesManagementToggle/servicesManagementToggleActions";
import { color } from "web/common/styles/theme";
import { toggleScheduleSuspensionCancelModal } from "dux/scheduleSuspensionCancelModal/scheduleSuspensionCancelModalActions";
import {
  ableToCancelASuspension,
  ableToCancelNewSuspension,
  ableToCancelScheduledSuspension,
} from "@/dux/servicesManagementToggle/serviceManagementToggleHelpers";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutCenter } from "@/layout/center/Center";
import { setSelectedSuspensionType } from "dux/hotelSelectedSuspensionType/selectedSuspensionTypeActions";
import { selectIsSuspensionSelected } from "dux/hotelSelectedSuspensionType/SelectedSuspensionTypeSelectors";

// MAIN COMPONENT ====================================================================================================
export const ServicesManagementToggle = props => {
  /* option and reason are for reasons dropdown,
    successMessage is for the icon with label component */

  const {
    componentId,
    isHidden,
    updateLabel,
    cancelLabel,
    disregardLabel,
    updateService,
    successMessage,
    options,
    reason,
    additionalDetails,
    switchLabel,
    toggleTypeTitle,
    clearSuccessMessage,
    isFormVisible,
    cancelSuspension,
    onComponentClick,
    isSelected,
  } = props;

  // LOCAL STATE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // state to show/hide form content -- if reason is undefined will coerce to hard bool value for clarity
  const [isFormContentVisible, setIsFormContentVisible] = useState(isFormVisible);

  // additionalDetails form filed value state
  const [fieldValue, setFieldValue] = useState(additionalDetails);

  // reason drop-down selected value state
  const [reasonValue, setReasonValue] = useState(reason);

  // update button disabled state
  const [isDisabled, setIsDisabled] = useState(false);

  const [isError, setIsError] = useState(false);

  const [cancelDisregardLabel, setCancelDisregardLabel] = useState(cancelLabel);
  // LOCAL STATE <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // LIFE CYCLE AND SIDE EFFECTS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // clear any update message on un-mount
  useEffect(() => () => clearSuccessMessage(), []);

  // Populate additional details on init render
  useEffect(() => {
    setFieldValue(additionalDetails);
  }, [additionalDetails]);

  // Populate reason drop-down selected on initial render
  useEffect(() => {
    setReasonValue(reason);
  }, [reason]);

  // Set local form visibility and disable button on initial render.
  useEffect(() => {
    // set via global state
    setIsFormContentVisible(isFormVisible);

    // set via local state initially undefined.
    shouldBeDisabled(fieldValue, reasonValue);
  }, [isFormVisible]);

  // Check if user can cancel can suspension on every render and set label accordingly
  useEffect(() => {
    const ableToCancel = ableToCancelASuspension(
      ableToCancelScheduledSuspension(isFormVisible, reason, additionalDetails),
      ableToCancelNewSuspension(isFormVisible, successMessage),
    );

    ableToCancel ? setCancelDisregardLabel(cancelLabel) : setCancelDisregardLabel(disregardLabel);
  }, [isFormVisible, successMessage]);
  // LIFE CYCLE AND SIDE EFFECTS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // COMPONENT METHODS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const shouldBeDisabled = (textAreaValue, dropdownValue) => {
    if (dropdownValue && textAreaValue?.length > 9) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const shouldHaveError = (textAreaValue, dropdownValue) => {
    if (dropdownValue && textAreaValue?.length < 10) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  // NOTE: Because we need the text area value on change and not after render, we pass it in to onTextAreaChange
  // and then pass that value to both the disabled and error functions instead using state.
  const onTextAreaChange = ({ textAreaValue }) => {
    setFieldValue(textAreaValue);

    shouldBeDisabled(textAreaValue, reasonValue);
    shouldHaveError(textAreaValue, reasonValue);
  };

  // NOTE: Because we need the dropdown value on change and not after render, we pass it in to onDropDownChange
  // and then pass that value to both the disabled and error functions instead using state.
  const onDropDownChange = ({ dropdownValue }) => {
    setReasonValue(dropdownValue);

    shouldBeDisabled(fieldValue, dropdownValue);
    shouldHaveError(fieldValue, dropdownValue);
  };

  /*
   * Determines whether the cancellation of a suspension is allowed or not.
   *
   * The cancellation is allowed if:
   * - The form content is visible.
   * - The reason and additional details for the suspension are provided (for scheduled suspensions).
   *   Note: The reason and additional details are pulled from Props, ensuring that only valid scheduled suspensions can be canceled.
   * - The success message is available (for newly scheduled suspensions).
   *   Note: The success message is only relevant if the form is open.
   *
   * If the cancellation is allowed, the suspension will be canceled by calling the cancelSuspension function.
   * Otherwise, the form content will be closed.
   *
   * @returns {void}
   */
  const shouldCancel = () => {
    const ableToCancel = ableToCancelASuspension(
      ableToCancelScheduledSuspension(isFormContentVisible, reason, additionalDetails),
      ableToCancelNewSuspension(isFormContentVisible, successMessage),
    );

    // if able to cancel, cancel suspension, if not hide form
    ableToCancel ? cancelSuspension() : setIsFormContentVisible(!isFormContentVisible);
    clearSuccessMessage();
  };
  // COMPONENT METHODS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // COMPONENT JSX >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox
      id={componentId}
      borderWidth="scale-S10"
      borderColor={isSelected ? "crayolaBlue" : "lightGrey"}
      padding="scale-G1"
      style={{ minWidth: "400px" }}
      onClick={() => onComponentClick()}
    >
      <LayoutStack space="scale-G1">
        {/* --- HEADING & TOGGLE --- */}
        <LayoutCenter id={`${componentId}__toggle`}>
          <LayoutStack>
            {/* --- HEADING--- */}
            <LayoutBox padding="scale-0">
              {/*{ toggleTypeTitle }*/}
              <Heading size="headline" tagName="div" style={{ textAlign: "center" }}>
                {toggleTypeTitle}
              </Heading>
            </LayoutBox>

            {/* --- TOGGLE --- */}
            <LayoutBox padding="scale-0">
              <LayoutCluster>
                {/*we set the isFormContentVisible to be flipped on checked since we want the form hidden when the toggle is actual in the ON position*/}
                <FormFieldToggleSwitch
                  label={switchLabel}
                  checked={!isFormContentVisible}
                  changed={() => shouldCancel()}
                />
              </LayoutCluster>
            </LayoutBox>
          </LayoutStack>
        </LayoutCenter>

        {/* --- TOGGLE DYNAMIC CONTENT --- */}
        {isFormContentVisible && (
          <LayoutBox id={`${componentId}__formContent`} padding="scale-0">
            <LayoutStack space="scale-G2">
              {/* --- SCHEDULED SUCCESS MESSAGE ---*/}
              {successMessage && (
                <LayoutBox padding="scale-0">
                  <IconWithLabel label={successMessage} src={PreCheckedInIcon} />
                </LayoutBox>
              )}

              {/* --- REASON DROPDOWN ---*/}
              <LayoutBox id={`${componentId}__reasonDropdown`} padding="scale-0">
                <Form.Field.Select
                  legacy
                  label="Reason"
                  name="suspensionReason"
                  variant="material"
                  options={options}
                  onChange={option => {
                    onDropDownChange({ dropdownValue: option.value });
                  }}
                  value={reasonValue}
                />
              </LayoutBox>

              {/* --- ADDITIONAL DETAILS INPUT ---*/}
              <LayoutBox id={`${componentId}__additionalDetails`} padding="scale-0">
                <LayoutStack space="scale-S10">
                  <Form.Field.TextArea
                    style={{ border: `${isError ? "1px solid red" : "1px solid black"}` }}
                    name="additionalDetails"
                    onInput={value => {
                      onTextAreaChange({ textAreaValue: value });
                    }}
                    label="Additional Details"
                    rows={4}
                    maxLength={200}
                    value={fieldValue}
                  />

                  <Text
                    style={{ color: `${isError ? "red" : "black"}` }}
                    size="text-size-sm"
                    color="text-color-disabled"
                    id={`${componentId}__minChar`}
                  >
                    min. 10 characters
                  </Text>
                </LayoutStack>
              </LayoutBox>

              {/* --- UPDATE AND CANCEL CTAs ---*/}
              <LayoutBox padding="scale-0">
                <LayoutCluster style={{ justifyContent: "space-between" }}>
                  {/*UPDATE/SAVE*/}
                  <Button
                    id={`${componentId}__update`}
                    variant="prism-primary"
                    onClick={() => updateService(fieldValue, reasonValue)}
                    disabled={isDisabled}
                  >
                    {updateLabel}
                  </Button>

                  {/*CANCEL*/}
                  <Button
                    id={`${componentId}__cancel`}
                    variant="link"
                    style={{ color: `${color.errorRed}` }}
                    onClick={() => {
                      shouldCancel();
                    }}
                  >
                    {cancelDisregardLabel}
                  </Button>
                </LayoutCluster>
              </LayoutBox>
            </LayoutStack>
          </LayoutBox>
        )}
      </LayoutStack>
    </LayoutBox>
  );
  // COMPONENT JSX <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
};

// SAME DAY TOGGLE CONTAINER ----------------------------------------------------------------------------------------
export const ServiceSuspensionToggle = connect(
  (state, { suspensionType }) => {
    const { suspensionTypeId, reasonTypeName } = suspensionType;

    const isUpdated = getIsScheduledSuspensionIsUpdated(state, reasonTypeName);
    const isScheduled = !!selectScheduledService(state, suspensionTypeId);
    const reason = isScheduled ? selectServiceSuspensionReason(state, suspensionTypeId) : "";
    const additionalDetails = isScheduled
      ? selectServiceSuspensionDetails(state, suspensionTypeId)
      : "";
    const suspensionId = selectServiceSuspensionSuspensionId(state, suspensionTypeId);
    const isSelected = selectIsSuspensionSelected(state, { suspensionTypeId, suspensionId });

    return {
      componentId: `ServiceSuspensionToggle__${reasonTypeName}`,
      updateLabel: isScheduled ? "Update" : "Schedule",
      cancelLabel: "Cancel Suspension",
      disregardLabel: "Disregard",
      successMessage: isUpdated
        ? `${reasonTypeName} suspension has been successfully scheduled.`
        : null,
      options: selectReasonBySuspensionTypeId({ suspensionTypeId })(state),
      isFormVisible: selectServiceSuspensionStatus(state, suspensionTypeId),
      toggleTypeTitle: reasonTypeName,
      switchLabel: "Manage ON/OFF",
      reason,
      additionalDetails,
      isSelected,

      // for merge props
      suspensionId,
      startDate: selectServiceSuspensionSuspensionStartDate(state, suspensionTypeId),
      suspensionType,
      isScheduled,
    };
  },

  dispatch => {
    return {
      handleScheduledSuspensionIdUpdate: ({
        details,
        reasonValue,
        suspensionId,
        startDate,
        suspensionTypeId,
      }) => {
        dispatch(
          patchHotelServiceSuspension({
            startDate,
            endDate: startDate,
            details,
            suspensionTypeId,
            suspensionId,
            isCanceled: false,
            reasonValue: reasonValue,
          }),
        );
      },
      handleUpdateService: ({ details, reasonValue, suspensionType }) => {
        const suspensionTypeId = suspensionType?.suspensionTypeId;
        const reasonTypeName = suspensionType?.reasonTypeName;

        dispatch(
          postHotelServiceSuspension({ details, reasonValue, suspensionTypeId, reasonTypeName }),
        );
      },
      handleClearSuccessMessage: suspensionType => {
        const reasonTypeName = suspensionType?.reasonTypeName;

        // on unmount we want to hide success message.
        dispatch(
          postHotelServiceSuspensionIsUpdated({
            isUpdated: false,
            reasonTypeName: reasonTypeName.replace(/\s/g, ""),
          }),
        );
      },
      handleCancelSuspensionModal: ({ suspensionId, startDate }) => {
        dispatch(toggleScheduleSuspensionCancelModal({ suspensionId, startDate }));
      },
      handleSetSelectedSuspensionType: ({ suspensionType, suspensionId }) => {
        dispatch(setSelectedSuspensionType({ suspensionType, suspensionId }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      isHidden,
      updateLabel,
      cancelLabel,
      disregardLabel,
      successMessage,
      options,
      reason,
      additionalDetails,
      switchLabel,
      isFormVisible,
      suspensionId,
      startDate,
      suspensionType,
      toggleTypeTitle,
      isScheduled,
      isSelected,
    } = mapProps;
    const {
      handleUpdateService,
      handleClearSuccessMessage,
      handleCancelSuspensionModal,
      handleScheduledSuspensionIdUpdate,
      handleSetSelectedSuspensionType,
    } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,
      updateLabel,
      cancelLabel,
      disregardLabel,
      successMessage,
      isFormVisible,
      isSelected,

      // text area
      additionalDetails,

      // reasons
      options,
      reason,

      // switch
      switchLabel,
      toggleTypeTitle,

      // actions to pass to view
      updateService: (details, reasonValue) => {
        if (isScheduled) {
          // UPDATE SUSPENSION
          handleScheduledSuspensionIdUpdate({
            details,
            reasonValue,
            suspensionId,
            startDate,
            suspensionTypeId: suspensionType.suspensionTypeId,
          });
        } else {
          // NEW SUSPENSION
          handleUpdateService({ details, reasonValue, suspensionType });
        }
      },
      clearSuccessMessage: () => handleClearSuccessMessage(suspensionType),
      cancelSuspension: () => handleCancelSuspensionModal({ suspensionId, startDate }),
      onComponentClick: () => handleSetSelectedSuspensionType({ suspensionType, suspensionId }),
    };
  },
)(ServicesManagementToggle);
