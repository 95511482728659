import { connect } from "react-redux";
import QuickViewCard from "./quickViewCardComponent";

const mapStateToProps = (state, ownProps) => {
  return {};
};

const QuickViewCardContainer = connect(mapStateToProps)(QuickViewCard);

export default QuickViewCardContainer;
