import quickQuoteActionTypes from "../../core/actionTypes/quickQuoteActionTypes";

export const setServicesTab = tabId => ({
  type: quickQuoteActionTypes.SET_SERVICES_TAB,
  tabId
});

export default {
  setServicesTab
};
