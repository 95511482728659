import moment from "moment";

/**
 * Responsible for base-10 differences between start and end times
 * For example, 15 minutes would be returned as 0.25.
 * @exports getDifferenceInDecimalHours
 * @returns {Number}
 */

export default function getDifferenceInDecimalHours(start, end, format = "h:mm A") {
  const momentStart = moment(start, format);
  const momentEnd = moment(end, format);
  return momentEnd.diff(momentStart, "hours", true);
}
