import moment from "moment";
import getISOWeekDayName from "./getISOWeekDayName";

/**
 * Responsible for checking if a particular date is the same as the ISO Day number passed in.
 *
 * @export isDateOnISOday
 * @param {*} date - ISO 8601 formatted string or a Moment object
 * @param {Number} WeekDayNumber - Number that represents the ISO days of the week, e.g. 1 for Monday, 7 for Sunday
 * @returns
 */
export default function isDateOnISOday(date, WeekDayNumber) {
  if (moment(date).format("dddd") === getISOWeekDayName(WeekDayNumber)) {
    return true;
  }

  return false;
}
