import React from "react";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { LayoutBox } from "@/layout/box/Box";
import { selectPetProfileFoodsForPostBooking } from "@/web/food/foodsSelector";
import { convertTimeOfDayObjToArr } from "@/core/utils/checkInOutUtils";
import { connect } from "react-redux";
import { SelectField } from "@petsmart-ui/sparky";
import { NEW_FOOD_ID } from "@/web/newFood/newFoodConstants";
import { isEmpty } from "lodash";

const CheckInOutPetProfileFoodMedDropdown = props => {
  const { componentId, isHidden, options, onChange, label, value } = props;
  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <SelectField
        label={label}
        items={options}
        onChange={onChange}
        value={value}
        style={{ textWrap: "wrap" }}
      />
    </LayoutBox>
  );
};

/**
 * Helper to format pet profile foods options for select
 * @function
 * @name formatFoodOptions
 * @param {Object} petProfileFoodOptions
 * @returns {{ value: string, label: string }[]}
 */
export const formatFoodOptions = petProfileFoodOptions =>
  Object.entries(petProfileFoodOptions)?.map(([value, food]) => {
    const labelArr = [
      food?.foodType,
      food?.foodName,
      food?.otherFoodName,
      food?.foodAmount,
      convertTimeOfDayObjToArr(food).join(", "),
    ];

    return { value, label: labelArr.filter(val => !!val).join(" - ") };
  });

export const CheckInOutPetProfileFoodDropdown = connect(
  (state, { foodId, petProfileFoodId, onChange }) => {
    if (foodId !== NEW_FOOD_ID) return { isHidden: true };

    const petId = getCurrentPet(state);
    const petProfileFoodOptions = selectPetProfileFoodsForPostBooking(state, { petId });

    return {
      componentId: "CheckInOutPetProfileFoodDropdown",
      isHidden: isEmpty(petProfileFoodOptions),
      options: formatFoodOptions(petProfileFoodOptions),
      label: "Pet Profile Foods",
      value: petProfileFoodId,
      onChange: e => {
        const food = petProfileFoodOptions[e?.target?.value];
        onChange(food);
      },
    };
  },
)(CheckInOutPetProfileFoodMedDropdown);
