import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { find, getOr } from "lodash/fp";
import { getPetFavorabilityHotel } from "./petFavorabilitySelectors";
import {
  petFavorabilityHotelActionTypes,
  updatePetFavorabilityHotelFailure,
  updatePetFavorabilityHotelRequest,
  updatePetFavorabilityHotelSuccess,
} from "./petFavorabilityHotelActions";
import { favourabilityOptions } from "core/constants/petProfileConstants";
import { updatePetProfilePartial } from "core/services/systemServicesBooking/profile/petProfile";
import { updatePetSuccess } from "core/actionCreators/petsActionCreators";
import { getPetEligibilityAfterChange } from "../eligibility/actions/eligibilityPetActions";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import getPrismPetState from "../prismPet/prismPetSelector";

function* onUpdatePetFavorabilityHotel({ customerKey, petId, formValues }) {
  try {
    yield put(updatePetFavorabilityHotelRequest());
    const prismPet = yield select(getPrismPetState, { petId });

    // if the there is no profile in state, e.g. null we just provide an bare profile object.
    const hotelProfile = prismPet?.profiles
      ? prismPet.profiles.find(({ profileType }) => profileType === systemName.DDC_HOTEL)
      : { profileType: systemName.DDC_HOTEL };

    const PetFavorability = yield select(getPetFavorabilityHotel, { petId });

    if (PetFavorability) {
      // Since the petRating is used as in ID in the UI, we need to get the corresponding string to the ID so the string can be passed to the API
      const ratingOption = find(
        option => option.value === formValues.ratingTypeId,
        favourabilityOptions,
      );

      const data = {
        profiles: [
          {
            profileType: systemName.DDC_HOTEL,
            petRating: getOr("", "label", ratingOption),
            petRatingReason: getOr("", "ratingReason", formValues),
          },
        ],
      };

      const response = yield call(updatePetProfilePartial, {
        customerKey,
        petKey: petId,
        data,
      });

      const updatedPrismPet = response.data.result.profiles.find(
        ({ profileType }) => profileType === systemName.DDC_HOTEL,
      );
      const updatedPetRating = getOr("", "petRating", updatedPrismPet);
      const updatedPetRatingReason = getOr("", "petRatingReason", updatedPrismPet);

      yield put(
        updatePetSuccess({
          pet: {
            petId: Number(petId),
            prismPet: {
              ...prismPet,
              profiles: [
                {
                  profileType: systemName.DDC_HOTEL,
                  ...hotelProfile,
                  petRatingReason: updatedPetRatingReason,
                  petRating: updatedPetRating,
                },
              ],
            },
          },
        }),
      );

      yield put(updatePetFavorabilityHotelSuccess());

      yield put(getPetEligibilityAfterChange({ petId, customerKey }));
    }
  } catch (error) {
    yield put(updatePetFavorabilityHotelFailure({ error }));
  }
}

function* watchUpdatePetFavorabilityHotel() {
  yield takeEvery(
    petFavorabilityHotelActionTypes.UPDATE_PET_FAVORABILITY_HOTEL,
    onUpdatePetFavorabilityHotel,
  );
}

export default function* updatePetFavorabilityHotelSaga() {
  yield all([watchUpdatePetFavorabilityHotel()]);
}
