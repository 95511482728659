import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { getConstructedHotelItinerary } from "dux/hotelItinerary/hotelConstructedItinerarySelectors";
import { updateItineraryWithPendingChanges } from "dux/hotelItinerary/hotelItineraryUtils";
import {
  DELETE_CHECK_IN_FEEDING,
  deleteCheckInFeedingFailure,
  deleteCheckInFeedingRequest,
  deleteCheckInFeedingSuccess,
} from "dux/_components/checkInOutFeeding/checkInFoodsActions";
import { onPutHotelItinerary } from "@/dux/hotelItinerary/hotelItinerarySaga";
import { selectCurrentStoreTimeZone } from "@/core/selectors/persistentSelectors";
import { selectPetFoodsWithoutFoodToBeRemoved } from "./CheckInOutCartFoodSelectors";

function* onDeleteCheckInFeeding({ petId, foodId }) {
  try {
    yield put(deleteCheckInFeedingRequest());

    // get the itinerary
    const itinerary = yield select(getConstructedHotelItinerary);

    const foods = yield select(selectPetFoodsWithoutFoodToBeRemoved(foodId), { petId });
    const timeZone = yield select(selectCurrentStoreTimeZone);

    // re-construct hotel Itinerary body, the api is a put only and requires the full body.
    const itineraryPayload = updateItineraryWithPendingChanges({
      itinerary,
      currentPetId: petId,
      foods,
      timeZone,
    });

    yield call(onPutHotelItinerary, { hotelItinerary: itineraryPayload });

    yield put(deleteCheckInFeedingSuccess());
  } catch (error) {
    yield put(deleteCheckInFeedingFailure(error));
  }
}

/** ----------------------------------------------------------------------- **\
 WATCH FUNCTIONS
 \** ----------------------------------------------------------------------- * */
function* watchDeleteCheckInFeeding() {
  yield takeEvery(DELETE_CHECK_IN_FEEDING, onDeleteCheckInFeeding);
}

/** ----------------------------------------------------------------------- **\
 EXPORT SAGAS
 \** ----------------------------------------------------------------------- * */
export default function* deleteCheckInFeedingSaga() {
  yield all([fork(watchDeleteCheckInFeeding)]);
}
