import { connect } from "react-redux";
import { get } from "lodash/fp";
import RemoveButtonWithLabel from "../common/buttons/RemoveButtonWithLabel";
import { showConfirmationModal } from "../../core/actionCreators/confirmationModalActionCreators";
import { removePetMedFromBooking } from "./actions/medicationListActions";
import { isFromBooking } from "../../core/utils/validationUtils/isFromValidation";
import { history } from "@/dux/utils/browser/browserHistory";
import { putCart } from "@/dux/servicesCart/servicesCartActions";

const mapStateToProps = (state, ownProps) => {
  const { medicationIndex, medicationId, petId } = ownProps;
  return {
    petId,
    isHidden: !isFromBooking(get(["location", "pathname"], history)),
    medicationIndex,
    medicationId,
    label: `Medication ${medicationIndex}`,
  };
};

const mapDispatchToProps = dispatch => ({
  deletePetMed: (medicationId, petId) => {
    dispatch(removePetMedFromBooking({ medicationId, petId }));
    dispatch(putCart());
  },
  showConfirmationModal: args => dispatch(showConfirmationModal(args)),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { medicationId, medicationIndex, petId } = propsFromState;
    propsFromDispatch.showConfirmationModal({
      header: `Remove medication ${medicationIndex} from booking`,
      content: "Are you sure want to remove this medication from booking?",
      confirmText: `Remove medication ${medicationIndex}`,
      confirm: () => propsFromDispatch.deletePetMed(medicationId, petId),
      cancelText: "Go back",
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RemoveButtonWithLabel);
