import { connect } from "react-redux";
import {
  getPendingFrequency,
  getPendingFrequencyDates,
  getPendingFrequencyType,
  getPendingId,
  getPendingPetId,
  selectIsFrequencySaveDisabled,
  selectPendingFrequencyIsFromCart,
} from "dux/frequency/frequencySelectors";
import {
  setFoodFrequency,
  setFoodFrequencyDates,
} from "dux/bookingFeeding/setFoodFrequency/actions/foodFrequencyActions";
import {
  setMedicationFrequency,
  setMedicationFrequencyDates,
} from "dux/bookingPage/setMedFrequency/medicationFrequencyActions";
import { buildEngagementAddonUpdatesForPut } from "@/core/utils/hotelEngagementUtils/buildEngagementsWithUpdatedAddons";
import {
  getPetHotelEngagements,
  selectAppliedAddonsFromEngagementsByPet,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { putHotelItineraryAddons } from "dux/hotelItineraryAddonPut/hotelItineraryAddonPutActions";
import { putCart } from "@/dux/servicesCart/servicesCartActions";
import { setHotelCartProductFrequency } from "@/dux/servicesCartHotel/servicesCartHotelActions";
import { hideBookingModal } from "core/actionCreators/bookingActionCreators";
import CommonButton from "../../../web/common/commonButton";
import { frequencyConstants, frequencyTypes } from "../FrequencyConstants";
import { getCartItemId } from "@/dux/servicesCart/servicesCartUtils";
import { selectEnhancedAddonChildrenFromCart } from "@/dux/servicesCartHotel/servicesCartHotelSelectors";
import { isFromCheckInOut } from "core/utils/validationUtils/isFromValidation";
import { history } from "@/dux/utils/browser/browserHistory";
import { selectCurrentStoreTimeZone } from "@/core/selectors/persistentSelectors";

const mapStateToProps = (state, { onClose }) => {
  const pendingId = getPendingId(state);
  const petId = getPendingPetId(state);
  const petEngagements = getPetHotelEngagements(state, { petId });
  const addons = selectAppliedAddonsFromEngagementsByPet(state, { petId });
  const isCheckInOut = isFromCheckInOut(history?.location?.pathname);

  return {
    pendingId,
    petId,
    pendingDates: getPendingFrequencyDates(state),
    pendingFrequency: getPendingFrequency(state),
    frequencyType: getPendingFrequencyType(state),
    label: "Save",
    width: "8.5em",
    disabled: selectIsFrequencySaveDisabled(state),
    onClose,
    isFromCart: selectPendingFrequencyIsFromCart(state),
    petEngagements,
    addons,
    isCheckInOut,
    childAddons: selectEnhancedAddonChildrenFromCart({ petId, productId: pendingId })(state),
    timeZone: selectCurrentStoreTimeZone(state),
  };
};

const mapDispatchToProps = dispatch => ({
  saveMedicationFrequency: values => {
    // On a save, set frequency dates (if manual - set frequency dates, if not, clear them) and frequency type.
    // Clear pending frequency.
    dispatch(
      setMedicationFrequency({
        petId: values.petId,
        medicationId: values.medicationId,
        frequency: values.pendingFrequency ? values.pendingFrequency : frequencyConstants.DAILY,
      }),
    );
    dispatch(
      setMedicationFrequencyDates({
        petId: values.petId,
        medicationId: values.medicationId,
        dates: values.pendingFrequency === frequencyConstants.MANUAL ? values.pendingDates : null,
      }),
    );
  },
  saveFoodFrequency: values => {
    // On a save, set frequency dates (if manual - set frequency dates, if not, clear them) and frequency type.
    // Clear pending frequency.
    dispatch(
      setFoodFrequency({
        petId: values.petId,
        foodId: values.foodId,
        frequency: values.pendingFrequency ? values.pendingFrequency : frequencyConstants.DAILY,
      }),
    );
    dispatch(
      setFoodFrequencyDates({
        petId: values.petId,
        foodId: values.foodId,
        dates: values.pendingFrequency === frequencyConstants.MANUAL ? values.pendingDates : null,
      }),
    );
  },
  saveAddonFrequency: ({
    petId,
    pendingId,
    pendingFrequency = frequencyConstants.DAILY,
    pendingDates = [],
    childAddons,
  }) => {
    const frequency = pendingFrequency;
    const customFrequencyDates = pendingFrequency === frequencyConstants.MANUAL ? pendingDates : [];
    dispatch(
      setHotelCartProductFrequency({
        cartItemId: getCartItemId({ petId, item: { productId: pendingId } }),
        frequency,
        customFrequencyDates,
      }),
    );

    const children = Object.values(childAddons);
    children?.map(child =>
      dispatch(
        setHotelCartProductFrequency({
          cartItemId: getCartItemId({ petId, item: child }),
          frequency,
          customFrequencyDates,
        }),
      ),
    );

    dispatch(putCart());
  },
  saveCheckInOutAddonFrequency: ({
    petId,
    petEngagements,
    pendingFrequency = frequencyConstants.DAILY,
    pendingDates = [],
    addons,
    pendingId,
    timeZone,
  }) => {
    const engagements = buildEngagementAddonUpdatesForPut({
      petEngagements,
      petAddons: addons,
      addonToEditId: pendingId,
      frequency: pendingFrequency,
      dates: pendingDates,
      timeZone,
    });

    dispatch(putHotelItineraryAddons({ petId, updatedEngagements: engagements }));
  },
  clearFrequency: () => {
    dispatch(hideBookingModal());
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const {
      pendingId,
      pendingFrequency,
      pendingDates,
      petId,
      frequencyType,
      onClose,
      isFromCart,
      petEngagements,
      addons,
      isCheckInOut,
      childAddons,
      timeZone,
    } = propsFromState;

    if (onClose) onClose();

    if (frequencyType === frequencyTypes.MEDICATION) {
      propsFromDispatch.saveMedicationFrequency({
        medicationId: pendingId,
        petId,
        pendingFrequency,
        pendingDates,
      });
    }
    if (frequencyType === frequencyTypes.FOOD) {
      propsFromDispatch.saveFoodFrequency({
        foodId: pendingId,
        petId,
        pendingFrequency,
        pendingDates,
      });
    }
    if (!!isFromCart && !isCheckInOut && frequencyType === frequencyTypes.ADDON) {
      propsFromDispatch.saveAddonFrequency({
        petId,
        pendingId,
        pendingFrequency,
        pendingDates,
        childAddons,
        timeZone,
      });
    }
    if (isFromCart && isCheckInOut && frequencyType === frequencyTypes.ADDON) {
      propsFromDispatch.saveCheckInOutAddonFrequency({
        petId,
        pendingFrequency,
        pendingDates,
        petEngagements,
        addons,
        pendingId,
        timeZone,
      });
    }

    propsFromDispatch.clearFrequency();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
