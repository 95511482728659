import { connect } from "react-redux";
import { getPetAlertsFormData } from "../../core/selectors/entitiesSelector";
import { updatePet } from "../../core/actionCreators/petsActionCreators";
import AlertsFormComponent from "../../web/petParentProfile/alerts/AlertsFormComponent";
import { systemName } from "../../web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "../../web/setSystemType/selectors/setSystemTypeSelectors";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getSelectedPet } from "core/selectors/bookingUISelectors";

const mapStateToProps = (state, ownProps) => {
  const customerKey = getCurrentCustomerKey(state);
  const selectedPet = getSelectedPet(state);

  return {
    componentID: "alertForm-SalonPetAlerts",
    title: "Pet Alerts",
    isHidden: getSystemBookingFlow(state) !== systemName.SALON,
    alertsFormData: getPetAlertsFormData(state, { petId: selectedPet }),
    showServiceCardAlert: true,

    // here for mergeProps
    customerKey,
    selectedPet,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleOnSubmit: ({ customerKey, petId, values }) => {
    dispatch(
      updatePet({
        customerKey,
        petId,
        data: {
          groomingBookingAlert: values.isBookingAlert ? values.bookingAlertReason : null,
          groomingCheckInAlert: values.isCheckInAlert ? values.checkInAlertReason : null,
          groomingCheckOutAlert: values.isCheckOutAlert ? values.checkOutAlertReason : null,
          groomingServiceCardAlert: values.isServiceCardAlert ? values.serviceCardAlertReason : null,
        },
      }),
    );
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSubmit: values =>
    propsFromDispatch.handleOnSubmit({
      customerKey: propsFromState.customerKey,
      petId: propsFromState.selectedPet,
      values,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AlertsFormComponent);
