import { connect } from "react-redux";
import { get } from "lodash/fp";
import moment from "moment";
import { loadSalonInfoWithHours } from "core/actionCreators/salonActionCreator";
import { selectIsMultiPetBooking, getSelectedPet } from "core/selectors/bookingUISelectors";
import {
  getAppointmentByPet,
  getCanNotBookPetsInCart,
  getCartSpecialsByPet,
  getIsBoarded,
} from "core/selectors/cartSelectors";
import { selectSalonTotalPriceByPet } from "core/selectors/cartPriceSelectors";
import {
  getAppliedSpecials,
  getAssociate,
  getPetBreedName,
  getPetById,
  getPetServiceItem,
  getServiceDetailsForCart,
  getShouldShowServiceCompleteForAll,
  getStoreFromSalon,
} from "core/selectors/entitiesSelector";
import { createLoadingSelector } from "core/selectors/utils";
import itinerariesActionTypes from "core/actionTypes/itinerariesActionTypes";
import engagementsActionTypes from "core/actionTypes/engagementsActionTypes";
import {
  selectPet,
  setCurrentBookingPetServiceItemId,
  setItineraryId,
  setPetIdToRemoveFromCart,
  showBookingModal,
} from "core/actionCreators/bookingActionCreators";
import {
  clearIsBoarded,
  initializeCartByItinerary,
  setIsBoarded,
} from "core/actionCreators/ui/web/cartActionCreators";
import {
  undoLastEngagementStatusUpdate,
  updateEngagementStatus,
} from "core/actionCreators/engagementsActionCreators";
import {
  undoLastItineraryStatusUpdate,
  updateItineraryStatus,
} from "core/actionCreators/itinerariesActionCreators";
import { loadStoreServicesForPet } from "core/actionCreators/servicesSelectionActionCreator";
import { loadAddOns } from "core/actionCreators/addOnsActionCreator";
import {
  loadSpecials,
  postSpecials,
  removeSpecials,
} from "core/actionCreators/specialsActionCreator";
import {
  getNumberOfPetsInItinerary,
  getPageName,
  isCartDetailsEditable,
  isFromAnotherStore,
} from "core/selectors/checkInOutSelector";
import {
  getAddonsWithDynamicPrice,
  getAvailableSpecialsByPetServiceItem,
  getDynamicPrices,
  getEnhancedServicesWithDynamicPrice,
} from "core/selectors/dynamicPricesSelector";

import { showCheckInOutModal } from "core/actionCreators/checkInOutActionCreator";
import { getIsSRCAgent } from "core/selectors/persistentSelectors";
import { modalTypes } from "core/constants/modalConstants";
import { updatePetServiceItem } from "core/actionCreators/petServiceItemsActionCreators";
import { APPOINTMENT_STATUS } from "core/constants";
import { showConfirmationModal } from "core/actionCreators/confirmationModalActionCreators";
import { eligibilityTypes } from "dux/eligibility/constants/eligibilityConstants";
import { formatCalendarDateMoment } from "core/utils/dateUtils/formatDateTime";
import { onSelectAppointment } from "core/actionCreators/ui/web/commonActionCreators";
import { showCancelAppointmentModal } from "core/actionCreators/cancellationsActionCreators";
import {
  getBundleEnhacedServiceIdByPetServiceId,
  getBundleOfferTypeByPetServiceId,
  getIsActiveBundleAvailable,
} from "dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import { getIsAnyBulkPackageOfferingSelected } from "dux/bgm/bulkPackageOfferings/bulkPackageOfferingsSelectors";
import withInBookingOrModify from "web/common/hoc/withInBookingOrModify";
import getIsBGMWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import { getPetEligibility } from "dux/eligibility/actions/eligibilityPetActions";
import getFTCOWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import CartDetailsComponent from "./CartDetailsComponent";
import { getCancellationReasons } from "dux/cancellationreasons/cancellationReasonsActions";
import { selectIsEnhancedServicesVisible } from "@/dux/enhancedServicesList/EnhancedServicesListSelectors";
import { selectPrePaidBundleEnhancedServicePrice } from "@/dux/_selectors/addOnsSelectors";

const mapStateToProps = (state, ownProps) => {
  const {
    router: {
      location,
      params: { itineraryId },
    },
    inBookingOrModify,
    petId,
  } = ownProps;

  const appointment = getAppointmentByPet(state, ownProps);
  const petServiceItemId = get("petServiceItemId", appointment);
  const pet = getPetById(state, petId);
  const petServiceItem = getPetServiceItem(state, { petServiceItemId }) || false;
  const pageName = getPageName(state);

  const path = location.pathname;

  const serviceDetailsForCart = getServiceDetailsForCart(state, {
    petServiceId: appointment.petService,
    petServiceItemId,
  });

  const petServiceId = appointment.petService;

  const totalPrice = selectSalonTotalPriceByPet(state, { petId, petServiceId });

  const isBGMWorkflowFeatureFlagHidden = getIsBGMWorkflowFeatureFlagHidden(state);
  const isBundleAvailable = getIsActiveBundleAvailable(state, { petId, petServiceId });
  const offerType = getBundleOfferTypeByPetServiceId(state, { petId, petServiceId });

  const isMultiPet = selectIsMultiPetBooking(state);

  const isEnhancedServicesVisible = selectIsEnhancedServicesVisible(state, { petId });

  const enhancedServices = getEnhancedServicesWithDynamicPrice(state, { petId });

  return {
    pageName,
    pet,
    petServiceId,
    petServiceItemId,
    offerType,
    isBundleAvailable,
    isBGMWorkflowFeatureFlagHidden,
    isPreCheckedIn: appointment?.completedPreCheckIn,
    isHidden:
      (!getIsBGMWorkflowFeatureFlagHidden(state) || !getFTCOWorkflowFeatureFlagHidden(state)) &&
      getIsAnyBulkPackageOfferingSelected(state),
    isModifying: path.includes("modify-appointment"),
    service: serviceDetailsForCart,
    addons: getAddonsWithDynamicPrice(state, { petId }),
    enhancedServices,
    availableSpecials: getAvailableSpecialsByPetServiceItem(state, { petServiceItemId }),
    selectedSpecials: getCartSpecialsByPet(state, { petId }),
    selectedPet: getSelectedPet(state),
    associate:
      appointment &&
      appointment.timeSlot &&
      getAssociate(state, { associateId: appointment.timeSlot.associateId }),
    isCanceling: createLoadingSelector([
      itinerariesActionTypes.DELETE_ITINERARY,
      engagementsActionTypes.DELETE_ENGAGEMENT,
    ])(state),
    totalPrice:
      ownProps.multiplePets && !petServiceItem ? totalPrice : petServiceItem?.currentPrice,
    petServiceItem,
    appointment,
    dynamicPrices: getDynamicPrices(state),
    appliedSpecials:
      petServiceItem && getAppliedSpecials(state, { itineraryId: petServiceItem.itinerary }),
    isSRCAgent: getIsSRCAgent(state),
    currentStore:
      petServiceItem && getStoreFromSalon(state, { storeNumber: petServiceItem.storeNumber }),
    breedName: getPetBreedName(state, { breedId: pet.breedId }),
    isEditable: isCartDetailsEditable(state, { petServiceItemId }),
    isFromAnotherStore: isFromAnotherStore(state, { petServiceItemId }),
    isBoarded: getIsBoarded(state, { petId: pet.petId }),
    shouldShowServiceCompleteForAll:
      itineraryId && getShouldShowServiceCompleteForAll(state, { itineraryId }),
    canOverride: !inBookingOrModify,
    numberOfPets: getNumberOfPetsInItinerary(state),
    canNotBookPets: getCanNotBookPetsInCart(state),
    isMultiPet,
    isEnhancedServicesVisible,
    preSelectedBundleEnhancedServicePrice: selectPrePaidBundleEnhancedServicePrice(state, {
      petId: pet.petId,
      petServiceId,
    }),
    isSelectedBundleEnhancedService:
      getBundleEnhacedServiceIdByPetServiceId(state, { ...ownProps, petServiceId }) ===
      enhancedServices?.addOnId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    router: {
      navigate,
      params: { customerKey, itineraryId },
    },
    petId,
    inBookingOrModify,
  } = ownProps;

  return {
    setPetIdToRemoveFromCart: petId => {
      dispatch(setPetIdToRemoveFromCart(petId));
    },
    onCancelAppointment: () => {
      dispatch(showBookingModal(modalTypes.CANCEL_APPOINTMENT));
    },
    loadCancellationReasons: () => dispatch(getCancellationReasons()),
    onSetCurrentBookingPetServiceItemId: petServiceItemId =>
      dispatch(setCurrentBookingPetServiceItemId(petServiceItemId)),
    onSelectPet: petId => dispatch(selectPet(petId)),
    initializeCart: petServiceItem => {
      dispatch(
        initializeCartByItinerary({ itineraryId: petServiceItem && petServiceItem.itinerary }),
      );
    },
    postSpecials: ({ customerId, itineraryId, specials }) =>
      dispatch(postSpecials({ customerId, itineraryId, specials })),
    removeSpecials: ({ customerId, itineraryId, specials }) =>
      dispatch(removeSpecials({ customerId, itineraryId, specials })),
    showCancelAppointmentModal: modalType => dispatch(showCancelAppointmentModal({ modalType })),
    showBookingModal: modalType => dispatch(showBookingModal(modalType)),
    undoLastEngagementStatusUpdate: ({ customerId, itineraryId, engagementId }) =>
      dispatch(undoLastEngagementStatusUpdate({ customerId, itineraryId, engagementId })),
    undoLastItineraryStatusUpdate: ({ customerId, itineraryId }) =>
      dispatch(undoLastItineraryStatusUpdate({ customerId, itineraryId })),
    setIsBoarded: () => dispatch(setIsBoarded({ petId })),
    clearIsBoarded: () => dispatch(clearIsBoarded({ petId })),
    onServiceCompleteForEngagement: ({ engagementId }) =>
      dispatch(
        updateEngagementStatus({
          status: APPOINTMENT_STATUS.SERVICE_COMPLETED,
          customerId: customerKey,
          itineraryId,
          engagementId,
        }),
      ),
    onServiceCompleteForItinerary: () =>
      dispatch(
        updateItineraryStatus({
          status: APPOINTMENT_STATUS.SERVICE_COMPLETED,
          customerId: customerKey,
          itineraryId,
        }),
      ),
    onUpdatePetServiceItem: ({ petServiceItem, data }) => {
      if (petServiceItem) {
        return dispatch(
          updatePetServiceItem({
            customerKey: petServiceItem.customer,
            itineraryId: petServiceItem.itinerary,
            engagementId: petServiceItem.engagement,
            petServiceItemId: petServiceItem.petServiceItemId,
            dispatchToCart: true,
            data: {
              ...data,
              isManualAppointment: inBookingOrModify ? undefined : !!data.isManualAppointment,
            },
          }),
        );
      }
    },
    onModifyAppointment: () => {
      dispatch(setItineraryId(itineraryId));
      navigate(`/modify-appointment/${customerKey}/${itineraryId}`);
    },
    loadServices: ({ pet: petId, startDateTime: fromDate }) => {
      dispatch(loadStoreServicesForPet({ petId, fromDate, customerKey }));
    },
    loadAddons: ({ petServiceId, startDateTime: fromDate }) => {
      dispatch(loadAddOns({ petServiceId, fromDate }));
    },
    loadSpecials: ({ petServiceItemId, itinerary: itineraryId }) => {
      dispatch(loadSpecials({ petServiceItemId, itineraryId }));
    },
    fetchStore: ({ storeNumber }) => {
      dispatch(loadSalonInfoWithHours({ storeNumber }));
    },
    showConfirmationModal: args => dispatch(showConfirmationModal(args)),
    onGetPetEligibility: ({ petId, startDateTime }) => {
      dispatch(
        getPetEligibility({
          petId,
          customerKey,
          startDate: formatCalendarDateMoment(moment(startDateTime)),
          eligibilityType: eligibilityTypes.CHECK_IN,
        }),
      );
    },
    onShowAppointmentHistory: ({ petServiceItemId }) => {
      dispatch(onSelectAppointment({ appointmentId: petServiceItemId }));
      dispatch(showCheckInOutModal({ modalType: modalTypes.SALON_APPOINTMENT_HISTORY }));
    },
  };
};

export default withInBookingOrModify(
  connect(mapStateToProps, mapDispatchToProps)(CartDetailsComponent),
);
