/**
 * @param {Object} param - results from eligibility BFF
 * @returns array of alerts formatted for state
 */
export const formatAlertsForState = ({ customerEligibilityResponse, petsEligibilityResponse }) => {
  const customerAlerts = customerEligibilityResponse?.map(({ customerId, ...customerAlert }) => ({
    ...customerAlert,
    type: "Customer",
    recordKey: `${customerId}`,
  }));

  const petAlerts = petsEligibilityResponse?.map(({ petId, ...petAlert }) => ({
    ...petAlert,
    type: "Pet",
    recordKey: `${petId}`,
  }));

  return [...customerAlerts, ...petAlerts];
};
