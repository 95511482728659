import { connect } from "react-redux";
import UnderlinedTab from "../../../../common/tabs/underlinedTabs/UnderlinedTab";
import { setActiveTab } from "../appointmentsListActions";
import { tabFilters } from "../appointmentsListConstants";
import { getSelectedTab } from "../appointmentsListSelectors";

const mapStateToProps = state => ({
  isActive: getSelectedTab(state) === tabFilters.APPOINTMENTS,
  componentID: "AppointmentsListTab",
  label: "Appointments",
});

const mapDispatchToProps = dispatch => ({
  handleClick: () => {
    dispatch(setActiveTab({ name: tabFilters.APPOINTMENTS }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnderlinedTab);
