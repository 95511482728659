import {
  HotelArrivalsHeader,
  HotelCheckedOutHeader,
  HotelDeparturesHeader,
  HotelOvernightHeader,
} from "dux/_components/titleCard/TitleCard";

import HotelArrivalsOverNightFilterBtnContainer from "../hotelArrivals/columnHeader/filterButtons/HotelArrivalsOverNightFilterBtnContainer";
import HotelArrivalsDayCampFilterBtnContainer from "../hotelArrivals/columnHeader/filterButtons/HotelArrivalsDayCampFilterBtnContainer";
import HotelArrivalsDayCareFilterBtnContainer from "../hotelArrivals/columnHeader/filterButtons/HotelArrivalsDayCareFilterBtnContainer";
import HotelArrivalsAllFilterBtnContainer from "../hotelArrivals/columnHeader/filterButtons/HotelArrivalsAllFilterBtnContainer";
import HotelArrivalsAttentionNeededFilterBtn from "../hotelArrivals/columnHeader/filterButtons/HotelArrivalsAttentionNeededFilterBtn";
import HotelArrivalsUnassignedPetFilterBtnContainer from "../hotelArrivals/columnHeader/filterButtons/HotelArrivalsUnassignedPetFilterBtnContainer";

import HotelDeparturesAttentionNeededFilterBtn from "../hotelDepartures/columnHeader/filterButtons/HotelDeparturesAttentionNeededFilterBtn";
import HotelDeparturesOverNightFilterBtnContainer from "../hotelDepartures/columnHeader/filterButtons/HotelDeparturesOverNightFilterBtnContainer";
import HotelDeparturesDayCampFilterBtnContainer from "../hotelDepartures/columnHeader/filterButtons/HotelDeparturesDayCampFilterBtnContainer";
import HotelDeparturesDayCareFilterBtnContainer from "../hotelDepartures/columnHeader/filterButtons/HotelDeparturesDayCareFilterBtnContainer";
import HotelDeparturesAllFilterBtnContainer from "../hotelDepartures/columnHeader/filterButtons/HotelDeparturesAllFilterBtnContainer";
import HotelDeparturesUnassignedPetFilterBtn from "../hotelDepartures/columnHeader/filterButtons/HotelDeparturesUnassignedPetFilterBtn";

import HotelOverNightFilterBtnContainer from "../hotelOvernight/columnHeader/filterButtons/HotelOverNightFilterBtnContainer";
import HotelOverNightDayCampFilterBtnContainer from "../hotelOvernight/columnHeader/filterButtons/HotelOverNightDayCampFilterBtnContainer";
import HotelOverNightDayCareFilterBtnContainer from "../hotelOvernight/columnHeader/filterButtons/HotelOverNightDayCareFilterBtnContainer";
import HotelOverNightAllFilterBtnContainer from "../hotelOvernight/columnHeader/filterButtons/HotelOverNightAllFilterBtnContainer";
import HotelOvernightAttentionNeededBtn from "../hotelOvernight/columnHeader/filterButtons/HotelOvernightAttentionNeededBtn";
import HotelOvernightUnassignedPetBtn from "../hotelOvernight/columnHeader/filterButtons/HotelOvernightUnassignedPetBtn";

import HotelCheckedOutOverNightFilterBtnContainer from "../hotelCheckedOut/columnHeader/filterButtons/HotelCheckedOutOverNightFilterBtnContainer";
import HotelCheckedOutAllFilterBtnContainer from "../hotelCheckedOut/columnHeader/filterButtons/HotelCheckedOutAllFilterBtnContainer";
import HotelCheckedOutDayCampFilterBtnContainer from "../hotelCheckedOut/columnHeader/filterButtons/HotelCheckedOutDayCampFilterBtnContainer";
import HotelCheckedOutDayCareFilterBtnContainer from "../hotelCheckedOut/columnHeader/filterButtons/HotelCheckedOutDayCareFilterBtnContainer";

import HotelArrivalsContainer from "../hotelArrivals/columnList/HotelArrivalsContainer";
import HotelDeparturesContainer from "../hotelDepartures/columnList/HotelDeparturesContainer";
import HotelOvernightContainer from "../hotelOvernight/columnList/HotelOvernightContainer";
import HotelCheckedOutContainer from "../hotelCheckedOut/columnList/HotelCheckedOutContainer";

export const hotelDashboardHeaderCompNames = {
  arrivals: HotelArrivalsHeader,
  departures: HotelDeparturesHeader,
  overnight: HotelOvernightHeader,
  checkedOut: HotelCheckedOutHeader,
};
export const hotelDashboardColListCompNames = {
  arrivals: HotelArrivalsContainer,
  departures: HotelDeparturesContainer,
  overnight: HotelOvernightContainer,
  checkedOut: HotelCheckedOutContainer,
};

export const HotelOverNightFilterBtnCompNames = {
  arrivals: HotelArrivalsOverNightFilterBtnContainer,
  departures: HotelDeparturesOverNightFilterBtnContainer,
  overnight: HotelOverNightFilterBtnContainer,
  checkedOut: HotelCheckedOutOverNightFilterBtnContainer,
};

export const HotelDayCampFilterBtnCompNames = {
  arrivals: HotelArrivalsDayCampFilterBtnContainer,
  departures: HotelDeparturesDayCampFilterBtnContainer,
  overnight: HotelOverNightDayCampFilterBtnContainer,
  checkedOut: HotelCheckedOutDayCampFilterBtnContainer,
};

export const HotelDayCareFilterBtnCompNames = {
  arrivals: HotelArrivalsDayCareFilterBtnContainer,
  departures: HotelDeparturesDayCareFilterBtnContainer,
  overnight: HotelOverNightDayCareFilterBtnContainer,
  checkedOut: HotelCheckedOutDayCareFilterBtnContainer,
};

export const HotelAllFilterBtnCompNames = {
  arrivals: HotelArrivalsAllFilterBtnContainer,
  departures: HotelDeparturesAllFilterBtnContainer,
  overnight: HotelOverNightAllFilterBtnContainer,
  checkedOut: HotelCheckedOutAllFilterBtnContainer,
};

export const HotelAttentionNeededFilterBtnCompNames = {
  arrivals: HotelArrivalsAttentionNeededFilterBtn,
  overnight: HotelOvernightAttentionNeededBtn,
  departures: HotelDeparturesAttentionNeededFilterBtn,
};

export const HotelUnassignedPetFilterBtnCompNames = {
  arrivals: HotelArrivalsUnassignedPetFilterBtnContainer,
  overnight: HotelOvernightUnassignedPetBtn,
  departures: HotelDeparturesUnassignedPetFilterBtn,
};
