import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getHotelServiceManagement } from "dux/serviceManagementPage/serviceManagementPageActions";
import { Layout, Text } from "@prism/psm-ui-components";
import { DDCServiceManagementHeader } from "dux/serviceManagementHeader/serviceManagementHeaderContainer";
import { ServiceSuspensionToggle } from "dux/servicesManagementToggle/ServicesManagementToggle";
import { ScheduleSuspensionButton } from "dux/serviceManagementHeader/ServicesManagementRouteButtons";
import { HotelServiceManagementDatePicker } from "dux/serviceManagementDatePicker/ServiceManagementDatePickerContainer";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { createLoadingSelector } from "core/selectors/utils";
import {
  GET_HOTEL_SERVICE_SUSPENSION,
  PATCH_HOTEL_SERVICE_SUSPENSION,
  POST_HOTEL_SERVICE_SUSPENSION,
} from "dux/scheduleSuspensionPage/scheduleSuspensionActions";
import { HotelScheduleSuspensionCancelModal } from "dux/scheduleSuspensionCancelModal/scheduleSuspensionCancelModal";
import LoadingWrapper from "web/common/LoadingWrapper";
import { selectSuspensionTypes } from "dux/hotelSelectedSuspensionType/SelectedSuspensionTypeSelectors";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";

export const ServiceManagementPage = props => {
  const { componentId, isHidden, getHotelServiceManagement, isLoading, suspensionTypes } = props;

  useEffect(() => {
    getHotelServiceManagement();
  }, []);

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack>
        {/* --- HEADER ---*/}
        <Layout.Box id={`${componentId}__header`}>
          <DDCServiceManagementHeader>
            <ScheduleSuspensionButton />
          </DDCServiceManagementHeader>
        </Layout.Box>

        {/* --- PAGE BODY ---*/}
        <Layout.Box>
          <Layout.Cluster style={{ overflow: "visible" }}>
            <Layout.Box>
              <Layout.Stack space="stack-space-8">
                {/* --- DATE PICKER --- */}
                <Layout.Cluster justify="center" style={{ overflow: "visible" }}>
                  <Layout.Box style={{ minHeight: "50px" }}>
                    <HotelServiceManagementDatePicker />
                  </Layout.Box>
                </Layout.Cluster>

                {/* --- TITTLE AND TOGGLES --- */}
                <LayoutBox>
                  <LayoutStack >
                    {/* --- ON OFF TOGGLE COMPONENTS --- */}
                    <LoadingWrapper isLoading={isLoading} displayContainer="block">
                        <LayoutBox>
                          <LayoutCluster style={{alignItems: "flex-start"}}>
                            {/*SAME DAY TOGGLES*/}
                            {
                              suspensionTypes.map((suspensionType) => {
                                return (
                                  <LayoutBox>
                                    <ServiceSuspensionToggle key={suspensionType.suspensionTypeId} suspensionType={suspensionType}  />
                                  </LayoutBox>
                                )
                              })
                            }
                          </LayoutCluster>
                        </LayoutBox>
                    </LoadingWrapper>


                    {/*LOADING MESSAGE */
                    isLoading && (
                      <Layout.Box>
                        <Text size="text-size-lg" align="center" bold>
                          Suspension's Loading
                        </Text>
                      </Layout.Box>
                    )}
                  </LayoutStack>
                </LayoutBox>
              </Layout.Stack>
            </Layout.Box>
          </Layout.Cluster>
        </Layout.Box>
      </LayoutStack>

      {/*MODAL*/}
      <HotelScheduleSuspensionCancelModal />
    </LayoutBox>
  );
};

// CONTAINER ----------------------------------------------------------------------------------------
export const HotelServiceManagementPage = connect(
  state => {
    const selectedDate = getSelectedDate(state);
    const isLoading = createLoadingSelector([
      POST_HOTEL_SERVICE_SUSPENSION,
      GET_HOTEL_SERVICE_SUSPENSION,
      PATCH_HOTEL_SERVICE_SUSPENSION,
    ])(state);

    const suspensionTypes = selectSuspensionTypes(state);

    return {
      componentId: "serviceManagementPage",
      title: "Doggie Day Camp Services Manager",
      suspensionTypes,

      // for mergeProps
      selectedDate,
      isLoading,
    };
  },

  dispatch => {
    return {
      handleGetHotelServiceManagement: ({ selectedDate }) => {
        // When the service Management page loads we only need to get scheduled suspensions for a single day, the selected date
        dispatch(getHotelServiceManagement({ fromDate: selectedDate, toDate: selectedDate }));
      },
    };
  },
  (mapProps, dispatchProps) => {
    const { componentId, title, selectedDate, isLoading, suspensionTypes } = mapProps;
    const { handleGetHotelServiceManagement } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      title,
      isLoading,
      suspensionTypes,

      // actions to pass to view
      getHotelServiceManagement: () => handleGetHotelServiceManagement({ selectedDate }),
    };
  },
)(ServiceManagementPage);
