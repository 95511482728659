import authActionTypes from "core/actionTypes/authActionTypes";

const initialState = null;
/**
 * Reducer function for managing secure redirect routes in the state.
 *
 * @memberof Reducer.persistent
 * @param {object} state - The current state.
 * @param {object} action - The action being dispatched.
 * @returns {object} The new state after applying the action.
 */
export const secureRedirectRouteReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.SET_SECURE_REDIRECT_ROUTE:
      return action.secureRedirectRoute;

    default:
      return state;
  }
};
