import { connect } from "react-redux";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import PetProfileDetailsComponent from "../features/petProfile/PetProfileDetailsTraining";

const mapStateToProps = state => {
  return {
    selectedPet: getCurrentPet(state),
    customerKey: getCurrentCustomerKey(state),
  };
};

export default connect(mapStateToProps)(PetProfileDetailsComponent);
