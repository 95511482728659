// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-titleBox__lists--main {
  margin-top: 2.5em;
  margin-bottom: 2.5rem;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/organisms/TitledBox/TitledBox.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":[".psm-titleBox__lists--main {\r\n  margin-top: 2.5em;\r\n  margin-bottom: 2.5rem;\r\n  font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
