import convertDateToTimestamp from "./dateToTimestamp";

/**
 * Determine if the date passed is expired based on the timestamp of the target date passed in
 * @param {String} date - Date to transform
 * @param {String} format - Target date to transform
 * @returns {Boolean}
 */
export default function isExpiredBeforeTargetDate(date, targetDate, format) {
  const expirationDateTimestamp = convertDateToTimestamp(date, format);
  const targetDateTimestamp = convertDateToTimestamp(targetDate, format);

  return expirationDateTimestamp < targetDateTimestamp;
}
