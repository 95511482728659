/* NOTE:
    Remember to use the correct Verb for watch and generator function names based on the verb for the api call.
    If you have more than one verb api call in a Saga generator, abstract to a new action - saga set.

    see adr-002-Redux_Flow_Naming_Conventions.md for more info

    These notes can be deleted. as they are just a reminder when creating files
*/

import { put, takeEvery, call, all } from "redux-saga/effects";
import { getNotifications } from "core/services/associateWeb/notifications";
import {
  GET_STORE_WIDE_NOTES,
  getStoreWideNotesRequest,
  getStoreWideNotesSuccess,
  getStoreWideNotesFailure,
} from "./storeWideNotesActions";
import { filter } from "lodash/fp";
import { NOTIFICATION_CENTER } from "dux/storeWideNotes/storeWideNotesConstants";
import { filterStoreWideNotifications } from "dux/storeWideNotes/_helpers_/storeWideNotesHelpers";

function* onGetStoreWideNotes({ storeNumber }) {
  try {
    yield put(getStoreWideNotesRequest());

    const response = yield call(getNotifications, { storeNumber });
    const results = response.data.result;

    const notifications = filterStoreWideNotifications(results);

    yield put(getStoreWideNotesSuccess({ notifications }));
  } catch (error) {
    yield put(getStoreWideNotesFailure(error));
  }
}

function* watchOnGetStoreWideNotes() {
  yield takeEvery(GET_STORE_WIDE_NOTES, onGetStoreWideNotes);
}

export default function* storeWideNotesSaga() {
  yield all([watchOnGetStoreWideNotes()]);
}
