import { connect } from "react-redux";
import HotelPetCardComponent from "dux/_components/petCard/HotelPetCardComponent";
import { history } from "dux/utils/browser/browserHistory";
import { routePaths } from "core/constants/routePaths";
import { setPetId } from "core/actionCreators/ui/web/generalActionCreators";
import { selectPet } from "core/actionCreators/bookingActionCreators";
import { reservationNeedsReviewBgColor } from "@/dux/hotelPetCardIcon/hotelIconUtils";
import { selectCurrentStoreTimeZone } from "@/core/selectors/persistentSelectors";
import { getCardFields } from "@/dux/_components/petCard/HotelPetCardUtils";

const mapStateToProps = (state, { appointment }) => {
  const storeTimeZone = selectCurrentStoreTimeZone(state);
  const { cardInfo, iconInfo } = getCardFields(appointment, storeTimeZone);

  return {
    isHidden: false,
    backgroundColor: reservationNeedsReviewBgColor(appointment),
    componentID: "HotelPetCardComponent__Departures",
    iconInfo,
    cardInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  setIds: ({ customerId, itineraryId, petId }) => {
    dispatch(selectPet(petId));
    dispatch(setPetId(petId));
    history.push(`${routePaths.CHECK_OUT}/${customerId}/${itineraryId}`);
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () => {
    const { customerId, itineraryId, petId } = ownProps?.appointment;
    const { setIds } = propsFromDispatch;

    setIds({ customerId, itineraryId, petId });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(HotelPetCardComponent);
