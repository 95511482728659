import React, { useEffect } from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import PetParentProfileHotelColumnContainer from "@/web/petParentProfile/PetParentProfileHotelColumnContainer";
import { createLoadingSelector } from "@/core/selectors/utils";
import customersActionTypes from "@/core/actionTypes/customersActionTypes";
import { loadCustomer } from "@/core/actionCreators/customersActionCreators";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { useParams } from "react-router-dom";

/**
 * React view component for pet parent column on DDC packages page
 * @memberOf Views.Purchase
 * @param {*} props
 * @returns {JSX.Element}
 */
export const DDCPackagesPetParentComponent = props => {
  const { componentId, isLoading, loadPetParent = () => {} } = props;
  const { customerKey } = useParams();

  useEffect(() => {
    if (customerKey) loadPetParent(customerKey);
  }, [customerKey]);

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LoadingWrapper isLoading={isLoading} displayContainer="block">
        <PetParentProfileHotelColumnContainer />
      </LoadingWrapper>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for pet parent column on DDC packages page
 * @memberOf Views.Purchase
 * @summary path: /purchase/:customerKey/ddc-packages
 * @function
 * @name DDCPackagesPetParentColumn
 * @example <DDCPackagesPetParentColumn />
 */
export const DDCPackagesPetParentColumn = connect(
  state => {
    return {
      componentId: "DDCPackagesPetParentColumn",
      isLoading: createLoadingSelector([
        customersActionTypes.LOAD_CUSTOMER,
        customersActionTypes.UPDATE_CUSTOMER,
      ])(state),
    };
  },
  dispatch => {
    return {
      loadPetParent: customerKey => {
        dispatch(loadCustomer({ customerKey }));
      },
    };
  },
)(DDCPackagesPetParentComponent);
