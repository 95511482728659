import React, { Component } from "react";
import styled from "styled-components";
import Input from "../common/Input";
import CloseIcon from "../../assets/icons/x.svg";

const InputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const CloseButton = styled.img`
  height: 10px;
  width: 10px;
  color: #bec0c4;
  padding-bottom: 0.35em;
  cursor: pointer;
`;

export default function SearchInput({ value, placeholder, clearText, onChange }) {
  return (
    <InputWrapper>
      <Input
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        placeholderTextSize="0.9em"
      />
      <CloseButton src={CloseIcon} onClick={clearText} />
    </InputWrapper>
  );
}
