import momentTz from "moment-timezone";

/**
 * Check if an array of dates includes a date with the same day as the given date
 * @param {Array} dates - array of date strings or moment objects
 * @param {string|moment} dateToFind - date to find
 * @param {string?} timeZone - defaults to "UTC"
 * @returns Boolean - true if date is in array, false if not
 */
export const checkFrequencyHasDay = (dates = [], dateToFind, timeZone = "UTC") =>
  dates?.some(date =>
    momentTz.tz(date, timeZone).isSame(momentTz.tz(dateToFind, timeZone).utc(), "day"),
  );
