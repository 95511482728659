import { connect } from "react-redux";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import PetParentComponent from "./petParent/PetParentComponent";
import { getCustomer, getIsCustomerActive } from "core/selectors/entitiesSelector";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import getIsHotelWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);

  return {
    isHidden: getIsHotelWorkflowFeatureFlagHidden(state),
    componentID: "petParentProfileHotelColumn-PetParentComponent",
    isLoading: createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state),
    isCustomerInStore: !!getCustomer(state, { customerKey }),
    isCustomerActive: getIsCustomerActive(state, { customerKey }),
    customerKey: getCurrentCustomerKey(state),
    colTitle: `Pets${systemName.HOTEL} Pet parent profile details`,
    gridArea: "pet-parent-form",
    systemName: systemName.HOTEL,
  };
};

export default connect(mapStateToProps)(PetParentComponent);
