import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { searchConstants } from "../../../core/constants/searchConstants";
import CardListWrapperContainer from "../../../web/upcoming_here/cardListWrapper/cardListWrapperContainer";
import Gantt from "../../../web/gantt/GanttContainer";
import DashboardModals from "../../../web/dashboard/modals/dashboardModalsContainer";
import DateSelectionWrapper from "../../../web/common/dateSelection/DateSelectionWrapper";
import DashboardCalendarContainer from "../../../web/dashboard/DashboardCalendarContainer";
import DashboardAssociateDropDownContainer from "../../../web/dashboard/DashboardAssociateDropDownContainer";
import { createLoadingSelector } from "@/core/selectors/utils";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import itinerariesActionTypes from "core/actionTypes/itinerariesActionTypes";
import associateActionTypes from "core/actionTypes/associateActionTypes";
import { selectAssociateWeekly } from "core/actionCreators/ui/web/commonActionCreators";
import { getDashboardAvailableAppointmentsWeekly } from "core/selectors/ganttSelectors";
import { getSelectedAssociateWeekly } from "core/selectors/commonSelector";
import { clearNewAppointmentData } from "core/actionCreators/ui/web/dashboardActionCreators";
import { setSearchComponentName } from "core/actionCreators/searchSalonActionCreator";
import { getDashboardAvailableAppointmentsByDateAndAssociate } from "core/selectors/schedulesSelectors";
import { getDashBoardModalType } from "core/selectors/modals/dashboardModalSelectors";
import loadItinerariesHelper from "web/_helpers/itineraries/loadItinerariesHelper";
import { clearCurrentCustomerKey } from "core/actionCreators/customer/customerActionCreator";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { loadAssociates } from "core/actionCreators/associateActionCreator";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";

export const DashboardSalonComponent = ({
  componentID,
  isHidden,
  modalType,
  availableAppointments,
  selectedAssociateWeekly,
  clearNewAppointmentData = () => {},
  clearCurrentCustomerKey = () => {},
  setSearchComponentName = () => {},
  selectAssociateWeekly = () => {},
  loadAPIs = () => {},
}) => {
  // Mount
  useEffect(() => {
    if (!isHidden) {
      clearNewAppointmentData();
      clearCurrentCustomerKey();
      loadAPIs();
      setSearchComponentName(searchConstants.CUSTOMER);
    }
  }, [isHidden]);

  // Unmount
  useEffect(() => {
    if (!isHidden)
      return () => {
        selectAssociateWeekly(null);
      };
  }, [isHidden]);

  if (isHidden) return null;

  return (
    <LayoutBox id={componentID} padding="scale-0" style={{ width: "100%" }}>
      <LayoutCluster space="scale-0" style={{ flex: 1, alignItems: "normal" }}>
        <LayoutStack space="scale-0" style={{ flex: 1 }}>
          <DateSelectionWrapper>
            <DashboardCalendarContainer />
            <DashboardAssociateDropDownContainer />
          </DateSelectionWrapper>
          <Gantt
            availableAppointments={availableAppointments}
            isWeekly={!!selectedAssociateWeekly}
            shouldLoadPets
          />
        </LayoutStack>
        <LayoutBox padding="scale-0" style={{ flex: 1 }}>
          <CardListWrapperContainer />
        </LayoutBox>
      </LayoutCluster>

      {modalType && <DashboardModals modalType={modalType} />}
    </LayoutBox>
  );
};

export const DashboardSalonContainer = connect(
  state => {
    const selectedDate = getSelectedDate(state);
    const selectedAssociateWeekly = getSelectedAssociateWeekly(state);
    const bookingFlow = getSystemBookingFlow(state);

    return {
      isHidden: bookingFlow !== systemName.SALON,
      componentID: "dashboard__Salon",
      isLoading: createLoadingSelector([
        itinerariesActionTypes.LOAD_ITINERARIES,
        associateActionTypes.LOAD_ASSOCIATES,
      ])(state),
      selectedAssociateWeekly,
      systemBookingFlow: getSystemBookingFlow(state),
      modalType: getDashBoardModalType(state),
      availableAppointments: selectedAssociateWeekly
        ? getDashboardAvailableAppointmentsWeekly(state)
        : getDashboardAvailableAppointmentsByDateAndAssociate(state, { selectedDate }),
      selectedDate,
    };
  },
  dispatch => ({
    selectAssociateWeekly: associateId => {
      dispatch(selectAssociateWeekly({ associateId }));
    },
    loadAPIs: (selectedDate = moment()) => {
      // Abstract out dispatching load itineraries since it is used in multiple containers.
      loadItinerariesHelper(dispatch, selectedDate, false);
      dispatch(loadAssociates({ isLimited: true }));
    },
    clearCurrentCustomerKey: () => dispatch(clearCurrentCustomerKey()),
    clearNewAppointmentData: () => dispatch(clearNewAppointmentData()),
    setSearchComponentName: searchComponentName =>
      dispatch(setSearchComponentName({ searchComponentName })),
  }),
)(DashboardSalonComponent);
