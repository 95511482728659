import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import {
  setAuthenticatedWithQuickPin,
  clearAuthenticatedWithQuickPin,
} from "../../../../core/actionCreators/authActionCreators";
import { getAuthenticatedWithQuickPin } from "../../../../core/selectors/authSelectors";
import {
  getSessionToken,
  getAccessToken,
} from "../../../../core/selectors/persistentSelectors";
import { changeLocation } from "../../../../core/actionCreators/navigationActionCreators";
import { isAuthRoute } from "../../../../core/utils/authHelper";
import { routePaths } from "../../../../core/constants/routePaths";

import DelayedLoader from "../../DelayedLoader";
import AuthLoading from "../../AuthLoading";

const CheckSession = ({
  isAuthenticatedWithQuickPin,
  accessToken,
  sessionToken,
  setAuthenticatedWithQuickPin,
  clearAuthenticatedWithQuickPin,
  changeLocation,
  children,
}) => {
  const location = useLocation();

  useEffect(() => {
    const isTokenInvalid = !sessionToken;
    const pathname = location?.pathname;

    if (!isAuthRoute(pathname)) {
      changeLocation(pathname);
    }

    if (isTokenInvalid) {
      clearAuthenticatedWithQuickPin();
      return;
    }

    setAuthenticatedWithQuickPin();
  }, []);

  if (!accessToken) {
    return <Navigate replace to={routePaths.LOGIN} />;
  }

  if (isAuthenticatedWithQuickPin === null) {
    return (
      <DelayedLoader
        delay={250}
        backgroundColor="rgba(0, 0, 0, 0.8)"
        render={() => <AuthLoading />}
      />
    );
  }

  if (!isAuthenticatedWithQuickPin) {
    return <Navigate replace to={routePaths.SELECT_USER} />;
  }

  return <>{children}</>;
};

const mapStateToProps = state => ({
  sessionToken: getSessionToken(state),
  isAuthenticatedWithQuickPin: getAuthenticatedWithQuickPin(state),
  accessToken: getAccessToken(state),
});

const mapDispatchToProps = dispatch => ({
  setAuthenticatedWithQuickPin: () => dispatch(setAuthenticatedWithQuickPin()),
  clearAuthenticatedWithQuickPin: () => dispatch(clearAuthenticatedWithQuickPin()),
  changeLocation: pathname => dispatch(changeLocation(pathname)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckSession);
