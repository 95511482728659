import { connect } from "react-redux";

// Components
import MenuButton from "@/web/common/MenuButton";

// Selectors
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { getStoreNumber } from "core/selectors/persistentSelectors";

// Feature Flag
import getReportsWorkflowFeatureFlag from "@/web/enableDisableWorkflowFeatureFlag/selectors/reports/getReportsWorkflowFeatureFlag";

// Utils
import { formatCalendarDateMoment } from "@/core/utils/dateUtils/formatDateTime";

// Constants
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = state => {
  const systemBookingFlow = getSystemBookingFlow(state);
  const storeNumber = getStoreNumber(state);
  const date = formatCalendarDateMoment();
  const isReportsFeatureFlagOn = getReportsWorkflowFeatureFlag(state)?.isHidden;

  return {
    date,
    isHidden: !(isReportsFeatureFlagOn && systemBookingFlow === systemName.HOTEL),
    storeNumber,
    title: "Food, Med & Activity Reports",
  };
};

const mapDispatchToProps = () => ({
  handleOnClick: (storeNumber, date) => {
    // These endpoints are hardcoded temporarily to get a quick workaround for reporting issue
    // These will be moved to their own PetsAdmin UI pages in future
    const env = window.env.SF_SANDBOX_PARTIAL;
    const baseUrl = `https://prism-petsmart`;

    const foodRecordsUrl = `${baseUrl}${env}.com/food?storeNumber=${storeNumber}&date=${date}`;
    const medRecordsUrl = `${baseUrl}${env}.com/medication?storeNumber=${storeNumber}&date=${date}`;
    const addOnActivityUrl = `${baseUrl}${env}.com/addOnActivity?storeNumber=${storeNumber}&date=${date}`;

    window.open(foodRecordsUrl, "_blank");
    window.open(medRecordsUrl, "_blank");
    window.open(addOnActivityUrl, "_blank");
  },
});

const mergeProps = (stateProps, dispatchProps) => {
  const { isHidden, storeNumber, date, title } = stateProps;
  const { handleOnClick } = dispatchProps;

  return {
    isHidden,
    onClick: () => handleOnClick(storeNumber, date),
    title,
  };
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MenuButton);
