import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, LoadingIndicator } from "@petsmart-ui/sparky";
import { Layout } from "@prism/psm-ui-components";
import { HotelBookingAlert } from "@/web/hotelAlerts/HotelAlertComponent";
import {
  selectCommonHotelBookingStepData,
  selectHotelBookingPetList,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import { addCartForPet } from "dux/petCartCard/actions/setPetCartCardActions";
import { commonHotelBookingStepActions } from "@/web/features/hotelBookingFlow/hotelBookingFlowUtils";
import { POST_CART, postCart, rebookingPostCart } from "dux/servicesCart/servicesCartActions";
import { selectCartPostObject, selectServicesCart } from "dux/servicesCart/servicesCartSelectors";
import {
  GET_HOTEL_ELIGIBILITY,
  POST_HOTEL_ELIGIBILITY,
} from "../hotelEligibility/hotelEligibilityActions";
import { getShouldShowHotelBookingAlertsForMultiPets } from "../hotelEligibility/hotelEligibilitySelectors";
import { selectIsRebookingItinerary } from "../hotelItinerary/hotelItinerarySelectors";
import { compose } from "redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const HotelBookingServiceSectionAlertsComponent = ({
  componentID,
  continueBooking,
  addCarts,
  isHidden,
  isActive,
  selectedPetIds,
  hasAlerts,
  isLoading,
  cartId,
}) => {
  useEffect(() => {
    // If there aren't any alerts then skip past this section and continue on
    if (!hasAlerts && isActive && !isLoading) {
      addCarts();
    }
  }, [isActive, hasAlerts, isLoading]);

  useEffect(() => {
    // If we have a cartId (POST call was successful) then continue with booking flow.
    if (cartId && isActive && !isLoading) {
      continueBooking();
    }
  }, [cartId]);

  if (isLoading) {
    return (
      <Layout.Cluster justify="center">
        <LoadingIndicator iconSize="md" />
      </Layout.Cluster>
    );
  }

  return (
    <Layout.Box id={componentID} isHidden={isHidden}>
      {/* Pet Parent Alerts */}
      <HotelBookingAlert />

      {/* Pet Alerts */}
      {selectedPetIds?.map(petId => (
        <HotelBookingAlert isPet petId={petId} key={petId} />
      ))}

      <Layout.Box style={{ marginTop: "2rem" }}>
        <ButtonGroup align="right">
          <Button
            variant="primary"
            onClick={() => addCarts()}
            text="Acknowledge"
            disabled={isLoading}
          />
        </ButtonGroup>
      </Layout.Box>
    </Layout.Box>
  );
};

export const HotelBookingServiceSectionAlerts = compose(
  withRouteProps,
  connect(
    (state, { stepIndex, router }) => {
      const customerKey = getCurrentCustomerKey(state);
      const cartId = selectServicesCart(state)?.servicesCartId;
      const pets = selectHotelBookingPetList(state);
      const petId = pets[0]; // Only 1 pet in a single pet booking, so grab first array element
      const selectedPetIds = selectHotelBookingPetList(state);
      const hasAlerts = getShouldShowHotelBookingAlertsForMultiPets(
        state,
        customerKey,
        selectedPetIds,
      );
      const isLoading = createLoadingSelector([
        GET_HOTEL_ELIGIBILITY,
        POST_CART,
        POST_HOTEL_ELIGIBILITY,
      ])(state);
      const { isActive, openStep, furthestStep } = selectCommonHotelBookingStepData(stepIndex)(
        state,
      );

      // Rebooking data
      const itineraryId = router?.params?.itineraryId;
      const isRebooking = selectIsRebookingItinerary(state, { itineraryId });

      return {
        componentID: "hotelBookingFlow-serviceSection_alerts",
        isActive,
        openStep,
        furthestStep,
        selectedPetIds,
        hasAlerts,
        isLoading,
        petId,
        pets,
        cartId,
        title: "Select a Service",
        pendingCart: selectCartPostObject(state),
        isRebooking,
      };
    },
    dispatch => {
      const { onContinue } = commonHotelBookingStepActions(dispatch);
      return {
        addPetCarts: ({ petIds }) => {
          petIds.forEach(petId => dispatch(addCartForPet({ petId })));
        },
        onContinue,
        onPostCart: cart => dispatch(postCart(cart)),
        onPostRebookingCart: () => dispatch(rebookingPostCart()),
      };
    },
    (mapProps, mapDispatchToProps) => {
      const {
        componentID,
        isActive,
        openStep,
        furthestStep,
        selectedPetIds,
        isLoading,
        hasAlerts,
        isEditable,
        pets,
        cartId,
        pendingCart,
        isRebooking,
      } = mapProps;

      const { addPetCarts, onContinue, onPostCart, onPostRebookingCart } = mapDispatchToProps;

      return {
        // control what props get passed to the view
        componentID,
        isEditable,
        isActive,
        selectedPetIds,
        isLoading,
        hasAlerts,
        cartId,
        // If this section was reopened after we already made a cart post, then just continue
        addCarts: () => {
          if (!!cartId) {
            onContinue({ openStep, furthestStep });
            return;
          }
          isRebooking ? onPostRebookingCart() : onPostCart(pendingCart);
        },
        continueBooking: () => {
          onContinue({ openStep, furthestStep });
          addPetCarts({ petIds: pets });
        },
      };
    },
  ),
)(HotelBookingServiceSectionAlertsComponent);
