import { put, takeEvery, call, all } from "redux-saga/effects";

// Actions
import {
  GET_AVAILABLE_REPORTS,
  getAvailableReportsRequest,
  getAvailableReportsSuccess,
  getAvailableReportsFailure,
} from "./reportSelectionActions";

// Endpoints
import { getAvailableReportsEndpoint } from "@/core/services/associateWebBff/reportsEndpoints";

/**
 * Generator function to fetch available reports
 * @memberOf Sagas.Reports
 * @generator
 * @name onGetAvailableReports
 * @returns {Generator<*, void, *>}
 */
function* onGetAvailableReports() {
  try {
    yield put(getAvailableReportsRequest());

    const response = yield call(getAvailableReportsEndpoint);

    yield put(getAvailableReportsSuccess(response?.data?.results));
  } catch (error) {
    yield put(getAvailableReportsFailure(error));
  }
}

function* watchOnGetAvailableReports() {
  yield takeEvery(GET_AVAILABLE_REPORTS, onGetAvailableReports);
}

export default function* reportSelectionSaga() {
  yield all([watchOnGetAvailableReports()]);
}
