import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get a list of the values available for service status.
 * These are required to allow an associate to select the
 * new status when updating an itinerary, engagement or pet service.
 * From System Services Booking Api - Get list of service status items
 * @returns {*} AxiosPromise
 */
export function fetchStatuses() {
  return bookingAPI.get(`${endpointVersionString()}/settings/servicestatuses`);
}
