import { connect } from "react-redux";
import { get } from "lodash/fp";
import { getSelectedAppointment } from "../../../../core/selectors/commonSelector";
import { getPetServiceItem } from "../../../../core/selectors/entitiesSelector";
import formatPhoneNumberNANP from "../../../../core/utils/stringManipulationUtils/formatPhoneNumber";
import CustomerPhoneComponent from "../../../common/phone/CustomerPhoneComponent";

const mapStateToProps = (state, ownProps) => {
  const selectedAppointmentId = getSelectedAppointment(state);
  const selectedAppointment = getPetServiceItem(state, { petServiceItemId: selectedAppointmentId });
  const phoneString = get("customerPhone", selectedAppointment);

  return {
    isHidden: ownProps.isHidden,
    customerPhone: formatPhoneNumberNANP(phoneString)
  };
};

export default connect(mapStateToProps)(CustomerPhoneComponent);
