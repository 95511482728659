import { connect } from "react-redux";
import moment from "moment";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import DaySelection from "./DaySelection";
import uniformTimeFormat from "../../core/utils/dateUtils/uniformTimeFormat";
import { createLoadingSelector } from "@/core/selectors/utils";
import bookingActionTypes from "@/core/actionTypes/bookingActionTypes";

const mapStateToProps = (state, ownProps) => {
  const uniformDates = uniformTimeFormat([ownProps.day, getSelectedDate(state)], "YYYY-MM-DD");
  return {
    componentId: "salonDaySelection",
    isSelected: moment(uniformDates.date_0).isSame(uniformDates.date_1),
    formatedDate: moment(ownProps.day).format("ddd DD"),
    isUpperCase: true,
    isLoading: createLoadingSelector([bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS, bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT])(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchClickHandler: ({ selectedDate }) => {
    dispatch(setSelectDate(selectedDate));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  clickHandler: () => {
    if (propsFromState.isLoading) {
      return false;
    }
    propsFromDispatch.dispatchClickHandler({
      selectedDate: ownProps.day,
    })
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DaySelection);