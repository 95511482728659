import { connect } from "react-redux";
import Arrow from "web/common/Arrow";
import { getIsPetCardShowing } from "dux/petCartCard/selectors/petCartCardSelectors";
import { showHidePetCard } from "dux/petCartCard/actions/setPetCartCardActions";

const mapStateToProps = (state, ownProps) => {
  const { petId } = ownProps;
  const isPetCardShowing = getIsPetCardShowing(state, { petId });
  return {
    petId,
    isPetCardShowing,
    direction: isPetCardShowing ? "down" : "right",
  };
};

const mapDispatchToProps = dispatch => ({
  showHideDropdown: (petId, isPetCardShowing) =>
    dispatch(showHidePetCard({ petId, isPetCardShowing })),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  clickHandler: () => {
    const { petId, isPetCardShowing } = propsFromState;
    propsFromDispatch.showHideDropdown(petId, !isPetCardShowing);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Arrow);
