import React from "react";
import styled from "styled-components";
import LogoImage from "../../assets/images/PRISM-logo.png";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const PrismLogo = styled.img`
  width: 158px;
  height: 66px;
`;

export default function Logo() {
  return (
    <SectionWrapper>
      <PrismLogo src={LogoImage} />
    </SectionWrapper>
  );
}
