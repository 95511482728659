import { connect } from "react-redux";
import {
  getPetParentAgents,
  getIsAddAgentFormShown,
  getIsAddAgentButtonShown
} from "../../../../core/selectors/agentsSelectors";
import agentsActionCreators from "../../../../core/actionCreators/agentsActionCreators";
import PetParentAgentFormWrapperComponent from "./PetParentAgentFormWrapperComponent";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = (state, ownProps) => ({
  isHidden: getSystemBookingFlow(state) !== systemName.SALON,
  componentId: "PetParent__AgentForm--Salon",
  petParentAgents: getPetParentAgents(state, ownProps),
  isAddAgentFormShown: getIsAddAgentFormShown(state),
  isAddAgentButtonShown: getIsAddAgentButtonShown(state, ownProps)
});

const mapDispatchToProps = dispatch => ({
  showAddAgentForm: () => dispatch(agentsActionCreators.showAddAgentForm())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PetParentAgentFormWrapperComponent);
