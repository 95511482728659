import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import { flatten, isEmpty, isNil, has, get } from "lodash/fp";
import customersActionTypes from "../../actionTypes/customersActionTypes";
import customersActionCreators from "../../actionCreators/customersActionCreators";
import {
  getCustomerProfileFieldsToUpdate,
  getIsPhonePreferred,
  getIsTextOptedOut,
  buildPhoneChannelPreferences,
} from "core/utils/customerProfile";
import { getPhonesByCustomer, getEmailsByCustomer } from "core/selectors/entitiesSelector";
import {
  getPetParentProfileFormData,
  getPhoneToDisplay,
} from "core/selectors/petParentProfileSelectors";
import {
  customerEmailTypes,
  customerPhoneTypes,
  channelTypes,
  communicationTypes,
} from "core/constants/customerProfileConstants";
import { onUpdateCustomer } from "../customersSaga";
import {
  onCreateCustomerPreferences,
  onUpdateCustomerPreferences,
} from "./customerPreferencesSaga";
import { chooseCreateOrUpdateEmail, chooseCreateOrUpdatePhone } from "./shared/utils";
import normalizeArrayByProperty from "../../utils/normalizeUtils/normalizeArray";
import { onUpdateCustomerEmailOptedOut } from "./customerEmailSaga";
import { updatePrismCustomerProfile } from "@/web/updatePrismCustomerProfile/updatePrismCustomerProfile";
import compareStrings from "@/core/utils/stringUtils/compareStrings";

function* onUpdateCustomerProfile({ customerKey, formValues, isFormFieldHidden }) {
  try {
    yield put(customersActionCreators.updateCustomerProfileRequest());

    // Get the current related form data (e.g Name, email, phone numbers etc.) from the entities.customer state object via a selector
    const previousFormValues = yield select(getPetParentProfileFormData, { customerKey });

    /* * ----------------------------------------------------------------------- * *\
      Since Updating Customer name, address email, phones, do not book, VIP etc.
      may trigger multiple api calls, we need to find out what fields need to actually
      be updated then we destructure out the accumulated
      "Grouped" form fields that will need to be updated via separate APIS.
    \* * ----------------------------------------------------------------------- * */
    const {
      customerInfoFieldsToUpdate,
      phoneFieldsToUpdate,
      emailFieldsToUpdate,
      addressFieldsToUpdate,
      profileFieldsToUpdate,
    } = getCustomerProfileFieldsToUpdate(previousFormValues, formValues, isFormFieldHidden);
    const [customerPhonesFromProfile, customerEmailsFromProfile] = yield all([
      yield select(getPhonesByCustomer, { customerKey }),
      yield select(getEmailsByCustomer, { customerKey }),
    ]);

    /* * ----------------------------------------------------------------------- * *\
      Once the particular form fields have been checked to see if they were updated
      they were "grouped" and we are now just checking if they are empty to inform
      some conditionals.
    \* * ----------------------------------------------------------------------- * */
    const shouldUpdateCustomerInfo = !isEmpty(customerInfoFieldsToUpdate);
    const shouldUpdateCustomerEmail = !isEmpty(emailFieldsToUpdate) && !formValues.isEmailDeclined;
    const shouldUpdateCustomerPhone = !isEmpty(phoneFieldsToUpdate);
    const shouldUpdateCustomerAddress = !isEmpty(addressFieldsToUpdate);
    const shouldUpdateCustomerProfile = !isEmpty(profileFieldsToUpdate);
    const shouldUpdateEmailOptedOut = !isNil(formValues.isEmailOptedOut);
    const updateProfileApiCalls = [];

    /* * ----------------------------------------------------------------------- * *\
      If it is determined one ore more of the destructured groups of fields have
      updated values e.g. not empty, the api call will be added to the
      updateProfileApiCalls saga call array so all calls can be fired in
      parallel
    \* * ----------------------------------------------------------------------- * */
    if (shouldUpdateCustomerInfo) {
      const updateCustomerInfo = call(onUpdateCustomer, {
        customerKey,
        data: {
          ...customerInfoFieldsToUpdate,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
        },
      });
      updateProfileApiCalls.push(updateCustomerInfo);
    }

    if (shouldUpdateCustomerEmail) {
      const currentEmailFromProfile =
        customerEmailsFromProfile && customerEmailsFromProfile.find(email => email.isActive);

      Object.values(emailFieldsToUpdate).forEach(emailFromForm =>
        updateProfileApiCalls.push(
          call(chooseCreateOrUpdateEmail, {
            emailFromProfile: currentEmailFromProfile,
            customerKey,
            emailFromForm,
            emailType: customerEmailTypes.HOME,
          }),
        ),
      );
    }

    if (shouldUpdateEmailOptedOut) {
      const updateCustomerEmailOptedOut = call(onUpdateCustomerEmailOptedOut, {
        customerKey,
        isEmailOptedOut: formValues.isEmailOptedOut ? 1 : 0, // Send as 0 or 1 until API is changed.
      });
      updateProfileApiCalls.push(updateCustomerEmailOptedOut);
    }

    if (shouldUpdateCustomerPhone) {
      // Update customer phone type/phone number
      const mobilePhoneFromProfile = getPhoneToDisplay({
        phones: customerPhonesFromProfile,
        type: customerPhoneTypes.MOBILE,
      });
      const homePhoneFromProfile = getPhoneToDisplay({
        phones: customerPhonesFromProfile,
        type: customerPhoneTypes.HOME,
      });
      const workPhoneFromProfile = getPhoneToDisplay({
        phones: customerPhonesFromProfile,
        type: customerPhoneTypes.WORK,
      });

      Object.entries(phoneFieldsToUpdate).forEach(([key, phoneNumberFromForm]) => {
        if (key.includes("mobile")) {
          updateProfileApiCalls.push(
            call(chooseCreateOrUpdatePhone, {
              phoneFromProfile: mobilePhoneFromProfile,
              customerKey,
              phoneNumberFromForm,
              phoneType: customerPhoneTypes.MOBILE,
            }),
          );
        }
        if (key.includes("home")) {
          updateProfileApiCalls.push(
            call(chooseCreateOrUpdatePhone, {
              phoneFromProfile: homePhoneFromProfile,
              customerKey,
              phoneNumberFromForm,
              phoneType: customerPhoneTypes.HOME,
            }),
          );
        }
        if (key.includes("work")) {
          updateProfileApiCalls.push(
            call(chooseCreateOrUpdatePhone, {
              phoneFromProfile: workPhoneFromProfile,
              customerKey,
              phoneNumberFromForm,
              phoneType: customerPhoneTypes.WORK,
            }),
          );
        }
      });
    }

    /* * ----------------------------------------------------------------------- * *\
      only runs code block if any profile field is not empty, e.g. has a VIP value
    \* * ----------------------------------------------------------------------- * */
    if (shouldUpdateCustomerProfile) {
      const { isVip, psaOnFile } = profileFieldsToUpdate;
      updateProfileApiCalls.push(
        call(updatePrismCustomerProfile, {
          customerKey,
          isVip,
          psaOnFile,
        }),
      );
    }

    /* * ----------------------------------------------------------------------- * *\
      Run all the apis that were added to the updateProfileApiCalls array, in
      parallel.
    \* * ----------------------------------------------------------------------- * */
    yield all(flatten(updateProfileApiCalls));

    yield setCustomerPreferences({
      customerPhonesFromProfile,
      customerEmailsFromProfile,
      shouldUpdateCustomerPhone,
      shouldUpdateCustomerEmail,
      customerKey,
    });

    yield put(customersActionCreators.updateCustomerProfileSuccess());
  } catch (error) {
    yield put(customersActionCreators.updateCustomerProfileError({ error }));
  }
}

function* setCustomerPreferences({
  customerPhonesFromProfile,
  customerEmailsFromProfile,
  shouldUpdateCustomerPhone,
  shouldUpdateCustomerEmail,
  customerKey,
}) {
  const [updatedCustomerPhonesFromProfile, updatedCustomerEmailsFromProfile] = yield all([
    yield select(getPhonesByCustomer, { customerKey }),
    yield select(getEmailsByCustomer, { customerKey }),
  ]);

  const previousPhonesFromProfile = normalizeArrayByProperty(customerPhonesFromProfile, "phoneId");
  const previousEmailsFromProfile = normalizeArrayByProperty(
    customerEmailsFromProfile,
    "emailAddressId",
  );

  const createCustomerPreferencesRequestPayload = [];
  const updateCustomerPreferencesRequestPayload = [];

  if (shouldUpdateCustomerPhone && updatedCustomerPhonesFromProfile.length) {
    const phonePreferencesToCreate = [];
    const phonePreferencesToUpdate = [];

    updatedCustomerPhonesFromProfile.forEach(phone => {
      const shouldUpdatePhonePreference =
        has(phone.phoneId, previousPhonesFromProfile) &&
        !isEmpty(get("communicationPreferences", phone));

      if (shouldUpdatePhonePreference) {
        phonePreferencesToUpdate.push(
          buildPhoneChannelPreferences({
            contactId: phone.phoneId,
            isOptedOut: getIsTextOptedOut(phone),
            isPreferred: getIsPhonePreferred(phone),
          }),
        );
      } else {
        // Add contact preferences for phones. With Express Consent, mobile numbers
        // need to be automatically opted-in when created in the system
        const isMobile = compareStrings(phone.phoneType, customerPhoneTypes.MOBILE);
        phonePreferencesToCreate.push(
          buildPhoneChannelPreferences({
            contactId: phone.phoneId,
            isOptedOut: !isMobile,
            isPreferred: false,
          }),
        );
      }
    });

    if (phonePreferencesToCreate.length) {
      createCustomerPreferencesRequestPayload.push({
        communicationTypeId: communicationTypes.TEXT,
        contacts: phonePreferencesToCreate,
      });
    }
    if (phonePreferencesToUpdate.length) {
      updateCustomerPreferencesRequestPayload.push({
        communicationTypeId: communicationTypes.TEXT,
        contacts: phonePreferencesToUpdate,
      });
    }
  }

  if (shouldUpdateCustomerEmail && updatedCustomerEmailsFromProfile.length) {
    const emailPreferencesToCreate = [];
    const emailPreferencesToUpdate = [];

    updatedCustomerEmailsFromProfile.forEach(email => {
      const payload = {
        contactId: email.emailAddressId,
        channels: [
          {
            channelId: channelTypes.MARKETING,
            isOptedOut: true,
            isPreferred: true,
          },
        ],
      };

      const shouldUpdateEmailPreference =
        has(email.emailAddressId, previousEmailsFromProfile) &&
        !isEmpty(get("communicationPreferences", email));

      if (shouldUpdateEmailPreference) {
        emailPreferencesToUpdate.push(payload);
      } else {
        emailPreferencesToCreate.push(payload);
      }
    });

    if (emailPreferencesToCreate.length) {
      createCustomerPreferencesRequestPayload.push({
        communicationTypeId: communicationTypes.EMAIL,
        contacts: emailPreferencesToCreate,
      });
    }
    if (emailPreferencesToUpdate.length) {
      updateCustomerPreferencesRequestPayload.push({
        communicationTypeId: communicationTypes.EMAIL,
        contacts: emailPreferencesToUpdate,
      });
    }
  }

  if (createCustomerPreferencesRequestPayload.length) {
    yield call(onCreateCustomerPreferences, {
      customerKey,
      data: createCustomerPreferencesRequestPayload,
    });
  }
  if (updateCustomerPreferencesRequestPayload.length) {
    yield call(onUpdateCustomerPreferences, {
      customerKey,
      data: updateCustomerPreferencesRequestPayload,
    });
  }
}

function* watchUpdateCustomerProfile() {
  yield takeEvery(customersActionTypes.UPDATE_CUSTOMER_PROFILE, onUpdateCustomerProfile);
}

export default function* updateCustomerProfileSaga() {
  yield all([fork(watchUpdateCustomerProfile)]);
}
