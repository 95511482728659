// @ts-check

/**
 * Success action type that adds pagination for scheduled suspensions into the redux state
 * @type {string}
 */
export const SET_SCHEDULE_SUSPENSION_PAGINATION_SUCCESS =
  "GET_SCHEDULE_SUSPENSION_PAGINATION_SUCCESS";

/**
 * Success action that adds pagination for scheduled suspensions into the redux state
 * @param {object} pagination - Destructed Props
 * @param {number} pagination.count - Number of items currently displayed
 * @param {number} pagination.page - Current Page
 * @param {number} pagination.total - Total number items available
 * @param {number} pagination.size - Total size of items that can currently be displayed
 * @returns {{total: number, size: number, count: number, page: number, type: string}}
 */
export const setScheduleSuspensionPaginationSuccess = ({ count, page, total, size }) => ({
  type: SET_SCHEDULE_SUSPENSION_PAGINATION_SUCCESS,
  count,
  page,
  total,
  size,
});
