import moment from "moment";

/**
 * @export isLate
 * @param {*} startDate
 * @returns
 */
export default function isLate(startDate) {
  return moment().diff(startDate, "minutes") > 0;
}
