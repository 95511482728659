import React from "react";

import {
  ModalCopy,
  ModalFooter,
  ModalHeader,
  SectionWrapper,
} from "web/petCheckInOut/modals/checkInOutModalsLayout";
import { PackageOfferDetails } from "@/dux/bgm/ftco/PackageOfferDetails";
import CheckInOutButtonMultiPetContainer from "web/petCheckInOut/modals/content/CheckInOutButtonMultiPetContainer";
import PetImagesContainer from "dux/petImages/PetImagesContainer";

function CheckOutMultiModalContent({ isHidden, petsPaid, headerText, message }) {
  if (!isHidden) {
    return (
      <SectionWrapper>
        <ModalHeader>{headerText}</ModalHeader>

        {!petsPaid && <ModalCopy data-testid="CheckOutModalContent__Message">{message}</ModalCopy>}

        <PetImagesContainer />

        <ModalFooter>
          <PackageOfferDetails />
          <CheckInOutButtonMultiPetContainer />
        </ModalFooter>
      </SectionWrapper>
    );
  }
  return null;
}

export default CheckOutMultiModalContent;
