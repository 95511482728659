import { getState } from "@/core/selectors/commonSelector";
import { createSelector } from "reselect";

/**
 * Selector to get overbooking exception state
 * @memberOf Selectors.overbookingExceptions
 * @function
 * @name selectOverBookingException
 * @param {Object} state
 * @returns {Object}
 * @example selectOverBookingException(state)
 */
export const selectOverBookingException = createSelector(
  [getState],
  state => state?.overBookingException ?? {},
);

/**
 * Selector to get overbooking exception percentage
 * @memberOf Selectors.overbookingExceptions
 * @function
 * @name selectOverBookingPercentage
 * @param {Object} state
 * @returns {Number}
 * @example selectOverBookingPercentage(state)
 */
export const selectOverBookingPercentage = createSelector(
  [selectOverBookingException],
  exception => exception?.overBookingPercentage,
);

/**
 * Selector to get overbooking exception start date
 * @memberOf Selectors.overbookingExceptions
 * @function
 * @name selectOverBookingExceptionStart
 * @param {Object} state
 * @returns {Object}
 * @example selectOverBookingExceptionStart(state)
 */
export const selectOverBookingExceptionStart = createSelector(
  [selectOverBookingException],
  exception => exception?.startDate,
);

/**
 * Selector to get overbooking exception end date
 * @memberOf Selectors.overbookingExceptions
 * @function
 * @name selectOverBookingExceptionEnd
 * @param {Object} state
 * @returns {Object}
 * @example selectOverBookingExceptionEnd(state)
 */
export const selectOverBookingExceptionEnd = createSelector(
  [selectOverBookingException],
  exception => exception?.endDate,
);

/**
 * Selector to get overbooking exception room type bucket number
 * @memberOf Selectors.overbookingExceptions
 * @function
 * @name selectOverBookingExceptionRoomType
 * @param {Object} state
 * @returns {Object}
 * @example selectOverBookingExceptionRoomType(state)
 */
export const selectOverBookingExceptionRoomType = createSelector(
  [selectOverBookingException],
  exception => exception?.roomTypeBucketNumber,
);

/**
 * Selector to get overbooking exception error
 * @memberOf Selectors.overbookingExceptions
 * @function
 * @name selectOverBookingExceptionError
 * @param {Object} state
 * @returns {Object}
 * @example selectOverBookingExceptionError(state)
 */
export const selectOverBookingExceptionError = createSelector(
  [selectOverBookingException],
  exception => exception?.error,
);
