import React, { useState } from "react";
import StyledPopover from "@/web/common/StyledPopover";

/**
 * Generic styled popover component.
 *
 * @param {Object} props - Component props.
 * @returns {React.ReactElement}
 */
export const GenericStyledPopOver = ({
  FlyoutContent,
  CTA,
  isHidden,
  place,
  ctaLabel,
  disabled,
  flyoutProps,
}) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  if (isHidden) {
    return null;
  }

  return (
    <div>
      <StyledPopover
        body={
          FlyoutContent ? (
            // onClose will be passed to whatever component/container that is the flyout content - see HotelCheckInApplySpecials as example
            <FlyoutContent onClose={() => setIsFlyoutOpen(false)} {...flyoutProps} />
          ) : (
            <div>Flyout content here</div>
          )
        }
        isOpen={isFlyoutOpen}
        preferPlace={place}
        onOuterAction={() => setIsFlyoutOpen(false)}
        tipSize={7}
      >
        <CTA
          label={ctaLabel}
          disabled={disabled}
          onClick={() => {
            setIsFlyoutOpen(true);
          }}
        />
      </StyledPopover>
    </div>
  );
};
