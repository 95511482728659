import React, { useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import DashboardCalendarHotelContainer from "web/dashboard/DashboardCalendarHotelContainer";
import DateSelectionWrapper from "../../../web/common/dateSelection/DateSelectionWrapper";
import RoomCapacityWrapper from "../roomCapacity/RoomCapacityWrapper";
import { HotelIconKey } from "dux/iconKey/iconKey";
import HotelRoomCapacityModalContainer from "dux/hotelRoomCapacityModal/HotelRoomCapacityModalContainer";
import { LayoutSwitcher } from "@/layout/switcher/Switcher";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import getIsHotelWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { getSelectedDate } from "@/dux/selectedDate/selectedDateSelector";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { loadHotelDailyDashboard } from "@/dux/dashboard/hotel/hotelDashboardActions";
import { clearCurrentCustomerKey } from "@/core/actionCreators/customer/customerActionCreator";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { getStoreInformation } from "@/core/selectors/entitiesSelector";
import { createLoadingSelector } from "@/core/selectors/utils";
import salonActionTypes from "@/core/actionTypes/salonActionTypes";
import { HotelDashboardAppointmentColumns } from "@/dux/hotelDashboardAppointmentColumns/HotelDashboardAppointmentColumns";

export const DashboardHotelComponent = ({
  isHidden,
  componentId,
  clearCurrentCustomerKey,
  loadAPIs,
  isStoreLoading,
}) => {
  useEffect(() => {
    // If the Hotel Dashboard does not load stop all lifeCycle code from executing.
    if (!isHidden) {
      clearCurrentCustomerKey();
      loadAPIs();
    }
  }, [isStoreLoading]);

  if (!isHidden) {
    return (
      <LayoutBox id={componentId} padding="scale-0">
        <LayoutSwitcher threshold="scale-G9" highlight={2} limit={2}>
          {/* LEFT SIDE OF DASHBOARD */}
          <LayoutStack>
            <DateSelectionWrapper>
              <DashboardCalendarHotelContainer />
            </DateSelectionWrapper>

            {/* ROOM CAPACITY */}
            <RoomCapacityWrapper />
            {/* ICON KEY */}
            <LayoutBox padding="scale-0">
              <HotelIconKey />
            </LayoutBox>
          </LayoutStack>

          {/* RIGHT SIDE OF DASHBOARD */}
          <HotelDashboardAppointmentColumns />
        </LayoutSwitcher>

        {/*HOTEL ROOM CAPACITY MODAL*/}
        <HotelRoomCapacityModalContainer />
      </LayoutBox>
    );
  }

  return null;
};

export const DashboardHotelContainer = connect(
  state => {
    const bookingFlow = getSystemBookingFlow(state);
    const storeId = getStoreNumber(state);

    return {
      componentId: "dashboard__Hotel",
      isHidden: bookingFlow !== systemName.HOTEL || getIsHotelWorkflowFeatureFlagHidden(state),
      selectedDate: getSelectedDate(state),
      storeId,
      isStoreLoaded: !!getStoreInformation(state, { storeNumber: storeId }),
      isStoreLoading: createLoadingSelector([salonActionTypes.LOAD_SALON_INFO_WITH_HOURS])(state),
    };
  },
  dispatch => ({
    dispatchLoadAPIs: ({ storeId, selectedDate = moment() }) => {
      dispatch(loadHotelDailyDashboard({ storeId, selectedDate }));
    },

    dispatchClearCurrentCustomerKey: () => dispatch(clearCurrentCustomerKey()),
  }),
  (propsFromState, propsFromDispatch) => {
    const {
      componentId,
      isHidden,
      selectedDate,
      storeId,
      isStoreLoaded,
      isStoreLoading,
    } = propsFromState;
    const { dispatchLoadAPIs, dispatchClearCurrentCustomerKey } = propsFromDispatch;

    return {
      isHidden,
      componentId,
      isStoreLoading,
      loadAPIs: () => isStoreLoaded && dispatchLoadAPIs({ storeId, selectedDate }),
      clearCurrentCustomerKey: () => dispatchClearCurrentCustomerKey(),
    };
  },
)(DashboardHotelComponent);
