import { connect } from "react-redux";
import AppHeader from "./AppHeader";
import { headerConfigConstants } from "../../core/constants/headerConfigConstants";
import { getJobRole } from "../../core/selectors/persistentSelectors";

// TODO: move to AppHeader.js so all containers are co-located with the component.
const mapStateToProps = (state, ownProps) => ({
  title: "My Schedule",
  config: headerConfigConstants.APPOINTMENT,
  jobRole: getJobRole(state, ownProps),
  backgroundImage: ownProps.backgroundImage
});

export default connect(mapStateToProps)(AppHeader);
