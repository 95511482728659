import { schema, normalize } from "normalizr";

const pet = new schema.Entity("pets", {}, { idAttribute: "petId" });

const customer = new schema.Entity(
  "customers",
  {
    pets: [pet]
  },
  {
    idAttribute: "customerKey",
    processStrategy: (value, parent, key) => ({
      ...value,
      pets: value.pets || [],
      phones: value.phones || [],
      emails: value.emails || [],
      addresses: value.addresses || []
    })
  }
);

const customersList = [customer];

export default function normalizeCustomers(customersResponse) {
  if (!customersResponse) {
    throw new Error("Customers must be supplied");
  }

  const { entities } = normalize(
    customersResponse,
    Array.isArray(customersResponse) ? customersList : customer
  );

  return {
    customers: entities.customers,
    pets: entities.pets || {}
  };
}
