import { DISPLAY_STORE_HOURS_ERROR, HIDE_STORE_HOURS_ERROR } from "./storeHoursErrorActions";

const initialState = {
  displayStoreHoursErrorMessage: false,
};

const storeHoursErrorMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_STORE_HOURS_ERROR:
      return {
        ...state,
        displayStoreHoursError: true,
      };
    case HIDE_STORE_HOURS_ERROR:
      return {
        ...state,
        displayStoreHoursError: false,
      };
    default:
      return state;
  }
};

export default storeHoursErrorMessageReducer;
