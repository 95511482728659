import { connect } from "react-redux";
import { modalTypes } from "../../../core/constants/associateSchedulingConstants";
import schedulesActionCreators from "../../../core/actionCreators/schedulesActionCreators";
import AddButton from "../../common/buttons/addButton";

const mapStateToProps = state => ({
  opacity: "1",
  label: "Add Partial Day Relocation",
  fontSize: "1em"
});

const mapDispatchToProps = dispatch => ({
  buttonClickHandler: () =>
    dispatch(schedulesActionCreators.showSchedulesModal(modalTypes.PARTIAL_DAY_RELOCATION))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddButton);
