import { EMAIL_REGEX } from "../../constants/regex";

/** @module emailValidation */

/**
 * Verify if an email string is valid
 * @exports verifyEmail
 * @param {string} emailString - email address
 * @return {boolean} A boolean if email string is valid or not
 *
 */
export default function verifyEmail(emailString) {
  const regXExpression = EMAIL_REGEX;
  return regXExpression.test(emailString);
}
