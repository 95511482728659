import React from "react";
import styled from "styled-components";

const CustomerPhone = styled.div`
  display: flex;
`;

export default class CustomerPhoneComponent extends React.Component {
  render() {
    const { customerPhone, isHidden } = this.props;
    return !isHidden && <CustomerPhone>{customerPhone}</CustomerPhone>;
  }
}
