import React from "react";
import styled from "styled-components";
import { isEmpty } from "lodash/fp";
import SalonServices from "../../services/salonServices/SalonServicesContainer";
import PendingAppointment from "../../pendingAppointment/PendingAppointmentContainer";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2.8125rem;
`;

const Services = ({
  selectedPet,
  eligibility,
  canNotBookReasons,
  isStandalone,
  hotelKey,
  salonKey,
  isHidden,
  componentID,
}) => {
  if (!isHidden) {
    return (
      <SectionWrapper id={componentID}>
        {!isEmpty(eligibility) &&
        eligibility.pets &&
        eligibility.pets[selectedPet] &&
        eligibility.pets[selectedPet].canBook === false ? (
          <ServicesWrapper>
            Pet is not eligible for booking for the following reasons:
            <ul>
              {eligibility.pets[selectedPet].reasons
                .filter(reason => canNotBookReasons.includes(reason.reasonCode))
                .map(reason => (
                  <li>{reason.description}</li>
                ))}
            </ul>
          </ServicesWrapper>
        ) : (
          <ServicesWrapper>
            <PendingAppointment key={hotelKey} />
            <SalonServices key={salonKey} isStandalone={isStandalone} />
          </ServicesWrapper>
        )}
      </SectionWrapper>
    );
  }

  return null;
};

export default Services;
