import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Update pet service notes
 * For System Services Booking API
 * - Update pet service notes
 * @param {*} { data, customerId, itineraryId, petServiceItemId }
 * @returns {*} AxiosPromise
 */
export function updateServiceNote({ data, customerId, itineraryId, petServiceItemId }) {
  return bookingAPI
    .put(
      `${endpointVersionString()}/customers/${customerId}/itineraries/${itineraryId}/engagements/${petServiceItemId}/petservices/${petServiceItemId}/notes`,
      data
    )
    .then(response => response);
}
