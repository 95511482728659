import jwtDecode from "jwt-decode";

import { history } from "dux/utils/browser/browserHistory";
import { store } from "../store";
import { getApiError } from "../selectors/utils";
import { authErrorMessages } from "../constants/authConstants";
import {
  setSessionToken,
  clearSessionToken,
  clearSelectedUser,
  clearAuthenticatedWithQuickPin,
  logoutUser,
  cancelRefresh,
} from "../actionCreators/authActionCreators";
import { routePaths } from "../constants/routePaths";
import { getIsSRCAgent } from "../selectors/persistentSelectors";
import {
  appendAuthenticatedSubscriptionKeyToHeaders,
  appendSessionTokenToHeaders,
} from "core/services/systemAssociateAuth/interceptors";

export const isAuthRoute = pathname =>
  pathname.includes("quick-pin") ||
  pathname.includes(routePaths.LOGIN) ||
  pathname.includes(routePaths.IMPLICIT_CALLBACK) ||
  pathname === routePaths.ROOT;

history.listen(({ location }) => {
  if (isAuthRoute(location.pathname)) {
    store.dispatch(cancelRefresh());
  }
});

export const setRequestHeaders = config => {
  // Send the Authenticated Subscription Key (ASK) with each API request
  appendAuthenticatedSubscriptionKeyToHeaders(config);

  // Send the refreshed bearer session token with each API request in order to access
  // authenticated/restricted endpoints.
  appendSessionTokenToHeaders(config);

  return config;
};

export const setResponseRefreshToken = response => {
  const {
    headers: { "x-refreshed-session-token": refreshedSessionToken },
  } = response;
  if (refreshedSessionToken) {
    store.dispatch(setSessionToken({ sessionToken: refreshedSessionToken }));
  }
  return response;
};

export const handleAuthorizationRejection = error => {
  const status = error?.response?.status;
  const apiError = getApiError(error);

  switch (status) {
    case 401:
      if (apiError.includes(authErrorMessages.NON_ELEVATED)) {
        closeUserSession();
      } else if (apiError.includes(authErrorMessages.ELEVATED_PERMISSION_EXPIRED)) {
        logoutUserFromOkta();
      } else {
        history.push(routePaths.LOGIN);
      }
      clearQuickPinSession();
      break;
    case 403:
      if (apiError.includes(authErrorMessages.USER_HAS_NO_PIN)) {
        history.push(routePaths.SET_PIN);
      } else if (apiError.includes(authErrorMessages.ELEVATED_PERMISSIONS_REJECTED)) {
        closeUserSession();
      } else {
        history.push(routePaths.LOGIN);
      }
      clearQuickPinSession();
      break;
    default:
      return Promise.reject(error);
  }
  return Promise.reject(error);
};

export const buildUrls = () => {
  const issuer = window.env.OKTA_ISSUER;
  return {
    issuer,
    authorizeUrl: `${issuer}/oauth2/v1/authorize`,
    userinfoUrl: `${issuer}/oauth2/v1/userinfo`,
  };
};

export function generateRandomString(length) {
  const randomCharset = "abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let random = "";
  for (let c = 0, cl = randomCharset.length; c < length; c += 1) {
    random += randomCharset[Math.floor(Math.random() * cl)];
  }
  return random;
}

export const buildDefaultOptions = () => {
  const issuer = window.env.OKTA_ISSUER;
  return {
    clientId: window.env.OKTA_CLIENT_ID,
    redirectUri: window.env.OKTA_REDIRECT_URI,
    responseType: ["id_token", "token"],
    responseMode: "fragment",
    state: generateRandomString(64),
    nonce: generateRandomString(64),
    scopes: ["openid", "profile", "email"],
    urls: buildUrls(),
    issuer,
  };
};

export const buildOAuthURL = ({ issuer, redirectUri, clientId, responseMode, state, nonce }) =>
  encodeURI(
    `${issuer}/oauth2/v1/authorize?` +
      `client_id=${clientId}` +
      `&redirect_uri=${redirectUri}` +
      "&response_type=id_token token" +
      `&response_mode=${responseMode}` +
      `&state=${state}` +
      `&nonce=${nonce}` +
      "&scope=openid profile email",
  );

function clearQuickPinSession() {
  store.dispatch(clearAuthenticatedWithQuickPin());
  store.dispatch(clearSessionToken());
  store.dispatch(clearSelectedUser());
}

export const setCookie = (name, value) => {
  document.cookie = `${name}=${value}; path=/;`;
};

export const deleteCookie = name => {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
};

export const setLocalStorageItem = (name, value) => {
  try {
    localStorage.setItem(name, value);
  } catch (e) {
    // Do nothing
  }
};

export const deleteLocalStorageItem = name => {
  try {
    localStorage.removeItem(name);
  } catch (e) {
    // Do nothing
  }
};

export const createOktaTokenStoragePayload = ({ accessToken, idToken }) => {
  const decodedAccessToken = jwtDecode(accessToken);
  const decodedIdToken = jwtDecode(idToken);
  const scopes = ["openid", "profile", "email"];
  const authorizeUrl = `${window.env.OKTA_ISSUER}/oauth2/v1/authorize`;

  return {
    idToken: {
      idToken,
      scopes,
      authorizeUrl,
      claims: { ...decodedIdToken },
      expiresAt: decodedIdToken.exp,
      issuer: window.env.OKTA_ISSUER,
      clientId: window.env.OKTA_CLIENT_ID,
    },
    accessToken: {
      accessToken,
      scopes,
      authorizeUrl,
      expiresAt: decodedAccessToken.exp,
      tokenType: "bearer",
      userinfoUrl: `${window.env.OKTA_ISSUER}/oauth2/v1/userinfo`,
    },
  };
};

export function setOktaTokenStorageCookie({ accessToken, idToken }) {
  const oktaTokenStorage = createOktaTokenStoragePayload({ accessToken, idToken });

  setLocalStorageItem("okta-token-storage", JSON.stringify(oktaTokenStorage));
}

function closeUserSession() {
  const isSRCAgent = getIsSRCAgent(store.getState());
  if (isSRCAgent) {
    logoutUserFromOkta();
  } else {
    history.push(routePaths.SELECT_USER);
  }
}

function logoutUserFromOkta() {
  history.push(routePaths.LOGIN);
  store.dispatch(logoutUser({ browserSessionOnly: true }));
}
