import { connect } from "react-redux";
import { get } from "lodash/fp";
import BookingFoodFormComponent from "../_components/feeding/booking/BookingFoodFormComponent";
import { getFoodById, getFoodIds } from "../../web/food/foodsSelector";
import {
  getFoodIsShowingDetails,
  getFoodIsShowingDetailsForType,
} from "../../web/foodList/foodListSelectors";
import { isFromBooking } from "../../core/utils/validationUtils/isFromValidation";
import { history } from "@/dux/utils/browser/browserHistory";
import { medicationTypes } from "@/web/newMedication/newMedicationConstants";
import { applyPreservedFood, preserveFood } from "@/web/food/actions/foodsActions";

const mapStateToProps = (state, ownProps) => {
  const { foodId, foodIndex, petId, locationType } = ownProps;
  const typesShowing = getFoodIsShowingDetails(state, { foodId, petId });
  // This form is used in 2 places on the booking page, so if at least one is open isEditingFood should be true
  const isEditingFood =
    typesShowing.includes(medicationTypes.BOOKING) || typesShowing.includes(medicationTypes.CART);

  return {
    petId,
    foodId,
    foodIndex,
    locationType,
    isHidden:
      !isFromBooking(get(["location", "pathname"], history)) ||
      !getFoodIsShowingDetailsForType(state, { foodId, petId, locationType }),
    foodIds: getFoodIds(state, { petId }),
    isEditingFood,
    isFoodPreserved: !!getFoodById(state, { foodId, petId })?.preserved,
  };
};

const mapDispatchToProps = (dispatch, { foodId, petId }) => ({
  preserveOrApplyFoodData: ({ isEditingFood, isFoodPreserved }) => {
    // When showing the food form, store a copy of food to preserve in case changes need to be discarded
    if (isEditingFood && !isFoodPreserved) dispatch(preserveFood({ foodId, petId }));

    // When closing, overwrite any changes with the preserved version if it exists
    if (!isEditingFood && isFoodPreserved) dispatch(applyPreservedFood({ foodId, petId }));
  },
});

const mergeProps = (stateProps, dispatchProps) => {
  const {
    petId,
    foodId,
    foodIndex,
    locationType,
    isHidden,
    foodIds,
    isEditingFood,
    isFoodPreserved,
  } = stateProps;
  const { preserveOrApplyFoodData } = dispatchProps;

  return {
    petId,
    foodId,
    foodIndex,
    locationType,
    isHidden,
    foodIds,
    isEditingFood,
    preserveOrApplyFoodData: () => preserveOrApplyFoodData({ isEditingFood, isFoodPreserved }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BookingFoodFormComponent);
