// @ts-check
/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_OVERBOOKING_SETUP_DATA = "GET_OVERBOOKING_SETUP_DATA";
// Below Action Types are patterned for using loading and error state
export const SET_OVERBOOKING_SETUP_DATA = "SET_OVERBOOKING_SETUP_DATA";
export const SET_OVERBOOKING_SETUP_DATA_REQUEST = "SET_OVERBOOKING_SETUP_DATA_REQUEST";
export const SET_OVERBOOKING_SETUP_DATA_SUCCESS = "SET_OVERBOOKING_SETUP_DATA_SUCCESS";
export const SET_OVERBOOKING_SETUP_DATA_FAILURE = "SET_OVERBOOKING_SETUP_DATA_FAILURE";
/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

export const getOverBookingSetupData = () => ({
  type: GET_OVERBOOKING_SETUP_DATA,
});

export const setOverBookingSetupData = () => ({
  type: SET_OVERBOOKING_SETUP_DATA,
});

export const setOverBookingSetupDataRequest = () => ({
  type: SET_OVERBOOKING_SETUP_DATA_REQUEST,
});

/**
 *
 * @param {object} payload - response data from API
 * @returns
 */
export const setOverBookingSetupDataSuccess = (payload) => ({
  type: SET_OVERBOOKING_SETUP_DATA_SUCCESS,
  payload,
});

/**
 *
 * @param {object} payload - error response from API
 * @returns
 */
export const setOverBookingSetupDataFailure = (payload) => ({
  type: SET_OVERBOOKING_SETUP_DATA_FAILURE,
  payload,
});
