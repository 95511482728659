import { connect } from "react-redux";

import { getAppointmentByPet } from "core/selectors/cartSelectors";
import { getCommonServicesByPet } from "core/selectors/entitiesSelector";
import { setAddons, selectPetService } from "core/actionCreators/ui/web/cartActionCreators";
import commonServicesButtonGrid from "web/common/servicesForPet/commonServicesButtonGrid";
import quickQuoteConstants from "core/constants/quickQuoteConstants";

const mapStateToProps = (state, ownProps) => {
  const appointment = getAppointmentByPet(state, ownProps);
  return {
    services: getCommonServicesByPet(state, ownProps) || [],
    selectedPetService: appointment && appointment.petService,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onServiceClick: petServiceId => {
      dispatch(setAddons({ addons: [], petId: quickQuoteConstants.QUOTE_PET }));
      dispatch(selectPetService({ petServiceId, petId: quickQuoteConstants.QUOTE_PET }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(commonServicesButtonGrid);
