import { connect } from "react-redux";
import { getFoodCount } from "web/foodCount/foodCountSelector";
import { showHideFoodDetails } from "web/foodList/actions/foodListActions";
import AddButton from "web/common/buttons/addButton";
import { isFromBooking } from "core/utils/validationUtils/isFromValidation";
import { get } from "lodash/fp";
import { NEW_FOOD_ID } from "web/newFood/newFoodConstants";
import { setNewFood, showAddNewFood } from "web/newFood/actions/newFoodActions";
import {
  getFoodIsShowingDetails,
  getFoodIsShowingDetailsForType,
} from "web/foodList/foodListSelectors";
import { history } from "@/dux/utils/browser/browserHistory";
import { setPendingFrequency } from "dux/frequency/actions/frequencyActions";
import { getSameDayFilteredOptions } from "dux/frequency/frequencySelectors";
import { getCheckForSameDay } from "@/dux/frequency/dateRangeForFrequencySelectors";
import { setFoodFrequency } from "dux/bookingFeeding/setFoodFrequency/actions/foodFrequencyActions";

const mapStateToProps = (state, ownProps) => {
  const { petId, locationType } = ownProps;
  const foodId = NEW_FOOD_ID;
  const foodCount = getFoodCount(state, { petId, foodId });
  const isLocationTypeShowing = getFoodIsShowingDetailsForType(state, {
    foodId,
    petId,
    locationType,
  });
  return {
    petId,
    foodId,
    locationType,
    foodCount,
    isLocationTypeShowing,
    isHidden: !isFromBooking(get(["location", "pathname"], history)) || isLocationTypeShowing,
    opacity: "1",
    label: "Add Food",
    fontSize: "regular",
    typesShowing: getFoodIsShowingDetails(state, { foodId, petId }),

    // These values are here for the Set Frequency button and modal. When the Food Booking Add button
    // is clicked, we need to know if it is DCC or hotel ,e.g. the isSameDay, and we need options for the
    // setPendingFrequency action below. Once that action is fired, it sets the frequency value and auto checks
    // the date checkbox in the set frequency modal.
    isSameDay: getCheckForSameDay(state),
    options: getSameDayFilteredOptions(state),
  };
};

const mapDispatchToProps = dispatch => ({
  showFeedingForm: (foodCount, foodId, petId, locationTypes, options, isSameDay) => {
    dispatch(setNewFood({ petId }));
    dispatch(showAddNewFood({ petId }));
    dispatch(
      showHideFoodDetails({
        foodId,
        petId,
        locationTypes,
      }),
    );

    // If it is DDC (a single day appointment) we will dispatch this action to auto populate the
    // frequency dropdown and date(s) checkboxes in the Set Frequency modal and set the frequency value
    // to everyday in state.
    if (isSameDay) {
      dispatch(setPendingFrequency({ frequency: options[0].value }));
      dispatch(setFoodFrequency({ petId, foodId, frequency: options[0].value }));
    }
  },
});

// TODO: refactor merge props to limit what props go to the view, example, options and isSame should be passed to the view.
const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  buttonClickHandler: () => {
    const {
      foodCount,
      foodId,
      petId,
      locationType,
      isLocationTypeShowing,
      typesShowing,
      options,
      isSameDay,
    } = propsFromState;
    let locationTypes;
    if (!isLocationTypeShowing) {
      locationTypes = [...typesShowing, locationType];
    }
    propsFromDispatch.showFeedingForm(foodCount, foodId, petId, locationTypes, options, isSameDay);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddButton);
