import React from "react";
import styled from "styled-components";
import { device } from "web/common/styles/responsive/devices";

const RootComponent = styled.section`
  display: flex;
  flex-direction: row;
`;

const Svg = styled.svg`
  transform: rotate(45deg);

  @media ${device.mobileS} {
    width: 60px;
    height: 60px;
  }

  @media ${device.tablet} {
    width: 40px;
    height: 40px;
  }

  @media ${device.tablet} {
    width: 25px;
    height: 25px;
  }

  // There were concerns around responsive affecting the UI layout a week before the BGM deployment, All media queries
  // that use the device object will have a media query to target Prism desktop to support BGM release.
  // This media query can be removed after 11/9/20
  @media ${device.prismDesktop} {
    width: 25px;
    height: 25px;
  }
`;

const Circle = styled.circle`
  fill: #1778f2;
  stroke-width: 2.5px;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  opacity: ${props => (props.opacity ? props.opacity : "1")};
`;

const Line = styled.line`
  stroke: white;
  stroke-width: 2.5px;
  stroke-miterlimit: 10;
  stroke-linecap: round;
`;

export default function AddIconSVGWrappedComponent(props) {
  const { isHidden, componentID } = props;

  if (!isHidden) {
    return (
      <RootComponent data-testid="addIconSVGWrapped-test" id={componentID}>
        <Svg data-testid="svg-element" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
          <Circle {...props} cx="20" cy="20" r="16" />
          <Line {...props} x1="15" y1="15" x2="25" y2="25" />
          <Line {...props} x1="25" y1="15" x2="15" y2="25" />
        </Svg>
      </RootComponent>
    );
  }

  return null;
}
