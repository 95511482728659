import { put, takeEvery, all, fork } from "redux-saga/effects";
import customersActionTypes from "../../actionTypes/customersActionTypes";
import {
  createCustomerPreferencesRequest,
  createCustomerPreferencesSuccess,
  createCustomerPreferencesFailure,
  updateCustomerPreferencesRequest,
  updateCustomerPreferencesSuccess,
  updateCustomerPreferencesFailure,
} from "../../actionCreators/customersActionCreators";
import {
  postCustomerPreferences,
  putCustomerPreferences,
} from "../../services/associateWebProfile/customersEndPoints";
import setPreferences from "@/core/utils/customer/preferences/setPreferences";

export function* onCreateCustomerPreferences({ customerKey, data }) {
  try {
    yield put(createCustomerPreferencesRequest());
    yield setPreferences({
      apiEndpoint: postCustomerPreferences,
      customerKey,
      data,
    });
    yield put(createCustomerPreferencesSuccess());
  } catch (error) {
    yield put(createCustomerPreferencesFailure({ error }));
  }
}

export function* onUpdateCustomerPreferences({ customerKey, data }) {
  try {
    yield put(updateCustomerPreferencesRequest());
    yield setPreferences({
      apiEndpoint: putCustomerPreferences,
      customerKey,
      data,
    });
    yield put(updateCustomerPreferencesSuccess());
  } catch (error) {
    yield put(updateCustomerPreferencesFailure({ error }));
  }
}

function* watchCreateCustomerPreferences() {
  yield takeEvery(customersActionTypes.CREATE_CUSTOMER_PREFERENCES, onCreateCustomerPreferences);
}

function* watchUpdateCustomerPreferences() {
  yield takeEvery(customersActionTypes.UPDATE_CUSTOMER_PREFERENCES, onCreateCustomerPreferences);
}

export default function* customerPreferencesSaga() {
  yield all([fork(watchCreateCustomerPreferences), fork(watchUpdateCustomerPreferences)]);
}
