import { compose } from "lodash/fp";
import shallowDiff from "../objectUtils/shallowDiff";
import removeEmpty from "../objectUtils/removeEmpty";

const getFormFieldsChanged = compose(
  removeEmpty,
  shallowDiff
);

export default getFormFieldsChanged;
