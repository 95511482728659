// React Redux
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

// Google Map
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { GoogleMap } from "@/googleMaps/GoogleMap";

// Layout
import { Layout } from "@prism/psm-ui-components";
import { GoogleMapMarker } from "@/googleMaps/GoogleMapsMarker";
import { selectStoreLatAndLong } from "dux/storeInfoGoogleMap/storeInfoGoogleMapSelectors";
import { getStoreNumber } from "core/selectors/persistentSelectors";

const render = (status: Status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return <h1>{status}</h1>;
};

export const StoreInfoGoogleMap = ({
  componentId,
  zoom,
  center,
  style,
  streetViewControl,
  mapTypeControl,
}) => {
  return (
    <Layout.Box id={componentId}>
      <Wrapper apiKey={window.env.GOOGLE_MAP_KEY} render={render}>
        <GoogleMap
          center={center}
          zoom={zoom}
          style={style}
          streetViewControl={streetViewControl}
          mapTypeControl={mapTypeControl}
        >
          <GoogleMapMarker position={center} />
        </GoogleMap>
      </Wrapper>
    </Layout.Box>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
// Selected Store
export const SRC_StoreInfoGoogleMap = connect(state => {
  const storeNumber = getStoreNumber(state);
  const zoom = 15; // initial zoom
  const center = selectStoreLatAndLong(state, { storeNumber });

  return {
    componentId: `SRC_StoreInfoGoogleMap`,
    zoom,
    center,
    streetViewControl: false,
    mapTypeControl: false,
    style: { width: "250px", height: "250px" },
  };
})(StoreInfoGoogleMap);

// Each store in a 75 mile radius
export const Closest_StoreInfoGoogleMap = connect((state, { lat, lng }) => {
  const zoom = 13; // initial zoom

  return {
    componentId: `Closest_StoreInfoGoogleMap--${lat}-${lng}`,
    zoom,
    center: { lat, lng },
    streetViewControl: false,
    mapTypeControl: false,
    style: { width: "150px", height: "150px" },
  };
})(StoreInfoGoogleMap);
