import { connect } from "react-redux";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { GO_LIVE_DATE } from "core/constants/dashboardContants";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { setWeekNumber } from "core/actionCreators/ui/web/commonActionCreators";
import Calendar from "web/common/Calendar";
import { getHotelServiceManagement } from "dux/serviceManagementPage/serviceManagementPageActions";
import moment from "moment";

// HOTEL SERVICE MANAGER DATE PICKER CONTAINER ----------------------------------------------------------------------------------------
export const HotelServiceManagementDatePicker = connect(
  state => {
    const selectedDate = getSelectedDate(state);

    return {
      componentId: "HotelServiceManagementDatePicker",
      isWeekly: false,
      isOutsideRange: date => date.isBefore(GO_LIVE_DATE),
      calendarTitle: "Service Date",
      selectedDate,
    };
  },

  dispatch => {
    return {
      selectDate: selectedDate => {
        dispatch(setSelectDate(selectedDate));

        const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
        dispatch(getHotelServiceManagement({ fromDate: formattedDate, toDate: formattedDate }));
      },

      setWeekNumber: weekNumber => {
        dispatch(setWeekNumber({ weekNumber }));
      },
    };
  },
)(Calendar);
