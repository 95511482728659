import { createSelector } from "reselect";
import { NEW_MEDICATION_ID } from "web/newMedication/newMedicationConstants";
import { getMedicationIds, getMedicationsByPet } from "../medication/medicationsSelector";

export const getMedicationCount = createSelector([getMedicationsByPet], medications => {
  return medications ? Object.keys(medications)?.length : 0;
});

export const getNewMedicationId = createSelector([getMedicationIds], meds => {
  const lastMed = meds[meds.length - 1]?.split("_");
  const lastMedIndex = lastMed ? lastMed[lastMed?.length - 1] : 0;
  return `${NEW_MEDICATION_ID}_${Number(lastMedIndex) + 1}`;
});
