import { connect } from "react-redux";
import AppHeaderModal from "./appHeaderModal";
import { hideHeaderModal } from "../../../core/actionCreators/ui/web/appHeaderActionCreators";

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(hideHeaderModal())
});

export default connect(
  null,
  mapDispatchToProps
)(AppHeaderModal);
