import features from "./featureFlagsConstants";
import { enableDisableFeatureActionTypes } from "./EnableDisableFeatureActions";
import { showHideRequiresFeatureActionTypes } from "../common/featureFlaggingRequires/ShowHideRequiresFeatureFlagActions";
import getNormalizedFeatureFlagName from "./utils/getNormalizedFeatureFlagName";

const initialState = features;

const enableDisableFeatureFlagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case enableDisableFeatureActionTypes.ENABLE_DISABLE_FEATURE_FLAG:
      return {
        ...state,
        [getNormalizedFeatureFlagName(action.featureFlagId)]: {
          ...state[getNormalizedFeatureFlagName(action.featureFlagId)],
          isHidden: !action.checked,
        },
      };
    case showHideRequiresFeatureActionTypes.SHOW_REQUIRE_FEATURE_FEATURE_FLAG:
      return {
        ...state,
        [getNormalizedFeatureFlagName(action.featureFlagId)]: {
          ...state[getNormalizedFeatureFlagName(action.featureFlagId)],
          showRequires: !action.isHidden,
        },
      };
    default:
      return state;
  }
};

export default enableDisableFeatureFlagsReducer;
