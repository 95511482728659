import { isEmpty, isNil, isNumber, isString } from "lodash/fp";
import { isValidString } from "@/core/utils/validationUtils/stringValidation";

/**
 * Util to check if all values in an array are valid
 * @memberOf Utils.Validation
 * @function
 * @name validateArrayItems
 * @param {Array} arr
 * @returns {Boolean}
 * @example validateArrayItems(["     ", undefined, {}]) // -> returns false
 */
export const validateArrayItems = requiredFields =>
  !requiredFields?.some(value => {
    if (isNumber(value)) return isNil(value);
    if (isString(value)) return !isValidString(value);
    return isEmpty(value);
  });
