import moment from "moment";
import { sortBy } from "lodash/fp";
import {
  absenceReasons,
  dayActivityTypes,
  petSupportedTypes,
} from "core/constants/schedulesConstants";
import { filterWorkHours, sortDayActivities } from "@/core/selectors/schedulesSelectors";

const { LUNCH_BREAK } = absenceReasons;

/**
 * Helper to get lunch break activity from array of day activities
 * @memberOf Utils.Schedules
 * @param {Object} activities
 * @returns lunch break object
 */
export const getLunchBreak = activities =>
  activities?.find(({ absenceReason }) => absenceReason === LUNCH_BREAK);

/**
 * Helper to add an index to split shift work activities for sorting
 * @memberOf Utils.Schedules
 * @param {Object} schedules - api data from schedules call
 * @returns Object of the same shape as schedules but where split shift activities have a shiftIndex attr
 */
export const addSplitShiftActivityIndex = schedules => {
  const updatedDailyActivity = Object.fromEntries(
    Object.entries(schedules?.dailyActivity).map(([day, activities]) => {
      const workHours = activities?.filter(({ type }) => type === dayActivityTypes.WORK);
      const isSplit = workHours?.length > 1;
      if (!isSplit) return [day, activities];

      // Sort work hours by time then add indexes
      const indexedWorkHours = workHours
        ?.sort(sortDayActivities)
        ?.map((shift, shiftIndex) => ({ ...shift, shiftIndex }));
      const nonWorkType = activities?.filter(({ type }) => type !== dayActivityTypes.WORK);

      return [day, [...indexedWorkHours, ...nonWorkType]];
    }),
  );

  return { ...schedules, dailyActivity: updatedDailyActivity };
};

/**
 * takes an array of activities for a given day and aligns the start/end times around the lunch break
 * @param {Object[]} updatedWorkActivities - updated array of activities for a given day
 * @returns Object[] an array of Activities aligned around the lunch time
 */
export const alignDayTimes = activities => {
  // Separate the work hours from the lunch break then sort, we can't assume the order of activities in activities
  const [morningBlock, afternoonBlock] = filterWorkHours(activities);
  const lunchBreak = getLunchBreak(activities);

  // make sure all the times line up correctly with the lunch break
  morningBlock.endTime = lunchBreak.startTime;
  afternoonBlock.startTime = lunchBreak.endTime;

  // Set flag to skip logic in saga that would update lunch hours
  afternoonBlock.isSplitShift = true;
  morningBlock.isSplitShift = true;

  return [morningBlock, lunchBreak, afternoonBlock];
};

/**
 * Adds the incoming changes to the corresponding Activity by localId
 * @param {Object[]} dayActivities - array of activities for a given day
 * @param {Object} Activity - times for a specific activity
 * @param {string} Activity.localId - id used to link activity with a specific day
 * @param {string} Activity.startTime - "HH:mm:ss" Moment formatted start time
 * @param {string} Activity.endTime - "HH:mm:ss" Moment formatted end time
 * @returns Activity
 */

export const applyShiftTimeChanges = (dayActivities, { localId, startTime, endTime }) =>
  dayActivities.map(activity => {
    if (activity.localId !== localId) return activity; // not the activity we're looking for
    return { ...activity, startTime, endTime }; // apply changes
  });

/**
 * takes a list of current day activities and a new change.
 *  Adds the changes and aligns the times before returning an array of activities
 *
 * @param {Object[]} dayActivities - array of activities for a given day
 * @param {{ localId: string, startTime: string, endTime: string }} newActivityTimes - incoming times that were changed by the user
 * @returns Object[] an aligned and sorted list of Activities
 */
export const buildShiftDayActivities = (dayActivities, newActivityTimes) => {
  const isSplitShift = dayActivities.length > 2;

  if (!isSplitShift) return [newActivityTimes]; // we only want to update split shifts

  // first apply the incoming changes
  const updatedWorkActivities = applyShiftTimeChanges(dayActivities, newActivityTimes);

  return alignDayTimes(updatedWorkActivities); // make sure all activities are aligned by time
};

/**
 *
 * @param {{ absenceReason: string }[]} dayActivities - array of activities for a given day
 * @param {bool} isEndTimeChanged - whether the end time has changed or not
 * @param {string} startTime - "HH:mm:ss" Moment formatted start time
 * @param {string} endTime - "HH:mm:ss" Moment formatted end time
 * @returns Activity
 */
export const findChangedActivity = (dayActivities, isEndTimeChanged, { startTime, endTime }) => {
  const workHours = dayActivities.filter(
    activity => activity.absenceReason !== absenceReasons.LUNCH_BREAK,
  );

  if (workHours.length === 1) {
    return { ...workHours[0], startTime, endTime };
  }

  const sortedWorkHours = sortBy(activity => moment(activity.startTime, "HH:mm"), workHours);

  return isEndTimeChanged
    ? { ...sortedWorkHours[1], endTime }
    : { ...sortedWorkHours[0], startTime };
};

/**
 * Util to filter out all Relocation work types from a list of weekly edit activities
 *
 * @memberOf Utils.Schedules
 * @function
 * @name filterRelocationWeeklyEditActivites
 * @param {Object} weeklyEditActivities - Map of weekly edit activites
 * @return {Object[]} - array of Relocation work types
 * @example filterRelocationWeeklyEditActivites(weeklyEditActivities)
 */
export const filterRelocationWeeklyEditActivites = (weeklyEditActivities = {}) =>
  Object.values(weeklyEditActivities).filter(({ type }) => type === dayActivityTypes.RELOCATION);

/**
 * Util to validate that all Relocation weekly edit activities have a store number selected
 *
 * @memberOf Utils.Schedules
 * @function
 * @name allRelocationsHaveStoreIds
 * @param {Object[]} relocations - Array of relocation weekly edit activities
 * @return {boolean} - false if ANY relocations don't have a store assigned
 * @example allRelocationsHaveStoreIds(relocations)
 */
export const allRelocationsHaveStoreIds = relocations =>
  relocations.every(weeklyEditActivity => !!weeklyEditActivity.storeId);

/**
 * Util to validate that Relocation weekly edit activities don't have the associate's primary store selected
 *
 * @memberOf Utils.Schedules
 * @function
 * @name isRelocatingToNonPrimaryStore
 * @param {Object[]} relocations - Array of relocation weekly edit activities
 * @param {Object} associate - Associate details
 * @return {boolean} - false if ANY relocations are using the associates primary store
 * @example isRelocatingToNonPrimaryStore(relocations, associate)
 */
export const isRelocatingToNonPrimaryStore = (relocations, associate) =>
  !relocations.some(({ storeId }) => storeId === associate?.primaryStoreNumber);

/**
 * Util returning if an activity type is 'Work' or 'Relocation'
 *
 * @memberOf Utils.Schedules
 * @function
 * @name isWorkOrRelocationActivityType
 * @param {string} type - activity type Work|Relocation|Absence
 * @return {boolean} - true is the activity type is 'Work' or 'Relocation'
 * @example isWorkOrRelocationActivityType(type)
 */
export const isWorkOrRelocationActivityType = type =>
  type === dayActivityTypes.WORK || type === dayActivityTypes.RELOCATION;

/**
 * Util to validate that an activity is a weekend day
 * @memberOf Utils.Schedules
 * @function
 * @name isWeekendDay
 * @param {string} dayOfWeek - day of the week
 * @return {boolean} - true if the day is a weekend day
 * @example isWeekendDay(dayOfWeek)
 */
export const isWeekendDay = dayOfWeek => dayOfWeek === "Saturday" || dayOfWeek === "Sunday";

/**
 * Util handling default pet supported types,
 *  if the activity is a weekend day or a Work/Relocation activity without a pet type,
 *  then default to Dog
 *
 * @memberOf Utils.Schedules
 * @function
 * @name derivePetSupportedType
 * @param {Object} activity - day activity
 * @param {string} activity.type - activity type Work|Relocation|Absence
 * @param {string} activity.dayOfWeek - day of the week
 * @param {string[]} activity.petSupportedType - array of pet supported types ie. ["Dog", "Cat"]
 * @return {string[]} - array of pet supported types ie. ["Dog"]
 * @example derivePetSupportedType(activity)
 */
export const derivePetSupportedType = ({ type, dayOfWeek, petSupportedType = [] }) =>
  isWeekendDay(dayOfWeek) || (isWorkOrRelocationActivityType(type) && petSupportedType.length === 0)
    ? [petSupportedTypes.DOG]
    : petSupportedType;

/**
 * Util to create split shift work hours from a single shift
 * @memberOf Utils.Schedules
 * @function
 * @param {Object} firstShift - day activity for work hours
 * @return {Object[]} - array containing 2 day activities
 */
export const createSplitShift = firstShift => {
  // Copy the first shift to create a new second shift and add indexes for sorting
  const newShift = { ...firstShift, localId: `${firstShift.localId}_temp` };
  return [firstShift, newShift].map((shift, shiftIndex) => ({
    ...shift,
    isSplitShift: true,
    shiftIndex,
  }));
};

/**
 * Util to get petSupportedType from a day activity in the correct format
 * @memberOf Utils.Schedules
 * @function
 * @param {String[]} petSupportedType - [] || ["Dog"] || ["Cat"] || ["Dog", "Cat"] || ["Cat", "Dog"]
 * @return {String} Default type is "Dog", if more than 1 then "Dog and Cat", else returns arg as is
 */
export const getCurrentPetType = (petSupportedType = []) => {
  if (petSupportedType?.length > 1) return petSupportedTypes.DOG_AND_CAT;
  return petSupportedType[0] ?? petSupportedTypes.DOG;
};
