import customerActionTypes from "../../../core/actionTypes/customer/customerActionTypes.js";

export const setCurrentCustomerKey = ({ currentCustomerKey }) => ({
  type: customerActionTypes.SET_CURRENT_CUSTOMER_KEY,
  currentCustomerKey
});

export const clearCurrentCustomerKey = () => ({
  type: customerActionTypes.CLEAR_CURRENT_CUSTOMER_KEY
});

export default {
  setCurrentCustomerKey,
  clearCurrentCustomerKey
};
