import { connect } from "react-redux";
import {
  getPetParentAgents,
  getIsAddAgentFormShown,
  getIsAddAgentButtonShown
} from "core/selectors/agentsSelectors";
import agentsActionCreators from "core/actionCreators/agentsActionCreators";
import PetParentAgentFormWrapperComponent from "./PetParentAgentFormWrapperComponent";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";

const mapStateToProps = (state) => {
  const customerKey = getCurrentCustomerKey(state);
  return{
    isHidden: getSystemBookingFlow(state) !== systemName.HOTEL,
    componentId: "PetParent__AgentForm--Hotel",
    petParentAgents: getPetParentAgents(state, {customerKey}),
    isAddAgentFormShown: getIsAddAgentFormShown(state),
    isAddAgentButtonShown: getIsAddAgentButtonShown(state, {customerKey}),
    customerKey,
  }
};

const mapDispatchToProps = dispatch => ({
  showAddAgentForm: () => dispatch(agentsActionCreators.showAddAgentForm())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PetParentAgentFormWrapperComponent);
