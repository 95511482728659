import moment from "moment";
import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import {
  getIsSRCAgent,
  getStoreNumber,
} from "@/core/selectors/persistentSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import { getSelectedDate, getFormattedSelectedDate } from "@/dux/selectedDate/selectedDateSelector";
import {
  GET_TRAINING_ASSOCIATES,
  getTrainingAssociates,
} from "@/dux/trainingAssociates/trainingAssociatesActions";
import {
  GET_TRAINING_CLASS_SESSIONS,
  POST_TRAINING_CLASS_SESSION,
  toggleTrainingClassForm,
  resetTrainingClassSessions,
} from "@/dux/trainingClassSessions/actions";
import { getTrainingPetServices } from "@/dux/trainingPetServices/trainingPetServicesActions";
import DashboardTrainingComponent from "@/dux/_components/dashboard/DashboardTrainingComponent";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import {
  getTrainingClassSessionCard,
  getIsTrainingClassFormHidden,
} from "@/dux/trainingClassSessions/selectors";
import calcAndDispatchRange from "@/dux/trainingClassSessions/helpers/calcandDispatchRange";
import { routePaths } from "@/core/constants/routePaths";

const mapStateToProps = (state, { router: { navigate } }) => {
  const bookingFlow = getSystemBookingFlow(state);

  return {
    componentID: "dashboard__Training",
    isHidden: bookingFlow !== systemName.TRAINING,
    selectedDate: getSelectedDate(state),
    cardListDate: getFormattedSelectedDate(state),
    storeId: getStoreNumber(state),
    cardData: getTrainingClassSessionCard(state),
    isTrainingClassFormHidden: getIsTrainingClassFormHidden(state),
    isSRCAgent: getIsSRCAgent(state),
    isLoading: createLoadingSelector([
      GET_TRAINING_CLASS_SESSIONS,
      GET_TRAINING_ASSOCIATES,
      POST_TRAINING_CLASS_SESSION,
    ])(state),
    onTrainingCardClick: () => navigate(routePaths.MANAGE_TRAINING_CLASS),
    isLimited: bookingFlow === systemName.TRAINING,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchLoadTrainingClassSessions: ({ storeNumber, selectedDate }) => {
    // wrap selected date into a moments object
    const MomentObj = moment(selectedDate);

    calcAndDispatchRange(dispatch, { MomentObj, cachedMonthsList: [], storeNumber });
  },

  dispatchLoadTrainingAssociates: ({ storeNumber, isLimited }) => {
    dispatch(getTrainingAssociates({ storeNumber, isLimited }));
  },

  dispatchLoadTrainingPetServices: ({ storeNumber }) => {
    dispatch(getTrainingPetServices({ storeNumber }));
  },
  dispatchToggleTrainingClassForm: () => {
    dispatch(toggleTrainingClassForm());
  },
  resetTrainingClassSessions: () => {
    dispatch(resetTrainingClassSessions());
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  loadTrainingClassSessions: () => {
    propsFromDispatch.dispatchLoadTrainingClassSessions({
      storeNumber: propsFromState.storeId,
      selectedDate: propsFromState.selectedDate,
    });
  },

  loadTrainingAssociates: () => {
    propsFromDispatch.dispatchLoadTrainingAssociates({
      storeNumber: propsFromState.storeId,
      selectedDate: propsFromState.selectedDate,
      isLimited: propsFromState.isLimited,
    });
  },

  loadTrainingPetServices: () => {
    propsFromDispatch.dispatchLoadTrainingPetServices({
      storeNumber: propsFromState.storeId,
    });
  },

  toggleTrainingClassForm: e => {
    if (propsFromState.isSRCAgent) {
      e.preventDefault();
      return false;
    }
    propsFromDispatch.dispatchToggleTrainingClassForm({
      isHidden: !propsFromState.isTrainingClassFormHidden,
    });
  },
});

export default withRouteProps(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(DashboardTrainingComponent),
);
