import { connect } from "react-redux";

import AlertComponent from "./AlertComponent";
import {
  getPet,
  getCustomerFullName,
  getPetPhoto
} from "../../../../../core/selectors/entitiesSelector";

const mapStateToProps = (state, ownProps) => ({
  subjectName: ownProps.isPet
    ? getPet(state, ownProps).petName
    : getCustomerFullName(state, ownProps),
  photo: getPetPhoto(state, ownProps)
});

export default connect(mapStateToProps)(AlertComponent);
