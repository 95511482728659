import { connect } from "react-redux";
import StoreClosedWarning from "./StoreClosedWarning";
import { getIsSalonClosed } from "../../../core/selectors/salonHoursSelector";

const mapStateToProps = (state, ownProps) => {
  return {
    isSalonClosed: getIsSalonClosed(state, ownProps)
  };
};

export default connect(mapStateToProps)(StoreClosedWarning);
