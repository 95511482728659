import vetsActionTypes from "../actionTypes/vetsActionTypes";

export const createVet = ({ customerKey, petId, formValues }) => ({
  type: vetsActionTypes.CREATE_VET,
  customerKey,
  petId,
  data: formValues
});

export const createVetRequest = () => ({
  type: vetsActionTypes.CREATE_VET_REQUEST
});

export const createVetSuccess = () => ({
  type: vetsActionTypes.CREATE_VET_SUCCESS
});

export const createVetFailure = ({ error }) => ({
  type: vetsActionTypes.CREATE_VET_FAILURE,
  error
});

export const updateVet = ({ customerKey, petId, veterinarianId, formValues }) => ({
  type: vetsActionTypes.UPDATE_VET,
  customerKey,
  petId,
  veterinarianId,
  data: formValues
});

export const updateVetRequest = () => ({
  type: vetsActionTypes.UPDATE_VET_REQUEST
});

export const updateVetSuccess = () => ({
  type: vetsActionTypes.UPDATE_VET_SUCCESS
});

export const updateVetFailure = ({ error }) => ({
  type: vetsActionTypes.UPDATE_VET_FAILURE,
  error
});

export const loadVetsSearchResults = ({ formValues }) => ({
  type: vetsActionTypes.LOAD_VET_SEARCH_RESULTS,
  data: formValues
});

export const loadVetsSearchResultsRequest = () => ({
  type: vetsActionTypes.LOAD_VET_SEARCH_RESULTS_REQUEST
});

export const loadVetsSearchResultsSuccess = ({ searchResults, searchParams }) => ({
  type: vetsActionTypes.LOAD_VET_SEARCH_RESULTS_SUCCESS,
  searchResults,
  searchParams
});

export const loadVetsSearchResultsFailure = ({ error }) => ({
  type: vetsActionTypes.LOAD_VET_SEARCH_RESULTS_FAILURE,
  error
});

export const deleteVet = ({ customerKey, petId, clinicId }) => ({
  type: vetsActionTypes.DELETE_VET,
  customerKey,
  petId,
  clinicId
});

export const deleteVetRequest = () => ({
  type: vetsActionTypes.DELETE_VET_REQUEST
});

export const deleteVetSuccess = () => ({
  type: vetsActionTypes.DELETE_VET_SUCCESS
});

export const deleteVetFailure = ({ error }) => ({
  type: vetsActionTypes.DELETE_VET_FAILURE,
  error
});

export const setCustomerAndPetKeys = ({ customerKey, petId }) => ({
  type: vetsActionTypes.SET_CUSTOMER_AND_PET_KEYS,
  customerKey,
  petId
});

export const clearCustomerAndPetKeys = () => ({
  type: vetsActionTypes.CLEAR_CUSTOMER_AND_PET_KEYS
});

export const setVetToDelete = veterinarianId => ({
  type: vetsActionTypes.SET_VET_TO_DELETE,
  vetToDelete: veterinarianId
});

export const clearVetToDelete = () => ({
  type: vetsActionTypes.CLEAR_VET_TO_DELETE
});

export const showVetSearchResults = () => ({
  type: vetsActionTypes.SHOW_VET_SEARCH_RESULTS
});

export const hideVetSearchResults = () => ({
  type: vetsActionTypes.HIDE_VET_SEARCH_RESULTS
});

export default {
  createVet,
  createVetRequest,
  createVetSuccess,
  createVetFailure,
  updateVet,
  updateVetRequest,
  updateVetSuccess,
  updateVetFailure,
  loadVetsSearchResults,
  loadVetsSearchResultsRequest,
  loadVetsSearchResultsSuccess,
  loadVetsSearchResultsFailure,
  setCustomerAndPetKeys,
  clearCustomerAndPetKeys,
  setVetToDelete,
  clearVetToDelete,
  showVetSearchResults,
  hideVetSearchResults,
  deleteVet,
  deleteVetRequest,
  deleteVetSuccess,
  deleteVetFailure
};
