import { color } from "../theme";

/**
 * Responsible for setting the color for the text
 *
 * @export SetInputFontColor
 * @param {boolean} { error, isLink, isStatic }
 * @returns
 */
export default function SetInputFontColor({ error, isLink, isStatic }) {
  if (error) {
    return color.errorRed;
  }

  if (isLink) {
    return color.kalEl;
  }

  if (isStatic) {
    return color.borderGrey;
  }

  return color.darkGrey;
}
