import React from "react";

export function GenericList({
  isHidden,
  componentID,
  component = "ul",
  itemComponent = "li",
  list = [],
  ...rest
}) {
  if (isHidden) return null;

  const List = component;
  const Item = itemComponent;

  return (
    <List id={componentID} {...rest}>
      {list.map(item => (
        <Item key={item}>{item}</Item>
      ))}
    </List>
  );
}
