/* * ----------------------------------------------------------------------- * *\
   Action Types
\* * ----------------------------------------------------------------------- * */

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

/* * ----------------------------------------------------------------------- * *\
   Action Creators
\* * ----------------------------------------------------------------------- * */

export const setActiveTab = ({ name }) => ({
  type: SET_ACTIVE_TAB,
  payload: name,
});
