/**
 * Format object for moment's calendar function
 * @memberof Constants
 * @example moment().calendar(null, CALENDAR_FORMAT)
 */
export const CALENDAR_FORMAT = {
  sameDay: "[Today], M/D/YYYY",
  nextDay: "[Tomorrow], M/D/YYYY",
  lastDay: "[Yesterday], M/D/YYYY",
  nextWeek: "dddd, M/D/YYYY",
  lastWeek: "dddd, M/D/YYYY",
  sameElse: "dddd, M/D/YYYY",
};
