// return true if all messages contain tag "CAN_CLOSE"; false otherwise
export const getCanClose = (messageTags) => {
  // filter through every array within the 2d array and return ones that include "CAN_CLOSE"
  const filteredTags = messageTags?.filter((message) => message?.includes("CAN_CLOSE"));
  // if all messages contain can close (aka before and after length are the same), return true
  return messageTags !== undefined && filteredTags?.length === messageTags?.length;
};

// return true if at least one message tag contains "CONFETTI"; false otherwise
export const getCanConfetti = (messageTags) => {
  const filteredTags = messageTags?.filter((message) => message?.includes("CONFETTI"));
  // return true if any one message contains CONFETTI tag
  return filteredTags !== undefined && filteredTags?.length !== 0;
};

// check if site already has notifications for today's date to reduce number of API calls
export const getHasNoNotifsForDate = (notifications) => {
  const currentDate = Date.now();
  const filteredNotifs = notifications?.filter((notification) => {
    const dateFrom = Date.parse(notification.startDatetime);
    const dateTo = Date.parse(notification.endDatetime);
    return currentDate <= dateTo && currentDate >= dateFrom;
  });
  return filteredNotifs?.length !== 0;
};
