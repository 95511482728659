import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get the available pet service bundles by pet ID.
 * From the System Services Booking API
 * - Get bundles by Customer ID
 * @param {*} { customerId }
 * @returns {*} AxiosPromise
 */

export default function getAvailableBundlesByCustomerForAllPets({ customerKey }) {
  return bookingAPI.get(`${endpointVersionString()}/products/customers/${customerKey}/bundles`);
}
