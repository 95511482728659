import { connect } from "react-redux";

import {
  fetchAuthorizedUsers,
  setSelectedUser,
  showAuthModal
} from "../../core/actionCreators/authActionCreators";
import { getAuthorizedUsers, getSelectedUser } from "../../core/selectors/authSelectors";
import { modalTypes } from "../../core/constants/quickPin";
import SelectUserComponent from "./SelectUserComponent";

const mapStateToProps = state => ({
  authedUsers: getAuthorizedUsers(state),
  selectedUser: getSelectedUser(state)
});

const mapDispatchToProps = dispatch => ({
  fetchAuthorizedUsers: () => dispatch(fetchAuthorizedUsers()),
  setSelectedUser: ({ name, username }) => dispatch(setSelectedUser({ name, username })),
  showModal: () => dispatch(showAuthModal({ modalType: modalTypes.START_SHIFT }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectUserComponent);
