import { getPetEngagementsFromItinerary } from "../hotelEngagementUtils/hotelEngagementUtils";

export const checkMedIsDiscontinued = medExternalData => med =>
  medExternalData?.[med?.medicationId]?.IsActive === false;

export const getAllPetMeds = (petId, itinerary) => {
  const engagements = getPetEngagementsFromItinerary(petId, itinerary);
  return engagements?.reduce(
    (arr, { metadata: { medications = [] } } = {}) => [
      ...arr,
      ...medications?.filter(({ isDeleted }) => !isDeleted),
    ],
    [],
  );
};

export const checkIfPetsHaveDiscontinuedMeds = ({ itinerary, petIds, medExternalData }) => {
  const itineraryPets = itinerary?.pets?.map(({ petKey }) => petKey) ?? [];
  const petsToCheck = petIds?.length ? petIds : itineraryPets;

  return petsToCheck.some(petId => {
    const petMeds = getAllPetMeds(petId, itinerary);
    return petMeds?.some(checkMedIsDiscontinued(medExternalData));
  }, {});
};
