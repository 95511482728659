import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import {
  selectedOtherVoidReasonNotes,
  selectVoidTransactionReason,
} from "@/dux/voidTransactionReasons/voidTransactionResonsSelectors";
import { getCurrentItinerary } from "@/core/selectors/checkInOutSelector";
import { putItineraryPayment } from "@/core/services/associateWebBff/hotelInvoiceEndpoints";
import { PAID_IN_FULL, VOID_TRANSACTION } from "@/dux/_components/invoice/invoiceConstants";
import { hideCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import { itinerariesPaymentStatuses } from "@/core/constants/itinerariesConstants";
import {
  PUT_HOTEL_INVOICE_PAID,
  putHotelInvoicePaidRequest,
  putHotelInvoicePaidSuccess,
  putHotelInvoicePaidFailure,
  PUT_HOTEL_INVOICE_VOID_TRANSACTION,
  putHotelInvoiceVoidTransactionRequest,
  putHotelInvoiceVoidTransactionSuccess,
  putHotelInvoiceVoidTransactionFailure,
} from "./hotelItineraryPaymentActions";
import {
  setHotelItineraryInvoice,
  setHotelItineraryStatus,
} from "../hotelItinerary/hotelItineraryActions";

/**
 * Generator to call itinerary payment endpoint
 * @memberOf Sagas.hotel.itinerary
 * @generator
 * @name callHotelPaymentPut
 * @returns {Generator<*, void, *>}
 */
export function* callHotelPaymentPut({ paymentOrigin, data }) {
  const customerId = yield select(getCurrentCustomerKey);
  const itineraryId = yield select(getCurrentItinerary);

  return yield call(putItineraryPayment, {
    customerId,
    itineraryId,
    paymentOrigin,
    data,
  });
}

/**
 * Saga to update hotel payment
 * @memberOf Sagas.hotel.itinerary
 * @generator
 * @name onPutHotelInvoicePaid
 * @returns {Generator<*, void, *>}
 */
function* onPutHotelInvoicePaid({ paymentOrigin }) {
  try {
    yield put(putHotelInvoicePaidRequest());

    const response = yield call(callHotelPaymentPut, {
      paymentOrigin,
      data: { action: PAID_IN_FULL },
    });

    // This response is not a full itinerary so just update the invoice's isPaid flag in the itinerary state
    const isPaid = response?.data?.result?.invoiceMarkedPaid;
    yield put(setHotelItineraryInvoice({ isPaid, paymentOrigin }));

    yield put(putHotelInvoicePaidSuccess());
  } catch (error) {
    yield put(putHotelInvoicePaidFailure(error));
  }
}

/**
 * Saga to void a hotel payment
 * @memberOf Sagas.hotel.itinerary
 * @generator
 * @name onPutHotelInvoiceVoidTransaction
 * @returns {Generator<*, void, *>}
 */
function* onPutHotelInvoiceVoidTransaction({ paymentOrigin }) {
  try {
    yield put(putHotelInvoiceVoidTransactionRequest());

    const otherVoidReasonNotes = yield select(selectedOtherVoidReasonNotes);
    const voidReason = yield select(selectVoidTransactionReason);

    yield call(callHotelPaymentPut, {
      paymentOrigin,
      data: {
        action: VOID_TRANSACTION,
        reason: voidReason,
        ...(otherVoidReasonNotes && { details: otherVoidReasonNotes }),
      },
    });

    // This response is not a full itinerary so just update the invoice's status flag in the itinerary state
    yield put(setHotelItineraryStatus({ status: itinerariesPaymentStatuses.VOIDED }));
    yield put(hideCheckInOutModal());
    yield put(putHotelInvoiceVoidTransactionSuccess());
  } catch (error) {
    yield put(putHotelInvoiceVoidTransactionFailure(error));
  }
}

function* watchOnPutHotelInvoicePaid() {
  yield takeLatest(PUT_HOTEL_INVOICE_PAID, onPutHotelInvoicePaid);
}

function* watchOnPutHotelInvoiceVoidTransaction() {
  yield takeLatest(PUT_HOTEL_INVOICE_VOID_TRANSACTION, onPutHotelInvoiceVoidTransaction);
}

export default function* hotelItineraryPaymentSaga() {
  yield all([watchOnPutHotelInvoicePaid(), watchOnPutHotelInvoiceVoidTransaction()]);
}
