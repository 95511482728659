import React, { useEffect } from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { SelectField } from "@petsmart-ui/sparky";

// Actions
import { getAvailableReports, setSelectedReportType } from "./reportSelectionActions";
import { setSelectedFilters } from "@/dux/reports/reportsActions";

// Selectors
import { selectAvailableReportsList, selectSelectedReportType } from "./reportSelectionSelectors";
import { setSelectedReportDate } from "@/dux/reportDateSelection/reportDateSelectionActions";

// Utils
import moment from "moment";

/**
 *  React view component for report selection
 *  @memberOf Views.Associate
 *  @function
 *  @name ReportSelection
 *  @param {object} props
 *  @param {string} props.componentId
 *  @param {Function} props.fetchAvailableReports
 *  @param {string} props.isHidden
 *  @param {Array<{ label: string, value: string}>} props.reportsList
 *  @param {string} props.label
 *  @param {Function} props.onChange
 *  @param {string} props.selectedReportType
 *  @returns {JSX.Element}
 *
 *  @example const component = connect()(ReportSelection);
 */
export const ReportSelection = props => {
  const {
    componentId,
    fetchAvailableReports,
    isHidden,
    reportsList,
    label,
    onChange,
    selectedReportType,
  } = props;

  useEffect(() => {
    if (typeof fetchAvailableReports === "function" && !reportsList.length) fetchAvailableReports();
  }, []);

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <SelectField
        style={{ minWidth: "210px" }}
        id={`${componentId}__report--select`}
        label={label}
        items={reportsList}
        name="reportSelection"
        onChange={onChange}
        selectedValue={selectedReportType}
      />
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the ReportSelection
 * @summary Located on the /reports page
 * @memberOf Views.Associate
 * @function
 * @name ReportSelectionContainer
 * @example <ReportSelectionContainer />
 */
export const ReportSelectionContainer = connect(
  state => {
    const reportsList = selectAvailableReportsList(state);
    const selectedReportType = selectSelectedReportType(state);

    return {
      componentId: "ReportSelectionContainer",
      reportsList,
      label: "Select Report",
      selectedReportType,
    };
  },
  dispatch => ({
    fetchAvailableReports: () => {
      dispatch(getAvailableReports());
    },
    onChange: event => {
      dispatch(setSelectedReportType(event?.target.value));
      dispatch(setSelectedReportDate(moment()));
      dispatch(setSelectedFilters([]));
    },
  }),
)(ReportSelection);
