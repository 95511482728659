import { combineReducers } from "redux";
import withClearReducer from "../../../core/utils/withClearReducer";
import appointmentsInCartReducer from "./appointmentsInCartReducer";
import { CLEAR_PENDING_APPOINTMENT } from "../actions/pendingAppointmentActions";

const pendingAppointmentReducer = combineReducers({
  appointments: appointmentsInCartReducer,
});

export default withClearReducer(pendingAppointmentReducer, CLEAR_PENDING_APPOINTMENT);
