import * as React from 'react';
import { psx } from '../../utils/clsx';
import { Layout, BoxProps } from '../../components/Layout';
import List, { IdObj } from '../../components/List';
import Hover from '../../components/Hover';

import './styles.css';

export interface CardProps<T> extends BoxProps {
  /** An array of data containing the text or components in the "label" propety */
  rows: T[];
  /** Styles to apply on the card onHover */
  hoverStyle?: React.CSSProperties;
}

export interface cardIdObj extends IdObj {}

export const Card = <T extends cardIdObj>({
  rows,
  hoverStyle = {},
  isHidden = false,
  className,
  children,
  ...props
}: CardProps<T>) => {
  const classNames = psx('psm', 'psm-card', className);

  return (
    <Hover hoverStyle={hoverStyle}>
      <Layout.Box
        className={classNames}
        isHidden={isHidden}
        borderWidth="box-border-width-2"
        borderColor="box-border-color-gray-100"
        padding="box-padding-4"
        {...props}
      >
        <Layout.Sidebar contentMinWidth={props?.contentMinWidth} space="sidebar-space-4">
          {children}
          <Layout.Stack space="stack-space-2">
            <List items={rows} as={Layout.Box}>
              {(row) => <>{row.label}</>}
            </List>
          </Layout.Stack>
        </Layout.Sidebar>
      </Layout.Box>
    </Hover>
  );
};

export default Card;
