import { createSelector } from "reselect";
import getWorkflowFeatureFlagsList from "./enableDisableWorkflowFeatureFlagsList";
import { getProps } from "../../../core/selectors/commonSelector";

const getWorkflowFeatureFlagById = createSelector(
  [getWorkflowFeatureFlagsList, getProps],
  (workflowFeatureFlags, props) => {
    return workflowFeatureFlags.find(workflow => workflow.id === props.id);
  },
);

export default getWorkflowFeatureFlagById;
