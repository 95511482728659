import moment from "moment";

/**
 * Responsible for providing an array of the week based on a single date.
 *
 * @export getWeekFromDate
 * @param {Object} obj - Destructuring parameters
 * @param {Object} obj.date - Generally a moment object, but can be a string as well.
 * @returns {Array} - Days of the week
 */
export function getWeekFromDate({ date }) {
  const startOfWeek = moment(date).startOf("week");
  const week = [];
  for (let i = 0; i < 7; i++) {
    week.push(moment(startOfWeek).add(i, "day"));
  }

  return week.map(day => day.toDate());
}
