/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const setMedicationTypeAmountActionTypes = {
  SET_MEDICATION_TYPE_AMOUNT: "SET_MEDICATION_TYPE_AMOUNT",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setMedicationTypeAmount = ({ medicationTypeAmount, medicationId, petId }) => ({
  type: setMedicationTypeAmountActionTypes.SET_MEDICATION_TYPE_AMOUNT,
  medicationTypeAmount,
  medicationId,
  petId,
});
