import { get } from "lodash/fp";
import { createSelector } from "reselect";
import { getPrismCustomerHotelProfile } from "../petParentProfile/selectors/getPrismCustomerProfileSelector";
import { reasonCodes } from "dux/eligibility/constants/eligibilityConstants";

export const getHotelBookingAlertReasons = createSelector(
  [getPrismCustomerHotelProfile],
  hotelProfile => {
    const hotelBookingReasons = [];
    if (get("doNotBook", hotelProfile)) {
      const doNotBookInfo = {
        reasonCode: reasonCodes.DoNotBook,
        description: hotelProfile.doNotBookReason,
      };
      hotelBookingReasons.push(doNotBookInfo);
    }
    return hotelBookingReasons;
  },
);
