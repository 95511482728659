import { createSelector } from "reselect";
import {
  getPetHotelEngagements,
  selectAddonByPetAndAddonId,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { compose, uniqBy } from "lodash/fp";
import flattenArray from "@/core/utils/arrayUtils/flattenArray";

/**
 * Selector to get the pet service specials from all engagements of the given pet
 * @memberOf Selectors.HotelEngagements
 * @function
 * @name getHotelPetSpecials
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {string} props.petId - param to specify pet
 * @returns {string} the petServiceName
 * @example  getHotelPetSpecials(state, { petId })
 */
export const getHotelPetSpecials = createSelector([getPetHotelEngagements], engagements => {
  const allSpecials = engagements?.map(
    ({ petService } = {}) => petService?.pricing?.specials || [],
  );
  return compose(uniqBy("code"), flattenArray)(allSpecials ?? []);
});

/**
 *  Selector to get the pet addon specials from the itinerary for a given pet
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name selectPetAddonSpecialsById
 *  @param {string} addonId - id of addon to get specials from
 *  @returns {Array} the addon specials for a given pet
 *  @example
 *
 *  selectPetAddonSpecialsById(addonId)(state, { petId })
 */
export const selectPetAddonSpecialsById = addonId =>
  createSelector([selectAddonByPetAndAddonId(addonId)], addon => addon?.pricing?.specials ?? []);
