import React from "react";
import { connect } from "react-redux";
import { Text, Layout, Form } from "@prism/psm-ui-components";
import { LayoutCluster } from "@/layout/culster/Cluster";
import momentTz from "moment-timezone";
import {
  getStoreTimeZoneForHotelItinerary,
  selectCurrentHotelEngagement,
  selectIsCheckInTodayOrPast,
  selectIsCheckOutInPast,
  selectPetsWithEngagementsByHostPetId,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import {
  patchHotelItineraryRoom,
  showReservationCartDetailsConfirmation,
} from "dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import { getCheckInOutPetRoom } from "dux/_components/checkInOutAppointmentSummary/checkInOutAppointmentSelectors";
import { HotelApplySpecial } from "dux/applySpecial/ApplySpecialComponent";
import { getIsSRCAgent } from "@/core/selectors/persistentSelectors";
import { getPetHotelEngagements } from "@/dux/hotelEngagements/hotelEngagementSelectors";
import { isRoomAssignedEmptyForOvernightService } from "@/core/utils/checkInOutUtils";
import { selectIsReservationDisabled } from "@/dux/hotelItinerary/hotelItinerarySelectors";
import { HotelRoomSelection } from "@/dux/roomSelection/RoomSelection";
import { HotelCheckInOutServiceSelect } from "@/dux/primaryServiceSelection/PrimaryServiceSelection";
import { setSelectDate } from "@/dux/selectedDate/selectedDateActions";

const CheckInOutAppointmentSummaryComponent = ({
  isHidden,
  componentId,
  isUnassignedSelectionDisabled,
  isRoomAssignedEmpty,
  setUnassignedRoom,
  isUnassigned,
  roomTypeBucketId,
  engagementDate,
}) => {
  if (!isHidden) {
    return (
      <Layout.Box>
        <Layout.Stack id={componentId} space="stack-space-2" justify="left">
          <Layout.Cluster justify="space-between" space="cluster-space-4">
            <Text bold>{`Appointment Summary for ${engagementDate}`}</Text>
            <HotelApplySpecial />
          </Layout.Cluster>

          <HotelCheckInOutServiceSelect />

          <Layout.Cluster
            justify="flex-start"
            space="cluster-space-4"
            style={{ overflow: "visible" }}
          >
            <HotelRoomSelection roomTypeBucketId={roomTypeBucketId} />
          </Layout.Cluster>
          <LayoutCluster>
            <Form.Field.Checkbox
              id={`${componentId}__unassignRoom--checkbox`}
              checked={isUnassigned}
              disabled={isUnassignedSelectionDisabled}
              onClick={({ target: { checked } }) => {
                setUnassignedRoom(checked);
              }}
            />
            <Text
              color={isUnassignedSelectionDisabled ? "text-color-disabled" : "text-color-black"}
            >
              Mark pet as Unassigned
            </Text>
          </LayoutCluster>
          {isRoomAssignedEmpty && (
            <Layout.Box>
              <Text id={`${componentId}__roomNumber--error`} color="text-color-red">
                Room number must be assigned
              </Text>
            </Layout.Box>
          )}
        </Layout.Stack>
      </Layout.Box>
    );
  }

  return null;
};

export const CheckInOutAppointmentSummary = connect(
  state => {
    const petId = getCurrentPet(state);
    // Engagement for a particular day
    const selectedDate = getSelectedDate(state); // the date selected on the Dashboard
    const engagement = selectCurrentHotelEngagement(state, { petId, date: selectedDate });
    const engagementId = engagement?.engagementId;
    const isSRCAgent = getIsSRCAgent(state);
    const storeTimeZone = getStoreTimeZoneForHotelItinerary(state);
    const isEngagementStartDateSameOrBeforeToday = selectIsCheckInTodayOrPast(state, { petId });
    const isCheckOutInPast = selectIsCheckOutInPast(state, { petId });
    const isReservationDisabled = selectIsReservationDisabled(state);
    const isUnassignedSelectionDisabled =
      isSRCAgent ||
      isReservationDisabled ||
      !isEngagementStartDateSameOrBeforeToday ||
      isCheckOutInPast;
    const currentRoomDetails = getCheckInOutPetRoom(state, { engagementId });
    const isRoomAssignedEmpty = isRoomAssignedEmptyForOvernightService(
      currentRoomDetails?.roomNumber,
      currentRoomDetails?.isUnassigned,
      engagement?.engagementType,
    );
    // Pet Engagements for current pet
    const petEngagements = getPetHotelEngagements(state, { petId });
    // For same day or overnight - at least one engagement is available
    // hostPetId value is either current petId or host pet - petId
    // hostPetId will be same on all engagements for a given pet
    const currentHostPetId = petEngagements[0]?.hostPetId;
    const currentPetToUpdate = [{ petId, engagements: petEngagements }];
    // Fetch other room sharing pet/pets and their engagements by host petId
    const roomSharingPetsToUpdate = selectPetsWithEngagementsByHostPetId({
      hostPetId: currentHostPetId,
      petId,
    })(state);
    // Aggregate pet/pets to update
    const petsToUpdate =
      roomSharingPetsToUpdate.length > 0
        ? [...currentPetToUpdate, ...roomSharingPetsToUpdate]
        : currentPetToUpdate;
    const engagementDate =
      (engagement?.startDatetime &&
        momentTz.tz(engagement.startDatetime, storeTimeZone).format("MM-DD-YYYY")) ??
      "";

    return {
      componentId: "checkInOut__appointmentSummary",
      isUnassigned: !!currentRoomDetails?.isUnassigned,
      isUnassignedSelectionDisabled,
      isRoomAssignedEmpty,
      petsToUpdate,
      engagementId,
      roomTypeBucketId: currentRoomDetails?.roomTypeBucketId,
      storeTimeZone,
      engagementDate,
    };
  },
  dispatch => {
    return {
      setRoomNumber: ({ data }) => {
        dispatch(patchHotelItineraryRoom({ data }));
        // TODO: Remove below action across the board
        dispatch(showReservationCartDetailsConfirmation({ isHidden: false }));
      },
      setSelectedDate: () => dispatch(setSelectDate()),
    };
  },
  (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,

    setUnassignedRoom: (isUnassigned = false) => {
      const { setRoomNumber, setSelectedDate } = propsFromDispatch;
      const { roomTypeBucketId, petsToUpdate, storeTimeZone } = propsFromState;

      // Current or all pets to updates
      const pets = petsToUpdate.map(pet => {
        return {
          petKey: pet?.petId,
          engagements: pet?.engagements
            ?.map(({ engagementId, startDatetime, metadata }) => {
              const today = momentTz.tz(undefined, storeTimeZone);
              const startDate = momentTz.tz(startDatetime, storeTimeZone);
              const isInPast = startDate.isBefore(today, "day");
              const isSameRoomType =
                metadata?.room?.roomTypeBucketId?.toString() === roomTypeBucketId?.toString();

              if (isInPast || !isSameRoomType) return {};

              return {
                engagementId,
                metadata: {
                  room: {
                    roomTypeBucketId,
                    roomNumber: null,
                    isUnassigned,
                  },
                },
              };
            })
            .filter(engagement => !!engagement?.engagementId),
        };
      });

      setRoomNumber({ data: { pets } });
      setSelectedDate();
    },
  }),
)(CheckInOutAppointmentSummaryComponent);

export default CheckInOutAppointmentSummary;
