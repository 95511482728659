export const petFacts = [
  '58% of owners sing "Happy Birthday" to their dogs.',
  "Cats spend about 70% of their lives asleep.",
  "Puppies are born deaf.",
  "Soft rock & reggae music cause relaxed doggie behavior.",
  "Wild goldfish live an average of 41 years!",
  "Hamster cheek pouches extend to their hips!",
  "Dogs aren't colorblind.",
  "Hummingbird hearts can beat up to 1260 beats per minute.",
  "Dogs have a worse memory than cats.",
  "Dogs will sometimes fake illness for attention!",
  "Dalmatians are born without spots.",
  "Chinchilla fur is the softest in the world.",
  "Chinchillas bathe in dust.",
  "Unlike other rodents, chinchillas can live up to 20 years!",
  "At night, chinchillas use their whiskers to navigate.",
  "Chinchillas can jump up to six feet!",
  "Dogs have unique noseprints, like human fingerprints!",
  "Puppies dream!",
  "Dog yawns can be triggered by human yawns.",
  "Greyhound dogs can win a race against cheetahs.",
  "The world's smallest dog was 3.8' tall and one pound.",
  "\"Ferret\" means 'little thief' in Latin.",
  "Ferret hearts beat 200-250 beats per minute.",
  "Male cockatiels are better at whistling than females.",
  "Cockatiels have night frieghts, and a nightlight can help.",
  "Some snakes hibernate in the winter.",
  "Snakes can go months without food.",
  "Snakes have 600-1800 bones.",
  "Snakes in captivity have lived up to 170 years.",
  "Some snakes can glide 100 meters through the air.",
  "Ferrets dance when threatened",
  "Baby ferrets and chinchillas are both called kits.",
];

export const petEmojis = [
  "🐵",
  "🐒",
  "🐶",
  "🐕",
  "🦮",
  "🐕‍🦺",
  "🐩",
  "🦊",
  "🐱",
  "🦁",
  "🐯",
  "🐴",
  "🐮",
  "🐷",
  "🐹",
  "🐰",
  "🐻",
  "🐻‍❄️",
  "🐨",
  "🦦",
  "🐣",
  "🐥",
  "🦉",
  "🦜",
  "🐢",
  "🦎",
  "🐍",
  "🐠",
  "🐡",
];

export function getEmoji(x) {
  return petEmojis[x];
}

export function getFact(x) {
  return petFacts[x];
}
export function getRandomFact() {
  const x = Math.floor(Math.random() * (petFacts.length - 1));
  return petFacts[x];
}

export const petFactsSize = petFacts.length;
export const emojiSize = petEmojis.length;

// Sources: https://outwardhound.com/furtropolis/dogs/pet-facts-that-may-surprise-you#:~:text=PAWnder%20These%20Fascinating%20Pet%20Facts&text=Cats%20have%2032%20muscles%20in,owners%20celebrate%20their%20dog's%20birthday.&text=Cats%20have%20over%20one%20hundred,helps%20to%20wear%20them%20down.
// https://blog.directauto.com/savings-tips/fun-facts-about-pets/
// https://www.petinsurance.com/healthzone/ownership-adoption/pet-ownership/pet-owner-topics/fun-facts-about-pets/
// https://thecarousel.com/lifestyle/pets-and-outdoors/39-amazing-facts-about-pets/
// https://facts.net/chinchilla-facts/
// https://www.akc.org/expert-advice/lifestyle/dog-facts/
// https://www.fda.gov/animal-veterinary/animal-health-literacy/fun-ferret-facts
// https://petkeen.com/cockatiel-facts/
// https://www.everythingreptiles.com/21-snake-facts/
// https://facts.net/snake-facts/
// https://www.mentalfloss.com/article/62329/15-furry-ferret-facts-national-ferret-day
