import { createSelector } from "reselect";
import moment from "moment";
import { selectBookingDateRangeForFrequency } from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { selectCheckInOutDateRangeForFrequencyByPet } from "../hotelEngagements/hotelEngagementSelectors";
import { isFromBooking } from "@/core/utils/validationUtils/isFromValidation";
import { history } from "../utils/browser/browserHistory";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { frequencyConstants } from "./FrequencyConstants";
import { selectHotelAddOnById } from "../hotelAddons/hotelAddonsLIstSelectors";

export const selectDateRangeForFrequency = createSelector(
  [selectBookingDateRangeForFrequency, (state, props) => ({ state, props })],
  (bookingDateRange, { state, props: { isInBooking } = {} }) => {
    // isInBooking can be passed in for cases where history can't be used, like in tests
    if (isInBooking || isFromBooking(history.location.pathname)) return bookingDateRange;

    const petId = getCurrentPet(state);
    return selectCheckInOutDateRangeForFrequencyByPet(state, { petId });
  },
);

export const getCheckForSameDay = createSelector([selectDateRangeForFrequency], dates => {
  const firstDay = dates[0];
  const lastDay = dates?.at(-1);
  return moment(firstDay).isSame(moment(lastDay), "day");
});

export const selectDefaultFrequency = createSelector([getCheckForSameDay], isSameDay =>
  isSameDay ? frequencyConstants.DAILY : frequencyConstants.MANUAL,
);

/**
 * Selector to get default frequency for a given addon
 * @memberOf Selectors.HotelAddonsList
 * @function
 * @name selectAddonDefaultFrequency
 * @param {string} addonId - addOnProductNumber
 * @returns {Function} Selector
 * @example selectAddonDefaultFrequency(addonId)(state)
 */
export const selectAddonDefaultFrequency = addonId =>
  createSelector(
    [selectHotelAddOnById({ addonId }), selectDefaultFrequency],
    (addon, defaultFrequency) => addon?.frequency ?? defaultFrequency,
  );
