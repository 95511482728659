import moment from "moment";
import { get } from "lodash/fp";
import { createSelector } from "reselect";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { getProps } from "./commonSelector";

/**
 * A simple selector that returns the store hours for the week from the redux store
 * @param {*} state
 * @returns {object} Store Hours { [date]:{...},[date]:{...}, ... }
 */
export const getSalonHours = state => state?.salonHours;

export const getIsSalonClosed = createSelector(
  [getSalonHours, getSelectedDate, getProps],
  (salonHours, selectedDate, props) => {
    const currentSelectedDate = props?.selectedDate || selectedDate;
    return get([currentSelectedDate, "IsClosed"], salonHours);
  },
);

// Returns an object with a week's worth of YYYY-MM-DD keys and values that are either
// "Store Closed" or formatted hours: "7:00 AM - 9:00 PM".
export const getSalonHoursRange = createSelector([getSalonHours], salonHours => {
  const dates = Object.keys(salonHours ?? {});
  const dateTimeFormatted = dates.map(day => {
    const hoursByDay = salonHours[day];
    const { OpenDateTime, CloseDateTime, IsClosed } = hoursByDay;
    const value = IsClosed
      ? "Store Closed"
      : `${moment(OpenDateTime).format("LT")} - ${moment(CloseDateTime).format("LT")}`;

    return { [day]: value };
  });

  return Object.assign({}, ...dateTimeFormatted);
});
