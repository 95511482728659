import React from "react";

export default function withWidthOnResize(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        width: 0
      };

      this.widthRef = React.createRef();
      this.updateWidth = this.updateWidth.bind(this);
    }

    componentDidMount() {
      this.updateWidth();
      window.addEventListener("resize", this.updateWidth);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateWidth);
    }

    updateWidth() {
      const currentWidth = this.widthRef.current ? this.widthRef.current.offsetWidth : 0;
      this.setState({
        width: currentWidth
      });
    }

    render() {
      const {
        widthRef,
        state: { width: currentWidth },
        props
      } = this;
      return <WrappedComponent widthRef={widthRef} currentWidth={currentWidth} {...props} />;
    }
  };
}
