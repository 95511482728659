import { getState } from "@/core/selectors/commonSelector";
import { createSelector } from "reselect";

export const selectSearchAddressByZipState = createSelector(
  [getState],
  state => state?.searchAddressByZip ?? {},
);

/**
 * Selector to get address from searchAddressByZip from the Redux State
 * @memberOf Selectors.searchAddressByZip
 * @function
 * @name selectSearchAddressByZipAddress
 * @param {Object } state - redux state
 * @returns {Object}
 * @example selectSearchAddressByZipAddress(state)
 */
export const selectSearchAddressByZipAddress = createSelector(
  [selectSearchAddressByZipState],
  ({ address }) => address,
);

/**
 * Selector to get id of form to be updated with zip search results
 * @memberOf Selectors.searchAddressByZip
 * @function
 * @name selectZipSearchFormToUpdate
 * @param {Object } state - redux state
 * @returns {Object}
 * @example selectZipSearchFormToUpdate(state)
 */
export const selectZipSearchFormToUpdate = createSelector(
  [selectSearchAddressByZipState],
  ({ formToUpdate }) => formToUpdate,
);

/**
 * Selector to get array of cities returned by the zip search
 * @memberOf Selectors.searchAddressByZip
 * @function
 * @name selectZipSearchCityOptions
 * @param {Object } state - redux state
 * @returns {Object}
 * @example selectZipSearchCityOptions(state)
 */
export const selectZipSearchCityOptions = createSelector(
  [selectSearchAddressByZipState],
  ({ cityOptions = [] }) => cityOptions,
);
