import { createSelector } from "reselect";
import { getPrismCustomerHotelProfile } from "web/petParentProfile/selectors/getPrismCustomerProfileSelector";
import moment from "moment";

/**
 * Selector to get hotel customer notes
 * @memberOf Selectors.Entities
 * @function
 * @name getHotelCustomerNotes
 * @param {Object} state - redux state
 * @returns {Array} notes data
 * @example getHotelCustomerNotes(state, { customerKey })
 */
export const getHotelCustomerNotes = createSelector(
  [getPrismCustomerHotelProfile],
  customerHotelProfile => {
    if (!customerHotelProfile?.notes) return [];

    return customerHotelProfile?.notes
      .map(item => ({
        ...item,
        formatedTime: moment(item.timestamp).format("MMMM Do YYYY, h:mm a"),
      }))
      .sort((a, b) => moment.utc(a.timestamp).diff(moment.utc(b.timestamp)));
  },
);
