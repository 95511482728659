import serviceStatusesActionTypes from "../actionTypes/serviceStatusesActionTypes";

export const loadStatuses = () => ({
  type: serviceStatusesActionTypes.LOAD_STATUSES
});

export const loadStatusesRequest = () => ({
  type: serviceStatusesActionTypes.LOAD_STATUSES_REQUEST
});

export const loadStatusesSuccess = ({ statuses }) => ({
  type: serviceStatusesActionTypes.LOAD_STATUSES_SUCCESS,
  statuses
});

export const loadStatusesFailure = ({ error }) => ({
  type: serviceStatusesActionTypes.LOAD_STATUSES_FAILURE,
  error
});

export default {
  loadStatuses,
  loadStatusesRequest,
  loadStatusesSuccess,
  loadStatusesFailure
};
