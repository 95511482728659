import React from "react";
import styled from "styled-components";
import { Layout, Text, psx } from "@prism/psm-ui-components";

import "./styles.css";

const Img = styled.img`
  width: 44px;
  height: 44px;
  margin-bottom: 20px;
`;

const SalonServicesGridButtonComponent = ({
  isSelected,
  onServiceClick,
  imageUrl,
  name,
  price,
  componentId,
}) => {
  const borderColorClasses = psx({
    "box-border-color-gray-100": !isSelected,
    "box-border-color-blue-200": isSelected,
  });
  const fontColorClasses = psx({
    "text-color-blue": isSelected,
  });

  return (
    <Layout.Box
      id={componentId}
      className="psm-salon-services-grid-button"
      borderWidth="box-border-width-2"
      padding="box-padding-7"
      borderColor={borderColorClasses}
      onClick={onServiceClick}
    >
      <Layout.Center>
        <Layout.Stack>
          <Layout.Center>
            <Img id={`${componentId}__img`} src={imageUrl} />
          </Layout.Center>
          <Text
            id={`${componentId}__name--${name?.replace(/\s/g, "")}`}
            bold
            color={fontColorClasses}
            align="center"
            size="text-size-sm"
          >
            {name}
          </Text>
          <Text
            id={`${componentId}__price--${price}`}
            bold
            color={fontColorClasses}
            align="center"
            size="text-size-sm"
          >
            ${price}
          </Text>
        </Layout.Stack>
      </Layout.Center>
    </Layout.Box>
  );
};

export default SalonServicesGridButtonComponent;
