import { combineReducers } from "redux";
import ddcRoomCapacityModalReducer from "dux/ddcRoomCapacityModal/ddcRoomCapacityModalReucer";
import hotelRoomCapacityModalReducer from "@/dux/hotelRoomCapacityModal/hotelRoomCapacityModalReducer";
import hotelManageRoomDetailsSuspensionHistoryReducer from "@/dux/hotelManageRoomDetailsSuspensionHistory/hotelManageRoomDetailsSuspensionHistoryReducer";
import refundPackageConfirmationModalReducer from "@/dux/refundPackageConfirmationModal/refundPackageConfirmationModalReducer";
import refundPackageErrorModalReducer from "@/dux/refundPackageErrorModal/refundPackageErrorModalReducer";
import scheduleSuspensionHistoryModalReducer from "dux/scheduleSuspensionHistoryModal/scheduleSuspensionHistoryModalReducer";
import scheduleSuspensionCancelModalReducer from "dux/scheduleSuspensionCancelModal/scheduleSuspensionCancelModalReducer";
import hotelOverbookingExceptionsHistoryReducer from "@/dux/hotelOverbookingExceptionsHistory/hotelOverbookingExceptionsHistoryReducer";
import bookingModalsReducer from "./bookingModalsReducer";
import dashboardModalsReducer from "./dashboardModalsReducer";
import associatesModalsReducer from "./associatesModalsReducer";
import authModalsReducer from "./authModalsReducer";
import checkInOutModalsReducer from "./checkInOutModalsReducer";
import checkInOutButtonModalsReducer from "./checkInOutButtonModalsReducer";
import confirmationModalReducer from "../confirmationModalReducer";
import petParentProfileModalsReducer from "./petParentProfileModalsReducer";
import petProfileModalsReducer from "./petProfileModalsReducer";
import schedulesModalsReducer from "./schedulesModalsReducer";
import appHeaderModalsReducer from "./appHeaderModalsReducer";
import cancelAppointmentModalsReducer from "./cancelAppointmentModalsReducer";
import { overbookingModalsReducer } from "@/dux/overbookingModals/overbookingModalsReducer";

const modalsReducer = combineReducers({
  bookingModal: bookingModalsReducer,
  dashboardModal: dashboardModalsReducer,
  associateModal: associatesModalsReducer,
  authModal: authModalsReducer,
  checkInOutModal: checkInOutModalsReducer,
  checkInOutButtonModal: checkInOutButtonModalsReducer,
  confirmationModal: confirmationModalReducer,
  petParentProfile: petParentProfileModalsReducer,
  petProfile: petProfileModalsReducer,
  schedules: schedulesModalsReducer,
  appHeader: appHeaderModalsReducer,
  cancelAppointmentModal: cancelAppointmentModalsReducer,
  ddcRoomCapacityModal: ddcRoomCapacityModalReducer,
  hotelRoomCapacityModal: hotelRoomCapacityModalReducer,
  hotelRoomSuspensionsHistoryModal: hotelManageRoomDetailsSuspensionHistoryReducer,
  hotelOverbookingExceptionsHistoryModal: hotelOverbookingExceptionsHistoryReducer,
  refundPackageConfirmationModal: refundPackageConfirmationModalReducer,
  refundPackageErrorModal: refundPackageErrorModalReducer,
  suspensionScheduleHistory: scheduleSuspensionHistoryModalReducer,
  suspensionScheduleCancel: scheduleSuspensionCancelModalReducer,
  overbooking: overbookingModalsReducer
});

export default modalsReducer;
