import { connect } from "react-redux";
import { Text } from "@prism/psm-ui-components";
import { NO_APPOINTMENTS_AVAILABLE_COPY } from "@/dux/trainingClassAvailability/trainingClassAvailabilityConstants";
import { getAvailableTrainingTimeSlotsForSelectedDate } from "@/dux/trainingClassAvailability/trainingClassAvailabilitySelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";

const mapStateToProps = state => {
  const storeNumber = getStoreNumber(state);
  const availableTimeSlots = getAvailableTrainingTimeSlotsForSelectedDate(state, { storeNumber });
  return {
    isHidden: availableTimeSlots.length,
    children: NO_APPOINTMENTS_AVAILABLE_COPY,
    size: "text-size-lg",
  };
};

export default connect(mapStateToProps)(Text);
