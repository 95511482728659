import { PHONE_NUMBER_REGEX } from "../../constants/regex";

/** @module phoneNumberValidation */

/**
 * Verify if a phone number is valid
 *
 * @exports verifyPhoneNumber
 * @param {number} phone
 * @return A Boolean if a phone number is valid or not
 */
export default function verifyPhoneNumber(phone) {
  return PHONE_NUMBER_REGEX.test(phone);
}
