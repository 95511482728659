/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_BULK_PACKAGE_OFFERINGS_DROPDOWN = "SET_BULK_PACKAGE_OFFERINGS_DROPDOWN";
export const SET_BULK_HISTORY_REDEMPTIONS_DROPDOWN = "SET_BULK_HISTORY_REDEMPTIONS_DROPDOWN";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

export const toggleBulkPackageOfferingsDropdown = selectedBulkPackageOfferingsDropdown => ({
  type: SET_BULK_PACKAGE_OFFERINGS_DROPDOWN,
  payload: selectedBulkPackageOfferingsDropdown,
});

export const toggleBulkHistoryRedemptionsDropdown = selectedBulkHistoryDropdown => ({
  type: SET_BULK_HISTORY_REDEMPTIONS_DROPDOWN,
  payload: selectedBulkHistoryDropdown,
});
