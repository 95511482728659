import * as React from 'react';
import classnames from 'classnames';
import { Layout } from "../Layout";
import './textInput.css';


// The properties listed in this interface are primarily here for documentation reasons in storybook
export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * CSS class names that can be appended to the component.
   */
  className?: string;

  /**
   * String that describes a type of file that may be selected by the user
   * https://developer.mozilla.org/en-US/docs/Web/*HTML/Element/input/file#Unique_file_type_specifiers
   */
  accept?: string;
  /** HTML id of the helper text used to describe the component */
  ariaDescribedBy?: string;
  autocomplete?: string;
  /** Disables the field and prevents editing the contents */
  disabled?: boolean;
  /** Gives a hint as to the type of data needed for text input */
  inputMode?:
    | 'text'
    | 'email'
    | 'url'
    | 'search'
    | 'tel'
    | 'none'
    | 'numeric'
    | 'decimal';
  /** Maximum number the input can take. When this number equals the input value, the plus button becomes disabled. */
  max?: number;
  /** Max number of characters for the text input */
  maxLength?: number;
  /** Minimum number the input can take. When this number equals the input value, the minus button becomes disabled. */
  min?: number;
  /** Multiple is a boolean to allow multiple files to be uploaded */
  multiple?: boolean;
  /**
   * HTML name attribute for the input
   */
  name?: string;
  /**
   * Function that fires when field value has changed
   */
  onChange?: React.ChangeEventHandler;
  /**
   * Input pattern
   */
  pattern?: string;
  /**
   * Placeholder attribute for input. Note: placeholder should be used sparingly
   */
  placeholder?: string;
  /**
   * Toggles the form control's interactivity. When `readOnly` is set to `true`, the form control is not interactive
   */
  readOnly?: boolean;
  /**
   * Indicates that field is required for form to be successfully submitted
   */
  required?: boolean;
  /**
   * Title attribute on input
   */
  title?: string;
  /**
   * HTML type attribute, allowing switching between text, password, and other HTML5 input field types
   */
  type?:
    | 'text'
    | 'password'
    | 'datetime'
    | 'datetime-local'
    | 'date'
    | 'file'
    | 'month'
    | 'time'
    | 'week'
    | 'number'
    | 'email'
    | 'url'
    | 'search'
    | 'tel';
  /**
   * The value of the input
   */
  value?: string | number;
  /**
   * The default value of the input
   */
  defaultValue?: string | number;
}

/*
 * React components hide their implementation details, including their rendered output.
 * The user of this component may need to access the input DOM node managing focus, selection, or
 * animations.
 * We use React.forwardRef FN to obtain the ref passed to it, and then forward it to the DOM input that
 * this component renders
 *
 * - https://reactjs.org/docs/forwarding-refs.html
 */

/**
 * An input component that will stretch to to 100% of it's container by default
 */
const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({
     className,
     disabled,
     autocomplete,
     ariaDescribedBy,
     id,

     ...other}, ref) => {

    const componentClassName = classnames('psm-textInput', className, {
      'psm-textInput--disabled': disabled,
    });
    return (
      <Layout.Switcher id={id}>
        <input
          disabled={disabled}
          autoComplete={autocomplete}
          className={componentClassName}
          aria-describedby={ariaDescribedBy}
          ref={ref}
          {...other}
        />
      </Layout.Switcher>
    )
  }
)

//used mainly within react error messaging, valuable for debugging. Not needed if using ES6 here "just in case"
TextInput.displayName = 'TextInput';

export default TextInput;
