import { connect } from "react-redux";
import { Text } from "@prism/psm-ui-components";
import getIsAddressEditable from "@/core/selectors/customer/address/getIsAddressEditable";
import { ADDRESS_NOTIFICATION_MESSAGE } from "@/dux/customerAddresses/customerAddressConstants";

export default connect((state, { customerKey, addressId }) => {
  return {
    isHidden: getIsAddressEditable(state, { customerKey, addressId }),
    componentId: "address-notification-message",
    children: ADDRESS_NOTIFICATION_MESSAGE,
    style: { marginBottom: "1.5rem" },
  };
})(Text);
