export const systemName = {
  SALON: "Salon",
  HOTEL: "Hotel",
  TRAINING: "Training",
  DDC_HOTEL: "DDC/Hotel",
};

export const engagementTypes = {
  GROOMING: "Grooming",
  DAY_CAMP: "Day Camp",
  OVERNIGHT_BOARDING: "Overnight Boarding",
  TRAINING: "Virtual Pet Training",
  DAY_CARE: "Day Care",
};

export const engagementTypeIds = {
  GROOMING: 100,
  DAY_CAMP: 200,
  DAY_CARE: 220,
  OVERNIGHT_BOARDING: 210,
  TRAINING: 300,
  VIRTUAL_PET_TRAINING: 310,
};
