import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import AttentionIcon from "@/assets/icons/attention.svg";
import WarningIcon from "@/assets/icons/warning.svg";
import InactiveIcon from "@/assets/icons/inactive.svg";

// Components
import { Button, Layout, Text } from "@prism/psm-ui-components";

// Helpers
import { history } from "dux/utils/browser/browserHistory";

// RoomManagementGridCard Component
export const RoomManagementGridCard = props => {
  const {
    cardMessage,
    componentId,
    handleOnClick,
    manageRoomLabel,
    roomNumber,
    statusIcon,
    textId,
  } = props;

  return (
    <Layout.Box
      id={componentId}
      style={{ minHeight: "150px" }}
      borderWidth
      borderColor="box-border-color-gray-100"
      padding="box-padding-4"
    >
      <Layout.Stack space="stack-space-8">
        <Layout.Cluster justify="space-around">
          <Text id={textId}>{`Room ${roomNumber}`}</Text>
          <Button variant="prism-primary" onClick={() => handleOnClick(roomNumber)}>
            {manageRoomLabel}
          </Button>
        </Layout.Cluster>

        <Layout.Cluster>
          {statusIcon && (
            <img src={statusIcon} alt={statusIcon} style={{ width: "24px", marginRight: "10px" }} />
          )}
          <Text>{cardMessage}</Text>
        </Layout.Cluster>
      </Layout.Stack>
    </Layout.Box>
  );
};

// HotelRoomManagementGridCard Container
export const HotelRoomManagementGridCard = connect(
  state => {
    return {
      componentId: "HotelRoomManagementGridCard",
      manageRoomLabel: "Manage Room",
    };
  },

  dispatch => {
    return {
      handleOnClick: roomNumber => {
        history.push(`/room-details/${roomNumber}`);
        // TODO: Set selected room card in store if needed
        // dispatch(setRoomCardSelected(roomCard));
      },
    };
  },
  (mapStateToProps, mapDispatchToProps, props) => {
    const { componentId, manageRoomLabel } = mapStateToProps;
    const { handleOnClick } = mapDispatchToProps;
    const { index, room } = props;

    const { roomNumber, suspensionDetails } = room;

    let cardMessage = "No inactivation is currently scheduled";
    let statusIcon;

    if (suspensionDetails.length && suspensionDetails[0].status === "Scheduled") {
      const suspendedEndDate = moment(suspensionDetails[0].endDate).format("l");
      const suspendedStartDate = moment(suspensionDetails[0].startDate).format("l");

      const isFutureDate = moment(suspendedStartDate).isAfter();
      const isOverCapactiy = suspensionDetails[0].alerts[0]?.alertType === "capacity";
      const isCurrentlyActive = !isFutureDate;

      if (isFutureDate) {
        cardMessage = `Inactive in future from ${suspendedStartDate} until ${suspendedEndDate}`;
        statusIcon = WarningIcon;
      }

      if (isFutureDate && isOverCapactiy) {
        cardMessage = `Inactive in future and over capacity`;
        statusIcon = InactiveIcon;
      }

      if (isCurrentlyActive) {
        cardMessage = `Currently inactived until ${suspendedEndDate}`;
        statusIcon = AttentionIcon;
      }
    }

    return {
      cardMessage,
      componentId: `${componentId}__${index}`,
      handleOnClick,
      manageRoomLabel,
      roomNumber,
      statusIcon,
      textId: `${componentId}__${index}__${roomNumber}`,
    };
  },
)(RoomManagementGridCard);
