/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const foodListActionTypes = {
  SHOW_HIDE_FOOD_DETAILS: "SHOW_HIDE_FOOD_DETAILS",
  REMOVE_PET_FOOD_FROM_BOOKING: "REMOVE_PET_FOOD_FROM_BOOKING",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const showHideFoodDetails = ({ locationTypes, foodId, petId }) => ({
  type: foodListActionTypes.SHOW_HIDE_FOOD_DETAILS,
  locationTypes,
  foodId,
  petId,
});

export const removePetFoodFromBooking = ({ foodId, petId }) => ({
  type: foodListActionTypes.REMOVE_PET_FOOD_FROM_BOOKING,
  foodId,
  petId,
});
