import { connect } from "react-redux";
import { createLoadingSelector } from "../../../../../core/selectors/utils";
import itinerariesActionTypes from "../../../../../core/actionTypes/itinerariesActionTypes";
import petsActionTypes from "../../../../../core/actionTypes/petsActionTypes";
import customersActionTypes from "../../../../../core/actionTypes/customersActionTypes";
import { getIsCustomerAppointmentListEmpty } from "../../../../../core/selectors/entitiesSelector";
import { getCurrentCustomerKey } from "../../../../../core/selectors/persistent/customer/customerSelectors";
import NoAppointmentsComponent from "./NoAppointmentsComponent";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  return {
    isLoading: createLoadingSelector([
      itinerariesActionTypes.LOAD_ITINERARIES_BY_CUSTOMER,
      petsActionTypes.LOAD_PETS_BY_CUSTOMER,
      customersActionTypes.LOAD_CUSTOMER,
    ])(state),
    isHidden: !getIsCustomerAppointmentListEmpty(state, { customerKey }),
    componentID: "NoAppointments",
  };
};

export default connect(mapStateToProps)(NoAppointmentsComponent);
