import { connect } from "react-redux";
import UnderlinedTab from "../../../../common/tabs/underlinedTabs/UnderlinedTab";
import { setActiveTab } from "../appointmentsListActions";
import { tabFilters } from "../appointmentsListConstants";
import { getSelectedTab } from "../appointmentsListSelectors";

const mapStateToProps = state => ({
  isActive: getSelectedTab(state) === tabFilters.OTHER_SERVICES,
  componentID: "OtherServicesListTab",
  label: "Other Purchases",
});

const mapDispatchToProps = dispatch => ({
  handleClick: () => {
    dispatch(setActiveTab({ name: tabFilters.OTHER_SERVICES }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UnderlinedTab);
