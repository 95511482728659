/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const toggleAccordionActionTypes = {
  SHOW_STANDALONE_LIST: "SHOW_STANDALONE_LIST",
  SHOW_BULK_PACKAGE_OFFERINGS: "SHOW_BULK_PACKAGE_OFFERINGS",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const toggleStandaloneAccordion = showStandaloneList => ({
  type: toggleAccordionActionTypes.SHOW_STANDALONE_LIST,
  showStandaloneList,
});

export const toggleBulkPackageOfferingsAccordion = showBulkPackageOfferings => ({
  type: toggleAccordionActionTypes.SHOW_BULK_PACKAGE_OFFERINGS,
  showBulkPackageOfferings,
});
