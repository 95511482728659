import { omitBy, isNil } from "lodash/fp";

/** @module removeEmpty */

/**
 * A Utils function to remove empty values from an object
 *
 * @exports removeEmpty
 * @param {object} obj1 - Object Literal - Base object
 * @returns {Object}
 */
export default function removeEmpty(obj) {
  return omitBy(value => isNil(value) || value === "")(obj);
}
