import React from "react";
import BundleHistoryContainer from "../../../../../dux/bgm/bundleHistory/BundleHistoryContainer";

const OtherServicesHistory = ({ isHidden, componentID }) => {
  if (!isHidden) {
    return (
      <div id={componentID}>
        <BundleHistoryContainer />
      </div>
    );
  }

  return null;
};

export default OtherServicesHistory;
