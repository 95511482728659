export const GET_BULK_PACKAGE_ADDONS = "GET_BULK_PACKAGE_ADDONS";
export const GET_BULK_PACKAGE_ADDONS_REQUEST = "GET_BULK_PACKAGE_ADDONS_REQUEST";
export const GET_BULK_PACKAGE_ADDONS_SUCCESS = "GET_BULK_PACKAGE_ADDONS_SUCCESS";
export const GET_BULK_PACKAGE_ADDONS_FAILURE = "GET_BULK_PACKAGE_ADDONS_FAILURE";
export const SET_SELECTED_BULK_PACKAGE_ADDONS = "SET_SELECTED_BULK_PACKAGE_ADDONS";
export const CLEAR_SELECTED_BULK_PACKAGE_ADDON = "CLEAR_SELECTED_BULK_PACKAGE_ADDON";
export const SET_SELECTED_ADDON = "SET_SELECTED_ADDON";

export const getBulkPackageAddOns = (petServiceId, bundleConfigId) => ({
  type: GET_BULK_PACKAGE_ADDONS,
  petServiceId,
  bundleConfigId,
});

export const getBulkPackageAddOnsRequest = () => ({
  type: GET_BULK_PACKAGE_ADDONS_REQUEST,
});

export const getBulkPackageAddOnsSuccess = () => ({
  type: GET_BULK_PACKAGE_ADDONS_SUCCESS,
});

export const getBulkPackageAddOnsFailure = ({ error }) => ({
  type: GET_BULK_PACKAGE_ADDONS_FAILURE,
  error,
});

export const setSelectedBulkPackageAddOns = ({ petId, productBundleConfigId, addOns }) => {
  return {
    type: SET_SELECTED_BULK_PACKAGE_ADDONS,
    petId,
    productBundleConfigId,
    addOns,
  };
};

export const setSelectedAddOn = addOnId => ({
  type: SET_SELECTED_ADDON,
  addOnId,
});

export const clearSelectedBulkPackageAddOn = () => ({
  type: CLEAR_SELECTED_BULK_PACKAGE_ADDON,
});
