import { createSelector } from "reselect";
import moment from "moment";
import { isEmpty } from "lodash/fp";

import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { getIsTargetDate } from "@/dux/utils/dateUtils/getIsTargetDate";
import {
  getBreakByDateAndAssociate,
  getItineraries,
  getEngagements,
  getPetServiceItems,
  getAssociates,
  getPet,
} from "./entitiesSelector";

import { DEFAULT_STAGGER } from "../constants/dashboardContants";
import getHourOptions from "../utils/dateUtils/getHourOptions";
import { getSelectedAssociateWeekly, getSelectedTime, getState, getProps } from "./commonSelector";

import { getPetServiceFromAppointmentByPet, getAllCartAppointments } from "./cartSelectors";

import {
  getAssociateShiftTimeByDate,
  getStateSliceForSchedules,
  getAssociateDayActivities,
} from "./schedulesSelectors";
import { createDeepEqualSelector } from "./utils";
import sortObject from "../utils/objectUtils/sortObject";
import { dayActivityTypes } from "../constants/schedulesConstants";

export const getBooking = state => state.ui.web.booking;
export const getSelectedPet = state => state.ui.web.booking.selectedPet;
export const getPetIdToRemoveFromCart = state => state.ui.web.booking.petIdToRemoveFromCart;
export const getSelectedItinerary = state => state.ui.web.booking.itineraryId;
export const getAvailableTimeSlots = state => getBooking(state).availableTimeSlots;
export const getHoveredTimeSlot = state => getBooking(state).hoveredTimeSlot;
export const getNewAppointmentTimeSlot = state => state.ui.web.booking.newAppointmentTimeSlot;
export const getBookingPetServiceItem = state => state.ui.web.booking.petServiceItemId;
export const getShowAdditionalAppointmentBeforeBreak = state =>
  getBooking(state).additionalAppointmentBeforeBreak;
export const getShowAdditionalAppointmentAfterBreak = state =>
  getBooking(state).additionalAppointmentAfterBreak;

export const getSelectedPetDetails = createSelector([getSelectedPet, getState], (petId, state) => {
  return getPet(state, { petId });
});

export const getDateOfFirstPetServiceItem = createSelector(
  [getSelectedItinerary, getItineraries, getPetServiceItems],
  (selectedItinerary, itineraries, petServiceItems) => {
    if (!selectedItinerary) {
      return undefined;
    }

    const itinerary = itineraries[selectedItinerary];

    if (!itinerary) {
      return undefined;
    }

    const { petServiceItems: itineraryPetServiceItems } = itinerary;

    if (!itineraryPetServiceItems || !itineraryPetServiceItems.length) {
      return undefined;
    }

    const firstPetServiceItem = petServiceItems[itineraryPetServiceItems[0]];

    return firstPetServiceItem && firstPetServiceItem.startDateTime;
  },
);

export const getIsDateChangingDisabled = createSelector(
  [getSelectedItinerary, getItineraries, getEngagements, getSelectedPet],
  (selectedItinerary, itineraries, engagements, selectedPet) => {
    if (!selectedItinerary) {
      return false;
    }

    if (!itineraries[selectedItinerary]) {
      return false;
    }

    const { engagements: itineraryEngagements } = itineraries[selectedItinerary];

    if (!itineraryEngagements || !itineraryEngagements.length) {
      return false;
    }

    if (itineraryEngagements.length > 1) {
      return true;
    }
    const engagement = engagements[itineraryEngagements[0]];

    if (engagement && selectedPet !== engagement.pet) {
      return true;
    }

    return false;
  },
);

export const getCanFinalizeBooking = createSelector([getAllCartAppointments], appointments => {
  if (!appointments.length) {
    return {
      value: false,
      description: "There are no appointments.",
    };
  }

  if (appointments.find(appointment => !appointment.timeSlot)) {
    return {
      value: false,
      description: "Please schedule all pets to proceed.",
    };
  }

  return {
    value: true,
    description: "",
  };
});

export const getAvailableTimeSlotsByAssociate = createSelector(
  [getAvailableTimeSlots],
  availableTimeSlots => {
    const availableTimeSlotsByAssociate = {};

    availableTimeSlots.forEach(timeSlot => {
      const currentAppointments = availableTimeSlotsByAssociate[timeSlot.associateId];

      availableTimeSlotsByAssociate[timeSlot.associateId] = currentAppointments
        ? [...currentAppointments, timeSlot]
        : [timeSlot];
    });

    return availableTimeSlotsByAssociate;
  },
);

export const getAvailableTimeSlotsWeekly = createSelector(
  [getAvailableTimeSlots, getSelectedAssociateWeekly, getSelectedDate],
  (availableTimeSlots, selectedAssociateWeekly, selectedDate) => {
    if (!selectedAssociateWeekly) {
      return {};
    }

    const availableTimeSlotsWeekly = {};

    availableTimeSlots.forEach(timeSlot => {
      if (
        timeSlot.associateId === selectedAssociateWeekly &&
        moment(timeSlot.startDateTime).isBetween(
          moment(selectedDate).startOf("isoWeek"),
          moment(selectedDate).endOf("isoWeek"),
          "[]",
        )
      ) {
        const dateKey = moment(timeSlot.startDateTime).format("YYYY-MM-DD");
        availableTimeSlotsWeekly[dateKey] = availableTimeSlotsWeekly[dateKey]
          ? [...availableTimeSlotsWeekly[dateKey], timeSlot]
          : [timeSlot];
      }
    });

    return availableTimeSlotsWeekly;
  },
);

export const getTimeGroupsByDate = createDeepEqualSelector(
  [getAvailableTimeSlots],
  availableTimeSlots => {
    const format = "h:mm A";
    const availableTimeSlotsByDate = {};

    availableTimeSlots.forEach(timeSlot => {
      const dateKey = moment(timeSlot.startDateTime).format(format);
      availableTimeSlotsByDate[dateKey] = availableTimeSlotsByDate[dateKey]
        ? [...availableTimeSlotsByDate[dateKey], timeSlot]
        : [timeSlot];
    });

    return sortObject(
      availableTimeSlotsByDate,
      (dateKeyA, dateKeyB) => {
        if (moment(dateKeyA, format).isBefore(moment(dateKeyB, format))) {
          return -1;
        }
        if (moment(dateKeyA, format).isAfter(moment(dateKeyB, format))) {
          return 1;
        }
        return 0;
      },
      false,
    );
  },
);

export const getHourOptionsByBreak = createSelector(
  [
    getBreakByDateAndAssociate,
    getAssociateShiftTimeByDate,
    getAvailableTimeSlotsByAssociate,
    getPetServiceFromAppointmentByPet,
    (state, props) => props,
  ],
  (currentBreak, shift, availableTimeSlots, service, { associateId }) => {
    const hourOptionsBeforeBreak = getHourOptions({
      startDate: shift && shift.shiftStartDateTime,
      endDate: currentBreak ? currentBreak.startDate : shift && shift.shiftEndDateTime,
      stagger: DEFAULT_STAGGER,
      availableTimeSlots: availableTimeSlots[associateId],
      service,
    });
    const hourOptionsAfterBreak = currentBreak
      ? getHourOptions({
          startDate: currentBreak.endDate,
          endDate: shift && shift.shiftEndDateTime,
          stagger: DEFAULT_STAGGER,
          availableTimeSlots: availableTimeSlots[associateId],
          service,
        })
      : [];

    return { hourOptionsBeforeBreak, hourOptionsAfterBreak };
  },
);

export const getAvailableStandaloneAssociates = createSelector(
  [getStateSliceForSchedules, getAssociates, getSelectedDate, getSelectedTime],
  (state, associates, selectedDate, selectedTime) => {
    const result =
      associates &&
      Object.keys(associates).filter(associateId => {
        const time = moment(selectedTime, "HH:mm").format("HH:mm:ss");
        const formattedTime = moment(time, "HH:mm:ss");
        const activities = getAssociateDayActivities(state, { associateId, selectedDate });

        // Find if current time is within work schedule
        const workSchedules = activities.filter(activity => {
          const startTime = moment(activity.startTime, "HH:mm:ss");
          const endTime = moment(activity.endTime, "HH:mm:ss");

          return (
            (dayActivityTypes.WORK === activity.type ||
              activity.type === dayActivityTypes.RELOCATION) &&
            formattedTime.isBetween(startTime, endTime, "minutes", [])
          );
        });

        // Find any lunch/absence that conflicts with booking time
        const lunchSchedules = activities.filter(activity => {
          const startTime = moment(activity.startTime, "HH:mm:ss");
          const endTime = moment(activity.endTime, "HH:mm:ss");

          return (
            dayActivityTypes.ABSENCE === activity.type &&
            formattedTime.isBetween(startTime, endTime, "minutes", [])
          );
        });

        // If work schedule within time exists and an absence is not for current time, return associate as bookable.
        return !isEmpty(workSchedules) && isEmpty(lunchSchedules);
      });

    return result;
  },
);

// Usage: getIsMultiPetBookingWarningToBeDisplayed(state)
export const getIsMultiPetBookingWarningToBeDisplayed = createSelector(
  [getAllCartAppointments, getSelectedPet, getSelectedDate],
  (allCartAppointments, selectedPet, selectedDate) => {
    return allCartAppointments
      .filter(appointment => appointment.petId !== selectedPet)
      .some(appointment => {
        return (
          !isEmpty(appointment?.timeSlot) &&
          !getIsTargetDate(appointment?.timeSlot?.startDateTime, selectedDate)
        );
      });
  },
);

export const selectIsMultiPetBooking = createSelector(
  [getAllCartAppointments],
  allCartAppointments => allCartAppointments.length > 1
);

export const getAreAllServicesScheduledOnTheSameDate = createSelector(
  [getAllCartAppointments],
  allCartAppointments => {
    return allCartAppointments.every((appointment, _, arr) =>
      getIsTargetDate(appointment?.timeSlot?.startDateTime, arr[0]?.timeSlot?.startDateTime),
    );
  },
);