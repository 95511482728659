import React from "react";
import { Button, Layout, List, Text } from "@prism/psm-ui-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCurrentCustomerKey,
  getCurrentCustomerFirstName,
} from "@/core/selectors/persistent/customer/customerSelectors";
import UnknownDogImage from "@/assets/images/unknown-dog.png";
import {
  getIsTrainingAppointmentSetModalOpen,
  getTrainingAppointmentCardInfo,
  getTrainingAppointment,
} from "@/dux/scheduleTrainingPage/scheduleTrainingPageSelectors";
import { toggleTrainingAppointmentIsSetModal } from "@/dux/scheduleTrainingPage/scheduleTrainingPageActions";
import { getTrainingClassAvailabilityByClassSessionId } from "@/dux/trainingClassAvailability/trainingClassAvailabilitySelectors";
import { getStoreNumber, getCurrentPet } from "@/core/selectors/persistentSelectors";
import { routePaths } from "@/core/constants/routePaths";
import CommonModal from "@/web/common/modals/commonModal";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

// Card ----------------------------------------------------------------------------------------------------------------
function AttendeeCard({ rows = [] }) {
  return (
    <Layout.Box borderWidth borderColor="box-border-color-gray-100" padding="box-padding-4" display>
      <div
        style={{
          display: "grid",
          gap: "1.5rem",
          gridTemplateColumns: "fit-content(20ch) minmax(min(50vw, 30ch), 1fr)",
        }}
      >
        <img
          alt="dog profile photo"
          src={UnknownDogImage}
          style={{ boxSizing: "border-box", objectFit: "cover" }}
        />
        <Layout.Box>
          <List items={rows} as={Layout.Box}>
            {row => <>{row?.label}</>}
          </List>
        </Layout.Box>
      </div>
    </Layout.Box>
  );
}

// Buttons -------------------------------------------------------------------------------------------------------------
const ProfileLinkContainer = connect(
  state => ({
    to: `/pet-parent-profile/${getCurrentCustomerKey(state)}`,
    style: { textDecoration: "none" },
  }),
  {},
)(Link);

const CustomerFirstNameContainer = connect(
  state => ({
    variant: "prism-primary-no-outline",
    children: `${getCurrentCustomerFirstName(state)}'s Profile`,
  }),
  {},
)(Button);

function ScheduleTrainingAppointmentSetModal({ isHidden, onClose, trainingAppointmentCardInfo }) {
  return (
    <>
      <CommonModal isHidden={isHidden} onClose={onClose}>
        <Layout.Box padding="box-padding-4">
          <Layout.Stack space="stack-space-8">
            <Text align="center" bold>
              The appointment is set!
            </Text>
            <AttendeeCard rows={trainingAppointmentCardInfo} />
            <Layout.Cluster space="cluster-space-4" style={{ "--setStackSpace": "2rem" }}>
              <ProfileLinkContainer>
                <CustomerFirstNameContainer />
              </ProfileLinkContainer>
              <Button variant="prism-primary" onClick={onClose}>
                Done
              </Button>
            </Layout.Cluster>
          </Layout.Stack>
        </Layout.Box>
      </CommonModal>
    </>
  );
}

export default withRouteProps(
  connect(
    state => {
      const customerKey = getCurrentCustomerKey(state);
      const petId = getCurrentPet(state);
      const storeNumber = getStoreNumber(state);
      const { classSessionId } = getTrainingAppointment(state);
      const trainingClass = getTrainingClassAvailabilityByClassSessionId(state, { classSessionId });
      const associateId = trainingClass?.associateId;
      const startDateTime = trainingClass?.startDatetime;
      return {
        isHidden: !getIsTrainingAppointmentSetModalOpen(state),
        trainingAppointmentCardInfo: getTrainingAppointmentCardInfo(state, {
          customerKey,
          petId,
          storeNumber,
          associateId,
          startDateTime,
        }),
      };
    },
    (dispatch, { router: { navigate } }) => ({
      onClose: () => {
        dispatch(toggleTrainingAppointmentIsSetModal());
        navigate(routePaths.DASHBOARD);
      },
    }),
  )(ScheduleTrainingAppointmentSetModal),
);
