import { connect } from "react-redux";
import QuickViewCustomerCard from "./quickViewCustomerCardComponent";
import {
  getCustQuickViewInfo,
  getCustAddressQuickViewInfo
} from "../../../../../core/selectors/searchSalonSRCSelector";

const mapStateToProps = (state, ownProps) => {
  const customerID = state.ui.web.search.custID;

  return {
    column1: getCustQuickViewInfo(state, customerID),
    column2: getCustAddressQuickViewInfo(state, customerID)
  };
};

const QuickViewCostomerCardContainer = connect(mapStateToProps)(QuickViewCustomerCard);

export default QuickViewCostomerCardContainer;
