import dynamicPricesActionTypes from "../../core/actionTypes/dynamicPricesActionTypes";

const initialState = {};

export default function dynamicPricesReducer(state = initialState, action) {
  switch (action.type) {
    // cc: loadItinerariesFromDB#10;Set State;Set Dynamic Prices.
    case dynamicPricesActionTypes.SET_DYNAMIC_PRICES:
      return {
        ...state,
        ...action.dynamicPrices
      };

    default:
      return state;
  }
}
