/**
 * Responsible for returning the getSearchFeature Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getSearchFeatureState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the searchFeature object in the redux store
 *
 * @example createSelector([getSearchFeatureState], searchFeatureState => searchFeatureState.searchText;
 */
export default function getSearchFeatureState(state) {
  return state.featureFlagging.featureSearch;
}
