import { connect } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { Layout, Text, Button } from "@prism/psm-ui-components";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import selectStoreEventNotificationsIsHidden from "web/enableDisableWorkflowFeatureFlag/selectors/storeEventNotifications/getStoreEventNotificationsFeatureFlagHidden";
import { getNotifForAlert } from "./storeEventNotificationsActions";
import {
  selectEventNotifications,
  selectNotificationMessage,
  selectEventNotificationsMessageTags,
  getIsCollapsed,
  selectStoreNotificationBannerVisibility,
} from "./storeEventNotificationsSelectors";
import { getCanClose, getCanConfetti, getHasNoNotifsForDate } from "./storeEventNotificationsUtils";
import * as confettiComponentTsx from "../../web/common/uiParticles/confettiComponent.tsx";
import { upArrowUriWhite, downArrowUriWhite } from "../../web/common/styles/theme";
import { getFact, getEmoji } from "./PetFacts";
import moment from "moment";

const Confetti = passedProps => {
  return <confettiComponentTsx.ConfettiComponent debug={false} {...passedProps} />;
};

const BannerComponent = props => {
  const {
    callGetNotifications,
    message,
    confetti,
    canClose,
    petFact,
    petEmoji,
    isHiddenPetFact,
    hasNoNotifsForDate,
    onCallGetNotifications,
    isHidden,
    petFactLabel,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [hasOverflow, setHasOverflow] = useState(false);

  // set initial local  state from props.
  const [isNotVisible, setIsHidden] = useState(isHidden);
  useEffect(() => {
    setIsHidden(isHidden);
  }, [isHidden]);

  // ref to determine if notifications cause overflow within banner
  const ref = useRef();
  useEffect(() => {
    setHasOverflow(hasOverflow || ref?.current?.scrollHeight > ref?.current?.clientHeight);
  }, [ref?.current?.scrollHeight > ref?.current?.clientHeight]);

  // if not hidden and no notifications are found for today's date, perform API call
  useEffect(() => {
    if (!isNotVisible && hasNoNotifsForDate) {
      const params = onCallGetNotifications();
      callGetNotifications(params);
    }
  }, [isHidden, hasNoNotifsForDate]);

  return (
    /* Outer blue box for notification banner */
    <Layout.Box
      isHidden={isNotVisible}
      backgroundColor="box-background-blue"
      borderWidth
      padding="box-padding-1"
      style={{ width: "100%" }}
    >
      {/* Display confetti animation if needed */}
      {confetti && (
        <Confetti
          run={confetti}
          recycle={false}
          friction={1}
          wind={0}
          gravity={0.2}
          initialVelocityX={1}
          initialVelocityY={20}
          numberOfPieces={2000}
        />
      )}

      {/* grid to contain notification lisit and "x" to close banner */}
      <Layout.Grid style={{ gridTemplateColumns: "98% 2%", maxWidth: "98%", paddingLeft: "2%" }}>
        {/* if there is overflow, display cut off notifications with ellipses, otherwise do not */}
        <Layout.Stack space="stack-space-1">
          <div
            ref={ref}
            style={
              isCollapsed
                ? {
                    display: "-webkit-box",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: "2",
                    overflow: "hidden",
                    width: "100%",
                  }
                : {}
            }
          >
            {message?.length > 1 ? (
              message?.map((mes, index) => (
                <Text size="text-size-lg" color="text-color-white" align="center">{`${index +
                  1}. ${mes}`}</Text>
              ))
            ) : (
              <Text size="text-size-lg" color="text-color-white" align="center">
                {message}
              </Text>
            )}
          </div>
        </Layout.Stack>

        {/* "x" button to close banner if banner is closable */}
        <Button
          isHidden={!canClose}
          onClick={() => setIsHidden(true)}
          variant="link"
          style={{ alignSelf: "self-start" }}
        >
          <Text size="text-size-lg" color="text-color-white" style={{ paddingLeft: "150%" }}>
            x
          </Text>
        </Button>
      </Layout.Grid>

      {/* Display pet facts and random emojis at bottom of banner if isHiddenPetFact is true */}
      {!isHiddenPetFact && (
        <Layout.Center centerText>
          {/* pet emoji */}
          {petEmoji}
          {/* pet fact */}
          <Text
            size="text-size-xs"
            bold
            color="text-color-green"
            style={{ paddingRight: "2px", fontStyle: "italic" }}
          >
            {petFactLabel}
          </Text>
          <Text
            size="text-size-xs"
            color="text-color-white"
            style={{ paddingRight: "2px", fontStyle: "italic" }}
          >
            {petFact}
          </Text>
          {petEmoji}
        </Layout.Center>
      )}

      {/* show expand/collapse button if overflow is present */}
      {hasOverflow && (
        <Layout.Center centerText>
          <Button size="xs" onClick={() => setIsCollapsed(!isCollapsed)} variant="link">
            <Text size="text-size-xs" color="text-color-white" style={{ paddingRight: "2px" }}>
              {isCollapsed ? "Expand" : "Collapse"}
            </Text>
            <img src={isCollapsed ? downArrowUriWhite : upArrowUriWhite} />
          </Button>
        </Layout.Center>
      )}
    </Layout.Box>
  );
};

/**
 * Dynamic version of the notifications banner, notifications are pulled from SF
 * @type {ConnectedComponent<React.JSXElementConstructor<Matching<TInjectedProps, GetProps<C>>>, DistributiveOmit<GetLibraryManagedProps<React.JSXElementConstructor<Matching<TInjectedProps, GetProps<C>>>>, keyof Shared<*&{onCallGetNotifications: function(): {storeNumber: *}}, GetLibraryManagedProps<React.JSXElementConstructor<Matching<TInjectedProps, GetProps<C>>>>>>>}
 */
export const BannerContainer = connect(
  state => {
    const messages = selectNotificationMessage(state);
    const messageTags = selectEventNotificationsMessageTags(state);
    const featureFlagIsOn = selectStoreEventNotificationsIsHidden(state);
    const isHidden = featureFlagIsOn || selectStoreNotificationBannerVisibility(state);
    const confett = getCanConfetti(messageTags);
    const canClose = getCanClose(messageTags);
    const isCollapsed = getIsCollapsed(state);
    const petFact = getFact(state.storeEventNotifications?.randomNumber);
    const petEmoji = getEmoji(state.storeEventNotifications?.randomNumberEmoji);
    const isHiddenPetFact = false; // toggle true in to hide pet facts
    const notifications = selectEventNotifications(state);
    const hasNoNotifsForDate = getHasNoNotifsForDate(notifications);
    const storeNumber = getStoreNumber(state);

    return {
      ...state,
      message: messages,
      confetti: confett,
      canClose,
      componentId: "NotifComponent",
      isHidden,
      isCollapsed,
      petFact,
      petEmoji,
      isHiddenPetFact,
      hasNoNotifsForDate,
      storeNumber,
      petFactLabel: "Randomized pet fact:",
    };
  },
  dispatch => {
    return {
      callGetNotifications: ({ storeNumber, displayLocation, date }) =>
        dispatch(getNotifForAlert({ storeNumber, displayLocation, date })),
    };
  },
  (stateProps, dispatchProps) => {
    return {
      ...stateProps,
      ...dispatchProps,
      onCallGetNotifications: () => {
        const { storeNumber } = stateProps;
        return { storeNumber };
      },
    };
  },
)(BannerComponent);

/**
 * Static version of hte Notifications banner
 * @type {ConnectedComponent<React.JSXElementConstructor<Matching<TInjectedProps, GetProps<C>>>, DistributiveOmit<GetLibraryManagedProps<React.JSXElementConstructor<Matching<TInjectedProps, GetProps<C>>>>, keyof Shared<* & {canClose: boolean, confetti: boolean, storeNumber: *, componentId: string, isCollapsed: boolean, petFactLabel: string, hasNoNotifsForDate: boolean, isHiddenPetFact: boolean, message: [string], petFact: null, petEmoji: null, isHidden} & {onCallGetNotifications: function()}, GetLibraryManagedProps<React.JSXElementConstructor<Matching<TInjectedProps, GetProps<C>>>>>>>}
 */
export const StaticBannerContainer = connect(
  state => {
    return {
      ...state,
      message: [
        "On Wednesday, May 10th all existing PRISM users will need to reset their Quick PIN when they log-in, as part of the upcoming PRISM Quick PIN change over.",
      ],
      confetti: false,
      canClose: true,
      componentId: "StaticBannerContainer",
      isHidden: !moment().isBetween("2023-05-08", "2023-05-10"), // set date range here for any static messages.
      isCollapsed: false,
      petFact: null,
      petEmoji: null,
      isHiddenPetFact: true,
      hasNoNotifsForDate: false,
      storeNumber: getStoreNumber(state),
      petFactLabel: "",
    };
  },
  dispatch => {
    return {
      onCallGetNotifications: () => {
        // no action/api needs to be called since content is static
      },
    };
  },
)(BannerComponent);

/**
 * NotificationsFactory returns a notifications connected component based on if the type of "static" is provided
 * @param {Object} props - The component's properties
 * @param {string} props.type - The type of notification to display e.g. 'static'
 * @returns {JSX.Element} A React component based on the type, static or dynamic
 */
export const NotificationsFactory = ({ type }) => {
  if (type === "static") return <StaticBannerContainer />;

  return <BannerContainer />;
};
