import { createSelector } from "reselect";
import { getEligibilityResults } from "dux/eligibility/selectors/eligibilitySelectors";
import {
  ALL_PETS_NOT_ALLOWED,
  CUSTOMER_NOT_ALLOWED,
} from "dux/eligibility/constants/eligibilityConstants";

export const getCustomerCanBook = createSelector([getEligibilityResults], eligibility => {
  // eslint-disable-next-line valid-typeof
  if (!eligibility.customer || typeof !eligibility.customer.canBook === undefined) {
    return true;
  }

  return eligibility.customer.canBook;
});

export const getRedirectionReason = createSelector([getCustomerCanBook], canBook => {
  return canBook ? ALL_PETS_NOT_ALLOWED : CUSTOMER_NOT_ALLOWED;
});
