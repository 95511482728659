import React, { useRef } from "react";
import { connect } from "react-redux";
import { Layout, Text, Form } from "@prism/psm-ui-components";
import { ClickOutside } from "@/core/utils/clickOutsideUtils/clickOutside";
import {
  getTrainingAttendeeById,
  getTrainingClassSessionDetails,
  getIsTrainingAppointmentCompleted,
  getTrainingAttendeeNotesInitialFormValues,
} from "../trainingClassSessions/selectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { patchTrainingClassItinerary } from "../trainingClassItineraries/trainingClassItinerariesActions";
import { useForm } from "../utils/formUtils/useForm";
import { setTrainingAttendees } from "../trainingClassSessions/actions";

const TrainingAttendeeClassNotes = ({
  label,
  rows,
  textAreaStyle,
  onSubmit,
  isDisabled,
  headingColor,
  initialFormValues,
}) => {
  const { values, didFormFieldsUpdate, setTextAreaInput } = useForm(initialFormValues);
  const { trainingNotes } = values;
  const ref = useRef(null);
  const onClickOutside = () => onSubmit(trainingNotes);
  const isClickOutsideDisabled = isDisabled || !didFormFieldsUpdate;
  const classNotesCharacterCount = trainingNotes ? `${trainingNotes.length} / 250 ` : `0 / 250`;

  ClickOutside(ref, onClickOutside, isClickOutsideDisabled);

  return (
    <Layout.Box>
      <Layout.Stack space="stack-space-2">
        <Text color={headingColor}>{label}</Text>
        <div ref={ref}>
          <Form.Field.TextArea
            name="trainingNotes"
            disabled={isDisabled}
            value={trainingNotes}
            rows={rows}
            style={textAreaStyle}
            onInput={setTextAreaInput}
            maxLength={250}
          />
          <Layout.Stack space="stack-space-1">
            <Layout.Cluster justify="flex-end">
              <Text>{classNotesCharacterCount}</Text>
            </Layout.Cluster>
          </Layout.Stack>
        </div>
      </Layout.Stack>
    </Layout.Box>
  );
};

// Class notes to be implemented - created an empty container
// to set us up for future tickets.
export default connect(
  (state, { attendeeId }) => {
    const storeNumber = getStoreNumber(state);
    const classSession = getTrainingClassSessionDetails(state, { storeNumber });
    const attendee = getTrainingAttendeeById(state, { attendeeId, storeNumber });
    const isDisabled = getIsTrainingAppointmentCompleted(state, { attendeeId, storeNumber });
    return {
      isDisabled,
      headingColor: isDisabled ? "text-color-disabled" : "text-color-inherit",
      isHidden: false,
      label: "Class Notes",
      rows: 4,
      textAreaStyle: { fontFamily: "Lato" },
      customerKey: attendee?.customerId,
      itineraryId: attendee?.itineraryId,
      petId: attendee?.petId,
      classSessionId: classSession?.classSessionId,
      notes: attendee?.additionalCustomerCommentary,
      customerPhone: attendee?.phoneNumber,
      initialFormValues: getTrainingAttendeeNotesInitialFormValues(state, {
        attendeeId,
        storeNumber,
      }),
    };
  },
  dispatch => ({
    dispatchPatchTrainingClassItinerary: payload => {
      dispatch(patchTrainingClassItinerary(payload));
    },
    dispatchSetTrainingAttendees: payload => {
      dispatch(setTrainingAttendees(payload));
    },
  }),
  (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,

    onSubmit: (notes = "") => {
      const { classSessionId, customerKey, petId } = propsFromState;
      const attendeeId = `${classSessionId}-${customerKey}-${petId}`;
      const {
        dispatchPatchTrainingClassItinerary,
        dispatchSetTrainingAttendees,
      } = propsFromDispatch;

      dispatchPatchTrainingClassItinerary({
        ...propsFromState,
        notes,
        onComplete: () =>
          dispatchSetTrainingAttendees({ [attendeeId]: { additionalCustomerCommentary: notes } }),
      });
    },
  }),
)(TrainingAttendeeClassNotes);
