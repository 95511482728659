import { connect } from "react-redux";
import PetsComponent from "./pets/PetsComponent";
import petsActionCreators from "core/actionCreators/petsActionCreators";
import petProfileActionCreators from "core/actionCreators/petProfileActionCreators";
import {
  getPetsNamesAndIdsAndActiveByCustomer,
  getIsCustomerActive,
  getPet,
  getNumberOfActivePets,
} from "core/selectors/entitiesSelector";
import { showConfirmationModal } from "core/actionCreators/confirmationModalActionCreators";
import { selectPet } from "core/actionCreators/bookingActionCreators";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { getModalType } from "core/selectors/modals/petProfileModalSelectors";
import { setPetId } from "core/actionCreators/ui/web/generalActionCreators";
import { modalTypes } from "core/constants/petProfileConstants";
import { getPetMissingRequired } from "core/selectors/entities/getPetMissingRequiredSelector";
import { getIsPetAdding } from "core/selectors/ui/petParentProfile/addingPetSelectors";
import petParentProfileActionCreator from "core/actionCreators/petParentProfileActionCreator";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = (state, ownProps) => {
  const selectedPet = getSelectedPet(state);
  return {
    selectedPet,
    componentID: "petProfileSalonColumn-petsComponent",
    routedPet: getCurrentPet(state),
    pets: getPetsNamesAndIdsAndActiveByCustomer(state, ownProps),
    missingRequiredMessage: getPetMissingRequired(state, ownProps),
    modalType: getModalType(state),
    isCustomerActive: getIsCustomerActive(state, ownProps),
    currentPet: getPet(state, { petId: selectedPet }),
    numberOfActivePets: getNumberOfActivePets(state, ownProps),
    isAddingPet: getIsPetAdding(state),
    disabled: !getCurrentCustomerKey(state),
    isFeedingDetailHidden: true,
    colTitle: `${systemName.SALON} Pet profile details`,
    gridArea: "pets",
    systemName: systemName.SALON,
  };
};

const mapDispatchToProps = dispatch => ({
  loadPetsByCustomer: ({ customerKey }) =>
    dispatch(petsActionCreators.loadPetsByCustomer({ customerKey })),
  selectPet: petId => {
    if (petId) {
      dispatch(selectPet(petId));
      dispatch(setPetId(petId));
    }
  },
  showModal: () =>
    dispatch(petProfileActionCreators.showPetProfileModal(modalTypes.ADD_PET_WARNING)),
  showConfirmationModal: args => dispatch(showConfirmationModal(args)),
  setIsAddingPet: isAddingPet =>
    dispatch(petParentProfileActionCreator.setIsAddingPet({ isAddingPet })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PetsComponent);
