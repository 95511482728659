import { connect } from "react-redux";
import CommonButton from "web/common/commonButton";
import { getMedicationById } from "web/medication/medicationsSelector";
import { showBookingModal } from "core/actionCreators/bookingActionCreators";
import {
  clearPendingFrequency,
  frequencyActionTypes,
  setPendingFrequency,
  setPendingFrequencyMedicationDates,
  setPendingId,
} from "dux/frequency/actions/frequencyActions";
import {
  getMedicationFrequencyById,
  getMedicationFrequencyDatesById,
} from "dux/bookingPage/setMedFrequency/medicationFrequencySelectors";
import { frequencyConstants, frequencyTypes } from "dux/frequency/FrequencyConstants";
import { getPendingPetId } from "@/dux/frequency/frequencySelectors";
import {
  selectDateRangeForFrequency,
  selectDefaultFrequency,
} from "@/dux/frequency/dateRangeForFrequencySelectors";
import { NEW_MEDICATION_ID } from "@/web/newMedication/newMedicationConstants";
import { setFrequencyDates } from "@/dux/frequency/modal/FrequencySelection";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, petId } = ownProps;
  const medication = getMedicationById(state, { medicationId, petId });
  const defaultFrequency = selectDefaultFrequency(state);
  return {
    medicationId,
    petId,
    medication,
    componentId: "medicationDetails-setFrequencyMedicationButton",
    label: "Set Frequency",
    width: "8.5em",
    medicationFrequency:
      getMedicationFrequencyById(state, { medicationId, petId }) || defaultFrequency,
    medicationFrequencyDates: getMedicationFrequencyDatesById(state, { medicationId, petId }),
    pendingPet: getPendingPetId(state),
    pendingAppointmentDates: selectDateRangeForFrequency(state),
  };
};

const mapDispatchToProps = dispatch => ({
  setMedicationPetId: (medicationId, petId) =>
    dispatch(
      setPendingId({ pendingId: medicationId, petId, frequencyType: frequencyTypes.MEDICATION }),
    ),
  setPendingAppointmentData: ({ medicationFrequency, medicationFrequencyDates }) => {
    if (medicationFrequency) dispatch(setPendingFrequency({ frequency: medicationFrequency }));
    if (medicationFrequencyDates) {
      dispatch(setPendingFrequencyMedicationDates({ dates: medicationFrequencyDates }));
    }
  },
  showFrequencyModal: () => dispatch(showBookingModal(frequencyActionTypes.SHOW_FREQUENCY_MODAL)),
  clearFrequency: () => dispatch(clearPendingFrequency()),
});

const mergeProps = (propsFromState, propsFromDispatch) => {
  const {
    componentId,
    label,
    width,
    medicationId,
    petId,
    medicationFrequency,
    medicationFrequencyDates,
    pendingPet,
    pendingAppointmentDates,
  } = propsFromState;
  const {
    clearFrequency,
    setMedicationPetId,
    setPendingAppointmentData,
    showFrequencyModal,
  } = propsFromDispatch;
  const dates =
    medicationFrequency === frequencyConstants.MANUAL
      ? medicationFrequencyDates
      : setFrequencyDates(medicationFrequency, pendingAppointmentDates);

  return {
    componentId,
    label,
    width,
    onClick: () => {
      if (pendingPet !== petId || medicationId === NEW_MEDICATION_ID) clearFrequency();
      setMedicationPetId(medicationId, petId);
      setPendingAppointmentData({
        medicationFrequency,
        medicationFrequencyDates: dates,
      });
      showFrequencyModal();
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
