/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_HOTEL_OVERBOOKING_EXCEPTIONS_HISTORY_ID =
  "SET_HOTEL_OVERBOOKING_EXCEPTIONS_HISTORY_ID";

/* * ----------------------------------------------------------------------- * *\
Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 *
 * @param {{exceptionId: string | null}} exceptionId
 * @returns Action creator for hotel overbooking exceptions history
 */
export const setHotelOverbookingExceptionsHistoryId = ({ exceptionId }) => ({
  type: SET_HOTEL_OVERBOOKING_EXCEPTIONS_HISTORY_ID,
  exceptionId,
});
