import { SET_CUSTOMER_FTCO_ELIGIBLE } from "./ftcoActions";

const initialState = { isCustomerFTCOEligible: false };

const ftcoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_FTCO_ELIGIBLE:
      return {
        ...state,
        isCustomerFTCOEligible: action.payload,
      };
    default:
      return state;
  }
};

export default ftcoReducer;
