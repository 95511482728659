import { get } from "lodash/fp";
import { createSelector } from "reselect";
import {
  getMedicationById,
  getMedicationsByPet,
  getPetMedicationIds,
  getPetMedications,
} from "../../../web/medication/medicationsSelector";

export const getMedicationSaveToProfileById = createSelector(
  [getMedicationsByPet, (state, props) => props],
  (medications, { medicationId }) => {
    return get([medicationId, "saveToProfile"], medications) || false;
  },
);

/**
 *  Selector to get id from med that corresponds to petMedicationId on the pet profile meds
 *  @memberOf Selectors.medications
 *  @function
 *  @name getMedProfileIdById
 *  @param {Object} state
 *  @param {Object} props
 *  @param {string} props.petId
 *  @param {string} props.medicationId
 *  @example getMedProfileIdById(state, { medicationId, petId })
 */
export const getMedProfileIdById = createSelector(
  [getMedicationById],
  med => med?.petProfileMedId ?? med?.petMedicationId,
);

/**
 *  Selector to determine if medication is from pet profile
 *  @memberOf Selectors.medications
 *  @function
 *  @name getIsMedicationOnPetById
 *  @param {Object} state
 *  @param {Object} props
 *  @param {string} props.petId
 *  @example getIsMedicationOnPetById(state, { petId })
 */
export const getIsMedicationOnPetById = createSelector(
  [getPetMedicationIds, getMedProfileIdById],
  (medicationIds, petProfileMedId) => {
    return !!medicationIds.find(id => id === petProfileMedId);
  },
);

export const getMedicationSaveToProfileAmount = createSelector(
  [getMedicationsByPet, getPetMedicationIds],
  (meds = {}, petMeds = []) => {
    const numberOfNewBookingMeds = Object.values(meds)?.filter(
      med => med.saveToProfile && !petMeds.find(petMed => petMed === med?.petProfileMedId),
    ).length;

    const numberOfProfileMeds = petMeds.length;

    // The amount of meds saving to profile equal the amount already from the profile plus whatever new booking meds have 'Save to Profile' checked.
    return meds ? numberOfProfileMeds + numberOfNewBookingMeds : numberOfProfileMeds;
  },
);
