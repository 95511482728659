import { createSelector } from "reselect";
import { selectServiceSuspensionReasons } from "dux/hotelSuspensionReasons/hotelSuspensionReasonsSelectors";
import { getMoDayYearDate } from "core/utils/dateUtils/formatDateTime";
import { getScheduledSuspensionsFilterButtonData } from "dux/scheduleSuspensionFilterButtons/scheduleSuspensionfilterbuttonSelectors";
import { filter } from "lodash/fp";
import moment from "moment";
import { convertSuspensionTypeIdToLabel } from "@/core/utils/scheduleServicesUtils";

export const getScheduledSuspensions = state => state.hotelScheduleSuspensions.suspensionsList;

export const getScheduledSuspensionData = createSelector(
  [getScheduledSuspensions, selectServiceSuspensionReasons],
  (suspensions, reasons) => {
    // Get a list of all suspension reasons keys so we can target each reason type object.
    const reasonIDList = Object.keys(reasons);

    // iterate over each suspension so we can match and marry to some keys from the suspensions reasons API result.
    return suspensions?.map(suspension => {
      const { suspensionId, startDate, suspensionTypeId, reasonId, status, details } = suspension;

      let reasonName = "";
      let suspensionType = "";

      reasonIDList.forEach(reasonID => {
        if (reasonID === suspensionTypeId) {
          suspensionType = reasons[reasonID].reasonTypeName;
        }
      });

      // Loop through the reasonIDList (suspension reasons keys) to target teh reason list in each object
      for (let i = 0; i < reasonIDList.length; i++) {
        const key = reasonIDList[i];
        const reasonsList = reasons[key];

        // Once we have the reason list, loop through it to find the reason name and break the loop on 1st occurrence.
        for (let j = 0; j < reasonsList.reasons.length; j++) {
          if (reasonId === reasonsList.reasons[j].reasonId) {
            reasonName = reasonsList.reasons[j].reasonName;
            break;
          }
        }
      }

      return {
        startDate: getMoDayYearDate(startDate), // format to MM/DD/YYYY
        suspensionType,
        reasonName: `${reasonName} ${details ? `- ${details}` : ""}`,
        status,
        suspensionId,
        suspensionTypeId,
        reasonId,
      };
    });
  },
);

/**
 *  Selector to construct suspensions table filter pills
 *  1. Status
 *  2. Service type
 *
 *  @memberOf Selectors.Schedules
 *  @function
 *  @name selectScheduleSuspensionsTableFilterPills
 *  @param {Object} state - redux state
 *  @returns {Array}
 */
export const selectScheduleSuspensionsTableFilterPills = createSelector(
  [getScheduledSuspensionData, selectServiceSuspensionReasons],
  (suspensions, reasons) => {
    if (suspensions?.length === 0 || !reasons) {
      return [];
    }

    const filtersWithDuplicates = suspensions
      .map(suspension => {
        const { status, suspensionTypeId } = suspension || {};

        const suspensionTypeLabel = convertSuspensionTypeIdToLabel(suspensionTypeId, reasons);

        return [status, suspensionTypeLabel];
      })
      .flat();

    const filters = [...new Set(filtersWithDuplicates)];

    return filters?.map(filterPill => {
      return {
        id: filterPill?.toLowerCase(),
        name: filterPill,
        isPressed: false,
        filterValue: filterPill,
      };
    });
  },
);

/** Gets the full scheduled suspension data, cross references with what Suspension Scheduled filter button(s)
    is active "isPressed" and returns the suspensions for each filter button that isPressed * */
export const getFilteredScheduledSuspensionData = createSelector(
  [getScheduledSuspensionData, getScheduledSuspensionsFilterButtonData],
  (suspensions, buttonData) => {
    // filter out only buttons that are pressed and return the name of each button in an array
    const pressedButtons = filter("isPressed", buttonData).map(button => button.name);

    // cross and filter the suspension status with any button that has a matching name
    const filteredSuspensions = filter(suspension => {
      return (
        pressedButtons.includes(suspension.status) ||
        pressedButtons.includes(suspension.suspensionType)
      );
    }, suspensions);

    // on initial load, the filteredSuspensions may be an empty array, if so we just return an unfiltered set of suspensions
    return filteredSuspensions.length === 0 ? suspensions : filteredSuspensions;
  },
);
