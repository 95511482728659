import { connect } from "react-redux";
import isPetAndCustomerEligibilityReasonsEmpty from "dux/eligibility/helperUtils/isPetAndCustomerEligibilityReasonsEmpty";
import { getCurrentCustomerKey } from "../../core/selectors/persistent/customer/customerSelectors";
import { getCustomer } from "../../core/selectors/entitiesSelector";
import { constructCustomerAndPetSalonEligibility } from "dux/eligibility/helperUtils/constructEligibility";
import VipMessageModal from "../common/modals/content/eligibilityModals/VIPMessageModal";

const mapStateToProps = (state, ownProps) => {
  const customerKey = getCurrentCustomerKey(state);
  const customer = getCustomer(state, { customerKey });

  const eligibility = constructCustomerAndPetSalonEligibility(
    state,
    ownProps.isWarnings,
    ownProps.selectedPets,
  );

  return {
    componentID: "VIPMessageModal_AlertMessage",
    isHidden: !(
      isPetAndCustomerEligibilityReasonsEmpty(eligibility) && customer.prismCustomer.isVip
    ),
  };
};

export default connect(mapStateToProps)(VipMessageModal);
