import React, { useEffect } from "react";
import MedicationSectionContainer from "../newMedication/MedicationSectionContainer";
import { BookingPetMedList } from "../medicationList/MedList";
import NewBookingMedicationFormContainer from "../../dux/bookingMedication/bookingMedicationForm/NewBookingMedicationFormContainer";
import { Layout } from "@prism/psm-ui-components";

const BookingMedicationComponent = ({
  isHidden,
  petId,
  hasPadding,
  locationType,
  loadMedicationsList,
  topLine,
  title,
}) => {
  useEffect(() => {
    if (typeof loadMedicationsList === "function") loadMedicationsList();
  }, []);

  if (isHidden) return null;

  const containerStyles = {
    padding: hasPadding || "inherit",
  };

  return (
    <Layout.Box style={containerStyles}>
      <MedicationSectionContainer
        petId={petId}
        locationType={locationType}
        topLine={topLine}
        title={title}
      >
        <NewBookingMedicationFormContainer petId={petId} locationType={locationType} />
        <BookingPetMedList petId={petId} locationType={locationType} />
      </MedicationSectionContainer>
    </Layout.Box>
  );
};

export default BookingMedicationComponent;
