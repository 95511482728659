import { GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS_SUCCESS } from "./overBookingExceptionTableDetailsActions";

const initialState = {
  suspensionDetails: [],
};

const overBookingExcpetionSuspensionDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS_SUCCESS:
      return {
        ...state,
        suspensionDetails: action.payload,
      };
    default:
      return state;
  }
};

export default overBookingExcpetionSuspensionDetailsReducer;
