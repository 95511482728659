import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { getServicesForPet, getStoreData } from "../../core/selectors/entitiesSelector";
import { getCartDetails } from "../../core/selectors/cartSelectors";
import {
  clearCart,
  initializeCartByItinerary,
  initializeCart,
} from "../../core/actionCreators/ui/web/cartActionCreators";
import { getSelectedPet } from "../../core/selectors/bookingUISelectors";
import { createLoadingSelector } from "../../core/selectors/utils";
import priceAdjustmentActionTypes from "../../core/actionTypes/priceAdjustmentActionTypes";
import specialsActionTypes from "../../core/actionTypes/specialsActionTypes";
import petServiceItemsActionTypes from "../../core/actionTypes/petServiceItemsActionTypes";
import { getStoreNumber } from "../../core/selectors/persistentSelectors";
import engagementsActionTypes from "../../core/actionTypes/engagementsActionTypes";
import itinerariesActionTypes from "../../core/actionTypes/itinerariesActionTypes";
import { getSystemBookingFlow } from "../setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import CartComponent from "./CartComponent";
import CartDetailsContainer from "web/cart/CartDetailsContainer";

const mapStateToProps = state => {
  const storeNumber = getStoreNumber(state);

  return {
    appointments: getCartDetails(state),
    servicesForPet: getServicesForPet(state),
    selectedPet: getSelectedPet(state),
    isHidden: getSystemBookingFlow(state) !== systemName.SALON,
    isLoading: createLoadingSelector([
      priceAdjustmentActionTypes.UPDATE_PRICE_OF_PET_SERVICE_OR_ADDON,
      specialsActionTypes.POST_SPECIALS,
      specialsActionTypes.REMOVE_SPECIALS,
      petServiceItemsActionTypes.UPDATE_PET_SERVICE_ITEM,
      engagementsActionTypes.UPDATE_ENGAGEMENT_STATUS,
      itinerariesActionTypes.UPDATE_ITINERARY_STATUS,
      itinerariesActionTypes.UPDATE_ITINERARY_PAYMENT_STATUS,
      engagementsActionTypes.UNDO_LAST_ENGAGEMENT_STATUS_UPDATE,
    ])(state),
    storeData: getStoreData(state, { storeNumber }),
    diComp: CartDetailsContainer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    router: {
      params: { itineraryId },
    },
  } = ownProps;
  return {
    clearCart: () => dispatch(clearCart()),
    initializeCart: petServiceItemId => {
      if (itineraryId) {
        dispatch(initializeCartByItinerary({ itineraryId }));
      } else if (petServiceItemId) {
        dispatch(initializeCart({ petServiceItemId }));
      }
    },
  };
};

export default withRouteProps(connect(mapStateToProps, mapDispatchToProps)(CartComponent));
