import React from "react";
import { connect } from "react-redux";
import { TextPassage } from "@petsmart-ui/sparky";
import { getFirstHotelEngagementByPet } from "dux/hotelEngagements/hotelEngagementSelectors";
import { LayoutCluster } from "@/layout/culster/Cluster";

export const AppointmentStatusLabel = ({ componentId, status }) => {
  return (
    <LayoutCluster style={{ padding: "5px 0px" }} id={componentId}>
      <TextPassage size="lg">Status:</TextPassage>
      <TextPassage size="lg">{status}</TextPassage>
    </LayoutCluster>
  );
};

export const HotelCheckInOutAppointmentStatusLabel = connect((state, ownProps) => {
  const { petId } = ownProps;
  const engagement = getFirstHotelEngagementByPet(state, { petId });

  return {
    componentId: "hotelCheckInOut_HotelCheckInOUtStatusLabel",
    status: engagement?.status,
  };
})(AppointmentStatusLabel);
