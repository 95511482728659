import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { APPOINTMENT_STATUS } from "@/core/constants";
import {
  HOTEL_LABEL,
  OVERNIGHT_LABEL,
  ROOM_SERVICE_TYPE_GROUP,
  ROOM_SERVICE_TYPE_INDIVIDUAL,
} from "@/core/constants/hotelServicesGroupNameConstants";
import { timesOfDay } from "@/core/constants/timeOfDayConstants";

/**
 * @method convertTimeOfDayToObj
 * @param {array} timeOfDay
 * @returns {object}
 */
export const convertTimeOfDayToObj = (timeOfDay = []) => {
  // In the UI we add food & med timeOfDay values with the first character
  // uppercase (Afternoon), but the Cart API uses all lowercase values so
  // we need to handle both when converting the timeOfDay array to booleans
  return {
    morning: timeOfDay?.some(time => time.toLowerCase() === "morning"),
    afternoon: timeOfDay?.some(time => time.toLowerCase() === "afternoon"),
    evening: timeOfDay?.some(time => time.toLowerCase() === "evening"),
  };
};

/**
 * @method convertTimeOfDayToObj
 * @param {array} timeOfDay
 * @returns {object}
 */
export const convertTimeOfDayObjToArr = ({ morning, afternoon, evening } = {}) => {
  const timeOfDay = [
    morning && timesOfDay.MORNING,
    afternoon && timesOfDay.AFTERNOON,
    evening && timesOfDay.EVENING,
  ];
  return timeOfDay.filter(str => !!str);
};

/**
 *  Helper to check if room is null for overnight reservation
 *
 *  @memberOf Utils.Engagement
 *  @function
 *  @name isRoomAssignedEmptyForOvernightService
 *  @param {String} roomNumber - room number from itinerary room meta data
 *  @param {Boolean} isUnassigned - isUnassigned value from itinerary room meta data
 *  @param {String} engagementType - engagementType from current engagement
 *  @returns {Boolean}
 *  @example
 *
 *  isRoomAssignedEmptyForOvernightService(roomNumber, engagementType)
 */
export const isRoomAssignedEmptyForOvernightService = (
  engagementRoomNumber,
  engagementRoomIsUnassigned,
  engagementType,
) => {
  const isOvernightService = engagementType === engagementTypes.OVERNIGHT_BOARDING;
  const isRoomNullorUnassigned = !(engagementRoomIsUnassigned || engagementRoomNumber);

  return isOvernightService && isRoomNullorUnassigned;
};

/**
 *  Helper to format room number label based on groupName form occupany details
 *  or based on checked out status
 *  Sample Response:
 *  "rooms" : [
 *     {
 *         "roomName" : "DDC Play Room - 133 - 201",
 *          "roomNumber" : 201,
 *           "petServiceType" : "Doggie Day Camp",
 *          "productFamily": "Day Camp",
 *          "isGroup": true,
 *          "isAssignable" : true,
 *          "occupancyDetails" : [
 *              {
 *                  "occupantPetId" : 1234,
 *                  "dateOfOccupancy" : "10/21/2022",
 *                  "customerId" : "1000258827",
 *                  "itineraryId" : "00666986",
 *                  "status" : "Checked-In",
 *                  "groupName" : "Doggie Day Camp",
 *                  "groupId" : 4567
 *              }
 *          ],
 *          "suspensionDetails" : []
 *      }
 *    ]
 *
 *  @memberOf Utils.Engagement
 *  @function
 *  @name formatHotelRoomSelectionLabel
 *  @param {Object} room - room data from room availability API
 *  @returns {string} - "201" or "201 (Checked-Out)" or "201 (Day Care Ind)" or "201 (Day Care Ind) & (Hotel Grp)"
 *  @example
 *
 *  formatHotelRoomSelectionLabel(room)
 */
export const formatHotelRoomSelectionLabel = room => {
  const { occupancyDetails, isGroup, productFamily } = room;

  // Returns plain room number when no isGroup property or no product family or no occupany details
  if (isGroup === null || productFamily === null || !occupancyDetails.length)
    return `${room.roomNumber}`;

  // Returns Room number with Checked-Out Label if status is checked out
  const hasRoomCheckedOut = occupancyDetails.every(
    petOccupancy => petOccupancy.status === APPOINTMENT_STATUS.CHECKED_OUT,
  );

  if (hasRoomCheckedOut) return `${room.roomNumber} (${APPOINTMENT_STATUS.CHECKED_OUT})`;

  // Labels are generated based on 2 properties on room object
  // 1. isGroup: boolean; 2. productFamily: string;
  const serviceType = isGroup ? ROOM_SERVICE_TYPE_GROUP : ROOM_SERVICE_TYPE_INDIVIDUAL;
  const formattedProductFamily = productFamily?.includes(OVERNIGHT_LABEL)
    ? HOTEL_LABEL
    : productFamily;

  return `${room.roomNumber} (${formattedProductFamily} ${serviceType})`;
};
