// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-textInput{
    line-height: 1.6rem;
    padding: 0.5rem;
    font-size: 1.125rem;
    border: 1px solid rgb(118, 118, 118);
}

psm-textInput--disabled{
    /*no styles at this point*/
}
`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/components/TextInput/textInput.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,oCAAoC;AACxC;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".psm-textInput{\n    line-height: 1.6rem;\n    padding: 0.5rem;\n    font-size: 1.125rem;\n    border: 1px solid rgb(118, 118, 118);\n}\n\npsm-textInput--disabled{\n    /*no styles at this point*/\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
