import React from "react";
import get from "lodash/fp/get";
import { connect } from "react-redux";
import moment from "moment";

// Components
import { DayOfWeekText } from "../DayOfWeek";
import { AssociateWorkHoursTimeRange } from "web/associateScheduling/weeklyTable/timeRange/TimeRangeComponent";
import DayType from "../dayType/DayTypeContainer";
import {
  CopyPreviousDayForAssociate,
  ResetDayForAssociate,
} from "web/associateScheduling/weeklyTable/copyPreviousDay/associateScheduleDay";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { Heading } from "@petsmart-ui/sparky";
import { SplitShiftCheckbox } from "./SplitShiftCheckbox";
import { PetTypeRadioButtons } from "./PetTypeRadioButtons";

// Selectors
import {
  getStartWeekDate,
  getIsCatGroomingHidden,
  getWorkingHoursWithTemp,
  getWorkHours,
} from "@/core/selectors/schedulesSelectors";
import { getSalonHours } from "@/core/selectors/salonHoursSelector";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getSchedulesModalType } from "@/core/selectors/modals/schedulesModalSelectors";

// Styles
import styles from "./weeklyRow.module.css";

// Utils
import { formatCalendarDateMoment } from "@/core/utils/dateUtils/formatDateTime";
import { getLunchBreak } from "@/core/utils/schedulesUtils/schedulesUtils";

/**
 *  React view component for the rows in the associate schedule table
 *  @summary Located on the associate-scheduling page
 *  @memberOf Views.Associate
 *  @function
 *  @name WeeklyRowComponent
 *  @param {Object} props - props passed into the view component
 *  @param {Object[]} props.workHours
 *  @param {Object[]} props.workHoursWithTemp
 *  @param {Object[]} props.rowData - day activities
 *  @param {String} props.dayOfWeek
 *  @param {String} props.startWeekDate
 *  @param {Boolean} props.readOnly
 *  @param {Boolean} props.isTemplateMode
 *  @param {Boolean} props.isCatGroomingHidden
 *  @param {Function} props.selectPetSupportedType
 *  @param {Object} props.lunchHours - day activity where absence reason is lunch break
 *  @param {Object} props.weekDayDate
 *  @param {Boolean} props.isSalonClosed
 *  @returns {JSX.Element}
 *  @example const component = connect()(WeeklyRowComponent)
 */
const WeeklyRowComponent = props => {
  const {
    componentId,
    workHours,
    workHoursWithTemp,
    rowData,
    dayOfWeek,
    startWeekDate,
    readOnly,
    isTemplateMode,
    isCatGroomingHidden,
    lunchHours,
    weekDayDate,
    isSalonClosed,
  } = props;

  const tdStyles = { padding: "10px 20px", border: 0 };

  return (
    <tr id={componentId} className={styles.weeklyRow}>
      {/* Day of week label */}
      <td style={tdStyles}>
        {dayOfWeek && (
          <DayOfWeekText
            dayOfWeek={dayOfWeek}
            startWeekDate={startWeekDate}
            isTemplateMode={isTemplateMode}
          />
        )}
      </td>

      <td style={tdStyles}>
        <LayoutStack>
          {isSalonClosed && (
            <Heading size="body-bold" tagName="div">
              Store Closed
            </Heading>
          )}

          {/* Day type dropdown */}
          <DayType
            isSplitShift={workHours.length > 1}
            partialRow={workHours[0]}
            readOnly={readOnly}
            isTemplateMode={isTemplateMode}
            isSalonClosed={isSalonClosed}
            dayOfWeek={dayOfWeek}
          />

          {/* Split Shift checkbox */}
          {!isCatGroomingHidden && (
            <SplitShiftCheckbox
              dayOfWeek={dayOfWeek}
              workHours={workHours}
              lunchHours={lunchHours}
            />
          )}
        </LayoutStack>
      </td>

      {/* Pet type radios */}
      {!isCatGroomingHidden && (
        <td style={{ ...tdStyles, whiteSpace: "noWrap" }}>
          <PetTypeRadioButtons workHours={workHours} dayOfWeek={dayOfWeek} />
        </td>
      )}

      {/* Work Hours */}
      <td style={tdStyles}>
        <LayoutStack>
          {workHoursWithTemp?.map(partialRow => (
            <AssociateWorkHoursTimeRange
              key={partialRow.localId}
              partialRow={partialRow}
              readOnly={readOnly}
              weekDayDate={weekDayDate}
              dayActivities={rowData}
            />
          ))}
        </LayoutStack>
      </td>

      {/* Lunch Hours */}
      <td style={tdStyles}>
        {lunchHours && (
          <AssociateWorkHoursTimeRange
            partialRow={lunchHours}
            readOnly={readOnly}
            dayActivities={rowData}
          />
        )}
      </td>

      {/* Reset & Copy buttons */}
      <td style={tdStyles}>
        {!readOnly && dayOfWeek && (
          <LayoutBox padding="scale-0">
            <LayoutStack space={"scale-S3"}>
              <ResetDayForAssociate dayOfWeek={dayOfWeek} />
              <CopyPreviousDayForAssociate dayOfWeek={dayOfWeek} />
            </LayoutStack>
          </LayoutBox>
        )}
      </td>
    </tr>
  );
};

/**
 * Redux Connect function for the WeeklyRowComponent
 * @see {@link Views.Associate.WeeklyRowComponent}
 * @summary Located on the associate-scheduling page
 * @memberOf Views.Associate
 * @function
 * @name WeeklyRow
 * @param {Object} props
 * @param {String} props.dayOfWeek
 * @param {String} props.associateId
 * @param {Object[]} props.rowData - array of day activities containing work hours
 * @param {Boolean} props.isTemplateMode
 * @param {Boolean} props.readOnly
 * @returns {JSX.Element|null}
 * @example <WeeklyRow />
 */
export const WeeklyRow = connect((state, { associateId, rowData, dayOfWeek }) => {
  const storeNumber = getStoreNumber(state);
  const isCatGroomingHidden = getIsCatGroomingHidden(state, { associateId });
  const workHours = getWorkHours(rowData, dayOfWeek);
  const workHoursWithTemp = getWorkingHoursWithTemp(rowData, dayOfWeek);
  const modalType = getSchedulesModalType(state);
  const startWeekDate = getStartWeekDate(state);
  const salonHours = getSalonHours(state, { storeNumber });
  const weekDayDate = formatCalendarDateMoment(moment(startWeekDate).isoWeekday(dayOfWeek));
  const daySalonHours = get(weekDayDate, salonHours);
  const isSalonClosed = get(["IsClosed"], daySalonHours);

  return {
    componentId: `WeeklyRow_${dayOfWeek}`,
    workHours,
    workHoursWithTemp,
    modalType,
    startWeekDate,
    isCatGroomingHidden,
    lunchHours: getLunchBreak(rowData),
    weekDayDate,
    isSalonClosed,
  };
})(WeeklyRowComponent);
