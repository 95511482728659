// scheduled suspension key values as they come back from the API
// -------------------------------------------------------------------------------------------------
export const SUSPENSION_TYPE_KEY = "suspensionType";
export const START_DATE_KEY = "startDate";
export const REASON_NAME_KEY = "reasonName";
export const STATUS_KEY = "status";
export const SUSPENSION_ID_KEY = "suspensionId";

// Column Titles to be use in the suspension schedule table
// -------------------------------------------------------------------------------------------------
export const DATE_TITLE = "Date";
export const SUSPENDED_SERVICE_TITLE = "Suspended Service";
export const REASON_TITLE = "Reason";
export const STATUS_TITLE = "Status";
export const ACTIONS_TITLE = "Actions";
