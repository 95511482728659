import moment from "moment";

export const DEFAULT_STAGGER = 1;

export const modalTypes = {
  MANUAL_BOOKING_WARNING: "MANUAL_BOOKING_WARNING",
};

export const columnHeaders = {
  ARRIVALS: "arrivals",
  DEPARTURES: "departures",
  OVERNIGHT: "overnight",
  CHECKED_OUT: "checkedOut",
};

export const GO_LIVE_DATE = moment("2019-03-04");
