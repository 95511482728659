/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const TOGGLE_SCHEDULE_SUSPENSION_CANCEL_MODAL = "TOGGLE_SCHEDULE_SUSPENSION_CANCEL_MODAL";

export const CANCEL_SCHEDULED_SUSPENSION = "CANCEL_SCHEDULED_SUSPENSION";
export const CANCEL_SCHEDULED_SUSPENSION_REQUEST = "CANCEL_SCHEDULED_SUSPENSION_REQUEST";
export const CANCEL_SCHEDULED_SUSPENSION_SUCCESS = "CANCEL_SCHEDULED_SUSPENSION_SUCCESS";
export const CANCEL_SCHEDULED_SUSPENSION_FAILURE = "CANCEL_SCHEDULED_SUSPENSION_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
// show/hide modal
export const toggleScheduleSuspensionCancelModal = ({ suspensionId, startDate }) => ({
  type: TOGGLE_SCHEDULE_SUSPENSION_CANCEL_MODAL,
  suspensionId,
  startDate,
});