import { connect } from "react-redux";
import { getIsAnyBulkPackageOfferingSelected } from "../../../bgm/bulkPackageOfferings/bulkPackageOfferingsSelectors";
import StandaloneAccordion from "./StandaloneServicesAccordionComponent";

const mapStateToProps = state => {
  return {
    // Hide standalone services accordion when we are in the BGM flow.
    isHidden: getIsAnyBulkPackageOfferingSelected(state),
  };
};

export default connect(mapStateToProps)(StandaloneAccordion);
