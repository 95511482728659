import { connect } from "react-redux";
import { getSystemBookingFlow } from "../setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import { Layout } from "@prism/psm-ui-components";

const mapStateToProps = state => {
  return {
    isHidden: getSystemBookingFlow(state) !== systemName.SALON,
    id: "petParentProfileSalonContent-GenericContentWrapper",
    as: "section",
  };
};

// If no mapDispatchToProps fn is passed, then dispatch gets passed as a prop,
// if the component being wrapped uses prop spreading this could cause errors.
// To prevent this we pass a function returning an empty obj
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Layout.Stack);
