import { associateWebProfileAPI } from "./_axiosConfigAssociateWebProfile";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Adds a Veterinarian or Clinic to an existing customer from the Web Associate Profile API -  Adds a Veterinarian/Clinic to an existing customer end point
 * @param {*} { customerKey, sourceId, petId, data }
 * @returns
 */
export function postVet({ customerKey, sourceId, petId, data }) {
  return associateWebProfileAPI.post(
    `/${endpointVersionString()}/customers/${customerKey}/pets/${petId}/vet-clinics?sourceId=${sourceId}`,
    data
  );
}

/**
 * Delete an existing vet-clinic from the Web Associate Profile API - Delete an existing vet-clinic end point
 * @param {*} { customerKey, petId, clinicId, sourceId }
 * @returns
 */
export function deleteVet({ customerKey, petId, clinicId, sourceId }) {
  return associateWebProfileAPI.delete(
    `/${endpointVersionString()}/customers/${customerKey}/pets/${petId}/vet-clinics/${clinicId}?sourceId=${sourceId}`
  );
}
