import { connect } from "react-redux";
import { headerConfigConstants } from "core/constants/headerConfigConstants";
import { getJobRole } from "core/selectors/persistentSelectors";
import AppHeader from "../../web/header/AppHeader";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { setHotelHeaderBanner } from "core/utils/imageUtils/dateBasedBanners";

// TODO: move to AppHeader.js so all containers are co-located with the component.
const mapStateToProps = (state, ownProps) => {
  const bookingFlow = getSystemBookingFlow(state);

  return {
    isHidden: bookingFlow !== systemName.HOTEL,
    componentID: "header--Dashboard-Hotel",
    config: headerConfigConstants.DASHBOARD,
    jobRole: getJobRole(state, ownProps),
    backgroundImage: setHotelHeaderBanner(),
  };
};

export default connect(mapStateToProps)(AppHeader);
