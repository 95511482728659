export const FIVE_WEEK_GRID_THRESHOLD = 35;
export const FIVE_WEEK_GRID_NUMBER_OF_DAYS = 35;
export const SIX_WEEK_GRID_NUMBER_OF_DAYS = 42;

export const DAYS_OF_WEEK = [
  { id: 'Sun', label: 'Sun' },
  { id: 'Mon', label: 'Mon' },
  { id: 'Tue', label: 'Tue' },
  { id: 'Wed', label: 'Wed' },
  { id: 'Thu', label: 'Thu' },
  { id: 'Fri', label: 'Fri' },
  { id: 'Sat', label: 'Sat' },
];
