import React from "react";
import styled from "styled-components";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 371px;
`;

export default function ConfirmationModalContentCopy() {
  return (
    <SectionWrapper>
      Are you sure you want to void this transaction?
      <br />
      If there are multiple pets in the order, all items will be voided with this action.
    </SectionWrapper>
  );
}
