import { connect } from "react-redux";
import getOtherAddresses from "@/core/selectors/customer/address/getOtherAddresses";
import { getCountriesOptions, getStatesOptions } from "@/core/selectors/enumsSelectors";
import PetParentAddressList from "@/web/common/Address/PetParentAddressList";
import getDisplayOtherAddresses from "@/core/selectors/customer/address/getDisplayOtherAddresses";
import { getIsOnlineAccount } from "@/core/selectors/petParentProfileSelectors";

const mapStateToProps = (state, { customerKey }) => {
  return {
    customerKey,
    addressList: getOtherAddresses(state, { customerKey }),
    countryOptions: getCountriesOptions(state),
    stateOptions: getStatesOptions(state),
    isHidden: !getDisplayOtherAddresses(state),
  };
};

export default connect(mapStateToProps)(PetParentAddressList);
