import { NAME_REGEX } from "../../constants/regex";

/** @module nameValidation */

/**
 * Verify if a string is a valid Name
 * @param {string} nameString
 * @return {boolean} A boolean if the string is a valid name or not
 */
export default function verifyName(nameString) {
  const regXExpression = NAME_REGEX;
  return regXExpression.test(nameString);
}
