import * as Yup from "yup";
import React from "react";
import { Formik } from "formik";
import Form from "../Form";
import withSaveOnClickOutside from "../hoc/withSaveOnClickOutside";
import FormInput from "../FormInput";
import isValidPostalCode from "../../../core/utils/validationUtils/postalCodeValidation";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../../core/constants/validationErrors";
import AddAddressButton from "../../petParentProfile/petParent/petParentForm/AddressInput/AddAddressButtonContainer";
import DeleteAddressButton from "./DeleteAddressButtonContainer";
import formatPhoneInputField from "../../../core/utils/stringManipulationUtils/formatPhoneInputField";
import { phoneSchema } from "../../../core/constants/validationSchema";
import AddressNotificationMessageContainer from "@/dux/customerAddresses/AddressNotificationMessageContainer";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import LoadingWrapper from "../LoadingWrapper";
import { PetParentZipCodeInput } from "@/dux/searchAddressByZip/searchAddressByZip";

/* * ----------------------------------------------------------------------- * *\
  Field Form Validation
\* * ----------------------------------------------------------------------- * */

const petParentZipCodeSchema = Yup.string().when("countryAbbreviation", (country, schema) =>
  schema.test("zipPostalCode", "Not a valid zipcode", zipCode =>
    isValidPostalCode(zipCode, country),
  ),
);

// NOTE: Street 2 is optional
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  lastName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  streetLine1: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  streetLine2: Yup.string(),
  city: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  stateProvinceAbbreviation: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  zipPostalCode: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .concat(petParentZipCodeSchema),
  countryAbbreviation: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  phoneNumber: Yup.string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .concat(phoneSchema),
});

/* * ----------------------------------------------------------------------- * *\
  View Component
\* * ----------------------------------------------------------------------- * */
const CustomerAddress = ({
  componentId,
  isHidden,
  formikRef,
  clickOutsideRef,
  onSubmit,
  handleFocus,
  address,
  addressId,
  countryOptions,
  stateOptions,
  customerKey,
  isDisabled,
  isAddAddressButtonShown,
  isLoading,
  isZipSearchLoading,
}) => {
  if (isHidden) return null;

  return (
    <LoadingWrapper isLoading={isLoading} displayContainer="block">
      <LayoutBox id={componentId} padding="scale-0">
        <LayoutStack space="scale-0">
          <AddressNotificationMessageContainer customerKey={customerKey} addressId={addressId} />
          <DeleteAddressButton customerKey={customerKey} addressId={addressId} />
          <Formik
            initialValues={address}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validationSchema}
            ref={formikRef}
            render={({ values, isValid, setFieldValue }) => (
              <Form
                innerRef={isAddAddressButtonShown || isZipSearchLoading ? null : clickOutsideRef}
                onFieldFocus={handleFocus}
              >
                {/* First Name Input */}
                <FormInput
                  id={componentId}
                  modifier="firstName"
                  showErrorOnLoad={!customerKey}
                  label="First name"
                  name="firstName"
                  disabled={isDisabled}
                />
                <FormInput
                  id={componentId}
                  modifier="lastName"
                  showErrorOnLoad={!customerKey}
                  label="Last name"
                  name="lastName"
                  disabled={isDisabled}
                />
                <FormInput
                  id={componentId}
                  modifier="streetLine1"
                  showErrorOnLoad={!customerKey}
                  label="Street Address"
                  name="streetLine1"
                  disabled={isDisabled}
                />

                {/* Street Address Line 2 */}
                <FormInput
                  id={componentId}
                  modifier="streetLine2"
                  label="Apt, Floor, Unit, etc."
                  name="streetLine2"
                  disabled={isDisabled}
                />

                {/* City Input */}
                <FormInput
                  id={componentId}
                  modifier="city"
                  showErrorOnLoad={!customerKey}
                  label="City"
                  name="city"
                  disabled={isDisabled}
                />

                {/* State DropDown */}
                <FormInput
                  id={componentId}
                  modifier="stateProvinceAbbreviation"
                  showErrorOnLoad={!customerKey}
                  label="State/Province"
                  type="select"
                  isClearable
                  options={stateOptions}
                  name="stateProvinceAbbreviation"
                  disabled={isDisabled}
                />

                {/* Postal Input */}
                <PetParentZipCodeInput
                  componentId={componentId}
                  modifier="zipPostalCode"
                  showErrorOnLoad={!customerKey}
                  label="Zip/Postal Code"
                  name="zipPostalCode"
                  disabled={isDisabled}
                  value={values.zipPostalCode}
                  setFieldValue={setFieldValue}
                  formId={addressId ?? componentId}
                />

                {/* Country Dropdown */}
                <FormInput
                  id={componentId}
                  modifier="countryAbbreviation"
                  showErrorOnLoad={!customerKey}
                  label="Country"
                  type="select"
                  isClearable
                  options={countryOptions}
                  name="countryAbbreviation"
                  disabled={isDisabled}
                />

                <FormInput
                  id={componentId}
                  modifier="phoneNumber"
                  showErrorOnLoad={!customerKey}
                  label="Phone"
                  name="phoneNumber"
                  value={formatPhoneInputField(values.phoneNumber)}
                  disabled={isDisabled}
                />

                <AddAddressButton
                  type="button"
                  isAddAddressButtonShown={isAddAddressButtonShown}
                  onClick={() => onSubmit(values)}
                  isValid={isValid}
                />
              </Form>
            )}
          />
        </LayoutStack>
      </LayoutBox>
    </LoadingWrapper>
  );
};

export default withSaveOnClickOutside(CustomerAddress);
