import React from "react";
import styled from "styled-components";

const Icon = styled.img`
  width: 1em;
  margin-left: 3px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
      cursor: default;
      pointer-events: none;
    `};
`;

export default function StarIconComponent(props) {
  const { buttonClickHandler } = props;
  return <Icon {...props} onClick={() => buttonClickHandler(props.value, props.customer)} />;
}
