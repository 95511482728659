import { buildEngagementAddonUpdatesForPut } from "./buildEngagementsWithUpdatedAddons";
import isEmpty from "lodash/isEmpty";
import { updateCustomFrequencyDates } from "./updateCustomFrequencyDates";

// Helpers
const replacePetEngagementAddons = (originalEngagements, newEngagements) =>
  originalEngagements?.map((engagement, index) => ({
    ...engagement,
    addOns: newEngagements[index]?.addOns ?? [],
  }));

/**
 * Util to update addons for a pets engagements based on frequency and dates
 * @memberOf Utils.Engagement
 * @function
 * @name updatePetEngagementsAddons
 * @param {Object} args
 * @param {Object[]} args.addons - array of addons from original engagements
 * @param {string} args.timeZone
 * @returns {engagements => Object[]} function to update given engagements with foods and meds
 * @example updatePetEngagementsAddons({ addons })(petEngagements)
 */
export const updatePetEngagementsAddons = ({ addons, timeZone }) => petEngagements => {
  if (isEmpty(addons)) return petEngagements;

  // Recalculate customFrequencyDates based on updated engagements
  const dates = petEngagements?.map(({ startDatetime }) => startDatetime);
  const manualAddonsWithCorrectDates = addons
    ?.filter(addon => !addon?.isAutoApplyAddOn)
    ?.map(updateCustomFrequencyDates(dates));

  const engagementsWithoutAddons = replacePetEngagementAddons(petEngagements, []);
  return manualAddonsWithCorrectDates?.reduce((engagements, addon) => {
    const newEngagements = buildEngagementAddonUpdatesForPut({
      petEngagements: engagements,
      petAddons: manualAddonsWithCorrectDates,
      addonToEditId: addon?.addOnProductNumber,
      frequency: addon?.frequency,
      dates: addon?.customFrequencyDates,
      timeZone,
    });

    return replacePetEngagementAddons(engagementsWithoutAddons, newEngagements);
  }, petEngagements);
};
