import React from "react";
import styled from "styled-components";
import ProfilePetCardContainer from "../../../profilePetCard/ProfilePetCardContainer";
import { fontSizes } from "web/common/styles/responsive/fonts";

const AppointmentsTitle = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: ${fontSizes.big};
  margin-bottom: 2.3125rem;
`;

const AppointmentWrapper = styled.div`
  padding-bottom: 2.3125rem;
`;

const CommonAppointmentComponent = ({ isHidden, componentID, appointments, type, title }) => {
  if (!isHidden) {
    return (
      <div id={componentID}>
        {title && appointments.length > 0 && (
          <AppointmentsTitle id={`appointment-title-${type}`}>{title}</AppointmentsTitle>
        )}
        {appointments.map((appointment, key) => (
          <AppointmentWrapper id={`${appointment}-${key}`} key={appointment}>
            <ProfilePetCardContainer showDate appointmentID={appointment} type={type} />
          </AppointmentWrapper>
        ))}
      </div>
    );
  }

  return null;
};

export default CommonAppointmentComponent;
