import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";

const mapStateToProps = state => ({
  isHidden: getSystemBookingFlow(state) !== systemName.TRAINING,
  id: "petParentProfileTrainingContent-GenericContentWrapper",
  as: "section",
});

// If no mapDispatchToProps fn is passed, then dispatch gets passed as a prop,
// if the component being wrapped uses prop spreading this could cause errors.
// To prevent this we pass a function returning an empty obj
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Layout.Stack);
