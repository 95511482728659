import { createSelector } from "reselect";
import moment from "moment";
import { filter, find } from "lodash/fp";
import { getHotelArrivalsAppointments } from "dux/hotelArrivals/columnList/hotelArivalsSelector";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { getHotelDeparturesAppointments } from "dux/hotelDepartures/columnList/hotelDeparturesSelector";

/**
 * Selector to get ddc room capacity state
 * @memberOf Selectors.ddcRoomCapacity
 * @function
 * @name getDdcRoomCapacityState
 * @param {Object} state
 * @returns object
 * @example getDdcRoomCapacityState(state)
 */
export const getDdcRoomCapacityState = state => {
  return state?.ddcRoomCapacity;
};

/**
 * Selector to get capacity object from  ddc room capacity state
 * @memberOf Selectors.ddcRoomCapacity
 * @function
 * @name getDdcRoomCapacity
 * @param {Object} state
 * @returns array
 * @example getDdcRoomCapacity(state)
 */
export const getDdcRoomCapacity = createSelector(
  [getDdcRoomCapacityState],
  DdcCapacityState => DdcCapacityState?.capacity,
);

/**
 * Selector to get DDC and Play data for table on dashboard
 * @memberOf Selectors.ddcRoomCapacity
 * @function
 * @name getDdcCapacityAsArray
 * @param {Object} state
 * @returns array of objects with descriptions value
 * @example getDdcCapacityAsArray(state)
 */
export const getDdcCapacityAsArray = createSelector([getDdcRoomCapacity], capacity => {
  if (!capacity) return [];

  const capacityList = [
    {
      description: "Expected DDC Arrivals",
      value: capacity?.ddcLeftToCheckIn ?? "Loading",
    },
    {
      description: "DDC current guest count",
      value: capacity?.ddcCheckedIn ?? "Loading",
    },
    {
      description: "Expected Stay & Play Group arrivals",
      value: capacity?.stayAndPlayGroupLeftToCheckIn ?? "Loading",
    },
    {
      description: "Stay & Play Group current guest count",
      value: capacity?.stayAndPlayGroupCheckedIn ?? "Loading",
    },
    {
      description: "Total playroom guests for all types",
      value: capacity?.totalGroupPlayGuests ?? "Loading",
    },
    {
      description: "Total Stay & Play Individual guest count",
      value: capacity?.totalIndividualPlayGuests ?? "Loading",
    },
  ];

  return capacityList;
});

/**
 * Selector to get line item of DDC and Play table by index
 * @memberOf Selectors.ddcRoomCapacity
 * @function
 * @name getDdcRoomCapacityByIndex
 * @param {Number} index
 * @returns selector
 * @example getDdcRoomCapacityByIndex(index)(state)
 */
export const getDdcRoomCapacityByIndex = index =>
  createSelector([getDdcCapacityAsArray], capacityAsArray => capacityAsArray[index]);

/**
 * Selector to get status of same day service
 * @memberOf Selectors.ddcRoomCapacity
 * @function
 * @name getDDCSameDayStatus
 * @param {Object} state
 * @returns boolean
 * @example getDDCSameDayStatus(state)
 */
export const getDDCSameDayStatus = createSelector(
  [getDdcRoomCapacity],
  ({ isDdcOn } = {}) => isDdcOn,
);

/**
 * Selector to get status of boarded guest service
 * @memberOf Selectors.ddcRoomCapacity
 * @function
 * @name getDDCBoardedGuestStatus
 * @param {Object} state
 * @returns boolean
 * @example getDDCBoardedGuestStatus(state)
 */
export const getDDCBoardedGuestStatus = createSelector(
  [getDdcRoomCapacity],
  ({ isBoardedGuestOn } = {}) => isBoardedGuestOn,
);

/**
 * Selector to get status of stay and play group service
 * @memberOf Selectors.ddcRoomCapacity
 * @function
 * @name selectStayAndPlayGroupStatus
 * @param {Object} state
 * @returns boolean
 * @example selectStayAndPlayGroupStatus(state)
 */
export const selectStayAndPlayGroupStatus = createSelector(
  [getDdcRoomCapacity],
  ({ isStayAndPlayGroupOn } = {}) => isStayAndPlayGroupOn,
);

/**
 * Selector to get status of stay and play individual service
 * @memberOf Selectors.ddcRoomCapacity
 * @function
 * @name selectStayAndPlayIndivStatus
 * @param {Object} state
 * @returns boolean
 * @example selectStayAndPlayIndivStatus(state)
 */
export const selectStayAndPlayIndivStatus = createSelector(
  [getDdcRoomCapacity],
  ({ isStayAndPlayIndividualOn } = {}) => isStayAndPlayIndividualOn,
);

export const getDDCArrivals = createSelector([getHotelArrivalsAppointments], appointments => {
  // Filter any appointments with a primary service of full day DDC || half day DDC
  const halfOrFullDayDDC = filter(function(appointment) {
    return appointment.petService === "Full Day DDC" || appointment.petService === "Half Day DDC";
  }, appointments);

  // From there, filter any that are Booked
  const booked = filter(function(appointment) {
    return appointment.status === "Booked";
  }, halfOrFullDayDDC);

  // Filter any appointments with overnight boarding and a add-on of ddc
  // NOTE, there is no add-ons in the api response as of yet, so this is commented out. for now.
  const overNightBoarding = filter(function(appointment) {
    return appointment.petService === "Overnight Boarding"; // && appointment.addons === "DDC"
  }, appointments);

  // Combine all filtered appointments into a single array and return
  const combinedAppointments = booked.concat(overNightBoarding);

  return combinedAppointments;
});

export const getDDCDepartures = createSelector(
  [getHotelDeparturesAppointments, getSelectedDate],
  (appointments, selectedDate) => {
    // filter all appointments that are checked in, full or half day DDC
    const halfOrFullDdcCheckedIn = filter(function(appointment) {
      return (
        appointment.status === "Checked In" &&
        (appointment.petService === "Full Day DDC" || appointment.petService === "Half Day DDC")
      );
    }, appointments);

    // Filter all appointments that are checking out on the selected date, are overnight and have an addon as DDC
    // NOTE, there is no add-ons in the api response as of yet, so this is commented out. for now.
    const overNightDDCAddon = filter(function(appointment) {
      const isCheckoutOnSelectedDay = moment(appointment.checkOutDateTime).isSame(
        selectedDate,
        "day",
      );

      return isCheckoutOnSelectedDay && appointment.petService === "Overnight Boarding"; // && appointment.addons === "DDC"
    }, appointments);

    // Show me all the filtered appointments.
    return halfOrFullDdcCheckedIn.concat(overNightDDCAddon);
  },
);
