import React from "react";
import { connect } from "react-redux";
import { selectEnhancedAddonChildrenFromEngagements } from "dux/hotelEngagements/hotelEngagementSelectors";
import { LayoutBox } from "@/layout/box/Box";
import { HotelBookingCartIncludedAddon, HotelCartIncludedAddon } from "./HotelCartAddon";
import { selectEnhancedAddonChildrenFromCart } from "../servicesCartHotel/servicesCartHotelSelectors";

export const IncludedAddons = ({ componentId, childAddons = {}, diComp }) => {
  const IncludedAddon = diComp?.includedAddon;
  return (
    <LayoutBox id={componentId} padding="scale-0">
      <ul style={{ margin: 0 }}>
        {Object.entries(childAddons)?.map(([key, addon]) => (
          <li key={key}>
            <IncludedAddon addon={addon} />
          </li>
        ))}
      </ul>
    </LayoutBox>
  );
};

export const HotelCheckInOutCartIncludedAddons = connect((state, { addonId, petId }) => {
  return {
    componentId: "HotelCheckInOutCartIncludedAddons",
    childAddons: selectEnhancedAddonChildrenFromEngagements(addonId)(state, {
      petId,
    }),
    diComp: {
      includedAddon: HotelCartIncludedAddon,
    },
  };
})(IncludedAddons);

export const HotelBookingCartIncludedAddons = connect((state, { addonId, petId }) => {
  return {
    componentId: "HotelBookingCartIncludedAddons",
    childAddons: selectEnhancedAddonChildrenFromCart({ petId, productId: addonId })(state),
    diComp: {
      includedAddon: HotelBookingCartIncludedAddon,
    },
  };
})(IncludedAddons);
