import React from "react";

const TotalPriceHotelBookingComponent = ({ totalPrice, label, componentId }) => {
  return (
    <div id={componentId}>
      <div>{label}</div>
      <div>{totalPrice}</div>
    </div>
  );
};

export default TotalPriceHotelBookingComponent;
