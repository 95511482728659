// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*------------------------------------*\\
    # HR (HORIZONTAL RULE)
\\*------------------------------------*/

/**
 * 1) Line that acts as a separator of content
 *
 */
.prism-hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e3e3e3;
}

/**
 * Hr Thick
 * 1) Horizontal rule with a thick border
 */
.prism-hr--lg {
    border-bottom-width: 4px;
}

/**
 * Hr Brand
 * 1) Branded horizontal rule
 */
.prism-hr--brand {
    border-color: #206ef6;
}`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/components/HR/Hr.css"],"names":[],"mappings":"AAAA;;uCAEuC;;AAEvC;;;EAGE;AACF;IACI,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,wBAAwB;IACxB,0BAA0B;IAC1B,4BAA4B;AAChC;;AAEA;;;EAGE;AACF;IACI,wBAAwB;AAC5B;;AAEA;;;EAGE;AACF;IACI,qBAAqB;AACzB","sourcesContent":["/*------------------------------------*\\\n    # HR (HORIZONTAL RULE)\n\\*------------------------------------*/\n\n/**\n * 1) Line that acts as a separator of content\n *\n */\n.prism-hr {\n    margin-top: 2rem;\n    margin-bottom: 2rem;\n    border-top: none;\n    border-right: none;\n    border-left: none;\n    border-bottom-width: 1px;\n    border-bottom-style: solid;\n    border-bottom-color: #e3e3e3;\n}\n\n/**\n * Hr Thick\n * 1) Horizontal rule with a thick border\n */\n.prism-hr--lg {\n    border-bottom-width: 4px;\n}\n\n/**\n * Hr Brand\n * 1) Branded horizontal rule\n */\n.prism-hr--brand {\n    border-color: #206ef6;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
