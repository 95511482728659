import { bookingAPI } from "./_axiosConfigSystemServicesBooking";

/**
 * API to get store settings
 * confluence https://petsmart.atlassian.net/wiki/spaces/PART/pages/418939538/Settings+API
 * @param {string|number} storeNumber
 * @returns
 */
export function fetchStoreSettings(storeNumber) {
  return bookingAPI.get(`settings/v1/configurations/stores/${storeNumber}`);
}
