import { SET_ACTIVE_TAB } from "./appointmentsListActions";
import { tabFilters } from "./appointmentsListConstants";

const initialState = { selectedTab: tabFilters.APPOINTMENTS };

const appointmentsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    default:
      return state;
  }
};

export default appointmentsListReducer;
