import maxPerBlockOrCheckReductionReasonsActionTypes from "../actionTypes/maxPerBlockOrCheckReductionReasonsActionTypes";

const initialState = [];

const maxPerBlockOrCheckReductionReasonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case maxPerBlockOrCheckReductionReasonsActionTypes.LOAD_MAX_PER_BLOCK_REDUCTION_REASONS_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export default maxPerBlockOrCheckReductionReasonsReducer;
