import {
  SET_VOID_TRANSACTION_REASON,
  SET_VOID_TRANSACTION_REASON_NOTES,
} from "dux/voidTransactionReasons/voidTransactionReasonsActions";
import voidTransactionActionTypes from "../actionTypes/voidTransactionActionTypes";

const initialState = {
  voidTransactionReason: "",
  voidTransactionReasonsList: [],
  voidTransactionReasonNotes: "",
};

const voidTransactionsReasonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case voidTransactionActionTypes.LOAD_VOID_TRANSACTIONS_REASONS_SUCCESS:
      return {
        ...state,
        voidTransactionReasonsList: action.payload,
      };
    case SET_VOID_TRANSACTION_REASON:
      return {
        ...state,
        voidTransactionReason: action.reason,
      };
    case SET_VOID_TRANSACTION_REASON_NOTES:
      return {
        ...state,
        voidTransactionReasonNotes: action.voidReasonNotes,
      };
    default:
      return state;
  }
};

export default voidTransactionsReasonsReducer;
