import { createSelector } from "reselect";
import groupBy from "lodash/fp/groupBy";
import { getAssociates } from "./entitiesSelector";
import { getUiWeb } from "./uiSelector";
import {
  getSearchFieldType,
  getSearchFieldValue,
  getSearchApplied,
} from "./searchSalonSRCSelector";
import { associateGroupConstants } from "../constants/associatesConstants";
import { getStoreNumber } from "./persistentSelectors";
import sortObject from "../utils/objectUtils/sortObject";

/**
 * Selector to get the applied tab filter
 * @memberOf Selectors.Entities
 * @function
 * @name getTabFilterApplied
 * @param {Object} state - redux state
 * @returns {string} current tab filter name
 * @example getTabFilterApplied(state)
 */
export const getTabFilterApplied = createSelector(
  [getUiWeb],
  uiWeb => uiWeb?.associates?.tabFilter || {},
);

/**
 * Selector to get all associates where the current store is their primary store
 * @memberOf Selectors.Entities
 * @function
 * @name getAssociatesOfCurrentStore
 * @param {Object} state - redux state
 * @returns {Object} associates
 * @example getAssociatesOfCurrentStore(state)
 */
export const getAssociatesOfCurrentStore = createSelector(
  [getStoreNumber, getAssociates],
  (storeNumber, associates = {}) =>
    Object.fromEntries(
      Object.entries(associates)?.filter(
        ([key, associate]) => associate.primaryStoreNumber === storeNumber,
      ),
    ),
);

/**
 * Selector to get all associates for the current store grouped by their employee group
 * @memberOf Selectors.Entities
 * @function
 * @name getAssociatesByGroup
 * @param {Object} state - redux state
 * @returns {Object} Object where keys are group types and values are arrays of associates
 * @example getAssociatesByGroup(state)
 */
export const getAssociatesByGroup = createSelector([getAssociatesOfCurrentStore], associates => {
  return sortObject(
    groupBy(associate => associate?.employeeGroupType, associates),
    (a, b) => {
      if (a === associateGroupConstants.OTHER) {
        return 1;
      }

      if (b === associateGroupConstants.OTHER) {
        return -1;
      }

      return a.localeCompare(b);
    },
    false,
  );
});

export const getAssociatesBySearchOrFilter = createSelector(
  [
    getAssociatesOfCurrentStore,
    getAssociatesByGroup,
    getSearchApplied,
    getTabFilterApplied,
    getSearchFieldType,
    getSearchFieldValue,
  ],
  (
    associates = {},
    associatesByGroup = {},
    searchApplied,
    tabFilterApplied,
    searchFieldType,
    searchFieldValue,
  ) => {
    return Object.values(
      tabFilterApplied === associateGroupConstants.ALL || !tabFilterApplied
        ? associates
        : associatesByGroup[tabFilterApplied] ?? {},
    ).filter(associate => {
      if (!searchApplied) {
        return true;
      }

      if (searchFieldType === "associateId") {
        return (
          associate?.oktaId &&
          associate?.oktaId &&
          associate?.oktaId.toString().includes(searchFieldValue)
        );
      }

      return (
        associate[searchFieldType] &&
        associate[searchFieldType]?.toLowerCase()?.includes(searchFieldValue?.toLowerCase())
      );
    });
  },
);
