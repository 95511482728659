import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Section from "../../../../common/Section";
import { displayAddPetParentAddressForm } from "../../../../../core/actionCreators/petParentProfileActionCreator";
import getDisplayAddPetParentAddressForm from "../../../../../core/selectors/customer/address/getDisplayAddPetParentAddressForm";
import getAddressesByCustomerID from "../../../../../core/selectors/customer/address/getAddressesByCustomer";
import getIsAddAddressVisible from "../../../../../core/selectors/customer/address/getIsAddAddressVisible";

const mapStateToProps = (state, { children, customerKey }) => {
  const displayAddPetParentAddress = getDisplayAddPetParentAddressForm(state);
  return {
    children,
    displayAddPetParentAddress,

    // Show top line styling if form is empty
    topLine: isEmpty(getAddressesByCustomerID(state, { customerKey })),
    title: displayAddPetParentAddress ? "Add address" : "",
    button: displayAddPetParentAddress ? "Hide" : "Add address",
    isVisible: getIsAddAddressVisible(state, { customerKey })
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchDisplayAddPetParentAddressForm: ({ isVisible }) =>
    dispatch(displayAddPetParentAddressForm({ isVisible }))
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  buttonClickHandler: () =>
    propsFromDispatch.dispatchDisplayAddPetParentAddressForm({
      isVisible: !propsFromState.displayAddPetParentAddress
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Section);
