import React from "react";
import { connect } from "react-redux";
import { showHotelCapacityDrawer } from "dux/hotelRoomCapacityModal/hotelRoomCapacityModalActions";
import ClickableText from "../../web/common/ClickableText";
import { Button } from "@petsmart-ui/sparky";
import { getHotelDrawerIsOpen } from "./hotelRoomCapacitySelector";
import { Icon } from "@petsmart-ui/sparky";

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => {
  const isDrawerOpen = getHotelDrawerIsOpen(state);
  return {
    componentID: "ClickableText__HotelRoomCapacity",
    isVisible: "true",
    children: isDrawerOpen ? <Icon name="chevron-left" /> : <Icon name="chevron-right" />,
    isDrawerOpen: isDrawerOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  onClick: isDrawerOpen => {
    dispatch(showHotelCapacityDrawer({ isDrawerOpen: isDrawerOpen }));
  },
});
const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () => {
    const { isDrawerOpen } = propsFromState;
    propsFromDispatch.onClick(!isDrawerOpen);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ClickableText);
