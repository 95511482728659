import { createSelector } from "reselect";
import moment from "moment";
import { dateTimeErrors } from "dux/hotelBookingDateTimeSelection/hotelBookingDateTimeConstants";
import { selectDDCHours, selectHotelHours } from "@/web/hotelHours/hotelHoursSelectors";

export const selectHotelHoursByDate = (date, isSameDay) =>
  createSelector([isSameDay ? selectDDCHours : selectHotelHours], hours => {
    return hours?.[date] || {};
  });

export const selectIsHotelClosed = (date, isSameDay) =>
  createSelector([selectHotelHoursByDate(date, isSameDay)], hours => {
    return hours?.IsClosed;
  });

export const selectTimeIsBeforeStoreOpen = ({ date, isSameDay, time }) =>
  createSelector([selectHotelHoursByDate(date, isSameDay)], hours => {
    return moment(time, "HH:mm").isBefore(moment(hours?.OpenTime, "HH:mm:ss"));
  });

export const selectTimeIsAfterStoreClose = ({ date, isSameDay, time }) =>
  createSelector([selectHotelHoursByDate(date, isSameDay)], hours => {
    return moment(time, "HH:mm").isAfter(moment(hours?.CloseTime, "HH:mm:ss"));
  });

export const selectEndDateTimeIsBeforeStartTime = times => {
  const { startDate, endDate, startTime, endTime } = times;

  if (moment(endDate).isBefore(moment(startDate))) return true;

  if (moment(startDate).isSame(moment(endDate))) {
    if (moment(endTime, "HH:mm").isBefore(moment(startTime, "HH:mm"))) {
      return true;
    }

    return false;
  }

  return false;
};

export const selectDateTimeAreEqual = ({ startDate, endDate, startTime, endTime }) => {
  const datesMatch = moment(startDate).isSame(moment(endDate));
  const timesMatch = moment(endTime, "HH:mm").isSame(moment(startTime, "HH:mm"));
  return datesMatch && timesMatch;
};

export const selectBookingDateTimeError = (times, isSameDay) =>
  createSelector(
    [
      selectIsHotelClosed(times?.startDate, isSameDay),
      selectIsHotelClosed(times?.endDate, isSameDay),
      selectTimeIsBeforeStoreOpen({ date: times?.startDate, time: times?.startTime, isSameDay }),
      selectTimeIsAfterStoreClose({ date: times?.startDate, time: times?.startTime, isSameDay }),
      selectTimeIsBeforeStoreOpen({ date: times?.endDate, time: times?.endTime, isSameDay }),
      selectTimeIsAfterStoreClose({ date: times?.endDate, time: times?.endTime, isSameDay }),
    ],
    (
      startDateClosed,
      endDateClosed,
      startBeforeOpen,
      startAfterClose,
      endBeforeOpen,
      endAfterClose,
    ) => {
      // Errors that block next step
      if (selectEndDateTimeIsBeforeStartTime(times)) return dateTimeErrors.BEFORE_START_TIME;
      if (selectDateTimeAreEqual(times)) return dateTimeErrors.START_END_MATCH;

      // Errors that don't block continuing to next step
      if (startDateClosed || endDateClosed) return dateTimeErrors.CLOSED;
      if (startBeforeOpen) return dateTimeErrors.START_BEFORE_OPEN;
      if (startAfterClose) return dateTimeErrors.START_AFTER_CLOSE;
      if (endAfterClose) return dateTimeErrors.END_AFTER_CLOSE;
      if (endBeforeOpen) return dateTimeErrors.END_BEFORE_OPEN;

      return null;
    },
  );
