import { connect } from "react-redux";
import { get } from "lodash/fp";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import AddButton from "../../../../buttons/addButton";
import { getAssociateId } from "../../../../../../core/selectors/commonSelector";
import {
  getAppointmentsByAssociateAndDate,
  getAssociate,
} from "../../../../../../core/selectors/entitiesSelector";
import isInRoute from "../../../../../../core/utils/routeUtils/inRoute";
import { routePaths } from "../../../../../../core/constants/routePaths";
import { setAdditionalAppointmentAfterBreak } from "../../../../../../core/actionCreators/bookingActionCreators";
import { getFilteredByBreakData } from "../../../../../../core/selectors/schedulesSelectors";

const mapStateToProps = state => {
  const associateId = getAssociateId(state);
  const selectedDate = getSelectedDate(state);
  const associate = getAssociate(state, { associateId });
  const appointments = getAppointmentsByAssociateAndDate(state, { associateId, selectedDate })[
    associateId
  ];
  const filteredByBreakData = getFilteredByBreakData(state, {
    appointments,
    selectedDate,
    associateId,
  });

  return {
    buttonDisabled:
      get(["appointmentsAfterBreak", "length"], filteredByBreakData) < associate.maxPerBlock2,
    opacity: "1",
    isHidden: isInRoute(routePaths.DASHBOARD),
  };
};

const mapDispatchToProps = dispatch => ({
  buttonClickHandler: () => dispatch(setAdditionalAppointmentAfterBreak()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddButton);
