import React from "react";
import FutureAppointmentsListContainer from "../FutureAppointmentsList/FutureAppointmentsListContainer";
import PastAppointmentsListContainer from "../PastAppointmentsList/PastAppointmentsListContainer";
import NoAppointmentsContainer from "../common/NoAppointmentsContainer";

const AppointmentHistory = ({ isHidden, componentID }) => {
  if (!isHidden) {
    return (
      <div id={componentID}>
        <FutureAppointmentsListContainer />
        <PastAppointmentsListContainer />
        <NoAppointmentsContainer />
      </div>
    );
  }

  return null;
};

export default AppointmentHistory;
