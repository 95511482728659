/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelRoomCapacityActionTypes = {
  LOAD_HOTEL_ROOM_CAPACITY_SUCCESS: "LOAD_HOTEL_ROOM_CAPACITY_SUCCESS",
  LOAD_HOTEL_ROOM_CAPACITY_REQUEST: "LOAD_HOTEL_ROOM_CAPACITY_REQUEST",
  LOAD_HOTEL_ROOM_CAPACITY_FAILURE: "LOAD_HOTEL_ROOM_CAPACITY_FAILURE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const loadHotelRoomCapacitySuccess = ({ hotelCapacity }) => ({
  type: hotelRoomCapacityActionTypes.LOAD_HOTEL_ROOM_CAPACITY_SUCCESS,
  hotelCapacity,
});

export const loadHotelRoomCapacityRequest = ({ hotelCapacity }) => ({
  type: hotelRoomCapacityActionTypes.LOAD_HOTEL_ROOM_CAPACITY_REQUEST,
  hotelCapacity,
});

export const loadHotelRoomCapacityFailure = ({ error }) => ({
  type: hotelRoomCapacityActionTypes.LOAD_HOTEL_ROOM_CAPACITY_FAILURE,
  error,
});
