import React from "react";
import { Layout, Text, Button } from "@prism/psm-ui-components";
import { getAlertThemeStyles, getAlertIconColor } from "./utils";
import { CheckMarkSVG } from "@/dux/_components/checkmark/Checkmark";
import { AlertCloseIcon } from "./AlertCloseIcon";

export const Alert = ({ theme = "success", componentId, isHidden, message, style, onClose }) => {
  return (
    <Layout.Box
      id={componentId}
      isHidden={isHidden}
      padding="box-padding-4"
      style={{
        ...getAlertThemeStyles(theme),
        ...style,
      }}
    >
      <Layout.Stack>
        <Layout.Cluster justify="left" space="cluster-space-2">
          <CheckMarkSVG color={getAlertIconColor(theme)} />
          <Text>{message}</Text>
          <Button variant="no-outline" onClick={onClose}>
            <AlertCloseIcon />
          </Button>
        </Layout.Cluster>
      </Layout.Stack>
    </Layout.Box>
  );
};
