/**
 * Constant for the check out complete modal
 * @memberOf Constants.CheckOut
 */
export const checkOutComplete = {
  greatJob: "Great Job!",
  singlePetLeft: "has left the hotel",
  multiPetLeft: "have left the hotel",
  done: "Done",
  invoiceMsg: "The Customer hasn't paid yet - Printing Invoice",
  hasCheckedOut: "has been checked out.",
  futureResMsg: "Next booked appointment:",
  checkInTime: "Check-In Time:",
};
