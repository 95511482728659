import { put, takeEvery, call, all, select } from "redux-saga/effects";
import isEmpty from "lodash/fp/isEmpty";
import {
  deleteCartByIdEndpoint,
  deleteCartSpecialEndpoint,
  getCartByIdEndpoint,
  getCartByItineraryIdEndpoint,
  patchCartSpecialsEndpoint,
  postCartEndpoint,
  putCartEndpoint,
} from "@/core/services/cart/servicesCartEndpoints";
import { clearFoods, setFoods } from "@/web/food/actions/foodsActions";
import { getFoodsExcludingNewFoodID } from "@/web/food/foodsSelector";
import { clearMedications, setMedications } from "@/web/medication/actions/medicationsActions";
import { getMedsExcludingNewMedId } from "@/web/medication/medicationsSelector";
import { clearHotelBooking } from "web/features/hotelBookingFlow/hotelBookingFlowActions";
import { clearCartsForPets } from "dux/petCartCard/actions/setPetCartCardActions";
import { showBookingModal } from "@/core/actionCreators/bookingActionCreators";
import { clearError } from "@/core/actionCreators/errorActionCreators";
import { hotelBookingTypes } from "@/web/hotelAlerts/hotelBookingConstants";
import {
  POST_CART,
  postCartRequest,
  postCartSuccess,
  postCartFailure,
  PUT_CART,
  putCartRequest,
  putCartSuccess,
  putCartFailure,
  GET_CART_BY_ID,
  getCartByIdRequest,
  getCartByIdSuccess,
  getCartByIdFailure,
  GET_CART_BY_ITINERARY_ID,
  getCartByItineraryIdRequest,
  getCartByItineraryIdSuccess,
  getCartByItineraryIdFailure,
  setServicesCart,
  clearServicesCart,
  deleteCartRequest,
  deleteCartSuccess,
  deleteCartFailure,
  DELETE_CART,
  patchCartSpecialsRequest,
  patchCartSpecialsFailure,
  patchCartSpecialsSuccess,
  PATCH_CART_SPECIALS,
  DELETE_CART_SPECIAL,
  deleteCartSpecialRequest,
  deleteCartSpecialSuccess,
  deleteCartSpecialFailure,
  REBOOKING_POST_CART,
} from "./servicesCartActions";
import {
  setHotelCartDetails,
  setHotelCartPets,
  setHotelCartProducts,
} from "../servicesCartHotel/servicesCartHotelActions";
import { mergeFoodObjectsOrMedObjects, normalizeServicesCart } from "./servicesCartUtils";
import {
  selectCartPostObject,
  selectCartPutObject,
  selectServicesCart,
} from "./servicesCartSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import {
  selectHotelBookingPetServices,
  selectHotelBookingStartDate,
} from "@/web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import { onGetHotelAddOnsByPetService } from "../hotelAddons/HotelAddonsLIstSaga";
import { formatMoment } from "@/core/utils/dateUtils/formatDateTime";
import moment from "moment";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";

function* saveFoods([petId, foods]) {
  yield put(setFoods({ foods, petId }));
}

function* saveMeds([petId, medications]) {
  yield put(setMedications({ medications, petId }));
}

function* saveHotelCart(hotelCart) {
  const { pets, products, feedings, medications, cartDetails } = hotelCart;
  yield put(setHotelCartDetails(cartDetails));
  yield put(setHotelCartPets(pets));
  yield put(setHotelCartProducts(products));

  // Select current food state and merge the response foods into it
  const foods = yield select(getFoodsExcludingNewFoodID) || {};
  const foodsToSave = mergeFoodObjectsOrMedObjects(foods, feedings);
  yield put(clearFoods());
  yield all(Object.entries(foodsToSave).map(saveFoods));

  // Select current med state and merge the response meds into it
  const meds = yield select(getMedsExcludingNewMedId) || {};
  const medsToSave = mergeFoodObjectsOrMedObjects(meds, medications);
  yield put(clearMedications());
  yield all(Object.entries(medsToSave).map(saveMeds));
}

export function* saveCartResponse(cartResponse) {
  const { servicesCart, hotelCart, salonCart, trainingCart } = yield call(
    normalizeServicesCart,
    cartResponse,
  );
  yield put(setServicesCart(servicesCart));
  // Save hotel cart data
  yield call(saveHotelCart, hotelCart);
}

function* onPostCart({ cart }) {
  try {
    yield put(postCartRequest());
    const response = yield call(postCartEndpoint, { cart });
    const cartResponse = response?.data?.result;
    // Clear any leftover data in the services cart state before saving newly created cart
    yield put(clearServicesCart());
    yield call(saveCartResponse, cartResponse);

    yield put(postCartSuccess());
  } catch (error) {
    const customerKey = cart?.customerKey;
    const itineraryId = error?.response?.headers && error.response.headers["x-itinerary-id"];

    if (!itineraryId || !customerKey) {
      yield put(postCartFailure(error));
      return;
    }

    yield put(showBookingModal(hotelBookingTypes.PRE_EXISTING_RESERVATION_MODAL));
    yield put(postCartFailure({ itineraryId }));

    // Clear the error so that the generic error modal closes
    yield put(clearError(POST_CART));
  }
}

function* onRebookingPostCart() {
  const startDate = yield select(selectHotelBookingStartDate);
  const checkInDate = formatMoment(moment(startDate).utc());
  const petServices = yield select(selectHotelBookingPetServices);
  const storeNumber = yield select(getStoreNumber);

  // Call get addons for each pet with their selected service so that we can
  // check if any addons aren't available anymore before cart post call
  yield all(
    petServices?.map(({ petId, petServiceId }) =>
      onGetHotelAddOnsByPetService({ storeNumber, petId, petServiceId, checkInDate }),
    ),
  );

  const cart = yield select(selectCartPostObject, { isRebooking: true });
  yield call(onPostCart, { cart });
}

function* onPutCart({ cart }) {
  try {
    yield put(putCartRequest());

    // If the cart arg is missing then selectCartPutObject will be
    // used to select the data. Leaving cart as an option in case fields need
    // to be edited but the values aren't in global state
    const cartObj = !isEmpty(cart) ? cart : yield select(selectCartPutObject);
    const cartId = cartObj?.servicesCartId;

    // Don't even attempt the call if there isn't a cart ID, it will fail
    if (!cartId) {
      yield put(putCartFailure());
      return;
    }

    const response = yield call(putCartEndpoint, { cartId, cart: cartObj });
    const cartResponse = response?.data?.result;
    yield call(saveCartResponse, cartResponse);

    yield put(putCartSuccess());
  } catch (error) {
    yield put(putCartFailure(error));
  }
}

function* onPatchCartSpecials({ setManualSpecials }) {
  try {
    yield put(patchCartSpecialsRequest());

    const customerId = yield select(getCurrentCustomerKey);
    const currentCart = yield select(selectServicesCart);
    const cartId = currentCart?.servicesCartId;

    // Don't even attempt the call if there isn't data to send, it will fail
    if (!cartId || !setManualSpecials || !customerId) {
      yield put(patchCartSpecialsFailure());
      return;
    }

    const response = yield call(patchCartSpecialsEndpoint, {
      cartId,
      specialsData: { setManualSpecials },
      customerId,
    });
    const cartResponse = response?.data?.result;
    yield call(saveCartResponse, cartResponse);

    yield put(patchCartSpecialsSuccess());
  } catch (error) {
    yield put(patchCartSpecialsFailure(error));
  }
}

function* onDeleteCartSpecial({ specialCode, petId }) {
  try {
    yield put(deleteCartSpecialRequest());

    const currentCart = yield select(selectServicesCart);

    const cartId = currentCart?.servicesCartId;
    const customerKey = currentCart?.customerKey;

    const lob = "Hotel";

    // Don't even attempt the call if there isn't a cart ID, it will fail
    if (!cartId) {
      yield put(deleteCartSpecialFailure());
      return;
    }

    const response = yield call(deleteCartSpecialEndpoint, {
      cartId,
      specialCode,
      lob,
      customerKey,
      petId,
    });
    const cartResponse = response?.data?.result;
    yield call(saveCartResponse, cartResponse);
    yield put(deleteCartSpecialSuccess());
  } catch (error) {
    yield put(deleteCartSpecialFailure(error));
  }
}

function* onGetCartById({ cartId, customerId }) {
  try {
    yield put(getCartByIdRequest());
    const response = yield call(getCartByIdEndpoint, { cartId, customerId });
    const cartResponse = response?.data?.result;
    yield call(saveCartResponse, cartResponse);
    yield put(getCartByIdSuccess());
  } catch (error) {
    yield put(getCartByIdFailure(error));
  }
}

function* onGetCartByItineraryId({ itineraryId, customerId }) {
  try {
    yield put(getCartByItineraryIdRequest());
    const response = yield call(getCartByItineraryIdEndpoint, { itineraryId, customerId });
    const cartResponse = response?.data?.result;

    yield call(saveCartResponse, cartResponse);
    yield put(getCartByItineraryIdSuccess());
  } catch (error) {
    yield put(getCartByItineraryIdFailure(error));
  }
}

function* onDeleteCart({ cartId, customerKey, clearBookingState = true }) {
  try {
    yield put(deleteCartRequest());
    yield call(deleteCartByIdEndpoint, { cartId, customerKey });

    // Restart booking flow
    yield put(clearServicesCart());
    if (clearBookingState) yield put(clearHotelBooking());
    yield put(clearCartsForPets());

    yield put(deleteCartSuccess());
  } catch (error) {
    yield put(deleteCartFailure(error));
  }
}

function* watchOnPostCart() {
  yield takeEvery(POST_CART, onPostCart);
}
function* watchOnRebookingPostCart() {
  yield takeEvery(REBOOKING_POST_CART, onRebookingPostCart);
}

function* watchOnPutCart() {
  yield takeEvery(PUT_CART, onPutCart);
}

function* watchOnPatchCartSpecials() {
  yield takeEvery(PATCH_CART_SPECIALS, onPatchCartSpecials);
}

function* watchOnDeleteCartSpecial() {
  yield takeEvery(DELETE_CART_SPECIAL, onDeleteCartSpecial);
}

function* watchOnDeleteCart() {
  yield takeEvery(DELETE_CART, onDeleteCart);
}

function* watchOnGetCartById() {
  yield takeEvery(GET_CART_BY_ID, onGetCartById);
}

function* watchOnGetCartByItineraryId() {
  yield takeEvery(GET_CART_BY_ITINERARY_ID, onGetCartByItineraryId);
}

export default function* servicesCartSaga() {
  yield all([
    watchOnPostCart(),
    watchOnRebookingPostCart(),
    watchOnPutCart(),
    watchOnPatchCartSpecials(),
    watchOnDeleteCartSpecial(),
    watchOnDeleteCart(),
    watchOnGetCartById(),
    watchOnGetCartByItineraryId(),
  ]);
}
