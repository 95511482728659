export default {
  LOAD_ADDONS: "LOAD_ADDONS",
  LOAD_ADDONS_REQUEST: "LOAD_ADDONS_REQUEST",
  LOAD_ADDONS_SUCCESS: "LOAD_ADDONS_SUCCESS",
  LOAD_ADDONS_FAILURE: "LOAD_ADDONS_FAILURE",

  PUT_ADDONS: "PUT_ADDONS",
  PUT_ADDONS_REQUEST: "PUT_ADDONS_REQUEST",
  PUT_ADDONS_SUCCESS: "PUT_ADDONS_SUCCESS",
  PUT_ADDONS_FAILURE: "PUT_ADDONS_FAILURE",

  PUT_ADDONS_WITH_BUNDLED_OPTIONS: "PUT_ADDONS_WITH_BUNDLED_OPTIONS",
  PUT_ADDONS_WITH_BUNDLED_OPTIONS_REQUEST: "PUT_ADDONS_WITH_BUNDLED_OPTIONS_REQUEST",
  PUT_ADDONS_WITH_BUNDLED_OPTIONS_SUCCESS: "PUT_ADDONS_WITH_BUNDLED_OPTIONS_SUCCESS",
  PUT_ADDONS_WITH_BUNDLED_OPTIONS_FAILURE: "PUT_ADDONS_WITH_BUNDLED_OPTIONS_FAILURE",

  // FLYOUTS
  OPEN_ADDONS_FLYOUT: "OPEN_ADDONS_FLYOUT",
  CLOSE_ADDONS_FLYOUT: "CLOSE_ADDONS_FLYOUT",

  OPEN_SERVICES_FLYOUT: "OPEN_SERVICES_FLYOUT",
  CLOSE_SERVICES_FLYOUT: "CLOSE_SERVICES_FLYOUT",

  //Enhanced Services
  LOAD_ENHANCED_SERVICES_SUCCESS: "LOAD_ENHANCED_SERVICES_SUCCESS",
};
