/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
import { eligibilityTypes } from "dux/eligibility/constants/eligibilityConstants";

export const eligibilityPetsByItineraryActionTypes = {
  GET_PETS_ELIGIBILITY_BY_ITINERARY: "eligibilty/GET_PETS_ELIGIBILITY_BY_ITINERARY",
  GET_PETS_ELIGIBILITY_BY_ITINERARY_REQUEST: "eligibilty/GET_PETS_ELIGIBILITY_BY_ITINERARY_REQUEST",
  GET_PETS_ELIGIBILITY_BY_ITINERARY_FAILURE: "eligibilty/GET_PETS_ELIGIBILITY_BY_ITINERARY_FAILURE",
  GET_PETS_ELIGIBILITY_BY_ITINERARY_SUCCESS: "eligibilty/GET_PETS_ELIGIBILITY_BY_ITINERARY_SUCCESS",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getPetsEligibilityByItinerary = ({
  customerKey,
  itineraryId,
  eligibilityType = eligibilityTypes.BOOKING,
}) => ({
  type: eligibilityPetsByItineraryActionTypes.GET_PETS_ELIGIBILITY_BY_ITINERARY,
  customerKey,
  itineraryId,
  eligibilityType,
});

export const getPetsEligibilityByItineraryRequest = () => ({
  type: eligibilityPetsByItineraryActionTypes.GET_PETS_ELIGIBILITY_BY_ITINERARY_REQUEST,
});

export const getPetsEligibilityByItineraryFailure = ({ error }) => ({
  type: eligibilityPetsByItineraryActionTypes.GET_PETS_ELIGIBILITY_BY_ITINERARY_FAILURE,
  error,
});

export const getPetsEligibilityByItinerarySuccess = () => ({
  type: eligibilityPetsByItineraryActionTypes.GET_PETS_ELIGIBILITY_BY_ITINERARY_SUCCESS,
});
