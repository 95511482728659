import isOutsideViewPort from "./isOutsideViewPort";

/**
 * Responsible for takeing a DOM Element and changing its position if the object is out of browsers view port.
 * It will take the Original DOM element, gets it original position, determine if the element is outside of the
 * browser viewport and place it to the top, left or bottom of the original position based on the DOM elements height.
 * and width.
 *
 * @export calculateDomElementNewPosition
 * @param {*} domElement - Dome Element to check if outside browser viewport.
 * @param {number} [offsetX=0] - any offset 0n the X axis
 * @param {number} [offsetY=0] - any offset 0n the Y axis
 * @returns - Object of key value paris for x and Y values
 */
export default function calculateDomElementNewPosition(domElement, offsetX = 0, offsetY = 0) {
  // Get getBoundingClientRect of dom so we know the original coordinates of the element.
  const eleBoundingBox = domElement.getBoundingClientRect();

  // Check if outside screen via isOutsideViewPort function
  const isOutside = isOutsideViewPort(domElement);

  // Set Initial X,Y values
  const positions = {
    x: eleBoundingBox.x,
    y: eleBoundingBox.y
  };

  if (isOutside.bottom && isOutside.right) {
    positions.y = eleBoundingBox.y - (eleBoundingBox.height + offsetY);
    positions.x = eleBoundingBox.x - (eleBoundingBox.width - offsetX);
    return positions;
  }

  if (isOutside.bottom) {
    positions.y = eleBoundingBox.y - (eleBoundingBox.height + offsetY);
    positions.x = eleBoundingBox.x - offsetX;
    return positions;
  }

  if (isOutside.right) {
    positions.y = eleBoundingBox.y + offsetY;
    positions.x = eleBoundingBox.x - (eleBoundingBox.width - offsetX);
    return positions;
  }

  // Not outside the viewport so return original values  with offsets.
  positions.y = eleBoundingBox.y + offsetY;
  positions.x = eleBoundingBox.x - offsetX;
  return positions;
}
