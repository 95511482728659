import { put, call, all, takeEvery, select } from "redux-saga/effects";
import { get } from "lodash/fp";

// Selectors
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getCurrentItinerary } from "@/core/selectors/checkInOutSelector";

// Endpoints
import { fetchHotelServiceCard } from "@/core/services/systemServicesBooking/hotelServiceCardEndPoints";

// Actions
import {
  GET_HOTEL_SERVICE_CARD,
  getHotelServiceCardRequest,
  getHotelServiceCardSuccess,
  getHotelServiceCardFailure,
} from "./hotelPrintServiceCardActions";

function* onGetHotelServiceCard({ petId }) {
  try {
    yield put(getHotelServiceCardRequest());
    const customerKey = yield select(getCurrentCustomerKey);
    const hotelItineraryId = yield select(getCurrentItinerary);

    const response = yield call(fetchHotelServiceCard, { customerKey, hotelItineraryId, petId });

    const url = get(["serviceCardUrl"], response.data.result);
    window.open(url);
    yield put(getHotelServiceCardSuccess());
  } catch (error) {
    yield put(getHotelServiceCardFailure({ error }));
    throw new Error("Hotel Service Card has failed", error);
  }
}
function* watchGetHotelServiceCard() {
  yield takeEvery(GET_HOTEL_SERVICE_CARD, onGetHotelServiceCard);
}

export default function* itinerarySaga() {
  yield all([watchGetHotelServiceCard()]);
}
