import React, { useState } from "react";
import { connect } from "react-redux";
import StyledPopover from "web/common/StyledPopover";
import {
  HotelPriceAdjustmentFlyout,
  SalonPriceAdjustmentFlyout,
} from "web/cart/PriceAdjustmentFlyoutComponent";
import { Button, Text, Layout } from "@prism/psm-ui-components";

import { isFromBooking } from "core/utils/validationUtils/isFromValidation";
import { get } from "lodash/fp";
import { hasManagerPrivileges } from "core/selectors/persistentSelectors";
import getAppointmentMetaDataByPetId from "core/selectors/pet/getAppointmentMetaDataByPetId";
import {
  getBundleOfferTypeByPetServiceId,
  getIsActiveBundleAvailable,
  getIsFreeUnitRedemptionRestricted,
} from "dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import getIsBGMWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getFTCOWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import { BGM, FTCO } from "web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";
import { getAppointmentPriceSalon } from "dux/appointmentPrice/appointmentPriceSelectors";
import { serviceTypes } from "core/constants/serviceTypesConstants";

export const AppointmentPriceComponent = props => {
  const { componentId, isHidden, petId, isStrikethrough, isClickable, price, flyoutType } = props;

  const [showPriceAdjustment, setShowPriceAdjustment] = useState(false);

  const FLYOUT_TYPES = {
    [serviceTypes.SALON]: (
      <SalonPriceAdjustmentFlyout
        petId={petId}
        price={price}
        onClose={() => setShowPriceAdjustment(false)}
      />
    ),
    [serviceTypes.HOTEL]: (
      <HotelPriceAdjustmentFlyout
        petId={petId}
        price={price}
        onClose={() => setShowPriceAdjustment(false)}
      />
    ),
  };

  if (isHidden) {
    return null;
  }

  const packageAppliedStyle = () => {
    if (isStrikethrough) {
      return {
        textDecoration: "line-through",
      };
    }
    return {
      textDecoration: "none",
    };
  };

  const getPriceCTA = () => {
    if (isClickable) {
      return (
        <Button
          variant="link"
          id={`${componentId}__priceButton`}
          style={packageAppliedStyle()}
          onClick={() => setShowPriceAdjustment(true)}
        >
          <Text>${price}</Text>
        </Button>
      );
    }
    return (
      <Text id={`${componentId}__priceText`} style={{ ...packageAppliedStyle(), paddingLeft: 10 }}>
        ${price}
      </Text>
    );
  };

  return (
    <Layout.Box id={componentId} data-testid="appointment-price">
      <StyledPopover
        id={`${componentId}__popover`}
        body={FLYOUT_TYPES[flyoutType]}
        isOpen={showPriceAdjustment}
        preferPlace="above"
        onOuterAction={() => setShowPriceAdjustment(false)}
        tipSize={7}
      >
        <Layout.Box id={`${componentId}__priceCTA`}>{getPriceCTA()}</Layout.Box>
      </StyledPopover>
    </Layout.Box>
  );
};

// SALON CONTAINER ----------------------------------------------------------------------------------------
export const SalonAppointmentPrice = connect((state, ownProps) => {
  const isBookingFlow = isFromBooking(get("history.location.pathname", ownProps));
  const isManager = hasManagerPrivileges(state);
  const { petId } = ownProps;
  const { petServiceId, petServiceItem, isEditable } = getAppointmentMetaDataByPetId(state, {
    petId,
  });

  const isBundleAvailable = getIsActiveBundleAvailable(state, { petId, petServiceId });
  const offerType = getBundleOfferTypeByPetServiceId(state, { petId, petServiceId });
  const isBGMWorkflowFeatureFlagHidden = getIsBGMWorkflowFeatureFlagHidden(state);
  const isFTCOWorkflowFeatureFlagHidden = getFTCOWorkflowFeatureFlagHidden(state);

  const isBGMBundleApplied =
    isBookingFlow && isBundleAvailable && !isBGMWorkflowFeatureFlagHidden && offerType !== FTCO;
  const isFTCOBundleApplied =
    isBookingFlow && isBundleAvailable && !isFTCOWorkflowFeatureFlagHidden && offerType !== BGM;

  const isPriceAdjustable = isManager && petServiceItem;
  const appointmentPrice = getAppointmentPriceSalon(state, { petId });

  const isFreeUnitRedemptionRestricted = getIsFreeUnitRedemptionRestricted(state, {
    petId,
    petServiceId,
  });

  const isStrikethrough =
    (isBGMBundleApplied || isFTCOBundleApplied || ownProps.isPriceChange) &&
    !isFreeUnitRedemptionRestricted;

  return {
    // Only show the applied bundle if we are in the booking flow for SVCSART-428.
    // Once we have bundle history data available, we will show the applied bundle
    // info for the check-in/check-out flow. This will be addressed in a future sprint.
    flyout: SalonPriceAdjustmentFlyout,
    isStrikethrough,
    isClickable: isPriceAdjustable && isEditable,
    price: appointmentPrice,
    flyoutType: serviceTypes.SALON,
    componentId: "SalonAppointmentPrice",
  };
})(AppointmentPriceComponent);
