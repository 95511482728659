import { createSelector } from "reselect";
import { get, getOr } from "lodash/fp";
import { getProps } from "core/selectors/commonSelector";
import { getPet } from "core/selectors/entitiesSelector";
import getUSDateString from "core/utils/dateUtils/usDate";
import isExpired from "core/utils/dateUtils/isExpired";
import { sortByPropertyAsc } from "core/utils/arrayUtils/sortByProperty";
import getAppointmentMetaDataByPetId from "core/selectors/pet/getAppointmentMetaDataByPetId";
import moment from "moment";
import {
  BASE_SERVICES,
  BASE_SERVICES_EXPIRE,
  FREE_SERVICES_EXPIRE,
  TRAINING,
} from "dux/bgm/availableBundlesByPet/availableBundlesConstents";
import {
  formatBundleServiceLabel,
  formatLabel,
} from "dux/_components/bgm/availableBundlesByPet/availableBundlesList/_Utils";
import convertDateToTimestamp from "@/core/utils/dateUtils/dateToTimestamp";
import groupBy from "@/dux/utils/arrayUtils/groupBy";
import { getPackageOfferType } from "../packageSelectors";
import { getIsBundleApplied } from "@/core/selectors/cartSelectors";

export const getAvailableBundlesByPet = createSelector([getPet], getOr([], "bundles"));

export const getActiveAvailableBundlesByPet = createSelector([getAvailableBundlesByPet], bundles =>
  bundles?.filter(({ availableUnits, reservedUnits }) => availableUnits > 0 || reservedUnits > 0),
);

export const getFilteredActiveBundlesByPet = createSelector(
  [getActiveAvailableBundlesByPet, getPackageOfferType],
  (bundles, packageOfferType) =>
    bundles?.filter(bundle => {
      if (!packageOfferType) {
        return true;
      }
      return bundle.offerType === packageOfferType;
    }),
);

/**
 * @export getAvailableBundlesByOfferTypeFactory
 * @param {string} offerType - Offer type to select available bundles for
 * @returns {Function} selector - A new selector that fetches available bundles based on the offer type passed in
 * @example getAvailableBundlesByOfferTypeFactory(TRAINING)
 * */
export const getAvailableBundlesByOfferTypeFactory = offerType =>
  createSelector(
    [getAvailableBundlesByPet],
    bundles => bundles?.filter(bundle => bundle?.offerType === offerType) || [],
  );

/**
 * @export getAvailableTrainingBundles
 * @param {Object} props - Properties to pass to selector
 * @param {Number} props.petId - Pet id to fetch bundles for
 * @returns {Object[]} bundles - An array of available pet service bundles to display
 * @example getAvailableTrainingBundles(state, { petId }))
 * */
export const getAvailableTrainingBundles = getAvailableBundlesByOfferTypeFactory(TRAINING);

/**
 * @export getSortedBundlesByPetServiceIdAndDatePurchasedFactory
 * @param {Function} selector - Selector reference used to fetch the initial bundles to sort
 * @returns {Function} selector - A new selector that sorts pet service bundles provided by initial selector passed in
 * @example getSortedBundlesByPetServiceIdAndDatePurchasedFactory(TRAINING)
 * */
export const getSortedBundlesByPetServiceIdAndDatePurchasedFactory = selector =>
  createSelector([selector], bundles =>
    bundles.sort((a, b) => {
      const previousPetServiceId = a.petServiceId;
      const currentPetServiceId = b.petServiceId;

      if (previousPetServiceId > currentPetServiceId) return -1;
      if (previousPetServiceId < currentPetServiceId) return 1;

      const previousDateTime = convertDateToTimestamp(a.purchaseDatetime);
      const currentDateTime = convertDateToTimestamp(b.purchaseDatetime);

      if (previousDateTime > currentDateTime) return -1;
      if (previousDateTime < currentDateTime) return 1;
    }),
  );

/**
 * @export getSortedTrainingBundlesByPetServiceIdAndDatePurchased
 * @param {Object} props - Properties to pass to selector
 * @param {Number} props.petId - Pet id to fetch bundles for
 * @returns {Object[]} bundles - An array of sorted pet service bundles to display
 * @example getSortedTrainingBundlesbyPetServiceIdAndDatePurchased(state, { petId }))
 * */
export const getSortedTrainingBundlesbyPetServiceIdAndDatePurchased = getSortedBundlesByPetServiceIdAndDatePurchasedFactory(
  getAvailableTrainingBundles,
);

/**
 * Responsible for getting all pet services to display on the schedule training service page.
 *
 * Since we are only displaying one bundle per service on the select service page (either the active bundle
 * or inactive one if no active bundle is available) we create the following data structure. We want to group
 * by pet service item id and by descending date.
  {
  '10000005': [
    {
      purchaseDatetime: '2021-05-25T22:36:04.000Z',
      petServiceId: 10000005,
      availableUnits: 0,
      reservedUnits: 0
    },
    {
      purchaseDatetime: '2021-04-23T22:36:04.000Z',
      petServiceId: 10000005,
      availableUnits: 0,
      reservedUnits: 8
    },
    {
      purchaseDatetime: '2021-03-05T22:36:04.000Z',
      petServiceId: 10000005,
      availableUnits: 0,
      reservedUnits: 0
    }
  ],
}
 * If there's an active bundle, display the first one found for that pet service item. However, if there's
 * no active bundle, then display the latest inactive bundle (there can potentially be many inactive bundles).
 * This will set us up for when we have other pet services down the line (group training, etc.) even though
 * we only have VDT for now. This data structure will then be reduced down to one bundle per pet service item, like so:
[
  {
    purchaseDatetime: '2021-05-25T22:36:04.000Z',
    petServiceId: 10000005,
    availableUnits: 0,
    reservedUnits: 0
  },
  {
    purchaseDatetime: '2021-04-23T22:36:04.000Z',
    petServiceId: 10002954,
    availableUnits: 8,
    reservedUnits: 0
  }
]
 *
 * @export getAvailableTrainingBundlesForServiceSelection
 * @param {Object} props - Properties to pass to selector
 * @param {Number} props.petId - Currently selected pet to fetch bundles for
 * @returns {Object[]} bundles - An array of pet service bundles to display
 * @example getAvailableTrainingBundlesForServiceSelection(state, { petId }))
 */
export const getAvailableTrainingBundlesForServiceSelection = createSelector(
  [getSortedTrainingBundlesbyPetServiceIdAndDatePurchased],
  sortedBundles => {
    const groupedBundles = groupBy(sortedBundles, "petServiceId");
    const bundlesToDisplay = [];

    Object.keys(groupedBundles).forEach(key => {
      const bundleGroup = groupedBundles[key];
      const activeBundle = bundleGroup.find(bundle => bundle.availableUnits > 0);

      if (activeBundle) {
        bundlesToDisplay.push(activeBundle);
      } else {
        const inactiveBundle = bundleGroup.find(Boolean);
        bundlesToDisplay.push(inactiveBundle);
      }
    });

    return bundlesToDisplay;
  },
);

export const getBundleByPetServiceIdSelectorFactory = selector => {
  return createSelector([selector, getProps], (bundles, { petServiceId }) =>
    bundles.find(bundle => bundle.petServiceId === petServiceId),
  );
};

export const getBundleByPetServiceId = getBundleByPetServiceIdSelectorFactory(
  getAvailableBundlesByPet,
);

export const getActiveBundleByPetServiceId = getBundleByPetServiceIdSelectorFactory(
  getActiveAvailableBundlesByPet,
);

/**
 * Selector to get the bundle for a pet based on the redemptionId on the appointment being viewed
 * @memberOf Selectors.Entities
 * @function
 * @name selectPetBundleByCurrentRedemptionId
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Object} - bundle
 * @example selectPetBundleByCurrentRedemptionId(state, { petId })
 */
export const selectPetBundleByCurrentRedemptionId = createSelector(
  [getAvailableBundlesByPet, getIsBundleApplied],
  (bundles, redemptionId) => {
    return bundles?.find(({ redemptions }) =>
      redemptions?.some(redemption => Number(redemption?.name) === Number(redemptionId)),
    );
  },
);

/**
 * Selector to get a bundle for a given pet, tries to find the bundle containing the
 * current redemptionId but will fallback to getting the active bundle for a given petServiceId
 * @memberOf Selectors.Entities
 * @function
 * @name selectPetBundleByCurrentRedemptionId
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.petServiceId
 * @returns {Object} - bundle
 * @example selectPetBundleByCurrentRedemptionId(state, { petId, petServiceId })
 */
export const selectPetBundle = createSelector(
  [selectPetBundleByCurrentRedemptionId, getActiveBundleByPetServiceId],
  (redeemedBundle, activeBundle) => {
    return redeemedBundle ?? activeBundle;
  },
);

export const getBundleOfferTypeByPetServiceId = createSelector(
  [getActiveBundleByPetServiceId],
  bundle => bundle?.offerType,
);

export const getBundleByBundleNumber = createSelector(
  [getAvailableBundlesByPet, getProps],
  (bundles, { bundleNumber }) => {
    return bundles.find(bundle => bundle.bundleNumber === bundleNumber);
  },
);

export const getBundleRedemptions = createSelector(
  [getBundleByBundleNumber],
  getOr([], "redemptions"),
);

export const getBundleRedemptionsByStatus = createSelector(
  [getBundleRedemptions, getProps],
  (redemptions, { status }) => {
    return sortByPropertyAsc(
      redemptions?.filter(appointment => appointment.status === status),
      "redemptionDate",
    );
  },
);

export const getBundleAvailableUnits = createSelector(
  [getBundleByPetServiceId],
  getOr(0, "availableUnits"),
);

export const getActiveBundleAvailableUnits = createSelector(
  [getActiveBundleByPetServiceId],
  getOr(0, "availableUnits"),
);

export const getIsBundleAvailable = createSelector(
  [getBundleAvailableUnits],
  availableUnits => availableUnits > 0,
);

export const getIsActiveBundleAvailable = createSelector(
  [getActiveBundleAvailableUnits],
  availableUnits => availableUnits > 0,
);

export const getBundleName = createSelector([getActiveBundleByPetServiceId], get("name"));

export const getBundleAddOnsByPetServiceId = createSelector(
  [getActiveBundleByPetServiceId],
  get("addOns"),
);

export const getBundleAddOnIdsByPetServiceId = createSelector(
  [getBundleAddOnsByPetServiceId],
  addOns => addOns?.map(({ addOnId }) => Number(addOnId)) || [],
);

export const getBundleAddOnByPetServiceId = createSelector(
  [getBundleAddOnsByPetServiceId],
  addOns => addOns?.find(Boolean),
);

export const getBundleEnhancedServiceByPetServiceId = createSelector(
  [getBundleAddOnsByPetServiceId],
  addOns => addOns?.find(item => item.isEnhancedAddOn),
);

export const getBundleAddOnIdByPetServiceId = createSelector(
  [getBundleAddOnByPetServiceId],
  addOn => Number(addOn?.addOnId),
);

export const getBundleEnhacedServiceIdByPetServiceId = createSelector(
  [getBundleEnhancedServiceByPetServiceId],
  enhancedService => Number(enhancedService?.addOnId),
);

export const getBundleAddOnNameByPetServiceId = createSelector(
  [getBundleAddOnByPetServiceId],
  get("name"),
);

export const getBundleRedeemedUnits = createSelector(
  [getBundleByBundleNumber],
  get("redeemedUnits"),
);

export const getBundleReservedUnits = createSelector(
  [getBundleByBundleNumber],
  get("reservedUnits"),
);

export const getBundlePurchasedUnits = createSelector(
  [getBundleByBundleNumber],
  get("purchasedUnits"),
);

export const getIsBundleFullyRedeemed = createSelector(
  [getBundleRedeemedUnits, getBundlePurchasedUnits],
  (redeemedUnits, purchasedUnits) => redeemedUnits === purchasedUnits,
);

export const getBundlePetServiceDisplayName = createSelector(
  [getBundleByBundleNumber],
  get("petServiceDisplayName"),
);

export const getFormattedBundleTypeWithLabel = createSelector([getBundleByBundleNumber], bundle =>
  formatLabel("Type", formatBundleServiceLabel(bundle)),
);

export const getFormattedBundleOfferTypeWithLabel = createSelector(
  [getBundleByBundleNumber],
  get("offerType"),
);

export const getBaseBundleExpirationDate = createSelector(
  [getBundleByBundleNumber],
  get("baseUnitsExpirationDate"),
);

export const getFreeBundleExpirationDate = createSelector(
  [getBundleByBundleNumber],
  get("freeUnitsExpirationDate"),
);

export const getFormattedFreeBundleExpirationDateWithLabel = createSelector(
  [getFreeBundleExpirationDate, getBundleByBundleNumber],
  (expirationDate, bundle) => {
    // there is a distinction between the bundle offertype being FTCO
    // and the customer being FTCO
    // This will not return the Free Units label to packages that do not offer them
    if (bundle?.offerType != "BGM") {
      return null;
    }

    if (!expirationDate) {
      return formatLabel(FREE_SERVICES_EXPIRE, "-");
    }

    return formatLabel(FREE_SERVICES_EXPIRE, getUSDateString(expirationDate));
  },
);

export const getFormattedBaseBundleExpirationDateWithLabel = createSelector(
  [getBaseBundleExpirationDate],
  expirationDate => formatLabel(BASE_SERVICES_EXPIRE, getUSDateString(expirationDate)),
);

export const getBundlePurchaseDatetime = createSelector(
  [getBundleByBundleNumber],
  get("purchaseDatetime"),
);

export const getFormattedBundlePurchaseDateTimeWithLabel = createSelector(
  [getBundlePurchaseDatetime],
  purchaseDateTime => formatLabel("Purchased", getUSDateString(purchaseDateTime)),
);

export const getBundlePurchasePaymentOrigin = createSelector(
  [getBundleByBundleNumber],
  get("paymentOrigin"),
);

export const getFormattedBundlePaymentOriginWithLabel = createSelector(
  [getBundlePurchasePaymentOrigin],
  paymentOrigin => formatLabel("Paid", paymentOrigin || "-"),
);

export const getBundleSoldBy = createSelector(
  [getBundleByBundleNumber],
  get(["soldBy", "associateName"]),
);

export const getFormattedBundleSoldByWithLabel = createSelector([getBundleSoldBy], soldBy =>
  formatLabel("Sold By", soldBy),
);

export const getBundleAddOns = createSelector([getBundleByBundleNumber], getOr([], "addOns"));

export const getBundleAddOn = createSelector([getBundleAddOns], addOns => {
  return addOns?.find(Boolean);
});

export const getBundleAddOnPrice = createSelector([getBundleAddOn], addOn => {
  return get("originalUnitPrice", addOn);
});

export const getBundleAddOnPriceLabel = createSelector([getBundleAddOn], addOn => {
  return get("isEnhancedAddOn", addOn)
    ? "Enhanced Service Purchased Unit Price"
    : "Add-On Purchased Unit Price";
});

export const getBundleAddOnName = createSelector([getBundleAddOn], addOn => {
  return get("name", addOn);
});

export const getBundleAddOnLabel = createSelector([getBundleAddOn], addOn => {
  return get("isEnhancedAddOn", addOn) ? "Enhanced Service" : "Add-On";
});

export const getFormattedAddOnNameWithLabel = createSelector(
  [getBundleAddOnName, getBundleAddOnLabel],
  (addOnName, addOnLabel) => {
    // Only display add-on if an add-on is available
    if (addOnName) {
      return formatLabel(addOnLabel, addOnName);
    }

    return null;
  },
);

// Remaining Units
export const getBaseRemainingUnits = createSelector([getBundleByBundleNumber], bundle => {
  return get("availableBaseUnits", bundle);
});

export const getFreeRemainingUnits = createSelector([getBundleByBundleNumber], bundle => {
  return get("availableFreeUnits", bundle);
});

export const getFormattedBaseRemainingUnits = createSelector(
  [getBaseRemainingUnits, getBaseBundleExpirationDate],
  (units, expiryDate) => {
    if (isExpired(expiryDate)) {
      return null;
    }
    if (units === 0) {
      return null;
    }
    return `${units} remaining`;
  },
);

export const getFormattedFreeRemainingUnits = createSelector(
  [
    getFreeRemainingUnits,
    getFreeBundleExpirationDate,
    getBaseBundleExpirationDate,
    getBundleByBundleNumber,
  ],
  (units, freeExpiry, baseExpiry, bundle) => {
    // there is a distinction between the bundle offertype being FTCO
    // and the customer being FTCO
    if (bundle?.offerType === "FTCO") {
      return null;
    }

    // no matter what, base expiry determines the visibility here
    if (isExpired(freeExpiry) || isExpired(baseExpiry)) {
      return null;
    }

    if (units === 0) {
      return null;
    }

    return `${units} remaining`;
  },
);

export const getFormattedWarningMessage = createSelector(
  [getFreeBundleExpirationDate, getBaseBundleExpirationDate, getBundleByBundleNumber],
  (freeExpiry, baseExpiry, bundle) => {
    // there is a distinction between the bundle offertype being FTCO
    // and the customer being FTCO
    if (bundle?.offerType === "FTCO") {
      return null;
    }

    if (isExpired(freeExpiry)) {
      const date = getUSDateString(baseExpiry);
      return `Promotional services have expired, ${BASE_SERVICES} are valid until ${date}.`;
    }

    if (!isExpired(baseExpiry)) {
      let date = getUSDateString(freeExpiry);
      if (date === "Invalid date") {
        date = getUSDateString(baseExpiry);
      }
      return `In order to use the full value of their package, the customer must use all services by ${date}.`;
    }

    return null;
  },
);

export const getIsBasePackageExpired = createSelector([getBaseBundleExpirationDate], expiryDate => {
  return isExpired(expiryDate);
});

export const getIsFreePackageExpired = createSelector([getFreeBundleExpirationDate], expiryDate => {
  return isExpired(expiryDate);
});

export const getBundleUnitPrice = createSelector([getBundleByBundleNumber], get("bundleUnitPrice"));

export const formatUnitPrice = (price, message) => {
  if (price) {
    const displayValue = price ? `$${price.toFixed(2)}` : "-";
    return formatLabel(message, displayValue);
  }

  return null;
};

export const getFormattedBundleUnitPrice = createSelector([getBundleUnitPrice], bundleUnitPrice =>
  formatUnitPrice(bundleUnitPrice, "Service Purchased Unit Price"),
);

export const getFormattedBundleAddOnUnitPrice = createSelector(
  [getBundleAddOnPrice, getBundleAddOnPriceLabel],
  (bundleUnitPrice, bundlePriceLabel) => formatUnitPrice(bundleUnitPrice, bundlePriceLabel),
);

export const getBundleOfferType = createSelector([getBundleByBundleNumber], get("offerType"));

export const getFormattedPackageDetails = createSelector(
  [
    getFormattedBundleTypeWithLabel,
    getFormattedAddOnNameWithLabel,
    getFormattedBundlePurchaseDateTimeWithLabel,
    getFormattedBundlePaymentOriginWithLabel,
    getFormattedBundleSoldByWithLabel,
    getFormattedBaseRemainingUnits,
    getFormattedFreeRemainingUnits,
    getFormattedBaseBundleExpirationDateWithLabel,
    getFormattedFreeBundleExpirationDateWithLabel,
    getFormattedWarningMessage,
    getIsBasePackageExpired,
    getIsFreePackageExpired,
    getFormattedBundleUnitPrice,
    getFormattedBundleAddOnUnitPrice,
    getFormattedBundleOfferTypeWithLabel,
  ],
  (
    type,
    addOn,
    purchaseDate,
    paymentOrigin,
    soldBy,
    baseRemainingUnits,
    freeRemainingUnits,
    baseExpirationDate,
    freeExpirationDate,
    warningMessage,
    isBasePackageExpired,
    isFreePackageExpired,
    serviceUnitPrice,
    addOnUnitPrice,
    offerType,
  ) => {
    return {
      type,
      addOn,
      purchaseDate,
      paymentOrigin,
      soldBy,
      baseRemainingUnits,
      freeRemainingUnits,
      baseExpirationDate,
      freeExpirationDate,
      warningMessage,
      isBasePackageExpired,
      isFreePackageExpired,
      serviceUnitPrice,
      addOnUnitPrice,
      offerType,
    };
  },
);

export const getIsFreeUnitRedemptionRestricted = createSelector(
  [getActiveBundleByPetServiceId, getAppointmentMetaDataByPetId],
  (bundle, appointment) => {
    const freeUnitsExpirationDate = bundle?.freeUnitsExpirationDate;
    const redeemedBaseUnits = bundle?.redeemedBaseUnits;
    const quantityPurchased = bundle?.quantityPurchased;
    const { timeSlotStartDateTime } = appointment;
    const baseUnitsRedeemed = redeemedBaseUnits === quantityPurchased;
    const freeUnitsExpired = moment(timeSlotStartDateTime).isAfter(freeUnitsExpirationDate);

    return baseUnitsRedeemed && freeUnitsExpired;
  },
);

/**
 * Selector to get the enhanced service from a given pets bundle
 * Note: if no bundle exists for current redemptionId then falls back to active bundle with petServiceId
 * @memberOf Selectors.Entities
 * @function
 * @name selectBundleEnhancedService
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.petServiceId
 * @returns {Object} - enhanced service addon
 * @example selectBundleEnhancedService(state, { petId, petServiceId })
 */
export const selectBundleEnhancedService = createSelector([selectPetBundle], ({ addOns } = {}) => {
  return addOns?.find(item => item?.isEnhancedAddOn);
});

/**
 * Selector to check if a given enhanced service is included in the pet's bundle
 * Note: if no bundle exists for current redemptionId then falls back to active bundle with petServiceId
 * @memberOf Selectors.Entities
 * @function
 * @name selectIsEnhancedServiceInBundle
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.petServiceId
 * @returns {Object} - enhanced service addon
 * @example selectIsEnhancedServiceInBundle(state, { petId, petServiceId })
 */
export const selectIsEnhancedServiceInBundle = createSelector(
  [selectBundleEnhancedService, (state, props) => props],
  (enhancedService, { addOnId } = {}) => {
    return enhancedService && enhancedService?.addOnId?.toString() === addOnId?.toString();
  },
);
