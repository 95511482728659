import { connect } from "react-redux";
import SubtotalsTable from "./subtotalsTableComponent";
import { getAssociates } from "../../core/selectors/entitiesSelector";
import { getStartWeekDate } from "../../core/selectors/schedulesSelectors";
import { createLoadingSelector } from "../../core/selectors/utils";
import schedulesActionTypes from "../../core/actionTypes/schedulesActionTypes";
import { getSalonHours } from "../../core/selectors/salonHoursSelector";

const mapStateToProps = state => ({
  associates: getAssociates(state),
  startWeekDate: getStartWeekDate(state),
  isScheduleLoading: createLoadingSelector([schedulesActionTypes.LOAD_ASSOCIATES_SCHEDULE])(state),
  salonHours: getSalonHours(state)
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubtotalsTable);
