/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_FREQUENCY_OPTIONS = "GET_FREQUENCY_OPTIONS";
export const GET_FREQUENCY_OPTIONS_REQUEST = "GET_FREQUENCY_OPTIONS_REQUEST";
export const GET_FREQUENCY_OPTIONS_SUCCESS = "GET_FREQUENCY_OPTIONS_SUCCESS";
export const GET_FREQUENCY_OPTIONS_FAILURE = "GET_FREQUENCY_OPTIONS_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getFrequencyOptions = () => ({
  type: GET_FREQUENCY_OPTIONS,
});

export const getFrequencyOptionsRequest = () => ({
  type: GET_FREQUENCY_OPTIONS_REQUEST,
});

export const getFrequencyOptionsSuccess = payload => ({
  type: GET_FREQUENCY_OPTIONS_SUCCESS,
  payload,
});

export const getFrequencyOptionsFailure = error => ({
  type: GET_FREQUENCY_OPTIONS_FAILURE,
  error,
});
