import {
  SET_PREV_BOOKING_TIME_ZONE,
  SET_CACHED_TRAINING_AVAILABILITY_WEEK,
  SET_SELECTED_TIME_SLOT,
  RESET_SELECTED_TIME_SLOT,
} from "@/dux/scheduleTrainingAppointment/scheduleTrainingAppointmentActions";
import { UPDATE_CUSTOMER_TIME_ZONE } from "@/dux/timeZoneCustomer/timeZoneCustomerActions";
import {
  RESET_SCHEDULE_TRAINING_PAGE,
  RESET_SCHEDULE_TRAINING_APPOINTMENT,
} from "../scheduleTrainingPage/scheduleTrainingPageActions";

const initialState = {
  previousBookingTimeZone: null,
  selectedCustomerTimeZone: null,
  cachedTrainingAvailabilityWeeks: [],
  selectedTimeSlot: null,
};

const scheduleTrainingAppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PREV_BOOKING_TIME_ZONE:
      return {
        ...state,
        previousBookingTimeZone: action.previousBookingTimeZone,
      };
    case UPDATE_CUSTOMER_TIME_ZONE:
      return {
        ...state,
        selectedCustomerTimeZone: action.timeZone,
      };
    case SET_CACHED_TRAINING_AVAILABILITY_WEEK:
      return {
        ...state,
        cachedTrainingAvailabilityWeeks:
          state.cachedTrainingAvailabilityWeeks.indexOf(action.week) === -1
            ? [...state.cachedTrainingAvailabilityWeeks, action.week]
            : state.cachedTrainingAvailabilityWeeks,
      };
    case SET_SELECTED_TIME_SLOT:
      return {
        ...state,
        selectedTimeSlot: action.classSessionId,
      };
    case RESET_SELECTED_TIME_SLOT:
      return {
        ...state,
        selectedTimeSlot: initialState.selectedTimeSlot,
      };

    case RESET_SCHEDULE_TRAINING_APPOINTMENT:
    case RESET_SCHEDULE_TRAINING_PAGE:
      return initialState;

    default:
      return state;
  }
};

export default scheduleTrainingAppointmentReducer;
