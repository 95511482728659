import { connect } from "react-redux";
import { medicationTypes } from "web/newMedication/newMedicationConstants";
import Arrow from "../common/Arrow";
import { showHideFoodDetails } from "./actions/foodListActions";
import { getFoodIsShowingDetails, getFoodIsShowingDetailsForType } from "./foodListSelectors";

const mapStateToProps = (state, ownProps) => {
  const { foodId, petId, locationType } = ownProps;
  const type = locationType || medicationTypes.PROFILE;
  const isFoodDetailsShowing = getFoodIsShowingDetailsForType(state, {
    foodId,
    petId,
    locationType: type,
  });
  return {
    isFoodDetailsShowing,
    locationType: type,
    foodId,
    petId,
    direction: isFoodDetailsShowing ? "down" : "right",
    typesShowing: getFoodIsShowingDetails(state, { foodId, petId }),
  };
};

const mapDispatchToProps = dispatch => ({
  showHideDropdown: values =>
    dispatch(
      showHideFoodDetails({
        foodId: values.foodId,
        petId: values.petId,
        locationTypes: values.locationTypes,
      }),
    ),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  clickHandler: () => {
    const { isFoodDetailsShowing, foodId, petId, locationType, typesShowing } = propsFromState;
    let locationTypes;
    if (!isFoodDetailsShowing) {
      locationTypes = [...typesShowing, locationType];
    } else {
      locationTypes = typesShowing.filter(type => type !== locationType);
    }
    propsFromDispatch.showHideDropdown({ isFoodDetailsShowing, foodId, petId, locationTypes });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Arrow);
