import { all, fork } from "redux-saga/effects";
import hotelDashboardSaga from "dux/dashboard/hotel/hotelDashboardSaga";
import hotelOverNightSaga from "dux/hotelOvernight/columnList/hotelOvernightSaga";
import hotelCheckedOutSaga from "dux/hotelCheckedOut/columnList/hotelCheckedOutSaga";
import hotelDeparturesSaga from "dux/hotelDepartures/columnList/hotelDeparturesSaga";
import hotelRoomCapacitySaga from "dux/hotelRoomCapacity/hotelRoomCapacitySaga";
import digitalServiceCardSagas from "@/dux/digitalServiceCard/digitalServiceCardSagas";
import ddcRoomCapacitySaga from "dux/ddcRoomCapacity/ddcRoomCapacitySaga";
import eligibilityRootSaga from "dux/eligibility/sagas/eligibilityRootSaga";
import storeCapabilitiesSaga from "dux/enhancedServicesList/EnhancedServicesListSaga";
import trainingClassSessionsSaga from "@/dux/trainingClassSessions/sagas";
import trainingAssociatesSaga from "dux/trainingAssociates/trainingAssociatesSagas";
import trainingPetServicesSaga from "@/dux/trainingPetServices/trainingPetServicesSagas";
import trainingClassAvailabilitySaga from "@/dux/trainingClassAvailability/trainingClassAvailabilitySagas";
import trainingClassItinerariesSagas from "@/dux/trainingClassItineraries/trainingClassItinerariesSagas";
import hotelRoomTypesSaga from "dux/hotelRoomTypes/hotelRoomTypesSagas";
import hotelPriceAdjustmentSaga from "dux/hotelPriceAdjustmentReasons/hotelPriceAdjustmentSaga";
import doNotBookSagas from "dux/doNotBook/doNotBookSagas";
import cancelHotelPetSaga from "dux/hotelCancelAppt/hotelCancelApptSaga";
import resendMeetingLinkSaga from "@/dux/_components/resendMeetingLink/resendMeetingLinkSagas";
import updateBoardingDetailsHotelSaga from "@/dux/boardingDetails/updateBoardingDetailsHotelSaga";
import hotelBeddingTypesSaga from "dux/hotelBeddingTypes/hotelBeddingTypesSagas";
import hotelRoomAvailabilitySaga from "@/dux/hotelRoomAvailability/hotelRoomAvailabilitySagas";
import associateProfileSaga from "@/dux/associateProfile/associateProfileSaga";
import hotelEligibilitySaga from "dux/hotelEligibility/hotelEligibilitySaga";
import applyManualSpecialsHotelSaga from "dux/applySpecial/applyManaulSpecialsHotelSaga";
import HotelServiceManagementSaga from "dux/serviceManagementPage/serviceManagementPageSaga";
import serviceManagementToggleSaga from "dux/servicesManagementToggle/serviceManagementToggleSaga";
import hotelCustomerNoteSaga from "dux/notes/hotelCustomerNotesSaga";
import hotelScheduleSuspensionSaga from "dux/scheduleSuspensionPage/scheduleSuspensionSaga";
import hotelRoomSelectionSaga from "@/dux/hotelRoomSelection/hotelRoomSelectionSaga";
import hotelRoomManagementDetailsSaga from "@/dux/hotelManageRoomDetails/hotelManageRoomDetailsSagas";
import hotelManageRoomDetailsTableSaga from "@/dux/hotelManageRoomDetailsTable/hotelManageRoomDetailsTableSagas";
import hotelPrintServiceCardSaga from "@/dux/hotelPrintServiceCardButton/hotelPrintServiceCardSaga";
import cancellationReasonsSaga from "dux/cancellationreasons/cancellationReasonsSaga";
import voidTransactionReasonsSaga from "dux/voidTransactionReasons/voidTransactionReasonsSaga";
import itineraryHistoryListSaga from "@/dux/itineraryHistoryList/itineraryHistoryListSagas";
import hotelAddonsLIstSaga from "dux/hotelAddons/HotelAddonsLIstSaga";
import searchAddressByZipSaga from "dux/searchAddressByZip/searchAddressByZipSagas";
import entitiesSaga from "./entitiesSaga";
import enumsSaga from "./enumsSaga";
import ganttSaga from "./ganttSaga";
import salonHoursSaga from "./salonHoursSaga";
import bookingSaga from "./bookingSaga";
import schedulesSaga from "./schedulesSaga";
import cartSaga from "./cartSaga";
import agentsSaga from "./agentsSaga";
import vetsSaga from "./vetsSaga";
import customerEmailSaga from "./customers/customerEmailSaga";
import customerAddressSaga from "./customers/customerAddressSaga";
import customerPhoneSaga from "./customers/customerPhoneSaga";
import customerPreferencesSaga from "./customers/customerPreferencesSaga";
import customerAlertsSaga from "./customers/customerAlertsSaga";
import updateCustomerProfileSaga from "./customers/updateCustomerProfileSaga";
import updateCustomerContactSettingsSaga from "./customers/updateCustomerContactSettingsSaga";
import petParentProfileSaga from "./petParentProfileSaga";
import petHealthConditionsSaga from "./petHealthConditionsSaga";
import petVaccinationsSaga from "./petVaccinationsSaga";
import priceAdjustmentSaga from "./priceAdjustmentSaga";
import notesSaga from "./notesSaga";
import PetFavorabilitySaga from "../../dux/petFavorability/petFavorabilitySaga";
import checkInOutSaga from "./checkInOutSaga";
import authSaga from "./authSaga";
import mockTestRole from "./jobRoles/mockTestRoleSaga";
import resetTestRoles from "./jobRoles/resetTestRoleSaga";
import maxPerBlockOrCheckReductionReasonsSaga from "./maxPerBlockOrCheckReductionReasonsSaga";
import availableBundlesByPet from "../../dux/bgm/availableBundlesByPet/availableBundlesByPetSagas";
import createUpdateDeleteMedicationSaga from "../../web/medication/sagas/createUpdateDeleteMedicationSaga";
import enableDisableWorkflowFeatureFlagSaga from "../../web/enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagSaga";
import enableDisableFeaturesSaga from "../../web/enableDisableFeature/enableDisableFeaturesSaga";
import hotelServicesSaga from "../../web/services/hotelServices/sagas/hotelServicesSaga";
import hotelHoursSaga from "../../web/hotelHours/hotelHoursSaga";
import createUpdateDeleteFoodSaga from "../../web/food/createUpdateDeleteFoodSaga";
import petAlertsSaga from "../../dux/petAlerts/petAlertsSaga";
import customerProfilesAlertsSaga from "../../dux/customerAlerts/customerAlertsSaga";
import bulkPackageOfferingsSaga from "../../dux/bgm/bulkPackageOfferings/bulkPackageOfferingsSagas";
import bulkPackageAddOnsSaga from "../../dux/bgm/bulkPackageOfferings/addOns/bulkPackageAddOnsSagas";
import bulkPackageEnhancedServicesSaga from "../../dux/bgm/bulkPackageOfferings/enhancedServices/bulkPackageEnhancedServiceSagas";
import updatePetFavorabilityHotelSaga from "../../dux/petFavorability/updatePetFavorabilityHotelSaga";
import hotelArivalsSaga from "../../dux/hotelArrivals/columnList/hotelArivalsSaga";
import hotelBookingSaga from "../../dux/bookHotel/hotelBookingSaga";
import hotelItinerarySaga from "../../dux/hotelItinerary/hotelItinerarySaga";
import checkInFoodsSaga from "../../dux/_components/checkInOutFeeding/checkInFoodsSaga";
import checkInMedsSaga from "../../dux/_components/checkInOutMedications/checkInMedsSaga";
import notificationsSaga from "dux/notificationCenter/storeEventNotificationsSaga";
import overBookingNewExceptionDateSaga from "@/dux/overBookingNewExceptionDate/overBookingNewExceptionDateSagas";
import overBookingSetupDataTableSaga from "@/dux/overBookingSetupTable/overBookingSetupTableSagas";
import serviceProductFamiliesSaga from "@/dux/serviceProductFamilies/serviceProductFamiliesSaga";
import storeWideNotesSaga from "dux/storeWideNotes/storeWideNotesSagas";
import deleteHotelAddonSaga from "dux/deleteHotelAddon/deleteHotelAddonSaga";
import overBookingExceptionSuspensionDetailsSaga from "@/dux/overbookingExceptionTableDetails/overBookingExceptionTableDetailsSagas";
import listOfNearByStoresSaga from "dux/listOfNearByStores/listOfNearByStoresSagas";
import frequencyOptionsSaga from "dux/frequencyOptions/frequencyOptionsSaga";
import searchFieldMainSaga from "dux/searchFieldMain/searchFieldMainSagas";
import servicesCartSaga from "@/dux/servicesCart/servicesCartSagas";
import reservationCartDetailsConfirmationSaga from "@/dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationSagas";
import hotelItineraryAddonPatchSaga from "@/dux/hotelItineraryAddonPatch/hotelItineraryAddonPatchSagas";
import hotelItineraryAddonPutSaga from "@/dux/hotelItineraryAddonPut/hotelItineraryAddonPutSagas";
import hotelPrimaryServiceSaga from "@/dux/hotelPrimaryServiceChange/hotelPrimaryServiceSagas";
import medicationsListSaga from "@/dux/medications/medicationsSaga";
import hotelItineraryCheckOutStatusSaga from "@/dux/checkOut/checkOutSaga";
import hotelInvoiceSaga from "@/dux/hotelInvoice/hotelInvoiceSagas";
import hotelItineraryPaymentSaga from "@/dux/hotelItineraryPayment/hotelItineraryPaymentSagas";
import hotelItineraryPetNotesPatchSaga from "@/dux/hotelItineraryPetNotes/hotelItineraryPetNotesSagas";
import overBookingExceptionSaga from "@/dux/overBookingException/overBookingExceptionSaga";
import reportSelectionSaga from "@/dux/reportSelection/reportSelectionSaga";
import reportsSaga from "@/dux/reports/reportsSaga";
import foodOptionsListSaga from "@/dux/foodOptionsList/foodOptionsListSagas";
import storeSettingsSaga from "@/dux/storeSettings/storeSettingsSagas";
import hotelItineraryLongStaySaga from "@/dux/hotelItinerary/hotelItineraryLongStaySagas";

export default function* rootSaga() {
  yield all([
    fork(entitiesSaga),
    fork(enumsSaga),
    fork(salonHoursSaga),
    fork(hotelHoursSaga),
    fork(bookingSaga),
    fork(schedulesSaga),
    fork(cartSaga),
    fork(agentsSaga),
    fork(vetsSaga),
    fork(ganttSaga),
    fork(petParentProfileSaga),
    fork(petHealthConditionsSaga),
    fork(petVaccinationsSaga),
    fork(PetFavorabilitySaga),
    fork(updatePetFavorabilityHotelSaga),
    fork(ganttSaga),
    fork(cancellationReasonsSaga),
    fork(voidTransactionReasonsSaga),
    fork(priceAdjustmentSaga),
    fork(notesSaga),
    fork(customerEmailSaga),
    fork(customerAddressSaga),
    fork(customerPhoneSaga),
    fork(customerPreferencesSaga),
    fork(customerAlertsSaga),
    fork(updateCustomerProfileSaga),
    fork(updateCustomerContactSettingsSaga),
    fork(eligibilityRootSaga),
    fork(checkInOutSaga),
    fork(authSaga),
    fork(mockTestRole),
    fork(resetTestRoles),
    fork(maxPerBlockOrCheckReductionReasonsSaga),
    fork(availableBundlesByPet),
    fork(createUpdateDeleteMedicationSaga),
    fork(enableDisableFeaturesSaga),
    fork(enableDisableWorkflowFeatureFlagSaga),
    fork(hotelServicesSaga),
    fork(createUpdateDeleteFoodSaga),
    fork(petAlertsSaga),
    fork(bulkPackageOfferingsSaga),
    fork(bulkPackageAddOnsSaga),
    fork(hotelDashboardSaga),
    fork(hotelArivalsSaga),
    fork(hotelOverNightSaga),
    fork(hotelDeparturesSaga),
    fork(hotelCheckedOutSaga),
    fork(hotelRoomCapacitySaga),
    fork(hotelRoomTypesSaga),
    fork(hotelPriceAdjustmentSaga),
    fork(hotelInvoiceSaga),
    fork(hotelItineraryPaymentSaga),
    fork(hotelItineraryAddonPatchSaga),
    fork(hotelItineraryAddonPutSaga),
    fork(hotelItineraryPetNotesPatchSaga),
    fork(ddcRoomCapacitySaga),
    fork(hotelBookingSaga),
    fork(hotelItinerarySaga),
    fork(hotelItineraryLongStaySaga),
    fork(hotelPrintServiceCardSaga),
    fork(applyManualSpecialsHotelSaga),
    fork(customerProfilesAlertsSaga),
    fork(digitalServiceCardSagas),
    fork(trainingClassSessionsSaga),
    fork(trainingAssociatesSaga),
    fork(trainingPetServicesSaga),
    fork(trainingClassAvailabilitySaga),
    fork(trainingClassItinerariesSagas),
    fork(doNotBookSagas),
    fork(cancelHotelPetSaga),
    fork(resendMeetingLinkSaga),
    fork(updateBoardingDetailsHotelSaga),
    fork(hotelBeddingTypesSaga),
    fork(associateProfileSaga),
    fork(checkInFoodsSaga),
    fork(checkInMedsSaga),
    fork(hotelRoomAvailabilitySaga),
    fork(hotelEligibilitySaga),
    fork(hotelCustomerNoteSaga),
    fork(HotelServiceManagementSaga),
    fork(serviceManagementToggleSaga),
    fork(hotelScheduleSuspensionSaga),
    fork(hotelRoomSelectionSaga),
    fork(overBookingExceptionSuspensionDetailsSaga),
    fork(storeCapabilitiesSaga),
    fork(storeSettingsSaga),
    fork(hotelRoomManagementDetailsSaga),
    fork(hotelManageRoomDetailsTableSaga),
    fork(itineraryHistoryListSaga),
    fork(hotelAddonsLIstSaga),
    fork(notificationsSaga),
    fork(deleteHotelAddonSaga),
    fork(serviceProductFamiliesSaga),
    fork(storeWideNotesSaga),
    fork(overBookingNewExceptionDateSaga),
    fork(overBookingSetupDataTableSaga),
    fork(overBookingExceptionSaga),
    fork(listOfNearByStoresSaga),
    fork(frequencyOptionsSaga),
    fork(searchFieldMainSaga),
    fork(searchAddressByZipSaga),
    fork(bulkPackageEnhancedServicesSaga),
    fork(servicesCartSaga),
    fork(reservationCartDetailsConfirmationSaga),
    fork(hotelPrimaryServiceSaga),
    fork(medicationsListSaga),
    fork(hotelItineraryCheckOutStatusSaga),
    fork(reportSelectionSaga),
    fork(reportsSaga),
    fork(foodOptionsListSaga),
  ]);
}
