import { convertTimeOfDayObjToArr } from "@/core/utils/checkInOutUtils";
import { NEW_FOOD_ID } from "../newFood/newFoodConstants";

export const isNewFood = foodId => {
  return foodId === NEW_FOOD_ID;
};

export const formatFood = food => {
  if (!food) {
    return {};
  }
  const {
    petFoodId,
    groupingId,
    name,
    amount,
    timeOfDay,
    specialInstructions,
    externalId,
    petProfileFoodId,
  } = food;
  return {
    // In the booking flow petFoodId will be overridden but the original value will be stored in petProfileFoodId
    petFoodId: petProfileFoodId ?? petFoodId,
    groupingId,
    name,
    amount,
    timeOfDay,
    specialInstructions,
    externalId,
  };
};

export const formatFoodFromCheckIn = food => {
  if (!food) return {};

  const { foodName, otherFoodName, foodAmount, foodInstructions, externalId } = food;

  return {
    name: otherFoodName || foodName,
    amount: foodAmount,
    timeOfDay: convertTimeOfDayObjToArr(food),
    specialInstructions: foodInstructions,
    externalId,
  };
};
