import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  GET_SERVICE_MANAGEMENT_TOGGLE_REASONS,
  getServiceManagementToggleReasonsRequest,
  getServiceManagementToggleReasonsSuccess,
  getServiceManagementToggleReasonsFailure,
} from "dux/servicesManagementToggle/servicesManagementToggleActions";
import { getSuspensionReasons } from "core/services/systemServicesBooking/suspensionReasons";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import removeEmpty from "@/core/utils/objectUtils/removeEmpty";

function* onServiceManagementToggleReasons({ suspensionTypes }) {
  try {
    yield put(getServiceManagementToggleReasonsRequest());

    const storeNumber = yield select(getStoreNumber);
    const response = yield call(getSuspensionReasons, { storeNumber, suspensionTypes });

    // response may contain some keys with null values, remove those before saving to state
    const suspensionReasons = yield call(removeEmpty, response?.data?.result);
    yield put(getServiceManagementToggleReasonsSuccess({ suspensionReasons }));
  } catch (error) {
    yield put(getServiceManagementToggleReasonsFailure());
  }
}

function* watchOnServiceManagementToggleReasons() {
  yield takeEvery(GET_SERVICE_MANAGEMENT_TOGGLE_REASONS, onServiceManagementToggleReasons);
}

export default function* serviceManagementToggleSaga() {
  yield all([fork(watchOnServiceManagementToggleReasons)]);
}
