// TODO => Get better validation of US/Canada numbers
export const PHONE_NUMBER_REGEX = /^(\(?\d{3}\)?)\s?(\d{3})-?(\d{4})$/;
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGEX = /^(?!\s*$).+/;
export const STORE_REGEX = /^[0-9]{1,4}/;
export const VALID_PHONE_INPUT_REGEX = /(^ *$|^\([0-9]{3}\) [0-9]{3}-[0-9]{3,5}$|^\d+$)/;
export const NUMBER_REGEX = /^[0-9]/;
export const IS_NOT_NUMBER_REGEX = /\D+/g;
export const ALPHA_NUMERIC_ONLY_REGEX = /[\W_\s]+/g;
export const DECIMAL_REGEX = /\./;
export const WHITESPACE_REGEX = /\s/;
export const NUMBERS_ONLY_REGEX = /^[0-9]*$/;
export const VALID_PRICE_REGEX = /^\d+\.?\d{0,2}?$/;
export const NO_NEW_LINES_REGEX = /\n/;
export const ZERO_TO_HUNDRED_REGEX = /^[0-9][0-9]?$|^100$/;
