import { connect } from "react-redux";
import RuleViolation from "./ruleViolationComponent";
import { getRuleViolations } from "../../../../../core/selectors/ruleViolationsSelectors";
import { clearRuleViolations } from "../../../../../core/actionCreators/ruleViolationsActionCreator";
import { loadPets } from "../../../../../core/actionCreators/petsActionCreators";
import { createLoadingSelector } from "../../../../../core/selectors/utils";
import petsActionTypes from "../../../../../core/actionTypes/petsActionTypes";
import schedulesActionCreators from "../../../../../core/actionCreators/schedulesActionCreators";

const mapStateToProps = state => ({
  ruleViolations: getRuleViolations(state),
  isLoading: createLoadingSelector([petsActionTypes.LOAD_PETS])(state)
});

const mapDispatchToPtops = dispatch => ({
  clearRuleViolations: () => dispatch(clearRuleViolations()),
  loadPets: ({ petIds }) => dispatch(loadPets({ petIds })),
  hideModal: () => dispatch(schedulesActionCreators.hideSchedulesModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToPtops
)(RuleViolation);
