import React from "react";
import styled from "styled-components";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default function DateAndAssociateSelectionWrapper(props) {
  const { isHidden, children } = props;
  if (!isHidden) {
    return <SectionWrapper>{children}</SectionWrapper>;
  }
  return null;
}
