import { connect } from "react-redux";
import { Button } from "@prism/psm-ui-components";
import { color } from "@/web/common/styles/theme";
import { hasManagerPrivileges } from "@/core/selectors/persistentSelectors";
import { isAssociateVirtualTrainer } from "@/core/selectors/entitiesSelector";
import { setToggleRemoveVirtualTrainerModal } from "./associateProfilePageActions";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

export default withRouteProps(
  connect(
    (state, { router }) => {
      const isManager = hasManagerPrivileges(state);
      const associateId = router?.params?.associateId;
      const isVirtualTrainer = isAssociateVirtualTrainer(state, { associateId });

      return {
        variant: "link",
        size: "lg",
        style: { fontFamily: "lato", color: color.errorRed },
        children: "Remove trainer from virtual",
        isHidden: !isManager || !isVirtualTrainer,
      };
    },
    (dispatch) => ({
      onClick: () => {
        dispatch(setToggleRemoveVirtualTrainerModal());
      },
    }),
  )(Button),
);
