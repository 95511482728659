import { put, takeEvery, call, all, select, delay } from "redux-saga/effects";
import moment from "moment";
import { get, set, reduce } from "lodash/fp";

import { getStoreNumber } from "../selectors/persistentSelectors";
import itinerariesActionTypes from "../actionTypes/itinerariesActionTypes";
import itinerariesActionCreators, {
  updateItineraryStatusFailure,
  loadServiceCardRequest,
  loadServiceCardSuccess,
  loadServiceCardFailure,
  loadServiceCardsRequest,
  loadServiceCardsSuccess,
  loadServiceCardsFailure,
  updateItineraryStatusRequest,
  updateItineraryStatusSuccess,
  updateItineraryPaymentStatusSuccess,
  updateItineraryPaymentStatusFailure,
  undoLastItineraryStatusUpdateRequest,
  undoLastItineraryStatusUpdateSuccess,
  undoLastItineraryStatusUpdateFailure,
} from "../actionCreators/itinerariesActionCreators";
import {
  getItinerary,
  getCustomers,
  getPets,
  getPetIdByEngagementId,
} from "../selectors/entitiesSelector";
import petServiceItemsActionCreators from "../actionCreators/petServiceItemsActionCreators";
import engagementsActionCreators from "../actionCreators/engagementsActionCreators";
import associatesActionCreators from "../actionCreators/associateActionCreator";
import {
  fetchItinerary,
  postItinerary,
  finalizeItinerary,
  deleteItinerary,
  putItineraryStatus,
  deleteLastItineraryStatusUpdate,
} from "../services/systemServicesBooking/itineraryEndPoints";

import {
  fetchItinerariesByCustomer,
  fetchItineraries,
} from "../services/systemServicesBooking/itinerariesEndPoints";
import { updatePaymentStatus } from "../services/systemServicesBooking/paymentEndPoints";
import { triggerSessionCacheRefresh } from "../services/systemServicesBooking/sessionCacheEndPoints";
import { initializeCartByItinerary } from "../actionCreators/ui/web/cartActionCreators";
import bookingActionCreators, { setItineraryId } from "../actionCreators/bookingActionCreators";
import normalizeItineraries from "../utils/normalizeItineraries";
import { loadSpecialsSuccess } from "../actionCreators/specialsActionCreator";
import { setDynamicPrices } from "../actionCreators/dynamicPricesActionCreator";
import { fetchInvoice } from "../services/systemServicesBooking/invoiceEndPoints";
import { loadSalonHoursSuccess } from "../actionCreators/salonHoursActionCreators";
import { showCheckInOutModal } from "../actionCreators/checkInOutActionCreator";
import {
  fetchServiceCard,
  fetchServiceCards,
} from "../services/systemServicesBooking/serviceCardEndPoints";
import { loadPets } from "../actionCreators/petsActionCreators";
import { onLoadCustomer } from "./customersSaga";

import { onUpdateEngagementStatus } from "./engagementsSaga";
import { onGetEngagementServiceDetails } from "@/dux/digitalServiceCard/digitalServiceCardSagas";
import { setCustomerFTCOEligible } from "@/dux/bgm/ftco/ftcoActions";
import { waitForRequests } from "./utilsSaga";
import engagementActionTypes from "../actionTypes/engagementsActionTypes";
import { setEligiblePromotions } from "dux/eligiblePromotions/eligiblePromotionsActions";
import { ITINERARIES_BY_STORE_STATUSES } from "dux/_constants";
import createCommaDelimitedList from "core/utils/stringUtils/createCommaDelimitedList";
import getItineraryByID from "@/core/selectors/entities/Itineraries/getItineraryByIDSelector";
import { FETCH_PDF_DELAY } from "@/core/constants/itinerariesConstants";

const getSalonHoursWithPetServiceItems = petServiceItems =>
  // cc: loadItinerariesFromDB#11;get Salon Hours With Pet ServiceItems .
  reduce(
    (result, petServiceItem) => {
      const petServiceItemDate = moment(petServiceItem.startDateTime).format("YYYY-MM-DD");

      return get([petServiceItemDate, "petServiceItems"], result)
        ? set(
            [petServiceItemDate, "petServiceItems"],
            [...result[petServiceItemDate].petServiceItems, petServiceItem.petServiceItemId],
            result,
          )
        : set([petServiceItemDate, "petServiceItems"], [petServiceItem.petServiceItemId], result);
    },
    {},
    petServiceItems,
  );

export function* dispatchItinerariesDataAndReturnItineraries({
  response,
  shouldLoadPets = false,
  shouldLoadCustomers = false,
}) {
  // cc: loadItinerariesFromDB#5;Handle Itineraries Data;petServiceItems, engagements, customers etc.

  const {
    petServiceItems,
    engagements,
    associates,
    itineraries,
    pets,
    customers,
    dynamicPrices,
    specials,
  } = normalizeItineraries(response.data);
  const storedCustomers = yield select(getCustomers);

  // cc: loadItinerariesFromDB#6;loadPets;Load pets data for dashboard
  if (shouldLoadPets) {
    const petsFromStore = yield select(getPets);
    const filteredPetIds = pets.filter(petId => !petsFromStore[petId]);
    if (filteredPetIds.length > 0) {
      yield put(loadPets({ petIds: pets }));
    }
  }

  if (shouldLoadCustomers) {
    yield* customers.map(customerKey => {
      if (!storedCustomers[customerKey]) {
        return call(onLoadCustomer, { customerKey });
      }
    });
  }
  yield put(petServiceItemsActionCreators.loadPetServiceItems({ petServiceItems }));
  yield put(associatesActionCreators.loadAssociatesSuccess({ associates }));
  yield put(engagementsActionCreators.loadEngagements({ engagements }));
  yield put(setDynamicPrices({ dynamicPrices }));
  yield put(loadSpecialsSuccess({ specials }));
  yield put(
    loadSalonHoursSuccess({ salonHours: getSalonHoursWithPetServiceItems(petServiceItems) }),
  );

  return itineraries;
}

function* onloadItinerary({ customerKey, itineraryId, shouldLoadPets, shouldLoadCustomers }) {
  try {
    yield put(itinerariesActionCreators.loadItineraryRequest());
    const response = yield call(fetchItinerary, { customerKey, itineraryId });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
      shouldLoadPets,
      shouldLoadCustomers,
    });
    const itinerary = normalizedItineraries[itineraryId];

    yield put(
      itinerariesActionCreators.loadItinerarySuccess({
        itinerary: normalizedItineraries[itineraryId],
      }),
    );

    // Need to get engagement service details for all appointments after the itinerary
    // is loaded in order to display pre checked-in health questions.
    const engagementServiceDetailsAPICalls = itinerary.engagements.map(engagementId =>
      call(onGetEngagementServiceDetails, { customerKey, itineraryId, engagementId }),
    );

    yield all(engagementServiceDetailsAPICalls);

    yield put(setCustomerFTCOEligible(itinerary.isFTCO));
  } catch (error) {
    yield put(itinerariesActionCreators.loadItineraryFailure({ error }));
  }
}

export function* onFinalizeItinerary({ customerKey, itineraryId, shouldCheckIn }) {
  try {
    yield put(itinerariesActionCreators.finalizeItineraryRequest());
    const response = yield call(finalizeItinerary, { customerKey, itineraryId });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });
    const itinerary = normalizedItineraries[itineraryId];

    if (shouldCheckIn && itinerary) {
      const { customerId, itineraryId, engagements } = itinerary;
      const pageName = "check-in";
      const status = "Checked-In";

      if (engagements.length > 1) {
        yield call(onUpdateItineraryStatus, {
          status,
          customerId,
          itineraryId,
        });
        yield call(onLoadServiceCards, {
          customerKey: customerId,
          itineraryId,
          engagementId: engagements[0],
        });
      } else {
        yield call(onUpdateEngagementStatus, {
          customerId,
          itineraryId,
          engagementId: engagements[0],
          status,
        });
        yield call(onLoadServiceCard, {
          customerKey: customerId,
          itineraryId,
          engagementId: engagements[0],
        });
      }
    }

    yield put(itinerariesActionCreators.finalizeItinerarySuccess({ itinerary }));
  } catch (error) {
    yield put(itinerariesActionCreators.finalizeItineraryFailure({ error }));
  }
}

function* onCreateItinerary({ customerKey, data, dispatchToCart }) {
  try {
    yield put(itinerariesActionCreators.createItineraryRequest());
    const response = yield call(postItinerary, { customerKey, data });

    const { itineraryId } = response.data;
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });
    const itinerary = normalizedItineraries[itineraryId];

    yield put(itinerariesActionCreators.createItinerarySuccess({ itinerary }));
    if (dispatchToCart) {
      yield put(initializeCartByItinerary({ itineraryId }));
      yield put(setItineraryId(itineraryId));
    }
  } catch (error) {
    yield put(itinerariesActionCreators.createItineraryFailure({ error }));
  }
}

export function* onDeleteItinerary({ customerKey, itineraryId }) {
  try {
    yield put(itinerariesActionCreators.deleteItineraryRequest());
    const itinerary = yield select(getItinerary, { itineraryId });
    if (itinerary) {
      const { engagements, petServiceItems } = itinerary;
      yield call(deleteItinerary, { customerKey, itineraryId });
      yield put(engagementsActionCreators.deleteEngagements({ engagements }));
      yield put(petServiceItemsActionCreators.deletePetServiceItems({ petServiceItems }));
      yield put(itinerariesActionCreators.deleteItinerarySuccess({ itineraryId }));
    }
  } catch (error) {
    yield put(itinerariesActionCreators.deleteItineraryFailure({ error }));
  }
}

function* onloadItineraries({ fromDate, toDate, shouldLoadPets, shouldLoadCustomers, serviceTypes, type }) {
  // cc: loadItinerariesFromDB#2;Handle Actions;Begin loading Itineraries

  try {
    const storeNumber = yield select(getStoreNumber);
    if (type === itinerariesActionTypes.LOAD_ITINERARIES) {
      yield put(itinerariesActionCreators.loadItinerariesRequest());
    }
    const response = yield call(fetchItineraries, {
      storeNumber,
      fromDate,
      toDate,
      status: createCommaDelimitedList(ITINERARIES_BY_STORE_STATUSES),
      ...serviceTypes && { serviceTypes: serviceTypes },
    });
    const itineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
      shouldLoadPets,
      shouldLoadCustomers,
    });
    yield put(itinerariesActionCreators.loadItinerariesSuccess({ itineraries }));
  } catch (error) {
    yield put(itinerariesActionCreators.loadItinerariesFailure({ error }));
  }
}

function* onLoadItinerariesByCustomer({ customerKey, fromDate, toDate, serviceTypes }) {
  try {
    yield put(itinerariesActionCreators.loadItinerariesByCustomerRequest());
    const response = yield call(fetchItinerariesByCustomer, {
      customerKey,
      fromDate,
      toDate,
      serviceTypes,
    });
    // const itinerary = tempHereNowItineraries; //response.data;

    const itineraries = yield call(dispatchItinerariesDataAndReturnItineraries, { response });
    yield put(itinerariesActionCreators.loadItinerariesByCustomerSuccess({ itineraries }));
  } catch (error) {
    yield put(itinerariesActionCreators.loadItinerariesByCustomerFailure({ error }));
  }
}

function* onLoadServiceCard({ customerKey, itineraryId, engagementId }) {
  try {
    // Trigger a session cache refresh before fetching the service card
    yield onRefreshSessionCache({ customerId: customerKey, engagementId });

    yield put(loadServiceCardRequest());
    const response = yield call(fetchServiceCard, { customerKey, itineraryId, engagementId });

    const url = get(["serviceCardUrl"], response.data);

    const openWindow = window.open(url);
    openWindow.onload = () => openWindow.print();

    /** ----------------------------------------------------------------------- **\
            IF PDF returned as a Base64
        \** ----------------------------------------------------------------------- * */
    // The web service is returning a PDF in a base64 string, we are decoding that data.
    // const byteCharacters = window.atob(serviceCard.data);
    // const byteNumbers = new Array(byteCharacters.length);

    // for (var i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }

    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], {type: 'application/pdf'});
    // const blobUrl = URL.createObjectURL(blob);
    // const openWindow = window.open(blobUrl);
    // openWindow.onload = () => openWindow.print();

    /** ----------------------------------------------------------------------- **\
            IF PDF returned as a blob
        \** ----------------------------------------------------------------------- * */
    // const blobUrl = URL.createObjectURL(serviceCard);
    // const openWindow = window.open(blobUrl);
    // openWindow.onload = () => openWindow.print();

    yield put(loadServiceCardSuccess());
  } catch (error) {
    yield put(loadServiceCardFailure({ error }));
  }
}

function* onLoadServiceCards({ customerKey, itineraryId, engagementId }) {
  try {
    // Trigger a session cache refresh before fetching the service card
    yield onRefreshSessionCache({ customerId: customerKey, engagementId });

    yield put(loadServiceCardsRequest());
    const response = yield call(fetchServiceCards, { customerKey, itineraryId });

    const url = get(["serviceCardUrl"], response.data);

    const openWindow = window.open(url);
    openWindow.onload = () => openWindow.print();

    yield put(loadServiceCardsSuccess());
  } catch (error) {
    yield put(loadServiceCardsFailure({ error }));
  }
}

export function* onRefreshSessionCache({ customerId, engagementId }) {
  try {
    yield put(bookingActionCreators.refreshSessionCache());
    const petId = yield select(getPetIdByEngagementId, { engagementId });

    yield put(bookingActionCreators.refreshSessionCacheRequest());
    yield call(triggerSessionCacheRefresh, { customerId, petId });
    yield put(bookingActionCreators.refreshSessionCacheSuccess());
  } catch (error) {
    yield bookingActionCreators.refreshSessionCacheFailure(error);
  }
}

/** ----------------------------------------------------------------------- **\
    Handles calling the invoice web service and displaying new tabs for
    each invoice.
\** ----------------------------------------------------------------------- * */
function* onLoadInvoice({ customerKey, itineraryId, petId }) {
  try {
    /** ----------------------------------------------------------------------- **\
            Send out the action to show (in Console or Redux Extension) we are making
            a request
        \** ----------------------------------------------------------------------- * */
    yield put(itinerariesActionCreators.loadInvoiceRequest());

    const { invoiceId } = yield select(getItineraryByID, itineraryId);

    // SVCSART-9781 We want to wait 5 seconds if there's no invoiceID provided since
    // the fetchInvoice API call attempts to create an invoice if one isn't generated
    // already. There's currently a race condition that occurs if an appointment is checked
    // out immediately following service complete. There are cases where there's no invoice
    // id even though one is currently being generated by SF, which is leading to record
    // locking since the call to fetch invoice below tries to create another one at the same time.

    if (!invoiceId) {
      yield delay(FETCH_PDF_DELAY);
    }

    /** ----------------------------------------------------------------------- **\
            Make a call to the get invoices for itinerary Endpoint
        \** ----------------------------------------------------------------------- * */
    const response = yield call(fetchInvoice, { customerKey, itineraryId /* , petId */ });
    const { itineraryInvoiceUrl } = response.data;

    /** ----------------------------------------------------------------------- **\
            Currently pulling a single invoice for entire Itinerary
        \** ----------------------------------------------------------------------- * */

    /** ----------------------------------------------------------------------- **\
            Will Eventually have a multi-pet situation
        \** ----------------------------------------------------------------------- * */
    // if(invoiceURL.petInvoices) {
    //     invoiceURL.petInvoices.forEach(invoice => {
    //         const openWindow =  window.open(invoice.invoiceUrl);
    //     });
    // }else{
    //     window.open(invoiceURL.itineraryInvoiceUrl);
    // }

    yield waitForRequests([engagementActionTypes.UPDATE_ENGAGEMENT_STATUS]);

    yield call(onloadItinerary, { customerKey, itineraryId });

    // SVCSART-5899: We only want to print an invoice if there is an invoice URL provided by the
    // fetch invoice endpoint. If we've booked an appointment with a BGM/FTCO package applied (with no add-ons),
    // then the invoice URL will return as null and not be printed. This is because we've already pre-paid
    // for the service and will not have a corresponding invoice.
    if (itineraryInvoiceUrl) {
      const openWindow = window.open(itineraryInvoiceUrl, "_blank");
      openWindow.onload = () => openWindow.print();
    }

    // Send out the action to show (in Console or Redux Extension) that the invoices were returned successful.
    yield put(itinerariesActionCreators.loadInvoiceSuccess());
  } catch (error) {
    yield put(itinerariesActionCreators.loadInvoiceFailure({ error }));
  }
}

export function* onUpdateItineraryStatus({ customerId, itineraryId, reason, status, pageName }) {
  try {
    yield put(updateItineraryStatusRequest());
    // Set data for request body payload
    const data = {
      status,
      reason,
    };

    const response = yield call(putItineraryStatus, { customerId, itineraryId, data });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });
    const itinerary = normalizedItineraries[itineraryId];

    yield put(updateItineraryStatusSuccess({ itinerary }));
    yield put(setCustomerFTCOEligible(itinerary.isFTCO));
    yield put(setEligiblePromotions(itinerary.eligiblePromotions));

    // dispatch verification modal
    const modalType = pageName.toUpperCase();
    yield put(showCheckInOutModal({ modalType }));
  } catch (error) {
    yield put(updateItineraryStatusFailure(error));
  }
}

export function* onUpdateItineraryPaymentStatus({ customerId, itineraryId, data }) {
  try {
    yield put(itinerariesActionCreators.updateItineraryPaymentStatusRequest());
    const response = yield call(updatePaymentStatus, { customerId, itineraryId, data });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });
    const itinerary = normalizedItineraries[itineraryId];
    yield put(updateItineraryPaymentStatusSuccess({ itinerary }));
  } catch (error) {
    yield put(updateItineraryPaymentStatusFailure(error));
  }
}

export function* onUndoLastItineraryStatusUpdate({ customerId, itineraryId }) {
  try {
    yield put(undoLastItineraryStatusUpdateRequest());
    const response = yield call(deleteLastItineraryStatusUpdate, { customerId, itineraryId });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });
    const itinerary = normalizedItineraries[itineraryId];

    yield put(undoLastItineraryStatusUpdateSuccess({ itinerary }));
  } catch (error) {
    yield put(undoLastItineraryStatusUpdateFailure(error));
  }
}

function* watchloadItineraries() {
  yield takeEvery(
    [itinerariesActionTypes.LOAD_ITINERARIES, itinerariesActionTypes.LOAD_ITINERARIES_LIVE_UPDATES],
    onloadItineraries,
  );
}

function* watchUndoLastItineraryStatusUpdate() {
  yield takeEvery(
    itinerariesActionTypes.UNDO_LAST_ITINERARY_STATUS_UPDATE,
    onUndoLastItineraryStatusUpdate,
  );
}

function* watchCreateItinerary() {
  yield takeEvery(itinerariesActionTypes.CREATE_ITINERARY, onCreateItinerary);
}

function* watchDeleteItinerary() {
  yield takeEvery(itinerariesActionTypes.DELETE_ITINERARY, onDeleteItinerary);
}

function* watchLoadItinerariesByCustomer() {
  yield takeEvery(itinerariesActionTypes.LOAD_ITINERARIES_BY_CUSTOMER, onLoadItinerariesByCustomer);
}

function* watchloadItinerary() {
  yield takeEvery(itinerariesActionTypes.LOAD_ITINERARY, onloadItinerary);
}

function* watchloadServiceCard() {
  yield takeEvery(itinerariesActionTypes.LOAD_SERVICE_CARD, onLoadServiceCard);
}

function* watchloadServiceCards() {
  yield takeEvery(itinerariesActionTypes.LOAD_SERVICE_CARDS, onLoadServiceCards);
}

function* watchloadInvoice() {
  yield takeEvery(itinerariesActionTypes.LOAD_INVOICE, onLoadInvoice);
}

function* watchFinalizeItinerary() {
  yield takeEvery(itinerariesActionTypes.FINALIZE_ITINERARY, onFinalizeItinerary);
}

function* watchUpdateItineraryStatus() {
  yield takeEvery(itinerariesActionTypes.UPDATE_ITINERARY_STATUS, onUpdateItineraryStatus);
}

function* watchUpdatePaymentItineraryStatus() {
  yield takeEvery(
    itinerariesActionTypes.UPDATE_ITINERARY_PAYMENT_STATUS,
    onUpdateItineraryPaymentStatus,
  );
}

export default function* itinerarySaga() {
  yield all([
    watchloadItineraries(),
    watchloadItinerary(),
    watchLoadItinerariesByCustomer(),
    watchloadServiceCard(),
    watchloadServiceCards(),
    watchloadInvoice(),
    watchCreateItinerary(),
    watchDeleteItinerary(),
    watchFinalizeItinerary(),
    watchUpdateItineraryStatus(),
    watchUpdatePaymentItineraryStatus(),
    watchUndoLastItineraryStatusUpdate(),
  ]);
}
