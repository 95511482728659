import React from "react";
import styled from "styled-components";
import { AFontRegular } from "../../../../../../common/styles/abstractStyledComponents";
import PetVetsForm from "../../../../petVets/petVetsForm/PetVetsFormContainer";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  min-height: 300px;
  padding: 2em 2em 0 2em;
`;

const Header = AFontRegular.extend`
  font-weight: bold;
  margin: 0 auto 0.5em;
`;

export default () => (
  <Content>
    <Header>Create new vet profile</Header>
    <PetVetsForm />
  </Content>
);
