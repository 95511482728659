import { connect } from "react-redux";

// Components
import SmallPillButton from "@/dux/_components/buttons/SmallPillButton";

// Selectors
import { getHotelDeparturesFilterBy } from "./hotelDeparturesPetServiceSelector";

// Actions
import {
  filterByAllDepartures,
  filterByAttentionNeededDepartures,
} from "./hotelDeparturesFilterButtonActions";

/**
 * Redux Connect function for the Hotel Attention Needed filter pill
 * @summary located on /dashboard
 * @memberOf Views.Dashboard
 * @function
 * @name HotelDeparturesAttentionNeededFilterBtn
 * @returns {JSX.Element|null}
 * @example <HotelDeparturesAttentionNeededFilterBtn />
 */
export default connect(
  state => {
    const filterBy = getHotelDeparturesFilterBy(state);

    return {
      isHidden: false,
      componentID: "HotelDeparturesAttentionNeededFilterBtn",
      label: "Attention Needed",
      selected: filterBy.attentionNeeded,
      disabled: false,
      filterBtnStatus: filterBy.attentionNeeded,
      isAllOthersSelected:
        filterBy.dayCamp && filterBy.dayCare && filterBy.overnight && filterBy.unassignedPet,
    };
  },
  dispatch => ({
    handleFilterByAttentionNeeded: ({ filterBtnStatus, isAllOthersSelected }) => {
      if (filterBtnStatus) {
        // toggle to off
        dispatch(filterByAttentionNeededDepartures({ filterBtnStatus: !filterBtnStatus }));

        // set all button to false;
        dispatch(filterByAllDepartures({ filterBtnStatus: false }));
      } else {
        // toggle to on
        dispatch(filterByAttentionNeededDepartures({ filterBtnStatus: !filterBtnStatus }));

        if (isAllOthersSelected) {
          // set all button to true;
          dispatch(filterByAllDepartures({ filterBtnStatus: true }));
        }
      }
    },
  }),
  (propsFromState, { handleFilterByAttentionNeeded }, ownProps) => ({
    ...propsFromState,
    ...ownProps,

    handleClick: () =>
      handleFilterByAttentionNeeded({
        filterBtnStatus: propsFromState.filterBtnStatus,
        isAllOthersSelected: propsFromState.isAllOthersSelected,
      }),
  }),
)(SmallPillButton);
