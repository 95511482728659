/*  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  sections are currently set to commented out as of this time training does not need to display
  Pet alerts.
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

import { connect } from "react-redux";
// import { getPetParentAlertsFormData } from "../../../../core/selectors/entitiesSelector";
import AlertsFormComponent from "../../alerts/AlertsFormComponent";
// import customersActionCreators from "../../../../core/actionCreators/customersActionCreators";
// import { getSystemBookingFlow } from "../../../setSystemType/selectors/setSystemTypeSelectors";
// import { systemName } from "../../../setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = (state, ownProps) => ({
  title: "Pet Parent Alerts",
  componentID: "alertForm-TrainingCustomerAlerts",
  isHidden: true, // currently set to false as of this time training does not need to display Pet alerts.
  // alertsFormData: getPetParentAlertsFormData(state, ownProps),
});

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   onSubmit: values => {
//     dispatch(
//       customersActionCreators.updateCustomerAlerts({
//         customerKey: ownProps.customerKey,
//         data: {
//           bookingAlert: (values.isBookingAlert && values.bookingAlertReason) || "",
//           checkInAlert: (values.isCheckInAlert && values.checkInAlertReason) || "",
//           checkOutAlert: (values.isCheckOutAlert && values.checkOutAlertReason) || "",
//         },
//       }),
//     );
//   },
// });

export default connect(mapStateToProps)(AlertsFormComponent);
