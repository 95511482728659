import { associateWebAPI } from "./_axiosConfigAssociateWeb";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Deletes a customer's existing account from the Web Associate API - Delete email to customer's account end point
 * @param {*} { customerKey, emailAddressId, sourceId, data }
 * @returns
 */
export function deleteEmail({ customerKey, emailAddressId, sourceId, data }) {
  return associateWebAPI.delete(
    `${endpointVersionString()}/customers/${customerKey}/emails/${emailAddressId}?sourceId=${sourceId}`
  );
}
