import { getPetEngagementsFromItinerary } from "../hotelEngagementUtils/hotelEngagementUtils";

export const checkFoodIsActive = foodExternalData => food =>
  foodExternalData?.[food?.foodId]?.IsActive !== false;

export const getAllPetFeedings = (petId, itinerary) => {
  const engagements = getPetEngagementsFromItinerary(petId, itinerary);
  return engagements?.reduce(
    (arr, { metadata: { feedings = [] } } = {}) => [
      ...arr,
      ...feedings?.filter(({ isDeleted }) => !isDeleted),
    ],
    [],
  );
};

export const checkAllPetsHaveAnActiveFood = ({ itinerary, petIds, foodExternalData }) => {
  const itineraryPets = itinerary?.pets?.map(({ petKey }) => petKey) ?? [];
  const petsToCheck = petIds?.length ? petIds : itineraryPets;

  return petsToCheck?.every(petId => {
    const petFeedings = getAllPetFeedings(petId, itinerary);
    return petFeedings?.some(checkFoodIsActive(foodExternalData));
  }, {});
};

export const checkNoPetsHaveDiscontinuedFoods = ({ itinerary, petIds, foodExternalData }) => {
  const itineraryPets = itinerary?.pets?.map(({ petKey }) => petKey) ?? [];
  const petsToCheck = petIds?.length ? petIds : itineraryPets;

  return petsToCheck?.every(petId => {
    const petFeedings = getAllPetFeedings(petId, itinerary);
    return !petFeedings?.some(food => !checkFoodIsActive(foodExternalData)(food));
  }, {});
};
