import { connect } from "react-redux";

import { columnHeaders } from "../../../../core/constants/dashboardContants";
import ListFilterButtons from "../../../_components/buttons/ListFilterButtons";
import {
  HotelAllFilterBtnCompNames,
  HotelAttentionNeededFilterBtnCompNames,
  HotelDayCampFilterBtnCompNames,
  HotelDayCareFilterBtnCompNames,
  HotelOverNightFilterBtnCompNames,
  HotelUnassignedPetFilterBtnCompNames,
} from "../../../_constants/dynamicComponentNames";

const mapStateToProps = () => ({
  isHidden: false,
  componentID: "ListFilterButtons__HotelDepartures",
  compName: columnHeaders.DEPARTURES,
  filters: [
    HotelOverNightFilterBtnCompNames,
    HotelDayCampFilterBtnCompNames,
    HotelDayCareFilterBtnCompNames,
    HotelAllFilterBtnCompNames,
    HotelAttentionNeededFilterBtnCompNames,
    HotelUnassignedPetFilterBtnCompNames,
  ],
});

export default connect(mapStateToProps)(ListFilterButtons);
