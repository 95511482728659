/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_TRAINING_PET_SERVICES = "GET_TRAINING_PET_SERVICES";
export const GET_TRAINING_PET_SERVICES_REQUEST = "GET_TRAINING_PET_SERVICES_REQUEST";
export const GET_TRAINING_PET_SERVICES_SUCCESS = "GET_TRAINING_PET_SERVICES_SUCCESS";
export const GET_TRAINING_PET_SERVICES_FAILURE = "GET_TRAINING_PET_SERVICES_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getTrainingPetServices = ({ storeNumber }) => ({
  type: GET_TRAINING_PET_SERVICES,
  storeNumber,
});

export const getTrainingPetServicesRequest = () => ({
  type: GET_TRAINING_PET_SERVICES_REQUEST,
});

export const getTrainingPetServicesSuccess = petServices => ({
  type: GET_TRAINING_PET_SERVICES_SUCCESS,
  petServices,
});

export const getTrainingPetServicesFailure = payload => ({
  type: GET_TRAINING_PET_SERVICES_FAILURE,
  payload,
});
