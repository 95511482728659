// @ts-check

export const SET_PET_NEEDS_REVIEW = "SET_PET_NEEDS_REVIEW";

/**
 *
 * @param {Bool} needsReview
 * @returns
 */
export const setPetNeedsReview = ({ petId, needsReview }) => ({
  type: SET_PET_NEEDS_REVIEW,
  petId,
  needsReview,
});
