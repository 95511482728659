import React from "react";
import styled from "styled-components";
import { List } from "react-virtualized";
import moment from "moment";
import { find } from "lodash/fp";
import { isWorkOrRelocationActivityType } from "core/utils/schedulesUtils";
import Select from "../../../common/Select";
import { dayTypes } from "../../../../core/constants/associateSchedulingConstants";
import { dayActivityTypes, petSupportedTypes } from "../../../../core/constants/schedulesConstants";

const DayTypeWrapper = styled.div``;

const StyledDayTypeSelect = styled(Select)`
  width: 200px;
  border-bottom: 1px solid #aaaaaa;
`;

const StyledStoreSelect = styled(Select)`
  width: 200px;
  border-bottom: 1px solid #aaaaaa;
`;

const MenuList = props => {
  const rows = props.children;
  const StyledRow = styled.div`
    overflow: hidden;
  `;
  const rowRenderer = ({ key, index, style }) => (
    <StyledRow key={key} style={style}>
      {rows[index]}
    </StyledRow>
  );

  return (
    <List
      style={{ width: "100%" }}
      width={300}
      height={300}
      rowHeight={30}
      rowCount={rows.length || 0}
      rowRenderer={rowRenderer}
    />
  );
};

const getSalonsOptions = salons =>
  Object.values(salons).map(salon => ({
    value: salon.StoreNumber,
    label: `${salon.Name} (#${salon.StoreNumber})`
  }));

export default class DayTypeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salonsOptions: getSalonsOptions(this.props.salons)
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.salons !== this.props.salons) {
      this.setState({
        salonsOptions: getSalonsOptions(this.props.salons)
      });
    }
  }

  handleChange(e) {
    const { partialRow, setWeeklyEditDayActivity, absenceReasons, isSplitShift } = this.props;
    const absenceReasonsValues = absenceReasons.map(absenceReason => absenceReason.value);
    const dayType = e.value;
    const isAbsenceType = absenceReasonsValues.includes(dayType);
    const type = isAbsenceType ? dayActivityTypes.ABSENCE : dayType;
    const absenceReason = isAbsenceType ? dayType : null;
    const resetIds = partialRow.type && partialRow.type !== type;
    const storeId = dayType === dayActivityTypes.RELOCATION ? partialRow.store : null;
    const petSupportedType = isWorkOrRelocationActivityType(dayType) ? [petSupportedTypes.DOG] : [];

    setWeeklyEditDayActivity({
      isSplitShift,
      localId: partialRow.localId,
      type,
      absenceReason,
      petSupportedType,
      storeId,
      resetIds,
    });
  }

  handleRelocationSalonChange(e) {
    const {
      partialRow,
      setWeeklyEditDayActivity,
      loadRelocatedSalonHours,
      selectedDate
    } = this.props;
    setWeeklyEditDayActivity({ localId: partialRow.localId, storeId: e.value });

    const beginDate = moment(selectedDate).startOf("isoWeek");
    const endDate = moment(selectedDate).endOf("isoWeek");
    loadRelocatedSalonHours({ storeNumber: e.value, beginDate, endDate });
  }

  render() {
    const { partialRow, readOnly, dayTypesAndAbsenceReasons, isTemplateMode } = this.props;
    const dayTypeOptions = isTemplateMode ? dayTypes : dayTypesAndAbsenceReasons;
    const dayTypeOption =
      partialRow.type === dayActivityTypes.ABSENCE ? partialRow.absenceReason : partialRow.type;
    const selectedOption = find(option => option.value === dayTypeOption, dayTypeOptions);
    const isRelocation = selectedOption && selectedOption.value === dayActivityTypes.RELOCATION;
    const selectedSalon = find(
      option => option.value === partialRow.storeId,
      this.state.salonsOptions
    );

    if (!partialRow.type) {
      return null;
    }

    if (readOnly) {
      return (
        <div>
          <div>{selectedOption && selectedOption?.label}</div>
          {isRelocation && <div>Store#: {partialRow.storeId}</div>}
        </div>
      );
    }

    return (
      <DayTypeWrapper>
        <StyledDayTypeSelect
          options={dayTypeOptions}
          value={selectedOption}
          backgroundColor="inherit"
          onChange={e => this.handleChange(e)}
        />
        {isRelocation && (
          <StyledStoreSelect
            components={{ MenuList }}
            options={this.state.salonsOptions}
            value={selectedSalon}
            backgroundColor="inherit"
            onChange={e => this.handleRelocationSalonChange(e)}
          />
        )}
      </DayTypeWrapper>
    );
  }
}
