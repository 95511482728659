/**
 * Sorts an array of objects by a specified property.
 *
 * @param {Object[]} arr - The array of objects to sort.
 * @param {string} property - The name of the property to sort by.
 * @returns {Object[]} The sorted array of objects.
 */
export function sortByPropertyAsc(arr = [], property) {
  // Use the sort() method with a comparator function that compares the specified property of each object
  return arr.sort((a, b) => {
    if (typeof a[property] === "number" && typeof b[property] === "number") {
      return a[property] - b[[property]];
    }
    return `${a[property] || ""}`.localeCompare(`${b[property] || ""}`);
  });
}

/**
 * Sorts an array of objects by a specified property in descending order
 *
 * @param {Object[]} arr - The array of objects to sort.
 * @param {string} property - The name of the property to sort by.
 * @returns {Object[]} The sorted array of objects.
 */
export function sortByPropertyDesc(arr = [], property) {
  // Use the sort() method with a comparator function that compares the specified property of each object
  return arr.sort((a, b) => {
    if (typeof a[property] === "number" && typeof b[property] === "number") {
      return b[property] - a[[property]];
    }
    return `${b[property] || ""}`.localeCompare(`${a[property] || ""}`);
  });
}

/**
 * Sorts a given list in ascending order. e.g. ["201", "203", "202"] to ["201", "202", "203"]
 *
 * @memberOf Utils.Array
 * @param {Array} list - The list to be sorted.
 * @returns {Array} - The sorted list in ascending order.
 *
 * @example
 *
 * sortListAscendingOrder(["201", "203", "202"]);
 */
export const sortListAscendingOrder = list => {
  const clonedList = [...list];

  return clonedList.sort((a, b) => a - b);
};

/**
 * Sorts a given list in descending order. e.g. ["201", "203", "202"] to ["203", "202", "201"]
 *
 * @memberOf Utils.Array
 * @param {Array} list - The list to be sorted.
 * @returns {Array} - The sorted list in descending order.
 *
 * @example
 *
 * sortListAscendingOrder(["201", "203", "202"]);
 */
export const sortListDescendingOrder = list => {
  const clonedList = [...list];

  return clonedList.sort((a, b) => b - a);
};
