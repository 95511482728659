import React from "react";
import styled from "styled-components";
import { color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import CommonButton from "web/common/commonButton";
import ClickableText from "web/common/ClickableText";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  font-size: ${fontSizes.regular};
  font-weight: bold;
  text-align: center;
`;

const Message = styled.div`
  margin: 20px auto;
  text-align: center;
  max-width: 400px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default function AlertModalComponent({
  onClose,
  header,
  message,
  onButtonClick,
  isVisible,
  label,
  isHidden,
  componentID,
}) {
  if (!isHidden) {
    return (
      <Container componentId={componentID}>
        <Header>{header}</Header>
        <Message>{message}</Message>
        <ButtonsContainer>
          <ClickableText isVisible={isVisible} styleObj={{ color: color.kalEl }} onClick={onClose}>
            Cancel
          </ClickableText>
          <CommonButton label={label} onClick={onButtonClick} />
        </ButtonsContainer>
      </Container>
    );
  }
  return null;
}
