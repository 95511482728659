/** @module postalCodeValidation */

/**
 * Returns boolean if postal code is valid
 *
 * @export isValidPostalCode
 * @param {string} postalCode Us or CA postal code
 * @param {string} countryCode Country Code abbreviation
 */
export default function isValidPostalCode(postalCode, countryCode) {
  let postalCodeRegex = null;

  if (!postalCode) {
    return true;
  }

  switch (countryCode) {
    case "US":
      postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
      break;
    case "CA":
      postalCodeRegex = /^[A-Z][0-9][A-Z]\s?[0-9][A-Z][0-9]$/i;
      break;
    default:
      postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
  }
  return postalCodeRegex.test(postalCode);
}
