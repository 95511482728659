/*
    A cohesive harmonic modular scale that will provide consistent scaling for layout components

    NOTE: If additional scale values are added, you must also update scaleTokens.TS
*/

const ratio: number = 1.5;
const base: number = 1;

// scale up
export const G_1: number = base * ratio;
export const G_2: number = G_1 + ratio;
export const G_3: number = G_2 * ratio;
export const G_4: number = G_3 * ratio;
export const G_5: number = G_4 * ratio;
export const G_6: number = G_5 * ratio;
export const G_7: number = G_6 * ratio;
export const G_8: number = G_7 * ratio;
export const G_9: number = G_8 * ratio;
export const G_10: number = G_9 * ratio;

// scale down
export const S_1: number = base / ratio;
export const S_2: number = S_1 / ratio;
export const S_3: number = S_2 / ratio;
export const S_4: number = S_3 / ratio;
export const S_5: number = S_4 / ratio;
export const S_6: number = S_5 / ratio;
export const S_7: number = S_6 / ratio;
export const S_8: number = S_7 / ratio;
export const S_9: number = S_8 / ratio;
export const S_10: number = S_9 / ratio;
