import { createSelector } from "reselect";

/**
 * Retrieves the store capabilities from the provided state object.
 *
 * @memberof Selectors.Capabilities
 * @function
 * @param {object} state - The state object containing store capabilities.
 * @returns {object} - The store capabilities retrieved from the state object.
 */
export const selectStoreCapabilities = state => state?.storeCapabilities;

/**
 * Retrieves the capabilities from the store capabilities.
 *
 * @memberof Selectors.Capabilities
 * @function
 * @param {Function} selectStoreCapabilities - A function that selects the store capabilities.
 * @returns {Array} The capabilities from the store capabilities.
 */
export const selectCapabilities = createSelector([selectStoreCapabilities], storeCapabilities => {
  return storeCapabilities?.capabilities;
});
