import React from "react";
import styled from "styled-components";
import { color, font } from "web/common/styles/theme";

const ComonentWrapper = styled.section`
  display: flex;
  flex-direction: row;
  font-family: ${font.secondaryFamily};
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${color.tChalla};
`;

/**
 * Shared header for the Void Transaction Modal
 * @param {String} title
 */
export default function VoidTransactionModalHeader(props) {
  const { title } = props;

  return <ComonentWrapper>{title}</ComonentWrapper>;
}
