import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  hotelDeparturesActionTypes,
  loadHotelDeparturesFailure,
  loadHotelDeparturesSuccess,
} from "dux/hotelDepartures/columnList/hotelDeparturesActions";

export function* loadHotelDepartures({ departures }) {
  try {
    yield put(loadHotelDeparturesSuccess({ departures }));
  } catch (error) {
    yield put(loadHotelDeparturesFailure({ error }));
  }
}

function* watchHotelDepartures() {
  yield takeEvery(hotelDeparturesActionTypes.LOAD_HOTEL_DEPARTURES_REQUEST, loadHotelDepartures);
}

export default function* hotelDeparturesSaga() {
  yield all([fork(watchHotelDepartures)]);
}
