import { connect } from "react-redux";
import { getPetsNamesAndIdsAndActiveByCustomer } from "@/core/selectors/entitiesSelector";
import { toggleAddVaccinationForm } from "@/core/actionCreators/petVaccinationsActionCreators";
import { getIsAddVaccinationFormOpen } from "@/core/selectors/ui/vaccinations/vaccinationsSelectors";
import PetsTabs from "@/web/petParentProfile/pets/petsTabs/PetsTabs";

const mapStateToProps = (state, { customerKey }) => {
  return {
    tabsData: getPetsNamesAndIdsAndActiveByCustomer(state, { customerKey }),
    style: { marginBottom: "0.625rem" },
    isAddVaccinationFormOpen: getIsAddVaccinationFormOpen(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTabChange: () => {
      dispatch(toggleAddVaccinationForm());
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isAddVaccinationFormOpen } = stateProps;
  const { onTabChange } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onTabChange: () => {
      if (isAddVaccinationFormOpen) {
        onTabChange();
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PetsTabs);
