import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import { Form, FormField } from "@prism/psm-ui-components";
import { useForm } from "../utils/formUtils/useForm";
import Calendar from "@/web/common/Calendar";
import { getPetVaccinationOptions } from "@/core/selectors/ui/vaccinations/vaccinationsSelectors";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import { getFormattedSelectedDate } from "./petVaccinationUtils";
import petVaccinationsActionCreators from "@/core/actionCreators/petVaccinationsActionCreators";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import petVaccinationsActionTypes from "@/core/actionTypes/petVaccinationsActionTypes";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { Button } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";

function PetVaccinationsFormComponent({
  componentId,
  isHidden,
  isLoading,
  vaccinationsDropdownOptions,
  createPetVaccination,
  closeForm,
}) {
  const { values, setDropdownOption, setFormValue } = useForm();
  const currentDate = moment().format("YYYY-MM-DD");
  const { vaccinationExpirationDate = currentDate, vaccinationType } = values;
  const isFormCompleted = vaccinationExpirationDate && vaccinationType;
  const isDisabled = isLoading || !isFormCompleted;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutStack>
        <FormField label="Date">
          <Calendar
            hasIcon
            numberOfMonths={1}
            displayFormat="MM/DD/YYYY"
            selectedDate={vaccinationExpirationDate}
            selectDate={selectedDate => {
              const formattedValue = getFormattedSelectedDate(selectedDate);
              setFormValue("vaccinationExpirationDate", formattedValue);
            }}
            isOutsideRange={day => day.isBefore(currentDate, "day")}
          />
        </FormField>
        <Form.Field.Select
          legacy
          label="Type"
          name="vaccinationType"
          variant="material"
          options={vaccinationsDropdownOptions}
          onChange={setDropdownOption}
          value={vaccinationType}
          transformLabel={option => {
            const selectedOption = vaccinationsDropdownOptions.find(
              vaccination => vaccination.value === option,
            );

            return selectedOption?.label;
          }}
        />
        <LayoutCluster>
          <LayoutBox style={{ marginLeft: "auto" }}>
            <Button
              variant="primary"
              disabled={isDisabled}
              onClick={() => createPetVaccination({ vaccinationType, vaccinationExpirationDate })}
              text="Submit"
            />
          </LayoutBox>
          <LayoutBox>
            <Button text="Cancel" onClick={closeForm} />
          </LayoutBox>
        </LayoutCluster>
      </LayoutStack>
    </LayoutBox>
  );
}

export const PetVaccinationsForm = connect(
  state => {
    const selectedPet = getSelectedPet(state);
    return {
      componentId: "PetVaccinationsForm",
      selectedPet,
      isHidden: false,
      isLoading: createLoadingSelector([petVaccinationsActionTypes.CREATE_PET_VACCINATION])(state),
      vaccinationsDropdownOptions: getPetVaccinationOptions(state, {
        petId: selectedPet,
      }),
      customerKey: getCurrentCustomerKey(state),
    };
  },
  dispatch => ({
    dispatchCreatePetVaccination: payload => {
      dispatch(petVaccinationsActionCreators.createPetVaccination(payload));
    },
    dispatchToggleAddVaccinationForm: () => {
      dispatch(petVaccinationsActionCreators.toggleAddVaccinationForm());
    },
  }),
  (stateProps, dispatchProps, ownProps) => {
    const { customerKey, selectedPet } = stateProps;
    const { dispatchCreatePetVaccination, dispatchToggleAddVaccinationForm } = dispatchProps;

    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      createPetVaccination: ({ vaccinationType, vaccinationExpirationDate }) => {
        dispatchCreatePetVaccination({
          customerKey,
          expirationDate: vaccinationExpirationDate,
          vaccinationId: vaccinationType,
          petId: selectedPet,
          onComplete: () => {
            dispatchToggleAddVaccinationForm();
          },
        });
      },
      closeForm: () => dispatchToggleAddVaccinationForm(),
    };
  },
)(PetVaccinationsFormComponent);
