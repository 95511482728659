import { combineReducers } from "redux";
import quickViewRebookReducer from "dux/quickViews/quickViewRebookReducer";
import appointmentQuickViewReducer from "./appointmentQuickViewReducer";
import petAppointmentQuickViewReducer from "./petAppointmentQuickViewReducer";

const quickViewReducer = combineReducers({
  appointmentQuickView: appointmentQuickViewReducer,
  rebookQuickView: quickViewRebookReducer,
  petAppointmentQuickView: petAppointmentQuickViewReducer,
});

export default quickViewReducer;
