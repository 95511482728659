import React from "react";
import { connect } from "react-redux";
import { TextPassage } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import NotificationImage from "assets/icons/notificaiton.svg";
import { selectHotelItineraryHasSalon } from "../hotelItinerary/hotelItinerarySelectors";

export const CheckInOutSalonInvoiceMsgComponent = ({ componentId, isHidden }) => {
  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutCluster>
        <img alt="icon" src={NotificationImage} />
        <TextPassage>Salon invoice available</TextPassage>
      </LayoutCluster>
    </LayoutBox>
  );
};

export const CheckInOutSalonInvoiceMsg = connect(state => {
  const hasSalonAppt = selectHotelItineraryHasSalon(state);
  return {
    componentId: "CheckInOutSalonInvoiceMsg",
    isHidden: !hasSalonAppt,
  };
})(CheckInOutSalonInvoiceMsgComponent);
