import { connect } from "react-redux";
import { getIsSRCAgent } from "core/selectors/persistentSelectors";
import { getIsBulkPackageOfferingsAccordionOpen } from "../../toggleAccordionSelectors";
import { toggleBulkPackageOfferingsAccordion } from "../../toggleAccordionActions";
import { createLoadingSelector } from "../../../../core/selectors/utils";
import { GET_BULK_PACKAGE_OFFERINGS } from "../../../bgm/bulkPackageOfferings/bulkPackageOfferingsActions";
import AccordionComponent from "../../../../web/common/Accordion/AccordionComponent";

const mapStateToProps = state => {
  return {
    componentID: "bulk-package-offerings-accordion-header",
    isOpen: getIsBulkPackageOfferingsAccordionOpen(state),
    isLoading: createLoadingSelector([GET_BULK_PACKAGE_OFFERINGS])(state),
    label: "Bulk Packages",
    isHidden: getIsSRCAgent(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchHeaderToggle: isOpen => dispatch(toggleBulkPackageOfferingsAccordion(isOpen)),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  onClick: () => propsFromDispatch.dispatchHeaderToggle(propsFromState.isOpen),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AccordionComponent);
