import React from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { StoreNameNumberAddress } from "@/dux/storeNameNumberAddress/StoreNameNumberAddress";
import { color } from "@/web/common/styles/theme";
import { PetParentCartSummary } from "@/dux/petParentCartSummary/PetParentCartSummary";
import { DdcPackagesPriceAndPurchase } from "../ddcPackagesPriceAndPurchase/DdcPackagesPriceAndPurchase";
import { Hr } from "@petsmart-ui/sparky";

/**
 * React view component for cart column on DDC packages page
 * @memberOf Views.Purchase
 * @param {*} props
 * @returns {JSX.Element}
 */
export const DDCPackagesCartColumnComponent = props => {
  const { componentId, isHidden } = props;
  if (isHidden) return null;

  return (
    <LayoutBox
      id={componentId}
      padding="scale-G1"
      style={{ borderLeft: `1px solid ${color.borderGrey}` }}
    >
      <LayoutStack>
        <PetParentCartSummary />
        <Hr style={{ marginLeft: 0, marginRight: 0 }} />

        <DdcPackagesPriceAndPurchase />
        <Hr style={{ marginLeft: 0, marginRight: 0 }} />

        <StoreNameNumberAddress heading="Package summary" />
      </LayoutStack>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for cart column on DDC packages page
 * @memberOf Views.Purchase
 * @summary path: /purchase/:customerKey/ddc-packages
 * @function
 * @name DDCPackagesCartColumn
 * @example <DDCPackagesCartColumn />
 */
export const DDCPackagesCartColumn = connect(state => {
  return {
    componentId: "DDCPackagesCartColumn",
  };
})(DDCPackagesCartColumnComponent);
