import { getContacts } from "../../customerProfile";

export default function updateCommunicationPreferences({
  updatedContacts,
  communicationTypeDescription,
  communicationTypeId,
  id,
}) {
  return resource => {
    const areContactsUpdated = updatedContacts.some(({ contactId }) => contactId === resource[id]);
    return {
      ...resource,
      communicationPreferences: [
        {
          communicationTypeDescription,
          communicationTypeId,
          contacts: areContactsUpdated
            ? updatedContacts.filter(({ contactId }) => contactId === resource[id])
            : getContacts(resource.communicationPreferences),
        },
      ],
    };
  };
}
