import { connect } from "react-redux";
import { history } from "@/dux/utils/browser/browserHistory";
import { hideCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import {
  isBGMEligibilityHidden,
  isFTCOHidden,
} from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import CommonButton from "@/web/common/commonButton";

const mapStateToProps = state => {
  return {
    isHidden: !isFTCOHidden(state) || !isBGMEligibilityHidden(state),
    componentId: "checkInOut__commonButton",
    label: "Done",
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClick: () => {
      history.push("/dashboard");
      dispatch(hideCheckInOutModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonButton);
