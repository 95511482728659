import React from "react";
import { connect } from "react-redux";

// Components
import { Button } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";

// Selectors
import { getFirstHotelEngagementStatusByPet } from "@/dux/hotelEngagements/hotelEngagementSelectors";

// Utils
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { APPOINTMENT_STATUS } from "@/core/constants";

// Actions
import { getHotelServiceCard } from "./hotelPrintServiceCardActions";

/**
 *  Print Service Card Button
 *  @memberOf Views.Store
 *  @function
 *  @name PrintServiceCardButton
 *  @param { Object } props
 */
export const PrintServiceCardButton = props => {
  const { componentId, isHidden, label, onClick } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <Button variant="primary" onClick={onClick} text={label} />
    </LayoutBox>
  );
};

// CONTAINER ----------------------------------------------------------------------------------------
export const HotelPrintServiceCardButton = connect(
  state => {
    const petId = getCurrentPet(state);
    const engagementStatus = getFirstHotelEngagementStatusByPet(state, { petId });
    const isHidden = engagementStatus !== APPOINTMENT_STATUS.CHECKED_IN;

    return {
      componentId: "HotelPrintServiceCardButton",
      isHidden,
      label: "Print Service Card",
      petId,
    };
  },

  dispatch => {
    return {
      onClick: petId => {
        dispatch(getHotelServiceCard({ petId }));
      },
    };
  },
  (stateProps, dispatchProps) => {
    const { componentId, isHidden, label, petId } = stateProps;
    const { onClick } = dispatchProps;

    return {
      componentId,
      isHidden,
      label,
      onClick: () => onClick(petId),
    };
  },
)(PrintServiceCardButton);
