import { associateWebAPI } from "./_axiosConfigAssociateWeb";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Gets Pet records from Salesforce and PODS by customerKey From Web Associate API - Get Pets for a Customer
 * @param {*} { customerKey }
 * @returns
 */
export function fetchPetsByCustomer({ customerKey }) {
  return associateWebAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/pets?includeInactives=true`
  );
}

/**
 * Gets Pet records from Salesforce and PODS from Web Associate API - Gets Specific Pet record for a Customer
 * @param {*} { petId, customerKey }
 * @returns
 */
export function fetchPet({ petId, customerKey }) {
  return associateWebAPI.get(`${endpointVersionString()}/customers/${customerKey}/pets/${petId}`);
}

/**
 * Updates Pet records in Salesforce and PODS from Web Associate API - Update Pet records for specific Pet
 * @param {*} { petId, customerKey, data }
 * @returns
 */
export function putPet({ petId, customerKey, data }) {
  return associateWebAPI.put(
    `${endpointVersionString()}/customers/${customerKey}/pets/${petId}`,
    data
  );
}

/**
 * Posts Pet records to Salesforce and PODS from Web Associate API - Create Pets for a Customer
 * @param {*} { customerKey, data }
 * @returns
 */
export function postPet({ customerKey, data }) {
  return associateWebAPI.post(`${endpointVersionString()}/customers/${customerKey}/pets`, data);
}
