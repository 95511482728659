import { associateWebAPI } from "./_axiosConfigAssociateWeb";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * API for retrieving notifications based on store number, display location, and date.  Notification message, service location type, display locations, and start and end date times are returned.
 * confluence https://confluence.ssg.petsmart.com/display/PART/Notification+REST+API
 * @param {*} { displayLocation, date }
 * @returns
 */
export function getNotifications({ storeNumber, displayLocation, date }) {
  return associateWebAPI.get(`${endpointVersionString()}/stores/${storeNumber}/notifications`, {
    params: { displayLocation, date },
  });
}
