import React from "react";
import styled from "styled-components";
import { color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  margin: ${props => (props.highlight ? props.highlight : "0")};
`;

const Message = styled.div`
  color: ${props => (props.isError ? color.errorRed : "initial")};
  font-size: ${props => (props.highlight ? fontSizes.regular : "initial")};
  font-weight: ${props => (props.highlight ? "bold" : "initial")};
`;

export default function CommonErrorMessage(props) {
  const { isHidden, message } = props;

  if (!isHidden) {
    return (
      <SectionWrapper {...props}>
        <Message {...props}>{message}</Message>
      </SectionWrapper>
    );
  }

  return null;
}
