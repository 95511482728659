// OverBooking Setup Table Selectors
import { createSelector } from "reselect";

// Imported Selectors
import {
  getHotelRoomSelectedRoomType,
  getHotelRoomSelectionRoomTypes,
} from "@/dux/hotelRoomSelection/hotelRoomSelectionSelector";

/**
 *
 * @param {object} state
 * @param {object} [state.overBookingSetupTable]
 * @param {Array<{}>} [state.overBookingSetupTable.data]
 * @returns Overbooking Setup data
 */
export const selectOverBookingSetupData = state => state?.overBookingSetupTable?.data;

/**
 *
 * @param {object} state
 * @param {object} [state.overBookingSetupTable]
 * @param {Object[]} [state.overBookingSetupTable.error]
 * @param {string} [state.overBookingSetupTable.error.message]
 * @returns Errors for Fetching Overbooking Setup data
 */
export const selectOverBookingSetupError = state =>
  state?.overBookingSetupTable?.error?.[0]?.message;

export const selectOverBookingSetupDataByRoomType = createSelector(
  [selectOverBookingSetupData, getHotelRoomSelectedRoomType, getHotelRoomSelectionRoomTypes],
  (data, selectedRoomType, roomTypes) => {
    // Getting roomTypeBucketId based on roomTypeName.
    const selectedRoomTypeBucketId = Object.keys(roomTypes)?.find(item =>
      roomTypes[item].roomTypeBucketName.includes(selectedRoomType),
    );
    return data?.filter(item => item.roomTypeBucketId === selectedRoomTypeBucketId);
  },
);
