import { getState } from "@/core/selectors/commonSelector";
import { createSelector } from "reselect";

/**
 * Selector to get selected report date
 * @memberOf Selectors.Reports
 * @function
 * @name selectSelectedReportDate
 * @param {Object} state
 * @returns {string} selected report date
 * @example selectSelectedReportDate(state)
 */
export const selectSelectedReportDate = createSelector([getState], state => {
  // Default is today's date
  return state?.reportDateSelection?.selectedReportDate;
});
