import { connect } from "react-redux";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { getAvailableTrainingBundlesForServiceSelection } from "@/dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import TrainingClassTypeListComponent from "@/dux/_components/scheduleTrainingPage/TrainingClassTypeListComponent";

const mapStateToProps = state => {
  // Using getCurrentPet instead of getSelectedPet since this data will be persisted on refresh and prevent type errors
  const petId = getCurrentPet(state);
  return {
    componentID: "ScheduleTrainingColumns_ColumnsComponent",
    isHidden: false,
    trainingBundles: getAvailableTrainingBundlesForServiceSelection(state, { petId }),
  };
};

export default connect(mapStateToProps)(TrainingClassTypeListComponent);
