import { createSelector } from "reselect";
import { getOr, get } from "lodash/fp";
import { getMoDayYearDate } from "core/utils/dateUtils/formatDateTime";
import { getProps, getState } from "../../../core/selectors/commonSelector";
import { getUiWeb } from "../../../core/selectors/uiSelector";
import { getSelectedPetDetails } from "../../../core/selectors/bookingUISelectors";
import { BGM, FTCO } from "@/web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";

// BGM offerings -----------------------------------------------------------------------------------

export const getSelectedBulkPackageOfferings = createSelector(
  [getUiWeb],
  get(["bulkPackageOfferings", "selectedOffering"]),
);

export const getBulkPackageOfferingsServiceTypesByPet = createSelector(
  [getSelectedPetDetails],
  getOr([], "bgmPackages"),
);

export const getBulkPackageOfferingsServiceTypeByPetServiceId = createSelector(
  [getBulkPackageOfferingsServiceTypesByPet, getProps],
  (offerings, { petServiceId }) => offerings.find(service => service.petServiceId === petServiceId),
);

export const getBulkPackageServiceName = createSelector(
  [getBulkPackageOfferingsServiceTypeByPetServiceId],
  get("primaryServiceName"),
);

export const getBulkPackageOfferingHasExistingPackage = createSelector(
  [getBulkPackageOfferingsServiceTypeByPetServiceId],
  get("hasExistingPackage"),
);

export const getBulkPackageOfferingsGroupRows = createSelector(
  [getBulkPackageOfferingsServiceTypeByPetServiceId],
  getOr([], "packages"),
);

function filterByPackagePredicate(packageEntry, type) {
  if (type) {
    return packageEntry?.offerType === type;
  }

  return true;
}

function transformPackageListValues(packageEntry, type) {
  if (type === BGM) {
    const { packageName, packagePrice } = packageEntry;
    return { ...packageEntry, packageListValues: [packageName, `$${packagePrice?.toFixed(2)}`] };
  }

  if (type === FTCO) {
    const { packageName, offerEligibilityExpiration, packagePrice } = packageEntry;
    return {
      ...packageEntry,
      packageListValues: [
        packageName,
        `Exp: ${getMoDayYearDate(offerEligibilityExpiration)}`,
        `$${packagePrice?.toFixed(2)}`,
      ],
    };
  }

  return packageEntry;
}

export const getPackagesByType = ({ state, type, petServiceId }) => {
  const packages = getBulkPackageOfferingsGroupRows(state, { petServiceId });
  return packages
    .filter(packageEntry => filterByPackagePredicate(packageEntry, type))
    .map(packageEntry => transformPackageListValues(packageEntry, type));
};

export const getBulkPackageOfferingsGroupRow = createSelector(
  [getBulkPackageOfferingsGroupRows, getProps],
  (bulkPackageOfferingsGroupRows, { productBundleConfigId }) => {
    return bulkPackageOfferingsGroupRows.find(
      bulkPackageOfferingsGroupRow =>
        bulkPackageOfferingsGroupRow.productBundleConfigId === productBundleConfigId,
    );
  },
);

export const getIsBulkPackageOfferingSelected = createSelector(
  [getSelectedBulkPackageOfferings, getProps],
  (selectedBulkPackageOfferings, { productBundleConfigId }) => {
    return get(productBundleConfigId, selectedBulkPackageOfferings);
  },
);

export const getIsAnyBulkPackageOfferingSelected = createSelector(
  [getSelectedBulkPackageOfferings],
  selectedPackageOfferings => {
    return Object.keys(selectedPackageOfferings).some(key => get(key, selectedPackageOfferings));
  },
);

export const getSelectedPackageOffering = createSelector(
  [getSelectedBulkPackageOfferings, getState],
  (selectedPackageOfferings, state) => {
    const { petServiceId, ...configIds } = selectedPackageOfferings;
    const productBundleConfigId = Object.keys(configIds)[0];

    return getBulkPackageOfferingsGroupRow(state, { petServiceId, productBundleConfigId });
  },
);

export const getSelectedProductBundleConfigId = createSelector(
  [getSelectedBulkPackageOfferings],
  selectedBulkPackageOfferings => {
    return Object.keys(selectedBulkPackageOfferings).find(Boolean);
  },
);

// BGM add-ons -------------------------------------------------------------------------------------

function generateCompositeKey(petServiceId, bundleConfigId) {
  return `${petServiceId}-${bundleConfigId}`;
}

export const getselectedAddOns = createSelector(
  [getUiWeb],
  get(["bulkPackageOfferings", "addOns"]),
);

export const getSelectedAddOn = createSelector([getselectedAddOns], get("selectedAddOn"));

export const getAddOnsByBundleConfigId = createSelector(
  [getSelectedPetDetails, getSelectedProductBundleConfigId, getselectedAddOns],
  (pet, bundleConfigId, addOns) => {
    const compositeKey = generateCompositeKey(pet?.petId, bundleConfigId);
    const addonsList = get([compositeKey], addOns);
    // we don't want enhanced services mixed with the list of addons
    return addonsList?.filter((addon) => !addon.isEnhancedAddOn);
  },
);

export const hasRequestedAddOnsByBundleConfigId = createSelector(
  [getSelectedPetDetails, getSelectedProductBundleConfigId, getselectedAddOns],
  (pet, bundleConfigId, addOns) => {
    const compositeKey = generateCompositeKey(pet?.petId, bundleConfigId);
    return Object.keys(addOns).includes(compositeKey);
  },
);

/**
 * Selector to get all the enhanced services for a bulk package
 * @memberOf Selectors.ui
 * @function
 * @name getSelectedEnhancedServices
 * @param {Object} state - redux state
 * @returns {Object} enhancedServices
 * @example getSelectedEnhancedServices(state)
 */
export const getSelectedEnhancedServices = createSelector(
  [getUiWeb],
  (uiWeb) => uiWeb?.bulkPackageOfferings?.enhancedServices || {},
);

export const getSelectedEnhancedService = createSelector([getSelectedEnhancedServices], get("selectedEnhancedService"));

export const getEnhancedServicesByBundleConfigId = createSelector(
  [getSelectedPetDetails, getSelectedProductBundleConfigId, getSelectedEnhancedServices],
  (pet, bundleConfigId, enhancedServices) => {
    const compositeKey = generateCompositeKey(pet?.petId, bundleConfigId);
    const enhancedServicesList = get([compositeKey], enhancedServices);
    return enhancedServicesList || [];
  },
);

export const getSelectedEnhancedServiceName = createSelector(
  [getEnhancedServicesByBundleConfigId, getSelectedEnhancedService],
  (enhancedServices, enhancedServiceId) => {
    const enhancedService = enhancedServices.find(es => es.addOnId === enhancedServiceId);
    return enhancedService?.addOnName && `Enhanced-Service: ${enhancedService?.addOnName}`;
});

export const getSelectedEnhancedServicePrice = createSelector(
  [getEnhancedServicesByBundleConfigId, getSelectedEnhancedService],
  (enhancedServices, enhancedServiceId) => {
    const enhancedService = enhancedServices.find(es => es.addOnId === enhancedServiceId);
    return enhancedService?.price;
});


export const hasRequestedEnhancedServicesByBundleConfigId = createSelector(
  [getSelectedPetDetails, getSelectedProductBundleConfigId, getSelectedEnhancedServices],
  (pet, bundleConfigId, enhancedServices) => {
    const compositeKey = generateCompositeKey(pet?.petId, bundleConfigId);
    return Object.keys(enhancedServices).includes(compositeKey);
  },
);

export const isAnyAddOnSelected = createSelector([getSelectedAddOn], selectedAddOn => {
  return Object.values(selectedAddOn).includes(true);
});

export const getSelectedAddOnId = createSelector([getSelectedAddOn], selectedAddOn => {
  return Object.keys(selectedAddOn).find(Boolean);
});

// contextual data based on user input: we grab the name + price later
export const getSelectedAddOnData = createSelector(
  [getSelectedAddOnId, getAddOnsByBundleConfigId],
  (selectedAddOn, addOns = []) => addOns.find(item => item.addOnId === Number(selectedAddOn)),
);

export const getSelectedAddOnName = createSelector([getSelectedAddOnData], addOn => {
  return addOn?.addOnName && `Add-On: ${addOn?.addOnName}`;
});

export const getSelectedAddOnPrice = createSelector([getSelectedAddOnData], addOn => addOn?.price);

// BGM cart details --------------------------------------------------------------------------------

export const getPetServiceDataByUserSelection = createSelector(
  [getBulkPackageOfferingsServiceTypesByPet, getSelectedBulkPackageOfferings],
  (offerings, { petServiceId }) => {
    return offerings.find(offering => offering.petServiceId === petServiceId);
  },
);

export const getOfferingByUserSelection = createSelector(
  [getPetServiceDataByUserSelection, getSelectedProductBundleConfigId],
  (petServiceData, bundleConfigId) => {
    const packages = getOr([], "packages", petServiceData);
    return packages.find(offering => offering.productBundleConfigId === bundleConfigId);
  },
);

export const getSelectedBulkPackageOfferingName = createSelector(
  [getOfferingByUserSelection],
  get("packageName"),
);

export const getPetServiceIdByConfigId = createSelector(
  [getPetServiceDataByUserSelection],
  data => data?.petServiceId,
);

export const getPrimaryServiceNameIdByConfigId = createSelector(
  [getPetServiceDataByUserSelection],
  getOr("", "primaryServiceName"),
);

export const getFormattedBulkPackageOfferingName = createSelector(
  [getPrimaryServiceNameIdByConfigId, getOfferingByUserSelection],
  (serviceName, selectedBulkPackageOffering) => {
    const packageName = get("packageName", selectedBulkPackageOffering);
    return `${serviceName} - ${packageName}`;
  },
);

export const getSelectedBulkPackageOfferingPrice = createSelector(
  [getOfferingByUserSelection],
  get("packagePrice"),
);

export const getBulkPackageOfferingsTotalPrice = createSelector(
  [getSelectedBulkPackageOfferingPrice, getSelectedEnhancedServicePrice, getSelectedAddOnPrice],
  (offeringPrice, enhancedServicePrice, addOnPrice) => {
    let sum = offeringPrice;
    if (!sum) return 0;

    if (enhancedServicePrice) {
      sum += enhancedServicePrice;
    }
    if (addOnPrice) {
      sum += addOnPrice;
    }
    return +sum.toFixed(2);
  },
);
