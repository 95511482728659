/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelArrivalsActionTypes = {
  LOAD_HOTEL_ARRIVALS_SUCCESS: "LOAD_HOTEL_ARRIVALS_SUCCESS",
  LOAD_HOTEL_ARRIVALS_REQUEST: "LOAD_HOTEL_ARRIVALS_REQUEST",
  LOAD_HOTEL_ARRIVALS_FAILURE: "LOAD_HOTEL_ARRIVALS_FAILURE",
  CLEAR_HOTEL_ARRIVALS: "CLEAR_HOTEL_ARRIVALS",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const loadHotelArrivalsSuccess = ({ arrivals }) => ({
  type: hotelArrivalsActionTypes.LOAD_HOTEL_ARRIVALS_SUCCESS,
  arrivals,
});

export const loadHotelArrivalsRequest = ({ arrivals }) => ({
  type: hotelArrivalsActionTypes.LOAD_HOTEL_ARRIVALS_REQUEST,
  arrivals,
});

export const loadHotelArrivalsFailure = ({ error }) => ({
  type: hotelArrivalsActionTypes.LOAD_HOTEL_ARRIVALS_FAILURE,
  error,
});

export const clearHotelArrivals = () => ({
  type: hotelArrivalsActionTypes.CLEAR_HOTEL_ARRIVALS,
});
