import React from "react";
import styled from "styled-components";
import { filter } from "lodash/fp";
import CommonButton from "./commonButton";
import Checkbox from "./Checkbox";

const Container = styled.div`
  padding: 1.5rem;
  width: 300px;
  flex-shrink: 0;
`;

const CheckboxWrapper = styled.div`
  padding: 1rem 0;
  font-weight: ${({ checked }) => (checked ? "bold" : "regular")};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

class SalonApplySpeicalsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedOptions: [] };
  }

  componentDidMount() {
    const { selectedOptions } = this.props;
    this.setState({ selectedOptions });
  }

  handleCheckboxClick = option => {
    const { selectedOptions } = this.state;

    if (selectedOptions.includes(option)) {
      this.setState({
        selectedOptions: filter(selectedOption => selectedOption !== option, selectedOptions),
      });
    } else {
      this.setState({
        selectedOptions: [...selectedOptions, option],
      });
    }
  };

  render() {
    const { closeFlyout, options, idProperty } = this.props;
    const { selectedOptions } = this.state;

    return (
      <Container>
        {options.map(option => {
          const checked = selectedOptions.includes(option[idProperty]);

          return (
            <CheckboxWrapper key={option[idProperty]} checked={checked}>
              <Checkbox
                label={option.name}
                checked={checked}
                labelPosition="right"
                onChange={() => this.handleCheckboxClick(option[idProperty])}
              />
            </CheckboxWrapper>
          );
        })}

        <ButtonContainer>
          <CommonButton label="Done" onClick={() => closeFlyout(selectedOptions)} />
        </ButtonContainer>
      </Container>
    );
  }
}

export default SalonApplySpeicalsList;
