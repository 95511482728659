import moment from "moment";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import { setWeekNumber } from "@/core/actionCreators/ui/web/commonActionCreators";
import { getTrainingClassAvailability } from "@/dux/trainingClassAvailability/trainingClassAvailabilityActions";
import getCacheEntryByDate from "../utils/dateUtils/getSelectedDateCacheEntry";
import { setCachedTrainingAvailabilityWeek } from "../scheduleTrainingAppointment/scheduleTrainingAppointmentActions";

export default function dispatchTrainingAvailability({
  selectedDate,
  cache,
  storeNumber,
  petServiceId,
  petId,
  dispatch,
}) {
  const week = moment(selectedDate).week();
  const cacheEntry = getCacheEntryByDate(selectedDate);

  dispatch(setSelectDate(selectedDate));
  dispatch(setWeekNumber({ weekNumber: week }));

  if (cache.indexOf(cacheEntry) === -1) {
    // fetch new results
    dispatch(
      getTrainingClassAvailability({
        storeNumber,
        fromDate: moment(selectedDate).startOf("isoWeek").format(),
        toDate: moment(selectedDate).endOf("isoWeek").format(),
        petServiceId,
        petId,
        onComplete: () => {
          dispatch(setCachedTrainingAvailabilityWeek(cacheEntry));
        },
      }),
    );
  }
}
