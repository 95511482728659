/**
 * Util to copy and addon object without the orderProductNumber
 * @memberOf Utils.Engagement
 * @function
 * @name newAddonFromExisting
 * @param {Object} existing - the addon obj
 * @returns {Object} - addon without orderProductNumber
 * @example newAddonFromExisting(existingAddon)
 */
export const newAddonFromExisting = ({ orderProductNumber, ...commonAddonAttrs } = {}) =>
  commonAddonAttrs;
