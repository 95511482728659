export const DISPLAY_STORE_HOURS_ERROR = "DISPLAY_STORE_HOURS_ERROR";
export const HIDE_STORE_HOURS_ERROR = "HIDE_STORE_HOURS_ERROR";

export const displayStoreHoursError = () => ({
  type: DISPLAY_STORE_HOURS_ERROR,
});

export const hideStoreHoursError = () => ({
  type: HIDE_STORE_HOURS_ERROR,
});
