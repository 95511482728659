import React from "react";
import styled from "styled-components";
import { fontSizes } from "web/common/styles/responsive/fonts";
import CommonModal from "../common/modals/commonModal";

const Header = styled.div`
  font-size: ${fontSizes.regular};
  font-weight: bold;
  text-align: center;
`;

const Message = styled.div`
  margin: 30px auto;
  text-align: center;
  max-width: 400px;
`;

export default ({ header, shouldDisplayHeader, serverErrorMessage, closeServerErrorModal }) => (
  <CommonModal onClose={closeServerErrorModal}>
    {shouldDisplayHeader && <Header>{header}</Header>}
    <Message>{serverErrorMessage}</Message>
  </CommonModal>
);
