import { put, takeEvery, call, all } from "redux-saga/effects";
import {
  GET_FOOD_OPTIONS_LIST,
  getFoodOptionsListRequest,
  getFoodOptionsListSuccess,
  getFoodOptionsListFailure,
  setFoodOptionsList,
} from "./foodOptionsListActions";
import { fetchFoodOptionsList } from "@/core/services/petsmartWebPublic/foodOptionsEndpoint";
import normalizeArrayByProperty from "@/core/utils/normalizeUtils/normalizeArray";

export function* saveFoodOptionsToState(foodsResponse) {
  const foodOptions = normalizeArrayByProperty(foodsResponse, "FoodId");
  yield put(setFoodOptionsList(foodOptions));
}

/**
 * @memberOf Sagas.foodOptions
 * @generator
 * @name onGetFoodOptionsList
 * @returns {Generator<*, void, *>}
 */
function* onGetFoodOptionsList() {
  try {
    yield put(getFoodOptionsListRequest());
    const response = yield call(fetchFoodOptionsList);
    yield call(saveFoodOptionsToState, response?.data);
    yield put(getFoodOptionsListSuccess());
  } catch (error) {
    yield put(getFoodOptionsListFailure());
  }
}

function* watchOnGetFoodOptionsList() {
  yield takeEvery(GET_FOOD_OPTIONS_LIST, onGetFoodOptionsList);
}

export default function* foodOptionsListSaga() {
  yield all([watchOnGetFoodOptionsList()]);
}
