import { createSelector } from "reselect";
import { get } from "lodash/fp";
import { getPet } from "../../../core/selectors/entitiesSelector";
import { speciesTypes } from "../../../core/constants/speciesContants";
import UnknownCatImage from "../../../assets/images/unknown-cat.png";
import UnknownDogImage from "../../../assets/images/unknown-dog.png";

export default createSelector([getPet], pet => {
  const isCat = get("speciesId", pet) === speciesTypes.CAT;

  if (isCat) {
    return UnknownCatImage;
  }

  return UnknownDogImage;
});
