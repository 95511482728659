import { cartAPI } from "core/services/cart/_axiosConfigCart";
import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { associateWebBFFAPI } from "../associateWebBff/_axiosConfigAssociateWebBff";
import { CUSTOMER_ID_HEADER_KEY } from "@/core/constants/endpointConstants";

/**
 * Updates an itinerary
 * @param {*} { itinerary payload. Structure can be found at https://confluence.ssg.petsmart.com/display/PS/6.+Itinerary+Instance+V2 }
 * @returns
 */
export function putHotelItinerary({ customerKey, data }) {
  return associateWebBFFAPI.put(`v1/hotel/itineraries/${data?.itineraryId}`, data, {
    headers: { [CUSTOMER_ID_HEADER_KEY]: customerKey },
  });
}

/**
 * Updates a portion itinerary
 * @param {*} { parts of an itinerary payload. Structure can be found at https://confluence.ssg.petsmart.com/display/PS/6.+Itinerary+Instance+V2 }
 * @returns
 */
export function patchHotelItinerary({ itineraryId, customerKey, data }) {
  return bookingAPI.patch(`hotel/v1/customers/${customerKey}/itineraries/${itineraryId}`, data);
}

/**
 *  PATCH API to Updates engagement(s) status(es) - using cartAPI
 *
 *  @memberOf APIs.hotel.itinerary
 *  @function
 *  @name patchHotelItineraryStatusEndpoint
 *  @param {Object } obj
 *  @param {Number} obj.itineraryId
 *  @param {Number} obj.customerKey
 *  @param {Object} obj.data
 *  @returns {Promise}
 *  @example
 *
 *  const response = yield call(patchHotelItineraryStatusEndpoint, {
 *       itineraryId,
 *       customerKey,
 *       data,
 *     });
 */
export function patchHotelItineraryStatusEndpoint({ itineraryId, customerKey, data }) {
  return cartAPI.patch(
    `v1/hotel/booking/customers/${customerKey}/itineraries/${itineraryId}/status`,
    data,
  );
}

/**
 *  DELETE Endpoint to remove manual specials from an itinerary - using cartAPI axios config
 *
 *  @memberOf APIs.hotel.itinerary
 *  @function
 *  @name deleteHotelManualSpecialsEndpoint
 *  @param {Object} obj
 *  @param {String} obj.itineraryId
 *  @param {String} obj.customerId
 *  @param {String} obj.specialCode - code of special to be removed
 *  @param {String} obj.petId - id of pet to remove special from
 *  @returns {Promise}
 *  @example
 *
 *  const response = yield call(deleteHotelManualSpecialsEndpoint, {
 *       itineraryId,
 *       customerId,
 *       specialCode,
 *       petId,
 *     });
 */
export function deleteHotelManualSpecialsEndpoint({ itineraryId, customerId, specialCode, petId }) {
  return cartAPI.delete(
    `v1/hotel/customers/${customerId}/itineraries/${itineraryId}/specials/${specialCode}?petIds=${petId}`,
  );
}

/**
 *  PATCH API to override pricing for service and addons on an itinerary - using cartAPI axios config
 *
 *  @memberOf APIs.hotel.itinerary
 *  @function
 *  @name patchHotelPriceOverrideEndpoint
 *  @param {Object} obj
 *  @param {Number} obj.itineraryId
 *  @param {Number} obj.customerId
 *  @param {Object} obj.data
 *  @returns {Promise}
 *  @example
 *
 *  const response = yield call(patchHotelPriceOverrideEndpoint, {
 *       itineraryId,
 *       customerId,
 *       data,
 *     });
 */
export function patchHotelPriceOverrideEndpoint({ itineraryId, customerId, data }) {
  return cartAPI.patch(
    `v1/hotel/customers/${customerId}/itineraries/${itineraryId}/priceOverride`,
    data,
  );
}

/**
 *  PATCH API to update addons on an itinerary
 *
 *  @memberOf APIs.hotel.itinerary
 *  @function
 *  @name patchHotelAddonsEndpoint
 *  @param {Object} obj
 *  @param {Number} obj.itineraryId
 *  @param {Number} obj.customerId
 *  @param {Object} obj.data
 *  @returns {Promise}
 *  @example
 *
 *  const response = yield call(patchHotelAddonsEndpoint, {
 *       itineraryId,
 *       customerId,
 *       data,
 *     });
 */
export function patchHotelAddonsEndpoint({ itineraryId, customerId, data }) {
  return associateWebBFFAPI.patch(
    `v1/hotel/customers/${customerId}/itineraries/${itineraryId}/addons`,
    data,
  );
}

/**
 *  PATCH API to update addons on an itinerary
 *
 *  @memberOf APIs.hotel.itinerary
 *  @function
 *  @name patchHotelAddonsEndpoint
 *  @param {Object} obj
 *  @param {Number} obj.itineraryId
 *  @param {Number} obj.customerId
 *  @param {Object} obj.data
 *  @returns {Promise}
 *  @example
 *    yield call(patchHotelReservationNotesEndpoint, {
 *      itineraryId,
 *      customerId,
 *      data: { "pets": [{ petKey, reservationNotes }]}
 *    });
 */
export function patchHotelReservationNotesEndpoint({ itineraryId, customerId, data }) {
  return associateWebBFFAPI.patch(
    `v1/hotel/customers/${customerId}/itineraries/${itineraryId}/reservationnotes`,
    data,
  );
}

/**
 * Creates an itinerary
 * @param {*} { itinerary payload. Structure can be found at https://confluence.ssg.petsmart.com/display/PS/6.+Itinerary+Instance+V2 }
 * @returns
 */
export function postHotelItinerary({ customerKey, data }) {
  return bookingAPI.post(`hotel/v1/customers/${customerKey}/itineraries/`, data);
}

/**
 * Gets an itinerary
 * @param {*} {  customer Key, itinerary ID }
 * @returns
 */
export function getHotelItinerary({ customerKey, itineraryId }) {
  return associateWebBFFAPI.get(`v1/customers/${customerKey}/itineraries/${itineraryId}`);
}

/**
 *  BFF patch endpoint to update room assignment in check in/out flow
 *
 *  @memberOf APIs.hotel.itinerary
 *  @function
 *  @name patchHotelItineraryRoomEndpoint
 *  @param { Object } obj
 *  @param { Number} obj.itineraryId
 *  @param { Number } obj.customerKey
 *  @param { Object } obj.data
 *  @returns { Promise }
 *  @example
 *
 *  const response = yield call(patchHotelItineraryRoomEndpoint, {
 *       itineraryId,
 *       customerKey,
 *       data: {
 *       "pets": [
 *         {
 *               "petKey": 2222,
 *               "engagements": [
 *                   {
 *                       "engagementId": 232692937,
 *                       "metadata": {
 *                           "room": {
 *                               "roomTypeBucketId": 83739,
 *                               "roomNumber": 2,
 *                               "isUnassigned": false
 *                           }
 *                       }
 *                   }
 *               ]
 *           }
 *        ]
 *      }
 *     });
 */
export function patchHotelItineraryRoomEndpoint({ itineraryId, customerKey, data }) {
  return cartAPI.patch(
    `v1/hotel/customers/${customerKey}/itineraries/${itineraryId}/roomnumber`,
    data,
  );
}
