import { GET_TRAINING_PET_SERVICES_SUCCESS } from "./trainingPetServicesActions";

const initialState = [];

const trainingPetServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRAINING_PET_SERVICES_SUCCESS:
      return [...state, ...action.petServices];
    default:
      return state;
  }
};

export default trainingPetServicesReducer;
