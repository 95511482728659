import React from "react";
import styled from "styled-components";
import VoidTransactionModalFooter from "./shared/VoidTransactionModalFooter";
import VoidTransactionModalHeader from "./shared/VoidTransactionModalHeader";

const ComponentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 600px;
`;

const ChildWrapper = styled.div`
  height: 380px;
  display: flex;
`;

/**
 * Main Content for the Void Transaction Modal.
 * @param {String} title -  Title of the modal
 * @param {JSX Component} children -  content of the modal, between the shared header and footer
 * @param {*} props -  Additional pass through props for the footer
 */
export default function VoidTransactionModalContent(props) {
  const { title, children } = props;

  return (
    <ComponentWrapper>
      <VoidTransactionModalHeader title={title} />
      <ChildWrapper>{children}</ChildWrapper>
      <VoidTransactionModalFooter {...props} />
    </ComponentWrapper>
  );
}
