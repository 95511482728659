//@ts-check
import { connect } from "react-redux";
import { isInclusivelyBeforeDay, isInclusivelyAfterDay } from "react-dates";
import moment, { Moment } from "moment";

// Components
import Calendar from "web/common/Calendar";

// Actions
import {
  setHotelManageRoomDetailsDatePickerStartDate,
  setHotelManageRoomDetailsDatePickerEndDate,
} from "./hotelManageRoomDetailsDatePickersActions";

// Selectors
import {
  selectHotelManagementDetailsStartDate,
  selectHotelManagementDetailsEndDate,
} from "./hotelManageRoomDetailsDatePickersSelectors";

// Utils
import { formatCalendarDateMoment } from "core/utils/dateUtils/formatDateTime";

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const HotelManageRoomDetailsStartDate = connect(
  state => {
    const selectedStartDate = selectHotelManagementDetailsStartDate(state);

    const minDate = moment().subtract(1, "days");
    const maxDate = moment().add(30, "days");

    const isOutsideRange = (/**@type {Moment} */ day) =>
      // @ts-ignore - Type 'Moment' is missing the following properties from type 'Moment': isoWeeksInISOWeekYear
      // Moment npm module has different version with the "day" moment object from react-dates.
      isInclusivelyBeforeDay(day, minDate) || isInclusivelyAfterDay(day, maxDate);

    return {
      componentId: "HotelManageRoomDetailsStartDate",
      isOutsideRange,
      selectedDate: moment(selectedStartDate),
    };
  },
  dispatch => {
    return {
      selectDate: (/** @type {moment.MomentInput | string | undefined }} */ selectedDate) => {
        const formattedStartDate = formatCalendarDateMoment(moment(selectedDate));
        dispatch(setHotelManageRoomDetailsDatePickerStartDate(formattedStartDate));
      },
    };
  },
)(Calendar);

export const HotelManageRoomDetailsEndDate = connect(
  state => {
    const selectedStartDate = selectHotelManagementDetailsStartDate(state);
    const selectedEndDate = selectHotelManagementDetailsEndDate(state);

    const minDate = moment(selectedStartDate).subtract(1, "days");
    const maxDate = moment(selectedStartDate).add(30, "days");

    const isOutsideRange = (/**@type {Moment} */ day) =>
      // @ts-ignore - Type 'Moment' is missing the following properties from type 'Moment': isoWeeksInISOWeekYear
      // Moment npm module has different version with the "day" moment object from react-dates.
      isInclusivelyBeforeDay(day, minDate) || isInclusivelyAfterDay(day, maxDate);

    return {
      componentId: "HotelManageRoomDetailsEndDate",
      isOutsideRange,
      selectedDate: selectedEndDate,
    };
  },
  dispatch => {
    return {
      selectDate: (/** @type {moment.MomentInput | string | undefined }} */ selectedDate) => {
        const formattedEndDate = formatCalendarDateMoment(moment(selectedDate));
        dispatch(setHotelManageRoomDetailsDatePickerEndDate(formattedEndDate));
      },
    };
  },
)(Calendar);
