import { connect } from "react-redux";
import FoodTimeWrapper from "./FoodTimeWrapper";
import { getFoodTimeOfDayTouched, getIsFoodTimeOfDayEmpty } from "./foodTimeOfDaySelectors";
import { getPetFoods } from "../food/foodsSelector";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../core/constants/validationErrors";

const mapStateToProps = (state, ownProps) => {
  const { foodId, foodIndex, petId } = ownProps;
  return {
    foodId,
    petId,
    title: `Food ${foodIndex} time of day`,
    foods: getPetFoods(state, { petId }),
    error:
      getFoodTimeOfDayTouched(state, { foodId, petId }) &&
      getIsFoodTimeOfDayEmpty(state, { foodId, petId }) &&
      REQUIRED_FIELD_ERROR_MESSAGE,
  };
};

export default connect(mapStateToProps)(FoodTimeWrapper);
