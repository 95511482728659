import React, { Children } from "react";
import * as Sentry from "@sentry/react";
import { Layout, Text } from "@prism/psm-ui-components";
import CommonButton from "../../web/common/commonButton";

function SentryErrorBoundary(props) {
  const { children } = props;
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <Layout.Stack space="stack-space-5">
          <Layout.Box padding="box-padding-4">
            <Text
              bold="bold"
              align="center"
              size="text-size-2xl"
              letterSpacing="ext-tracking-normal"
            >
              Ruh roh...Something went wrong!
            </Text>
          </Layout.Box>
          <Layout.Box padding="box-padding-8">
            <Layout.Stack space="stack-space-8">
              <Text bold="bold" size="text-size-md" align="left">
                {error.toString()}
              </Text>
              <Text size="text-size-sm" align="left">
                <code>{componentStack}</code>
              </Text>
            </Layout.Stack>
          </Layout.Box>
          <Layout.Center>
            <CommonButton
              label="Reload"
              onClick={() => {
                resetError();
                window?.location?.reload(); // the user expects the page to reload when the button is pressed
              }}
            />
          </Layout.Center>
        </Layout.Stack>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default SentryErrorBoundary;
