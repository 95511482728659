import React from "react";
import GenericTitle from "../genericLayoutComponents/GenericTitle";
import { Layout } from "@prism/psm-ui-components";

export default function CapacityHeader(props) {
  const { isHidden, componentID, title, diComp, instanceName } = props;

  if (!isHidden) {
    const Modalbutton = diComp[instanceName];

    return (
      <Layout.Cluster justify="space-between" style={{ marginBottom: '1rem' }} id={componentID}>
        <GenericTitle title={title} />

        <Modalbutton />
      </Layout.Cluster>
    );
  }

  return null;
}
