/** @module normalizeArray */

/**
 * Returns an object literal of normalized key value pairs based on passed property
 *
 * @export normalizeArrayByProperty
 * @param {Array} arr - Array of objects
 * @param {string} property - Key name for Id value that will be normalized.
 */
export default function normalizeArrayByProperty(arr, property) {
  const obj = {};

  if (!arr) return {};

  arr.forEach(elem => {
    obj[elem[property]] = { ...elem };
  });

  return obj;
}
