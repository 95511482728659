import React from "react";
import { connect } from "react-redux";
import CommonModal from "web/common/modals/commonModal";
import {
  getScheduledSuspensionCancelModalIsHidden,
  getScheduledSuspensionCancelModalStartDate,
  getScheduledSuspensionCancelModalSuspensionId,
} from "dux/scheduleSuspensionCancelModal/scheduleSuspensionCancelModalSelector";
import { toggleScheduleSuspensionCancelModal } from "dux/scheduleSuspensionCancelModal/scheduleSuspensionCancelModalActions";
import { Layout, Button, Text } from "@prism/psm-ui-components";
import { getScheduledSuspensionData } from "dux/scheduleSuspensionTable/scheduleSuspensionTableSelector";
import { find } from "lodash/fp";
import { patchHotelServiceSuspension } from "dux/scheduleSuspensionPage/scheduleSuspensionActions";
import {
  selectServiceSuspensionDetails,
  selectServiceSuspensionReasonId,
  selectSuspensionTypeIdFromSuspensionType,
} from "dux/servicesManagementToggle/servicesManagementToggleSelectors";
import moment from "moment";

export const ScheduleSuspensionCancelModal = props => {
  const { componentId, isHidden, message, onClose, cancelSuspension } = props;

  return (
    <CommonModal isHidden={isHidden} onClose={onClose}>
      <Layout.Box padding="box-padding-8" style={{ paddingTop: "0" }} id={componentId}>
        <Layout.Stack space="stack-space-12">
          <Layout.Box>
            <Text align="center" size="text-size-xl" bold>
              Cancel suspension
            </Text>
          </Layout.Box>

          <Layout.Box style={{ maxWidth: "375px" }}>
            <Text size="text-size-xl" align="center">
              {message}
            </Text>
          </Layout.Box>

          <Layout.Box>
            <Layout.Cluster justify="space-between">
              <Button onClick={onClose} variant="link">
                Go Back
              </Button>
              <Button onClick={cancelSuspension} variant="prism-primary">
                Cancel Suspension
              </Button>
            </Layout.Cluster>
          </Layout.Box>
        </Layout.Stack>
      </Layout.Box>
    </CommonModal>
  );
};

// CONTAINER NAME CONTAINER ----------------------------------------------------------------------------------------
export const HotelScheduleSuspensionCancelModal = connect(
  state => {
    const startDate = moment(getScheduledSuspensionCancelModalStartDate(state)).format(
      "YYYY-MM-DD",
    );
    const suspensionId = getScheduledSuspensionCancelModalSuspensionId(state);
    const suspensionData = getScheduledSuspensionData(state);
    const suspension = find(suspension => {
      return suspension.suspensionId === suspensionId;
    }, suspensionData);
    const suspensionTypeId = selectSuspensionTypeIdFromSuspensionType(suspension?.suspensionType)(
      state,
    );
    const details = selectServiceSuspensionDetails(state, suspensionTypeId);
    const reasonId = selectServiceSuspensionReasonId({
      suspensionType: suspension?.suspensionType,
      reasonName: suspension?.reasonName,
    })(state);

    return {
      componentId: "HotelScheduleSuspensionCancelModal",
      isHidden: getScheduledSuspensionCancelModalIsHidden(state),
      message: `Are you sure you want to cancel this ${suspension?.suspensionType} suspension on ${startDate}`,

      //for merge props
      startDate,
      suspensionId,
      details,
      reasonId,
      suspensionTypeId,
    };
  },

  dispatch => {
    return {
      handleOnClose: () => {
        // close modal - Setting the suspension Id to null on modal close so no lingering id is in state.
        dispatch(toggleScheduleSuspensionCancelModal({ suspensionId: null, startDate: "" }));
      },
      handleOnCancelSuspension: ({
        startDate,
        suspensionId,
        details,
        reasonId,
        suspensionTypeId,
      }) => {
        // dispatch to make api call
        dispatch(
          patchHotelServiceSuspension({
            startDate,
            endDate: startDate,
            details,
            reasonId,
            suspensionTypeId,
            suspensionId,
            isCanceled: true,
          }),
        );

        // close modal - Setting the suspension Id to null on modal close so no lingering id is in state.
        dispatch(toggleScheduleSuspensionCancelModal({ suspensionId: null, startDate: "" }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      isHidden,
      message,
      startDate,
      suspensionId,
      details,
      reasonId,
      suspensionTypeId,
    } = mapProps;
    const { handleOnCancelSuspension, handleOnClose } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,
      message,

      // actions to pass to view
      cancelSuspension: () =>
        handleOnCancelSuspension({ startDate, suspensionId, details, reasonId, suspensionTypeId }),
      onClose: () => handleOnClose(),
    };
  },
)(ScheduleSuspensionCancelModal);
