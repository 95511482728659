import React from "react";
import withClickOutside from "./withClickOutside";

const withSaveOnClickOutside = WrappedComponent => {
  return withClickOutside(
    class extends React.Component {
      constructor(props) {
        super(props);

        this.state = {
          isFocused: false
        };

        this.formikRef = React.createRef();
        this.handleFocus = this.handleFocus.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleAutoSave = this.handleAutoSave.bind(this);
      }

      handleFocus() {
        this.setState({ isFocused: true });
      }

      handleAutoSave(e) {
        this.formikRef.current.props.onSubmit({ [e.target.name]: e.target.value });
      }

      handleClickOutside() {
        const { isCreate } = this.props;

        if (this.state.isFocused && !isCreate) {
          this.formikRef.current.submitForm();
        }
        this.setState({ isFocused: false });
      }

      render() {
        return (
          <WrappedComponent
            handleAutoSave={this.handleAutoSave}
            handleFocus={this.handleFocus}
            formikRef={this.formikRef}
            {...this.props}
          />
        );
      }
    }
  );
};

export default withSaveOnClickOutside;
