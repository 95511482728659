import moment from "moment";
/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */

export const selectedDateActions = {
  RESET_SELECTED_DATE: "RESET_SELECTED_DATE",
  SELECT_DATE: "SELECT_DATE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setSelectDate = selectedDate => ({
  type: selectedDateActions.SELECT_DATE,
  selectedDate: moment(selectedDate).format("YYYY-MM-DD"),
});

export const resetSelectedDate = () => ({
  type: selectedDateActions.RESET_SELECTED_DATE,
});
