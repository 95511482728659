import React from "react";
import { connect } from "react-redux";
import BundleHistoryCardRedeemedCopyContainer from "dux/bgm/bundleHistory/BundleHistoryCardRedeemedCopyContainer";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import {
  getBundleHistoryItemName,
  getBundleHistoryItemPurchaseDate,
  getBundleHistoryItemSoldByName,
  getBundleHistoryItemPaymentOrigin,
  getBundleHistoryItemAddOnName,
  getBundleHistoryItemPetInfo,
  getBundleHistoryItemRedeemedUnits,
  getBaseBundleHistoryItemFormattedExpirationDate,
  getFreeBundleHistoryItemFormattedExpirationDate,
  getIsBaseBundleHistoryItemExpired,
  getIsFreeBundleHistoryItemExpired,
} from "./bundleHistorySelectors";
import { color } from "@/web/common/styles/theme";
import { TextPassage } from "@petsmart-ui/sparky";

/**
 * React view component for details in bundle history cards
 * @summary Other Purchases column of pet parent profile
 * @memberOf Views.Customer
 * @function
 * @name BundleHistoryCardDetailsComponent
 * @param {Object} props - props passed into the view component
 * @param {String} props.componentId
 * @param {String} props.name
 * @param {String} props.purchaseDate
 * @param {Boolean} props.isBaseBundleExpired
 * @param {Boolean} props.isFreeBundleExpired
 * @param {String} props.baseExpirationDate
 * @param {String} props.freeExpirationDate
 * @param {String} props.soldBy
 * @param {String} props.paymentOrigin
 * @param {String} props.addOnName
 * @param {String} props.petInfo
 * @param {String} props.bundleNumber
 * @returns {JSX.Element}
 */
export const BundleHistoryCardDetailsComponent = ({
  componentId,
  name,
  purchaseDate,
  isBaseBundleExpired,
  isFreeBundleExpired,
  baseExpirationDate,
  freeExpirationDate,
  soldBy,
  paymentOrigin,
  addOnName,
  petInfo,
  bundleNumber,
}) => {
  return (
    <LayoutCluster
      id={componentId}
      flexWrap="flex-noWrap"
      style={{ justifyContent: "space-between", alignItems: "flex-start" }}
    >
      <TextPassage>
        <LayoutStack>
          <b>{name}</b>
          {addOnName && <b>{addOnName}</b>}
          <span>{petInfo}</span>
          <b style={isBaseBundleExpired ? { color: color.errorRed } : {}}>{baseExpirationDate}</b>
          {freeExpirationDate && (
            <b style={isFreeBundleExpired ? { color: color.errorRed } : {}}>{freeExpirationDate}</b>
          )}
        </LayoutStack>
      </TextPassage>

      <TextPassage>
        <LayoutStack style={{ textAlign: "right" }}>
          <span>Purchased: {purchaseDate}</span>
          <span>
            Sold By: <span style={{ color: color.kalEl }}>{soldBy}</span>
          </span>
          <span>Paid: {paymentOrigin}</span>
          <BundleHistoryCardRedeemedCopyContainer bundleNumber={bundleNumber} />
        </LayoutStack>
      </TextPassage>
    </LayoutCluster>
  );
};

/**
 * Redux Connect function for the BundleHistoryCardDetailsComponent
 * @see {@link Views.Customer.BundleHistoryCardDetailsComponent}
 * @memberOf Views.Customer
 * @function
 * @name BundleHistoryCardDetails
 * @param {Object} props - props passed into the view component
 * @param {String} props.id
 * @returns {JSX.Element}
 * @example <BundleHistoryCardDetails />
 */
export const BundleHistoryCardDetails = connect((state, { id }) => {
  const customerKey = getCurrentCustomerKey(state);
  return {
    componentId: "BundleHistoryCardDetails",
    bundleNumber: id,
    count: getBundleHistoryItemRedeemedUnits(state, { customerKey, id }),
    name: getBundleHistoryItemName(state, { customerKey, id }),
    purchaseDate: getBundleHistoryItemPurchaseDate(state, { customerKey, id }),
    isBaseBundleExpired: getIsBaseBundleHistoryItemExpired(state, { customerKey, id }),
    isFreeBundleExpired: getIsFreeBundleHistoryItemExpired(state, { customerKey, id }),
    baseExpirationDate: getBaseBundleHistoryItemFormattedExpirationDate(state, { customerKey, id }),
    freeExpirationDate: getFreeBundleHistoryItemFormattedExpirationDate(state, { customerKey, id }),
    soldBy: getBundleHistoryItemSoldByName(state, { customerKey, id }),
    paymentOrigin: getBundleHistoryItemPaymentOrigin(state, { customerKey, id }),
    addOnName: getBundleHistoryItemAddOnName(state, { customerKey, id }),
    petInfo: getBundleHistoryItemPetInfo(state, { customerKey, id }),
  };
})(BundleHistoryCardDetailsComponent);
