import { ddcRoomCapacityActionTypes } from "./ddcRoomCapacityActions";

const initialState = {
  capacity: {},
};

const ddcRoomCapacityReducer = (state = initialState, action) => {
  switch (action.type) {
    case ddcRoomCapacityActionTypes.LOAD_DDC_ROOM_CAPACITY_SUCCESS:
      return {
        ...state,
        capacity: action.ddcCapacity,
      };
    default:
      return state;
  }
};

export default ddcRoomCapacityReducer;
