import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { getAssociate } from "../../../../core/selectors/entitiesSelector";
import { getAppointmentByPet } from "../../../../core/selectors/cartSelectors";
import { getHoveredTimeSlot } from "../../../../core/selectors/bookingUISelectors";
import { compareTimeSlots } from "../../../../core/utils/bookingUtils";
import { createLoadingSelector } from "../../../../core/selectors/utils";
import itinerariesActionTypes from "../../../../core/actionTypes/itinerariesActionTypes";
import engagementsActionTypes from "../../../../core/actionTypes/engagementsActionTypes";
import { getCurrentCustomerKey } from "../../../../core/selectors/persistent/customer/customerSelectors";
import {
  bookAppointment,
  selectHoveredTimeSlot,
} from "../../../../core/actionCreators/bookingActionCreators";
import AvailableAppointmentComponent from "./AvailableAppointmentComponent";

const mapStateToProps = (state, ownProps) => {
  const appointment = getAppointmentByPet(state, ownProps);
  const hoveredTimeSlot = getHoveredTimeSlot(state);
  const isHovered = hoveredTimeSlot && compareTimeSlots(hoveredTimeSlot, ownProps.timeSlot);

  return {
    selectButtonID: ownProps.selectButtonID,
    customerKey: getCurrentCustomerKey(state),
    associate: getAssociate(state, { associateId: ownProps.timeSlot.associateId }),
    selectedPetService: appointment && appointment.petService,
    isLoading: createLoadingSelector([
      itinerariesActionTypes.CREATE_ITINERARY,
      engagementsActionTypes.CREATE_ENGAGEMENT,
    ])(state),
    isHovered,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    router: {
      params: { customerKey },
    },
  } = ownProps;
  return {
    onSelectTimeSlot: ({ selectedPetService }) => {
      dispatch(
        bookAppointment({
          timeSlot: ownProps.timeSlot,
          customerKey,
          selectedPetService,
        }),
      );
    },
    onHoverTimeSlot: () => {
      dispatch(selectHoveredTimeSlot({ timeSlot: ownProps.timeSlot, petId: ownProps.petId }));
    },
    onClearHoverTimeSlot: () => {
      dispatch(selectHoveredTimeSlot({ timeSlot: "", petId: ownProps.petId }));
    },
  };
};

export default withRouteProps(
  connect(mapStateToProps, mapDispatchToProps)(AvailableAppointmentComponent),
);
