import { put, takeEvery, call, all } from "redux-saga/effects";
import { searchFieldTypeConstants } from "core/constants/searchFieldTypeConstants";
import {
  getGeoCodingByAddress,
  getGeoCodingByCityAndState,
  getGeoCodingByPostalCode,
} from "core/services/googleGeoCoding/googleGeoCoding.api";
import { fetchNearbyStores } from "core/services/petsmartWebPublic/searchNearbyStoresEndPoints";
import { loadSalonInfoWithServicesSuccess } from "core/actionCreators/salonActionCreator";
import {
  getSearchFieldMainStoresByLocationRequest,
  getSearchFieldMainStoresByLocationFailure,
  GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION,
} from "./searchFieldMainActions";
import { reduceStoreInfoHelper } from "dux/listOfNearByStores/reduceStoreInfoHelper";

function getGeocodeFnBySearchType({ searchFieldType }) {
  if (searchFieldType === searchFieldTypeConstants.ADDRESS) {
    return getGeoCodingByAddress;
  }

  if (searchFieldType === searchFieldTypeConstants.ZIP_Code) {
    return getGeoCodingByPostalCode;
  }

  if (searchFieldType === searchFieldTypeConstants.CITY) {
    return getGeoCodingByCityAndState;
  }

  return getGeoCodingByAddress;
}

/*
  call correct google geoCoding API based on search type, e.g. postal code, address city/state etc.
  and will return the location in the form of Lat and long coordinates.
 */
function* getLocation({ searchFieldType, searchFieldValue }) {
  const geocodeFn = yield call(getGeocodeFnBySearchType, { searchFieldType });

  const response = yield call(geocodeFn, searchFieldValue);
  const location = response?.results?.[0]?.geometry?.location;

  return {
    lat: location?.lat() ?? 0,
    lng: location?.lng() ?? 0,
  };
}

/*
  Saga, will call google geoCoding APIs for Location info based on the type of search, e.g. postal code, address, city/state etc.
  will take the lat and long data and feed it to the PETM stores api to get the closest stores and plase that in the
  state.search.salonInfoWithServices redux state.
 */
function* onGetSearchFieldMainStoresByLocation({ searchFieldType, searchFieldValue }) {
  try {
    yield put(getSearchFieldMainStoresByLocationRequest());

    const location = yield getLocation({ searchFieldType, searchFieldValue });

    const nearByStoresData = yield call(fetchNearbyStores, {
      latitude: location.lat,
      longitude: location.lng,
    });

    const storeSearchResults = nearByStoresData.data.StoreSearchResults.map(item => {
      // return item.Store;
      return item;
    });

    // We don't want to dump all the store info into state, so we parse the data and pull out only what is needed and format where necessary.
    const nearByStores = reduceStoreInfoHelper(storeSearchResults);

    // send stores to state.search.salonInfoWithServices
    yield put(loadSalonInfoWithServicesSuccess({ salon: nearByStores }));
  } catch (error) {
    yield put(getSearchFieldMainStoresByLocationFailure(error));
  }
}

function* watchOnGetSearchFieldMain() {
  yield takeEvery(GET_SEARCH_FIELD_MAIN_STORES_BY_LOCATION, onGetSearchFieldMainStoresByLocation);
}

export default function* searchFieldMainSaga() {
  yield all([watchOnGetSearchFieldMain()]);
}
