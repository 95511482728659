import petVaccinationsActionTypes from "../actionTypes/petVaccinationsActionTypes";

export const createPetVaccination = ({
  customerKey,
  petId,
  vaccinationId,
  expirationDate,
  onComplete,
}) => ({
  type: petVaccinationsActionTypes.CREATE_PET_VACCINATION,
  customerKey,
  petId,
  vaccinationId,
  expirationDate,
  onComplete,
});

export const createPetVaccinationRequest = () => ({
  type: petVaccinationsActionTypes.CREATE_PET_VACCINATION_REQUEST,
});

export const createPetVaccinationSuccess = () => ({
  type: petVaccinationsActionTypes.CREATE_PET_VACCINATION_SUCCESS,
});

export const createPetVaccinationFailure = ({ error }) => ({
  type: petVaccinationsActionTypes.CREATE_PET_VACCINATION_FAILURE,
  error,
});

export const deletePetVaccination = ({ customerKey, petId, vaccinationId }) => ({
  type: petVaccinationsActionTypes.DELETE_PET_VACCINATION,
  customerKey,
  petId,
  vaccinationId,
});

export const deletePetVaccinationRequest = () => ({
  type: petVaccinationsActionTypes.DELETE_PET_VACCINATION_REQUEST,
});

export const deletePetVaccinationSuccess = () => ({
  type: petVaccinationsActionTypes.DELETE_PET_VACCINATION_SUCCESS,
});

export const deletePetVaccinationFailure = ({ error }) => ({
  type: petVaccinationsActionTypes.DELETE_PET_VACCINATION_FAILURE,
  error,
});

export const updatePetVaccinations = ({ customerKey, petId, formValues, onComplete }) => ({
  type: petVaccinationsActionTypes.UPDATE_PET_VACCINATIONS,
  customerKey,
  petId,
  formValues,
  onComplete,
});

export const updatePetVaccinationsRequest = () => ({
  type: petVaccinationsActionTypes.UPDATE_PET_VACCINATIONS_REQUEST,
});

export const updatePetVaccinationsSuccess = () => ({
  type: petVaccinationsActionTypes.UPDATE_PET_VACCINATIONS_SUCCESS,
});

export const updatePetVaccinationsFailure = ({ error }) => ({
  type: petVaccinationsActionTypes.UPDATE_PET_VACCINATIONS_FAILURE,
  error,
});

export const setVaccinationToDelete = (vaccinationId) => ({
  type: petVaccinationsActionTypes.SET_VACCINATION_TO_DELETE,
  vaccinationToDelete: vaccinationId,
});

export const setCustomerAndPetKeys = ({ customerKey, petId }) => ({
  type: petVaccinationsActionTypes.SET_CUSTOMER_AND_PET_KEYS,
  customerKey,
  petId,
});

export const clearCustomerAndPetKeys = () => ({
  type: petVaccinationsActionTypes.CLEAR_CUSTOMER_AND_PET_KEYS,
});

export const clearVaccinationToDelete = () => ({
  type: petVaccinationsActionTypes.CLEAR_VACCINATION_TO_DELETE,
});

export const showVaccinationDropdown = () => ({
  type: petVaccinationsActionTypes.SHOW_DROPDOWN,
});

export const hideVaccinationDropdown = () => ({
  type: petVaccinationsActionTypes.HIDE_DROPDOWN,
});

export const toggleAddVaccinationForm = () => ({
  type: petVaccinationsActionTypes.TOGGLE_ADD_VACCINATION_FORM,
});

export default {
  createPetVaccination,
  createPetVaccinationRequest,
  createPetVaccinationSuccess,
  createPetVaccinationFailure,
  deletePetVaccination,
  deletePetVaccinationRequest,
  deletePetVaccinationSuccess,
  deletePetVaccinationFailure,
  updatePetVaccinations,
  updatePetVaccinationsRequest,
  updatePetVaccinationsSuccess,
  updatePetVaccinationsFailure,
  setVaccinationToDelete,
  setCustomerAndPetKeys,
  clearCustomerAndPetKeys,
  clearVaccinationToDelete,
  showVaccinationDropdown,
  hideVaccinationDropdown,
  toggleAddVaccinationForm,
};
