import React, { useEffect, useState } from "react";
import { Text, Form } from "@prism/psm-ui-components";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { connect } from "react-redux";
import { difference, filter, has, union } from "lodash/fp";
import CommonButton from "web/common/commonButton";
import {
  selectSelectedOptionsFromHotelEngagements,
  selectApplicableHotelManualSpecialsFromCart,
  selectApplicableHotelManualSpecialsFromItinerary,
} from "dux/applySpecial/applyManaulSpecialsHotelSelectors";
import {
  LOAD_HOTEL_MANUAL_SPECIALS,
  loadHotelSpecialsManual,
  patchHotelManualSpecials,
} from "dux/applySpecial/applyManaulSpecialsHotelActions";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import {
  selectHotelItineraryIdFromCart,
  selectProductSpecialsByPetId,
} from "dux/servicesCartHotel/servicesCartHotelSelectors";
import { patchCartSpecials } from "dux/servicesCart/servicesCartActions";
import { showConfirmationModal } from "core/actionCreators/confirmationModalActionCreators";
import { getHotelItinerary } from "../hotelItinerary/hotelItinerarySelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import LoadingWrapper from "@/web/common/LoadingWrapper";

/**
 * The selectedSpecials (local) state represents the specials that are checked in the UI and are an array of
 * code(s) for each special, e.g. [ "GROOM10", "DDC5OFF" ]
 *
 * The currentlyAppliedSpecials are the codes that come from the hotel Itinerary response and represent specials
 * that are already applied, thus we update the local selectedSpecials when component 1st loads since
 * selectedSpecials represent what is checked in the UI
 *
 * The options represents the list of manual specials that will populate this list of checkboxes and
 * originate from the specials/manual api response (these are all the special options that are shown in the list.)
 */
export const ApplySpecialsList = props => {
  const {
    componentId,
    isHidden,
    currentlyAppliedSpecials,
    closeFlyout,
    options,
    idProperty,
    noSpecials,
    isLoading,
    getSpecialsList = () => {},
  } = props;

  // set use state hook with initial state from props
  const [selectedSpecials, setSelectedSpecials] = useState(currentlyAppliedSpecials);

  // Get list of specials when component renders
  useEffect(() => {
    getSpecialsList();
  }, []);

  // will only run (changing the value in state) only if the value from props changes.
  useEffect(() => {
    setSelectedSpecials(currentlyAppliedSpecials);
  }, [currentlyAppliedSpecials]);

  const handleCheckboxClick = option => {
    if (selectedSpecials?.includes(option)) {
      setSelectedSpecials(filter(selection => selection !== option, selectedSpecials));
    } else {
      setSelectedSpecials([...selectedSpecials, option]);
    }
  };

  if (isHidden) {
    return null;
  }

  return (
    <LoadingWrapper isLoading={isLoading}>
      <LayoutBox id={componentId} style={{ width: "300px" }}>
        <LayoutStack>
          <LayoutBox>
            <LayoutStack id={`${componentId}__list`} space="scale-0">
              {options.length > 0 ? (
                options.map(option => {
                  const checked = selectedSpecials?.includes(option[idProperty]);

                  return (
                    <LayoutBox
                      key={option[idProperty]}
                      id={`${componentId}__selectionAndLabel_${option[idProperty]}`}
                    >
                      <LayoutCluster
                        flexWrap="flex-noWrap"
                        style={{ alignItems: "center", justifyContent: "start" }}
                      >
                        <Form.Field.Checkbox
                          checked={checked}
                          onClick={() => handleCheckboxClick(option[idProperty])}
                        />
                        <Text>{option.name}</Text>
                      </LayoutCluster>
                    </LayoutBox>
                  );
                })
              ) : (
                <LayoutBox>
                  <Text size="text-size-base">{noSpecials}</Text>
                </LayoutBox>
              )}
            </LayoutStack>
          </LayoutBox>

          {/* Done button */}
          <LayoutBox>
            <LayoutCluster style={{ justifyContent: "right" }}>
              <CommonButton label="Done" onClick={() => closeFlyout(selectedSpecials)} />
            </LayoutCluster>
          </LayoutBox>
        </LayoutStack>
      </LayoutBox>
    </LoadingWrapper>
  );
};

/**
 * Helper to determine when to call endpoint to update specials & which specials to send
 * @function
 * @name updateSpecials
 * @param {Object} args
 * @param {string[]} args.selectedSpecials
 * @param {string[]} args.currentlyAppliedSpecials
 * @param {Function} args.showConfirmation - fn to dispatch the showConfirmationModal action
 * @param {(specials: string[]) => void} args.updateSpecialsAPICall - fn to dispatch an api call to update specials
 * @example updateSpecials({ selectedSpecials, currentlyAppliedSpecials, showConfirmation, updateSpecialsAPICall })
 */
export const updateSpecials = ({
  selectedSpecials,
  currentlyAppliedSpecials,
  showConfirmation,
  updateSpecialsAPICall,
}) => {
  const specialsToAdd = difference(selectedSpecials, currentlyAppliedSpecials);
  const specialsToRemove = difference(currentlyAppliedSpecials, selectedSpecials);

  // Show confirmation if any specials are being removed
  if (specialsToRemove.length) {
    showConfirmation({
      header: "Are you sure?",
      content: `Are you sure you want to remove specials?`,
      confirmText: "Yes, Remove Special",
      cancel: () => {
        // Only add newly checked specials, don't remove any unchecked specials
        if (specialsToAdd.length) {
          const specials = union(selectedSpecials, specialsToRemove);
          updateSpecialsAPICall(specials);
        }
      },
      confirm: () => updateSpecialsAPICall(selectedSpecials),
      closeBeforeConfirm: true,
    });

    return;
  }

  // if no specials are being removed, but some specials need to be added
  if (specialsToAdd.length) updateSpecialsAPICall(selectedSpecials);
};

// HOTEL CHECK IN CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInApplySpecials = connect(
  state => {
    const customerId = getCurrentCustomerKey(state);
    const itineraryId = getHotelItinerary(state)?.itineraryId;
    const petId = getCurrentPet(state);
    const specials = selectApplicableHotelManualSpecialsFromItinerary(state, { petId }); // manual specials response
    const currentlyAppliedSpecials = selectSelectedOptionsFromHotelEngagements(state, { petId }); // itinerary response

    return {
      componentId: "HotelCheckInApplySpecials",
      options: specials,
      idProperty: "code",
      currentlyAppliedSpecials,
      isLoading: createLoadingSelector([LOAD_HOTEL_MANUAL_SPECIALS])(state),

      // for mapProps
      customerId,
      itineraryId,
      noSpecials: "No specials are available to apply.",
      buildManualSpecialsPatchReq: specials => ({
        setManualSpecials: specials?.map(code => ({ code })),
      }),
    };
  },

  (dispatch, { onClose }) => {
    return {
      getSpecialsList: itineraryId => {
        dispatch(loadHotelSpecialsManual({ itineraryId }));
      },
      setSpecials: ({
        selectedSpecials,
        customerId,
        itineraryId,
        currentlyAppliedSpecials,
        buildManualSpecialsPatchReq,
      }) => {
        const patchSpecials = specials =>
          dispatch(
            patchHotelManualSpecials({
              customerId,
              itineraryId,
              data: buildManualSpecialsPatchReq(specials),
            }),
          );

        updateSpecials({
          selectedSpecials,
          currentlyAppliedSpecials,
          showConfirmation: confirmationProps => dispatch(showConfirmationModal(confirmationProps)),
          updateSpecialsAPICall: patchSpecials,
        });

        onClose();
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      options,
      idProperty,
      currentlyAppliedSpecials,
      customerId,
      itineraryId,
      isHidden,
      noSpecials,
      isLoading,
      buildManualSpecialsPatchReq,
    } = mapProps;
    const { setSpecials, getSpecialsList } = dispatchProps;

    return {
      // control what props get passed to the view
      componentId,
      options,
      idProperty,
      currentlyAppliedSpecials,
      isHidden,
      noSpecials,
      isLoading,

      // actions to pass to view
      getSpecialsList: () => getSpecialsList(itineraryId),
      closeFlyout: selectedSpecials =>
        setSpecials({
          selectedSpecials,
          customerId,
          itineraryId,
          currentlyAppliedSpecials,
          buildManualSpecialsPatchReq,
        }),
    };
  },
)(ApplySpecialsList);

// HOTEL BOOKING CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingApplySpecialsList = connect(
  (state, { petId, onClose }) => {
    const customerId = getCurrentCustomerKey(state);
    const itineraryId = selectHotelItineraryIdFromCart(state);
    const currentlyAppliedSpecials = selectProductSpecialsByPetId({ petId })(state);

    return {
      componentId: "HotelBookingApplySpecials",
      options: selectApplicableHotelManualSpecialsFromCart(petId)(state), // manual specials response,
      idProperty: "code",
      currentlyAppliedSpecials,
      isLoading: createLoadingSelector([LOAD_HOTEL_MANUAL_SPECIALS])(state),
      // for mapProps
      customerId,
      itineraryId,
      noSpecials: "No specials are available to apply.",
      isHidden: false,
      onClose,
    };
  },

  (dispatch, { onClose }) => {
    const patchSpecials = specials => {
      const setManualSpecials = specials?.map(code => ({ code }));
      dispatch(patchCartSpecials({ setManualSpecials }));
    };

    return {
      getSpecialsList: itineraryId => {
        dispatch(loadHotelSpecialsManual({ itineraryId }));
      },
      setSpecials: ({ selectedSpecials, currentlyAppliedSpecials }) => {
        updateSpecials({
          selectedSpecials,
          currentlyAppliedSpecials,
          showConfirmation: confirmationProps => dispatch(showConfirmationModal(confirmationProps)),
          updateSpecialsAPICall: patchSpecials,
        });

        onClose();
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      options,
      idProperty,
      isHidden,
      noSpecials,
      currentlyAppliedSpecials,
      itineraryId,
      isLoading,
    } = mapProps;
    const { setSpecials, getSpecialsList } = dispatchProps;

    return {
      // control what props get passed to the view
      componentId,
      options,
      idProperty,
      isHidden,
      noSpecials,
      currentlyAppliedSpecials,
      isLoading,

      // actions to pass to view
      getSpecialsList: () => getSpecialsList(itineraryId),
      closeFlyout: selectedSpecials => {
        setSpecials({ selectedSpecials, currentlyAppliedSpecials });
      },
    };
  },
)(ApplySpecialsList);
