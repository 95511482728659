import verifyNumber from "../validationUtils/numberValidation";
import verifyName from "../validationUtils/nameValidation";

const validateAssociateSearchField = ({ searchFieldType, searchFieldValue }) => {
  if (searchFieldType === "associateID" && !searchFieldValue) {
    return {
      validationError: true,
      message: "Associate ID must be provided."
    };
  }
  if (searchFieldType === "associateID" && !verifyNumber(searchFieldValue)) {
    return {
      validationError: true,
      message: "Associate ID is not valid."
    };
  }
  if (searchFieldType === "name" && !searchFieldValue) {
    return {
      validationError: true,
      message: "Name must be at least one character."
    };
  }
  if (searchFieldType === "name" && !verifyName(searchFieldValue)) {
    return {
      validationError: true,
      message: "Name is not valid."
    };
  }
  return { validationError: false };
};

export default validateAssociateSearchField;
