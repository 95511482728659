import { connect } from "react-redux";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getFirstTimeCustomerInfo } from "dux/bgm/ftco/ftcoSelectors";
import { isBGMEligibilityHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import { getEligiblePromotionReasonBGM } from "dux/eligiblePromotions/eligiblePromotionsSelectors";
import { ELIGIBLE_PROMOTIONS_BGM_LABEL } from "dux/eligiblePromotions/eligiblePromotionConstants";
import PreCheckedInIcon from "assets/icons/pre-checked-in-appointment.svg";
import EligiblePromotionsBGMTextComponent from "dux/_components/eligiblePromotions/EligiblePromotionsBGMTextComponent";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  const { firstName } = getFirstTimeCustomerInfo(state, { customerKey });
  const eligiblePromotionReasonBGM = getEligiblePromotionReasonBGM(state);
  return {
    firstName,
    componentId: "eligiblePromotionsBGMTextContainer",
    isHidden: isBGMEligibilityHidden(state),
    label: `${ELIGIBLE_PROMOTIONS_BGM_LABEL}`,
    src: PreCheckedInIcon,
    reason: eligiblePromotionReasonBGM,
  };
};

export default connect(mapStateToProps)(EligiblePromotionsBGMTextComponent);
