import { getAssociate } from "@/core/selectors/entitiesSelector";
import { associateGroupConstants } from "@/core/constants/associatesConstants";

export const parseAssociateCopy = ({ state, associateId, isLoading, text }) => {
  const associate = getAssociate(state, { associateId });
  const associateName = associate?.associateName;

  return isLoading ? "Loading..." : `${associateName}'s ${text}`;
};

export const getEmployeeGroupType = (associate) => {
  const { serviceTypeGroup } = associate;
  return serviceTypeGroup || associateGroupConstants.OTHER;
};
