// @ts-nocheck
import * as React from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import { pluck, psx } from '../../../utils';
import { SWITCHER_TOKENS } from './_tokens';
import { LayoutProps } from '../base';

import './styles.css';

export interface SwitcherProps extends LayoutProps<React.ElementType> {
  /** A CSS margin value. The minimum space between the elements. */
  space?: keyof typeof SWITCHER_TOKENS['SPACE'];
  /** A CSS value usd in the calculation of the threshold for an
   * auto break based on container width */
  threshold?: keyof typeof SWITCHER_TOKENS['THRESHOLD'];
  /** A value to determine what child element when in a horizontal
   * configuration, be allotted more (flex-grow: 2) of the available
   * space `[NOTE: to trigger this control, must adjust the growValue]` */
  growNthChild?: number;
  /** Determines the value the Nth Child will grow. - works in conjunction
   * with growNthChild `[NOTE: to trigger this control, must adjust the growNthChild]`*/
  growValue?: keyof typeof SWITCHER_TOKENS['GROW_VALUE'];
  /** Determine if the number of children is greater than this value,
   * all children will forgo the row layout and will always be a column
   * layout (think of it as a dynamic stack) - works in conjunction with
   * `grow` */
  forceStackAfter?: number;
  /** Completely removes the component from the DOM. Useful for feature flags or t hide or show
   * a component. */
  isHidden?: boolean;
}

/**
 * `<Switcher/>` handles a situations in which you might want to switch directly between a
 * horizontal and vertical layout determined by the Threshold. But it is especially useful
 * where each element should be considered equal, or part of a continuum.
 *
 * The Switcher will switch between a single row layout to a single column according to the
 * available space within it's parent container.
 *
 * The aim for this layout type to make them as self-governing as possible based on the threshold.
 *
 * [Source](https://every-layout.dev/layouts/switcher/)
 */
export const Switcher: React.FunctionComponent<SwitcherProps> = ({
  space = 'switcher-space-0',
  threshold = 'switcher-threshold-3',
  growValue = 'switcher-grow-value-7',
  growNthChild,
  forceStackAfter,
  style = {},
  className,
  children,
  testid,
  as,
  isHidden = false,
  ...props
}) => {
  const id = 'psm-layout__switcher';
  const Component = as || 'div';
  const classNames = psx('psm', id, className);
  const flattenedChildren = flattenChildren(children);

  if (isHidden) {
    return null;
  }

  return (
    <Component
      className={classNames}
      data-testid={[id, testid].join('-')}
      style={{
        '--setSwitcherSpace': pluck(SWITCHER_TOKENS.SPACE, space),
        '--setSwitcherThreshold': pluck(SWITCHER_TOKENS.THRESHOLD, threshold),
        ...style,
      }}
      {...props}
    >
      <Component>
        {
          /* When in a horizontal configuration, we want to allot more of the available space to
           * one of the children. Since we can not add variables to the :nth-child() function in a
           * css doc, we sudo emulating that functionality here.
           *
           * Notably, there are current issues with the component when the children are wrapped in
           * a fragment. To resolve this, we rely on a library so that it doesn't matter whether
           * a fragment is present or not:
           * https://tommckenzie.dev/posts/react-keyed-flatten-children.html
           */
          flattenedChildren.map((child, idx) => {
            let updatedStyles = {};

            if (growNthChild && idx === growNthChild - 1) {
              updatedStyles = { flexGrow: pluck(SWITCHER_TOKENS.GROW_VALUE, growValue) };
            }

            if (forceStackAfter && flattenedChildren.length >= forceStackAfter) {
              updatedStyles = { flexBasis: '100%' };
            }

            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                key: `stack-child-${idx}`,
                style: {
                  ...child.props.style,
                  ...updatedStyles,
                },
              });
            } else {
              return null;
            }
          })
        }
      </Component>
    </Component>
  );
};
