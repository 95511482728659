/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const addMedicationBookingActionTypes = {
  ADD_NEW_BOOKING_MEDICATION: "ADD_NEW_BOOKING_MEDICATION",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const addNewBookingMedication = ({ medicationId, petId }) => ({
  type: addMedicationBookingActionTypes.ADD_NEW_BOOKING_MEDICATION,
  medicationId,
  petId,
});
