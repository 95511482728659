import { connect } from "react-redux";
import {
  getIsPetActive,
  getPetFullName,
  getPetPhoto,
  getCustomerLastName
} from "../../../../core/selectors/entitiesSelector";
import petsActionCreators from "../../../../core/actionCreators/petsActionCreators";
import FormHeaderComponent from "../../FormHeaderComponent";

const mapStateToProps = (state, ownProps) => {
  const customerLastName = getCustomerLastName(state, ownProps);

  return {
    fullName: ownProps.petName ? `${ownProps.petName} ${customerLastName}` : "",
    isActive: getIsPetActive(state, ownProps),
    photo: getPetPhoto(state, ownProps)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSetIsActive: isActive => {
    if (!isActive) {
      dispatch(
        petsActionCreators.setPetInactive({
          petId: ownProps.petId,
          customerKey: ownProps.customerKey
        })
      );
    } else {
      dispatch(
        petsActionCreators.setPetActive({
          petId: ownProps.petId,
          customerKey: ownProps.customerKey
        })
      );
    }
  },

  onSetName: petName => {
    dispatch(
      petsActionCreators.updatePet({
        customerKey: ownProps.customerKey,
        petId: ownProps.petId,
        data: {
          petName
        }
      })
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormHeaderComponent);
