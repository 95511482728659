import { Moment } from 'moment';
import { getDayNumbersToDisplayForPreviousMonth } from './getDayNumbersToDisplayForPreviousMonth';
import { getDayNumbersToDisplayForCurrentMonth } from './getDayNumbersToDisplayForCurrentMonth';
import { getDayNumbersToDisplayForNextMonth } from './getDayNumbersToDisplayForNextMonth';
import { addPrecedingZero } from './format';

export function getDayNumbersToDisplayInCalendarGrid(selectedDate: Moment) {
  const dayNumbersToDisplayForPreviousMonth = getDayNumbersToDisplayForPreviousMonth(selectedDate);
  const dayNumbersToDisplayForCurrentMonth = getDayNumbersToDisplayForCurrentMonth(selectedDate);
  const dayNumbersToDisplayForNextMonth = getDayNumbersToDisplayForNextMonth({
    dayNumbersToDisplayForPreviousMonth,
    dayNumbersToDisplayForCurrentMonth,
    selectedDate,
  });

  const mergedDayNumbers = [
    ...dayNumbersToDisplayForPreviousMonth,
    ...dayNumbersToDisplayForCurrentMonth,
    ...dayNumbersToDisplayForNextMonth,
  ];

  return mergedDayNumbers.map((day) => ({ ...day, id: day.date, dayNumber: addPrecedingZero(day.dayNumber) }));
}
