import { createSelector } from "reselect";
import { getState } from "core/selectors/commonSelector";
import { getCustomer } from "core/selectors/entitiesSelector";
import { isEmpty } from "lodash/fp";
import getPrismPetState from "dux/prismPet/prismPetSelector";
import {
  eligibilitySubjectTypes,
  messagePrefixes,
  reasonCodes,
} from "dux/eligibility/constants/eligibilityConstants";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

export const selectHotelEligibility = createSelector([getState], state => {
  return state?.hotelEligibility;
});

export const getHotelCheckInPetAlertReasons = createSelector(
  [selectHotelEligibility, getPrismPetState],
  (eligibility, petProfile) => {
    const hotelProfile = petProfile?.profiles?.find(
      ({ profileType }) => profileType === systemName.DDC_HOTEL,
    );

    const petEligibility = Object.values(eligibility)?.find(record => {
      return (
        record?.type === eligibilitySubjectTypes.PET &&
        record?.recordKey === petProfile?.petKey?.toString()
      );
    });
    let petReasons = petEligibility?.reasons || [];

    if (hotelProfile?.checkInAlert) {
      petReasons = [
        ...petReasons,
        {
          reasonCode: reasonCodes.CheckInAlert,
          description: hotelProfile?.checkInAlert,
        },
      ];
    }
    return petReasons;
  },
);

export const getHotelBookingPetAlertReasons = createSelector(
  [selectHotelEligibility, getPrismPetState],
  (eligibility, petProfile) => {
    const hotelProfile = petProfile?.profiles?.find(
      ({ profileType }) => profileType === systemName.DDC_HOTEL,
    );

    const petEligibility = Object.values(eligibility)?.find(record => {
      return (
        record?.type === eligibilitySubjectTypes.PET &&
        record?.recordKey === petProfile?.petKey?.toString()
      );
    });
    let petReasons = petEligibility?.reasons || [];

    if (hotelProfile?.bookingAlert) {
      petReasons = [
        ...petReasons,
        {
          reasonCode: reasonCodes.BookingAlert,
          description: hotelProfile?.bookingAlert,
        },
      ];
    }
    return petReasons;
  },
);

export const getHotelBookingPetParentAlertReasons = createSelector(
  [selectHotelEligibility, getCustomer],
  (eligibility, customer) => {
    const hotelProfile = customer?.prismCustomer?.profiles?.find(
      ({ profileType }) => profileType === systemName.DDC_HOTEL,
    );

    const customerEligibility = Object.values(eligibility)?.find(record => {
      return record?.type === eligibilitySubjectTypes.CUSTOMER;
    });
    let customerReasons = customerEligibility?.reasons || [];

    if (hotelProfile?.bookingAlert) {
      customerReasons = [
        ...customerReasons,
        {
          reasonCode: reasonCodes.BookingAlert,
          description: hotelProfile?.bookingAlert,
        },
      ];
    }

    if (isEmpty(customer?.addresses)) {
      customerReasons = [
        ...customerReasons,
        {
          reasonCode: reasonCodes.MissingAddress,
          description: messagePrefixes[reasonCodes.MissingAddress],
        },
      ];
    }

    return customerReasons;
  },
);

export const getHotelCheckInPetParentAlertReasons = createSelector(
  [selectHotelEligibility, getCustomer],
  (eligibility, customer) => {
    const hotelProfile = customer?.prismCustomer?.profiles?.find(
      ({ profileType }) => profileType === systemName.DDC_HOTEL,
    );

    const customerEligibility = Object.values(eligibility)?.find(record => {
      return record?.type === eligibilitySubjectTypes.CUSTOMER;
    });
    let customerReasons = customerEligibility?.reasons || [];

    if (hotelProfile?.checkInAlert) {
      customerReasons = [
        ...customerReasons,
        {
          reasonCode: reasonCodes.CheckInAlert,
          description: hotelProfile?.checkInAlert,
        },
      ];
    }

    if (isEmpty(customer?.addresses)) {
      customerReasons = [
        ...customerReasons,
        {
          reasonCode: reasonCodes.MissingAddress,
          description: messagePrefixes[reasonCodes.MissingAddress],
        },
      ];
    }

    return customerReasons;
  },
);

export const getShouldShowHotelCheckInEligibilityForModal = createSelector(
  [getHotelCheckInPetAlertReasons, getHotelCheckInPetParentAlertReasons],
  (petReasons, petParentReasons) => !isEmpty(petReasons) || !isEmpty(petParentReasons),
);

export const getShouldShowHotelBookingEligibility = createSelector(
  [getHotelBookingPetAlertReasons, getHotelBookingPetParentAlertReasons],
  (petReasons, petParentReasons) => !isEmpty(petReasons) || !isEmpty(petParentReasons),
);

export const getShouldShowHotelBookingAlertsForMultiPets = (state, customerKey, petIds) =>
  petIds.some(petId => !!getShouldShowHotelBookingEligibility(state, { customerKey, petId }));

/**
 * Checks to verify if the UI should show the hotel check-in eligibility modal
 *
 * @memberOf Selectors.HotelEligibility
 * @function
 * @name getShouldShowHotelCheckInAlertsForMultiPets
 * @param {object }state - Redux state
 * @param {Number } customerKey - Id that represents a customer
 * @param {Number[]} petIds - Array of Pet Ids (e.g [12345, 569874]
 * @returns {Boolean}
 *
 * @example
 *
 * const petCustomerHasMissingEligibilityRequirement = getShouldShowHotelCheckInAlertsForMultiPets(
 *       state,
 *       customerKey,
 *       allPetIds,
 *     );
 */
export const getShouldShowHotelCheckInAlertsForMultiPets = (state, customerKey, petIds) => {
  return petIds.some(
    petId => !!getShouldShowHotelCheckInEligibilityForModal(state, { customerKey, petId }),
  );
};
