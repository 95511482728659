// @ts-check
import { SET_HOTEL_ROOM_SUSPENSION_ERROR } from "./hotelManageRoomDetailsTableActions";

const initialState = {
  data: {},
  error: [],
};

const hotelManageRoomDetailsTableReducer = (
  state = initialState,
  /** @type {{ type: string; payload: string; }} */ action,
) => {
  switch (action.type) {
    case SET_HOTEL_ROOM_SUSPENSION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default hotelManageRoomDetailsTableReducer;
