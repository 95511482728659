import { createSelector } from "reselect";
import { reduceFoodOrMedHistory } from "core/utils/petUtils/petFoodAndMedUtils";

/**
 * Responsible for returning the food Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 * @memberOf Selectors.Food
 * @function
 * @name getFoodState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the food object in the redux store
 *
 * @example createSelector([foodState], foodState => foodState.isHidden;
 */
export default function getFoodState(state) {
  return state?.food;
}

/**
 * Given Pet food, Selects the food history.
 *
 *  @memberOf Selectors.Food
 *  @function
 *  @name selectHotelFoodHistory
 * @param {function} getFoodState - The selector function that returns the food state.
 * @returns {Array} - The food history .
 */
export const selectHotelFoodHistory = createSelector([getFoodState], hotelFood => {
  return reduceFoodOrMedHistory(hotelFood?.foods);
});
