import React from "react";
import { Layout } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import { DoNotBookHotelCheckInInfoLabel } from "dux/doNotBook/doNotBookInfoLabel";
/* NOTE: -------------------------------------------------------------------------------------------
 The intent and responsibility of this file is to just provide a dry solution for
 for the JSX that contains the pet parent alert info messages and toggles.
 This code is identical in the CheckInOutSinglePetCheckInLayout and CheckInOutMultiPetCheckInLayout
 files and thus this file is to abstract that out to reduce wet code and provide a update once, use
 multiple principle.

 I you find this layout differs between the CheckInOutSinglePetCheckInLayout and CheckInOutMultiPetCheckInLayout
 use a different solution, do not modify this file to handle conditional cases.

 This file should not contain any logic as it is specifically a wrapper for other components.
 -------------------------------------------------------------------------------------------------*/
export const PetParentAlertLayoutWrapper = props => {
  const { componentId, isHidden } = props;

  if (isHidden) {
    return null;
  }

  return (
    <Layout.Box id={componentId}>
      <Layout.Stack space="stack-space-5">
        <DoNotBookHotelCheckInInfoLabel />
      </Layout.Stack>
    </Layout.Box>
  );
};

// CONTAINER ----------------------------------------------------------------------------------------
export const PetParentAlertLayoutCheckInWrapper = connect(() => {
  return {
    componentId: "PetParentAlertLayoutCheckInWrapper",
    isHidden: false,
  };
})(PetParentAlertLayoutWrapper);
