import { connect } from "react-redux";
import { getSelectedTab } from "../appointmentsListSelectors";
import { tabFilters } from "../appointmentsListConstants";
import AppointmentHistoryComponent from "./OtherServicesHistoryComponent";

const mapStateToProps = state => {
  const isSelectedTab = getSelectedTab(state) === tabFilters.OTHER_SERVICES;
  return {
    isHidden: isSelectedTab === false,
    componentID: "OtherServicesHistory",
  };
};

export default connect(mapStateToProps)(AppointmentHistoryComponent);
