import React from "react";
import { Layout } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import { HotelPriceChange } from "dux/priceChange/PriceChangeComponent";
import { HotelCheckInServiceNameAndSelection } from "dux/ServiceNameAndSelection/ServiceNameAndSelection";
import { HotelCheckInOutCartSpecialPrice } from "@/dux/_components/petCard/HotelSpecials";
import { HotelCartPrimaryServicePrice } from "dux/cartPrice/cartPrice";

/* NOTE: -------------------------------------------------------------------------------------------
 The intent and responsibility of this file is to just provide a dry solution for
 for the JSX that contains the appointment summary price for hotel checkIn/Out
 This code is identical in the CheckInOutSinglePetCheckInLayout and CheckInOutMultiPetCheckInLayout
 files and thus this file is to abstract that out to reduce wet code and provide a update once, use
 multiple principle.

 I you find this layout differs between the CheckInOutSinglePetCheckInLayout and CheckInOutMultiPetCheckInLayout
 use a different solution, do not modify this file to handle conditional cases.

 This file should not contain any logic as it is specifically a wrapper for other components.
 -------------------------------------------------------------------------------------------------*/

export const AppointmentSummaryPriceLayoutWrapper = props => {
  const { componentId, isHidden } = props;

  if (isHidden) {
    return null;
  }

  return (
    <Layout.Box id={componentId}>
      <Layout.Cluster justify="space-between">
        <HotelCheckInServiceNameAndSelection />

        <Layout.Cluster space="cluster-space-4">
          <HotelCartPrimaryServicePrice />
          <HotelPriceChange />
        </Layout.Cluster>
      </Layout.Cluster>

      <HotelCheckInOutCartSpecialPrice />
    </Layout.Box>
  );
};

// CONTAINER ----------------------------------------------------------------------------------------
export const AppointmentSummaryPriceLayoutCheckInWrapper = connect(() => {
  return {
    componentId: "AppointmentSummaryPriceLayoutCheckInWrapper",
    isHidden: false,
  };
})(AppointmentSummaryPriceLayoutWrapper);
