import { Layout, Text, Button, QuantityModifier } from "@prism/psm-ui-components";
import React, { useState } from "react";
import { connect } from "react-redux";
import { getCurrentPet, selectCurrentStoreTimeZone } from "core/selectors/persistentSelectors";
import {
  getFirstHotelEngagementByPet,
  selectAddonByPetAndAddonId,
  selectAddonTotalQtyByGroupingId,
  selectAppliedAddonsFromEngagementsByPet,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { getHotelItinerary } from "dux/hotelItinerary/hotelItinerarySelectors";
import { createLoadingSelector } from "core/selectors/utils";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { putCart, PUT_CART } from "../servicesCart/servicesCartActions";
import {
  removeHotelCartProducts,
  setHotelCartProduct,
} from "../servicesCartHotel/servicesCartHotelActions";
import {
  selectEnhancedAddonChildrenFromCart,
  selectHotelCartPetProductById,
} from "../servicesCartHotel/servicesCartHotelSelectors";
import {
  PATCH_HOTEL_ITINERARY_ADDONS,
  patchHotelItineraryAddons,
} from "../hotelItineraryAddonPatch/hotelItineraryAddonPatchActions";
import { buildEngagementsForSingleDayAddon } from "@/core/utils/hotelEngagementUtils/buildEngagementsForSingleDayAddon";
import { getCartItemId } from "../servicesCart/servicesCartUtils";
import {
  PUT_HOTEL_ITINERARY_ADDONS,
  putHotelItineraryAddons,
} from "../hotelItineraryAddonPut/hotelItineraryAddonPutActions";
import { buildEngagementAddonUpdatesForPut } from "@/core/utils/hotelEngagementUtils/buildEngagementsWithUpdatedAddons";
import { frequencyConstants } from "../frequency/FrequencyConstants";
import { formatMoney } from "../_utils/moneyUtil";

export const HotelAddonsEditQuantityCartFlyoutComponent = ({
  price,
  initialQuantity,
  onApply,
  componentId,
  isLoading,
  label,
}) => {
  const [quantity, setQuantity] = useState(initialQuantity);

  return (
    <Layout.Stack space="stack-space-6" id={componentId}>
      <Text align="center">Edit Add-on Quantity</Text>
      <Layout.Cluster justify="space-between">
        <Text align="left">Price:</Text>
        <Text align="center">{formatMoney(price)}</Text>
      </Layout.Cluster>
      <Layout.Cluster justify="space-between">
        <Text align="left">Quantity:</Text>
        <QuantityModifier quantity={quantity} updateQuantity={setQuantity} />
      </Layout.Cluster>
      <Layout.Cluster justify="space-between">
        <Text align="left">Total:</Text>
        <Text align="center">{formatMoney(price * quantity)}</Text>
      </Layout.Cluster>
      <Button
        variant="prism-primary"
        disabled={quantity === initialQuantity || isLoading}
        onClick={() => onApply({ newQuantity: quantity })}
      >
        <Text>{label}</Text>
      </Button>
    </Layout.Stack>
  );
};

// HOTEL CHECK IN/OUT ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelAddonsEditQuantityCartFlyout = connect(
  (state, { addonId }) => {
    const petId = getCurrentPet(state);
    const itineraryId = getHotelItinerary(state)?.itineraryId;

    // It is okay to use only the first engagement here because multiday addons won't use this component
    // So, there will only ever be one copy of the addon on the first engagement for a given pet
    const engagement = getFirstHotelEngagementByPet(state, { petId });
    const customerKey = getCurrentCustomerKey(state);
    const addon = selectAddonByPetAndAddonId(addonId)(state, { petId });
    const petAddons = selectAppliedAddonsFromEngagementsByPet(state, { petId });

    const isLoading = createLoadingSelector([
      PATCH_HOTEL_ITINERARY_ADDONS,
      PUT_HOTEL_ITINERARY_ADDONS,
    ])(state);

    return {
      componentId: "hotelAddons_editCartQuantity-Flyout",
      petId,
      addon,
      itineraryId,
      initialQuantity: selectAddonTotalQtyByGroupingId(addon?.groupingId)(state, { petId }),
      engagementId: engagement?.engagementId,
      price: addon?.pricing?.pricePerUnit,
      isLoading,
      label: isLoading ? "Applying..." : "Apply",
      customerKey,
      // merge props
      engagement,
      petAddons,
      timeZone: selectCurrentStoreTimeZone(state),
    };
  },

  dispatch => {
    return {
      dispatchOnApply: ({ itineraryId, customerId, data }) => {
        dispatch(patchHotelItineraryAddons({ itineraryId, customerId, data }));
      },
      dispatchOnUpdate: ({ petId, updatedEngagements }) =>
        dispatch(putHotelItineraryAddons({ petId, updatedEngagements })),
    };
  },

  (mapProps, mapDispatchToProps, { addonId }) => {
    const {
      componentId,
      addon,
      initialQuantity,
      petId,
      itineraryId,
      engagement,
      engagementId,
      price,
      isLoading,
      label,
      customerKey,
      petAddons,
      timeZone,
    } = mapProps;
    const { dispatchOnApply, dispatchOnUpdate } = mapDispatchToProps;
    return {
      // control what props get passed to the view
      componentId,
      addon,
      price,
      initialQuantity,
      label,
      isLoading,
      onApply: ({ newQuantity }) => {
        if (newQuantity === 0) {
          // Remove addons with PUT call
          const updatedEngagements = buildEngagementAddonUpdatesForPut({
            petEngagements: [engagement],
            petAddons,
            addonToEditId: addonId,
            timeZone,
            // sending empty dates array to remove this addon from all engagements
            dates: [],
          });

          dispatchOnUpdate({ petId, updatedEngagements });
        } else {
          const engagements = buildEngagementsForSingleDayAddon({
            engagementId,
            addon,
            quantity: newQuantity,
          });
          const data = { pets: [{ petKey: petId, engagements }] };
          dispatchOnApply({ itineraryId, customerId: customerKey, data });
        }
      },
    };
  },
)(HotelAddonsEditQuantityCartFlyoutComponent);

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingAddonsEditQuantityCartFlyout = connect(
  (state, { petId, addonId }) => {
    const addon = selectHotelCartPetProductById({ petId, productId: addonId })(state);
    const childAddons = selectEnhancedAddonChildrenFromCart({ petId, productId: addonId })(state);
    const isLoading = createLoadingSelector([PUT_CART])(state);

    return {
      componentId: "hotelBookingAddons_editCartQuantity-Flyout",
      petId,
      addon,
      initialQuantity: addon?.pricing?.quantity,
      price: addon?.pricing?.pricePerUnit,
      isLoading,
      label: isLoading ? "Applying..." : "Apply",
      childAddons: Object.values(childAddons),
    };
  },

  dispatch => {
    return {
      dispatchOnApply: ({ product, cartItemId }) => {
        dispatch(setHotelCartProduct({ product, cartItemId }));
        dispatch(putCart());
      },
      dispatchDeleteAddon: productsToRemove => {
        dispatch(removeHotelCartProducts(productsToRemove));
        dispatch(putCart());
      },
    };
  },

  (mapProps, mapDispatchToProps, { addonId }) => {
    const {
      componentId,
      addon,
      initialQuantity,
      petId,
      price,
      isLoading,
      label,
      childAddons,
    } = mapProps;
    const { dispatchOnApply, dispatchDeleteAddon } = mapDispatchToProps;
    return {
      componentId,
      addon,
      price,
      initialQuantity,
      label,
      isLoading,
      onApply: ({ newQuantity }) => {
        const cartItemId = getCartItemId({ petId, item: { productId: addonId } });
        if (newQuantity === 0) {
          const childrenToRemove = childAddons?.map(item => getCartItemId({ petId, item }));
          dispatchDeleteAddon([cartItemId, ...childrenToRemove]);
        } else {
          const product = {
            ...addon,
            frequency: frequencyConstants.DAILY,
            singleDayStayQuantity: newQuantity,
          };
          dispatchOnApply({ product, cartItemId });
        }
      },
    };
  },
)(HotelAddonsEditQuantityCartFlyoutComponent);
