// @ts-nocheck
import * as React from 'react';
import * as CSS from 'csstype';
import { psx, pluck } from '../../utils/';
import { ICON_TOKENS } from './tokens';

import './styles.css';

export interface SvgIconProps extends React.SVGAttributes<SVGElement> {
  /** A "key" contained in the icon universe. Legacy icons have less control over their size, strokeWidth and color. */
  variant?: keyof typeof ICON_TOKENS.UNIVERSE;
  /** The size of the icon is measured by the width of the icon in pixels*/
  size?: keyof typeof ICON_TOKENS.SIZE;
  /** The color of the path svg icon */
  color?: keyof typeof ICON_TOKENS.COLOR;
  /** The strokeWidth of the icon in pixels */
  strokeWidth?: number;
  /** Raise or lower the icon */
  verticalAlign?: CSS.Property.VerticalAlign;
  /** viewbox */
  viewbox?: string;
  /** Option to use the color value as a fill instead of a stroke */
  useColorForFill?: boolean
}

/**
 * For accessibility reasons, we set the following attributes:
 * - aria-hidden="true" //  allows assistive tech to skip the SVG since it's decorative
 * - focusable="false" //  prevents a "double focus" event in some version of IE
 *
 * - [SVG Icon Systems](https://paulie.dev/posts/2020/04/svg-icon-systems/)
 * - [Relative Sizing with em-units](https://hankchizljaw.com/wrote/relative-sizing-with-em-units/)
 * - [Accessibility](https://www.sarasoueidan.com/blog/accessible-icon-buttons/)
 */

export const SvgIcon: React.FunctionComponent<SvgIconProps> = ({
  viewbox = '0 0 24 24',
  variant = 'hero-icon-x',
  color = 'psm-icon-color-current',
  size = 'psm-icon-size-16',
  strokeWidth = 2,
  verticalAlign = 0,
  style = {},
  className,
  children,
  useColorForFill = false,
}) => {
  const classNames = psx('psm', 'psm-icon', `variant-${variant}`, className);
  const d = pluck(ICON_TOKENS.UNIVERSE, variant);

  // Map style props to the icon if it NOT legacy
  const stroke = useColorForFill ? 'none' : pluck(ICON_TOKENS.COLOR, color);
  const fill = useColorForFill ? pluck(ICON_TOKENS.COLOR, color) : 'none';
  const computeStyle: CSS.Properties | React.CSSProperties = {
    '--setIconVerticalAlign': verticalAlign,
    '--setIconSize': pluck(ICON_TOKENS.SIZE, size),
    ...style,
  };

  // Used to support legacy icons. When the data returned is an object,
  // that means we just want to return the old svg
  if (typeof d === 'object') {
    return React.cloneElement(d, { className: classNames, style: computeStyle });
  }

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewbox}
        fill={fill}
        className={classNames}
        stroke={stroke}
        style={computeStyle}
        aria-hidden="true" //  allows assistive tech to skip the SVG since it's decorative
        focusable="false" //  prevents a "double focus" event in some version of IE
      >
        <path d={d} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} />
        <path d="M0 0h24v24H0z" fill="none" strokeWidth="0" />
      </svg>
      {children}
    </>
  );
};

export default SvgIcon;
