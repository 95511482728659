import { TOGGLE_CUSTOMER_PROFILE_INCOMPLETE_MODAL } from "./customerProfileIncompleteModalActions";

const initialState = {
  isCustomerProfileIncompleteModalOpen: false,
};

const customerProfileIncompleteModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CUSTOMER_PROFILE_INCOMPLETE_MODAL:
      return {
        ...state,
        isCustomerProfileIncompleteModalOpen: !state.isCustomerProfileIncompleteModalOpen,
      };
    default:
      return state;
  }
};

export default customerProfileIncompleteModalReducer;
