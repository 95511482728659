import { connect } from "react-redux";
import { getPetParentAlertsFormData } from "../../../../core/selectors/entitiesSelector";
import AlertsFormComponent from "../../alerts/AlertsFormComponent";
import customersActionCreators from "../../../../core/actionCreators/customersActionCreators";
import { getSystemBookingFlow } from "../../../setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../../../setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = (state, ownProps) => ({
  componentID: "alertForm-SalonCustomerAlerts",
  title: "Pet Parent Alerts",
  isHidden: getSystemBookingFlow(state) !== systemName.SALON,
  alertsFormData: getPetParentAlertsFormData(state, ownProps),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: values => {
    dispatch(
      customersActionCreators.updateCustomerAlerts({
        customerKey: ownProps.customerKey,
        data: {
          bookingAlert: (values.isBookingAlert && values.bookingAlertReason) || "",
          checkInAlert: (values.isCheckInAlert && values.checkInAlertReason) || "",
          checkOutAlert: (values.isCheckOutAlert && values.checkOutAlertReason) || "",
        },
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsFormComponent);
