import { createSelector } from "reselect";
import { setWith, get } from "lodash/fp";
import moment from "moment";
import { getSelectedDate } from "dux/selectedDate/selectedDateSelector";
import { getSelectedAssociateWeekly, getProps } from "./commonSelector";
import { getSalonHours } from "./salonHoursSelector";
import { getPetServiceItems, getAbsences, getAssociates } from "./entitiesSelector";
import {
  getAppointmentsAbsencesAndShiftByAssociateAndDate,
  getAssociateShiftTimeByDate,
  getDashboardAvailableAppointmentsForAssociate,
} from "./schedulesSelectors";

import { createDeepEqualSelector } from "./utils";
import { getStoreNumber } from "./persistentSelectors";
import filterValidWeeklyAppointments from "../utils/entitiesUtils/filterValidWeeklyAppointments";

export const getDashboardAvailableAppointmentsWeekly = createSelector(
  [getSelectedDate, getSalonHours, getSelectedAssociateWeekly, (state, props) => state],
  (selectedDate, salonHours, selectedAssociateWeekly, state) => {
    const availableAppointmentsWeekly = {};

    const startDate = moment(selectedDate).startOf("isoWeek");
    const endDate = moment(selectedDate).endOf("isoWeek");

    for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, "days")) {
      const dateKey = m.format("YYYY-MM-DD");
      if (!salonHours[dateKey]) {
        continue;
      }
      availableAppointmentsWeekly[dateKey] = getDashboardAvailableAppointmentsForAssociate(state, {
        associateId: selectedAssociateWeekly,
        selectedDate: dateKey,
      });
    }

    return availableAppointmentsWeekly;
  },
);

export const getGanttGroupsWeekly = createSelector(
  [
    getSalonHours,
    getPetServiceItems,
    getAbsences,
    getAssociates,
    getSelectedDate,
    getSelectedAssociateWeekly,
    getStoreNumber,
    (state, props) => state,
  ],
  (
    salonHours,
    petServiceItems,
    absences,
    associates,
    selectedDate,
    selectedAssociateWeekly,
    storeNumber,
    state,
  ) => {
    const associateName = associates[selectedAssociateWeekly]
      ? associates[selectedAssociateWeekly].associateName
      : "";

    let groups = {
      [associateName]: {},
    };

    const startDate = moment(selectedDate).startOf("isoWeek");
    const endDate = moment(selectedDate).endOf("isoWeek");

    for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, "days")) {
      const dateKey = m.format("YYYY-MM-DD");
      // Not adding to group dates that associate do not have a shift on
      if (
        !salonHours[dateKey] ||
        !getAssociateShiftTimeByDate(state, {
          associateId: selectedAssociateWeekly,
          selectedDate: dateKey,
        })
      ) {
        continue;
      }
      groups = setWith(
        Object,
        [associateName, dateKey, "appointments"],
        (salonHours[dateKey].petServiceItems || [])
          .map(petServiceItemId => petServiceItems[petServiceItemId])
          .filter(petServiceItem =>
            filterValidWeeklyAppointments({ petServiceItem, storeNumber, selectedAssociateWeekly }),
          ),
        groups,
      );
      groups = setWith(
        Object,
        [associateName, dateKey, "absences"],
        (salonHours[dateKey].absences || [])
          .map(absenceId => absences[absenceId])
          .filter(absence => absence && Number(absence.associateId) === selectedAssociateWeekly),
        groups,
      );
    }

    return Object.keys(groups).reduce((acc, cur) => {
      const eventsByDate = groups[cur];
      acc[cur] = Object.keys(eventsByDate).map(d => [d, eventsByDate[d]]);
      return acc;
    }, {});
  },
);

export const getGanttGroupsByAssociates = createDeepEqualSelector(
  [getAppointmentsAbsencesAndShiftByAssociateAndDate, getAssociates, getProps],
  (appointmentsAbsencesAndShift, associates, { excludeAssociatesWithoutShift }) => {
    let groups = {};
    Object.keys(appointmentsAbsencesAndShift).forEach(associateId => {
      // Not adding to group associates that do not have a shift on selectedDate
      if (
        excludeAssociatesWithoutShift &&
        !get([associateId, "shift"], appointmentsAbsencesAndShift)
      ) {
        return;
      }

      associateId = Number(associateId);

      const associateGroup =
        associates[associateId] && (associates[associateId].associateGroup || "Unknown");
      if (associateGroup) {
        groups = setWith(
          Object,
          [associateGroup, associateId.toString()],
          appointmentsAbsencesAndShift[associateId.toString()],
          groups,
        );
      }
    });

    const sortGroups = Object.keys(groups).reduce((acc, cur) => {
      // First we order the shift by start time
      const shiftsByGroup = Object.entries(groups[cur]);
      const shiftsSortedByStartTime = shiftsByGroup.sort(
        (a, b) => a[1].shift.shiftStartDateTime - b[1].shift.shiftStartDateTime,
      );

      // Get associate names and combine them with the shift data.
      // This allows us to perform a secondary sort if the shift
      // times are equivalent, then we sort alphabetically by name.
      const associateIdByGroup = shiftsSortedByStartTime.map(associate => associate[0]);
      const associateNames = associateIdByGroup.reduce(
        (acc, cur) => [...acc, (associates[cur] && associates[cur].associateName) || ""],
        [],
      );

      const combineAssociatesAndShifts = shiftsSortedByStartTime.map((schedule, idx) => [
        ...schedule,
        associateNames[idx],
      ]);
      const shiftsSortedByTimeAndName = combineAssociatesAndShifts.sort((a, b) => {
        const firstAssociateStartTime = a[1].shift.shiftStartDateTime.format("hh:mm A");
        const secondAssociateStartTime = b[1].shift.shiftStartDateTime.format("hh:mm A");

        if (firstAssociateStartTime === secondAssociateStartTime) {
          return a[2].localeCompare(b[2]);
        }
      });

      acc[cur] = shiftsSortedByTimeAndName;
      return acc;
    }, {});

    return sortGroups;
  },
);
