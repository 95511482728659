import { petsmartWebPublicAPI } from "./_axiosConfigPetMWebPubic";

/**
 * Endpoint for pet food options
 * @memberOf APIs.foodOptions
 * @function getFoodOptionsList
 * @returns {*} AxiosPromise
 */
export function fetchFoodOptionsList() {
  return petsmartWebPublicAPI.get(`pets/foods`);
}
