import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get the itinerary invoice and/or pet invoices for the specified itinerary.
 * Payment can be made on the entire invoice or a single pet. If the entire
 * itinerary is paid, there will be a single itinerary invoice. If a single pet
 * is paid, when the engagement includes multiple pets, there will be multiple
 * invoices. At least one will be for a pet. The other may be for the rest of the
 * itinerary, or for another pet.
 * For System Services Booking API
 * - Get invoices for itinerary
 * @param {*} { customerKey, itineraryId, ...params }
 * @returns {*} AxiosPromise
 */
export function fetchInvoice({ customerKey, itineraryId, ...params }) {
  return bookingAPI.get(
    `${endpointVersionString()}/customers/${customerKey}/itineraries/${itineraryId}/invoices`,
    {
      params
    }
  );
}
