// eslint-disable-next-line no-unused-vars
import { connect } from "react-redux";
import ClickableText from "web/common/ClickableText";
import { showHideDDCCapacityModal } from "dux/ddcRoomCapacityModal/ddcRoomCapacityModalActions";

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => ({
  componentID: "ClickableText__DDCRoomCapacity",
  isVisible: false,
  label: "More Info",
});

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(showHideDDCCapacityModal({ isHidden: false })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClickableText);
