import React from "react";
import styled from "styled-components";

/* * ----------------------------------------------------------------------- * *\
  CSS Config Logic
\* * ----------------------------------------------------------------------- * */
function setLyoutType(cssConfig) {
  if (cssConfig.layout === "vertical") {
    return `
      display: flex;
      flex-direction: column;
    `;
  }

  if (cssConfig.layout === "3-col") {
    return `
      display: grid;
      grid-template-columns: 1fr 1fr 30%;
    `;
  }

  return `
    display: flex;
    flex-direction: row;
  `;
}

const SectionWrapper = styled.section`
  margin-top: ${cssConfig => (cssConfig.lift ? cssConfig.lift : 0)};
  margin-left: ${cssConfig => (cssConfig.shift ? cssConfig.shift : 0)};
  ${cssConfig => setLyoutType(cssConfig)};
`;

/* * ----------------------------------------------------------------------- * *\
  Component Markup
\* * ----------------------------------------------------------------------- * */
export default function GenericContentWrapper(props) {
  const { isHidden, componentID, children } = props;
  if (!isHidden) {
    return (
      <SectionWrapper id={componentID} {...props}>
        {children}
      </SectionWrapper>
    );
  }

  return null;
}
