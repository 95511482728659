import React from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

/**
 * HOC to replace withRouter() which was removed from react-router-dom in v6
 * https://reactrouter.com/en/v6.3.0/faq#what-happened-to-withrouter-i-need-it
 *
 * This function will need to be used in order to use the route props in class components and
 * connect functions, but functional components could be refactored to use the hooks directly.
 */
export const withRouteProps = Component => {
  return props => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  };
};
