import { connect } from "react-redux";

import {
  setUpcomingSearchText,
  clearUpcomingSearchText
} from "../../core/actionCreators/upcomingAndHereActionCreators";
import { getUpcomingSearchText } from "../../core/selectors/upcomingAndHereSelectors";
import SearchInput from "../common/SearchInput";

const mapStateToProps = state => ({
  placeholder: "Search by associate or pet",
  value: getUpcomingSearchText(state)
});

const mapDispatchToProps = dispatch => ({
  onChange: e => dispatch(setUpcomingSearchText(e.target.value)),
  clearText: () => dispatch(clearUpcomingSearchText())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchInput);
