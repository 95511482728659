import React from "react";
import styled from "styled-components";
import SectionTitle from "../../../../common/SectionTitle";

const StyledSection = styled(SectionTitle)`
  align-self: center;
`;

const NoAppointmentsComponent = ({ isHidden, isLoading, componentID }) => {
  if (!isHidden) {
    return isLoading ? null : (
      <StyledSection id={componentID} title="No future or past appointments" />
    );
  }

  return null;
};

export default NoAppointmentsComponent;
