import React, { Component } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash/fp";
import { color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import CommonPriceList from "../../common/modalInsets/selectablePriceLists/commonPriceList";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${color.victorStone};
`;

const SearchField = styled.input`
  font-size: ${fontSizes.regular};
  margin-bottom: 25px;
  padding-left: 10px;
  height: 45px;
`;

const PriceLists = styled.div`
  overflow-y: auto;
  height: 435px;
`;

// NOTE: This component is shared by both BookingAddOnsPriceListContainer and StandaloneSeriesAccordionContentContainer
// Since BGM/FTCO add-ons are only required to be pre-selected in the booking flow and the standalone flow, we may
// want to look into refactoring this into two separate components - one that includes a componentDidMount that dispatches
// which add-ons to initially select and another one that does not include this lifecycle method.

export default class BookingAddonsPriceListComponent extends Component {
  state = { query: "" };

  handleQuery = (event) => {
    this.setState({ query: event.target.value });
  };

  render() {
    const {
      addOns,
      properties,
      onSelectionChange,
      selectedAddOns = [],
      disabledOptions = [],
    } = this.props;
    const { query } = this.state;
    return (
      <Container>
        <SearchField value={query} onChange={this.handleQuery} placeholder="Search" />
        <PriceLists>
          {addOns.map((addOn) => (
            <React.Fragment key={addOn?.label}>
              <h3>{addOn?.label}</h3>
              <CommonPriceList
                priceList={addOn.value}
                idProperty={properties.id}
                nameProperty={properties.name}
                searchFilter={query}
                selectedOptions={selectedAddOns}
                disabledOptions={disabledOptions}
                onSelectionChange={onSelectionChange}
              />
            </React.Fragment>
          ))}
        </PriceLists>
      </Container>
    );
  }
}
