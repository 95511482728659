import { connect } from "react-redux";
import CommonButton from "../common/commonButton";
import { hideBookingModal } from "../../../src/core/actionCreators/bookingActionCreators";

const mapStateToProps = (state, ownProps) => ({
  componentId: "CommonButton_VIPBookingAlert",
  label: "Got It!",
});

const mapDispatchToProps = dispatch => ({
  onClick: ({}) => dispatch(hideBookingModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonButton);
