import React from "react";
import styled from "styled-components";
import CommonButton from "web/common/commonButton";

const ComponentWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 30px 0;
`;

const Prev = styled.div`
  cursor: pointer;
`;

/**
 * Shared footer for the first few void transaction modal windows.
 * @param {String} reason - What Reason checkbox is currently checked
 * @param {String} buttonLabel - Label for the forward button in modal flow
 * @param {String} linkLabel - Label for the go back or previous CTA
 * @param {Function} voidTransactionFlow - Handler function for the forward button in modal flow
 * @param {Function} goBack - Handler function for the previous button in modal flow
 * @param {Array} modalTypeList - List of Modal types (e.g. windows isn the flow)
 * @param {String} modalType - The current modal
 */
export default function VoidTransactionModalFooter(props) {
  const {
    reason,
    buttonLabel,
    linkLabel,
    voidTransactionFlow,
    goBack,
    modalTypeList,
    modalType,
  } = props;
  const modalIndex = modalTypeList.indexOf(modalType);

  return (
    <ComponentWrapper>
      <Prev
        onClick={() => {
          const modalTypeName = modalTypeList[modalIndex - 1];
          goBack({ modalTypeName, modalIndex });
        }}
      >
        {" "}
        {linkLabel}{" "}
      </Prev>

      <CommonButton
        disabled={modalIndex === 1 && !reason}
        onClick={() => {
          const modalTypeName = modalTypeList[modalIndex + 1];
          voidTransactionFlow({ modalTypeName, modalIndex });
        }}
        label={buttonLabel}
      />
    </ComponentWrapper>
  );
}
