import React, { Component } from "react";
import styled from "styled-components";

import LoadingWrapper from "../../common/LoadingWrapper";
import StandaloneAccordion from "../../../dux/toggleAccordion/_sections/StandaloneServices/StandaloneServicesAccordionContainer";
import StandaloneService from "../../booking/standaloneService/StandaloneServiceContainer";
import BookingAddons from "../../booking/bookingAddons/BookingAddonsContainer";
import SalonServicesGridContainer from "../../services/salonServices/SalonServicesGridContainer";
import BulkPackageOfferingsAccordionContainer from "../../../dux/toggleAccordion/_sections/BulkPackageOfferings/BulkPackageOfferingsAccordionContainer";
import BulkPackageOfferingsAddOns from "../../../dux/bgm/bulkPackageOfferings/addOns/BulkPackageOfferingsAddOnsContainer";
import BulkPackageOfferingsEnhancedServices from "../../../dux/bgm/bulkPackageOfferings/enhancedServices/bulkPackageEnhancedServiceContainer";

import { BookingEnhancedServicesList } from "../../../dux/enhancedServicesList/EnhancedServicesList"

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const AddonRowsContainer = styled.div`
  margin-top: 15px;
`;

const AddOnHeader = styled.h3`
  margin-bottom: 30px;
  margin-top: 40px;
`;

export default class SalonServices extends Component {
  componentDidMount() {
    const {
      loadServicesForPet,
      selectedPet,
      isStandalone,
      isHidden,
      customerKey,
      getAvailableBundlesForAllPetsByCustomer,
      getPackageOfferings,
      isBGMFeatureWorkflowEnabled,
      isFTCOFeatureWorkflowEnabled,
      isBookingFlow,
      isStandaloneFlow,
      storeNumber
    } = this.props;
    if (selectedPet && !isHidden) {
      loadServicesForPet({ customerKey, petId: selectedPet, isStandalone });
      if (isBGMFeatureWorkflowEnabled || isFTCOFeatureWorkflowEnabled) {
        if (isBookingFlow) {
          getAvailableBundlesForAllPetsByCustomer({ customerKey });
        }
        if (isStandaloneFlow) {
          getPackageOfferings();
        }
      }
    }
  }

  componentWillUnmount() {
    const {
      isBGMFeatureWorkflowEnabled,
      isStandaloneFlow,
      clearSelectedBulkPackageOfferings,
    } = this.props;
    const shouldClearSelectedBulkPackageOffering = isBGMFeatureWorkflowEnabled && isStandaloneFlow;

    if (shouldClearSelectedBulkPackageOffering) {
      clearSelectedBulkPackageOfferings();
    }
  }

  renderStandalones() {
    const { selectedPet } = this.props;

    return (
      <>
        <StandaloneAccordion />
        <StandaloneService petId={selectedPet} />
      </>
    );
  }

  renderServicesOrStandaloneService() {
    const { isStandalone, selectedPet, isServicesLoading } = this.props;
    return isStandalone ? (
      this.renderStandalones()
    ) : (
      <LoadingWrapper isLoading={isServicesLoading}>
        <SalonServicesGridContainer petId={selectedPet} />
      </LoadingWrapper>
    );
  }

  renderAddonSection = (isServicesLoading, isStandalone) => {
    if (isStandalone) {
      return <BulkPackageOfferingsAddOns />;
    }

    return (
      <LoadingWrapper isLoading={isServicesLoading}>
        <AddonRowsContainer>
          <AddOnHeader>Add-ons</AddOnHeader>
          <BookingAddons />
        </AddonRowsContainer>
      </LoadingWrapper>
    );
  };

  render() {
    const { appointment, isServicesLoading, isStandalone, isHidden, componentID } = this.props;

    if (!isHidden) {
      return (
        <SectionWrapper id={componentID}>
          <BulkPackageOfferingsAccordionContainer />
          {this.renderServicesOrStandaloneService()}
          { isStandalone ? <BulkPackageOfferingsEnhancedServices /> : <BookingEnhancedServicesList /> }
          {appointment ? this.renderAddonSection(isServicesLoading, isStandalone) : null}
        </SectionWrapper>
      );
    }

    return null;
  }
}
