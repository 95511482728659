import { connect } from "react-redux";
import { color } from "web/common/styles/theme";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getFirstTimeCustomerInfo } from "dux/bgm/ftco/ftcoSelectors";
import { isBGMEligibilityHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import IconWithLabel from "dux/_components/icon/IconWithLabel";
import PreCheckedInIcon from "assets/icons/pre-checked-in-appointment.svg";
import EligiblePromotionsBGMTextContainer from "dux/eligiblePromotions/EligiblePromotionsBGMTextContainer";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  const { firstName } = getFirstTimeCustomerInfo(state, { customerKey });
  const isHidden = !customerKey || isBGMEligibilityHidden(state);
  return {
    isHidden,
    firstName,
    componentId: "eligiblePromotionsBGMContainer__IconWithLabel",
    src: PreCheckedInIcon,
    diComp: EligiblePromotionsBGMTextContainer,
    styleObj: {
      padding: "1em",
      justifyContent: "center",
      borderBottom: `1px solid ${color.borderGrey}`,
    },
  };
};

export default connect(mapStateToProps)(IconWithLabel);
