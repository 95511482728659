import isEmpty from "lodash/isEmpty";
import { buildAddonForPatchCall } from "./buildAddonForAPICall";

/**
 * Util to format addon in engagement for patch call for single day reservations
 * @memberOf Utils.Engagement
 * @function
 * @name buildEngagementsForSingleDayAddon
 * @param {Object} args
 * @param {string} args.engagementId
 * @param {Object} args.addon - the addon currently being edited - for new addons it should only contain addOnProductNumber
 * @param {Object} args.instructions - comments to add to addons
 * @param {number} args.quantity - quantity to add to addon
 * @param {Object} args.childAddons - child addons or empty obj if none
 * @returns {Object[]} - engagements data for patch call
 * @example buildEngagementsForSingleDayAddon({ engagementId, addon, instructions, quantity })
 */
export const buildEngagementsForSingleDayAddon = ({
  engagementId,
  addon,
  instructions = {},
  quantity,
  childAddons = {},
}) => {
  const addOns = [
    buildAddonForPatchCall({ addon, instructions, quantity }),
    ...Object.values(childAddons).map(child =>
      buildAddonForPatchCall({ addon: child, instructions, quantity }),
    ),
  ].filter(addon => !isEmpty(addon));

  return addOns.length ? [{ engagementId, addOns }] : [];
};
