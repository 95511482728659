import { connect } from "react-redux";
import { Button } from "@prism/psm-ui-components";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import {
  getTrainingAttendeeById,
  getIsTrainingAppointmentCompleted,
} from "../trainingClassSessions/selectors";
import {
  postResendMeetingLink,
  POST_RESEND_MEETING_LINK,
} from "../_components/resendMeetingLink/resendMeetingLinkActions";
import { createLoadingSelector } from "@/core/selectors/utils";
import { toggleResendMeetingLinkFlashMessage } from "./manageTrainingClassPageActions";

const mapStateToProps = (state, { attendeeId }) => {
  const storeNumber = getStoreNumber(state);
  const { engagementId } = getTrainingAttendeeById(state, { storeNumber, attendeeId });
  const isLoading = createLoadingSelector([POST_RESEND_MEETING_LINK])(state);
  const isTrainingAppointmentCompleted = getIsTrainingAppointmentCompleted(state, {
    storeNumber,
    attendeeId,
  });
  return {
    variant: "link",
    children: "Resend Meeting Link",
    engagementId,
    disabled: isLoading || isTrainingAppointmentCompleted,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchResendMeetingLink: ({ engagementId, onComplete }) => {
    dispatch(postResendMeetingLink({ engagementId, onComplete }));
  },
  toggleResendMeetingLinkFlashMessage: () => {
    dispatch(toggleResendMeetingLinkFlashMessage());
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  return {
    ...propsFromState,
    ...ownProps,
    ...propsFromDispatch,
    onClick: () => {
      const { dispatchResendMeetingLink, toggleResendMeetingLinkFlashMessage } = propsFromDispatch;
      const { engagementId } = propsFromState;
      dispatchResendMeetingLink({
        engagementId,
        onComplete: () => {
          toggleResendMeetingLinkFlashMessage();
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Button);
