/**
 * Util to check if an arg is a string containing non whitespace characters
 * @memberOf Utils.Strings
 * @function
 * @name isValidString
 * @param {string} str
 * @returns {Boolean}
 * @example isValidString("     ") // -> returns false
 */
export const isValidString = str => {
  const isStr = typeof str === "string";
  if (!isStr || !str) return false;

  const isOnlyWhitespace = !str?.trim();
  const hasExtraLeadingWhitespace = /\s{2,}/.test(str);
  const hasExtraTrailingWhitespace = /\s{2,}/.test(str);

  return !isOnlyWhitespace && !hasExtraLeadingWhitespace && !hasExtraTrailingWhitespace;
};
