import { connect } from "react-redux";
import { enableDisableWorkflowFeatureFlags } from "web/enableDisableWorkflowFeatureFlag/enableDisableWorkflowFeatureFlagActions";
import getWorkflowById from "web/enableDisableWorkflowFeatureFlag/selectors/getWorkflowFeatureFlagById";
import Checkbox from "web/common/Checkbox";

const mapStateToProps = (state, { id }) => {
  const workflow = getWorkflowById(state, { id });
  const workflowId = workflow.id;

  return {
    labelPosition: "left",
    checked: !workflow.isHidden,
    name: workflowId,
    value: workflowId,
    label: workflowId,
  };
};

const mapDispatchToProps = dispatch => ({
  handleCheckBoxChange: ({ featureFlagId, checked }) => {
    dispatch(enableDisableWorkflowFeatureFlags({ featureFlagId, checked }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onChange: () =>
    propsFromDispatch.handleCheckBoxChange({
      featureFlagId: propsFromState.value,
      checked: !propsFromState.checked,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Checkbox);
