// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pplt-layout__box {
    --setBoxPadding: 0;
    --setBorderWidth: 0;
    --setBorderColor: black;
    --setBorderRadius: 0;
    --setBackgroundColor: inherit;
}

.pplt-layout__box {
    padding: var(--setBoxPadding);
    border: var(--setBorderWidth) solid var(--setBorderColor);
    background-color: var(--setBackgroundColor);
    border-radius: var(--setBorderRadius);
  }
`, "",{"version":3,"sources":["webpack://./src/layout/box/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,oBAAoB;IACpB,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;IAC7B,yDAAyD;IACzD,2CAA2C;IAC3C,qCAAqC;EACvC","sourcesContent":[".pplt-layout__box {\n    --setBoxPadding: 0;\n    --setBorderWidth: 0;\n    --setBorderColor: black;\n    --setBorderRadius: 0;\n    --setBackgroundColor: inherit;\n}\n\n.pplt-layout__box {\n    padding: var(--setBoxPadding);\n    border: var(--setBorderWidth) solid var(--setBorderColor);\n    background-color: var(--setBackgroundColor);\n    border-radius: var(--setBorderRadius);\n  }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
