import { connect } from "react-redux";
import { isBGMEligibilityHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import { ELIGIBLE_PROMOTIONS_BGM_DECLINE_BUTTON_LABEL } from "dux/eligiblePromotions/eligiblePromotionConstants";
import { hideCheckInOutModal } from "@/core/actionCreators/checkInOutActionCreator";
import CommonButton from "@/web/common/commonButton";

const mapStateToProps = state => {
  return {
    componentId: "bgm-decline__commonButton",
    isHidden: isBGMEligibilityHidden(state),
    label: ELIGIBLE_PROMOTIONS_BGM_DECLINE_BUTTON_LABEL,
    inverted: true,
  };
};

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(hideCheckInOutModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonButton);
