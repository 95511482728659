import { connect } from "react-redux";
import SearchInput from "../../../../web/common/SearchInput";
import simplePhrases from "../../../_constants/simplePhrasesConstants";
import {
  clearHotelOverNightSearchText,
  setHotelOverNightSearchText,
} from "./hotelOverNightHeaderSearchActions";
import { getOverNightSearchText } from "./hotelOverNightHeaderSearchSelector";

const mapStateToProps = state => ({
  placeholder: simplePhrases.HOTEL_HEADER_SEARCH_BY,
  value: getOverNightSearchText(state),
});

const mapDispatchToProps = dispatch => ({
  onChange: e => dispatch(setHotelOverNightSearchText({ value: e.target.value })),
  clearText: () => dispatch(clearHotelOverNightSearchText()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
