import React, { useEffect } from "react";
import getUnknownPetImage from "core/utils/assetUtils/unknownPetImage";
import HotelDashboardCardIconContainer from "dux/hotelPetCardIcon/HotelDashboardCardIconContainer";
import { connect } from "react-redux";
import { PetImage } from "web/petCheckInOut/modals/checkInOutModalsLayout";
import { getHotelDashboardAppointmentByPetId } from "dux/hotelArrivals/columnList/hotelArivalsSelector";
import { getHotelItinerary } from "dux/hotelItinerary/hotelItinerarySelectors";
import moment from "moment";
import { createLoadingSelector } from "core/selectors/utils";
import {
  hotelDashBoardActionTypes,
  loadHotelDailyDashboard,
} from "dux/dashboard/hotel/hotelDashboardActions";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { GET_HOTEL_ITINERARY } from "dux/hotelItinerary/hotelItineraryActions";
import { getPetInfoOrMemoizedObject } from "dux/_components/checkInOutCart/appointmentDetails/petCardSelector";
import {
  selectHotelItineraryPetCardIconInfo,
  selectHotelProfilePetCardIconInfo,
} from "@/dux/hotelPetCardIcon/hotelPetCardIconSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getCardFields } from "@/dux/_components/petCard/HotelPetCardUtils";
import { getPetBreedName } from "@/core/selectors/entitiesSelector";
import calculateAge from "@/core/utils/dateUtils/calculateAge";
import { isEmpty } from "lodash";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { TextPassage } from "@petsmart-ui/sparky";

const PetCardComponent = props => {
  const {
    isHidden,
    componentId,
    petName,
    petAge,
    petBreedDescription,
    petWeight,
    speciesId,
    petId,
    iconInfo,
    loadAppointments,
    isLoadingAppointment,
    storeId,
    startDate,
    appointment,
  } = props;

  useEffect(() => {
    // If no appointment is found, get the appointments for the day of the itinerary
    if (isEmpty(appointment) && !isLoadingAppointment && storeId && startDate) {
      loadAppointments();
    }
  }, [petId, storeId, startDate]);

  // If missing iconInfo, show loading until daily appointments load.
  if (isLoadingAppointment) {
    return (
      <TextPassage size="lg" style={{ fontWeight: "bold" }}>
        Loading...
      </TextPassage>
    );
  }

  if (!isHidden) {
    return (
      <LayoutCluster id={componentId}>
        <PetImage src={getUnknownPetImage({ speciesId })} />

        <LayoutStack>
          <TextPassage size="lg">
            <b>{petName}, </b>
            {petAge}, {petBreedDescription}, {petWeight}
          </TextPassage>

          <HotelDashboardCardIconContainer iconInfo={iconInfo} />
        </LayoutStack>
      </LayoutCluster>
    );
  }

  return null;
};

export const HotelPetCardContainer = connect(
  (state, { petId }) => {
    const pet = getPetInfoOrMemoizedObject(state, { petId });
    const breed = getPetBreedName(state, { breedId: pet?.breedId });

    const hotelItinerary = getHotelItinerary(state);
    const startDate =
      hotelItinerary?.startDateTime &&
      moment.utc(hotelItinerary?.startDateTime).format("YYYY-MM-DD");
    const appointment =
      startDate &&
      getHotelDashboardAppointmentByPetId(state, {
        date: startDate,
      });
    const { iconInfo, cardInfo } = getCardFields(appointment || {});

    // Derive icon info from itinerary & pet profile state and get rest from dashboard appointment API
    const customerKey = getCurrentCustomerKey(state);
    const profileIconInfo = selectHotelProfilePetCardIconInfo(state, { petId, customerKey });
    const itineraryIconInfo = selectHotelItineraryPetCardIconInfo(state, { petId, customerKey });

    return {
      componentId: "HotelPetCardContainer",
      isLoadingAppointment: createLoadingSelector([
        GET_HOTEL_ITINERARY,
        hotelDashBoardActionTypes.LOAD_PETS_HOTEL_DAILY_DASHBOARD,
      ])(state),
      storeId: getStoreNumber(state),
      petName: pet?.petName ?? cardInfo?.petName,
      petAge: pet?.birthDate ? calculateAge(pet?.birthDate) : cardInfo?.petAge,
      petWeight: pet.weight ?? cardInfo?.petWeight,
      petBreedDescription: breed ?? cardInfo.petBreed,
      speciesId: pet?.speciesId,
      startDate,
      iconInfo: { ...iconInfo, ...profileIconInfo, ...itineraryIconInfo },
      appointment,
    };
  },
  dispatch => {
    return {
      callLoadAppointments: ({ storeId, startDate }) => {
        dispatch(loadHotelDailyDashboard({ storeId, selectedDate: startDate }));
      },
    };
  },
  (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,

    loadAppointments: () => {
      const { storeId, startDate } = propsFromState;
      const { callLoadAppointments } = propsFromDispatch;
      callLoadAppointments({ storeId, startDate });
    },
  }),
)(PetCardComponent);

export default HotelPetCardContainer;
