import { put, takeEvery, call, all, select, delay } from "redux-saga/effects";
import {
  patchReservationCartDetailsConfirmationFailure,
  patchReservationCartDetailsConfirmationRequest,
  patchReservationCartDetailsConfirmationSuccess,
  SAVE_RESERVATION_CART_DETAILS,
  PATCH_RESERVATION_CART_DETAILS,
  saveReservationCartDetailsConfirmationFailure,
  saveReservationCartDetailsConfirmationRequest,
  saveReservationCartDetailsConfirmationSuccess,
  showReservationCartDetailsConfirmation,
  clearPendingFood,
  clearPendingMed,
  clearPendingPrice,
  PATCH_HOTEL_ITINERARY_STATUS,
  patchHotelItineraryStatusSuccess,
  patchHotelItineraryStatusRequest,
  patchHotelItineraryStatusFailure,
  PATCH_HOTEL_ITINERARY_ROOM,
  PATCH_HOTEL_ITINERARY_CHECK_IN_STATUS,
  patchHotelItineraryRoomFailure,
  patchHotelItineraryRoomRequest,
  patchHotelItineraryRoomSuccess,
  PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE,
  patchHotelItineraryPriceOverrideFailure,
  patchHotelItineraryPriceOverrideRequest,
  patchHotelItineraryPriceOverrideSuccess,
} from "dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import {
  patchHotelItinerary,
  patchHotelItineraryStatusEndpoint,
  patchHotelItineraryRoomEndpoint,
  patchHotelPriceOverrideEndpoint,
} from "core/services/systemServicesBooking/getCreateUpdateDeleteHotelItineraryEndpoints";
import {
  separateHotelItineraryResponse,
  updateItineraryWithPendingChanges,
} from "dux/hotelItinerary/hotelItineraryUtils";
import { getConstructedHotelItinerary } from "dux/hotelItinerary/hotelConstructedItinerarySelectors";
import {
  getFirstHotelEngagement,
  selectIsAllPetsStatusCheckedIn,
} from "dux/hotelEngagements/hotelEngagementSelectors";
import { createBatchActions } from "core/utils/batchUtils";
import { getReservationConfirmationPendingPrice } from "dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationSelectors";
import { setHotelEngagement } from "dux/hotelEngagements/hotelEngagementActions";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { getCurrentItinerary } from "@/core/selectors/checkInOutSelector";
import { getHotelServiceCard } from "@/dux/hotelPrintServiceCardButton/hotelPrintServiceCardActions";
import { getHotelPriceAdjustmentReasons } from "../hotelPriceAdjustmentReasons/hotelPriceAdjustmentSelectors";
import {
  onPutHotelItinerary,
  saveHotelItineraryResponse,
} from "@/dux/hotelItinerary/hotelItinerarySaga";
import { getFoodUpdatesForItinerary } from "../_components/checkInOutFeeding/CheckInOutCartFoodSelectors";
import { getMedUpdatesForItinerary } from "../_components/checkInOutMedications/CheckInOutCartMedsSelectors";
import { history } from "../utils/browser/browserHistory";
import { routePaths } from "@/core/constants/routePaths";
import { selectCurrentStoreTimeZone } from "@/core/selectors/persistentSelectors";

function* onSaveReservationCartDetailsConfirmation({ petId }) {
  try {
    yield put(saveReservationCartDetailsConfirmationRequest());

    const itinerary = yield select(getConstructedHotelItinerary);

    const foods = yield select(getFoodUpdatesForItinerary, { petId });
    const meds = yield select(getMedUpdatesForItinerary, { petId });
    const timeZone = yield select(selectCurrentStoreTimeZone);

    // Construct itinerary and update it with pending changes,
    // the api is a put only and requires the full body.
    const itineraryPayload = updateItineraryWithPendingChanges({
      itinerary,
      foods,
      meds,
      currentPetId: petId,
      timeZone,
    });

    yield call(onPutHotelItinerary, { hotelItinerary: itineraryPayload });

    yield put(showReservationCartDetailsConfirmation({ isHidden: true }));

    // Clear any pending changes as they have now been saved
    yield put(clearPendingFood());
    yield put(clearPendingMed());
    yield put(clearPendingPrice());

    yield put(saveReservationCartDetailsConfirmationSuccess());
  } catch (error) {
    // We are currently batching actions so that we only trigger one render cycle when
    // updating Redux with our normalized data. This is to prevent triggering multiple render cycles,
    // which can lead to performance degradation.
    yield put(
      createBatchActions(
        saveReservationCartDetailsConfirmationFailure(error),
        showReservationCartDetailsConfirmation({ isHidden: false }),
      ),
    );
  }
}

function* onPatchReservationCartDetailsConfirmation({ hotelItineraryId, customerKey }) {
  try {
    yield put(patchReservationCartDetailsConfirmationRequest());

    // get first hotel engagement for an itinerary until logic for which engagement to pull is implemented
    const hotelEngagement = yield select(getFirstHotelEngagement);

    const pricing = yield select(getReservationConfirmationPendingPrice);
    const priceAdjustmentReasons = yield select(getHotelPriceAdjustmentReasons);
    const adjustmentReason =
      pricing?.reason &&
      priceAdjustmentReasons?.find(reason => reason?.label === pricing?.reason)?.value;

    const data = {
      itineraryId: hotelItineraryId,
      pets: [
        {
          petKey: hotelEngagement?.petId,
          engagements: [
            {
              engagementId: hotelEngagement?.engagementId,
              petService: {
                petServiceId: hotelEngagement?.petService?.petServiceId,
                petServiceProductId: hotelEngagement?.petService?.petServiceProductId,
                pricing: {
                  finalprice:
                    Number(pricing?.price) || hotelEngagement?.petService?.pricing.finalprice,
                  adjustmentReason:
                    adjustmentReason || hotelEngagement?.petService?.pricing.adjustmentReason,
                },
              },
            },
          ],
        },
      ],
    };

    // Update hotel Itinerary
    const response = yield call(patchHotelItinerary, {
      itineraryId: hotelItineraryId,
      customerKey,
      data,
    });

    // Parse and store hotel itinerary response
    yield call(saveHotelItineraryResponse, { responseData: response.data.result });

    yield put(showReservationCartDetailsConfirmation({ isHidden: true }));

    // Clear any pending changes as they have now been saved
    yield put(clearPendingFood());
    yield put(clearPendingMed());
    yield put(clearPendingPrice());

    yield put(patchReservationCartDetailsConfirmationSuccess());
  } catch (error) {
    // We are currently batching actions so that we only trigger one render cycle when
    // updating Redux with our normalized data. This is to prevent triggering multiple render cycles,
    // which can lead to performance degradation.
    yield put(
      createBatchActions(
        patchReservationCartDetailsConfirmationFailure(error),
        showReservationCartDetailsConfirmation({ isHidden: false }),
      ),
    );
  }
}

export function* onPatchHotelItineraryStatus({ itineraryId, data }) {
  try {
    yield put(patchHotelItineraryStatusRequest());

    const customerKey = yield select(getCurrentCustomerKey);

    const response = yield call(patchHotelItineraryStatusEndpoint, {
      itineraryId,
      customerKey,
      data,
    });

    // Parse and store hotel itinerary response
    yield call(saveHotelItineraryResponse, { responseData: response.data.result });

    yield put(patchHotelItineraryStatusSuccess());
  } catch (error) {
    yield put(patchHotelItineraryStatusFailure(error));
    return error;
  }

  return null;
}

/**
 *  Saga to update room number for an itinerary in check in/out flow
 *  @memberOf Sagas.hotel.itinerary
 *  @function
 *  @name onPatchHotelItineraryRoom
 *  @param { Object } data - request payload for updating room number
 */
function* onPatchHotelItineraryRoom({ data }) {
  try {
    yield put(patchHotelItineraryRoomRequest());

    const customerKey = yield select(getCurrentCustomerKey);
    const itineraryId = yield select(getCurrentItinerary);

    const response = yield call(patchHotelItineraryRoomEndpoint, {
      itineraryId,
      customerKey,
      data,
    });

    const { result } = response.data;
    const dataObj = separateHotelItineraryResponse(result);

    // send engagements to state
    yield put(setHotelEngagement(dataObj.engagements));

    // set data for patch room sucess
    yield put(patchHotelItineraryRoomSuccess());
  } catch (error) {
    yield put(patchHotelItineraryRoomFailure(error));
  }
}

/**
 *  Saga to update check in status and print service card
 *  @memberOf Sagas.hotel.itinerary
 *  @function
 *  @name onPatchHotelItineraryCheckInStatus
 *  @param { Object } itineraryId - Itinerary ID
 *  @param { Object } data - request payload to update check in status
 *  @param { number | null } petId - pet id used to print a service card for a specific pet if null all service cards will be printed
 */
function* onPatchHotelItineraryCheckInStatus({ itineraryId, data, petId = null }) {
  try {
    // Update Check in status
    yield call(onPatchHotelItineraryStatus, { itineraryId, data });
    yield delay(5000); // delay 5 sec so SF has time to process (SVCSART-33064)
    // Print Service Card
    yield put(getHotelServiceCard({ petId }));

    // If all pets are checked-in then redirect to check-out page
    const allCheckedIn = yield select(selectIsAllPetsStatusCheckedIn);
    if (allCheckedIn) {
      const customerKey = yield select(getCurrentCustomerKey);
      history.push(`${routePaths.CHECK_OUT}/${customerKey}/${itineraryId}`);
    }
  } catch (error) {
    yield put(patchHotelItineraryStatusFailure(error));
  }
}

/**
 * Saga to update price overrides for an itinerary in check in/out flow
 * @memberOf Sagas.hotel.itinerary
 * @function
 * @name onPatchHotelItineraryPriceOverride
 * @param {string} itineraryId - id of itinerary to update
 * @param {string} customerId - current customer key
 * @param {Object} data - request payload for updating room number
 */
function* onPatchHotelItineraryPriceOverride({ itineraryId, customerId, data }) {
  try {
    yield put(patchHotelItineraryPriceOverrideRequest());

    const response = yield call(patchHotelPriceOverrideEndpoint, {
      itineraryId,
      customerId,
      data,
    });

    // Parse and store hotel itinerary response
    yield call(saveHotelItineraryResponse, { responseData: response.data.result });

    yield put(patchHotelItineraryPriceOverrideSuccess());
  } catch (error) {
    yield put(patchHotelItineraryPriceOverrideFailure(error));
  }
}

function* watchSaveReservationCartDetailsConfirmation() {
  yield takeEvery(SAVE_RESERVATION_CART_DETAILS, onSaveReservationCartDetailsConfirmation);
  yield takeEvery(PATCH_RESERVATION_CART_DETAILS, onPatchReservationCartDetailsConfirmation);
}

function* watchOnPatchHotelItineraryStatus() {
  yield takeEvery(PATCH_HOTEL_ITINERARY_STATUS, onPatchHotelItineraryStatus);
  yield takeEvery(PATCH_HOTEL_ITINERARY_CHECK_IN_STATUS, onPatchHotelItineraryCheckInStatus);
}

function* watchOnPatchHotelItineraryRoom() {
  yield takeEvery(PATCH_HOTEL_ITINERARY_ROOM, onPatchHotelItineraryRoom);
}

function* watchOnPatchHotelItinerarPriceOverride() {
  yield takeEvery(PATCH_HOTEL_ITINERARY_PRICE_OVERRIDE, onPatchHotelItineraryPriceOverride);
}

export default function* reservationCartDetailsConfirmationSaga() {
  yield all([
    watchSaveReservationCartDetailsConfirmation(),
    watchOnPatchHotelItineraryStatus(),
    watchOnPatchHotelItineraryRoom(),
    watchOnPatchHotelItinerarPriceOverride(),
  ]);
}
