// REACT AND REDUX IMPORTS
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// LIBRARY IMPORTS
import moment from "moment";
import { isEmpty } from "lodash/fp";

// UI COMPONENT IMPORTS
import { Layout, TitledBox, Text, SearchableList } from "@prism/psm-ui-components";

// DUX RELATED IMPORTS
import {
  GET_HOTEL_ADDONS_BY_PET_SERVICE,
  getHotelAddOnsByPetService,
  getHotelAddonsList,
} from "dux/hotelAddons/hotelAddonsLIstActions";
import { getCurrentPet, getStoreNumber } from "core/selectors/persistentSelectors";
import {
  selectHotelAddOnsByPetService,
  selectHotelAddonsListSearchValue,
  selectHotelBookingAddonListTitle,
} from "dux/hotelAddons/hotelAddonsLIstSelectors";
import {
  getFirstHotelEngagementByPet,
  selectAddonByPetAndAddonId,
  selectAddonTotalPriceByGroupingId,
  selectAddonTotalQtyByGroupingId,
  selectCurrentHotelEngagement,
  selectCurrentHotelPetService,
} from "dux/hotelEngagements/hotelEngagementSelectors";

// OTHER IMPORTS
import { formatMoment } from "core/utils/dateUtils/formatDateTime";
import {
  MultiDayHotelBookingAddonModal,
  MultiDayHotelCartCheckInOutAddonModal,
  SingleDayHotelBookingAddonModal,
  SingleDayHotelCartCheckInOutAddonModal,
} from "dux/hotelAddonsModal/HotelAddonsModal";
import { createLoadingSelector } from "core/selectors/utils";
import { AddonsListAppliedAddonButton } from "dux/hotelAddons/AddonsListAppliedAddonButton";
import { AddonListAddButton } from "dux/hotelAddons/AddonsListAddButton";
import CommonModal from "web/common/modals/commonModal";
import {
  HotelBookingCartAddonReturnButton,
  HotelCheckInOutCartAddonReturnButton,
} from "dux/hotelCartAddons/HotelCartAddAddonReturnButton";
import {
  selectHotelBookingStartDate,
  selectIsOvernight,
  selectPetSelectedService,
} from "web/features/hotelBookingFlow/hotelBookingFlowSelectors";
import {
  selectCartProductIsMissingDates,
  selectHotelCartPetProducts,
  selectHotelItineraryIdFromCart,
} from "dux/servicesCartHotel/servicesCartHotelSelectors";
import { formatMoney } from "../_utils/moneyUtil";
import { getPendingAppointmentServicesByPet } from "@/web/pendingAppointment/selectors/pendingAppointmentSelectors";
import { OVERNIGHT_TITLE, SINGLE_DAY_TITLE } from "./hotelAddonsListConstants";
import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { HotelAddonListItem, HotelEnhancedAddon } from "./HotelAddonListItem";
import { getHotelItinerary } from "../hotelItinerary/hotelItinerarySelectors";

/**
 * Formats the columns of price, qty, & total for the addon list, the default args are the column headers
 * @param {string} price - a formatted string of the addons price
 * @param {string} qty - the addons quantity
 * @param {string} total - a formatted string of the addons total price
 * @returns
 */
export const renderAddonListPricing = (price = "Price", qty = "Qty", total = "Total") => {
  const sharedStyles = {
    width: "15%",
  };

  return (
    <>
      <Text style={{ marginLeft: "auto", ...sharedStyles }} align="center">
        {price}
      </Text>
      <Text style={sharedStyles} align="center">
        {qty}
      </Text>
      <Text style={sharedStyles} align="center">
        {total}
      </Text>
    </>
  );
};

/**
 * Creates a stack of addon buttons, and determines if the button should show as an add button or an edit button if there are appliedAddons
 * @param {Function} findAppliedAddon - a function that will return the applied addon that has the same id as addon
 * @param {array} addon - List of all addons
 * @param {string} id - Id for the component
 * @return {JSX.Element}
 */
const setAddonListButton = (
  findAppliedAddon,
  addon,
  id = "AddonListButton",
  onClick = () => {},
  checkAppliedAddonError,
) => {
  const appliedAddon = findAppliedAddon(addon.addOnId);
  const price = formatMoney(addon.price);
  const qty = appliedAddon?.quantity || "0";
  const appliedPrice = formatMoney(appliedAddon?.finalPrice ?? 0);
  const pricing = renderAddonListPricing(price, qty, appliedPrice);

  const addonNameComp = appliedAddon ? AddonsListAppliedAddonButton : AddonListAddButton;

  return (
    <HotelAddonListItem
      key={addon?.addOnId}
      componentId={id}
      addonNameComp={addonNameComp}
      addonListPricing={pricing}
      name={addon?.addOnName}
      onClick={onClick}
      hasError={checkAppliedAddonError(addon?.addOnId)}
    />
  );
};

export const EnhancedAddons = ({
  addons,
  componentId,
  findAppliedAddon,
  setShowingAddonId,
  petId,
}) => {
  if (!addons?.length) return null;

  return (
    <TitledBox id={`${componentId}__enhanced`} title="Enhanced Add-ons" isSection>
      <Layout.Stack space="stack-space-4">
        <Layout.Cluster>{renderAddonListPricing()}</Layout.Cluster>
        {addons?.map(addon => (
          <HotelEnhancedAddon
            key={addon?.addOnId}
            addon={addon}
            appliedAddon={findAppliedAddon(addon?.addOnId)}
            onClick={() => setShowingAddonId(addon.addOnId)}
            petId={petId}
          />
        ))}
      </Layout.Stack>
    </TitledBox>
  );
};

/**
 * @param {Object} obj - destructed params.
 * @param {String} obj.componentId
 * @param {Boolean} obj.isHidden
 * @param {String} obj.title
 * @param {String} obj.petId
 * @param {String} obj.petServiceId
 * @param {Boolean} obj.hasActionableService
 * @param {[]} obj.addons
 * @param {Object} obj.appliedAddons
 * @param obj.loadHotelAddOnsByPetService
 * @param obj.onChange
 * @return {JSX.Element|null}
 */
export const AddonsLIst = ({
  componentId,
  isHidden,
  isLoading,
  title,
  petId,
  petServiceId,
  hasActionableService,
  loadHotelAddOnsByPetService,
  searchValue = "",
  onChange,
  addons,
  findAppliedAddon = () => {},
  checkAppliedAddonError = () => false,
  isFromCart,
  diComp,
  compName,
  onClose,
}) => {
  // Sets which addon should be presented by the modal by setting ID.
  const [showingAddonId, setShowingAddonId] = useState(null);

  // Make call to the getHotelAddOnsByPetService api if any of of the dependencies update
  useEffect(() => {
    if (!isHidden) {
      loadHotelAddOnsByPetService({ petId, petServiceId });
    }
  }, [petId, petServiceId, hasActionableService, isHidden]);

  // If pet changes then reset the search value
  useEffect(() => {
    onChange({ target: { value: "" } });
  }, [petId]);

  if (isHidden) {
    return null;
  }

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const UpdateSaveAddonComponent = diComp ? diComp[compName] : null;
  const BackButton = diComp?.backBtn;

  if (isFromCart && showingAddonId) {
    return (
      <Layout.Stack space="stack-space-4">
        <BackButton onClose={() => setShowingAddonId(null)} />
        <UpdateSaveAddonComponent
          onClose={onClose}
          setShowingAddonId={setShowingAddonId}
          showingAddonId={showingAddonId}
          petId={petId}
        />
      </Layout.Stack>
    );
  }

  const renderAddonListItem = addon =>
    setAddonListButton(
      findAppliedAddon,
      addon,
      componentId,
      () => setShowingAddonId(addon.addOnId),
      checkAppliedAddonError,
    );

  /**
   * Component's Main JSX
   */
  return (
    <>
      <SearchableList
        id={componentId}
        title={title}
        isDivided
        type="search"
        onChange={onChange}
        value={searchValue}
      >
        {/* ENHANCED ADDONS */}
        <EnhancedAddons
          addons={addons?.enhanced}
          componentId={componentId}
          findAppliedAddon={findAppliedAddon}
          setShowingAddonId={setShowingAddonId}
          petId={petId}
        />

        {/* MOST POPULAR ADDONS */}
        <TitledBox id={`${componentId}__mostPopular`} title="Most Popular" isSection>
          <Layout.Stack space="stack-space-4">
            <Layout.Cluster>{renderAddonListPricing()}</Layout.Cluster>
            {addons.mostPopular.map(addon => renderAddonListItem(addon))}
          </Layout.Stack>
        </TitledBox>

        {/* OTHER ADDONS */}
        <TitledBox id={`${componentId}__otherAddons`} title="Other Add-ons" isSection>
          <Layout.Stack space="stack-space-4">
            <Layout.Cluster>{renderAddonListPricing()}</Layout.Cluster>
            {addons.mainAddOns.map(addon => renderAddonListItem(addon))}
          </Layout.Stack>
        </TitledBox>
      </SearchableList>

      {/* ADDON MODAL */}
      {UpdateSaveAddonComponent && (
        <CommonModal
          isHidden={!showingAddonId}
          componentId={componentId}
          onClose={() => {
            setShowingAddonId(null);
          }}
        >
          <UpdateSaveAddonComponent
            setShowingAddonId={setShowingAddonId}
            showingAddonId={showingAddonId}
            petId={petId}
          />
        </CommonModal>
      )}
    </>
  );
};

/**
 * Get the addon data to be displayed
 *
 * @function
 * @name findAppliedAddonFromCartAndFormatForUI
 * @param {Array} appliedAddons - array of addons for a pet from the cart api
 * @param {string} addOnId - productId of addon to find within appliedAddons
 * @returns an object containing the quantity and final price of the addon to be displayed
 * @example findAppliedAddonFromCartAndFormatForUI(appliedAddons, addOnId)
 */
export const findAppliedAddonFromCartAndFormatForUI = (appliedAddons, addOnId) =>
  Object.values(appliedAddons)
    ?.map(({ productId, pricing }) => ({
      productId,
      quantity: pricing?.quantity,
      finalPrice: pricing?.finalPrice,
    }))
    ?.find(addon => addon.productId === addOnId);

// HOTEL BOOKING ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingAddons = connect(
  (state, { petId, isActive }) => {
    const itineraryId = selectHotelItineraryIdFromCart(state);
    const petServiceId = selectPetSelectedService(petId)(state);
    const checkInDate = selectHotelBookingStartDate(state);
    const hasPetServiceId = !!petServiceId;
    const hasActionableService = isActive && hasPetServiceId;
    const addons = selectHotelAddOnsByPetService(state);
    const appliedAddons = selectHotelCartPetProducts(petId)(state);
    const isOvernight = selectIsOvernight(state);

    return {
      componentId: "HotelBookingAddons",
      isHidden: !hasActionableService || !itineraryId,
      title: selectHotelBookingAddonListTitle(state),
      isDivided: true,
      type: "search",
      compName: "HOTEL",
      diComp: {
        HOTEL: isOvernight ? MultiDayHotelBookingAddonModal : SingleDayHotelBookingAddonModal,
        backBtn: HotelBookingCartAddonReturnButton,
      },
      petId,
      petServiceId,
      hasActionableService,
      addons,
      findAppliedAddon: addOnId => findAppliedAddonFromCartAndFormatForUI(appliedAddons, addOnId),
      isLoading: createLoadingSelector([GET_HOTEL_ADDONS_BY_PET_SERVICE])(state),
      checkAppliedAddonError: productId =>
        selectCartProductIsMissingDates({ petId, productId })(state),
      searchValue: selectHotelAddonsListSearchValue(state),

      // merge props
      checkInDate,
      storeNumber: getStoreNumber(state),
    };
  },

  dispatch => {
    return {
      /**
       * dispatch action to update search characters in global state.
       * @param {Object} obj - destructed params.
       * @param {string} obj.searchValue
       */
      dispatchOnChange: ({ searchValue }) => {
        // specific to Hotel
        dispatch(getHotelAddonsList({ searchValue }));
      },

      /**
       * dispatch action to call the "Get Hotel AddOns by Pet Service" api
       * @param {Object} obj - destructed params.
       * @param {string} obj.petServiceId
       * @param {string} obj.petId
       * @param {string} obj.checkInDate
       * @param {boolean} obj.hasActionableService
       */
      dispatchGetHotelAddOnsByPetService: ({
        storeNumber,
        petServiceId,
        petId,
        checkInDate,
        hasActionableService,
      }) => {
        // updating the petServiceId, PetId may invoke this function, we do a check to verify there is a ActionableService in order
        // invoke the action/api call
        if (hasActionableService) {
          dispatch(
            getHotelAddOnsByPetService({
              storeNumber,
              petServiceId,
              petId,
              checkInDate: formatMoment(moment(checkInDate).utc()),
            }),
          );
        }
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      id,
      title,
      isHidden,
      isDivided,
      petId,
      petServiceId,
      checkInDate,
      hasActionableService,
      addons,
      findAppliedAddon,
      compName,
      diComp,
      isLoading,
      checkAppliedAddonError,
      storeNumber,
      searchValue,
    } = mapProps;
    const { dispatchOnChange, dispatchGetHotelAddOnsByPetService } = dispatchProps;
    return {
      // control what props get passed to the view
      id,
      title,
      isHidden,
      isDivided,
      petId,
      petServiceId,
      hasActionableService,
      addons,
      findAppliedAddon,
      compName,
      diComp,
      isLoading,
      checkAppliedAddonError,
      searchValue,
      // actions to pass to view
      onChange: searchValue => dispatchOnChange({ searchValue: searchValue.target.value }),
      loadHotelAddOnsByPetService: ({ petId, petServiceId }) => {
        dispatchGetHotelAddOnsByPetService({
          storeNumber,
          petServiceId,
          petId,
          checkInDate,
          hasActionableService,
        });
      },
    };
  },
)(AddonsLIst);

// HOTEL BOOKING CART ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingCartAddons = connect(
  (state, { petId, onClose }) => {
    const itineraryId = selectHotelItineraryIdFromCart(state);
    const petServiceId = selectPetSelectedService(petId)(state);
    const checkInDate = selectHotelBookingStartDate(state);
    const hasServices = !isEmpty(getPendingAppointmentServicesByPet({ petId })(state));
    const hasPetServiceId = !!petServiceId;
    const hasActionableService = hasPetServiceId && hasServices;
    const addons = selectHotelAddOnsByPetService(state);
    const appliedAddons = selectHotelCartPetProducts(petId)(state);
    const isOvernight = selectIsOvernight(state);

    return {
      componentId: "HotelBookingCartAddons",
      isHidden: !hasActionableService || !itineraryId,
      title: selectHotelBookingAddonListTitle(state),
      isDivided: true,
      type: "search",
      compName: "HOTEL",
      diComp: {
        HOTEL: isOvernight ? MultiDayHotelBookingAddonModal : SingleDayHotelBookingAddonModal,
        backBtn: HotelBookingCartAddonReturnButton,
      },
      petId,
      petServiceId,
      hasActionableService,
      addons,
      findAppliedAddon: addOnId => findAppliedAddonFromCartAndFormatForUI(appliedAddons, addOnId),
      isFromCart: true,
      onClose,
      isLoading: createLoadingSelector([GET_HOTEL_ADDONS_BY_PET_SERVICE])(state),
      checkAppliedAddonError: productId =>
        selectCartProductIsMissingDates({ petId, productId })(state),
      searchValue: selectHotelAddonsListSearchValue(state),
      // merge props
      checkInDate,
      storeNumber: getStoreNumber(state),
    };
  },

  dispatch => {
    return {
      /**
       * dispatch action to update search characters in global state.
       * @param {Object} obj - destructed params.
       * @param {string} obj.searchValue
       */
      dispatchOnChange: ({ searchValue }) => {
        // specific to Hotel
        dispatch(getHotelAddonsList({ searchValue }));
      },

      /**
       * dispatch action to call the "Get Hotel AddOns by Pet Service" api
       * @param {Object} obj - destructed params.
       * @param {string} obj.petServiceId
       * @param {string} obj.petId
       * @param {string} obj.checkInDate
       * @param {boolean} obj.hasActionableService
       */
      dispatchGetHotelAddOnsByPetService: ({
        storeNumber,
        petServiceId,
        petId,
        checkInDate,
        hasActionableService,
      }) => {
        // updating the petServiceId, PetId may invoke this function, we do a check to verify there is a ActionableService in order
        // invoke the action/api call
        if (hasActionableService) {
          dispatch(
            getHotelAddOnsByPetService({
              storeNumber,
              petServiceId,
              petId,
              checkInDate: formatMoment(moment(checkInDate).utc()),
            }),
          );
        }
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      id,
      title,
      isHidden,
      isDivided,
      petId,
      petServiceId,
      checkInDate,
      hasActionableService,
      addons,
      findAppliedAddon,
      compName,
      diComp,
      isFromCart,
      isLoading,
      onClose,
      checkAppliedAddonError,
      storeNumber,
      searchValue,
    } = mapProps;
    const { dispatchOnChange, dispatchGetHotelAddOnsByPetService } = dispatchProps;
    return {
      // control what props get passed to the view
      id,
      title,
      isHidden,
      isDivided,
      petId,
      petServiceId,
      hasActionableService,
      addons,
      findAppliedAddon,
      compName,
      diComp,
      isFromCart,
      onClose,
      isLoading,
      checkAppliedAddonError,
      searchValue,

      // actions to pass to view
      onChange: searchValue => dispatchOnChange({ searchValue: searchValue.target.value }),
      loadHotelAddOnsByPetService: ({ petId, petServiceId }) =>
        dispatchGetHotelAddOnsByPetService({
          storeNumber,
          petServiceId,
          petId,
          checkInDate,
          hasActionableService,
        }),
    };
  },
)(AddonsLIst);

// HOTEL CHECK IN/OUT ADDON CONTAINER ----------------------------------------------------------------------------------------
export const HotelCheckInOutAddons = connect(
  state => {
    const petId = getCurrentPet(state);
    const petServiceId = selectCurrentHotelPetService(state, { petId })?.petServiceId;
    const checkInDate = getFirstHotelEngagementByPet(state, { petId })?.startDatetime;
    const hasPetServiceId = !!petServiceId;
    const hasActionableService = hasPetServiceId;

    const addons = selectHotelAddOnsByPetService(state);

    const engagementType = selectCurrentHotelEngagement(state, { petId })?.engagementType;
    const isOvernight = engagementType === engagementTypes.OVERNIGHT_BOARDING;

    return {
      componentId: "HotelCheckInOutAddons",
      isHidden: !hasActionableService,
      isLoading: createLoadingSelector([GET_HOTEL_ADDONS_BY_PET_SERVICE])(state),
      title: isOvernight ? OVERNIGHT_TITLE : SINGLE_DAY_TITLE,
      isDivided: true,
      type: "search",
      petId,
      petServiceId,
      hasActionableService,
      addons,
      findAppliedAddon: addOnId => {
        const appliedAddon = selectAddonByPetAndAddonId(addOnId)(state, { petId });
        if (isEmpty(appliedAddon)) return;
        return {
          addOnProductNumber: addOnId,
          quantity: selectAddonTotalQtyByGroupingId(appliedAddon?.groupingId)(state, { petId }),
          finalPrice: selectAddonTotalPriceByGroupingId(appliedAddon?.groupingId)(state, { petId }),
        };
      },
      compName: "HOTEL",
      diComp: {
        HOTEL: isOvernight
          ? MultiDayHotelCartCheckInOutAddonModal
          : SingleDayHotelCartCheckInOutAddonModal,
        backBtn: HotelCheckInOutCartAddonReturnButton,
      },
      isFromCart: true,
      searchValue: selectHotelAddonsListSearchValue(state),
      // merge props
      checkInDate,
      storeNumber: getHotelItinerary(state)?.storeNumber,
    };
  },

  dispatch => {
    return {
      /**
       * dispatch action to update search characters in global state.
       * @param {Object} obj - destructed params.
       * @param {string} obj.searchValue
       */
      dispatchOnChange: ({ searchValue }) => {
        // specific to Hotel
        dispatch(getHotelAddonsList({ searchValue }));
      },

      /**
       * dispatch action to call the "Get Hotel AddOns by Pet Service" api
       * @param {Object} obj - destructed params.
       * @param {string} obj.petServiceId
       * @param {string} obj.petId
       * @param {string} obj.checkInDate
       * @param {boolean} obj.hasActionableService
       */
      dispatchGetHotelAddOnsByPetService: ({
        storeNumber,
        petServiceId,
        petId,
        checkInDate,
        hasActionableService,
      }) => {
        // updating the petServiceId, PetId may invoke this function, we do a check to verify there is a ActionableService in order
        // invoke the action/api call
        if (hasActionableService) {
          dispatch(
            getHotelAddOnsByPetService({
              storeNumber,
              petServiceId,
              petId,
              checkInDate: formatMoment(moment(checkInDate).utc()),
            }),
          );
        }
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      id,
      title,
      isHidden,
      isLoading,
      isDivided,
      petId,
      petServiceId,
      checkInDate,
      hasActionableService,
      addons,
      findAppliedAddon,
      compName,
      diComp,
      isFromCart,
      storeNumber,
      searchValue,
    } = mapProps;
    const { dispatchOnChange, dispatchGetHotelAddOnsByPetService } = dispatchProps;
    return {
      // control what props get passed to the view
      id,
      title,
      isHidden,
      isLoading,
      isDivided,
      petId,
      petServiceId,
      hasActionableService,
      addons,
      findAppliedAddon,
      compName,
      diComp,
      isFromCart,
      searchValue,
      // actions to pass to view
      onChange: searchValue => dispatchOnChange({ searchValue: searchValue.target.value }),
      loadHotelAddOnsByPetService: ({ petId, petServiceId }) =>
        dispatchGetHotelAddOnsByPetService({
          storeNumber,
          petServiceId,
          petId,
          checkInDate,
          hasActionableService,
        }),
    };
  },
)(AddonsLIst);
