/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const DELETE_CHECK_IN_FEEDING = "DELETE_CHECK_IN_FEEDING";
export const DELETE_CHECK_IN_FEEDING_REQUEST = "DELETE_CHECK_IN_FEEDING_REQUEST";
export const DELETE_CHECK_IN_FEEDING_SUCCESS = "DELETE_CHECK_IN_FEEDING_SUCCESS";
export const DELETE_CHECK_IN_FEEDING_FAILURE = "DELETE_CHECK_IN_FEEDING_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const deleteCheckInFeeding = ({ petId, foodId }) => ({
  type: DELETE_CHECK_IN_FEEDING,
  petId,
  foodId,
});

export const deleteCheckInFeedingRequest = () => ({
  type: DELETE_CHECK_IN_FEEDING_REQUEST,
});

export const deleteCheckInFeedingSuccess = () => ({
  type: DELETE_CHECK_IN_FEEDING_SUCCESS,
});

export const deleteCheckInFeedingFailure = error => ({
  type: DELETE_CHECK_IN_FEEDING_FAILURE,
  error,
});
