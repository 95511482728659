import { authAPI } from "./_axiosConfigAuth";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get authorized users for store
 * From the System Associate Auth API
 * - Get users who have been authorized today
 * @param {*} { storeNumber, clientSessionToken }
 * @returns AxiosPromise
 */
export function fetchAuthorizedUsers({ storeNumber, clientSessionToken }) {
  return authAPI.get(`${endpointVersionString()}/stores/${storeNumber}/users`, {
    headers: { "x-client-session-token": clientSessionToken },
  });
}

/**
 * Authorize user by PIN
 * from the System Associate Auth API
 * - Authorize user by PIN
 * @param {*} { storeNumber, Username, pin }
 * @returns AxiosPromise
 */
export function authorizeUserByPin({ storeNumber, Username, pin }) {
  return authAPI.post(`${endpointVersionString()}/pins/authorize`, {
    storeNumber,
    Username,
    pin,
  });
}

/**
 * Set a user's PIN using their Okta access token
 * From the  System Associate Auth API
 * - Set a user's PIN
 * @param {*} { accessToken, pin }
 * @returns AxiosPromise
 */
export function setOrUpdateUserPin({ accessToken, pin }) {
  return authAPI.post(`${endpointVersionString()}/pins`, { accessToken, pin });
}

/**
 * Log out user for store by deleting user's token record
 * From the  System Associate Auth API
 * - Set a user's PIN
 * @param {*} { username, storeNumber, clientSessionToken }
 * @returns AxiosPromise
 */
export function logoutUser({ username, storeNumber, clientSessionToken }) {
  return authAPI.delete(`${endpointVersionString()}/stores/${storeNumber}/users/${username}`, {
    headers: { "x-client-session-token": clientSessionToken },
  });
}

/**
 * Log out authorized users for store
 * From the  System Associate Auth API
 * - Delete token records for authorized users at store
 * @param {*} { storeNumber, sessionToken }
 * @returns AxiosPromise
 */
export function logoutStore({ storeNumber, sessionToken }) {
  return authAPI.delete(`${endpointVersionString()}/stores/${storeNumber}/users`, {
    headers: { "x-session-token": sessionToken },
  });
}

/**
 * Refresh a token
 * System Associate Auth API
 * - Refresh a token
 * @param {*} { sessionToken: SessionToken }
 * @returns AxiosPromise
 */
export function refreshSessionToken({ sessionToken: SessionToken, duration }) {
  return authAPI.post(`${endpointVersionString()}/tokens/refresh`, {
    SessionToken,
    duration,
  });
}

/**
 * Validate a token
 * System Associate Auth API
 * - Check if the session token passed is valid
 * @param {*} { sessionToken: SessionToken }
 * @returns AxiosPromise
 */
export function validateSessionToken({ sessionToken: SessionToken }) {
  return authAPI.post(`${endpointVersionString()}/tokens/validate`, {
    SessionToken,
  });
}
