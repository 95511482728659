import { SET_OVERBOOKING_EXCEPTION_PAGINATION } from "./overbookingExceptionsPaginationActions";

const initialState = {};

/**
 * @name overbookingExceptionsPaginationReducer
 * @memberof Reducer.Overbooking
 * @param state
 * @param {object} pagination
 * @param {string} pagination.type
 * @param {number} pagination.count - Number of items currently displayed
 * @param {number} pagination.page - Current Page
 * @param {number} pagination.total - Total number items available
 * @param {number} pagination.size - Total size of items that can currently be displayed
 * @returns {{total: number, size: number, count: number, page: number}|{}}
 */
const overbookingExceptionsPaginationReducer = (
  state = initialState,
  { type, count, page, total, size },
) => {
  switch (type) {
    case SET_OVERBOOKING_EXCEPTION_PAGINATION:
      return {
        ...state,
        count,
        page,
        total,
        size,
      };
    default:
      return state;
  }
};

export default overbookingExceptionsPaginationReducer;
