import { font } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import SetInputFontColor from "./setInputFontColor";
import SetTextInputBorder from "./setTextInputBorder";
import SetTextAlignment from "../general/setTextAlignment";
import SetCursor from "../general/setCursor";
import SetOpacity from "../general/setOpacity";
import SetPointerEvent from "../general/setPointerEvent";

export default function TextInputTheme(props) {
  const { error, centerText, isLink, backgroundColor, disabled, isStatic } = props;

  return {
    border: "none",
    borderBottom: SetTextInputBorder(error),
    fontSize: fontSizes.regular,
    fontFamily: font.mainFamily,
    textAlign: SetTextAlignment({ centerText }),
    lineHeight: fontSizes.extraBig,
    color: SetInputFontColor({ error, isLink, isStatic }),
    cursor: SetCursor({ isLink, isStatic }),
    backgroundColor: backgroundColor || "#FFF",
    opacity: SetOpacity({ disabled }),
    pointerEvents: SetPointerEvent({ disabled }),
    outline: "none",
  };
}
