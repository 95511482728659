import { connect } from "react-redux";
import { getPackagesByType } from "../bulkPackageOfferingsSelectors";
import { getIsSelectedBulkPackageOfferingDropdownSelected } from "../../../toggleArrow/toggleArrowSelectors";
import { BGM, FTCO } from "@/web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";
import BulkPackageOfferingsContentComponent from "../../../_components/bgm/bulkPackageOfferings/BulkPackageOfferingsContentComponent";
import getIsBGMWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getFTCOWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";

function getWorkflowToDisplay(isBGMWorkflowFeatureFlagHidden, isFTCOWorkflowFeatureFlagHidden) {
  if (isBGMWorkflowFeatureFlagHidden === false && isFTCOWorkflowFeatureFlagHidden === false) {
    // Return all packages (BGM & FTCO). We do this by not specifying a workflow type.
    return null;
  }

  if (isBGMWorkflowFeatureFlagHidden === false) {
    return BGM;
  }

  return FTCO;
}

const mapStateToProps = (state, { petServiceId }) => {
  const isBGMWorkflowFeatureFlagHidden = getIsBGMWorkflowFeatureFlagHidden(state);
  const isFTCOWorkflowFeatureFlagHidden = getFTCOWorkflowFeatureFlagHidden(state);
  const type = getWorkflowToDisplay(
    isBGMWorkflowFeatureFlagHidden,
    isFTCOWorkflowFeatureFlagHidden,
  );

  return {
    petServiceId,
    isHidden: !getIsSelectedBulkPackageOfferingDropdownSelected(state, { petServiceId }),
    componentID: `bulk-package-offerings-group-rows-${petServiceId}`,
    items: getPackagesByType({ state, type, petServiceId }),
  };
};

export default connect(mapStateToProps)(BulkPackageOfferingsContentComponent);
