import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { clearEligibilityResults } from "dux/eligibility/actions/eligibilityLoadClearActions";

const mapDispatchToProps = dispatch => ({
  clearEligibilityResults: () => dispatch(clearEligibilityResults()),
});

const withEligibility = WrappedComponent => {
  return class withEligibilityHOC extends React.Component {
    componentDidMount() {
      const { onCallEligibility } = this.props;
      onCallEligibility && onCallEligibility();
    }

    componentWillUnmount() {
      const { clearEligibilityResults } = this.props;
      clearEligibilityResults && clearEligibilityResults();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default compose(connect(null, mapDispatchToProps), withEligibility);
