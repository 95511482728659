import { connect } from "react-redux";
import { headerConfigConstants } from "core/constants/headerConfigConstants";
import { getJobRole } from "core/selectors/persistentSelectors";
import HeaderImage from "../../assets/images/hotel-int-header-image.png";
import AppHeader from "web/header/AppHeader";

// DDC Service Management header container ----------------------------------------------------------------------------------------
export const DDCServiceManagementHeader = connect(state => {
  // const someProp = someSelector(state);

  return {
    componentId: "DDCServiceManagementHeader",
    title: "Services Manager",
    config: headerConfigConstants.APPOINTMENT,
    // jobRole: getJobRole(state, ownProps),
    backgroundImage: HeaderImage,
  };
})(AppHeader);
