import { connect } from "react-redux";
import { clearFoodFrequency } from "dux/bookingFeeding/setFoodFrequency/actions/foodFrequencyActions";
import { getCheckForSameDay } from "@/dux/frequency/dateRangeForFrequencySelectors";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { isFromBooking } from "@/core/utils/validationUtils/isFromValidation";
import SelectWithWrapper from "../common/SelectWithWrapper";
import { MAX_CHECK_IN_OPTIONS } from "../../core/constants/bookingConstants";
import { setFoodType } from "./actions/setFoodTypeActions";
import { getFoodTypeByExternalId } from "./foodTypeSelectors";
import { FOOD_TYPE_OPTIONS } from "./foodTypeConstants";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../core/constants/validationErrors";
import { setFoodName } from "../foodName/actions/setFoodNameActions";
import getIsHotelWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/hotel/getIsHotelWorkflowFeatureFlagHidden";
import { setFoodTypeAmount } from "../foodTypeAmount/actions/foodTypeAmountActions";
import { setFoodTimeOfDay } from "../foodTimeOfDay/actions/foodTimeOfDayActions";
import { setFoodInstructions } from "../foodInstructions/actions/foodInstructionsActions";
import { getFoodIsDiscontinuedById } from "../food/foodsSelector";

const mapStateToProps = (state, ownProps) => {
  const { foodId, foodIndex, petId, router } = ownProps;
  const inBooking = isFromBooking(router?.location?.pathname);
  const isSameDayBooking = inBooking && getCheckForSameDay(state);

  return {
    foodId,
    petId,
    title: `Food type ${foodIndex}`,
    options: getIsHotelWorkflowFeatureFlagHidden(state) ? MAX_CHECK_IN_OPTIONS : FOOD_TYPE_OPTIONS,
    value: getFoodTypeByExternalId(state, { foodId, petId }),
    error: REQUIRED_FIELD_ERROR_MESSAGE,
    shouldClearFrequency: !isSameDayBooking,
    disabled: getFoodIsDiscontinuedById(state, { petId, foodId }),
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeFoodType: ({ option, foodId, petId, shouldClearFrequency }) => {
    dispatch(setFoodType({ foodType: option.value, foodId, petId }));
    dispatch(
      setFoodName({
        name: "",
        externalId: "",
        foodId,
        petId,
      }),
    );
    dispatch(setFoodTypeAmount({ foodTypeAmount: "", foodId, petId }));
    dispatch(setFoodTimeOfDay({ timeOfDay: [], foodId, petId }));
    if (shouldClearFrequency) dispatch(clearFoodFrequency({ foodId, petId }));
    dispatch(setFoodInstructions({ foodInstructions: "", foodId, petId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onChange: option => {
    const { foodId, petId, shouldClearFrequency } = propsFromState;
    propsFromDispatch.onChangeFoodType({ option, foodId, petId, shouldClearFrequency });
  },
});

export default withRouteProps(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(SelectWithWrapper),
);
