import React from "react";
import { Layout } from "@prism/psm-ui-components";
import { DDCPetDetailsContainer } from "dux/_components/ddcPetDetails/DDCPetDetailsComponent";
import { connect } from "react-redux";
import { getSelectedPet } from "core/selectors/bookingUISelectors";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import PetFavorabilityHotelContainer from "dux/petFavorability/PetFavorabilityHotelContainer";
import Section from "../../common/Section";
import PetForm from "../../petParentProfile/pets/petForm/PetFormContainer";
import { PetVaccinations } from "@/dux/vaccinations/PetVaccinations";
import { HotelPetNotesV2 } from "dux/notes/Notes";

const HotelPetProfileDetailsComponents = props => {
  const { customerKey, selectedPet, isHidden, componentID } = props;

  if (!isHidden) {
    return (
      <Layout.Box padding="box-padding-8" id={componentID}>
        <Section paddingTop="0">
          <PetForm customerKey={customerKey} petId={selectedPet} isBooking />
        </Section>

        <PetVaccinations />

        <PetFavorabilityHotelContainer topLine customerKey={customerKey} petId={selectedPet} />

        <DDCPetDetailsContainer />

        <HotelPetNotesV2 />
      </Layout.Box>
    );
  }

  return null;
};

export const HotelPetProfileDetails = connect(state => {
  return {
    selectedPet: getSelectedPet(state),
    customerKey: getCurrentCustomerKey(state),
  };
})(HotelPetProfileDetailsComponents);
