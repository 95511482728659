/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA =
  "SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA";
export const SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA_REQUEST =
  "SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA_REQUEST";
export const SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA_SUCCESS =
  "SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA_SUCCESS";
export const SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA_FAILURE =
  "SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setSuspensionScheduleFilterButtonData = ({ data }) => ({
  type: SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA,
  data,
});

export const setSuspensionScheduleFilterButtonDataRequest = () => ({
  type: SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA_REQUEST,
});

export const setSuspensionScheduleFilterButtonDataSuccess = ({ data }) => ({
  type: SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA_SUCCESS,
  data,
});

export const setSuspensionScheduleFilterButtonDataFailure = error => ({
  type: SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA_FAILURE,
  error,
});
