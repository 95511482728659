import { connect } from "react-redux";
import SmallPillButton from "../../../_components/buttons/SmallPillButton";
import { getHotelArrivalsFilterBy } from "./hotelArrivalsPetServiceSelector";
import {
  filterByAllArrivals,
  filterByUnassignedPetArrivals,
} from "./hotelArrivalsFilterButtonActions";

const mapStateToProps = state => {
  const filterBy = getHotelArrivalsFilterBy(state);

  return {
    isHidden: false,
    componentID: "SmallPillButton_HotelArrivalsUnassignedPetFilterButton",
    label: "Unassigned Pet",
    selected: filterBy.unassignedPet,
    disabled: false,
    filterBtnStatus: filterBy.unassignedPet,
    isAllOthersSelected:
      filterBy.overnight && filterBy.dayCamp && filterBy.dayCare && filterBy.attentionNeeded,
  };
};

const mapDispatchToProps = dispatch => ({
  handleFilterByOverNightArrivals: ({ filterBtnStatus, isAllOthersSelected }) => {
    if (filterBtnStatus) {
      // toggle button off
      dispatch(filterByUnassignedPetArrivals({ filterBtnStatus: !filterBtnStatus }));

      // toggle off all button as well
      dispatch(filterByAllArrivals({ filterBtnStatus: false }));
    } else {
      dispatch(filterByUnassignedPetArrivals({ filterBtnStatus: !filterBtnStatus }));

      if (isAllOthersSelected) {
        dispatch(filterByAllArrivals({ filterBtnStatus: true }));
      }
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleClick: () =>
    propsFromDispatch.handleFilterByOverNightArrivals({
      filterBtnStatus: propsFromState.filterBtnStatus,
      isAllOthersSelected: propsFromState.isAllOthersSelected,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SmallPillButton);
