import React from "react";
import styled from "styled-components";
import { color } from "../../../common/styles/theme";
import ClickableText from "../../../common/ClickableText";
import SplitLineWithMessage from "../../../common/SplitLineWithMessage";
import { PRINT_INVOICE_REMINDER_MESSAGE } from "../../../../core/constants/checkInOutConstants";
import VaccinationCheckWarning from "./VaccinationCheckWarningContainer";
import PetCard from "../../../upcoming_here/petCard/PetCardContainer";
import Button from "../../../common/commonButton";

const StyledAppointmentSet = styled.div`
  display: flex;
  flex-direction: column;
  height: 405px;
`;

const PetCardsContainer = styled.div`
  overflow-y: auto;
  margin: 25px 0;
`;

const AppointmentTitle = styled.div`
  display: flex;
  justify-content: center;
`;

const DoneButtons = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  align-items: center;
  padding-bottom: 1rem;
`;

const PetParentButton = styled.div`
  cursor: pointer;
  color: ${color.kalEl};
`;

class AppointmentSet extends React.Component {
  getTitleMessage = () => {
    const { petServiceItemsIds, isModified, isCheckingIn } = this.props;

    if (!petServiceItemsIds || !petServiceItemsIds.length) {
      return;
    }
    let titleMessage = "";
    const appointmentsAmount = petServiceItemsIds && petServiceItemsIds.length;
    if (isModified) {
      titleMessage = "The appointment was modified successfully!";
    } else if (isCheckingIn) {
      if (appointmentsAmount > 1) {
        titleMessage = `${appointmentsAmount} appointments are set and checked-in!`;
      } else {
        titleMessage = "The appointment is set and checked-in!";
      }
    } else if (appointmentsAmount > 1) {
      titleMessage = `${appointmentsAmount} appointments are set!`;
    } else {
      titleMessage = "The appointment is set!";
    }

    return titleMessage;
  };

  handlePayNow = () => {
    const { showConfirmationModal, onPayNow } = this.props;

    showConfirmationModal({
      header: "Pay Now Reminder",
      content: SplitLineWithMessage({ string: PRINT_INVOICE_REMINDER_MESSAGE, delimiter: "\n" }),
      confirmText: "Proceed Anyway",
      confirm: () => onPayNow(),
      cancelText: "Cancel",
    });
  };

  render() {
    const {
      customer,
      petServiceItemsIds,
      isCheckingIn,
      verificationMessage,
      handleDoneClick,
      handleProfileClick,
    } = this.props;

    return (
      <StyledAppointmentSet>
        <AppointmentTitle>
          <h2>{this.getTitleMessage()}</h2>
        </AppointmentTitle>
        <PetCardsContainer>
          {petServiceItemsIds.map(petServiceItemsId => (
            <React.Fragment key={petServiceItemsId}>
              <PetCard appointmentID={petServiceItemsId} showDate type="future" />
              {!isCheckingIn && (
                <VaccinationCheckWarning
                  appointmentID={petServiceItemsId}
                  verification={verificationMessage}
                />
              )}
            </React.Fragment>
          ))}
        </PetCardsContainer>
        <Footer>
          <PetParentButton onClick={handleProfileClick}>
            {`${customer.firstName}'s profile`}
          </PetParentButton>
          <DoneButtons>
            {isCheckingIn && (
              <ClickableText
                isVisible
                styleObj={{ color: color.kalEl }}
                onClick={() => this.handlePayNow()}
              >
                Pay now
              </ClickableText>
            )}
            <Button
              componentId="button_set_appointment_modal"
              onClick={handleDoneClick}
              label="Done"
            />
          </DoneButtons>
        </Footer>
      </StyledAppointmentSet>
    );
  }
}
export default AppointmentSet;
