import { put, takeEvery, all, fork, call, select } from "redux-saga/effects";
import { isEmpty, get, has } from "lodash/fp";
import customersActionTypes from "../../actionTypes/customersActionTypes";
import {
  updateCustomerContactSettingsRequest,
  updateCustomerContactSettingsSuccess,
  updateCustomerContactSettingsFailure
} from "../../actionCreators/customersActionCreators";
import { onUpdateCustomer } from "../customersSaga";
import {
  onCreateCustomerPreferences,
  onUpdateCustomerPreferences
} from "./customerPreferencesSaga";
import { getPhonesByCustomer } from "../../selectors/entitiesSelector";
import {
  getIsPhonePreferred,
  buildTextContactPreferences,
  buildPhoneChannelPreferences
} from "../../utils/customerProfile";

function* onUpdateCustomerContactSettings({
  customerKey,
  data: { receiveReminderCalls, ...phonesToUpdate }
}) {
  try {
    yield put(updateCustomerContactSettingsRequest());

    const customerPhones = yield select(getPhonesByCustomer, { customerKey });

    yield call(onUpdateCustomer, {
      customerKey,
      data: {
        reminderOptOutFlag: !receiveReminderCalls
      }
    });

    yield call(setPhonePreferences, { customerKey, customerPhones, phonesToUpdate });

    yield put(updateCustomerContactSettingsSuccess());
  } catch (error) {
    yield put(updateCustomerContactSettingsFailure({ error }));
  }
}

function* setPhonePreferences({ customerPhones, phonesToUpdate, customerKey }) {
  const contactPhones = customerPhones.filter(({ phoneId }) => has(phoneId, phonesToUpdate));

  const { preferencesToCreate, preferencesToUpdate } = buildPhonePreferencesToCreateOrUpdate(
    contactPhones,
    phonesToUpdate
  );

  if (!isEmpty(preferencesToCreate)) {
    yield call(onCreateCustomerPreferences, {
      customerKey,
      data: buildTextContactPreferences(preferencesToCreate)
    });
  }

  if (!isEmpty(preferencesToUpdate)) {
    yield call(onUpdateCustomerPreferences, {
      customerKey,
      data: buildTextContactPreferences(preferencesToUpdate)
    });
  }
}

function buildPhonePreferencesToCreateOrUpdate(contactPhones, phonesToUpdate) {
  const preferencesToCreate = [];
  const preferencesToUpdate = [];

  contactPhones.forEach(phone => {
    const payload = buildPhoneChannelPreferences({
      contactId: phone.phoneId,
      isOptedOut: !get(phone.phoneId, phonesToUpdate),
      isPreferred: getIsPhonePreferred(phone)
    });

    const isMissingPreferences = isEmpty(get("communicationPreferences", phone));

    if (isMissingPreferences) {
      preferencesToCreate.push(payload);
    } else {
      preferencesToUpdate.push(payload);
    }
  });

  return { preferencesToCreate, preferencesToUpdate };
}

function* watchUpdateCustomerContactSettings() {
  yield takeEvery(
    customersActionTypes.UPDATE_CUSTOMER_CONTACT_SETTINGS,
    onUpdateCustomerContactSettings
  );
}

export default function* updateCustomerContactSettingsSaga() {
  yield all([fork(watchUpdateCustomerContactSettings)]);
}
