import React from "react";
import CommonModal from "@/web/common/modals/commonModal";
import { Layout } from "@prism/psm-ui-components";
import DeleteVaccination from "web/petParentProfile/pets/modals/content/vaccinations/deleteVaccination/DeleteVaccinationModalContainer";

export default function DeleteVaccinationModal(props) {
  const { isHidden, componentID, onModalClose } = props;

  if (!isHidden) {
    return (
      <Layout.Cluster id={componentID}>
        <CommonModal onClose={onModalClose}>
          <DeleteVaccination onModalClose={onModalClose} />
        </CommonModal>
      </Layout.Cluster>
    );
  }

  return null;
}
