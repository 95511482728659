import React from "react";
import TableCell from "./TableCell";

export default function TableBody({ columns, rows }) {
  const numberOfColumns = columns.length + 1;

  const renderCell = (item, column) => {
    const cellData = item[column.path];

    if (Array.isArray(cellData)) {
      return cellData.map((cell, idx) => <TableCell key={idx} values={cell} />);
    }

    return <TableCell values={cellData} />;
  };

  return (
    <tbody>
      {rows.map((row, idx) => {
        // In the print-schedule, this allows the group headers to be one long cell,
        // spanning the width of the entire table. This cell can now accommodate for
        // long text.
        if (row.type === "group-header") {
          return (
            <tr className={row.type}>
              <td colSpan={numberOfColumns}>{row.rowLabel}</td>
            </tr>
          );
        }

        return (
          <tr key={idx} className={row.type} type={row.type}>
            {columns.map(column => (
              <td key={column.path}>{renderCell(row, column)}</td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
}
