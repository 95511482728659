import { get, isArray } from "lodash/fp";
import { createSelector } from "reselect";
import { getMedicationById } from "../medication/medicationsSelector";
import { medicationFields } from "../newMedication/newMedicationConstants";

export const getMedicationIsShowingDetails = createSelector([getMedicationById], medication => {
  return get(medicationFields.LOCATION_TYPES, medication) || [];
});

export const getMedicationIsShowingDetailsForType = createSelector(
  [getMedicationIsShowingDetails, (state, props) => props],
  (showingTypes, { locationType }) => {
    return isArray(showingTypes) ? showingTypes.find(type => type === locationType) : false;
  },
);
