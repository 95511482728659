import React from "react";
import { Field } from "formik";
import styled from "styled-components";
import moment from "moment";
import { device } from "web/common/styles/responsive/devices";
import Select from "./Select";
import { FormContext } from "./Form";
import Checkbox from "./Checkbox";
import Input from "./Input";
import TextArea from "./TextArea";
import StarImage from "../../assets/icons/star.svg";
import UncheckedStarImage from "../../assets/icons/star-unchecked.svg";
import CloseButton from "./CloseButton";
import CautionImage from "../../assets/icons/caution.svg";
import { color } from "./styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import Calendar from "./Calendar";
import { NO_NEW_LINES_REGEX, VALID_PHONE_INPUT_REGEX } from "../../core/constants/regex";
import StarIcon from "./starIcon/starIconContainer";
import ResizableTextarea from "./formInputs/ResizableTextarea";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.horizontal ? "row" : "column")};
  justify-content: ${props => (props.horizontal ? "flex-start" : "space-between")};
  opacity: ${props => (props.disabled ? "0.5" : "inherit")}
    ${props =>
      props.isLink &&
      props.disabled &&
      `
        opacity: inherit;
    `}
    ${props => props.disableHeight && "height: auto;"}
    ${props => props.alignCenter && "justify-content: center;"}
    ${props =>
      props.horizontal &&
      `
        flex-direction: row-reverse;
        justify-content: flex-end;
    `}
    ${props => props.withCheckbox && "margin-bottom: 0.5em;"}
    ${props => props.secondaryInput && "height: 1em;"}
    ${props => props.withCloseIcon && "width: 50%;"}
    ${props => props.gridColumn && `grid-column: ${props.gridColumn};`};

  @media ${device.mobileS} {
    min-height: 5rem;
    width: inherit;
  }
  @media ${device.laptop} {
    min-height: 4rem;
  }
  @media ${device.galaxyTabProLandscape} {
    min-height: 5rem;
  }
  @media ${device.galaxyTabProPortrait} {
    min-height: 5rem;
  }
  @media ${device.prismDesktop} {
    min-height: 3.9375rem;
    ${props => props.closeInputStyle && "width: 85%;"};
  }
`;

const StyledLabel = styled.div`
  font-size: ${props => (props.isNote ? "1em" : fontSizes.regular)};
  color: ${props => {
    if ((props.error && props.touched) || (props.error && props.showErrorOnLoad)) {
      return color.errorRed;
    }
    if (props.isFocused) {
      return color.kalEl;
    }
    if (props.defaultLabelColor) {
      return props.defaultLabelColor;
    }
    if (props.isNote) {
      return color.duskyBlue;
    }
    return color.tChalla;
  }};
`;

const Icon = styled.img`
  vertical-align: bottom;
  margin-left: 0.1em;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledError = styled.div`
  ${props => props.errorStyling && "margin-top: .25em;"};
  color: ${color.errorRed};
  font-size: ${fontSizes.small};
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.withLabelDisclaimer ? "column" : "row")};
  ${props => props.withCloseIcon && "justify-content: space-between;"};
`;

const LabelDisclaimer = styled.div`
  font-size: 10px;
  margin: 0 0 15px 0;
`;
const NameContainer = styled.div`
  width: 100%;
`;

const Label = styled.div``;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CalendarContainer = styled.div`
  //here if styles are needed
`;

const ErrorMessage = ({ error, errorStyling }) => (
  <StyledError errorStyling={errorStyling}>{error}</StyledError>
);
class FormInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  shouldChangeWithRegex(values) {
    const { e, regex, form, isExclusive } = values;
    const shouldReturn = isExclusive ? !regex.test(e.target.value) : regex.test(e.target.value);

    if (shouldReturn) {
      return;
    }

    form.handleChange(e);
  }

  renderControl({ field, form, formContext, error, touched, isDisabled }) {
    const {
      label,
      labelPosition,
      autoSave,
      options,
      name,
      withCloseIcon,
      hasIcon,
      yearsOptions,
      checked,
      disabled,
      onChange,
      ...props
    } = this.props;

    const commonProps = {
      error: (error && touched) || (error && props.showErrorOnLoad),
      onFocus: e => {
        this.setState({
          isFocused: true,
        });
        formContext.onFocus && formContext.onFocus(e);
      },
      onBlur: e => {
        this.setState({
          isFocused: false,
        });
        form.handleBlur(e);
        form.setFieldTouched(name, true);
        if (autoSave) {
          formContext.onSubmit &&
            formContext.onSubmit({
              [name]: e.target.value,
            });
        }
      },
    };
    switch (props.type) {
      case "select":
        return (
          <SelectContainer id={`${props.id}__dropDown`}>
            <NameContainer id={`${props.id}__${props.type}--${props.modifier}`}>
              <Select
                options={options}
                value={options.find(option => option.value === field.value) || null}
                disabled={isDisabled}
                isForm
                onChange={e => {
                  const value = e === null ? undefined : e.value;
                  onChange && onChange(value);
                  form.setFieldValue(name, value);
                }}
                {...props}
                {...commonProps}
              />
            </NameContainer>
            {hasIcon && <Icon id={`${props?.componentId}__icon`} src={CautionImage} />}
          </SelectContainer>
        );
      case "checkbox":
        return (
          <div id={`${props.id}__${props.type}--${props.modifier}`}>
            <Checkbox
              name={field.name}
              value={field.value}
              label={label}
              labelPosition={labelPosition || "right"}
              fontSize={fontSizes.regular}
              onChange={() => {
                onChange && onChange(!field.value);
                formContext.onFocus && formContext.onFocus();
                form.setFieldValue(field.name, !field.value);
              }}
              checked={field.value}
              disabled={isDisabled}
              {...props}
              {...commonProps}
            />
          </div>
        );
      case "textarea":
        return (
          <div id={`${props.id}__${props.type}--${props.modifier}`}>
            <TextArea
              name={name}
              value={field.value}
              disabled={isDisabled}
              withCloseIcon={withCloseIcon}
              onChange={e => {
                onChange && onChange(e);
                form.handleChange(e);
              }}
              {...props}
              {...commonProps}
            />
          </div>
        );
      case "calendar":
        return (
          <CalendarContainer {...props} {...commonProps}>
            <Calendar
              selectedDate={field.value && moment(field.value).format("YYYY-MM-DD")}
              yearsOptions={yearsOptions}
              hasIcon={hasIcon}
              closeCalendar={() =>
                this.setState({
                  isFocused: false,
                })
              }
              selectDate={selectedDate => {
                const formattedValue = moment(selectedDate).isMoment
                  ? selectedDate.format("YYYY-MM-DD")
                  : moment(selectedDate).format("YYYY-MM-DD");

                onChange && onChange(formattedValue);
                formContext.onFocus && formContext.onFocus();
                form.setFieldValue(field.name, formattedValue);
              }}
              {...props}
              {...commonProps}
            />
          </CalendarContainer>
        );
      case "phone":
        return (
          <Input
            name={name}
            value={field.value}
            disabled={isDisabled}
            withCloseIcon={withCloseIcon}
            onChange={e => {
              this.shouldChangeWithRegex({
                e,
                regex: VALID_PHONE_INPUT_REGEX,
                isExclusive: true,
                form,
                onChange,
              });
            }}
            {...props}
            {...commonProps}
          />
        );
      default:
        return (
          <div id={`${props.id}__${props.type}--${props.modifier}`}>
            <ResizableTextarea
              name={name}
              value={field.value}
              disabled={isDisabled}
              withCloseIcon={withCloseIcon}
              onChange={e => {
                this.shouldChangeWithRegex({
                  e,
                  regex: NO_NEW_LINES_REGEX,
                  form,
                  onChange,
                });
              }}
              {...props}
              {...commonProps}
            />
          </div>
        );
    }
  }

  render() {
    const {
      label,
      labelDisclaimer,
      autoSave,
      options,
      name,
      type,
      value,
      isPreferred,
      customerKey,
      withCloseIcon,
      isNote,
      onClose,
      checked,
      disabled,
      formContext,
      defaultLabelColor,
      isHidden,
      ...props
    } = this.props;

    if (!isHidden) {
      return (
        <FormContext.Consumer>
          {formContext => {
            const { fieldsToInclude } = formContext;
            if (fieldsToInclude && !fieldsToInclude.includes(name)) {
              return null;
            }

            return (
              <Field
                name={name}
                render={({ field, form }) => {
                  const error = props.error || form.errors[name];
                  const touched = props.touched || form.touched[name];
                  const { showErrorOnLoad } = props;
                  const isDisabled = disabled || formContext.disabled;
                  return (
                    <InputWrapper
                      className="FormInput__input-wrapper"
                      disabled={isDisabled}
                      {...props}
                      withCloseIcon={withCloseIcon}
                    >
                      {label && type !== "checkbox" && (
                        <StyledLabel
                          className="FormInput__styledLabel"
                          htmlFor={props.name || props.id}
                          error={error}
                          touched={touched}
                          isFocused={this.state.isFocused}
                          isNote={isNote}
                          withCloseIcon={withCloseIcon}
                          showErrorOnLoad={props.showErrorOnLoad}
                          defaultLabelColor={defaultLabelColor}
                        >
                          <LabelWrapper className="FormInput__labelWrapper">
                            <LabelContainer
                              className="FormInput__labelContainer"
                              withCloseIcon={withCloseIcon}
                              withLabelDisclaimer={labelDisclaimer}
                            >
                              {labelDisclaimer ? (
                                <>
                                  <Label>{label}</Label>
                                  <LabelDisclaimer>{labelDisclaimer}</LabelDisclaimer>
                                </>
                              ) : (
                                <Label>{label}</Label>
                              )}

                              {name.includes("Phone") && (
                                <StarIcon
                                  value={value}
                                  customerKey={customerKey}
                                  src={isPreferred ? StarImage : UncheckedStarImage}
                                />
                              )}
                            </LabelContainer>
                            {withCloseIcon && <CloseButton onClick={onClose} />}
                          </LabelWrapper>
                        </StyledLabel>
                      )}

                      {this.renderControl({ field, form, formContext, error, touched, isDisabled })}

                      {error && (touched || showErrorOnLoad) && (
                        <ErrorMessage errorStyling={type === "select"} error={error} />
                      )}
                    </InputWrapper>
                  );
                }}
              />
            );
          }}
        </FormContext.Consumer>
      );
    }

    return null;
  }
}

export default FormInput;
