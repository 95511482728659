import { put, takeEvery, call, all } from "redux-saga/effects";
import {
  GET_CANCELLATION_REASONS,
  getCancellationReasonsFailure,
  getCancellationReasonsRequest,
  getCancellationReasonsSuccess,
} from "dux/cancellationreasons/cancellationReasonsActions";
import { fetchCancellationReasons } from "core/services/systemServicesBooking/cancellationsEndPoints";

function* onGetCancellationReasons() {
  try {
    yield put(getCancellationReasonsRequest());
    const response = yield call(fetchCancellationReasons);
    const cancellationReasons = response.data;

    yield put(getCancellationReasonsSuccess({ cancellationReasons }));
  } catch (error) {
    yield put(getCancellationReasonsFailure({ error }));
  }
}

function* watchOnGetCancellationReasons() {
  yield takeEvery(GET_CANCELLATION_REASONS, onGetCancellationReasons);
}

export default function* cancellationReasonsSaga() {
  yield all([watchOnGetCancellationReasons()]);
}
