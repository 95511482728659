import { createSelector } from "reselect";
import {
  ELIGIBLE_PROMOTIONS_BGM,
  ELIGIBLE_PROMOTIONS_FTCO,
} from "dux/eligiblePromotions/eligiblePromotionConstants";

/**
 * Will return the eligible Promotions values from state.entities.itineraries[itineraryId]
 * @param state
 * @return {Array}
 */
export const getEligiblePromotions = state => {
  const eligiblePromotions = state.eligiblePromotions.eligiblePromotionsList;

  if (!eligiblePromotions) {
    return [];
  }

  return eligiblePromotions;
};

// Salesforce will send back an array of eligible promotions, however only the 1st item in the array
// takes precedent, So if that 1st item is "BGM" we return a true to show that message.
export const getWillShowEligiblePromotionsBGM = createSelector(
  [getEligiblePromotions],
  eligiblePromotions => {
    return eligiblePromotions[0]?.type === ELIGIBLE_PROMOTIONS_BGM;
  },
);

// Salesforce will send back an array of eligible promotions, however only the 1st item in the array
// takes precedent, So if that 1st item is "FTCO" we return a true to show that message.
// NOTE: this is different then the isFTCO property that determines if a customer is actually qualified for FTCO
export const getWillShowEligiblePromotionsFTCO = createSelector(
  [getEligiblePromotions],
  eligiblePromotions => {
    return eligiblePromotions[0]?.type === ELIGIBLE_PROMOTIONS_FTCO;
  },
);

export const getEligiblePromotionsSelectorFactory = promotionType => {
  return createSelector([getEligiblePromotions], eligiblePromotions => {
    return eligiblePromotions.find(({ type }) => type === promotionType);
  });
};

export const getEligiblePromotionsBGM = getEligiblePromotionsSelectorFactory(
  ELIGIBLE_PROMOTIONS_BGM,
);

export const getEligiblePromotionsFTCO = getEligiblePromotionsSelectorFactory(
  ELIGIBLE_PROMOTIONS_FTCO,
);

export const getEligiblePromotionReasonSelectorFactory = selector => {
  return createSelector([selector], eligiblePromotion => eligiblePromotion?.reason);
};

export const getEligiblePromotionReasonBGM = getEligiblePromotionReasonSelectorFactory(
  getEligiblePromotionsBGM,
);

export const getEligiblePromotionReasonFTCO = getEligiblePromotionReasonSelectorFactory(
  getEligiblePromotionsFTCO,
);
