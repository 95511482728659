import { connect } from "react-redux";
import { getCheckForSameDay } from "@/dux/frequency/dateRangeForFrequencySelectors";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { isFromBooking } from "@/core/utils/validationUtils/isFromValidation";
import SelectWithWrapper from "../common/SelectWithWrapper";
import { setMedicationName } from "./actions/setMedicationNameActions";
import {
  getPetMedicationOptions,
  getMedicationNameByExternalId,
  getMedicationNameExternalIdById,
} from "./medicationNameSelectors";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../core/constants/validationErrors";
import { OTHER_MEDICATION_NAME } from "../newMedication/newMedicationConstants";
import { setMedicationTypeAmount } from "../medicationTypeAmount/actions/medicationTypeAmountActions";
import { setMedicationTimeOfDay } from "../medicationTimeOfDay/actions/medicationTimeOfDayActions";
import { setMedicationInstructions } from "../medicationInstructions/actions/medicationInstructionsActions";
import { clearMedicationFrequency } from "../../dux/bookingPage/setMedFrequency/medicationFrequencyActions";
import { getMedIsDiscontinuedById } from "../medication/medicationsSelector";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, medicationIndex, petId, router } = ownProps;
  const inBooking = isFromBooking(router?.location?.pathname);
  const isSameDayBooking = inBooking && getCheckForSameDay(state);
  const value = getMedicationNameExternalIdById(state, { medicationId, petId });
  const currentMedicationName = getMedicationNameByExternalId(state, { medicationId, petId });
  const isDiscontinued = getMedIsDiscontinuedById(state, { petId, medicationId });
  const discontinuedOption = [{ value, label: currentMedicationName }];

  return {
    medicationId,
    petId,
    title: `Medication ${medicationIndex} name`,
    options: isDiscontinued ? discontinuedOption : getPetMedicationOptions(state),
    isForm: true,
    componentId: "medicationDetails-medicationName",
    value,
    currentMedicationName,
    error: REQUIRED_FIELD_ERROR_MESSAGE,
    shouldClearFrequency: !isSameDayBooking,
    disabled: isDiscontinued,
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeMedicationName: ({
    option,
    medicationId,
    currentMedicationName,
    petId,
    shouldClearFrequency,
  }) => {
    if (
      currentMedicationName === OTHER_MEDICATION_NAME &&
      option?.label !== OTHER_MEDICATION_NAME
    ) {
      dispatch(
        setMedicationName({ name: option?.label, externalId: option.value, medicationId, petId }),
      );
    } else if (option?.label === OTHER_MEDICATION_NAME) {
      dispatch(
        setMedicationName({
          name: "",
          externalId: option.value,
          medicationId,
          petId,
        }),
      );
    } else {
      dispatch(
        setMedicationName({
          name: option?.label,
          externalId: option.value,
          medicationId,
          petId,
        }),
      );
    }
    dispatch(setMedicationTypeAmount({ medicationTypeAmount: "", medicationId, petId }));
    dispatch(setMedicationTimeOfDay({ timeOfDay: [], medicationId, petId }));
    if (shouldClearFrequency) dispatch(clearMedicationFrequency({ medicationId, petId }));
    dispatch(setMedicationInstructions({ medicationInstructions: "", medicationId, petId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onChange: option => {
    const { medicationId, currentMedicationName, petId, shouldClearFrequency } = propsFromState;
    propsFromDispatch.onChangeMedicationName({
      option,
      medicationId,
      currentMedicationName,
      petId,
      shouldClearFrequency,
    });
  },
});

export default withRouteProps(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(SelectWithWrapper),
);
