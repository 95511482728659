import { all, fork, put, takeEvery } from "redux-saga/effects";
import { patchHotelItineraryStatus } from "@/dux/reservationCartDetailsConfirmation/reservationCartDetailsConfirmationActions";
import {
  CANCEL_HOTEL_PET,
  cancelHotelPetFailure,
} from "dux/hotelCancelAppt/hotelCancelApptActions";
import { getChangeStatusPayload } from "@/dux/reservationCartDetailsConfirmation/reservationCartDetailsUtils";

function* onCancelHotelPet({ petId, status, reason, hotelItineraryId }) {
  try {
    // Cancelling one pet at a time, API can hanlde multi pet cancellation
    const requestPayloadData = getChangeStatusPayload({
      petIds: [petId],
      cancellationReason: reason,
      status,
    });

    yield put(
      patchHotelItineraryStatus({
        itineraryId: hotelItineraryId,
        data: requestPayloadData,
      }),
    );
  } catch (error) {
    yield put(cancelHotelPetFailure({ error }));
  }
}

/** ----------------------------------------------------------------------- **\
 WATCH FUNCTIONS
 \** ----------------------------------------------------------------------- * */
function* watchCancelHotelPet() {
  yield takeEvery(CANCEL_HOTEL_PET, onCancelHotelPet);
}

/** ----------------------------------------------------------------------- **\
 EXPORT SAGAS
 \** ----------------------------------------------------------------------- * */
export default function* cancelHotelPetSaga() {
  yield all([fork(watchCancelHotelPet)]);
}
