import { connect } from "react-redux";
import SmallPillButton from "../../../_components/buttons/SmallPillButton";

const mapStateToProps = () => ({
  isHidden: false,
  componentID: "SmallPillButton_HotelOverNightOverNightFilterButton",
  label: "Overnight",
  selected: false, // Get from Selector
  disabled: false,
});

export default connect(mapStateToProps)(SmallPillButton);
