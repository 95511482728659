export const eligibilityTypes = {
  CHECK_IN: "CHECK_IN",
  BOOKING: "BOOKING",
  CHECK_OUT: "CHECK_OUT",
  PROFILE: "PROFILE",
};

export const eligibilitySubjectTypes = {
  PET: "Pet",
  CUSTOMER: "Customer",
  BOOKING: "Booking",
};

export const reasonCodes = {
  CheckOutAlert: "CheckOutAlert",
  CheckInAlert: "CheckInAlert",
  BookingAlert: "BookingAlert",
  Inactive: "Inactive",
  DoNotBook: "DoNotBook",
  CatHeartCondition: "CatHeartCondition",
  OlderPet: "OlderPet",
  RabiesExpired: "RabiesExpired",
  NotGoodFit: "NotGoodFit",
  Caution: "Caution",
  HealthCondition: "HealthCondition",
  VaccinationsMustBeMet: "VaccinationsMustBeMet",
  MissingVaccinations: "MissingVaccinations",
  IndividualFlag: "IndividualFlag",
  MaleNeutered: "MaleNeutered",
  WalkType: "WalkType",
  ProfileType: "ProfileType",
  MissingAddress: "MissingAddress",
};

export const pageMountReasonCodes = [
  reasonCodes.DoNotBook,
  reasonCodes.CheckOutAlert,
  reasonCodes.CheckInAlert,
  reasonCodes.BookingAlert,
  reasonCodes.OlderPet,
  reasonCodes.CatHeartCondition,
  reasonCodes.NotGoodFit,
];

export const actionClickedReasonCodes = [
  reasonCodes.RabiesExpired,
  reasonCodes.Caution,
  reasonCodes.HealthCondition,
];

export const canNotBookReasonCodes = [
  reasonCodes.Inactive,
  reasonCodes.CatHeartCondition,
  reasonCodes.MaleNeutered,
  reasonCodes.IndividualFlag,
];

export const actionClickedDDCReasonCodes = [
  reasonCodes.BookingAlert,
  reasonCodes.Caution,
  reasonCodes.HealthCondition,
  reasonCodes.VaccinationsMustBeMet,
  reasonCodes.MissingVaccinations,
  reasonCodes.OlderPet,
  reasonCodes.NotGoodFit,
];

export const canNotBookDDCReasonCodes = [
  reasonCodes.DoNotBook,
  reasonCodes.NotGoodFit,
  reasonCodes.Inactive,
  reasonCodes.MaleNeutered,
  reasonCodes.IndividualFlag,
  reasonCodes.ProfileType,
];

export const messagePrefixes = {
  [reasonCodes.CheckOutAlert]: "Check out alert",
  [reasonCodes.CheckInAlert]: "Check in alert",
  [reasonCodes.BookingAlert]: "Booking alert",
  [reasonCodes.OlderPet]: "Senior pet alert",
  [reasonCodes.DoNotBook]: "Pet parent is marked as Do Not Book",
  [reasonCodes.CatHeartCondition]: "Cat heart condition alert",
  [reasonCodes.RabiesExpired]: "Expired vaccination alert",
  [reasonCodes.NotGoodFit]: "Not a good fit",
  [reasonCodes.Caution]: "Caution",
  [reasonCodes.HealthCondition]: "Health condition",
  [reasonCodes.VaccinationsMustBeMet]: "Expired vaccination alert",
  [reasonCodes.MissingVaccinations]: "One or more vaccinations on file are missing or expired",
  [reasonCodes.MaleNeutered]: "Pet is an unneutered male",
  [reasonCodes.IndividualFlag]: "Pet is marked as individual",
  [reasonCodes.WalkType]: "Walk type cannot be left blank",
  [reasonCodes.ProfileType]: "Profile type",
  [reasonCodes.MissingAddress]: "Address is Missing",
};

export const ALL_PETS_NOT_ALLOWED = "All pets are not allowed to book";
export const CUSTOMER_NOT_ALLOWED = "Customer is not allowed to book";
export const ELIGIBILITY_WARNING_FOR_CHOSEN_DATE =
  "Please notice new eligibility warnings for the chosen date";
export const ALERTS_FOR_THIS_APPOINTMENT = "Please notice alerts for this appointment";
export const ELIGIBILITY_WARNINGS_FOR_THIS_APPT =
  "Please notice eligibility warnings for this appointment";
