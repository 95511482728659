import { connect } from "react-redux";
import AssociateSchedulingModals from "../../../web/associateScheduling/modals/associateSchedulingModals";
import {
  hideSchedulesModal,
  setStartWeekDate,
  setAssociateId,
  goToAssociateTemplate,
} from "../../../core/actionCreators/schedulesActionCreators";
import schedulesActionCreators from "../../../core/actionCreators/schedulesActionCreators";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    associateId,
    newStartWeekDate,
    newAssociateId,
    newWeekId,
    router: { location, navigate },
  } = ownProps;

  return {
    onClose: () => dispatch(hideSchedulesModal()),
    setStartWeekDate: () => dispatch(setStartWeekDate(newStartWeekDate)),
    setAssociateId: () => {
      const mainRoute = location.pathname.split("/")[1];

      navigate(`/${mainRoute}/${newAssociateId}`);
      dispatch(setAssociateId(newAssociateId));
    },
    goToAssociateTemplate: () => dispatch(goToAssociateTemplate(associateId)),
    setTemplateWeeklyEdit: () => dispatch(schedulesActionCreators.setTemplateWeeklyEdit(newWeekId)),
  };
};

export default withRouteProps(connect(null, mapDispatchToProps)(AssociateSchedulingModals));
