import { createSelector } from "reselect";
import getFeatureFlagIconState from "./_featureFlagIconState";

const getFeatureFlagIconVisibleState = createSelector(
  [getFeatureFlagIconState],
  featureFlagIconState => {
    return featureFlagIconState.isHidden;
  }
);

export default getFeatureFlagIconVisibleState;
