import { easeInOutQuad } from "tween-functions";
import { RectInterface } from "./rect";
import ParticleGenerator from "./particleGenerator";

export interface ConfettiOptionsInterface {
  width: number;
  height: number;
  numberOfPieces: number;
  friction: number;
  wind: number;
  gravity: number;
  initialVelocityX: number;
  initialVelocityY: number;
  colors: string[];
  opacity: number;
  recycle: boolean;
  run: boolean;
  debug: boolean;
  confettiSource: RectInterface;
  tweenFunction: (
    currentTime: number,
    currentValue: number,
    targetValue: number,
    duraton: number,
    s?: number,
  ) => number;
  tweenDuration: number;
  drawShape?: (context: CanvasRenderingContext2D) => void;
  onConfettiComplete?: (confettiInstance?: Confetti) => void;
}

export const confettiDefaults: Pick<
  ConfettiOptionsInterface,
  Exclude<keyof ConfettiOptionsInterface, "confettiSource">
> = {
  width: typeof window !== "undefined" ? window.innerWidth : 300,
  height: typeof window !== "undefined" ? window.innerHeight : 200,
  numberOfPieces: 200,
  friction: 0.99,
  wind: 0,
  gravity: 0.1,
  initialVelocityX: 4,
  initialVelocityY: 10,
  colors: [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#795548",
  ],
  opacity: 1.0,
  debug: true,
  tweenFunction: easeInOutQuad,
  tweenDuration: 5000,
  recycle: true,
  run: true,
};

export class Confetti {
  constructor(canvas: HTMLCanvasElement, opts: Partial<ConfettiOptionsInterface>) {
    this.canvas = canvas;
    const ctx = this.canvas.getContext("2d");
    if (!ctx) {
      throw new Error("Could not get canvas context");
    }
    this.context = ctx;

    this.generator = new ParticleGenerator(
      this.canvas,
      () => this.options as ConfettiOptionsInterface,
    );
    this.options = opts;
    this.update();
  }

  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  _options!: ConfettiOptionsInterface;
  generator: ParticleGenerator;
  rafId?: number;

  get options(): Partial<ConfettiOptionsInterface> {
    return this._options;
  }

  set options(opts: Partial<ConfettiOptionsInterface>) {
    const lastRunState = this._options && this._options.run;
    const lastRecycleState = this._options && this._options.recycle;
    this.setOptionsWithDefaults(opts);
    if (this.generator) {
      Object.assign(this.generator, this.options.confettiSource);
      if (typeof opts.recycle === "boolean" && opts.recycle && lastRecycleState === false) {
        this.generator.lastNumberOfPieces = this.generator.particles.length;
      }
    }
    if (typeof opts.run === "boolean" && opts.run && lastRunState === false) {
      this.update();
    }
  }

  setOptionsWithDefaults = (opts: Partial<ConfettiOptionsInterface>) => {
    const computedConfettiDefaults = {
      confettiSource: {
        x: 0,
        y: 0,
        w: this.canvas.width,
        h: 0,
      },
    };
    this._options = {
      ...computedConfettiDefaults,
      ...confettiDefaults,
      ...opts,
    };
    Object.assign(this, opts.confettiSource);
  };

  update = () => {
    const {
      options: { run, onConfettiComplete },
      canvas,
      context,
    } = this;
    if (run) {
      context.fillStyle = "white";
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
    if (this.generator.animate()) {
      this.rafId = requestAnimationFrame(this.update);
    } else {
      if (
        onConfettiComplete &&
        typeof onConfettiComplete === "function" &&
        this.generator.particlesGenerated > 0
      ) {
        onConfettiComplete.call(this, this);
      }
      this._options.run = false;
    }
  };

  reset = () => {
    if (this.generator && this.generator.particlesGenerated > 0) {
      this.generator.particlesGenerated = 0;
      this.generator.particles = [];
      this.generator.lastNumberOfPieces = 0;
    }
  };

  stop = () => {
    this.options = { run: false };
    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
      this.rafId = undefined;
    }
  };
}

export default Confetti;
