import React from 'react';
import './styles.css';
import { pluck } from '../utils';
import { GLOBAL_TOKENS } from '../scaleToken';

// Sets the interface for the Box layout custom properties.
interface StackProps<C extends React.ElementType> {

  /** A CSS padding value */
  space?: keyof typeof GLOBAL_TOKENS['SCALE'];
}

// Include types that are not part of StackProps, such as children,
type Props <C extends React.ElementType> = React.PropsWithChildren<StackProps<C>> & React.ComponentPropsWithoutRef<C>;

export const LayoutStack = <C extends React.ElementType>({
  space = 'scale-S1',
  children,
  style,
  ...props

}: Props<C>): React.ReactElement => {
  return (
    <div
      className="pplt-layout__stack"
      style={{
        '--setStackSpace': pluck(GLOBAL_TOKENS.SCALE, space),
        ...style,
      }}

      {...props}
    >
        {children}
    </div>
  );
};
