import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Text, Button } from "@prism/psm-ui-components";
import { getIsRescheduleTrainingAppointmentModalOpen } from "./manageTrainingClassPageSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";
import { GET_TRAINING_CLASS_AVAILABILITY } from "../trainingClassAvailability/trainingClassAvailabilityActions";
import { getTrainingClassSessionDetails } from "../trainingClassSessions/selectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getSelectedDate } from "../selectedDate/selectedDateSelector";
import { getCachedTrainingAvailabilityWeeks } from "../scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";
import dispatchTrainingAvailability from "../scheduleTrainingPage/scheduleTrainingPageUtils";
import {
  getTrainingAssociates,
  GET_TRAINING_ASSOCIATES,
} from "../trainingAssociates/trainingAssociatesActions";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import CommonModal from "@/web/common/modals/commonModal";
import { RescheduleTrainingAppointmentSummary } from "./RescheduleTrainingAppointmentSummary";
import RescheduleTrainingCalendar from "./RescheduleTrainingCalendar";
import WeeklyNavigationBarRescheduleTraining from "./WeeklyNavigationBarRescheduleTraining";
import TrainerPillButtonsReschedule from "./TrainerPillButtonsReschedule";
import { RescheduleTrainingClassTimes } from "../trainingClassAvailability/TrainingClassAvailabilityList";
import { toggleRescheduleTrainingAppointmentModal } from "./manageTrainingClassPageActions";
import RescheduleTrainingAppointmentButton from "./RescheduleTrainingAppointmentButton";
import { resetScheduleTrainingAppointment } from "../scheduleTrainingPage/scheduleTrainingPageActions";

export function RescheduleTrainingAppointmentModal({
  isHidden,
  isLoading,
  componentId,
  onClose,
  getGetTrainingClassAvailability,
  getTrainingAssociates,
}) {
  if (isHidden) {
    return null;
  }

  useEffect(() => {
    getGetTrainingClassAvailability();
    getTrainingAssociates();
  }, []);

  return (
    <CommonModal isHidden={isHidden} onClose={onClose}>
      <LoadingWrapper isLoading={isLoading}>
        <Layout.Box id={componentId}>
          <Layout.Stack space="stack-space-8">
            <Text id={`${componentId}__backButton`} size="text-size-lg" align="center" bold>
              Reschedule customer
            </Text>
            <Layout.Box>
              <Layout.Stack space="stack-space-4" style={{ display: "grid", placeItems: "center" }}>
                <RescheduleTrainingCalendar />
                <WeeklyNavigationBarRescheduleTraining />
                <TrainerPillButtonsReschedule />
              </Layout.Stack>
            </Layout.Box>
            <Layout.Switcher>
              <Layout.Box padding="box-padding-4">
                <RescheduleTrainingAppointmentSummary />
              </Layout.Box>
              <Layout.Box padding="box-padding-4" style={{ minWidth: "45ch" }}>
                <Layout.Stack space="stack-space-2">
                  <RescheduleTrainingClassTimes />
                </Layout.Stack>
              </Layout.Box>
            </Layout.Switcher>
            <Layout.Box padding="box-padding-2">
              <Layout.Cluster justify="flex-end" space="cluster-space-4">
                <Button
                  id={`${componentId}__backButton`}
                  variant="prism-primary-no-outline"
                  onClick={onClose}
                >
                  Go back
                </Button>
                <RescheduleTrainingAppointmentButton />
              </Layout.Cluster>
            </Layout.Box>
          </Layout.Stack>
        </Layout.Box>
      </LoadingWrapper>
    </CommonModal>
  );
}

export default connect(
  (state) => {
    const storeNumber = getStoreNumber(state);
    const { petServiceId } = getTrainingClassSessionDetails(state, { storeNumber });
    return {
      petServiceId,
      storeNumber,
      componentId: "RescheduleTrainingAppointmentModal",
      selectedDate: getSelectedDate(state),
      isHidden: !getIsRescheduleTrainingAppointmentModalOpen(state),
      isLoading: createLoadingSelector([GET_TRAINING_CLASS_AVAILABILITY, GET_TRAINING_ASSOCIATES])(
        state,
      ),
      cache: getCachedTrainingAvailabilityWeeks(state),
    };
  },
  (dispatch) => ({
    onClose: () => {
      dispatch(toggleRescheduleTrainingAppointmentModal());
      dispatch(resetScheduleTrainingAppointment());
    },
    dispatchGetTrainingClassAvailability: ({ selectedDate, storeNumber, petServiceId, cache }) => {
      dispatchTrainingAvailability({
        selectedDate,
        storeNumber,
        petServiceId,
        cache,
        dispatch,
      });
    },
    dispatchGetTrainingAssociates: (storeNumber) => {
      dispatch(getTrainingAssociates({ storeNumber, isLimited: true }));
    },
  }),
  (stateProps, dispatchProps) => {
    const { selectedDate, petServiceId, storeNumber, cache, ...rest } = stateProps;
    const {
      dispatchGetTrainingClassAvailability,
      dispatchGetTrainingAssociates,
      onClose,
    } = dispatchProps;
    return {
      ...rest,
      onClose,
      getGetTrainingClassAvailability: () => {
        dispatchGetTrainingClassAvailability({
          selectedDate,
          storeNumber,
          petServiceId,
          cache,
        });
      },
      getTrainingAssociates: () => {
        dispatchGetTrainingAssociates(storeNumber);
      },
    };
  },
)(RescheduleTrainingAppointmentModal);
