/**
 * Hotel Room Selection Page Selectors
 */
import { createSelector } from "reselect";
import { getHotelRoomManagementSearchTerm } from "@/dux/hotelRoomManagementSearch/hotelRoomManagementSearchSelectors";
import moment from "moment";
import { selectOverBookingExceptionRoomType } from "../overBookingException/overBookingExceptionSelectors";

// Helpers
const hasAllFilters = filters => !filters.length || filters.length === 3;

const hotelRoomFilterFn = (room, filter) => {
  const suspensionDetails = room.suspensionDetails[0];

  // Scheduled and InActive Rooms
  if (suspensionDetails?.status) {
    const isScheduled =
      filter.id === "scheduled" && moment(room.suspensionDetails[0]?.startDate).isAfter();
    const isInactive =
      filter.id === "inactive" && moment(room.suspensionDetails[0]?.startDate).isSameOrBefore();

    return isScheduled || isInactive;
  }

  // active Rooms
  return filter.id === "active";
};

// Selectors
export const getHotelRoomSelectedRoomType = state => state.hotelRoomSelection.roomTypeSelected;

export const getHotelRoomSelectionRoomTypes = state => state.hotelRoomSelection.roomTypes;

export const getHotelRoomSelectedFilters = state => state.hotelRoomFilters.roomFilters;

export const getHotelRoomTypes = createSelector(
  [getHotelRoomSelectionRoomTypes],
  hotelRoomTypes => {
    let hotelRooms = [];
    for (let roomId in hotelRoomTypes) {
      hotelRooms.push(hotelRoomTypes[roomId].roomTypeBucketName);
    }

    return [...hotelRooms, "View All"];
  },
);

export const getRoomsByRoomType = createSelector(
  [getHotelRoomSelectionRoomTypes, getHotelRoomSelectedRoomType],
  (hotelRoomTypes, roomSelected) => {
    if (roomSelected === "View All") {
      return Object.values(hotelRoomTypes)?.flatMap(item => item.rooms) || [];
    }

    const selectedRoomType = Object.values(hotelRoomTypes).filter(
      roomType => roomType.roomTypeBucketName === roomSelected,
    )[0];

    return selectedRoomType?.rooms || [];
  },
);

export const getFilteredRoomsBySearchTerm = createSelector(
  [getRoomsByRoomType, getHotelRoomManagementSearchTerm],
  (hotelRoomsByType, searchTerm) => {
    return hotelRoomsByType.filter(roomType => roomType?.roomNumber?.includes(searchTerm));
  },
);

export const getFilteredRoomsByRoomType = createSelector(
  [getFilteredRoomsBySearchTerm, getHotelRoomSelectedFilters],
  (hotelRoomTypes, selectedFilters) => {
    // If none or all filters are selected
    if (hasAllFilters(selectedFilters)) return hotelRoomTypes;

    let filteredRooms = [];
    selectedFilters.forEach(filter => {
      filteredRooms = [
        ...filteredRooms,
        ...hotelRoomTypes.filter(room => hotelRoomFilterFn(room, filter)),
      ];
    });

    return filteredRooms;
  },
);

/**
 * Selector to get room type dropdown options for overbooking exception form
 * Note: filters room types to only return where bucket name contains Atrium
 * @memberOf Selectors.hotelRoomSelection
 * @function
 * @name getHotelRoomTypesForExceptionForm
 * @param {Object} state
 * @returns array of objects containing label's and value's
 * @example getHotelRoomTypesForExceptionForm(state)
 */
export const getHotelRoomTypesForExceptionForm = createSelector(
  [getHotelRoomSelectionRoomTypes],
  hotelRoomTypes => {
    return Object.entries(hotelRoomTypes)
      ?.filter(([key, value]) => value.roomTypeBucketName.toLowerCase().includes("atrium"))
      ?.map(([key, value]) => ({
        label: value?.roomTypeBucketName,
        value: key,
      }));
  },
);

/**
 * Selector to get number of rooms based on currently selected room type bucket id
 * @memberOf Selectors.hotelRoomSelection
 * @function
 * @name selectRoomCountBySelectedBucketId
 * @param {Object} state
 * @returns number of rooms or 0
 * @example selectRoomCountBySelectedBucketId(state)
 */
export const selectRoomCountBySelectedBucketId = createSelector(
  [getHotelRoomSelectionRoomTypes, selectOverBookingExceptionRoomType],
  (hotelRoomTypes, roomTypeBucketId) => {
    return hotelRoomTypes[roomTypeBucketId]?.rooms?.length ?? 0;
  },
);
