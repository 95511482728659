import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { get } from "lodash/fp";
import {
  getBundleOfferTypeByPetServiceId,
  getIsActiveBundleAvailable,
  getIsFreeUnitRedemptionRestricted,
} from "../availableBundlesByPetSelectors";
import { isFromBooking } from "../../../../core/utils/validationUtils/isFromValidation";
import getIsBGMWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import { BGM } from "@/web/enableDisableWorkflowFeatureFlag/workflowFeatureTypes";
import PriceComponent from "dux/_components/pricing/PriceComponent";

const mapStateToProps = (state, { petId, petServiceId, router: { location } }) => {
  const isBGMWorkflowFeatureFlagHidden = getIsBGMWorkflowFeatureFlagHidden(state);
  const isBundleAvailable = getIsActiveBundleAvailable(state, { petId, petServiceId });

  // Only show the applied bundle if we are in the booking flow for SVCSART-428.
  // Once we have bundle history data available, we will show the applied bundle
  // info for the check-in/check-out flow. This will be addressed in a future sprint.
  const isBookingFlow = isFromBooking(location.pathname);

  const offerType = getBundleOfferTypeByPetServiceId(state, { petId, petServiceId });

  const isFreeUnitRedemptionRestricted = getIsFreeUnitRedemptionRestricted(state, {
    petId,
    petServiceId,
  });

  let isHidden = true;
  if (isBookingFlow) {
    isHidden =
      isBGMWorkflowFeatureFlagHidden ||
      offerType !== BGM ||
      !isBundleAvailable ||
      isFreeUnitRedemptionRestricted;
  }

  return {
    isHidden,
    componentID: "bundle-price-container",
    type: "bundle",
    price: "0.00",
  };
};

export default withRouteProps(connect(mapStateToProps, null)(PriceComponent));
