import { connect } from "react-redux";
import ManualBookingWarning from "./manualBookingWarning";
import { clearNewAppointmentData } from "../../../../core/actionCreators/ui/web/dashboardActionCreators";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  clearNewAppointmentData: () => dispatch(clearNewAppointmentData())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualBookingWarning);
