import { connect } from "react-redux";
import { getAssociatesFilterButtonData } from "dux/trainingAssociates/trainingAssociatesSelectors";
import { setFilteringByTrainer } from "dux/filteringButtons/dux";
import { ToggleButtonListWithSelectAll } from "dux/filteringButtons/ToggleButtonListWithSelectAll";

const mapStateToProps = (state) => {
  const data = getAssociatesFilterButtonData(state);
  return {
    isHidden: false,
    key: data,
    data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalState: (payload) => {
      dispatch(setFilteringByTrainer(Object.values(payload)));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleButtonListWithSelectAll);
