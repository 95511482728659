import { createSelector } from "reselect";
import filterAppointmentBySelectedDate from "../../../core/utils/filterUtils/filterAppointmentBySelectedDate";
import filterAppointmentsByPetNameRoomOwner from "../../../core/utils/filterUtils/filterAppointmentsByPetNameRoomOwner";
import filterAppointmentsByFilterType from "../../../core/utils/filterUtils/filterAppointmentsByFilterType";
import { sortByPropertyAsc } from "@/core/utils/arrayUtils/sortByProperty";

export const getHotelOvernightState = state => {
  return state.hotelOvernight;
};

export const getHotelOvernightAppointmentsState = state => {
  return state.hotelOvernight.appointments;
};

export const getHotelOvernightAppointments = createSelector(
  [getHotelOvernightState],
  ({ appointments, searchText, filterBy }) => {
    const filteredBySearchText = filterAppointmentsByPetNameRoomOwner(appointments, searchText);
    const filteredByFilterType = filterAppointmentsByFilterType(filteredBySearchText, filterBy);
    const filteredAndSortedAppointments = sortByPropertyAsc(
      filteredByFilterType,
      "checkOutDateTime",
    );

    return filteredAndSortedAppointments;
  },
);

export const getHotelOvernightAppointmentsByDate = createSelector(
  [getHotelOvernightState, (state, props) => props],
  (overnight, { date }) => {
    return filterAppointmentBySelectedDate(overnight.appointments, date);
  },
);
