import React from "react";
import { connect } from "react-redux";
import { Layout, Text, Button } from "@prism/psm-ui-components";
import { toggleCustomerProfileIncompleteModal } from "@/dux/customerProfileIncompleteModal/customerProfileIncompleteModalActions";
import { getIsCustomerProfileIncompleteModalOpen } from "@/dux/customerProfileIncompleteModal/customerProfileIncompleteModalSelectors";
import CommonModal from "@/web/common/modals/commonModal";

function CustomerProfileIncompleteModal({ isHidden, onClose }) {
  if (isHidden) {
    return null;
  }
  return (
    <CommonModal isHidden={isHidden} onClose={onClose}>
      <Layout.Box padding="box-padding-8" style={{ height: "100%" }}>
        <Layout.Stack space="stack-space-8">
          <Text size="text-size-xl" align="center">
            Customer profile is incomplete
          </Text>
          <Layout.Cluster space="cluster-space-4">
            <Button variant="prism-primary" onClick={onClose}>
              Close
            </Button>
          </Layout.Cluster>
        </Layout.Stack>
      </Layout.Box>
    </CommonModal>
  );
}

export default connect(
  state => {
    return {
      isHidden: !getIsCustomerProfileIncompleteModalOpen(state),
    };
  },
  dispatch => ({
    onClose: () => {
      dispatch(toggleCustomerProfileIncompleteModal());
    },
  }),
)(CustomerProfileIncompleteModal);
