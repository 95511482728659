import {
  SET_SELECTED_ENHANCED_SERVICE,
  SET_BULK_ENHANCED_SERVICE_LIST,
  // CLEAR_SELECTED_BULK_PACKAGE_ENHANCED_SERVICE,
} from "./bulkPackageEnhancedServiceActions";

const initialState = { selectedEnhancedService: null };

const bulkPackageEnhancedServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ENHANCED_SERVICE: {
      return { ...state, selectedEnhancedService: action.enhancedServiceId };
    }
    case SET_BULK_ENHANCED_SERVICE_LIST:
      return {
        ...state,
        [`${action.petId}-${action.productBundleConfigId}`]: action.enhancedServices,
      };
    default:
      return state;
  }
};

export default bulkPackageEnhancedServicesReducer;
