import { connect } from "react-redux";
import { isBGMEligibilityHidden } from "dux/eligiblePromotions/helpers/isHiddenHelpers";
import TextComponent from "dux/_components/genericLayoutComponents/TextComponent";
import { ELIGIBLE_PROMOTIONS_BGM_PURCHASE_MESSAGE } from "dux/eligiblePromotions/eligiblePromotionConstants";

const mapStateToProps = state => {
  return {
    isHidden: isBGMEligibilityHidden(state),
    text: ELIGIBLE_PROMOTIONS_BGM_PURCHASE_MESSAGE,
    styleType: "div",
    styleObj: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: "1em 0 0 0",
    },
  };
};

export default connect(mapStateToProps)(TextComponent);
