import { createSelector } from "reselect";
import { get } from "lodash/fp";
import { getUiWeb } from "../../core/selectors/uiSelector";
import { getProps } from "../../core/selectors/commonSelector";

export const getArrowViews = createSelector([getUiWeb], uiWeb => uiWeb.arrowViews);

export const getSelectedBulkPackageOfferingsDropdowns = createSelector(
  [getArrowViews],
  arrowViews => {
    return arrowViews.selectedBulkPackageOfferingsDropdowns;
  },
);

export const getSelectedBulkPackageOfferingsPetServiceItem = createSelector(
  [getSelectedBulkPackageOfferingsDropdowns],
  selectedBulkPackageOfferingsDropdowns => {
    // at this time, there is only one pet-service item that can be selected
    // that's why we can confidently index the array by 0
    return Number(Object.keys(selectedBulkPackageOfferingsDropdowns)[0]);
  },
);

export const getIsSelectedBulkPackageOfferingDropdownSelected = createSelector(
  [getSelectedBulkPackageOfferingsDropdowns, getProps],
  (selectedBulkPackageOfferingsDropdowns, { petServiceId }) => {
    return get(petServiceId, selectedBulkPackageOfferingsDropdowns);
  },
);

export const getSelectedBulkHistoryRedemptionsDropdowns = createSelector(
  [getArrowViews],
  arrowViews => arrowViews.selectedBulkHistoryRedemptionsDropdowns,
);

export const getIsBulkHistoryRedemptionsDropdownSelected = createSelector(
  [getSelectedBulkHistoryRedemptionsDropdowns, getProps],
  (selectedBulkHistoryRedemptionsDropdowns, { bundleNumber }) =>
    get(bundleNumber, selectedBulkHistoryRedemptionsDropdowns),
);
