import { connect } from "react-redux";
import SmallPillButton from "../../../_components/buttons/SmallPillButton";
import { getHotelDeparturesFilterBy } from "./hotelDeparturesPetServiceSelector";

import {
  filterByAllDepartures,
  filterByAttentionNeededDepartures,
  filterByDayCampDepartures,
  filterByDayCareDepartures,
  filterByOverNightDepartures,
  filterByUnassignedPetDepartures,
} from "./hotelDeparturesFilterButtonActions";

const mapStateToProps = state => {
  const filterBy = getHotelDeparturesFilterBy(state);

  return {
    isHidden: false,
    componentID: "SmallPillButton_HotelDeparturesAllFilterButton",
    label: "All",
    selected: filterBy.all,
    disabled: false,
    filterBtnStatus: filterBy.all,
  };
};

const mapDispatchToProps = dispatch => ({
  handleFilterByOverNightDepartures: ({ filterBtnStatus }) => {
    if (filterBtnStatus) {
      // toggle to off
      dispatch(filterByAllDepartures({ filterBtnStatus: !filterBtnStatus }));

      // set all other buttons to false;
      dispatch(filterByAttentionNeededDepartures({ filterBtnStatus: false }));
      dispatch(filterByOverNightDepartures({ filterBtnStatus: false }));
      dispatch(filterByDayCampDepartures({ filterBtnStatus: false }));
      dispatch(filterByDayCareDepartures({ filterBtnStatus: false }));
      dispatch(filterByUnassignedPetDepartures({ filterBtnStatus: false }));
    } else {
      // toggle to on
      dispatch(filterByAllDepartures({ filterBtnStatus: !filterBtnStatus }));

      // set all other buttons to true;
      dispatch(filterByAttentionNeededDepartures({ filterBtnStatus: true }));
      dispatch(filterByOverNightDepartures({ filterBtnStatus: true }));
      dispatch(filterByDayCampDepartures({ filterBtnStatus: true }));
      dispatch(filterByDayCareDepartures({ filterBtnStatus: true }));
      dispatch(filterByUnassignedPetDepartures({ filterBtnStatus: true }));
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleClick: () =>
    propsFromDispatch.handleFilterByOverNightDepartures({
      filterBtnStatus: propsFromState.filterBtnStatus,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SmallPillButton);
