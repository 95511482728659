import { createSelector } from "reselect";
import { getRoomTypesBySpecies } from "dux/hotelRoomTypes/hotelRoomTypesSelectors";
import { getBeddingTypes } from "dux/hotelBeddingTypes/hotelBeddingTypesSelectors";
import getPrismPetState from "dux/prismPet/prismPetSelector";

export const getDDCPetDetailsFormValues = createSelector(
  [getRoomTypesBySpecies, getBeddingTypes, getPrismPetState],
  (roomTypes, beddingTypes, petProfile) => {
    return {
      roomTypes,
      beddingTypes,
      petProfile,
    };
  },
);
