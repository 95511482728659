import React from "react";
import { getCurrentPetServiceId } from "@/core/selectors/cartSelectors";
import { selectPrePaidBundleEnhancedServicePrice } from "@/dux/_selectors/addOnsSelectors";
import { TextPassage } from "@petsmart-ui/sparky";
import { connect } from "react-redux";
import { color } from "@/web/common/styles/theme";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { getEnhancedServicesWithDynamicPrice } from "@/core/selectors/dynamicPricesSelector";
import { selectIsEnhancedServiceInBundle } from "@/dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";

const BulkPackageEnhancedServiceAppliedComponent = ({
  isHidden,
  componentId,
  price,
  label = "Package Enhanced Discount",
  styleObj,
}) => {
  if (isHidden) return null;
  return (
    <LayoutCluster id={componentId} style={{ justifyContent: "space-between", ...styleObj }}>
      <TextPassage>{label}</TextPassage>
      <TextPassage>{price}</TextPassage>
    </LayoutCluster>
  );
};

export const BulkPackageEnhancedServiceApplied = connect((state, { petId }) => {
  const petServiceId = getCurrentPetServiceId(state, { petId });
  const price = selectPrePaidBundleEnhancedServicePrice(state, { petId, petServiceId });

  // Check if enhanced service from current itinerary is from a bundle
  const enhancedService = getEnhancedServicesWithDynamicPrice(state, { petId }) ?? {};
  const isRedeemedBundleEnhancedService = selectIsEnhancedServiceInBundle(state, {
    petId,
    petServiceId,
    addOnId: enhancedService?.addOnId,
  });

  return {
    componentId: "BulkPackageEnhancedServiceApplied",
    isHidden: !isRedeemedBundleEnhancedService,
    price: `-$${price}`,
    styleObj: { color: color.lightGrey },
  };
})(BulkPackageEnhancedServiceAppliedComponent);
