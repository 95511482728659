import specialsActionTypes from "../../core/actionTypes/specialsActionTypes";

export const loadSpecials = ({ petServiceItemId, itineraryId }) => ({
  type: specialsActionTypes.LOAD_SPECIALS,
  petServiceItemId,
  itineraryId
});

export const loadSpecialsRequest = () => ({
  type: specialsActionTypes.LOAD_SPECIALS_REQUEST
});

export const loadSpecialsSuccess = ({ specials }) => ({
  type: specialsActionTypes.LOAD_SPECIALS_SUCCESS,
  payload: specials
});

export const loadSpecialsFailure = ({ error }) => ({
  type: specialsActionTypes.LOAD_SPECIALS_FAILURE,
  error
});

export const postSpecials = ({ customerId, itineraryId, specials }) => ({
  type: specialsActionTypes.POST_SPECIALS,
  customerId,
  itineraryId,
  specials
});

export const postSpecialsRequest = () => ({
  type: specialsActionTypes.POST_SPECIALS_REQUEST
});

export const postSpecialsSuccess = ({ itinerary }) => ({
  type: specialsActionTypes.POST_SPECIALS_SUCCESS,
  payload: itinerary
});

export const postSpecialsFailure = ({ error }) => ({
  type: specialsActionTypes.POST_SPECIALS_FAILURE,
  error
});

export const removeSpecials = ({ customerId, itineraryId, specials }) => ({
  type: specialsActionTypes.REMOVE_SPECIALS,
  customerId,
  itineraryId,
  specials
});

export const removeSpecialsRequest = () => ({
  type: specialsActionTypes.REMOVE_SPECIALS_REQUEST
});

export const removeSpecialsSuccess = ({ itinerary }) => ({
  type: specialsActionTypes.REMOVE_SPECIALS_SUCCESS,
  payload: itinerary
});

export const removeSpecialsFailure = ({ error }) => ({
  type: specialsActionTypes.REMOVE_SPECIALS_FAILURE,
  error
});

export default {
  loadSpecials,
  loadSpecialsRequest,
  loadSpecialsSuccess,
  loadSpecialsFailure,
  postSpecials,
  postSpecialsRequest,
  postSpecialsSuccess,
  postSpecialsFailure,
  removeSpecials,
  removeSpecialsRequest,
  removeSpecialsSuccess,
  removeSpecialsFailure
};
