import React from "react";
import { connect } from "react-redux";
import {
  getPetVaccinationOptions,
  getIsAddVaccinationFormOpen,
} from "@/core/selectors/ui/vaccinations/vaccinationsSelectors";
import { getSelectedPet } from "@/core/selectors/bookingUISelectors";
import petVaccinationsActionCreators from "@/core/actionCreators/petVaccinationsActionCreators";
import Section from "@/web/common/Section";
import { PetVaccinationsForm } from "./PetVaccinationsForm";
import { PetVaccinationsList } from "./PetVaccinationsList";
import { LayoutStack } from "@/layout/stack/Stack";

export function PetVaccinationsComponent({
  vaccinationOptions,
  toggleAddVaccinationForm,
  isAddVaccinationFormOpen,
}) {
  return (
    <Section
      topLine
      button="Add vaccination"
      title="Vaccinations"
      buttonDisabled={!vaccinationOptions.length}
      buttonClickHandler={toggleAddVaccinationForm}
    >
      <LayoutStack space="scale-G1">
        {isAddVaccinationFormOpen && <PetVaccinationsForm />}
        <PetVaccinationsList />
      </LayoutStack>
    </Section>
  );
}

export const PetVaccinations = connect(
  state => ({
    isAddVaccinationFormOpen: getIsAddVaccinationFormOpen(state),
    vaccinationOptions: getPetVaccinationOptions(state, { petId: getSelectedPet(state) }),
  }),
  dispatch => ({
    toggleAddVaccinationForm: () =>
      dispatch(petVaccinationsActionCreators.toggleAddVaccinationForm()),
  }),
)(PetVaccinationsComponent);
