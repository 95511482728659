import { combineReducers } from "redux";
import newFoodReducer from "../../newFood/newFoodReducer";
import foodsReducer from "./foodsReducer";

const foodReducer = combineReducers({
  newFood: newFoodReducer,
  foods: foodsReducer,
});

export default foodReducer;
