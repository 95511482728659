import { Layout } from "@prism/psm-ui-components";
import { CheckInOutNewFood } from "dux/_components/checkInOutFeeding/CheckInOutFoodForm";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSystemBookingFlow } from "web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { getIsPetCardShowing } from "dux/petCartCard/selectors/petCartCardSelectors";
import { medicationTypes } from "web/newMedication/newMedicationConstants";
import FoodSectionContainer from "web/newFood/FoodSectionContainer";
import { CheckInOutPetFoodList } from "@/web/foodList/FoodList";
import { CheckInOutTotalFeedingCharges } from "@/dux/feedingCharges/FeedingCharges";
import { getFoodOptionsList } from "@/dux/foodOptionsList/foodOptionsListActions";
import selectIsDiscontinuedFoodsFeatureHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/discontinuedFoods/getIsDiscontinuedFoodsWorkflowFeatureFlagHidden";

export const CheckInOutFoodComponent = props => {
  const { isHidden, petId, locationType, componentId, loadFoodOptions } = props;

  const [invalidFeedingForms, setFeedingFormInvalid] = useState([]);

  useEffect(() => {
    loadFoodOptions();
  }, []);

  if (!isHidden) {
    return (
      <Layout.Stack id={componentId} space="stack-space-7">
        <FoodSectionContainer petId={petId} locationType={locationType}>
          <CheckInOutNewFood
            setFeedingFormInvalid={setFeedingFormInvalid}
            invalidFeedingForms={invalidFeedingForms}
          />
          <CheckInOutPetFoodList
            petId={petId}
            locationType={locationType}
            setFeedingFormInvalid={setFeedingFormInvalid}
            invalidFeedingForms={invalidFeedingForms}
          />

          <CheckInOutTotalFeedingCharges petId={petId} />
        </FoodSectionContainer>
      </Layout.Stack>
    );
  }
  return null;
};

export const CheckInOutFoods = connect(
  (state, { petId }) => {
    const systemFlow = getSystemBookingFlow(state);
    return {
      componentId: `checkInOut_foods`,
      petId,
      isHidden: systemFlow !== systemName.HOTEL || !getIsPetCardShowing(state, { petId }),
      hasPadding: "1rem 1rem 0 1rem",
      locationType: medicationTypes.CART,
      isFoodFeatureHidden: selectIsDiscontinuedFoodsFeatureHidden(state),
    };
  },
  dispatch => ({
    loadFoodOptions: () => dispatch(getFoodOptionsList()),
  }),
  (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    loadFoodOptions: () => {
      const { isFoodFeatureHidden } = stateProps;
      const { loadFoodOptions } = dispatchProps;
      if (!isFoodFeatureHidden) loadFoodOptions();
    },
  }),
)(CheckInOutFoodComponent);
