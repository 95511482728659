export default function cookieExist(cookieKey) {
  if (document.cookie.split(";").filter(item => item.trim().startsWith(cookieKey)).length) {
    const cookieList = document.cookie.split(";").filter(item => item.trim());

    const cookie = cookieList.find(element => element.trim().startsWith(cookieKey));

    const cookieValue = !!cookie.match(/true/g);

    return cookieValue;
  }
}
