import { convertTimeOfDayObjToArr } from "@/core/utils/checkInOutUtils";
import { NEW_MEDICATION_ID } from "../newMedication/newMedicationConstants";

export const isNewMedication = medicationId => {
  return medicationId === NEW_MEDICATION_ID;
};

export const formatMedication = medication => {
  if (!medication) {
    return {};
  }
  const {
    petMedicationId,
    name,
    groupingId,
    amount,
    timeOfDay,
    specialInstructions,
    externalId,
    petProfileMedId,
  } = medication;
  return {
    // In the booking flow petMedicationId will be overridden but the original value will be stored in petProfileMedId
    petMedicationId: petProfileMedId ?? petMedicationId,
    name,
    groupingId,
    amount,
    timeOfDay,
    specialInstructions,
    externalId,
  };
};

export const formatMedFromCheckIn = med => {
  if (!med) return {};

  const { medDisplayName, otherMedName, medAmount, medInstructions, externalId } = med;

  return {
    name: otherMedName || medDisplayName,
    amount: medAmount,
    timeOfDay: convertTimeOfDayObjToArr(med),
    specialInstructions: medInstructions,
    externalId,
  };
};
