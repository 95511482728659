import React from 'react';
import { psx } from '../../utils/clsx';
import { Layout, StackProps } from '../../components/Layout';
import Button, { ButtonProps } from '../../components/Button';
import Text, { TextProps } from '../../components/Text';
import SvgIcon from '../../components/Icon';
import * as CSS from 'csstype';

export interface PaginatedStackProps extends StackProps {
  /** Total number of pages */
  total: number;

  /** Optional prop to change the alignment of the pagination controls */
  JustifyControls?: CSS.Property.JustifyContent;

  /** A function that returns the current page of rendered elements given a page index */
  children: (currentPage: number) => React.ReactNode;

  /** Optional prop to override props for the prev button, won't affect isHidden or disabled */
  overridePrevButtonProps?: ButtonProps;

  /** Optional prop to override props for the next button, won't affect isHidden or disabled */
  overrideNextButtonProps?: ButtonProps;

  /** Optional prop to override the props for the page label Text component */
  overrideTextProps?: TextProps<React.ElementType>;
}

export interface PaginatedStackState {
  /** The index of the current page starting at 1 */
  currentPage: number;
}

// Had to use a class component instead of functional component because using hooks was throwing an Invalid Hook Call error in PRISM
export class PaginatedStack extends React.Component<PaginatedStackProps, PaginatedStackState> {
  constructor(props: PaginatedStackProps) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }

  render() {
    const {
      componentId,
      total = 1,
      overridePrevButtonProps,
      overrideNextButtonProps,
      overrideTextProps,
      JustifyControls = 'center',
      isHidden = false,
      className,
      children,
      ...props
    } = this.props;
    const { currentPage } = this.state;
    const classNames = psx('psm', 'psm-paginated-stack', className);

    const hasPrev = currentPage > 1;
    const hasNext = currentPage < total;

    const onPrevClick = () => this.setState((prev) => ({ currentPage: prev.currentPage - 1 }));
    const onNextClick = () => this.setState((prev) => ({ currentPage: prev.currentPage + 1 }));

    const commonBtnProps: ButtonProps = {
      variant: 'primary',
      style: {
        minWidth: 'auto',
        padding: '0.25em',
        display: 'flex',
      },
    };

    const prevBtnProps: ButtonProps = {
      ...commonBtnProps,
      title: 'Prev',
      children: <SvgIcon variant="hero-icon-left" />,
      ...overridePrevButtonProps,
      onClick: (e) => {
        onPrevClick();
        if (overridePrevButtonProps?.onClick) overridePrevButtonProps.onClick(e);
      },
      disabled: !hasPrev,
      isHidden: !hasPrev
    }

    const nextBtnProps: ButtonProps = {
      ...commonBtnProps,
      title: 'Next',
      children: <SvgIcon variant="hero-icon-right" />,
      ...overrideNextButtonProps,
      onClick: (e) => {
        onNextClick();        
        if (overrideNextButtonProps?.onClick) overrideNextButtonProps.onClick(e);
      },
      disabled: !hasNext,
      isHidden: !hasNext
    }

    if (isHidden) return null;

    return (
      <Layout.Stack id={componentId} className={classNames} {...props}>
        {/* Page */}
        {children(currentPage)}

        {/* Pagination Controls */}
        <Layout.Box>
          <Layout.Cluster space="cluster-space-3" justify={JustifyControls}>
            <Button {...prevBtnProps} />

            <Text {...overrideTextProps}>
              Page {currentPage} of {total}
            </Text>

            <Button {...nextBtnProps} />
          </Layout.Cluster>
        </Layout.Box>
      </Layout.Stack>
    );
  }
}

export default PaginatedStack;
