import { connect } from "react-redux";
import { get } from "lodash/fp";

import RemoveSpecial from "./removeSpecial";
import { removeSpecials } from "../../../../../core/actionCreators/specialsActionCreator";
import {
  clearSpecialToRemove,
  showCheckInOutModal,
  hideCheckInOutModal
} from "../../../../../core/actionCreators/checkInOutActionCreator";
import { getSpecialToRemove } from "../../../../../core/selectors/checkInOutSelector";
import { getSpecial } from "../../../../../core/selectors/entitiesSelector";
import { getSelectedItinerary } from "../../../../../core/selectors/bookingUISelectors";
import { hideBookingModal } from "../../../../../core/actionCreators/bookingActionCreators";
import { isFromBooking } from "../../../../../core/utils/validationUtils/isFromValidation";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const mapStateToProps = (state, ownProps) => {
  const specialId = getSpecialToRemove(state);

  return {
    special: specialId && getSpecial(state, { specialId }),
    customerId: get("router.params.customerKey", ownProps),
    itineraryId: get("router.params.itineraryId", ownProps) || getSelectedItinerary(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeSpecial: ({ code, customerId, itineraryId }) =>
    dispatch(
      removeSpecials({
        customerId,
        itineraryId,
        specials: [code]
      })
    ),
  onClose: () => {
    isFromBooking(get("router.location.pathname", ownProps))
      ? dispatch(hideBookingModal())
      : dispatch(hideCheckInOutModal());
  },
  clearSpecialToRemove: () => dispatch(clearSpecialToRemove())
});

export default withRouteProps(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RemoveSpecial)
);
