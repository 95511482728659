import React from "react";
import { color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import AddIconSVGWrappedComponent from "dux/_components/buttons/AddIconSVGWrappedComponent";
import { Button } from "@petsmart-ui/sparky";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { Hover } from "@prism/psm-ui-components";

export default function AddButton(props) {
  const { label, buttonClickHandler, buttonDisabled, isHidden } = props;

  // Style props
  const { opacity = ".5", marginTop = 0, paddingTop = "0px", fontSize } = props;

  if (isHidden) return null;
  return (
    <Button
      {...props}
      variant="link"
      disabled={buttonDisabled}
      onClick={!buttonDisabled && buttonClickHandler ? buttonClickHandler : undefined}
      style={{
        opacity: buttonDisabled ? "0.5" : opacity,
        marginTop,
        paddingTop,
      }}
      text={
        <Hover
          style={{ color: color.tChalla }}
          hoverStyle={buttonDisabled ? {} : { color: color.kalEl }}
        >
          <LayoutCluster space="scale-0" flexWrap="flex-noWrap">
            <AddIconSVGWrappedComponent />
            <span style={{ fontSize: fontSizes ? fontSizes[fontSize] : fontSizes.big }}>
              {label || "Add"}
            </span>
          </LayoutCluster>
        </Hover>
      }
    />
  );
}
