import { connect } from "react-redux";
import { FlashMessage } from "@/dux//flashMessage/FlashMessage";
import { alertThemesTypes } from "@/dux/alert/utils";
import { toggleRemoveTrainerFromVirtualFlashMessage } from "../associateProfile/associateProfilePageActions";
import { getIsRemoveTrainerFromVirtualFlashMessageOpen } from "../associateProfile/associateProfilePageSelectors";

export const RemoveTrainerFromVirtualFlashMessage = connect(
  (state) => ({
    theme: alertThemesTypes.SUCCESS,
    display: getIsRemoveTrainerFromVirtualFlashMessageOpen(state),
    message: "Associate successfully removed from virtual trainer group",
  }),
  (dispatch) => ({
    toggleRemoveTrainerFromVirtualFlashMessage: () => {
      dispatch(toggleRemoveTrainerFromVirtualFlashMessage());
    },
  }),
  (stateProps, dispatchProps) => {
    const { toggleRemoveTrainerFromVirtualFlashMessage } = dispatchProps;
    return {
      ...stateProps,
      onClose: () => {
        toggleRemoveTrainerFromVirtualFlashMessage();
      },
    };
  },
)(FlashMessage);
