import { NUMBER_REGEX } from "../../constants/regex";

/** @module numberValidation */

/**
 * Validates that the string is a number
 *
 * @export verifyNumber
 * @param {string} numberString
 * @returns {boolean}
 */
export default function verifyNumber(numberString) {
  const regXExpression = NUMBER_REGEX;
  return regXExpression.test(numberString);
}
