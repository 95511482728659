import React, { useEffect } from "react";
import { HotelBookingPetSelection } from "dux/hotelBookingPetSelection/HotelBookingPetSelection";
import { HotelBookingAlerts } from "@/dux/hotelBookingAlerts/HotelBookingAlerts";
import { HotelBookingDateTimeSelection } from "dux/hotelBookingDateTimeSelection/HotelBookingDateTimeSelection";
import { HotelBookingServiceSelection } from "@/dux/hotelBookingServiceSelection/HotelBookingServiceSelection";
import { HotelBookingMedsSection } from "@/dux/hotelBookingMedsSection/HotelBookingMedsSection";
import { HotelBookingFoodSection } from "@/dux/hotelBookingFoodSection/HotelBookingFoodSection";
import { HotelBookingRoomSelection } from "dux/hotelBookingServiceSelection/HotelBookingRoomSelection";
import { HotelBookingAddonSection } from "dux/hotelBookingAddonSection/HotelBookingAddonSection";

const HotelBookingFlowContainer = ({
  isHidden,
  isLoadingFrequencies,
  isLoadingEligibility,
  petIds,
  getFrequencies = () => {},
  getEligibility = () => {},
  loadFoodAndMedOptions = () => {},
  isMultiPet = false,
  resetBookingState = () => {},
  loadRebookingItinerary = () => {},
}) => {
  useEffect(() => {
    // Load the frequency picklist options for food, med, and addons
    if (!isLoadingFrequencies && !isHidden) getFrequencies();

    // Load the food & med picklist options
    loadFoodAndMedOptions();

    // Load itinerary if rebooking
    loadRebookingItinerary();
  }, []);

  // On mount & unmount clear any state related to a previous booking or any services cart data left in state
  useEffect(() => {
    resetBookingState();
    return () => {
      resetBookingState();
    };
  }, []);

  useEffect(() => {
    if (!isHidden && !isLoadingEligibility && petIds?.length) getEligibility();
  }, [petIds.join(",")]);

  // Step containers
  const singlePetsStepsInfo = [
    { stepComp: HotelBookingPetSelection },
    { stepComp: HotelBookingAlerts },
    {
      stepComp: HotelBookingDateTimeSelection,
    },
    { stepComp: HotelBookingServiceSelection },
    { stepComp: HotelBookingFoodSection },
    { stepComp: HotelBookingMedsSection },
    {
      stepComp: HotelBookingAddonSection,
    },
  ];

  const multiPetStepsInfo = [
    { stepComp: HotelBookingPetSelection },
    { stepComp: HotelBookingAlerts },
    {
      stepComp: HotelBookingDateTimeSelection,
    },
    { stepComp: HotelBookingRoomSelection },
    { stepComp: HotelBookingServiceSelection },
    { stepComp: HotelBookingFoodSection },
    { stepComp: HotelBookingMedsSection },
    {
      stepComp: HotelBookingAddonSection,
    },
  ];

  const stepsInfo = isMultiPet ? multiPetStepsInfo : singlePetsStepsInfo;

  if (!isHidden) {
    return (
      <>
        {stepsInfo.map((step, index) => {
          const StepComponent = step?.stepComp;
          return <StepComponent key={`bookingStep-${index}`} stepIndex={index} />;
        })}
      </>
    );
  }

  return null;
};

export default HotelBookingFlowContainer;
