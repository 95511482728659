import { createSelector } from "reselect";
import { getPrismPetProfiles } from "core/utils/petUtils/petProfileUtils";
import { getPets, getPet } from "../../core/selectors/entitiesSelector";

/**
 * Responsible for returning the prismPet state from  Pet in the Redux store.
 *
 * @todo refactor to use the getPrismPetProfiles util function
 * Example of use: getPrismPetState(state, {petId});
 */
const getPrismPetState = createSelector([getPet], pet => {
  // Doing a double check for undefined 1st for pet, then prismPet, If neither is
  // undefined, we return a actual bool value.
  const isProfileAvailable = !!(pet && pet.prismPet);

  if (isProfileAvailable) {
    return pet.prismPet;
  }

  return {};
});

/**
 * A Selector to get a list of pet profiles from all pets. If pets are not loaded in state the selector will an empty array.
 *
 * @memberOf Selectors.Entities
 * @function
 * @name selectAllPrismPetProfiles
 * @param {Object} state - Redux state
 * @param {Array} listOfPetIds - a list of Pet ids (e.g. [1000022397, 1000022396])
 * @returns {Array} - An array of pet profiles. If pets are not loaded in state the selector will an empty array.
 *
 * @example
 *
 * const profiles = selectAllPrismPetProfiles(state, listOfPetIds);
 */
export const selectAllPrismPetProfiles = createSelector(
  [getPets, (pets, listOfPetIds) => listOfPetIds],
  (pets, listOfPetIds) => {
    // Verify Pets are loaded
    if (pets) {
      // curried map function
      const curriedMap = mapFn => array => array.map(mapFn);

      // pass the function gets a single prism pet profile from the pets object.
      const mapGetProfile = curriedMap(getPrismPetProfiles(pets));

      // Send the list of IDs the getPrismPetProfiles needs to map over.
      const profiles = mapGetProfile(listOfPetIds);

      // return the list of profile
      return profiles;
    }

    // If not, return an empty array
    return [];
  },
);

export default getPrismPetState;
