import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get a list of the values available for price adjustment reasons.
 * These are required to allow a store manager to select the reason
 * for adjusting the price of an order.
 * For System Services Booking API -
 * Get list of reasons for adjusting the price of an order product.
 * @returns {*} AxiosPromise - Array of Objects
 */
export function fetchPriceAdjustmentReasons({ appType }) {
  const type = appType || "Salon";
  return bookingAPI.get(
    `${endpointVersionString()}/settings/priceadjustmentreasons?appType=${type}`,
  );
}
