import {
  SET_TRAINING_ATTENDEES,
  GET_TRAINING_CLASS_SESSIONS_SUCCESS,
  DELETE_TRAINING_CLASS_SESSION_SUCCESS,
  SET_TRAINING_SESSION_APPOINTMENT_DATES,
  SET_TRAINING_CLASS_CACHED_MONTH,
  TOGGLE_TRAINING_CLASS_FORM,
  RESET_TRAINING_CLASS_SESSIONS,
} from "./actions";
import shallowMergeObjects from "@/dux/utils/objectUtils/shallowMergeObjects";

const initialCachedMonths = {};
const initialClassSessions = {};
const initialAttendees = {};
const initialAppointmentDates = {};
const initialTrainingViews = {
  isAddTrainingFormHidden: true,
};

export const trainingViewsReducer = (state = initialTrainingViews, action) => {
  switch (action.type) {
    case TOGGLE_TRAINING_CLASS_FORM:
      return {
        ...state,
        isAddTrainingFormHidden: !state.isAddTrainingFormHidden,
      };
    default:
      return state;
  }
};

export const trainingClassSessionsReducer = (state = initialClassSessions, action) => {
  switch (action.type) {
    case DELETE_TRAINING_CLASS_SESSION_SUCCESS:
      const { [action.payload.classNumber]: omittedValue, ...rest } = state;

      return rest;

    case GET_TRAINING_CLASS_SESSIONS_SUCCESS:
      return shallowMergeObjects(state, action.payload);

    case RESET_TRAINING_CLASS_SESSIONS:
      return initialClassSessions;

    default:
      return state;
  }
};

export const trainingAttendeesReducer = (state = initialAttendees, action) => {
  switch (action.type) {
    case SET_TRAINING_ATTENDEES:
      return shallowMergeObjects(state, action.payload);
    default:
      return state;
  }
};

export const trainingSessionAppointmentDatesReducer = (state = initialAppointmentDates, action) => {
  switch (action.type) {
    case SET_TRAINING_SESSION_APPOINTMENT_DATES:
      return shallowMergeObjects(state, action.payload);
    default:
      return state;
  }
};

export const trainingClassCachedMonthsReducer = (state = initialCachedMonths, action) => {
  switch (action.type) {
    case SET_TRAINING_CLASS_CACHED_MONTH:
      return {
        ...state,
        cachedMonths: action.cachedMonths,
      };
    default:
      return state;
  }
};
