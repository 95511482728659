import { put, takeLatest, all, call, select } from "redux-saga/effects";

// Actions
import {
  GET_OVERBOOKING_SETUP_DATA,
  setOverBookingSetupDataRequest,
  setOverBookingSetupDataSuccess,
  setOverBookingSetupDataFailure,
} from "./overBookingSetupTableActions";

import { fetchHotelOverBookingSetUp } from "@/core/services/associateWebBff/hotelOverbookingExceptions";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { formatCalendarDateMoment } from "@/core/utils/dateUtils/formatDateTime";

/**
 * Generator function to fetch data for overbooking setup table
 */
export function* onGetOverBookingSetupData() {
  try {
    yield put(setOverBookingSetupDataRequest());

    const storeNumber = yield select(getStoreNumber);

    const response = yield call(fetchHotelOverBookingSetUp, {
      storeNumber,
      date: formatCalendarDateMoment(),
    });

    yield put(setOverBookingSetupDataSuccess(response?.data?.result));
  } catch (error) {
    yield put(setOverBookingSetupDataFailure(error));
  }
}

function* watchOnGetOverBookingSetupData() {
  yield takeLatest(GET_OVERBOOKING_SETUP_DATA, onGetOverBookingSetupData);
}

export default function* overBookingSetupDataTableSaga() {
  yield all([watchOnGetOverBookingSetupData()]);
}
