import {
  filter,
  findKey,
  flow,
  forEach,
  groupBy,
  keys,
  mapValues,
  sortBy,
  isEmpty,
} from "lodash/fp";
import moment from "moment";
import { absenceReasons, dayActivityTypes } from "../../../core/constants/schedulesConstants";
import { forEachWithKey } from "../../../core/utils/general/loopsAndMaps";
import { timeRangeErrors } from "../../../core/constants/associateSchedulingConstants";

const getFilteredActivities = activities => {
  const workHours = filter(activity => {
    return activity.type === dayActivityTypes.WORK || activity.type === dayActivityTypes.RELOCATION;
  }, activities);
  return (workHours.length > 1 ? activities : filter(
    activity => activity.absenceReason !== absenceReasons.LUNCH_BREAK, activities));
};

export const checkWeeklyErrors = dayActivities => {
  let errorMessage = "";

  // =============================== //
  // check overlap times on same day //
  // =============================== //

  const dayActivitiesForOverlap = flow([
    filter(
      value =>
        value.absenceReason !== absenceReasons.DAY_OFF,
    ),
    groupBy("dayOfWeek"),
  ])(dayActivities);

  forEach(activities => {
    activities = getFilteredActivities(activities);
    if (activities.length > 1) {
      for (let i = 0; i < activities.length; i += 1) {
        for (let j = 0; j < activities.length; j += 1) {
          const firstActivityStartMoment = moment(activities[i].startTime, "HH:mm");
          const firstActivityEndMoment = moment(activities[i].endTime, "HH:mm");
          const secondActivityStartMoment = moment(activities[j].startTime, "HH:mm");
          const secondActivityEndMoment = moment(activities[j].endTime, "HH:mm");

          if (i !== j) {
            if (
              firstActivityEndMoment.isBetween(
                secondActivityStartMoment,
                secondActivityEndMoment,
              ) ||
              firstActivityStartMoment.isBetween(
                secondActivityStartMoment,
                secondActivityEndMoment,
              ) ||
              (firstActivityStartMoment.isSame(secondActivityStartMoment) &&
                firstActivityEndMoment.isSame(secondActivityEndMoment))
            ) {
              if (!errorMessage) {
                errorMessage = `Times overlaps on ${activities[i].dayOfWeek}`;
              }
            }
          }
        }
      }
    }
  })(dayActivitiesForOverlap);

  // ====================================== //
  // check time gaps between activities on same day
  // ====================================== //
  const dayActivitiesForTimeGaps = {};

  forEachWithKey((activities, day) => {
    activities = getFilteredActivities(activities);
    dayActivitiesForTimeGaps[day] = sortBy(
      activity => moment(activity.startTime, "HH:mm"),
      activities,
    );
  }, dayActivitiesForOverlap);

  forEachWithKey((activities, day) => {
    activities = getFilteredActivities(activities);
    if (activities.length > 1) {
      for (let i = 1; i < activities.length; i += 1) {
        const prevActivity = activities[i - 1];
        const currActivity = activities[i];

        if (prevActivity.endTime !== currActivity.startTime) {
          errorMessage = `${errorMessage}
          Cannot save partial day relocation with a gap on ${day}`;
        }
      }
    }
  }, dayActivitiesForTimeGaps);

  if (errorMessage) {
    return errorMessage;
  }

  // ====================================== //
  // check multiple relocations on same day //
  // ====================================== //
  const dayWithMultipleRelocations = flow([
    filter({ type: dayActivityTypes.RELOCATION }),
    groupBy("dayOfWeek"),
    mapValues(value => value.length),
    findKey(value => value > 1),
  ])(dayActivities);

  if (dayWithMultipleRelocations) {
    return `${dayWithMultipleRelocations} have multiple relocations.`;
  }

  // ====================================== //
  // check "Day Off" is the Only activity available on same day
  // ====================================== //
  const daysWithDayOff = flow([
    filter({ absenceReason: absenceReasons.DAY_OFF }),
    groupBy("dayOfWeek"),
    keys,
  ])(dayActivities);

  const dayWithDayOffAndAnotherActivity = flow([
    filter(
      dayActivity =>
        dayActivity.absenceReason !== absenceReasons.DAY_OFF &&
        daysWithDayOff.includes(dayActivity.dayOfWeek),
    ),
    groupBy("dayOfWeek"),
    mapValues(value => value.length),
    findKey(value => value > 0),
  ])(dayActivities);

  if (dayWithDayOffAndAnotherActivity) {
    return `${dayWithDayOffAndAnotherActivity} can't be "Day Off" and something else.`;
  }

  // everything is OK
  return false;
};

export const getTimeRangeError = ({ startTime, endTime, daySalonHours, type }) => {
  const startMoment = moment(startTime, "HH:mm");
  const endMoment = moment(endTime, "HH:mm");
  const isStartTimeBeforeEndTime = startMoment.isBefore(endMoment);

  if (isEmpty(daySalonHours)) {
    if (type === dayActivityTypes.WORK) {
      return timeRangeErrors.OUTSIDE_STORE_HOURS;
    }

    return !isStartTimeBeforeEndTime && timeRangeErrors.START_EARLIER_THAN_END;
  }

  const storeOpenMoment = moment(daySalonHours.OpenTime, "HH:mm");
  const storeCloseMoment = moment(daySalonHours.CloseTime, "HH:mm");

  if (!isStartTimeBeforeEndTime) {
    return timeRangeErrors.START_EARLIER_THAN_END;
  } else if (
    (startMoment.isBefore(storeOpenMoment) || endMoment.isAfter(storeCloseMoment)) &&
    type !== dayActivityTypes.ABSENCE
  ) {
    return timeRangeErrors.OUTSIDE_STORE_HOURS;
  } else if (
    (startMoment.isBefore(storeOpenMoment) || endMoment.isAfter(storeCloseMoment)) &&
    daySalonHours.isClosed
  ) {
    return timeRangeErrors.OUTSIDE_STORE_HOURS;
  }

  return null;
};
