import { connect } from "react-redux";
import { displayRefundPackageConfirmationModal } from "./refundPackageConfirmationModalActions";
import RefundPackageConfirmationModalComponent from "../_components/refundPackageModals/RefundPackageConfirmationModalComponent";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getRefundBundleNumberForConfirmationModal } from "./refundPackageConfirmationModalSelectors";
import {
  refundPackage,
  PUT_REFUND_PACKAGE,
} from "../bgm/bulkPackageOfferings/bulkPackageOfferingsActions";
import { createLoadingSelector } from "@/core/selectors/utils";

const mapStateToProps = state => ({
  isHidden: false,
  headerText: "Refund package",
  messageText: "Are you sure you want to refund this package?",
  cancelButtonLabel: "Cancel",
  confirmButtonLabel: "Refund",
  storeNumber: getStoreNumber(state),
  customerKey: getCurrentCustomerKey(state),
  bundleConfigId: getRefundBundleNumberForConfirmationModal(state),
  isRefundPackageLoading: createLoadingSelector([PUT_REFUND_PACKAGE])(state),
});

const mapDispatchToProps = dispatch => ({
  onModalClose: () => dispatch(displayRefundPackageConfirmationModal({ isHidden: true })),
  dispatchOnSubmit: ({ storeNumber, customerKey, bundleConfigId }) => {
    dispatch(refundPackage({ storeNumber, customerKey, bundleConfigId }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSubmit: () => {
    propsFromDispatch.dispatchOnSubmit({
      storeNumber: propsFromState.storeNumber,
      customerKey: propsFromState.customerKey,
      bundleConfigId: propsFromState.bundleConfigId,
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(RefundPackageConfirmationModalComponent);
