import { connect } from "react-redux";
import { getFutureAppointmentsByCustomer } from "../../../../../core/selectors/entitiesSelector";
import { getCurrentCustomerKey } from "../../../../../core/selectors/persistent/customer/customerSelectors";
import CommonAppointmentComponent from "../common/CommonAppointmentListComponent";

// NOTE: This container is identical to the regular
// appointments list container for now, but we want
// the flexibility to change the appointment list display
// behavior when BGM is implemented.

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  return {
    type: "future",
    title: "Future Appointments",
    appointments: getFutureAppointmentsByCustomer(state, { customerKey }),
    componentID: "FutureAppointmentsList",
  };
};

export default connect(mapStateToProps)(CommonAppointmentComponent);
