import React from "react";
import { connect } from "react-redux";

// Components
import { TextInput } from "@prism/psm-ui-components";

// Actions
import { setHotelRoomManagementSearch } from "./hotelRoomManagementSearchActions";

const HotelRoomManagementSearchBox = props => {
  const { componentId, handleOnChange } = props;
  return (
    <TextInput
      id={componentId}
      type="search"
      placeholder="Filter by room number"
      onChange={handleOnChange}
    ></TextInput>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const HotelRoomManagementSearch = connect(
  state => {
    return {
      componentId: "HotelRoomManagementSearchBox",
    };
  },

  dispatch => {
    return {
      handleOnChange: ({ target }) => dispatch(setHotelRoomManagementSearch(target.value)),
    };
  },
)(HotelRoomManagementSearchBox);
