import { LayoutBox } from "@/layout/box/Box";
import React from "react";
import PriceComponent from "../_components/pricing/PriceComponent";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { connect } from "react-redux";
import { selectPrePaidBundleEnhancedServicePrice } from "../_selectors/addOnsSelectors";
import { getAppointmentByPet } from "@/core/selectors/cartSelectors";
import { getEnhancedServicesWithDynamicPrice } from "@/core/selectors/dynamicPricesSelector";
import { selectIsEnhancedServiceInBundle } from "../bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import { Text } from "@prism/psm-ui-components";
import { BulkPackageEnhancedServiceApplied } from "../_components/bgm/BulkPackageEnhacedServiceAppliedComponent";
import { isEmpty } from "lodash";
import { selectIsEnhancedServicesVisible } from "../enhancedServicesList/EnhancedServicesListSelectors";

export const EnhancedServiceDetails = props => {
  const { isHidden, componentId, petId, name, addOnId, price = "0.00", showPriceChange } = props;

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutCluster>
        <Text>{name}</Text>

        <LayoutBox padding="scale-0" style={{ marginLeft: "auto" }}>
          <LayoutCluster space="scale-0">
            {showPriceChange && <PriceComponent type="" price="0.00" />}
            <PriceComponent type={showPriceChange ? "price-change" : ""} price={price} />
          </LayoutCluster>
        </LayoutBox>
      </LayoutCluster>

      <BulkPackageEnhancedServiceApplied addOnId={addOnId} petId={petId} />
    </LayoutBox>
  );
};

export const SalonEnhancedServiceDetails = connect((state, { petId }) => {
  const isEnhancedServicesVisible = selectIsEnhancedServicesVisible(state, { petId });

  const appointment = getAppointmentByPet(state, { petId });
  const petServiceId = appointment?.petService;

  // Enhanced service from current itinerary
  const enhancedService = getEnhancedServicesWithDynamicPrice(state, { petId }) ?? {};
  const addOnId = enhancedService?.addOnId;

  // Check if the enhanced service is part of a bundle redemption
  const isRedeemedBundleEnhancedService = selectIsEnhancedServiceInBundle(state, {
    petId,
    petServiceId,
    addOnId,
  });

  const preSelectedBundleEnhancedServicePrice = selectPrePaidBundleEnhancedServicePrice(state, {
    petId,
    petServiceId,
  });

  const enhancedServicePrice = enhancedService?.price || enhancedService?.currentPrice;
  const enhancedServicePriceFormatted = enhancedServicePrice
    ? enhancedServicePrice?.toFixed(2)
    : preSelectedBundleEnhancedServicePrice;

  return {
    componentId: "SalonEnhancedServiceDetails",
    isHidden: !isEnhancedServicesVisible || isEmpty(enhancedService),
    name: enhancedService?.addOnName,
    addOnId,
    price: enhancedServicePriceFormatted,
    showPriceChange: isRedeemedBundleEnhancedService,
  };
})(EnhancedServiceDetails);
