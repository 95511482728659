/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const SET_SELECTED_REPORT_DATE = "SET_SELECTED_REPORT_DATE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 * Action to set selected report date
 * @memberOf Actions.Reports
 * @function
 * @name setSelectedReportDate
 * @param {string} selectedReportDate
 * @returns {object}
 * @example setSelectedReportDate()
 */
export const setSelectedReportDate = selectedReportDate => ({
  type: SET_SELECTED_REPORT_DATE,
  selectedReportDate,
});
