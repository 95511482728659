// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-layout__grid {
  --gridCellSpace: ;
  --gridCellMinWidth: ;
  display: grid;
  grid-gap: var(--gridCellSpace);
}

@supports (width: min(var(--gridCellMinWidth), 100%)) {
  .psm-layout__grid {
    /* ↓ Enhance with the min() function into multiple columns */
    grid-template-columns: repeat(auto-fit, minmax(min(var(--gridCellMinWidth), 100%), 1fr));
  }
}
`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/components/Layout/Grid/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE;IACE,4DAA4D;IAC5D,wFAAwF;EAC1F;AACF","sourcesContent":[".psm-layout__grid {\n  --gridCellSpace: ;\n  --gridCellMinWidth: ;\n  display: grid;\n  grid-gap: var(--gridCellSpace);\n}\n\n@supports (width: min(var(--gridCellMinWidth), 100%)) {\n  .psm-layout__grid {\n    /* ↓ Enhance with the min() function into multiple columns */\n    grid-template-columns: repeat(auto-fit, minmax(min(var(--gridCellMinWidth), 100%), 1fr));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
