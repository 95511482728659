import React from "react";
import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 16px;
  margin-bottom: 2.5em;
`;

const animation = keyframes`
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
`;

const Spinner = styled.div`
  opacity: 1;
  position: relative;
  transition: opacity linear 0.1s;
  &::before {
    animation: 1s linear infinite ${animation};
    border: solid 3px #eee;
    border-bottom-color: #b6b6b6;
    border-radius: 50%;
    content: "";
    height: 40px;
    left: 50%;
    opacity: inherit;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    width: 40px;
    will-change: transform;
  }
`;

export default ({ showSpinner }) => (
  <Wrapper>
    <Title>Loading...</Title>
    {showSpinner ? <Spinner /> : null}
  </Wrapper>
);
