// @ts-check
import React, { useEffect } from "react";
import { connect } from "react-redux";

// Components
import { Layout } from "@prism/psm-ui-components";

// Actions
import {
  SET_HOTEL_ROOM_SUSPENSION,
  setHotelRoomSuspensionError,
} from "./hotelManageRoomDetailsTableActions";
import {
  GET_HOTEL_MANAGE_ROOM_DETAILS,
  getHotelManageRoomDetailsSuccess,
} from "@/dux/hotelManageRoomDetails/hotelManageRoomDetailsActions";

// Selectors
import {
  getHotelSelectedRoomDetails,
  selectHotelRoomManageSchuduleInactivation,
} from "@/dux/hotelManageRoomDetails/hotelManageRoomDetailsSelectors";

// Components
import { HotelManageRoomDetailsTableRow } from "./HotelManageRoomDetailsTableRow";
import LoadingWrapper from "@/web/common/LoadingWrapper";

// Utils
import moment from "moment";
import { createLoadingSelector } from "@/core/selectors/utils";

// Styles
const style = {
  padding: "10px",
  width: "200px",
};

/**
 * Room Details Table Component
 * @param {Object} props - Destructed Props
 * @param {string} props.componentId - Id used for Automated testing
 * @param {boolean} props.isLoading - Loading state
 * @param {Object[]} props.roomSuspensionDetails - Data for the table
 * @param {Function} props.setRoomSuspensionsData - dispatch fn to set room suspensions table data
 * @param {Function} props.setHotelRoomSuspensionError - dispatch fn to set room suspensions error
 * @returns {(JSX.Element|null)}
 */

export const RoomDetailsTable = props => {
  const {
    componentId,
    isLoading,
    roomSuspensionDetails,
    setRoomSuspensionsData,
    setHotelRoomSuspensionError,
  } = props;

  useEffect(() => {
    // clear store data on component unload
    return () => {
      setRoomSuspensionsData([]);
      setHotelRoomSuspensionError([]);
    };
  }, []);

  return (
    <Layout.Box id={componentId}>
      <LoadingWrapper displayContainer="block" isLoading={isLoading}>
        <table
          id={`${componentId}__table`}
          style={{
            borderBottom: "1px solid gray",
            borderCollapse: "collapse",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <thead>
            <tr
              style={{
                borderBottom: "1px solid gray",
                textAlign: "left",
              }}
            >
              <th style={style}>Start Date</th>
              <th style={style}>End Date</th>
              <th style={{ ...style, width: "120px" }}>Status</th>
              <th style={style}>Reason</th>
              <th style={style}>Details</th>
              <th style={style}></th>
            </tr>
          </thead>

          <tbody>
            {roomSuspensionDetails?.map((
              /** @type {object} */ suspension,
              /** @type {number} */ index,
            ) => (
              // @ts-ignore
              <HotelManageRoomDetailsTableRow
                key={index}
                index={index}
                isLoading={isLoading}
                suspension={suspension}
              />
            ))}
          </tbody>
        </table>
      </LoadingWrapper>
    </Layout.Box>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const HotelManageRoomDetailsTable = connect(
  state => {
    const roomSelected = getHotelSelectedRoomDetails(state);
    const suspensionDetails =
      roomSelected && Object.values(roomSelected)[0]?.rooms[0]?.suspensionDetails;
    const isNewScheduleInactivation = selectHotelRoomManageSchuduleInactivation(state);

    return {
      componentId: "HotelManageRoomDetailsTable",
      isLoading: createLoadingSelector([GET_HOTEL_MANAGE_ROOM_DETAILS, SET_HOTEL_ROOM_SUSPENSION])(
        state,
      ),
      isNewScheduleInactivation,
      suspensionDetails,
    };
  },

  dispatch => {
    return {
      setRoomSuspensionsData: (/** @type {Object[]} */ list) =>
        dispatch(getHotelManageRoomDetailsSuccess(list)),
      setHotelRoomSuspensionError: (/** @type {Object[]} */ list) =>
        dispatch(setHotelRoomSuspensionError(list)),
    };
  },

  (mapProps, dispatchProps) => {
    const { componentId, isLoading, isNewScheduleInactivation, suspensionDetails } = mapProps;
    const { setRoomSuspensionsData, setHotelRoomSuspensionError } = dispatchProps;

    const newScheduledInactivation = {
      alerts: [],
      createdDate: moment().format("YYYY-MM-DD"),
      details: "",
      endDate: "",
      history: [],
      reasonId: "",
      startDate: "",
      status: "",
      suspensionId: "",
      suspensionTypeId: "",
    };

    const roomSuspensionDetails = isNewScheduleInactivation
      ? [...suspensionDetails, newScheduledInactivation]
      : suspensionDetails;

    return {
      componentId,
      isLoading,
      roomSuspensionDetails,
      setRoomSuspensionsData,
      setHotelRoomSuspensionError,
    };
  },
)(RoomDetailsTable);
