import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Update the current price and/or payment status of the itinerary.
 * For System Services Booking API
 * - Update payment status
 * @param {*} { customerId, itineraryId, data }
 * @returns {*} AxiosPromise
 */
export function updatePaymentStatus({ customerId, itineraryId, data }) {
  return bookingAPI.put(
    `${endpointVersionString()}/customers/${customerId}/itineraries/${itineraryId}/payment`,
    data
  );
}
