import { connect } from "react-redux";
import BulkPackageOfferingsCartDetailsComponent from "../../../_components/bgm/cartDetails/BulkPackageOfferingsCartDetailsComponent";
import getIsBGMWorkflowFeatureFlagHidden from "../../../../web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getFTCOWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import { getIsAnyBulkPackageOfferingSelected } from "../bulkPackageOfferingsSelectors";

const mapStateToProps = state => {
  return {
    componentID: "bulk-package-offerings-cart-details",
    isHidden:
      (getIsBGMWorkflowFeatureFlagHidden(state) && getFTCOWorkflowFeatureFlagHidden(state)) ||
      getIsAnyBulkPackageOfferingSelected(state) === false,
  };
};

export default connect(mapStateToProps)(BulkPackageOfferingsCartDetailsComponent);
