export default function parseAlertData(obj) {
  return {
    isBookingAlert: obj ? Boolean(obj.bookingAlert) : false,
    bookingAlertReason: obj ? obj.bookingAlert || "" : "",
    isCheckInAlert: obj ? Boolean(obj.checkInAlert) : false,
    checkInAlertReason: obj ? obj.checkInAlert || "" : "",
    isCheckOutAlert: obj ? Boolean(obj.checkOutAlert) : false,
    checkOutAlertReason: obj ? obj.checkOutAlert || "" : "",
    isServiceCardAlert: obj ? Boolean(obj.serviceCardAlert) : false,
    serviceCardAlertReason: obj ? obj.serviceCardAlert || "" : "",
  };
}
