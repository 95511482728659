/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const ddcRoomCapacityModalActionTypes = {
  SHOW_HIDE_DDC_CAPACITY_MODAL: "SHOW_HIDE_DDC_CAPACITY_MODAL",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const showHideDDCCapacityModal = ({ isHidden }) => ({
  type: ddcRoomCapacityModalActionTypes.SHOW_HIDE_DDC_CAPACITY_MODAL,
  isHidden,
});
