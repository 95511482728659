import enumsActionTypes from "../actionTypes/enumsActionTypes";

const initialState = {
  breeds: {},
  species: {},
  genders: {},
  vaccinations: {},
  medications: {},
  colors: {},
  conditions: {},
  states: {},
  countries: {},
  foods: {},
};

const enumsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case enumsActionTypes.LOAD_ENUMS_SUCCESS:
      return {
        ...state,
        breeds: payload.Breeds,
        species: payload.Species,
        genders: payload.Genders,
        vaccinations: payload.Vaccinations,
        medications: payload.Medications,
        colors: payload.Colors,
        conditions: payload.Conditions,
        states: payload.States,
        countries: payload.Countries,
        foods: payload.Foods,
      };
    default:
      return state;
  }
};

export default enumsReducer;
