import { connect } from "react-redux";
import { Calendar } from "@prism/psm-ui-components";
import { setSelectDate } from "@/dux/selectedDate/selectedDateActions";
import { getSelectedDate } from "@/dux/selectedDate/selectedDateSelector";
import { getTrainingClassCachedMonths } from "@/dux/trainingClassSessions/selectors";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import calcAndDispatchRange from "dux/trainingClassSessions/helpers/calcandDispatchRange";

const mapStateToProps = (state) => {
  return {
    selectedDate: getSelectedDate(state),

    storeNumber: getStoreNumber(state),
    cachedMonths: getTrainingClassCachedMonths(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSelectDate: (selectedDate) => {
      dispatch(setSelectDate(selectedDate));
    },
    handleNextMonth: ({ MomentObj, cachedMonthsList, storeNumber }) => {
      calcAndDispatchRange(dispatch, { MomentObj, cachedMonthsList, storeNumber });
    },
    handlePreviousMonth: ({ MomentObj, cachedMonthsList, storeNumber }) => {
      calcAndDispatchRange(dispatch, { MomentObj, cachedMonthsList, storeNumber });
    },
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onSelectPreviousMonth: (MomentObj) =>
    propsFromDispatch.handlePreviousMonth({
      cachedMonthsList: propsFromState.cachedMonths,
      storeNumber: propsFromState.storeNumber,
      MomentObj,
    }),
  onSelectNextMonth: (MomentObj) =>
    propsFromDispatch.handleNextMonth({
      cachedMonthsList: propsFromState.cachedMonths,
      storeNumber: propsFromState.storeNumber,
      MomentObj,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Calendar);
