import { connect } from "react-redux";
import getAddOnMetaData from "dux/_selectors/getAddOnMetaData";
import PriceComponent from "dux/_components/pricing/PriceComponent";

const mapStateToProps = (state, { addOnId, petId, isHidden }) => {
  const { isOriginalPriceShown, originalPrice } = getAddOnMetaData(state, {
    addOnId,
    petId,
  });
  return {
    isHidden: !isOriginalPriceShown || isHidden,
    componentID: "AddOnOriginalPrice",
    type: "price-change",
    price: originalPrice && originalPrice?.toFixed(2),
  };
};

export default connect(mapStateToProps)(PriceComponent);
