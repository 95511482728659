import petVaccinationsActionTypes from "../../../actionTypes/petVaccinationsActionTypes";

const initialState = {
  customerKey: null,
  petId: null,
  vaccinationToDelete: null,
  showDropdown: false,
  isAddVaccinationFormOpen: false,
};

export default function vaccinationsReducer(state = initialState, action) {
  switch (action.type) {
    case petVaccinationsActionTypes.SET_CUSTOMER_AND_PET_KEYS:
      return {
        ...state,
        customerKey: action.customerKey,
        petId: action.petId,
      };
    case petVaccinationsActionTypes.SET_VACCINATION_TO_DELETE:
      return {
        ...state,
        vaccinationToDelete: action.vaccinationToDelete,
      };
    case petVaccinationsActionTypes.CLEAR_VACCINATION_TO_DELETE:
      return {
        ...state,
        vaccinationToDelete: initialState.vaccinationToDelete,
      };
    case petVaccinationsActionTypes.SHOW_DROPDOWN:
      return {
        ...state,
        showDropdown: true,
      };
    case petVaccinationsActionTypes.HIDE_DROPDOWN:
      return {
        ...state,
        showDropdown: initialState.showDropdown,
      };
    case petVaccinationsActionTypes.TOGGLE_ADD_VACCINATION_FORM:
      return {
        ...state,
        isAddVaccinationFormOpen: !state.isAddVaccinationFormOpen,
      };
    default:
      return state;
  }
}
