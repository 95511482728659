import { createSelector } from "reselect";
import getFeatureFlagWindowState from "./_featureFlagWindowState";

const getFeatureFlagVisibleState = createSelector(
  [getFeatureFlagWindowState],
  featureFlagWindowState => {
    return featureFlagWindowState.isHidden;
  }
);

export default getFeatureFlagVisibleState;
