import { connect } from "react-redux";
import { SetFrequencyModalComponent } from "dux/_components/medication/modal/SetFrequencyModalComponent";

const mapStateToProps = () => {
  return {
    header: "Set Frequency",
    componentId: "bookingModal-setFrequency",
  };
};

export default connect(mapStateToProps)(SetFrequencyModalComponent);
