import { connect } from "react-redux";
import CapacityTable from "dux/_components/roomCapacity/CapacityTable";
import DoggyDayCampCapacityTableHeaderContainer from "dux/ddcRoomCapacity/DoggyDayCampCapacityTableHeaderContainer";
import { getDdcCapacityAsArray } from "dux/ddcRoomCapacity/ddcRoomCapacitySelector";
import DdcCapacityRowContainer from "dux/ddcRoomCapacity/DdcCapacityRowContainer";

const mapStateToProps = state => {
  return {
    isHidden: false,
    componentID: "CapacityTable__Hotel",
    capacity: getDdcCapacityAsArray(state),
    diComp: {
      header: {
        DDC: DoggyDayCampCapacityTableHeaderContainer,
      },
      row: {
        DDC: DdcCapacityRowContainer,
      },
    },
    compName: "DDC",
  };
};

export default connect(mapStateToProps)(CapacityTable);
