import {
  CLEAR_HOTEL_ITINERARY,
  GET_HOTEL_ITINERARY_SUCCESS,
  PUT_HOTEL_ITINERARY_FAILURE,
  SET_HOTEL_ITINERARY_INVOICE,
  SET_HOTEL_ITINERARY_STATUS,
} from "./hotelItineraryActions";

const initialState = {};

const hotelItineraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTEL_ITINERARY_SUCCESS:
      return {
        ...state,
        itinerary: action.payload,
      };
    case CLEAR_HOTEL_ITINERARY:
      return initialState;
    case SET_HOTEL_ITINERARY_INVOICE:
      return {
        ...state,
        itinerary: {
          ...state?.itinerary,
          invoice: { ...state?.itinerary?.invoice, ...action?.invoice },
        },
      };
    case SET_HOTEL_ITINERARY_STATUS:
      return {
        ...state,
        itinerary: {
          ...state?.itinerary,
          status: action?.status,
        },
      };
    case PUT_HOTEL_ITINERARY_FAILURE:
      return { ...state, error: action?.error };
    default:
      return state;
  }
};

export default hotelItineraryReducer;
