import React from "react";
import { connect } from "react-redux";
import { Card, Layout, List, Text } from "@prism/psm-ui-components";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { loadTrainingClassDetails } from "@/dux/manageTrainingClassPage/manageTrainingClassPageActions";
import { getTrainingClassSessionCard } from "@/dux/trainingClassSessions/selectors";
import { isTrainingClassScheduled } from "@/dux/trainingClassSessions/_utils";
import { routePaths } from "@/core/constants/routePaths";

function TrainingCardList({ data = [], loadTrainingClassDetails, isHidden = false }) {
  const empty = data.length > 0;
  return (
    <Layout.Box padding={empty ? "box-padding-6" : "box-padding-8"} isHidden={isHidden}>
      {empty ? (
        <Layout.Stack className="calendar-card-list" space="stack-space-4">
          <List items={data}>
            {card => (
              <Card
                className="calendar-card"
                hoverStyle={{ outline: "3px solid #1777f2" }}
                rows={card?.label}
                style={{
                  cursor: isTrainingClassScheduled(card.status) ? "pointer" : "auto",
                }}
                onClick={event => loadTrainingClassDetails(event, card)}
              >
                <div
                  style={{
                    backgroundColor: card.color,
                    borderRadius: "5px",
                    maxWidth: "0.5%",
                    minWidth: "2%",
                    flex: "0.03",
                  }}
                />
              </Card>
            )}
          </List>
        </Layout.Stack>
      ) : (
        <Text>There are no appointments on the selected date.</Text>
      )}
    </Layout.Box>
  );
}

export default withRouteProps(
  connect(
    state => ({
      isHidden: false,
      data: getTrainingClassSessionCard(state),
    }),
    (dispatch, { router: { navigate } }) => ({
      loadTrainingClassDetails: (event, { customerKey, itineraryId, status }) => {
        // Temporary hack to prevent issue where we redirect to manage training class
        // page when clicking anywhere inside modal. This could be due to the fact that
        // the modal's HTML is contained within the appointment card. Long-term solution will be
        // to separate the modal out into its own separate component.
        const commonModal = document.querySelector(".commonModal");
        const isTargetWithinModal = !!commonModal?.contains(event.target);

        if (isTrainingClassScheduled(status) && !isTargetWithinModal) {
          dispatch(loadTrainingClassDetails({ customerKey, itineraryId }));
          navigate(routePaths.MANAGE_TRAINING_CLASS);
        }
      },
    }),
  )(TrainingCardList),
);
