import { ddcRoomCapacityModalActionTypes } from "dux/ddcRoomCapacityModal/ddcRoomCapacityModalActions";

const initialState = { isHidden: true };

const ddcRoomCapacityModalReducer = (state = initialState, action) => {
  if (action.type === ddcRoomCapacityModalActionTypes.SHOW_HIDE_DDC_CAPACITY_MODAL) {
    return {
      ...state,
      isHidden: action.isHidden,
    };
  }
  return state;
};

export default ddcRoomCapacityModalReducer;
