import bookingActionTypes from "../actionTypes/bookingActionTypes";

export const openAssociateDropdown = () => ({
  type: bookingActionTypes.OPEN_ASSOCIATE_DROPDOWN,
});

export const closeAssociateDropdown = () => ({
  type: bookingActionTypes.CLOSE_ASSOCIATE_DROPDOWN,
});

export const selectPet = petId => ({
  type: bookingActionTypes.SELECT_PET,
  petId: Number(petId) || petId,
});

export const setItineraryId = itineraryId => ({
  type: bookingActionTypes.SET_BOOKING_ITINERARY_ID,
  itineraryId,
});

export const setCurrentBookingPetServiceItemId = petServiceItemId => ({
  type: bookingActionTypes.SET_CURRENT_BOOKING_SERVICE_ITEM_ID,
  petServiceItemId,
});

export const setPetIdToRemoveFromCart = petId => ({
  type: bookingActionTypes.SET_PET_ID_TO_REMOVE_FROM_CART,
  petId,
});

export const selectHoveredTimeSlot = ({ petId, timeSlot }) => ({
  type: bookingActionTypes.SELECT_HOVERED_TIME_SLOT,
  petId,
  timeSlot,
});

export const loadAvailableTimeSlots = ({
  customerKey,
  fromDate,
  toDate,
  petServiceId,
  associateId,
}) => ({
  type: bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS,
  customerKey,
  fromDate,
  toDate,
  petServiceId,
  associateId,
});

export const loadAvailableTimeSlotsRequest = () => ({
  type: bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_REQUEST,
});

export const loadAvailableTimeSlotsSuccess = ({ availableTimeSlots, petId }) => ({
  type: bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_SUCCESS,
  availableTimeSlots,
  petId,
});

export const loadAvailableTimeSlotsFailure = ({ error }) => ({
  type: bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_FAILURE,
  error,
});

export const loadAvailableTimeSlotsByEngagement = ({
  customerKey,
  itineraryId,
  engagementId,
  fromDate,
  toDate,
  associateId,
}) => ({
  type: bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT,
  customerKey,
  itineraryId,
  engagementId,
  fromDate,
  toDate,
  associateId,
});

export const loadAvailableTimeSlotsByEngagementRequest = () => ({
  type: bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT_REQUEST,
});

export const loadAvailableTimeSlotsByEngagementSuccess = ({ availableTimeSlots, petId }) => ({
  type: bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT_SUCCESS,
  availableTimeSlots,
  petId,
});

export const loadAvailableTimeSlotsByEngagementFailure = ({ error }) => ({
  type: bookingActionTypes.LOAD_AVAILABLE_TIME_SLOTS_BY_ENGAGEMENT_FAILURE,
  error,
});

export const setTimeSlotFromDashboard = ({
  customerKey,
  fromDate,
  petId,
  associateId,
  petServiceId,
}) => ({
  type: bookingActionTypes.SET_TIME_SLOT_FROM_DASHBOARD,
  customerKey,
  fromDate,
  petId,
  associateId,
  petServiceId,
});

export const setTimeSlotFromDashboardRequest = () => ({
  type: bookingActionTypes.SET_TIME_SLOT_FROM_DASHBOARD_REQUEST,
});

export const setTimeSlotFromDashboardSuccess = () => ({
  type: bookingActionTypes.SET_TIME_SLOT_FROM_DASHBOARD_SUCCESS,
});

export const setTimeSlotFromDashboardFailure = ({ error }) => ({
  type: bookingActionTypes.SET_TIME_SLOT_FROM_DASHBOARD_FAILURE,
  error,
});

export const showBookingModal = (modalType, props) => ({
  type: bookingActionTypes.SHOW_BOOKING_MODAL,
  modalType,
  props,
});

export const hideBookingModal = () => ({
  type: bookingActionTypes.HIDE_BOOKING_MODAL,
});

export const selectNewAppointmentTimeSlot = newTimeSlot => ({
  type: bookingActionTypes.SELECT_NEW_APPOINTMENT_TIME_SLOT,
  newTimeSlot,
});

export const clearNewAppointmentTimeSlot = () => ({
  type: bookingActionTypes.CLEAR_NEW_APPOINTMENT_TIME_SLOT,
});

export const bookAppointment = ({
  timeSlot,
  selectedPetService,
  customerKey,
  isManualAppointment = false,
}) => ({
  type: bookingActionTypes.BOOK_APPOINTMENT,
  timeSlot,
  selectedPetService,
  customerKey,
  isManualAppointment,
});

export const finalizeAppointment = ({ customerKey, shouldCheckIn }) => ({
  type: bookingActionTypes.FINALIZE_APPOINTMENT,
  customerKey,
  shouldCheckIn,
});

export const shouldProceedWithBooking = shouldProceed => ({
  type: bookingActionTypes.SHOULD_PROCEED_WITH_BOOKING,
  shouldProceed,
});

const refreshSessionCache = () => ({
  type: bookingActionTypes.REFRESH_SESSION_CACHE,
});

const refreshSessionCacheRequest = () => ({
  type: bookingActionTypes.REFRESH_SESSION_CACHE_REQUEST,
});

const refreshSessionCacheSuccess = () => ({
  type: bookingActionTypes.REFRESH_SESSION_CACHE_SUCCESS,
});

const refreshSessionCacheFailure = error => ({
  type: bookingActionTypes.REFRESH_SESSION_CACHE_FAILURE,
  error,
});

export const setAdditionalAppointmentBeforeBreak = () => ({
  type: bookingActionTypes.SET_ADDITIONAL_APPOINTMENT_BEFORE_BREAK,
});

export const setAdditionalAppointmentAfterBreak = () => ({
  type: bookingActionTypes.SET_ADDITIONAL_APPOINTMENT_AFTER_BREAK,
});

export default {
  openAssociateDropdown,
  closeAssociateDropdown,
  selectPet,
  setItineraryId,
  setCurrentBookingPetServiceItemId,
  selectHoveredTimeSlot,
  loadAvailableTimeSlots,
  loadAvailableTimeSlotsRequest,
  loadAvailableTimeSlotsSuccess,
  loadAvailableTimeSlotsFailure,
  loadAvailableTimeSlotsByEngagement,
  loadAvailableTimeSlotsByEngagementRequest,
  loadAvailableTimeSlotsByEngagementSuccess,
  loadAvailableTimeSlotsByEngagementFailure,
  showBookingModal,
  hideBookingModal,
  selectNewAppointmentTimeSlot,
  clearNewAppointmentTimeSlot,
  bookAppointment,
  finalizeAppointment,
  setTimeSlotFromDashboard,
  setTimeSlotFromDashboardRequest,
  setTimeSlotFromDashboardSuccess,
  setTimeSlotFromDashboardFailure,
  shouldProceedWithBooking,
  refreshSessionCache,
  refreshSessionCacheRequest,
  refreshSessionCacheSuccess,
  refreshSessionCacheFailure,
  setAdditionalAppointmentBeforeBreak,
  setAdditionalAppointmentAfterBreak,
};
