import { set } from "lodash/fp";
import generalActionTypes from "core/actionTypes/ui/web/generalActionTypes";
import { checkForUndefined } from "core/utils/undefinedOrNullUtils/undefinedOrNullUtils";

const initialState = null;
/**
 * Reducer function for managing the current store state.
 *
 * @memberof Reducer.persistent
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @returns {Object} - The new state.
 */
export const currentStoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case generalActionTypes.SET_STORE_NUMBER:
      return checkForUndefined(action.storeNumber, initialState);

    case generalActionTypes.RESET_STORE_NUMBER:
      return initialState

    default:
      return state;
  }
};
