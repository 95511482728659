import { createSelector } from "reselect";
import { getState } from "core/selectors/commonSelector";
import { getCurrentPet } from "core/selectors/persistentSelectors";

/**
 *  Selector to get hotel needs review state
 *  @memberOf Selectors.hotelPetNeedsReview
 *  @function
 *  @name selectPetNeedsReviewState
 *  @param {Object} state - redux state
 *  @returns {Object}
 *  @example selectPetNeedsReviewState(state)
 */
export const selectPetNeedsReviewState = createSelector(
  [getState],
  state => state?.hotelPetNeedsReview ?? {},
);

/**
 *  Selector to get hotel pet needs review for the current pet
 *  @memberOf Selectors.hotelPetNeedsReview
 *  @function
 *  @name selectPetNeedsReview
 *  @param {Object} state - redux state
 *  @returns {Boolean}
 *  @example selectPetNeedsReview(state)
 */
export const selectPetNeedsReview = createSelector(
  [selectPetNeedsReviewState, getCurrentPet],
  (hotelPetNeedsReview, petId) => hotelPetNeedsReview[petId]?.needsReview ?? false,
);

/**
 *  Selector to get hotel pet needs review for a given pet
 *  @memberOf Selectors.hotelPetNeedsReview
 *  @function
 *  @name selectPetNeedsReviewByPetId
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {String|Number} props.petId
 *  @returns {Boolean}
 *  @example selectPetNeedsReviewByPetId(state, { petId })
 */
export const selectPetNeedsReviewByPetId = createSelector(
  [selectPetNeedsReviewState, (state, props) => props],
  (hotelPetNeedsReview, { petId } = {}) => {
    return hotelPetNeedsReview[petId]?.needsReview ?? false;
  },
);
