import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  hotelOvernightActionTypes,
  loadHotelOvernightFailure,
  loadHotelOvernightSuccess,
} from "dux/hotelOvernight/columnList/hotelOvernightActions";

export function* loadHotelOvernight({ overnight }) {
  try {
    yield put(loadHotelOvernightSuccess({ overnight }));
  } catch (error) {
    yield put(loadHotelOvernightFailure({ error }));
  }
}

function* watchHotelOvernight() {
  yield takeEvery(hotelOvernightActionTypes.LOAD_HOTEL_OVERNIGHT_REQUEST, loadHotelOvernight);
}

export default function* hotelOverNightSaga() {
  yield all([fork(watchHotelOvernight)]);
}
