/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelArrivalsFilterButtonActionTypes = {
  FILTER_BY_OVERNIGHT_ARRIVALS: "FILTER_BY_OVERNIGHT_ARRIVALS",
  FILTER_BY_DAY_CAMP_ARRIVALS: "FILTER_BY_DAY_CAMP_ARRIVALS",
  FILTER_BY_DAY_CARE_ARRIVALS: "FILTER_BY_DAY_CARE_ARRIVALS",
  FILTER_BY_All_ARRIVALS: "FILTER_BY_All_ARRIVALS",
  FILTER_BY_ATTENTION_NEEDED_ARRIVALS: "FILTER_BY_ATTENTION_NEEDED_ARRIVALS",
  FILTER_BY_UNASSIGNED_PET_ARRIVALS: "FILTER_BY_UNASSIGNED_PET_ARRIVALS",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const filterByOverNightArrivals = ({ filterBtnStatus }) => ({
  type: hotelArrivalsFilterButtonActionTypes.FILTER_BY_OVERNIGHT_ARRIVALS,
  filterBtnStatus,
});

export const filterByDayCampArrivals = ({ filterBtnStatus }) => ({
  type: hotelArrivalsFilterButtonActionTypes.FILTER_BY_DAY_CAMP_ARRIVALS,
  filterBtnStatus,
});

export const filterByDayCareArrivals = ({ filterBtnStatus }) => ({
  type: hotelArrivalsFilterButtonActionTypes.FILTER_BY_DAY_CARE_ARRIVALS,
  filterBtnStatus,
});

export const filterByAllArrivals = ({ filterBtnStatus }) => ({
  type: hotelArrivalsFilterButtonActionTypes.FILTER_BY_All_ARRIVALS,
  filterBtnStatus,
});

export const filterByAttentionNeededArrivals = ({ filterBtnStatus }) => ({
  type: hotelArrivalsFilterButtonActionTypes.FILTER_BY_ATTENTION_NEEDED_ARRIVALS,
  filterBtnStatus,
});

export const filterByUnassignedPetArrivals = ({ filterBtnStatus }) => ({
  type: hotelArrivalsFilterButtonActionTypes.FILTER_BY_UNASSIGNED_PET_ARRIVALS,
  filterBtnStatus,
});
