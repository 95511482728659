import { connect } from "react-redux";
import { uniq } from "lodash/fp";
import { getAppointmentByPet } from "core/selectors/cartSelectors";
import cartActionCreators, { setAddons } from "core/actionCreators/ui/web/cartActionCreators";
import { updatePetServiceItem } from "core/actionCreators/petServiceItemsActionCreators";
import { putAddOnsWithBundledOptions } from "core/actionCreators/addOnsActionCreator";
import addOnsActionTypes from "core/actionTypes/addOnsActionTypes";
import withInBookingOrModify from "../../web/common/hoc/withInBookingOrModify";
import SalonServicesGridButtonComponent from "dux/_components/salonServicesGridButtonComponent/SalonServicesGridButtonComponent";
import {
  getBundleAddOnIdsByPetServiceId,
  getIsActiveBundleAvailable,
} from "dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";
import { getSelectedAddOnIds } from "dux/_selectors/addOnsSelectors";
import wrapAddonIdsToObjects from "dux/utils/addOns/wrapAddonIdsToObjects";
import { setEnhancedServicesAddon } from "dux/enhancedServicesList/EnhancedServicesListActions";


const mapStateToProps = (state, { petId, petServiceId }) => {
  const appointment = getAppointmentByPet(state, { petId });
  const selectedPetService = appointment && appointment.petService;
  const bundledAddOns = getBundleAddOnIdsByPetServiceId(state, { petId, petServiceId });
  const selectedAddOns = getSelectedAddOnIds(state, { petId });
  const isActiveBundleAvailable = getIsActiveBundleAvailable(state, { petId, petServiceId });

  return {
    petServiceId,
    selectedPetService,
    selectedAddOns,
    bundledAddOns,
    isActiveBundleAvailable,
    isSelected: petServiceId === selectedPetService,
    componentId: `SalonServicesGridButton`,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { inBookingOrModify, petId } = ownProps;

  return {
    dispatchOnServiceClick: (petServiceId) => {
      const { petServiceItem, onClose } = ownProps;

      dispatch(
        cartActionCreators.setMaxCheckInLimit({
          maxCheckInCount: 1,
          petId,
        }),
      );
      dispatch(
        cartActionCreators.setMaxPerBlockLimit({
          maxPerBlockCount: 1,
          petId,
        }),
      );

      if (petServiceItem) {
        const { customer, itinerary, engagement, petServiceItemId } = petServiceItem;
        const data = { petServiceId, isManualAppointment: inBookingOrModify ? undefined : true };

        if (petServiceId !== petServiceItem.petServiceId) {
          dispatch(
            updatePetServiceItem({
              customerKey: customer,
              itineraryId: itinerary,
              engagementId: engagement,
              dispatchToCart: true,
              petServiceItemId,
              data,
            }),
          );

          // We want to set pre-selected bundled add-ons when the pet service type is switched
          // to a BGM service with an add-on included
          dispatch(
            putAddOnsWithBundledOptions({
              type: addOnsActionTypes.PUT_ADDONS_WITH_BUNDLED_OPTIONS,
              petServiceId,
              petServiceItemId,
              customerKey: customer,
              itineraryId: itinerary,
              engagementId: engagement,
            }),
          );
        }
      } else {
        dispatch(cartActionCreators.selectPetService({ petServiceId, petId: ownProps.petId }));
      }

      onClose && onClose();
    },
    dispatchSetAddOns: ({ petId, addOns }) => dispatch(setAddons({ petId, addons: addOns })),
    dispatchSetEnhancedServicesAddon: ({ petId }) => dispatch(setEnhancedServicesAddon(null, petId)),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onServiceClick: () => {
    const { petId } = ownProps;
    const { petServiceId, bundledAddOns, isActiveBundleAvailable } = propsFromState;

    propsFromDispatch.dispatchSetEnhancedServicesAddon({ petId });

    // Each time a service is selected, we want to clear all add-ons that were previously selected.
    // Ensure that bundled add-ons are always added to the cart when a service
    // is selected. We also only want to include a bundled add-on if the package bundle is available to redeem.
    const mergedAddOns = isActiveBundleAvailable
      ? [ ...bundledAddOns]
      : [];
    propsFromDispatch.dispatchSetAddOns({
      petId,
      addOns: wrapAddonIdsToObjects(uniq(mergedAddOns)),
    });

    propsFromDispatch.dispatchOnServiceClick(petServiceId);
  },
});

export default withInBookingOrModify(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(SalonServicesGridButtonComponent),
);
