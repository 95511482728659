// @ts-check
import {
  SET_OVERBOOKING_SETUP_DATA_SUCCESS,
  SET_OVERBOOKING_SETUP_DATA_FAILURE,
} from "./overBookingSetupTableActions";

const initialState = {
  data: [],
  error: [],
};

const overBookingSetupTableReducer = (
  state = initialState,
  /** @type {{ type: string; payload: string; }} */ action,
) => {
  switch (action.type) {
    case SET_OVERBOOKING_SETUP_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case SET_OVERBOOKING_SETUP_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default overBookingSetupTableReducer;
