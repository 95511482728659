import React from "react";
import { Button, Layout } from "@prism/psm-ui-components";
import { BookingEnhancedServicesList } from "dux/enhancedServicesList/EnhancedServicesList";

/**
 * List of two sections that contain prices and a selection checkbox. Designed to go in a flyOut or Modal
 * @param {Function} onClose - Event handler to close a Window (done via action in container)
 */
const EnhancedServicesFlyoutComponent = props => {

  const { onClose, isStandalone, componentId } = props;

  return (
      <Layout.Box padding="box-padding-10">
        <Layout.Stack id={componentId} space="stack-space-10" justify="left">
          <Layout.Cluster id={`${componentId}_ServicesList`} justify="space-between" space="cluster-space-4">
              {isStandalone ? null : <BookingEnhancedServicesList itemName="enhancedServices_flyout" />}
          </Layout.Cluster>

          <Layout.Cluster id={`${componentId}_Footer`} justify="space-between" space="cluster-space-4">
              <Button
                variant="prism-primary-no-outline"
                size="xs"
                style={{width:'initial', height:'initial', fontWeight:'normal'}}
                title="Close"
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                title="Done"
                variant="prism-primary"
                size="xs"
                style={{width:'initial', height:'initial'}}
                onClick={() => {
                    if (onClose) {
                      onClose();
                    }
                }}
              >
                Done
              </Button>
          </Layout.Cluster>
        </Layout.Stack>
      </Layout.Box>
  );
}

export default EnhancedServicesFlyoutComponent;