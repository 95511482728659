import React from "react";
import { routePaths } from "@/core/constants/routePaths";
import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { PetCard, Text, Icon } from "@prism/psm-ui-components";
import { compose } from "redux";
import { formatPropsForList } from "../_utils/uiComponentUtils";
import Img from "@/web/common/Img";
import getUnknownPetImage from "@/core/utils/assetUtils/unknownPetImage";

export const HotelManageRoomDetailsPetCard = compose(
  withRouteProps,
  connect(
    () => {
      return {
        componentId: "hotelMangeRoomPetCard_Container",
        isDisabled: false,
        serviceIcon: (
          <Icon
            variant="psm-icon-ph"
            useColorForFill
            color="psm-icon-color-white"
            size="psm-icon-size-48"
            style={{ backgroundColor: "#1777f2" }}
          />
        ),
      };
    },
    (dispatch, ownProps) => {
      const {
        router: { navigate },
      } = ownProps;
      return {
        dispatchOnClick: ({ itineraryId, customerId }) => {
          navigate(`${routePaths.CHECK_IN}/${customerId}/${itineraryId}`);
        },
      };
    },
    (stateProps, dispatchProps, ownProps) => {
      const { dispatchOnClick } = dispatchProps;
      const { occupant } = ownProps;
      const { isDisabled } = stateProps;
      const {
        itineraryId,
        petService,
        petName,
        petAge,
        petBreedDescription,
        petSpeciesId,
        customerId,
      } = ownProps;
      return {
        ...stateProps,
        petImage: <Img src={getUnknownPetImage({ speciesId: petSpeciesId })} />,
        topSectionRows: formatPropsForList([
          <>
            <Text bold size="text-size-lg">
              {petName || "Loading..."},&nbsp;
            </Text>
            <Text size="text-size-lg">
              {petAge}, {petBreedDescription}
            </Text>
          </>,
          <Text>{petService}</Text>,
        ]),
        onClick: event => {
          if (isDisabled) {
            event.preventDefault();
            return false;
          }
          dispatchOnClick({ itineraryId, customerId });
        },
      };
    },
  ),
)(PetCard);
