import { SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA } from "dux/scheduleSuspensionFilterButtons/scheduleSuspensionFilterButtonsActions";

const initialState = {};

const scheduleSuspensionFilterButtonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUSPENSION_SCHEDULE_FILTER_BUTTON_DATA:
      return action.data;
    default:
      return state;
  }
};

export default scheduleSuspensionFilterButtonsReducer;
