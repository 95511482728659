import * as React from 'react';

interface HoverProps {
  style?: React.CSSProperties;
  hoverStyle: React.CSSProperties;
  children?: React.ReactNode;
}

interface HoverState {
  isHovered: boolean;
}

export default class Hover extends React.Component<HoverProps, HoverState> {
  state = { isHovered: false };

  setHovered = (value: boolean) => {
    this.setState(() => ({ isHovered: value }));
  };
  render() {
    const { style, hoverStyle, children } = this.props;
    const { isHovered } = this.state;
    const calculatedStyle = { ...style, ...(isHovered ? hoverStyle : {}) };

    return (
      <div
        style={calculatedStyle}
        onMouseEnter={() => this.setHovered(true)}
        onMouseLeave={() => this.setHovered(false)}
      >
        {children}
      </div>
    );
  }
}
