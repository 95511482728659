import React, { useEffect } from "react";
import { connect } from "react-redux";

// Components
import { SelectField } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";

// Actions
import {
  SET_HOTEL_ROOM_TYPES,
  loadHotelRoomTypes,
} from "@/dux/hotelRoomSelection/hotelRoomSelectionActions";
import { setOverbookingExceptionRoomType } from "@/dux/overBookingException/overBookingExceptionActions";

// Selectors
import {
  getHotelRoomTypesForExceptionForm,
  selectRoomCountBySelectedBucketId,
} from "@/dux/hotelRoomSelection/hotelRoomSelectionSelector";
import { createLoadingSelector } from "@/core/selectors/utils";
import { selectOverBookingExceptionRoomType } from "@/dux/overBookingException/overBookingExceptionSelectors";

/**
 * Helper function to validate the room type
 * @param {string?} roomType
 * @param {boolean?} isRequired
 * @returns {string}
 */
export const validateRoomType = (roomType, isRequired) => {
  const hasValue = roomType !== null && roomType !== undefined && roomType !== "";
  if (isRequired && !hasValue) return "Room type is required";
  return "";
};

/**
 * @param {Object} props
 * @param {Function} props.onChange
 * @param {string} props.value
 * @param {string} props.label
 * @param {Object[]} props.items
 * @param {Function} props.getHotelRoomTypes
 * @param {string} props.roomCount
 * @param {Boolean} props.disabled
 * @param {String} props.error
 * @returns {JSX.Element|null}
 */
export const OverbookingRoomTypeDropdownComponent = props => {
  const {
    componentId,
    onChange,
    value,
    label,
    items,
    getHotelRoomTypes,
    roomCount,
    disabled,
    error,
  } = props;

  useEffect(() => {
    // fetch room types
    if (!items.length) {
      getHotelRoomTypes();
    }
  }, [getHotelRoomTypes]);

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <SelectField
        items={items}
        label={label}
        value={value}
        defaultValue={value}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
        fieldNote={error ? error : roomCount}
        isError={!!error}
      />
    </LayoutBox>
  );
};

export const OverbookingRoomTypeDropdown = connect(
  (state, { isRequired, disabled }) => {
    const selectedRoomTypeId = selectOverBookingExceptionRoomType(state);
    const roomTypeOptions = getHotelRoomTypesForExceptionForm(state);
    const roomCount = selectRoomCountBySelectedBucketId(state);

    return {
      componentId: "OverbookingRoomTypeDropdown",
      label: "Room Type",
      items: roomTypeOptions,
      value: selectedRoomTypeId ?? "",
      roomCount: roomCount ? `Total Physical Rooms: ${roomCount}` : " ",
      error: validateRoomType(selectedRoomTypeId, isRequired),
      disabled: disabled || createLoadingSelector([SET_HOTEL_ROOM_TYPES])(state),
    };
  },
  dispatch => {
    return {
      onChange: value => {
        dispatch(setOverbookingExceptionRoomType(value));
      },
      getHotelRoomTypes: () => dispatch(loadHotelRoomTypes()),
    };
  },
)(OverbookingRoomTypeDropdownComponent);
