import * as Yup from "yup";
import { PHONE_NUMBER_REGEX } from "./regex";

import hasNonNumericCharacters from "../utils/validationUtils/hasNonNumericCharacters";
import { isSequentialPin, allCharactersSame } from "../utils/validationUtils/pinValidation";
import { pinValidationErrors } from "./authConstants";

export const phoneSchema = Yup.string().matches(PHONE_NUMBER_REGEX, {
  message: "Phone number must be 10 characters",
  excludeEmptyString: true
});

export const emailSchema = Yup.string().email("Invalid email address");

export const pinValidationSchema = Yup.string()
  .required(pinValidationErrors.REQUIRED)
  .min(6, pinValidationErrors.LENGTH)
  .test("is NaN", pinValidationErrors.NON_NUMERIC, pin => !hasNonNumericCharacters(pin))
  .test("all characters same", pinValidationErrors.ALL_SAME_NUMBERS, pin => !allCharactersSame(pin))
  .test("sequential pin", pinValidationErrors.SEQUENTIAL_NUMBERS, pin => !isSequentialPin(pin));

export const setPinValidationSchema = Yup.object().shape({
  pin: pinValidationSchema,
  confirmPin: Yup.string()
    .oneOf([Yup.ref("pin"), null], pinValidationErrors.PINS_DONT_MATCH)
    .required(pinValidationErrors.CONFIRM_PIN)
});
