import moment from "moment";

/*
 * Responsible for calculating the elapsed time in day up to timestamp in milliseconds provided
 *
 * @export getMillisecondsElapsedInDay
 * @param {number} timestamp - Integer value for timestamp in milliseconds
 * @returns {string} - Time elapsed for day in milliseconds
 */
export default function getMillisecondsElapsedInDay(timestamp) {
  const startTime = moment().startOf("day");
  const elapsed = moment.duration(moment(timestamp).diff(startTime));

  return elapsed.asMilliseconds();
}
