import { createSelector } from "reselect";
import { find, get } from "lodash/fp";
import getAddressesByCustomerID from "./getAddressesByCustomer";

/* * ----------------------------------------------------------------------- * *\
    Selector that returns normalized Values form the customers Primary Address

    Example of use:  getPrimaryAddress(state, { customerKey });
\* * ----------------------------------------------------------------------- * */
const getPrimaryAddress = createSelector([getAddressesByCustomerID], addresses =>
  find(get("isPrimary"), addresses)
);

export default getPrimaryAddress;
