import { Text } from "@prism/psm-ui-components";
import { connect } from "react-redux";
import React from "react";
import {
  selectHotelCartPetPrimaryServiceProduct,
  selectHotelCartPetProductById,
} from "dux/servicesCartHotel/servicesCartHotelSelectors";
import { LayoutCluster } from "@/layout/culster/Cluster";
import {
  HotelBookingManualSpecialRemoveButton,
  HotelCheckInOutManualSpecialRemoveButton,
} from "dux/hotelRemoveButton/HotelRemoveButton";
import { formatMoney } from "dux/_utils/moneyUtil";
import { LayoutStack } from "@/layout/stack/Stack";
import { getHotelPetSpecials, selectPetAddonSpecialsById } from "@/dux/specials/specialsSelectors";
import { getCurrentPet } from "@/core/selectors/persistentSelectors";
import { SPECIAL_TYPES } from "@/dux/specials/specialsConstants";

export const HotelSpecial = ({ special = {}, componentId, petId, diComp }) => {
  const RemoveButton = diComp;
  const sign = special?.type !== SPECIAL_TYPES.PREMIUM && "-";

  return (
    <LayoutStack space="scale-0" id={componentId}>
      <LayoutCluster
        style={{ display: "flex", justifyContent: "space-between", opacity: "50%" }}
        flexWrap="flex-noWrap"
      >
        <Text id={`${componentId}__Name`}>{special.name}</Text>
        <Text id={`${componentId}__Price`}>
          {sign}
          {/*NOTE totalAmount is total discount amount*/}
          {formatMoney(special?.totalAmount)}
        </Text>
      </LayoutCluster>
      <RemoveButton petId={petId} special={special} />
    </LayoutStack>
  );
};

export const HotelSpecialList = ({ specials, componentId, petId, diComp }) => {
  return specials?.map(special => (
    <HotelSpecial
      key={special?.code}
      petId={petId}
      special={special}
      componentId={componentId}
      diComp={diComp}
    />
  ));
};

export const HotelBookingCartSpecialPrice = connect((state, ownProps) => {
  const { petId } = ownProps;
  const cartServiceInformation = selectHotelCartPetPrimaryServiceProduct(petId)(state);

  const specials = cartServiceInformation?.pricing?.specials || [];

  return {
    componentId: "HotelBookingCartSpecialPrice",
    specials,
    petId,
    diComp: HotelBookingManualSpecialRemoveButton,
  };
})(HotelSpecialList);

export const HotelBookingCartAddonSpecialPrice = connect((state, { petId, addonId }) => {
  const addon = selectHotelCartPetProductById({ petId, productId: addonId })(state);
  const specials = addon?.pricing?.specials || [];

  return {
    componentId: "HotelBookingCartAddonSpecialPrice",
    specials,
    petId,
    diComp: HotelBookingManualSpecialRemoveButton,
  };
})(HotelSpecialList);

export const HotelCheckInOutCartSpecialPrice = connect(state => {
  const petId = getCurrentPet(state);
  const specials = getHotelPetSpecials(state, { petId });

  return {
    componentId: "HotelCheckInOutCartSpecialPrice",
    specials,
    petId,
    diComp: HotelCheckInOutManualSpecialRemoveButton,
  };
})(HotelSpecialList);

export const HotelCheckInOutCartAddonSpecialPrice = connect((state, { addonId, petId }) => {
  const specials = selectPetAddonSpecialsById(addonId)(state, { petId });

  return {
    componentId: "HotelCheckInOutCartAddonSpecialPrice",
    specials,
    petId,
    diComp: HotelCheckInOutManualSpecialRemoveButton,
  };
})(HotelSpecialList);
