import React from "react";
import { connect } from "react-redux";
import { hideSchedulesModal } from "core/actionCreators/schedulesActionCreators";
import { color } from "web/common/styles/theme";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { Button } from "@petsmart-ui/sparky";
import { List } from "@prism/psm-ui-components";
import { formatPropsForList } from "@/dux/_utils/uiComponentUtils";

/**
 * React view component for associate modals styled for a header, body text, and a footer section
 * @summary Located on the associate-scheduling and template-scheduling pages.
 * @memberOf Views.Associate
 * @function
 * @name AssociateSchedulingModal
 * @param {Object} props - props passed into the view component
 * @param {String} props.componentId - component id
 * @param {String} props.headerText - text to display in header
 * @param {String | JSX.Element} props.bodyText - text or JSX.Element to display in body section
 * @param {JSX.Element} props.footerButtons - JSX.Element expected to contain buttons to render in the footer
 * @param {Object} props.footerStyleOverride - optional param to override footer styles
 * @returns {JSX.Element}
 * @example <AssociateSchedulingModal />
 */
export const AssociateSchedulingModal = ({
  componentId,
  headerText,
  bodyText,
  footerButtons,
  footerStyleOverride,
}) => {
  return (
    <LayoutBox id={componentId} padding="scale-0" style={{ padding: "0 50px" }}>
      <LayoutBox
        padding="scale-0"
        style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center", marginBottom: "20px" }}
      >
        {headerText}
      </LayoutBox>
      <LayoutBox padding="scale-0">{bodyText}</LayoutBox>

      <LayoutCluster
        space="scale-0"
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "30px",
          borderTop: `1px solid ${color.ororoMunroe}`,
          minHeight: "79px",
          ...footerStyleOverride,
        }}
      >
        {footerButtons}
      </LayoutCluster>
    </LayoutBox>
  );
};

/**
 * Helper to render arrays to include line breaks
 * @param {JSX.Element[]} items - array of strings or components
 * @returns {JSX.Element}
 */
const renderList = (items = []) => (
  <List items={formatPropsForList(items)}>{({ label }) => <>{label}</>}</List>
);

/**
 * Helper to render button components
 * @param {Object[]} btns - array of objects containing props to pass to button components
 * @returns {JSX.Element}
 */
const renderButtons = btns =>
  btns.map(
    ({ variant = "primary", text, onClick, isHidden }) =>
      !isHidden && <Button key={text} variant={variant} text={text} onClick={onClick} />,
  );

/**
 * Redux Connect function for the AssociateSchedulingModal
 *
 * @see {@link AssociateSchedulingModal}
 * @summary Located on the associate-scheduling and template-scheduling pages.
 * @memberOf Views.Associate
 * @function
 * @name Notification
 * @param {Object} props
 * @param {String} props.notificationMessage - text to display in the modal
 * @returns {JSX.Element|null}
 * @example <Notification notificationMessage={notificationMessage} />
 */
export const Notification = connect(
  (state, { notificationMessage }) => ({
    componentId: "Notification",
    headerText: "Notification",
    bodyText: notificationMessage,
  }),
  dispatch => {
    const closeModal = () => dispatch(hideSchedulesModal());
    return {
      footerButtons: renderButtons([{ text: "Close", onClick: closeModal }]),
    };
  },
)(AssociateSchedulingModal);

/**
 * Redux Connect function for the AssociateSchedulingModal
 *
 * @see {@link AssociateSchedulingModal}
 * @summary Located on the template-scheduling page.
 * @memberOf Views.Associate
 * @function
 * @name TemplateDateMustBeChanged
 * @returns {JSX.Element|null}
 * @example <TemplateDateMustBeChanged />
 */
export const TemplateDateMustBeChanged = connect(
  () => ({
    componentId: "TemplateDateMustBeChanged",
    headerText: "Template Date Must Be Changed",
    bodyText: renderList([
      "You can't create template for same effective date",
      "Please change date to at least 1 week ahead of current effective date.",
    ]),
  }),
  dispatch => {
    const closeModal = () => dispatch(hideSchedulesModal());
    return {
      footerButtons: renderButtons([{ text: "Close", onClick: closeModal }]),
    };
  },
)(AssociateSchedulingModal);

/**
 * Redux Connect function for the AssociateSchedulingModal
 *
 * @see {@link AssociateSchedulingModal}
 * @summary Located on the associate-scheduling and template-scheduling pages.
 * @memberOf Views.Associate
 * @function
 * @name UnsaveChanges
 * @param {Object} props
 * @param {String} props.onContinue -  optional function to undo changes made and continue to whatever lkink was clicked
 * @returns {JSX.Element|null}
 * @example <UnsaveChanges onContinue={() => {}} />
 */
export const UnsaveChanges = connect(
  (state, { onContinue }) => ({
    componentId: "UnsaveChanges",
    headerText: "Unsaved Changes",
    bodyText: onContinue
      ? renderList([
          "You have unsaved changed.",
          'You can click "Cancel" to go back and save changes,',
          'or click "continue" to continue without saving changes.',
        ])
      : renderList([
          "You can't leave schedules screen until you have unsaved changes.",
          "Please, first save or cancel unsaved changes",
        ]),
    footerStyleOverride: { justifyContent: onContinue ? "space-between" : "center" },
  }),
  (dispatch, { onContinue }) => {
    const closeModal = () => dispatch(hideSchedulesModal());

    return {
      footerButtons: renderButtons([
        { text: "Cancel", variant: "link", onClick: closeModal },
        { text: "Continue", onClick: onContinue, isHidden: !onContinue },
      ]),
    };
  },
)(AssociateSchedulingModal);
