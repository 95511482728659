import { put, takeEvery, call, all, select } from "redux-saga/effects";
import { fetchNearbyStores } from "core/services/petsmartWebPublic/searchNearbyStoresEndPoints";
import { reduceStoreInfoHelper } from "dux/listOfNearByStores/reduceStoreInfoHelper";
import {
  GET_LIST_OF_NEAR_BY_STORES,
  getListOfNearByStoresRequest,
  getListOfNearByStoresSuccess,
  getListOfNearByStoresFailure,
} from "./listOfNearByStoresActions";

function* onGetListOfNearByStores({ latitude, longitude }) {
  try {
    yield put(getListOfNearByStoresRequest());

    const response = yield call(fetchNearbyStores, { latitude, longitude });

    const storeSearchResults = response.data.StoreSearchResults;

    // We don't want to dump all the store info into state, so we parse the data and pull out only what is needed and format where necessary.
    const nearByStores = reduceStoreInfoHelper(storeSearchResults);

    yield put(getListOfNearByStoresSuccess({ nearByStores }));
  } catch (error) {
    yield put(getListOfNearByStoresFailure());
  }
}

function* watchOnGetListOfNearByStores() {
  yield takeEvery(GET_LIST_OF_NEAR_BY_STORES, onGetListOfNearByStores);
}

export default function* listOfNearByStoresSaga() {
  yield all([watchOnGetListOfNearByStores()]);
}
