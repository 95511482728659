import { connect } from "react-redux";
import { getScheduleViolationOverride } from "../../core/selectors/schedules/scheduleViolationOverride";
import { getScheduleViolationMessage } from "../../core/selectors/schedules/scheduleViolationMessage";
import CommonErrorMessage from "../common/errorAndMessages/CommonErrorMessage";

const mapStateToProps = state => ({
  message: getScheduleViolationMessage(state),
  isError: getScheduleViolationOverride(state),
  highlight: "2rem"
});

export default connect(mapStateToProps)(CommonErrorMessage);
