import { LOCATION_CHANGE } from "../actionTypes/navigationActionTypes";

const initialState = {
  previousLocation: null,
  currentLocation: null
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        previousLocation: state.currentLocation,
        currentLocation: action.location
      };
    default:
      return state;
  }
};

export default navigationReducer;
