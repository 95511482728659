import { createSelector } from "reselect";
import { getSelectedAddOns } from "core/selectors/cartSelectors";
import { getAddOns, getEnhancedServices } from "core/selectors/entitiesSelector";
import {
  getBundleAddOnByPetServiceId,
  selectBundleEnhancedService,
} from "dux/bgm/availableBundlesByPet/availableBundlesByPetSelectors";

/**
 * Converts an array of selected add-on objects to an array of numeric selected add-on ID's
 *
 * @export getTimestampDiff
 * @param {Array.<Object>} - Array of selected add-ons objects
 * @returns {Array.<Number>} - Array of selected add-on ids.
 */

export const getSelectedAddOnIds = createSelector([getSelectedAddOns], (selectedAddOns = []) => {
  return selectedAddOns.map(addOn => Number(addOn?.addOnId));
});

export const getPrePaidBundleAddOnPriceByPetServiceId = createSelector(
  [getBundleAddOnByPetServiceId, getAddOns],
  (addOnIncludedWithBundle, addOns) => {
    const prePaidAddOn = Object.values(addOns)?.find(
      addOn => addOn.addOnId === Number(addOnIncludedWithBundle?.addOnId),
    );

    return prePaidAddOn?.price?.toFixed(2);
  },
);

/**
 * Selector to get Salon Enhanced Services from the Redux State
 * @memberOf Selectors.Entities
 * @function
 * @name selectPrePaidBundleEnhancedServicePrice
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @param {String} props.petServiceId - id of service used to find active bundle
 * @returns {String} - formatted price of the pre paid enhanced addon or undefined
 * @example selectPrePaidBundleEnhancedServicePrice(state, { petId, petServiceId })
 */
export const selectPrePaidBundleEnhancedServicePrice = createSelector(
  [selectBundleEnhancedService, getEnhancedServices],
  (enhancedServiceIncludedWithBundle, addOns) => {
    const prePaidAddOn = Object.values(addOns)?.find(
      addOn => addOn.addOnId === Number(enhancedServiceIncludedWithBundle?.addOnId),
    );

    return prePaidAddOn?.price?.toFixed(2);
  },
);
