import { mapValues } from "lodash/fp";

/**
 * Creates Object of Customer & Pet eligibility values from state to
 * filter the reasons for Customer or Pet based on the predicate callback to
 * be used to display the modal alerts and explain the reason
 * for eligibility warning/failure to the associate UI.
 *
 * @param {object} eligibility The eligibility object containing the pet and customer.
 * @param {function} predicate The type of eligibility to check for (i.e RabiesExpiration)
 * @return {object}
 */
export default function getFilteredReasons(eligibility, predicate) {
  const { customer, pets } = eligibility;

  const result = {
    customer: {
      ...customer,
      reasons:
        customer && customer.reasons ? customer.reasons.filter(reason => predicate(reason)) : [],
    },
    pets: mapValues(
      pet => ({
        ...pet,
        reasons: pet && pet.reasons ? pet.reasons.filter(reason => predicate(reason)) : [],
      }),
      pets,
    ),
  };
  return result;
}
