import React from "react";
import styled from "styled-components";
import { color, font } from "../../common/styles/theme";
import QuickViewCardContainer from "./quickViewCard/quickViewCardContainer";

export default function QuickViewWrapper(props) {
  const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  `;

  // Component JSX
  return <SectionWrapper>{props.showQuickView ? <QuickViewCardContainer /> : null}</SectionWrapper>;
}
