import { connect } from "react-redux";
import BookingMedicationFormComponent from "../../../web/common/BookingMedicationFormComponent";
import { getMedicationIsShowingDetailsForType } from "../../../web/medicationList/medicationListSelectors";
import { NEW_MEDICATION_ID } from "../../../web/newMedication/newMedicationConstants";
import { getMedicationIds } from "../../../web/medication/medicationsSelector";

const mapStateToProps = (state, ownProps) => {
  const { petId, locationType } = ownProps;
  const medicationId = NEW_MEDICATION_ID;
  return {
    medicationId,
    petId,
    medicationIds: getMedicationIds(state, { petId }),
    isShowingDetails: getMedicationIsShowingDetailsForType(state, {
      medicationId,
      petId,
      locationType,
    }),
    isHidden: !getMedicationIsShowingDetailsForType(state, { medicationId, petId, locationType }),
  };
};

export default connect(mapStateToProps)(BookingMedicationFormComponent);
