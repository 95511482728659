import { createSelector } from "reselect";
import { getAppointmentByPet } from "core/selectors/cartSelectors";
import { getPetServiceItem, getServiceDetailsForCart } from "core/selectors/entitiesSelector";
import { getState } from "core/selectors/commonSelector";

export const getAppointmentPriceSalon = createSelector(
  [getAppointmentByPet, getState],
  (appointment, state) => {
    const petServiceItemId = appointment?.petServiceItemId;
    const timeSlot = appointment?.timeSlot;
    const petServiceId = appointment?.petService;
    const petServiceItem = getPetServiceItem(state, { petServiceItemId }) || false;
    const dynamicPrice = petServiceItem?.dynamicPrice;
    const service = getServiceDetailsForCart(state, {
      petServiceId,
      petServiceItemId,
    });

    if (dynamicPrice) {
      return dynamicPrice?.finalPrice?.toFixed(2);
    }

    if (timeSlot) {
      return timeSlot?.price?.toFixed(2);
    }

    return service?.price?.toFixed(2);
  },
);
