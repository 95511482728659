export const GET_SERVICE_PRODUCT_FAMILIES = "GET_SERVICE_PRODUCT_FAMILIES";
export const GET_SERVICE_PRODUCT_FAMILIES_REQUEST = "GET_SERVICE_PRODUCT_FAMILIES_REQUEST";
export const GET_SERVICE_PRODUCT_FAMILIES_SUCCESS = "GET_SERVICE_PRODUCT_FAMILIES_SUCCESS";
export const GET_SERVICE_PRODUCT_FAMILIES_FAILURE = "GET_SERVICE_PRODUCT_FAMILIES_FAILURE";

export const getServiceProductFamilies = () => ({
  type: GET_SERVICE_PRODUCT_FAMILIES,
});

export const getServiceProductFamiliesRequest = () => ({
  type: GET_SERVICE_PRODUCT_FAMILIES_REQUEST,
});

export const getServiceProductFamiliesSuccess = ({ payload }) => ({
  type: GET_SERVICE_PRODUCT_FAMILIES_SUCCESS,
  payload,
});

export const getServiceProductFamiliesFailure = ({ error }) => ({
  type: GET_SERVICE_PRODUCT_FAMILIES_FAILURE,
  error,
});
