import { compose, flatMap, filter, matchesProperty, get, some, isEmpty } from "lodash/fp";
import shallowDiff from "./objectUtils/shallowDiff";
import {
  CUSTOMER_INFO_FIELDS,
  CUSTOMER_EMAIL_FIELDS,
  CUSTOMER_PHONE_FIELDS,
  CUSTOMER_ADDRESS_FIELDS,
  CUSTOMER_PROFILE_FIELDS,
  customerPhoneTypes,
  channelTypes,
  communicationTypes,
  CUSTOMER_DO_NOT_BOOK_FIELDS,
} from "../constants/customerProfileConstants";
import getFormFieldsChanged from "./formUtils/getFormFieldsChanged";

export const getFormBookingFieldsChanged = compose(shallowDiff);

export const getCustomerProfileFieldsToUpdate = (
  previousFormValues,
  currentFormValues,
  isFormFieldHidden,
) => {
  const formFieldsChanged = getFormFieldsChanged(previousFormValues, currentFormValues);

  const fieldsToUpdate = Object.keys(formFieldsChanged).reduce(
    (accumulator, currentField) => {
      if (
        CUSTOMER_INFO_FIELDS.includes(currentField) ||
        (CUSTOMER_DO_NOT_BOOK_FIELDS.includes(currentField) && !isFormFieldHidden)
      ) {
        return {
          ...accumulator,
          customerInfoFieldsToUpdate: {
            ...accumulator.customerInfoFieldsToUpdate,
            [currentField]: formFieldsChanged[currentField],
          },
        };
      }
      if (CUSTOMER_EMAIL_FIELDS.includes(currentField)) {
        return {
          ...accumulator,
          emailFieldsToUpdate: {
            ...accumulator.emailFieldstoUpdate,
            [currentField]: formFieldsChanged[currentField],
          },
        };
      }
      if (CUSTOMER_PHONE_FIELDS.includes(currentField)) {
        return {
          ...accumulator,
          phoneFieldsToUpdate: {
            ...accumulator.phoneFieldsToUpdate,
            [currentField]: formFieldsChanged[currentField],
          },
        };
      }
      if (CUSTOMER_ADDRESS_FIELDS.includes(currentField)) {
        return {
          ...accumulator,
          addressFieldsToUpdate: {
            ...accumulator.addressFieldsToUpdate,
            [currentField]: formFieldsChanged[currentField],
          },
        };
      }
      if (
        CUSTOMER_PROFILE_FIELDS.includes(currentField) ||
        (CUSTOMER_DO_NOT_BOOK_FIELDS.includes(currentField) && isFormFieldHidden)
      ) {
        return {
          ...accumulator,
          profileFieldsToUpdate: {
            ...accumulator.profileFieldsToUpdate,
            [currentField]: formFieldsChanged[currentField],
          },
        };
      }

      return { ...accumulator };
    },

    {
      customerInfoFieldsToUpdate: {},
      emailFieldsToUpdate: {},
      phoneFieldsToUpdate: {},
      addressFieldsToUpdate: {},
      profileFieldsToUpdate: {},
    },
  );

  return fieldsToUpdate;
};

export const getPhoneFields = formFields =>
  Object.entries(formFields).filter(([key]) => key.toLowerCase().includes("phone"));

export const getPhoneType = value => {
  if (value.includes("work")) {
    return customerPhoneTypes.WORK;
  }
  if (value.includes("home")) {
    return customerPhoneTypes.HOME;
  }

  return customerPhoneTypes.MOBILE;
};

export const getPhoneChannel = x =>
  compose(
    filter(matchesProperty("channelId", channelTypes.SERVICES)),
    flatMap(get("channels")),
    flatMap(get("contacts")),
    filter(matchesProperty("communicationTypeId", communicationTypes.TEXT)),
    Array.isArray(x) ? flatMap(get("communicationPreferences")) : get("communicationPreferences"),
  )(x);

export const isMissingCommunicationPreferences = phone =>
  isEmpty(get("communicationPreferences", phone));

export const getIsTextOptedOut = phone => {
  // Automatically set text opted out to true if missing communication preferences
  if (isMissingCommunicationPreferences(phone)) {
    return true;
  }

  return compose(some(get("isOptedOut")), getPhoneChannel)(phone);
};

export const getIsPhonePreferred = compose(some(get("isPreferred")), getPhoneChannel);

export const getContacts = compose(flatMap(get("contacts")));

export const buildPhoneChannelPreferences = ({
  contactId,
  isOptedOut = true,
  isPreferred = true,
}) => ({
  contactId,
  channels: [
    {
      channelId: channelTypes.SERVICES,
      isOptedOut,
      isPreferred,
    },
  ],
});

export const buildTextContactPreferences = preferences => [
  {
    communicationTypeId: communicationTypes.TEXT,
    contacts: preferences,
  },
];
