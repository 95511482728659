import { createSelector } from "reselect";
import moment from "moment-timezone";
import { getAssociate, getStoreTimeZone } from "@/core/selectors/entitiesSelector";
import { getState, getProps } from "@/core/selectors/commonSelector";
import { getIsTargetDate } from "@/dux/utils/dateUtils/getIsTargetDate";
import { getSelectedDate } from "@/dux/selectedDate/selectedDateSelector";
import { getAssociatesFilterButtonData } from "dux/trainingAssociates/trainingAssociatesSelectors";
import convertDateToTimestamp from "@/core/utils/dateUtils/dateToTimestamp";
import { getCustomerTimeZone } from "../scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";

const getTrainingClassAvailabilityState = (state) => state.trainingClassAvailability;

// Filter Class availability by Date
const getTrainingClassAvailabilityForSelectedDate = createSelector(
  [getTrainingClassAvailabilityState, getSelectedDate, getStoreTimeZone],
  (timeSlots, selectedDate, storeTimeZone) => {
    const slots = Object.values(timeSlots).filter((timeSlot) => {
      const start = moment.tz(timeSlot.startDatetime, storeTimeZone);
      const selected = moment.tz(selectedDate, storeTimeZone);
      /*
        Since the selected date doesn't include a time, it defaults to T00:00:00Z.
        This is a problem when comparing it to the class start time which DOES have a time, ie (T17:00:00Z)
        Therefore, we're setting the selected date's time equal to the class time.
        This way when we compare them, they're both based on the store timezone,
          and the time is not off by the UTC offset (ie. -7 hrs for MST)
        This fix is related to SVCSART-15949 and SVCSART-16736
      */
      selected.hours(start.hours());
      selected.minutes(start.minutes());
      selected.seconds(start.seconds());
      selected.milliseconds(start.milliseconds());

      return getIsTargetDate(start, selected);
    });

    return slots;
  },
);

// Filter class availability by Date and Selected Trainer
const getTrainingClassAvailabilityForSelectedTrainer = createSelector(
  [getTrainingClassAvailabilityForSelectedDate, getAssociatesFilterButtonData],
  (availabilityForSelectedDate, buttonData) => {
    const idList = buttonData.filter((item) => item.isPressed).map((item) => item.id);

    if (idList.length === 0) {
      return availabilityForSelectedDate;
    }

    return availabilityForSelectedDate.filter((selectedDate) =>
      idList.includes(selectedDate.associateId),
    );
  },
);

// Build Card Data.
export const getTrainingClassAvailabilityByClassSessionId = createSelector(
  [getTrainingClassAvailabilityState, getProps],
  (timeSlots, { classSessionId }) =>
    Object.values(timeSlots).find((timeSlot) => timeSlot.classSessionId === classSessionId),
);

export const getAvailableTrainingTimeSlots = (
  filteredTimeSlotsSelector = getTrainingClassAvailabilityState,
) =>
  createSelector(
    [filteredTimeSlotsSelector, getStoreTimeZone, getCustomerTimeZone, getState],
    (timeSlots, storeTimeZone, customerTimeZone, state) => {
      // Selector logic goes here

      return timeSlots
        .sort((a, b) => {
          const previousStartTime = convertDateToTimestamp(
            moment.tz(a.startDatetime, storeTimeZone),
          );
          const currentStartTime = convertDateToTimestamp(
            moment.tz(b.startDatetime, storeTimeZone),
          );

          // Sort by store timeslot
          if (previousStartTime > currentStartTime) return 1;
          if (previousStartTime < currentStartTime) return -1;

          const associateA = getAssociate(state, { associateId: a.associateId });
          const associateB = getAssociate(state, { associateId: b.associateId });

          const [firstNameA, lastNameA] = associateA?.associateName?.toUpperCase()?.split(" ");
          const [firstNameB, lastNameB] = associateB?.associateName?.toUpperCase()?.split(" ");

          // If store timeslots are identical, sort by first name
          if (firstNameA > firstNameB) return 1;
          if (firstNameA < firstNameB) return -1;

          // If first names are identical, then sort by last name
          if (lastNameA > lastNameB) return 1;
          if (lastNameA < lastNameB) return -1;

          return;
        })
        .map((timeSlot) => {
          const associate = getAssociate(state, { associateId: timeSlot.associateId });
          const storeStartTime = moment.tz(timeSlot.startDatetime, storeTimeZone).format("hh:mm A");
          const storeEndTime = moment.tz(timeSlot.endDatetime, storeTimeZone).format("hh:mm A z");
          const customerStartTime = moment
            .tz(timeSlot.startDatetime, customerTimeZone)
            .format("hh:mm A");
          const customerEndTime = moment
            .tz(timeSlot.endDatetime, customerTimeZone)
            .format("hh:mm A z");
          return {
            ...timeSlot,
            storeTimeSlot: `${storeStartTime} - ${storeEndTime} `,
            customerTimeSlot: `${customerStartTime} - ${customerEndTime}`,
            associateName: associate?.associateName,
            storeLabel: "(Store)",
            customerLabel: "(Customer)",
          };
        });
    },
  );

// Send filtered by Date to selector to build card
export const getAvailableTrainingTimeSlotsForSelectedDate = getAvailableTrainingTimeSlots(
  getTrainingClassAvailabilityForSelectedTrainer,
);
