import { connect } from "react-redux";
import SearchInput from "../../../../web/common/SearchInput";
import simplePhrases from "../../../_constants/simplePhrasesConstants";
import {
  clearHotelCheckedOutSearchText,
  setHotelCheckedOutSearchText,
} from "./hotelCheckedOutHeaderSearchActions";
import { getCheckedOutSearchText } from "./hotelCheckedOutHeaderSearchSelector";

const mapStateToProps = state => ({
  placeholder: simplePhrases.HOTEL_HEADER_SEARCH_BY,
  value: getCheckedOutSearchText(state),
});

const mapDispatchToProps = dispatch => ({
  onChange: e => dispatch(setHotelCheckedOutSearchText({ value: e.target.value })),
  clearText: () => dispatch(clearHotelCheckedOutSearchText()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
