import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import { isEmpty, compose, map, filter, get, uniq } from "lodash/fp";
import moment from "moment";
import { getStoreNumber } from "../../core/selectors/persistentSelectors";
import salonHoursActionTypes from "../../core/actionTypes/salonHoursActionTypes";
import salonHoursActionCreators from "../../core/actionCreators/salonHoursActionCreators";
import { fetchSalonHours } from "../../core/services/petsmartWebPublic/salonHoursEndPoints";
import { getSalonTimes } from "../selectors/entitiesSelector";
import { getStartWeekDate, getWeeklyEditActivities } from "../selectors/schedulesSelectors";
import { normalizeSalonHours } from "@/dux/utils/normalize/normalizeSalonHours";

function* onloadSalonHours({ beginDate, endDate }) {
  try {
    const storeNumber = yield select(getStoreNumber);
    yield put(salonHoursActionCreators.loadSalonHoursRequest());
    const response = yield call(fetchSalonHours, { storeNumber, beginDate, endDate });
    const SalonHours = getSalonTimes(response.data);
    yield put(
      salonHoursActionCreators.loadSalonHoursSuccess({
        salonHours: normalizeSalonHours(SalonHours),
      }),
    );
  } catch (error) {
    yield put(salonHoursActionCreators.loadSalonHoursFailure({ error }));
  }
}

export function* onloadRelocatedSalonHours({ storeNumber, beginDate, endDate }) {
  try {
    yield put(salonHoursActionCreators.loadRelocatedSalonHoursRequest());
    const response = yield call(fetchSalonHours, { storeNumber, beginDate, endDate });
    const SalonHours = getSalonTimes(response.data);
    yield put(
      salonHoursActionCreators.loadRelocatedSalonHoursSuccess({
        StoreNumber: storeNumber,
        salonHours: normalizeSalonHours(SalonHours),
      }),
    );
  } catch (error) {
    yield put(salonHoursActionCreators.loadSalonHoursFailure({ error }));
  }
}

// Load all relocated salon hours to validate schedule
export function* onloadAllRelocatedSalonHours() {
  try {
    yield put(salonHoursActionCreators.loadAllRelocatedSalonHoursRequest());
    const dayActivities = yield select(getWeeklyEditActivities);
    const storeNumber = yield select(getStoreNumber);
    const startWeekDate = yield select(getStartWeekDate);
    const beginDate = moment(startWeekDate).startOf("isoWeek");
    const endDate = moment(startWeekDate).endOf("isoWeek");
    const relocatedStoreHoursToFetch = getRelocatedSalonHoursToFetch({
      dayActivities,
      storeNumber,
    });

    if (!isEmpty(relocatedStoreHoursToFetch)) {
      const relocatedStoreHoursAPICalls = relocatedStoreHoursToFetch.map(store =>
        call(onloadRelocatedSalonHours, { storeNumber: store, beginDate, endDate }),
      );
      yield all(relocatedStoreHoursAPICalls);
    }

    yield put(salonHoursActionCreators.loadAllRelocatedSalonHoursSuccess());
  } catch (e) {
    yield put(salonHoursActionCreators.loadAllRelocatedSalonHoursFailure(e));
  }
}

function getRelocatedSalonHoursToFetch({ dayActivities, storeNumber }) {
  return compose(
    uniq,
    map(get("storeId")),
    filter(({ storeId }) => storeId !== storeNumber),
    filter(get("storeId")),
  )(dayActivities);
}

function* watchLoadSalonHours() {
  yield takeEvery(salonHoursActionTypes.LOAD_SALON_HOURS, onloadSalonHours);
}

function* watchRelocatedSalonHours() {
  yield takeEvery(salonHoursActionTypes.LOAD_RELOCATED_SALON_HOURS, onloadRelocatedSalonHours);
}

function* watchLoadAllRelocatedSalonHours() {
  yield takeEvery(
    salonHoursActionTypes.LOAD_ALL_RELOCATED_SALON_HOURS,
    onloadAllRelocatedSalonHours,
  );
}

export default function* salonHoursSaga() {
  yield all([
    fork(watchLoadSalonHours),
    fork(watchRelocatedSalonHours),
    fork(watchLoadAllRelocatedSalonHours),
  ]);
}
