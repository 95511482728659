import { connect } from "react-redux";

import {
  setHereBoardedGuestChecked,
  clearHereBoardedGuestChecked
} from "../../core/actionCreators/upcomingAndHereActionCreators";
import { getHereBoardedGuestChecked } from "../../core/selectors/upcomingAndHereSelectors";
import CheckboxWithLabel from "../common/CheckboxWithLabel";

const mapStateToProps = state => ({
  checked: getHereBoardedGuestChecked(state),
  label: "boardedGuest"
});

const mapDispatchToProps = dispatch => ({
  setHandler: () => dispatch(setHereBoardedGuestChecked()),
  clearHandler: () => dispatch(clearHereBoardedGuestChecked())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckboxWithLabel);
