/** @module replaceLastOccurrence */

/**
 * Replaces the last occurrence of whatToReplace with replaceWith.
 *
 * @export replaceLastOccurrence
 * @param {string} str
 * @param {string} whatToReplace
 * @param {string} replaceWith
 * @returns {string}
 */
export default function replaceLastOccurrence(str, whatToReplace, replaceWith) {
  const lastIndex = str.lastIndexOf(whatToReplace);

  return str.substring(0, lastIndex) + replaceWith + str.substring(lastIndex + 1);
}
