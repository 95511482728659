import { SET_CURRENT_ADDON_GROUP_ID } from "./hotelPriceOverrideActions";

export const currentAddonGroupingIdReducer = (state = "", action) => {
  switch (action.type) {
    case SET_CURRENT_ADDON_GROUP_ID:
      return action?.groupingId;
    default:
      return state;
  }
};
