import { connect } from "react-redux";
import {
  getGanttGroupsByAssociates,
  getGanttGroupsWeekly
} from "../../../core/selectors/ganttSelectors";
import GanttAssociateInfo from "../ganttAssociateInfo/GanttAssociateInfoContainer";
import GanttWeekDay from "../ganttWeekDay/GanttWeekDayContainer";

import { getStoreTimeZoneOffset } from "../../../core/selectors/entitiesSelector";
import { getStoreNumber } from "../../../core/selectors/persistentSelectors";
import { businessHours } from "../../../core/constants/associateSchedulingConstants";

import GanttBodyComponent from "./GanttBodyComponent";

const mapStateToProps = (state, ownProps) => {
  const storeNumber = getStoreNumber(state);

  return {
    businessHours,
    groups: ownProps.isWeekly
      ? getGanttGroupsWeekly(state, ownProps)
      : getGanttGroupsByAssociates(state, { ...ownProps, excludeAssociatesWithoutShift: true }),
    GroupMemberComponent: ownProps.isWeekly ? GanttWeekDay : GanttAssociateInfo,
    timeOffset: getStoreTimeZoneOffset(state, { storeNumber })
  };
};

export default connect(mapStateToProps)(GanttBodyComponent);
