export default {
  SHOW_PET_PARENT_PROFILE_MODAL: "SHOW_PET_PARENT_PROFILE_MODAL",
  HIDE_PET_PARENT_PROFILE_MODAL: "HIDE_PET_PARENT_PROFILE_MODAL",
  REBOOK_CLICK: "REBOOK_CLICK",
  HISTORY_CLICK: "HISTORY_CLICK",
  HISTORY_CLICK_FAILURE: "HISTORY_CLICK_FAILURE",
  LOAD_PET_PARENT_APIS: "LOAD_PET_PARENT_APIS",
  SET_CUSTOMER_AND_EMAIL_KEYS: "SET_CUSTOMER_AND_EMAIL_KEYS",
  CLEAR_CUSTOMER_AND_EMAIL_KEYS: "CLEAR_CUSTOMER_AND_EMAIL_KEYS",
  SET_FIELD_FOR_NEW_PROFILE: "SET_FIELD_FOR_NEW_PROFILE",
  CLEAR_FIELD_FOR_NEW_PROFILE: "CLEAR_FIELD_FOR_NEW_PROFILE",
  DISPLAY_OTHER_PET_PARENT_ADDRESSES: "DISPLAY_OTHER_PET_PARENT_ADDRESSES",
  DISPLAY_ADD_PET_PARENT_ADDRESS_FORM: "DISPLAY_ADD_PET_PARENT_ADDRESS_FORM",
  SET_IS_ADDING_PET: "SET_IS_ADDING_PET"
};
