import { connect } from "react-redux";
import CapacityModals from "dux/_components/roomCapacity/CapacityModals";
import { showHideHotelCapacityModal } from "dux/hotelRoomCapacityModal/hotelRoomCapacityModalActions";
import { getHotelModalIsHidden } from "dux/hotelRoomCapacityModal/hotelRoomCapacitySelector";
import { HotelRoomCapacityDetails } from "dux/hotelRoomCapacityModal/hotelRoomCapacityDetails";

const mapStateToProps = state => ({
  isHidden: getHotelModalIsHidden(state),
  componentID: "CapacityModals__HotelRoomCapacity",
  compName: "HOTEL",
  diComp: { HOTEL: HotelRoomCapacityDetails },
});

const mapDispatchToProps = dispatch => {
  return {
    onModalClose: () => {
      dispatch(showHideHotelCapacityModal({ isHidden: true }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CapacityModals);
