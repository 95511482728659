import { connect } from "react-redux";
import CommonButton from "web/common/commonButton";
import { APPOINTMENT_STATUS } from "core/constants";
import {
  getCancelAppointmentReason,
  getCurrentPetServiceItem,
} from "core/selectors/checkInOutSelector";
import { getBookingPetServiceItem } from "core/selectors/bookingUISelectors";
import { getPet, getPetServiceItem } from "core/selectors/entitiesSelector";
import { updateEngagementStatus } from "core/actionCreators/engagementsActionCreators";
import { clearCart } from "core/actionCreators/ui/web/cartActionCreators";
import { hideCheckInOutModal } from "core/actionCreators/checkInOutActionCreator";
import { hideBookingModal } from "core/actionCreators/bookingActionCreators";
import { hideCancelAppointmentModal } from "core/actionCreators/cancellationsActionCreators";
import { REBOOK } from "dux/appointmentRebook/_appointmentRebookConstants";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const mapStateToProps = state => {
  const currentPetServiceItem = getCurrentPetServiceItem(state);
  const currentBookingPetServiceItem = getBookingPetServiceItem(state);
  const petServiceItem = currentPetServiceItem
    ? getPetServiceItem(state, { petServiceItemId: currentPetServiceItem })
    : getPetServiceItem(state, { petServiceItemId: currentBookingPetServiceItem });
  const isCanceled = petServiceItem?.status === APPOINTMENT_STATUS.CANCELED;
  const cancelAppointmentReason = getCancelAppointmentReason(state);
  const pet = getPet(state, { petId: petServiceItem?.pet });

  return {
    componentID: `AppointmentRebookCancelAppt__CommonButton`,
    isHidden: isCanceled,
    label: REBOOK,
    disabled: !cancelAppointmentReason || !pet.isActive,

    // for mergeProps
    petServiceItem,
    cancelAppointmentReason,
  };
};

const mapDispatchToProps = (dispatch, { router: { navigate } }) => ({
  handleRebook: ({ petServiceItem, cancelAppointmentReason }) => {
    if (!petServiceItem) return;

    const {
      addOns,
      pet,
      petServiceId,
      itinerary,
      engagement,
      lockToAssociate,
      customer,
    } = petServiceItem;

    dispatch(
      updateEngagementStatus({
        customerId: customer,
        itineraryId: itinerary,
        engagementId: engagement,
        reason: cancelAppointmentReason,
        status: APPOINTMENT_STATUS.CANCELED,
      }),
    );

    dispatch(clearCart());

    navigate(`/booking/${customer}/select-service`, {
      state: {
        cart: {
          addOns,
          pet,
          petServiceId,
          lockToAssociate,
        },
      },
    });

    dispatch(hideCheckInOutModal());
    dispatch(hideBookingModal());
    dispatch(hideCancelAppointmentModal());
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () =>
    propsFromDispatch.handleRebook({
      petServiceItem: propsFromState.petServiceItem,
      cancelAppointmentReason: propsFromState.cancelAppointmentReason,
    }),
});

export default withRouteProps(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton),
);
