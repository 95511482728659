import React, { useEffect } from "react";
import { Layout } from "@prism/psm-ui-components";

import LoadingWrapper from "@/web/common/LoadingWrapper";
import TrainingHeaderContainer from "@/dux/_components/trainingHeader/TrainingHeaderContainer";
import TrainingClassDetails from "./TrainingClassDetails";
import TrainingAttendeeCards from "../trainingAttendeeCards/TrainingAttendeeCards";
import { RescheduleTrainingAppointmentFlashMessage } from "../flashMessage/RescheduleTrainingAppointmentFlashMessage";
import { ResendMeetingLinkFlashMessage } from "../flashMessage/ResendMeetingLinkFlashMessage";

const ManageTrainingClassComponent = ({
  getTrainingItinerary,
  resetRescheduleClassSessionId,
  resetTrainingClassSessions,
  isLoading,
}) => {
  useEffect(() => {
    getTrainingItinerary();
    return () => {
      resetRescheduleClassSessionId();
      // Need to reset training class sessions until we have a consistent/reliable class session id as an identifier to "select" the correct class session from a list of training classes
      resetTrainingClassSessions();
    };
  }, []);

  return (
    <LoadingWrapper displayContainer="block" isLoading={isLoading}>
      <TrainingHeaderContainer />
      <Layout.Box padding="box-padding-8">
        <Layout.Stack space="stack-space-12">
          <TrainingClassDetails />
          <Layout.Cluster space="cluster-space-36">
            <TrainingAttendeeCards />
          </Layout.Cluster>
        </Layout.Stack>
      </Layout.Box>
      <RescheduleTrainingAppointmentFlashMessage />
      <ResendMeetingLinkFlashMessage />
    </LoadingWrapper>
  );
};

export default ManageTrainingClassComponent;
