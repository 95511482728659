// @ts-nocheck
import * as React from 'react';
import { psx } from '../../../../utils';
import FormField, { FormFieldInputProps, DEFAULT_FORM_FIELD_VARIANT } from '..';
import { getSelectErrorMessage } from '../error-messages';
import LegacySelect from './legacy-react-select';
import { FormElement } from '../../types';

import '../styles.css';

export interface TransformOption {
  (arg: number | string): number | string;
}

export interface FormFieldSelectProps extends FormFieldInputProps, React.ComponentPropsWithoutRef<'select'> {
  /** value relates to the selected item. if it's a string, it will find the index of the item */
  value?: number | string | undefined;
  /** the visible items in the select list */
  options?: string[];
  /** legacy applications use a library, this is our hook to enable native and library select components */
  legacy?: boolean;
  /** Transforms label based on transform function provided  */
  transformLabel?: TransformOption;
  /** Transforms value based on transform function provided */
  transformValue?: TransformOption;
}

export class FormFieldSelect extends React.Component<FormFieldSelectProps> {
  state = { errorMessage: '' };

  handleErrorMessage = (inputRef: FormElement) => {
    this.setState(() => ({
      errorMessage: getSelectErrorMessage(inputRef),
    }));
  };

  // OptionValue {label, name}
  handleChange = (e: any) => {
    if (this.props.onChange) {
      /* @ts-ignore */
      this.props.onChange(e, this.props.name);
    }
    this.setState(() => ({
      errorMessage: '',
    }));
  };

  handleInvalid = (inputRef: FormElement) => {
    this.setState(() => ({
      errorMessage: getSelectErrorMessage(inputRef),
    }));
  };

  render() {
    const {
      children,
      className,
      name,
      label,
      labelClassName,
      onInput,
      legacy = false,
      options = [],
      defaultValue,
      value,
      variant = DEFAULT_FORM_FIELD_VARIANT,
      transformLabel = (f) => f,
      transformValue = (f) => f,
      ...rest
    } = this.props;

    if (legacy) {
      let formatOptions = [];
      if (typeof options[0] === 'object') {
        formatOptions = options;
      } else {
        formatOptions = options.map((option) => {
          return {
            label: transformLabel(option),
            value: transformValue(option),
          };
        });
      }

      const formatValue = value && { label: transformLabel(value), value: transformValue(value) };
      const { errorMessage } = this.state;

      return (
        <FormField className={className} name={name} error={errorMessage} variant="legacy-select">
          {/* Ignoring Typescript errors for now since legacy REact Select types do not seem to be compatible with React 17 types */}
          {/* @ts-ignore */}
          <LegacySelect
            {...rest}
            name={name}
            label={label}
            value={formatValue}
            options={formatOptions}
            error={errorMessage}
            onInvalid={this.handleInvalid}
            onChange={this.handleChange}
          />
        </FormField>
      );
    }

    const classNames = psx(`psm`, `form-input-selection`, className);

    const optionValues = options.map((optionValue: string, index: string | number) => (
      <option key={`${index}-${optionValue}`} value={optionValue}>
        {optionValue}
      </option>
    ));

    const valueAsNumber = value ? parseInt(value.toString(), 10) : 0;

    // -1 = no selection
    // if it's a string, make sure it is not an index
    const selectValue =
      typeof value === 'string' && isNaN(valueAsNumber)
        ? options.findIndex((o: string) => o === value) || -1
        : valueAsNumber;

    return (
      <FormField className={labelClassName} label={label} variant={variant}>
        <select {...rest} className={classNames} defaultValue={options[selectValue]}>
          {optionValues}
        </select>
      </FormField>
    );
  }
}
