import replaceLastOccurrence from "./replaceLastOccurrence";

/** @module joinWithLastDelimiter */

/**
 * Takes an array of strings, joins them by the delimiter and replaces the last
 * delimiter with the given lastDelimiter.
 *
 * @param {string[]} stringsArray
 * @param {string} delimiter
 * @param {string} lastDelimiter
 */
export default function joinWithLastDelimiter(
  stringsArray,
  delimiter = ", ",
  lastDelimiter = " and "
) {
  const asString = stringsArray.join(delimiter);

  return replaceLastOccurrence(asString, delimiter, lastDelimiter);
}
