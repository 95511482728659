import { connect } from "react-redux";
import moment from "moment";
import { PsmPrevNextSelector } from "@prism/psm-ui-components";
import { getSelectedDate } from "@/dux/selectedDate/selectedDateSelector";
import { setSelectDate } from "@/dux/selectedDate/selectedDateActions";
import calcAndDispatchRange from "dux/trainingClassSessions/helpers/calcandDispatchRange";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { getTrainingClassCachedMonths } from "dux/trainingClassSessions/selectors";

const mapStateToProps = state => {
  const selectedDate = getSelectedDate(state);
  const isToday = moment(0, "HH").diff(selectedDate, "days") === 0;
  const formattedDate = moment(selectedDate).format(`[${isToday ? "Today, " : ""}] MMMM Do YYYY`);

  return {
    children: formattedDate,
    storeNumber: getStoreNumber(state),
    cachedMonths: getTrainingClassCachedMonths(state),

    childOverrides: { justifyContent: "space-between" },
    space: 20,
    selectedDate,
  };
};

const mapDispatchToProps = dispatch => ({
  handleSelectNext: ({ selectedDate, cachedMonths, storeNumber }) => {
    const nextDay = moment(selectedDate).add(1, "days");
    dispatch(setSelectDate(nextDay));

    // wap selected date into a moments object
    const MomentObj = moment(nextDay);

    calcAndDispatchRange(dispatch, { MomentObj, cachedMonthsList: cachedMonths, storeNumber });
  },
  handleSelectPrev: ({ selectedDate, cachedMonths, storeNumber }) => {
    const prevDay = moment(selectedDate).subtract(1, "days");
    dispatch(setSelectDate(prevDay));

    // wap selected date into a moments object
    const MomentObj = moment(prevDay);

    calcAndDispatchRange(dispatch, { MomentObj, cachedMonthsList: cachedMonths, storeNumber });
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  selectNext: () =>
    propsFromDispatch.handleSelectNext({
      selectedDate: propsFromState.selectedDate,
      storeNumber: propsFromState.storeNumber,
      cachedMonths: propsFromState.cachedMonths,
    }),
  selectPrev: () =>
    propsFromDispatch.handleSelectPrev({
      selectedDate: propsFromState.selectedDate,
      storeNumber: propsFromState.storeNumber,
      cachedMonths: propsFromState.cachedMonths,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PsmPrevNextSelector);
