import { createSelector } from "reselect";
import isEmpty from "lodash/fp/isEmpty";
import intersection from "lodash/fp/intersection";
import uniq from "lodash/fp/uniq";
import { formatCalendarDateMoment } from "@/core/utils/dateUtils/formatDateTime";
import { getDateRange } from "@/core/utils/dateUtils/dateRanges";
import moment from "moment";
import { find, get } from "lodash/fp";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import { hotelPetRatings } from "dux/_constants/petRatingConstants";
import { getCustomer, getPetBreedIsAggressive, getPets } from "core/selectors/entitiesSelector";
import { getPetSpeciesId } from "core/selectors/ui/vaccinations/vaccinationsSelectors";
import { getProps, getState } from "core/selectors/commonSelector";
import flattenArray from "@/core/utils/arrayUtils/flattenArray";

export const selectHotelBookingFlow = state => state?.hotelBookingFlowReducer;

export const selectHotelBookingPetList = createSelector(
  [selectHotelBookingFlow],
  hotelBooking => hotelBooking?.petList || [],
);

/**
 * Selector gets list of host pet ids
 * @memberOf Selectors.hotelBookingFlowReducer
 * @name selectHotelBookingHostPets
 * @param {Object} state - redux state
 * @returns {array} list of host pet ids for all rooms
 * @example selectHotelBookingHostPets(state)
 */
export const selectHotelBookingHostPets = createSelector(
  [selectHotelBookingFlow],
  hotelBooking => hotelBooking?.hostPets || [],
);

export const selectHotelBookingRooms = createSelector(
  [selectHotelBookingFlow],
  hotelBooking => hotelBooking?.rooms || {},
);

/**
 * Selector gets all rooms with the list of pets sorted by host pet first
 * @memberOf Selectors.hotelBookingFlowReducer
 * @name selectHotelBookingRoomsHostFirst
 * @param {Object} state - redux state
 * @returns {Object} same shape as the 'rooms' object except pets have been sorted with host first
 * @example selectHotelBookingRoomsHostFirst(state)
 */
export const selectHotelBookingRoomsHostFirst = createSelector(
  [selectHotelBookingRooms, selectHotelBookingHostPets],
  (hotelBookingRooms, hotelBookingHostPets) =>
    Object.fromEntries(
      Object.entries(hotelBookingRooms).map(([room, petIds]) => [
        room,
        uniq([...intersection(petIds, hotelBookingHostPets), ...petIds]),
      ]),
    ),
);

export const selectHotelBookingPetListByRoomId = roomId =>
  createSelector([selectHotelBookingRooms], rooms => {
    return rooms[roomId] || [];
  });

export const selectHotelBookingRoomIdByPetId = petId =>
  createSelector([selectHotelBookingRooms], (rooms = {}) => {
    let roomId;

    if (!isEmpty(rooms)) {
      Object.keys(rooms).forEach(curRoomId => {
        if (rooms[curRoomId].includes(petId)) roomId = curRoomId;
      });
    }
    return roomId;
  });

export const selectIsCatInRoomWithDog = createSelector(
  [selectHotelBookingRooms, getState],
  (rooms, state) => {
    let roomsSharingCatAndDog = false;

    if (!isEmpty(rooms)) {
      Object.values(rooms).forEach(room => {
        const hasCat = room.find(petId => getPetSpeciesId(state, { petId }) === 2);
        const hasDog = room.find(petId => getPetSpeciesId(state, { petId }) === 1);

        if (!!hasCat && !!hasDog) {
          roomsSharingCatAndDog = true;
        }
      });
    }
    return roomsSharingCatAndDog;
  },
);

export const selectIsAggressivePetRoomSharing = createSelector(
  [selectHotelBookingRooms, getState],
  (rooms, state) => {
    let isAggressivePetRoomSharing = false;

    if (!isEmpty(rooms)) {
      Object.values(rooms).forEach(room => {
        const hasAggressivePet = room.find(petId => getPetBreedIsAggressive(state, { petId }));

        if (!!hasAggressivePet && Object.values(room)?.length > 1) {
          isAggressivePetRoomSharing = true;
        }
      });
    }
    return isAggressivePetRoomSharing;
  },
);

export const selectHotelBookingStartDate = createSelector(
  [selectHotelBookingFlow],
  hotelBooking => hotelBooking?.startDate,
);

export const selectHotelBookingEndDate = createSelector(
  [selectHotelBookingFlow],
  hotelBooking => hotelBooking?.endDate,
);

export const selectPetsWithoutRoomOrService = createSelector(
  [selectHotelBookingFlow, selectHotelBookingPetList],
  (hotelBooking, hotelBookingPetList) =>
    !!hotelBookingPetList?.find(
      pet => !hotelBooking[pet] || !hotelBooking[pet].petService || !hotelBooking[pet].roomId,
    ),
);

export const selectPetsWithoutRoom = createSelector(
  [selectHotelBookingFlow, selectHotelBookingPetList],
  (hotelBooking, hotelBookingPetList) =>
    !!hotelBookingPetList?.find(pet => !hotelBooking[pet] || !hotelBooking[pet].roomId),
);

export const selectPetsWithoutService = createSelector(
  [selectHotelBookingFlow, selectHotelBookingPetList],
  (hotelBooking, hotelBookingPetList) =>
    !!hotelBookingPetList?.find(pet => !hotelBooking[pet] || !hotelBooking[pet].petService),
);

export const selectCurrentServiceSelectionRoom = createSelector(
  [selectHotelBookingFlow],
  hotelBooking => hotelBooking?.currentServiceSelectionRoom,
);

export const selectHotelBookingPetById = petId =>
  createSelector([selectHotelBookingFlow], (hotelBooking = {}) => hotelBooking[petId] ?? {});

export const selectPetSelectedService = petId => {
  const petSelector = selectHotelBookingPetById(petId);
  return createSelector([petSelector], pet => pet?.petService);
};

/**
 * Selector gets the roomTypeBucketId for a given petId
 * @memberOf Selectors.hotelBookingFlowReducer
 * @name selectPetRoomTypeBucketId
 * @param {Object} state - redux state
 * @param {number} petId - petId in question
 * @returns {string} roomTypeBucketId of the given pet || undefined
 * @example selectPetRoomTypeBucketId(petId)(state)
 */
export const selectPetRoomTypeBucketId = petId => {
  const petSelector = selectHotelBookingPetById(petId);
  return createSelector([petSelector], pet => pet?.roomTypeBucketId);
};

/**
 * Selector gets guest pet ids in the host pets room
 * @memberOf Selectors.hotelBookingFlowReducer
 * @name selectGuestPetsByHostPetId
 * @param {Object} state - redux state
 * @param {number} hostPetId - host petId in question
 * @returns {array} petIds the guest pets in the same room as the host
 * @example selectGuestPetsByHostPetId(state, {hostPetId})
 */
export const selectGuestPetsByHostPetId = createSelector(
  [
    selectHotelBookingRooms,
    (state, { hostPetId }) => selectHotelBookingPetById(hostPetId)(state) || {},
    (__, { hostPetId }) => hostPetId,
  ],
  (rooms, { roomId }, hostPetId) => {
    return rooms[roomId]?.filter(petId => petId !== hostPetId);
  },
);

/**
 * Selector gets the host pet id of the room the input pet id is part of
 * @memberOf Selectors.hotelBookingFlowReducer
 * @name selectRoomHostIdByPetId
 * @param {Object} state - redux state
 * @param {number} petId - petId in question
 * @returns {number} petId of the host pet in the same room
 * @example selectRoomHostIdByPetId(state, {petId})
 */
export const selectRoomHostIdByPetId = createSelector(
  [selectHotelBookingRoomsHostFirst, (state, { petId }) => selectHotelBookingPetById(petId)(state)],
  (rooms, { roomId }) => rooms[roomId]?.at(0),
);

/**
 * Selector gets if a service has been selected for the rooms host pet
 * @memberOf Selectors.hotelBookingFlowReducer
 * @name selectIsServiceSelectedForHostByPetId
 * @param {Object} state - redux state
 * @param {number} petId - petId in question
 * @returns {boolean}
 * @example selectIsServiceSelectedForHostByPetId(state, {petId})
 */
export const selectIsServiceSelectedForHostByPetId = createSelector(
  [selectRoomHostIdByPetId, getState],
  (hostPetId, state) => {
    return !!selectPetSelectedService(hostPetId)(state);
  },
);

export const selectMultiPetSelectedServices = ({ state, petList }) => {
  return petList?.map(pet => {
    return {
      petId: pet,
      selectedService: selectPetSelectedService(pet)(state),
    };
  });
};

/**
 * Selector to get an array of dates for every day of stay for the current hotel reservation being booked
 * @memberOf Selectors.hotelBookingFlowReducer
 * @function
 * @name getHotelEngagementByEngagementId
 * @param {Object} state - redux state
 * @returns {String[]} array of date strings in format 'YYYY-MM-DD'
 * @example selectBookingDateRangeForFrequency(state)
 */
export const selectBookingDateRangeForFrequency = createSelector(
  [selectHotelBookingStartDate, selectHotelBookingEndDate],
  (checkInDate, checkOutDate) =>
    getDateRange(
      formatCalendarDateMoment(checkInDate),
      formatCalendarDateMoment(checkOutDate),
      "days",
    )?.map(date => formatCalendarDateMoment(date)),
);

export const selectIsOvernight = createSelector(
  [selectHotelBookingStartDate, selectHotelBookingEndDate],
  (startDate, endDate) => !moment(startDate).isSame(moment(endDate), "day"),
);

export const getPetsNamesAndIdsAndActiveAndDoNotBookByCustomer = createSelector(
  [getPets, getCustomer],
  (pets, customer) => {
    return customer && customer.pets
      ? customer.pets
          .map(petId => {
            const petProfile = find(
              { profileType: systemName.DDC_HOTEL },
              get(["prismPet", "profiles"], pets[petId]),
            );
            const doNotBook = petProfile?.petRating === hotelPetRatings.NOT_GOOD_FIT;
            return {
              petId,
              petName: pets[petId].petName,
              isActive: pets[petId].isActive,
              speciesId: pets[petId].speciesId,
              doNotBook,
              petRatingReason: petProfile?.petRatingReason,
            };
          })
          .sort((x, y) => ((x.isActive === y).isActive ? 0 : x.isActive ? -1 : 1))
      : [];
  },
);

// @param customerKey
export const getBookablePetsForTabsList = createSelector(
  [getPetsNamesAndIdsAndActiveAndDoNotBookByCustomer],
  pets => pets?.filter(pet => !!pet.isActive),
);

/**
 * Select array of petId's for booking eligibility call
 * @memberOf Selectors.hotelBookingFlowReducer
 * @function
 * @name selectPetListForBookingEligibility
 * @param {Object} state
 * @param {Object} props
 * @param {String} props.customerKey
 * @param {String} props.petId
 * @returns {String[]}
 * @example selectPetListForBookingEligibility(state, { petId, customerKey })
 */
export const selectPetListForBookingEligibility = createSelector(
  [getBookablePetsForTabsList, selectHotelBookingPetList, getProps],
  (bookablePets, bookingPetList, { petId } = {}) => {
    const defaultPetList = bookablePets?.map(pet => pet?.petId);
    const petList = isEmpty(bookingPetList) ? defaultPetList : bookingPetList;
    return petId ? flattenArray([petId, petList]) : petList;
  },
);

// As long as at least one pet has a service selected, then the user
// would need to restart if they want to edit pets, dates, rooms, or services
export const selectShouldRestartToEdit = createSelector(
  [selectHotelBookingFlow, selectHotelBookingPetList],
  (hotelBooking, hotelBookingPetList) =>
    !!hotelBookingPetList?.find(pet => hotelBooking[pet]?.petService),
);

export const selectHotelBookingPendingChanges = createSelector(
  [selectHotelBookingFlow],
  hotelBooking => hotelBooking?.pending || {},
);

export const selectHotelBookingOpenStep = createSelector(
  [selectHotelBookingFlow],
  hotelBooking => hotelBooking?.openStep || 0,
);

export const selectHotelBookingFurthestStep = createSelector(
  [selectHotelBookingFlow],
  hotelBooking => hotelBooking?.furthestStep || 0,
);

// Checks if we've reached this step before, we can navigate to it (i.e. show buttonText).
export const selectHotelBookingCanNavigate = step =>
  createSelector([selectHotelBookingFurthestStep], furthestStep => step <= furthestStep);

// Checks if the step is the one being viewed based on if we can navigate to the step and the step is the current one in state.
export const selectHotelBookingIsActive = step =>
  createSelector(
    [selectHotelBookingCanNavigate(step), selectHotelBookingOpenStep],
    (canNavigate, openStep) => canNavigate && step === openStep,
  );

export const selectCommonHotelBookingStepData = step =>
  createSelector(
    [
      selectHotelBookingIsActive(step),
      selectHotelBookingCanNavigate(step),
      selectHotelBookingOpenStep,
      selectHotelBookingFurthestStep,
    ],
    (isActive, canNavigate, openStep, furthestStep) => ({
      isActive,
      canNavigate,
      openStep,
      furthestStep,
    }),
  );

/**
 * Selector to get array of pets being booked and their petServiceIds
 * @memberOf Selectors.hotelBookingFlowReducer
 * @function
 * @name selectHotelBookingPetServices
 * @returns {{ petId: Number, petServiceId: Number }[]}}
 * @example selectHotelBookingPetServices(state)
 */
export const selectHotelBookingPetServices = createSelector(
  [selectHotelBookingPetList, state => petId => selectPetSelectedService(petId)(state)],
  (petList, getPetServiceId) =>
    petList?.map(petId => ({ petId, petServiceId: getPetServiceId(petId) })),
);
