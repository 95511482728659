import React from "react";
import { Layout } from "@prism/psm-ui-components";
import PetVetCardContainer from "@/web/searchSalonSRC/petVetCard/PetVetCardContainer";

export default function PetVetCardListComponent({ isHidden, searchResults }) {
  if (isHidden) {
    return null;
  }
  return (
    <>
      {searchResults.map(clinic => (
        <Layout.Box
          padding="box-padding-4"
          borderWidth="box-border-width"
          borderColor="box-border-color-gray-100"
          style={{ marginBottom: "1em" }}
        >
          <PetVetCardContainer
            key={clinic.clinicId}
            clinic={clinic.data}
            clinicId={clinic.clinicId}
            vetId={clinic.vetId}
            address={clinic.address}
            zip={clinic.zip}
            city={clinic.city}
            state={clinic.state}
            phoneNumber={clinic.phone}
          />
        </Layout.Box>
      ))}
    </>
  );
}
