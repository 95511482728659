import axios from "axios";

/**
 * Axios Create configuration for authorize tokens API
 * Even though this is the same configuration as _axiosConfigAuth.js,
 * we are creating a new instance of Axios in order to add a request
 * interceptor to set the Authenticated Subscription Key (ASK) for the
 * endpoints that mock the access levels
 */
export const mockAccessLevelAPI = axios.create({
  baseURL: `${window.env.BASE_URL}${window.env.AUTH_URI}`,
  headers: {
    "Ocp-Apim-Subscription-Key": window.env.UNAUTHENTICATED_SUBSCRIPTION_KEY,
  },
});
