import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { get, isEmpty } from "lodash/fp";

// Actions
import { loadAssociates } from "core/actionCreators/associateActionCreator";
import { loadAllSalons } from "core/actionCreators/salonsActionCreator";
import { loadAbsenceReasons } from "core/actionCreators/absenceReasonsActionCreator";
import { loadSalonHours } from "core/actionCreators/salonHoursActionCreators";
import {
  setStartWeekDate,
  setEffectiveStartDate,
  setAssociateId,
  loadTemplateSchedules,
  clearSchedules,
  clearTemplateSchedules,
  setTemplateSchedules,
  showSchedulesModal,
} from "core/actionCreators/schedulesActionCreators";
import schedulesActionTypes from "core/actionTypes/schedulesActionTypes";

// Selectors
import { getAssociatesByGroup } from "core/selectors/associatesSelectors";
import { getStoreNumber } from "core/selectors/persistentSelectors";
import { getSchedulesModalType } from "core/selectors/modals/schedulesModalSelectors";
import {
  getAssociateId,
  getStartWeekDate,
  getEffectiveStartDate,
  getTemplateWeekId,
  getWeeklyEditActivities,
  getTemplateEffectiveStartDate,
  getAllTimeRangesError,
  getWeeklyEditChanged,
  getTemplateHasFutureAppointments,
  selectIsRelocationValid,
} from "core/selectors/schedulesSelectors";

// Utils
import { createLoadingSelector } from "core/selectors/utils";
import { formatCalendarDateMoment } from "core/utils/dateUtils/formatDateTime";
import { history } from "@/dux/utils/browser/browserHistory";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

// Constants
import { font } from "web/common/styles/theme";
import { pageNames } from "core/constants/checkInOutConstants";
import { modalTypes } from "core/constants/associateSchedulingConstants";

// Assets
import HeaderImage from "@/assets/images/bg-header-image.png";
import ExpiredIcon from "@/assets/icons/expired.svg";

// Components
import { TextPassage } from "@petsmart-ui/sparky";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import Button from "web/common/commonButton";
import Calendar from "web/common/Calendar";
import { RightSideArrow } from "web/common/styles/calendarStyle";
import AssociateDropdown from "web/common/associateDropdown/AssociateDropdownContainer";
import AssociateSchedulingHeaderComponent from "web/header/AssociateSchedulingHeaderContainer";
import { WeekTab } from "./weekTab/WeekTabContainer";
import AssociateSchedulingModals from "./modals/associateSchedulingModalsContainer";
import { AssociateSchedulingWeeklyTable } from "web/associateScheduling/weeklyTable/WeeklyTableComponent";

// Main Component
const TemplateSchedulingComponent = ({
  associateId,
  weekId,
  startWeekDate,
  effectiveStartDate,
  rangeSelectorText,
  templateEffectiveStartDate,
  weeklyEditActivities,
  modalType,
  timeRangesError,
  weeklyEditChanged,
  isLoading,
  allowedStartDate,
  loadAbsenceReasons,
  loadAssociates,
  loadAllSalons,
  setEffectiveStartDate,
  setAssociateId,
  loadTemplateSchedules,
  clearSchedules,
  clearTemplateSchedules,
  setTemplateSchedules,
  showModal,
  loadSalonHours,
  router,
  storeHours, // TODO: This prop isn't in container, add it ??
  isRelocationValid,
}) => {
  const [newAssociateId, setNewAssociateId] = useState();
  const [newWeekId, setNewWeekId] = useState();
  const unblock = useRef();

  // Mount
  useEffect(() => {
    loadAssociates({ isLimited: false });
    loadAllSalons();
    loadAbsenceReasons();
    callLoadSalonHours();

    try {
      const associateIdParam = parseInt(router.params.associateId, 10);
      if (!isNaN(associateIdParam)) {
        setAssociateId(associateIdParam);
      } else {
        clearSchedules();
        clearTemplateSchedules();
      }
    } catch (e) {}
  }, []);

  // Unmount
  useEffect(() => {
    return () => {
      if (unblock.current) unblock.current();
    };
  }, []);

  // associateId did update
  useEffect(() => {
    if (associateId) {
      loadTemplateSchedules({ associateId });
    } else {
      clearSchedules();
      clearTemplateSchedules();
    }
  }, [associateId]);

  // weeklyEditChanged did update
  useEffect(() => {
    if (weeklyEditChanged) {
      unblock.current = history.block(({ location }) => {
        const mainRoute = location.pathname.split("/")[1];

        if (mainRoute === "template-scheduling" || mainRoute === pageNames.FUTURE_APPOINTMENT) {
          return true;
        }
        showModal(modalTypes.UNSAVE_CHANGES_MODAL_URL_CHANGE);
        return false;
      });
    } else if (unblock.current) {
      unblock.current();
    }
  }, [weeklyEditChanged]);

  const callLoadSalonHours = () => {
    if (!get(startWeekDate, storeHours)) {
      loadSalonHours({
        beginDate: startWeekDate,
        endDate: formatCalendarDateMoment(moment(startWeekDate).add(7, "days")),
      });
    }
  };

  const handleAssociateChange = newId => {
    setNewAssociateId(newId);

    if (weeklyEditChanged) {
      showModal(modalTypes.UNSAVE_CHANGES_MODAL_ASSOCIATE_CHANGE);
      return;
    }

    setAssociateId(newId);
    history.push(`/template-scheduling/${newId}`);
  };

  const handleSave = () => {
    if (effectiveStartDate === templateEffectiveStartDate) {
      showModal(modalTypes.TEMPLATE_DATE_MUST_BE_CHANGED);
      return;
    }
    setTemplateSchedules({
      associateId,
      weekId,
      effectiveStartDate,
      dayActivities: weeklyEditActivities,
    });
  };

  const handleCancel = () => {
    if (associateId) {
      loadTemplateSchedules({ associateId });
      history.push(`/associate-scheduling/${associateId}`);
    }

    if (unblock.current) {
      unblock.current();
    }
  };

  // Shared styles
  const headerStyles = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "20px",
    fontFamily: font.mainFamily,
  };
  const warningStyles = { color: "red", flexGrow: "1", alignItems: "center" };

  return (
    <LayoutBox padding="scale-0">
      <AssociateSchedulingHeaderComponent backgroundImage={HeaderImage} />
      <LayoutBox padding="scale-G2">
        <LayoutBox padding="scale-0" style={{ paddingBottom: "20px" }}>
          <TextPassage style={headerStyles}>Associate</TextPassage>
          <AssociateDropdown
            selectedAssociate={associateId}
            onSelectAssociate={newId => handleAssociateChange(newId)}
            includeAllSuitable={false}
          />
        </LayoutBox>

        <TextPassage style={headerStyles}>Regular Schedule</TextPassage>

        <LayoutBox padding="scale-0">
          <LayoutCluster space="scale-G1">
            <WeekTab id="week1" title="Week 1" onClick={() => setNewWeekId("week1")} />
            <WeekTab id="week2" title="Week 2" onClick={() => setNewWeekId("week2")} />
          </LayoutCluster>
        </LayoutBox>
        {!isEmpty(weeklyEditActivities) && (
          <LayoutBox style={{ padding: "20px 0" }}>
            <AssociateSchedulingWeeklyTable
              associateId={associateId}
              isTemplateMode
              weekId={weekId}
              readOnly={false}
            />
          </LayoutBox>
        )}

        {!isEmpty(weeklyEditActivities) && moment(effectiveStartDate).isValid() ? (
          <>
            <LayoutBox padding="scale-0">
              Update Week 1 and Week 2 Schedule starting the week of
            </LayoutBox>
            <LayoutBox
              padding="scale-0"
              style={{ borderBottom: "1px solid #aaaaaa", display: "inline-block" }}
            >
              <Calendar
                isWeekly
                openDirection="up"
                isOutsideRange={day => day.isBefore(allowedStartDate) || day.weekday() !== 0}
                selectedDate={effectiveStartDate}
                selectDate={dt => setEffectiveStartDate(formatCalendarDateMoment(dt))}
              >
                <RightSideArrow>{rangeSelectorText}</RightSideArrow>
              </Calendar>
            </LayoutBox>

            <LayoutCluster
              space="scale-G1"
              style={{ justifyContent: "flex-end", alignItems: "center" }}
            >
              <LayoutBox padding="scale-0" style={warningStyles}>
                <img style={{ verticalAlign: "bottom" }} src={ExpiredIcon} alt="Warning" />*
                Warning: Changing the regular schedule will Override all changes to weekly schedules
                starting on {moment(effectiveStartDate).format("dddd, MMMM DD")}
              </LayoutBox>
              {timeRangesError && (
                <LayoutBox padding="scale-0" style={warningStyles}>
                  {timeRangesError}
                </LayoutBox>
              )}
              <Button width="100px" label="Cancel" onClick={() => handleCancel()} inverted />
              <Button
                disabled={timeRangesError || isLoading || !isRelocationValid}
                width="100px"
                label="Save"
                onClick={() => handleSave()}
              />
            </LayoutCluster>
          </>
        ) : (
          <LayoutBox padding="scale-0">Loading...</LayoutBox>
        )}
      </LayoutBox>

      {modalType && (
        <AssociateSchedulingModals
          modalType={modalType}
          newAssociateId={newAssociateId}
          newWeekId={newWeekId}
        />
      )}
    </LayoutBox>
  );
};

export const TemplateSchedulingContainer = connect(
  state => {
    const storeNumber = getStoreNumber(state);
    const effectiveStartDate = getEffectiveStartDate(state);
    const rangeStart = moment(effectiveStartDate).format("MM-DD-YYYY");
    const rangeEnd = moment(effectiveStartDate)
      .add(6, "d")
      .format("MM-DD-YYYY");
    const hasFutureAppointments = getTemplateHasFutureAppointments(state);
    return {
      associateId: getAssociateId(state),
      weekId: getTemplateWeekId(state),
      startWeekDate: getStartWeekDate(state),
      effectiveStartDate,
      rangeSelectorText: `${rangeStart} - ${rangeEnd}`,
      templateEffectiveStartDate: getTemplateEffectiveStartDate(state),
      weeklyEditActivities: getWeeklyEditActivities(state),
      associatesByGroup: getAssociatesByGroup(state),
      modalType: getSchedulesModalType(state),
      timeRangesError: getAllTimeRangesError(state, { storeNumber, omitTemp: true }),
      weeklyEditChanged: getWeeklyEditChanged(state),
      isRelocationValid: selectIsRelocationValid(state),
      isLoading: createLoadingSelector([schedulesActionTypes.SET_TEMPLATE_SCHEDULES])(state),
      // template view should be editable each Monday but only 4 week from the last Monday if there are futureAppointments
      allowedStartDate: hasFutureAppointments
        ? moment()
            .startOf("week")
            .add(4, "week")
        : moment()
            .startOf("week")
            .add(1, "week"),
    };
  },
  dispatch => ({
    loadAbsenceReasons: () => dispatch(loadAbsenceReasons()),
    loadAssociates: ({ isLimited }) => dispatch(loadAssociates({ isLimited })),
    loadAllSalons: () => dispatch(loadAllSalons()),
    setStartWeekDate: date => dispatch(setStartWeekDate(date)),
    setEffectiveStartDate: date => dispatch(setEffectiveStartDate(date)),
    setAssociateId: associateId => dispatch(setAssociateId(associateId)),
    loadTemplateSchedules: ({ associateId, fromDate }) =>
      dispatch(loadTemplateSchedules({ associateId, fromDate })),
    clearSchedules: () => dispatch(clearSchedules()),
    clearTemplateSchedules: () => dispatch(clearTemplateSchedules()),
    setTemplateSchedules: ({ associateId, weekId, effectiveStartDate, dayActivities }) =>
      dispatch(
        setTemplateSchedules({
          associateId,
          weekId,
          effectiveStartDate,
          dayActivities,
        }),
      ),
    showModal: modalType => dispatch(showSchedulesModal(modalType)),
    loadSalonHours: ({ beginDate, endDate }) => dispatch(loadSalonHours({ beginDate, endDate })),
  }),
)(withRouteProps(TemplateSchedulingComponent));
