export const GRID_TOKENS = {
  CELL_SPACING: {
    'cell-space-0': '0px',
    'cell-space-px': '1px',
    'cell-space-0.5': '0.125rem',
    'cell-space-1': '0.25rem',
    'cell-space-1.5': '0.375rem',
    'cell-space-2': '0.5rem',
    'cell-space-2.5': '0.625rem',
    'cell-space-3': '0.75rem',
    'cell-space-3.5': '0.875rem',
    'cell-space-4': '1rem',
    'cell-space-5': '1.25rem',
    'cell-space-6': '1.5rem',
    'cell-space-7': '1.75rem',
    'cell-space-8': '2rem',
    'cell-space-9': '2.25rem',
    'cell-space-10': '2.5rem',
    'cell-space-11': '2.75rem',
    'cell-space-12': '3rem',
    'cell-space-14': '3.5rem',
    'cell-space-16': '4rem',
    'cell-space-20': '5rem',
    'cell-space-24': '6rem',
    'cell-space-28': '7rem',
    'cell-space-32': '8rem',
    'cell-space-36': '9rem',
    'cell-space-40': '10rem',
    'cell-space-44': '11rem',
    'cell-space-48': '12rem',
    'cell-space-52': '13rem',
    'cell-space-56': '14rem',
    'cell-space-60': '15rem',
    'cell-space-64': '16rem',
    'cell-space-72': '18rem',
    'cell-space-80': '20rem',
    'cell-space-96': '24rem',
  },
  CELL_MIN_WIDTH: {
    'cell_min-width-0': '0px',
    'cell_min-width-px': '1px',
    'cell_min-width-0.5': '0.125rem',
    'cell_min-width-1': '0.25rem',
    'cell_min-width-1.5': '0.375rem',
    'cell_min-width-2': '0.5rem',
    'cell_min-width-2.5': '0.625rem',
    'cell_min-width-3': '0.75rem',
    'cell_min-width-3.5': '0.875rem',
    'cell_min-width-4': '1rem',
    'cell_min-width-5': '1.25rem',
    'cell_min-width-6': '1.5rem',
    'cell_min-width-7': '1.75rem',
    'cell_min-width-8': '2rem',
    'cell_min-width-9': '2.25rem',
    'cell_min-width-10': '2.5rem',
    'cell_min-width-11': '2.75rem',
    'cell_min-width-12': '3rem',
    'cell_min-width-14': '3.5rem',
    'cell_min-width-16': '4rem',
    'cell_min-width-20': '5rem',
    'cell_min-width-24': '6rem',
    'cell_min-width-28': '7rem',
    'cell_min-width-32': '8rem',
    'cell_min-width-36': '9rem',
    'cell_min-width-40': '10rem',
    'cell_min-width-44': '11rem',
    'cell_min-width-48': '12rem',
    'cell_min-width-52': '13rem',
    'cell_min-width-56': '14rem',
    'cell_min-width-60': '15rem',
    'cell_min-width-64': '16rem',
    'cell_min-width-72': '18rem',
    'cell_min-width-80': '20rem',
    'cell_min-width-96': '24rem',
  },
};
