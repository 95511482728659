import { set } from "lodash/fp";
import { systemActionTypes } from "web/setSystemType/actions/setSystemTypeActions";
import { checkForUndefined } from "core/utils/undefinedOrNullUtils/undefinedOrNullUtils";

const initialState = null;
/**
 * Reducer function for the booking flow state in the system.
 *
 * @memberof Reducer.persistent
 * @param {Object} state - The current state of the booking flow.
 * @param {Object} action - The action object that contains the type and payload for the action.
 * @returns {Object} - The new state after applying the action.
 */
export const systemBookingFlowReducer = (state = initialState, action) => {
  switch (action.type) {
    case systemActionTypes.SET_SYSTEM_BOOKING_FLOW:
      return checkForUndefined(action.systemBookingFlow, initialState);

    default:
      return state;
  }
};
