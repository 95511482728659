import { put, takeEvery, call, all } from "redux-saga/effects";
import {
  GET_TRAINING_PET_SERVICES,
  getTrainingPetServicesRequest,
  getTrainingPetServicesSuccess,
  getTrainingPetServicesFailure,
} from "./trainingPetServicesActions";
import { fetchTrainingPetServicesForStore } from "@/core/services/systemServicesBooking/petServicesEndPoints";
import { serviceTypeTraining } from "@/dux/trainingAssociates/trainingAssociatesConstants";
import { loadStoreServices } from "@/core/actionCreators/servicesSelectionActionCreator";
import normalizeArrayByProperty from "@/core/utils/normalizeUtils/normalizeArray";

export function* onGetTrainingPetServices({ storeNumber }) {
  try {
    yield put(getTrainingPetServicesRequest());
    const response = yield call(fetchTrainingPetServicesForStore, {
      storeNumber,
      params: {
        serviceType: serviceTypeTraining,
      },
    });
    const { petServices } = response.data.result;
    const petServiceIds = petServices.map(petService => petService.petServiceId);

    yield put(
      loadStoreServices({ petServices: normalizeArrayByProperty(petServices, "petServiceId") }),
    );
    yield put(getTrainingPetServicesSuccess(petServiceIds));
  } catch (error) {
    yield put(getTrainingPetServicesFailure(error));
  }
}

function* watchGetTrainingPetServices() {
  yield takeEvery(GET_TRAINING_PET_SERVICES, onGetTrainingPetServices);
}

export default function* trainingPetServicesSaga() {
  yield all([watchGetTrainingPetServices()]);
}
