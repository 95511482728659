import moment from "moment";
import { connect } from "react-redux";
import { isBookingDisabled } from "dux/eligibility/helperUtils/checkIsBookingDisabled";
import { setSelectDate } from "dux/selectedDate/selectedDateActions";
import getParamFromPath from "../../../core/utils/matchUtils/getParmFromPath";
import CommonButton from "../../common/commonButton";
import { paramName } from "../../../core/constants/urlParamConstants";
import { history } from "@/dux/utils/browser/browserHistory";
import { getSystemBookingFlow } from "../../setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../../setSystemType/constants/setSystemTypeConstants";
import getIsBGMWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getFTCOWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";

const mapStateToProps = state => {
  const customerKey = getParamFromPath(paramName.CUSTOMER_KEY);
  const isBgmOrFtcoHidden =
    getIsBGMWorkflowFeatureFlagHidden(state) && getFTCOWorkflowFeatureFlagHidden(state);

  return {
    isHidden: getSystemBookingFlow(state) !== systemName.SALON,
    componentId: "bookStandalonePetParentProfile-commonButton",
    label: isBgmOrFtcoHidden ? "Book Standalone Service" : "Book Other Salon Services",
    disabled: isBookingDisabled(state, customerKey),

    customerKey, // here only for pass through to mapDispatch
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchClickHandler: customerKey => {
    history.push(`/standalone/${customerKey}/select-service`);

    dispatch(setSelectDate(moment()));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  onClick: () => propsFromDispatch.dispatchClickHandler(propsFromState.customerKey),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CommonButton);
