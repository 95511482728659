//@ts-check
import React from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { ButtonGroup, Button } from "@petsmart-ui/sparky";

// Helpers
import { history } from "@/dux/utils/browser/browserHistory";

// Constants
import { routePaths } from "@/core/constants/routePaths";

/**
 *
 * @param {object} props
 * @param {string} props.componentId
 * @param {React.MouseEventHandler<Element>} props.handleOnClick
 * @returns
 */
export const NewException = props => {
  const { componentId, handleOnClick } = props;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <ButtonGroup align="right">
        <Button variant="primary" text="Add exception" onClick={handleOnClick} />
      </ButtonGroup>
    </LayoutBox>
  );
};

// CONTAINER(s) ----------------------------------------------------------------------------------------
export const NewExceptionButton = connect(
  state => {
    return {
      componentId: "NewExceptionButton",
    };
  },
  dispatch => {
    return {
      handleOnClick: () => {
        history.push(routePaths.MANAGE_OVERBOOKING_NEW_EXCEPTION_DATE);
      },
    };
  },
)(NewException);
