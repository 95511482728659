import { connect } from "react-redux";
import {
  getPendingFrequencyType,
  getPendingId,
  getPendingPetId,
} from "dux/frequency/frequencySelectors";
import { getCheckForSameDay } from "@/dux/frequency/dateRangeForFrequencySelectors";
import { medicationFrequencyTouched } from "dux/bookingPage/setMedFrequency/medicationFrequencyActions";
import { frequencyTypes } from "dux/frequency/FrequencyConstants";
import { foodFrequencyTouched } from "dux/bookingFeeding/setFoodFrequency/actions/foodFrequencyActions";
import {
  hideBookingModal,
  shouldProceedWithBooking,
} from "core/actionCreators/bookingActionCreators";
import BookingModals from "./bookingModals";
import { clearPendingFrequency } from "@/dux/frequency/actions/frequencyActions";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import { getCurrentPetServiceItem } from "@/core/selectors/checkInOutSelector";

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    pendingId: getPendingId(state),
    petId: getPendingPetId(state),
    frequencyType: getPendingFrequencyType(state),
    customerKey: getCurrentCustomerKey(state),
    petServiceItem: getCurrentPetServiceItem(state),

    // here for merge props
    isSameDay: getCheckForSameDay(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(hideBookingModal());
  },

  onShouldProceed: boolean => {
    dispatch(shouldProceedWithBooking(boolean));
  },

  clearFrequency: ({ pendingId, frequencyType, petId }) => {
    if (frequencyType === frequencyTypes.MEDICATION) {
      dispatch(medicationFrequencyTouched({ medicationId: pendingId, petId }));
    }
    if (frequencyType === frequencyTypes.FOOD) {
      dispatch(foodFrequencyTouched({ foodId: pendingId, petId }));
    }
    if (frequencyType === frequencyTypes.ADDON) {
      dispatch(clearPendingFrequency());
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  clearFrequency: () => {
    const { petId, frequencyType, pendingId, isSameDay } = propsFromState;
    if (!isSameDay) {
      propsFromDispatch.clearFrequency({ pendingId, frequencyType, petId });
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BookingModals);
