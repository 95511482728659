import jobRoleActionTypes from "core/actionTypes/jobRoleActionTypes";
import { checkForUndefined } from "core/utils/undefinedOrNullUtils/undefinedOrNullUtils";

const initialState = { isVisible: false };

/**
 * A reducer function that handles actions related to test job roles in the state.
 *
 * @memberof Reducer.persistent
 * @param {Object} state - The current state.
 * @param {Object} action - The action being dispatched.
 * @returns {Object} - The updated state.
 */
export const testJobRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobRoleActionTypes.SHOW_TEST_ROLES:
      return {
        ...state,
        isVisible: checkForUndefined(action.isVisible, initialState),
      };

    case jobRoleActionTypes.UPDATE_TEST_JOB_ROLES:
      return {
        ...state,
        AvailableTestJobRoles: checkForUndefined(action.jobRoles, initialState),
      };

    default:
      return state;
  }
};
