/**
 * Responsible for returning the Addons Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getAddonsState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the Addons object in the redux store
 *
 * @example createSelector([getAddonsState], addonsState => addonsState.showAddonFlyout;
 */
export default function getAddonsState(state) {
  return state.ui.web.addons;
}
