/**
 *  Room Suspensions Table Helpers
 */
import find from "lodash/find";
/**
 *
 * @param {Object[]} initialRoomSuspensionDetails
 * @param {Object[]} initialRoomSuspensionDetails.rooms
 * @param {Object[]} initialRoomSuspensionDetails.suspensionDetails
 * @param {Object[]} newSuspensionDetails
 * @param {Object[]} newSuspensionDetails.rooms
 * @param {Object[]} newSuspensionDetails.suspensionDetails
 * @returns {Object[]} - updated room suspensions data
 */
export const updateRoomSuspensionsData = (initialRoomSuspensionDetails, newSuspensionDetails) => {
  const roomId = Object.keys(initialRoomSuspensionDetails)[0];
  const roomNumber = Object.values(initialRoomSuspensionDetails)[0]?.rooms?.[0].roomNumber;
  const initialSuspensions = Object.values(initialRoomSuspensionDetails)[0]?.rooms?.[0]
    ?.suspensionDetails;

  const newSuspension = Object.values(newSuspensionDetails)[0]?.rooms?.[0]?.suspensionDetails[0];

  let updatedSuspensions;
  if (find(initialSuspensions, { suspensionId: newSuspension.suspensionId })) {
    updatedSuspensions = initialSuspensions.map(suspension =>
      suspension.suspensionId === newSuspension.suspensionId ? newSuspension : suspension,
    );
  } else {
    updatedSuspensions = [...initialSuspensions, newSuspension];
  }
  const updatedDetails = {
    ...initialRoomSuspensionDetails,
    [roomId]: {
      rooms: [
        {
          roomNumber,
          suspensionDetails: updatedSuspensions,
        },
      ],
    },
  };

  return updatedDetails;
};
