/**
 * Responsible for returning a reference to the modals Object in the redux store.
 *
 * @export getModalsState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the modals object in the redux store
 *
 * @example createSelector([getModalsState], modals => modals.petProfile.modalType);
 */
export default function getModalsState(state, ownProps) {
  return state.modals;
}
