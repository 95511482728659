import { connect } from "react-redux";
import { getSelectedAppointment, getSelectedAssociate } from "@/core/selectors/commonSelector";
import { getPetServiceItem, getEngagement } from "@/core/selectors/entitiesSelector";
import { setPetServiceItem } from "@/core/actionCreators/checkInOutActionCreator";
import { selectPet } from "@/core/actionCreators/bookingActionCreators";
import getIsTrainingWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/training/getIsTrainingWorkflowFeatureFlagHidden";
import {
  onSelectAssociate,
  onSelectAppointment,
} from "@/core/actionCreators/ui/web/commonActionCreators";
import { loadTrainingClassDetails } from "@/dux/manageTrainingClassPage/manageTrainingClassPageActions";
import ProfilePetCardComponent from "./ProfilePetCardComponent";
import { engagementTypes } from "@/web/setSystemType/constants/setSystemTypeConstants";
import { hideAppointmentQuickView, showAppointmentQuickView } from "@/dux/quickViews/quickViewActions";

const mapStateToProps = (state, ownProps) => {
  const petServiceItem = getPetServiceItem(state, { petServiceItemId: ownProps.appointmentID });
  const selectedAppointment = getSelectedAppointment(state);
  const selectedAssociate = getSelectedAssociate(state);
  const engagement = getEngagement(state, { engagementId: petServiceItem.engagement });

  return {
    isEngagementTypeIconHidden: !getIsTrainingWorkflowFeatureFlagHidden(state),
    engagement,
    isSelectedAppointment: selectedAppointment
      ? ownProps.appointmentID === selectedAppointment
      : petServiceItem.associate === selectedAssociate,
    associateId: petServiceItem.associate,
    customerKey: petServiceItem.customer,
    itineraryId: petServiceItem.itinerary,
    selectedPet: petServiceItem.pet,
    fromPetParent: true,
    isDisabled: engagement?.engagementType === engagementTypes.TRAINING && petServiceItem?.status === "Canceled",
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchOnSelectAssociate: ({ associateId }) => {
    dispatch(onSelectAssociate({ associateId }));
  },
  dispatchOnSelectAppointment: ({ appointmentId }) => {
    dispatch(onSelectAppointment({ appointmentId }));
  },
  dispatchShowQuickView: ({ appointmentID, xLoc, yLoc }) => {
    dispatch(
      showAppointmentQuickView({
        xLoc,
        yLoc,
        petServiceItemId: appointmentID,
      }),
    );
  },
  dispatchHideQuickView: () => {
    dispatch(hideAppointmentQuickView());
  },

  setPetServiceItem: petServiceItem => dispatch(setPetServiceItem({ petServiceItem })),
  selectPet: petId => dispatch(selectPet(petId)),
  setCustomerKeyAndItineraryId: (customerKey, itineraryId) => {
    dispatch(loadTrainingClassDetails({ customerKey, itineraryId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePetCardComponent);
