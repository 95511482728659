import { connect } from "react-redux";
import { ToggleButtonListWithSelectAll } from "dux/filteringButtons/ToggleButtonListWithSelectAll";
import getIsTrainingWorkflowFeatureFlagHidden
  from "web/enableDisableWorkflowFeatureFlag/selectors/training/getIsTrainingWorkflowFeatureFlagHidden";
import {
  getAssociatesFilterButtonData,
} from "dux/trainingAssociates/trainingAssociatesSelectors";
import { setFilteringByTrainer } from "dux/filteringButtons/dux";
import { getSelectedTrainingPetService } from "dux/scheduleTrainingPage/scheduleTrainingPageSelectors";

const mapStateToProps = (state) => {
  const data= getAssociatesFilterButtonData(state);
  return {
    componentId: "ToggleClassByTrainerContainer__toggleButtonListWithSelectAll",
    isHidden: getIsTrainingWorkflowFeatureFlagHidden(state) || !getSelectedTrainingPetService(state),
    key: data,  // force a component did update via the key field
    data,
  }
};

const mapDispatchToProps = dispatch => {

  return {
    setGlobalState: (payload) => {
      // need to convert payload back to an array
      dispatch(setFilteringByTrainer(Object.values(payload)))
    }
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToggleButtonListWithSelectAll);
