/**
 * Increments a target number by a set amount
 *
 * @param {number} num - Number to increment
 * @param {number} amount - Interval to increment by
 * @returns {number} New target number
 */
export function incrementBy(num, amount) {
  return num + amount;
}

/**
 * Decrements a target number by a set amount
 *
 * @param {number} num - Number to decrement
 * @param {number} amount - Interval to decrement by
 * @returns {number} New target number
 */
export function decrementBy(num, amount) {
  return num - amount;
}
