/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const petAlertsActionsTypes = {
  UPDATE_HOTEL_PET_ALERTS: "UPDATE_HOTEL_PET_ALERTS",
  UPDATE_HOTEL_PET_ALERTS_FAILURE: "UPDATE_HOTEL_PET_ALERTS_FAILURE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const updateHotelPetAlerts = ({ alerts }) => ({
  type: petAlertsActionsTypes.UPDATE_HOTEL_PET_ALERTS,
  alerts,
});

export const updateHotelPetAlertsFailure = ({ error }) => ({
  type: petAlertsActionsTypes.UPDATE_HOTEL_PET_ALERTS_FAILURE,
  error,
});
