import React from "react";
import { Routes, Route } from "react-router-dom";
import { PetsHotelAdminNavBar } from "../petsHotelAdminNavBar/PetsHotelAdminNavBar";
import { Icon } from "@prism/psm-ui-components";
import { PetsHotelOverBooking } from "../../petsHotelAdminManager/PetsHotelOverBookingPage";
import { connect } from "react-redux";
import { routePathPartials } from "@/core/constants/routePaths";
import getIsHotelAdminWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/hotelAdmin/getIsHotelAdminWorkflowFeatureFlagHidden";
import { LayoutGrid } from "@/layout/grid/Grid";
import { LayoutBox } from "@/layout/box/Box";
import { PetsHotelAdminHeader } from "@/dux/petsHotelAdminHeaderContainer/PetsHotelAdminHeaderContainer";

/**
 * React view component that formats the page header, left nav bar, and page content for the hotel admin page
 * @memberOf Views.HotelAdmin
 * @function
 * @name HotelAdmin
 * @param {Object} props - props passed into the view component
 * @param {String} props.componentId
 * @param {Boolean} props.isHidden
 * @param {Object[]} props.navItems
 * @returns {JSX.Element}
 * @example <HotelAdmin />
 */
export const HotelAdmin = ({ navItems, componentId, isHidden }) => {
  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <PetsHotelAdminHeader />

      <LayoutGrid
        style={{
          gridTemplateAreas: '"NavBar MainContent"',
          gridTemplateColumns: "max-content 1fr",
          width: "100%",
          height: "100%",
        }}
      >
        <PetsHotelAdminNavBar list={navItems} />

        {/* Right Panel Content */}
        <LayoutBox
          padding="scale-G2"
          style={{ gridArea: "MainContent", overflow: "auto", boxSizing: "border-box" }}
        >
          <Routes>
            {navItems?.map(item => (
              <Route key={item?.path} path={`${item?.path}/*`} element={item?.component} />
            ))}
          </Routes>
        </LayoutBox>
      </LayoutGrid>
    </LayoutBox>
  );
};

/**
 * Redux Connect function for the HotelAdmin component
 * @see {@link Views.HotelAdmin.HotelAdmin}
 * @summary located on /hotel-management page
 * @memberOf Views.HotelAdmin
 * @function
 * @name PetsHotelAdminLayout
 * @returns {JSX.Element|null}
 * @example <PetsHotelAdminLayout />
 */
export const PetsHotelAdminLayout = connect(state => {
  const hotelAdminIsHidden = getIsHotelAdminWorkflowFeatureFlagHidden(state);
  return {
    navItems: [
      {
        icon: (
          <Icon
            variant="psm-icon-ph"
            useColorForFill
            color="psm-icon-color-black"
            size="psm-icon-size-48"
            style={{ backgroundColor: "#E2F2FF", borderRadius: "4px" }}
          />
        ),
        title: "Overbooking",
        component: <PetsHotelOverBooking />,
        path: routePathPartials.HOTEL_OVERBOOKING,
      },
    ],
    componentId: "PetsHotelAdminLayout",
    isHidden: hotelAdminIsHidden,
  };
})(HotelAdmin);
