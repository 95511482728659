import React from "react";
import styled from "styled-components";
import getUnknownPetImage from "core/utils/assetUtils/unknownPetImage";
import { color } from "web/common/styles/theme";

const compLineHeight = "1.3rem";

const RootComponent = styled.section`
  display: flex;
  flex-direction: row;
  border: 1px solid ${color.whiteGrey};
  padding: 1vh 1vh 1vh 0;
  cursor: pointer;
`;

const PetImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 3% 0 3%;
`;

const PetImg = styled.img`
  width: 57px;
  height: 57px;
`;

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
`;

const Item = styled.div`
  line-height: ${compLineHeight};
  margin-right: 5px;
`;

const PetName = styled(Item)`
  font-weight: bold;
`;

export default function BasePetCardComponent(props) {
  const { isHidden, componentID, cardInfo, onClick } = props;
  const { petName, petAge, petBreed, petService, pet, timeRange, date, associateName } = cardInfo;

  if (!isHidden) {
    return (
      <RootComponent data-testid="basePetCardComponent" id={componentID} onClick={onClick}>
        <PetImageWrapper>
          <PetImg src={getUnknownPetImage(pet)} />
        </PetImageWrapper>

        <CardContentWrapper>
          <Row>
            <PetName>{`${petName}`}</PetName>
            <Item>{`${petAge}, `}</Item>
            <Item>{petBreed}</Item>
          </Row>

          <Row>
            <Item>{petService}</Item>
          </Row>

          <Row>
            <Item>{timeRange}</Item>
            <Item>{date}</Item>
          </Row>

          <Row>
            <Item>{associateName}</Item>
          </Row>
        </CardContentWrapper>
      </RootComponent>
    );
  }

  return null;
}
