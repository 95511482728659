import React from "react";
import moment from "moment";
import styled from "styled-components";
import { color } from "web/common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import ExpiredIcon from "../../../../assets/icons/expired.svg";
import isExpiredBeforeTargetDate from "../../../../core/utils/dateUtils/isExpiredBeforeTargetDate";

const SectionWrapper = styled.section`
  display: flex;
  justify-content: center;
`;

const Notification = styled.section`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

const ExpirationDate = styled.div`
  color: ${color.errorRed};
  font-size: ${fontSizes.regular};
  text-align: center;
  padding: 10px;
`;

const Icon = styled.img`
  height: 1em;
  margin: auto;
`;

const Text = styled.div`
  font-size: ${fontSizes.medium};
  text-align: center;
`;

function getNotification(expirationDate, endDate) {
  const targetDate = moment(endDate).startOf("day");
  if (isExpiredBeforeTargetDate(expirationDate, targetDate)) {
    return (
      <Notification>
        <ExpirationDate>
          <Icon src={ExpiredIcon} /> Rabies {moment(expirationDate).format("MM/DD/YY")}
        </ExpirationDate>
        <Text>The Rabies vaccination has expired.</Text>
        <Text>Updated Rabies verification is needed for check-in.</Text>
      </Notification>
    );
  }

  return null;
}

function VaccinationCheckWarning(props) {
  const { petServiceItem, verification } = props;
  const petId = petServiceItem.pet;
  return (
    <SectionWrapper>
      {verification[petId] && verification[petId].expirationDate ? (
        getNotification(verification[petId].expirationDate, petServiceItem.endDateTime)
      ) : (
        <Notification>
          <ExpirationDate>
            <Icon src={ExpiredIcon} /> Rabies
          </ExpirationDate>
          <Text>The Rabies vaccination is missing.</Text>
          <Text>Updated Rabies verification is needed for check-in.</Text>
        </Notification>
      )}
    </SectionWrapper>
  );
}

export default VaccinationCheckWarning;
