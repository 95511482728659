import { connect } from "react-redux";
import {
  getIsBulkPackageOfferingSelected,
  getBulkPackageOfferingsGroupRow,
} from "../bulkPackageOfferingsSelectors";
import { setSelectedBulkPackageOffering } from "../bulkPackageOfferingsActions";
import {
  getBulkPackageAddOns,
  clearSelectedBulkPackageAddOn,
} from "../addOns/bulkPackageAddOnsActions";
import Checkbox from "../../../../web/common/Checkbox";
import { getBulkPackageEnhancedServices } from "../enhancedServices/bulkPackageEnhancedServiceActions";
import { getSelectedPetDetails } from "@/core/selectors/bookingUISelectors";

const mapStateToProps = (state, { petServiceId, productBundleConfigId }) => {
  const { hasAddOns } = getBulkPackageOfferingsGroupRow(state, {
    petServiceId,
    productBundleConfigId,
  });
  return {
    componentId: `bulk-package-offerings-group-row-checkbox-${petServiceId}-${productBundleConfigId}`,
    name: productBundleConfigId,
    value: productBundleConfigId,
    petServiceId,
    checked: getIsBulkPackageOfferingSelected(state, {
      petServiceId,
      productBundleConfigId,
    }),

    // Used to determine if we should dispatch
    // add-ons API call
    hasAddOns,
    isDogSpeciesID: getSelectedPetDetails(state)?.speciesId === 1
  };
};

const mapDispatchToProps = dispatch => ({
  clearAddOnSelected: () => dispatch(clearSelectedBulkPackageAddOn()),

  dispatchClick: (petServiceId, bundleConfigId) =>
    dispatch(setSelectedBulkPackageOffering(petServiceId, bundleConfigId)),

  getAddOns: (petServiceId, bundleConfigId) =>
    dispatch(getBulkPackageAddOns(petServiceId, bundleConfigId)),
  getEnhancedServices: (petServiceId, bundleConfigId) =>
    dispatch(getBulkPackageEnhancedServices(petServiceId, bundleConfigId)),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  onChange: () => {
    propsFromDispatch.dispatchClick(propsFromState.petServiceId, propsFromState.value);

    // Only dispatch add-ons API call for BGM and not FTCO
    if (propsFromState.hasAddOns) {
      propsFromDispatch.getAddOns(propsFromState.petServiceId, propsFromState.value);
    }

    if (propsFromState.hasAddOns && propsFromState.isDogSpeciesID) {
      propsFromDispatch.getEnhancedServices(propsFromState.petServiceId, propsFromState.value);
    }

    propsFromDispatch.clearAddOnSelected();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Checkbox);
