import { ASSOCIATE_NAME_VALUE_TYPE } from "core/constants/dropdownOptions";

export const searchFieldTypeConstants = {
  PHONE_NUMBER: "phoneNumber",
  EMAIL: "email",
  NAME: "name",
  STORE: "store",
  ZIP_Code: "zipCode",
  ADDRESS: "address",
  CITY: "cityState",
  ASSOCIATE_ID: "associateId",
  ASSOCIATE_NAME: ASSOCIATE_NAME_VALUE_TYPE,
};

/**
 * Checks if the dropdown selection is phoneNumber, email or name.
 * @param searchFieldType - Value the Search Dropdown is selected e.g. phoneNumber, Email etc.
 * @return {boolean}
 */
export const categoryCustomer = ({ searchFieldType }) => {
  return (
    searchFieldType === searchFieldTypeConstants.PHONE_NUMBER ||
    searchFieldType === searchFieldTypeConstants.EMAIL ||
    searchFieldType === searchFieldTypeConstants.NAME
  );
};

/**
 * Checks if the dropdown selection is store number
 * @param searchFieldType - Value the Search Dropdown is selected e.g. store, postalCode, address or city.
 * @return {boolean}
 */
export const categoryStore = ({ searchFieldType }) => {
  return searchFieldType === searchFieldTypeConstants.STORE;
};

/**
 * Checks if the dropdown selection is a location such as zipCode, address or cityState.
 * @param searchFieldType
 * @return {boolean}
 */
export const isCategoryStoresByLocation = ({ searchFieldType }) => {
  return (
    searchFieldType === searchFieldTypeConstants.ZIP_Code ||
    searchFieldType === searchFieldTypeConstants.ADDRESS ||
    searchFieldType === searchFieldTypeConstants.CITY
  );
};
/**
 * Checks if the dropdown selection is an associate profile related search, such as associate name or Id.
 * @param searchFieldType
 * @return {boolean}
 */
export const isCategoryAssociateSearch = ({ searchFieldType }) => {
  return (
    searchFieldType === searchFieldTypeConstants.ASSOCIATE_NAME ||
    searchFieldType === searchFieldTypeConstants.ASSOCIATE_ID
  );
};
