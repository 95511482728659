import { put, call, select } from "redux-saga/effects";
import { eligibilityTypes } from "dux/eligibility/constants/eligibilityConstants";
import { getIsCustomerActive } from "core/selectors/entitiesSelector";
import {
  getCustomerEligibilityFailure,
  getCustomerEligibilityRequest,
  getCustomerEligibilitySuccess,
} from "dux/eligibility/actions/eligibilityCustomerActions";
import { waitForRequests } from "core/sagas/utilsSaga";
import petsActionTypes from "core/actionTypes/petsActionTypes";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import { fetchCustomerEligibility } from "core/services/systemServicesBooking/customerEligibilityEndPoints";
import mergeEnginesResult from "dux/eligibility/helperUtils/mergeResults";
import { onGetReasonFlow } from "dux/eligibility/sagas/eligibilityPetCustomerReasonsFow";

// eslint-disable-next-line consistent-return
export function* customerEligibilityFlow({
  customerKey,
  eligibilityType,
  eligibilitySubjectType,
  startDate,
}) {
  try {
    const isCustomerActive = yield select(getIsCustomerActive, { customerKey }); // active or inactive

    if (isCustomerActive) {
      yield put(getCustomerEligibilityRequest());
      yield waitForRequests([petsActionTypes.UPDATE_PET, customersActionTypes.UPDATE_CUSTOMER]);

      let primaryEngineResult;

      if (eligibilityType !== eligibilityTypes.CHECK_OUT) {
        const response = yield call(fetchCustomerEligibility, { customerKey, startDate });

        primaryEngineResult = response.data;
      }

      const secondaryEngineResult = yield call(onGetReasonFlow, {
        id: customerKey,
        eligibilityType,
        eligibilitySubjectType,
      });

      const mergedResults = mergeEnginesResult({ primaryEngineResult, secondaryEngineResult });

      if (mergedResults) {
        const customerEligibility = {
          ...mergedResults,
          customerKey: Number(customerKey),
        };

        yield put(getCustomerEligibilitySuccess());

        return customerEligibility;
      }
    }

    yield put(getCustomerEligibilitySuccess());
  } catch (error) {
    yield put(getCustomerEligibilityFailure({ error }));
  }
}
