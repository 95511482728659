import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { routePaths } from "../../../../core/constants/routePaths";
import CommonButton from "../../../common/commonButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 1em;
`;

const Text = styled.div`
  margin: 3em 0;
  max-width: 350px;
  font-size: 16px;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 0 0 1em 0;
`;

const LockUserModal = ({ lockUser, hideModal }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Text>Are you sure you want to lock out of your profile?</Text>
      <ButtonContainer>
        <CommonButton
          width="200px"
          label="Lock Profile"
          onClick={() => {
            lockUser();
            navigate(routePaths.SELECT_USER);
            hideModal();
          }}
        />
      </ButtonContainer>
    </Container>
  );
};

export default LockUserModal;
