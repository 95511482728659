import moment from "moment";
import commonActionTypes from "../../../../core/actionTypes/ui/web/commonActionTypes";

export const onSelectAppointment = ({ appointmentId }) => ({
  type: commonActionTypes.SELECT_APPOINTMENT,
  appointmentId
});

export const onSelectAssociate = ({ associateId }) => ({
  type: commonActionTypes.SELECT_ASSOCIATE,
  associateId
});

export const selectAssociateWeekly = ({ associateId }) => ({
  type: commonActionTypes.SELECT_ASSOCIATE_WEEKLY,
  associateId
});

export const onAssociateClick = ({ associateId }) => ({
  type: commonActionTypes.ASSOCIATE_CLICK,
  associateId
});

export const setWeekNumber = ({ weekNumber }) => ({
  type: commonActionTypes.WEEK_NUMBER,
  weekNumber
});

export const setSelectDate = selectedDate => ({
  type: commonActionTypes.SELECT_DATE,
  selectedDate: moment(selectedDate).format("YYYY-MM-DD")
});

export const resetSelectedDate = () => ({
  type: commonActionTypes.RESET_SELECTED_DATE
});

export const setSelectTime = selectedTime => ({
  type: commonActionTypes.SELECT_TIME,
  selectedTime
});

export const resetSelectedTime = () => ({
  type: commonActionTypes.RESET_SELECTED_TIME
});

export const setRebookErrorMessages = ({ errorMessages }) => ({
  type: commonActionTypes.SET_REBOOK_ERROR_MESSAGES,
  errorMessages
});

export const setWeeklyGanttDate = ({ date }) => ({
  type: commonActionTypes.SET_WEEKLY_GANTT_DATE,
  date
});

export const clearWeeklyGanttDate = () => ({
  type: commonActionTypes.CLEAR_WEEKLY_GANTT_DATE
});

export const clearRebookErrorMessages = () => ({
  type: commonActionTypes.CLEAR_REBOOK_ERROR_MESSAGES
});

export default {
  onSelectAppointment,
  onSelectAssociate,
  onAssociateClick,
  selectAssociateWeekly,
  setSelectDate,
  resetSelectedDate,
  setRebookErrorMessages,
  clearRebookErrorMessages,
  setWeeklyGanttDate,
  clearWeeklyGanttDate
};
