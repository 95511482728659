// @ts-nocheck
import * as React from 'react';
import { psx } from '../../utils/clsx';
import { Layout, ClusterProps } from '../../components/Layout';
import { IdObj } from '../../components/List';

import './styles.css';
import Button from '../../components/Button';
import { Form } from '../../components/Form/form';
import Text from '../../components/Text';

export interface QuantityModifierProps extends ClusterProps {
  /** An amount to display within the input */
  quantity: number;

  /** A function that is called and passes quantity when called */
  updateQuantity: (object: number) => () => any;
}

export interface quantityModifierIdObj extends IdObj {}

export const QuantityModifier = ({
  componentId,
  isHidden = false,
  className,
  quantity,
  updateQuantity,
  disableQuantity,
  ...props
}: QuantityModifierProps) => {
  const classNames = psx('psm', 'psm-qty-modifier', className);

  if (isHidden) return null;

  return (
    <Layout.Cluster className={classNames} {...props}>
      {/* Minus Button */}
      <Button
        variant="link"
        disabled={disableQuantity || quantity <= 0}
        onClick={() => {
          if (quantity > 0) {
            updateQuantity(quantity - 1);
          }
        }}
      >
        <Text size="text-size-lg" bold>
          -
        </Text>
      </Button>

      {/* Quantity Input Box */}
      <Form.Field.Text
        className="psm-qty-modifier__input"
        id={`${componentId}__quantity`}
        type="number"
        modifier="quantity"
        value={quantity}
        disabled={disableQuantity}
        onChange={(e) => {
          const newQuantity = Number(e.target.value);

          if (Number.isInteger(newQuantity)) updateQuantity(newQuantity);
        }}
      />

      {/* Addition Button */}
      <Button variant="link" onClick={() => updateQuantity(quantity + 1)} disabled={disableQuantity}>
        <Text size="text-size-lg" bold>
          +
        </Text>
      </Button>
    </Layout.Cluster>
  );
};

export default QuantityModifier;
