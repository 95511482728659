import React from "react";
import BulkPackageOfferingsFreeUnitsExpirationContainer from "dux/bgm/bulkPackageOfferings/offerings/BulkPackageOfferingsFreeUnitsExpirationContainer";
import BulkPackageOfferingsBaseUnitsExpirationContainer from "dux/bgm/bulkPackageOfferings/offerings/BulkPackageOfferingsBaseUnitsExpirationContainer";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutBox } from "@/layout/box/Box";

const BulkPackageOfferingsExpirationComponent = ({ isHidden, componentID }) => {
  if (isHidden) {
    return null;
  }
  return (
    <LayoutBox id={componentID} padding="scale-0">
      <LayoutStack space="scale-0">
        <BulkPackageOfferingsFreeUnitsExpirationContainer />
        <BulkPackageOfferingsBaseUnitsExpirationContainer />
      </LayoutStack>
    </LayoutBox>
  );
};

export default BulkPackageOfferingsExpirationComponent;
