// cc: QuickViewAppointment#4;Get State;Get QuickView State

/**
 * Responsible for returning the quickView Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getQuickViewState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the quickView object in the redux store
 *
 * @example createSelector([getQuickViewState], quickViewState => quickViewState.appointmentQuickView.quickViewType);
 */
export default function getQuickViewState(state) {
  return state.quickViews;
}
