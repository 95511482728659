/*
  Note this function is not necessary, as it is just a wrapper for the Array.prototype.flat() method
  An issue was created to remove this file and replace all usages with Array.prototype.flat()
  issue #1665 - https://github.ssg.petsmart.com/Stores-and-Services-Engineering/petm-services-prism-ui-react/issues/1665
 */

/**
 * Flattens a nested array up to a maximum depth of 3 levels,
 * Example: [1, [2, 3], [4, [5, 6]]]
 * @param {Array} arr - The array to flatten.
 * @returns {*[]} - A flattened array.
 *
 * @example
 *
 * const flatList = flattenArray(array);
 *
 */
export default function flattenArray(arr) {
  // Use the `flat` method of the input array to flatten it up to a maximum depth of 3 levels.
  return [...new Set(arr.flat(3))];
}
