import React from "react";
import { connect } from "react-redux";
import { selectIsReservationDisabled } from "@/dux/hotelItinerary/hotelItinerarySelectors";
import Section from "../common/Section";
import MedicationAddButtonContainer from "./buttons/MedicationAddButtonContainer";
import { getMedicationIds } from "../medication/medicationsSelector";
import MedicationBookingAddButtonContainer from "./buttons/MedicationBookingAddButtonContainer";

const mapStateToProps = (state, ownProps) => {
  const { petId, locationType, topLine = true, title = "Medication Details" } = ownProps;
  const medicationIds = getMedicationIds(state, { petId });
  const isReservationDisabled = selectIsReservationDisabled(state);

  return {
    title,
    addButton: (
      <>
        <MedicationAddButtonContainer petId={petId} buttonDisabled={isReservationDisabled} />
        <MedicationBookingAddButtonContainer petId={petId} locationType={locationType} />
      </>
    ),
    topLine,
    padding: medicationIds.length > 0 ? "2.5rem 0 0 0" : "2.5rem 0 3rem 0",
    titleMarginBottom: medicationIds.length > 0 && "none",
    paddingTop: "10px",
  };
};

export default connect(mapStateToProps)(Section);
