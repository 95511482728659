import modalInitState from "./_modalInitState";
import getUpdatedModalState from "./helpers/getUpdatedModalState";
import cancellationsActionTypes from "../../actionTypes/cancellationsActionTypes";

const initialState = modalInitState();

/**
 * Reducer for canceling an appointment Modals
 *
 * @export cancelAppointmentModalsReducer
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {string}
 */
export default function cancelAppointmentModalsReducer(state = initialState, action) {
  return getUpdatedModalState(
    state,
    initialState,
    cancellationsActionTypes.SHOW_CANCEL_APPOINTMENT_MODAL,
    cancellationsActionTypes.HIDE_CANCEL_APPOINTMENT_MODAL,
    action
  );
}
