import authActionTypes from "core/actionTypes/authActionTypes";

const initialState = false;
/**
 * Reducer for handling the state of redirectToForgotPin.
 *
 * @memberof Reducer.persistent
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @returns {Object} The new state.
 */
export const redirectToForgotPinReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.SET_REDIRECT_TO_FORGOT_PIN:
      return true;

    case authActionTypes.CLEAR_REDIRECT_TO_FORGOT_PIN:
      return initialState;

    default:
      return state;
  }
};