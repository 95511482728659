// selectors for notification center
import { createSelector } from "reselect";

export const selectEventNotifications = (state) => state?.storeEventNotifications?.notifications;

// get array of notification messages
export const selectNotificationMessage = createSelector([selectEventNotifications], (notifications) => {
  return notifications?.map(notif => notif.plainTextMessage);
});

// get an array of arrays of message tags
export const selectEventNotificationsMessageTags = createSelector([selectEventNotifications], (notifications) =>
  notifications?.map((notif) => notif.messageTags),
);

// selector to determine if the store notifications (blue) banner under the header should be visible
export const selectStoreNotificationBannerVisibility = createSelector([selectEventNotifications], (notifications) => notifications?.length === 0);

export const getIsCollapsed = (state) => state.storeEventNotifications?.isCollapsed;

// get an array of display location types
export const selectNotificationDisplayLocation = createSelector([selectEventNotifications], (notifications) =>
  notifications?.map((notification) => notification.displayLocations),
);
