import { connect } from "react-redux";
import { getIsPetCardShowing } from "dux/petCartCard/selectors/petCartCardSelectors";
import { DELETE_CART, deleteCart } from "dux/servicesCart/servicesCartActions";
import { selectServicesCart } from "dux/servicesCart/servicesCartSelectors";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import { createLoadingSelector } from "core/selectors/utils";
import { showConfirmationModal } from "../../../core/actionCreators/confirmationModalActionCreators";
import { color } from "../../../web/common/styles/theme";
import ClickableText from "../../../web/common/ClickableText";

const mapStateToProps = (state, ownProps) => {
  const { petId } = ownProps;
  return {
    cartId: selectServicesCart(state)?.servicesCartId,
    customerKey: getCurrentCustomerKey(state),
    isVisible: getIsPetCardShowing(state, { petId }),
    label: "Cancel",
    disabled: createLoadingSelector([DELETE_CART])(state),
    styleObj: { color: color.errorRed },
  };
};

const mapDispatchToProps = dispatch => ({
  removeFromCart: ({ customerKey, cartId }) => dispatch(deleteCart({ customerKey, cartId })),
  showConfirmationModal: args => dispatch(showConfirmationModal(args)),
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,

  onClick: () => {
    const { customerKey, cartId } = propsFromState;
    propsFromDispatch.showConfirmationModal({
      header: `Removing from Cart`,
      content: `Are you sure want to cancel the appointment?`,
      confirmText: "Remove from Cart",
      confirm: () => propsFromDispatch.removeFromCart({ customerKey, cartId }),
      cancelText: "Go back",
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ClickableText);
