import React from "react";
import styled, { css } from "styled-components";
import { device } from "web/common/styles/responsive/devices";
import { color } from "./styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import NonEditable from "./NonEditable";
import TextInputTheme from "./styles/inputs/textInputTheme";

const StyledInput = styled.input`
  ${props =>
    css`
      ${TextInputTheme(props)};
    `};

  @media ${device.mobileS} {
    width: 100%;
  }

  @media ${device.prismDesktop} {
    width: auto;
  }

  /* can't place sudo selectors in object literal so could not be included in TextInputTheme and
  * must be placed here
  */
  &:focus {
    outline: none;
    border-bottom-color: ${props => (props.error ? color.errorRed : color.kalEl)};
    color: ${props => (props.error ? color.errorRed : color.kalEl)};
  }

  &::placeholder {
    font-size: ${fontSizes.small};
  }
`;

const InputContaner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Symbol = styled.div`
  /* position: absolute; */
  /* top: 1px; */
  display: flex;
  flex-direction: row;
`;

const Input = props => {
  return props.nonEditable ? (
    <NonEditable {...props}>{props.value}</NonEditable>
  ) : (
    <InputContaner>
      {props.lineSymbol && <Symbol>{props.lineSymbol}</Symbol>}
      <StyledInput {...props} />
    </InputContaner>
  );
};

export default Input;
