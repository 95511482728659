import { connect } from "react-redux";
import { getCurrentCustomerKey } from "../../../core/selectors/persistent/customer/customerSelectors";
import { getBundleHistoryList } from "./bundleHistorySelectors";
import BundleHistoryComponent from "../../_components/bgm/bundleHistory/BundleHistoryComponent";

const mapStateToProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  return {
    componentID: "bundle-history",
    bundleHistoryList: getBundleHistoryList(state, { customerKey }),
  };
};

export default connect(mapStateToProps)(BundleHistoryComponent);
