import { GET_CANCELLATION_REASONS_SUCCESS } from "dux/cancellationreasons/cancellationReasonsActions";

/**
 * @typedef {Object} CancellationReason
 * @property {string} value - Value provided for the selection element ff
 * @property {string} name - Name provided for the selection element
 */

/**
 * @typedef  {CancellationReason[]} CancellationReasons
 */

/** @typedef  {String} Type */

/** @typedef  {type: Type, cancellationReasons: CancellationReasons} Action */

const initialState = [];

/**
 *
 * @param {*[]}state
 * @param {Action} action
 * @return {*[]|CancellationReasons}
 */
const cancellationReasonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CANCELLATION_REASONS_SUCCESS:
      return action.cancellationReasons;

    default:
      return state;
  }
};

export default cancellationReasonsReducer;
