import { createSelector } from "reselect";
import { selectCurrentHotelPetService } from "dux/hotelEngagements/hotelEngagementSelectors";

/**
 *  Selector to get the petServiceName from the current engagement
 *
 *  @memberOf Selectors.HotelEngagements
 *  @function
 *  @name getHotelPetServiceName
 *  @param {Object} state - redux state
 *  @param {Object} props
 *  @param {string} props.petId - param to specify pet
 *  @returns {string} the petServiceName
 *  @example
 *
 *  getHotelPetServiceName(state, { petId })
 */
export const getHotelPetServiceName = createSelector([selectCurrentHotelPetService], petService => {
  return petService?.petServiceName ?? "";
});
