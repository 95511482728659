import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import { fetchStoreCapabilities } from "core/services/associateWeb/storeCapabilitiesEndpoint";
import {
  storeCapabilitiesActionTypes,
  getStoreCapabilitiesFailure,
  getStoreCapabilitiesRequest,
  getStoreCapabilitiesSuccess,
} from "./EnhancedServicesListActions";

// eslint-disable-next-line consistent-return
function* onGetStoreCapabilities({ storeNumber }) {
  try {
    yield put(getStoreCapabilitiesRequest());

    const response = yield call(fetchStoreCapabilities, storeNumber);

    const result = response?.data?.result;

    yield put(getStoreCapabilitiesSuccess({ result }));
  } catch (error) {
    yield put(getStoreCapabilitiesFailure({ error }));
  }
}

function* watchOnGetStoreCapabilities() {
  yield takeEvery(storeCapabilitiesActionTypes.GET_STORE_CAPABILITIES, onGetStoreCapabilities);
}

export default function* storeCapabilitiesSaga() {
  yield all([fork(watchOnGetStoreCapabilities)]);
}
