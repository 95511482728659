import {
  SET_HOTEL_BOOKING_FLOW_DATE_TIME,
  SET_HOTEL_BOOKING_FLOW_PETS,
  SET_HOTEL_BOOKING_FLOW_ROOM,
  REMOVE_HOTEL_BOOKING_FLOW_ROOM,
  SET_HOTEL_BOOKING_FLOW_PET_SERVICE,
  DELETE_HOTEL_BOOKING_FLOW_PET_SERVICE,
  SET_HOTEL_BOOKING_FLOW_PET_ROOM_NUMBER,
  SET_HOTEL_BOOKING_CURRENT_SERVICE_SELECTION_ROOM,
  SET_HOTEL_BOOKING_PENDING_CHANGES,
  CLEAR_HOTEL_BOOKING_PENDING_CHANGES,
  APPLY_HOTEL_BOOKING_PENDING_CHANGES,
  SET_HOTEL_BOOKING_OPEN_STEP,
  SET_HOTEL_BOOKING_FURTHEST_STEP,
  CLEAR_HOTEL_BOOKING,
  SAVE_HOTEL_BOOKING_HOST_PETS,
} from "web/features/hotelBookingFlow/hotelBookingFlowActions";
import omit from "lodash/fp/omit";

const initialState = {
  openStep: 0,
  furthestStep: 0,
};

export default function hotelBookingFlowReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HOTEL_BOOKING_FLOW_PETS:
      return { ...state, petList: action.pets?.petList };
    case SET_HOTEL_BOOKING_FLOW_DATE_TIME:
      return { ...state, startDate: action.startDate, endDate: action.endDate };
    case SAVE_HOTEL_BOOKING_HOST_PETS:
      return { ...state, hostPets: action.hostPets };
    case SET_HOTEL_BOOKING_FLOW_ROOM:
      return { ...state, rooms: { ...state.rooms, [action?.roomId]: action?.pets } };
    case REMOVE_HOTEL_BOOKING_FLOW_ROOM:
      return { ...state, rooms: omit(action?.roomId, state.rooms) };
    case SET_HOTEL_BOOKING_FLOW_PET_ROOM_NUMBER:
      return {
        ...state,
        [action?.petId]: { ...state[action?.petId], roomId: action?.roomId },
      };
    case SET_HOTEL_BOOKING_FLOW_PET_SERVICE:
      return {
        ...state,
        [action?.petId]: {
          ...state[action?.petId],
          petService: action?.petServiceId,
          roomTypeBucketId: action?.roomTypeBucketId,
        },
      };
    case DELETE_HOTEL_BOOKING_FLOW_PET_SERVICE:
      return {
        ...state,
        [action?.petId]: {
          ...omit(["petService", "roomTypeBucketId"], state[action?.petId]),
        },
      };
    case SET_HOTEL_BOOKING_CURRENT_SERVICE_SELECTION_ROOM:
      return { ...state, currentServiceSelectionRoom: action.roomId };
    case SET_HOTEL_BOOKING_PENDING_CHANGES:
      return { ...state, pending: action?.pending };
    case CLEAR_HOTEL_BOOKING_PENDING_CHANGES:
      return omit("pending", state);
    case APPLY_HOTEL_BOOKING_PENDING_CHANGES: {
      const { openStep, furthestStep, hostPets } = state;
      return { openStep, furthestStep, hostPets, ...action?.pending };
    }
    case SET_HOTEL_BOOKING_OPEN_STEP:
      return { ...state, openStep: action?.step };
    case SET_HOTEL_BOOKING_FURTHEST_STEP:
      return { ...state, furthestStep: action?.step };
    case CLEAR_HOTEL_BOOKING:
      return { ...initialState };
    default:
      return state;
  }
}
