import ValuesInPixels from "../../../web/common/styles/valuesInPixels";

/**
 * Responsible for taking a pixel value and converting it to ems
 *
 * @export CalculateEMFromPX
 * @param {number} pxValue
 * @returns {number}
 */
export default function CalculateEMFromPX(pxValue) {
  const emValue = pxValue / ValuesInPixels.base;

  return emValue;
}
