import { connect } from "react-redux";
import SearchInput from "../../../../web/common/SearchInput";
import simplePhrases from "../../../_constants/simplePhrasesConstants";
import {
  clearHotelDeparturesSearchText,
  setHotelDeparturesSearchText,
} from "./hotelDeparturesHeaderSearchActions";
import { getDeparturesSearchText } from "./hotelDeparturesheaderSearchSelector";

const mapStateToProps = state => ({
  placeholder: simplePhrases.HOTEL_HEADER_SEARCH_BY,
  value: getDeparturesSearchText(state),
});

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = dispatch => ({
  onChange: e => dispatch(setHotelDeparturesSearchText({ value: e.target.value })),
  clearText: () => dispatch(clearHotelDeparturesSearchText()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
