import React from "react";
import styled from "styled-components";
import LoadingWrapper from "../../common/LoadingWrapper";
import TitleCard from "../../upcoming_here/titleCard/TitleCardContainer";
import PetCard from "../../upcoming_here/petCard/PetCardContainer";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const PetCards = styled.div`
  max-width: 100%;
`;

export default function CardList({
  title,
  isLoading,
  appointments,
  isFilterableList,
  hereOrCompletedFilterApplied,
  setHereOrCompletedFilterApplied,
}) {
  return (
    <SectionWrapper>
      <TitleCard
        titleName={title}
        isLoading={isLoading}
        isFilterableList={isFilterableList}
        hereOrCompletedFilterApplied={hereOrCompletedFilterApplied}
        setHereOrCompletedFilterApplied={setHereOrCompletedFilterApplied}
      />
      <LoadingWrapper isLoading={isLoading} background={false} showSpinner={false}>
        <PetCards>
          {!isLoading &&
            appointments.map((appointment, index) => (
              <PetCard
                index={index}
                key={`cardList__${appointment}`}
                appointmentID={appointment}
                type={title}
                showStatus
                fromDashboard
              />
            ))}
        </PetCards>
      </LoadingWrapper>
    </SectionWrapper>
  );
}
