import React from "react";
import styled from "styled-components";

const SectionWrapper = styled.section`
  position: absolute;
  top: 3px;
  right: 10px;
  cursor: pointer;
  display: flex;
`;

export default function FeatureFlagIconWrapper(props) {
  const { isHidden, componentID, children } = props;

  if (!isHidden) {
    return <SectionWrapper id={componentID}>{children}</SectionWrapper>;
  }

  return null;
}
