import React from "react";
import { Layout, Text } from "@prism/psm-ui-components";
import RefundPackageContainer from "@/dux/bgm/availableBundlesByPet/applyAvailableBundle/RefundPackageContainer";
import RefundPackageConfirmationModalContainer from "@/dux/refundPackageConfirmationModal/RefundPackageConfirmationModalContainer";
import RefundPackageErrorModalContainer from "@/dux/refundPackageErrorModal/RefundPackageErrorModalContainer";
import ReservedList from "@/dux/bgm/availableBundlesByPet/availableBundlesList/BundleReservedDatesListContainer";
import RedeemedList from "@/dux/bgm/availableBundlesByPet/availableBundlesList/BundleRedeemedDatesListContainer";
import AddOnServicePriceContainer from "@/dux/addOnServicePrice/AddOnServicePriceContainer";

export default function BundlePackageDetailsComponent({
  componentId,
  bundleNumber,
  petId,
  customerId,
  data = {},
}) {
  const {
    isBasePackageExpired,
    isFreePackageExpired,
    warningMessage,
    freeExpirationDate,
    baseExpirationDate,
    baseRemainingUnits,
    freeRemainingUnits,
    type,
    addOn,
    purchaseDate,
    paymentOrigin,
    soldBy,
    serviceUnitPrice,
    offerType,
  } = data;

  const idEnum = { customerId, petId };
  const getTextColor = isExpired => (isExpired ? "text-color-red" : "text-color-black");

  return (
    <Layout.Stack
      style={{ marginTop: "1em" }}
      space="stack-space-4"
      testid={componentId}
      id={componentId}
    >
      <Text>{warningMessage}</Text>
      <Layout.Grid cellSpacing="cell-space-8">
        <Layout.Stack testid="base-units">
          <Text color={getTextColor(isBasePackageExpired)}>{baseExpirationDate}</Text>
          <Text>{baseRemainingUnits}</Text>
        </Layout.Stack>
        <Layout.Stack testid="free-units">
          <Text color={getTextColor(isFreePackageExpired)}>{freeExpirationDate}</Text>
          <Text>{freeRemainingUnits}</Text>
        </Layout.Stack>
        <Text>{type}</Text>
        <Text>{addOn}</Text>
        <Layout.Cluster space="cluster-space-2" justify="left">
          <Text>{purchaseDate}</Text>
          <RefundPackageContainer petId={petId} bundleNumber={bundleNumber} />
        </Layout.Cluster>
        <Text>{paymentOrigin}</Text>
        <Text>{serviceUnitPrice}</Text>
        <AddOnServicePriceContainer bundleNumber={bundleNumber} idEnum={idEnum} />
        <Text>{soldBy}</Text>
        <Text>{/** deliberately empty to organize the grid */}</Text>
        <ReservedList bundleNumber={bundleNumber} idEnum={idEnum} offerType={offerType} />
        <RedeemedList bundleNumber={bundleNumber} idEnum={idEnum} offerType={offerType} />
      </Layout.Grid>
      <RefundPackageConfirmationModalContainer />
      <RefundPackageErrorModalContainer />
    </Layout.Stack>
  );
}
