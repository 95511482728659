import React from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

/**
 * columns is an array of objects containing "path" and "label" properties:
 * [{path: "a", label: "A"}, {path: "b", label: "B"}, {path: "c", label: "C"}]
 *
 * rows is an array of objects containing properties that match a "path" in the
 * columns array. each object in the array represents a row in the table:
 * [
 *   {a: 1, b: 3, c: 5 }
 *   {a: 2, b: 4, c: 6 },
 * ]
 *
 * Output
 *
 * | A | B | C |
 * |---|---|---|
 * | 1 | 3 | 5 |
 * | 2 | 4 | 6 |
 *
 */

export default function Table({ columns, rows }) {
  return (
    <table className="table">
      <TableHeader columns={columns} />
      <TableBody columns={columns} rows={rows} />
    </table>
  );
}
