import { call, select } from "redux-saga/effects";
import { onCreateCustomerPhone, onUpdateCustomerPhone } from "../customerPhoneSaga";
import { onCreateCustomerEmail, onUpdateCustomerEmail } from "../customerEmailSaga";
import { onCreateCustomerAddress, onUpdateCustomerAddress } from "../customerAddressSaga";
import { getPhonesByCustomer } from "../../../selectors/entitiesSelector";

export function* chooseCreateOrUpdatePhone({
  phoneFromProfile,
  customerKey,
  phoneNumberFromForm,
  phoneType
}) {
  const customerPhones = yield select(getPhonesByCustomer, { customerKey });

  if (phoneFromProfile) {
    yield call(onUpdateCustomerPhone, {
      customerKey,
      phoneId: Number(phoneFromProfile.phoneId),
      data: {
        ...phoneFromProfile,
        phoneNumber: phoneNumberFromForm
      }
    });
  } else {
    // Update to make mobile primary if other phone fields provided
    const basePhonePayload = {
      isPrimary: !customerPhones,
      isActive: true
    };
    yield call(onCreateCustomerPhone, {
      customerKey,
      data: {
        ...basePhonePayload,
        phoneNumber: phoneNumberFromForm,
        phoneType
      }
    });
  }
}

export function* chooseCreateOrUpdateAddress({
  addressFromProfile,
  customerKey,
  addressFromForm,
  addressType
}) {
  if (addressFromProfile) {
    yield call(onUpdateCustomerAddress, {
      customerKey,
      addressId: Number(addressFromProfile.addressId),
      data: { ...addressFromProfile, ...addressFromForm }
    });
  } else {
    yield call(onCreateCustomerAddress, {
      customerKey,
      data: { ...addressFromForm, addressType }
    });
  }
}

export function* chooseCreateOrUpdateEmail({
  emailFromProfile,
  customerKey,
  emailFromForm,
  emailType
}) {
  if (emailFromProfile) {
    yield call(onUpdateCustomerEmail, {
      customerKey,
      emailAddressId: Number(emailFromProfile.emailAddressId),
      data: {
        ...emailFromProfile,
        email: emailFromForm
      }
    });
  } else {
    const baseEmailPayload = {
      isEmailVerified: true,
      isPrimary: true,
      isActive: true
    };
    yield call(onCreateCustomerEmail, {
      customerKey,
      data: {
        ...baseEmailPayload,
        email: emailFromForm,
        emailType
      }
    });
  }
}
