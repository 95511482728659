import { connect } from "react-redux";
import { getCurrentCustomerKey } from "@/core/selectors/persistent/customer/customerSelectors";
import {
  isBookAppointmentHotelDisabled,
  isBookingDisabled,
} from "@/dux/eligibility/helperUtils/checkIsBookingDisabled";

import CommonButton from "web/common/commonButton";
import {
  SearchStoreNumSalonContent,
  SearchStoreNumHotelContent,
  SearchStoreNumTrainingContent,
} from "@/dux/searchStoreNumAndBooking/SearchStoreNumAndBookingContent";
import { GenericStyledPopOver } from "../_components/selectServiceButton/GenericStyledPopOver";
import { BOOK_HOTEL_RESERVATION } from "@/dux/bookAppointment/PetsHotelReservationConstant";

const getCommonProps = state => {
  const customerKey = getCurrentCustomerKey(state);
  return {
    customerKey,
    isHidden: false,
    CTA: CommonButton,
    disabled: isBookingDisabled(state, customerKey),
    place: "below",
  };
};

export const SearchStoreNumSalonStyledPopover = connect(state => {
  return {
    ...getCommonProps(state),
    ctaLabel: "Book Salon Appointment",
    FlyoutContent: SearchStoreNumSalonContent,
  };
})(GenericStyledPopOver);

export const SearchStoreNumHotelStyledPopover = connect(state => {
  const commonProps = getCommonProps(state);
  return {
    ...commonProps,
    disabled: isBookAppointmentHotelDisabled(state, commonProps?.customerKey),
    ctaLabel: BOOK_HOTEL_RESERVATION,
    FlyoutContent: SearchStoreNumHotelContent,
  };
})(GenericStyledPopOver);

export const SearchStoreNumTrainingStyledPopover = connect(state => {
  return {
    ...getCommonProps(state),
    ctaLabel: "Schedule Class",
    FlyoutContent: SearchStoreNumTrainingContent,
  };
})(GenericStyledPopOver);
