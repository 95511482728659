import { all, fork } from "redux-saga/effects";
import updateDoNotBookSalonSaga from "dux/doNotBook/doNotBookSalonSaga";
import updateDoNotBookHotelSaga from "dux/doNotBook/doNotBookHotelSaga";

export default function* doNotBookSagas() {
  yield all([
    fork(updateDoNotBookSalonSaga),
    fork(updateDoNotBookHotelSaga),
  ]);
}
