import moment from "moment";

/**
 * Takes a date in the format "2021-11-11" and returns a cache entry
 * that specifies the week and month. For example, "46-2021".
 *
 * @param {string} date - The date to set a a cache entry for.
 * @returns {string} The returned cache entry.
 */
export default function getCacheEntryByDate(date) {
  const week = moment(date).week();
  const year = moment(date).year();

  return `${week}-${year}`;
}
