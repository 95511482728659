import { connect } from "react-redux";
import { getPetFullName, getPetById } from "../../../core/selectors/entitiesSelector";
import { getPetAlert } from "../../../core/selectors/checkInOutSelector";
import { loadPet } from "../../../core/actionCreators/petsActionCreators";
import { setPetId } from "../../../core/actionCreators/ui/web/generalActionCreators";
import PetDetails from "./petDetailsComponent";

const mapStateToProps = (state, ownProps) => {
  const pet = getPetById(state, ownProps.petId);

  return {
    isPetActive: pet?.isActive,
    petFullName: getPetFullName(state, ownProps),
    petAlert: getPetAlert(state, ownProps),
  };
};

const mapDispatchToProps = dispatch => ({
  loadPet: ({ petId, customerKey }) => dispatch(loadPet({ petId, customerKey })),
  setPetId: petId => dispatch(setPetId(petId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PetDetails);
