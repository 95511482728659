import React from "react";
import styled from "styled-components";
import Img from "./Img";
import { font } from "./styles/theme";

/**
|--------------------------------------------------
| * Takes a string and return it with a </br>
 * @param {String} line A string that will be displayed with a line break after
 * @returns {Span} A span with a line and a line break
|--------------------------------------------------
*/

export default function SplitLine(line) {
  return (
    <span key={line}>
      {line}
      <br />
    </span>
  );
}
