import { call } from "redux-saga/effects";
import { eligibilityTypes } from "dux/eligibility/constants/eligibilityConstants";

/**
 * Responsible for getting the Primary eligibility engine results for all pets
 * @param { string } eligibilityType - eligibility tpe, such as booking, check in, check out etc.
 * @param { string | number }eligibilityAPICallArgument
 * @param ( string } eligibilityAPICallKey - param passed api call.
 * @param { string } customerKey - customer key
 * @param { string } startDate - appointment start date
 * @param { FN } eligibilityAPICall - the api to call, either for customer or eligibility
 * @return {Generator<SimpleEffect<"CALL", CallEffectDescriptor>, *, ?>}
 */
export function* onGetPrimaryEngineResultsForAllPets({
  eligibilityType,
  eligibilityAPICall,
  eligibilityAPICallArgument,
  eligibilityAPICallKey,
  customerKey,
}) {
  let primaryEngineResult;

  if (eligibilityType !== eligibilityTypes.CHECK_OUT) {
    const response = yield call(eligibilityAPICall, {
      customerKey,
      isBooking: eligibilityType === eligibilityTypes.BOOKING,
      [eligibilityAPICallKey]: eligibilityAPICallArgument,
    });

    // Once we receive eligibility back for all pets, we will map over each pet and append a petID
    // and set the primary engine result
    primaryEngineResult = response.data.map(pet => ({ ...pet, petId: Number(pet.petId) }));
  }

  return primaryEngineResult;
}
