import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import customersActionTypes from "../../actionTypes/customersActionTypes";
import customersActionCreators from "../../actionCreators/customersActionCreators";
import { getSourceId } from "../../selectors/persistentSelectors";
import {
  postAddress,
  putAddress,
  deleteAddress,
} from "../../services/associateWebProfile/addressEndPoints";
import getCustomerHasPrimaryAddress from "../../selectors/customer/address/getCustomerHasPrimaryAddress";
import { customerAddressTypes } from "../../constants/customerProfileConstants";
import petParentProfileActionCreator from "../../actionCreators/petParentProfileActionCreator";
import getAddressesByCustomer from "../../selectors/customer/address/getAddressesByCustomer";
import getAddressById from "../../selectors/customer/address/getAddressById";

export function* onCreateCustomerAddress({ customerKey, data }) {
  try {
    yield put(customersActionCreators.createCustomerAddressRequest());
    const sourceId = yield select(getSourceId);
    const hasPrimaryAddress = yield select(getCustomerHasPrimaryAddress, { customerKey });
    const response = yield call(postAddress, {
      customerKey,
      sourceId,
      data: {
        ...data,
        sourceId,
        // Only set primary address if customer does not already have one
        isPrimary: !hasPrimaryAddress,
        // Default address type should be "Other" per business requirements
        addressType: customerAddressTypes.OTHER,
      },
    });

    // When creating an address, default editable to true since the isEditable property
    // is not returned on the API level. This will need to be addressed in a future
    // sprint.
    const addressToAdd = { ...response.data, isEditable: true };
    const customerAddresses = yield select(getAddressesByCustomer, {
      customerKey,
    });
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          addresses: customerAddresses.concat(addressToAdd),
        },
      }),
    );
    // Hide add address form when API call is complete
    yield put(petParentProfileActionCreator.displayAddPetParentAddressForm({ isVisible: false }));
    yield put(customersActionCreators.createCustomerAddressSuccess());
  } catch (error) {
    yield put(customersActionCreators.createCustomerAddressError({ error }));
  }
}

export function* onUpdateCustomerAddress({ customerKey, addressId, data }) {
  try {
    yield put(customersActionCreators.updateCustomerAddressRequest());
    const sourceId = yield select(getSourceId);
    const { isPrimary } = yield select(getAddressById, { customerKey, addressId });
    const response = yield call(putAddress, {
      customerKey,
      addressId,
      sourceId,
      data: {
        ...data,
        sourceId,
        // Only set primary address if customer does not already have one
        isPrimary,
        // Default address type should be "Other" per business requirements
        addressType: customerAddressTypes.OTHER,
      },
    });
    const updatedAddress = response.data;
    const customerAddresses = yield select(getAddressesByCustomer, {
      customerKey,
    });
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey: Number(customerKey),
          addresses: customerAddresses.map(address => {
            if (address.addressId === updatedAddress.addressId) {
              return { ...address, ...updatedAddress };
            }
            return address;
          }),
        },
      }),
    );
    yield put(customersActionCreators.updateCustomerAddressSuccess());
  } catch (error) {
    yield put(customersActionCreators.updateCustomerAddressError({ error }));
  }
}

function* onDeleteCustomerAddress({ customerKey, addressId }) {
  try {
    yield put(customersActionCreators.deleteCustomerAddressRequest());
    const sourceId = yield select(getSourceId);
    yield call(deleteAddress, {
      customerKey,
      addressId,
      sourceId,
    });
    const customerAddresses = yield select(getAddressesByCustomer, { customerKey });
    yield put(
      customersActionCreators.updateCustomerSuccess({
        customer: {
          customerKey,
          addresses: customerAddresses.filter(address => address.addressId !== addressId),
        },
      }),
    );
    yield put(customersActionCreators.deleteCustomerAddressSuccess());
  } catch (error) {
    yield put(customersActionCreators.deleteCustomerAddressError({ error }));
  }
}

function* watchCreateCustomerAddress() {
  yield takeEvery(customersActionTypes.CREATE_CUSTOMER_ADDRESS, onCreateCustomerAddress);
}

function* watchUpdateCustomerAddress() {
  yield takeEvery(customersActionTypes.UPDATE_CUSTOMER_ADDRESS, onUpdateCustomerAddress);
}

function* watchDeleteCustomerAddress() {
  yield takeEvery(customersActionTypes.DELETE_CUSTOMER_ADDRESS, onDeleteCustomerAddress);
}

export default function* customerAddressSaga() {
  yield all([
    fork(watchCreateCustomerAddress),
    fork(watchUpdateCustomerAddress),
    fork(watchDeleteCustomerAddress),
  ]);
}
