import { createSelector } from "reselect";
import getModalsState from "@/core/selectors/modals/modalsState";

/**
 * Selector to get overbooking modals state
 * @memberOf Selectors.modals
 * @function
 * @name selectOverbookingModal
 * @param {Object} state
 * @returns current modalType for overbooking modals
 * @example selectOverbookingModal(state)
 */
export const selectOverbookingModal = createSelector(
  [getModalsState],
  modals => modals?.overbooking?.modalType,
);

/**
 * Selector to get current overbooking modal name
 * @memberOf Selectors.modals
 * @function
 * @name selectOverbookingModalName
 * @param {Object} state
 * @returns string
 * @example selectOverbookingModalName(state)
 */
export const selectOverbookingModalName = createSelector(
  [selectOverbookingModal],
  modalType => modalType?.name,
);

/**
 * Selector to get current overbooking modal data
 * @memberOf Selectors.modals
 * @function
 * @name selectOverbookingModalData
 * @param {Object} state
 * @example selectOverbookingModalData(state)
 */
export const selectOverbookingModalData = createSelector(
  [selectOverbookingModal],
  modalType => modalType?.data,
);
