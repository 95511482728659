import { connect } from "react-redux";
import PetInfoComponent from "./petInfoComponent";
import {
  getPetByPetServiceItemId,
  getPetServiceItem,
  getCustomerByPetServiceItemId,
} from "@/core/selectors/entitiesSelector";
import { getBreeds } from "@/core/selectors/enumsSelectors";
import { rebookClick, historyClick } from "@/core/actionCreators/petParentProfileActionCreator";
import { getAddOnsList, getEnhancedServiceName } from "@/core/selectors/entities/petServiceItemsSelectors";

const mapStateToProps = (state, ownProps) => {
  return {
    pet: getPetByPetServiceItemId(state, ownProps) || {},
    breeds: getBreeds(state),
    appointment: getPetServiceItem(state, ownProps),
    addOnsList: getAddOnsList(state, ownProps),
    enhancedServiceName: getEnhancedServiceName(state, ownProps),
    customer: getCustomerByPetServiceItemId(state, ownProps),
  };
};

const mapDispatchToProps = dispatch => ({
  rebookClick: ({ appointment }) => dispatch(rebookClick({ appointment })),
  showModal: ({ appointment }) => dispatch(historyClick({ appointment })),
});

const PetInfoContainer = connect(mapStateToProps, mapDispatchToProps)(PetInfoComponent);

export default PetInfoContainer;
