import { createSelector } from "reselect";
import getAddressById from "@/core/selectors/customer/address/getAddressById";
import { getProps } from "@/core/selectors/commonSelector";

// Example of use:  getIsAddressEditable(state, { customerKey, addressId });
const getIsAddressEditable = createSelector(
  [getAddressById, getProps],
  (currentAddress, { addressId }) => {
    return !addressId || currentAddress?.isEditable;
  },
);

export default getIsAddressEditable;
