import React from "react";
import styled from "styled-components";
import CommonModal from "@/web/common/modals/commonModal";

const RootComponent = styled.section`
  display: flex;
  flex-direction: row;
`;

export default function RefundPackageModalContent(props) {
  const { isHidden, componentID, onModalClose, compName, diComp } = props;

  if (!isHidden) {
    const ModalContent = diComp[compName];

    return (
      <RootComponent id={componentID}>
        <CommonModal onClose={onModalClose}>
          <ModalContent />
        </CommonModal>
      </RootComponent>
    );
  }

  return null;
}
