import { connect } from "react-redux";
import { getHotelDrawerIsOpen } from "../hotelRoomCapacityModal/hotelRoomCapacitySelector";
import CapacityRoomRowThreeCol from "../_components/roomCapacity/CapacityRoomRowThreeCol";
import { getHotelRoomCapacityByIndex } from "./hotelRoomCapacitySelector";

const mapStateToProps = (state, ownProps) => {
  const capacity = getHotelRoomCapacityByIndex(state, ownProps.index);
  const isDrawerOpen = getHotelDrawerIsOpen(state);
  return {
    isHidden: false,
    componentID: "CapacityRoomRow_Hotel",
    room: capacity.room,
    used: capacity.used,
    availableRooms: capacity.availableRooms,
    physical: capacity.physicalRooms,
    overbooking: capacity.overbookingRooms,
    isDrawerOpen,
  };
};

export default connect(mapStateToProps)(CapacityRoomRowThreeCol);
