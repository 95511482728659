/**
 * Constant for check out day alert
 * @memberOf Constants.CheckOut
 */
export const checkoutDayAlert = {
  header: "Check-out Date Alert",
  proceed: "Proceed Anyway",
  cancel: "Cancel",
  mainMsg:
    "Check-Out date is different than current date.\n\nPlease update the check-out date to\nreflect the correct pricing on the invoice.",
};
