import * as React from 'react';
import { psx } from '../../../utils/clsx';
import Text from '../../Text';

import './styles.css';

export type FormFieldVariant = 'none' | 'floating' | 'prism' | 'legacy-select';

export const DEFAULT_FORM_FIELD_VARIANT: FormFieldVariant = 'none';

export interface FormFieldProps extends React.ComponentPropsWithoutRef<'div'> {
  className?: string;
  error?: string;
  label?: string;
  labelStyle?: {};
  variant?: FormFieldVariant;
  layout?: 'block';
  name?: string | undefined;
}

export interface FormFieldInputProps {
  label?: string;
  labelStyle?: {};
  labelClassName?: string;
  validateOnMount?: boolean;
  variant?: FormFieldVariant;
}

// a form field handles the labeling and error message state
const FormField: React.FunctionComponent<FormFieldProps> = (props) => {
  const {
    children,
    className,
    error,
    label,
    labelStyle,
    layout,
    variant = DEFAULT_FORM_FIELD_VARIANT,
    ...rest
  } = props;
  const hasError = Boolean(error);

  const containerClassNames = psx('psm', 'form-group', className, {
    'has-error': hasError,
  });

  const labelClassNames = psx('psm', `form-label-${variant}`, className, {
    'has-error': hasError,
  });

  const errorMessageClassNames = psx('psm', 'form-error-message', `form-error-message-${variant}`);

  const labelElement = label && (
    <label className={labelClassNames} htmlFor={label}>
      <Text bold style={labelStyle}>
        {label}
      </Text>
    </label>
  );

  const errorMessage = hasError && (
    <div className={errorMessageClassNames}>
      <span>{error}</span>
    </div>
  );

  return (
    <div>
      <div {...rest} className={containerClassNames}>
        {labelElement}
        {children}
      </div>
      <div>{errorMessage}</div>
    </div>
  );
};

export default FormField;
