/**
 * Takes a string and a count & returns a pluralized string if the count is greater than zero.
 * @param {String} The string value to pluralize
 * @param {Number} The current count
 * @returns {String} Pluralized string or original string value.
 * */

export default function pluralizeString(str, count) {
  return count === 1 ? str : `${str}s`;
}
