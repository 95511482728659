import React from "react";
import styled from "styled-components";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
`;

export default function PetsHotelIcon(props) {
  const { isHidden, componentID, handleClick, color } = props;

  if (!isHidden) {
    return (
      <SectionWrapper id={componentID} onClick={handleClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15.143"
          height="17.153"
          viewBox="0 0 15.143 17.153"
        >
          <g id="Group_30" data-name="Group 30" transform="translate(-87.608 -66.396)">
            <path
              id="Path_2"
              data-name="Path 2"
              transform="translate(-11.918 -20.251)"
              fill={color}
              d="M106.916,93.935l-.075-.06a2.724,2.724,0,0,0-.769-.419,3.257,3.257,0,0,0-.834-.169,2.306,2.306,0,0,0-.885.117,2,2,0,0,0-.779.487,1.7,1.7,0,0,0-.288.386,2.017,2.017,0,0,0-.172.428,2.288,2.288,0,0,0-.063.886,3.363,3.363,0,0,0,.206.811,5.592,5.592,0,0,0,.341.716,5.772,5.772,0,0,0,.433.663,8.207,8.207,0,0,0,1.052,1.136,10.794,10.794,0,0,0,1.205.943c.242.162.452.29.662.4a3.574,3.574,0,0,0,.379.18,1.264,1.264,0,0,0,.13.043l.037.009.03.006.029,0,.063.005h.015a.492.492,0,0,0,.074-.006.388.388,0,0,0,.065-.015.694.694,0,0,0,.152-.068l.023-.013c.038-.023.069-.045.1-.068a5.466,5.466,0,0,0,.6-.522c.178-.174.35-.357.527-.56a10.311,10.311,0,0,0,.92-1.226,8.127,8.127,0,0,0,.711-1.377,5.387,5.387,0,0,0,.377-1.547,3.033,3.033,0,0,0-.057-.845,2.311,2.311,0,0,0-.341-.822,1.847,1.847,0,0,0-.706-.619,2.152,2.152,0,0,0-.882-.213,2.539,2.539,0,0,0-.872.114,2.353,2.353,0,0,0-.787.432,2.642,2.642,0,0,0-.567.67C106.957,93.862,106.936,93.9,106.916,93.935Zm-.069,1.169.016.009a.457.457,0,0,0,.323.047.14.14,0,0,0,.03-.007.435.435,0,0,0,.306-.362l0-.012a1.177,1.177,0,0,1,.071-.24,1.945,1.945,0,0,1,.132-.27,1.771,1.771,0,0,1,.376-.454,1.546,1.546,0,0,1,1.077-.359,1.1,1.1,0,0,1,.933.455,1.8,1.8,0,0,1,.263,1.143,4.576,4.576,0,0,1-.322,1.3,7.315,7.315,0,0,1-.639,1.237,9.569,9.569,0,0,1-.846,1.128c-.162.185-.324.358-.483.512a4.727,4.727,0,0,1-.494.433c-.068-.029-.149-.069-.25-.123-.185-.1-.379-.219-.595-.364a9.983,9.983,0,0,1-1.113-.87,7.482,7.482,0,0,1-.948-1.022,4.964,4.964,0,0,1-.372-.569,4.745,4.745,0,0,1-.292-.612,1.906,1.906,0,0,1-.12-1.186,1.15,1.15,0,0,1,.1-.25.9.9,0,0,1,.158-.2,1.173,1.173,0,0,1,.462-.275,1.369,1.369,0,0,1,.434-.066,2.281,2.281,0,0,1,.734.136,1.867,1.867,0,0,1,.519.292,2.1,2.1,0,0,1,.223.206c.035.038.07.077.1.117l.091.112.018.021.026.027A.407.407,0,0,0,106.847,95.1Z"
            />
            <path
              id="Path_3"
              data-name="Path 3"
              transform="translate(0)"
              fill={color}
              d="M102.612,71.946l-1.8-1.377c.008-.212.015-.423.019-.636l.008-.827-.008-.826c-.005-.276-.012-.552-.026-.839a.344.344,0,0,0-.343-.326H98.333a.347.347,0,0,0-.344.341l-.019.946-2.558-1.936a.357.357,0,0,0-.428,0l-7.222,5.45a.391.391,0,0,0-.154.319l.125,5.479.141,5.478a.354.354,0,0,0,.353.346q2.2.008,4.512.008c3.088,0,6.3-.012,9.606-.034a.148.148,0,0,0,.147-.143l.174-5.56.053-2.794.032-2.789A.353.353,0,0,0,102.612,71.946ZM98.684,67.8h1.422q-.007.239-.011.479l-.008.827.008.826c0,.029,0,.058,0,.086L99.02,69.2l-.312-.236Zm-.108,1.977,3.461,2.619L102,73.569l-.1,4.034-.034,2.693-.02,2.56c-3.2-.022-6.232-.033-9.172-.033q-2.074,0-4.1.007L88.494,77.7l-.1-5.281L95.2,67.2Z"
            />
          </g>
        </svg>
      </SectionWrapper>
    );
  }

  return null;
}
