import { connect } from "react-redux";
import { headerConfigConstants } from "../../core/constants/headerConfigConstants";
import { getJobRole } from "../../core/selectors/persistentSelectors";
import AppHeader from "../../web/header/AppHeader";
import salonHeaderImage from "../../assets/images/bg-header-image.png";
import { getSystemBookingFlow } from "../../web/setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../../web/setSystemType/constants/setSystemTypeConstants";

// TODO: move to AppHeader.js so all containers are co-located with the component.
const mapStateToProps = (state, ownProps) => {
  const bookingFlow = getSystemBookingFlow(state);

  return {
    isHidden: bookingFlow !== systemName.SALON,
    componentID: "header--Dashboard-Salon",
    config: headerConfigConstants.DASHBOARD,
    jobRole: getJobRole(state, ownProps),
    backgroundImage: salonHeaderImage,
  };
};

export default connect(mapStateToProps)(AppHeader);
