import React from "react";
import { LayoutBox } from "../../../layout/box/Box";
import { LayoutStack } from "../../../layout/stack/Stack";

export default function ScrollablePetCardList(props) {
  const { isHidden, componentId, appointments, compName, diComp, style } = props;

  if (!isHidden) {
    const PetApptInfoCard = diComp[compName];

    return (
      <LayoutBox padding="scale-0" id={componentId} style={style}>
        <LayoutStack space="scale-S3">
          {appointments.map(appointment => (
            <PetApptInfoCard
              appointment={appointment}
              key={`${appointment?.petId}-${appointment?.itineraryId}`}
            />
          ))}
        </LayoutStack>
      </LayoutBox>
    );
  }

  return null;
}
