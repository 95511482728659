import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Layout } from "@prism/psm-ui-components";
import fadeIn from "web/common/styles/Animation/fade/fadeIn";
import { color } from "../styles/theme";
import closeImage from "../../../assets/icons/x.svg";

const ModalOverlay = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;

  animation: ${fadeIn()} 0.3s ease;
`;

const RelContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 5px solid ${color.kalEl};
`;

const ModalContent = styled.div`
  min-width: 470px;
  min-height: 260px;
  background-color: ${color.victorStone};
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
`;

const CloseButton = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  outline: 0;
  z-index: 1;
`;

export default class CommonModal extends Component {
  render() {
    const { children, onClose, isHidden } = this.props;

    if (isHidden) {
      return null;
    }

    return ReactDOM.createPortal(
      <ModalOverlay className="commonModal">
        <RelContainer className="commonModal__RelContainer">
          <ModalContent className="commonModal__ModalContent">
            <Layout.Box padding="box-padding-4" style={{ maxHeight: "75vh" }}>
              <Layout.Cluster justify="flex-end">
                <Layout.Box padding="box-padding-2">
                  <CloseButton
                    className="commonModal__CloseButton"
                    src={closeImage}
                    onClick={onClose}
                  />
                </Layout.Box>
              </Layout.Cluster>
              {children}
            </Layout.Box>
          </ModalContent>
        </RelContainer>
      </ModalOverlay>,
      document.body,
    );
  }
}
