import engagementsActionTypes from "../actionTypes/engagementsActionTypes";

export const loadEngagement = ({ engagement }) => ({
  type: engagementsActionTypes.LOAD_ENGAGEMENT,
  payload: engagement
});

export const loadEngagements = ({ engagements }) => ({
  type: engagementsActionTypes.LOAD_ENGAGEMENTS,
  payload: engagements
});

export const deleteEngagements = ({ engagements }) => ({
  type: engagementsActionTypes.DELETE_ENGAGEMENTS,
  payload: engagements
});

export const createEngagement = ({ customerKey, itineraryId, data, dispatchToCart }) => ({
  type: engagementsActionTypes.CREATE_ENGAGEMENT,
  itineraryId,
  dispatchToCart,
  customerKey,
  data
});

export const createEngagementRequest = () => ({
  type: engagementsActionTypes.CREATE_ENGAGEMENT_REQUEST
});

export const createEngagementSuccess = ({ itinerary }) => ({
  type: engagementsActionTypes.CREATE_ENGAGEMENT_SUCCESS,
  payload: itinerary
});

export const createEngagementFailure = ({ error }) => ({
  type: engagementsActionTypes.CREATE_ENGAGEMENT_FAILURE,
  error
});

export const deleteEngagement = ({ customerKey, itineraryId, engagementId }) => ({
  type: engagementsActionTypes.DELETE_ENGAGEMENT,
  customerKey,
  itineraryId,
  engagementId
});

export const deleteEngagementRequest = () => ({
  type: engagementsActionTypes.DELETE_ENGAGEMENT_REQUEST
});

export const deleteEngagementSuccess = ({ engagementId }) => ({
  type: engagementsActionTypes.DELETE_ENGAGEMENT_SUCCESS,
  payload: engagementId
});

export const deleteEngagementFailure = ({ error }) => ({
  type: engagementsActionTypes.DELETE_ENGAGEMENT_FAILURE,
  error
});

export const updateEngagementStatus = ({
  pageName,
  customerId,
  itineraryId,
  engagementId,
  reason,
  status
}) => ({
  type: engagementsActionTypes.UPDATE_ENGAGEMENT_STATUS,
  customerId,
  itineraryId,
  engagementId,
  reason,
  status,
  pageName
});

export const updateEngagementStatusRequest = () => ({
  type: engagementsActionTypes.UPDATE_ENGAGEMENT_STATUS_REQUEST
});

export const updateEngagementStatusSuccess = ({ itinerary }) => ({
  type: engagementsActionTypes.UPDATE_ENGAGEMENT_STATUS_SUCCESS,
  payload: itinerary
});

export const updateEngagementStatusFailure = ({ error }) => ({
  type: engagementsActionTypes.UPDATE_ENGAGEMENT_STATUS_FAILURE,
  error
});

export const undoLastEngagementStatusUpdate = ({ customerId, itineraryId, engagementId }) => ({
  type: engagementsActionTypes.UNDO_LAST_ENGAGEMENT_STATUS_UPDATE,
  customerId,
  itineraryId,
  engagementId
});

export const undoLastEngagementStatusUpdateRequest = () => ({
  type: engagementsActionTypes.UNDO_LAST_ENGAGEMENT_STATUS_UPDATE_REQUEST
});

export const undoLastEngagementStatusUpdateSuccess = ({ itinerary }) => ({
  type: engagementsActionTypes.UNDO_LAST_ENGAGEMENT_STATUS_UPDATE_SUCCESS,
  payload: itinerary
});

export const undoLastEngagementStatusUpdateFailure = ({ error }) => ({
  type: engagementsActionTypes.UNDO_LAST_ENGAGEMENT_STATUS_UPDATE_FAILURE,
  payload: error
});

export default {
  loadEngagement,
  loadEngagements,
  deleteEngagements,
  createEngagement,
  createEngagementRequest,
  createEngagementFailure,
  createEngagementSuccess,
  deleteEngagement,
  deleteEngagementRequest,
  deleteEngagementFailure,
  deleteEngagementSuccess,
  updateEngagementStatus,
  updateEngagementStatusRequest,
  updateEngagementStatusSuccess,
  updateEngagementStatusFailure,
  undoLastEngagementStatusUpdate,
  undoLastEngagementStatusUpdateRequest,
  undoLastEngagementStatusUpdateSuccess,
  undoLastEngagementStatusUpdateFailure
};
