import { useEffect, useRef } from "react";

/**
 * Will invoke a callback function if the user clicks outside of a DOM reference
 * @param ref - Dom ref, e.g. a react useRef(null)
 * @param callBack - a callback function to invoke on click outside.
 */
export const ClickOutside = (ref, callBack, isDisabled) => {
  /* A reference, specific to the callback function needs to be created each time this FN is invoked.
   * The updated callback is passed as the new Ref. This is done because when this function is
   * initially invoked, it references the initial callback and that callback does not get updated.
   * Subsequently this FN still reference the initial callback from teh initial invocation. The
   * callback ref is used to get the latest version of the callback each time the ClickOutside is invoked. */
  const cbRef = useRef(callBack);
  const disabledRef = useRef(isDisabled);

  useEffect(() => {
    cbRef.current = callBack;
    disabledRef.current = isDisabled;
  });

  useEffect(() => {
    let canFireCallBack = false;

    function handleClickOutside(event) {
      const isInSideRef = ref?.current?.contains(event.target);
      const isOutsideRef = !ref?.current?.contains(event.target);

      if (isInSideRef) {
        canFireCallBack = true;
      }

      if (isOutsideRef && canFireCallBack) {
        if (!!disabledRef.current) {
          return;
        }
        cbRef.current();
        canFireCallBack = false;
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};
