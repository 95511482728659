import isEmpty from "lodash/isEmpty";
import { checkFrequencyHasDay } from "../frequencyUtils/checkFrequencyHasDay";

/**
 * Util to format engagements for overnight itinerary with new addon objs for addon patch
 * @memberOf Utils.Engagement
 * @function
 * @name buildEngagementsWithNewOvernightAddons
 * @param {Object} args
 * @param {Object[]} args.petEngagements - all engagements for given pet from itinerary
 * @param {string} args.showingAddonId - the addonId for the addon currently being edited
 * @param {{ [addonId: string]: { notes: string }}} args.instructions - comment to add to new addon
 * @param {string} args.frequency - frequency to add to new addon
 * @param {string[]} args.dates - dates for which engagements to add the new addon to
 * @param {string} args.timeZone
 * @returns {Object[]} - engagements data for patch call
 * @example buildEngagementsWithNewOvernightAddons({ petEngagements, showingAddonId, instructions, frequency, dates, timeZone })
 */
export const buildEngagementsWithNewOvernightAddons = ({
  petEngagements = [],
  showingAddonId,
  instructions = {},
  frequency,
  dates,
  timeZone,
}) =>
  petEngagements
    .map(({ engagementId, startDatetime } = {}) => {
      const isDateInFrequency = checkFrequencyHasDay(dates, startDatetime, timeZone);
      if (!isDateInFrequency) return {};
      return {
        engagementId,
        addOns: [
          {
            addOnProductNumber: showingAddonId,
            quantity: 1,
            comment: instructions[showingAddonId]?.notes,
            frequency,
          },
        ],
      };
    })
    .filter(engagement => !isEmpty(engagement));
