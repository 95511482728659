import React, { useState } from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import {
  getCheckedOutColumnVisibility,
  getCheckedOutSelectedOption,
} from "dux/hotelCheckedOut/columnHeader/columnSwitch/hotelCheckedOutColumnToggleSelector";
import { CHECKED_OUT_OR_DEPARTURES_FILTER_OPTIONS } from "core/constants/dropdownOptions";
import { toggleHotelCheckedOutColumn } from "dux/hotelCheckedOut/columnHeader/columnSwitch/hotelCheckedOutColumnToggleActions";
import GenericTitle from "../genericLayoutComponents/GenericTitle";
import StyledPopover from "../../../web/common/StyledPopover";
import FlyoutToggleOptions from "./FlyoutToggleOptions";
import { downArrowUri } from "../../../web/common/styles/theme";

export const toggleFlyout = ({ isFlyoutOpen, setIsFlyoutOpen }) => {
  setIsFlyoutOpen(!isFlyoutOpen);
};

export const FlyoutToggleWithTitle = props => {
  // Short circuit view if it isHidden
  const { isHidden } = props;

  if (isHidden) return null;

  // value props
  const { componentId, options, title, isFilterableList } = props;

  // fn props
  const { selectionHandler, selectedOption } = props;

  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <LayoutCluster>
        <StyledPopover
          body={
            <FlyoutToggleOptions
              selectionHandler={selectionHandler}
              selectedOption={selectedOption}
              options={options}
            />
          }
          isOpen={isFlyoutOpen}
          preferPlace="below"
          onOuterAction={() => toggleFlyout({ isFlyoutOpen, setIsFlyoutOpen })}
          tipSize={7}
        >
          <LayoutBox padding="scale-0">
            <LayoutCluster style={{ flexWrap: "nowrap" }}>
              <LayoutBox padding="scale-0">
                <GenericTitle title={title} />
              </LayoutBox>

              <LayoutBox padding="scale-0">
                {isFilterableList && (
                  <LayoutBox
                    style={{
                      cursor: "pointer",
                      width: "2em",
                      height: "1.75em",
                      backgroundImage: `url(${downArrowUri})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPositionX: "50%",
                      backgroundPositionY: "50%",
                    }}
                    onClick={() => {
                      toggleFlyout({ isFlyoutOpen, setIsFlyoutOpen });
                    }}
                  />
                )}
              </LayoutBox>
            </LayoutCluster>
          </LayoutBox>
        </StyledPopover>
      </LayoutCluster>
    </LayoutBox>
  );
};

/**
 * NOTE this redux container was originally
 * packages/associate-ui/src/dux/hotelCheckedOut/columnHeader/columnSwitch/HotelCheckedOutColumnToggleContainer.js
 * and was deprecated and deleted as of ticket SVCSART-22840
 */
export const HotelCheckOutToggle = connect(
  state => {
    return {
      componentId: "HotelCheckOutToggle",
      isFilterableList: true,
      selectedOption: getCheckedOutSelectedOption(state),
      title: "Checked Out",
      options: CHECKED_OUT_OR_DEPARTURES_FILTER_OPTIONS,
      isColumnHidden: getCheckedOutColumnVisibility(state),
    };
  },

  dispatch => {
    return {
      toggleHandler: ({ isHidden }) => {
        dispatch(toggleHotelCheckedOutColumn({ isHidden: !isHidden }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      isFilterableList,
      selectedOption,
      title,
      options,
      isColumnHidden,
    } = mapProps;
    const { toggleHandler } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isFilterableList,
      selectedOption,
      title,
      options,

      // actions to pass to view
      selectionHandler: () =>
        toggleHandler({
          isHidden: isColumnHidden,
        }),
    };
  },
)(FlyoutToggleWithTitle);

/**
 * NOTE this redux container was originally
 * packages/associate-ui/src/dux/hotelDepartures/columnHeader/columnSwitch/HotelDeparturesColumnToggleContainer.js
 * and was deprecated and deleted as of ticket SVCSART-22840
 */
export const HotelDeparturesToggle = connect(
  state => {
    return {
      componentId: "HotelDeparturesToggle",
      isFilterableList: true,
      selectedOption: getCheckedOutSelectedOption(state),
      title: "Departures",
      options: CHECKED_OUT_OR_DEPARTURES_FILTER_OPTIONS,
      isColumnHidden: getCheckedOutColumnVisibility(state),
    };
  },

  dispatch => {
    return {
      toggleHandler: ({ isHidden }) => {
        dispatch(toggleHotelCheckedOutColumn({ isHidden: !isHidden }));
      },
    };
  },

  (mapProps, dispatchProps) => {
    const {
      componentId,
      isFilterableList,
      selectedOption,
      title,
      options,
      isColumnHidden,
    } = mapProps;
    const { toggleHandler } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isFilterableList,
      selectedOption,
      title,
      options,

      // actions to pass to view
      selectionHandler: () =>
        toggleHandler({
          isHidden: isColumnHidden,
        }),
    };
  },
)(FlyoutToggleWithTitle);
