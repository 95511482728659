import schedulesActionTypes from "../actionTypes/schedulesActionTypes";

export const loadAssociatesSchedule = ({ fromDate }) => ({
  type: schedulesActionTypes.LOAD_ASSOCIATES_SCHEDULE,
  fromDate
});

export const loadAssociatesScheduleRequest = () => ({
  type: schedulesActionTypes.LOAD_ASSOCIATES_SCHEDULE_REQUEST
});

export const loadAssociatesScheduleSuccess = ({ schedules }) => ({
  type: schedulesActionTypes.LOAD_ASSOCIATES_SCHEDULE_SUCCESS,
  payload: schedules
});

export const loadAssociatesScheduleFailure = ({ error }) => ({
  type: schedulesActionTypes.LOAD_ASSOCIATES_SCHEDULE_FAILURE,
  error
});

export const loadMySchedule = ({ fromDate }) => ({
  type: schedulesActionTypes.LOAD_MY_SCHEDULE,
  fromDate
});

export const loadMyScheduleRequest = () => ({
  type: schedulesActionTypes.LOAD_MY_SCHEDULE_REQUEST
});

export const loadMyScheduleSuccess = ({ dayActivities }) => {
  return {
    type: schedulesActionTypes.LOAD_MY_SCHEDULE_SUCCESS,
    payload: dayActivities
  };
};

export const loadMyScheduleFailure = ({ error }) => ({
  type: schedulesActionTypes.LOAD_MY_SCHEDULE_FAILURE,
  error
});

export const setAllAssociatesWeekSchedule = ({ activities }) => ({
  type: schedulesActionTypes.SET_ALL_ASSOCIATES_WEEK_SCHEDULE,
  activities
});

export const setStartWeekDate = startWeekDate => ({
  type: schedulesActionTypes.SET_START_WEEK_DATE,
  startWeekDate
});

export const setEffectiveStartDate = effectiveStartDate => ({
  type: schedulesActionTypes.SET_EFFECTIVE_START_DATE,
  effectiveStartDate
});

export const clearAssociatesSchedule = () => ({
  type: schedulesActionTypes.CLEAR_ASSOCIATES_SCHEDULES
});

export const clearSchedules = () => ({
  type: schedulesActionTypes.CLEAR_SCHEDULES
});

export const clearTemplateSchedules = () => ({
  type: schedulesActionTypes.CLEAR_TEMPLATE_SCHEDULES
});

export const loadSchedules = ({ associateId, fromDate }) => ({
  type: schedulesActionTypes.LOAD_SCHEDULES,
  associateId,
  fromDate
});

export const loadSchedulesRequest = () => ({
  type: schedulesActionTypes.LOAD_SCHEDULES_REQUEST
});

export const loadSchedulesSuccess = () => ({
  type: schedulesActionTypes.LOAD_SCHEDULES_SUCCESS
});

export const loadSchedulesFailure = ({ error }) => ({
  type: schedulesActionTypes.LOAD_SCHEDULES_FAILURE,
  error
});

export const loadTemplateSchedules = ({ associateId }) => ({
  type: schedulesActionTypes.LOAD_TEMPLATE_SCHEDULES,
  associateId
});

export const loadTemplateSchedulesRequest = () => ({
  type: schedulesActionTypes.LOAD_TEMPLATE_SCHEDULES_REQUEST
});

export const loadTemplateSchedulesSuccess = ({ templateSchedules }) => ({
  type: schedulesActionTypes.LOAD_TEMPLATE_SCHEDULES_SUCCESS,
  templateSchedules
});

export const loadTemplateSchedulesFailure = ({ error }) => ({
  type: schedulesActionTypes.LOAD_TEMPLATE_SCHEDULES_FAILURE,
  error
});

export const setSchedules = ({ associateId, startWeekDate, dayActivities }) => ({
  type: schedulesActionTypes.SET_SCHEDULES,
  associateId,
  startWeekDate,
  dayActivities
});

export const setSchedulesRequest = () => ({
  type: schedulesActionTypes.SET_SCHEDULES_REQUEST
});

export const setSchedulesSuccess = ({ dayActivities }) => ({
  type: schedulesActionTypes.SET_SCHEDULES_SUCCESS,
  payload: dayActivities
});

export const setSchedulesFailure = ({ error }) => ({
  type: schedulesActionTypes.SET_SCHEDULES_FAILURE,
  error
});

export const setTemplateSchedules = ({
  associateId,
  weekId,
  effectiveStartDate,
  dayActivities
}) => ({
  type: schedulesActionTypes.SET_TEMPLATE_SCHEDULES,
  associateId,
  weekId,
  effectiveStartDate,
  dayActivities
});

export const setTemplateSchedulesRequest = () => ({
  type: schedulesActionTypes.SET_TEMPLATE_SCHEDULES_REQUEST
});

export const setTemplateSchedulesSuccess = () => ({
  type: schedulesActionTypes.SET_TEMPLATE_SCHEDULES_SUCCESS
});

export const setTemplateSchedulesFailure = ({ error }) => ({
  type: schedulesActionTypes.SET_TEMPLATE_SCHEDULES_FAILURE,
  error
});

export const showSchedulesModal = modalType => ({
  type: schedulesActionTypes.SHOW_SCHEDULES_MODAL,
  modalType
});

export const hideSchedulesModal = () => ({
  type: schedulesActionTypes.HIDE_SCHEDULES_MODAL
});

export const setAssociateId = associateId => ({
  type: schedulesActionTypes.SET_ASSOCIATE_ID,
  associateId
});

export const setTemplateWeekId = weekId => ({
  type: schedulesActionTypes.SET_TEMPLATE_WEEK_ID,
  weekId
});

export const changeTemplateWeekId = weekId => ({
  type: schedulesActionTypes.CHANGE_TEMPLATE_WEEK_ID,
  weekId
});

export const setWeeklyEdit = ({ dayActivities }) => ({
  type: schedulesActionTypes.SET_WEEKLY_EDIT,
  dayActivities
});

export const setNewWeeklyEdit = (dayActivity) => ({
  type: schedulesActionTypes.SET_NEW_WEEKLY_EDIT,
  payload: { ...dayActivity }
});

export const setNewWeeklyEditSuccess = (dayActivity) => ({
  type: schedulesActionTypes.SET_NEW_WEEKLY_EDIT_SUCCESS,
  payload: { ...dayActivity }
});

export const removeWeeklyEdit = (dayActivity) => ({
  type: schedulesActionTypes.REMOVE_WEEKLY_EDIT,
  payload: { ...dayActivity }
});

export const setTemplateWeeklyEdit = weekId => ({
  type: schedulesActionTypes.SET_TEMPLATE_WEEK_EDIT,
  weekId
});

export const getTemplateWeeklyEdit = weekId => ({
  type: schedulesActionTypes.GET_TEMPLATE_WEEK_EDIT,
  weekId
});

export const setWeeklyEditDayActivity = ({
  localId,
  type,
  absenceReason,
  startTime,
  endTime,
  storeId,
  resetIds,
  petSupportedType,
  hasChanged,
  isSplitShift,
  shiftIndex,
}) => ({
  type: schedulesActionTypes.SET_WEEKLY_EDIT_DAY_ACTIVITY,
  payload: {
    localId,
    type,
    absenceReason,
    startTime,
    endTime,
    storeId,
    resetIds,
    petSupportedType,
    hasChanged,
    isSplitShift,
    shiftIndex,
  },
});

export const copyPreviousDay = dayOfWeek => ({
  type: schedulesActionTypes.COPY_PREVIOUS_DAY,
  dayOfWeek
});

export const resetDay = dayOfWeek => ({
  type: schedulesActionTypes.RESET_DAY,
  dayOfWeek
});

export const copyPreviousWeek = () => ({
  type: schedulesActionTypes.COPY_PREVIOUS_WEEK
});

export const resetWeek = weekId => ({
  type: schedulesActionTypes.RESET_WEEK,
  weekId
});

export const addPartialDayRelocation = partialDayRelocation => ({
  type: schedulesActionTypes.ADD_PARTIAL_DAY_RELOCATION,
  partialDayRelocation
});

export const setDailyLunch = ({ dayOfWeek, startTime, endTime }) => ({
  type: schedulesActionTypes.SET_DAILY_LUNCH,
  dayOfWeek,
  startTime,
  endTime
});

export const removeDailyLunch = ({ dayOfWeek }) => ({
  type: schedulesActionTypes.REMOVE_DAILY_LUNCH,
  dayOfWeek
});

export const goToAssociateTemplate = associateId => ({
  type: schedulesActionTypes.GO_TO_ASSOCIATE_TEMPLATE,
  associateId
});

export const resetDayActivityIds = dayActivityLocalId => ({
  type: schedulesActionTypes.RESET_DAY_ACTIVITY_IDS,
  dayActivityLocalId
});

export const loadLunchBreakTimes = storeNumber => ({
  type: schedulesActionTypes.LOAD_LUNCH_BREAK_TIMES,
  storeNumber
});

export const loadLunchBreakTimesRequest = () => ({
  type: schedulesActionTypes.LOAD_LUNCH_BREAK_TIMES_REQUEST
});

export const loadLunchBreakTimesSuccess = ({ lunchBreakTimes, storeNumber }) => ({
  type: schedulesActionTypes.LOAD_LUNCH_BREAK_TIMES_SUCCESS,
  lunchBreakTimes,
  storeNumber
});

export const loadLunchBreakTimesFailure = ({ error }) => ({
  type: schedulesActionTypes.LOAD_LUNCH_BREAK_TIMES_FAILURE,
  error
});

export const setSchedulesSearchValue = ({ searchValue }) => ({
  type: schedulesActionTypes.SET_SCHEDULES_SEARCH_VALUE,
  searchValue
});

export const setWeeklyEditChanged = newWeeklyEditChanged => ({
  type: schedulesActionTypes.SET_WEEKLY_EDIT_CHANGED,
  newWeeklyEditChanged
});

export default {
  setStartWeekDate,
  setEffectiveStartDate,
  clearSchedules,
  clearTemplateSchedules,
  loadSchedules,
  loadSchedulesRequest,
  loadSchedulesSuccess,
  loadSchedulesFailure,
  loadTemplateSchedules,
  loadTemplateSchedulesRequest,
  loadTemplateSchedulesSuccess,
  loadTemplateSchedulesFailure,

  loadAssociatesSchedule,
  loadAssociatesScheduleRequest,
  loadAssociatesScheduleSuccess,
  loadAssociatesScheduleFailure,
  setAllAssociatesWeekSchedule,

  setSchedules,
  setSchedulesRequest,
  setSchedulesSuccess,
  setSchedulesFailure,
  setTemplateSchedules,
  setTemplateSchedulesRequest,
  setTemplateSchedulesSuccess,
  setTemplateSchedulesFailure,
  showSchedulesModal,
  hideSchedulesModal,
  setAssociateId,
  setTemplateWeekId,
  changeTemplateWeekId,
  setWeeklyEdit,
  setWeeklyEditDayActivity,
  setDailyLunch,
  removeDailyLunch,
  setTemplateWeeklyEdit,
  getTemplateWeeklyEdit,
  copyPreviousDay,
  resetDay,
  copyPreviousWeek,
  resetWeek,
  addPartialDayRelocation,
  goToAssociateTemplate,
  resetDayActivityIds,

  loadLunchBreakTimes,
  loadLunchBreakTimesRequest,
  loadLunchBreakTimesSuccess,
  loadLunchBreakTimesFailure,

  setSchedulesSearchValue,
  setWeeklyEditChanged,

  loadMySchedule,
  loadMyScheduleRequest,
  loadMyScheduleSuccess,
  loadMyScheduleFailure
};
