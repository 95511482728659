// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-layout__cluster {
  --setClusterSpace: ;
  --setClusterJustify: ;
  --setClusterAlign: ;
  overflow: hidden;
}

.psm-layout__cluster > * {
  display: flex;
  flex-wrap: wrap;
  justify-content: var(--setClusterJustify);
  align-items: var(--setClusterAlign);
  margin: calc(var(--setClusterSpace) / 2 * -1);
}

.psm-layout__cluster > * > * {
  margin: calc(var(--setClusterSpace) / 2);
}
`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/components/Layout/Cluster/styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,yCAAyC;EACzC,mCAAmC;EACnC,6CAA6C;AAC/C;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".psm-layout__cluster {\n  --setClusterSpace: ;\n  --setClusterJustify: ;\n  --setClusterAlign: ;\n  overflow: hidden;\n}\n\n.psm-layout__cluster > * {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: var(--setClusterJustify);\n  align-items: var(--setClusterAlign);\n  margin: calc(var(--setClusterSpace) / 2 * -1);\n}\n\n.psm-layout__cluster > * > * {\n  margin: calc(var(--setClusterSpace) / 2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
