import { takeEvery, takeLatest, put, call, all, select, fork } from "redux-saga/effects";
import {
  getBulkPackageOfferings,
  postBulkPackageOfferings,
  putRefundPackage,
} from "../../../core/services/systemServicesBooking/bulkPackageOfferingsEndPoints";
import {
  GET_BULK_PACKAGE_OFFERINGS,
  getBulkPackageOfferingsRequest,
  getBulkPackageOfferingsFailure,
  getBulkPackageOfferingsSuccess,
  POST_BULK_PACKAGE_OFFERINGS,
  postBulkPackageOfferingsRequest,
  postBulkPackageOfferingsSuccess,
  postBulkPackageOfferingsFailure,
  refundPackageRequest,
  refundPackageSuccess,
  refundPackageFailure,
  PUT_REFUND_PACKAGE,
} from "./bulkPackageOfferingsActions";
import {
  getPetServiceIdByConfigId,
  getSelectedProductBundleConfigId,
  getSelectedAddOnId,
  getSelectedEnhancedService,
} from "./bulkPackageOfferingsSelectors";
import { updatePetSuccess } from "../../../core/actionCreators/petsActionCreators";
import { showBookingModal } from "../../../core/actionCreators/bookingActionCreators";
import { getCurrentCustomerKey } from "../../../core/selectors/persistent/customer/customerSelectors";
import { getStoreNumber } from "../../../core/selectors/persistentSelectors";
import { getSelectedPet } from "../../../core/selectors/bookingUISelectors";
import { displayRefundPackageConfirmationModal } from "@/dux/refundPackageConfirmationModal/refundPackageConfirmationModalActions";
import { onGetAvailableBundlesByCustomerForAllPets } from "../availableBundlesByPet/availableBundlesByPetSagas";

// GET BGM offerings -------------------------------------------------------------------------------
function* onGetBulkPackageOfferings({ storeNumber, customerKey, petId, offerType }) {
  try {
    yield put(getBulkPackageOfferingsRequest());

    const response = yield call(getBulkPackageOfferings, {
      storeNumber,
      customerKey,
      petId,
      offerType,
    });
    const { bgmPackages } = response.data.result;

    yield put(
      updatePetSuccess({
        pet: {
          petId: Number(petId),
          bgmPackages,
        },
      }),
    );

    yield put(getBulkPackageOfferingsSuccess());
  } catch (error) {
    yield put(getBulkPackageOfferingsFailure({ error }));
  }
}

// POST BGM offerings ------------------------------------------------------------------------------
function* onPurchaseBulkPackageOffering() {
  try {
    yield put(postBulkPackageOfferingsRequest());
    const storeNumber = yield select(getStoreNumber);
    const customerKey = yield select(getCurrentCustomerKey);
    const petId = yield select(getSelectedPet);
    const primaryServiceId = yield select(getPetServiceIdByConfigId);
    const productBundleConfigId = yield select(getSelectedProductBundleConfigId);
    const selectedAddonId = yield select(getSelectedAddOnId);
    const selectedEnhancedServiceId = yield select(getSelectedEnhancedService);

    const addOns = [selectedAddonId, selectedEnhancedServiceId?.toString()].filter((value) => value);

    const post = yield call(postBulkPackageOfferings, {
      storeNumber,
      customerKey,
      petId,
      data: {
        productBundleConfigId,
        primaryServiceId,
        addOns,
      },
    });

    // open invoice in new browser window
    const { invoiceUrl } = post.data.result;
    const openWindow = window.open(invoiceUrl);
    openWindow.onload = () => openWindow.print();

    // show modal
    yield put(showBookingModal("PURCHASE_BULK_PACKAGE"));

    // success!
    yield put(postBulkPackageOfferingsSuccess());
  } catch (error) {
    yield put(postBulkPackageOfferingsFailure({ error }));
  }
}

// PUT Refund package offering (BGM/FTCO) -------------------------------------------------------------------------------
function* onRefundPackage({ storeNumber, customerKey, bundleConfigId }) {
  try {
    yield put(refundPackageRequest());

    yield call(putRefundPackage, {
      storeNumber,
      customerKey,
      bundleConfigId,
    });

    // We must refresh package offerings after refund in order to reconcile
    // state, since putRefundPackage endpoint doesn't return updated bundle info.
    yield call(onGetAvailableBundlesByCustomerForAllPets, { customerKey });

    yield put(refundPackageSuccess());
    yield put(displayRefundPackageConfirmationModal({ isHidden: true }));
  } catch (error) {
    yield put(refundPackageFailure({ error }));
    yield put(displayRefundPackageConfirmationModal({ isHidden: true }));
  }
}

function* watchLoadBulkPackageOfferings() {
  yield takeEvery([GET_BULK_PACKAGE_OFFERINGS], onGetBulkPackageOfferings);
}

function* watchPostBulkPackageOfferings() {
  yield takeLatest([POST_BULK_PACKAGE_OFFERINGS], onPurchaseBulkPackageOffering);
}

function* watchRefundPackage() {
  yield takeLatest([PUT_REFUND_PACKAGE], onRefundPackage);
}

// Exports -----------------------------------------------------------------------------------------
export default function* bulkPackageOfferingsSaga() {
  yield all([
    fork(watchLoadBulkPackageOfferings),
    fork(watchPostBulkPackageOfferings),
    fork(watchRefundPackage),
  ]);
}
