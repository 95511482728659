import React from "react";
import { truncateStringToLimit } from "@/core/utils/stringUtils/truncateStringUtil";

/**
 * Helper to determine if text should be truncated, and returns the truncated text or an empty string
 * @function
 * @param {*} children - if this isn't a string, then an empty string will be returned
 * @param {number} limit - number of characters to allow in string
 * @returns {string} - truncated string or empty
 */
export const truncateWithEllipsis = (children, limit) => {
  const invalidArgs = isNaN(limit) || typeof children !== "string";
  if (invalidArgs) return "";

  const smallStr = truncateStringToLimit(limit)(children);
  const wasTruncated = smallStr !== children;

  return wasTruncated ? smallStr + "..." : smallStr;
};

/**
 * A wrapper component to hide overflowing text and display ellipsis
 * @function
 * @param {Object} props
 * @param {string} props.children
 * @param {number} props.limit - Optional param to specify the character count
 *        before truncating the text, if not provided then will default to css
 * @returns JSX
 */
export const TextWithEllipsis = ({ children, style, limit, ...props }) => {
  const str = truncateWithEllipsis(children, limit);
  const truncateStyle = str?.length
    ? { wordWrap: "break-word" }
    : { overflow: "hidden", textOverflow: "ellipsis" };

  return (
    <span {...props} style={{ maxWidth: "100%", ...style, ...truncateStyle }}>
      {str?.length ? str : children}
    </span>
  );
};
