import { connect } from "react-redux";
import { getVetToDelete } from "../../../../../../../core/selectors/vetsSelectors";
import petProfileActionCreators from "../../../../../../../core/actionCreators/petProfileActionCreators";
import vetsActionCreators from "../../../../../../../core/actionCreators/vetsActionCreators";
import vetsActionTypes from "../../../../../../../core/actionTypes/vetsActionTypes";
import { createLoadingSelector } from "../../../../../../../core/selectors/utils";
import DeleteVetModalComponent from "./DeleteVetModalComponent";

const mapStateToProps = state => ({
  vetToDelete: getVetToDelete(state),
  isLoading: createLoadingSelector([vetsActionTypes.DELETE_VET])(state)
});

const mapDispatchToProps = dispatch => ({
  handleRemove(vetToRemove) {
    dispatch(
      vetsActionCreators.deleteVet({
        clinicId: vetToRemove
      })
    );
  },
  onClose() {
    dispatch(vetsActionCreators.clearCustomerAndPetKeys());
    dispatch(vetsActionCreators.clearVetToDelete());
    dispatch(petProfileActionCreators.hidePetProfileModal());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteVetModalComponent);
