import * as React from 'react';
import * as CSS from 'csstype';
import { LayoutProps } from '../';
import { psx, pluck } from '../../../utils/';
import { SIDEBAR_TOKENS } from './_tokens';

import './styles.css';

export interface SidebarProps extends LayoutProps<React.ElementType> {
  /** A CSS margin value representing the space between the two elements */
  space?: keyof typeof SIDEBAR_TOKENS['SPACE'];
  /**
   * Represents the width of the sidebar when adjacent. If not set (null) it
   * defaults to the sidebar's content width
   */
  sideWidth?: CSS.Property.FlexBasis;
  /**
   * A CSS percentage value. The minimum width of the content element in the
   * horizontal configuration
   */
  contentMinWidth?: string;
  /** Completely removes the component from the DOM. Useful for feature flags or t hide or show
   * a component. */
  isHidden?: boolean;
}

/**
 * Use the `<Sidebar/>` for responive wrapping of two side by side elements;
 *
 * Where there is enough space, the two elements appear side-by-side.
 * Critically, the sidebar’s width is fixed while the two elements are adjacent,
 * and the non-sidebar takes up the rest of the available space. But when the
 * two elements wrap, each takes up 100% of the shared container.
 *
 * [Source](https://every-layout.dev/layouts/sidebar/)
 */
export const Sidebar: React.FunctionComponent<SidebarProps> = ({
  space = 'sidebar-space-0',
  contentMinWidth = '50%',
  sideWidth = '20rem',
  style = {},
  isHidden = false,
  side = 'left',
  className,
  children,
  testid,
  as,
  ...props
}) => {
  const id = side === 'left' ? 'psm-layout__sidebar' : 'psm-layout__sidebar-right';
  const Component = as || 'div';
  const classNames = psx('psm', id, className);

  if (isHidden) {
    return null;
  }

  return (
    <Component
      className={classNames}
      data-testid={[id, testid].join('-')}
      style={{
        '--setSidebarWidth': sideWidth,
        '--setSidebarContentMinWidth': contentMinWidth,
        '--setSidebarSpace': pluck(SIDEBAR_TOKENS['SPACE'], space),
        ...style,
      }}
      {...props}
    >
      <div>{children}</div>
    </Component>
  );
};
