import { connect } from "react-redux";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { routePaths } from "../../../../core/constants/routePaths";
import getIsBGMWorkflowFeatureFlagHidden from "../../../../web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import BulkPackageOfferingsAccordionComponent from "./BulkPackageOfferingsAccordionComponent";
import getFTCOWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";

const mapStateToProps = (state, { router }) => {
  const pathname = router?.location?.pathname;
  return {
    isHidden:
      (getIsBGMWorkflowFeatureFlagHidden(state) && getFTCOWorkflowFeatureFlagHidden(state)) ||
      pathname.includes(routePaths.BOOKING),
    componentID: "bulk-package-offerings-accordion",
  };
};

export default withRouteProps(connect(mapStateToProps, null)(BulkPackageOfferingsAccordionComponent));
