export default {
  LOAD_SPECIALS: "LOAD_SPECIALS",
  LOAD_SPECIALS_REQUEST: "LOAD_SPECIALS_REQUEST",
  LOAD_SPECIALS_SUCCESS: "LOAD_SPECIALS_SUCCESS",
  LOAD_SPECIALS_FAILURE: "LOAD_SPECIALS_FAILURE",
  POST_SPECIALS: "POST_SPECIALS",
  POST_SPECIALS_REQUEST: "POST_SPECIALS_REQUEST",
  POST_SPECIALS_SUCCESS: "POST_SPECIALS_SUCCESS",
  POST_SPECIALS_FAILURE: "POST_SPECIALS_FAILURE",
  REMOVE_SPECIALS: "REMOVE_SPECIALS",
  REMOVE_SPECIALS_REQUEST: "REMOVE_SPECIALS_REQUEST",
  REMOVE_SPECIALS_SUCCESS: "REMOVE_SPECIALS_SUCCESS",
  REMOVE_SPECIALS_FAILURE: "REMOVE_SPECIALS_FAILURE"
};
