import { SHOW_HIDE_HOTEL_CAPACITY_MODAL, SHOW_HIDE_HOTEL_CAPACITY_DRAWER } from "./hotelRoomCapacityModalActions";

const initialState = { isHidden: true, isDrawerOpen: false };

const hotelRoomCapacityModalReducer = (state = initialState, action) => {
  switch(action.type) {
    case SHOW_HIDE_HOTEL_CAPACITY_MODAL:
      return {
        ...state,
        isHidden: action.isHidden,
      }
    case SHOW_HIDE_HOTEL_CAPACITY_DRAWER:
      return {
        ...state,
        isDrawerOpen: action.isDrawerOpen,
      }
    default:
      return state;
  }
};

export default hotelRoomCapacityModalReducer;
