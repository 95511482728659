import React from "react";

export const CheckMarkSVG = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <circle id="b" cx="8" cy="8" r="8" />
        <filter
          id="a"
          width="181.2%"
          height="181.2%"
          x="-34.4%"
          y="-40.6%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dx="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.0901960784 0 0 0 0 0.470588235 0 0 0 0 0.949019608 0 0 0 0.222882699 0"
          />
        </filter>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(3 4)">
          <use fill="#000" filter="url(#a)" xlinkHref="#b" />
          <use fill={color} xlinkHref="#b" />
        </g>
        <path stroke="#fff" stroke-width="1.6" d="M7.8 12.287l2.298 2.38 4.635-4.8" />
      </g>
    </svg>
  );
};
