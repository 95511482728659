// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.9.1_webpack@5.91.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psm-layout__stack {
  --setStackSpace: ;
  display: flex;
  flex-direction: column;
}

.psm-layout__stack > * {
  margin-top: 0;
  margin-bottom: 0;
}

.psm-layout__stack > * + * {
  margin-top: var(--setStackSpace);
}

.stack-flip {
  display: flex;
  flex-direction: row;
}

.stack-flip > * {
  height: 100%;
}

.stack-flip > * + * {
  margin-left: var(--setStackSpace);
}
`, "",{"version":3,"sources":["webpack://./../psm-ui-components/src/components/Layout/Stack/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":[".psm-layout__stack {\n  --setStackSpace: ;\n  display: flex;\n  flex-direction: column;\n}\n\n.psm-layout__stack > * {\n  margin-top: 0;\n  margin-bottom: 0;\n}\n\n.psm-layout__stack > * + * {\n  margin-top: var(--setStackSpace);\n}\n\n.stack-flip {\n  display: flex;\n  flex-direction: row;\n}\n\n.stack-flip > * {\n  height: 100%;\n}\n\n.stack-flip > * + * {\n  margin-left: var(--setStackSpace);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
