import React from "react";
import styled from "styled-components";
import { color, font } from "../../common/styles/theme";
import { fontSizes } from "web/common/styles/responsive/fonts";
import { TextWithEllipsis } from "@/dux/_components/textWithEllipsis/TextWithEllipsis";
import { preferredNameConstants } from "@/core/constants/associatesConstants";
// Styles via styled-components
const SectionWrapper = styled.section`
  overflow: hidden;
`;

const StyledGroomerName = styled(TextWithEllipsis)`
  font-family: ${font.mainFamily};
  font-size: ${fontSizes.medium};
  color: ${color.tChalla};
`;

export default function GroomerName(props) {
  // Component JSX
  const { associate, componentId } = props;
  return (
    <SectionWrapper id={componentId}>
      {associate && (
        <StyledGroomerName
          id={`${componentId}--${associate.associateName}`}
          limit={preferredNameConstants.limit}
        >
          {associate.associateName}
        </StyledGroomerName>
      )}
    </SectionWrapper>
  );
}
