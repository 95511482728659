import { createSelector } from "reselect";
import { find } from "lodash/fp";
import getPrismPetState from "../../prismPet/prismPetSelector";
import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";

/**
 * Responsible for returning the prismPet.profiles state in the Redux store.
 * requires petId, e.g. const petHotelProfile = getPrismPetHotelProfile(state, { petId });
 */
const getPrismPetHotelProfile = createSelector([getPrismPetState], prismPet => {
  const { profiles } = prismPet;
  return find({ profileType: systemName.DDC_HOTEL }, profiles);
});

export default getPrismPetHotelProfile;
