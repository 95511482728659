import { set } from "lodash/fp";

import { eligibilityPetActionTypes } from "dux/eligibility/actions/eligibilityPetActions";
import { eligibilityCustomerActionTypes } from "dux/eligibility/actions/eligibilityCustomerActions";
import { eligibilityLoadClearActionTypes } from "dux/eligibility/actions/eligibilityLoadClearActions";

const initialState = {};

const eligibilityReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * May Trigger on:
     * Pet Parent Profile Page
     * Standalone Page
     * Booking page
     * When an appointment is set in the Booking page.

     * loads Customer and Pet data, example:
         customer: {
            canBook: true,
            customerKey: 1000002745,
            reasons: [{…}],
          },
         pets: {
            1000001555: {petId: 1000001555, canBook: true, reasons: Array(3)},
            1000001556: {petId: 1000001556, canBook: true, reasons: Array(1)},
          }
     */
    case eligibilityLoadClearActionTypes.LOAD_ELIGIBILITY_RESULTS:
      return action.results;

    /**
     * May trigger on:
     * When an appointment is set ( in the Booking page.

     * loads Customer and Pet data example:
          {petId: 1000001555, canBook: true, reasons: Array(3)}
     */
    case eligibilityPetActionTypes.GET_PET_ELIGIBILITY_SUCCESS:
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        pets: set([action.petId], action.result, state.pets),
      };

    /**
     * Seems to currently be hotel specific as of 5/20/2020 .jc
     */
    case eligibilityCustomerActionTypes.LOAD_CUSTOMER_ELIGIBILITY_SUCCESS:
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        customer: action.result,
      };

    /**
     * May trigger on:
     *  customer search.
     */
    case eligibilityCustomerActionTypes.CLEAR_ELIGIBILITY_RESULTS:
      return {};
    default:
      return state;
  }
};

export default eligibilityReducer;
