//@ts-check
import { createSelector } from "reselect";
/**
 *
 * @param {object} state
 * @param {object} [state.hotelRoomManagementDetailsDatePickers]
 * @param {string} state.hotelRoomManagementDetailsDatePickers.startDate
 * @param {string} state.hotelRoomManagementDetailsDatePickers.endDate
 * @returns
 */

export const selectHotelManagementDetailsDatePickers = state =>
  state?.hotelRoomManagementDetailsDatePickers;

export const selectHotelManagementDetailsStartDate = createSelector(
  selectHotelManagementDetailsDatePickers,
  selectedDates => {
    return selectedDates?.startDate;
  },
);

export const selectHotelManagementDetailsEndDate = createSelector(
  selectHotelManagementDetailsDatePickers,
  selectedDates => {
    return selectedDates?.endDate;
  },
);
