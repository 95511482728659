import React from "react";
import styled from "styled-components";
import GanttGroup from "../GanttGroup";

const StyledGanttBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const GanttBodyComponent = props => {
  const {
    groups,
    GroupMemberComponent,
    isWeekly,
    timeOffset,
    businessHours,
    availableAppointments
  } = props;

  return (
    <StyledGanttBody>
      {Object.keys(groups).map((group, index) => {
        const isFirstGroup = index === 0;

        return (
          <GanttGroup
            key={group}
            isWeekly={isWeekly}
            timeOffset={timeOffset}
            groupName={group}
            availableAppointments={availableAppointments}
            group={groups[group]}
            isFirstGroup={isFirstGroup}
            GroupMemberComponent={GroupMemberComponent}
            hours={businessHours}
          />
        );
      })}
    </StyledGanttBody>
  );
};

export default GanttBodyComponent;
