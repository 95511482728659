/** @module logByGroup */

/**
 * Will log to the console a grouped set of statements, providing the  key name, or array index along with a
 * key or array value, and type value.
 *
 * @export logGroupByObject
 * @param {string} name - Name of your logging group
 * @param {(array|object)} logList - List of the items you want to log, can be either an array or an object literal
 * @example
 * - logByGroup('Log By Object', {foobar, baz, myArray, myObj});
 * - logByGroup('Log By Array', ['A string', 3, [1,2,3,4], {"someKey": 'some value'}]);
 */
export default function logByGroup(name, logList) {
  const titleStyles = "color: grey; font-weight: normal;";
  const nameStyles = "color: inherit; ont-weight: bold;";
  const objectEntries = Object.entries(logList);

  console.group(`${" %clog group " + "%c"}${name.toUpperCase()}`, titleStyles, nameStyles);

  objectEntries.map((item, indx) => {
    console.group(`%c${item[0]}'s Info`, "color: #1f95d1 ;"); // #0574f5
    console.log("%cValue is:: ", "color: #50ad55;", item[1]);
    console.log(
      "%cType is of::",
      "color: #50ad55;",
      `${Array.isArray(item[1]) ? "array" : typeof item[1]}`
    );
    console.groupEnd();
  });

  console.groupEnd();
}
