import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { LayoutStack } from "@/layout/stack/Stack";
import { Button } from "@petsmart-ui/sparky";
import LoadingWrapper from "@/web/common/LoadingWrapper";

// Local Components
import { OverBookingExceptionTableRow } from "./OverBookingExcepitonDetailTableRow";
import { HotelOverbookingExceptionsHistoryModal } from "../hotelOverbookingExceptionsHistory/HotelOverbookingExceptionsHistory";

// Actions
import {
  GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS,
  CANCEL_OVERBOOKING_EXCEPTION,
  getOverBookingExceptionSuspensionDetails,
} from "@/dux/overbookingExceptionTableDetails/overBookingExceptionTableDetailsActions";

// Selectors
import { getSuspensionDetails } from "@/dux/overbookingExceptionTableDetails/overBookingExceptionTableDetailsSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";

// Utils
import { sortByPropertyAsc, sortByPropertyDesc } from "@/core/utils/arrayUtils/sortByProperty";

// Constants
import {
  EXCEPTION_TABLE_SIZE,
  exceptionTableHeaderColumns,
  exceptionTableHeaderColumnKeys,
} from "./overBookingExceptionTableDetailsConstants";

// Svg's
import UpArrowSvg from "@/dux/_components/upArrowSvg/UpArrowSvg";
import DownArrowSvg from "@/dux/_components/downArrowSvg/DownArrowSvg";

export const ExceptionTableDetails = props => {
  const {
    componentId,
    isHidden,
    setUpTableColumns,
    getHotelRoomTypes,
    suspensionDetails,
    isLoading,
  } = props;

  useEffect(() => {
    getHotelRoomTypes();
  }, [getHotelRoomTypes]);

  useEffect(() => {
    setSortedData(suspensionDetails);
  }, [suspensionDetails]);

  const [sortedData, setSortedData] = useState(suspensionDetails);

  if (isHidden) {
    return null;
  }

  // Handlers
  const getSortValueByTitle = ({ columnName }) => {
    switch (columnName) {
      case setUpTableColumns[0]:
        return exceptionTableHeaderColumnKeys[0];
      case setUpTableColumns[1]:
        return exceptionTableHeaderColumnKeys[1];
      case setUpTableColumns[2]:
        return exceptionTableHeaderColumnKeys[2];
      case setUpTableColumns[3]:
        return exceptionTableHeaderColumnKeys[3];
      case setUpTableColumns[4]:
        return exceptionTableHeaderColumnKeys[4];
      default:
        return exceptionTableHeaderColumnKeys[0];
    }
  };

  // Handler to sort any column by Asc order
  const handleSortASC = ({ columnName }) => {
    const key = getSortValueByTitle({ columnName });
    const ascTableData = sortByPropertyAsc(sortedData, key);

    setSortedData([...ascTableData]);
  };

  // Handler to sort any column by Desc order
  const handleSortDESC = ({ columnName }) => {
    const key = getSortValueByTitle({ columnName });
    const descTableData = sortByPropertyDesc(sortedData, key);

    setSortedData([...descTableData]);
  };

  return (
    <LoadingWrapper isLoading={isLoading}>
      <LayoutBox id={componentId} padding="scale-0">
        <table
          id={`${componentId}__table`}
          style={{
            borderCollapse: "collapse",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <thead>
            <tr
              style={{
                borderBottom: "1px solid gray",
                textAlign: "left",
              }}
            >
              {setUpTableColumns?.map((columnName, index, { length }) => (
                <th
                  style={{ padding: "20px", width: index === 1 ? "300px" : "200px" }}
                  key={columnName}
                >
                  <LayoutCluster space="scale-G1">
                    <div style={{ marginLeft: 0 }}>{columnName}</div>
                    {index + 1 !== length && (
                      <LayoutStack space="scale-0">
                        <Button
                          onClick={() => handleSortASC({ columnName })}
                          text={<UpArrowSvg />}
                          variant="bare"
                          size="sm"
                        />
                        <Button
                          onClick={() => handleSortDESC({ columnName })}
                          text={<DownArrowSvg />}
                          variant="bare"
                          size="sm"
                        />
                      </LayoutStack>
                    )}
                  </LayoutCluster>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData?.map((room, index) => (
              <OverBookingExceptionTableRow room={room} key={index} />
            ))}
          </tbody>
        </table>
        <HotelOverbookingExceptionsHistoryModal />
      </LayoutBox>
    </LoadingWrapper>
  );
};

export const OverBookingExceptionTableDetails = connect(
  state => {
    return {
      componentId: "OverBookingExceptionTable",
      isHidden: false,
      setUpTableColumns: exceptionTableHeaderColumns,
      suspensionDetails: getSuspensionDetails(state),
      isLoading: createLoadingSelector([
        GET_OVER_BOOKING_EXCEPTION_SUSPENSION_DETAILS,
        CANCEL_OVERBOOKING_EXCEPTION,
      ])(state),
    };
  },
  dispatch => {
    return {
      getHotelRoomTypes: () =>
        dispatch(getOverBookingExceptionSuspensionDetails({ page: 0, size: EXCEPTION_TABLE_SIZE })),
    };
  },
)(ExceptionTableDetails);
