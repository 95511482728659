import getCookieValueOrNull from "core/utils/cookies/cookieValueOrNull";

/**
 * Toggles the value of the cookie
 *
 * @export toggleCookie
 * @param { string } cookieName - the name of the cooking including the equal sign, e.g. persistFF=
 * @returns {Boolean} - the value the cooke has been set to.
 */
export default function toggleCookie(cookieName) {
  if (getCookieValueOrNull(cookieName)) {
    document.cookie = `${cookieName}false`;
    return false;
  } else {
    document.cookie = `${cookieName}true`;
    return true;
  }
}
