// cc: QuickViewAppointment#0;Start;Dispatch 'Show QuickView' Action

import { connect } from "react-redux";

import { hideQuickView, showQuickView } from "dux/quickViews/quickViewActions";
import {
  getSelectedAppointment,
  getSelectedAssociate,
} from "../../../core/selectors/commonSelector";
import GanttAppointmentComponent from "./GanttAppointmentComponent";
import commonActionCreators from "../../../core/actionCreators/ui/web/commonActionCreators";
import { compareTimeSlots } from "../../../core/utils/bookingUtils";

// Booking
import {
  selectHoveredTimeSlot,
  bookAppointment,
} from "../../../core/actionCreators/bookingActionCreators";
import { getSelectedPet, getHoveredTimeSlot } from "../../../core/selectors/bookingUISelectors";
import {
  getSelectedPetServiceItem,
  getAppointmentByPet,
} from "../../../core/selectors/cartSelectors";
import { getPetById } from "../../../core/selectors/entitiesSelector";
import { alerts } from "../../../core/utils/iconUtils/iconUtils";

const mapStateToProps = (state, ownProps) => {
  const bookingSelectedPet = getSelectedPet(state);
  const selectedAppointment = getSelectedPetServiceItem(state, { petId: bookingSelectedPet });
  // const selectedTimeSlot = selectedAppointment && selectedAppointment.timeSlot;
  const selectedCartAppointment = getAppointmentByPet(state, { petId: bookingSelectedPet });
  const selectedPetService = selectedCartAppointment && selectedCartAppointment.petService;
  const hoveredTimeSlot = getHoveredTimeSlot(state);
  const pet = getPetById(state, ownProps.appointment.pet);
  const speciesId = pet && pet.speciesId;

  return {
    appointment: ownProps.appointment,
    lockToAssociate: ownProps.appointment.lockToAssociate && ownProps.appointment.lockToAssociate,
    hasRatingAlert: pet && pet.petRatings && alerts(pet),
    speciesId,
    isHovered:
      hoveredTimeSlot &&
      !ownProps.appointment.petServiceItemId &&
      compareTimeSlots(hoveredTimeSlot, ownProps.appointment),
    isBooked: selectedAppointment && ownProps.appointment.petServiceItemId === selectedAppointment,
    isSelected: getSelectedAppointment(state)
      ? Number(getSelectedAppointment(state)) === Number(ownProps.appointment.petServiceItemId)
      : Number(getSelectedAssociate(state)) === Number(ownProps.associateId),
    selectedPetService,
    bookingSelectedPet,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    appointment,
    router: {
      params: { customerKey },
    },
  } = ownProps;
  return {
    onSelectAppointment: () => {
      dispatch(
        commonActionCreators.onSelectAppointment({
          appointmentId: appointment.petServiceItemId || "NEW_APPOINTMENT",
        }),
      );
    },
    onClearAppointment: () => {
      dispatch(commonActionCreators.onSelectAppointment({ appointmentId: "" }));
    },
    onSelectTimeSlot: ({ selectedPetService }) => {
      dispatch(bookAppointment({ timeSlot: appointment, customerKey, selectedPetService }));
      // dispatch(selectTimeSlot({ timeSlot: appointment, petId }));
    },
    onHoverTimeSlot: ({ petId }) => {
      dispatch(selectHoveredTimeSlot({ timeSlot: appointment, petId }));
    },
    onClearHoverTimeSlot: ({ petId }) => {
      dispatch(selectHoveredTimeSlot({ timeSlot: "", petId }));
    },

    onHoverAppointment: (xLoc, yLoc) => {
      if (appointment.petServiceItemId) {
        dispatch(
          showQuickView({
            xLoc,
            yLoc,
            petServiceItemId: appointment.petServiceItemId,
          }),
        );
      }
    },

    onLeaveAppointment: () => dispatch(hideQuickView()),
  };
};

// can't access the store or router within the canvas, so exposing 
// mapStateToProps & mapDispatchToProps for parent component to use
// and pass down to GanttAppointmentComponent
export const getAppointmentStateProps = mapStateToProps;
export const getAppointmentDispatchProps = mapDispatchToProps;
