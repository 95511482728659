import { connect } from "react-redux";
import DeleteAddressButtonComponent from "./DeleteAddressButtonComponent";
import { showConfirmationModal } from "../../../core/actionCreators/confirmationModalActionCreators";
import customersActionCreators from "../../../core/actionCreators/customersActionCreators";
import getIsDeleteAddressButtonHidden from "../../../core/selectors/customer/address/getIsDeleteAddressButtonHidden";

const mapStateToProps = (state, { customerKey, addressId }) => {
  return {
    isHidden: getIsDeleteAddressButtonHidden(state, { customerKey, addressId }),
    isRightAligned: true
  };
};

const mapDispatchToProps = (dispatch, { customerKey, addressId }) => ({
  onClick: () => {
    dispatch(
      showConfirmationModal({
        header: "Delete address",
        content: "Are you sure you want to delete this address?",
        confirmText: "Delete",
        confirm: () =>
          dispatch(
            customersActionCreators.deleteCustomerAddress({
              customerKey,
              addressId
            })
          ),
        cancelText: "Cancel"
      })
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAddressButtonComponent);
