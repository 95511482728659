import React from "react";
import styled from "styled-components";
import Select from "../Select";
import Input from "../Input";
import { capitalize } from "lodash/fp";

import StyledPopover from "../StyledPopover";
import { SelectionCopy } from "../customFlyout/customFlyoutSelection";
import AssociateDropdownRow from "./AssociateDropdownRow";
import { associateGroupConstants } from "../../../core/constants/associatesConstants";

const StyledAssociateDropdown = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

const SelectContainer = styled.div`
  width: 100px;
  height: 60px;
`;

const HeaderTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const SearchContainer = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledInput = styled(Input)`
  margin-bottom: 12px;
`;

const AssociateRows = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 18.75rem;
  overflow: auto;
`;

const StyledAssociateDropdownHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.25em;
`;

export default class AssociateDropdownComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedGroup: associateGroupConstants.ALL,
      searchValue: "",
    };
  }

  componentDidUpdate() {
    const { isOpen, searchValue, selectedGroup } = this.state;
    if (!this.state.isOpen) {
      if (selectedGroup !== associateGroupConstants.ALL) {
        this.setState({
          selectedGroup: associateGroupConstants.ALL,
        });
      }
      if (searchValue) {
        this.setState({
          searchValue: "",
        });
      }
    }
  }

  handleDropdownClick = () => {
    const { isOpen } = this.state;
    const { showModalBeforeOpen } = this.props;

    if (!isOpen && showModalBeforeOpen) {
      showModalBeforeOpen(() => this.setState({ isOpen: true }));
    } else {
      this.setState({ isOpen: !isOpen });
    }
  };

  renderAssociateDropdown = () => {
    const {
      onSelectAssociate,
      selectedAssociate,
      associatesByGroup,
      includeAllSuitable = true,
    } = this.props;
    const { selectedGroup, searchValue } = this.state;

    const options = Object.keys(associatesByGroup).map(group => ({
      value: group,
      label: `${capitalize(group)}${group !== associateGroupConstants.ALL ? "s" : ""}`,
    }));

    return (
      <StyledAssociateDropdown>
        <StyledAssociateDropdownHeader>
          <SelectContainer>
            <HeaderTitle>Group Type</HeaderTitle>
            <Select
              options={options}
              value={options.find(option => option.value === selectedGroup)}
              onChange={({ value }) => this.setState({ selectedGroup: value })}
            />
          </SelectContainer>
          <SearchContainer>
            <HeaderTitle>Search</HeaderTitle>
            <StyledInput
              value={searchValue}
              onChange={e => this.setState({ searchValue: e.target.value })}
            />
          </SearchContainer>
        </StyledAssociateDropdownHeader>
        <AssociateRows>
          <div>
            {includeAllSuitable && (
              <AssociateDropdownRow
                isSelected={!selectedAssociate}
                onClick={() => {
                  this.setState({ isOpen: false });
                  onSelectAssociate && onSelectAssociate(null);
                }}
                name="All suitable"
              />
            )}
            {associatesByGroup[selectedGroup]
              .filter(({ associateName }) => {
                if (!searchValue) {
                  return true;
                }
                return associateName.toLowerCase().includes(searchValue.toLowerCase());
              })
              .map(({ associateId, associateName, associateGroup }) => {
                associateId = Number(associateId);
                return (
                  <AssociateDropdownRow
                    isSelected={selectedAssociate && associateId === selectedAssociate}
                    onClick={() => {
                      this.setState({ isOpen: false });
                      onSelectAssociate && onSelectAssociate(associateId);
                    }}
                    name={`${associateName}, `}
                    role={associateGroup}
                    key={associateId}
                  />
                );
              })}
          </div>
        </AssociateRows>
      </StyledAssociateDropdown>
    );
  };

  render() {
    const { associates, selectedAssociate, disabled, isHidden } = this.props;
    const { isOpen } = this.state;

    if (isHidden) {
      return null;
    }

    return (
      <StyledPopover
        body={this.renderAssociateDropdown()}
        isOpen={isOpen}
        place="below"
        onOuterAction={() => this.setState({ isOpen: false })}
        tipSize={7}
      >
        <SelectionCopy onClick={this.handleDropdownClick} isEnabled={!disabled}>
          {selectedAssociate && associates[selectedAssociate]
            ? associates[selectedAssociate].associateName
            : "Associate"}
        </SelectionCopy>
      </StyledPopover>
    );
  }
}
