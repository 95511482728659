/**
 * Represents the starting date (range) for the list of Suspensions for a store
 * - represents the number of months prior from today's date
 *
 * @memberof Constants.Suspensions
 * @type {number}
 */
export const SuspensionDateRangeStart = 1;

/**
 * Represents the end date (range) for the list of Suspensions for a store
 * - represents the number of months after today's date
 *
 * @memberof Constants.Suspensions
 * @type {number}
 */
export const SuspensionDateRangeEnd = 18;
