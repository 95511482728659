import React from "react";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutBox } from "@/layout/box/Box";
import { color } from "@/web/common/styles/theme";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { Heading } from "@petsmart-ui/sparky";
import Checkbox from "../../../bgm/bulkPackageOfferings/addOns/bulkPackageAddOnsCheckboxContainer";
import LoadingWrapper from "../../../../web/common/LoadingWrapper";

export default function BulkPackageOfferingsAddOnsComponent({
  title,
  componentID,
  items = [],
  isLoading,
  isHidden,
  message,
}) {
  if (isHidden) return null;
  if (isLoading) return <LoadingWrapper isLoading={isLoading} />;

  // checking the length of the items prevents showing a header and empty content when no data loads
  return (
    Boolean(items.length) && (
      <LayoutStack
        id={componentID}
        data-testid="BulkPackageOfferingsAddOnsComponent"
        space="scale-G1"
        style={{ marginTop: "30px" }}
      >
        <LayoutCluster space="scale-G2">
          <LayoutBox padding="scale-0">
            <Heading tagName="h3" size="headline">
              {title}
            </Heading>
          </LayoutBox>
          <LayoutBox padding="scale-0" style={{ color: color.errorRed }}>
            {message}
          </LayoutBox>
        </LayoutCluster>
        {items.map(({ addOnId, addOnName, price }) => (
          <LayoutBox padding="scale-0">
            <LayoutCluster
              key={addOnId}
              flexWrap="flex-noWrap"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <LayoutBox padding="scale-0">
                <LayoutCluster flexWrap="flex-noWrap">
                  <Checkbox itemId={addOnId} />
                  <LayoutBox padding="scale-0">{addOnName}</LayoutBox>
                </LayoutCluster>
              </LayoutBox>
              <LayoutBox style={{ textAlign: "right" }} padding="scale-0">
                {`$${price?.toFixed(2)}`}
              </LayoutBox>
            </LayoutCluster>
          </LayoutBox>
        ))}
      </LayoutStack>
    )
  );
}
