import { connect } from "react-redux";
import { getSalonHoursRange } from "../../core/selectors/salonHoursSelector";
import { getStartWeekDate, getSchedulesSearchValue } from "../../core/selectors/schedulesSelectors";
import { setSchedulesSearchValue } from "../../core/actionCreators/schedulesActionCreators";
import ColumnTitlesComponent from "./ColumnTitlesComponent";

const mapStateToProps = state => ({
  startWeekDate: getStartWeekDate(state),
  salonHoursRange: getSalonHoursRange(state),
  searchValue: getSchedulesSearchValue(state)
});

const mapDispatchToProps = dispatch => ({
  setSchedulesSearchValue: searchValue => dispatch(setSchedulesSearchValue({ searchValue }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColumnTitlesComponent);
