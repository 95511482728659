import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { color } from "../../common/styles/theme";
import { pageNames } from "../../../core/constants/checkInOutConstants";
import { getTypeFromStatus } from "../../../core/utils/appointmentUtils";
import PetInfoContainer from "../petInfo/petInfoContainer";

const SectionWrapper = styled.section`
  display: flex;
  padding: 0.8rem;

  /*  Default behavior should be a border; however there some cases where there should be o border
      on the petCardComponent, so we add a prop for a hideBorder. most cases it will be undefined, thus
      falsy e.g. show border, however if true then the border will be hidden.*/
  border: ${props =>
    props.hideBorder
      ? "none"
      : `1px solid ${props.isSelectedAppointment ? color.kalEl : color.ororoMunroe}`};

  &:hover {
    border: 1px solid ${color.kalEl};
  }
`;

function PetCard(props) {
  const {
    appointmentID,
    associateId,
    customerKey,
    itineraryId,
    dispatchOnSelectAssociate,
    dispatchOnSelectAppointment,
    setPetServiceItem,
    setCurrentCustomerKey,
    showDate,
    showStatus,
    type,
    status,
    fromDashboard,
    fromPetParent,
    isSelectedAppointment,
    showErrorModal,
    hideModal,
    fromGantt,
    hideBorder,
    shouldShowCustomerPhone,
    selectPet,
    selectedPet,
    componentId,
  } = props;

  const navigate = useNavigate();

  const pageType = type || getTypeFromStatus(status);
  let pageName;

  switch (pageType) {
    case "Upcoming":
      pageName = pageNames.CHECK_IN;
      break;

    case "Here":
      pageName = pageNames.CHECK_OUT;
      break;

    default:
      pageName = pageNames.PAST_APPOINTMENT;
      break;
  }

  const cursorStyle = !fromDashboard ? 'not-allowed': 'pointer';
  const noop = () => {};

  return (
    <SectionWrapper
      style={{cursor: cursorStyle}}
      id={componentId}
      hideBorder={hideBorder} // Most cases this wil be undefined thus falsy
      isSelectedAppointment={isSelectedAppointment}
      onClick={!fromDashboard ? noop : () => {
        selectPet(selectedPet);

        if (fromDashboard) {
          dispatchOnSelectAssociate({ associateId: "" });
          setCurrentCustomerKey(customerKey);
          setPetServiceItem(Number(appointmentID));

          if (pageType) {
            if (fromGantt) {
              hideModal();
            }

            navigate(`/${pageName}/${customerKey}/${itineraryId}`);
          }
        } else if (fromPetParent) {
          setPetServiceItem(Number(appointmentID));
          if (pageType) {
            navigate(`/${pageType}-appointment/${customerKey}/${itineraryId}`);
          }
        }
      }}
      onMouseEnter={() => {
        if (fromDashboard) {
          dispatchOnSelectAssociate({ associateId });
          dispatchOnSelectAppointment({ appointmentId: appointmentID });
        }
      }}
      onMouseLeave={() => {
        if (fromDashboard) {
          dispatchOnSelectAssociate({ associateId: "" });
          dispatchOnSelectAppointment({ appointmentId: "" });
        }
      }}
    >
      <PetInfoContainer
        shouldShowCustomerPhone={shouldShowCustomerPhone}
        petServiceItemId={appointmentID}
        showDate={showDate}
        showStatus={showStatus}
        type={type}
        showErrorModal={showErrorModal}
      />
    </SectionWrapper>
  );
}

export default PetCard;
