import { petsmartWebPublicAPIV2 } from "./_axiosConfigPetMWebPubic";

/**
 * @summary - Fetch V2 medications list
 * @method getMedicationsListEndpoint
 * @returns {Object}
 */
export const getMedicationsListEndpoint = () => {
  return petsmartWebPublicAPIV2.get("/pets/medications");
};
