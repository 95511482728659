import { connect } from "react-redux";
import SearchSalon from "./searchSalonComponent";
import { getJobRole } from "../../core/selectors/persistentSelectors";
import { setSearchComponentName } from "../../core/actionCreators/searchSalonActionCreator";

const mapStateToProps = state => ({
  jobRole: getJobRole(state)
});

const mapDispatchToProps = dispatch => ({
  setSearchComponentName: searchComponentName =>
    dispatch(setSearchComponentName({ searchComponentName }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchSalon);
