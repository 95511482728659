export default {
  LOAD_CUSTOMER: "LOAD_CUSTOMER",
  LOAD_CUSTOMER_REQUEST: "LOAD_CUSTOMER_REQUEST",
  LOAD_CUSTOMER_SUCCESS: "LOAD_CUSTOMER_SUCCESS",
  LOAD_CUSTOMER_FAILURE: "LOAD_CUSTOMER_FAILURE",
  LOAD_CUSTOMER_CLEAR_ERRORS: "LOAD_CUSTOMER_CLEAR_ERRORS",

  UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
  UPDATE_CUSTOMER_REQUEST: "UPDATE_CUSTOMER_REQUEST",
  UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_FAILURE: "UPDATE_CUSTOMER_FAILURE",

  CREATE_CUSTOMER: "CREATE_CUSTOMER",
  CREATE_CUSTOMER_REQUEST: "CREATE_CUSTOMER_REQUEST",
  CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
  CREATE_CUSTOMER_FAILURE: "CREATE_CUSTOMER_FAILURE",

  LOAD_CUSTOMERS: "LOAD_CUSTOMERS",
  LOAD_CUSTOMERS_REQUEST: "LOAD_CUSTOMERS_REQUEST",
  LOAD_CUSTOMERS_SUCCESS: "LOAD_CUSTOMERS_SUCCESS",
  LOAD_CUSTOMERS_FAILURE: "LOAD_CUSTOMERS_FAILURE",

  CREATE_CUSTOMER_PHONE: "CREATE_CUSTOMER_PHONE",
  CREATE_CUSTOMER_PHONE_REQUEST: "CREATE_CUSTOMER_PHONE_REQUEST",
  CREATE_CUSTOMER_PHONE_SUCCESS: "CREATE_CUSTOMER_PHONE_SUCCESS",
  CREATE_CUSTOMER_PHONE_FAILURE: "CREATE_CUSTOMER_PHONE_FAILURE",

  UPDATE_CUSTOMER_PHONE: "UPDATE_CUSTOMER_PHONE",
  UPDATE_CUSTOMER_PHONE_REQUEST: "UPDATE_CUSTOMER_PHONE_REQUEST",
  UPDATE_CUSTOMER_PHONE_SUCCESS: "UPDATE_CUSTOMER_PHONE_SUCCESS",
  UPDATE_CUSTOMER_PHONE_FAILURE: "UPDATE_CUSTOMER_PHONE_FAILURE",

  CREATE_CUSTOMER_EMAIL: "CREATE_CUSTOMER_EMAIL",
  CREATE_CUSTOMER_EMAIL_REQUEST: "CREATE_CUSTOMER_EMAIL_REQUEST",
  CREATE_CUSTOMER_EMAIL_SUCCESS: "CREATE_CUSTOMER_EMAIL_SUCCESS",
  CREATE_CUSTOMER_EMAIL_FAILURE: "CREATE_CUSTOMER_EMAIL_FAILURE",

  UPDATE_CUSTOMER_EMAIL: "UPDATE_CUSTOMER_EMAIL",
  UPDATE_CUSTOMER_EMAIL_REQUEST: "UPDATE_CUSTOMER_EMAIL_REQUEST",
  UPDATE_CUSTOMER_EMAIL_SUCCESS: "UPDATE_CUSTOMER_EMAIL_SUCCESS",
  UPDATE_CUSTOMER_EMAIL_FAILURE: "UPDATE_CUSTOMER_EMAIL_FAILURE",

  DELETE_CUSTOMER_EMAIL: "DELETE_CUSTOMER_EMAIL",
  DELETE_CUSTOMER_EMAIL_REQUEST: "DELETE_CUSTOMER_EMAIL_REQUEST",
  DELETE_CUSTOMER_EMAIL_SUCCESS: "DELETE_CUSTOMER_EMAIL_SUCCESS",
  DELETE_CUSTOMER_EMAIL_FAILURE: "DELETE_CUSTOMER_EMAIL_FAILURE",

  UPDATE_CUSTOMER_EMAIL_OPTED_OUT: "UPDATE_CUSTOMER_EMAIL_OPTED_OUT",
  UPDATE_CUSTOMER_EMAIL_OPTED_OUT_REQUEST: "UPDATE_CUSTOMER_EMAIL_OPTED_OUT_REQUEST",
  UPDATE_CUSTOMER_EMAIL_OPTED_OUT_SUCCESS: "UPDATE_CUSTOMER_EMAIL_OPTED_OUT_SUCCESS",
  UPDATE_CUSTOMER_EMAIL_OPTED_OUT_FAILURE: "UPDATE_CUSTOMER_EMAIL_OPTED_OUT_FAILURE",

  UPDATE_CUSTOMER_PROFILE: "UPDATE_CUSTOMER_PROFILE",
  UPDATE_CUSTOMER_PROFILE_REQUEST: "UPDATE_CUSTOMER_PROFILE_REQUEST",
  UPDATE_CUSTOMER_PROFILE_SUCCESS: "UPDATE_CUSTOMER_PROFILE_SUCCESS",
  UPDATE_CUSTOMER_PROFILE_FAILURE: "UPDATE_CUSTOMER_PROFILE_FAILURE",
  UPDATE_CUSTOMER_PROFILE_CLEAR_ERRORS: "UPDATE_CUSTOMER_PROFILE_CLEAR_ERRORS",

  UPDATE_CUSTOMER_CONTACT_SETTINGS: "UPDATE_CUSTOMER_CONTACT_SETTINGS",
  UPDATE_CUSTOMER_CONTACT_SETTINGS_REQUEST: "UPDATE_CUSTOMER_CONTACT_SETTINGS_REQUEST",
  UPDATE_CUSTOMER_CONTACT_SETTINGS_SUCCESS: "UPDATE_CUSTOMER_CONTACT_SETTINGS_SUCCESS",
  UPDATE_CUSTOMER_CONTACT_SETTINGS_FAILURE: "UPDATE_CUSTOMER_CONTACT_SETTINGS_FAILURE",

  UPDATE_IS_PREFERRED_CONTACT: "UPDATE_IS_PREFERRED_CONTACT",
  UPDATE_IS_PREFERRED_CONTACT_REQUEST: "UPDATE_IS_PREFERRED_CONTACT_REQUEST",
  UPDATE_IS_PREFERRED_CONTACT_SUCCESS: "UPDATE_IS_PREFERRED_CONTACT_SUCCESS",
  UPDATE_IS_PREFERRED_CONTACT_FAILURE: "UPDATE_IS_PREFERRED_CONTACT_FAILURE",

  SET_CUSTOMER_ACTIVE: "SET_CUSTOMER_ACTIVE",
  SET_CUSTOMER_ACTIVE_REQUEST: "SET_CUSTOMER_ACTIVE_REQUEST",
  SET_CUSTOMER_ACTIVE_SUCCESS: "SET_CUSTOMER_ACTIVE_SUCCESS",
  SET_CUSTOMER_ACTIVE_FAILURE: "SET_CUSTOMER_ACTIVE_FAILURE",

  SET_CUSTOMER_INACTIVE: "SET_CUSTOMER_INACTIVE",
  SET_CUSTOMER_INACTIVE_REQUEST: "SET_CUSTOMER_INACTIVE_REQUEST",
  SET_CUSTOMER_INACTIVE_SUCCESS: "SET_CUSTOMER_INACTIVE_SUCCESS",
  SET_CUSTOMER_INACTIVE_FAILURE: "SET_CUSTOMER_INACTIVE_FAILURE",

  CREATE_CUSTOMER_ADDRESS: "CREATE_CUSTOMER_ADDRESS",
  CREATE_CUSTOMER_ADDRESS_REQUEST: "CREATE_CUSTOMER_ADDRESS_REQUEST",
  CREATE_CUSTOMER_ADDRESS_SUCCESS: "CREATE_CUSTOMER_ADDRESS_SUCCESS",
  CREATE_CUSTOMER_ADDRESS_FAILURE: "CREATE_CUSTOMER_ADDRESS_FAILURE",

  UPDATE_CUSTOMER_ADDRESS: "UPDATE_CUSTOMER_ADDRESS",
  UPDATE_CUSTOMER_ADDRESS_REQUEST: "UPDATE_CUSTOMER_ADDRESS_REQUEST",
  UPDATE_CUSTOMER_ADDRESS_SUCCESS: "UPDATE_CUSTOMER_ADDRESS_SUCCESS",
  UPDATE_CUSTOMER_ADDRESS_FAILURE: "UPDATE_CUSTOMER_ADDRESS_FAILURE",

  DELETE_CUSTOMER_ADDRESS: "DELETE_CUSTOMER_ADDRESS",
  DELETE_CUSTOMER_ADDRESS_REQUEST: "DELETE_CUSTOMER_ADDRESS_REQUEST",
  DELETE_CUSTOMER_ADDRESS_SUCCESS: "DELETE_CUSTOMER_ADDRESS_SUCCESS",
  DELETE_CUSTOMER_ADDRESS_FAILURE: "DELETE_CUSTOMER_ADDRESS_FAILURE",

  UPDATE_IS_PRIMARY_PHONE: "UPDATE_IS_PRIMARY_PHONE",
  UPDATE_IS_PRIMARY_PHONE_REQUEST: "UPDATE_IS_PRIMARY_PHONE_REQUEST",
  UPDATE_IS_PRIMARY_PHONE_SUCCESS: "UPDATE_IS_PRIMARY_PHONE_SUCCESS",
  UPDATE_IS_PRIMARY_PHONE_FAILURE: "UPDATE_IS_PRIMARY_PHONE_FAILURE",

  CREATE_CUSTOMER_PREFERENCES: "CREATE_CUSTOMER_PREFERENCES",
  CREATE_CUSTOMER_PREFERENCES_REQUEST: "CREATE_CUSTOMER_PREFERENCES_REQUEST",
  CREATE_CUSTOMER_PREFERENCES_SUCCESS: "CREATE_CUSTOMER_PREFERENCES_SUCCESS",
  CREATE_CUSTOMER_PREFERENCES_FAILURE: "CREATE_CUSTOMER_PREFERENCES_FAILURE",

  UPDATE_CUSTOMER_PREFERENCES: "UPDATE_CUSTOMER_PREFERENCES",
  UPDATE_CUSTOMER_PREFERENCES_REQUEST: "UPDATE_CUSTOMER_PREFERENCES_REQUEST",
  UPDATE_CUSTOMER_PREFERENCES_SUCCESS: "UPDATE_CUSTOMER_PREFERENCES_SUCCESS",
  UPDATE_CUSTOMER_PREFERENCES_FAILURE: "UPDATE_CUSTOMER_PREFERENCES_FAILURE",

  UPDATE_CUSTOMER_ALERTS: "UPDATE_CUSTOMER_ALERTS",
  UPDATE_CUSTOMER_ALERTS_REQUEST: "UPDATE_CUSTOMER_ALERTS_REQUEST",
  UPDATE_CUSTOMER_ALERTS_SUCCESS: "UPDATE_CUSTOMER_ALERTS_SUCCESS",
  UPDATE_CUSTOMER_ALERTS_FAILURE: "UPDATE_CUSTOMER_ALERTS_FAILURE",
};
