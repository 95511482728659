import React from "react";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { isNil } from "lodash/fp";
import {
  AT_LEAST_ONE_PHONE_REQUIRED,
  REQUIRED_FIELD_ERROR_MESSAGE,
} from "core/constants/validationErrors";
import { phoneSchema } from "core/constants/validationSchema";
import { EMAIL_OPT_OUT_OPTIONS } from "core/constants/customerProfileConstants";
import Form from "@/web/common/Form";
import FormInput from "@/web/common/FormInput";
import Button from "@/web/common/commonButton";
import withSaveOnClickOutside from "@/web/common/hoc/withSaveOnClickOutside";
import formatPhoneInputField from "@/core/utils/stringManipulationUtils/formatPhoneInputField";

const petParentPhoneSchema = phoneSchema.test(
  "no-phone",
  AT_LEAST_ONE_PHONE_REQUIRED,
  function validatePhone(phone) {
    const hasAtLeastOnePhone = Object.entries(this.parent).some(
      ([key, value]) => value && key.toLowerCase().includes("phone"),
    );
    return !!(hasAtLeastOnePhone || phone);
  },
);

const emailOptInSchema = Yup.bool().test(
  "isEmailOptedOut",
  "Required if email exists",
  function emailSchema(value) {
    const emailExists = !!this.parent?.email;
    const emailOptedInRequired = emailExists && isNil(value);

    return !emailOptedInRequired;
  },
);

const petParentValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  lastName: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
  mobilePhone: petParentPhoneSchema,
  homePhone: petParentPhoneSchema,
  workPhone: petParentPhoneSchema,
  email: Yup.string().email("Invalid email address"),
  isEmailDeclined: Yup.bool(),
  isEmailOptedOut: emailOptInSchema.nullable(),
  doNotBook: Yup.bool(),
  doNotBookReason: Yup.string()
    .when("doNotBook", {
      is: true,
      then: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE),
    })
    .nullable(),
});

const InputWithCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.div`
  margin-bottom: 1.2em;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`;

class PetParentFormComponent extends React.Component {
  componentWillUnmount() {
    const { clearFieldForNewProfiles } = this.props;
    clearFieldForNewProfiles();
  }

  render() {
    const {
      customerKey,
      petParentFormData,
      customerPreferredPhoneType,
      onSubmit,
      fieldsToInclude,
      handleFocus,
      formikRef,
      clickOutsideRef,
      createCustomerErrors,
      updateCustomerErrors,
      clearCreateCustomerErrors,
      clearUpdateCustomerErrors,
      isCreatingCustomer,
      isCustomerActive,
      isOnlineAccount,
      isLoyaltyCustomer,
      showDeleteEmailModal,
      emailAddressId,
    } = this.props;

    const componentId = "petParentForm"; // NOTE this should eventually come from the container or parent

    return (
      <Formik
        initialValues={petParentFormData}
        onSubmit={onSubmit}
        ref={formikRef}
        validationSchema={petParentValidationSchema}
        enableReinitialize
        render={({ values, errors, isValid }) => {
          const isActive = customerKey && !isCustomerActive;
          const shouldShowCloseIcon = petParentFormData.email && !isLoyaltyCustomer;
          return (
            <Form
              disabled={isCreatingCustomer || isActive}
              innerRef={customerKey ? clickOutsideRef : null}
              onFieldFocus={handleFocus}
              fieldsToInclude={fieldsToInclude}
            >
              <FormInput
                id={componentId}
                modifier="firstName"
                label="*First Name"
                type="text"
                name="firstName"
              />

              <FormInput
                id={componentId}
                modifier="lastName"
                label="*Last Name"
                type="text"
                name="lastName"
              />

              <InputWithCheckboxWrapper>
                <StyledInput>
                  <FormInput
                    id={componentId}
                    modifier="mobilePhone"
                    label="Phone Number (mobile)"
                    type="phone"
                    name="mobilePhone"
                    isPreferred={customerPreferredPhoneType === "Mobile"}
                    customerKey={customerKey}
                    value={formatPhoneInputField(values.mobilePhone)}
                    onChange={() => {
                      if (createCustomerErrors.phone) {
                        clearCreateCustomerErrors();
                      }
                      if (updateCustomerErrors.phone) {
                        clearUpdateCustomerErrors();
                      }
                    }}
                    error={errors.phone || createCustomerErrors.phone || updateCustomerErrors.phone}
                  />
                </StyledInput>
                <StyledInput>
                  <FormInput
                    label="Phone Number (home)"
                    type="phone"
                    name="homePhone"
                    isPreferred={customerPreferredPhoneType === "Home"}
                    customerKey={customerKey}
                    value={formatPhoneInputField(values.homePhone)}
                    onChange={() => {
                      if (createCustomerErrors.phone) {
                        clearCreateCustomerErrors();
                      }
                      if (updateCustomerErrors.phone) {
                        clearUpdateCustomerErrors();
                      }
                    }}
                    error={errors.phone || createCustomerErrors.phone || updateCustomerErrors.phone}
                  />
                </StyledInput>
                <FormInput
                  id={componentId}
                  modifier="workPhone"
                  label="Phone Number (work)"
                  type="text"
                  name="workPhone"
                  isPreferred={customerPreferredPhoneType === "Work"}
                  customerKey={customerKey}
                  value={formatPhoneInputField(values.workPhone)}
                  onChange={() => {
                    if (createCustomerErrors.phone) {
                      clearCreateCustomerErrors();
                    }
                    if (updateCustomerErrors.phone) {
                      clearUpdateCustomerErrors();
                    }
                  }}
                  error={errors.phone || createCustomerErrors.phone || updateCustomerErrors.phone}
                />
              </InputWithCheckboxWrapper>
              <InputWithCheckboxWrapper>
                <FormInput
                  id={componentId}
                  modifier="email"
                  label="Email"
                  type="email"
                  name="email"
                  onChange={e => {
                    if (createCustomerErrors.email) {
                      clearCreateCustomerErrors();
                    }
                    if (updateCustomerErrors.email) {
                      clearUpdateCustomerErrors();
                    }
                    if (!e.target.value) {
                      // eslint-disable-next-line no-param-reassign
                      values.isEmailOptedOut = null;
                    }
                  }}
                  error={errors.email || createCustomerErrors.email || updateCustomerErrors.email}
                  withCheckbox
                  value={values.isEmailDeclined ? "No email provided" : values.email}
                  disabled={!!values.isEmailDeclined || isOnlineAccount}
                  withCloseIcon={shouldShowCloseIcon}
                  closeInputStyle
                  onClose={() => showDeleteEmailModal({ customerKey, emailAddressId })}
                />
                <FormInput
                  id={componentId}
                  modifier="isEmailDeclined"
                  label="Declined to provide email"
                  type="checkbox"
                  name="isEmailDeclined"
                />
                <FormInput
                  id={componentId}
                  modifier="isEmailOptedOut"
                  showErrorOnLoad={!customerKey}
                  label="Opt in/out of Marketing Email"
                  type="select"
                  options={EMAIL_OPT_OUT_OPTIONS}
                  name="isEmailOptedOut"
                  disabled={!values.email}
                />
              </InputWithCheckboxWrapper>

              {!customerKey && (
                <Button
                  type="button"
                  height="4rem"
                  onClick={() => {
                    onSubmit(values);
                  }}
                  disabled={isCreatingCustomer || !isValid}
                  label={isCreatingCustomer ? "Creating.." : "Create"}
                />
              )}
            </Form>
          );
        }}
      />
    );
  }
}

export default withSaveOnClickOutside(PetParentFormComponent);
