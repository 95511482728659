/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelCheckedOutActionTypes = {
  LOAD_HOTEL_CHECKED_OUT_SUCCESS: "LOAD_HOTEL_CHECKED_OUT_SUCCESS",
  LOAD_HOTEL_CHECKED_OUT_REQUEST: "LOAD_HOTEL_CHECKED_OUT_REQUEST",
  LOAD_HOTEL_CHECKED_OUT_FAILURE: "LOAD_HOTEL_CHECKED_OUT_FAILURE",
  CLEAR_HOTEL_CHECKED_OUT: "CLEAR_HOTEL_CHECKED_OUT",
};
/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

export const loadHotelCheckedOutSuccess = ({ checkedOut }) => ({
  type: hotelCheckedOutActionTypes.LOAD_HOTEL_CHECKED_OUT_SUCCESS,
  checkedOut,
});

export const loadHotelCheckedOutRequest = ({ checkedOut }) => ({
  type: hotelCheckedOutActionTypes.LOAD_HOTEL_CHECKED_OUT_REQUEST,
  checkedOut,
});

export const loadHotelCheckedOutFailure = ({ error }) => ({
  type: hotelCheckedOutActionTypes.LOAD_HOTEL_CHECKED_OUT_FAILURE,
  error,
});

export const clearHotelCheckedOut = () => ({
  type: hotelCheckedOutActionTypes.CLEAR_HOTEL_CHECKED_OUT,
});
