import React from "react";
import { connect } from "react-redux";

// Components
import { LayoutBox } from "@/layout/box/Box";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { Form } from "@prism/psm-ui-components";
import LoadingWrapper from "@/web/common/LoadingWrapper";
import { FormWithSaveAndCancel } from "dux/_components/formWithSaveAndCancel/FormWithSaveAndCancel";

// Sparky Components
import { Heading, TextPassage } from "@petsmart-ui/sparky";

// Helpers
import { useForm } from "@/dux/utils/formUtils/useForm";

// Containers
import { HotelPetParentPhoneContainer } from "@/dux/hotelPetParentPhone/HotelPetParentPhone";

// Selectors
import { getSystemBookingFlow } from "@/web/setSystemType/selectors/setSystemTypeSelectors";
import {
  getPetParentContactSettings,
  getPhoneContactOptInOptOutSettingsFormValues,
} from "@/core/selectors/petParentProfileSelectors";
import { createLoadingSelector } from "@/core/selectors/utils";

// Actions
import { updateCustomerContactSettings } from "@/core/actionCreators/customersActionCreators";
import customersActionTypes from "@/core/actionTypes/customersActionTypes";

// Constants
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";

// Helper component for checkboxes
const OptInCheckbox = props => {
  const { componentId, isHidden, checked, onClick, disabled, name, label } = props;
  if (isHidden) return null;

  const id = `${componentId}-${name}`;

  return (
    <LayoutBox padding="scale-0">
      <LayoutCluster>
        <Form.Field.Checkbox
          id={id}
          name={name}
          checked={checked}
          onClick={onClick}
          disabled={disabled}
        />
        <TextPassage size="lg">
          <label htmlFor={id}>{label}</label>
        </TextPassage>
      </LayoutCluster>
    </LayoutBox>
  );
};

// PetParentContactSettings Component
export const PetParentContactSettingsComponent = props => {
  // Props from Container
  const {
    componentId,
    customerKey,
    initialContactSettingsValues,
    isHidden,
    isReminderCallsFieldHidden,
    onSubmit,
    phoneContactSettingsFormValues,
    title,
    isLoading,
  } = props;

  const { didFormFieldsUpdate, values, touched, setFormValue, setFormValues } = useForm(
    initialContactSettingsValues,
  );

  const submitForm = () => {
    if (didFormFieldsUpdate) onSubmit(values);
  };
  const resetForm = () => setFormValues(initialContactSettingsValues);

  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-0">
      <FormWithSaveAndCancel
        submitForm={submitForm}
        resetForm={resetForm}
        touched={touched}
        values={values}
      >
        <LoadingWrapper isLoading={isLoading}>
          <LayoutStack space="scale-G1">
            <Heading size="headline" tagName="h2">
              {title}
            </Heading>

            <OptInCheckbox
              componentId={componentId}
              label="Receive reminder calls"
              name="receiveReminderCalls"
              checked={values.receiveReminderCalls}
              isHidden={isReminderCallsFieldHidden}
              onClick={() => setFormValue("receiveReminderCalls", !values.receiveReminderCalls)}
            />

            {phoneContactSettingsFormValues &&
              Object.keys(phoneContactSettingsFormValues).map(phoneId => (
                <LayoutBox key={phoneId} padding="scale-0">
                  <LayoutStack space="scale-0">
                    <HotelPetParentPhoneContainer customerKey={customerKey} phoneId={phoneId} />
                    <OptInCheckbox
                      componentId={componentId}
                      label="Receive text messages"
                      name={phoneId}
                      checked={!!values[phoneId]}
                      onClick={() => setFormValue(phoneId, !values[phoneId])}
                    />
                  </LayoutStack>
                </LayoutBox>
              ))}
          </LayoutStack>
        </LoadingWrapper>
      </FormWithSaveAndCancel>
    </LayoutBox>
  );
};

export const PetParentContactSettings = connect(
  (state, { customerKey }) => {
    const initialContactSettingsValues = getPetParentContactSettings(state, { customerKey });
    const isReminderCallsFieldHidden = getSystemBookingFlow(state) === systemName.TRAINING;
    const phoneContactSettingsFormValues = getPhoneContactOptInOptOutSettingsFormValues(state, {
      customerKey,
    });

    return {
      componentId: "PetParentContactSettings",
      customerKey,
      initialContactSettingsValues,
      isHidden: false,
      isReminderCallsFieldHidden,
      phoneContactSettingsFormValues,
      title: "Contact Settings",
      isLoading: createLoadingSelector([customersActionTypes.UPDATE_CUSTOMER_CONTACT_SETTINGS])(
        state,
      ),
    };
  },
  (dispatch, { customerKey }) => ({
    onSubmit: formValues => {
      dispatch(
        updateCustomerContactSettings({
          customerKey,
          data: formValues,
        }),
      );
    },
  }),
)(PetParentContactSettingsComponent);
