import { frequencyConstants } from "@/dux/frequency/FrequencyConstants";

/**
 * Util to check if frequency is valid
 * @memberOf Utils.Validation
 * @function
 * @name isValidFrequency
 * @param {String} frequency
 * @param {String[]} dates
 * @returns {Boolean}
 * @example isValidFrequency("Manual") // -> returns false
 */
export const isValidFrequency = (frequency, dates) => {
  const isManual = frequency === frequencyConstants.MANUAL;
  const isValidValue = Object.values(frequencyConstants)?.some(value => value === frequency);
  return isManual ? !!dates?.length : isValidValue;
};
