import { createSelector } from "reselect";
import { get, flatten } from "lodash/fp";
import isExpired from "core/utils/dateUtils/isExpired";
import {
  BASE_SERVICES_EXPIRE,
  FREE_SERVICES_EXPIRE,
  BGM,
} from "dux/bgm/availableBundlesByPet/availableBundlesConstents";
import getTimestampDiff from "../../utils/dates/getTimestampDiff";
import calculateAge from "../../../core/utils/dateUtils/calculateAge";
import getUSDateString from "../../../core/utils/dateUtils/usDate";
import { getState, getProps } from "../../../core/selectors/commonSelector";
import {
  getPetsByCustomer,
  getPet,
  getCustomer,
  getPetBreedName,
} from "../../../core/selectors/entitiesSelector";
import { getAvailableBundlesByPet } from "../availableBundlesByPet/availableBundlesByPetSelectors";
import BGM_STATUS from "../availableBundlesByPet/availableBundlesByPetConstants";
import { formatBundleHistoryServiceLabel } from "@/dux/_components/bgm/bundleHistory/_Utils";

export const getAllBundlesByCustomer = createSelector(
  [getPetsByCustomer, getState],
  (petIds, state) => {
    const bundles = petIds.map(petId => getAvailableBundlesByPet(state, { petId }));
    return flatten(bundles);
  },
);

// We want to filter out all expired bundles and bundles that have been fully redeemed.
// This will be indicated by no remaining available units
export const getBundleHistoryList = createSelector([getAllBundlesByCustomer], bundles =>
  bundles
    .sort((previous, current) =>
      getTimestampDiff(previous.purchaseDatetime, current.purchaseDatetime),
    )
    .filter(({ availableUnits, reservedUnits, expirationDate }) => {
      // If available units is zero and there are either no reserved units or the bundle is expired,
      // we want to show these bundles in the bundle history list since they are no longer applicable.
      return (
        availableUnits === 0 && (reservedUnits === 0 || isExpired(expirationDate, "YYYY-MM-DD"))
      );
    }),
);

export const getBundleHistoryItemById = createSelector(
  [getBundleHistoryList, getProps],
  (bundles, { id }) => bundles.find(({ bundleNumber }) => bundleNumber === id),
);

export const getBundleHistoryItemName = createSelector([getBundleHistoryItemById], item => {
  return formatBundleHistoryServiceLabel(item);
});

export const getBundleHistoryItemPurchaseDate = createSelector([getBundleHistoryItemById], item => {
  const purchaseDateTime = get("purchaseDatetime", item);
  return getUSDateString(purchaseDateTime);
});

export const getBundleHistoryItemOfferType = createSelector([getBundleHistoryItemById], item => {
  return get("offerType", item);
});

// expiration dates
export const getBaseBundleHistoryItemExpirationDate = createSelector(
  [getBundleHistoryItemById],
  item => get("baseUnitsExpirationDate", item),
);

export const getFreeBundleHistoryItemExpirationDate = createSelector(
  [getBundleHistoryItemById],
  item => get("freeUnitsExpirationDate", item),
);

export const isExpiredSelectorFactory = selector =>
  createSelector([selector], expirationDate => isExpired(expirationDate));

export const getIsBaseBundleHistoryItemExpired = isExpiredSelectorFactory(
  getBaseBundleHistoryItemExpirationDate,
);

export const getIsFreeBundleHistoryItemExpired = isExpiredSelectorFactory(
  getFreeBundleHistoryItemExpirationDate,
);

export const getBaseBundleHistoryItemFormattedExpirationDate = createSelector(
  [getBaseBundleHistoryItemExpirationDate, getIsBaseBundleHistoryItemExpired],
  (expirationDate, isExpired) => {
    const date = getUSDateString(expirationDate);
    return isExpired ? `${BASE_SERVICES_EXPIRE}d: ${date}` : `${BASE_SERVICES_EXPIRE} ${date}`;
  },
);

export const getFreeBundleHistoryItemFormattedExpirationDate = createSelector(
  [
    getFreeBundleHistoryItemExpirationDate,
    getBundleHistoryItemOfferType,
    getIsFreeBundleHistoryItemExpired,
  ],
  (expirationDate, type, isExpired) => {
    if (type !== BGM) {
      return null;
    }
    const date = getUSDateString(expirationDate);
    return isExpired ? `${FREE_SERVICES_EXPIRE}d: ${date}` : `${FREE_SERVICES_EXPIRE} ${date}`;
  },
);

export const getBundleHistoryItemSoldByName = createSelector([getBundleHistoryItemById], item =>
  get(["soldBy", "associateName"], item),
);

export const getBundleHistoryItemPaymentOrigin = createSelector(
  [getBundleHistoryItemById],
  item => get("paymentOrigin", item) || "-",
);

export const getBundleHistoryItemAddOn = createSelector([getBundleHistoryItemById], item => {
  const addOns = get("addOns", item) || [];
  return addOns.find(Boolean);
});

export const getBundleHistoryItemAddOnName = createSelector([getBundleHistoryItemAddOn], addOn => {
  return addOn?.name && `Add-on: ${addOn?.name}`;
});

export const getBundleHistoryItemPetId = createSelector([getBundleHistoryItemById], item =>
  get("petId", item),
);

export const getBundleHistoryItemRedeemedUnits = createSelector([getBundleHistoryItemById], item =>
  get("redeemedUnits", item),
);

export const getBundleHistoryItemPetInfo = createSelector(
  [getBundleHistoryItemById, getState, getProps, getCustomer],
  ({ petId }, state, { customerKey }, customer) => {
    const { petName: firstName, birthDate, breedId } = getPet(state, { customerKey, petId });
    const { lastName } = customer;
    const age = calculateAge(birthDate);
    const breed = getPetBreedName(state, { breedId });

    return `${firstName} ${lastName} ${age}, ${breed}`;
  },
);

export const getIsBundleHistoryItemRefunded = createSelector([getBundleHistoryItemById], bundle => {
  // If any of the bundle redemptions are refunded, then ALL of them
  // are refunded.
  return bundle?.redemptions?.some(redemption => redemption.status === BGM_STATUS.REFUNDED);
});
