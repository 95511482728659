import React from "react";
import styled from "styled-components";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  margin-left: 105px;
`;

export default function IndexTabs(props) {
  const { isHidden, componentID, children } = props;

  if (!isHidden) {
    return <SectionWrapper id={componentID}>{children}</SectionWrapper>;
  }

  return null;
}
