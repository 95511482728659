/**
 * Responsible for returning the Notes Object in the redux store.
 * This is done so we have one central location to pull from redux state.
 *
 * @export getNotesState
 * @param {object} state
 * @param {object} ownProps
 * @returns{object} - Reference to the Notes object in the redux store
 *
 * @example createSelector([getNotesState], notesState => notesState.showNewGroomingNote);
 */
export default function getNotesState(state) {
  return state.ui.web.notes;
}
