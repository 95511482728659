import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Layout } from "@prism/psm-ui-components";
import CommonModal from "@/web/common/modals/commonModal";
import { getIsRemoveVirtualTrainerModalOpen } from "./associateProfilePageSelectors";
import {
  setToggleRemoveVirtualTrainerModal,
  loadTrainerBookedAppointments,
} from "./associateProfilePageActions";
import { getSelectedDate } from "@/dux/selectedDate/selectedDateSelector";
import RemoveVirtualTrainerConfirmationModalContent from "./RemoveVirtualTrainerConfirmationModalContent";
import RemoveVirtualTrainerSchedulingConflictModalContent from "./RemoveVirtualTrainerSchedulingConflictModalContent";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

export function RemoveVirtualTrainerModal({
  isHidden,
  onClose,
  associateId,
  loadTrainerBookedAppointments,
  selectedDate,
}) {
  useEffect(() => {
    const fromDate = moment(selectedDate)
      .startOf("isoWeek")
      .format();
    loadTrainerBookedAppointments({
      associateId,
      fromDate,
    });
  }, []);

  if (isHidden) {
    return null;
  }
  return (
    <CommonModal isHidden={isHidden} onClose={onClose}>
      <Layout.Box>
        <RemoveVirtualTrainerConfirmationModalContent />
        <RemoveVirtualTrainerSchedulingConflictModalContent />
      </Layout.Box>
    </CommonModal>
  );
}

const mapStateToProps = (state, { router }) => {
  const associateId = router?.params?.associateId;
  return {
    isHidden: !getIsRemoveVirtualTrainerModalOpen(state),
    associateId,
    selectedDate: getSelectedDate(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(setToggleRemoveVirtualTrainerModal());
  },
  loadTrainerBookedAppointments: ({ associateId, fromDate }) =>
    dispatch(loadTrainerBookedAppointments({ associateId, fromDate })),
});

export default withRouteProps(connect(mapStateToProps, mapDispatchToProps)(RemoveVirtualTrainerModal));
