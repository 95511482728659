/**
|--------------------------------------------------
| * Takes an array of strings and builds a comma-delimited list.
 * @param {Array} arr An array of strings to build a comma-delimited list out of.
 * @returns {String} Comma-delimited list
|--------------------------------------------------
*/

export default (arr = []) =>
  arr.reduce((accumulator, current) => {
    return accumulator ? `${accumulator},${current}` : current;
  }, "");
