export const appointmentHistoryHeaderValues = [
  { value: "timestamp", label: "Date" },
  { value: "timestamp", label: "Time" },
  { value: "eventType", label: "Activity Log" },
  { value: "description", label: "Description" },
  { value: "user", label: "User" }
];

export const creationChannels = {
  ONLINE: "Online",
  SRC: "SRC",
  IN_STORE: "In-Store"
};
