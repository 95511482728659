import React from "react";
import styled from "styled-components";
import SearchResultsWrapperContainer from "./searchRestultsWrapper/searchResultsWrapperContainer";
import HeaderImage from "../../assets/images/bg-header-image.png";
import SearchSalonHeaderContainer from "../header/SearchSalonHeaderContainer";
import { searchConstants } from "../../core/constants/searchConstants";
import { jobRoleConstants } from "../../core/constants/jobRoleConstants";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

class SearchSalon extends React.Component {
  componentDidMount() {
    const { jobRole, setSearchComponentName } = this.props;

    jobRole === jobRoleConstants.SRC
      ? setSearchComponentName(searchConstants.STORE)
      : setSearchComponentName(searchConstants.CUSTOMER);
  }

  render() {
    return (
      <SectionWrapper>
        <SearchSalonHeaderContainer backgroundImage={HeaderImage} />
        <SearchResultsWrapperContainer />
      </SectionWrapper>
    );
  }
}

export default SearchSalon;
