import { put, takeEvery, call, all, select } from "redux-saga/effects";
import engagementsActionTypes from "../actionTypes/engagementsActionTypes";
import engagementsActionCreators, {
  undoLastEngagementStatusUpdateFailure,
  undoLastEngagementStatusUpdateRequest,
  undoLastEngagementStatusUpdateSuccess,
  updateEngagementStatus,
  updateEngagementStatusFailure,
  updateEngagementStatusRequest,
  updateEngagementStatusSuccess,
} from "../actionCreators/engagementsActionCreators";
import { getEngagement, getItinerary, getPetServiceItem } from "../selectors/entitiesSelector";
import { putItineraryStatus } from "../services/systemServicesBooking/itineraryEndPoints";
import {
  postEngagement,
  deleteEngagement,
  putEngagementStatus,
  deleteLastEngagementStatusUpdate,
} from "../services/systemServicesBooking/engagementEndPoints";
import { initializeCartByItinerary } from "../actionCreators/ui/web/cartActionCreators";
import { dispatchItinerariesDataAndReturnItineraries } from "./itinerariesSaga";
import {
  loadItinerarySuccess,
  updateItineraryStatusFailure,
} from "../actionCreators/itinerariesActionCreators";
import petServiceItemsActionCreators from "../actionCreators/petServiceItemsActionCreators";
import { getCurrentPetServiceItem, getPage } from "../selectors/checkInOutSelector";
import { showCheckInOutModal } from "../actionCreators/checkInOutActionCreator";

import { APPOINTMENT_STATUS } from "../constants";
import { history } from "@/dux/utils/browser/browserHistory";
import { routePaths } from "../constants/routePaths";
import { setCustomerFTCOEligible } from "@/dux/bgm/ftco/ftcoActions";
import { setEligiblePromotions } from "dux/eligiblePromotions/eligiblePromotionsActions";

function* onCreateEngagement({ customerKey, itineraryId, data, dispatchToCart }) {
  try {
    yield put(engagementsActionCreators.createEngagementRequest());
    const response = yield call(postEngagement, { customerKey, data, itineraryId });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });
    const itinerary = normalizedItineraries[itineraryId];

    yield put(engagementsActionCreators.createEngagementSuccess({ itinerary }));
    if (dispatchToCart) {
      yield put(initializeCartByItinerary({ itineraryId }));
    }
  } catch (error) {
    yield put(engagementsActionCreators.createEngagementFailure({ error }));
  }
}

export function* onDeleteEngagement({ customerKey, itineraryId, engagementId }) {
  try {
    yield put(engagementsActionCreators.deleteEngagementRequest());
    const { petServiceItems } = yield select(getEngagement, { engagementId });
    const response = yield call(deleteEngagement, { customerKey, itineraryId, engagementId });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });
    const itinerary = normalizedItineraries[itineraryId];

    yield put(loadItinerarySuccess({ itinerary }));
    yield put(petServiceItemsActionCreators.deletePetServiceItems({ petServiceItems }));
    yield put(engagementsActionCreators.deleteEngagementSuccess({ engagementId }));
  } catch (error) {
    yield put(engagementsActionCreators.deleteEngagementFailure({ error }));
  }
}

export function* onUpdateEngagementStatus({
  pageName,
  customerId,
  itineraryId,
  engagementId,
  reason,
  status,
}) {
  try {
    // Set data for request body payload
    const data = {
      status,
      reason,
    };

    yield put(updateEngagementStatusRequest());
    const response = yield call(putEngagementStatus, {
      customerId,
      itineraryId,
      engagementId,
      data,
    });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });
    const itinerary = normalizedItineraries[itineraryId];

    yield put(updateEngagementStatusSuccess({ itinerary }));
    yield put(setCustomerFTCOEligible(itinerary.isFTCO));
    yield put(setEligiblePromotions(itinerary.eligiblePromotions));
    if (pageName) {
      // dispatch verification modal
      const modalType = pageName.toUpperCase();
      yield put(showCheckInOutModal({ modalType }));
    }
  } catch (error) {
    yield put(updateEngagementStatusFailure(error));
  }
}

export function* onUndoLastEngagementStatusUpdate({ customerId, itineraryId, engagementId }) {
  try {
    yield put(undoLastEngagementStatusUpdateRequest());
    const response = yield call(deleteLastEngagementStatusUpdate, {
      customerId,
      itineraryId,
      engagementId,
    });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response,
    });
    const itinerary = normalizedItineraries[itineraryId];

    const petServiceItemId = yield select(getCurrentPetServiceItem);
    const petServiceItem = yield select(getPetServiceItem, { petServiceItemId });

    yield put(undoLastEngagementStatusUpdateSuccess({ itinerary }));

    if (petServiceItem.status === APPOINTMENT_STATUS.BOOKED) {
      history.push(`${routePaths.CHECK_IN}/${customerId}/${itineraryId}`);
    }
  } catch (error) {
    yield put(undoLastEngagementStatusUpdateFailure(error));
  }
}

function* watchCreateEngagement() {
  yield takeEvery(engagementsActionTypes.CREATE_ENGAGEMENT, onCreateEngagement);
}

function* watchDeleteEngagement() {
  yield takeEvery(engagementsActionTypes.DELETE_ENGAGEMENT, onDeleteEngagement);
}

function* watchUpdateEngagementStatus() {
  yield takeEvery(engagementsActionTypes.UPDATE_ENGAGEMENT_STATUS, onUpdateEngagementStatus);
}

function* watchUndoLastEngagementStatusUpdate() {
  yield takeEvery(
    engagementsActionTypes.UNDO_LAST_ENGAGEMENT_STATUS_UPDATE,
    onUndoLastEngagementStatusUpdate,
  );
}

export default function* engagementsSaga() {
  yield all([
    watchCreateEngagement(),
    watchDeleteEngagement(),
    watchUpdateEngagementStatus(),
    watchUndoLastEngagementStatusUpdate(),
  ]);
}
