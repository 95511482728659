import cancellationsActionTypes from "../actionTypes/cancellationsActionTypes";

export const showCancelAppointmentModal = ({ modalType }) => ({
  type: cancellationsActionTypes.SHOW_CANCEL_APPOINTMENT_MODAL,
  modalType
});

export const hideCancelAppointmentModal = () => ({
  type: cancellationsActionTypes.HIDE_CANCEL_APPOINTMENT_MODAL
});
