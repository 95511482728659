import { connect } from "react-redux";
import { get } from "lodash/fp";
import Checkbox from "../../../web/common/Checkbox";
import { isFromBooking } from "../../../core/utils/validationUtils/isFromValidation";
import { history } from "@/dux/utils/browser/browserHistory";
import { fontSizes } from "web/common/styles/responsive/fonts";
import { getIsMedicationOnPetById } from "../bookingSaveMedicationToProfile/medicationSaveToProfileSelectors";

const mapStateToProps = (state, ownProps) => {
  const { medicationId, petId } = ownProps;
  return {
    medicationId,
    checked: getIsMedicationOnPetById(state, { medicationId, petId }),
    componentId: "medicationDetails-isFromProfile",
    isHidden: !isFromBooking(get(["location", "pathname"], history)),
    label: "Pet profile medication",
    labelPosition: "right",
    paddingTop: "10px",
    fontSize: fontSizes.regular,
    disabled: true,
  };
};

export default connect(mapStateToProps)(Checkbox);
