import { createSelector } from "reselect";
import { getProps } from "../../commonSelector";
import getPrimaryAddressID from "./getPrimaryAddressID";

// Example of use:  getIsPrimaryAddress(state, { customerKey, addressId });
const getIsPrimaryAddress = createSelector(
  [getProps, getPrimaryAddressID],
  ({ addressId }, primaryAddressId) => addressId === primaryAddressId
);

export default getIsPrimaryAddress;
