import { connect } from "react-redux";
import AppHeader from "./AppHeader";
import { getPetFullName } from "../../core/selectors/entitiesSelector";
import { headerConfigConstants } from "../../core/constants/headerConfigConstants";
import { getJobRole } from "../../core/selectors/persistentSelectors";

// TODO: move to AppHeader.js so all containers are co-located with the component.
const mapStateToProps = (state, ownProps) => ({
  title: `Appointment for ${getPetFullName(state, ownProps) || ""}`,
  config: headerConfigConstants.APPOINTMENT,
  jobRole: getJobRole(state, ownProps)
});

export default connect(mapStateToProps)(AppHeader);
