import React from "react";
import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  ${({ numberOfColumns = 3 }) => `
    grid-template-columns: repeat(${numberOfColumns}, auto);
  `}
  grid-template-rows: auto;
  gap: 0.5em 0.5em;
`;

const GridCell = styled.div`
  text-align: center;
`;

export default function PackageOfferTable(props) {
  const { isHidden, componentID, packageList, numberOfColumns } = props;
  if (!isHidden) {
    return (
      <Grid id={componentID} data-testid={componentID} numberOfColumns={numberOfColumns}>
        {packageList.map(({ packageListValues }) =>
          packageListValues.map(packageListValue => <GridCell key={packageListValue}>{packageListValue}</GridCell>),
        )}
      </Grid>
    );
  }

  return null;
}
