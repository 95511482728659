import { createSelector } from "reselect";
import { get, isUndefined } from "lodash/fp";
import { getHotelBookingCart } from "../../../web/hotelBookingCart/hotelBookingCartSelectors";

export const getPetCart = createSelector([getHotelBookingCart], hotelBookingCart => {
  return get("petCarts", hotelBookingCart);
});

export const getPetCardList = createSelector([getPetCart], petCarts => {
  return Object.keys(petCarts) || [];
});

export const getCardForPet = createSelector(
  [getPetCart, (state, props) => props],
  (petCart, { petId }) => {
    return petCart[petId];
  },
);

export const getIsPetCardShowing = createSelector([getCardForPet], petCard => {
  const isCardShowing = get("isPetCardShowing", petCard);
  return !isUndefined(isCardShowing) ? isCardShowing : true;
});
