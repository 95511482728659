import { connect } from "react-redux";
import { getSelectedTab } from "../appointmentsListSelectors";
import { tabFilters } from "../appointmentsListConstants";
import AppointmentHistoryBGMComponent from "./AppointmentHistoryBGMComponent";

const mapStateToProps = state => {
  const isSelectedTab = getSelectedTab(state) === tabFilters.APPOINTMENTS;
  return {
    isHidden: isSelectedTab === false,
    componentId: "AppointmentHistory",
  };
};

export default connect(mapStateToProps)(AppointmentHistoryBGMComponent);
