import { associateWebAPI } from "./_axiosConfigAssociateWeb";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Updates an existing phone for a customer from the Web Associate API - Updates an existing phone for a customer end point.
 * @param {*} { customerKey, phoneId, sourceId, data }
 * @returns
 */
export function putPhoneNumber({ customerKey, phoneId, sourceId, data }) {
  return associateWebAPI.put(
    `${endpointVersionString()}/customers/${customerKey}/phones/${phoneId}?sourceId=${sourceId}`,
    data
  );
}
