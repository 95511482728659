import { set } from "lodash/fp";
import customerActionTypes from "core/actionTypes/customer/customerActionTypes";
import { checkForUndefined } from "core/utils/undefinedOrNullUtils/undefinedOrNullUtils";

const initialState = null;
/**
 * Reducer function to handle current customer key related actions.
 *
 * @memberof Reducer.persistent
 * @param {object} state - The current state.
 * @param {object} action - The action object.
 * @returns {object} - The new state.
 */
export const currentCustomerKeyReducer = (state = initialState, action) => {
  switch (action.type) {
    case customerActionTypes.SET_CURRENT_CUSTOMER_KEY:
      return checkForUndefined(action.currentCustomerKey, initialState);

    case customerActionTypes.CLEAR_CURRENT_CUSTOMER_KEY:
      return initialState;

    default:
      return state;
  }
};
