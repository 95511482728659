import { put, takeEvery, call, all, select } from "redux-saga/effects";
import maxPerBlockOrCheckReductionReasonsActionTypes from "../actionTypes/maxPerBlockOrCheckReductionReasonsActionTypes";
import maxPerBlockOrCheckReductionReasonsActionCreator from "../actionCreators/maxPerBlockOrCheckReductionReasonsActionCreator";
import { reduce, set, mapValues, get } from "lodash/fp";
import { fetchMaxPerBlockOrCheckReductionReasons } from "../services/systemServicesBooking/maxPerBlockOrCheckReductionEndPoints";

function* onloadMaxPerBlockOrCheckReductionReasons() {
  try {
    yield put(
      maxPerBlockOrCheckReductionReasonsActionCreator.loadMaxPerBlockOrCheckReductionReasonsRequest()
    );
    const response = yield call(fetchMaxPerBlockOrCheckReductionReasons);
    const maxPerBlockOrCheckReductionReasons = response.data;
    yield put(
      maxPerBlockOrCheckReductionReasonsActionCreator.loadMaxPerBlockOrCheckReductionReasonsSuccess(
        { maxPerBlockOrCheckReductionReasons }
      )
    );
  } catch (error) {
    yield put(
      maxPerBlockOrCheckReductionReasonsActionCreator.loadMaxPerBlockOrCheckReductionReasonsFailure(
        { error }
      )
    );
  }
}

function* watchloadMaxPerBlockOrCheckReductionReasons() {
  yield takeEvery(
    maxPerBlockOrCheckReductionReasonsActionTypes.LOAD_MAX_PER_BLOCK_REDUCTION_REASONS,
    onloadMaxPerBlockOrCheckReductionReasons
  );
}

export default function* maxPerBlockOrCheckReductionReasonsSaga() {
  yield all([watchloadMaxPerBlockOrCheckReductionReasons()]);
}
