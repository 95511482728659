/** @module stringToNumberConversion */

/**
 * Takes a string of numbers and converts them to a number type
 * @exports convertStringToNumbers
 * @param {string} phoneString
 * @return {number} A series of numbers
 */
export default function convertStringToNumbers(phoneString) {
  return `${phoneString}`.replace(/\D/g, "");
}
