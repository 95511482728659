import {
  getAddOns,
  getAvailableSpecialIdsByPetServiceItem,
  getSpecials,
} from "../../core/selectors/entitiesSelector";
import { createSelector } from "reselect";
import { getAppointmentByPet } from "../../core/selectors/cartSelectors";
import { get } from "lodash/fp";

export const getDynamicPrices = (state) => state.dynamicPrices;
export const getPetServiceItemSpecials = (state, props) =>
  state.entities.petServiceItems[props.petServiceItemId].specials;

export const getAddonsWithDynamicPrice = createSelector(
  [getAppointmentByPet, getAddOns, getDynamicPrices],
  (cartAppointment, addons, dynamicPrices) => {
    if (get(["addons", 0, "dynamicPrice"], cartAppointment)) {
      return cartAppointment?.addons;
    }

    return cartAppointment?.addons?.map((addon) => {
      const dynamicPriceObj = addon.addOnItemId ? dynamicPrices.addOns[addon.addOnItemId] : {};

      return (
        {
          ...addons[addon.addOnId],
          dynamicPrice: dynamicPriceObj,
        } || []
      );
    });
  },
);

export const getEnhancedServicesWithDynamicPrice = createSelector(
  [getAppointmentByPet],
  (cartAppointment) => {
    return cartAppointment?.enhancedServices;
  },
);

export const getAddOnsTotalWithDynamicPrice = createSelector(
  [getAddonsWithDynamicPrice],
  (addOns) => addOns.reduce((acc, addOn) => acc + addOn.price, 0),
);

export const getEnhancedServicesTotalWithDynamicPrice = createSelector(
  [getEnhancedServicesWithDynamicPrice],
  (enhancedService) => {
    return enhancedService?.price || enhancedService?.currentPrice || 0;
  },
);

export const getAppliedSpecialsByPetServiceItem = createSelector(
  [getDynamicPrices, (state, props) => props],
  (dynamicPrices, { petServiceItemId }) =>
    get([petServiceItemId, "specials"], dynamicPrices.petServiceItems) || [],
);

export const getAppliedSpecialsByAddonItem = createSelector(
  [getDynamicPrices, (state, props) => props],
  (dynamicPrices, { addOnItemId }) => get([addOnItemId, "specials"], dynamicPrices.addOns) || [],
);

export const getAvailableSpecialsByPetServiceItem = createSelector(
  [getAvailableSpecialIdsByPetServiceItem, getSpecials],
  (availableSpecialIds, specials) => availableSpecialIds.map((specialId) => specials[specialId]),
);
