/**
 * Selector to get current groupingId saved in cart state
 * Note: The groupingId is used to reference addons for the
 * price modal because we want to grab all copies of the given
 * addon across all of a pets engagements on an itinerary
 *
 * @memberOf Selectors.HotelPriceOverride
 * @function
 * @name selectCurrentAddonGroupingId
 * @param {Object} state - redux state
 * @returns {string | undefined} currentAddonGroupingId
 * @example selectCurrentAddonGroupingId(state)
 */
export const selectCurrentAddonGroupingId = state =>
  state?.hotelPriceOverrideCurrentAddonGroupingId;
