import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import {
  eligibilityPetActionTypes,
  getPetEligibilityFailure,
  getPetEligibilityRequest,
  getPetEligibilitySuccess,
} from "dux/eligibility/actions/eligibilityPetActions";
import { waitForRequests } from "core/sagas/utilsSaga";
import petsActionTypes from "core/actionTypes/petsActionTypes";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import { eligibilityTypes } from "dux/eligibility/constants/eligibilityConstants";
import { fetchPetEligibility } from "core/services/systemServicesBooking/petEligibilityEndPoints";
import mergeEnginesResult from "dux/eligibility/helperUtils/mergeResults";
import { onGetReasonFlow } from "dux/eligibility/sagas/eligibilityPetCustomerReasonsFow";

// eslint-disable-next-line consistent-return
function* petEligibilityFlow({
  petId,
  customerKey,
  startDate,
  eligibilityType,
  eligibilitySubjectType,
}) {
  try {
    yield put(getPetEligibilityRequest());
    yield waitForRequests([petsActionTypes.UPDATE_PET, customersActionTypes.UPDATE_CUSTOMER]);

    let primaryEngineResult;

    if (eligibilityType !== eligibilityTypes.CHECK_OUT) {
      const response = yield call(fetchPetEligibility, {
        petId,
        customerKey,
        startDate,
        isBooking: eligibilityType === eligibilityTypes.BOOKING,
      });

      primaryEngineResult = response.data;
    }

    const secondaryEngineResult = yield call(onGetReasonFlow, {
      id: petId,
      eligibilityType,
      eligibilitySubjectType,
    });

    const mergedResults = mergeEnginesResult({ primaryEngineResult, secondaryEngineResult });

    // Should dispatch does not actually determine if an action is dispatched or not
    // It actually decides whether to pass results to a function that is dispatched.
    if (mergedResults) {
      const petEligibility = {
        ...mergedResults,
        petId: Number(petId),
      };

      yield put(getPetEligibilitySuccess({ result: petEligibility, petId }));

      return petEligibility;
    }

    yield put(getPetEligibilitySuccess({ result: null, petId }));
  } catch (error) {
    yield put(getPetEligibilityFailure({ error }));
  }
}

function* watchGetPetEligibility() {
  yield takeEvery(eligibilityPetActionTypes.GET_PET_ELIGIBILITY, petEligibilityFlow);
}

export default function* eligibilityPetSaga() {
  yield all([fork(watchGetPetEligibility)]);
}
