import { connect } from "react-redux";
import { getTrainingClassSessionDetails } from "../trainingClassSessions/selectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import TrainingAppointmentDetailsComponent from "./TrainingAppointmentDetailsComponent";

const mapStateToProps = state => {
  const storeNumber = getStoreNumber(state);
  const classSession = getTrainingClassSessionDetails(state, { storeNumber });
  return {
    associateName: classSession?.associateName,
    sessionStartDate: classSession?.sessionStartDate,
    storeSessionTime: classSession?.storeSessionTime,
    customerSessionTime: classSession?.customerSessionTime,
  };
};

export default connect(mapStateToProps)(TrainingAppointmentDetailsComponent);
