/* * ----------------------------------------------------------------------- * *\
  Action Availability
\* * ----------------------------------------------------------------------- * */
export const GET_HOTEL_ROOM_AVAILABILITY = "GET_HOTEL_ROOM_AVAILABILITY";
export const GET_HOTEL_ROOM_AVAILABILITY_REQUEST = "GET_HOTEL_ROOM_AVAILABILITY_REQUEST";
export const GET_HOTEL_ROOM_AVAILABILITY_SUCCESS = "GET_HOTEL_ROOM_AVAILABILITY_SUCCESS";
export const GET_HOTEL_ROOM_AVAILABILITY_FAILURE = "GET_HOTEL_ROOM_AVAILABILITY_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const getHotelRoomAvailability = payload => ({
  type: GET_HOTEL_ROOM_AVAILABILITY,
  payload,
});

export const getHotelRoomAvailabilityRequest = payload => ({
  type: GET_HOTEL_ROOM_AVAILABILITY_REQUEST,
  payload,
});

export const getHotelRoomAvailabilitySuccess = payload => ({
  type: GET_HOTEL_ROOM_AVAILABILITY_SUCCESS,
  payload,
});

export const getHotelRoomAvailabilityFailure = payload => ({
  type: GET_HOTEL_ROOM_AVAILABILITY_FAILURE,
  payload,
});
