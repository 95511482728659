import { connect } from "react-redux";

// Components
import { ToggleButtonListWithSelectAll } from "dux/filteringButtons/ToggleButtonListWithSelectAll";

// Selectors
import { getHotelRoomManagementFilters } from "dux/hotelRoomManagementFilterPills/hotelRoomManagementFilterPillsSelector";

// Actions
import { setHotelRoomFilters } from "./hotelRoomManagementFilterPillsActions";

// Container
export const HotelRoomManagementFilterPills = connect(
  state => {
    const filters = getHotelRoomManagementFilters(state);

    return {
      componentId: "HotelRoomManagementFilterPills",
      key: filters,
      data: filters,
    };
  },

  dispatch => {
    return {
      setGlobalState: data => {
        const filters = Object.values(data)?.filter(item => item.isPressed);
        dispatch(setHotelRoomFilters(filters));
      },
    };
  },
)(ToggleButtonListWithSelectAll);
