import absenceReasonsActionTypes from "../../core/actionTypes/absenceReasonsActionTypes";

const initialState = [];

const absenceReasonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case absenceReasonsActionTypes.LOAD_ABSENCE_REASONS_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export default absenceReasonsReducer;
