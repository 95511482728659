import { put, takeLatest, call, all } from "redux-saga/effects";
import { patchHotelAddonsEndpoint } from "@/core/services/systemServicesBooking/getCreateUpdateDeleteHotelItineraryEndpoints";
import {
  PATCH_HOTEL_ITINERARY_ADDONS,
  patchHotelItineraryAddonsRequest,
  patchHotelItineraryAddonsSuccess,
  patchHotelItineraryAddonsFailure,
} from "./hotelItineraryAddonPatchActions";
import { saveHotelItineraryResponse } from "../hotelItinerary/hotelItinerarySaga";

/**
 * Saga to update addons for an itinerary
 * @memberOf Sagas.hotel.itinerary
 * @function
 * @name onPatchHotelItineraryAddons
 * @param {string} itineraryId - id of itinerary to update
 * @param {string} customerId - current customer key
 * @param {Object} data - request payload for updating addons
 */
function* onPatchHotelItineraryAddons({ itineraryId, customerId, data }) {
  try {
    yield put(patchHotelItineraryAddonsRequest());

    const response = yield call(patchHotelAddonsEndpoint, {
      itineraryId,
      customerId,
      data,
    });

    // Parse and store hotel itinerary response
    yield call(saveHotelItineraryResponse, { responseData: response.data.result });

    yield put(patchHotelItineraryAddonsSuccess());
  } catch (error) {
    yield put(patchHotelItineraryAddonsFailure(error));
  }
}

function* watchOnPatchHotelItineraryAddons() {
  yield takeLatest(PATCH_HOTEL_ITINERARY_ADDONS, onPatchHotelItineraryAddons);
}

export default function* hotelItineraryAddonPatchSaga() {
  yield all([watchOnPatchHotelItineraryAddons()]);
}
