//@ts-check
import { SET_HOTEL_MANAGE_ROOM_SUSPENSION_REASON } from "./hotelManageRoomDetailsReasonsActions";

const initialState = {
  roomSuspensionReason: "",
};

const hotelManageRoomDetailsReasonsReducer = (
  state = initialState,
  /** @type {{ type: string; payload: string; }} */ action,
) => {
  switch (action.type) {
    case SET_HOTEL_MANAGE_ROOM_SUSPENSION_REASON:
      return {
        ...state,
        roomSuspensionReason: action.payload,
      };
    default:
      return state;
  }
};

export default hotelManageRoomDetailsReasonsReducer;
