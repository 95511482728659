import { connect } from "react-redux";
import { FlashMessage } from "@/dux//flashMessage/FlashMessage";
import { getIsResendMeetingLinkFlashMessageOpen } from "@/dux/manageTrainingClassPage/manageTrainingClassPageSelectors";
import { alertThemesTypes } from "@/dux/alert/utils";
import { toggleResendMeetingLinkFlashMessage } from "../manageTrainingClassPage/manageTrainingClassPageActions";

export const ResendMeetingLinkFlashMessage = connect(
  (state) => ({
    theme: alertThemesTypes.SUCCESS,
    display: getIsResendMeetingLinkFlashMessageOpen(state),
    message: "Meeting link successfully resent",
  }),
  (dispatch) => ({
    toggleResendMeetingLinkFlashMessage: () => {
      dispatch(toggleResendMeetingLinkFlashMessage());
    },
  }),
  (stateProps, dispatchProps) => {
    const { toggleResendMeetingLinkFlashMessage } = dispatchProps;
    return {
      ...stateProps,
      onClose: () => {
        toggleResendMeetingLinkFlashMessage();
      },
    };
  },
)(FlashMessage);
