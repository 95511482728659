import { associateWebProfileAPI } from "./_axiosConfigAssociateWebProfile";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Fetch pet info such as name, age, breed etc. from the Web Associate Profile API - Given a list of petIds return the petName, age, breed, primary photo url for each of the pets end Point.
 * @param {*} { petIds } - An Array of Pet ids
 * @returns
 */
export function fetchPetsByIds({ petIds }) {
  return associateWebProfileAPI.post(`${endpointVersionString()}/pets/search`, {
    petIds
  });
}

/**
 * Delete a pet from the Web Associate Profile API - Deletes a pet end point.
 * @param {*} { petId, customerKey, sourceId }
 * @returns
 */
export function deletePet({ petId, customerKey, sourceId }) {
  return associateWebProfileAPI.delete(
    `v2/customers/${customerKey}/pets/${petId}?sourceId=${sourceId}`
  );
}
