import { connect } from "react-redux";
import { Text } from "@prism/psm-ui-components";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getTrainingAttendeeById } from "@/dux/trainingClassSessions/selectors";

export default connect((state, { attendeeId }) => {
  const storeNumber = getStoreNumber(state);
  const { petName } = getTrainingAttendeeById(state, { storeNumber, attendeeId });
  return {
    children: petName,
  };
})(Text);
