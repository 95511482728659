import React from "react";
import { connect } from "react-redux";

// Components
import { Layout } from "@prism/psm-ui-components";

// Containers
import { HotelRoomManagementGridCard } from "@/dux/hotelRoomManagementGridCard/hotelRoomManangementGridCard";

// Selectors
import {
  getHotelRoomSelectedRoomType,
  getHotelRoomTypes,
  getFilteredRoomsByRoomType,
} from "@/dux/hotelRoomSelection/hotelRoomSelectionSelector";

// Helpers
import { history } from "dux/utils/browser/browserHistory";

// Contants
import { routePaths } from "@/core/constants/routePaths";

export const RoomManagementGrid = props => {
  const { componentId, roomsByRoomType } = props;

  return (
    <Layout.Box id={componentId}>
      <Layout.Grid cellSpacing="cell-space-6" style={{ gridTemplateColumns: "repeat(3, 1fr)" }}>
        {roomsByRoomType.map((room, index) => (
          <HotelRoomManagementGridCard
            index={index}
            key={`${index}__${room.roomNumber}`}
            room={room}
          />
        ))}
      </Layout.Grid>
    </Layout.Box>
  );
};

// HotelRoomManagementGrid Container
export const HotelRoomManagementGrid = connect(
  state => {
    const roomSelected = getHotelRoomSelectedRoomType(state);
    const roomTypes = getHotelRoomTypes(state);

    if (!roomSelected || roomTypes.length === 1) {
      // If no selected room type or room types in store
      // redirect to room selection page
      history.push(routePaths.ROOM_SELECTION);
    }

    return {
      componentId: "HotelRoomManagementGrid",
      roomsByRoomType: getFilteredRoomsByRoomType(state),
    };
  },

  dispatch => {
    return {
      // someAction: () => dispatch(action()),
    };
  },
)(RoomManagementGrid);
