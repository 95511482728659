import { createSelector } from "reselect";
import isRequiredPetFieldMissing from "../../utils/formUtils/isRequiredPetFieldMissing";
import { getCustomer, getPets } from "../entitiesSelector";

// /Gets missing data message is a pet does not have one or more required fields.
export const getPetMissingRequired = createSelector([getPets, getCustomer], (pets, customer) => {
  if (customer && customer.pets) {
    return customer.pets
      .filter(
        petId => pets[petId] && pets[petId].isActive && isRequiredPetFieldMissing(pets[petId]),
      ) // Filter pets out if they are not missing data.
      .map(petId => `${pets[petId].petName}'s profile is missing required information.\n`) // For those missing data, make a string alerting they are misisng data.
      .reduce((acc, message) => acc + message, ""); // Take all the messages and concat them into a single message.
  }
  return ""; // If no data is missing from any pet, return an empty string for a falsy value.
});
