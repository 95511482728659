import salonHoursActionTypes from "../../core/actionTypes/salonHoursActionTypes";

export const loadSalonHours = ({ storeNumber, beginDate, endDate }) => ({
  type: salonHoursActionTypes.LOAD_SALON_HOURS,
  storeNumber,
  beginDate,
  endDate,
});

export const loadSalonHoursRequest = () => ({
  type: salonHoursActionTypes.LOAD_SALON_HOURS_REQUEST,
});

export const loadSalonHoursSuccess = ({ salonHours }) => ({
  type: salonHoursActionTypes.LOAD_SALON_HOURS_SUCCESS,
  salonHours,
});

export const loadSalonHoursFailure = ({ error }) => ({
  type: salonHoursActionTypes.LOAD_SALON_HOURS_FAILURE,
  error,
});

export const loadRelocatedSalonHours = ({ storeNumber, beginDate, endDate }) => ({
  type: salonHoursActionTypes.LOAD_RELOCATED_SALON_HOURS,
  storeNumber,
  beginDate,
  endDate,
});

export const loadRelocatedSalonHoursRequest = () => ({
  type: salonHoursActionTypes.LOAD_RELOCATED_SALON_HOURS_REQUEST,
});

export const loadRelocatedSalonHoursSuccess = payload => ({
  type: salonHoursActionTypes.LOAD_RELOCATED_SALON_HOURS_SUCCESS,
  payload,
});

export const loadRelocatedSalonHoursFailure = ({ error }) => ({
  type: salonHoursActionTypes.LOAD_RELOCATED_SALON_HOURS_FAILURE,
  error,
});
export const loadAllRelocatedSalonHours = () => ({
  type: salonHoursActionTypes.LOAD_ALL_RELOCATED_SALON_HOURS,
});

export const loadAllRelocatedSalonHoursRequest = () => ({
  type: salonHoursActionTypes.LOAD_ALL_RELOCATED_SALON_HOURS_REQUEST,
});

export const loadAllRelocatedSalonHoursSuccess = () => ({
  type: salonHoursActionTypes.LOAD_ALL_RELOCATED_SALON_HOURS_SUCCESS,
});

export const loadAllRelocatedSalonHoursFailure = ({ error }) => ({
  type: salonHoursActionTypes.LOAD_ALL_RELOCATED_SALON_HOURS_FAILURE,
  error,
});

export const displayStoreHoursErrorMessage = ({ message }) => ({
  type: salonHoursActionTypes.DISPLAY_STORE_HOURS_ERROR_MESSAGE,
  message,
});

export const hideStoreHoursErrorMessage = () => ({
  type: salonHoursActionTypes.HIDE_STORE_HOURS_ERROR_MESSAGE,
});

export default {
  loadSalonHours,
  loadSalonHoursRequest,
  loadSalonHoursSuccess,
  loadSalonHoursFailure,
  loadRelocatedSalonHours,
  loadRelocatedSalonHoursRequest,
  loadRelocatedSalonHoursSuccess,
  loadRelocatedSalonHoursFailure,
  loadAllRelocatedSalonHours,
  loadAllRelocatedSalonHoursRequest,
  loadAllRelocatedSalonHoursSuccess,
  loadAllRelocatedSalonHoursFailure,
};
