import React from "react";
import styled from "styled-components";
import { Layout } from "@prism/psm-ui-components";

const StyledTabs = styled.div`
  // TODO: delete below when after the BGM deployment
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin-top: 0;

  // TODO: Un-comment after the BGM deployment
  // display: flex;
  // flex-wrap: wrap;
  // list-style-type: none;
`;

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.cloneTab = this.cloneTab.bind(this);
  }

  cloneTab(elem) {
    return React.cloneElement(elem, {
      onClick: () => this.props.onTabClick(elem.props.tabId),
      isActive: elem.props.tabId === this.props.activeTab,
    });
  }

  render() {
    const { children = [], componentId, style } = this.props;
    return (
      <Layout.Cluster
        id={componentId}
        data-testid={componentId}
        style={style}
        space="cluster-space-4"
        justify="flex-start"
      >
        {React.Children.map(children, child => this.cloneTab(child))}
      </Layout.Cluster>
    );
  }
}

export default Tabs;
