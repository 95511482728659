/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const setFoodInstructionsActionTypes = {
  SET_FOOD_INSTRUCTIONS: "SET_FOOD_INSTRUCTIONS",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setFoodInstructions = ({ foodInstructions, foodId, petId }) => ({
  type: setFoodInstructionsActionTypes.SET_FOOD_INSTRUCTIONS,
  foodInstructions,
  foodId,
  petId,
});
