import { get } from "lodash/fp";
import { createSelector } from "reselect";
import getFoodState from "../../food/_foodState";

export const getIsAddingNewFood = createSelector([getFoodState], foodState =>
  get("newFood", foodState)
);

export const getIsNotAddingNewFood = createSelector(
  [getFoodState],
  foodState => !get("newFood", foodState)
);
