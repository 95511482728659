import { webServicesEngagementAPI } from "./_axiosConfigWebServicesEngagement";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Enrolls the customer in the desired pet service. If a customer is new to PRISM,
 * the customer and their pets will be created in PRISM, the customer will be activated
 * in that service within Profile, and their primary or most recent mobile phone will be
 * opted into services text messages. For returning customers to PRISM, they will merely
 * be re-activated in that service within Profile.
 * @param {*} { sourceId, customerKey }
 * @returns
 */
export function postEnrollInServices({ sourceId, customerKey, data }) {
  return webServicesEngagementAPI.post(
    `/${endpointVersionString()}/customers/${customerKey}/enrollments`,
    { sourceId, ...data },
  );
}
