import { connect } from "react-redux";
import { isEmpty } from "lodash/fp";

import TotalsRowComponent from "./totalsRowComponent";
import { getWeekWorkDurationTotals } from "../../core/selectors/schedulesSelectors";

const mapStateToProps = (state, ownProps) => ({
  totals:
    !isEmpty(ownProps.schedules) &&
    getWeekWorkDurationTotals(state, {
      filteredSchedules: ownProps.schedules
    })
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalsRowComponent);
