import { connect } from "react-redux";
import JobRoleIndicator from "../../common/jobRole/JobRoleIndicator";
import { jobRoleConstants } from "../../../core/constants/jobRoleConstants";
import { getJobRole, getIsAccessLevelMocked } from "../../../core/selectors/persistentSelectors";
import { showTestJobRoles } from "../../../core/actionCreators/jobRoleActionCreators";
import {
  getIsTestJobRolesVisible,
  getTestJobRolesList
} from "../../../core/selectors/jobRoles/jobRolesSelectors";

const getJobString = function jobStringFunction(jobRole) {
  switch (jobRole) {
    case jobRoleConstants.MANAGER:
      return "MANAGER";

    case jobRoleConstants.SRC:
      return "SRC AGENT";

    default:
      return "ASSOCIATE";
  }
};

const mapStateToProps = state => {
  const jobRole = getJobRole(state);
  return {
    jobRole: getJobString(jobRole),
    isVisible: true, // currently always true since any access level can currently mock another, this prop is here incase those rules change in the future.
    showTestRoles: getIsTestJobRolesVisible(state),
    isClickable: getTestJobRolesList(state),
    isMocked: getIsAccessLevelMocked(state)
  };
};

const mapDispatchToProps = dispatch => ({
  // This is to show/hide the job roles in a bullet list, not for the visibility of the JobRoleIndicator.
  onHandleClick: () => dispatch(showTestJobRoles({ isVisible: true }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // mergeProps,
)(JobRoleIndicator);
