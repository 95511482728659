import { Layout, Text, Button } from "@prism/psm-ui-components";
import React from "react";
import AddIconSVGWrappedComponent from "dux/_components/buttons/AddIconSVGWrappedComponent";

/**
 * A View component for an addons button.
 * @param {Object} obj - destructed params.
 * @param {String} obj.componentId
 * @param {Boolean} obj.isHidden
 * @param {String} obj.buttonLabel
 * @param {Callback} obj.onClick
 * @return {JSX.Element|null}
 */
export const AddonListAddButton = ({ componentId, isHidden, buttonLabel, onClick }) => {
  if (isHidden) {
    return null;
  }

  return (
    <Layout.Box id={componentId}>
      <Button variant="link" onClick={onClick}>
        <Layout.Cluster space="cluster-space-4" childOverrides={{ flexWrap: "nowrap" }}>
          <AddIconSVGWrappedComponent />
          <Text size="text-size-xl">{buttonLabel}</Text>
        </Layout.Cluster>
      </Button>
    </Layout.Box>
  );
};
