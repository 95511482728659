import { pageNames } from "./checkInOutConstants";

export const routePaths = {
  ROOT: "/",
  LOGIN: "/login",
  ENV_DETAILS: "/env_details",
  PET_PARENT_PROFILE: "/pet-parent-profile",
  DASHBOARD: "/dashboard",
  SEARCH: "/search",
  ASSOCIATES: "/associates",
  ASSOCIATE: "/associate",
  ASSOCIATE_SCHEDULING: "/associate-scheduling",
  TEMPLATE_SCHEDULING: "/template-scheduling",
  SCHEDULES: "/schedules",
  MY_SCHEDULE: "/my-schedule",
  SRC_DASHBOARD: "/searchSRC",
  BOOKING: "/booking",
  STANDALONE: "/standalone",
  CHECK_IN: `/${pageNames.CHECK_IN}`,
  CHECK_OUT: `/${pageNames.CHECK_OUT}`,
  PAST_APPOINTMENT: `/${pageNames.PAST_APPOINTMENT}`,
  FUTURE_APPOINTMENT: `/${pageNames.FUTURE_APPOINTMENT}`,
  QUICK_QUOTE: "/quick-quote",
  FORGOT_PIN: "/quick-pin/forgot-pin",
  SET_PIN: "/quick-pin/set-pin",
  ENTER_PIN: "/quick-pin/enter-pin",
  SELECT_USER: "/quick-pin/select-user",
  IMPLICIT_CALLBACK: "/implicit/callback",
  SCHEDULE_TRAINING: "/schedule-training",
  MANAGE_TRAINING_CLASS: "/manage-training-class",
  SERVICE_MANAGEMENT: "/service-management",
  MANAGE_HOTEL_OVERBOOKING: "/hotel-management/overbooking",
  MANAGE_HOTEL: "/hotel-management",
  MANAGE_OVERBOOKING_SETUP: "/hotel-management/overbooking/setup",
  MANAGE_OVERBOOKING_EXCEPTION_DATES: "/hotel-management/overbooking/exception-dates",
  MANAGE_OVERBOOKING_NEW_EXCEPTION_DATE: "/hotel-management/overbooking/new-exception-date",
  MANAGE_OVERBOOKING_MODFIY_EXCEPTION_DATE: "/hotel-management/overbooking/modify-exception-date",
  MANAGE_OVERBOOKING_REVIEW_EXCEPTION_DATE: "/hotel-management/overbooking/review-exception-date",
  SCHEDULE_SUSPENSION: "/scheduled-suspensions",
  ROOM_SELECTION: "/room-selection",
  ROOM_MANAGEMENT: "/room-management",
  ROOM_MANAGEMENT_DETAILS: "/room-details/:room",
  REPORTS: "/reports",
  LABELS: "/reports/labels",
  PURCHASE: "/purchase",
  DDC_PACKAGES: "/ddc-packages",
};

export const routePathPartials = {
  MODIFY_APPOINTMENT: "modify-appointment",
  HOTEL_OVERBOOKING: "overbooking",
  HOTEL_OVERBOOKING_SETUP: "setup",
  HOTEL_OVERBOOKING_EXCEPTION_DATES: "exception-dates",
  HOTEL_OVERBOOKING_NEW_EXCEPTION: "new-exception-date",
  HOTEL_OVERBOOKING_MODFIY_EXCEPTION: "modify-exception-date",
  HOTEL_OVERBOOKING_REVIEW_EXCEPTION: "review-exception-date",
};
