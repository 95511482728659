import React from "react";
import styled from "styled-components";
import { fontSizes } from "web/common/styles/responsive/fonts";
import MedicationNameContainer from "../medicationName/MedicationNameContainer";
import AddNewMedicationButtonContainer from "../addMedication/buttons/AddNewMedicationButtonContainer";
import CancelNewMedicationButtonContainer from "../addMedication/buttons/CancelNewMedicationButtonContainer";
import OtherMedicationNameContainer from "../otherMedicationName/OtherMedicationNameContainer";
import MedicationTypeAmountContainer from "../medicationTypeAmount/MedicationTypeAmountContainer";
import MedicationInstructionsContainer from "../medicationInstructions/MedicationInstructionsContainer";
import MedicationTimeOfDayContainer from "../medicationTimeOfDay/MedicationTimeOfDayContainer";
import { isNewMedication } from "../medication/medicationUtils";
import withClickOutside from "./hoc/withClickOutside";

const MedicationInstructionsWrapper = styled.div`
  font-size: ${fontSizes.regular};
`;

const Grid = styled.div`
  display: grid;
  grid-row-gap: 1.875rem;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 3.75rem;
  font-size: ${fontSizes.regular};
  padding: 1rem 0;
`;

const NewMedicationButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 0px;
`;

class PetProfileMedicationFormComponent extends React.Component {
  handleClickOutside = this.handleClickOutside.bind(this);

  handleClickOutside(e) {
    const { onSubmit } = this.props;
    onSubmit && onSubmit();
  }

  render() {
    const {
      isHidden,
      medicationId,
      medicationIndex,
      medicationIds,
      clickOutsideRef,
      petId,
    } = this.props;
    const newMedicationIndex = isNewMedication(medicationId)
      ? medicationIds.length + 1
      : medicationIndex;

    return !isHidden ? (
      <div ref={clickOutsideRef} handleClickOutside={this.handleClickOutside}>
        <Grid>
          <MedicationNameContainer
            medicationIndex={newMedicationIndex}
            medicationId={medicationId}
            petId={petId}
          />
          <OtherMedicationNameContainer medicationId={medicationId} petId={petId} />
          <MedicationTypeAmountContainer
            medicationId={medicationId}
            medicationIndex={newMedicationIndex}
            petId={petId}
          />
          <MedicationTimeOfDayContainer
            medicationId={medicationId}
            medicationIndex={newMedicationIndex}
            petId={petId}
          />
        </Grid>
        <MedicationInstructionsWrapper>
          <MedicationInstructionsContainer
            medicationId={medicationId}
            medicationIndex={newMedicationIndex}
            petId={petId}
          />
        </MedicationInstructionsWrapper>
        <NewMedicationButtonsContainer>
          <AddNewMedicationButtonContainer medicationId={medicationId} petId={petId} />
          <CancelNewMedicationButtonContainer medicationId={medicationId} petId={petId} />
        </NewMedicationButtonsContainer>
      </div>
    ) : null;
  }
}

export default withClickOutside(PetProfileMedicationFormComponent);
