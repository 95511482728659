import React from "react";
import { connect } from "react-redux";
import { Form, Text } from "@prism/psm-ui-components";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { getCurrentPet } from "core/selectors/persistentSelectors";
import { addNeedsReviewToPetInItinerary } from "dux/hotelCheckInOutPetNeedsReview/hotelCheckInOutPetNeedsReviewHelpers";
import { selectPetNeedsReview } from "dux/hotelCheckInOutPetNeedsReview/hotelCheckInOutPetNeedsReviewSelectors";
import { getConstructedHotelItinerary } from "dux/hotelItinerary/hotelConstructedItinerarySelectors";
import { putHotelItinerary } from "dux/hotelItinerary/hotelItineraryActions";
import LoadingWrapper from "web/common/LoadingWrapper";
import { selectIsReservationDisabled } from "@/dux/hotelItinerary/hotelItinerarySelectors";

export const HotelCheckInOutPetNeedsReviewComponent = props => {
  const {
    componentId,
    isHidden,
    isDisabled,
    checked,
    label,
    petId,
    onClick,
    itineraryPayload,
    isLoading,
  } = props;

  if (isHidden) {
    return null;
  }

  return (
    <LoadingWrapper isLoading={isLoading}>
      <LayoutCluster>
        <Form.Field.Checkbox
          id={componentId}
          checked={checked}
          disabled={isDisabled}
          onClick={({ target }) => onClick(petId, target?.checked, itineraryPayload)}
        />

        <Text color={isDisabled ? "text-color-disabled" : "text-color-black"}>{label}</Text>
      </LayoutCluster>
    </LoadingWrapper>
  );
};

export const HotelCheckInOutPetNeedsReviewContainer = connect(
  state => {
    const needsReview = selectPetNeedsReview(state);
    const petId = getCurrentPet(state);
    const itineraryPayload = getConstructedHotelItinerary(state);
    // TODO: once the itinerary needs review PATCH is implemented, use that for loading state,
    // there is already a full page loading state for PUT_HOTEL_ITINERARY
    const isLoading = false; // createLoadingSelector([PUT_HOTEL_ITINERARY])(state);
    const isReservationDisabled = selectIsReservationDisabled(state);

    return {
      componentId: "ReviewReservationCheckBox_HotelCheckInOut",
      isHidden: false,
      isDisabled: isLoading || isReservationDisabled,
      checked: needsReview,
      label: isLoading ? "Updating Reservation" : "Review Reservation",
      petId,
      itineraryPayload,
      isLoading,
    };
  },
  dispatch => {
    return {
      onClick: (petId, needsReview, itineraryPayload) => {
        // adding/updating the needs review value to the entire pets hotel itinerary object.
        const itineraryWithNeedsReview = addNeedsReviewToPetInItinerary(
          itineraryPayload,
          needsReview,
          petId,
        );

        dispatch(putHotelItinerary({ hotelItinerary: itineraryWithNeedsReview }));
      },
    };
  },
)(HotelCheckInOutPetNeedsReviewComponent);
