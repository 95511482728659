export default {
  LOAD_ITINERARY: "LOAD_ITINERARY",
  LOAD_ITINERARY_REQUEST: "LOAD_ITINERARY_REQUEST",
  LOAD_ITINERARY_SUCCESS: "LOAD_ITINERARY_SUCCESS",
  LOAD_ITINERARY_FAILURE: "LOAD_ITINERARY_FAILURE",

  CREATE_ITINERARY: "CREATE_ITINERARY",
  CREATE_ITINERARY_REQUEST: "CREATE_ITINERARY_REQUEST",
  CREATE_ITINERARY_SUCCESS: "CREATE_ITINERARY_SUCCESS",
  CREATE_ITINERARY_FAILURE: "CREATE_ITINERARY_FAILURE",

  DELETE_ITINERARY: "DELETE_ITINERARY",
  DELETE_ITINERARY_REQUEST: "DELETE_ITINERARY_REQUEST",
  DELETE_ITINERARY_SUCCESS: "DELETE_ITINERARY_SUCCESS",
  DELETE_ITINERARY_FAILURE: "DELETE_ITINERARY_FAILURE",

  FINALIZE_ITINERARY: "FINALIZE_ITINERARY",
  FINALIZE_ITINERARY_REQUEST: "FINALIZE_ITINERARY_REQUEST",
  FINALIZE_ITINERARY_SUCCESS: "FINALIZE_ITINERARY_SUCCESS",
  FINALIZE_ITINERARY_FAILURE: "FINALIZE_ITINERARY_FAILURE",

  LOAD_ITINERARIES: "LOAD_ITINERARIES",
  LOAD_ITINERARIES_REQUEST: "LOAD_ITINERARIES_REQUEST",
  LOAD_ITINERARIES_SUCCESS: "LOAD_ITINERARIES_SUCCESS",
  LOAD_ITINERARIES_FAILURE: "LOAD_ITINERARIES_FAILURE",

  LOAD_ITINERARIES_LIVE_UPDATES: "LOAD_ITINERARIES_LIVE_UPDATES",

  LOAD_ITINERARIES_BY_CUSTOMER: "LOAD_ITINERARIES_BY_CUSTOMER",
  LOAD_ITINERARIES_BY_CUSTOMER_REQUEST: "LOAD_ITINERARIES_BY_CUSTOMER_REQUEST",
  LOAD_ITINERARIES_BY_CUSTOMER_SUCCESS: "LOAD_ITINERARIES_BY_CUSTOMER_SUCCESS",
  LOAD_ITINERARIES_BY_CUSTOMER_FAILURE: "LOAD_ITINERARIES_BY_CUSTOMER_FAILURE",

  LOAD_INVOICE: "LOAD_INVOICE",
  LOAD_INVOICE_REQUEST: "LOAD_INVOICE_REQUEST",
  LOAD_INVOICE_SUCCESS: "LOAD_INVOICE_SUCCESS",
  LOAD_INVOICE_FAILURE: "LOAD_INVOICE_FAILURE",

  LOAD_SERVICE_CARD: "LOAD_SERVICE_CARD",
  LOAD_SERVICE_CARD_REQUEST: "LOAD_SERVICE_CARD_REQUEST",
  LOAD_SERVICE_CARD_SUCCESS: "LOAD_SERVICE_CARD_SUCCESS",
  LOAD_SERVICE_CARD_FAILURE: "LOAD_SERVICE_CARD_FAILURE",

  LOAD_SERVICE_CARDS: "LOAD_SERVICE_CARDS",
  LOAD_SERVICE_CARDS_REQUEST: "LOAD_SERVICE_CARDS_REQUEST",
  LOAD_SERVICE_CARDS_SUCCESS: "LOAD_SERVICE_CARDS_SUCCESS",
  LOAD_SERVICE_CARDS_FAILURE: "LOAD_SERVICE_CARDS_FAILURE",

  UPDATE_ITINERARY_STATUS: "UPDATE_ITINERARY_STATUS",
  UPDATE_ITINERARY_STATUS_REQUEST: "UPDATE_ITINERARY_STATUS_REQUEST",
  UPDATE_ITINERARY_STATUS_SUCCESS: "UPDATE_ITINERARY_STATUS_SUCCESS",
  UPDATE_ITINERARY_STATUS_FAILURE: "UPDATE_ITINERARY_STATUS_FAILURE",
  UPDATE_ITINERARY_PAYMENT_STATUS: "UPDATE_ITINERARY_PAYMENT_STATUS",
  UPDATE_ITINERARY_PAYMENT_STATUS_REQUEST: "UPDATE_ITINERARY_PAYMENT_STATUS_REQUEST",
  UPDATE_ITINERARY_PAYMENT_STATUS_SUCCESS: "UPDATE_ITINERARY_PAYMENT_STATUS_SUCCESS",
  UPDATE_ITINERARY_PAYMENT_STATUS_FAILURE: "UPDATE_ITINERARY_PAYMENT_STATUS_FAILURE",
  CREATE_ITINERARY_FROM_EXISTING_ITINERARY: "CREATE_ITINERARY_FROM_EXISTING_ITINERARY",

  UNDO_LAST_ITINERARY_STATUS_UPDATE: "UNDO_LAST_ITINERARY_STATUS_UPDATE",
  UNDO_LAST_ITINERARY_STATUS_UPDATE_REQUEST: "UNDO_LAST_ITINERARY_STATUS_UPDATE_REQUEST",
  UNDO_LAST_ITINERARY_STATUS_UPDATE_SUCCESS: "UNDO_LAST_ITINERARY_STATUS_UPDATE_SUCCESS",
  UNDO_LAST_ITINERARY_STATUS_UPDATE_FAILURE: "UNDO_LAST_ITINERARY_STATUS_UPDATE_FAILURE"
};
