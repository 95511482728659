import { storeCapabilitiesActionTypes } from "./EnhancedServicesListActions";

// The capabilities api could possibly return an empty array, this can cause an issue with how the feature
// rendering, e.g. hotel dashboard my not render correctly. By setting initialState to [{}], we force
// a change to the state regardless if the capabilities comes back empty or with actual data. We can
// then handle this via a useEffect. -- see DashboardComponent as an example.
const initialState = {
  capabilities: [{}],
};

const storeCapabilitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case storeCapabilitiesActionTypes.GET_STORE_CAPABILITIES_SUCCESS:
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        capabilities: action?.result?.capabilities,
      };

    default:
      return state;
  }
};

export default storeCapabilitiesReducer;
