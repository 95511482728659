import React from "react";
import { connect } from "react-redux";
import { Layout, Form, Text } from "@prism/psm-ui-components";
import { getItineraryInvoice } from "dux/hotelItinerary/hotelItinerarySelectors";
import { LayoutBox } from "@/layout/box/Box";

export const InvoiceGeneratedCheckBox = props => {
  const {
    componentId,
    isHidden,
    onChange,
    isChecked,
    checkBoxLabel,
    labelDisabled,
    checkBoxDisabled,
  } = props;

  if (isHidden) {
    return null;
  }

  return (
    <LayoutBox padding="scale-0">
      <Layout.Cluster
        id={componentId}
        space="cluster-space-2"
        justify="left"
        style={{ paddingLeft: "1rem" }}
      >
        <Form.Field.Checkbox
          id={`${componentId}__Checkbox`}
          changed={onChange}
          checked={isChecked}
          disabled={checkBoxDisabled}
        />
        <Text
          id={`${componentId}__Label`}
          color={labelDisabled ? "text-color-disabled" : "text-color-black"}
        >
          {checkBoxLabel}
        </Text>
      </Layout.Cluster>
    </LayoutBox>
  );
};

// CONTAINER ----------------------------------------------------------------------------------------
export const HotelBookingInvoiceGeneratedCheckbox = connect(
  () => {
    return {
      isHidden: false,
      componentId: "HotelBookingInvoiceGeneratedCheckbox",
      isChecked: false,
      labelDisabled: true,
      checkBoxDisabled: true,
      checkBoxLabel: "Invoice Generated",
    };
  },
  () => {
    return {
      dispatchInvoiceGenerated: ({}) => {
        // specific to Hotel
        // dispatch(someAction({}));
      },
    };
  },
  (mapProps, dispatchProps) => {
    const {
      componentId,
      isHidden,
      isChecked,
      checkBoxLabel,
      labelDisabled,
      checkBoxDisabled,
    } = mapProps;
    const { dispatchInvoiceGenerated } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,
      isChecked,
      checkBoxLabel,
      labelDisabled,
      checkBoxDisabled,

      // actions to pass to view
      onChange: () => dispatchInvoiceGenerated({}),
    };
  },
)(InvoiceGeneratedCheckBox);

export const HotelCheckInOutInvoiceGeneratedCheckbox = connect(
  state => {
    return {
      isHidden: false,
      componentId: "HotelCheckInOutInvoiceGeneratedCheckbox",
      isChecked: !!getItineraryInvoice(state)?.invoiceId,
      labelDisabled: true,
      checkBoxDisabled: true,
      checkBoxLabel: "Invoice Generated",
    };
  },
  () => {
    return {
      dispatchInvoiceGenerated: ({}) => {
        // specific to Hotel
        // dispatch(someAction({}));
      },
    };
  },
  (mapProps, dispatchProps) => {
    const {
      componentId,
      isHidden,
      isChecked,
      checkBoxLabel,
      labelDisabled,
      checkBoxDisabled,
    } = mapProps;
    const { dispatchInvoiceGenerated } = dispatchProps;
    return {
      // control what props get passed to the view
      componentId,
      isHidden,
      isChecked,
      checkBoxLabel,
      labelDisabled,
      checkBoxDisabled,

      // actions to pass to view
      onChange: () => dispatchInvoiceGenerated({}),
    };
  },
)(InvoiceGeneratedCheckBox);
