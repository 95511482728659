/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const hotelDeparturesActionTypes = {
  LOAD_HOTEL_DEPARTURES_SUCCESS: "LOAD_HOTEL_DEPARTURES_SUCCESS",
  LOAD_HOTEL_DEPARTURES_REQUEST: "LOAD_HOTEL_DEPARTURES_REQUEST",
  LOAD_HOTEL_DEPARTURES_FAILURE: "LOAD_HOTEL_DEPARTURES_FAILURE",
  CLEAR_HOTEL_DEPARTURES: "CLEAR_HOTEL_DEPARTURES",
};
/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

export const loadHotelDeparturesSuccess = ({ departures }) => ({
  type: hotelDeparturesActionTypes.LOAD_HOTEL_DEPARTURES_SUCCESS,
  departures,
});

export const loadHotelDeparturesRequest = ({ departures }) => ({
  type: hotelDeparturesActionTypes.LOAD_HOTEL_DEPARTURES_REQUEST,
  departures,
});

export const loadHotelDeparturesFailure = ({ error }) => ({
  type: hotelDeparturesActionTypes.LOAD_HOTEL_DEPARTURES_FAILURE,
  error,
});

export const clearHotelDepartures = () => ({
  type: hotelDeparturesActionTypes.CLEAR_HOTEL_DEPARTURES,
});
