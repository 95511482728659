import { FTCO, BGM, TRAINING } from "@/dux/bgm/availableBundlesByPet/availableBundlesConstents";

export default function formatBundleLabelFactory(formatLabelFunction) {
  return function formatBundle(bundle = {}) {
    const {
      petServiceDisplayName,
      quantityPurchased,
      quantityReceived,
      offerType,
      savings = {},
    } = bundle;
    const { percentage } = savings;

    const percentOffCopy = formatLabelFunction(petServiceDisplayName, `${percentage}% off`);
    const bundleTypeCopy = formatLabelFunction(
      petServiceDisplayName,
      `${quantityPurchased}/${quantityReceived}`,
    );

    switch (offerType) {
      case FTCO:
        return percentOffCopy;
      case BGM:
        return bundleTypeCopy;
      case TRAINING:
        // If only one bundle is purchased, just display the pet service name since there's no bundle discount.
        return percentage ? percentOffCopy : petServiceDisplayName;
    }
  };
}
