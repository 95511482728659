/* * ----------------------------------------------------------------------- * *\
  Action Creators  getReservationCartDetailsConfirmation
\* * ----------------------------------------------------------------------- * */
export const GET_HOTEL_ELIGIBILITY = "GET_HOTEL_ELIGIBILITY";
export const GET_HOTEL_ELIGIBILITY_REQUEST = "GET_HOTEL_ELIGIBILITY_REQUEST";
export const GET_HOTEL_ELIGIBILITY_SUCCESS = "GET_HOTEL_ELIGIBILITY_SUCCESS";
export const GET_HOTEL_ELIGIBILITY_FAILURE = "GET_HOTEL_ELIGIBILITY_FAILURE";
export const POST_HOTEL_ELIGIBILITY = "POST_HOTEL_ELIGIBILITY";
export const POST_HOTEL_ELIGIBILITY_REQUEST = "POST_HOTEL_ELIGIBILITY_REQUEST";
export const POST_HOTEL_ELIGIBILITY_SUCCESS = "POST_HOTEL_ELIGIBILITY_SUCCESS";
export const POST_HOTEL_ELIGIBILITY_FAILURE = "POST_HOTEL_ELIGIBILITY_FAILURE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

export const getHotelEligibility = ({
  hotelEngagementId,
  customerKey,
  eligibilityType,
  payload,
}) => ({
  type: GET_HOTEL_ELIGIBILITY,
  hotelEngagementId,
  eligibilityType,
  customerKey,
  payload,
});

export const getHotelEligibilityRequest = () => ({
  type: GET_HOTEL_ELIGIBILITY_REQUEST,
});

export const getHotelEligibilitySuccess = payload => ({
  type: GET_HOTEL_ELIGIBILITY_SUCCESS,
  payload,
});

export const getHotelEligibilityFailure = ({ error }) => ({
  type: GET_HOTEL_ELIGIBILITY_FAILURE,
  error,
});

export const postHotelEligibility = ({
  hotelEngagementId,
  customerKey,
  eligibilityType,
  payload,
}) => ({
  type: POST_HOTEL_ELIGIBILITY,
  hotelEngagementId,
  eligibilityType,
  customerKey,
  payload,
});

export const postHotelEligibilityRequest = () => ({
  type: POST_HOTEL_ELIGIBILITY_REQUEST,
});

export const postHotelEligibilitySuccess = payload => ({
  type: POST_HOTEL_ELIGIBILITY_SUCCESS,
  payload,
});

export const postHotelEligibilityFailure = ({ error }) => ({
  type: POST_HOTEL_ELIGIBILITY_FAILURE,
  error,
});
