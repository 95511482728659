import { connect } from "react-redux";
import { Text } from "@prism/psm-ui-components";
import { getTrainingAttendeeById } from "../trainingClassSessions/selectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";

export default connect((state, { attendeeId }) => {
  const storeNumber = getStoreNumber(state);
  const { redemptionStatus } = getTrainingAttendeeById(state, {
    storeNumber,
    attendeeId,
  });
  const formattedStatus = redemptionStatus ? redemptionStatus : "N/A";
  return { align: "right", children: `Status: ${formattedStatus}` };
})(Text);
