/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const searchForFeatureActionTypes = {
  SEARCH_FOR_FEATURE: "SEARCH_FOR_FEATURE"
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const searchForFeature = ({ searchValue }) => ({
  type: searchForFeatureActionTypes.SEARCH_FOR_FEATURE,
  searchValue
});
