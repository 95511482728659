import React from "react";
import { Layout, Text } from "@prism/psm-ui-components";

const TrainingAppointmentDetails = ({
  sessionStartDate,
  storeSessionTime,
  associateName,
  customerSessionTime,
}) => {
  return (
    <Layout.Stack space="stack-space-2">
      <Text bold="bold" align="center" size="text-size-base" letterSpacing="ext-tracking-normal">
        {sessionStartDate}
      </Text>
      <Text bold="bold" align="center" size="text-size-base" letterSpacing="ext-tracking-normal">
        {customerSessionTime}
      </Text>
      <Text align="center" size="text-size-base" letterSpacing="ext-tracking-normal">
        {storeSessionTime}
      </Text>
      <Text align="center" size="text-size-base" letterSpacing="ext-tracking-normal">
        {associateName}
      </Text>
    </Layout.Stack>
  );
};

export default TrainingAppointmentDetails;
