import jobRoleActionTypes from "../actionTypes/jobRoleActionTypes";

export const showTestJobRoles = ({ isVisible }) => ({
  type: jobRoleActionTypes.SHOW_TEST_ROLES,
  isVisible
});

export const updateTestJobRoles = ({ jobRoles }) => ({
  type: jobRoleActionTypes.UPDATE_TEST_JOB_ROLES,
  jobRoles
});

export const mockDifferentJobRole = ({ jobRole, isAccessLevelMocked }) => ({
  type: jobRoleActionTypes.MOCK_DIFFERENT_JOB_ROLE,
  jobRole,
  isAccessLevelMocked
});

export const updateAccessLevelInState = ({ mockAccessLevel }) => ({
  type: jobRoleActionTypes.UPDATE_ACCESS_LEVEL_IN_STATE,
  mockAccessLevel
});

export const mockTestRoleFailure = ({ error }) => ({
  type: jobRoleActionTypes.MOCK_TEST_ROLE_FAILURE,
  error
});

export const resetMockJobRoles = () => ({
  type: jobRoleActionTypes.RESET_MOCK_JOB_ROLES
});
