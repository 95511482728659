import React from "react";
import styled from "styled-components";
import Button from "../../../common/commonButton";
import { fontSizes } from "web/common/styles/responsive/fonts";

const StyledBookingRedirectionModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 12.5rem;
`;

const Title = styled.div`
  font-size: ${fontSizes.big};
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  align-items: center;
  padding-bottom: 1rem;
`;

const BookingRedirectionModal = props => {
  const { onRedirection, redirectionReason } = props;
  return (
    <StyledBookingRedirectionModal>
      <Title>
        <b>{redirectionReason}</b>
      </Title>
      <div />
      You will be redirected to the dashboard.
      <div />
      <Footer>
        <Button label="OK" onClick={onRedirection} />
      </Footer>
    </StyledBookingRedirectionModal>
  );
};

export default BookingRedirectionModal;
