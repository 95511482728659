import moment from "moment";

/** @module todayTimestamp */

/**
 * Converts today's date into a timestamp.
 *
 * @exports getTodayTimestamp
 * @returns {Number} - timestamp
 */
export default function getTodayTimestamp() {
  return moment()
    .startOf("day")
    .utc()
    .valueOf();
}
