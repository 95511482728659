import React from "react";
import { connect } from "react-redux";
import { Button } from "@prism/psm-ui-components";
import { createLoadingSelector } from "@/core/selectors/utils";
import { GET_TRAINING_CLASS_AVAILABILITY } from "../trainingClassAvailability/trainingClassAvailabilityActions";
import { GET_TRAINING_ASSOCIATES } from "../trainingAssociates/trainingAssociatesActions";
import { getRescheduleAttendeeId } from "./manageTrainingClassPageSelectors";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import { getTrainingAttendeeById } from "../trainingClassSessions/selectors";
import {
  getSelectedTimeSlot,
  getCustomerTimeZone,
} from "../scheduleTrainingAppointment/scheduleTrainingAppointmentSelectors";
import {
  PUT_TRAINING_CLASS_ITINERARY,
  putTrainingClassItinerary,
  getTrainingClassItinerary,
} from "../trainingClassItineraries/trainingClassItinerariesActions";
import {
  toggleRescheduleTrainingAppointmentModal,
  toggleRescheduleTrainingAppointmentFlashMessage,
  setRescheduleClassSessionId,
} from "./manageTrainingClassPageActions";
import { resetScheduleTrainingAppointment } from "../scheduleTrainingPage/scheduleTrainingPageActions";

export function RescheduleTrainingAppointmentButton({ isDisabled, onSubmit }) {
  return (
    <Button variant="prism-primary" disabled={isDisabled} onClick={onSubmit}>
      Reschedule
    </Button>
  );
}

export default connect(
  (state) => {
    const attendeeId = getRescheduleAttendeeId(state);
    const { customerId, itineraryId, petId } = getTrainingAttendeeById(state, { attendeeId });
    const storeNumber = getStoreNumber(state);
    const isLoading = createLoadingSelector([
      GET_TRAINING_CLASS_AVAILABILITY,
      GET_TRAINING_ASSOCIATES,
      PUT_TRAINING_CLASS_ITINERARY,
    ])(state);
    const selectedTimeSlot = getSelectedTimeSlot(state);
    const customerTimeZone = getCustomerTimeZone(state, { storeNumber });
    return {
      isLoading,
      selectedTimeSlot,
      customerTimeZone,
      customerId,
      itineraryId,
      petId,
      isDisabled: isLoading || !selectedTimeSlot,
    };
  },
  (dispatch) => ({
    dispatchPutTrainingItinerary: (payload) => {
      dispatch(putTrainingClassItinerary(payload));
    },
    dispatchToggleRescheduleTrainingAppointmentModal: () => {
      dispatch(toggleRescheduleTrainingAppointmentModal());
    },
    dispatchGetTrainingItinerary: ({ customerKey, itineraryId, onComplete }) => {
      dispatch(getTrainingClassItinerary({ customerKey, itineraryId, onComplete }));
    },
    toggleRescheduleTrainingAppointmentFlashMessage: () => {
      dispatch(toggleRescheduleTrainingAppointmentFlashMessage());
    },
    resetScheduleTrainingAppointment: () => {
      dispatch(resetScheduleTrainingAppointment());
    },
    setRescheduleClassSessionId: (classSessionId) => {
      dispatch(setRescheduleClassSessionId(classSessionId));
    },
  }),
  (stateProps, dispatchProps) => {
    const {
      isDisabled,
      selectedTimeSlot,
      customerTimeZone,
      customerId,
      itineraryId,
      petId,
    } = stateProps;
    const {
      dispatchGetTrainingItinerary,
      dispatchPutTrainingItinerary,
      dispatchToggleRescheduleTrainingAppointmentModal,
      toggleRescheduleTrainingAppointmentFlashMessage,
      resetScheduleTrainingAppointment,
      setRescheduleClassSessionId,
    } = dispatchProps;
    return {
      isDisabled,
      onSubmit: () => {
        dispatchToggleRescheduleTrainingAppointmentModal();

        dispatchPutTrainingItinerary({
          selectedTimeSlot,
          customerTimeZone,
          itineraryId,
          petId,
          customerKey: customerId,
          classSessionId: selectedTimeSlot,
          isReschedule: true,
          onComplete: ({ classSessionId, itineraryId }) => {
            setRescheduleClassSessionId(classSessionId);
            resetScheduleTrainingAppointment();

            dispatchGetTrainingItinerary({
              customerKey: customerId,
              itineraryId,
              onComplete: () => {
                toggleRescheduleTrainingAppointmentFlashMessage();
              },
            });
          },
        });
      },
    };
  },
)(RescheduleTrainingAppointmentButton);
