// @ts-nocheck
import * as React from 'react';
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select';
import { createFormInputEventFromElement } from '../../utils';
import { buildCustomStyles } from './legacy-select-styles';
import Text from '../../../Text';

import './legacy-styles.css';

export interface SelectValues {
  label: string | number;
  value: string | number;
}

/* @ts-ignore */
// Ignoring Typescript errors for now since legacy React Select types do not seem to be compatible with React 17 types
export interface LegacyReactSelectProps extends SelectProps<OptionTypeBase> {
  label?: string;
  onChange: (e: any) => void;
  value?: string | number | undefined | SelectValues;
}

export interface LegacyReactSelectState {
  isActive: boolean;
}

export default class LegacySelect extends React.Component<LegacyReactSelectProps, LegacyReactSelectState> {
  constructor(props: LegacyReactSelectProps) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.getStaticInputProps = this.getStaticInputProps.bind(this);
    this.state = { isActive: false };
  }

  selectRef = React.createRef<HTMLInputElement>();

  handleFocus() {
    const { onDone } = this.props;
    this.setState({ isActive: true });
    onDone && onDone();
  }

  handleBlur() {
    this.setState({ isActive: false });
  }

  handleChange(value: OptionTypeBase | null) {
    if (!value) {
      return;
    }

    const inputRef = this.selectRef.current;
    if (this.selectRef.current) {
      this.selectRef.current.value = value.value ? value.value : '';
      // setErrorMessage(getErrorMessage(inputRef, 'color'));
      // dispatch to form
      createFormInputEventFromElement(inputRef, this.props.name || '', value.value);

      // this.props.handleErrorMessage(inputRef);
      // if (this.props.onChange) {
      //   this.props.onChange(value.label);
      // }
    }
  }

  getStaticInputProps() {
    const { isStatic, inputValue } = this.props;

    // const StaticInput = ({ children, value, ...props }: { children?: React.ReactNode; value: string | number }) => {
    //   return (
    //     <div className="psm-legacy-static-input" style={{ marginBottom: '5px' }} {...props}>
    //       {value}
    //     </div>
    //   );
    // };

    if (isStatic) {
      return {
        // components: { Input: StaticInput },
        menuIsOpen: false,
        autoSize: false,
        inputValue: inputValue || ' ',
      };
    }
    return {};
  }

  render() {
    const {
      name,
      value,
      isDisabled,
      options,
      label = '',
      className,
      onInvalid,
      error,
      labelStyle,
      ...props
    } = this.props;
    const customStyles = buildCustomStyles({ ...props, isDisabled, error, ...this.state });

    return (
      <div data-testid={name} className="legacy-react-select">
        <Text bold style={labelStyle}>
          {label}
        </Text>
        <div className={className} onFocus={this.handleFocus} onBlur={this.handleBlur}>
          {props.nonEditable ? (
            <div style={{ fontSize: '1.125rem', color: '#333333' }}>
              <>{this.props.value ? this.props.value : 'No value'}</>
            </div>
          ) : (
            <div
              className="select-container"
              style={{ display: 'flex', flexDirection: 'row', fontFamily: 'lato', minWidth: '10rem' }}
            >
              <div className="name-container" style={{ width: '100%' }}>
                <ReactSelect
                  styles={customStyles}
                  options={options}
                  isDisabled={isDisabled}
                  /* @ts-ignore */
                  value={value}
                  tabIndex={props.tabIndex?.toString()}
                  {...props}
                  {...this.getStaticInputProps()}
                  onChange={(e) => {
                    if (props.onChange) {
                      /* @ts-ignore */
                      props.onChange(e);
                    }
                    this.handleChange(e);
                  }}
                />
                {/* @ts-ignore */}
                <input className="psm-hidden-input" name={name} ref={this.selectRef} onInvalid={onInvalid} {...props} />
              </div>
              {/* {hasIcon && <Icon src={CautionImage} />} */}
            </div>
          )}
        </div>
      </div>
    );
  }
}
