import { GET_TRAINING_ASSOCIATES_SUCCESS } from "./trainingAssociatesActions";

const initialState = [];

const trainingAssociatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRAINING_ASSOCIATES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default trainingAssociatesReducer;
