import * as React from 'react';
import * as CSS from 'csstype';
import { psx } from '../../../utils/';
import { LayoutProps } from '../';
import { CENTER_TOKENS } from './_tokens';

import './styles.css';

export interface CenterProps extends LayoutProps<React.ElementType> {
  /** The minimum space on either side of the content */
  gutterWidth?: keyof typeof CENTER_TOKENS['GUTTER_WIDTH'];
  /** A CSS max-width value - INLINE styles */
  maxWidth?: number | CSS.Property.MaxWidth;
  /** Center child elements based on their content width */
  intrinsic?: boolean;
  /** Center align the text */
  centerText?: boolean;
  /** Completely removes the component from the DOM. Useful for feature flags or t hide or show
   * a component. */
  isHidden?: boolean;
}

/**
 * Generates a horizontally centered column for page content.
 *
 * [Source](https://every-layout.dev/layouts/center)
 */
export const Center: React.FunctionComponent<CenterProps> = ({
  gutterWidth = 'center-gutter-0',
  centerText = false,
  intrinsic = false,
  maxWidth = 800,
  style = {},
  className,
  children,
  testid,
  as,
  isHidden = false,
  ...props
}) => {
  const id = 'psm-layout__center';
  const Component = as || 'div';
  const classNames = psx('psm', id, className, {
    [`${id}--centerText`]: centerText,
    [`${id}--intrinsic`]: intrinsic,
  });

  if (isHidden) {
    return null;
  }

  return (
    <Component
      className={classNames}
      data-testid={[id, testid].join('-')}
      data-gutter-width={gutterWidth}
      style={{ maxWidth, ...style }}
      {...props}
    >
      {children}
    </Component>
  );
};
