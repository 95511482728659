import { connect } from "react-redux";
import { color } from "web/common/styles/theme";
import IconWithLabel from "dux/_components/icon/IconWithLabel";
import PreCheckedInIcon from "../../assets/icons/pre-checked-in-appointment.svg";
import { getAssociate, isAssociateVirtualTrainer } from "@/core/selectors/entitiesSelector";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";

const mapStateToProps = (state, { router }) => {
  const IS_VIRTUAL_TRAINER_MSG = "is a virtual trainer.";
  const associateId = router?.params?.associateId;
  const { associateName } = getAssociate(state, { associateId });
  const isVirtualTrainer = isAssociateVirtualTrainer(state, { associateId });

  return {
    componentId: "pre-checked-in-icon",
    src: PreCheckedInIcon,
    label: `${associateName} ${IS_VIRTUAL_TRAINER_MSG}`,
    isHidden: !isVirtualTrainer,
    styleObj: {
      justifyContent: "center",
      padding: "0.5em",
      borderBottom: `1px solid ${color.borderGrey}`,
      fontWeight: "bold",
    },
  };
};

export default withRouteProps(connect(mapStateToProps)(IconWithLabel));
