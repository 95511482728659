import { reduce, set } from "lodash/fp";
import moment from "moment";
import getISODateWithTime from "@/core/utils/dateUtils/ISODateWithTime";
import { serviceTypeIds } from "@/core/constants/serviceTypesConstants";

export const normalizeHotelHours = hotelHours =>
  reduce(
    (result, hotelHour) =>
      set(
        moment(hotelHour.ForDate).format("YYYY-MM-DD"),
        {
          ...hotelHour,
          OpenDateTime: getISODateWithTime(hotelHour.ForDate, hotelHour.OpenTime),
          CloseDateTime: getISODateWithTime(hotelHour.ForDate, hotelHour.CloseTime),
        },
        result,
      ),
    {},
    hotelHours,
  );

export const includeDateIfHoursAreMissing = (hours = {}, date) => {
  if (!date) return hours;
  return hours[date] ? hours : { ...hours, [date]: {} };
};

export const getStoreServiceHours = serviceId => (data, date) => {
  const storeServiceHours = data?.StoreServices?.find(service => service.ServiceId === serviceId)
    ?.StoreServiceHoursForDateList;
  const normalizedHours = normalizeHotelHours(storeServiceHours);

  return includeDateIfHoursAreMissing(normalizedHours, date);
};

export const getPetsHotelStoreServiceHours = getStoreServiceHours(serviceTypeIds.HOTEL);
export const getDdcStoreServiceHours = getStoreServiceHours(serviceTypeIds.DDC);
