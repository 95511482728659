import { connect } from "react-redux";
import AlertsModalComponent from "./AlertsModalComponent";
import isPetAndCustomerEligibilityReasonsEmpty from "dux/eligibility/helperUtils/isPetAndCustomerEligibilityReasonsEmpty";
import {
  constructCustomerAndPetSalonEligibility,
  mergeCustomerAndPetHotelEligibilityReasons,
} from "dux/eligibility/helperUtils/constructEligibility";
import { history } from "@/dux/utils/browser/browserHistory";
import { getSystemBookingFlow } from "../../../../setSystemType/selectors/setSystemTypeSelectors";
import { systemName } from "../../../../setSystemType/constants/setSystemTypeConstants";
import { createLoadingSelector } from "@/core/selectors/utils";
import { eligibilityScreenActionTypes } from "@/dux/eligibility/actions/eligibilityByScreenActions";

const mapStateToProps = (state, ownProps) => {
  const systemBookingFlow = getSystemBookingFlow(state);
  let eligibility;
  if (systemBookingFlow === systemName.SALON) {
    eligibility = constructCustomerAndPetSalonEligibility(
      state,
      ownProps.isWarnings,
      ownProps.selectedPets,
    );
  }
  if (systemBookingFlow === systemName.HOTEL) {
    eligibility = mergeCustomerAndPetHotelEligibilityReasons(state, ownProps.selectedPets);
  }
  return {
    eligibility,
    isHidden: isPetAndCustomerEligibilityReasonsEmpty(eligibility),
    inTimeSlot: history.location.pathname.includes("time-slot"),
    inModifyAppointment: history.location.pathname.includes("modify-appointment"),
    inService: history.location.pathname.includes("service"),
    isLoading: createLoadingSelector([
      eligibilityScreenActionTypes.GET_CHECK_IN_SCREEN_ELIGIBILITY,
    ])(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    redirect: () => history.push(history.location.pathname.replace("service", "time-slot")),
    onShouldProceed: () => ownProps.onShouldProceed && ownProps.onShouldProceed(true),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  onProceed:
    ownProps.isWarnings &&
    (() => {
      if (ownProps.onProceed) {
        ownProps.onProceed();
      } else {
        let shouldProceed;
        if (propsFromState.inService) {
          shouldProceed =
            propsFromState.systemBookingFlow === systemName.SALON ||
            !propsFromState.systemBookingFlow
              ? propsFromDispatch.redirect
              : propsFromDispatch.onShouldProceed;
        } else if (propsFromState.inTimeSlot || propsFromState.inModifyAppointment) {
          shouldProceed = propsFromDispatch.onShouldProceed;
        } else {
          shouldProceed = ownProps.onClose;
        }
        shouldProceed();
      }
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AlertsModalComponent);
