import React from 'react';
import './styles.css';
import { pluck } from '../utils';
import { GLOBAL_TOKENS } from '../scaleToken';

// Sets the interface for the Box layout custom properties.
interface CenterProps<C extends React.ElementType> {

  /** A CSS padding value */
  padding?: keyof typeof GLOBAL_TOKENS['SCALE'];
}

// Include types that are not part of CenterProps, such as children,
type Props <C extends React.ElementType> = React.PropsWithChildren<CenterProps<C>> & React.ComponentPropsWithoutRef<C>;

export const LayoutCenter = <C extends React.ElementType>({
  padding = 'scale-S1',
  children,
  style,
  ...props
}: Props<C>):React.ReactElement => {
  return (
    <div
      className="pplt-layout__center"
      style={{
        '--setCenterPadding': pluck(GLOBAL_TOKENS.SCALE, padding),
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
};
