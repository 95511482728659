import React from "react";
import styled from "styled-components";
import QuickViewCustomerCardContainer from "./quickViewCustomerCard/quickViewCustomerCardContainer";
import QuickViewPetCardContainer from "./quickViewPetCard/quickViewPetCardContainer";
import { color, font } from "../../../common/styles/theme";

export default function QuickViewCard(props) {
  const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 400px;
  `;

  const Divider = styled.div`
    border-bottom: 1px solid ${color.ororoMunroe};
    width: 100%;
    margin: 19px 0 19px 0;
  `;

  return (
    <SectionWrapper>
      <QuickViewCustomerCardContainer />
      <Divider />
      {/* <QuickViewPetCardContainer /> */}
    </SectionWrapper>
  );
}
