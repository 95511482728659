import React from "react";
import { connect } from "react-redux";
import { selectOverbookingModalName } from "./overbookingModalsSelectors";
import { overbookingModalTypes } from "./overbookingModalConstants";
import { LayoutBox } from "@/layout/box/Box";
import { OverbookingCancelExceptionModal } from "../overbookingModal/OverbookingModal";

const modals = {
  [overbookingModalTypes.CANCEL_EXCEPTION]: <OverbookingCancelExceptionModal />,
};

/**
 * React view component that renders the current overbooking modal
 * @summary /hotel-management/overbooking page
 * @memberOf Views.Overbooking
 * @function
 * @name OverbookingModalsWrapper
 * @param {Object} props - props passed into the view component
 * @param {String} props.componentId
 * @param {Boolean} props.isHidden
 * @param {JSX.Element} props.modalComponent - modal component to be rendered
 * @returns {JSX.Element|null}
 * @example const Component = connect()(OverbookingModalsWrapper)
 */
const OverbookingModalsWrapper = ({ componentId, isHidden, modalComponent }) => {
  if (isHidden) return null;

  return <LayoutBox id={componentId}>{modalComponent}</LayoutBox>;
};

/**
 * Connect function for hotel overbooking modals
 * @see {@link Views.Overbooking.OverbookingModalsWrapper}
 * @summary /hotel-management/overbooking page
 * @memberOf Views.Overbooking
 * @function
 * @name OverbookingModals
 * @returns {JSX.Element|null}
 * @example <OverbookingModals />
 */
export const OverbookingModals = connect(
  state => {
    const contentType = selectOverbookingModalName(state);

    return {
      componentId: "OverbookingModals",
      isHidden: !contentType,
      modalComponent: modals[contentType],
    };
  },
  dispatch => {
    return {
      onClose: () => dispatch(hideCheckInOutModal()),
    };
  },
)(OverbookingModalsWrapper);
