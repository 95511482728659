import { connect } from "react-redux";
import getIsBGMWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/bgm/getIsBGMWorkflowFeatureFlagHidden";
import getFTCOWorkflowFeatureFlagHidden from "web/enableDisableWorkflowFeatureFlag/selectors/ftco/getFTCOWorkflowFeatureFlagHidden";
import AppointmentsListComponent from "./AppointmentsListComponent";

const mapStateToProps = state => {
  const isBGMFeatureFlagHidden = getIsBGMWorkflowFeatureFlagHidden(state);
  const isFTCOFeatureFlagHidden = getFTCOWorkflowFeatureFlagHidden(state);
  const isBGMOrFTCOEnabled = !isBGMFeatureFlagHidden || !isFTCOFeatureFlagHidden;
  return {
    isHidden: isBGMOrFTCOEnabled,
    componentID: "AppointmentsList",
  };
};

export default connect(mapStateToProps)(AppointmentsListComponent);
