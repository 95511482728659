import { get } from "lodash/fp";

export default function filterIsBoarded({
  isChecked,
  petServiceItems,
  engagements,
  petServiceItemId
}) {
  if (isChecked) {
    const engagementId = get([petServiceItemId, "engagement"], petServiceItems);
    const isBoarded = get([engagementId, "isBoarded"], engagements);

    return !!isBoarded;
  }
  return true;
}
