/**
 * Determines the duration that the user's token should be refreshed for.
 * @param {string} status active | idle : the current status of the user
 * @param {object} env the window environment variables
 * @param {object} timings relevant times for calculations
 * @param {number} timings.lastInteraction the millisecond time the user last interacted with the app
 * @param {number} timings.currentTime the millisecond time as of this call
 * @param {number} timings.sessionDuration the millisecond duration of the current session
 * @returns {number} milliseconds | 0 - duration of new session to be requested or 0 if the user should be logged out
 */
export const calculateRefreshTokenDuration = (
  status,
  env,
  { lastInteraction, currentTime, sessionDuration },
) => {
  if (status === "active") {
    // if the user is still active, then refresh for a full new session
    return env.SESSION_TOKEN_TIMEOUT;
  }
  const userStatusTimeout = env.USER_STATUS_TIMEOUT;
  // the amount of time the user has been idle
  const idleTime = currentTime - lastInteraction;
  // the amount of time the user was active during the session - one userStatusTimeout.
  const activeTime = sessionDuration - idleTime - userStatusTimeout;
  // user has to have been active more than a set minimum to extend the session
  const newSessionDuration = activeTime > env.MIN_USER_ACTIVITY_TIME ? activeTime : 0;

  return newSessionDuration;
};
