import React from "react";
import { connect } from "react-redux";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { Checkbox } from "@petsmart-ui/sparky";
import { Text } from "@prism/psm-ui-components";
import { putCart, PUT_CART } from "../servicesCart/servicesCartActions";
import { createLoadingSelector } from "@/core/selectors/utils";
import { selectHotelCartPetNeedsReview } from "../servicesCartHotel/servicesCartHotelSelectors";
import { toggleNeedsReview } from "../servicesCartHotel/servicesCartHotelActions";

export const NeedsReviewCheckbox = ({
  componentId,
  isHidden = false,
  checked = false,
  disabled = false,
  onChange = () => {},
}) => {
  if (isHidden) return null;

  return (
    <LayoutBox id={componentId} padding="scale-G1">
      <LayoutCluster>
        <Checkbox checked={checked} onChange={onChange} disabled={disabled} />
        <Text>Review Reservation</Text>
      </LayoutCluster>
    </LayoutBox>
  );
};

export const HotelBookingNeedsReviewCheckbox = connect(
  (state, { petId }) => {
    return {
      componentId: "HotelBookingNeedsReviewCheckbox",
      checked: selectHotelCartPetNeedsReview(petId)(state),
      disabled: createLoadingSelector([PUT_CART])(state),
    };
  },
  (dispatch, { petId }) => ({
    onChange: () => {
      dispatch(toggleNeedsReview(petId));
      dispatch(putCart());
    },
  }),
)(NeedsReviewCheckbox);
