import { connect } from "react-redux";
import { setFilteringByEngagementType, getButtons } from "./dux";
import getIsTrainingWorkflowFeatureFlagHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/training/getIsTrainingWorkflowFeatureFlagHidden";
import { ToggleButtonListWithSelectAll } from "./ToggleButtonListWithSelectAll";

export default connect(
  (state) => ({
    componentId: "ToggleEngagementTypeButtonsContainer__toggleButtonListWithSelectAll",
    isHidden: getIsTrainingWorkflowFeatureFlagHidden(state),
    data: getButtons(),
  }),
  {
    setGlobalState: setFilteringByEngagementType,
  },
)(ToggleButtonListWithSelectAll);
