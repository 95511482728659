export const SWITCHER_TOKENS = {
  SPACE: {
    'switcher-space-0': '0px',
    'switcher-space-px': '1px',
    'switcher-space-0.5': '0.125rem',
    'switcher-space-1': '0.25rem',
    'switcher-space-1.5': '0.375rem',
    'switcher-space-2': '0.5rem',
    'switcher-space-2.5': '0.625rem',
    'switcher-space-3': '0.75rem',
    'switcher-space-3.5': '0.875rem',
    'switcher-space-4': '1rem',
    'switcher-space-5': '1.25rem',
    'switcher-space-6': '1.5rem',
    'switcher-space-7': '1.75rem',
    'switcher-space-8': '2rem',
    'switcher-space-9': '2.25rem',
    'switcher-space-10': '2.5rem',
    'switcher-space-11': '2.75rem',
    'switcher-space-12': '3rem',
    'switcher-space-14': '3.5rem',
    'switcher-space-16': '4rem',
    'switcher-space-20': '5rem',
    'switcher-space-24': '6rem',
    'switcher-space-28': '7rem',
    'switcher-space-32': '8rem',
    'switcher-space-36': '9rem',
    'switcher-space-40': '10rem',
    'switcher-space-44': '11rem',
    'switcher-space-48': '12rem',
    'switcher-space-52': '13rem',
    'switcher-space-56': '14rem',
    'switcher-space-60': '15rem',
    'switcher-space-64': '16rem',
    'switcher-space-72': '18rem',
    'switcher-space-80': '20rem',
    'switcher-space-96': '24rem',
  },
  THRESHOLD: {
    'switcher-threshold-1': '12rem',
    'switcher-threshold-2': '16rem',
    'switcher-threshold-3': '24rem',
    'switcher-threshold-4': '32rem',
    'switcher-threshold-5': '40rem',
    'switcher-threshold-6': '48rem',
    'switcher-threshold-7': '52rem',
    'switcher-threshold-8': '75rem',
    'switcher-threshold-9': '100rem',
    'switcher-threshold-10': '150rem',
  },
  GROW_VALUE: {
    'switcher-grow-value-0': '0',
    'switcher-grow-value-1': '0.25',
    'switcher-grow-value-2': '0.375',
    'switcher-grow-value-3': '0.5',
    'switcher-grow-value-4': '0.625',
    'switcher-grow-value-5': '0.75',
    'switcher-grow-value-6': '0.875',
    'switcher-grow-value-7': '1',
    'switcher-grow-value-8': '1.25',
    'switcher-grow-value-9': '1.5',
    'switcher-grow-value-10': '1.75',
    'switcher-grow-value-11': '2',
    'switcher-grow-value-12': '2.25',
    'switcher-grow-value-13': '2.5',
    'switcher-grow-value-14': '2.75',
    'switcher-grow-value-15': '3',
    'switcher-grow-value-16': '3.5',
    'switcher-grow-value-17': '4',
  },
};
