import { bookingAPI } from "./_axiosConfigSystemServicesBooking";
import { endpointVersionString } from "../../utils/envUtils";

/**
 * Get a list of the values available for cancellation reasons.
 * These are required to allow an associate to select the reason
 * for cancellation of an itinerary, engagement or pet service.
 * From System Services Booking API
 * - Get list of reasons for cancellation
 * @returns {*} AxiosPromise
 */
export function fetchCancellationReasons() {
  return bookingAPI.get(`${endpointVersionString()}/settings/cancellationreasons`);
}
