// @ts-nocheck
import React from "react";
import classnames from 'classnames';
import { Layout } from "../../components/Layout"
import Text from "../../components/Text";
import Hr from "../../components/HR/Hr";

import './TitledBox.css';

export interface TitledBoxProps {
  /** DOM Selector id **/
  id: string,

  /** Controls the visibility of the component */
  isHidden?: boolean,

  /** Title of the component **/
  title: string,

  /** Show or hide divider between title and content **/
  isDivided?: boolean,

  /** Determines if this is component is used as a Section or a main title. **/
  isSection: boolean,

  /** Class name to add/override default classes **/
  className?: string,

  children?: React.ReactNode,
}


const TitledBox: React.FC<TitledBoxProps> = (
  { id,
    isHidden,
    title,
    isSection = true, // controls title spacing
    isDivided = false,
    children,
    className,
    ...other}) => {

  const componentClassName = classnames('titledBox__content', className, {});
  const titleSpacing = `psm-titleBox__lists--${isSection ? 'section' : 'main'}`;

  return !isHidden && (
    <Layout.Box id={id} {...other}>
        {/*Title*/}
        { title &&
          <Layout.Box className={titleSpacing}>
            <Text id={`${id}__title`} bold size="text-size-lg">
              {title}
            </Text>
          </Layout.Box>
        }

        {isDivided && <Hr />}

        {/*Box*/}
        <Layout.Box id={`${id}__content`} className={componentClassName}>
          { children }
        </Layout.Box>
    </Layout.Box>
  );
};

export default TitledBox