import { connect } from "react-redux";
import { get } from "lodash/fp";
import CancelBookingAppointment from "./cancelBookingAppointment";
import { cancelAppointment } from "../../../../../core/actionCreators/ui/web/cartActionCreators";
import { getPetIdToRemoveFromCart } from "../../../../../core/selectors/bookingUISelectors";
import { getPet } from "../../../../../core/selectors/entitiesSelector";
import { hideBookingModal } from "../../../../../core/actionCreators/bookingActionCreators";

const mapStateToProps = state => {
  const petIdToRemoveFromCart = getPetIdToRemoveFromCart(state);
  const pet = getPet(state, { petId: petIdToRemoveFromCart });

  return {
    petIdToRemoveFromCart,
    petName: get("petName", pet),
  };
};

const mapDispatchToProps = dispatch => ({
  cancelAppointment: ({ petId, customerKey }) =>
    dispatch(cancelAppointment({ petId, customerKey })),
  hideModal: () => dispatch(hideBookingModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelBookingAppointment);
