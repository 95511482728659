import { dayActivityTypes } from "@/core/constants/schedulesConstants";
import { mapValues } from "lodash";

const hasPartialRelocationFn = activity => activity.filter(item => !!item.storeId)?.length > 1;
const isRelocation = item => item.type == dayActivityTypes.RELOCATION
const normalizePartialActivity = activity => {
    const isPartialRelocation = hasPartialRelocationFn(activity)
    return activity.map(item => {
        const itemDetails = item
        itemDetails.isPartialDayRelocation = false
        if (isPartialRelocation && isRelocation(item)) {
            itemDetails.isPartialDayRelocation = true
        }
        return itemDetails;
    })
}
const normalizeDayActivity = dayActivity => mapValues(dayActivity, item => normalizePartialActivity(item))
/* 
  * the following normalise is used to differentiate between partial relocation and work row relocation
  * this is handled via storeID, 
  * if current day has more than two storeId then it is expected that the relocation is partial othrwise work row
*/
export const normaliseResponseForPartialRelocation = (data) => {
    const formatted = data;
    formatted.dailyActivity = normalizeDayActivity(data.dailyActivity)

    return formatted;
}
