import { combineReducers } from "redux";
import { omit } from "lodash/fp";
import {
  SET_HOTEL_CART_DETAILS,
  SET_HOTEL_CART_PETS,
  SET_HOTEL_CART_PRODUCTS,
  SET_HOTEL_CART_PET_NOTES,
  SET_HOTEL_CART_PRODUCT,
  REMOVE_HOTEL_CART_PRODUCTS,
  SET_HOTEL_CART_TIMES,
  SET_HOTEL_CART_PRODUCT_FREQUENCY,
  TOGGLE_HOTEL_CART_PET_NEEDS_REVIEW,
} from "./servicesCartHotelActions";
import { CLEAR_SERVICES_CART } from "../servicesCart/servicesCartActions";

const cartDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_HOTEL_CART_DETAILS:
      return { ...state, ...action?.cartDetails };
    case SET_HOTEL_CART_TIMES:
      return { ...state, ...action?.times };
    case CLEAR_SERVICES_CART:
      return {};
    default:
      return state;
  }
};

const petsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_HOTEL_CART_PETS:
      return { ...state, ...action?.pets };
    case SET_HOTEL_CART_PET_NOTES:
      return {
        ...state,
        [action?.petId]: { ...state[action?.petId], notes: action?.notes },
      };
    case TOGGLE_HOTEL_CART_PET_NEEDS_REVIEW:
      return {
        ...state,
        [action?.petId]: {
          ...state[action?.petId],
          needsReview: !state[action?.petId]?.needsReview,
        },
      };
    case CLEAR_SERVICES_CART:
      return {};
    default:
      return state;
  }
};

const productsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_HOTEL_CART_PRODUCTS:
      return { ...state, ...action?.products };
    case SET_HOTEL_CART_PRODUCT:
      return { ...state, [action?.cartItemId]: { ...action?.product } };
    case SET_HOTEL_CART_PRODUCT_FREQUENCY:
      return {
        ...state,
        [action?.cartItemId]: {
          ...state[action?.cartItemId],
          frequency: action?.frequency,
          customFrequencyDates: action?.customFrequencyDates,
        },
      };
    case REMOVE_HOTEL_CART_PRODUCTS:
      return omit(action.cartItemIds, state);
    case CLEAR_SERVICES_CART:
      return {};
    default:
      return state;
  }
};

const servicesCartHotelReducer = combineReducers({
  cartDetails: cartDetailsReducer,
  pets: petsReducer,
  products: productsReducer,
});

export default servicesCartHotelReducer;
