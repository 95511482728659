import { connect } from "react-redux";
import RefundPackageErrorContentComponent from "../_components/refundPackageModals/RefundPackageErrorContentComponent";
import { displayRefundPackageErrorModal } from "./refundPackageErrorModalActions";

const mapStateToProps = () => ({
  isHidden: false,
  componentID: "refund-package-error-content-container",
  headerText: "Conflict",
  messageText:
    "The package you are attempting to refund has appointments scheduled with a redemption. These appointments must be cancelled before the refund can be made.",
  buttonLabel: "OK",
});

const mapDispatchToProps = dispatch => ({
  onModalClose: () => dispatch(displayRefundPackageErrorModal({ isHidden: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundPackageErrorContentComponent);
