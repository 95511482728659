import { connect } from "react-redux";
import CapacityModals from "dux/_components/roomCapacity/CapacityModals";
import { showHideDDCCapacityModal } from "dux/ddcRoomCapacityModal/ddcRoomCapacityModalActions";
import { getDDCModalIsHidden } from "dux/ddcRoomCapacityModal/ddcRoomCapacitySelector";
import DDCRoomCapacityModalContentContainer from "dux/ddcRoomCapacityModal/DDCRoomCapacityModalContentContainer";

const mapStateToProps = state => ({
  isHidden: getDDCModalIsHidden(state),
  componentID: "CapacityModals__DDCRoomCapacity",
  compName: "DDC",
  diComp: { DDC: DDCRoomCapacityModalContentContainer },
});

const mapDispatchToProps = dispatch => ({
  onModalClose: () => dispatch(showHideDDCCapacityModal({ isHidden: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CapacityModals);
