/**
 *
 * @param {object} enhancedService
 * @param {number[]} [addOnsIdListByService]
 * @returns Enhanced Service object to be set to the state or null if un-setting
 */
/*
  if the service list hasn't loaded 'yet', then we can't verify if it's active yet so
    just pass the enhanced service along
  if the service list has loaded, then cross reference the enhanced service to see if it's active.

  Two assumptions are made here:
    1. Every Service will have an Enhanced Service list.  If it's empty,
      then rebooking may not work correctly because this will assume it hasn't loaded
    2. The service will not send Enhanced Services that are not in 'active' status
*/
export const deriveActiveEnhancedService = (enhancedService = null, addOnsIdListByService = []) => {
  const enhancedServiceId = enhancedService?.addOnId;
  const hasListLoaded = !!addOnsIdListByService.length;

  const isEnhancedServiceActive = addOnsIdListByService.includes(enhancedServiceId);

  return !hasListLoaded || isEnhancedServiceActive ? enhancedService : null;
};

/**
 *
 * @param {object[]} [addons]
 * @param {number[]} [addOnsIdListByService]
 * @returns Array of Addon objects to be set to the state, can be []
 */
/*
  Since there's no property returned by the API indicating if an add-on
  is active or inactive, we need to check if the appointment
  add-on is included in list of add-ons for the chosen service
*/
export const deriveActiveAddons = (addons = [], addOnsIdListByService = []) => {
  const hasListLoaded = !!addOnsIdListByService.length;
  const activeAddOnsList = addons.filter((addon) => addOnsIdListByService.includes(addon?.addOnId));

  return hasListLoaded ? activeAddOnsList : addons;
};

/**
 *
 * @param {object} associates
 * @returns Boolean on whether there is at least one object with a primaryStoreNumber prop
 */
/*
  The schedule page requires the associate state to have a 'primaryStoreNumber' prop
    however when coming in as an SRC agent that prop doesn't exist yet but other props do
    therefore we're going to check for that prop and then make the API call if it doesn't exist
    This helper just checks for the prop, see BookingComponent.js for the actual call
*/
export const hasAPrimaryStoreNumber = (associates = {}) => {
  return Object.values(associates).some((associate) => associate.primaryStoreNumber);
};
