import React from "react";
import styled from "styled-components";

const RootComponent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: Row;
    width: 60%;
    text-align: center
    font-weight: bold;
    margin-bottom: 50px;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: Row;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  margin-left: 3%;
  margin-bottom: 20px;
`;

export default function GenericTwoColModalContent(props) {
  const { isHidden, componentID, title, compName, diComp } = props;

  if (!isHidden) {
    const ColOne = diComp.ColOne[compName];
    const ColTwo = diComp.ColTwo[compName];

    return (
      <RootComponent id={componentID}>
        <TitleWrapper>{title}</TitleWrapper>
        <ColumnsWrapper>
          <ColumnWrapper>
            <ColumnTitle>{diComp.ColOne.title}</ColumnTitle>
            <ColOne />
          </ColumnWrapper>

          <ColumnWrapper>
            <ColumnTitle>{diComp.ColTwo.title}</ColumnTitle>
            <ColTwo />
          </ColumnWrapper>
        </ColumnsWrapper>
      </RootComponent>
    );
  }

  return null;
}
