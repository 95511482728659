import React from "react";
import styled from "styled-components";
import OtherPetParentAddress from "../../petParentProfile/petParent/petParentForm/AddressInput/OtherPetParentAddressContainer";
import slideDown from "../styles/Animation/slideDownAnimation";

/* * ----------------------------------------------------------------------- * *\
  Styled Components
\* * ----------------------------------------------------------------------- * */

const SectionWrapper = styled.section`
  display: flex;
  overflow: hidden;
`;

const AnimationWrapper = styled.section`
  display: flex;
  flex-direction: column;

  animation: ${slideDown()} 1s ease;
`;

// We do not want the 1st address to have as large a margin as the remaining, so we do a simple check
// if its the 1st in the list e.g. index 0, set the margin to zero
const AddressWrapper = styled.div`
  margin-top: ${({ index }) => (index > 0 ? "100px" : 0)};
`;

/* * ----------------------------------------------------------------------- * *\
  JSX View Component
\* * ----------------------------------------------------------------------- * */
export default function PetParentAddressList(props) {
  const { isHidden, addressList, customerKey } = props;

  if (!isHidden) {
    return (
      <SectionWrapper>
        <AnimationWrapper>
          {addressList.map(({ addressId }, index) => (
            <AddressWrapper key={addressId} index={index}>
              <OtherPetParentAddress customerKey={customerKey} addressId={addressId} />
            </AddressWrapper>
          ))}
        </AnimationWrapper>
      </SectionWrapper>
    );
  }

  return null;
}
