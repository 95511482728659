import { connect } from "react-redux";
import vetsActionCreators from "../../../../../core/actionCreators/vetsActionCreators";
import vetsActionTypes from "../../../../../core/actionTypes/vetsActionTypes";
import petProfileActionCreators from "../../../../../core/actionCreators/petProfileActionCreators";
import { modalTypes } from "../../../../../core/constants/petProfileConstants";
import { createLoadingSelector } from "../../../../../core/selectors/utils";
import PetVetsFormComponent from "./PetVetsFormComponent";

const mapStateToProps = state => ({
  isLoading: createLoadingSelector([vetsActionTypes.CREATE_VET])(state),
  isCreate: true,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: ({ formValues }) => {
    dispatch(vetsActionCreators.createVet({ formValues }));
  },
  goBack: () => {
    dispatch(petProfileActionCreators.showPetProfileModal(modalTypes.SEARCH_VET));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PetVetsFormComponent);
