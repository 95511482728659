import { OTHER_MEDICATION_NAME } from "@/web/newMedication/newMedicationConstants";
import { frequencyConstants } from "@/dux/frequency/FrequencyConstants";
import { validateArrayItems } from "@/core/utils/validationUtils/validateArrayItems";

/**
 * Checks if a required pet medication field is missing
 *
 * @export isRequiredPetMedicationFieldMissing
 * @param { Object } values - An object with form values.
 * @returns { Boolean } - returns true/false if form data is not loaded.
 */

export default function isRequiredPetMedicationFieldMissing(values) {
  if (!values.medication) {
    return true;
  }
  const { isInBooking } = values;
  const { name, externalId, amount, timeOfDay, frequency, concreteSchedule } = values.medication;
  let requiredFields = [externalId, amount, timeOfDay];
  if (!values.medicationList) {
    return false;
  }

  const foundOtherMedication = values.medicationList.find(medication => {
    return (
      medication.MedicationId === externalId && medication.Description === OTHER_MEDICATION_NAME
    );
  });
  if (foundOtherMedication) {
    // If 'other' is selected for medication, the name field is required.
    requiredFields = [...requiredFields, name];
  }

  if (isInBooking) {
    // If in the booking flow, the frequency field is required. If frequency is manual, manual dates is required.
    if (frequency === frequencyConstants.MANUAL) {
      requiredFields = [...requiredFields, frequency, concreteSchedule];
    } else {
      requiredFields = [...requiredFields, frequency];
    }
  }

  // If it has, check if any required field is missing and return t/f accordingly
  return !validateArrayItems(requiredFields);
}
