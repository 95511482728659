import { connect } from "react-redux";
import { history } from "@/dux/utils/browser/browserHistory";
import { routePaths } from "@/core/constants/routePaths";
import { setSystemBookingFlowType } from "../setSystemType/actions/setSystemTypeActions";
import { systemName } from "../setSystemType/constants/setSystemTypeConstants";
import getIsTrainingWorkflowFeatureFlagHidden from "../enableDisableWorkflowFeatureFlag/selectors/training/getIsTrainingWorkflowFeatureFlagHidden";
import { getStoreNumber } from "@/core/selectors/persistentSelectors";
import MenuButton from "../common/MenuButton";

const mapStateToProps = (state) => {
  const storeNumber = getStoreNumber(state);
  return {
    className: "ProfileButton__menuButton--trainingDashboard",
    componentID: "ProfileButton-trainingDashboard",
    title: "Training Dashboard",
    // We want to hide the salon dashboard link in the SRC flow if there's no store number
    isHidden: !storeNumber || getIsTrainingWorkflowFeatureFlagHidden(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClick: () => {
    dispatch(setSystemBookingFlowType({ systemBookingFlow: systemName.TRAINING }));
    history.push(routePaths.DASHBOARD);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);
