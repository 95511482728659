import React from "react";
import styled from "styled-components";
import { color } from "../../common/styles/theme";
import Section from "../../common/Section";
import PetForm from "../../petParentProfile/pets/petForm/PetFormContainer";
import { PetVaccinations } from "@/dux/vaccinations/PetVaccinations";
import PetProfileAvailableBundlesListContainer from "@/dux/bgm/availableBundlesByPet/availableBundlesList/PetProfileAvailableBundlesListContainer";
import VetInformation from "@/web/petParentProfile/pets/petVets/petVetsTable/PetVetsTableContainer";

const PetFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2.8125rem;
  border-right: 1px solid ${color.borderGrey};
`;

export default function PetProfileDetails(props) {
  const { customerKey, selectedPet, isHidden, componentID } = props;

  if (!isHidden) {
    return (
      <PetFormContainer componentID={componentID}>
        <Section paddingTop="0">
          <PetForm customerKey={customerKey} petId={selectedPet} isBooking />
        </Section>
        <PetProfileAvailableBundlesListContainer />
        <PetVaccinations />
        <VetInformation customerKey={customerKey} petId={selectedPet} />
      </PetFormContainer>
    );
  }

  return null;
}
