/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const createUpdateDeleteFoodActionTypes = {
  CREATE_PET_FOOD: "CREATE_PET_FOOD",
  CREATE_PET_FOOD_REQUEST: "CREATE_PET_FOOD_REQUEST",
  CREATE_PET_FOOD_SUCCESS: "CREATE_PET_FOOD_SUCCESS",
  CREATE_PET_FOOD_FAILURE: "CREATE_PET_FOOD_FAILURE",

  DELETE_PET_FOOD: "DELETE_PET_FOOD",
  DELETE_PET_FOOD_REQUEST: "DELETE_PET_FOOD_REQUEST",
  DELETE_PET_FOOD_SUCCESS: "DELETE_PET_FOOD_SUCCESS",
  DELETE_PET_FOOD_FAILURE: "DELETE_PET_FOOD_FAILURE",

  UPDATE_PET_FOOD: "UPDATE_PET_FOOD",
  UPDATE_PET_FOOD_REQUEST: "UPDATE_PET_FOOD_REQUEST",
  UPDATE_PET_FOOD_SUCCESS: "UPDATE_PET_FOOD_SUCCESS",
  UPDATE_PET_FOOD_FAILURE: "UPDATE_PET_FOOD_FAILURE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const createPetFood = ({ food, petId, isFromBooking = false }) => ({
  type: createUpdateDeleteFoodActionTypes.CREATE_PET_FOOD,
  food,
  petId,
  isFromBooking,
});

export const createPetFoodRequest = () => ({
  type: createUpdateDeleteFoodActionTypes.CREATE_PET_FOOD_REQUEST,
});

export const createPetFoodSuccess = () => ({
  type: createUpdateDeleteFoodActionTypes.CREATE_PET_FOOD_SUCCESS,
});

export const createPetFoodFailure = ({ error }) => ({
  type: createUpdateDeleteFoodActionTypes.CREATE_PET_FOOD_FAILURE,
  error,
});

export const deletePetFood = ({ foodId, petId }) => ({
  type: createUpdateDeleteFoodActionTypes.DELETE_PET_FOOD,
  foodId,
  petId,
});

export const deletePetFoodRequest = () => ({
  type: createUpdateDeleteFoodActionTypes.DELETE_PET_FOOD_REQUEST,
});

export const deletePetFoodSuccess = () => ({
  type: createUpdateDeleteFoodActionTypes.DELETE_PET_FOOD_SUCCESS,
});

export const deletePetFoodFailure = ({ error }) => ({
  type: createUpdateDeleteFoodActionTypes.DELETE_PET_FOOD_FAILURE,
  error,
});

export const updatePetFood = ({ food, petId, isFromBooking = false, isFoodDetailsShowing }) => ({
  type: createUpdateDeleteFoodActionTypes.UPDATE_PET_FOOD,
  food,
  petId,
  isFromBooking,
  isFoodDetailsShowing,
});

export const updatePetFoodRequest = () => ({
  type: createUpdateDeleteFoodActionTypes.UPDATE_PET_FOOD_REQUEST,
});

export const updatePetFoodSuccess = () => ({
  type: createUpdateDeleteFoodActionTypes.UPDATE_PET_FOOD_SUCCESS,
});

export const updatePetFoodFailure = ({ error }) => ({
  type: createUpdateDeleteFoodActionTypes.UPDATE_PET_FOOD_FAILURE,
  error,
});
