import { connect } from "react-redux";
import React from "react";
import { Button, TextPassage } from "@petsmart-ui/sparky";
import { LayoutStack } from "@/layout/stack/Stack";
import { LayoutBox } from "@/layout/box/Box";
import { LayoutCluster } from "@/layout/culster/Cluster";
import { formatMoney } from "../_utils/moneyUtil";

/**
 * React view component for DDC packages total price and purchase button
 * @memberOf Views.Purchase
 * @param {*} props
 * @returns {JSX.Element}
 */
export const DdcPackagesPriceAndPurchaseComponent = props => {
  const { componentId, totalPrice, disabled, onClick = () => {}, isHidden } = props;
  if (isHidden) return null;

  return (
    <LayoutCluster
      id={componentId}
      style={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <LayoutBox style={{ paddingLeft: 0 }}>
        <LayoutStack space="scale-0">
          <TextPassage>Total price</TextPassage>
          <TextPassage>{totalPrice}</TextPassage>
        </LayoutStack>
      </LayoutBox>

      <LayoutBox style={{ paddingRight: 0 }}>
        <Button text="Purchase" onClick={onClick} disabled={disabled} />
      </LayoutBox>
    </LayoutCluster>
  );
};

/**
 * Redux Connect function for DDC packages total price and purchase button
 * @memberOf Views.Purchase
 * @function
 * @name DdcPackagesPriceAndPurchase
 * @example <DdcPackagesPriceAndPurchase />
 */
export const DdcPackagesPriceAndPurchase = connect(state => {
  return {
    componentId: "DdcPackagesPriceAndPurchase",
    totalPrice: formatMoney(0), // TODO: get total price of selected package
    disabled: true, // TODO: enable button if package is selected
  };
})(DdcPackagesPriceAndPurchaseComponent);
