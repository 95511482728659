import { put, takeEvery, call, all } from "redux-saga/effects";
import specialsActionCreator from "../../core/actionCreators/specialsActionCreator";
import specialsActionTypes from "../../core/actionTypes/specialsActionTypes";

import {
  fetchSpecials,
  removeSpecials,
  postSpecials
} from "../../core/services/systemServicesBooking/specialsEndPoints";
import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";
import { loadPetServiceItems } from "../../core/actionCreators/petServiceItemsActionCreators";
import { dispatchItinerariesDataAndReturnItineraries } from "../../core/sagas/itinerariesSaga";

function* onloadSpecials({ petServiceItemId, itineraryId }) {
  try {
    yield put(specialsActionCreator.loadSpecialsRequest());
    const response = yield call(fetchSpecials, { itineraryId });
    const specials = response.data;
    yield put(
      loadPetServiceItems(getPetServiceItemWithAvailableSpecials(petServiceItemId, specials))
    );
    yield put(
      specialsActionCreator.loadSpecialsSuccess({
        specials: normalizeArrayByProperty(specials, "code")
      })
    );
  } catch (error) {
    yield put(specialsActionCreator.loadSpecialsFailure({ error }));
  }
}

function* onPostSpecials({ customerId, itineraryId, specials }) {
  try {
    yield put(specialsActionCreator.postSpecialsRequest());
    const response = yield call(postSpecials, { customerId, itineraryId, specials });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response
    });
    const itinerary = normalizedItineraries[itineraryId];

    yield put(specialsActionCreator.postSpecialsSuccess({ itinerary }));
  } catch (error) {
    yield put(specialsActionCreator.postSpecialsFailure({ error }));
  }
}

function* onRemoveSpecials({ customerId, itineraryId, specials }) {
  try {
    yield put(specialsActionCreator.removeSpecialsRequest());
    const response = yield call(removeSpecials, { customerId, itineraryId, specials });
    const normalizedItineraries = yield call(dispatchItinerariesDataAndReturnItineraries, {
      response
    });
    const itinerary = normalizedItineraries[itineraryId];

    yield put(specialsActionCreator.removeSpecialsSuccess({ itinerary }));
  } catch (error) {
    yield put(specialsActionCreator.removeSpecialsFailure({ error }));
  }
}

function* watchloadSpecials() {
  yield takeEvery(specialsActionTypes.LOAD_SPECIALS, onloadSpecials);
}

function* watchPostSpecials() {
  yield takeEvery(specialsActionTypes.POST_SPECIALS, onPostSpecials);
}

function* watchRemoveSpecials() {
  yield takeEvery(specialsActionTypes.REMOVE_SPECIALS, onRemoveSpecials);
}

export default function* specialsSaga() {
  yield all([watchloadSpecials(), watchPostSpecials(), watchRemoveSpecials()]);
}

const getPetServiceItemWithAvailableSpecials = (petServiceItemId, specials) => ({
  petServiceItems: {
    [petServiceItemId]: {
      availableSpecials: specials.map(special => special.code)
    }
  }
});
