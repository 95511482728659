import React from "react";
import { withRouteProps } from "@/core/utils/routingUtils/withRouteProps";
import { connect } from "react-redux";
import { getCustomerFullName } from "core/selectors/entitiesSelector";
import { createLoadingSelector } from "core/selectors/utils";
import customersActionTypes from "core/actionTypes/customersActionTypes";
import { Button, Text } from "@prism/psm-ui-components";
import { getCurrentCustomerKey } from "core/selectors/persistent/customer/customerSelectors";
import VIPImage from "assets/icons/VIP-customer-icon.svg";
import { LayoutBox } from "@/layout/box/Box";
import { getCustomer } from "@/core/selectors/entitiesSelector";

export const PetParentContactInfoComponent = ({
  componentId,
  isLoading,
  isVipCustomer,
  loadingMessage,
  onClick,
  customerName,
}) => (
  <LayoutBox id={componentId} style={{ padding: "0" }}>
    <Button onClick={onClick} variant="no-outline" style={{ padding: "0" }}>
      <Text
        color="text-color-blue"
        align="left"
        fontFamily="text-family-montserrat"
        letterSpacing="text-tracking-normal"
        size="text-size-lg"
      >
        {isLoading ? loadingMessage : customerName}
      </Text>
    </Button>
    {isVipCustomer && (
      <img alt="vip icon" src={VIPImage} style={{ height: "1.125rem", marginLeft: "10px" }} />
    )}
  </LayoutBox>
);

// UNIFIED PROPS FOR ALL CONTAINERS -----------------------------------------------------------------------------------
const getUnifiedProps = (state, ownProps) => {
  const customerKey = getCurrentCustomerKey(state);
  const customer = getCustomer(state, { customerKey });
  const isVipCustomer = customer?.prismCustomer?.isVip;

  return {
    customerKey,
    customerName: getCustomerFullName(state, { customerKey }),
    isLoading: createLoadingSelector([customersActionTypes.LOAD_CUSTOMER])(state),
    isVipCustomer,
    loadingMessage: "Loading...",
  };
};
// HOTEL PET PARENT CONTACT LINK CONTAINER -----------------------------------------------------------------------------
export const HotelPetParentContactLink = withRouteProps(
  connect(
    (state, ownProps) => {
      return {
        ...getUnifiedProps(state, ownProps),
        componentId: "PetsHotel petParentContactLink",
      };
    },
    (dispatch, { router: { navigate } }) => {
      return {
        handleParentClick: ({ customerKey }) => {
          navigate(`/pet-parent-profile/${customerKey}`);
        },
      };
    },
    (propsFromState, propsFromDispatch, ownProps) => ({
      ...propsFromState,
      ...propsFromDispatch,
      ...ownProps,
      // Temporarily hiding VIP label per PO request - SVCSART-30464
      isVipCustomer: false,
      onClick: () => {
        const { customerKey } = propsFromState;
        const { handleParentClick } = propsFromDispatch;
        handleParentClick({
          customerKey,
        });
      },
    }),
  )(PetParentContactInfoComponent),
);

// SALON PET PARENT CONTACT LINK CONTAINER -----------------------------------------------------------------------------
export const SalonPetParentContactLink = withRouteProps(
  connect(
    (state, ownProps) => {
      return {
        ...getUnifiedProps(state, ownProps),
        componentId: "Salon petParentContactLink",
      };
    },
    (dispatch, { router: { navigate } }) => {
      return {
        handleParentClick: ({ customerKey }) => {
          navigate(`/pet-parent-profile/${customerKey}`);
        },
      };
    },
    (propsFromState, propsFromDispatch, ownProps) => ({
      ...propsFromState,
      ...propsFromDispatch,
      ...ownProps,
      onClick: () => {
        const { customerKey } = propsFromState;
        const { handleParentClick } = propsFromDispatch;
        handleParentClick({
          customerKey,
        });
      },
    }),
  )(PetParentContactInfoComponent),
);
