import { connect } from "react-redux";
import QuickViewPetCard from "./quickViewPetCardComponent";
import { getPets } from "../../../../../core/selectors/searchSalonSRCSelector";

const mapStateToProps = (state, ownProps) => {
  const customerID = state.ui.web.search.custID;

  return {
    petList: getPets(state, customerID)
  };
};

const QuickViewPetCardContainer = connect(mapStateToProps)(QuickViewPetCard);

export default QuickViewPetCardContainer;
