import { createSelector } from "reselect";
import { get, has, pickBy, sumBy } from "lodash/fp";
import { getCanNotBookPets } from "dux/eligibility/selectors/eligibilityPetsCanNotBookSelectors";
import {
  getEngagements,
  getItineraries,
  getItinerary,
  getPetServiceItems,
  getPetServiceItemsByItinerary,
  getServicesForPet,
} from "./entitiesSelector";
import { APPOINTMENT_STATUS } from "../constants";
import { getJobRole } from "./persistentSelectors";
import { jobRoleConstants } from "../constants/jobRoleConstants";
import {
  MAX_PER_BLOCK_OPTIONS,
  MAX_PER_BLOCK_OPTIONS_FOR_MANAGER,
} from "../constants/bookingConstants";
import { getUiWeb } from "./uiSelector";

/**
 * Selector to get salon appointments
 * @memberOf Selectors.ui
 * @function
 * @name getCartDetails
 * @param {Object} state - redux state
 * @returns {Object} an object of pet appointments where the keys are petIds
 * @example getCartDetails(state)
 */
export const getCartDetails = createSelector([getUiWeb], uiWeb => uiWeb?.cart);

/**
 * Selector to get salon appointment for a given pet
 * @memberOf Selectors.ui
 * @function
 * @name selectCartDetailsByPet
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Object}
 * @example selectCartDetailsByPet(state, { petId })
 */
export const selectCartDetailsByPet = createSelector(
  [getCartDetails, (_, props) => props],
  (cartDetails = {}, { petId } = {}) => cartDetails[petId],
);

/**
 * Selector to get salon appointment by petId, if we are booking then uses state in
 * state.ui.web.cart else use existing itinerary/engagement/petServiceItem data in state.entities
 * @memberOf Selectors.ui
 * @function
 * @name getAppointmentByPet
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Object} pet salon appointment details
 * @example getAppointmentByPet(state, { petId })
 */
export const getAppointmentByPet = createSelector(
  [selectCartDetailsByPet, getPetServiceItems, getItineraries, getEngagements],
  (cartAppointment, petServiceItems = {}, itineraries = {}, engagements = {}) => {
    const petServiceItemId = cartAppointment?.petServiceItemId;

    // If no petServiceItemId then we are booking and the pending data is in cartAppointment, else use existing itinerary
    if (!petServiceItemId) {
      return cartAppointment;
    }

    const petServiceItem = petServiceItems[petServiceItemId] ?? {};
    const engagement = engagements[petServiceItem?.engagement] ?? {};
    const { isBoarded, questions = [], completedPreCheckIn, additionalBookingType } = engagement;

    const {
      petServiceId,
      pet,
      startDateTime,
      currentPrice,
      duration,
      associate,
      addOns,
      lockToAssociate,
      maxPerBlockCount,
      maxCheckInCount,
      maxPerBlockOrCheckReductionReason,
      redemptionId,
      enhancedServices,
    } = petServiceItem;

    const itinerary = itineraries[petServiceItem?.itinerary] ?? {};

    return {
      petId: pet,
      petService: petServiceId,
      petServiceItemId,
      addons: addOns,
      enhancedServices,
      additionalBookingType,
      isBoarded,
      specials: itinerary?.appliedSpecials,
      maxPerBlockCount,
      maxCheckInCount,
      maxPerBlockBool: maxPerBlockCount > 1,
      maxCheckInBool: maxCheckInCount > 1,
      lockToAssociate,
      maxPerBlockOrCheckReductionReason,
      timeSlot: {
        startDateTime,
        currencyCode: itinerary?.currencyCode,
        duration,
        associateId: associate,
        price: currentPrice,
      },
      redemptionId,
      questions,
      completedPreCheckIn,
      currentPrice,
    };
  },
);

/**
 * Selector to get petServiceItemId from a given pet's salon appointment
 * @memberOf Selectors.ui
 * @function
 * @name getSelectedPetServiceItem
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Number}
 * @example getSelectedPetServiceItem(state, { petId })
 */
export const getSelectedPetServiceItem = createSelector(
  [selectCartDetailsByPet],
  petCart => petCart?.petServiceItemId,
);

/**
 * Selector to get selected addons from a given pet's salon appointment
 * @memberOf Selectors.ui
 * @function
 * @name getSelectedAddOns
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Number}
 * @example getSelectedAddOns(state, { petId })
 */
export const getSelectedAddOns = createSelector(
  [getAppointmentByPet],
  appointment => appointment?.addons,
);

export const getSelectedEnhancedServices = (state, props) =>
  get("enhancedServices", getAppointmentByPet(state, props));

/**
 * Selector to get maxCheckInCount from a given pet's salon appointment
 * @memberOf Selectors.ui
 * @function
 * @name getMaxCheckInCount
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Number}
 * @example getMaxCheckInCount(state, { petId })
 */
export const getMaxCheckInCount = createSelector(
  [getAppointmentByPet],
  appointment => appointment?.maxCheckInCount,
);

/**
 * Selector to get maxCheckInBool for a given pets salon appointment
 * Note: if booking then uses state.ui.web.cart else uses state.entities
 * @memberOf Selectors.ui
 * @function
 * @name getMaxCheckInBool
 * @param {Object} state - redux state
 * @param {Object} props - specifically the petId
 * @returns {Boolean}
 * @example getMaxCheckInBool(state, { petId })
 */
export const getMaxCheckInBool = createSelector([getAppointmentByPet], appointment => {
  return appointment?.maxCheckInBool;
});

/**
 * Selector to get maxPerBlockCount for a given pets salon appointment
 * Note: if booking then uses state.ui.web.cart else uses state.entities
 * @memberOf Selectors.ui
 * @function
 * @name getMaxPerBlockCount
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {Number} props.petId
 * @returns {Boolean}
 * @example getMaxPerBlockCount(state, { petId })
 */
export const getMaxPerBlockCount = createSelector([getAppointmentByPet], appointment => {
  return appointment?.maxPerBlockCount;
});

/**
 * Selector to get maxPerBlockBool for a given pets salon appointment
 * Note: if booking then uses state.ui.web.cart else uses state.entities
 * @memberOf Selectors.ui
 * @function
 * @name getMaxPerBlockBool
 * @param {Object} state - redux state
 * @param {{ petId: string }} props
 * @returns {Boolean}
 * @example getMaxPerBlockBool(state, { petId })
 */
export const getMaxPerBlockBool = createSelector([getAppointmentByPet], appointment => {
  return appointment?.maxPerBlockBool;
});

/**
 * Selector to get maxPerBlockOrCheckReductionReason for a given pets salon appointment
 * @memberOf Selectors.ui
 * @function
 * @name getMaxPerBlockOrCheckReductionReason
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {String|null}
 * @example getMaxPerBlockOrCheckReductionReason(state, { petId })
 */
export const getMaxPerBlockOrCheckReductionReason = createSelector(
  [getAppointmentByPet],
  appointment => appointment?.maxPerBlockOrCheckReductionReason,
);

/**
 * Selector to get isBoarded for a given pets salon appointment
 * @memberOf Selectors.ui
 * @function
 * @name getIsBoarded
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Boolean}
 * @example getIsBoarded(state, { petId })
 */
export const getIsBoarded = createSelector([getAppointmentByPet], petAppointment => {
  return petAppointment?.isBoarded;
});

/**
 * Selector to get notes for a given pet in booking and check in/out flow
 * @memberOf Selectors.ui
 * @function
 * @name getAppointmentNote
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {String|null}
 * @example getAppointmentNote(state, { petId })
 */
export const getAppointmentNote = (state, props) =>
  get([props.petId, "appointmentNote"], getCartDetails(state, props));
export const getLockToAssociate = (state, props) =>
  get("lockToAssociate", getAppointmentByPet(state, props));

export const getAllCartAppointments = state =>
  Object.keys(getCartDetails(state)).map(petId => getAppointmentByPet(state, { petId }));

export const getMaxCountInfo = createSelector(
  [getMaxCheckInCount, getMaxPerBlockCount, getMaxCheckInBool, getMaxPerBlockBool],
  (maxCheckInCount, maxPerBlockCount, maxCheckInBool, maxPerBlockBool) => ({
    maxCheckInCount,
    maxPerBlockCount,
    maxCheckInBool,
    maxPerBlockBool,
  }),
);

export const getCurrentItineraryPrice = createSelector(
  [getItinerary],
  itinerary => itinerary && itinerary.currentPrice,
);

export const getMaxLimitFormValues = createSelector([getAppointmentByPet], appointment => ({
  reduceCheckInFlag: appointment && appointment.maxCheckInBool ? appointment.maxCheckInBool : false,
  reduceByBlockFlag:
    appointment && appointment.maxPerBlockBool ? appointment.maxPerBlockBool : false,
  maxCheckInCount:
    appointment && appointment.maxCheckInCount
      ? appointment.maxCheckInCount - 1
      : appointment.maxCheckInCount === 0
      ? 0
      : 1,
  maxPerBlockCount:
    appointment && appointment.maxPerBlockCount
      ? appointment.maxPerBlockCount - 1
      : appointment.maxPerBlockCount === 0
      ? 0
      : 1,
  isManualOverbook: appointment
    ? appointment.maxPerBlockCount === 0 && appointment.maxCheckInCount === 0
    : false,
  reductionReason:
    appointment && appointment.maxPerBlockOrCheckReductionReason
      ? appointment.maxPerBlockOrCheckReductionReason
      : null,
}));

/**
 * Selector to get the petservice from a given pet's salon appointment
 * @memberOf Selectors.ui
 * @function
 * @name getPetServiceFromAppointmentByPet
 * @param {Object} state - redux state
 * @param {Object} props
 * @param {String} props.petId
 * @returns {Object} pet service data
 * @example getPetServiceFromAppointmentByPet(state, { petId })
 */
export const getPetServiceFromAppointmentByPet = createSelector(
  [getAppointmentByPet, getServicesForPet],
  (appointment, servicesForPet = {}) => servicesForPet[appointment?.petService],
);

export const getCartSpecialsByPet = createSelector(
  [getAppointmentByPet],
  appointment => appointment.specials,
);

export const getTotalPriceForAllPetServiceItems = createSelector(
  [getPetServiceItemsByItinerary],
  petServiceItems =>
    sumBy(
      petServiceItem =>
        petServiceItem?.status !== APPOINTMENT_STATUS.CANCELED && petServiceItem?.currentPrice,
      petServiceItems,
    ) || 0,
);

export const getIsServiceFound = createSelector(
  [getAppointmentByPet, getServicesForPet],
  (appointment, services) => {
    const currentPetService = get("petService", appointment);
    return has(currentPetService, services);
  },
);

/**
 * Selector to get details for any of the pets with salon appointments that are marked as canBook false
 * @memberOf Selectors.ui
 * @function
 * @name getCanNotBookPetsInCart
 * @param {Object} state - redux state
 * @returns {Object} object containing pet details for pets with canBook false in their eligibility response
 * @example getCanNotBookPetsInCart(state)
 */
export const getCanNotBookPetsInCart = createSelector(
  [getCanNotBookPets, getCartDetails],
  (canNotBookPets, cart) => pickBy((value, petId) => has(petId, cart), canNotBookPets),
);

export const getMaxPerBlockOptions = createSelector([getJobRole], jobRole =>
  jobRole === jobRoleConstants.MANAGER ? MAX_PER_BLOCK_OPTIONS_FOR_MANAGER : MAX_PER_BLOCK_OPTIONS,
);

export const getIsBundleApplied = createSelector([getAppointmentByPet], get("redemptionId"));

export const getCurrentPetServiceId = createSelector([getAppointmentByPet], get("petService"));

export const getAppointmentTimeSlot = createSelector([getAppointmentByPet], get("timeSlot"));

export const selectAdditionalBookingType = createSelector(
  [getAppointmentByPet],
  get("additionalBookingType"),
);
