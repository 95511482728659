import { SET_FOOD_OPTIONS_LIST } from "./foodOptionsListActions";

const initialState = {};

/**
 * Redux Reducer to add foodOptions to the Redux State
 * @memberOf Reducer.foodOptions
 * @function
 * @name foodOptionsListReducer
 * @param {Object } state - Redux state
 * @param {Object} action - Redux Action
 * @returns {Array}
 */
const foodOptionsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FOOD_OPTIONS_LIST:
      return { ...action?.foodOptions };
    default:
      return state;
  }
};

export default foodOptionsListReducer;
