import React, { useEffect, useState } from "react";

/**
 * Takes an argument and if it's an array, returns the first item in the array
 * otherwise returns the argument
 * @param {*} arg the maybe-array
 * @return {*} the arg or it's first item
 */
function unwrapArray(arg) {
  return Array.isArray(arg) ? arg[0] : arg;
}

// used execute many event handlers
export const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args));

/**
 * React wrapper component that manages a toggle state
 * @summary Used for filter pills on many pages including room-management &  pet-parent-profile
 * @memberOf Views
 * @function
 * @name ToggleManager
 * @param {Object} props - props passed into the view component
 * @param {boolean|undefined} props.on - optional prop to control the toggle state
 * @param {boolean|undefined} props.defaultOn - optional prop to set initial toggle state if uncontrolled
 * @param {function} props.onToggle - callback to call when toggle state updates
 * @param {({ on: boolean, getTogglerProps: function, setOn: function, setOff: function, toggle: function}) => {}} props.children
 * @returns {JSX.Element}
 * @example <ToggleManager>{({ on, toggle, getTogglerProps }) => (<> ... </>)}</ToggleManager>
 * @see {@link ToggleButtonListWithSelectAll}
 */
export const ToggleManager = ({ on, defaultOn = false, onToggle = () => {}, children }) => {
  const isOnControlled = () => on !== undefined;

  const [onState, setState] = useState({ on: isOnControlled() ? on : defaultOn });

  const getOn = (state = onState) => (isOnControlled() ? on : state?.on);

  const getTogglerProps = (props = {}) => ({
    "aria-pressed": Boolean(getOn()),
    ...props,
    onClick: callAll(props.onClick, toggle),
  });

  const getTogglerStateAndHelpers = () => ({
    on: getOn(),
    getTogglerProps,
    setOn,
    setOff,
    toggle,
  });

  const setOnState = (state = !getOn()) => {
    if (isOnControlled()) {
      onToggle(state, getTogglerStateAndHelpers());
    } else {
      setState({ on: state });
    }
  };

  useEffect(() => {
    onToggle(getOn(), getTogglerStateAndHelpers());
  }, [onState]);

  const setOn = () => setOnState(true);
  const setOff = () => setOnState(false);
  const toggle = () => setOnState(undefined);

  const renderProp = unwrapArray(children);
  return renderProp(getTogglerStateAndHelpers());
};
