import { connect } from "react-redux";
import SmallPillButton from "../../../_components/buttons/SmallPillButton";
import { getHotelCheckedOutFilterBy } from "./hotelCheckedOutPetServiceSelector";
import {
  filterByAllCheckedOut,
  filterByDayCampCheckedOut,
} from "./hotelCheckedOutFilterButtonActions";

const mapStateToProps = state => {
  const filterBy = getHotelCheckedOutFilterBy(state);

  return {
    isHidden: false,
    componentID: "SmallPillButton_HotelCheckedOutDayCampFilterButton",
    label: "Day Camp",
    selected: filterBy.dayCamp,
    disabled: false,
    filterBtnStatus: filterBy.dayCamp,

    isAllOthersSelected: filterBy.overnight && filterBy.dayCare,
  };
};

const mapDispatchToProps = dispatch => ({
  handleFilterByDayCampCheckedOut: ({ filterBtnStatus, isAllOthersSelected }) => {
    if (filterBtnStatus) {
      // toggle off
      dispatch(filterByDayCampCheckedOut({ filterBtnStatus: !filterBtnStatus }));

      // toggle off all button as well
      dispatch(filterByAllCheckedOut({ filterBtnStatus: false }));
    } else {
      dispatch(filterByDayCampCheckedOut({ filterBtnStatus: !filterBtnStatus }));

      if (isAllOthersSelected) {
        dispatch(filterByAllCheckedOut({ filterBtnStatus: true }));
      }
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,

  handleClick: () =>
    propsFromDispatch.handleFilterByDayCampCheckedOut({
      filterBtnStatus: propsFromState.filterBtnStatus,
      isAllOthersSelected: propsFromState.isAllOthersSelected,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SmallPillButton);
