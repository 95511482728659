import { connect } from "react-redux";
import { columnHeaders } from "core/constants/dashboardContants";
import HotelArrivalsPetCardContainer from "dux/hotelArrivals/columnList/HotelArrivalsPetCardContainer";
import { getHotelArrivalsAppointments } from "./hotelArivalsSelector";
import ScrollablePetCardList from "../../_components/petCard/ScrollablePetCardList";

const mapStateToProps = state => ({
  componentID: "scrollableCardList__hotelArrivals",
  appointments: getHotelArrivalsAppointments(state),
  isHidden: false,
  diComp: {
    [columnHeaders.ARRIVALS]: HotelArrivalsPetCardContainer,
  },
  compName: columnHeaders.ARRIVALS,
});

export default connect(mapStateToProps)(ScrollablePetCardList);
