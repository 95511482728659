import { isEmpty, isNil, isNumber, isUndefined } from "lodash/fp";

/**
 * Checks if a required pet field is missing
 *
 * @export isRequiredPetFieldMissing
 * @param { Object } values - An object with form values.
 * @returns { Boolean } - returns true/false if form data is not loaded.
 */

export default function isRequiredPetFieldMissing(values) {
  const { birthDate, breedId, genderId, petName, speciesId, weight } = values;
  const requiredFields = [birthDate, breedId, genderId, petName, speciesId, weight];

  if (!requiredFields.every(value => isUndefined(value))) {
    // Check that data has loaded
    return requiredFields.some(value => (isNumber(value) ? isNil(value) : isEmpty(value))); // If it has, check if any required field is missing and return t/f accordingly
  }
  return false; // Return false if form data is not loaded
}
