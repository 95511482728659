import { put, takeEvery, call, all } from "redux-saga/effects";
import { getHotelRoomTypes } from "dux/hotelRoomTypes/hotelRoomTypesEndpoints";
import {
  GET_HOTEL_ROOM_TYPES,
  getHotelRoomTypesRequest,
  getHotelRoomTypesSuccess,
  getHotelRoomTypesFailure,
} from "./hotelRoomTypesActions";

function* onGetHotelRoomTypes() {
  try {
    yield put(getHotelRoomTypesRequest());
    const response = yield call(getHotelRoomTypes);
    const payload = response?.data?.result;

    yield put(getHotelRoomTypesSuccess(payload));
  } catch (error) {
    yield put(getHotelRoomTypesFailure());
  }
}

function* watchGetHotelRoomTypes() {
  yield takeEvery(GET_HOTEL_ROOM_TYPES, onGetHotelRoomTypes);
}

export default function* hotelRoomTypesSaga() {
  yield all([watchGetHotelRoomTypes()]);
}
