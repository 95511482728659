import { connect } from "react-redux";
import QuickViewWrapper from "./quickViewWrapperComponent";
import { getShowQuickView } from "../../../core/selectors/searchSalonSRCSelector";

const mapStateToProps = (state, ownProps) => {
  return {
    showQuickView: getShowQuickView(state)
  };
};

const QuickViewWrapperContainer = connect(mapStateToProps)(QuickViewWrapper);

export default QuickViewWrapperContainer;
