import { put, takeEvery, call, all, fork, select } from "redux-saga/effects";
import {
  loadEnumRequest,
  loadEnumSuccess,
  loadEnumFailure,
} from "../actionCreators/enumsActionCreators";
import enumsActionTypes from "../actionTypes/enumsActionTypes";
import { fetchEnumByName, fetchEnums } from "../services/petsmartWebPublic/enumsEndPoints";
import normalizeArrayByProperty from "../utils/normalizeUtils/normalizeArray";

import { enumNames } from "../constants/enumsConstants";
import { saveFoodOptionsToState } from "@/dux/foodOptionsList/foodOptionsListSagas";
import selectIsDiscontinuedFoodsFeatureHidden from "@/web/enableDisableWorkflowFeatureFlag/selectors/discontinuedFoods/getIsDiscontinuedFoodsWorkflowFeatureFlagHidden";

function* onloadEnum() {
  try {
    yield put(loadEnumRequest());
    const vaccinations = yield call(fetchEnumByName, { enumName: enumNames.VACCINATIONS });
    const states = yield call(fetchEnumByName, { enumName: enumNames.STATES });
    const countries = yield call(fetchEnumByName, { enumName: enumNames.COUNTRIES });

    const enums = yield call(fetchEnums);
    const responseData = enums.data;

    let vaccinationResponseData = [];

    vaccinations.data.forEach(({ Species, Vaccinations }) => {
      const vaccinationsBySpecies = Vaccinations.map(vaccination => ({
        ...vaccination,
        SpeciesId: Species.SpeciesId,
      }));
      vaccinationResponseData = vaccinationResponseData.concat(vaccinationsBySpecies);
    });

    const payload = {
      Allergies: normalizeArrayByProperty(responseData.Allergies, "AllergyId"),
      Breeds: normalizeArrayByProperty(responseData.Breeds, "BreedId"),
      Colors: normalizeArrayByProperty(responseData.Colors, "ColorId"),
      Conditions: normalizeArrayByProperty(responseData.Conditions, "ConditionId"),
      Genders: normalizeArrayByProperty(responseData.Genders, "GenderId"),
      Species: normalizeArrayByProperty(responseData.Species, "SpeciesId"),
      Vaccinations: normalizeArrayByProperty(vaccinationResponseData, "VaccinationId"),
      States: normalizeArrayByProperty(states.data, "StateProvinceAbbreviation"),
      Countries: normalizeArrayByProperty(countries.data, "Abbreviation"),
    };

    const foodFeatureHidden = yield select(selectIsDiscontinuedFoodsFeatureHidden);
    if (foodFeatureHidden) yield call(saveFoodOptionsToState, responseData.Foods);

    yield put(loadEnumSuccess({ payload }));
  } catch (error) {
    yield put(loadEnumFailure({ error }));
  }
}

function* watchloadEnum() {
  yield takeEvery(enumsActionTypes.LOAD_ENUMS, onloadEnum);
}

export default function* enumsSaga() {
  yield all([fork(watchloadEnum)]);
}
