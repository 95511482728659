export const GET_STORE_SETTINGS = "GET_STORE_SETTINGS";
export const GET_STORE_SETTINGS_REQUEST = "GET_STORE_SETTINGS_REQUEST";
export const GET_STORE_SETTINGS_SUCCESS = "GET_STORE_SETTINGS_SUCCESS";
export const GET_STORE_SETTINGS_FAILURE = "GET_STORE_SETTINGS_FAILURE";

export const getStoreSettings = () => ({
  type: GET_STORE_SETTINGS,
});

export const getStoreSettingsRequest = () => ({
  type: GET_STORE_SETTINGS_REQUEST,
});

export const getStoreSettingsSuccess = settings => ({
  type: GET_STORE_SETTINGS_SUCCESS,
  settings,
});

export const getStoreSettingsFailure = error => ({
  type: GET_STORE_SETTINGS_FAILURE,
  error,
});
