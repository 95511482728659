/** @module isEligibilityEmpty */

/**
 * A Utils function to check if there are any Pet or Customer Reasons within Eligibility
 * @exports isPetAndCustomerEligibilityReasonsEmpty
 * @param {object} eligibility - Eligibility that should be checked
 * @returns {boolean}
 */
export default function isPetAndCustomerEligibilityReasonsEmpty(eligibility) {
  return (
    !eligibility ||
    (eligibility.customer.reasons.length === 0 &&
      Object.values(eligibility.pets).every(pet => pet.reasons.length === 0))
  );
}
