import { connect } from "react-redux";
import { Layout } from "@prism/psm-ui-components";
import { isAFeatureFlagsHiddenLogicHelper } from "web/petParentProfile/petParentProfileHelpers";

const mapStateToProps = state => {
  return {
    isHidden: !isAFeatureFlagsHiddenLogicHelper(state),
    id: "petParentProfile-GenericContentWrapper",
    as: "section",
  };
};

// If no mapDispatchToProps fn is passed, then dispatch gets passed as a prop,
// if the component being wrapped uses prop spreading this could cause errors.
// To prevent this we pass a function returning an empty obj
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Layout.Stack);
