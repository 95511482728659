/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const foodSaveToProfileActionTypes = {
  SET_FOOD_SAVE_TO_PROFILE: "SET_FOOD_SAVE_TO_PROFILE",
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const setFoodSaveToProfile = ({ saveToProfile, foodId, petId }) => ({
  type: foodSaveToProfileActionTypes.SET_FOOD_SAVE_TO_PROFILE,
  saveToProfile,
  foodId,
  petId,
});
