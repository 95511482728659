import petParentProfileActionTypes from "../../../../core/actionTypes/ui/petParentProfileActionTypes";

const initialState = {
  customerKey: null,
  emailAddressId: null,
  newCustomerFieldType: "",
  newCustomerFieldValue: "",
  displayOtherAddresses: false,
  displayAddPetParentAddressForm: false,
  isAddingPet: false
};

const petParentProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case petParentProfileActionTypes.SET_CUSTOMER_AND_EMAIL_KEYS:
      return {
        ...state,
        customerKey: action.customerKey,
        emailAddressId: action.emailAddressId
      };

    case petParentProfileActionTypes.CLEAR_CUSTOMER_AND_EMAIL_KEYS:
      return {
        ...state,
        customerKey: initialState.customerKey,
        emailAddressId: initialState.emailAddressId
      };
    case petParentProfileActionTypes.SET_IS_ADDING_PET:
      return {
        ...state,
        isAddingPet: action.isAddingPet
      };
    case petParentProfileActionTypes.SET_FIELD_FOR_NEW_PROFILE:
      return {
        ...state,
        newCustomerFieldType: action.searchFieldType,
        newCustomerFieldValue: action.searchFieldValue
      };

    case petParentProfileActionTypes.CLEAR_FIELD_FOR_NEW_PROFILE:
      return {
        ...state,
        newCustomerFieldType: initialState.newCustomerFieldType,
        newCustomerFieldValue: initialState.newCustomerFieldValue
      };

    case petParentProfileActionTypes.DISPLAY_OTHER_PET_PARENT_ADDRESSES:
      return {
        ...state,
        displayOtherAddresses: action.isVisible
      };

    case petParentProfileActionTypes.DISPLAY_ADD_PET_PARENT_ADDRESS_FORM:
      return {
        ...state,
        displayAddPetParentAddressForm: action.isVisible
      };

    default:
      return state;
  }
};

export default petParentProfileReducer;
