import { Layout, Text } from "@prism/psm-ui-components";
import React from "react";
import { connect } from "react-redux";
import SalonAppointmentImage from "assets/icons/salon-appt-icon-key.svg";
import PSAImage from "assets/icons/PSA-icon.svg";
import PaidImage from "assets/icons/paid.svg";
import HealthImage from "assets/icons/medications-Icon.svg";
import MedicationImage from "assets/icons/health.svg";
import ValetImage from "assets/icons/doggie-valet-icon.svg";
import BedImage from "assets/icons/no-bed-icon.svg";
import NotificationImage from "assets/icons/notificaiton.svg";
import AgeImage from "assets/icons/age.svg";
import VIPImage from "assets/icons/VIP-customer-icon.svg";
import StarImage from "assets/icons/star.svg";
import NotesImage from "assets/icons/notes.svg";
import CatImage from "assets/icons/black-cat.svg";
import PuppyImage from "assets/icons/puppy.svg";
import IndividualImage from "assets/icons/flag-alert.svg";
import NewPetImage from "assets/icons/new-pet.svg";
import VaccinationImage from "assets/icons/vaccination.svg";
import ExpressImage from "assets/icons/express.svg";
import HistoryImage from "assets/icons/history.svg";
import BoardedGuestImage from "assets/icons/boarded-guest.svg";
import LinkedImage from "assets/icons/linked.svg";
import DigitalBookingImage from "assets/icons/digital-booking.svg";
import MaxLimitImage from "assets/icons/max-limit.svg";
import IsManualImage from "assets/icons/manual-appointment.svg";
import ReviewReservation from "assets/icons/review-reservation.svg";
import { generateRandomString } from "core/utils/authHelper";

export const baseIconList = [
  { iconText: "Health conditions", iconSVG: HealthImage },
  { iconText: "Senior", iconSVG: AgeImage },
  { iconText: "Puppy", iconSVG: PuppyImage },
  { iconText: "Cat", iconSVG: CatImage },
  { iconText: "Status alert", iconSVG: NotificationImage },
  { iconText: "New Customer", iconSVG: NewPetImage },
  { iconText: "Online Booking", iconSVG: DigitalBookingImage },
  { iconText: "Invoice paid", iconSVG: PaidImage },
  { iconText: "Group itinerary", iconSVG: LinkedImage },
];

export const hotelOnlyIconList = [
  { iconText: "Salon Appointment", iconSVG: SalonAppointmentImage },
  { iconText: "Vaccination missing", iconSVG: VaccinationImage },
  { iconText: "PSA", iconSVG: PSAImage },
  { iconText: "Receiving medications", iconSVG: MedicationImage },
  // Temporarily hiding VIP label per PO request - SVCSART-30464
  // { iconText: "Doggie Valet", iconSVG: ValetImage },
  { iconText: "No Bed", iconSVG: BedImage },
  // { iconText: "VIP Customer", iconSVG: VIPImage },
  { iconText: "Individual", iconSVG: IndividualImage },
  { iconText: "Review reservation", iconSVG: ReviewReservation },
];

export const salonOnlyIconList = [
  { iconText: "Vaccination", iconSVG: VaccinationImage },
  { iconText: "Express", iconSVG: ExpressImage },
  { iconText: "Locked groomer", iconSVG: StarImage },
  { iconText: "Notes", iconSVG: NotesImage },
  { iconText: "History", iconSVG: HistoryImage },
  { iconText: "Boarded guest", iconSVG: BoardedGuestImage },
  { iconText: "Max per block/Check-in max", iconSVG: MaxLimitImage },
  { iconText: "Manual Appointment", iconSVG: IsManualImage },
];

// ICON KEY COMPONENT/VIEW ---------------------------------------------------------------------------------------------
export const IconKey = props => {
  const { icons, boxBorderWidth, alignHeader } = props;
  return (
    <Layout.Box
      backgroundColor="box-background-inherit"
      borderColor="box-border-color-gray-100"
      borderRadius="box-rounded-none"
      borderStyle="box-border-solid"
      borderWidth={boxBorderWidth}
      padding="box-padding-3"
    >
      <Layout.Stack space="stack-space-4">
        <Layout.Box>
          <Layout.Center gutterWidth="center-gutter-0">
            <Layout.Box style={{ textAlign: alignHeader }}>
              <Text bold="bold" size="text-size-xl">
                Icons Key
              </Text>
            </Layout.Box>
          </Layout.Center>
        </Layout.Box>

        <Layout.Grid cellMinWidth="cell_min-width-48" cellSpacing="cell-space-2">
          {icons.map(({ iconText, iconSVG }) => (
            <Layout.Box key={generateRandomString(8)}>
              <img alt="icon" style={{ marginRight: "1rem" }} src={iconSVG} />
              <Text size="text-size-sm">{iconText}</Text>
            </Layout.Box>
          ))}
        </Layout.Grid>
      </Layout.Stack>
    </Layout.Box>
  );
};

// HOTEL ICON KEY CONTAINER --------------------------------------------------------------------------------------------
export const HotelIconKey = connect(() => {
  return {
    icons: baseIconList.concat(hotelOnlyIconList),
    boxBorderWidth: true,
    alignHeader: "left",
  };
})(IconKey);

// HOTEL ICON KEY CONTAINER FOR MODAL POPUP ----------------------------------------------------------------------------
export const HotelModalIconKey = connect(() => {
  return {
    icons: baseIconList.concat(hotelOnlyIconList),
    boxBorderWidth: false,
    alignHeader: "center",
  };
})(IconKey);

// SALON ICON KEY CONTAINER --------------------------------------------------------------------------------------------
export const SalonIconKey = connect(() => {
  return {
    icons: baseIconList.concat(salonOnlyIconList),
    boxBorderWidth: false,
    alignHeader: "left",
  };
})(IconKey);
