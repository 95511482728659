import isString from "lodash/isString";

/**
 * Util to format addon for itinerary API calls
 * @memberOf Utils.Engagement
 * @function
 * @name buildAddonForAPICall
 * @param {Object} addon - the addon currently being edited - for new addons it should only contain addOnProductNumber
 * @param {Object} instructions - comments to add to addon
 * @param {number} quantity - quantity to add to addon
 * @param {string} frequency - frequency to add to addon
 * @returns {Object} - addon data for patch call
 * @example buildAddonForAPICall({ addon, instructions, quantity, frequency })
 */
export const buildAddonForAPICall = ({ addon, instructions = {}, quantity, frequency }) => {
  if (!addon) return {};

  const { addOnProductNumber, orderProductNumber, groupingId, comment } = addon;
  const newInstr = instructions[addOnProductNumber]?.notes;

  return {
    addOnProductNumber,
    orderProductNumber,
    groupingId,
    quantity: quantity ?? addon?.quantity,
    comment: newInstr ?? comment,
    frequency: frequency ?? addon?.frequency,
  };
};

/**
 * Util to format addon for patch call
 * @memberOf Utils.Engagement
 * @function
 * @name buildAddonForPatchCall
 * @param {Object} addon - the addon currently being edited - for new addons it should only contain addOnProductNumber
 * @param {Object} instructions - comments to add to addons
 * @param {number} quantity - quantity to add to addon
 * @param {string} frequency - frequency to add to addon
 * @returns {Object} - addon data for patch call
 * @example buildAddonForPatchCall({ addon, instructions, quantity, frequency })
 */
export const buildAddonForPatchCall = ({ addon, instructions = {}, quantity, frequency }) => {
  if (!addon) return {};

  const { addOnProductNumber, orderProductNumber, comment } = addon;

  const newInstr = instructions[addOnProductNumber]?.notes;
  const instrEdited = isString(newInstr) && newInstr !== comment;
  const qtyEdited = !!quantity && quantity !== addon?.quantity;
  const freqEdited = !!frequency && frequency !== addon?.frequency;
  const isNew = !orderProductNumber;

  if (isNew || instrEdited || qtyEdited || freqEdited)
    return buildAddonForAPICall({ addon, instructions, quantity, frequency });

  return {};
};
