import { systemName } from "web/setSystemType/constants/setSystemTypeConstants";
import React from "react";
import { HotelCustomerNotesV2, HotelPetNotesV2, SalonCustomerNotesV2, SalonGroomingNotesV2 } from "dux/notes/Notes";

// Will return Customer Notes for the Service type based on what System flow the user is in.
export const CUSTOMER_NOTES_PER_SERVICE_TYPE = {
  [systemName.SALON]: <SalonCustomerNotesV2 />,
  [systemName.HOTEL]: <HotelCustomerNotesV2 />,
  // [systemName.TRAINING]: <TrainingCustomerNotes />, // un-comment for training customer notes
};

// Will return Pet service Notes (e.g. grooming or Hotel Pet notes) for the Service type based on what System flow the user is in.
export const PET_NOTES_PER_SERVICE_TYPE = {
  [systemName.SALON]: <SalonGroomingNotesV2 />,
  [systemName.HOTEL]: <HotelPetNotesV2 />,
  // [systemName.TRAINING]: <TrainingPETNotes />, // un-comment for training customer notes
};
