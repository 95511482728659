import { connect } from "react-redux";
import {
  showSearchResults,
  setSearchFieldType,
  setSearchField,
} from "core/actionCreators/searchSalonActionCreator";
import { loadCustomers } from "core/actionCreators/customersActionCreators";
import { loadSalonInfoWithServices } from "core/actionCreators/salonActionCreator";
import { setStoreNumber } from "core/actionCreators/ui/web/generalActionCreators";
import { clearAssociatesSchedule } from "core/actionCreators/schedulesActionCreators";
import SearchFieldComponent from "dux/searchField/searchFieldComponent";
import { commonDispatchActions, searchFieldProps } from "dux/searchField/searchFieldCommonProps";
import { STORE_SEARCH_DEFAULT_VALUE, STORE_SEARCH_OPTIONS } from "core/constants/dropdownOptions";
import getSelectedSearchOption from "core/utils/searchUtils/getSelectedSearchOption";
import redirectOnCustomerSearch from "core/utils/searchUtils/redirectOnCustomerSearch";
import getFormattedCustomerSearchField from "core/utils/searchUtils/getFormattedCustomerSearchField";
import { setSearchResultsToDisplay } from "@/core/actionCreators/searchActionCreators";
import { searchConstants } from "@/core/constants/searchConstants";
import { searchFieldTypeConstants } from "@/core/constants/searchFieldTypeConstants";
import {
  setSystemType,
  setSystemBookingFlowType,
} from "@/web/setSystemType/actions/setSystemTypeActions";
import { systemName } from "@/web/setSystemType/constants/setSystemTypeConstants";

const mapStateToProps = (state, ownProps) => {
  const commonProps = searchFieldProps(state, ownProps);
  const { searchFieldType, searchFieldValue, options } = commonProps;
  return {
    ...commonProps,

    //use case props here
    ...options,
    defaultValue: STORE_SEARCH_DEFAULT_VALUE,
    defaultSearchFieldType: "store",
    searchOptions: STORE_SEARCH_OPTIONS,
    selectedSearchOption: getSelectedSearchOption({ searchFieldType }, STORE_SEARCH_OPTIONS),
    searchFieldValue: getFormattedCustomerSearchField({ searchFieldType, searchFieldValue }),
    redirectOnSearch: redirectOnCustomerSearch,
    styles: { width: "100%" },
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchCommonActions = commonDispatchActions(dispatch);
  return {
    ...dispatchCommonActions,
    dispatchOnSearch: () => (searchType, searchValue) => {
      if (searchType === "store") {
        // search by store number
        dispatch(loadSalonInfoWithServices({ storeNumber: searchValue }));
        dispatch(setStoreNumber({ storeNumber: searchValue }));
        dispatch(clearAssociatesSchedule());
        dispatch(setSearchResultsToDisplay({ searchResultsToDisplay: searchConstants.STORE }));
        dispatch(setSearchFieldType({ searchFieldType: searchFieldTypeConstants.PHONE_NUMBER }));
        dispatch(setSearchField({ searchFieldValue: "" }));
      } else {
        // search customer info (phone number, email, name)
        dispatch(clearAssociatesSchedule());
        dispatch(loadCustomers({ [searchType]: searchValue }));
        dispatch(setSearchResultsToDisplay({ searchResultsToDisplay: searchConstants.CUSTOMER }));
        dispatch(setSearchFieldType({ searchFieldType: searchType }));
        dispatch(setSearchField({ searchFieldValue: searchValue }));
      }

      dispatch(setSystemType({ systemType: systemName.SALON }));
      dispatch(setSystemBookingFlowType({ systemBookingFlow: systemName.SALON }));

      dispatch(
        showSearchResults({
          showSearchResults: true,
        }),
      );
    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return{
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
    onSearch: dispatchProps.dispatchOnSearch(stateProps.searchComponentName),
  }
};

const SearchFieldSrcContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(SearchFieldComponent);

export default SearchFieldSrcContainer;
