export const dayActivityTypes = {
  WORK: "Work",
  ABSENCE: "Absence",
  RELOCATION: "Relocation",
};

export const absenceReasons = {
  LUNCH_BREAK: "Lunch Break",
  DAY_OFF: "Day Off",
};

export const VOIDED_STATUS = "PSVC_Voided";

export const RELOCATION_ON_SPLIT_SHIFT = "Relocation with split shift is not possible.";

export const petSupportedTypes = {
  DOG: "Dog",
  CAT: "Cat",
  DOG_AND_CAT: "Dog_Cat",
};

export const PET_TYPES = [
  {
    label: "Dog & Cat",
    id: petSupportedTypes.DOG_AND_CAT,
  },
  {
    label: petSupportedTypes.DOG,
    id: petSupportedTypes.DOG,
  },
  {
    label: petSupportedTypes.CAT,
    id: petSupportedTypes.CAT,
  },
];
