import React from "react";
import { connect } from "react-redux";

// Components
import { Layout } from "@prism/psm-ui-components";

// Containers
import { HotelRoomManagementFilterPills } from "@/dux/hotelRoomManagementFilterPills/hotelRoomManagementFilterPills";
import { HotelRoomManagementGrid } from "@/dux/hotelRoomManagementGrid/hotelRoomManagementGrid";
import { HotelRoomManagementHeader } from "@/dux/hotelRoomManagementHeader/hoteRoomManagementHeader";
import { HotelRoomManagementSearch } from "@/dux/hotelRoomManagementSearch/hotelRoomManagementSearch";
import { HotelRoomTypeComboBox } from "@/dux/hotelRoomTypeSelection/hotelRoomTypeSelection";

export const RoomManagementPage = props => {
  const { componentId, isHidden } = props;

  if (isHidden) {
    return null;
  }

  return (
    <Layout.Box id={componentId}>
      <Layout.Stack space="stack-space-3">
        <HotelRoomManagementHeader />
        {/* className="psm-debug-layout" will be removed once page is finished */}
        <Layout.Box padding="box-padding-6">
          {/* Room Management */}
          <Layout.Stack space="stack-space-4">
            {/* Room Selection */}
            <Layout.Box style={{ width: "20%" }}>
              <HotelRoomTypeComboBox />
            </Layout.Box>
            {/* Search and filter pills */}
            <Layout.Box>
              <Layout.Cluster justify="space-between">
                <HotelRoomManagementSearch></HotelRoomManagementSearch>
                <HotelRoomManagementFilterPills></HotelRoomManagementFilterPills>
              </Layout.Cluster>
            </Layout.Box>
            {/* Room Grid */}
            <HotelRoomManagementGrid></HotelRoomManagementGrid>
          </Layout.Stack>
        </Layout.Box>
      </Layout.Stack>
    </Layout.Box>
  );
};

export const HotelRoomManagementPage = connect(state => {
  return {
    componentId: "HotelRoomManagementPage",
    isHidden: false,
  };
})(RoomManagementPage);
