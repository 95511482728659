/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const showHideRequiresFeatureActionTypes = {
  SHOW_REQUIRE_FEATURE_FEATURE_FLAG: "SHOW_REQUIRE_FEATURE_FEATURE_FLAG"
};

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */
export const showHideRequireFeature = ({ featureFlagId, isHidden }) => ({
  type: showHideRequiresFeatureActionTypes.SHOW_REQUIRE_FEATURE_FEATURE_FLAG,
  featureFlagId,
  isHidden
});
