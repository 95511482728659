import { createSelector } from "reselect";
import { remove, get, slice } from "lodash/fp";
import getCustomerState from "../_customerState";
import { totalNumberOfOtherAddresses } from "../../../constants/petParentProfileConstants";

// Example of use:  getOtherAddresses(state, { customerKey });
const getOtherAddresses = createSelector([getCustomerState], customer => {
  const addresses = get("addresses", customer);

  // Remove any address that has a property of isPrimary and return an array of the rest, eg. all other addresses.
  const otherAddresses = remove(get("isPrimary"), addresses);

  // A total Number of addresses should be visible at a time, so we slice to get that
  const slicedAddress = slice(0, totalNumberOfOtherAddresses, otherAddresses);

  return slicedAddress;
});

export default getOtherAddresses;
