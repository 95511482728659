/* * ----------------------------------------------------------------------- * *\
  Action Types
\* * ----------------------------------------------------------------------- * */
export const GET_AVAILABLE_REPORTS = "GET_AVAILABLE_REPORTS";
export const GET_AVAILABLE_REPORTS_REQUEST = "GET_AVAILABLE_REPORTS_REQUEST";
export const GET_AVAILABLE_REPORTS_SUCCESS = "GET_AVAILABLE_REPORTS_SUCCESS";
export const GET_AVAILABLE_REPORTS_FAILURE = "GET_AVAILABLE_REPORTS_FAILURE";
export const SET_SELECTED_REPORT_TYPE = "SET_SELECTED_REPORT_TYPE";

/* * ----------------------------------------------------------------------- * *\
  Action Creators
\* * ----------------------------------------------------------------------- * */

/**
 * Action to fetch available reports
 * @memberOf Actions.Reports
 * @function
 * @name getAvailableReports
 * @returns {object}
 * @example getAvailableReports()
 */
export const getAvailableReports = () => ({
  type: GET_AVAILABLE_REPORTS,
});

/**
 * Action to request available reports
 * @memberOf Actions.Reports
 * @function
 * @name getAvailableReportsRequest
 * @returns {object}
 * @example getAvailableReportsRequest()
 */
export const getAvailableReportsRequest = () => ({
  type: GET_AVAILABLE_REPORTS_REQUEST,
});

/**
 * Action to set available reports
 * @memberOf Actions.Reports
 * @function
 * @name getAvailableReportsSuccess
 * @returns {object}
 * @example getAvailableReportsSuccess()
 */
export const getAvailableReportsSuccess = availableReports => ({
  type: GET_AVAILABLE_REPORTS_SUCCESS,
  availableReports,
});

/**
 * Action to set error for available reports
 * @memberOf Actions.Reports
 * @function
 * @name getAvailableReportsFailure
 * @returns {object}
 * @example getAvailableReportsFailure()
 */
export const getAvailableReportsFailure = error => ({
  type: GET_AVAILABLE_REPORTS_FAILURE,
  error,
});

/**
 * Action to set selected report type
 * @memberOf Actions.Reports
 * @function
 * @name setSelectedReportDate
 * @param {string} selectedReportType
 * @returns {object}
 * @example setSelectedReportType()
 */
export const setSelectedReportType = selectedReportType => ({
  type: SET_SELECTED_REPORT_TYPE,
  selectedReportType,
});
