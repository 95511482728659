import React from "react";
import { createSelector } from "reselect";
import moment from "moment";
import momentTz from "moment-timezone";
import { get } from "lodash/fp";
import { serviceTypeIds } from "core/constants/serviceTypesConstants";
import {
  formatCalendarDateMoment,
  formatDayHourMinutes,
  formatHourTimeMoment,
} from "core/utils/dateUtils/formatDateTime";
import getHotelHoursState from "./_hotelState";
import { getStoreTimeZone } from "@/core/selectors/entitiesSelector";

export const selectHotelHours = createSelector(
  [getHotelHoursState],
  hotelHours => hotelHours?.[serviceTypeIds.HOTEL] ?? {},
);

export const selectDDCHours = createSelector(
  [getHotelHoursState],
  hotelHours => hotelHours?.[serviceTypeIds.DDC] ?? {},
);

// TODO: SVCSART-34405 use selectDDCHours for same day reservations instead of selectHotelHours
export const getHotelHoursByDate = createSelector(
  [selectHotelHours, (state, props) => props],
  (hotelHours, { date }) => {
    return get(date, hotelHours);
  },
);

export const getHotelHoursOpenTimeByDate = createSelector([getHotelHoursByDate], hotelHours => {
  return get("OpenTime", hotelHours);
});

export const getHotelHoursCloseTimeByDate = createSelector([getHotelHoursByDate], hotelHours => {
  return get("CloseTime", hotelHours);
});

export const getHotelIsClosedByDate = createSelector([getHotelHoursByDate], hotelHours => {
  return get("IsClosed", hotelHours);
});

/**
 *  Selector to get error message if store is closed, or if given check out date and time are out of bounds for the store hours
 *
 *  @memberOf Selectors.HotelHours
 *  @function
 *  @name getHotelCheckOutHourOutsideRange
 *  @param {Object} state - redux state
 *  @param {string | moment} props.startDateTime - reservation startDateTime
 *  @param {string} props.time - check out time
 *  @param {string} props.date - check out date
 *  @returns {string | JSX}
 *  @example getHotelCheckOutHourOutsideRange(state, { startDateTime, time, date })
 */
export const getHotelCheckOutHourOutsideRange = createSelector(
  [
    getHotelHoursOpenTimeByDate,
    getHotelHoursCloseTimeByDate,
    getHotelIsClosedByDate,
    getStoreTimeZone,
    (state, props) => props,
  ],
  (openTime, closeTime, isClosed, timeZone, { startDateTime, time, date }) => {
    let formattedOpenTime;
    let start;

    const startDateInStoreTz = momentTz.tz(startDateTime, timeZone);
    const startDate = formatCalendarDateMoment(startDateInStoreTz);
    const startTime = formatHourTimeMoment(startDateInStoreTz);

    if (moment(startDate).isSame(moment(date), "day")) {
      start = startTime;
      formattedOpenTime = formatDayHourMinutes(startTime);
    } else {
      start = openTime;
      formattedOpenTime = formatDayHourMinutes(openTime);
    }

    const formattedCloseTime = formatDayHourMinutes(closeTime);

    if (isClosed) {
      return "Hotel is closed";
    }

    return (
      (closeTime < time || start > time) && (
        <div>{`Time must be between ${formattedOpenTime}-${formattedCloseTime}`}</div>
      )
    );
  },
);

/**
 *  Selector to check if given check in date and time are out of bounds for the store hours
 *
 *  @memberOf Selectors.HotelHours
 *  @function
 *  @name getHotelCheckInHourOutsideRange
 *  @param {Object} state - redux state
 *  @param {string | moment} props.endDateTime - reservation endDateTime
 *  @param {string} props.time - check in time
 *  @param {string} props.date - check in date
 *  @returns {boolean}
 *  @example getHotelCheckInHourOutsideRange(state, { endDateTime, time, date })
 */
export const getHotelCheckInHourOutsideRange = createSelector(
  [
    getHotelHoursOpenTimeByDate,
    getHotelHoursCloseTimeByDate,
    getStoreTimeZone,
    (state, props) => props,
  ],
  (openTime, closeTime, timeZone, { endDateTime, time, date }) => {
    let end;
    const endDateInStoreTz = momentTz.tz(endDateTime, timeZone);
    const endDate = formatCalendarDateMoment(endDateInStoreTz);

    if (moment(endDate).isSame(moment(date), "day")) {
      end = formatHourTimeMoment(endDateInStoreTz);
    } else {
      end = formatHourTimeMoment(closeTime);
    }
    return time < openTime || time > end;
  },
);

/**
 *  Selector to format open and close time or to return hotel is closed msg
 *
 *  @memberOf Selectors.HotelHours
 *  @function
 *  @name getFormattedTimeRange
 *  @param {Object} state - redux state
 *  @param {string | moment} props.endDateTime - reservation endDateTime
 *  @param {string} props.date - check in date
 *  @returns {object} { isClosed, formattedOpenTime, formattedCloseTime }
 *  @example getFormattedTimeRange(state, { endDateTime, date })
 */
export const getFormattedTimeRange = createSelector(
  [
    getHotelHoursOpenTimeByDate,
    getHotelHoursCloseTimeByDate,
    getHotelIsClosedByDate,
    getStoreTimeZone,
    (state, props) => props,
  ],
  (openTime, closeTime, isClosed, timeZone, { endDateTime, date }) => {
    let formattedCloseTime;
    const dateInStoretz = momentTz.tz(date, timeZone);
    const endDateInStoreTz = momentTz.tz(endDateTime, timeZone);
    const endDate = formatCalendarDateMoment(endDateInStoreTz);
    const endTime = formatHourTimeMoment(endDateInStoreTz);

    if (moment(endDate).isSame(moment(dateInStoretz), "day")) {
      formattedCloseTime = formatDayHourMinutes(endTime);
    } else {
      formattedCloseTime = formatDayHourMinutes(closeTime);
    }

    const formattedOpenTime = formatDayHourMinutes(openTime);

    if (isClosed) {
      return "Hotel is closed";
    }

    return {
      isClosed,
      formattedOpenTime,
      formattedCloseTime,
    };
  },
);
